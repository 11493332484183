import _ from 'underscore';
import { CWBaseCollection } from 'core/models/cwBase.collection';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { Model } from 'Backbone';

/**
 * Collection to get typology list with filtre
 *
 * Need Habilitation to filtre the collection
 */
export class CWTypoDomCol<TModel extends CWBaseModel = CWBaseModel> extends CWBaseCollection<CWBaseModel> {//eslint-disable-line

  public listVal: { [key: string]: any };
  public globalListTypo: Array<Model>;
  public ajaxDeferred: Array<JQueryXHR>;
  /**
   * ## Exemple d'initialisation :
   *
   *   new TypoDomCol({
   *    habContext: new HabilitationContext({
   *      onglet: "XXX",
   *      foncCour: {
   *        visualisation: {
   *         actprev: "XXX.V",
   *         actreal: "XXX.V",
   *        },
   *        gestion: {
   *          actprev: "XXX.G",
   *          actreal: "XXX.G",
   *        }
   *      },
   *    }),
   *    initFetch: true|false,
   *  });
   *
   *
   * ## foncCour peut être de la forme :
   *
   *    • cas habilitaion simple : foncCour: 'XXX.X'
   *
   *    • cas habilitaion composé : foncCour : {
   *         actprev: "XXX.V",
   *         actreal: "XXX.V",
   *        },
   *
   *    • cas habilitaion complette : foncCour: {
   *        visualisation: {
   *         actprev: "XXX.V",
   *         actreal: "XXX.V",
   *        },
   *        gestion: {
   *          actprev: "XXX.G",
   *          actreal: "XXX.G",
   *        }
   *        [
   *          validation: {
   *          actprev: "XXX.G",
   *          actreal: "XXX.G",
   *        }
   *        ]
   *      },
   *
   */
  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.model = options.model || CWBaseModel;
    super([], options);
    if (options.deplacements !== undefined && options.deplacements !== null) {
      this.url = (): string => {
        return Configuration.restRoot + '/rest/exp/activite/typodom/lister?DEPLAC=' + options.deplacements;
      };
    } else {
      this.url = (): string => {
        return Configuration.restRoot + '/rest/exp/activite/typodom/lister';
      };
    }
    if (options) {
      if (options.habContext) {
        this.habContext = options.habContext;
      } else {
        throw new Error('TypoDomCol need habContext to be initialized');
      }
      //listVal will stock fetch results
      this.listVal = {};
      if (options.initFetch) {
        this.fetchTypo();
      }
    } else {
      throw Error("TypoDomCol need options to be initialized");
    }
    this.listVal = new Object();
    this.globalListTypo = [];
    // https://tc39.github.io/ecma262/#sec-array.prototype.find
    if (!Array.prototype.find) {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Array.prototype, 'find', {
        value: (predicate: () => void, ...args: any[]): string => {
          let o = null;
          let len = 0;
          const thisArg = args[0]; // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
          let k = 0; // 5. Let k be 0.

          // 1. Let O be ? ToObject(this value).
          if (CWSTR.isBlank(this)) {
            throw new TypeError('"this" is null or not defined');
          }
          o = Object(this);
          // 2. Let len be ? ToLength(? Get(O, "length")).
          len = o.length || 0;
          // 3. If IsCallable(predicate) is false, throw a TypeError exception.
          if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
          }
          // 6. Repeat, while k < len
          while (k < len) {
            const kValue = o[k];

            // a. Let Pk be ! ToString(k).
            // b. Let kValue be ? Get(O, Pk).
            // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
            // d. If testResult is true, return kValue.
            if (predicate.call(thisArg, kValue, k, o)) {
              return kValue;
            }
            // e. Increase k by 1.
            k++;
          }
          // 7. Return null.
          return null;
        },
        configurable: true,
        writable: true
      });
    }
  }


  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      let nbFalse = 0;

      this.globalListTypo = _.clone(GLOBAL_DATA.typologies.models);
      for (let index = 0; index < response.length; index++) {
        const newData = this._getDataTypologie(response[index].acttypodomid);

        if (newData) {
          response[index - nbFalse] = newData;
        } else {
          nbFalse++;
        }
      }
      for (let elRemove = response.length - nbFalse; elRemove < response.length; elRemove++) {
        delete response[elRemove];
      }
      response.length = response.length - nbFalse;
    }
    return response;
  }

  _getDataTypologie(code: string): Model {
    let data: Model = null;
    let index = 0;

    while (index < this.globalListTypo.length) {
      if (this.globalListTypo[index].get('code') === code) {
        data = $.extend(true, {}, this.globalListTypo.splice(index, 1)[0]);
        break;
      }
      index++;
    }
    return data;
  }

  /**
   * Return list of filtered typologies
   */
  getTypoFiltre(): void {
    if (!CWSTR.isBlank(this.habContext)) {
      const foncCour = this.habContext.get('foncCour');

      this._fetchTypo(foncCour);
    }
  }

  fetchTypo(foncCour?: string): void {
    foncCour = !_.isEmpty(foncCour) && !CWSTR.isUndefined(foncCour) ? foncCour : this.habContext.get("foncCour");
    this._fetchTypo(foncCour);
  }

  _fetchTypo(foncCour: string | { [key: string]: any }): void {
    const saveContext = this.habContext.copy();

    switch (typeof foncCour) {
      case "object": {
        const keys = Object.keys(foncCour);
        let fonc = "";

        this.listVal = {};
        this.ajaxDeferred = [];
        if (typeof foncCour[keys[0]] === 'object') {
          const ajaxDeferredIndex: Array<string> = [];
          const listOptions: { [key: string]: any } = {
            "visualisation": {},
            "gestion": {},
            "validation": {}
          };
          const listActions: { [key: string]: any } = {
            "visualisation": [],
            "gestion": [],
            "validation": []
          };
          let lcontActions = 0;
          const listFetch: { [key: string]: any } = {};
          const listOmit: { [key: string]: any } = {};

          for (let indexKey = 0; indexKey < keys.length; indexKey++) {
            const keyFonc = Object.keys(foncCour[keys[indexKey]]);

            for (let indexFonc = 0; indexFonc < keyFonc.length; indexFonc++) {
              fonc = foncCour[keys[indexKey]][keyFonc[indexFonc]];

              if (!CWSTR.isBlank(fonc)) {
                if (CWHABILITATION.canView(fonc)) {
                  listOptions[keys[indexKey]][keyFonc[indexFonc]] = lcontActions;
                  listActions[keys[indexKey]].push(lcontActions);
                  this.habContext.update({
                    foncCour: fonc
                  });
                  ajaxDeferredIndex.push(keys[indexKey] + "-" + keyFonc[indexFonc]);
                  this.listVal[keys[indexKey]] = {};
                  if (CWSTR.isBlank(listFetch[fonc])) {
                    listFetch[fonc] = this.fetch();
                    this.ajaxDeferred.push(listFetch[fonc]);
                  } else {
                    listOmit[lcontActions] = _.keys(listFetch).indexOf(fonc);
                    this.ajaxDeferred.push(null);
                  }
                  this.habContext = saveContext.copy();
                  lcontActions++;
                }
              }
            }
          }
          Promise.all(this.ajaxDeferred).then((values): void => {
            if (!_.isEmpty(listOmit)) {
              const keysOmit = _.keys(listOmit);

              for (let i = 0; i < keysOmit.length; i++) {
                values[Number(keysOmit[i])] = values[listOmit[keysOmit[i]]]
              }
            }
            if (values.length > 0) {
              const withValidation = (!_.isEmpty(listOptions["validation"]));
              let visualisation: { [key: string]: any } = new Object();
              let gestion: { [key: string]: any } = new Object();
              let validation: { [key: string]: any } = new Object();
              let index: number;
              let typeEvent = [];
              let listKeys: Array<string> = [];

              //set data              
              for (let i = 0; i < listActions["visualisation"].length; i++) {
                visualisation = _.extend({}, this.concate(visualisation, values[listActions["visualisation"][i]]));
              }
              for (let i = 0; i < listActions["gestion"].length; i++) {
                gestion = _.extend({}, this.concate(gestion, values[listActions["gestion"][i]]));
              }
              //set length attribut
              visualisation.length = Object.keys(visualisation).length;
              gestion.length = Object.keys(gestion).length;
              if (!withValidation) {
                this.listVal.length = 2;
              } else {
                for (let i = 0; i < listActions["validation"].length; i++) {
                  validation = _.extend({}, this.concate(validation, values[listActions["validation"][i]]));
                }
                validation.length = Object.keys(validation).length;
                this.listVal.length = 3;
              }
              //Add type Event
              listKeys = _.keys(listOptions["visualisation"]);
              for (index = 0; index < visualisation.length; index++) {
                typeEvent = [];
                listKeys = _.keys(listOptions["visualisation"]);
                for (let j = 0; j < listKeys.length; j++) {
                  const indexVisuali = listOptions["visualisation"][listKeys[j]];
                  const lValTemp = values[indexVisuali];
                  const lCodeVisu = visualisation[index].get("code");
                  const lFindVisu = _.find(lValTemp, (item: { [key: string]: any }): boolean => {
                    return item.id === lCodeVisu;
                  });

                  if (lFindVisu) {
                    if (listKeys[j] === "actprev") {
                      typeEvent.push('P');
                    } else {
                      typeEvent.push('R');
                    }
                  }
                }
                visualisation[index].set('typeevenement', typeEvent);
              }
              for (index = 0; index < gestion.length; index++) {
                typeEvent = [];
                listKeys = _.keys(listOptions["gestion"]);
                for (let j = 0; j < listKeys.length; j++) {
                  const indexGestion = listOptions["gestion"][listKeys[j]];
                  const lValTemp = values[indexGestion];
                  const lCodeGest = gestion[index].get("code");
                  const lFindGest = _.find(lValTemp, (item: { [key: string]: any }): boolean => {
                    return item.id === lCodeGest;
                  });

                  if (lFindGest) {
                    if (listKeys[j] === "actprev") {
                      typeEvent.push('P');
                    } else {
                      typeEvent.push('R');
                    }
                  }
                }
                gestion[index].set('typeevenement', typeEvent);
              }
              if (withValidation) {
                for (index = 0; index < validation.length; index++) {
                  typeEvent = [];
                  listKeys = _.keys(listOptions["validation"]);
                  for (let j = 0; j < listKeys.length; j++) {
                    const indexValid = listOptions["validation"][listKeys[j]];
                    const lValTemp = values[indexValid];
                    const lCodeValid = validation[index].get("code");
                    const lFindValid = _.find(lValTemp, (item: { [key: string]: any }): boolean => {
                      return item.id === lCodeValid;
                    });

                    if (lFindValid) {
                      if (listKeys[j] === "actprev") {
                        typeEvent.push('P');
                      } else {
                        typeEvent.push('R');
                      }
                    }
                  }
                  validation[index].set('typeevenement', typeEvent);
                }
              }
              this.listVal.visualisation = visualisation;  //on pourrait ne pas l'ajouter s'il n'a pas de valeur
              this.listVal.gestion = gestion; //on pourrait ne pas l'ajouter s'il n'a pas de valeur
              if (withValidation) {
                this.listVal.validation = validation;
              }
              this.trigger('fetch:success');
            } else {
              throw new Error('habilitation foncCour property format is not good, check format');
            }
          })
        } else {
          for (let index = 0; index < keys.length; index++) {
            fonc = foncCour[keys[index]];
            this.habContext.update({
              foncCour: fonc
            });
            this.ajaxDeferred.push(this.fetch());

            this.habContext = saveContext.copy();
          }
          Promise.all(this.ajaxDeferred).then((values) => {
            if (values.length > 0) {
              let index = 0;
              const lfuncFind = (element: { [key: string]: any }): boolean => {
                return element.id === this.listVal[index].get('code');
              };

              this.listVal = this.concate(values[0], values[1])
              this.listVal.length = Object.keys(this.listVal).length;
              this.length = this.listVal.length;
              for (index = 0; index < this.listVal.length; index++) {
                const typeEvent = [];

                if (values[0] && values[0].find(lfuncFind)) {
                  typeEvent.push('P');
                }
                if (values[1] && values[1].find(lfuncFind)) {
                  typeEvent.push('R');
                }
                if (!_.isEmpty(keys) && _.isEmpty(typeEvent)) {
                  for (let i = 0; i < keys.length; i++) {
                    if (keys[i] === "actprev") {
                      typeEvent.push('P');
                    } else if (keys[i] === "actreal") {
                      typeEvent.push('R');
                    }
                  }
                }
                this.listVal[index].set('typeevenement', typeEvent);
              }
              this.trigger('fetch:success');
            } else {
              throw new Error('Habilitation passed to the fonction are not good');
            }
          })
        }
        break;
      }
      case "string":
        this.habContext.update({
          foncCour: foncCour
        });
        this.fetch({
          success: (fresh: CWTypoDomCol) => {
            this.listVal = fresh.models;
            this.trigger('fetch:success');
          }
        })
        break;
      default:
        throw Error('foncCour not correctly format');
    }
  }

  /**
   * Retourne si la typologie peut être géré en fonction du type d'évènement
   *
   * @param String code Code de la typologie à tester
   * @param String type Code du type d'évènement. "P" : prevu | "R" : realiser | undefined / "A" : prevu & realiser
   *
   * @returns true/false
   */
  canManage(code: string, type?: string): boolean {
    return this._canDo(this.listVal.gestion, code, type);
  }

  /**
   * Retourne si la typologie peut être valider en fonction du type d'évènement
   *
   * @param String code Code de la typologie à tester
   * @param String type Code du type d'évènement. "P" : prevu | "R" : realiser | undefined / "A" : prevu & realiser
   *
   * @returns true/false
   */
  canValidate(code: string, type: string): boolean {
    return this._canDo(this.listVal.validation, code, type);
  }

  getArrayValuesVisualisation(): Array<CWBaseModel> {
    return this._getArrayValues(this.listVal.visualisation);
  }

  getArrayValuesGestion(): Array<CWBaseModel> {
    return this._getArrayValues(this.listVal.gestion);
  }

  getArrayValuesValidation(): Array<CWBaseModel> {
    return this._getArrayValues(this.listVal.validation);
  }

  getTypologieVisu(code: string): CWBaseModel {
    return this._getTypologie(code, this.listVal.visualisation);
  }

  getTypologieGestion(code: string): CWBaseModel {
    return this._getTypologie(code, this.listVal.gestion);
  }

  getTypologieValidation(code: string): CWBaseModel {
    return this._getTypologie(code, this.listVal.validation);
  }

  _canDo(list: { [key: string]: any }, code: string, type?: string): boolean {
    let index = 0;
    let keys: string[] = null;
    let keyLength = 0;

    if (_.isEmpty(list)) {
      return false;
    }
    keys = Object.keys(list);
    keyLength = keys.length;
    type = !_.isEmpty(type) ? type : 'A';
    while (index < keyLength) {
      if (!CWSTR.isBlank(list[index])) {
        if (list[keys[index]].id === code) {
          if (type === "A" || list[keys[index]].get('typeevenement').indexOf(type) !== -1) {
            return true;
          } else {
            break;
          }
        }
      }
      index++;
    }
    return false;
  }

  _getArrayValues(tab: Array<CWBaseModel>): Array<CWBaseModel> {
    const tmp = _.clone(tab);

    if (!Array.isArray(tab)) {
      delete (tmp.length);
    }
    // return Object.values(tmp);
    return Object.keys(tmp).map((e) => {
      return tmp[e as unknown as number];
    });
  }

  _getTypologie(code: string, tab: Array<CWBaseModel>): CWBaseModel {
    if (!CWSTR.isBlank(code)) {
      const keys = Object.keys(tab);
      const length = (keys.indexOf('length') !== -1) ? keys.length : (keys.length - 1);

      for (let index = 0; index < length; index++) {
        const item = tab[index];

        if (item !== undefined && item.get('code') === code) {
          return item;
        }
      }
    }
    return null;
  }

  concate(tab1: Array<CWBaseModel> | { [key: string]: any }, tab2: Array<CWBaseModel>): Array<CWBaseModel> {
    let nextIndex;
    let tabFinal: any = $.extend(true, {}, tab1);
    const tabTmp: any = $.extend(true, {}, tab1);

    if (!_.isEmpty(tab1) && !_.isEmpty(tab2)) {
      const lenTab1 = _.keys(tab1).length;
      const lenTab2 = _.keys(tab2).length;

      tabTmp.length = lenTab1;
      nextIndex = lenTab1;
      for (let indexTab2 = 0; indexTab2 < lenTab2; indexTab2++) {
        let exist = false;
        let indexTmp = 0;

        while (indexTmp < tabTmp.length) {
          if (tabTmp[indexTmp].id === tab2[indexTab2].id) {
            exist = true;
            break;
          }
          indexTmp++;
        }
        if (!exist) {
          tabFinal[nextIndex] = tab2[indexTab2];
          nextIndex++;
        }
      }
    } else if (!_.isEmpty(tab2)) {
      tabFinal = $.extend(true, {}, tab2);
    }
    return tabFinal;
  }

  sortByLibelle(): void {
    this.listVal = this._getArrayValues(this.listVal as Array<CWBaseModel>).sort(function (a, b) {
      if (a.get('libelle') > b.get('libelle')) {
        return 1;
      }
      if (a.get('libelle') < b.get('libelle')) {
        return -1;
      }
      return 0;
    });
  }

  _getValuesByTypeGestion(type: string): { [key: string]: any } {
    const arrayValues: { [key: string]: any } = {};
    const tmp = Object.keys(this.listVal);
    if (tmp.indexOf('length') !== -1) {
      tmp.splice(tmp.indexOf('length'), 1);
    }
    for (let index = 0; index < tmp.length; index++) {
      arrayValues[tmp[index]] = [];
      for (let element = 0; element < this.listVal[tmp[index]].length; element++) {
        const currentElement = this.listVal[tmp[index]][element];
        if (currentElement.get('typeGestionActivite') === type) {
          arrayValues[tmp[index]].push(currentElement);
        }
      }
    }
    return arrayValues;
  }

  getValueTypeDEPLAC(): any {
    return this._getValuesByTypeGestion("DEPLAC");
  }

  getValueTypeActJour(): { [key: string]: any } {
    const listACTJGEN = this._getValuesByTypeGestion("ACTJGEN");
    const listACTJORG = this._getValuesByTypeGestion("ACTJORG");
    const listFinal: { [key: string]: any } = {};

    listFinal.gestion = this.concate(listACTJGEN.gestion, listACTJORG.gestion);
    listFinal.visualisation = this.concate(listACTJGEN.visualisation, listACTJORG.visualisation);
    listFinal.validation = this.concate(listACTJGEN.validation, listACTJORG.validation);
    return listFinal;
  }
}
