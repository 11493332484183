import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils';

/**
 * Collection for Declaration
 */
export class CWDeclarationColl extends Backbone.Collection {

  constructor(models?: CWBaseModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(models, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/utilitaire/divcod/extended/STACC"
    }
  }

  parse(response: { [key: string]: any }[]): { [key: string]: any } {
    if (response) {
      const objRtn: { [key: string]: any } = {};
      let text: string = "";

      for (let i = 0; i < response.length; i++) {
        if (response[i].code) {
          if (CWSTR.isBlank(response[i].valtexte)) {
            text = "[ " + response[i].libellelong + " ]";
          } else {
            text = response[i].valtexte;
          }
          objRtn[response[i].code.toLowerCase()] = text;
        }
      }

      if (!_.isEmpty(objRtn)) {
        response = [objRtn];
      }
    }
    return response;
  }
}
