import { ReadOnlyModel } from 'core/models/readOnly.model.js';

/**
 * Workflow for UC "Résultats de contrôles"
 */
export var ResultcontrolesWorkflow = ReadOnlyModel.extend({

  initialize: function() {
    // table collections
    this.saisieColl = null;
    this.recapColl = null;

    this.collab = null;
    this.recap = null;

    this.model = null;

    //Variables to keep message count
    this.saisieNum = {};
    this.recapNum = {};

    //Flag set to true when action is blocked because there are "méssages bloquants"
    this.blocked = false;
    //Action to do when confirming
    this.action = null;

    this.set("ready", false);
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {
    if (callback) {
      callback();
    }
    this.set("ready", true);
  }

});