import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWSTR } from 'utils/cwStr';

interface ModelDestroyOptionsAuthModel extends Backbone.ModelDestroyOptions {
  async: boolean;
}
/**
 * Authentication Model. To be used with these ids: "session" --> Authentication
 * and user in session data "date" --> Server date "langues" --> langues
 * available
 */
export class CWAuthModel extends Backbone.Model {

  urlRoot: string;

  constructor(attributes?: { [key: string]: any } | any, options?: { [key: string]: any } | any) {
    super(attributes, options);
    this.urlRoot = Configuration.restRoot + "/rest/authentification";
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    if (CWSTR.isBlank(attrs.login)) {
      errors.login = "Login cannot be empty";
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && response.token) {
      Configuration["x_token_key"] = response.token;
    }
    return response;
  }

  destroy(options?: ModelDestroyOptionsAuthModel): any {
    return Backbone.Model.prototype.destroy.call(this, options);
  }
}