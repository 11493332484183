import _ from 'underscore';
import { CWListeBadgeageModel } from './cwListeBadgeage.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';


export class CWListeBadgeageColl extends CWPaginatedCollection<CWListeBadgeageModel> {

  matricule: string;
  statuts: string;
  datedeb: string;
  datefin: string;
  horaire: string;
  isForResponsable: boolean;
  validation: string;

  /**
   * Constructor - renove the paginated (this.paginated = false)
   */


  constructor(models?: CWListeBadgeageModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    CWListeBadgeageColl.prototype.version = "2.2.0";
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWListeBadgeageModel;
    super(models, options);
    this.version = "2.2.0";
    this.matricule = null;
    this.statuts = null;
    this.datedeb = null;
    this.datefin = null;
    this.horaire = null;
    this.paginated = false;
    this.isForResponsable = true;
    this.url = (): string => {
      let txtUrl = "?matricule=" + this.matricule + "&statuts=" + this.statuts;

      if (!CWSTR.isBlank(this.datedeb)) {
        txtUrl += "&datedeb=" + this.datedeb;
      }
      if (!CWSTR.isBlank(this.datefin)) {
        txtUrl += "&datefin=" + this.datefin;
      }
      if (!CWSTR.isBlank(this.horaire)) {
        txtUrl += "&horaire=" + this.horaire;
      }
      if (!CWSTR.isBlank(this.validation)) {
        txtUrl += "&ctxActionValider=" + this.validation;
      }
      if (!_.isBoolean(this.isForResponsable) || this.isForResponsable === true) {
        return Configuration.restRoot + "/rest/badgeage/composant/liste" + txtUrl;
      } else {
        return Configuration.restRoot + "/rest/badgeage/composant/listecoll" + txtUrl;
      }
    }
  }

  clone(): this {
    const temp = super.clone.call(this);

    return temp;
  }
}
