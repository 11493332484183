import _ from 'underscore';
import { CWActionBarView } from 'core/controls/cwActionBar.view';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseGridOptions, CWBaseGridView } from '../basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDataGridRowView } from '../data_grid/cwDataGridRow.view';
import { CWEditableGridModel } from './cwEditableGrid.model';
import { CWEditedRowView } from 'core/views/cwEditedRow.view';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';
import { CWVoletView } from 'src/core/components/dialog/volet/cwVolet.view';
import { EventHandler, Model, ViewOptions } from 'Backbone';
import { i18n } from 'src/i18n.js';


export interface CWEditableGridInterface<TModel extends CWBaseModel = CWBaseModel,
  TColl extends CWPaginatedCollection = CWPaginatedCollection<TModel>> extends CWBaseGridOptions<TModel, TColl> {
  viewRow: CWEditedRowView<TModel>;
  viewRowTitle: string;
}

export class CWEditableGridView<TModel extends CWBaseModel = CWBaseModel,
  TColl extends CWPaginatedCollection<TModel> = CWPaginatedCollection<TModel>> extends CWBaseGridView<TModel, TColl> {
  /**
   * Event launched for mode management
   */
  /**
   * Event launched for action save
   */
  /**
   * Event launched for action revert
   */
  /**
   * Add this to have Outline
   *
   */
  public model: CWEditableGridModel<TColl, TModel>;
  public viewRow: CWEditedRowView<TModel>;
  public viewRowTitle: string;
  public editionView: CWVoletView;
  public editionViewModel: Model;
  public canEdit: boolean;
  public headerFixed: boolean;
  public heightMax: boolean;
  public _showButtonBar: boolean;
  enableEmptyRow: any;
  public classForButtons: string;
  public showDeleteHead: boolean;
  public messageDeleteHead: string;

  //public canEditCallback: (model: CWBaseGridModel, callback: (canedit: boolean) => void) => void;

  /**
   * Constructor
   * EditableGridView
   */
  constructor(options: ViewOptions<TModel> | any) {
    options = options || {};
    options.editable = true;
    options.isEditableGridView = true;
    super(options);
    /**
     * indicate table is editable
     */
    this.classForButtons = options.classForButtons;
    this.editable = true;
    this.isEditableGridView = true;
    this.DEFAULT_NANE = "Editable";
    this.DEFAULT_HEIGHT = 305;


    //columns definition
    if (!options.model) {
      throw Error("You must define an EditableGridModel to use a EditableGridView");
    }
    /**
     * EditableGridModel
     */
    this.model = options.model;

    //Search Pk definition if exist and initializes toggle
    _.each(this.currentVue.columns, (column) => {
      if (_.has(column, "pk") && column.pk === true) {
        this.model.propertyPK.push(column.property);
      }
    }, this);

    //edit row view definition
    if (!options.viewRow) {
      throw Error("You must define a ViewRow to use a EditableGridView");
    }
    this.viewRow = options.viewRow;
    this.viewRowTitle = options.viewRowTitle;
    this.viewRow.parentId = this.id;

    if (this.viewRow) {
      this.viewRow.setDataGrid(this);
    }

    this.headerFixed = false;
    if (options.headerFixed) {
      this.headerFixed = true;
    }

    this.heightMax = false;
    if (options.heightMax) {
      this.heightMax = true;
    }

    this.showDeleteHead = false;
    if (options.showDeleteHead) {
      this.showDeleteHead = true;
    }
    this.messageDeleteHead = "";
    if (options.messageDeleteHead) {
      this.messageDeleteHead = options.messageDeleteHead;
    }
    // action bar
    //		this.actionBarTemplate = TPL_common_table_actionbar;

    this._showButtonBar = true;
    /**
     * ButtonBar
     */
    this.buttonBar = new CWActionBarView({ iconsOnly: true, positionNewButtonsOnLeft: this.positionNewButtonsOnLeft, moveInButton: this.moveInButton, showDeleteHead: this.showDeleteHead });
    this.model.btnBarModel = this.buttonBar.model;
    this.model.btnBarModel.set("mode", this.model.get("mode"));
    this.model.set("messageDeleteHead", this.messageDeleteHead);
    this.model.coll.off("add");
    this.model.coll.on("add", (model) => {
      if (model.isNew()) {
        const rowView = new CWDataGridRowView({ model: model, dataGrid: this, classForButtons: this.classForButtons });
        rowView.render();
        model.trigger("add:model", model);
        this.manageCustomMessageVisibility(false);

        if (!CWSTR.isBlank(this.multiselection)) {
          this._focusFirstSelectibleCell();
        } else {
          this._focusFirstCell();
        }
      }
    });

    this.listenTo(this.model, "change:mode", this._manageMode);
    this.listenTo(this.model, "action:save", this._saveRowEdition);
    this.listenTo(this.model, "action:revert", this._revertRowEdition);
    this.listenTo(this.model, "action:copy", this._copyRowEdition);
    this.listenTo(this.model, "action:delete", this._deleteRowEdition);
    this.listenTo(this.model, "finishEdition", this._finishVoletEdition);

    //Btn bar reset
    this.model.coll.on("reset", this._resetBtnBar, this);

    // Set Up the model
    this.model.setUp();
  }

  _updateEditionModel(model: TModel): void {
    const formModel = this.viewRow.model;
    formModel.set("value", model);
    if (model.isNew()) {
      formModel.set("mode", "C");
    } else {
      formModel.set("mode", "E");
    }
  }

  openEditionVolet(model: TModel): JQuery {
    this._updateEditionModel(model);

    if (!this.editionView) {
      this.editionView = this._createVolet(this.viewRow);
    }

    this._updateVoletTitle();

    const formModel = this.viewRow.model;
    this.listenTo(formModel, "form:edited", () => {
      formModel.set("mode", "EM");
      this._updateVoletTitle();
    });

    this.editionView.open();

    return this.editionView.internalView.el;
  }

  _createVolet(view: CWBaseFormView): CWVoletView {
    const volet = new CWVoletView({
      view: view,
      isTitleHTML: true,
      minWidth: 500
    });
    volet.setHeight("auto");
    volet.setWidth("auto");
    volet.dialogClass += " cw-pave__volet";

    this.listenTo(volet.model, "volet:close", () => {
      if (CWSTR.isBlank(this.model.id)) {
        this.model.trigger("action:revert", false);
      } else {
        this.model.trigger("action:revert", true);
      }
      this._finishVoletEdition();
    });
    return volet;
  }

  _finishVoletEdition(closeVolet?: boolean): void {
    if (closeVolet && !CWSTR.isBlank(this.editionView)) {
      this.editionView.close();
    }
    this.stopListening(this.viewRow.model, "form:edited");
  }

  _updateVoletTitle(): void {
    if (this.editionView) {
      const formModel = this.viewRow.model;
      const model = formModel.get("value");
      const mode = formModel.get("mode");
      const isCopy = model.isCopy;
      const isNew = model.isNew();
      const title = this.viewRowTitle;
      let subTitle = this._getVoletSubTitleByStatus(mode, isNew, isCopy);

      if (!CWSTR.isBlank(subTitle)) {
        const $span = $("<span>").addClass("cw-volet__subTitle cw-texteAllege cw-texteAllege--selectionne");
        $span.text(subTitle)
        subTitle = " - " + $span.get(0).outerHTML;
      }

      this.editionView.updateTitle(title + subTitle);
    }
  }

  updateVoletTitle(title: string): void {
    this.viewRowTitle = title;
    this._updateVoletTitle();
  }

  _getVoletSubTitleByStatus(mode: string, isNew: boolean, isCopy: boolean): string {
    let subTitle = "";
    if (isCopy) {
      subTitle = i18n.t('common:grid.duplication_en_cours');
    } else if (isNew) {
      subTitle = i18n.t('common:grid.creation_en_cours');
    } else if (mode === "EM") { // Edition with modification
      subTitle = i18n.t('common:grid.modification_en_cours');
    }
    return subTitle;
  }

  /**
   * Manage key press. Depending on the key pressed:
   * Down one row /down 1 page/ save changes /escape key
   */
  keyEvent(e: JQueryKeyEventObject): boolean {
    let ret = CWBaseGridView.prototype.keyEvent.call(this, e);
    const rowInEdition = (this.model.get('mode') === "E");

    if (e.keyCode === 40 && !rowInEdition) { // down 1 row
      this._revertRowEdition();
    }
    if (e.keyCode === 34 && !rowInEdition) { // down 1 page
      this._revertRowEdition();
    }
    //if the enter button is pressed on the revert button its not a save action
    if (e.keyCode === 13 && $(e.target).hasClass("phx-cell-revert-action") === false) { // Enter key pressed
      if (rowInEdition) {
        // we are in edition mode and press enter to save changes
        this._saveRowEdition();
      } else {
        if ((e.target as HTMLInputElement)?.type !== "radio") {
          // press enter to enter edition mode
          this.model.coll.trigger("row:dblclick", this.model.get('value'));
        }
      }
      ret = false;
    }
    if (e.keyCode === 27 || ((e.keyCode === 13 || e.keyCode === 32) && $(e.target).hasClass("phx-cell-revert-action") === true)) { // Esc key pressed or enter on the revert
      this._revertRowEdition(true);
      ret = false;
      //WCAG
      this._focusLastCell();
    }
    //return false if event was managed overwise return true
    //LOG.debug("keypressed: "+e.keyCode);
    return ret;
  }

  /**
   * Reverts row edition
   */
  _revertRowEdition(clickRow?: boolean): void {
    if (this.model.get('mode') === "E") {
      $(".phx-cell-revert-action", this.el).focus();

      this.model.btnBarModel.trigger("btn:click", "revert");
      if (this.model.coll.length === 0) {
        this.showTable(false); //on doit cacher l'entête avec les colonnes(le tableau vide)
        this.manageCustomMessageVisibility(true);
      } else {
        const model = this.model.get("value");

        if (clickRow) {
          model.trigger("row:select", model);
        }
      }
    }
  }

  /**
   * Save row edition
   */
  _saveRowEdition(): void {
    $(".phx-cell-save-action", this.el).focus();
    this.model.btnBarModel.trigger("btn:click", "save");
    this._focusLastCell();
  }

  /**
   * Delete row edition
   */
  _deleteRowEdition(): void {
    this.model.btnBarModel.trigger("btn:click", "delete", "OrigineVolet");
    this._focusLastCell();
  }

  /**
   * Copy row edition
   */
  _copyRowEdition(): void {
    this.model.btnBarModel.trigger("btn:click", "copy");
    this._focusLastCell();
  }

  /**
   * Render the button bar
   */
  _renderButtonBar(): void {
    if (this._showButtonBar) {
      $(".c-grind__titleBar__barAction", this.el).append(this.buttonBar.render().el);
      // This is needed because when you use nested views, if you initializes the view in the constructor,
      // the delegate events is called before the dom is ready.
      this.buttonBar.delegateEvents();
    }
  }
  /**
   * Renders this view
   */
  render(): CWEditableGridView<TModel, TColl> | any {

    if ($(this.el).children().length === 0) { // Render the Button bar and the header only one time
      $(this.el).html(this.template());
      // Header and button bar
      this._renderHead();
      this._renderButtonBar();
      if (Object.keys(this.vues).length > 1 && this.showLevierMultipleViews === true) {
        this._addButtonSwitchVues();
      }
      if (Object.keys(this.vues).length > 1 && this.showButtonsMultipleVues === true) {
        this._renderVuesButtonBar();
      }
    }

    if (this.headerFixed === true) {
      let height = this.height;

      if (this.heightMax === true) {
        height = window.innerHeight - 100 - $(this.el).find("tbody").offset().top;
      }

      $(this.el).find("tbody").parents("table").css({ "display": "table-caption" });
      $(this.el).find("tbody").parents("table").height(height);
    }

    $(this.el).find("tbody").attr("tableView-id", this.cid);
    //Configure scroll
    const tableScroll = $(this.el).find(".c-grind__scroll");
    tableScroll.height(this.height);
    tableScroll.css("overflow-y", "auto");

    //Width:
    $(this.el).width(this.width);

    const tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);
    }
    this.renderPaginator();

    this.applyHabilitations();

    this.positionHeader();
    return this;
  }

  /**
   * It manages the buttons that allow to edit the values of the table
   * Copied from enabled
   */
  enableEdition(enable: boolean): void {
    this.editable = enable && this.habilitationUpdate;

    this.model.readOnly = !this.editable;

    if (this.editable) {
      $(".c-grind__titleBar__barAction", this.el).show();
      // this.currentVue._columns["phx-action"].visible = true;
      // this._unlockColumn("phx-action");
    } else {
      $(".c-grind__titleBar__barAction", this.el).hide();
      // this.currentVue._columns["phx-action"].visible = false;
      // this._lockColumn("phx-action");
    }
  }

  /**
   * It manages the buttons that allow to edit the values of the table
   * when we want to show the action bar
   */
  enableTableEdition(enable: boolean): void {
    this.editable = enable && this.habilitationUpdate;

    this.model.readOnly = !this.editable;

    if (this.editable) {
      // this.currentVue._columns["phx-action"].visible = true;
      // this._unlockColumn("phx-action");
    } else {
      // this.currentVue._columns["phx-action"].visible = false;
      // this._lockColumn("phx-action");
    }
  }

  /**
   * Mode management
   */
  _manageMode(model: TModel, mode: string): void {
    if (mode === "E" && this.model.coll.paginated) {
      $(".c-grind__scroll", this.el).css("overflow-y", "hidden");
    } else {
      $(".c-grind__scroll", this.el).css("overflow-y", "auto");
    }
    if (mode === "E" || mode === "C") {
      this.manageCustomMessageVisibility(false);
    }
  }
  /**
   * Set button bar auto render
   */
  setButtonBarAutoRender(renderBtnBar: boolean): void {
    this._showButtonBar = renderBtnBar;
  }
  /**
   * Reset button abr
   */
  _resetBtnBar(): void {
    this.model.set("mode", "C");
  }
  /**
   * Allow edition
   */
  allowEdition(allowed: boolean): void {
    if (allowed) {
      this.model.coll.on("row:dblclick", null); //???
      //this.currentVue._columns["phx-action"].visible = true;
    } else {
      this.model.coll.off("row:dblclick");
      //this.currentVue._columns["phx-action"].visible = false;
    }
  }
  /**
   * Allow row update
   */
  allowRowUpdate(allowed: boolean): void {
    CWBaseGridView.prototype._allowRowUpdate.call(this, allowed);
    this._showButtonBar = allowed;
  }
  /**
   * Disable delete button
   */
  disableDeleteButton(disable: boolean): void {
    if (disable === true) {
      this.buttonBar.model.trigger("hide:delete");
    } else {
      this.buttonBar.model.trigger("show:delete");
    }
  }
  /**
   * Overrides a menu
   */
  overrideMenu(customButtonActionCallback: EventHandler, callingView: any): void {
    if (customButtonActionCallback) {
      this.buttonBar.model.off("btn:click");
      this.buttonBar.model.on("btn:click", customButtonActionCallback, callingView);
    }
  }

  //Accessibility
  _focusLastCell(): void {
    this._focusCell(this.model.coll.editModeCellSelected);
  }

  //select the first selectible cell with input
  _focusFirstSelectibleCell(): void {
    let index = 0;
    let findCell = 0;

    while (findCell === 0) {
      const currentRow = this.$el.find(".phx-selected");
      const cell = currentRow.children()[index];

      if ($(cell).length > 0) {
        if ($(cell).find(":input").is(":input")) {
          // we have found the first cell with an input
          this._focusCell(index);
          findCell = 1;
        } else {
          // we haven't found a cell with an input, we loop again
          index++;
        }
      } else {
        // we haven't found any cell with an input
        findCell = 2;
      }
    }
  }

  _focusFirstCell(): void {
    this._focusCell(0);
  }

  _focusCell(index: number): void {
    const currentRow = this.$el.find(".phx-selected");
    const cell = currentRow.children()[index];

    if ($(cell).find(":input").is(":input")) {
      $(cell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus();
    } else {
      $(cell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
    }
  }
}
