import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * referentiel Utilisateurs Model
 */
export class CWDelegationsUtilisateurModel extends CWBaseModel {

  code: any;


  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "matricule": null,
      "matriculeaux": "",
      "nom": "",
      "prenom": "",
      "utilcode": "",
      "utilnom": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/utildelegation/utilisateurs";
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      //il faut utiliser un identificateur composé
      response.id = response.matricule + "," + response.utilcode;//matricule peut être 'null' mais ce n'est pas un problème car utilcode aura de valeur
    }
    return response;
  }
}