import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { UTILS } from 'utils/utils.js';

/**
 * View that creates a multiple label input from the
 * template passed.
 */
export class CWMultiLabel extends Backbone.View {

  selectionEnabled: boolean;
  required: boolean;
  avoidUniqueSelection: boolean;
  currentBloc: any;
  name: any;
  changeLabelCallback: any;
  parentView: any;
  withinParent: any;
  saisieMasse: any;
  titleSaisieMasse: any;
  width: any;
  canAccess: any;
  $div: JQuery;
  $label: JQuery;
  $icon: JQuery;
  $blocContainer: JQuery;
  $labelContainer: JQuery;
  labels: any[];
  comboAffichage: any;
  $labelSelector: JQuery;
  _filter: any;
  inSameRow: boolean;
  customPosition: { [key: string]: any };
  helpLabel: any;

  constructor(options: Backbone.ViewOptions | any) {
    options = options || {};
    options.tagName = "div";
    options.className = "phx-multiLabel";
    super(options);
    const events: { [key: string]: string } = {};

    this.model = new Backbone.Model();
    this.selectionEnabled = true;
    this.required = false;
    this.avoidUniqueSelection = false;
    //avoid Selector with one value.
    if (options.avoidUniqueSelection) {
      this.avoidUniqueSelection = options.avoidUniqueSelection;
    }
    //Template with the multiLabel HTML
    if (options.template) {
      this.template = options.template;
    }
    //Initial Selected Bloc
    if (options.initialBloc) {
      this.currentBloc = options.initialBloc;
    }
    //If we can change the label
    if (options.selectionEnabled) {
      this.selectionEnabled = options.selectionEnabled;
    }
    //Add required class to the label
    if (options.required) {
      this.required = options.required;
    }
    //Name of the label in the model
    if (options.name) {
      this.name = options.name;
    }
    //Function that will be called when change the label
    if (options.changeLabelCallback) {
      this.changeLabelCallback = options.changeLabelCallback;
    }
    //View that adds the MultiLabel component
    if (options.parentView) {
      this.parentView = options.parentView;
      //Force the compontent to be rendered inside the parent view
      if (options.withinParent) {
        this.withinParent = options.withinParent;
      }
    }
    //Options for paint component in saisie de masse
    if (options.saisieMasse) {
      this.saisieMasse = options.saisieMasse;
    }
    //Options for menu position
    if (options.customPosition) {
      this.customPosition = options.customPosition;
    }
    //Display label and fields in same row
    if (options.inSameRow) {
      this.inSameRow = options.inSameRow;
    }
    if (options.titleSaisieMasse) {
      this.titleSaisieMasse = options.titleSaisieMasse;
    }
    if (options.width) {
      this.width = options.width;
    }
    if (options.helpLabel) {
      this.helpLabel = options.helpLabel;
    }
    this.canAccess = null;
    //---------------EVENTS-------------    
    events["click ." + this.cid + ".phx-multiLabel-btn"] = "_showLabelsSelector";
    events["click ." + this.cid + ".phx-multiLabel-option"] = "_changeLabel";
    events["change .c-cwComboBoxView2__content .typesaisie"] = "_changeLabel";
    events["change .c-cwComboBoxView2__content .modesaisie"] = "_changeLabel";
    events["change .c-cwComboBoxView2__content .typeevenement"] = "_changeLabel";
    this.delegateEvents(events);
    //------------ END --EVENTS-------------    
  }

  render(): CWMultiLabel {
    this.$el.prop("viewRef", this as any);
    //Basic Component Template
    if (this.saisieMasse) {
      this.$div = $("<div class='typeCombo form-group'>");
      this.$label = $("<label>");
      this.$label.append(this.titleSaisieMasse);
      this.$div.append(this.$label);
    } else {
      this.$label = $("<label>");
    }
    if (this.required) {
      this.$label.addClass("cw-required");
    }
    this.$icon = $("<span class='" + this.cid + " phx-multiLabel-btn cw-multilabel_plusInfo'>" + UTILS.getSVGIcon("triangle_bas", "cw-icon--primary", 12) + "</span>");
    this.$blocContainer = $("<div class='cw-multiLabel-blocContainer'>").append(this.template);
    if (this.saisieMasse) {
      this.$el.append(this.$div);
      this.$el.append(this.$blocContainer);
    } else {
      this.$el.html([this.$label, this.$icon, this.$blocContainer] as any);
    }
    //Set the refView to the inputs
    this.$el.find(":input").prop("data-refview");
    //Create labels Array [{label,name,jquerySelector},...]
    this.labels = [];
    _.each(this.$el.find("div.cw-multiLabel-bloc"), (it): void => {
      //Save the label in Array
      $(it).addClass(this.cid);
      if (this.saisieMasse) {
        this.labels.push({ "code": $(it).data("bloc-label"), "libelle": $(it).data("bloc-name"), "jquerySelector": $(it) });
      } else {
        this.labels.push({ "label": $(it).data("bloc-label"), "name": $(it).data("bloc-name"), "jquerySelector": $(it) });
      }
    });
    if (this.inSameRow) {
      this.$el.addClass("d-flex align-items-center");
      this.$blocContainer.addClass("w-100 d-flex");
      this.$labelContainer = $("<div class='form-group col-auto cw-fieldset__forceCentered'></div>");
      this.$labelContainer.insertAfter(this.$label);
      this.$labelContainer.append(this.$label, this.$icon);
      this.$label.addClass("mb-0 phx-multiLabel-btn " + this.cid);
      this.$icon.addClass("mb-0");
    }
    if (this.labels.length > 0) {
      if (this.saisieMasse) {
        this._initializeCombo();
      }
      this._initializeLabelSelector();
      if (CWSTR.isBlank(this.currentBloc)) {
        this.currentBloc = _.first(this.labels);
      }
      this.showCurrentBloc();
    }
    if (!this.selectionEnabled || this.labels.length === 1) {
      this.disableSelector();
    }
    return this;
  }

  /**
   * Hide all blocs, set the the label of the curret selected bloc and show it.
   */
  showCurrentBloc(): void {
    //Hide all blocs
    this.$el.find("div." + this.cid + ".cw-multiLabel-bloc").addClass("d-none");
    if (this.saisieMasse) {
      this.comboAffichage.setItem(this.currentBloc);
    } else {
      //Set the selected Label
      this.$label.html(this.currentBloc.label);
      // Add icon help
      if (this.$el.find('.multiLabel-iconHelp').length === 0) {
        this.$label.after($('<span>').addClass('multiLabel-iconHelp').addClass('d-none').html(UTILS.getSVGIcon("aide_bulle", "", 16)));
      }
    }
    //Show the current selected Bloc
    this.currentBloc.jquerySelector.removeClass("d-none");
    if (this.filterLabels().length === 1) {
      this.disableSelector();
    }
  }

  getItem(): void {
    //nothig
  }

  setItem(): void {
    //Empty
  }

  /**
   * Set a new Label by his bloc name
   */
  setLabelByName(name: string): void {
    const callback = (it: { [key: string]: any }): boolean => {
      if (this.saisieMasse) {
        return it.libelle === name;
      } else {
        return it.name === name;
      }
    };
    const labelBloc = _.find(this.labels, callback);

    if (this.saisieMasse) {
      this._filterCombo();
    }
    if (labelBloc) {
      this.currentBloc = labelBloc;
      this.showCurrentBloc();
    } else {
      CWLOG.warn("No Label Name found");
    }
  }

  enableSelector(): void {
    this.selectionEnabled = true;
    if (this.avoidUniqueSelection === false) {
      this.$icon.show();
    }
  }

  disableSelector(): void {
    this.selectionEnabled = false;
    this.$icon.hide();
  }

  _initializeLabelSelector(): void {
    if (!this.$labelSelector) {
      this.$labelSelector = $("<ul class='phx-multiLabel-selector c-panneauMenu c-panneauMenu--noIcon'>");
      this._generateLabelSelectorOptions();
      // this.$labelSelector.menu();
      this.$labelSelector.hide();
      this.$label.after(this.$labelSelector);
    }
    else {
      this.$labelSelector.html("");
      this._generateLabelSelectorOptions();
    }
  }

  _initializeCombo(): void {
    this.comboAffichage = new CWComboBoxView2({
      enum: this.labels,
      name: this.name,
      required: true,
      width: this.width,
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.code;
      },
      // readonly: this.selectionEnabled
    });
    //this.$label.after(this.comboAffichage.render().el);
    this.$el.find(".typeCombo").append(this.comboAffichage.render().el);
    this.comboAffichage.enable(this.selectionEnabled);
  }

  _filterCombo(): void {
    this.comboAffichage.setFilter((response: any[]): any[] => {
      //return only unités checked
      return _.filter(response, (resp: { [key: string]: any }): boolean => {
        let found = false;

        _.each(this.filterLabels(), (elemArray: { [key: string]: any }): void => {
          if (elemArray.libelle === resp.attrs.libelle) {
            found = true;
          }
        });
        return found;
      });
    });
  }

  _generateLabelSelectorOptions(): void {
    const labelsFiltered = this.filterLabels();

    _.each(labelsFiltered, (it): void => {
      const $a = $("<a>").addClass(it.name).html(it.label);

      this.$labelSelector.append($("<li class='" + it.name + " " + this.cid + " phx-multiLabel-option c-panneauMenu__item'>").append($a));
    });
  }

  filterLabels(): any[] {
    let filtered = this.labels;

    if (this._filter) {
      filtered = this._filter(this.labels);
    }
    return filtered;
  }

  setFilter(filterCallback: (arg: { [key: string]: any }) => { [key: string]: any }): void {
    this._filter = filterCallback;
  }

  _showLabelsSelector(): void {
    if (this.$labelSelector.is(":visible")) {
      this.$labelSelector.hide();
    } else {
      if (this._canAccessMenu() === true) {
        const positionOptions: { [key: string]: any } = {
          my: "right top",
          at: "right-5 bottom+5",
          of: this.$icon
        };

        this._initializeLabelSelector();
        if (!CWSTR.isBlank(this.parentView) && this.withinParent) {
          positionOptions.collision = "flipfit";
          positionOptions.within = this.parentView.$el;
        }
        if (this.customPosition) {
          _.extend(positionOptions, this.customPosition);
        }
        this.$labelSelector.show().position(positionOptions);
        //If you click out of the menu, close the menu.
        $(document).one("mousedown", (event: JQuery.TriggeredEvent): void => {
          const element = this.$labelSelector.find(event.target);
          const icon = this.$icon.find(event.target);

          if (element.length === 0 && icon.length === 0) {
            this._showLabelsSelector();
          }
        });
      }
    }
  }

  _canAccessMenu(): boolean {
    if (!CWSTR.isBlank(this.canAccess)) {
      return this.canAccess;
    }
    return true;
  }

  setMenuAccess(access: boolean, onlyFirst?: boolean): void {
    this.canAccess = access;
    if (this.canAccess !== true) {
      if (onlyFirst === true) {
        this.$el.find(".phx-multiLabel-btn.cw-multilabel_plusInfo").hide();
      } else {
        this.$el.find(".phx-multiLabel-btn").hide();
        if (this.$labelContainer) {
          this.$labelContainer.hide();
        }
      }
    } else if (this.selectionEnabled) {
      this.$el.find(".phx-multiLabel-btn").show();
      if (this.$labelContainer) {
        this.$labelContainer.show();
      }
      if (onlyFirst === true) {
        this.$el.find(".phx-multiLabel-btn.cw-multilabel_plusInfo").hide();
      }
    }
  }

  _changeLabel(event: JQuery.TriggeredEvent): void {
    let selectedElement = event.target.className.split(" ")[0];

    if (this.saisieMasse) {
      this.currentBloc = _.find(this.labels, (item: { [key: string]: any }): boolean => {
        return (!CWSTR.isBlank(item.code) && String(item.code) === String(event.target["data-code"]));
      });
      if (!CWSTR.isBlank(this.currentBloc)) {
        selectedElement = this.currentBloc.libelle;
      }
    } else {
      this.currentBloc = _.find(this.labels, (item): boolean => {
        return (!CWSTR.isBlank(item.name) && item.name === selectedElement);
      });
    }
    //Trigger on component model a change event
    if (!CWSTR.isBlank(this.changeLabelCallback)) {
      this.changeLabelCallback.call(this.parentView, this.name, selectedElement);
    }
    //Perform Visual changes
    this.showCurrentBloc();
    if (!this.saisieMasse) {
      if (this.$labelSelector.is(":visible")) {
        this.$labelSelector.hide();
      }
    }
  }

  setReadOnly(isReadOnly: boolean): void {
    if (isReadOnly === true) {
      this.disableSelector();
    } else {
      this.enableSelector();
    }
  }
}
