import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

export class CWDialogModel extends CWBaseModel {

  canViewTreatment: boolean;
  matricule: string;
  sens: string
  fonction: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.canViewTreatment = true;
    if (options) {
      if (options.matricule) {
        this.matricule = options.matricule;
      }
      if (options && options.sens) {
        this.sens = options.sens;
      }
      if (options && options.fonction) {
        this.fonction = options.fonction;
      }
    }
    this.url = (): string => {
      return Configuration.restRoot + "/rest/badgvirtuel";
    }
  }
}
