import TPLListerecapResumePopUp from '../resumePopUp.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { i18n } from 'src/i18n';


export class CWResumePopUpView extends CWBaseFormView {

  public template: (params?: any) => string;
  private data: { [key: string]: any };
  public parent: CWDialogPopupView | any;

  constructor(options: { [key: string]: any }) {
    super(options);
    this.template = TPLListerecapResumePopUp;
    if (options) {
      this.data = options.data;
    }
    if (options.parent) {
      this.parent = options.parent;
    }

    this.listenTo(this, "fermer", this.clickListener);
  }

  private clickListener(action: string): void {
    switch (action) {
      case "fermer":
        this.parent.close();
        break;
      default:
    }
  }
  render(): this {
    const json = { 'i18n': i18n };
    this.$el.append(this.template(json));

    $(this.el).find(".NDemandesText").html(i18n.t('messages:GT_1219'));
    $(this.el).find(".numDemandes").html(this.data.nbDemandes);

    $(this.el).find(".resultatNDemandes").append(this.data.nbDemandes);

    $(this.el).find(".NDemandesAbandone").html(i18n.t('messages:GT_1220'));
    $(this.el).find(".numDemandesAbandone").html(this.data.nbDemandesAbandonee);

    $(this.el).find(".NDemandesError").html(i18n.t('messages:GT_1221'));
    $(this.el).find(".numDemandesError").html(this.data.nbErrors);

    return this;
  }
}
