import { STR } from 'utils/str.js';
import TPL_common_file_transfer_item from './file_transfer_item.tpl.html';

/**
 * Input field component used by the FileTransferView component
 */
export var FileTransferItemView = Backbone.View.extend({

  tagName: "div",
  className: "phx-file-transfer_item",

  events: {
    "click .add": "_add",
    "click .remove": "_remove",
    "change :input": "_inputChanged"
  },

  initialize: function(options) {
    this.template = TPL_common_file_transfer_item;

    this.model = new Backbone.Model();

    this.allowRemove = false;
    this.allowAdd = false;
    if (options && _.isBoolean(options.allowRemove)) {
      this.allowRemove = options.allowRemove;
    }
    if (options && _.isBoolean(options.allowAdd)) {
      this.allowAdd = options.allowAdd;
    }
  },

  render: function() {
    this.$el.html(this.template());

    if (this.allowRemove === true) {
      this.$el.find(":input").prepend("<br>");
    }
    this.$el.find(".add").hide();
    this.$el.find(".remove").hide();
    return this;
  },

  renderRefresh: function() {
    this.delegateEvents();

    return this;
  },

  setName: function(name) {
    this.$el.find(":input").attr("name", name);
  },

  setFileExtensions: function(data) {
    this.$el.find(":input").attr("accept", data);
  },

  hasValue: function() {
    try {
      var fileInfo = this.$el.find(":file").val();
      return !STR.isBlank(fileInfo);
    } catch (e) {
      return false;
    }
  },

  getFileName: function() {
    var fileInfo = this.$el.find(":file").val();
    //return [{name: fileInfo.replace(/^.*\\/, '')}];
    return fileInfo.replace(/^.*\\/, '');
  },

  _add: function() {
    this.model.trigger("add");
  },

  _remove: function() {
    this.model.trigger("remove");
  },

  _inputChanged: function() {
    var fileInfo = this.$el.find(":file").val();

    if (STR.isBlank(fileInfo)) {
      this.$el.find(".add").hide();
      this.$el.find(".remove").hide();
    } else {
      if (this.allowAdd === true) {
        this.$el.find(".add").show();
      }
      if (this.allowRemove === true) {
        this.$el.find(".remove").show();
      }
    }
  },

  remove: function() {
    try {
      Backbone.View.prototype.remove.call(this); // Remove view from DOM
      delete this.$el; // Delete the jQuery wrapped object variable
      delete this.el; // Delete the variable reference to this node
    } catch (e) {
      //nothing
    }
  }

});
