module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc" data-bloc-name="D" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.date')))==null?'':__t)+
'">\n    <div class="form-group">\n        <label for="dateDebD" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.datedebut_le') + " / " + i18n.t('common:gereractivites.datedebut')))==null?'':__t)+
'</label>\n        <!--<input type="text" class="datedeb typeDate" size="10">-->\n        <div class="c-date-selector input-group">\n            <input type="text" class="dateDebD datedeb typeDate form-control" size="10" />\n        </div>\n    </div>\n</div>\n\n<div class="cw-multiLabel-bloc" data-bloc-name="P" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.periode')))==null?'':__t)+
'">\n    <div class="multiLabelTypeSaisie">\n        <fieldset class="multiLabelTypeSaisie_form cw-fieldset">\n            <div class="d-flex">\n                <div class="form-group date clearfix">\n                    <label for="dateDebP" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.datedebut')))==null?'':__t)+
'</label>\n                    <div class="c-date-selector input-group">\n                        <input type="text" class="dateDebP datedeb typeDate periodStart form-control" periodId="1" size="10" /></input>\n                    </div>\n                </div>\n                <div class="form-group unite clearfix">\n                    <label for="modesai_periodeb.code">&nbsp;</label>\n                    <span class="comboUniteDeb"></span>\n                </div>\n                <div class="form-group heure clearfix heuredeb-container">\n                    <label for="heuredeb">&nbsp;</label>\n                    <input type="text" class="heuredeb typeHourMinuteNightly form-control" size="5">\n                    <span class="heuredeb-error-container error-container"></span>\n                </div>\n                <div class="form-group date clearfix">\n                    <label for="dateFinP" class="cw-required">\n                        '+
((__t=(i18n.t('common:gereractivites.datefin')))==null?'':__t)+
'</label>\n                    <div class="c-date-selector input-group">\n                        <input type="text" class="dateFinP datefin typeDate periodEnd form-control" periodId="1" size="10" /></input>\n                    </div>\n                </div>\n                <div class="form-group unite clearfix">\n                    <label for="modesai_periofin.code">&nbsp;</label>\n                    <span class="comboUniteFin"></span>\n                </div>\n                <div class="form-group heure clearfix heurefin-container">\n                    <label for="heurefin">&nbsp;</label>\n                    <input type="text" class="heurefin typeHourMinuteNightly form-control" size="5">\n                    <span class="heurefin-error-container error-container"></span>\n                </div>\n            </div>\n        </fieldset>\n    </div>\n    <div class="label-presencehorspresence-periode"></div>\n</div>\n<div class="cw-multiLabel-bloc" data-bloc-name="R" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.repetition')))==null?'':__t)+
'">\n    <!-- <div class="phx-line repetition date">\n        <label for="datedeb" class="required">'+
((__t=(i18n.t("common:gereractivites.datedebut")))==null?'':__t)+
'</label>\n        <input type="text" class="dateDebR datedeb typeDate periodStart" periodId="1" size="10">\n        <span class="datedeb-error-container"></span>\n    </div>\n    <div class="phx-line repetition date">\n        <label for="datefin" class="required">'+
((__t=(i18n.t('common:gereractivites.datefin')))==null?'':__t)+
'</label>\n        <input type="text" class="dateFinR datefin typeDate periodEnd" periodId="1" size="10">\n        <span class="datefin-error-container"></span>\n    </div> -->\n\n    <div class="row">\n        <div class="form-group repetition date">\n            <label for="dateDebR">'+
((__t=(i18n.t("common:gereractivites.datedebut")))==null?'':__t)+
'</label>\n            <div class="c-date-selector input-group">\n                <input type="text" class="dateDebR datedeb typeDate periodStart form-control" periodId="1" value="" size="10" />\n            </div>\n        </div>\n\n        <div class="form-group repetition date">\n            <label for="dateFinR">'+
((__t=(i18n.t("common:gereractivites.datefin")))==null?'':__t)+
'</label>\n            <div class="c-date-selector input-group">\n                <input type="text" class="dateFinR datefin typeDate periodEnd form-control" periodId="1" value="" size="10" />\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};
