module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-list-box-component">\r\n    <div class="phx-list-box-colonne form-group">\r\n        <div class="phx-list-box form-control">\r\n            <input type="text" class="list-box-search" placeholder="'+
((__t=( i18n.t('common:list_box.search')))==null?'':__t)+
'" style="display:none;" />\r\n            <div class="cwListBoxSelectContainer"></div>\r\n        </div>\r\n    </div>\r\n    <div class="phx-list-box-colonne">\r\n        <div class="extraControlsContainer">\r\n            <div class="cwListBoxSearchLoupe">\r\n                <span class="ui-icon-search ui-button-icon-primary ui-icon ui-icon-zoomin" data-state="false"></span>\r\n            </div>\r\n            <div class="cwListBoxSelectAllContainer">\r\n                <label class="phx-checkbox" title="'+
((__t=( i18n.t('common:list_box.select_all')))==null?'':__t)+
'">\r\n                    <input type="checkbox" class="select-all '+
((__t=(name))==null?'':__t)+
'" id="select-all">\r\n                    <span class="checkmark"></span>\r\n                </label>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>';
}
return __p;
};
