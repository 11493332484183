import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';


/**
 * Model for FormView
 */
export class CWProgressBarModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "value": null,
      "treatedSuccess": null,
      "max": null,
      "stop": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }
}
