import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLActivitetreesHierarchieTree from '../cwHierarchieTree.tpl.html';
import { CWActiviteColl } from 'common/evenements/planifier/activite/models/cwActivite.collection';
import { CWActiviteTreesViewType } from './../models/cwActivitetrees.workflow';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWBaseTreeView } from './cwBaseTree.view';
import { CWSTR } from 'utils/cwStr';
import { CWTree2View } from 'core/tree/cwTree2.view';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

export class CWHierarchieTreeView extends CWBaseTreeView {

  racineColl: CWActiviteColl;
  deployColl: any;
  showSelected: boolean;
  multiselect: boolean;
  hideMenuOptions: boolean;
  showExpandAllIcon: boolean;
  readOnly: boolean;
  isCheckedCallback: any;
  listRenderStyle: number;
  hideCheck: boolean;
  opennode: boolean;
  firstLevelModels: any;
  initSelectedModels: any;
  label: any;
  context: { [key: string]: any };


  /**
   * Constructor
   * Hierarchies Activites Tree
   */
  constructor(params?: { [key: string]: any }) {
    params = params || {};
    params.tagName = "span";
    params.className = "phx-activite-tree";

    params.events = _.extend({
      "click .phx-activite-tree-menuicon": "_toggleMenu",
      "click .phx-activite-tree-menucontent span": "_changeRenderer",
      "click .phx-activite-tree-menucontent li": "_changeRenderer"
    }, params.events);
    super(params);
    this.template = TPLActivitetreesHierarchieTree;
    if (!params) {
      throw new Error("Parameters are missing in the tree definition");
    }
    this.params = params;
    this.workflow = params.workflow;
    this.context = params.context;
    this.racineModel = params.racineModel;
    this.racineColl = params.racineColl;
    this.deployColl = params.deployColl;
    this.showSelected = true;
    if (!CWSTR.isBlank(params.showSelected)) {
      this.showSelected = params.showSelected;
    }
    this.model = new CWBaseModel({
      domaine: 0,
      value: null
    });

    //EVO 186: Multiselection mode, to show the checkboxes
    this.multiselect = false;
    if (params && !CWSTR.isBlank(params.multiselect)) {
      this.multiselect = params.multiselect;
    }
    //Show / hide labeling menu option
    this.hideMenuOptions = true;

    //Show / hide Expand / collapse all option
    this.showExpandAllIcon = true;
    if (params && params.showExpandAllIcon) {
      this.showExpandAllIcon = params.showExpandAllIcon;
    }

    //Checks are editable or not
    this.readOnly = false;
    if (params && params.readOnly) {
      this.readOnly = params.readOnly;
    }

    if (params && params.isCheckedCallback) {
      this.isCheckedCallback = params.isCheckedCallback;
    }

    this.model.on("change:domaine", this._setDomaineID, this);
    this.model.on("selectNode", this._selectNode, this);
    this.model.on("expandTreePath", this.expandTreePath, this);
    this.model.on("updateTreeNodeRecursive", this.updateTreeNodeRecursive, this);
    this.model.on("updateParentAfterDelete", this.updateParentAfterDelete, this);
    this.model.on("updateParentAfterDeleteNoSelect", this.updateParentAfterDeleteNoSelect, this);
    this.model.on("updateTreeNode", this.updateTreeNode, this);
    this.model.on("updateActiviteParents", this._updateActiviteParents, this);
    this.model.on("findExpandedParents", this.findExpandedParents, this);
    this.model.on("selectCorrectNodeOrFirst", this._selectCorrectNodeOrFirst, this);
    this.model.on("selectCorrectNodeAfterDup", this._selectCorrectNodeAfterDup, this);
    //		this.selection.on("add", this.workflow._treeValueChange, this.workflow);
    this.listRenderStyle = 0;
    this.hideCheck = false;
    if (!CWSTR.isBlank(params.hideCheck)) {
      this.hideCheck = params.hideCheck
    }
  }

  _obtainView(): string {
    return "HIERARCHIE";
  }
  _obtainCode(): string {
    return "";
  }
  _obtainLastParentView(): { [key: string]: any } {
    return {};
  }
  /**
   * Get renderer to generate a label for each node
   */
  _getRenderer(listRendererStyle?: number | string, classToApply?: string): (item: CWBaseModel) => string {
    let result = null;

    const domaineDetail = this.workflow.context.ctxDomaineDetail;
    const niveauxTypeHierarchy = this.workflow.context.ctxNiveauxTypeHierarchy;
    const listRenderer = !CWSTR.isBlank(listRendererStyle) ? listRendererStyle : this.listRenderStyle;
    let format = null;

    switch (String(listRenderer)) {
      case "0":
        result = (item: CWBaseModel): string => {
          let renderfunction = null;
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_hierarchie');
          } else if (item.get("typelt") === "S") {
            return item.get("libelleformat");
          } else { //Activites
            if (item.get("hiertypeniv") && !CWSTR.isBlank(item.get("hiertypeniv").niveau) && item.get("hiertypeniv").niveau !== 0) {
              let itemNiveau = null;
              if (this.workflow.hierarchie) {
                itemNiveau = this.workflow.hierarchie.findWhere({ niveau: item.get("hiertypeniv").niveau });
              } else {
                if (niveauxTypeHierarchy) {
                  itemNiveau = niveauxTypeHierarchy[item.get("hiertypeniv").niveau];
                }
              }
              if (itemNiveau) {
                let affichageClass = "";
                if (itemNiveau.get) { //is a model
                  affichageClass = itemNiveau.get("style").affichage;
                  renderfunction = this._getRenderer(parseInt(itemNiveau.get("format")), affichageClass);
                } else { //Is a object
                  affichageClass = itemNiveau.style.affichage;
                  renderfunction = this._getRenderer(parseInt(itemNiveau.format), affichageClass);
                }
                return renderfunction(item);
              } else {
                if (!CWSTR.isBlank(domaineDetail.get("fmtact"))) { //Default format for domaine
                  format = (!CWSTR.isBlank(domaineDetail.get("fmtact"))) ? domaineDetail.get("fmtact") : 1;
                  renderfunction = this._getRenderer(parseInt(format));
                  return renderfunction(item);
                } else {
                  return item.get("libelle") + " (" + item.get("code") + ")";
                }
              }
            } else if (!CWSTR.isBlank(domaineDetail.get("fmtact"))) { //Default format for domaine
              format = (!CWSTR.isBlank(domaineDetail.get("fmtact"))) ? domaineDetail.get("fmtact") : 1;
              renderfunction = this._getRenderer(parseInt(format));
              return renderfunction(item);
            } else {
              return item.get("libelle") + " (" + item.get("code") + ")";
            }
          }

        };
        break;
      case "1":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_hierarchie');
          } else {
            const spanItem = $("<span></span>");
            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("libelle") + " (" + item.get("code") + ")");
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "2":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_hierarchie');
          } else {
            const spanItem = $("<span></span>");
            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("code") + " (" + item.get("libelle") + ")");
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "3":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_hierarchie');
          } else {
            const spanItem = $("<span></span>");
            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("libelle"));
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "4":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_hierarchie');
          } else {
            const spanItem = $("<span></span>");
            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.append(item.get("code"));
            return spanItem[0].outerHTML;
          }
        };
        break;
      default:
        break;
    }
    return result;
  }

  /** Callback is a function to select the right node(from the previous view)
   *
   */
  _paintTree(calback?: () => void): void {
    //Gets the collection to build the tree
    //		var activiteColl = new ActiviteTreeColl(this.params);

    const activiteColl = this.deployColl.clone();

    activiteColl.setHabContext(this.workflow.getHabContext());

    this.opennode = !CWSTR.isBlank(this.tree) && !CWSTR.isBlank(this.tree.root.opennode) && this.tree.root.opennode === true ? true : false;
    this.close();

    //Create the tree
    this.tree = new CWTree2View({
      "showSelected": this.showSelected,
      "coll": null,
      "name": null,
      "draggable": true,
      "rootModel": this.racineModel,
      "manyRoots": true,
      "firstLevelModels": this.firstLevelModels,
      "buildUrlColl": (parentNiveau: number, node: CWBaseModel, collection: { [key: string]: any }): void => {
        collection.setNiveau(parentNiveau + 1);
        collection.setParentTypelt(node.get("typelt"));
        collection.setParentCode(node.get("code"));
        collection.setParentDateDebut(node.get("datedeb"));
        collection.setParentDateFin(node.get("datefin"));
        const domaineDetail = this.workflow.context.ctxDomaineDetail;
        collection.setDomaine(!CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domcode") : "");
        //Selecteur activite tooltip necessary data
        collection.setDomaineLibelle(!CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domlib") : "");
        collection.setContext(this.workflow.context.ctxFilterData);
        collection.setVue(2);
      },
      "dragAndDropCallback": (): void => {
        //Do nothing
      },
      "renderer": this._getRenderer(),
      "selectableNode": (model: CWBaseModel): boolean => {
        if (model.get("typelt") !== "A" || (!CWSTR.isBlank(model.get("indic_inapt")) && (model.get("indic_inapt") === "C" || model.get("indic_inapt") === "P"))) {
          return false;
        } else {
          const niveauxTypeHierarchy = this.workflow.context.ctxNiveauxTypeHierarchy;
          let levelHier;
          if (niveauxTypeHierarchy) {
            levelHier = niveauxTypeHierarchy[model.attributes.hiertypeniv.niveau];
          }
          //var levelHier = this.workflow.hierarchie.getByNiveau(model.attributes.hiertypeniv.niveau);
          if (levelHier !== undefined) {
            if (levelHier.saisieexp === true) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      },
      "tooltipRenderer": (model: CWBaseModel, view: { [key: string]: any }): void => {
        let text = "";
        let label = $(view.$el.find(".cw-treenode-label")[0]).html();
        const treeLabel = view.$el.find(".cw-treenode-label")[0];
        const tooltipIcon = " <span class=\"cw-tree-tooltip-icon\" title=\"\"></span>";
        let padding = 0;
        const competencePartielle = this._getCompetencePartielle(model);
        const couvertureCertifications = this._getCouvertureCertifications(model);
        const $lPosTooltiCpheck = view.$el.find("div.cw-treenode-check-container");
        let inpatitudeComplete: string = null;
        let inpatitudePartielle: string = null;
        let tooltip: JQuery = null, tooltipCheck: JQuery = null, datedeb = "", datefin = "";

        //add tooltip icon
        //Avoid aplying not selectionnable IHM style to familles
        if ($(treeLabel).hasClass("ui-phx-ihm-non-selectionnable")) {
          $(treeLabel).removeClass("ui-phx-ihm-non-selectionnable");
          if (model.get("code") !== " ") {
            label = "<span class='ui-phx-ihm-non-selectionnable'>" + label + "</span>";
          }
        }
        if (competencePartielle !== "" || couvertureCertifications !== "") {
          padding = 25;
        }
        inpatitudeComplete = this._getInaptitudeComplete(model, padding);
        inpatitudePartielle = this._getInaptitudePartielle(model, padding);
        if (inpatitudeComplete !== "" || inpatitudePartielle !== "") {
          padding += 25;
        }
        if (padding > 0) {
          label = "<span style=\"padding-left: " + padding + "px\">" + label + "</span>";
        }
        if (!CWSTR.isBlank(model.get("rattachement")) && model.get("rattachement").length > 1) {
          let first = true;

          for (let i = 0; i < model.get("rattachement").length; i++) {
            if (first === false) {
              text += "</br></br>";
            }
            if (model.get("rattachement")[i].datedeb !== model.get("datedeb")) {
              //normal
              text += this._paintTooltipPeriod(model.get("rattachement")[i]);
              //Include chemin at tooltip
              text += "  " + this._createCheminLibelle(model.get("rattachement")[i].libelle);
            } else {
              //Painting current structure
              //bold current node
              text += "<b>" + this._paintTooltipPeriod(model) + "</b>";
              //Include chemin at tooltip
              text += "  " + this._createCheminLibelle(model.get("rattachement")[i].libelle);
            }
            first = false;
          }
          if (this.context?.ctxModeRepresentation === "pop-up") {
            view.$el.find(".cw-treenode-label").first().html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label + tooltipIcon);
            tooltip = view.$el.find(".cw-tree-tooltip-icon").first().tooltip({ content: "" });
            tooltip.tooltip("option", "content", text);
            if (model.get("feuille") === true) {
              $lPosTooltiCpheck.append(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + tooltipIcon);
              tooltipCheck = $lPosTooltiCpheck.find(".cw-tree-tooltip-icon").first().tooltip({ content: "" });
              tooltipCheck.tooltip("option", "content", text);
            }
          } else {
            view.$el.find(".cw-treenode-label").first().html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label);
          }
        } else {
          const dated = CWSTR.isBlank(model.get("datedeb")) ? CWTYPE.DATE.INITIAL : model.get("datedeb");
          const datef = CWSTR.isBlank(model.get("datefin")) ? CWTYPE.DATE.INFINITY : model.get("datefin");

          if ((dated > this.params.workflow.context.ctxPeriodeGestion.datedeb && datef < this.params.workflow.context.ctxPeriodeGestion.datefin) ||
            datef < this.params.workflow.context.ctxPeriodeGestion.datefin ||
            dated > this.params.workflow.context.ctxPeriodeGestion.datedeb) {
            datedeb = CWTYPE.DATE.format(dated);
            datefin = CWTYPE.DATE.format(datef);
            if ((dated > CWTYPE.DATE.INITIAL && datef < CWTYPE.DATE.INFINITY)) {
              datedeb = CWTYPE.DATE.format(model.get("datedeb"));
              datefin = CWTYPE.DATE.format(model.get("datefin"));
            } else if (datef < CWTYPE.DATE.INFINITY) {
              datedeb = "... ";
              datefin = CWTYPE.DATE.format(datef);
            } else if (dated > CWTYPE.DATE.INITIAL) {
              datedeb = CWTYPE.DATE.format(dated);
              datefin = "  ...";
            }
            text += "[" + datedeb + " - " + datefin + "]";
            if (this.context?.ctxModeRepresentation === "pop-up") {
              view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label + tooltipIcon);
              tooltip = view.$el.find(".cw-tree-tooltip-icon").tooltip({ content: "" });
              tooltip.tooltip("option", "content", text);
              if (model.get("feuille") === true) {
                $lPosTooltiCpheck.append(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + tooltipIcon);
                tooltipCheck = $lPosTooltiCpheck.find(".cw-tree-tooltip-icon").tooltip({ content: "" });
                tooltipCheck.tooltip("option", "content", text);
              }
            } else {
              view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label);
            }
          } else {
            view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label);
          }
        }
      },
      "checkPeriode": (model: CWBaseModel, context: { [key: string]: any }): boolean => {
        let lRtn = true;

        //S'il n'y a pas d'information des périodes, il ne devrait rien changer-> Il retournera true 
        if (model && model instanceof Backbone.Model && context && !CWSTR.isBlank(context.ctxPeriodeDebut) && !CWSTR.isBlank(context.ctxPeriodeFin)) {
          if (model.get("datedeb") > context.ctxPeriodeDebut || context.ctxPeriodeFin > model.get("datefin")) {
            lRtn = false;
          }
        }
        return lRtn;
      },
      "multiselect": this.multiselect,
      "checkedColl": this.initSelectedModels,
      "readOnly": this.readOnly,
      "isCheckedCallback": this.isCheckedCallback,
      "hideCheck": this.hideCheck,
      "hieractivitidad": this.workflow.hierarchie,
      "context": this.context,
      "view": CWActiviteTreesViewType.HIERARCHIE,
      "expandedNodes": this.workflow.context.ctxFromSelecteurDactivites ? this.workflow.context.ctxFromSelecteurDactivites : false,
    });
    this.tree.hierarchieFetchNodeOrFirst = false;
    this.tree.setSortFunction(this._sortTree);
    this.tree.setColl(activiteColl);
    this.tree.model.on("nodeSelected", this._setSelection, this);
    this.tree.model.on("updatedTreeNodeCollection", this.checkSelectionableLeafts, this);
    this.model.off("expandPath");
    this.model.on("expandPath", this.tree.expandPath, this.tree);
    //		this.tree.model.on("nodeSelectedWithCtrlKey", this._setSelectionWithCtrlkey, this);
    this.$el.find(".phx-activite-tree-tree").html(this.tree.el);
    this.tree.render();
    this.setAffichage(this.listRenderStyle);
    if (!CWSTR.isBlank(this.opennode) && this.opennode === true) {
      this.tree.root.opennode = true;
    }
    if (!CWSTR.isBlank(this.tree.context) && this.tree.context.forcedSelectNodeOrFirst === true) {
      this.tree.hierarchieFetchNodeOrFirst = true;
    }
    //expand ficticious node
    this.tree.expandRoot((): void => {
      this._selectCorrectNodeOrFirst();
    });
    if (calback) {
      calback();
    }
  }

  /**
   * This function is called then the tree is rendered or after create a new activity.
   * This function select the current value (it opens its path or
   */
  _selectCorrectNodeOrFirst(shouldReload?: boolean): void {
    this.workflow.trigger("selectLastSelectedOrFirst", shouldReload, this._obtainView());
  }

  render(callback?: () => void): this {

    const json = { i18n: i18n, label: this.label };

    $(this.el).html(this.template(json));

    //remove all the events
    this.undelegateEvents();
    //Restart events
    this.delegateEvents();

    // paint tree
    const helpRacineColl = this.racineColl.clone();
    //		var helpRacineColl = new ActiviteColl(parameters);
    helpRacineColl.domaine = this.params.domaine;
    helpRacineColl.vue = 2;
    const domaineDetail = this.workflow.context.ctxDomaineDetail;

    if (!CWSTR.isBlank(domaineDetail)) {
      helpRacineColl.domaine = domaineDetail.get("domcode");
      helpRacineColl.setContext(this.workflow.context.ctxFilterData);
      helpRacineColl.setHabContext(this.workflow.getHabContext());
      //this.firstLevelModels= new ActiviteTreeModel();
      helpRacineColl.fetch({
        success: (fresh: { [key: string]: any }): void => {
          //Obtain activites without family
          if (fresh.models && fresh.models.length > 0) {
            this.checkSelectionableLeafts(fresh);
            this.racineModel = this.initializeRacineModel(helpRacineColl.models[0]);
            this.racineModel.set("code", "");
            this.racineModel.firstLevelColl = helpRacineColl;
            this.firstLevelModels = [];
            _.each(helpRacineColl.models, (model: { [key: string]: any }): void => {
              if (CWSTR.isBlank(model.get("code"))) { //Hors regroupement
                model.set("code", " ");
                model.set("libelle", i18n.t('activite.hors_hierarchie'));
              }
              model.vue = 2;
              model.niveau = 1;
              this.firstLevelModels.push(model);
            });
            this.workflow.trigger("tree:activitiesNumber", fresh.models.length);
            this._paintTree(callback);
          } else {
            //No results found initialize empty tree
            this.firstLevelModels = [];
            this.racineModel = this.initializeRacineModel(helpRacineColl.models[0]);
            this.racineModel.set("code", "");
            this.racineModel.firstLevelColl = helpRacineColl;
            //					this.selection.trigger("add",null);
            this.model.set("value", null);
            this.workflow.trigger("tree:activitiesNumber", 0);
            this._paintTree(callback);
          }
        },
        reset: true
      });
    }
    //		this.model.rendered=true;
    //Build the menu
    if (!this.hideMenuOptions) {
      const menuContent = $(".phx-activite-tree-menucontent", this.el);
      menuContent.menu();
      menuContent.hide();
    } else {
      $(this.el).find(".phx-activite-tree-dlgresultheader").hide();
      $(this.el).find(".phx-activite-tree-menucontent").hide();
    }

    return this;
  }

  /**
   *
   */
  setAffichage(style: number, repaintTree?: boolean): void {
    $(".phx-activite-tree-opt1", this.el).removeClass("ui-state-active");
    $(".phx-activite-tree-opt2", this.el).removeClass("ui-state-active");
    $(".phx-activite-tree-opt3", this.el).removeClass("ui-state-active");
    $(".phx-activite-tree-opt4", this.el).removeClass("ui-state-active");

    switch (style) {
      case 1:
        this.listRenderStyle = 1;
        $(".phx-activite-tree-opt1", this.el).addClass("ui-state-active");
        break;
      case 2:
        this.listRenderStyle = 2;
        $(".phx-activite-tree-opt2", this.el).addClass("ui-state-active");
        break;
      case 3:
        this.listRenderStyle = 3;
        $(".phx-activite-tree-opt3", this.el).addClass("ui-state-active");
        break;
      case 4:
        this.listRenderStyle = 4;
        $(".phx-activite-tree-opt4", this.el).addClass("ui-state-active");
        break;
      default:
        break;
    }

    if (this.tree) {
      this.tree.setRenderer(this._getRenderer());
      if (repaintTree !== false) {
        this.tree.root.repaintLabel(this._getRenderer());
      }
      this.trigger("rendererChanged");
    }
  }

  /**
   * In order to implement the button to select the way to render the tree
   */
  _changeRenderer(event: { [key: string]: any }): boolean {
    switch (event.target.className.split(" ")[0]) {
      case "phx-activite-tree-opt1":
        this.setAffichage(1);
        break;
      case "phx-activite-tree-opt2":
        this.setAffichage(2);
        break;
      case "phx-activite-tree-opt3":
        this.setAffichage(3);
        break;
      case "phx-activite-tree-opt4":
        this.setAffichage(4);
        break;
      default:
        break;
    }

    return false;
  }

  //Method to close and open the menu.
  _toggleMenu(): void {
    const menuContent = $(".phx-activite-tree-menucontent", this.el);
    if (menuContent.is(":visible")) {
      menuContent.hide();
    } else {
      const menuBtn = $(".phx-activite-tree-menuicon", this.el);
      menuContent.show().position({
        my: "right top",
        at: "right bottom",
        of: menuBtn
      });

      //If you click out of the menu, close the menu.

      $(document).one("mousedown", (event: { [key: string]: any }) => {
        const element = $(this.el).find(event.target);
        if (element.length === 0) {
          this._toggleMenu();
        }
      });
      //If you click in one option, the menu is closed
      $(document).one("mouseup", (event: { [key: string]: any }) => {
        const element = $(this.el).find(event.target);
        if (element.length > 0 && event.target.className.split(" ")[0] !== "phx-activite-tree-menuicon") {
          this._toggleMenu();
        }
      });
    }
  }

  getCheckedRows(): { [key: string]: any } {
    let lRtn = null;

    if (this.tree && this.tree.getCheckedRows) {
      lRtn = this.tree.getCheckedRows();
    }
    return lRtn;
  }

  setCheckedRows(selectedModels: { [key: string]: any }): void {
    this.initSelectedModels = selectedModels;
    if (this.tree) {
      this.tree.setCheckedRows(selectedModels);
    }
  }

  clearCheckedRows(): void {
    if (!CWSTR.isBlank(this.tree)) {
      this.tree.clearCheckedRows();
    }
  }
}
