import { CWJauge, CWJaugeOptions, JaugeBarValue } from 'src/core/components/jauge/cwJauge.view';
import { CWSTR } from 'src/utils';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n';
import { Model, ViewOptions } from 'Backbone';



export interface DreulantCompteur {
  libelle: string;
  valeur: string;
  code: string;
  datevalo: string;
  typevalo: string;
}

export interface CWDeroulantJaugeOptions extends ViewOptions<Model> {
  isNegative: boolean;
  compteur: DreulantCompteur;
  dateRef: string;
  showBar?: boolean;
  barValue?: JaugeBarValue;
}

export class CWDeroulantJauge extends CWJauge {
  private _isNegative: boolean;
  private _dateValo: string;
  private _typevalo: string;
  private _dateRef: string;
  constructor(deroulantJaugeOptions: CWDeroulantJaugeOptions) {
    const baseOptions: CWJaugeOptions = {
      id: deroulantJaugeOptions.compteur.code,
      value: deroulantJaugeOptions.compteur.valeur,
      showBar: deroulantJaugeOptions.showBar,
      barValue: deroulantJaugeOptions.barValue
    }
    baseOptions.className = baseOptions.className || "c-jauge c-jauge-deroulant align-items-end col-3";
    super(baseOptions);
    this.title = deroulantJaugeOptions.compteur.libelle;
    this.datevalo = deroulantJaugeOptions.compteur.datevalo;
    this.isNegative = deroulantJaugeOptions.isNegative;
    this.typevalo = deroulantJaugeOptions.compteur.typevalo;
    this.datevalo = deroulantJaugeOptions.compteur.datevalo;
    this.dateref = deroulantJaugeOptions.dateRef;
  }
  get dateref(): string {
    return this._dateRef;
  }

  set dateref(value: string) {
    this._dateRef = value;
    this.refreshTootlTip();
    this.refreshComponent();
  }

  get datevalo(): string {
    return this._dateValo;
  }

  set datevalo(value: string) {
    this._dateValo = value;
    this.refreshTootlTip();
    this.refreshComponent();
  }

  get typevalo(): string {
    return this._typevalo;
  }

  set typevalo(value: string) {
    this._typevalo = value;
    this.refreshTootlTip();
    this.refreshComponent();
  }

  get isNegative(): boolean {
    return this._isNegative;
  }

  set isNegative(value: boolean) {
    this._isNegative = value;
    this.refreshComponent();
  }

  private renderDateValo(): void {
    const dateValoContainer = $(this.el).find(".c-jauge__circle__break");
    const value = $(this.el).find(".c-jauge__circle__break__text").empty();

    dateValoContainer.append(value);
    if (this._dateValo && !CWSTR.isBlank(this.datevalo) && this.datevalo !== this.dateref) {
      const dateFormated = CWTYPE.DATE.format(this.datevalo, CWTYPE._getFormatByCode("DATE_A"));
      value.append(i18n.t('common:jauge.auMaj') + " " + dateFormated);
      if (!this._isNegative) {
        dateValoContainer.addClass("ui-phx-ihm-texte-donnees-alternatif");
        return;
      }
      return;
    }
    value.addClass("c-jauge__emptyVal");
    value.append(i18n.t('common:jauge.auDefault'));
  }

  protected refreshComponent(): void {
    if (this.isRendered) {
      //Value negative
      if (this._isNegative) {
        $(this.el).addClass("c-jauge-val-negative");
      }
      else {
        $(this.el).removeClass("c-jauge-val-negative");
      }
      $(this.el).find(".c-jauge-inner__content").empty();
      $(this.el).find(".c-jauge-inner__content").append(this.renderValue());
      $(this.el).find(".c-jauge-inner__content").append(this.renderTitle());
      this.renderDateValo();
      $(this.el).append(this.renderBar());
      this.renderTootlTip();
    }
  }

  protected renderValue(): JQuery {
    const returned = $("<div>").addClass("c-jauge__val");
    if (typeof this.value === "string") {
      for (let i = 0; i < this.value.length; i++) {
        const charVal = this.value.charAt(i);
        if (!isNaN(parseInt(charVal))) {
          const valNumber = $("<span>").addClass("c-jauge__val__number").append(charVal);
          returned.append(valNumber);
        } else {
          const valNumber = $("<span>").addClass("c-jauge__val__ascii").append(charVal);
          returned.append(valNumber);
        }
      }
    }
    else {
      returned.append(this.value);
    }
    return returned;
  }


  protected refreshTootlTip(): void {
    const dateFormated = CWTYPE.DATE.format(this.datevalo, CWTYPE._getFormatByCode("DATE_A"));
    if (this._typevalo === "J") {
      if (this._dateValo && this.datevalo !== this.dateref && !CWSTR.isBlank(dateFormated)) {
        this.tooltip = $('<span>').append(i18n.t('common:jauge.valeurJournee') + " " + i18n.t('common:jauge.du') + " " + dateFormated);
      } else {
        this.tooltip = i18n.t('common:jauge.valeurJournee');
      }
    } else if (this._typevalo === "P") {
      if (this._dateValo && this.datevalo !== this.dateref && !CWSTR.isBlank(dateFormated)) {
        this.tooltip = $('<span>').append(i18n.t('common:jauge.valeurPeriodique') + " " + i18n.t('common:jauge.au') + " " + dateFormated);
      } else {
        this.tooltip = i18n.t('common:jauge.valeurPeriodique');
      }
    }
    else {
      this.tooltip = null;
    }
  }
}
