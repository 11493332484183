module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="cw-fieldset phx-populations-detail-structure-simple">\n    <div class="">\n        <div id="selectorEstructura"></div>\n        <br><br>\n        <div class="form-group">\n            ';
 if(context && context.ctxStructureSelpop){
__p+='\n                <div class="custom-control custom-checkbox phx-chemin-population-descendence '+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 1 )?'d-none':''))==null?'':__t)+
'" >                    \n                <input id="cw-populationDescendence" class="populationDescendence custom-control-input" type="checkbox" '+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 2 )?'checked':''))==null?'':__t)+
' '+
((__t=((context.ctxSelRemDesc == 2 || context.ctxSelRemDesc == 3 )?'':'disabled'))==null?'':__t)+
' value="true">\n                <label for="cw-populationDescendence" class="custom-control-label"> '+
((__t=( i18n.t("populationsmenu.returnerEgalement")))==null?'':__t)+
'</label>\n                </div>\n            ';
}
__p+='\n        </div>\n        <div class="">\n            <button type="button" class="buttonAppliquer btn btn-primary" style="float:left; margin-left:12px;">'+
((__t=(i18n.t("populationsmenu.appliquer")))==null?'':__t)+
'</button>\n        </div>\n    </div>\n</fieldset>\n';
}
return __p;
};
