module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="dialogPersoSaveCriteria row">\n  <div class="col-lg-12">\n    <div class="form-group">\n    <span class="cw-titreBloc">'+
((__t=( description ))==null?'':__t)+
'</span>\n    </div>\n    <div class="section-own">\n      ';
 if(showSubs === true) { 
__p+='\n      <div class="subdescription form-group">\n        <span class="cw-texteNormal" style="font-weight: normal !important;">'+
((__t=( userSubdescription ))==null?'':__t)+
'</span>\n      </div>\n      ';
 } 
__p+='\n      <div class="options" style="padding-left: 12px">\n        <div class="bloq_user form-group">\n          <div class="custom-control custom-checkbox">\n            <input id="aff_user" type="checkbox" class="aff_user custom-control-input" name="aff_user" value="1">\n            <label for="aff_user" class="custom-control-label font-weight-bold">'+
((__t=( userLabel ))==null?'':__t)+
'</label>\n          </div>\n        </div>\n        <div class="bloq_allusers form-group">\n          <div class="custom-control custom-checkbox">\n            <input id="aff_allusers" type="checkbox" class="aff_allusers custom-control-input" name="aff_allusers" value="1">\n            <label for="aff_allusers" class="custom-control-label font-weight-bold">'+
((__t=( userAllLabel ))==null?'':__t)+
'</label>\n          </div>\n        </div>\n      </div>\n    </div>\n\n    ';
 if(showGeneral === true) { 
__p+='\n    <div class="section-general">\n      ';
 if(showSubs === true) { 
__p+='\n      <div class="subdescription form-group">\n        <span class="cw-texteNormal" style="font-weight: normal !important;">'+
((__t=( i18n.t('agenda.dialog_persosavecriteria_subdescutil_habcon') ))==null?'':__t)+
'</span>\n      </div>\n      ';
 } 
__p+='\n      <div class="options" style="padding-left: 12px">\n        <div class="bloq_util form-group">\n          <div class="custom-control custom-checkbox">\n            <input id="aff_util" type="checkbox" class="aff_util custom-control-input" name="aff_util" value="1">\n            <label for="aff_util" class="custom-control-label font-weight-bold">'+
((__t=( i18n.t('agenda.dialog_persosavecriteria_affutil_habcon') ))==null?'':__t)+
'</label>\n          </div>\n        </div>\n        <div class="bloq_allequip form-group">\n          <div class="custom-control custom-checkbox">\n            <input id="aff_allequip" type="checkbox" class="aff_allequip custom-control-input" name="aff_allequip" value="1">\n            <label for="aff_allequip" class="custom-control-label font-weight-bold">'+
((__t=( i18n.t('agenda.dialog_persosavecriteria_affallequip_habcon') ))==null?'':__t)+
'</label>\n          </div>\n        </div>\n      </div>\n    </div>\n    ';
 } 
__p+='\n\n  </div>\n</div>\n';
}
return __p;
};
