import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWListerEvtGenWorkflow } from './cwListerEvtGen.workflow';
import { CWSTR } from 'utils/cwStr';


export class CWComboStatutListerEvtGenCollection extends CWCombosColl {


  workflow: CWListerEvtGenWorkflow;

  constructor(models?: CWBaseModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(models, options);
    this.workflow = options.workflow;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    const index = [];
    if (!CWSTR.isBlank(this.workflow) && !CWSTR.isBlank(this.workflow.context) && !CWSTR.isBlank(this.workflow.context.ctxValideur) && this.workflow.context.ctxValideur) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].code !== "D" && response[i].code !== "I") {
          index.push(response[i].code);
        }
      }
    }
    if (!CWSTR.isBlank(index)) {
      for (let i = 0; i < index.length; i++) {
        for (let j = 0; j < response.length; j++) {
          if (response[j].code === index[i]) {
            response.splice(j, 1);
            break;
          }
        }
      }
    }
    return response;
  }
}