import _ from 'underscore';
import TPLGererActivitesDeplacement from '../cwGererActivites-deplacement.tpl.html';
import TPLGereractivitesMultiLabelModeSaisie from '../cwMultiLabel-modeSaisie.tpl.html';
import TPLGereractivitesMultiLabelModeSaisieSaisieMasse from '../cwMultiLabel-modeSaisie-saisiemass-mode.tpl.html';
import TPLGereractivitesMultiLabelTypeEvenement from '../cwMultiLabel-typeEvenement.tpl.html';
import TPLGereractivitesMultiLabelTypeEvenementSaisieMasse from '../cwMultiLabel-typeEvenement-saisiemass-mode.tpl.html';
import TPLGereractivitesMultiLabelTypeSaisie from '../cwMultiLabel-typeSaisie.tpl.html';
import TPLGereractivitesMultiLabelTypeSaisieSaisieMasse from '../cwMultiLabel-typeSaisie-saisiemass-mode.tpl.html';
import { CWACTIVITE } from 'utils/cwActivite';
import { CWActiviteDeplacemnetPersistenceModel } from '../models/cwActiviteDeplacementPersistence.model';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWCompteDeplacementModel } from '../models/cwComptesDeplacement.model';
import { CWFORMS } from 'utils/cwForms';
import { CWGererActivitesDeplacementModel } from '../models/cwGererActivitesDeplacement.model';
import { CWGererActiviteView } from './cwGererActivite.view';
import { CWMSGS } from 'utils/cwMsgs';
import { CWMultiLabel } from 'core/views/cwMultiLabel';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';
import { SelecteurActiviteItemModel } from 'common/selecteuractivites/models/selecteurActiviteItem.model.js';
import { UTILS } from 'utils/utils.js';


/**
 * GERER ACTIVITE DEPLACEMENT VIEW
 */
export class CWGererActiviteDeplacementView extends CWGererActiviteView {

  constructor(options?: ViewOptions<Model> | any) {
    options = options || {};
    super(options);
    this.templateDeplacement = TPLGererActivitesDeplacement;
    this._setUpDeplacementMode();
    this.workflow.cwGererActivitesView = this;
    if (this.context && !this.context.ctxSaisieMasse) {
      this.listenTo(this.workflow, "change:defaultActiviteValues", this._cleanDefaultValidationErrors);
    }
  }

  /**
    * @implements CWGererActiviteView.typeofModel
    */
  typeofModel(): typeof CWGererActivitesDeplacementModel {
    return CWGererActivitesDeplacementModel;
  }

  _setUpDeplacementMode(): void {
    this._getModel().deplacementMode = true;
  }

  protected _getCommonContext(): { [key: string]: any } {
    const commonContext: { [key: string]: any } = super._getCommonContext();

    if (this.context.ctxModeInitialisation === "Ajout") {
      commonContext.ctxUnique = true;
    }
    commonContext.ctxDeplacement = true;
    if (CWSTR.isBlank(this.comboTypeDeplacement)) {
      this.comboTypeDeplacement = new CWComboBoxView2({
        autocomplete: true,
        ws: new CWCombosColl({
          urlMine: Configuration.restRoot + "/rest/ls/divcod/typededeplacement?court=true"
        }),
        habContext: this.model.getHabContext(),
        required: true,
        name: "typedeplac.code"
      });
    }
    if (CWSTR.isBlank(this.comboCompte)) {
      this.comboCompte = new CWComboBoxView2({
        autocomplete: true,
        ws: new CWCombosColl({
          urlMine: Configuration.restRoot + "/rest/ls/ventilationcptana"
        }),
        habContext: this.model.getHabContext(),
        name: "cptdeplac.code"
      });
    }
    return commonContext;
  }

  showAdvertisment(event: JQueryEventObject): void {
    if ((event.target as any).value.length > 0) {
      this.$el.find(".msg-avertissement").show();
    } else {
      this.$el.find(".msg-avertissement").hide();
    }
  }

  /**
   * @implements CWGererActiviteView._initModeConsultation
   */
  _initModeConsultation(activite: CWGererActivitesDeplacementModel, callback: () => void): void {
    const gererActivite = this._getModel();

    gererActivite.off("invalid");
    this.$el.find(".msg-avertissement").hide();//cacher le message si l'il est visible
    if (this.context.ctxActionsPossibles.indexOf("Dupliquer") === -1 && this.context.ctxEcran === "mdepl") {
      this.$el.find(".c-form--fixedButtons").removeClass("c-form--fixedButtons");
      this.btnBar.setDroit("copy", false);
    }
    this.btnBar.model.set("mode", "R", { silent: true });
    this.btnBar.model.trigger("change:mode");
    this.btnBar.hideAllBtnBar();
    activite.setHabContext(this.model.getHabContext());
    activite.id = this.context.ctxIdentifiantEvt;
    activite.avecbesoin = this.context.ctxActiviteBesoin;
    activite.action = "deplacement";
    activite.fetch({
      success: (fresh: any): void => {
        const afterCombosCallback = (): void => {
          this.actTypeSaisieOptionsColl.activite = fresh.get("activite");
          //Refresh activite default values for the id
          this.actDefaults.id = fresh.get("activite");
          this.actDefaults.hierid = fresh.get("hierid");
          this.actDefaults.itemid = fresh.get("straid");
          this.actDefaults.fetch({
            success: (freshDef: any): void => {
              // activité non gérée dans un récapitulatif
              if (fresh.get("recapitulatif") && CWSTR.isBlank(fresh.get("recapitulatif").code)) {
                if ((fresh.get("typeevenement") === "P" && freshDef.get("soumiswkf_p")) || (fresh.get("typeevenement") === "R" && freshDef.get("soumiswkf_r"))) {
                  // activite gérée en workflow : commentaire
                  this.$el.find(".blockCommentaire").show();
                } else {
                  // activité non gérée en workflow : pas de commentaire
                  this.$el.find(".blockCommentaire").hide();
                }
              }
              gererActivite.set("carpers", freshDef.get("carpers"));
              fresh.set("indication", freshDef.get("indication"));
              this.setDefaultActiviteValues(gererActivite, freshDef, freshDef.get("valdef_impose"), true);
              this.actTypeSaisieOptionsColl.fetch({
                success: (): void => {
                  this.actModeSaisieOptionsColl.activite = fresh.get("activite");
                  this.actModeSaisieOptionsColl.fetch({
                    success: (): void => {
                      const afterEtatFinalCallback = (): void => {
                        fresh.set("dateDebD", fresh.get("datedeb"));
                        fresh.set("dateDebP", fresh.get("datedeb"));
                        fresh.set("dateDebR", fresh.get("datedeb"));
                        fresh.set("dateFinP", fresh.get("datefin"));
                        fresh.set("dateFinR", fresh.get("datefin"));
                        fresh.store();
                        this._renderDeroulantLabels(null);
                        this._initCombos();
                        if (this.selectActRealisee) {
                          this.$el.find(".refActreal").html(this.selectActRealisee.render().el);
                        }
                        this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
                        this.model.set("value", fresh); // This will trigger a _mapToForm
                        this.model.store();
                        this.original = fresh.clone();
                        this._initWKFHisto();
                        this._showDateConsultee();
                        this._showIntitule();
                        this._showTypeStatusTitle();
                        this._showRecapitulatif();
                        this._manageCheckAtribAuto();
                        // After the consultation of an activity :
                        // If there are more than 1 type of events
                        // CtxTypeEvtGere show the type of events. and a new button is shown. (menu)
                        if (this.context.ctxTypeEvtGere.length > 1) {
                          this.btnBar.model.trigger("disable:new");
                          // custom new button only when required.
                          this._createMenuOptions();
                        } else {
                          this.btnBar.model.trigger("enable:new");
                        }
                        this._manageActionsPossibles();
                        this._manageHabilitation();
                        this._managePieceJointe();
                        // Evo 378 valider
                        this._manageButonValider(fresh, (): void => {
                          this._initSelecteurDates();
                          this._showIndicateurEvenamentMessage();
                          this._updateVisibiliteOrigine();
                          if (callback) {
                            callback();
                          }
                          //Mettre toujours à la fin
                          this._manageRestrictions(fresh, this.isEditable);
                          this.$el.find(".gereractivite-form-inputs").off("focusin");
                          if (!this.desactiveHabOuEtat) {
                            this.$el.find(".gereractivite-form-inputs").one("focusin", (event: JQuery.TriggeredEvent): void => {
                              this._checkModeSilentModifyActGest(fresh, (peutModifier: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }): void => {
                                this.infoErrorModify = {
                                  "isModifiable": peutModifier,
                                  "error": infoError,
                                  "response": infoResponse,
                                  "options": {}
                                };
                                //Pièces jointes
                                if (this.pieceJointe) {
                                  this.pieceJointe.setOmitAction(false);//Dans ce point, l'action doit être activée
                                }
                                this._actionPrevClickActivite();
                                if (!this.desactiveHabOuEtat) {
                                  if (this._showMessageModifyActGest()) {
                                    const lModel = this.model?.get("value");

                                    this._manageRestrictions(lModel, this.infoErrorModify?.isModifiable);
                                    this._MessageModifyActGest(event);
                                  } else {
                                    this.timer = window.setInterval(() => {
                                      if (!UTILS.checkHidder(this.context.ctxEcran)) {
                                        if (!(event.target.viewRef instanceof CWComboBoxView2)) {
                                          event.target.click();
                                        }
                                        if (this.timer) {
                                          window.clearInterval(this.timer);
                                          this.timer = null;
                                        }
                                      }
                                    }, 300);
                                  }
                                }
                              });
                            });
                          }
                        });
                      };

                      if (!CWSTR.isBlank(activite.get("recapitulatif")) && !CWSTR.isBlank(activite.get("recapitulatif").code)) {
                        //get valideur and valideur final
                        this._getEtatFinal(activite, afterEtatFinalCallback);
                      } else {
                        if (afterEtatFinalCallback) {
                          afterEtatFinalCallback();
                        }
                      }
                      //Map data to deplacement model
                      this.mapActiviteToDeplacementModel(activite);
                      this.mapDeplacementModelToForm();
                      this._changeHourInterventionDeplacement();
                    }
                  });
                }
              });
            }
          });
        };

        this._enableForm();
        // activite dans un récapitulatif : pas de commentaire
        if (fresh.get("recapitulatif") && !CWSTR.isBlank(fresh.get("recapitulatif").code)) {
          this.$el.find(".blockCommentaire").hide();
        }
        this._updateCombosForActivite(fresh, afterCombosCallback);
      }
    });
    if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxEcran !== "mactivites") {
      this._setTitleImbrique(null);
    }
  }

  mapActiviteToDeplacementModel(activite: { [key: string]: any }): void {
    this._getModel().set("happel", (!_.isNaN(parseInt(activite.get('happel')))) ? parseInt(activite.get('happel')) : '');
    this._getModel().set("hdebinter", (!_.isNaN(parseInt(activite.get('hdebinter')))) ? parseInt(activite.get('hdebinter')) : '');
    this._getModel().set("hfininter", (!_.isNaN(parseInt(activite.get('hfininter')))) ? parseInt(activite.get('hfininter')) : '');
    this._getModel().set("dureeinter", (!_.isNaN(parseInt(activite.get('dureeinter')))) ? parseInt(activite.get('dureeinter')) : '');
    this._getModel().set("typedeplac", (!_.isEmpty(activite.get('typedeplac'))) ? activite.get('typedeplac') : { "code": "", "libelle": "" });
    this._getModel().set("cptdeplac", (!_.isEmpty(activite.get('cptdeplac'))) ? activite.get('cptdeplac') : { "code": "", "libelle": "" });
  }

  /**
   * ["Creer","Supprimer","Modifier","Valider","Dupliquer"]
   */
  _manageActionsPossibles(): void {
    super._manageActionsPossibles();
    if (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Consultation") {
      this.btnBar.model.trigger("hide:copy");
    }
  }

  /**
   * @implements CWGererActiviteView._setTitlePopUp
   */
  _setTitlePopUp(headerNew?: boolean): void {
    let title = "";
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);// Typologie
    const genre = typologie.get("genre");
    const indic: { [key: string]: any } = null;
    const $div = $('<div>').addClass("titleAndEtiquette");
    const $span = $('<span class="typeActivite">');

    // Nouvelle
    if ((headerNew === true) || (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length >= 1 && _.contains(this.context.ctxActionsPossibles, "Creer") && this.context.ctxModeInitialisation === "Ajout")) {
      if (genre === "F") {
        title += i18n.t('common:gereractivites.nouvelle_title_F') + " ";
      } else if (genre === "M") {
        title += i18n.t('common:gereractivites.nouvelle_title_M') + " ";
      }
    }
    title += CWACTIVITE.getTypologyLabel(this.context.ctxTypologieActivite, 1, indic, false, false);
    $span.html(CWSTR.toFirstUpperCase(title.toUpperCase()));
    if (this.context.ctxModeInitialisation === "Ajout") {
      $span.append($('<span>').addClass('gereractivites-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereractivites.creation_en_cours'))));
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      $span.append($('<span>').addClass('gereractivites-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(String(" - ").concat(i18n.t('common:gereractivites.modification_en_cours'))));
    }
    $div.append($span);
    $div.append($("<span>").addClass("c-titleHistoWkf"));
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.parent.isTitleHTML = true;
      this.parent._setTitle($div);
    } else {
      $(this.$el.parent().parent().parent()).find(".l-panelB-entete .typeActivite").remove();
      $(this.$el.parent().parent().parent()).find(".l-panelB-entete").append($span);
    }
  }

  /**
   * @implements CWGererActiviteView._showTypeStatusTitle
   */
  _showTypeStatusTitle(): void {
    let $layout: JQuery = null;

    if (this.context.ctxModeRepresentation === "pop-up") {
      $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');
    } else {
      $layout = this.parent.layout ? $(this.parent.layout.el).find(".l-panelB-buttonContainer") : this.$el.find(".l-panelB-buttonContainer");
    }
    if (!CWSTR.isBlank($layout)) {
      $layout.hide();
    }
    this._paintWorkflowStatus($layout);
    this._paintGenereEtiquette($layout);
  }

  setInitialDeplacementValues(): void {
    this._getModel().set({ "datedeb": this.context.ctxValeursCreation.ActDateDebut || this.context.ctxValeursCreation.ActiviteDatedebut });
    this.mapDeplacementModelToForm();
  }

  /**
   * @implements CWGererActiviteView.render
   */
  render(): this {
    const json = {
      "i18n": i18n,
      "UTILS": UTILS,
      "deplacement": true
    };

    this.$el.empty();
    this.$el.html(this.templateDeplacement(json));
    // MultiLabels
    this._renderDeroulantLabels(null);
    this._initCombos();
    CWFORMS.setDatepicker(this, ".date-intervention");
    CWFORMS.setDatepicker(this, ".comptabilise");
    this.setInitialDeplacementValues();
    this._renderDeplacementDateAppel();
    // Combos
    this.$el.find(".comboUniteDeb").html(this.comboUniteDeb.render().el);
    this.$el.find(".comboUniteFin").html(this.comboUniteFin.render().el);
    this.$el.find(".comboUnites").html(this.comboUnites.render().el);
    this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
    this.$el.find(".comboTypedeplac").html(this.comboTypeDeplacement.render().el);
    this.$el.find(".cptdeplac").html(this.comboCompte.render().el);
    this.$el.find(".gereractivites-detail").css("min-width", this.minWidth);
    // contruct valid dates
    if (this._getModel().get("modesaisie") === "PERIOC") {
      this._InitDatesPeriode();
    }
    // Dates    
    CWFORMS.setDatepicker(this, ".dateDebD");
    CWFORMS.setDatepicker(this, ".dateDebP");
    CWFORMS.setDatepicker(this, ".dateFinP");
    CWFORMS.setDatepicker(this, ".dateDebR");
    CWFORMS.setDatepicker(this, ".dateFinR");
    //Flag all elements are renderd, so setContext can show/hide correctly
    this.isRendered = true;
    //Context
    if (this.context) {
      this.setContext(this.context);
    }
    //Dialog Position
    if (this.parent && this.parent.dialog) {
      this.parent.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
      this.parent.dialog.dialog("option", "width", "760px");
    }
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.trigger("manageUnselected", false);
    }
    //la dernière action------------------------------
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.listenTo(this.collTypoDeplac, "fetch:success", this.afterFetchListerTypoDom);
    } else {
      this.afterFetchListerTypoDom();
    }
    return this;
  }

  _renderDeroulantLabels(action: any): void {
    let json = null;
    let requiredValue = true;
    let tplTypeEvenemet = null;
    let typologie;
    let evenement = "";
    let actprev = "";
    let actreal = "";
    let evtLength = 0;
    let avoidUnique = false;
    let tplTypeSaisie = null;
    const callBackFilterTypeSaisie = (resp: { [key: string]: any }): boolean => {
      let found = false;

      if (!CWSTR.isBlank(this.actTypeSaisieOptionsColl) && this.actTypeSaisieOptionsColl.models.length > 0) {
        _.each(this.actTypeSaisieOptionsColl.models, (typeSaisie: { [key: string]: any }): void => {
          if (this.context && this.context.ctxSaisieMasse === true) {
            if (typeSaisie.get("code") === resp.libelle) { found = true; }
          } else {
            if (typeSaisie.get("code") === resp.name) { found = true; }
          }
        });
      } else {
        found = true;
      }
      return found;
    };
    const callBackFilterTypeEvent = (resp: { [key: string]: any }): boolean => {
      let found = false;

      if (!CWSTR.isBlank(this.context.ctxTypeEvtGere) && this.context.ctxTypeEvtGere.length > 0) {
        _.each(this.context.ctxTypeEvtGere, (typeEvenement: string): void => {
          let lib = "";

          if (this.context && this.context.ctxSaisieMasse === true) {
            lib = resp.libelle[0];
          } else {
            lib = resp.name[0]
          }
          if (typeEvenement === lib) {
            found = true;
          }
        });
      } else {
        found = true;
      }
      return found;
    };
    let tplModeSaisie = null;
    const callBackFilterModeSaisie = (resp: { [key: string]: any }): boolean => {
      let found = false;

      if (!CWSTR.isBlank(this.actModeSaisieOptionsColl) && this.actModeSaisieOptionsColl.models.length > 0) {
        _.each(this.actModeSaisieOptionsColl.models, (modeSaisie: { [key: string]: any }): void => {
          if (this.context && this.context.ctxSaisieMasse === true) {
            if (modeSaisie.get("code") === resp.libelle) { found = true; }
          } else {
            if (modeSaisie.get("code") === resp.name) { found = true; }
          }
        });
      } else {
        found = true;
      }
      return found;
    };

    if (this.context && this.context.ctxSaisieMasse === true) {
      tplTypeEvenemet = TPLGereractivitesMultiLabelTypeEvenementSaisieMasse;
    } else {
      tplTypeEvenemet = TPLGereractivitesMultiLabelTypeEvenement;
    }
    if (action === "revert") {
      typologie = GLOBAL_DATA.typologies.get(this._getModel().get("typologie").code);
    } else {
      typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);
    }
    if (typologie) {
      evenement = typologie.get("evenement");
      // we want evenement in uppercase (first letter).
      if (!CWSTR.isBlank(evenement)) {
        evenement = evenement.substr(0, 1).toUpperCase() + evenement.substr(1);
      }
    }
    actprev = evenement;
    actreal = evenement;
    json = { "i18n": i18n, "actprev": actprev, "actreal": actreal };
    if (this.context && this.context.ctxSaisieMasse) {
      json = { "i18n": i18n, "actprev": i18n.t('common:gereractivites.prevu'), "actreal": i18n.t('common:gereractivites.realise') };
      requiredValue = false;
    }
    evtLength = this.context.ctxTypeEvtGere.length;
    avoidUnique = evtLength === 1 ? true : false; // we dont show selector when there is only 1 value.
    this.typeEvenement = new CWMultiLabel({
      template: tplTypeEvenemet(json),
      required: requiredValue,
      avoidUniqueSelection: avoidUnique,
      titleSaisieMasse: "&nbsp;",
      name: "typeevenement",
      changeLabelCallback: this._changeMultiLabel,
      parentView: this,
      withinParent: true,
      saisieMasse: this.context.ctxSaisieMasse
    });
    this.$el.find(".typeEvenement").html(this.typeEvenement.render().el);
    json = { "i18n": i18n, "actprev": actprev, "actreal": actreal };
    if (this.context && this.context.ctxSaisieMasse) {
      tplTypeSaisie = TPLGereractivitesMultiLabelTypeSaisieSaisieMasse;
    } else {
      tplTypeSaisie = TPLGereractivitesMultiLabelTypeSaisie;
    }
    this.typeSaisie = new CWMultiLabel({
      template: tplTypeSaisie(json),
      required: requiredValue,
      name: "typesaisie",
      titleSaisieMasse: i18n.t('common:gereractivites.periodicite'),
      changeLabelCallback: this._changeMultiLabel,
      parentView: this,
      saisieMasse: this.context.ctxSaisieMasse
    });
    this.$el.find(".typeSaisie").html(this.typeSaisie.render().el);
    //Filter typeSaisie
    this.typeSaisie.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, callBackFilterTypeSaisie);
    });
    //Filter typeEvenement
    this.typeEvenement.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, callBackFilterTypeEvent);
    });
    if (this.context && this.context.ctxSaisieMasse === true) {
      tplModeSaisie = TPLGereractivitesMultiLabelModeSaisieSaisieMasse;
    } else {
      tplModeSaisie = TPLGereractivitesMultiLabelModeSaisie;
    }
    this.modeSaisie = new CWMultiLabel({
      template: tplModeSaisie(json),
      required: requiredValue,
      name: "modesaisie",
      titleSaisieMasse: i18n.t('common:gereractivites.modesaisie'),
      changeLabelCallback: this._changeMultiLabel,
      parentView: this,
      saisieMasse: this.context.ctxSaisieMasse
    });
    this.$el.find(".modeSaisie").html(this.modeSaisie.render().el);
    //Filter modeSaisie
    this.modeSaisie.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, callBackFilterModeSaisie);
    });
  }

  _changeHourInterventionDeplacement(): void {
    const soit = this._calculateDureeDeplacement(this._getModel().get("hdebinter"), this._getModel().get("hfininter"));
    let textAux = "";

    this._getModel().set("dureeinter", soit);
    this.$el.find("fieldset.gereractivites-detail-form .duree-container").show();
    textAux = this._nextDayDuree(this._getModel().get("hdebinter"), this._getModel().get("hfininter"));
    if (!_.isEmpty(textAux)) {
      this.$el.find("fieldset.gereractivites-detail-form .data-result-text").show();
    } else {
      this.$el.find("fieldset.gereractivites-detail-form .data-result-text").hide();
    }
    this.$el.find("fieldset.gereractivites-detail-form .data-result").val(textAux);
    this.$el.find("fieldset.gereractivites-detail-form input.dureeinter").val(CWTYPE.DURATION.HOUR_MINUTE_24.format(this._getModel().get("dureeinter")));
    CWFORMS.autosizeInput(/*el*/);
  }

  _calculateDureeDeplacement(mheuredeb: string, mheurefin: string): number {
    let duree = 0;
    const heuredeb = parseInt(mheuredeb, 10);
    const heurefin = parseInt(mheurefin, 10);
    const heuredebBool = !CWSTR.isBlank(mheuredeb);
    const heurefinBool = !CWSTR.isBlank(mheurefin);

    if (heuredebBool === true && heurefinBool === true && (Number(mheuredeb) !== 0 || Number(mheurefin) !== 0)) {
      if (heuredeb > heurefin) {
        duree = this._calculateDuree(String(heuredeb), String(heurefin + 2400))
      } else {
        duree = this._calculateDuree(String(heuredeb), String(heurefin))
      }
    }
    return duree;
  }


  /**
   * Map the Model to the form, perform some data managements befor call
   * the FormView default MapModelToForm function.
   * @implements CWGererActiviteView._mapToForm
   */
  _mapToForm(): void {
    let buttons = null;

    if (this.$el) {
      const gererActivite = this._getModel();

      if (CWSTR.isBlank(gererActivite)) {
        this.$el.find(".gereractivite-form-inputs").hide();
        this._hideGrilleHoraireMessage();
      } else {
        const $fieldset = this.$el.find(".gereractivite-content");
        const renderers = {};//Renderers
        let collabInput: string = null;

        this.$el.find(".gereractivite-form-inputs").show();
        gererActivite.setHabContext(this.model.getHabContext());
        if (gererActivite.get("grillehoraire") === true) {
          this._showGrilleHoraireMessage();
          if (this.context.ctxEcran !== "planmed") {
            this.context.ctxActionsPossibles = [];
          }
        } else {
          this._hideGrilleHoraireMessage();
        }
        // Error management deplacement
        gererActivite.set('dateDebD', gererActivite.get('datedeb'));
        gererActivite.off("invalid");
        //gererActivite.on("invalid", this._showValidationErrorsDeplacement, this);
        gererActivite.on("invalid", this._showValidationErrors, this);
        // Clear current validation erros
        this._cleanValidationErrors();
        // Visibility
        this._panelStateRender(/*gererActivite*/);
        // Manage legend
        this._manageLegend();
        if (this.context && this.context.ctxSaisieMasse) {
          buttons = this.$el.find("#activitecollab_btnbar").detach();
        }
        //Dynamic Data
        this._loadInformationsComplementairesDynamic(this._getModel().get("carpers"));
        if (this.context && this.context.ctxSaisieMasse) {
          this.$el.find("div.gereractivite-form-inputs div.gereractivites-infocomp").append(buttons);
          this._setHeureDeb();
          this._setHeureFin();
        }
        //Initialize proghoraire checks
        this._initializeCheckAtrinbAuto();
        // Fix to display libelle and matricule in referentiel collaborateurs

        if (this.context.ctxSaisieMasse === true && !CWSTR.isBlank(gererActivite.get("matricule"))) {
          collabInput = this.$el.find('.cmbCollaborateurs input').val() as string;
        }
        // Map model to the form
        // 4º parameteravoids linking labels, manage manually the datepickers problem
        this._mapModelToForm($fieldset, gererActivite, renderers, true);
        this.mapDeplacementModelToForm();
        // Fix to display libelle and matricule in referentiel collaborateurs
        if (this.context.ctxSaisieMasse === true) {
          this.$el.find('.cmbCollaborateurs input').val(collabInput);
          // we call to set the height to the table if the activite has many characteristics
          this.trigger("setTableScroll");
        }
        // Manage Delorants Labels
        this._manageMultiLabels();
        //Manual Label Linking
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeEvenement"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".modeSaisie"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".gereractivites-infocomp"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='D']"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='P']"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='R']"));
        //Set reliquat	and presence
        this.modeSaisie.$el.find(".horspresence").prop("checked", true);
        this.modeSaisie.$el.find(".presence").prop("checked", true);
        // Calculate Soit
        this._manageSoitValue(gererActivite.get("heuredeb"), gererActivite.get("heurefin"));
        // Map the Activite Referentiel
        if (gererActivite.get("activite")) {
          const item = new SelecteurActiviteItemModel({
            "code": gererActivite.get("activite"),
            "libelle": gererActivite.get("libelle"),
            "hierid": gererActivite.get("hierid"),
            "structid": gererActivite.get("structid"),
            "itemid": gererActivite.get("straid"),
            "datedeb": gererActivite.get("datedeb"),
            "datefin": gererActivite.get("datefin"),
            "parentdomcode": CWSTR.getElValue(gererActivite, "domaine.code")
          });

          // Realisee
          if (this.selectActRealisee) {
            this.selectActRealisee.addValue(item);
          }
        } else if (this.selectActRealisee) {
          this.selectActRealisee.clearSelection();
          this.selectActRealisee.clean();
        }
        this._manageUniteDeb(CWSTR.getElValue(gererActivite, "modesai_periodeb.code"));
        this._manageUniteFin(CWSTR.getElValue(gererActivite, "modesai_periofin.code"));
        this._manageHorsPresence(CWSTR.getElValue(gererActivite, "modesai_periodeb.code"));
        this._manageHorsPresence(CWSTR.getElValue(gererActivite, "modesai_periofin.code"));
        this._manageModeSaisie();
        this._enableModeSaisie();
        //Display information label about horspresence
        this._manageLabelPresenceHorsPresence(gererActivite.get("presence"), gererActivite.get("horspresence"), gererActivite.get("typehorspresence"));
        if (this.context.ctxModeRepresentation === "pop-up") {
          this._setTitlePopUp(null);
        }
        this._enableValuesActivite();
        if (this.actDefaults && typeof this.actDefaults.get("valdef_impose") === "boolean" && this.actDefaults.get("valdef_impose") === true) {
          this._blockDefaultValues(this.defaultMaps);
        }
      }
      if (this.actModeSaisieOptionsColl.length === 1) {
        this.modeSaisie.setMenuAccess(false);
      }
      if (this.actTypeSaisieOptionsColl.length === 1) {
        this.typeSaisie.setMenuAccess(false);
      }
      if (!CWSTR.isBlank(gererActivite.get("activite"))) {
        this.manageIndicationVisibility(gererActivite);
      }
    }
  }

  mapDeplacementModelToForm(): void {
    const objTypeDeplac = { code: (!_.isEmpty(this._getModel().get("typedeplac")) ? this._getModel().get("typedeplac").code : ''), "libelle": (!_.isEmpty(this._getModel().get("typedeplac")) ? this._getModel().get("typedeplac").libelle : '') };
    const objCompte = { code: (!_.isEmpty(this._getModel().get("cptdeplac")) ? this._getModel().get("cptdeplac").code : ''), "libelle": (!_.isEmpty(this._getModel().get("cptdeplac")) ? this._getModel().get("cptdeplac").libelle : '') };

    this.$el.find(".happel").val(CWTYPE.HOUR_MINUTE.format(this._getModel().get("happel")));
    this.$el.find(".datedeb").val(CWTYPE.DATE.format(this._getModel().get("datedeb"), CWTYPE._getFormatByCode("DATE_A")));
    this.$el.find(".dtdebinter").val(CWTYPE.DATE.format(this._getModel().get("dtdebinter"), CWTYPE._getFormatByCode("DATE_A")));
    this.$el.find(".hdebinter").val(CWTYPE.HOUR_MINUTE.format(this._getModel().get("hdebinter")));
    this.$el.find(".hfininter").val(CWTYPE.HOUR_MINUTE.format(this._getModel().get("hfininter")));
    this.comboTypeDeplacement.setItem(objTypeDeplac);
    this.comboCompte.setItem(objCompte);
    this._renderDeplacementDateAppel()
  }

  _renderDeplacementDateAppel(): void {
    if (!CWSTR.isBlank(this._getModel().get("datedeb")) && !CWSTR.isBlank(this._getModel().get("dtappel")) && this._getModel().get("datedeb").toString() !== this._getModel().get("dtappel").toString()) {
      this.$el.find(".dtappel-container-text").show();
      this.$el.find(".dtappel-container").val(CWTYPE.DATE.format(this._getModel().get("dtappel"), CWTYPE._getFormatByCode("DATE_A")));
      CWFORMS.setFieldReadonly(this.$el.find(".dtappel-container"), true);
    } else {
      this.$el.find(".dtappel-container-text").hide();
      this.$el.find(".dtappel-container").val('');
    }
  }

  _showValidationErrorsDeplacement(): void {
    const originalModelErrors = (this._getModel().validationError && this._getModel().validationError.errors) ? _.extend({}, this._getModel().validationError.errors) : {};
    const errorsDeplacement = _.extend({}, originalModelErrors, {});

    this._showValidationErrors(null, { "errors": errorsDeplacement, "errorValidation": "" });
  }

  /**
   * The View Custom change event, peform logics when the inputs change his value and
   * performs put the new values on the current model with the FormView _change function.
   * @implements CWGererActiviteView._changeView
   */
  _changeView(event: JQueryEventObject, data: any): void {
    const gererActivite = this._getModel();
    const className = event.target.className.split(" ")[0];
    let errors = 0;
    let lTypeEvt;
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);

    this._displaySubtitle();
    // Avoid default Change Logic, apply custom change logic
    switch (className) {
      case "unitedeb.code":
        this._change(event, data);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);

        this._manageCheckAtribAuto();
        break;
      case "commentaire":
      case "complement":
        this._change(event, data);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        if (_.isString(this._getModel().get("complement")) && this._getModel().get("complement").length > 0) {
          this.$el.find(".msg-avertissement").show();
        } else {
          this.$el.find(".msg-avertissement").hide();
        }
        break;
      case "actprev":
      case "actreal":
        //Do nothing selecteuractivite manages this changes alone
        break;
      case "dtdebinter":
        this._change(event, data, true);
        this._changeHourInterventionDeplacement();
        this._addDayDifference();//mise à jour "dtfininter"     
        //mise à jour de la date d'appel
        if (parseInt(this._getModel().get('hdebinter')) < parseInt(this._getModel().get("happel"))) {
          this._getModel().set({ "dtappel": this._getModel().get('dateDebD') });
        } else {
          this._getModel().set({ "dtappel": this._getModel().get('dtdebinter') });
        }
        //mis a jour de l'heure d'appel
        this._change({ target: this.$el.find(".happel").get(0) }, data, true);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        this._manageComptesDeplacement();
        break;
      case "hdebinter":
      case "hfininter": {
        const lModeCtxt = (this.context.ctxModeInitialisation === "Ajout" || this.context.ctxModeInitialisation === "Consultation");

        this._change(event, data, true);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        this._changeHourInterventionDeplacement();
        this._manageDtdebinter(lModeCtxt, className === "hdebinter");
        if (className === "hdebinter") {
          this._change({ target: this.$el.find(".dtdebinter").get(0) }, data, true);
          this._change({ target: this.$el.find(".happel").get(0) }, data, true);
        }
        if (className !== "hfininter") {
          this._manageComptesDeplacement();
        }
      }
        break;
      case "dateDebD":
      case "dateDebP":
      case "dateDebR":
      case "datedeb":
        if (className !== "datedeb") {
          this._getModel().set({ "datedeb": CWTYPE.DATE.parse((event.target as any).value).val }, { silent: true });
        }
        this._change(event, data, true);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        this._getModel().set({ "dtappel": CWTYPE.DATE.parse((event.target as any).value).val }, { silent: true });
        this._manageComptesDeplacement();
        this.mapDeplacementModelToForm();
        break;
      case "dateFinP":
      case "dateFinR":
      case "datefin":
        if (className !== "datefin") {
          this._getModel().set({ "datefin": CWTYPE.DATE.parse((event.target as any).value).val }, { silent: true });
        }
        this._change(event, data);
        break;
      case "typedeplac.code":
        this._change(event, data);
        this._manageComptesDeplacement();
        if (!_.isEmpty(this._getModel().get("typedeplac"))) {
          let valLibelle = "";
          const codeTdep = this._getModel().get("typedeplac").code;

          if (!CWSTR.isBlank(codeTdep) && !_.isEmpty(this.comboTypeDeplacement.coll.get(codeTdep))) {
            valLibelle = this.comboTypeDeplacement.coll.get(codeTdep).get("libelle");
          }
          this._getModel().get("typedeplac").libelle = valLibelle;
        }
        break;
      case "happel":
      case "cptdeplac.code":
        if (className === "happel") {
          this._change(event, data, true);
          errors = CWFORMS.countErrors(gererActivite.validationError.errors);

          if (CWSTR.isBlank(this._getModel().get("happel"))) {
            this._getModel().set({ "dtappel": "" });
          } else {
            if (parseInt(this._getModel().get('hdebinter')) < parseInt(this._getModel().get("happel"))) {
              this._getModel().set({ "dtappel": this._getModel().get('dateDebD') });
            } else {
              this._getModel().set({ "dtappel": this._getModel().get('dtdebinter') });
            }
          }
          this.mapDeplacementModelToForm();
        } else {
          let valLibelle = "";

          this._change(event, data);
          if (!CWSTR.isBlank(data) && !_.isEmpty(this.comboCompte.coll.get(data))) {
            valLibelle = this.comboCompte.coll.get(data).get("libelle");
          }
          this._getModel().get("cptdeplac").libelle = valLibelle;
        }
        break;
      case "typeevenement":
        lTypeEvt = data === i18n.t('common:gereractivites.realise') ? "R" : "P";
        this._updateHabilitationForChangeTypeEvt(this._getHabilitationDuContext(lTypeEvt, "Gestion"));
        break;
      case "matricule":
        this._change(event, data);
        if (!CWSTR.isBlank(this.context) && this.context.ctxEcran === "saisiemass" && !CWSTR.isBlank(gererActivite.validationError) && CWSTR.isBlank(gererActivite.validationError.errors.matricule) && Object.keys(gererActivite.validationError.errors).length <= 1) {
          CWFORMS.cleanErrors($(this.el));
        }
        break;
      default:
        // FormView Default Change call
        this._change(event, data);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        break;
    }
    if (typologie.get('typeGestionActivite') === "REPOBLIG") {
      this._getModel().set('gentrt', false);
    }
    if (errors > 0) {
      let errorList = null;

      this._cleanValidationErrors();
      errorList = gererActivite.validationError.errors;
      if (this.context.ctxTypeActivite === "REPOBLIG" || typologie.get('typeGestionActivite') === "REPOBLIG" && this.context.ctxModeInitialisation === "Ajout") {
        for (const key of Object.keys(errorList)) {
          if (key !== className) {
            delete errorList[key]
          }
        }
      }
      if (!_.isEmpty(errorList)) {
        CWFORMS.showErrors(this.$el, errorList);
      }
      switch (className) {
        case "modesai_periodeb.code":
          this._manageUniteDeb(data);
          this._manageHorsPresence(data);
          break;
        case "modesai_periofin.code":
          this._manageUniteFin(data);
          this._manageHorsPresence(data);
          break;
      }
    } else {
      let datedeb = null, datefin = null;

      // Addition default change Logic
      switch (className) {
        case "modesai_periodeb.code":
          this._manageUniteDeb(data);
          this._manageHorsPresence(data);
          break;
        case "modesai_periofin.code":
          this._manageUniteFin(data);
          this._manageHorsPresence(data);
          break;
        case "dateDebD":
        case "dateDebP":
        case "dateDebR":
        case "datedeb":
          if (gererActivite.get("datedeb") === "D") {
            datedeb = gererActivite.get("datedeb");
          }
          datedeb = gererActivite.get("datedeb");
          datefin = gererActivite.get("typesaisie") === "D" ? gererActivite.get("datedeb") : gererActivite.get("datefin");
          if (((gererActivite.get("typesaisie") === "P") || (this.context && this.context.ctxSaisieMasse === true && gererActivite.get("typesaisie") === "R")) && datefin <= datedeb) { // if the start date is changed, update end date
            const dateAux1 = CWTYPE.DATE.strToDate(datedeb);
            const fieldset = this.$el.find(".multiLabelTypeSaisie_form");

            if (gererActivite.get("typesaisie") === "P") {
              if (!CWSTR.isBlank(dateAux1)) {
                UTILS.addDays(dateAux1, 1);
              }
            }
            datefin = CWTYPE.DATE.dateToStr(dateAux1, CWTYPE.DATE.DEFAULT_MASK_BBDD);
            gererActivite.set("datefin", datefin);
            this.$el.find(".datefin").val(CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode("DATE")));
            this._cleanValidationErrors();
            delete gererActivite.validationError.errors.datefin;
            this._setHeureDeb();
            this._setHeureFin();
            this._mapModelToForm(fieldset, this._getModel());
          }
          if (this.selectActRealisee) {
            this.selectActRealisee.setCollDates(datedeb, datefin);
          }
          if (CWSTR.isBlank(this._getModel().validationError) || CWSTR.isBlank(this._getModel().validationError.errors[className])) {
            this._updateCombosForActivite(gererActivite);
          }
          if (UTILS.isIE()) {
            $(event.target.parentElement).find(".ui-datepicker-trigger").focus();
          }
          break;
        case "dateFinP":
        case "dateFinR":
        case "datefin":
          datedeb = gererActivite.get("datedeb");
          datefin = gererActivite.get("typesaisie") === "D" ? gererActivite.get("datedeb") : gererActivite.get("datefin");
          if (this.selectActRealisee) {
            this.selectActRealisee.setCollDates(datedeb, datefin);
          }
          break;
        case "heuredeb":
        case "heurefin":
          this._manageSoitValue(gererActivite.get("heuredeb"), gererActivite.get("heurefin"));
        // falls through
        //Not default
      }
    }
  }

  _manageComptesDeplacement(): void {
    if (_.isEmpty(this.dataComptesBackup)) {
      this._setCompteDeplacementBackup();
      this._setAndFetchDataDeplacementComptes();
    } else if (this._compareComptesDeplacementAreEqual() === false) {
      this._setCompteDeplacementBackup();
      this._setAndFetchDataDeplacementComptes();
    }
  }

  _setAndFetchDataDeplacementComptes(): void {
    if (!_.isEmpty(this._getModel().get("typedeplac")) && !CWSTR.isBlank(this._getModel().get("typedeplac")) && this._getModel().get("typedeplac").code === "DN") {
      const compteModel = new CWCompteDeplacementModel();

      compteModel.set({
        matricule: (this._getModel().get("matricule")) || this.context.ctxGestionCollab.matricule,
        datedeb: this._getModel().get("datedeb").toString(),
        dtdebinter: this._getModel().get("dtdebinter").toString(),
        hdebinter: this._getModel().get("hdebinter").toString(),
      });
      compteModel.setHabContext(this._getModel().getHabContext());
      if (compteModel.isValid()) {
        compteModel.fetch({
          success: (model: any, data: { [key: string]: any }): void => {
            if (!_.isEmpty(data) && _.isObject(data) && _.isObject(data.cptdeplac) && !CWSTR.isBlank(data.cptdeplac.code)) {
              this._setComboComptesValues(data.cptdeplac.code, data.cptdeplac.libelle);
            } else {
              this._setComboComptesValues('', '');
            }
          }
        });
      }
    } else {
      this._setComboComptesValues('', '');
    }
  }


  _compareComptesDeplacementAreEqual(): boolean {
    let result = false;

    if (!CWSTR.isBlank(this.dataComptesBackup.datedeb) && !CWSTR.isBlank(this.dataComptesBackup.dtdebinter) && !CWSTR.isBlank(this.dataComptesBackup.hdebinter) && !CWSTR.isBlank(this.dataComptesBackup.typedeplac) &&
      this.dataComptesBackup.datedeb === this._getModel().get("datedeb") && this.dataComptesBackup.dtdebinter === this._getModel().get("dtdebinter") &&
      this.dataComptesBackup.hdebinter.toString() === this._getModel().get("hdebinter").toString() && this.dataComptesBackup.typedeplac.toString() === this._getModel().get("typedeplac").code.toString()) {
      result = true;
    }
    return result;
  }

  _setCompteDeplacementBackup(): void {
    this.dataComptesBackup = {
      datedeb: this._getModel().get("datedeb") || '',
      dtdebinter: this._getModel().get("dtdebinter") || '',
      hdebinter: this._getModel().get("hdebinter") || '',
      typedeplac: (this._getModel().get("typedeplac") && !CWSTR.isBlank(this._getModel().get("typedeplac").code)) ? this._getModel().get("typedeplac").code : ""
    };
  }

  /**
   * TODO revisar si esta lógica pertenece a la vista o al workflow
   * Performs a Revert on the From.
   * Function Called when Press the revert Btn.
   */
  revertView(): void {
    if (this.context && this.context.ctxModeRepresentation === "pop-up" && (this.context.ctxModeInitialisation === "Ajout" || this.context.ctxModeInitialisation === "Transformation")) {
      this.parent.close();
    } else {
      this.hasBeenFocused = false;
      if (this.context.ctxEcran !== "saisiemass") {
        this.context.ctxModeInitialisation = "Consultation";
      }
      this._restoreActivite();
      this._restoreInitialState();
      //Map data to deplacement model
      this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationAcces, natGest: "" });
      this.mapActiviteToDeplacementModel(this._getModel());
      this.mapDeplacementModelToForm();
      this._changeHourInterventionDeplacement();
      if (this.context && this.context.ctxModeRepresentation !== "pop-up") {
        this._setTitleImbrique("revert");
        this._renderDeroulantLabels("revert");
        this._initCombos();
        if (this.selectActRealisee) {
          this.$el.find(".refActreal").html(this.selectActRealisee.render().el);
        }
        this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
      } else {
        this._setTitlePopUp();
      }
      this._initSubtitle(false);
      this._showTypeStatusTitle();
      if (this.workflow.cwGererActivitesView.original) {
        this.model.trigger("activiteChanged", this._getModel(), this.context.ctxModeInitialisation, true);
      } else {
        this.model.trigger("activiteReverted", this._getModel(), this.context.ctxModeInitialisation);
      }
      this.$el.find(".msg-avertissement").hide();//cacher le message si l'il est visible
    }
  }

  /**
   * 
   * @param modeDeclarer 
   * @implements CWGererActiviteView.saveActivite
   */
  saveActivite(modeDeclarer: boolean): void {
    //debut declaration-----------------------------------------------------------------------
    const target = this._getModel();
    const errorCallback = (model: CWBaseModel): void => {
      if (!_.isEmpty(model.validationError) && !_.isEmpty(model.validationError.errors) && model.validationError.errors.datedeb) {
        if (target.get("typesaisie") === "D") {
          model.validationError.errors.dateDebD = model.validationError.errors.datedeb;
        } else if (target.get("typesaisie") === "P") {
          model.validationError.errors.dateDebP = model.validationError.errors.datedeb;
        } else {
          model.validationError.errors.dateDebR = model.validationError.errors.datedeb;
        }
        delete model.validationError.errors.datedeb;
        this._showValidationErrors(model, model.validationError);
      } else if ((this.typeSaisie.$el.find("label").first().text() === i18n.t('common:gereractivites.date') || (this.context && this.context.ctxSaisieMasse === true &&
        this.typeSaisie.$el.find("label").first().text() === i18n.t('common:gereractivites.periodicite'))) && model.validationError && model.validationError.errors && !model.validationError.errors.datedeb) {
        //model.validationError.errors.datedeb = model.validationError.errors.datefin;
        if (model.validationError.errors.datefin) {
          if (target.get("typesaisie") === "D") {
            model.validationError.errors.dateDebD = model.validationError.errors.datefin;
          } else if (target.get("typesaisie") === "P") {
            model.validationError.errors.dateDebP = model.validationError.errors.datefin;
          } else {
            model.validationError.errors.dateDebR = model.validationError.errors.datefin;
          }
          delete model.validationError.errors.datefin;
          this._showValidationErrors(model, model.validationError);
        }
      }
    };
    const saveCallback = (fresh: any): void => {
      let saveAction = "update";

      //nettoyer les erreurs de l'écran
      this._cleanValidationErrors();
      if (modeDeclarer === true) {
        saveAction = "transformation";
      }
      if (target.isNew() || target.creation === true) {
        saveAction = "create";
        target.creation = undefined;
      }
      this.savePiecesJointes(fresh, (): void => {
        if (this.context.ctxModeRepresentation === "pop-up") {
          this.parent.close();
        }
        if (this.context && this.context.ctxSaisieMasse === true) {
          this.collabBackup = this.referentielCollabPopu.model.toJSON();
          this.selectCollab();
        }
        if (this._isOutOfDateRange(target) === true) {
          if (this._setReloadPlanningFlag(target)) {
            if (this.plworkflow && _.isBoolean(this.plworkflow.affRefPrets)) {
              //A preference stored so we don't  have to show dialog, only act as stored
              if (this.plworkflow && this.plworkflow.affRefPrets === true) {
                this.plworkflow.trigger("refresh:all", { resetPlanning: true });
              } else {
                this.model.trigger("activiteChanged", fresh, saveAction);
                if (this.context.ctxActiviteBesoin === true) {
                  const arrayCellIds = [];

                  arrayCellIds.push(fresh.get("matricule") + "," + fresh.get("datedeb"));
                  this.plworkflow.ctxChangerPlanningBesoin = true;
                  this.plworkflow.ctxInitPlanActivite = false;
                  this.plworkflow.trigger("refresh:selection", fresh.get("datedeb"), fresh.get("datefin"), null, null, null, true, arrayCellIds, true);
                  this.plworkflow.totalsModel.trigger("change");
                  this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
                }
              }
              //If affRefPrets=false, don't refresh all planning
            } else {//No preference stored so we have to shoe dialog              
              const callback = (): void => {
                this.model.trigger("activiteChanged", fresh, saveAction);
                if (this.context.ctxActiviteBesoin === true) {
                  const arrayCellIds = [];

                  this.plworkflow.ctxChangerPlanningBesoin = true;
                  this.plworkflow.ctxInitPlanActivite = false;
                  arrayCellIds.push(fresh.get("matricule") + "," + fresh.get("datedeb"));
                  this.plworkflow.trigger("refresh:selection", fresh.get("datedeb"), fresh.get("datefin"), null, null, null, true, arrayCellIds, true);
                  this.plworkflow.totalsModel.trigger("change");
                  this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
                }
              };

              this._openRefreshPretsDialog(null, callback);
            }
          } else {
            this.model.trigger("activiteChanged", fresh, saveAction);
            if (this.context.ctxActiviteBesoin === true) {
              const arrayCellIds = [];

              this.plworkflow.ctxChangerPlanningBesoin = true;
              this.plworkflow.ctxInitPlanActivite = false;
              arrayCellIds.push(fresh.get("matricule") + "," + fresh.get("datedeb"));
              this.plworkflow.trigger("refresh:selection", fresh.get("datedeb"), fresh.get("datefin"), null, null, null, true, arrayCellIds, true);
              this.plworkflow.totalsModel.trigger("change");
              this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
            }
          }
        } else {
          this.model.trigger("activiteChanged", fresh, saveAction);
          if (this.context.ctxActiviteBesoin === true) {
            const arrayCellIds = [];

            this.plworkflow.ctxChangerPlanningBesoin = true;
            this.plworkflow.ctxInitPlanActivite = false;
            arrayCellIds.push(fresh.get("matricule") + "," + fresh.get("datedeb"));
            this.plworkflow.trigger("refresh:selection", fresh.get("datedeb"), fresh.get("datefin"), null, null, null, true, arrayCellIds, true);
            this.plworkflow.totalsModel.trigger("change");
            this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
          }
        }
        fresh.store();
      });
    };
    const lHabGestion = this._getHabilitationDuContext(target.get("typeevenement"), "Gestion");
    //fin declaration-----------------------------------------------------------------------

    target.set("matricule", this.context.ctxGestionCollab.matricule);
    target.action = "element";
    target.updateHabContext({ foncCour: lHabGestion });
    target.modeDeclarer = modeDeclarer;
    if (modeDeclarer === true) {
      if (!CWSTR.isBlank(target.get("recapitulatif")) && !CWSTR.isBlank(target.get("recapitulatif").code)) {
        target.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestionRecap, natGest: "A" });
      } else {
        target.updateHabContext({ foncCour: lHabGestion, natGest: "A" });
      }
    }
    this._checkHoraireActivite(target, (): void => {
      if (modeDeclarer === false && this.isPrevue === true && (target.get("typetransfo") === "C" || target.get("typetransfo") === "P")) {
        CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1343'), (result: string): void => {
          if (result === "Y") {
            target.save(null, {
              success: saveCallback
            });
          } else {
            this.revertView();
          }
        }, null, null, null, this.workflow.$appendTo);
      } else {
        if (target.isNew()) {
          target.creation = true;
        } else {
          target.creation = undefined;
        }
        this._manageTypeSaisieDate(target);
        if (target.isValid()) {
          const callbacks = {
            success: saveCallback,
            error: errorCallback
          };

          this.deplacementSaveAction(callbacks, target);
        }
      }
    });
  }

  deplacementSaveAction(options: any, target: { [key: string]: any }): void {
    const data = {
      // Attributs communs aux activités et aux déplacements
      "activite": this._getModel().get("activite") || "",
      "libelle": this._getModel().get("libelle") || "",
      "straid": this._getModel().get("straid") || null,
      "hierid": this._getModel().get("hierid") || null,
      "typeevenement": "R",
      "matricule": this._getModel().get("matricule") || null,
      "datedeb": (this._getModel().get("datedeb")) ? this._getModel().get("datedeb").toString() : "",
      "commentaire": this._getModel().get("commentaire") || "",
      "complement": this._getModel().get("complement") || "",
      "origine": this._getModel().get("origine") || {
        "code": "",
        "libelle": ""
      },
      // Attributs spécifiques aux déplacements
      "happel": this._getModel().get("happel") || null,
      "dtappel": (this._getModel().get("dtappel")) ? this._getModel().get("dtappel").toString() : "",
      "dtdebinter": (this._getModel().get("dtdebinter")) ? this._getModel().get("dtdebinter").toString() : "",
      "dtfininter": (this._getModel().get("dtfininter")) ? this._getModel().get("dtfininter").toString() : "",

      "hdebinter": !CWSTR.isBlank(this._getModel().get("hdebinter")) ? this._getModel().get("hdebinter") : 0,
      "hfininter": !CWSTR.isBlank(this._getModel().get("hfininter")) ? this._getModel().get("hfininter") : 0,
      "dureeinter": this._getModel().get("dureeinter") || 0,
      "typedeplac": this._getModel().get("typedeplac") || {
        "code": "",
        "libelle": ""
      },
      "iddemande": this._getModel().get("iddemande"),
      "cptdeplac": this._getModel().get("cptdeplac") || {
        "code": "",
        "libelle": ""
      }
    };
    const modelPersistence = new CWActiviteDeplacemnetPersistenceModel(data);

    modelPersistence.setHabContext(target.getHabContext());
    if (!this._getModel().isNew()) {
      modelPersistence.id = this._getModel().get("id");
      modelPersistence.update = true;
    }
    if (!CWSTR.isBlank(this._getModel().oldAttributes)) {
      modelPersistence.oldAttributes = this._getModel().oldAttributes;
    }
    (modelPersistence as any)._events = target._events;//si l'il y a des erreurs, il faut les afficher (les actions sont dans target._events)
    modelPersistence.save(null, options);
  }

  /**
   * @implements CWGererActiviteView.newActivite
   */
  newActivite(): void {
    let target = null;
    const lHabGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");

    this.stopMessagePrevClickActivite();
    this.notCheckCombos = true;
    if (this.context && this.context.ctxSaisieMasse === true) {
      target = new CWGererActivitesDeplacementModel(null, { "matricule": null, infoCompAttributeName: this.infoCompAttributeName, context: (!_.isEmpty(this.context)) ? this.context : {} });
    } else {
      target = new CWGererActivitesDeplacementModel(null, { infoCompAttributeName: this.infoCompAttributeName, context: (!_.isEmpty(this.context)) ? this.context : {} });
    }
    this.model.updateHabContext({ foncCour: lHabGestion, natGest: "A" });
    if (!CWSTR.isBlank(this.actDefaults)) {
      target.set("carpers", this.actDefaults.get("carpers"));
    }
    target.set("matricule", this.context.ctxGestionCollab.matricule);
    this._enableForm();
    if (this.context.ctxEcran === "mdepl") {
      this.$el.find(".c-form--fixedButtons").removeClass("c-form--fixedButtons");
      if (CWSTR.isBlank(this.context.ctxTypologieActivite)) {
        this.context.ctxTypologieActivite = this.collTypoDeplac.models[0].attributes.get("code");
      }
      this._renderDeroulantLabels(null);
      this._initCombos();
      if (this.selectActRealisee) {
        this.$el.find(".refActreal").html(this.selectActRealisee.render().el);
      }
      this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
    } else {
      this.model.trigger("mode:create");
    }
    this.model.set("value", target);
    this.$el.find(".msg-avertissement").hide();//cacher le message si l'il est visible
    this.model.trigger("form:edited", true);
    if (this.context.ctxEcran !== "saisiemass") {
      this.context.ctxModeInitialisation = "Ajout";
    }
    this._setTitlePopUp();
    this._initSubtitle(true);
    this._showTypeStatusTitle();
    this._hideTypeStatusTitle();
    this._managePieceJointe();
    this._showDateConsultee();
    this._showIndicateurEvenamentMessage();
    this.mapActiviteToDeplacementModel(this._getModel());
    this.setInitialDeplacementValues();
    this.mapDeplacementModelToForm();
    this._changeHourInterventionDeplacement();
    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "A" });
  }

  _cleanDefaultValidationErrors(): void {
    if (!CWSTR.isBlank(this.context) && this.context.ctxEcran !== "saisiemass") {
      //Clear current validation erros
      this._cleanValidationErrors();
    }
  }

  _manageDtdebinter(isContextAjout: boolean, isClasshdebinter: boolean): void {
    const hdebinter = this._getModel().get('hdebinter');
    let dtdebinter = this._getModel().get('dateDebD');

    if (isContextAjout && isClasshdebinter) {
      if (!CWSTR.isBlank(hdebinter)) {
        if (hdebinter < this.FRACTIONJMAT.heuredeb) {
          const tmpDate = CWTYPE.DATE.strToDate(this._getModel().get('dateDebD'));

          tmpDate.setDate(tmpDate.getDate() + 1);
          dtdebinter = CWTYPE.DATE.dateToStr(tmpDate, 'YYYYMMDD');
        }
      } else {
        dtdebinter = "";
      }
    } else {
      dtdebinter = this._getModel().get('dtdebinter') || "";
    }
    this._getModel().set({ "dtdebinter": dtdebinter });
    if (!CWSTR.isBlank(this._getModel().get("happel"))) {
      if (hdebinter < this._getModel().get("happel")) {
        this._getModel().set({ "dtappel": this._getModel().get('dateDebD') });
      } else {
        this._getModel().set({ "dtappel": dtdebinter });
      }
    } else {
      this._getModel().set({ "dtappel": "" });
    }
    this._addDayDifference();
    this._mapToForm();
  }

  _addDayDifference(): void {
    if (parseInt(this._getModel().get("hfininter"), 10) < parseInt(this._getModel().get("hdebinter"), 10)) {
      if (this._getModel().get("dateDebD") && this._getModel().get("dtdebinter") && this._getModel().get("dateDebD") < this._getModel().get("dtdebinter")) {
        this._getModel().set({ "dtfininter": this._getModel().get("dtdebinter") }, { silent: true });
      } else {
        if (!CWSTR.isBlank(this._getModel().get("dtdebinter"))) {
          this._getModel().set({ "dtfininter": UTILS.addDaysString(this._getModel().get("dtdebinter"), 1) }, { silent: true });
        } else {
          this._getModel().set({ "dtfininter": null }, { silent: true });
        }
      }
    } else {
      this._getModel().set({ "dtfininter": this._getModel().get("dtdebinter") }, { silent: true });
    }
  }

  _setComboComptesValues(code: string, libelle: string): void {
    this.comboCompte.setItem({ 'code': code, 'libelle': libelle });
    this._getModel().set({ 'cptdeplac': { 'code': code, 'libelle': libelle } }, { silent: true });
  }

  afterFetchListerTypoDom(): void {
    const lfuncLocal = (): void => {
      this.manageIndicationVisibility(this._getModel());
      if (this.context && this.context.ctxSaisieMasse) {

        const buttons = this.$el.find("#activitecollab_btnbar").detach();
        this.$el.find("div.gereractivite-form-inputs div.gereractivites-infocomp").append(buttons);
      }
    };

    // Ref. Activites réalisées
    if (!CWSTR.isBlank(this.context.ctxTypologieActivite) && this.selectActRealisee) {
      this.$el.find(".refActreal").html(this.selectActRealisee.render(lfuncLocal).el);
    }
  }
}