import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Model representing the workflow of an event type
 */
export class CWSensBadgeageModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "libelle": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  /**
   * Process to manage the response
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}
