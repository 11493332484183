import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererpretsGererPrets from '../cwGererPrets.tpl.html';
import TPLGererpretsMultiLabelModeSaisie from '../cwMultiLabel-modeSaisie.tpl.html';
import TPLGererpretsMultiLabelTypeSaisie from '../cwMultiLabel-typeSaisie.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWButtonBarValidationView } from 'src/core/controls/cwButtonBarValidation.view';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWComplementComboColl } from '../models/cwComplementCombo.collection';
import { CWDIVERS } from 'utils/cwDivers';
import { CWEtiquette, validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { CWGererPretsGestion } from '../models/cwGererPretsGestion';
import { CWGererPretsModel } from '../models/cwGererPrets.model';
import { CWGererpretsWorkflow } from '../models/cwGererPrets.workflow';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMSGS } from 'utils/cwMsgs';
import { CWMultiLabel } from 'core/views/cwMultiLabel';
import { CWPanneauDeroulant } from 'src/core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPieceJointes } from 'common/piecesjointes/views/cwPieceJointes.view';
import { CWSelecteurCheminItemModel } from 'core/components/selecteur_chemin/cwSelecteurCheminItem.model';
import { CWSelecteurCheminView } from 'core/components/selecteur_chemin/cwSelecteurChemin.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWTypeGestionModel } from './../models/cwTypeGestion.model';
import { CWWkflhistoView } from 'common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { InitRattEmpruntCollab } from '../models/initRattEmpruntCollab.js';
import { ModeSaisieOptionsColl } from '../models/modeSaisieOptions.collection.js';
import { objs } from 'src/objectsRepository';
import { onError } from 'src/Interceptors/onError.js';
import { SIMULATION } from 'utils/simulation.js';
import { TypeAffDefaults } from '../models/typeAffDefaults.js';
import { TypeSaisieOptionsColl } from '../models/typeSaisieOptions.collection.js';
import { TypeStructuresColl } from '../models/typeStructures.collection.js';
//import { CWComplementComboModel } from '../models/cwComplementCombo.model';
//import { UTILS } from 'utils/utils.js';

/**
 * Render gerer pret view
 */
export class CWGererPretView extends CWBaseFormView {

  _pretParPeriode: boolean;
  affModeSaisieOptionsColl: typeof ModeSaisieOptionsColl;
  affTypeSaisieOptionsColl: typeof TypeSaisieOptionsColl;
  btnBar: CWButtonBarValidationView
  cache: any;
  comboMotif: CWComboBoxView2;
  comboOrigine: CWComboBoxView2;
  comboPerimetre: CWComboBoxView2 | any;
  combos: any;
  comboType: CWComboBoxView2;
  comboUnites: CWComboBoxView2;
  comboUniteDebut: CWComboBoxView2;
  comboUniteFin: CWComboBoxView2;
  composantWorkflow: CWWkflhistoView;
  context: any;
  contextComponentWkf: any;
  disabled: boolean;
  diver: string;
  filterPerimetres: any;
  hasBeenFocused: boolean;
  id: any;
  isContextInitialized: boolean;
  listeCheminViews: any;
  model: CWBaseModel | any;
  modeSaisie: CWMultiLabel | any;
  motifChanged: boolean;
  motifComboColl: CWCombosColl;
  mouseUpDone: boolean;
  original: CWBaseModel | any;
  origineChanged: boolean;
  origineContext: any;
  parent: any;
  perimetreComboColl: CWCombosColl;
  pieceJointe: CWPieceJointes | any;
  pieceJointeModified: boolean;
  pretDefaults: typeof TypeAffDefaults;
  profilModel: CWBaseModel | any;
  saisieChanged: boolean;
  typeComboColl: CWCombosColl;
  typeGestionOfCollab: CWTypeGestionModel | any;
  typeSaisie: CWMultiLabel | any;
  typeStructures: typeof TypeStructuresColl | any;
  unitesComboColl: CWCombosColl;
  uniteDebComboColl: CWCombosColl;
  uniteFinComboColl: CWCombosColl;
  workflow: CWGererpretsWorkflow;
  etiquetteHisto1: CWEtiquette;
  panneauIsOpen: boolean;
  histoEtiquettePopup: CWPanneauDeroulant;
  protected desactiveHabOuEtat: boolean;
  protected errorPetAntSaveDelete: boolean; //flag pour savoir si la petition dernier était en erreur ou pas
  public isEditable: boolean;
  private infoErrorModify: { [key: string]: any };
  private notCheckCombos: boolean;
  infoCompAttributeName: string;


  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.className = "cw-fieldset cwGererPret";
    options.tagName = "fieldset";
    options.events = _.extend({
      "change :input": "_changeView",
      "change .datedeb": "_initRattEmpruntCollab",
      "keyup :input:not([readonly])": "_notifyEdition",
      "click li.phx-multiLabel-option": "_changeTypeSaisie",
      "keyup textarea:not([readonly])": "_notifyEdition",
      "click .menuItem": "menuItemListener",
      "click .valider": "valider",
    }, CWTYPE.DATE.events(), CWTYPE.CUSTOM.events(), CWTYPE.HOUR_MINUTE_NIGHTLY.events());
    super(options);
    this.infoCompAttributeName = 'infocomp';
    this.isContextInitialized = false;
    this.id = null;
    this.disabled = false;
    this.desactiveHabOuEtat = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    this.model = new CWBaseModel({
      value: new CWGererPretsModel(),
      mode: "C"
    });
    this.hasBeenFocused = false;
    if (options.context) {
      this.context = options.context;
      this.model.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces
      }));
    }
    this.workflow = new CWGererpretsWorkflow({}, { "context": this.context });
    this.btnBar = new CWButtonBarValidationView({ id: "pretcollab_btnbar" });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.gererPrets = this;
    this.workflow.formModel = this.model;
    this.workflow.setUp();
    this.diver = this.workflow.pDiversIdCollabModel.get("valeur");
    this.affTypeSaisieOptionsColl = new TypeSaisieOptionsColl();
    this.affModeSaisieOptionsColl = new ModeSaisieOptionsColl();
    this.pretDefaults = null;
    this.profilModel = null;
    this._initChangeFlags();
    //List of selecteur de chemin views
    this.listeCheminViews = [];
    this.typeStructures = null;
    if (options.parent) {
      this.parent = options.parent;
    }
    this.template = TPLGererpretsGererPrets;
    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._mapToForm, this);
    this.model.on("change:mode", this._manageMode, this);
    this._pretParPeriode = true;
    this.hasBeenFocused = false;
    this._initCombos();
    this.typeFormatByClass = {};
    this.typeMaskByClass = {};
    this.typeMaskByClass["datedeb"] = "DATE_L";
    this.typeMaskByClass["datefin"] = "DATE_L";
  }

  _changePerimetre(): void {
    //the fields are enabled so they can be selected.
    CWFORMS.setFieldReadonly(this.$el.find(".type\\.code"), false, false);
    CWFORMS.setFieldReadonly(this.$el.find(".unitedeb\\.code"), false, false);
    if (!_.isEmpty(this.model.get("value"))) {
      if (!_.isEmpty(this.model.get("value").get("type"))) {
        this.model.get("value").get("type").code = null;
      }
      if (!_.isEmpty(this.model.get("value").get("unitedeb"))) {
        this.model.get("value").get("unitedeb").code = null;
      }
    }
    this._initRattEmpruntCollab();
  }

  // ::::::: EVO - 458 ::::::::
  // call the init of "rattachements" for the collab "emprunté",
  // when the component is open (creation/modication) and the datedebut or the perimetre exist or changes.
  _initRattEmpruntCollab(): void {
    let collabremplace = null;
    let datedeb: string = null;
    let perType = null;
    const initRattEmpCollab = new InitRattEmpruntCollab({});
    let habContext = null;

    if (this.pretDefaults.id) {
      if (this.typeGestionOfCollab.date !== this.model.get("value").attributes.datedeb) {
        this._updateCombosForType(this.pretDefaults.id);
      }
    }
    // ::::::::::: COLLAB remplace :::::::::::
    if (this.model.get("value") && this.model.get("value").get("collabremplace") &&
      this.model.get("value").get("collabremplace").matric) {
      collabremplace = this.model.get("value").get("collabremplace").matric;
    } else {
      // création à partir du remplacement d'un collaborateur (emprunt),
      // le collaborateur remplacé est celui que l'on a sélectionné au départ
      if (this.context.ctxCollabReference && this.context.ctxCollabReference.matricule) {
        collabremplace = this.context.ctxCollabReference.matricule;
      }
    }
    // :::::::: DATE DEBUT ::::::::::
    datedeb = $(".gererprets-detail .datedeb").val() as string;
    // we are in modification
    if (!CWSTR.isBlank(datedeb)) {
      const myDateArray = datedeb.split("/");

      datedeb = myDateArray[2] + myDateArray[1] + myDateArray[0];
    }
    // we are in creation - consultation
    if (CWSTR.isBlank(datedeb)) {
      if (this.model.get("value") && this.model.get("value").get("datedeb")) {
        datedeb = this.model.get("value").get("datedeb");
      } else if (this.context.ctxValeursCreation) {
        datedeb = this.context.ctxValeursCreation.PretDateDebut;
      }
    }
    // ::::::: PERIODE ::::::::::
    // in consultation - or creation
    if (this.model.get("value") && this.model.get("value").get("perimetre") && this.model.get("value").get("perimetre").code) {
      perType = this.model.get("value").get("perimetre").code;
    } else {
      perType = this.context.ctxPerimetre;
    }
    // Call to the new WS to initialise 'the rattachements aux structures'
    // Dans le collab emprunté
    // InitialiserRattachCollabEmprunte
    initRattEmpCollab.set("collabremplace", collabremplace);
    initRattEmpCollab.set("datedeb", datedeb);
    // set context and permissions in the new Webservice
    if (perType === "N") { // if perimetre type Normal
      habContext = new CWHabilitationContext({
        onglet: "gererprets",
        foncCour: {
          "collaborateur": "RES_GCOLLAB.G",
          "emprunt": "RES_EMPR_N.G"
        }
      });
    }
    if (perType === "E") { // if perimetre type Entendu
      habContext = new CWHabilitationContext({
        onglet: "gererprets",
        foncCour: {
          "collaborateur": "RES_GCOLLAB.G",
          "emprunt": "RES_EMPR_E.G"
        }
      });
    }
    initRattEmpCollab.setHabContext(habContext);
    this._initializeModeAndTypeColl();
    // if we got the collabRemplace and date - we call the WS 'InitialiserRattachCollabEmprunte.'
    if (!CWSTR.isBlank(collabremplace) && !CWSTR.isBlank(datedeb)) {
      initRattEmpCollab.save(null, {
        success: (fresh: any): void => {
          const arrayStructures = [];
          let cont = 0;

          // : this takes all informations about "rattachements"
          // as Webservice "readPretCollab"  - StructureLibelle1 à n et PretElementStructure1 à n
          while (fresh.attributes[cont]) {
            arrayStructures[cont] = fresh.attributes[cont];
            cont++;
          }
          this.model.get("value").set("structures", arrayStructures);
          this.model.trigger("change:value");
        }
      });
    } else { // if not we update the rest of the data.
      this.model.trigger("change:value");
    }
  }

  _initChangeFlags(): void {
    this.saisieChanged = null;
    this.motifChanged = null;
    this.origineChanged = null;
  }

  _getHabilitationGestion(model?: CWBaseModel | any): string {
    const perimetre = CWSTR.isBlank(CWSTR.getElValue(model, "perimetre.code")) ? this.context.ctxPerimetre : CWSTR.getElValue(model, "perimetre.code");

    return (perimetre === "N" ? this.context.ctxHabilitation.HabilitationGestionNormal : this.context.ctxHabilitation.HabilitationGestionEtendu);
  }

  _getPerimetre(model: CWBaseModel | any): string {
    const perimetre = CWSTR.isBlank(CWSTR.getElValue(model, "perimetre.code")) ? this.context.ctxPerimetre : CWSTR.getElValue(model, "perimetre.code");

    return perimetre;
  }

  _initCombos(): void {
    this.typeComboColl = new CWCombosColl({ name: "ls/typeaffectation?nature=P" });
    this.typeComboColl.setHabContext(this.model.getHabContext());
    this.comboType = new CWComboBoxView2({
      ws: this.typeComboColl,
      habContext: this.model.getHabContext(),
      autocomplete: true,
      name: "type.code",
      required: true,
      optionsRender: (item: any): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      }
    });
    this.perimetreComboColl = new CWCombosColl({ name: "ls/divcod/perimetrespret" });
    this.perimetreComboColl.setHabContext(this.model.getHabContext());
    this.comboPerimetre = new CWComboBoxView2({
      ws: this.perimetreComboColl,
      habContext: this.model.getHabContext(),
      required: true,
      autocomplete: true,
      name: "perimetre.code",
      optionsRender: (item: any): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      }
    });
    this.motifComboColl = new CWCombosColl({ name: "ls/motifpret" });
    this.motifComboColl.setHabContext(this.model.getHabContext());
    this.comboMotif = new CWComboBoxView2({
      ws: this.motifComboColl,
      habContext: this.model.getHabContext(),
      autocomplete: true,
      name: "motif.code",
      required: true,
      optionsRender: (item: any): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      }
    });
    this.origineContext = (this.context.ctxUtilisateur === "Responsable") ? "respo" : "collab";
    this.comboOrigine = new CWComboBoxView2({
      ws: new CWCombosColl({ urlMine: Configuration.restRoot + "/rest/ls/origine/" + this.origineContext }),
      autocomplete: true,
      required: false,
      name: "origine.code",
      habContext: this.model.getHabContext(),
      optionsRender: (item: any): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle;
      }
    });
    //************
    // The enum for the next 3 combos is initialized in _updateCombosForType function
    //**************
    this.uniteDebComboColl = new CWCombosColl({
      name: "utilevenement/modesaisie/periodedebut/affectation"
    });
    this.uniteDebComboColl.setHabContext(this.model.getHabContext());
    this.comboUniteDebut = new CWComboBoxView2({
      enum: null,
      externalCache: this.cache,
      syncExternalCache: false,
      name: "modesai_periodeb.code",
      required: true,
      habContext: this.model.getHabContext()
    });
    this.uniteFinComboColl = new CWCombosColl({
      name: "utilevenement/modesaisie/periodefin/affectation"
    });
    this.uniteFinComboColl.setHabContext(this.model.getHabContext());
    this.comboUniteFin = new CWComboBoxView2({
      enum: null,
      externalCache: this.cache,
      syncExternalCache: false,
      name: "modesai_periofin.code",
      required: true,
      habContext: this.model.getHabContext()
    });
    this.unitesComboColl = new CWCombosColl({ name: "uniteevenement/?typeevtid=PRET" });
    this.unitesComboColl.setHabContext(this.model.getHabContext());
    this.comboUnites = new CWComboBoxView2({
      enum: null,
      name: "unitedeb.code",
      required: true,
      externalCache: this.cache,
      syncExternalCache: false,
      habContext: this.model.getHabContext()
    });
    this.listenTo(this.comboType, "comboEdited", this._formEdited);
    this.listenTo(this.comboPerimetre, "comboEdited", this._formEdited);
    this.listenTo(this.comboMotif, "comboEdited", this._formEdited);
    this.listenTo(this.comboOrigine, "comboEdited", this._formEdited);
    this.listenTo(this.comboUniteDebut, "comboEdited", this._formEdited);
    this.listenTo(this.comboUniteFin, "comboEdited", this._formEdited);
    this.listenTo(this.comboUnites, "comboEdited", this._formEdited);
  }

  /**
   * Initialize collections of authorized type saisies and modesaisies for a type
   * If collections are empty, all type and modes are allowed.
   */

  _initializeModeAndTypeColl(): void {
    let foncCourTmp = this.context.ctxHabilitation.HabilitationAcces;

    //When no activity is selected affTypeSaisieOptionsColl adn affModeSaisieOptionsColl are initialized
    this.affTypeSaisieOptionsColl = new TypeSaisieOptionsColl();
    if (this.context.ctxPerimetre === "N") {
      foncCourTmp = this.context.ctxHabilitation.HabilitationGestionNormal;
    } else if (this.context.ctxPerimetre === "E") {
      foncCourTmp = this.context.ctxHabilitation.HabilitationGestionEtendu;
    }
    this.affTypeSaisieOptionsColl.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCourTmp
    }));
    this.affModeSaisieOptionsColl = new ModeSaisieOptionsColl();
    this.affModeSaisieOptionsColl.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCourTmp
    }));
  }

  _panelStateIsReadonly(): boolean {
    const model = this._getModel();
    let isReadOnly = false;
    const habilitationGestion = this._getHabilitationGestion(this.model.get("value"));

    if (this.context && this.context.ctxHabilitation && habilitationGestion) {
      const canUpdate = CWHABILITATION.canUpdate(habilitationGestion);

      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  }

  _formEdited(): void {
    this.model.trigger("form:edited");
  }

  /**
   * Apply the context to the Component.
   *
   * ctxUtilisateur			> "Responsable" | "Collaborateur"
   * ctxHabilitation			HabilitationAcces		COL_PRET.V, RES_PRET.V
   * 							HabilitationGestion
   *                                    perimetreNormal	 RES_PRET_N.G,RES_EMPR_N.G
   *                                    perimetreEtendu   RES_PRET_E.G,RES_EMPR_E.G
   * 							HabilitationValidation	RES_VAL_PRET.G
   * ctxEcran					> Identifiant de l’écran.
   * ctxModeRepresentation	> "Imbrique" | "pop-up"
   * ctxCollab			> Information du collaborateur concerné
   *         matricule
   *         matric_aux
   *         nom
   *         prenom
   * ctxListeCollab           > Liste des collaborateurs concernés par le prêt
   * ctxCollabReference		< Information du collaborateur remplacé
   * 		   matricule
   * 		   matric_aux
   *         nom
   *  	   prenom
   *    	   datedeb
   *    	   datefin
   * ctxPerimetre             >Indique le périmètre du prêt   Normal|Etendu
   * ctxPerimetreFige         >  Oui |Non
   * ctxTypeEvtGere           >  P (Prêt) | E (Emprunt)
   * ctxActionsPossibles		> ["Creer", "Supprimer", "Modifier", "Valider"
   * ctxModeInitialisation	> "Consultation" | "Ajout"]
   * ctxIdentifiantEvt		> En cas de restitution d’un événement existant, identifiant de celui-ci.
   * ctxValeursEvenement      >
   * PretCodeStatutWKF =
   *	PretEnSuppression = OUI / NON
   *	PretType =
   *	PretPerimetre =
   *	PretListeElementStructure=
   *	PretTypeSaisie =
   *	PretModeSaisie =
   *	PretDateDebut =
   *	PretUniteDebut =
   *	PretDateFin =
   *	PretUniteFin =
   *	PretHeureDebut =
   *	PretHeureFin =
   *	PretDuree =
   *	PretPourcentage=
   *	PretMotif=
   *	PretOrigine=
   *	PretLibelle=
   *	PretCommentaire=
   *	PretListeInfoComplValeur=
   *	PretCommentaireWKF =
   * ctxValeursCréation
   *      PretType =
   *		PretPerimetre =
   *		PretEstUnEmprunt=  vrai si ctxTypeEvtGere = Emprunt sinon faux
   *		PretResponsable = identifiant de l’utilisateur
   *		PretDateDebut = donnée obligatoire
   *		PretUniteDebut =
   *		PretDateFin = donnée obligatoire
   *		PretUniteFin =
   *		PretHeureDebut =
   *		PretHeureFin =
   *		PretCommentaire=
   */
  setContext(context: any): void {
    const lFuncLocal = (): void => {
      this._manageModeRepresentation();
      this._manageModeInitialisation(() => {
        if (CWSTR.isBlank(this.context.ctxModeInitialisation)) {
          this._disableForm();
        }
        this.hasBeenFocused = false;
        this.btnBar.hideButton("copy");
      });
    };

    this.isContextInitialized = true;
    if (CWSTR.isBlank(this.model.getHabContext())) {
      let foncCourTmp = this.context.ctxHabilitation.HabilitationAcces;

      if (this.context.ctxPerimetre === "N") {
        foncCourTmp = this.context.ctxHabilitation.HabilitationGestionNormal;
      } else if (this.context.ctxPerimetre === "E") {
        foncCourTmp = this.context.ctxHabilitation.HabilitationGestionEtendu;
      }
      this.model.updateHabContext({
        onglet: context.ctxEcran,
        foncCour: foncCourTmp,
        natGest: ""
      });
    }
    this.context = context;
    if (!CWSTR.isBlank(this.workflow.maxPieces)) {
      //obtain masxpieces
      this.workflow.maxPieces.fetch({
        success: (): void => {
          lFuncLocal();
        }
      });
    } else {
      lFuncLocal();
    }
    //Check for périmètre habilitations
    this.comboPerimetre.filterPerimetres = [];
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionNormal)) {
      this.comboPerimetre.filterPerimetres.push("N");
    }
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionEtendu)) {
      this.comboPerimetre.filterPerimetres.push("E");
    }
    if (this.comboPerimetre.filterPerimetres.length > 0) {
      this.comboPerimetre.setFilter(this._filterCombo);
      if (this.context.ctxValeursCreation && _.indexOf(this.comboPerimetre.filterPerimetres, this.context.ctxValeursCreation.PretPerimetre) !== -1) {
        this.context.ctxValeursCreation.PretPerimetre = "";
      }
      if (_.indexOf(this.comboPerimetre.filterPerimetres, this.context.ctxPerimetre) !== -1) {
        this.context.ctxPerimetre = "";
      }
    }
    if (CWSTR.isBlank(this.context.ctxDonneeSimulee)) {
      this.context.ctxDonneeSimulee = false;
    }
    if (this.context.ctxDonneeSimulee) {
      this.model.get("value").activeSimulation = true;
    }
    if (this.context.ctxEcran === "planresp" && this.context.ctxSimulation) {
      const divcod = new CWCombosColl({
        name: "utilitaire/divcod",
        comboId: "STEVS",
      });

      if (CWSTR.isBlank(this.context.ctxEvtEstSimule)) {
        this.context.ctxEvtEstSimule = SIMULATION.getSimulationState(4);
      }
      divcod.setHabContext(this.model.getHabContext());
      divcod.fetch({
        success: (data: any): void => {
          if (data.models.length > 0) {
            for (let i = 0; i < data.models.length; i++) {
              if (data.models[i] && String(data.models[i].get("code")) === String(this.context.ctxEvtEstSimule.valeur)) {
                this.context.ctxEvtEstSimule.libelle = data.models[i].get("libelle");
                break;
              }
            }
          }
          this._paintSimulateModeHeader();
        }
      });
    }
    this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  _filterCombo(options: any): any {
    let result = [];

    result = _.filter(options, (obj: any) => {
      if (_.indexOf(this.filterPerimetres, obj.attrs.code) === -1) {
        return true;
      } else {
        return false;
      }
    });
    return result;
  }

  _manageModeRepresentation(): void {
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation === "pop-up") { // Pop up
      this.$el.parent().find(".gererprets-detail").append(this.btnBar.render().el);
      this.$el.parent().find("#pretcollab_btnbar").css("float", "left");
      this._setTitlePopUp();
    } else {
      this.$el.find(".gererprets-utilisateur").hide();
      if (this.context && this.context.ctxEcran !== "saisiescoll") {
        this.$el.parent().find(".gererprets-detail").prepend(this.btnBar.render().el);
      }
    }
  }

  _manageHeader(): void {
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation === "pop-up") { // Pop up
      this._setHeaderPopUp();
    } else {
      this._setHeaderImbrique();
    }
  }

  menuItemListener(event: any): void {
    const action = event.currentTarget.attributes["data-value"].nodeValue;
    const code = event.currentTarget.attributes["data-code"].nodeValue;

    switch (action) {
      case "2":
        this.btnBar.model.trigger("btn:click", "accepter", code);
        break;
      case "3":
        this.btnBar.model.trigger("btn:click", "refuser", code);
        break;
      default:
      //Nothing
    }
  }

  _setTitlePopUp(): void {
    const title = $('<div>').html('<div class="gererprets-titlePopup"><span class="typePrets">' + this._getTitle().toUpperCase() + '</span> <span class="c-titleHistoWkf"></span>');

    if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length >= 1 && _.contains(this.context.ctxActionsPossibles, "Creer") &&
      this.context.ctxModeInitialisation === "Ajout") {
      title.find(".typePrets").append($('<span>').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gererprets.creation_en_cours'))));
    }
    this.parent.isTitleHTML = true;
    this.parent._setTitle(title.html());
  }

  _getTitle(): string {
    let title = "",
      perim = null,
      perimetre = null;

    if (this.context.ctxEcran === "suivicollab") {
      if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length >= 1 && _.contains(this.context.ctxActionsPossibles, "Creer") &&
        this.context.ctxModeInitialisation === "Ajout") {
        title = i18n.t('common:gererprets.nouvelle_pret_title');
      } else {
        title = i18n.t('common:gererprets.actionregul');
      }
    } else {
      if (this.context.ctxTypeEvtGere === "P") {
        title += i18n.t('common:gererprets.title_pret_de_collaborateur');
        if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length === 1 && _.contains(this.context.ctxActionsPossibles, "Creer")) {
          title = i18n.t('common:gererprets.nouvelle_pret_title') + " " + i18n.t('common:gererprets.title_sur_perimetre');
          perim = this._getPerimetre(this.model.get("value"));
          perimetre = perim === "N" ? i18n.t('common:gererprets.normal') : i18n.t('common:gererprets.etendu');
          title += " " + perimetre;
        }
      } else if (this.context.ctxTypeEvtGere === "E") {
        title += i18n.t('common:gererprets.title_emprunt_de_collaborateur');
        if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length === 1 && _.contains(this.context.ctxActionsPossibles, "Creer")) {
          title = i18n.t('common:gererprets.nouvel_emprunt_title') + " " + i18n.t('common:gererprets.title_sur_perimetre');
          perim = this._getPerimetre(this.model.get("value"));
          perimetre = perim === "N" ? i18n.t('common:gererprets.normal') : i18n.t('common:gererprets.etendu');
          title += " " + perimetre;
        }
      }
    }
    return title
  }

  _paintWorkflowStatus(): void {
    let $layout = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll") {
      if (this.context.ctxModeRepresentation === "pop-up") {
        $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');
      } else {
        $layout = this.parent.layout ? $(this.parent.layout.el).find(".l-panelB-buttonContainer") : this.$el.find(".l-panelB-buttonContainer");
      }
      if (this.context.ctxModeInitialisation === "Consultation") {//if is consultation mode
        const pret = this.model.get("value");
        const subtextEtiquette = pret.get("etat") === "X" ? i18n.t('common:etiquette_subText.suppression') : "";//etiquette workflowhisto
        const codeStatut = this.checkSCodetatut(pret.get("statut").code);
        const text = pret.get("statut").code === "H" ? "" : pret.get("statut").libelle;
        const onlyIcon = this.context.ctxHistoriqueWKF && pret.get("iddemande") >= 0 && pret.get("statut").code === "H";

        $layout.show();
        this.etiquetteHisto1 = new CWEtiquette({
          id: "etiquetteWorkHisto",
          text: { enabled: text }, //perhaps we should change for i18n text
          subText: { enabled: subtextEtiquette },
          type: codeStatut,
          state: "actif",
          onlyIcon: onlyIcon
        });
        if (this.context.ctxHistoriqueWKF && this.workflow.wkfPretActiv && pret.get("iddemande") >= 0) {
          this.etiquetteHisto1.icon = 'horloge_fleche';
          this.listenTo(this.etiquetteHisto1.model, "click", this.histoEtiquetteClick);
        } else if (onlyIcon === true || text === "") {
          this.etiquetteHisto1.setNoBackground();
        }
        $layout.html(this.etiquetteHisto1.render().el);
      } else {
        if (!CWSTR.isBlank($layout)) {
          $layout.hide();
        }
      }
    }
  }

  private histoEtiquetteClick(): void {
    if (!CWSTR.isBlank(this.histoEtiquettePopup) && this.panneauIsOpen === true) {
      this.histoEtiquettePopup.close();
      this.panneauIsOpen = false;
    } else {
      this.panneauIsOpen = true;
      if (this.histoEtiquettePopup === undefined) {
        this.histoEtiquettePopup = new CWPanneauDeroulant({
          view: this.composantWorkflow,
          viewData: {
            appendTo: this.el,
            modal: false
          },
          notIconClose: true,
          closeOnClickOutside: true
        });
      } else {
        this.histoEtiquettePopup.viewData = {
          appendTo: this.el,
          modal: false
        };
      }
      this.histoEtiquettePopup.setPosition({ my: "right top", at: "right bottom", of: $(event.currentTarget) });
      this.histoEtiquettePopup.setHeight("auto");
      this.histoEtiquettePopup.setWidth(500);
      this.histoEtiquettePopup.model.trigger("dlg:open");
      this.listenTo(this.histoEtiquettePopup.model, "dlg:close", (): void => {
        this.panneauIsOpen = false;
      });
      this.histoEtiquettePopup._setTitle(i18n.t('common:listerevtgen.historique').toUpperCase());
    }
    event.stopPropagation();
  }

  // This method return the style typoe of the etiquette when we send the letter of the status
  private checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes = "demande";

    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
      //Nothing->par défaut sera "demande"
    }
    return status;
  }

  _setHeaderPopUp(): void {
    const gererPret = this.model.get("value");

    if (gererPret) {
      this.$el.find(".imbrique-header").hide();
    }
    //COLLABORATEUR
    this._manageUtilisateur();
    //STATUT
    if (this.context.ctxModeInitialisation !== "Ajout" && !CWSTR.isBlank(gererPret.get("statut")) && !CWSTR.isBlank(gererPret.get("statut").code)) { //Statut
      this._paintWorkflowStatus();
    }
    //DESCRIPTION
    this._manageDescription();
  }

  _setHeaderImbrique(): void {
    const gererPret = this.model.get("value");

    this.$el.find(".popup-header").hide();
    //PERIODE
    if (this.context.ctxModeInitialisation === "Ajout") {
      if (this.context.ctxTypeEvtGere === "P") { //Pret
        this.$el.find(".periode-header").html(i18n.t('common:gererprets.nouvelle_pret_title'));
      } else { //Emprunt
        this.$el.find(".periode-header").html(i18n.t('common:gererprets.nouvel_emprunt_title'));
      }
    } else {
      let periode = i18n.t('common:gererprets.header_du') + " " + CWTYPE.DATE.format(gererPret.get("datedeb"));

      if (!CWSTR.isBlank(gererPret.get("datefin")) && gererPret.get("datedeb") !== gererPret.get("datefin")) {
        periode += " " + i18n.t('common:gererprets.header_au') + " " + CWTYPE.DATE.format(gererPret.get("datefin"));
      }
      this.$el.find(".periode-header").html(periode);
    }
    //STATUT
    if (this.context.ctxModeInitialisation !== "Ajout" && !CWSTR.isBlank(gererPret.get("statut")) && !CWSTR.isBlank(gererPret.get("statut").code)) { //Statut
      this._paintWorkflowStatus();
    }
    //DESCRIPTION
    this._manageDescription();
  }

  _manageUtilisateur(): void {
    if (this.context.ctxUtilisateur === "Responsable" && !CWSTR.isBlank(this.context.ctxCollab) && this.context.ctxModeRepresentation === "pop-up") {
      let collabText = this.context.ctxCollab.nom + " " + this.context.ctxCollab.prenom;

      this.$el.find(".gererprets-utilisateur").show();
      // information du collaborateur
      if (CWSTR.isBlank(this.diver) || this.diver === "matric") {
        collabText += " (" + this.context.ctxCollab.matricule + ")";
      } else {
        collabText += " (" + this.context.ctxCollab.matric_aux + ")";
      }
      this.$el.find(".gererprets-utilisateur").html(collabText)
    } else {
      this.$el.find(".popup-header").hide();
    }
  }

  _manageDescription(): void {
    if (this.context.ctxTypeEvtGere === "P" || this.context.ctxUtilisateur === "Collaborateur") {
      this.$el.find(".description-header").html(i18n.t('common:gererprets.header_descripton_du_pret'));
    } else {
      this.$el.find(".description-header").html(i18n.t('common:gererprets.header_descripton_du_emprunt'));
    }
    if (this.context.ctxValeursEvenement && !CWSTR.isBlank(this.context.ctxValeursEvenement.PretEnSuppression) && this.context.ctxValeursEvenement.PretEnSuppression === true) {
      const statutLibelle = "<span class='ui-phx-titre-demande-suppression' >" + i18n.t('common:gererprets.header_info_lies_suppresion') + "</span>";

      this.$el.find(".description-header").html(statutLibelle);
    }
  }

  _manageModeInitialisation(originalCallback: any): void {
    const initMode = this.context.ctxModeInitialisation;
    const pret = new CWGererPretsModel();
    const callback = (fresh: any): void => {
      const hc = this.model.getHabContext().copy();

      this.affTypeSaisieOptionsColl.affectation = CWSTR.getElValue(fresh, "type.code");
      this.affModeSaisieOptionsColl.affectation = CWSTR.getElValue(fresh, "type.code");
      this.model.set("value", fresh, { silent: true }); //This will trigger a _mapToForm
      this.model.get("value").store();
      this.original = fresh.clone();
      hc.update({ foncCour: this.context.ctxHabilitation.HabilitationAcces, natGest: "" });
      this.typeStructures.setHabContext(hc);
      //Get structures in consultation in order to show their libelles.
      this.typeStructures.fetch({
        success: (): any => {
          const profilId = pret.get("code");
          const hc = this.model.getHabContext().copy();

          this.model.get("value").structures = this.model.get("value").get("structures");
          this._manageHeader();
          this._manageWKFHisto(this.context.ctxHistoriqueWKF);
          this._manageRestrictions(true);
          this._manageActionsPossibles();
          this._manageHabilitation();
          this._showHideFields();
          this._renderSelChemins();
          this._showPieceJointe();
          this._paintSimulateModeHeader();
          // EVO 458. if we have date and collabremplace we create initialise 'les rattachements aux structures'
          this._initRattEmpruntCollab(); //Launch _mapToForm in the last CALLBACK.
          this._enableDisableFields();
          if (this.$el.find(".liste-structures label:eq(0)").text().length > 18) {
            this.$el.find(".liste-structures label:eq(0)").css("margin-right", 30);
          }
          this.btnBar.model.trigger("hide:accepter");
          this.btnBar.model.trigger("hide:refuser");
          if (this.context.ctxUtilisateur !== "Collaborateur") {
            this.profilModel = new CWBaseModel();
            this.profilModel.url = (): any => {
              return Configuration.restRoot + "/rest/composant/pret/profils/" + encodeURIComponent(profilId);
            };
            hc.update({ foncCour: this.context.ctxHabilitation.HabilitationValidation, natGest: "" });
            this.profilModel.setHabContext(hc);
            this.profilModel.fetch({
              success: (freshProfil: any): void => {
                if (freshProfil.get("profils") && freshProfil.get("profils").length > 0) {
                  this.model.get("value").set("profils", freshProfil.get("profils"));
                  this.btnBar.configureValidationsButtonFromModel(this.model.get("value"));
                  this.btnBar.model.trigger("show:accepter");
                  this.btnBar.model.trigger("show:refuser");
                } else {
                  this.btnBar.model.trigger("hide:accepter");
                  this.btnBar.model.trigger("hide:refuser");
                }
                if (originalCallback) {
                  originalCallback();
                }
                this._manageRestrictions(this.isEditable);//on doit vérifier ici, autre fois, les possibles modifications des boutons
              }
            });
          } else {
            if (originalCallback) {
              originalCallback();
            }
            this._manageRestrictions(this.isEditable);//on doit vérifier ici, autre fois, les possibles modifications des boutons
          }
        }
      });
    };

    //réinitialisation des valeurs
    this.disabled = false;
    this.desactiveHabOuEtat = false;
    this.isEditable = true;
    this.errorPetAntSaveDelete = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    //-------------------
    if (CWSTR.isBlank(this.pretDefaults)) {
      this.pretDefaults = new TypeAffDefaults();
      this.pretDefaults.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces
      }));
    }
    this._initializeModeAndTypeColl();
    switch (initMode) {
      case "Consultation":
        this._initModeConsultation(pret, callback);
        break;
      case "Ajout":
        this._initModeAjout(pret);
        break;
      default:
      //Nothing
    }
  }

  _allValeursEvenementInformed(): boolean {
    let allInformed = false;
    const valerusEvenement = this.context.ctxValeursEvenement;

    if (!CWSTR.isBlank(valerusEvenement) && !CWSTR.isBlank(valerusEvenement.PretCodeStatutWKF) && !CWSTR.isBlank(valerusEvenement.PretEnSuppression) && !CWSTR.isBlank(valerusEvenement.PretType) &&
      !CWSTR.isBlank(valerusEvenement.PretPerimetre) && !CWSTR.isBlank(valerusEvenement.PretListeElementStructure) && !CWSTR.isBlank(valerusEvenement.PretTypeSaisie) && !CWSTR.isBlank(valerusEvenement.PretModeSaisie) &&
      !CWSTR.isBlank(valerusEvenement.PretDateDebut) && !CWSTR.isBlank(valerusEvenement.PretUnite) && !CWSTR.isBlank(valerusEvenement.PretUniteDebut) && !CWSTR.isBlank(valerusEvenement.PretDateFin) &&
      !CWSTR.isBlank(valerusEvenement.PretUniteFin) && !CWSTR.isBlank(valerusEvenement.PretHeureDebut) && !CWSTR.isBlank(valerusEvenement.PretHeureFin) && !CWSTR.isBlank(valerusEvenement.PretDuree) &&
      !CWSTR.isBlank(valerusEvenement.PretPourcentage) && !CWSTR.isBlank(valerusEvenement.PretMotif) && !CWSTR.isBlank(valerusEvenement.PretOrigine) && !CWSTR.isBlank(valerusEvenement.PretLibelle) &&
      !CWSTR.isBlank(valerusEvenement.PretCommentaire) && !CWSTR.isBlank(valerusEvenement.PretListeInfoComplValeur)) {
      allInformed = true;
    }
    if (this.context.ctxHistoriqueWKF === true) {
      allInformed = allInformed && !CWSTR.isBlank(valerusEvenement.PretCommentaireWKF);
    }
    return allInformed;
  }

  /**
   * Check if there is only one type of pret available and select it and initialize the rest of fields depending on it
   * in case there is only one available
   */
  _initTypePret(pret: any, callback: any): void {
    this.typeComboColl.fetch({
      success: (freshTypes: any): void => {
        if (freshTypes.models.length === 1) {
          //si l’utilisateur est habilité à un seul type de prêt, le proposer,
          CWSTR.setElValue(pret, "type.code", freshTypes.models[0].get("code"));
          CWSTR.setElValue(pret, "type.libelle", freshTypes.models[0].get("libelle"));
          this._typeChanged(pret, callback);
        } else {
          callback();
        }
      }
    });
  }

  /**
   * Initialize values of model as described in Règles d’initialisation du composant en mode création.
   */
  _initializeCreation(pret: any, callback: any): void {
    const pretType = this.context.ctxValeursCreation.PretType;
    const dateDeb = this.context.ctxValeursCreation.PretDateDebut;
    const dateFin = this.context.ctxValeursCreation.PretDateFin;

    if (CWSTR.isBlank(pretType) && CWSTR.isBlank(dateDeb) && CWSTR.isBlank(dateFin)) {
      //Si aucune donnée n'est renseignée dans ctxValeursCreation :
      pret.set("typesaisie", "D");
      pret.set("modesaisie", "UNITE");
      this._initTypePret(pret, callback);
    } else if (CWSTR.isBlank(pretType) && !CWSTR.isBlank(dateDeb) && !CWSTR.isBlank(dateFin)) {
      //Si le type de prêt n'est pas renseigné et les dates de début et fin sont renseignées dans ctxValeursCreation
      if (dateDeb === dateFin) {
        pret.set("typesaisie", "D");
        pret.set("modesaisie", "UNITE");
      } else {
        pret.set("typesaisie", "P");
        pret.set("modesaisie", "PERIOC");
      }
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.PretUniteDebut) && !CWSTR.isBlank(this.context.ctxValeursCreation.PretUniteFin)) {
        if (pret.get("typesaisie") === "P") {
          //Dans le cas où le type de saisie est à période et que l'unité de début n'est pas n'autorisée en 1er jour de période ou que l'unité de fin n'est pas n'autorisée en dernier jour de période proposer l'unité Jour
          pret.set("modesaisie", "PERIOC");
          this.unitesComboColl.fetch({
            success: (): void => {
              this.uniteDebComboColl.fetch({
                success: (): void => {
                  this.uniteFinComboColl.fetch({
                    success: (): void => {
                      const unitedeb = this.uniteDebComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
                      const unitefin = this.uniteFinComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteFin });

                      if (CWSTR.isBlank(unitedeb) || CWSTR.isBlank(unitefin)) {
                        pret.get("modesai_periodeb").code = "J";
                        pret.get("modesai_periofin").code = "J";
                      } else {
                        pret.get("modesai_periodeb").code = this.context.ctxValeursCreation.PretUniteDebut;
                        pret.get("modesai_periofin").code = this.context.ctxValeursCreation.PretUniteFin;
                      }
                      this.checkUnitesCoherence(pret);
                      this._initTypePret(pret, callback);
                    }
                  });
                }
              });
            }
          });
        } else {
          //	Si l'unité de début et l'unité de fin sont renseignées dans ctxValeursCreation, le mode de saisie est initialisé � � Unit� �.
          CWSTR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
          this._initTypePret(pret, callback);
        }
      } else {
        //Sinon  (Unité de début et/ou fin non renseignées), initialiser le mode de saisie avec le mode de saisie par défaut associé au type de saisie positionné.
        this._initTypePret(pret, callback);
      }
    } else if (CWSTR.isBlank(pretType) && !CWSTR.isBlank(dateDeb) && CWSTR.isBlank(dateFin)) {
      //Si la date de début est renseignée et pas le type de prêt et la date de fin dans ctxValeursCreation
      pret.set("typesaisie", "D");
      pret.set("modesaisie", "UNITE");
      CWSTR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
      this._initTypePret(pret, callback);
    } else if (CWSTR.isBlank(pretType) && CWSTR.isBlank(dateDeb) && !CWSTR.isBlank(dateFin)) {
      //Si la date de fin est renseignée et pas le type de prêt et la date de début dans ctxValeursCreation
      pret.set("datefin", "");
      this._initTypePret(pret, callback);
    } else if (!CWSTR.isBlank(pretType)) {
      //When type pret is informed, obtain default pret values, authorized typesaisie,modesaisie, unitedeb unitedeb for periode and unitefin for periode
      this.pretDefaults.id = pretType;
      this.pretDefaults.fetch({
        success: (fresh: any): void => {
          this.affTypeSaisieOptionsColl.affectation = pretType;
          this.affTypeSaisieOptionsColl.fetch({
            success: (): void => {
              this.affModeSaisieOptionsColl.affectation = pretType;
              this.affModeSaisieOptionsColl.fetch({
                success: (): void => {
                  this._updateCombosForType(pretType, () => {
                    this.initCreationModeWithType(pret, fresh, callback);
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  initCreationModeWithType(pret: any, defaultValues: any, callback: any): void {
    const pretType = this.context.ctxValeursCreation.PretType;
    const dateDeb = this.context.ctxValeursCreation.PretDateDebut;
    const dateFin = this.context.ctxValeursCreation.PretDateFin;
    let unitedeb = null,
      unitefin = null;

    if (!CWSTR.isBlank(pretType) && CWSTR.isBlank(dateDeb) && CWSTR.isBlank(dateFin)) {
      //Si seule type de pret est renseignée dans ctxValeursCréation
      this.setDefaultTypeModeValues(pret, defaultValues, true);
    } else if (!CWSTR.isBlank(pretType) && !CWSTR.isBlank(dateDeb) && !CWSTR.isBlank(dateFin)) {
      //Si le type de prêt et les dates de début et fin sont renseignées dans ctxValeursCreation :
      if (dateDeb === dateFin) {
        pret.set("typesaisie", "D");
      } else {
        const periodeFound = this.affTypeSaisieOptionsColl.findWhere({ code: "P" });
        const repetitiveFound = this.affTypeSaisieOptionsColl.findWhere({ code: "R" });

        if (!CWSTR.isBlank(periodeFound) && !CWSTR.isBlank(repetitiveFound)) {
          if (this.pretDefaults.get("modadef").code === "P" || this.pretDefaults.get("modadef").code === "R") {
            pret.set("typesaisie", this.pretDefaults.get("modadef").code);
          } else {
            pret.set("typesaisie", "R");
          }
        } else if ((CWSTR.isBlank(periodeFound) && !CWSTR.isBlank(repetitiveFound)) ||
          (!CWSTR.isBlank(periodeFound) && CWSTR.isBlank(repetitiveFound))) {
          pret.set("typesaisie", this.pretDefaults.get("modadef").code);
        } else {
          pret.set("typesaisie", "D");
          pret.set("datefin", "");
        }
      }
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.PretUniteDebut) && !CWSTR.isBlank(this.context.ctxValeursCreation.PretUniteFin)) {
        if (pret.get("typesaisie") === "P") {
          unitedeb = this.uniteDebComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
          unitefin = this.uniteFinComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteFin });
        } else {
          unitedeb = this.unitesComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
          unitefin = this.unitesComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteFin });
        }
        if ((pret.get("typesaisie") === "P" && !CWSTR.isBlank(unitedeb) && !CWSTR.isBlank(unitefin)) || (pret.get("typesaisie") !== "P" && !CWSTR.isBlank(unitedeb))) {
          const modesaisie = pret.get("typesaisie") === "P" ? "PERIOC" : "UNITE";
          const callbackControlPeriode = (): void => {
            if (pret.get("typesaisie") === "P") {
              const unitedeb = this.uniteDebComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
              const unitefin = this.uniteFinComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteFin });
              if (CWSTR.isBlank(unitedeb) || CWSTR.isBlank(unitefin)) {
                pret.set("modesai_periodeb", this.uniteDebComboColl.at(0));
                pret.set("modesai_periofin", this.uniteFinComboColl.at(0));
              }
            }
          };

          //Si elles sont n'autorisées pour le type de prêt et le type de saisie : le mode de saisie est initialisé à l'Unité.
          pret.set("modesaisie", modesaisie);
          this.checkUnitesCoherence(pret, callbackControlPeriode);
        } else { //Initialisation Mode de saisie et composants associés Cas 1
          this._initModeCas1(pret, defaultValues);
        }
      } else {
        this.setDefaultTypeModeValues(pret, defaultValues, false);
      }
    } else if (!CWSTR.isBlank(pretType) && !CWSTR.isBlank(dateDeb) && CWSTR.isBlank(dateFin)) {
      //Si le type de prêt et la date de début sont renseignés et pas la date de fin dans ctxValeursCreation
      pret.set("typesaisie", "D");
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.PretUniteDebut)) { //Si l'unité de début est renseignée dans ctxValeursCreation
        unitedeb = this.unitesComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
        if (!CWSTR.isBlank(unitedeb)) { //Si elle est autorisée pour le type du pret et le type de saisie «Date»
          pret.set("modesaisie", "UNITE");
          CWSTR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
          this.checkUnitesCoherence(pret);
        } else {
          // Initialisation Mode de saisie et composants associés Cas 1
          this._initModeCas1(pret, defaultValues);
        }
      } else {
        // Initialisation Mode de saisie et composants associés Cas 1
        this._initModeCas1(pret, defaultValues);
      }
    } else if (!CWSTR.isBlank(pretType) && CWSTR.isBlank(dateDeb) && !CWSTR.isBlank(dateFin)) {
      //Si le type de prêt et la date de fin sont renseignés et pas la date de début dans ctxValeursCreation
      this.setDefaultTypeModeValues(pret, defaultValues, true);
    }
    if (callback) {
      callback();
    }
  }

  _initModeCas1(pret: any, defaultValues: any): void {
    if (pret.get("typesaisie") === "P") {
      //Type de saisie «Période»: Initialiser le mode de saisie par défaut pour une période continue
      //Et initialiser les composants de saisie associés à vide sans tenir compte des valeurs de ctxValeursCréation
      pret.set("heuredeb", "");
      pret.set("heurefin", "");
    }
    this.setDefaultTypeModeValues(pret, defaultValues, false);
  }

  /**
   * Obtain structures to be used in the form. They depend on the typeEvt (pret or emprunt) and the perimetre.
   * Depending this fields, the habilitation used to filter will be one or another and values will change.
   * Used to obtain the structures to be used in Ajout mode. Should be reloaded when perimetre changes
   */
  _getStructuresPerTypeEvtAndper(datedeb: any, model: CWBaseModel | any, callback: any): void {
    const hc = this.model.getHabContext().copy();
    const habilitationGestion = this._getHabilitationGestion(model);
    let structRattPret = false;
    let structRattPretAndCollab = false;

    // Update the main model habilitation with the perimetre (normal or etendu)
    this.model.getHabContext().update({ foncCour: habilitationGestion });
    hc.update({ foncCour: habilitationGestion, natGest: "" });
    this.typeStructures.setHabContext(hc);
    if (!CWSTR.isBlank(datedeb)) {
      this.typeStructures.datedeb = datedeb;
    }
    this.typeStructures.fetch({
      success: (): void => {
        const structures: any = [], structuresFull: any = [];

        _.each(this.typeStructures.models, (structModel: any) => {
          const obj = { type: structModel.get("code"), element: "" };
          const objFull = { type: structModel.get("code"), element: "", rattcollab: structModel.get("rattcollab") };

          if (!structRattPret && structModel.get("rattpret")) {
            structRattPret = true;
          }
          if (!structRattPretAndCollab && structModel.get("rattpret") && (structModel.get("rattcollab") === "O" || structModel.get("rattcollab") === "F")) {
            structRattPretAndCollab = true;
          }
          // Type is "Non concerne"
          if (structModel.get("rattcollab") === "N") {
            // Hide it
            return;
          }
          structuresFull.push(objFull);
          structures.push(obj);
        });
        if (!structRattPret) {
          CWMSGS.showError(i18n.t('messages:GT_1429'), null, this.workflow.$appendTo);
          this.parent.close();
        } else if (!structRattPretAndCollab) {
          CWMSGS.showError(i18n.t('messages:GT_1430'), null, this.workflow.$appendTo);
          this.parent.close();
        } else {
          model.set("structures", structures);
          model.structures = structuresFull;
          if (callback) {
            callback();
          }
        }
      }
    });
  }

  /**
   *
   */
  _paintSimulateModeHeader(): void {
    if (this.context.ctxEcran === "planresp" && this.context.ctxModeRepresentation === "pop-up" && !CWSTR.isBlank(this.context.ctxSimulation) && !CWSTR.isBlank(this.context.ctxEvtEstSimule)) {
      const msg = this.context.ctxEvtEstSimule.libelle;

      this.$el.find(".journeeSimulated").html(msg).show();
    } else {
      this.$el.find(".journeeSimulated").hide();
    }
  }

  /**
   * Get the statut style of the statut of a pret
   * Posible values are the same than posible values for prets
   */
  _getWorkflowStyle(statut: string): string {
    switch (statut) {
      case "A":
        return "ui-phx-statut-accepte";
      case "D":
      case "T":
        return "ui-phx-statut-demande";
      case "I":
        return "ui-phx-statut-en-cours";
      case "R":
        return "ui-phx-statut-refuse";
      case "H":
        return "ui-phx-statut-hors-validation";
      default:
        return "";
    }
  }

  _manageActionsPossibles(): void {
    let actionsPossibles = this.context.ctxActionsPossibles;//["Creer","Supprimer","Modifier","Valider"]

    if (!CWSTR.isBlank(this.context.ctxSimulation)) {
      const evtEstSimule = this.context.ctxEvtEstSimule && this.context.ctxEvtEstSimule.valeur ? this.context.ctxEvtEstSimule.valeur : this.context.ctxEvtEstSimule;

      if (this.context.ctxDonneeSimulee === false) {
        actionsPossibles = _.reject(actionsPossibles, (d) => { return (d === "Modifier" || d === "Supprimer") });
      }
      if (String(evtEstSimule) !== "3" && String(evtEstSimule) !== "4" && String(evtEstSimule) !== "5" && this.context.ctxCollab.type !== "S") {
        actionsPossibles = _.reject(actionsPossibles, (d) => { return (d === "Supprimer") });
      }
    }
    if (actionsPossibles && this.context.ctxModeInitialisation === "Ajout") {
      if (actionsPossibles.indexOf("Creer") === -1 || this.context.ctxModeRepresentation === "pop-up") {
        this.btnBar.model.trigger("hide:new");
      }
    } else if (actionsPossibles && this.context.ctxModeInitialisation === "Consultation") {
      if (actionsPossibles.indexOf("Creer") === -1 || this.context.ctxModeRepresentation === "pop-up") {
        this.btnBar.model.trigger("hide:new");
      }
      if (actionsPossibles.indexOf("Supprimer") === -1) {
        this.btnBar.model.trigger("hide:delete");
      }
      if (actionsPossibles.indexOf("Modifier") === -1) {
        this._disableForm();
      }
      if (actionsPossibles.indexOf("Creer") === -1 && actionsPossibles.indexOf("Modifier") === -1) {
        this.btnBar.model.trigger("hide:save");
        this.btnBar.model.trigger("hide:revert");
      }
      if (actionsPossibles.indexOf("Valider") === -1) {
        this.btnBar.model.trigger("hide:valider");
      }
    }
  }

  _manageHabilitation(): void {
    const habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    const peutCreer = (!CWSTR.isBlank(habilitationGestion)) ? CWHABILITATION.canCreate(habilitationGestion) : false;
    const peutModifier = (!CWSTR.isBlank(habilitationGestion)) ? CWHABILITATION.canUpdate(habilitationGestion) : false;
    const peutSupprimer = (!CWSTR.isBlank(habilitationGestion)) ? CWHABILITATION.canDelete(habilitationGestion) : false;
    const peutValider = (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationValidation)) ? CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation) : false;
    let isFormDesactive = false;

    if (this.model.get("value").isNew()) {
      this.isEditable = peutCreer;
    } else {
      this.isEditable = peutModifier;
    }
    if (!peutCreer) {
      this.btnBar.model.trigger("hide:new");
      this.desactiveHabOuEtat = true;
    }
    if (!peutModifier && !peutCreer) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
      this._disableForm();
      this.desactiveHabOuEtat = true;
      isFormDesactive = true;
    }
    if (!peutSupprimer) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!peutValider) {
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:accepter");
      this.btnBar.model.trigger("hide:refuser");
    }
    if (this.context.ctxModeInitialisation === "Ajout" && !CWHABILITATION.canView(habilitationGestion)) {
      if (!isFormDesactive) {
        this._disableForm();
      }
      this.desactiveHabOuEtat = true;
    }
  }

  _updateSaveButtonState(): void {
    this._manageRestrictions();
  }

  /**
   * Restrictions to be set depending on the current statut
   */
  _manageRestrictions(editable?: boolean): void {
    const gererPrets = this.model.get("value");
    const model = this.model.get("value");

    if (editable === false) {
      this._disableForm();
    }
    if (editable === true && this.disabled === true) {
      this._enableForm();
    }
    if (this.context?.ctxUtilisateur === "Collaborateur" || this.context?.ctxEcran === "valevt") {
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:new");
      this._disableForm();
    } else if (this.context && this.context.ctxUtilisateur === "Responsable" && !CWSTR.isBlank(model.get("statut"))) {
      if (gererPrets.get("est_createur") !== true && this.model.get("value").get("etat") === "D") {
        this.btnBar.model.trigger("hide:delete");
      } else if ((gererPrets.get("est_createur") === true && (this.model.get("value").get("etat") === "D" || this.model.get("value").get("etat") === "X")) || (this.model.get("value").get("etat") === "A")) {
        this.btnBar.model.trigger("show:delete");
      }
      switch (model.get("etat")) {
        case "D": //Demande
        case "X": //Demande
          if (gererPrets.get("est_createur") !== true) {
            this._disableForm();
            this.desactiveHabOuEtat = true;
          }
          if (!_.isEmpty(this.profilModel?.get("profils")) || !_.isEmpty(gererPrets?.get("profil"))) {
            this.btnBar.model.trigger("show:valider");
          }
          break;
        case "A": //Accepte
          //valideurfinal is checked in metiere part by ws updateControlPretCollab(is modifiable)
          this.btnBar.model.trigger("hide:valider");
          break;
        case "R": //Refusé
          this.btnBar.model.trigger("hide:delete");
          this.btnBar.model.trigger("hide:valider");
          this._disableForm();
          this.desactiveHabOuEtat = true;
          break;
        default:
          if (editable !== false) {
            this._enableForm();
          }
      }
    }
  }

  _disableForm(): void {
    CWFORMS.setFormReadonly(this.$el, true, false);
    this.disabled = true;
    this._desactiverPieceJointe(true);
  }

  _enableForm(disableActionchemin?: boolean): void {
    CWFORMS.setFormReadonly(this.$el, false, false);
    this.disabled = false;
    this._enableDisableFields();
    this._desactiverPieceJointe(false);
    if (disableActionchemin === true) {
      this._mapToForm();
    }
  }

  _disableInfoComp(): void {
    CWFORMS.setFormReadonly(this.$el.find(".gererprets-infocomp"), true, false);
    this.disabled = true;
  }

  _enableInfoComp(): void {
    CWFORMS.setFormReadonly(this.$el.find(".gererprets-infocomp"), false, false);
    this.disabled = false;
  }

  _enableDisableFields(notModifChemin?: boolean): void {
    const gererPret = this.model.get("value");

    if (!gererPret.isNew()) { //This is not a creation
      CWFORMS.setFieldReadonly(this.$el.find(".type\\.code"), true, false);
      this._enableDisablePerimetre();
      if (notModifChemin !== true) {
        _.each(this.listeCheminViews, (selChemin: any) => {
          selChemin.setReadOnly(true);
        });
      }
      this.typeSaisie.setMenuAccess(false, true);
      this._enableModeSaisie();
      CWFORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periodeb\\.code"), true, false);
      CWFORMS.setFieldReadonly(this.$el.find(".modeSaisie .unitedeb\\.code"), true, false);
      CWFORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periofin\\.code"), true, false);
      if (gererPret.get("typesaisie") === "P") { //For typeSaisie=Periode, dates modifiable only in creation mode
        CWFORMS.setFieldReadonly(this.$el.find(".datedeb"), true, false);
        CWFORMS.setFieldReadonly(this.$el.find(".datefin"), true, false);
      }
    } else {
      CWFORMS.setFieldReadonly(this.$el.find(".type\\.code"), false, false);
      this.typeSaisie.setMenuAccess(true, true);
      this.modeSaisie.setMenuAccess(true, true);
      _.each(this.listeCheminViews, (selChemin: any) => {
        selChemin.setReadOnly(false);
      });
      CWFORMS.setFieldReadonly(this.$el.find(".unitedeb\\.code"), false, false);
      CWFORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periodeb\\.code"), false, false);
      CWFORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periofin\\.code"), false, false);
      CWFORMS.setFieldReadonly(this.$el.find(".datedeb"), false, false);
      this._enableDisablePerimetre();
    }
  }

  _enableModeSaisie(): void {
    const gererPret = this.model.get("value");
    let lbVal = true; //par defaut

    if (!gererPret.isNew() || CWSTR.isBlank(CWSTR.getElValue(gererPret, "type.code"))) {
      lbVal = false;
    }
    if (this.modeSaisie) {
      this.modeSaisie.setMenuAccess(lbVal, true);
    }
  }

  _enableDisablePerimetre(): void {
    if (this.context.ctxPerimetreFige === true || (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionNormal) && !CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionEtendu))) {
      CWFORMS.setFieldReadonly(this.$el.find(".perimetre\\.code"), true, false);
    } else if (this.disabled !== true) {
      CWFORMS.setFieldReadonly(this.$el.find(".perimetre\\.code"), false, false);
    }
  }

  _showHideFields(): void {
    if (this.context.ctxUtilisateur === "Collaborateur") {
      this.$el.find("comment_pret_div").hide();
    } else {
      this.$el.find("comment_pret_div").show();
    }
    if (this.context.ctxUtilisateur === "Responsable" && !CWSTR.isBlank(this.context.ctxIdentifiantEvt)) {
      this.$el.find("gererprets-infocomp").show();
    } else {
      this.$el.find("gererprets-infocomp").hide();
    }
    if (this.context.ctxHistoriqueWKF === true) {
      this.$el.find(".commentaire_div").show();
    } else {
      this.$el.find(".commentaire_div").hide();
    }
  }


  _initializePretFromContext(fresh: any): void {
    if (!CWSTR.isBlank(this.context.ctxValeursEvenement)) {
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretType)) {
        CWSTR.setElValue(fresh, "type.code", this.context.ctxValeursEvenement.PretType);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretListeElementStructure) && this.context.ctxValeursEvenement.PretListeElementStructure.length > 0) {
        const arrayStructures: any = [];

        _.each(this.context.ctxValeursEvenement.PretListeElementStructure, (structObj) => {
          arrayStructures.push(structObj);
        });
        CWSTR.setElValue(fresh, "structures", arrayStructures);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretEnSuppression)) {
        CWSTR.setElValue(fresh, "demandesuppression", this.context.ctxValeursEvenement.PretEnSuppression);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretTypeSaisie)) {
        CWSTR.setElValue(fresh, "typesaisie", this.context.ctxValeursEvenement.PretTypeSaisie);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretDateDebut)) {
        CWSTR.setElValue(fresh, "datedeb", this.context.ctxValeursEvenement.PretDateDebut);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretDateFin)) {
        CWSTR.setElValue(fresh, "datefin", this.context.ctxValeursEvenement.PretDateFin);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretModeSaisie)) {
        CWSTR.setElValue(fresh, "modesaisie", this.context.ctxValeursEvenement.PretModeSaisie);
      }
      //Hors perimetre V2
      //			if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretHeureDebut))
      //				CWSTR.setElValue(fresh,"heuredeb",this.context.ctxValeursEvenement.PretHeureDebut);
      //			if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretHeureFin ))
      //				CWSTR.setElValue(fresh,"heurefin",this.context.ctxValeursEvenement.PretHeureFin);
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretUnite)) {
        CWSTR.setElValue(fresh, "unitedeb.code", this.context.ctxValeursEvenement.PretUniteDebut);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretUniteDebut)) {
        CWSTR.setElValue(fresh, "modesai_periodeb.code", this.context.ctxValeursEvenement.PretUniteDebut);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretUniteFin)) {
        CWSTR.setElValue(fresh, "modesai_periofin.code", this.context.ctxValeursEvenement.PretUniteFin);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretMotif)) {
        CWSTR.setElValue(fresh, "motif.code", this.context.ctxValeursEvenement.PretMotif);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretOrigine)) {
        CWSTR.setElValue(fresh, "origine.code", this.context.ctxValeursEvenement.PretOrigine);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretLibelle)) {
        CWSTR.setElValue(fresh, "libelle", this.context.ctxValeursEvenement.PretLibelle);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretCommentaire)) {
        CWSTR.setElValue(fresh, "commentaire_pret", this.context.ctxValeursEvenement.PretCommentaire);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretCommentaireWKF)) {
        CWSTR.setElValue(fresh, "commentaire", this.context.ctxValeursEvenement.PretCommentaireWKF);
      }
      if (!CWSTR.isBlank(this.context.ctxValeursEvenement.PretListeInfoComplValeur)) {
        CWSTR.setElValue(fresh, "infocomp", this.context.ctxValeursEvenement.PretListeInfoComplValeur);
      }
    }
  }

  _manageLink(event: any): void {
    const element = event.target.className.split(" ")[0];

    switch (element) {
      case "montreraide": // SHOW HELP
        this.$el.find(".montreraide").hide();
        this.$el.find(".masqueraide").show();
        this.$el.find(".indication").show();
        break;
      case "masqueraide": // HIDE HELP
        this.$el.find(".indication").hide();
        this.$el.find(".masqueraide").hide();
        this.$el.find(".montreraide").show();
        break;
      default:
      //Nothing
    }
  }

  render(): CWGererPretView {
    const json = { "i18n": i18n };

    this.$el.empty();
    this.$el.html(this.template(json));
    this._renderDeroulantLabels();
    this.renderForm();
    if (this.context) {
      this.setContext(this.context);
    }
    //Dialog Position
    if (this.parent) {
      this.parent.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
      this.$el.parent().parent().css({ "min-width": "600px" });
      this.$el.parent().parent().find(".ui-dialog-titlebar").removeClass("ui-helper-clearfix");
    }
    return this;
  }

  _renderDeroulantLabels(): void {
    const json = { "i18n": i18n };
    const tplTypeSaisie = TPLGererpretsMultiLabelTypeSaisie;
    const tplModeSaisie = TPLGererpretsMultiLabelModeSaisie;

    this.typeSaisie = new CWMultiLabel({
      template: tplTypeSaisie(json),
      required: true,
      name: "typesaisie",
      changeLabelCallback: this._changeMultiLabel,
      parentView: this
    });
    this.$el.find(".typeSaisie").html(this.typeSaisie.render().el);
    //Filter typeSaisie
    this.typeSaisie.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, (resp: { [key: string]: any }): boolean => {
        let found = false;

        if (!CWSTR.isBlank(this.affTypeSaisieOptionsColl) && this.affTypeSaisieOptionsColl.models.length > 0) {
          _.each(this.affTypeSaisieOptionsColl.models, (typeSaisie: any) => {
            if (String(typeSaisie.get("code")) === String(resp.name)) {
              found = true;
            }
          });
        } else {
          found = true;
        }
        return found;
      });
    });
    this.modeSaisie = new CWMultiLabel({
      template: tplModeSaisie(json),
      required: true,
      name: "modesaisie",
      changeLabelCallback: this._changeMultiLabel,
      parentView: this
    });
    this.$el.find(".modeSaisie").html(this.modeSaisie.render().el);
    //Filter typeSaisie
    this.modeSaisie.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, (resp: { [key: string]: any }): boolean => {
        let found = false;

        if (!CWSTR.isBlank(this.affModeSaisieOptionsColl) && this.affModeSaisieOptionsColl.models.length > 0) {
          _.each(this.affModeSaisieOptionsColl.models, (modeSaisie: any) => {
            if (String(modeSaisie.get("code")) === String(resp.name)) {
              found = true;
            }
          });
        } else {
          found = true;
        }
        return found;
      });
    });
  }

  _renderSelChemins(): void {
    const gererPrets = this.model.get("value");
    const structures = gererPrets.structures;

    this.listeCheminViews = [];
    $(this.el).find(".liste-structures").html("");
    _.each(structures, (struct: any) => {
      const type = _.find(this.typeStructures.models, (structModel: any) => {
        return String(structModel.get("code")) === String(struct.type);
      });

      if (!CWSTR.isBlank(type)) {
        const label = "<label for='structures_itemid_" + struct.type + "' class='" + (struct.rattcollab === "O" ? "cw-required" : "") + "'>" + type.get("libelle") + "</label>";
        const readonly = gererPrets.isNew() ? false : true;
        let comboChemin: CWSelecteurCheminView = null;
        const errorContainer = "<span class='structures_itemid_" + struct.type + "-error-container'></span>";

        $(this.el).find(".liste-structures").append(label);
        comboChemin = new CWSelecteurCheminView({
          name: "structures_itemid_" + struct.type,
          listRenderStyle: 1, //format
          fieldWidth: 200,
          height: 500,
          width: 850,
          structid: struct.type, //code
          addItemCallback: (data: any, index: any): void => {
            this._responseCallbackChemin(data, index, struct.type);
          },
          intermediaire: true,
          partial: false,
          modeComplet: true,
          multiselection: false,
          nonrattachable: false,
          readonly: readonly,
          habContext: this.model.getHabContext() // Use the current habilitation context
        });
        $(this.el).find(".liste-structures").append(comboChemin.render().el);
        $(this.el).find(".liste-structures").append(errorContainer);
        $(this.el).find(".structures_itemid_" + struct.type + "-error-container").append("<br>");
        this.listeCheminViews.push(comboChemin);
      }
    });
  }

  /**
   * Same as Change but for multilabel component
   */
  _changeMultiLabel(name: any, selectedValue: any): void {
    const gererPret = this.model.get("value");

    CWSTR.setElValue(gererPret, name, selectedValue);
    if (gererPret.isNew()) { //Flag to know if modesaisie or typesaisie have changed in creation mode;
      this.saisieChanged = true;
    }
    this.paintDates();
  }
  /**
   * Type Saisie change event.
   */
  _changeTypeSaisie(event: any): void {
    const className = event.target.className.split(" ")[0];

    if (className === "P") {
      this.model.get("value").set("modesaisie", "PERIOC");
      this.$el.find(".modeSaisie").hide();
      this.model.get("value").attributes["modesai_periodeb"] = this.pretDefaults.get("unitedebdef");
      this.model.get("value").attributes["modesai_periofin"] = this.pretDefaults.get("unitefindef");
    } else {
      const value = this.model.get("value");

      if (value.get("modesaisie") === "PERIOC") {
        const modeSaisieOptionsInformed = !CWSTR.isBlank(this.affModeSaisieOptionsColl) && this.affModeSaisieOptionsColl.models.length > 0;
        let modesaisie = "UNITE"; //Default mode saisie=unites//TODO when adding new modesaisie, select the first as default

        //When changing from type saisie PERIOD to another typesaisie select first valid modesaisie
        if (modeSaisieOptionsInformed === true) {
          modesaisie = modeSaisieOptionsInformed === true ? this.affModeSaisieOptionsColl.at(0).get("code") : "PLH";
        }
        value.set("modesaisie", modesaisie);
      }
      this.$el.find(".modeSaisie").show();
    }
    this._mapToForm();
  }

  renderForm(callback?: any): CWGererPretView | any {
    //Render combo
    this.$el.find(".cmbType").html(this.comboType.render().el);
    this.$el.find(".cmbPerimetre").html(this.comboPerimetre.render().el);
    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);
    this.$el.find(".cmbOrigine").html(this.comboOrigine.render().el);
    this.$el.find(".comboUniteDeb").html(this.comboUniteDebut.render().el);
    this.$el.find(".comboUniteFin").html(this.comboUniteFin.render().el);
    this.$el.find(".comboUnites").html(this.comboUnites.render().el);
    if (callback) {
      callback();
    }
    return this;
  }

  _getTypegest(callback?: any): void {
    this.typeGestionOfCollab = new CWTypeGestionModel({
      collab: this.context.ctxCollab.matricule,
      date: this._getModel().get("datedeb")
    });
    this.typeGestionOfCollab.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.typeGestionOfCollab.fetch({
      success: (fresh: any): void => {
        callback(fresh.get("typegest"));
        this.typeGestionOfCollab.typegest = fresh.get("typegest");
      }
    });
  }

  /**
   *
   */
  _updateCombosForType(typeCode?: any, callback?: any): void {
    const setUnitesCallback = (typeGest: any): void => {
      if (CWSTR.isBlank(this.unitesComboColl) || CWSTR.isBlank(this.uniteDebComboColl) || CWSTR.isBlank(this.uniteFinComboColl)) {
        return;
      }
      this.unitesComboColl.params = { typegest: typeGest, dateper: 'DREPD', codeaff: typeCode };
      this.unitesComboColl.fetch({
        success: (): void => {
          this.uniteDebComboColl.params = { code: typeCode };
          this.uniteDebComboColl.fetch({
            success: (): void => {
              this.uniteFinComboColl.params = { code: typeCode };
              this.uniteFinComboColl.fetch({
                success: (): void => {
                  this.comboUnites.enum = this.unitesComboColl.map(this._mapUnitesForCombo);
                  this.comboUniteFin.enum = this.uniteFinComboColl.map(this._mapUnitesForCombo);
                  this.comboUniteDebut.enum = this.uniteDebComboColl.map(this._mapUnitesForCombo);
                  this.comboUnites.clearCache();
                  this.comboUniteDebut.clearCache();
                  this.comboUniteFin.clearCache();
                  if (callback) {
                    callback();
                  }
                }
              });
            }
          });
        }
      });
    };

    this._getTypegest(setUnitesCallback);
  }

  _mapUnitesForCombo(model: CWBaseModel | any): any {
    return { code: model.get("code"), libelle: model.get("libelle") };
  }
  /**
   *
   */
  _emptyCombosUnite(): void {
    if (this.unitesComboColl) {
      this.unitesComboColl.params = {};
    }
    if (this.comboUnites && !_.isEmpty(this.comboUnites.coll)) {
      this.comboUnites.clearCache();
      this.comboUnites.clearColl();
    }
    if (this.uniteDebComboColl) {
      this.uniteDebComboColl.params = {};
    }
    if (this.comboUniteDebut && !_.isEmpty(this.comboUniteDebut.coll)) {
      this.comboUniteDebut.clearCache();
      this.comboUniteDebut.clearColl();
    }
    if (this.uniteFinComboColl) {
      this.uniteFinComboColl.params = {};
    }
    if (this.comboUniteFin && !_.isEmpty(this.comboUniteFin.coll)) {
      this.comboUniteFin.clearCache();
      this.comboUniteFin.clearColl();
    }
  }

  /**
   * When type  has changed, or when iniitalizing type because there is only one available for user
   * - prepare combos url for that type.
   * - Obtain default values for the type
   * - And set them for : typeSaisie and modesaisie
   *                      motifs and origine
   */
  _typeChanged(gererPret?: any, callbackType?: any): void {
    const typeCode = CWSTR.getElValue(gererPret, "type.code");

    if (!CWSTR.isBlank(typeCode)) {
      if (this.pretDefaults === null) {
        this.pretDefaults = new TypeAffDefaults();
        this.pretDefaults.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: this.context.ctxHabilitation.HabilitationAcces
        }));
      }
      this.pretDefaults.id = typeCode;
      this._updateCombosForType(this.pretDefaults.id, () => {
        this.pretDefaults.fetch({
          success: (fresh: any): void => {
            gererPret.set("infocomp", fresh.get("infocomp"));
            this.model.structures = fresh.get("structures");
            this.affTypeSaisieOptionsColl.affectation = typeCode;
            this.affTypeSaisieOptionsColl.fetch({
              success: (): void => {
                this.affModeSaisieOptionsColl.affectation = typeCode;
                this.affModeSaisieOptionsColl.fetch({
                  success: (): void => {
                    let modeSaisieFound = null;
                    let emptyEnteringContext = false;
                    let currentTypeSaisie: string = null;
                    let currentModeSaisie: string = null;
                    let typeSaisieFound = null;

                    //Set default motif and origine
                    this.setDefaultMotifInitiaValues(gererPret, fresh);
                    //Set default type et mode
                    this.setDefaultTypeModeValues(gererPret, fresh, true);
                    currentTypeSaisie = gererPret.get("typesaisie");
                    currentModeSaisie = gererPret.get("modesaisie");
                    typeSaisieFound = _.find(this.affTypeSaisieOptionsColl.models, (typesaisieModel: any) => {
                      return String(typesaisieModel.get("code")) === String(currentTypeSaisie);
                    });
                    if (currentTypeSaisie === "P") {
                      //When current typesaisie=Period, current modesaisie is going to be  perioc but this modesaisie doesn't come in the WS of mode
                      //Saisies allowed, so we suppose it is allowed
                      modeSaisieFound = "PERIOC";
                    } else {
                      modeSaisieFound = _.find(this.affModeSaisieOptionsColl.models, (modesaisieModel: any) => {
                        return String(modesaisieModel.get("code")) === String(currentModeSaisie);
                      });
                    }
                    //Le type de saisie ou le mode de saisie ne fait pas partie des types et modes de saisies autorisés paramétrés pour l’activité,
                    //Le type de saisie et le mode de saisie correspondent à une initialisation suite à un contexte de création sans valeurs renseignées
                    //et il n’y a pas eu de modification du type et/ou du mode ou de saisie de valeurs par l’utilisateur
                    if (this.context.ctxValeursCreation) {
                      emptyEnteringContext = CWSTR.isBlank(this.context.ctxValeursCreation.PretType) && CWSTR.isBlank(this.context.ctxValeursCreation.PretDateDebut) && CWSTR.isBlank(this.context.ctxValeursCreation.PretDateFin);
                    } else {
                      if (gererPret.isNew()) {
                        emptyEnteringContext = true;
                      }
                    }
                    if (CWSTR.isBlank(typeSaisieFound) || CWSTR.isBlank(modeSaisieFound) || (gererPret.isNew() && emptyEnteringContext === true && this.saisieChanged !== true)) {
                      this.setDefaultTypeModeValues(gererPret, fresh, true);
                      this._mapToForm();
                      if (callbackType) {
                        callbackType();
                      }
                    } else {
                      const callback = (): void => {
                        this._mapToForm();
                        if (callbackType) {
                          callbackType();
                        }
                      };

                      if (this.context.ctxValeursCreation) {
                        if (CWSTR.isBlank(this.context.ctxValeursCreation.PretUniteDebut) && CWSTR.isBlank(this.context.ctxValeursCreation.PretHeureDebut) &&
                          CWSTR.isBlank(this.context.ctxValeursCreation.PretUniteFin) && CWSTR.isBlank(this.context.ctxValeursCreation.PretHeureFin)) {
                          this.setDefaultTypeModeValues(gererPret, fresh, false);
                        }
                      }
                      this.checkUnitesCoherence(gererPret, callback);
                    }
                  }
                });
              }
            });
          }
        });
      });
    } else {
      this._emptyCombosUnite();
      this._initializeModeAndTypeColl();
      if (callbackType) {
        callbackType();
      }
    }
  }

  /**
   * Set the default pret values for motif and origine if the user hasn't selected a value for them before
   */
  setDefaultMotifInitiaValues(gererPret: any, defaultValues: any): void {
    if (this.motifChanged !== true) {
      CWSTR.setElValue(gererPret, "motif.code", defaultValues.get("motif").code);
    }
    if (this.origineChanged !== true) {
      CWSTR.setElValue(gererPret, "origine.code", defaultValues.get("origine").code);
    }
  }

  /**
   * Set the default pret values for current type
   */
  setDefaultTypeModeValues(gererPret: any, defaultValues: any, changeTypeSaisie: any): void {
    let periodeb = null,
      periofin = null;

    if (!CWSTR.isBlank(defaultValues.get("repetdef")) && !CWSTR.isBlank(defaultValues.get("repetdef").code)) {
      gererPret.set("modesaisie", defaultValues.get("repetdef").code);
    } else {
      const modeSaisieFound = this.affModeSaisieOptionsColl.findWhere({ code: gererPret.get("modesaisie") });

      //Check that current modesaisie is valid for pret, if it is not,set one by default
      if (CWSTR.isBlank(modeSaisieFound)) {
        const firstModel = this.affModeSaisieOptionsColl.at(0);
        const code = !CWSTR.isBlank(firstModel) ? firstModel.get("code") : null;

        gererPret.set("modesaisie", code);
      }
    }
    if (!CWSTR.isBlank(defaultValues.get("modadef")) && !CWSTR.isBlank(defaultValues.get("modadef").code)) {
      if (changeTypeSaisie !== false) {
        gererPret.set("typesaisie", defaultValues.get("modadef").code);
      }
      if (gererPret.get("typesaisie") === "P") { //If typesaisie=P, modesaisie is PERIOC
        gererPret.set("modesaisie", "PERIOC");
      }
    }
    gererPret.set("unitedeb", defaultValues.get("unitedef"));
    this.model.get("value").attributes["modesai_periodeb"] = this.pretDefaults.get("unitedebdef");
    this.model.get("value").attributes["modesai_periofin"] = this.pretDefaults.get("unitefindef");
    if (!CWSTR.isBlank(defaultValues.get("perdef")) &&
      !CWSTR.isBlank(defaultValues.get("perdef").code)) {
      switch (defaultValues.get("perdef").code) {
        // Cas du mode saisie en Unite de début /fin
        case "U":
          periodeb = { code: "", libelle: "" };
          periofin = { code: "", libelle: "" };
          if (!CWSTR.isBlank(defaultValues.get("unitedebdef")) && !CWSTR.isBlank(defaultValues.get("unitedebdef").code)) {
            periodeb = defaultValues.get("unitedebdef");
            if (periodeb.code.indexOf("U_") !== 0) {
              periodeb.code = "U_" + periodeb.code;
            }
          }
          if (!CWSTR.isBlank(defaultValues.get("unitefindef")) && !CWSTR.isBlank(defaultValues.get("unitefindef").code)) {
            periofin = defaultValues.get("unitefindef");
            if (periofin.code.indexOf("U_") !== 0) {
              periofin.code = "U_" + periofin.code;
            }
          }
          gererPret.set("modesai_periodeb", periodeb);
          gererPret.set("modesai_periofin", periofin);
          break;
        case "H":
          //--  Cas du mode saisie en Heures, cas heures : non prevu
          gererPret.set("modesai_periodeb", { code: "H", libelle: "" });
          gererPret.set("modesai_periofin", { code: "H", libelle: "" });
          break;
        case "E":
          //  --Cas du mode saisie en Heures, cas mode : non prevu
          gererPret.set("modesai_periodeb", { code: "S", libelle: "" });
          gererPret.set("modesai_periofin", { code: "E", libelle: "" });
          break;
        default:
        //Nothing
      }
      this._mapToForm();
    }
    // perderf (affsaip_typedef) n'est pas valorisé: il s'agit du cas ou les périodes ne sont pas typées (H ou U)
    else {
      if (!CWSTR.isBlank(defaultValues.get("modadef")) && !CWSTR.isBlank(defaultValues.get("modadef").code)) {
        // S'il s'agit d'un type en periode continue
        if (defaultValues.get("modadef").code === "P") {
          periodeb = { code: "", libelle: "" };
          periofin = { code: "", libelle: "" };
          if (!CWSTR.isBlank(defaultValues.get("unitedebdef")) && !CWSTR.isBlank(defaultValues.get("unitedebdef").code)) {
            periodeb = defaultValues.get("unitedebdef");
            if (periodeb.code.indexOf("U_") !== 0) {
              periodeb.code = "U_" + periodeb.code;
            }
          }
          if (!CWSTR.isBlank(defaultValues.get("unitefindef")) && !CWSTR.isBlank(defaultValues.get("unitefindef").code)) {
            periofin = defaultValues.get("unitefindef");
            if (periofin.code.indexOf("U_") !== 0) {
              periofin.code = "U_" + periofin.code;
            }
          }
          gererPret.set("modesai_periodeb", periodeb);
          gererPret.set("modesai_periofin", periofin);
          this._mapToForm();
        }
      }
    }
  }

  checkUnitesCoherence(gererPret?: any, callback?: any): void {
    let allowedUnite = this.unitesComboColl.findWhere({ code: gererPret.get("unitedeb").code });
    let allowedUniteDeb = null;
    let allowedUniteFin = null;

    if (!CWSTR.isBlank(gererPret.get("modesai_periodeb")) && !CWSTR.isBlank(gererPret.get("modesai_periodeb").code)) {
      allowedUniteDeb = this.uniteDebComboColl.findWhere({ code: gererPret.get("modesai_periodeb").code });
    }
    if (!CWSTR.isBlank(gererPret.get("modesai_periofin")) && !CWSTR.isBlank(gererPret.get("modesai_periofin").code)) {
      allowedUniteFin = this.uniteFinComboColl.findWhere({ code: gererPret.get("modesai_periofin").code });
    }
    if (CWSTR.isBlank(allowedUnite)) {
      gererPret.set("unitedeb", { code: "", libelle: "" });
    } else {
      gererPret.get("unitedeb").libelle = allowedUnite.get("libelle");
    }
    if (CWSTR.isBlank(allowedUniteDeb)) {
      gererPret.set("modesai_periodeb", { code: "", libelle: "" });
    } else if (CWSTR.isBlank(gererPret.get("modesai_periodeb").libelle)) { //Set libelle value in order to avoid another call to combo WS
      gererPret.get("modesai_periodeb").libelle = allowedUniteDeb.get("libelle");
    }
    if (CWSTR.isBlank(allowedUniteFin)) {
      gererPret.set("modesai_periofin", { code: "", libelle: "" });
    } else if (CWSTR.isBlank(gererPret.get("modesai_periofin").libelle)) { //Set libelle value in order to avoid another call to combo WS
      gererPret.get("modesai_periofin").libelle = allowedUniteFin.get("libelle");
    }
    //Do the same to the default unités
    allowedUnite = this.unitesComboColl.findWhere({ code: this.pretDefaults.get("unitedef").code });
    allowedUniteDeb = null;
    if (!CWSTR.isBlank(this.pretDefaults.get("unitedebdef")) && !CWSTR.isBlank(this.pretDefaults.get("unitedebdef").code)) {
      //The U_ is because Metier send "U_<unite>" when indiate periode (ñapa?)
      allowedUniteDeb = this.uniteDebComboColl.findWhere({ code: this.pretDefaults.get("unitedebdef").code });
    }
    allowedUniteFin = null;
    if (!CWSTR.isBlank(this.pretDefaults.get("unitefindef")) && !CWSTR.isBlank(this.pretDefaults.get("unitefindef").code)) {
      //The U_ is because Metier send "U_<unite>" when indiate periode (ñapa?)
      allowedUniteFin = this.uniteFinComboColl.findWhere({ code: this.pretDefaults.get("unitefindef").code });
    }
    if (CWSTR.isBlank(allowedUnite)) {
      this.pretDefaults.set("unitedef", { code: "", libelle: "" });
    } else {
      this.pretDefaults.get("unitedef").libelle = allowedUnite.get("libelle");
    }
    if (CWSTR.isBlank(allowedUniteDeb)) {
      this.pretDefaults.set("unitedebdef", { code: "", libelle: "" });
    } else if (!CWSTR.isBlank(this.pretDefaults.get("unitedebdef").libelle)) { //Set libelle value in order to avoid another call to combo WS
      this.pretDefaults.get("unitedebdef").libelle = allowedUniteDeb.get("libelle");
    }
    if (CWSTR.isBlank(allowedUniteFin)) {
      this.pretDefaults.set("unitefindef", { code: "", libelle: "" });
    } else if (!CWSTR.isBlank(this.pretDefaults.get("unitefindef").libelle)) { //Set libelle value in order to avoid another call to combo WS
      this.pretDefaults.get("unitefindef").libelle = allowedUniteFin.get("libelle");
    }
    if (callback) {
      callback();
    }
  }

  /**
   * The View Custom change event, peform logics when the inputs change his value and
   * performs put the new values on the current model with the FormView _change function.
   */
  _changeView(event: any, data: any): void {
    const gererPret = this.model.get("value");
    const className = event.target.className.split(" ")[0];
    const oldPerimetreCode = _.isEqual("perimetre.code", className) ? this.model.get("value").get("perimetre").code : null;
    const callback = (): void => {
      this._renderSelChemins();
    };

    $(".datedeb.dateDebD").removeClass("hasDatepicker");
    $(".datedeb.dateDebP").removeClass("hasDatepicker");
    $(".datefin.dateFinP").removeClass("hasDatepicker");
    $(".datedeb.dateDebR").removeClass("hasDatepicker");
    $(".datefin.dateFinR").removeClass("hasDatepicker");
    this._change(event, data, null, true);
    // Addition default change Logic
    switch (className) {
      case "type.code":
        this._enableModeSaisie();
        this._typeChanged(gererPret);
        if (!gererPret.isNew()) {
          this.typeSaisie.setMenuAccess(true, true);
        }
        break;
      case "perimetre.code":
        //On doit vérifier si le code a chamgé.
        if (!_.isEqual(this.model.get("value").get("perimetre").code, oldPerimetreCode)) {
          //On supprime la 'libelle' pour que cette information se change correctement dans la liste déroulante (combo)
          this.model.get("value").get("perimetre").libelle = null;
          this._changePerimetre();
          //Reset title and header to match the selected perimetre
          this._manageHeader();
          if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation === "pop-up") { // Pop up
            this._setTitlePopUp();
          }
          //Perimetre is going to be modifiable only in creation mode
          //if it has been changed, it is necessary to reload structures for the current fonctionalite
          //En création: autant de lignes «Libellé de type de structure - champ Elément de structure» que de types de structure «de prêt» présents dans le filtre de l’habilitation de la fonctionnalité
          if (gererPret.isNew()) {
            this._getStructuresPerTypeEvtAndper(gererPret.get("datedeb"), gererPret, callback);
          }
          //In consultation mode we don't have to update the list of structures because it only depends on la liste des éléments de structure destinataires du prêt
          // Apply new perimetre in context
          this.context.ctxPerimetre = CWSTR.getElValue(gererPret, "perimetre.code");
          if (!_.isEmpty(gererPret)) {
            this._resetModel(gererPret);
          }
        }
        break;
      case "motif.code":
        this.motifChanged = true;
        break;
      case "origine.code":
        this.origineChanged = true;
        break;
      case "modesai_periodeb.code":
        this._manageUniteDeb(data);
        break;
      case "modesai_periofin.code":
        this._manageUniteFin(data);
        break;
      default:
      //Nothing
    }
  }

  _mapToForm(): void {
    if (this.$el) {
      const gererPret = this.model.get("value");

      if (gererPret === null) {
        this.$el.find(".gererpret-form-inputs").hide();
      } else {
        const fieldset = this.$el.parent().find("fieldset");
        const renderers: any = {};

        this.$el.find(".gererpret-form-inputs").show();
        gererPret.setHabContext(this.model.getHabContext());
        renderers["collabremplace"] = (): any => {
          let collabRemplace = "";

          if (!CWSTR.isBlank(this.context.ctxCollabReference) && !CWSTR.isBlank(this.context.ctxCollabReference.matricule)) {
            collabRemplace = this.context.ctxCollabReference.nom + " " + this.context.ctxCollabReference.prenom;
            if (CWSTR.isBlank(this.diver) || this.diver === "matric") {
              collabRemplace += " (" + this.context.ctxCollabReference.matricule + ")";
            } else {
              collabRemplace += " (" + this.context.ctxCollabReference.matric_aux + ")";
            }
          } else if (!CWSTR.isBlank(gererPret.get("collabremplace")) && !CWSTR.isBlank(gererPret.get("collabremplace").matric)) {
            collabRemplace = gererPret.get("collabremplace").nom + " " + gererPret.get("collabremplace").prenom;
            if (CWSTR.isBlank(this.diver) || this.diver === "matric") {
              collabRemplace += " (" + gererPret.get("collabremplace").matric + ")";
            } else {
              collabRemplace += " (" + gererPret.get("collabremplace").matricaux + ")";
            }
          }
          return collabRemplace;
        };
        this.$el.find(".collabremplace").attr("readonly", 'true');
        //Dynamic Data
        this._LoadDynamicFieldData();
        this._mapModelToForm(fieldset, gererPret, renderers, true);
        //manually map the dates
        // Error management
        gererPret.off("invalid");
        gererPret.on("invalid", this._showValidationErrors, this);
        // Clear current validation erros
        this._cleanValidationErrors();
        this._panelStateRender(gererPret);
        // Manage Delorants Labels
        this._mapMultiLabels();
        //Manual Label Linking
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='D']"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='P']"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='R']"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".modeSaisie"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".beginning-form"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".end-form"));
        this._manageUniteDeb(CWSTR.getElValue(gererPret, "modesai_periodeb.code"));
        this._manageUniteFin(CWSTR.getElValue(gererPret, "modesai_periofin.code"));
        this._manageModeSaisie();
        // Map structures chemins
        this._mapStructures();
        if (this.disabled === true) {
          fieldset.find("label.required").css("background", "none");
        }
        this._updateVisibiliteOrigine();
        switch (gererPret.get("typesaisie")) {
          case "D":
            $(".datedeb.dateDebP").removeClass("hasDatepicker");
            $(".datefin.dateFinP").removeClass("hasDatepicker");
            $(".datedeb.dateDebR").removeClass("hasDatepicker");
            $(".datefin.dateFinR").removeClass("hasDatepicker");
            if (!$(".datedeb.dateDebD").hasClass("hasDatepicker")) {
              CWFORMS.setDatepicker(this, ".datedeb.dateDebD");
            }
            break;
          case "P":
            $(".datedeb.dateDebD").removeClass("hasDatepicker");
            $(".datedeb.dateDebR").removeClass("hasDatepicker");
            $(".datefin.dateFinR").removeClass("hasDatepicker");
            if (!$(".datedeb.dateDebP").hasClass("hasDatepicker")) {
              CWFORMS.setDatepicker(this, ".datedeb.dateDebP");
            }
            if (!$(".datefin.dateFinP").hasClass("hasDatepicker")) {
              CWFORMS.setDatepicker(this, ".datefin.dateFinP");
            }
            break;
          case "R":
            $(".datedeb.dateDebD").removeClass("hasDatepicker");
            $(".datedeb.dateDebP").removeClass("hasDatepicker");
            $(".datefin.dateFinP").removeClass("hasDatepicker");
            if (!$(".datedeb.dateDebR").hasClass("hasDatepicker")) {
              CWFORMS.setDatepicker(this, ".datedeb.dateDebR");
            }
            if (!$(".datedeb.dateFinR").hasClass("hasDatepicker")) {
              CWFORMS.setDatepicker(this, ".datefin.dateFinR");
            }
        }
      }
    }
  }
  /**
   * Map the multi Labels with the model data
   */

  paintDates(): void {
    const gererPret = this.model.get("value");

    switch (gererPret.get("typesaisie")) {
      case "D":
        this.$el.find(".dateDebD.datedeb").val(CWTYPE.DATE.format(gererPret.get("datedeb")));
        break;
      case "P":
        this.$el.find(".dateDebP.datedeb").val(CWTYPE.DATE.format(gererPret.get("datedeb")));
        this.$el.find(".dateFinP.datefin").val(CWTYPE.DATE.format(gererPret.get("datefin")));
        break;
      case "R":
        this.$el.find(".dateDebR.datedeb").val(CWTYPE.DATE.format(gererPret.get("datedeb")));
        this.$el.find(".dateFinR.datefin").val(CWTYPE.DATE.format(gererPret.get("datefin")));
        break;
    }
  }

  _mapMultiLabels(): void {
    const gererPret = this.model.get("value");

    this.typeSaisie.setLabelByName(gererPret.get("typesaisie"));
    this.modeSaisie.setLabelByName(gererPret.get("modesaisie"));
  }

  _manageModeSaisie(): void {
    const gererPret = this.model.get("value");

    if (gererPret.get("typesaisie") === "P") {
      this.$el.find(".modeSaisie").hide();
    } else {
      this.$el.find(".modeSaisie").show();
    }
  }

  /**
   * Map structures to form
   */
  _mapStructures(): void {
    const gererPret = this.model.get("value");
    const structures = gererPret.get("structures");
    const infoDate = this._getModel().get("datedeb");

    if (!_.isEmpty(structures) && structures.length > 0) {
      _.each(structures, (structure: any) => {
        const structid = structure.type;
        const itemid = structure.element;
        const cheminView = _.find(this.listeCheminViews, (cheminView: any) => {
          return String(cheminView.structid) === String(structid);
        });

        if (!CWSTR.isBlank(cheminView) && !CWSTR.isBlank(itemid) && (_.isEmpty(cheminView.getValues()) || cheminView.getValues().length === 0)) { //uniquement si le chemin n'existe pas déjà, il sera demandé
          const item = new CWSelecteurCheminItemModel({
            "structid": structid,
            "elementCode": itemid,
            "date": infoDate
          });

          cheminView.addValue(item, () => {
            const obj: { [key: string]: any } = {};

            obj.structcode = structid;
            obj.elementcode = itemid;
          });
          //Set width
          if (cheminView.readonly === true) {
            cheminView.$el.find("input").css("width", "200px");
          }
        } else {
          if (!CWSTR.isBlank(cheminView)) {
            cheminView._paintItems();
          }
        }
      });
    }
  }

  //callback chemin
  _responseCallbackChemin(data: any, index: any, structid: any): void {
    _.each(this.model.get("value").get("structures"), (struct: any) => {
      if (String(struct.type) === String(structid)) {
        struct.element = data.code;
      }
    });
  }

  _manageUniteDeb(value: any): void {
    if (value === "H") {
      this.$el.find(".heuredeb-container").show();
    } else {
      this.$el.find(".heuredeb-container").hide();
    }
  }

  _manageUniteFin(value: any): void {
    if (value === "H") {
      this.$el.find(".heurefin-container").show();
    } else {
      this.$el.find(".heurefin-container").hide();
    }
  }

  /**
   * Create the Workflow Composant and atach to the form
   */
  _manageWKFHisto(historique: any): void {
    const histo = CWSTR.isBlank(historique) ? true : historique;

    if (this.context.ctxHistoriqueWKF === true && histo === true && this.context.ctxModeInitialisation === "Consultation") {
      this.contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: "PRET", //Define is this is PRET
        ctxRefEvenement: CWSTR.getElValue(this.model.get("value"), "evenement"),
        ctxDemId: CWSTR.getElValue(this.model.get("value"), "iddemande"),
        ctxModeRestitution: "Imbrique",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: false
      };
      this.composantWorkflow = new CWWkflhistoView();
      $(this.el).find(".gererprets-wkfhisto").show();
      $(this.el).find(".gererprets-wkfhisto").html(this.composantWorkflow.render().el);
      this.composantWorkflow.setContext(this.contextComponentWkf);
      this.composantWorkflow.hideTitle();
      this.composantWorkflow.hideIcon();
    } else {
      $(this.el).find(".gererprets-wkfhisto").hide();
    }

  }

  _showPieceJointe(): void {
    const maxPieces = !CWSTR.isBlank(this.workflow.maxPieces) ? this.workflow.maxPieces.get("maxi") : 0;
    const paramPJointe = CWDIVERS.get("pJointe") === "1" ? true : false;

    if (this.model && this.model.get("value") && this.model.get("value").get("piecesjointes") && this.model.get("value").get("piecesjointes").length > 0) {
      this.context.ctxPieceJointe = true;
      this.model.get("value").set("indicateurpj", true);
    }
    //Dont' show pieces jointes when creating or in ajout mode in contexte multiple (ctxListeCollab renseigne)
    if (paramPJointe === true && this.context.ctxPieceJointe === true && !CWSTR.isBlank(this.context.ctxCollab) && this.context.ctxUtilisateur === "Responsable" &&
      maxPieces > 0 && !(this.context.ctxModeInitialisation === "Ajout" && !CWSTR.isBlank(this.context.ctxListeCollab))) {
      const habilitations: any = {};
      const habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
      const refEvenement = CWSTR.getElValue(this.model.get("value"), "evenement");
      let context: { [key: string]: any } = null;

      this.pieceJointeModified = false;
      //Prepare habilitations
      habilitations.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
      habilitations.HabilitationGestion = habilitationGestion;
      habilitations.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
      context = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: habilitations,
        ctxRefEvenement: refEvenement,
        ctxModeRestitution: "Imbrique",
        ctxModeDefaut: "Replie",
        ctxActionsPossibles: this.disabled ? [] : ["Creer", "Supprimer"],
        ctxGestionCollab: this.context.ctxCollab
      };
      // Obtain the max number of pieces availables
      // Create the component
      this.pieceJointe = new CWPieceJointes({
        context: context, type: "PJPRT",
        maxPieces: maxPieces,
        appendTo: (this.context.ctxModeRepresentation !== "pop-up") ? this.context.ctxAppendTo : null,
        modal: true
      });
      $(this.el).find(".gererprets-piecesjointes").html(this.pieceJointe.render().el);
      this.pieceJointe.setContext(context);
      this.pieceJointe.setPiecesJointes(this.model.get("value").get("piecesjointes"));
      this.pieceJointe.model.on("piecejointeChanged", () => {
        this.model.trigger("form:edited");
        this.pieceJointeModified = true;
      }, this);
      $(this.el).find(".gererprets-piecesjointes").show();
    } else {
      $(this.el).find(".gererprets-piecesjointes").hide();
    }
  }

  /**************************************************/
  /** INFORMATIONS COMPLEMENTARIES PERSONALIZABLES **/
  /**************************************************/
  /**
   * Creates all the combos or input fields or date fields when are necessary.
   */
  _LoadDynamicFieldData(): void {
    const gererPret = this.model.get("value");
    const infoCompArray = gererPret.get("infocomp");
    const infoCompOrder = _.sortBy(infoCompArray, (item: any) => { return item.ordre; });

    this.combos = [];
    //Reset the component
    this.$el.find(".gererprets-infocomp").html("");
    _.each(infoCompOrder, (it) => {
      this._manageFields(it);
    });
    if (this.disabled === true || (this.context.ctxUtilisateur === "Collaborateur" || (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Consultation" && this.context.ctxActionsPossibles.indexOf("Modifier") === -1))) {
      this._disableInfoComp();
    } else {
      this._enableInfoComp();
    }
  }

  _manageFields(fieldData: any): void {
    if (!CWSTR.isBlank(fieldData.codif)) {
      // PAINT A COMBO
      const combo = this._createCombo(fieldData);

      if (combo) {
        this._paintField(fieldData, combo);
      }
    } else {
      // PAINT AN INPUT TEXT
      const input = this._createInputText(fieldData.code, fieldData.valeur, 14, 10);
      this._paintField(fieldData, input);
    }
  }
  /**
   * Paints the input fields or combos and their labels.
   */
  _paintField(fieldData: any, fieldToPaint: any): void {
    const fieldName = fieldData.code;
    const pre = "infocomp";
    const $div = $("<div>");
    const $span = $("<span>");
    let $domEl: JQuery = null;
    let $labelEl: JQuery = null;

    $div.addClass("form-group");
    $span.attr("id", fieldName);
    $domEl = $div.append($span);
    $labelEl = $("<label>").attr("for", pre + "." + fieldName + ".valeur");
    if (fieldData.oblig) {
      $labelEl.addClass("cw-required");
    }
    $domEl.append($labelEl);
    this.$el.find(".gererprets-infocomp").append($domEl);
    if (!CWSTR.isBlank(fieldData.codif)) {
      if (fieldToPaint.type !== "select-one") {
        $domEl.append(fieldToPaint.render().el);
      } else {
        $domEl.append(fieldToPaint);
      }
      $labelEl.text(fieldData.libelle);
    } else {
      // Paint the input fields
      $domEl.append(fieldToPaint);
      // Add typeLong mask and events changed in # customer 146014
      if (fieldData.type === "N") {
        if (fieldData.masque) {
          $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeCustom");
          this.typeFormatByClass[pre + "." + fieldName + ".valeur"] = fieldData.masque;
        } else {
          $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeLong");
        }
        $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").removeAttr("maxlength");
      }
      //fills the dates if needed
      if (fieldData.type === "D") {
        const errorContainer = "<span class='" + pre + "." + fieldName + ".valeur-error-container'></span>";

        $domEl.find("." + pre + "\\." + fieldName + "\\.valeur").addClass("typeDate");
        $domEl.find("." + pre + "\\." + fieldName + "\\.valeur").removeAttr("maxlength");
        $domEl.append(errorContainer);
      }
      if (fieldData.type === "J") {
        $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeJour");
      }
      $labelEl.text(fieldData.libelle);
    }
    $domEl.append($("<br>"));
    // evaluate if this must be hidden or not
    if (fieldData.libelle && fieldData.libelle.indexOf(".") === 0) {
      $domEl.hide();
    } else {
      $domEl.show();
    }
  }

  _createCombo(fieldData: { [key: string]: any }): CWComboBoxView2 {
    let combo: CWComboBoxView2 = null;

    if (!_.isEmpty(fieldData)) {
      const idCombo = fieldData.code;

      combo = new CWComboBoxView2({
        ws: new CWComplementComboColl([], { codif: fieldData.codif, "typeInfocomp": fieldData.type }),
        name: "infocomp." + idCombo + ".valeur",
        habContext: this.model.getHabContext(),
        required: fieldData.oblig,
        optionsRender: (value: any): any => {
          if (!value || CWSTR.isBlank(value.code)) {
            return "";
          }
          return value.libelle + " (" + value.code + ")";
        },
        preprocessBeforeSetItem: (item: any): any => {
          return { "code": item.valeur, "libelle": "" };
        }
      });

      this.combos[idCombo] = combo;
      this.listenTo(combo, "comboEdited", this._formEdited);
    }
    return combo;
  }

  /**
   * Creates and returns an input text field.
   */
  _createInputText(idInput: any, value: any, size: number, maxLength: number): JQuery | any {
    const input = $("<input>").addClass("infocomp." + idInput + ".valeur").addClass("form-control").attr({ "type": "text", "size": size, "maxlength": maxLength });

    return input;
  }

  savePretCollab(): void {
    const target = this.model.get("value");
    let saveAction = "update";
    const saveCallback = (fresh: any): void => {
      target.store();
      this.savePiecesJointes(fresh, () => {
        if (this.context.ctxModeRepresentation === "pop-up") {
          this.parent.close();
        }
        this.model.trigger("pretChanged", fresh, saveAction);
      });
    };
    const errorSaveCallback = (model: any): void => {
      if (!CWSTR.isBlank(model.validationError)) {
        // server does not send the error in datedebut (only in datefin)
        // so we create the error manually in the save process.
        if (!CWSTR.isBlank(model.validationError.errors.datefin)) {
          model.validationError.errors.datedeb = model.validationError.errors.datefin;
          delete model.validationError.errors.datefin;
          this._showValidationErrors(model, model.validationError);
        }
      }
    };

    target.activeSimulation = !CWSTR.isBlank(this.context.ctxSimulation);
    target.set("collaborateur", { matric: this.context.ctxCollab.matricule });
    if (CWSTR.isBlank(target.get("collabremplace").matricule) && !CWSTR.isBlank(this.context.ctxCollabReference)) {
      let datedeb, datefin;

      if (!CWSTR.isBlank(this.context.ctxCollabReference.datedeb)) {
        datedeb = this.context.ctxCollabReference.datedeb;
      } else {
        datedeb = this.context.ctxValeursCreation.PretDateDebut;
      }
      if (!CWSTR.isBlank(this.context.ctxCollabReference.datefin)) {
        datefin = this.context.ctxCollabReference.datefin;
      } else {
        datefin = this.context.ctxValeursCreation.PretDateFin;
      }
      target.set("collabremplace", {
        matric: this.context.ctxCollabReference.matricule,
        matricaux: this.context.ctxCollabReference.matric_aux,
        nom: this.context.ctxCollabReference.nom,
        prenom: this.context.ctxCollabReference.prenom,
        datedeb: datedeb,
        datefin: datefin
      });
    }
    target.action = "demande";
    if (target.isNew()) {
      saveAction = "create";
    }
    this._manageTypeSaisieDate(target);
    target.save(null, {
      success: saveCallback,
      error: errorSaveCallback
    });
  }

  _manageTypeSaisieDate(target: any): void {
    //In order to send datefin=datedeb for type saisie date
    if (target.get("typesaisie") === "D") {
      const datedeb = target.get("datedeb");

      target.set("datefin", datedeb);
    }
  }

  savePiecesJointes(pret: any, callback: any): void {
    if (pret && this.pieceJointeModified === true) {
      const evenement = pret.get("evenement");

      this.pieceJointe.applyPendingActions(evenement, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  }

  newPretCollab(): void {
    const target = new CWGererPretsModel();
    const habilitationGestion = this._getHabilitationGestion(target);
    const callback = (): void => {
      this._manageWKFHisto(false);
      this._showPieceJointe();
      this._renderSelChemins();
      this._enableDisableFields();
      this._initChangeFlags();
    };
    let gererPret = null;

    this.stopMessagePrevClickPret();
    this.context.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
    this.model.updateHabContext({ foncCour: habilitationGestion, natGest: "A" });
    //Initialize collaborateur
    target.set("collaborateur", { matric: this.context.ctxCollab.matricule });
    //Initialize perimetre to context perimetre
    CWSTR.setElValue(target, "perimetre.code", this.context.ctxPerimetre);
    CWSTR.setElValue(target, "emprunt", this.context.ctxTypeEvtGere === "E" ? true : false); // si ctxTypeEvtGere = Emprunt sinon faux
    this.model.trigger("mode:create");
    this._enableForm();
    this.model.set("value", target);
    this.model.trigger("form:edited", true);
    gererPret = this.model.get("value");
    this._getStructuresPerTypeEvtAndper(gererPret.get("datedeb"), gererPret, callback);
  }

  revertView(): void {
    if (this.context && this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Ajout") {
      this.parent.close();
    } else {
      this.hasBeenFocused = false;
      this.workflow.btnBarModel.trigger("show:valider");
      this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationAcces, natGest: "" });
      if (this.model.get("value") && this.model.get("value").isNew()) {
        const previous = this.model.previous("value");

        if (previous) {
          this.model.unset("value", { silent: true });
          this.model.set("value", previous);
          if (this.context && this.context.ctxModeRepresentation !== "pop-up" && previous.isNew()) {
            this.model.set("value", null);
            this.workflow.btnBarModel.set("mode", "C");
          } else {
            this.workflow.btnBarModel.set("mode", "R");
            this._setTitlePopUp();
          }
          this._manageRestrictions();
        } else if (CWSTR.isBlank(this.context.ctxIdentifiantEvt) || this.model.get("value").isNew()) {
          this.model.set("value", null);
          this.workflow.btnBarModel.set("mode", "C");
          this._cleanValidationErrors();
        }
        this._enableDisableFields();
      } else {
        if (this.model.get("value")) {
          this.model.get("value").revert();
        }
        this._enableDisableFields(true);//ce méthode doit être avant de mapToForm -> le trigger ci-dessous :
        this.model.trigger("change:value");
        this.workflow.btnBarModel.set("mode", "R");
        this._manageHabilitation();
        this._manageRestrictions();
        if (this.context && this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Consultation") {
          this.btnBar.model.trigger("hide:new");
          this.btnBar.model.trigger("hide:copy");
        }
      }
      this._manageWKFHisto(this.context.ctxHistoriqueWKF);
      this._showPieceJointe();
      this.typeSaisie.setMenuAccess(false, true);
      // Set WAI-ARIA attributes
      $(this.el).find(".type\\.code").focus();
    }
  }

  deletePretCollab(commentaire: string): void {
    const target = this.model.get("value");
    const isMedical = (this.contextComponentWkf && this.contextComponentWkf.ctxEcran === 'planmed') ? '?medical=true' : "";
    let droit = target.get("droit");
    const habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    const successDestroyCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        this.parent.close();
      }
      this.model.trigger("pretChanged", target, "delete");
    };

    this.model.updateHabContext({ foncCour: habilitationGestion, natGest: "S" });
    if (!droit) {
      droit = "N";
    }
    target.action = "demande";
    if (this.context.ctxDonneeSimulee) {
      target.activeSimulation = true;
    }
    if (!CWSTR.isBlank(commentaire)) {
      target.set("commentaire", commentaire);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }
    target.destroy({
      contentType: 'application/json',
      url: Configuration.restRoot + "/rest/composant/pret/" + target.action + "/" + encodeURIComponent(target.id) + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit) + isMedical,
      success: successDestroyCallback,
      //error -> rien à faire
      wait: true
    });
  }

  deletePretFromMenuButton(pret: any): void { // EVO 524
    pret.setHabContext(this.model.getHabContext());
    this.model.set("value", pret, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  }

  accepterPretCollab(commentaire: string, profil: any): void {
    const values = this.model.get("value");
    let valuesProfil = null;
    let lProfil = {
      code: "",
      libelle: ""
    };
    const id = values.get("id");
    let droit = "";
    const target = new CWGererPretsGestion();
    const saveCallback = (mmodel: CWGererPretsGestion, fresh: { [key: string]: any }): void => {
      if (!_.isEmpty(fresh) && !CWSTR.isBlank(fresh.result) && fresh.result.toUpperCase() === "OK" && this._getModel()?.get("etat") === "D") {
        this._getModel().set("etat", "A", { silent: true });//il faut exécuter la popup de rafraîchissement
      }
      this.model.trigger("pretChanged", this.model.get("value"), "update");
      if (this.context.ctxModeRepresentation === "pop-up") {
        this.parent.close();
      }
    };

    if (this.profilModel && this.profilModel.get("profils")[0]) {
      valuesProfil = this.profilModel.get("profils")[0];
      lProfil = {
        code: !CWSTR.isBlank(profil) ? profil : "",
        libelle: !CWSTR.isBlank(valuesProfil.libelle) ? valuesProfil.libelle : ""
      };
    } else if (!CWSTR.isBlank(this.model.get("value").profil)) {
      lProfil = {
        code: this.model.get("value").profil.code,
        libelle: this.model.get("value").profil.libelle
      };
    }
    if (!CWSTR.isBlank(values.get("droit"))) {
      droit = values.get("droit");
    } else {
      droit = "O";
    }
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    target.action = "accepter";
    target.id = id;
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", lProfil);
    target.save(null, {
      success: saveCallback
      //error -> rien à faire ici
    });
  }

  accepterPretFromMenuButton(pret: any): void { // EVO 524
    this.id = encodeURIComponent(pret.id);
    pret.setHabContext(this.model.getHabContext());
    this.model.set("value", pret, { silent: true });
    this.model.get("value").set("id", this.id);
    this.btnBar.model.trigger("btn:click", "accepter");
  }

  refuserPretCollab(commentaire: string): void {
    let droit: any = null;
    const values = this.model.get("value");
    const id = values.get("code");
    const profil = !_.isEmpty(values.get("profil")) ? values.get("profil") : (!_.isEmpty(this.profilModel.get("profils")) ? this.profilModel.get("profils")[0] : null);
    const target = new CWGererPretsGestion();
    const saveCallback = (): void => {
      this.model.trigger("pretChanged", this.model.get("value"), "update");
      if (this.context.ctxModeRepresentation === "pop-up") {
        this.parent.close();
      }
    };

    if (!CWSTR.isBlank(values.get("droit"))) {
      droit = values.get("droit");
    } else {
      droit = "N";
    }
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    target.action = "refuser";
    target.id = id;
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", profil);
    target.save(null, {
      success: saveCallback
      //error -> rien à faire ici
    });
  }

  refuserPretFromMenuButton(pret: any): void { // EVO 524
    this.id = encodeURIComponent(pret.id);
    pret.setHabContext(this.model.getHabContext());
    this.model.set("value", pret, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser");
  }

  valider(callback: any): void {
    const habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    const target = this.model.get("value");
    const saveCallback = (model: any, response: any): void => {
      if (response.result === "ok") {
        if (callback) {
          callback();
        }
      }
    };

    this.model.updateHabContext({ foncCour: habilitationGestion, natGest: "" });
    target.action = "validesaisie";
    target.setHabContext(this.model.getHabContext());
    target.save(null, {
      success: saveCallback
      //error -> rien à faire ici
    });
  }

  _updateVisibiliteOrigine(): void {
    const lPosOrigin = this.$el.find(".originActif"); //label and combo
    const lContextActif = GLOBAL_DATA.contextActif.contextActifPret;

    //La présence de cette information dans le formulaire ne sera effective que si,
    // pour le type d’événement « Prêt », la saisie de l’origine de la demande est autorisée.
    if (CWSTR.isBlank(lContextActif) || lContextActif.get("active") !== true) {
      lPosOrigin.hide();
    } //Il n'est pas necessaire l'action "show" parce qu'il vient de le faire(la création)
  }

  _resetModel(model: CWBaseModel | any): void {
    if (!_.isEmpty(model.get("type"))) {
      model.set("type", { code: null, libelle: null });
    }
    if (!_.isEmpty(model.get("type"))) {
      model.set("motif", { code: null, libelle: null });
    }
    if (!_.isEmpty(model.get("type"))) {
      model.set("origine", { code: null, libelle: null });
    }
    if (!_.isEmpty(model.get("unitedeb"))) {
      model.set("unitedeb", { code: null, libelle: null });
    }
  }

  _MessageModifyPretGest(): void {
    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(this.infoErrorModify?.error) && !_.isEmpty(this.infoErrorModify?.response)) {
      this.infoErrorModify.options.success = (): void => {
        this.isEditable = true;
        this.infoErrorModify.isModifiable = this.isEditable;
        this._enableForm(true);
      };
      onError(this.infoErrorModify?.error, this.infoErrorModify?.response, this.infoErrorModify?.options);
    }
  }

  _checkModeSilentModifyPretGest(callback: (result: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }) => any): void {
    const lModel = this.model?.get("value");
    const pretId = lModel?.id;
    const habilitationGestion = this._getHabilitationGestion(lModel);

    if (pretId) {
      if (this.context.ctxUtilisateur === "Responsable" && CWHABILITATION.canView(habilitationGestion)) {
        const isModifiableModel = new CWGererPretsGestion();

        isModifiableModel.id = pretId;
        isModifiableModel.action = "modifiable";
        isModifiableModel.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: habilitationGestion,
          natGest: ""
        }));
        if (!CWSTR.isBlank(this.context.ctxSimulation)) {
          isModifiableModel.activeSimulation = true;
        }
        isModifiableModel.fetch({
          success: () => {
            this.isEditable = true;
            if (typeof callback === "function") {
              callback(true);
            }
          },
          error: (model?: { [key: string]: any }, response?: { [key: string]: any }) => {
            this.isEditable = false;
            if (response.status === 406 && typeof callback === "function") {
              const lError: { [key: string]: any } = {};
              const lResponse = _.clone(response);

              lError.response = _.clone(response);
              lError.responseData = _.clone(response.responseJSON);
              //après les actions précédentes de "clone", on doit changer la valeur de status et omit, pour traiter ici le message
              response.status = 200;
              response.responseText = null;
              response.omit = true;
              if (lResponse.responseJSON?.icone === "?" && lResponse.responseJSON?.boutons.length > 1) {
                lResponse.responseJSON.boutons[0].action = "CONTINUE";//l'information possible du premier bouton ne sera pas utilisé. On doit la changer pour suivre avec l'action
                lResponse.responseText = JSON.stringify(lResponse.responseJSON);
              }
              callback(false, lError, lResponse);
            }
          }
        });
      } else {
        this.isEditable = false;
        if (typeof callback === "function") {
          callback(false);
        }
      }
    } else {
      this.isEditable = true; //création
      if (typeof callback === "function") {
        callback(true);
      }
    }
    this.errorPetAntSaveDelete = false;
  }

  _showMessageModifyPretGest(): boolean {
    let rtn = false;
    const response = this.infoErrorModify?.response;

    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(response) && ((response.responseJSON?.icone === "X" && response.responseJSON?.boutons[0].action === "ABORT") || (response.responseJSON?.icone === "?" && response.responseJSON?.boutons[1].action === "ABORT"))) {
      rtn = true;
    }
    return rtn;
  }
  _actionPrevClickPret(): void {
    if (this._showMessageModifyPretGest()) {
      const $posCalend = this.$el.find("input.typeDate:not([readonly])");
      const listeCombos = _.filter(this, (item: any): boolean => {
        return (item instanceof CWComboBoxView2);
      });

      //Combo's
      this.notCheckCombos = false;
      if (listeCombos && listeCombos.length > 0) {
        for (let i = 0; i < listeCombos.length; i++) {
          if (!CWSTR.isBlank(listeCombos[i].coll)) {
            listeCombos[i].stopListening(listeCombos[i].coll, "sync");
            listeCombos[i].listenToOnce(listeCombos[i].coll, "sync", (): void => {
              if (!this.notCheckCombos) {
                const $tempComboFetch = listeCombos[i].$el.find("input.c-cwComboBoxView2__input");

                this.notCheckCombos = true;
                if ($tempComboFetch.autocomplete("instance")) {
                  $tempComboFetch.autocomplete("close");
                }
              }
            });
          }
        }
      }
      //Si l'il y a d'information complémentaire, on doit rechercher tous les les listes de sélection
      if (!CWSTR.isBlank(this.infoCompAttributeName) && !_.isEmpty(this.model.get("value").get(this.infoCompAttributeName))) {
        const $posCombos = this.$el.find("input.c-cwComboBoxView2__input:not([readonly])");

        if ($posCombos && $posCombos.length > 0) {
          for (let i = 0; i < $posCombos.length; i++) {
            const $tempCombo = $($posCombos[i]);

            if ($tempCombo.autocomplete("instance") && $tempCombo.autocomplete("widget").is(":visible")) {
              $tempCombo.autocomplete("close");
            }
          }
        }
      }
      //Datepicker's
      if ($posCalend && $posCalend.length > 0) {
        for (let i = 0; i < $posCalend.length; i++) {
          $($posCalend[i]).datepicker("hide");
        }
      }
      //Boutons
      if (this.btnBar.isVisible("revert") || this.btnBar.isVisible("save")) {
        this.btnBar.model.trigger("btn:click", "revert");
      }
    }
  }

  stopMessagePrevClickPret(): void {
    if (this.$el) {
      this.$el.find(".gererpret-form-inputs").off("focusin");
    }
  }

  _initModeConsultation(pret: CWGererPretsModel, callback: (arg: any) => void): void {
    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationAcces, natGest: "" });
    pret.setHabContext(this.model.getHabContext());
    this._enableForm();
    //If we are in consultation mode we shouldnt need buttons bottom side
    this.btnBar.model.set("mode", "R");
    this.btnBar.hideAllBtnBar();
    //Responsable and valider --> show
    if (this.context.ctxActionsPossibles.indexOf("Valider") === 1 && this.context.ctxUtilisateur === "Responsable") {
      this.btnBar.model.trigger("show:valider");
    }
    pret.id = this.context.ctxIdentifiantEvt;
    pret.action = "element";
    this.typeStructures = new TypeStructuresColl();
    if (this._allValeursEvenementInformed() === true) {
      //If all valeurs evenement are informed in the entering context
      this._initializePretFromContext(pret);
      callback(pret);
    } else {
      //Initialize from server
      if (!CWSTR.isBlank(this.context.ctxSimulation)) {
        pret.activeSimulation = true;
        pret.ctxVueJourneeDate = this.context.ctxVueJourneeDate;
      }
      pret.fetch({
        success: (fresh: any): void => {
          fresh.store();
          this.model.set("value", pret, { silent: true }); //This will trigger a _mapToForm
          if (!_.isEmpty(this.typeStructures)) {
            this.model.get("value").typeStructures = this.typeStructures;
          }
          this.original = fresh.clone();
          this._checkModeSilentModifyPretGest((peutModifier: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }): void => {
            this.infoErrorModify = {
              "isModifiable": peutModifier,
              "error": infoError,
              "response": infoResponse,
              "options": {}
            };
            this.context.ctxEvtEstSimule = SIMULATION.getSimulationState(fresh.get("etatsimu"));
            callback(fresh);
            //Mettre toujours à la fin
            this.$el.find(".gererpret-form-inputs").off("focusin");
            this.$el.find(".gererpret-form-inputs").one("focusin", (): void => {
              this._actionPrevClickPret();
              if (!this.desactiveHabOuEtat) {
                if (this._showMessageModifyPretGest()) {
                  this._manageRestrictions(this.infoErrorModify?.isModifiable);
                  this._MessageModifyPretGest();
                }
              }
            });
          });
        }
      });
    }
  }

  _initModeAjout(pret: CWGererPretsModel): void {
    const habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    const matric = objs.appRt.workflow.authModel.get("matricule");
    const callbackAjout = (): void => {
      this.model.set("value", pret, { silent: true });
      if (!_.isEmpty(this.typeStructures)) {
        this.model.get("value").typeStructures = this.typeStructures;
      }
      this.model.get("value").store();
      this._manageHeader();
      this._manageRestrictions(this.isEditable);
      this.btnBar.model.trigger("hide:valider");
      this._manageActionsPossibles();
      this._manageHabilitation();
      this._renderSelChemins();
      this._showPieceJointe();
      this._enableDisableFields();
      this._showHideFields();
      this._initRattEmpruntCollab(); //Launch _mapToForm in the last CALLBACK.
      this.btnBar.model.set("mode", "E");
    };
    const callbackInSuccess = (): void => {
      this._getStructuresPerTypeEvtAndper(pret.get("datedeb"), pret, callbackAjout);
    };

    this.model.updateHabContext({ foncCour: habilitationGestion, natGest: "A" });
    this.btnBar.model.trigger("disable:new");
    this.btnBar.model.trigger("disable:delete");
    this.btnBar.model.trigger("hide:valider");
    CWSTR.setElValue(pret, "type.code", this.context.ctxValeursCreation.PretType || "");
    CWSTR.setElValue(pret, "perimetre.code", this.context.ctxValeursCreation.PretPerimetre || this.context.ctxPerimetre);
    CWSTR.setElValue(pret, "emprunt", this.context.ctxValeursCreation.PretEstUnEmprunt || this.context.ctxTypeEvtGere === "E" ? true : false); // si ctxTypeEvtGere = Emprunt sinon faux
    pret.set("createur", this.model.get("value").get("est_createur") || "");
    CWSTR.setElValue(pret, "datedeb", this.context.ctxValeursCreation.PretDateDebut || "");
    if (pret.get("typesaisie") === "P") {
      CWSTR.setElValue(pret, "modesai_periodeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
    } else {
      CWSTR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
    }
    CWSTR.setElValue(pret, "datefin", this.context.ctxValeursCreation.PretDateFin || "");
    CWSTR.setElValue(pret, "modesai_periofin.code", this.context.ctxValeursCreation.PretUniteFin || "");
    CWSTR.setElValue(pret, "heuredeb", this.context.ctxValeursCreation.PretHeureDebut || "");
    CWSTR.setElValue(pret, "heurefin", this.context.ctxValeursCreation.PretHeureFin || "");
    CWSTR.setElValue(pret, "commentaire", this.context.ctxValeursCreation.PretCommentaire || "");
    CWSTR.setElValue(pret, "motif.code", this.context.ctxValeursCreation.PretMotif || "");
    CWSTR.setElValue(pret, "createur", matric);
    this.typeStructures = new TypeStructuresColl();
    this._initializeCreation(pret, callbackInSuccess);
  }

  _desactiverPieceJointe(etat: boolean): void {
    if (_.isBoolean(etat) && !_.isEmpty(this.pieceJointe)) {
      if (this.workflow.paramPJointe === true && this.context.ctxPieceJointe === true && etat === true) {
        this.pieceJointe.desactiverPieceJointe(true);
      } else {
        this.pieceJointe.desactiverPieceJointe(false);
      }
    }
  }
}
