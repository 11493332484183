import * as Backbone from 'Backbone';
import TPLReferentielcollaborateurDetail from '../detail.tpl.html';
import { CWCollaborateursColl } from '../models/cwCollaborateurs.collection';
import { CWCollaborateursModel } from '../models/cwCollaborateurs.model';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSelecteurRefDialogView } from './cwSelecteurRefDialog.view';
import { CWSelecteurReferentielView } from 'core/components/selecteur_referentiel/cwSelecteurReferentiel.view.js';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

/**
 * Select collab view
 */
export class CWSelectCollabView extends Backbone.View<Backbone.Model>{


  diversIdCollab: any;
  host: any;
  context: any;
  nonaffectes: any;
  selectcode: any;
  exclure_fusionencours: boolean;
  type_collab: string;
  id_simulation: any;
  typegestCode: any;
  required: any;
  fieldWidth: any;
  forceContextHabilitation: any;
  referentiel: any;
  idComponent: any;
  habContext: CWHabilitationContext;
  model: CWCollaborateursModel;
  habContextColl: CWHabilitationContext;
  $appendTo: JQuery;
  useHabilitationSelecteur: boolean;//pour appliquer l'habilitation initial du sélecteur


  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = {
      "change :input:not([readonly])": "_change"
    };
    super(options);
    this.template = TPLReferentielcollaborateurDetail;
    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur");
    this.host = options.host;
    this.context = options.context;
    this.nonaffectes = options.nonaffectes;
    this.selectcode = options.selectcode;
    this["exclure_fusionencours"] = options.exclure_fusionencours;
    this["type_collab"] = options.type_collab || options.typeCollab;
    this["id_simulation"] = options.id_simulation;
    this["typegestCode"] = options.typegestCode;
    this.required = options.required === true ? true : false;
    this.fieldWidth = options.fieldWidth; // Width of the ref input default is "100%"
    this.forceContextHabilitation = options.forceContextHabilitation;
    this.useHabilitationSelecteur = (this.context && this.context.useHabilitationSelecteur === true);//pour appliquer l'habilitation initial du sélecteur ou pas
    this.$appendTo = options.appendTo;
    this.model = new CWCollaborateursModel();
    if (this.host && this.host.workflow && this.host.workflow.formModel) {
      this.habContext = this.host.workflow.formModel.getHabContext();
      if (options.forceContextHabilitation && this.context) {
        this.habContext.set("foncCour", this.context.habilitation);
        this.habContext.set("onglet", this.context.usecase);
      }
    } else {
      this.habContext = new CWHabilitationContext({
        onglet: !CWSTR.isBlank(this.context.usecase) ? this.context.usecase : "referentielcollaborateur",
        foncCour: "RES_POPCOLLAB.V",
        natGest: ""
      });
    }
    this.model.setHabContext(this.habContext);
    this.listenTo(this.model, "change", this._modelChanged);
  }

  setIdCollab(): void {
    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur");
  }

  setIdCollabMatric(matric: string): void {
    this.diversIdCollab = matric
  }

  _modelChanged(): void {
    this.model.trigger("referentielChanged");
  }

  render(): any {
    const json = { "i18n": i18n };
    const coll = new CWCollaborateursColl([], {
      nonaffectes: this.nonaffectes,
      selectcode: this.selectcode,
      "type_collab": this.type_collab,
      "id_simulation": this.id_simulation,
      "aff_collabsimu": !CWSTR.isBlank(this.id_simulation), // by default: true if mode simulated
      "exclure_fusionencours": this.exclure_fusionencours,
      typegestCode: this.typegestCode
    });
    let referentiel: typeof CWSelecteurReferentielView = null;

    this.$el.html(this.template(json));
    // we put the correct nature from the host (usecase)  in the component.
    if ((this.host && this.host.workflow && this.host.workflow.formModel) || this.useHabilitationSelecteur === true) {
      this.habContextColl = new CWHabilitationContext({
        onglet: this.context.usecase,
        foncCour: "RES_POPCOLLAB.G"
      });
      coll.setHabContext(this.habContextColl);
    } else { // if not exist we use the normal context.
      this.habContextColl = new CWHabilitationContext({
        onglet: this.context.usecase,
        foncCour: this.context.habilitation
      });
      coll.setHabContext(this.habContextColl);
    }
    if (!CWSTR.isBlank(coll.id_simulation)) {
      coll.activeSimulation = true;
    }
    referentiel = new CWSelecteurReferentielView({
      coll: coll,
      name: "nom",
      itemRenderer: (model: any): string => {
        if (model.code === null) {
          return model.libelle;
        }
        if (this.diversIdCollab === "matric_aux") {
          return model.nom + " " + model.prenom + " (" + model.matricaux + ")";
        } else {
          return model.nom + " " + model.prenom + " (" + model.matric + ")";
        }
      },
      view: CWSelecteurRefDialogView,
      title: i18n.t('referentielcollaborateur.title_pop'),
      height: 550,
      width: 500,
      required: this.required,
      addItemCallback: (data: any): void => {
        this._responseCallback(data);
      },
      fieldWidth: this.fieldWidth,
      appendTo: this.$appendTo,
      keyDelay: 700
    });
    this.referentiel = referentiel;
    this.$el.find(".cmbSelectionner").html(referentiel.render().el);
    return this;
  }

  _responseCallback(data: any): void {
    this.model.set(data);
    if (this.host && this.host._responseCallback) {
      this.host._responseCallback(data, this.idComponent);
    }
  }

  initWithCode(code: any): void {
    const originalModel = new CWCollaborateursModel();
    const originalCollection = new Backbone.Collection();

    originalModel.id = code;
    if (this.habContext) {
      originalModel.setHabContext(this.habContext);
    }
    originalModel.fetch({
      success: (fresh: any) => {
        originalCollection.push(fresh);
        this.referentiel.setValues(originalCollection);
      }
    });
  }

  remove(): any {
    this.host = null;
    return Backbone.View.prototype.remove.call(this); // Remove view from DOM
  }

  setSelectcode(selectcode: any): void {
    this.selectcode = selectcode;
    this.referentiel.coll.selectcode = selectcode;
  }
}
