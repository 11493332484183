import _ from 'underscore';
import { CWListerEvtGenModel } from './cwListerEvtGen.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';

export type CWEvenementModelCollTypoVar = {
  code: string;
  codif: string;
  ident: string;
  libelle: string;
  oblig: boolean;
  ordre: number;
  restcol: boolean;
  restevt: string;
  type: string;
  valeur: string;
  masque?: string;
};

export type CWEvenementModelCollTypoCaracol = {
  libelle?: string;
  idx?: number;
  code?: string;
  valeur?: string;
};

export type CWEvenementModelCollTypo = {
  caracol?: Array<CWEvenementModelCollTypoCaracol>;
  code?: string;
  evtg_varchar1?: CWEvenementModelCollTypoVar;
  evtg_varchar2?: CWEvenementModelCollTypoVar;
  evtg_varchar3?: CWEvenementModelCollTypoVar;
  evtg_varchar4?: CWEvenementModelCollTypoVar;
  evtg_varchar5?: CWEvenementModelCollTypoVar;
  evtg_varchar6?: CWEvenementModelCollTypoVar;
  evtg_varchar7?: CWEvenementModelCollTypoVar;
  evtg_varchar8?: CWEvenementModelCollTypoVar;
  evtg_varchar9?: CWEvenementModelCollTypoVar;
  evtg_varchar10?: CWEvenementModelCollTypoVar;
  //The rest, if you wish, add it... :·O
  genre?: string;
  groupe?: string;
  intitule?: string;
  libelle?: string;
  nbre?: number;
  saiauto?: { [key: string]: any };
  saidefo?: string;
  sairepet?: boolean;
  soumiswkf?: true;
};
/**
 * Collection of Evenement Generique Model
 */
export class CWEvenementModelColl<TModel extends CWListerEvtGenModel = CWListerEvtGenModel> extends CWPaginatedCollection<TModel> {
  public typo: Array<CWEvenementModelCollTypo>;
  public backupStatuts: string;

  public nbEvtEtatValid: number;
  public nbEvtEtatA: number;
  public nbEvtEtatR: number;

  public nbEvtEtatAcceptes: { [key: string]: any };
  public nbEvtEtatDemandes: { [key: string]: any };
  public nbEvtEtatRefuses: { [key: string]: any };

  public nbEvtTotal: number;

  constructor(models?: CWListerEvtGenModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWListerEvtGenModel;
    super(models, options);

    if (options && options.usecase) {
      this.usecase = options.usecase;
    }
    if (options && options.habilitationG) {
      this.habilitationG = options.habilitationG;
    }
    if (options && options.habilitationV) {
      this.habilitationV = options.habilitationV;
    }
    this.typo = [];
    this.backupStatuts = ""; //copy param statuts
    this.paginated = false;
    this.url = Configuration.restRoot + "/rest/composant/evtgen/liste";
    this.nbEvtTotal = 0;
  }

  setSortings(ctxTriDate: string): void {
    const asc = (ctxTriDate === "Ascendant") ? true : false;
    this.sortings = { datedeb: asc };
  }

  _resetOriginalParams(): void {
    if (this.backupStatuts && this.backupStatuts.length > 0) {
      this.params.statuts = this.backupStatuts;
    }
  }

  _getTypologieCaracteristique(typoCode: string, caracCode: string): CWEvenementModelCollTypoVar | any {
    if (!CWSTR.isBlank(typoCode) && !CWSTR.isBlank(caracCode)) {
      const typo = _.find(this.typo, { code: typoCode });

      if (!CWSTR.isBlank(typo)) {
        //The WS is an a shit, so i need to do this ---------˅
        const carac = _.find(typo, (value: CWEvenementModelCollTypoVar, key: string) => {
          if (key.indexOf("evtg_") > -1 && !CWSTR.isBlank(value)) {
            return value.code === caracCode;
          } else {
            return false;
          }
        });

        return carac;
      }

    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.typo) {
      this.typo = response.typo;
    }
    if (response.evt) {
      return response.evt;
    }
    return response;
  }

  clone(): this {
    const temp = super.clone.call(this);

    temp.typo = _.clone(this.typo);
    return temp;
  }
}