import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

export class CWLibellesModel extends CWBaseModel {


  defaults(): { [key: string]: any } {
    return {
      "code": "",
      "libelle": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }
}