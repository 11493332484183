import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';

/**
 * Briques configuration.
 */
/**
 * Model for Brique 'affectations en cours ou à venir'
 */
/**
 *  */
export class CWAffectationEnCoursModel extends CWReadOnlyModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.urlRoot = Configuration.restRoot + "/rest/brique/affectations/principales/";
  }
}