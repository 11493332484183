import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLParametragebresultatsDetail from '../detail.tpl.html';
import { CWActivateModel } from '../models/cwActivate.model';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseGridModel } from 'src/core/grids/basegrid/cwBaseGrid.model';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWButtonBarView } from 'core/controls/cwButtonBar.view';
import { CWCompteursTableColl } from '../models/cwCompteursTable.collection';
import { CWCompteursTableModel } from '../models/cwCompteursTable.model';
import { CWDeplacerCompteursModel } from '../models/cwDeplacerCompteurs.model';
import { CWEditableGridModel } from 'core/grids/editablegrid/cwEditableGrid.model';
import { CWEditableGridView } from 'core/grids/editablegrid/cwEditableGrid.view';
import { CWEditedRowCompteursView } from './cwEditedRowCompteurs.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListeBriquesModel } from '../../briques/persobriques/models/cwListeBriques.model';
import { CWParametragebresultatsWorkflow } from '../models/cwParametragebresultats.workflow';
import { CWParametrageModel } from '../models/cwParametrage.model';
import { CWRadioBoxView } from 'core/views/cwRadioBox.view';
import { CWSTR } from 'utils/cwStr';
import { CWVoletView } from 'src/core/components/dialog/volet/cwVolet.view';
import { FORMS } from 'utils/forms.js';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
/**
 * Render gerer parametrage of bresultats view
 */

export class CWParametrageView extends CWBaseFormView<CWBaseModel>{

  workflow: CWParametragebresultatsWorkflow;
  isContextInitialized: boolean;
  btnBar: CWButtonBarView;
  changeDetected: boolean;
  context: { [key: string]: any };
  parent: CWVoletView;
  table: CWEditableGridView;
  BRCnbElt: Backbone.Model;
  RadioOptions: Array<any>;
  radioDate: CWRadioBoxView;
  original: Backbone.Collection;
  disabled: boolean;


  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change .parametragebresultats-detail :input": "_manageView",
      "keyup .parametragebresultats-detail :input:not([readonly])": "_notifyEdition"
    }, options.events);
    super(options);
    this.context = options.context;
    this.id = null;
    this.isContextInitialized = false;
    this.template = TPLParametragebresultatsDetail;
    this.model = new CWBaseModel({
      value: null,
      mode: "C"
    });
    if (options.context) {
      /**
      // Context
      // context.ctxEcran
      // context.ctxHabilitation.HabilitationAcces
      // context.ctxHabilitation.HabilitationGestion
      // context.espaceid
      // context.zone
      // context.zoneName
      // context.ctxActionsPossibles
      // context.ctxModeInitialisation
      // context.briqueName
      // context.typebrique
      // context.userid
      // context.codebrique
      **/
      this.model.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces
      }));
    }
    this.parent = options.parent;
    this.workflow = new CWParametragebresultatsWorkflow({ "module": (this.context ? this.context.ctxEcran : null) });
    this.btnBar = new CWButtonBarView({ id: "parametrageResultats_btnbar" });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.parametrageResultats = this;
    this.changeDetected = false;
    this.listenTo(this.model, "change:value", this._mapToForm);
    if (CWSTR.isBlank(this.model.getHabContext())) {
      this.model.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: ""
      }));
    }
    this.listenTo(this.btnBar.model, "change:mode", this._modeChanged);
    this.table = this._initTable();
    this.workflow.table = this.table;
    this.table.model.btnBarModel.off("btn:click");
    this.listenTo(this.table.model.btnBarModel, "btn:click", this._customTableClick);
    this.listenTo(this.table.model.coll, "sync", () => {
      this._checkParametresDivButtons();
    });
    this.BRCnbElt = GLOBAL_DATA.paramDivers.get("BRCnbElt");
    this._initCombos();
    this.pkSelectors = ["codebrique"];
  }

  /**
   * Initializes the edit table view.
   */
  _initTable(): CWEditableGridView {
    const hc = new CWHabilitationContext({// create a copy of the habilitation context to be used by the actions (add/update/remove) originating from the grid
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion
    });
    const tableModel = new CWEditableGridModel({
      coll: new CWCompteursTableColl()
    });
    const hcColl = new CWHabilitationContext({// set habilitations context that the table collection will use
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    });
    const tblColumns = [
      { title: i18n.t('common:parametragebresultats.table_col_compteur'), property: "compteur.code", pk: true, width: 4 },
      { title: i18n.t('common:parametragebresultats.table_col_type'), property: "typeresultat.code", width: 3 },
      { title: i18n.t('common:parametragebresultats.table_col_periodicite'), property: "periodicite.code", width: 2 },
      { title: i18n.t('common:parametragebresultats.table_col_periode'), property: "periodevalo.code", width: 2 },
      { title: i18n.t('common:parametragebresultats.table_col_format'), property: "format", width: 1 }];
    const viewRow = new CWEditedRowCompteursView({
      context: this.context,
      workflow: this.workflow
    });
    const table = new CWEditableGridView({
      id: "liste_de_compteurs",
      columns: tblColumns,
      model: tableModel,
      viewRow: viewRow,
      title: i18n.t('common:parametragebresultats.table_title'),
      showFilteredRowsInTitle: true,
      habilitations: this.context.ctxHabilitation.HabilitationGestion,
      omitHabilitationAttributes: true,
      viewRowTitle: i18n.t('common:parametragebresultats.title_volet'),
      positionNewButtonsOnLeft: true,
      moveInButton: true,
      showDeleteHead: true,
      messageDeleteHead: i18n.t('messages:GT_5337')
    });

    tableModel.usecase = this.context.ctxEcran;//Il faut ajouter cette information pour le message de confirmation de suppression (il doit s'ouvrir dans la même zone)
    hcColl.update({ natGest: "" });
    tableModel.coll.setHabContext(hcColl);
    // Render the Cells for formated the text presentation
    table.cellRenderers["compteur.code"] = (model: CWBaseModel): string => {
      if (model.get("compteur").code < 0) {
        return i18n.t('common:parametragebresultats.table_ajout_ligne');
      } else {
        return model.get("compteur").libelle + " (" + model.get("compteur").code + ")";
      }
    };
    table.cellRenderers["typeresultat.code"] = (model: CWBaseModel): string => {
      if (model.get("compteur").code > 0) {
        return model.get("typeresultat").libelle + "(" + model.get("typeresultat").code + ")";
      } else {
        return ""; // Blank Line.
      }
    };
    table.cellRenderers["periodicite.code"] = (model: CWBaseModel): string => {
      if (CWSTR.getElValue(model, "typeresultat.code") !== "A" && CWSTR.getElValue(model, "typeresultat.code") !== "R") {
        return "";
      } else {

        if (model.get("compteur").code > 0) {
          return model.get("periodicite").libelle + "(" + model.get("periodicite").code + ")";
        } else {
          return ""; // Blank Line.
        }
      }
    };
    table.cellRenderers["periodevalo.code"] = (model: CWBaseModel): string => {
      if (CWSTR.getElValue(model, "typeresultat.code") !== "A" && CWSTR.getElValue(model, "typeresultat.code") !== "R") {
        return "";
      } else {
        if (model.get("compteur").code > 0) {
          return model.get("periodevalo").libelle + "(" + model.get("periodevalo").code + ")";
        } else {
          return ""; // Blank Line.
        }
      }
    };
    table.cellRenderers["format"] = (model: Backbone.Model): string => {
      return !CWSTR.isBlank(model.get("compteur").format) ? model.get("compteur").format : "";
    };
    table.setSortableColumns([""]);
    table.model.btnBarModel.trigger("hab.hide:copy");
    hc.update({ natGest: "M" });
    table.model.setHabContext(hc);
    //Enable row ordering
    table.model.btnBarModel.set("rowOrdering", true);
    //titles -Infobulle
    table.buttonBar.setTitle("monter", i18n.t('common:parametragebresultats.button_remonter'));
    table.buttonBar.setTitle("descendre", i18n.t('common:parametragebresultats.button_descendre'));
    return table;
  }

  _initTableMenu(): void {
    if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && this._checkPublicHab()) {
      this.table.buttonBar.addButton(i18n.t('common:parametragebresultats.btn_separation'), "ajoutLigne", false, null, i18n.t('common:parametragebresultats.button_ligne_separation'));
      this.table.buttonBar.setButtonVisibleInModeC("ajoutLigne", false);
    }
  }

  _checkPublicHab(): boolean {
    let valid = false;

    if (CWSTR.getElValue(this.model.get("value"), "visibilite") !== true) {
      valid = true;
    } else if (CWSTR.getElValue(this.model.get("value"), "visibilite") === true && CWHABILITATION.canView("PER_BRACCTOUS.G")) {
      valid = true;
    }
    valid = valid && CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);
    return valid;
  }

  _modeChanged(): void {
    if (!CWSTR.isBlank(this.model.get("value")) && this.model.get("value").isNew()) {
      this._enableGrid(false);
      this.btnBar.model.trigger("hide:activer");
      this.btnBar.model.trigger("hide:desactiver");
    } else {
      if (this.btnBar.model.get("mode") === "E") {
        this._enableGrid(false);
        this.btnBar.model.trigger("hide:activer");
        this.btnBar.model.trigger("hide:desactiver");
      } else {
        this._enableGrid(true);
        this.btnBar.model.trigger("show:activer");
        this.btnBar.model.trigger("show:desactiver");
      }
    }
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) || !this._checkPublicHab()) {
      this._enableGrid(false);
    }
    if (this.btnBar.model.get("mode") !== "R") {
      this.table.model.btnBarModel.trigger("hab.hide:new");
    } else {
      this.table.model.btnBarModel.trigger("hab.show:new");
    }
  }

  _detachBtnBarButtons(): void {
    if (this.btnBar) {
      this.btnBar.detachButton("activer", this.$el.find(".zoneBtn__activer"));
      this.btnBar.detachButton("desactiver", this.$el.find(".zoneBtn__desactiver"));
    }
  }

  _enableGrid(enabled: boolean): void {
    if (enabled === false) {
      //Disable grid when Create or Copy
      this.table.enabled(false);
    } else {
      //Enable Grid when Not Create or Copy
      this.table.enabled(true);
    }
  }

  _initCombos(): void {
    this.RadioOptions = [];
    if (this.context.zone === "coll") {
      this.RadioOptions.push({ code: "J", libelle: i18n.t('common:parametragebresultats.radio_date') });
      this.RadioOptions.push({ code: "V", libelle: i18n.t('common:parametragebresultats.radio_derniere') });
    } else if (this.context.zone === "resp") {
      this.RadioOptions.push({ code: "J", libelle: i18n.t('common:parametragebresultats.radio_date') });
      this.RadioOptions.push({ code: "H", libelle: i18n.t('common:parametragebresultats.radio_hier') });
    }
    this.radioDate = new CWRadioBoxView({
      options: this.RadioOptions,
      name: "datereference",
      required: true,
      selectedOption: "J",
      orientation: "horizontal"
    });
  }

  _formEdited(): void {
    this.model.trigger("form:edited");
  }

  _getmodel(): void {
    return this.model.get("value");
  }

  _panelStateIsReadonly(): boolean {
    const model = this._getModel();
    let isReadOnly = false;

    if (model && model.context && model.context.ctxHabilitation && model.context.ctxHabilitation.HabilitationGestion) {
      const canUpdate = CWHABILITATION.canView(model.context.ctxHabilitation.HabilitationGestion) && this._checkPublicHab();

      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  }

  _manageModeInitialisation(callback: () => any): void {
    const initMode = this.context.ctxModeInitialisation;
    const parametrage = new CWParametrageModel();

    switch (initMode) {
      case "Consultation":
        this._initModeConsultation(parametrage, callback);
        break;
      case "Ajout":
        this._initModeAjout(parametrage, callback);
        break;
      default:
      //Nothing
    }
  }

  _initModeConsultation(parametrage: CWParametrageModel, callback: () => any): void {
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    });
    parametrage.setHabContext(this.model.getHabContext());
    this.btnBar.model.set("mode", "R");
    parametrage.id = this.context.userid + "," + this.context.codebrique;
    parametrage.espaceid = this.context.espaceid;
    parametrage.action = "GET";
    parametrage.fetch({
      success: (fresh: CWCompteursTableModel) => {
        const lTableColl = (this.table.model.coll as any) as CWCompteursTableColl;

        lTableColl.userid = this.context.userid;
        lTableColl.codebrique = this.context.codebrique;
        lTableColl.fetch({
          success: (freshColl: CWCompteursTableColl) => {
            fresh.action = "";
            fresh.store();
            freshColl.store();
            this.model.set("value", parametrage); //This will trigger a _setContextGMSituation, and this a _mapToForm
            if (_.isBoolean(this.context?.currentBrique?.active)) {
              this._getModel().set("active", this.context.currentBrique.active, { silent: true });
            }
            this.model.store();
            this.model.get("value").store();
            this.original = fresh.clone();
            if (callback) {
              callback();
            }
          }
        });
      },
      error: () => {
        //on doit fermer la popup si l'on n'a pas de droits (par exemple, la brique a changé de publique à privée)
        if (this.parent?.model) {
          this.parent.model.trigger("dlg:close");
        }
      }
    });
  }

  _initModeAjout(parametrage: CWParametrageModel, callback: () => any): void {
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });
    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");
    parametrage.store();
    this.model.set("value", parametrage);
    this.model.get("value").store();
    this.original = parametrage.clone();
    if (callback) {
      callback();
    }
    this.btnBar.model.set("mode", "E");
    if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("btn:click", "new");
      this.btnBar.model.trigger("hide:new");
      this.$el.find(".tableCompteurs").hide();
      this.btnBar.model.trigger("hide:new-menu");
    }
  }

  _manageActionsPossibles(): void {
    //["Creer","Supprimer","Modifier","Valider"]
    if (this.context.ctxActionsPossibles) {
      if (this.context.ctxModeInitialisation === "Consultation") {
        if (this.context.ctxActionsPossibles.indexOf("Supprimer") !== -1) {
          this.btnBar.model.trigger("show:delete");
        }
        if (this.context.ctxActionsPossibles.indexOf("Dupliquer") !== -1) {
          this.btnBar.model.trigger("show:copy");
        }
        if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1) {
          this._disableForm();
        }
        if (this.context.ctxActionsPossibles.indexOf("Activer") === -1) {
          this.btnBar.model.trigger("show:activer");
          this.btnBar.model.trigger("show:desactiver");
        }
      } else {
        this.btnBar.model.trigger("hide:activer");
        this.btnBar.model.trigger("hide:desactiver");
      }
      this.btnBar.model.trigger("hide:new");
    }
  }

  _disableForm(): void {
    this.disabled = true;
    FORMS.setFormReadonly(this.$el, true, false);
  }

  _enableForm(forced?: boolean): void {
    if (forced !== true) {
      forced = false;
    }
    this.disabled = false;
    FORMS.setFormReadonly(this.$el, false, forced);
  }

  _checkParametresDivButtons(): void {
    if (!CWSTR.isBlank(this.BRCnbElt) && parseInt(this.BRCnbElt.get("valeur"), 10) <= this.table.model.coll.models.length) {
      this.table.buttonBar.$el.find(".new").hide();
      this.table.buttonBar.$el.find(".ajoutLigne").hide();
    } else if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      if (this._checkPublicHab()) {
        this.table.buttonBar.$el.find(".new").show();
        this.table.buttonBar.$el.find(".ajoutLigne").show();
      }
    }
  }

  _manageHabilitation(): void {
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      this._disableForm();
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:copy");
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
    }
    if (!CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:copy");
    }
    if (!CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && !CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:save");
    }
    if (!CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!this._checkPublicHab()) {
      if (this.btnBar.model.get("mode") !== "E") {
        this._disableForm();
      }
      this.btnBar.model.trigger("hide:delete");
      //Disable table if it has no habilitation
      this._enableGrid(false);
    }
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.btnBar.model.trigger("hide:activer");
      this.btnBar.model.trigger("hide:desactiver");
    }
  }

  _visibilitePublic(model: Backbone.Model): boolean {
    let publ = false;

    if (model.get("visibilite") === true) {
      publ = true;
    }
    return publ;
  }

  _manageActiverButtons(): void {
    const modelBrique = this?._getModel();

    if (!CWSTR.isBlank(modelBrique) && !modelBrique.isNew() && this.btnBar.model.get("mode") !== "E") {
      if (modelBrique.get("active") === true) {
        this.btnBar.model.trigger("hide:activer");
        if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
          this.btnBar.model.trigger("show:desactiver");
        }
      } else {
        this.btnBar.model.trigger("hide:desactiver");
        if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
          this.btnBar.model.trigger("show:activer");
        }
      }
    } else {
      this.btnBar.model.trigger("hide:activer");
      this.btnBar.model.trigger("hide:desactiver");
    }
  }

  render(): CWParametrageView {
    const json = { "i18n": i18n };

    this.$el.empty();
    this.$el.html(this.template(json));
    this.$el.find(".radioDateReference").html(this.radioDate.render().el);
    if (this.context) {
      this.btnBar.model.off("btn:click");
      this.btnBar.model.on("btn:click", this.workflow._buttonAction, this.workflow);
      this.model.off("form:edited");
      this.model.on("form:edited", this.workflow._formEdited, this.workflow);
      this._setContext(this.context);
    }
    this.$el.find(".tableCompteurs").html(this.table.el);
    this.table.render();
    if (!CWSTR.isBlank(this.model.get("value")) && this.model.get("value").isNew()) {
      this._enableGrid(false);
      this.btnBar.model.trigger("hide:activer");
      this.btnBar.model.trigger("hide:desactiver");
    }
    //disable buttons placer and premier
    $(this.table.buttonBar.$el).find(".placer").hide();
    $(this.table.buttonBar.$el).find(".premier").hide();
    this.$el.find(".tableCompteurs .c-grind__scroll").css({ "max-height": "135px" });
    return this;
  }

  _setTitlePopUp(): void {
    const modelBrique = this?._getModel();

    if (modelBrique && !modelBrique.isNew()) {
      let activeText = null;

      this.$el.find(".titreBrique").html(i18n.t("common:parametragebresultats.ficheBrique"));
      activeText = (modelBrique.get("active") === true) ? i18n.t('common:parametragebresultats.etatBriqueActivée') : i18n.t('common:parametragebresultats.etatBriqueDesactivée');
      //const cssClassText = "ui-phx-ihm-texte-donnees-alternatif-important";
      this.$el.find(".infoCompBrique").addClass("cw-texteNormal cw-texteNormal--selectionne");
      this.$el.find(".infoCompBrique").html(activeText);
    } else {
      let lTitle = "";

      if (this.context.typebrique === "CPTC" || this.context.typebrique === "CPTR") {
        //typebrique	= ‘CPTC’ ou ‘CPTR’-->( permet de déterminer BrAccTypeLibelle actuellement fixe = « Résultats » pour les lignes fictives)
        //"Nouvelle brique d’accueil de type "BrAccTypeLibelle" … "
        lTitle = i18n.t('common:parametragebresultats.new_title', { "0": i18n.t('common:parametragebresultats.typeLibelle_resultats') });
      }
      this.$el.find(".titreBrique").html(lTitle);
      this.$el.find(".infoCompBrique").html("");
    }
    this.parent._setTitle(i18n.t('common:parametragebresultats.title', { "0": this.context.zoneName }));
  }

  _generateButtonBar(): void {
    const peutAcces = (this.context.zone === "coll") ? CWHABILITATION.canView("PER_BRACC_C.G") : CWHABILITATION.canView("PER_BRACC_R.G");
    const peutCreer = peutAcces && (this.context.zone === "coll" ? CWHABILITATION.canCreate("PER_BRACC_C.G") : CWHABILITATION.canCreate("PER_BRACC_R.G"));
    const peutModif = peutAcces && (this.context.zone === "coll" ? CWHABILITATION.canUpdate("PER_BRACC_C.G") : CWHABILITATION.canUpdate("PER_BRACC_R.G"));
    let showButtons = peutAcces;

    if ((showButtons && CWSTR.isBlank(this.context.codebrique) && !peutCreer) || (showButtons && !CWSTR.isBlank(this.context.codebrique) && !peutModif)) {
      showButtons = false;
    }
    if (showButtons) {
      this.$el.find(".btnBar-container").append(this.btnBar.render().el);
      this.$el.find("#parametrageResultats_btnbar").css("float", "left");
      this.btnBar.addButton("activer", i18n.t('common:parametragebresultats.activer'), "", null);
      this.btnBar.addButton("desactiver", i18n.t('common:parametragebresultats.desactiver'), "", null);
      this._detachBtnBarButtons();
    }
  }

  _setContext(context: { [key: string]: any }): void {
    this.workflow.context = context;
    this.model.updateHabContext({
      onglet: context.ctxEcran,
      foncCour: context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    });
    this.context = context;
    if (CWSTR.isBlank(this.context)) {
      throw new Error("A context must be defined...");
    }
    this._generateButtonBar();
    this._initTableMenu();
    this._manageModeInitialisation(() => {
      this._setTitlePopUp();
      this._manageActiverButtons();
      this._manageActionsPossibles();
      this._manageHabilitation();
    });
    if (this.context.ctxModeRepresentation === "pop-up") {
      $(this.parent.el).css("height", "auto");
    }
  }

  _mapToForm(): void {
    const parametrage = this.model.get("value");

    this._enableForm();
    if (this.$el === undefined) {
      // this happens if component has been removed before initialization process has finished
      return;
    }
    if (parametrage !== null) {
      const renderers = {};
      const fieldset = this.$el.find("fieldset");

      // Error management
      parametrage.off("invalid");
      parametrage.on("invalid", this._showValidationErrors, this);
      // Clear current validation erros
      this._cleanValidationErrors();
      //CWFormView mapToForm
      this._mapModelToForm(fieldset, parametrage, renderers);
      if (parametrage.isNew()) {
        fieldset.find(".codebrique").focus();
      }
      if (!CWSTR.isBlank(this.model.get("value"))) {
        this._enablePk(this.model.get("value").isNew());
      }
      if (!CWHABILITATION.canView("PER_BRACCTOUS.G")) {
        FORMS.setFieldReadonly(this.$el.find("input.visibilite"), true, false);
      } else {
        FORMS.setFieldReadonly(this.$el.find("input.visibilite"), false, false);
      }
    }
  }

  _manageView(event: JQueryEventObject, data: object): void {
    this._change(event, data);
  }

  _restoreHabilitation(): void {
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "M"
    });
    this.model.get("value").setHabContext(this.model.getHabContext());
  }

  _restoreParametrage(): void {
    if (!CWSTR.isBlank(this.model.get("value").oldAttributes)) {
      this.model.get("value").revert();
      this.table.model.coll.revert();
      this.table.model.coll.trigger("reset");

      this._restoreHabilitation();

    } else if (this.model.get("value").isNew()) {
      const parametrage = new CWParametrageModel();

      this.model.updateHabContext({
        foncCour: this.context.ctxHabilitation.HabilitationGestion,
        natGest: "A"
      });
      parametrage.setHabContext(this.model.getHabContext());

      this.model.set("value", parametrage);
    }
    this.model.trigger("change:value");
  }

  revertView(): void {
    if (this.model.isNew() && CWSTR.isBlank(this.model["oldAttributes"])) {
      //l'action de "revert" d'un nouveau compteur est fermer le popup.
      this.parent.close();
    } else {
      this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "" });
      this._restoreParametrage();
      this.workflow.btnBarModel.set("mode", "R");
      this._manageActionsPossibles();
      this._manageActiverButtons();
      this._manageHabilitation();
      this._setTitlePopUp();
      if (this.model.get("value").isNew()) {
        this.btnBar.model.set("mode", "C");
      }
    }
  }

  saveParametrage(): void {
    const target = this.model.get("value");
    let saveAction = "update";
    const finalCallback = (fresh: CWListeBriquesModel): void => {
      const lTable = (this.table.model.coll as any) as CWCompteursTableColl;
      const lPosTable = this.$el.find(".tableCompteurs");

      if (!lPosTable.is(":visible")) {
        lPosTable.show();
        this.parent.setPosition({ my: "center", at: "center", of: window });
      }
      this.btnBar.model.set("mode", "R");
      this.btnBar.model.trigger("hide:new");


      this.model.trigger("parametrageChanged", fresh, saveAction);
      this.context.ctxModeInitialisation = "Consultation";
      target.formerID = null;
      target.store();
      lTable.userid = target.get("userid");
      lTable.codebrique = target.get("codebrique");
      if (saveAction === "create") {
        lTable.reset();
      }
      lTable.store();
      this._manageActiverButtons();
      this._restoreHabilitation();
      this.model.trigger("change:value"); //Trigger map to form and _enablepk
      this._setTitlePopUp();
      this._manageHabilitation();
    };
    const saveCallback = (fresh: CWListeBriquesModel): void => {
      if (saveAction === "duplicate") {
        const lTable = (this.table.model.coll as any) as CWCompteursTableColl;

        lTable.userid = fresh.get("userid");
        lTable.codebrique = fresh.get("codebrique");
        lTable.fetch({
          success: (fresh: CWListeBriquesModel) => {
            finalCallback(fresh);
          }
        });
      } else {
        finalCallback(fresh);
      }
    };

    if (target.isNew()) {
      if (!CWSTR.isBlank(target.formerID)) {
        saveAction = "duplicate";
      } else {
        saveAction = "create";
      }
      target.set("userid", objs.appRt.workflow.authModel.get("user"));
      target.set("typebrique", this.context.typebrique);
      this.model.updateHabContext({
        foncCour: this.context.ctxHabilitation.HabilitationGestion,
        natGest: "A"
      });
      target.setHabContext(this.model.getHabContext());
    } else {
      saveAction = "modification";
      this.model.updateHabContext({
        foncCour: this.context.ctxHabilitation.HabilitationGestion,
        natGest: "M"
      });
      target.setHabContext(this.model.getHabContext());
    }
    target.save(null, {
      success: saveCallback
    });
  }

  deleteParametrage(): void {
    const target = this.model.get("value");
    const destroyCallback = (): void => {
      this.model.trigger("parametrageChanged", target, "delete");
      this.parent.close();
    };

    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "S" });
    target.destroy({
      contentType: 'application/json',
      success: destroyCallback,
      error: destroyCallback,
      wait: true
    });
  }

  newParametrage(): void {
    let modeloldAttributes = "";
    let colloldModels = "";
    const parametrage = new CWParametrageModel();

    if (!this.model.get("value").isNew()) {
      this.model.get("value").store();
      this.table.model.coll.store();
      modeloldAttributes = this.model.get("value").oldAttributes;
      colloldModels = this.table.model.coll.oldModels;
    }
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });
    this._enableForm();
    parametrage.setHabContext(this.model.getHabContext());
    this.table.model.coll.reset();
    this.model.set("value", parametrage);
    //Keep old attributes
    if (!CWSTR.isBlank(modeloldAttributes) && !CWSTR.isBlank(colloldModels)) {
      this.model.get("value").oldAttributes = modeloldAttributes;
      this.table.model.coll.oldModels = colloldModels;
    }
    this._setTitlePopUp();
    this._manageActionsPossibles();
    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");
    this.btnBar.model.set("mode", "E");
  }

  activerParametrage(action: string): void {
    const value = this.model.get("value");
    const activateModel = new CWActivateModel();
    const ctxTemp = this.model.getHabContext();
    const activateCallback = (fresh: CWListeBriquesModel): void => {
      if (action === "activer") {
        this.model.get("value").set("active", true);
      } else {
        this.model.get("value").set("active", false); //Update active information
      }
      this.model.get("value").store();
      this.btnBar.model.set("mode", "R");
      this.model.trigger("parametrageChanged", fresh, action);
      this.context.ctxModeInitialisation = "Consultation";
      this._manageActiverButtons();
      this._manageHabilitation();
      this._restoreHabilitation();
      this.model.trigger("change:value"); //Trigger map to form and _enablepk
      this._setTitlePopUp();
    };

    activateModel.userid = value.get("userid");
    activateModel.codebrique = value.get("codebrique");
    activateModel.action = (action === "activer") ? "activate" : "desactivate";
    activateModel.espaceid = this.context.espaceid;
    activateModel.id = "put";
    ctxTemp.update({ foncCour: this.context.ctxHabilitation.HabilitationAcces });
    activateModel.setHabContext(ctxTemp /*this.model.getHabContext()*/);
    activateModel.save(null, {
      success: activateCallback
    });
  }

  _customTableClick(buttonId: string, infoOrigine?: string): void {
    const value = this.model.get("value");
    const model = this.table.model.get("value");

    switch (buttonId) {
      case "save":
        if (!CWSTR.isBlank(model.get("compteur"))) { //Format field is not expected in modification and creation WS
          delete model.get("compteur").format;
        }
        if (model.isNew()) { //Set ordre in creation
          model.set("ordre", this.table.model.coll.length);
        }
        this.table.model._buttonAction(buttonId, () => {
          this.table.model.coll.store();
          this.model.trigger("parametrageChanged", value, "modification");
        });
        break;
      case "delete":
        if (typeof infoOrigine === "string" && infoOrigine === "OrigineVolet") {
          const params: { [key: string]: any } = {
            callback: () => {
              this.table.model.coll.store();
              this.model.trigger("parametrageChanged", value, "modification");
            },
            "infoOrigine": infoOrigine
          };

          this.table.model._buttonAction(buttonId, params);
        } else {
          this.table.model._buttonAction(buttonId, () => {
            this.table.model.coll.store();
            this.model.trigger("parametrageChanged", value, "modification");
          });
        }
        break;
      case "monter":
        this._movePositionInfoComp(model, "monter");
        break;
      case "descendre":
        this._movePositionInfoComp(model, "descendre");
        break;
      case "ajoutLigne":
        if (!CWSTR.isBlank(model.get("compteur"))) { //Format field is not expected in modification and creation WS
          delete model.get("compteur").format;
        }
        this._ajouterLigneSeparation();
        break;
      default:
        this.table.model._buttonAction(buttonId, () => {
          this.table.model.coll.store();
        });
    }
  }

  /**
   * Move the position of the information complementaire.
   */
  _movePositionInfoComp(model: CWBaseGridModel, sens: string): void {
    const value = this.model.get("value");
    let ordre = model.get("ordre"); // Calculate the order
    const deplacerAttrModel = new CWDeplacerCompteursModel(model.attributes);
    const lTable = (this.table.model.coll as any) as CWCompteursTableColl;

    if (sens === "monter") {
      ordre--;
    } else {
      ordre++;
    }
    // Create the model data
    deplacerAttrModel.set("id", model.get("rowid"));
    deplacerAttrModel.userid = this.context.userid;
    deplacerAttrModel.codebrique = lTable.codebrique;
    deplacerAttrModel.ordre = ordre;
    deplacerAttrModel.setHabContext(this.table.model.getHabContext());
    // Save the modification
    deplacerAttrModel.save(null, {
      success: () => {
        lTable.fetch({
          success: () => {
            lTable.store();
            this.model.trigger("parametrageChanged", value, "modification");
            // Select the record and paginate if required
            this.table.model.set("mode", "R");
            this.table.model.selectRow(model);
          }
        });
      }
    });
  }

  _ajouterLigneSeparation(): void {
    const modelValue = this.model.get("value");
    const value = this.table.model.get("value");
    const collection = (this.table.model.coll as any) as CWCompteursTableColl;
    const ligneSeparationModel = new CWCompteursTableModel(value.attributes);
    const url = (): string => {
      return Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(collection.userid + "," + collection.codebrique) + "/detail/";
    };

    ligneSeparationModel.set("id", null); //This should be post
    ligneSeparationModel.id = null;
    ligneSeparationModel.url = url;
    ligneSeparationModel.setHabContext(this.table.model.getHabContext());
    CWSTR.setElValue(ligneSeparationModel, "compteur.code", -value.get("ordre"));
    // Create new ligne de separation
    ligneSeparationModel.save(null, {
      success: () => {
        this.table.model.coll.fetch({
          success: () => {
            this.table.model.coll.store();
            this.model.trigger("parametrageChanged", modelValue, "modification");
            // Select the record and paginate if required
            this.table.model.set("mode", "R");
            this.table.model.selectRow(value);
          }
        });
      }
    });
  }
}
