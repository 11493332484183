import * as _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';


/**
 * Model for accept, reject or view is modificable
 */
export class CWGererBadgeagesGestion extends CWBaseModel {
  action: string;

  /**
 * Default data model
 *		commentaire : "",
 * }
 */
  defaults(): { [key: string]: any } {
    return {
      "commentaire": ""
    }
  }
  //REVIEW: 	i18n : phx.i18n.common.gererbadgeages,
  //REVIEW: 	i18nCom : phx.i18n.common,

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/badgeage/composant/" + this.action + "/" + this.id;
    };
  }

  /**
   * Validate the model before launch the REST
   */
  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    if (this.action === "refuser") {
      if (CWSTR.isBlank(attrs.commentaire)) {
        errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.comment') });
      }
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }

  /**
   * Parsed the results returned by the REST,
   * Copy the return code in the object id
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
}