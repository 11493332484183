import _ from 'underscore';
import { CWBaseGridColums, gridColumnAlign } from './cwBaseGrid.view';
import { CWSTR } from 'utils/cwStr';

/**
 * Intern Object that represents a table vue.
 */
// export var InternVueModel = function(vueName, columns, nongroupee, menuName, buttonText, beforeFetchCallback) {

export class CWInternVueModel {

  _columns: { [key: string]: any }; // Used to store columns
  cellRenderers: { [key: string]: any }; //Allow the definition of custom cell renderer per column
  _visibleColumns: Array<string>; // Used to store default visible columns
  _lockedColumns: Array<string>; // Used to store default locked columns
  _columnsAlign: { [key: string]: gridColumnAlign }; // Used to store the columns alignment
  _sortableCustomNames: { [key: string]: any };
  columns: Array<CWBaseGridColums>;
  nongroupee: boolean;
  menuName: string;
  buttonText: string;
  beforeFetchCallback: (callback: () => void) => void;
  vueName: string;
  code: number | string;
  column: { [key: string]: any };

  constructor(vueName: string, options: { [key: string]: any }) {
    if (!CWSTR.isBlank(options.columns)) {
      this.columns = options.columns;
    }
    if (!CWSTR.isBlank(options.nongroupee)) {
      this.nongroupee = options.nongroupee; // For treeviews, nongroupee indicates that table shouldn't be grouped
    }
    if (!CWSTR.isBlank(options.menuName)) {
      this.menuName = options.menuName;
    }
    if (!CWSTR.isBlank(options.buttonText)) {
      this.buttonText = options.buttonText;
    }
    if (!CWSTR.isBlank(options.beforeFetchCallback)) {
      this.beforeFetchCallback = options.beforeFetchCallback;
    }
    if (!CWSTR.isBlank(vueName)) {
      this.vueName = vueName;
    }
    if (!CWSTR.isBlank(options.code)) {
      this.code = options.code;
    }
    if (!CWSTR.isBlank(options.columns) && options.columns.minWidthMode === true) {
      this.processMinWidth();
    }

    this._columns = {}; // Used to store columns
    this.cellRenderers = {}; //Allow the definition of custom cell renderer per column
    this._visibleColumns = []; // Used to store default visible columns
    this._lockedColumns = []; // Used to store default locked columns
    this._columnsAlign = {}; // Used to store the columns alignment
    this._sortableCustomNames = {};
  }

  processMinWidth(): void {
    _.each(this.columns, (column: CWBaseGridColums, index: string | number) => {
      if (!CWSTR.isBlank(column.minWidth) && !CWSTR.isBlank(index)) {
        this.column[index].width = '100%';
      }
    });
  }
}