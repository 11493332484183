import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model of etats for the left table
 */
export class CWEtatsModel extends CWBaseModel {

  level: number;

  parse(response: { [key: string]: any }): { [key: string]: any } {
    response.id = response.code + "," + response.libelle;
    return response;
  }
}