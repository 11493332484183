import { BaseModel } from 'core/models/base.model.js';

export var HistoRecapitulatifsAndActivitesModel = BaseModel.extend({

  default: {
    "type": null,
    "evenement": null,
    "iddemande": null
  },

  url: Configuration.restRoot + "/rest/wkf/histo/evenement/",
});
