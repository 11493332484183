import _ from 'underscore';
import { CWCombosModel } from './cwCombos.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';

/**
 * Collection for combos
 */
export class CWCombosColl<TModel extends CWCombosModel = CWCombosModel> extends CWPaginatedCollection<TModel> {

  urlMine: string;
  comboId: string;
  name: string;
  urlException: string;

  constructor(models?: TModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWCombosModel;
    super(models, options);
    this.url = (): string => {
      let url = null;

      if (!CWSTR.isBlank(this.urlMine)) {
        url = this.urlMine;
      } else {
        if (!CWSTR.isBlank(this.urlException)) {
          url = Configuration.restRoot + "/rest/" + this.urlException;
        } else {
          if (CWSTR.isBlank(this.name)) {
            url = Configuration.restRoot + "/rest/" + this.comboId;
          } else {
            if (CWSTR.isBlank(this.comboId)) {
              url = Configuration.restRoot + "/rest/" + this.name;
            } else {
              url = Configuration.restRoot + "/rest/" + this.name + "/" + this.comboId;
            }
          }
        }
      }
      return url;
    }
    this.paginated = false;
    this.urlMine = "";
    this.comboId = "";
    this.name = "";
    if (options.model) {
      this.model = options.model;
    }
    if (options.params) {
      this.params = options.params;
    }
    if (options.urlMine) {
      this.urlMine = options.urlMine;
    } else {
      if (options.comboId) {
        this.comboId = options.comboId;
      }
      if (options.name) {
        this.name = options.name;
      } else {
        this.name = "ls";
      }
    }
  }
}