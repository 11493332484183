import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWTypeStructureModel } from './cwTypeStructure.model';

/**
 * Collection of typeStructure
 */

export class CWTypeStructureColl<TModel extends CWBaseModel = CWBaseModel> extends CWPaginatedCollection<CWTypeStructureModel> {

  codestructureRequest: string;
  indselpop: boolean;

  constructor(models?: TModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWTypeStructureModel;
    options.idnames = ["rowid"];
    super(models, options);
    this.url = (): string => {
      let url = Configuration.restRoot + "/rest/structorga";

      if (this.codestructureRequest) {
        url = url + '/' + encodeURIComponent(this.codestructureRequest);
      }
      if (this.indselpop === true) {
        url += "?indselpop=true&attributs=abrege,code,codef,libelle,principal,rattcollab,rattactivite,rattaffectationsec,rattcolpar,rattpret,typeremdesc,libselpop";
      }
      return url;
    }
    this.paginated = false;
    this.indselpop = _.isBoolean(options.indselpop) ? options.indselpop : false;
  }
}
