import _ from 'underscore';
import { CWBaseGridModel } from 'src/core/grids/basegrid/cwBaseGrid.model';

export class CWTreeTypeGridModel extends CWBaseGridModel {

  /**
   * Constructor
   * Model underlying a Tree type DataGrid
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  /**
   * Called when do a click on a row, performs the selection of that row.
   * Overrides _manageRowSelection in basegrid
   */
  _manageRowSelection(model: any, options: { [key: string]: any }): void {
    if (model && options && options.deploy === true) {
      this._expandPath(model);
    }
    CWBaseGridModel.prototype._manageRowSelection.call(this, model, options);
  }

  /**
   * Method used to expand a path to an specific row
   */
  _expandPath(model: any): void {
    const path = model.path;
    const level = model.level;
    if (model.visible === false) {
      _.each(this.coll.models, (mod: any) => {
        if (level > mod.level && path.toString().indexOf(String(mod.path)) === 0) {
          //the model represents a parent row of the row we want to click
          if (mod.isExpanded === false) {
            mod.trigger("treetbl_exp", mod);
          }
        }
      });
    }
  }
}
