import * as Backbone from 'Backbone';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { i18n } from 'src/i18n.js';
import { CWSTR } from 'utils/cwStr';

export class CWDelegantRenderView extends Backbone.View {

  ctxEcran: string
  workflow: any;

  constructor(options: { [key: string]: any }) {
    super(options);
    this.model = null;
    if (options && options.model) {
      this.model = options.model;
    }
    this.workflow = null;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    if (options && options.ctxEcran) {
      this.ctxEcran = options.ctxEcran;
    }
  }

  render(): CWDelegantRenderView {

    let text = "";

    if (!CWSTR.isBlank(this.model.get("delegant"))) {
      if (!CWSTR.isBlank(this.model.get("delegant").get("matricule"))) { // if have a collaborateur
        if (this.workflow.paramIdCollab === "matric" || CWSTR.isBlank(this.workflow.paramIdCollab)) {
          text = this.model.get("delegant").get("nom") + " " + this.model.get("delegant").get("prenom") + " (" + this.model.get("delegant").get("matriculeaux") + ")";
          this.$el.html(text).attr("title", text);
        } else if (this.workflow.paramIdCollab === "matric_aux") {
          text = this.model.get("delegant").get("nom") + " " + this.model.get("delegant").get("prenom") + " (" + this.model.get("delegant").get("matriculeaux") + ")";
          this.$el.html(text).attr("title", text);
        }
      } else { // if have a utilisateur
        text = i18n.t('droitsdel.utilisateur') + " <span style='font-weight: bold'>" + this.model.get("delegant").get("utilnom") + "</span>";
        this.$el.html(text).attr("title", text);
      }
    }

    // ::::::: buttonBarcontrol ::::::::::::::::::::::::

    const USECASE = this.ctxEcran;

    this.workflow.btnBarModel.trigger("show:save");
    this.workflow.btnBarModel.trigger("show:revert");

    if (USECASE === "utilisateur") {
      if (!CWHABILITATION.canCreate("ADM_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:new");
        this.workflow.btnBarModel.trigger("hide:copy");
      }
      if (!CWHABILITATION.canUpdate("ADM_DELEG") && !CWHABILITATION.canCreate("ADM_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:save");
        this.workflow.btnBarModel.trigger("hide:revert");
      }
      if (!CWHABILITATION.canDelete("ADM_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:delete");
      }
    } else if (USECASE === "suivicollab") {
      if (!CWHABILITATION.canCreate("RES_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:new");
        this.workflow.btnBarModel.trigger("hide:copy");
      }
      if (!CWHABILITATION.canUpdate("RES_DELEG") && !CWHABILITATION.canCreate("RES_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:save");
        this.workflow.btnBarModel.trigger("hide:revert");
      }
      if (!CWHABILITATION.canDelete("RES_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:delete");
      }
    } else {
      if (!CWHABILITATION.canCreate("COL_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:new");
        this.workflow.btnBarModel.trigger("hide:copy");
      }
      if (!CWHABILITATION.canUpdate("COL_DELEG") && !CWHABILITATION.canCreate("COL_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:save");
        this.workflow.btnBarModel.trigger("hide:revert");
      }
      if (!CWHABILITATION.canDelete("COL_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:delete");
      }
    }
    return this;
  }
}