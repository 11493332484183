import { BaseModel } from 'core/models/base.model.js';

/**
 * Initier les rattachements aux structures
 */

export var InitRattEmpruntCollab = BaseModel.extend({

  url: function() {
    return Configuration.restRoot + "/rest/composant/pret/initratt";
  },

  defaults: {
    "collabremplace": null,
    "datedeb": ""
  }
});
