//import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { GLOBAL_DATA } from 'src/globalData';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { IntermediateSituationView } from './intermediateSituation.view.js';
import { objs } from 'src/objectsRepository';
import { CWSTR } from 'utils/cwStr';

export var GmSituationView = Backbone.View.extend({

  className: "gmsituation",

  open: function(paramCollabId) {
    var valoHab = "COL_SITUVAL.V";

    if (this.module && this.module === "agenda_R") {
      valoHab = "RES_RESCPT.V";
    }
    if (CWHABILITATION.canView(valoHab) === true) {
      var collab = (paramCollabId) ? paramCollabId : objs.appRt.workflow.authModel.get("matricule");
      var context = {};

      // load dependencies to component
      // set context
      context.resultatsCollab = collab;
      context.resultatsGroupe = 33;
      context.resultatsDate = null;
      context.resultatsPRDde = GLOBAL_DATA.paramDivers.get("demabs_sit");
      context.resultatsPRSht = GLOBAL_DATA.paramDivers.get("SouhAbs");
      context.resultatsMode = "collaborateur";
      context.resultatsAuto = GLOBAL_DATA.paramDivers.get("affCpt_sit");
      context.resultatsTitre = false;
      /*Customer 200953*/
      context.ctxHabilitation = _.isEqual(valoHab, "RES_RESCPT.V") ? "RES_RESCPT.V" : "COL_SITUVAL.V";
      context.ctxEcran = "gmsituation";
      context.ctxModule = this.module;
      context.ctxUser = this.objuser;
      if (String(context.resultatsAuto.get("valeur")) === "1") {
        context.resultatsAuto = true;
      } else {
        context.resultatsAuto = false;
      }
      if (String(context.resultatsPRSht.get("valeur")) === "1") {
        context.resultatsPRSht = true;
      } else {
        context.resultatsPRSht = false;
      }
      if (String(context.resultatsPRDde.get("valeur")) === "1") {
        context.resultatsPRDde = true;
      } else {
        context.resultatsPRDde = false;
      }
      if (paramCollabId) {
        context.resultatsPRDde = context.resultatsPRSht = context.resultatsDate = null;
      }
      this.dialogView = new CWDialogPopupView({
        view: IntermediateSituationView,
        popupType: CWDialogPopupType.AVEC_SAISIE,
        viewData: {
          context: context,
          modal: false
        }
      });
      this.dialogView.setHeight(700);
      this.dialogView.setWidth(450);
      // open popup
      this.dialogView.open();
    }
  },

  close: function() {
    if (this.dialogView) {
      this.dialogView.close();
      this.dialogView.remove();
    }
    if (this.$el) {
      this.$el.remove();
    }
  },

  setContext: function(context) {
    // set context
    if (this.dialogView && !CWSTR.isBlank(this.dialogView.internalView)) {
      this.dialogView.internalView.setContext(context);
    }
    if (context && context.module) {
      this.module = context.module;
    }
    if (context && context.userobj) {
      this.objuser = context.userobj;
    }
  }
});
