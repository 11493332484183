import * as Backbone from 'Backbone';

/**
 * Model for Combos
 */
export var DesactivateMessageModel = Backbone.Model.extend({
  //on ne doit pas utiliser "BaseModel" parce que ce modèle est utilisé en MSGS et celui, dans BaseModel-> problème//BaseModel.extend({

  url: function() {
    var url = Configuration.restRoot + "/rest/utilitaire/messages/famille/" + encodeURIComponent(this.famille) + "/code/" + encodeURIComponent(this.numero) + "/desactive";

    return url;
  }
});
