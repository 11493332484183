module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="calendacc-customBar form-row">\n    <div class="form-group" style="text-align: center">\n        <span class="arrow leftArrow">'+
((__t=(UTILS.getSVGIconButton("fleche_pleine_gauche","cw-icon--primary", null, i18n.t('common:ARIA_label.prev_year'))))==null?'':__t)+
'</span>\n    </div>\n    <div class="form-group">\n        <label for="year_id" style="display:none" aria-hidden="false">'+
((__t=(i18n.t('calendacc.year')))==null?'':__t)+
'</label>\n        <input type="text" class="year_id year form-control cw-texteAllege--selectionne" value="" maxlength="4" />\n    </div>\n    <div class="form-group" style="text-align: center">\n        <span class="arrow rightArrow">'+
((__t=(UTILS.getSVGIconButton("fleche_pleine_droite","cw-icon--primary", null, i18n.t('common:ARIA_label.next_year'))))==null?'':__t)+
'</span>\n    </div>\n</div>\n</div>';
}
return __p;
};
