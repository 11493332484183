import { CWBaseModel } from 'src/core/models/cwBase.model';


export class CWAccepterRecap extends CWBaseModel {

  constructor(options?: { [key: string]: any }) {
    super(options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/accepter/"
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "commentaire": "",
      "droit": "",
      "profil": ""
    }
  }
}
