import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWActiviteModel } from 'src/uc/common/evenements/planifier/activite/models/cwActivite.model';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTree2View } from 'core/tree/cwTree2.view';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

export abstract class CWBaseTreeView extends Backbone.View {
  FAMILLE: string;
  HIERARCHIE: string;
  STRUCTURESIMPLE: string;
  STRUCTUREDETAIL: string;
  tree: CWTree2View;
  racineModel: CWActiviteModel;
  workflow: any;
  params: any;

  /**
   * Constructor
   * Hierarchies Activites Tree
   */
  constructor(options?: Backbone.ViewOptions) {
    options = options || {};
    options.tagName = "span";
    options.className = "phx-activite-tree";
    super(options);

    this.FAMILLE = "FAMILLE";
    this.HIERARCHIE = "HIERARCHIE";
    this.STRUCTURESIMPLE = "STRUCTURESIMPLE";
    this.STRUCTUREDETAIL = "STRUCTUREDETAIL";
  }

  /**
   * To build chemin in order to generate tooltip on the tree
   */
  _createCheminLibelle(libelle: string): string {
    if (!CWSTR.isBlank(libelle)) {
      const cheminArray = libelle.split("/");

      let cheminResult = "";
      let i = 0;
      for (i = cheminArray.length; i > 0; i--) {
        if (!CWSTR.isBlank(cheminArray[(i - 1)])) {
          cheminResult += "/" + cheminArray[(i - 1)];
        }
      }
      return cheminResult;
    } else {
      return "";
    }
  }

  _paintTooltipPeriod(rattachement: { [key: string]: any }): string {
    let text = "";
    if (CWSTR.isBlank(rattachement.attributes)) //try to use _.isObject() function of underscore
    { //rattachement is not a model
      if (rattachement.datedeb === CWTYPE.DATE.INITIAL && rattachement.datefin === CWTYPE.DATE.INFINITY) {
        text = "[...-...]";
      } else if (rattachement.datedeb === CWTYPE.DATE.INITIAL) {
        text = "[...-" + CWTYPE.DATE.format(rattachement.datefin) + "]";
      } else if (rattachement.datefin === CWTYPE.DATE.INFINITY) {
        text = "[" + CWTYPE.DATE.format(rattachement.datedeb) + "-...]";
      } else {
        text = "[" + CWTYPE.DATE.format(rattachement.datedeb) + "-" + CWTYPE.DATE.format(rattachement.datefin) + "]";
      }
    } else { //rattachement is a model
      if (rattachement.get("datedeb") === CWTYPE.DATE.INITIAL && rattachement.get("datefin") === CWTYPE.DATE.INFINITY) {
        text = "[...-...]";
      } else if (rattachement.get("datedeb") === CWTYPE.DATE.INITIAL) {
        text = "[...-" + CWTYPE.DATE.format(rattachement.get("datefin")) + "]";
      } else if (rattachement.get("datefin") === CWTYPE.DATE.INFINITY) {
        text = "[" + CWTYPE.DATE.format(rattachement.get("datedeb")) + "-...]";
      } else {
        text = "[" + CWTYPE.DATE.format(rattachement.get("datedeb")) + "-" + CWTYPE.DATE.format(rattachement.get("datefin")) + "]";
      }
    }

    return text;
  }

  _selectCorrectNodeAfterDup(origineNodeValue: CWBaseModel, freshModelToSelect: { [key: string]: any }): void {

    let nodeFound = false;
    const code = CWSTR.getElValue(origineNodeValue, "code");
    const hierid = CWSTR.getElValue(origineNodeValue, "hierid");
    this.tree.findElement(this.tree.root, code, null, true, (node: { [key: string]: any }): void => {
      if (!hierid || String(node.model.node.get("hierid")) === String(hierid)) {
        //Found the node origine of duplication
        const callbackDup = (parents: { [key: string]: any }): void => {
          _.each(parents, (parent: { [key: string]: any }): void => {
            if (nodeFound === false) {
              this.tree.findElement(parent, freshModelToSelect.get("code"), null, true, (nodeDuplicated: { [key: string]: any }): void => {
                //For the first node duplicated found that is a brother of the node origine, select it
                if (nodeFound === false) {
                  $(nodeDuplicated.$el.find(".phx-treenode-label")).trigger("click");
                }
                nodeFound = true;
              });
            }
          });
        }
        this.model.trigger("findExpandedParents", code, callbackDup);
      }
    });
    if (nodeFound === false) {
      this.tree._selectFirstNode();
    }
  }

  _sortTree(a: { [key: string]: any }, b: { [key: string]: any }): number {
    let compA = a.model.get("label");
    let compB = b.model.get("label");
    let comp = (compA < compB) ? -1 : (compA > compB) ? 1 : 0;

    //Comparation for hors structure and hors famille
    if (a.model.node.get("code") === " ") {
      return -1;
    } else if (b.model.node.get("code") === " ") {
      return 1;
    }

    if (comp === 0) {
      // comparing with datedeb de validité
      if (!CWSTR.isBlank(a.model.node) && !CWSTR.isBlank(b.model.node)) {
        compA = a.model.node.get("datedeb");
        compB = b.model.node.get("datedeb");
        comp = (compA < compB) ? 1 : (compA > compB) ? -1 : 0;
      } else {
        return 0; //0 means that both are equal
      }

    }
    return comp;
  }

  //llamada al pulsar un nodo
  _setSelection(model: { [key: string]: any }): void {
    this.tree.$el.find("span.ui-state-active").each(function () {
      if ($(this).hasClass("cw-treeNode-lastNodeSelected")) { //eslint-disable-line
        //If it was the last selected we only change that class
        $(this).removeClass("cw-treeNode-lastNodeSelected"); //eslint-disable-line
      } else {
        //As it's not multiple and not the last selection we need to remove the class
        $(this).removeClass("ui-state-active"); //eslint-disable-line
      }
    });
    this.model.set("value", model.node);
  }

  render(): this {
    return this;
  }

  initializeRacineModel(model?: { [key: string]: any }): CWActiviteModel {
    const newModel = model ? model.clone() : this.racineModel.clone();
    const domaineDetail = this.workflow.context.ctxDomaineDetail;
    newModel.domaine = !CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domcode") : "";
    return newModel;
  }

  /**
   * To can set the params structure Id when is loaded or changed.
   */
  _setDomaineID(model: { [key: string]: any }): void {
    this.params.domaine = model.get("domaine");
  }

  /**
   * Se lanza al borrar una ACTIVIDAD.
   *
   * Update all parents in the tree for a node that has been deleted
   */
  updateParentAfterDelete(freshModel: CWBaseModel): void {

    const previousNode = this._obtainPreviousNode(freshModel);
    const callback = (): void => {
      this._updateActiviteParents(freshModel, (): void => {
        const sizeRootSons = this.tree.root.sonsColl.length;
        if (!CWSTR.isBlank(previousNode)) {
          previousNode.trigger("selectNode");
        } else if (sizeRootSons > 0) {
          this.tree._selectFirstNode();
        } else {
          this._emptyForm(true);
        }
      });
    };
    this.model.trigger("updateTreeNodeRecursive", callback); //Update the root in order to delete first level activities

  }

  updateParentAfterDeleteNoSelect(freshModel: { [key: string]: any }): void {

    const callback = (): void => {
      this._updateActiviteParents(freshModel);
    };
    this.model.trigger("updateTreeNodeRecursive", callback); //Update the root in order to delete first level activities
  }

  _getCompetencePartielle(model: { [key: string]: any }): string {
    let competencePartielle = "";
    if (!CWSTR.isBlank(model.get("indic_comp_req")) && model.get("indic_comp_req") === "O") {
      competencePartielle = "<span style=\"display: inline-block; margin-left:3px; margin-top: 2px; position: absolute;\"><span class=\"phx-icon ui-phx-icon-metier ui-phx-competence-partielle\" title='" + i18n.t('messages:GT_1594') + "'></span></span>";
    }
    return competencePartielle;
  }

  _getCouvertureCertifications(model: { [key: string]: any }): string {
    let couvertureCertifications = "";
    if (!CWSTR.isBlank(model.get("indic_comp_req")) && model.get("indic_comp_req") === "E") {
      let tooltipTitle = "";
      if (!CWSTR.isBlank(model.get("mes1595")) && model.get("mes1595").length >= 1) {
        _.each(model.get("mes1595"), (message: { [key: string]: any }, index: number): void => {
          message["{0}"] = message["@1"];
          message["{1}"] = message["@2"];
          message["{2}"] = message["@3"];
          tooltipTitle = tooltipTitle + CWSTR.buildMessageParametres(i18n.t('messages:GT_1595'), model.get("mes1595")[index]) + "<br>";
        });
      }
      couvertureCertifications = "<span style=\"display: inline-block; margin-left:3px; margin-top: 2px; position: absolute;\"><span class=\"phx-icon ui-phx-icon-metier ui-phx-competence-partielle\" title='" + tooltipTitle + "'></span></span>";
    }
    return couvertureCertifications;
  }

  _getInaptitudeComplete(model: { [key: string]: any }, padding?: number): string {
    let inaptitudeTotale = "";
    const paddingleft = padding + 3;
    if (!CWSTR.isBlank(model.get("indic_inapt")) && model.get("indic_inapt") === "C") {
      inaptitudeTotale = "<span style=\"display: inline-block; margin-left:" + paddingleft + "px; margin-top: 2px; position: absolute;\"><span class=\"phx-icon ui-phx-icon-metier ui-phx-inaptitude-totale\" title='" + i18n.t('messages:GT_1596') + "'></span></span>";
    }
    return inaptitudeTotale;
  }

  _getInaptitudePartielle(model: { [key: string]: any }, padding?: number): string {
    let inaptitudePartielle = "";
    const paddingleft = padding + 3;
    if (!CWSTR.isBlank(model.get("indic_inapt")) && model.get("indic_inapt") === "P") {
      let tooltipTitle = "";
      if (!CWSTR.isBlank(model.get("mes1599")) && model.get("mes1599").length > 1) {
        _.each(model.get("mes1599"), (message: { [key: string]: any }, index: number): void => {
          message["{0}"] = message["@1"];
          message["{1}"] = message["@2"];
          message["{2}"] = message["@3"];
          tooltipTitle = tooltipTitle + CWSTR.buildMessageParametres(i18n.t('messages:GT_1599'), model.get("mes1599")[index]) + "<br>";
        });
      }
      inaptitudePartielle = "<span style=\"display: inline-block; margin-left:" + paddingleft + "px; margin-top: 2px; position: absolute;\"><span class=\"phx-icon ui-phx-icon-metier ui-phx-inaptitude-partielle\" title='" + i18n.t('messages:GT_1599', { "1": model.get("mes1599") }) + "'></span></span>";
    }
    return inaptitudePartielle;
  }

  abstract _obtainCode(): string;

  abstract _obtainView(): string;

  abstract _obtainLastParentView(): { [key: string]: any };

  _obtainPreviousNode(freshModel: CWBaseModel): { [key: string]: any } {
    const hierid = CWSTR.getElValue(freshModel, "hierid");
    const code = CWSTR.getElValue(freshModel, "code");
    let previousNodeModel: { [key: string]: any } = null;

    this.tree.findElement(this.tree.root, freshModel.get("code"), freshModel.get("datedeb"), true, (node: { [key: string]: any }, treatedArray: any[]) => {
      if (String(node.model.node.get("hierid")) === String(hierid) && String(node.model.node.get("code")) === String(code)) {
        let index = _.indexOf(treatedArray, node);
        do {
          if (treatedArray.length > 1) {
            index = index > 0 ? index - 1 : index + 1;
          }
          if (index >= 0) {
            previousNodeModel = treatedArray[index].model.node;
            if (previousNodeModel.get("code") === " " || String(previousNodeModel.get("code")) === String(code) || previousNodeModel.get("typelt") !== "A") {
              //If previousNodeModel is hierarchie set previousNodeModel to null in order to select first
              //if previousNodeModel is the model what we are looking for then we omit it.
              previousNodeModel = null;
            }
          }
        } while (CWSTR.isBlank(previousNodeModel) && index > 0);
      }
    });
    return previousNodeModel;
  }
  /**
   * empty form setting value to null
   * Treee value change will set btnBarMolde to C and the form will be empty
   */
  _emptyForm(empty: boolean): void {
    if (empty === true) { //If no element has been selected empty form and set it to blank
      this.model.set("value", null, { silent: true });
      this.model.trigger("change:value");
    }
  }

  /**
   * Busca todos los puntos donde esta la actividad y actualiza al padre.
   * Update all parents of an activite and select current activite after updates
   * If current ativite is not found, select fist node
   */
  _updateActiviteParents(activite: { [key: string]: any }, callbackAfterUpdate?: () => void, code?: string): void {

    code = (!CWSTR.isBlank(activite) && !CWSTR.isBlank(activite.get("code"))) ? activite.get("code") : code;
    const callback = (expandedParents: { [key: string]: any }[]): void => {
      let parentsLength = 0;
      parentsLength = expandedParents.length - 1;
      let callbackExecuted = false;
      _.each(expandedParents, (parentNode: { [key: string]: any }, index: number): void => {
        if (index === parentsLength) {
          if (String(parentNode.model.get("level")) === "0" && parentNode.model.firstLevelNode === true) { //This is the fictitious root, so update it
            this.tree.root.model.trigger("updateTreeNodeRecursive", callbackAfterUpdate);
          } else {
            this.model.trigger("updateTreeNode", parentNode.model.node.get("code"), parentNode.model.node.get("datedeb"), parentNode.model.node.get("hierid"), callbackAfterUpdate);
          }
          callbackExecuted = true;
        } else {
          if (String(parentNode.model.get("level")) === "0" && parentNode.model.firstLevelNode === true) { //This is the fictitious root, so update it
            this.tree.root.model.trigger("updateTreeNodeRecursive");
          } else {
            this.model.trigger("updateTreeNode", parentNode.model.node.get("code"), parentNode.model.node.get("datedeb"), parentNode.model.node.get("hierid"));
          }
        }
      });
      if (callbackExecuted === false && callbackAfterUpdate) {
        callbackAfterUpdate();
      }
    };

    this.model.trigger("findExpandedParents", code, callback);
  }

  /** expands a full path in the tree and executes callback if no callback informed it selects the
   * end node of the path
   */
  expandTreePath(path: any[], leafNode: { [key: string]: any }, updatePath: boolean, callbackAfterExpand: () => void): void {
    const index = path.length - 1;
    this._expandPathRecursively(path, index, this.tree.root, leafNode, updatePath, callbackAfterExpand);
  }

  _expandPathRecursively(path: any[], index: number, root: { [key: string]: any }, leafNode: { [key: string]: any }, updatePath: boolean, callbackAfterExpand: () => void): void {
    if (index >= 0) {
      const item = path[index];

      // expand nodes
      this.tree.findElement(root, item.code, item.datedeb, false, (node: { [key: string]: any }) => {
        if (!node.model.get("expanded")) {
          node._expand(null, () => {
            this._expandPathRecursively(path, --index, node, leafNode, updatePath, callbackAfterExpand);
          });
        } else {
          if (updatePath === true && index === 0) { //Current node is already expanded but we have to update
            //it because it is last parent and we have to update its sons data
            const callback = (): void => {
              this._expandPathRecursively(path, --index, node, leafNode, updatePath, callbackAfterExpand);
            };
            node.model.trigger("updateTreeNode", callback);
          } else {
            this._expandPathRecursively(path, --index, node, leafNode, updatePath, callbackAfterExpand);
          }
        }
      });
    } else {
      // select last node of the path
      let nodeFound = false;
      this.tree.findElement(root, leafNode.code, leafNode.datedeb, false, (node: { [key: string]: any }) => {
        if (callbackAfterExpand) {
          callbackAfterExpand();
        } else {
          node.itemSelected();
        }
        nodeFound = true;
      });
      if (nodeFound === false) {
        if (callbackAfterExpand) {
          callbackAfterExpand();
        } else {
          this.tree._selectFirstNode();
        }
      }
    }
  }

  updateTreeNode(elementCode: string, datedeb: string, hierid: string, callback: () => void): void {
    let found = false;
    this.tree.findElement(this.tree.root, elementCode, datedeb, true, (node: { [key: string]: any }) => {
      if (String(node.model.node.get("hierid")) === String(hierid)) {
        found = true;
        if (callback) {
          node.model.trigger("updateTreeNode", callback);
        } else {
          node.model.trigger("updateTreeNode");
        }
      }
    });
    if (found === false && callback) {
      callback();
    }
  }

  updateTreeNodeRecursive(callback: () => void): void {
    this.tree.root.model.trigger("updateTreeNodeRecursive", callback);
  }

  /**
   * Selects desired node and if it is not found sleects first selectable node
   */
  _selectNode(model: { [key: string]: any }, datedeb?: string, code?: string): void {

    let nodeFound = false;
    code = !CWSTR.isBlank(code) ? code : model.get("code");
    this.tree.findElement(this.tree.root, code, datedeb, true, (node: { [key: string]: any }) => {
      node.$el.find(".phx-treenode-label.ui-state-active").trigger("click");
      nodeFound = true;
    });
    if (nodeFound === false) {
      this.tree._selectFirstNode();
    }
  }

  /**
   * Find the expanded parents in the tree. Use the recursive function findElementParents for a deep tree.
   */
  findExpandedParents(elementCode: string, callback: (parents: { [key: string]: any }[]) => void): { [key: string]: any }[] {
    const parents: { [key: string]: any }[] = [];
    this.findExpandedParentsRecursive(this.tree.root, elementCode, true, (node: { [key: string]: any }) => {
      parents.push(node);
    });
    if (callback) {
      callback(parents);
    }
    return parents;
  }

  /**
   * Recursive function to find all parents, of the deleted element.
   */
  findExpandedParentsRecursive(parentNode: { [key: string]: any }, elementCode: string, recursive: boolean, callback: (parentNode: { [key: string]: any }) => void): void {

    _.each(parentNode.sonsColl, (childNode: { [key: string]: any }): void => {
      // recursive navigation thru all the nodes
      if (childNode.model.get("expanded") && recursive) {
        this.findExpandedParentsRecursive(childNode, elementCode, recursive, callback);
      }
      // if node == elementCode then we call the callback function for further processing
      if (childNode.model.node.get("code") === elementCode) {
        callback(parentNode);
      }
    });
  }

  remove(): Backbone.View<Backbone.Model> {
    // COMPLETELY UNBIND THE VIEW
    if (this.$el) {
      if (this.$el.off) {
        this.undelegateEvents();
      }
      this.$el.removeData().unbind();
    }
    this.close();
    const resp = super.remove(); // Remove view from DOM
    delete this.$el; // Delete the jQuery wrapped object variable
    delete this.el; // Delete the variable reference to this node
    return resp;
  }

  close(): void {
    if (this.tree) {
      this.tree.remove();
      this.tree = null;
    }
  }

  checkSelectionableLeafts(collectionNewTreeBranch: { [key: string]: any }): void {
    if (this.workflow.context && this.workflow.context.ctxOnlySelectLeaf === true) {
      _.each(collectionNewTreeBranch.models, (treebranch: { [key: string]: any }) => {
        if (treebranch.get("feuille") === false) {
          treebranch.set("selectionnable", false);
        }
      });
    }
  }

}
