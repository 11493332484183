import _ from 'underscore';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWRemoteFileModel } from 'core/models/cwRemoteFile.model';

/**
 * Liste pieces jointes collection
 */
export class CWPiecesJointesColl extends CWPaginatedCollection<CWRemoteFileModel> {

  public id: string;

  constructor(models?: CWRemoteFileModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWRemoteFileModel;
    super(models, options);
    this.paginated = false;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/fichier/liste/" + encodeURIComponent(this.id);
    };
  }
}