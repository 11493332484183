module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc" data-bloc-name="D" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.date')))==null?'':__t)+
'">\n  <div class="form-group">\n    <label for="dateDebD" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.du")))==null?'':__t)+
'</label>\n    <div class="c-date-selector input-group">\n        <input type="text" class="dateDebD datedeb typeDate periodStart form-control cw-required" periodId="1" value="" size="17" />\n    </div>\n  </div>\n</div>\n<div class="cw-multiLabel-bloc" data-bloc-name="P" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.periode')))==null?'':__t)+
'">\n  <div class="multiLabelTypeSaisie">\n    <fieldset class="multiLabelTypeSaisie_form cw-fieldset">\n      <div class="row">\n        <div class="form-group col">\n          <label for="dateDebP" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.periodeDeb")))==null?'':__t)+
'</label>\n          <div class="c-date-selector input-group">\n            <div class="input-group-prepend">\n              <div for="dateDebP" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.du")))==null?'':__t)+
'</div>\n            </div>\n            <input type="text" class="dateDebP datedeb typeDate periodStart form-control cw-required" periodId="1" value="" size="10" />\n          </div>\n        </div>\n\n        <div class="form-group col">\n          <label for="modesai_periodeb.code" style="display:none" class="phx-inline-label"></label>\n          <span class="comboUniteDeb"></span>\n        </div>\n\n        <div class="form-group col heuredeb-container">\n          <input type="text" class="heuredeb form-control typeHourMinuteNightly" size="5">\n          <label for="heuredeb" class="sr-only"></label>\n        </div>\n      </div>\n\n      <div class="row">\n        <div class="form-group col">\n          <label for="dateFinP" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.periodeFin")))==null?'':__t)+
'</label>\n          <div class="c-date-selector input-group">\n            <div class="input-group-prepend">\n              <div for="dateFinP" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.au")))==null?'':__t)+
'</div>\n            </div>\n            <input type="text" class="dateFinP datefin typeDate periodEnd form-control" periodId="1" value="" size="10" />\n          </div>\n        </div>\n\n        <div class="form-group col">\n          <label for="modesai_periofin.code" style="display:none" class="phx-inline-label"></label>\n          <span class="comboUniteFin"></span>\n        </div>\n\n        <div class="form-group col heurefin-container">\n          <input type="text" class="heurefin form-control typeHourMinuteNightly" size="5">\n          <label for="heurefin" class="sr-only"></label>\n        </div>\n      </div>\n\n    </fieldset>\n  </div>\n  <span class="label-presencehorspresence-periode"></span>\n</div>\n<div class="cw-multiLabel-bloc" data-bloc-name="R" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.repetition')))==null?'':__t)+
'">\n  <div class="row">\n    <div class="form-group col">\n      <label for="dateDebR" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.periodeDeb")))==null?'':__t)+
'</label>\n      <div class="c-date-selector input-group">\n        <div class="input-group-prepend">\n          <div for="dateDebR" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.du")))==null?'':__t)+
'</div>\n        </div>\n        <input type="text" class="dateDebR datedeb typeDate periodStart form-control" periodId="1" value="" size="10" />\n      </div>\n    </div>\n\n    <div class="form-group col">\n      <label for="dateFinR" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.periodeFin")))==null?'':__t)+
'</label>\n      <div class="c-date-selector input-group">\n        <div class="input-group-prepend">\n          <div for="dateFinR" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.au")))==null?'':__t)+
'</div>\n        </div>\n        <input type="text" class="dateFinR datefin typeDate periodEnd form-control" periodId="1" value="" size="10" />\n      </div>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
