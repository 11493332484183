import { SelecteurCheminColl } from './selecteurChemin.collection.js';
import { STR } from 'utils/str.js';

/**
 * Selecteur Chemin Model
 */
export var SelecteurCheminModel = Backbone.Model.extend({
  /**
   * Add this to have Outline
   *
   */

  /**
   * Default data model
   *	code : null,
   *  libelle : "",
   *  codef : "",
   *  niveau : null,
   *  datedebut : "",
   *  datefin : "",
   *  debsitu : "",
   *  finsitu : "",
   *  feuille : false,
   *  selectionnable : true,
   *  datespartielles : []
   * }
   */
  defaults: {
    code: null,
    libelle: "",
    codef: "",
    niveau: null,
    datedebut: "",
    datefin: "",
    debsitu: "",
    finsitu: "",
    feuille: false,
    selectionnable: true,
    datespartielles: []
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }

    if (response.enfants) {
      this.set("enfants", new SelecteurCheminColl(response.enfants, { parse: true }));
    }
    delete response.enfants;
    return response;
  }

});
