module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="c-panneauDeroulant groupecompteurs-consultation">\n  \n    <fieldset class="cw-fieldset groupecompteurs-consultation-detail">\n      \n        \n          <div class="form-group groupecompteurs-consultation-groupe">\n            <label for="groupe" class="text-left">'+
((__t=(i18n.t("common:resultatgrpcompteurs.groupe")))==null?'':__t)+
'</label>\n            <span class="cmbGroupe"></span>\n          </div>\n          <div class="form-group">\n            <label for="date" class=" text-left" required>'+
((__t=(i18n.t("common:resultatgrpcompteurs.date")))==null?'':__t)+
'</label>\n            <div class="input-group">\n              <input type="text" class="date typeDate form-control" required value="" size="10" title="'+
((__t=(i18n.t('common:soldesabsences.date_title')))==null?'':__t)+
'" />\n            </div>\n          </div>\n          <div class="form-group">\n            <span class="groupecompteurs-consultation-cmbValeur">\n              <div class="checkbox-zone-demande custom-control custom-checkbox">\n                <input type="checkbox" name="demande" class="demande custom-control-input" value="true" />\n                <label for="demande" class="custom-control-label">'+
((__t=(i18n.t("common:resultatgrpcompteurs.absence")))==null?'':__t)+
'</label>\n              </div>\n            </span>\n          </div>\n          <div class="form-group">\n            <span class="checkSouhait">\n              <div class="checkbox-zone-souhait custom-control custom-checkbox">\n                <input type="checkbox" name="souhait" class="souhait custom-control-input" value="true" />\n                <label for="souhait" class="custom-control-label">'+
((__t=(i18n.t("common:resultatgrpcompteurs.souhait")))==null?'':__t)+
'</label>\n              </div>\n            </span>\n          </div>\n        \n      \n    </fieldset>\n  \n</div>\n';
}
return __p;
};
