import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWScheduleModel } from './cwSchedule.model';
import { CWScheduleRowView } from './cwScheduleRow.view';

export class CWScheduleView<TModel extends CWScheduleModel = CWScheduleModel> extends Backbone.View<CWScheduleModel> {
  /**
   * Add this to have Outline
   *
   */

  public showTitle: boolean;


  /**
   * Constructor
   * PlagesHoraire graphique component view.
   * It's model supports:
   */
  constructor(options?: Backbone.ViewOptions<TModel> | any) {
    options = options || {};
    options.tagName = options.tagName || "div";
    options.className = options.className || "phx-schedule";
    super(options);
    this.model = options;
    if (_.isBoolean(options.showTitle)) {
      this.showTitle = options.showTitle;
    } else {
      this.showTitle = true;
    }
  }

  /**
   * Renders this view
   */
  render(): CWScheduleView {
    const divText = new CWScheduleRowView({ plages: this.model.plages, type: "text" });
    const divPlage = new CWScheduleRowView({ plages: this.model.plages, type: "plage", showTitle: this.showTitle });

    this.$el.html(divText.render().el);
    this.$el.append(divPlage.render().el);
    return this;
  }
}