/**
 * Returns an style metier class to apply to an anomalie, from an anomalie model
 */
export var STYLE = {

  /**
   * Gets the Style Class for the anomalie by code.
   */
  getClassFromAnomalie: function(anocode) {
    var className = "";
    switch (anocode) {
      case "B":
        className = "ui-phx-anomalie-bloquante";
        break;
      case "P":
        className = "ui-phx-anomalie-persistante";
        break;
      case "N":
        className = "ui-phx-anomalie-non-bloquante";
        break;
      case "I":
        className = "ui-phx-anomalie-ignoree";
        break;
      default:
        className = "ui-phx-anomalie-ignoree";
        break;
    }
    return className;
  },

  /**
   * Gets the colour Style Class for the anomalie by code.
   */
  getClassColorFromAnomalie: function(anocode) {
    var className = "";
    switch (anocode) {
      case "B":
        className = "ui-phx-anomalie-bloquante-color";
        break;
      case "P":
        className = "ui-phx-anomalie-persistante-color";
        break;
      case "N":
        className = "ui-phx-anomalie-non-bloquante-color";
        break;
      case "I":
        className = "ui-phx-anomalie-ignoree-color";
        break;
      default:
        className = "ui-phx-anomalie-ignoree-color";
        break;
    }
    return className;
  }
};
