import _ from 'underscore';
import gererBadgeagesDetailRowTPL from '../cwDetailRow.tpl.html';
import { BlockView } from 'common/wkfhisto/views/block.view.js';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWEditedRowView as CommonEditedRowView } from 'core/views/cwEditedRow.view';
import { CWEtiquette, validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { CWGererOneBadgeageProfilsModel } from 'common/evenements/gerer/gereronebadgeage/models/cwGererOneBadgeageProfils.model';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWPanneauDeroulant } from 'src/core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWWkflhistoView } from 'src/uc/common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


export class CWEditedRowView extends CommonEditedRowView {
  /**
   * Event launch for Backbone when a inputs of fieldset change the property value
   *
   */
  /**
   * Event launch for Backbone when a hover your mouse over the class 'txtStatutGererEdit'
   *
   */
  /**
   * Event launch for Backbone when a quit your mouse over the class 'txtStatutGererEdit'
   *
   */


  /**
   * Constructor
   * View of EditedRow to the table of badgeages
   */
  parent: any;
  context: { [key: string]: any };
  workflow: any;
  comboSens: CWComboBoxView2;
  comboMotif: CWComboBoxView2;
  tooltipDemande: any;
  etiquetteHisto1: CWEtiquette;
  histoEtiquettePopup: CWPanneauDeroulant;
  panneauIsOpen: boolean;
  composantWorkflow: CWWkflhistoView;

  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_changeRow", // See jQuery api, it means
      "keyup :input:not([readonly])": "_notifyEditionRow",
      "keyup textarea:not([readonly])": "_notifyEditionRow",
      "mouseenter span.txtStatutGererEdit": "_openTooltipListenerEdit",
      "mouseleave span.txtStatutGererEdit": "_closeTooltipListenerEdit"
    }, options.events, CWTYPE.DATE.events(), CWTYPE.HOUR_MINUTE_NIGHTLY.events());
    options.className = "c-form c-form--fixedButtons";
    super(options);
    this.template = gererBadgeagesDetailRowTPL;
    this.parent = options.parent;
    this.context = options.context;
    this.workflow = options.workflow;
    if (this.canAccess()) {
      this.context.ctxHistoriqueWKF = true;
    }
    this.typeMaskByClass = {};
    this.typeMaskByClass["saisie.date"] = "DATE_A";
    this.typeMaskByClass["saisie.heure"] = "INSTHM";
    this.listenTo(this.workflow, "updateFonction", this._updateFonction);
  }

  /**
   * Renders the view to the form
   */
  render(): CWEditedRowView {
    const json = { "i18n": i18n, "UTILS": UTILS };
    const objNew = this._getModel().isNew();
    const habContext = this.workflow.getHabContext().copy();
    const combId = "motifs?removenothourly=true";
    const renderers: any = {};
    let param: string = null;
    const habCombo = habContext.copy();

    this.$el.html(this.template(json));
    //is New
    if (objNew) {
      habContext.set("natGest", "A");
      this.context.ctxModeInitialisation = "Ajout";
    } else {
      habContext.set("natGest", "M");
      if (CWSTR.isBlank(this.context.ctxModeInitialisation) || this.context.ctxModeInitialisation === "Ajout") {
        this.context.ctxModeInitialisation = "Consultation";
      }
    }
    habCombo.set("natGest", "");
    if (this.context?.ctxHabilitation?.HabilitationGestion) {
      habCombo.update({ foncCour: this.context.ctxHabilitation.HabilitationGestion });
    }
    //Combo sens
    this.comboSens = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "utilbadgeage",
        comboId: "sens"
      }),
      name: "saisie.sens.code",
      optionsRender: (value: any): string => {
        if (!value || CWSTR.isBlank(value.libelle) || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.code + " (" + value.libelle + ")";
      },
      required: true,
      habContext: habCombo
    });
    this.comboSens.setFilter(this._filterComboSens());
    this.$el.find(".cmbSens").html(this.comboSens.render().el);
    // Placeholder for the comment input for creating/updating
    this.$el.find(".commentaire").attr("placeholder", i18n.t('common:gererbadgeages.commentaire'));
    //Combo motifs
    this.comboMotif = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "absence/composant",
        comboId: combId
      }),
      name: "motif.code",
      autocomplete: true,
      required: false,
      habContext: habCombo
    });
    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);
    if (objNew) { //if a new option
      CWFORMS.setDatepicker(this, ".saisie\\.date");
    } else { //if a modification option
      let obj: string = null;
      let objStatut: string = null;

      CWFORMS.setDatepicker(this, ".saisie\\.date");
      if (this.parent._isDiferentBadgeages(this.model.get("value").get("valorise"), this.model.get("value").get("saisie"))) {
        const formatedDate = CWTYPE.DATE.format(this.model.get("value").get("valorise").date, CWTYPE._getFormatByCode("DATE_A"));
        const formatedHeure = CWTYPE.HOUR_MINUTE_NIGHTLY.format(this.model.get("value").get("valorise").heure);

        obj = "<br/>" + formatedDate;
        $(this.el).find(".txtValoriseDate").html(obj);
        //render heure
        obj = "<br/>" + formatedHeure;
        $(this.el).find(".txtValoriseHeure").html(obj);
        //render sensa
        obj = "<br/>" + this.model.get("value").get("valorise").sens.code;
        $(this.el).find(".txtValoriseSens").html(obj);
      }
      objStatut = this._apliqueStatut(this.model.get("value"));
      this.$el.find(".objStatut").html(objStatut);
    }
    renderers["motif.code"] = (): string => {
      if (!CWSTR.isBlank(this.model.get("value").get("motif").code)) {
        return this.model.get("value").get("motif").libelle + " (" + this.model.get("value").get("motif").code + ")";
      } else {
        return null;
      }
    };
    this._convertSensBadgeageVirtual(this.model.get("value"));
    this._mapModelToForm($(this.el), this.model.get("value"), renderers);
    param = !CWSTR.isBlank(GLOBAL_DATA.paramDivers.get("sai_lisf")) ? GLOBAL_DATA.paramDivers.get("sai_lisf").get("valeur") : null;
    if (!CWSTR.isBlank(param) && param === "0") {
      CWFORMS.setFieldReadonly(this.$el.find(".fonction"), true);
    } else {
      CWFORMS.setFieldReadonly(this.$el.find(".fonction"), false);
    }
    this.listenTo(this.workflow.tableModel.coll, "row:edit", this._manageActionsForStatut);
    this.listenTo(this.parent.table.model.btnBarModel, "check:rowOrdering", this._checkButtonDelete);
    this._manageModeInitialisation();
    //augmenter la largeur du volet
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation === "pop-up") { // Pop up
      this.$el.parent().parent().css({ "min-width": "680px" });
    }
    return this;
  }

  _updateFonction(): void {
    if (GLOBAL_DATA.paramDivers.get("sai_lisf") && GLOBAL_DATA.paramDivers.get("sai_lisf").get("valeur") === "0") {
      CWFORMS.setFieldReadonly(this.$el.find(".fonction"), true);
    } else {
      CWFORMS.setFieldReadonly(this.$el.find(".fonction"), false);
    }
  }

  /**
   * Filter the option to load the combo sens
   */
  _filterComboSens(): any {
    const filter = (collection: any): { [key: string]: any } => {
      if (this.context.ctxModeSuppression === "Button") {
        const filterCollection: any = [];

        _.each(collection, (model: any) => {
          if (model.attrs.code !== "X") {
            filterCollection.push(model);
          }
        });

        return filterCollection;
      } else {
        return collection;
      }
    };
    return filter;
  }

  /**
   * Apply the class to the statut type
   */
  _apliqueStatut(badgeage: any): string {
    let ensemble = null, demande = null;
    let style = null;
    let resp = null;

    if (String(badgeage.get("iddemande")) === "0") {
      ensemble = badgeage.get("evenement");
      demande = null;
    } else {
      demande = badgeage.get("iddemande");
      ensemble = null;
    }
    switch (badgeage.get("statut").code) {
      case "D":
      case "T":
        style = "ui-phx-statut-demande";
        break;
      case "I":
        style = "ui-phx-statut-en-cours";
        break;
      case "A":
        style = "ui-phx-statut-accepte";
        break;
      case "R":
        style = "ui-phx-statut-refuse";
        break;
      case "H":
        style = "ui-phx-statut-hors-validation";
        break;
      // no default
    }
    if (badgeage.get("historique")) {
      resp = "<span class='txtStatutGererEdit " + style + "' demande='" + demande + "' ensemble='" + ensemble + "'>" + badgeage.get("statut").libelle + "</span>";
    } else {
      resp = "<span class='" + style + "'>" + badgeage.get("statut").libelle + "</span>";
    }
    return resp;
  }

  /**
   * Create and open tooltip when a hover your mouse over the class 'txtStatutGererEdit'
   */
  _openTooltipListenerEdit(event: any): void {
    let demande = null;
    let reference = null;
    const jquery = event.currentTarget.attributes["aria-describedby"];
    const infobulleWorkflow = new BlockView();
    let contextInfobulleWkf: { [key: string]: any } = null;

    if (jquery) {
      return;
    }
    if (String(event.currentTarget.attributes.demande.value) === "0") {
      reference = event.currentTarget.attributes.ensemble.value;
    } else {
      demande = event.currentTarget.attributes.demande.value;
    }
    this.tooltipDemande = demande || reference;
    contextInfobulleWkf = {
      ctxEcran: this.context.ctxEcran,
      ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: "BADGEAGE",
      ctxRefEvenement: reference,
      ctxDemId: demande,
      ctxModeRestitution: "infobulle",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Replie",
      ctxModeRech: true
    };
    infobulleWorkflow.setContext(contextInfobulleWkf);
    $(event.currentTarget).tooltip({ content: "" });
    $(event.currentTarget).tooltip("option", "content", (): string => {
      return infobulleWorkflow.$el.html();
    });
    $(event.currentTarget).prop("title", "");
    infobulleWorkflow.model.get("histoDem").once("sync", (): void => {
      try {
        const demande = event.currentTarget.attributes.demande.value;

        if (this.tooltipDemande === demande) {
          $(event.currentTarget).tooltip("open");
        }
      } catch (error) {
        // empty
      }
    });
    infobulleWorkflow.model.get("histoEvent").once("sync", (): void => {
      try {
        const reference = event.currentTarget.attributes.ensemble.value;

        if (this.tooltipDemande === reference) {
          $(event.currentTarget).tooltip("open");
        }
      } catch (error) {
        // empty
      }
    });
  }

  /**
   * Close tooltip when a quit your mouse over the class 'txtStatutGererEdit'
   */
  _closeTooltipListenerEdit(): void {
    try {
      this.tooltipDemande = null;
      $(event.currentTarget).tooltip("destroy");

    } catch (error) {
      // empty
    }
  }

  /**
   * Convert the sens Badgeages (e and s) to virtual (E and S)
   * */
  _convertSensBadgeageVirtual(gererBadgeage: any): void {
    if (gererBadgeage.get("saisie").sens.code === "e") {
      gererBadgeage.get("saisie").sens.code = "E";
    }
    if (gererBadgeage.get("saisie").sens.code === "s") {
      gererBadgeage.get("saisie").sens.code = "S";
    }
  }

  _panelStateIsReadonly(): boolean {
    const model = this._getModel();
    let isReadOnly = false;
    const currentStatut = !CWSTR.isBlank(this.context.BadgeageCodeStatutWKF) ? this.context.BadgeageCodeStatutWKF : model.get("statut").code;

    if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1 && this.context.ctxModeInitialisation === "Consultation") {
      isReadOnly = true;
    }
    //When contextutilisateur is 'Responsable' and statut is demande or en cours de validation,
    // fields are disabled.
    if (this.context.ctxUtilisateur === "Responsable" && this.context.ctxGestionCollective === false && (currentStatut === "D" || currentStatut === "T" || currentStatut === "I")) {
      isReadOnly = true;
    }
    //When statut is refuser disable the form
    if (model.get("etat") === "R") {
      isReadOnly = true;
    }
    if (isReadOnly === false && model && model.getHabContext && model.getHabContext()) {
      const canUpdate = CWHABILITATION.canUpdate(model.getHabContext().get("foncCour"));

      isReadOnly = !canUpdate && !model.isNew();
    }
    if (this._getModel().get("valorise") && this._getModel().get("valorise").sens && (this._getModel().get("valorise").sens === "X" || this._getModel().get("valorise").sens.code === "X")) {
      isReadOnly = true;
    }
    return isReadOnly;
  }

  /**
  //  * Function to know if you can edit or cannot delete the line
  //  * */
  canRemove(model: any): boolean {
    if (model.get("value") && model.get("value").get("valorise") && model.get("value").get("valorise").sens && (model.get("value").get("valorise").sens === "X" || model.get("value").get("valorise").sens.code === "X")) {
      return false;
    }
    if (this.parent && !this.parent._actionsForStatut("suppression", model.get("value").get("statut").code)) {
      return false;
    }
    if (this.parent && this.parent._lineStateIsReadonly()) {
      return false;
    }
    return true;
  }

  _manageActionsForStatut(): boolean {
    let isReadOnly = false;

    if (!this._getModel().isNew()) {
      const statut = (this._getModel().get("statut") && this._getModel().get("statut").code) ? this._getModel().get("statut") && this._getModel().get("statut").code : null;

      this.parent.table.model.btnBarModel.trigger("show:new"); // quelque soit le statut => on affiche le bouton créer (sfd Composant badgeage v2.0 paragraphe 2.2.2)
      if (!this.parent._actionsForStatut("modification", statut)) {
        isReadOnly = true;
        this.parent.table.model.btnBarModel.trigger("hide:save");
        this.parent.table.model.btnBarModel.trigger("hide:revert");
        CWFORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), true, true);
        this.$el.find("textarea.commentaire").prop("placeholder", "");//enlever l'information de "placeholder" en mode "readonly"
      } else {
        CWFORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), false, false);
      }
      if (!this.parent._actionsForStatut("suppression", statut)) {
        this.parent.table.model.btnBarModel.trigger("hide:delete");
      } else {
        this.parent.table.model.btnBarModel.trigger("show:delete");
      }
      if (!this.parent._actionsForStatut("validation", statut)) {
        this.parent.table.model.btnBarModel.trigger("hide:valider");
      } else {
        this.parent.table.model.btnBarModel.trigger("show:valider");
      }
    }
    return isReadOnly;
  }

  _checkButtonDelete(): void {
    const $lPos = this.$el.find(".cwDataGrid__actionCell.cw-volet__buttons.phx-td0 .btn-delete-container .delete");

    if ($lPos && $lPos.length) {
      const currentClass = $lPos.removeClass("delete").prop("class");

      $lPos.prop("class", "phx-cell-delete-action " + currentClass);
    }
  }

  _manageTitle(): void {
    const context = this.context;
    let mode: string = "";
    const $div = $('<div>');
    const $title = $('<div>');
    const $span = $('<span>');

    if (context.ctxModeRepresentation === "pop-up") { //is a popUp
      mode = context.ctxModeInitialisation === "Ajout" ? i18n.t('common:gereronebadgeage.title_popup_creation') : i18n.t('common:gereronebadgeage.title_popup');
    }
    $span.html(mode.toUpperCase());
    $title.addClass('gereronebadgeage-titlePopup');
    if (this.context.ctxModeInitialisation === "Ajout") {
      $span.append($('<span>').addClass('gereronebadgeage-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereronebadgeage.creation_en_cours'))));
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      if (this.parent.table.buttonBar.model.get("mode") === 'E') {
        $span.append($('<span>').addClass('gereronebadgeage-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereronebadgeage.modification_en_cours'))));
      } else {
        $span.append($('<span>').addClass('gereronebadgeage-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(""));
      }
    }
    $title.append($span);
    $title.append($('<span>').addClass('c-titleHistoWkf'));
    $div.append($title);
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.dataGrid.editionView.isTitleHTML = true;
      this.dataGrid.editionView._setTitle($div.html());
    }
    this._paintWorkflowStatus();
  }

  _paintWorkflowStatus(): void {
    const $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');

    if (this.context.ctxModeInitialisation === "Consultation") {//if is consultation mode
      const badgeage = this.model.get("value");
      const text = badgeage.get("statut").code === "H" ? "" : badgeage.get("statut").libelle;
      const subtextEtiquette = badgeage.get("etat") === "X" ? i18n.t('common:etiquette_subText.suppression') : "";
      const codeStatut = this.checkSCodetatut(badgeage.get("statut").code);
      const onlyIcon = this.context.ctxHistoriqueWKF && badgeage.get("iddemande") >= 0 && badgeage.get("statut").code === "H";


      $layout.show();
      this.etiquetteHisto1 = new CWEtiquette({
        id: "etiquetteWorkHisto",
        text: {
          enabled: text
        },
        subText: {
          enabled: subtextEtiquette
        },
        type: codeStatut,
        state: "actif",
        onlyIcon: onlyIcon
      });

      if (this.context.ctxHistoriqueWKF && this.workflow.wkf_activ && badgeage.get("iddemande") >= 0) {
        this.etiquetteHisto1.icon = 'horloge_fleche';
        this.listenTo(this.etiquetteHisto1.model, "click", this.histoEtiquetteClick);
      } else if (onlyIcon === true || text === "") {
        this.etiquetteHisto1.setNoBackground();
      }

      $layout.html(this.etiquetteHisto1.render().el);
    } else {
      if (!CWSTR.isBlank($layout)) {
        $layout.hide();
      }
    }
  }

  private histoEtiquetteClick(): void {
    if (!CWSTR.isBlank(this.histoEtiquettePopup) && this.panneauIsOpen === true) {
      this.histoEtiquettePopup.close();
      this.panneauIsOpen = false;
    } else {
      this.panneauIsOpen = true;
      if (CWSTR.isBlank(this.histoEtiquettePopup)) {
        this.histoEtiquettePopup = new CWPanneauDeroulant({
          fixedButtons: true,
          maxHeight: 360,
          view: this.composantWorkflow,
          viewData: {
            appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
            modal: false
          },
          notIconClose: true,
          closeOnClickOutside: true
        });
      } else {
        this.histoEtiquettePopup.viewData = {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          modal: false
        };
        this.histoEtiquettePopup.view = this.composantWorkflow;
      }
      this.histoEtiquettePopup.setPosition({
        my: "right top",
        at: "right bottom",
        of: $(event.currentTarget)
      });
      this.histoEtiquettePopup.setHeight("auto");
      this.histoEtiquettePopup.setWidth(500);
      this.histoEtiquettePopup.model.trigger("dlg:open");
      this.listenTo(this.histoEtiquettePopup.model, "dlg:close", (): void => {
        this.panneauIsOpen = false;
      });
      this.histoEtiquettePopup._setTitle(i18n.t('common:listerevtgen.historique').toUpperCase());
    }
    event.stopPropagation();
  }

  private checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes = null;

    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
        status = 'demande';
        break;
    }
    return status;
  }

  _manageWorkflowContect(): void {
    let auxNatGest: string = null;

    if (this.context.ctxModeInitialisation === "Ajout") {
      auxNatGest = "A";
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      auxNatGest = "M";
    } else {
      auxNatGest = "";
    }
    this.workflow.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: auxNatGest
    }));
  }

  _manageModeInitialisation(callback?: () => void): void {
    this._manageWorkflowContect();
    if (this.context.ctxModeInitialisation === "Ajout") {
      this.parent.table.model.btnBarModel.set("mode", "C");
      this._manageTitle();
      //New attribute for know if we are saving or deleting a new badgeage
      this.model.get("value").isCreation = true;
      if (callback) {
        callback();
      }
      this.model.get("value").store();
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      if (!CWSTR.isBlank(this.context.ctxGestionCollab)) {// && !CWSTR.isBlank(this.context.ctxIdentifiantEvt)) {
        const model = this._getModel();

        model.setHabContext(this.model.getHabContext());
        this._manageTitle();
        if (this.context.ctxModeInitialisation !== "Ajout" && !CWSTR.isBlank(model.get("statut")) && !CWSTR.isBlank(model.get("statut").code)) { //Statut
          this._paintWorkflowStatus();
        }
        if (model.get("historique")) {
          // retrieve workflow information
          if (this.context.ctxHistoriqueWKF === true) {
            const contextComponentWkf = {
              ctxEcran: this.context.ctxEcran,
              ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
              ctxTypeEvenement: "BADGEAGE",
              ctxRefEvenement: model.get("evenement"),
              ctxDemId: model.get("iddemande"),
              ctxModeRestitution: "-",
              ctxActivModeSynth: true,
              ctxModeDefaut: "Replie",
              ctxModeRech: true
            };

            this.composantWorkflow = new CWWkflhistoView();
            this.composantWorkflow.render();
            this.composantWorkflow.setContext(contextComponentWkf);
            this.composantWorkflow.hideIcon();
          }
        }
        this.parent.table.buttonBar._manageMode()
        this.parent.table.model.btnBarModel.trigger("hide:copy")
        if (this.$el.find("button.valider").is(":visible") === true) {
          const profilModel = new CWGererOneBadgeageProfilsModel();

          this.parent.table.model.btnBarModel.trigger("hide:valider");
          profilModel.profilId = model.get("matricule") + "," + model.get("code");
          profilModel.setHabContext(new CWHabilitationContext({
            onglet: this.context.ctxEcran,
            foncCour: this.context.ctxHabilitation.HabilitationValidation
          }));
          profilModel.fetch({
            success: () => {
              if (profilModel.get("profils") && profilModel.get("profils").length > 0) {
                this.model.get("value").set("profils", profilModel.get("profils"));
                this.parent.table.buttonBar.configureValidationsButtonFromModel(this.model.get("value"));
                this.parent.table.model.btnBarModel.trigger("show:valider");
              }
              if (callback) {
                callback();
              }
            }
          });
        } else {
          if (callback) {
            callback();
          }
        }
      } else {
        if (callback) {
          callback();
        }
        this.model.get("value").store();
      }
    } else if (this.context.ctxModeInitialisation === "InitForAction") {
      if (callback) {
        callback();
      }
    }
    if (this.context.ctxModeSuppression === "SensX") {
      this.parent.table.model.btnBarModel.trigger("hide:delete");
    }
  }

  _configureValidationButton(): void {
    const model = this.model.get("value");
    const profils = model.get("profils");
    const ul = $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu");

    ul.empty();
    if (profils && profils.length === 1 && profils[0] && ul) {
      const accepter = $("<li>");
      const refuser = $("<li>");
      let a = $("<a class='menuItem' data-value='2' >" + i18n.t('common:gereronebadgeage.btnAccepter') + "</a>");

      a.attr("data-code", profils[0].code);
      accepter.append(a);
      ul.append(accepter);
      a = $("<a class='menuItem' data-value='3' >" + i18n.t('common:gereronebadgeage.btnRefuser') + "</a>");
      a.attr("data-code", profils[0].code);
      refuser.append(a);
      ul.append(refuser);
    }
    if (profils && profils.length > 1 && ul) {
      const accepter = $("<li>");
      const refuser = $("<li>");

      accepter.append("<span class='ui-corner-all'>" + i18n.t('common:gereronebadgeage.btnAccepter') + "</span>");
      ul.append(accepter);
      _.each(profils, (obj: { [key: string]: any }) => {
        const item = $("<li>");
        const a = $("<a class='menuItem' data-value='2'>- " + obj.libelle + "</a>");

        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      });
      refuser.append("<span class='ui-corner-all'>" + i18n.t('common:gereronebadgeage.btnRefuser') + "</span>");
      ul.append(refuser);
      _.each(profils, (obj: { [key: string]: any }) => {
        const item = $("<li>");
        const a = $("<a class='menuItem' data-value='3'>- " + obj.libelle + "</a>");

        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      });
    }
    $(".gereronebadgeage-detail", this.el).find("button.valider").button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true }).click((): boolean => {
      const menu = $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu").show().position({
        my: "left top",
        at: "left bottom",
        of: this
      });

      $(document).one("click", (): void => {
        menu.hide();
      });
      return false;
    });

    if (!profils || profils.length === 0) {
      this.parent.table.model.btnBarModel.trigger("hide:valider");
    }
    $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu").hide().menu();
  }

  canAccess(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationAcces && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces);
  }

  _changeRow(event: any): void {
    this._change(event);
    this._manageTitle();
  }

  _notifyEditionRow(event: any): void {
    this._notifyEdition(event);
    this._manageTitle();
  }
}
