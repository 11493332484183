import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererrecapitulatifsDetailMonoType from '../cwDetailMonoType.tpl.html';
import { CWBaseView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwBase.view';
import { CWButtonBarView } from 'src/core/controls/cwButtonBar.view';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWSTR } from 'src/utils/cwStr';
import { CWTableColl } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwTable.collection';
import { CWTreeTypeGridModel } from 'src/core/grids/treetypegrid/cwTreeTypeGrid.model';
import { CWTreeTypeGridView } from 'src/core/grids/treetypegrid/cwTreeTypeGrid.view';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';

export class CWMonoTypeView extends CWBaseView {
  btnBarP: CWButtonBarView;
  tableColl: CWTableColl;
  template: any;
  typeEvenements: string;
  workflow: any;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "click input.supprimerPHeaderMono": "_manageHeaderCheckbox",
      "click .supprimerPMonth": "_manageMonthCheckbox",
      "click .supprimerPWeek": "_manageWeekCheckbox",
      "click .supprimerPDay": "_manageDayCheckbox",
      "click .supprimerPLine": "_manageLineCheckbox",
      "click .piece-jointe": "_managePieceJointe",
      "mouseenter .monthData": "_hoverBtnTitleMono",
      "mouseleave .monthData": "_hoverBtnTitleMono",
      "mouseenter .weekData": "_hoverBtnTitleMono",
      "mouseleave .weekData": "_hoverBtnTitleMono",
      "mouseenter .dayData": "_hoverBtnTitleMono",
      "mouseleave .dayData": "_hoverBtnTitleMono"
    });
    super(options);
    this.template = TPLGererrecapitulatifsDetailMonoType;

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.btnBarP = new CWButtonBarView({ id: "supprimerP_mono_btnbar", isHideOnDisabled: false });
    this.tableColl = new CWTableColl();
    this.table = this._initTable();
    this.table.model.coll.nbevPSuppr = 0;
    this.listenTo(this.btnBarP.model, "btn:click", (buttonId, code) => {
      this.workflow._supprimerMonoTypeAction(buttonId, code);
    });
    this.typeEvenements = "";
  }

  render(): CWMonoTypeView {
    const json = { "i18n": i18n };
    this.$el.append(this.template(json));

    this.$el.find(".tableMonoType").html(this.table.el);
    this.table.render();
    this._renderHeaderButton();

    return this;
  }

  _renderHeaderButton(): void {
    this.btnBarP.render().$el.appendTo(this.$el.find(".supprimerButtons").first());
    this.btnBarP.model.trigger("hide:save");
    this.btnBarP.model.trigger("hide:revert");
    this.btnBarP.model.trigger("hide:new");
    this.btnBarP.model.trigger("hide:copy");
  }

  _checkButtonsSupprimerEv(): void {
    const tableColl = this.table.model.coll;
    this.$el.find("#supprimerP_mono_btnbar").css("padding", "0px").css("margin-left", "auto").css("margin-right", "auto");

    const hab = this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR ? "COL_ACT_PREV.G" : "RES_ACT_PREV.G";
    if (CWHABILITATION.canDelete(hab)) {
      if (tableColl.nbevPSuppr > 0) {
        this.btnBarP.model.trigger("enable:delete");
      } else {
        this.btnBarP.model.trigger("disable:delete");
      }
    } else {
      this.btnBarP.model.trigger("disable:delete");
    }
  }

  _checkHeadersCheckboxes(): void {
    const prevueColl = this.workflow._getAnyPrevueEvenement(true);
    const evCanBeSupressed = _.find(prevueColl.models, (model: any) => { return !CWSTR.isBlank(model.evPSuppr); });
    if (CWSTR.isBlank(evCanBeSupressed)) {
      this.$el.find(".supprimerPHeaderMono").hide();
    } else {
      this.$el.find(".supprimerPHeaderMono").show();
      this.$el.find(".supprimerPHeaderMono").prop("checked", false);
    }
  }

  _hoverBtnTitleMono(event: JQuery.TriggeredEvent): void {
    const typeevenement = this.workflow.modeRepresentationModel.get("typeevenement");
    this._hoverBtnTitle(event, typeevenement, this.workflow.MONO);
  }

  findAndSelectFirstEvenement(): void {
    if (CWSTR.isBlank(this.table.model.get("value"))) { //If no element is selected, select first evenement row
      const firstEv = _.find(this.table.model.coll.models, (model: any) => {
        if (!CWSTR.isBlank(model.get("prevueColumn")) && (model.get("prevueColumn").type === this.workflow.ABSENCE ||
          model.get("prevueColumn").type === this.workflow.ACTIVITE)) {
          return true;
        } else if (!CWSTR.isBlank(model.get("realiseeColumn")) && (model.get("realiseeColumn").type === this.workflow.ABSENCE ||
          model.get("realiseeColumn").type === this.workflow.ACTIVITE)) {
          return true;
        }
        return false;
      });
      if (!CWSTR.isBlank(firstEv)) {
        firstEv.trigger("row:click", firstEv);
      }
    } else {
      const value = this.table.model.get("value");
      const modelInColl = this.table.model.coll.get(value);
      if (!CWSTR.isBlank(modelInColl)) {
        modelInColl.trigger("row:click", modelInColl);
      }
    }
  }

  /**
   * This function manages the change of the vue for left table, in order to add a synthetique param
   * and make sorting work
   */
  _vueChanged(): void {
    this._checkButtonsSupprimerEv();
    //Unselect currentRow if a row is selected
    if (!CWSTR.isBlank(this.table.model.get("value"))) {
      this.table.model.get("value").trigger("row:unselect", this.table.model.get("value"));
    }
    this.table.model.set("value", null);
    this.findAndSelectFirstEvenement();
  }

  /**
   * Defines the treeTable and the function used to load the subtree
   */
  _initTable(): CWTreeTypeGridView {
    const tableModel = new CWTreeTypeGridModel({
      coll: this.tableColl
    });
    const columnSupprimerPTitle = this._inputBuilder("supprimerPHeaderMono");
    const tblColumnsHM = [
      { title: i18n.t('common:gererrecapitulatifs.grid_evenement'), property: "evenement", width: 6, treecol: true },
      { title: i18n.t('common:gererrecapitulatifs.grid_statut'), property: "statut", width: 2 },
      { title: i18n.t('common:gererrecapitulatifs.grid_duree_hm'), property: "duree", width: 2 },
      { menuTitle: "", title: columnSupprimerPTitle, property: "supprimerP", width: 2 }
    ];
    const tblColumnsHC = [
      { title: i18n.t('common:gererrecapitulatifs.grid_evenement'), property: "evenement", width: 6, treecol: true },
      { title: i18n.t('common:gererrecapitulatifs.grid_statut'), property: "statut", width: 2 },
      { title: i18n.t('common:gererrecapitulatifs.grid_duree_hc'), property: "duree", width: 2 },
      { menuTitle: "", title: columnSupprimerPTitle, property: "supprimerP", width: 2 }
    ];

    const vues: { [key: string]: any } = {};
    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')] = { columns: tblColumnsHM };
    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')] = { columns: tblColumnsHC };
    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')].menuName = i18n.t('common:gererrecapitulatifs.vue_pd_HM_menu_name');
    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')].menuName = i18n.t('common:gererrecapitulatifs.vue_pd_HC_menu_name');
    const table = new CWTreeTypeGridView({
      vues: vues,
      model: tableModel,
      defaultVue: i18n.t('common:gererrecapitulatifs.vue_pd_HM'),
      title: "", //i18n.t('common:gererrecapitulatifs.grid_title_mono'),
      showFilteredRowsInTitle: false,
      gridHeightType: "auto"
    });

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')].cellRenderers["evenement"] = (model: any): any => {
      const span = this._generateEvenement(model, i18n.t('common:gererrecapitulatifs.vue_pd_HM'));
      return span;
    };

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')].cellRenderers["evenement"] = (model: any): any => {
      const span = this._generateEvenement(model, i18n.t('common:gererrecapitulatifs.vue_pd_HC'));
      return span;
    };

    table.cellRenderers["statut"] = (model: { [key: string]: any }): any => {
      let resp = "";
      if (model.level === 4) { //Evenement
        if (model.type === this.workflow.ACTIVITE) { //ACTIVITES
          const manageActivite = this.workflow._checkActiviteGereParRecap(model.get("prevueColumn"));
          if (manageActivite === false) {
            resp = this.workflow._buildActiviteSpanStatus(model.get("prevueColumn"));
          }
        } else if (model.type === this.workflow.ABSENCE) { //ABSENCES
          const manageAbsence = this.workflow._checkActiviteGereParRecap(model.get("prevueColumn"), true);
          if (manageAbsence === false) {
            resp = this.workflow._buildAbsenceSpanStatus(model.get("prevueColumn"));
          }
        }
      }
      return resp;
    };

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')].cellRenderers["duree"] = (model: { [key: string]: any }): string => {
      let resp = "";
      if (model.level === 3 && !CWSTR.isBlank(model.summeDuresAct)) { //DAY
        if (model.summeDuresAct !== 0) {
          resp = '<div style="width:100%;display:inline-block;margin:auto;text-align:center;"><b>( ' + CWTYPE.DURATION.HOUR_MINUTE.format(model.summeDuresAct) + ' )</b></div>';
        }
      }
      return resp;
    };

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')].cellRenderers["duree"] = (model: { [key: string]: any }): string => {
      let resp = "";
      if (model.level === 3 && !CWSTR.isBlank(model.summeDuresAct)) { //DAY
        if (model.summeDuresAct !== 0) {
          resp = '<div style="width:100%;display:inline-block;margin:auto;text-align:center;"><b>( ' + CWTYPE.DURATION.HOUR_HUNDREDTH.format(this.workflow._toHourHundreth(model.summeDuresAct)) + ' )</b></div>';
        }
      }
      return resp;
    };

    table.cellRenderers["supprimerP"] = (model: { [key: string]: any }): string => {
      let year = "",
        month = "",
        evCanBeSupressed = "",
        dateBBDD = "",
        week = "";
      if (this.workflow._recapEtatCanBeSupressed("P") === true) {
        const checkedAttribute = model.evPSuppr === true ? "checked" : "";
        if (model.level === 1) { //MONTH
          year = model.id.split(",")[0];
          month = model.id.split(",")[1];
          const prevueMonthColl = this.workflow._getPrevueMonthEvenements(true, year, month);
          evCanBeSupressed = _.find(prevueMonthColl.models, (model: any) => { return !CWSTR.isBlank(model.evPSuppr); });
          if (prevueMonthColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id, "MONTH", year, month, "supprimerPMonth", checkedAttribute);
            // return '<input data-type="MONTH" data-year="' + year + '" data-month="' + month + '" class="supprimerPMonth ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
        } else if (model.level === 2) { //WEEK
          year = model.id.split(",")[0];
          month = model.id.split(",")[1];
          const weekArray = model.id.split(",");
          week = weekArray[2];
          const prevueWeekColl = this.workflow._getPrevueWeekEvenements(true, year, week);
          evCanBeSupressed = _.find(prevueWeekColl.models, (model: any) => { return !CWSTR.isBlank(model.evPSuppr); });
          if (prevueWeekColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id, "WEEK", year, month, week, "supprimerPWeek", checkedAttribute);
            // return '<input data-type="WEEK" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" class="supprimerPWeek ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
          //if is delegue view in style delegation
        } else if (model.level === 3) { //DAY
          year = model.id.split(",")[0];
          month = model.id.split(",")[1];
          week = model.id.split(",")[2];
          dateBBDD = model.id.split(",")[3];

          const prevueDayColl = this.workflow._getDayPrevueEvenements(true, dateBBDD);
          evCanBeSupressed = _.find(prevueDayColl.models, (model: any) => { return !CWSTR.isBlank(model.evPSuppr); });
          if (prevueDayColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id + ",P", "DAY", year, month, week, dateBBDD, "supprimerPDay", checkedAttribute);
            // return '<input data-type="DAY" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" data-date="' + dateBBDD + '" class="supprimerPDay ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
        } else if (model.level === 4) {
          const prevueModel = model.get("prevueColumn");
          if (!CWSTR.isBlank(model.get("prevueColumn")) && !CWSTR.isBlank(model.evPSuppr) && model.get("prevueColumn").type === this.workflow.ACTIVITE) {
            let code = prevueModel.get("code");

            year = prevueModel.year;
            month = prevueModel.month;
            week = prevueModel.week;
            dateBBDD = prevueModel.currentDate;
            if (code.indexOf("/") !== -1) {
              code = prevueModel.get("code").replace('/', '_');
            }
            return this._inputBuilder2(code, "EVENEMENT", year, month, week, dateBBDD, "supprimerPLine", checkedAttribute);
          }
        }
      }
      return '';
    };

    table.setSortableColumns([], i18n.t('common:gererrecapitulatifs.vue_pd_HC'));
    table.setSortableColumns([], i18n.t('common:gererrecapitulatifs.vue_pd_HM'));

    tableModel.off("vue:change");
    tableModel.on("vue:change", this._vueChanged, this);

    return table;
  }

  _generateEvenement(model: { [key: string]: any }, vueName: string): any {
    let htmltoReturn = $("<span></span>");
    let dateBBDD = null;
    let journee = null;

    if (model.level === 1) { //MONTH
      htmltoReturn = this._generateMonthHtml(model);
    } else if (model.level === 2) { //WEEK
      htmltoReturn = this._generateWeekHtml(model);

      //if is delegue view in style delegation
    } else if (model.level === 3) { //DAY
      dateBBDD = model.id.split(",")[3];
      journee = this.workflow._getJournee(dateBBDD);
      if (!CWSTR.isBlank(journee) && journee.get("repos") === true) {
        htmltoReturn.addClass("ui-phx-presence-repos").html(this._generateDayHtml(model));
      } else {
        htmltoReturn.html(this._generateDayHtml(model));
      }
    } else if (model.level === 4) {
      let text = "";
      const habRecap = this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";
      if (!CWHABILITATION.canUpdate(habRecap)) {
        htmltoReturn.css("text-decoration", "none");
      }

      if (model.type === this.workflow.ACTIVITE) { //ACTIVITE
        text = this.workflow._buildActiviteLine(model.get("prevueColumn"), vueName);
      } else if (model.type === this.workflow.ABSENCE) { //ABSENCE
        text = this.workflow._buildAbsenceLine(model.get("prevueColumn"), vueName);
      } else {
        dateBBDD = model.currentDate;
        journee = this.workflow._getJournee(dateBBDD);
        if (!CWSTR.isBlank(journee) && journee.get("repos") !== true) {
          text = i18n.t('common:gererrecapitulatifs.aucun_evenement');
        }
      }
      htmltoReturn.html('<span class="ui-phx-ihm-texte-application">' + text + '</span>');
    }
    return htmltoReturn;
  }

  _generateMonthHtml(model: { [key: string]: any }): any {
    const firstMonthDay = new Date(model.monthInfoArray[1], model.monthInfoArray[0], 1); //function used to obtain the first month day
    const lastMonthDay = new Date(model.monthInfoArray[1], model.monthInfoArray[0] + 1, 0); //function used to obtain the first month day
    const firstMonthDayBBDD = CWTYPE.DATE.dateToStr(firstMonthDay, "YYYYMMDD");
    const lastMonthDayBBDD = CWTYPE.DATE.dateToStr(lastMonthDay, "YYYYMMDD");

    const htmltoReturn = $('<div data-startDate="' + firstMonthDayBBDD + '" data-endDate="' + lastMonthDayBBDD + '"></div>');
    htmltoReturn.addClass("monthData");
    htmltoReturn.css("width", "auto");
    htmltoReturn.css("display", "inline-block");
    htmltoReturn.html(i18n.t('common:gererrecapitulatifs.month_' + model.monthInfoArray[0]) + "&nbsp;" + model.monthInfoArray[1]);

    return htmltoReturn;
  }

  _generateWeekHtml(model: { [key: string]: any }): any {
    const firstWeekDay = model.weekInfoArray[1];
    const lastWeekDay = model.weekInfoArray[2];
    const htmltoReturn = $('<div data-startDate="' + firstWeekDay + '" data-endDate="' + lastWeekDay + '"></div>');
    htmltoReturn.addClass("weekData");
    htmltoReturn.css("width", "auto");
    htmltoReturn.css("display", "inline-block");
    htmltoReturn.html(i18n.t('common:gererrecapitulatifs.week') + "&nbsp;" + model.weekInfoArray[0]);
    return htmltoReturn;
  }

  _generateDayHtml(model: { [key: string]: any }): any {
    const dateBBDD = model.dayInfoArray[2]; //YYYYMMDD
    const htmltoReturn = $('<div data-startDate="' + dateBBDD + '" data-endDate="' + dateBBDD + '"></div>');
    htmltoReturn.addClass("dayData");
    htmltoReturn.css("width", "auto");
    htmltoReturn.css("display", "inline-block");
    htmltoReturn.html("<b>" + i18n.t('common:gererrecapitulatifs.day_' + model.dayInfoArray[0]) + "&nbsp;" + model.dayInfoArray[1] + "</b>");

    return htmltoReturn;
  }

  _inputBuilder(id: string): string {
    let ckb = "";

    ckb = '<div class="' + id + ' form-group">';
    ckb += '<div class="custom-control custom-checkbox">';
    ckb += '  <input id="' + id + '" type="checkbox" class="' + id + ' custom-control-input" value="a" />';
    ckb += '  <label for="' + id + '" class="custom-control-label"></label>';
    ckb += '</div>';
    ckb += '</div>';
    return ckb;
  }

  _inputBuilder2(id: string, type: string, ...val: any[]): string {
    let ckb = "";

    ckb = '<div class="form-group">';
    ckb += '<div class="custom-control custom-checkbox">';
    switch (type) {
      case "MONTH":
        ckb += '<input id="' + id + '" data-type="MONTH" data-year="' + val[0] + '" data-month="' + val[1] + '" class="' + val[2] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[3] + ' />';
        break;
      case "WEEK":
        ckb += '<input id="' + id + '" data-type="WEEK" data-year="' + val[0] + '" data-month="' + val[1] + '" data-week="' + val[2] + '" class="' + val[3] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[4] + ' />';
        break;
      case "DAY":
        ckb += '<input id="' + id + '" data-type="DAY" data-year="' + val[0] + '" data-month="' + val[1] + '" data-week="' + val[2] + '" data-date="' + val[3] + '" class="' + val[4] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[5] + ' />';
        break;
      case "EVENEMENT":
        ckb += '<input id="' + id + '" data-type="EVENEMENT" data-year="' + val[0] + '" data-month="' + val[1] + '" data-week="' + val[2] + '" data-date="' + val[3] + '" data-id="' + id + '" class="' + val[4] + ' ' + val[0] + ',' + val[1] + ',' + val[2] + ',' + val[3] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[5] + ' />';
        break;
    }
    ckb += '  <label for="' + id + '" class="custom-control-label"></label>';
    ckb += '</div>';
    ckb += '</div>';
    return ckb;
  }
}
