module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<br>\n<div class="persobriques-container" style="min-width : 470px;">\n  <div class="persobriques-intitle ui-phx-ihm-texte-application-important cw-titre"></div>\n  <span class="persobriques-title ui-phx-ihm-texte-application-important cw-texteTresAllege">'+
((__t=(i18n.t('common:persobriques.briques_dispo')))==null?'':__t)+
'</span>\n  <fieldset class="cw-fieldset c-form__scrollZone persobriques-content"></fieldset>\n  <div class="persobriques-info cw-texteTresAllege">'+
((__t=(i18n.t('common:persobriques.info_trier')))==null?'':__t)+
'</div>  \n</div>\n';
}
return __p;
};
