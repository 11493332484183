import _ from 'underscore';
import sjcl from 'sjcl';
import TPLCommonDetailMotPasse from './cwDetailMotPasse.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWFORMS } from 'utils/cwForms';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { LoginPasswordModel } from './loginPassword.model.js';
import { UTILS } from 'utils/utils.js';


export class CWLoginChangePwdDialogView extends CWBaseFormView<CWBaseModel> {

  template: any;
  premiere: any;
  message: any;
  parent: any;
  parentView: any;
  loginPasswordModel: typeof LoginPasswordModel;
  loginView: any;
  saved: boolean;
  operation: any;


  constructor(options: any) {
    options = options || {};
    options.events = _.extend({
      "click .motpassecontinue": function () {
        this._continue(this);
      },
      "click .motpassecancel": function () {
        this._close(this);
      },
      "click #wp-togglePassword_old": "_togglePassword",
      "click #wp-togglePasswordBarre_old": "_togglePassword",
      "click #wp-togglePassword_new": "_togglePassword",
      "click #wp-togglePasswordBarre_new": "_togglePassword",
      "click #wp-togglePassword_confirm": "_togglePassword",
      "click #wp-togglePasswordBarre_confirm": "_togglePassword"
    })
    super(options);
    this.template = TPLCommonDetailMotPasse;
    if (options && !CWSTR.isBlank(options.premiere)) {
      this.premiere = options.premiere;
    }
    if (options && !CWSTR.isBlank(options.message)) {
      this.message = options.message;
    }
    if (options && options.parent) {
      this.parent = options.parent;
    }
    if (options && options.parentView) {
      this.parentView = options.parentView;
    }
    if (options && options.loginView) {
      this.loginView = options.loginView;
    }
    if (options && options.operation) {
      this.operation = options.operation;
    }
    this.saved = false;
    this.loginPasswordModel = new LoginPasswordModel();
    this.loginPasswordModel.setHabContext(new CWHabilitationContext({
      onglet: "password",
      foncCour: "N",
      natGest: ""
    }));
    this.loginPasswordModel.premiere = this.premiere;
  }

  _showValidationErrors(model: any, errors: any): void {
    CWLOG.debug("ERROR saving..." + errors);
    if (errors && _.has(errors, "errorValidation")) {
      if (_.has(errors, "errors")) {
        const fieldErrors = errors.errors;
        CWFORMS.showErrors($(this.el), fieldErrors);
      }
    }
  }

  _cleanValidationErrors(): void {
    CWFORMS.cleanErrors($(this.el));
  }

  _continue(view: any): void {
    let errors = null;

    view._cleanValidationErrors();
    view.loginPasswordModel.id = "password";
    view.loginPasswordModel.set("password",
      sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits($("#password").val() as string)), {
      silent: true
    });
    view.loginPasswordModel.set("pwd_new",
      sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits($("#pwd_new").val() as string)), {
      silent: true
    });
    view.loginPasswordModel.set("pwd_confirm",
      sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits($("#pwd_confirm").val() as string)), {
      silent: true
    });
    errors = view.loginPasswordModel.validate(view.loginPasswordModel.attributes);
    if (!_.isNull(errors)) {
      view._showValidationErrors(view.loginPasswordModel, errors);
    } else {
      view.loginPasswordModel.save(null, {
        success: () => {
          const loginData: { user: string; pass: string } = { user: null, pass: null };

          this.saved = true;
          if (this.loginView && this.operation === "badgeage") {
            loginData.user = this.loginView.$el.find("#user_login").val();
            loginData.pass = $("#pwd_new").val() as string;
          }
          view.parent.close();
          if (this.parentView) {
            this.parentView._logon(null, view.loginPasswordModel.get("pwd_new"));
          }
          if (this.loginView && this.operation === "badgeage") {
            this.loginView._badger(loginData);
          }
        }
      });
    }
  }

  _close(view: any): void {
    view.parent.close();
  }

  render(): CWLoginChangePwdDialogView {
    const json = { "i18n": i18n, "UTILS": UTILS };
    const fieldset = this.$el;

    this.$el.html(this.template(json));
    this.$el.find(".ctxGestionCollab").hide();
    if (this.premiere === true) {
      this.$el.find(".old_password").hide();
    } else {
      this.$el.find(".old_password").show();
    }

    this._cleanValidationErrors();
    this._mapModelToForm(fieldset, this.model);//RGAA - needed to show "(*) : champ obligatoire"
    return this;
  }

  _togglePassword(event: JQuery.TriggeredEvent): void {
    const $posMotDePasseOld = this.$el.find(".old_password .password");
    const $posIconeOld = this.$el.find(".old_password .iconMotdepasseOld");
    const $posIconeBarreOld = this.$el.find(".old_password .iconMotdepasseBarreOld");
    const $posMotDePasseNew = this.$el.find(".motdepasse_new .pwd_new");
    const $posIconeNew = this.$el.find(".motdepasse_new .iconMotdepasseNew");
    const $posIconeBarreNew = this.$el.find(".motdepasse_new .iconMotdepasseBarreNew");
    const $posMotDePasseConfirm = this.$el.find(".motdepasse_confirm .pwd_confirm");
    const $posIconeConfirm = this.$el.find(".motdepasse_confirm .iconMotdepasseConfirm");
    const $posIconeBarreConfirm = this.$el.find(".motdepasse_confirm .iconMotdepasseBarreConfirm");
    const optionSelect = event.currentTarget && !CWSTR.isBlank(event.currentTarget.id) && (event.currentTarget.id.split("_").length > 0) ? event.currentTarget.id.split("_")[1] : event.currentTarget.id;

    switch (optionSelect) {
      case "password":
      case "old":
        $posMotDePasseOld.toggleClass("togglePassword_hide");
        $posIconeOld.toggleClass("d-none");
        $posIconeBarreOld.toggleClass("d-none");
        if ($posMotDePasseOld.attr("type") === "password") {
          $posMotDePasseOld.attr("type", "text");
        } else {
          $posMotDePasseOld.attr("type", "password");
        }
        break;
      case "new":
        $posMotDePasseNew.toggleClass("togglePassword_hide");
        $posIconeNew.toggleClass("d-none");
        $posIconeBarreNew.toggleClass("d-none");
        if ($posMotDePasseNew.attr("type") === "password") {
          $posMotDePasseNew.attr("type", "text");
        } else {
          $posMotDePasseNew.attr("type", "password");
        }
        break;
      case "confirm":
        $posMotDePasseConfirm.toggleClass("togglePassword_hide");
        $posIconeConfirm.toggleClass("d-none");
        $posIconeBarreConfirm.toggleClass("d-none");
        if ($posMotDePasseConfirm.attr("type") === "password") {
          $posMotDePasseConfirm.attr("type", "text");
        } else {
          $posMotDePasseConfirm.attr("type", "password");
        }
        break;
      default:
      //Nothing
    }
    event.preventDefault();
    event.stopPropagation(); //il faut le faire pour éviter une navegation involontaire à "#"
  }
}
