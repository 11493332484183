import { FORMS } from 'utils/forms.js';
import { HABILITATION } from 'utils/habilitation.js';
import { HEADERS } from 'utils/headers.js';
import { objs } from 'src/objectsRepository';
import { STR } from 'utils/str.js';

/**
 * BaseModel : base model to use instead of the backbone model
 *
 * - management of habilitations
 */
export var BaseModel = Backbone.Model.extend({

  blacklist: [],
  /**
   * Checks habilitations and if they are correct, fetches the model
   *  responds fetch without errors
   */
  fetch: function(options) {
    var l_options = options ? _.clone(options) : {};
    var urlParams = null;

    this.validationError = null; //initialisation 
    if (!STR.isBlank(this.version)) {
      var currentVersion = _.isObject(this.version) ? this.version.GET : this.version;

      this.setHeaders(l_options, HEADERS.versionContext(currentVersion));
    }
    urlParams = this._prepareUrlParams();
    if (!l_options.type || l_options.type === "GET") {
      l_options.data = urlParams;
    }
    if (this.activeSimulation === true) {
      this.prepareSimulation(l_options);
    }
    this._prepareEcranHeader(l_options);
    if (this.habContext) {
      var oldFoncCour = (typeof this.habContext.get("foncCour") === "string" ? this.habContext.get("foncCour") : _.clone(this.habContext.get("foncCour")));

      this.habContext.verifierFoncCour();
      this.setHeaders(l_options, this.habContext.header());
      if (this.usePopulation === true) {
        this.preparePopulation(l_options);
      }
      //retablir la valeur de foncCour après la verification et la préparation de "header"
      this.updateHabContext({ "foncCour": oldFoncCour });
      return Backbone.Model.prototype.fetch.call(this, l_options);
    } else {
      this._checkHabilitations();

      if (this.habilitationV === "N" || HABILITATION.canView(this.habilitationV)) {
        if (this.usePopulation === true) {
          this.preparePopulationAndHabiliation(l_options);
        } else {
          this.setHeaders(l_options, HEADERS.habilitationContext(this.usecase, this.habilitationV));
        }
        return Backbone.Model.prototype.fetch.call(this, l_options);

      } else {
        if (Configuration.development === true && STR.isBlank(this.habilitationG)) {
          throw new Error("The habilitation is not properly configured");
        }
        if (l_options.success) {
          l_options.success(this, null, l_options);
        }
      }
    }
    return $.Deferred().resolve();
  },

  /**
   * Includes population Ident and Type in header
   */
  preparePopulationAndHabiliation: function(options) {
    var popId = null,
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;

    if (!STR.isBlank(this.popId) && !STR.isBlank(this.popType)) {
      popId = this.popId;
      popType = this.popType;
      popCode = this.popCode;
      popNat = this.popNat;
      popDesc = this.popDesc;
    } else if (!STR.isBlank(objs.populationMenu) && !STR.isBlank(objs.populationMenu.model)) {
      popId = STR.isBlank(objs.populationMenu.model.get("ident")) ? "0" : objs.populationMenu.model.get("ident");
      popType = objs.populationMenu.model.get("type");
      popCode = objs.populationMenu.model.get("code");
      popNat = objs.populationMenu.model.get("nature");
      popDesc = objs.populationMenu.model.get("desc");
    }
    this.setHeaders(options, HEADERS.habilitationContext(this.usecase, this.habilitationV));
    if (STR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (STR.isBlank(popNat)) {
      popNat = "";
    }
    if (STR.isBlank(popCode)) {
      popCode = "";
    }
    if (STR.isBlank(popDesc)) {
      popDesc = "";
    }
    if (STR.isBlank(popType)) {
      popType = "D";
    } else if (popType === "M") {
      popType = "D";
    }
    this.setHeaders(options, HEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  },

  /**
   * Includes population Ident and Type in header
   */
  preparePopulation: function(options) {
    var popId = null,
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;

    if (!STR.isBlank(this.popId) && !STR.isBlank(this.popType)) {
      popId = this.popId;
      popType = this.popType;
      popCode = this.popCode;
      popNat = this.popNat;
      popDesc = this.popDesc;
    } else if (!STR.isBlank(objs.populationMenu) && !STR.isBlank(objs.populationMenu.model)) {
      popId = objs.populationMenu.model.get("ident");
      popType = objs.populationMenu.model.get("type");
      popCode = objs.populationMenu.model.get("code");
      popNat = objs.populationMenu.model.get("nature");
      popDesc = objs.populationMenu.model.get("desc");
    }
    if (STR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (STR.isBlank(popNat)) {
      popNat = "";
    } else if (popNat === "M") {
      popNat = "C";
    }
    if (STR.isBlank(popCode)) {
      popCode = "";
    }
    if (STR.isBlank(popDesc)) {
      popDesc = "";
    }
    // Type must be S or D
    if (STR.isBlank(popType)) {
      popType = "D";
    } else if (popType === "M") {
      popType = "D";
    }
    this.setHeaders(options, HEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  },

  prepareSimulation: function(options) {
    var sim = !STR.isBlank(this.simulationData) ? this.simulationData : objs.simulationModel;

    if (!STR.isBlank(sim)) {
      var datedeb = sim.get("datedeb");
      var passreel = false;
      var vueJourneeDate = "";

      if (objs.ctxAffReelPasse === true) {
        passreel = true
      }
      if (!STR.isBlank(this.ctxVueJourneeDate)) {
        vueJourneeDate = this.ctxVueJourneeDate;
      }
      this.setHeaders(options, HEADERS.simulationContext(sim.get("code"), datedeb, sim.get("datefin"), passreel, vueJourneeDate));
    }
  },

  /**
   * Checks habilitations and if they are correct, executes standard Backbone.model Save
   *  responds save without errors
   *  responds save with an error
   */
  save: function(key, val, options) {
    var isValOptions = false;
    var header = null;

    if (STR.isBlank(key) || typeof key === 'object') {
      options = val;
      isValOptions = true;
    }
    options = options ? _.clone(options) : {};
    if (!_.isEmpty(this.blacklist)) {
      this._filterAttributesInBlackList(isValOptions ? val : options);
    }
    if (!STR.isBlank(this.version)) {
      var currentVersion = this.version;

      if (_.isObject(this.version)) {
        if (this.isNew()) {
          currentVersion = this.version.POST;
        } else {
          currentVersion = this.version.PUT;
        }
      }
      header = HEADERS.versionContext(currentVersion);
      if (isValOptions === true) {
        this.setHeaders(val, header);
      } else {
        this.setHeaders(options, header);
      }
    }
    if (this.habContext) {
      var oldFoncCour = (typeof this.habContext.get("foncCour") === "string" ? this.habContext.get("foncCour") : _.clone(this.habContext.get("foncCour")));

      this.habContext.verifierFoncCour((this.isNew() ? "add" : "update"));
      if (isValOptions === true) {
        this._prepareEcranHeader(val);
        this._prepareOptionsErrors(val);
      } else {
        this._prepareEcranHeader(options);
        this._prepareOptionsErrors(options);
      }
      header = this.habContext.header();
      if (isValOptions === true) {
        this.setHeaders(val, header);
      } else {
        this.setHeaders(options, header);
      }
      if (isValOptions === true && this.usePopulation === true) {
        this.preparePopulation(val);
      } else if (this.usePopulation === true) {
        this.preparePopulation(options);
      }
      if (isValOptions === true && this.activeSimulation === true) {
        this.prepareSimulation(val);
      } else if (this.activeSimulation === true) {
        this.prepareSimulation(options);
      }
      //retablir la valeur de foncCour après la verification et la préparation de "header"
      this.updateHabContext({ "foncCour": oldFoncCour });
      Backbone.Model.prototype.save.call(this, key, val, options);
    } else {
      this._checkHabilitations();
      if (isValOptions === true) {
        this._prepareEcranHeader(val);
        this._prepareOptionsErrors(val);
      } else {
        this._prepareEcranHeader(options);
        this._prepareOptionsErrors(options);
      }
      if (isValOptions === true && this.usePopulation === true) {
        this.preparePopulation(val);
      } else if (this.usePopulation === true) {
        this.preparePopulation(options);
      }
      if (isValOptions === true && this.activeSimulation === true) {
        this.prepareSimulation(val);
      } else if (this.activeSimulation === true) {
        this.prepareSimulation(options);
      }
      if (this.habilitationG === "N" || (this.canViewTreatment === true && HABILITATION.canView(this.habilitationG))) {
        header = HEADERS.habilitationContext(this.usecase, this.habilitationG, "");
        if (isValOptions === true) {
          this.setHeaders(val, header);
        } else {
          this.setHeaders(options, header);
        }
        Backbone.Model.prototype.save.call(this, key, val, options);
      } else if (this.isNew() && (HABILITATION.canCreate(this.habilitationG))) {
        header = HEADERS.habilitationContext(this.usecase, this.habilitationG, "A");
        if (isValOptions === true) {
          this.setHeaders(val, header);
        } else {
          this.setHeaders(options, header);
        }
        Backbone.Model.prototype.save.call(this, key, val, options);
      } else if (!this.isNew() && (HABILITATION.canUpdate(this.habilitationG))) {
        header = HEADERS.habilitationContext(this.usecase, this.habilitationG, "M");
        if (isValOptions === true) {
          this.setHeaders(val, header);
        } else {
          this.setHeaders(options, header);
        }
        Backbone.Model.prototype.save.call(this, key, val, options);
      } else {
        if (Configuration.development === true && STR.isBlank(this.habilitationG)) {
          throw new Error("The habilitation is not properly configured");
        }
        if (STR.isBlank(key) || typeof key === 'object') {
          if (val.error) {
            val.error(this, null, val);
          }
        } else {
          if (options.error) {
            options.error(this, null, options);
          }
        }
      }
    }
  },

  /**
   * Checks habilitations and if they are correct, executes standard Backbone.model Destroy
   *  responds destroy without errors
   *  responds destroy with an error
   */
  destroy: function(options) {
    options = options ? _.clone(options) : {};
    if (!STR.isBlank(this.version)) {
      var currentVersion = _.isObject(this.version) ? this.version.DELETE : this.version;

      this.setHeaders(options, HEADERS.versionContext(currentVersion));
    }
    if (this.activeSimulation === true) {
      this.prepareSimulation(options);
    }
    if (this.habContext) {
      var oldFoncCour = (typeof this.habContext.get("foncCour") === "string" ? this.habContext.get("foncCour") : _.clone(this.habContext.get("foncCour")));

      this.habContext.verifierFoncCour("delete");
      this.setHeaders(options, this.habContext.header());
      //retablir la valeur de foncCour après la verification et la préparation de "header"
      this.updateHabContext({ "foncCour": oldFoncCour });
      Backbone.Model.prototype.destroy.call(this, options);
    } else {
      this._checkHabilitations();
      this._prepareEcranHeader(options);
      if (this.habilitationG === "N" || (this.canViewTreatment === true && HABILITATION.canView(this.habilitationG))) {
        this.setHeaders(options, HEADERS.habilitationContext(this.usecase, this.habilitationG, ""));
        Backbone.Model.prototype.destroy.call(this, options);
      } else if (HABILITATION.canDelete(this.habilitationG) || this.isNew()) {
        this.setHeaders(options, HEADERS.habilitationContext(this.usecase, this.habilitationG, "S"));
        Backbone.Model.prototype.destroy.call(this, options);
      } else {
        if (Configuration.development === true && STR.isBlank(this.habilitationG)) {
          throw new Error("The habilitation is not properly configured");
        }
        if (options.error) {
          options.error(this, null, options);
        }
      }
    }
  },

  /**
   * Checks that habilitationV, habilitationG and usecase are informed.If they are not an error is shown
   */
  _checkHabilitations: function() {
    if (STR.isBlank(this.habilitationV)) {
      throw new Error("View Habilitation must be defined or set to empty string if not used.");
    }
    if (STR.isBlank(this.habilitationG)) {
      throw new Error("Management Habilitation must be defined or set to empty string if not used.");
    }
    if (STR.isBlank(this.usecase)) {
      throw new Error("Usecase name must be defined.");
    }
  },

  /**
   * Prepares model header for server calls
   */
  _prepareEcranHeader: function(options) {
    if (!STR.isBlank(this.ecran)) {
      this.setHeaders(options, HEADERS.ecranContext(this.ecran));
    }
  },

  _filterAttributesInBlackList: function(options) {
    options.attrs = _.omit(options.attrs ? options.attrs : this.attributes, this.blacklist);
  },

  /**
   * Adds options.header to current header
   *  responds without errors
   *  responds with an error
   */
  setHeaders: function(options, header) {
    if (options.headers) {
      options.headers = _.extend(options.headers, header);
    } else {
      options.headers = header;
    }
  },

  /**
   * Copies current attributes to the property oldAttributes. This property can be accessed in the future to revert changes
   */
  store: function() {
    this.oldAttributes = _.clone(JSON.stringify(this.attributes));
  },

  /**
   * Returns a value from the last values got from the server.
   */
  getOldAttribute: function(attrName) {
    var value = null;

    if (this.oldAttributes) {
      var oldAttr = this.parse(JSON.parse(this.oldAttributes));

      value = STR.getElValueFromObj(oldAttr, attrName);
    }
    return value;
  },

  /**
   * Sets current model attributes to values that were stored (oldAttributes).
   */
  revert: function() {
    if (this.oldAttributes) {
      this.set(this.parse(JSON.parse(this.oldAttributes)));
      this.validationError = null;
    }
  },

  /**
   * Sets model's habilitations and usecase to values passed
   */
  setHabilitation: function(V, G, usecase) {
    if (!STR.isBlank(V)) {
      this.habilitationV = V;
    }
    if (!STR.isBlank(G)) {
      this.habilitationG = G;
    }
    if (!STR.isBlank(usecase)) {
      this.usecase = usecase;
    }
  },

  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  setVersion: function(version) {
    this.version = version;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  },

  /**
   * Prepare the 3 kinds of parameter this collection understand in an unique
   * object that the REST service can understand
   */
  _prepareUrlParams: function() {
    var pWhere = {};

    // Filter parameters not empty
    _.each(this.params, function(value, key) {
      if (!STR.isBlank(value)) {
        pWhere[key] = value;
      }
    });
    if (this.usePopulation === true) {
      // Join population parameter
      return _.extend(pWhere, { "filtre": true });
    }
    return pWhere;
  },

  /**
   * Function that adds a custom error management for error 406 when save.
   * Preserves the original error function defined on the model callback and call it
   * after the the errorBaseModel function.
   */
  _prepareOptionsErrors: function(options) {
    var errorCallback;
    var errorBaseModel = function(model, response, options) {
      var excludeByAction = (!STR.isBlank(model.action) && (model.action === "accepter" || model.action === "refuser") ? true : false);

      if (!excludeByAction && response && response.status === 406 && !_.isEmpty(response.responseText)) {
        //If there was an error of saisi incorrect we want to show it on the edited row
        model.validationError = model.validationError || { errors: {}, errorValidation: {} };
        if (response.responseJSON && response.responseJSON.attribut) {
          var errorField = response.responseJSON.attribut;

          FORMS.assignValue(model.validationError.errors, errorField, response.responseJSON.message);
          model.trigger("invalid", self, model.validationError, errorField);
          response.oldStatus = response.status;
          response.oldResponseText = response.responseText;
          //Avoid the creation of the pop up
          response.status = 200;
          response.responseText = null;
        }
      }
      if (errorCallback) {
        errorCallback(model, response, options);
      }
    };
    if (options.error) {
      errorCallback = options.error;
    }
    options.error = errorBaseModel;
  },

  clone: function() {
    return new this.constructor(JSON.parse(JSON.stringify(this.toJSON())));
  }
});
