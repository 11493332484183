import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for load the elements with the droids table (table rigth)
 */
export class CWDelegationsTableModel extends CWBaseModel {

  /**
   * Default data model
   *		id : null,
   *		code : "",
   *		datedeb : "",
   *		datefin : "",
   *		retro : null,
   *		datetrace : "",
   *		heuretrace : "",
   *		profil : "",
   *		delegue :
   *		{
   *			matricule : null,
   *			matriculeaux : "",
   *			nom : "",
   *			prenom : "",
   *			utilcode : "",
   *			utilnom : ""
   *		},
   *		auteur :
   *		{
   *			matricule : null,
   *			matriculeaux : "",
   *			nom : "",
   *			prenom : "",
   *			utilcode : "",
   *			utilnom : ""
   *		},
   *		delegant :
   *		{
   *			matricule : null,
   *			matriculeaux : "",
   *			nom : "",
   *			prenom : "",
   * 			utilcode : "",
   *			utilnom : ""
   *		}
   * }
   */
  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "datedeb": "",
      "datefin": "",
      "retro": null,
      "datetrace": "",
      "heuretrace": "",
      "profil": "",
      "delegue": {
        "matricule": null,
        "matriculeaux": "",
        "nom": "",
        "prenom": "",
        "utilcode": "",
        "utilnom": ""
      },
      "auteur": {
        "matricule": null,
        "matriculeaux": "",
        "nom": "",
        "prenom": "",
        "utilcode": "",
        "utilnom": ""
      },
      "delegant": {
        "matricule": null,
        "matriculeaux": "",
        "nom": "",
        "prenom": "",
        "utilcode": "",
        "utilnom": ""
      }
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.usecase = options.usecase || "droitsdel";
    super(attributes, options);
    /**
 * Builds the url for the REST call
 */
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/delegation";
    };
  }

  /**
   * Parsed the results returned by the REST,
   * Copy the return code in the object id
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      response.id = response.code;
    }
    return response;
  }
}