import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHEADERS } from 'utils/cwHeaders';
import { CWSTR } from 'utils/cwStr';

/**
 * Model to manage the context of an habilitation
 *
 */
export class CWHabilitationContext extends Backbone.Model {

  copyAttributes: { [key: string]: any };
  overrideNatureGestion: boolean;
  exceptionCreation: boolean;//Il sera utilisé pour changer l'action add par update en la vérifiaction de l'habilitation

  defaults(): { [key: string]: any } {
    return {
      "onglet": "",
      "foncCour": "", // eslint-disable-line
      "natGest": "" // eslint-disable-line
    }
  }

  /**
   * overrideNatureGestion is used in some components (mainly grids) to indicate if the component can modify this value
   * or should use the original value
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.copyAttributes = _.clone(this.attributes);
    this.overrideNatureGestion = true;
    if (options && _.isBoolean(options.overrideNatureGestion)) {
      this.overrideNatureGestion = options.overrideNatureGestion;
    }
    this.exceptionCreation = (options && _.isBoolean(options.exceptionCreation)) ? options.exceptionCreation : false;
  }

  /**
   * List of the attributs that will be updated
   *
   */
  update(attrs: { [key: string]: any }): void {
    this.copyAttributes = _.clone(this.attributes);
    for (const i in attrs) {
      if (i === "natGest") {
        if (this.overrideNatureGestion === true) {
          this.set(i, attrs[i]);
        }
      } else {
        this.set(i, attrs[i]);
      }
    }
  }

  /**
   * Build the gfi-context header used by the WS
   *
   */
  header(): { [key: string]: any } {
    return CWHEADERS.habilitationContext(this.attributes.onglet, this.attributes.foncCour, this.attributes.natGest);
  }

  /**
   * return a copy of the current habilitation context
   *
   */
  copy(): CWHabilitationContext {
    const ctx = new CWHabilitationContext(_.clone(this.attributes));

    ctx.overrideNatureGestion = this.overrideNatureGestion;
    return ctx;
  }

  /**
   * reverts the attributs to a previous version (previous update call)
   */
  revert(): void {
    this.attributes = this.copyAttributes;
  }

  /**
   * Verifier les droits de foncCour(string ou objet)
   */
  _verifierNettoyerFoncCour(mode?: string): string | { [key: string]: any } {
    let rtnFoncCour: string | { [key: string]: any } = this.get("foncCour");
    let methodeCheck: (arg: string) => boolean = null;
    let modeCheck = mode;

    if (!_.isEmpty(rtnFoncCour)) {
      switch (modeCheck) {
        case "add":
          if (this.exceptionCreation === true) {
            methodeCheck = CWHABILITATION.canUpdate;
          } else {
            methodeCheck = CWHABILITATION.canCreate;
          }
          break;
        case "delete":
          methodeCheck = CWHABILITATION.canDelete;
          break;
        case "update":
          methodeCheck = CWHABILITATION.canUpdate;
          break;
        case "view":
        default://en blanc-> view
          methodeCheck = CWHABILITATION.canView;
          modeCheck = "view";
      }
      if (typeof rtnFoncCour === "string") {
        //'replace(/ /g,"")' commment un "trim" pour tout le texte or  si la lib de typescript était ES2021/ES12-> replaceAll
        rtnFoncCour = (rtnFoncCour ? rtnFoncCour.replace(/ /g, "") : rtnFoncCour);
        rtnFoncCour = this._verifierHabilitationString(methodeCheck, rtnFoncCour, modeCheck);
      } else if (typeof rtnFoncCour === "object") {
        const foncKeys: string[] = _.keys(rtnFoncCour);
        const foncLen = foncKeys.length;

        for (let i = 0; i < foncLen; i++) {
          //'replace(/ /g,"")' commment un "trim" pour tout le texte or  si la lib de typescript était ES2021/ES12-> replaceAll
          const lfoncTemp = ((rtnFoncCour as { [key: string]: any })[foncKeys[i]]) ? (rtnFoncCour as { [key: string]: any })[foncKeys[i]].replace(/ /g, "") : (rtnFoncCour as { [key: string]: any })[foncKeys[i]];
          const lfoncRtn = this._verifierHabilitationString(methodeCheck, lfoncTemp, modeCheck);

          if (!CWSTR.isBlank(lfoncRtn)) {
            //On met la valeur correcte de "foncCour"
            (rtnFoncCour as { [key: string]: any })[foncKeys[i]] = lfoncRtn;
          } else {
            delete (rtnFoncCour as { [key: string]: any })[foncKeys[i]];//on suprime l'habilitation du objet
          }
        }
      } else {
        rtnFoncCour = null;//ce n'est pas préparé pour le type de données ou c'est une erreur
      }
    }
    return rtnFoncCour;
  }

  /**
   * Ajouter le founcCour actif ou "N" pour l'utilisateur, mode (add, delete, update, view(defaut) )
   */

  verifierFoncCour(mode?: string): string | { [key: string]: any } {
    let foncFinal = this.get("foncCour");

    if (!_.isEmpty(foncFinal) && Configuration.IHMSecurity !== false) {
      foncFinal = this._verifierNettoyerFoncCour(mode);
      this.set("foncCour", foncFinal);
    }
    return foncFinal;
  }

  _checkSansGestion(methodeCheck: (arg: string) => boolean, rtnFoncCour: string): boolean {
    let accesDelete = false;

    if (typeof methodeCheck === "function" && typeof rtnFoncCour === "string" && !CWSTR.isBlank(rtnFoncCour)) {
      if (methodeCheck === CWHABILITATION.canView) {
        accesDelete = true;
      } else {
        if (!CWHABILITATION.canCreate(rtnFoncCour) && !CWHABILITATION.canDelete(rtnFoncCour) && !CWHABILITATION.canUpdate(rtnFoncCour)) {
          accesDelete = true;
        } else {
          accesDelete = true;
        }
      }
    }
    return accesDelete;
  }

  _verifierHabilitationString(methodeCheck: (arg: string) => boolean, valFoncCour: string, modeCheck: string): string {
    let rtnFonc: string = null;

    if (typeof methodeCheck === "function" && typeof valFoncCour === "string" && !CWSTR.isBlank(valFoncCour) && !CWSTR.isBlank(modeCheck)) {
      const elemFonc = valFoncCour.split(",");

      if (elemFonc) {
        rtnFonc = valFoncCour;
        if (elemFonc.length <= 1) {
          if (rtnFonc === "N" && modeCheck !== "view" && modeCheck !== "update") {
            rtnFonc = null;//la valeur "N" est utilisée en mode "view"(cas special dans l'enregistrement du password, mode "update"). En autre cas, c'est une erreur. 
          } else if (rtnFonc !== "N" && !methodeCheck(rtnFonc) && this._checkSansGestion(methodeCheck, rtnFonc)) {
            rtnFonc = null; //on suprime l'habilitation du objet
          }
        } else {
          const newFonc: string[] = [];

          for (let i = 0; i < elemFonc.length; i++) {
            if (methodeCheck(elemFonc[i]) || !this._checkSansGestion(methodeCheck, elemFonc[i])) {
              newFonc.push(elemFonc[i]);
            }
          }
          if (newFonc.length > 0) {
            rtnFonc = newFonc.join(",");//les bonnes habiitations
          } else {
            rtnFonc = null; //on suprime l'habilitation du objet
          }
        }
      }
    }
    return rtnFonc;
  }
}