import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';


export class CWSauveGarderPreferenceModelDelete extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "tabgardes";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/preferences/generique/utilisateur?themes=TG_COLL&ctxAcces=C";
    }
  }
}
