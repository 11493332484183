import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWCommonDataModel as CWTabservCollDTS } from 'src/uc/mon_compte/consulter/tabservcoll/models_db/cwCommonData.model';
import { CWDIVERS } from './cwDivers';
import { CWLOG } from 'utils/cwLog';
import { CWRENDERAGENTS } from './cwRenderAgents';
import { CWSTR } from './cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { ScheduleModel } from 'core/controls/schedule/schedule.model.js';
import { ScheduleView } from 'core/controls/schedule/schedule.view.js';
import { UTILS } from './utils.js';

/**
 * Generic Render for Medical planing and diary cells.
 */
export class CWRENDERMEDICAL {


  static _uniteM(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract = null;

    if (listeFractionnes[0] === 1) {
      //lPosFract = targetDom.find(".planmed_cell_nfraction_0");
      lPosFract = targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0");
      if (lPosFract.attr("data-cell-paint") === "0") {
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr2"));
          }
        }
        lPosFract.attr("data-cell-paint", "1");
      } else {
        lPosFract = null;
      }
    }
    return lPosFract;
  }

  static _uniteS(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any, aPeriode?: boolean): JQuery {
    let lPosFract = null;
    const out: any = [];
    let lUniteN1 = null;
    let lUniteN2 = null;
    const lFuncOne = (): JQuery => {
      let ltpPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");

      if (ltpPosFract.attr("data-cell-paint") === "0") {
        if (activite) {
          if (ltpPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            ltpPosFract = CWRENDERMEDICAL.paintTextActMedical(ltpPosFract, dts, activite.get("lib"));
          } else {
            ltpPosFract = CWRENDERMEDICAL.paintTextActMedical(ltpPosFract, dts, activite.get("abr2"));
          }
        }
        ltpPosFract.attr("data-cell-paint", "1");
      } else {
        ltpPosFract = null;
      }
      return ltpPosFract;
    };

    if (listeFractionnes[1] === 1 || aPeriode === true) {
      //lPosFract = targetDom.find(".planmed_cell_nfraction_1");
      if (aPeriode === true) {
        lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");
        if (lPosFract.attr("data-cell-paint") === "0") {
          if (listeFractionnes[2] === 1 && listeFractionnes[3] === 1) {
            if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0" &&
              targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
              targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [1, 2, 3], listeFractionnes); //2 cellules [1,3,0,0]
              lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");
              if (activite) {
                if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
                } else {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr4"));
                }
              }
              out.push(lPosFract);
            } else if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
              targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [1, 2], listeFractionnes); //3 cellules [1,2,0,1]
              lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");
              if (activite) {
                if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
                } else {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr4"));
                }
              }
              out.push(lPosFract);
            } else if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
              lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1"); //4 cellules [1,1,1,1]
              if (activite) {
                if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
                } else {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr2"));
                }
              }
              out.push(lPosFract);
              lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
              if (!CWSTR.isBlank(lUniteN2)) {
                out.push(lUniteN2);
              }
            } else {
              lPosFract = lFuncOne();
              if (!CWSTR.isBlank(lPosFract)) {
                out.push(lPosFract);
              }
            }
          } else if (listeFractionnes[2] === 1) {
            if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
              targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [1, 2], listeFractionnes); //3 cellules [1,2,0,1]
              lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");
              if (activite) {
                if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
                } else {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr4"));
                }
              }
              out.push(lPosFract);
            } else {
              lPosFract = lFuncOne();
              if (!CWSTR.isBlank(lPosFract)) {
                out.push(lPosFract);
              }
            }
          } else if (listeFractionnes[3] === 1) {
            if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
              lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1"); //4 cellules [1,1,1,1]
              if (activite) {
                if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
                } else {
                  lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr2"));
                }
              }
              out.push(lPosFract);
              lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
              if (!CWSTR.isBlank(lUniteN2)) {
                out.push(lUniteN2);
              }
            } else {
              lPosFract = lFuncOne();
              if (!CWSTR.isBlank(lPosFract)) {
                out.push(lPosFract);
              }
            }
          } else {
            lPosFract = lFuncOne();
            if (!CWSTR.isBlank(lPosFract)) {
              out.push(lPosFract);
            }
          }
        } else {
          if (listeFractionnes[2] === 1 && listeFractionnes[3] === 1) {
            if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0" &&
              targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
              out.push(CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts));
            } else if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
              lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
              if (!CWSTR.isBlank(lUniteN1)) {
                out.push(lUniteN1);
              }
            } else if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
              lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
              if (!CWSTR.isBlank(lUniteN2)) {
                out.push(lUniteN2);
              }
            }
          } else if (listeFractionnes[2] === 1 && targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
            lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
            if (!CWSTR.isBlank(lUniteN1)) {
              out.push(lUniteN1);
            }
          } else if (listeFractionnes[3] === 1 && targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
            lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
            if (!CWSTR.isBlank(lUniteN2)) {
              out.push(lUniteN2);
            }
          }
        }
      } else {
        lPosFract = lFuncOne();
        if (!CWSTR.isBlank(lPosFract)) {
          out.push(lPosFract);
        }
      }
    }
    return out;
  }

  static _uniteN1(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract = null;

    if (listeFractionnes[2] === 1) {
      //lPosFract = targetDom.find(".planmed_cell_nfraction_2");
      lPosFract = targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2");
      if (lPosFract.attr("data-cell-paint") === "0") {
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr2"));
          }
        }
        lPosFract.attr("data-cell-paint", "1");
      } else {
        lPosFract = null;
      }
    }
    return lPosFract;
  }

  static _uniteN2(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract = null;

    if (listeFractionnes[3] === 1) {
      //lPosFract = targetDom.find(".planmed_cell_nfraction_3");
      lPosFract = targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3");
      if (lPosFract.attr("data-cell-paint") === "0") {
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr2"));
          }
        }
        lPosFract.attr("data-cell-paint", "1");
      } else {
        lPosFract = null;
      }
    }
    return lPosFract;
  }

  static _uniteN(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract;
    const out: any = [];
    let lUniteN1 = null;
    let lUniteN2 = null;

    if (listeFractionnes[2] === 1 && listeFractionnes[3] === 1) {
      if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
        targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [2, 3], listeFractionnes); //3 cellules [1,1,2,0]
        lPosFract = targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2");
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr4"));
          }
        }
        out.push(lPosFract);
      } else {
        if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
          lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUniteN1)) {
            out.push(lUniteN1);
          }
        } else if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
          lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUniteN2)) {
            out.push(lUniteN2);
          }
        }
      }
    } else {
      if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
        lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
        if (!CWSTR.isBlank(lUniteN1)) {
          out.push(lUniteN1);
        }
      }
      if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
        lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
        if (!CWSTR.isBlank(lUniteN2)) {
          out.push(lUniteN2);
        }
      }
    }
    return out;
  }

  static _uniteSN1(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract;
    let out: any = [];
    let lUniteN1 = null;

    if (listeFractionnes[1] === 1 && listeFractionnes[2] === 1) {
      if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
        targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [1, 2], listeFractionnes); //3 cellules
        lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr4"));
          }
        }
        out.push(lPosFract);
      } else {
        if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
          out = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        } else if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
          lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUniteN1)) {
            out.push(lUniteN1);
          }
        }
      }
    } else {
      if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
        out = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
      }
      if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
        lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
        if (!CWSTR.isBlank(lUniteN1)) {
          out.push(lUniteN1);
        }
      }
    }
    return out;
  }

  static _uniteJ(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract = null;
    let out: any = [];
    let lUniteM = null;

    if (listeFractionnes[0] === 1 && listeFractionnes[1] === 1) {
      if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
        targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [0, 1], listeFractionnes); //3 cellules [2,0,1,1]
        lPosFract = targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0");
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr4"));
          }
        }
        out.push(lPosFract);
      } else {
        if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0") {
          lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUniteM)) {
            out.push(lUniteM);
          }
        } else if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
          out = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        }
      }
    } else {
      if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0") {
        lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);
        if (!CWSTR.isBlank(lUniteM)) {
          out.push(lUniteM);
        }
      }
      if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
        out = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
      }
    }
    return out;
  }

  static _uniteJN1(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract = null;
    let out: any = [];
    let lUniteN1 = null;

    if (listeFractionnes[0] === 1 && listeFractionnes[1] === 1 && listeFractionnes[2] === 1) {
      if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
        targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [0, 1, 2], listeFractionnes); // 2 cellules
        lPosFract = targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0");
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr8"));
          }
        }
        out.push(lPosFract);
      } else {
        if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0") {
          out = CWRENDERMEDICAL._uniteJ(listeFractionnes, targetDom, activite, dts);
        }
        if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
          out = out.concat(CWRENDERMEDICAL._uniteSN1(listeFractionnes, targetDom, activite, dts));
        }
        if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
          lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUniteN1)) {
            out = out.concat(lUniteN1);
          }
        }
      }
    } else {
      if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0") {
        out = CWRENDERMEDICAL._uniteJ(listeFractionnes, targetDom, activite, dts);
      }
      if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
        out = out.concat(CWRENDERMEDICAL._uniteSN1(listeFractionnes, targetDom, activite, dts));
      }
      if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
        lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
        if (!CWSTR.isBlank(lUniteN1)) {
          out = out.concat(lUniteN1);
        }
      }
    }
    return out;
  }

  static _uniteSNU(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract = null;
    let out: any = [];
    let lUniteN2 = null;

    if (listeFractionnes[1] === 1 && listeFractionnes[2] === 1 && listeFractionnes[3] === 1) {
      if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
        targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [1, 2, 3], listeFractionnes); //2 cellules
        lPosFract = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr8"));
          }
        }
        out.push(lPosFract);
      } else {
        if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
          out = CWRENDERMEDICAL._uniteSN1(listeFractionnes, targetDom, activite, dts);
        }
        if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
          out = out.concat(CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts));
        }
        if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
          lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUniteN2)) {
            out = out.concat(lUniteN2);
          }
        }
      }
    } else {
      if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
        out = CWRENDERMEDICAL._uniteSN1(listeFractionnes, targetDom, activite, dts);
      }
      if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
        out = out.concat(CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts));
      }
      if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
        lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
        if (!CWSTR.isBlank(lUniteN2)) {
          out = out.concat(lUniteN2);
        }
      }
    }
    return out;
  }

  static _uniteD(listeFractionnes: Array<number>, targetDom: JQuery, activite: Backbone.Model, dts: any): JQuery {
    let lPosFract = null;
    let out: any = [];
    let lUniteN2 = null;

    if (listeFractionnes[0] === 1 && listeFractionnes[1] === 1 && listeFractionnes[2] === 1 && listeFractionnes[3] === 1) {
      if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0" &&
        targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
        targetDom = CWRENDERMEDICAL.fusionFractionsMedical(targetDom, [0, 1, 2, 3], listeFractionnes); //1 cellule [4,0,0,0]
        lPosFract = targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0");
        if (activite) {
          if (lPosFract.parent().parent().hasClass("tabservind_cell") === true) {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("lib"));
          } else {
            lPosFract = CWRENDERMEDICAL.paintTextActMedical(lPosFract, dts, activite.get("abr8"));
          }
        }
        out.push(lPosFract);
      } else {
        if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0") {
          if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
            out = CWRENDERMEDICAL._uniteJN1(listeFractionnes, targetDom, activite, dts);
          } else {
            const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

            if (!CWSTR.isBlank(lUniteM)) {
              out.push(lUniteM);
            }
          }
        }
        if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
          out = out.concat(CWRENDERMEDICAL._uniteSNU(listeFractionnes, targetDom, activite, dts));
        }
        if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
          out = out.concat(CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts));
        }
        if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
          lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUniteN2)) {
            out = out.concat(lUniteN2);
          }
        }
      }
    } else {
      if (targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0").attr("data-cell-paint") === "0") {
        out = CWRENDERMEDICAL._uniteJN1(listeFractionnes, targetDom, activite, dts);
      }
      if (targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1").attr("data-cell-paint") === "0") {
        out = out.concat(CWRENDERMEDICAL._uniteSNU(listeFractionnes, targetDom, activite, dts));
      }
      if (targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2").attr("data-cell-paint") === "0") {
        out = out.concat(CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts));
      }
      if (targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3").attr("data-cell-paint") === "0") {
        lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);
        if (!CWSTR.isBlank(lUniteN2)) {
          out = out.concat(lUniteN2);
        }
      }
    }
    return out;
  }

  /**
   * Function that generate the HTML structure of the Activites information for the cell.
   */

  static _renderActiviteMedical(dts: any, fam: Backbone.Model, fractionsCell: JQuery, activite: Backbone.Model, listeFractionnes: Array<number>, typeGestionActivite: string, isvueJournee: boolean, listeAnnuleCouv?: Array<Backbone.Model>, libTypologie?: string, infoUnite?: Backbone.Model): JQuery {
    const targetDom = fractionsCell;//DOM structure
    let lPosFract: any = [];

    if ((dts.mod === 'UNITE' || (typeGestionActivite === "REPOBLIG" && dts.mod === "PERIOC")) && _.isEmpty(listeAnnuleCouv)) {

      switch (dts.uni) {
        case 'M': {
          const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteM)) {
            lPosFract.push(lUniteM);
          }
        }
          break;
        case 'S':
          lPosFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
          break;
        case 'SN1':
          lPosFract = CWRENDERMEDICAL._uniteSN1(listeFractionnes, targetDom, activite, dts);
          break;
        case 'SNU':
          lPosFract = CWRENDERMEDICAL._uniteSNU(listeFractionnes, targetDom, activite, dts);
          break;
        case 'J':
          lPosFract = CWRENDERMEDICAL._uniteJ(listeFractionnes, targetDom, activite, dts);
          break;
        case 'JN1':
          lPosFract = CWRENDERMEDICAL._uniteJN1(listeFractionnes, targetDom, activite, dts);
          break;
        case 'N1': {
          const lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteN1)) {
            lPosFract.push(lUniteN1);
          }
        }
          break;
        case 'N2': {
          const lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteN2)) {
            lPosFract.push(lUniteN2);
          }
        }
          break;
        case 'N':
          lPosFract = CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts);
          break;
        case 'D':
          lPosFract = CWRENDERMEDICAL._uniteD(listeFractionnes, targetDom, activite, dts);
          break;
        default:
          return targetDom;
      }
    } else {
      lPosFract = CWRENDERMEDICAL.restitutionActivitesPosteAvecCouverture(lPosFract, dts, fractionsCell, activite, listeFractionnes, typeGestionActivite, listeAnnuleCouv);
    }
    if (lPosFract.length > 0) {
      for (let i = 0, l = lPosFract.length; i < l; i++) {
        if (lPosFract[i]) {
          //Add typologie info (to be used with Accentuer affichage option)
          lPosFract[i].data("act-typos", [dts.tpo]);
          //Activite External
          if (dts.ext === false) {
            lPosFract[i].filter((index: number, item: any) => {
              return !$(item).hasClass("phx-cell-actExterieures");
            }).addClass('phx-cell-actInterieures');
          } else {
            lPosFract[i].filter((index: number, item: any) => {
              return !$(item).hasClass("phx-cell-actExterieures");
            }).addClass("phx-cell-actInterieuresForCheck");//il sera utilisé pour le neutraliser si le collaborateur n'est pas extérieur
          }
          if (!CWSTR.isBlank(dts.sta) && dts.sta !== "A" && dts.sta !== "H") {
            lPosFract[i].addClass("phx-dynamicStyle-cell-actaval");
          }
          if (CWSTR.isBlank(dts.fam) || dts.fam === "EMPTY") {
            if (dts.typ === "P") {
              lPosFract[i].addClass("ui-phx-activite-prevue");
            } else if (dts.typ === "R") {
              lPosFract[i].addClass("ui-phx-activite-realisee");
            }
          } else {
            lPosFract[i].css({ "background-color": fam.get("bgcolor"), "color": fam.get("color") });
          }
          if (isvueJournee === true) {
            const lTmpDiv = $("<div class='plmed-inVuejournee'>");

            lTmpDiv.text((lPosFract[i].hasClass('phx-dynamicStyle-cell-actaval') ? '? ' : '') + lPosFract[i].text() + " - " + activite.get("lib"));
            lPosFract[i].text("");
            lPosFract[i].attr("data-actMedVue", dts.row);
            lPosFract[i].append(lTmpDiv);
          }
        }
      }
    } else if (typeGestionActivite === "REPOBLIG" && (dts.mod === 'UNITE' || dts.mod === "PERIOC")) {
      if ($(targetDom.children()).find(".indicRepOblig").length === 0) {
        const $lDiv = $("<div class='infobulleRepOblig'>");
        const $lDivTitle = $("<div class='infobulleRepObligTitle'>");
        const $lDivInfo = $("<div class='infobulleRepObligInfo'>");
        const lTitle = i18n.t("common:infoIndicateurs") + "<span class='indicRepObligInfobulle'> ! </span>";//on doit ajouter un indicateur "!"
        const lInfo = libTypologie + ", " + infoUnite.get("lib") + ", <span class='ui-phx-ihm-texte-donnees-important'>" + activite.get("lib") + "</span>";
        const $lIndicateur = $("<div class='indicRepOblig'>").html("!");

        $lDivTitle.html(lTitle);
        $lDiv.append($lDivTitle);
        $lDivInfo.html(lInfo);
        $lDiv.append($lDivInfo);
        $lIndicateur.attr("title", $lDiv.html());
        switch (dts.uni) {
          case 'M':
          case 'D': {
            const lText = $(targetDom.children()).find(".cellMed-fract-0");

            lText.prepend($lIndicateur);
          }
            break;
          case 'S':
            if (listeFractionnes[0] > 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            } else {
              const lText = $(targetDom.children()).find(".cellMed-fract-1");

              lText.prepend($lIndicateur);
            }
            break;
          case 'J':
            if (listeFractionnes[0] >= 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[1] === 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-1");

              lText.prepend($lIndicateur);
            }
            break;
          case 'N1':
            if (listeFractionnes[0] > 2) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[1] > 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-1");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[2] >= 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-2");

              lText.prepend($lIndicateur);
            }
            break;
          case 'N2':
            if (listeFractionnes[0] > 3) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[1] > 2) {
              const lText = $(targetDom.children()).find(".cellMed-fract-1");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[2] > 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-2");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[3] === 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-3");

              lText.prepend($lIndicateur);
            }
            break;
          case 'SN1':
            if (listeFractionnes[0] > 2) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[1] > 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-1");

              lText.prepend($lIndicateur);
            }
            break;
          case 'SNU':
            if (listeFractionnes[0] > 3) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[1] > 2) {
              const lText = $(targetDom.children()).find(".cellMed-fract-1");

              lText.prepend($lIndicateur);
            }
            break;
          case 'JN1':
            if (listeFractionnes[0] > 2) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            }
            break;
          case 'N':
            if (listeFractionnes[0] > 3) {
              const lText = $(targetDom.children()).find(".cellMed-fract-0");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[1] > 2) {
              const lText = $(targetDom.children()).find(".cellMed-fract-1");

              lText.prepend($lIndicateur);
            }
            if (listeFractionnes[2] > 1) {
              const lText = $(targetDom.children()).find(".cellMed-fract-2");

              lText.prepend($lIndicateur);
            }
            break;
        }
      }
    }
    return targetDom;
  }

  // /**
  //  * Function that generate the HTML structure of the Astreintes information for the cell.
  //  */
  // static _renderAstreinteMedical(dts: any, fam: any, activitiesDoms: JQuery, activitiesFilters: Backbone.Collection, isvueJournee: boolean): JQuery {
  //   return activitiesDoms;
  // }


  static _cellMedical(pk: string, fractionsMedical: JQuery, isJourFerie: boolean, listeFractionnes: Array<number>): Array<JQuery> {
    const lFractionsCell = [];

    if (!CWSTR.isBlank(pk) && !_.isEmpty(fractionsMedical)) {
      let lStyle: any = [];
      const lDate = CWTYPE.DATE.strToDate(pk.split(",")[1]);
      const lNbfractions = fractionsMedical.length;
      const lWidth = ((!CWSTR.isBlank(lNbfractions) && lNbfractions !== 0) ? (100 / lNbfractions) : 100) + "%";

      if (lNbfractions > 0) {
        lStyle = CWRENDERMEDICAL.styleJourMedical(lDate.getDay(), isJourFerie, lNbfractions);
        for (let i = 0; i < lNbfractions; i++) {
          const lCellfr = $("<div class=\"planmed_cell_nfraction_" + i + "\">");
          const lCss = {
            "text-align": "center",
            "width": lWidth,
            "height": "23px",
            "float": "left",
            "display": "block",
            "position": "relative",
            "box-sizing": "border-box"
          };
          const lDivCell = $("<div>").addClass("cellMed-fract-" + i).css({ "height": "100%", "width": "100%" });
          const lIdFrac = (i + 1) + ",1," + lNbfractions; //structure "data-idFract": pos,len,nbfrac (par exemple: "1,1,4")

          lCellfr.addClass(lStyle[i]);
          lCellfr.css(lCss);
          //structure "data-idFract": pos,len,nbfrac (par exemple: "1,1,4")
          lCellfr.attr("data-idFract", lIdFrac);
          // l_cellfr.attr("data-cell-paint", '0'); // possible values "0" or "1". To check whether the top-level event has already painted the cell.
          //l_divCell.attr("data-idFract", l_idFrac);
          lDivCell.attr("data-cell-paint", '0'); // possible values "0" or "1". To check whether the top-level event has already painted the cell.
          lCellfr.append(lDivCell);
          lFractionsCell.push(lCellfr);
          listeFractionnes[i] = 1; //nombre de cellules. Il sera utilisé postérieurement dans absences et activités
        }
      }
    }
    return lFractionsCell;
  }

  static styleJourMedical(aDay: number, isJourFerie: boolean, nbfractions: number): Array<string> {
    const lRtn: any = [];
    let lFract = 0;

    if (!CWSTR.isBlank(nbfractions)) {
      lFract = nbfractions;
    }
    for (let i = 0; i < lFract; i++) {
      if (isJourFerie === true) {
        if (nbfractions > (i + 1)) {
          lRtn.push("ui-gtmed-service-gardes-et-astreintes-n1");
        } else {
          lRtn.push("ui-gtmed-service-gardes-et-astreintes-n2");
        }
      } else {
        if (!CWSTR.isBlank(aDay)) {
          switch (aDay) {
            case 0: //Diumenge
              if (nbfractions > (i + 1)) {
                lRtn.push("ui-gtmed-service-gardes-et-astreintes-n1");
              } else {
                lRtn.push("ui-gtmed-service-gardes-et-astreintes-n2");
              }
              break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
              if ((nbfractions - 2) >= 2 && i >= (nbfractions - 2)) {
                if (nbfractions > (i + 1)) {
                  lRtn.push("ui-gtmed-service-gardes-et-astreintes-n1");
                } else {
                  lRtn.push("ui-gtmed-service-gardes-et-astreintes-n2");
                }
              } else {
                lRtn.push("ui-gtmed-service-jour"); //white");
              }
              break;
            case 6: //Samedi
              //la prèmiere fraction ira en blanc, le reste en "gris
              if (i === 0) {
                lRtn.push("ui-gtmed-service-jour"); //white");
              } else {
                if (nbfractions > (i + 1)) {
                  lRtn.push("ui-gtmed-service-gardes-et-astreintes-n1");
                } else {
                  lRtn.push("ui-gtmed-service-gardes-et-astreintes-n2");
                }
              }
              break
            default:
            //Nothing-> []
          }
        } else {
          break;
        }
      }
    }
    return lRtn;
  }

  /**
   * Function that generate the HTML structure of the Horaires information for the cell.
   */
  static _renderHorairesMedical(layers: Backbone.Model, fractionsCell: JQuery, alisteFractionnes: Array<number>, evtModel: any, dtsModel: any, pk: string, selonNature: boolean, options: { [key: string]: any }, isvueJournee: boolean): JQuery {
    const targetDom = fractionsCell;
    let horaireId: number = null;
    let horaireExc = null;
    let horaire = null;
    const selonNatureHor = (_.isBoolean(selonNature)) ? selonNature : false;
    const joursExMode = (options && options.joursExMode) ? options.joursExMode : null;
    const lHorFract = ["horabrmat", "horabrapm", "horabrnu1", "horabrnu2"];
    const parametreDiversIndJourneeValeur = CWDIVERS.get("indJournee");
    let natureTargetClass = "";
    let targetClass = "";
    const isTabServInd = options ? options.isTabServInd : false;
    const isTabServColl = options ? options.isTabServColl : false;

    horaireId = evtModel.getEvent("HORAIRES", pk).get("evt").at(0).get("val"); //0
    //Only add horaire if layer activated
    horaire = _.clone(dtsModel.HORAIRES.get(horaireId));
    if (selonNatureHor === true) { //Paint horaire style with nature d'horaire style
      const nat = horaire.get("nat");

      if (!CWSTR.isBlank(nat) && !CWSTR.isBlank(dtsModel.NATHOR.get(nat))) {
        natureTargetClass = dtsModel.NATHOR.get(nat).get("aff");
      }
    }
    //HORAIRE TYPE CLASS STYLE
    if (horaire.get("re") === true) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-repos" : natureTargetClass;
    } else if (horaire.get("hn") === true && horaire.get("hm") === true && horaire.get("hs") === true) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour-nuit" : natureTargetClass;
    } else if (horaire.get("hn") === true && horaire.get("hm") === true) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin-nuit" : natureTargetClass;
    } else if (horaire.get("hn") === true && horaire.get("hs") === true) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir-nuit" : natureTargetClass;
    } else if (horaire.get("hn") === true && horaire.get("hj") === false) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-nuit" : natureTargetClass;
    } else if (horaire.get("hm") === true && horaire.get("hs") === true) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour" : natureTargetClass;
    } else if (horaire.get("hm") === true) {
      //Check Absences on Left Part (Matin)
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin" : natureTargetClass;
      // targetRenderClass = "phx-cell-render-left";
      // semi = true;
    } else if (horaire.get("hs") === true) {
      //Check Absences on Right Part (Soir)
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir" : natureTargetClass;
      // targetRenderClass = "phx-cell-render-right";
      // semi = true;
    } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === false) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1" : natureTargetClass;
    } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === true) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1-nuit" : natureTargetClass;
    } else if (selonNatureHor === true) {
      targetClass = natureTargetClass;
    } else {
      targetClass = "ui-phx-horaire-sans-presence";
    }

    //An horarie is Exceptionel when val != cod
    horaireExc = (horaireId !== evtModel.getEvent("HORAIRES", pk).get("evt").at(0).get("cod"));
    for (let i = 0; i < lHorFract.length; i++) {
      let lTextFract = (!CWSTR.isBlank(horaire.get(lHorFract[i]))) ? horaire.get(lHorFract[i]) : "";
      const lPosFract = targetDom.find(".planmed_cell_nfraction_" + i + " .cellMed-fract-" + i);
      const cell = ".planmed_cell_nfraction_" + i;

      if ((lPosFract.attr("data-cell-paint") === '0') && (alisteFractionnes[i] === 1)) {
        if (isTabServInd) {
          // Class to show label when truncated in TSIND
          targetDom.children(cell).addClass("tabservind-cell-hor");
        }
        if (isvueJournee === true && !CWSTR.isBlank(lTextFract)) {
          lTextFract += " - " + horaire.get("lib");
        }
        lPosFract.text(lTextFract);
        if (!CWSTR.isBlank(lTextFract) && !CWSTR.isBlank(targetClass)) {
          lPosFract.addClass(targetClass);
        }
        if (horaireExc && layers.get("hor_exc") && !joursExMode && !CWSTR.isBlank(lTextFract)) {
          lPosFract.addClass("phx-dynamicStyle-cellMed-hor_exc");
        }
        if (isvueJournee === true) {
          const lTmpDiv = $("<div class='plmed-inVuejournee'>");

          lTmpDiv.text(lPosFract.text());
          lPosFract.text("");
          lPosFract.append(lTmpDiv);
        }
        if ((targetDom.hasClass("tabservind_cell") === true || isTabServColl) && !CWSTR.isBlank(lTextFract)) {
          let tooltipPlages = null;

          if (!CWSTR.isBlank(options.usecase) && objs[options.usecase + "Rt"] && objs[options.usecase + "Rt"].workflow) {
            const listePlgHoraires = objs[options.usecase + "Rt"].workflow.listePlagesHoraires;

            if (_.isEmpty(listePlgHoraires)) {
              tooltipPlages = new ScheduleModel({ showPlageBlank: false });
              tooltipPlages.set({
                tolouv: horaire.get("too"),
                tolclo: horaire.get("toc"),
                houv: horaire.get("ho"),
                hclo: horaire.get("hf"),
                dpf1: horaire.get("f1d"),
                fpf1: horaire.get("f1f"),
                dpf2: horaire.get("f2d"),
                fpf2: horaire.get("f2f"),
                pause: horaire.get("pau")
              });

              objs[options.usecase + "Rt"].workflow.listePlagesHoraires = [];
              objs[options.usecase + "Rt"].workflow.listePlagesHoraires.push({ "id": horaireId, "tooltipPlages": tooltipPlages });

            } else {
              const findPLgHor = _.find(listePlgHoraires, (plgHor: { [key: string]: any }): boolean => {
                return plgHor.id === horaireId;
              });

              if (!_.isEmpty(findPLgHor)) {
                tooltipPlages = findPLgHor.tooltipPlages;
              } else {
                tooltipPlages = new ScheduleModel({ showPlageBlank: false });
                tooltipPlages.set({
                  tolouv: horaire.get("too"),
                  tolclo: horaire.get("toc"),
                  houv: horaire.get("ho"),
                  hclo: horaire.get("hf"),
                  dpf1: horaire.get("f1d"),
                  fpf1: horaire.get("f1f"),
                  dpf2: horaire.get("f2d"),
                  fpf2: horaire.get("f2f"),
                  pause: horaire.get("pau")
                });

                listePlgHoraires.push({ "id": horaireId, "tooltipPlages": tooltipPlages });
              }
            }
          } else {
            //const tooltipPlages = new ScheduleModel({ showPlageBlank: false });
            tooltipPlages = new ScheduleModel({ showPlageBlank: false });
            tooltipPlages.set({
              tolouv: horaire.get("too"),
              tolclo: horaire.get("toc"),
              houv: horaire.get("ho"),
              hclo: horaire.get("hf"),
              dpf1: horaire.get("f1d"),
              fpf1: horaire.get("f1f"),
              dpf2: horaire.get("f2d"),
              fpf2: horaire.get("f2f"),
              pause: horaire.get("pau")
            });
          }
          targetDom.children(cell).removeAttr("title");
          targetDom.children(cell).prop("title", this._buildTooltipHoraire(tooltipPlages, horaire));
        }
      }
      // if ((lPosFract.attr("data-cell-paint") === '0') && (l_listeFractionnes[i] === 1)) {
      //   if (isvueJournee === true && !STR.isBlank(l_textFract)) {
      //     l_textFract += " - " + horaire.get("lib");
      //   }
      //   l_divCell.text(l_textFract);
      //   if (!STR.isBlank(l_textFract) && !STR.isBlank(targetClass)) {
      //     l_divCell.addClass(targetClass);
      //   }
      //   if (horaireExc && layers.get("hor_exc") && !joursExMode && !STR.isBlank(l_textFract)) {
      //     l_divCell.addClass("phx-dynamicStyle-cellMed-hor_exc");
      //   }
      //   l_divCell.attr("data-cell-paint", lPosFract.attr("data-cell-paint"));
      //   lPosFract.append(l_divCell);
      // }
    }
    return targetDom;
  }

  /**
   * Function that generate the HTML structure of the Absences information for the cell.
   */
  static _renderAbsencesMedical(layers: Backbone.Model, fractionsCell: JQuery, evtModel: any, dtsModel: any,
    pk: string, range: string, absTextByGroupe: boolean, options: { [key: string]: any }, listeFractionnes: Array<number>, isvueJournee: boolean): JQuery {

    const idlg = options ? options.planningIdlg : null;
    const isTabServInd = options ? options.isTabServInd : false;
    const isTabServColl = options ? options.isTabServColl : false;
    let textByAbsGroupe = false;
    let absencesDoms = fractionsCell;
    let absences = null;
    let filteredAbsences: any = [];
    const newFilteredAbsencesDay: any = [];
    let firstUniteJ = true, firstUniteK = true, firstUniteN = true, firstUniteM = true, firstUniteS = true;
    const newFilteredAbsences: any = [];
    let filteredAbsencesTooltip: any = [];

    if (_.isBoolean(absTextByGroupe)) {
      textByAbsGroupe = absTextByGroupe;
    }
    const absDate = evtModel.getEvent("ABSENCES", pk, idlg).get("dat");
    absences = evtModel.getEvent("ABSENCES", pk, idlg).get("evt"); //1
    //Remove the Heures absences
    for (let i = 0; i < absences.length; i++) {
      // I don't show the absences which are only to daily zoom.
      if (absences.at(i).get("dts").zom !== false) {
        if (absences.at(i).get("val") !== "H") {
          // accessed from the planning
          if (layers.get("abs")) {
            filteredAbsences.push(absences.at(i));
          }
        }
      }
    }
    //Remove evenements no validated,
    //show only absences that have been validated
    if (!CWSTR.isBlank(layers.get("evt_val")) && layers.get("evt_val") === false) {
      for (let i = 0; i < filteredAbsences.length; i++) {
        const status = filteredAbsences[i].get("dts").sta;

        if (status === "A" || status === "H" || status === "R") {
          newFilteredAbsences.push(filteredAbsences[i]);
        }
      }
      filteredAbsences = newFilteredAbsences;
    }

    //Not ordered copy
    filteredAbsencesTooltip = _.clone(filteredAbsences);

    if (filteredAbsences.length > 0) {
      //1º tri les absences et n'importe pas le numero d'absences parce qu'elles seront peintes par la priorité et unité s'il y a fraction disponible (de cette maniere, le numero d'absences n'est pas déjà un problème)
      filteredAbsences = CWRENDERMEDICAL.sortAbsences(filteredAbsences);
      //Remove 2 absences to day, etc    
      for (let i = 0; i < filteredAbsences.length; i++) {
        const day = filteredAbsences[i].get("val");

        if (day === "J" && firstUniteJ === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteJ = false;
        }
        if (day === "N" && firstUniteN === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteN = false;
        }
        if (day === "K" && firstUniteK === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteK = false;
        }
        if (day === "S" && firstUniteS === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteS = false;
        }
        if (day === "M" && firstUniteM === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteM = false;
        }
      }
      filteredAbsences = newFilteredAbsencesDay;
      for (let i = 0; i < filteredAbsences.length; i++) {
        absencesDoms = CWRENDERMEDICAL._setAbsenceMedical(filteredAbsences[i], layers, absencesDoms, dtsModel, pk, range, textByAbsGroupe, listeFractionnes, isvueJournee, isTabServInd, isTabServColl);
      }
    }
    if (absencesDoms.hasClass("tabservind_cell") === true) {
      let aux = 0;

      for (let j = 0; j < listeFractionnes.length; j++) {
        if (listeFractionnes[j] > 0 && absencesDoms.find(".planmed_cell_nfraction_" + j).children().hasClass("tabservind-cell-abs") === true) {
          for (let i = aux; i < filteredAbsencesTooltip.length; i++) {
            const absence = dtsModel.ABSENCES.get(filteredAbsencesTooltip[i].get("cod"));
            const dts = filteredAbsencesTooltip[i];
            const cell = ".planmed_cell_nfraction_" + j;

            absence.set("date", absDate);
            absencesDoms.children(cell).children(".tabservind-cell-abs").removeAttr("title");
            absencesDoms.children(cell).children(".tabservind-cell-abs").prop("title", this._buildTooltipAbsence(false, absence, dts, dtsModel));
            aux++;
            break;
          }
        }
      }
    } else if (absencesDoms.hasClass("planmed_cell") === true && isTabServColl === false) {
      let aux = 0;
      for (let j = 0; j < listeFractionnes.length; j++) {
        let cellMed = ".cellMed-fract-" + j;

        if (listeFractionnes[j] > 0 && absencesDoms.find(".planmed_cell_nfraction_" + j).children(cellMed).hasClass("phx-dynamicStyle-cell-abs") === true) {
          for (let i = aux; i < filteredAbsencesTooltip.length; i++) {
            const absence = dtsModel.ABSENCES.get(filteredAbsencesTooltip[i].get("cod"));
            const dts = filteredAbsencesTooltip[i];
            const cell = ".planmed_cell_nfraction_" + j;

            cellMed = ".cellMed-fract-" + j;
            absence.set("date", absDate);
            absencesDoms.children(cell).children(cellMed).removeAttr("title");
            absencesDoms.children(cell).children(cellMed).prop("title", this._buildTooltipAbsence(false, absence, dts, dtsModel));
            aux++;
            break;
          }
        }
      }
    }
    return absencesDoms;
  }

  /**
   * Function that generate the HTML structure of a Pret Reçu information in the cell.
   */
  static _renderPretRecuAffSecMedical(fractionsCell: JQuery, dtsModel: any, listeFractionnes: Array<number>, aff: string): JQuery {
    let pretDoms = fractionsCell;
    const dtsModelAff = aff === "affr" ? dtsModel.affru : dtsModel.affsu;
    // day fraction assignment table for units [M, S, N1, N2]
    const unites: { [key: string]: boolean[] } = {
      "M": [true, false, false, false],
      "S": [false, true, false, false],
      "SN1": [false, true, true, false],
      "SNU": [false, true, true, true],
      "J": [true, true, false, false],
      "K": [true, true, false, false],
      "JN1": [true, true, true, false],
      "N1": [false, false, true, false],
      "N2": [false, false, false, true],
      "N": [false, false, true, true],
      "D": [true, true, true, true]
    };
    // result of the overlap of two units for the same day
    const addUnites = (unite1: boolean[], unite2: boolean[]): boolean[] => {
      for (let _i = 0; _i < unite1.length; _i++) {
        unite1[_i] = unite1[_i] || unite2[_i];
      }
      return unite1;
    };

    if (dtsModelAff) {
      let affrFractions: boolean[] = [false, false, false, false];
      // for several prets in a day sumatory of unites
      _.each(dtsModelAff, (affru: string) => {
        if (affru in unites) {
          affrFractions = addUnites(affrFractions, unites[affru]);
        }
      });
      pretDoms = CWRENDERMEDICAL._setPretRecuAffSecMedical(pretDoms, affrFractions, dtsModel, listeFractionnes, aff);
    }

    return pretDoms;
  }

  /**
  * Set fractions as pret reçu if position in array is true
  */
  static _setPretRecuAffSecMedical(fractionsCell: JQuery, affrFractions: boolean[], dtsModel: any, listeFractionnes: Array<number>, aff: string): JQuery {
    const targetDom = fractionsCell; //DOM structure
    const lPosFract: any = [];
    let lUniteM, lUniteS, lUniteN1, lUniteN2;

    if (affrFractions[0] && targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0")) {
      lUniteM = targetDom.find(".planmed_cell_nfraction_0 .cellMed-fract-0");
      lPosFract.push(lUniteM);
    }
    if (affrFractions[1] && targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1")) {
      lUniteS = targetDom.find(".planmed_cell_nfraction_1 .cellMed-fract-1");
      lPosFract.push(lUniteS);
    }
    if (affrFractions[2] && targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2")) {
      lUniteN1 = targetDom.find(".planmed_cell_nfraction_2 .cellMed-fract-2");
      lPosFract.push(lUniteN1);
    }
    if (affrFractions[3] && targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3")) {
      lUniteN2 = targetDom.find(".planmed_cell_nfraction_3 .cellMed-fract-3");
      lPosFract.push(lUniteN2);
    }

    if (lPosFract.length > 0) {
      for (let i = 0, l = lPosFract.length; i < l; i++) {
        if (lPosFract[i]) {
          if (aff === "affr") {
            lPosFract[i].parent().addClass("phx-cell-recu");
          } else {
            lPosFract[i].addClass("phx-cell-affs");
          }
        }
      }
    }

    return targetDom;
  }

  static fusionFractionsMedical(fractionsCell: JQuery, listFusion: Array<number>, listeFractionnes: Array<number>): JQuery {
    const targetDom = fractionsCell; //DOM structure

    if (!CWSTR.isBlank(listFusion) && listFusion.length > 1 && !CWSTR.isBlank(fractionsCell) && !CWSTR.isBlank(listeFractionnes)) {
      const lPos = targetDom.find(".planmed_cell_nfraction_" + listFusion[0]);
      let lIdFrac = "";
      const lNbfractions = !CWSTR.isBlank(listeFractionnes) ? listeFractionnes.length : 0;
      let lWidth = (!CWSTR.isBlank(lNbfractions) && lNbfractions !== 0) ? (100 / lNbfractions) : 100;
      const lCssBorder = {
        "border-right-color": "#F2F2F2",
        "border-right-style": "solid",
        "border-right-width": "1px",
        "box-sizing": "border-box"
      };

      //update listeFractionnes
      listeFractionnes[listFusion[0]] = listFusion.length;
      for (let i = 1; i < listFusion.length; i++) {
        targetDom.find(".planmed_cell_nfraction_" + listFusion[i]).css({ "display": "none" });
        //targetDom.find(".planmed_cell_nfraction_" + listFusion[i]).attr("data-cell-paint", "1");
        targetDom.find(".planmed_cell_nfraction_" + listFusion[i] + " .cellMed-fract-" + listFusion[i]).attr("data-cell-paint", "1");
        //update listeFractionnes
        listeFractionnes[listFusion[i]] = 0;
      }
      if (lNbfractions - 1 > 0) {
        lWidth = lWidth * listFusion.length;
        if (listFusion.indexOf((lNbfractions - 1)) >= 0) {
          if (lPos.hasClass("ui-gtmed-service-jour")) {
            lPos.removeClass("ui-gtmed-service-jour");
          }
          if (lPos.hasClass("ui-gtmed-service-gardes-et-astreintes-n1")) {
            lPos.removeClass("ui-gtmed-service-gardes-et-astreintes-n1");
          }
        } else {
          lPos.css(lCssBorder);
        }
        lPos.css({ "width": lWidth + "%" });
        //structure "data-idFract": pos,len,nbfrac (par exemple: "1,1,4")
        lIdFrac = (listFusion[0] + 1) + "," + listFusion.length + "," + lNbfractions;
        lPos.attr("data-idFract", lIdFrac);
        lPos.find(".cellMed-fract-" + listFusion[0]).attr("data-cell-paint", "1");
      }
    }
    return targetDom;
  }

  /**
   * Function that generate the HTML structure of one Absence information for the Absences Render.
   */
  static _setAbsenceMedical(event: any, layers: Backbone.Model, fractionsCell: JQuery, dtsModel: any, pk: string,
    range: string, textByAbsGroupe: boolean, listeFractionnes: Array<number>, isvueJournee: boolean,
    isTabServInd: boolean, isTabServColl: boolean): JQuery {
    const unite = event.get("val");
    const sta = event.get("dts").sta; // Char
    const periode = event.get("dts").per; //Periode
    const absJSON = dtsModel.ABSENCES.get(event.get("cod"));
    let signClass;
    const abUid = event.get("uid"); //Absence uid
    const targetDom = fractionsCell; //DOM structure

    // view mode M is used only by Agenda view Annuel.
    // if we are in agenda - view annuel - we dont show the indicateur "s". souhait.
    // cannot change CSS because is used in other parts of app.
    //Deleted: Customer#188188 request
    /*if(viewMode === "M" && usecase === "agenda"){
      showIndicateurSou = false;
    }*/
    if (unite !== "H") {
      let lPosFract: any = [];

      switch (unite) {
        case "M": {
          const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, null, dtsModel);

          if (!CWSTR.isBlank(lUniteM)) {
            lPosFract.push(lUniteM);
          }
        }
          break;
        case "S":
          listeFractionnes = [0, 1, 0, 0];
          lPosFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, null, dtsModel, periode);
          break;
        case "K": //Matin+Après Midi
          lPosFract = CWRENDERMEDICAL._uniteJ(listeFractionnes, targetDom, null, dtsModel);
          break;
        case "N":
          lPosFract = CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, null, dtsModel);
          break;
        case "J":
          // Unite "J" in abcense is unite "D" in activites, day complete
          lPosFract = CWRENDERMEDICAL._uniteD(listeFractionnes, targetDom, null, dtsModel);
          break;
        default:
          //on ne fait rien
          return targetDom;
      }
      if (lPosFract.length > 0) {

        const TSAbs = (!CWSTR.isBlank(CWDIVERS.get("GME_TS_Abs"))) ? CWDIVERS.get("GME_TS_Abs") : "0";
        const connectedUser = objs.appRt.workflow.authModel.get("matricule");
        const eventUser = pk.split(",")[0];

        for (let i = 0, l = lPosFract.length; i < l; i++) {
          if (lPosFract[i]) {
            let cod = "";

            if (isTabServInd) {
              // Class to show label when truncated in TSIND
              lPosFract[i].addClass("tabservind-cell-abs");
            }

            lPosFract[i].attr("abs-data-uid", abUid);

            if (isTabServColl && TSAbs === "1" && eventUser !== connectedUser) {
              lPosFract[i].addClass("ui-gtmed-service-abs-ts_abs");
            } else {
              //CLASS STYLE
              if (!CWSTR.isBlank(absJSON) && !CWSTR.isBlank(absJSON.get("group"))) {
                cod = absJSON.get("group").get("cod");
                lPosFract[i].attr("abs-data-code", cod);
              }
              lPosFract[i].addClass("phx-dynamicStyle-cell-abs phx-dynamicStyle-cell-abs_" + UTILS.escapeJQueryString(cod));
              //sta
              if (sta === "D" || sta === "I" || sta === "T") {
                //Deleted: Customer#188188 request
                /*if(showIndicateurSou){*/
                signClass = "phx-dynamicStyle-cell-absaval";
                /*}*/
                lPosFract[i].addClass("phx-cell-abs-aval"); //Class for options D'affichage
              }
              // only if has value
              if (!CWSTR.isBlank(signClass)) {
                lPosFract[i].addClass(signClass);
              }
              if (unite === "M" || unite === "S" || unite === "J" || unite === "N" || unite === "K") {
                let text = "";

                //If has symbols don't has label
                if (!CWSTR.isBlank(absJSON)) {
                  if (textByAbsGroupe !== true) {
                    if (lPosFract[i].parent().parent().hasClass("tabservind_cell") === true) {
                      text = absJSON.get("lib");
                    } else {
                      if (listeFractionnes[i] > 0) {
                        text = absJSON.get("cod").substring(0, listeFractionnes[i] * 2);
                      } else {
                        text = absJSON.get("cod").substring(0, 2);
                      }
                    }
                  } else {
                    const grp = absJSON.get("grp");
                    const abrc = dtsModel.GROUPS_ABSENCE.get(grp).get("lib");

                    text = !CWSTR.isBlank(abrc) ? abrc : "**";
                  }
                }
                lPosFract[i].text(text);
                if (isvueJournee === true) {
                  const lTmpDiv = $("<div class='plmed-inVuejournee'>");

                  text += " - " + absJSON.get("lib");
                  if (!CWSTR.isBlank(signClass)) {
                    text = "? " + text;
                  }
                  lTmpDiv.html(text);
                  lPosFract[i].text("");
                  lPosFract[i].append(lTmpDiv);
                }
              }
            }


            if (periode === true && layers.get("abs_per") && lPosFract.length > 0) {
              //ICON Période
              CWRENDERMEDICAL._managePeriodeAbsPlanningMedical(pk, event, range, lPosFract[i], unite, listeFractionnes);
            }
          }
        }
      }
    }
    return targetDom;
  }

  static _checkPeriodesMedical(debUnit: string, finUnit: string): boolean {
    let lRtn = false;

    /*UNITES
      M: MATIN
      S: SOIR
      N1: NUIT 1
      N2: NUIT 2
      N: NUIT (NUIT 1 ET NUIT 2)
      J: JOUR (MATIN ET SOIR)
      D: DAY COMPLETE (MATIN, SOIR, NUIT 1 ET NUIT 2) OU (JOUR ET NUIT) OU RESTE DE COMBINAISONS
    ORIGINE : S,D,N et N2
    DESTINATION : M,J,N1 et D

    */
    if ((debUnit === "S" || debUnit === "J" || debUnit === "D" || debUnit === "N" || debUnit === "N2") &&
      (finUnit === "M" || finUnit === "J" || finUnit === "N1" || finUnit === "D" || finUnit === "K")) {
      lRtn = true;
    }
    return lRtn;
  }

  /**
   * Function that generate the HTMLicon for periode absences in planning medical
   * **/
  static _managePeriodeAbsPlanningMedical(pk: string, event: any, range: string, targetDom: JQuery, unite: string, listeFractionnes: Array<number>): void {
    const periodeIconClass = 'ui-phx-icon-absperiode-m';
    const currentDate = parseInt(pk.split(",")[1], 10);
    const perDebDate = parseInt(event.get("dts").deb, 10);
    const perFinDate = parseInt(event.get("dts").fin, 10);
    const perDebUnit = event.get("dts").ude;
    const perFinUnit = event.get("dts").ufi;
    const firstRangeDate = range.split(",")[0];
    const lastRangeDate = range.split(",")[1];
    const lNbfractions = listeFractionnes.length;
    let lbRight = false;

    if (!CWSTR.isBlank(lNbfractions) && lNbfractions > 0) {
      for (let i = 0; i < lNbfractions && !lbRight; i++) {
        if (targetDom.hasClass("cellMed-fract-" + i) && listeFractionnes[i] === (lNbfractions - i)) {
          lbRight = true;
        }
      }
      if (targetDom.parent().parent().hasClass("tabservind_cell") === false) {
        //Normal Case
        if (currentDate < perFinDate && CWRENDERMEDICAL._checkPeriodesMedical(perDebUnit, perFinUnit)) {
          if (currentDate !== perDebDate && targetDom.hasClass("cellMed-fract-0")) {
            targetDom.prepend('<span class="phx-icon ui-phx-icon-absperiode-avant ' + periodeIconClass + '"></span>');
          }
          if (lbRight) {
            if (String(currentDate) === lastRangeDate) {
              targetDom.prepend('<span class="phx-icon ui-phx-icon-absperiode-apres-end ' + periodeIconClass + '"></span>');
            } else {
              targetDom.prepend('<span class="phx-icon ui-phx-icon-absperiode-apres  ' + periodeIconClass + '"></span>');
            }
          }
        } else if (currentDate === perFinDate && CWRENDERMEDICAL._checkPeriodesMedical(perDebUnit, perFinUnit) && targetDom.hasClass("cellMed-fract-0")) {
          targetDom.prepend('<span class="phx-icon ui-phx-icon-absperiode-avant ' + periodeIconClass + '"></span>');
        }
        // //First Case (on the border of the planning)
        //if ((String(currentDate) === firstRangeDate) && (unite !== "S" || unite !== "N" || unite !== "N1") && targetDom.hasClass("cellMed-fract-0")) {
        if ((String(currentDate) === firstRangeDate) && (unite !== "S" && unite !== "N" && unite !== "N1") && targetDom.hasClass("cellMed-fract-0")) {
          if (currentDate > perDebDate && currentDate <= perFinDate) {
            targetDom.prepend('<span class="phx-icon ui-phx-icon-absperiode-avant ' + periodeIconClass + '"></span>');
          }
        }
      }
    }
  }

  /**
   * Function that generate the HTML structure of the Activities Medical information for the cell.
   */

  static _renderActivitiesMedical(layers: Backbone.Model, fractionsCell: JQuery, dtsModel: any, activitiesFilters: Backbone.Collection,
    alisteFractionnes: Array<number>, activities: Backbone.Collection, isvueJournee: boolean, options: { [key: string]: any }): JQuery {
    let activitiesDoms = fractionsCell;
    const evts: any = [];
    const isTabServColl = options ? options.isTabServColl : false;
    let found;
    // const byTdo = activities.groupBy((model: Backbone.Model): any => {
    //   return model.get("dts").tdo;
    // });    
    // let sizeACT = 0;
    // let sizeASTR = 0;

    // // If there are Activities, count them.
    // if (byTdo["ACT"]) {
    //   const typeActs = _.countBy(byTdo["ACT"], (item: Backbone.Model): any => {
    //     return item.get("dts").typ;
    //   });

    //   if (typeActs.P > 0)
    //     sizeACT++;
    //   if (typeActs.R > 0)
    //     sizeACT++;
    // }
    // // If there are Astreintes, count them.
    // if (byTdo["ASTR"]) {
    //   const typeAsts = _.countBy(byTdo["ASTR"], (item: Backbone.Model): any => {
    //     return item.get("dts").typ;
    //   });

    //   if (typeAsts.P > 0)
    //     sizeASTR++;
    //   if (typeAsts.R > 0)
    //     sizeASTR++;
    // }
    if (activities) {
      for (let i = 0; i < activities.length; i++) {
        found = false;
        for (let j = 0; !found && j < evts.length; j++) {
          if (activities.at(i).get("dts").chevnb === evts[j].acts[0].get("dts").chevnb &&
            activities.at(i).get("dts").chevnb > 1 &&
            activities.at(i).get("dts").chevhed === evts[j].acts[0].get("dts").chevhed &&
            activities.at(i).get("dts").chevhef === evts[j].acts[0].get("dts").chevhef &&
            activities.at(i).get("dts").typ === evts[j].acts[0].get("dts").typ &&
            activities.at(i).get("dts").tdo === evts[j].acts[0].get("dts").tdo) {
            evts[j].acts.push(activities.at(i));
            found = true;
          }
        }
        if (!found) {
          const acts: any = [];

          acts.push(activities.at(i));
          evts.push({ acts: acts });
        }

      }
    }
    for (let i = 0, size = evts.length; i < size; i++) {
      const dts = evts[i].acts[0].get("dts");
      let canRender = true;
      const lType = (activities.at(i).get("dts").typ === "P") ? "prevu" : "realise";//Preferences

      if (layers.get("act") !== true) {
        canRender = false;
      }
      if (!activitiesFilters.get(activities.at(i).get("dts").tpo).get(lType)) {
        canRender = false;
      }
      if (canRender) {
        const activite = dtsModel.ACTIVITES.get(activities.at(i).get("cod"));
        const fam = dtsModel.FAM_ACTIVITE.get(activities.at(i).get("dts").fam);
        let typeGestionActivite = null;
        let libTypologie = "";

        if (activitiesFilters && activities.at(i) && activities.at(i).get("dts") && activities.at(i).get("dts").tpo && activitiesFilters.get(activities.at(i).get("dts").tpo)) {
          const infoActFilter = activitiesFilters.get(activities.at(i).get("dts").tpo);

          typeGestionActivite = infoActFilter.get("typeGestionActivite");
          libTypologie = infoActFilter.get("libTypologie");
        }
        if (activite) {
          activitiesDoms = CWRENDERMEDICAL._renderActiviteMedical(dts, fam, activitiesDoms, activite, alisteFractionnes, typeGestionActivite, isvueJournee, options.listeAnnuleCouv, libTypologie, dtsModel.AUX_UNI.get(dts.uni));
        }
      }
    }
    if (activitiesDoms.hasClass("tabservind_cell") === true || dtsModel.module === "tabservind" || isTabServColl) {
      let k = 0;
      for (let j = 0; j < alisteFractionnes.length; j++) {
        if (activitiesDoms.find(".planmed_cell_nfraction_" + j).children().hasClass("phx-cell-actInterieures") === true && evts[k]) {
          const dts = evts[k].acts[0].get("dts");
          const activite = dtsModel.ACTIVITES.get(activities.at(k).get("cod"));
          const cell = ".planmed_cell_nfraction_" + j;
          const cellMed = ".cellMed-fract-" + j;

          activitiesDoms.children(cell).children(cellMed).removeAttr("title");
          if (isTabServColl && !CWSTR.isBlank(dts.emp)) {
            activitiesDoms.children(cell).children(cellMed).prop("title", this._buildTooltipActiviteEmplacement(activite, dts, dtsModel));
          } else if (!isTabServColl) {
            activitiesDoms.children(cell).children(cellMed).prop("title", this._buildTooltipActivite(false, activite, dts, dtsModel));
          }
          k++;
        }
      }
    }
    return activitiesDoms;
  }

  /**
   * Function to check if indicateur primaire is going to be shown or not
   */
  static _checkIndicateurPrimaireMedical(indToRender: { [key: string]: any }, typeIndSecon: number, indicSecondaire: string, indicateurs: { [key: string]: any }, activitiesFilters: Backbone.Collection, viewMode: string): boolean {
    let primaire = true;
    const lFuncComprobation = (differentType: boolean): boolean => {
      let lRtn = differentType;
      let numberOfIndicateurs = 0;

      if (indToRender.val && indicateurs.val) {
        numberOfIndicateurs++;
      }
      if (indToRender.valb && indicateurs.valb) {
        numberOfIndicateurs++;
      }
      if (indToRender.abs && indicateurs.abs) {
        numberOfIndicateurs++;
      }
      if (indToRender.reg && indicateurs.reg) {
        numberOfIndicateurs++;
      }
      if (indToRender.astreg && indicateurs.astreg) {
        numberOfIndicateurs++;
      }
      if (indToRender.mem && indicateurs.mem) {
        numberOfIndicateurs++;
      }
      if (indToRender.mes && indicateurs.mes) {
        numberOfIndicateurs++;
      }
      if (indToRender.rptn && indicateurs.rptn) {
        numberOfIndicateurs++;
      }
      if (indToRender.rpen && indicateurs.rpen) {
        numberOfIndicateurs++;
      }
      if (indToRender.pret && indicateurs.pretd) {
        numberOfIndicateurs++;
      }
      if (indToRender.prpn && indicateurs.prpn) {
        numberOfIndicateurs++;
      }
      if (indToRender.pren && indicateurs.pren) {
        numberOfIndicateurs++;
      }
      if (indToRender.affsec && indicateurs.affsec) {
        numberOfIndicateurs++;
      }
      //There is going to be shown an indicateur secondaire
      if (CWRENDERAGENTS._checkIndicateurSecondaire(indToRender, typeIndSecon, indicSecondaire, indicateurs, activitiesFilters, viewMode)) {
        //Reinitialisation du valeur pour retourner (s'il y a de valeurs, on n'a pas en compte "valeur")
        if (numberOfIndicateurs > 0) {
          lRtn = true;
        }
        if (numberOfIndicateurs < 2) {
          //When numberOfIndicateurs>= 2 it is not necessary to check typologies, so we don't do it, in order to optimize behaviour
          //Typologies d'activite
          const result = CWRENDERAGENTS._checkInfoTypologies(indicSecondaire, indicateurs, activitiesFilters, viewMode);

          numberOfIndicateurs = numberOfIndicateurs + result.numberOfIndicateurs;
        }
        if (numberOfIndicateurs <= 1 || (numberOfIndicateurs >= 2 && differentType === false)) {
          //If there is only 1 indicateur to be shown and it is an indicateur secondaire,
          //don't show indicateur primaire
          lRtn = false;
        }
      } else if (lRtn === false && numberOfIndicateurs > 0) {
        lRtn = true;
      } else {
        const lKeys = _.keys(indicateurs);

        if (numberOfIndicateurs === 0 && lKeys.length > 0 && lKeys.indexOf("ap") < 0 && lKeys.indexOf("ar") < 0) {
          lRtn = false;
        }
      }
      return lRtn;
    };

    if (CWRENDERMEDICAL._checkIndicateurDeplacementMedical(indicSecondaire, indicateurs, activitiesFilters, viewMode)) {
      const typologiesDeplacementArray = _.reduce(activitiesFilters.models, (acum: Array<any>, typologie: Backbone.Model): Array<any> => {
        if (typologie.get("deplacement") === true) {
          return acum.concat(typologie.get("code"));
        } else {
          return acum;
        }
      }, []);
      const lFind = _.filter(indicateurs.typo, (item: { [key: string]: any }): boolean => {
        return typologiesDeplacementArray.indexOf(item.cod) === -1;
      });

      //si tous les éléments sont déplacements, la valeur de "primaire" sera false
      if (CWSTR.isBlank(lFind) && lFind.length === 0) {
        primaire = lFuncComprobation(false);
      }
    } else {
      primaire = lFuncComprobation(true);
    }
    return primaire;
  }

  static _renderIndicateursMedical(layers: Backbone.Model, aFractionsCell: JQuery, aListeFractionnes: Array<number>,
    indToRender: { [key: string]: any }, indicateurs: { [key: string]: any }, evtModel: any, dtsModel: any, i18n: any,
    model: any, pk: string, aOptions: { [key: string]: any }): JQuery {
    let absTooltip = ""; //Icon tooltip
    let absences = null;
    let first = true;
    let indPrimaire = false;
    const lPosIndicateur: any = [];
    const lListeIndicateursDom: any = [];
    let lListeIndicateurs: any = [];
    let pretFractions: any = [];
    let affSecFractions: any = [];
    const lNfract = (aListeFractionnes) ? aListeFractionnes.length : 0;
    let lCont = 0;
    let lGroupIndicateurs: any = [];
    let lPosInd = null;
    let lKeys: any = [];
    let indPretAffSecToRender: any = {};
    const isVueJourneeAffPret = (aOptions.lIsvueJournee === true);// aFractionsCell.find(".cellMed-fract-0").children().length > 0;
    let affsecFlag = false;
    let indPretAffSecFlag = false;

    //INDICATEURS
    if (layers.get("ind")) { //2
      indToRender = {};
      indToRender.act = this.existIndicateurComplementaireMedicalActivites(indToRender, layers, indicateurs, aOptions.activitiesFilters, "S");
      indToRender.val = (indicateurs.val && layers.get("ind_ano"));
      indToRender.valb = (indicateurs.valb && layers.get("ind_ano"));
      indToRender.abs = (indicateurs.abs && layers.get("ind_abs"));
      indToRender.absv = (indicateurs.absv && layers.get("ind_abs"));
      indToRender.absav = (indicateurs.absav && layers.get("ind_abs"));
      indToRender.reg = (indicateurs.reg && layers.get("ind_reg"));
      indToRender.regav = (indicateurs.regav && layers.get("ind_reg"));
      indToRender.astreg = (indicateurs.astreg && layers.get("ind_astreg"));
      indToRender.mem = (indicateurs.mem && layers.get("ind_mem"));
      indToRender.mes = (indicateurs.mes && layers.get("ind_mes"));
      indToRender.pret = (indicateurs.pretd && layers.get("ind_pret"));
      indToRender.rptn = false;
      indToRender.rpen = false;
      indToRender.pren = false;
      indToRender.prpn = false;
      indToRender.affsec = false;
      indPretAffSecToRender = {};
      indPretAffSecToRender.affsec = (indicateurs.affsec && layers.get("ind_affs"));
      indPretAffSecToRender.rptn = (indicateurs.rptn && layers.get("ind_repla"));
      indPretAffSecToRender.rpen = (indicateurs.rpen && layers.get("ind_repla"));
      indPretAffSecToRender.pren = (indicateurs.pren && layers.get("ind_repla"));
      indPretAffSecToRender.prpn = (indicateurs.prpn && layers.get("ind_repla"));
      if (indToRender.val || indToRender.valb || indToRender.abs || indToRender.absv || indToRender.astreg || indToRender.absav ||
        indToRender.reg || indToRender.regav || indToRender.mem || indToRender.mes || indToRender.bad ||
        indToRender.pret) {
        indPrimaire = true;
        if (evtModel.hasEvent("ABSENCES", pk, aOptions.idlg) === true) {
          absences = evtModel.getEvent("ABSENCES", pk, aOptions.idlg).get("evt").models;
          for (let i = 0; i < absences.length; i++) {
            if (absences[i].get("dts").sou === false && absences[i].get("val") === "H" && !CWSTR.isBlank(absences[i].get("dts").hed) && !CWSTR.isBlank(absences[i].get("dts").hef)) {
              let absLibelle = i18n.t('common:utils.absence');
              let auxtooltip = null;

              if (first === true) {
                first = false;
              } else {
                absTooltip += "<br/>";
              }
              if (!CWSTR.isBlank(dtsModel.ABSENCES.get(absences[i].get("cod")))) {
                absLibelle = dtsModel.ABSENCES.get(absences[i].get("cod")).get("lib");
              }
              auxtooltip = i18n.t('common:utils.abscomp', {
                "0": absLibelle + "<label>",
                "1": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hed, CWTYPE._getFormatByCode("DUREEHM")).trim() + "<label>",
                "2": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hef, CWTYPE._getFormatByCode("DUREEHM")).trim(),
                interpolation: { escapeValue: false }
              });
              absTooltip += auxtooltip;
            }
          }
        }
      }
    }
    if (aListeFractionnes && aListeFractionnes.length > 0) {
      for (let i = 0; i < aListeFractionnes.length; i++) {
        lListeIndicateursDom[i] = null;
        lListeIndicateurs[i] = {}; //initialise
      }
    }
    lCont = 0;
    for (let i = 0; i < aListeFractionnes.length; i++) {
      if (aListeFractionnes[i] !== 0) {
        lPosIndicateur[i] = i; //i + a_listeFractionnes[i] - 1;
        lCont = lPosIndicateur[i];
      } else {
        lPosIndicateur[i] = lCont;
      }
    }

    lGroupIndicateurs = CWRENDERMEDICAL._groupIndicateursMedical(indicateurs);
    lPosInd = null;
    lKeys = _.keys(lGroupIndicateurs)
    for (let m = 0; m < lKeys.length; m++) {
      switch (lKeys[m]) {
        case "absence":
          if (indToRender.abs || indToRender.absv || indToRender.absav) {
            lListeIndicateurs = CWRENDERMEDICAL._indicateurAbsenceMedical(lGroupIndicateurs[lKeys[m]], lListeIndicateurs, lNfract, aOptions.fractionsMedical, lPosIndicateur);
          }
          break;
        case "activite":
          lListeIndicateurs = CWRENDERMEDICAL._indicateurActiviteMedical(lGroupIndicateurs[lKeys[m]], lListeIndicateurs, lNfract, aOptions.fractionsMedical, lPosIndicateur);
          //par exemple pour les "Déplacements" qui sont de type
          break;
        case "deplacement":
          break;
        default: //"autres"
          if (isVueJourneeAffPret && (lKeys[m] === "pret" || lKeys[m] === "affsec")) {
            if (lKeys[m] === "pret") {
              const lIndPret = CWRENDERMEDICAL._indicateurPretMedicalVueJournee(lGroupIndicateurs[lKeys[m]], aListeFractionnes, lListeIndicateurs,);

              lListeIndicateurs = lIndPret.listeIndicateurs;
              pretFractions = lIndPret.pretFractions;
            } else {
              const lIndAffSec = CWRENDERMEDICAL._indicateurAffSecMedicalVueJournee(lGroupIndicateurs[lKeys[m]], aListeFractionnes, lListeIndicateurs);

              lListeIndicateurs = lIndAffSec.listeIndicateurs;
              affSecFractions = lIndAffSec.affSecFractions;
            }
          } else {

            lPosInd = lPosIndicateur[lNfract - 1]; //affecter la fractionne au indicateurDom
            if (!_.isEmpty(lListeIndicateurs[lPosInd])) {
              lListeIndicateurs[lPosInd] = _.extend({}, lListeIndicateurs[lPosInd], lGroupIndicateurs[lKeys[m]]);
            } else {
              lListeIndicateurs[lPosInd] = lGroupIndicateurs[lKeys[m]];
            }
          }
      }
    }
    for (let i = 0; i < lListeIndicateurs.length; i++) {
      const idx = i;

      if (!_.isEmpty(lListeIndicateurs[i]) || (pretFractions.length > 0 && pretFractions[i] === true) || (affSecFractions.length > 0 && affSecFractions[i] === true)) {
        let paintIndicator = false;
        let indicateurMedicalPretsAff: JQuery = null;
        let indicateurMedical: JQuery = null;
        let iKey = null;

        lListeIndicateursDom[lPosIndicateur[i]] = $("<div class='phx-cell-render-ind'/>").attr("data-fractIndicateur", i);
        if (i === (lNfract - 1)) {
          //Add class for Affichage Options
          if (indToRender.regav) {
            lListeIndicateursDom[lPosIndicateur[i]].addClass("phx-cell-ind-regaval");
          }
          if (indToRender.astreg) {
            lListeIndicateursDom[lPosIndicateur[i]].addClass("phx-cell-ind-astregav");
          }
        }
        if (!CWSTR.isBlank(lListeIndicateurs[i].absav)) {
          //Add class for Affichage Options
          lListeIndicateursDom[lPosIndicateur[i]].addClass("phx-cell-ind-absaval");
        }
        if (!CWSTR.isBlank(lListeIndicateurs[i].apav) || !CWSTR.isBlank(lListeIndicateurs[i].apzav)) {
          lListeIndicateursDom[lPosIndicateur[i]].addClass("phx-cell-act-prev-ind-val");
        }
        if (!CWSTR.isBlank(lListeIndicateurs[i].arav) || !CWSTR.isBlank(lListeIndicateurs[i].arzav)) {
          lListeIndicateursDom[lPosIndicateur[i]].addClass("phx-cell-act-rel-ind-val");
        }
        if (indPretAffSecToRender.affsec && lGroupIndicateurs["affsec"].affsec && !CWSTR.isBlank(lListeIndicateurs[i].affsec) && isVueJourneeAffPret === false) {
          affsecFlag = true;
        }
        if (indPretAffSecToRender.affsec && lGroupIndicateurs["affsec"].affsec && isVueJourneeAffPret === true) {
          affsecFlag = true;
        }
        iKey = Object.keys(lListeIndicateurs[idx]);
        if (CWSTR.isUndefined(indPretAffSecToRender.rptn) || CWSTR.isBlank(indPretAffSecToRender.rptn)) {
          indPretAffSecToRender.rptn = false;
        }
        if (CWSTR.isUndefined(indPretAffSecToRender.rpen) || CWSTR.isBlank(indPretAffSecToRender.rpen)) {
          indPretAffSecToRender.rpen = false;
        }
        if (CWSTR.isUndefined(indPretAffSecToRender.pren) || CWSTR.isBlank(indPretAffSecToRender.pren)) {
          indPretAffSecToRender.pren = false;
        }
        if (CWSTR.isUndefined(indPretAffSecToRender.prpn) || CWSTR.isBlank(indPretAffSecToRender.prpn)) {
          indPretAffSecToRender.prpn = false;
        }
        // Displays pretaffsec indicator
        if (affsecFlag === true ||
          indPretAffSecToRender.rptn && lGroupIndicateurs["pret"].rptn && iKey.indexOf("rptn") >= 0 ||
          indPretAffSecToRender.rpen && lGroupIndicateurs["pret"].rpen && iKey.indexOf("rpen") >= 0 ||
          indPretAffSecToRender.pren && lGroupIndicateurs["pret"].pren && iKey.indexOf("pren") >= 0 ||
          indPretAffSecToRender.prpn && lGroupIndicateurs["pret"].prpn && iKey.indexOf("prpn") >= 0 ||
          indPretAffSecToRender.rptn && lGroupIndicateurs["pret"].prtd && iKey.indexOf("prtd") >= 0) {
          let viewModeAff = "";
          let indPretAffSecToRenderCopy = null;
          let options: { [key: string]: any } = {};

          indPretAffSecFlag = true;
          if (aOptions.viewMode === "S") {
            viewModeAff = "SCMED";
          } else if (aOptions.viewMode === "M") {
            viewModeAff = "MCMED";
          }
          if (aOptions.lbIsTabServInd) {
            viewModeAff = "S"
          }
          indicateurMedicalPretsAff = CWRENDERAGENTS.renderIndicateurPretsAffSec(viewModeAff, aOptions.pretsAffSecBordClass, aOptions.pretsAffSecBackClass, aOptions.anim3).addClass("ui-phx-pretaffsec-icon-in-tooltip");
          options = {
            indPrimaire: false,
            indSecondaireFlag: false,
            indPretsAffSec: true,
            lbIsTabServInd: aOptions.lbIsTabServInd,
            pretsAffSecBordClass: aOptions.pretsAffSecBordClass,
            pretsAffSecBackClass: aOptions.pretsAffSecBackClass
          }
          if (!isVueJourneeAffPret) {
            if (pretFractions.length > 0 && pretFractions[i] === true) {
              indPretAffSecToRenderCopy = _.extend({}, indPretAffSecToRender);
              indPretAffSecToRenderCopy.affsec = false;
            }
            if (affSecFractions.length > 0 && affSecFractions[i] === true) {
              indPretAffSecToRenderCopy = _.extend({}, indPretAffSecToRender);
              indPretAffSecToRenderCopy.prpn = false;
            }
          }
          else {
            const lKeysInd = _.keys(lListeIndicateurs[i]);

            indPretAffSecToRenderCopy = {
              "affsec": (lKeysInd.indexOf("affsec") >= 0),
              "pren": (lKeysInd.indexOf("pren") >= 0),
              "prpn": (lKeysInd.indexOf("prpn") >= 0),
              "rpen": (lKeysInd.indexOf("rpen") >= 0),
              "rptn": (lKeysInd.indexOf("rptn") >= 0)
            };
          }
          CWRENDERAGENTS._renderIndicateursNewTooltip(dtsModel, !CWSTR.isBlank(indPretAffSecToRenderCopy) ? indPretAffSecToRenderCopy : indPretAffSecToRender, pk, model, indicateurs, indicateurMedicalPretsAff, options);
          if ((isVueJourneeAffPret === false && i === lPosIndicateur[i]) || (isVueJourneeAffPret === true && pretFractions.length > 0 && pretFractions[i] === true) || (isVueJourneeAffPret === true && affSecFractions.length > 0 && affSecFractions[i] === true)) {
            lListeIndicateursDom[lPosIndicateur[i]].prepend(indicateurMedicalPretsAff);
          }
        }
        if (!_.isEmpty(lListeIndicateurs[i])) {
          //If list of indicators match with layers of indicators not affsec or pret 
          for (let j = 0; j < _.keys(indToRender).length; j++) {
            if (indToRender[_.keys(indToRender)[j]] && _.keys(lListeIndicateurs[i]).indexOf(_.keys(indToRender)[j]) !== (-1)) {
              paintIndicator = true;
            }
          }
          // Displays normal indicator
          if (paintIndicator || (CWRENDERMEDICAL._checkIndicateurDeplacementMedical(aOptions.indicSecondaire, lListeIndicateurs[i], aOptions.activitiesFilters, aOptions.viewMode) ||
            (CWRENDERMEDICAL._checkIndicateurPrimaireMedical(indToRender, aOptions.typeIndSecon, aOptions.indicSecondaire, lListeIndicateurs[i], aOptions.activitiesFilters, aOptions.viewMode) && indPrimaire === true) ||
            (CWRENDERAGENTS._checkIndicateurSecondaire(indToRender, aOptions.typeIndSecon, aOptions.indicSecondaire, indicateurs, aOptions.activitiesFilters, aOptions.viewMode) && paintIndicator === true) ||
            this.existIndicateurComplementaireMedicalActivites(indToRender, layers, lListeIndicateurs[i], aOptions.activitiesFilters, "S") === true
          )) {
            indicateurMedical = CWRENDERMEDICAL._indicateurMedical(indToRender, lListeIndicateurs[i], dtsModel, model, pk, absTooltip, lListeIndicateursDom[lPosIndicateur[i]], aOptions);
            lListeIndicateursDom[lPosIndicateur[i]].append(indicateurMedical);
          }
        }
        if (lListeIndicateursDom[lPosIndicateur[i]].children().length > 1 && aOptions.anim3 === true && indPretAffSecFlag === true) {
          lListeIndicateursDom[lPosIndicateur[i]].find(".animationPretsAffSecScaled").addClass("affPretAnim");
        }
      }
    }
    for (let i = 0; i < aListeFractionnes.length; i++) {
      if (!CWSTR.isBlank(lListeIndicateursDom[i])) {
        const lPosFract = aFractionsCell.find(".planmed_cell_nfraction_" + i);

        lPosFract.css("display", "flex");
        lPosFract.append(lListeIndicateursDom[i]);
        if (lListeIndicateursDom[i].children().length > 2) {
          lListeIndicateursDom[i].find(".phx-icon-secondaire-container-m-GTMED").addClass("spec2");
        } else if (lListeIndicateursDom[i].children().length === 1 && indPretAffSecFlag === true) {
          lListeIndicateursDom[i].find(".ui-phx-icon-pretaffsec-indicateur-w.med").addClass("spec3");
        }
      }
    }
    return aFractionsCell;
  }

  /**
   * Function that generate the primaire indicateur in planning Médical
   * */
  static renderIndicateurPrimaireMedical(coleurBorderClass: string, coleurBackgroundClass: string, animate: boolean, view: string): JQuery {
    const $divIndicateur = $("<div>");
    const $animationContainer = $("<span>").addClass("animationContainer").css({
      width: "8px",
      height: "20px",
      display: "inline"
    });
    let rgbColor = null;

    if (!CWSTR.isBlank(coleurBorderClass)) {
      rgbColor = CWRENDERAGENTS._getColorFromClass(coleurBorderClass);
    } else {
      rgbColor = "rgb(0,0,0)"; //Default border color is black
    }
    $divIndicateur.addClass("ui-phx-icon-primary-indicateur-m-GTMED ui-phx-icon-indicateurs-mensuel " + coleurBackgroundClass);
    $divIndicateur.css({ "border": "1px solid " + rgbColor, "display": "inline-block" });
    $animationContainer.append($divIndicateur);
    if (animate) {
      if ($('html').is('.ie9')) { //ie9 does not support animation by css so we have to use jquery animation
        CWRENDERAGENTS._animate($divIndicateur, view, $animationContainer);
      } else {
        $divIndicateur.addClass("animate");
      }
    }
    return $animationContainer;
  }

  /**
   * Function that generate the secondaire indicateur in planning Médical
   * */
  static renderIndicateurSecondaireMedical(coleurBorderClass: string, coleurBackgroundClass: string, animate: boolean, view: string): JQuery {
    const $divIndicateur = $("<div>");
    let rgbColor = null;

    if (!CWSTR.isBlank(coleurBorderClass)) {
      rgbColor = CWRENDERAGENTS._getColorFromClass(coleurBorderClass);
    } else {
      rgbColor = "rgb(0,0,0)"; //Default border color is black
    }
    $divIndicateur.addClass("ui-phx-icon-secondaire-indicateur-m-CTIME-GTMED " + coleurBackgroundClass);
    $divIndicateur.css("border", "1px solid " + rgbColor);
    if (animate) {
      if ($('html').is('.ie9')) { //ie9 does not support animation by css so we have to use jquery animation
        CWRENDERAGENTS._animateRotation($divIndicateur, view);
      } else {
        $divIndicateur.addClass("animationSecondaire");
      }
    }
    return $divIndicateur;
  }

  static heureDebutRangeFractionMedical(heure: number, aFractionsMedical: { [key: string]: any }): number {
    let lRtn = null;

    if (aFractionsMedical && aFractionsMedical.length > 0) {
      const _ran = aFractionsMedical;

      for (let i = 0; i < aFractionsMedical.length; i++) {
        if (heure >= _ran[i].heuredeb && heure < _ran[i].heurefin) {
          lRtn = i;
          break;
        }
      }
    }
    return lRtn;
  }

  static heureFinRangeFractionMedical(heure: number, aFractionsMedical: { [key: string]: any }): number {
    let lRtn = null;

    if (aFractionsMedical && aFractionsMedical.length > 0) {
      const _ran = aFractionsMedical;

      for (let i = 0; i < aFractionsMedical.length; i++) {
        if (heure > 3200) {
          heure = 3230;
        }
        if (heure > _ran[i].heuredeb && heure <= _ran[i].heurefin) {
          lRtn = i;
          break;
        }
      }
    }
    return lRtn;
  }

  //  check if the absence duration for hours
  static checkHeuresMedical(heureDebut: number, heureFin: number, aFractionsMedical: { [key: string]: any }): { [key: string]: any } {
    const out = [];
    let lIni = 1000;
    let lFin = 0;
    let lTmpRange = null;

    lTmpRange = CWRENDERMEDICAL.heureDebutRangeFractionMedical(heureDebut, aFractionsMedical);
    if (lTmpRange === null) {
      out.push((aFractionsMedical.length - 1));//le dernier position s'il y a de problèmes avec la heure
    } else {
      lIni = lTmpRange;
      lTmpRange = CWRENDERMEDICAL.heureFinRangeFractionMedical(heureFin, aFractionsMedical);
      if (lTmpRange === null) {
        out.push((aFractionsMedical.length - 1));//le dernier position s'il y a de problèmes avec la heure
      } else {
        lFin = lTmpRange;
        for (let i = lIni; i <= lFin; i++) {
          out.push(i);
        }
      }
    }
    return out;
  }

  static _groupIndicateursMedical(indicateurs: { [key: string]: any }): any {
    const lGroup: any = [];

    if (!CWSTR.isBlank(indicateurs)) {
      const lList = _.keys(indicateurs);

      _.each(lList, (item: string) => {
        if (item.indexOf("abs") >= 0) {
          if (_.keys(lGroup["absence"]).length === 0) {
            lGroup["absence"] = [];
            lGroup.length++;
          }
          lGroup["absence"][item] = indicateurs[item];
          lGroup["absence"].length++;
        } else if (item.indexOf("ar") >= 0 || item.indexOf("ap") >= 0 || item.indexOf("typo") >= 0) {
          if (_.keys(lGroup["activite"]).length === 0) {
            lGroup["activite"] = [];
            lGroup.length++;
          }
          lGroup["activite"][item] = indicateurs[item];
          lGroup["activite"].length++;
        } else if (item.indexOf("pr") >= 0) {
          if (_.keys(lGroup["pret"]).length === 0) {
            lGroup["pret"] = [];
            lGroup.length++;
          }
          lGroup["pret"][item] = indicateurs[item];
          lGroup["pret"].length++;
        } else if (item.indexOf("aff") >= 0) {
          if (_.keys(lGroup["affsec"]).length === 0) {
            lGroup["affsec"] = [];
            lGroup.length++;
          }
          lGroup["affsec"][item] = indicateurs[item];
          lGroup["affsec"].length++;
        }
        //  else if (item.indexOf("dr") >= 0 || item.indexOf("dp") >= 0) {
        //   if (_.keys(l_group["deplacement"]).length === 0) {
        //     l_group["deplacement"] = [];
        //     l_group.length++;
        //   }
        //   l_group["deplacement"][item] = indicateurs[item];
        //   l_group["deplacement"].length++;
        // }
        else {
          if (_.keys(lGroup["autres"]).length === 0) {
            lGroup["autres"] = [];
            lGroup.length++;
          }
          lGroup["autres"][item] = indicateurs[item];
          lGroup["autres"].length++;
        }
      });
    }
    return lGroup;
  }

  static _indicateurMedical(indToRender: { [key: string]: any }, indicateurs: { [key: string]: any }, dtsModel: any, model: any, pk: string, absTooltip: string, indicateurDom: JQuery, aOptions: { [key: string]: any }): JQuery {
    let indPrimaire = null;
    let indSecondaireFlag = false;
    let indDeplacementFlag = false;
    let $indicateurPrimaire = null;
    let $indicateurSecondaire = null;
    let $indicateurDeplacement = null;
    let $seconContainer = null;
    let $DeplacementContainer = null;
    let indToRenderSecondaire = null;
    let lFindDepla = null;
    let lActivitesFilterSansDeplacement = aOptions.activitiesFilters;
    const lSinAnimation = false; //les déplacement, au fin, ils ne tiendront pas de animation
    let viewMode = "M";
    const isTabServColl = aOptions ? aOptions.lbIsTabServColl : false;

    if (aOptions.viewMode) {
      viewMode = aOptions.viewMode;
    }
    //Indicateur principale in planning
    indDeplacementFlag = CWRENDERMEDICAL._checkIndicateurDeplacementMedical(aOptions.indicSecondaire, indicateurs, aOptions.activitiesFilters, aOptions.viewMode);
    if (indDeplacementFlag === true) {
      lFindDepla = _.filter(aOptions.activitiesFilters.models, (item: Backbone.Model) => {
        return item.get("deplacement") !== true;
      });
      if (lFindDepla) {
        lActivitesFilterSansDeplacement = new Backbone.Collection(lFindDepla);
      }
    }
    indPrimaire = CWRENDERMEDICAL._checkIndicateurPrimaireMedical(indToRender, aOptions.typeIndSecon, aOptions.indicSecondaire, indicateurs, aOptions.activitiesFilters, aOptions.viewMode);
    if (indDeplacementFlag === false || indPrimaire === false) {
      indSecondaireFlag = CWRENDERAGENTS._checkIndicateurSecondaire(indToRender, aOptions.typeIndSecon, aOptions.indicSecondaire, indicateurs, lActivitesFilterSansDeplacement, aOptions.viewMode);
    }
    $indicateurPrimaire = CWRENDERMEDICAL.renderIndicateurPrimaireMedical(aOptions.princBordClass, aOptions.princBackClass, aOptions.anim1, viewMode);
    if (!CWSTR.isBlank(absTooltip)) {
      $indicateurPrimaire.attr("title", absTooltip);
    }
    $indicateurSecondaire = CWRENDERMEDICAL.renderIndicateurSecondaireMedical(aOptions.secBordClass, aOptions.secBackClass, aOptions.anim2, viewMode);
    $indicateurSecondaire.addClass("d-block");
    $seconContainer = $("<div>").addClass("phx-icon-secondaire-container");
    //Container of indicateur secondaire to set margins
    //$seconContainer.addClass("phx-icon-secondaire-container-w-GTMED");
    $seconContainer.addClass("phx-icon-secondaire-container-m-GTMED");
    $seconContainer.css("display", "inline-block");
    $seconContainer.append($indicateurSecondaire);
    //les déplacement, au fin, ils ne tiendront pas de animation
    $indicateurDeplacement = CWRENDERMEDICAL.renderIndicateurDeplacementMedical("M", lSinAnimation);
    $DeplacementContainer = $("<div>").addClass("phx-icon-secondaire-container");
    $DeplacementContainer.addClass("phx-icon-secondaire-container-m-GTMED");
    $DeplacementContainer.css("display", "inline-block");
    $DeplacementContainer.append($indicateurDeplacement);
    if (indDeplacementFlag === true) {
      indicateurDom.append($DeplacementContainer);
    } else if (indSecondaireFlag === true) { //Render indicateur secondaire
      indicateurDom.append($seconContainer);
    }
    if (indPrimaire === true) { //Render indicateur primaire
      indicateurDom.append($indicateurPrimaire);
      $seconContainer.addClass("mt-3");
    } else if (indSecondaireFlag === true) {
      indicateurDom.append($seconContainer);
    }
    if (model) {
      let lbRetablir = false;
      const lCopOptions: any = {};

      if (aOptions.indPrimaire !== indPrimaire || aOptions.indSecondaireFlag !== indSecondaireFlag) {
        lCopOptions.indPrimaire = aOptions.indPrimaire;
        aOptions.indPrimaire = indPrimaire;
        lCopOptions.indSecondaireFlag = aOptions.indSecondaireFlag;
        aOptions.indSecondaireFlag = indSecondaireFlag;
        lbRetablir = true;
      }
      //New way to paint infobulle on indicateurs in planning
      if (indPrimaire === true) { //Render indicateur primaire
        if (indSecondaireFlag === true && indDeplacementFlag !== true) { //Addapt indToRender to avoid showing data of indicateur secondaire in primaire tooltip
          indToRenderSecondaire = CWRENDERAGENTS._getSecondaireIndicateur(aOptions.typeIndSecon, aOptions.indicSecondaire); //Prepare indTorender to render only secondaire
          _.each(_.keys(indToRenderSecondaire), (key: string) => {
            if (key !== "act") {
              indToRender[key] = false;
            } else if (String(aOptions.typeIndSecon) === "2") { //For activities we check if there is a different typologie to be shown as indicateur (different from the one used for indicateur secondaire)
              _.each(indicateurs.typo, (typolIndic: { [key: string]: any }) => {
                if (String(typolIndic.cod) !== String(aOptions.indicSecondaire)) {
                  indToRender[key] = true;
                }
              });
            }
          });
        }
        CWRENDERMEDICAL._renderIndicateursNewTooltipMedical(dtsModel, indToRender, pk, model, indicateurs, indicateurDom.find($indicateurPrimaire), aOptions, false, isTabServColl);
      }
      if (indDeplacementFlag === true) {
        CWRENDERMEDICAL._renderIndicateursNewTooltipMedical(dtsModel, indToRender, pk, model, indicateurs, indicateurDom.find($indicateurDeplacement), aOptions, true, isTabServColl);
      }
      if (indSecondaireFlag === true) { //Render indicateur secondaire
        indToRenderSecondaire = CWRENDERAGENTS._getSecondaireIndicateur(aOptions.typeIndSecon, aOptions.indicSecondaire); //Prepare indTorender to render only secondaire
        aOptions.indPrimaire = false;
        aOptions.indSecondaireFlag = true;
        CWRENDERMEDICAL._renderIndicateursNewTooltipMedical(dtsModel, indToRenderSecondaire, pk, model, indicateurs, indicateurDom.find($seconContainer), aOptions, null, isTabServColl);
      }
      if (lbRetablir === true) {
        aOptions.indPrimaire = lCopOptions.indPrimaire;
        aOptions.indSecondaireFlag = lCopOptions.indSecondaireFlag;
      }
    }
    return indicateurDom;
  }

  static _indicateurAbsenceMedical(indicateurs: { [key: string]: any }, aListeIndicateurs: any, aNfract: number, aFractionsMedical: { [key: string]: any }, aPosIndicateur: any): any {
    let lListPosAbsHor = null;
    const lList: any = [];
    const lRtn = aListeIndicateurs;
    const lFuncList = (value: { [key: string]: any }, aPosInd: any): void => {
      if (!_.isEmpty(lList[aPosInd])) {
        lList[aPosInd].abs++;
        lList[aPosInd].absd.push(value);
        if (value.sta === "A" || value.sta === "H") {
          lList[aPosInd].absv++;
        } else {
          lList[aPosInd].absav++;
        }
      } else {
        lList[aPosInd].abs = 1;
        lList[aPosInd].absd = [];
        lList[aPosInd].absd.push(value);
        if (value.sta === "A" || value.sta === "H") {
          lList[aPosInd].absv = 1;
        } else {
          lList[aPosInd].absav = 1;
        }
      }
    };

    for (let i = 0; i < aNfract; i++) {
      lList[i] = {};
    }
    _.each(indicateurs.absd, (value: { [key: string]: any }) => {
      if (value.hrd > 0 && value.hrf > 0) {
        lListPosAbsHor = CWRENDERMEDICAL.checkHeuresMedical(value.hrd, value.hrf, aFractionsMedical);
        if (lListPosAbsHor.length > 1) {
          const listTmp = [];

          listTmp.push(lListPosAbsHor[0]);
          //on doit enlever les indicateurs qui seront affiché en double si la fractionne est fusionné
          for (let j = 1; j < lListPosAbsHor.length; j++) {
            const lVal = aPosIndicateur[lListPosAbsHor[j]];

            if (listTmp.indexOf(lVal) >= 0) {
              lListPosAbsHor.splice(j, 1);
              j--;
            } else {
              listTmp.push(lListPosAbsHor[j]);
            }
          }
        }
        if (lListPosAbsHor.length > 0) {
          _.each(lListPosAbsHor, (aPosAbsHor: any) => { //
            lFuncList(value, aPosIndicateur[aPosAbsHor]);
          });
        }
      } else {
        lFuncList(value, aPosIndicateur[aNfract - 1]);
      }
    });
    for (let i = 0; i < lList.length; i++) {
      lRtn[i] = _.extend({}, aListeIndicateurs[i], lList[i]);
    }
    return lRtn;
  }

  static _indicateurPretMedicalVueJournee(indicateurs: { [key: string]: any }, aListeFractionnes: Array<number>, listeIndicateurs: Array<any>): any {
    let dtsModelAff = null;
    const unites: { [key: string]: boolean[] } = {
      "M": [true, false, false, false],
      "S": [false, true, false, false],
      "SN1": [false, true, true, false],
      "SNU": [false, true, true, true],
      "J": [true, true, false, false],
      "K": [true, true, false, false],
      "JN1": [true, true, true, false],
      "N1": [false, false, true, false],
      "N2": [false, false, false, true],
      "N": [false, false, true, true],
      "D": [true, true, true, true]
    };
    const addUnites = (unite1: boolean[], unite2: boolean[]): boolean[] => {
      for (let _i = 0; _i < unite1.length; _i++) {
        unite1[_i] = unite1[_i] || unite2[_i];
      }
      return unite1;
    };
    let pretFractions = [false, false, false, false];

    if (!CWSTR.isBlank(indicateurs.prtd)) {
      dtsModelAff = indicateurs.prtd;
    }
    // result of the overlap of two units for the same day
    if (dtsModelAff) {
      // for several affsec in a day sumatory of unites
      _.each(dtsModelAff, (affru: any) => {
        if (affru.unic in unites) {
          pretFractions = addUnites(pretFractions, unites[affru.unic]);
        }
      });
    }
    for (let i = 0; i < pretFractions.length; i++) {
      if (pretFractions[i]) {
        if (aListeFractionnes[i] > 0) {
          // if (!lListeElement[i]) {
          if (!_.isEmpty(listeIndicateurs[i])) {
            listeIndicateurs[i] = _.extend({}, listeIndicateurs[i], indicateurs);
          } else {
            listeIndicateurs[i] = indicateurs;
          }
          //   lListeElement[i] = true;
          // }
        } else {
          let j = i - 1;

          pretFractions[i] = false;
          while (j > 0 && aListeFractionnes[j] === 0) {
            pretFractions[j] = false;
            j--;
          }
          pretFractions[j] = true;
          // if (!lListeElement[j]) {
          if (!_.isEmpty(listeIndicateurs[j])) {
            listeIndicateurs[j] = _.extend({}, listeIndicateurs[j], indicateurs);
          } else {
            listeIndicateurs[j] = indicateurs;
          }
          //   lListeElement[j] = true;
          // }
        }
      }
    }
    return { "listeIndicateurs": listeIndicateurs, "pretFractions": pretFractions };
  }

  static _indicateurAffSecMedicalVueJournee(indicateurs: { [key: string]: any }, aListeFractionnes: Array<number>, listeIndicateurs: Array<any>): any {
    let dtsModelAff = null;
    const unites: { [key: string]: boolean[] } = {
      "M": [true, false, false, false],
      "S": [false, true, false, false],
      "SN1": [false, true, true, false],
      "SNU": [false, true, true, true],
      "J": [true, true, false, false],
      "K": [true, true, false, false],
      "JN1": [true, true, true, false],
      "N1": [false, false, true, false],
      "N2": [false, false, false, true],
      "N": [false, false, true, true],
      "D": [true, true, true, true]
    };
    const addUnites = (unite1: boolean[], unite2: boolean[]): boolean[] => {
      for (let _i = 0; _i < unite1.length; _i++) {
        unite1[_i] = unite1[_i] || unite2[_i];
      }
      return unite1;
    };
    let affSecFractions = [false, false, false, false];

    if (!CWSTR.isBlank(indicateurs.affsec)) {
      dtsModelAff = indicateurs.affsec;
    }
    // result of the overlap of two units for the same day
    if (dtsModelAff) {
      // for several prets in a day sumatory of unites
      _.each(dtsModelAff, (affru: any) => {
        if (affru.unitecode in unites) {
          affSecFractions = addUnites(affSecFractions, unites[affru.unitecode]);
        }
      });
    }
    for (let i = 0; i < affSecFractions.length; i++) {
      if (affSecFractions[i]) {
        if (aListeFractionnes[i] > 0) {
          //  if (!lListeElement[i]) {
          if (!_.isEmpty(listeIndicateurs[i])) {
            listeIndicateurs[i] = _.extend({}, listeIndicateurs[i], indicateurs);
          } else {
            listeIndicateurs[i] = indicateurs;
          }
          //   lListeElement[i] = true;
          // }
        } else {
          let j = i - 1;

          affSecFractions[i] = false;
          while (j > 0 && aListeFractionnes[j] === 0) {
            affSecFractions[j] = false;
            j--;
          }
          affSecFractions[j] = true;
          // if (!lListeElement[j]) {
          if (!_.isEmpty(listeIndicateurs[j])) {
            listeIndicateurs[j] = _.extend({}, listeIndicateurs[j], indicateurs);
          } else {
            listeIndicateurs[j] = indicateurs;
          }
          //   lListeElement[j] = true;
          // }
        }
      }
    }
    return { "listeIndicateurs": listeIndicateurs, "affSecFractions": affSecFractions };
  }

  static _indicateurActiviteMedical(indicateurs: { [key: string]: any }, aListeIndicateurs: any, aNfract: number, aFractionsMedical: { [key: string]: any }, aPosIndicateur: any): any {
    let lListPosActHor = null;
    const lList: any = [];
    const lRtn = aListeIndicateurs;
    const lFuncList = (value: { [key: string]: any }, aPosInd: any, aCode: any, aTypo: string, aRegt: any): void => {
      const lTmpList: any = [];

      if (!_.isEmpty(lList[aPosInd])) {
        if (aTypo === "prev") {
          if (!CWSTR.isBlank(lList[aPosInd].ap)) {
            if (!CWSTR.isBlank(lList[aPosInd].ap[aCode])) {
              lList[aPosInd].ap[aCode]++;
            } else {
              lList[aPosInd].ap[aCode] = 1;
            }
          } else {
            lList[aPosInd].ap = {};
            lList[aPosInd].ap[aCode] = 1;
          }
          lTmpList.push(value);
          if (!CWSTR.isBlank(lList[aPosInd].typo)) {
            const lFind = _.find(lList[aPosInd].typo, (aElement: { [key: string]: any }) => {
              return aElement.cod === aCode;
            });

            if (!CWSTR.isBlank(lFind)) {
              if (!CWSTR.isBlank(lFind.prev) && _.find(lFind.prev, (o) => {
                return _.isEqual(o, value);
              })) {
                // do nothing, avoid repeat line
              } else if (!CWSTR.isBlank(lFind.prev)) {
                lFind.prev.push(value);
              } else {
                lFind.prev = lTmpList;
              }
            } else {
              lList[aPosInd].typo.push({ "cod": aCode, "prev": lTmpList, "regt": aRegt });
            }
          } else {
            lList[aPosInd].typo = [];
            lList[aPosInd].typo.push({ "cod": aCode, "prev": lTmpList, "regt": aRegt });
          } //en activité est "eta" en lieu de "sta"
          if (!(CWSTR.isBlank(value.eta) || value.eta === "A" || value.eta === "H")) {
            if (!CWSTR.isBlank(lList[aPosInd].apav)) {
              lList[aPosInd].apav[aCode]++;
            } else {
              lList[aPosInd].apav = {};
              lList[aPosInd].apav[aCode] = 1;
            }
          }
        } else {
          if (!CWSTR.isBlank(lList[aPosInd].ar)) {
            if (!CWSTR.isBlank(lList[aPosInd].ar[aCode])) {
              lList[aPosInd].ar[aCode]++;
            } else {
              lList[aPosInd].ar[aCode] = 1;
            }
          } else {
            lList[aPosInd].ar = {};
            lList[aPosInd].ar[aCode] = 1;
          }
          lTmpList.push(value);
          if (!CWSTR.isBlank(lList[aPosInd].typo)) {
            const lFind = _.find(lList[aPosInd].typo, (aElement: { [key: string]: any }) => {
              return aElement.cod === aCode;
            });

            if (!CWSTR.isBlank(lFind)) {
              if (!CWSTR.isBlank(lFind.real) && _.find(lFind.real, (o: any) => { return _.isEqual(o, value); })) {
                // do nothing, avoid repeat line
              } else if (!CWSTR.isBlank(lFind.real)) {
                lFind.real.push(value);
              } else {
                lFind.real = lTmpList;
              }
            } else {
              lList[aPosInd].typo.push({ "cod": aCode, "real": lTmpList, "regt": aRegt });
            }
          } else {
            lList[aPosInd].typo = [];
            lList[aPosInd].typo.push({ "cod": aCode, "real": lTmpList, "regt": aRegt });
          } //en activité est "eta" en lieu de "sta"
          if (!(CWSTR.isBlank(value.eta) || value.eta === "A" || value.eta === "H")) {
            if (!CWSTR.isBlank(lList[aPosInd].arav)) {
              lList[aPosInd].arav[aCode]++;
            } else {
              lList[aPosInd].arav = {};
              lList[aPosInd].arav[aCode] = 1;
            }
          }
        }
      } else {
        if (aTypo === "prev") {
          lList[aPosInd].ap = {};
          lList[aPosInd].ap[aCode] = 1;
          lList[aPosInd].typo = [];
          lTmpList.push(value);
          lList[aPosInd].typo.push({ "cod": aCode, "prev": lTmpList, "regt": aRegt });
          //en activité est "eta" en lieu de "sta"
          if (!(CWSTR.isBlank(value.eta) || value.eta === "A" || value.eta === "H")) {
            lList[aPosInd].apav = {};
            lList[aPosInd].apav[aCode] = 1;
          }
        } else {
          lList[aPosInd].ar = {};
          lList[aPosInd].ar[aCode] = 1;
          lList[aPosInd].typo = [];
          lTmpList.push(value);
          lList[aPosInd].typo.push({ "cod": aCode, "real": lTmpList, "regt": aRegt });
          //en activité est "eta" en lieu de "sta"
          if (!(CWSTR.isBlank(value.eta) || value.eta === "A" || value.eta === "H")) {
            lList[aPosInd].arav = {};
            lList[aPosInd].arav[aCode] = 1;
          }
        }
      }
    };

    for (let i = 0; i < aNfract; i++) {
      lList[i] = {};
    }
    _.each(indicateurs.typo, (item: { [key: string]: any }) => {
      if (!CWSTR.isBlank(item.prev)) {
        _.each(item.prev, (value: { [key: string]: any }) => {
          if (value.hed > 0 && value.hef > 0) {
            lListPosActHor = CWRENDERMEDICAL.checkHeuresMedical(value.hed, value.hef, aFractionsMedical);
            if (lListPosActHor.length > 1) {
              const listTmp = [];

              listTmp.push(lListPosActHor[0]);
              //on doit enlever les indicateurs qui seront affiché en double si la fractionne est fusionné
              for (let j = 1; j < lListPosActHor.length; j++) {
                const lVal = aPosIndicateur[lListPosActHor[j]];

                if (listTmp.indexOf(lVal) >= 0) {
                  lListPosActHor.splice(j, 1);
                  j--;
                } else {
                  listTmp.push(lListPosActHor[j]);
                }
              }
            }
            if (lListPosActHor.length > 0) {
              _.each(lListPosActHor, (aPosActHor: any) => { //
                lFuncList(value, aPosIndicateur[aPosActHor], item.cod, "prev", item.regt);
              });
            }
          } else {
            lFuncList(value, aPosIndicateur[aNfract - 1], item.cod, "prev", item.regt);
          }
        });
      }
      if (!CWSTR.isBlank(item.real)) {
        _.each(item.real, (value: { [key: string]: any }) => {
          if (value.hed > 0 && value.hef > 0) {
            lListPosActHor = CWRENDERMEDICAL.checkHeuresMedical(value.hed, value.hef, aFractionsMedical);
            if (lListPosActHor.length > 1) {
              const listTmp = [];

              listTmp.push(lListPosActHor[0]);
              //on doit enlever les indicateurs qui seront affiché en double si la fractionne est fusionné
              for (let j = 1; j < lListPosActHor.length; j++) {
                const lVal = aPosIndicateur[lListPosActHor[j]];

                if (listTmp.indexOf(lVal) >= 0) {
                  lListPosActHor.splice(j, 1);
                  j--;
                } else {
                  listTmp.push(lListPosActHor[j]);
                }
              }
            }
            if (lListPosActHor.length > 0) {
              _.each(lListPosActHor, (aPosActHor: any) => { //
                lFuncList(value, aPosIndicateur[aPosActHor], item.cod, "real", item.regt);
              });
            }
          } else {
            lFuncList(value, aPosIndicateur[aNfract - 1], item.cod, "real", item.regt);
          }
        });
      }
    });
    for (let i = 0; i < lList.length; i++) {
      lRtn[i] = _.extend({}, aListeIndicateurs[i], lList[i]);
    }
    return lRtn;
  }

  static existIndicateurComplementaireMedical(indToRender: { [key: string]: any }, layers: Backbone.Model, indicateurs: { [key: string]: any }, activitiesFilters: Backbone.Collection, viewMode: string): boolean {
    let ap = false;
    let ar = false;
    let apz = false;
    let arz = false;
    let apav = false;
    let arav = false;
    let apzav = false;
    let arzav = false;
    let keys = null;
    let size = null;
    let i = 0;
    let isIndCoc = false;

    if (indicateurs.ap) {
      keys = _.keys(indicateurs.ap);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          ap = true;
          break;
        }
      }
    }
    indToRender.act = (ap && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.ar) {
      keys = _.keys(indicateurs.ar);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          ar = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || ar) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.apz) {
      keys = _.keys(indicateurs.apz);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          apz = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || apz) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.arz) {
      keys = _.keys(indicateurs.arz);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          arz = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || arz) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.arav) {
      keys = _.keys(indicateurs.arav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          arav = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || arav) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.apav) {
      keys = _.keys(indicateurs.apav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          apav = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || apav) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.apzav) {
      keys = _.keys(indicateurs.apzav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          apzav = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || apzav) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.arzav) {
      keys = _.keys(indicateurs.arzav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          arzav = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || arzav) && layers.get("ind_act"));
    if (layers.get("ind")) {
      indToRender.val = (indicateurs.val && layers.get("ind_ano"));
      indToRender.valb = (indicateurs.valb && layers.get("ind_ano"));
      indToRender.abs = (indicateurs.abs && layers.get("ind_abs"));
      indToRender.absv = (indicateurs.absv && layers.get("ind_abs"));
      indToRender.absav = (indicateurs.absav && layers.get("ind_abs"));
      indToRender.reg = (indicateurs.reg && layers.get("ind_reg"));
      indToRender.regav = (indicateurs.regav && layers.get("ind_reg"));
      indToRender.astreg = (indicateurs.astreg && layers.get("ind_astreg"));
      indToRender.mem = (indicateurs.mem && layers.get("ind_mem"));
      indToRender.mes = (indicateurs.mes && layers.get("ind_mes"));
      indToRender.pret = (indicateurs.pretd && layers.get("ind_pret"));
      indToRender.affsec = (indicateurs.affsec && layers.get("ind_affs"));
      indToRender.rptn = (indicateurs.rptn && layers.get("ind_repla"));
      indToRender.rpen = (indicateurs.rpen && layers.get("ind_repla"));
      indToRender.pren = (indicateurs.pren && layers.get("ind_repla"));
      indToRender.prpn = (indicateurs.prpn && layers.get("ind_repla"));
      if (indToRender.val || indToRender.valb || indToRender.abs || indToRender.absv || indToRender.astreg || indToRender.absav ||
        indToRender.reg || indToRender.regav || indToRender.mem || indToRender.mes || indToRender.act || indToRender.pret ||
        indToRender.rptn || indToRender.rpen || indToRender.pren || indToRender.prpn || indToRender.pretd || indToRender.affsec) {
        isIndCoc = true;
      }
    }
    return isIndCoc;
  }

  static existIndicateurComplementaireMedicalActivites(indToRender: { [key: string]: any }, layers: Backbone.Model, indicateurs: { [key: string]: any }, activitiesFilters: Backbone.Collection, viewMode: string): boolean {
    let ap = false;
    let ar = false;
    let apz = false;
    let arz = false;
    let apav = false;
    let arav = false;
    let apzav = false;
    let arzav = false;
    let keys = null;
    let size = null;
    let i = 0;

    if (indicateurs.ap) {
      keys = _.keys(indicateurs.ap);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          ap = true;
          break;
        }
      }
    }
    indToRender.act = (ap && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.ar) {
      keys = _.keys(indicateurs.ar);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          ar = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || ar) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.apz) {
      keys = _.keys(indicateurs.apz);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          apz = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || apz) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.arz) {
      keys = _.keys(indicateurs.arz);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          arz = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || arz) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.arav) {
      keys = _.keys(indicateurs.arav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          arav = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || arav) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.apav) {
      keys = _.keys(indicateurs.apav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          apav = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || apav) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.apzav) {
      keys = _.keys(indicateurs.apzav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          apzav = true;
          break;
        }
      }
    }
    indToRender.act = ((indToRender.act || apzav) && layers.get("ind_act"));
    if (!indToRender.act && indicateurs.arzav) {
      keys = _.keys(indicateurs.arzav);
      size = keys.length;
      for (i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          arzav = true;
          break;
        }
      }
    }

    return ((indToRender.act || arzav) && layers.get("ind_act"));
  }

  static paintTextActMedical(lPosFract: JQuery, dts: { [key: string]: any }, text: string): JQuery {
    //sta
    if (dts.sta === "D" || dts.sta === "I" || dts.sta === "T" || dts.sta === "E") {
      if (dts.typ === "R") {
        lPosFract.addClass("phx-cell-act-rel-val");
      } else {
        lPosFract.addClass("phx-cell-act-prev-val");
      }
    }

    if (dts.chevnb > 1) {
      CWRENDERAGENTS.paintPreviousSymbols(lPosFract, dts);
      lPosFract.text("(" + dts.chevnb + ")");
    } else {
      //Set text
      CWRENDERAGENTS.paintPreviousSymbols(lPosFract, dts);
      lPosFract.text(text);
    }

    return lPosFract;
  }

  /**
   * New method to render indicateur tooltip dans le planning Médical (l'autre méthode est utilisé aussi par l'agenda)
   */
  static _renderIndicateursNewTooltipMedical(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, viewEl: JQuery, options: { [key: string]: any }, isDeplacement: boolean, isTabServColl?: boolean): void {
    let icons = null,
      activitiesFilters: any = null,
      viewMode = "S",
      indPrimaire = false,
      indSecondaireFlag = false,
      indDeplacementFlag = false;
    let princBackClass = "",
      princBordClass = "",
      secBackClass = "",
      secBordClass = "";
    let lTmpActiviteFilter: any = null;
    let $actContent = null;
    let lFind = null;
    const $titleContent = $("<div>");
    const user = model.user;
    const nom = (user?.nom) ? user.nom.toUpperCase() : "";
    const date = CWTYPE.DATE.format(model.dat, "DD Month YYYY");
    const day = CWTYPE.DATE.strToDate(model.dat).getDay();
    const $firstLine = $("<span class='ui-phx-ihm-texte-donnees-important'>" + nom + " " + user?.pre + "</span>, le " + i18n.t('common:dayNamesMin.' + day) + " <span class='ui-phx-ihm-texte-donnees-important'>" + date + "</span><br>");
    const $secondLine = $("<span class='ui-phx-ihm-texte-application'><span class='phx-tooltip-second-line-text-container'>" + i18n.t('common:infoIndicateurs') + "</span></span>").css("display", "inline-block");
    const $secondLineIndicContainer = $("<span>").addClass("phx-tooltip-second-line-container");
    const $evContent = $("<table>");

    if (options) {
      if (options.icons) {
        icons = options.icons;
      }
      if (options.activitiesFilters) {
        if (CWSTR.isBlank(isDeplacement)) {
          activitiesFilters = options.activitiesFilters;
        } else if (isDeplacement === false) {
          lFind = _.filter(options.activitiesFilters.models, (item: Backbone.Model) => {
            return item.get("deplacement") !== true;
          });
          if (lFind) {
            activitiesFilters = new Backbone.Collection(lFind);
          }
        } else { //elle sont déplacements
          lFind = _.filter(options.activitiesFilters.models, (item: Backbone.Model) => {
            return item.get("deplacement") === true;
          });
          if (lFind) {
            activitiesFilters = new Backbone.Collection(lFind);
            indDeplacementFlag = true;
          }
        }
      }
      if (options.viewMode) {
        viewMode = options.viewMode;
      }
      if (_.isBoolean(options.indSecondaireFlag)) {
        indSecondaireFlag = options.indSecondaireFlag;
      }
      if (_.isBoolean(options.indPrimaire)) {
        indPrimaire = options.indPrimaire;
      }
      if (options.princBackClass) {
        princBackClass = options.princBackClass;
      }
      if (options.princBordClass) {
        princBordClass = options.princBordClass;
      }
      if (options.secBackClass) {
        secBackClass = options.secBackClass;
      }
      if (options.secBordClass) {
        secBordClass = options.secBordClass;
      }
    }
    //Tooltip - only when indicateur
    /*
        <b>NOM Prénom</b>, le vendredi <b>31 mai 2013</b>
        2 anomalies(s)
        3 régularisation(s)
        1 absence(s) complémentaire(s)
      */
    $secondLine.find(".phx-tooltip-second-line-text-container").css({ "vertical-align": "middle", "margin-top": "2px" });
    if (icons && icons.mainIcon) {
      $secondLine.append(icons.mainIcon);
    } else if (indPrimaire === true && indDeplacementFlag === false) {
      $secondLineIndicContainer.append(CWRENDERAGENTS.renderIndicateurPrimaire(viewMode, princBordClass, princBackClass, false).addClass("ui-phx-icon-in-tooltip"));
      $secondLine.append($secondLineIndicContainer);
    } else if (indDeplacementFlag === true) {
      $secondLineIndicContainer.append(CWRENDERMEDICAL.renderIndicateurDeplacementMedical(viewMode, false).addClass("ui-phx-icon-in-tooltip"));
      $secondLine.append($secondLineIndicContainer);
    } else if (indSecondaireFlag === true) {
      $secondLineIndicContainer.append(CWRENDERAGENTS.renderIndicateurSecondaire(viewMode, secBordClass, secBackClass, false).addClass("ui-phx-icon-in-tooltip"));
      $secondLine.append($secondLineIndicContainer);
    }
    if (indDeplacementFlag === false) {
      //Regularisations
      if (indToRender.reg && indicateurs.regd && indicateurs.regd.length > 0) {
        const $regContent = CWRENDERAGENTS._renderRegulsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        $evContent.append($regContent);
      }
      //Absences complémentaires,
      if (indToRender.abs && indicateurs.absd && indicateurs.absd.length > 0) {
        let $absContent: JQuery = null;

        if (isTabServColl === true) {
          const TSAbs = (!CWSTR.isBlank(CWDIVERS.get("GME_TS_Abs"))) ? CWDIVERS.get("GME_TS_Abs") : "0";
          const connectedUser = objs.appRt.workflow.authModel.get("matricule");
          //Dans le cas qui soit le tableau de service collectif --> (isTabServColl === true),
          //le paramDiver GME_TS_Abs === '1', 
          //et le collaborateur different au connecté, alors l'infobulle d'absence ne se doit pas générer.

          if (model?.user?.mat === connectedUser || TSAbs !== "1") {
            $absContent = CWRENDERAGENTS._renderAbsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);
          }
        } else {
          $absContent = CWRENDERAGENTS._renderAbsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);
        }

        $evContent.append($absContent);
      }
    }
    //Activités regroupées dans un bloc par typologie et types d’activité «prévues»/«réalisées» (voir ci-après)
    if (indToRender.act && indicateurs.typo && indicateurs.typo.length > 0) {
      let addTypoRealVal = false;
      let addTypoPrevVal = false;
      const lCopIndicateursTypo = indicateurs.typo; //pour rétablir après si indDeplacementFlag = false ou true; uniquement si est equal à null, sera le même valeur
      const typologiesDeplacementArray = _.reduce(options.activitiesFilters.models, (acum: Array<any>, typologie: Backbone.Model) => {
        if (typologie.get("deplacement") === true) {
          return acum.concat(typologie.get("code"));
        } else {
          return acum;
        }
      }, []);

      if (indDeplacementFlag === false) {
        indicateurs.typo = _.filter(indicateurs.typo, (item: { [key: string]: any }) => {
          return typologiesDeplacementArray.indexOf(item.cod) === -1;
        });
      } else if (indDeplacementFlag === true) {
        indicateurs.typo = _.filter(indicateurs.typo, (item: { [key: string]: any }) => {
          return typologiesDeplacementArray.indexOf(item.cod) !== -1;
        });
      }
      _.each(indicateurs.typo, (typologie: { [key: string]: any }, index: number) => {
        if (!_.isNull(typologie.real) && _.isArray(typologie.real) && typologie.real.length > 0) {
          _.each(typologie.real, (real: { [key: string]: any }) => {
            if (real.eta === "D" || real.eta === "I" || real.eta === "T") {
              addTypoRealVal = true;
              let currentData = viewEl.data("act-typos");

              if (CWSTR.isBlank(currentData)) {
                currentData = [];
              }
              currentData.push(typologie.cod);
              viewEl.parent(".phx-cell-render-ind").data("act-typos", currentData);
            }
          });
        }
        if (!_.isNull(typologie.prev) && _.isArray(typologie.prev) && typologie.prev.length > 0) {
          _.each(typologie.prev, (prev: { [key: string]: any }) => {
            if (prev.eta === "D" || prev.eta === "I" || prev.eta === "T") {
              addTypoPrevVal = true;
              let currentData = viewEl.data("act-typos");

              if (CWSTR.isBlank(currentData)) {
                currentData = [];
              }
              currentData.push(typologie.cod);
              viewEl.parent(".phx-cell-render-ind").data("act-typos", currentData);
            }
          });
        }
        // if (indSecondaireFlag !== true) { //We are painting indicateur primaire
        //le suivant block fait le même dans le "if" comme le "else"
        if (!CWSTR.isBlank(indDeplacementFlag)) { //copier la valeur pour le rétablir après (uniquement s'il n'y a pas de déplacements et c'est une indicateur secondaire, ne se modifie pas)
          lTmpActiviteFilter = options.activitiesFilters;
          options.activitiesFilters = activitiesFilters;
        }
        $actContent = CWRENDERAGENTS._renderActTooltip(dtsModel, typologie, index, indToRender, pk, model, indicateurs, options);
        if (addTypoRealVal === true) {
          viewEl.parent(".phx-cell-render-ind").addClass("phx-cell-act-rel-ind-val");
        }
        if (addTypoPrevVal === true) {
          viewEl.parent(".phx-cell-render-ind").addClass("phx-cell-act-prev-ind-val");
        }
        $evContent.append($actContent);
        if (!CWSTR.isBlank(indDeplacementFlag)) { //retablir la valuer
          options.activitiesFilters = lTmpActiviteFilter;
        }
      });
      //rétablir les valeurs de indicateurs.typo
      indicateurs.typo = lCopIndicateursTypo;
    }
    if (indDeplacementFlag === false) {
      //Prêts ou emprunts avec ou sans remplacement
      if ((indToRender.rptn || indToRender.rpen || indToRender.pren || indToRender.prpn || indToRender.repla) && indicateurs.prtd && indicateurs.prtd.length > 0) {
        //repla is not sent by metier, is a local indicator for vue zoom journee that there is some indicateur of pret or remplacement for this day
        const $pretContent = CWRENDERAGENTS._renderPretTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        $evContent.append($pretContent);
      }
      //Disponibilité ou indisponibilité pour prêts
      if (indToRender.pret && indicateurs.pretd) {
        const $dispContent = CWRENDERAGENTS._renderDispTooltip(indToRender, pk, model, indicateurs, options);

        $evContent.append($dispContent);
      }
      //Anomalies,
      if ((indToRender.val || indToRender.valb) && indicateurs.anod && indicateurs.anod.length > 0) {
        const $anoContent = CWRENDERAGENTS._renderAnoTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        $evContent.append($anoContent);
      }
      //Messages
      if (indToRender.mes && indicateurs.mesd && indicateurs.mesd.length > 0) {
        const $mesContent = CWRENDERAGENTS._renderMesTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        $evContent.append($mesContent);
      }
      //Astreinte par regul
      if (indToRender.astreg && indicateurs.astregd && indicateurs.astregd.length > 0) {
        const $astregContent = CWRENDERAGENTS._renderAstrRegulsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        $evContent.append($astregContent);
      }
      //Affectations secondaires MT819
      if (indToRender.affsec > 0) {
        const $affsecondContent = CWRENDERAGENTS._renderAffsecondTooltip(dtsModel, indToRender, pk, model, indicateurs);

        $evContent.append($affsecondContent);
      }
    }
    //Dans le cas qui soit tableau de service collectif --> (isTabServColl === true)
    //et le paramDiver GME_TS_Abs === '1' et il n'y aiet pas autre indicateurs --> ($evContent.children().length ===0), 
    //l'infobulle ne s'affichera pas. Pour les autres cas, elle sera peintée 
    if (isTabServColl !== true || $evContent.children().length > 0) {
      if ((options.lbIsTabServInd === false && options.lIsvueJournee === false) || options.lbIsTabServColl) {
        $titleContent.append($firstLine);
      }
      $titleContent.append($secondLine);
      $titleContent.append($evContent);
      viewEl.prop("title", $titleContent[0].innerHTML);
    }
  }

  /**
   * Function to check if indicateur "deplacement" is going to be shown or not
   */
  static _checkIndicateurDeplacementMedical(indicSecondaire: string, indicateurs: { [key: string]: any }, activitiesFilters: Backbone.Collection, viewMode: string): boolean {
    let lDeplacement = false;
    let lCollDepla = null;
    let lFind = null;

    //fam: "GTM-DEPLAC"
    if (activitiesFilters && activitiesFilters.length > 0) {
      let lRtn = null;

      lFind = _.filter(activitiesFilters.models, (item: Backbone.Model): boolean => {
        return item.get("deplacement") === true;
      });
      if (lFind) {
        lCollDepla = new Backbone.Collection(lFind);
        lRtn = CWRENDERAGENTS._checkInfoTypologies(indicSecondaire, indicateurs, lCollDepla, viewMode);
        if (lRtn && lRtn.numberOfIndicateurs > 0) {
          lDeplacement = true
        }
      }
    }
    return lDeplacement;
  }

  /**
   * Function that generate the deplacement indicateur in planning Médical
   * */
  static renderIndicateurDeplacementMedical(view: string, animate: boolean): JQuery {
    const $divIndicateur = $("<div>");

    if (view === "S") {
      //Utilisé dans le tooltip
      $divIndicateur.addClass("ui-phx-icon-deplacement-indicateur-w");
    } else {
      $divIndicateur.addClass("ui-phx-icon-deplacement-indicateur-m");
    }
    if (animate) {
      if ($('html').is('.ie9')) { //ie9 does not support animation by css so we have to use jquery animation
        CWRENDERAGENTS._animateRotation($divIndicateur, view);
      } else {
        $divIndicateur.addClass("animationDeplacement");
      }
    }
    return $divIndicateur;
  }

  static _hasActiviteIndicateursExternals(activitesIndicateurs: { [key: string]: any }): boolean {
    const result = _.reduce(activitesIndicateurs.typo, (acum: number, typo: { [key: string]: any }) => {
      let resultTypo = 0
      if (typo.prev instanceof Array) {
        for (let cont = 0; typo.prev[cont]; cont++) {
          if (typo.prev[cont].ext === false) {//ne pas changer ext === false -> c'est correct
            resultTypo++;
          }
        }
      }
      if (typo.real instanceof Array) {
        for (let cont = 0; typo.real[cont]; cont++) {
          if (typo.real[cont].ext === false) {//ne pas changer ext === false -> c'est correct
            resultTypo++;
          }
        }
      }
      return (resultTypo > 0) ? acum + 1 : acum;
    }, 0);
    return (result > 0) ? true : false;
  }

  static _isExternalCellCollaborateur(model: { [key: string]: any }): boolean {
    if (model?.user?.ext === true) {
      return true;
    } else {
      return false;
    }
  }

  static _renderMemosMedical(layers: Backbone.Model, fractionsMedical: JQuery, aListeFractionnes: Array<number>, evtModel: any, pk: string, options: { [key: string]: any }, isvueJournee: boolean): JQuery {
    if (!_.isEmpty(aListeFractionnes)) {
      let lPosFracMemo = null;
      let nfracMemo = (aListeFractionnes.length - 1);
      let ldepFrac = 1;

      for (let i = (aListeFractionnes.length - 1); i >= 0; i--) {
        if (aListeFractionnes[i] === ldepFrac) {
          nfracMemo = i;
          break;
        }
        ldepFrac++;
      }
      lPosFracMemo = fractionsMedical.find(".planmed_cell_nfraction_" + nfracMemo + " .cellMed-fract-" + nfracMemo);
      if (lPosFracMemo.length > 0) {
        if (isvueJournee === true) {
          lPosFracMemo = lPosFracMemo.find(".plmed-inVuejournee");
          if (lPosFracMemo.length > 0) {
            lPosFracMemo.before(CWRENDERAGENTS._renderMemos(layers, "S", evtModel, pk, options));
          }
        } else {
          lPosFracMemo.append(CWRENDERAGENTS._renderMemos(layers, "M", evtModel, pk, options));
        }
      }
    }

    return fractionsMedical;
    //return CWRENDERAGENTS._renderMemos(layers, "M", evtModel, pk, options);
  }

  static _buildTooltipActiviteEmplacement(activite: Backbone.Model, dts: { [key: string]: any }, dtsModel: CWTabservCollDTS): string {
    let result = "";
    const dtsEmp = dtsModel.ACT_EMPLACEMENTS.getEmplacement(dts.emp, dts.deb, dts.fin);
    const dtsHor = dtsModel.ACT_HORAIRES.get(dts.acthor);
    if (dtsEmp && dtsHor) {
      const hourDeb = CWTYPE.HOUR_MINUTE.format(dtsHor.get("hdeb"));
      const hourFin = CWTYPE.HOUR_MINUTE.format(dtsHor.get("hfin"));
      const duration = (CWTYPE.DURATION.HOUR_MINUTE.format(dtsHor.get("tdu"))).trim();
      result =
        `<div class="cwActEmplTooltip d-flex flex-column">
          <div class="d-flex mb-1">
            <span class="cw-texteAllege mr-2">${i18n.t('common:emplacement')}</span> 
            <span class="cw-texteImportant mr-2">${dtsEmp.get("lib")}</span>
            <span class="cw-texteAllege">(${dtsEmp.get("chemincode")})</span>
          </div>
          <div class="d-flex">
            <span class="cw-texteAllege mr-2">${i18n.t('common:horaire')}</span>
            <span class="cw-texteImportant mr-1">${dtsHor.get("libelle")}</span>
            <span class="cw-texteAllege cwActEmplTooltip__hours">- ${hourDeb} ${i18n.t('common:a')} ${hourFin} (${duration})</span>
          </div>
        </div>`;
    }
    return result;
  }

  static _buildTooltipActivite(reducedMode: boolean, activite: any, dts: any, dtsModel: any): string {
    let result = "";
    if (GLOBAL_DATA.typologies.models.length > 0) {
      const typAux = GLOBAL_DATA.typologies.get(dts.tpo).get("evenement");
      const typ = typAux.substr(0, 1).toUpperCase() + typAux.substr(1);
      const motif = activite.get("lib") + " (" + activite.get("cod") + ")";
      const unit = this.getUnit(dtsModel.AUX_UNI.models, dts.ude, dts.ufi);

      if (reducedMode === false) {
        const line1 = this._buildMessage(dts, motif, unit);
        const line2 = dts.com;
        const h1 = "<label for ='agenda_acttable'><b>" + typ + "</b></label>";
        let h2 = "";
        const statut = dts.sta ? dts.sta : null;
        let statutLibelle = "";

        switch (statut) {
          case "D":
          case "T":
            h2 += "<label class='ui-phx-statut-demande'><b>";
            statutLibelle = i18n.t('common:statuts.demande');
            break;
          case "I":
            h2 += "<label class='ui-phx-statut-en-cours'><b>";
            statutLibelle = i18n.t('common:statuts.en_cours');
            break;
          case "A":
            h2 += "<label class='ui-phx-statut-accepte'><b>";
            statutLibelle = i18n.t('common:statuts.accepte');
            break;
          case "R":
            h2 += "<label class='ui-phx-statut-refuse'><b>";
            statutLibelle = i18n.t('common:statuts.refuse');
            break;
          case "H":
            h2 += "<label class='ui-phx-statut-hors-validation'><b>";
            break;
          case "X":
            h2 += "<label class='ui-phx-demande-suppression'><b>";
            statutLibelle = i18n.t('common:statuts.suppression');
            break;
          default:
            break;
        }
        //  EVO 778 Check recapitulatif
        // let staLib = statutLibelle;
        // if (CWSTR.isBlank(staLib) && this.recapStatus) {
        //   staLib = this.recapStatus.get("lib");
        // }
        // h2 += staLib + "</b></label>";
        h2 += statutLibelle + "</b></label>";
        if (statut !== "H") {
          result = "<table style='width: 100%'><tr><td align='left' width='45%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";
        } else {
          result = "<table style='width: 100%'><tr><td align='left' width='90%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";
        }
        if (!CWSTR.isBlank(line1) && line1.indexOf("null") === -1) {
          result += "<br/>" + line1;
          if (!CWSTR.isBlank(line2) && (statut === "D" || statut === "A")) {
            result += "<br/>" + line2;
          }
        }
      } else {
        let temps = "";

        // check size of unites.
        if ((dts.mod === "UNITE" || dts.mod === "PERIOC") && dts.ude && dts.ufi && dts.deb && dts.fin) {
          let unite = null;
          if (dts.deb === CWTYPE.DATE.dateToStr(dts.deb, CWTYPE.DATE.DEFAULT_MASK_BBDD)) {
            unite = dts.ude;
          } else if (dts.fin === CWTYPE.DATE.dateToStr(dts.fin, CWTYPE.DATE.DEFAULT_MASK_BBDD)) {
            unite = dts.ufi;
          } else {
            unite = dtsModel.AUX_UNI.models[4].get("code");
          }
          temps = ", " + dtsModel.AUX_UNI.get(unite).get("libelle");
        } else if (dts.hed && dts.hef) {
          const heureDebut = CWTYPE.HOUR_MINUTE.format(dts.hed);
          let heureFin = CWTYPE.HOUR_MINUTE.format(dts.hef);

          if (dts.hed > dts.hef) {
            heureFin = "+" + heureFin;
          }
          if (dts.het) {
            temps = ", " + i18n.t('agenda.tt_aff_dea', {
              "0": heureDebut,
              "1": heureFin,
              "2": CWTYPE.DURATION.HOUR_MINUTE.format(dts.het).replace(/ /g, "")
            });
          } else {
            temps = ", " + i18n.t('agenda.tt_aff_deasimp', {
              "0": heureDebut,
              "1": heureFin
            });
          }
        }
        result = "<label for='agenda_actl1'>" + typ + ", " + motif + temps + "</label>";
      }
    }
    return result;
  }

  static getUnit(models: Array<Backbone.Model>, ude: string, ufi: string): { [key: string]: any } {
    const unit: { [key: string]: any } = {};

    _.each(models, (value) => {
      if (!CWSTR.isBlank(ude) && value.get("cod") === ude) {
        unit.abrDe = value.get("abrege");
        unit.libDe = value.get("lib");
      }
      if (!CWSTR.isBlank(ufi) && value.get("cod") === ufi) {
        unit.abrFi = value.get("abrege");
        unit.libFi = value.get("lib");
      }
    });
    return unit;
  }

  static _buildMessage(dts: { [key: string]: any }, motif: string, unit: { [key: string]: any }): string {
    let result = "";
    let hDeb = "";
    let hFin = "";

    //Check dates
    const dateDeb = "</label><span class='agenda_actl1'><b>" + CWTYPE.DATE.format(dts.deb, CWTYPE._getFormatByCode("DATE_A")) + "</b></span><label for='agenda_actl1'>";
    const dateFin = "</label><span class='agenda_actl1'><b>" + CWTYPE.DATE.format(dts.fin, CWTYPE._getFormatByCode("DATE_A")) + "</b></span><label for='agenda_actl1'>";

    //check hours
    if (!CWSTR.isBlank(dts.hed) && !CWSTR.isBlank(dts.hef) && dts.hed !== dts.hef) {
      const hDebAux = dts.hed >= 2400 ? dts.hed - 2400 : dts.hed;
      hDeb = "</label><span class='agenda_actl1'>" + CWTYPE.HOUR_MINUTE.format(hDebAux) + "</span><label for='agenda_actl1'>";
      const hFinAux = dts.hef > 2400 ? dts.hef - 2400 : dts.hef;
      hFin = "</label><span class='agenda_actl1'>" + CWTYPE.HOUR_MINUTE.format(hFinAux) + "</span><label for='agenda_actl1'>";
    }

    if (dts.deb === dts.fin) {
      if (!CWSTR.isBlank(dts.hed) && !CWSTR.isBlank(dts.hef) && dts.hed !== dts.hef && dts.mod === "PLH") {
        result = i18n.t('agenda.act_tt_leh', { "0": dateDeb, "1": hDeb, "2": hFin, "3": motif, interpolation: { escapeValue: false } });
      } else {
        result = i18n.t('agenda.act_tt_le', { "0": dateDeb, "1": unit.libDe, "2": motif, interpolation: { escapeValue: false } });
      }
    } else {
      if (!CWSTR.isBlank(dts.hed) && !CWSTR.isBlank(dts.hef) && dts.hed !== dts.hef && (dts.mod === "PLH" || (dts.mod === "PERIOC" && !dts.ude))) {
        result = i18n.t('agenda.act_tt_duh', { "0": dateDeb, "1": dateFin, "2": hDeb, "3": hFin, "4": motif, interpolation: { escapeValue: false } });
      } else {
        result = i18n.t('agenda.act_tt_du', { "0": dateDeb, "1": unit.libDe, "2": dateFin, "3": unit.libFi, "4": motif, interpolation: { escapeValue: false } });
      }
    }
    let result1 = "<label for='agenda_actl1'>" + result + "</label>";

    if (!CWSTR.isBlank(dts.acp) && !CWSTR.isBlank(dts.acn) &&
      !CWSTR.isBlank(dts.sai) && !CWSTR.isBlank(dts.acd)) {
      const nom = dts.acp.substring(0, 1) + "." + dts.acn;
      const sai = CWTYPE.DATE.format(dts.sai, CWTYPE._getFormatByCode("DATE_A"));
      const acd = CWTYPE.DATE.format(dts.acd, CWTYPE._getFormatByCode("DATE_A"));
      const tooltip = "<label for ='agenda_saidate'>" + i18n.t('agenda.absencetooltipSaisi') + " </label><span class='agenda_saidate'><b>" + sai + "</b><label for='agenda_acp'>" +
        i18n.t('agenda.absencetooltipDerniere') + " </label><span class='agenda_acp'><b>" + nom + "</b></span><label for='agenda_acd'> " + i18n.t('agenda.absencetooltipLe') +
        " </label><span class='agenda_acd'><b>" + acd + "</b></span>";
      const line4 = tooltip;
      if (!CWSTR.isBlank(line4)) {
        result1 += "<br/>" + line4;
      }
    }
    return result1;
  }

  static _buildTooltipAbsence(reducedMode: boolean, absence: any, dts: any, dtsModel: any): string {
    const unit = this.getUnit(dtsModel.AUX_UNI.models, dts.get("val"), dts.get("val"));
    const motif = absence.get("lib") + " (" + absence.get("cod") + ")";
    const dateDeb = "</label><span class='agenda_actl1'><b>" + CWTYPE.DATE.format(absence.get("date"), CWTYPE._getFormatByCode("DATE_A")) + "</b></span><label for='agenda_actl1'>";


    if (unit.abrDe === unit.abrFi) {
      if (unit.abrDe === "J") {
        unit.libDe = i18n.t('screens:motifsabs.unitej');
      }
      if (dts.get("val") === "K") {
        unit.libDe = i18n.t('screens:motifsabs.unitek');
      }
    }

    const line1 = i18n.t('screens:agenda.act_tt_le', { "0": dateDeb, "1": unit.libDe, "2": motif, interpolation: { escapeValue: false } });
    const line3 = dts.com;
    let line4 = "";
    let result = "";

    if (!CWSTR.isBlank(dts.acp) && !CWSTR.isBlank(dts.acn) &&
      !CWSTR.isBlank(dts.sai) && !CWSTR.isBlank(dts.acd)) {
      const nom = dts.acp.substring(0, 1) + "." + dts.acn;
      const sai = CWTYPE.DATE.format(dts.sai, CWTYPE._getFormatByCode("DATE_A"));
      const acd = CWTYPE.DATE.format(dts.acd, CWTYPE._getFormatByCode("DATE_A"));
      const tooltip = "<label for ='agenda_saidate'>" + i18n.t('screens:agenda.absencetooltipSaisi') + " </label><span class='agenda_saidate'><b>" + sai + "</b><label for='agenda_acp'>" +
        i18n.t('screens:agenda.absencetooltipDerniere') + " </label><span class='agenda_acp'><b>" + nom + "</b></span><label for='agenda_acd'> " + i18n.t('screens:agenda.absencetooltipLe') +
        " </label><span class='agenda_acd'><b>" + acd + "</b></span>";
      line4 = tooltip;
    }

    let h1 = "<label for ='agenda_abstable'><b>" + i18n.t('screens:agenda.absencetooltipAbs') + "</b></label>";
    if (dts.sou === true) {
      h1 = "<label for ='agenda_abstable'><b>" + i18n.t('screens:agenda.absencetooltipSou') + "</b></label>";
    }
    let h2 = "";

    let statutLibelle = "";
    switch (dts.get("dts").sta) {
      case "D":
      case "T":
        h2 += "<label class='ui-phx-statut-demande'><b>";
        statutLibelle = i18n.t('common:statuts.demande');
        break;
      case "I":
        h2 += "<label class='ui-phx-statut-en-cours'><b>";
        statutLibelle = i18n.t('common:statuts.en_cours');
        break;
      case "A":
        h2 += "<label class='ui-phx-statut-accepte'><b>";
        statutLibelle = i18n.t('common:statuts.accepte');
        break;
      case "R":
        h2 += "<label class='ui-phx-statut-refuse'><b>";
        statutLibelle = i18n.t('common:statuts.refuse');
        break;
      case "H":
        h2 += "<label class='ui-phx-statut-hors-validation'><b>";
        break;
      default:
        break;
    }

    h2 += statutLibelle + "</b></label>";

    if (dts.sta !== "H") {
      result = "<table style='width: 100%'><tr><td align='left' width='45%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";
    } else {
      result = "<table style='width: 100%'><tr><td align='left' width='90%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";
    }

    if (!CWSTR.isBlank(line1) && line1.indexOf("null") === -1) {
      result += "<br/>" + line1;
    }
    if (!CWSTR.isBlank(line3)) {
      result += "<br/>" + line3;
    }
    if (!CWSTR.isBlank(line4)) {
      result += "<br/>" + line4;
    }

    return result;
  }

  static _buildTooltipHoraire(scheduleModel: Backbone.Model, horaire: any): string {
    let result = null;
    const json = { i18n: i18n };

    if (scheduleModel) {
      const tooltipTemplate = _.template(
        `<fieldset class="agenda_plage_tooltip">
          <table>
              <tr>
                  <td><label for="agenda_horaire"><b><%= i18n.t('agenda.plagHoraire')%></b></label><span class="agenda_horaire"></span></td>
                  <td></td>
              </tr>
              <tr>
                  <td width="50%"><label for="agenda_presence"><%= i18n.t('agenda.plagPresence')%></label><span class="agenda_presence"></span></td>
                  <td width="50%"><label for="agenda_temps"><%= i18n.t('agenda.plagTemps')%></label><span class="agenda_temps"></span></td>
              </tr>
          </table>
          <table>
              <tr>
                  <td width="15%"><label for="agenda_plageGraph"><%= i18n.t('agenda.plagRepart')%></label></td>
                  <td><span class="agenda_plageGraph"></span></td>
              </tr>
          </table>
        </fieldset>`
      );
      const plageView = new ScheduleView(null, scheduleModel);

      $(tooltipTemplate(json)).find(".agenda_plageGraph").html(plageView.render().el);

      // EVO 545 prints presence in the infobulle
      const repos = horaire.get("re");
      const nuit = horaire.get("hn");
      const matin = horaire.get("hm");
      const soir = horaire.get("hs");
      const jour = horaire.get("hj");
      const parametreDiversIndJourneeValeur = (!CWSTR.isBlank(CWDIVERS.get("indJournee"))) ? CWDIVERS.get("indJournee") : "0";
      let presence = "";

      if (repos) {
        presence = i18n.t('agenda.hor_repos');
      } else if (nuit) {
        presence = i18n.t('agenda.hor_nuit');
      } else if (parametreDiversIndJourneeValeur === "0" && matin && soir) {
        presence = i18n.t('agenda.hor_journee');
      } else if (parametreDiversIndJourneeValeur === "1" && matin && soir) {
        presence = i18n.t('agenda.hor_journee_M_A');
      } else if (parametreDiversIndJourneeValeur === "1" && jour) {
        presence = i18n.t('agenda.hor_journee');
      } else if (matin && !soir) {
        presence = i18n.t('agenda.hor_matin');
      } else if (!matin && soir) {
        presence = i18n.t('agenda.hor_apresMidi');
      }

      result = $("<div/>").html(tooltipTemplate(json));
      result.find(".agenda_plageGraph").html(plageView.render().el);
      result.find(".agenda_horaire").text(horaire.get("lib") + " (" + horaire.get("cod") + ")");
      result.find(".agenda_presence").text(presence);
      result.find(".agenda_temps").text(CWTYPE.HOUR_MINUTE.format(horaire.get("tdu"), CWTYPE._getFormatByCode("DUREEHM")));
      result = result.html();
    }

    return result;
  }

  static _uniteListeAbsence(listeAbs: Array<Backbone.Model>): string {
    let lRtn = null;

    //il y aura comme maximum 3 absences et la liste unites fusionnées possibles: M S K N J MN SN
    if (listeAbs && listeAbs.length > 0) {
      let sumUnite = 0;

      //Unite M(valeur 1), S(valeur 4), N(valeur 13), K(valeur 40) et J(valeur 121)
      //Avec ces valeurs et les combinaisons de 1 à 3 absences, on a ces résultats      
      for (let i = 0; i < listeAbs.length; i++) {
        switch (listeAbs[i].get("val")) {
          case "M":
            sumUnite++;
            break;
          case "S":
            sumUnite = sumUnite + 4;
            break;
          case "N":
            sumUnite = sumUnite + 13;
            break;
          case "K":
            sumUnite = sumUnite + 40;
            break;
          case "J":
            sumUnite = sumUnite + 121;
        }
      }
      if (sumUnite >= 121) {
        lRtn = "J";
      } else {
        switch (sumUnite) {
          case 1:
          case 2:
          case 3:
            lRtn = "M";
            break;
          case 4:
          case 8:
          case 12:
            lRtn = "S";
            break;
          case 5:
          case 6:
          case 9:
          case 40:
          case 41:
          case 42:
          case 44:
          case 45:
          case 48:
          case 80:
          case 81:
          case 84:
          case 120:
            lRtn = "K";
            break;
          case 13:
          case 26:
          case 39:
            lRtn = "N";
            break;
          case 14:
          case 15:
          case 27:
            lRtn = "MN";
            break;
          case 17:
          case 21:
          case 30:
            lRtn = "SN";
            break;
          case 18:
          case 53:
          case 54:
          case 57:
          case 93:
            lRtn = "J";
            break;
          default:
            CWLOG.debug("Valeur non prise en compte(_uniteListeAbsence):" + sumUnite, " Liste d'absences:" + listeAbs.length);
        }
      }
    }
    return lRtn;
  }

  static restitutionNuitActivitesPostéesAbsences(UniteVerifier: string, listeAbs: Array<Backbone.Model>, typeGestionActivite: string): boolean {
    let lRtn = false;

    if (listeAbs && listeAbs.length > 0 && !CWSTR.isBlank(typeGestionActivite) && !CWSTR.isBlank(UniteVerifier)) {
      for (let i = 0; i < listeAbs.length; i++) {
        const lVal = (UniteVerifier.length > 1) ? UniteVerifier[1] : UniteVerifier; //liste unites fusionnées: M S K N J MN SN

        if (lVal === listeAbs[i].get("val")) {
          if ((typeGestionActivite === "POGARDE" && listeAbs[i].get("mavarint5") === 1) || (typeGestionActivite === "POASTR" && listeAbs[i].get("mavarint6") === 1)) {
            lRtn = true;
          }
        }
      }
    }
    return lRtn;
  }

  static sortAbsences(listeAbsences: Array<Backbone.Model>): Array<Backbone.Model> {
    //tri 1º par la priorité et si elles sont les mêmes, par unité
    if (!_.isEmpty(listeAbsences) && listeAbsences.length > 1) {
      listeAbsences.sort((infoAbsenceA: Backbone.Model, infoAbsenceB: Backbone.Model) => {
        if (infoAbsenceA.get("dts").pri > infoAbsenceB.get("dts").pri) {
          return 1;
        } else if (infoAbsenceA.get("dts").pri < infoAbsenceB.get("dts").pri) {
          return -1;
        } else {
          //J->1, N->2, K->3, S->4, M->5
          let valUniteA = 999, valUniteB = 999;

          switch (infoAbsenceA.get("val")) {
            case "J":
              valUniteA = 1;
              break;
            case "N":
              valUniteA = 2;
              break;
            case "K":
              valUniteA = 3;
              break;
            case "S":
              valUniteA = 4;
              break;
            case "M":
              valUniteA = 5;
              break;
          }
          switch (infoAbsenceB.get("val")) {
            case "J":
              valUniteB = 1;
              break;
            case "N":
              valUniteB = 2;
              break;
            case "K":
              valUniteB = 3;
              break;
            case "S":
              valUniteB = 4;
              break;
            case "M":
              valUniteB = 5;
              break;
          }
          if (valUniteA > valUniteB) {
            return 1;
          }
          if (valUniteA < valUniteB) {
            return -1;
          }
          return 0;
        }
      });
    }
    return listeAbsences;
  }

  static restitutionActivitesPosteAvecCouverture(posFract: any, dts: any, fractionsCell: JQuery, activite: Backbone.Model, listeFractionnes: Array<number>, typeGestionActivite: string, listeAnnuleCouv: Array<Backbone.Model>): Array<any> {
    const targetDom = fractionsCell;//DOM structure
    const lAbsUnite = CWRENDERMEDICAL._uniteListeAbsence(listeAnnuleCouv);

    switch (dts.uni) {
      case 'M':
        if (lAbsUnite === "S" || lAbsUnite === "N" || lAbsUnite === "SN") {
          const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteM)) {
            posFract.push(lUniteM);
          }
        }
        break;
      case 'S':
        if (lAbsUnite === "M" || lAbsUnite === "N" || lAbsUnite === "MN") {
          posFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        }
        break;
      case 'J':
        if (lAbsUnite === "N") {
          posFract = CWRENDERMEDICAL._uniteJ(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "M" || lAbsUnite === "MN") {
          posFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "S" || lAbsUnite === "SN") {
          const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteM)) {
            posFract.push(lUniteM);
          }
        }
        break;
      case 'N1':
        if (lAbsUnite === "M" || lAbsUnite === "S" || lAbsUnite === "K" ||
          ((lAbsUnite === "J" || lAbsUnite === "N" || lAbsUnite === "MN" || lAbsUnite === "SN") && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          const lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteN1)) {
            posFract.push(lUniteN1);
          }
        }
        break;
      case 'N2':
        if (lAbsUnite === "M" || lAbsUnite === "S" || lAbsUnite === "K" ||
          ((lAbsUnite === "J" || lAbsUnite === "N" || lAbsUnite === "MN" || lAbsUnite === "SN") && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          const lUniteN2 = CWRENDERMEDICAL._uniteN2(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteN2)) {
            posFract.push(lUniteN2);
          }
        }
        break;
      case 'N':
        if (lAbsUnite === "M" || lAbsUnite === "S" || lAbsUnite === "K" ||
          ((lAbsUnite === "J" || lAbsUnite === "N" || lAbsUnite === "MN" || lAbsUnite === "SN") && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts);
        }
        break;
      case 'SN1':
        if (lAbsUnite === "M" || ((lAbsUnite === "N" || lAbsUnite === "MN") && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteSN1(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "S" || lAbsUnite === "K" || ((lAbsUnite === "J" || lAbsUnite === "SN") && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          const lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteN1)) {
            posFract.push(lUniteN1);
          }
        }
        if (((lAbsUnite === "N" || lAbsUnite === "MN") && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        }
        break;
      case 'SNU':
        if (lAbsUnite === "M" || ((lAbsUnite === "N" || lAbsUnite === "MN") && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteSNU(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "S" || lAbsUnite === "K" || ((lAbsUnite === "J" || lAbsUnite === "SN") && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts);
        }
        if (((lAbsUnite === "N" || lAbsUnite === "MN") && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        }
        break;
      case 'D':
        if (lAbsUnite === "M" || (lAbsUnite === "MN" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteSNU(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "S" || (lAbsUnite === "SN" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteM)) {
            posFract.push(lUniteM);
            posFract = posFract.concat(CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts));
          }
        }
        if (lAbsUnite === "K") {
          posFract = CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "N" && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite)) {
          posFract = CWRENDERMEDICAL._uniteJ(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "N" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite)) {
          posFract = CWRENDERMEDICAL._uniteD(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "J" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite)) {
          posFract = CWRENDERMEDICAL._uniteN(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "MN" && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite)) {
          posFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "SN" && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite)) {
          const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteM)) {
            posFract.push(lUniteM);
          }
        }
        break;
      case 'JN1':
        if (lAbsUnite === "M" || (lAbsUnite === "MN" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteSN1(listeFractionnes, targetDom, activite, dts);
        }
        if (lAbsUnite === "S" || (lAbsUnite === "SN" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          let lUnite = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUnite)) {
            posFract.push(lUnite);
          }
          lUnite = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);
          if (!CWSTR.isBlank(lUnite)) {
            posFract.push(lUnite);
          }
        }
        if (lAbsUnite === "K") {
          const lUnite = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUnite)) {
            posFract.push(lUnite);
          }
        }
        if ((lAbsUnite === "N" && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteJ(listeFractionnes, targetDom, activite, dts);
        }
        if ((lAbsUnite === "N" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteJN1(listeFractionnes, targetDom, activite, dts);
        }
        if ((lAbsUnite === "J" && CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          const lUniteN1 = CWRENDERMEDICAL._uniteN1(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteN1)) {
            posFract.push(lUniteN1);
          }
        }
        if ((lAbsUnite === "MN" && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          posFract = CWRENDERMEDICAL._uniteS(listeFractionnes, targetDom, activite, dts);
        }
        if ((lAbsUnite === "SN" && !CWRENDERMEDICAL.restitutionNuitActivitesPostéesAbsences(lAbsUnite, listeAnnuleCouv, typeGestionActivite))) {
          const lUniteM = CWRENDERMEDICAL._uniteM(listeFractionnes, targetDom, activite, dts);

          if (!CWSTR.isBlank(lUniteM)) {
            posFract.push(lUniteM);
          }
        }
        break;
    }
    return posFract;
  }
}
