import _ from 'underscore';
import { CWCriteresModel } from './cwCriteres.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';

/**
 * Collection of etats for the rigth side.
 */
export class CWCriteresColl<TModel extends CWCriteresModel = CWCriteresModel> extends CWPaginatedCollection<TModel> {

  thcollab: boolean;
  id: string | number;

  constructor(models?: Array<TModel> | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWCriteresModel;
    super(models, options);
    this.version = "2.2.2";
    this.usePopulation = true;
    CWPaginatedCollection.prototype.initialize.call(this);
    this.sortings = { libelle: true };
    this.paginated = false;
    this.url = (): string => {
      let criteresPath = Configuration.restRoot + "/rest/edition/definition/" + encodeURIComponent(this.id) + "/critere?thcollab=false";

      if (this.thcollab && this.thcollab === true) {
        criteresPath = Configuration.restRoot + "/rest/edition/definition/" + encodeURIComponent(this.id) + "/critere?thcollab=true"
      }
      return criteresPath;
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
}