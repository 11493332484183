import * as _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { TypeStructuresColl } from './typeStructures.collection';

/**
 * Lister Gerer Prets Model
 */
export class CWGererPretsModel extends CWBaseModel {

  /**
   * Example of data
     * "PRTSVARDAT2" :{ // JSON correspondant à une caractéristique de type date
        "code":"PRTSVARDAT2"
        "valeur":"20141118"
        "type" : "D",
        "oblig" : true,
        "libelle" : "Lib date",
        "datemin" : "",
        "datemax" : ""
      },
     "PRTSVARCHAR3" : { // JSON correspondant à une caractéristique de type chaine
        "code":"PRTSVARCHAR3"
        "valeur":"Caract1"
        "type" : "C",
        "oblig" : true,
        "libelle" : "Lib chaine",
        "codif" : ""
      },
      "PRTSVARINT1" : { // JSON correspondant à une caractéristique de type numérique
        "code":"PRTSVARINT1"
        "valeur":"12"
        "type" : "N",
        "oblig" : true,
        "libelle" : "Lib num",
        "masque" : "",
        "nummin" : 5,
        "nummax" : 15,
        "codif" : ""
      }
     */

  MODEGROUPE: boolean;
  structures: any[];
  action: string;
  profil: any;
  typeStructures: typeof TypeStructuresColl;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "collaborateur": {
        "matricule": null,
        "matriculeaux": "",
        "nom": "",
        "prenom": ""
      },
      "type": {
        "code": "",
        "libelle": ""
      },
      "emprunt": null,
      "createur": null,
      "perimetre": {
        "code": "",
        "libelle": ""
      },
      "collabremplace": {
        "nom": "",
        "prenom": "",
        "matric": null,
        "matricaux": "",
        "datedeb": "",
        "datefin": ""
      },
      "structures": null,
      "typesaisie": "D",
      "modesaisie": "UNITE",
      "datedeb": "",
      "datefin": "",
      "unitedeb": {
        "code": "",
        "libelle": ""
      },
      "modesai_periodeb": {
        "code": "",
        "libelle": ""
      },
      "modesai_periofin": {
        "code": "",
        "libelle": ""
      },
      "heuredeb": "",
      "heurefin": "",
      "duree": "",
      "pourcentage": "",
      "dureecalculee": "",
      "motif": {
        "code": "",
        "libelle": ""
      },
      "origine": {
        "code": "",
        "libelle": ""
      },
      "libelle": "",
      "commentaire_pret": "",
      "demandesuppression": null,
      "statut": {
        "code": "",
        "libelle": ""
      },
      "commentaire": "",
      "etat": "",
      "indicateurpj": null,
      "est_createur": null,
      "infocomp": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.MODEGROUPE = false;
    //		this.set("infocomp",{});
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
    this.structures = [];
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);

      if (this.action === "saisiegroupee") {
        return Configuration.restRoot + "/rest/composant/pret/" + this.action;
      }
      return Configuration.restRoot + "/rest/composant/pret/" + this.action + "/" + id;
    }
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    let errorMsg;

    if (CWSTR.isBlank(attrs.type.code)) {
      errors.type = {};
      errors.type.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.type') });
    }
    if (CWSTR.isBlank(attrs.perimetre.code)) {
      errors.perimetre = {};
      errors.perimetre.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.perimetre') });
    }
    if (CWSTR.isBlank(attrs.motif.code)) {
      errors.motif = {};
      errors.motif.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.motif') });
    }
    // Dates
    if (attrs.typesaisie === "D") {
      if (CWSTR.isBlank(attrs.datedeb)) {
        errors.datedeb = i18n.t('common:required', { "0": i18n.t('common:gererprets.datedebut') });
      } else {
        errorMsg = CWTYPE.DATE.validate(attrs.datedeb);
        if (!CWSTR.isBlank(errorMsg)) {
          errors.datedeb = errorMsg;
        }
      }
    } else {
      //Period validation
      const datedeb = {
        "name": "datedeb",
        "value": attrs.datedeb,
        "label": i18n.t('common:gererprets.datedebut')
      };
      const datefin = {
        "name": "datefin",
        "value": attrs.datefin,
        "label": i18n.t('common:gererprets.datefin'),
        "isInfinity": false
      };
      const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);

      if (!CWSTR.isBlank(errorMsg)) {
        errors.datedeb = errorMsg.datedeb;
        errors.datefin = errorMsg.datefin;
      }
    }
    if (attrs.typesaisie === "P") {
      if (!CWSTR.isBlank(attrs.modesai_periofin) && CWSTR.isBlank(attrs["modesai_periofin"].code)) {
        errors["modesai_periofin"] = {};
        errors["modesai_periofin"].code = i18n.t('common:required', { "0": i18n.t('common:gererprets.unite_fin') });
      }
      if (!CWSTR.isBlank(attrs.modesai_periodeb) && CWSTR.isBlank(attrs["modesai_periodeb"].code)) {
        errors["modesai_periodeb"] = {};
        errors["modesai_periodeb"].code = i18n.t('common:required', { "0": i18n.t('common:gererprets.unite_deb') });
      }
    }
    if (attrs.typesaisie !== "P") {
      if (CWSTR.isBlank(attrs.unitedeb.code)) {
        errors.unitedeb = {};
        errors.unitedeb.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.unite') });
      }
    }
    if (CWSTR.isBlank(attrs.datefin)) {
      errors.datefin = i18n.t('common:required', { "0": i18n.t('common:gererprets.datefin') });
    } else {
      errorMsg = CWTYPE.DATE.validate(attrs.datefin);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.datefin = errorMsg;
      }
    }
    if (CWSTR.isBlank(errors.datefin) && CWSTR.isBlank(errors.datedeb) && attrs.datedeb > attrs.datefin) {
      errors.datefin = i18n.t('messages:GT_224');
    }
    if (attrs.typesaisie === "P") {
      if (CWSTR.isBlank(errors.datefin) && CWSTR.isBlank(errors.datedeb) && attrs.datedeb === attrs.datefin) {
        errors.datefin = i18n.t('messages:GT_1345');
      }
    }
    _.each(this.structures, (struct: { [key: string]: any }): void => {
      const modelStruct: { [key: string]: any } = _.find(attrs.structures, { type: struct.type });
      if ((!modelStruct || CWSTR.isBlank(modelStruct.element)) && struct.rattcollab === "O") {
        let infoTextStructure = i18n.t('common:gererprets.structure');

        if (modelStruct.type && this.typeStructures?.get(modelStruct.type) && !CWSTR.isBlank(this.typeStructures.get(modelStruct.type).get("libelle"))) {
          infoTextStructure = this.typeStructures.get(modelStruct.type).get("libelle");
        }
        errors["structures_itemid_" + struct.type] = {};
        errors["structures_itemid_" + struct.type] = i18n.t('common:required', { "0": infoTextStructure });
      }
    });
    //Infocomp
    _.each(attrs.infocomp, (item: { [key: string]: any }): void => {
      if (CWSTR.isBlank(item.valeur)) {
        if (item.oblig) {
          if (CWSTR.isBlank(errors.infocomp)) {
            errors.infocomp = {};
          }
          errors.infocomp[item.code] = {};
          errors.infocomp[item.code].valeur = i18n.t('common:required', { "0": item.libelle });
        }
      } else {
        switch (item.type) {
          case "D":
            errorMsg = CWTYPE.DATE.validate(item.valeur);
            if (!CWSTR.isBlank(errorMsg)) {
              if (CWSTR.isBlank(errors.infocomp)) {
                errors.infocomp = {};
              }
              errors.infocomp[item.code] = {};
              errors.infocomp[item.code].valeur = errorMsg;
            } else if ((!CWSTR.isBlank(item.datemin) && item.valeur < item.datemin) || (!CWSTR.isBlank(item.datemax) && item.valeur > item.datemax)) {
              if (CWSTR.isBlank(errors.infocomp)) {
                errors.infocomp = {};
              }
              errors.infocomp[item.code] = {};
              errors.infocomp[item.code].valeur = i18n.t('messages:GT_1040', { "1": item.libelle, "2": CWTYPE.DATE.format(item.datemin), "3": CWTYPE.DATE.format(item.datemax) });
            }
            break;
          case "N":
            if (!CWSTR.isBlank(item.masque)) {
              if (!CWSTR.isBlank(CWTYPE.CUSTOM.validate(item.valeur, item.nummin, item.nummax))) {
                if (CWSTR.isBlank(errors.infocomp)) {
                  errors.infocomp = {};
                }
                errors.infocomp[item.code] = {};
                errors.infocomp[item.code].valeur = i18n.t('messages:GT_1041', { "1": item.libelle, "2": item.nummin, "3": item.nummax });
              }
            } else {
              const errorNum = CWTYPE.LONG.validate(item.valeur, item.nummin, item.nummax);
              if (!CWSTR.isBlank(errorNum)) {
                if (CWSTR.isBlank(errors.infocomp)) {
                  errors.infocomp = {};
                }
                errors.infocomp[item.code] = {};
                errors.infocomp[item.code].valeur = errorNum;
              }
            }
            break;
        }
      }
    });
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      if (this.MODEGROUPE === false) {
        if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
          response.id = response.code;
        }
      }
      if (response.result) {
        return null;
      }
    }
    return response;
  }
}
