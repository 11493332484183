module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc" data-bloc-name="D" data-bloc-label="'+
((__t=(i18n.t('common:gererprets.date')))==null?'':__t)+
'">\r\n    <div class="form-group">\r\n        <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.du")))==null?'':__t)+
'</label>\r\n        <div class="c-date-selector input-group">\r\n            <input type="text" class="datedeb dateDebD typeDate periodStart form-control cw-required" periodId="1" value="" size="17" />\r\n        </div>\r\n        <span class="datedeb-error-container"></span>\r\n    </div>\r\n</div>\r\n<div class="cw-multiLabel-bloc" data-bloc-name="P" data-bloc-label="'+
((__t=(i18n.t('common:gererprets.periode')))==null?'':__t)+
'">\r\n    <div class="multiLabelTypeSaisie">\r\n        <fieldset class="multiLabelTypeSaisie_form cw-fieldset" style="display:table;vertical-align:top">\r\n            <div class="row">\r\n                <div class="form-group col">\r\n                    <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.periodeDeb")))==null?'':__t)+
'</label>\r\n                    <div class="c-date-selector input-group">\r\n                        <div class="input-group-prepend">\r\n                            <div for="datedeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.du")))==null?'':__t)+
'</div>\r\n                        </div>\r\n                        <input type="text" class="datedeb dateDebP typeDate periodStart form-control cw-required" periodId="1" value="" size="10" />\r\n                    </div>\r\n                    <span class="datedeb-error-container"></span>\r\n                </div>\r\n                <div class="form-group col">\r\n                    <label for="modesai_periodeb.code" style="display:none" class="phx-inline-label"></label>\r\n                    <span class="comboUniteDeb"></span>\r\n                    <span class="heuredeb-container">\r\n                        <div>\r\n                            <input type="text" class="heuredeb typeHourMinuteNightly" size="5">\r\n                        </div>\r\n                    </span>\r\n                </div>\r\n            </div>\r\n\r\n            <div class="row">\r\n                <div class="form-group col">\r\n                    <label for="datefin" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.periodeFin")))==null?'':__t)+
'</label>\r\n                    <div class="c-date-selector input-group">\r\n                        <div class="input-group-prepend">\r\n                            <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.au")))==null?'':__t)+
'</div>\r\n                        </div>\r\n                        <input type="text" class="datefin dateFinP  typeDate periodEnd form-control" periodId="1" value="" size="10" />\r\n                    </div>\r\n                    <span class="datefin-error-container"></span>\r\n                </div>\r\n\r\n                <div class="form-group col">\r\n                    <label for="modesai_periofin.code" style="display:none" class="phx-inline-label"></label>\r\n                    <span class="comboUniteFin"></span>\r\n                    <span class="heurefin-container">\r\n                        <div>\r\n                            <input type="text" class="heurefin typeHourMinuteNightly" size="5">\r\n                        </div>\r\n                    </span>\r\n                </div> \r\n            </div>\r\n        </fieldset>\r\n    </div>\r\n    <span class="label-presencehorspresence-periode"></span>\r\n</div>\r\n<div class="cw-multiLabel-bloc" data-bloc-name="R" data-bloc-label="'+
((__t=(i18n.t('common:gererprets.repetition')))==null?'':__t)+
'">\r\n    <div class="row">\r\n        <div class="form-group col">\r\n            <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.periodeDeb")))==null?'':__t)+
'</label>\r\n            <div class="c-date-selector input-group">\r\n            <div class="input-group-prepend">\r\n                <div for="datedeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.du")))==null?'':__t)+
'</div>\r\n            </div>\r\n            <input type="text" class="datedeb dateDebR  typeDate periodStart form-control" periodId="1" value="" size="10" />\r\n            </div>\r\n            <span class="datedeb-error-container"></span>\r\n        </div>\r\n    \r\n        <div class="form-group col">\r\n            <div class="c-date-selector input-group">\r\n            <div class="input-group-prepend">\r\n                <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.au")))==null?'':__t)+
'</div>\r\n            </div>\r\n            <input type="text" class="datefin dateFinR  typeDate periodEnd form-control" periodId="1" value="" size="10" />\r\n            </div>\r\n            <span class="datefin-error-container"></span>\r\n        </div>\r\n    </div>\r\n</div>';
}
return __p;
};
