import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWWkfHistoModel } from './cwWkfHisto.model';


export class CWHistoEventModelColl<TModel extends CWWkfHistoModel = CWWkfHistoModel> extends CWPaginatedCollection<TModel> {

  public ctxTypeEvenement: string;
  public ctxRefEvenement: string;
  public evenements: string;
  public demandes: string;
  public ctxDemId: string;
  public ctxModeRech: string;

  constructor(models?: TModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    super(models, options);
    this.paginated = false;
    this.url = (): string => {
      const newUrl = Configuration.restRoot + "/rest/wkf/histo/evenement/" + encodeURIComponent(this.ctxTypeEvenement + "," + this.ctxRefEvenement);
      return newUrl;
    }
  }
}