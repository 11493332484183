/**
 * Utilities to configure and manage the layouts
 */
export var LAYOUT = {

  //correction par le focus de l'erreur du "fetch"
  adjustScrollByFetchError: { "etat": false, "position": null },

  /**
   * Synchronize horizontal scrollbars from 2 elements.
   */
  _synchronizeScrollHorizontal: function(divElA, divElB) {
    divElA.scroll(function() {
      divElB.scrollLeft(divElA.scrollLeft());
    });
  },

  /**
   * Synchronize vertical scrollbars from 2 elements.
   */
  _synchronizeScrollVertical: function(divElA, divElB) {
    divElA.scroll(function() {
      divElB.scrollTop(divElA.scrollTop());
    });
  },

  /**
   * Removes the scroll synchronization of an element.
   */
  _desSynchronizeScroll: function(divEl) {
    divEl.off('scroll');
  }
};
