module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-panneaux-deroulant">    \n    <div class="table_ResultatsCompteurs"></div>\n    <div class="cwResultats-buttons">\n        <!--<button type="button" class="cwBtnConfirmer" value="btnOK">'+
((__t=(i18n.t("common:confirmer")))==null?'':__t)+
'</button>\n        <button type="button" class="cwBtnAnnuler" value="btnAnnuler">'+
((__t=(i18n.t("common:cancel")))==null?'':__t)+
'</button>-->\n    </div>\n\n</div>';
}
return __p;
};
