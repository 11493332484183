import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWPretModel } from './cwPret.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Collection of prets
 */
export class CWPretColl<TModel extends CWPretModel = CWPretModel> extends CWPaginatedCollection<TModel>{
  /**
   * Add this to have Outline
   *
   */

  habilitationV: string;
  habilitationG: string;
  //usecase: string;
  filtreStatus: string;
  filtreDatedeb: string;
  filtreDatefin: string;
  filtreAvenir: string;
  filtrePretrecus: string;
  filtreTypepret: string;
  matricule: string;
  filtrePrets: string;
  piecejointe: boolean;
  nbpretEtatValid: number;
  nbpretEtatA: number;
  nbpretEtatR: number;
  pretEtatValid: string;

  constructor(models?: CWPretModel[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWPretModel;
    super(models, options);
    //Default values from class
    this.habilitationV = "N";
    this.habilitationG = "N";
    //this.usecase = "aujourdhui";
    this.url = (): string => {
      const optionalParams = this._buildURL();
      return Configuration.restRoot + "/rest/composant/pret/liste" + optionalParams;
    }
    this.paginated = true;
  }

  setSortings(ctxTriDate: string): void {
    const asc = (ctxTriDate === "Ascendant") ? true : false;

    this.sortings = { periode: asc };
  }

  /**
   * Set parametres to the collection from context
   */
  setParametres(context: { [key: string]: any }, maxPieces?: Backbone.Model, paramDivPieceJointe?: Backbone.Model): void {
    if (context.ctxFiltreApplique && context.ctxFiltreApplique.length > 1) {
      this.filtreStatus = context.ctxFiltreApplique.join(",");
    } else {
      this.filtreStatus = "";
    }
    if (context.ctxPeriode && context.ctxPeriode.datedeb) {
      this.filtreDatedeb = context.ctxPeriode.datedeb;
    }
    if (context.ctxPeriode && context.ctxPeriode.datefin) {
      this.filtreDatefin = context.ctxPeriode.datefin;
    }
    if (context.ctxEncoursOuAVenir) {
      this.filtreAvenir = context.ctxEncoursOuAVenir;
    }
    if (context.ctxPretRecus && context.ctxPretRecus !== false) {
      this.filtrePretrecus = context.ctxPretRecus;
      // when filtre_pretrecus not false
      if (context.ctxTypePret) {
        this.filtreTypepret = context.ctxTypePret;
      }
    }
    if (context.ctxCollab && (context.ctxCollab.matricule)) {
      this.matricule = context.ctxCollab.matricule;
    }
    if (context.ctxPretsID && !CWSTR.isBlank(context.ctxPretsID)) {
      this.filtrePrets = context.ctxPretsID.join(",");
    }
    if (CWSTR.isBlank(maxPieces) || maxPieces.get("maxi") <= 0 ||
      paramDivPieceJointe.get("valeur") !== "1") {
      this.piecejointe = false;
    } else {
      this.piecejointe = true;
    }
  }

  /**
   * Build the URL by the params
   */
  _buildURL(): string {
    let params = "";

    // Filtre en fonction de leur statut.
    params += "?filtre_statuts=" + encodeURIComponent(this.filtreStatus);
    // Periode-Date debut
    if (!CWSTR.isBlank(this.filtreDatedeb)) {
      params += "&datedeb=" + encodeURIComponent(this.filtreDatedeb);
    }
    // Periode-Date fin
    if (!CWSTR.isBlank(this.filtreDatefin)) {
      params += "&datefin=" + encodeURIComponent(this.filtreDatefin);
    }
    // Emprunts en cours ou à venir
    if (!CWSTR.isBlank(this.filtreAvenir)) {
      params += "&filtre_avenir=" + encodeURIComponent(this.filtreAvenir);
    }
    if (!CWSTR.isBlank(this.piecejointe)) {
      params += "&piecejointe=" + encodeURIComponent(this.piecejointe);
    }
    if (!CWSTR.isBlank(this.filtrePrets)) {
      params += "&filtre_prets=" + encodeURIComponent(this.filtrePrets);
    }
    // Si afficher tous les prêts qui sont affectés
    if (!CWSTR.isBlank(this.filtrePretrecus)) {
      params += "&filtre_pretrecus=" + encodeURIComponent(this.filtrePretrecus);
    }
    // Filtrer les prêts selon PretEstUnEmprunt
    if (this.filtreTypepret) {
      params += "&filtre_typepret=" + encodeURIComponent(this.filtreTypepret);
    }
    return params;
  }
}
