import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { i18n } from 'src/i18n.js';
import { MessageColl } from '../models/message.collection.js';
import { MessageModel } from '../models/message.model.js';
import { CWMSGS } from 'utils/cwMsgs';
import { ResultControlesPopUpView } from './resultControlesPopUp.view.js';
import { STR } from 'utils/str.js';
import { ResultcontrolesWorkflow } from '../models/resultcontroles.workflow.js';

/**
 * Main View
 */
export var ResultControlesView = Backbone.View.extend({

  initialize: function(options) {
    options = options || {};
    this.context = options.context;
    this.workflow = new ResultcontrolesWorkflow();
    this.workflow.context = options.context;
    this.model = options.messagesModel;
    this.model.setHabContext(options.habilitations);
    this.workflow.model = this.model;
    this.actionOk = false;
    this.action = "";
    if (options.originalModel) {
      this.originalModel = options.originalModel;
    }
    if (options.actionModel) {
      this.actionModel = options.actionModel;
    }
    this._loadData();
    this.dialog = this._initPopupView();
  },

  _loadData: function() {
    var self = this;

    _.each(this.model.get("groupes"), function(item) {
      switch (item.code) {
        case "SAISIE":
          self.workflow.saisieColl = new MessageColl();
          self._loadMessages(self.workflow.saisieColl, item);
          //self.workflow.saisieColl.setHabContext(new HabilitationContext(""));
          break;
        case "RECAPITULATIF":
          self.workflow.recapColl = new MessageColl();
          self._loadMessages(self.workflow.recapColl, item);
          //self.workflow.recapColl.setHabContext(self.workflow.context);
          break;
      }
    });
    if (!this.workflow.blocked) {
      var ok = _.find(this.model.get("boutons"), function(item) {
        return item.libelle === "i18n_ok";
      });

      if (!STR.isBlank(ok)) {
        this.workflow.action = ok.action;
      }
    }

    this.workflow.recap = this.model.get("identifiant");
    this.workflow.collab = this.model.get("collab");
  },

  //Create message collection
  _loadMessages: function(col, data) {
    var cont = 0;

    if (data.messagesbloquants.length > 0) {
      this.workflow.blocked = true;
      _.each(data.messagesbloquants, function(item) {
        var mod = new MessageModel(item);

        mod.set("bloquant", true);
        col.add(mod);
        cont++;
      });
    }
    //Save number of messages
    if (data.code === "SAISIE") {
      this.workflow.saisieNum.bloquants = cont;
    } else {
      this.workflow.recapNum.bloquants = cont;
    }
    cont = 0;
    _.each(data.messagesnonbloquants, function(item) {
      var mod = new MessageModel(item);

      mod.set("bloquant", false);
      col.add(mod);
      cont++;
    });
    //Save number of messages
    if (data.code === "SAISIE") {
      this.workflow.saisieNum.nonbloquants = cont;
    } else {
      this.workflow.recapNum.nonbloquants = cont;
    }
  },

  _initPopupView: function() {
    if ((!STR.isBlank(this.workflow.saisieColl) && this.workflow.saisieColl.length > 0) || (!STR.isBlank(this.workflow.recapColl) && this.workflow.recapColl.length > 0)) {
      var isModal = false;
      var self = this;
      var dialog = null;

      if (this.workflow.context.ctxModePopup === "Modale" || this.workflow.context.ctxUtilisation === "Traitement") {
        isModal = true;
      }
      dialog = new CWDialogView({
        view: ResultControlesPopUpView,
        viewData: {
          workflow: self.workflow,
          modal: isModal,
          originalModel: this.originalModel,
          actionModel: this.actionModel,
          context: this.context
        }
      });
      if (!STR.isBlank(this.workflow.context.ctxTitrePopup)) {
        dialog._setTitle(this.workflow.context.ctxTitrePopup);
      } else {
        dialog._setTitle(i18n.t('common:resultcontroles.popup_default_title'));
      }
      dialog.setHeight("auto");
      dialog.setWidth(900);
      dialog.setPosition({ my: "center", at: "center", of: window });
      return dialog;
    } else {
      return null;
    }
  },

  open: function(options) {
    if (!STR.isBlank(this.dialog)) {
      var self = this;
      var callback = function() {
        if (self.dialog.actionOk) {
          if (options && options.success) {
            options.success();
          }
        } else {
          if (options && options.error) {
            options.error();
          }
        }
      };

      this.dialog.open(callback);
    } else {
      var notification = {};
      notification.icone = "?";
      notification.titre = "i18n_information";
      notification.message = i18n.t('common:resultcontroles.no_messages');
      notification.boutons = [];
      notification.boutons[0] = {};
      notification.boutons[0].libelle = "i18n_ok";
      notification.boutons[0].action = "";
      if (options && options.success) {
        CWMSGS.showNotification(notification, options.success, null, this.$appendTo);
      } else {
        CWMSGS.showNotification(notification, null, null, this.$appendTo);
      }
    }
  }

});
