import { BaseModel } from 'core/models/base.model.js';

export var RecapHistoArray = BaseModel.extend({

  defaults: {
    "type": "",
    "evenement": "",
    "iddemande": null
  },

  url: Configuration.restRoot + "/rest/wkf/histo/evenement"

  /*save : function(error, options) {
		this.attributes = [
		       		   {
		     		      "action":"CREAT",
		     		      "date":"20140108",
		     		      "heure":1526,
		     		      "auteur": "Lucas P.",
		     		      "commentaire" : "création commentaire"
		     		   },
		     		   {
		     		      "action":"SOUM_D",
		     		      "date":"20140109",
		     		      "heure":1211,
		     		      "auteur": "Lucas P.",
		     		      "commentaire" : "soumission commentaire"
		     		   },
		     		   {
		     		      "action":"ACC_I",
		     		      "date":"20140109",
		     		      "heure":1635,
		     		      "auteur": "Pepe",
		     		      "commentaire" : "acceptation commentaire"
		     		   },
		     		   {
		     		      "action":"DEM_REVV",
		     		      "date":"20150110",
		     		      "heure":0910,
		     		      "auteur": "Lucas P.",
		     		      "commentaire" : "demande rev commentaire"
		     		   },
		     		   {
		     		      "action":"REV_V",
		     		      "date":"20150112",
		     		      "heure":1526,
		     		      "auteur": "Pepe",
		     		      "commentaire" : "révision commentaire"
		     		   },
		     		   {
		     		      "action":"ACC_F",
		     		      "date":"20150113",
		     		      "heure":1407,
		     		      "auteur": "Pepe",
		     		      "commentaire" : "acceptation def commentaire"
		     		   }
		     		];

		options.success(this);
        this.trigger('sync', this);
        return true;
	}*/
});
