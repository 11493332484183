import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import TPLAppZoneItem from '../zone_item.tpl.html';
import { CWPopulationMenuView } from 'common/populationsmenu/views/cwPopulationMenu.view';
import { CWSTR } from 'utils/cwStr';
import { CWWorkFlowModel } from '../models/cwWorkFlow.model';
import { LOG } from 'utils/log.js';
import { NAV } from 'utils/nav.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';


/**
 * Zone Selector View, Application Header
 */
export class CWZoneSelectorView<TModel extends CWWorkFlowModel = CWWorkFlowModel> extends Backbone.View<TModel> {

  _zones: { [key: string]: any };
  components: Array<any>;

  constructor(options: Backbone.ViewOptions<TModel> | any) {
    options = options || {};
    options.tagName = options.tagName || "span";
    options.events = _.extend({
      "click .phx-zones-menu > li": "_clickListener"
    }, options.events);
    super(options);
    this.template = TPLAppZoneItem;
    //Store as constant the ids of the zones
    this._zones = _.keys(this.model.ZONES);
    this.model.on("change:zone", this._manageZoneChange, this);
    _.each(this._zones, (zone: string) => {
      this.model.on("change:tabs-" + zone, this._numTabsChanged, this);
    }, this);
    // listen resize event
    this.listenTo(Backbone, "resize:views", this._resizeHeader);
    this.listenTo(Backbone, "resize:header", this._resizeHeader);
    this.listenTo(this.model, "hideZone", this._hideZone);
    this.listenTo(this.model, "showZone", this._showZone);
    //this.listenTo(GLOBAL_DATA.rights, "sync", this._checkPopulations);
  }

  _checkPopulations(): void {
    if (this.model.RESP_POPULATION.showMenu === true) {
      this._loadPopulations();
    }
  }

  _loadPopulations(): void {
    const component = [];

    if (this.model.headerView.$el.find(".phx-populations-menu-zone").length) {
      this.model.headerView.$el.find(".phx-populations-menu-zone-container").empty();
    }
    component[0] = "populationsmenu";
    objs.populationMenu = new CWPopulationMenuView({ "appendTo": $("#cw-appContainer") });
    this.model.headerView.$el.find('.phx-populations-menu-zone-container').append(objs.populationMenu.render().el);
    if (this.model.get("zone") !== "resp") {
      this.$el.find(".phx-populations-menu-zone").css("display", "none");
    }
  }

  _clickListener(event: JQueryEventObject): boolean {
    let module = null;
    let type = null;
    let btnClicked = null;
    let zoneId = null;
    let lbHome = false;

    if ($(event.target).parent().parent().hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    btnClicked = event.currentTarget.id;
    zoneId = btnClicked.replace("phx-btn-zone-", "");
    //Mark ARIA to indentify whichi option is checked.
    this._updateAriaChecked(event.currentTarget);
    this._updateBodyClass(zoneId);

    if (!CWSTR.isBlank(this.model.get("selectedTab-" + zoneId))) {
      module = this.model.get("selectedTab-" + zoneId).module;
      type = this.model.get("selectedTab-" + zoneId).type;
    }
    if (!CWSTR.isBlank(zoneId) && CWSTR.isBlank(module) && CWSTR.isBlank(type)) {
      lbHome = true;
    }
    this.model.menuView._toogleMenu(lbHome);
    NAV.navigate(zoneId, module, type);
    return true;
  }

  _updateBodyClass(zone: string): void {
    $("body").removeClass("cw");
    if (zone === "coll" || zone === "resp") {
      $("body").addClass("cw"); // Chronotime Workspace
    } else {
      $("body").addClass("ct"); // Chronotime
    }
  }

  _hideZone(zone: { [key: string]: any }): void {
    this.$el.find(".phx-zones-menu li#phx-btn-zone-" + zone.id).addClass("ui-state-disabled");
  }

  _showZone(zone: { [key: string]: any }): void {
    this.$el.find(".phx-zones-menu li#phx-btn-zone-" + zone.id).removeClass("ui-state-disabled");
  }

  /**
   * Updates the view to the new zone selected
   */
  _manageZoneChange(): void {
    const zone = this.model.get("zone");
    const lPos = $("#phx-btn-zone-" + zone, this.$el);

    // Set all zones to default state
    _.each(this._zones, (tempZone: string) => {
      $("#phx-btn-zone-" + tempZone, this.$el).removeClass("ui-state-active");
    }, this);
    // Set selected zone to active state
    lPos.addClass("ui-state-active");
    //nettoyer l'option de menu sélectioné
    this.$el.find(".cw-icon.cw-zone-logo").removeClass("cw-zone-logo-selected");
    //ajouter la "class" de sélecction à l'option choisée
    lPos.find(".cw-icon.cw-zone-logo").addClass("cw-zone-logo-selected");
    if (objs.gmsituation) {
      objs.gmsituation.close();
      objs.gmsituation = null;
    }
    if (zone === "resp") {
      this.model.headerView.$el.find(".phx-populations-menu-zone").css("display", "flex");
    } else {
      this.model.headerView.$el.find(".phx-populations-menu-zone").css("display", "none");
    }
    this._moveMenuComponentsToZone();
  }

  /*
   * Update data for accesibility
   */
  _updateAriaChecked(target: BaseJQueryEventObject["currentTarget"]): void {
    _.each(this.$el.find(">.phx-zones-menu > li"), (liElement) => {
      $(liElement).attr("aria-checked", "false");
    });
    $(target).attr("aria-checked", "true");
  }

  _numTabsChanged(model: Backbone.Model): void {
    const zone = _.find(_.keys(model.changed), function (element) { return element.indexOf("tabs-") === 0; }).split("-")[1];
    const numTabs = this.model.get("tabs-" + zone);
    let zname = $("#phx-btn-zone-" + zone, this.$el).html().split("(")[0];

    LOG.debug("Num Tabs of " + zone + " has changed to : " + numTabs);
    if (numTabs > 0) {
      zname = zname + " (" + numTabs + ")";
    }
    $("#phx-btn-zone-" + zone, this.$el).html(zname);
  }

  render(): CWZoneSelectorView<TModel> {
    const containerdiv = "<div class='phx-zones-menu' role='menubar'  style='display:flex'></div>";

    this.$el.empty();
    this.$el.append(containerdiv);
    _.each(this.model.configuration.get("confignav").get("zones"), (zone: { [key: string]: any }, index: number) => {
      const lAddClass = "cw-zone-logo";
      const lIcone = UTILS.getSVGIcon(zone.icone.slice(0, -4), lAddClass, 40, zone.nom);
      const json = { "id": zone.id, "icone": (lIcone) ? lIcone : "" };
      let zoneHtml = this.template(json);

      if (index === this._zones.length - 1) {
        // Remove the last "|" char
        zoneHtml = zoneHtml.substring(0, zoneHtml.length - 1);
      }
      this.$el.find(".phx-zones-menu").append(zoneHtml);
      if (index === 0) {
        this.$el.find(".phx-zones-menu").find("li").last().attr("aria-checked", "true");
        //on sélectione la zone
        this.$el.find(".cw-icon.cw-zone-logo").addClass("cw-zone-logo-selected");
      } else {
        this.$el.find(".phx-zones-menu").find("li").last().attr("aria-checked", "false");
      }
      //Prepare keyboard Navigation      
      this.$el.find(".phx-zones-menu #phx-btn-zone-" + zone.id).keydown((e: { [key: string]: any }) => {
        this._zoneSelectorKeyboardNav(e);
      });
      if (zone.id === "resp") {
        this._checkPopulations();
      }
    }, this);
    this.$el.attr("role", "navigation");
    this.$el.attr("tabindex", "0");
    this.$el.append("<div class='phx-menus-transversal phx-menus-zone'></div>");
    //Create transversal menus
    this._loadComponents();
    return this;
  }

  _zoneSelectorKeyboardNav(e: { [key: string]: any }): void {
    if (e.keyCode === 37) { // left
      e.preventDefault();
      if ($(e.target).prevAll('li').first().length === 0) {
        $(e.target).parent('.phx-zones-menu').find('> li').last().focus();
      } else {
        $(e.target).prevAll('li').first().focus();
      }
    } else if (e.keyCode === 39) { // right
      e.preventDefault();
      if ($(e.target).nextAll('li').first().length === 0) {
        $(e.target).parent('.phx-zones-menu').find('> li').first().focus();
      } else {
        $(e.target).nextAll('li').first().focus();
      }
    } else if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 40) { //enter, spacebar, up arrow, down arrow
      let btnClicked = null;
      let zoneId = null;

      $(e.target).click();
      //Navigate to first submenu in zonemenuView
      btnClicked = e.target.id;
      zoneId = btnClicked.replace("phx-btn-zone-", "");
      this.$el.find("#phx-menu-zone-" + zoneId + " > div").first().focus();
      e.preventDefault();
    }
  }

  /**
   * Resize header to adapt the height when the menu relocate its menuitems
   */
  _resizeHeader(): void {
    // Resize center depending on the header resize
    (Backbone as any).trigger("resize:center");
  }

  _loadComponents(): void {
    this.components = [];
    //Create transversal menus
    this._renderMenuComponents();
  }

  _renderMenuComponents(): void {
    const tmenu = this.$el.find(".phx-menus-transversal");

    if (!CWSTR.isBlank(tmenu) && this.components) {
      for (let i = 0; i < this.components.length; i++) {
        tmenu.append(this.components[i].el);
      }
    }
    (Backbone as any).trigger("resize:header");
  }

  _moveMenuComponentsToZone(): void {
    const zone = this.model.get("zone");
    const tmenu = this.$el.find(".phx-menus-transversal");
    const zoneEl = this.$el.find("#phx-menu-zone-" + zone);

    tmenu.css("display", "inline-block");
    tmenu.detach();
    tmenu.css("margin-top", "0");
    zoneEl.append(tmenu);
  }
}
