import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { EditableGridView } from '../editablegrid/editableGrid.view.js';
import TPL_common_table_cell_editaction from '../basegrid/table_cell_editaction.tpl.html';
import TPL_common_table_cell_multiselectaction from '../basegrid/table_cell_multiselectaction.tpl.html';
import { STR } from 'utils/str.js';

export var GroupeItemGridRowView = DataGridRowView.extend({

  tagName: "tr",
  className: "ui-grid-row",
  edited: false,

  /**
   * Event triggered when permon a click inside the view.
   */
  /**
   * Event triggered when permon a double click inside the view.
   */
  events: {
    "click": "_rowListener",
    "dblclick": "_rowListener"
  },

  /**
   * Grid Row render Function
   */
  render: function() {
    var rowThis = $(this.el);
    var isSelected = rowThis.hasClass("phx-selected");
    var _this = this;

    if (this.dataGrid.selectable) {
      rowThis.css("cursor", "pointer");
    }
    rowThis.empty();
    if (this.dataGrid.model.groupeByColumn) {
      var groupeByColumnValue = this.dataGrid._groupeByColumnValue(this.model);

      rowThis.attr("groupe-item", groupeByColumnValue);
    }
    if (this.edited === true &&
      (this.dataGrid instanceof EditableGridView)) {
      this._renderForm();
    } else {
      _.each(this.dataGrid.currentVue.columns, function(column, index) {
        var cell = null;

        switch (column.property) {
          case "phx-multiselect":
            if (_this.dataGrid.currentVue._columns["phx-multiselect"] && _this.dataGrid.currentVue._columns["phx-multiselect"].visible) {
              cell = _this._addActionCell(column, index, TPL_common_table_cell_multiselectaction);
              //						$(cell.el).addClass("phx-cell-multiselect-action");
            }
            break;
          case "phx-action":
            if (_this.dataGrid.currentVue._columns["phx-action"] && _this.dataGrid.currentVue._columns["phx-action"].visible) {
              cell = _this._addActionCell(column, index, TPL_common_table_cell_editaction);
            }
            break;
          case "phx-last":
            break;

          default:
            cell = _this._addCell(column, index);
        }
        if (!STR.isBlank(cell)) {
          rowThis.append(cell.el);
          _this.dataGrid.currentVue._columns[column.property].cells.push(cell);
          if (!_this.dataGrid.currentVue._columns[column.property].visible) {
            $(cell.el).hide();
          }
          if (column.property === _this.dataGrid.model.groupeByColumn) {
            $(cell.el).html("");
          }
        }
      });
      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }
      if (isSelected) {
        this._selectRow();
      }
    }
    return this;
  }
});
