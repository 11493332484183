import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWSTR } from 'utils/cwStr';
import { ParametresDiversModel } from './parametresDivers.model.js';
import { StoreParamDiversModel } from './storeParamDivers.model.js';

/**
 * Parametres Divers Collection
 */
export class CWParametresDiversColl extends Backbone.Collection {

  /**
   * habilitationV
   */
  habilitationV: string;
  /**
   * habilitationG
   */
  habilitationG: string;
  /**
   * usecase
   */
  usecase: string;
  filterCode: string;

  constructor(models?: typeof ParametresDiversModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || ParametresDiversModel;
    super(models, options);
    this.usecase = options.usecase || "app";
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.filterCode = options.filterCode;
    this.url = (): string => {
      let url = Configuration.restRoot + "/rest/utilitaire/divers";

      if (!CWSTR.isBlank(this.filterCode)) {
        url += "/" + this.filterCode;
      }
      return url;
    }
  }

  get(id: string | number): any {
    let result = null;

    if (!CWSTR.isBlank(id)) {
      let element = Backbone.Collection.prototype.get.call(this, id);

      if (_.isEmpty(element) && _.isString(id)) {
        element = new ParametresDiversModel({ code: id }, { parse: true });
        this.add(element, { merge: false });
      }
      result = (element instanceof Backbone.Model) ? element.clone() : element;
    }
    return result;
  }

  update(id: string | number): void {
    if (CWSTR.isBlank(id) && Configuration.development) {
      throw Error("The first parameter should be the diver identifier");
    } else if (!CWSTR.isBlank(id)) {
      const diverModel = Backbone.Collection.prototype.get.call(this, id);

      if (diverModel) {
        diverModel.fetched = false;
        diverModel.fetch();
      }
    }
  }

  modifyParamForUser(code: string, value: string, ecran: string, callback?: () => any): void {
    const storeModel = new StoreParamDiversModel();

    storeModel.usecase = ecran;
    storeModel.paramCode = code;
    storeModel.set("valeur", value);
    storeModel.save(null, {
      success: (): void => {
        const element = Backbone.Collection.prototype.get.call(this, code);

        if (!CWSTR.isBlank(element)) {
          element.set("valeur", value);
        }
        if (callback) {
          callback();
        }
      }
    });
  }
}
