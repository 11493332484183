import * as Backbone from 'Backbone';
import TPLAgendaDialogPersoSavecriteria from '../dialog_perso_savecriteria.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

// Evo 512
export class CWPersoSaveCriteriaDialog extends CWBaseFormView {

  workflow: any;
  parent: any;
  habColl: any;
  habResp: any;
  habPer: any;
  perscriu: number;
  perscrig: number;
  mode: number;
  options: string;
  level: string;



  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {

    options.events = {
      "click .options .bloq_user": "_checkButtonDelete",
      "click .options .bloq_allusers": "_checkButtonDelete",
      "click .options .bloq_util": "_checkButtonDelete",
      "click .options .bloq_allequip": "_checkButtonDelete"
    };

    super(options);

    this.template = TPLAgendaDialogPersoSavecriteria;

    this.habColl = CWHABILITATION.canView("COL_AGHEBDO") || CWHABILITATION.canView("COL_AGMENS") || CWHABILITATION.canView("COL_AGANNU");
    this.habResp = CWHABILITATION.canView("RES_AGHEBDO") || CWHABILITATION.canView("RES_AGMENS") || CWHABILITATION.canView("RES_AGANNU");
    this.habPer = CWHABILITATION.canView("PER_PERSOGEN.M");

    this.perscriu = 1;
    this.perscrig = 0;

    if (this.habColl && this.habResp && this.habPer) {
      this.perscrig = 1;
    }

    /* Modes
     * 0: none
     * 1: Enregistrement avec choix du niveau de personnalisation
     * 2: Enregistrement avec choix du contexte
     * 3: Enregistrement avec choix du niveau de personnalisation et du contexte
     */
    this.mode = 0;

    if (options.workflow) {
      this.workflow = options.workflow;

    }

    if (options.parent) {
      this.parent = options.parent;
      //set the title to the dialog
      this.parent._setTitle(i18n.t('agenda.dialog_persosavecriteria_title'));
    }

    this.options = "noperso";
    this.level = "nolevel";
    this.listenTo(this, "_saveCriteria", this._saveCriteria);
    this.listenTo(this, "_cancel", this._cancel);

  }

  /*
   * {acces}/{contextUser}/{contextGlobal}
   * {acces} value are 0:Collaborateur and 1:Responsable
   * {contextUser} value are : 0: no choice   1: CollabUser  2:ResponsableUser  3: CollabUser and ResponsableUSer
   * {contextGlobal} value are : 0: no choice   1: CollabGlobal  2:ResponsableGlobal  3: CollabGlobal and ResponsableGlobal
   */
  _saveCriteria(): void {
    this.workflow.preferences.action = true;
    this.workflow.preferences.contextUser = 0;
    this.workflow.preferences.contextGlobal = 0;

    //utilisateur
    if (this.$el.find('.aff_user') && this.$el.find('.aff_user').length > 0) {
      this.workflow.preferences.contextUser = (this.$el.find('.aff_user').is(":checked")) ? 1 : 0;
    }
    if (this.$el.find('.aff_allusers') && this.$el.find('.aff_allusers').length > 0) {
      //cas spécial
      if (this.habPer && this.habColl && !this.habResp && this.level === "collab") {
        this.workflow.preferences.contextGlobal = (this.$el.find('.aff_allusers').is(":checked")) ? 1 : 0;
      }
      this.workflow.preferences.contextUser += (this.$el.find('.aff_allusers').is(":checked")) ? 2 : 0;
    }
    if (this.workflow.preferences.contextUser === 0) {
      this.workflow.preferences.contextUser = 3;
    }
    //global
    if (this.$el.find('.aff_util') && this.$el.find('.aff_util').length > 0) {
      this.workflow.preferences.contextGlobal = (this.$el.find('.aff_util').is(":checked")) ? 1 : 0;
    }
    if (this.$el.find('.aff_allequip') && this.$el.find('.aff_allequip').length > 0) {
      this.workflow.preferences.contextGlobal += (this.$el.find('.aff_allequip').is(":checked")) ? 2 : 0;
    }
    this.workflow.preferences.set("perscriu", (this.workflow.preferences.contextUser !== 0 ? 1 : 0), { silent: true });
    this.workflow.preferences.set("perscrig", (this.workflow.preferences.contextGlobal !== 0 ? 1 : 0), { silent: true });
    this.workflow.preferences.save(null, {});
    this.parent.close();
  }

  _checkButtonDelete(): void {

    let isDisabled = true;

    if (this.$el.find(".bloq_user input").is(":checked") ||
      this.$el.find(".bloq_allusers input").is(":checked")) {
      isDisabled = false;
    }

    if (this.$el.find(".aff_util").is(":checked") ||
      this.$el.find(".aff_allequip").is(":checked")) {
      isDisabled = false;
    }

    this.$el.parent().find(".cwDialog-buttons .bt-col-blue").prop({
      "disabled": isDisabled
    });


  }

  render(): CWPersoSaveCriteriaDialog {
    let userLabel = i18n.t('agenda.dialog_persosavecriteria_affuser');
    let userAllLabel = i18n.t('agenda.dialog_persosavecriteria_affallusers');

    this.mode = 1;

    if (this.habPer && this.habColl && this.habResp) {
      userLabel = i18n.t('agenda.dialog_persosavecriteria_affuser_habcon');
      userAllLabel = i18n.t('agenda.dialog_persosavecriteria_affallusers_habcon');
      this.mode = 3;
    } else if (!this.habPer && this.habColl && this.habResp) {
      userLabel = i18n.t('agenda.dialog_persosavecriteria_affuser_context');
      userAllLabel = i18n.t('agenda.dialog_persosavecriteria_affallusers_context');
      this.mode = 2;
    }

    const json = {
      i18n: i18n,
      UTILS: UTILS,
      showSubs: (this.habColl && this.habResp) ? true : false,
      showGeneral: this.mode === 3 ? true : false,
      description: this.habPer ? i18n.t('agenda.dialog_persosavecriteria_desc') : i18n.t('agenda.dialog_persosavecriteria_desc_context'),
      userSubdescription: !this.habPer ? i18n.t('agenda.dialog_persosavecriteria_subdesc_context') : i18n.t('agenda.dialog_persosavecriteria_subdesc_habcon'),
      userLabel: userLabel,
      userAllLabel: userAllLabel,
    };

    this.$el.html(this.template(json));

    this.$el.find("button").button();

    if (this.mode === 1 || this.workflow.get("utilisateur") !== this.workflow.RESPONSABLE_TYPE) { // Context Access Responsable
      this.$el.find('.aff_user').attr('checked', 'true');
      this.$el.find('.aff_allusers').attr('checked', 'false');
      this.level = "collab";
    } else {
      this.$el.find('.aff_user').attr('checked', 'false');
      this.$el.find('.aff_allusers').attr('checked', 'true');
      this.level = "resp";
    }

    if (this.mode === 1) {
      this.$el.parent().css("height", 206);
    } else {
      if (this.mode === 2) {
        this.$el.parent().css("height", 226);
      }
    }

    return this;
  }

  _cancel(): void {
    this.parent.close();
  }

}
