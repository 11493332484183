import { ActiviteModel } from './activite.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { STR } from 'utils/str.js';

/**
 * Collection for loading the Racine activites in  trees
 */
export var ActiviteColl = PaginatedCollection.extend({
  /**
   * Add this to have Outline
   *
   */

  model: ActiviteModel,

  url: function() {
    var url = Configuration.restRoot + "/rest/composant/selecteur/activite/complet/liste";
    //PARAMS
    /*
    vue (obligatoire)
    contenu (obligatoire)
    dom (obligatoire) (un seul domaine)
    avec_inapt (obligatoire)
    comp_exacte (obligatoire)
    ctxutil(facultatif) (en exploitation, si non renseigné on prendra le contexte standard)
    typevt (facultatif)
    rechdeb (facultatif)
    rechfin (facultatif)
    search (obligatoire)
    perdeb (facultatif)
    pertot (facultatif)
    matric (facultatif)
    fltstruct (facultatif)
    heritage (facultatif)
    emploidutemps (facultatif)*/

    var params = "?vue=" + this.vue;
    var i;

    if (!STR.isBlank(this.contenu)) {
      params += "&contenu=" + encodeURIComponent(this.contenu);
    }
    if (!STR.isBlank(this.dom)) {
      params += "&dom=" + encodeURIComponent(this.dom);
    }
    if (!STR.isBlank(this.avec_inapt)) {
      params += "&avec_inapt=" + encodeURIComponent(this.avec_inapt);
    }
    if (!STR.isBlank(this.comp_exacte)) {
      params += "&comp_exacte=" + encodeURIComponent(this.comp_exacte);
    }
    if (!STR.isBlank(this.ctxutil)) {
      params += "&ctxutil=" + encodeURIComponent(this.ctxutil);
    }
    if (!STR.isBlank(this.typevt)) {
      params += "&typevt=" + encodeURIComponent(this.typevt);
    }
    if (!STR.isBlank(this.rechdeb)) {
      params += "&rechdeb=" + encodeURIComponent(this.rechdeb);
    }
    if (!STR.isBlank(this.rechfin)) {
      params += "&rechfin=" + encodeURIComponent(this.rechfin);
    }
    if (!STR.isBlank(this.search)) {
      params += "&search=" + encodeURIComponent(this.search);
    }
    if (!STR.isBlank(this.perdeb)) {
      params += "&perdeb=" + encodeURIComponent(this.perdeb);
    }
    if (!STR.isBlank(this.pertot)) {
      params += "&pertot=" + encodeURIComponent(this.pertot);
    }
    if (!STR.isBlank(this.matric)) {
      params += "&matric=" + encodeURIComponent(this.matric);
    }
    if (!STR.isBlank(this.fltstruct)) {
      params += "&fltstruct=" + encodeURIComponent(this.fltstruct);
    }
    if (!STR.isBlank(this.heritage)) {
      params += "&heritage=" + encodeURIComponent(this.heritage);
    }
    if (!STR.isBlank(this.act_unique)) {
      params += "&act_unique=" + encodeURIComponent(this.act_unique);
    }
    if (!STR.isBlank(this.code)) {
      params += "&code=" + encodeURIComponent(this.code);
    }
    if (!STR.isBlank(this.famille)) {
      params += "&famille=" + encodeURIComponent(this.famille);
    }
    if (!STR.isBlank(this.libelle)) {
      params += "&libelle=" + encodeURIComponent(this.libelle);
    }
    if (!STR.isBlank(this.emploidutemps)) {
      params += "&emploidutemps=" + encodeURIComponent(this.emploidutemps);
    }
    if (!STR.isBlank(this.flt_hiertypeniv)) {
      params += "&flt_hiertypeniv=" + encodeURIComponent(this.hiertypeniv);
    }
    if (!STR.isBlank(this.flt_ratthorsregr)) {
      params += "&flt_ratthorsregr=" + this.flt_ratthorsregr;
    }
    if (!STR.isBlank(this.idplanact)) {
      params += "&idplanact=" + encodeURIComponent(this.idplanact);
    }
    if (this.medical === true) {
      params += "&medical=true";
    }
    if (this.contenu === "R" && !_.isNull(this.context.ctxTypeGest) && !STR.isBlank(this.context.ctxTypeGest)) { //il faut ne pas utiliser STR.isBlank parce qu'on doit envoyer le cas que la valeur soit "".
      params += "&typegest=" + encodeURIComponent(this.typegest);
      //uniquement s'il y a contexte de typegest, on enverra l'autre contexte ctxHorsTypeGest ->horstypegest
      params += "&horstypegest=" + this.horstypegest;
    } else if (this.emptyTypegest) { //cas spécial lorsque typegest="" et on doit le envoyer dans l'url
      params += "&typegest="; //Aucune valeur
    }
    if (this.horsStructExplotation === true) { //pour le mode Explotation et grille non médicals
      params += "&horsStruct=true";
    }
    if (this.ctxfeuilletemps === true) {
      params += "&ctxfeuilletemps=true";
      if (!STR.isBlank(this.ctxcptana)) {
        params += "&ctxcptana=" + encodeURIComponent(this.ctxcptana)
      }
    }
    if (!STR.isBlank(this.filter)) {
      delete this.filter.code;
      delete this.filter.libelle;
      var filterLength = _.keys(this.filter).length;

      for (i = 0; i < filterLength; i++) {
        var index = _.keys(this.filter)[i];

        if (!STR.isBlank(this.filter[index].code)) {
          params += "&codeniv" + index + "=" + encodeURIComponent(this.filter[index].code);
        }
        if (!STR.isBlank(this.filter[index].libelle)) {
          params += "&libelleniv" + index + "=" + encodeURIComponent(this.filter[index].libelle);
        }
      }
      delete this.filter;
    }
    return url + params;
  },

  setContext: function(context) {
    /*
    vue (obligatoire)
    contenu (obligatoire)
    dom (obligatoire) (un seul domaine)
    avec_inapt (obligatoire)
    comp_exacte (obligatoire)
    ctxutil(facultatif) (en exploitation, si non renseigné on prendra le contexte standard)
    typevt (facultatif)
    rechdeb (facultatif)
    rechfin (facultatif)
    search (obligatoire)
    perdeb (facultatif)
    pertot (facultatif)
    matric (facultatif)
    fltstruct (facultatif)
    heritage (facultatif)
    hiertypeniv (facultatif)
    ratthorsregr (facultatif)*/
    this.params.vue = context.vue;
    this.params.contenu = context.contenu;
    this.params.dom = context.dom;
    this.params.avec_inapt = context.avec_inapt;
    this.params.comp_exacte = context.comp_exacte;
    this.params.ctxutil = context.ctxutil;
    this.params.typevt = context.typevt;
    this.params.typevt = context.typevt;
    this.params.rechdeb = context.datedeb;
    this.params.rechfin = context.datefin;
    this.params.search = context.search;
    this.params.perdeb = context.perdeb;
    this.params.pertot = context.pertot;
    this.params.matric = context.matric;
    this.params.fltstruct = context.fltstruct;
    this.params.heritage = context.heritage;
    this.params.act_unique = context.act_unique;
    this.params.emploidutemps = context.emploidutemps;
    this.params.flt_hiertypeniv = context.flt_hiertypeniv;
    this.params.flt_ratthorsregr = context.flt_ratthorsregr;
    this.params.idplanact = context.idplanact;
    this.filter = context.filter;
    if (context.medical === true) {
      this.params.medical = true;
    }
    this.code = context.code;
    this.famille = context.famille;
    this.libelle = context.libelle;
    if (context.contenu === "R" && !_.isNull(context.ctxTypeGest) && !STR.isBlank(context.ctxTypeGest)) { //il faut ne pas utiliser STR.isBlank parce qu'on doit envoyer le cas que la valeur soit "".
      this.params.typegest = context.ctxTypeGest;
      //uniquement s'il y a contexte de typegest, on enverra l'autre contexte ctxHorsTypeGest ->horstypegest
      this.params.horstypegest = _.isBoolean(context.ctxHorsTypeGest) ? context.ctxHorsTypeGest : false;
      //cas spécial parce que les paramétres qui sont vîdes dans la pétition de paginate.collection ou base.collection sont supprimés et pas envoyés
      if (STR.isBlank(context.ctxTypeGest)) {
        this.emptyTypegest = true;
      }
    }
    //pour le mode Explotation et grille non médicals
    this.horsStructExplotation = context.horsStructExplotation;
    this.ctxfeuilletemps = context.ctxfeuilletemps;
    this.ctxcptana = context.ctxcptana;
  },

  initialize: function(params) {
    PaginatedCollection.prototype.initialize.call(this);
    if (params) {
      if (params.domaine) {
        this.domaine = params.domaine;
      }
    }
    this.paginated = false;
    this.emptyTypegest = false; //pour envoyer dans le cas que typegest = "" et la valeur arrive uniquement par context->this.params.typegest
  }
});
