import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';


/**
 * Model for the main table
 */
export class CWSuvietatModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "code": "",
      "libelle": "",
      "heuredebut": null,
      "datedebut": "",
      "heurefin": null,
      "datefin": "",
      "etat": {
        "code": "",
        "libelle": ""
      },
      "edition": null,
      "theme": "",
      "type": "",
      "matricule": null,
      "url": ""
    }
  }
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/edition/" + encodeURIComponent(this.id);
    };
  }


  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
}