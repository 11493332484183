import _ from 'underscore';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWPresencesModel } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwPresences.model';

export class CWPresencesColl extends CWPaginatedCollection {

  matricule: string;
  datedeb: string;
  datefin: string;

  /**
   * Constructor
   * Collection for obtaining presences
   */
  constructor(models?: CWPresencesModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWPresencesModel;
    super(models, options);
    this.paginated = false;
    this.url = (): string => {
      const params = this.matricule + "," + this.datedeb + "," + this.datefin
      return Configuration.restRoot + "/rest/composant/recapactivite/presences/" + encodeURIComponent(params);
    }
  }

  /**
   * This method is overwritting the parent's one.
   */
  clone(): any {
    const temp = CWPaginatedCollection.prototype.clone.call(this);

    temp.matricule = this.matricule;
    temp.datedeb = this.datedeb;
    temp.datefin = this.datefin;
    return temp;
  }
}
