import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * dabsences Model
 */
export class CWProfilsRecapActivite extends CWBaseModel {
  code: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.code = "";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/profils/" + encodeURIComponent(this.code);
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "profils": null
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (_.isObject(response.Payload)) {
      return response.Payload;
    } else {
      return response;
    }
  }
}
