import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWEditableGridModel } from '../editablegrid/cwEditableGrid.model';
import { CWEditableGridView } from '../editablegrid/cwEditableGrid.view';
import { CWEditedRowView } from 'src/core/views/cwEditedRow.view';
import { CWSTR, UTILS } from 'src/utils';
import { i18n } from 'src/i18n';
import { View, ViewOptions } from 'Backbone';

export class CWDataGridActionCellView<TModel extends CWEditableGridModel = CWEditableGridModel> extends View<TModel> {

  public dataGridRow: CWEditedRowView;
  dataGrid: CWEditableGridView;
  private rowNumber: number;
  /**
   * View of the Cells with Action Buttons for its row.
   */
  constructor(options: ViewOptions<TModel> | any) {
    options.tagName = options.tagName || "div";
    options.className = options.className || "cwDataGrid__actionCell cw-volet__buttons";
    options.model = options.model || new CWBaseModel();
    super(options);
    if (!options.dataGridRow) {
      throw Error("You must use a DataGridCellView just inside a DataGridRowView");
    }
    if (!options.action) {
      throw Error("You must define a template");
    }
    this.rowNumber = options.rowNumber || "0";
    this.template = options.action;
    this.dataGridRow = options.dataGridRow;
    if (this.dataGridRow && this.dataGridRow.dataGrid) {
      this.dataGrid = this.dataGridRow.dataGrid;
    }
    this.listenTo(this.model, "change:mode", this._updateButtons);
  }

  render(): CWDataGridActionCellView<TModel> {
    $(this.el).html(this.template({ i18n: i18n, UTILS: UTILS, id: "row-" + this.rowNumber }));

    if (this.dataGrid && this.dataGrid.isEditableGridView) {
      // Detach buttons from table btnBar to keep
      // the control on habilitations in the btnBar
      this.dataGrid.buttonBar.detachButton("copy", this.$el.find(".btn-copy-container"));
      this.dataGrid.buttonBar.detachButton("delete", this.$el.find(".btn-delete-container"));
    }

    this._updateButtons(this.model, this.model.get("mode"));
    return this;
  }

  /**
   * Show/Hide this cell when row is editable or not
   */
  disableEdition(disable: boolean): void {
    if (disable) {
      $(this.el).find(".phx-cell-edit-action").addClass("d-none");
    } else {
      $(this.el).find(".phx-cell-edit-action").removeClass("d-none");
    }
  }

  _updateButtons(model: TModel, mode: string): void {
    /* Edition with modifications and Creation */
    if (mode === "EM" || mode === "C") {
      // Hide copy & delete buttons
      $(this.el).find(".btn-copy-container").addClass("d-none");
      $(this.el).find(".btn-delete-container").addClass("d-none");
      // Show save and revert buttons
      $(this.el).find(".btn-save-container").removeClass("d-none");
      $(this.el).find(".btn-revert-container").removeClass("d-none");
    }
    /* Consultation and Edition without modifications */
    else if (mode === "R" || mode === "E") {
      // Show copy & delete buttons
      $(this.el).find(".btn-copy-container").removeClass("d-none");
      $(this.el).find(".btn-delete-container").removeClass("d-none");
      // Hide save and revert buttons
      $(this.el).find(".btn-save-container").addClass("d-none");
      $(this.el).find(".btn-revert-container").addClass("d-none");
    }
    if (this.dataGrid && this.dataGrid.isEditableGridView) {
      const cannotDelete = (!CWSTR.isBlank(this.dataGrid.viewRow.canRemove) && this.dataGrid.viewRow.canRemove(model) === false);
      if (cannotDelete) {
        $(this.el).find(".btn-delete-container").addClass("d-none");
      }
    }
  }

}