import _ from 'underscore';
import { Collection, Model } from 'Backbone';
import { CWBaseGridModel } from '../basegrid/cwBaseGrid.model';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDataGridModel } from '../data_grid/cwDataGrid.model';
import { CWFORMS } from 'utils/cwForms';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMSGS, ShowConfirmOptions } from 'utils/cwMsgs';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';


export class CWEditableGridModel<TColl extends CWPaginatedCollection = CWPaginatedCollection,
  TModel extends CWBaseModel = CWBaseModel> extends CWDataGridModel<TColl, TModel>{
  /**
   * Event launched when a row is clicked
   */
  /**
   * Event launched when a row is double clicked
   */
  /**
   * Event launched when a model is added
   */
  /**
   * Event launched when a model is added to multiselectcoll
   */
  /**
   * Event launched when a model is removed from multiselectcoll
   */
  /**
   * Event launched when a button in the table is clicked
   */
  /**
   * Event launched when the value changes
   *
   */
  /**
   * Add this to have Outline
   *
   */

  /**
   * Constructor
   * Model underlying a editable DataGrid.
   * Events out: change:value
   * Events in: row:click, row:select, row:unselect
   */
  public btnBarModel: CWReadOnlyModel;
  public propertyPK: Array<string>;
  public readOnly: boolean;
  public lastCode: number | string;
  public collection: TColl;
  public groupeByColumn: any; //number;
  public CWEditableGridModel: any;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options)
    this.coll.off("row:click");
    this.coll.off("row:dblclick");
    this.coll.off("add:model");
    this.coll.on("row:click", this._manageRowSelection, this);
    this.coll.on("row:dblclick", this._manageRowEdition, this);
    this.coll.on("add:model", this._manageRowEdition, this);
    this.set("mode", "C");
    this.btnBarModel = undefined;
    this.propertyPK = [];
    this.multiselectColl.on("add", this._manageMultiSelection, this);
    this.multiselectColl.on("remove", this._manageMultiSelection, this);
    this.multiselectColl.on("reset", this._manageMultiSelection, this);
    this.readOnly = false;
  }

  /**
   * Set up the work flow of the model. Link the models between them
   */
  setUp(): void {
    // Declare events consumers
    this.on("change:mode", this._manageMode, this);
    this.btnBarModel.on("btn:click", this.buttonAction, this);
  }

  /**
   * Public default button action of the editable grid. It is public to allow
   * the extension of its behavior by doing somethign like this:
   * someCustomBtnAction : function(buttonId) { // Additional code...
   * this.tableModel.buttonAction(buttonId); }
   */
  buttonAction(buttonId: string, callback?: () => void): void {
    this._buttonAction(buttonId, callback);
  }

  /**
   * Set mode for btnbarModel and show error if it is not valid.
   */
  _manageMode(): void {
    const newMode = this.get("mode");
    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in EditableGrid : " + newMode);
    }
    this.btnBarModel.set("mode", newMode);
  }

  /**
   * When a row is selected value is updated and mode is changed
   */
  _manageRowSelection(model: CWBaseGridModel): void {
    if (model) {
      if (this.get("mode") === "E") {
        this.btnBarModel.trigger("btn:click", "revert", () => {
          //Added to avoid double select row when we click in a different row of the new one
          this.get("value").trigger("row:unselect", this.get("value"));
          model.trigger("row:select", model);
          this.set("value", model);
          this.set("mode", "R");
          this._manageRowOrdering();
        });
      } else {
        if (CWSTR.isBlank(this.get("value"))) {
          this.set("value", model);
          model.trigger("row:select", model);
          this.set("mode", "R");
          this._manageRowOrdering();
        } else if (this.get("value") === model) {
          this.set("value", null);
          model.trigger("row:unselect", model);
          this.set("mode", "C");
        } else {
          this.get("value").trigger("row:unselect", this.get("value"));
          model.trigger("row:select", model);
          this.set("value", model);
          this.set("mode", "R");
          this._manageRowOrdering();
        }
      }

    }
  }
  /**
   * Manages row edition
   */
  _manageRowEdition(model: CWBaseGridModel): void {
    let currentHabContext = null;// set habilitation context of the model

    if (!this.readOnly) {
      if (!CWSTR.isBlank(model)) {
        if (this.habContext) {
          currentHabContext = this.getHabContext().copy();
          if (model.isNew()) {
            currentHabContext.update({ natGest: "A" }); //eslint-disable-line
          } else {
            currentHabContext.update({ natGest: "M" }); //eslint-disable-line
          }
          model.setHabContext(currentHabContext);
        }
        if (this.get("mode") === "E") {
          this.btnBarModel.trigger("btn:click", "revert", () => {
            model.trigger("row:select", model);
            model.trigger("row:edit", model);
            this.set("value", model);
            this.set("mode", "E");
          });
        } else {
          let lRow: number = null;
          let lTotal: number = null;

          if (!CWSTR.isBlank(this.get("value")) && this.get("value") !== model) {
            this.get("value").trigger("row:unselect", this.get("value"));
          }
          lRow = (this.coll && this.coll.length > 0) ? this.coll.indexOf(model) : null;
          lTotal = (this.coll && this.coll.length) ? this.coll.length : null;
          model.trigger("row:select", model);
          model.trigger("row:edit", model);
          this.btnBarModel.trigger("check:rowOrdering", lRow, lTotal);
          this.set("value", model);
          this.set("mode", "E");
        }
      }
    }
  }

  /**
   * callback : callback function called when action succeeded (save, rever and delete)
   */
  _buttonAction(buttonId: string, origineOrCallback?: ((buttonID: string, row?: Model) => void) | { [key: string]: any } | string): void {
    let currentHabContext: CWHabilitationContext = null;
    let editedModel: CWDataGridModel = null;
    let value = null;
    let freshNew: any;
    let callbackRetour: (buttonID: string, row?: Model) => void = null;


    if (typeof origineOrCallback === "object") {
      if (typeof (origineOrCallback as { [key: string]: any }).callback === "function") {
        callbackRetour = (origineOrCallback as { [key: string]: any }).callback;
      }
    } else if (typeof origineOrCallback === "function") {
      callbackRetour = origineOrCallback as ((buttonID: string, row?: Model) => void);
    }
    if (this.habContext) {
      currentHabContext = this.habContext.copy();
    }
    switch (buttonId) {
      case "save":
        editedModel = this.get("value");
        if (!CWSTR.isBlank(editedModel)) {
          editedModel.save(null, {
            success: (fresh) => {
              this.coll.fetch({
                success: () => {
                  // Select the record and paginate if required
                  this.set("mode", "R");
                  this.trigger("finishEdition", true);
                  this.selectRow(fresh);
                  if (!CWSTR.isBlank(callbackRetour)) {
                    callbackRetour(buttonId, fresh);
                  }
                }
              });
            },
            error: (model: any, response: any) => { //eslint-disable-line
              if (response && response.status === 406 && !_.isEmpty(response.responseText)) {
                //If there was an error of saisi incorrect we want to show it on the edited row
                this.get("value").validationError = this.get("value").validationError || { errors: {}, errorValidation: {} };
                if (response.responseJSON && response.responseJSON.attribut) {
                  CWFORMS.assignValue(this.get("value").validationError.errors, response.responseJSON.attribut, response.responseJSON.message);
                  this.get("value").trigger("invalid", self, this.get("value").validationError);
                  //Avoid the creation of the pop up
                  response.status = 200;
                  response.responseText = null;
                }
              }
            }
          });
        }
        break;
      case "revert":
        if (this.get("mode") === "EM" || this.get("mode") === "E") {
          editedModel = this.get("value");
          if (editedModel.isNew()) {
            editedModel.destroy();
            editedModel.trigger("row:unedit");
            let row = undefined;
            if (!CWSTR.isBlank(this.lastCode)) {
              row = this.coll.get(this.lastCode);
              if (CWSTR.isBlank(row)) {
                this.set("mode", "C");
              } else {
                this.set("mode", "R");
                this.coll.trigger("row:click", row);
              }
            } else {
              this.set("mode", "C");
            }
            if (!CWSTR.isBlank(callbackRetour)) {
              callbackRetour(buttonId, row);
            }
          } else {
            editedModel.revert();
            // Select the record and paginate if required
            editedModel.trigger("row:unedit");
            this.set("mode", "R");
            if (!CWSTR.isBlank(callbackRetour)) {
              callbackRetour(buttonId, editedModel);
            }
          }
        }
        break;
      case "new":
        // get previous row id if it exist
        this.lastCode = undefined;
        if (!CWSTR.isBlank(this.get("value"))) {
          this.lastCode = this.get("value").id;
        }
        // create new row
        freshNew = new this.coll.model;
        this.coll.add(freshNew);
        if (!CWSTR.isBlank(callbackRetour)) {
          callbackRetour(buttonId, freshNew);
        }
        break;
      case "copy":
        if (this.get("mode") !== "EM" && !CWSTR.isBlank(this.get("value"))) {
          const copy = new this.coll.model(this.get("value").toJSON());

          copy.id = null;
          (copy as any).isCopy = true;
          _.each(this.propertyPK, (property) => {
            // check if property is part of the model attributes
            if (!CWSTR.isBlank(CWSTR.getElValue(copy, property))) {
              CWSTR.setElValue(copy, property, null, { silent: true });
            }
          });
          if (!CWSTR.isBlank(callbackRetour)) {
            callbackRetour(buttonId, copy);
          }
          // Unselect current row
          this.get("value").trigger("row:unselect", this.get("value"));
          this.coll.add(copy);
        }
        break;
      case "delete":
      case "deleteHead":
        editedModel = this.get("value");
        if (!CWSTR.isBlank(editedModel)) {
          const elements = this.multiselectColl.length;
          let $appendTo = (!CWSTR.isBlank(this.usecase) ? $("#" + this.usecase) : null);

          if ((typeof origineOrCallback === "object" && (origineOrCallback as { [key: string]: any }).infoOrigine === "OrigineVolet") || (typeof origineOrCallback === "string" && (origineOrCallback as string) === "OrigineVolet")) {
            $appendTo = null;
          }
          if (currentHabContext) {
            currentHabContext.update({ natGest: "S" }); //eslint-disable-line
            editedModel.setHabContext(currentHabContext);
          }
          if (elements === 0) {
            let message = i18n.t('common:delconfirm');
            let showConfirmOptions: ShowConfirmOptions = null;

            if (this.get("messageDeleteHead") && this.get("messageDeleteHead") !== "") {
              message = this.get("messageDeleteHead");
            }
            showConfirmOptions = {
              text: message,
              buttons: { yes: true, no: true },
              callback: (result: string): void => {
                if (result === "Y") {
                  const position = _.indexOf(this.coll.models, editedModel);

                  editedModel.destroy({
                    success: (fresh: CWDataGridModel) => {
                      this.coll.fetch({
                        success: () => {
                          this.trigger("finishEdition", true);
                          if (!CWSTR.isBlank(callbackRetour)) {
                            callbackRetour(buttonId, fresh);
                          }
                          if (this.coll.length > 0) {
                            let next = this.coll.at(position);
                            if (next) {
                              next.trigger("row:click", next);
                            } else {
                              next = this.coll.at(this.coll.length - 1);
                              if (next) {
                                next.trigger("row:click", next);
                              }
                            }
                          }
                        },
                        error: () => {
                          const next = this.coll.at(position);

                          next.trigger("row:click", next);
                        }
                      });

                    },
                    wait: true
                  });
                }
              },
              appendTo: $appendTo
            };
            CWMSGS.showConfirm(showConfirmOptions);
          } else {
            const showConfirmOptions2 = {
              text: i18n.t('common:delconfirm2', { "0": elements }),
              buttons: { yes: true, no: true },
              callback: (result: string): void => {
                if (result === "Y") {
                  this._recursiveSuppress(this.multiselectColl, currentHabContext, 0, (position: number) => {
                    this.coll.fetch({
                      success: () => {
                        this.trigger("finishEdition", true);
                        if (this.coll.length > 0) {
                          let next = this.coll.at(position);
                          if (next) {
                            next.trigger("row:click", next);
                          } else {
                            next = this.coll.at(this.coll.length - 1);
                            if (next) {
                              next.trigger("row:click", next);
                            }
                          }
                        }
                      },
                      error: () => {
                        const next = this.coll.at(position);
                        next.trigger("row:click", next);
                      }
                    });
                    if (!CWSTR.isBlank(callbackRetour)) {
                      callbackRetour(buttonId);
                    }
                  });
                }
              },
              appendTo: $appendTo
            }
            CWMSGS.showConfirm(showConfirmOptions2);
          }
        }
        break;
      case "monter":
        value = this.get("value");
        value.trigger("moved:up", value);
        this._manageRowOrdering();
        break;
      case "descendre":
        value = this.get("value");
        value.trigger("moved:down", value);
        this._manageRowOrdering();
        break;
      case "placer":
        value = this.get("value");
        value.trigger("moved:placer", value);
        this._manageRowOrdering();
        break;
      case "premier":
        value = this.get("value");
        value.trigger("moved:premier", value);
        this._manageRowOrdering();
        break;
      default:
      //nothing
    }
  }

  /**
   * Manages the order of the rows
   */
  _manageRowOrdering(): void {
    if (this.btnBarModel && this.btnBarModel.get("rowOrdering") === true) {
      const value = this.get("value");
      const index = this.coll.indexOf(value);
      const length = this.coll.length - 1;
      if (this.coll.length > 1) {
        if (index === 0) {
          this.btnBarModel.trigger("moved:top");
        } else if (index === length) {
          this.btnBarModel.trigger("moved:bottom");
        } else {
          this.btnBarModel.trigger("moved:other");
        }
      } else {
        this.btnBarModel.trigger("moved:hide");
      }
    }
  }
  /**
   * When removing rows for multiselection
   */
  _recursiveSuppress(models: Collection<CWBaseModel>, currentHabContext: CWHabilitationContext, position: number, callback: (position: number) => void): void {
    const item = models.shift();
    let localPosition = position;

    if (!CWSTR.isBlank(item)) {
      localPosition = _.indexOf(this.coll.models, item);
      if (CWSTR.isBlank(item.urlRoot)) {
        item.urlRoot = typeof this.coll.url === "function" ? this.coll.url() : this.coll.url;
      }
      item.setHabContext(currentHabContext);
      item.destroy({
        success: () => {
          this._recursiveSuppress(models, currentHabContext, localPosition, callback);
        },
        error: () => {
          models.unshift(item);
          callback(localPosition);
        }
      });
    } else {
      callback(localPosition);
    }
  }

  _manageMultiSelection(): void {
    if (this.multiselectColl.length > 0) {
      this.btnBarModel.trigger("override.show:delete");
    } else {
      this.btnBarModel.trigger("override.hide:delete");
    }
  }

  /**
   * The current habilitation context that the grid should use perform the actions (add/modify/remove)
   *
   */
  setHabContext(habContext: CWHabilitationContext): void {
    this.habContext = habContext;
  }

  updateHabContext(attributes: { [key: string]: any }): void {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  }

  getHabContext(): CWHabilitationContext {
    return this.habContext;
  }
}
