import { BaseModel } from 'core/models/base.model.js';


/**
 * searchCheminActrivite or searchCheminActivite (maybe it is wrong typed in the SFD)
 * Model for read domaine
 */
export var SearchCheminActivite = BaseModel.extend({

  initialize: function(params) {
    this.params = params;
    this.code = null;
  },

  /**
   * URL root to use in every call to webservices
   */
  url: function() {
    return Configuration.restRoot + "/rest/activite/chemin/" + encodeURIComponent(this.code);
  },

  parse: function(response) {
    _.each(response.hierar, function(object) {
      object.nodeType = "H";
    });
    _.each(response.structa, function(object) {
      object.nodeType = "S";
    });
    return response;
  }
});