import _ from 'underscore';
import CommonTableCellMultiselectActionTPL from '../basegrid/cwTable_cell_multiselectaction.tpl.html';
import CommonTableCellRevertActionTPL from '../basegrid/cwTableCellRevertAction.tpl.html';
import CommonTableCellSaveRevertActionTPL from '../basegrid/cwTableCellSaveRevertAction.tpl.html';
import {
  Collection,
  Model,
  View,
  ViewOptions
  } from 'Backbone';
import { CWDataGridActionCellView } from './cwDataGridActionCell.view';
import { CWDataGridCellView } from './cwDataGridCell.view';
import { CWDataGridModel } from './cwDataGrid.model';
import { CWEditableGridModel } from '../editablegrid/cwEditableGrid.model';
import { CWEditableGridView } from '../editablegrid/cwEditableGrid.view';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { CWVoletView } from 'core/components/dialog/volet/cwVolet.view';

export class CWDataGridRowView<TModel extends CWEditableGridModel = CWEditableGridModel> extends View<TModel> {

  /**
   * Event triggered when select the row.
   */
  /**
   * Event triggered when unselect the row.
   */
  /**
   * Event triggered when the row becomes editable.
   */
  /**
   * Event triggered when the row becomes to read only.
   */
  /**
   * Event triggered when ckeck the row on multiselection row.
   */
  /**
   * Event triggered when unckeck the row on multiselection row.
   */

  /**
   * Constructor
   * View underlying a row of generic data grid
   */
  public edited: boolean;
  public dataGrid: CWEditableGridView;
  public volet: CWVoletView;
  public showedColumns: Array<string>;
  public blokedColumns: Array<string>;
  public dblclick: number;
  public model: TModel;
  public filterSelection: boolean;
  public filterMultiSelectColl: Collection;
  public filterSelectionFunction: (model: Model) => boolean;
  private rowNumber: number;
  public classForButtons: string;

  constructor(options: ViewOptions<CWDataGridModel> | any) {
    options.tagName = "tr";
    options.className = "c-grind__table__row row";
    /**
     * Event triggered when permon a click inside the view.
     * Event triggered when permon a double click inside the view.
     */
    options.events = _.extend({
      "click": "_rowListener",
      "dblclick": "_rowListener",
      "keydown": "keyEvent"
    }, options.events);
    super(options);
    this.edited = false;
    /**
     * The instance of the editable Grid
     */
    this.dataGrid = null;
    /**
     * Columns that are being showed by the edition.
     */
    this.showedColumns = [];
    /**
     * Columns that are being blocked to avoid edition.
     */
    this.blokedColumns = [];

    this.model = options.model;

    this.classForButtons = options.classForButtons;

    this.rowNumber = options.rowNumber || 0;

    if (this.model) {
      this.model.off("destroy");
      this.model.off("row:select");
      this.model.off("row:unselect");
      this.model.off("row:edit");
      this.model.off("row:unedit");
      this.model.off("row:checked");
      this.model.off("row:unchecked");
      this.model.off("row:notSelectable");
      this.model.off("row:Selectable");
      this.model.off("row:supprimed");

      this.model.on("destroy", this.close, this);
      this.model.on("row:select", this._selectRow, this);
      this.model.on("row:unselect", this._unselectRow, this);
      this.model.on("row:edit", this._editRow, this);
      this.model.on("row:unedit", this._uneditRow, this);
      this.model.on("row:checked", this._showMultiSelection, this);
      this.model.on("row:unchecked", this._showMultiSelection, this);
      this.model.on("cell:select", this._selectCell, this);
      this.model.on("row:notSelectable", this._notSelectable, this);
      this.model.on("row:Selectable", this._selectable, this);
      this.model.on("row:supprimed", this._supprimed, this);

    }
    if (!options.dataGrid) {
      throw Error("You must use a DataGridRowView just inside a DataGridView");
    }
    this.dataGrid = options.dataGrid;

    if (!(this.dataGrid.isEditableGridView === true) && this.model) {
      this.listenTo(this.model, "change", this.render);
    }

  }

  /**
   * Method which manages the row click or double click in order to select it or edit it.
   */
  _rowListener(event: JQueryEventObject): void {
    let className = null;

    CWLOG.debug("event.type=" + event.type);
    if (this.dataGrid.multiselection === false && $(event.target) && ($(event.target).is("input[type=checkbox]") || $(event.target).is("input[type=radio]"))) {
      return;
    }
    if ($(event.currentTarget) && ($(event.currentTarget).hasClass("phx-notSelectable") || $(event.currentTarget).hasClass("phx-supprimed"))) {
      return;
    }
    if (event.target.tagName === "svg") {
      return;
    }
    className = event.target.className.split(" ")[0];
    if (className === "phx-cell-save-action") {
      this.dataGrid.model.trigger("action:save");
    }
    if (className === "phx-cell-revert-action") {
      this.dblclick = 0;
      if (CWSTR.isBlank(this.model.id)) {
        this.dataGrid.model.trigger("action:revert", false);
        event.stopPropagation();
        return;
      } else {
        this.dataGrid.model.trigger("action:revert", true);
        event.stopPropagation();
        return;
      }
    }
    if (className === "phx-bistate-box") {
      //put the focus to the checkbox due to this function does a return and the click code is not executed.
      $(event.currentTarget).parents(".phx-grid:first").find(".grid-cell-focus").attr("tabindex", "-1").removeClass("grid-cell-focus");
      $(event.target).attr("tabindex", "0").addClass("grid-cell-focus").focus();
      this._toggleMultiSelection();
      event.stopPropagation();
      return;
    }
    if ($(event.target).hasClass("phx-grid-not-selectable-cell") || this.$el.find($(event.target).closest(".phx-grid-not-selectable-container-in-cell") as any).length >= 1) {
      return;
    }
    //If the row is being edited, no click event must be propagated
    if (this.edited) {
      event.stopPropagation();
      return;
    }

    const isCheckbox = $(event.target).is(":checkbox");
    const isLink = $(event.target).is("a");
    const isSelect = $(event.target).is("select");
    const isOption = $(event.target).is("option");
    const isSelected = $(this.el).hasClass("phx-selected");

    if (this.dataGrid.selectable) {
      // Trigger the custom even select to be interpreted by the parent view
      if (!this.dataGrid.isEditableGridView && event.type === "click") {

        //Accessibility. select the correct cell when click a row
        const currentCell = $(event.target).is("td") ? $(event.target) : $(event.target).parents("td:first");
        //set the cell clicked to do a correct focus
        this.dataGrid.cellSelected = currentCell.parents("tr:first").children().index(currentCell as any);

        let editActionClicked = false;
        // if we click on the edit icon in the row then we trigger a dblclick event to enter in edition mode
        if (className === "phx-cell-edit-action") {
          if (this.dataGrid.isEditableGridView === true && this.dataGrid.editable && this.dataGrid.habilitationUpdate) {
            //this.dblclick = 2;
            editActionClicked = true;
            this.model.trigger("row:dblclick", this.model);
            event.stopPropagation();
          }
        }
        // If no double click then simple click
        if (!editActionClicked) {
          setTimeout((): boolean => {
            const double = this.dblclick;

            if (double > 0) {
              this.dblclick = double - 1;
              return false;
            } else {
              this.dataGrid.model.lastCode = null;
              // case clicked item is a checkbos or link
              if (!isSelected && (isCheckbox || isLink || isSelect)) {
                this.model.trigger("row:click", this.model, null, null, true);
              } else if (!(isCheckbox || isLink || isSelect || isOption)) {
                this.model.trigger("row:click", this.model, null, null, true);
              }
              return true;
            }
          }, 100);//Attention: avec 300, il y a de problèmes (répéter la même action 3 fois et deux erreurs de chargement de page)

        }
      } else if (event.type === "click" || event.type === "dblclick") {
        if ($(event.target).is("td")) {
          if (this.model.collection) {
            this.model.collection.editModeCellSelected = $(event.currentTarget).children().index(event.target);
          }
        }
        if (!$(event.target).hasClass("cwDataGrid__cellLine") && !($(event.target).parents(".cwDataGrid__cellLine").length > 0)) {
          if (event.type === "dblclick") {
            if (CWSTR.isBlank(this.dblclick) || this.dblclick === 0) {
              this.dblclick = 1;
            }
          } else {
            this.model.trigger("row:click", this.model);
          }
          event.stopPropagation();
        } else if (this.dataGrid.enableSelectionOnDblclick === true) {
          this.dblclick = 2;
          this.model.trigger("row:dblclick", this.model);
          event.stopPropagation();
        } else {
          if (this.dataGrid.isEditableGridView === true && this.dataGrid.editable && this.dataGrid.habilitationUpdate) {
            if (CWSTR.isBlank(this.dataGrid.viewRow.canEdit) && CWSTR.isBlank(this.dataGrid.viewRow.canEditCallback)) {
              this.dblclick = 2;
              this.model.trigger("row:dblclick", this.model);
              event.stopPropagation();
            }
            // synchronized method to know if a row is editable
            if (!CWSTR.isBlank(this.dataGrid.viewRow.canEdit) && this.dataGrid.viewRow.canEdit(this.model) === true) {
              this.dblclick = 2;
              this.model.trigger("row:dblclick", this.model);
              event.stopPropagation();
            }
            // asynchronized method to know if a row is editable
            if (!CWSTR.isBlank(this.dataGrid.viewRow.canEditCallback)) {
              this.dblclick = 2;
              event.stopPropagation();
              this.dataGrid.viewRow.canEditCallback(this.model, (canEdit: boolean) => {
                if (canEdit) {
                  this.model.trigger("row:dblclick", this.model);
                }
              }, event);
            }
          } else if (event.type === "dblclick") {
            this.dblclick = 2;
            event.stopPropagation();
          }
        }
      }
    }
  }

  keyEvent(e: JQueryKeyEventObject): boolean {
    let ret = true;
    //accessibility
    if (e.keyCode === 32 && e.shiftKey === true) { //space && shift
      this.model.trigger("row:click", this.model);
      ret = false;
    } else if (e.keyCode === 13) {
      const isLink = $(event.target).is("a") || $(event.target).find('a').length > 0;
      const isSelected = $(event.target).hasClass("grid-cell-focus");
      if (isSelected && isLink && !CWSTR.isBlank($(event.target).find('a').prop('href'))) {
        window.open($(event.target).find('a').prop('href'), "_blank");
        ret = false;
      }
    }
    return ret;
  }

  _visible(element: JQuery): boolean {
    if (element && element.width() > 0 &&
      parseInt(element.css("opacity")) > 0 &&
      element.css("visibility") !== "hidden" &&
      element.css("display") !== "none") {
      return true;
    }
    return false;
  }

  /**
   * Manages the row selection.
   */
  _selectRow(): void {
    if (!$(this.el).hasClass("notSelectable")) {
      if (this.dataGrid.isNotSelectableRow === false) {
        let visibleRow = this.$el.parents(".c-grind__headerScroll__table").is(":visible");

        if (visibleRow === false) { //when false, another checking(four IE)
          visibleRow = this._visible(this.dataGrid.$el);
        }
        if (visibleRow === false) {
          this.$el.parents(".c-grind__headerScroll__table").show();
        }
        $(this.el).addClass("phx-selected");
        $(this.el).focus();

        if (!CWSTR.isBlank(this.dataGrid.cellSelected)) {
          //select the next row when the table has pagination
          this.dataGrid._selectAdjacentCell($(this.el).find("td"), 1, this.dataGrid.cellSelected - 1, "td");
        } else if (this.$el.find(".grid-cell-focus").length === 0 && CWSTR.isBlank(this.dataGrid.cellSelected)) {
          //when the page is loaded for first time this code selet the first cell of the selected column. No focus

          this.$el.parents(".phx-grid:first").find(".grid-cell-focus").attr("tabindex", "-1").removeClass("grid-cell-focus");

          let nextCellPosition = -1;
          let nextCell = null;
          const container = this.$el.children();

          //obtain the new cell
          if (container && container.length > 0) {
            do {
              nextCellPosition = nextCellPosition + 1;
              nextCell = !CWSTR.isBlank(container[nextCellPosition]) ? container[nextCellPosition] : null;
            } while ((CWSTR.isBlank(nextCell) && nextCellPosition < container.length || (!CWSTR.isBlank(nextCell) && !$(nextCell).is(":visible"))));
          }

          if (!CWSTR.isBlank(nextCell) && $(nextCell).is(":visible")) {
            this.dataGrid.cellSelected = 0;
            if ($(nextCell).find(":input").is(":input")) {
              $(nextCell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus");
            } else {
              $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus");
            }
          }
        }

        if (visibleRow === false) {
          this.$el.parents(".c-grind__headerScroll__table").hide();
        }
      }


    }
  }

  /**
   * Manages the row unselection.
   */
  _unselectRow(): void {
    $(this.el).removeClass("phx-selected");
    if (this.dataGrid.isEditableGridView === true) {
      this.dataGrid.disableDeleteButton(false);
      if (!CWSTR.isBlank(this.dataGrid.viewRow)) {
        //???
      }
    }

  }

  _notSelectable(): void {
    this._unselectRow();
    if (!$(this.el).hasClass("phx-notSelectable")) {
      $(this.el).addClass("phx-notSelectable");
    }
  }

  _selectable(): void {
    if ($(this.el).hasClass("phx-notSelectable")) {
      $(this.el).removeClass("phx-notSelectable");
    }
  }

  _supprimed(): void {
    this._unselectRow();
    if (!$(this.el).hasClass("phx-supprimed")) {
      $(this.el).addClass("phx-supprimed");
    }
  }

  /**
   * Make the row editable.
   */
  _editRow(): void {
    this.edited = true;
    this.model.off("invalid");
    this.model.on("invalid", this._showValidationErrors, this);
    if (this.dataGrid.isEditableGridView === true) {
      this._renderForm();
    }
    $(this.dataGrid.el).find(".phx-bistate-box").prop("disabled", true);
    this.model.store(); // Store old attributes
  }

  /**
   * Make the row uneditable.
   */
  _uneditRow(): void {
    this.edited = false;
    $(this.dataGrid.el).find(".phx-bistate-box").removeAttr("disabled");
    $(this.el).css("vertical-align", "");
  }

  /**
   * Enable/Disable the multiselection
   */
  _toggleMultiSelection(): void {
    this.model.isChecked = !this._isChecked(this.model.id);

    if (this.model.isChecked) {
      this.dataGrid.model.multiselectColl.add(this.model);
      this.$el.removeClass(this.dataGrid.classUnselectedRow);
      this.$el.addClass(this.dataGrid.classSelectedRow);
      if (!CWSTR.isBlank(this.filterSelection)) {
        this.model.multiselectColl.models = this.model.multiselectColl.where(this.filterMultiSelectColl);
      } else if (this.filterSelectionFunction) {
        this.model.multiselectColl.models = _.filter(this.model.multiselectColl.models, (model): boolean => {
          return this.filterSelectionFunction(model);
        })
      }
      if (CWSTR.isBlank(this.dataGrid.model.get("value"))) {
        // if no rows are selected and a row is checked for multiselection, we select the current row
        this.model.trigger("row:click", this.model);
      }
    } else {
      this.$el.removeClass(this.dataGrid.classSelectedRow);
      this.$el.addClass(this.dataGrid.classUnselectedRow);
      this.dataGrid.model.multiselectColl.remove(this.model);
    }

    this._showMultiSelection();
  }

  /**
   * Check/Uncheck the row for multiselection
   */
  _showMultiSelection(): void {
    const checked = this._isChecked(this.model.id);
    const jQtarget = this.$el.find(".phx-bistate-box");
    jQtarget.prop("checked", checked);
    if (this.dataGrid.hideMultiSelectionCheckBox === true) {
      jQtarget.css("display", "none");
    }
  }

  /**
   * Look if the row is checked in multiselection
   */
  _isChecked(id: string): boolean {
    const item = this.dataGrid.model.multiselectColl.get(id);
    if (!CWSTR.isBlank(item)) {
      return true;
    }
    return false;
  }

  /**
   * Show validation Error for the row form, calling the viewRow generic function.
   */
  _showValidationErrors(model: CWDataGridModel, errors: { [key: string]: any }): void {
    $(this.el).css("vertical-align", "top");
    this.dataGrid.viewRow._showValidationErrors(model, errors, null);
  }

  /**
   * Grid Row render Function
   */
  render(): CWDataGridRowView<TModel> {
    const $rowThis = $(this.el);
    $rowThis.html("");//Clean HTML to remove old cells and avoid duplicate cells when the model is updated
    $rowThis.attr("cid", this.cid);
    const isSelected = $rowThis.hasClass("phx-selected");

    if (this.dataGrid.appendClassToRow !== "") {
      $rowThis.addClass(this.dataGrid.appendClassToRow);
    }
    if (this.dataGrid.selectable) {
      $rowThis.css("cursor", "pointer");
    }
    if (this.edited === true &&
      (this.dataGrid.isEditableGridView === true)) {
      this._renderForm();
    } else {
      _.each(this.dataGrid.currentVue.columns, (column, index) => {
        let cell = null;
        switch (column.property) {
          case "phx-multiselect":
            if (this.dataGrid.currentVue._columns["phx-multiselect"] && this.dataGrid.currentVue._columns["phx-multiselect"].visible) {
              cell = this._addActionCell(column, index, CommonTableCellMultiselectActionTPL);
              //						$(cell.el).addClass("phx-cell-multiselect-action");
            }
            break;
          // CW: These actions are not longer being displayed in the row
          // case "phx-action":
          //   if (this.dataGrid.currentVue._columns["phx-action"] && this.dataGrid.currentVue._columns["phx-action"].visible) {
          //     cell = this._addActionCell(column, index, CommonTableCellEditActionTPL);
          //   }
          //   break;
          case "phx-last":
            break;

          default:
            cell = this._addCell(column, index);
        }

        if (!CWSTR.isBlank(cell)) {
          $rowThis.append(cell.el);
          this.dataGrid.currentVue._columns[column.property].cells.push(cell);
          if (!this.dataGrid.currentVue._columns[column.property].visible) {
            $(cell.el).hide();
          }
        }

        //if this render is called after exit of an editable mode its necessary to set all inputs of the row with tabindex -1
        if (!CWSTR.isBlank(cell) && cell.$el.children().length > 0) {
          if (cell.$el.find("a").is("a")) {
            cell.$el.find("a").attr("tabindex", "-1");
          }
          if (cell.$el.find("select").is("select")) {
            cell.$el.find("select").attr("tabindex", "-1");
          }
          if (cell.$el.find(":input").is(":input")) {
            cell.$el.find("input").attr("tabindex", "-1");
          }
          if (cell.$el.find("button").is("button")) {
            cell.$el.find("button").attr("tabindex", "-1");
          }
        }

      }, this);

      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }

      if (isSelected) {
        this._selectRow();
      }
    }

    $(this.el).attr({ "role": "row" });
    return this;
  }

  /**
   * Adds the cell for the column with the "Save" "Revert" actions.
   */
  _addActionCell(column: any, index: number, action: any): CWDataGridCellView | CWDataGridActionCellView {//action peut être string ou JSON
    let cell;
    let tagName = "";
    let className = "";

    if (!CWSTR.isBlank(this.dataGrid.filterSelection) && !this.model.attributes[this.dataGrid.filterSelection]) {
      cell = new CWDataGridCellView({ "dataGridRow": this, "className": column.className }).render();
      cell.setText("");
    } else {
      if (column.property === "phx-multiselect") {
        tagName = "td";
        className = "col-1";
      }
      if (this.dataGrid.filterSelectionFunction && !this.dataGrid.filterSelectionFunction(this.model)) {
        if (column.property === "phx-multiselect") {
          cell = new CWDataGridCellView({ "dataGridRow": this, "tagName": tagName, "className": className }).render();
        } else {
          cell = new CWDataGridCellView({ "dataGridRow": this, "className": column.className }).render();
        }
        cell.setText("");
      } else {
        let formModel;
        let cellView;

        if (this.dataGrid.viewRow) {
          formModel = this.dataGrid.viewRow.model;
          cellView = new CWDataGridActionCellView({ "dataGridRow": this, "action": action, "model": formModel, "tagName": tagName, "className": className, "rowNumber": this.rowNumber });
        } else {
          cellView = new CWDataGridActionCellView({ "dataGridRow": this, "action": action, "tagName": tagName, "className": className, "rowNumber": this.rowNumber });
        }
        cell = cellView.render();
        if (this.dataGrid.multiselection === true && this.dataGrid.hideMultiSelectionCheckBox === true) {
          $(cell.el).hide();
        }
        $(cell.el).addClass("phx-td" + index);
      }
    }
    return cell;
  }

  /**
   * Create the cells views to be rendered on the row.
   */
  _addCell(column: { [key: string]: any }, index: number): CWDataGridCellView {
    const property = column.property;
    const alignment = (this.dataGrid.currentVue._columnsAlign && this.dataGrid.currentVue._columnsAlign[property]) ? this.dataGrid.currentVue._columnsAlign[property] : "left";
    let value: JQuery | string = null;
    let cellRender = null;
    const cell = new CWDataGridCellView({ dataGridRow: this, "className": column.className }).render();

    //If cellrenderer is definied for one view, use it. If not, use table's cellrenderer.
    if (!CWSTR.isBlank(this.dataGrid.currentVue.cellRenderers) && !CWSTR.isBlank(this.dataGrid.currentVue.cellRenderers[property])) {
      cellRender = this.dataGrid.currentVue.cellRenderers[property];
    } else {
      cellRender = this.dataGrid.cellRenderers[property];
    }
    if (!CWSTR.isBlank(cellRender)) {
      value = cellRender.call(this, this.model, property, cell);
    } else {
      value = CWSTR.getElValue(this.model, property);
    }
    if (value instanceof jQuery && (value as JQuery).hasClass("erreur-validation") === true) {
      $(this.el).addClass("borderErreurStyle");
    }
    cell.setText(value);
    if (column.width === "auto" || column.width === "100%") {
      $(cell.el).addClass("phx-td" + index + " col").attr("role", "gridcell");
    } else {
      let valWCol = column.width;

      if (!CWSTR.isBlank(this.dataGrid?.vues?.CURRENT?._columns[property]?.widthOrigine)) {
        valWCol = this.dataGrid.vues.CURRENT._columns[property].width;
      }
      $(cell.el).addClass("phx-td" + index + " col-" + valWCol).attr("role", "gridcell");
    }
    switch (alignment) {
      case "right":
        $(cell.el).css("text-align", "right");
        break;
      case "center":
        $(cell.el).css("text-align", "center");
        break;
      default:
        //Nothing
        break;
    }
    if (column.paddingZero) {
      $(cell.el).css("padding", "0");
    }
    return cell;
  }



  /**
   * Renders the form, auxiliar function used by the view render.
   */
  _renderForm(): void {
    this.dataGrid.viewRow.pks = this.dataGrid.model.propertyPK;
    const isNew = this.model.isNew();
    const rowContent = this.dataGrid.openEditionVolet(this.model);
    const keys = _.keys(this.dataGrid.currentVue._columns);
    const dataGrid = this.dataGrid;
    let columnsIndex = 0;

    if (dataGrid.multiselection === true) {
      columnsIndex++;
    }
    let actionCell = null;
    if (dataGrid._isRowUpdatable) {
      actionCell = this._addActionCell(dataGrid.currentVue._columns[keys[columnsIndex]], columnsIndex, CommonTableCellSaveRevertActionTPL);
    } else {
      actionCell = this._addActionCell(dataGrid.currentVue._columns[keys[columnsIndex]], columnsIndex, CommonTableCellRevertActionTPL);
    }
    if (!CWSTR.isBlank(actionCell)) {
      dataGrid.currentVue._columns[keys[columnsIndex]].cells.push(actionCell);
      if (!CWSTR.isBlank(this.classForButtons) && $(rowContent).find('.' + this.classForButtons).length > 0) {
        $(rowContent).find('.' + this.classForButtons).append(actionCell.el);
      } else {
        $(rowContent).append(actionCell.el);
      }

      $(rowContent).find("td:last").addClass("cwTdlast");
    }

    if (dataGrid.multiselection === true) {
      const cell = this._addActionCell(dataGrid.currentVue._columns["phx-multiselect"], 0, CommonTableCellMultiselectActionTPL);
      $(cell.el).insertBefore($(rowContent).find("td:first"));
      this._showMultiSelection();
    }

    let cellSelected = false;
    if (!isNew && !CWSTR.isBlank(this.model.collection) && !CWSTR.isBlank(this.model.collection.editModeCellSelected)) {
      const columnName = keys[this.model.collection.editModeCellSelected];
      let $cell = $(rowContent).find("fieldset").find("[data-column='" + columnName + "']").first();
      if ($cell.length > 0) {
        if (!$cell.is(":input") && $cell.find("input").length > 0) {
          $cell = $cell.find("input").first();
        }
        if ($cell.is(":input") && !$cell.is("[readonly]")) {
          $cell.attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus();
          cellSelected = true;
        }
      }
    }

    if (!cellSelected) {
      // select first input not readonly and not multiselection checkbox
      if (dataGrid.multiselection === true) {
        $(rowContent).find(":input:not([readonly])").eq(1).focus();
      } else {
        $(rowContent).find(":input:not([readonly])").eq(0).focus();
      }
    }
  }

  /**
   * Remove the view and his events.
   */
  close(): void {
    this.off();
    this.remove();
  }

  //Accessibility
  _selectCell(index: number): void {
    const cell = !CWSTR.isBlank(index) ? $(this.$el.children()[index]) : $(this.$el.children()[0]);

    $(this.el).parent().find(".grid-cell-focus").removeClass("grid-cell-focus").attr({ "tabindex": "-1" });

    if ($(cell).find(":input").is(":input")) {
      $(cell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus();
    } else {
      $(cell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      CWLOG.debug("focus the first cell");
    }
  }

  isEditableGridView(): boolean {
    return false;
  }

  _manageLineSelected(): void {
    if (this.$el.find(".btn-validations").hasClass("selectionne") === true) {
      this.$el.addClass("phx-selected-multiLine");
    } else {
      this.$el.removeClass("phx-selected");
      this.$el.removeClass("phx-selected-multiLine");
    }
  }


}
