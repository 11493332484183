import _ from 'underscore';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSensBadgeageModel } from './cwSensBadgeages.model';

/**
 * Collection of signing senses
 */
export class CWSensBadgeageCollection<TModel extends CWSensBadgeageModel = CWSensBadgeageModel> extends CWPaginatedCollection<TModel>{

  habilitationV: string;
  habilitationG: string;
  usecase: string;

  constructor(models?: CWSensBadgeageModel[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWSensBadgeageModel;
    super(models, options);
    //Default values from class
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = options.module;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/utiltypeterm/sens";
    }
    //ce n'est pas nécessaire par l'appel "super" -->CWPaginatedCollection.prototype.initialize.call(this); 
    this.paginated = false;
  }
}