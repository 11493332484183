import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';


/**
 * Lister Regularisation Model
 */
export class CWListerRegularisationModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "iddemande": null,
      "valeur": null,
      "historique": false,
      "statut": {
        "code": "",
        "libelle": ""
      },
      "libelle": "",
      "evenement": "",
      "format": "",
      "table": "",
      "parametres": {
        "regDateDebut": "",
        "regLibelleMotif": "",
        "regDateFin": ""
      },
      "code": ""
    }
  }
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);

  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
}