import * as Backbone from 'Backbone';
import _ from 'underscore';
import referentielCollabPopulationDetailTPL from '../cwDetail.tpl.html';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWCollabPopulationCollection } from '../models/cwCollabPopulation.collection';
import { CWCollabPopulationModel } from '../models/cwCollabPopulation.model';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSelecteurRefDialogCollabPopulation } from './cwSelecteurRefDialogCollabPopulation.view';
import { CWSelecteurReferentielView } from 'core/components/selecteur_referentiel/cwSelecteurReferentiel.view.js';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Select collab Population view
 */
export class CWSelectCollabPopulationView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<CWBaseModel> {
  diversIdCollab: any;
  required: any;
  ecran: any;
  foncCour: any;
  context: any;
  fieldWidth: any;
  habContext: any;
  referentiel: typeof CWSelecteurReferentielView;
  $appendTo: JQuery;
  height: any;
  width: any;

  constructor(options?: Backbone.ViewOptions<TModel> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, options.events);
    super(options);
    let onglet;
    let foncCour;

    this.$appendTo = options.appendTo;
    this.template = referentielCollabPopulationDetailTPL;
    this.diversIdCollab = null;
    if (options && options.diversIdCollab) {
      this.diversIdCollab = options.diversIdCollab;
    }
    this.required = options.required ?? false;
    this.height = options.height;
    this.width = options.width;
    this.ecran = options.context.ctxEcran;
    this.foncCour = options.foncCour;
    this.context = options.context; // context for the habilitations
    this.fieldWidth = options.fieldWidth; // Width of the ref input default is "100%"
    if (this.context) {
      onglet = this.ecran;
      foncCour = this.foncCour;
    }
    this.habContext = new CWHabilitationContext({
      onglet: onglet,
      foncCour: foncCour
    });
    this.model = new CWCollabPopulationModel();
    this.model.setHabContext(this.habContext);
  }

  setIdCollab(idCollab: number): void {
    this.diversIdCollab = idCollab;
  }

  render(): CWSelectCollabPopulationView {
    const json = { 'i18n': i18n };
    const coll = new CWCollabPopulationCollection();

    this.$el.html(this.template(json));
    coll.setHabContext(this.habContext);
    this.referentiel = new CWSelecteurReferentielView({
      coll: coll,
      name: "matricule",
      itemRenderer: (model: { [key: string]: any }): string => {
        if (model === null) {
          return '';
        }
        if (!CWSTR.isBlank(model.nom) && !CWSTR.isBlank(model.prenom)) {
          if (!CWSTR.isBlank(this.diversIdCollab) && this.diversIdCollab === "matric_aux") {
            if (!CWSTR.isBlank(model.matricaux)) {
              return model.nom + " " + model.prenom + " (" + model.matricaux + ")";
            } else {
              return model.nom + " " + model.prenom;
            }
          } else {
            if (!CWSTR.isBlank(model.matric)) {
              return model.nom + " " + model.prenom + " (" + model.matric + ")";
            } else {
              return model.nom + " " + model.prenom;
            }
          }
        } else {
          return model.libelle;
        }
      },
      view: CWSelecteurRefDialogCollabPopulation,
      title: i18n.t('referentielcollaborateur.title_pop'),
      height: !CWSTR.isBlank(this.height) ? this.height : 500,
      width: !CWSTR.isBlank(this.width) ? this.width : 500,
      readonly: false,
      required: this.required,
      addItemCallback: (data: any): void => {
        this._responseCallback(data);
      },
      fieldWidth: this.fieldWidth,
      appendTo: this.$appendTo
    });
    this.$el.find(".cmbSelectionner").html(this.referentiel.render().el);
    return this;
  }

  _responseCallback(data: any): void {
    this.model.set(data);
    this.model.trigger("referentielChanged");
  }
}
