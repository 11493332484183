import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'src/utils/sync.js';


/**
 * Model for load the data the Delegations
 */
export class CWDelegationsModel extends CWBaseModel {

  /**
 * Code of the Utilisateur
 */
  utilCode: string | number | boolean;


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.usecase = options.usecase || "droitsdel";
    super(attributes, options);
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
    /**
 * Builds the url for the REST call
 */
    this.url = (): string => {
      const base = Configuration.restRoot + "/rest/delegation/" + encodeURIComponent(this.utilCode) + "/element";

      if (this.isNew()) { return base; }
      return base + (base.charAt(base.length - 1) === '/' ? '' : '/') + encodeURIComponent(this.id);
    };
  }


  /**
   * Default data model
   *		id : null,
   *		code : "",
   *		datedeb : "",
   *		datefin : "",
   *		retro : true,
   *		commentaire : "",
   *		delegue : null,
   *		profils : []
   * }
   */
  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "datedeb": "",
      "datefin": "",
      "retro": false,
      "commentaire": "",
      "delegue": null,
      "profils": []
    }
  }

  /**
   * Validate the model before launch the REST
   */
  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    const datedeb = {//Period validation
      "name": "datedeb",
      "value": attrs.datedeb,
      "label": i18n.t('droitsdel.datedeb')
    };
    const datefin = {
      "name": "datefin",
      "value": attrs.datefin,
      "label": i18n.t('droitsdel.datefin'),
      "isInfinity": true
    };
    const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
    const pDiversModifDeleg = GLOBAL_DATA.paramDivers.get("modifDeleg");

    if (!CWSTR.isBlank(errorMsg)) {
      if (!CWSTR.isBlank(errorMsg.datedeb)) {
        errors.datedeb = errorMsg.datedeb;
      }
      if (!CWSTR.isBlank(errorMsg.datefin)) {
        errors.datefin = errorMsg.datefin;
      }
    }

    if (!CWSTR.isBlank(pDiversModifDeleg) && pDiversModifDeleg.get("valeur") === "1") {
      if (CWSTR.isBlank(errors.datefin) && !CWSTR.isBlank(attrs.datefin)) {
        const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
        if (today >= attrs.datefin) {
          errors.datefin = i18n.t('messages:GT_2109');
        }
      }
    }
    if (CWSTR.isBlank(attrs.delegue) || (CWSTR.isBlank(attrs.delegue.matricule) && CWSTR.isBlank(attrs.delegue.utilnom))) {
      errors.delegue = {};
      errors.delegue.matricule = i18n.t('common:required', { "0": i18n.t('droitsdel.delegues') });
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  /**
   * Parsed the results returned by the REST,
   * Copy the return code in the object id
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      response.id = response.code;
    }
    return response;
  }
}