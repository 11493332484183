import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWAuthModel } from './cwAuth.model';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWBodyView } from '../views/cwBody.view';
import { CWConfigModel } from './cwConfig.model';
import { CWContexteActifModel } from './cwContexteActif.model';
import { CWFractionJourModel } from './cwFractionJour.model';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHeaderView } from '../views/cwHeader.view';
import { CWLOG } from 'src/utils/cwLog';
import { CWLoginLicenseModel } from 'core/components/login/cwLoginLicense.model';
import { CWMenuView } from '../views/cwMenu.view';
import { CWParametresDiversColl } from './cwParametresDivers.collection';
import { CWProfilesModel } from 'common/profilutilisateur/models/cwProfiles.model';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWSubRoute } from 'src/core/routers/cwSubRoute';
import { CWThemeRollConfigModel } from './cwThemeRollConfig.model';
import { CWTransversalSyncModel } from './cwTransversalSync.model';
import { CWTypoEvtGenColl } from './cwTypoEvtGen.collection';
import { CWTypoStandardColl } from './cwTypoStandard.collection';
import { forkJoin } from 'rxjs';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { SESSION } from 'src/utils/session.js';
import { SYNC } from 'src/utils/sync.js';
import { TypeExterneModel } from './typeExterne.model.js';
import { UTILS } from 'src/utils/utils.js';



export class CWWorkFlowModel extends CWReadOnlyModel {

  ZONES: { [key: string]: any };
  BRIQUES: { [key: string]: any };
  TRANSVERSES: { [key: string]: any };
  paramEcranDef: Backbone.Model;
  paramPersNumer: Backbone.Model;
  /**
   * Contains a model with the authentication information
   */
  authModel: CWAuthModel;
  configuration: CWConfigModel;
  syncModel: CWTransversalSyncModel;
  RESP_POPULATION: { [key: string]: any };
  MODULES_ZONES: { [key: string]: any };
  pendingPopulationRefresh: boolean;
  bodyView: CWBodyView<CWBaseModel>;
  headerView: CWHeaderView;
  menuView: CWMenuView<CWWorkFlowModel>;

  defaults(): { [key: string]: any } {
    return {
      "ready": false,
      "fullscreen": false,
      "zone": "",
      "usecase": "",
      "operation": "",
      "uri": ""
    }
  }
  /**
   * Constructor
   * Workflow model for App
   * Links to: CWAuthModel, navModel
   * Attributes: zone, usecase, operation, uri,
   * 			   tabs-[zoneId] = number of open tabs, "lastuc-[zoneId]" last uc open in zone
   * Events: user:logout, change:zone, change:usecase, change:operation (operation is categories/[list] / [8] ... )
   *         add:tab, remove:tab
   */

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.ZONES = {};
    this.BRIQUES = {};
    this.TRANSVERSES = {};
    this.paramEcranDef = null;
    /**
     * Contains a model with the authentication information
     */
    this.authModel = new CWAuthModel({ "id": "session" });
    this.configuration = new CWConfigModel();
    GLOBAL_DATA.paramDivers = new CWParametresDiversColl();
    GLOBAL_DATA.typologies = new CWTypoStandardColl();
    GLOBAL_DATA.typoevtgen = new CWTypoEvtGenColl();
    GLOBAL_DATA.licences = new CWLoginLicenseModel();
    GLOBAL_DATA.theme = new CWThemeRollConfigModel();
    GLOBAL_DATA.profiles = new CWProfilesModel();
    this.syncModel = new CWTransversalSyncModel();
    this.set("ready", false);
  }


  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback: () => void): void {

    // declare event consumers
    this.on("user:prelogout", this._prelogout, this);
    this.on("user:logout", this._logout, this);
    this.on("change:usecase", this._usecaseChange, this);
    this.on("add:tab", this._addTab, this);
    this.on("remove:tab", this._removeTab, this);

    // register sync events
    this._registerSyncEvents();

    // Data of the connected user
    this.authModel.fetch({
      success: (): void => {
        this._themeConfiguration();
        // Launch time synchronization
        SYNC.calculateDelay();
        this.configuration.fetch({
          success: (): void => {
            const calls = [];
            let language = this.configuration.get("langue").toLowerCase();
            let profilesDefer = null;
            const languageDefer = $.Deferred();

            if (CWSTR.isBlank(language)) {
              language = "fr";
            }
            //mettre à jour l'information dans la page
            $("html").attr("lang", language); //'language'
            CWLOG.info("Selected user language : " + language);
            calls.push(languageDefer);
            i18n.changeLanguage(language, (err: { [key: string]: any }): void => {
              if (err) {
                languageDefer.reject();
              } else {
                languageDefer.resolve();
              }
            });
            //
            //Pas utiliser ici ou en ce point CWHABILITATION.canView-> il n'y a pas de donnés encore
            if (this.canViewBefore("UTI_SELPROFCOUR")) {// l'affichage de l'hierarchie n'est pas possible sans le profil courant. Le contrôle est fait par métier pour "UTI_SELPROFHIER"
              //ici, on ne doit pas faire '&& this.canViewBefore("UTI_SELPROFHIER")) {'
              profilesDefer = GLOBAL_DATA.profiles.fetch({
                error: (): void => {
                  this._logout();
                }
              });
              calls.push(profilesDefer);
            }

            const paramDiversDefer = GLOBAL_DATA.paramDivers.fetch();
            calls.push(paramDiversDefer);


            const licencesDefer = GLOBAL_DATA.licences.fetch();
            calls.push(licencesDefer);

            const typologiesDefer = GLOBAL_DATA.typologies.fetch();
            calls.push(typologiesDefer);

            //Contexte actif des évènements
            GLOBAL_DATA.contextActif = [];
            //Absences et souhaits
            GLOBAL_DATA.contextActif.contextActifAbs = new CWContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "ABSENCE", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifAbs.fetch());
            //activités prevues
            GLOBAL_DATA.contextActif.contextActifActPre = new CWContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "ACTPREV", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifActPre.fetch());
            //Activités realisées
            GLOBAL_DATA.contextActif.contextActifActRea = new CWContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "ACTREAL", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifActRea.fetch());
            //Horaire excepcionnel
            GLOBAL_DATA.contextActif.contextActifHorExcep = new CWContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "HOREXCEP", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifHorExcep.fetch());
            //Pret
            GLOBAL_DATA.contextActif.contextActifPret = new CWContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "PRET", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifPret.fetch());
            //Regularisation
            GLOBAL_DATA.contextActif.contextActifReg = new CWContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "REGULARISATION", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifReg.fetch());
            GLOBAL_DATA.contextActif.length = _.keys(GLOBAL_DATA.contextActif).length;

            // EVOL BIRT
            GLOBAL_DATA.TYPEEXTERNE = new TypeExterneModel();
            calls.push(GLOBAL_DATA.TYPEEXTERNE.fetch());

            //EVO #887 Fraction de journée
            GLOBAL_DATA.fractionJour = new CWFractionJourModel();
            calls.push(GLOBAL_DATA.fractionJour.fetch());


            const sub = forkJoin(calls);
            sub.subscribe((): void => {
              this._menuConfiguration();
              //les événement génériques ont besoin de savoir les "rights". Par eux, ils doivent aller après de _menuConfiguration
              this._loadEvenementsGeneriques(callback);
            },
              (): void => {
                this._logout();
              });
          },
          error: (): void => {
            this._logout();
          }
        });

      }
    });

    // Screen model is Ready
    this.set("ready", true);
  }

  /**
   * Set up the recorded themplate (themeroll) EVO 569
   */
  _themeConfiguration(): void {
    GLOBAL_DATA.theme.fetch({
      success: (model?: CWThemeRollConfigModel): void => {
        const prefTheme = _.find(model.get("preferences"), (item: { [key: string]: any }): boolean => {
          return item.code === "AP_THEME";
        });
        const theme = !_.isEmpty(prefTheme) ? prefTheme.varchar1 : "";
        let element: HTMLLinkElement = null;
        let confTheme = !CWSTR.isBlank(theme) ? _.find(Configuration.themes, (itemTheme: string): boolean => {
          return itemTheme === theme;
        }) : "ctime.theme";

        element = document.createElement("link");
        if (CWSTR.isBlank(confTheme)) {
          confTheme = "ctime.theme";
        }
        //element.href = "css/" + theme + ".css?_=" + window.crypto.getRandomValues(new Uint32Array(1))[0];
        element.href = "css/" + confTheme + ".css";
        element.id = "ui-theme";
        element.rel = "stylesheet";
        element.addEventListener("load", () => {
          $("#phx-wrap").addClass("cw-themeLoaded");
          (Backbone as any).trigger("resize:center");
        }, false);
        document.head.appendChild(element);
      }
    });
  }

  _menuConfiguration(): void {
    const transverses = this.configuration.get("confignav").get("transverses");

    // gestion transverses
    this.TRANSVERSES["user"] = [];
    _.each(transverses, (transverse: { [key: string]: any }): void => {
      if (transverse["id"] === "ppmpd") {
        this.TRANSVERSES["user"].push(transverse["id"]);
      }
    });
    this.RESP_POPULATION = {};
    // gestion zones
    if (_.isEmpty(this.ZONES)) {
      const zones = this.configuration.get("confignav").get("zones");

      // To manage the numbers of tabs
      this.set("tabstotal", 0);
      _.each(zones, (zone: { [key: string]: any }): void => {
        this.ZONES[zone["id"]] = [];
        this.BRIQUES[zone["id"]] = [];
        this.set("tabs-" + zone["id"], 0);
        _.each(zone.menus, (menu: { [key: string]: any }): void => {
          if (menu.id) {
            this.ZONES[zone["id"]].push(menu["id"]);
          }
          if (menu.emid === "EM_00026") {
            this.RESP_POPULATION.showMenu = true;
          }
          _.each(menu.ecrans, (uc: { [key: string]: any }): void => {
            if (uc.emid === "EM_00027") {
              this.RESP_POPULATION.showGerer = true;
            }
            _.each(uc.ecrans, (uc2: { [key: string]: any }): void => {
              this.ZONES[zone["id"]].push(uc2["id"]);
            });
            if (uc["id"]) {
              this.ZONES[zone["id"]].push(uc["id"]);
            }
          });
        });
        // briques
        _.each(zone.briques, (brique: { [key: string]: any }): void => {
          if (brique["id"]) {
            this.BRIQUES[zone["id"]].push(brique);
          }
        });
      });
    }
    this.MODULES_ZONES = [];
    _.each(_.keys(this.ZONES), (aZone: string): void => {
      _.each(this.ZONES[aZone], (aModule: string): void => {
        if (CWSTR.isBlank(this.MODULES_ZONES[aModule])) {
          this.MODULES_ZONES[aModule] = [];
        }
        this.MODULES_ZONES[aModule].push(aZone);
      });
    });

    if (_.contains(_.keys(this.ZONES), "resp")) {
      if (!this.MODULES_ZONES["agenda_R"]) {
        this.MODULES_ZONES["agenda_R"] = [];
      }
      this.MODULES_ZONES["agenda_R"].push("resp");
    }

    GLOBAL_DATA.types = this.configuration.get("typeapplicatif");
    GLOBAL_DATA.rights = this.configuration.get("droit");
    GLOBAL_DATA.mymetriksurl = this.configuration.get("mymetriksurl");
    GLOBAL_DATA.moddebugurl = this.configuration.get("moddebugurl");

    this.paramEcranDef = GLOBAL_DATA.paramDivers.get("ecranDef");
    // pers_numer
    this.paramPersNumer = GLOBAL_DATA.paramDivers.get("pers_numer");
  }

  _reloadBriquesInfo(callback: () => void, usecase: string): void {
    const configurationModel = new CWConfigModel();

    configurationModel.usecase = usecase;
    UTILS.showUsecaseHidder(usecase);
    configurationModel.fetch({
      success: (freshConfiguration: CWConfigModel): void => {
        const zones = freshConfiguration.get("confignav").get("zones");

        setTimeout(() => {//pour afficher le hidder pendant le navigateur peint
          UTILS.hideUsecaseHidder(usecase);
        }, 100);
        this.set("tabstotal", 0);
        _.each(zones, (zone: { [key: string]: any }): void => {
          this.BRIQUES[zone["id"]] = [];
          // briques
          _.each(zone.briques, (brique: { [key: string]: any }): void => {
            if (brique["id"]) {
              this.BRIQUES[zone["id"]].push(brique);
            }
          });
        });
        callback();
      }
    });
  }

  isGoingToZoneHome(): boolean {
    // Check if there are only one in the uri.
    let isGoing = false;
    const match = this.get("uri").match(/\//g);

    if (match !== null) {
      isGoing = match.length === 1;
    }
    return isGoing;
  }

  // register listener to syncEvents type
  _registerSyncEvents(): void {
    this.pendingPopulationRefresh = false;
    this.listenTo(objs.appRt.workflow, "change:uri", this._managePendingSyncEvents);
    this.listenTo(objs.appRt.workflow.syncModel, "change:population", this._manageChangePopulationEvent);
  }

  // execute pending syncEvents
  _managePendingSyncEvents(): void {
    if (this.pendingPopulationRefresh === true) {
      this._manageChangePopulationEvent();
    }
  }

  // syncEvent : populations
  _manageChangePopulationEvent(): void {
    if (objs.appRt.workflow.get("zone") === "resp" && objs.appRt.workflow.get("usecase") === "") {
      this._changePopulation();
    } else {
      this.pendingPopulationRefresh = true;
    }
  }

  _changePopulation(): void {
    this.trigger("refresh:Briques_" + this.get("zone"), true);
    this.pendingPopulationRefresh = false;
  }

  /**
   * Sets the uri when the zone changes
   */
  _zoneChange(): void {
    const zoneId = this.get("zone");

    if (zoneId === "") {
      this.set("uri", "");
    } else {
      this.set("uri", "z/" + zoneId);
    }
  }

  _usecaseChange(): void {
    const zoneId = this.get("zone");
    const ucId = this.get("usecase");

    // Stores the last zone id
    this.set("lastuc-" + zoneId, ucId);
  }

  _operationChange(): void {
    const zoneId = this.get("zone");
    const ucId = this.get("usecase");
    const operation = this.get("operation");

    if (ucId === "") {
      this.set("uri", "z/" + zoneId);
      return;
    }
    if (!_.contains(this.ZONES[zoneId], ucId)) {
      CWLOG.error("The required zone/usecase is not valid : [" + zoneId + "," + ucId + "]");
      return;
    }
    this.set("uri", "z/" + zoneId + "/uc/" + ucId + "/" + operation);
  }

  /**
   * Realize actions before logout
   */
  _prelogout(): void {
    const subRouters = _.filter(objs, (obj: { [key: string]: any }): boolean => {
      return (obj instanceof CWSubRoute) && (!CWSTR.isBlank((obj as any)._treatDesconnection));
    });
    const treatDesconnection = (index: number): void => {
      subRouters[index]._treatDesconnection((cancel: boolean): void => {
        if (!cancel && index < (subRouters.length - 1)) {
          treatDesconnection(index + 1);
        }
        if (index >= (subRouters.length - 1) && !cancel) {
          this.trigger("user:logout");
        }
      });
    };

    if (subRouters.length > 0) {
      treatDesconnection(0);
    } else {
      this.trigger("user:logout");
    }
  }

  _logout(isAsync?: boolean, notLogin?: boolean): void {
    // async -> The script is executed asynchronously with the rest of the page (the script will be executed while the page continues the parsing)
    const async = _.isBoolean(isAsync) ? isAsync : true;

    CWLOG.debug("logout required");
    // Clear user rights
    GLOBAL_DATA.rights = {};
    // Clear session data
    SESSION.clear();
    this.authModel.destroy({
      success: (): void => {
        CWLOG.debug("logout successful");
        this.authModel.clear();
        if (!notLogin) {
          this._goToLoginPage();
        }
      },
      error: (): void => {
        CWLOG.debug("logout failed");
        let urlTmp = '../chronotime/rest/authentification/session';

        if (Configuration.restRoot.indexOf('http') !== -1) {
          urlTmp = Configuration.restRoot + '/rest/authentification/session';
        }
        //Custo #151085
        $.ajax({
          url: urlTmp,
          dataType: 'json',
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          success: (): void => {
            // Do something with the result
          },
          error: (): void => {
            // Do something with the result
          }
        });

        if (this.authModel) {
          this.authModel.clear();
        }
      },
      "async": async
    });
  }

  _addTab(zoneId: string): void {
    if (!CWSTR.isBlank(zoneId)) {
      const attrName = "tabs-" + zoneId;
      let currentNumber = this.get(attrName);
      currentNumber = currentNumber + 1;
      this.set(attrName, currentNumber);
      this.set("tabstotal", this.get("tabstotal") + 1);
    }
  }

  _removeTab(zoneId: string): void {
    const attrName = "tabs-" + zoneId;
    let currentNumber = this.get(attrName);

    currentNumber = currentNumber - 1;
    this.set(attrName, currentNumber);
    this.set("tabstotal", this.get("tabstotal") - 1);
  }

  _goToLoginPage(): void {
    if (CWSTR.isBlank(this.configuration.get("logouturl")) || _.isEqual(this.configuration.get("logouturl"), "")) {
      window.location.href = "login/login.html";
    } else {
      window.location.href = this.configuration.get("logouturl");
    }
  }

  _loadPreferedUc(): boolean {
    let navigated = false;
    const param = this.paramEcranDef;

    // When there is a parameter for prefered uc in start up
    if (!CWSTR.isBlank(param) && !CWSTR.isBlank(param.get("valeur")) && param.get("valeur").length > 0) {
      const uc = param.get("valeur");

      if (Object.prototype.hasOwnProperty.call(this.ZONES, uc)) { //its a zone
        objs.appRt.navigate("z/" + uc, true);
        navigated = true;
      } else {
        const zone = this._searchZoneFromUc(uc);

        if (!CWSTR.isBlank(zone)) { // When it's possible to load the prefered uc
          if (uc === "planresp" || uc === "planact") {
            objs.appRt.navigate("z/" + zone + "/pl/" + uc, true);
          } else {
            // Planmed est /uc/
            objs.appRt.navigate("z/" + zone + "/uc/" + uc, true);
          }
          navigated = true;
        }
      }
    }
    return navigated;
  }

  _searchZoneFromUc(ucToFind: string): string {
    let foundZone = null;

    if (!CWSTR.isBlank(ucToFind)) {
      /*customer 209130, checking to see whether the user has access to a certain UC,
      so they cant copy and paste a URL to a UC they dont have permissions to see.
      if not it returns them to the login screen*/
      if (this.MODULES_ZONES[ucToFind.split("/")[0]] !== undefined) {
        foundZone = this.MODULES_ZONES[ucToFind.split("/")[0]][0];
      } else {
        this._goToLoginPage();
      }
    }
    return foundZone;
  }

  getBriqueUniqueId(object: { [key: string]: any }): string {
    let uniqueId;
    if (!CWSTR.isBlank(object.personnalisation)) {
      uniqueId = object.id + "," + object.emid + "," + object.personnalisation.userid + "," + object.personnalisation.codebrique;
    } else {
      uniqueId = object.id + "," + object.emid;
    }
    return uniqueId;
  }

  _loadEvenementsGeneriques(callback: () => void): void {
    if (CWHABILITATION.canView("PAR_EVTGEN.V") || CWHABILITATION.canView("COL_EVTGEN.V") || CWHABILITATION.canView("RES_EVTGEN.V")) {
      GLOBAL_DATA.typoevtgen.fetch({
        success: (fresh: CWTypoEvtGenColl): void => {
          if (!_.isEmpty(fresh)) {
            const calls = [];
            let sub = null;

            //Ëvènements génériques
            GLOBAL_DATA.contextActif.contextActifEvtgen = [];
            for (let i = 0; i < fresh.length; i++) {
              GLOBAL_DATA.contextActif.contextActifEvtgen[fresh.models[i].get("code")] = new CWContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "EVTTYPOGEN", "typologie": fresh.models[i].get("code") });
              calls.push(GLOBAL_DATA.contextActif.contextActifEvtgen[fresh.models[i].get("code")].fetch());
            }
            if (!_.isEmpty(calls)) {
              GLOBAL_DATA.contextActif.contextActifEvtgen.length = _.keys(GLOBAL_DATA.contextActif.contextActifEvtgen).length;
              sub = forkJoin(calls);
              sub.subscribe((): void => {
                if (callback) {
                  callback();
                }
              },
                (): void => {
                  this._logout();
                });
            } else {
              if (callback) {
                callback();
              }
            }
          } else {
            if (callback) {
              callback();
            }
          }
        },
        error: (): void => {
          this._logout();
        }
      });

    } else {
      if (callback) {
        callback();
      }
    }
  }

  canViewBefore(fonction: string): boolean {
    //Pour êtree utilisé avant d'avoir l'information dans
    //GLOBAL_DATA.rights....->et pouvoir utiliser CWHABILITATION.canView(..)
    try {
      let right = false;
      const fonctions = CWHABILITATION.toArray(fonction);
      const droits = this.configuration.get("droit");

      for (let i = 0, l = fonctions.length; i < l; i++) {
        right = right || !CWSTR.isBlank(droits.get(fonctions[i]));
      }
      return right;
    } catch (err) {
      return false;
    }
  }
}
