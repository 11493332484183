import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

export var onBeforeSend = function(headers) {
  if (UTILS) {
    if (headers["gfi-context"]) {
      if (objs && objs.appRt &&
        (objs.appRt.blocked === true || objs.appRt.hidderDisabled === true)) {
        //don't show usecase hidder when app is blocked
      } else {
        UTILS.showUsecaseHidder(JSON.parse(headers["gfi-context"]).onglet);
      }
    }
  }
}
