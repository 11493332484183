import { BaseModel } from 'core/models/base.model';
import { STR } from 'utils/str.js';

/**
 * Selecteur Activite mode simple model
 */
export var completeDomainData = BaseModel.extend({

  defaults: {
    "code": "",
    "realise": true,
    "typologie": {
      "code": "",
      "libelle": ""
    },
    "libelle": "",
    "hiertype": {
      "code": "",
      "libelle": ""
    },
    "format": {
      "code": "",
      "libelle": ""
    },
    "actstruct": {
      "rattcollab": null,
      "code": null,
      "libelle": null
    },
    "prevu": false,
    "typedom": {
      "code": "",
      "libelle": ""
    },
    "hierarchie": false

  },

  initialize: function(code) {
    this.code = code;
    return Backbone.Model.prototype.initialize.call(this);
  },

  url: function() {
    var ls_url = Configuration.restRoot + "/rest/activite/domaine/" + encodeURIComponent(this.code);

    if (this.autresParams && _.keys(this.autresParams).length > 0) {
      for (var i = 0; i < _.keys(this.autresParams).length; i++) {
        var l_param = _.keys(this.autresParams)[i];

        ls_url += ((i === 0) ? "?" : "&") + l_param + "=" + this.autresParams[l_param];
      }
    }
    return ls_url;
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }
    if (response.hiertype === undefined) {
      response.hiertype = this.defaults.hiertype;
    }
    return response;
  }
});
