import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLSimpleSelectourStructureDialog from '../simpleSelectourStructureDialog.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWPopulationMenuView } from './cwPopulationMenu.view';
import { CWSelecteurCheminView } from 'core/components/selecteur_chemin/cwSelecteurChemin.view';
import { FormatColonneSortieModel } from 'src/uc/referentiel/autres/interfaces/models/formatColonneSortie.model';
import { i18n } from 'src/i18n.js';


export class CWPopupSelectStructureView extends CWBaseFormView {

  structSelecteurChemin: CWSelecteurCheminView;
  context: { [key: string]: any };
  tooltipDataSelected: any;
  parent: CWPopulationMenuView;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .buttonAppliquer": "_appliquerStruct"
    }, options.events);
    options.className = "populationsmenu";
    super(options);
    this.template = TPLSimpleSelectourStructureDialog;
    /*
     *Récupération des options du contexte
     */
    Object.keys(options || {}).forEach((key: any): void => {
      (this as any)[key] = options[key];
    });
    /*
     Sélecteur
     */
    this.structSelecteurChemin = new CWSelecteurCheminView({
      titlePopup: this.context.name,
      name: this.context.name, //Données de la structure
      listRenderStyle: this.context.format, //Données de la structure
      fieldWidth: 280,
      height: 700,
      width: 850,
      structid: this.context.code, //Données de la structure
      datedebut: this.context.datedeb, //Données de la structure
      datefin: this.context.datefin, //Données de la structure
      intermediaire: true,
      elementCode: options,
      partial: true,
      modeComplet: true,
      multiselection: false,
      context: this.context
    });
    this.structSelecteurChemin.on("generatedTooltip:selectourChemin", (data: any): void => {
      if (data.multiple === false) {
        this.tooltipDataSelected = data;
        this.$el.find(".buttonAppliquer").removeClass("btn-primary-bis");
      }
    }, this);
  }

  _appliquerStruct(): void {
    this.parent._clearTooltipStructure();
    $(this.parent.el).find(".phx-population-menu-header").html(i18n.t('populationsmenu.tous'));
    if (this.tooltipDataSelected && this.tooltipDataSelected.tooltip) {
      let descendenceSelected: boolean = FormatColonneSortieModel;

      $(this.parent.el).find(".phx-population-menu-header").html(this.structSelecteurChemin.selection.at(0).get("label"));
      $(this.parent.el).find(".phx-population-menu-header").get(0).title = this.tooltipDataSelected.tooltip;
      $(this.parent.el).find(".phx-population-menu-header").tooltip({ content: this.tooltipDataSelected.tooltip });
      descendenceSelected = this._getPopulationDescendence();
      this.parent._setStructureOnTraversalComponent(this.structSelecteurChemin.selection, descendenceSelected);
    }
    this.parent.dialog[this.context.name].close();
  }

  _getPopulationDescendence(): boolean {
    return $(".phx-populations-detail-structure-simple input.populationDescendence").prop("checked");
  }

  render(): any {
    const json = { i18n: i18n, context: this.context };

    this.$el.html(this.template(json));
    this.$el.find("#selectorEstructura").html(this.structSelecteurChemin.render().el);
    this.$el.find(".buttonAppliquer").addClass("btn-primary-bis");
    return this;
  }
}
