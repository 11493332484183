import * as Backbone from 'Backbone';
import _ from 'underscore';
import collapsibleDropdownViewContainerComponentTPL from './cwCollapsibleBlock.tpl.html';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { UTILS } from 'src/utils/utils';


export interface CWCollapsibleBlockOptions<TModel extends Backbone.Model = CWBaseModel> extends Backbone.ViewOptions<TModel> {
  iconHidden?: any;
  detailTemplate?: any;
  expanded?: any;
  component?: any;
  buttons?: any;
  buttonsLine?: any;
  ctxEcran?: any;
  popupGerer?: any;
  typeAff?: any;
}

interface CWCollapsibleBlockButton {
  id: string;
  text?: string;
  icons?: any;
  validateLine?: any;
  disableLine?: any;
}

/**
 * common CollapsibleBlockView
 */
export abstract class CWCollapsibleBlockView<TModel extends Backbone.Model = CWBaseModel> extends Backbone.View<TModel>  {
  protected menuBar: boolean;
  protected templateHTML: JQuery;
  protected $block: JQuery;
  protected $icon: JQuery;
  protected $title: JQuery;
  protected $content: JQuery;
  protected contentVoid: boolean;
  protected titleHTML: string;
  protected iconHidden: boolean;
  protected expanded: boolean;
  protected component: string;
  protected detailTemplate: any;
  protected buttons: CWCollapsibleBlockButton[];
  protected buttonsLine: any;
  protected ctxEcran: any;
  protected popupGerer: any;
  protected secondaryAff: any;
  protected withFocus: any;
  protected context: any;

  constructor(params: CWCollapsibleBlockOptions<TModel>) {
    params = params || {};
    params.events = _.extend({
      "click span.c-block__titleIcon": "_clickHandler",
      "keydown": "_keydownHandler"
    }, params.events);
    super(params);
    this.template = collapsibleDropdownViewContainerComponentTPL;
    this.templateHTML = $(this.template({ 'UTILS': UTILS }));
    this.$block = this.templateHTML;
    this.$icon = this.templateHTML.find(".c-block__titleIcon");
    this.$title = this.templateHTML.find(".c-block__titleText");
    this.$content = this.templateHTML.find(".c-block__content");

    this.menuBar = false;
    //flag for build menu
    this.contentVoid = true;

    this.titleHTML = null;
    this.iconHidden = false;
    if (!CWSTR.isBlank(params)) {
      if (params.iconHidden) {
        this.iconHidden = params.iconHidden;
      }
      this.detailTemplate = params.detailTemplate;
      if (!CWSTR.isBlank(params.expanded)) {
        this.expanded = params.expanded;
      } else {
        this.expanded = false;
      }
      this.component = params.component;
      this.buttons = params.buttons;
      this.buttonsLine = params.buttonsLine;
      this.ctxEcran = params.ctxEcran;

    }
    // Set WAI-ARIA attributes
    this.$block.attr("role", "region");
    this.$block.attr("tabindex", "0");
    this.withFocus = false;
  }

  public setButtons(arrayButtons: []): void {
    this.buttons = arrayButtons;
  }

  public setButtonsLine(arrayButtons: []): void {
    this.buttonsLine = arrayButtons;
  }

  public render(keepVisualizationState?: boolean): CWCollapsibleBlockView<TModel> {
    this.setElement(this.templateHTML);
    this.$title.html(this.titleHTML);
    if (this.iconHidden) {
      this.$icon.hide();
    }
    if (this.expanded === true) {
      this.expand();
    }
    if (!keepVisualizationState) {
      this.hideBlock();
      if (!CWSTR.isBlank(this.context)) {
        this.showBlock();
      }
    }
    return this;
  }

  public setTitle(html: string): void {
    this.titleHTML = html;
    this.$title.html(this.titleHTML);
  }

  /**
   * Method used just to hide the title of the block
   */
  public hideTitle(): void {
    this.$title.hide();
  }

  public setContent(html: any): void {
    this.$content.html(html);
  }

  public hideIcon(): void {
    this.iconHidden = true;
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0) {
      //this.collapse();
      this.$icon.hide();
    }
  }

  public showIcon(): void {
    this.iconHidden = false;
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0) {
      this.$icon.show();
      if (this.context && this.context.ctxBlocEstDeplie) {
        this.expand();
      }
    }
  }

  public collapse(): void {
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0 && !CWSTR.isBlank(this.$content) && this.$content.length > 0) {
      this.$el.addClass("c-collapsePanel--open");
      this.$el.removeClass("c-collapsePanel--close");
      this.$content.hide();
      if (!this.context.ctxBlocEstDeplie) {
        this.expanded = false;
      }
      this.model.trigger("collapse", this);
      // Set WAI-ARIA attributes
      this.$block.attr("aria-expanded", "false");
      this.$content.attr("aria-hidden", "true");
    }
  }

  public expand(): void {
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0 && !CWSTR.isBlank(this.$content) && this.$content.length > 0) {
      this.$el.removeClass("c-collapsePanel--open");
      this.$el.addClass("c-collapsePanel--close");
      this.$content.show();
      if (this.context.ctxBlocEstDeplie) {
        this.expanded = true;
      }
      this.model.trigger("expand", this);
      // Set WAI-ARIA attributes*/
      this.$block.attr("aria-expanded", "true");
      this.$content.attr("aria-hidden", "false");
    }
  }

  public hideBlock(): void {
    this.$el.hide();
  }

  public showBlock(): void {
    this.$el.show();
  }

  public showBlockNoCollapsable(): void {
    this.hideBlock();
    this.iconHidden = true;
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0) {
      this.$icon.hide();
    }

    if (!CWSTR.isBlank(this.$content) && this.$content.length > 0) {
      this.$content.show();
      if (this.context.ctxBlocEstDeplie === true) {
        this.expanded = true;
      }
      this.model.trigger("expand", this);
      // Set WAI-ARIA attributes*/
      this.$block.attr("aria-expanded", "true");
      this.$content.attr("aria-hidden", "false");
    }

    this.showBlock();
  }

  /*
   * Returns True when icon is not hidden.
   */
  public isEmpty(): boolean {
    return (this.$el.css("display") === "none") ||
      (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0 && this.$icon.css("display") === "none");
  }

  public isExpanded(): boolean {
    return this.expanded;
  }

  protected _clickHandler(): void {
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0 && this.$icon.css("display") !== "none") {
      if (this.$el.hasClass("c-collapsePanel--open")) {
        this.context.ctxBlocEstDeplie = true;
        this.expanded = true;
        this.expand();
      } else {
        this.context.ctxBlocEstDeplie = false;
        this.expanded = false;
        this.collapse();
      }
    }
  }

  protected _keydownHandler(e: KeyboardEvent): void {
    if ((e.target as HTMLElement).className.split(" ")[0] === "c-block") {
      const key = e.which || e.keyCode;
      if (key === 13 /* enter */ || key === 32 /* space */) {
        e.preventDefault();
        this._clickHandler();
      }
    }
  }
  protected _manageBlocEstDeplie(): void {
    if (this.context && this.context.ctxBlocEstDeplie === true) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  public abstract setContext(context: { [key: string]: any }, anyElement?: any): void; //anyElement pourra être un boolean, function, collection, etc

  protected abstract _generateContent(collection?: Backbone.Collection, callback?: () => any): any;

  protected abstract _loadData(): void;
}
