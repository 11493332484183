import _ from 'underscore';
import { CWCollaborateursModel } from './cwCollaborateurs.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';

/**
 * Collection of collaborateurs
 */
export class CWCollaborateursColl extends CWPaginatedCollection<CWCollaborateursModel> {

  nonaffectes: any;
  selectcode: any;
  type_collab: string;
  exclure_fusionencours: boolean;
  id_simulation: any;
  aff_collabsimu: boolean;
  typegestCode: any;

  constructor(models?: CWCollaborateursModel[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWCollaborateursModel;
    super(models, options);
    this.sortings = { nom: true, prenom: true, matric: true };

    if (options) {
      this.nonaffectes = options.nonaffectes;
      this.selectcode = options.selectcode;
      this["type_collab"] = options["type_collab"];
      this["exclure_fusionencours"] = options["exclure_fusionencours"];
      this["id_simulation"] = options["id_simulation"];
      this["aff_collabsimu"] = options["aff_collabsimu"];
      this.typegestCode = options.typegestCode;
    }

    this.url = (): string => {
      let next = false;
      let url = Configuration.restRoot + "/rest/population/collaborateurs?";

      if (!CWSTR.isBlank(this.nonaffectes)) {
        url += "nonaffectes=" + this.nonaffectes;
        if (CWSTR.isBlank(this.selectcode)) {
          this.selectcode = 0;
        }
        url += "&selectcode=" + this.selectcode;
        next = true;
      }
      if (!CWSTR.isBlank(this.type_collab) && !CWSTR.isBlank(this.exclure_fusionencours)) {
        url += (next ? '&' : '') + "type_collab=" + this.type_collab;
        url += "&exclure_fusionencours=" + this.exclure_fusionencours;
        if (!CWSTR.isBlank(this.typegestCode)) {
          url += "&typegest=" + encodeURIComponent(this.typegestCode);
        }
      }
      if (!CWSTR.isBlank(this["id_simulation"]) && !CWSTR.isBlank(this["aff_collabsimu"])) {
        url += (next ? '&' : '') + "aff_collabsimu=" + this["aff_collabsimu"];
      }
      return url;
    }
  }


  fetch(pOptions: any): any {
    const options = pOptions || {};

    return CWPaginatedCollection.prototype.fetch.call(this, options);
  }
}