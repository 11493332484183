module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="cw-fieldset renouvellementMotPasse">\n    <!-- Empty div //RGAA - needed to show "(*) : champ obligatoire"  -->\n    <div></div><br>\n    <div class="form-row messages cw-texteNormal">\n        <span id="messageInfor" class=""></span>\n    </div>\n\n    <div class="form-row">\n        <div class="old_password form-group">\n            <label for="password" class="cw-required">'+
((__t=(i18n.t('login:pwdmotpassact')))==null?'':__t)+
'</label>\n            <input id="password" class="password form-control" type="password" size="35" maxlength="32" autocomplete="current-password" aria-required="true" />\n            <button id="wp-togglePassword_old" title="'+
((__t=(i18n.t('login:logPasswordShow')))==null?'':__t)+
'" class="iconMotdepasseOld">'+
((__t=(UTILS.getSVGIcon("oeil", "cw-icon-24", 24)))==null?'':__t)+
'</button>\n            <button id="wp-togglePasswordBarre_old" title="'+
((__t=(i18n.t('login:logPasswordHide')))==null?'':__t)+
'" class="iconMotdepasseBarreOld d-none">'+
((__t=(UTILS.getSVGIcon("oeil_barre", "cw-icon-24", 24)))==null?'':__t)+
'</button>\n            <span class="password-error-container d-flex"></span>\n        </div>\n    </div>\n    <div class="form-row">\n        <div class="form-group motdepasse_new new">\n            <label for="pwd_new" class="cw-required">'+
((__t=(i18n.t('login:pwdmotpassnou')))==null?'':__t)+
'</label>\n            <input id="pwd_new" class="pwd_new form-control" type="password" size="35" maxlength="32" autocomplete="new-password" aria-required="true" />\n            <button id="wp-togglePassword_new" title="'+
((__t=(i18n.t('login:logPasswordShow')))==null?'':__t)+
'" class="iconMotdepasseNew">'+
((__t=(UTILS.getSVGIcon("oeil", "cw-icon-24", 24)))==null?'':__t)+
'</button>\n            <button id="wp-togglePasswordBarre_new" title="'+
((__t=(i18n.t('login:logPasswordHide')))==null?'':__t)+
'" class="iconMotdepasseBarreNew d-none">'+
((__t=(UTILS.getSVGIcon("oeil_barre", "cw-icon-24", 24)))==null?'':__t)+
'</button>\n            <span class="pwd_new-error-container d-flex"></span>\n        </div>\n    </div>\n    <div class="form-row">\n        <div class="form-group motdepasse_confirm">\n            <label for="pwd_confirm" class="cw-required">'+
((__t=(i18n.t('login:pwdmotpassconf')))==null?'':__t)+
'</label>\n            <input id="pwd_confirm" class="pwd_confirm form-control" type="password" size="35" maxlength="32" autocomplete="confirm-password" aria-required="true" />\n            <button id="wp-togglePassword_confirm" title="'+
((__t=(i18n.t('login:logPasswordShow')))==null?'':__t)+
'" class="iconMotdepasseConfirm">'+
((__t=(UTILS.getSVGIcon("oeil", "cw-icon-24", 24)))==null?'':__t)+
'</button>\n            <button id="wp-togglePasswordBarre_confirm" title="'+
((__t=(i18n.t('login:logPasswordHide')))==null?'':__t)+
'" class="iconMotdepasseBarreConfirm d-none">'+
((__t=(UTILS.getSVGIcon("oeil_barre", "cw-icon-24", 24)))==null?'':__t)+
'</button>\n            <span class="pwd_confirm-error-container d-flex"></span>\n        </div>\n    </div>\n    <div class="form-row">\n        <button class="motpassecontinue save btn btn-withIcon btn-primary">'+
((__t=(i18n.t('login:pwdenregistrer')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('valider')))==null?'':__t)+
'</button>\n        <button class="motpassecancel revert btn btn-withIcon btn-secondary">'+
((__t=(i18n.t('login:pwdannuler')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('croix')))==null?'':__t)+
'</button>\n    </div>\n</fieldset>';
}
return __p;
};
