import { CompletDomColl } from './completDom.collection.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { NiveauTypeHierarchieColl } from './niveauTypeHierarchie.collection.js'
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from 'utils/str.js';
import { LOG } from 'utils/log.js';
import { HABILITATION } from 'src/utils/habilitation.js';

export var CompleteWFL = ReadOnlyModel.extend({
  /**
   * Initializes the workflow model of selecteur activites complete view
   */
  initialize: function(options) {
    if (options && options.parameters) {
      this.parameters = options.parameters;
    }
    this.domColl = new CompletDomColl(this.parameters);

    this.context = null;
    if (options && options.context) {
      this.context = options.context;
      var foncCour = this._obtainFoncCour(true);
      this.habContext = new HabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: foncCour
      });
      this.domColl.setHabContext(this.getHabContext());
    }
    this.filterContext = null;
    // Evo#642
    this.niveauxTypeHierarchy = new NiveauTypeHierarchieColl();
    // Evo#642 - End

  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {
    var self = this;
    // Load data
    this.domColl.clearFilter();
    this.domColl.fetch({
      success: function() {
        if (!STR.isBlank(self.domColl.models[0]) && !STR.isBlank(self.domColl.models[0].get("hiertype"))) {
          self._loadHierarchyType(self.domColl.models[0].get("hiertype").code);
        }
        if (callback) {
          callback();
        }
      }
    });
  },

  _loadHierarchyType: function(code) {
    var self = this;
    var hc = this.getHabContext().copy();

    if (self.niveauxTypeHierarchy !== undefined || self.niveauxTypeHierarchy !== null) {
      this.niveauxTypeHierarchy.reset(null);
    }

    hc.update({ foncCour: "PAR_ACTHIE.V", natGest: "" });
    this.niveauxTypeHierarchy.setHabContext(hc);

    this.niveauxTypeHierarchy.code = code;
    this.niveauxTypeHierarchy.params = { horsregr: true }

    if (HABILITATION.canView("PAR_ACTHIE.V")) {
      this.niveauxTypeHierarchy.fetch({
        success: function(niveauxTypeHierarchy) {
          LOG.debug(niveauxTypeHierarchy)
          self.trigger("updateFilterFields");
        }
      });
    }
  },

  reinitialize: function(options) {
    if (options && options.parameters) {
      this.parameters = options.parameters;
    }
    this.domColl = new CompletDomColl(this.parameters);
    if (options && options.context) {
      this.context = options.context;
      var foncCour = this._obtainFoncCour();
      this.habContext = new HabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: foncCour
      });
      this.domColl.setHabContext(this.getHabContext().copy());
    }
  },

  /**
   * Sets the context for selecteurActivite complete model
   */
  setContext: function(context, callback) {
    var foncCour = "";

    this.initializeParams(context);
    this.domColl.setCollParameters(this.parameters);
    foncCour = this._obtainFoncCour(true);
    this.habContext = new HabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCour
    });
    this.domColl.setHabContext(this.getHabContext().copy());
    this.setUp(callback);
  },

  /**
   * obtain fonccour from context for habilitations
   */
  _obtainFoncCour: function(consultation) {
    var foncCour = {};

    if (consultation !== true && this.context && this.context.ctxHabilitation && !_.isEmpty(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxEcran !== "suivicollab") {
      switch (this.context.ctxTypeEvtGere) {
        case "P":
          if (!STR.isBlank(this.context.ctxHabilitation.HabilitationGestion.prevue)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion.prevue;
          } else if (!STR.isBlank(this.context.ctxHabilitation.HabilitationGestion)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion;
          }
          break;
        case "R":
          if (!STR.isBlank(this.context.ctxHabilitation.HabilitationGestion.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion.realisee;
          } else if (!STR.isBlank(this.context.ctxHabilitation.HabilitationGestion)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion;
          }
          break;
        default:
          if (this.context.ctxHabilitation.HabilitationGestion.prevue) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion.prevue;
          }
          if (this.context.ctxHabilitation.HabilitationGestion.realisee) {
            if (_.isEmpty(foncCour)) {
              foncCour = this.context.ctxHabilitation.HabilitationGestion.realisee;
            } else {
              foncCour.actprev = this.context.ctxHabilitation.HabilitationGestion.prevue;
              foncCour.actreal = this.context.ctxHabilitation.HabilitationGestion.realisee;
            }
          }
          if (!_.isEmpty(this.context.ctxHabilitation.HabilitationGestion) && STR.isBlank(this.context.ctxHabilitation.HabilitationGestion.prevue) && STR.isBlank(this.context.ctxHabilitation.HabilitationGestion.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion;
          }
      }
    } else if (this.context && this.context.ctxHabilitation && !_.isEmpty(this.context.ctxHabilitation.HabilitationConsultation)) {
      switch (this.context.ctxTypeEvtGere) {
        case "P":
          if (!STR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.prevue)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation.prevue;
          } else if (!STR.isBlank(this.context.ctxHabilitation.HabilitationConsultation)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation;
          }
          break;
        case "R":
          if (!STR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation.realisee;
          } else if (!STR.isBlank(this.context.ctxHabilitation.HabilitationConsultation)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation;
          }
          break;
        default:
          if (this.context.ctxHabilitation.HabilitationConsultation.prevue) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation.prevue;
          }
          if (this.context.ctxHabilitation.HabilitationConsultation.realisee) {
            if (_.isEmpty(foncCour)) {
              foncCour = this.context.ctxHabilitation.HabilitationConsultation.realisee;
            } else {
              foncCour.actprev = this.context.ctxHabilitation.HabilitationConsultation.prevue;
              foncCour.actreal = this.context.ctxHabilitation.HabilitationConsultation.realisee;
            }
          }
          if (!_.isEmpty(this.context.ctxHabilitation.HabilitationConsultation) && STR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.prevue) && STR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation;
          }
      }
    }
    return foncCour;
  },

  /**
   *
   */
  initializeParams: function() {
    //Load parameters
    this.parameters = {
      typevt: this.context.ctxTypeEvtGere,
      typo: this.context.ctxTypologieActivite
    };

    /**
     * Context Utilisation
     */
    if (this.context && !STR.isBlank(this.context.ctxUtilisation)) {
      this.parameters.ctxutil = this.context.ctxUtilisation;
    }
    /**
     * Date deb
     */
    if (this.context.ctxPeriodeGestion && !STR.isBlank(this.context.ctxPeriodeGestion.datedeb)) {
      this.parameters.datedeb = this.context.ctxPeriodeGestion.datedeb;
    }
    /**
     * Date fin
     */
    if (this.context.ctxPeriodeGestion && !STR.isBlank(this.context.ctxPeriodeGestion.datefin)) {
      this.parameters.datefin = this.context.ctxPeriodeGestion.datefin;
    }
    /**
     * Domaines
     */
    if (!STR.isBlank(this.context.ctxDomainesActivites)) {
      this.parameters.dom = this.context.ctxDomainesActivites;
    }
    /**
     * ctxCollab
     */
    if (!STR.isBlank(this.context.ctxCollab)) {
      this.parameters.matric = this.context.ctxCollab;
    }
    if (this.context.ctxPourPlanningMedical === true) {
      this.parameters.medical = true;
    }
  }
});
