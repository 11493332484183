import { BaseModel } from 'core/models/base.model.js';
import { ButtonBarView } from 'core/controls/buttonBar.view.js';
import { GLOBAL_DATA } from 'src/globalData';
import { GroupeGridModel } from 'core/grids/groupegrid/groupeGrid.model.js';
import { GroupeGridView } from 'core/grids/groupegrid/groupeGrid.view.js';
import { i18n } from 'src/i18n.js';
import { CWMSGS } from 'utils/cwMsgs';
import { STR } from 'utils/str.js';
import { TYPE } from 'tda/tda.js';
import TPL_resultcontroles_detail from '../detail.tpl.html';

/**
 * PopUp View
 */
export var ResultControlesPopUpView = Backbone.View.extend({

  initialize: function(options) {
    options = options || {};
    this.context = options.context;
    this.$appendTo = (this.context && !STR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    this.workflow = options.workflow;
    this.template = TPL_resultcontroles_detail;
    this.saisieTable = this._initSaisieTable();
    this.recapTable = this._initRecapTable();
    this.btnBar = new ButtonBarView({ id: "resultcontroles_btnbar" });
    if (options.originalModel) {
      this.originalModel = options.originalModel;
    }
    if (options.actionModel) {
      this.actionModel = options.actionModel;
    }
    if (options.parent) {
      this.parent = options.parent;
    }
  },

  events: {
    "click button": "_buttonAction"
  },

  _initSaisieTable: function() {
    if (STR.isBlank(this.workflow.saisieColl) || this.workflow.saisieColl.length === 0) {
      return null;
    }

    var self = this;

    var tableModel = new GroupeGridModel({
      coll: this.workflow.saisieColl
    });
    var tblColumns = [
      { title: "", property: "bloquant", width: 30, groupe: true },
      { title: i18n.t('common:resultcontroles.table_periode'), property: "datedeb", width: 190 },
      { title: i18n.t('common:resultcontroles.table_message'), property: "message", width: 440 },
      { title: i18n.t('common:resultcontroles.table_controle'), property: "code", width: 200 }
    ];

    var table = new GroupeGridView({
      model: tableModel,
      title: i18n.t('common:resultcontroles.grid_saisie_title', { "0": (self.workflow.saisieNum.bloquants + self.workflow.saisieNum.nonbloquants) }),
      columns: tblColumns,
      gridHeightType: "nolimite"

    });

    table.cellRenderers["datedeb"] = function(model) {
      if (String(model.get("datedeb")) === String(model.get("datefin"))) {
        return i18n.t('common:resultcontroles.date_text', { "0": TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE")) });
      } else {
        return i18n.t('common:resultcontroles.periode_text', { "0": TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE")), "1": TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE")) });
      }
    };
    table.cellRenderers["bloquant"] = function(model) {
      var span = null;

      if (model.get("bloquant")) {
        span = $("<span>").addClass("ui-phx-ihm-texte-erreur-important");
        span.html(i18n.t('common:resultcontroles.bloquant_title', { "0": self.workflow.saisieNum.bloquants }));
        return span;
      } else {
        span = $("<span>").addClass("ui-phx-ihm-texte-application-important");
        span.html(i18n.t('common:resultcontroles.nonbloquant_title', { "0": self.workflow.saisieNum.nonbloquants }));
        return span;
      }
    };
    table.cellRenderers["message"] = function(model) {
      if (model.get("bloquant")) {
        var span = $("<span>").addClass("ui-phx-ihm-texte-erreur");
        span.html(model.get("message"));
        return span;
      } else {
        return model.get("message");
      }
    };
    table.setOmittedColumns(["bloquant"]);
    table.setVisibleColumns(["bloquant", "datedeb", "message"]);
    table.setSortableColumns([]);

    return table;
  },

  _initRecapTable: function() {
    if (STR.isBlank(this.workflow.recapColl) || this.workflow.recapColl.length === 0) {
      return null;
    }

    var self = this;

    var tableModel = new GroupeGridModel({
      coll: this.workflow.recapColl
    });
    var tblColumns = [
      { title: "", property: "bloquant", width: 30, groupe: true },
      { title: i18n.t('common:resultcontroles.table_periode'), property: "datedeb", width: 190 },
      { title: i18n.t('common:resultcontroles.table_message'), property: "message", width: 440 },
      { title: i18n.t('common:resultcontroles.table_controle'), property: "code", width: 200 }
    ];

    var table = new GroupeGridView({
      model: tableModel,
      title: i18n.t('common:resultcontroles.grid_recap_title', { "0": (self.workflow.recapNum.bloquants + self.workflow.recapNum.nonbloquants) }),
      columns: tblColumns,
      gridHeightType: "nolimite"
    });

    table.cellRenderers["datedeb"] = function(model) {
      if (String(model.get("datedeb")) === String(model.get("datefin"))) {
        return i18n.t('common:resultcontroles.date_text', { "0": TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE")) });
      } else {
        return i18n.t('common:resultcontroles.periode_text', { "0": TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE")), "1": TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE")) });
      }
    };
    table.cellRenderers["bloquant"] = function(model) {
      var span = null;

      if (model.get("bloquant")) {
        span = $("<span>").addClass("ui-phx-ihm-texte-erreur-important");
        span.html(i18n.t('common:resultcontroles.bloquant_title', { "0": self.workflow.recapNum.bloquants }));
        return span;
      } else {
        span = $("<span>").addClass("ui-phx-ihm-texte-application-important");
        span.html(i18n.t('common:resultcontroles.nonbloquant_title', { "0": self.workflow.recapNum.nonbloquants }));
        return span;
      }
    };
    table.cellRenderers["message"] = function(model) {
      if (model.get("bloquant")) {
        var span = $("<span>").addClass("ui-phx-ihm-texte-erreur");
        span.html(model.get("message"));
        return span;
      } else {
        return model.get("message");
      }
    };
    table.setOmittedColumns(["bloquant"]);
    table.setVisibleColumns(["bloquant", "datedeb", "message"]);
    table.setSortableColumns(["datedeb", "code"]);

    return table;
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.append(this.template(json));

    //Render Button Bar
    this.$el.find(".phx-resultcontroles-buttons").html(this.btnBar.render().el);

    if (this.workflow.context.ctxUtilisation === "Information" ||
      this.workflow.blocked || STR.isBlank(this.workflow.action)) {
      this.btnBar.addButton("fermer", i18n.t('common:resultcontroles.fermer'));
    } else {
      this.btnBar.addButton("confirmer", i18n.t('common:resultcontroles.confirmer'));
      this.$el.find(".phx-resultcontroles-buttons button.confirmer").css("margin-right", "10px");
      this.btnBar.addButton("annuler", i18n.t('common:resultcontroles.annuler'));
    }
    this.btnBar.model.trigger("hide:save");
    this.btnBar.model.trigger("hide:revert");
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("hide:copy");
    this.btnBar.model.trigger("hide:delete");

    // Render tables
    if (!STR.isBlank(this.saisieTable)) {
      $(this.el).find(".phx-resultcontroles-tablesaisie").html(this.saisieTable.el);
      this.saisieTable.render();
    }
    if (!STR.isBlank(this.recapTable)) {
      $(this.el).find(".phx-resultcontroles-tablerecap").html(this.recapTable.el);
      this.recapTable.render();
      $(this.el).find(".phx-resultcontroles-tablerecap").append("<br>");
    }

    //Header
    this._setHeader();

    return this;
  },

  _setHeader: function() {
    var periotext = null;

    if (this.workflow.context.ctxUtilisateur === "Responsable" && !STR.isBlank(this.workflow.context.ctxGestionCollab)) {
      var collabText = this.workflow.collab.nom + " " + this.workflow.collab.prenom + " (";

      if (GLOBAL_DATA.paramDivers.get("idCollab").get("valeur") === "matric_aux") {
        collabText += this.workflow.collab.matricaux + ")";
      } else {
        collabText += this.workflow.collab.code + ")";
      }
      $(this.el).find(".phx-resultcontroles-header .collab").html(collabText);
    } else {
      $(this.el).find(".phx-resultcontroles-header .collab").hide();
    }
    if (this.workflow.blocked) {
      $(this.el).find(".phx-resultcontroles-header .etat").html(i18n.t('common:resultcontroles.etat_controle'));
    }

    if (STR.isBlank(this.workflow.context.ctxIdentifiantRecap)) {
      if (this.workflow.context.ctxPeriodeControles.datedeb === this.workflow.context.ctxPeriodeControles.datefin) {
        periotext = i18n.t('common:resultcontroles.date') + " " + i18n.t('common:resultcontroles.date_text', { "0": TYPE.DATE.format(this.workflow.context.ctxPeriodeControles.datedeb, TYPE._getFormatByCode("DATE")) }).toLowerCase();
      } else {
        periotext = i18n.t('common:resultcontroles.periode') + " " + i18n.t('common:resultcontroles.periode_text', { "0": TYPE.DATE.format(this.workflow.context.ctxPeriodeControles.datedeb, TYPE._getFormatByCode("DATE")), "1": TYPE.DATE.format(this.workflow.context.ctxPeriodeControles.datefin, TYPE._getFormatByCode("DATE")) }).toLowerCase();
      }
    } else {
      var tempText = null;

      periotext = i18n.t('common:resultcontroles.recap') + " <span class='bold'>" + this.workflow.recap.libelle + "</span> ";
      if (this.workflow.context.ctxPeriodeControles.datedeb === this.workflow.context.ctxPeriodeControles.datefin) {
        tempText = i18n.t('common:resultcontroles.date_text', { "0": TYPE.DATE.format(this.workflow.context.ctxPeriodeControles.datedeb, TYPE._getFormatByCode("DATE")) });
        periotext += tempText.toLowerCase();
      } else {
        tempText = i18n.t('common:resultcontroles.periode_text', { "0": TYPE.DATE.format(this.workflow.context.ctxPeriodeControles.datedeb, TYPE._getFormatByCode("DATE")), "1": TYPE.DATE.format(this.workflow.context.ctxPeriodeControles.datefin, TYPE._getFormatByCode("DATE")) });
        periotext += tempText.toLowerCase();
      }
    }
    $(this.el).find(".phx-resultcontroles-header .periode").html(periotext);
  },

  _buttonAction: function(event) {
    var btnClicked = event.currentTarget.value;
    this.action = btnClicked;
    switch (btnClicked) {
      case "fermer":
      case "annuler":
        this.parent.close();
        break;
      case "confirmer":
        var self = this;
        var model = new BaseModel(this.originalModel.attributes);
        //				model = this.originalModel;
        model.url = Configuration.restRoot + "/rest/" + this.workflow.action;
        model.id = "_";
        model.setHabContext(this.workflow.model.getHabContext());
        if (this.actionModel === "save") {
          model.save(null, {
            success: function() {
              self.parent.actionOk = true;
              self.parent.close();
            },
            error: function(model, xhf) {
              CWMSGS.showNotification(xhf.responseJSON, null, null, this.$appendTo);
            }
          });
        } else if (this.actionModel === "destroy") {
          model.destroy({
            success: function() {
              self.parent.actionOk = true;
              self.parent.close();
            },
            error: function(model, xhf) {
              CWMSGS.showNotification(xhf.responseJSON, null, null, this.$appendTo);
            }
          });
        } else if (this.actionModel === "fetch") {
          model.fetch({
            success: function() {
              self.parent.actionOk = true;
              self.parent.close();
            },
            error: function(model, xhf) {
              CWMSGS.showNotification(xhf.responseJSON, null, null, this.$appendTo);
            }
          });
        }

        break;
    }
  }

});
