import { CWDataGridModel } from 'src/core/grids/data_grid/cwDataGrid.model';

/**
 * Model for Composant 'Soldes d’absences en D/P/S'
 */

export class CWSoldesAbsencesModel extends CWDataGridModel {

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": "",
      "precedent": {
        "solde": null,
        "pris": null,
        "droit": null
      },
      "courant": {
        "solde": null,
        "pris": null,
        "droit": null
      },
      "suivant": {
        "solde": null,
        "pris": null,
        "droit": null
      }
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
  }
}