import { CWProfilesColl } from './cwProfiles.collection';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';

/**
 * Profile model
 */
export class CWProfileModel extends CWReadOnlyModel {

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.id === undefined) {
      if (response.code !== undefined) {
        this.id = response.code;
      }

      if (response.codedele !== undefined) {
        this.id = response.codedele;
      }

    }

    response.enfants = new CWProfilesColl(response.enfants, { parse: true });

    return response;
  }

  profilesNumber(): number {
    let result = 0;
    if (CWSTR.isBlank(this.get("codedele"))) {
      result = 1;
    }
    result += this.get("enfants").profilesNumber();
    return result;
  }

  getProfile(code: string): string {
    const element = this.get("enfants").getProfile(code);
    return element;
  }
}