import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Model pour obtenir la valeur de Type de Gestion du collaborateur
 */
export class CWTypeGestionModel extends CWBaseModel {

  collab: any;
  date: any;

  defaults(): { [key: string]: any } {
    return {
      "typegest": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(options);
    if (options && options.collab) {
      this.collab = options.collab;
    }
    if (options && options.date) {
      this.date = options.date;
    }
    this.url = (): string => {
      const collab = !CWSTR.isBlank(this.collab) ? "/" + encodeURIComponent(this.collab) : "";
      const date = !CWSTR.isBlank(this.date) ? "/" + encodeURIComponent(this.date) : "";

      return Configuration.restRoot + "/rest/ficheperso" + collab + "/typegest" + date;
    }
  }
}
