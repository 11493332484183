import _ from 'underscore';
import { CWEtatsModel } from './cwEtats.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';

/**
 * Collection of etats for the left table.
 */
export class CWEtatsColl<TModel extends CWEtatsModel = CWEtatsModel> extends CWPaginatedCollection<TModel> {

  numGroupes: number;

  constructor(models?: CWEtatsModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWEtatsModel;
    super(models, options);
    this.version = "3.1.0";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/edition/groupe";
    };
    this.sortings = { ordre: true };
    this.paginated = false;
    this.numGroupes = 0;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      _.each(response, (respTheme: { [key: string]: any }): void => {
        _.each(respTheme.definitions, (definition: { [key: string]: any }): void => {
          definition.thcollab = respTheme.thcollab;
        });
      });
    }
    return response;
  }
}