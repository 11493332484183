import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from '../models/cwBase.model';
import { CWButtonBarView } from './cwButtonBar.view';
import { i18n } from 'src/i18n.js';



/**
* Button bar view.
* It's model supports:
* @event change:mode
* @event btn:click (buttonId)
* @event change:enabled (buttonId)
* @event hide Detach the button bar from the Dom
* @event show Re-atach th button bar to the Dom
* @event hide:(buttonId)
* @event show:(buttonId)
* @event enable:(buttonId)
* @event disable:(buttonId)
*/


interface CWButtonBarViewInterface extends Backbone.ViewOptions<CWBaseModel> {
  iconsOnly?: boolean;
  ignoreHabilitationsNature?: boolean;
  focusToFormSaveElement?: JQuery;
  btnClasses?: { [key: string]: string };
  btnIcons?: { [key: string]: string };
  isDisplayOnRight?: boolean;
  isAlignToRight?: boolean;
  isHideOnDisabled?: boolean;
  detached?: boolean;
}


export class CWButtonBarValidationView extends CWButtonBarView {

  /**
   * Constructor
  */
  constructor(options?: CWButtonBarViewInterface) {
    super(options);
    this.model.on('hide:valider', this.hideValidationBtn);
    this.model.on('show:valider', this.showValidationBtn);
  }

  render(): CWButtonBarValidationView {
    super.render();

    this.btnClasses['accepter'] = 'btn-primary-nominal';
    this.btnClasses['refuser'] = 'btn-primary-alert';
    this.addButton("accepter", i18n.t('common:btnBarValidation.accepter'), 'valider');
    this.addButton("refuser", i18n.t('common:btnBarValidation.refuser'), 'croix');

    return this;
  }

  configureValidationsButtonFromModel(model: CWBaseModel): void {
    const profils = model.get("profils");

    if (profils && profils.length === 1 && profils[0]) {
      this.trigger('show:accepter');
      this.trigger('show:refuser');
      $(this.el).find('button.accepter').attr('value', profils[0].code);
      $(this.el).find('button.refuser').attr('value', profils[0].code);
    } else if (profils && profils.length > 1) {

      const menuProfils: Array<{ name: string; label: string }> = [];
      _.each(profils, (obj: { [key: string]: any }): void => {
        menuProfils.push({
          name: obj.code,
          label: obj.libelle
        });
      });

      this.addMenuToButton('accepter', menuProfils, true);
      this.addMenuToButton('refuser', menuProfils, true);
      this.showValidationBtn();
    } else if (!profils || profils.length === 0) {
      this.hideValidationBtn();
    }
  }

  hideValidationBtn(): void {
    this.trigger('hide:accepter');
    this.trigger('hide:refuser');
  }

  showValidationBtn(): void {
    this.trigger('show:accepter');
    this.trigger('show:refuser');
  }

  public hideAllBtnBar(): void {
    super.hideAllBtnBar();
    this.model.trigger("hide:accepter");
    this.model.trigger("hide:refuser");
  }

}
