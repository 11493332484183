import _ from 'underscore';
import { View } from 'Backbone';

/**
 * Intern Object that represents a table column.
 */
export class CWInternColumnModel {


  header: View;
  /**
   * The view of the aux Header fake cell.
   */
  headerFake: View;
  /**
   * View of the current editable cell.
   */
  editableCell: any;
  /**
   * Array of the cells related to the column.
   */
  cells: { [key: string]: any };
  /**
   * If the column is currently visible or not.
   */
  visible: boolean;
  /**
   * If the column is showed on the menu Chooser.
   */
  omitMenu: boolean;
  /**
   * If the column radio in menu is marked or not.
   */
  radioOff: boolean;

  /**
   * If the column is selectable to sortingby it.
   */
  sortable: boolean;
  /**
   * If the grid is currently being sorting by this column.
   */
  sort: { [key: string]: any };
  /**
   * With of the column
   */
  width: number;

  constructor() {
    /**
     * The view of the Header cell.
     */
    this.header = null;
    /**
     * The view of the aux Header fake cell.
     */
    this.headerFake = null;
    /**
     * View of the current editable cell.
     */
    this.editableCell = null;
    /**
     * Array of the cells related to the column.
     */
    this.cells = [];
    /**
     * If the column is currently visible or not.
     */
    this.visible = true;
    /**
     * If the column is showed on the menu Chooser.
     */
    this.omitMenu = false;
    /**
     * If the column radio in menu is marked or not.
     */
    this.radioOff = false;

    /**
     * If the column is selectable to sortingby it.
     */
    this.sortable = true;
    /**
     * If the grid is currently being sorting by this column.
     */
    this.sort = null;
    /**
     * With of the column
     */
    this.width = 100;
  }

  /**
   * Hide the Column.
   */
  hide(): void {
    $(this.header.el).hide();
    $(this.headerFake.el).hide();
    $(this.editableCell).hide();
    _.each(this.cells, function (cell: { [key: string]: any }) {
      $(cell.el).hide();
    });
  }

  /**
   * Hide the Column with Chrome.
   */
  hide_in_chrome(): void { //eslint-disable-line
    $(this.header.el).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    $(this.headerFake.el).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    $(this.editableCell).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    _.each(this.cells, function (cell: { [key: string]: any }) {
      $(cell.el).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    });
    window.setTimeout(() => {
      $(this.header.el).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      $(this.headerFake.el).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      $(this.editableCell).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      _.each(this.cells, function (cell: { [key: string]: any }) {
        $(cell.el).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      });
    }, 10);
  }

  hideHeader(): void {
    $(this.header.el).hide();
    $(this.headerFake.el).hide();
  }

  /**
   * Reset the Column width to its original value.
   */
  setOriginalWidth(): void {
    // Set column width to auto
    $(this.header.el).css("width", this.width + "px");
    $(this.header.el).find(".ui-resizable").css("width", "100%");
    $(this.headerFake.el).css("width", this.width + "px");
  }

  /**
   * Set column width to auto
   */
  setWidthAuto(): void {
    $(this.header.el).css("width", "auto");
    $(this.header.el).find(".ui-resizable").css("width", "100%");
    $(this.headerFake.el).css("width", "auto");
  }

  /**
   * Show the Column
   */
  show(): void {
    $(this.header.el).show();
    $(this.headerFake.el).show();
    $(this.editableCell).show();
    _.each(this.cells, function (cell: { [key: string]: any }) {
      $(cell.el).show();
    });
  }

  /**
   * Show the Column with Chrome.
   */
  show_in_chrome(): void { //eslint-disable-line
    $(this.header.el).css({ 'backface-visibility': '', 'display': 'none' });
    $(this.headerFake.el).css({ 'backface-visibility': '', 'display': 'none' });
    $(this.editableCell).css({ 'backface-visibility': '', 'display': 'none' });
    _.each(this.cells, function (cell: { [key: string]: any }) {
      $(cell.el).css({ 'backface-visibility': '', 'display': 'none' });
    });
    window.setTimeout(() => {
      $(this.header.el).css({ 'backface-visibility': 'visible', 'display': '' });
      $(this.headerFake.el).css({ 'backface-visibility': 'visible', 'display': '' });
      $(this.editableCell).css({ 'backface-visibility': 'visible', 'display': '' });
      _.each(this.cells, function (cell: { [key: string]: any }) {
        $(cell.el).css({ 'backface-visibility': 'visible', 'display': '' });
      });
    }, 10);
  }

  /**
   * Show the resizeable div to allow the user resize the column.
   */
  showResizeable(): void {
    // Show resizeable div
    $(this.header.el).find(".ui-resizable-handle").show();
  }

  /**
   * Hide the resizeable div to deny the user to resize the column.
   */
  hideResizeable(): void {
    $(this.header.el).find(".ui-resizable-handle").hide();
  }

  /**
   * Add the ui class that shows the right border to the header.
   */
  showRightMargin(): void {
    $(this.header.el).addClass("ui-grid-th-RightBorder");
  }

  /**
   * Removes the ui class that shows the right border to the header.
   */
  hideRightMargin(): void {
    $(this.header.el).removeClass("ui-grid-th-RightBorder");
  }

  /**
   * Called when backbone removes the view.
   */
  remove(): void {
    if (this.header) {
      this.header.remove();
    }
    if (this.headerFake) {
      this.headerFake.remove();
    }
    delete this.header;
    delete this.headerFake;
    delete this.editableCell;
    const cellsLength = this.cells.length;
    for (let i = 0; i < cellsLength; i++) {
      this.cells[i].remove();
    }
    this.cells.splice(0, cellsLength);
    delete this.cells;
    delete this.visible;
    delete this.sortable;
    delete this.sort;
    delete this.radioOff;
  }
}
