import _ from 'underscore';
import { CWAffectationEnCoursModel } from './cwAffectationEnCours.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';



export class CWAffectationComplemColl extends CWPaginatedCollection<CWAffectationEnCoursModel> {

  constructor(models?: CWAffectationEnCoursModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWAffectationEnCoursModel;
    super(models, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/brique/affectations/complementaires";
    }
    this.paginated = false;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    response.list = response.affectation;
    delete response.affectation;
    return CWPaginatedCollection.prototype.parse.call(this, response);
  }
}