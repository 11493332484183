import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCommonFileTransferItem from './cwFile_transfer_item.tpl.html';
import { CWButtonBarView } from 'core/controls/cwButtonBar.view';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n';

/**
 * Input field component used by the FileTransferView component
 */
export class CWFileTransferItemView extends Backbone.View<Backbone.Model> {

  public tagName: string;
  public className: string;
  private allowRemove: boolean;
  private allowAdd: boolean;
  private extensionsAllowed: string;
  private btnBar: CWButtonBarView;

  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.tagName = options.tagName || "div";
    options.className = options.className || "phx-file-transfer_item";
    options.events = _.extend({
      "click .add": "_add",
      "click .remove": "_remove",
      "change :input": "_inputChanged"
    }, options.events);
    super(options);
    this.template = TPLCommonFileTransferItem;
    this.model = new Backbone.Model();
    this.allowRemove = _.isBoolean(options.allowRemove) ? options.allowRemove : false;
    this.allowAdd = _.isBoolean(options.allowAdd) ? options.allowAdd : false;
    this.extensionsAllowed = options.extensionsAllowed;
    this.btnBar = options.btnBar;
  }

  public render(): CWFileTransferItemView {
    const json = { "i18n": i18n };

    this.$el.html(this.template(json));
    if (this.allowRemove === true) {
      this.$el.find(":input").prepend("<br>");
    }
    this.$el.find(".add").hide();
    this.$el.find(".remove").hide();
    this.btnBar.disableButton("confirmer");
    this.$el.find(".fileTransfer").click((event: JQuery.TriggeredEvent): void => {
      event.preventDefault();
      $('#file').click();
    });
    return this;
  }

  public renderRefresh(): CWFileTransferItemView {
    this.delegateEvents();
    return this;
  }

  public setName(name: string): void {
    this.$el.find(":input").attr("name", name);
  }

  public setFileExtensions(data: string): void {
    this.$el.find(":input").attr("accept", data);
  }

  public hasValue(): boolean {
    try {
      const fileInfo = this.$el.find(":file").val();

      return !CWSTR.isBlank(fileInfo);
    } catch (e) {
      return false;
    }
  }

  public getFileName(): string {
    const fileInfo = this.$el.find(":file").val() as string;

    return fileInfo.replace(/^.*\\/, '');
  }

  public getFileExtension(): string {
    let fileName = this.getFileName();

    if (CWSTR.isBlank(fileName)) {
      fileName = this.$el.find(".fileInfoName").text();
    }
    if (!CWSTR.isBlank(fileName)) {
      const parts = fileName.split(".");

      return parts[parts.length - 1];
    }
    return null;
  }

  private _add(): void {
    this.model.trigger("add");
  }

  private _remove(): void {
    this.model.trigger("remove");
  }

  private _inputChanged(): void {
    const fileInfo = this.getFileName();
    let hasError;

    if (!this.hasValue() || !this.isExtensionAccepted()) {
      const err = i18n.t('common:uploadfile.mimeTypeError', { "0": this.getFileExtension() });

      this.$el.find(".fileTransfer").addClass("ui-state-error");
      this.$el.find(".mimeType-error").empty().append(`<span>${err}</span>`).removeClass("d-none");
      this.btnBar.disableButton("confirmer");
      hasError = true;
    } else {
      this.$el.find(".fileTransfer").removeClass("ui-state-error");
      this.$el.find(".mimeType-error").addClass("d-none");
      this.btnBar.enableButton("confirmer");
      hasError = false;
    }
    if (CWSTR.isBlank(fileInfo)) {
      this.$el.find(".add").hide();
      this.$el.find(".remove").hide();
    } else {
      this.$el.find(".fileTransfer").html('<span class="fileInfoName">' + fileInfo + '</span>');
      if (this.allowAdd === true && !hasError) {
        this.$el.find(".add").show();
      }
      if (this.allowRemove === true && !hasError) {
        this.$el.find(".remove").show();
      }
    }
  }

  public remove(): Backbone.View {
    try {
      Backbone.View.prototype.remove.call(this); // Remove view from DOM
      delete this.$el; // Delete the jQuery wrapped object variable
      delete this.el; // Delete the variable reference to this node
    } catch (e) {
      //nothing
    }
    return null;
  }

  public isExtensionAccepted(): boolean {
    const extensions = this.extensionsAllowed.split(",");

    return extensions.includes("." + this.getFileExtension());
  }
}