module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-referentiel-wrap input-group">\n    <input class="phx-referentiel-input" type="text" placeholder="'+
((__t=(placeholder))==null?'':__t)+
'" />\n    <div class="loupe input-group-append cw-texteTresAllege">\n        <div class="cw-referentiel-button input-group-text cw-triggerClick" role="button" aria-label="'+
((__t=(i18n.t('common:ARIA_label.search')))==null?'':__t)+
'" tabindex=" 0">\n            '+
((__t=(UTILS.getSVGIcon('loupe')))==null?'':__t)+
'\n        </div>\n    </div>\n</div>\n<div class="phx-referentiel-selection"></div>';
}
return __p;
};
