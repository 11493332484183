import _ from 'underscore';
import { CWElementModel } from './cwElement.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';

export class CWElementColl<TModel extends CWElementModel = CWElementModel> extends CWPaginatedCollection<TModel> {

  constructor(models?: CWElementModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWElementModel;
    options.usecase = options.usecase || "droitsdel";
    super(models, options);
    this.paginated = false;
  }

  clone(): any {
    const temp = CWPaginatedCollection.prototype.clone.call(this);

    return temp;
  }
}
