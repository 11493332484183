import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWScheduleColorItemView } from './cwScheduleColorItem.view';
import { CWScheduleTextItemView } from './cwScheduleTextItem.view';
import { CWSTR } from 'utils/cwStr';

export class CWScheduleRowView extends Backbone.View {
    /**
     * Add this to have Outline
     *
     */

    /**
     * HORIZONTAL
     */
    public HORIZONTAL: string; //  "H",
    /**
     * VERTICAL
     */
    public VERTICAL: string; //  "V",
    public plages: any;
    public type: any;
    public direction: any;
    public showTitle: any;
    public exceptional: any;

    /**
     * Constructor
     * Draw the row. If type==Text, we paint only the hours, overwise we paint colored cells
     */
    constructor(options?: { [key: string]: any }) {
        options = options || {};
        options.tagName = options.tagName || "div";
        super(options);
        this.HORIZONTAL = "H";
        this.VERTICAL = "V";
        this.plages = options.plages;
        this.type = options.type;
        this.direction = options.direction || this.HORIZONTAL; //Direction (horizontal or vertical)
        if (_.isBoolean(options.showTitle)) {
            this.showTitle = options.showTitle;
        } else {
            this.showTitle = true;
        }
        if (_.isBoolean(options.exceptional)) {
            this.exceptional = options.exceptional;
        } else {
            this.exceptional = false;
        }
    }

    /**
     * Renders this view
     */
    render(): CWScheduleRowView {
        this.$el.empty();
        if (this.direction === this.VERTICAL) {
            this.$el.css("width", "100%");
            this.$el.css("height", "100%");
        }
        //Show the last debut time in the blank close plage, ignoring the tolfin plage.
        if (!CWSTR.isBlank(this.plages)) {
            const size = this.plages.length;

            if (this.plages[size - 2].type !== "tolfin") {
                this.plages[size - 1].hdebut = this.plages[size - 2].hfin;
            }
        }

        _.each(this.plages, (plage: { [key: string]: any }) => {
            if (this.type === "text") {
                const textItem = new CWScheduleTextItemView({ plage: plage, direction: this.direction });

                this.$el.append(textItem.render().el);
            } else {
                const colorItem = new CWScheduleColorItemView({
                    plage: plage,
                    direction: this.direction,
                    showTitle: this.showTitle,
                    exceptional: this.exceptional
                });

                this.$el.append(colorItem.render().el);
            }
        }, this);
        return this;
    }
}