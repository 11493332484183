import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPersoDeleteCriteriaDialog as DeleteCriteriaAgenda } from './cwPersoDeleteCriteriaAgendaDialog';
import { CWPersoDeleteCriteriaDialog as DeleteCriteriaPlanning } from './cwPersoDeleteCriteriaPlanningDialog';
import { CWPersoSaveCriteriaDialog as SaveCriteriaAgenda } from './cwPersoSaveCriteriaAgendaDialog';
import { CWPersoSaveCriteriaDialog as SaveCriteriaPlanning } from './cwPersoSaveCriteriaPlanningDialog';
import { CWPersoSaveCriteriaDialog as SaveCriteriaTebleauService } from './cwPersoSaveCriteriaTableauServiceDialog';
import { CWPersoSaveCriteriaDialog as SaveCriteriaTebleauCollService } from './cwPersoSaveCriteriaTableauServiceCollDialog';
import { CWPersoSaveCriteriaDialog as SaveCriteriaTableauGardes } from './cwPersoSaveCriteriaTableauGardesDialog';
import { CWPersoSaveCriteriaDialog as SaveCriteriaPlequipe } from './cwPersoSaveCriteriaPlequipeDialog';
import { CWPreferenceGeneralExiste } from '../models/cwPreferenceGeneralExiste.model';
import { CWPreferenceUtilisateurExiste } from '../models/cwPreferenceUtilisateurExiste.model';
import { CWSauveGarderPreferenceModel as SauveGarderPlanrespPreference } from 'common/briques/persobriques/models/cwSauveGarderPlanrespPreference.model';
import { CWSauveGarderPreferenceModel as SauveGarderPlanmedPreference } from 'common/briques/persobriques/models/cwSauveGarderPlanmedPreference.model';
import { CWSauveGarderPreferenceModel as SauveGarderTabServicePreference } from 'common/briques/persobriques/models/cwSauveGarderTabServicePreference.model';
import { CWSauveGarderPreferenceModel as SauveGarderTabServiceCollPreference } from 'common/briques/persobriques/models/cwSauveGarderTabServiceCollPreference.model';
import { CWSauveGarderPreferenceModel as SauveGarderTabGardesPreference } from 'common/briques/persobriques/models/cwSauveGarderTabGardesPreference.model';
import { CWSauveGarderPreferenceModel as SauveGarderPlequipePreference } from 'common/briques/persobriques/models/cwSauveGarderPlequipePreference.model';
import { CWSTR } from 'src/utils';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

//Evo 512
export class CWPersonalisationMenuView extends CWBaseFormView {

  className: string;
  workflow: { [key: string]: any };
  isVisible: boolean;
  RESPONSABLE_TYPE: any;


  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {

    super(options);
    if (options && options.workflow) {
      this.workflow = options.workflow;
    } else {
      this.workflow = {};
    }
    this.workflow.persUtil = false;
    this.workflow.persGeneral = false;
    this.workflow.prefUtilisateurExiste = new CWPreferenceUtilisateurExiste({});
    this.workflow.prefGeneralExiste = new CWPreferenceGeneralExiste({});
  }


  _showDialogDeleteCriteria(uc: string): void {
    let Rt, RRt: any;
    let view: any;

    switch (uc) {
      case "agenda":
      case "agenda_R":
        Rt = objs.agendaRt ? objs.agendaRt : {};
        RRt = objs.agenda_RRt ? objs.agenda_RRt : {};
        _.extend(this.workflow, (uc === 'agenda') ? Rt.workflow : RRt.workflow);
        view = DeleteCriteriaAgenda;
        break;
      case "planresp":
      case "planact":
      case "planmed":
        if (uc === 'planmed') {
          Rt = objs.planmedRt ? objs.planmedRt : {};
        } else {
          Rt = objs.planrespRt ? objs.planrespRt : {};
        }

        _.extend(this.workflow, Rt.workflow);
        Object.setPrototypeOf(this.workflow, Object.getPrototypeOf(Rt.workflow));//pour avoir les méthodes du workflow
        view = DeleteCriteriaPlanning;
        break;
      case "tabservind": {
        _.extend(this.workflow, objs.tabservindRt.workflow);
        view = DeleteCriteriaPlanning;
        break;
      }
      case "tabservcoll": {
        _.extend(this.workflow, objs.tabservcollRt.workflow);
        view = DeleteCriteriaPlanning;
        break;
      }
      case "tabgardes": {
        _.extend(this.workflow, objs.tabgardesRt.workflow);
        view = DeleteCriteriaPlanning;
        break;
      }
      case "plequipe": {
        _.extend(this.workflow, objs.plequipeRt.workflow);
        view = DeleteCriteriaPlanning;
        break;
      }
      default:
      // code block
    }

    const lPosition = { my: "center top", at: "center top+140", of: window };
    const dialog = new CWPanneauDeroulant({
      dialogClass: "c-panneauDeroulant cw-style cwHeader__transversal",
      view: view,
      popupType: 2,
      position: lPosition,
      viewData: {
        workflow: this.workflow,
        modal: true,//éviter l'ouverture et pouvoir changer à autre zone (coll,resp, adm, etc) ou écran
        calledFromPersonnalisation: true,
        uc: uc
      }
    });
    dialog.setHeight("auto");
    dialog.setWidth(500);

    const btn = [{
      text: i18n.t('agenda.dialog_persodeletecriteria_confirm'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        dialog.getInternalViewObject().trigger("btnDelete");
      }
    }, {
      text: i18n.t('agenda.dialog_persodeletecriteria_cancel'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        dialog.getInternalViewObject().trigger("btnCancel");
      }
    }];
    dialog.setButtons(btn);
    dialog.open();

  }

  _showDialogSaveCriteria(checksValues?: string | null, uc?: string): void {
    const habColl = CWHABILITATION.canView("COL_AGHEBDO") || CWHABILITATION.canView("COL_AGMENS") || CWHABILITATION.canView("COL_AGANNU");
    const habResp = CWHABILITATION.canView("RES_AGHEBDO") || CWHABILITATION.canView("RES_AGMENS") || CWHABILITATION.canView("RES_AGANNU");
    const habPer = CWHABILITATION.canView("PER_PERSOGEN.M");
    let Rt, RRt: any;
    let view: any;

    switch (uc) {
      case "agenda":
      case "agenda_R":
        Rt = objs.agendaRt ? objs.agendaRt : {};
        RRt = objs.agenda_RRt ? objs.agenda_RRt : {};
        _.extend(this.workflow, (uc === 'agenda') ? Rt.workflow : RRt.workflow);
        if (uc === 'agenda') {
          this.workflow.preferences.habilitationV = "N";
          this.workflow.preferences.habilitationG = "N";
        }
        view = SaveCriteriaAgenda;
        break;
      case "planresp":
      case "planact":
      case "planmed":
        if (uc === 'planmed') {
          Rt = objs.planmedRt ? objs.planmedRt : {};
        } else {
          Rt = objs.planrespRt ? objs.planrespRt : {};
        }

        _.extend(this.workflow, Rt.workflow);
        Object.setPrototypeOf(this.workflow, Object.getPrototypeOf(Rt.workflow));//pour avoir les méthodes du workflow
        view = SaveCriteriaPlanning;
        break;
      case "tabservind":
        _.extend(this.workflow, objs.tabservindRt.workflow);
        view = SaveCriteriaTebleauService;
        break;
      case "tabservcoll":
        _.extend(this.workflow, objs.tabservcollRt.workflow);
        view = SaveCriteriaTebleauCollService;
        break;
      case "tabgardes":
        _.extend(this.workflow, objs.tabgardesRt.workflow);
        view = SaveCriteriaTableauGardes;
        break;
      case "plequipe":
        _.extend(this.workflow, objs.plequipeRt.workflow);
        view = SaveCriteriaPlequipe;
        break;
      default:
      // code block
    }

    if ((uc === "agenda" || uc === "agenda_R") && !(habColl && habResp) && !habPer) {
      this._autoSaveAgendaCriteria(uc);
    } else {
      //Select dialog
      const lPosition = { my: "center top", at: "center top+140", of: window };
      const dialog = new CWPanneauDeroulant({
        dialogClass: "c-panneauDeroulant cw-style cwHeader__transversal",
        view: view,
        popupType: 2,
        position: lPosition,
        viewData: {
          workflow: this.workflow,
          modal: true,//éviter l'ouverture et pouvoir changer à autre zone (coll,resp, adm, etc) ou écran
          calledFromPersonnalisation: true,
          checksValues: checksValues,
          uc: uc
        }
      });
      dialog.setWidth(500);
      dialog.setHeight("auto");

      const btn = [{
        text: i18n.t('agenda.dialog_persosavecriteria_save'),
        btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
        icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
        click: (): void => {
          dialog.getInternalViewObject().trigger("_saveCriteria");
        }
      }, {
        text: i18n.t('agenda.dialog_persosavecriteria_cancel'),
        btnClass: 'btn btn-secondary btn-withIcon',
        icon: UTILS.getSVGIcon("croix", "", 16, null),
        click: (): void => {
          dialog.getInternalViewObject().trigger("_cancel");
        }
      }];
      dialog.setButtons(btn);
      if (this._checkPreferencesLoad(uc)) {
        dialog.open();
      }
    }
  }
  /**
   * Cheks preferences needed in personalisation popup screens 
   * @param uc 
   * @returns 
   */
  _checkPreferencesLoad(uc: string): boolean {
    /* list of screen we need to check values of perscrig */
    const listScreens = ["agenda", "agenda_R", "planresp", "planact", "planmed", "tabservind"];

    if (listScreens.indexOf(uc) === -1 ||
      (listScreens.indexOf(uc) !== -1 && this.workflow.preferences && !CWSTR.isBlank(this.workflow.preferences.get("perscrig")))) {
      return true;
    }

    return false;
  }

  _autoSaveCriteria(uc: string): void {
    let persoPlanning: any;
    let habContext: any;
    let Rt = null;

    if (uc === 'planmed') {
      persoPlanning = new SauveGarderPlanmedPreference();
      Rt = objs.planmedRt ? objs.planmedRt : {};
      habContext = {
        onglet: "planmed",
        foncCour: "RES_PLMED",
        natGest: ""
      }
    } else if (_.isEqual(uc, 'tabservind')) {
      persoPlanning = new SauveGarderTabServicePreference();
      Rt = objs.tabservindRt ? objs.tabservindRt : {};
      habContext = {
        onglet: "tabservind",
        foncCour: "COL_TSIND",
        natGest: ""
      }
    } else if (_.isEqual(uc, 'tabservcoll')) {
      persoPlanning = new SauveGarderTabServiceCollPreference();
      Rt = objs.tabservcollRt ? objs.tabservcollRt : {};
      habContext = {
        onglet: "tabservcoll",
        foncCour: "COL_TSCOL",
        natGest: ""
      }
    } else if (_.isEqual(uc, 'tabgardes')) {
      persoPlanning = new SauveGarderTabGardesPreference();
      Rt = objs.tabgardesRt ? objs.tabgardesRt : {};
      habContext = {
        onglet: "tabgardes",
        foncCour: "COL_TGARDE",
        natGest: "A"
      }
    } else if (_.isEqual(uc, 'plequipe')) {
      persoPlanning = new SauveGarderPlequipePreference();
      Rt = objs.plequipeRt ? objs.plequipeRt : {};
      habContext = {
        onglet: "plequipe",
        foncCour: "COL_PLEQUIPE",
        natGest: "A"
      }
    } else {
      persoPlanning = new SauveGarderPlanrespPreference();
      Rt = objs.planrespRt ? objs.planrespRt : {};
      habContext = {
        onglet: "planresp",
        foncCour: "RES_GCOLLAB.G",
        natGest: ""
      }
    }
    _.extend(this.workflow, Rt.workflow);
    persoPlanning.set("preferences", this.workflow.preferences.get("preferences").clone());

    this.workflow.preferences.set("perscriu", 1);
    this.workflow.preferences.set("perscrig", 0);

    persoPlanning.set("perscriu", this.workflow.preferences.get("perscriu"));
    persoPlanning.set("perscrig", this.workflow.preferences.get("perscrig"));

    persoPlanning.setHabContext(new CWHabilitationContext(habContext));

    this._cleanValidationErrors();

    if (_.isEqual(uc, "tabservind")) {
      persoPlanning.url = Configuration.restRoot + "/rest/planning/tabservice/preference";
    } else if (_.isEqual(uc, "tabservcoll")) {
      persoPlanning.url = Configuration.restRoot + "/rest/planning/tabservicecoll/preference";
    } else if (_.isEqual(uc, "tabgardes")) {
      persoPlanning.url = Configuration.restRoot + "/rest/preferences/generique?themes=TG_COLL&ctxAcces=C";
    } else if (_.isEqual(uc, "plequipe")) {
      persoPlanning.url = Configuration.restRoot + "/rest/planning/monequipe/preference";
    } else {
      persoPlanning.url = Configuration.restRoot + "/rest/planning/preference";
    }
    persoPlanning.save(null, {
      success: (fresh: any) => {
        this.workflow.preferences.set("perscriu", fresh.get("perscriu"));
        this.workflow.preferences.set("perscrig", fresh.get("perscrig"));
        this.workflow.prefUtilisateurExiste.notFecth = false;
      }
    });

  }

  _autoSaveAgendaCriteria(uc: string): void {
    const Rt = objs.agendaRt ? objs.agendaRt : {};
    const RRt = objs.agenda_RRt ? objs.agenda_RRt : {};
    _.extend(this.workflow, (uc === 'agenda') ? Rt.workflow : RRt.workflow);
    const habColl = CWHABILITATION.canView("COL_AGHEBDO") || CWHABILITATION.canView("COL_AGMENS") || CWHABILITATION.canView("COL_AGANNU");
    const habResp = CWHABILITATION.canView("RES_AGHEBDO") || CWHABILITATION.canView("RES_AGMENS") || CWHABILITATION.canView("RES_AGANNU");

    this.workflow.preferences.action = true;
    this.workflow.preferences.set("perscriu", 1);
    this.workflow.preferences.set("perscrig", 0);

    if (this.workflow.get("utilisateur") === this.RESPONSABLE_TYPE || this.workflow.module === "agenda_R") {
      this.workflow.preferences.contextUser = 0;
      if (habResp) {
        this.workflow.preferences.contextGlobal = 1;
      } else {
        this.workflow.preferences.contextGlobal = 0;
      }
    } else {
      if (habColl) {
        this.workflow.preferences.contextUser = 1;
      } else {
        this.workflow.preferences.contextUser = 0;
      }
      this.workflow.preferences.contextGlobal = 0;
    }
    this.workflow.preferences.save(null, {});
  }

}
