import _ from 'underscore';
import TPLGereronebadgeageDetail from '../cwDetail.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWButtonBarValidationView } from 'src/core/controls/cwButtonBarValidation.view';
import { CWComboBoxView2 } from 'src/core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWEtiquette, validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'src/utils/cwForms';
import { CWGererBadgeagesGestion } from 'src/uc/common/evenements/gerer/gererbadgeages/models/cwGererBadgeagesGestion';
import { CWGererOneBadgeagePopUpView } from './cwGererOneBadgeagePopUp.view';
import { CWGererOneBadgeageProfilsModel } from '../models/cwGererOneBadgeageProfils.model';
import { CWGereronebadgeageWorkflow } from '../models/cwGereronebadgeage.workflow';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWListeBadgeageModel } from '../models/cwListeBadgeage.model';
import { CWPanneauDeroulant } from 'src/core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { CWWkflhistoView } from 'src/uc/common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';
import { UTILS } from 'src/utils/utils.js';


interface Renders {
  [index: string]: () => string;
}

export class CWGererOneBadgeageView extends CWBaseFormView {
  btnBar: CWButtonBarValidationView;
  combos: any;
  comboMotif: CWComboBoxView2;
  comboSens: CWComboBoxView2;
  composantWorkflow: CWWkflhistoView;
  context: { [key: string]: any };
  divers: string;
  EMPTY_BADGEAGE: CWListeBadgeageModel;
  isReadOnly: boolean;
  orientation: number;
  parent: any;
  ready: boolean;
  tooltipDemande: string | number;
  workflow: CWGereronebadgeageWorkflow;
  etiquetteHisto1: CWEtiquette;
  histoEtiquettePopup: CWPanneauDeroulant;
  panneauIsOpen: boolean;

  /**
   * Event launched when a non-readonly input changes its value
   *
   */
  /**
   * Event launched when a keyup event is fired for an input of this view
   *
   */
  /**
   * Event launched when a keyup event is fired for a textarea input of this view
   *
   */
  /**
   * Event launched to manage tooltip when mouse is entered in gereronebadgeage-action
   *
   */
  /**
   * Event launched to manage tooltip when mouse is leaved in gereronebadgeage-action
   *
   */
  /**
   * Event launched when a click is done in a a menu item option (Accepter or Refuser)
   */
  /**
   * Event launched when value has changed and form must be refreshed
   */
  /**
   * Event launched when form mode has changed in order to check if new mode is avalid mode
   */
  /**
   * Event launched when current value model is not valid
   */

  /**
   * Constructor
   * View used for gerer one badgeage
   */
  constructor(options?: ViewOptions<Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_changeParent",
      "keyup :input:not([readonly])": "_notifyParent",
      "keyup textarea:not([readonly])": "_notifyParent"
    }, options.events, CWTYPE.DATE.events(), CWTYPE.HOUR_MINUTE_NIGHTLY.events());
    options.className = options.className || "cw-gererOneBadgeage";
    super(options);
    this.template = TPLGereronebadgeageDetail;
    this.id = null;
    this.context = options.context;
    this.parent = options.parent;
    this.orientation = options.orientation;// 0: Horizontal ; 1: Vertical
    this.model = new CWBaseModel({
      value: new CWListeBadgeageModel(),
      mode: "C"
    });
    if (this.context && this.context.ctxMode) {
      this.model.set("mode", this.context.ctxMode);
    }
    this.EMPTY_BADGEAGE = new CWListeBadgeageModel();
    this.workflow = new CWGereronebadgeageWorkflow({}, { "context": this.context });
    this.EMPTY_BADGEAGE.setHabContext(this.workflow.getHabContext());
    this.btnBar = new CWButtonBarValidationView({ id: "c-gererOneBadgeage__btnBar", isHideOnDisabled: true });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.gereronebadgeage = this;
    this.combos = {};
    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._mapToForm, this);
    this.model.on("change:mode", this._manageMode, this);
    this.typeMaskByClass = {};
    this.typeMaskByClass["saisie.date"] = "DATE_A";
    this.typeMaskByClass["saisie.heure"] = "INSTHM";
    this.ready = false;
    this.model.habContext = new CWHabilitationContext();
  }

  _changeParent(event: any): void {
    this._change(event);
    this._manageTitle();
  }

  _notifyParent(event: any): void {
    this._notifyEdition(event);
    this._manageTitle();
  }

  /**
   * Sets up the workflow for this view
   */
  setUp(callback: () => void): void {
    if (this.ready === false) {
      this.workflow.setUp(() => {
        this._loadData(callback);

      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  /**
   * Refreshes context and calls setup rendering again the view.
   */
  setContext(context: { [key: string]: any }): void {
    this.context = context;
    this._manageContext();
    this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  /**
   * Calls the setup and refreshes habilitations
   */
  _manageContext(): void { //
    this.setUp(() => {
      this._manageModeInitialisation(() => {
        this._manageActionsForStatut();
        this._manageActionsPossibles(); //OK
        this._manageHabilitation(); //OK
        if (this.context.ctxVolet === true) {
          if (this.context.ctxModeInitialisation === "Ajout") {
            this.btnBar.model.set("mode", "E");
          }
        }

      }); //OK
    });
  }

  _manageWorkflowContect(): void {
    let auxNatGest: string = null;

    if (this.context.ctxModeInitialisation === "Ajout") {
      auxNatGest = "A";
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      auxNatGest = "M";
    } else {
      auxNatGest = "";

    }
    this.workflow.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: auxNatGest
    }));
  }

  _loadData(callback: () => void): void {
    //Update the workflow's habilitation
    this._manageWorkflowContect();

    this.renderForm(null);
    this.ready = true;
    if (callback) {
      callback();
    }
  }

  /**
   * Refreshes title content
   */
  _manageTitle(): void {
    const context = this.context;
    let mode: string = "";

    if (context.ctxModeRepresentation === "pop-up") { //is a popUp
      mode = context.ctxModeInitialisation === "Ajout" ? i18n.t('common:gereronebadgeage.title_popup_creation') : i18n.t('common:gereronebadgeage.title_popup');
    }

    const $div = $('<div>');
    const $title = $('<div>');
    const $span = $('<span>');
    //$title.html($span);
    $span.html(mode.toUpperCase());
    $title.addClass('gereronebadgeage-titlePopup');
    if (this.context.ctxModeInitialisation === "Ajout") {
      $span.append($('<span>').addClass('gereronebadgeage-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereronebadgeage.creation_en_cours'))));
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      if (this.btnBar.model.get("mode") === 'E') {
        $span.append($('<span>').addClass('gereronebadgeage-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereronebadgeage.modification_en_cours'))));
      } else {
        $span.append($('<span>').addClass('gereronebadgeage-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(""));
      }
    }

    $title.append($span);
    $title.append($('<span>').addClass('c-titleHistoWkf'));
    $div.append($title);
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.parent.isTitleHTML = true;
      this.parent._setTitle($div.html());
    }
    this._paintWorkflowStatus();
  }

  /**
   * Sets model values from context data
   */
  _manageValeursCreation(): void {
    if (!CWSTR.isBlank(this.context.ctxValeursCreation)) {
      CWSTR.setElValue(this.model.get("value"), "saisie.date", this.context.ctxValeursCreation.BadgeageDate);
      CWSTR.setElValue(this.model.get("value"), "saisie.heure", this.context.ctxValeursCreation.BadgeageHeure);
      CWSTR.setElValue(this.model.get("value"), "saisie.sens", this.context.ctxValeursCreation.BadgeageSens);
      this.model.get("value").get("motif").code = this.context.ctxValeursCreation.BadgeageAbsence;
      CWSTR.setElValue(this.model.get("value"), "fonction", this.context.ctxValeursCreation.BadgeageFonction);
      CWSTR.setElValue(this.model.get("value"), "commentaire", this.context.ctxValeursCreation.BadgeageCommentaire);
    }
  }

  /**
   * Shows or hides buttons depending on ctxActionsPossibles data
   */
  _manageActionsPossibles(): void {
    //["Creer","Supprimer","Modifier","Valider"]
    if (this.context.ctxActionsPossibles) {
      if (this.context.ctxActionsPossibles.indexOf("Creer") === -1 || this.context.ctxModeRepresentation === "pop-up") {
        this.btnBar.model.trigger("hide:new");
      }

      if (this.context.ctxActionsPossibles.indexOf("Supprimer") === -1) {
        this.btnBar.model.trigger("hide:delete");
      }

      if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1) {
        this.btnBar.model.trigger("hide:save");
      }

      if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1 && this.context.ctxActionsPossibles.indexOf("Creer") === -1) {
        this.btnBar.model.trigger("hide:save");
        this.btnBar.model.trigger("hide:revert");
      }
      if (this.context.ctxActionsPossibles.indexOf("Valider") === -1 || !this.model.get("value").get("profils") || this.model.get("value").get("profils").length === 0) {
        this.btnBar.model.trigger("hide:valider");
      }
    }
  }

  /**
   * Shows or hides buttons depending on habilitations
   */
  _manageHabilitation(): void {
    if (!CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:new");
    }
    if (!CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxModeInitialisation !== "Ajout") {
      this.btnBar.model.trigger("hide:delete");
      CWFORMS.setFieldReadonly(this.$el.find(".saisie\\.sens"), true);
      CWFORMS.setFieldReadonly(this.$el.find(".motif\\.code"), true);
      CWFORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), true, false);
      this.$el.find(".phx-combobox-button").hide();
    } else if (CWSTR.isBlank(this.isReadOnly) || this.isReadOnly === false) {
      CWFORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), false, false);
      this.$el.find(".phx-combobox-button").show();
    }
    if (!CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && !CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
    }
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
      this.btnBar.model.trigger("hide:valider");
    }

    if (this.model.get("mode") === "V") {
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:save");
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:new");
      CWFORMS.setFormReadonly(this.$el, true, false);
    }

    if (GLOBAL_DATA.paramDivers.get("sai_lisf") && GLOBAL_DATA.paramDivers.get("sai_lisf").get("valeur") === "0") {
      CWFORMS.setFieldReadonly(this.$el.find(".fonction"), true);
    } else {
      CWFORMS.setFieldReadonly(this.$el.find(".fonction"), false);
    }
  }

  /**
   * Shows or hides buttons depending on habilitations
   */
  _manageActionsForStatut(): void {
    this.isReadOnly = false;
    this.btnBar.model.trigger("show:new"); // quelque soit le statut => on affiche le bouton créer (sfd Composant badgeage v2.0 paragraphe 2.2.2)

    if (!this._actionsForStatut("modification", null)) {
      this.isReadOnly = true;

      this.btnBar.model.trigger("hide:save");
      this.btnBar.model.trigger("hide:revert");
      CWFORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), true, true);
    } else {
      CWFORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), false, false);
    }
    if (!this._actionsForStatut("suppression", null)) {
      this.btnBar.model.trigger("hide:delete");
    } else {
      this.btnBar.model.trigger("show:delete");
    }
    if (!this._actionsForStatut("validation", null)) {
      this.btnBar.model.trigger("hide:valider");
    } else {
      this.btnBar.model.trigger("show:valider");
    }
    if (this._getModel().get("valorise") && this._getModel().get("valorise").sens && (this._getModel().get("valorise").sens === "X" || this._getModel().get("valorise").sens.code === "X")) {
      this.btnBar.model.trigger("hide:delete");
    }
  }

  /**
   * Manage restrictions for each action
   * Accepte, En course, Demande, Hors validation, refuse, Demande -> 	"A","I","D","H","R","T"
   */
  //	Accepte, En course, Demande, Hors validation, refuse, Demande -> 	"A","I","D","H","R","T"
  //	Manage restrictions for actions
  _actionsForStatut(action: string, stat: string): boolean {
    //view if can ejecute action where a one statut and a one collaborateur
    const utilisateur = this.context.ctxUtilisateur;
    const etat = this.model.get("value").get("etat")
    let statut = null;
    if (!CWSTR.isBlank(stat)) {
      statut = stat;
    } else {
      statut = this.model.get("value").get("statut").code;
    }


    let resp = false;
    //for ever statut
    if (action === "creation") {
      if (utilisateur === "Responsable") {
        resp = true;
      } else if (utilisateur === "Collaborateur") {
        resp = true;
      }
    } else if (action === "validation") {
      if (utilisateur === "Collaborateur") {
        resp = false;
      }
    }

    //for a one statut
    switch (statut) {
      case "D":
      case "T":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur" && statut === "D" && etat === "X") {
            resp = false
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = true;
          }
        }
        break;
      case "I":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = true;
          }
        }
        break;
      case "A":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = false;
          }
        }
        break;
      case "R":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = false;
          }
        }
        break;
      case "H":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = false;
          }
        }
        break;
      default:
        resp = true;
        break;
    }
    return resp;
  }

  /**
   * Manages initializing
   */
  _manageModeInitialisation(callback: () => void): void {
    this._manageWorkflowContect();
    this.btnBar.hideAllBtnBar();

    if (this.context.ctxModeInitialisation === "Ajout") {
      this._manageValeursCreation();
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.hideValidationBtn();
      this.btnBar.model.set("mode", "C");
      this._manageTitle();
      //New attribute for know if we are saving or deleting a new badgeage
      this.model.get("value").isCreation = true;
      this._mapToForm();
      if (callback) {
        callback();
      }
      this.model.get("value").store();
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      if (!CWSTR.isBlank(this.context.ctxGestionCollab) && !CWSTR.isBlank(this.context.ctxIdentifiantEvt)) {
        const gererBadgeage = this.model.get("value");
        this.id = encodeURIComponent(this.context.ctxGestionCollab.matricule + "," + this.context.ctxIdentifiantEvt);
        gererBadgeage.setHabContext(this.workflow.getHabContext());

        gererBadgeage.action = "element/" + this.id;
        gererBadgeage.fetch({
          success: (fresh: { [key: string]: any }) => {
            fresh.store();
            fresh.setHabContext(this.model.getHabContext());
            this._mapToForm();
            this._manageTitle();
            if (this.context.ctxModeInitialisation !== "Ajout" && !CWSTR.isBlank(gererBadgeage.get("statut")) && !CWSTR.isBlank(gererBadgeage.get("statut").code)) { //Statut
              this._paintWorkflowStatus();
            }
            this._manageWKFHisto(this.context.ctxHistoriqueWKF);
            this.btnBar._manageMode()
            this.btnBar.model.trigger("hide:copy")

            if (callback) {
              callback();
            }
            if (this.$el.find("button.accepter").is(":visible") === true) {
              const profilModel = new CWGererOneBadgeageProfilsModel();

              this.btnBar.model.trigger("hide:accepter");
              this.btnBar.model.trigger("hide:refuser");
              profilModel.profilId = gererBadgeage.get("matricule") + "," + gererBadgeage.get("code");
              profilModel.setHabContext(new CWHabilitationContext({
                onglet: this.context.ctxEcran,
                foncCour: this.context.ctxHabilitation.HabilitationValidation
              }));
              profilModel.fetch({
                success: () => {
                  if (profilModel.get("profils") && profilModel.get("profils").length > 0) {
                    this.model.get("value").set("profils", profilModel.get("profils"));
                    this.btnBar.configureValidationsButtonFromModel(this.model.get("value"));
                    this.btnBar.model.trigger("show:accepter");
                    this.btnBar.model.trigger("show:refuser");
                  } else {
                    this.btnBar.model.trigger("hide:accepter");
                    this.btnBar.model.trigger("hide:refuser");
                  }
                }
              });
            }
          },
          error: () => {
            if (callback) {
              callback();
            }
          }
        });

      } else {
        if (callback) {
          callback();
        }
        this.model.get("value").store();
      }
    } else if (this.context.ctxModeInitialisation === "InitForAction") {
      if (callback) {
        callback();
      }
    }

    if (this.context.ctxModeSuppression === "SensX") {
      this.btnBar.model.trigger("hide:delete");
    }
  }

  _paintWorkflowStatus(): void {
    const $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');
    if (this.context.ctxModeInitialisation === "Consultation") {//if is consultation mode
      $layout.show();
      const badgeage = this.model.get("value");
      //etiquette workflowhisto
      const subtextEtiquette = badgeage.get("etat") === "X" ? i18n.t('common:etiquette_subText.suppression') : "";
      const codeStatut = this.checkSCodetatut(badgeage.get("statut").code);
      const text = badgeage.get("statut").code === "H" ? "" : badgeage.get("statut").libelle;
      const onlyIcon = this.context.ctxHistoriqueWKF && badgeage.get("iddemande") >= 0 && badgeage.get("statut").code === "H";

      this.etiquetteHisto1 = new CWEtiquette({
        id: "etiquetteWorkHisto",
        text: {
          enabled: text //perhaps we should change for i18n text
        },
        subText: {
          enabled: subtextEtiquette
        },
        type: codeStatut,
        state: "actif",
        onlyIcon: onlyIcon
      });

      if (this.context.ctxHistoriqueWKF && this.workflow.wkf_activ && badgeage.get("iddemande") >= 0) {
        this.etiquetteHisto1.icon = 'horloge_fleche';
        this.listenTo(this.etiquetteHisto1.model, "click", this.histoEtiquetteClick);
      } else if (onlyIcon === true || text === "") {
        this.etiquetteHisto1.setNoBackground();
      }
      $layout.html(this.etiquetteHisto1.render().el);
    } else {
      if (!CWSTR.isBlank($layout)) {
        $layout.hide();
      }
    }
  }

  private histoEtiquetteClick(): void {

    if (!CWSTR.isBlank(this.histoEtiquettePopup) && this.panneauIsOpen === true) {
      this.histoEtiquettePopup.close();
      this.panneauIsOpen = false;
    } else {
      this.panneauIsOpen = true;

      if (this.histoEtiquettePopup === undefined) {
        this.histoEtiquettePopup = new CWPanneauDeroulant({
          fixedButtons: true,
          maxHeight: 360,
          view: this.composantWorkflow,
          viewData: {
            appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
            modal: false
          },
          notIconClose: true,
          closeOnClickOutside: true
        });
      } else {
        this.histoEtiquettePopup.viewData = {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          modal: false
        };
      }


      this.histoEtiquettePopup.setPosition({
        my: "right top",
        at: "right bottom",
        of: $(event.currentTarget)
      });


      this.histoEtiquettePopup.setHeight("auto");
      this.histoEtiquettePopup.setWidth(500);

      //const dialogModel = this.histoEtiquettePopup.model;
      this.histoEtiquettePopup.model.trigger("dlg:open");

      this.listenTo(this.histoEtiquettePopup.model, "dlg:close", (): void => {
        this.panneauIsOpen = false;
      });
      this.histoEtiquettePopup._setTitle(i18n.t('common:listerevtgen.historique').toUpperCase());
    }
    event.stopPropagation();
  }

  private checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes;
    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
        status = 'demande';
        break;
    }
    return status;
  }


  _panelStateIsReadonly(): boolean {
    const model = this._getModel();
    let isReadOnly = false;

    if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1 && this.context.ctxModeInitialisation === "Consultation") {
      isReadOnly = true;
    }

    //When contextutilisateur is 'Responsable' and statut is demande or en cours de validation,
    // fields are disabled.
    const currentStatut = !CWSTR.isBlank(this.context.BadgeageCodeStatutWKF) ? this.context.BadgeageCodeStatutWKF : model.get("statut").code;
    if (this.context.ctxUtilisateur === "Responsable" &&
      this.context.ctxGestionCollective === false &&
      (currentStatut === "D" || currentStatut === "T" || currentStatut === "I")) {
      isReadOnly = true;
    }
    //When statut is refuser disable the form
    if (model.get("etat") === "R") {
      isReadOnly = true;
    }

    if (isReadOnly === false && model && model.getHabContext && model.getHabContext()) {
      const canUpdate = CWHABILITATION.canUpdate(model.getHabContext().get("foncCour"));
      isReadOnly = !canUpdate && !model.isNew();
    }
    if (this._getModel().get("valorise") && this._getModel().get("valorise").sens && (this._getModel().get("valorise").sens === "X" || this._getModel().get("valorise").sens.code === "X")) {
      isReadOnly = true;
    }
    return isReadOnly;
  }

  /**
   * Manages mode representation
   */
  _manageModeRepresentation(): void {
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation === "pop-up") { // Pop up
      this.$el.parent().parent().css({ "min-width": "680px" });
      this.$el.find(".gereronebadgeage-content").append(this.btnBar.render().el);
      this.btnBar.model.trigger("hide:copy");
      // this.btnBar.addButton("valider", i18n.t('common:gereronebadgeage.btnValider'));
      this.$el.find("#regulcollab_btnbar").css("float", "left");
      if (this.context.ctxUtilisateur === "Collaborateur") {
        this.$el.find(".gereronebadgeage-utilisateur").hide();
      }
    } else {
      this.$el.find(".gereronebadgeage-utilisateur").hide();
      if (this.context && this.context.ctxEcran !== "saisiescoll") {
        this.$el.find(".gereronebadgeage-content").prepend(this.btnBar.render().el);
      }
      this.btnBar.model.trigger("hide:copy");
      // this.btnBar.addButton("valider", i18n.t('common:gereronebadgeage.btnValider'));
    }
  }


  /**
   * Prepares HTML for validation button
   */
  _configureValidationButton(): void {
    const model = this.model.get("value");
    const profils = model.get("profils");
    const ul = $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu");
    ul.empty();

    if (profils && profils.length === 1 && profils[0] && ul) {
      const accepter = $("<li>");
      const refuser = $("<li>");
      let a = $("<a class='menuItem' data-value='2' >" + i18n.t('common:gereronebadgeage.btnAccepter') + "</a>");
      a.attr("data-code", profils[0].code);
      accepter.append(a);
      ul.append(accepter);

      a = $("<a class='menuItem' data-value='3' >" + i18n.t('common:gereronebadgeage.btnRefuser') + "</a>");
      a.attr("data-code", profils[0].code);
      refuser.append(a);
      ul.append(refuser);
    }
    if (profils && profils.length > 1 && ul) {
      const accepter = $("<li>");
      const refuser = $("<li>");
      accepter.append("<span class='ui-corner-all'>" + i18n.t('common:gereronebadgeage.btnAccepter') + "</span>");
      ul.append(accepter);
      _.each(profils, (obj: { [key: string]: any }) => {
        const item = $("<li>");
        const a = $("<a class='menuItem' data-value='2'>- " + obj.libelle + "</a>");
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      });

      refuser.append("<span class='ui-corner-all'>" + i18n.t('common:gereronebadgeage.btnRefuser') + "</span>");
      ul.append(refuser);
      _.each(profils, (obj: { [key: string]: any }) => {
        const item = $("<li>");
        const a = $("<a class='menuItem' data-value='3'>- " + obj.libelle + "</a>");
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      });
    }
    $(".gereronebadgeage-detail", this.el).find("button.valider")
      .button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true })
      .click((): boolean => {
        const menu = $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu").show().position({
          my: "left top",
          at: "left bottom",
          of: this
        });
        $(document).one("click", function () {
          menu.hide();
        });
        return false;
      });

    if (!profils || profils.length === 0) {
      this.btnBar.model.trigger("hide:valider");
    }

    $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu").hide().menu();
  }

  /**
   * Renders view
   */
  render(): CWGererOneBadgeageView {
    const json = { "i18n": i18n, "UTILS": UTILS };
    this.$el.html(this.template(json));

    if (this.context) {
      this._manageContext();
    }

    this._manageModeRepresentation();

    return this;
  }

  /**
   * Renders form
   *
   */
  renderForm(callback: () => void): CWGererOneBadgeageView {
    const habCombo: CWHabilitationContext = this.workflow.getHabContext().copy();

    // combos need nature.
    habCombo.set("natGest", "");
    habCombo.set("foncCour", this.context.ctxHabilitation.HabilitationGestion);
    //Combo motif absence
    this.comboMotif = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "absence/composant",
        comboId: "motifs"
      }),
      name: "motif.code",
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.libelle) || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      autocomplete: true,
      required: false,
      habContext: habCombo
    });
    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);
    //Combo sens
    this.comboSens = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "utilbadgeage",
        comboId: "sens"
      }),
      name: "saisie.sens",
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.libelle) || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.code + " (" + value.libelle + ")";
      },
      required: true,
      habContext: habCombo
    });
    this.comboSens.setFilter(this._filterComboSens());
    this.$el.find(".cmbSens").html(this.comboSens.render().el);
    // Dates
    CWFORMS.setDatepicker(this, ".saisie\\.date");
    if (this.context.ctxEcran === "saisiescoll") {
      this.$el.find('.optionsHeader').hide();
    } else {
      this.$el.find('.optionsHeader').show();
    }
    if (callback) {
      callback();
    }
    return this;
  }

  /**
   * Filters combo options leaving only options with code different from 'X'
   */
  _filterComboSens(): any {
    const filter = (collection: any): any => {
      if (this.context.ctxModeSuppression === "Button") {
        const filterCollection: any = [];

        _.each(collection, (model: any) => {
          if (model.attrs.code !== "X") {
            filterCollection.push(model);
          }
        });

        return filterCollection;
      } else {
        return collection;
      }
    };
    return filter;
  }

  /**
   * Maps model to form
   */
  _mapToForm(): void {
    const context = this.context;
    let objPeriode = null;
    const gererBadgeage = this.model.get("value");
    const renderers: Renders = {};

    if (CWSTR.isBlank(this.$el)) {
      return;
    }
    const fieldset = this.$el.find("fieldset");
    const spanStatut = $(this.el).find("fieldset.gereronebadgeage-detail span.actionbadgeage");

    if (gererBadgeage.get("etat") === "X") {
      spanStatut.html(i18n.t('common:gereronebadgeage.demandeSuppressionBadgeage'));
      spanStatut.addClass("ui-phx-titre-demande-suppression");
    }

    // utilisateur
    if (context.ctxUtilisateur === "Responsable" &&
      context.ctxGestionCollective === false &&
      !CWSTR.isBlank(context.ctxGestionCollab)) {
      let utilisateur = context.ctxGestionCollab.nom + " " + context.ctxGestionCollab.prenom;
      // Default value is matric
      if (this.divers === "matric_aux") {
        utilisateur += " (" + this.context.ctxGestionCollab.matric_aux + ")";
      } else {
        utilisateur += " (" + this.context.ctxGestionCollab.matricule + ")";
      }
      $(this.el).find(".gereronebadgeage-utilisateur").html(utilisateur);
    }

    //Date ou période
    if (!CWSTR.isBlank(context.ctxGestionDate) && CWSTR.isBlank(context.ctxGestionPeriode)) {
      /*const formatDate = CWTYPE.DATE.format(context.ctxGestionDate, CWTYPE._getFormatByCode("DATE_L"));
      objPeriode = i18n.t('messages:GT_1143', { "1": formatDate });

      $(this.el).find(".gereronebadgeage-date").html(objPeriode);
      $(this.el).find(".gereronebadgeage-date").append("<br/><br/>");*/
    } else if (!CWSTR.isBlank(context.ctxGestionPeriode) && CWSTR.isBlank(context.ctxGestionDate)) {
      const formatDateDeb = CWTYPE.DATE.format(context.ctxGestionPeriode.datedebut, CWTYPE._getFormatByCode("DATE_L"));
      const formatDateFin = CWTYPE.DATE.format(context.ctxGestionPeriode.datefin, CWTYPE._getFormatByCode("DATE_L"));
      const formatHeureDeb = CWTYPE.HOUR_MINUTE_NIGHTLY.format(context.ctxGestionPeriode.heuredebut);
      const formatHeureFin = CWTYPE.HOUR_MINUTE_NIGHTLY.format(context.ctxGestionPeriode.heurefin);

      if (context.ctxGestionPeriode.datedebut === context.ctxGestionPeriode.datefin) {
        objPeriode = i18n.t('messages:GT_1144', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatHeureFin });
      } else {
        objPeriode = i18n.t('messages:GT_1196', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatDateFin, "4": formatHeureFin });
      }

      $(this.el).find(".gereronebadgeage-date").html(objPeriode);
      $(this.el).find(".gereronebadgeage-date").append("<br/><br/>");
    }
    renderers["motif.code"] = (): string => {
      if (!CWSTR.isBlank(gererBadgeage.get("motif").code)) {
        return gererBadgeage.get("motif").libelle + " (" + gererBadgeage.get("motif").code + ")";
      }
      return null;
    };
    // Error management
    gererBadgeage.off("invalid");
    gererBadgeage.on("invalid", this._showValidationErrors, this);
    // Clear current validation erros
    this._cleanValidationErrors();
    this._convertSensBadgeageVirtual(gererBadgeage);
    this._mapModelToForm(fieldset, gererBadgeage, renderers);
    this.btnBar.model.set("mode", "R");
    this._manageTitle();
  }

  /**
   * Converts sens to uppercase
   */
  //convert the sens Badgeages from virtual (lower case) to saisie (upper case)
  _convertSensBadgeageVirtual(gererBadgeage: { [key: string]: any }): void {
    if (gererBadgeage.get("saisie") && !CWSTR.isBlank(gererBadgeage.get("saisie").sens)) {
      gererBadgeage.get("saisie").sens = gererBadgeage.get("saisie").sens.toUpperCase();
    }
  }

  /**
   * Reverts view
   */
  revertView(): void {
    if (this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Ajout") {
      this.parent.close();
    } else {
      //Update context
      this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationAcces, natGest: "" });
      if (this.model.get("value")) {
        this.model.get("value").revert();
      }
      this.model.trigger("change:value");
      this.workflow.btnBarModel.set("mode", "R");
      this.btnBar.model.trigger("hide:copy");
      this._manageHabilitation();
      if (this.context && this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Consultation") {
        this.btnBar.model.trigger("hide:new");
      }
      this._manageWKFHisto(this.context.ctxHistoriqueWKF);
    }
  }

  /**
   * Save one badgeage
   */
  saveOneBadgeages(action: string, callback: (arg: { [key: string]: any }) => void): void {
    const target = this.model.get("value");

    target.set("matricule", this.context.ctxGestionCollab.matricule);
    target.action = action;
    if (target.isNew()) {
      target.id = null;
    }
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "M"
    }));
    target.save(null, {
      success: (fresh: any) => {
        if (callback) {
          callback(fresh);
        }
        this.parent.close();
      }
    });
  }

  /**
   * Modify one badgeage
   */
  modifiableOneBadgeages(callback: (arg: { [key: string]: any }) => void): void {
    const modifiableBadgeage = new CWGererBadgeagesGestion();

    modifiableBadgeage.action = "modifiable";
    modifiableBadgeage.id = encodeURIComponent(this.model.get("value").id);
    modifiableBadgeage.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion
    }));
    modifiableBadgeage.fetch({
      success: (fresh: any) => {
        if (callback) {
          callback(fresh);
        }
      }
    });
  }

  /**
   * Fetch the badgeage
   */
  fetchBadgeage(action: string, callback: (arg: { [key: string]: any }) => void): void {
    const target = new CWListeBadgeageModel();

    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    target.action = action;
    target.fetch({
      success: (freshModel: any) => {
        this.model.set("value", freshModel);

        if (callback) {
          callback(freshModel);
        }
      }
    });
  }

  /**
   * Create new badgeage
   */
  newOneBadgeages(): void {
    this.model.set("value", this.EMPTY_BADGEAGE, { silent: true });
    this._manageValeursCreation();
    this.model.get("value").set("matricule", this.context.ctxGestionCollab.matricule);
    //default values
    if (!CWSTR.isBlank(this.context.ctxGestionDate)) {
      this.model.get("value").get("saisie").date = this.context.ctxGestionDate;
    }
    //if map default values
    this._mapToForm();
    $(this.el).find(".gereronebadgeage-action").hide();
    $(this.el).find(".gereronebadgeage-wkfhisto").hide();
  }

  /**
   * Delete the badegeage
   */
  deleteOneBadgeages(comment: string): void {
    const target = new CWGererBadgeagesGestion();
    let droit = this.model.get("value").get("droit");
    const originalBadgeage = this.model.get("value");

    target.action = "demande";
    target.id = encodeURIComponent(this.model.get("value").id);
    if (!droit) {
      droit = "N";
    }
    // nature should be "S". in deletion
    this.model.getHabContext().set("natGest", "S");
    target.setHabContext(this.model.getHabContext());
    if (!CWSTR.isBlank(comment)) {
      target.set("commentaire", comment);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }
    target.destroy({
      contentType: 'application/json',
      url: Configuration.restRoot + "/rest/badgeage/composant/" + target.action + "/" + target.id + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
      success: () => {
        if (this.context.ctxModeRepresentation === "pop-up") {
          if (!CWSTR.isBlank(this.parent) && !CWSTR.isBlank(this.parent.dialog)) {
            this.parent.close();
          }
        }

        this.model.trigger("badgeageChanged", originalBadgeage, "delete");
      }
    });
  }

  deleteBadgeageFromMenuButton(badgeage: { [key: string]: any }): void {
    this.id = encodeURIComponent(badgeage.id);
    badgeage.setHabContext(this.model.getHabContext());
    this.model.set("value", badgeage, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  }

  deleteTousBadgeages(badgeages: { [key: string]: any }, length: number): void {
    let objModel;
    let statut;
    let dialog;
    let dialogModel: any;
    const badgeagesOk: any = [];

    for (let j = 0; j < length; j++) {
      if (this._actionsForStatut("suppression", badgeages[j].get("statut").code)) {
        badgeagesOk.push(badgeages[j]);
      }
    }
    if (badgeagesOk.length > 0) {
      dialog = this._initPopUp("suppressionTous", "", badgeagesOk.length);
      dialogModel = dialog.model;
      dialog.open(() => {
        if (dialogModel.get("action") === "OK") {
          for (let i = 0; i < badgeagesOk.length; i++) {
            objModel = badgeagesOk[i];
            statut = objModel.get("statut").code;
            this.model.set("value", objModel, { silent: true });

            if (statut === "A" || statut === "H" || (statut === "I" && this.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
              this.deleteOneBadgeages(dialogModel.get("commentaire"));
            } else {
              this.deleteOneBadgeages("");
            }
          }
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    }
  }

  /**
   * Accept badgeage
   */
  accepterOneBadgeages(commentaire: string, profil: { [key: string]: any } | string): void {
    const target = new CWGererBadgeagesGestion();
    const droit = this.model.get("value").get("droit");
    const originalBadgeage = this.model.get("value");

    target.action = "accepter";
    target.id = encodeURIComponent(this.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    // Profil isn't a object always: It can be a string or null value
    // We can obtain it from originalBadgeage too.
    if (profil !== null && profil !== undefined && profil !== "") {
      if (profil instanceof Object && profil.code && profil.libelle) {
        target.set("profil", profil);
      } else {
        target.set("profil", { "code": profil, "libelle": profil });
      }
    } else {
      target.set("profil", originalBadgeage.get("profil"));
    }

    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: "M"
    }));
    target.save(null, {
      success: () => {
        if (this.context.ctxModeRepresentation === "pop-up") {
          this.parent.close();
        }
        this.model.trigger("badgeageChanged", originalBadgeage, "update");
      }
    });
  }

  accepterBadgeageFromMenuButton(badgeage: { [key: string]: any }): void {
    this.id = encodeURIComponent(badgeage.id);
    badgeage.setHabContext(this.model.getHabContext());
    this.model.set("value", badgeage, { silent: true });
    this.btnBar.model.trigger("btn:click", "accepter", badgeage.get("profil"));
  }

  accepterTousBadgeages(badgeages: { [key: string]: any }, length: number): void {
    let objModel;
    let dialog;
    let dialogModel: any;
    const badgeagesOk: any = [];

    for (let j = 0; j < length; j++) {
      if (this._actionsForStatut("validation", badgeages[j].get("statut").code)) {
        badgeagesOk.push(badgeages[j]);
      }
    }
    if (badgeagesOk.length > 0) {
      dialog = this._initPopUp("accepterTous", "D", null);
      dialogModel = dialog.model;
      dialog.open(() => {
        if (dialogModel.get("action") === "OK") {

          for (let i = 0; i < badgeagesOk.length; i++) {
            objModel = badgeagesOk[i];
            this.model.set("value", objModel, { silent: true });

            this.accepterOneBadgeages(dialogModel.get("commentaire"), objModel.get("profil"));
          }
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    }
  }

  /**
   * Refuse badgeage
   */
  refuserOneBadgeages(commentaire: string, profil: { [key: string]: any } | string): void {
    const target = new CWGererBadgeagesGestion();
    const droit = this.model.get("value").get("droit");
    const originalBadgeage = this.model.get("value");

    target.action = "refuser";
    target.id = encodeURIComponent(this.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    // Profil isn't a object always: It can be a string or null value
    // We can obtain it from originalBadgeage too.
    if (profil !== null && profil !== undefined && profil !== "") {
      if (profil instanceof Object && profil.code && profil.libelle) {
        target.set("profil", profil);
      } else {
        target.set("profil", { "code": profil, "libelle": profil });
      }
    } else {
      target.set("profil", originalBadgeage.get("profil"));
    }
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: "M"
    }));
    target.save(null, {
      success: () => {
        if (this.context.ctxModeRepresentation === "pop-up") {
          this.parent.close();
        }
        this.model.trigger("badgeageChanged", originalBadgeage, "update");
      }
    });
  }

  refuserBadgeageFromMenuButton(badgeage: { [key: string]: any }): void {
    this.id = encodeURIComponent(badgeage.id);
    badgeage.setHabContext(this.model.getHabContext());
    this.model.set("value", badgeage, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser", badgeage.get("profil"));
  }

  refuserTousBadgeages(badgeages: { [key: string]: any }, length: number): void {
    let objModel;
    let dialog;
    let dialogModel: any;
    const badgeagesOk: any = [];

    for (let j = 0; j < length; j++) {
      if (this._actionsForStatut("validation", badgeages[j].get("statut").code)) {
        badgeagesOk.push(badgeages[j]);
      }
    }
    if (badgeagesOk.length > 0) {
      dialog = this._initPopUp("refuserTous", "D", null);
      dialogModel = dialog.model;
      dialog.open(() => {
        if (dialogModel.get("action") === "OK") {
          for (let i = 0; i < badgeagesOk.length; i++) {
            objModel = badgeagesOk[i];
            this.model.set("value", objModel, { silent: true });

            this.refuserOneBadgeages(dialogModel.get("commentaire"), objModel.get("profil"));
          }
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    }
  }

  _initPopUp(act: string, text: string, nombre: string): CWDialogView {
    const stat = text ? text : this.model.get("value").get("statut").code;
    const ctxUtilisateur = this.context.ctxUtilisateur;
    const dialog = new CWDialogView({
      view: CWGererOneBadgeagePopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur,
        nombre: nombre,
        appendTo: this.workflow.$appendTo
      }
    });

    dialog.setHeight(220);
    dialog.setWidth(445);
    return dialog;
  }

  /**
   * Validate badgeage
   */
  valider(callback: () => void): void {
    const target = this.model.get("value");

    target.action = "validesaisie";
    // CUSTOMER 143376
    if (target.get("saisie") && target.attributes.datedeb) {
      target.get("saisie").date = target.get('datedeb');
      delete target.attributes.datedeb;
    }
    if (target.get("saisie") && target.attributes.datefin) {
      target.get("saisie").date = target.get('datefin');
      delete target.attributes.datefin;
    }
    target.unset("result");
    target.setHabContext(this.model.getHabContext());
    target.save(null, {
      success: (model: any, response: { [key: string]: any }) => {
        if (response.result === "ok") {
          // CUSTOMER 143376
          // Old WS errors remain after ok WS call, so we have to clear them in 'ok' case.
          this._cleanValidationErrors();
          if (callback) {
            callback();
          }
        }
      }
    });
  }

  _manageWKFHisto(historique: boolean): void {
    if (historique === true && CWSTR.getElValue(this.model.get("value"), "historique") === true) {
      const contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: "BADGEAGE",
        ctxRefEvenement: CWSTR.getElValue(this.model.get("value"), "evenement"),
        ctxDemId: CWSTR.getElValue(this.model.get("value"), "iddemande"),
        ctxModeRestitution: "Imbrique", //"-""
        ctxActivModeSynth: true,
        ctxModeDefaut: "Deplie",
        ctxModeRech: false
      };

      this.composantWorkflow = new CWWkflhistoView();
      this.composantWorkflow.render();
      this.composantWorkflow.setContext(contextComponentWkf);
      this.composantWorkflow.hideIcon();
    } else {
      this.$el.find(".gereronebadgeage-wkfhisto").hide();
    }
  }
}
