import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLBresultatsBriqueResultatsCompteurs from '../briqueResultatsCompteurs.tpl.html';
import { CWDeroulantJauge, DreulantCompteur } from 'src/uc/common/deroulantJauge/cwDeroulantJauge.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWResultatsModel } from '../models/cwResultats.model';
import { CWResultatsPanneauView } from './cwResultatsPanneau.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


/**
 * View of Brique 'Resultats'
 */
export class CWResultatsView extends Backbone.View<Backbone.Model>{

  usePopulation: boolean;
  parent: any;
  brique: any;
  template: (params: any) => string;
  model: CWResultatsModel | any;
  habContext: CWHabilitationContext;
  empty: boolean;
  showTousCompteurs: boolean;
  panneau: CWPanneauDeroulant;
  outsideClickListener: (arg1: JQueryEventObject) => any;

  constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
    params = params || {};
    params.events = _.extend({
      "click .btn.btn-secondary": "_ouvrirPanneauCompteurs"
    }, params.events);
    super(params);
    this.usePopulation = false;
    this.showTousCompteurs = false;
    if (params) {
      if (params.usePopulation) {
        this.usePopulation = params.usePopulation;
      }
      if (params.parent) {
        this.parent = params.parent;
      }
      if (params.brique) {
        this.brique = params.brique;
      }
    }
    this.template = TPLBresultatsBriqueResultatsCompteurs;
    this.model = new CWResultatsModel({}, { userId: this.brique.personnalisation.userid, codeBrique: this.brique.personnalisation.codebrique });
    this.model.usePopulation = this.usePopulation;
    this.habContext = new CWHabilitationContext({
      onglet: "bresultats" + this.cid.substring(4) + "_codeb_" + UTILS.escapeJQueryString(this.model.codeBrique),
      foncCour: this.brique.fonctions[0], // eslint-disable-line
      natGest: "" // eslint-disable-line
    });
    this.model.setHabContext(this.habContext);
    this.empty = false;
  }

  render(): CWResultatsView {
    const json = { 'i18n': i18n };

    this.$el.html(this.template(json));
    return this;
  }

  isEmpty(): boolean {
    return this.empty;
  }

  checkHabilation(): boolean {
    return CWHABILITATION.canView("PER_BRACC_C.V") || CWHABILITATION.canView("PER_BRACC_R.V");
  }

  refreshBrique(): void {
    if (CWHABILITATION.canView("PER_BRACC_C.V") || CWHABILITATION.canView("PER_BRACC_R.V")) {
      this.model.fetch({
        success: (fresh: CWResultatsModel) => {
          this.empty = false;
          this.$el.parents("#bresultats" + this.cid.substring(4) + "_codeb_" + UTILS.escapeJQueryString(fresh.codeBrique)).show();
          if (fresh.get("cpt").length > 0 && !(fresh.get("cpt").length === 1 && CWSTR.isBlank(fresh.get("cpt")[0].code))) {
            this.$el.find(".table_ResultatsCompteurs ").html(this._arrayToGrid(fresh.get("cpt"), fresh.get("dateref")));
            this.parent.setTitle(fresh.get("libelle") + " " + i18n.t('common:bresultats.au') + " " + CWTYPE.DATE.format(fresh.get("dateref"), CWTYPE._getFormatByCode("DATE_A")));
          } else {
            this.$el.find(".table_ResultatsCompteurs ").html(i18n.t("common:bdem.noDemandesAffiche"));
            this.parent.setTitle(this.model.codeBrique + " " + i18n.t('common:bresultats.au') + " " + CWTYPE.DATE.format(fresh.get("dateref"), CWTYPE._getFormatByCode("DATE_A")));
          }
          //update margins
          this.$el.parents(".phx-portlet-div").css({
            "padding": "0px 0px 0px 2px",
            "width": "calc(100% - 4px)"
          });
          //Update libelles and put them into infobulles if they are longer than max
          const compteurCircles = this.$el.find('.c-jauge-inner');
          if (!CWSTR.isBlank(compteurCircles)) {
            if (compteurCircles.length > 0) {
              compteurCircles.each((i: number, comptCirc: any) => {
                const $comptLib = $(comptCirc).find(".c-jauge__lib");
                const comptCircWidth = $comptLib[0].offsetWidth > 0 ? $comptLib[0].offsetWidth : 104;
                let comptLibWidth = UTILS.getWidthTextHTML($comptLib[0].innerText);
                const comptLibText = $comptLib[0].innerText;
                const maxLibCircle = comptCircWidth - 24; //12px padding left and 12px padding right
                const lFactor = maxLibCircle / ((maxLibCircle * 60) / 80); //proportion 80 / 60;
                let lValPrev = -1;

                if (lFactor * comptLibWidth >= maxLibCircle) {
                  $(comptCirc).prop('title', comptLibText + "<br/>" + ($(comptCirc)[0].attributes as any)["title"].value);

                  while ((lFactor * comptLibWidth) > maxLibCircle) {
                    $comptLib[0].innerText = $comptLib[0].innerText.substring(0, $comptLib[0].innerText.length - 1);
                    comptLibWidth = UTILS.getWidthTextHTML($comptLib[0].innerText); //parseInt($comptLib.css('width'), 10);
                    if (lValPrev !== comptLibWidth) {
                      lValPrev = comptLibWidth;
                    } else {
                      break;
                    }
                  }
                  $comptLib[0].innerText = $comptLib[0].innerText.substring(0, $comptLib[0].innerText.length - 1) + ".";
                }
              })
            }
          }
          if (this.panneau) {
            this.panneau._closePanneau();
            this.panneau.dialog.dialog("destroy");
            this.panneau.dialog = null;
            this.panneau = null;
          }
        }
      });
    }
  }

  _arrayToGrid(modelCompteurs: Array<any>, dateref: string): any {
    const maxCompteurs = 3; // 4 en total
    const compteurs = JSON.parse(JSON.stringify(modelCompteurs)) as Array<DreulantCompteur>;//copie des compteurs

    for (let i = 0; i < compteurs.length; i++) {
      if (CWSTR.isBlank(compteurs[i].code)) {
        const index = compteurs.indexOf(compteurs[i]);
        if (index > -1) {
          compteurs.splice(index, 1);
          i--;
        }
      }
    }


    //paint the data
    const $container = $("<div>").addClass('container');
    const $row = $("<div>").addClass('row pb-2');
    $container.append($row);

    for (let i = 0; i < compteurs.length; i++) {
      const compteurNegative = compteurs[i].valeur.indexOf("-") !== -1;
      const jauge = new CWDeroulantJauge({
        isNegative: compteurNegative,
        compteur: compteurs[i],
        dateRef: dateref
      });

      $row.append(jauge.render().el);
      if (i === 3) {
        break;
      }
    }
    if (compteurs.length > maxCompteurs + 1) {
      const loupeIcon = UTILS.getSVGIcon("loupe", "", 24);
      const $buttonVisualiser = $("<div>", {
        "class": 'btn btn-secondary cw-bres-btn__visualiserCompteurs',
        "title": i18n.t('common:bresultats.visualiserCompteurs')
      })
      const $texteButton = $("<span>").addClass('btnResult mr-1');
      $texteButton.append(i18n.t('common:bresultats.visualiserCompteurs'));
      const $iconButton = $("<span>").addClass('ml-1');
      $iconButton.append(loupeIcon);
      $buttonVisualiser.append($texteButton);
      $buttonVisualiser.append($iconButton);
      $container.append($buttonVisualiser);
    }
    return $container;
  }

  _creationPanneau(ofContainer: HTMLElement): CWPanneauDeroulant {
    let lTitle = "";
    const lPosition = (ofContainer) ? { my: "center top", at: "center bottom", of: ofContainer } : { my: "center top", at: "center bottom", of: this.el }
    const panneau = new CWPanneauDeroulant({
      view: CWResultatsPanneauView,
      notIconClose: true,
      position: lPosition,
      viewData: {
        appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
        model: this.model,
        parent: this,
      }
    });

    panneau.setHeight("auto");
    panneau.setWidth("auto"); //470
    //panneau.setMinWidth(ofContainer.clientWidth);
    panneau.setMinWidth("90%" as undefined);

    panneau.dialogClass += " cw-pave__panneau"; //
    if (!CWSTR.isBlank(this.model.get("libelle"))) {
      lTitle = this.model.get("libelle");
      if (!CWSTR.isBlank(CWTYPE.DATE.format(this.model.get("dateref")))) {
        lTitle += " " + i18n.t('common:bresultats.au') + " " + CWTYPE.DATE.format(this.model.get("dateref"), CWTYPE._getFormatByCode("DATE_A"));
      }
    }
    panneau._setTitle(lTitle);
    return panneau;
  }

  _ouvrirPanneauCompteurs(): void {
    const lPos = this.$el.find(".cw-bres-btn__visualiserCompteurs");
    let lPosButton = null;

    if (lPos.length > 0) {
      lPosButton = lPos[0];
    } else {
      lPosButton = null;
    }
    if (CWSTR.isBlank(this.panneau)) {
      this.panneau = this._creationPanneau(lPosButton);
      this.outsideClickListener = (event: any): any => {
        if (lPos.filter(event.target).length < 1 &&
          (lPos.find("." + event.target.classList[0]).length < 1 || lPos.offsetParent()[0] !== event.target.offsetParent) &&
          (this.panneau.$el.find($(event.target)).length < 1 && lPos.find(event.target).length < 1)) {
          this.panneau._closePanneau(event, null);
        }
      };
    }
    $(this.panneau.$el).off("dialogopen");
    $(this.panneau.$el).off("dialogclose");
    $(this.panneau.$el).on("dialogopen", (): void => {
      $(document).on("click", this.outsideClickListener);
    }
    );
    $(this.panneau.$el).on("dialogclose", (): void => {
      $(document).off("click", this.outsideClickListener as any);
    }
    );
    this.panneau.open();
  }
}