import _ from 'underscore';
import TPLDroitsdelFilter from '../cwFilterMore.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWFilterBaseModel } from 'core/models/cwFilterBase.model';
import { CWRadioBoxView } from 'core/views/cwRadioBox.view';
import { CWSelectUtilisateurView } from 'common/referentiel/referentielutilisateur/views/cwSelectUtilisateur.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


export class CWFilterMoreContentView extends CWBaseFormView<CWFilterBaseModel> {

  /**
   * Constructor
   * View for the content of the filter part
   */

  workflow: { [key: string]: any };
  ctxEcran: string;
  referentiel: CWSelectUtilisateurView;
  referentielFilter: any;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, CWTYPE.DATE.events())
    super(options);
    this.template = TPLDroitsdelFilter;
    this.workflow = options.workflow;
    this.ctxEcran = options.ctxEcran;
    this.model = options.model;
    this.model.off("invalid");
    this.model.on("invalid", this._showValidationErrors, this);
    this.model.setHabContext(this.workflow.getHabContext());
    if (this.workflow && this.workflow.ctxPeriode && this.workflow.ctxPeriode.datedeb) {
      this.model.set("datedeb", this.workflow.ctxPeriode.datedeb);
    }
    if (this.workflow && this.workflow.ctxPeriode && this.workflow.ctxPeriode.datefin) {
      this.model.set("datefin", this.workflow.ctxPeriode.datefin);
    }
  }

  /**
   * Return the models of the use case
   */
  _getModel(): any {
    return this.model;
  }

  /**
   * Renders the view to the form
   */
  render(): CWFilterMoreContentView {
    const json = { "i18n": i18n };

    this.$el.html(this.template(json));
    return this;
  }

  /**
   * Renders the view to the form (referentiels, radiobuttons)
   */
  renderData(): void {
    let context: { [key: string]: any } = {};
    const options = [];
    const option: { [key: string]: any } = {};
    const option2: { [key: string]: any } = {};
    const option3: { [key: string]: any } = {};
    let radioRetroRender: string = null;

    context = {
      Habilitation: this.workflow.getHabContext().get("foncCour"),
      name: "delegue.matricule",
      ctxEcran: this.ctxEcran
    };
    this._mapModelToForm(this.$el, this.model);
    this.referentiel = new CWSelectUtilisateurView({ host: this, idComponent: "refutiliz", context: context, "ctxEcran": context.ctxEcran, paginatorPosition: "butom" });
    this.$el.find(".droisDeleguesA").html(this.referentiel.render().el);
    this.$el.find(".droisDeleguesA .phx-selecteur-referentiel");
    this.referentiel.filterOut(this.referentielFilter);
    //generate the options of radioButon
    option.code = "all";
    option.libelle = i18n.t('droitsdel.retroAll');
    options.push(option);
    option2.code = true;
    option2.libelle = i18n.t('droitsdel.retroTrue');
    options.push(option2);
    option3.code = false;
    option3.libelle = i18n.t('droitsdel.retroFalse');
    options.push(option3);
    radioRetroRender = new CWRadioBoxView({
      id: _.uniqueId("CWRadioBoxView"),
      options: options,
      name: "retro",
      required: true,
      selectedOption: "all"
    }).render().el;
    this.$el.find(".radioRetro").html(radioRetroRender);
  }

  /**
   * Action performed when choosing a data or close a referentiel
   */
  _responseCallback(data: { [key: string]: any }): void {
    const model = this.model;

    if (_.isNull(model.get("delegue")) || CWSTR.isBlank(model.get("delegue"))) {
      model.set("delegue", {});
    }
    model.get("delegue").matricule = data.matricule;
    model.get("delegue").matriculeaux = data.matriculeaux;
    model.get("delegue").nom = data.nom;
    model.get("delegue").prenom = data.prenom;
    model.get("delegue").utilcode = data.utilcode;
    model.get("delegue").utilnom = data.utilnom;
  }

  /**
   * Filter the data return of the referentiel
   */
  referentielRestriction(matricule: number | string): void {
    if (this.referentiel) {
      if (!CWSTR.isBlank(matricule)) {
        this.referentiel.filterOut([matricule]);
      } else {
        this.referentiel.filterOut([]);
      }
    } else {
      this.referentielFilter = [matricule];
    }
  }
}