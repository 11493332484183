import { STR } from 'utils/str.js';
import { HABILITATION } from 'utils/habilitation.js';
import { HEADERS } from 'utils/headers.js';

/**
 * Model to manage the context of an habilitation
 *
 */
export var HabilitationContext = Backbone.Model.extend({

  defaults: {
    onglet: "",
    foncCour: "",
    natGest: ""
  },

  /**
   * overrideNatureGestion is used in some components (mainly grids) to indicate if the component can modify this value
   * or should use the original value
   */
  initialize: function(attributes, options) {
    this.copyAttributes = _.clone(this.attributes);
    this.overrideNatureGestion = true;
    if (options && _.isBoolean(options.overrideNatureGestion)) {
      this.overrideNatureGestion = options.overrideNatureGestion;
    }
    this.exceptionCreation = (options && _.isBoolean(options.exceptionCreation)) ? options.exceptionCreation : false;
  },

  /**
   * List of the attributs that will be updated
   *
   */
  update: function(attrs) {
    this.copyAttributes = _.clone(this.attributes);

    for (var i in attrs) {
      if (i === "natGest") {
        if (this.overrideNatureGestion === true) {
          this.set(i, attrs[i]);
        }
      } else {
        this.set(i, attrs[i]);
      }
    }
  },

  /**
   * Build the gfi-context header used by the WS
   *
   */
  header: function() {
    return HEADERS.habilitationContext(this.attributes.onglet, this.attributes.foncCour, this.attributes.natGest);
  },

  /**
   * return a copy of the current habilitation context
   *
   */
  copy: function() {
    var ctx = new HabilitationContext(_.clone(this.attributes));
    ctx.overrideNatureGestion = this.overrideNatureGestion;
    return ctx;
  },

  /**
   * reverts the attributs to a previous version (previous update call)
   */
  revert: function() {
    this.attributes = this.copyAttributes;
  },

  /**
   * Verifier les droits de foncCour(string ou objet)
   */
  _verifierNettoyerFoncCour: function(mode) {
    var rtnFoncCour = this.get("foncCour");
    var methodeCheck = null;
    var modeCheck = mode;

    if (!_.isEmpty(rtnFoncCour)) {
      switch (modeCheck) {
        case "add":
          if (this.exceptionCreation === true) {
            methodeCheck = HABILITATION.canUpdate;
          } else {
            methodeCheck = HABILITATION.canCreate;
          }
          break;
        case "delete":
          methodeCheck = HABILITATION.canDelete;
          break;
        case "update":
          methodeCheck = HABILITATION.canUpdate;
          break;
        case "view":
        default: //en blanc-> view
          methodeCheck = HABILITATION.canView;
          modeCheck = "view";
      }
      if (typeof rtnFoncCour === "string") {
        //'replace(/ /g,"")' commment un "trim" pour tout le texte or  si la lib de typescript était ES2021/ES12-> replaceAll
        rtnFoncCour = (rtnFoncCour ? rtnFoncCour.replace(/ /g, "") : rtnFoncCour);
        rtnFoncCour = this._verifierHabilitationString(methodeCheck, rtnFoncCour, modeCheck);
      } else if (typeof rtnFoncCour === "object") {
        var foncKeys = _.keys(rtnFoncCour);
        var foncLen = foncKeys.length;

        for (let i = 0; i < foncLen; i++) {
          //'replace(/ /g,"")' commment un "trim" pour tout le texte or  si la lib de typescript était ES2021/ES12-> replaceAll
          var lfoncTemp = (rtnFoncCour[foncKeys[i]]) ? rtnFoncCour[foncKeys[i]].replace(/ /g, "") : rtnFoncCour[foncKeys[i]];
          var lfoncRtn = this._verifierHabilitationString(methodeCheck, lfoncTemp, modeCheck);

          if (!STR.isBlank(lfoncRtn)) {
            //On met la valeur correcte de "foncCour"
            rtnFoncCour[foncKeys[i]] = lfoncRtn;
          } else {
            delete rtnFoncCour[foncKeys[i]]; //on suprime l'habilitation du objet
          }
        }
      } else {
        rtnFoncCour = null; //ce n'est pas préparé pour le type de données ou c'est une erreur
      }
    }
    return rtnFoncCour;
  },

  /**
   * Ajouter le founcCour actif ou "N" pour l'utilisateur, mode (add, delete, update, view(defaut) )
   */

  verifierFoncCour: function(mode) {
    var foncFinal = this.get("foncCour");

    if (!_.isEmpty(foncFinal) && Configuration.IHMSecurity !== false) {
      foncFinal = this._verifierNettoyerFoncCour(mode);
      this.set("foncCour", foncFinal);
    }
    return foncFinal;
  },

  _checkSansGestion: function(methodeCheck, rtnFoncCour) {
    var accesDelete = false;

    if (typeof methodeCheck === "function" && typeof rtnFoncCour === "string" && !STR.isBlank(rtnFoncCour)) {
      if (methodeCheck === HABILITATION.canView) {
        accesDelete = true;
      } else {
        if (!HABILITATION.canCreate(rtnFoncCour) && !HABILITATION.canDelete(rtnFoncCour) && !HABILITATION.canUpdate(rtnFoncCour)) {
          accesDelete = true;
        } else {
          accesDelete = true;
        }
      }
    }
    return accesDelete;
  },

  _verifierHabilitationString: function(methodeCheck, valFoncCour, modeCheck) {
    var rtnFonc = null;

    if (typeof methodeCheck === "function" && typeof valFoncCour === "string" && !STR.isBlank(valFoncCour) && !STR.isBlank(modeCheck)) {
      var elemFonc = valFoncCour.split(",");

      if (elemFonc) {
        rtnFonc = valFoncCour;
        if (elemFonc.length <= 1) {
          if (rtnFonc === "N" && modeCheck !== "view" && modeCheck !== "update") {
            rtnFonc = null; //la valeur "N" est utilisée en mode "view"(cas special dans l'enregistrement du password, mode "update"). En autre cas, c'est une erreur. 
          } else if (rtnFonc !== "N" && !methodeCheck(rtnFonc) && this._checkSansGestion(methodeCheck, rtnFonc)) {
            rtnFonc = null; //on suprime l'habilitation du objet
          }
        } else {
          var newFonc = [];

          for (let i = 0; i < elemFonc.length; i++) {
            if (methodeCheck(elemFonc[i]) || !this._checkSansGestion(methodeCheck, elemFonc[i])) {
              newFonc.push(elemFonc[i]);
            }
          }
          if (newFonc.length > 0) {
            rtnFonc = newFonc.join(","); //les bonnes habiitations
          } else {
            rtnFonc = null; //on suprime l'habilitation du objet
          }
        }
      }
    }
    return rtnFonc;
  }
});
