module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n    <tr>\n        ';
 if(readonly) { 
__p+='\n        <td><span class="phx-referentiel-item-text" title="'+
((__t=(model.label ))==null?'':__t)+
'">'+
((__t=(model.label ))==null?'':__t)+
'</span></td>\n        ';
 } else { 
__p+='\n        <td><span class="phx-referentiel-item-text" title="'+
((__t=(model.label ))==null?'':__t)+
'">'+
((__t=(model.label ))==null?'':__t)+
'</span></td>\n        <td><span class="phx-referentiel-item-icon ui-icon ui-icon-close cw-triggerClick" role="button" aria-label="'+
((__t=(i18n.t('common:ARIA_label.supprimer_selection')))==null?'':__t)+
'" tabindex="0"></span></td>\n        ';
 }
__p+='\n    </tr>\n</table>';
}
return __p;
};
