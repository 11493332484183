import _ from 'underscore';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWEvtBadgeageSoumisWkf } from './cwEvtBadgeageSoumisWkf';
import { CWGererOneBadgeagePopUpView } from '../views/cwGererOneBadgeagePopUp.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWMSGS } from 'src/utils/cwMsgs';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

export class CWGereronebadgeageWorkflow extends CWReadOnlyModel {
  gereronebadgeage: any;
  btnBarModel: any;
  pDiversIdCollabModel: any;
  wkf_activ: boolean;
  public module: string;
  public $appendTo: JQuery; //pour les messages
  context: { [key: string]: any };

  /**
   * Constructor
   * Workflow model for component Gerer One Badgeage
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.context = options.context;
    this.module = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : null;
    this.btnBarModel = null;
    this.habContext = new CWHabilitationContext();
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback: () => void): void {
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.gereronebadgeage.divers = this.pDiversIdCollabModel.get("valeur");

    const evtBadgeageSoumisWkf = new CWEvtBadgeageSoumisWkf();
    this["wkf_activ"] = false;
    evtBadgeageSoumisWkf.setHabContext(new CWHabilitationContext({
      onglet: "gererbadgeages",
      foncCour: "PAR_WKFASSC.V",
      natGest: ""
    }));
    if (this.gereronebadgeage && !CWSTR.isBlank(this.gereronebadgeage.context)) {
      const lContext = this.gereronebadgeage.context;

      if (!CWSTR.isBlank(lContext.ctxEcran) && !CWSTR.isBlank(lContext.ctxHabilitation.HabilitationAcces)) {
        const lHabContext = new CWHabilitationContext({
          onglet: lContext.ctxEcran,
          foncCour: lContext.ctxHabilitation.HabilitationAcces
        });
        evtBadgeageSoumisWkf.setHabContext(lHabContext);
      }
    }
    if (callback) {
      if (CWHABILITATION.canView(evtBadgeageSoumisWkf.habContext.get("foncCour"))) {
        evtBadgeageSoumisWkf.fetch({
          success: (fresh: { [key: string]: any }) => {
            this["wkf_activ"] = fresh.get("0").active;
            if (callback) {
              this._manageCommentaire();
              callback();
            }
          }
        });
      } else {
        if (callback) {
          callback();
        }
      }
    }

    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.gereronebadgeage.model.on("form:edited", this._formEdited, this);
  }

  _manageCommentaire(): void {
    if (this.wkf_activ) {
      $(".blockBadgeCommentaire").show();
    } else {
      $(".blockBadgeCommentaire").hide();
    }
  }

  _confirmDelete(): void {
    const objModel = this.gereronebadgeage.model.get("value");
    const statut = objModel.get("statut").code;
    if (statut === "A" || statut === "H" ||
      (statut === "I" && this.gereronebadgeage.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
      const dialog = this._initPopUp("suppression");
      const dialogModel = dialog.model;
      dialog.open(() => {
        if (dialogModel.get("action") === "OK") {
          this.gereronebadgeage.deleteOneBadgeages(dialogModel.get("commentaire"));
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    } else {
      CWMSGS.showConfirmAdapter(i18n.t('common:gereronebadgeage.delconfirm'), (result: string) => {
        if (result === "Y") {
          this.gereronebadgeage.deleteOneBadgeages("");
        }
      }, null, null, null, this.$appendTo);
    }
  }

  /**
   * Manages actions (save,revert,new,delete,accepter,refuser) when buttons are pressed
   */
  _buttonAction(buttonId: string, profilMenu?: string): void {
    let dialog = null;
    let dialogModel: any = null;
    let validationProfil: { [key: string]: any } = null;
    let objModel = null;
    let context = null;
    let error = false;

    switch (buttonId) {
      case "save":
        objModel = this.gereronebadgeage.model.get("value");
        context = this.gereronebadgeage.context;

        if (!CWSTR.isBlank(context.ctxGestionPeriode)) {
          if (context.ctxGestionPeriode.datedebut < objModel.get("saisie").date &&
            context.ctxGestionPeriode.datefin > objModel.get("saisie").date) {

            if (context.ctxGestionPeriode.heuredebut < objModel.get("saisie").heure &&
              context.ctxGestionPeriode.heurefin > objModel.get("saisie").heure) {
              error = false;
            } else {
              error = true;
            }
          } else {
            error = true;
          }
        }

        if (error) {
          const txtError = i18n.t('messages:GT_1142', { "1": context.ctxGestionPeriode.datedebut, "2": context.ctxGestionPeriode.heuredebut, "3": context.ctxGestionPeriode.datefin, "4": context.ctxGestionPeriode.heurefin });
          CWMSGS.showError(txtError, null, this.$appendTo);
        } else {
          //New badgeage
          if (objModel.isNew()) {
            const action = "demande";
            this.gereronebadgeage.saveOneBadgeages(action, (fresh: { [key: string]: any }) => {
              this.gereronebadgeage.model.trigger("badgeageChanged", fresh, "create");
            });
          } else { //Modified regularisation. First we check if is modifiable
            if (objModel.get("saisie") && (objModel.get("saisie").sens === "X" || objModel.get("saisie").sens === "x")) {
              this._confirmDelete();
            } else {
              this.gereronebadgeage.modifiableOneBadgeages((fresh: { [key: string]: any }) => {
                if (fresh.get("result") === "ok") {
                  const action = "demande/" + encodeURIComponent(this.gereronebadgeage.model.get("value").id);
                  this.gereronebadgeage.saveOneBadgeages(action, (fresh: { [key: string]: any }) => {
                    this.gereronebadgeage.model.trigger("badgeageChanged", fresh, "update");
                  });
                }
              });
            }
          }
        }
        break;
      case "revert":
        this.btnBarModel.set("mode", "R");
        this.gereronebadgeage.revertView();
        this.btnBarModel.trigger("enabled:accepter");
        this.btnBarModel.trigger("enabled:refuser");
        break;
      case "new":
        if (this.btnBarModel.get("mode") !== "E") {
          this.gereronebadgeage.newOneBadgeages();

          this.btnBarModel.set("mode", "E");
          this.btnBarModel.trigger("show:save");
          this.btnBarModel.trigger("disable:accepter");
          this.btnBarModel.trigger("disable:refuser");
        }
        break;
      case "delete":
        this._confirmDelete();
        break;
      case "accepter":
        dialog = this._initPopUp("accepter");
        dialogModel = dialog.model;
        validationProfil = this._getValidationProfil(profilMenu);
        dialog.open(() => {
          if (dialogModel.get("action") === "OK") {
            this.gereronebadgeage.accepterOneBadgeages(dialogModel.get("commentaire"), validationProfil);
          }
        });

        dialog._setTitle(i18n.t('common:gereronebadgeage.commentaire_AcceptDemande'));
        break;
      case "refuser":
        dialog = this._initPopUp("refuser");
        dialogModel = dialog.model;
        validationProfil = this._getValidationProfil(profilMenu);
        dialog.open(() => {
          if (dialogModel.get("action") === "OK") {
            this.gereronebadgeage.refuserOneBadgeages(dialogModel.get("commentaire"), validationProfil);
          }
        });

        dialog._setTitle(i18n.t('common:gereronebadgeage.commentaire_RefusDemande'));
        break;
    }
  }

  _getValidationProfil(profilMenu?: string): { [key: string]: any } {
    const model = this.gereronebadgeage.model.get("value");
    const profils = model.get("profils");
    let profil: { [key: string]: any } = null;

    if (profils && profils.length === 1 && profils[0]) {
      profil = {
        code: profils[0].code,
        libelle: profils[0].libelle
      };
    } else if (!CWSTR.isBlank(profilMenu) && typeof profilMenu === "string" && profils && profils.length > 1) {
      const findProfil = _.find(profils, (item: { [key: string]: any }): boolean => {
        return item.code === profilMenu;
      });

      if (!_.isEmpty(findProfil)) {
        profil = findProfil;
      }
    }
    return profil;
  }
  /**
   * Manages buttonBar when form is edited. It sets mode to E and disables accepter and refuser buttons.
   */
  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("disable:accepter");
    this.btnBarModel.trigger("disable:refuser");
  }

  /**
   * It initializes pop up view used to send a message and confirm (GererOneBadgeagePopUpView)
   */
  _initPopUp(act: string): CWDialogPopupView {
    const stat = this.gereronebadgeage.model.get("value").get("statut").code;
    const ctxUtilisateur = this.gereronebadgeage.context.ctxUtilisateur;
    const dialog = new CWDialogPopupView({
      view: CWGererOneBadgeagePopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur,
        appendTo: this.$appendTo
      }
    });

    dialog.setHeight("auto");
    dialog.setWidth(470);

    return dialog;
  }
}
