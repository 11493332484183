module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="listerregularisation-customBar">\n  <div>\n    <a class="tableSimple" href="javascript:void(0)" style="text-decoration: none; padding: 2px;">\n      '+
((__t=(i18n.t('common:listerregularisations.simple')))==null?'':__t)+
'\n    </a>\n  </div>\n  <div>\n    <a class="tableDetaille" href="javascript:void(0)" style="text-decoration: none; padding: 2px;">\n      '+
((__t=(i18n.t('common:listerregularisations.detaille')))==null?'':__t)+
'\n    </a>\n  </div><br/>\n</div><br/>\n';
}
return __p;
};
