import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWLOG } from 'src/utils';
import { CWSTR } from 'utils/cwStr';
import { objs } from 'src/objectsRepository';
/**
 * Phoenix custom router
 */
export class CWSubRoute extends Backbone.Router {

  workflow: any;
  module: string;

  matchedRouteMethod: () => void;
  module_func: () => string;
  private prefix: string;
  private separator: string;
  createTrailingSlashRoutes: boolean;
  postInitialize: (options?: { [key: string]: any }) => void;
  private initialized: boolean;
  isSubroute: boolean;


  /**
   * Constructor
   */
  constructor(prefix: string, options: { [key: string]: any }) {
    super(options as Backbone.RouterOptions);
    this.initialized = false;
    if (options && options.module) {
      this.module = options.module;
    }
    // Create a new routes replacing module_mane by the real name.
    const routes: { [key: string]: any } = this.routes;
    let keys = _.keys(routes);
    const values = _.values(routes);
    keys = _.map(keys, (key) => {
      return key.replace("<module_name>", this.module);
    }, this);
    this.routes = _.object(keys, values);
    this.listenTo(objs.appRt.workflow, "change:usecase", this._treatUsecaseFocusInternal);
    this.initializeSubRouter(prefix, options);
  }

  initialize(): void {
    //Empty initialize to prevent Backbone.router calls crash
  }

  /**
   * Cleans screen and initializes the layout
   */
  list(): void {
    // Check dependencies
    if (CWSTR.isBlank(this.workflow)) {
      throw new Error("This router need a workflow");
    }
    if (CWSTR.isBlank(this.workflow.tableModel)) {
      throw new Error("The wokflow of this model needs a tableModel");
    }

    // clean screen and initializes the layout
    this._initLayoutUC((): void => {
      const table = this.workflow.tableModel;
      if (table.coll.length > 0) {
        if (table.coll.pagination.startIndex === 0) {
          const firstRow = table.coll.at(0);
          if (_.isNull(table.get("value")) || table.get("value").id !== firstRow.id) {
            table.selectRow(firstRow);
          }
        } else {
          table.selectFirstRow();
        }
      }
    });
  }
  /**
   * Details for this subroute
   */
  details(id: string): void {
    // Check dependencies
    if (!this.workflow) {
      throw new Error("This router need a workflow");
    }
    if (!this.workflow.tableModel) {
      throw new Error("The wokflow of this model needs a tableModel");
    }

    const table = this.workflow.tableModel;

    //0 - clean screen and initializes the layout
    this._initLayoutUC(function () {
      // 1 - Do the required actions over the already painted screen
      // In this case, select the row passed by param
      const model = new Backbone.Model({ id: decodeURIComponent(id) });
      if (_.isNull(table.get("value")) || table.get("value").id !== model.id) {
        table.selectRow(model);
      }
    });
  }

  /**
   *
   */
  getCurrentMode(): string {
    let mode = "R";
    if (this.workflow && this.workflow.btnBarModel && this.workflow.btnBarModel instanceof Backbone.Model) {
      mode = this.workflow.btnBarModel.get("mode");
    }
    return mode;
  }

  /**
   * Throws error because this function is not implemented yet.
   */
  _initLayoutUC(callback?: (...args: any[]) => void): void { //eslint-disable-line
    throw new Error('Method "_initLayoutUC" not implemented yet');
  }
  /**
   * Returns true if it is on accueil
   */
  isOnAccueil(): boolean {
    return this.prefix.indexOf("acc") > 0;
  }
  /**
   * Returns true if it is Mon Compte
   */
  isOnMonCompte(): boolean {
    return this.prefix.indexOf("coll") > 0;
  }
  /**
   * Returns true if it is on Mon Equipe
   */
  isOnMonEquipe(): boolean {
    return this.prefix.indexOf("resp") > 0;
  }
  /**
   * Returns true if it is on Referentiel
   */
  isOnReferentiel(): boolean {
    return this.prefix.indexOf("ref") > 0;
  }
  /**
   * Returns true if it is on Administration
   */
  isOnAdministration(): boolean {
    return this.prefix.indexOf("adm") > 0;
  }

  _treatUsecaseFocusInternal(appRtWorkflow: void, focusedModule: string): void {
    if (this.module === focusedModule) {
      //avant de changer, on doit verifier s'il y a une popup qui est cachée et modal (très important)
      const $lPosPopup = $("#" + this.module).find(".ui-widget-overlay.ui-front");

      if ($lPosPopup && $lPosPopup.length > 0 && this.workflow && !CWSTR.isBlank(this.workflow.module)) {
        const $lModule = $(this.workflow.module === "." ? "body" : "#" + this.workflow.module);
        const $lPosModal = $lModule.find(".ui-widget-overlay.ui-front");//style modal

        if ($lPosModal && $lPosModal.length > 0) {
          const $lPopups = $lModule.find(".c-cwDialogPopup.ui-dialog");

          //on prend la dernier popup (elle sera laquelle est modal)
          if ($lPopups && $lPopups.length > 0) {
            const $elemPop = $($lPopups[$lPopups.length - 1]);

            if ($elemPop.css("display") === "none") {
              const $lPosDialog = $elemPop.find(".phx-Dialog.ui-dialog-content");

              if ($lPosDialog && $lPosDialog.length > 0 && $lPosDialog.dialog("instance")) {
                const position = $lPosDialog.dialog("option", "position");

                //la popup est affichée
                $elemPop.show();
                //et réposition avec la même information
                $lPosDialog.dialog("option", "position", position);
              }
            }
          }
        }
      }
      this._treatUsecaseFocus();
    }
  }

  _treatUsecaseFocus(): any {
    // Override this method to capture when the UCs is selected
  }


  // OLD unmaintened subrouter lib:
  initializeSubRouter(prefix?: string, options?: { [key: string]: any }): void {

    // each subroute instance should have its own routes hash
    this.routes = _.clone(this.routes);

    // Prefix is optional, set to empty string if not passed
    this.prefix = prefix = prefix || "";

    // SubRoute instances may be instantiated using a prefix with or without a trailing slash.
    // If the prefix does *not* have a trailing slash, we need to insert a slash as a separator
    // between the prefix and the sub-route path for each route that we register with Backbone.        
    this.separator = (prefix.slice(-1) === "/") ? "" : "/";

    // if you want to match "books" and "books/" without creating separate routes, set this
    // option to "true" and the sub-router will automatically create those routes for you.
    this.createTrailingSlashRoutes = options && options.createTrailingSlashRoutes;

    // Required to have Backbone set up routes
    options.forcedInitialize = true;
    Backbone.Router.prototype.constructor.call(this, options);

    // grab the full URL
    let hash: string = "";
    if ((Backbone.history as any).fragment) {
      hash = Backbone.history.getFragment();
    } else {
      hash = Backbone.history.getHash();
    }

    // Trigger the subroute immediately.  this supports the case where 
    // a user directly navigates to a URL with a subroute on the first page load.
    // Check every element, if one matches, break. Prevent multiple matches
    _.every(this.routes, (key: any, route: any) => {
      // Use the Backbone parser to turn route into regex for matching
      if (hash.match((Backbone.Router as any).prototype._routeToRegExp(route))) {
        Backbone.history.loadUrl(hash);
        return false;
      }
      return true;
    }, this);

    if (this.postInitialize) {
      this.postInitialize(options);
    }
  }

  navigate(fragment: string, options?: Backbone.NavigateOptions | boolean | any): Backbone.Router {
    if (fragment.substr(0, 1) !== '/' &&
      fragment.indexOf(this.prefix.substr(0, this.prefix.length - 1)) !== 0) {

      fragment = this.prefix +
        (fragment ? this.separator : "") +
        fragment;
    }
    return super.navigate(fragment, options);
  }

  route(route: string, name: string, callback?: (...args: any) => any): Backbone.Router {
    // strip off any leading slashes in the sub-route path, 
    // since we already handle inserting them when needed.
    if (route.substr(0) === "/") {
      route = route.substr(1, route.length);
    }

    let _route = this.prefix || "";
    const separator = this.separator || "";
    if (route && route.length > 0) {
      _route += (separator + route);
    }

    if (this.createTrailingSlashRoutes) {
      (this.routes as any)[_route + '/'] = name;
      this.routeBackbone(_route + '/', name, callback);
    }

    // remove the un-prefixed route from our routes hash
    delete (this.routes as any)[route];

    // add the prefixed-route.  note that this routes hash is just provided 
    // for informational and debugging purposes and is not used by the actual routing code.
    (this.routes as any)[_route] = name;

    // delegate the creation of the properly-prefixed route to Backbone
    return (this as any).routeBackbone(_route, name, callback);
  }

  routeBackbone(routeArg: RegExp | string, name: string | ((...args: any) => any), callback?: (...args: any) => any): CWSubRoute {
    let route: RegExp | string = routeArg;
    if (!_.isRegExp(route)) {
      route = (this as any)._routeToRegExp(route);
    }
    if (_.isFunction(name)) {
      callback = name;
      name = '';
    }
    if (!callback) {
      callback = (this as any)[name];
    }
    Backbone.history.route(route, (fragment: string): void => {
      //This funcition is executed in app/router because we need to finish the contruction of the object
      this.matchedRouteMethod = (): void => {
        const args = (this as any)._extractParameters(route, fragment);
        const infoRoute: string = (this.routes as any)[fragment] ? (this.routes as any)[fragment] : null;

        if (infoRoute && infoRoute !== name) {
          CWLOG.debug("Change infoR--" + name + " > " + infoRoute);
          name = infoRoute;
          if (typeof (this as any)[name] === "function") {
            callback = (this as any)[name];
          }
        }
        if (callback) {
          callback.apply(this, args);
        }
        this.trigger.apply(this, ['route:' + name].concat(args)); //eslint-disable-line
        this.trigger('route', name, args);
        Backbone.history.trigger('route', this, name, args);
        this.initialized = true;
      };
      if (this.initialized === true) {
        this.matchedRouteMethod();
      }
    });
    return this;
  }

}
