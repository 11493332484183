import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererbadgeagesDetail from '../cwDetail.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWEditableGridModel } from 'core/grids/editablegrid/cwEditableGrid.model';
import { CWEditableGridView } from 'core/grids/editablegrid/cwEditableGrid.view';
import { CWEditedRowView } from './cwEditedRow.view';
import { CWGererBadgeagesGestion } from '../models/cwGererBadgeagesGestion';
import { CWGererBadgeagesProfilsModel } from '../models/cwGererBadgeagesProfils.model';
import { CWGererbadgeagesWorkflow } from '../models/cwGererbadgeages.workflow';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWHistoDemModelColl } from 'src/uc/common/wkfhisto/models/cwHistoDemModel.collection';
import { CWHistoEventModelColl } from 'src/uc/common/wkfhisto/models/cwHistoEventModel.collection';
import { CWListeBadgeageColl } from '../models/cwListeBadgeage.collection';
import { CWListeBadgeageModel } from '../models/cwListeBadgeage.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWWkflhistoView } from 'common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';


export class CWGererBadgeagesView extends CWBaseFormView {
  /**
   * Constructor
   * View to render gerer badgeages, and
   * API to the component for execute the actions (new, fetch, save, modifiable, delete, acepter, refuser)
   */
  listeBadgeage: CWListeBadgeageColl;
  workflow: CWGererbadgeagesWorkflow;
  EMPTY_BADGEAGE: CWListeBadgeageModel
  context: { [key: string]: any };
  parent: any;
  orientation: number;
  table: CWEditableGridView;
  paramIdCollab: string;
  tooltipDemande: any;
  paramProvenanceBadgeage: any;
  parametreDiversAfficheBadgeXModel: Backbone.Model;
  paramAfficheBadgeX: string;
  composantWorkflow: CWWkflhistoView;
  collectionHisto: CWHistoDemModelColl | CWHistoEventModelColl;


  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "mouseenter span.txtStatutGerer": "_openTooltipListener",
      "mouseleave span.txtStatutGerer": "_closeTooltipListener"
    })
    super(options);
    let lModule: string = null;

    this.listeBadgeage = new CWListeBadgeageColl();
    this.model = new CWBaseModel({
      value: new CWListeBadgeageModel(),
      mode: "C"
    });
    if (options.context) {
      this.context = options.context;
      lModule = this.context.ctxEcran;
    }
    this.workflow = new CWGererbadgeagesWorkflow({ "module": lModule });
    this.workflow.gererBadgeages = this;
    this.EMPTY_BADGEAGE = new CWListeBadgeageModel();
    this.EMPTY_BADGEAGE.setHabContext(this.workflow.getHabContext());
    this.template = TPLGererbadgeagesDetail;
    if (options && options.parent) {
      this.parent = options.parent;
    }
    if (options.orientation) { // 0: Horizontal ; 1: Vertical
      this.orientation = options.orientation;
    }

    this.parent._setTitle(i18n.t('common:gererbadgeages.table_title'));

    this.workflow.setUp(() => {
      this.setUp(() => {
        this.workflow.tableModel.btnBarModel.off("btn:click", this.workflow.tableModel.buttonAction);
        this.workflow.tableModel.btnBarModel.on("btn:click", this.workflow._customBtnAction, this);
        this.listenTo(this.workflow.tableModel.coll, "row:select", this._manageActionsForLine);
      });
    });
  }

  /**
   * Show/hide buttons depending the actions available for the selected line
   */
  _manageActionsForLine(model: { [key: string]: any }): void {
    const btnBar = this.workflow.tableModel.btnBarModel;

    this._manageButtonsTable();
    if (!CWSTR.isBlank(model.get("statut").code)) {
      //view si line can delete
      if (!this._actionsForStatut("suppression", model.get("statut").code)) {
        btnBar.trigger("hide:delete");
      }
      if (this._getModel().get("valorise") && this._getModel().get("valorise").sens && (this._getModel().get("valorise").sens === "X" || model.get("valorise").sens.code === "X")) {
        btnBar.trigger("hide:delete");
      }
      if (this.context.ctxModeSuppression === "SensX") {
        btnBar.trigger("hide:delete");
      }
      //view si line can valider
      if (!this._actionsForStatut("validation", model.get("statut").code) || !model.get("profils") || model.get("profils").length === 0) {
        btnBar.trigger("custom.hide:valider");
      }
    }
    this._changeValiderOptions(model);
  }

  /**
   * Show/hide buttons depending the actions available for the table
   */
  _manageButtonsTable(): void {
    const context = this.context;
    const btnBar = this.workflow.tableModel.btnBarModel;

    this._habilityButtons();
    //view if can create
    if (context.ctxActionsPossibles.indexOf("Creer") === -1 || !this._actionsForStatut("creation")) {
      btnBar.trigger("hide:new");
    }
    //view if can delete
    if (context.ctxActionsPossibles.indexOf("Supprimer") === -1 || context.ctxModeSuppression !== "Button") {
      btnBar.trigger("hide:delete");
    }
    //view if validate
    if (context.ctxActionsPossibles.indexOf("Valider") === -1 || !CWHABILITATION.canView(context.ctxHabilitation.HabilitationValidation)) {
      btnBar.trigger("custom.hide:valider");
    }
    //hide every copy
    btnBar.trigger("hide:copy");
  }

  /**
   * Show all buttons
   */
  _habilityButtons(): void {
    const btnBar = this.workflow.tableModel.btnBarModel;

    btnBar.trigger("show:save");
    btnBar.trigger("show:revert");
    btnBar.trigger("show:new");
    btnBar.trigger("show:delete");
    btnBar.trigger("custom.show:valider");
  }

  /**
   * Set up the view. Link the models between them and render the form
   */
  setUp(callback: () => void): void {
    const context = this.context;

    //params for url
    this.listeBadgeage.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.listeBadgeage.matricule = context.ctxGestionCollab.matricule;
    //dates
    if (context.ctxGestionDate) {
      this.listeBadgeage.datedeb = context.ctxGestionDate;
      this.listeBadgeage.datefin = context.ctxGestionDate;
    } else {
      this.listeBadgeage.datedeb = context.ctxGestionPeriode.datedebut;
      this.listeBadgeage.datefin = context.ctxGestionPeriode.datefin;
    }
    // parametres divers
    this.parametreDiversAfficheBadgeXModel = GLOBAL_DATA.paramDivers.get("AffBadgX");
    this.paramAfficheBadgeX = this.parametreDiversAfficheBadgeXModel.get("valeur");
    this.listeBadgeage.statuts = context.ctxFiltreApplique.join(",");
    if (this.paramAfficheBadgeX && this.paramAfficheBadgeX === "1") {
      this.listeBadgeage.statuts = this.listeBadgeage.statuts.concat(",S");
    }
    //Update the workflow's habilitations
    this._manageWorkflowContect();
    if (context && context.ctxUtilisateur === "Collaborateur") {
      this.listeBadgeage.isForResponsable = false;
    }
    this.listeBadgeage.fetch({
      success: () => {
        //delete the file where valorise sens = X
        this.table = this._initTable();
        this.workflow.table = this.table;
        this.workflow.tableModel = this.table.model;
        if (callback) {
          callback();
        }
        this._manageButtonsTable();
        this.renderForm();
        if (!(context.ctxModeInitialisation === "Ajout" && context.ctxActionsPossibles.indexOf("Creer") !== -1 && CWHABILITATION.canCreate(context.ctxHabilitation.HabilitationGestion) && this._actionsForStatut("creation"))) {
          const tableMd = this.workflow.tableModel;

          if (tableMd.coll.length > 0) {
            const firstRow = tableMd.coll.at(0);

            tableMd.selectRow(firstRow);
          }
        }
        this.model.trigger("ready");
      }
    });
  }

  _lineStateIsReadonly(): boolean {
    const model = this.table.model.get("value");
    let isReadOnly = false;

    if (model && this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion) {
      const canUpdate = CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);

      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  }

  /**
   * Renders the view to the form and formated the Collaborateur and Periode
   */
  renderForm(): any {
    const context = this.context;
    let objMatricule = null;
    let objPeriode = null;

    //Collaborateur concerne
    if (context.ctxUtilisateur === "Responsable" && context.ctxGestionCollective === false && !CWSTR.isBlank(context.ctxGestionCollab)) {
      const nom = context.ctxGestionCollab.nom;
      const prenom = context.ctxGestionCollab.prenom;
      let htmlCollab = nom.toUpperCase() + " " + prenom + " ";

      if (this.paramIdCollab === "matric_aux") {
        objMatricule = context.ctxGestionCollab.matric_aux;
      } else {
        objMatricule = context.ctxGestionCollab.matricule;
      }
      htmlCollab += " (" + objMatricule + ")";
      $(this.el).find(".gererbadgeages-detail .collaborateur").html(htmlCollab);
    }
    //Date ou période
    if (!CWSTR.isBlank(context.ctxGestionDate) && CWSTR.isBlank(context.ctxGestionPeriode)) {
      const formatDate = CWTYPE.DATE.format(context.ctxGestionDate, CWTYPE._getFormatByCode("DATE_L"));

      objPeriode = i18n.t('messages:GT_1143', { "1": formatDate });
    } else if (!CWSTR.isBlank(context.ctxGestionPeriode) && CWSTR.isBlank(context.ctxGestionDate)) {
      const formatDateDeb = CWTYPE.DATE.format(context.ctxGestionPeriode.datedebut, CWTYPE._getFormatByCode("DATE_L"));
      const formatDateFin = CWTYPE.DATE.format(context.ctxGestionPeriode.datefin, CWTYPE._getFormatByCode("DATE_L"));
      const formatHeureDeb = CWTYPE.HOUR_MINUTE.format(context.ctxGestionPeriode.heuredebut);
      const formatHeureFin = CWTYPE.HOUR_MINUTE.format(context.ctxGestionPeriode.heurefin);

      if (context.ctxGestionPeriode.datedebut === context.ctxGestionPeriode.datefin) {
        objPeriode = i18n.t('messages:GT_1144', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatHeureFin });
      } else {
        objPeriode = i18n.t('messages:GT_1196', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatDateFin, "4": formatHeureFin });
      }
    }
    $(this.el).find(".gererbadgeages-detail .periode").html(objPeriode);
    //table of badgeages
    $(this.el).find(".gererbadgeages-detail .table_badgeages").html(this.table.el);
    this.table.render();
    return this;
  }

  _manageWorkflowContect(): void {
    let auxNatGest: string = null;

    if (this.context.ctxModeInitialisation === "Ajout") {
      auxNatGest = "A";
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      auxNatGest = "M";
    } else {
      auxNatGest = "";
    }
    this.workflow.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: auxNatGest
    }));
  }

  /**
   * Creating the table that contains the data from the collection
   */
  _initTable(): CWEditableGridView {
    const tableModel = new CWEditableGridModel({ coll: this.listeBadgeage });
    const tblColumns: Array<CWBaseGridColums> = [
      { title: i18n.t('common:gererbadgeages.date'), property: "saisie.date", width: 2 },
      { title: i18n.t('common:gererbadgeages.heure'), property: "saisie.heure", width: 1 },
      { title: i18n.t('common:gererbadgeages.sens'), property: "saisie.sens", width: 2 },
      { title: i18n.t('common:gererbadgeages.motif'), property: "motif.code", width: 3 },
      { title: i18n.t('common:gererbadgeages.fonction'), property: "fonction", width: 1 },
      { title: i18n.t('common:gererbadgeages.origine'), property: "origine", width: 2 },
      { title: i18n.t('common:gererbadgeages.statut'), property: "statut.code", width: 1 }
    ];
    const viewRow = new CWEditedRowView({ parent: this, context: this.context, workflow: this.workflow });
    const gridOptions: any = {
      id: "gererbadgeages_table",
      columns: tblColumns,
      model: tableModel,
      viewRow: viewRow,
      showFilteredRowsInTitle: true,
      habilitations: this.context.ctxHabilitation.HabilitationGestion,
      title: i18n.t('common:gererbadgeages.table_title')
    };
    let table: CWEditableGridView = null;
    let menuOptions: { [key: string]: any }[] = [];

    gridOptions["viewRow"] = new CWEditedRowView({ parent: this, context: this.context, workflow: this.workflow });
    gridOptions["viewRowTitle"] = i18n.t('common:gererbadgeages.table_title');
    gridOptions["title"] = i18n.t('common:gererbadgeages.table_title');
    gridOptions.classForButtons = 'c-form--fixedButtons';
    table = new CWEditableGridView(gridOptions);
    //		// Render the Cells for formated the text presentation
    table.cellRenderers["saisie.date"] = (model: { [key: string]: any }): string => {
      let resp = "";
      const formatedSaisieDate = CWTYPE.DATE.format(model.get("saisie").date, CWTYPE._getFormatByCode("DATE_A"));
      const formatedValoriseDate = CWTYPE.DATE.format(model.get("valorise").date, CWTYPE._getFormatByCode("DATE_A"));

      resp = formatedSaisieDate;
      if (this.context.ctxModeInitialisation === "Consultation") {
        if (this._isDiferentBadgeages(model.get("valorise"), model.get("saisie"))) {
          resp = formatedSaisieDate;
          resp += "<br/>";
          resp += "<span class='ui-phx-badgeage-valorise'>" + formatedValoriseDate + "</span>";
        } else {
          resp = "<span>" + formatedValoriseDate + "</span>";
        }
      }
      return resp;
    };

    table.cellRenderers["saisie.heure"] = (model: { [key: string]: any }): string => {
      let resp = "";
      const formatedSaisieHeure = CWTYPE.HOUR_MINUTE.format(model.get("saisie").heure);
      const formatedValoriseHeure = CWTYPE.HOUR_MINUTE.format(model.get("valorise").heure);

      resp = formatedSaisieHeure;
      if (this.context.ctxModeInitialisation === "Consultation") {
        if (this._isDiferentBadgeages(model.get("valorise"), model.get("saisie"))) {
          resp = formatedSaisieHeure;
          resp += "<br/>";
          resp += "<span class='ui-phx-badgeage-valorise'>" + formatedValoriseHeure + "</span>";
        } else {
          resp = "<span>" + formatedValoriseHeure + "</span>";
        }
      }
      return resp;
    };
    table.cellRenderers["saisie.sens"] = (model: { [key: string]: any }): string => {
      let resp = "";

      if (this._isDiferentBadgeages(model.get("valorise"), model.get("saisie"))) {
        resp = model.get("saisie").sens.code;
        resp += "<br/>";
        resp += "<span class='ui-phx-badgeage-valorise'>" + model.get("valorise").sens.code + "</span>";
      } else {
        resp = "<span>" + model.get("valorise").sens.code + "</span>";
      }
      if (model.get("etat") === "X") {
        resp += "<br/>";
        resp += "<span class='ui-phx-demande-suppression'>" + i18n.t('common:gererbadgeages.demandeSuppressionBadgeage') + "</span>";
      }
      return resp;
    };
    table.cellRenderers["motif.code"] = (model: { [key: string]: any }): string => {
      let resp = "";

      if (!CWSTR.isBlank(model.get("motif").code)) {
        resp = model.get("motif").libelle + " (" + model.get("motif").code + ")";
      }
      return resp;
    };
    table.cellRenderers["origine"] = (model: { [key: string]: any }): string => {
      let resp = "";
      let msgType = "";
      let msgOrigine = "";
      let msgPointage = "";
      const genere = model.get("genere");
      const origine = model.get("origine");
      const terminal = model.get("terminal");
      const provenance = model.get("provenance");
      const initial = model.get("initial");
      const valorise = model.get("valorise");
      let pointSeparator = false;
      let minusSeparator = false;

      if (genere === true) {
        msgType = i18n.t('common:gererbadgeages.origine_genere');
      } else if (origine === "T") {
        msgType = i18n.t('common:gererbadgeages.origine_telephone');
      } else if (origine === "E") {
        msgType = i18n.t('common:gererbadgeages.origine_cs');
      } else {
        if (terminal && !CWSTR.isBlank(terminal.code)) {
          msgType = i18n.t('common:gererbadgeages.origine_pmf');
          msgOrigine = terminal.libelle + " (" + terminal.code + ")";
        } else {
          if (origine === "P") {
            msgType = i18n.t('common:gererbadgeages.origine_pmf');
          } else if (origine === "D") {
            msgType = i18n.t('common:gererbadgeages.origine_differe');
          }
          if ((origine === "P" || origine === "I" || origine === "D") &&
            !CWSTR.isBlank(provenance) &&
            !CWSTR.isBlank(this.paramProvenanceBadgeage) &&
            (this.paramProvenanceBadgeage === "1" || this.paramProvenanceBadgeage === "2")) {
            msgOrigine = provenance;
          }
        }
        if (!CWSTR.isBlank(initial.heure) && this._isDiferentBadgeages(initial, valorise)) {
          const sensLibelle = initial.sens.libelle;
          const sensCode = initial.sens.code;
          const formattedDate = CWTYPE.DATE.format(initial.date, CWTYPE._getFormatByCode("DATE_A"));
          const formattedHeure = CWTYPE.HOUR_MINUTE.format(initial.heure);

          if (initial.date !== valorise.date) {
            msgPointage = formattedDate + " " + formattedHeure + " " + sensLibelle + " (" + sensCode + ")";
          } else {
            msgPointage = formattedHeure + " " + sensLibelle + " (" + sensCode + ")";
          }
        }
      }
      pointSeparator = !CWSTR.isBlank(msgType) && !CWSTR.isBlank(msgOrigine);
      minusSeparator = (!CWSTR.isBlank(msgType) || !CWSTR.isBlank(msgOrigine)) && !CWSTR.isBlank(msgPointage);
      resp += msgType;
      if (pointSeparator) {
        resp += " : ";
      }
      resp += msgOrigine;
      if (minusSeparator) {
        resp += " - ";
      }
      resp += msgPointage;
      return resp;
    };
    table.cellRenderers["statut.code"] = (model: { [key: string]: any }): string => {
      let resp = "";

      if (!CWSTR.isBlank(model.get("statut").code)) {
        resp = this._apliqueStatut(model);
      }
      return resp;
    };
    table.setColumnsAlignment({ "saisie.date": "left", "saisie.heure": "left", "saisie.sens": "left" });
    this.workflow.habContext = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion
    });
    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(this.workflow.getHabContext());
    // create a copy of the habilitation context to be used by the actions (add/update/remove) originating from the grid
    table.model.setHabContext(this.workflow.getHabContext());
    menuOptions = [{ action: "accepter", text: i18n.t('common:gererbadgeages.accepter') }, { action: "refuser", text: i18n.t('common:gererbadgeages.refuser') }];
    table.buttonBar.addButton(i18n.t('common:gererbadgeages.valider'), "valider", true, menuOptions);
    tableModel.coll.on("row:dblclick", this._customManageRowEdition, this);
    return table;
  }

  /**
   * Creating the menu valider to the table, depends to the profils
   */
  _changeValiderOptions(model: { [key: string]: any }): void {
    if (this.context.ctxActionsPossibles.indexOf("Valider") !== -1 && this._actionsForStatut("validation", model.get("statut").code)) {
      const profilId = model.get("matricule") + "," + model.get("code");
      const profilsModel = new CWGererBadgeagesProfilsModel();

      profilsModel.profilId = profilId;
      profilsModel.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: "RES_VAL_BDG.G"
      }));
      profilsModel.fetch({
        success: (fresh: { [key: string]: any }) => {
          const profils = fresh.get("profils");
          let menuOptions: any = [];

          if (profils && profils.length === 0 || profils && profils.length > 0 && !profils[0]) { //0 profils
            this.table.buttonBar.model.trigger("custom.hide:valider");
          } else if (profils && profils.length === 1 && profils[0]) { //only 1 profils
            // #customer 146291
            menuOptions = [
              { action: "accepter_" + profils[0].code, text: i18n.t('common:gererbadgeages.accepter') },
              { action: "refuser_" + profils[0].code, text: i18n.t('common:gererbadgeages.refuser') }
            ];
            // #customer 146291
          } else if (profils && profils.length > 1) { // >1 profils
            menuOptions.push({ action: "", text: i18n.t('common:gererbadgeages.accepter') });
            _.each(profils, (profil: { [key: string]: any }) => {
              if (!CWSTR.isBlank(profil)) {
                menuOptions.push({ action: "accepter_" + profil.code, text: profil.libelle });
              }
            });
            menuOptions.push({ action: "", text: i18n.t('common:gererbadgeages.refuser') });
            _.each(profils, (profil: { [key: string]: any }) => {
              if (!CWSTR.isBlank(profil)) {
                menuOptions.push({ action: "refuser_" + profil.code, text: profil.libelle });
              }
            });
          }
          this.table.buttonBar.replaceButtonOptions("valider", menuOptions);
          this.table._showSelectedRowIndex();
        }
      });
    }
  }

  /**
   * Find libelle to the liste, with your code
   */
  _findLibelle(code: number, liste: any): any {
    for (let i = 0; i < liste.length; i++) {
      const c1 = String(liste[i].code).toUpperCase();
      const c2 = String(code).toUpperCase();
      if (c1 === c2) {
        return liste[i].libelle;
      }
    }
    return null;
  }

  /**
   * Apply the class to the statut type
   */
  _apliqueStatut(badgeage: { [key: string]: any }): string {
    let ensemble = null, demande = null;
    let style = null;
    let resp = null;

    if (badgeage.get("iddemande") === 0) {
      ensemble = badgeage.get("evenement");
      demande = null;
    } else {
      demande = badgeage.get("iddemande");
      ensemble = null;
    }
    switch (badgeage.get("statut").code) {
      case "D":
      case "T":
        style = "ui-phx-statut-demande";
        break;
      case "I":
        style = "ui-phx-statut-en-cours";
        break;
      case "A":
        style = "ui-phx-statut-accepte";
        break;
      case "R":
        style = "ui-phx-statut-refuse";
        break;
      case "H":
        style = "ui-phx-statut-hors-validation";
        break;
      // no default
    }
    if (badgeage.get("historique")) {
      resp = "<span class='txtStatutGerer " + style + "' demande='" + demande +
        "' ensemble='" + ensemble + "'>" + badgeage.get("statut").libelle +
        "</span>";
    } else {
      resp = "<span class='" + style + "'>" + badgeage.get("statut").libelle + "</span>";
    }
    return resp;
  }

  /**
   * Compare 2 badgeages and says if different
   */
  _isDiferentBadgeages(badgeage1: { [key: string]: any }, badgeage2: { [key: string]: any }): boolean {
    if (badgeage1.date !== badgeage2.date || badgeage1.heure !== badgeage2.heure || (!CWSTR.isBlank(badgeage1.sens) && !CWSTR.isBlank(badgeage2.sens) && (badgeage1.sens.code !== badgeage2.sens.code))) {
      return true;
    }
    return false;
  }

  /**
   * Renders the view to the form
   */
  render(): CWGererBadgeagesView {
    const json = { "i18n": i18n };

    this.$el.html(this.template(json));
    return this;
  }

  /**
   * Create and open tooltip when a hover your mouse over the class 'txtStatutGerer'
   */
  _openTooltipListener(event: any): void {
    let demande = null;
    let reference = null;
    const jquery = event.currentTarget.attributes["aria-describedby"];

    if (jquery) {
      return;
    }
    if (event.currentTarget.attributes.demande.value === 0 || event.currentTarget.attributes.demande.value === "null") {
      reference = event.currentTarget.attributes.ensemble.value;
    } else {
      demande = event.currentTarget.attributes.demande.value;
    }
    this.tooltipDemande = demande || reference;
    $(event.currentTarget).tooltip({ content: "" });
    if (this.context.ctxHistoriqueWKF === true) {
      const contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: "BADGEAGE",
        ctxRefEvenement: reference,
        ctxDemId: demande,
        ctxModeRestitution: "-",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: true
      };

      this.composantWorkflow = new CWWkflhistoView();
      this.composantWorkflow.context = contextComponentWkf;
      this.composantWorkflow.render();
      //this.composantWorkflow.hideIcon();
      if (!CWSTR.isBlank(demande)) {
        this.collectionHisto = new CWHistoDemModelColl();
        this.collectionHisto.ctxDemId = demande;
        this.collectionHisto.ctxModeRech = true;
      } else {
        this.collectionHisto = new CWHistoEventModelColl();
        (this.collectionHisto as CWHistoEventModelColl).ctxTypeEvenement = "BADGEAGE";
        (this.collectionHisto as CWHistoEventModelColl).ctxRefEvenement = reference;
      }
      this.collectionHisto.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces
      }));
      this.collectionHisto.fetch({
        success: (fresh: CWHistoDemModelColl | CWHistoEventModelColl) => {
          if (fresh.length > 0) {
            $(event.currentTarget).tooltip("option", "content", () => {
              return this.composantWorkflow._generateContent(fresh);
            });
            $(event.currentTarget).prop("title", "");
            $(event.currentTarget).tooltip("open");
          }
        }
      });
    }
  }

  /**
   * Close tooltip when a quit your mouse over the class 'txtStatutGerer'
   */
  _closeTooltipListener(): void {
    try {
      this.tooltipDemande = null;
      $(event.currentTarget).tooltip("destroy");

    } catch (error) {
      // empty
    }
  }

  /**
   * Return if is permitted an action depending on the state
   */
  _actionsForStatut(action?: string, statut?: string): boolean {
    const utilisateur = this.context.ctxUtilisateur;
    let resp = false;

    //view if can ejecute action where a one statut and a one collaborateur
    //for ever statut
    if (action === "creation") {
      if (utilisateur === "Responsable") {
        resp = true;
      } else if (utilisateur === "Collaborateur") {
        resp = true;
      }
    } else if (action === "validation") {
      if (utilisateur === "Collaborateur") {
        resp = false;
      }
    }
    //for a one statut
    switch (statut) {
      case "D":
      case "T":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = true;
          }
        }
        break;
      case "I":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = true;
          }
        }
        break;
      case "A":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = false;
          }
        }
        break;
      case "R":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = false;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = false;
          }
        }
        break;
      case "H":
        if (action === "modification") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = false;
          }
        } else if (action === "suppression") {
          if (utilisateur === "Responsable") {
            resp = true;
          } else if (utilisateur === "Collaborateur") {
            resp = true;
          }
        } else if (action === "validation") {
          if (utilisateur === "Responsable") {
            resp = false;
          }
        }
        break;
      // no default
    }
    return resp;
  }

  /**
   * Create the new badgeage
   */
  newBadgeage(): void {
    const target = new CWListeBadgeageModel();
    const context = this.context;

    target.usecase = context.ctxEcran;
    target.habilitationV = context.ctxHabilitation.HabilitationAcces;
    this.workflow.updateHabContext({ natGest: "A" });
    //default values
    target.set("matricule", context.ctxGestionCollab.matricule);
    if (!CWSTR.isBlank(context.ctxValeursCreation)) {
      target.get("saisie").date = context.ctxValeursCreation;
    } else {
      target.get("saisie").date = context.ctxGestionDate;
    }
    //select combo sens option
    target.get("saisie").sens.code = "E";
    this.table.model.coll.add(target);
  }

  /**
   * Fetch data to the server of the badgeage
   */
  revertBadgeage(callback: () => void): void {
    let editedModel = null;

    if (this.table.model.get("value").isNew()) {
      editedModel = this.table.model.get("value");
      editedModel.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationGestion,
        natGest: "S"
      }));
      editedModel.destroy();
      //not selection model in the coll into
      this.table.model.set("value", null);
      this.table.model.set("mode", "C");
      this.workflow.updateHabContext({ natGest: "M" });
      this.table.model.selectFirstRow();
    } else {
      editedModel = this.table.model.get("value");
      editedModel.revert();
      this.workflow.updateHabContext({ natGest: "M" });
      // Select the record and paginate if required
      editedModel.trigger("row:unedit");
      this.table.model.set("mode", "R");
      if (callback) {
        callback();
      }
    }
  }

  /**
   * Save data of the badgeage
   */
  saveBadgeage(callback: (fresh: { [key: string]: any }) => void): void {
    const target = this.table.model.get("value");
    let operation = "";

    target.set("matricule", this.context.ctxGestionCollab.matricule);
    target.action = "demande";
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "M"
    }));
    if (target.isNew()) {
      target.version = "2.2.0";
      target.id = null;
      operation = "create";
      target.updateHabContext({ natGest: "A" });
    } else {
      target.version = "2.2.0";
      target.action = target.action + "/" + encodeURIComponent(target.id);
      operation = "update";
    }
    target.save(null, {
      success: (fresh: any) => {
        this.table.model.coll.fetch({
          success: () => {
            this.table.model.set("mode", "R");
            this.workflow.updateHabContext({ natGest: "M" });
            this.table.model.selectRow(fresh);
            this.model.trigger("badgeageChanged", fresh, operation);
            if (callback) {
              callback(fresh);
            }
          }
        });
        this.model.trigger("form:edited");
        this.table.model.trigger("finishEdition", true);
      }
    });
  }

  /**
   * Check up is modificable the badgeage
   */
  modifiableBadgeage(callback: (fresh: { [key: string]: any }) => void): void {
    const modifiableBadgeage = new CWGererBadgeagesGestion();

    modifiableBadgeage.action = "modifiable";
    modifiableBadgeage.id = encodeURIComponent(this.table.model.get("value").id);
    modifiableBadgeage.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion
    }));
    modifiableBadgeage.fetch({
      success: (fresh: { [key: string]: any }) => {
        if (callback) {
          callback(fresh);
        }
      }
    });
  }

  /**
   * Delete the badgeage
   */
  deleteBadgeage(comment: string): void {
    const target = new CWGererBadgeagesGestion();
    let droit = this.table.model.get("value").get("droit");
    const originalBadgeage = this.table.model.get("value");

    target.action = "demande";
    target.id = encodeURIComponent(this.table.model.get("value").id);
    if (!droit) {
      droit = "N";
    }
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "S"
    }));
    if (!CWSTR.isBlank(comment)) {
      target.set("commentaire", comment);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }
    target.destroy({
      contentType: 'application/json',
      url: Configuration.restRoot + "/rest/badgeage/composant/" + target.action + "/" + target.id + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
      success: () => {
        this.table.model.coll.fetch({
          success: () => {
            this.model.trigger("badgeageChanged", originalBadgeage, "delete");
          }
        });
        this.table.model.trigger("finishEdition", true);
      }
    });
  }

  /**
   * Valider the badgeage to acepter
   */
  accepterBadgeage(commentaire: string, profil: { [key: string]: any }): void {
    const target = new CWGererBadgeagesGestion();
    const droit = this.table.model.get("value").get("droit");

    target.action = "accepter";
    target.id = encodeURIComponent(this.table.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil;
    target.canViewTreatment = true;
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: "M"
    }));
    target.save(null, {
      success: (fresh: { [key: string]: any }) => {
        this.table.model.coll.fetch({
          success: () => {
            let attrs: { [key: string]: any } = null;

            this.table.model.selectRow(this.table.model.get("value"));
            attrs = _.extend(this.table.model.get("value").attributes, fresh.attributes);
            fresh.set(attrs);
            this.model.trigger("badgeageChanged", fresh, "update");
          }
        });
      }
    });
  }

  /**
   * Valider the badgeage to refuser
   */
  refuserBadgeage(commentaire: string, profil: { [key: string]: any }): void {
    const target = new CWGererBadgeagesGestion();
    const droit = this.table.model.get("value").get("droit");

    target.action = "refuser";
    target.id = encodeURIComponent(this.table.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil;
    target.canViewTreatment = true;
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: "M"
    }));
    target.save(null, {
      success: (fresh: { [key: string]: any }) => {
        this.table.model.coll.fetch({
          success: () => {
            let attrs: { [key: string]: any } = null;

            this.table.model.selectRow(this.table.model.get("value"));
            attrs = _.extend(this.table.model.get("value").attributes, fresh.attributes);
            fresh.set(attrs);
            this.model.trigger("badgeageChanged", fresh, "update");
          }
        });
      }
    });
  }

  _customManageRowEdition(): void {
    this.workflow.trigger("updateFonction");
  }
}
