import _ from 'underscore';
import { CWDummyEvtGen } from './cwDummyEvtGen.model';


export class CWAccepterEvtGen extends CWDummyEvtGen {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/evtgen/accepter/";
    }
  }
}