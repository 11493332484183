import _ from 'underscore';
import { CWAccepterEvtGen } from './cwAccepterEvtGen.model';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWDialogView } from 'src/core/components/dialog/cwDialog.view';
import { CWEvenementModelColl } from './cwListerEvtGen.collection';
import { CWFilterListerEvtGen } from './cwFilterListerEvtGen.model';
import { CWGererPiecesJointesView } from 'common/evenements/gerer/gererpiecesjointes/views/cwGererPiecesJointes.view';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWListerEvenementsGeneralView } from '../views/cwListerEvenementsGeneral.view';
import { CWListerEvetGenOverloadedBaseModel } from './cwListerEvtGenOverloadedBaseModel.model';
import { CWListerEvetGenProgressBarModel } from './cwListerEvetGenProgressBar.model';
import { CWListerEvtGenModel } from './cwListerEvtGen.model';
import { CWMenuGridModel } from 'src/core/grids/menugrid/cwMenuGrid.model';
import {
  CWMSGS,
  CWSTR,
  LOG,
  UTILS
} from 'src/utils';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWRefuserEvtGen } from './cwRefuserEvtGen';
import { CWVoletView } from 'src/core/components/dialog/volet/cwVolet.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n';
import { Model } from 'Backbone';
import { objs } from 'src/objectsRepository';

/**
 * Model Representing the UC work flow
 */
export class CWListerEvtGenWorkflow extends CWReadOnlyModel { //Old:: CWReadOnlyModel
  /**
   * Add this to have Outline
   *
   */
  public filterModel: CWFilterListerEvtGen;
  public tableModel: CWMenuGridModel<CWEvenementModelColl>;
  public btnBarModel: Model;
  public headerModel: Model;
  public progressBarModel: Model;

  public formModel: CWListerEvetGenOverloadedBaseModel;

  public pDiversIdCollabModel: { [key: string]: any };
  public pDiversValEGalertModel: { [key: string]: any };
  public paramDivPieceJointe: { [key: string]: any };

  public objErrors: Array<string>;
  public nObjErrors: { [key: string]: any };

  public showProfils: boolean;

  public parent: CWListerEvenementsGeneralView;
  public pendingPopulationRefresh: boolean;
  public pjPopupView: CWDialogView<CWGererPiecesJointesView>;
  public COLLABORATEUR: string;
  public RESPONSABLE: string;

  public evtGenTypo: CWListerEvtGenModel;

  public evenementsColl: CWEvenementModelColl;
  public model: CWReadOnlyModel;
  public volet: CWVoletView;

  public isSelectedProfils: boolean;
  public module: string;
  public $appendTo: JQuery; //pour les messages


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.module = objs.appRt.workflow.get("usecase") || (options.usecase ? options.usecase : (options.module ? options.module : ((options.context && options.context.ctxEcran) ? options.context.ctxEcran : null)));
    if (options && options.context) {
      this.context = options.context;
    }
    this.usecase = this.module;
    this.context = options.context;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    this.filterModel = null;
    this.tableModel = null;
    this.btnBarModel = null;
    this.headerModel = null;
    this.progressBarModel = null;
    // Retrieve parametre divers for th collab info (idCollab)
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    // Retrieve parametre divers for th collab info (idCollab)
    this.pDiversValEGalertModel = GLOBAL_DATA.paramDivers.get("ValEGalert");
    // Get attachements
    this.paramDivPieceJointe = GLOBAL_DATA.paramDivers.get("pJointe");
    //array and object errors
    this.objErrors = []; //code: ,type: Aceppte/Refuser , message:""
    this.nObjErrors = {};
    this.nObjErrors.nbDemandes = 0;
    this.nObjErrors.nbDemandesAbandonee = 0;
    this.nObjErrors.nbErrors = 0;
    this.showProfils = false;
    this.isSelectedProfils = false;
    this.updateOptions(options);
    //****** --- Old CWformListerEvetGenWorkFlow

    // register sync events
    this._registerSyncEvents();
    this.COLLABORATEUR = "Collaborateur";
    this.RESPONSABLE = "Responsable";
    this.set("ready", false);
  }

  public updateOptions(options?: { [key: string]: any }): void {
    if (options && options.context) {
      this.context = options.context;
    }
    if (options && options.parent) {
      this.parent = options.parent;
      // Declare events consumers
      this.parent.workflow.filterModel.off("search");
      this.parent.workflow.filterModel.on("search", this._filterTable, this);
      this.parent.workflow.btnBarModel.off("btn:click");
      this.parent.workflow.btnBarModel.on("btn:click", this._buttonAction, this);
      this.parent.workflow.filterModel.off("clear");
      this.parent.workflow.filterModel.on("clear", this._clearFilter, this);
    }
    if (options && options.context) {
      this.module = this.context.ctxEcran;
      if (!this.getHabContext()) {
        this.setHabContext(new CWHabilitationContext());
      }
      this.updateHabContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces,
        natGest: ""
      });
    }
  }

  public checkShowProfils(comboProfilCollection: CWCombosColl): void {
    if (this.context && this.context.ctxValidation === true && comboProfilCollection.length > 1 && (!this.context.ctxCollab || !this.context.ctxCollab.matricule)) {
      this.showProfils = true;
    } else {
      this.showProfils = false;
    }
  }

  _getDistinctProfils(collection: CWEvenementModelColl): Array<{ [key: string]: any }> {
    let profils: Array<{ [key: string]: any }> = [];
    if (collection.models) {
      _.each(collection.models, (model) => {
        const modelProfils = model.get("profils");
        if (modelProfils && modelProfils.length > 0) {
          profils = _.union(profils, modelProfils);
        }
      });
      profils = _.uniq(profils, false, (p: { [key: string]: any }) => {
        return p.code;
      });
    }
    return profils;
  }



  /**
   * Sets the context
   */
  setContext(context: { [key: string]: any }, callback?: () => void): void {
    this.context = context;
    this.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationAcces });
    this.tableModel.coll.setHabContext(this.getHabContext());
    this.setUp(callback);
  }

  setUpTable(callback: () => void): void {

    const params: { [key: string]: any } = {};
    let statutFilter = "A,D,H,I,R,T";
    if (this.context && !CWSTR.isBlank(this.context.ctxFiltreApplique)) {
      if (_.isArray(this.context.ctxFiltreApplique)) {
        statutFilter = this.context.ctxFiltreApplique.join(",");
      } else {
        statutFilter = this.context.ctxFiltreApplique;
      }
    }
    if (!CWSTR.isBlank(statutFilter)) {
      params.statut = statutFilter;
    }

    let matriculeFilter = "";
    if (this.context && !this.context.ctxGestionCollective && !CWSTR.isBlank(this.context.ctxCollab)) {
      matriculeFilter = this.context.ctxCollab.matricule;
    }
    if (!CWSTR.isBlank(matriculeFilter)) {
      params.matricule = matriculeFilter;
    }
    // Load data
    this.tableModel.coll.applyFilter(params);
    this.filterModel.filterDefaultValues = params;
    this.tableModel.coll.fetch({
      success: () => {
        this.evenementsColl = this.tableModel.coll.clone();
        this.refreshList();
        this.trigger("manageUnselected", true);
        if (callback) {
          callback();
        }
      },
      silent: true
    });
    this.set("ready", true);
  }

  setUp(callback: () => void): void {
    if (this.context.ctxUtilisateur === "Collaborateur") {
      this.setUpTable(callback);
    } else {
      const params: { [key: string]: any } = { "statut": "D,I", "ctxvalidation": true, "activwkf": true };

      if (this.context.ctxUtilisateur === "Responsable" && (this.context.ctxEcran.indexOf("agenda") >= 0 || this.context.ctxEcran === "suivicollab") && this.context.ctxValidation) {
        // Return all evenements even those that are already validated
        params.activwkf = false;
      }

      if (this.context.ctxUtilisateur === "Responsable" && !this.context.ctxGestionCollective) {
        params.matricule = this.context.ctxCollab.matricule;
      }
      if (!CWSTR.isBlank(this.context.ctxFiltreApplique)) {
        if (_.isArray(this.context.ctxFiltreApplique)) {
          params.statut = this.context.ctxFiltreApplique.join(",");
        } else {
          params.statut = this.context.ctxFiltreApplique;
        }
      }

      // Load data
      this.tableModel.coll.applyFilter(params);
      this.filterModel.filterDefaultValues = params; //Save for restore if we do an a filter clean
      this.tableModel.coll.usePopulation = (objs.appRt.workflow.get("zone") === "resp");
      this.tableModel.coll.fetch({
        success: () => {
          this._numberEtat();
          this.trigger("clean:comboProfil");
          if (!CWSTR.isBlank(this.tableModel)) {
            this.evenementsColl = this.tableModel.coll.clone();
          }
          if (this.parent) {
            this.parent.form.modelsEvts = this.tableModel.coll.models;
          }
          this.refreshList();
          if (callback) {
            callback();
          }
          this.trigger("manageTable");
        },
        silent: true
      });
      this.set("ready", true);
    }
  }

  private _clearFilter(): void {
    const filter: { [key: string]: any } = this.filterModel.filterDefaultValues;

    this.isSelectedProfils = false;
    this.tableModel.coll.applyFilter(filter);
    this.filterModel.trigger('clearFilter');
    if (!this.gridCanBeDisplayed()) {
      this.tableModel.trigger('hideGrid');
    }
  }

  _buttonAction(buttonId: string): void {
    this._buttonActionWithCallback(buttonId, this._managePendingSyncEvents);
  }

  _buttonActionWithCallback(buttonId: string, callback: () => void): void {
    LOG.debug("Button clicked in devenements : " + buttonId);
    switch (buttonId) {
      case "save": {
        const evenements = this.tableModel.coll;
        let areConditionsReady = true;

        this.formModel.trigger("cleanGridErrors");
        this._clearArrays();
        _.each(evenements.models, (evenement) => {
          if (evenement.evtEtatValid === "R" && CWSTR.isBlank(evenement.get("commentaire"))) {
            areConditionsReady = false;
          }
        });

        const areProfilsReady = this._checkProfils();

        if (areProfilsReady) {
          if (areConditionsReady !== true) {
            CWMSGS.showError(i18n.t('messages:GT_1102'), null, this.$appendTo);
          } else {
            this.btnBarModel.set("mode", "R", { silent: true });
            this.btnBarModel.trigger("hide:save");
            this.btnBarModel.trigger("hide:revert");
            (UTILS.usecase_loading_exception as any)[this.module] = true;
            this._launchProcess(() => {
              (UTILS.usecase_loading_exception as any)[this.module] = false;
              this.btnBarModel.set("mode", "R", { silent: true });
              this.btnBarModel.trigger("hide:save");
              this.btnBarModel.trigger("hide:revert");
              this.tableModel.trigger("finish:proccess");
              if (typeof callback === "function") {
                callback.call(this);
              }
            });
          }
        } else {
          this.tableModel.coll.trigger("reset");
        }
        break;
      }
      case "revert":
        _.each(this.tableModel.coll.models, (rowModel: CWListerEvtGenModel) => {
          rowModel.set("commentaire", "");
          rowModel.evtEtatValid = null;
        });
        this.tableModel.coll.trigger("revert:table");
        this.tableModel.multiselectColl.reset(null);
        this.btnBarModel.set("mode", "R");
        this.formModel.trigger("resetSortableColumns");
        if (typeof callback === "function") {
          callback.call(this);
        }
        break;
      default:
      //Nothing
    }
  }

  _filterTableTable(filter: { [key: string]: any }): void {
    const tableColl = this.tableModel.coll;

    if (CWSTR.isBlank(filter.profils)) {
      filter.profils = "";
    }
    if (CWSTR.isBlank(filter.statut)) {
      if (_.isArray(this.context.ctxFiltreApplique)) {
        filter.statut = this.context.ctxFiltreApplique.join(",");
      } else {
        filter.statut = this.context.ctxFiltreApplique;
      }
    }
    filter.matricule = this.context.ctxCollab.matricule;
    tableColl.applyFilter(filter);
    tableColl.fetch({
      success: () => {
        this.evenementsColl = this.tableModel.coll.clone();
        this.refreshList();
      },
      silent: true
    });
  }

  _filterTable(filter: { [key: string]: any }, isProfil: boolean): void {
    if (this.context.ctxUtilisateur === "Collaborateur") {
      this._filterTableTable(filter);
    } else {
      const tableColl = this.tableModel.coll;
      const tmpParams = tableColl.params;
      let lFilter = filter;

      lFilter.ctxvalidation = true;
      if (!CWSTR.isBlank(this.parent.context.ctxCollab.matricule)) {
        lFilter.matricule = this.parent.context.ctxCollab.matricule;
      }
      if (this.context.ctxUtilisateur === "Responsable" && (this.context.ctxEcran.indexOf("agenda") >= 0 || this.context.ctxEcran === "suivicollab") && this.context.ctxValidation) {
        lFilter.activwkf = false;
      } else {
        lFilter.activwkf = true;
      }
      if (isProfil === true) {
        if (CWSTR.isBlank(lFilter.profils)) {
          lFilter.profils = "";
        }
        lFilter = _.extend(lFilter, _.omit(tmpParams, "profils"));
      } else if (_.keys(tmpParams).indexOf("profils") !== (-1)) {
        lFilter.profils = tmpParams.profils;
      }
      if (_.keys(lFilter).indexOf("statut") === (-1)) {
        if (this.context && !CWSTR.isBlank(this.context.ctxFiltreApplique)) {
          lFilter.statut = this.context.ctxFiltreApplique;
        } else {
          lFilter.statut = "D,I";
        }
      }
      LOG.debug("Filter table " + JSON.stringify(lFilter));
      tableColl.applyFilter(lFilter);
      tableColl.fetch({
        success: () => {
          this._numberEtat();
          this.evenementsColl = this.tableModel.coll.clone();
          this._clearArrays();
          this.refreshList();
        },
        silent: true
      });
    }
  }

  _setNewProfil(coll: CWEvenementModelColl, profil: string): void {
    _.each(coll.models, (model) => {
      if (model.get("profils").length > 1) {
        model.selectedProfil = {
          code: profil,
          libelle: ""
        };
      } else {
        model.selectedProfil = model.get("profils")[0];
      }
    });
  }

  _checkProfils(): boolean {
    let areProfilsReady = true;

    _.each(this.tableModel.coll.models, (evtGen) => {
      if ((evtGen.evtEtatValid === "R" || evtGen.evtEtatValid === "A") && (!evtGen.isValid())) {
        areProfilsReady = false;
      } else {
        evtGen.profilErrors = null;
      }
    });
    return areProfilsReady;
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
  }

  _launchProcess(callback: () => void): void {
    const evtGen = this.tableModel.coll;
    const progressBarModel = this.formModel.get("progressBarModel");

    progressBarModel.set("max", evtGen.nbEvtEtatValid);
    progressBarModel.set("treatedSuccess", 0);
    progressBarModel.set("value", 0);
    // Reset index saved for pause/play and booleans
    CWSTR.setElValue(this.formModel.get("progressBarModel"), "stop", false);
    // items will be treated sequentially
    this._processItem(progressBarModel, 0, 0, callback);
  }

  _processItem(progressBarModel: CWListerEvetGenProgressBarModel, index: number, nbItemExecuted: number, callback: () => void): void {
    // If stop process button is clicked we show a message, else we continue the process
    if (CWSTR.getElValue(this.formModel.get("progressBarModel"), "stop") === true) {

      CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1110'), (result) => {
        if (result === "Y") { //Stop the process
          this.formModel.nObjErrors.nbDemandesAbandonee = this.formModel.get("progressBarModel").get("max") - this.formModel.get("progressBarModel").get("treatedSuccess");
          this._isValidationComplet(callback, true);
        } else { //Process continues
          CWSTR.setElValue(this.formModel.get("progressBarModel"), "stop", false);
          this._executerItem(progressBarModel, index, nbItemExecuted, callback);
        }
      }, null, null, null, this.$appendTo);
    } else {
      this._executerItem(progressBarModel, index, nbItemExecuted, callback);
    }
  }

  /**
   * Execute the action in a collaborator and continues the recursion if there are more to treat.
   */
  _executerItem(progressBarModel: CWListerEvetGenProgressBarModel, index: number, nbItemExecuted: number, callback: () => void): void {
    const evtGenColl = this.tableModel.coll;
    const evtGen = evtGenColl.models[index];
    let action = "";
    if (!CWSTR.isBlank(evtGen)) {
      action = evtGen.evtEtatValid;
    }

    // Treat a collaborator if it has been selected in the collection, else we continue the process
    if (!CWSTR.isBlank(action)) {
      const itemVal = this._buildModelToVal(evtGen, action);
      const originalOptions = {
        success: (): void => {
          this.formModel.nObjErrors.nbDemandes++;
          evtGenColl.nbEvtEtatValid--;
          const treatedSuccess = progressBarModel.get("treatedSuccess");
          progressBarModel.set("treatedSuccess", treatedSuccess + 1);
          progressBarModel.set("value", ++nbItemExecuted);
          index++;
          if (this._isValidationComplet(callback, false) === false) {
            this._processItem(progressBarModel, index, nbItemExecuted, callback);
          }
        },
        error: (model?: CWAccepterEvtGen | CWRefuserEvtGen, xhf?: JQueryXHR): void => {
          const evtGenColl = this.tableModel.coll;
          const evtGen = evtGenColl.models[index];
          const action = evtGen.evtEtatValid;

          this._manageError(model, originalOptions, xhf, evtGen, action, () => {
            index++;
            this.formModel.nObjErrors.nbDemandes++;
            evtGenColl.nbEvtEtatValid--;
            const treatedSuccess = progressBarModel.get("treatedSuccess");
            progressBarModel.set("treatedSuccess", treatedSuccess + 1);
            progressBarModel.set("value", ++nbItemExecuted);

            if (this._isValidationComplet(callback, false) === false) {
              this._processItem(progressBarModel, index, nbItemExecuted, callback);
            }
          });
        }
      };

      itemVal.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationValidation });
      itemVal.save(null, originalOptions); //end save
    } else {
      index++;
      if (this._isValidationComplet(callback, false) === false) {
        this._processItem(progressBarModel, index, nbItemExecuted, callback);
      }
    }
  }

  _manageError(model: CWAccepterEvtGen | CWRefuserEvtGen, options: { [key: string]: any }, xhr: any, evtGen: CWListerEvtGenModel, action: string, callback: () => void): void {
    let customMsg: { [key: string]: any } = {};
    const status = xhr.status;

    try {
      if (!CWSTR.isBlank(xhr.oldResponseText)) {
        customMsg = JSON.parse(xhr.oldResponseText);
      } else {
        customMsg = JSON.parse(xhr.responseText);
      }
    } catch (error) {
      // ignore
    }

    if (status === 406 || status === 500 || xhr.oldStatus === 406 || xhr.oldStatus === 500) {
      if (!CWSTR.isBlank(customMsg.titre) && !CWSTR.isBlank(customMsg.message)) {
        const error: { [key: string]: any } = {};
        switch (customMsg.titre) {
          case "i18n_alert":
          case "i18n_error":
          case "i18n_information":
            error.code = evtGen.get("id");
            error.message = customMsg.message;
            error.type = action;

            this.formModel.nObjErrors.nbErrors++;
            this.formModel.objErrors.push(error);

            xhr.omit = true;

            if (callback) {
              callback();
            }
            break;
          case "i18n_confirmation":
          case "i18n_question":
            xhr.omit = true;
            // notification message returned by server that needs processing...
            CWMSGS.showNotification(customMsg, (result: string): void => {
              switch (result) {
                case "ABORT":
                  this.formModel.nObjErrors.nbDemandesAbandonee++;
                  if (callback) {
                    callback();
                  }
                  break;
                default:
                  options.url = Configuration.restRoot + "/rest/" + result;
                  model.save(null, options);
              }
            }, null, this.$appendTo);
            break;
          default:
          //Nothing
        }
      }
    }
  }

  _clearArrays(): void {
    this.formModel.objErrors = []; //code: ,type: Aceppte/Refuser , message:""
    this.formModel.nObjErrors = {};
    this.formModel.nObjErrors.nbDemandes = 0;
    this.formModel.nObjErrors.nbDemandesAbandonee = 0;
    this.formModel.nObjErrors.nbErrors = 0;
  }

  /**
   * Build the correct model to execute it depends on the action selected.
   */
  _buildModelToVal(evtGen: CWListerEvtGenModel, action: string): CWAccepterEvtGen | CWRefuserEvtGen {
    const evenement = evtGen.get("evenement");
    const model = action === "A" ? new CWAccepterEvtGen() : new CWRefuserEvtGen();

    model.setHabContext(new CWHabilitationContext({
      onglet: this.module,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: "M"
    }));

    model.id = evenement;
    model.set("commentaire", evtGen.get("commentaire"));
    model.set("droit", "N");

    const profils: Array<{ [key: string]: any }> = evtGen.get("profils");
    const selectedProfil = evtGen.selectedProfil;

    const profil = _.find(profils, function (p) {
      return String(p.code) === String(selectedProfil.code);
    });

    model.set("profil", profil);
    return model;
  }

  /**
   * Build the correct model to execute it depends on the action selected.
   */
  _isValidationComplet(callback: () => void, forceComplete: boolean): boolean {
    let isValidationFinish = false;

    // If the number of collaborators validated is the total we show the report table.
    if (this.tableModel.coll.nbEvtEtatValid === 0 || forceComplete === true) {
      this.tableModel.coll.trigger("display:rightText", "hide");
      if (callback) {
        callback();
      }
      isValidationFinish = true;
    }
    return isValidationFinish;
  }

  // register listener to syncEvents type
  _registerSyncEvents(): void {
    this.pendingPopulationRefresh = false;
    this.stopListening(objs.appRt.workflow, "changed:usecase");
    this.listenTo(objs.appRt.workflow, "changed:usecase", this._managePendingSyncEvents);
    this.stopListening(objs.appRt.workflow.syncModel, "change:population");
    this.listenTo(objs.appRt.workflow.syncModel, "change:population", this._manageChangePopulationEvent);
  }

  // execute pending syncEvents
  _managePendingSyncEvents(): void {
    if (this.pendingPopulationRefresh === true) {
      this._manageChangePopulationEvent();
    }
  }

  // syncEvent : populations
  _manageChangePopulationEvent(): void {
    //Dans "valevt", le changement de la population est fait dans valvet.workflow et il est créé un nouveau workflow
    //il ne faut pas executer la méthode _changePopulation
    this.pendingPopulationRefresh = true;
  }

  // _changePopulation(): void {
  //   this.pendingPopulationRefresh = false;
  //   if (this.btnBarModel.get("mode") === "E") {
  //     this.pendingPopulationRefresh = true;
  //     CWMSGS.showInfo(i18n.t('messages:GL_1034'), this.$appendTo);
  //   } else {
  //     if (this.tableModel) {
  //       this.tableModel.coll.resetPagination();
  //       this.tableModel.coll.fetch({
  //         success: () => {
  //           this._numberEtat();
  //           this.evenementsColl = this.tableModel.coll.clone();
  //           this.refreshList();
  //         },
  //         silent: true
  //       });
  //     }
  //   }
  // }

  refreshList(): void {
    const typoCode = this.context.ctxTypologieEvenement ? this.context.ctxTypologieEvenement.code : null;

    if (this.parent) {
      this.parent.wkfDemandes = null;
    }
    // close pieces jointes popup dialog
    if (this.pjPopupView) {
      this.pjPopupView.close();
    }
    /* Initialize table after collection is retrieved because
     *  - Columns are dynamic
     *  - Columns are different depending on typologie
     *  - Columns from a typologie can be changed from Referentiel
     */
    if (!CWSTR.isBlank(this.parent)) {
      this.parent.form._initTable(typoCode);
    }
    if (!CWSTR.isBlank(this.tableModel)) {
      this.tableModel.coll.trigger("reset");
      if (this.tableModel.coll.length === 0) {
        this.formModel.trigger("isEmpty", this);
      }
    }
  }

  refreshListTable(): void {

    this.parent.wkfDemandes = null;

    // close pieces jointes popup dialog
    if (this.pjPopupView) {
      this.pjPopupView.close();
    }

    this.parent.form._initTable(this.context.ctxTypologieEvenement.code);

    if (this.tableModel.coll.length === 0 && this.context.ctxEcran === "suivicollab") {
      this.parent.model.trigger("emptyList");
    }
  }

  _numberEtat(): void {
    if (!CWSTR.isBlank(this.tableModel)) {
      this.tableModel.coll.nbEvtEtatAcceptes = {}; //acceptés
      this.tableModel.coll.nbEvtEtatDemandes = {}; //Pour valider
      this.tableModel.coll.nbEvtEtatRefuses = {}; //Réfusés
      if (this.tableModel && this.tableModel.coll && this.tableModel.coll.models && this.tableModel.coll.models.length > 0) {
        const models = this.tableModel.coll.models;
        for (let i = 0; i < models.length; i++) {
          const code = models[i].get("code");

          switch (models[i].get("statut").code) {
            case "A":
            case "H":
              if (CWSTR.isBlank(this.tableModel.coll.nbEvtEtatAcceptes[code])) {
                this.tableModel.coll.nbEvtEtatAcceptes[code] = 0;
              }
              this.tableModel.coll.nbEvtEtatAcceptes[code]++;
              break;
            case "R":
              if (CWSTR.isBlank(this.tableModel.coll.nbEvtEtatRefuses[code])) {
                this.tableModel.coll.nbEvtEtatRefuses[code] = 0;
              }
              this.tableModel.coll.nbEvtEtatRefuses[code]++;
              break;
            case "I":
            case "D":
              if (CWSTR.isBlank(this.tableModel.coll.nbEvtEtatDemandes[code])) {
                this.tableModel.coll.nbEvtEtatDemandes[code] = 0;
              }
              this.tableModel.coll.nbEvtEtatDemandes[code]++;
              break;
            default:
            //Nothing
          }
        }
      }
    }
  }

  public showVolet(model: { [key: string]: any }, context: { [key: string]: any }): void {
    if (this.volet) {
      this.volet.viewData = _.extend({}, this.volet.viewData, {
        "context": context,
      });
      this.volet.open();
    }
  }

  public gridCanBeDisplayed(): boolean {
    return (!this.showProfils || (this.showProfils && this.isSelectedProfils));
  }
}
