import * as Backbone from 'Backbone';
import TPLPanneauAffectationPpal from '../panneauAffectationPpal.tpl.html';
import { CWAffectationEnCoursModel } from '../models/cwAffectationEnCours.model';
import { CWAffectationPrincipColl } from '../models/cwAffectationPrincip.collection';
import { CWBaseGridColums, CWBaseGridOptions } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


/**
 * View of Brique 'Resultats'
 */
export class CWPanneauAffectationPpalView extends Backbone.View<Backbone.Model>{

    template: (params?: any) => string;
    collAffPrincipal: CWAffectationPrincipColl | any;
    parent: any;
    structureSelected: any;
    usePopulation: boolean;
    habContext: CWHabilitationContext;
    table: CWDataGridView;

    constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
        params = params || {};
        // params.events = _.extend({
        //     "click button": "_clickListener"
        // }, params.events);
        super(params);
        this.template = TPLPanneauAffectationPpal;
        this.usePopulation = params.usePopulation || false;
        if (CWSTR.isBlank(params.habContext)) {
            this.habContext = new CWHabilitationContext({
                onglet: "baffec",
                foncCour: "COL_AFFPRINC.V", // eslint-disable-line
                natGest: "" // eslint-disable-line
            });
        } else {
            this.habContext = params.habContext;
        }
        this.collAffPrincipal = params.coll || null;
        if (CWSTR.isBlank(this.collAffPrincipal)) {
            this.collAffPrincipal = new CWAffectationPrincipColl();
            this.collAffPrincipal.usePopulation = this.usePopulation;
            this.collAffPrincipal.setHabContext(this.habContext);
        }
        this.parent = params.parent || null;
        this.table = this._initTable();
    }

    render(): CWPanneauAffectationPpalView {
        const json = { 'i18n': i18n };

        this.$el.html(this.template(json));
        this.$el.find(".table_AutresAffectationsPrincipales").html(this.table.el);
        this.table.render();
        this.$el.css({ "max-height": "232px", "max-width": "600px", "width": "580px" });
        this.$el.find(".phx-th00").css({ "padding": "0px" });
        //this.model.trigger("panneau:ready");
        return this;
    }

    _initTable(): CWDataGridView {
        const tableModel = new CWDataGridModel({
            coll: this.collAffPrincipal
        });
        const tblColumns: Array<CWBaseGridColums> = [
            { title: "", property: "itemcode", width: 12 },
        ];
        const table = new CWDataGridView({
            id: "baffec_tablePrincip",
            columns: tblColumns,
            model: tableModel,
            showFilteredRowsInTitle: false,
            title: "",
            gridHeightType: "auto",
            maxVisibleRows: 4,
            habilitations: "COL_AFFPRINC.V",
            selectable: false,
            sansSelection: true
        } as CWBaseGridOptions);

        tableModel.coll.usePopulation = this.usePopulation;
        table.setSortableColumns([]);
        //To show a different value in the cell
        table.cellRenderers["itemcode"] = (model: CWAffectationEnCoursModel): string => {
            let lRtn = "";

            if (model.get("datedeb")) {
                lRtn = i18n.t('common:baffec.colonneDu') + " " + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A"));
            }
            if (model.get("datefin")) {
                const lText = i18n.t('common:baffec.colonneAu').toLowerCase() + " " + CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A"));

                if (!CWSTR.isBlank(lRtn)) {
                    lRtn += " " + lText;
                } else {
                    lRtn = lText;
                }
            }
            if (!CWSTR.isBlank(lRtn)) {
                lRtn = "<span class='cw-texteAllege'>" + lRtn + "</span>";
            }
            if (!CWSTR.isBlank(model.get("itemcode")) || !CWSTR.isBlank(model.get("itemlib"))) {
                let lStruct = "";
                if (!CWSTR.isBlank(lRtn)) {
                    lStruct += ", ";
                }
                lStruct += this._formatStructure(model);
                if (!CWSTR.isBlank(lStruct)) {
                    lRtn += "<span class='cw-texteNormal'>" + lStruct + "</span>";
                }
            }
            return lRtn;
        };
        // set habilitations context that the table collection will use
        tableModel.coll.setHabContext(this.habContext);
        return table;
    }

    _formatStructure(model: CWAffectationEnCoursModel): string {
        let lRtn = "";

        if (!CWSTR.isBlank(model)) {
            const lCode = !CWSTR.isBlank(model.get("itemcode")) ? model.get("itemcode") : "";
            const lLibelle = !CWSTR.isBlank(model.get("itemlib")) ? model.get("itemlib") : "";
            const lFormat = model.get("format");
            let lText = "";

            switch (lFormat) {
                case 2:
                    lText = lCode;
                    if (!CWSTR.isBlank(lLibelle)) {
                        if (!CWSTR.isBlank(lText)) {
                            lText += " ";
                        }
                        lText += "(" + lLibelle + ")";
                    }
                    lRtn = lText;
                    break;
                case 3:
                    lRtn = lLibelle;
                    break;
                case 4:
                    lRtn = lCode;
                    break;
                default://comme case 1
                    lText = lLibelle;
                    if (!CWSTR.isBlank(lCode)) {
                        if (!CWSTR.isBlank(lText)) {
                            lText += " ";
                        }
                        lText += "(" + lCode + ")";
                    }
                    lRtn = lText;
            }

        }
        return lRtn;
    }
}