import * as Backbone from 'Backbone';
import { CWLoginLicenseModel } from './core/components/login/cwLoginLicense.model';
import { CWProfilesModel } from 'common/profilutilisateur/models/cwProfiles.model';
import { CWThemeRollConfigModel } from './app/models/cwThemeRollConfig.model';

export const GLOBAL_DATA: {
    paramDivers?: Backbone.Collection; // ParametresDiversColl
    typologies?: Backbone.Collection; //  TypoStandardColl
    typoevtgen?: Backbone.Collection; // TypoEvtGenColl
    licences?: CWLoginLicenseModel; // CWLoginLicenseModel
    theme?: CWThemeRollConfigModel; // CWThemeRollConfigModel
    profiles?: CWProfilesModel; // CWProfilesModel 
    [key: string]: any;
} = {};