import { ActiviteTreeModel } from './activiteTree.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { STR } from 'utils/str.js';
import { TYPE } from 'tda/tda.js';

/**
 * Tree item collection
 */
export var ActiviteTreeColl = PaginatedCollection.extend({

  model: ActiviteTreeModel,

  initialize: function(params) {
    PaginatedCollection.prototype.initialize.call(this);
    this.domaine = undefined;
    this.datedebutparent = TYPE.DATE.INITIAL;
    this.niveau = undefined;
    // temporal context identifying period range
    this.datedeb = TYPE.DATE.INITIAL;
    this.datefin = TYPE.DATE.INFINITY;
    if (params) {
      if (params.domaine) {
        this.domaine = params.domaine;
      }
    }
    this.paginated = false;
  },

  setDomaine: function(domaine) {
    this.domaine = domaine;
  },

  setDomaineLibelle: function(domaineLib) {
    this.parentdomlib = domaineLib;
  },

  setParentTypelt: function(parenttypelt) {
    this.parenttypelt = parenttypelt;
  },

  setParentDateDebut: function(parentdatedeb) {
    if (STR.isBlank(parentdatedeb)) {
      this.datedebutparent = TYPE.DATE.INITIAL;
    } else {
      this.datedebutparent = parentdatedeb;
    }
  },

  setParentDateFin: function(parentdatefin) {
    if (STR.isBlank(parentdatefin)) {
      this.datefinparent = TYPE.DATE.INFINITY;
    } else {
      this.datefinparent = parentdatefin;
    }
  },

  setStructId: function(parentstraid) {
    this.parentstraid = parentstraid;
  },

  setItemId: function(itemid) {
    this.itemida = itemid;
  },

  setParentCode: function(parentcode) {
    this.parentcode = parentcode;
  },

  setVue: function(vue) {
    this.vue = vue;
  },

  setContext: function(context) {
    /*hierid (obligatoire si typelt=A pour l’élément déplié)
			contenu (obligatoire)
			dom (obligatoire) (un seul domaine)
			vue (obligatoire)
			avec_inapt (obligatoire)
			comp_exacte (obligatoire)
			ctxutil(facultatif) (en exploitation, si non renseigné on prendra le contexte standard)
			typevt (facultatif)
			datedeb (facultatif)
			datefin (facultatif)
			search (obligatoire) ?¿?¿??
			perdeb (facultatif)
			pertot (facultatif)
			matric (facultatif)
			fltstruct (facultatif)
			heritage (facultatif)*/

    this.vue = context.vue;
    this.hierid = context.hierid;
    this.contenu = context.contenu;
    this.dom = context.dom;
    this.avec_inapt = context.avec_inapt;
    this.comp_exacte = context.comp_exacte;
    this.ctxutil = context.ctxutil;
    this.typevt = context.typevt;
    this.datedeb = context.datedeb;
    this.datefin = context.datefin;
    this.search = context.search;
    this.perdeb = context.perdeb;
    this.pertot = context.pertot;
    this.matric = context.matric;
    this.fltstruct = context.fltstruct;
    this.heritage = context.heritage;
    this.act_unique = context.act_unique;
    this.emploidutemps = context.emploidutemps;
    this.code = context.code;
    this.famille = context.famille;
    this.libelle = context.libelle;
    this.flt_hiertypeniv = context.flt_hiertypeniv;
    this.flt_ratthorsregr = context.flt_ratthorsregr;
    if (context.medical === true) {
      this.medical = true;
    }
    this.filter = context.filter;
    this.typegest = context.ctxTypeGest;
    this.horstypegest = _.isBoolean(context.ctxHorsTypeGest) ? context.ctxHorsTypeGest : false;
    //pour le mode Explotation et grille non médicals
    this.horsStructExplotation = context.horsStructExplotation;
    this.ctxfeuilletemps = context.ctxfeuilletemps;
    this.ctxcptana = context.ctxcptana;
  },

  setPerutil: function(perutil) {
    this.perutil = perutil;
  },

  setNiveau: function(niveau) {
    this.niveau = niveau;
  },

  url: function() {
    var url = Configuration.restRoot + "/rest/composant/selecteur/activite/complet/element";
    var params = "?vue=" + this.vue;
    var i;

    /*
     * PARAMS
     *
        hierid (obligatoire si typelt=A pour l’élément déplié)
    	contenu (obligatoire)
    	dom (obligatoire) (un seul domaine)
    	vue (obligatoire)
    	avec_inapt (obligatoire)
    	comp_exacte (obligatoire)
    	ctxutil(facultatif) (en exploitation, si non renseigné on prendra le contexte standard)
    	typevt (facultatif)
    	datedeb (facultatif)
    	datefin (facultatif)
    	search (obligatoire) ?¿?¿??
    	perdeb (facultatif)
    	pertot (facultatif)
    	matric (facultatif)
    	fltstruct (facultatif)
    	heritage (facultatif)
     */

    if (!STR.isBlank(this.hierid)) {
      params += "&hierid=" + encodeURIComponent(this.hierid);
    }
    if (!STR.isBlank(this.contenu)) {
      params += "&contenu=" + encodeURIComponent(this.contenu);
    }
    if (!STR.isBlank(this.dom)) {
      params += "&dom=" + encodeURIComponent(this.dom);
    }
    if (!STR.isBlank(this.avec_inapt)) {
      params += "&avec_inapt=" + encodeURIComponent(this.avec_inapt);
    }
    if (!STR.isBlank(this.comp_exacte)) {
      params += "&comp_exacte=" + encodeURIComponent(this.comp_exacte);
    }
    if (!STR.isBlank(this.ctxutil)) {
      params += "&ctxutil=" + encodeURIComponent(this.ctxutil);
    }
    if (!STR.isBlank(this.typevt)) {
      params += "&typevt=" + encodeURIComponent(this.typevt);
    }
    if (!STR.isBlank(this.datedeb)) {
      params += "&rechdeb=" + encodeURIComponent(this.datedeb);
    }
    if (!STR.isBlank(this.datefin)) {
      params += "&rechfin=" + encodeURIComponent(this.datefin);
    }
    if (!STR.isBlank(this.search)) {
      params += "&search=" + encodeURIComponent(this.search);
    }
    if (!STR.isBlank(this.perdeb)) {
      params += "&perdeb=" + encodeURIComponent(this.perdeb);
    }
    if (!STR.isBlank(this.pertot)) {
      params += "&pertot=" + encodeURIComponent(this.pertot);
    }
    if (!STR.isBlank(this.matric)) {
      params += "&matric=" + encodeURIComponent(this.matric);
    }
    if (!STR.isBlank(this.fltstruct)) {
      params += "&fltstruct=" + encodeURIComponent(this.fltstruct);
    }
    if (!STR.isBlank(this.heritage)) {
      params += "&heritage=" + encodeURIComponent(this.heritage);
    }
    if (!STR.isBlank(this.act_unique)) {
      params += "&act_unique=" + encodeURIComponent(this.act_unique);
    }
    if (!STR.isBlank(this.code)) {
      params += "&code=" + encodeURIComponent(this.code);
    }
    if (!STR.isBlank(this.famille)) {
      params += "&famille=" + encodeURIComponent(this.famille);
    }
    if (!STR.isBlank(this.libelle)) {
      params += "&libelle=" + encodeURIComponent(this.libelle);
    }
    if (!STR.isBlank(this.emploidutemps)) {
      params += "&emploidutemps=" + encodeURIComponent(this.emploidutemps);
    }
    if (!STR.isBlank(this.flt_hiertypeniv)) {
      params += "&flt_hiertypeniv=" + encodeURIComponent(this.flt_hiertypeniv);
    }
    if (!STR.isBlank(this.flt_ratthorsregr)) {
      params += "&flt_ratthorsregr=" + this.flt_ratthorsregr;
    }
    if (this.medical === true) {
      params += "&medical=true";
    }
    if (this.contenu === "R" && !_.isNull(this.typegest) && !STR.isBlank(this.typegest)) { //il faut ne pas utiliser CWSTR.isBlank parce qu'on doit envoyer le cas que la valeur soit "".
      //les valeurs possibles sont : "" et la valeur de ctxTypeGest
      params += "&typegest=" + encodeURIComponent(this.typegest);
      //uniquement s'il y a contexte de typegest, on enverra l'autre contexte ctxHorsTypeGest ->horstypegest
      params += "&horstypegest=" + this.horstypegest;
    }
    if (this.horsStructExplotation === true) { //pour le mode Explotation et grille non médicals
      params += "&horsStruct=true";
    }
    if (this.ctxfeuilletemps === true) {
      params += "&ctxfeuilletemps=true";
      if (!STR.isBlank(this.ctxcptana)) {
        params += "&ctxcptana=" + encodeURIComponent(this.ctxcptana)
      }
    }
    //facultatif
    if (this.niveau && this.niveau > 0) {
      //The level is not the root so we have to add the id to the url
      url = url + "/" + encodeURIComponent(this.parenttypelt + "," + this.parentcode + "," + this.datedebutparent + "," + this.datefinparent);
    }
    if (!STR.isBlank(this.filter)) {
      var filterLength = 0;

      delete this.filter.code;
      delete this.filter.libelle;
      filterLength = _.keys(this.filter).length;
      for (i = 0; i < filterLength; i++) {
        var index = _.keys(this.filter)[i];

        if (!STR.isBlank(this.filter[index].code)) {
          params += "&codeniv" + index + "=" + encodeURIComponent(this.filter[index].code);
        }
        if (!STR.isBlank(this.filter[index].libelle)) {
          params += "&libelleniv" + index + "=" + encodeURIComponent(this.filter[index].libelle);
        }
      }
      delete this.filter;
    }
    return url + params;
  }
});
