module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="btnBar-btn-container">\n    <button type="button" class="save btn ';
 if(!CWSTR.isBlank(btnIcons.save)){ print('btn-withIcon') } 
__p+=' '+
((__t=(btnClasses['save']))==null?'':__t)+
'" value="save" data-cy="save">\n        '+
((__t=(i18n.t("common:save")))==null?'':__t)+
'';
 if(!CWSTR.isBlank(btnIcons.save)){ print(UTILS.getSVGIcon(btnIcons.save,'',16)); } 
__p+='\n    </button>\n</div>\n<div class="btnBar-btn-container">\n    <button type="button" value="revert" data-cy="revert" class="revert btn ';
 if(!CWSTR.isBlank(btnIcons.revert)){ print('btn-withIcon') } 
__p+=' '+
((__t=(btnClasses['revert']))==null?'':__t)+
'">\n        '+
((__t=(i18n.t("common:annuler")))==null?'':__t)+
'';
 if(!CWSTR.isBlank(btnIcons.revert)){ print(UTILS.getSVGIcon(btnIcons.revert,'',16)); } 
__p+='\n    </button>\n</div>\n<div class="btnBar-btn-container">\n    <button type="button" class="new btn ';
 if(!CWSTR.isBlank(btnIcons.new)){ print('btn-withIcon') } 
__p+=' '+
((__t=(btnClasses['new']))==null?'':__t)+
'" value="new" data-cy="new">\n        '+
((__t=(i18n.t("common:nw")))==null?'':__t)+
'';
 if(!CWSTR.isBlank(btnIcons.new)){ print(UTILS.getSVGIcon(btnIcons.new,'',16)); } 
__p+='\n    </button>\n</div>\n<div class="btnBar-btn-container">\n    <button type="button" class="delete btn ';
 if(!CWSTR.isBlank(btnIcons.delete)){ print('btn-withIcon') } 
__p+=' '+
((__t=(btnClasses['delete']))==null?'':__t)+
'" value="delete" data-cy="delete">\n        '+
((__t=(i18n.t("common:del")))==null?'':__t)+
'';
 if(!CWSTR.isBlank(btnIcons.delete)){ print(UTILS.getSVGIcon(btnIcons.delete,'',16)); } 
__p+='\n    </button>\n</div>\n<div class="btnBar-btn-container">\n    <button type="button" class="copy btn ';
 if(!CWSTR.isBlank(btnIcons.copy)){ print('btn-withIcon') } 
__p+=' '+
((__t=(btnClasses['copy']))==null?'':__t)+
'" value="copy" data-cy="copy">\n        '+
((__t=(i18n.t("common:copy")))==null?'':__t)+
'';
 if(!CWSTR.isBlank(btnIcons.copy)){ print(UTILS.getSVGIcon(btnIcons.copy,'',16)); } 
__p+='\n    </button>\n</div>';
}
return __p;
};
