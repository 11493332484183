import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseFormView as CommonFormView } from 'core/views/cwForm.view';
import { CWButtonBarView } from './cwButtonBar.view';
import { CWEtatsColl } from '../models/cwEtats.collection';
import { CWFilterContentView } from './cwFilterContent.view';
import { CWFilterView } from 'core/components/filter/cwFilter.view';
import { CWFormView } from './cwForm.view';
import { CWHeaderView } from './cwHeader.view';
import { CWLanceetatWorkflow } from '../models/cwLanceetat.workflow';
import { CWListeDetailPanelView } from 'core/layouts/cwListeDetailPanel.view';
import { CWNavigationView } from 'core/components/navigation/cwNavigation.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWTreeTypeGridModel } from 'core/grids/treetypegrid/cwTreeTypeGrid.model';
import { CWTreeTypeGridView } from 'core/grids/treetypegrid/cwTreeTypeGrid.view';
import { i18n } from 'src/i18n.js';

/**
 * It will paint all the tab for the UC.
 */
export class CWGeneralView extends CommonFormView {
  parent: any;
  workflow: CWLanceetatWorkflow;
  layout: any;
  filter: any;
  form: any;
  detailsHeader: any;
  navigationView: any;
  table: any;
  btnBar: any;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.className = 'lanceetat';
    super(options);
    if (options && options.parent) {
      this.parent = options.parent;
    }
    // Initializes the main parts of the UI and its workflow
    this.workflow = new CWLanceetatWorkflow({ "loc": options.loc });
    this.layout = new CWListeDetailPanelView({ "position": 24, defaultOpeningSize: "33/66", "module": this.workflow.module });
    this.filter = this._initFilter();
    this.workflow.filterModel = this.filter.model;
    this.form = this._initForm();
    this.workflow.formColl = this.form.coll;
    this.workflow.formModel = this.form.model;
    this.detailsHeader = this._initDetailsHeader();
    this.workflow.headerModel = this.detailsHeader.model;
    this.navigationView = this._initNavigationView();
    this.workflow.navigationModel = this.navigationView.model;
    this.table = this._initTable();
    this.workflow.tableModel = this.table.model;
    this.btnBar = new CWButtonBarView();
    this.workflow.btnBarModel = this.btnBar.model;
    this.listenTo(this.workflow, "closePopup", this._closePopup);
    this.listenTo(this.workflow, "checkFournisseur", this._checkFournisseur);
    this.listenTo(this.workflow.headerModel, "resize", this._resizeHeader);
  }

  _checkFournisseur(): void {
    let boolHideFournisseur = true;

    _.each(this.table.model.coll.models, (mod: { [key: string]: any }): void => {
      if (mod.hasChild === false) {
        if (mod.get("fournisseur") !== 2 && mod.get("fournisseur") !== 4) {
          boolHideFournisseur = false;
        }
      }
    });
    if (boolHideFournisseur === true) {
      this.table.setVisibleColumns(["libelle"]);
    } else {
      this.table.setVisibleColumns(["fournisseur"]);
    }
  }

  _initLayoutUC(callback?: (arg?: any) => void): void {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      }
    } else {
      this.workflow.setUp((setUpCallback: (arg?: any) => void): void => {
        //When the workflow is ready, we can render the view without concerns
        this.layout.$el.find(".l-panelA-entete").parent().remove(); //ado
        this.layout.$el.find(".l-panelA-filter").html(this.filter.render().el);
        this.layout.$el.find(".l-panelA-liste").html(this.table.el);
        this.table.render();
        this.layout.$el.find(".l-panelB-butomButtons").html(this.btnBar.render().el);
        //this.layout.$el.find(".l-panelB-buttonContainer").html(this.navigationView.render().el);
        this.layout.$el.find(".l-panelB-entete").html(this.detailsHeader.render().el);
        this.layout.$el.find(".l-panelB-content").html(this.form.render().el);
        if (this.workflow.paramExportEtat === false) {
          this.btnBar.hideButton("export");
        }
        if (callback) {
          callback(setUpCallback);
        }
        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        this.trigger("uc:ready");
      });
    }
  }

  _initTable(): CWTreeTypeGridView {
    const tableModel = new CWTreeTypeGridModel({
      coll: new CWEtatsColl()
    });
    const tblColumns = [
      { title: i18n.t('lanceetat.nom'), property: "libelle", width: 12, treecol: true },
      { title: i18n.t('lanceetat.ordre'), property: "ordre" },
      { title: i18n.t('lanceetat.commentaire'), property: "commentaire" },
      { title: i18n.t('lanceetat.nom'), property: "fournisseur", width: 12, treecol: true }
    ];
    const table = new CWTreeTypeGridView({
      id: "lanceetat_table",
      title: i18n.t('lanceetat.title_table'),
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      parentContainer: this.parent
    });

    table._manageSortingOld = table._manageSorting;
    table._manageSorting = (columnCode: string): void => {
      table._manageSortingOld(columnCode, (fresh: any): void => {
        let finded = false;

        //reset and build flat collection for tree grid
        this.workflow.flatColl.reset();
        this.workflow._arrayToCollection(fresh);
        this.table.model.coll.totalRecords = this.workflow.flatColl.length;
        this.table.model.coll.reset(this.workflow.flatColl.models);
        //search first level 2 element ( level 1 can't be selected)        
        for (let i = 0; i < this.table.model.coll.length && finded === false; i++) {
          if (this.table.model.coll.at(i).level === 2) {
            const row = this.table.model.coll.at(i);

            finded = true;
            row.trigger("row:click", row);
          }
        }
      }, true);
    };
    table.cellRenderers["libelle"] = (model: { [key: string]: any }): any => {
      const libelle = $('<span>');

      if (model.level === 1) {
        libelle.css("font-weight", "bold");
      }
      libelle.text(model.get("libelle"));
      return libelle;
    };

    table.cellRenderers["fournisseur"] = (model: { [key: string]: any }): any => {
      const libelle = $('<span>');

      if (model.level === 1) {
        libelle.css("font-weight", "bold");
      } else {
        let fournisseur = "";

        if (model.get("fournisseur") === 2 || model.get("fournisseur") === 4) {
          fournisseur = i18n.t('lanceetat.chrono');
        } else if (model.get("fournisseur") >= 3) {
          fournisseur = i18n.t('lanceetat.timeRep');
        }
        if (!CWSTR.isBlank(model.get("fournisseur"))) {
          libelle.text(model.get("libelle") + " (" + fournisseur + ")");
          return libelle;
        }
      }
      libelle.text(model.get("libelle"));
      return libelle;
    };
    table.setVisibleColumns(["libelle", "fournisseur"]);
    table.setSortableColumns(["ordre"]);
    table.height = 450;
    // override default method to show correct total number of rows
    table.overrideFilteredRowsInTitle = (): number => {
      let totalRecords = (this.table.model.coll.length || 0) - (this.workflow.flatColl.numGroupes || 0);

      if (totalRecords < 0) {
        totalRecords = 0;
      }
      return totalRecords;
    };
    return table;
  }

  _initFilter(): CWFilterView {
    const part1View = new CWFilterContentView({ workflow: this.workflow });
    const filter = new CWFilterView({
      viewPart1: part1View,
      columns: 12,
      buttonOnBottomFilterView: true,
    });

    return filter;
  }

  _initForm(): CWFormView {
    const form = new CWFormView({ workflow: this.workflow });

    return form;
  }

  _initDetailsHeader(): CWHeaderView {
    const header = new CWHeaderView();

    return header;
  }

  _initNavigationView(): CWNavigationView {
    const page = new CWNavigationView({ text: i18n.t('lanceetat.usecase'), "module": this.workflow.module });

    return page;
  }

  render(): CWGeneralView {
    this.$el.html(this.layout.render().el);
    // on récupère la couleur de la barre du spliter
    this.layout.barhandleColor = this.layout.$el.find(".ui-resizable-goto-right").css('backgroundColor');
    this.parent._setTitle(i18n.t('lanceetat.title_popup'));
    this._initLayoutUC((callback: (arg?: any) => void) => {
      if (this.parent) {
        this.parent.resize();
      }
      if (callback) {
        callback(); // ici on appelle le callback du setUp
      }
    });
    return this;
  }

  _closePopup(): void {
    this.parent.close();
  }

  _resizeHeader(): void {
    this.parent.resize();
  }
}