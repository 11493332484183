module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="dialogPersoSaveCriteria row">\n  <div class="col-lg-12" style="height: 105px">\n    <div class="form-group">\n      <span class="cw-titreBloc">'+
((__t=(i18n.t('plequipe.dialog_persosavecriteria_desc')))==null?'':__t)+
'</span>\n    </div>\n    <div class="options">\n      <div class="bloq_user form-group">\n        <div class="custom-control custom-checkbox">\n          <input id="aff_user" type="checkbox" class="aff_user custom-control-input" value="1">\n          <label for="aff_user" class="custom-control-label font-weight-bold">'+
((__t=(i18n.t('plequipe.dialog_persosavecriteria_affuser')))==null?'':__t)+
'</label>\n        </div>\n      </div>\n\n      <div class="bloq_allusers form-group">\n        <div class="custom-control custom-checkbox">\n          <input id="aff_allusers" type="checkbox" class="aff_allusers custom-control-input" value="1">\n          <label for="aff_allusers" class="custom-control-label font-weight-bold">'+
((__t=(i18n.t('plequipe.dialog_persosavecriteria_affallusers')))==null?'':__t)+
'</label>\n        </div>\n      </div>\n    </div>\n    <br>\n    <br>\n  </div>\n</div>\n\n';
}
return __p;
};
