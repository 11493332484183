import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for infocomp and defaults of activity
 */
export class CWActiviteDeplacemnetPersistenceModel extends CWBaseModel {
  public update: boolean;
  public fetchMode: boolean;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    super(options);
    this.url = (): string => {
      let url = "";
      if (this.update === true || this.fetchMode === true) {
        url = `${Configuration.restRoot}/rest/composant/activite/deplacement/${encodeURIComponent(this.id)}`;
        //url = `${Configuration.restRoot}/rest/composant/activite/deplacement/${this.get("activite")}`;
      } else {
        url = `${Configuration.restRoot}/rest/composant/activite/deplacement`;
      }
      return url;
    }
  }

  defaults(): { [key: string]: any } {
    return {
      "activite": "", // "ACT1"
      "libelle": "", // "Activité 1"
      "hierid": "",
      "straid": "",
      "typeevenement": "R", // "R" Realisee | "P" Prevue
      "matricule": null, // 9001
      "complement": "",
      "datedeb": "", // "20141114"
      "happel": null,
      "dtappel": "",
      "dtdebinter": "",
      "dtfininter": "",
      "hdebinter": null,
      "hfininter": null,
      "dureeinter": null,
      "typedeplac": {
        "code": "",
        "libelle": "",
      },
      "cptdeplac": {
        "code": "",
        "libelle": "",
      }
    }
  }
}
