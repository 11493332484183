module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n  <li class="percrit_save ui-menu-item">\n    <a href="javascript:void(0)" class="percrit_save">\n      '+
((__t=(i18n.t('agenda.percrit_save')))==null?'':__t)+
'\n    </a>\n  </li>\n  <li class="percrit_suppr ui-menu-item">\n    <a href="javascript:void(0)" class="percrit_suppr">\n      '+
((__t=(i18n.t('agenda.percrit_suppr')))==null?'':__t)+
'\n    </a>\n  </li>\n</ul>\n';
}
return __p;
};
