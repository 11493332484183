/**
 * Generic Utilities for the planning.
 * Date transformations, auxiliar functions, etc...
 */
export var UTILS_PL = {

  /**
   * Transform a Date type in String ID.
   */
  dateToId: function(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1; // added one to adjust javascipt mounths
    var year = date.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return String(year) + String(month) + String(day);
  },

  /**
   * Transform a date String ID in a Date type.
   */
  idToDate: function(str) {
    var digits = [str.substring(0, 4), str.substring(4, 6), str.substring(6, 8)];
    return new Date(digits[0], digits[1] - 1, digits[2]);
  },

  /**
   * Clones a Date.
   */
  clone: function(date) {
    return new Date(date.getTime());
  },

  /**
   * Append a rowHidden to the passed [thead/tbody/tfoot]
   */
  appendRowHidden: function(elem, colNumber, hasMoreRows) {
    var _elem = $(elem);

    var tokenTd = "td";
    if (_elem.is("thead")) {
      tokenTd = "th";
    }

    var strTd = "<" + tokenTd + ">&nbsp;</" + tokenTd + ">";
    if (hasMoreRows) {
      strTd = "<" + tokenTd + "></" + tokenTd + ">";
    }

    var strTr = "<tr class=\"rowHidden\">";
    for (var i = 0; i < colNumber; i++) {
      strTr = strTr + strTd;
    }
    strTr = strTr + "</tr>";
    _elem.append($(strTr));
  },

  /**
   * Manages the wheel scroll event movement.
   */
  mousewheelScroll: function(event, jQel) {
    //Negative DOWN, Positive UP
    var delta = 0;
    if (event.originalEvent.wheelDelta) { // mousewheel
      delta = event.originalEvent.wheelDelta; //+-120
    } else { // DOMMouseScroll
      delta = event.originalEvent.detail; //-+3 (inverse than other event)
      delta = delta * -40;
    }
    var scrollTop = jQel.scrollTop();
    jQel.scrollTop(scrollTop - Math.round(delta));
  },

  /**
   * Transforms the Horaire from dts format to Outil Format.
   */
  dtsHoraireToOutilFormat: function(dtsJSON) {
    var horJSON = {
      abrege1: dtsJSON.ab1,
      abrege2: dtsJSON.ab2,
      abrege: dtsJSON.ab4,
      abrege8: dtsJSON.ab8,
      code: dtsJSON.cod,
      dpf1: dtsJSON.f1d,
      fpf1: dtsJSON.f1f,
      dpf2: dtsJSON.f2d,
      fpf2: dtsJSON.f2f,
      famille: dtsJSON.fam,
      hclo: dtsJSON.hf,
      hormatin: dtsJSON.hm,
      hornuit: dtsJSON.hn,
      houv: dtsJSON.ho,
      horsoir: dtsJSON.hs,
      horjour: dtsJSON.hj,
      libelle: dtsJSON.lib,
      nature: dtsJSON.nat,
      pause: dtsJSON.pau,
      repos: dtsJSON.re,
      tdu: dtsJSON.tdu
    };
    return horJSON;
  },

  /**
   * Get the simple "collabId,date" ID format from Simple/Grouped ID.
   */
  trimCellId: function(groupCellId) {
    var auxArray = groupCellId.split(",");
    var date = auxArray.pop();
    var collabId = auxArray.pop();
    return collabId + "," + date;
  },

  /**
   * Check if the dropped element is inside the visible Area (Prevent drops on overlapped elements).
   */
  isMouseEventOnArea: function($area, event) {
    //Scrollable window state
    var positionTop = $area.offset().top; //Litle offset for better feel
    var positionLeft = $area.offset().left;
    var height = $area.height();
    var width = $area.width();
    //Cursor coords
    var cursorTop = event.clientY;
    var cursorLeft = event.clientX;

    if (cursorTop >= positionTop && cursorTop <= positionTop + height &&
      cursorLeft >= positionLeft && cursorLeft <= positionLeft + width) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * Check if the dropped element is inside the visible Area (Prevent drops on overlapped elements).
   */
  getScrollerWidth: function() {
    var mirror = $("body .cloned_elements .autresElements_clone_elements");
    var scr = $("<div style='width: 100px; height: 50px; overflow: hidden;'>");
    var inn = $("<div style='width: 100%; height: 200px;'>");
    var wNoScroll = 0;
    var wScroll = 0;

    scr.append(inn);
    mirror.append(scr);
    // Width of the inner div sans scrollbar
    wNoScroll = inn[0].offsetWidth;
    // Add the scrollbar
    scr.css({ "overflow": "auto" });
    // Width of the inner div width scrollbar
    wScroll = inn[0].offsetWidth;
    mirror.empty(); //clean once it has been used to calculate width 
    // Pixel width of the scroller
    return (wNoScroll - wScroll);
  }
};
