import * as Backbone from 'Backbone';
import _ from 'underscore';
import CWGererAbsenceSouhaitPopUpTPL from '../cwPopUp.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWButtonBarView } from 'src/core/controls/cwButtonBar.view';
import { CWGererAbsenceSouhaitGestion } from '../models/cwGererAbsenceSouhaitGestion';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils';

export class CWGererAbsenceSouhaitPopUpView extends CWBaseFormView {

  public parent: CWGererAbsenceSouhaitPopUpView | any;
  action: string;
  type: boolean;
  etat: string;
  absJustifVerif: boolean;
  justifCheck: boolean;
  statut: string;
  btnbBar: CWButtonBarView;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "click button": "_clickListener"
    }, options.events
    );
    super(options);
    this.template = CWGererAbsenceSouhaitPopUpTPL;
    this.model = new CWBaseModel({
      value: new CWGererAbsenceSouhaitGestion()
    });
    this.parent = options.parent;
    this.btnbBar = new CWButtonBarView();
    this.action = options.action;
    this.model.get("value").action = this.action;
    this.statut = options.statut;
    this.justifCheck = options.justifCheck;
    this.absJustifVerif = options.absJustifVerif;
    this.etat = options.etat;
    if (_.isBoolean(options.type)) {
      this.type = options.type;
    }
  }

  render(): CWGererAbsenceSouhaitPopUpView {
    const json = { "i18n": i18n, UTILS: UTILS };
    const isSouhait = this.type;
    let comment = "";

    this.$el.append(this.template(json));
    this.$el.find(".gererabsencesouhait-popup-justifier").hide();
    $(".gererabsencesouhait-popup-justifier .justifok").prop('checked', false);
    if (this.justifCheck) {
      this.$el.find(".gererabsencesouhait-popup-justifier").show();
      if (this.absJustifVerif) {
        $(".gererabsencesouhait-popup-justifier .justifok").prop('checked', true);
      }
    }
    switch (this.action) {
      case "accepter":
        if (this.etat === "X") {
          this.$el.find(".gererabsencesouhait-popup-justifier").hide();
          comment = isSouhait === false ? i18n.t('common:gererabsencesouhait.commentaire_accept_suppresion_abs') : i18n.t('common:gererabsencesouhait.commentaire_accept_suppresion_sou');
        } else {
          comment = isSouhait === false ? i18n.t('common:gererabsencesouhait.commentaire_accept_abs') : i18n.t('common:gererabsencesouhait.commentaire_accept_sou');
        }
        break;
      case "refuser":
        if (this.etat === "X") {
          this.$el.find(".gererabsencesouhait-popup-justifier").hide();
          comment = isSouhait === false ? i18n.t('common:gererabsencesouhait.commentaire_refus_suppresion_abs') : i18n.t('common:gererabsencesouhait.commentaire_refus_suppresion_sou');
        } else {
          comment = isSouhait === false ? i18n.t('common:gererabsencesouhait.commentaire_refus_abs') : i18n.t('common:gererabsencesouhait.commentaire_refus_sou');
        }
        this.$el.find("label[for=commentaire],label[for^=commentaire_uid_]").addClass("cw-required");
        break;
      case "suppression":
        // Customer #184777 If it's a popup for "supression", don't need the justifier checkbox
        this.$el.find(".gererabsencesouhait-popup-justifier").hide();
        switch (this.statut) {
          case "I":
            comment = isSouhait === false ? i18n.t('common:gererabsencesouhait.commentaire_suppresionCours_abs') : i18n.t('common:gererabsencesouhait.commentaire_suppresionCours_sou');
            break;
          case "A":
            comment = isSouhait === false ? i18n.t('common:gererabsencesouhait.commentaire_suppresionAccept_abs') : i18n.t('common:gererabsencesouhait.commentaire_suppresionAccept_sou');
            break;
          default:
            comment = isSouhait === false ? i18n.t('common:gererabsencesouhait.commentaire_suppresion_abs') : i18n.t('common:gererabsencesouhait.commentaire_suppresion_sou');
        }
    }
    this.parent._setTitle(comment);
    return this;
  }

  _clickListener(event: JQueryEventObject | any): void {
    const btnClicked = event.currentTarget.value;
    const model = this.model.get("value");

    switch (btnClicked) {
      case "btnOK":
        if (this.action === "accepter") {
          let lJusti = model.get("justifok");

          if (CWSTR.isBlank(lJusti)) {
            lJusti = this.absJustifVerif;
          }
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.model.set("justifok", lJusti);
          this.parent.close();
        } else {
          if (this.action === "refuser") {
            model.off("invalid");
            model.on("invalid", this._showValidationErrors, this);
            if (model.isValid()) {
              this.parent.model.set("action", "OK");
              this.parent.model.set("commentaire", model.get("commentaire"));
              this.parent.close();
            }
          } else if (this.action === "suppression") {
            this.parent.model.set("action", "OK");
            this.parent.model.set("commentaire", model.get("commentaire"));
            this.parent.close();
          }
        }
        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
      //Nothing
    }
  }
}
