module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="gererprets-popup cw-fieldset">\n    <div class="phx-formTable">\n        <div class="phx-formErrors ui-state-error"></div>\n        <p class="spanComment cw-texteNormal"></p>\n        <div class="form-group">\n            <label for="commentaire">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t('common:gererprets.comment')))==null?'':__t)+
'</label>\n            <textarea class="commentaire form-control" maxlength="60" cols="30" rows="3"></textarea>\n        </div>\n    </div>\n    <div class="cwDialog-buttons">\n        <button type="button" class="btnOK btn btn-primary btn-withIcon" value="btnOK">'+
((__t=(i18n.t('common:gererprets.btnOK')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('valider')))==null?'':__t)+
'</button>\n        <button type="button" class="btnAnnuler btn btn-secondary btn-withIcon" value="btnAnnuler">'+
((__t=(i18n.t('common:gererprets.btnAnnuler')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('croix')))==null?'':__t)+
'</button>\n    </div>\n</fieldset>';
}
return __p;
};
