import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

export var SelecteurCheminItemModel = BaseModel.extend({
  /**
   * Add this to have Outline
   *
   */

  /**
   * urlRoot
   */
  urlRoot: Configuration.restRoot + "/rest/configuration",

  /**
   * Constructor
   * Paginated collection for the application. Manage also filtering and sorting
   */
  initialize: function(params) {
    params = params || {};
    /**
     * Data used to form URL
     */
    this.data = "structid=" + params.structid;
    if (!STR.isBlank(params.detailunique)) {
      this.data += "&detailunique=true";
      this.data += "&datedebut=" + params.datedebut;
      this.data += "&datefin=" + params.datefin;
    } else {
      this.data += "&datedebut=" + params.date;
      this.data += "&datefin=" + params.date;
    }
    this.data += "&multiple=false&intermediaire=true&partial=true&nonrattachable=true&nonrattactiv=false";
    if (!STR.isBlank(params.chemin)) {
      this.data += "&chemin=" + params.chemin;
    }
    if (!STR.isBlank(params.elementCode)) {
      this.data += "&elementcode=" + params.elementCode;
    } else {
      if (this.data.indexOf("niveau") === (-1)) {
        this.data += "&niveau=1"
      }
    }
    this.urlRoot = Configuration.restRoot + "/rest/cheminstructure/simple?" + this.data;
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
});
