import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Model for Combos
 */
export class CWCombosModel extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}