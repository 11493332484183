import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLResultatgrpcompteursConsultation from '../cwConsultation.tpl.html';
import TPLResultatgrpcompteursConsultationWithDate from '../cwConsultationWithDate.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWComboBoxView2 } from 'src/core/components/combo/cwComboBoxView2';
import { CWFORMS } from 'utils/cwForms';
import { CWSearchListeGroupesData } from './../models/cwSearchListeGroupesData';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Dialog View of the ConsultationDialogView
 */
export class CWConsultationDialogView extends CWBaseFormView {

  template: (params: any) => string;
  model: CWBaseModel;
  comboGroupe: CWComboBoxView2;
  comboOptions: any;
  configSouhait: any;
  withDate: boolean;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {

    options.events = _.defaults({
      "change :input:not([readonly])": "_change"
    }, CWTYPE.DATE.events());

    super(options);


    this.model = new CWBaseModel({
      value: new CWSearchListeGroupesData()
    });
    if (options && options.comboOptions) {
      this.comboOptions = options.comboOptions;
      this.model = options.model;
    }
    if (options && _.isBoolean(options.withDate)) {
      this.withDate = options.withDate;
    } else {
      this.withDate = true;
    }

    this.template = this.withDate ? TPLResultatgrpcompteursConsultationWithDate : TPLResultatgrpcompteursConsultation;

    this.configSouhait = options.configSouhait;

    this.typeMaskByClass = {
      date: "DATE_A",
    }
  }

  render(): CWConsultationDialogView {
    const json = { 'i18n': i18n };
    $(this.el).html(this.template(json));

    if (this.comboOptions.length > 1 || !this.withDate) {
      this.comboGroupe = new CWComboBoxView2({
        enum: this.comboOptions.toJSON(),
        name: "groupe",
        required: true
      });
      $(this.el).find(".cmbGroupe").html(this.comboGroupe.render().el);
      this.$el.find(":input.groupe").focus();
    } else {
      $(this.el).find(".groupecompteurs-consultation-groupe").hide();
      this.$el.find(":input.date").focus();
    }

    if (!this.withDate && this.comboOptions.length === 0) {
      $(this.el).find(".groupecompteurs-consultation-detail").hide();
      $(this.el).find(".groupecompteurs-consultation-empty").show();
    } else {
      $(this.el).find(".groupecompteurs-consultation-empty").hide();
    }

    if (this.configSouhait === false) {
      $(this.el).find(".checkbox-zone-souhait").hide();
    }

    this._mapToForm();
    if (!this.withDate) {
      if (this.comboOptions.length <= 1) {
        // CWFORMS.setFieldReadonly(".cmbGroupe", true);
        this.comboGroupe.enable(false);
        if (this.comboOptions.length > 0) {
          this.comboGroupe.setItem(this.comboGroupe.getValueEnum(0));
          this.model.set("groupe", this.comboGroupe.getValueEnum(0).code);
        }
      }
      $(this.el).find(".date").hide();
    }
    CWFORMS.setDatepicker(this, ".date");

    return this;
  }

  _mapToForm(): void {
    const consu = this.model;

    // Error management
    consu.off("invalid");
    consu.on("invalid", this._showValidationErrors, this);
    // Clear current validation erros
    this._cleanValidationErrors();

    // Map the inputs, checkboxes, selects, textareas from the model
    const fieldset = this.$el.find("fieldset");
    this._mapModelToForm(fieldset, consu, null);
  }

  _getModel(): CWBaseModel {
    return this.model;
  }
}
