module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div style="display:inline-block; float:left;	width: 90%;text-align: center;">\n    '+
((__t=(text))==null?'':__t)+
'\n</div>\n<div class="phx-hd-btn ui-menu-icons" style="float:right;width: 18px;margin:1.5px 2.5px 0 1.5px;padding:0;">\n    <ul style="position: fixed;z-index: 10;"></ul>\n    <button type="button" aria-label="'+
((__t=(i18nCom.t('common:grid.colonnes_menu')))==null?'':__t)+
'" style="width: 18px;margin:1;padding:0"></button>\n</div>';
}
return __p;
};
