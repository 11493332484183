import { STR } from 'utils/str.js';

/**
 * Model to manage the context of an habilitation
 */
export var BaseGridModel = Backbone.Model.extend({

  /**
   * Event that Show/Hide a column
   */
  /**
   * Event that Locks a column.
   */
  /**
   * Event that Unlocks a column.
   */
  /**
   * Event that Blocks a column.
   */
  /**
   * Event that Unblocks a column.
   */
  /**
   * Event that scrolls the grid to the selected Row.
   */
  /**
   * Event that scrolls the grid to the selected Row if is not visible.
   */
  /**
   * Event triggered when user select a row.
   */
  /**
   * Event triggered when user unselects a row.
   */
  /**
   * Event that Reset the Height of the rows.
   */
  /**
   * Event fired when click on the header checkbox on multiselection.
   */

  /**
   * Event fired to inform a usecase that view a different view for this table has been selected
   */
  /**
   * Event fired when click on a view of the table menu
   */

  /**
   * Model underlying a basic DataGrid.
   */
  initialize: function(options) {
    var self = this;

    this.unset("coll");
    if (!options.coll) {
      throw new Error("You must initialize a DataGridModel with a Collection");
    }
    this.coll = options.coll;
    this.coll.on("row:click", this._manageRowSelection, this);
    if (options.enableSelectionOnDblclick === true) {
      this.unset("enableSelectionOnDblclick");
      this.enableSelectionOnDblclick = options.enableSelectionOnDblclick;
      this.coll.on("row:dblclick", this._manageRowSilentSelection, this);
    }
    // Delegate the events row:* to the coll
    this.on("all", function(eventName, value) {
      if (eventName.indexOf("row:") !== -1) {
        var model = self.coll.get(value);

        if (!model) {
          //If it's called a row operation over a model not present in the grid collection, it will be ignored
          return;
        }
        self.coll.trigger(eventName, model);
      }
    }, this);
    this.set("value", null); // value model
    this.multiselectColl = new Backbone.Collection();
  },

  /**
   * Select (silently) the row when do double click on it.
   *
   */
  _manageRowSilentSelection: function(model) {
    if (model) {
      this.set("value", model);
    }
  },

  /**
   * Called when do a click on a row, performs the selection of that row.
   */
  _manageRowSelection: function(model, options) {
    if (model) {
      if (!this.get("value")) {
        this.set("value", model, options);
        model.trigger("row:select", model);
      } else if (this.get("value") !== model) {
        this.get("value").trigger("row:unselect", this.get("value"));
        model.trigger("row:select", model);
        this.set("value", model, options);
      } else if (this.get("value") === model) {
        this.trigger("row:selectedAgain", this);
      }
    } else {
      this.set("value", null);
    }
  },

  /**
   * Scroll the table to show the model passed.
   */
  scrollToRow: function(model, callback, modeforced) {
    var loadedModel = this.coll.get(model) ? this.coll.get(model).clone() : null;
    var result = 0;
    var self = this;
    var oldSelectedModel = null;

    if (modeforced === true) {
      loadedModel = null; //il faut nettoyer le modèle afin de faire une nouvelle recherche
    }
    if (loadedModel) {
      var startIndex = this.coll.pagination.startIndex;
      var modelIndex = this.coll.indexOf(loadedModel);

      this.trigger("scroll:to", startIndex + modelIndex);
      if (callback) {
        callback();
      }
      return null;
    }
    oldSelectedModel = this.get("value");
    // If the action is new we get the previous value
    if (!this.get("value")) {
      oldSelectedModel = this.previous("value");
    }
    result = this.coll.findAndGo(model, function(selectOld) {
      if (selectOld) {
        loadedModel = self.coll.get(oldSelectedModel.id);
      } else {
        loadedModel = self.coll.get(model.id);
      }
      var startIndex = self.coll.pagination.startIndex;
      loadedModel = self.coll.get(model.id);
      var modelIndex = self.coll.indexOf(loadedModel);
      self.trigger("scroll:to", startIndex + modelIndex);

      if (callback) {
        callback();
      }
    }, modeforced);
    if (result === -1) { //la méthode a fini en erreur sans exécuter le callback
      if (callback) {
        callback();
      }
      result = null; //on doit nettoyer la valeur -> Il doit être une Promise ->JQueryXHR
    }
    return result;
  },

  /**
   * Used to select the passed model on the Grid, scrolls to that element.
   */
  selectRow: function(model, callback, sansScroll, modeforced) {
    var loadedModel = this.coll.get(model);
    var self = this;
    var oldSelectedModel = null;

    if (loadedModel && !loadedModel.notSelectable) {
      var startIndex = this.coll.pagination.startIndex;
      var modelIndex = this.coll.indexOf(loadedModel);

      if (sansScroll !== true) {
        this.trigger("scroll:to", startIndex + modelIndex);
      }
      this._manageRowSelection(loadedModel);
      if (typeof callback === "function") {
        callback(loadedModel);
      }
      //WCAG
      loadedModel.trigger("cell:select", this.coll.editModeCellSelected);
      this.coll.editModeCellSelected = null;
      return;
    }
    oldSelectedModel = this.get("value");
    // If the action is new we get the previous value
    if (!this.get("value")) {
      oldSelectedModel = this.previous("value");
    }
    this.coll.findAndGo(model, function(selectOld) {
      var startIndex = self.coll.pagination.startIndex;
      var modelIndex = null;
      var lIndexKeyColl = self.coll.map(function(model) {
        return model.id || model.cid;
      });

      if (selectOld) {
        loadedModel = self.coll.get(oldSelectedModel.id);
      } else {
        loadedModel = self.coll.get(model.id);
      }
      if (STR.isBlank(loadedModel) && self.coll.length > 0) {
        if (!STR.isBlank(selectOld) && !STR.isBlank(self.coll.get(model.id))) {
          loadedModel = self.coll.get(model.id);
        } else if (!STR.isBlank(self.previous("value"))) {
          loadedModel = self.previous("value");
          //il faut vérifier si le modèle existe ou pas (par exemple, il y a un filtre appliqué)
          if (!STR.isBlank(loadedModel) && !STR.isBlank(loadedModel.id)) {
            loadedModel = self.coll.get(loadedModel.id);
            if (_.isEmpty(loadedModel)) {
              //on sélectionne le premier élément de la collection
              loadedModel = self.coll.at(0);
            }
          } else {
            //on sélectionne le premier élément de la collection
            loadedModel = self.coll.at(0);
          }
        } else {
          //on sélectionne le premier élément de la collection
          loadedModel = self.coll.at(0);
        }
      }
      if (!STR.isBlank(loadedModel)) {
        modelIndex = lIndexKeyColl.indexOf(loadedModel.id);
      }
      if (STR.isBlank(modelIndex) || modelIndex < 0) {
        modelIndex = 0;
      }
      if (sansScroll !== true) {
        self.trigger("scroll:to", startIndex + modelIndex);
      } else {
        self.trigger("rePositionScroll", loadedModel.id);
      }
      self._manageRowSelection(loadedModel);
      if (typeof callback === "function") {
        callback(loadedModel);
      }
    }, modeforced);
  },

  notSelectableRow: function(model) {
    var loadedModel = this.coll.get(model);
    if (loadedModel) {
      loadedModel.notSelectable = true;
      loadedModel.trigger("row:notSelectable", loadedModel);
    }
  },

  selectableRow: function(model) {
    var loadedModel = this.coll.get(model);
    if (loadedModel) {
      loadedModel.notSelectable = false;
      loadedModel.trigger("row:Selectable", loadedModel);
    }
  },

  supprimedRow: function(model) {
    var loadedModel = this.coll.get(model);
    if (loadedModel) {
      loadedModel.notSelectable = true;
      loadedModel.trigger("row:supprimed", loadedModel);
    }
  },

  /**
   * Select the first row of the table, scroll to the beginning.
   */
  selectFirstRow: function(silent) {
    var model = this.coll.at(0);
    var l_silent = STR.isBlank(silent) ? false : silent;
    var self = this;

    if (this.coll.pagination.startIndex === 0) {
      if (l_silent === false && !STR.isBlank(model) && model.notSelectable !== true) {

        this._manageRowSelection(_.first(this.coll.models));
      } else {
        for (var i = 1; i < this.coll.length && (model.notSelectable && model.notSelectable === true); i++) {
          model = this.coll.at(i);
        }
        if (STR.isBlank(model) || model.notSelectable === true) {
          model = null;
        }
        this._manageRowSelection(model);
      }
      this.trigger("scroll:to", 0);
    } else {
      //It is needed to paginate to first page
      this.coll.goTo(0, function() {
        self.selectFirstRow();
        self.trigger("scroll:to", 0);
      });
    }
  },

  resetScroll: function() {
    this.trigger("scroll:to", 0);
  },

  /**
   * Select the last row of the table, scroll to the end.
   */
  selectLastRow: function(silent) {
    var lastPageIndex = this.coll.totalRecords - this.coll.pagination.size;
    var newIncrement = 1;
    var model = this.coll.at(this.coll.length - 1);
    var l_silent = STR.isBlank(silent) ? false : silent;
    var self = this;

    if (this.coll.pagination.startIndex >= lastPageIndex) {

      if (l_silent === false && !STR.isBlank(model) && model.notSelectable !== true) {

        this._manageRowSelection(_.last(this.coll.models));
      } else {
        for (var i = lastPageIndex - newIncrement; i >= 0 && (model.notSelectable && model.notSelectable === true); i--) {
          newIncrement++;
          if (lastPageIndex - newIncrement > 0) {
            model = this.coll.at(this.coll.length - newIncrement);
          }
          if (STR.isBlank(model) || model.notSelectable === true) {
            model = null;
          }
        }
        this._manageRowSelection(model);
      }

      this.trigger("scroll:to", this.coll.totalRecords);
    } else {
      //It is needed to paginate to first page
      this.coll.goTo(lastPageIndex, function() {
        self.selectLastRow();
        self.trigger("scroll:to", self.coll.totalRecords);
      });
    }
  },

  /**
   * Select the previous row of the current selected row on the table.
   */
  selectPreviousRow: function() {
    var index = this.coll.indexOf(this.get("value"));
    var newIncrement = 1;
    if ((index - newIncrement) === -1) {
      return;
    }
    var model = this.coll.at(index - newIncrement);
    if (model && model.notSelectable === true) {
      for (var i = index - newIncrement; i >= 0 && (model.notSelectable === true); i--) {
        newIncrement++;
        if (index - newIncrement >= 0) {
          model = this.coll.at(index - newIncrement);
        }
      }
    }
    if (index > 0 && (model.notSelectable !== true)) {
      this._manageRowSelection(model);
      this.trigger("scroll:toIfHidden", index, -newIncrement);
    } else {
      this._manageRowSelection(null);
      this.trigger("scroll:toIfHidden", index, -newIncrement);
    }
  },

  /**
   * Select the next row of the current selected row on the table.
   */
  selectNextRow: function() {
    var index = this.coll.indexOf(this.get("value"));
    if (index === -1) {
      // Avoid start selection in the first row if no row is
      // previously selected
      return;
    }
    if (index < this.coll.length - 1) {
      var newIncrement = 1;
      var model = this.coll.at(index + newIncrement);
      if (STR.isBlank(model) || model.notSelectable === true) {
        for (var i = index + newIncrement; i < this.coll.length && (model.notSelectable === true); i++) {
          model = this.coll.at(i);
        }
      }
      if (STR.isBlank(model) || model.notSelectable !== true) {
        this._manageRowSelection(model);
        this.trigger("scroll:toIfHidden", index, newIncrement);
      } else {
        this._manageRowSelection(null);
        this.trigger("scroll:toIfHidden", index, newIncrement);
      }
    }
  },

  /**
   * Methods to lock a column, lock a column mean that this column disappear
   * and can't be show with the menu
   */
  lock: function(key) {
    this.trigger("lock:column", key);
  },

  /**
   * Methods to unlock a column, the column is showed again on the menu to display it.
   */
  unlock: function(key) {
    this.trigger("unlock:column", key);
  },

  /**
   * Method to block a column,
   * blocking a column mean that this column can't be hidden/shown with the menu.
   */
  block: function(key) {
    this.trigger("block:column", key);
  },

  /**
   * Method to unblock a column, this column can be hidden/shown with the menu.
   */
  unblock: function(key) {
    this.trigger("unblock:column", key);
  },

  /**
   * Function that triggers the reset of the height of the rows.
   */
  resetRowHeight: function() {
    this.trigger("reset:rowHeight");
  }
});
