import { AutocompleteColl } from './autocomplete.collection.js';
import { ComboBox2ResultItemView } from './comboBox2ResultItem.view.js';
import { FORMS } from 'utils/forms.js';
import { LOG } from 'utils/log.js';
import { STR } from 'utils/str.js';
import { UTILS } from 'utils/utils.js';

export var ComboBoxView2 = Backbone.View.extend({

    /** Mark to use like instanceof */
    isComboBoxView2: true,

    /**
     * Name of the html tag which involves the view
     */
    tagName: "div",

    /**
     * Name of the container class of the view
     */
    className: "phx-combobox phx-list-builder",

    /**
     * Alias for the common messages to show in this view
     */
    //REVIEW: 	i18n : phx.i18n.common,

    /**
     * Indicator of the multiselection functionality
     */
    multiselection: false,

    /**
     * Indicator of the readonly mode
     */
    readonly: false,

    events: {
        "click .phx-combobox-button": "_buttonSearch",
        "click .phx-combobox-input:not([readonly])": "_inputSearch",
        "autocompleteselect": "_storeValue",
        "autocompletefocus": "_focus",
        "keydown :not([readonly]):not([disabled])": "_keyDownEvent"
    },

    /**
     * Constructor
     * ComboBox2 components to lazy load the information
     */
    initialize: function(params) {

        // set the collection used to retrieve the data
        this.coll = params.ws;
        this.enum = params.enum;

        // class name
        this.name = params.name;

        // if true removes the blank option
        this.required = false;
        if (_.isBoolean(params.required)) {
            this.required = params.required;
        }
        //autreText for the required option
        this.autreText = params.autreText;
        //cas spécial: disableEmptyItem
        this.disableEmptyItem = false;
        if (!STR.isBlank(params.disableEmptyItem) && STR.isBlank(this.autreText)) {
            //Seuelement lorsque il n'avait pas de valeur dans "autreText", on appliquera la valeur de disableEmptyItem
            this.disableEmptyItem = params.disableEmptyItem;
        }
        //if autocomplete mode is allowed, component uses search term to filter results in WS
        this.autocompleteMode = false;
        if (_.isBoolean(params.autocomplete)) {
            this.autocompleteMode = params.autocomplete;
        }
        if (this.coll && this.autocompleteMode === true && !this.coll.applyFilter) {
            LOG.debug("You must define a collection to allow autocomplete");
        }
        this.optionsRender = params.optionsRender;

        if (!this.optionsRender) {
            this.optionsRender = function(item) {
                return ((item) ? item.libelle : "");
            };
        }
        //Input renderer: optional parameter to determine how to show the option selected in the input
        this.inputRender = params.inputRender;
        if (!this.inputRender) {
            this.inputRender = this.optionsRender;
        }

        this.width = params.width;
        this.height = params.height;

        // externalErrorContainer : if an external error container is defined for this component we
        // do not add out internal error container
        this.useExternalErrorContainer = false;
        if (_.isBoolean(params.useExternalErrorContainer)) {
            this.useExternalErrorContainer = params.useExternalErrorContainer;
        } else if (params.useExternalErrorContainer) {
            throw new Error("Parameter 'useExternalErrorContainer' should be a boolean ");
        }

        this.setOutErrorContainer = false;
        if (_.isBoolean(params.setOutErrorContainer)) {
            this.setOutErrorContainer = params.setOutErrorContainer;
        }

        // used to share the combos results between differents combos (example in Motif absence --> droits panel)
        if (params.externalCache) {
            this.syncExternalCache = true;
            this.cache = params.externalCache;
        } else {
            this.syncExternalCache = false;
            this.cache = {};
        }

        if (_.isBoolean(params.syncExternalCache)) {
            this.syncExternalCache = params.syncExternalCache;
        }

        // if autocompleteMode == true, the external cache is not allowed
        if (this.autocompleteMode === true) {
            this.syncExternalCache = false;
            this.cache = {};
        }

        this.cache["pendingInUse"] = this.cache["pendingInUse"] || [];
        this.term = "";
        this.anterieurTerm = ""; //pour savoir l'anterieur "term" utilisé

        // used to iterate thru the list of result starting with the same letter
        this.delay = 500;
        this.selectLetter = [];
        this.searchLetters = "";

        if (params && params.habContext) {
            this.habilitationContext(params.habContext);
        }

        if (params && params.multiselection) {
            this.multiselection = params.multiselection;
        }

        if (this.multiselection === true) {
            // called with the selected items
            /**
             * Callback function to be executed when a new item is added to the list
             */
            this.addItemCallback = params.addItemCallback;

            /**
             * Callback function to be executed when a new item is removed from the list
             */
            this.removeItemCallback = params.removeItemCallback;

            // response list filled with user selection
            /**
             * Current selection in the list
             */
            this.selection = new AutocompleteColl();
            this.selection.on("click:item", this._removeItem, this);
            this.selection.on("reset", this._resetItems, this);
        }

        if (params && params.preprocessBeforeSetItem) {
            this.preprocessBeforeSetItem = params.preprocessBeforeSetItem;
        }

        if (params._isValidComboId) {
            this._isValidComboId = params._isValidComboId;
        }

        this.keepOldId = null;
        if (params && params.keepOldId) {
            this.keepOldId = params.keepOldId;
        }
        this.refreshCombo = true;
    },

    render: function() {
        var self = this;
        this.$el.empty();

        // make input
        var div = $("<div class='phx-combobox-content phx-list-builder-wrap'>");

        this.input = $("<input type='text'>");
        this.input.addClass(this.name);
        this.input.attr("data-cy", this.name);
        this.input.addClass("phx-combobox-input");
        this.input.prop("viewRef", this);
        this.input.attr("id", this.name + "_" + _.uniqueId());

        if (!STR.isBlank(this.keepOldId)) {
            this.input.attr("id", this.keepOldId);
        }

        div.append(this.input);

        if (this.autocompleteMode !== true) {
            this.input.css("cursor", "default");
        }
        self.appended = false;
        $(this.input).autocomplete({
            minLength: 0,
            appendTo: null,
            position: { collision: 'flipfit' },
            open: function() {
                var menu = $(this).data("ui-autocomplete").menu;
                menu.activeMenu.addClass("phx-combobox phx-combobox2");
                if (menu.activeMenu.hasClass("c-panneauMenu")) {
                    menu.activeMenu.removeClass("c-panneauMenu");
                }
                if (menu.activeMenu.hasClass("c-panneauMenu--noIcon")) {
                    menu.activeMenu.removeClass("c-panneauMenu--noIcon")
                }
                //				menu.activeMenu.css('z-index', 110);
                //				var currentZIndex=$(self.input).zIndex();
                //				menu.activeMenu.css('z-index', currentZIndex+1);
                var code = $(self.input).prop("data-code");

                if (self.multiselection === true && !STR.isBlank(code)) {
                    code = undefined;
                }

                if (!STR.isBlank(code) && !self.searching) {
                    var codeSel = code.toString().replace(/ /g, "\\ "); //To let selecting codes that contain spaces
                    codeSel = codeSel.replace("*", "\\*");
                    var element = $(menu.element).find("[data-value=" + UTILS.escapeJQueryString(codeSel) + "]");
                    if (element && element.length > 0) {
                        menu.focus(null, element);
                    }
                }
                return false;
            },
            close: function() {
                $(document).off("wheel." + self.cid);
                $(document).off("mousedown." + self.cid);
                this.searching = false;

                $(this).data('ui-autocomplete').term = null;
                if (self.cache["current"] && self.autocompleteMode === true) {
                    self._setItem(self.cache["current"].attrs);
                }

                if (self.multiselection === true) {
                    self._setInputValue("");
                }
                return false;
            },
            focus: function(event, ui) {
                if (ui && ui.item) {
                    self._renderColorComboChange(ui.item.attrs);
                }
                return false;
            },

            create: function() {
                var menu = $(this).data("ui-autocomplete").menu;

                // customer 161771 /* if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {$(menu.activeMenu).css("position","relative")}else */;
                $(menu.activeMenu).css("position", "fixed");
                $(this).data('ui-autocomplete')._renderItem = function(ul, item) {
                    return self._renderItem(this, ul, item);
                };
                $(this).data('ui-autocomplete')._resizeMenu = function() {
                    var lis = this.menu.element.find("li");
                    var padding = parseInt(this.menu.element.css("padding-left").replace("px", "")) + parseInt(this.menu.element.css("padding-right").replace("px", ""));
                    var size = lis.length;
                    var minWidth = 20;
                    var mirror = $("body .cloned_elements .cw-combobox2_clone_elements"); //Generate a mirror to measure each element's width
                    if (size > 0 && mirror.length > 0) {
                        var lenTexteMax = lis.eq(0).text().length;
                        var currentWith = 0;
                        var listElement = [];

                        listElement.push(0);
                        for (var i = 1; i < size; i++) {
                            var lenTextAux = lis.eq(i).text().length;

                            if (lenTextAux > lenTexteMax) {
                                lenTexteMax = lenTextAux;
                                listElement = [];
                                listElement.push(i);
                            } else if (lenTextAux === lenTexteMax) {
                                listElement.push(i);
                            }
                        }
                        for (var j = 0; j < listElement.length; j++) {
                            mirror.append(lis.eq(listElement[j]).text().toUpperCase()); //pour avoir la largeur plus grande (majuscule)
                            if (j < (listElement.length - 1)) {
                                mirror.append($("</br>"));
                            }
                        }
                        $.each(['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'letterSpacing', 'textTransform', 'wordSpacing', 'textIndent'], function(aPosText, val) {
                            mirror[0].style[val] = lis.eq(aPosText).css(val);
                        });
                        currentWith = mirror.width();
                        currentWith += padding;
                        if (currentWith > minWidth) {
                            minWidth = currentWith;
                        }
                        mirror.empty(); //clean once it has been used to calculate width of each line
                        mirror.attr("style", "left:0; white-space:pre;"); //retablir les styles                        
                    }
                    minWidth += 12;
                    if (size > 20) {
                        minWidth += 14; // adjusted to show the scroll
                    }
                    // adjust the size of the elements, to the size of the combo.
                    var l_tmpWidth = $("<div>").width(self.width); //pour faire les conversions de "auto", "100%", "300px" et "1em" à les mêmes unités que minWidth
                    /*customer 205285*/
                    var l_tmpWidthContainer = self.$el.find(".phx-combobox-content").width();

                    if (l_tmpWidth !== 0 && minWidth < (l_tmpWidth.width() - 3)) {
                        minWidth = l_tmpWidth.width() - 3;
                    } else if (minWidth < l_tmpWidthContainer) {
                        minWidth = l_tmpWidthContainer - 3;
                    }

                    this.menu.element.innerWidth(minWidth);
                    if (self.appended === false) {
                        //AppendTo option in order to let jquery recalculate z-index for each element
                        $(self.input).autocomplete("option", "appendTo", null);
                        self.appended = true;
                    }

                    var tab_contaner_height = 0;

                    $(".ui-tabs-nav").each(function() {
                        // eslint-disable-next-line no-invalid-this
                        if (tab_contaner_height < $(this)[0].offsetHeight) {
                            // eslint-disable-next-line no-invalid-this
                            tab_contaner_height = $(this)[0].offsetHeight;
                        }
                    });

                    //Get the limit container
                    var limit_container = $("#phx-container");

                    if (this.element[0] && $(this.element[0]) && limit_container && $(this.element[0]).offset()) {
                        var scrollTop = $(window).scrollTop(),
                            scrollBot = scrollTop + $(window).height(),
                            elTop = limit_container.offset().top,
                            elBottom = elTop + limit_container.outerHeight(),
                            visibleTop = elTop < scrollTop ? scrollTop : elTop,
                            visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;

                        this.menu.element[0].style.maxHeight = ""; //set default heiht

                        var distance_from_input_to_top_container = $(this.element[0]).offset().top - (limit_container.offset().top + tab_contaner_height);
                        var distance_from_input_to_buttom = (visibleBottom - visibleTop - tab_contaner_height) - distance_from_input_to_top_container;
                        var height_of_select_despl = this.menu.element[0].offsetHeight;
                        var height_of_the_input = this.element[0].offsetHeight;

                        distance_from_input_to_buttom -= height_of_the_input;

                        if (distance_from_input_to_buttom < height_of_select_despl) {
                            if (distance_from_input_to_buttom > distance_from_input_to_top_container) {
                                var maxHeight = distance_from_input_to_buttom - 15;
                                if (maxHeight < 20) {
                                    maxHeight = 20;
                                }
                                this.menu.element[0].style.maxHeight = maxHeight + "px";
                            } else {
                                this.menu.element[0].style.maxHeight = (distance_from_input_to_top_container - 15) + "px";
                            }
                        }
                    }
                };
                self.refreshCombo = false;
            }
        });

        // make button
        var dropDownButton = $("<span>");
        dropDownButton.addClass("phx-combobox-button");
        dropDownButton.addClass("ui-icon");
        div.append(dropDownButton);

        if (this.autreText) {

            this._fetchCombo();
            // autocomplete input
            $(this.input).autocomplete("option", "source",
                function(request, response) {
                    //self.term = this.autreText;
                    self.trigger("comboEdited");
                    self._fetchCombo(response);
                }

            );

        } else {

            // autocomplete input
            $(this.input).autocomplete("option", "source",
                function(request, response) {
                    self.term = "";
                    if (self.autocompleteMode === true) {
                        self.term = request.term;
                    }

                    if (self.term in self.cache) {
                        // eslint-disable-next-line no-invalid-this
                        if (self.refreshCombo === false && self.term === self.anterieurTerm && this.menu.element[0].childElementCount > 0) {
                            // eslint-disable-next-line no-invalid-this
                            this.menu.element.show();
                            // eslint-disable-next-line no-invalid-this
                            this.menu.element.position($.extend({
                                // eslint-disable-next-line no-invalid-this
                                of: this.element
                                    // eslint-disable-next-line no-invalid-this
                            }, this.options.position));

                            var code = $(self.input).prop("data-code");
                            if (code) {
                                var codeSel = code.toString().replace(/ /g, "\\ "); //To let selecting codes that contain spaces

                                codeSel = codeSel.replace("*", "\\*");
                                if (codeSel !== "") {
                                    // eslint-disable-next-line no-invalid-this
                                    var element = $(this.menu.element).find("[data-value=" + UTILS.escapeJQueryString(codeSel) + "]");

                                    if (element && element.length > 0) {
                                        // eslint-disable-next-line no-invalid-this
                                        this.menu.focus(null, element);
                                    }
                                }
                            }
                        } else {
                            self._updatePendingInUse(self.cache[self.term]);
                            response(self._applyFilter(self.cache[self.term]));
                            self.refreshCombo = false;
                        }
                        self.anterieurTerm = self.term;
                    } else {
                        if (self.coll && self.autocompleteMode === true && self.coll.applyFilter) {
                            _.extend(self.coll.params, { "search": self.term });
                            self.anterieurTerm = self.term;
                        }
                        self.trigger("comboEdited");
                        self._fetchCombo(response);
                    }
                }
            );

        }

        div.width(this.width);
        this._setHeight(this.height);
        this.$el.attr("cid", this.cid);
        $(this.input).attr("cid", this.cid);
        dropDownButton.attr("cid", this.cid);

        $(this.el).html(div);

        if (this.multiselection === true) {
            //make the result list container
            var resultList = $("<div>");
            resultList.addClass("phx-list-builder-selection");

            $(this.el).append(resultList);
        }

        if (this.useExternalErrorContainer === false && this.setOutErrorContainer === false) {
            var error = $("<span class='" + this.name + "-error-container'>");
            $(this.el).append(error);
        }
        this.delegateEvents();

        return this;
    },

    /**
     * Retrieve information from the WS for the combo
     *
     * It caches the response returned from the WS for the term used (if autocompleteMode == true)
     */
    _fetchCombo: function(response) {
        var self = this;

        // clear dropdown content
        //$(this.input).autocomplete("widget").empty();

        if (this.coll && (!this.cache[this.term] || this.cache[this.term].length === 0) &&
            this._isValidComboId()) {

            this.coll.fetch({
                success: function(resp) {
                    self._processFetchResponse(resp, response);
                },
                error: function() {
                    if (response) {
                        response([]);
                    }
                }
            });
        } else if (this.enum && (!this.cache[this.term] || this.cache[this.term].length === 0)) {
            this.cache[this.term] = [];

            if (this.autreText && this.required) {
                this.cache[this.term].push(this._otherText());
            }
            if (this.required !== true && _.keys(this.enum).length > 0 && this.disableEmptyItem !== true) {
                this.cache[this.term].push(this._emptyItem());
            } //AD
            _.each(this.enum, function(item) {
                if (!STR.isBlank(item.code)) {
                    self.cache[self.term].push(self._formatItem(item));
                } else {
                    self.cache[self.term].push(self._emptyItem());
                }

            });

            this._updatePendingInUse(this.cache[this.term]);

            if (_.keys(this.enum).length > 0 && this.setItemPendingAction === true) {
                this._setItem(this.setItemPendingValue);
            }

            if (response) {
                response(this._applyFilter(this.cache[this.term]));
            }
        } else {
            if (response) {
                response(this._applyFilter(this.cache[this.term]));
            }
        }
    },

    _processFetchResponse: function(resp, response) {
        if (resp instanceof Backbone.Collection) {
            this.cache[this.term] = this._formatResponse(resp.models);
        } else {
            this.cache[this.term] = this._formatResponse(resp.attributes);
        }

        // clear dropdown content if fetch return empty list of elements
        if (this.cache[this.term].length === 0) {
            if ($(this.input).hasClass('ui-autocomplete-input')) {
                $(this.input).autocomplete("widget").empty();
            }
        }

        if (this.autreText && this.required) {
            this.cache[this.term].unshift(this._otherText());
        }
        this._updatePendingInUse(this.cache[this.term]);

        if (this.setItemPendingAction === true && !STR.isBlank(resp) && !STR.isBlank(resp.get(this.setItemPendingValue.code))) {
            this._setItem(this.setItemPendingValue);
        }
        if (response && $(this.input).hasClass("ui-autocomplete-input")) {
            response(this._applyFilter(this.cache[this.term]));
        }
    },

    _isValidComboId: function() {
        return true;
    },

    /**
     * Capture key events avoid input modification when autocompleteMode == false
     *
     */
    _keyDownEvent: function(e) {
        var self = this;

        if (this.shouldCloseMenu === true) {
            if ($(this.input).autocomplete("widget").is(":visible")) {
                $(this.input).autocomplete("close");
            }
            this.shouldCloseMenu = false;
        } else {
            this._registerOneMousedonwEvent();
            this._registerOneWheelEvent();

            if (this.autocompleteMode === true) {
                this.searching = true;
                return true;
            }

            var key = e.which || e.keyCode;

            // If ctrl + c or ctrl+v do nothing.
            if (e.ctrlKey && (key === 99 || key === 118)) {
                return true;
            }

            // function keys
            if (key >= 112 && key <= 123) {
                return true;
            }

            var passedBy = [9, 13, 27, 91, 92];
            if (_.indexOf(passedBy, key) !== -1) {
                return true;
            }

            this.searchLetters += String.fromCharCode(key);
            this._delay(function() {
                if (self.searchLetters !== "") {
                    self._selectItemStartingWithLetter();
                }
            }, this.delay);
        }

        e.preventDefault();
        return false;
    },

    /**
     * Delay the call to the handler function
     *
     */
    _delay: function(handler, delay) {
        var instance = this;
        var handlerProxy = function() {
            return (typeof handler === "string" ? instance[handler] : handler)
                .apply(instance, arguments);
        }

        return setTimeout(handlerProxy, delay || 0);
    },

    /**
     * Selects the entries starting with the same letter as the letter received from the keyevent
     * If entries > 0 then it iterates thru them
     *
     */
    _selectItemStartingWithLetter: function() {
        var self = this;
        var baseLetter = this.searchLetters.toUpperCase();

        this.searchLetters = "";
        //customer 163495 error when type at input when none comboId is setted by this ws collection.
        if (!this._isValidComboId()) {
            return;
        }

        this._fetchCombo(function(list) {
            var responseIndex = -1;
            var lastIndex = -1;
            var index = 0;

            if (STR.isBlank(self.selectLetter[baseLetter])) {
                self.selectLetter[baseLetter] = 0;
            }
            var substringLength = baseLetter.length;
            for (var i = list.length - 1; i >= 0; i--) {
                var item = list[i];
                if (item && !STR.isBlank(item.label)) {
                    var firstLetter = item.label.substr(0, substringLength).toUpperCase();
                    if (baseLetter === firstLetter) {
                        if (i > self.selectLetter[baseLetter]) {
                            responseIndex = i;
                        }
                        lastIndex = i;
                    }
                }
            }

            index = (responseIndex >= 0) ? responseIndex : lastIndex;

            if (index >= 0) {
                self.selectLetter[baseLetter] = index;
                self._setItem(list[index].attrs);
                $(self.input).trigger("change", list[index].id);
                $(self.input).data("ui-autocomplete")._trigger("open");

            }
        });

    },

    //    _buttonSearch2 : function(){
    //    	this.$el.find(".phx-combobox-input").click();
    //    },

    /**
     * This function is called when we click on the dropdown button
     *
     * It triggers the search action on the autocomplete plugin to open the dropdown menu
     *
     */
    _buttonSearch: function() {
        if (this.$el.find(".phx-combobox-input").is(":not([disabled])")) {
            if (this.shouldCloseMenu === true) {
                if ($(this.input).autocomplete("widget").is(":visible")) {
                    $(this.input).autocomplete("close");
                }
                this.shouldCloseMenu = false;
            } else {
                $(this.input).autocomplete("search", "");
                $(this.input).focus();
                this._registerOneMousedonwEvent();
                this._registerOneWheelEvent();
            }
        }
        return false;
    },

    /**
     * Close autocomplete dropdown when scrolling outside.
     */
    _registerOneWheelEvent: function() {
        var self = this;

        $(document).off("wheel." + this.cid);
        $(document).one("wheel." + this.cid, function(event) {
            self.shouldCloseMenu = false;

            // wheel on the list
            try {
                if ($(event.target).attr("id") === $(self.input).autocomplete("widget").attr("id") || $(self.input).autocomplete("widget").find(event.target).length > 0) {
                    self._registerOneWheelEvent();
                } else if ($(self.input).autocomplete("widget").find(event.target).length === 0) {
                    if ($(self.input).autocomplete("widget").is(":visible")) {
                        $(self.input).autocomplete("close");
                        if (self.cache["current"] && self.cache["current"].attrs && self.multiselection === false) {
                            self._renderColorComboChange(self.cache["current"].attrs);
                        } else if (STR.isBlank(self.currentCode)) {
                            self._setInputValue("");
                        }
                    }
                    self.shouldCloseMenu = false;
                }
            } catch (e) {
                LOG.error("Autocomplete__registerOneWheelEvent: " + e);
            }
        });
    },

    _registerOneMousedonwEvent: function() {
        var self = this;

        $(document).off("mousedown." + this.cid);
        $(document).one("mousedown." + this.cid, function(event) {
            self.shouldCloseMenu = false;
            var l_erreur = false;

            // clicked on the scroll of the list
            try {
                if ($(event.target).attr("id") === $(self.input).autocomplete("widget").attr("id")) {
                    self._registerOneMousedonwEvent();
                    return;
                } else if ($(self.input).autocomplete("widget").find(event.target).length === 0) {
                    if ($(self.input).autocomplete("widget").is(":visible")) {
                        $(self.input).autocomplete("close");
                        if (self.cache["current"] && self.cache["current"].attrs && self.multiselection === false) {
                            self._renderColorComboChange(self.cache["current"].attrs);
                        } else if (STR.isBlank(self.currentCode)) {
                            if (self.autreText && self.required) {
                                self._setInputValue(self.autreText);
                            } else {
                                self._setInputValue("");
                            }
                        }
                    }
                    self.shouldCloseMenu = false;
                }
            } catch (e) {
                LOG.warn("Autocomplete__registerOneMousedonwEvent: " + e);
                l_erreur = true;
            }

            if (!l_erreur && self.$el.find(event.target).length > 0 && $(event.target).hasClass("phx-combobox-button")) {
                if ($(self.input).autocomplete("widget").is(":visible")) {
                    self.shouldCloseMenu = true;
                }
            }
        });
        $(document).off("tabsactivate." + this.cid);
        $(document).one("tabsactivate." + this.cid, function() {
            self.shouldCloseMenu = false;
            if (!STR.isBlank($(self.input).data('ui-autocomplete'))) {
                $(self.input).autocomplete("close");
            }
        });
    },

    /**
     * This function is called when we click on the input field and the autocompleteMode == false
     *
     * It triggers the search action on the autocomplete plugin to open the dropdown menu
     *
     */
    _inputSearch: function() {
        if (this.autocompleteMode !== true) {
            if ($(this.input).autocomplete("widget").is(":visible")) {
                $(this.input).autocomplete("close");
            } else {
                this._buttonSearch();
            }
        } else {
            $(this.input).autocomplete("close");
        }
    },

    _focus: function(event) {
        // solve problem when mouse is over the menu but the user uses the keyboard to validate the entry
        if (typeof event.keyCode === 'undefined' || String(event.keyCode) === "0" || String(event.keyCode) === "38" || String(event.keyCode) === "40") {
            this.isHoverSelect = true;
        } else {
            this.isHoverSelect = false;
        }
    },

    /**
     * This function is called when the autocomplete plugin trigger the event "autocompleteselect"
     *
     * It occurs when an item from the dropdown menu is selected and trigger a "change" event to the application
     *
     */
    _storeValue: function(event, ui) {
        var trulyClick = false;

        if (event.originalEvent.originalEvent.type === "click") {
            trulyClick = true;
        }

        if (trulyClick === false && (_.isBoolean(this.isHoverSelect) && !this.isHoverSelect && typeof event.keyCode !== 'undefined' && event.keycode !== 0)) {
            //just tabbed or hovered and hit enter
            event.preventDefault();
        } else {
            event.stopPropagation();
            var code = ui.item.id;

            // *important: trigger the change event to the parent view with the code
            // of the selected item
            this._setItem({ code: code });

            $(this.input).prop("data-code", code);
            //if (this.multiselection == false) {
            $(this.input).trigger("change", code);
            //} else {
            if (this.multiselection === true) {
                this._selectItem(event, ui);
            }
            return (String(this.currentCode) === String(code));
        }
        return false;
    },

    /**
     * Formats the data returned by the model or collection
     * In the case of a model, it must have at least the following attributs 'code', 'libelle'
     * In the case of a collection, you can override the function 'optionsRender' to adapt it
     * to the needed attributs
     *
     */
    _formatResponse: function(response) {
        var data = [];
        var counter = 0;
        var self = this;
        var length = response.length;

        if (response && _.isObject(response)) {
            var array = $.map(response, function(value) {
                return [value];
            });

            length = array.length;
        }
        if (this.required !== true && length > 0) {
            data[counter] = this._emptyItem();
            counter++;
        }

        _.each(response, function(resp) {
            var item = self._formatItem(resp);

            if (item) {
                data[counter] = item;
                counter++;
            }
        });
        return data;
    },

    _emptyItem: function() {
        var data = {};

        data.inUse = false;
        data.label = "";
        data.attrs = { code: null, libelle: "" };
        data.id = null;
        return data;
    },
    _otherText: function() {
        var data = {};

        data.inUse = false;
        if (this.autreText && this.required) {
            data.id = null;
            data.attrs = { code: null, libelle: $("<div>").text(this.autreText).text() };
            data.label = this.autreText;
        }
        return data;
    },

    _formatItem: function(item) {
        var data = null;

        if (item instanceof Backbone.Model && !STR.isBlank(item.get("id"))) {
            data = {};
            data.inUse = false;
            data.label = this.inputRender(item.attributes);
            data.attrs = item.attributes;
            data.id = item.get("id");
        } else if (!STR.isBlank(item.code)) {
            data = {};
            data.inUse = false;
            data.label = this.inputRender(item);
            data.attrs = item;
            data.id = item.code;
        }

        return data;
    },

    /**
     * Search and return an item by its id from the current cache
     *
     */
    _getItemById: function(id) {
        var result = null;

        if (!STR.isBlank(id)) {
            var list = this.cache[this.term];
            if (list) {
                result = _.find(list, function(obj) { return String(obj.id) === String(id); });
            }
        }
        return result;
    },

    /**
     * Indicates if the current id is being used by this combo or another combo that shares the same cache
     *
     */
    _inUseState: function(id, inUse) {
        var item = this._getItemById(id);
        if (item) {
            item.inUse = (inUse && this.syncExternalCache);
        } else {
            this.cache["pendingInUse"].push({ id: id, inUse: inUse && this.syncExternalCache });
        }
    },

    /**
     * 'pendingInUse' is a temporary cache that is used while no call to the underlying WS has been made
     * We use this cache to store the state of the item.
     * When a call to the WS is made this cache is cleaned and synchronized with the current cache
     *
     */
    _updatePendingInUse: function(data) {
        _.each(this.cache["pendingInUse"], function(obj) {
            var item = _.find(data, function(o) {
                //return (!STR.isBlank(o.id) && !STR.isBlank(obj.id) && String(o.id) === String(obj.id));
                return (String(o.id) === String(obj.id));
            });
            if (item) {
                item.inUse = obj.inUse;
            }
        }, this);
        this.cache["pendingInUse"].length = 0;
    },

    /**
     * Retrieve the list of items currently used by the combo or the combos that shares the same cache
     */
    inUseItems: function() {
        return _.union(_.where(this.cache[this.term], { inUse: true }), _.where(this.cache["pendingInUse"], { inUse: true }));
    },

    setItem: function(item, callback) {
        this.clean();
        if (this.preprocessBeforeSetItem) {
            item = this.preprocessBeforeSetItem(item);
        }
        this._setItem(item, callback);

        if (this.multiselection === true) {
            this.selection.add([item], { parse: true });
            // paint items
            this._paintItems();
        }
    },

    setItems: function(items) {
        if (!STR.isBlank(items) && this.multiselection === true) {
            this.clean();
            for (var i = 0; i < items.length; i++) {
                this._setItem(items[i]);

                this.selection.add([items[i]], { parse: true });
            }
            // paint items
            this._paintItems();
        }
    },

    /**
     * Mainly used in the function mapModelToForm, it initializes the combo with the models value
     */
    _setItem: function(item, callback) {
        var code = null;

        this.setItemPendingAction = false;
        if (!STR.isBlank(item) && !STR.isBlank(item.code)) {
            code = item.code;
        } else if (!STR.isBlank(item) && !STR.isBlank(item.attrs)) {
            code = item.id;
        }

        if (item && !STR.isBlank(code)) {
            var val = this._getItemById(code);

            this._inUseState(this.currentCode, false);
            if (val) {
                $(this.input).prop("data-code", val.id);
                this.cache["current"] = val;
                this._renderColorComboChange(val.attrs);
                this.refreshCombo = true;
                if (callback) {
                    callback();
                }
            } else {
                if (STR.isBlank(item.libelle)) {
                    this.setItemPendingAction = true;
                    this.setItemPendingValue = item;
                    this.fetchCombo(callback);
                } else {
                    this.cache["current"] = this._formatItem(item);
                    this._renderColorComboChange(item);
                    if (callback) {
                        callback();
                    }
                }
                $(this.input).prop("data-code", item.code);
            }

            this.currentCode = code;
            this._inUseState(this.currentCode, true);
        } else {
            $(this.input).prop("data-code", "");
            if (this.autreText) {
                this._setInputValue(this.autreText);
            } else {
                this._setInputValue("");
            }
            this._removeColorClasses();
            this._inUseState(this.currentCode, false);
            this.currentCode = null;
            this.cache["current"] = {};
            if (callback) {
                callback();
            }
        }
        if ($(this.input).is("[readonly]")) {
            FORMS.autosizeInput(this.$el.find(".phx-combobox-input"));
        } else {
            FORMS.resetSizeInput(this.$el.find(".phx-combobox-input"));
        }
    },

    _setInputValue: function(value) {
        var fields = "";

        if (this.name) {
            fields = $("." + this.name.replace(/\./g, "\\."), this.el);
        }
        _.each(fields, function(field) {
            if ($(field).is("span")) {
                $(field).html(value);
            } else {
                $(field).val(value);
            }
        }, this);
    },

    /**
     * Called the first time to initialize the context of the combo
     */
    habilitationContext: function(context) {
        if (this.coll) {
            if (STR.isBlank(this.coll.habContext) || !_.isEqual(this.coll.habContext.toJSON(), context.toJSON())) {
                this.clearCache();
            }

            this.stopListening();
            this.coll.setHabContext(context);
            this.listenTo(context, "change", this.clearCache);
        }

    },

    /**
     * This function is used to filter the response received from the WS
     * It can be overriden thru the function 'setFilter'
     *
     */
    _filter: function(response) {
        return response;
    },

    /**
     * Apply the filter to the response
     *
     */
    _applyFilter: function(response) {
        var filtered = response;
        if (this._filter) {
            filtered = this._filter(response);
        }
        if (this.required && STR.isBlank(this.autreText)) {
            // we remove the empty row
            return _.filter(filtered, function(item) {
                if (STR.isBlank(item)) {
                    return false;
                } else {
                    return !STR.isBlank(item.id);
                }
            });
        }
        return filtered;
    },

    /**
     * Pass a callback to the new filter function to be used. This will replace the default '_filter' behavior.
     */
    setFilter: function(filterCallback) {
        var existFiltrePrec = (typeof this._filter === "function");

        this._filter = filterCallback;
        //If we do the call, we need to re-apply the filter to see the corrects results
        if ((this.coll && this.coll.attributes && !_.isEmpty(this.coll.attributes[0])) || (existFiltrePrec && this.enum && this.enum.length > 0)) {
            this.refreshCombo = true;
        }
    },

    /**
     * Public function to call the underlying WS of the combo
     * A callback function can be provided that will be called once the data is available
     */
    fetchCombo: function(doneCallback) {
        this._fetchCombo(doneCallback);
    },

    /**
     * Set the state of the combo, taking in account the Habilitations
     */
    enable: function(enabled, a_context) {
        var context = a_context;

        if (!context) {
            context = this.$el;
        }
        FORMS.setFieldReadonly(context.find(".phx-combobox-input"), !enabled);
    },

    /**
     * Enables/Disables the Combo
     */
    _enable: function(enabled) {
        $(".phx-combobox-input", this.$el).attr("readonly", !enabled);
        $(".phx-combobox-content", this.$el).attr("readonly", !enabled);
        $(".phx-combobox-button", this.$el).css("display", enabled ? "" : "none");

        if (enabled) {
            $(".phx-combobox-input", this.$el).css("width", "");
        } else {
            FORMS.autosizeInput($(".phx-combobox-input", this.$el));
        }
    },

    /**
     * Retrieve the current value of the combo
     */
    getItem: function() {
        var item = this._getItemById(this.currentCode);
        var _this = this;

        if (!item) {
            item = _.find(this.cache["pendingInUse"], function(o) {
                return String(o.id) === String(_this.currentCode);
            });
        }
        return item;
    },

    getItemId: function() {
        var item = this.getItem();
        var returnedValue = null;

        // user invented values are not valid
        if (!STR.isBlank(item)) {
            returnedValue = item.id;
        } else {
            returnedValue = this.currentCode;
        }

        return returnedValue;
    },

    setCache: function(term, list) {
        this.term = term;
        this.cache[this.term] = this._formatResponse(list);
    },

    getCache: function() {
        return this.cache[this.term];
    },

    clearCache: function() {
        for (var i in this.cache) {
            if (Object.prototype.hasOwnProperty.call(this.cache, i)) {
                this.cache[i].length = 0;
                delete this.cache[i];
            }
        }
        this.cache["pendingInUse"] = this.cache["pendingInUse"] || [];
    },

    clearColl: function() {
        if (this.coll instanceof Backbone.Collection) {
            if (this.coll._events) {
                this.coll.trigger("reset");
            } else {
                //Pour rédemarrer la valeur de this.coll.pagination.size-> "resetPagination"
                this.coll.resetPagination();
                this.coll.reset();
            }
        } else {
            // This removes the id too
            this.coll.clear();
        }
    },

    /**
     * Method to do when the combo value is changed. Add the style color and background to the
     * selected value
     */
    _renderColorComboChange: function(item) {
        var bakRGB = "";
        var textRGB = "";
        var className = "";

        if (item && item.coularp) {
            if (!STR.isBlank(item)) {
                bakRGB = this._getColorRGB(item.coularp);
                textRGB = this._getColorRGB(item.coulavp);

                //USE CLASSNAME OR COULARP AND COULAVP, NOT BOTH
                //If component applies a class to its options to define background and text color instead of coularp and coulavp
                if (!STR.isBlank(item.className)) {
                    className = item.className;
                }
            }
            $(this.el).removeClass("ui-phx-anomalie-bloquante ui-phx-anomalie-persistante ui-phx-anomalie-non-bloquante ui-phx-anomalie-ignoree");
            if (!STR.isBlank(className)) {
                $(this.el).addClass(className);
            } else {
                $(this.input).css("color", textRGB);
                $(this.input).css("background-color", bakRGB);
            }
        }
        this._removeColorClasses();
        if (item && (item.affichage || (item.style && item.style.affichage))) {
            var affichage = (item.style && item.style.affichage) ? item.style.affichage : item.affichage;

            //add new color class
            $(this.input).addClass(affichage);
        }
        this._setInputValue(this.inputRender(item));
    },

    /**
     * Removes the color classes in the combo
     */
    _removeColorClasses: function() {
        //Delete all the color classes
        if ($(this.input) && $(this.input).length > 0) {
            var classes = $(this.input)[0].className.split(" ").filter(function(c) {
                return (c.lastIndexOf("ui-phx-color", 0) !== 0 && c.lastIndexOf("ui-phx-Variation", 0) !== 0);
            });
            $(this.input)[0].className = $.trim(classes.join(" "));
        }
    },

    /**
     * Gets the color in css style
     */
    _getColorRGB: function(color) {
        var l_rtn = "rgb(255,255,255)";

        if (color) {
            l_rtn = "rgb(" + color.coder + "," + color.codeg + "," + color.codeb + ")";
        }
        return l_rtn;
    },

    _renderItem: function(component, ul, item) {
        var colBackground = undefined;
        var colText = undefined;
        var text = this.optionsRender(item.attrs);
        var emptyItem = false;
        var a = null;

        if (text === "") {
            text = "&nbsp;Empty";
            emptyItem = true;
        }
        a = $("<a>").text(text);
        if (!STR.isBlank(item.attrs.desc)) {
            a.attr("title", item.attrs.desc);
        }

        if (emptyItem === true) { //text-indent in order to avoid the text (Empty) to be shown as an option
            a.css("text-indent", "-9999px");
        }
        //If the component has background color, apply it to the option.
        if (!STR.isBlank(item.attrs.coularp)) {
            colBackground = item.attrs.coularp;
            a.css("background-color", "rgb(" + colBackground.coder + "," + colBackground.codeg + "," + colBackground.codeb + ")");
        }
        //If the component has text color, apply it to the option.
        if (!STR.isBlank(item.attrs.coulavp)) {
            colText = item.attrs.coulavp;
            a.css("color", "rgb(" + colText.coder + "," + colText.codeg + "," + colText.codeb + ")");
        }
        if (!STR.isBlank(item.attrs.affichage) || (!STR.isBlank(item.attrs.style) && !STR.isBlank(item.attrs.style.affichage))) {
            var affichage = (item.attrs.style && item.attrs.style.affichage) ? item.attrs.style.affichage : item.attrs.affichage;
            a.addClass(affichage);
        }
        //		$(ul).zIndex(this.$el.zIndex() + 1);
        if (String(item.id) === String(this.currentCode) || item.inUse === false) {
            var li = $("<li>").attr("data-value", item.id);

            return li.append(a).appendTo(ul);
        } else {
            return $("");
        }
    },

    showErrors: function() {
        var span = this.$el.find(".phx-combobox-content");

        if (!span.hasClass("ui-state-error")) {
            span.addClass("ui-state-error");
        }
    },

    cleanErrors: function() {
        var span = this.$el.find(".phx-combobox-content");

        if (span.hasClass("ui-state-error")) {
            span.removeClass("ui-state-error");
        }
    },

    /**
     * Paints the selected items of the view
     */
    _paintItems: function() {
        var self = this;

        this.$el.find(".phx-list-builder-selection").empty();
        _.each(this.selection.models, function(value) {
            var label = self.optionsRender(value.attributes.attrs);
            var itemView = new ComboBox2ResultItemView({ label: label, model: value });
            self.$el.find(".phx-list-builder-selection").append(itemView.render().el);
        });

        if (this.selection.models.length === 0) {
            this.$el.find(".phx-list-builder-selection").css("display", "inherit");
        } else {
            this.$el.find(".phx-list-builder-selection").css("display", "");
        }

        this.$el.find(".phx-list-builder-selection").position({
            my: "left top",
            at: "left bottom",
            of: self.$el.find(".phx-list-builder-wrap")
        });
    },

    /**
     * Sets the selected values of the list
     */
    setValues: function(coll) {
        this.selection.reset(null, { silent: true });
        if (coll) {
            this.selection.add(coll.toJSON(), { parse: true });
        }
        // paint selected values
        this._paintItems();
        this.model.on("change:omitedViews", this._omitViews, this);
        this.model.on("resetRendered", this._resetRendered, this);
    },

    /**
     * Gets the selected elements
     */
    getValues: function() {
        return this.selection;
    },

    /**
     * Selects an item in the view
     */
    _selectItem: function(event) {
        var id = this.currentCode;

        if (!STR.isBlank(id)) {
            var attrs = this._getItemById(id);
            this._addItem(event, attrs);
        }
        return false;
    },

    /**
     * Adds an item to the selection
     */
    _addItem: function(event, attrs) {
        // clear input field and set focus to it.
        $(this.el).find(".phx-list-builder-select").val("");
        $(this.el).find(".phx-list-builder-select").focus();
        // add item to selection list
        if (STR.isBlank(this.selection.get(attrs.id))) {
            this.selection.add([attrs.attrs], { parse: true });
            if (this.addItemCallback) {
                this.addItemCallback(attrs.attrs, event);
            }
            // paint items
            this._paintItems();
            // Notify edition
        }
        // retur false to prevent bubbling of event
        return false;
    },

    /**
     * Resets the painted items
     */
    _resetItems: function() {
        // paint items
        this._paintItems();
        // Notify edition
        $(this.el).find(".phx-list-builder-select").trigger("change");
    },

    /**
     * Deletes an existing element from the component selection list
     */
    _removeItem: function(model, event) {
        // remove item from collection
        this.selection.remove(model);
        if (this.removeItemCallback) {
            this.removeItemCallback(model.attributes.attrs, event);
        }
        $(this.el).find(".phx-list-builder-select").trigger("focus");
        // repaint
        this._paintItems();
    },

    /**
     * Cleans the selection list and the component's value
     */
    clean: function() {
        this.$el.find(".phx-list-builder-select").val("");
        if (this.selection) {
            this.selection.reset();
        }
    },
    /**
     * Set the height of the combo list in percentage
     */
    _setHeight: function() {
        if (this.height) {
            $(this.input).autocomplete("widget").css("max-height", this.height + "%");
        }
    }
});