import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import { CWSTR } from './cwStr';
import { CWTYPE } from '../tda/cwTda';
import { i18n } from '../i18n.js';


/**
 * Utilities to manage the workflow
 */
export class CWWKF {

  /**
   * Construct the first line of a workflow message
   *
   */
  static buildLine(model: Backbone.Model): string {
    let result = "";
    const date = CWTYPE.DATE.format(model.get("moments")[0]["@date"], CWTYPE._getFormatByCode("DATE_A"));
    const codefonctionnel = model.get("codefonctionnel");
    const resp = CWWKF._retrieveMessageCode(codefonctionnel);
    const messageCode = resp[0];
    const style = resp[1];

    const messageDate = " " + i18n.t('messages:GL_1026', { "1": "<span class='cw-texteNormal'>" + date + " </span>", interpolation: { escapeValue: false } });
    let messageAuthor = !CWSTR.isBlank(model.get('auteur')['@auteur']) ? model.get("auteur").message : model.get('auteur')['message'].split(" ")[0] + " @auteur";
    model.get('auteur')['@auteur'] = !CWSTR.isBlank(model.get('auteur')['@auteur']) ? "<span class='cw-texteNormal'>" + model.get('auteur')['@auteur'] + "</span>" : "<span class='cw-texteNormal'>" + model.get('auteur')['message'].split(" ")[1] + "</span>";
    messageAuthor = CWWKF._buildMessageParametres(messageAuthor, model.get("auteur"));

    result += "<div><span class='" + style + " cw-texteImportant'>" + messageCode + "</span>" + messageDate + messageAuthor + "</div>";

    if (!CWSTR.isBlank(model.get("commentaire"))) {
      result += "<div class='cw-texteSuperAllege'>" + model.get("commentaire") + "</div>";
    }
    return result;
  }

  /**
   * Construct the historical information line of a workflow message (as a table row)
   *
   */
  static buildHistoLine(model: Backbone.Model): string {
    let result = "";
    const date = CWTYPE.DATE.format(model.get("moments")[0]["@date"], CWTYPE._getFormatByCode("DATE_A"));
    const codefonctionnel = model.get("codefonctionnel");
    const resp = CWWKF._retrieveMessageCode(codefonctionnel);
    const messageCode = resp[0];
    const style = resp[1];

    const messageDate = " " + i18n.t('messages:GL_1026', { "1": "<b>" + date + "</b>", interpolation: { escapeValue: false } });
    let messageAuthor = " <b>" + model.get("auteur").message + "</b>";
    messageAuthor = CWWKF._buildMessageParametres(messageAuthor, model.get("auteur"));

    result += "<div><span class='" + style + "'>" + messageCode + "</span>" + messageDate + messageAuthor + "</div>";

    return result;
  }

  /**
   * Construct the historical information line of a workflow message (as a cell)
   *
   */
  static buildHistoLine2(model: Backbone.Model): JQuery {
    const date = CWTYPE.DATE.format(model.get("moments")[0]["@date"], CWTYPE._getFormatByCode("DATE_A"));
    const codefonctionnel = model.get("codefonctionnel");
    const resp = CWWKF._retrieveMessageCode(codefonctionnel);
    const messageCode = resp[0];
    const style = resp[1];

    const messageDate = " " + i18n.t('messages:GL_1026', { "1": "<b>" + date + "</b>", interpolation: { escapeValue: false } });
    let messageAuthor = " <b>" + model.get("auteur").message + "</b>";
    messageAuthor = CWWKF._buildMessageParametres(messageAuthor, model.get("auteur"));

    const td = $("<span>");
    const span = $("<span>");
    span.addClass(style);
    span.html(messageCode);
    td.html(span.html());
    td.append(messageDate);
    td.append(messageAuthor);

    return td;
  }

  /**
   * merge parameters values inside preformated message
   *
   */
  private static _buildMessageParametres(message: string, parametresBeforeFilter: { [key: string]: any }): string {
    const parametres: { [key: string]: any } = {};
    const keysParametres: string[] = _.keys(parametresBeforeFilter);
    let indexLibelle = 0;
    //Delete message from parametres because it is the message not a parametre
    _.each(parametresBeforeFilter, function (item) {
      if (keysParametres[indexLibelle] !== "message") {
        parametres[keysParametres[indexLibelle]] = item;
      }
      indexLibelle++;
    });

    let result = null;
    if (!CWSTR.isBlank(message)) {
      result = message;
      if (!CWSTR.isBlank(parametres)) {
        const array = [];
        for (const i in parametres) {
          if (i) {
            array.push(i);
          }
        }
        for (let j = 0; j < array.length; j++) {
          result = result.replace(array[j], parametres[array[j]]);
        }
      }
    }
    return result;
  }

  /**
   * retrieve the workflow message and style to use for a given 'codefonctionnel'
   *
   */
  public static _retrieveMessageCode(codefonctionnel: string): Array<string> {
    let messageCode = "";
    let style = "";
    switch (codefonctionnel) {
      case "CO_AJ_D":
        messageCode = i18n.t('messages:GL_1010');
        style = "c-wkfHisto__statutDemande";
        break;
      case "CO_AT_D":
        messageCode = i18n.t('messages:GL_1010');
        style = "c-wkfHisto__statutDemande";
        break;
      case "CO_AT_V":
        messageCode = i18n.t('messages:GL_1011');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "CO_AJ_V":
        messageCode = i18n.t('messages:GL_1011');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "CO_SU_D":
        messageCode = i18n.t('messages:GL_1012');
        style = "c-wkfHisto__statutDemande";
        break;
      case "CO_SU_V":
        messageCode = i18n.t('messages:GL_1013');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "CO_MD_D":
        messageCode = i18n.t('messages:GL_1039');
        style = "c-wkfHisto__statutDemande";
        break;
      case "CO_MD_V":
        messageCode = i18n.t('messages:GL_1014');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "CO_AD":
      case "CO_AD_D":
        messageCode = i18n.t('messages:GL_1015');
        style = "c-wkfHisto__statutDemande";
        break;
      case "CO_AD_V":
        messageCode = i18n.t('messages:GL_1015');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "CO_AS":
        messageCode = i18n.t('messages:GL_1016');
        break;
      case "RE_RED_D":
        messageCode = i18n.t('messages:GL_1017');
        style = "c-wkfHisto__statutEnCours";
        break;
      case "RE_ACD_D":
        messageCode = i18n.t('messages:GL_1018');
        style = "c-wkfHisto__statutEnCours";
        break;
      case "RE_ACD_V":
        messageCode = i18n.t('messages:GL_1020');
        style = "c-wkfHisto__statutAccepte";
        break;
      case "RE_RED_V":
        messageCode = i18n.t('messages:GL_1019');
        style = "c-wkfHisto__statutRefuse";
        break;
      case "RE_RES_D":
        messageCode = i18n.t('messages:GL_1021');
        style = "c-wkfHisto__statutEnCours";
        break;
      case "RE_RES_V":
        messageCode = i18n.t('messages:GL_1023');
        style = "c-wkfHisto__statutRefuse";
        break;
      case "RE_ACS_D":
        messageCode = i18n.t('messages:GL_1022');
        style = "c-wkfHisto__statutEnCours";
        break;
      case "RE_ACS_V":
        messageCode = i18n.t('messages:GL_1024');
        style = "c-wkfHisto__statutAccepte";
        break;
      case "RE_AJ_D":
        messageCode = i18n.t('messages:GL_1010');
        style = "c-wkfHisto__statutDemande";
        break;
      case "RE_AJ_V":
        messageCode = i18n.t('messages:GL_1011');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "RE_SU_D":
        messageCode = i18n.t('messages:GL_1012');
        style = "c-wkfHisto__statutDemande";
        break;
      case "RE_SU_V":
        messageCode = i18n.t('messages:GL_1013');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "RE_MO_V":
        messageCode = i18n.t('messages:GL_1014');
        style = "c-wkfHisto__statutHorsValidation";
        break;
      case "REL_D":
        messageCode = i18n.t('messages:GL_1025');
        break;
      case "REL_S":
        messageCode = i18n.t('messages:GL_1025');
        break;
      default:
        messageCode = "";
        break;
    }
    return [messageCode, style];
  }

}
