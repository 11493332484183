module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n  <div class="form-group col-3">\n    <label for="code">'+
((__t=(i18n.t("common:selecteuractivites.dlgcode")))==null?'':__t)+
'</label>\n    <input type="text" class="code form-control" value="" size="15" maxlength="15" />\n  </div>\n  <div class="form-group col-9">\n    <label for="libelle">'+
((__t=(i18n.t("common:selecteuractivites.dlglibelle")))==null?'':__t)+
'</label>\n    <input type="text" class="libelle form-control" value="" size="25" maxlength="60" />\n  </div>\n  <div class="hierTypeFilterFields form-group col-9 d-none"></div>\n';
}
return __p;
};
