import { i18n } from 'src/i18n.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from 'utils/str.js';
import TPL_common_table_actionbar from './table_actionbar.tpl.html';

export var ActionBarView = Backbone.View.extend({
  /**
   * Event launched when the model of this view changes its mode
   */
  /**
   * Event launched when the model of this view changes the property enabled
   */
  /**
   * Event launched when the model of this view changes the property rowOrdering
   */
  /**
   * Event launched when there is any event for the model
   */
  /**
   * Event launched when a button is clicked
   */
  /**
   * Event launched when a ui-icon is clicked
   */
  /**
   * Event launched when monter button is clicked
   */
  /**
   * Event launched when descendre button is clicked
   */
  /**
   * Event launched when placer button is clicked
   */
  /**
   * Event launched when premier button is clicked
   */
  /**
   * Add this to have Outline
   *
   */

  /**
   * Constructor
   * Grid bar action View.
   * It's model supports:
   * Events: change:mode, btn:click (buttonId)
   */
  initialize: function(options) {
    var self = this;

    this.template = TPL_common_table_actionbar;

    //For simple models it's not needed to create a specific model
    this.model = new ReadOnlyModel({
      mode: "", // E:edition, C:nothing selected, R:row selected
      enabled: true,
      rowOrdering: false
    });
    this.model.on("change:mode", this._manageMode, this);
    this.model.on("change:enabled", this._manageEnabled, this);
    this.model.on("change:rowOrdering", this._manageRowOrdering, this);
    /**
     * Wether all buttons in this view are enabled or not
     */
    this.isEnabled = true;
    /**
     * Wether arrows to move rows are enabled or not
     */
    this.disableArrows = !this.model.get("rowOrdering"); //To manage the ability to click on the arrows
    /**
     * Object that represent all the properties for each button
     */
    this.buttons = {};
    this.buttons.habilitation = [];
    this.buttons.visible = [];
    this.buttons.override = [];
    this.buttons.custom = [];
    this.buttons.visibleInModeC = []; // buttons added manually that are visible when nothing is selected
    // rights set by habilitations
    this.buttons.habilitation["delete"] = true;
    this.buttons.habilitation["copy"] = true;
    this.buttons.habilitation["new"] = true;
    this.buttons.habilitation["moveUp"] = true;
    this.buttons.habilitation["moveDown"] = true;
    this.buttons.habilitation["movePlacer"] = true;
    this.buttons.habilitation["movePremier"] = true;
    // rights set by UC to indicate if button is visible or not
    this.buttons.visible["delete"] = true;
    this.buttons.visible["copy"] = true;
    this.buttons.visible["new"] = true;
    // rights set to indicate if a different behavior than the default
    this.buttons.override["delete"] = false;
    this.buttons.override["copy"] = false;
    this.buttons.override["new"] = false;

    // Delegate the events row:* to the coll
    this.model.on("all", function(eventName) {
      if (_.indexOf(eventName, ":") > 0) {
        var eventParts = eventName.split(":");
        var event = eventParts[0];
        var className = eventParts[1];
        switch (event) {
          case "hide":
            self.buttons.visible[className] = false;
            break;
          case "show":
            self.buttons.visible[className] = true;
            break;
          case "hab.hide":
            if (className === "all") {
              for (var i in self.buttons.habilitation) {
                if (i) {
                  self.buttons.habilitation[i] = false;
                }
              }
              self._showRowOrderingArrows(false);
            } else {
              self.buttons.habilitation[className] = false;
            }
            break;
          case "hab.show":
            if (className === "all") {
              for (var j in self.buttons.habilitation) {
                if (j) {
                  self.buttons.habilitation[j] = true;
                }
              }
              self._showRowOrderingArrows(true);
            } else {
              self.buttons.habilitation[className] = true;
            }
            break;
          case "override.hide":
            self.buttons.override[className] = false;
            break;
          case "override.show":
            self.buttons.override[className] = true;
            break;
          case "custom.hide":
            self.buttons.custom[className] = false;
            break;
          case "custom.show":
            self.buttons.custom[className] = true;
            break;
          case "moved":
            if (className === "hide") {
              self._refreshMoveLineButtons(true, true);
            } else if (className === "top") {
              self._refreshMoveLineButtons(true, false);
            } else if (className === "bottom") {
              self._refreshMoveLineButtons(false, true);
            } else if (className === "enableArrows") {
              self.disableArrows = false;
            } else if (className === "disableArrows") {
              self.disableArrows = true;
            } else {
              self._refreshMoveLineButtons(false, false);
            }
            break;
          default:
            break;
        }
        self._manageMode();
      }
    });

    /**
     * External buttons
     */
    this.newButtons = [];

    if (options && options.positionNewButtonsOnLeft) {
      this.positionNewButtonsOnLeft = options.positionNewButtonsOnLeft;
    }

    /**
     *  Object that represent all the titles of infobulle for each button
     * */
    this.buttonsTitle = {};
  },

  /**
   * Enables or disables buttons when model property enabled is changed
   */
  _manageEnabled: function() {
    this.enabled(this.model.get("enabled"));
  },

  /**
   * Enables or disables buttons
   */
  enabled: function(enable) {
    this.model.set("enabled", enable, { silent: true });
    this.isEnabled = enable;
    if (this.isEnabled) {
      $(this.el).removeClass("ui-state-disabled");
      $("button", this.el).css("cursor", "");
    } else {
      $(this.el).addClass("ui-state-disabled");
      $("button", this.el).css("cursor", "default");
    }
  },

  /**
   * Checks that mode is valid. If it is not an error is shown. Enables/disables buttons
   */
  _manageMode: function() {
    var newMode = this.model.get("mode");
    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in ActionBar : " + newMode);
    }
    this._disableButtons(newMode);

    this._showRowOrderingArrows(newMode === "R");
  },

  /**
   * Hide/show buttons depending on the mode of the action bar
   */
  _disableButtons: function(mode) {
    var self = this;

    var showDeleteMenu = this.buttons.habilitation["delete"] && this.buttons.visible["delete"];
    var overrideShowDeleteMenu = showDeleteMenu && this.buttons.override["delete"];

    var showNewMenu = this.buttons.habilitation["new"] && this.buttons.visible["new"];

    var showCopyMenu = this.buttons.habilitation["copy"] && this.buttons.visible["copy"];

    $(this.el).find(".new-menu .nwBtn").html(i18n.t('common:grid.nw'));

    switch (mode) {
      case "E": // edition
        $(this.el).find(".delete-menu").hide();
        $(this.el).find(".new-menu").hide();
        _.each(this.newButtons, function(button) {
          $(self.el).find("." + button.buttonAction + "-menu").hide();
        });
        break;
      case "R": // item selected
        if (showDeleteMenu) {
          $(this.el).find(".delete-menu").css("display", "inline-block");
        } else {
          $(this.el).find(".delete-menu").hide();
        }
        if (showCopyMenu || showNewMenu) {
          $(this.el).find(".new-menu").css("display", "inline-block");
          $(this.el).find(".new-menu .ui-icon").css("display", "inline-block");

          if (showCopyMenu) {
            $(this.el).find(".new-menu-content .copy").show();
            if (!showNewMenu) {
              var l_tmpClass = $(this.el).find(".new-menu").attr("class");

              l_tmpClass = "copy-menu " + l_tmpClass;
              $(this.el).find(".new-menu").attr('class', l_tmpClass).removeClass("new-menu");
              l_tmpClass = $(this.el).find(".copy-menu").find(".new").attr("class");
              l_tmpClass = "copy " + l_tmpClass; //first element
              $(this.el).find(".copy-menu").find(".new").attr("class", l_tmpClass).removeClass("new");
              $(this.el).find(".copy-menu .nwBtn").html(i18n.t('common:grid.copy'));
              if (!$(this.el).find(".copy-menu").is(":visible") && this.buttons.visible["copy"]) {
                $(this.el).find(".copy-menu").show();
              }
            }
          } else {
            $(this.el).find(".new-menu-content .copy").hide();
            $(this.el).find(".new-menu .ui-icon").hide();
          }
          if (showNewMenu) {
            $(this.el).find(".new-menu-content .new").show();
          } else {
            $(this.el).find(".new-menu-content .new").hide();
            if (showCopyMenu) {
              $(this.el).find(".copy-menu .ui-icon").hide();
            } else {
              $(this.el).find(".new-menu .ui-icon").hide();
            }
          }
        } else {
          $(this.el).find(".new-menu").hide();
          if ($(this.el).find(".copy-menu").is(":visible") && !this.buttons.visible["copy"]) {
            $(this.el).find(".copy-menu").hide();
          }
        }

        _.each(this.newButtons, function(button) {
          var action = button.buttonAction;
          if (self.buttons.custom[action] !== false) {
            $(self.el).find("." + action + "-menu").css("display", "inline-block");
          } else {
            $(self.el).find("." + action + "-menu").hide();
          }

          if (self.buttons.habilitation[action] === false) {
            $(self.el).find("." + action + "-menu").hide();
          }
        });

        break;
      case "C": // nothing selected
        if (overrideShowDeleteMenu) {
          $(this.el).find(".delete-menu").css("display", "inline-block");
        } else {
          $(this.el).find(".delete-menu").hide();
        }

        if (showNewMenu) {
          $(this.el).find(".new-menu").css("display", "inline-block");
          $(this.el).find(".new-menu-content").hide();
          $(this.el).find(".new-menu .ui-icon").hide();
          $(this.el).find(".new-menu-content .copy").hide();
          $(this.el).find(".new-menu-content .new").hide();
        } else {
          $(this.el).find(".new-menu").hide();
          if (showCopyMenu) {
            $(this.el).find(".copy-menu").hide();
          }
        }

        _.each(this.newButtons, function(button) {
          var action = button.buttonAction;
          if (self.buttons.visibleInModeC[action] === true) {
            $(self.el).find("." + action + "-menu").css("display", "inline-block");
          } else {
            $(self.el).find("." + action + "-menu").hide();
          }

          if (self.buttons.habilitation[action] === false) {
            $(self.el).find("." + action + "-menu").hide();
          }
        });
        break;
      default:
        break;
    }
  },

  /**
   * Definition of Jquery events managed by the view
   */
  events: {
    "click .button": "_clickListener",
    "click .ui-icon": "_toggleMenu",
    "click .monter": "_clickListenerMove",
    "click .descendre": "_clickListenerMove",
    "click .placer": "_clickListenerMove",
    "click .premier": "_clickListenerMove",
    "keydown": "_keyEvent"
  },

  /**
   * Manages keydown action
   */
  _keyEvent: function(event) {
    if (event.keyCode === 13 || event.keyCode === 32) { // enter or space
      $(event.target).click();
      event.preventDefault();
    }
  },

  /**
   * Manages click over a button
   */
  _clickListener: function(event) {

    if (this.isEnabled) {
      var btnClicked = event.target.className.split(" ")[0];
      this.model.trigger("btn:click", btnClicked);
    }

    $(".new-menu-content", this.el).hide();

    return false;
  },

  /**
   * When rows are clicked
   */
  _clickListenerMove: function(event) {

    if (this.isEnabled) {
      var btnClicked = event.target.className.split(" ")[0];
      if (!$(event.target).hasClass("ui-state-disabled")) {
        this.model.trigger("btn:click", btnClicked);
      }
    }

    return false;
  },

  /**
   * Renders this view
   */
  render: function() {
    var self = this;
    var json = { "grid": i18n };
    $(this.el).html(this.template(json));

    if (!_.isEmpty(this.buttonsTitle)) {
      _.each(this.buttonsTitle, function(title, button) {
        if (!STR.isBlank(title)) {
          $(self.el).find("." + button).attr("title", title);
        }
      });
    }
    this._renderNewButtons();
    //Default mode
    this._disableButtons(this.model.get("mode"));

    // hide arrows that allows moving lines up or down
    this._showRowOrderingArrows(false);
    return this;
  },
  /**
   * Shows/Hides menu with many options
   */
  _toggleMenu: function(event) {
    if ($(event.target).parent().hasClass("new-menu")) {
      this._toggleNewMenu(event);
    } else {
      this._toggleExternalMenu(event, $(event.target).parent()[0].className.split(" ")[0].replace("-menu", ""));
    }
  },
  /**
   * Shows/Hides menu with many options
   */
  _toggleNewMenu: function(event) {
    var self = this;

    if (!$(this.el).find(".new-menu .ui-icon").is(":visible")) {
      if (this.isEnabled) {
        var showCopyMenu = this.buttons.habilitation["copy"] && this.buttons.visible["copy"];
        var showNewMenu = this.buttons.habilitation["new"] && this.buttons.visible["new"];
        if (showCopyMenu && !showNewMenu) {
          this.model.trigger("btn:click", "copy");
        } else {
          this.model.trigger("btn:click", "new");
        }
      }
    } else {
      if ($(".new-menu-content", this.el).is(":visible")) {
        $(".new-menu-content", this.el).hide();
      } else {
        $(".new-menu-content", this.el).show();
        $(".new-menu-content", this.el).position({
          my: "left top",
          at: "left bottom",
          of: $(event.target).parent()
        });
        //If you click out of the menu, close the menu.

        $(document).one("mousedown", function(event) {
          var element = $(self.el).find(event.target);
          if (element.length === 0) {
            $(".new-menu-content", self.el).hide();
          }
        });
      }
    }
    return false;
  },

  /**
   * Adds a button
   */
  addButton: function(buttonText, buttonAction, hasMenu, menuOptions, title) {
    this.newButtons.push({
      buttonText: buttonText,
      buttonAction: buttonAction,
      hasMenu: hasMenu,
      menuOptions: menuOptions,
      title: title
    });

    this.buttons.visibleInModeC[buttonAction] = false;
  },
  /**
   * Replace the button options
   */
  replaceButtonOptions: function(buttonAction, menuOptions) {
    menuOptions.reverse();
    _.each(this.newButtons, function(obj) {
      if (obj.buttonAction === buttonAction) {
        obj.menuOptions = menuOptions;
      }
    }, this);

    var $menu = $("." + buttonAction + "-menu-content", this.el);
    if ($menu) {
      $menu.empty();

      var $ul = $("<ul></ul>");
      var size = menuOptions.length;
      for (var j = 0; j < size; j++) {
        if (STR.isBlank(menuOptions[j].action)) {
          $ul.prepend("<span class='nwBtn' style='white-space:nowrap;'>" + menuOptions[j].text + "</span>");
        } else {
          $ul.prepend("<li class='" + menuOptions[j].action + " button nwBtn phx-hover' data-cy='" + menuOptions[j].action + "' style='white-space:nowrap;'>" + menuOptions[j].text + "</li>");
        }
      }
      $menu.prepend($ul);
    }
  },
  /**
   * Makes the button visible in mode consultation
   */
  setButtonVisibleInModeC: function(buttonAction, visible) {
    this.buttons.visibleInModeC[buttonAction] = visible;
  },
  /**
   * Render a new button
   */
  _renderNewButtons: function() {
    var actionBar = this.$el.find(".grid-action-bar");

    var buttonsLength = this.newButtons.length;

    for (var i = 0; i < buttonsLength; i++) {

      var $buttonDiv = $("<div></div>");
      $buttonDiv.addClass(this.newButtons[i].buttonAction + "-menu");
      $buttonDiv.addClass("phx-grid-title-bar-menu");
      $buttonDiv.addClass("phx-hover");

      if (this.newButtons[i].title && !STR.isBlank(this.newButtons[i].title)) {
        $buttonDiv.append("<span tabindex='0' class='" + this.newButtons[i].buttonAction + " button nwBtn' data-cy='" + this.newButtons[i].buttonAction + "' title='" + this.newButtons[i].title + "'>" + this.newButtons[i].buttonText + "</span>");
      } else {
        $buttonDiv.append("<span tabindex='0' class='" + this.newButtons[i].buttonAction + " button nwBtn' data-cy='" + this.newButtons[i].buttonAction + "' >" + this.newButtons[i].buttonText + "</span>");
      }

      if (this.positionNewButtonsOnLeft) {
        actionBar.prepend($buttonDiv);
      } else {
        actionBar.append($buttonDiv);
      }
      if (this.newButtons[i].hasMenu) {
        $buttonDiv.append("<span class='ui-icon ui-icon-triangle-1-s phx-hover'></span>");

        var $menu = $("<div style='display:none'></div>");
        $menu.addClass(this.newButtons[i].buttonAction + "-menu-content");
        $menu.addClass("phx-grid-title-bar-menu-content");
        $menu.addClass("ui-menu");

        var $ul = $("<ul></ul>");
        var size = this.newButtons[i].menuOptions.length;
        for (var j = 0; j < size; j++) {
          if (STR.isBlank(this.newButtons[i].menuOptions[j].action)) {
            $ul.append("<span class='nwBtn' style='white-space:nowrap;'>" + this.newButtons[i].menuOptions[j].text + "</span>");
          } else {
            $ul.append("<li class='" + this.newButtons[i].menuOptions[j].action + " button nwBtn phx-hover' data-cy='" + this.newButtons[i].menuOptions[j].action + "' style='white-space:nowrap;'>" + this.newButtons[i].menuOptions[j].text + "</li>");

          }
        }

        $menu.append($ul);
        actionBar.append($menu);
      }
    }
  },
  /**
   * Toggles an external menu
   */
  _toggleExternalMenu: function(event, menuId) {
    var self = this;

    if (this.isEnabled === true) {
      if ($("." + menuId + "-menu-content", this.el).is(":visible")) {
        $("." + menuId + "-menu-content", this.el).hide();
      } else {
        $("." + menuId + "-menu-content", this.el).show();
        $("." + menuId + "-menu-content", this.el).position({
          my: "left top",
          at: "left bottom",
          of: $(event.target).parent()
        });
        //If you click out of the menu, close the menu.

        $(document).one("mousedown", function(event) {
          var element = $(self.el).find(event.target);
          if (element.length === 0) {
            $("." + menuId + "-menu-content", self.el).hide();
          }
        });
        $(window).one("resize", function() {
          $("." + menuId + "-menu-content", self.el).hide();
        });
      }
    } else {
      $("." + menuId + "-menu-content", this.el).hide();
    }

    return false;
  },

  /**
   * hide/show arrow to allow row ordering
   */
  _manageRowOrdering: function() {
    this.disableArrows = !this.model.get("rowOrdering");
    this._showRowOrderingArrows(true);
  },
  /**
   * show arrow to allow row ordering
   */
  _showRowOrderingArrows: function(show) {
    var hab = this.buttons.habilitation["moveUp"] || this.buttons.habilitation["moveDown"] || this.buttons.habilitation["movePlacer"] || this.buttons.habilitation["moveUp"];
    if (this.model.get("rowOrdering") === true && show && hab) {
      $(".rowOrdering", this.el).css("display", "inline-block");
    } else {
      $(".rowOrdering", this.el).hide();
    }
  },
  /**
   * Refreshes move line buttons
   */
  _refreshMoveLineButtons: function(disableMoveUp, disableMoveDown) {
    var montEnabled = true,
      descEnabled = true,
      placEnabled = true,
      premEnabled = true;
    if (disableMoveUp || this.buttons.habilitation["moveUp"] === false || this.disableArrows) {
      $(".rowOrdering .monter", this.el).addClass("ui-state-disabled");
      montEnabled = false;
    } else {
      $(".rowOrdering .monter", this.el).removeClass("ui-state-disabled");
    }

    if (disableMoveDown || this.buttons.habilitation["moveDown"] === false || this.disableArrows) {
      $(".rowOrdering .descendre", this.el).addClass("ui-state-disabled");
      descEnabled = false;
    } else {
      $(".rowOrdering .descendre", this.el).removeClass("ui-state-disabled");
    }

    if (disableMoveUp || this.buttons.habilitation["movePlacer"] === false || this.disableArrows) {
      $(".rowOrdering .placer", this.el).addClass("ui-state-disabled");
      placEnabled = false;
    } else {
      $(".rowOrdering .placer", this.el).removeClass("ui-state-disabled");
    }

    if (disableMoveDown || this.buttons.habilitation["movePremier"] === false || this.disableArrows) {
      $(".rowOrdering .premier", this.el).addClass("ui-state-disabled");
      premEnabled = false;
    } else {
      $(".rowOrdering .premier", this.el).removeClass("ui-state-disabled");
    }

    if (!montEnabled && !descEnabled && !placEnabled && !premEnabled) {
      $(".rowOrdering .descendre", this.el).parent().addClass("ui-state-disabled");
    } else {
      $(".rowOrdering .descendre", this.el).parent().removeClass("ui-state-disabled");
    }

    this._showRowOrderingArrows(true);
  },

  /**
   * Set title to button (for infobulle)
   */
  setTitle: function(button, title) {
    if (button && !STR.isBlank(button)) {
      this.buttonsTitle[button] = title;
    }
  }

});
