module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cwSelecteurActiviteDlg">\n    ';
if(restreindre){ 
__p+='\n    <fieldset class="cwSelecteurActiviteDlg__collaborateurFieldsetRes cw-fieldset">\n        <div class="cwSelecteurActiviteDlg__collaborateurResDiv">\n            <div class="cwSelecteurActiviteDlg__restreindre form-group">\n                <div class="custom-control custom-checkbox">\n                    <input id="restreindre" type="checkbox" class="restreindre custom-control-input" value="true" />\n                    <label for="restreindre" class="custom-control-label">'+
((__t=(i18n.t("common:selecteuractivites.dlgrestreindre")))==null?'':__t)+
'</label>\n                </div>\n            </div>\n        </div>\n    </fieldset>\n    ';
 } 
__p+='\n    <fieldset class="cwSelecteurActiviteDlg__collaborateurFieldset cw-fieldset form-group">\n        <div class="form-group cw-customControlGroup">\n            <div class="custom-control custom-checkbox">\n                <input id="competence" type="checkbox" class="competence custom-control-input" name="customCheckbox" value="true" />\n                <label for="competence" class="custom-control-label">'+
((__t=(i18n.t("common:selecteuractivites.dlgcompetence")))==null?'':__t)+
'</label>\n            </div>\n            <div class="custom-control custom-checkbox">\n                <input id="inaptitude" type="checkbox" class="inaptitude custom-control-input" name="customCheckbox" value="true" />\n                <label for="inaptitude" class="custom-control-label">'+
((__t=(i18n.t("common:selecteuractivites.dlginaptitude")))==null?'':__t)+
'</label>\n            </div>\n        </div>\n    </fieldset>\n\n    <!-- BLOCK1 TODO add it to V x-->\n    <!-- 	<div class="cwSelecteurActiviteDlg__block block-recemment"> -->\n    <!-- 		<div class="cwSelecteurActiviteDlg__block-title"> -->\n    <!-- 			<span class="cwSelecteurActiviteDlg__txt">'+
((__t=(i18n.t("common:selecteuractivites.dlgrecemment")))==null?'':__t)+
'</span> -->\n    <!-- 			<span class="phx-select-act-block-btn phx-select-act-block-btn-recemment ui-icon ui-icon-triangle-1-e"> -->\n    <!-- 			</span> -->\n    <!-- 		</div> -->\n    <!-- 		<div class="cwSelecteurActiviteDlg__block-data" style="display:none"> -->\n    <!-- 		</div> -->\n    <!-- 	</div> -->\n\n    <!-- 	 BLOCK2 -->\n    <!-- 	<div class="cwSelecteurActiviteDlg__block block-cette-periode"> -->\n    <!-- 		<div class="cwSelecteurActiviteDlg__block-title"> -->\n    <!-- 			<span class="cwSelecteurActiviteDlg__txt">'+
((__t=(i18n.t("common:selecteuractivites.dlgcette_periode")))==null?'':__t)+
'</span> -->\n    <!-- 			<span class="phx-select-act-block-btn phx-select-act-block-btn-cette-periode ui-icon ui-icon-triangle-1-e"> -->\n    <!-- 			</span> -->\n    <!-- 		</div> -->\n    <!-- 		<div class="cwSelecteurActiviteDlg__block-data" style="display:none"> -->\n    <!-- 		</div> -->\n    <!-- 	</div> -->\n\n    <!-- 	BLOCK3 -->\n    <!-- 	<div class="cwSelecteurActiviteDlg__block block-periode-precedente"> -->\n    <!-- 		<div class="cwSelecteurActiviteDlg__block-title"> -->\n    <!-- 			<span class="cwSelecteurActiviteDlg__txt">'+
((__t=(i18n.t("common:selecteuractivites.dlgperiode_precedente")))==null?'':__t)+
'</span> -->\n    <!-- 			<span class="phx-select-act-block-btn phx-select-act-block-btn-periode-precedente ui-icon ui-icon-triangle-1-e"> -->\n    <!-- 			</span> -->\n    <!-- 		</div> -->\n    <!-- 		<div class="cwSelecteurActiviteDlg__block-data" style="display:none"> -->\n    <!-- 		</div> -->\n    <!-- 	</div> -->\n\n    <!-- BLOCK4 -->\n    <div class="cwSelecteurActiviteDlg__block block-disponibles form-group">\n        <div class="cwSelecteurActiviteDlg__block-title">\n            <label class="cwSelecteurActiviteDlg__txt cw-texteRubrique">'+
((__t=(i18n.t("common:selecteuractivites.dlg_dom_disponibles")))==null?'':__t)+
'</label>\n            '+
((__t=(UTILS.getSVGIcon("fleche_pleine_bas","phx-select-act-block-btn phx-select-act-block-btn-disponibles cw-icon--primary cw-icon--clickable d-none ml-1",12)))==null?'':__t)+
'\n        </div>\n        <div class="cwSelecteurActiviteDlg__block-data">\n            <!-- DOMAINES -->\n            <div class="domaineFieldset">\n                <div class="domaineSection">\n                </div>\n            </div>\n        </div>\n    </div>\n\n\n    <!-- FILTER -->\n    <span class="filter">\n    </span>\n\n\n    <!-- ACTIVITE-TREES-VIEWS -->\n    <span class="activite-trees-Views">\n    </span>\n\n    <!-- MESSAGE GRILLE BESOIN EVO #784 -->\n    <div class="message-activite">\n        <span class="cw-texteNormal"></span>\n    </div>\n</div>';
}
return __p;
};
