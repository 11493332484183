import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CompleteWFL } from '../models/completeWFL.js';
import { CWDialogView } from './cwDialog.view';
import { CWSTR } from 'src/utils/cwStr';

const TPLSelecteurActivitesRouter = require('../cwSelecteurActiviteRouter.tpl.html');

export class CWCompleteGeneralView extends Backbone.View {

  context: any;
  parameters: any;
  workflow: typeof CompleteWFL;
  completeView: any;

  /**
   * Constructor
   * Selecteur d'activités mode complet general view. It acts as a router for the complete dialog view.
   */
  constructor(options: Backbone.ViewOptions | any) {
    super(options);
    /**
     * HTML template of the view
     */
    this.template = TPLSelecteurActivitesRouter;

    if (options && !CWSTR.isBlank(options.context)) {
      this.context = options.context;
    }
    if (options && !CWSTR.isBlank(options.parameters)) {
      this.parameters = options.parameters;
    }

    if (options && options.workflow) {
      this.workflow = options.workflow;
      //this.workflow.on("updateFilterFields", this._renderHierTypeFilterFields, this);
    }
    //Reinitialize workflow and pass to workflow context and parameters
    this.workflow.reinitialize(options);

    const paramsExtended = _.extend(options, { workflow: this.workflow });
    this.completeView = new CWDialogView(paramsExtended);
  }
  /**
   * Paints the view of the selecteur de chemin dialog component
   */
  render(): CWCompleteGeneralView {
    const json = {};

    $(this.el).append(this.template(json));
    const callback = (): void => {
      $(this.el).find(".selecteurActiviteCompleteView").html(this.completeView.render().el);
    };
    //Makes necessary fetchs to obtain data and executes callback in order to render the complete dialog view
    this.workflow.setUp(callback);
    return this;
  }

  /**
   * Sets the context and rerenders complete view
   */
  setContext(context: any): void {
    const params: { [key: string]: any } = {};
    params.context = context;
    const paramsExtended = _.extend(params, { workflow: this.workflow });
    this.completeView = new CWDialogView(paramsExtended);
    const callback = (): void => {
      $(this.el).find(".selecteurActiviteCompleteView").html(this.completeView.render().el);
    };
    this.workflow.setContext(context, callback);
  }

  /**
   * Prepares parentModel to fetch in order to obtain parents to execute "ouvrirparents" action
   * from hierarchie and structure models
   */
  _obtainCurrentVue(): number {
    let vue = 1;
    const viewName = this.completeView.activiteTreesView.workflow.menuViewsModel.get("menuView");
    switch (viewName) {
      case "FAMILLE":
        vue = 1;
        break;
      case "HIERARCHIE":
        vue = 2;
        break;
      case "STRUCTURESIMPLE":
        vue = 3;
        break;
      case "STRUCTUREDETAIL":
        vue = 4;
        break;
      default:
        vue = 1;
        break;
    }
    return vue;
  }
}
