import _ from 'underscore';
import listerevenementsDetailTableTPL from '../detailTable.tpl.html';
import { CWBaseGridColums } from 'core/grids/basegrid/cwBaseGrid.view';
import { CWEvenementModelColl, CWEvenementModelCollTypo } from '../models/cwListerEvtGen.collection';
import { CWGroupeGridModel } from 'core/grids/groupegrid/cwGroupeGrid.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListerEvetGenHistoriqueColl } from '../models/cwListerEvetGenHistorique.collection';
import { CWListerEvetGenOverloadedBaseModel } from '../models/cwListerEvtGenOverloadedBaseModel.model';
import { CWListerEvtFormView, CWListerEvtFormViewOptions } from '../views_form/cwListerEvtForm.view';
import { CWListerEvtGenModel } from '../models/cwListerEvtGen.model';
import { CWListerEvtGenWorkflow } from '../models/cwListerEvtGen.workflow';
import { CWMenuGridModel } from 'core/grids/menugrid/cwMenuGrid.model';
import { CWMenuGridView } from 'core/grids/menugrid/cwMenuGrid.view';
import { CWSTR } from 'utils/cwStr';
import { CWWKF } from 'utils/cwWkf';
import { i18n } from 'src/i18n';
import { objs } from 'src/objectsRepository';

export interface CWListerEvtTableViewOptions extends CWListerEvtFormViewOptions {
  workflow?: CWListerEvtGenWorkflow;
}

/**
 * DataGridView to be used when no validation action is required
 */
export class CWListerEvtTableView extends CWListerEvtFormView {

  public tableMode: string;

  constructor(options?: CWListerEvtTableViewOptions) {
    options = options || {};
    super(options);
    this.template = listerevenementsDetailTableTPL;
    if (options && options.context) {
      this.context = options.context;
    }
    if (options && options.parent) {
      this.parent = options.parent;
      this.cellRenderers = options.parent.tableCellRenderers;
    }
    this.model = new CWListerEvetGenOverloadedBaseModel();
    if (options.workflow) {
      this.workflow = options.workflow;
      this.workflow.updateOptions(options);
    } else {
      this.workflow = new CWListerEvtGenWorkflow(options);
      //this.workflow = new CWListerEvetGenTableWorkFlowModel(options);
    }
    this.workflow.formModel = this.model;
    this.tableModel = new CWMenuGridModel<CWEvenementModelColl, CWListerEvtGenModel>({ coll: new CWEvenementModelColl() });
    this.parent.tableModel = this.tableModel;
    this.tableModel.selectRow = (model: CWListerEvtGenModel, callback?: (model?: CWListerEvtGenModel) => void): void => {
      const loadedModel = this.tableModel.coll.get(model);

      if (loadedModel) {
        const startIndex = this.tableModel.coll.pagination.startIndex;
        const modelIndex = this.tableModel.coll.indexOf(loadedModel);

        this.tableModel.trigger("scroll:to", startIndex + modelIndex);
        this.tableModel._manageRowSelection(loadedModel);
        if (callback) {
          callback(loadedModel);
        }
        //WCAG
        loadedModel.trigger("cell:select", this.tableModel.coll.editModeCellSelected);
        this.tableModel.coll.editModeCellSelected = null;
      } else if (callback) {
        callback();
      }
    }
    this.tableModel.coll.setOmittedParams({ matricule: "", statuts: "" });
    this.tableModel.on("change:value", this._tableValueChange, this);
    this.tableModel.on("row:selectedAgain", this._tableValueChange, this);
    this.workflow.tableModel = this.tableModel;
    this.historiqueColl = new CWListerEvetGenHistoriqueColl<CWListerEvtGenModel>();
    this.historiqueColl.setHabContext(this.workflow.getHabContext());
    this.wkfEvenements = null;
    this.listenTo(objs.appRt.workflow, "change:usecase", () => {
      if (this.pjPopupView) {
        this.pjPopupView.close();
      }
    });
    if (this.context) {
      this.setContext(this.context);
    }
  }

  setContext(context: { [key: string]: any }): void {
    this.context = context;
    this.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.tableModel.coll.setHabContext(this.getHabContext());
    this.historiqueColl.setHabContext(this.getHabContext());
    // Styles for the table
    this.tableMode = this.context.ctxModeRepresentation;
    this.tableModel.coll.backupStatuts = this.context.ctxStatut; //backup for one exceptional case
    this.tableModel.coll.applyFilter(this._contextToFilter());
    this.tableModel.coll.setSortings(this.context.ctxTriDate);
    this.workflow.setContext(this.context);
  }

  setTableInfo(): void {
    if (this.table) {
      const title = i18n.t('common:listerevtgen.titleAbs');

      this.table.setTitle(title);
      this.tableModel.coll.applyFilter(this._contextToFilter());
    }
  }

  _manageTableMode(): void {
    this.tableMode = this.parent.customBar.model.get("tableMode");
    if (this.tableMode !== "Simple") {
      this._historique((): void => {
        this.tableModel.coll.trigger("reset");
      });
    } else {
      this.tableModel.coll.trigger("reset");
    }
  }

  _contextToFilter(): { [key: string]: any } {
    const params: { [key: string]: any } = {};

    params.matricule = this.context.ctxCollab.matricule;
    params.statut = this.context.ctxStatut;
    if (this.context.ctxPeriode) {
      params.datedeb = this.context.ctxPeriode;
    }
    return params;
  }

  // trigger a rowChange event when a new row is selected
  _tableValueChange(tableModel: CWGroupeGridModel): void {
    if (this.pjPopupView) {
      this.pjPopupView.close();
    }
    if (tableModel.coll.length > 0) {
      this.model.trigger("rowChange", tableModel.get("value"));
      this.parent.model.trigger("rowChange", tableModel.get("value"));
    } else {
      this.parent.model.trigger("emptyList");
    }
  }

  _initTypoCombo(code: string): void {
    super._initTypoCombo(code);
    this.tableModel.coll.reset(this.workflow.evenementsColl.where({ code: code }));
    this.tableModel.set("value", null);
  }

  _initTable(code: string, selectFirstRow?: boolean): void {
    const typoOptions = this.tableModel.coll.typo;
    let selectedTypo: CWEvenementModelCollTypo = null;
    const tblColumns: Array<CWBaseGridColums> = [];
    let table: CWMenuGridView<CWListerEvtGenModel, CWEvenementModelColl> = null;
    let sortableColumns: string[] = null;

    typoOptions.sort(function (a, b) {
      if (a.libelle > b.libelle) {
        return 1;
      }
      if (a.libelle < b.libelle) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    if (!code) {
      code = typoOptions.length > 0 ? typoOptions[0].code : null;
    }
    selectedTypo = typoOptions.length > 0 ? _.findWhere<CWEvenementModelCollTypo[]>(typoOptions, { code: code }) : {};
    this.parent.selectedTypo = selectedTypo;
    this.context.ctxTypologieEvenement = selectedTypo;
    this.workflow.trigger("selectedTypoChanged", selectedTypo);
    this._initTypoCombo(code);
    // Column: Role
    if (this.context.ctxUtilisateur === "Responsable") {
      tblColumns.push({ title: i18n.t('common:listerevtgen.role'), property: "role", width: 3 });
      if (this.context.ctxGestionCollective) {
        tblColumns.push({ title: i18n.t('common:listerevtgen.collaborateur'), property: "collaborateur", width: 3 });
      }
      // Columns: Periode and Statut
      tblColumns.push({ title: i18n.t('common:listerevtgen.periode'), property: "periode", width: 4 });
      if (this.context && this.context.ctxVisuStatut) {
        tblColumns.push({ title: i18n.t('common:listerevtgen.statut'), property: "statut", width: 2 });
      }
    } else {
      // Column: Collaborateur
      if (this.context.ctxGestionCollective) {
        tblColumns.push({ title: i18n.t('common:listerevtgen.collaborateur'), property: "collaborateur", width: 4 });
        // Columns: Periode and Statut
        tblColumns.push({ title: i18n.t('common:listerevtgen.periode'), property: "periode", width: 5 });
        if (this.context && this.context.ctxVisuStatut) {
          tblColumns.push({ title: i18n.t('common:listerevtgen.statut'), property: "statut", width: 3 });
        }
      } else {
        // Columns: Periode and Statut
        tblColumns.push({ title: i18n.t('common:listerevtgen.periode'), property: "periode", width: 9, className: "cw-evtgen_periode" });
        if (this.context && this.context.ctxVisuStatut) {
          tblColumns.push({ title: i18n.t('common:listerevtgen.statut'), property: "statut", width: 3 });
        }
      }
    }
    if (this.context.ctxUtilisateur === this.workflow.RESPONSABLE) {
      // Columns: Caractéristique personnalisable
      _.each(selectedTypo.caracol, (value) => {
        const column = { title: value.libelle, property: "caracol" + value.idx };

        tblColumns.push(column);
      });
    }
    table = new CWMenuGridView<CWListerEvtGenModel, CWEvenementModelColl>({
      id: "listerevtgen_table",
      columns: tblColumns,
      model: this.tableModel,
      title: "",
      showFilteredRowsInTitle: false,
      gridHeightType: "auto",
      disableAlternateRowBackground: true,
      disableColumnTitles: true
    });
    table.cellRenderers = this.getCellRenders(selectedTypo);
    sortableColumns = ["collaborateur", "periode"];
    _.each(selectedTypo.caracol, (value): void => {
      sortableColumns.push("caracol" + value.idx);
    });
    table.setSortableColumns(sortableColumns);
    table.setSortableCustomNames({
      "collaborateur": ["nom", "prenom"],
      "periode": "datedeb"
    });
    if (this.table) {
      this.table.stopListening();
      this.table.$el.remove();
    }
    this.table = table;
    this.setTableSorting(this.table);
    $(".table_devenements", this.el).html(this.table.el);
    if ((this.context.ctxUtilisateur === this.workflow.RESPONSABLE && CWHABILITATION.canView('RES_EVTGEN.V')) ||
      (this.context.ctxUtilisateur === this.workflow.COLLABORATEUR && CWHABILITATION.canView('COL_EVTGEN.V'))) {
      this.table.render();
    }
    if (selectFirstRow) {
      if (this.table.model.coll.length === 0) {
        this.parent.model.trigger("emptyList");
      } else {
        this.table.model.selectFirstRow();
      }
    }
  }

  protected getCellRenders(selectedTypo: CWEvenementModelCollTypo): { [key: string]: (model: CWListerEvtGenModel, className?: string) => JQuery | string | HTMLElement } {
    const cellRenderers = super.getCellRenders(selectedTypo);

    return cellRenderers;
  }

  _historique(callback?: () => void): void {
    if (this.wkfEvenements === null) {
      const params: Array<string> = [];

      _.each(this.table.model.coll.models, function (item) {
        if (item.get("historique") === true) {
          params.push(item.get("evenement"));
        }
      }, this);
      this.wkfEvenements = params.join(",");
      if (!CWSTR.isBlank(this.wkfEvenements)) {
        this.historiqueColl.applyFilter({ evenements: this.wkfEvenements });
        this.historiqueColl.fetch({
          success: function () {
            if (callback) {
              callback();
            }
          }
        });
      } else {
        if (callback) {
          callback();
        }
      }
    } else {
      if (callback) {
        callback();
      }
    }
  }

  _buildHistoDemande(objModel: CWListerEvtGenModel): JQuery {
    let index = 0;
    const wkfModels: Array<CWListerEvtGenModel> = [];
    let message = null;

    for (let i = 0; i < this.historiqueColl.length; i++) {
      const model = this.historiqueColl.at(i) as CWListerEvtGenModel;

      //if iddemande = 0 use the evenement
      if (String(objModel.get("iddemande")) === "0") {
        if (model.get("evenement") === objModel.get("evenement")) {
          wkfModels[index] = model;
          if (index === 0) {
            index++;
          }
        }
      } else {
        if (model.get("demande") === objModel.get("iddemande")) {
          wkfModels[index] = model;
          if (index === 0) {
            index++;
          }
        }
      }
    }
    if (wkfModels[0]) {
      message = this._buildHistoLine(wkfModels[0]);
      if (wkfModels[1] && wkfModels[0].get("acte") !== wkfModels[1].get("acte")) {
        message.append(i18n.t('messages:GL_1035'));
        message.append(this._buildHistoLine(wkfModels[1]));
      }
    }
    return message;
  }

  _buildHistoLine(model: CWListerEvtGenModel): JQuery {
    return CWWKF.buildHistoLine2(model);
  }

  _bold(normalText: string): string {
    const boldText = "<b>" + normalText + "</b>";

    return boldText;
  }

  unselectAllRows(): void {
    this.table.model.coll.trigger("row:click", this.table.model.get("value"));
    this.table.model.set("value", null);
  }

  render(): CWListerEvtTableView {
    this.$el.html(this.template());
    $(this.el).find(".customBar_DEvenements").html(this.parent.customBar.render().el);
    return this;
  }
}
