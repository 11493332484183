import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Criteres du calendrier Model
 */
export class CWCriteresCalModel extends CWBaseModel {
    datedeb: string;
    datefin: string;

    constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {

        if (!options && !_.isEmpty(attributes)) {
            options = attributes;
        }

        super(attributes, options);
        this.datedeb = "";
        this.datefin = "";
    }
}