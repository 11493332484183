import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { objs } from 'src/objectsRepository';

/**
 * Touche Fonction Model
 */
export class CWToucheFonctionModel extends CWReadOnlyModel {

  version: string;
  habilitationV: string;
  habilitationG: string;
  usecase: string;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": "",
      "intitule": "",
      "module1": {
        "code": "",
        "libelle": ""
      },
      "module2": {
        "code": "",
        "libelle": ""
      },
      "module3": {
        "code": "",
        "libelle": ""
      },
      "msgformat": null,
      "cpt1": {
        "code": "",
        "libelle": ""
      },
      "typecpt1": {
        "code": "",
        "libelle": ""
      },
      "periocpt1": {
        "code": "",
        "libelle": ""
      },
      "cpt2": {
        "code": "",
        "libelle": ""
      },
      "typecpt2": {
        "code": "",
        "libelle": ""
      },
      "periocpt2": {
        "code": "",
        "libelle": ""
      },
      "cpt3": {
        "code": "",
        "libelle": ""
      },
      "cpt4": {
        "code": "",
        "libelle": ""
      },
      "absence": {
        "code": "",
        "libelle": ""
      },
      "sens": {
        "code": "",
        "libelle": ""
      },
      "libelletouche": "",
      "fichiericone": "",
      "pageweb": null,
      "proxyacces": "",
      "dureeaff": 120
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    options = options || {};
    super(attributes, options);
    this.version = "2.3.0";
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "home" + objs.appRt.workflow.get("zone");
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}
