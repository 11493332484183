import * as Backbone from 'Backbone';
import { CWCalendaccModel } from './cwCalendacc.model';
import { CWMarqueursModel } from './cwMarqueurs.model';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTypeStructModel } from './cwTypeStruct.model';
import { GLOBAL_DATA } from 'src/globalData';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'utils/sync.js';

/**
 * Workflow for UC Calendrier du collaborateur
 */
export class CWCalendaccWorkflow extends CWReadOnlyModel {

  EMPTY = typeof CWCalendaccModel;
  formModel: any;
  customBarModel: any;
  yearDate: number;
  form: any;
  marqueursModel: CWMarqueursModel;
  typeStructModel: CWTypeStructModel;
  paramDivMrqRestit: Backbone.Model;


  constructor(options?: { [key: string]: any }) {
    options = options || {};
    super(options);
    const actualDate = SYNC.getServerDate();

    this.usecase = options.usecase;
    this.formModel = null;
    this.customBarModel = null;
    this.set("ready", false);
    //for default put the current year   
    this.yearDate = actualDate.getFullYear();
    this.marqueursModel = new CWMarqueursModel({}, { "usecase": this.usecase });
    this.typeStructModel = new CWTypeStructModel({}, { "usecase": this.usecase });
    this.paramDivMrqRestit = GLOBAL_DATA.paramDivers.get("MrqRestit");
  }

  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback: () => void): void {
    const datedeb = this.yearDate + "0101";
    const datefin = this.yearDate + "1231";
    const funcContinue = (): void => {
      this.formModel.fetch({
        success: (fresh: { [key: string]: any }) => {
          const context = {
            ctxEcran: this.usecase,
            ctxHabilitation: "",
            ctxDateSynoptique: {
              ctxDateDeb: datedeb,
              ctxDateFin: datefin
            },
            ctxDateEnEvidence: "",
            ctxCollabMatricule: "",
            ctxAfficheAnnee: false
          };

          this.form.vueannuelle.setContext({ context: context, mainModel: fresh });
          if (callback) {
            callback();
          }
        }
      });
    };

    this.formModel.datedeb = datedeb;
    this.formModel.datefin = datefin;
    this.typeStructModel.fetch({
      success: () => {
        if (!CWSTR.isBlank(this.paramDivMrqRestit.get("valeur")) && this.paramDivMrqRestit.get("valeur") !== "0" && (!CWSTR.isBlank(this.typeStructModel.get("code")))) {
          this.marqueursModel.dated = datedeb;
          this.marqueursModel.datef = datefin;
          this.marqueursModel.matric = objs.appRt.workflow.authModel.get("matricule");
          this.marqueursModel.fetch({
            success: () => {
              funcContinue();
            }
          })
        } else {
          funcContinue();
        }
      }
    });
    this.set("ready", true);
    this.formModel.on("changeYear", this._setYear, this);
  }

  _setYear(year: number): void {
    const datedeb = year + "0101";
    const datefin = year + "1231";
    const funcContinue = (): void => {
      this.formModel.fetch({
        success: (fresh: { [key: string]: any }) => {
          const context = {
            ctxEcran: this.usecase,
            ctxHabilitation: "",
            ctxDateSynoptique: {
              ctxDateDeb: datedeb,
              ctxDateFin: datefin
            },
            ctxDateEnEvidence: "",
            ctxCollabMatricule: "",
            ctxAfficheAnnee: false
          };

          this.form.vueannuelle.setContext({ context: context, mainModel: fresh, renderer: true });
        }
      });
    };

    this.yearDate = year;
    this.formModel.datedeb = datedeb;
    this.formModel.datefin = datefin;
    //collection fetch
    this.typeStructModel.fetch({
      success: () => {
        if (!CWSTR.isBlank(this.paramDivMrqRestit.get("valeur")) && this.paramDivMrqRestit.get("valeur") !== "0" && (!CWSTR.isBlank(this.typeStructModel.get("code")))) {
          this.marqueursModel.dated = datedeb;
          this.marqueursModel.datef = datefin;
          this.marqueursModel.matric = objs.appRt.workflow.authModel.get("matricule");
          this.marqueursModel.fetch({
            success: () => {
              funcContinue();
            }
          });
        } else {
          funcContinue();
        }
      }
    })
  }

  buildMarqTooltip(typeMarq: any): any {
    let tooltip = "";

    if (!CWSTR.isBlank(typeMarq)) {
      tooltip += typeMarq.libelle.bold() + " ";
      tooltip += " - ";
      tooltip += typeMarq.libellelong
    }
    return tooltip
  }
}
