module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-selecteur-activite-wrap input-group">\n    <input class="cw-selecteur-activite-input form-control" type="text" title="" placeholder="'+
((__t=(placeholder))==null?'':__t)+
'"></input>\n    <div class="input-group-append">\n        <span role="button" tabindex="0" aria-label="'+
((__t=(i18n.t('common:ARIA_label.select_activity')))==null?'':__t)+
'" class="cw-selecteur-activite-button input-group-text d-none">'+
((__t=(UTILS.getSVGIcon("loupe")))==null?'':__t)+
'</span>\n    </div>\n</div>\n<div class="cw-selecteur-activite-multiSelection"></div>';
}
return __p;
};
