import * as Backbone from 'Backbone';
import TPLPersobriquesGeneral from '../general.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWBriquesListeView } from './cwBriquesListe.view';
import { CWDialogView } from 'src/core/components/dialog/cwDialog.view';
import { CWPersoBriquesModel } from '../models/cwPersoBriques.model';
import { i18n } from 'src/i18n.js';


export class CWGeneralView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<TModel> {

  context: { [key: string]: any };
  listeView: CWBriquesListeView;
  parent: CWDialogView;

  constructor(options: Backbone.ViewOptions<CWBaseModel> | any) {
    options = options || {};
    super(options);
    this.template = TPLPersobriquesGeneral;
    if (options) {
      this.context = options.context;
      this.parent = options.parent;
    }
    this.model = new CWBaseModel({
      value: new CWPersoBriquesModel()
    }) as TModel;
    this.listeView = new CWBriquesListeView({ context: this.context, model: this.model, parent: this.parent });
  }

  setContext(context: { [key: string]: any }): void {
    this.listeView.setContext(context);
  }

  render(): CWGeneralView<TModel> {
    const json = { "i18n": i18n };

    this.$el.append(this.template(json));
    this.$el.find(".persobriques-general-View").html(this.listeView.render().el);
    if (this.context) {
      this.setContext(this.context);
    }
    return this;
  }
}