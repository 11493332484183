import * as Backbone from 'Backbone';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDIVERS } from 'utils/cwDivers';
import { CWSTR } from 'utils/cwStr';

interface CWForfaitJourOption extends Backbone.ViewOptions {
  matric?: string;
  date?: string;
}

/**
 * Type de "forfait jour"
 * @values 0 : Pas de forfait jour
 * @values 1 : Forfait jour sans indicateur de présence
 * @values 2 : Forfait jour présence indiqué par badgeage
 * @values 3 : Forfait jour présence indiqué par régul
 */
export type CWForfaitJourType = "0" | "1" | "2" | "3";

/**
 * Mange the return of WS : rest/forfaitj/{matric}%2C{date}
 * with habilistation   colab : COL_HOR.V 
 *                      resp :  RES_HOR.V
 */
export class CWForfaitJourModel extends CWBaseModel {
  matric: string;
  date: string;
  paramDivRegPres: string;

  constructor(options?: CWForfaitJourOption) {
    options = options || {};
    super(options);
    this.matric = options.matric || "";
    this.date = options.date || "";
    this.paramDivRegPres = CWDIVERS.get('reg_pres');
    this.url = (): string => {
      return Configuration.restRoot + "/rest/forfaitj/" + encodeURIComponent(this.matric) + "%2C" + encodeURIComponent(this.date);
    }
  }

  getForfaitJourType(): CWForfaitJourType {
    const forfaitJ = this.get("forfaitj");
    return forfaitJ ? forfaitJ.code : "";
  }

  getPresence(): boolean {
    return this.get('presence');
  }

  /**
   * test if the type of forfait jour = 3 and if the param diver reg_pres is not null
   */
  isForfaitJourWithRegul(): boolean {
    return (!CWSTR.isBlank(this.paramDivRegPres) && this.getForfaitJourType() === "3")
  }

  isPresent(): boolean {
    return this.getPresence() ? true : false;
  }

  parse(response?: { [key: string]: any }): { [key: string]: any } {
    return response;
  }

}