import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Lister l'historique de l'absence
 */
export class CWListerHistoriqueModel extends CWBaseModel {
  /** * ListerHistoriqueModel */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);

    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/listedetail";
    }
  }

  defaults(): { [key: string]: any } {
    return { "evenements": null }
  }
}
