import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

export class CWElementLevel3Model extends CWBaseModel {

  code: string | number | boolean;
  /**
   * Code of the nivel2 model
   */

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.usecase = options.usecase || "droitsdel";
    super(attributes, options);
    this.code = options.code;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/delegation/fonctionnalites/" + encodeURIComponent(this.code) + "?delegable=true";
    };
  }
}