import _ from 'underscore';
import TPLListerregularisationsCustomBar from '../customBar.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { i18n } from 'src/i18n.js';
import { View, ViewOptions } from 'Backbone';


interface CWCustomBarListeRegulViewInitOptions extends ViewOptions<CWReadOnlyModel> {
  tableMode?: string;
}

export class CWCustomBarListeRegulView extends View<CWReadOnlyModel> {

  tableMode: string;

  constructor(options?: CWCustomBarListeRegulViewInitOptions) {
    options = options ? options : {};
    options.events = _.extend({
      "click a": "_manageLink"
    }, options.events);
    super(options);
    if (options.tableMode) {
      this.tableMode = options.tableMode;
    }
    this.model = new CWBaseModel({
      tableMode: this.tableMode
    });
    this.template = TPLListerregularisationsCustomBar;
  }

  render(): CWCustomBarListeRegulView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));
    if (this.tableMode === "Simple") {
      this.$el.find(".tableSimple").hide();
    } else if (this.tableMode === "Detaille") {
      this.$el.find(".tableDetaille").hide();
    }
    return this;
  }

  _manageLink(event: JQueryMouseEventObject): void {
    const element = event.target.className.split(" ")[0];
    this.swapLink(element);
  }

  swapLink(element: string): void {
    switch (element) {
      case "tableSimple": // SIMPLE MODE
        this.model.set("tableMode", "Simple");
        this.$el.find(".tableSimple").hide();
        this.$el.find(".tableDetaille").show();
        break;
      case "tableDetaille": // DETAIL MODE
        this.model.set("tableMode", "Detaille");
        this.$el.find(".tableDetaille").hide();
        this.$el.find(".tableSimple").show();
        break;
      default:
        break;
    }
  }
}
