import _ from 'underscore';
import TPLGererevtgenPopUp from '../cwPopUp.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWGererevtgenGestion } from '../models/cwGererEvtGenGestion';
import { CWSTR } from 'src/utils';
import { i18n } from 'src/i18n';
import { UTILS } from 'src/utils/utils.js';
import { ViewOptions } from 'Backbone';


export interface CWGererevtgenPopUpViewOptions extends ViewOptions<CWBaseModel> {
  parent?: CWDialogPopupView;
  action?: string;
  statut?: string;
  justifCheck?: boolean;
  absJustifVerif?: boolean;
  etat?: string;
  type?: boolean;
}

export class CWGererevtgenPopUpView extends CWBaseFormView<CWBaseModel> {
  public parent: CWDialogPopupView;
  public action: string;
  public statut: string;
  public justifCheck: boolean;
  public absJustifVerif: boolean;
  public etat: string;
  public type: boolean;

  constructor(options?: CWGererevtgenPopUpViewOptions) {
    options = options ? options : {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "click button": "_clickListener"
    }, options.events);
    super(options);
    this.template = TPLGererevtgenPopUp;

    this.model = new CWBaseModel({
      value: new CWGererevtgenGestion()
    });

    if (options.parent) {
      this.parent = options.parent;
    }

    if (options.action) {
      this.action = options.action;
      this.model.get("value").action = this.action;
    }

    if (options.statut) {
      this.statut = options.statut;
    }

    if (options.justifCheck) {
      this.justifCheck = options.justifCheck;
    }

    if (options.absJustifVerif) {
      this.absJustifVerif = options.absJustifVerif;
    }

    if (options.etat) {
      this.etat = options.etat;
    }

    if (_.isBoolean(options.type)) {
      this.type = options.type;
    }
  }

  render(): CWGererevtgenPopUpView {
    const json = { "i18n": i18n, "UTILS": UTILS };
    this.$el.append(this.template(json));

    this.$el.find(".gererevtgen-popup-justifier").hide();
    $(".gererevtgen-popup-justifier .justifok").prop('checked', false);

    if (this.justifCheck) {
      this.$el.find(".gererevtgen-popup-justifier").show();
      if (this.absJustifVerif) {
        $(".gererevtgen-popup-justifier .justifok").prop('checked', true);
      }
    }

    let comment = "";
    switch (this.action) {
      case "accepter":
        break;
      case "refuser":
        this.$el.find("label[for=commentaire],label[for^=commentaire_uid_]").addClass("cw-required");
        break;
      case "suppression":
        // Customer #184777 If it's a popup for "supression", don't need the justifier checkbox
        this.$el.find(".gererevtgen-popup-justifier").hide();
        switch (this.statut) {
          case "I":
            comment = i18n.t('common:gererevtgen.commentaire_suppression') + " " + i18n.t('common:gererevtgen.commentaire_en_cours');
            break;
          case "A":
            comment = i18n.t('common:gererevtgen.commentaire_suppression_accepte');
            break;
          default:
            comment = i18n.t('common:gererevtgen.commentaire_suppression');
        }
    }

    $(this.el).find(".spanComment").html(comment);

    return this;
  }

  _clickListener(event: JQueryInputEventObject): void {
    const btnClicked = (event.currentTarget as HTMLInputElement).value;
    const model = this.model.get("value");

    switch (btnClicked) {
      case "btnOK":
        if (this.action === "accepter") {
          let justi = model.get("justifok");

          if (CWSTR.isBlank(justi)) {
            justi = this.absJustifVerif;
          }
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.model.set("justifok", justi);
          this.parent.close();
        } else {
          if (this.action === "refuser") {

            model.off("invalid");
            model.on("invalid", this._showValidationErrors, this);

            if (model.isValid()) {
              this.parent.model.set("action", "OK");
              this.parent.model.set("commentaire", model.get("commentaire"));
              this.parent.close();
            }

          } else if (this.action === "suppression") {
            this.parent.model.set("action", "OK");
            this.parent.model.set("commentaire", model.get("commentaire"));
            this.parent.close();
          }
        }

        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
        break;
    }
  }
}