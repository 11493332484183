module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc form-group" data-bloc-name="PLH" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.heures')))==null?'':__t)+
'">\n  <div class="d-flex">\n    <div class="bloc-label-input">\n      <label for="heuredeb" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.debut')))==null?'':__t)+
'</label>\n      <input type="text" class="heuredeb form-control typeHourMinuteNightly" size="5">\n      <span class="heuredeb-error-container"></span>\n    </div>\n    <div class="bloc-label-input">\n        <label for="heurefin" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.fin')))==null?'':__t)+
'</label>\n        <input type="text" class="heurefin form-control typeHourMinuteNightly" size="5">\n        <span class="heurefin-error-container"></span>\n    </div>\n    <div class="bloc-label-input">\n        <label for="dureecalculee" class="phx-inline-label">'+
((__t=(i18n.t('common:gereractivites.duree')))==null?'':__t)+
'</label>\n        <input type="text" class="dureecalculee form-control typeDurationHM" size="5" readOnly>\n    </div>\n  </div>\n</div>\n\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="DUREE" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.duree')))==null?'':__t)+
'">\n    <div class="bloc-label-input">\n        <label for="duree" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.duree')))==null?'':__t)+
'</label>\n        <input type="text" class="duree form-control typeDuration24HM" size="6">\n    </div>\n</div>\n\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="UNITE" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.unite')))==null?'':__t)+
'">\n    <div class="bloc-label-input-unite">\n        <label for="unitedeb.code" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.unite')))==null?'':__t)+
'</label>\n        <span class="comboUnites"></span>\n    </div>\n</div>\n\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="PRES" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.presence')))==null?'':__t)+
'">\n    <div class="bloc-label-input-pres">\n        <label for="presence" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.indif_presence')))==null?'':__t)+
'</label>\n        <input type="checkbox" class="presence form-control" value="true" readonly="readonly">\n    </div>\n</div>\n\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="POURCENT" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.tempeff')))==null?'':__t)+
'">\n    <div class="bloc-label-input-perc">\n        <label for="pourcentage" class="cw-required">%&nbsp;</label>\n        <input type="text" class="pourcentage form-control typePercentage_0" size="10">\n    </div>\n</div>\n\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="RELIQUAT" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.reliquat')))==null?'':__t)+
'">\n    <div class="bloc-label-input">\n        <label for="horspresence" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.DuJour')))==null?'':__t)+
'</label>\n        <input type="checkbox" class="horspresence form-control" value="true" readonly="readonly">\n    </div>\n</div>\n\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="HORSPRES" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.horspres')))==null?'':__t)+
'">\n    <div class="d-flex">\n      <div class="bloc-label-input">\n          <label for="horspresav" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.avant')))==null?'':__t)+
'</label>\n          <input type="checkbox" class="horspresav custom-control-input form-control" value="true">\n      </div>\n      <div class="bloc-label-input">\n          <label for="horspresav" class="cw-required">'+
((__t=(i18n.t('common:gereractivites.apres')))==null?'':__t)+
'</label>\n          <input type="checkbox" class="horspresap custom-control-input" value="true">\n      </div>\n    </div>\n</div>\n';
}
return __p;
};
