import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for the complement combos
 */
export class CWSaisieOptionsModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": ""
    }
  }
}