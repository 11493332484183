import * as Backbone from 'Backbone';
import { CWButtonBarView as CommonButtonBarView } from 'core/controls/cwButtonBar.view';
import { i18n } from 'src/i18n.js';

/**
 * View for the button bar
 */
export class CWButtonBarView extends CommonButtonBarView {
  exportEtat: boolean;

  constructor(params?: Backbone.ViewOptions<Backbone.Model> | any) {
    super(params);
    CommonButtonBarView.prototype.initialize.call(this);

    this.model.on("exportEtat", this._manageExportEtat, this);

    this.addButton("print", i18n.t('lanceetat.imprimer'));
    this.addButton("export", i18n.t('lanceetat.exporter'));
    this.addButton("revert", i18n.t('common:revert'), "croix");

    this.exportEtat = false;
  }

  _manageExportEtat(exportEtat: boolean): void {
    this.exportEtat = exportEtat;
    if (exportEtat === true) {
      this.$el.find(".export").show();
    } else {
      this.$el.find(".export").hide();
    }
  }

  render(): CWButtonBarView {
    if (this.exportEtat === true) {
      this.$el.find(".export").show();
    } else {
      this.$el.find(".export").hide();
    }
    return this;
  }
}