import * as Backbone from 'Backbone';
import _ from 'underscore';
import cwCommmonDynamicStyleAbsTPL from 'core/cwDynamicStyleAbs.tpl.html';
import cwCommmonDynamicStyleActTPL from 'core/cwDynamicStyleAct.tpl.html';
import cwCommmonDynamicStyleTPL from 'core/cwDynamicStyle.tpl.html';
import { CWDIVERS } from './cwDivers';
import { CWRENDERAGENTS } from './cwRenderAgents';
import { CWRENDERMEDICAL } from './cwRenderMedical';
import { CWSTR } from './cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from './utils.js';

/**
 * Generic Render for planing and diary cells.
 */
export class CWRENDER {


  /**
   * ----CAUTION-----: This methos is used for planning, agenda and Emploi Du Temps, so when it is changed, tha change should be tested
   * in all this screens
   * Function that generate the HTML structure of the Absences information for the cell.
   */
  static renderDayCell(layers: Backbone.Model, viewMode: string, evtModel: Backbone.Model, range: string, dtsModel: Backbone.Model | any, pk: string, model: any, viewEl: any, options: any): CWRENDER {
    let confidentielle = null,
      monequip = null,
      //icons = null,
      activitiesFilters: Backbone.Collection = null,
      plagesMode = null,
      selonNature = null,
      absTextByGroupe = null,
      edt = null,
      //indPrimaire = null,
      simulation = null;
    let princBackClass = null,
      princBordClass = null,
      secBackClass = null,
      secBordClass = null,
      pretsAffSecBackClass = null,
      pretsAffSecBordClass = null,
      indicSecondaire: any = null,
      anim1 = false,
      anim2 = false,
      anim3 = false,
      typeIndSecon = 0;
    let ignoreHgrp = null;
    let typologiesConfig = null;
    let displayMultiLines = false;
    const eventLines: any = [];
    let objConfMultiline = null;
    let nbMultiLines = 0;
    let lineAbs = 0,
      lineHor = 0,
      lineBadg = 0,
      lineAct = 0,
      lineAst = 0,
      lineInd = 0,
      lineAstReg = 0,
      lineMem = 0;
    let indicateursMultiline: any = { "objIndicateurs": [], "objIndicateursSecondaire": [], "objIndicateursAffSec": [] }; //Object with info for each line for indicateur primaire and secondaire
    let objIndicateurs = [];
    let objIndicateursSecondaire = [];
    let objIndicateursAffSec = [];
    let indicContainerSim;
    let multiPlanning = false;
    let simulateMode = false;
    let indSimulation = false;
    let rowNumber = 0;
    const idlg = options ? options.planningIdlg : null;
    const hasSimInd = false;
    let simIndBackClass;
    let simIndBordClass;
    let simIndAnim;
    let lActPreIsNum = false;
    let lActReaIsNum = false;
    let lAbsIsNum = false;
    let lRegIsNum = false;
    let lAffIsNum = false;
    let lBadIsNum = false;
    let lMesIsNum = false;
    let lAgendaAnnuelInd = false;
    let keys = null;
    let size = null;
    let i = null;
    //Déclaration pour Planning Medical
    let lbIsMedical = false;
    let lbIsTabServInd = false;
    let lbIsTabServColl = false;
    let lNbfractions = 0;
    let lFractionsCell: any = null;
    let lFractionsMedical: any = [];
    let lIsJourFerie = false;
    let lIsvueJournee = false;
    let indicateurs: any = null;
    let indToRender: any = {};
    let indPretAffSecToRender: any = {};
    let ap = false,
      ar = false,
      apz = false,
      arz = false,
      apav = false,
      arav = false,
      apzav = false,
      arzav = false;
    let isIndCoc;
    let indPrimaireCheck = false; // pour cas spécial de horaire, deux plages et deux ou plus indicateurs
    let indSecondaireFlagCheck = false; // pour cas spécial de horaire, deux plages et deux ou plus indicateurs
    let forfaitjOption: { [key: string]: any }; //
    //fin déclaration pour Planning Medical

    if (options) {
      if (options.confidentielle) {
        confidentielle = options.confidentielle;
      }
      if (options.monequip) {
        monequip = options.monequip;
      }
      if (options.activitiesFilters) {
        activitiesFilters = options.activitiesFilters;
      }
      if (options.typologiesConfig) {
        typologiesConfig = options.typologiesConfig;
      }
      if (_.isBoolean(options.plagesMode)) {
        plagesMode = options.plagesMode;
      }
      if (_.isBoolean(options.selonNature)) {
        selonNature = options.selonNature;
      }
      if (_.isBoolean(options.absTextByGroupe)) {
        absTextByGroupe = options.absTextByGroupe;
      }
      if (_.isBoolean(options.ignoreHgrp)) {
        ignoreHgrp = options.ignoreHgrp;
      }
      if (_.isBoolean(options.edt)) {
        edt = options.edt;
      }
      if (_.isBoolean(options.simulation)) {
        simulation = options.simulation;
      }
      //Indicateur primary and secondary options
      if (options.princBackClass) {
        princBackClass = options.princBackClass;
      }
      if (options.princBordClass) {
        princBordClass = options.princBordClass;
      }
      if (options.secBackClass) {
        secBackClass = options.secBackClass;
      }
      if (options.secBordClass) {
        secBordClass = options.secBordClass;
      }
      if (options.pretsAffSecBackClass) {
        pretsAffSecBackClass = options.pretsAffSecBackClass;
      }
      if (options.pretsAffSecBordClass) {
        pretsAffSecBordClass = options.pretsAffSecBordClass;
      }
      if (options.indicSecondaire) {
        indicSecondaire = options.indicSecondaire;
      }
      if (_.isBoolean(options.anim1)) {
        anim1 = options.anim1;
      }
      if (_.isBoolean(options.anim2)) {
        anim2 = options.anim2;
      }
      if (_.isBoolean(options.anim3)) {
        anim3 = options.anim3;
      }
      if (options.typeIndSecon) {
        typeIndSecon = options.typeIndSecon;
      }
      if (_.isBoolean(options.displayMultiLines)) {
        //only if is boolean displayMultiLines, we use objConfMultiline
        displayMultiLines = options.displayMultiLines;
        objConfMultiline = ((displayMultiLines && !CWSTR.isBlank(options.objConfMultiline)) ? options.objConfMultiline : null);
        nbMultiLines = ((displayMultiLines && $.isNumeric(options.nbMultiLines)) ? options.nbMultiLines : 0);
      }
      if (options.multiPlanning) {
        multiPlanning = options.multiPlanning;
      }
      if (options.rowNumber) {
        rowNumber = options.rowNumber;
      }
      // Simulation mode flag
      if (options.simulateMode) {
        simulateMode = options.simulateMode;
      }
      // Simulation indicateur options
      if (options.indSimulation) {
        indSimulation = options.indSimulation;
      }
      if (options.simIndBackClass) {
        simIndBackClass = options.simIndBackClass;
      }
      if (options.simIndBordClass) {
        simIndBordClass = options.simIndBordClass;
      }
      if (_.isBoolean(options.simIndAnim)) {
        simIndAnim = options.simIndAnim;
      }
      if (options.agendaAnnuelInd === true) {
        lAgendaAnnuelInd = true;
      }
      if (options.isMedical) {
        lbIsMedical = options.isMedical;
      }
      if (options.isTabServInd) {
        lbIsTabServInd = options.isTabServInd;
      }
      if (options.isTabServColl) {
        lbIsTabServColl = options.isTabServColl;
      }
      if (options.forfaitj) {
        forfaitjOption = options.forfaitj;
      }
      if (lbIsMedical && !_.isEmpty(options.fractionsMedical)) {
        lFractionsMedical = options.fractionsMedical;
        lNbfractions = lFractionsMedical.length;
        lIsJourFerie = options.isJourFerie;
        lIsvueJournee = options.isvueJournee;
      }
    }
    // Clean all styles
    if (multiPlanning === false) {
      viewEl.empty();
    }
    viewEl.removeClass();
    viewEl.prop("title", "");
    viewEl.addClass("ui-phx-info-cell-style");
    // 1 PRIORITY // HORS CONTRAT
    if (model && model.dts && model.dts.hcon === true) {
      // THIS MODE BLOCK THE REST !!!!
      if (lbIsMedical) {
        let lDivHc;
        const cellWidth = lbIsTabServInd ? ((Configuration.planning.CELL_WIDTH_TABSERVIND) ? Configuration.planning.CELL_WIDTH_TABSERVIND : 190) : Configuration.planning.CELL_WIDTH_MONTH;
        const lValCss: { [key: string]: any } = {
          "width": ((cellWidth * lNbfractions) - 1) + "px" /*123px*/,
          "height": "22px",
          "border-right-color": "#F2F2F2",
          "border-right-style": "solid",
          "border-right-width": "1px"
        };

        if (lbIsTabServInd) {
          lDivHc = $("<div class='tabservind_cell'>");
        } else {
          lDivHc = $("<div class='planmed_cell'>");
        }
        lDivHc.css(lValCss);
        lDivHc.addClass("ui-phx-medical-hors-contrat");
        viewEl.append(lDivHc);
      } else {
        viewEl.addClass("ui-phx-hors-contrat"); // THIS MODE BLOCK THE REST !!!!
        viewEl.html("<div>&nbsp;</div>");
      }
    } else if (model && model.dts && model.dts.hplnmed === true) {
      // THIS MODE BLOCK THE REST !!!!
      if (lbIsMedical) {
        let lDivHc;
        const cellWidth = lbIsTabServInd ? ((Configuration.planning.CELL_WIDTH_TABSERVIND) ? Configuration.planning.CELL_WIDTH_TABSERVIND : 190) : Configuration.planning.CELL_WIDTH_MONTH;
        const lValCss = {
          "width": ((cellWidth * lNbfractions) - 1) + "px" /*123px*/,
          "height": "23px",
          "border-right-color": "#FDE5CD",
          "border-right-style": "solid",
          "border-right-width": "1px"
        };

        if (lbIsTabServInd) {
          lDivHc = $("<div class='tabservind_cell'>").css(lValCss);
        } else {
          lDivHc = $("<div class='planmed_cell'>").css(lValCss);
        }
        lDivHc.addClass("ui-phx-medical-hors-medical-pl");
        viewEl.append(lDivHc);
      } else {
        viewEl.addClass("ui-phx-hors-contrat"); // THIS MODE BLOCK THE REST !!!!
        viewEl.html("<div>&nbsp;</div>");
      }
    } else {//cell DOM elements
      let cellDom = $("<div class='phx-cell-render'>");
      const cellForfaitj = $("<div>");
      let indicateurDom = $("<div class='phx-cell-render-ind'/>");
      let indicateurDomAffPret = $("<div class='phx-cell-render-ind'/>");
      let horairesDoms, badgeagesDoms, absencesDoms, activitiesDoms, astreintesDoms, memosDoms;

      if (rowNumber > 0) {
        cellDom.css("border-top", "1px solid #E6E6E6");
      }
      if (edt !== true && !lbIsMedical) { //Only for planning or agenda, not for emploi du temps and not pl medical
        CWRENDER.renderDayCellType(model, cellDom, ignoreHgrp, false, evtModel, pk, dtsModel);
      }
      if (simulateMode && indSimulation) {
        if (model.dat >= objs.simulationModel.get("datedeb") && model.dat <= objs.simulationModel.get("datefin")) {
          if (model.dts.ect && (model.dts.ect.hor === true || model.dts.ect.abs === true || model.dts.ect.prt === true)) {
            const indicateurSimulation = CWRENDERAGENTS.renderIndicateurSimulation(viewMode, simIndBordClass, simIndBackClass, simIndAnim);

            indicContainerSim = $("<div>").addClass("phx-icon-secondaire-container spec1");
            if (viewMode === "M") { //Container of indicateur secondaire to set margins
              indicContainerSim.addClass("phx-icon-secondaire-container-m");
              indicContainerSim.css("display", "inline-block");
            } else {
              indicContainerSim.addClass("phx-icon-secondaire-container-w");
              indicContainerSim.css("display", "inline-block");
            }
            indicContainerSim.append(indicateurSimulation);
            indicateurDom.append(indicContainerSim);
          }
        }
      }
      if (lbIsMedical && lNbfractions > 0) {
        const listeFractionnes: any = [];
        const lTmpDiv = CWRENDERMEDICAL._cellMedical(pk, lFractionsMedical, lIsJourFerie, listeFractionnes); //aussi il ajoute la liste de fractionnes.
        const cellWidth = lbIsTabServInd ? ((Configuration.planning.CELL_WIDTH_TABSERVIND) ? Configuration.planning.CELL_WIDTH_TABSERVIND : 190) : Configuration.planning.CELL_WIDTH_MONTH;

        if (lbIsTabServInd) {
          lFractionsCell = $("<div class='tabservind_cell'>").css({ "width": (cellWidth * lNbfractions) + "px" });
        } else {
          lFractionsCell = $("<div class='planmed_cell'>").css({ "width": (cellWidth * lNbfractions) + "px" });
        }
        if (!CWSTR.isBlank(lTmpDiv)) {
          lFractionsCell.append(lTmpDiv);
        }
        // ACTIVITIES POSTE: GME-POJOUR, etc
        if (layers.get("act") && (evtModel as any).hasEvent("ACTIVITES", pk, idlg)) { //5
          //let idlg = options ? options.planningIdlg : null;
          const activities = (evtModel as any).getEvent("ACTIVITES", pk, idlg).get("evt");
          const lIndexActFilter = _.map(activitiesFilters.toJSON(), "code");
          const activitiesPostes = new Backbone.Collection(activities.filter((model: any) => {
            const lPosIndex = (lIndexActFilter) ? lIndexActFilter.indexOf(model.get("dts").tpo) : -1;
            let lRtn = false;

            if (lPosIndex >= 0) {
              const lType = activitiesFilters.models[lPosIndex].get("typeGestionActivite");

              lRtn = (lType !== "ACTJGEN" && lType !== "ACTJORG" && lType !== "REPOBLIG");
            }
            return lRtn;
          }));

          if (activitiesPostes.length > 0) {
            const lAbsences = (layers.get("abs") && (evtModel as any).hasEvent("ABSENCES", pk, idlg)) ? (evtModel as any).getEvent("ABSENCES", pk, idlg).get("evt") : null;

            if (lAbsences) {
              options.listeAnnuleCouv = CWRENDER.preparationAbsencesCouv(lAbsences, dtsModel);
            }
            if (model.module === "tabservind") {
              dtsModel.module = model.module
            }
            lFractionsCell = CWRENDERMEDICAL._renderActivitiesMedical(layers, lFractionsCell, dtsModel, activitiesFilters, listeFractionnes, activitiesPostes, lIsvueJournee, options);
          }
        }
        // ABSENCES
        if (layers.get("abs") && (evtModel as any).hasEvent("ABSENCES", pk, idlg)) {
          lFractionsCell = CWRENDERMEDICAL._renderAbsencesMedical(layers, lFractionsCell, evtModel, dtsModel, pk, range, absTextByGroupe, options, listeFractionnes, lIsvueJournee);
        }
        // ACTIVITIES JOUR: GME-ACTJORG, etc
        if (layers.get("act") && (evtModel as any).hasEvent("ACTIVITES", pk, idlg)) { //5
          const activities = (evtModel as any).getEvent("ACTIVITES", pk, idlg).get("evt");
          const lIndexActFilter = _.map(activitiesFilters.toJSON(), "code");
          const activitiesJour = new Backbone.Collection(activities.filter((model: any) => {
            const lPosIndex = (lIndexActFilter) ? lIndexActFilter.indexOf(model.get("dts").tpo) : -1;
            let lRtn = false;

            if (lPosIndex >= 0) {
              const lType = activitiesFilters.models[lPosIndex].get("typeGestionActivite");

              lRtn = (lType === "ACTJGEN" || lType === "ACTJORG");
            }
            return lRtn;
          }));

          if (activitiesJour.length > 0) {
            lFractionsCell = CWRENDERMEDICAL._renderActivitiesMedical(layers, lFractionsCell, dtsModel, activitiesFilters, listeFractionnes, activitiesJour, lIsvueJournee, options);
          }
        }
        // ACTIVITIES REPOBLIG (repos obligatoires)
        if (layers.get("act") && (evtModel as any).hasEvent("ACTIVITES", pk, idlg)) { //5
          const activities = (evtModel as any).getEvent("ACTIVITES", pk, idlg).get("evt");
          const lIndexActFilter = _.map(activitiesFilters.toJSON(), "code");
          const activiteReposOblig = new Backbone.Collection(activities.filter((model: any) => {
            const lPosIndex = (lIndexActFilter) ? lIndexActFilter.indexOf(model.get("dts").tpo) : -1;
            let lRtn = false;

            if (lPosIndex >= 0) {
              const lType = activitiesFilters.models[lPosIndex].get("typeGestionActivite");

              lRtn = (lType === "REPOBLIG");
            }
            return lRtn;
          }));

          if (activiteReposOblig.length > 0) {
            lFractionsCell = CWRENDERMEDICAL._renderActivitiesMedical(layers, lFractionsCell, dtsModel, activitiesFilters, listeFractionnes, activiteReposOblig, lIsvueJournee, options);
          }
        }
        // HORAIRES
        if (layers.get("hor") && (evtModel as any).hasEvent("HORAIRES", pk, idlg)) {
          lFractionsCell = CWRENDERMEDICAL._renderHorairesMedical(layers, lFractionsCell, listeFractionnes, evtModel, dtsModel, pk, selonNature, options, lIsvueJournee);
        }
        // INDICATEURS
        if ((evtModel as any).hasEvent("INDICATEURS", pk, idlg) === true) {
          indicateurs = (evtModel as any).getEvent("INDICATEURS", pk, idlg).get("evt").at(0).get("dts"); //2
          indToRender = {};
          indPretAffSecToRender = {};
          if (CWRENDERMEDICAL.existIndicateurComplementaireMedical(indToRender, layers, indicateurs, activitiesFilters, "S")) {
            const aOptions = {
              activitiesFilters: activitiesFilters,
              viewMode: "S",
              typologiesConfig: typologiesConfig,
              edt: edt,
              indPrimaire: true,
              indSecondaireFlag: false,
              typeIndSecon: typeIndSecon,
              indicSecondaire: indicSecondaire,
              princBackClass: princBackClass,
              princBordClass: princBordClass,
              secBackClass: secBackClass,
              secBordClass: secBordClass,
              pretsAffSecBordClass: pretsAffSecBordClass,
              pretsAffSecBackClass: pretsAffSecBackClass,
              absTextByGroupe: absTextByGroupe,
              idlg: idlg,
              anim1: anim1,
              anim2: anim2,
              anim3: anim3,
              fractionsMedical: lFractionsMedical,
              lbIsTabServInd: lbIsTabServInd,
              lIsvueJournee: lIsvueJournee,
              lbIsTabServColl: lbIsTabServColl
            };

            lFractionsCell = CWRENDERMEDICAL._renderIndicateursMedical(layers, lFractionsCell, listeFractionnes, indToRender, indicateurs, evtModel, dtsModel, i18n, model, pk, aOptions);
          }
        }
        // MÉMOS
        if (layers.get("mem") && (evtModel as any).hasEvent("MEMOS", pk, idlg)) {
          lFractionsCell = CWRENDERMEDICAL._renderMemosMedical(layers, lFractionsCell, listeFractionnes, evtModel, pk, options, lIsvueJournee);
          //indicateurDom.append(CWRENDERMEDICAL._renderMemosMedical(layers, lFractionsCell, listeFractionnes, evtModel, pk, options));
        }
        // COLLABREÇU
        if (model.dts && model.dts.affr) {
          lFractionsCell = CWRENDERMEDICAL._renderPretRecuAffSecMedical(lFractionsCell, model.dts, listeFractionnes, "affr");
        }
        // AFFECT SEC
        if (model.dts && model.dts.affs) {
          lFractionsCell = CWRENDERMEDICAL._renderPretRecuAffSecMedical(lFractionsCell, model.dts, listeFractionnes, "affs");
        }
        CWRENDER.renderDayCellType(model, lFractionsCell, ignoreHgrp, true, evtModel, pk, dtsModel);
        cellDom.append(lFractionsCell);
      } else {
        let pretFlag = false;
        let affsecFlag = false;

        if (displayMultiLines) {
          const lHeighMulti = CWRENDER._heightMultiline(viewMode, layers);
          let lLignes = null;
          const lObjEvts = objConfMultiline.typeEvt.map((item: any) => { return item.row; });
          let lLignesEmptyEnd = null;
          let lItemAbs: { [key: string]: any } = null;
          let lItemBadg: { [key: string]: any } = null;
          let lItemHor: { [key: string]: any } = null;
          let lItemAct: { [key: string]: any } = null;
          let lItemAstReg: { [key: string]: any } = null;
          let lItemMem: { [key: string]: any } = null;

          if (!CWSTR.isBlank(objConfMultiline.diode)) {
            lObjEvts.push(String(objConfMultiline.diode));
          }
          if (!CWSTR.isBlank(objConfMultiline.memo)) {
            lObjEvts.push(String(objConfMultiline.memo));
          }
          lLignes = $.unique(lObjEvts).sort();
          lLignesEmptyEnd = (lLignes.length > 0) ? parseInt(String(lLignes[lLignes.length - 1]), 10) + 1 : (-1);
          for (i = 0; i < nbMultiLines; i++) {
            const divLine = $("<div class=\"" + viewMode + "-event-row-" + i + " js-row-event\">");

            if (lLignesEmptyEnd > 0 && i >= lLignesEmptyEnd) {
              divLine.removeClass("js-row-event").addClass("js-row-notEvent");
            } else if (viewMode === "S") {
              divLine.addClass("table-" + lHeighMulti + "-height");
            }
            divLine.css({ "position": "relative", "width": "100%" });
            eventLines.push(divLine);
          }
          cellDom.append(eventLines);
          //evt -> line
          lItemAbs = _.find(objConfMultiline.typeEvt, (item: { [key: string]: any }) => {
            return item.evt === "Absences";
          });
          if (!CWSTR.isBlank(lItemAbs)) {
            lineAbs = parseInt(lItemAbs.row);
          }
          lItemBadg = _.find(objConfMultiline.typeEvt, (item: { [key: string]: any }) => {
            return item.evt === "Badgeages";
          });
          if (!CWSTR.isBlank(lItemBadg)) {
            lineBadg = parseInt(lItemBadg.row);
          }
          lItemHor = _.find(objConfMultiline.typeEvt, (item: { [key: string]: any }) => {
            return item.evt === "Horaires";
          });
          if (!CWSTR.isBlank(lItemHor)) {
            lineHor = parseInt(lItemHor.row);
          }
          lItemAct = _.find(objConfMultiline.typeEvt, (item: { [key: string]: any }) => {
            return item.evt === "Activites";
          });
          if (!CWSTR.isBlank(lItemAct)) {
            lineAct = parseInt(lItemAct.row);
          }
          lItemAct = _.find(objConfMultiline.typeEvt, (item: { [key: string]: any }) => {
            return item.evt === "Astreintes";
          });
          if (!CWSTR.isBlank(lItemAct)) {
            lineAst = parseInt(lItemAct.row);
          }
          lItemAstReg = _.find(objConfMultiline.typeEvt, (item: { [key: string]: any }) => {
            return item.evt === "AstReg";
          });
          if (!CWSTR.isBlank(lItemAstReg)) {
            lineAstReg = parseInt(lItemAstReg.row);
          }
          //evt -> line
          lItemMem = objConfMultiline.memo;
          if (_.isNumber(lItemMem)) {
            lineMem = lItemMem;
          }
        }
        // ABSENCES
        if ((layers.get("abs") || layers.get("sou"))) { //1
          if ((evtModel as any).hasEvent("ABSENCES", pk, idlg)) {
            absencesDoms = CWRENDERAGENTS._renderAbsences(layers, viewMode, evtModel, dtsModel, pk, range, confidentielle, absTextByGroupe, null, edt, simulation, false, displayMultiLines, lineAbs, lineAct, options);
          }
        }
        if (monequip === true) {
          let absTooltip = "";//Icon tooltip
          let absTitle = "";

          //if (confidentielle && confidentielle.absences == true && (evtModel as any).hasEvent("ABSENCES",pk) == true) {
          if ((evtModel as any).hasEvent("ABSENCES", pk, idlg) === true) {
            const absences = (evtModel as any).getEvent("ABSENCES", pk, idlg).get("evt").models;
            let first = true;
            let firstAbsenceJ = true;

            for (i = 0; i < absences.length; i++) {
              //absencces  type H
              if (absences[i].get("dts").sou === false && absences[i].get("val") === "H" && !CWSTR.isBlank(absences[i].get("dts").hed) && !CWSTR.isBlank(absences[i].get("dts").hef)) {
                if (first === true) {
                  first = false;
                } else {
                  absTooltip += "<br/>";
                }
                if (confidentielle && confidentielle.absences === true) {
                  const auxtooltip = "<label>" + i18n.t('common:utils.absconf', {
                    "0": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hed, CWTYPE._getFormatByCode("DUREEHM")).trim() + "<label>",
                    "1": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hef, CWTYPE._getFormatByCode("DUREEHM")).trim(),
                    interpolation: { escapeValue: false }
                  });

                  absTooltip += auxtooltip;
                } else {
                  let absLibelle = i18n.t('common:utils.absence');
                  let auxtooltip: string = null;

                  if (!CWSTR.isBlank(dtsModel.ABSENCES.get(absences[i].get("cod")))) {
                    absLibelle = dtsModel.ABSENCES.get(absences[i].get("cod")).get("lib");
                  }
                  auxtooltip = i18n.t('common:utils.abscomp', {
                    "0": absLibelle + "<label>",
                    "1": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hed, CWTYPE._getFormatByCode("DUREEHM")).trim() + "<label>",
                    "2": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hef, CWTYPE._getFormatByCode("DUREEHM")).trim(),
                    interpolation: { escapeValue: false }
                  });
                  absTooltip += auxtooltip;
                }
                //absencces  type J
              } else if (absences[i].get("dts").sou === false && absences[i].get("val") === "J") {
                if (firstAbsenceJ === true) { //no view thw first absence to day
                  firstAbsenceJ = false;
                } else {
                  const absenc = dtsModel.ABSENCES.get(absences[i].get("cod"));

                  if (first === true) {
                    first = false;
                  } else {
                    absTooltip += "<br/>";
                  }
                  absTooltip += i18n.t('agenda.absenceday');
                  //Show complete absence info when we have absences permission
                  if (absenc && confidentielle && confidentielle.absences === false) {
                    absTooltip += ": " + absenc.get("lib");
                  }
                }
              }
            }
          }
          //indicateur
          if (!CWSTR.isBlank(absTooltip)) {
            absTitle = "title=\"" + absTooltip + "\" ";
            if (viewMode === "M") {
              indicateurDom.append("<span " + absTitle + "class='cw-cercle-plein-icon cw-icon cw-icon-10 cw-cursor-pointer'></span>");
            } else if (viewMode === "S") {
              indicateurDom.append("<span " + absTitle + "class='cw-cercle-plein-icon cw-icon cw-icon-16 cw-cursor-pointer'></span>");
            }
          }
        }
        // HORAIRES
        if (layers.get("hor")) {
          // DEBUT ROULEMENT
          if (layers.get("hor_deb")) {
            //Debut de roulement indicator
            let debRoul = null;
            const showDebRoul = layers.get("hor_deb");

            if ((evtModel as any).hasEvent("HORAIRES", pk, idlg) && (evtModel as any).getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("dts")) {
              const debRoulIndicateur = $("<div class='phx-cell-render-roulind'/>");
              const span = $("<span class='phx-icon-marqueur-debut-roulement' />");

              //paint indicator for Debut de roulement
              debRoul = (evtModel as any).getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("dts").roul;
              if ((debRoul === true) && (showDebRoul === true)) {
                if (viewMode === "M") {
                  span.addClass("phx-icon-view-m");
                } else if (viewMode === "S") {
                  span.addClass("phx-icon-view-s");
                }
                debRoulIndicateur.append(span);
                if (displayMultiLines) {
                  cellDom.find("." + viewMode + "-event-row-" + lineHor + ".js-row-event").append(debRoulIndicateur);
                } else {
                  //Included in CellDom
                  cellDom.append(debRoulIndicateur);
                }
              }
            }
          }
          if (CWSTR.isBlank(forfaitjOption) || CWSTR.isBlank(forfaitjOption.code) || forfaitjOption.code === "0" || (forfaitjOption && forfaitjOption.typeblock === "REP" && !forfaitjOption.presence) || (forfaitjOption && forfaitjOption.code !== "0" && !forfaitjOption.presence && forfaitjOption.typeblock === "FERI")) {
            if ((evtModel as any).hasEvent("HORAIRES", pk, idlg)) { //0
              horairesDoms = CWRENDERAGENTS._renderHoraires(layers, viewMode, evtModel, dtsModel, pk, confidentielle, selonNature, edt, displayMultiLines, lineHor, lineAbs, lineBadg, lineAct, options);
            } else {
              horairesDoms = ($("<div>").addClass("phx-cell-render-center ui-phx-horaire-indetermine"));
            }
          } else {
            //#595 ForfaitJ
            if (forfaitjOption && forfaitjOption.presence) {
              if (forfaitjOption.isHorExc) {
                horairesDoms = CWRENDERAGENTS._renderHoraires(layers, viewMode, evtModel, dtsModel, pk, confidentielle, selonNature, edt, displayMultiLines, lineHor, lineAbs, lineBadg, lineAct, options);
                horairesDoms.css("border-radius", "7px");
                if (!CWSTR.isBlank(options) && (options.usecase === "agenda" || options.usecase === "agenda_R")) {
                  const libelle = horairesDoms.find(".phx-cell-render-text").text();

                  horairesDoms.find(".phx-cell-render-text").text("* " + libelle);
                } else {
                  //const libelle = horairesDoms.find(".phx-cell-render-text").text();
                  horairesDoms.find(".phx-cell-render-text").text("");
                  if (displayMultiLines) {
                    horairesDoms.addClass("cw-forfaitj-cell-render-multiligne");
                    if (viewMode === "M") {
                      horairesDoms.css("width", "89%");
                    }
                  } else {
                    horairesDoms.css("height", "90%");
                    horairesDoms.css("border-radius", "7px");
                    horairesDoms.css("margin", "2px");
                    if (viewMode === "M") {
                      horairesDoms.css("width", "89%");
                    } else {
                      horairesDoms.css("width", "97%");
                    }
                  }
                }
              } else {
                if (!CWSTR.isBlank(options) && options.usecase === "planresp") {
                  if (!CWSTR.isBlank(forfaitjOption.nature)) {
                    cellForfaitj.addClass(forfaitjOption.nature);
                  }
                  if (forfaitjOption.isHorMat === true && forfaitjOption.isHorSoir === true) {
                    cellForfaitj.addClass("phx-cell-render-center");
                  } if (forfaitjOption.isHorMat === true && forfaitjOption.isHorSoir === false) {
                    cellForfaitj.addClass("phx-cell-render-left");
                  } else if (forfaitjOption.isHorMat === false && forfaitjOption.isHorSoir === true) {
                    cellForfaitj.addClass("phx-cell-render-right");
                  }
                  if (displayMultiLines) {
                    cellForfaitj.addClass("cw-forfaitj-cell-render-multiligne");
                    if (viewMode === "M" && !CWSTR.isBlank(options) && options.usecase === "planresp") {
                      cellDom.css("width", "89%");
                    }
                  } else {
                    cellForfaitj.css({ "height": "90%", "border-radius": "7px", "margin": "2px", "position": "unset" });
                    if (viewMode === "M" && !CWSTR.isBlank(options) && options.usecase === "planresp") {
                      cellForfaitj.css("width", "89%");
                    } else {
                      cellForfaitj.css("width", "97%");
                    }
                  }
                  if (forfaitjOption.typeblock !== "JOUR" && forfaitjOption.typeblock !== "REP") {
                    cellForfaitj.css("width", "47%");
                    if (!CWSTR.isBlank(options) && options.usecase === "planresp") {
                      cellForfaitj.css("overflow", "visible");
                    }
                    if (forfaitjOption.typeblock === "SOIR") {
                      if (displayMultiLines) {
                        cellForfaitj.css("margin-left", "73px");
                      } else {
                        cellForfaitj.css("float", "right");
                      }
                    }
                  }
                }
              }
              if (options.usecase !== "planresp") {
                cellDom.append(cellForfaitj);
              }
            }
          }
        }
        if (!layers.get("hor") && forfaitjOption && forfaitjOption.code !== "0" && (forfaitjOption.presence || forfaitjOption.typeblock === "REP" || forfaitjOption.isJourFerie)) {
          if ((forfaitjOption.isHorExc || forfaitjOption.typeblock === "REP" || forfaitjOption.isJourFerie) && !CWSTR.isBlank(options) && (options.usecase === "agenda" || options.usecase === "agenda_R")) {
            if (!CWSTR.isBlank(evtModel) && (evtModel as any).hasEvent("HORAIRES", pk, idlg)) {
              horairesDoms = CWRENDERAGENTS._renderHoraires(layers, viewMode, evtModel, dtsModel, pk, confidentielle, selonNature, edt, displayMultiLines, lineHor, lineAbs, lineBadg, lineAct, options);
              if (forfaitjOption.isHorExc) {
                const libelle = horairesDoms.find(".phx-cell-render-text").text();

                horairesDoms.find(".phx-cell-render-text").text("* " + libelle);
              }
            } else {
              horairesDoms = ($("<div>").addClass("phx-cell-render-center ui-phx-horaire-indetermine"));
            }
          } else {
            horairesDoms = $("<div>").addClass("phx-cell-render-center");
          }
          if (forfaitjOption.presence) {
            horairesDoms.css("border-radius", "7px");
          }
          if (!forfaitjOption.isHorExc) {
            if (!CWSTR.isBlank(forfaitjOption.nature) && !CWSTR.isBlank(options) && options.usecase !== "planresp") {
              if (horairesDoms !== null && horairesDoms !== undefined) {
                horairesDoms.addClass(forfaitjOption.nature);
              }
            } else {
              cellForfaitj.addClass(" ui-phx-color-blanc_1");
            }
          } else {
            if (!CWSTR.isBlank(forfaitjOption.nature)) {
              horairesDoms.addClass(forfaitjOption.nature);
            } else {
              horairesDoms.addClass(" ui-phx-color-blanc_1");
            }
            if (displayMultiLines) {
              horairesDoms.addClass("cw-forfaitj-cell-render-multiligne");
            }
          }
          if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.usecase) && options.usecase === "planresp") {
            cellForfaitj.css("border-radius", "7px");
            if (displayMultiLines) {
              cellForfaitj.addClass("cw-forfaitj-cell-render-multiligne");
            } else {
              cellForfaitj.css("height", "87%");
              cellForfaitj.css("margin", "2px");
            }
            if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.usecase) && options.usecase === "planresp") {
              if (viewMode === "M") {
                cellForfaitj.css("margin", "2px 0px 2px 2px");
                cellForfaitj.css("width", "86%");
              }
            } else {
              cellForfaitj.css("width", "97%");
            }
            if (forfaitjOption.typeblock !== "JOUR" && forfaitjOption.typeblock !== "REP" && !forfaitjOption.isJourFerie) {
              if (!CWSTR.isBlank(forfaitjOption.nature) && !CWSTR.isBlank(options) && options.usecase !== "planresp") {
                cellForfaitj.css("width", "50%");
              }
              if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.usecase) && options.usecase === "planresp") {
                cellForfaitj.css("overflow", "visible");
              }
              if (forfaitjOption.typeblock === "SOIR") {
                cellForfaitj.css("float", "right");
              }
            }
          } else {
            if (forfaitjOption.typeblock !== "REP" && !forfaitjOption.isJourFerie) {
              horairesDoms.css("border-radius", "7px");
              horairesDoms.css("height", "87%");
              horairesDoms.css("margin-top", "1px");
              horairesDoms.css("width", "97%");
            }
            if (forfaitjOption.typeblock !== "JOUR" && forfaitjOption.typeblock !== "REP" && !forfaitjOption.isJourFerie) {
              if (!CWSTR.isBlank(forfaitjOption.nature)) {
                horairesDoms.css("width", "50%");
              }
              if (forfaitjOption.typeblock === "SOIR") {
                horairesDoms.css("float", "right");
              } else {
                horairesDoms.css("margin-right", "38px");
              }
            }
          }
          if (options.usecase !== "planresp") {
            cellDom.append(cellForfaitj);
          }
        }
        // BADGEAGES
        if (layers.get("bdg")) { //3
          if ((evtModel as any).hasEvent("BADGEAGES", pk, idlg) && viewMode === "S") {
            badgeagesDoms = CWRENDERAGENTS._renderBadgeages(layers, viewMode, evtModel, dtsModel, pk, displayMultiLines, confidentielle, selonNature, lineBadg, lineHor, lineAbs, lineAct, options);
          }
        }
        // MÉMOS
        if (layers.get("mem")) { //3
          if ((evtModel as any).hasEvent("MEMOS", pk, idlg)) {
            memosDoms = CWRENDERAGENTS._renderMemos(layers, viewMode, evtModel, pk, options);
          }
        }
        // Activities
        if (layers.get("act")) {
          if ((evtModel as any).hasEvent("ACTIVITES", pk, idlg)) { //5
            if (displayMultiLines) {
              const lRtn = CWRENDERAGENTS._renderActivitiesMultilines(layers, viewMode, evtModel, dtsModel, pk, activitiesFilters, plagesMode, edt, options);

              activitiesDoms = lRtn.activitiesDoms;
              astreintesDoms = lRtn.astreintesDoms;
            } else {
              activitiesDoms = CWRENDERAGENTS._renderActivities(layers, viewMode, evtModel, dtsModel, pk, activitiesFilters, plagesMode, edt, options);
            }
          }
        }
        if ((evtModel as any).hasEvent("INDICATEURS", pk, idlg) === true) {
          let absTitle = "";
          let absTooltip = "";//Icon tooltip

          indicateurs = (evtModel as any).getEvent("INDICATEURS", pk, idlg).get("evt").at(0).get("dts"); //2
          indToRender = {};
          ap = false;
          ar = false;
          apz = false;
          arz = false;
          apav = false;
          arav = false;
          apzav = false;
          arzav = false;
          if (indicateurs.ap) {
            keys = _.keys(indicateurs.ap);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("prevu") === true) {
                ap = true;
                break;
              }
            }
            //Pour l'agenda annuelle
            if (size === 0 && _.isNumber(indicateurs.ap) && indicateurs.ap > 0) {
              ap = true;
              lActPreIsNum = lAgendaAnnuelInd;
            }
          }
          if (indicateurs.ar || indicateurs.asr) {
            keys = _.keys(indicateurs.ar);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("realise") === true) {
                ar = true;
                break;
              }
            }
            //Pour l'agenda annuelle
            if (size === 0 && _.isNumber(indicateurs.ar) && indicateurs.ar > 0) {
              ar = true;
              lActReaIsNum = lAgendaAnnuelInd;
            }
          }
          if (indicateurs.apz) {
            keys = _.keys(indicateurs.apz);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("prevu") === true) {
                apz = true;
                break;
              }
            }
            //Il n'y a pas dans l'agenda annuelle
          }
          if (indicateurs.arz) {
            keys = _.keys(indicateurs.arz);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("realise") === true) {
                arz = true;
                break;
              }
            }
            //Il n'y a pas dans l'agenda annuelle   
          }
          if (indicateurs.arav || indicateurs.asrav) {
            keys = _.keys(indicateurs.arav);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("realise") === true) {
                arav = true;
                break;
              }
            }
            //Pour l'agenda annuelle
            if (size === 0 && _.isNumber(indicateurs.arav) && indicateurs.arav > 0) {
              arav = true;
              lActReaIsNum = lAgendaAnnuelInd;
            }
          }
          if (indicateurs.apav) {
            keys = _.keys(indicateurs.apav);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("prevu") === true) {
                apav = true;
                break;
              }
            }
            //Pour l'agenda annuelle
            if (size === 0 && _.isNumber(indicateurs.apav) && indicateurs.apav > 0) {
              apav = true;
              lActPreIsNum = lAgendaAnnuelInd;
            }
          }
          if (indicateurs.apzav) {
            keys = _.keys(indicateurs.apzav);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("prevu") === true) {
                apzav = true;
                break;
              }
            }
            //Il n'y a pas dans l'agenda annuelle
          }
          if (indicateurs.arzav) {
            keys = _.keys(indicateurs.arzav);
            size = keys.length;
            for (i = 0; i < size; i++) {
              if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
                activitiesFilters.get(keys[i]).get("selected") === true &&
                activitiesFilters.get(keys[i]).get("realise") === true) {
                arzav = true;
                break;
              }
            }
            //Il n'y a pas dans l'agenda annuelle
          }
          //Pour l'agenda annuelle
          if (indicateurs.asp) {//indicateur d'astreintes (activités) dans l'agenda
            //on va utiliser le même indicateur parce que elles sont peints como activité prevués
            indicateurs.ap = indicateurs.ap || 0;
            indicateurs.ap += indicateurs.asp; //on doit ajouter les autres
            delete indicateurs.asp;//on doit l'enlever
            ap = true;
            lActPreIsNum = lAgendaAnnuelInd;
          }
          if (indicateurs.aspav) {//indicateur d'astreintes (activités) dans l'agenda
            //on va utiliser le même indicateur parce que elles sont peints como activité prevués
            indicateurs.apav = indicateurs.apav || 0;
            indicateurs.apav += indicateurs.aspav; //on doit ajouter les autres
            delete indicateurs.aspav;//on doit l'enlever
            apav = true;
            lActPreIsNum = lAgendaAnnuelInd;
          }
          if (indicateurs.asr) {//indicateur d'astreintes (activités) dans l'agenda
            //on va utiliser le même indicateur parce que elles sont peints como activité prevués
            indicateurs.ar = indicateurs.ar || 0;
            indicateurs.ar += indicateurs.asr; //on doit ajouter les autres
            delete indicateurs.asr;//on doit l'enlever
            ar = true;
            lActReaIsNum = lAgendaAnnuelInd;
          }
          if (indicateurs.asrav) {//indicateur d'astreintes (activités) dans l'agenda
            //on va utiliser le même indicateur parce que elles sont peints como activité prevués
            indicateurs.arav = indicateurs.arav || 0;
            indicateurs.arav += indicateurs.asrav; //on doit ajouter les autres
            delete indicateurs.asrav;//on doit l'enlever
            arav = true;
            lActReaIsNum = lAgendaAnnuelInd;
          }
          //---Indicateurs d'activités (et d'astreintes pour l'agenda annuelle)---------------
          indToRender.act = ((ap || ar || apz || arz || apav || arav || apzav || arzav) && layers.get("ind_act"));
          //----------------------------------------------------------------------------------
          if (indicateurs.abs && _.keys(indicateurs.abs).length === 0 && _.isNumber(indicateurs.abs) && indicateurs.abs > 0) {
            //despuis Agenda annuel
            lAbsIsNum = lAgendaAnnuelInd;
          }
          if (indicateurs.reg && _.keys(indicateurs.reg).length === 0 && _.isNumber(indicateurs.reg) && indicateurs.reg > 0) {
            //despuis Agenda annuel
            lRegIsNum = lAgendaAnnuelInd;
          }
          if (indicateurs.affsec && _.keys(indicateurs.affsec).length === 0 && _.isNumber(indicateurs.affsec) && indicateurs.affsec > 0) {
            //despuis Agenda annuel
            lAffIsNum = lAgendaAnnuelInd;
          }
          if (indicateurs.bad && _.keys(indicateurs.bad).length === 0 && _.isNumber(indicateurs.bad) && indicateurs.bad > 0) {
            //despuis Agenda annuel
            lBadIsNum = lAgendaAnnuelInd;
          }
          if (indicateurs.mes && _.keys(indicateurs.mes).length === 0 && _.isNumber(indicateurs.mes) && indicateurs.mes > 0) {
            //despuis Agenda annuel
            lMesIsNum = lAgendaAnnuelInd;
          }
          isIndCoc = false;
          //INDICATEURS
          if (layers.get("ind")) { //2
            indToRender.val = (indicateurs.val && layers.get("ind_ano"));
            indToRender.valb = (indicateurs.valb && layers.get("ind_ano"));
            indToRender.abs = (indicateurs.abs && layers.get("ind_abs"));
            indToRender.absv = (indicateurs.absv && layers.get("ind_abs"));
            indToRender.absav = (indicateurs.absav && layers.get("ind_abs"));
            indToRender.souv = (indicateurs.souv && layers.get("ind_abs"));
            indToRender.souav = (indicateurs.souav && layers.get("ind_abs"));
            indToRender.reg = (indicateurs.reg && layers.get("ind_reg"));
            indToRender.regav = (indicateurs.regav && layers.get("ind_reg"));
            indToRender.astreg = (indicateurs.astreg && layers.get("ind_astreg"));
            indToRender.mem = (indicateurs.mem && layers.get("ind_mem"));
            indToRender.mes = (indicateurs.mes && layers.get("ind_mes"));
            indToRender.bad = (indicateurs.bad && layers.get("ind_bdg"));
            indToRender.pret = (indicateurs.pretd && layers.get("ind_pret"));
            indPretAffSecToRender.affsec = (indicateurs.affsec && layers.get("ind_affs"));
            indPretAffSecToRender.rptn = (indicateurs.rptn && layers.get("ind_repla"));
            indPretAffSecToRender.rpen = (indicateurs.rpen && layers.get("ind_repla"));
            indPretAffSecToRender.pren = (indicateurs.pren && layers.get("ind_repla"));
            indPretAffSecToRender.prpn = (indicateurs.prpn && layers.get("ind_repla"));
            if (indToRender.val || indToRender.valb || indToRender.abs || indToRender.absv || indToRender.astreg || indToRender.absav ||
              indToRender.souv || indToRender.souav || indToRender.reg || indToRender.regav || indToRender.mem ||
              indToRender.mes || indToRender.bad || indToRender.act || indToRender.pret) {
              isIndCoc = true;
              //Add class for Affichage Options
              if (indToRender.regav) {
                indicateurDom.addClass("phx-cell-ind-regaval");
              }
              if (indToRender.astreg) {
                indicateurDom.addClass("phx-cell-ind-astregav");
              }
              if (indToRender.absav) {
                indicateurDom.addClass("phx-cell-ind-absaval");
              }
              if (indToRender.souav) {
                indicateurDom.addClass("phx-cell-ind-souaval");
              }
              //if (confidentielle && confidentielle.absences == true && (evtModel as any).hasEvent("ABSENCES",pk) == true) {
              if ((evtModel as any).hasEvent("ABSENCES", pk, idlg) === true) {
                const absences = (evtModel as any).getEvent("ABSENCES", pk, idlg).get("evt").models;
                let first = true;

                for (let i = 0; i < absences.length; i++) {
                  if (absences[i].get("dts").sou === false && absences[i].get("val") === "H" && !CWSTR.isBlank(absences[i].get("dts").hed) && !CWSTR.isBlank(absences[i].get("dts").hef)) {
                    if (first === true) {
                      first = false;
                    } else {
                      absTooltip += "<br/>";
                    }
                    if (confidentielle && confidentielle.absences === true) {
                      const auxtooltip = "<label>" + i18n.t('common:utils.absconf', {
                        "0": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hed, CWTYPE._getFormatByCode("DUREEHM")).trim() + "<label>",
                        "1": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hef, CWTYPE._getFormatByCode("DUREEHM")).trim(),
                        interpolation: { escapeValue: false }
                      });

                      absTooltip += auxtooltip;
                    } else {
                      let absLibelle = i18n.t('common:utils.absence');
                      let auxtooltip: string = null;

                      if (!CWSTR.isBlank(dtsModel.ABSENCES.get(absences[i].get("cod")))) {
                        absLibelle = dtsModel.ABSENCES.get(absences[i].get("cod")).get("lib");
                      }
                      auxtooltip = i18n.t('common:utils.abscomp', {
                        "0": absLibelle + "<label>",
                        "1": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hed, CWTYPE._getFormatByCode("DUREEHM")).trim() + "<label>",
                        "2": "</label>" + CWTYPE.HOUR_MINUTE.format(absences[i].get("dts").hef, CWTYPE._getFormatByCode("DUREEHM")).trim(),
                        interpolation: { escapeValue: false }
                      });
                      absTooltip += auxtooltip;
                    }
                  }
                }
              }
              if (!CWSTR.isBlank(absTooltip)) {
                absTitle = "title=\"" + absTooltip + "\" ";
              }
            }
          }
          if (indToRender.act || isIndCoc) {
            if (indicateurs.apav || indicateurs.apzav) {
              indicateurDom.addClass("phx-cell-act-prev-ind-val");
              if (indicateurs.apav) {
                _.each(indicateurs.apav, (index: number, value) => {
                  let currentData = indicateurDom.data("act-typos");
                  if (CWSTR.isBlank(currentData)) {
                    currentData = [];
                  }
                  currentData.push(value);
                  indicateurDom.data("act-typos", currentData);
                });
              }
              if (indicateurs.apzav) {
                _.each(indicateurs.apzav, (index: number, value) => {
                  let currentData = indicateurDom.data("act-typos");

                  if (CWSTR.isBlank(currentData)) {
                    currentData = [];
                  }
                  currentData.push(value);
                  indicateurDom.data("act-typos", currentData);
                });
              }
            }
            if (indicateurs.arav || indicateurs.arzav) {
              indicateurDom.addClass("phx-cell-act-rel-ind-val");
              if (indicateurs.arav) {
                _.each(indicateurs.arav, (index, value) => {
                  let currentData = indicateurDom.data("act-typos");

                  if (CWSTR.isBlank(currentData)) {
                    currentData = [];
                  }
                  currentData.push(value);
                  indicateurDom.data("act-typos", currentData);
                });
              }
              if (indicateurs.arzav) {
                _.each(indicateurs.arzav, (index, value) => {
                  let currentData = indicateurDom.data("act-typos");

                  if (CWSTR.isBlank(currentData)) {
                    currentData = [];
                  }
                  currentData.push(value);
                  indicateurDom.data("act-typos", currentData);
                });
              }
            }
          }
          if (monequip === true || edt === true
            || (lActPreIsNum === true && indToRender.act) || (lActReaIsNum === true && indToRender.act)
            || (lAbsIsNum && indToRender.abs)
            || (lRegIsNum && indToRender.reg)
            || (lAffIsNum && indPretAffSecToRender.affsec)
            || (lBadIsNum && indToRender.bad)
            || (lMesIsNum) && indToRender.mes) {
            //Agenda or emploi du temps keep having the old indicateur
            if (viewMode === "M") {
              if (monequip === true || edt === true || (lAgendaAnnuelInd && confidentielle.absences)) {
                indicateurDom.append("<span " + absTitle + "class='cw-cercle-plein-icon cw-icon cw-icon-10 cw-cursor-pointer'></span>");
              } else {
                indicateurDom.append(CWRENDERAGENTS.renderIndicateurPrimaire("S", princBordClass, princBackClass, anim1).addClass("ui-phx-icon-in-tooltip cw-diode-agenda"));
                //indicateurDom.append("<span " + absTitle + "class='cw-cercle-plein-icon cw-icon cw-icon-10 cw-cursor-pointer'></span>");
              }
            } else if (viewMode === "S") {
              indicateurDom.append("<span " + absTitle + "class='cw-cercle-plein-icon cw-icon cw-icon-16 cw-cursor-pointer'></span>");
            }
            CWRENDERAGENTS._renderIndicateursTooltip(indToRender, pk, model, indicateurs, viewEl, options);
          } else {
            const indPrimaire = CWRENDERAGENTS._checkIndicateurPrimaire(indToRender, typeIndSecon, indicSecondaire, indicateurs, activitiesFilters, viewMode);//Indicateur principale in planning
            const indSecondaireFlag = CWRENDERAGENTS._checkIndicateurSecondaire(indToRender, typeIndSecon, indicSecondaire, indicateurs, activitiesFilters, viewMode);
            const $indicateurPrimaire = CWRENDERAGENTS.renderIndicateurPrimaire(viewMode, princBordClass, princBackClass, anim1);
            const $seconContainer = $("<div>").addClass("phx-icon-secondaire-container");
            let $indicateurSecondaire = null;
            let optionsIndicateurs: { [key: string]: any } = {};

            indPrimaireCheck = indPrimaire;
            indSecondaireFlagCheck = indSecondaireFlag;
            if (!CWSTR.isBlank(absTooltip)) {
              $indicateurPrimaire.attr("title", absTooltip);
            }
            if (!indicContainerSim) {
              $indicateurSecondaire = CWRENDERAGENTS.renderIndicateurSecondaire(viewMode, secBordClass, secBackClass, anim2);
              if (viewMode === "M") { //Container of indicateur secondaire to set margins
                $seconContainer.addClass("phx-icon-secondaire-container-m");
                $seconContainer.css("display", "inline-block");
              } else {
                $seconContainer.addClass("phx-icon-secondaire-container-w");
                $seconContainer.css("display", "inline-block");
              }
              $seconContainer.append($indicateurSecondaire);
              if (indSecondaireFlag === true) { //Render indicateur secondaire
                indicateurDom.append($seconContainer);
              }
            }
            if (indPrimaire === true && isIndCoc === true) { //Render indicateur primaire
              indicateurDom.append($indicateurPrimaire);
            }
            optionsIndicateurs = {
              activitiesFilters: activitiesFilters,
              viewMode: viewMode,
              typologiesConfig: typologiesConfig,
              edt: edt,
              indPrimaire: true,
              indSecondaireFlag: false,
              typeIndSecon: typeIndSecon,
              indicSecondaire: indicSecondaire,
              princBackClass: princBackClass,
              princBordClass: princBordClass,
              secBackClass: secBackClass,
              secBordClass: secBordClass,
              absTextByGroupe: absTextByGroupe,
              pretsAffSecBackClass: pretsAffSecBackClass,
              pretsAffSecBordClass: pretsAffSecBordClass,
              isPlequipe: options.isPlequipe,
              paramDiverAnonymAbs: options.paramDiverAnonymAbs,
              paramDiverAnonymAct: options.paramDiverAnonymAct
            };
            if (model) {
              if (displayMultiLines) {
                if (!CWSTR.isBlank(objConfMultiline.diode)) {
                  lineInd = objConfMultiline.diode;
                }
                optionsIndicateurs = _.extend(optionsIndicateurs, {
                  "nbMultiLines": nbMultiLines,
                  "lineAbs": lineAbs,
                  "lineBadg": lineBadg,
                  "lineAct": lineAct,
                  "lineAst": lineAst,
                  "lineInd": lineInd,
                  "lineAstReg": lineAstReg,
                  "lineMem": lineMem
                });
                indicateursMultiline = CWRENDERAGENTS._renderIndicateursMultiLineTooltip(dtsModel, evtModel, indToRender, pk, model, indicateurs, optionsIndicateurs, indPretAffSecToRender);
                objIndicateurs = indicateursMultiline.objIndicateurs;
                objIndicateursSecondaire = indicateursMultiline.objIndicateursSecondaire;
                objIndicateursAffSec = indicateursMultiline.objIndicateursAffSec;
              } else {
                //New way to paint infobulle on indicateurs in planning
                if (indPrimaire === true) { //Render indicateur primaire
                  if (indSecondaireFlag === true) { //Addapt indToRender to avoid showing data of indicateur secondaire in primaire tooltip
                    const indToRenderSecondaire = CWRENDERAGENTS._getSecondaireIndicateur(typeIndSecon, indicSecondaire); //Prepare indTorender to render only secondaire

                    _.each(_.keys(indToRenderSecondaire), (key) => {
                      if (key !== "act") {
                        indToRender[key] = false;
                      } else if (String(typeIndSecon) === "2") { //For activities we check if there is a different typologie to be shown as indicateur (different from the one used for indicateur secondaire)
                        _.each(indicateurs.typo, (typolIndic: { [key: string]: any }) => {
                          if (String(typolIndic.cod) !== String(indicSecondaire)) {
                            indToRender[key] = true;
                          }
                        });

                      }
                    });
                  }
                  CWRENDERAGENTS._renderIndicateursNewTooltip(dtsModel, indToRender, pk, model, indicateurs, indicateurDom.find($indicateurPrimaire), optionsIndicateurs);
                }
                if (indSecondaireFlag === true) { //Render indicateur secondaire
                  const indToRenderSecondaire = CWRENDERAGENTS._getSecondaireIndicateur(typeIndSecon, indicSecondaire); //Prepare indTorender to render only secondaire

                  optionsIndicateurs.indPrimaire = false;
                  optionsIndicateurs.indSecondaireFlag = true;
                  CWRENDERAGENTS._renderIndicateursNewTooltip(dtsModel, indToRenderSecondaire, pk, model, indicateurs, indicateurDom.find($seconContainer), optionsIndicateurs);
                }
              }
            }
          }
        }
        if ((!CWSTR.isBlank(indPretAffSecToRender.rptn) && indPretAffSecToRender.rptn) //|| (!CWSTR.isBlank(indPretAffSecToRender.rpen) && indPretAffSecToRender.rpen)
          || (!CWSTR.isBlank(indPretAffSecToRender.pren) && indPretAffSecToRender.pren) || (!CWSTR.isBlank(indPretAffSecToRender.prpn) && indPretAffSecToRender.prpn)) {
          pretFlag = true;
        }
        if ((!CWSTR.isBlank(indPretAffSecToRender.affsec) && indPretAffSecToRender.affsec)) {
          affsecFlag = true;
        }
        if (affsecFlag || pretFlag) {
          let viewModeAff = "";
          let $indicateurPretAffsec: JQuery<HTMLElement> = null;

          if (viewMode === "S") {
            viewModeAff = "SC";
          } else {
            viewModeAff = viewMode;
          }
          $indicateurPretAffsec = CWRENDERAGENTS.renderIndicateurPretsAffSec(viewModeAff, pretsAffSecBordClass, pretsAffSecBackClass, anim3);
          options.indPrimaire = false;
          options.indSecondaireFlag = false;
          options.indPretsAffSec = true;
          if (indicateurDom[0].children.length > 0 && anim3 === true && viewModeAff === "M") {
            $indicateurPretAffsec.addClass("affPretAnim");
          }
          if (indicateurDom[0].children.length > 1) {
            indicateurDomAffPret.addClass("affPret" + viewMode);
            indicateurDomAffPret.append($indicateurPretAffsec);
            CWRENDERAGENTS._renderIndicateursNewTooltip(dtsModel, indPretAffSecToRender, pk, model, indicateurs, indicateurDomAffPret, options);
          } else {
            indicateurDom.prepend($indicateurPretAffsec);
            CWRENDERAGENTS._renderIndicateursNewTooltip(dtsModel, indPretAffSecToRender, pk, model, indicateurs, indicateurDom, options);
            if (!CWSTR.isBlank(horairesDoms) && horairesDoms.hasClass("phx-cell-render-right") === true) {
              indicateurDom.css("display", "grid");
            }
          }
        }
        //modification de la visualisation de horaire avec deux plages et deux ou plus indicateurs sur la même cellule
        if ((indPrimaireCheck && indSecondaireFlagCheck) || ((indPrimaireCheck || indSecondaireFlagCheck) && (pretFlag || affsecFlag))) {
          const paramDiverPLaff2plfx = !CWSTR.isBlank(CWDIVERS.get("PLaff2plfx")) ? CWDIVERS.get("PLaff2plfx") : "0";

          if (layers.get("hor") && layers.get("hor_pla") && paramDiverPLaff2plfx && (evtModel as any).hasEvent("HORAIRES", pk, idlg)) {
            if (horairesDoms && horairesDoms.length > 0) {
              const lPosFind = horairesDoms.find(".phx-cell-render-right.cw-cell-render__deuxPlages");

              if (lPosFind.length > 0) {
                if (displayMultiLines) {
                  const keysInd = _.keys(indicateursMultiline);

                  if (keysInd.length > 0) {
                    let cont = 0;

                    for (let i = 0; i < keysInd.length; i++) {
                      if (!CWSTR.isBlank(indicateursMultiline[keysInd[i]][lineHor])) {//S'il y a d'indicateurs dans la même ligne que l'horaire
                        cont++;
                      }
                    }
                    if (cont > 1) {
                      lPosFind.addClass("cwReduitCell");
                    }
                  }
                } else {//mode normal, une ligne
                  lPosFind.addClass("cwReduitCell");
                }
              }
            }
          }
        }
        //Build the html structure
        if (displayMultiLines) {
          if (!CWSTR.isBlank(forfaitjOption) && !CWSTR.isBlank(forfaitjOption.code) && forfaitjOption.code !== "0" && forfaitjOption.presence && !forfaitjOption.isHorExc) {
            cellDom.find("." + viewMode + "-event-row-" + lineHor + ".js-row-event").append(cellForfaitj);
          } else {
            cellDom.find("." + viewMode + "-event-row-" + lineHor + ".js-row-event").append(horairesDoms);
          }
          cellDom.find("." + viewMode + "-event-row-" + lineAbs + ".js-row-event").append(absencesDoms);
          //Clean up Texts
          //même line horaires et badgeages
          if (!CWSTR.isBlank(badgeagesDoms) && ((!CWSTR.isBlank(horairesDoms) && lineHor === lineBadg) || (!CWSTR.isBlank(absencesDoms) && lineAbs === lineBadg))) {
            if (badgeagesDoms.hasClass("phx-cell-render-center")) {
              cellDom.find("." + viewMode + "-event-row-" + lineBadg + ".js-row-event").find(".phx-cell-render-text").hide();
            } else if (badgeagesDoms.hasClass("phx-cell-render-left")) {
              cellDom.find("." + viewMode + "-event-row-" + lineBadg + ".js-row-event").find(".phx-cell-render-left .phx-cell-render-text").hide();
            } else if (badgeagesDoms.hasClass("phx-cell-render-right")) {
              cellDom.find("." + viewMode + "-event-row-" + lineBadg + ".js-row-event").find(".phx-cell-render-right .phx-cell-render-text").hide();
            }
          }
          cellDom.find("." + viewMode + "-event-row-" + lineBadg + ".js-row-event").append(badgeagesDoms);
          cellDom.find("." + viewMode + "-event-row-" + lineAct + ".js-row-event").append(activitiesDoms);
          cellDom.find("." + viewMode + "-event-row-" + lineAst + ".js-row-event").append(astreintesDoms);
          if (!CWSTR.isBlank(indicateurDom.html()) && ((objIndicateurs && objIndicateurs.length > 0) || hasSimInd)) {
            for (let i = 0; i < nbMultiLines; i++) {
              const indTemp = $("<div class='phx-cell-render-ind'/>");

              lineInd = objConfMultiline.diode;
              if (indicContainerSim && i === lineInd) {
                indTemp.append(indicContainerSim);
                cellDom.find("." + viewMode + "-event-row-" + lineInd + ".js-row-event").append(indTemp);
              } else if (!CWSTR.isBlank(objIndicateursSecondaire[i])) {
                const indSpan = CWRENDERAGENTS.renderIndicateurSecondaire(viewMode, secBordClass, secBackClass, anim2);
                const $seconContainer = $("<div>").addClass("phx-icon-secondaire-container");//Generate container for indicateur secondaire

                $seconContainer.css("display", "inline-block");
                if (viewMode === "M") { //Container of indicateur secondaire to set margins
                  $seconContainer.addClass("phx-icon-secondaire-container-m");
                } else {
                  $seconContainer.addClass("phx-icon-secondaire-container-w");
                }
                $seconContainer.append(indSpan)
                indTemp.append($seconContainer);
                indSpan.prop("title", objIndicateursSecondaire[i]);
                cellDom.find("." + viewMode + "-event-row-" + i + ".js-row-event").append(indTemp);
              }
              if (!CWSTR.isBlank(objIndicateurs[i])) {
                const indSpan = CWRENDERAGENTS.renderIndicateurPrimaire(viewMode, princBordClass, princBackClass, anim1);

                indTemp.append(indSpan);
                indSpan.prop("title", objIndicateurs[i]);
                cellDom.find("." + viewMode + "-event-row-" + i + ".js-row-event").append(indTemp);
              }
              //accentuer indicateurs multi-lignes
              if (!CWSTR.isBlank(objIndicateursSecondaire[i]) || !CWSTR.isBlank(objIndicateurs[i])) {
                if (lineInd === i && isIndCoc === true && indToRender.regav) {
                  indTemp.addClass("phx-cell-ind-regaval");
                }
                if (lineAbs === i && isIndCoc === true && indToRender.absav) {
                  indTemp.addClass("phx-cell-ind-absaval");
                }
                if (lineAbs === i && isIndCoc === true && indToRender.souav) {
                  indTemp.addClass("phx-cell-ind-souaval");
                }
                if (lineAct === i && isIndCoc === true && indToRender.act && (indicateurs.apav || indicateurs.apzav)) {
                  const actTyposP = indicateurDom.data("act-typos");

                  indTemp.addClass("phx-cell-act-prev-ind-val");
                  indTemp.data("act-typos", actTyposP);
                }
                if (lineAct === i && isIndCoc === true && indToRender.act && (indicateurs.arav || indicateurs.arzav)) {
                  const actTyposR = indicateurDom.data("act-typos");

                  indTemp.addClass("phx-cell-act-rel-ind-val");
                  indTemp.data("act-typos", actTyposR);
                }
              }
              if (!CWSTR.isBlank(objIndicateursAffSec) && (!CWSTR.isBlank(objIndicateursAffSec[i]))) {
                let viewModeAff = "";
                let indSpan: JQuery<HTMLElement> = null;

                if (viewMode === "S") {
                  viewModeAff = "SC";
                } else {
                  viewModeAff = viewMode;
                }
                indSpan = CWRENDERAGENTS.renderIndicateurPretsAffSec(viewModeAff, pretsAffSecBordClass, pretsAffSecBackClass, anim3);
                if (indTemp.children().length > 0 && anim3 === true && viewModeAff === "M") {
                  indSpan.addClass("affPretAnim");
                }
                if (indTemp.children().length > 0) {
                  indTemp.prepend(indSpan);
                } else {
                  indTemp.append(indSpan);
                }
                indSpan.prop("title", objIndicateursAffSec[i]);
                cellDom.find("." + viewMode + "-event-row-" + i + ".js-row-event").append(indTemp);
              }
            }
          }
          cellDom.find("." + viewMode + "-event-row-" + lineMem + ".js-row-event").append(memosDoms);
        } else {
          cellDom.append(horairesDoms);
          cellDom.append(absencesDoms);
          //Clean up Texts
          if (badgeagesDoms) {
            if (badgeagesDoms.hasClass("phx-cell-render-center")) {
              cellDom.find(".phx-cell-render-text").hide();
            } else if (badgeagesDoms.hasClass("phx-cell-render-left")) {
              cellDom.find(".phx-cell-render-left .phx-cell-render-text").hide();
            } else if (badgeagesDoms.hasClass("phx-cell-render-right")) {
              cellDom.find(".phx-cell-render-right .phx-cell-render-text").hide();
            }
          }
          cellDom.append(badgeagesDoms);
          cellDom.append(activitiesDoms);
          if (!CWSTR.isBlank(indicateurDomAffPret.html())) {
            cellDom.append(indicateurDomAffPret);
          }
          if (!CWSTR.isBlank(indicateurDom.html())) {
            cellDom.append(indicateurDom);
          }
          if (!CWSTR.isBlank(memosDoms)) {
            cellDom.append(memosDoms);
          }
          if (options.usecase === "planresp") {
            if (!CWSTR.isBlank(cellForfaitj)) {
              cellDom.append(cellForfaitj);
            }
          }
        }
        //render Restitution Régularisation d'astreintes
        if ((evtModel as any).hasEvent("ASTREINTES", pk, idlg) === true && layers.get("ast_pla")) {
          if (displayMultiLines) {
            let lFindHor = cellDom.find("." + viewMode + "-event-row-" + lineHor + ".js-row-event").find(".phx-cell-render-center"); //pour horaire de jour

            if (lineHor === lineAstReg) { //même que les horaires
              const lDivAst = $("<div/>");

              if (lFindHor.length === 0) {
                let lSubFindHor: JQuery = null;

                lFindHor = cellDom.find("." + viewMode + "-event-row-" + lineHor + ".js-row-event"); //pour matin, soir, etc
                lSubFindHor = lFindHor.find(".phx-cell-render-left");
                if (lSubFindHor.length === 0) {
                  lSubFindHor = lFindHor.find(".phx-cell-render-right");
                }
                if (lSubFindHor.length > 0 && lineHor === lineAstReg) {
                  lSubFindHor.css({ "height": "calc(100% - 4px)", "top": "4px" });
                }
              }
              lDivAst.css({ "border-top": "solid 4px", "position": "relative", "z-index": 100 });
              lDivAst.addClass("ui-phx-regularisation-dastreintes");
              //line de AstReg
              cellDom.find("." + viewMode + "-event-row-" + lineAstReg + ".js-row-event").prepend(lDivAst);
              if (lineHor === lineAstReg) {
                lFindHor.find(".phx-cell-render-text-wrap").css("margin-top", "-4px");
              }
            } else {
              const targetDom = $("<div/>");

              if (lFindHor.length === 0) {
                const lSubFindHor2 = cellDom.find("." + viewMode + "-event-row-" + lineHor + ".js-row-event").find(".phx-cell-render-left"); //pour matin, soir, etc

                if (lSubFindHor2.length === 0) {
                  targetDom.addClass("phx-cell-render-right");
                } else {
                  targetDom.addClass("phx-cell-render-left");
                }
              } else {
                targetDom.addClass("phx-cell-render-center");
              }
              targetDom.css({ "border-top": "solid 4px", "height": "1px" }); //1px pour éviter cacher/couvrir la boule du infobulle
              targetDom.addClass("ui-phx-regularisation-dastreintes");
              cellDom.find("." + viewMode + "-event-row-" + lineAstReg + ".js-row-event").append(targetDom);
            }
          } else {
            let wrapDiv = cellDom.find(".phx-cell-render-center"); //pour horaire de jour
            const divAst = $("<div></div>");

            if (wrapDiv.length === 0) {
              let lWrapaux = cellDom.find(".phx-cell-render-left");

              wrapDiv = cellDom; //pour matin, soir, etc
              if (lWrapaux.length === 0) {
                lWrapaux = cellDom.find(".phx-cell-render-right");
              }
              if (lWrapaux.length > 0) {
                lWrapaux.css({ "height": "calc(100% - 4px)", "top": "4px" });
              }
            }
            divAst.css({ "border-top": "solid 4px", "height": "1px" }); //1px pour éviter cacher/couvrir la boule du infobulle
            divAst.addClass("ui-phx-regularisation-dastreintes");
            wrapDiv.prepend(divAst);
            wrapDiv.find(".phx-cell-render-text-wrap").css("margin-top", "-4px");
          }
        }
      }
      //FIX the Vertical Alignment Problem,
      //NOTE if remove this check the Options Affichage Algorimth
      cellDom.find(".phx-cell-render-text").wrap("<div class='phx-cell-render-text-wrap'>");
      if (model.dts.affs === true && (CWSTR.isBlank(model.dts.affshp) || model.dts.affshp === false)) {
        cellDom.find(".phx-cell-render-center").addClass("phx-cell-affs");
        cellDom.find(".phx-cell-render-left").addClass("phx-cell-affs");
        cellDom.find(".phx-cell-render-right").addClass("phx-cell-affs");
        cellDom.find(".phx-cell-render-ind").addClass("phx-cell-affs");
      }
      viewEl.append(cellDom);
      cellDom = null;
      indicateurDom = null;
      indicateurDomAffPret = null;
    }
    return this;
  }

  static renderDayCellType(model: any, cell: any, ignoreHgrp?: boolean, isMedical?: boolean, evtModel?: Backbone.Model, pk?: any, dtsModel?: any): void {
    let ignHgrp = false;
    const actExt: any = [];

    if (isMedical) {
      _.each(dtsModel.ACTIVITES.models, (act: any) => {
        if (act.get("ext")) {
          actExt.push(act.get("cod"));
        }
      });
    }
    if (!CWSTR.isBlank(ignoreHgrp) && _.isBoolean(ignoreHgrp)) {
      ignHgrp = ignoreHgrp;
    }
    if (model && model.dts) {
      // Hors Critere
      if (model.dts.hper === true) {
        cell.addClass("phx-cell-hper");
      }
      // (Hors critere) No pret, no affectation secondaire with criteres, no affectation principal with criteres
      if (!model.dts.affp && !(model.dts.affs === true && model.dts.affshp === false) && (model.dts.affd === "N" && model.dts.hper === true)) {
        cell.addClass("phx-cell-affd");
      }
      // Hors Groupe
      if (model.dts.hgrp === true && !ignHgrp) {
        cell.addClass("phx-cell-hgrp");
      }
      // Collaborateur reçu sur la date "not" appartient à la population
      if (model.dts.affr === true && isMedical !== true) {
        cell.addClass("phx-cell-recu");
      }
      // Simulated
      if (model.dts.simu === true) {
        cell.addClass("phx-cell-simu");
      }
      // Collaborateur prêté sur la date n’appartient pas à la population
      if (model.dts.affd === "N" && !isMedical) {
        cell.addClass("phx-cell-prete");
      }
      //barre oblique pour planning collab
      if (model.dts.affd === "P" && !isMedical) {
        cell.addClass("phx-cell-barreOblique");
      }
      //in planning medical
      if (isMedical) {
        if (model.dts.affd === "N" || model.dts.affd === "P") {
          if (Object.values(model.dts.actc).includes(true) || model.dts.affd === "P") {
            cell.addClass("phx-cell-act");
          } else {
            cell.addClass("phx-cell-prete");
          }
          //la representation de la barre oblique pour le planning médical a été portée à la vue central (cwcenter.view)
        }
        // Collab external et médical
        if (model.user.ext) {
          if (Object.values(model.dts.actc).includes(true)) {
            cell.addClass("phx-cell-act")
          } else {
            cell.addClass("phx-cell-prete");
            cell.addClass("phx-cell-affd");
          }
        }
      }
      // affect secondaires sur la date "not" appartient à la population
      if (model.dts.affs === true && model.dts.affd === "N" && model.dts.affshp === true) {
        cell.addClass("phx-cell-affs");
      }
      // Is assigned to an activity that is part of the business plan
      if (isMedical && !CWSTR.isBlank(model.dts.actc) && (model.dts.actc["m"] === true || model.dts.actc["s"] === true ||
        model.dts.actc["n1"] === true || model.dts.actc["n2"] === true)) {
        cell.removeClass("phx-cell-affd");
        cell.removeClass("phx-cell-hper");
      }
      // Is reçu by pret or periode out of criteres
      if (isMedical && ((model.dts.affd === "O" && model.dts.hper === false) || (model.dts.affd === "P" && model.dts.affr === true))) {
        cell.removeClass("phx-cell-affd");
        cell.removeClass("phx-cell-hper");
      }
      // Journée modifiée de la simulation
      if (model.dts.msim && (model.dts.msim.hor === true || model.dts.msim.abs === true || model.dts.msim.prt === true)) {
        cell.addClass("phx-cell-msim");
      }
      // Ecarts en mode simulation
      if (model.dts.ect && (model.dts.ect.hor === true || model.dts.ect.abs === true || model.dts.ect.prt === true)) {
        cell.addClass("phx-cell-ect");
      }
    }
    // Jours calculés
    if (model && model.dat && model.user && model.user.dc && (parseInt(model.dat, 10) <= parseInt(model.user.dc, 10))) {
      cell.addClass("phx-cell-jourcalc");
    }
    // Jours transférés en paie
    if (model && model.dat && model.user && model.user.dep && (parseInt(model.dat, 10) <= parseInt(model.user.dep, 10))) {
      cell.addClass("phx-cell-jourtrp");
    }
    CWRENDER._neutralizeExternalEventsCollaborateurs(model, cell);
  }

  static _neutralizeExternalEventsCollaborateurs(model: any, cell: any): void {
    if (CWRENDER._isExternalCellCollaborateur(model)) {
      cell.find('[class*="cellMed-fract-"]').filter((index: number, item: any) => {
        return !$(item).hasClass("phx-cell-actInterieures");
      }).addClass('phx-cell-actExterieures');
      cell.find('.phx-cell-render-ind').filter((index: number, item: any) => {
        return !$(item).hasClass("phx-cell-actInterieures");
      }).addClass('phx-cell-actExterieures');
    } else {
      cell.find('[class*="cellMed-fract-"]').filter((index: number, item: any) => {
        return $(item).hasClass("phx-cell-actInterieuresForCheck");//il sera utilisé pour le neutraliser si le collaborateur n'est pas extérieur
      }).addClass('phx-cell-actExterieures');
      cell.find('.phx-cell-render-ind').filter((index: number, item: any) => {
        return $(item).hasClass("phx-cell-actInterieuresForCheck");
      }).addClass('phx-cell-actExterieures');
    }
  }

  static _heightMultiline(viewMode: string, layers: Backbone.Model): string {
    let lRtn = "normal"; //normal -> céllule de 45px et small de 22px

    if (viewMode === "M" || (viewMode === "S" && (!layers.get("hor") || (!layers.get("hor_pla") && !layers.get("bdg"))))) {
      lRtn = "small";
    }
    return lRtn;
  }

  /**
   * Create the CSS styles to give color to Absences by Group d'Absences.
   */
  static refreshDynamicCSS(group: any, type: string, scope?: any): string {
    const json = { "UTILS": UTILS, "groups": group, "scope": scope, "type": type };
    const templateDynamicStyle = cwCommmonDynamicStyleTPL;

    return templateDynamicStyle(json);
  }

  /** Create the CSS styles to give color to Absences by Group d'Absences.
   */
  static refreshDynamicCSSAbsences(group: any, type: string, scope: any): string {
    const json = { "UTILS": UTILS, "groups": group, "scope": scope, "type": type };
    const templateDynamicStyle = cwCommmonDynamicStyleAbsTPL;

    return templateDynamicStyle(json);
  }

  /**
   * Create the CSS styles to give color to Activités.
   */
  static refreshDynamicCSSAct(group: any, type: string, invert: boolean, scope?: any): string {
    const json = { "UTILS": UTILS, "groups": group, "invert": invert, "scope": scope, "type": type };
    const templateDynamicStyle = cwCommmonDynamicStyleActTPL;

    return templateDynamicStyle(json);
  }

  static _isExternalCellCollaborateur(model: any): boolean {
    if (model.user && _.isBoolean(model.user.ext)) {
      return model.user.ext;
    } else {
      return false;
    }
  }



  static preparationAbsencesCouv(lAbsences: Backbone.Collection, dtsModel: Backbone.Model | any): Array<Backbone.Model> {
    const listeAnnuleCouv: Array<Backbone.Model> = [];//crée une nouvelle liste

    //connaître s'il y a d'absences avec paramètre "annulecouv = 'O'"
    if (lAbsences) {
      _.each(lAbsences.models, (item: Backbone.Model) => {
        const lAbsDts = dtsModel.ABSENCES.get(item.get("cod"));

        if (lAbsDts && lAbsDts.get("annulecouv") === "O") {
          const lCopyAbs = new Backbone.Model(item.attributes);

          lCopyAbs.set("annulecouv", lAbsDts.get("annulecouv"));
          lCopyAbs.set("mavarint5", lAbsDts.get("mavarint5"));
          lCopyAbs.set("mavarint6", lAbsDts.get("mavarint6"));
          listeAnnuleCouv.push(lCopyAbs);
        }
      });
    }
    return CWRENDERMEDICAL.sortAbsences(listeAnnuleCouv);
  }
}
