module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-row persobriques-brique-line">\n  <div class="persobriques-brique-line-check-text col-10">\n    <div class="custom-control custom-checkbox persobriques-brique-line-check-container">\n        <input id="'+
((__t=(idLine))==null?'':__t)+
'" type="checkbox" class="persobriques-brique-line-check custom-control-input" name="customCheckbox" title="'+
((__t=(i18n.t('common:persobriques.briques_check')))==null?'':__t)+
'"/>\n        <label for="'+
((__t=(idLine))==null?'':__t)+
'" class="persobriques-brique-line-text custom-control-label cw-texteImportant"></label>\n        <label for="'+
((__t=(idLine))==null?'':__t)+
'" class="persobriques-brique-line-visibility"></label>\n    </div>\n  </div>\n  <div class="line-btns col-2">\n    <span class="persobriques-brique-line-btns"></span>\n  </div>\n</div>\n';
}
return __p;
};
