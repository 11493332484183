import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import gererbadgeagesPopUpTPL from '../cwPopUp.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWGererBadgeagesGestion } from '../models/cwGererBadgeagesGestion';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

export class CWGererBadgeagesPopUpView extends CWBaseFormView {

  action: string;
  statut: string;
  utilisateur: string;
  parent: any;

  /**
   * Constructor
   * View of dialog to gerer the badgeages
   */
  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_notifyEdition",
      "click button": "_clickListener"
    }, options.events);
    options.className = options.className || "cw-gererOneBadgeage";
    super(options);
    this.template = gererbadgeagesPopUpTPL;
    this.model = new CWBaseModel({
      value: new CWGererBadgeagesGestion()
    });
    if (options.parent) {
      this.parent = options.parent;
    }
    this.action = options.action;
    this.statut = options.statut;
    this.utilisateur = options.utilisateur;
  }


  /**
   * Renders the view to the form, and manage the comment of the dialog
   */
  render(): CWGererBadgeagesPopUpView {
    const json = { "i18n": i18n, UTILS: UTILS };

    this.$el.append(this.template(json));
    switch (this.action) {
      case "accepter":
        if (this.statut === "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gererbadgeages.commentaire_AcceptDemande'));
        } else if (this.statut === "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gererbadgeages.commentaire_AcceptCours'));
        }
        break;
      case "refuser":
        if (this.statut === "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gererbadgeages.commentaire_RefusDemande'));
        } else if (this.statut === "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gererbadgeages.commentaire_RefusCours'));
        }
        break;
      case "suppression":
        if (this.statut === "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gererbadgeages.commentaire_SuppresionCours'));
        } else if (this.statut === "A") {
          $(this.el).find(".spanComment").html(i18n.t('common:gererbadgeages.commentaire_SuppresionAccept'));
        } else {
          $(this.el).find(".spanComment").html(i18n.t('common:gererbadgeages.commentaire_SuppresionSupp'));
        }
        break;
      // no default
    }

    if (this.utilisateur === "Responsable" && this.action === "refuser") {

      this.$el.find("[for=commentaire]").addClass("required");
      //this.model.get("value").off("invalid");
      //this.model.get("value").on("invalid", this._showValidationErrors, this);
    }
    return this;
  }

  /**
   * Load the internal events of the view, and the events of TDA Date
   */


  /**
   * Load the action to the Button Bar
   */
  _clickListener(event: Event): void {
    const btnClicked = $(event.currentTarget).val();
    const model = this.model.get("value");

    switch (btnClicked) {
      case "btnOK": {
        //validation
        const errors: { [key: string]: any } = {};

        if (this.action === "refuser") {
          if (CWSTR.isBlank(model.get("commentaire"))) {
            errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.comment') });
          }
        }
        if (!_.isEmpty(errors)) {
          const errorMap = {
            errorValidation: CWSTR.formatMessage(errors),
            errors: errors
          };

          this._showValidationErrors(this.model.get("value"), errorMap);
        } else {
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.close();
        }
      }
        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
    }
  }
}