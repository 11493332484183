import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';


/**
 * Lister l'historique de l'evtGen
 */
export class CWListerEvetGenHistoriqueColl<TModel extends CWBaseModel = CWBaseModel> extends CWPaginatedCollection<TModel> {

  constructor(models?: TModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(models, options);
    this.url = Configuration.restRoot + "/rest/wkf/histo/ensemble/EVTGEN";
  }
}