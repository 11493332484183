import _ from 'underscore';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWRecapModel } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwRecap.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';

/**
 * Collection of DAbsence
 */
export class CWRecapColl<TModel extends CWRecapModel = CWRecapModel> extends CWPaginatedCollection<TModel> {
  /**
   * Add this to have Outline
   *
   */
  filtreStatus: string;
  recapitulatif: string;
  filtreDatedeb: string;
  filtreDatefin: string;
  matricule: string;
  piecejointe: any;
  feuilletemps: boolean;

  constructor(models?: CWRecapModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWRecapModel;
    super(models, options);
    this.paginated = false;
    this.url = (): string => {
      const optionalParams = this._buildURL();
      return Configuration.restRoot + "/rest/composant/recapactivite/liste" + optionalParams;
    }
  }

  /**
   * Set parametres to the collection from context
   */
  setParametres(filtreApplique: any, recapitulatif: any, datedeb: any, datefin: any, matricule: any, piecejointe: any, feuilletemps: any): void {
    if (filtreApplique && filtreApplique.length > 1) {
      this.filtreStatus = filtreApplique.join(",");
    } else {
      this.filtreStatus = "";
    }

    if (!CWSTR.isBlank(recapitulatif)) {
      this.recapitulatif = recapitulatif;
    }

    if (datedeb && datedeb !== CWTYPE.DATE.INITIAL) {
      this.filtreDatedeb = datedeb;
    }
    if (datefin && datefin !== CWTYPE.DATE.INFINITY) {
      this.filtreDatefin = datefin;
    }
    if (!CWSTR.isBlank(matricule)) {
      this.matricule = matricule;
    }
    if (!CWSTR.isBlank(piecejointe)) {
      this.piecejointe = piecejointe;
    }
    if (!CWSTR.isBlank(feuilletemps)) {
      this.feuilletemps = feuilletemps;
    }
  }

  /**
   * Build the URL by the params
   */
  _buildURL(): string {
    let existParam = false;
    let params = "";

    if (existParam) {
      params += "&";
    }
    existParam = true;
    params += "filtre_statuts=" + encodeURIComponent(this.filtreStatus);

    if (!CWSTR.isBlank(this.recapitulatif)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "recapitulatif=" + encodeURIComponent(this.recapitulatif);
    }
    if (!CWSTR.isBlank(this.filtreDatedeb)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "filtre_datedeb=" + encodeURIComponent(this.filtreDatedeb);
    }
    if (!CWSTR.isBlank(this.filtreDatefin)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "filtre_datefin=" + encodeURIComponent(this.filtreDatefin);
    }
    if (!CWSTR.isBlank(this.matricule)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "matricule=" + encodeURIComponent(this.matricule);
    }
    if (!CWSTR.isBlank(this.piecejointe)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "piecejointe=" + encodeURIComponent(this.piecejointe);
    }
    if (!CWSTR.isBlank(this.feuilletemps)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "ctxfeuilletemps=" + encodeURIComponent(this.feuilletemps);
    }
    if (existParam) {
      params = "?" + params;
    }
    return params;
  }
}
