import { CWBaseModel } from 'core/models/cwBase.model';
import { CWCriteresColl } from './cwCriteres.collection';

/**
 * Model of criteria for the right side
 */
export class CWCriteresModel extends CWBaseModel {

  collection: CWCriteresColl;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "libelle": "",
      "selectattribut": "",
      "obligatoire": false,
      "parent": "",
      "tda": null,
      "valeurparent": "",
      "code": "",
      "type": "",
      "groupe": null
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;

      const arraySous = response.souscriteres;
      response.souscriteres = new CWCriteresColl(arraySous, { parse: true });
    }
    return response;
  }
}