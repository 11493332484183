import { CWBaseModel } from 'core/models/cwBase.model';
import { STR } from 'utils/str.js';


/**
 * Model for infocomp and defaults of activity
 */
export class CWActiviteDefaults extends CWBaseModel {

  public hierid: string;
  public itemid: string;

  defaults(): { [key: string]: any } {
    return {
      "saisie_typedef": {
        "code": null,
        "libelle": ""
      },
      "valdef_impose": false,
      "saisie_dateunitedef": {
        "code": null,
        "libelle": ""
      },
      "modesaisie": "PLH",
      "saisie_modesaidatedef": {
        "code": null,
        "libellecourt": ""
      },
      "saisie_debperiodedef": {
        "code": null,
        "libelle": null
      },
      "saisie_finperiodedef": {
        "code": null,
        "libelle": null,
      },
      "saisie_typeperiodedef": {
        "code": null,
        "libelle": ""
      },
      "saisie_debunitedef": {
        "code": null,
        "libellecourt": ""
      },
      "saisie_finunitedef": {
        "code": null,
        "libellecourt": ""
      },
      "per_hdebdef": null,
      "per_hfindef": null,
      "carpers": null,
      "typehorspresence": "",
      "presence": false,
      "horspresence": false,
      "dat_hdebdef": null,
      "dat_hfindef": null,
      "dat_dureedef": null,
      "dat_pourcentdef": null,
      "dat_presdefav": false,
      "dat_presdefap": false,
      "typegest": "",
      "soumiswkf_p": false,
      "soumiswkf_r": false
    }
  }

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    super(options);

    this.url = (): string => {
      let url = "";

      if (STR.isBlank(this.hierid) || this.hierid === "null") {
        this.hierid = null;
      }
      if (STR.isBlank(this.itemid) || this.itemid === "null") {
        this.itemid = null;
      }
      url = Configuration.restRoot + "/rest/composant/activite/definition/" + encodeURIComponent(this.id) + "%2C" + encodeURIComponent(this.hierid) + "%2C" + (encodeURIComponent(this.itemid) || null);
      return url;
    }
  }

}
