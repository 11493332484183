import * as Backbone from 'Backbone';
import { CWTYPE } from 'tda/cwTda';

export class CWScheduleTextItemView extends Backbone.View {

    /**
     * HORIZONTAL
     */
    public HORIZONTAL: string; // "H",
    /**
     * VERTICAL
     */
    public VERTICAL: string; // "V",
    /**
     * Plage
    */
    public plage: any;
    /**
     * Direction (vertical or horizontal)
     */
    public direction: any;

    /**
     * Constructor
     * Representation of each Text (time) interval
     */
    constructor(options?: { [key: string]: any }) {
        options = options || {};
        options.className = options.className || "phx-schedule-text-item";
        options.tagName = options.tagName || "span";
        super(options);
        this.HORIZONTAL = "H";
        this.VERTICAL = "V";
        this.plage = options.plage; // Plage
        this.direction = options.direction || this.HORIZONTAL; //Direction (vertical or horizontal)
    }

    /**
     * Renders this view
     */
    render(): CWScheduleTextItemView {
        let property = "width";

        if (this.direction === this.VERTICAL) {
            property = "height";
            this.$el.css("width", "100%");
            this.$el.css("display", "block");
        }
        this.$el.css(property, this.plage.range + "%");

        if (this.plage.type === "toldeb") {
            this.$el.html("&nbsp");
        } else {
            this.$el.html(CWTYPE.HOUR_MINUTE.format(this.plage.hdebut));
        }
        return this;
    }
}