import { NiveauTypeHierarchieModel } from './niveauTypeHierarchie.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { STR } from 'utils/str.js';

/**
 * Collection for loading the hierarchies
 */
export var NiveauTypeHierarchieColl = PaginatedCollection.extend({
  /**
   * Add this to have Outline
   *
   */
  habilitationV: "PAR_ACTHIE.V",
  usecase: "activite",

  model: NiveauTypeHierarchieModel,

  url: function() {
    var url = Configuration.restRoot + "/rest/activite/typehierarchie/" + encodeURIComponent(this.code) + "/niveau";
    var params = "";

    if (!STR.isBlank(this.horsregr) && (this.horsregr === true || this.horsregr === false)) {
      params = "?horsregr=" + this.horsregr;
    }

    return url + params;
  },

  comparator: function(item) {
    return item.get('niveau');
  },

  initialize: function(code) {
    this.code = code;
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  isFirst: function(model) {
    var index = this.indexOf(model);
    return index === 0;
  },

  isLast: function(model) {
    var index = this.indexOf(model);
    return index === (this.length - 1);
  },

  getByNiveau: function(niveau) {
    var result = this.where({ "niveau": niveau });
    return result[0];
  },

  getFirstNiveau: function() {
    return this.at(0);
  },

  getPreviousNiveau: function(niveau) {
    var previousNiveau = null;

    for (var i = 0; i < this.length; i++) {
      if (String(this.at(i).get("niveau")) === String(niveau) && !this.isFirst(this.at(i))) {
        previousNiveau = this.at(i - 1);
      }
    }

    return previousNiveau;
  },

  getNextNiveau: function(niveau) {
    var nextNiveau = null;

    for (var i = 0; i < this.length; i++) {
      if (String(this.at(i).get("niveau")) === String(niveau) && !this.isLast(this.at(i))) {
        nextNiveau = this.at(i + 1);
      }
    }

    return nextNiveau;
  }
});
