import _ from 'underscore';
import { CWGererEvtGenWorflowActifModel } from './cwGererEvtGenWorflowActif.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';


/**
 * Collection of Type d'evénements
 */
export class CWGererEvtGenWorkflowActifColl extends CWPaginatedCollection<CWGererEvtGenWorflowActifModel>{

  canViewTreatment: boolean;

  constructor(models?: CWGererEvtGenWorflowActifModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWGererEvtGenWorflowActifModel;
    super(models, options);
    this.canViewTreatment = true;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/assocircuitevt/type";
    };
    this.sortings = { libelle: true };
    this.paginated = false;
  }
}