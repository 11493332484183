import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';
import { ModelSaveOptions } from 'Backbone';


export class CWCaracteristiquesFormModel extends CWBaseModel {

  // Name of the attribute that contains the caractéristiques paramétrables
  // It is expected to be an array
  infoCompAttributeName: string;
  MODEGROUPE: boolean;
  groupedErrors: {
    datedeb: Array<string>;
    datefin: Array<string>;
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.infoCompAttributeName = "infocomp";
    if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.infoCompAttributeName)) {
      this.infoCompAttributeName = options.infoCompAttributeName;
      this._sortModelCaracteristiques();
    }
    this.MODEGROUPE = false;
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
  }

  _sortModelCaracteristiques(): void {
    const infoCompArray = this.get(this.infoCompAttributeName);
    this.set(this.infoCompAttributeName, _.sortBy(infoCompArray, function (item: { [key: string]: any }) { return item.ordre; }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(attrs: { [key: string]: any }, options?: { [key: string]: any }): { [key: string]: any } {//options est utilisé dans le objet herité et il faut le déclarer ici
    let errors: { [key: string]: any } = {};

    errors = this._validateInfoComp(attrs);

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }

  save(key?: any, val?: ModelSaveOptions, options?: any): any {
    // Temporary fix: update valeur field when
    // codif table is used
    _.each(this.get(this.infoCompAttributeName), (item: { [key: string]: any }) => {
      if (!CWSTR.isBlank(item.codif)) {
        const value = item.valeurcodif.code;
        item.valeur = value;
      }
    });
    return super.save(key, val, options);
    //BaseModel.prototype.save.call(this, key, val, options);
  }

  _validateInfoComp(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    let tdaType: any = null;

    // infoCompAttributeName
    _.each(attrs[this.infoCompAttributeName], (item: { [key: string]: any }, index: number) => {
      let errorMsg;
      let valeurTag = "valeur";
      if (!CWSTR.isBlank(item.codif)) {
        valeurTag = "valeurcodif";
      }
      if ((CWSTR.isBlank(item.codif) && CWSTR.isBlank(item.valeur)) || (!CWSTR.isBlank(item.codif) && CWSTR.isBlank(item.valeurcodif.code))) {
        if (item.oblig) {
          if (CWSTR.isBlank(errors[this.infoCompAttributeName])) {
            errors[this.infoCompAttributeName] = {};
          }
          if (valeurTag === "valeur") {
            errors[this.infoCompAttributeName][index] = {};
            errors[this.infoCompAttributeName][index][valeurTag] = i18n.t('common:required', { "0": item.libelle });
          } else if (valeurTag === "valeurcodif") {
            errors[this.infoCompAttributeName][index] = {};
            errors[this.infoCompAttributeName][index][valeurTag] = { code: i18n.t('common:required', { "0": item.libelle }) };
          }
        }
      } else {
        switch (item.type) {
          case "D":
            if (CWSTR.isBlank(item.valeur) && item.oblig) {
              errorMsg = i18n.t('common:required', { "0": item.libelle });
            } else {
              errorMsg = CWTYPE.DATE.validate(item.valeur);
              if (CWSTR.isBlank(errorMsg)) {
                // Check if not between min and max dates.
                if ((!CWSTR.isBlank(item.datemin) && item.valeur < item.datemin) || (!CWSTR.isBlank(item.datemax) && item.valeur > item.datemax)) {
                  errorMsg = i18n.t('messages:GT_1040', { "1": item.libelle, "2": CWTYPE.DATE.format(item.datemin), "3": CWTYPE.DATE.format(item.datemax) });
                }
              }
            }
            if (!CWSTR.isBlank(errorMsg)) {
              if (CWSTR.isBlank(errors[this.infoCompAttributeName])) {
                errors[this.infoCompAttributeName] = {};
              }
              errors[this.infoCompAttributeName][index] = {};
              errors[this.infoCompAttributeName][index].valeur = errorMsg;
            }
            break;
          case "N":
            tdaType = CWTYPE.LONG;
            if (!CWSTR.isBlank(item.tda)) {
              tdaType = CWTYPE._getTypeByCode(item.tda)
            }
            if (CWSTR.isBlank(item.valeur) && item.oblig) {
              errorMsg = i18n.t('common:required', { "0": item.libelle });
            } else {
              errorMsg = tdaType.validate(item.valeur);
              if (CWSTR.isBlank(errorMsg) || (!CWSTR.isBlank(errorMsg) && item.tda === "DUR24HM")) {
                if ((!CWSTR.isBlank(item.nummin) && item.valeur < item.nummin) || (!CWSTR.isBlank(item.nummax) && item.valeur > item.nummax)) {
                  if (!CWSTR.isBlank(item.masque)) {
                    errorMsg = i18n.t('messages:GT_1041', { "1": item.libelle, "2": tdaType.format(item.nummin, item.masque), "3": tdaType.format(item.nummax, item.masque) });
                  } else {
                    errorMsg = i18n.t('messages:GT_1041', { "1": item.libelle, "2": tdaType.format(item.nummin), "3": tdaType.format(item.nummax) });
                  }
                }
              }
            }
            if (!CWSTR.isBlank(errorMsg)) {
              if (CWSTR.isBlank(errors[this.infoCompAttributeName])) {
                errors[this.infoCompAttributeName] = {};
              }
              errors[this.infoCompAttributeName][index] = {};
              errors[this.infoCompAttributeName][index].valeur = errorMsg;
            }
            break;
          case "C":
            if (CWSTR.isBlank(item[valeurTag]) && item.oblig) {
              errorMsg = i18n.t('common:required', { "0": item.libelle });
            } else if (!CWSTR.isBlank(CWTYPE._getTypeByCode(item.tda))) {
              errorMsg = CWTYPE._getTypeByCode(item.tda).validate(item[valeurTag]);
            }
            if (!CWSTR.isBlank(errorMsg)) {
              if (CWSTR.isBlank(errors[this.infoCompAttributeName])) {
                errors[this.infoCompAttributeName] = {};
              }
              errors[this.infoCompAttributeName][index] = {};
              errors[this.infoCompAttributeName][index][valeurTag] = errorMsg;
            }
            break;
          default:
          //Nothing
        }
      }
    });
    return errors;
  }

  clearInfocomp(): void {
    _.each(this.attributes[this.infoCompAttributeName], (item: { [key: string]: any }) => {
      if (item.oblig === false) {
        item.valeur = null;
        item.valeurcodif = { code: null, libelle: "" }
      }
    });
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    const infoCompOrder = _.sortBy(response[this.infoCompAttributeName], function (item: { [key: string]: any }) { return item.ordre; });
    response[this.infoCompAttributeName] = infoCompOrder;
    return response;
  }

}
