import { DesactivateMessageModel } from 'core/models/desactivateMessage.model.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from 'src/i18n.js';
import { SESSION } from './session.js';
import { STR } from './str.js';

/**
 * User messages manager
 */
export var MSGS = {

  /**
   * Confirm the family, number and usecase.
   *
   */
  confirmMessage: function(opt, options) {
    var casesArray = [
      { famille: "GT", numero: 1496, usecase: "utilisateur" },
      { famille: "GT", numero: 1496, usecase: "habr" },
      { famille: "GT", numero: 1496, usecase: "habg" },
      { famille: "GT", numero: 1496, usecase: "habp" },
      { famille: "GT", numero: 1932, usecase: "suivicollab" }
    ];
    var onglet = "";

    if (!STR.isBlank(options) && !STR.isBlank(options.headers) && !STR.isBlank(options.headers["gfi-context"]) && !STR.isBlank(JSON.parse(options.headers["gfi-context"]).onglet)) {
      onglet = JSON.parse(options.headers["gfi-context"]).onglet;
    } else {
      return false;
    }

    for (var i = 0; i < casesArray.length; i++) {
      if ((opt.famille === casesArray[i].famille) && (opt.numero === casesArray[i].numero) && (onglet === casesArray[i].usecase)) {
        return true;
      }
    }

    return false;
  },

  /**
   * Show a info message to the user
   *
   */
  showInfo: function(str, a_appendTo) {
    var text = str;
    var timeout = 6000; // millis
    var id = _.uniqueId("dialogShowInfo");
    var $div = $("<div>").attr("id", id);
    var $p = $("<p>").addClass("phx-msgs-body").html(text);
    var l_options = {};

    $div.append($p);
    if (!STR.isBlank(a_appendTo)) {
      a_appendTo.prepend($div);
    } else {
      $("body").prepend($div);
    }
    l_options = {
      dialogClass: "ui-dialog-showInfo ui-state-highlight",
      height: "auto",
      width: "auto",
      maxHeight: 200,
      maxWidth: 800,
      minHeight: 100,
      minWidth: 400,
      draggable: false,
      resizable: false,
      closeText: i18n.t('common:close'),
      position: {
        my: "right bottom",
        at: "right bottom",
        of: "#phx-container"
      },
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
        // apply unique styling
        $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass("ui-state-highlight");
      },
      open: function() {
        window.setTimeout(function() {
          $("#" + id).dialog('close');
        }, timeout);
      },
      close: function() {
        $(this).dialog("destroy");
        $(this).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), function(a_element) {
          if (a_element && $(a_element).tooltip("instance")) {
            $(a_element).tooltip("disable");
            $(a_element).tooltip("enable");
          }
        });
      }
    };
    if (!STR.isBlank(a_appendTo)) {
      l_options.appendTo = a_appendTo;
    }
    return $("#" + id).dialog(l_options);
  },

  /**
   * Show a warning message to the user
   *
   */
  showWarning: function(str, callback, noShowAgainButton, noShowAgainId, a_appendTo) {
    if (noShowAgainButton === true && SESSION.get(noShowAgainId) === true) {
      if (!STR.isBlank(callback)) {
        callback();
      }
      return null;
    } else {
      var text = str;
      var icon = "<span class='ui-icon ui-icon-alert' style='float: left; margin-right: .3em;'></span>";
      var id = _.uniqueId("dialogShowWarning");
      var $div = $("<div>").attr("id", id);
      var $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
      var l_options = {};

      $div.append($p);
      if (noShowAgainButton === true) {
        var noShowAgain = $("<div><input type='checkbox' name='neplusaff' >" + i18n.t('common:neplusaff') + "</input></div");
        noShowAgain.css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
          "margin-left": "calc(50% - 90px)"
        });
        $div.append(noShowAgain);
      }
      if (!STR.isBlank(a_appendTo)) {
        a_appendTo.prepend($div);
      } else {
        $("body").prepend($div);
      }
      l_options = {
        dialogClass: "ui-dialog-showWarning",
        width: 500,
        modal: true,
        title: i18n.t('common:msgs_warning_title'),
        closeText: i18n.t('common:close'),
        buttons: [{
          autofocus: true,
          text: i18n.t('common:close'),
          click: function() {
            $(this).dialog("close");
          }
        }],
        create: function() {
          var $parent = $(this).parent();
          $parent.removeClass("ui-corner-all");
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
          // apply unique styling
          $(this).parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap(
            '<div class="ui-state-highlight" style="border:none; background:none;" />');
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar").addClass("ui-state-highlight");
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass(
            "ui-state-highlight");
        },
        close: function(event) {
          if (noShowAgainButton === true) {
            SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
          }
          if (!STR.isBlank(callback)) {
            callback();
          }
          $(this).dialog("destroy");
          $(this).remove();
          //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
          _.each($(document).find("[aria-describedby]"), function(a_element) {
            if (a_element && $(a_element).tooltip("instance")) {
              $(a_element).tooltip("disable");
              $(a_element).tooltip("enable");
            }
          });
        }
      };
      if (!STR.isBlank(a_appendTo)) {
        l_options.appendTo = a_appendTo;
      }
      return $("#" + id).dialog(l_options);
    }
  },

  /**
   * Show a info message to the user in a center popup
   *
   */
  showInfoOpt: function(str, callback, noShowAgainButton, noShowAgainId, a_appendTo) {
    if (noShowAgainButton === true && SESSION.get(noShowAgainId) === true) {
      if (!STR.isBlank(callback)) {
        callback();
      }
      return null;
    } else {
      var text = str;
      var icon = "<span class='ui-icon ui-icon-info' style='float: left; margin-right: .3em;'></span>";
      var id = _.uniqueId("dialogShowWarning");
      var $div = $("<div>").attr("id", id);
      var $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
      var l_options = {};

      $div.append($p);
      if (noShowAgainButton === true) {
        var noShowAgain = $("<div><input type='checkbox' name='neplusaff' >" + i18n.t('common:neplusaff') + "</input></div");
        noShowAgain.css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
          "margin-left": "calc(50% - 90px)"
        });
        $div.append(noShowAgain);
      }
      if (!STR.isBlank(a_appendTo)) {
        a_appendTo.prepend($div);
      } else {
        $("body").prepend($div);
      }
      l_options = {
        dialogClass: "ui-dialog-showWarning",
        width: 500,
        modal: true,
        title: i18n.t('common:information'),
        closeText: i18n.t('common:close'),
        buttons: [{
          autofocus: true,
          text: i18n.t('common:close'),
          click: function() {
            $(this).dialog("close");
          }
        }],
        create: function() {
          var $parent = $(this).parent();
          $parent.removeClass("ui-corner-all");
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
          // apply unique styling
          $(this).parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap(
            '<div class="ui-state-highlight" style="border:none; background:none;" />');
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar").addClass("ui-state-highlight");
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass(
            "ui-state-highlight");
        },
        close: function(event) {
          if (noShowAgainButton === true) {
            SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
          }
          if (!STR.isBlank(callback)) {
            callback();
          }
          $(this).dialog("destroy");
          $(this).remove();
          //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
          _.each($(document).find("[aria-describedby]"), function(a_element) {
            if (a_element && $(a_element).tooltip("instance")) {
              $(a_element).tooltip("disable");
              $(a_element).tooltip("enable");
            }
          });
        }
      };
      if (!STR.isBlank(a_appendTo)) {
        l_options.appendTo = a_appendTo;
      }
      return $("#" + id).dialog(l_options);
    }
  },

  /**
   * Show an error message to the user
   *
   */
  showError: function(str, callback, a_appendTo) {
    var text = str;
    var icon = "<span class='ui-icon ui-icon-circle-close' style='float: left; margin-right: .3em;'></span>";
    var id = _.uniqueId("dialogShowError");
    var $div = $("<div>").attr("id", id);
    var $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
    var l_options = {};

    $div.append($p);
    if (!STR.isBlank(a_appendTo)) {
      a_appendTo.prepend($div);
    } else {
      $("body").prepend($div);
    }
    l_options = {
      dialogClass: "ui-dialog-showError",
      width: 500,
      modal: true,
      title: i18n.t('common:msgs_error_title'),
      closeText: i18n.t('common:close'),
      buttons: [{
        autofocus: true,
        text: i18n.t('common:close'),
        click: function() {
          $(this).dialog("close");
        }
      }],
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
        // apply unique styling
        $(this).parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap(
          '<div class="ui-state-error" style="border:none; background:none;" />');
      },
      close: function() {
        if (!STR.isBlank(callback)) {
          callback();
        }
        $(this).dialog("destroy");
        $(this).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), function(a_element) {
          if (a_element && $(a_element).tooltip("instance")) {
            $(a_element).tooltip("disable");
            $(a_element).tooltip("enable");
          }
        });
      }
    };
    if (!STR.isBlank(a_appendTo)) {
      l_options.appendTo = a_appendTo;
    }
    return $("#" + id).dialog(l_options);
  },

  /**
   * Show a message to the user requiring confirmation
   *
   * 								 true = 3 buttons yes/no/cancel
   * 								true = 1 button
   */
  showConfirm: function(str, callback, tributton, optionsText, oneButton, a_appendTo) {
    var text = str;
    var id = _.uniqueId("dialogShowConfirm");
    var $div = $("<div>").attr("id", id);
    var $p = $("<p>").addClass("phx-msgs-body").html(text);
    var l_options = {};
    var btn = [{
      text: (optionsText ? (optionsText.yes ? optionsText.yes : i18n.t('common:yes')) : i18n.t('common:yes')),
      click: function() {
        $(this).dialog("close");
        callback("Y");
      }
    }];

    $div.append($p);
    if (!STR.isBlank(a_appendTo)) {
      a_appendTo.prepend($div);
    } else {
      $("body").prepend($div);
    }
    if (tributton) {
      btn.push({
        text: (optionsText ? (optionsText.no ? optionsText.no : i18n.t('common:no')) : i18n.t('common:no')),
        click: function() {
          $(this).dialog("close");
          callback("N");
        }
      });
    }
    if (!_.isBoolean(oneButton) || !oneButton) {
      btn.push({
        autofocus: true,
        text: (optionsText ? (tributton ? (optionsText.cancel ? optionsText.cancel : i18n.t('common:cancel')) :
          (optionsText.no ? optionsText.no : i18n.t('common:cancel'))) : i18n.t('common:cancel')),
        click: function() {
          $(this).dialog("close");
          callback("C");
        }
      });
    }
    l_options = {
      title: i18n.t('common:msgs_confirm_title'),
      dialogClass: "ui-dialog-showConfirm",
      width: 500,
      modal: true,
      buttons: btn,
      closeText: i18n.t('common:close'),
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
      },
      close: function() {
        $(this).dialog("destroy");
        $(this).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), function(a_element) {
          if (a_element && $(a_element).tooltip("instance")) {
            $(a_element).tooltip("disable");
            $(a_element).tooltip("enable");
          }
        });
      }
    };
    if (!STR.isBlank(a_appendTo)) {
      l_options.appendTo = a_appendTo;
    }
    return $("#" + id).dialog(l_options);
  },

  /**
   * Show a message of notification
   *
   */
  showNotification: function(notification, callback, options, a_appendTo) {
    var id = _.uniqueId("dialogShowNotification");
    var l_options = {};
    var noShowAgain = null;
    var title = "";
    var dialogClass = "ui-state-highlight";
    var image = "";
    var btn = [];
    var $div = null;
    var $p = null;
    var check = null;
    var callbackAction = function(action) {
      switch (action) {
        case "BREAK": // ABORT
          break;
        case "ACTION": // CONTINUE
          if (callback) {
            callback(action);
          }
          break;
        default:
          if (check && noShowAgain && noShowAgain.find("input").prop("checked")) {
            var model = new DesactivateMessageModel();

            model.famille = notification.famille;
            model.numero = notification.numero;
            model.setHabContext(new HabilitationContext({
              onglet: JSON.parse(options.headers["gfi-context"]).onglet,
              foncCour: JSON.parse(options.headers["gfi-context"]).fonc_cour,
              natGest: ""
            }));
            model.save(null, {
              success: function() {
                if (callback) {
                  callback(action);
                }
              }
            });
          } else {
            if (callback) {
              callback(action);
            }
          }
      }
    };

    // set notification icon
    switch (notification.icone) {
      case "!":
        image = "<span class='ui-icon ui-icon-alert' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "?":
        image = "<span class='ui-icon ui-icon-info' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "X":
        image = "<span class='ui-icon ui-icon-circle-close' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      default:
    }
    // set notification title
    switch (notification.titre) {
      case "i18n_error":
        title = i18n.t('common:error');
        dialogClass = "ui-state-error";
        break;
      case "i18n_alert":
        title = i18n.t('common:alert');
        dialogClass = "ui-state-error";
        break;
      case "i18n_information":
        title = i18n.t('common:information');
        dialogClass = "ui-state-highlight";
        break;
      case "i18n_confirmation":
        title = i18n.t('common:confirmation');
        dialogClass = "ui-state-showConfirm";
        break;
      case "i18n_question":
        title = i18n.t('common:question');
        dialogClass = "ui-state-highlight";
        break;
      default:
        title = notification.titre;
        break;
    }
    $div = $("<div>").attr("id", id).attr("title", title);
    $div.append(image);
    $p = $("<p>").addClass("phx-msgs-body").html(notification.message);
    $div.append($p);
    check = this.confirmMessage(notification, options);
    if (check) {
      noShowAgain = $("<div><input type='checkbox' name='neplusaff' >" + i18n.t('common:neplusaff') + "</input></div");
      noShowAgain.css({
        "margin-top": "10px",
        "margin-bottom": "-5px"
      });
      $div.append(noShowAgain);
    }
    if (!STR.isBlank(a_appendTo)) {
      a_appendTo.prepend($div);
    } else {
      $("body").prepend($div);
    }
    // manage notification buttons
    _.each(notification.boutons, function(button) {
      var text = "";

      switch (button.libelle) {
        case "i18n_close":
          text = i18n.t('common:close');
          break;
        case "i18n_ok":
          text = i18n.t('common:ok');
          break;
        case "i18n_yes":
          text = i18n.t('common:yes');
          break;
        case "i18n_no":
          text = i18n.t('common:no');
          break;
        case "i18n_continue":
          text = i18n.t('common:continu');
          break;
        case "i18n_abort":
          text = i18n.t('common:abort');
          break;
        case "i18n_fermer":
          text = "Fermer";
          break;
        case "i18n_annuler":
          text = "Annuler";
          break;
        case "i18n_appliquer":
          text = "Appliquer";
          break;
        default:
          text = button.libelle;
          break;
      }
      btn.push({
        text: text,
        click: function() {
          $(this).dialog("close");
          callbackAction(button.action);
        }
      });
    }, this);
    // Set autofoucs to the last button, it usually is the cancel button.
    _.last(btn).autofocus = true;

    l_options = {
      modal: true,
      dialogClass: dialogClass,
      width: 500,
      buttons: btn,
      closeText: i18n.t('common:close'),
      create: function() {
        var $parent = $(this).parent();

        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
      },
      close: function() {
        $(this).dialog("destroy");
        $(this).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), function(a_element) {
          if (a_element && $(a_element).tooltip("instance")) {
            $(a_element).tooltip("disable");
            $(a_element).tooltip("enable");
          }
        });
      },
      open: function() {
        $(this).parent().find(".ui-dialog-buttonset button").focus();
      }
    };
    if (!STR.isBlank(a_appendTo)) {
      l_options.appendTo = a_appendTo;
    }
    return $("#" + id).dialog(l_options);
  }
};
