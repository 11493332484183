module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div id="bemprunt">\n	<span class="filtre_Statut">\n		<div class="cw-emprunt-statuts">\n			<span class=\'cw-emprunt-etiq cw-emprunt-etiqEnCours\'>'+
((__t=(i18n.t('common:bemprunt.fitre_demande')))==null?'':__t)+
'</span>\n			<span class=\'cw-emprunt-etiq cw-emprunt-etiqAccepte\'>'+
((__t=(i18n.t('common:bemprunt.filtre_aceptes')))==null?'':__t)+
'</span>\n			<span class=\'cw-emprunt-etiq cw-emprunt-etiqRefuse\'>'+
((__t=(i18n.t('common:bemprunt.filtre_refuses')))==null?'':__t)+
'</span>\n		</div>\n	</span>\n	<span class="table_BriqueMesEmprunts"></span>\n</div>';
}
return __p;
};
