import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWBodyView } from './views/cwBody.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHeaderView } from './views/cwHeader.view';
import { CWMenuView } from './views/cwMenu.view';
import { CWMSGS } from 'utils/cwMsgs';
import { CWRouterFactory } from 'src/uc/cwRouterFactory';
import { CWSTR } from 'utils/cwStr';
import { CWWorkFlowModel } from './models/cwWorkFlow.model';
import { FocusToFormSaveView } from './views/focusToFormSave.view.js';
import { FORMS } from 'utils/forms.js';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { NAV } from 'utils/nav.js';
import { objs } from 'src/objectsRepository';
import { SplitPanelView } from 'core/layouts/splitPanel.view.js';
import { UTILS } from 'utils/utils.js';

/**
 * Application main configuration
 */

/**
 * Application Main Router
 */
export class CWAppRouter extends Backbone.Router {

  workflow: CWWorkFlowModel;
  headerView: CWHeaderView;
  menuView: CWMenuView<CWWorkFlowModel>;
  bodyView: CWBodyView<CWBaseModel>;
  goToLogout: any;
  blocked: boolean;
  hidderDisabled: boolean;

  constructor() {
    const options = {
      routes: {
        "": "home",
        "z/:zoneid": "dispatchToUseCase",
        "z/:zoneid/:type/*subroute": "dispatchToUseCase"
      }
    }
    super(options);
    // Initializes the main parts of the UI and its workflow
    this.workflow = new CWWorkFlowModel();
    // change the uri in the home implies a real navigation
    this.workflow.on("change:uri", (wfl, value, options): void => {
      let trigger = true;

      LOG.debug("Navigate to: " + wfl.get("uri") + " value: " + value + (options.trigger ? " trigger: " + options.trigger : ""));
      if (options && _.isBoolean(options.trigger)) {
        trigger = options.trigger;
      }
      if (trigger === false) {
        const zone = wfl.get("uri").split("/").splice(1, 1).join("/");
        const usecase = wfl.get("uri").split("/").splice(3, 1).join("/");
        const newOperation = wfl.get("uri").split("/").splice(4).join("/");

        if (this.workflow.get("zone") === zone && this.workflow.get("usecase") === usecase && this.workflow.get("operation") !== newOperation) {
          this.workflow.set("operation", newOperation);
        }
      }
      // Gestion de l'affichage de la roue crantée en fonction de l'uri
      this._managePersonalization(wfl.get("uri"));
      this.navigate(wfl.get("uri"), { "trigger": trigger });
    });
    // Reset the subrouter of the usecase
    this.workflow.on("predestroy:usecase", this._predestroyUsecase, this);
    this.workflow.on("destroy:usecase", this._resetUsecase, this);
    // Manage full screen changes
    this.workflow.on("change:fullscreen", (wfl): void => {
      const fullsc = wfl.get("fullscreen");

      this._manageFullscreen(fullsc);
    });
    // Initializes the views
    this.headerView = new CWHeaderView({ el: $(".cw-header"), model: this.workflow });
    this.menuView = new CWMenuView({ el: $(".cw-menu"), model: this.workflow });
    this.bodyView = new CWBodyView({ el: $("#phx-container"), model: this.workflow });
    this.goToLogout = new FocusToFormSaveView({ el: $(".focusToFormSave") });
    this.workflow.headerView = this.headerView;
    this.workflow.menuView = this.menuView;
    this.workflow.bodyView = this.bodyView;
    Backbone.history.on("route", (router, name, args): void => {
      const infoDebug = ((router.prefix && router.module) ? "route: " + router.prefix + router.module + " " : " ") + (router.module ? "usecase: " + router.module + " " : " ") + (args.join("/") ? "operation: " + args.join("/") : "");

      if (infoDebug) {
        LOG.debug(infoDebug);
      }
      // If the router listener is a subroute and the subroute is already created
      if (router !== this && objs[router.module + "Rt"]) {
        const zone = router.prefix.split("/")[1];
        const type = router.prefix.split("/")[2];

        this.workflow.set("zone", zone);
        this.workflow.set("type", type);
        this.workflow.set("usecase", router.module);
        this.workflow.set("operation", args.join("/"));
        this.workflow.set("uri", Backbone.history.getFragment());
        LOG.debug("zone: " + zone + " usecase: " + router.module + " operation: " + args.join("/"));
      }
    });
    this.listenTo(Backbone, "resize:center", (): void => {
      this._resizeProtoResizables();
    });
    this.workflow.on("changed:usecase", (): void => {
      this._resizeProtoResizables();
      // event used to fix a bug in IE with the scrolls
      this.workflow.trigger("fixScrollPosition");
    });
    this.workflow.on("resize", (): void => {
      const usecase = this.workflow.get("usecase");

      if (!CWSTR.isBlank(usecase)) {
        this._resizeUsecaseResizables("#" + usecase);
        if (!CWSTR.isBlank(UTILS.usecase_loading_counter[usecase])) {
          UTILS.resizeUsecaseHidder(usecase);
        }
      }
      if (this.blocked) {
        this.blockApplication();
      }
    });
    this.workflow.on("block:app", (): void => {
      this.blockApplication();
    });
    this.workflow.on("unblock:app", (): void => {
      this.unblockApplication();
    });
    this.workflow.set("activeTabs", new Backbone.Model());
    this.workflow.set("newNavigation", false);
    this.blocked = false; //Flag to know if application is blocked (in etape par etape component application is blocked and no hidder should be shown);
    // disable the hidder temporary
    this.hidderDisabled = false;
    this.workflow.on("hidder:enable", (): void => {
      this.hidderDisabled = false;
    });
    this.workflow.on("hidder:disable", (): void => {
      this.hidderDisabled = true;
    });
    this.listenTo(this.workflow, "resize:menu", this._updateMaxWidthSplitPanels);
  }

  /**
   * Home of the application
   */
  home(): void {
    this._initHomeLayout((): void => {
      let preferedUcLoaded = false;

      if (!this.workflow.get("newNavigation") && this.workflow.paramEcranDef !== undefined && !CWSTR.isBlank(this.workflow.paramEcranDef.get("valeur")) &&
        this.workflow.paramEcranDef.get("valeur") !== "aucun" && GLOBAL_DATA.rights.menuVisibility.get(this.workflow.paramEcranDef.get("valeur"))) {
        preferedUcLoaded = this.workflow._loadPreferedUc();
      }
      if (preferedUcLoaded === false) {
        const briques = this.workflow.BRIQUES;
        let zone = "";
        const availableZones = this.workflow.ZONES;
        const oldUsecase = this.workflow.get("usecase");
        let obj: { [key: string]: any } = null;

        if (briques["coll"] && briques["coll"].length > 0 && this._checkBriquePermissions(briques["coll"])) {
          zone = "coll";
        } else if (briques["resp"] && briques["resp"].length > 0 && this._checkBriquePermissions(briques["resp"])) {
          zone = "resp";
        } else if (briques["adm"] && briques["adm"].length > 0 && this._checkBriquePermissions(briques["adm"])) {
          zone = "adm";
        } else if (briques["ref"] && briques["ref"].length > 0 && this._checkBriquePermissions(briques["ref"])) {
          zone = "ref";
        } else {
          // There is no briques, it's default zones
          if (!CWSTR.isBlank(availableZones.coll) && GLOBAL_DATA.rights.menuVisibility.get("coll")) {
            zone = "coll"; //Default zone if no briques
          } else if (!CWSTR.isBlank(availableZones.resp) && GLOBAL_DATA.rights.menuVisibility.get("resp")) {
            zone = "resp";
          } else if (!CWSTR.isBlank(availableZones.adm) && GLOBAL_DATA.rights.menuVisibility.get("adm")) {
            zone = "adm";
          } else if (!CWSTR.isBlank(availableZones.ref) && GLOBAL_DATA.rights.menuVisibility.get("ref")) {
            zone = "ref";
          }
        }
        obj = {
          "zone": zone,
          "usecase": "",
          "type": "uc",
          "operation": ""
        };
        this.workflow.set(obj);
        this.workflow.set("newNavigation", false);
        if (oldUsecase === "") {
          this.workflow.trigger("change:usecase", this.workflow, "");
        }
        if (zone !== "") {
          this.workflow.set("uri", "z/" + zone);
          this.workflow.set("selectedTab-" + zone, { module: "", type: "" });
        }
      }
    });
  }

  _checkBriquePermissions(briquesArr: { [key: string]: any }): any {
    return briquesArr.every((b: { [key: string]: any }): any => {
      return GLOBAL_DATA.rights.menuVisibility.get(b.emid);
    });
  }

  _handleUseCase(tmpTabs: Backbone.Model, z: string, usecaseid: string): void {
    const zone: { [key: string]: any } = _.findWhere(this.workflow.configuration.get("confignav").get("zones"), { id: z });
    let perms = null;
    let tab: { [key: string]: any } = null;

    _.each(zone.menus, (menu: { [key: string]: any }): void => {
      if (menu.id) {
        perms = menu.fonctions;
      }
    });
    tab = { id: usecaseid, permissions: perms };
    if (!tmpTabs.get(z)) {
      tmpTabs.set(z, new Backbone.Collection);
    }
    tmpTabs.get(z).add(tab);
  }

  /**
   * Manage the requirement of load an Usecase
   */
  dispatchToUseCase(zone: string, type: string, subroute: string): void {
    let uri = "";

    // Check if Home is not yet initialized
    if (!(CWSTR.isBlank(subroute) || _.isNull(subroute)) && NAV.checkMaximumTabs() === true) {
      // Limit for the tabs size
      return;
    }
    this._dispatchToZone(zone, (): void => {
      let ucid = "";

      this.workflow.set("zone", zone);
      // create zone uri
      uri += "z/" + zone;
      try {
        if (!CWSTR.isBlank(subroute) && zone !== subroute) {
          const parts = subroute.split("/");
          let operation = null;

          ucid = parts[0];
          if (parts.length > 1) {
            operation = subroute.replace(ucid + "/", "");
          }
          try {
            this.workflow.set("type", type);
            this.workflow.set("usecase", ucid);
            this._resizeProtoResizables();
          } catch (e) {
            LOG.error("Error resizing a module : " + e);
          }
          //add usecase uri
          uri += "/" + type + "/" + _.map(parts, (part): string => { return encodeURIComponent(part); }).join("/");
          this._loadModule(ucid, type + "/");
          if (!CWSTR.isBlank(operation)) {
            this.workflow.set("operation", operation);
          }
        } else {
          this.workflow.set("type", "");
          this.workflow.set("usecase", "");
          this.workflow.trigger("change:usecaseTitle", this.workflow.bodyView.zViews[zone].zJSON.nom);
        }
      } finally {
        // Set uri
        this.workflow.set("uri", uri);
        //menu
        this.workflow.menuView._toogleMenu(this.workflow.isGoingToZoneHome());
        if (!CWSTR.isBlank(ucid)) {
          let lZone = this.workflow._searchZoneFromUc(ucid);

          if (CWSTR.isBlank(lZone) && !CWSTR.isBlank(this.workflow.get("zone"))) {
            lZone = this.workflow.get("zone");
          }
          if (lZone !== zone) {
            zone = lZone; //Éviter des problèmes
          }
        }
        this.workflow.set("selectedTab-" + zone, { module: ucid, type: type });
        if (!CWSTR.isBlank(ucid)) {
          this._handleUseCase(this.workflow.get("activeTabs"), zone, ucid);
        }
      }
    });
  }

  /**
   * Manage the zone dispatching
   */
  _dispatchToZone(zone: string, callback: () => void): void {
    this._initHomeLayout((): void => {
      const zones = _.keys(this.workflow.ZONES);

      if (!_.contains(zones, zone)) {
        LOG.debug("The required zone does not exist : [" + zone + "]");
        this.workflow.set("uri", "");
        this.navigate(this.workflow.get("uri"));
      } else if (callback) {
        callback();
      }
    });
  }

  /**
   * Manage the change to full screen mode
   */
  _manageFullscreen(fullsc: boolean): void {
    if (fullsc === true) {
      $(".cw-header").hide();
      this._resizeProtoResizables();
      $(".phx-center").height($("#phx-wrap").height());
    } else {
      $(".cw-header").show();
      this._resizeProtoResizables();
    }
    this._updateMaxWidthSplitPanels();
  }

  /**
   * Initializes all the objects needed to paint the home. Layouts, workflow ...
   */
  _initHomeLayout(callback: () => void): void {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      } // fix the error in IE11.
      this._updateMaxWidthSplitPanels();
    } else {
      this.workflow.setUp((): void => {
        $("#phx-loading_initial").hide();
        $("#phx-wrap").show();
        this.headerView.render();
        this.menuView.render();
        this.bodyView.render();
        this.listenTo(GLOBAL_DATA.rights, "updateTabs", this._handleTabs);
        if (callback) {
          callback();
        }
        this._updateMaxWidthSplitPanels();
        // resize header
        (Backbone as any).trigger("resize:header");
      });
    }
  }

  _handleTabs(avoidNavigation: boolean, callback: () => any): void {
    if (avoidNavigation !== true) {
      const tmpTabs = this.workflow.get("activeTabs");
      let checkClosed = false;
      const zones = tmpTabs.values(tmpTabs.attributes);
      const zonesSize = zones.length;
      const applyAction = (): void => {
        const tabsToClose: Array<any> = [];
        const tabsToReload: { [key: string]: any } = {
          coll: [],
          adm: [],
          resp: [],
          ref: []
        };

        _.each(tmpTabs.attributes, (menus: { [key: string]: any }, id): void => {
          const menusSize = menus.length;

          for (let j = 0; j < menusSize; j++) {
            const usecase = menus.at(j);

            if (GLOBAL_DATA.rights.menuVisibility.get(usecase.id)) {
              tabsToReload[id].push(usecase);
            } else {
              tabsToClose.push(usecase);
            }
          }
        });
        //Pour les briques est necessaire ajouter avant l'information ded reload
        _.each(objs.appRt.bodyView.zViews, (zoneView: { [key: string]: any }, id: string): void => {
          _.each(zoneView.tabsView.home.BRIQUES_VIEWS, (v: { [key: string]: any }, i: string): void => {
            const briqueEmid = i.split(",")[1];

            if (GLOBAL_DATA.rights.menuVisibility.get(briqueEmid)) {
              v.$el.show();
            } else {
              v.$el.hide();
            }
          });
          tabsToReload[id].forEach((uc: { [key: string]: any }): void => {
            if (this.workflow.get("usecase") !== uc.id) {
              zoneView.tabsView.$el.find("li[aria-controls=" + uc.id + "]").prop("reload", true);
            }
          });
          zoneView.tabsView.$el.find("li[aria-controls=home" + id + "]").prop("reload", true);
        });
        if (GLOBAL_DATA.rights.menuVisibility.get(this.workflow.get("usecase"))) {
          CWMSGS.showInfo(i18n.t('messages:GL_1072'));
          this._predestroyUsecase(this.workflow.get("usecase"), true);
        } else {
          if (GLOBAL_DATA.rights.menuVisibility.get(this.workflow.get("zone"))) {
            NAV.navigate(this.workflow.get("zone"));
            this.workflow.trigger("change:usecase", this.workflow, "");
          } else {
            // if i'm already in the home I force to go there again.
            if ((Backbone.history as any).fragment === "") {
              Backbone.history.loadUrl((Backbone.history as any).fragment);
            } else {
              NAV.navigate("");
            }
            this.workflow.set("newNavigation", true);
          }
        }
        tabsToClose.forEach((uc): void => {
          this._predestroyUsecase(uc.get("id"), false, true);
        });
      };

      for (let i = 0; i < zonesSize && checkClosed === false; i++) {
        const menus = zones[i];
        const menusSize = menus.length;

        for (let j = 0; j < menusSize && checkClosed === false; j++) {
          const usecase = menus.at(j);

          checkClosed = !GLOBAL_DATA.rights.menuVisibility.get(usecase.get("id"));
        }
      }
      if (checkClosed) {
        CWMSGS.showConfirmAdapter(i18n.t('messages:GL_1071'), (result: string): void => {
          if (result === "Y") {
            applyAction();
            if (typeof callback === "function") {
              callback();
            }
          } else {
            objs.profilutilisateur._revertSelection();
          }
        });
      } else {
        applyAction();
        if (typeof callback === "function") {
          callback();
        }
      }
    }
  }

  /**
   * Load an application module on the fly
   */
  _loadModule(module: string, modulePrefix: string): void {
    this._createNewRouter(module, modulePrefix);
  }

  _createNewRouter(module: string, modulePrefix: string): void {
    try {
      let prefix = "";

      // We assume all files are already loaded.
      if (!CWSTR.isBlank(module)) {
        let lZone = objs.appRt.workflow._searchZoneFromUc(module);

        if (CWSTR.isBlank(lZone) && !CWSTR.isBlank(this.workflow.get("zone"))) {
          lZone = this.workflow.get("zone");
        }
        //la zone du module est obtenu de l'information qui déjà on a. On évite des problèmes comme "coll/pl/planresp" ou "ref/suivi..."
        prefix = "z/" + lZone + "/" + modulePrefix;
        if (this.workflow.get("zone") !== lZone) {
          LOG.debug("(_createNewRouter) zone distinct: " + this.workflow.get("zone") + " !=" + lZone);
          this.workflow.set("zone", lZone, { silent: true });
        }
      } else {
        prefix = "z/" + this.workflow.get("zone") + "/" + modulePrefix;
      }
      LOG.debug("(_createNewRouter) prefix Router:" + prefix + module);
      CWRouterFactory.get(module).then((router: any): void => {
        let notCreation = false;

        LOG.debug("New Router " + module)
        if (objs[module + "Rt"]) {
          if (objs[module + "Rt"].newRouter !== true) {
            LOG.debug("Avant New Router - Clean Info " + module);
            objs[module + "Rt"].off();
            objs[module + "Rt"].stopListening();
          } else {
            LOG.debug("New Router - Canceled -" + module);
            notCreation = true;
          }
        }
        if (notCreation !== true) {
          objs[module + "Rt"] = new router(prefix, { "prefix": prefix, createTrailingSlashRoutes: true, "module": module });
          objs[module + "Rt"].newRouter = true;
          if (typeof objs[module + "Rt"].matchedRouteMethod === "function") {
            const lTmpAgendaR = (module === "agenda_R") ? this.workflow.get("operation") : null;

            objs[module + "Rt"].matchedRouteMethod();
            //rétablir la valeur de "operation" si module est "agenda_R"
            if (!CWSTR.isBlank(lTmpAgendaR)) {
              this.workflow.set("operation", lTmpAgendaR, { silent: true });
            }
          }
          objs[module + "Rt"].on("uc:ready", (): void => {
            objs[module + "Rt"].newRouter = false;
            this._resizeProtoResizables();
            this._updateMaxWidthSplitPanels();
          });
          this.workflow.set("usecase", objs[module + "Rt"].module);
        }
      });
    } catch (ex) {
      throw new Error("Error creating a router. Error code:(LoadingUCException) - reason -> " + ex);
    }
  }

  /**
   * Reset recursively the backbone objects by calling off() or remove() for
   * the views
   */
  _resetBackboneRouter(obj: { [key: string]: any }): void {
    if (obj instanceof Backbone.Router) {
      obj.off();
      obj.stopListening();
      if ((obj as any).remove) {
        (obj as any).remove();
      } else {
        //TAG-CHANGER LIBJQUERY, on devra verifier pour le changement des librairies JQuery et jQuery-UI
        UTILS.backboneObjectRecollector(obj);
      }
    }
  }

  /**
   * Reset the router of the Usecase if it exists
   */
  _resetUsecase(ucid: string, reloadUC: boolean, isOmittedCheck?: boolean, callback?: () => void): void {
    const router = objs[ucid + "Rt"];

    // Delete UC routes from history
    Backbone.history.handlers = _.filter(Backbone.history.handlers, (historyRouter): boolean => {
      let notFromUC = true;
      const route = historyRouter.route.toString();

      // Only keep routes that are not from current UC
      if (ucid === "agenda") {
        notFromUC = (route.indexOf("uc\\/agenda\\/") === -1 && route.indexOf("uc\\/agenda$/") === -1);
      } else if (ucid === "agenda_R") {
        notFromUC = (route.indexOf("uc\\/agenda_R\\/") === -1 && route.indexOf("uc\\/agenda_R$/") === -1);
      } else {
        notFromUC = (route.indexOf(ucid) === -1);
      }
      return notFromUC;
    });
    if (router) {
      let tmpTabs = null;

      try {
        //TAG-CHANGER LIBJQUERY, on devra verifier pour le changement des librairies JQuery et jQuery-UI(on pourra utiliser "delete objs[ucid + "Rt"];" ou corriger le code suivant
        this._resetBackboneRouter(router);
      }
      catch (error) {
        LOG.warn("Warn! I can't clean all memory: " + error);
      }
      tmpTabs = this.workflow.get("activeTabs");
      tmpTabs.values(tmpTabs.attributes).forEach((coll: { [key: string]: any }): void => {
        coll.reset(coll.without(coll.get(ucid)));
      });
      delete objs[ucid + "Rt"];
    }
    if (reloadUC) {
      const ulDom = $("#phx-tabs-" + this.workflow.get("zone") + " ul").eq(0);
      let subroute = ucid

      if ($("#" + ucid, ulDom.parent()).length === 0) {
        ulDom.after($("<div id=\"" + ucid + "\" class='phx-proto-resizable '></div>"));
      }
      if (this.workflow.get("usecase") === ucid && !CWSTR.isBlank(this.workflow.get("operation"))) {
        subroute += "/" + this.workflow.get("operation");
        if (ucid === "agenda") {
          subroute = subroute.replace(/^\/+|\/+$/g, "");
        }
      }
      this.dispatchToUseCase(this.workflow.get("zone"), ulDom.find("[aria-controls=" + ucid + "]").attr("uc-type"), subroute);
    }
    if (callback) {
      callback();
    }
  }

  /**
   * Reset the router of the Usecase if it exists
   */
  _predestroyUsecase(ucid: string, reloadUC?: boolean, omittedCheck?: boolean, callback?: () => void): void {
    const router = objs[ucid + "Rt"];

    LOG.debug("destroy:" + ucid + " before treatClose");
    //When router has a _treatClose function
    if (router && !CWSTR.isBlank(router._treatClose)) {
      router._treatClose(reloadUC, (): void => {
        LOG.debug("destroy:" + ucid + " after treatClose");
        this.workflow.trigger("destroy:usecase", ucid, reloadUC, omittedCheck, callback);
      });
    } else {
      LOG.debug("destroy:" + ucid + " wihtout treatClose");
      this.workflow.trigger("destroy:usecase", ucid, reloadUC, omittedCheck, callback);
    }
  }

  /**
   * Configure the screen and layout management
   */
  configureHomeLayout(): void {
    let prevHeight = $("#phx-wrap").height();

    //On Resize the center part
    $(window).resize((event: JQuery.TriggeredEvent, ui?: JQueryUI.ResizableUIParams): void => {
      const height = $("#phx-wrap").height();

      // If the resize don't implies an ui or is on height it applies resizeProtos.
      if ((!ui && prevHeight !== height) || (ui && ui.originalSize.height !== ui.size.height)) {
        // reset previous height
        prevHeight = height;
        this._resizeProtoResizables();
      }
      this._updateMaxWidthSplitPanels();
      // Expand resize event to the whole application
      (Backbone as any).trigger("resize:views", event, ui);
    });
    // Configure tooltips
    $(document).tooltip(FORMS.tooltip).off("focusin");
    // changed the general config to avoid problems.
    $(document).tooltip(FORMS.tooltip).on('focusout mouseout mouseleave', (): void => {
      const lTool = $(document).find("[role='tooltip']");

      if (lTool.length > 1) {
        const lLen = lTool.length - 1;

        for (let i = 0; i < lLen; i++) {
          $(document).find("[role='tooltip']").first().remove();
        }
      }
      $(document).tooltip('close');
    }).tooltip();
  }

  /**
   * Resize the height of layout divs
   */
  _resizeProtoResizables(): void {
    let headerHeight = 0;

    if ($(".cw-header").is(":visible")) {
      headerHeight = $(".cw-header").outerHeight();
    }
    // Sets the height of the phx-container.
    $("#phx-container").height("calc(100vh - " + headerHeight + "px)");
    this._resizeUsecaseResizables("#phx-container");
    this.workflow.trigger("resize");
  }

  /**
   * Resize the height of layout divs
   */
  _resizeUsecaseResizables(usecase: string): void {
    const components = $(usecase + " .phx-proto-resizable").filter(":visible");

    _.each(components, (component): void => {
      const offsetTop = $(component).offset().top;
      const margins = parseFloat($(component).css("margin-top").replace("px", "")) + parseFloat($(component).css("margin-bottom").replace("px", ""));
      const paddings = parseFloat($(component).css("padding-top").replace("px", "")) + parseFloat($(component).css("padding-bottom").replace("px", ""));
      let newHeight = 0;

      if (component && offsetTop > 0) {
        newHeight = $("#phx-wrap").height() - (offsetTop + margins + paddings);
        $(component).height(newHeight);
      } else {
        $(component).height("100%");
      }
    }, this);
    this._cwUpdateHeightSplitPanels();
  }

  /**
   * Resize the maxwidth of split panels and the width of partB to adjust
   * scroll
   */
  _updateMaxWidthSplitPanels(): void {
    const borderWidth = SplitPanelView.prototype.SPLIT_BORDER_WIDTH;//This border width is needed to adjust the min and maximun width of the menu
    const zone = this.workflow.get("zone");
    const lastUsecase = "lastuc-" + zone;// set width of main container
    const activeModule = this.workflow.get(lastUsecase);
    let maxWidthAllowed: number = null;
    let $module: JQuery = null;
    let $elSplitA: JQuery = null;

    if (!activeModule || activeModule === "") {
      return;
    }
    maxWidthAllowed = $("#phx-wrap").width() - $("#phx-wrap .cw-menu").width(); // avant $("#phx-wrap").width();
    //set size of active panel
    $module = $("#" + activeModule);
    // $module.width(maxWidthAllowed - navMenuWidth);
    $module.width("100%");
    // Configure split panels
    // set size of left panel
    $elSplitA = $module.find(".phx-splitPanel .phx-splitA");
    // If is a liste detail
    if ($elSplitA.length > 0) {
      const size = $elSplitA.length;

      for (let i = 0; i < size; i++) {
        const cid = $elSplitA.eq(i).parents(".phx-splitPanel").attr("cid");

        LOG.debug("Backbone=>rezize:" + cid);
        (Backbone as any).trigger("resize:" + cid);
      }
    } else {
      $elSplitA = $module.find(".phx-splitA .phx-panelA3");
      $elSplitA.width(maxWidthAllowed - borderWidth);
    }
  }

  _cwUpdateHeightSplitPanels(): void {
    const zone = this.workflow.get("zone");
    const lastUsecase = "lastuc-" + zone;
    const activeModule = this.workflow.get(lastUsecase);
    const layoutPadding = 90;
    let $module: JQuery = null;
    let ucHeight: number = null;

    if (!activeModule || activeModule === "") {
      return;
    }
    $module = $("#" + activeModule);
    // Set height of split B
    ucHeight = $($module).height() - layoutPadding;
    $(".l-splitB__cell", $module).attr("style", "max-height: " + ucHeight + "px");
  }

  /**
   * Calculate the required height of #phx-container
   */
  calculateCenterHeight(): number {
    return $("#phx-wrap").height() - $(".cw-header:visible").height();
  }

  blockApplication(): void {
    const $divBlocker = $("<div>").addClass("phx-parent-hidder");
    const $divBlockerTabs = $("<div>").addClass("phx-tabs-hidder");
    const $divBlockerSplitA = $("<div>").addClass("phx-parent-hidder");
    const $divBlockerPanelB1 = $("<div>").addClass("phx-panelB1-hidder");
    const $divBlockerPanelB2 = $("<div>").addClass("phx-panelB2-hidder");
    const lTabZone = !CWSTR.isBlank(this.workflow.get("usecase")) ? "#phx-tabs-" + objs.appRt.workflow._searchZoneFromUc(objs.appRt.workflow.get("usecase")) : "#phx-tabs-" + objs.appRt.workflow.get("zone");
    const lUsecase = "#" + objs.appRt.workflow.get("usecase");

    //Premier
    if (this.blocked) {
      this.unblockApplication();
    }
    //Après
    $(".cw-header").prepend($divBlocker);
    if ($(lTabZone).length > 0) {
      const lHeight = $(lTabZone + " > ul").outerHeight(true);

      $(lTabZone).prepend($divBlockerTabs);
      $(lTabZone + " .phx-tabs-hidder").height(lHeight + 5);
    }
    if (!CWSTR.isBlank(lUsecase)) {
      const $lDivB1 = $(lUsecase + " .phx-splitBcell .phx-splitB .phx-contentPanel.phx-panelB1");
      const $lDivB2 = $(lUsecase + " .phx-splitBcell .phx-splitB .phx-contentPanel.phx-panelB2");
      const lMarginLeftSplitA = $(lUsecase + " .phx-splitA").outerWidth(true);

      $(lUsecase + " .phx-splitA").prepend($divBlockerSplitA);
      if ($lDivB1.length > 0) {
        const lHeightB1 = $lDivB1.outerHeight(true);
        const lWidthB1 = $lDivB1.outerWidth() + 9;

        $lDivB1.prepend($divBlockerPanelB1);
        $lDivB1.find(".phx-panelB1-hidder").css({ "height": lHeightB1, "width": lWidthB1, "margin-left": lMarginLeftSplitA });
      }
      if ($lDivB2.length > 0) {
        const lHeightB2 = $lDivB2.outerHeight(true) + 5;
        const lWidthB2 = $lDivB2.outerWidth() + 9;

        $lDivB2.prepend($divBlockerPanelB2);
        $lDivB2.find(".phx-panelB2-hidder").css({ "height": lHeightB2, "width": lWidthB2, "margin-left": lMarginLeftSplitA });
      }
    }
    this.blocked = true;
    document.onkeydown = (): boolean => {
      return false;
    }
  }

  unblockApplication(): void {
    $(".phx-parent-hidder").remove();
    $(".phx-tabs-hidder").remove();
    $(".phx-panelB1-hidder").remove();
    $(".phx-panelB2-hidder").remove();
    this.blocked = false;
    document.onkeydown = (): boolean => {
      return true;
    }
  }

  private _managePersonalization(uri: string): void {
    if (this._testUseCase(uri)) {
      $(".phx-menus-transversal-personnaliser").show();
    } else {
      $(".phx-menus-transversal-personnaliser").hide();
    }
  }

  private _testUseCase(uri: string): boolean {
    const zone = this.workflow.get("zone");

    if (uri === "" && zone === "coll" && CWHABILITATION.canView("PER_BRACC_C.V")) {
      return true;
    }
    if (uri === "z/coll" && CWHABILITATION.canView("PER_BRACC_C.V")) {
      return true;
    }
    if (uri === "z/resp" && CWHABILITATION.canView("PER_BRACC_R.V")) {
      return true;
    }
    if (uri === "z/coll/uc/agenda" || uri === "z/coll/uc/tabservind" || uri === "z/coll/uc/tabservcoll" || uri === "z/coll/uc/tabgardes" || uri === "z/coll/uc/plequipe") {
      return true;
    }
    if (uri === "z/resp/uc/agenda_R" || uri.startsWith("z/resp/uc/agenda_R")) {
      return true;
    }
    if (uri === "z/resp/pl/planresp") {
      return true;
    }
    if (uri === "z/resp/uc/planmed") {
      return true;
    }
    if (uri.indexOf("z/resp/pl/planact") >= 0) {
      return true;
    }
    return false;
  }
}
