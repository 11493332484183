import _ from 'underscore';
import { CWCaracteristiquesFormModel } from 'core/components/caracteristiques/cwCaracteristiquesForm.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

/**
 * Lister Gerer Activites Model
 */
export class CWGererActivitesModel extends CWCaracteristiquesFormModel {

  modeDeclarer: boolean;
  matricule: boolean;
  action: string;
  avecbesoin: string | number | boolean;
  deplacementMode: boolean;
  creation: boolean;


  defaults(): { [key: string]: any } {
    return {
      "code": "", // "AAAGoLAAGAAACCjAA2"
      "activite": "", // "ACT1"
      "libelle": "", // "Activité 1"
      "matricule": null, // 9001
      "complement": "",
      "typologie": {
        "libelle": null,
        "code": ""
      },
      "typeevenement": "P", // "R" Realisee | "P" Prevue
      "typesaisie": "D", // "D" : Date | "P" : Période Continuos | "R" : Repetitition
      "modesaisie": "PLH", // "PLH"
      "modesai_periodeb": { //unité de début pour type période
        "code": "",
        "libelle": ""
      },
      "modesai_periofin": { //unité de fin pour type période
        "code": "",
        "libelle": ""
      },
      "datedeb": "", // "20141114"
      "datefin": "", // "20141114"
      "unitedeb": {
        "libelle": "",
        "code": ""
      },
      "unitefin": {
        "libelle": "",
        "code": ""
      },
      "heuredeb": 0, // 800
      "heurefin": 0, // 1000
      "duree": 0, // 200
      "dureecalculee": 0,
      "pourcentage": 0,
      "carpers": [],
      "profils": {
        "code": "",
        "libelle": ""
      },
      "statut": {
        "code": "",
        "libelle": ""
      },
      "iddemande": {
        "code": "",
        "libelle": ""
      },
      "iswkfactive": "",
      "indicateurpj": null, // true
      "droit": null,
      "evenement": "",
      "recapitulatif": {
        "code": "", // "RECAP1"
        "libelle": "", // "Récapitulatif 1"
        "datedeb": "", // "20140101"
        "datefin": "", // "20141231"
        "etat": ""
      },
      "hierid": "",
      "straid": "",
      "structid": "",
      "horspresav": false,
      "horspresap": false,
      "evenement_origine": "",
      "typehorspresence": "",
      "presence": false,
      "horspresence": false,
      "origine": {
        "code": "",
        "libelle": ""
      },
      "addhoraireauto": false,
      "indication": "",
      "gentrt": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.MODEGROUPE = false;
    this.modeDeclarer = false;
    if (options && !CWSTR.isBlank(options.matricule)) {
      this.matricule = true;
    }
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
    this.context = (options && !CWSTR.isBlank(options.context)) ? options.context : {};
    this.deplacementMode = false;
    this.creation = false;
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);
      const avecbesoin = this.isNew() === true ? "" : encodeURIComponent(this.avecbesoin);
      let autresParams = "";

      if (this.modeDeclarer) {
        return Configuration.restRoot + "/rest/composant/activite/transformation/" + id;
      } else {
        if (this.action === "saisiegroupee") {
          return Configuration.restRoot + "/rest/composant/activite/" + this.action;
        }
        autresParams = ((avecbesoin && !CWSTR.isUndefined(avecbesoin)) ? "?avecbesoin=" + avecbesoin : "");
        if (this.context.ctxEcran === "planmed" && !CWSTR.isBlank(this.context.ctxBesActId)) {
          autresParams += (CWSTR.isBlank(autresParams) ? "?" : "&") + "medical=true"; //il faut ajouter le paramètre "medical=true" aux activités avec besoins du planning médical.
        }
        return Configuration.restRoot + "/rest/composant/activite/" + this.action + "/" + id + autresParams;
      }
    }
    if (!_.isBoolean(this.context.ctxDeplacement) || this.context.ctxDeplacement === false) {
      this.infoCompAttributeName = "carpers";
    } else {
      this.infoCompAttributeName = "";//il n'y a pas pour les déplacements
    }
  }


  validate(attrs: { [key: string]: any }, options?: { [key: string]: any }): { [key: string]: any } {
    const errors: { [Key: string]: any } = {};
    let errorMsg;
    const typologie = GLOBAL_DATA.typologies.get(attrs.typologie.code);
    const oldAttributes = this.oldAttributes ? JSON.parse(this.oldAttributes) : {};
    let datedeb: {
      "name": string;
      "value": string;
      "label": string;
    };
    let datefin: {
      "name": string;
      "value": string;
      "label": string;
      "isInfinity": boolean;
    };
    let onglet: string = null;

    // Activite
    if (CWSTR.isBlank(attrs.activite)) {
      let act = "";

      if (!CWSTR.isBlank(typologie)) {
        const evenement = typologie.get("evenement");

        //activite prevue
        if (attrs.typeevenement === "P") {
          if (typologie.get("genre") === "M") {
            act = i18n.t('common:gereractivites.actprevM', { "0": evenement });
          } else {
            act = i18n.t('common:gereractivites.actprevF', { "0": evenement });
          }
          errors.actprev = i18n.t('common:required', { "0": act });
          //activite realise
        } else if (attrs.typeevenement === "R") {
          if (typologie.get("genre") === "M") {
            act = i18n.t('common:gereractivites.actrealM', { "0": evenement });
          } else {
            act = i18n.t('common:gereractivites.actrealF', { "0": evenement });
          }
          errors.actreal = i18n.t('common:required', { "0": act });
        }
      }
    }
    if (this.matricule && CWSTR.isBlank(attrs.matricule)) {
      errors.matricule = i18n.t('common:required', { "0": i18n.t('common:gereractivites.collab') });
    }
    //TypeSaisie
    //Check unites selected in periode mode
    switch (attrs.typesaisie) {
      case "P":
        //Period validation
        datedeb = {
          "name": "datedeb",
          "value": attrs.dateDebP || attrs.datedeb,
          "label": i18n.t('common:gereractivites.datedebut')
        };
        datefin = {
          "name": "datefin",
          "value": attrs.dateFinP || attrs.datefin,
          "label": i18n.t('common:gereractivites.datefin'),
          "isInfinity": false
        };
        if (CWSTR.isBlank(datedeb.value) || CWSTR.isBlank(datefin.value)) {
          if (CWSTR.isBlank(datedeb.value)) {
            errors.dateDebP = i18n.t('common:required', { "0": i18n.t('common:gereractivites.datedebut') });
          } else {
            errorMsg = CWTYPE.DATE.validate(datedeb.value);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.dateDebP = errorMsg;
            }
          }
          if (CWSTR.isBlank(datefin.value)) {
            errors.dateFinP = i18n.t('common:required', { "0": i18n.t('common:gereractivites.datefin') });
          } else {
            errorMsg = CWTYPE.DATE.validate(datefin.value);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.dateFinP = errorMsg;
            }
          }
        } else {
          errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
          if (!CWSTR.isBlank(errorMsg)) {
            if (errorMsg.datedeb) {
              errors.dateDebP = errorMsg.datedeb;
            }
            if (errorMsg.datefin) {
              errors.dateFinP = errorMsg.datefin;
            }
          }
          //Evo #692 Repos obligatoire
          if (typologie && typologie.get('typeGestionActivite') === "REPOBLIG") {
            const dif = CWTYPE.DATE.getDiffInDays(String(datedeb.value), String(datefin.value));

            if (dif > 1) {
              errors.dateFinP = i18n.t('messages:GME_121');
            } else if (dif === 0) {
              errors.dateFinP = i18n.t('messages:GT_1781');
            }
            if (this.context.ctxModeInitialisation !== "Ajout") {
              //Check if old Date is in the new period
              if (oldAttributes.typesaisie === "D") {
                if (oldAttributes.datedeb !== attrs.datedeb && oldAttributes.datedeb !== attrs.datefin) {
                  errors.dateDebP = i18n.t('messages:GME_126');
                }
              }
              if (oldAttributes.typesaisie === "P") {
                const checkDateDeb = attrs.datedeb !== oldAttributes.datedeb && attrs.datedeb !== oldAttributes.datefin;
                const checkDateFin = attrs.datefin !== oldAttributes.datedeb && attrs.datefin !== oldAttributes.datefin;

                if (checkDateDeb && checkDateFin) {
                  errors.dateDebP = i18n.t('messages:GME_126');
                }
              }
            }
          }
        }
        if (CWSTR.isBlank(attrs.modesai_periodeb) || CWSTR.isBlank(attrs.modesai_periodeb.code)) {
          errors["modesai_periodeb"] = {};
          errors["modesai_periodeb"].code = i18n.t('common:required', { "0": i18n.t('common:gereractivites.modesai_periodeb') });
        } else if (attrs.modesai_periodeb.code === "H") {
          if (this.context.ctxSaisieMasse) {
            if (CWSTR.isBlank(attrs.heuredeb)) {
              errors.heuredeb = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heuredebut') });
            } else {
              errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heuredeb);
              if (!CWSTR.isBlank(errorMsg)) {
                errors.heuredeb = errorMsg;
              }
            }
          } else {
            if (CWSTR.isBlank(attrs.heuredeb)) {
              errors.heuredeb = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heuredebut') });
            } else {
              errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heuredeb);
              if (!CWSTR.isBlank(errorMsg)) {
                errors.heuredeb = errorMsg;
              }
            }
          }
        }
        if (CWSTR.isBlank(attrs.modesai_periofin) || CWSTR.isBlank(attrs.modesai_periofin.code)) {
          errors["modesai_periofin"] = {};
          errors["modesai_periofin"].code = i18n.t('common:required', { "0": i18n.t('common:gereractivites.modesai_periofin') });
        } else if (attrs.modesai_periofin.code === "H") {
          if (this.context.ctxSaisieMasse) {
            if (CWSTR.isBlank(attrs.heurefin)) {
              errors.heurefin = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heurefin') });
            } else {
              errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heurefin);
              if (!CWSTR.isBlank(errorMsg)) {
                errors.heurefin = errorMsg;
              }
            }
          } else {
            if (CWSTR.isBlank(attrs.heurefin)) {
              errors.heurefin = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heurefin') });
            } else {
              errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heurefin);
              if (!CWSTR.isBlank(errorMsg)) {
                errors.heurefin = errorMsg;
              }
            }
          }
        }
        break;
      case "D":
        if (CWSTR.isBlank(attrs.dateDebD) && CWSTR.isBlank(attrs.datedeb)) {
          if (attrs.dateDebD) {
            errors.dateDebD = i18n.t('common:required', { "0": i18n.t('common:gereractivites.datedebut') });
          } else {
            errors.datedeb = i18n.t('common:required', { "0": i18n.t('common:gereractivites.datedebut') });
          }
        } else {
          const dateDebD = attrs.dateDebD || attrs.datedeb;

          errorMsg = CWTYPE.DATE.validate(dateDebD);
          if (!CWSTR.isBlank(errorMsg)) {
            if (attrs.dateDebD) {
              errors.dateDebD = errorMsg;
            } else {
              errors.datedeb = errorMsg;
            }
          }
        }
        //Evo #692 Repos Obligatoire 
        if (typologie && typologie.get('typeGestionActivite') === "REPOBLIG" && this.context.ctxModeInitialisation !== "Ajout") {
          //Check if old Date is in the new period
          if (oldAttributes.typesaisie === "P") {
            if (oldAttributes.datedeb !== attrs.dateDebD && oldAttributes.datefin !== attrs.dateDebD) {
              errors.dateDebD = i18n.t('messages:GME_126');
            }
          }
        }
        if (attrs.modesaisie === 'PLH') {
          if (CWSTR.isBlank(attrs.heuredeb)) {
            errors.heuredeb = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heuredebut') });
          } else {
            errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heuredeb);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.heuredeb = errorMsg;
            }
          }
          if (CWSTR.isBlank(attrs.heurefin)) {
            errors.heurefin = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heurefin') });
          } else {
            errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heurefin);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.heurefin = errorMsg;
            }
          }
          if (CWSTR.isBlank(errors.heurefin) && CWSTR.isBlank(errors.heuredeb) && (attrs.heuredeb >= attrs.heurefin)) {
            errors.heurefin = i18n.t('messages:GL_1070');
          }
        }
        break;
      case "R":
        //Period validation
        datedeb = {
          "name": "datedeb",
          "value": attrs.dateDebR || attrs.datedeb,
          "label": i18n.t('common:gereractivites.datedebut')
        };
        datefin = {
          "name": "datefin",
          "value": attrs.dateFinR || attrs.datefin,
          "label": i18n.t('common:gereractivites.datefin'),
          "isInfinity": false
        };
        if (typologie && typologie.get('typeGestionActivite') === "REPOBLIG") {
          errors.dateDebR = i18n.t('messages:GME_119');
        }
        if (CWSTR.isBlank(datedeb.value) || CWSTR.isBlank(datefin.value)) {
          if (CWSTR.isBlank(datedeb.value)) {
            errors.dateDebR = i18n.t('common:required', { "0": i18n.t('common:gereractivites.datedebut') });
          }
          if (CWSTR.isBlank(datefin.value)) {
            errors.dateFinR = i18n.t('common:required', { "0": i18n.t('common:gereractivites.datefin') });
          }
        } else {
          errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
          if (!CWSTR.isBlank(errorMsg)) {
            if (errorMsg.datedeb) {
              errors.dateDebR = errorMsg.datedeb;
            }
            if (errorMsg.datefin) {
              errors.dateFinR = errorMsg.datefin;
            }
          }
        }
        break;
    }
    // ModeSaisie
    if (attrs.typesaisie !== "P") {
      switch (attrs.modesaisie) {
        case "PLH":
          if (CWSTR.isBlank(attrs.heuredeb)) {
            errors.heuredeb = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heuredebut') });
          } else {
            errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heuredeb);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.heuredeb = errorMsg;
            }
          }
          if (CWSTR.isBlank(attrs.heurefin)) {
            errors.heurefin = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heurefin') });
          } else {
            errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heurefin);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.heurefin = errorMsg;
            }
          }
          if (CWSTR.isBlank(errors.heurefin) && CWSTR.isBlank(errors.heuredeb) && (attrs.heuredeb >= attrs.heurefin)) {
            errors.heurefin = i18n.t('messages:GL_1070');
          }
          break;
        case "DUREE":
          if (CWSTR.isBlank(attrs.duree)) {
            errors.duree = i18n.t('common:required', { "0": i18n.t('common:gereractivites.duree') });
          } else {
            errorMsg = CWTYPE.DURATION.HOUR_MINUTE.validate(attrs.duree);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.duree = errorMsg;
            }
          }
          break;
        case "UNITE":
          if (CWSTR.isBlank(attrs.unitedeb.code)) {
            errors.unitedeb = {};
            errors.unitedeb.code = i18n.t('common:required', { "0": i18n.t('common:gereractivites.unite') });
          }
          break;
        case "POURCENT":
          if (CWSTR.isBlank(attrs.pourcentage)) {
            errors.pourcentage = i18n.t('common:required', { "0": i18n.t('common:gereractivites.tempeff') });
          } else {
            errorMsg = CWTYPE.CUSTOM.validate(attrs.pourcentage, 0, 100);
            if (!CWSTR.isBlank(errorMsg)) {
              errors.pourcentage = errorMsg;
            }
          }
          break;
        case "PERIOC":
          if (CWSTR.isBlank(attrs.heuredeb)) {
            errors.heuredeb = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heuredebut') });
          }
          if (CWSTR.isBlank(attrs.heurefin)) {
            errors.heurefin = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heurefin') });
          }
          break;
      }
    }
    //Jira 5998 by saisiemass screen
    onglet = !CWSTR.isBlank(this.habContext) && !CWSTR.isBlank(this.habContext.get("onglet")) ? this.habContext.get("onglet") : null;
    if (!CWSTR.isBlank(onglet) && _.isEqual("saisiemass", onglet)) {
      if (CWSTR.isBlank(attrs.matricule)) {
        errors.matricule = i18n.t('common:required', { "0": i18n.t('common:gereractivites.collab') });
      }
    }
    _.extend(errors, this._validateInfoComp(attrs));

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (this.MODEGROUPE === false) {
      if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
        response.id = response.code;
      }
    }
    if (response[0] && response[0].datedeb) {
      response.datedeb = response[0].datedeb;
    }
    if (response[0] && response[0].datefin) {
      response.datefin = response[0].datefin;
    }
    if (response.result) {
      return null;
    }
    return response;
  }
}
