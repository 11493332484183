import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Model for combo Motif
 */
export class CWListeMotifRegulModel extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
  }

  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "libelle": "0",
      "format": "",
      "valdef": null,
      "periode": null,
      "valautorisees": "",
      "indication": ""
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}