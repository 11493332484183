import { STR } from 'utils/str.js';

export var AutocompleteColl = Backbone.Collection.extend({

  /**
   * Name of the property to show in the label
   */
  label: "libelle",

  /**
   * Event launched when an element is clicked
   *
   */

  /**
   * Constructor
   * Autocompletion results collection
   */
  initialize: function(a_params) {
    /**
     * Render function of every collection element
     */
    this.itemRenderer = null; //This was outside the initialize
    var params = a_params || {};

    if (!STR.isBlank(params.label)) {
      /**
       * Label of the Collection
       */
      this.label = params.label;
    }
  },

  /**
   * Method to be executed when a response from a call to a webservice is received successfully
   */
  parse: function(response) {
    var data = [];
    var self = this;
    _.each(response, function(resp, index) {
      data[index] = {};
      data[index].label = self._renderer(resp);
      data[index].attrs = resp;
      if (!STR.isBlank(resp.id)) {
        data[index].id = resp.id;
      } else {
        data[index].id = resp.code;
      }
    }, this);

    return data;
  },

  /**
   * Method to be executed when a response from a call to a webservice is received successfully
   */
  _renderer: function(resp) {
    if (this.itemRenderer) {
      return this.itemRenderer(resp);
    } else {
      return resp[this.label];
    }
  }

});
