module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="c-filter-content c-form" role="search">\n    <div class="container-fluid">\n        <fieldset class="cw-fieldset">\n            <div class="row filter-simple col">\n                <div class="filter-simple-fields '+
((__t=(columnsFilter))==null?'':__t)+
'"></div>\n                <div class="c-button-filter '+
((__t=(columnsButtons))==null?'':__t)+
'">\n                    <span role="button" aria-label="'+
((__t=(i18n.t('common:morefilters')))==null?'':__t)+
'"  class="c-button-filter__more-filter" tabindex="0">'+
((__t=(i18n.t("common:morefilters")))==null?'':__t)+
'</span>\n                    <div class="d-flex filterButtonsContainer" '+
((__t=( (hideMainButtons ? "" : "style='display:block'") ))==null?'':__t)+
'>\n                        <button type="button" class="search btn btn-primary-bis btn-withIcon" title="'+
((__t=(i18n.t("common:search")))==null?'':__t)+
'" value="search">'+
((__t=(i18n.t("common:apply")))==null?'':__t)+
''+
((__t=(icon.search))==null?'':__t)+
'</button>\n                        <button type="button" class="clear btn btn-secondary-bis btn-onlyIcon" title="'+
((__t=(i18n.t("common:clear")))==null?'':__t)+
'" value="clear">'+
((__t=(icon.clear))==null?'':__t)+
'</button>\n                    </div>\n                </div>\n            </div>\n            <div class="filter-more">\n                <div class="filter-more-fields"></div>\n            </div>\n            <div class="filter-bottom form-row">\n                <div class="form-group customFilterButtonsContainer col d-flex flex-row" />\n            </div>\n        </fieldset>\n    </div>\n</div>';
}
return __p;
};
