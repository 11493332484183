import _ from 'underscore';
import { CWCompteursTableModel } from './cwCompteursTable.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';

/**
 * Collection of Table Compteurs
 */
export class CWCompteursTableColl extends CWPaginatedCollection<CWCompteursTableModel> {

  userid: string;
  codebrique: string;

  constructor(models?: Array<CWCompteursTableModel> | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWCompteursTableModel;
    options.idnames = ["rowid"];
    super(models, options);
    //Default values from class
    this.url = (): string => {
      const url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.userid + "," + this.codebrique) + "/detail/";

      return url;
    }
  }
}