import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';

export class CWPaginationComponentModel extends CWReadOnlyModel {

  public actualPage: number;
  public lastPage: number;
  public itemsPerPage: number;
  public totalItems: number;


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options)
    this.actualPage = 0;
    this.lastPage = 0;
    this.itemsPerPage = 0;
    this.totalItems = 0;
  }
}