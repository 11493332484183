module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="cw-fieldset gererrecapitulatifs-commentPopup">\n    <!-- Empty div //RGAA - needed to show "(*) : champ obligatoire"  -->\n    <div></div>\n    <div class="form-row">\n        <div class="col m-0">\n            <div class="phx-gererrecapitulatifs-data">\n                <div class="form-group">\n                    <span class="title"></span>\n                </div>\n                <div class="form-group recap-commentaire">\n                    <label for="commentaire_infocomp" class="commentLabelStyle" title="'+
((__t=(i18n.t('common:gererrecapitulatifs.commentaire')))==null?'':__t)+
'">'+
((__t=(i18n.t('common:gererrecapitulatifs.commentaire')))==null?'':__t)+
'</label>\n                    <textarea class="commentaire_infocomp form-control" rows="6" cols="40" maxlength="200" style="resize:none;"></textarea>\n                </div>\n                <div class="form-group">\n                    <span class="infocomp"></span>\n                </div>\n                <div class="form-group">\n                    <span class="recap-piecesjointes form-group"></span>\n                    <span class="histo"></span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="form-row">\n        <span class="cwDialog-buttons">\n            <button type="button" class="btnValider btn btn-primary btn-withIcon" value="btnValider">'+
((__t=(i18n.t('common:save')))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('valider','',16)))==null?'':__t)+
'</button>\n            <button type="button" class="btnRevert btn btn-secondary btn-withIcon" value="btnRevert">'+
((__t=(i18n.t('common:revert')))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon("croix",'',16)))==null?'':__t)+
'</button>\n        </span>\n    </div>\n</fieldset>';
}
return __p;
};
