import _ from 'underscore';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Model for Composant 'Soldes d’absences en D/P/S' config
 */
export class CWSoldesAbsencesConfigModel extends CWFilterBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "date": "",
      "demande": null,
      "souhait": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/soldeabs/config";
    };
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    let validateError: any = null;

    validateError = CWTYPE.DATE.validate(attrs.date, null, null);
    if (!CWSTR.isBlank(validateError)) {
      errors.date = validateError;
    }

    if (CWSTR.isBlank(attrs.date)) {
      errors.date = i18n.t('common:required', { "0": i18n.t('common:soldesabsences.date_title') });
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}