import { BaseCollection } from 'core/models/base.collection.js';
import { STR } from 'utils/str.js';
import { SelecteurCheminNivModel } from './selecteurCheminNiv.model.js';
import { objs } from 'src/objectsRepository';

export var SelecteurCheminNivColl = BaseCollection.extend({
  /**
   * Add this to have Outline
   *
   */

  model: SelecteurCheminNivModel,

  /**
   * Constructor
   * Selecteur Chemin Niveau Collection
   */
  initialize: function(params) {
    this.code = "";
    if (params && params.code) {
      this.code = params.code;
    }
  },

  /**
   * Url for this collection
   */
  url: function() {
    var urlFin = Configuration.restRoot + "/rest/cheminstructure/" + this.code + "/niveau";

    return urlFin;
  },

  fetch: function(options) {
    options = options || {};
    this.usecase = objs.appRt.workflow.get("usecase");
    if (!this.habContext && !this.habilitationV && !this.habilitationG) {
      this.habilitationV = "N";
      this.habilitationG = "N";
    }
    if (!STR.isBlank(this.ecran)) {
      this.usecase = this.ecran;
    }
    return BaseCollection.prototype.fetch.call(this, options);
  }
});
