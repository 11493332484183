import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

export class CWThemeRollConfigModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "preferences": [{
        "varchar5": "",
        "varchar3": "",
        "varchar4": "",
        "varchar1": "", //Theme name
        "code": "AP_THEME",
        "varchar2": "",
        "varint3": null,
        "varint2": null,
        "varint1": null,
        "varint5": null,
        "varint4": null,
        "vardat5": "",
        "vardat4": "",
        "vardat3": "",
        "vardat2": "",
        "vardat1": ""
      }]
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "N";
    this.habilitationG = "PER_PERSOGEN.M";
    this.usecase = "themeroll";
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/application/preferences";
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}