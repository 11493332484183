import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Parametrage Model
 */
export class CWParametrageModel extends CWBaseModel {

  formerID: string;
  action: string;
  espaceid: string;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "userid": "",
      "codebrique": "",
      "libelle": "",
      "typebrique": "",
      "visibilite": false,
      "datereference": "J",
      "typegroupe": "BR_CPT",
      "active": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);
      let url = "";

      if (!CWSTR.isBlank(this.formerID)) {
        url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.formerID);
      } else {
        url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + id;
      }
      if (!CWSTR.isBlank(this.action) && this.action === "GET" && !CWSTR.isBlank(this.espaceid)) {
        url += "?espaceid=" + encodeURIComponent(this.espaceid);
      }
      return url;
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: any = {};

    if (CWSTR.isBlank(attrs.codebrique)) {
      errors.codebrique = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.code_label') });
    } else {
      if (attrs.codebrique.indexOf("\\") !== -1) {
        errors.codebrique = i18n.t('common:incorrect', { "0": i18n.t('common:parametragebresultats.code_label') });
      } else {
        if (attrs.codebrique.indexOf("/") === 0) {
          errors.codebrique = i18n.t('common:incorrect', { "0": i18n.t('common:parametragebresultats.code_label') });
        }
      }
    }
    if (CWSTR.isBlank(attrs.libelle)) {
      errors.libelle = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.titre_label') });
    }
    if (CWSTR.isBlank(attrs.datereference)) {
      errors.datereference = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.date_label') });
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.userid && response.codebrique) {
      response.id = response.userid + "," + response.codebrique;
    }
    return response;
  }
}