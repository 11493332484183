import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

/**
 * Model for the complement combos
 */
export class CWComplementComboModel extends CWBaseModel {
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": ""
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    const result: { id?: string; code?: number; libelle?: string } = {};
    if (!CWSTR.isBlank(response.code)) {
      result.id = response.code;
      result.code = response.code;
    }
    if (response.code === 0) {
      result.id = "0";
      result.code = 0;
    }
    if (!CWSTR.isBlank(response.libellecourt)) {
      result.libelle = response.libellecourt;
    }

    return result;
  }

  validate(): { [key: string]: any } {
    const errors = {};

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}
