import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererrecapitulatifsCommentPopup from '../cwCommentPopup.tpl.html';
import { CWAccepterRecap } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwAccepterRecap';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWModifierRecap } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwModifierRecap';
import { CWRefuserRecap } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwRefuserRecap';
import { CWSoumettreRecap } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwSoumettreRecap';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { ResultControlesView } from 'src/uc/common/resultcontroles/views/resultControles.view.js';
import { UTILS } from 'src/utils/utils.js';


export class CWCommentPopupView extends CWBaseFormView {

  action: string;
  commentaire: string;
  parent: any;
  reeval: boolean;
  template: any;
  title: string;
  titleControl: string;
  workflow: any;
  public $appendTo: JQuery; //pour les messages

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "click button": "_clickListener"
    });
    super(options);
    this.template = TPLGererrecapitulatifsCommentPopup;
    this.model = new CWBaseModel({
      value: null
    });
    this.workflow = options.workflow;
    this.$appendTo = (this.workflow && this.workflow.context && !CWSTR.isBlank(this.workflow.context.ctxEcran)) ? $("#" + this.workflow.context.ctxEcran) : null;
    this.title = "";
    this.reeval = false;
    if (options.action && !CWSTR.isBlank(this.workflow.headerModel.get("value"))) {
      this.action = options.action;
      const id = this.workflow.headerModel.get("value").get("code");
      let model = null;
      let headerModel = null;
      const hab = this.workflow.habG.clone();

      switch (this.action) {
        case "accepter":
          //Reeval to true in order to repaint header after validation is done (and obtain new profiles,because valider buttons is shown depending on profiles)
          this.reeval = true;
          this.model.set("value", new CWAccepterRecap());
          model = this.model.get("value");
          model.action = this.action;
          headerModel = this.workflow.headerModel.get("value");
          model.id = id;
          model.set("commentaire", headerModel.get("commentaire"));
          model.set("droit", headerModel.get("droit"));
          model.set("profil", options.profil);
          model.setHabContext(new CWHabilitationContext({
            onglet: this.workflow.context.ctxEcran,
            foncCour: "RES_VAL_RECAP.G",
            natGest: ""
          }));
          this.titleControl = i18n.t('common:gererrecapitulatifs.controle_accepter');
          break;
        case "refuser":
          //Reeval to true in order to repaint header after validation is done (and obtain new profiles,because valider buttons is shown depending on profiles)
          this.reeval = true;
          this.model.set("value", new CWRefuserRecap());
          model = this.model.get("value");
          model.action = this.action;
          headerModel = this.workflow.headerModel.get("value");
          model.id = id;
          model.set("commentaire", headerModel.get("commentaire"));
          model.set("droit", headerModel.get("droit"));
          model.set("profil", options.profil);
          model.setHabContext(new CWHabilitationContext({
            onglet: this.workflow.context.ctxEcran,
            foncCour: "RES_VAL_RECAP.G",
            natGest: ""
          }));
          this.titleControl = i18n.t('common:gererrecapitulatifs.controle_refuser');
          break;
        case "soumettre":
          this.reeval = true;
          this.model.set("value", new CWSoumettreRecap());
          model = this.model.get("value");
          model.action = this.action;
          model.id = id;
          hab.set("natGest", "");
          model.setHabContext(hab);
          this.titleControl = i18n.t('common:gererrecapitulatifs.controle_soumettre');
          break;
        case "modifier":
          this.reeval = true;
          this.model.set("value", new CWModifierRecap());
          model = this.model.get("value");
          model.action = this.action;
          model.id = id;
          model.setHabContext(this.workflow.habG);
          this.titleControl = i18n.t('common:gererrecapitulatifs.controle_modifier');
      }
    } else {
      this.model.set("value", null);
    }
    if (!CWSTR.isBlank(this.model.get("value"))) {
      this.model.get("value").on("change:commentaire", this._mapToForm, this);
    }
    this.commentaire = CWSTR.isBlank(options.commentaire) ? "" : options.commentaire;
    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }
  }

  _panelStateIsReadonly(mod: any): boolean {
    let isReadOnly = false;
    if (mod) {
      const canValidate = CWHABILITATION.canView("RES_VAL_RECAP.G");
      isReadOnly = !canValidate;
    }
    return isReadOnly;
  }

  render(): CWCommentPopupView {
    const json = { "i18n": i18n, "UTILS": UTILS };
    this.$el.append(this.template(json));

    // Render buttons
    //$(this.el).find(".btnOk").button();
    //$(this.el).find(".btnAnnuler").button();

    //Set title
    switch (this.action) {
      case "accepter":
        $(this.el).find(".phx-gererrecapitulatifs-data span.title").html(i18n.t('common:gererrecapitulatifs.titleAccepter'));
        break;
      case "refuser":
        $(this.el).find(".phx-gererrecapitulatifs-data span.title").html(i18n.t('common:gererrecapitulatifs.titleRefuser'));
        //Make the comment mandatory if is for refuse
        $(this.el).find(".phx-gererrecapitulatifs-data label[for=commentaire], .phx-gererrecapitulatifs-data label[for^=commentaire_uid_]").addClass("cw-required");
        break;
      case "soumettre":
        $(this.el).find(".phx-gererrecapitulatifs-data span.title").html(i18n.t('common:gererrecapitulatifs.titleSoumettre'));
        break;
      case "modifier":
        $(this.el).find(".phx-gererrecapitulatifs-data span.title").html(i18n.t('common:gererrecapitulatifs.titleModifier'));
        break;
    }

    //Initialize the comment textarea with the previous comment.
    CWSTR.setElValue(this.model.get("value"), "commentaire", this.commentaire);

    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }

    return this;
  }

  _change(event: JQueryEventObject): void {
    CWBaseFormView.prototype._change.call(this, event);

    // Clear current validation erros
    this._cleanValidationErrors();
  }

  _mapToForm(): void {
    const model = this.model.get("value");
    const fieldset = this.$el.find("fieldset");
    this._mapModelToForm(fieldset, model, null);
  }

  _clickListener(event: JQueryEventObject): void {
    const btnClicked = $(event.currentTarget).val();
    const model = this.model.get("value");

    switch (btnClicked) {
      case "btnOk":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);

        if (model.isValid()) {
          if (this.action === "modifier") {
            model.unset("commentaire");
          }
          model.save(null, {
            success: () => {
              this.parent.model.set("reeval", this.reeval);
              this.parent.close();
              this.workflow.trigger("recapUpdated");
            },
            error: (model: any, xhr: any) => {
              if (xhr.status === 410) {
                const periodeControle = {
                  datedeb: this.workflow.headerModel.get("value").get("datedeb"),
                  datefin: this.workflow.headerModel.get("value").get("datefin")
                }

                const context = {
                  ctxUtilisateur: this.workflow.context.ctxUtilisateur,
                  ctxUtilisation: "Traitement",
                  ctxModePopup: "Modale",
                  ctxGestionCollab: this.workflow.context.ctxCollab,
                  ctxPeriodeControles: periodeControle,
                  ctxTitrePopup: this.titleControl,
                  ctxIdentifiantRecap: this.workflow.headerModel.get("value").get("code"),
                  ctxEcran: this.workflow.context.ctxEcran
                };
                const controlePopUp = new ResultControlesView({
                  context: context,
                  habilitations: this.model.get("value").getHabContext(),
                  messagesModel: new CWBaseModel(xhr.responseJSON),
                  originalModel: model,
                  actionModel: "save"
                });

                controlePopUp.open({
                  success: () => {
                    this.parent.model.set("reeval", this.reeval);
                    this.parent.close();
                    this.workflow.trigger("recapUpdated");
                  },
                  error: () => {
                    this.parent.close();
                  }
                });
              }
            }
          });
        }
        break;
      case "btnAnnuler":
        CWSTR.setElValue(model, "commentaire", this.commentaire);
        this.parent.close();
        break;
      default:
    }
  }
}
