import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

export class CWHistoRecapitulatifsAndActivitesModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "type": null,
      "evenement": null,
      "iddemande": null
    }
  }
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options)
    this.url = (): string => { return Configuration.restRoot + "/rest/wkf/histo/evenement" };
  }
}
