import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';
import { CWWkfHistoModel } from './cwWkfHisto.model';


export class CWHistoEnsembleModelColl<TModel extends CWWkfHistoModel = CWWkfHistoModel> extends CWPaginatedCollection<TModel> {

  public ctxTypeEvenement: string;
  public evenements: string;

  constructor(models?: TModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    super(models, options);
    this.paginated = false;
    this.url = (): string => {
      let newUrl = Configuration.restRoot + "/rest/wkf/histo/ensemble/" + encodeURIComponent(this.ctxTypeEvenement);
      if (!CWSTR.isBlank(this.evenements)) {
        newUrl += "?evenements=" + this.evenements;
      }
      return newUrl;
    }
  }
}