import * as Backbone from 'Backbone';
import _ from 'underscore';
import CWButtonBarTPL from './cwButtonBar.tpl.html';
import { CWBaseModel } from '../models/cwBase.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

interface CWButtonBarViewInterface extends Backbone.ViewOptions<CWBaseModel> {
  iconsOnly?: boolean;
  ignoreHabilitationsNature?: boolean;
  focusToFormSaveElement?: JQuery;
  btnClasses?: { [key: string]: string };
  btnIcons?: { [key: string]: string };
  isDisplayOnRight?: boolean;
  isAlignToRight?: boolean;
  isHideOnDisabled?: boolean;
  detached?: boolean;
  btnOrder?: { [key: string]: number };
}

export type CWButtonBarMenu = Array<{
  name: string;
  label: string;
  className?: string;
}>;

/**
* Button bar view.
* It's model supports:
* @event change:mode
* @event btn:click (buttonId)
* @event change:enabled (buttonId)
* @event hide Detach the button bar from the Dom
* @event show Re-atach th button bar to the Dom
* @event hide:(buttonId)
* @event show:(buttonId)
* @event enable:(buttonId)
* @event disable:(buttonId)
*/
export class CWButtonBarView extends Backbone.View<Backbone.Model> {

  protected focusToFormSaveElement: JQuery;
  public template: (params?: any) => string;
  public droits: { [key: string]: boolean };
  protected visible: { [key: string]: boolean };
  protected iconsOnly: boolean;
  protected isEnabled: boolean;
  protected detached: boolean;
  protected buttonsTitle: { [key: string]: string };
  public btnIcons: { [key: string]: string };
  public btnClasses: { [key: string]: string };
  protected dblclick: number;
  protected ignoreHabilitationsNature: boolean;
  public model: CWBaseModel;
  protected detachedBtns: { [key: string]: { position: JQuery; name: string; bindedFunction: any; isDetached: boolean } };
  protected isDisplayOnRight: boolean;
  protected isAlignToRight: boolean;
  protected isHideOnDisabled: boolean;
  protected right: number;
  /**
   * Prevent lost event and attached buttons when is rendered again
   */
  public isRendered: boolean;
  protected deAtachParent: Array<JQuery>;
  /**
   * Array of buttons painted outside of the component container
   */
  protected distributedButtons: Array<JQuery>;
  protected btnOrder: { [key: string]: number };
  protected customBtns: { [key: string]: JQuery };
  protected customBtnsPositions: { [key: string]: any };

  /**
   * Constructor
  */
  constructor(options?: CWButtonBarViewInterface) {
    options = options || {};
    options.tagName = options.tagName || "span";
    options.className = options.className || "cw-buttonBar";
    options.events = _.extend({
      "click button": "_clickListener",
      "dblclick button": "_clickListener"
    }, options.events);
    super(options);
    this.btnClasses = options.btnClasses || {};
    this.btnClasses["new"] = options.btnClasses && options.btnClasses["new"] ? options.btnClasses["new"] : "btn-primary";
    this.btnClasses["copy"] = options.btnClasses && options.btnClasses["copy"] ? options.btnClasses["copy"] : "btn-secondary";
    this.btnClasses["delete"] = options.btnClasses && options.btnClasses["delete"] ? options.btnClasses["delete"] : "btn-primary-alert";
    this.btnClasses["save"] = options.btnClasses && options.btnClasses["save"] ? options.btnClasses["save"] : "btn-primary";
    this.btnClasses["revert"] = options.btnClasses && options.btnClasses["revert"] ? options.btnClasses["revert"] : "btn-secondary";
    this.btnIcons = {};
    this.btnIcons["new"] = options.btnIcons && options.btnIcons["new"] !== undefined ? options.btnIcons["new"] : "plus";
    this.btnIcons["copy"] = options.btnIcons && options.btnIcons["copy"] !== undefined ? options.btnIcons["copy"] : "dupliquer";
    this.btnIcons["delete"] = options.btnIcons && options.btnIcons["delete"] !== undefined ? options.btnIcons["delete"] : "poubelle";
    this.btnIcons["save"] = options.btnIcons && options.btnIcons["save"] !== undefined ? options.btnIcons["save"] : "valider";
    this.btnIcons["revert"] = options.btnIcons && options.btnIcons["revert"] !== undefined ? options.btnIcons["revert"] : "croix";
    this.detachedBtns = {};
    this.btnOrder = options.btnOrder || {};
    this.customBtnsPositions = {};
    this.template = CWButtonBarTPL;
    //For simple models it's not needed to create a specific model
    this.model = new CWBaseModel({
      mode: "C",
      enabled: true
    });
    this.model.on("change:mode", this._manageMode, this);
    this.model.on("change:enabled", this._manageEnabled, this);
    /**
     * When set to true, no icons text is shown for this button
     */
    this.iconsOnly = false;
    if (options && options.iconsOnly) {
      if (!_.contains([true, false], options.iconsOnly)) {
        throw new Error("Property iconsOnly can have only these values: (true / false)");
      }
      this.iconsOnly = options.iconsOnly;
    }
    this.ignoreHabilitationsNature = false;
    if (options && options.ignoreHabilitationsNature) {
      this.ignoreHabilitationsNature = options.ignoreHabilitationsNature;
    }
    if (options && options.tagName) {
      this.tagName = options.tagName;
    }
    this.detached = false;
    if (options && options.detached) {
      this.detached = options.detached;
    }
    this.isDisplayOnRight = false;
    if (options && options.isDisplayOnRight) {
      this.isDisplayOnRight = options.isDisplayOnRight;
    }
    this.isAlignToRight = false;
    if (options && options.isAlignToRight) {
      this.isAlignToRight = options.isAlignToRight;
    }
    this.isHideOnDisabled = true; //cas particulier UX: cacher les boutons au lieu de désactiver
    if (options && _.isBoolean(options.isHideOnDisabled)) {
      this.isHideOnDisabled = options.isHideOnDisabled;
    }
    /**
     * Wether all buttons in this view are enabled or not
     */
    this.isEnabled = true;
    /**
     * This object has a boolean property for each button that indicates if user has droits over this button
     */
    this.droits = {};
    this.droits["new"] = true;
    this.droits["copy"] = true;
    this.droits["delete"] = true;
    this.droits["save"] = true;
    this.droits["revert"] = true;
    /**
     * This object has a boolean property for each button that indicates if it is shown or not
     */
    this.visible = {};
    this.visible["new"] = true;
    this.visible["copy"] = true;
    this.visible["delete"] = true;
    this.visible["save"] = true;
    this.visible["revert"] = true;
    // Delegate the events row:* to the coll
    this.model.on("all", this.eventDefault, this);
    if (options && options.focusToFormSaveElement) {
      this.focusToFormSaveElement = options.focusToFormSaveElement;
    } else {
      this.focusToFormSaveElement = $(".focusToFormSave");
    }

    /**
     *  Object that represent all the titles of infobulle for each button
     *  e.g. this.buttonsTitle = { "revert": i18n.t('common:revert') };
     */

    this.dblclick = 0;
    this.deAtachParent = new Array<JQuery>();
    this.distributedButtons = new Array<JQuery>();
    this.customBtns = {};
    this.isRendered = false;
  }

  protected eventDefault(eventName: string): void {
    if (_.indexOf(eventName, ":") > 0) {
      const eventParts = eventName.split(":");
      const event = eventParts[0];
      const className = eventParts[1];

      switch (event) {
        case "hide":
          this.hideButton(className);
          break;
        case "show":
          this.showButton(className);
          break;
        case "enable":
          this.enableButton(className);
          break;
        case "disable":
          this.disableButton(className);
          break;
        default:
          break;
      }
    } else {
      switch (eventName) {
        case "hide":
          this.manageVisibilityButtonBar(false);
          break;
        case "show":
          this.manageVisibilityButtonBar(true);
          break;
        default:
          break;
      }
    }
  }

  _showButton($btn: JQuery): void {
    $btn.removeClass("d-none");
  }

  _hideButton($btn: JQuery): void {
    $btn.addClass("d-none");
  }

  restoreDetachedButtons(): void {
    const $detachedBtn = this.$el.find('.btnBar-btnDetached');

    this._showButton($detachedBtn);
    $detachedBtn.removeClass('.btnBar-btnDetached');
  }

  detachButton(btnName: string, btnRef: JQuery): void {
    if (this.detachedBtns[btnName]) {
      this.model.off('all', this.detachedBtns[btnName].bindedFunction);
    } else {
      this.detachedBtns[btnName] = {
        name: btnName,
        bindedFunction: null,
        isDetached: false,
        position: btnRef
      };
    }
    if (this.$el.find('.' + btnName).length > 0 && $(btnRef)) {
      this._cloneDetachedButton(btnName, btnRef);
      this._observeDetachedButton(btnName, btnRef);
      this.detachedBtns[btnName].bindedFunction = this._detachButtonCallback.bind(this, btnName, btnRef);
      this.model.on('all', this.detachedBtns[btnName].bindedFunction);
      this.detachedBtns[btnName].isDetached = true;
      this.detachedBtns[btnName].position = btnRef;
    }
    this._manageMode();
  }

  _detachButtonCallback(btnName: string, btnRef: JQuery, eventName: string): void {
    this.eventDefault(eventName);
    if (_.indexOf(eventName, ":") > 0) {
      const eventParts = eventName.split(":");
      const className = eventParts[1];

      if (className === btnName && (eventParts[0] !== "hide" && eventParts[0] !== "disable")) {
        const $clonedBtn = this.$el.find('.' + btnName).parent(".btnBar-btn-container").clone(true, true);

        $clonedBtn.removeClass('btnBar-btnDetached').addClass('btnBar-btnCloned');
        this.reconnectEvents($clonedBtn);
        $(btnRef).empty().append($clonedBtn);
      }
    }
  }

  _observeDetachedButton(btnName: string, btnRef: JQuery): void {
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(this._cloneDetachedButton.bind(this, btnName, btnRef));// Create an observer instance linked to the callback function

    // Start observing the target node for configured mutations
    observer.observe(this.$el.find('.' + btnName)[0], config);
    // Later, you can stop observing
    // observer.disconnect();
  }

  _cloneDetachedButton(btnName: string, btnRef: JQuery): void {
    const $clonedBtn = this.$el.find('.' + btnName).parent(".btnBar-btn-container").clone(true, true);

    $clonedBtn.removeClass('btnBar-btnDetached').addClass('btnBar-btnCloned');
    this.reconnectEvents($clonedBtn);
    $(btnRef).empty().append($clonedBtn);
    this.$el.find('.' + btnName).parent(".btnBar-btn-container").addClass("btnBar-btnDetached");
  }

  /**
   * Sets droits and manages enabling and disabling buttons depending on habilitation
   */
  habilitations(habilitations: string): void {
    if (!CWSTR.isBlank(this.habilitations)) {
      if (!CWHABILITATION.canCreate(habilitations)) {
        this.droits["new"] = false;
        this.droits["copy"] = false;
      }
      if (!CWHABILITATION.canDelete(habilitations)) {
        this.droits["delete"] = false;
      }
      if (!CWHABILITATION.canCreate(habilitations) && !CWHABILITATION.canUpdate(habilitations)) {
        this.droits["save"] = false;
        this.droits["revert"] = false;
      }
      this._manageMode();
    }
  }

  /**
   * Sets droits and manages enabling and disabling buttons depending on habilitation
   */
  manageHabContext(): void {
    if (!CWSTR.isBlank(this.model.getHabContext())) {
      if (this.ignoreHabilitationsNature) {
        if (!CWHABILITATION.canView(this.model.getHabContext().get("foncCour"))) {
          this.droits["new"] = false;
          this.droits["copy"] = false;
          this.droits["delete"] = false;
          this.droits["save"] = false;
          this.droits["revert"] = false;
        }
      } else {
        if (!CWHABILITATION.canCreate(this.model.getHabContext().get("foncCour"))) {
          this.droits["new"] = false;
          this.droits["copy"] = false;
        }
        if (!CWHABILITATION.canDelete(this.model.getHabContext().get("foncCour"))) {
          this.droits["delete"] = false;
        }
        if (!CWHABILITATION.canCreate(this.model.getHabContext().get("foncCour")) && !CWHABILITATION.canUpdate(this.model.getHabContext().get("foncCour"))) {
          this.droits["save"] = false;
          this.droits["revert"] = false;
        }
      }
    }
  }

  /**
   * Enables or disables the whole buttonbar when enable property has changed.
   */
  _manageEnabled(): void {
    this.enabled(this.model.get("enabled"));
  }

  /**
   * Enables or disables the whole buttonbar.
   */
  enabled(enable: boolean): void {
    this.model.set("enabled", enable, { silent: true });
    this.isEnabled = enable;
    if (this.isEnabled) {
      this.$el.removeClass("ui-state-disabled");
      $("button", this.el).css("cursor", "");
    } else {
      this.$el.addClass("ui-state-disabled");
      $("button", this.el).css("cursor", "default");
    }
  }

  /**
   * Hides a button by its name
   */
  hideButton(name: string): void {
    this.visible[name] = false;
    this.getButtonHTMLElement(name).parent(".btnBar-btn-container").addClass("d-none");
  }

  public hideAllBtnBar(): void {
    this.model.trigger("hide:save");
    this.model.trigger("hide:revert");
    this.model.trigger("hide:new");
    this.model.trigger("hide:nouveau");
    this.model.trigger("hide:copy");
    this.model.trigger("hide:delete");
    this.model.trigger("hide:transformer");
  }

  /**
   * Shows a button by its name
   */
  showButton(name: string): void {
    this.visible[name] = true;
    if (this.droits[name]) {
      this.getButtonHTMLElement(name).removeClass("d-none");
      this.getButtonHTMLElement(name).parent(".btnBar-btn-container").removeClass("d-none");
    }
  }

  /**
   * Enables a button by its name
   */
  enableButton(name: string): void {
    this.enabledForcedButton(name);
    if (this.isHideOnDisabled) {
      this.showButton(name);
    }
  }

  /**
   * Disables a button by its name
   */
  disableButton(name: string): void {
    this.disabledForcedButton(name);
    if (this.isHideOnDisabled) {
      this.hideButton(name);
    }
  }

  /**
   * Checks if mode is a valid mode. If it is not, an error is shown. Disables buttons if it is necessary.
   */
  _manageMode(): void {
    const newMode = this.model.get("mode");

    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in ButtonBar : " + newMode);
    }
    this._manageButtonsDisplay(newMode);
  }

  /**
   * Disables/Enables buttons depending on current button bar mode (R:consultation, E:Edition, C:Creation)
   */
  _manageButtonsDisplay(mode: string): void {
    let $button = null;

    this.manageHabContext();
    if (mode !== "E") {
      // Added to solve the problem that appears when disable a button dynamically
      // this button doesn't trigger the mouseleave event
      this.$el.find(".revert").trigger("mouseleave");
      // Disable focus order in forms. It returns to the normal order.
      this.focusToFormSaveElement.attr("tabindex", "-1");
    } else {
      // Enable focus order in forms. It allow to change the focus order using hidden element.
      this.focusToFormSaveElement.attr("tabindex", "0");
    }
    // R : consultation mode
    // E : edition mode
    // C : creation mode
    /*
    this.$el.find(".save").prop("disabled", mode !== "E");
    this.$el.find(".revert").prop("disabled", mode !== "E");
    this.$el.find(".new").prop("disabled", mode === "E");
    this.$el.find(".delete").prop("disabled", mode !== "R");
    this.$el.find(".copy").prop("disabled", mode !== "R");
    */
    switch (mode) {
      case 'C':
        this.hideButton('save'); // disabled if <> E
        this.hideButton('revert'); // disabled if <> E
        this.enableButton('new'); // enabled if <> E
        this.disableButton('delete'); // disabled if <> R
        this.disableButton('copy'); // disabled if <> R
        break;
      case 'E':
        this.showButton('save'); // enabled if == E
        this.showButton('revert'); // enabled if == E
        this.disableButton('new'); //disabled if == E
        this.disableButton('delete'); // disabled if <> R
        this.disableButton('copy'); // disabled if <> R
        break;
      case 'R':
        this.hideButton('save'); // disabled if <> E
        this.hideButton('revert'); // disabled if <> E
        this.enableButton('new'); // enabled if <> E
        this.enableButton('delete'); // enable if == R
        this.enableButton('copy'); // enable if == R
        break;
    }

    for (const button in this.droits) {
      if (button) {
        if (this.droits[button] === false) {
          $button = this.$el.find("." + button);
          if ($button.length > 0) {
            $button.addClass("d-none");
          }
        }
        if (this.visible[button] === true && this.droits[button] === true) {
          $button = this.$el.find("." + button);
          if ($button.length > 0) {
            $button.removeClass("d-none");
          }
        }
      }
    }
  }

  /**
   * When a button is clicked launches its management if it is enabled. The event must be listened and managed from uc
   */
  _clickListener(event: JQuery.TriggeredEvent): void {
    if (this.isEnabled) {
      const btnClicked = event.currentTarget.className.split(" ")[0];
      let target = event.currentTarget;
      let menuId: string = null;
      let $btn: JQuery = null;
      let $btnContainer: JQuery = null;
      let $menuContent: JQuery = null;
      let buttonWithMenu = false;

      if ($(target).hasClass("cw-icon")) {
        target = $(target).parent().get(0);
      }
      menuId = target.className.split(" ")[0].replace("-menu", "");
      $btn = this.getButtonHTMLElement(menuId);
      $btnContainer = $btn.parent();
      $menuContent = $("." + menuId + "-menu-content", $btnContainer);
      buttonWithMenu = $menuContent.length > 0;
      if (buttonWithMenu) {
        this._toggleMenu(event);
      } else {
        if (event.type === "click") {
          setTimeout((): boolean => {
            const double = this.dblclick;

            if (double > 0) {
              this.dblclick = 0;
              return false;
            } else {
              const lValue = (event.currentTarget as any).value;

              if (!CWSTR.isBlank(lValue) && lValue !== btnClicked) {//éviter des problèmes avec le "callback"
                this.model.trigger("btn:click", btnClicked, lValue);
              } else {
                this.model.trigger("btn:click", btnClicked);
              }
            }
            return true;
          }, 100);
        } else if (event.type === "dblclick") {
          this.dblclick = 2;
          event.stopPropagation();
        }
      }
    }
  }

  /**
   * When a custom button is clicked launches its management if it is enabled. The event must be listened and managed from uc
   * closeMenuID: string -> if is called from menu, close the menu
   */
  _customClickListener(event: JQuery.TriggeredEvent, closeMenuId?: string): void {
    let $element: JQuery = null;

    if ($(event.target).hasClass("customButton")) {
      $element = $(event.target);
    } else if ($(event.target).parent(".c-panneauMenu__item").hasClass("customButton")) {
      $element = $(event.target).parent(".c-panneauMenu__item");
    }
    if (this.isEnabled && $element && $element.length > 0 && !CWSTR.isBlank($element.attr("value"))) {
      const btnClicked = $element.attr("value");
      const classBtn = $element.attr("class").split(' ')[0];

      if ($element.attr("value") === classBtn) {
        this.model.trigger("btn:click", btnClicked);
      } else {
        this.model.trigger("btn:click", classBtn, btnClicked);
      }
      if (!CWSTR.isBlank(closeMenuId)) {
        const $btn = this.getButtonHTMLElement(closeMenuId);
        const $btnContainer = $btn.parent();

        $("." + closeMenuId + "-menu-content", $btnContainer).hide();
      }
    }
  }

  _menuKeyDownListener(event: JQuery.TriggeredEvent): void {
    if (event.keyCode === 13) {
      let target = event.currentTarget;
      let menuId = null;
      let $menuContent = null;

      event.preventDefault();
      event.stopPropagation();
      if ($(target).hasClass("cw-icon")) {
        target = $(target).parent().get(0);
      }
      menuId = target.className.split(" ")[0].replace("-menu", "");
      //cacher le menu s'il y a 
      $menuContent = $(event.currentTarget).parents("[class*='-menu-content']")
      if ($menuContent.length > 0) {
        const $lMenucontainer = ($menuContent.length === 1) ? $menuContent : $($menuContent[0]);

        $lMenucontainer.hide();
        $(".c-panneauMenu__item", $lMenucontainer).off("keydown");
      }
      if (!CWSTR.isBlank(menuId)) {
        this._customClickListener(event, menuId);
      }
    }
  }

  /**
   * Shows/Hides menu with many options
   */
  _toggleMenu(event: JQuery.TriggeredEvent): void {
    let buttonWithMenu = false;
    let iconTarget = false;

    if ($(event.currentTarget).hasClass("revert") === false) {
      let target = event.currentTarget;
      let menuId: string = null;

      iconTarget = $(target).hasClass("cw-icon") ? true : false;
      if (iconTarget) {
        target = $(target).parent().get(0);
      }
      menuId = target.className.split(" ")[0].replace("-menu", "");
      if (!CWSTR.isBlank(menuId)) {
        const $btn = this.getButtonHTMLElement(menuId);
        const $btnContainer = $btn.parent();
        const $menuContent = $("." + menuId + "-menu-content", $btnContainer);

        // If it has a menu then display the menu please.
        if ($menuContent.length > 0) {
          if (iconTarget) {
            event.stopPropagation();
          }
          buttonWithMenu = true;
          if ($menuContent.is(":visible")) {
            $menuContent.hide();
            $(".c-panneauMenu__item", $menuContent).off("keydown");
          } else {
            //RGAA - All this new changes were made for RGAA behaviour in buttons with menu
            const checkClick = (): void => {
              $(document).off("click." + this.cid + "_" + menuId);
              $(document).one("click." + this.cid + "_" + menuId, () => {
                const $btnContainer = $btn.parent();
                const $menuContent = $("." + menuId + "-menu-content", $btnContainer);
                if ($menuContent.length === 0) {
                  $menuContent.hide();
                  this.$el.find(".ui-icon").removeClass("ui-icon-triangle-1-n");
                  this.$el.find(".ui-icon").addClass("ui-icon-triangle-1-s");
                }
              });
              $(document).off("mouseup." + this.cid + "_" + menuId);
              $(document).one("mouseup." + this.cid + "_" + menuId, (event: JQuery.TriggeredEvent): void => {
                const $btnContainer = $btn.parent();
                const $menuContent = $("." + menuId + "-menu-content", $btnContainer);
                if ($menuContent.length !== 0 && CWSTR.isBlank(this._customClickListener(event as any, menuId))) {
                  $menuContent.hide();
                  this.$el.find(".ui-icon").removeClass("ui-icon-triangle-1-n");
                  this.$el.find(".ui-icon").addClass("ui-icon-triangle-1-s");
                }
              });
            };
            $menuContent.show();
            $(".c-panneauMenu__item", $menuContent).off("keydown");
            $(".c-panneauMenu__item", $menuContent).on("keydown", (event: JQuery.TriggeredEvent) => this._menuKeyDownListener(event));
            //setPosition here
            this._manageMenuPosition(menuId);
            //If you click out of the menu, close the menu.
            checkClick();
          }
        }
      }
    }
    if (!buttonWithMenu) {
      this._customClickListener(event);
    }
  }
  /**
   * Check if position is on the page
   * If not change the position on top of the button.
   *
   * @param menuId
   */
  _manageMenuPosition(menuId: string): void {
    const $button = this.getButtonHTMLElement(menuId);
    const $btnContainer = $button.parent();
    const $menu = $("." + menuId + "-menu-content", $btnContainer);
    const screenSize = {
      width: $(window).width(),
      height: $(window).height()
    };

    if (!CWSTR.isBlank(this.customBtnsPositions[menuId])) {
      $menu.position(Object.assign({ of: $btnContainer }, this.customBtnsPositions[menuId]));
    } else {
      const menuPosition = $menu.position();

      if (this.isAlignToRight) {
        if (this.right === undefined) {
          this.right = screenSize.width - menuPosition.left - $("." + menuId).outerWidth();
          $menu.css({ right: this.right });
        }
      } else {
        if (menuPosition.left + $menu.outerWidth() > screenSize.width) {
          $menu.css({ left: screenSize.width - $menu.outerWidth() });
        }
      }
      // variable to help debugging the value of the menu and the button
      if ($menu.offset().top + $menu.outerHeight() > screenSize.height) {
        $menu.css({ top: $button.offset().top - $menu.outerHeight() });
      }
      if ($menu.offset().top < $button.offset().top && $menu.offset().top + $menu.outerHeight() > $button.offset().top + $button.outerHeight()) {
        $menu.css({ top: $button.offset().top - $menu.outerHeight() });
      }
    }
  }

  /**
   * Renders this view
   */
  render(): CWButtonBarView {
    if (!this.isRendered) {
      const json = { "i18n": i18n, CWSTR: CWSTR, "btnClasses": this.btnClasses, "btnIcons": this.btnIcons, UTILS: UTILS };

      this.$el.html(this.template(json));
      if (this.isDisplayOnRight) {
        this.$el.addClass('d-flex justify-content-end');
      }
    }
    if (!_.isEmpty(this.buttonsTitle)) {
      _.each(this.buttonsTitle, (title: string, button: string): void => {
        if (!CWSTR.isBlank(title)) {
          this.$el.find("." + button).parent().attr("title", title);
        }
      });
    }
    //Default mode
    this._manageButtonsDisplay("mode");
    //Check if we need to move the button
    if (!this.isRendered) {
      this.checkIsDefautButtonsAreDetached();
    }
    this.changeBtnDisplayOrder();
    this.isRendered = true;
    return this;
  }

  /**
   * Check if the user are call to detachButton before render
   */
  protected checkIsDefautButtonsAreDetached(): void {
    const defaultButtonsNames = ["save", "revert", "new", "delete", "copy"];

    defaultButtonsNames.forEach(name => {
      if (this.detachedBtns[name] && this.detachedBtns[name].isDetached === false) {
        this.detachButton(name, this.detachedBtns[name].position);
      }
    });
  }

  /**
   * Adds a new button to the button bar
   * @param {String} insertTo - "first" / "last" / The class of the element after which the button is inserted
   * @param {String} name - The name of the button
   * @param {String} label - The label text of the button that will be shown to the user
   * @param {String} icon - The icon shown for this button
   * @param {Boolean} showText - Wether to show text or not
   * @param {Boolean} habilitation - Wether if there are habilitations to show the button or not
   * @param {String} title - set title four infobulle
   * @param {Boolean} discret - Boolean, if set to true the button is added with style discret
   */
  addButtonAt(insertTo: string, name: string, label?: string, icon?: string, showText?: boolean, habilitation?: boolean,
    title?: string, hasMenu?: any, menuOptions?: any, discret?: boolean, ariaLabel?: string): void {
    if (this.customBtns[name] === undefined) { //Check is arlready added
      const $groupButton = $("<div>");
      let menuIcon: string = null;
      let menu: JQuery = null;
      const classes = this.btnClasses && this.btnClasses[name] ? this.btnClasses[name] : "btn-primary";
      let $button = $("<button type='button' class='" + name + " btn " + classes + "' value='" + name + "'>" + label + "</button>");
      let lshowText = showText;

      if (!CWSTR.isBlank(hasMenu) && hasMenu === true) {
        menuIcon = UTILS.getSVGIconButton('fleche_bas', name + '-menu cw-icon-text', 16, i18n.t("common:ARIA_label.open_menu"));
        $button = $("<button type='button' class='" + name + " btn " + classes + "'><span class='customButton' value='" + name + "' >" + label + "</span>" + menuIcon + "</button>");
      }
      if ((icon && showText !== false) || hasMenu === true) {
        $button.addClass("btn-withIcon");
      } else if (icon && showText === false) {
        $button.addClass("btn-onlyIcon");
      }
      if (!_.isBoolean(lshowText)) {
        lshowText = true;
      }
      lshowText = (!this.iconsOnly && lshowText);
      if (!CWSTR.isBlank(hasMenu) && hasMenu === true) {
        const $options = $("<ul>");

        menu = $("<div style='display:none; position: fixed;'></div>");
        menu.addClass(name + "-menu-content");
        menu.addClass("phx-buttonBar-menu-content");
        menu.addClass("c-panneauMenu c-panneauMenu--noIcon");
        for (let i = 0; i < menuOptions.length; i++) {
          if (menuOptions[i].name === "ui-menu-divider") {
            $options.append("<li class='" + menuOptions[i].name + " button customButton c-panneauMenu__item' value='" + menuOptions[i].name + "' style='white-space:nowrap; padding-top: 1px; padding-bottom: 1px;'>" + menuOptions[i].label + "</li>");
          } else {
            $options.append("<li class='" + menuOptions[i].name + " button customButton c-panneauMenu__item' value='" + menuOptions[i].name + "' tabindex='0' style='white-space:nowrap;'>" + menuOptions[i].label + "</li>");
          }
        }
        menu.append($options);
      }
      //Infobulle
      if (title && !CWSTR.isBlank(title)) {
        $groupButton.attr("title", title);
      }
      // aria-label
      if (ariaLabel && !CWSTR.isBlank(ariaLabel)) {
        $button.attr("aria-label", ariaLabel);
      }
      $button.prop({ text: lshowText });
      $groupButton.addClass("btnBar-btn-container");
      $groupButton.append($button);
      if (!CWSTR.isBlank(hasMenu) && hasMenu === true) {
        $groupButton.append(menu);
      }
      // Position the button
      if (insertTo === "first") {
        this.$el.prepend($groupButton);
      } else if (insertTo === "last") {
        this.$el.append($groupButton);
      } else {
        this.$el.find("." + insertTo).parent().after($groupButton);
        this.distributedButtons[this.distributedButtons.length] = $groupButton;
      }
      if (icon) {
        this.$el.find("." + name).append(UTILS.getSVGIcon(icon, name + '-menu cw-icon-text', 16, undefined))
      }
      if (_.isBoolean(habilitation)) {
        this.droits[name] = habilitation;
      } else {
        this.droits[name] = true;
      }
      this.visible[name] = true;
      this._manageMode();
      if (_.isBoolean(discret) && discret === true) {
        $button.addClass("phx-button-discret");
      }
      //Check is must be detached
      if (this.detachedBtns[name] && this.detachedBtns[name].isDetached === false) {
        this.detachButton(name, this.detachedBtns[name].position);
      }
      this.customBtns[name] = $groupButton;
    } else {
      CWLOG.warn("The button you are trying to add in the bar is already added (" + name + ").");
    }
  }

  /**
   * Adds a new button to the button bar
   */
  addButton(name: string, label?: string, icon?: string, showText?: boolean, habilitation?: boolean, title?: string, hasMenu?: any, menuOptions?: any, discret?: boolean, ariaLabel?: string): void {
    this.addButtonAt("last", name, label, icon, showText, habilitation, title, hasMenu, menuOptions, discret, ariaLabel);
  }

  /**
   * Adds a new button at the top of the button bar
   */
  addButtonFirst(name: string, label: string, icon?: string, showText?: boolean, habilitation?: boolean, title?: string, hasMenu?: any, menuOptions?: any, discret?: boolean, ariaLabel?: string): void {
    this.addButtonAt(
      "first", name, label, icon, showText, habilitation, title, hasMenu, menuOptions, discret, ariaLabel);
  }

  /**
   * Delete button from the button bar
   */
  deleteButton(name: string): void {
    this.visible[name] = false;
    this.droits[name] = false;
    this.$el.find("." + name).parent().remove();

  }

  changeButtonLabel(name: string, label: string): void {
    const button = this.$el.find("." + name);

    if (button) {
      button.text(label);
    }
  }

  addClass(search: string, className: string): void {
    const button = this.$el.find(search);

    if (button) {
      button.addClass(className);
    }
  }

  /**
   * Set title to button (for infobulle)
   */
  setTitle(button: string, title: string): void {
    if (button && !CWSTR.isBlank(button)) {
      this.buttonsTitle[button] = title;
    }
  }

  /**
   * set icon
   */
  setIcon(btn: string, icon: string): void {
    const button = this.$el.find("." + btn);

    if (button) {
      button.addClass("btn-withIcon");
      button.append(UTILS.getSVGIcon(icon, 'btn-withIcon', 16, undefined));
    }
  }


  addMenuToButton(name: string, menuOptions: CWButtonBarMenu, changeIcon: boolean, newIcon?: string, menuPosition?: { [key: string]: any }): void {
    const menu = $("<div style='display:none; position: fixed;'>");
    const $options = $("<ul>");

    this.$el.find("." + name + "-menu-content").remove();
    menu.addClass(name + "-menu-content");
    menu.addClass("phx-buttonBar-menu-content");
    menu.addClass("c-panneauMenu c-panneauMenu--noIcon");
    for (let i = 0; i < menuOptions.length; i++) {
      if (menuOptions[i].name === "ui-menu-divider") {
        $options.append(`<li class='${(menuOptions[i] as any).className ? (menuOptions[i] as any).className + ' ' : ''}${name} button customButton c-panneauMenu__item' value='${menuOptions[i].name}' style='white-space:nowrap;'>${menuOptions[i].label}</li>`);
      } else {
        $options.append(`<li class='${(menuOptions[i] as any).className ? (menuOptions[i] as any).className + ' ' : ''}${name} button customButton c-panneauMenu__item' value='${menuOptions[i].name}' tabindex='0' style='white-space:nowrap;'>${menuOptions[i].label}</li>`);
      }
    }
    menu.append($options);
    this.$el.find('.' + name).append(menu);
    if (menuPosition) {
      this.customBtnsPositions[name] = menuPosition;
    }
    if (changeIcon) {
      if (newIcon) {
        this.$el.find('button.' + name + ' .cw-icon').remove();
        this.$el.find('button.' + name).append(UTILS.getSVGIcon(newIcon, name + '-menu cw-icon-text', 16, undefined));
      } else {
        this.$el.find('button.' + name + ' .cw-icon').remove();
        this.$el.find('button.' + name).append(UTILS.getSVGIcon('fleche_bas', name + '-menu cw-icon-text', 16, undefined));
      }
      this.$el.find('button.' + name).addClass('btn-withIcon');
    }
  }

  /**
   * Manage the visivility of the all component
   * @param {boolean} isVisible Indicate is the button bar is visible or nor
   */
  protected manageVisibilityButtonBar(isVisible: boolean): void {
    if (!isVisible && this.deAtachParent.length === 0 && document.contains(this.$el[0])) { //Only it is rendered to DOM
      this.deAtachParent[0] = this.$el.parent();
      this.$el.detach();
      this.distributedButtons.forEach(element => {
        this.deAtachParent[this.deAtachParent.length] = element.parent();
        element.detach();
      });
    } else if (!isVisible && this.deAtachParent.length > 0 && _.isEqual(this.deAtachParent[0], this.$el.parent())) {
      this.$el.detach();
    } else if (isVisible && this.deAtachParent.length > 0) {
      let counter = 1;

      this.deAtachParent[0].append(this.$el);
      this.distributedButtons.forEach(element => {
        this.deAtachParent[counter].append(element);
        counter++;
      });
      this.deAtachParent = Array<JQuery>();
    }
  }

  reconnectEvents($clonedBtn: JQuery): void {
    if (!CWSTR.isBlank($clonedBtn)) {
      $clonedBtn.find(".cw-icon").off("click");
      $clonedBtn.find(".cw-icon").on("click", (event: JQuery.TriggeredEvent) => {
        this._toggleMenu(event);
      });
      $clonedBtn.find("button").off("click");
      $clonedBtn.find("button").on("click", (event: JQuery.TriggeredEvent) => {
        this._clickListener(event);
      });
      $clonedBtn.find("button").off("dblclick");
      $clonedBtn.find("button").on("dblclick", (event: JQuery.TriggeredEvent) => {
        this._clickListener(event);
      });
      $clonedBtn.find(".revert").off("click");
      $clonedBtn.find(".revert").on("click", (event: JQuery.TriggeredEvent) => {
        this._clickListener(event);
      });
      $clonedBtn.find(".customButton").off("click");
      $clonedBtn.find(".customButton").on("click", (event: JQuery.TriggeredEvent) => {
        this._customClickListener(event);
      });
    }
  }

  /**
   * Returns the button HTML element
   * @param {stirng} btnName the name of the button
   */
  public getButtonHTMLElement(btnName: string): JQuery {
    const elementInButtonBar = this.$el.find("button." + btnName);

    if (this.detachedBtns[btnName] && this.detachedBtns[btnName].isDetached === true) {
      return this.detachedBtns[btnName].position.find("button." + btnName);
    }
    return elementInButtonBar;
  }

  changeTypeButton(button: string, valueClass: string): boolean {
    let lRtn = false;

    if (!CWSTR.isBlank(button) && !CWSTR.isBlank(valueClass)) {
      this.btnClasses[button] = valueClass;
      lRtn = true;
    }
    return lRtn;
  }

  setDroit(action: string, valeur: boolean): void {
    if (!CWSTR.isBlank(action) && _.isBoolean(valeur)) {
      this.droits[action] = valeur;
    }
  }

  changeBtnDisplayOrder(): void {
    _.each(this.btnOrder, (number, name): void => {
      this.$el.find("." + name).parent().css('order', number);
    })
  }

  getCustomBtns(): any {
    return this.customBtns;
  }

  isVisible(button: string): boolean {
    let rtn = false;

    if (!CWSTR.isBlank(button) && _.isBoolean(this.visible[button])) {
      rtn = this.visible[button];
    }
    return rtn;
  }

  /**
  * Enables a button by its name (forced mode)
  */
  enabledForcedButton(name: string): void {
    if (!CWSTR.isBlank(name) && this.droits[name]) {
      this.$el.find("." + name).prop("disabled", false);
    }

  }

  /**
   * Disables a button by its name (mode forced)
   */
  disabledForcedButton(name: string): void {
    if (!CWSTR.isBlank(name) && this.droits[name]) {
      this.$el.find("." + name).prop("disabled", true);
    }
  }

  remove(): any {
    if (this.model) {
      this.model.off();
      this.model = null;
    }
    this.$el.empty();
    return super.remove();
  }
}
