import { BaseModel } from 'core/models/base.model.js';

//Table remplacements EVO 450

/**
 * Type de Structure Model
 */
export var TypeStructureModel = BaseModel.extend({

  url: function() {
    var url = Configuration.restRoot + "/rest/structorga/";
    //read purpose only
    url += encodeURIComponent(this.get("id"));

    return url;
  },

  params: {
    "calcule_a_activer": false,
  },

  defaults: {
    "id": null,
    "code": null,
    "codef": "",
    "libelle": "",
    "abrege": "",
    "dated": "",
    "format": 1,
    "indiczonesco": false,
    "prioritejexpar": 0,
    "rattcolpar": "O",
    "nbelements": null,
    "nbcollab": null,
    "nbcollabnonrattache": null,
    "nbactratcol": null,
    "nbaffectationsec": null,
    "principal": false,
    "rattactivite": false,
    "nbactivite": null,
    "rattpret": false,
    "rattaffectationsec": false,
    "nbpretratcol": null,
    "racine": null
  },

  parse: function(response) {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;
    }
    this._codef = response.codef;
    this._rattcollab = response.rattcolpar;
    return response;
  }
});
