import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

//EVO 556
/**
 * Model for the complement combos
 */
export class CWComplementComboModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    if (!CWSTR.isBlank(response.code)) {
      result.id = response.code;
      result.code = response.code;
    }
    if (!CWSTR.isBlank(response.libellecourt)) {
      result.libelle = response.libellecourt;
    }
    return result;
  }
}
