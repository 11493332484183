import { STR } from 'utils/str.js';
import { BaseModel } from 'core/models/base.model';

/**
 * TypeExterneModel save type of etat used :
 *      0, aucun
 *      1, SSRS
 *      2, BIRT
 */
export var TypeExterneModel = BaseModel.extend({

  habilitationV: "N",
  habilitationG: "N",
  usecase: "app",

  listType: ["NONE", "SSRS", "BIRT"],

  urlRoot: Configuration.restRoot + "/rest/adminetat/typeexterne",

  default: {
    "code": null
  },

  parse: function(response) {
    if (response && !STR.isBlank(response.etatExterne)) {
      response.code = response.etatExterne;
      response.libelle = this.listType[response.etatExterne];
    }
    return response;
  }
});
