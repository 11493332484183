import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { FORMS } from 'utils/forms.js';
import { i18n } from 'src/i18n.js';
import { LICENCE } from 'utils/licence.js';
import { LOG } from 'utils/log.js';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'utils/sync.js';

/**
 * Lister Absence & Souhait Model
 */

export class CWGererAbsenceSouhaitModel extends CWBaseModel {

  SAISIE_DUREE: string;
  SAISIE_HEURE: string;
  MODEGROUPE: boolean;
  matricule: boolean;
  action: string;
  periode: boolean;
  profil: { [key: string]: any };
  transformationEnCours: boolean;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "souhait": false,
      "refsouhait": "",
      "statut": {
        "code": "",
        "libelle": ""
      },
      "motif": {
        "code": "",
        "libelle": ""
      },
      "etat": null,
      "infocomp": {},
      "datedeb": "",
      "datefin": "",
      "unitedeb": "",
      "unitefin": "",
      "heuredeb": "0000",
      "heurefin": "0000",
      "duree": "0000",
      "matricule": null,
      "table": "",
      "iddemande": null,
      "commentaire": "",
      "historique": null,
      "droit": "N",
      "generationedt": false,
      "origine": {
        "code": "",
        "libelle": ""
      },
      "permutation": false
    }
  }

  constructor(attributes: { [key: string]: any }, options: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.params = options.params;
    if (CWSTR.isBlank(options.SAISIE_DUREE)) {
      throw Error("You must set 'options.SAISIE_DUREE' with the value of the parameter diver 'abshduree0'");
    } else {
      this.SAISIE_DUREE = options.SAISIE_DUREE;
    }
    this.context = options.context;
    if (CWSTR.isBlank(options.SAISIE_HEURE)) {
      throw Error("You must set 'options.SAISIE_HEURE' with the value of the parameter diver 'sai_absh'");
    } else {
      this.SAISIE_HEURE = options.SAISIE_HEURE;
    }
    this.MODEGROUPE = false;
    if (!attributes || CWSTR.isBlank(attributes.unitedeb)) {
      this.set("unitedeb", {});
      this.set("unitefin", {});
    }
    if (attributes && Object.prototype.hasOwnProperty.call(attributes, "matricule")) {
      this.matricule = true;
    }
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"],
      heuredeb: ["heuredeb", "heurefin"],
      heurefin: ["heuredeb", "heurefin"]
    };
    if (options.outils) {
      this.set("justif", false);
      this.set("justifverif", false);
      this.set("comm", "");
    }
    // The gestion of the parameter A.mesCtrl is done by metier
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);
      const paramsMed = ((this.context?.ctxEcran === "planmed") ? "?medical=true" : "");//De moment, params aura le texte '?medical=true' ou il sera vide-> renommé paramsMed

      // MMB Customer: #176911 The parameter 'modegroupe' is already in 'this.action'
      if (this.action === "saisiegroupee") {// || (id === "" && CWSTR.isBlank(paramsMed))) {
        return Configuration.restRoot + "/rest/absence/composant/" + this.action;
      } else {
        return Configuration.restRoot + "/rest/absence/composant/" + this.action + (!CWSTR.isBlank(id) ? "/" + id : "") + (!CWSTR.isBlank(paramsMed) ? paramsMed : "");
      }
    };
  }

  clone(): CWGererAbsenceSouhaitModel {
    return new CWGererAbsenceSouhaitModel(JSON.parse(JSON.stringify(this.toJSON())), {
      SAISIE_HEURE: this.SAISIE_HEURE,
      SAISIE_DUREE: this.SAISIE_DUREE
    });
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    if (CWSTR.isBlank(this.SAISIE_DUREE)) {
      throw Error("You must set 'this.SAISIE_DUREE' with the value of the parameter diver 'abshduree0'");
    }
    if (CWSTR.isBlank(this.SAISIE_HEURE)) {
      throw Error("You must set 'this.SAISIE_HEURE' with the value of the parameter diver 'sai_absh'");
    }
    if (CWSTR.isBlank(attrs.motif.code)) {
      errors.motif = {};
      errors.motif.code = i18n.t('common:gererabsencesouhait.motif_title');
    }
    if (this.matricule === true && CWSTR.isBlank(attrs.matricule)) {
      errors.matricule = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.collab') });
    }

    const datedeb = {
      "name": "datedeb",
      "value": attrs.datedeb,
      "label": i18n.t('common:gererabsencesouhait.datedebut')
    };

    const datefin = {
      "name": "datefin",
      "value": attrs.datefin,
      "label": i18n.t('common:gererabsencesouhait.datefin'),
      "isInfinity": false
    };

    const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
    if (!CWSTR.isBlank(errorMsg)) {
      if (!CWSTR.isBlank(errorMsg.datedeb)) {
        errors.datedeb = errorMsg.datedeb;
      }
      if (!CWSTR.isBlank(errorMsg.datefin)) {
        errors.datefin = errorMsg.datefin;
      }
    }

    const dateNow = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "YYYYMMDD");
    // # customer 142472
    if (!CWSTR.isBlank(this.context)) {
      const noGestionCollective = (CWSTR.isBlank(this.context.ctxGestionCollective) || this.context.ctxGestionCollective !== true);
      const noTransformation = (CWSTR.isBlank(this.transformationEnCours) || this.transformationEnCours !== true);
      if (CWSTR.isBlank(errors.datedeb) && (attrs.datedeb < dateNow && noGestionCollective) && attrs.souhait === true && noTransformation) {
        errors.datedeb = i18n.t('messages:GT_1050');
      }
    } else {
      if (objs.appRt.workflow.get("usecase") !== "saisiescoll" && CWSTR.isBlank(errors.datedeb) && attrs.datedeb < dateNow && attrs.souhait === true) {
        errors.datedeb = i18n.t('messages:GT_1050');
      }
    }
    // # customer 142472

    if (CWSTR.isBlank(attrs.unitedeb.code)) {
      if ((this.periode !== undefined && this.periode === true) || this.get("datedeb") !== this.get("datefin")) {
        errors.unitedeb = {};
        errors.unitedeb.code = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.unitedebut_title') });
      } else {
        errors.unitedeb = {};
        errors.unitedeb.code = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.unite') });
      }
    }
    if (CWSTR.isBlank(attrs.unitefin.code)) {
      if ((this.periode !== undefined && this.periode === true) || this.get("datedeb") !== this.get("datefin")) {
        errors.unitefin = {};
        errors.unitefin.code = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.unitefin_title') });
      }
    }

    if (attrs.unitedeb.code === 'H') {
      let heuredeb = parseInt(attrs.heuredeb, 10);
      heuredeb = options.editedAttr === "heurefin" && isNaN(heuredeb) ? 0 : heuredeb;

      let heurefin = parseInt(attrs.heurefin, 10);
      heurefin = options.editedAttr === "heuredeb" && isNaN(heurefin) ? 0 : heurefin;

      let duree = parseInt(attrs.duree, 10);
      const heuredebNaN = isNaN(heuredeb);
      const heurefinNaN = isNaN(heurefin);
      const dureeNan = isNaN(duree);

      if (isNaN(heuredeb)) {
        heuredeb = 0;
      }
      if (isNaN(heurefin)) {
        heurefin = 0;
      }
      if (isNaN(duree)) {
        duree = 0;
      }

      if (CWSTR.isBlank(attrs.heuredeb) && !CWSTR.isBlank(attrs.heurefin)) {
        errors.heuredeb = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.heuredebut') });
      }

      if (CWSTR.isBlank(attrs.heurefin) && !CWSTR.isBlank(attrs.heuredeb) && options.individual !== true) {
        errors.heurefin = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.fin') });
      }

      if (CWSTR.isBlank(attrs.duree) && (this.SAISIE_DUREE !== "0" && this.SAISIE_HEURE === "3")) {
        errors.duree = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.duree') });
      }

      //We delete error 2 from heredeb (heuredeb and heurefin are inserted unitariement)
      //to prevent showing error 637 when neither heuredeb nor heurefin are informed.
      if (this.validationError &&
        this.validationError.errors &&
        !CWSTR.isBlank(this.validationError.errors.heuredeb) &&
        this.validationError.errors.heuredeb === i18n.t('messages:GT_637')) {
        FORMS.removeValue(this.validationError.errors, "heuredeb");
      }
      //We delete error 2 from herefin  (heuredeb and heurefin are inserted unitariement)
      //to prevent showing error 637 when heuredeb && heurefin && duree are not informed
      if (this.validationError &&
        this.validationError.errors &&
        !CWSTR.isBlank(this.validationError.errors.heurefin) &&
        this.validationError.errors.heurefin === i18n.t('messages:GT_637')) {
        FORMS.removeValue(this.validationError.errors, "heurefin");
      }
      //heredeb heurefin and duree are not informed
      if (heuredebNaN && heurefinNaN && dureeNan) {
        errors.heuredeb = i18n.t('messages:GT_422');
      } else {
        //We delete error 1(duree &&heredebut &&heurefin not informed)
        //in case one of them is informed
        if (this.validationError &&
          this.validationError.errors &&
          !CWSTR.isBlank(this.validationError.errors.heurefin) &&
          this.validationError.errors.heurefin === i18n.t('messages:GT_422')) {
          FORMS.removeValue(this.validationError.errors, "heurefin");
        }
      }

      // 2)Si AbsHeureDurée > 24:00 alors affichage du message d’erreur
      // 534 famille GT.
      if (duree > 2400) {
        errors.duree = i18n.t('messages:GT_534');
      }

      // 3 a)L’heure de début et de fin ne peuvent être saisie
      // unitairement
      // AbsHeureDebut et AbsHeurefin ne peuvent être renseignés
      // unitairement, soit les deux
      // sont renseignés soit aucun des deux, affichage du message
      // d’erreur 637 famille GT.
      if (heuredebNaN && !heurefinNaN) {
        errors.heuredeb = i18n.t('messages:GT_637');
        //errors.heurefin = i18n.t('messages:GT_637');
      } else if (!heuredebNaN && heurefinNaN) {
        //errors.heuredeb = i18n.t('messages:GT_637');
        errors.heurefin = i18n.t('messages:GT_637');
      }

      // 3 b)Si AbsHeureFin < AbsHeureDebut alors affichage du message
      // d’erreur 420 famille GT
      if (!heurefinNaN && options.individual !== true && (heurefin < heuredeb)) {
        // CWMSGS.showError(i18n.t('messages:GT_420'));
        errors.heurefin = i18n.t('messages:GT_420');
      }

      // 3 c)Si ( AbsHeureDébut<>0 OU AbsHeureFin<>0 )
      // ET AbsHeureDurée > ( AbsHeureFin – AbsHeureDebut )
      // alors affichage du message d’erreur 429 famille GT.
      if (errors &&
        (CWSTR.isBlank(errors.heurefin) && CWSTR.isBlank(errors.heuredeb)) &&
        (duree > (heurefin - heuredeb)) && ((String(heuredeb) !== "0") || (String(heurefin) !== "0"))) {
        errors.duree = i18n.t('messages:GT_429');
      }

      // 4)Si (AbsHeureDurée=0 ET (modeSaisieHeure<>3 ou modeSaisieHeureDuree<>0))
      // alors affichage du message d’erreur 412 famille GT
      // (La durée ne peut valoir 0 que si modeSaisieHeure=3 et modeSaisieHeureDurree=0)
      if ((this.SAISIE_HEURE !== "3" || String(this.SAISIE_DUREE) !== "0") && parseInt(attrs.duree) <= 0) {
        errors.duree = i18n.t('messages:GT_412');
      }

      if (this.validationError &&
        this.validationError.errors &&
        !CWSTR.isBlank(this.validationError.errors.duree)) {
        FORMS.removeValue(this.validationError.errors, "duree");
      }

      let errorMsgHeureDeb = "";
      if (!CWSTR.isBlank(attrs.heuredeb)) {
        errorMsgHeureDeb = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heuredeb);
        if (!CWSTR.isBlank(errorMsgHeureDeb)) {
          errors.heuredeb = errorMsgHeureDeb;
        }
      }
      let errorMsgHeureFin = "";
      if (!CWSTR.isBlank(attrs.heurefin)) {
        errorMsgHeureFin = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heurefin);
        if (!CWSTR.isBlank(errorMsgHeureFin)) {
          errors.heurefin = errorMsgHeureFin;
        }
      }
      let errorMsgDuree = "";
      if (!CWSTR.isBlank(attrs.duree)) {
        errorMsgDuree = CWTYPE.DURATION.HOUR_MINUTE.validate(attrs.duree);
        if (!CWSTR.isBlank(errorMsgDuree)) {
          errors.duree = errorMsgDuree;
        }
      }
    }
    // Check the combinations of unite debut and unite fin
    if (!LICENCE.hasModule("GTM")) {
      if (attrs.unitedeb.code === 'J') {
        if (attrs.unitefin.code === 'S' || attrs.unitefin.code === 'H') {
          errors.unitefin = {};
          errors.unitefin.code = i18n.t('messages:GT_418');
        }
      } else if (attrs.unitedeb.code === 'M') {
        if (attrs.unitefin.code === 'J' || attrs.unitefin.code === 'S' || attrs.unitefin.code === 'H') {
          errors.unitefin = {};
          errors.unitefin.code = i18n.t('messages:GT_418');
        }
      } else if (attrs.unitedeb.code === 'S') {
        if (attrs.unitefin.code === 'H') {
          errors.unitefin = {};
          errors.unitefin.code = i18n.t('messages:GT_418');
        }
      } else if (attrs.unitedeb.code === 'H') {
        if (attrs.unitefin.code === 'J' || attrs.unitefin.code === 'M' || attrs.unitefin.code === 'S') {
          errors.unitefin = {};
          errors.unitefin.code = i18n.t('messages:GT_418');
        }
      }
    }
    //EVO 556 varialbe properties
    _.each(this.get("infocomp"), (infocom: { [key: string]: any }) => {
      let messageError = "";

      //Check required
      if (infocom.oblig === true && CWSTR.isBlank(attrs.infocomp[infocom.code].valeur)) {
        messageError = i18n.t('common:required', { "0": attrs.infocomp[infocom.code].libelle });
      }
      //Check max - min
      if (!CWSTR.isBlank(infocom.code) && !CWSTR.isBlank(attrs.infocomp[infocom.code]) && !CWSTR.isBlank(attrs.infocomp[infocom.code].valeur)) {
        if (infocom.type === "D" && (!CWSTR.isBlank(infocom.datemin) || !CWSTR.isBlank(infocom.datemax))) { //Type DATE
          messageError = CWTYPE.DATE.validate(attrs.infocomp[infocom.code].valeur);
          if (CWSTR.isBlank(messageError)) {
            if ((!CWSTR.isBlank(infocom.datemin) && parseInt(attrs.infocomp[infocom.code].valeur) < parseInt(infocom.datemin)) || (!CWSTR.isBlank(infocom.datemax) && parseInt(attrs.infocomp[infocom.code].valeur) > parseInt(infocom.datemax))) {
              messageError = i18n.t('messages:GT_1040', { "1": infocom.libelle, "2": CWTYPE.DATE.format(infocom.datemin), "3": CWTYPE.DATE.format(infocom.datemax) });
            }
          }
        } else {
          if (infocom.type === "N" && (!CWSTR.isBlank(infocom.nummin) || !CWSTR.isBlank(infocom.nummax))) { //Tipe NUM
            if ((!CWSTR.isBlank(infocom.nummin) && parseInt(attrs.infocomp[infocom.code].valeur) < parseInt(infocom.nummin)) || (!CWSTR.isBlank(infocom.nummax) && parseInt(attrs.infocomp[infocom.code].valeur) > parseInt(infocom.nummax))) {
              messageError = i18n.t('messages:GT_1041', { "1": infocom.libelle, "2": infocom.nummin, "3": infocom.nummax });
            }
          }
        }
      }
      if (!CWSTR.isBlank(messageError)) {
        if (CWSTR.isBlank(errors.infocomp)) {
          errors.infocomp = {};
        }
        if (CWSTR.isBlank(errors.infocomp[infocom.code])) {
          errors.infocomp[infocom.code] = {};
        }
        errors.infocomp[infocom.code].valeur = messageError;
      }
    });

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  save(key?: { [key: string]: any }, val?: { [key: string]: any }, options?: { [key: string]: any }): void {
    LOG.debug("custom save");
    if (this.action !== "refuser" && this.action !== "accepter") {
      this.unset("profils");
      this.unset("table");
      this.unset("justifverif");
      this.unset("justif");
    }
    // The gestion of the parameter A.mesCtrl is done by metier
    super.save(key, val, options);
  }

  _calculateDays(datedeb: string, datefin: string): number {
    const start = this._parseDate(datedeb);
    const end = this._parseDate(datefin);
    const startMs = start.getTime();
    const endMs = end.getTime();
    const diffMs = endMs - startMs;
    const diffMsAux = diffMs / 86400000;
    let res = Math.trunc ? Math.trunc(diffMsAux) : diffMsAux - diffMsAux % 1;//Math.trunc doesn't work on IE11 and lower

    res = res + 1;
    return res;
  }

  _parseDate(date: string): Date {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      if (this.MODEGROUPE === false) {
        if (response.id === undefined && response.code !== undefined) {
          response.id = response.matricule + "," + response.code + "," + response.table;
        }
      }
      if (response.result) {
        return null;
      }
    }
    return response;
  }

  destroy(options?: { [key: string]: any }): void {
    const lOpt = options ? _.clone(options) : null;

    if (this.context?.ctxEcran === "planmed" && !CWSTR.isBlank(lOpt?.url) && lOpt.url.indexOf("medical=true") < 0) {
      if (lOpt.url.indexOf("?") < 0) {
        lOpt.url += "?medical=true";
      } else {
        lOpt.url += "&medical=true";
      }
    }
    super.destroy(lOpt);
  }
}
