import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLAppMenuItemNiv1 from '../ctimeMenuItemHeader.tpl.html';
import TPLAppMenuItemNiv2 from '../ctimeMenuItemNiv2.tpl.html';
import TPLAppMenuItemSection from '../ctimeMenuItemSection.tpl.html';
import TPLAppUsermenu from '../cwUsermenu.tpl.html';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWGeneralView } from 'common/droitsdel/views/cwGeneral.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWLOG } from 'utils/cwLog';
import { CWLoginChangePwdDialogView } from 'core/components/login/cwLoginChangePwdDialog.view';
import { CWProfileSelector } from './cwProfileSelector';
import { CWWorkFlowModel } from '../models/cwWorkFlow.model';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

/**
 * UsereMenu View
 *
 * Contain the menus specific to a user
 *
 */
export class CWUserMenuView extends Backbone.View<CWWorkFlowModel> {
  templateItemNiv1: any;
  templateItemNiv2: any;
  templateItemSection: any;
  chgPwdDialog: any;
  droitsdelDialog: CWDialogPopupView;
  isPrfActiv: boolean;


  constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
    params.events = _.extend({
      "click a": "clickOption",
      "click li": (event: JQueryEventObject): void => {
        event.stopPropagation();
        if ($(event.target).children("div#phx-profile-selector").length === 0 && $(event.target).find("a").length === 1) {
          $(event.target).find("a").trigger("click");
        }
      },
      "click .phx-menu": "_clickMenu",
      "click .ui-menu-item.phx-profil-menu": "_clickProfil",
      "click .phx-acount-span a": (): void => {
        //logout
        this.model.trigger("user:prelogout");
      }
    }, params.events);
    super(params);
    this.template = TPLAppUsermenu;
    this.templateItemNiv1 = TPLAppMenuItemNiv1;
    this.templateItemNiv2 = TPLAppMenuItemNiv2;
    this.templateItemSection = TPLAppMenuItemSection;
    this.listenTo(GLOBAL_DATA.rights, "sync", this._reloadMenu);
    this.isPrfActiv = CWDIVERS.get("prfActiv") === "1" ? true : false;
  }

  _clickProfil(event: JQueryMouseEventObject): void {
    if (objs && objs.profilutilisateur && objs.profilutilisateur.workflow) {
      objs.profilutilisateur.workflow.trigger("clickMenu", event);
    }
  }

  _reloadMenu(): void {
    this.model.configuration.get("confignav").get("transverses").forEach((trans: any): void => {
      if (GLOBAL_DATA.rights.menuVisibility.get(trans.emid)) {
        this.$el.find("." + trans.emid).removeClass("ui-state-disabled");
      } else {
        this.$el.find("." + trans.emid).addClass("ui-state-disabled");
      }
    }, this);
  }

  render(): CWUserMenuView {
    const json = { UTILS: UTILS, nom: "", alt: "", index: 0, i18n: i18n };
    let ucsInThisMenu = ["droitsdel"];
    if (CWHABILITATION.canView("CHGMDP.V")) {
      ucsInThisMenu = ["ppmpd", "droitsdel"];
    }
    let visibleMenu = false;
    let menuDom: JQuery = null;
    let ulDom: JQuery = null;
    const logoutOption = "<li class='ui-menu-item phx-menu-link phx-hover'><span class=\"phx-acount-span\"><a href = \"javascript:void(0)\">" + i18n.t('app.logout') + "</a></span></li>";

    //Create the DOM for the menu
    this.$el.append(this.template(json));
    menuDom = $(".phx-menu", this.el);
    ulDom = $("ul", this.el);
    _.each(this.model.configuration.get("confignav").get("transverses"), (uc: { [key: string]: any }): void => {
      if (_.contains(ucsInThisMenu, uc.id)) {
        if (uc.id) {
          let itemNiv1: JQuery = null;

          uc.href = "";
          uc.target = "";
          itemNiv1 = this.templateItemNiv1(uc);
          $(ulDom).append(itemNiv1);
          //Prepare keyboard navigation for last item added
          $(ulDom).find("li").last().keydown((e: any): void => {
            this._userMenuLinksKeyboardNav(e);
          });
          visibleMenu = true;
        }
      }
    }, this);
    // l'affichage de l'hierarchie n'est pas possible sans le profil courant. Le contrôle est fait par métier pour "UTI_SELPROFHIER"
    //ici, on ne doit pas faire '&& this.canViewBefore("UTI_SELPROFHIER")) {'
    if (CWHABILITATION.canView("UTI_SELPROFCOUR") && this.isPrfActiv && GLOBAL_DATA.profiles.profilesNumber() > 0) {
      const transverses = this.model.configuration.get("confignav").get("transverses");

      for (let i = 0; i < transverses.length; i++) {
        const uc = transverses[i];

        if (uc.id === "profilutilisateur" && GLOBAL_DATA.profiles.profilesNumber() > 0 && this.isPrfActiv) {
          $(ulDom).append('<li class="ui-menu-item phx-menu-link phx-hover"><div id="phx-profile-selector"></div></li>');
          if (!visibleMenu) {
            visibleMenu = true;
          }
          break;
        }
      }
    }
    $(ulDom).append(logoutOption);
    $("div", menuDom).hide();
    //Prepare keyboard Navigation for user menu icon
    this.$el.find(".phx-menu span.phx-profiles-first-icon").keydown((e: any): void => {
      this._userMenuIconKeyboardNav(e);
    });
    if (visibleMenu === false) {
      menuDom.hide();
    } else {
      menuDom.show();
    }
    // l'affichage de l'hierarchie n'est pas possible sans le profil courant. Le contrôle est fait par métier pour "UTI_SELPROFHIER"
    //ici, on ne doit pas faire '&& this.canViewBefore("UTI_SELPROFHIER")) {'
    if (CWHABILITATION.canView("UTI_SELPROFCOUR") && this.isPrfActiv) {
      $("#phx-profile-selector", this.el).parents("li").addClass("phx-profil-menu");
      const profilesView = new CWProfileSelector({
        el: $("#phx-profile-selector", this.el),
        model: this.model
      });

      profilesView.render();
    }

    return this;
  }

  _userMenuIconKeyboardNav(e: any): void {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 40) { //enter, spacebar,  down arrow
      //Open-Close menu
      $(e.target).click();

      //Navigate to first submenu in zonemenuView
      const parentDiv = $(this.el).find(".phx-menu");
      if (parentDiv.is(":visible")) { //If menu has been opened navigate to first element
        parentDiv.find("ul li").first().focus();
      }
      e.preventDefault();
    }
  }

  _userMenuLinksKeyboardNav(e: any): void {
    if (e.keyCode === 38) { // up
      const currentLi = $(e.target);

      if (currentLi.prevAll('li.phx-menu-link').first().length === 0) {
        const lastMenu = currentLi.nextAll('li.phx-menu-link').last();

        lastMenu.first().focus();
      } else {
        const prevMenu = currentLi.prevAll('li.phx-menu-link').first();

        prevMenu.first().focus();
      }
    } else if (e.keyCode === 40) { // down
      const currentLi = $(e.target);

      if (currentLi.nextAll('li.phx-menu-link').first().length === 0) {
        const firstMenu = currentLi.prevAll('li.phx-menu-link').last();

        firstMenu.first().focus();
      } else {
        const nextMenu = currentLi.nextAll('li.phx-menu-link').first();

        nextMenu.first().focus();
      }
    } else if (e.keyCode === 27) { // escape
      const menuZone = $(this.el).find(".phx-menu");

      // Close menu
      menuZone.find("span.ui-icon").click();
      //Focus parent element
      menuZone.find("span.ui-icon").focus();
    } else if (e.keyCode === 9) { //tab      
      const menuZone = $(this.el).find(".phx-menu");
      const divmenuZone = $(this.el).find(".phx-menu div");

      // If there is a submenu being show for any item in this zoneMenu, we hide it
      //Is menu is shown close it
      if (divmenuZone.is(":visible")) {
        menuZone.find("span.ui-icon").click();
      }
      //var accountMenu=$(e.target).closest("#phx-account");
    } else if (e.keyCode === 13 || e.keyCode === 32) { //enter, spacebar,  down arrow
      //Open-Close menu
      $(e.target).find("a").click();
    }
  }

  clickMenu(): void {
    $(this.el).find("svg").click();
  }

  _clickMenu(event: JQueryEventObject): void {
    const menu = $(event.target).parents("div.phx-menu");
    const ecran = $(event.target).children("div.cw-menu-item-title").attr("data-ecran");
    const index = menu.attr("data-value");

    CWLOG.debug("menu clicked: " + index);
    if (index !== undefined) {
      if (ecran === undefined) {
        if ($(".userMenu", menu).is(":visible") && $(event.target).parents(".phx-profiles-menu-list").length === 0
          && $(event.target).find("#phx-profile-selector").length === 0) {
          $(".userMenu", menu).hide();
          // Accessibility parameters
          this.$el.parent().attr("aria-expanded","false");
        } else {
          $(".userMenu", menu).show();
          this._enableCloseMenuListener();
          // Accessibility parameters
          this.$el.parent().attr("aria-expanded","true");
        }
      }
    }
  }

  _enableCloseMenuListener(): void {

    //If you click out of the menu, close the menu.    
    $(document).one("mousedown", null, (event: JQueryEventObject): void => {
      const element = $(this.el).find(event.target);
      if (element.length === 0) {
        $(".userMenu").hide();
      } else {
        this._enableCloseMenuListener();
      }
    }, null);
  }

  clickOption(event: JQueryEventObject, nameEvent: string, options: { [key: string]: any }): boolean {
    const name = nameEvent ? nameEvent : event.target.className.split(" ")[0];
    const menu = $(".phx-menu", this.el);

    switch (name) {
      case "ppmpd":
        if (this.chgPwdDialog === undefined) {
          this.chgPwdDialog = new CWDialogPopupView({
            dialogClass: "c-cwDialogPopup cw-style cwHeader__transversal",
            view: CWLoginChangePwdDialogView,
            popupType: CWDialogPopupType.AVEC_SAISIE
          });
        }
        this.chgPwdDialog.setWidth(400);
        this.chgPwdDialog.setHeight("auto");
        this.chgPwdDialog.open();
        this.chgPwdDialog._setTitle(i18n.t('login:pwdtitle'));
        $(".userMenu", menu).hide();
        break;
      case "droitsdel": {
        const screenSize = {
          width: $(window).width(),
          height: $(window).height()
        };

        // load dependencies to component
        if (!options) {
          options = {};
        }
        if (!options.view) {
          options.dialogClass = "c-cwDialogPopup cw-style cwHeader__transversal";
          options.view = CWGeneralView;
          options.viewData = {
            appendTo: $("#cw-appContainer")
          };
        }
        this.droitsdelDialog = new CWDialogPopupView(options);
        if (screenSize.height < 800) {
          this.droitsdelDialog.setHeight(screenSize.height - 5);
        } else {
          this.droitsdelDialog.setHeight(800);
        }
        if (screenSize.width < 1400) {
          this.droitsdelDialog.setWidth(screenSize.width - 40);
        } else {
          this.droitsdelDialog.setWidth(1400);
        }
        this.droitsdelDialog.open();
        $(".userMenu", menu).hide();
      }
        break;
      default:
        break;
    }
    return false;
  }
}
