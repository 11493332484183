import { SelecteurActiviteModeSimpleActiviteModel } from './selecteurActiviteModeSimpleActivite.model.js';
import { SelecteurActiviteModeSimpleActivitesColl } from './selecteurActiviteModeSimpleActivites.collection.js';
import { STR } from 'utils/str.js';

/**
 * Selecteur Activite mode simple model
 */
export var SelecteurActiviteModeSimpleModel = Backbone.Model.extend({

  /**
   * Default data model
   *	domcode : null,
   *	domlib : "",
   *	fmtact : "",
   *	fmtstra : null,
   *	act : ""
   * }
   */
  defaults: {
    domcode: null,
    domlib: "",
    fmtact: "",
    fmtstra: null,
    straid: "",
    act: ""
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (STR.isBlank(response.id) && !STR.isBlank(response.domcode)) {
      response.id = response.domcode;
    }
    if (!_.isEmpty(response.act) && response.act.length && response.act.length > 0) {
      var self = this;

      this.ActsColl = new SelecteurActiviteModeSimpleActivitesColl();
      _.each(response.act, function(activite) {
        var actCloned = _.clone(activite);
        var modelActivite = null;

        actCloned.parentfmtact = response.fmtact;
        actCloned.parentfmtstra = response.fmtstra;
        actCloned.parentdomcode = response.domcode;
        actCloned.parentdomlib = response.domlib;
        actCloned.parentstraid = response.straid;
        actCloned.parentfmtact = !STR.isBlank(actCloned.parentfmtact) ? parseInt(actCloned.parentfmtact) : 1;
        actCloned.parentfmtstra = !STR.isBlank(actCloned.parentfmtstra) ? parseInt(actCloned.parentfmtstra) : 1;
        modelActivite = new SelecteurActiviteModeSimpleActiviteModel(actCloned, { parse: true });
        self.ActsColl.add(modelActivite);
      });
    }
    return response;
  }
});
