import * as Backbone from 'Backbone';
import _ from 'underscore';
import { cwAutoCompleteModel } from './cwAutocomplete.model';
import { CWSTR } from 'utils/cwStr';

interface AddOptionsCWAutocompleteColl extends Backbone.AddOptions {
  parse?: boolean;
}

export class CWAutocompleteColl extends Backbone.Collection<cwAutoCompleteModel> {

  itemRenderer: (item: any) => string;
  public label: string;
  /**
   * Constructor
   * Autocompletion results collection
   */
  // constructor(params?: { [key: string]: any }) {
  constructor(models?: cwAutoCompleteModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || cwAutoCompleteModel;
    /**
  * Name of the property to show in the label
  */
    options.label = options.label || "libelle";
    super(models, options); // eslint-disable-line
    /**
     * Render function of every collection element
     */
    this.itemRenderer = null; //This was outside the initialize    
    /**
     * Label of the Collection
     */
    this.label = options.label;
  }

  /**
   * Method to be executed when a response from a call to a webservice is received successfully
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    const data: { [key: string]: any } = [];

    _.each(response, (resp, index) => {
      data[index] = {};
      data[index].label = this._renderer(resp);
      data[index].attrs = resp;
      if (!CWSTR.isBlank(resp.id)) {
        data[index].id = resp.id;
      } else {
        data[index].id = resp.code;
      }
    }, this);
    return data;
  }

  /**
   * Method to be executed when a response from a call to a webservice is received successfully
   */
  _renderer(resp: { [key: string]: any }): string {
    if (this.itemRenderer) {
      return this.itemRenderer(resp);
    } else {
      return resp[this.label];
    }
  }

  add(models: { [key: string]: any } | cwAutoCompleteModel, options?: AddOptionsCWAutocompleteColl): cwAutoCompleteModel;
  add(models: ({ [key: string]: any } | cwAutoCompleteModel)[], options?: AddOptionsCWAutocompleteColl): cwAutoCompleteModel[];
  add(models: unknown, options?: unknown): cwAutoCompleteModel | cwAutoCompleteModel[] {
    return super.add(models, options);
  }
}
