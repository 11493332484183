import _ from 'underscore';
import { CWCombosModel } from '../combo/cwCombos.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Model of the combos to obtain the structure of the combos from personalissables fields
 */
export class CWCaracteristiquesCodifModel extends CWCombosModel {

  /**
   * Default attribute values of the model
   */
  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  /**
   * This method is executed when we receive a response after a successfull call to a webservice
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    const result: any = {};
    if (!CWSTR.isBlank(response.code)) {
      result.id = response.code;
      result.code = response.code;
    }
    if (response.code === 0) {
      result.id = "0";
      result.code = 0;
    }
    if (!CWSTR.isBlank(response.libellecourt)) {
      result.libelle = response.libellelong;
    }
    return result;
  }


}