import * as Backbone from 'Backbone';
import TPLAppZone from '../zone.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWTabSheetView } from './cwTabSheet.view';


/**
 * Zone view. A zone it's a view with a menu on the left and a tabsheet on the
 * right that is able to load Use cases in the tab sheet
 */
export class CWZoneView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<CWBaseModel> {

  zJSON: any;
  tabsView: CWTabSheetView<CWBaseModel>;

  constructor(options?: Backbone.ViewOptions<TModel> | any) {
    options = options || {};
    options.className = "phx-zone";
    super(options);
    this.template = TPLAppZone;
    this.zJSON = options.zJSON;
  }

  render(): CWZoneView {
    this.$el.html(this.template(this.zJSON));
    // TabSheet
    this.tabsView = new CWTabSheetView({
      el: $(".phx-center", this.el),
      model: this.model,
      zJSON: this.zJSON
    });
    this.tabsView.render();
    // By default it's hidden
    this.$el.hide();
    return this;
  }
}
