import _ from 'underscore';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWGererActivitesGestionModel } from '../models/cwGererActivitesGestion.model';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

const TPLGereractivitesPopUp = require('../cwPopUp.tpl.html');
export class CWGererActivitePopUpView extends CWBaseFormView {
  parent: any;
  action: any;
  statut: any;
  utilisateur: any;
  typeEvenement: any;

  //REVIEW: 	i18n : phx.i18n.common.gereractivites,
  //REVIEW: 	i18nCom : phx.i18n.common,

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "click button": "_clickListener"
    }, options.events);
    super(options);
    this.template = TPLGereractivitesPopUp;

    this.model = new CWBaseModel({
      value: new CWGererActivitesGestionModel()
    });

    if (options.parent) {
      this.parent = options.parent;
    }

    if (options.action) {
      this.action = options.action;
      this.model.get("value").action = this.action;
    }

    if (options.statut) {
      this.statut = options.statut;
    }

    if (options.utilisateur) {
      this.utilisateur = options.utilisateur; //Collaborateur | Responsable
    }
    if (options.typeEvenement) {
      this.typeEvenement = options.typeEvenement; //prevue | realisee
    }
  }

  render(): this {
    const json = { "i18n": i18n, UTILS: UTILS };
    this.$el.append(this.template(json));

    let comment = "";
    let typeEvenementAdded = false;
    switch (this.action) {
      case "accepter":
        break;
      case "refuser":
        this.$el.find("label[for=commentaire],label[for^=commentaire_uid_]").addClass("cw-required");
        break;
      case "suppression":
        switch (this.statut) {
          case "I":
            if (this.typeEvenement === "prevue") {
              comment = i18n.t('common:gereractivites.commentaire_SuppresionCoursPrevue');
            } else if (this.typeEvenement === "realisee") {
              comment = i18n.t('common:gereractivites.commentaire_SuppresionCoursRealisee');
            }
            typeEvenementAdded = true;
            break;
          case "A":
            if (this.typeEvenement === "prevue") {
              comment = i18n.t('common:gereractivites.commentaire_SuppresionAcceptPrevue');
            } else if (this.typeEvenement === "realisee") {
              comment = i18n.t('common:gereractivites.commentaire_SuppresionAcceptRealisee');
            }
            typeEvenementAdded = true;
            break;
          case "B":
            comment = i18n.t('common:gereractivites.commentaire_SuppresionAcceptBesoin');
            typeEvenementAdded = true;
            break;
          default:
            comment = i18n.t('common:gereractivites.commentaire_Suppresion');
            break;
        }
        if (!typeEvenementAdded) {
          if (this.typeEvenement === "prevue") {
            comment += i18n.t('common:gereractivites.prevue');
          } else if (this.typeEvenement === "realisee") {
            comment += i18n.t('common:gereractivites.realisee');
          }
        }
      // no default

    }

    $(this.el).find(".spanComment").html(comment);

    return this;
  }

  _clickListener(event: JQueryEventObject): void {
    const btnClicked = (event.currentTarget as any).value;
    const model = this.model.get("value");

    switch (btnClicked) {
      case "btnOK":
        if (this.action === "accepter") {
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.close();
        } else {
          if (this.action === "refuser") {

            model.off("invalid");
            model.on("invalid", this._showValidationErrors, this);

            if (model.isValid()) {
              this.parent.model.set("action", "OK");
              this.parent.model.set("commentaire", model.get("commentaire"));
              this.parent.close();
            }

          } else if (this.action === "suppression") {
            this.parent.model.set("action", "OK");
            this.parent.model.set("commentaire", model.get("commentaire"));
            this.parent.close();
          }
        }

        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
    }
  }
}
