import _ from 'underscore';
import { CWDelegationsTableModel } from './cwDelegationsTable.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';


export class CWDelegationsTableColl<TModel extends CWDelegationsTableModel = CWDelegationsTableModel> extends CWPaginatedCollection<TModel> {

  utilCode: string | number | boolean;
  /**
   * Code of the Utilisateur
   */

  constructor(models?: CWDelegationsTableModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWDelegationsTableModel;
    options.usecase = options.usecase || "droitsdel";
    super(models, options);
    this.paginated = false;
    this.utilCode = null;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/delegation/" + encodeURIComponent(this.utilCode);
    };
  }
}