import _ from 'underscore';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWToucheFonctionModel } from './cwToucheFonction.model';

/**
 * Collection of Touche Fonction
 */
export class CWToucheFonctionColl<TModel extends CWToucheFonctionModel = CWToucheFonctionModel> extends CWPaginatedCollection<TModel>{

  habilitationV: string;
  habilitationG: string;
  usecase: string;
  idTypeTerminal: string;
  sortings: { [key: string]: any };

  //model: CWToucheFonctionModel;

  constructor(models?: CWToucheFonctionModel[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWToucheFonctionModel;
    super(models, options);
    //Default values from class
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = options.module;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/typeterm/" + encodeURIComponent(this.idTypeTerminal) + "/fonction";
    }
    this.idTypeTerminal = null;
    CWPaginatedCollection.prototype.initialize.call(this);
    //this.paginated = false;
    this.sortings = { code: true };
  }
}