import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

/**
 * Model for the complement combos
 */
export var SaisieOptionsModel = BaseModel.extend({

  defaults: {
    "id": null,
    "code": "",
    "libelle": ""
  },

  parse: function(response) {
    if (response && STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
});
