import { CWBaseModel } from 'core/models/cwBase.model';


/**
 * Model for Combos Loading
 */
export class CWGererBadgeagesProfilsModel extends CWBaseModel {
  profilId: any;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.url = (): string => {
      const result = Configuration.restRoot + "/rest/badgeage/composant/profils/" + encodeURIComponent(this.profilId);
      return result;
    };
  }

  initialize(options: { [key: string]: any }): void {
    this.profilId = "";
    if (options && options.profilId) {
      this.profilId = options.profilId;
    }
  }
}