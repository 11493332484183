module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="dialogPersoDeleteCriteria row">\n  <div class="col-lg-12">\n    <div class="title form-group">\n      <span class="dialogPersoDeleteCriteria-title-propre cw-titreBloc">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_subtitle_propre')))==null?'':__t)+
'</span>\n      <span class="dialogPersoDeleteCriteria-title-equipe cw-titreBloc">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_subtitle_equipe')))==null?'':__t)+
'</span>\n      <span class="dialogPersoDeleteCriteria-title-select cw-titreBloc">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_subtitle_select')))==null?'':__t)+
'</span>\n    </div>\n    <div class="section-own">\n      <div class="subdescription form-group">\n        <span class="cw-texteNormal" style="font-weight: normal !important;">'+
((__t=( i18n.t('agenda.dialog_persodeletecriteria_subtitle_select_options') ))==null?'':__t)+
'</span>\n      </div>\n      <div class="options" style="padding-left: 12px">\n        <div class="bloq_user form-group">\n          <div class="custom-control custom-checkbox">\n            <input id="aff_user" type="checkbox" class="del_user custom-control-input" name="del_user" value="1">\n            <label for="aff_user" class="custom-control-label font-weight-bold">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_subtitle_option_user')))==null?'':__t)+
'</label>\n          </div>\n        </div>\n\n        <div class="bloq_allusers form-group">\n          <div class="custom-control custom-checkbox">\n            <input id="del_allusers" type="checkbox" class="del_allusers custom-control-input" name="del_allusers" value="1">\n            <label for="del_allusers" class="custom-control-label font-weight-bold">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_subtitle_option_all')))==null?'':__t)+
'</label>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="decription">\n      <div class="form-group">\n        <span class="cw-texteNormal" style="font-weight: normal !important;">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_des')))==null?'':__t)+
'</span>\n      </div>\n    </div>\n<!--    <div class="buttons">\n      <button type="button" class="btnDelete" value="appliquer">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_confirm')))==null?'':__t)+
'</button>\n      <button type="button" class="btnCancel" value="annuler">'+
((__t=(i18n.t('agenda.dialog_persodeletecriteria_cancel')))==null?'':__t)+
'</button>\n    </div>-->\n  </div>\n</div>\n';
}
return __p;
};
