import _ from 'underscore';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWRecapModel } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwRecap.model';

/**
 * Collection of DAbsence
 */
export class CWTableColl extends CWPaginatedCollection {

  /**
   * Add this to have Outline
   *
   */
  constructor(models?: CWRecapModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWRecapModel;
    super(models, options);
    this.paginated = false;
  }
}
