import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';

export type CWRadioBoxViewOptions = {
  code: string | number;
  libelle: string;
  disabled: boolean;
}


export class CWRadioBoxView extends Backbone.View<CWReadOnlyModel> {

  /** Mark to use like instanceof */
  isRadioBoxView: boolean;
  tagName: string;
  className: string;
  private name: string;
  private label: string;
  private group: string;
  private selectedOption: any;
  private title: string;
  private orientation: string;
  private additionalViews: { [key: string]: any };
  private additionalRenderedViews: { [key: string]: any };
  private valueSelected: string;
  private personalDisplay: any;
  private options: Array<CWRadioBoxViewOptions>;
  private addTionalClassForRadios: string;
  private selectedClass: string;
  private classLabel: string;
  private idName: string;
  private useTagLabel: boolean;

  constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
    params = params || {};
    params.tagName = params.tagName || (params.orientation === "horizontal") ? "div" : "fieldset";
    params.className = params.className || (params.orientation === "horizontal") ? "form-group cw-customControlGroup c-radio-box" : "c-radio-box cw-fieldset";
    params.isRadioBoxView = params.isRadioBoxView || true;
    params.events = _.extend({
      "change": "_changeView",
      "click [readonly]": "_clickEvent",
      "keydown .custom-control": "keyEvent"
    }, params.events);
    params.options = params.options || [{
      code: "",
      libelle: "",
      disabled: false
    }];
    super(params);
    this.model = new CWReadOnlyModel({ value: "" });
    this.name = params.name;
    if (CWSTR.isBlank(this.name)) {
      throw new Error("Radiobox name must be defined.");
    }
    this.idName = params.id || params.name;
    this.label = params.label;
    this.group = "radio";
    if (!CWSTR.isBlank(params.group)) {
      this.group = params.group;
    }
    this.options = params.options;
    this.selectedOption = params.selectedOption;
    this.title = params.title;
    if (!CWSTR.isBlank(params.orientation)) {
      this.orientation = params.orientation;
    } else {
      this.orientation = "vertical";
    }
    this.additionalViews = [];
    this.additionalRenderedViews = [];
    this.valueSelected = "";
    if (!CWSTR.isBlank(params.additionalViews)) {
      this.additionalViews = params.additionalViews;
    }
    if (!CWSTR.isBlank(params.personalDisplay)) {
      this.personalDisplay = params.personalDisplay;
    }
    this.addTionalClassForRadios = "";
    if (!CWSTR.isBlank(params.addTionalClassForRadios)) {
      this.addTionalClassForRadios = params.addTionalClassForRadios;
    }
    this.selectedClass = "selectionne";
    if (!CWSTR.isBlank(params.selectedClass) || params.selectedClass === "") {
      this.selectedClass = params.selectedClass;
    }
    this.classLabel = "cw-texteImportant";
    if (!CWSTR.isBlank(params.classLabel) || params.classLabel === "") {
      this.classLabel = params.classLabel;
    }
    this.useTagLabel = (params.useTagLabel === true);
  }

  _clickEvent(e: any): void {
    e.preventDefault();
    e.stopPropagation();
  }

  _changeView(event: any): void {
    const target = event.target;
    const inputClass = event.target.className.split(" ")[0];

    if (!$(event.target).is(":radio")) {
      this.model.set(inputClass, event.target.value);
    } else if (this.model) {
      this.manageAdditionalViewState(target.value);
      this.model.set("value", target.value);
    }
  }

  _disableAdditionalView(code: any): void {
    if (!CWSTR.isBlank(code) && this.additionalRenderedViews[code] && this.additionalRenderedViews[code].disableView) {
      this.additionalRenderedViews[code].disableView();
    }
  }

  _enableAdditionalView(code: any): void {
    if (!CWSTR.isBlank(code) && this.additionalRenderedViews[code] && this.additionalRenderedViews[code].enableView) {
      this.additionalRenderedViews[code].enableView();
    }
  }

  render(): CWRadioBoxView {
    const $optionsDiv = (this.orientation === "horizontal") ? this.$el : $("<div class='groupe'>");

    // Legend or label
    if (!CWSTR.isBlank(this.label)) {
      this.$el.append($((this.useTagLabel ? "<label>" : "<legend>")).html(this.label));
    }
    // Options
    _.each(this.options, (labelValue: CWRadioBoxViewOptions): void => {
      const $opt: JQuery = $("<input type='radio'/>");
      const $divCont: JQuery = $("<div type='radio'>").addClass("custom-control custom-radio " + this.addTionalClassForRadios);
      let classLabel = this.classLabel;
      const $labelOpt = $("<label for='" + this.idName + labelValue.code + "' style='float:none'>");

      $opt.attr("value", labelValue.code);
      $opt.attr("name", this.idName + "_" + this.group);
      $opt.attr("id", this.idName + labelValue.code);
      $opt.addClass(this.name + " custom-control-input");
      //there is a view for this radio option
      if (labelValue.disabled) {
        $opt.attr("disabled", "true");
      } else {
        $opt.attr("tabindex", "0");
      }
      if (!CWSTR.isBlank(this.selectedOption) && this.selectedOption === labelValue.code) {
        this.valueSelected = this.selectedOption;
        $opt.attr("checked", "checked");
        $divCont.addClass(this.selectedClass);
        classLabel += " " + this.selectedClass;
      }
      $opt.prop("viewRef", this as any);
      $divCont.append($opt);
      $labelOpt.addClass("custom-control-label " + classLabel);
      if (!CWSTR.isBlank(this.title)) {
        $labelOpt.attr("title", this.title);
      }
      $labelOpt.html(labelValue.libelle);
      $divCont.append($labelOpt);
      $optionsDiv.append($divCont);
      if (this.orientation === "vertical") {
        $divCont.wrap("<div class='form-group'></div>");
      }
      if (this.additionalViews && this.additionalViews[labelValue.code]) {
        const additionalView = this.additionalViews[labelValue.code];
        const view = new additionalView.view(additionalView.viewData);

        this.additionalRenderedViews[labelValue.code] = view;
        view.render();
        if (this.personalDisplay) {
          view.$el.css("display", this.personalDisplay);
        } else {
          view.$el.css("display", "inline-block");
        }
        $optionsDiv.append(view.$el);
        if (!CWSTR.isBlank(this.selectedOption) && this.selectedOption !== labelValue.code) {
          view.disableView();
        }
        view.$el.addClass("form-group containerView");
        if (view.$el.find(".input-group").length > 0) {
          view.$el.find(".input-group").after('<div class="' + view.$el.find('input').attr("class").split(" ").shift() + '-error-container"></div>');
        } else {
          $optionsDiv.append('<div class="' + view.$el.find('input').attr("class").split(" ").shift() + '-error-container"></div>');
        }
      }
    });
    this.$el.append($optionsDiv);
    return this;
  }

  getAdditionalViews(): { [key: string]: any } {
    return this.additionalRenderedViews;
  }

  getAdditionalView(code: any): { [key: string]: any } {
    return this.additionalRenderedViews[code];
  }

  manageAdditionalViewState(value: string): void {
    //disable old option
    this._disableAdditionalView(this.valueSelected);
    //enable new option
    this._enableAdditionalView(value);
    this.valueSelected = value;
  }

  setItem(code: string): void {
    const domEl = this.$el.find(String("input." + this.name));

    if (domEl.length > 0) {
      if (domEl.val() === code) {
        this.manageAdditionalViewState(code);
      }
      domEl.val([code]);
    }
  }

  /**
   * Manage the keypress events on the radio box.
   */
  keyEvent(e: JQueryKeyEventObject): boolean {
    let ret = true;
    let codeToFocus = "";
    const currentValue = $(e.currentTarget).find("input").val() as string;

    if (e.keyCode === 40) { // down 1 row
      ret = false;
    }
    if (e.keyCode === 39 || e.keyCode === 40) { // right or down
      codeToFocus = this.getNextRadioByValue(currentValue);
      ret = false;
    }
    if (e.keyCode === 37 || e.keyCode === 38) { // left or up
      codeToFocus = this.getPrevRadioByValue(currentValue);
      ret = false;
    }
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 113) { // enter, space or F2
      $(e.currentTarget).find("input").click();
      return false;
    }
    //Select objetive
    if (codeToFocus !== "") {
      $(e.currentTarget).parent().find('input[value="' + codeToFocus + '"]').focus();
    }
    return ret;
  }

  private getNextRadioByValue(currentValue: string): string {
    if (this.options && this.options.length > 0) {
      const currentIndex = this.options.indexOf(_.find(this.options, (option) => {
        return option.code === currentValue;
      }));

      if (currentIndex + 1 < this.options.length) {
        return String(this.options[currentIndex + 1].code);
      }
      return String(this.options[0].code);
    }
    return "";
  }

  private getPrevRadioByValue(currentValue: string): string {
    if (this.options && this.options.length > 0) {
      const currentIndex = this.options.indexOf(_.find(this.options, (option) => {
        return option.code === currentValue;
      }));

      if (currentIndex - 1 >= 0) {
        return String(this.options[currentIndex - 1].code);
      }
      return String(this.options[this.options.length - 1].code);
    }
    return "";
  }

  clean(): void {
    if (!CWSTR.isBlank(this.selectedOption)) {
      this.setItem(this.selectedOption)
    }
  }
}
