import * as Backbone from 'Backbone';
import _ from 'underscore';
import detailSelecteurRefDialogLanceetatTPL from '../cwDetailSelecteurRefDialog.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWCollabPopulationCollection } from '../models/cwCollabPopulation.collection';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Selecteur Referentiel Dialog View
 */
export class CWSelecteurRefDialogCollabPopulation extends CWBaseFormView {

  searchTerm: string;
  coll: CWCollabPopulationCollection;
  parent: any;
  table: CWDataGridView;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.className = "0 refDialogCollabPop";
    options.tagName = "div";
    options.searchTerm = "";
    super(options);
    this.searchTerm = "";
    this.template = detailSelecteurRefDialogLanceetatTPL;
    if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.coll)) {
      this.coll = options.coll;
    }
    this.parent = options.parent;
    // Create table
    this.table = this._initTable(options.currentSelection);
    this.table.model.on("change:value", this.selection, this);
    this.table.model.multiselectColl.on("add", this.selection, this);
    this.table.model.multiselectColl.on("remove", this.selection, this);
    this.table.model.multiselectColl.on("reset", this.selection, this);
  }

  _manageTable(): void {
    this.table.model.resetRowHeight();
    this.table.model.coll.applyFilter({ "search": this.searchTerm });
    this.table.model.coll.fetch({
      success: (): void => {
        this.parent.dialog.dialog("widget").css("display", "block");
        this.$el.find(".referentielhabr-selecteurref-table legend span").text(this.table.model.coll.totalRecords);
        this.$el.find(".ui-grid.c-grind__scroll").css("height", "200px");
        if (this.parent) {
          this.parent.setPosition({ my: "center", at: "center", of: window });
        }
      },
      error: (): void => {
        this.parent.dialog.dialog("widget").css("display", "block");
      }
    });
  }

  render(): CWSelecteurRefDialogCollabPopulation {
    const json = { 'i18n': i18n };

    this.$el.html(this.template(json));
    // Render table    
    this.parent.dialog.dialog("widget").css("display", "none");
    this.$el.find(".table_selecteurref_referentielhabr").html(this.table.render().el);
    this._manageTable();
    this.$el.find(".criteres").autocomplete({
      minLength: 0,
      source: (request: { [key: string]: any }): boolean => {
        this.searchTerm = request.term;
        this._manageTable();
        return false;
      }
    });
    this.$el.find(".habr-selecteur-view-size legend").hide(); //To hide title from the top
    if (this.parent.viewData.displayTitle !== true) {
      this.$el.find(".habr-selecteur-view-size legend").hide();
      this.$el.find(".phx-grid-header-scroll").hide();
      this.$el.find(".phx-grid-main-chooser").hide();
      this.$el.find(".phx-grid-scroll").css("margin-top", "38px");
    }
    return this;
  }

  selection(): any {
    const selection = this.table.getSelectedRows();

    this.trigger("response", selection);
    return selection;
  }

  _selectionAndClose(): void {
    this.trigger("close", "Y");
  }

  _initTable(currentSelection: Backbone.Collection): CWDataGridView {
    let table: CWDataGridView = null;
    let tableModel = null;
    const tableColumns: CWBaseGridColums[] = [{
      title: i18n.t('common:saisiemass.table_selectref_nom'), property: "nom", width: 4
    }, {
      title: i18n.t('common:saisiemass.table_selectref_prenom'), property: "prenom", width: 4
    }, {
      title: i18n.t('common:saisiemass.table_selectref_matric'), property: "matric", width: 4
    }];

    tableModel = new CWDataGridModel({
      coll: this.coll,
      enableSelectionOnDblclick: true
    });
    table = new CWDataGridView({
      id: this.parent.viewData.name + "_selecteurref_table",
      columns: tableColumns,
      model: tableModel,
      title: i18n.t('habr.legend_selectref_table'),
      showFilteredRowsInTitle: true,
      multiselection: this.parent.viewData.multiselection,
      gridHeightType: "statique",
      parentContainer: this.parent,
      paginatorPosition: "butom"
    });
    // table.width = 750;
    table.height = 250;
    if (this.parent.viewData.displayAllColumns === true) {
      table.setVisibleColumns(["nom", "prenom", "matric"]);
    } else {
      const visibleColumns: string[] = [];

      if (!CWSTR.isBlank(this.parent.viewData.displayColumns)) {
        _.each(this.parent.viewData.displayColumns, (item: string): void => {
          visibleColumns.push(item);
        });
      }
      table.setVisibleColumns(visibleColumns);
    }
    if (currentSelection && currentSelection instanceof Backbone.Collection) {
      currentSelection.forEach(function (element) {
        table.model.multiselectColl.add(element.get("attrs"), { "parse": true } as { [key: string]: any });
      });
    }
    return table;
  }
}
