import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseGridColums } from 'core/grids/basegrid/cwBaseGrid.view';
import { CWButtonBarView } from 'core/controls/cwButtonBar.view';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWDelegationsTableColl } from '../models/cwDelegationsTable.collection';
import { CWDelegueRenderView } from './cwDelegueRender.view';
import { CWDroitsdelWorkflow } from '../models/cwDroitsdel.workflow';
import { CWFilterContentView } from './cwFilterContent.view';
import { CWFilterModel } from '../models/cwFilter.model';
import { CWFilterMoreContentView } from './cwFilterMoreContent.view';
import { CWFilterView } from 'core/components/filter/cwFilter.view';
import { CWFormView } from './cwForm.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListeDetailPanelView } from 'core/layouts/cwListeDetailPanel.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';

export class CWGeneralView extends Backbone.View {
  /**
   * it sets the workflow pointer
   */


  /**
   * Constructor
   * It will paint all the tab for the UC.
   */
  parent: any;
  usecase: string
  workflow: any | CWDroitsdelWorkflow;
  ctxEcran: string;
  idButtonBar: string;
  layout: CWListeDetailPanelView;
  filter: CWFilterView;
  table: CWDataGridView;
  btnBar: CWButtonBarView;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.className = options.className || 'droitsdel';
    super(options);
    let utilCode = objs.appRt.workflow.authModel.get("user");//utilCode of the aplication
    let utilMatricule = null;
    let ctxPeriode = null;
    let form: CWFormView = null;

    this.parent = options.parent;
    this.usecase = options.usecase;
    if (options.utilMatricule) {
      utilCode = options.utilCode;
      utilMatricule = options.utilMatricule;
    }
    ctxPeriode = options.ctxPeriode;
    // Initializes the main parts of the UI and its workflow
    this.workflow = new CWDroitsdelWorkflow({
      utilCode: utilCode,
      ctxPeriode: ctxPeriode,
      module: this.usecase
    });
    this.workflow.setHabContext(new CWHabilitationContext({
      onglet: this.usecase,
      foncCour: options.habilitation,
      natGest: ""
    }));
    if (options.usecase === "suivicollab") {
      this.workflow.getHabContext().update({ foncCour: "RES_DELEG", natGest: "" });
      this.ctxEcran = "suivicollab";
      this.idButtonBar = "resp_droitsdel_buttonbar";
      //General view that gather all the other
      this.layout = new CWListeDetailPanelView({ position: 22, defaultOpeningSize: "33/66" });
    } else if (options.usecase === "utilisateur") {
      this.workflow.getHabContext().update({ foncCour: "ADM_DELEG", natGest: "" });
      this.ctxEcran = "utilisateur";
      this.idButtonBar = "adm_droitsdel_buttonbar";
      //General view that gather all the other
      this.layout = new CWListeDetailPanelView({ position: 22, defaultOpeningSize: "33/66" });
    } else {
      this.ctxEcran = "droitsdel";
      //Dans l'utilisation du menu transverse, l'habilitation ne doit pas dépendre de l'espace sélectionné (car c'est transverse) et 
      //ce menu permet de déléguer ses propres droits, donc on doit toujours envoyer l'habilitation COL_DELEG.
      this.workflow.getHabContext().update({ foncCour: "COL_DELEG", natGest: "" });
      if (CWSTR.isBlank(options.usecase)) {//zone transversal
        this.ctxEcran = "cw-appContainer";
        this.workflow.getHabContext().update({ "onglet": this.ctxEcran });
        this.workflow.$appendTo = options.appendTo;
      }
      this.idButtonBar = "coll_droitsdel_buttonbar";
      //General view that gather all the other
      this.layout = new CWListeDetailPanelView({ position: 28, defaultOpeningSize: "33/66" });
    }
    this.filter = this._initFilter();
    this.workflow.filterModel = this.filter.model;
    this.table = this._initTable();
    this.workflow.tableModel = this.table.model;
    this.btnBar = new CWButtonBarView({ id: this.idButtonBar, isHideOnDisabled: true });
    this.btnBar.model.setHabContext(this.workflow.getHabContext());
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.btnBar = this.btnBar;
    form = this._initForm();
    this.workflow.formModel = form.model;
    this.workflow.form = form;
    this.workflow.form.referentielRestriction(utilMatricule);
    (this.filter.viewPart2 as CWFilterMoreContentView).referentielRestriction(utilMatricule);
  }

  /**
   * Function to change the collaborateur, with the utilisateur joined
   */
  _changeCollab(utilCode: string, utilMatricule: string): void {
    this.workflow.utilCode = utilCode;

    this.workflow.form.referentielRestriction(utilMatricule);
    (this.filter.viewPart2 as CWFilterMoreContentView).referentielRestriction(utilMatricule);

    this.workflow._loadData(utilCode, () => {
      this.workflow.tableModel.selectFirstRow();
      this.workflow.isCreation = false;
    });
  }

  /**
   * Load the diferent elements in the layout
   */
  _initLayoutUC(callback: () => void): any {
    if (this.workflow.get("ready")) {
      callback();
    } else {
      this.workflow.setUp(() => {
        // When the workflow is ready, we can render the view without
        // concerns
        this.layout.$el.find(".l-panelA-entete").parent().remove();
        this.layout.$el.find(".l-panelA-filter").html(this.filter.render().el);
        //this renders the two date fields
        (this.filter.viewPart1 as CWFilterContentView).renderData();
        //this render the more filter view fields
        (this.filter.viewPart2 as CWFilterMoreContentView).renderData();
        this.layout.$el.find(".l-panelA-liste").html(this.table.el);
        this.table.render();
        this.layout.$el.find(".l-panelB-butomButtons").html(this.btnBar.render().el);
        this.$el.find(".leftContentPanel").addClass("l-panelContainer__panelbg");
        //get the habilitation in options
        //this.layout.$el.find(".phx-panelB2").html(this.navigationView.render().el);
        this.layout.$el.find(".l-panelB-entete").html(i18n.t('droitsdel.usecase'));
        this.layout.$el.find(".l-panelB-content").html(this.workflow.form.render().el);
        this.layout.$el.find(".l-panelB-content").resize();


        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        if (callback) {
          callback();
        }

        this.trigger("uc:ready");
      });
    }
  }

  /**
   * Renders the view to the form
   */
  render(): CWGeneralView {
    this.$el.html(this.layout.el);
    this.layout.render();
    if (CWSTR.isBlank(this.layout.getBarhandleColor())) {
      this.layout.setBarhandleColor("rgb(25,120,218)");
    }

    // allow auto height of dialog
    if (this.parent) {
      this.parent._setTitle(i18n.t('droitsdel.usecase_title'));
    }
    if (this.usecase !== "suivicollab") {
      this.renderView();
    }
    return this;
  }

  /**
   * Renders the view to the form
   */
  renderView(callback?: () => void): void {
    this._initLayoutUC(() => {
      const tableMd = this.workflow.tableModel;
      if (tableMd.coll.length > 0) {
        //If the WF have ctxPeriode and we have this periode select this periode if not select the first row
        if (this.workflow.ctxPeriode) {
          const model = _.find(tableMd.coll.models, (item: any) => {
            if (CWTYPE.DATE.strToDate(item.get("datedeb")) <= CWTYPE.DATE.strToDate(this.workflow.ctxPeriode.datedeb) &&
              CWTYPE.DATE.strToDate(item.get("datefin")) >= CWTYPE.DATE.strToDate(this.workflow.ctxPeriode.datefin)) {
              return item;
            }
            return null;
          });
          if (!CWSTR.isBlank(model)) {
            tableMd.trigger("row:click", model);
          } else {
            const firstRow = tableMd.coll.at(0);
            tableMd.trigger("row:click", firstRow);
          }
        } else {
          const firstRow = tableMd.coll.at(0);
          tableMd.trigger("row:click", firstRow);
        }
      } else {
        this.workflow.trigger("resetForm");
      }

      if (this.parent) {
        this.parent.resize();
      }
      //habilitations des boutons du menu selon l'écran
      if (this.ctxEcran === "cw-appContainer") { // appel depuis le menu transverse
        this.workflow.btnBar.habilitations("COL_DELEG");
      } else if (this.ctxEcran === "droitsdel") {
        this.workflow.btnBar.habilitations("COL_DELEG");
      } else if (this.ctxEcran === "suivicollab") {
        this.workflow.btnBar.habilitations("RES_DELEG");
      } else {
        this.workflow.btnBar.habilitations("ADM_DELEG");
      }

      if (callback) {
        callback();
      }
    });
  }

  /**
   * Creating the filter for the table
   */
  _initFilter(): CWFilterView {
    const model = new CWFilterModel({}, { "usecase": this.usecase });
    const part1View = new CWFilterContentView({ workflow: this.workflow, ctxEcran: this.ctxEcran, habilitation: this.workflow.getHabContext(), "model": model });
    const part2View = new CWFilterMoreContentView({ workflow: this.workflow, ctxEcran: this.ctxEcran, habilitation: this.workflow.getHabContext(), "model": model });
    const filter = new CWFilterView({
      id: "droitsdelfilter",
      viewPart1: part1View,
      viewPart2: part2View,
      buttonOnBottomFilterView: true,
      columns: 12,
      buttonOnMoreFilterView: true,
      iconeMoreCriteres: true,
      iconeMoreCriteresExpand: "cercle_fleche_bas",
      iconeMoreCriteresCollapse: "cercle_fleche_haut"
    });
    return filter;
  }

  /**
   * Creating the table that contains the data from the collection
   */
  _initTable(): CWDataGridView {
    const tableModel = new CWDataGridModel({
      coll: new CWDelegationsTableColl({}, { "usecase": this.usecase })
    });
    tableModel.coll.usecase = this.ctxEcran;

    const tblColumns: Array<CWBaseGridColums> = [
      { title: i18n.t('droitsdel.du'), property: "datedeb", width: 4 },
      { title: i18n.t('droitsdel.au'), property: "datefin", width: 4 },
      { title: i18n.t('droitsdel.delegues'), property: "delegue", width: 4 },
      { title: i18n.t('droitsdel.retro'), property: "retro", width: 2 }
    ];

    const table = new CWDataGridView({
      id: "droitsdel_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('droitsdel.usecase_title'),
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      parentContainer: this.parent,
      habilitations: this.workflow.getHabContext()
    });

    table.cellRenderers["datedeb"] = (model: any): any => {
      return CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE"));
    };

    table.cellRenderers["datefin"] = (model: any): any => {
      return CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE"));
    };

    table.cellRenderers["delegue"] = (model: any): any => {
      return new CWDelegueRenderView({ model: model, workflow: this.workflow });
    };

    table.cellRenderers["retro"] = (model: any): any => {
      // The same method than "delegue" to show title (infobulle)
      const resp = $("<span>");
      const text = CWTYPE.BOOLEAN.format(model.get("retro"));
      const title = i18n.t('droitsdel.retrocession_title');
      resp.text(text).attr("title", title);
      return resp;
    };

    table.setVisibleColumns(["datedeb", "datefin", "delegue"]);
    table.setSortableColumns(["datedeb", "datefin"]);

    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(this.workflow.getHabContext());
    tableModel.setHabContext(this.workflow.getHabContext());

    return table;
  }

  /**
   * Creating the form to the data
   */
  _initForm(): CWFormView {
    const form = new CWFormView({ id: "droitsdel_form", workflow: this.workflow, ctxEcran: this.ctxEcran, habilitation: this.workflow.getHabContext(), parentDialog: this.parent });
    return form;
  }

  /**
   * Creating the navigation view for the table
   */
  // _initNavigationView: function() {
  //   let page = new NavigationView({ text: i18n.t('droitsdel.usecase') });
  //   return page;
  // }

}
