import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCommonComboBoxItem from './cwComboBoxItem.tpl.html';


export interface CWComboBox2ResultItemViewOptions<TModel extends Backbone.Model = Backbone.Model> extends Backbone.ViewOptions<TModel> {
  label: string;
  model: any;
}


export class CWComboBox2ResultItemView extends Backbone.View<Backbone.Model> {

  data: { [key: string]: any };

  /**
   * Constructor
   * View underlying a List builder element
   */
  constructor(params?: CWComboBox2ResultItemViewOptions) {
    params.tagName = params.tagName || "span";
    params.className = params.className || "phx-list-builder-item ui-corner-all";
    params.events = _.extend({
      "click .phx-list-builder-item-icon": "_removeItem"
    }, params.events);
    super(params);
    this.template = TPLCommonComboBoxItem;
    this.data = {};
    this.data.label = "";
    if (params && params.label) {
      this.data.label = params.label;
    }
    if (params && params.model) {
      this.model = params.model;
    }
  }

  /**
   * Paints the view of List builder element
   */
  render(): CWComboBox2ResultItemView {
    $(this.el).html(this.template(this.data));
    return this;
  }

  /**
   * Removes the current element
   */
  _removeItem(event: JQueryEventObject): void {
    this.model.trigger("click:item", this.model, event);
  }
}