import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWEtatsColl } from './cwEtats.collection';
import { CWEtatsModel } from './cwEtats.model';
import { CWGeneralView } from '../../suvietat/views/cwGeneral.view';
import { CWHABILITATION } from 'src/utils';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMSGS } from 'src/utils/cwMsgs';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';

/**
 * Workflow of etats
 */
export class CWLanceetatWorkflow extends CWReadOnlyModel {
  defaultCriteresModel: CWEtatsModel;
  flatColl: CWEtatsColl<CWEtatsModel>;
  tableModel: any;
  btnBarModel: any;
  filterModel: any;
  formColl: any;
  formModel: any;
  headerModel: any;
  navigationModel: any;
  parametreDiversIdCollabModel: any;
  parametreDiversExportEtatModel: any;
  parametreDiversCalPrev: any;
  paramIdCollab: any;
  paramExportEtat: boolean;
  loc: any;
  formView: any;
  openedFromPlanning: any;
  openedFromPlanningCol: boolean;
  openedFromPlanningMed: boolean;
  preferencesPlanningPopulation: { popid: any; poptype: any; poplib: any };
  public $appendTo: JQuery; //pour les messages
  public module: string;


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.defaults = {
      "mode": "C"
    }
    super(attributes, options);
    this.module = "lanceetat";//parce que c'est transversal
    this.usecase = this.module;//parce que c'est transversal
    this.defaultCriteresModel = new CWEtatsModel();
    //Collection with the correct format to use the treegrid table
    this.flatColl = new CWEtatsColl();
    this.tableModel = null;
    this.btnBarModel = null;
    this.filterModel = null;
    this.formColl = null;
    this.formModel = null;
    this.headerModel = null;
    this.navigationModel = null;
    //parametre divers
    this.parametreDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.parametreDiversExportEtatModel = GLOBAL_DATA.paramDivers.get("IEXCEL");
    this.parametreDiversCalPrev = GLOBAL_DATA.paramDivers.get("CalPrev");
    this.paramIdCollab = this.parametreDiversIdCollabModel.get("valeur");
    this.paramExportEtat = this.parametreDiversExportEtatModel.get("valeur") === "1" ? true : false;
    /**
     * Model of the habilitation context for the UC
     */
    this.habContext = new CWHabilitationContext({
      onglet: this.module,
      foncCour: (objs.appRt.workflow.get("zone") === "coll" ? { "collab": "ETATSCOL.V" } : { "resp": "ETATS.V", "collab": "ETATSCOL.V" })
    });
    this.defaultCriteresModel.setHabContext(this.getHabContext());
    this.flatColl.setHabContext(this.getHabContext());
    if (options && options.loc) {
      this.loc = options.loc;
    }
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null; //null;//il doit s'ouvrir sans restrictions parce qu'il est transversal 
    if (this.$appendTo && this.$appendTo.length === 0) {
      this.$appendTo = null;
    }
    // ready = true when the setUp is finished. Just one setUp per life
    // cycle of the module
    this.set("ready", false);
  }

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp(callback?: (arg: any) => void): void {
    // Declare events consumers
    this.filterModel.on("search", this._filterTable, this);
    this.tableModel.on("change:value", this._tableValueChange, this);
    this.navigationModel.on("change:navigation", this._navigateRow, this);
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.formModel.on("form:edited", this._formEdited, this);
    this.btnBarModel.trigger("exportEtat", this.paramExportEtat);

    //If we are in usecase planning, make a previous call to store current planning criteres
    this._checkOpenedFromPlanning();
    if (this.openedFromPlanning) {
      let preferencesPlanning: { [key: string]: any } = null;

      if (this.loc && this.loc.uc && this.loc.uc === "planmed") {
        preferencesPlanning = objs.planmedRt.workflow.preferences.clone(); //méthode clone dans common/preferences.js
      } else {
        preferencesPlanning = objs.planrespRt.workflow.preferences.clone(); //méthode clone dans common/preferences.js
      }
      //Si les deux populations sont "tous les collaborateurs, on doit envoyer 0
      preferencesPlanning.temporaire = true;
      //update valeur de population, calques et periode du planning
      if (!CWSTR.isBlank(objs.populationMenu) && !CWSTR.isBlank(objs.populationMenu.model.get("ident"))) {
        //n'est pas "tous les collaborateurs"
        preferencesPlanning.set("popid", objs.populationMenu.model.get("ident"));
        preferencesPlanning.set("poptype", objs.populationMenu.model.get("type"));
        preferencesPlanning.set("poplib", objs.populationMenu.model.get("libelle"));
      } else {
        //Si les deux populations sont "tous les collaborateurs, on doit envoyer null
        preferencesPlanning.set("popid", 0);
        preferencesPlanning.set("poptype", "D");
        preferencesPlanning.set("poplib", i18n.t('populationsmenu.tous'));
      }
      //EVO 518
      if (parseInt(preferencesPlanning.getPreferenceValue("PL_PERIODE", "varint1") /*pertype*/, 10) !== 1) {
        const attrs = {
          varint1: 1, //pertype
          varchar2: "" //perunit
        };

        preferencesPlanning.setPreferenceValue("PL_PERIODE", attrs, { silent: true });
      }
      preferencesPlanning.setHabContext(new CWHabilitationContext({
        onglet: this.module,
        foncCour: "RES_GCOLLAB.G",
        natGest: ""
      }));
      preferencesPlanning.url = Configuration.restRoot + "/rest/planning/critere/temporaire";
      preferencesPlanning.save(null, {
        success: (): void => {
          this.preferencesPlanningPopulation = {
            popid: preferencesPlanning.get("popid"),
            poptype: preferencesPlanning.get("poptype"),
            poplib: preferencesPlanning.get("poplib")
          }
          this._loadData(callback);
        }
      });
    } else {
      this._loadData(callback);
    }

    this.set("panel", 0);
    this.set("ready", true);
  }

  _checkOpenedFromPlanning(): void {
    this.openedFromPlanning = false;

    const idUc = this.loc.zone + "_" + this.loc.uc;
    if (idUc === "resp_planresp") {
      this.openedFromPlanning = true;
      this.openedFromPlanningCol = true;
    } else if (idUc === "resp_planmed") {
      this.openedFromPlanningMed = true
      this.openedFromPlanning = true;
    }
  }

  _loadData(callback?: (arg?: any) => void): void {
    const coll = new CWEtatsColl();
    const habFoncCour: { [key: string]: any } = {};
    if (CWHABILITATION.canView("ETATSCOL.V")) {
      habFoncCour["collab"] = "ETATSCOL.V";
    }
    if (CWHABILITATION.canView("ETATS.V")) {
      habFoncCour["resp"] = "ETATS.V";
    }

    //collection fetch
    coll.setHabContext(new CWHabilitationContext({
      onglet: this.module,
      foncCour: habFoncCour,
      natGest: ""
    }));
    coll.fetch({
      success: (fresh: any): void => {
        this._arrayToCollection(fresh); //this.flatColl
        this.tableModel.coll.totalRecords = this.flatColl.length;
        this.tableModel.coll.reset(this.flatColl.models);
        this.trigger("checkFournisseur");
        if (this.loc) {
          if (callback) {
            callback((rowSelected: any): void => {
              const numEtat = this._getNumEtatByLoc();
              if (numEtat.split(",")[0] !== "undefined") {
                rowSelected = this.tableModel.coll.get(numEtat);
                this.tableModel.selectRow(rowSelected);
              } else {
                this.formView.empty(true);
              }
            });
          }
        } else if (callback) {
          callback();
        }
      }
    });
  }

  _getNumEtatByLoc(): string {
    const zone = this.loc.zone;
    const uc = this.loc.uc;
    const idUc = zone + "_" + uc;
    const array: { [key: string]: number } = {};
    let id: string = null;

    array["resp_suivicollab"] = 410; //Détail journée"
    array["resp_planresp"] = 1300;
    array["resp_planmed"] = 1500;
    array["resp_presabs"] = 260;
    array["resp_anomalie"] = 1170;
    array["resp_anovalor"] = 440;
    array["ref_categories"] = 330;
    array["ref_metiers"] = 20;
    array["ref_qualification"] = 20;
    array["ref_statut"] = 530;
    array["ref_grptravail"] = 420;
    array["ref_arrete"] = 310;
    array["ref_vacances"] = 270;
    array["ref_calendiv"] = 280;
    array["ref_motifsabs"] = 140;
    array["ref_horairej"] = 90;
    array["ref_proghoraire"] = 100;
    array["ref_cyclehor"] = 110;
    array["ref_motifsregul"] = 70;
    array["ref_motifanomalie"] = 160;
    array["ref_compteur"] = 300;
    array["ref_reglecalcul"] = 250;
    array["ref_calcprev"] = 340;
    array["ref_calcprevcompteur"] = 340;
    array["ref_calcprevanomalie"] = 340;
    array["ref_calcprevarrete"] = 340;
    array["ref_paramdivers"] = 50;
    array["ref_tablebase"] = 820;
    array["ref_codiftable"] = 810;
    array["adm_utilisateur"] = 450;
    array["adm_sitebadgeage"] = 40;
    array["adm_terminal"] = 380;
    array["adm_typeterm"] = 320;
    id = array[idUc] + ",";
    if (zone === "resp") {
      id += "EXPL";
    } else {
      id += zone.toUpperCase();
    }
    return id;
  }

  /**
   * Do an action when a row is selected in the grid.
   */
  _tableValueChange(model: CWBaseModel): void {
    const value = model.get("value");
    const id = (value === null) ? null : value.get("code");

    // Update Btn Bar and Form
    if (value === null) {
      this.headerModel.set("value", this.defaultCriteresModel);
      this.formColl.trigger("sync");
    } else {
      // Fetch the complete data of the model before notifying the form
      if (value.level !== 1) {
        this.formView.empty(false);
        this.set("mode", "R");

        const newHab = this.getHabContext().copy();
        newHab.update({ foncCour: value.get("fonction").code });
        this.formColl.setHabContext(newHab);
        this.formColl.thcollab = value.get("thcollab")
        this.formColl.id = id;
        this.formColl.set("souscriteres", []);

        let params = null;
        const numEtatSelected = this.tableModel.get("value").get("code");
        // If is opened since the planning and the edition selected is "planning par criteres"
        //differentiate between planmed & planresp because the criteres aren't the same
        if (this.openedFromPlanning) {
          if ((this.openedFromPlanningCol && numEtatSelected === 1300) || (this.openedFromPlanningMed && numEtatSelected === 1500)) {
            params = {
              headers: { "gfi-ecran": '"RES_PLANNING"' },
              success: (fresh: any): void => {
                this.formView.reinitModel(null, fresh.get("critid").get("defaut"), null, true);
              }
            };
          }
        }
        this.formColl.fetch(params);

        this.headerModel.set("value", value);
      } else {
        this.headerModel.set("value", this.defaultCriteresModel);
        this.formView.empty(true);
      }
    }
    this.btnBarModel.set("mode", "R");
    this.formModel.set("mode", "R");
  }

  _buttonAction(buttonId: string): void {
    switch (buttonId) {
      case "print": {
        if (!CWSTR.isBlank(this.tableModel.get("value"))) {
          this.formColl.trigger("validateColl", (model: { [key: string]: any }): void => {
            model.type = "pdf";
            model.thcollab = this.tableModel.get("value").get("thcollab");
            model.code = this.tableModel.get("value").get("code");

            model.setHabContext(this.getHabContext());
            model.updateHabContext({ foncCour: (objs.appRt.workflow.get("zone") === "coll" ? "ETATSCOL.V" : "ETATS.V") });
            model.removeTitleBirt();

            model.save(null, {
              success: (): void => {
                this.trigger("closePopup");
                const dialogSuvieEtat = new CWDialogPopupView({
                  view: CWGeneralView,
                  viewData: {
                    appendTo: this.$appendTo
                  }
                });
                this._dialogResize(dialogSuvieEtat);
                dialogSuvieEtat.open();
                this.btnBarModel.set("mode", "R");
                this.formModel.set("mode", "R");
                dialogSuvieEtat.model.on("dlg:close", (events: JQuery.TriggeredEvent): void => {
                  const $element = $(events.currentTarget);

                  if ($element.dialog("instance")) {
                    $element.dialog("destroy");
                    $element.remove();
                  }
                });
              },
              error: (fresh: any, response: { [key: string]: any }): void => {
                if (!_.isEmpty(response)) {
                  //Désactiver le comportement normal pour le montrer dans un popup (demandé dans SFD)
                  response.status = response.oldStatus;
                  response.responseText = response.oldResponseText;
                  CWMSGS.showError(response.responseJSON.message, null, this.$appendTo);
                }
              }
            });
          });
        }
        break;
      }
      case "export": {
        if (this.paramExportEtat === true && !CWSTR.isBlank(this.tableModel.get("value"))) {
          this.formColl.trigger("validateColl", (model: { [key: string]: any }): void => {
            model.type = "excel";
            model.code = this.tableModel.get("value").get("code");
            model.thcollab = this.tableModel.get("value").get("thcollab");
            model.setHabContext(this.getHabContext());
            model.updateHabContext({ foncCour: (objs.appRt.workflow.get("zone") === "coll" ? "ETATSCOL.V" : "ETATS.V") });
            model.removeTitleBirt();

            model.save(null, {
              success: (): void => {
                this.trigger("closePopup");
                const dialogSuvieEtat = new CWDialogPopupView({
                  view: CWGeneralView,
                  viewData: {
                    appendTo: this.$appendTo
                  }
                });
                this._dialogResize(dialogSuvieEtat);
                dialogSuvieEtat.open();
                this.btnBarModel.set("mode", "R");
                this.formModel.set("mode", "R");
                dialogSuvieEtat.model.on("dlg:close", (events: JQuery.TriggeredEvent): void => {
                  const $element = $(events.currentTarget);

                  if ($element.dialog("instance")) {
                    $element.dialog("destroy");
                    $element.remove();
                  }
                });
              },
              error: (fresh: any, response: { [key: string]: any }): void => {
                //Désactiver le comportement normal pour le montrer dans un popup (demandé dans SFD)
                response.status = response.oldStatus;
                response.responseText = response.oldResponseText;
                CWMSGS.showError(response.responseJSON.message, null, this.$appendTo);
              }
            });
          });
        }
        break;
      }
      case "revert": {
        this.formColl.id = this.tableModel.get("value").get("code");
        this.formColl.set("souscriteres", []);
        this.formColl.fetch();
        this.btnBarModel.set("mode", "R");
        break;
      }
      default:
      //none
    }
  }
  /**
   * Adapts dialog size to screen measurements
   * @param view 
   */
  _dialogResize(dialog: CWDialogPopupView): void {
    const screenSize = {
      width: $(window).width() < 1400 ? $(window).width() - 40 : 1400,
      height: $(window).height() < 800 ? $(window).height() - 20 : 800
    };

    dialog.setWidth(screenSize.width);
    dialog.setHeight(screenSize.height);
  }

  /**
   * Filters the grid by some fields.
   */
  _filterTable(filter: { [key: string]: any }): void {
    const coll = new CWEtatsColl(); //collection only for retrieve filtered data

    coll.applyFilter(filter);
    coll.setHabContext(this.getHabContext());
    coll.fetch({
      success: (fresh: any): void => {
        this.flatColl.reset();
        this._arrayToCollection(fresh);
        this.tableModel.coll.totalRecords = this.flatColl.length;
        this.tableModel.coll.reset(this.flatColl.models);
        if (this.flatColl.length > 0) {
          let finded = false;
          for (let i = 0; i < this.flatColl.length && finded === false; i++) {
            if (this.flatColl.at(i).level === 2) {
              finded = true;
              const row = this.tableModel.coll.at(i);
              row.trigger("row:click", row);
            }
          }
        } else {
          this.tableModel.set("value", null);
        }
      }
    });
  }

  _navigateRow(action: number): void {
    switch (action) {
      case 1: // navigate to first row (see NavigationView)
        this.tableModel.selectFirstRow();
        break;
      case 2: // navigate to prev row (see NavigationView)
        this.tableModel.selectPreviousRow();
        break;
      case 3: // navigate to next row (see NavigationView)
        this.tableModel.selectNextRow();
        break;
      case 4: // navigate to last row (see NavigationView)
        this.tableModel.selectLastRow();
        break;
      default:
      //none
    }
  }

  _arrayToCollection(collection: { [key: string]: any }): void {
    let numGroupes = 0;

    //level 1
    for (let i = 0; i < collection.length; i++) {
      const auxModel1 = collection.at(i);
      //atributes for tree table view
      auxModel1.isExpanded = true;
      auxModel1.level = 1;
      auxModel1.collapsible = true;
      auxModel1.branch = i;
      if (!_.isEmpty(auxModel1.get("definitions")) && auxModel1.get("definitions").length > 0) {
        auxModel1.hasChild = true;
        numGroupes++;
      } else {
        auxModel1.hasChild = false;
      }

      //level 2
      const arrayDefinitions = auxModel1.get("definitions");
      if (arrayDefinitions !== undefined) {
        this.flatColl.add(auxModel1);
        for (let j = 0; j < arrayDefinitions.length; j++) {
          const auxModel2 = arrayDefinitions[j];
          const model2 = new CWBaseModel(auxModel2);

          //atributes for tree table view
          (model2 as any).isExpanded = true;
          (model2 as any).level = 2;
          (model2 as any).collapsible = false;
          (model2 as any).branch = i;
          (model2 as any).hasChild = false;
          (model2 as any).id = model2.get("code") + "," + model2.get("theme");

          //Remove definitions 340,430 if calprev not activated
          if (!(this.parametreDiversCalPrev.get("valeur") === "0" && (model2.id === "340" || model2.id === "430"))) {
            this.flatColl.add(model2);
          }
        }
      }
    }
    this.flatColl.numGroupes = numGroupes;
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.formModel.set("mode", "E");
  }
}
