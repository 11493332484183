import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWRecapColl } from 'common/evenements/gerer/gererrecapitulatifs/models/cwRecap.collection';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';


/**
 * dabsences Model
 */
export class CWRecapModel extends CWBaseModel {
  selectedProfil: any;
  profilErrors: any;
  recEtatValid: any;
  nbrecEtatValid: number;
  objErrors: any;
  nObjErrors: any;

  public coll: CWRecapColl<CWRecapModel>;
  evtEtatValid: string;
  public individualComment: boolean;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
  }

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "recapitulatif": "",
      "libelle": "",
      "datedeb": "",
      "datefin": "",
      "droit": "",
      "profils": "",
      "commentaire": "",
      "collaborateur": {
        "nom": "",
        "prenom": "",
        "matric": 0,
        "matricAux": "",
        "dmaj": ""
      },
      "evenement": null,
      "indicateurpj": false,
      "piecesjointes": null,
      "etat": null,
      "statut": null,
      "nbevenement": null
    }
  }

  validate(/*attrs*/): { [key: string]: any } | null {
    const errors: { [key: string]: any } = {};

    if (CWSTR.isBlank(this.get("profils")[0]) || this.get("profils")[0].code === "0") {
      const profilError: string = i18n.t('common:required', { "0": i18n.t('dabsences.role') });
      const profilId: string = 'comboProfil_' + this.cid;
      errors[profilId] = profilError;
      this.profilErrors = profilError;
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.code !== undefined) {
      response.id = response.code;
    }

    return response;
  }
}
