import { i18n } from 'src/i18n.js';
import { CWLOG } from 'utils/cwLog';
import { CWMSGS } from 'utils/cwMsgs'; //on va utiliser MSGS pour les zones REF et ADM, et CWMSGS pour le reste (erreurs, zones COLL et RESP, etc), mais uniquement pour status equal "406"
import { objs } from 'src/objectsRepository';
import { CWSTR } from 'utils/cwStr';
import { UTILS } from 'utils/utils.js';
import { LoginTestConnModel } from 'core/components/login/loginTestConn.model.js';
import { MSGS } from 'utils/msgs.js'; //on va utiliser MSGS pour les zones REF et ADM, et CWMSGS pour le reste (erreurs, zones COLL et RESP, etc), mais uniquement pour status equal "406"

export let onError = function(e, xhr, options) {
  var customMsg = {};
  var status = !_.isEmpty(xhr) ? xhr.status : null;
  var lMsg = "";
  var lAppendTo = null;
  var $lPosMsgs = null;
  var positionMessage = function($posMsgs) {
    if ($posMsgs && $posMsgs.length > 0) {
      var idPopup = $posMsgs.attr("id");

      if (!CWSTR.isBlank(idPopup)) {
        var $container = ($posMsgs.parent().parent().length > 0) ? $posMsgs.parent().parent() : $("body");
        var $lPosPopups = $container.find(".ui-dialog[aria-describedby='" + idPopup + "']");

        if ($lPosPopups && $lPosPopups.length > 0) {
          var infoScreen = $lPosPopups.position();
          var widthContainer = screen.width - 100;
          var heightContainer = screen.height - 100;
          var isVisible = true;
          var widthPopup = 0;
          var heightPopup = 0;

          if ($lPosPopups.css("display") === "none") {
            $lPosPopups.css("display", "block");
            isVisible = false;
          }
          //Après la possibilité de display "none"->"block"
          widthPopup = ($lPosPopups.width() > 500) ? $lPosPopups.width() : 500;
          heightPopup = ($lPosPopups.height() > 200) ? $lPosPopups.height() : 200;
          if (infoScreen.top <= 0 || infoScreen.left <= 0 || (infoScreen.top + heightPopup) >= heightContainer || (infoScreen.left + widthPopup) >= widthContainer) {
            var repositionner = true;

            if (!isVisible) {
              $lPosPopups.css("height", "100%");
              heightPopup = ($lPosPopups.height() > 0) ? $lPosPopups.height() : 200;
            }
            if ($lPosPopups.css("position") === "relative") {
              $lPosPopups[0].style.removeProperty("position");
              if (infoScreen.top + heightPopup <= heightContainer && infoScreen.left + widthPopup <= widthContainer) {
                repositionner = false;
              }
            }
            if (repositionner) {
              var $info = $posMsgs.children(":first");
              var $containerPopup = ($container.parents('.phx-proto-resizable:first').length > 0) ? $container.parents('.phx-proto-resizable:first') : $("body");
              var widthFinal = $containerPopup.width() > widthPopup ? $containerPopup.width() : screen.width;
              var heightFinal = $containerPopup.height() > heightPopup ? $containerPopup.height() : screen.height;
              var objCss = {
                "left": ((widthFinal - widthPopup) / 2) + "px",
                "top": (((heightFinal - heightPopup) / 2) - 20) + "px"
              };
              var recalWidth = false;
              var recalHeight = false;

              if (!isVisible) {
                objCss.width = widthPopup + "px";
                objCss.height = "auto";
              }
              if (((widthFinal - widthPopup) / 2) <= 0) {
                objCss.left = ((widthFinal - widthContainer) / 2) + "px";
                if (!isVisible) {
                  $info.css({ "max-width": (widthContainer - 20) + "px", "overflow": "auto" });
                  objCss.width = widthContainer + "px";
                }
                recalWidth = true;
              } else if (!isVisible) {
                $info.css({ "max-width": (widthPopup - 20) + "px", "overflow": "auto" });
              }
              if (((heightFinal - heightPopup) / 2) <= 0) {
                objCss.top = (((heightFinal - heightContainer) / 2) - 20) + "px";
                if (!isVisible) {
                  $info.css({ "max-height": (heightContainer * 0.29) + "px", "overflow": "auto" });
                }
                recalHeight = true;
              } else if (!isVisible) {
                const tmpMaxHeight = ((heightPopup <= 200) ? 200 : heightPopup) * 0.29;

                $info.css({ "max-height": tmpMaxHeight + "px", "overflow": "auto" });
              }
              if ($containerPopup === $("body")) {
                if (!recalWidth) {
                  objCss.left = ((screen.width - widthPopup) / 2) + "px";
                }
                if (!recalHeight) {
                  objCss.top = (((screen.height - heightPopup) / 2) - 20) + "px";
                }
              } else {
                if (!recalWidth) {
                  if (widthFinal === screen.width) {
                    objCss.left = (((screen.width - widthPopup) / 2) - 200) + "px";
                  }
                }
                if (!recalHeight) {
                  if (heightFinal === screen.height) {
                    objCss.top = (((screen.height - heightPopup) / 2) - 100) + "px";
                  }
                }
              }
              $lPosPopups.css(objCss);
            }
          }
        }
      }
    }
  };

  if (xhr.omit === true) {
    return;
  }
  if (window.location.pathname.indexOf("login.html") === -1 && objs && objs.appRt.workflow && objs.appRt.blocked === true) {
    //Prevent app of being blocked if there is an error
    objs.appRt.workflow.trigger("unblock:app");
  }
  if (options.headers && !CWSTR.isBlank(options.headers["gfi-context"]) && xhr.sansAppendTo !== true) {
    var lOnglet = options.headers["gfi-context"].split(",")[0].split(":");
    var valOnglet = (lOnglet.length > 0) ? lOnglet[1].replaceAll('"', "").trim() : null;

    if (!CWSTR.isBlank(valOnglet) && valOnglet !== "password") {
      lAppendTo = $("#" + valOnglet);

      if (!lAppendTo || lAppendTo.length === 0) {
        lAppendTo = null;
      }
    }
  }
  try {
    CWLOG.error("ERROR Ajax : " + JSON.stringify(xhr));
  } catch (e) {
    try {
      CWLOG.error("ERROR Ajax : " + JSON.stringify(_.omit(xhr, "responseXML")));
    } catch (e1) {
      CWLOG.error("ERROR Ajax : unknown");
    }
  }
  try {
    if (xhr.responseText) {
      customMsg = JSON.parse(xhr.responseText);
    } else {
      customMsg = e.responseData || {};
    }
  } catch (error) {
    customMsg = {};
  }
  if (status === 200 && _.isEmpty(customMsg)) {
    // It is a correct response, but empty, without results.
    return;
  }
  if (status === 0 || status > 12000) {
    if (window.location.pathname.indexOf("login.html") === -1) {
      var testConnectionModel = new LoginTestConnModel();

      testConnectionModel.fetch({
        timeout: testConnectionModel.timeout,
        error: function() {
          if (UTILS._isLoged() === true) {
            //Forbidden. Security issue, redirect to login page
            $lPosMsgs = CWMSGS.showWarning(i18n.t('common:sesionExpire'), UTILS._logear, null, null, lAppendTo);
            positionMessage($lPosMsgs);
          } else {
            UTILS._logear();
          }
        },
        success: function() {
          //Nothing
        }
      });
    }
  }
  if (status === 403 || status === 440) {
    if (customMsg && customMsg.message) {
      $lPosMsgs = CWMSGS.showError(customMsg.message, null, lAppendTo);
      positionMessage($lPosMsgs);
    } else {
      if (UTILS._isLoged() === true) {
        //Forbidden. Security issue, redirect to login page
        if (objs.appRt.workflow.authModel) {
          objs.appRt.workflow.authModel.clear();
        }
        $lPosMsgs = CWMSGS.showWarning(i18n.t('common:sesionExpire'), UTILS._logear, null, null, lAppendTo);
        if (!CWSTR.isBlank($lPosMsgs) && $lPosMsgs.parent() && $lPosMsgs.parent().parent() && $lPosMsgs.parent().parent().find(".ui-dialog").length > 0) {
          //on le donne un "z-index" plus grand quel utilisé dans le "hidder"->1000
          $lPosMsgs.parent().parent().find(".ui-dialog").css("z-index", "1001");
        }
        positionMessage($lPosMsgs);
      } else {
        UTILS._logear();
      }
    }
    return;
  }
  if (status === 404) {
    if (options.url.indexOf("/tpl/") !== -1) {
      throw new Error("Error 404 on getting missing templates: " + options.url + "\nError code:(LoadingUCException)");
    } else if (options.url.indexOf(".js") !== -1) {
      throw new Error("Error 404 on getting wrong js file: " + options.url + "\nError code:(LoadingUCException)");
    } else {
      lMsg = i18n.t(['messages:GT_1316', 'common:server_error'], { "1": xhr.status + ": " + xhr.statusText });
      $lPosMsgs = CWMSGS.showError(lMsg, null, lAppendTo);
      positionMessage($lPosMsgs);
    }
    return;
  }
  if (status === 410) {
    //Error already treated by a component
    return;
  }
  //No right enough to access to a webservice
  //Security tag is missing
  //XSS intrusion
  //Jeton erreur
  if ((status === 550 && CWSTR.isBlank(customMsg.message)) || status === 551 || status === 552 || status === 553) {
    $lPosMsgs = CWMSGS.showError(i18n.t('common:security', { '0': status }), null, lAppendTo);
    positionMessage($lPosMsgs);
  } else if (status === 550 || status === 406 || status === 500) {
    var OBJMESSAGE = CWMSGS; //on va utiliser MSGS pour les zones REF et ADM, et CWMSGS pour le reste (erreurs, zones COLL et RESP, etc), mais uniquement pour status equal "406"

    if (status === 406 && !_.isEmpty(options) && options.headers && !_.isEmpty(options.headers["gfi-context"]) && objs.appRt && objs.appRt.workflow && objs.appRt.workflow.MODULES_ZONES) {
      var usecase = JSON.parse(options.headers["gfi-context"]).onglet;

      if (!CWSTR.isBlank(usecase) && !CWSTR.isBlank(objs.appRt.workflow.MODULES_ZONES[usecase.split("/")[0]])) {
        var zoneUsecase = objs.appRt.workflow.MODULES_ZONES[usecase.split("/")[0]][0];

        if (zoneUsecase === "ref" || zoneUsecase === "adm") {
          OBJMESSAGE = MSGS;
        }
      }
    }
    // spécial case with grid column ordering
    if (customMsg.message === "-904") {
      customMsg.message = "Tri non disponible";
    }
    if (!CWSTR.isBlank(customMsg.titre) && !CWSTR.isBlank(customMsg.message)) {
      // notification message returned by server that needs processing...
      $lPosMsgs = OBJMESSAGE.showNotification(customMsg, function(result) {
        switch (result) {
          case "ABORT":
            break;
          case "CONTINUE":
            options.success();
            break;
          default:
            options.url = Configuration.restRoot + "/rest/" + result;
            Backbone.ajax(options);
        }
      }, options, lAppendTo);
    } else {
      if (CWSTR.isBlank(customMsg.message)) {
        $lPosMsgs = OBJMESSAGE.showError("ERROR : " + xhr.status + ": " + xhr.statusText, null, lAppendTo);
      } else {
        $lPosMsgs = OBJMESSAGE.showError(customMsg.message, null, lAppendTo);
      }
    }
    if (!CWSTR.isBlank($lPosMsgs) && $lPosMsgs.parent() && $lPosMsgs.parent().parent() && $lPosMsgs.parent().parent().find(".ui-dialog").length > 0) {
      var $lPosPopups = $lPosMsgs.parent().parent().find(".ui-dialog");
      var lMax = _.max(_.map($lPosPopups, function(item) { return (!CWSTR.isBlank($(item).css("z-index")) ? parseInt($(item).css("z-index"), 10) : 100); }));
      var $lVolet = $("body").find(".cw-volet.ui-dialog");

      //on doit rechercher s'il y a un "volet" ouvert et il sera la valeur maximum de "z-index"
      if ($lVolet && $lVolet.length > 0 && $lVolet.is(":visible")) { //$lVolet.is(":visible")->s'il y en a plusieurs, il nous dira s'il y a actif un
        var zindexVolet = $lVolet.css("z-index"); //s'il y en a plusieurs, il nous dira la valeur 'z-indez' plus grande

        if (!CWSTR.isBlank(zindexVolet) && (CWSTR.isBlank(lMax) || lMax < zindexVolet)) {
          lMax = zindexVolet;
        }
      }
      if (!CWSTR.isBlank(lMax)) {
        var layerModal = (!CWSTR.isBlank(lAppendTo)) ? lAppendTo.find(".ui-widget-overlay.ui-front") : $(document).find(".ui-widget-overlay.ui-front");

        //on le donne un "z-index" un moins grand quel utilisé dans le "hidder"->1000 et plus grand quel utilisé dans les pop-ups modal 100,101,..
        if (layerModal && layerModal.length > 0) {
          if (layerModal.length >= 1) {
            //uniquement le dernier est incrémenté dans la même zone
            $(layerModal[(layerModal.length - 1)]).css("z-index", ++lMax);
          } else {
            layerModal.css("z-index", ++lMax);
          }
        }
        $lPosMsgs.parent().css("z-index", ++lMax);
      }
    }
    positionMessage($lPosMsgs);
  } else if (customMsg.errorBoMsg) {
    lMsg = i18n.t('common:server_error', { '1': "\n" + customMsg.errorBoMsg });
    $lPosMsgs = CWMSGS.showError(lMsg, null, lAppendTo);
    positionMessage($lPosMsgs);
  } else if (customMsg.errorMessage) {
    lMsg = i18n.t('common:server_error', { '1': "\n" + xhr.status + " / " + customMsg.errorMessage });
    $lPosMsgs = CWMSGS.showError(lMsg, null, lAppendTo);
    positionMessage($lPosMsgs);
  } else if (status >= 400 && status <= 599) {
    lMsg = i18n.t(['messages:GL_1068', 'common:server_error'], { '1': xhr.status + " (" + xhr.statusText + ")" });
    $lPosMsgs = CWMSGS.showError(lMsg, null, lAppendTo);
    if (status === 503 && Configuration && Configuration.development === true && options.url && options.url.indexOf("session") > 0) {
      const styleTexte = { "font-family": "font-opensans-semibold", "font-size": "1.4em", "color": "#4c515d" };
      const styleTitle = { "display": "flex", "font-size": "2.29rem" };

      //correction de l'affichege de l'érreur 503, uniquement en mode développement (localhost), lorsque le serveur n'est pas actif
      $lPosMsgs.find("button").hide();
      $lPosMsgs.parent().find(".ui-dialog-title.cw-texteTitrePopup").css(styleTitle);
      $lPosMsgs.parent().find(".c-cwDialogPopup__close.ui-dialog-titlebar-close.cw-icon-16").css("display", "none");
      $lPosMsgs.find(".c-cwDialogPopup__body.cw-texteNormal").css(styleTexte);
    }
    positionMessage($lPosMsgs);
  } else {
    // status = 0 or status = 12029 (IE9)
    CWLOG.error("ERROR : " + xhr.status + ": " + xhr.statusText);
  }
};
