import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLPlanrespDialogPretsRefresh from '../cwDialogPretsRefresh.tpl.html';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWPlanrespWorkflow } from '../models/cwPlanresp.workflow';
import { i18n } from 'src/i18n.js';

export class CWPretsRefreshPlanningDialog extends Backbone.View {

  workflow: CWPlanrespWorkflow;
  fresh: any;
  parent: CWDialogPopupView;
  closeCallback: (optionBtn?: { [key: string]: any }) => any;//options.refreshPlanAll(options de la popup, Y->true, N->false)

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .btnConfirm": "_manageYes",
      "click .btnCancel": "_manageNo"
    })
    super(options);
    this.template = TPLPlanrespDialogPretsRefresh;
    this.workflow = options.workflow;
    this.fresh = options.fresh;
    this.closeCallback = options.closeCallback;
    this.parent = options.parent;
    if (this.parent) {
      this.parent._setTitle(i18n.t('plans.planresp.affichage_prets_title'));
    }
    this.model = new Backbone.Model();
  }

  /**
   * Clicked on Yes Button
   * Refreshes planning
   */
  _manageYes(event?: JQuery.TriggeredEvent): void {
    const options = { "refreshPlanAll": true };//options.refreshPlanAll(options de la popup, Y->true, N->false)

    if (this.$el.find(".neplus").prop("checked") === true) {
      this.workflow.affRefPrets = true;
    }
    this.workflow.trigger("refresh:PlanningFromPopup", true);
    this.parent.close(event, options);
  }

  /**
   * Clicked on No Button
   * When clicked no, no action done
   */
  _manageNo(event?: JQuery.TriggeredEvent): void {
    const options = { "refreshPlanAll": false };//options.refreshPlanAll(options de la popup, Y->true, N->false)

    if (this.$el && this.$el.find(".neplus").prop("checked") === true) {
      this.workflow.affRefPrets = false;
    }
    if (this.closeCallback) {
      this.closeCallback(options);
      this.parent.close();
    } else {
      this.parent.close(event, options);
    }
  }

  render(): CWPretsRefreshPlanningDialog {
    const json = { i18n: i18n };

    this.$el.html(this.template(json));
    this.$el.find(".message_question").html(i18n.t('messages:PLH_124'));

    return this;
  }
}