import { CWBaseModel } from 'core/models/cwBase.model';
import { objs } from 'src/objectsRepository';

/* WS added for EVO 512
 * supprimerpreferences
 */
export class CWSupprimerPreferenceModel extends CWBaseModel {

  habilitationV: string;
  habilitationG: string;
  usecase: string;
  contextCollab: any;
  contextResp: any;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    options = options || {};
    super(attributes, options);
    this.habilitationV = "PER_PERSOGEN.M";
    this.habilitationG = "PER_PERSOGEN.M";
    this.usecase = options.usecase || objs.appRt.workflow.get("usecase");
    this.contextCollab = 0;
    this.contextResp = 0;
    if (options.contextCollab) {
      this.contextCollab = options.contextCollab;
    }
    if (options.contextResp) {
      this.contextResp = options.contextResp;
    }
    this.urlRoot = (): string => {
      const contextSuppression = (jQuery.isNumeric(this.contextCollab) ? this.contextCollab : 0) + (jQuery.isNumeric(this.contextResp) ? this.contextResp : 0);

      return Configuration.restRoot + "/rest/agenda/preference/" + contextSuppression;
    }
  }
}
