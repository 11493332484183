module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="droitsdel-detail-panel cw-fieldset">\n  <div class="phx-formTable">\n    <div class="form-group phx-formErrors ui-state-error"></div>\n    <div class="form-row col-12" style="margin-top: 1em">\n      <div class="form-group col-12">\n          <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("droitsdel.datedeb")))==null?'':__t)+
'</label>\n          <div class="c-date-selector input-group">\n              <div class="input-group-prepend">\n                  <div for="datedeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:droits.coldu")))==null?'':__t)+
'</div>\n              </div>\n              <input type="text" class="datedeb typeDate periodStart form-control" periodId="1" value="" size="15" />\n          </div>\n          <span class="datedeb-error-container"></span>\n      </div>\n    </div>\n\n    <div class="form-row col-12">\n      <div class="form-group col-12">\n          <label for="datefin" class="sr-only">'+
((__t=(i18n.t("droitsdel.datefin")))==null?'':__t)+
'</label>\n          <div class="c-date-selector input-group">\n              <div class="input-group-prepend">\n                  <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:droits.colau")))==null?'':__t)+
'</div>\n              </div>\n              <input type="text" class="datefin typeDate periodEnd form-control" periodId="1" value="" size="15" />\n          </div>\n          <span class="datefin-error-container"></span>\n      </div>\n    </div>\n</fieldset>\n';
}
return __p;
};
