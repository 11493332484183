import { STR } from 'utils/str.js';

export var TreeModel = Backbone.Model.extend({

  /**
   * Event when choose a Node with double click.
   */
  /**
   * Event when select a Node with the Ctrl Key pressed.
   */
  /**
   * Event when select a Node.
   */

  defaults: {
    label: "",
    maxLevels: 0
  },

  /**
   * Model of the Tree Component
   *
   */
  initialize: function(params) {
    params = params || {};
    this.coll = params.coll;
    this.set("label", params.name);
    this.draggable = (params.dragable === true);
    this.rootModel = params.rootModel;
    if (params.manyRoots === true) {
      this.manyRoots = true;
      if (params.firstLevelModels) {
        this.firstLevelModels = params.firstLevelModels;
      }
      if (!STR.isBlank(this.rootModel) && STR.isBlank(this.rootModel.firstLevelColl)) {
        throw new Error("You must inform a firstLevelColl in order to use a fictitious Root : ");
      }
    }
    this.buildUrlColl = params.buildUrlColl;
    this.dragAndDropCallback = params.dragAndDropCallback;
    this.renderer = params.renderer;
    this.tooltipRenderer = params.tooltipRenderer;
    this.overLabelTooltipRenderer = params.overLabelTooltipRenderer;
    this.checkClass = params.checkClass;
    this.selectableNode = params.selectableNode;
  }
});
