import { CWLOG } from 'utils/cwLog';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';

/**
 * Model for loading the Domaines d'activites hierarchie and structure
 */
export class CWActiviteDetailModel extends CWReadOnlyModel {

  public actCode: string;
  public isDragAndDrop: boolean;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "saisie_typedef": {
        "code": "D",
        "libelle": ""
      },
      "saisie_dateunitedef": {
        "code": "",
        "libelle": null
      },
      "unites": [{
        "libelle": "",
        "ordre": null,
        "code": "",
        "debperiodeauto": true,
        "finperiodeauto": true,
        "autorise": false
      }],
      "modesaisies": [{
        "ordre": null,
        "code": "",
        "libelle2": "",
        "libelle1": "",
        "autorise": false
      }],
      "saisie_repauto": true,
      //new
      "dat_hdebdef": null,
      "dat_hfindef": null,
      "dat_dureedef": null,
      "dat_pourcentdef": null,
      "dat_presdefav": false,
      "dat_presdefap": false,
      //end new
      "saisie_modesaidatedef": {
        "libellecourt": null,
        "code": ""
      },
      "saisie_perhrs": true,
      "saisie_perhpres": true,
      "saisie_perunite": true,
      "saisie_debunitedef": {
        "code": "",
        "libelle": null
      },
      "saisie_finunitedef": {
        "code": "",
        "libelle": null
      },
      "presence": false,
      "horspresence": false,
      "typehorspresence": "E",
      //new fields
      "per_hdebdef": null,
      "per_hfindef": null,
      "valdef_impose": false,
      "saisie_debperiodedef": {
        "code": "",
        "libelle": null
      },
      "saisie_finperiodedef": {
        "code": "",
        "libelle": null
      },
      //end new fields
      "validation": {
        "wkf_activ_p": true,
        "wkf_grpevtprev": {
          "code": "",
          "libelle": ""
        }, //Facultatif
        "wkf_activ_r": true,
        "wkf_grpevtreal": {
          "code": "",
          "libelle": ""
        } //Facultatif
      }
    }
  }

  constructor(param?: { [key: string]: any }) {
    param = param || {};
    super(param)
    if (!param.actCode) {
      CWLOG.error("this model required an activite code (actCode) to work!!");
    }
    this.actCode = param.actCode;
    this.isDragAndDrop = false;
    this.urlRoot = (): string => {
      if (this.isDragAndDrop) {
        this.isDragAndDrop = false;
        return Configuration.restRoot + "/rest/activite/definition/" + encodeURIComponent(this.actCode);
      }
      return Configuration.restRoot + "/rest/activite/definition/" + encodeURIComponent(this.actCode) + "/caracteristique";
    }
  }

}
