import _ from 'underscore';
import TPLCommonTableActionbar from './cwTable_actionbar.tpl.html';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { Model, View, ViewOptions } from 'Backbone';
import { UTILS } from 'utils/utils.js';


export interface ButtonStructure {
  buttonText: string;
  buttonAction: string;
  hasMenu: boolean;
  menuOptions?: { [key: string]: any };
  title?: string;
  icon?: string;
}

export class CWActionBarView extends View<CWReadOnlyModel> {
  /**
   * Event launched when the model of this view changes its mode
   */
  /**
   * Event launched when the model of this view changes the property enabled
   */
  /**
   * Event launched when the model of this view changes the property rowOrdering
   */
  /**
   * Event launched when there is any event for the model
   */
  /**
   * Event launched when a button is clicked
   */
  /**
   * Event launched when a ui-icon is clicked
   */
  /**
   * Event launched when monter button is clicked
   */
  /**
   * Event launched when descendre button is clicked
   */
  /**
   * Event launched when placer button is clicked
   */
  /**
   * Event launched when premier button is clicked
   */
  /**
   * Add this to have Outline
   *
   */

  /**
   * Constructor
   * Grid bar action View.
   * It's model supports:
   * Events: change:mode, btn:click (buttonId)
   */
  private isEnabled: boolean;
  private disableArrows: boolean;
  public buttons: { [key: string]: any };
  public newButtons: Array<ButtonStructure>;
  private positionNewButtonsOnLeft: boolean;
  private buttonsTitle: { [key: string]: any };
  private detachedBtns: any;
  private showDeleteHead: any;
  moveInButton: boolean;

  /**
       * Definition of Jquery events managed by the view
       */
  events(): { [key: string]: any } {
    return {
      "click .btn": "_clickListener",
      "click .cw-actionBar__btnOption:not(.btn)": "_clickListener",
      "click .cw-actionBar__btnMenu": "_toggleMenu",
      "click .monter:not(.btn)": "_clickListener",
      "click .descendre:not(.btn)": "_clickListener",
      "click .placer:not(.btn)": "_clickListener",
      "click .premier:not(.btn)": "_clickListener",
      "keydown": "_keyEvent"
    }
  }

  constructor(options: ViewOptions<Model> | any) {
    super(options);

    this.template = TPLCommonTableActionbar;

    //For simple models it's not needed to create a specific model
    this.model = new CWReadOnlyModel({
      mode: "", // E:edition, C:nothing selected, R:row selected
      enabled: true,
      rowOrdering: false
    });
    this.model.on("change:mode", this._manageMode, this);
    this.model.on("change:enabled", this._manageEnabled, this);
    this.model.on("change:rowOrdering", this._manageRowOrdering, this);
    /**
     * Wether all buttons in this view are enabled or not
     */
    this.isEnabled = true;
    /**
     * Wether arrows to move rows are enabled or not
     */
    this.disableArrows = !this.model.get("rowOrdering"); //To manage the ability to click on the arrows
    /**
     * Object that represent all the properties for each button
     */
    this.buttons = {};
    this.buttons.habilitation = [];
    this.buttons.visible = [];
    this.buttons.override = [];
    this.buttons.custom = [];
    this.buttons.visibleInModeC = []; // buttons added manually that are visible when nothing is selected
    // rights set by habilitations
    this.buttons.habilitation["delete"] = true;
    this.buttons.habilitation["copy"] = true;
    this.buttons.habilitation["new"] = true;
    this.buttons.habilitation["moveUp"] = true;
    this.buttons.habilitation["moveDown"] = true;
    this.buttons.habilitation["movePlacer"] = true;
    this.buttons.habilitation["movePremier"] = true;
    // rights set by UC to indicate if button is visible or not
    this.buttons.visible["delete"] = true;
    this.buttons.visible["copy"] = true;
    this.buttons.visible["new"] = true;
    // rights set to indicate if a different behavior than the default
    this.buttons.override["delete"] = false;
    this.buttons.override["copy"] = false;
    this.buttons.override["new"] = false;
    this.detachedBtns = {};
    /**
     * External buttons
     */
    this.newButtons = [];
    if (options) {
      this.positionNewButtonsOnLeft = options.positionNewButtonsOnLeft || false;
      this.moveInButton = options.moveInButton || false;
      this.showDeleteHead = options.showDeleteHead || false;
    }
    /**
     *  Object that represent all the titles of infobulle for each button
     * */
    this.buttonsTitle = {};
    // Delegate the events row:* to the coll
    this.model.on("all", (eventName: string) => {
      if (_.indexOf(eventName, ":") > 0) {
        const eventParts = eventName.split(":");
        const event = eventParts[0];
        const className = eventParts[1];
        switch (event) {
          case "hide":
            this.buttons.visible[className] = false;
            break;
          case "show":
            this.buttons.visible[className] = true;
            break;
          case "hab.hide":
            if (className === "all") {
              this.buttons.habilitation["delete"] = false;
              this.buttons.habilitation["copy"] = false;
              this.buttons.habilitation["new"] = false;
              this.buttons.habilitation["moveUp"] = false;
              this.buttons.habilitation["moveDown"] = false;
              this.buttons.habilitation["movePlacer"] = false;
              this.buttons.habilitation["movePremier"] = false;

              this._showRowOrderingArrows(false);
            } else {
              this.buttons.habilitation[className] = false;
            }
            break;
          case "hab.show":
            if (className === "all") {
              this.buttons.habilitation["delete"] = true;
              this.buttons.habilitation["copy"] = true;
              this.buttons.habilitation["new"] = true;
              this.buttons.habilitation["moveUp"] = true;
              this.buttons.habilitation["moveDown"] = true;
              this.buttons.habilitation["movePlacer"] = true;
              this.buttons.habilitation["movePremier"] = true;
              this._showRowOrderingArrows(true);
            } else {
              this.buttons.habilitation[className] = true;
            }
            break;
          case "override.hide":
            this.buttons.override[className] = false;
            break;
          case "override.show":
            this.buttons.override[className] = true;
            break;
          case "custom.hide":
            this.buttons.custom[className] = false;
            break;
          case "custom.show":
            this.buttons.custom[className] = true;
            break;
          case "moved":
            if (className === "hide") {
              this._refreshMoveLineButtons(true, true);
            } else if (className === "top") {
              this._refreshMoveLineButtons(true, false);
            } else if (className === "bottom") {
              this._refreshMoveLineButtons(false, true);
            } else if (className === "enableArrows") {
              this.disableArrows = false;
            } else if (className === "disableArrows") {
              this.disableArrows = true;
            } else {
              this._refreshMoveLineButtons(false, false);
            }
            break;
          default:
            break;
        }
        this._manageMode();
      }
    }, this);

    this.listenTo(this.model, "check:rowOrdering", this._initializeRowOrdering);
  }

  /**
* Shows/Hides menu with many options
*/
  _toggleMenu(event: JQueryMouseEventObject): void {
    if ($(event.currentTarget).parent().hasClass("new-menu")) {
      this._toggleNewMenu(event);
    } else {
      this._toggleExternalMenu(event, $(event.currentTarget)[0].className.split(" ")[0].replace("-menu", ""));
    }
  }

  /**
* Shows/Hides menu with many options
*/
  _toggleNewMenu(event: JQueryMouseEventObject): any {

    if (!$(this.el).find(".new-menu .ui-icon").is(":visible")) {
      if (this.isEnabled) {
        const showCopyMenu = this.buttons.habilitation["copy"] && this.buttons.visible["copy"];
        const showNewMenu = this.buttons.habilitation["new"] && this.buttons.visible["new"];
        if (showCopyMenu && !showNewMenu) {
          this.model.trigger("btn:click", "copy");
        } else {
          this.model.trigger("btn:click", "new");
        }
      }
    } else {
      if ($(".new-menu-content", this.el).is(":visible")) {
        $(".new-menu-content", this.el).hide();
      } else {
        $(".new-menu-content", this.el).show();
        $(".new-menu-content", this.el).position({
          my: "left top",
          at: "left bottom",
          of: $(event.target).parent()
        });
        //If you click out of the menu, close the menu.

        $(document).one("mousedown", (event: any) => {
          const element = $(this.el).find(event.target);
          if (element.length === 0) {
            $(".new-menu-content", this.el).hide();
          }
        });
      }
    }
    return false;
  }

  /**
   * Enables or disables buttons when model property enabled is changed
   */
  _manageEnabled(): void {
    this.enabled(this.model.get("enabled"));
  }

  /**
   * Enables or disables buttons
   */
  enabled(enable: boolean): void {
    this.model.set("enabled", enable, { silent: true });
    this.isEnabled = enable;
    if (this.isEnabled) {
      $(this.el).removeClass("ui-state-disabled");
      $("button", this.el).css("cursor", "");
    } else {
      $(this.el).addClass("ui-state-disabled");
      $("button", this.el).css("cursor", "default");
    }
  }

  /**
   * Checks that mode is valid. If it is not an error is shown. Enables/disables buttons
   */
  _manageMode(): void {
    const newMode = this.model.get("mode");
    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in ActionBar : " + newMode);
    }
    this._disableButtons(newMode);

    this._showRowOrderingArrows(newMode === "R");
  }
  /**
   * Hide/show buttons depending on the mode of the action bar
   */
  _disableButtons(mode: string): void {
    const showNewMenu = this.buttons.habilitation["new"] && this.buttons.visible["new"];
    const showDeleteMenu = this.buttons.habilitation["delete"] && this.buttons.visible["delete"];
    const showDeleteHeadMenu = this.showDeleteHead && this.buttons.habilitation["delete"] && this.buttons.visible["delete"];
    const overrideShowDeleteMenu = showDeleteMenu && this.buttons.override["delete"];
    const overrideShowDeleteHeadMenu = showDeleteHeadMenu && this.buttons.override["delete"];
    const showCopyMenu = this.buttons.habilitation["copy"] && this.buttons.visible["copy"];

    const $newBtn = $(this.el).find(".new-menu");
    const $deleteBtn = $(this.el).find(".delete-menu .delete");
    const $deleteHeadBtn = $(this.el).find(".deleteHead-menu .deleteHead");
    const $copyBtn = $(this.el).find(".copy-menu .copy");

    if (showDeleteMenu) {
      this._showButton($deleteBtn);
    } else {
      this._hideButton($deleteBtn);
    }
    if (showDeleteHeadMenu) {
      this._showButton($deleteHeadBtn);
    } else {
      this._hideButton($deleteHeadBtn);
    }
    if (showCopyMenu) {
      this._showButton($copyBtn);
    } else {
      this._hideButton($copyBtn);
    }
    if (showNewMenu) {
      this._showButton($newBtn);
    } else {
      this._hideButton($newBtn);
    }

    switch (mode) {
      case "E": // edition
        // Hide new button  
        this._hideButton($newBtn);
        this._hideButton($deleteHeadBtn);
        // Hide other buttons
        _.each(this.newButtons, (button) => {
          this._hideButton($(this.el).find("." + button.buttonAction + "-menu"));
        });
        break;
      case "R": // item selected
        _.each(this.newButtons, (button) => {
          const action = button.buttonAction;
          const $newBtn = $(this.el).find("." + action + "-menu");
          if (this.buttons.custom[action] !== false) {
            this._showButton($newBtn);
          } else {
            this._hideButton($newBtn);
          }
          if (this.buttons.habilitation[action] === false) {
            this._hideButton($newBtn);
          }
        });
        break;
      case "C": // nothing selected
        if (overrideShowDeleteMenu) {
          this._showButton($deleteBtn);
        } else {
          this._hideButton($deleteBtn);
        }
        if (overrideShowDeleteHeadMenu) {
          this._showButton($deleteHeadBtn);
        } else {
          this._hideButton($deleteHeadBtn);
        }
        _.each(this.newButtons, (button) => {
          const action = button.buttonAction;
          const $newBtn = $(this.el).find("." + action + "-menu");
          if (this.buttons.visibleInModeC[action] === true) {
            this._showButton($newBtn);
          } else {
            this._hideButton($newBtn);
          }
          if (this.buttons.habilitation[action] === false) {
            this._hideButton($newBtn);
          }
        });
        break;
      default:
        break;
    }
  }

  _showButton($btn: JQuery): void {
    $btn.removeClass("d-none");
  }

  _hideButton($btn: JQuery): void {
    $btn.addClass("d-none");
  }



  restoreDetachedButtons(): void {
    const $detachedBtn = $(this.el).find('.btnBar-btnDetached');
    this._showButton($detachedBtn);
    $detachedBtn.removeClass('.btnBar-btnDetached');
  }

  detachButton(btnName: string, btnRef: JQuery): void {
    if (this.detachedBtns[btnName]) {
      this.model.off('all', this.detachedBtns[btnName]);
    }
    if ($(this.el).find('.' + btnName).length > 0 && $(btnRef)) {
      this._cloneDetachedButton(btnName, btnRef);
      this._observeDetachedButton(btnName, btnRef);

      this.detachedBtns[btnName] = this._detachButtonCallback.bind(this, btnName, btnRef);
      this.model.on('all', this.detachedBtns[btnName]);
    }
  }

  _detachButtonCallback(btnName: string, btnRef: JQuery, eventName: string): void {
    if (_.indexOf(eventName, ":") > 0) {
      const eventParts = eventName.split(":");
      const className = eventParts[1];
      if (className === btnName) {
        const $clonedBtn = $(this.el).find('.' + btnName).clone(true, true);
        this._showButton($clonedBtn);
        $(btnRef).empty().append($clonedBtn);
        const $parentBtn = $(this.el).find('.' + btnName).parent();
        this._hideButton($parentBtn);
      }
    }
  }

  _observeDetachedButton(btnName: string, btnRef: JQuery): void {
    const config = { attributes: true, childList: true, subtree: true };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(this._cloneDetachedButton.bind(this, btnName, btnRef));

    // Start observing the target node for configured mutations
    observer.observe($(this.el).find('.' + btnName)[0], config);

    // Later, you can stop observing
    // observer.disconnect();
  }

  _cloneDetachedButton(btnName: string, btnRef: JQuery): void {
    const clonedBtn = $(this.el).find('.' + btnName).clone(true, true);
    const actionClass = "phx-cell-" + btnName + "-action";
    clonedBtn.attr("class", actionClass + " " + clonedBtn.attr("class"));
    $(btnRef).empty().append(clonedBtn);
    const $parentBtn = $(this.el).find('.' + btnName).parent();
    this._hideButton($parentBtn);
    $parentBtn.addClass("btnBar-btnDetached");
  }

  /**
   * Manages keydown action
   */
  _keyEvent(event: JQueryEventObject): void {
    if (event.keyCode === 13 || event.keyCode === 32) { // enter or space
      $(event.target).click();
      event.preventDefault();
    }
  }

  /**
   * Manages click over a button
   */
  _clickListener(event: JQueryEventObject): boolean {
    if (this.isEnabled) {
      const btnClicked = event.currentTarget.className.split(" ")[0];

      if (!$(event.currentTarget).hasClass("ui-state-disabled") && $(event.currentTarget).parent().is(":visible")) {
        this.model.trigger("btn:click", btnClicked);
      }
    }
    return false;
  }

  /**
   * When rows are clicked
   */
  _clickListenerMove(event: JQueryEventObject): boolean {

    if (this.isEnabled) {
      const btnClicked = event.target.className.split(" ")[0];

      if (!$(event.target).hasClass("ui-state-disabled")) {
        this.model.trigger("btn:click", btnClicked);
      }
    }

    return false;
  }

  /**
   * Renders this view
   */
  render(): CWActionBarView {
    const json = {
      "grid": i18n,
      "UTILS": UTILS
    };
    $(this.el).html(this.template(json));

    if (!_.isEmpty(this.buttonsTitle)) {
      _.each(this.buttonsTitle, (title, button) => {
        if (!CWSTR.isBlank(title)) {
          $(this.el).find("." + button).attr("title", title);
        }
      });
    }
    this._renderNewButtons();

    //Default mode
    this._disableButtons(this.model.get("mode"));

    // hide arrows that allows moving lines up or down
    this._showRowOrderingArrows(false);
    return this;
  }

  /**
   * Adds a button
   */
  addButton(buttonText: string, buttonAction: string, hasMenu: boolean, menuOptions?: { [key: string]: any }, title?: string, icon?: string): void {
    this.newButtons.push({
      buttonText: buttonText,
      buttonAction: buttonAction,
      hasMenu: hasMenu,
      menuOptions: menuOptions,
      title: title,
      icon: icon
    });

    this.buttons.visibleInModeC[buttonAction] = false;
  }

  /**
   * Replace the button options
   */
  replaceButtonOptions(buttonAction: string, menuOptions: { [key: string]: any }): void {
    const actionBar = this.$el.find(".grid-action-bar");
    const $menu = $("." + buttonAction + "-menu-content", this.el);
    if ($menu) {
      $menu.empty();
    }
    menuOptions.reverse();
    _.each(this.newButtons, (obj) => {
      if (obj.buttonAction === buttonAction) {
        obj.menuOptions = menuOptions;
      }
    }, this);

    actionBar.append(this._renderMenuButtons(buttonAction, menuOptions));
  }
  /**
   * Makes the button visible in mode consultation
   */
  setButtonVisibleInModeC(buttonAction: string, visible: boolean): void {
    this.buttons.visibleInModeC[buttonAction] = visible;
  }
  /**
   * Render a new button
   */
  _renderNewButtons(): void {
    const actionBar = this.$el.find(".grid-action-bar");
    const buttonsLength = this.newButtons.length;

    if (!this.moveInButton) {
      this.$el.find(".newRowOrdering").hide();
    }

    for (let i = 0; i < buttonsLength; i++) {

      const $buttonDiv = $("<div></div>");
      $buttonDiv.addClass(this.newButtons[i].buttonAction + "-menu");
      $buttonDiv.addClass("cw-grid-title-bar-menu");
      $buttonDiv.addClass("cw-hover");

      let $button;

      if (this.newButtons[i].title && !CWSTR.isBlank(this.newButtons[i].title)) {
        $button = $("<button tabindex='0' class='" + this.newButtons[i].buttonAction + " nwBtn btn btn-secondary' data-cy='" + this.newButtons[i].buttonAction + "' title='" + this.newButtons[i].title + "'>" + this.newButtons[i].buttonText + "</button>");
      } else {
        $button = $("<button tabindex='0' class='" + this.newButtons[i].buttonAction + " nwBtn btn btn-secondary' data-cy='" + this.newButtons[i].buttonAction + "'>" + this.newButtons[i].buttonText + "</button>");
      }

      $buttonDiv.append($button);

      if (this.positionNewButtonsOnLeft) {
        actionBar.prepend($buttonDiv);
      } else {
        actionBar.append($buttonDiv);
      }
      if (this.newButtons[i].hasMenu) {
        $button.addClass("btn-withIcon cw-actionBar__btnMenu").append(UTILS.getSVGIcon('fleche_bas'));
        actionBar.append(this._renderMenuButtons(this.newButtons[i].buttonAction, this.newButtons[i].menuOptions));
      }
      if (!CWSTR.isBlank(this.newButtons[i].icon)) {
        if (!CWSTR.isBlank(this.newButtons[i].buttonText) || this.newButtons[i].hasMenu === true) {
          $button.addClass("btn-withIcon");
        } else {
          $button.addClass("btn-onlyIcon");
        }
        $button.append(UTILS.getSVGIcon(this.newButtons[i].icon, this.newButtons[i].icon + '-icon cw-icon', 16, undefined))
      }
    }
  }

  /**
   * Toggles an external menu
   */
  _toggleExternalMenu(event: JQueryMouseEventObject, menuId: string): boolean {

    if (this.isEnabled === true) {
      if ($("." + menuId + "-menu-content", this.el).is(":visible")) {
        $("." + menuId + "-menu-content", this.el).hide();
      } else {
        $("." + menuId + "-menu-content", this.el).show();
        $("." + menuId + "-menu-content", this.el).position({
          my: "left top",
          at: "left bottom",
          of: $(event.target).parent()
        });
        //If you click out of the menu, close the menu.

        $(document).one("mousedown", (event: any) => {
          const element = $(this.el).find(event.target);
          if (element.length === 0) {
            $("." + menuId + "-menu-content", this.el).hide();
          }
        });
        $(window).one("resize", () => {
          $("." + menuId + "-menu-content", this.el).hide();
        });
      }
    } else {
      $("." + menuId + "-menu-content", this.el).hide();
    }


    return false;
  }

  /**
   * hide/show arrow to allow row ordering
   */
  _manageRowOrdering(): void {
    this.disableArrows = !this.model.get("rowOrdering");
    this._showRowOrderingArrows(true);
  }
  /**
   * show arrow to allow row ordering
   */
  _showRowOrderingArrows(show: boolean): void {
    const hab = this.buttons.habilitation["moveUp"] || this.buttons.habilitation["moveDown"] || this.buttons.habilitation["movePlacer"] || this.buttons.habilitation["moveUp"];
    if (this.model.get("rowOrdering") === true && show && hab) {
      if (this.moveInButton) {
        $(".rowOrdering", this.el).hide();
        this.$el.find(".newRowOrdering").css("display", "inline-flex");

      } else {
        $(".rowOrdering", this.el).css("display", "inline-block");
        this.$el.find(".newRowOrdering").hide();
      }
    } else {
      if (this.moveInButton) {
        this.$el.find(".newRowOrdering").hide();
      }
      $(".rowOrdering", this.el).hide();
    }
  }
  /**
   * Refreshes move line buttons
   */
  _refreshMoveLineButtons(disableMoveUp: boolean, disableMoveDown: boolean): void {
    let montEnabled = true,
      descEnabled = true,
      placEnabled = true,
      premEnabled = true;
    let lPosMonter = null;
    let lPosDescendre = null;

    if (this.moveInButton) {
      lPosMonter = this.$el.find(".monter-menu .monter");
      lPosDescendre = this.$el.find(".descendre-menu .descendre");

    } else {
      lPosMonter = $(".rowOrdering .monter", this.el);
      lPosDescendre = $(".rowOrdering .descendre", this.el);
    }
    if (disableMoveUp || this.buttons.habilitation["moveUp"] === false || this.disableArrows) {
      if (!this.moveInButton) {
        lPosMonter.addClass("ui-state-disabled");
      } else {
        lPosMonter[0].setAttribute("disabled", "");
        //lPosMonter.attr("disabled", "true");
      }
      montEnabled = false;
    } else {
      if (!this.moveInButton) {
        lPosMonter.removeClass("ui-state-disabled");
      } else {
        lPosMonter.removeAttr("disabled");
      }
    }
    if (disableMoveDown || this.buttons.habilitation["moveDown"] === false || this.disableArrows) {
      if (!this.moveInButton) {
        lPosDescendre.addClass("ui-state-disabled");
      } else {
        //lPosDescendre.attr("disabled", "true");
        lPosDescendre[0].setAttribute("disabled", "");
      }
      descEnabled = false;
    } else {
      if (!this.moveInButton) {
        lPosDescendre.removeClass("ui-state-disabled");
      }
      else {
        lPosDescendre.removeAttr("disabled");
      }
    }
    if (disableMoveUp || this.buttons.habilitation["movePlacer"] === false || this.disableArrows) {
      $(".rowOrdering .placer", this.el).addClass("ui-state-disabled");
      placEnabled = false;
    } else {
      $(".rowOrdering .placer", this.el).removeClass("ui-state-disabled");
    }
    if (disableMoveDown || this.buttons.habilitation["movePremier"] === false || this.disableArrows) {
      $(".rowOrdering .premier", this.el).addClass("ui-state-disabled");
      premEnabled = false;
    } else {
      $(".rowOrdering .premier", this.el).removeClass("ui-state-disabled");
    }
    if (!montEnabled && !descEnabled && !placEnabled && !premEnabled) {
      $(".rowOrdering .descendre", this.el).parent().addClass("ui-state-disabled");
    } else {
      $(".rowOrdering .descendre", this.el).parent().removeClass("ui-state-disabled");
    }
    this._showRowOrderingArrows(true);
  }

  /**
   * Set title to button (for infobulle)
   */
  setTitle(button: string, title: string): void {
    if (button && !CWSTR.isBlank(button)) {
      this.buttonsTitle[button] = title;
    }
  }

  changeButtonLabel(name: string, label: string): void {
    const button = $(this.el).find("." + name);
    if (button) {
      button.text(label);
    }
  }

  _changeClassButton(aButton: string, action: string, value: string): void {
    const actionBar = this.$el.find(".grid-action-bar");

    if (!CWSTR.isBlank(aButton) && !CWSTR.isBlank(action) && !CWSTR.isBlank(value)) {
      const lPos = actionBar.find("." + aButton);

      if (lPos && lPos.length > 0) {
        switch (action) {
          case "add":
            lPos.addClass(value);
            break;
          case "delete":
            lPos.removeClass(value);
            break;
          default:
          //nothing 
        }
      }
    }
  }

  _initializeRowOrdering(position: number, totalRows: number): void {
    if (!CWSTR.isBlank(position) && !CWSTR.isBlank(position)) {
      if (position + 1 === totalRows) {
        if (position !== 0) {
          //More rows
          this._refreshMoveLineButtons(false, true);
        } else {
          //One row
          this._refreshMoveLineButtons(true, true);
        }
      }
      if (position + 1 < totalRows) {
        if (position === 0) {
          this._refreshMoveLineButtons(true, false);
        }
        else {
          this._refreshMoveLineButtons(false, false);
        }
      }
    }
  }

  _renderMenuButtons(buttonAction: string, menuOptions: { [key: string]: any }): JQuery {

    let $menu: JQuery = null;
    const size = menuOptions.length;
    if (size > 0) {
      $menu = $("<div style='display:none'></div>").css("position", "fixed");
      $menu.addClass(buttonAction + "-menu-content");
      $menu.addClass("cw-grid-title-bar-menu-content");
      $menu.addClass("c-panneauMenu c-panneauMenu--noIcon");

      const $ul = $("<ul></ul>");
      for (let j = 0; j < size; j++) {
        if (CWSTR.isBlank(menuOptions[j].action)) {
          $ul.append("<span class='nwBtn c-panneauMenu__item cw-actionBar__btnOption' style='white-space:nowrap;'>" + menuOptions[j].text + "</span>");
        } else {
          $ul.append("<li class='" + menuOptions[j].action + " nwBtn c-panneauMenu__item cw-actionBar__btnOption cw-hover' style='white-space:nowrap;' data-cy='" + menuOptions[j].action + "'>" + menuOptions[j].text + "</li>");
        }
      }
      $menu.append($ul);
    }
    return $menu;
  }
}
