import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from './cwBase.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from './cwHabilitationContext';
import { CWHEADERS } from 'utils/cwHeaders';
import { CWSTR } from 'utils/cwStr';
import { objs } from 'src/objectsRepository';

export type EventBackboneType = {
  callback: (model: Backbone.Model, options: any) => any;
  context: Backbone.Model | any;
  ctx: Backbone.Model | any;
};

/**
 * BaseCollection : base collection to use instead of the backbone collection
 *
 */

export class CWBaseCollection<TModel extends Backbone.Model = Backbone.Model> extends Backbone.Collection<TModel> {

  //Backbone stores the events in this object
  _events: {
    [key: string]: EventBackboneType;
  };

  public version: string | { [key: string]: any };
  public activeSimulation: boolean;
  public habContext: CWHabilitationContext;
  public usePopulation: boolean;
  public populationCompte: boolean;
  public habilitationV: string;
  public habilitationG: string;
  public usecase: string;
  public popId: string | number;
  public popType: string;
  public popCode: string | number;
  public popNat: string;
  public popDesc: string;
  public simulationData: { [key: string]: any };
  public simulationModel: CWBaseModel;
  public ecran: string;
  public oldModels: string;

  //constructor(...args: any[]) {
  constructor(models?: TModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(models, options);
  }

  /**
   * Checks habilitations and if they are correct, fetches the collection

     *  responds fetch without errors
   */
  fetch(options?: { [key: string]: any }): JQueryXHR {
    const internalOptions = options ? _.clone(options) : {};

    if (!CWSTR.isBlank(this.version)) {
      const currentVersion = typeof this.version === "object" ? this.version.GET : this.version;

      this.setHeaders(internalOptions, CWHEADERS.versionContext(currentVersion));
    }
    if (this.activeSimulation === true) {
      this.prepareSimulation(internalOptions);
    }
    if (this.habContext) {
      const oldFoncCour = (typeof this.habContext.get("foncCour") === "string" ? this.habContext.get("foncCour") : _.clone(this.habContext.get("foncCour")));

      this.habContext.verifierFoncCour();
      this._prepareEcranHeader(internalOptions);
      this.setHeaders(internalOptions, this.habContext.header());
      if (this.usePopulation === true) {
        this.preparePopulation(internalOptions);
      }
      //retablir la valeur de foncCour après la verification et la préparation de "header"
      this.updateHabContext({ "foncCour": oldFoncCour });
      return Backbone.Collection.prototype.fetch.call(this, internalOptions);
    } else {
      this._checkHabilitations();
      this._prepareEcranHeader(internalOptions);

      if (this.habilitationV === "N" || CWHABILITATION.canView(this.habilitationV)) {
        if (this.usePopulation === true) {
          this.preparePopulationAndHabiliation(internalOptions);
        } else {
          this.setHeaders(internalOptions, CWHEADERS.habilitationContext(this.usecase, this.habilitationV));
        }

        return Backbone.Collection.prototype.fetch.call(this, internalOptions);

      } else {
        if (Configuration.development === true && CWSTR.isBlank(this.habilitationV)) {
          throw new Error("The habilitation is not properly configured");
        }

        if (internalOptions.success) {
          internalOptions.success(this, null, internalOptions);
        }
      }
    }
    return ($.Deferred() as any).resolve();
  }

  /**
   * Checks that habilitationV, habilitationG and usecase are informed. If they are not an error is shown
   */
  _checkHabilitations(): void {
    if (CWSTR.isBlank(this.habilitationV)) {
      throw new Error("View Habilitation must be defined or set to empty string if not used.");
    }
    if (CWSTR.isBlank(this.habilitationG)) {
      throw new Error("Management Habilitation must be defined or set to empty string if not used.");
    }
    if (CWSTR.isBlank(this.usecase)) {
      throw new Error("Usecase name must be defined.");
    }
  }

  /**
   * Includes population Ident and Type in header
   */
  preparePopulationAndHabiliation(options: { [key: string]: any }): void {
    let popId: string | number = "0",
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;

    if (!CWSTR.isBlank(this.popId) && !CWSTR.isBlank(this.popType)) {
      popId = this.popId;
      popType = this.popType;
      popCode = this.popCode;
      popNat = this.popNat;
      popDesc = this.popDesc;
    } else if (!CWSTR.isBlank(objs.populationMenu) && !CWSTR.isBlank(objs.populationMenu.model)) {
      popId = objs.populationMenu.model.get("ident");
      popType = objs.populationMenu.model.get("type");
      popCode = objs.populationMenu.model.get("code");
      popNat = objs.populationMenu.model.get("nature");
      popDesc = objs.populationMenu.model.get("desc");
    }
    this.setHeaders(options, CWHEADERS.habilitationContext(this.usecase, this.habilitationV));
    if (CWSTR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (CWSTR.isBlank(popNat)) {
      popNat = "";
    } else if (popNat === "M") {
      popNat = "C";
    }
    if (CWSTR.isBlank(popCode)) {
      popCode = "";
    }
    if (CWSTR.isBlank(popDesc)) {
      popDesc = "";
    }
    if (CWSTR.isBlank(popType)) {
      popType = "D";
    }
    this.setHeaders(options, CWHEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  }

  /**
   * Includes population Ident and Type in header
   */
  preparePopulation(options: { [key: string]: any }): void {
    let popId = null,
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;
    const populationModel = objs.populationMenu ? objs.populationMenu.model : null;

    if (!CWSTR.isBlank(this.popId)) {
      popId = this.popId;
    } else if (populationModel && populationModel.get("ident")) {
      popId = populationModel.get("ident");
    }
    if (!CWSTR.isBlank(this.popType)) {
      popType = this.popType;
    } else if (populationModel && populationModel.get("type")) {
      popType = populationModel.get("type");
    }
    if (!CWSTR.isBlank(this.popCode)) {
      popCode = this.popCode;
    } else if (populationModel && populationModel.get("code") && !CWSTR.isBlank(populationModel.get("nature")) && !this.populationCompte) {
      popCode = populationModel.get("code");
    }
    if (!CWSTR.isBlank(this.popNat)) {
      popNat = this.popNat;
    } else if (populationModel && !this.populationCompte) {
      popNat = populationModel.get("nature");
    }
    if (!CWSTR.isBlank(this.popDesc)) {
      popDesc = this.popDesc;
    } else if (populationModel && !this.populationCompte) {
      popDesc = populationModel.get("desc");
    }
    if (CWSTR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (CWSTR.isBlank(popNat)) {
      popNat = "";
    } else if (popNat === "M") {
      popNat = "C";
    }
    if (CWSTR.isBlank(popCode)) {
      popCode = "";
    }
    if (CWSTR.isBlank(popType)) {
      popType = "D";
    } else if (popType === "M") {
      popType = "D";
    }
    this.setHeaders(options, CWHEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  }

  prepareSimulation(options: { [key: string]: any }): void {
    const sim = !CWSTR.isBlank(this.simulationData) ? this.simulationData : objs.simulationModel;

    if (!CWSTR.isBlank(sim)) {
      const datedeb = sim.get("datedeb");
      let passreel = false;

      if (objs.ctxAffReelPasse === true) {
        passreel = true
      }
      this.setHeaders(options, CWHEADERS.simulationContext(sim.get("code"), datedeb, sim.get("datefin"), passreel));
    }
  }

  /**
   * Prepares model header for server calls
   */
  _prepareEcranHeader(options: { [key: string]: any }): void {
    if (!CWSTR.isBlank(this.ecran)) {
      this.setHeaders(options, CWHEADERS.ecranContext(this.ecran));
    }
  }

  /**
   * Adds options.header to current header
   *  responds without errors
   *  responds with an error
   */
  setHeaders(options: { [key: string]: any }, header: { [key: string]: any }): void {
    if (options.headers) {
      options.headers = _.extend(options.headers, header);
    } else {
      options.headers = header;
    }
  }
  /**
   * Sets collection's habilitations and usecase to values passed
   */
  setHabilitation(V: string, G: string, usecase: string): void {
    if (!CWSTR.isBlank(V)) {
      this.habilitationV = V;
    }
    if (!CWSTR.isBlank(G)) {
      this.habilitationG = G;
    }
    if (!CWSTR.isBlank(usecase)) {
      this.usecase = usecase;
    }
  }

  setHabContext(habContext: CWHabilitationContext): void {
    this.habContext = habContext;
  }

  setVersion(version: string | { [key: string]: any }): void {
    this.version = version;
  }

  updateHabContext(attributes: CWBaseModel | { [key: string]: any }): void {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  }

  getHabContext(): CWHabilitationContext {
    return this.habContext;
  }

  /**
   * Copies current attributes to the property oldAttributes. This property can be accessed in the future to revert changes
   */
  store(): void {
    this.oldModels = _.clone(JSON.stringify(this.toJSON()));
  }

  /**
   * Sets current model attributes to values that were stored (oldAttributes).
   */
  revert(): void {
    if (this.oldModels) {
      this.reset(this.parse(JSON.parse(this.oldModels)));
    }
  }
}
