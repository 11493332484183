import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

/**
 * Model of the type of evenements gere by Recapitulatif activites
 */
export class CWRecapActiviteTypeEvtModel extends CWBaseModel {

  code: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/element/construction/" + encodeURIComponent(this.code);
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "type": {
        "code": null,
        "libelle": ""
      },
      "domaine": {
        "code": null,
        "libelle": ""
      },
      "gestion": false,
      "label": false
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.type !== undefined) {
      if (!CWSTR.isBlank(response.type.code)) {
        response.id = response.type.code.toString();
      }
      if (!CWSTR.isBlank(response.domaine) && !CWSTR.isBlank(response.domaine.code)) {
        response.id = response.id + "," + response.domaine.code;
      }
    }
    return response;
  }
}
