import _ from 'underscore';
import { CWGererRegularisationView } from 'src/uc/common/evenements/gerer/gererregularisations/views/cwGererRegularisation.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListerRegularisationView } from 'common/evenements/lister/listerregularisations/views/cwListerRegularisation.view';
import { CWMotifRegulInitModel } from './cwMotifRegulInit.model';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { objs } from 'src/objectsRepository';



export class CWMregulWorkflow extends CWReadOnlyModel {

  public gererRegularisations: CWGererRegularisationView;
  public listerRegularisations: CWListerRegularisationView;
  public matricule: string | number;
  public contextGerer: { [key: string]: any };
  public initWorkflow: CWMotifRegulInitModel;
  public wkfActiv: boolean;
  public module: string;
  wkfRecupere: boolean;
  private $appendTo: JQuery; //pour les messages


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.module = options.module;
    this.gererRegularisations = null;
    this.listerRegularisations = null;
    this.matricule = options.matricule;
    this.module = options.module;
    //ready = true when the setUp is finished. Just one setUp per life cycle of the module
    this.set("ready", false);
    this.contextGerer = {
      orientation: 0, // 0: Horizontal ; 1: Vertical
      ctxUtilisateur: "Collaborateur",
      ctxHabilitation: {
        HabilitationAcces: "COL_REG.V",
        HabilitationGestion: "COL_REG.G"
      },
      ctxModeRepresentation: "Imbrique",
      ctxEcran: this.module,
      ctxGestionCollab: {
        matricule: this.matricule
      },
      ctxActionsPossibles: ["Creer", "Supprimer", "Modifier", "Valider"],
      ctxModeInitialisation: "",
      ctxIdentifiantEvt: "",
      ctxValeursEvenement: {},
      ctxValeursCreation: {
        RegDateDebut: "",
        RegDateFin: "",
        RegCodeMotif: ""
      },
      ctxGestionCollective: false
    };
    this.initWorkflow = new CWMotifRegulInitModel();
    this.initWorkflow.setHabContext(new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_REG.V"
    }));
    this.wkfActiv = null;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
  }

  /**
   * Set up the workflow. Link the models between them
   */
  public setUp(callback?: () => void): void {
    this.listerRegularisations.model.on("rowChange", this._tableValueChange, this);
    this.listerRegularisations.model.on("isEmpty", this._tableValueChange, this);
    this.gererRegularisations.model.on("regulChanged", this._regulChanged, this);
    this.gererRegularisations.model.on("mode:create", this._manageCreateMode, this);
    // Load data
    if (callback) {
      if (this.wkfRecupere) {
        callback();
      } else {
        this.initWorkflow.fetch({
          success: (fresh: CWMotifRegulInitModel): void => {
            this.wkfRecupere = true; //flag pour savoir que le fetch déjà est fait ou pas (ne pas le faire deux fois)
            this.wkfActiv = fresh.get("wkf_activ");
            if (callback) {
              callback();
            }
          }
        });
      }
    } else {
      this._manageCommentaire();
    }
    //Events 
    this.listenTo(this, "manageUnselected", (status: boolean) => {
      this.gererRegularisations.trigger("manageUnselected", status);
    });
    this._registerSyncEvents();
    this.set("ready", true);
  }

  _regulChanged(model: CWMotifRegulInitModel, action: any): void {
    let modelId = (!model || model === null) ? null : model.get("code");
    if (action === "delete") {
      modelId = "";
    }
    this.listerRegularisations.refreshList(modelId, action);
  }

  /**
     * check if the model is not on the filter, return true if is not on the filter
     * @param model
     */
  _modelNotInFilter(model: { [key: string]: any }): boolean {
    let param = "";
    const keys = Object.keys(this.listerRegularisations.table.model.coll.params);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];

      param = this.listerRegularisations.table.model.coll.params[key]
      switch (key) {
        case "motif":
          if (model.get('motif').code !== param) {
            return true;
          }
          break;
        case "absence":
          if (!param && !model.get('souhait')) {
            return true
          }
          break;
        case "souhait":
          if (!param && model.get('souhait')) {
            return true
          }
          break;
        case "statuts":
          if (param.split(',').indexOf(model.get('statut').code) === -1) {
            return true;
          }
          break;
        case "datedeb":
          if (param > model.get('datedeb')) {
            return true;
          }
          break;
        case "datefin":
          if (param < model.get('datefin')) {
            return true;
          }
          break;
        default:
          if (String(model.get(key)) !== String(param)) {
            return true;
          }
      }
    }
    return false;
  }

  _manageEmptyMode(): void {
    if (!CWSTR.isBlank(this.gererRegularisations)) {
      this.trigger("hideGererRegularisations", this);
    }
  }

  _manageCreateMode(): void {
    $(".gererregularisation-form-inputs", "#" + this.module).show();
    this._manageCommentaire();
  }

  _tableValueChange(model: CWMotifRegulInitModel): void {
    const modelId = _.isEmpty(model) ? null : model.get("code");

    this.gererRegularisations.$el.show();
    this.gererRegularisations.$el.find(".gererregularisation-content").show();
    this.gererRegularisations.$el.find(".l-panelB-buttonContainer").show();
    this.trigger("manageUnselected", false);
    if (CWSTR.isBlank(model)) {
      this.contextGerer.ctxModeInitialisation = "";
      this.contextGerer.ctxIdentifiantEvt = null;
      this.contextGerer.ctxValeursEvenement = {};
      this.contextGerer.ctxValeursCreation = {};
      this.contextGerer.ctxHistoriqueWKF = false;
      this.gererRegularisations.setContext(this.contextGerer);
      //You must go to the end, after the setContext
      this.trigger("hideGererRegularisations", this);
      this.trigger("manageUnselected", true);
    } else {
      this.contextGerer.ctxModeInitialisation = "Consultation";
      this.contextGerer.ctxIdentifiantEvt = modelId + "," + model.get("table");
      this.contextGerer.ctxValeursEvenement = {
        RegCodeStatutWKF: model.get("statut").code,
        RegDateDebut: model.get("parametres")["@regDateDebut"],
        RegDateFin: model.get("parametres")["@regDateFin"],
        RegCodeMotif: model.get("parametres")["@regLibelleMotif"]
      };
      this.contextGerer.ctxValeursCreation = {};
      this.contextGerer.ctxHistoriqueWKF = true;
      this.gererRegularisations.setContext(this.contextGerer);
    }
    this._manageCommentaire();
  }

  _manageCommentaire(): void {
    if (this.wkfActiv) {
      $(".gererregularisation-form-inputs .blockCommentaire").show();
    } else {
      $(".gererregularisation-form-inputs .blockCommentaire").hide();
    }
  }

  // register listener to syncEvents type
  _registerSyncEvents(): void {
    this.listenTo(objs.appRt.workflow, "changed:usecase", this._managePendingSyncEvents);
  }

  // execute pending syncEvents
  _managePendingSyncEvents(): void {
    if (objs.appRt.workflow.get("zone") === "coll" && objs.appRt.workflow.get("usecase") === this.module) {
      this.listerRegularisations.table.model.coll.trigger("updatePaginationCounter");
    }
  }
}
