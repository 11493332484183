import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWFORMS } from 'src/utils/cwForms';
import { CWGererActivitePopUpView } from '../views/cwGererActivitePopUp.view';
import { CWGererActiviteView } from '../views/cwGererActivite.view';
import { CWGererActiviteWorflowActifModel } from './cwGererActiviteWorflowActif.model';
import { CWGererActiviteWorkflowActifColl } from './cwGererActiviteWorkflowActif.collection';
import { CWLOG } from 'utils/cwLog';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPiecesAutorisees } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesAutorisees';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';



export class CWGereractivitesWorkflow extends CWReadOnlyModel {

  cwGererActivitesView: CWGererActiviteView;
  btnBarModel: CWBaseModel;
  modeDeclarer: boolean;
  pDiversIdCollabModel: any;
  maxPieces: any;
  formModel: any;
  contutilid: string;
  public module: string;
  public $appendTo: JQuery; //pour les messages
  public wkfActivitePrevActiv: boolean;
  public wkfActiviteRealActiv: boolean;
  private activesWorkflows: CWGererActiviteWorkflowActifColl;
  context: { [key: string]: any };

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.context = options.context;
    this.module = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : null;
    this.btnBarModel = null;
    this.modeDeclarer = false;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp(callback?: () => void): void {
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.cwGererActivitesView.diver = this.pDiversIdCollabModel.get("valeur");
    this.maxPieces = new CWPiecesAutorisees({ id: "PJEVTACT" });
    if (this.formModel !== undefined && this.formModel.getHabContext() !== undefined) {
      this.maxPieces.setHabContext(this.formModel.getHabContext());
    }
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.cwGererActivitesView.model.on("form:edited", this._formEdited, this);

    this._fetchWorkflowOpt(callback);
  }

  _buttonAction(pButtonId: string, profil?: string): void {
    let defaultValues = true;
    let emptyNew = false;
    let currentModel;
    let validationProfil: string;
    let typeevenement;
    let typologie;
    let dialog: CWDialogView;
    let dialogModel: Backbone.Model;
    let activite;
    let code;
    let buttonId = pButtonId;

    CWLOG.debug("Button clicked in gerer activites : " + pButtonId);
    if (buttonId === "new" && !CWSTR.isBlank(profil) && profil.indexOf(",") > -1) {
      const arrayCreation = profil.split(',');

      typologie = arrayCreation[0];
      typeevenement = arrayCreation[1];
      emptyNew = true;
    } else if (buttonId.indexOf(",") > -1) {
      const arrayCreation = buttonId.split(',');

      typologie = arrayCreation[0];
      typeevenement = arrayCreation[1];
      buttonId = "new";
      defaultValues = false;
    } else if (this.cwGererActivitesView.model && this.cwGererActivitesView.model.get("value")) {
      currentModel = this.cwGererActivitesView.model.get("value");
      typeevenement = currentModel.get("typeevenement");
      typologie = currentModel.get("typologie").code;
      activite = currentModel.get("activite");
      code = currentModel.get("code");
      emptyNew = true;
    }
    switch (buttonId) {
      case "save":
        // we cannot save with "M". SFD says that when we create we use nature "A". in this case.
        if (!this.cwGererActivitesView.model.get("value").isNew()) {
          if (!this.modeDeclarer) {
            this.formModel.updateHabContext({ foncCour: this.cwGererActivitesView.context.ctxHabilitation.HabilitationGestion, natGest: "M" });
          }
          this.cwGererActivitesView.model.get("value").updateHabContext({ natGest: "M" });
        }
        if (!this.context.ctxFeuilleTemps) {
          CWFORMS.removeValue(this.cwGererActivitesView.model.get("value").attributes, "imputation");
        }
        this.cwGererActivitesView.saveActivite(this.modeDeclarer);
        this.modeDeclarer = false;
        break;
      case "revert":
        // in revert we use nature "M" as in other cases.
        if (this.cwGererActivitesView.original) {
          typologie = this.cwGererActivitesView.original.get("typologie").code;
        }
        if (typologie) {
          this.cwGererActivitesView.context.ctxTypologieActivite = typologie;
        }
        this.formModel.updateHabContext({ foncCour: this.cwGererActivitesView.context.ctxHabilitation.HabilitationGestion, natGest: "M" });
        this.cwGererActivitesView.model.get("value").updateHabContext({ natGest: "M" });
        this.cwGererActivitesView.revertView();
        if (CWSTR.isNull(this.cwGererActivitesView.model.get("value")) || CWSTR.isBlank(this.cwGererActivitesView.model.get("value").id) && !CWSTR.isBlank(this.cwGererActivitesView.$el)) {
          this.trigger("manageUnselected", true);
        }
        if (this.cwGererActivitesView.context.ctxModeRepresentation === "pop-up" && this.modeDeclarer === true) {
          this.cwGererActivitesView._setTitlePopUp();
        } else if (this.cwGererActivitesView.context.ctxModeRepresentation === "imbrique") {
          this.cwGererActivitesView._setTitleImbrique(null);
        }
        this.modeDeclarer = false;
        break;
      case "new":
        //vérification du context avant de continuer si le mode est déjà un pop-up et le mode n'est pas "Ajout", par exemple "Consultation",
        //de cette manière, on ne doit pas montrer les boutons de valider, etc. et l'action de annuler, doit fermer le pop-up. Même action
        //pour les déplacements en mode "imbrique". Pour faire ça:
        if (this.cwGererActivitesView && this.cwGererActivitesView.context && this.cwGererActivitesView.context.ctxModeInitialisation !== "Ajout" &&
          (this.cwGererActivitesView.context.ctxModeRepresentation === "pop-up" || this.cwGererActivitesView.context.ctxEcran === "mdepl")) {
          this.cwGererActivitesView.context.ctxModeInitialisation = "Ajout";
          this.btnBarModel.trigger("hide:valider");
        }
        // added nature "A" as in SFD.
        if (typologie) {
          this.cwGererActivitesView.context.ctxTypologieActivite = typologie;
        }
        this.formModel.updateHabContext({ foncCour: this.cwGererActivitesView.context.ctxHabilitation.HabilitationGestion, natGest: "A" });
        this.cwGererActivitesView.model.get("value").updateHabContext({ natGest: "A" });
        this.cwGererActivitesView.newActivite();
        this.trigger("manageUnselected", false);
        if (defaultValues === false) {
          this.cwGererActivitesView.model.get("value").get("typologie").code = typologie;
          this.cwGererActivitesView.model.trigger("change:value");
        } else if (emptyNew) {
          this.cwGererActivitesView.model.get("value").get("typologie").code = typologie;
          this.cwGererActivitesView.model.get("value").set("typeevenement", typeevenement);
          if (this.cwGererActivitesView.context.ctxEcran !== "mdepl") {
            this.cwGererActivitesView.model.get("value").set("activite", activite);
          }
          this.cwGererActivitesView.model.get("value").set("code", code);
          this.cwGererActivitesView.model.trigger("change:value");
        }
        if (this.cwGererActivitesView.context.ctxModeRepresentation === "pop-up") { // Pop up)
          this.cwGererActivitesView._setTitlePopUp(true, typeevenement);
        }
        this.btnBarModel.trigger("disable:valider");
        this.btnBarModel.trigger("disable:declarer");
        // wait reder buttons and fucus first form element
        setTimeout(() => {
          this.cwGererActivitesView._setFocus();
        }, 300);
        break;
      case "copy":
        // copyActivite Updates the Nature. (already)
        this.cwGererActivitesView.copyActivite();
        this.btnBarModel.trigger("disable:valider");
        this.btnBarModel.trigger("disable:declarer");
        break;
      case "delete": {
        const statut = CWSTR.getElValue(this.cwGererActivitesView.model.get("value"), "statut.code");
        const reloadPlanningModal = this.cwGererActivitesView._setReloadPlanningFlag(this.cwGererActivitesView.model.get("value"));
        const title = i18n.t('common:gereractivites.title_popUp');

        currentModel = this.cwGererActivitesView.model.get("value");
        if (currentModel.get("typeevenement") === "P" && (currentModel.get("typetransfo") === "C" || currentModel.get("typetransfo") === "P")) {
          CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1344'), (result: string): void => {
            if (result === "Y") {
              if (statut === "A" || statut === "H" || (statut === "I" && this.cwGererActivitesView.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
                const dialog = this._initPopUp("suppression");

                dialogModel = dialog.model;
                dialog.open((): void => {
                  if (dialogModel.get("action") === "OK") {
                    this.cwGererActivitesView.deleteActivite(dialogModel.get("commentaire"), reloadPlanningModal);
                  }
                });
                dialog._setTitle(title);
              } else {
                CWMSGS.showConfirmAdapter(i18n.t('common:delconfirm'), (result: string): void => {
                  if (result === "Y") {
                    this.cwGererActivitesView.deleteActivite("", reloadPlanningModal);
                  }
                }, null, null, null, this.$appendTo);
              }
            }
          }, null, null, null, this.$appendTo);
        } else {
          if (statut === "A" || statut === "H" ||
            (statut === "I" && this.cwGererActivitesView.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
            dialog = this._initPopUp("suppression");
            dialogModel = dialog.model;
            dialog.open((): void => {
              if (dialogModel.get("action") === "OK") {
                this.cwGererActivitesView.deleteActivite(dialogModel.get("commentaire"), reloadPlanningModal);
              }
            });
            dialog._setTitle(title);
          } else {
            CWMSGS.showConfirmAdapter(i18n.t('common:delconfirm'), (result: string): void => {
              if (result === "Y") {
                this.cwGererActivitesView.deleteActivite("", reloadPlanningModal);
              }
            }, null, null, null, this.$appendTo);
          }
        }
      }
        break;
      case "decIden":
      case "declarer":
        CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1342'), (result: string): void => {
          if (result === "Y") {
            const actView = this.cwGererActivitesView;
            const declarerModel = actView.model.get("value");
            const isRecap = declarerModel.get("recapitulatif") && !CWSTR.isBlank(declarerModel.get("recapitulatif").code);

            declarerModel.modeDeclarer = true;
            if (isRecap) {
              // Cas mode collaborateur
              let foncCour = actView.selectActRealisee.context.ctxHabilitation.HabilitationGestion.realisee;
              if (CWSTR.isBlank(foncCour)) {
                // cas mode gestionnaire
                foncCour = actView.selectActRealisee.context.ctxHabilitation.HabilitationGestion;
              }
              declarerModel.updateHabContext({ onglet: this.cwGererActivitesView.context.ctxEcran, foncCour: foncCour, natGest: "M" });
            } else {
              let hab = "";

              if (this.cwGererActivitesView.context.ctxHabilitation.HabilitationGestion.R) {
                hab = this.cwGererActivitesView.context.ctxHabilitation.HabilitationGestion.R;
              } else {
                //la déclaration (decIden ou declarer et aussi decModif mais ce cas sera ajouté dans autre point) est la transformation à réalisée: on doit utiliser la bonne habilitation
                hab = declarerModel.context.ctxHabilitation.HabilitationGestion
                hab = hab.replace("PREV", "REAL");
              }
              declarerModel.updateHabContext({ onglet: this.cwGererActivitesView.context.ctxEcran, foncCour: hab, natGest: "M" });
            }
            this.cwGererActivitesView.context.ctxModeInitialisation = "Transformation";
            declarerModel.fetch({
              success: (fresh: any): void => {
                this.cwGererActivitesView.model.trigger("activiteChanged", fresh, "transformation");
              }
            });
            declarerModel.modeDeclarer = false;
            if (this.cwGererActivitesView.context.ctxModeRepresentation === "pop-up") {
              this.cwGererActivitesView.parent.close();
            }
          }
        }, null, null, null, this.$appendTo);
        break;
      case "decModif": {
        const actReal = this.cwGererActivitesView.parent.viewData.context.ctxTypologieActivite;
        const actView = this.cwGererActivitesView;
        const foncCour = actView.selectActRealisee.context.ctxHabilitation.HabilitationGestion.realisee;

        currentModel = this.cwGererActivitesView.model.get("value");
        currentModel.updateHabContext({ onglet: this.cwGererActivitesView.context.ctxEcran, foncCour: foncCour, natGest: "M" });
        this.cwGererActivitesView.context.ctxModeInitialisation = "Declaration";
        this.btnBarModel.set("mode", "E");
        this.btnBarModel.trigger("disable:valider");
        this.btnBarModel.trigger("disable:declarer");
        this.modeDeclarer = true;
        if (this.cwGererActivitesView.context.ctxModeRepresentation === "pop-up") {
          this.cwGererActivitesView.parent._setTitle(actReal + i18n.t('common:gereractivites.realisee'));
        }
        if (currentModel.get("transformation_auto") !== "O") {
          //Initialize activite if it's not allowed in this domaine
          currentModel.set("activite", "");
          currentModel.set("libelle", "");
          currentModel.set("hierid", "");
          currentModel.set("straid", "");
          this.cwGererActivitesView.model.trigger("change:value");
        }
        //enable the form for modifying
        this.trigger("declareModify");
      }
        break;
      case "accepter":
        dialog = this._initPopUp("accepter");
        dialogModel = dialog.model;
        validationProfil = profil;
        dialog.open((): void => {
          if (dialogModel.get("action") === "OK") {
            this.cwGererActivitesView.accepterActivite(dialogModel.get("commentaire"), validationProfil);
          }
        });
        dialog._setTitle(i18n.t('common:gereractivites.commentaire_Accept'));
        break;
      case "refuser":
        dialog = this._initPopUp("refuser");
        dialogModel = dialog.model;
        validationProfil = profil;
        dialog.open((): void => {
          if (dialogModel.get("action") === "OK") {
            this.cwGererActivitesView.refuserActivite(dialogModel.get("commentaire"), validationProfil);
          }
        });
        dialog._setTitle(i18n.t('common:gereractivites.commentaire_Refus'));
        break;
      //EVO 378 fin
      // no default
    }
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("disable:valider");
    this.btnBarModel.trigger("disable:declarer");
    if (this.formModel.getHabContext().get("natGest") !== "A") {
      this.formModel.updateHabContext({ foncCour: this.cwGererActivitesView.context.ctxHabilitation.HabilitationGestion, natGest: "M" });
    }
  }

  _initPopUp(act: string): CWDialogPopupView {
    const stat = this.cwGererActivitesView.model.get("value").get("statut").code;
    const ctxUtilisateur = this.cwGererActivitesView.context.ctxUtilisateur;
    const typeEvenement = this.cwGererActivitesView.model.attributes.value.attributes.typeevenement === "P" ? "prevue" : "realisee";
    let dialog = null;
    const lViewvData: { [key: string]: any } = {
      action: act,
      statut: stat,
      utilisateur: ctxUtilisateur,
      typeEvenement: typeEvenement,
      appendTo: this.$appendTo
    };

    if (this.cwGererActivitesView && this.cwGererActivitesView.context && !CWSTR.isBlank(this.cwGererActivitesView.context.ctxAppendTo) && this.cwGererActivitesView.context.ctxModeRepresentation !== "pop-up") {
      //par exemple: appendTo: $(".planmed-wrap", $("#" + this.workflow.module)),
      lViewvData.appendTo = this.cwGererActivitesView.context.ctxAppendTo;
    }
    dialog = new CWDialogPopupView({
      view: CWGererActivitePopUpView,
      viewData: lViewvData
    });
    dialog.setHeight("auto");
    dialog.setWidth(470);
    return dialog;
  }

  setButtonBarListener(): void {
    // this.listenTo(this.btnBarModel,"btn:click",this._buttonAction);
  }

  _fetchWorkflowOpt(callback: () => void): void {
    let wkfPrev, wkfReal;

    this.activesWorkflows = new CWGererActiviteWorkflowActifColl();
    this.activesWorkflows.setHabContext(this.formModel.getHabContext().copy());
    this.activesWorkflows.fetch({
      success: (fresh: CWGererActiviteWorkflowActifColl): void => {
        // Obtain values for activites workflow circuits
        wkfReal = fresh.find((model: CWGererActiviteWorflowActifModel): boolean => {
          return model.get("code") === "ACTREAL";
        });
        wkfPrev = fresh.find((model: CWGererActiviteWorflowActifModel): boolean => {
          return model.get("code") === "ACTPREV";
        });
        this.wkfActiviteRealActiv = wkfReal ? wkfReal.get("active") : false;
        this.wkfActivitePrevActiv = wkfPrev ? wkfPrev.get("active") : false;

        if (callback) {
          callback();
        }
      }
    });
  }
}
