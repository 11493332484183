import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWZoneView } from './cwZone.view';

/**
 * View to paint the body of the home. It stores a reference to every
 * zoneView and manages the swapping of zone hiding or
 * showing the zone.
 */
export class CWBodyView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<CWBaseModel> {

  zViews: { [key: string]: any };

  constructor(options?: Backbone.ViewOptions<TModel>) {
    super(options);
    this.zViews = {};
    this.model.on("change:zone", this._zonesSwapping, this);
  }

  _zonesSwapping(): void {
    const oldZone = this.model.previous("zone");
    const newZone = this.model.get("zone");

    if (oldZone !== "" && !_.isUndefined(oldZone)) {//ne pas changer par CWSTR.isBlank
      this.zViews[oldZone].$el.hide();
    }
    if (newZone !== "" && !_.isUndefined(newZone)) {//ne pas changer par CWSTR.isBlank
      this.zViews[newZone].$el.show();
    }
    this.model.trigger("changed:zone", newZone);
  }

  render(): CWBodyView {
    _.each((this.model as any).configuration.get("confignav").get("zones"), (zone: { [key: string]: any }): void => {
      const zView = new CWZoneView({
        id: "phx-zone-" + zone.id,
        model: this.model,
        zJSON: zone
      });

      this.zViews[zone.id] = zView;
      this.$el.append(zView.render().el);
    }, this);
    return this;
  }
}
