module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="form-row row mt-2">\n    <div class="d-flex flex-column col">\n        <div class="form-group d-flex flex-row half-gutters">\n            <div class="col">\n                <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gererregularisations.du")))==null?'':__t)+
'</label>\n                <div class="c-date-selector input-group">\n                    <div class="input-group-prepend">\n                        <div for="datedeb" class="input-group-text c-labelAppend">\n                            '+
((__t=(i18n.t("common:gererregularisations.du")))==null?'':__t)+
'\n                        </div>\n                    </div>\n                    <input type="text" class="datedeb typeDate form-control" value="" size="10" />\n                </div>\n            </div>\n            <div class="col">\n                <label for="datefin" class="sr-only">'+
((__t=(i18n.t("common:gererregularisations.au")))==null?'':__t)+
'</label>\n                <div class="c-date-selector input-group">\n                    <div class="input-group-prepend">\n                        <div for="datefin" class="input-group-text c-labelAppend">\n                            '+
((__t=(i18n.t("common:gererregularisations.au")))==null?'':__t)+
'\n                        </div>\n                    </div>\n                    <input type="text" class="datefin typeDate form-control" value="" size="10" />\n                </div>\n            </div>\n        </div>\n        <div class="form-group d-flex flex-row half-gutters">\n            <div class="col">\n                <div class="cmbMotif"></div>\n            </div>\n            ';
if (deuxLignes) { 
__p+='\n        </div>\n        <div class="form-group d-flex flex-row half-gutters secondLine">\n            ';
 } 
__p+='\n                <div class="col">\n                    <div class="cw-customControlGroup">\n                        <div class="custom-control custom-radio tous">\n                            <input id="feudt-tout" type="radio" class="feudt tout custom-control-input" name="feudtFiltre" value="0" />\n                            <label for="feudt-tout" class="feudt tout custom-control-label">'+
((__t=(i18n.t('common:listerregularisations.feudt_tous') ))==null?'':__t)+
'</label>\n                        </div>\n                        <div class="custom-control custom-radio uniq">\n                            <input id="feudt-uniq" type="radio" class="feudt uniq custom-control-input" name="feudtFiltre" value="1" />\n                            <label for="feudt-uniq" class="feudt uniq custom-control-label">'+
((__t=(i18n.t('common:listerregularisations.feudt_uniq') ))==null?'':__t)+
'</label>\n                        </div>\n                        <div class="custom-control custom-radio sans">\n                            <input id="feudt-sans" type="radio" class="feudt sans custom-control-input" name="feudtFiltre" value="2" />\n                            <label for="feudt-sans" class="feudt sans custom-control-label">'+
((__t=(i18n.t('common:listerregularisations.feudt_sans') ))==null?'':__t)+
'</label>\n                        </div>\n                    </div>\n                </div>\n        </div>\n        <div class="form-group d-flex flex-row half-gutters">\n            <div class="col">\n                <div class="customFilterButtonsContainer d-flex justif-content-end"></div>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};
