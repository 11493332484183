import * as Backbone from 'Backbone';
import _ from 'underscore';
import { TypoEvtGenModel } from './typoEvtGen.model.js';

/**
 * Parametres ypoEvtGen Collection
 */
export class CWTypoEvtGenColl extends Backbone.Collection {


  /**
   * habilitationV
   */
  habilitationV: string;
  /**
   * habilitationG
   */
  habilitationG: string;
  /**
   * usecase
   */
  usecase: string;


  constructor(models?: typeof TypoEvtGenModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || TypoEvtGenModel;
    super(models, options);
    this.usecase = options.usecase || "app";
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/typoevtgen";
    }
  }

  /**
   * Get a Typologye by code
   */
  get(id: string | number): any {
    const element = Backbone.Collection.prototype.get.call(this, id);
    let result = element;

    if (element instanceof Backbone.Model) {
      result = element.clone();
    }
    return result;
  }
}
