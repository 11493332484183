import { CWSTR } from './cwStr';
import { GLOBAL_DATA } from 'src/globalData';
/**
 * Utility to get the divers parameters.
 */
export class CWDIVERS {

  static get(id?: string): string {
    let lRtn = null;

    if (!CWSTR.isBlank(id) && GLOBAL_DATA.paramDivers && GLOBAL_DATA.paramDivers.get(id)) {
      lRtn = GLOBAL_DATA.paramDivers.get(id).get("valeur");
    }
    return lRtn;
  }
}