import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';

export class CWEtiquetteModel extends CWReadOnlyModel {

  defaults(): { [key: string]: any } {
    return {
      "value": null
    }
  }
}
