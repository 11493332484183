import _ from 'underscore';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWRecapActiviteTypeEvtModel } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwRecapActiviteTypeEvt.model';

export class CWRecapActiviteTypeEvtColl extends CWPaginatedCollection {
  idRecap: string;

  /**
   * Constructor
   * Collection of modtype evenements of Recapitulatif
   */
  constructor(models?: CWRecapActiviteTypeEvtModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWRecapActiviteTypeEvtModel;
    super(models, options);

    if (options && options.idRecap) {
      this.idRecap = options.idRecap;
    }
    this.paginated = false;
    this.url = (): string => { return Configuration.restRoot + "/rest/activite/recap/" + encodeURIComponent(this.idRecap) + "/evenement"; }
  }

  /**
   * This method is overwritting the parent's one.
   */
  clone(): any {
    const temp = CWPaginatedCollection.prototype.clone.call(this);
    temp.idRecap = this.idRecap;
    return temp;
  }
}
