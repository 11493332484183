import * as Backbone from 'Backbone';

/**
 * Transversal model used to synchronize data across the application
 */
export class CWTransversalSyncModel extends Backbone.Model {

    constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
        super(attributes, options);
    }
}