import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLDroitsdelDetail from '../cwDetail.tpl.html';
import { CWBaseFormView as CommonFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDelegantRenderView } from './cwDelegantRender.view';
import { CWDelegationsModel } from '../models/cwDelegations.model';
import { CWDroitsdelWorkflow } from '../models/cwDroitsdel.workflow';
import { CWElementColl } from '../models/cwElement.collection';
import { CWElementLevel3Model } from '../models/cwElementLevel3.model';
import { CWFORMS } from 'utils/cwForms';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMSGS } from 'utils/cwMsgs';
import { CWSelectUtilisateurView } from 'common/referentiel/referentielutilisateur/views/cwSelectUtilisateur.view';
import { CWSTR } from 'utils/cwStr';
import { CWTooltipDelegView } from './cwTooltipDeleg.view';
import { CWTreeTypeGridModel } from 'core/grids/treetypegrid/cwTreeTypeGrid.model';
import { CWTreeTypeGridView } from 'core/grids/treetypegrid/cwTreeTypeGrid.view';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


export class CWFormView extends CommonFormView {
  /**
   * Event launch for Backbone when a inputs of fieldset 'droitsdel-detail-panel' change the property value
   *
   */
  /**
   * Event launch for Backbone when a key up in the inputs in  fieldset 'droitsdel-detail-panel'
   *
   */
  /**
   * Event launch for Backbone when a checkbox change the value
   *
   */
  /**
   * Event launch for Backbone when a mouse is over to span the class 'nivel3'
   *
   */
  /**
   * Event launch for Backbone when a click the link of class 'masquerAfficherDroits'
   *
   */
  /**
   * Event launch for Backbone when a change the value of input of class 'datedeb'
   *
   */

  /**
   * Constructor
   * View for render a delegations in a form
   */
  habilitation: any;
  context: { [key: string]: any };
  habContext: CWHabilitationContext;
  ctxEcran: string;
  workflow: CWDroitsdelWorkflow;
  parentDialog: any;
  table: CWTreeTypeGridView;
  isAllChecked: boolean;
  referentiel: CWSelectUtilisateurView;
  referentielFilter: any;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change .droitsdel-detail-panel :input:not([readonly])": "_change", // See jQuery api, it means
      // input,select,textarea
      "keyup .droitsdel-detail-panel :input:not([readonly])": "_notifyEdition",
      "change :checkbox": "_clickListenerCheck",
      "mouseover span.nivel3": "_tooltipListener",
      "click a.masquerAfficherDroits": "_manageViewDroids",
      "change .droitsdel-detail-panel :input.datedeb": "_searchDroitsFromDatedeb"
    }, CWTYPE.DATE.events());
    super(options);
    this.model = new CWBaseModel({
      value: new CWDelegationsModel({}, { "usecase": this.ctxEcran }),
      mode: "C"
    });
    this.template = TPLDroitsdelDetail;
    this.workflow = options.workflow;
    this.ctxEcran = options.ctxEcran;
    this.context = null;
    this.context = options.context;
    this.habilitation = options.habilitation;
    if (this.habilitation) {
      this.habContext = new CWHabilitationContext({
        onglet: this.ctxEcran,
        foncCour: this.habilitation.get("foncCour"),
        natGest: this.habilitation.get("natGest")
      });
    }
    this.parentDialog = options.parentDialog;
    // Create table editable
    this.table = this._initTable();
    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._mapToForm, this);
    this.model.on("change:mode", this._manageMode, this);
    this.listenTo(this.workflow, 'resetForm', this._mapToForm);
    this.table.model.off("change:mode");
    this.listenTo(this.table.model, "change:mode", this._manageTableMode);
    this.typeMaskByClass = {};
    this.typeMaskByClass["datedeb"] = "DATE_A";
    this.typeMaskByClass["datefin"] = "DATE_A";
    // listen HideShow the form View
    this.listenTo(this.model, "hide:form", this._hideForm);
    this.listenTo(this.model, "show:form", this._showForm);
  }

  _hideForm(): void {
    this.$el.hide();
  }

  _showForm(): void {
    this.$el.show();
  }

  /**
   * Load the Base Droits of the data base when change the datedeb
   */
  _searchDroitsFromDatedeb(): void {
    if (this.model.isValid()) {
      if (this.ctxEcran === "suivicollab" || this.ctxEcran === "utilisateur" || this.ctxEcran === "droitsdel" || this.ctxEcran === "cw-appContainer") {
        this.workflow.droitsBase.datefrom = this.model.get("value").get("datedeb");
        this.workflow.droitsBase.queretro = true;
        this.workflow.droitsBase.delegation = (this.workflow.formModel.get("value").get("code") !== null) ? this.workflow.formModel.get("value").get("code") : this.workflow.formModel.get("value").get("codeDelegation");
      }
      this.workflow.droitsBase.utilCode = this.workflow.utilCode;
      this.workflow.droitsBase.fetch({
        success: (fresh: { [key: string]: any }) => {
          this.workflow.collectionBase = this.workflow._arrayToCollection(fresh.get("profils"));
          this.table.model.coll.reset(this.workflow.collectionBase.models);
          this._manageInitChecks();

          //change the view droits to masquer
          $(this.el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
          this.workflow.fullDroids = true;
        }
      });
    }
  }

  /**
   * See or not see the droits no delegues
   */
  _manageViewDroids(event: any): void {
    const textLink = (event.currentTarget.text || event.currentTarget.innerText);

    if (textLink === i18n.t('droitsdel.masquer')) {
      $(this.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.afficher'));
      this.workflow._masquerDroits("masquer");
    } else if (textLink === i18n.t('droitsdel.afficher')) {
      $(this.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
      this.workflow._masquerDroits("afficher");
    }
  }

  /**
   * Create and view the tooltip of the doits of nivel 3, tooltip value send for parametre
   */
  _tooltipListener(event: any): void {
    const txt = (event.currentTarget.attributes.tooltip && event.currentTarget.attributes && !CWSTR.isBlank(event.currentTarget.attributes.tooltip.value)) ? event.currentTarget.attributes.tooltip.value : null;

    //create the tooltip width the attribute tooltip send for parametre
    if (!CWSTR.isBlank(txt)) {
      $(event.currentTarget).tooltip({ content: "" });
      $(event.currentTarget).tooltip("option", "content", txt);
      $(event.currentTarget).prop("title", "");
      $(event.currentTarget).tooltip({ position: { my: "left-10 top", at: "right bottom" } });
      $(event.currentTarget).tooltip("open");
    }
  }

  /**
   * Check or not check the checkbox of groups and the general
   */
  _clickListenerCheck(event: any): void {
    const element = event.currentTarget.className.split(" ")[0];
    const objDom = $(event.currentTarget);
    const value = objDom.is(":checked");
    let code = "";
    let allChecked = "";

    //select the groupe of check
    if (element === "checkAll") {
      if (value) {
        $(this.el).find(":checkbox[n1],[n2],[n3]").prop([true]);
        $(this.el).find(":checkbox[n1],[n2],[n3]").prop("checked", "checked");
        $(this.el).find(":checkbox[class='checkN2']").attr('disabled', 'disabled');
        $(this.el).find(":checkbox[class='checkN3']").attr('disabled', 'disabled');
      } else {
        $(this.el).find(":checkbox[n1],[n2],[n3]").prop([false]);
        $(this.el).find(":checkbox[n1],[n2],[n3]").removeAttr("checked");
        $(this.el).find(":checkbox[class='checkN2']").removeAttr('disabled');
        $(this.el).find(":checkbox[class='checkN3']").removeAttr('disabled');
      }
    } else if (element === "checkN1") {
      code = objDom.attr("n1");
      //check o uncheck the checkboxs
      $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").removeAttr('disabled');
      $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").removeAttr('disabled');
      if (value) {
        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").prop([true]);
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").prop([true]);
        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").prop("checked", "checked");
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").prop("checked", "checked");
        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").attr('disabled', 'disabled');
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").attr('disabled', 'disabled');
      } else {
        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").prop([false]);
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").prop([false]);
        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").removeAttr("checked");
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").removeAttr("checked");
      }
      //find the all check of all profils
      allChecked = this._ifAllChecked(element);
      if (allChecked === "all") {
        $(this.el).find(":checkbox[class*='checkAll']").prop([true]);
        $(this.el).find(":checkbox[class*='checkAll']").prop("checked", "checked");
      } else if (allChecked === "none") {
        $(this.el).find(":checkbox[class*='checkAll']").prop([false]);
        $(this.el).find(":checkbox[class*='checkAll']").removeAttr("checked");
      } else {
        $(this.el).find(":checkbox[class*='checkAll']").prop([false]);
        $(this.el).find(":checkbox[class*='checkAll']").removeAttr("checked");
      }
    } else if (element === "checkN2") {
      code = objDom.attr("n2");
      if (value) {
        $(this.el).find(":checkbox[class='checkN3'][n2='" + code + "']").prop([true]);
        $(this.el).find(":checkbox[class='checkN3'][n2='" + code + "']").prop("checked", "checked");
      } else {
        $(this.el).find(":checkbox[class='checkN3'][n2='" + code + "']").prop([false]);
        $(this.el).find(":checkbox[class='checkN3'][n2='" + code + "']").removeAttr("checked");
      }
      //find the all check of profil
      allChecked = this._ifAllChecked(element, objDom.attr("n1"));
      if (allChecked === "all") {
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").prop([true]);
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").prop("checked", "checked");
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").change();
      } else {
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").prop([false]);
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").removeAttr("checked");
      }
    } else if (element === "checkN3") {
      let upperCheckbox: any = [];

      code = objDom.attr("n3");
      allChecked = this._ifAllChecked(element, objDom.attr("n1"));
      //find the all check of groupe
      upperCheckbox = $(this.el).find(":checkbox[class='checkN2'][n2='" + objDom.attr("n2") + "']");
      if (upperCheckbox.length === 0) {
        upperCheckbox = $(this.el).find(":checkbox[class*='checkN1'][n1='" + objDom.attr("n1") + "']");
      }
      if (allChecked === "all") {
        upperCheckbox.prop([true]);
        upperCheckbox.prop("checked", "checked");
        upperCheckbox.change();
      } else {
        upperCheckbox.prop([false]);
        upperCheckbox.removeAttr("checked");
        $(this.el).find(":checkbox[class*='checkAll']").prop([false]);
        $(this.el).find(":checkbox[class*='checkAll']").removeAttr("checked");
      }
    }
    //checked in the models
    this._checkedModels();
    this.workflow.btnBarModel.set("mode", "E");
  }

  /**
   * Find the all check of groupe and see if are checked o no
   */
  _ifAllChecked(element?: string, parentCode?: any): any {
    let checks: any, checksChecked: any = [];

    if (element === "checkN1") {
      checks = $(this.el).find(":checkbox[class*='" + element + "']");
      checksChecked = $(this.el).find(":checkbox[class*='" + element + "']:checked");
    } else if (element === "checkN2") {
      checks = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']");
      checksChecked = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']:checked");
    } else if (element === "checkN3") {
      checks = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']");
      checksChecked = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']:checked");
      // in case of no groups or deleNiveau=1
      if (checks.length === 0) {
        if ($(this.el).find(":checkbox[n1='" + parentCode + "']").first().is(":checked")) {
          return "all";
        } else {
          return "none";
        }
      }
    }
    //view if all are checked
    if (checks.length === checksChecked.length) { return "all"; }
    //view if all are not checked
    if (checksChecked.length === 0) { return "none"; }
    return false;
  }

  /**
   * Check or not check the checkbox of groups and the general, when init the component
   */
  _manageInitChecks(): void {
    const checks = $(this.el).find(":checkbox[class*='checkN1']");
    let allChecked: any;

    _.each(checks, (obj: { [key: string]: any }) => {
      const objDom = $(obj);

      allChecked = this._ifAllChecked("checkN3", objDom.attr("n1"));
      if (allChecked === "all") {
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").prop([true]);
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").prop("checked", "checked");
        $(this.el).find(":checkbox[class='checkN2'][n1='" + objDom.attr("n1") + "']").attr('disabled', 'disabled');
        $(this.el).find(":checkbox[class='checkN3'][n1='" + objDom.attr("n1") + "']").attr('disabled', 'disabled');
      } else if (allChecked === "none") {
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").prop([false]);
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").removeAttr("checked");
        if (!this._panelStateIsReadonly()) {
          $(this.el).find(":checkbox[class='checkN2'][n1='" + objDom.attr("n1") + "']").removeAttr('disabled');
          $(this.el).find(":checkbox[class='checkN3'][n1='" + objDom.attr("n1") + "']").removeAttr('disabled');
        }
      }
    });
    allChecked = this._ifAllChecked("checkN1");
    if (allChecked === "all") {
      $(this.el).find(":checkbox[class='checkAll']").prop([true]);
      $(this.el).find(":checkbox[class='checkAll']").prop("checked", "checked");
    } else if (allChecked === "none" || allChecked === false || allChecked === "false") {
      $(this.el).find(":checkbox[class='checkAll']").prop([false]);
      $(this.el).find(":checkbox[class='checkAll']").removeAttr("checked");
    }
  }

  /**
   * Change the value of delegue for all models when click the checkbox
   */
  _checkedModels(): void {
    _.each(this.table.model.coll.models, (obj: { [key: string]: any }) => {
      if (String(obj.get("nivel")) === "1") {
        const complet = $(this.el).find(":checkbox[class*='checkN1'][n1='" + obj.get("id") + "']").is(":checked");

        obj.set("complet", complet);
      } else if (String(obj.get("nivel")) === "2" && obj.levelType === "group") {
        const checked = $(this.el).find(":checkbox[class='checkN3'][n1='" + obj.get("id1") + "'][n2='" + obj.get("id") + "'][item='" + obj.item + "']").is(":checked");

        obj.set("delegue", checked, { silent: true });
      } else if (String(obj.get("nivel")) === "3") {
        const checked = $(this.el).find(":checkbox[class='checkN3'][n1='" + obj.get("id1") + "'][n2='" + obj.get("id2") + "'][item='" + obj.item + "']").is(":checked");

        obj.set("delegue", checked, { silent: true });
      }
    });
  }

  /**
   * Only to don't have the exception because it's not implemented
   */
  _enablePk(): void {//Ne pas supprimer
    //empty 
  }

  /**
   * Creating the table that contains the data from the droits
   */
  _initTable(): CWTreeTypeGridView {
    const tableModel = new CWTreeTypeGridModel({ coll: new CWElementColl({}, { "usecase": this.ctxEcran }) });
    const checkHead = "<input type='checkbox' class='checkAll' value=true style='margin-right: 5px;'></input>";
    const tblColumns = [
      { title: checkHead, property: "check", width: 1 },
      { title: i18n.t('droitsdel.doitsTable'), property: "libelle", width: 3, treecol: true },
      { title: i18n.t('droitsdel.perimetre'), property: "perimetre.code", width: 2 },
      { title: i18n.t('droitsdel.du'), property: "datedeb", width: 2 },
      { title: i18n.t('droitsdel.au'), property: "datefin", width: 2 },
      { title: i18n.t('droitsdel.deleguePar'), property: "delegant", width: 2 }
    ];
    let table: CWTreeTypeGridView = null;

    tableModel.coll.usecase = this.ctxEcran;
    table = new CWTreeTypeGridView({
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: true,
      title: i18n.t('droitsdel.listeDesDroits'),
      rowAltPersonalised: true
      //habilitations : this.model.getHabContext()
    });
    table.height = 200;
    this.isAllChecked = true;
    //insert the checks for select the profils and groupes
    table.cellRenderers["check"] = (model: any): any => {
      let element = $("<input>").attr({ type: 'checkbox', value: true });

      this.isAllChecked = true;
      switch (model.get("nivel")) {
        case 1:
          element.addClass("checkN1");
          element.attr("N1", model.get("id"));
          element.attr("checked", model.get("complet"));
          if (model.get("complet") === false) {
            this.isAllChecked = false;
          }
          break;
        case 2:
          if (model.levelType === "group" && String(this.workflow.deleNiveau) === "0") {
            element.addClass("checkN3");
            element.attr("N1", model.get("id1"));
            element.attr("N2", model.get("id"));
            element.attr("item", model.item);
            element.attr("checked", model.get("delegue"));
            if (model.get("delegue") === false) {
              this.isAllChecked = false;
            }
          } else {
            element = $("<span></span>");
          }
          break;
        case 3:
          if (!CWSTR.isBlank(model.get("nivel2")) && String(this.workflow.deleNiveau) === "0") {
            element.addClass("checkN3");
            element.attr("N1", model.get("id1"));
            element.attr("N2", model.get("id2"));
            element.attr("N3", model.get("id"));
            element.attr("item", model.item);
            element.attr("checked", model.get("delegue"));
            if (model.get("delegue") === false) {
              this.isAllChecked = false;
            }
          } else {
            element = $("<span></span>");
          }
          break;
        case 4:
          element = $("<span></span>");
        // Not default
      }
      //view if is readOnly
      if (element) {
        const isReadonly = this._panelStateIsReadonly();

        CWFORMS.setFieldReadonly(element, isReadonly, true);
      }
      return element;
    };
    table.cellRenderers["libelle"] = (model: any): any => {
      const resp = $("<span>");

      if (String(model.get("nivel")) === "1") {
        resp.html(model.get("libelle"));
        resp.css("font-weight", "bold");
        //if is delegue view in style delegation
        if (model.get("droit") === "D") {
          resp.addClass("ui-phx-delegation");
        }
      } else if (String(model.get("nivel")) === "2") {
        resp.html(model.get("libelle"));
        resp.css("font-weight", "bold");
        //if is delegue view in style delegation
        if (model.get("droit") === "D") {
          resp.addClass("ui-phx-delegation");
        }
      } else if (String(model.get("nivel")) === "3") {
        resp.html(model.get("libelle"));
        resp.css("font-weight", "bold");
        //if is delegue view in style delegation
        if (model.get("droit") === "D") {
          resp.addClass("ui-phx-delegation");
        }
      } else if (String(model.get("nivel")) === "4") {
        let toolText;

        resp.html(model.get("libelle"));
        resp.attr("class", "nivel3");
        //When there is information that should be shown as a tooltip
        if (model.get("vue") || (!CWSTR.isBlank(model.get("profilwkf"))) ||
          model.get("ajout") === true || model.get("modification") === true ||
          model.get("suppression") === true) {
          const tool = new CWTooltipDelegView({ model: model });
          const toolRender = tool.render().el;

          toolText = toolRender.innerHTML;
        } else {
          toolText = "";
        }
        resp.attr("tooltip", toolText);
      }
      return resp;
    };
    table.cellRenderers["perimetre.code"] = (model: any): any => {
      if (model.get("perimetre")) {
        return model.get("perimetre").libelle;
      } else if (model.get("perimetres") && model.get("perimetres")[0]) {
        const perimetres = _.pluck(model.get("perimetres"), 'libelle');

        return perimetres.join('; ');
      }
      return "";
    };
    table.cellRenderers["datedeb"] = (model: any): string => {
      let resp = "";

      if (model.get("datedeb") !== CWTYPE.DATE.INITIAL) {
        resp = CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE"));
      }
      return resp;
    };
    table.cellRenderers["datefin"] = (model: any): string => {
      return CWTYPE.DATE.manageInfinity(model.get("datefin"), CWTYPE._getFormatByCode("DATE"));
    };
    table.cellRenderers["delegant"] = (model: any): any => {
      return new CWDelegantRenderView({ model: model, workflow: this.workflow, ctxEcran: this.ctxEcran });
    };
    table.setColumnsAlignment({
      "check": "center"
    });
    table.setSortableColumns();
    table.model.coll.off("row:click");
    //coll for fetch the nivel 3
    table.fetchSubTree = (model: any, callback: any): void => {
      const code = model.get("nivel1") + "," + model.get("nivel2") + "," + model.get("code");
      const modelNivel4 = new CWElementLevel3Model({}, { "usecase": this.ctxEcran, "code": code }); // change to level 4

      modelNivel4.setHabContext(this.getHabContext());
      modelNivel4.fetch({
        success: (fresh: { [key: string]: any }) => {
          const collection = new Backbone.Collection();
          const arrayResp = _.toArray(fresh.attributes);

          _.each(arrayResp, (obj4: any) => {
            if (obj4 instanceof Object) {
              const model4: any = new CWBaseModel();

              model4.set("nivel", 4);
              model4.set("libelle", obj4.libelle);
              model4.set("filtres", obj4.filtres);
              model4.set("vue", obj4.vue);
              model4.set("profilwkf", obj4.profilwkf);
              model4.set("ajout", obj4.ajout);
              model4.set("modification", obj4.modification);
              model4.set("suppression", obj4.suppression);
              //atributes for tree table view
              model4.isExpanded = false;
              model4.level = 4;
              model4.collapsible = false;
              model4.branch = model.branch;
              //have a children
              model.hasChild = false;
              collection.add(model4);
            }
          });
          callback(model, collection, table.model.coll);
        }
      });
    };
    //We prevent removing the "check" column from the columns that are shown.
    table.setOmittedColumns(["check"]);
    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(this.model.getHabContext());
    return table;
  }

  /**
   * See if the new mode is suport and change the mode in the buttonBarModel in the table
   */
  _manageTableMode(): void {
    const newMode = this.table.model.get("mode");

    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in EditableGrid : " + newMode);
    }
    this.table.model.vuesBtnBarModel.set("mode", newMode);
  }

  /**
   * Loading of data in the table, which it depends of the code of Delegation.
   * Launch when change the value of left table
   */
  _chargeDataTable(): void {
    const formModel = this.model.get("value");
    const collectionData = this.workflow._arrayToCollection(formModel.get("profils"));
    const models = collectionData.models;

    //unchecked te check for all
    $(this.el).find(":checkbox[class='checkAll']").prop([false]);
    $(this.el).find(":checkbox[class='checkAll']").removeAttr("checked");
    if (formModel && formModel.isNew()) {
      this.table.model.coll.reset();
    } else {
      this.table.model.coll.reset(models);
      this._manageInitChecks();
    }
  }

  /**
   * Renders the view to the form
   */
  render(): CWFormView {
    const json = { "i18n": i18n };
    const context: any = {
      Habilitation: this.habilitation.get("foncCour"),
      name: "delegue.matricule",
      ctxEcran: this.ctxEcran
    };

    $(this.el).html(this.template(json));
    this.referentiel = new CWSelectUtilisateurView({ host: this, idComponent: "refutiliz", context: context, "ctxEcran": context.ctxEcran, paginatorPosition: "butom" });
    this.$el.find(".droisDeleguesA").html(this.referentiel.render().el);
    this.$el.find(".droisDeleguesA .phx-selecteur-referentiel");
    this.referentiel.filterOut(this.referentielFilter);
    CWFORMS.setDatepicker(this, ".datedeb");
    CWFORMS.setDatepicker(this, ".datefin");
    $(this.el).find(".table_droits").html(this.table.render().el);
    return this;
  }

  /**
   * Action performed when choosing a data or close a referentiel
   *
   */
  _responseCallback(data: { [key: string]: any }): void {
    const model = this.model.get("value");

    if (_.isNull(model.get("delegue"))) {
      model.set("delegue", {});
    }
    model.get("delegue").matricule = data.matricule;
    model.get("delegue").matriculeaux = data.matriculeaux;
    model.get("delegue").nom = data.nom;
    model.get("delegue").prenom = data.prenom;
    model.get("delegue").utilcode = data.utilcode;
    model.get("delegue").utilnom = data.utilnom;
  }

  /**
   * Map the value of the model to form
   */
  _mapToForm(): void {
    const pal = this.model.get("value");
    const renderers: any = {};
    const fieldset = this.$el.find(".droitsdel-detail-panel");

    // Error management
    pal.off("invalid");
    pal.on("invalid", this._showValidationErrors, this);
    // Clear current validation erros
    this._cleanValidationErrors();
    //values for default (creation)
    if (this.workflow.isCreation) {
      if (this.workflow.ctxPeriode) {
        pal.set("datedeb", this.workflow.ctxPeriode.datedeb);
        pal.set("datefin", this.workflow.ctxPeriode.datefin);
      } else {
        const today = CWTYPE.DATE.getCurrentDate();

        pal.set("datedeb", today);
        pal.set("datefin", CWTYPE.DATE.INFINITY);
      }
    }
    //no view the datefin if is a 31/12/2100
    renderers["datefin"] = (value: any): string => {
      if (value === CWTYPE.DATE.INFINITY) {
        return "";
      } else {
        return value;
      }
    };
    renderers["delegue.matricule"] = (): string => {
      let resp = "";

      if (!CWSTR.isBlank(pal.get("delegue"))) {
        if (!CWSTR.isBlank(pal.get("delegue").nom)) { // if have a collaborateur
          if (this.workflow.paramIdCollab === "matric" || CWSTR.isBlank(this.workflow.paramIdCollab)) {
            resp = pal.get("delegue").nom + " " + pal.get("delegue").prenom + " (" + pal.get("delegue").matricule + ")";
          } else if (this.workflow.paramIdCollab === "matric_aux") {
            resp = pal.get("delegue").nom + " " + pal.get("delegue").prenom + " (" + pal.get("delegue").matriculeaux + ")";
          }
        } else { // if have a utilisateur
          resp = i18n.t('droitsdel.utilisateur') + " " + pal.get("delegue").utilnom;
        }
      }
      this.$el.find(".delegue\\.matricule").attr("title", resp);
      return resp;
    };
    // Map the inputs, checkboxes, selects, textareas from the model
    this._mapModelToForm(fieldset, pal, renderers);
    this._chargeDataTable();
    this._checkVisibility();
  }

  _checkVisibility(): void { //Customer #183246
    const pal = this.model.get("value");

    if (!CWSTR.isBlank(this.workflow.pDiversModifDeleg) && (this.workflow.pDiversModifDeleg.get("valeur") === "1")) {
      if (!CWSTR.isBlank(pal.get("datedeb")) && !this.workflow._isPostDate(pal.get("datedeb")) && !this.workflow.isCreation) {
        CWFORMS.setFieldReadonly($(this.el).find(".datedeb"), true);
      } else {
        CWFORMS.setFieldReadonly($(this.el).find(".datedeb"), false);
      }
      if (!CWSTR.isBlank(pal.get("datefin")) && !this.workflow._isPostDate(pal.get("datefin")) && !this.workflow.isCreation) {
        CWFORMS.setFieldReadonly($(this.el).find(".datefin"), true);
      } else {
        CWFORMS.setFieldReadonly($(this.el).find(".datefin"), false);
      }
      if ((!this.workflow._isPostDate(pal.get("datedeb")) || !this.workflow._isPostDate(pal.get("datefin"))) && !this.workflow.isCreation) {
        CWFORMS.setFieldReadonly($(this.el).find(".delegue\\.matricule"), true);
      } else {
        CWFORMS.setFieldReadonly($(this.el).find(".delegue\\.matricule"), false);
      }
    }
  }

  _showSupprimerError(): void {
    CWMSGS.showError(i18n.t('messages:GT_2108'), null, this.workflow.$appendTo);
  }

  /**
   * Filter the data return of the referentiel
   */
  referentielRestriction(matricule: number): void {
    if (this.referentiel) {
      if (!CWSTR.isBlank(matricule)) {
        this.referentiel.filterOut([matricule]);
      } else {
        this.referentiel.filterOut([]);
      }
    } else {
      this.referentielFilter = [matricule];
    }
  }

  _panelStateRenderCustomFields(isReadonly: boolean): void {
    const element = this.$el.find(".checkAll");

    if (!CWSTR.isBlank(element) && element.length > 0) {
      CWFORMS.setFieldReadonly(element, isReadonly, true);
    }
  }
}