module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<label class="cw-libelleEnrichi '+
((__t=( labelClass ))==null?'':__t)+
'">\r\n    '+
((__t=( UTILS.getSVGIcon("trombone", "", 16) ))==null?'':__t)+
'\r\n    '+
((__t=(i18n.t("common:gererpiecesjointes.libelle")))==null?'':__t)+
'\r\n</label>\r\n<ul class="listPieces"></ul>';
}
return __p;
};
