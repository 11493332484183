import * as Backbone from 'Backbone';
import TPLAppPortlet from './portlet.tpl.html';


/**
 * Portlet item container
 */
export class CWPortletView extends Backbone.View<Backbone.Model>{

  template: (json?: { [key: string]: any }) => string;
  public title: string;
  public tooltip: string;
  public view: Backbone.View<Backbone.Model>;
  public parent: Backbone.View<Backbone.Model>;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options.className = options.className || "phx-portlet";
    super(options);
    this.template = TPLAppPortlet;
    this.title = options.title;
    this.tooltip = options.tooltip;
    this.view = options.view;
    this.parent = options.parent;
  }

  render(): CWPortletView {
    this.$el.html(this.template());
    $(".phx-porlet-title", this.el).html(this.title);
    $(".phx-portlet-header", this.el).attr("title", this.tooltip);
    $(".phx-portlet-content", this.el).html(this.view.render().el);
    this.$el.css("position", "relative");
    // Set WAI-ARIA attributes
    $(".phx-porlet-title", this.el).attr("id", this.cid + "-porlet-title"); // it gives id to the title to be used as label.
    this.$el.attr("role", "region");
    this.$el.attr("tabindex", "0");
    this.$el.attr("labelledby", this.cid + "-porlet-title");
    this.$el.attr("aria-expanded", "true");
    return this;
  }

  setTitle(title: string): void {
    this.title = title;
    $(".phx-porlet-title", this.el).html(this.title);
  }

  setContentHeight(height: string | number): void {
    $(".phx-portlet-content", this.el).css("max-height", height + "px");
  }
}
