import { ScheduleColorItemView } from './scheduleColorItem.view.js';
import { ScheduleTextItemView } from './scheduleTextItem.view.js';
import { STR } from 'utils/str.js';

export var ScheduleRowView = Backbone.View.extend({
  /**
   * Add this to have Outline
   *
   */
  /**
   * i18n
   */
  //REVIEW: 	i18n : phx.i18n.common,
  /**
   * tagName
   */
  tagName: "div",
  /**
   * HORIZONTAL
   */
  HORIZONTAL: "H",
  /**
   * VERTICAL
   */
  VERTICAL: "V",
  /**
   * Constructor
   * Draw the row. If type==Text, we paint only the hours, overwise we paint colored cells
   */
  initialize: function(options) {
    /**
     * Plages
     */
    this.plages = options.plages;
    /**
     * type
     */
    this.type = options.type;
    /**
     * Direction (horizontal or vertical)
     */
    this.direction = options.direction || this.HORIZONTAL;
    if (_.isBoolean(options.showTitle)) {
      /**
       * A boolean that indicates if title must be shown
       */
      this.showTitle = options.showTitle;
    } else {
      this.showTitle = true;
    }

    if (_.isBoolean(options.exceptional)) {
      /**
       * A boolean that indicates if exceptional mark must be shown
       */
      this.exceptional = options.exceptional;
    } else {
      this.exceptional = false;
    }
  },

  /**
   * Renders this view
   */
  render: function() {
    var _this = this;

    $(this.el).empty();
    if (this.direction === this.VERTICAL) {
      $(this.el).css("width", "100%");
      $(this.el).css("height", "100%");
    }
    //Show the last debut time in the blank close plage, ignoring the tolfin plage.
    if (!STR.isBlank(this.plages)) {
      var size = this.plages.length;

      if (this.plages[size - 2].type !== "tolfin") {
        this.plages[size - 1].hdebut = this.plages[size - 2].hfin;
      }
    }
    _.each(this.plages, function(plage) {
      if (_this.type === "text") {
        var textItem = new ScheduleTextItemView({ plage: plage, direction: _this.direction });

        $(_this.el).append(textItem.render().el);
      } else {
        var colorItem = new ScheduleColorItemView({
          plage: plage,
          direction: _this.direction,
          showTitle: _this.showTitle,
          exceptional: _this.exceptional
        });
        $(_this.el).append(colorItem.render().el);
      }
    });
    return this;
  }
});
