module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="c-panneauDeroulant groupecompteurs-consultation"> \n    <fieldset class="cw-fieldset groupecompteurs-consultation-detail">        \n          <div class="form-group groupecompteurs-consultation-groupe">\n            <label for="groupe" class="text-left cw-required">'+
((__t=(i18n.t("common:resultatgrpcompteurs.groupe")))==null?'':__t)+
'</label>\n            <span class="cmbGroupe"></span>\n          </div>        \n    </fieldset>\n    <div class="groupecompteurs-consultation-empty cw-titreBrique">\n      '+
((__t=(i18n.t("common:resultatgrpcompteurs.aucun_groupe")))==null?'':__t)+
'\n    </div>\n</div>\n';
}
return __p;
};
