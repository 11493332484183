module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-row gererrecapitulatifs-form">\n    <div class="gererrecapitulatifs-form-inputs">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n\n        <div class="form-group col-12">\n            <label for="recapitulatif" class="cw-libelleEnrichi cw-required">'+
((__t=(i18n.t('common:gererrecapitulatifs.recap')))==null?'':__t)+
'</label>\n            <span class="cmbType"></span>\n        </div>\n\n        <div class="form-group col-12">\n            <label for="periode" class="cw-libelleEnrichi cw-required">'+
((__t=(i18n.t('common:gererrecapitulatifs.periode')))==null?'':__t)+
'</label>\n            <span class="cmbPeriode"></span>\n        </div>\n\n\n        <div class="form-group col-12">\n            <label for="commentaire_infocomp" class="inline table_label cw-libelleEnrichi">'+
((__t=(i18n.t('common:gererrecapitulatifs.commentaire')))==null?'':__t)+
'</label>\n            <textarea class="commentaire_infocomp form-control" style="white-space:pre-wrap;" maxlength="200" cols="48" rows="5"></textarea>\n        </div>\n    </div>\n    <div class="createBtns"></div>\n</div>';
}
return __p;
};
