import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';


/**
 * Workflow for UC Gestion des regularisations
 */

/**
 * Model for Initialisation (EvtRegulSoumisWkf)
 */
export class CWMotifRegulInitModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "wkf_activ": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = Configuration.restRoot + "/rest/motifregul/init";
  }
}