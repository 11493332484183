/**
 * JavaScript logging utility
 */
export var LOG = {

  level: "ERROR",

  /**
   * Type of the logs
   *		"DEBUG" : 1,
   *		"INFO" : 2,
   *		"WARN" : 3,
   *		"ERROR" : 4
   * }
   */
  levelMap: {
    "DEBUG": 1,
    "INFO": 2,
    "WARN": 3,
    "ERROR": 4
  },

  methodMap: {
    "DEBUG": "debug",
    "INFO": "info",
    "WARN": "warn",
    "ERROR": "error"
  },

  /**
   * Loged the message type DEBUG.
   */
  debug: function(msg) {
    this.innerLog("DEBUG", msg);
  },

  /**
   * Loged the message type INFO.
   */
  info: function(msg) {
    this.innerLog("INFO", msg);
  },

  /**
   * Loged the message type WARN.
   */
  warn: function(msg) {
    this.innerLog("WARN", msg);
  },

  /**
   * Loged the message type ERROR.
   */
  error: function(msg) {
    this.innerLog("ERROR", msg);
  },

  /**
   * Launch the message to de console, depend of type the log
   */
  innerLog: function(pLevel, msg) {
    if (!window.console) {
      return;
    }
    if (this.levelMap[this.level] <= this.levelMap[pLevel]) {
      var method = this.methodMap[pLevel];

      if (console[method]) {
        console[method]("[" + pLevel + "] - " + msg);
      } else {
        LOG.debug(msg);
      }
    }
  }
};
