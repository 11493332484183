import _ from 'underscore';
import TPLWkfhistoDetail from '../cwDetail.tpl.html';
import TPLWkfhistoDetailLine from '../cwDetailLine.tpl.html';
import TPLWkfhistoDetailTable from '../cwDetailTable.tpl.html';
import { Collection, Model, ViewOptions } from 'Backbone';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWCollapsibleBlockView } from 'src/core/components/collapsible_block/cwCollapsibleBlock.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWHistoDemModelColl } from '../models/cwHistoDemModel.collection';
import { CWHistoEnsembleModelColl } from '../models/cwHistoEnsembleModel.collection';
import { CWHistoEventModelColl } from '../models/cwHistoEventModel.collection';
import { CWHistoRecapitulatifsAndActivitesModel } from '../models/cwHistoRecapitulatifsAndActivites.model';
import { CWHistoSouhaitOriginModel } from '../models/cwHistoSouhaitOrigin.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWWKF } from 'utils/cwWkf';
import { i18n } from 'src/i18n.js';


interface CWWkflhistoViewInterface<TModel extends Model = CWBaseModel> extends ViewOptions<TModel> {
  component?: string;
  detailTemplate?: (json?: any, options?: any) => string;
  buttons?: Array<string>;
}

/**
 * View for the workflow component
 */
export class CWWkflhistoView extends CWCollapsibleBlockView {

  private loadedData: boolean;
  public context: { [key: string]: any };
  private title: string;
  private titleAction: string;
  private souhaitOrigine: CWHistoSouhaitOriginModel;
  public collection: CWHistoEventModelColl;
  private tempalteTable: any;
  private templateLine: any;

  constructor(opt?: CWWkflhistoViewInterface) {
    if (!opt) {
      opt = {};
    }
    opt.component = opt.component ? opt.component : "wkfhisto";
    opt.detailTemplate = opt.detailTemplate ? opt.detailTemplate : TPLWkfhistoDetail;
    opt.buttons = opt.buttons ? opt.buttons : [];
    opt.events = _.extend({}, opt.events);
    super(opt);
    // list of components to load
    this.loadedData = false;


    this.tempalteTable = TPLWkfhistoDetailTable;
    this.templateLine = TPLWkfhistoDetailLine;

    this.model = new CWBaseModel({
      histoDem: new CWHistoDemModelColl(),
      histoEvent: new CWHistoEventModelColl(),
      histoEnsemble: new CWHistoEnsembleModelColl()
    });
    this.model.on("expand", this._loadDataInFirstTime, this);
  }

  _loadDataInFirstTime(data: any): void {
    if (!this.loadedData && data.expanded) {
      this._loadData();
    }
  }

  setContext(context: { [key: string]: any }): void {
    this.context = context;
    this.title = "";
    this.titleAction = "";

    if (CWSTR.isBlank(this.context.ctxDemId) || this.context.ctxDemId === 0 || this.context.ctxDemId === "null") {
      this.context.ctxDemId = null;
    }
    if (CWSTR.isBlank(this.context.ctxRefEvenement) || this.context.ctxRefEvenement === "null") {
      this.context.ctxRefEvenement = null;
    }

    //Default values
    if (this.context.ctxModeRestitution === "Imbrique") {
      this.title = i18n.t('common:wkfhisto.title_imbrique');
    }

    this.model.get("histoDem").setHabContext(
      new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation //eslint-disable-line
      })
    );
    this.model.get("histoEvent").setHabContext(
      new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation //eslint-disable-line
      })
    );
    this.model.get("histoEnsemble").setHabContext(
      new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation //eslint-disable-line
      })
    );
    this._manageBlocEstDeplie();
    this.setTitle(this.title + this.titleAction);
    this.showBlock();
  }

  _loadData(): void {
    this.loadedData = true;
    //1er les activités(et aussi les récapìtulatifs) et après les autres événements
    if (this.context.ctxEnsemble === true && this.context.ctxTypeEvenement === "ACTRECAP") {
      let ltypoAct = this.context.ctxTypeEvenement;
      const lhistoAct = new CWHistoRecapitulatifsAndActivitesModel();

      if (!CWSTR.isBlank(this.context.ctxHabilitation) && typeof this.context.ctxHabilitation === "string") {
        if (this.context.ctxHabilitation.indexOf("PRE") >= 0 || this.context.ctxHabilitation.indexOf("ACTPR") >= 0) {
          //activité prevue
          ltypoAct = "ACTPREV";
        }
        if (this.context.ctxHabilitation.indexOf("REC") >= 0) {
          //récapitulatif d'activité
          ltypoAct = "ACTRECAP";
        }
        if (this.context.ctxHabilitation.indexOf("REA") >= 0 || this.context.ctxHabilitation.indexOf("ACTRE") >= 0) {
          //activité realisée
          ltypoAct = "ACTREAL";
        }
      }
      lhistoAct.set("type", ltypoAct);
      lhistoAct.set("evenement", this.context.ctxRefEvenement);
      lhistoAct.set("iddemande", this.context.ctxDemId);
      lhistoAct.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation //eslint-disable-line
      }));
      lhistoAct.save(null, {
        success: (fresh) => {
          this.setContent(this._generateContent(fresh));
        }
      });
    } else {
      if (this.context.ctxEnsemble === true) {
        this.collection = this.model.get("histoEnsemble");
        this.collection.ctxTypeEvenement = this.context.ctxTypeEvenement;
        this.collection.evenements = this.context.ctxRefEvenement;
        this.collection.demandes = this.context.ctxDemId;

        this.collection.fetch({
          success: (fresh: CWHistoEventModelColl) => {
            this.setContent(this._generateContent(fresh));
            if (fresh.length === 0) {
              this.hideIcon();
            }
          }
        });

      } else if (this.context.ctxRefEvenement !== null) {
        this.collection = this.model.get("histoEvent");
        this.collection.ctxRefEvenement = this.context.ctxRefEvenement;
        this.collection.ctxTypeEvenement = this.context.ctxTypeEvenement === "Activité" ? "ACTIVITE" : this.context.ctxTypeEvenement;
        this.souhaitOrigine = null;
        if ((this.collection.ctxTypeEvenement === "REGULARISATION" || this.collection.ctxTypeEvenement === "EVTGEN" || this.collection.ctxTypeEvenement === "ABSENCE" ||
          this.collection.ctxTypeEvenement === "SOUHAITABS" || this.collection.ctxTypeEvenement === "PRET" || this.collection.ctxTypeEvenement === "BADGEAGE" ||
          this.collection.ctxTypeEvenement === "ACTIVITE" || this.context.ctxTypeEvenement === "ACTRECAP")) {
          if (this.collection.ctxTypeEvenement === "ACTIVITE") {
            if (this.collection.habContext.get('foncCour') === 'RES_VAL_ACTPR.G'
              || this.collection.habContext.get('foncCour') === 'RES_VAL_ACTPR.V'
              || this.collection.habContext.get('foncCour') === 'COL_ACT_PREV.G'
              || this.collection.habContext.get('foncCour') === 'COL_ACT_PREV.V'
              || this.collection.habContext.get('foncCour') === 'RES_ACT_PREV.G'
              || this.collection.habContext.get('foncCour') === 'RES_ACT_PREV.V') {
              this.collection.ctxTypeEvenement = 'ACTPREV';
            } else {
              this.collection.ctxTypeEvenement = 'ACTREAL';
            }
          }
          if (!CWSTR.isBlank(this.context.ctxRefSouhait)) {
            // read souhait origine
            this.souhaitOrigine = new CWHistoSouhaitOriginModel();
            this.souhaitOrigine.setHabContext(new CWHabilitationContext({
              onglet: this.context.ctxEcran,
              foncCour: this.context.ctxHabilitation
            }));
            this.souhaitOrigine.ctxRefSouhait = this.context.ctxRefSouhait;
            this.souhaitOrigine.fetch({
              success: () => {
                this.collection.fetch({
                  success: (fresh: CWHistoSouhaitOriginModel) => {
                    this.setContent(this._generateContent(fresh));
                  }
                });
              }
            });
          } else {
            this.collection.fetch({
              success: (fresh: CWHistoEventModelColl) => {
                this.setContent(this._generateContent(fresh));
              }
            });
          }
        }
      } else if (this.context.ctxDemId !== null) {
        this.collection = this.model.get("histoDem");
        this.collection.ctxDemId = this.context.ctxDemId;
        this.collection.ctxModeRech = this.context.ctxModeRech;
        this.collection.fetch({
          success: (fresh: CWHistoEventModelColl) => {
            this.setContent(this._generateContent(fresh));
            if (fresh.length === 0) {
              this.hideIcon();
            }
          }
        });
      }
    }
  }

  _manageVisualization(): void {
    if (this.context.ctxBlocEstDeplie) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  _manageBlocEstDeplie(): void {
    this.expanded = true;
    this.expand();
    this.loadedData = true;
    /*if (this.context.ctxModeDefaut === "Replie") {
      this.expanded = false;
      this.collapse();
      this.loadedData = false;
    } else {
    }*/
  }

  _generateContent(collection: Collection | CWHistoSouhaitOriginModel): string {
    let content = "";
    let i: number;
    let showSouhait = true;

    if (collection && collection instanceof Collection) {
      for (i = 0; i < collection.length; i++) {
        content += this.templateLine({ 'line': this._buildLine(collection.at(i)), 'index': i, "customClass": "" });
        if (collection.at(i).get("codefonctionnel") === "CO_AT_D" && this.souhaitOrigine) {//Si c'est une trasnformation, l'information du souhait doit aller après ce code fonctionnel (regarder la SFD)
          content += this.templateLine({ 'line': this._buildLineSouhaitOrigine(this.souhaitOrigine), 'index': i, 'customClass': "c-wkfhisto__souhaitOrigine" });
          showSouhait = false;
        }
      }
    }
    if (showSouhait && this.souhaitOrigine) {
      content += this.templateLine({ 'line': this._buildLineSouhaitOrigine(this.souhaitOrigine), 'index': i, 'customClass': "c-wkfhisto__souhaitOrigine" });
    }

    return this.tempalteTable({ 'content': content });
  }

  _buildLineSouhaitOrigine(model: CWHistoSouhaitOriginModel): string {
    let date = "";
    if (model.get("souhait")) {
      date = CWTYPE.DATE.format(model.get("souhait")[0]["@date"], CWTYPE._getFormatByCode("DATE_A"));
    } else if (model.ctxRefSouhait) {
      date = CWTYPE.DATE.format(model.get("@date"), CWTYPE._getFormatByCode("DATE_A"));
    }

    let message = "";
    message = "<div class='cw-texteRubrique c-wkfhisto__souhaitOrigine__title'>" + i18n.t('messages:GL_1032') + " </div>";
    message += "<div class='cw-texteNormal c-wkfhisto__souhaitOrigine__content'>" + i18n.t('messages:GL_1033', { "1": date, interpolation: { escapeValue: false } }) + " </div>";
    return message;
  }

  _buildLine(model: Model): string {
    return CWWKF.buildLine(model);
  }
}
