import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 *  sauvegarderPreference
 */

export class CWSauveGarderPreferenceModel extends CWBaseModel {

  workflow: any;

  defaults(): { [key: string]: any } {
    return {
      "perscriu": null,
      "perscrig": null,
      "preferences": [{
        "varint1": null,
        "varint2": null,
        "varint3": null,
        "varint4": null,
        "varint5": null,
        "varchar1": "", //« NORMEID,USERID»
        "varchar2": "",
        "varchar3": "",
        "varchar4": "",
        "varchar5": "",
        "vardat1": "",
        "vardat2": "",
        "vardat3": "",
        "vardat4": "",
        "vardat5": "",
        "code": ""
        // autres préférences {} ..
      }],
      medical: true
    }
  }


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.habilitationV = options.habilitationV ? options.habilitationV : "RES_PLMED";
    options.habilitationG = options.habilitationG ? options.habilitationG : "RES_PLMED";
    options.usecase = options.usecase ? options.usecase : "planmed";
    options.canViewTreatment = options.canViewTreatment ? options.canViewTreatment : true;
    super(attributes, options);
    if (options !== undefined) {
      this.workflow = options.workflow;
    }
    this.url = (): string => {
      return Configuration.restRoot + "/rest/planning/preference";
    }
  }

  // OVERRIDE default clone method to copy workflow
  clone(): CWBaseModel | any {
    return new (this.constructor(this.attributes, { workflow: this.workflow }));
  }

}
