import { BaseModel } from '../models/base.model.js';
import { HABILITATION } from '../../utils/habilitation.js';
import { i18n } from '../../i18n.js';
import { STR } from 'utils/str.js';
import TPL_common_button_bar from './button_bar.tpl.html';

export var ButtonBarView = Backbone.View.extend({
  /**
   * Event launched when a button is clicked
   */
  /**
   * Event launched when the model of this view changes its mode
   */
  /**
   * Event launched when the model of this view changes the property enabled
   */
  /**
   * Event launched when there is any event for the model
   */
  /**
   * Add this to have Outline
   *
   */

  /**
   * tagName
   */
  tagName: "span",
  /**
   * className
   */
  className: "phx-buttonBar",

  focusToFormSaveElement: undefined,

  /**
   * Constructor
   * Button bar view.
   * It's model supports:
   * Events: change:mode, btn:click (buttonId)
   */
  initialize: function(options) {
    var self = this;

    this.template = TPL_common_button_bar;
    //For simple models it's not needed to create a specific model
    this.model = new BaseModel({
      mode: "C",
      enabled: true
    });
    this.model.on("change:mode", this._manageMode, this);
    this.model.on("change:enabled", this._manageEnabled, this);
    /**
     * When set to true, no icons text is shown for this button
     */
    this.iconsOnly = false;
    if (options && options.iconsOnly) {
      if (!_.contains([true, false], options.iconsOnly)) {
        throw new Error("Property iconsOnly can have only these values: (true / false)");
      }
      this.iconsOnly = options.iconsOnly;
    }
    if (options && options.ignoreHabilitationsNature) {
      this.ignoreHabilitationsNature = options.ignoreHabilitationsNature;
    }
    if (options && options.tagName) {
      this.tagName = options.tagName;
    }
    /**
     * Wether all buttons in this view are enabled or not
     */
    this.isEnabled = true;
    /**
     * This object has a boolean property for each button that indicates if user has droits over this button
     */
    this.droits = {};
    this.droits["new"] = true;
    this.droits["copy"] = true;
    this.droits["delete"] = true;
    this.droits["save"] = true;
    this.droits["revert"] = true;
    /**
     * This object has a boolean property for each button that indicates if it is shown or not
     */
    this.visible = {};
    this.visible["new"] = true;
    this.visible["copy"] = true;
    this.visible["delete"] = true;
    this.visible["save"] = true;
    this.visible["revert"] = true;
    // Delegate the events row:* to the coll
    this.model.on("all", function(eventName) {
      if (_.indexOf(eventName, ":") > 0) {
        var eventParts = eventName.split(":");
        var event = eventParts[0];
        var className = eventParts[1];
        switch (event) {
          case "hide":
            self.hideButton(className);
            break;
          case "show":
            self.showButton(className);
            break;
          case "enable":
            self.enableButton(className);
            break;
          case "disable":
            self.disableButton(className);
            break;
          default:
            break;
        }
      }
    });
    if (STR.isBlank(this.focusToFormSaveElement)) {
      this.focusToFormSaveElement = $(".focusToFormSave");
    }
    /**
     *  Object that represent all the titles of infobulle for each button
     */
    this.buttonsTitle = { "revert": i18n.t('common:revert') };
    this.dblclick = 0;
  },

  /**
   * Sets droits and manages enabling and disabling buttons depending on habilitation
   */
  habilitations: function(habilitations) {

    if (!STR.isBlank(this.habilitations)) {
      if (!HABILITATION.canCreate(habilitations)) {
        this.droits["new"] = false;
        this.droits["copy"] = false;
      }
      if (!HABILITATION.canDelete(habilitations)) {
        this.droits["delete"] = false;
      }
      if (!HABILITATION.canCreate(habilitations) && !HABILITATION.canUpdate(habilitations)) {
        this.droits["save"] = false;
        this.droits["revert"] = false;
      }
      this._manageMode();
    }
  },

  /**
   * Sets droits and manages enabling and disabling buttons depending on habilitation
   */
  manageHabContext: function() {

    if (!STR.isBlank(this.model.getHabContext())) {
      if (this.ignoreHabilitationsNature) {
        if (!HABILITATION.canView(this.model.getHabContext().get("foncCour"))) {
          this.droits["new"] = false;
          this.droits["copy"] = false;
          this.droits["delete"] = false;
          this.droits["save"] = false;
          this.droits["revert"] = false;
        }
      } else {
        if (!HABILITATION.canCreate(this.model.getHabContext().get("foncCour"))) {
          this.droits["new"] = false;
          this.droits["copy"] = false;
        }
        if (!HABILITATION.canDelete(this.model.getHabContext().get("foncCour"))) {
          this.droits["delete"] = false;
        }
        if (!HABILITATION.canCreate(this.model.getHabContext().get("foncCour")) && !HABILITATION.canUpdate(this.model.getHabContext().get("foncCour"))) {
          this.droits["save"] = false;
          this.droits["revert"] = false;
        }
      }
    }
  },

  /**
   * Enables or disables the whole buttonbar when enable property has changed.
   */
  _manageEnabled: function() {
    this.enabled(this.model.get("enabled"));
  },

  /**
   * Enables or disables the whole buttonbar.
   */
  enabled: function(enable) {
    this.model.set("enabled", enable, { silent: true });
    this.isEnabled = enable;
    if (this.isEnabled) {
      $(this.el).removeClass("ui-state-disabled");
      $("button", this.el).css("cursor", "");
    } else {
      $(this.el).addClass("ui-state-disabled");
      $("button", this.el).css("cursor", "default");
    }
  },

  /**
   * Hides a button by its name
   */
  hideButton: function(name) {
    this.visible[name] = false;
    $(this.el).find("." + name).parent(".btnBar-btn-container").hide();
  },

  /**
   * Shows a button by its name
   */
  showButton: function(name) {
    this.visible[name] = true;
    if (this.droits[name]) {
      $(this.el).find("." + name).parent(".btnBar-btn-container").show();
    }
  },

  /**
   * Enables a button by its name
   */
  enableButton: function(name) {
    if (this.droits[name]) {
      $(this.el).find("." + name).button({ disabled: false });
    }
  },

  /**
   * Disables a button by its name
   */
  disableButton: function(name) {
    $(this.el).find("." + name).button({ disabled: true });
  },

  /**
   * Checks if mode is a valid mode. If it is not, an error is shown. Disables buttons if it is necessary.
   */
  _manageMode: function() {
    var newMode = this.model.get("mode");
    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in ButtonBar : " + newMode);
    }
    this._disableButtons(newMode);
  },

  /**
   * Disables/Enables buttons depending on current button bar mode (R:consultation, E:Edition, C:Creation)
   */
  _disableButtons: function(mode) {
    var $button = null;

    this.manageHabContext();

    if (mode !== "E") {
      // Added to solve the problem that appears when disable a button dynamically
      // this button doesn't trigger the mouseleave event
      $(this.el).find(".revert").trigger("mouseleave");

      // Disable focus order in forms. It returns to the normal order.
      this.focusToFormSaveElement.attr("tabindex", "-1");
    } else {
      // Enable focus order in forms. It allow to change the focus order using hidden element.
      this.focusToFormSaveElement.attr("tabindex", "0");
    }

    // R : consultation mode
    // E : edition mode
    // C : creation mode
    $(this.el).find(".save").button({ disabled: (mode !== "E") });
    $(this.el).find(".revert").button({ disabled: (mode !== "E") });
    $(this.el).find(".new").button({ disabled: (mode === "E") });
    $(this.el).find(".delete").button({ disabled: (mode !== "R") });
    $(this.el).find(".copy").button({ disabled: (mode !== "R") });

    if ($(this.el).find(".revert").is(':disabled')) {
      $(this.el).find("button .revert").text("");
      $(this.el).find(".revert").removeAttr("title");
    } else {
      $(this.el).find("button .revert").text(i18n.t('common:revert'));
      $(this.el).find(".revert").attr("title", i18n.t('common:revert'));
    }

    for (var button in this.droits) {
      if (button) {
        if (this.droits[button] === false) {
          $button = $(this.el).find("." + button);
          if ($button.length > 0) {
            $button.hide();
          }
        }
        if (this.visible[button] === true && this.droits[button] === true) {
          $button = $(this.el).find("." + button);
          if ($button.length > 0) {
            $button.show();
          }
        }
      }
    }
  },

  /**
   * View JQuery events
   */
  events: {
    "click .ui-icon": "_toggleMenu",
    "click button": "_clickListener",
    "dblclick button": "_clickListener",
    "click .customButton": "_customClickListener"
  },

  /**
   * When a button is clicked launches its management if it is enabled. The event must be listened and managed from uc
   */
  _clickListener: function(event) {
    var _this = this;

    if (this.isEnabled) {
      var btnClicked = event.currentTarget.value;

      if (event.type === "click") {
        setTimeout(function() {
          var double = _this.dblclick;

          if (double > 0) {
            _this.dblclick = 0;
            return false;
          } else {
            _this.model.trigger("btn:click", btnClicked);
          }
          return true;
        }, 300);
      } else if (event.type === "dblclick") {
        this.dblclick = 2;
        event.stopPropagation();
      }
    }
  },

  /**
   * When a custom button is clicked launches its management if it is enabled. The event must be listened and managed from uc
   */
  _customClickListener: function(event) {
    if (this.isEnabled && !STR.isBlank($(event.target).attr("value"))) {
      var btnClicked = $(event.target).attr("value");

      this.model.trigger("btn:click", btnClicked);
    }
  },

  /**
   * Shows/Hides menu with many options
   */
  _toggleMenu: function(event) {
    var self = this;

    if ($(event.currentTarget).parent().hasClass("revert") === false) {
      var menuId = event.currentTarget.className.split(" ")[0].replace("-menu", "");
      if ($("." + menuId + "-menu-content", this.el).is(":visible")) {
        $("." + menuId + "-menu-content", this.el).hide();
      } else {
        $("." + menuId + "-menu-content", this.el).show();
        //If you click out of the menu, close the menu.
        var mouseDown = function(event) {
          var element = $(self.el).find(event.target);
          if (element.length !== 0) {
            self._customClickListener(event);
          }
          $("." + menuId + "-menu-content", self.el).hide();
        };

        $(document).off("mousedown." + this.cid + "_" + menuId);
        $(document).one("mousedown." + this.cid + "_" + menuId, mouseDown);
      }
    }
  },

  /**
   * Renders this view
   */
  render: function() {
    var self = this;
    var json = { "i18n": i18n };
    $(this.el).html(this.template(json));
    var showText = !this.iconsOnly;
    $(this.el).find(".save").button({ text: showText });
    $(this.el).find(".revert").button({ icons: { primary: "ui-icon-arrowrefresh-1-w" }, text: false });
    $(this.el).find(".new").button({ text: showText });
    $(this.el).find(".delete").button({ text: showText });
    $(this.el).find(".copy").button({ text: showText });

    if (!_.isEmpty(this.buttonsTitle)) {
      _.each(this.buttonsTitle, function(title, button) {
        if (!STR.isBlank(title)) {
          $(self.el).find("." + button).parent().attr("title", title);
        }
      });
    }

    //Default mode
    this._disableButtons("mode", this.model.get("mode"));

    return this;
  },

  /**
   * Adds a new button to the button bar
   * @param {String} insertTo - "first" / "last" / The class of the element after which the button is inserted
   * @param {String} name - The name of the button
   * @param {String} label - The label text of the button that will be shown to the user
   * @param {String} icon - The icon shown for this button
   * @param {Boolean} showText - Wether to show text or not
   * @param {Boolean} habilitation - Wether if there are habilitations to show the button or not
   * @param {String} title - set title four infobulle
   * @param {Boolean} discret - Boolean, if set to true the button is added with style discret
   */
  addButtonAt: function(insertTo, name, label, icon, showText, habilitation, title, hasMenu, menuOptions, discret) {
    var $groupButton = $("<div style='display:inline-block;'>");
    var menuIcon;
    var menu = "";
    var $button = $("<button type='button' class='" + name + " ui-button-special' value='" + name + "' data-cy='" + name + "'>" + label + "</button>");
    var l_showText = showText;

    if (!STR.isBlank(hasMenu) && hasMenu === true) {
      menuIcon = "<span class='" + name + "-menu ui-icon ui-icon-triangle-1-s phx-hover' style='display: inline-block; position: relative; left: 4px;'></span>";
      $button = $("<span type='button' role='button' class='" + name + " ui-button-special' style='height: 24px;'><span class='customButton' value='" + name + "' data-cy='" + name + "' style='font-size: 1em; font-weight: bold; font-family: Tahoma,Arial,sans-serif; vertical-align: top;'>" + label + "</span>" + menuIcon + "</span>");
    }

    if (!_.isBoolean(l_showText)) {
      l_showText = true;
    }
    l_showText = (!this.iconsOnly && l_showText);

    if (!STR.isBlank(hasMenu) && hasMenu === true) {

      menu = $("<div style='display:none; position: fixed;'></div>");
      menu.addClass(name + "-menu-content");
      menu.addClass("phx-buttonBar-menu-content");
      menu.addClass("ui-menu");

      var $options = $("<ul></ul>");
      for (var i = 0; i < menuOptions.length; i++) {
        $options.append("<li class='" + menuOptions[i].name + " button customButton phx-hover' value='" + menuOptions[i].name + "' data-cy='" + menuOptions[i].name + "' style='white-space:nowrap;'>" + menuOptions[i].label + "</li>");
      }
      menu.append($options);
    }

    //Infobulle
    if (title && !STR.isBlank(title)) {
      $groupButton.attr("title", title);
    }
    $button.button({ text: l_showText });
    $groupButton.addClass("btnBar-btn-container");
    $groupButton.append($button);
    if (!STR.isBlank(hasMenu) && hasMenu === true) {
      $groupButton.append(menu);
    }

    // Position the button
    if (insertTo === "first") {
      $($groupButton).append("&nbsp;");
      $(this.el).prepend($groupButton);
    } else if (insertTo === "last") {
      $(this.el).append($groupButton);
      $($groupButton).prepend("&nbsp;");
    } else {
      $(this.el).find("." + insertTo).parent().after($groupButton);
    }

    if (icon) {
      $(this.el).find("." + name).button("option", "icons", { primary: icon });
    }

    if (_.isBoolean(habilitation)) {
      this.droits[name] = habilitation;
    } else {
      this.droits[name] = true;
    }
    this.visible[name] = true;

    this._manageMode();

    if (_.isBoolean(discret) && discret === true) {
      $button.addClass("phx-button-discret");
    }
  },

  /**
   * Adds a new button to the button bar
   */
  addButton: function(name, label, icon, showText, habilitation, title, hasMenu, menuOptions, discret) {
    this.addButtonAt("last", name, label, icon, showText, habilitation, title, hasMenu, menuOptions, discret);
  },

  /**
   * Adds a new button at the top of the button bar
   */
  addButtonFirst: function(name, label, icon, showText, habilitation, title, hasMenu, menuOptions, discret) {
    this.addButtonAt("first", name, label, icon, showText, habilitation, title, hasMenu, menuOptions, discret);
  },

  /**
   * Delete button from the button bar
   */
  deleteButton: function(name) {
    this.visible[name] = false;
    this.droits[name] = false;

    $(this.el).find("." + name).parent().remove();
  },

  changeButtonLabel: function(name, label) {
    var button = $(this.el).find("." + name);
    if (button) {
      button.button({ label: label });
    }
  },

  /**
   * Set title to button (for infobulle)
   */
  setTitle: function(button, title) {
    if (button && !STR.isBlank(button)) {
      this.buttonsTitle[button] = title;
    }

  }
});
