import _ from 'underscore';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';

export class CWListerecpFilterMoreModel extends CWFilterBaseModel {

  constructor() {
    super()
    this.groupedErrors = {
      filtre_datedeb: ["filtre_datedeb", "filtre_datefin"],//eslint-disable-line
      filtre_datefin: ["filtre_datedeb", "filtre_datefin"]//eslint-disable-line
    };
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } | null {
    const errors: { [key: string]: any } = {};

    //Period validation
    const datedeb = {
      "name": "filtre_datedeb",
      "value": attrs.filtre_datedeb,
      "label": undefined as string
    };
    const datefin = {
      "name": "filtre_datefin",
      "value": attrs.filtre_datefin,
      "label": undefined as string,
      "isInfinity": false
    };
    const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
    if (!CWSTR.isBlank(errorMsg)) {
      errors.filtre_datedeb = errorMsg.datedeb; //eslint-disable-line
      errors.filtre_datefin = errorMsg.datefin; //eslint-disable-line
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }

}
