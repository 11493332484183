module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-group listerprets-dialogPopupRow">\n    <fieldset class="listerprets-dialogPopupRow cw-fieldset">\n        <div class="phx-formTable">\n            <div class="input-group">\n                <div class="phx-listerprets-data">\n                    <label for="commentaire" class="cw-label-entrezcomment text-left" title="'+
((__t=(i18n.t("common:listerprets.commentaires")))==null?'':__t)+
'">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
' '+
((__t=(i18n.t("common:listerprets.commentaires")))==null?'':__t)+
'</label>\n                    <textarea class="commentaire form-control" rows="6" cols="40" maxlength="200" style="resize:none" aria-required="true"></textarea>\n                    <!--Histo wkf component-->\n                    <span class="listerprets-wkfhisto"></span>\n                    <span class="buttons"></span>\n                </div>\n            </div>\n    </fieldset>\n</div>';
}
return __p;
};
