import { STR } from 'utils/str.js';

/**
 * Selecteur Activite mode simple model
 */
export var CompletDomModel = Backbone.Model.extend({

  /**
   * Default data model
   *	code: "",
   *	libelle: "",
   *	fmtact: "",
   *	fmtstra: "",
   *  hierarchie:false,
   *  actstruct:{
   *		code : "",
   *		libelle : ""
   *	  }
   * }
   */
  defaults: {
    "domcode": "",
    "domlib": "",
    "hiera": false,
    "fmtact": "",
    "straid": "",
    "fmtstra": "",
    "grillebesoin": false,
    "hierarchie": false,
    "hiertype": {
      "code": null,
      "libelle": ""
    },
    "saisieft": false
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && STR.isBlank(response.id) && !STR.isBlank(response.domcode)) {
      response.id = response.domcode;
    }
    if (response.hiertype === undefined) {
      response.hiertype = this.defaults.hiertype;
    }
    return response;
  }
});
