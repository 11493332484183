import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLPopulationsmenuPopupSelectCollaborateur from '../popupSelectCollaborateur.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWPopulationMenuView } from './cwPopulationMenu.view';
import { CWPopulationSelectColaborateursModel } from '../models/cwPopulationSelectColaborateurs.model';
import { CWSelectCollabView } from 'common/referentiel/referentielcollaborateur/views/cwSelectCollab.view';
import { CWSTR } from 'src/utils';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

export class CWPopupSelectCollabView extends CWBaseFormView {

  referenView: CWSelectCollabView;
  diversIdCollab: Backbone.Model;
  parent: CWDialogPopupView;
  parentView: CWPopulationMenuView;
  $appendTo: JQuery;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .buttonAppliquer": "_appliquerCollab",
      "change :input:not([readonly])": "_change"
    }, options.events);
    options.className = "populationsmenu";
    super(options);
    const context = {
      habilitation: "RES_POPCOLLAB.G",
      usecase: "populationsmenu"
    };

    this.template = TPLPopulationsmenuPopupSelectCollaborateur;
    this.parent = options.parent;
    this.parentView = options.parentView;
    this.$appendTo = options.appendTo;
    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab");
    this.referenView = new CWSelectCollabView({
      "context": context,
      "exclure_fusionencours": false,
      "type_collab": "R",
      "appendTo": this.$appendTo
    });
    this.model = new CWPopulationSelectColaborateursModel();
    this.listenTo(this.referenView.model, "referentielChanged", this._modelChanged);
  }

  _modelChanged(): void {
    this.model.set("matric", this.referenView.model.get("matric"));
    if (!CWSTR.isBlank(this.referenView.model.get("matric"))) {
      this.$el.find(".buttonAppliquer").removeClass("btn-primary-bis");
    }
  }

  _getModel(): CWPopulationSelectColaborateursModel {
    return this.model;
  }

  _appliquerCollab(): void {
    if (CWSTR.isBlank(this.referenView.model.get("matric"))) {
      this.$el.find(".cmbCollab input.nom").focus();
    } else {
      this.model.set("matric", this.referenView.model.get("matric"));
      // Error management
      this.model.off("invalid");
      this.model.on("invalid", this._showValidationErrors, this);
      if (this.model.isValid()) {
        const model = this.referenView.model;

        this.parentView._setCollaborateurOnTraversalComponent(model, (rawData: { [key: string]: any }): void => {
          const prenomCollab = model.get("prenom");
          let libelle = model.get("nom") + " " + prenomCollab + " (";

          $(this.parent.el).parents().find(".phx-population-menu-header").html(i18n.t('populationsmenu.tous'));
          // Default value is matric
          if (this.diversIdCollab.get("valeur") === "matric_aux") {
            libelle += model.get("matricaux");
          } else {
            libelle += model.get("matric");
          }
          libelle += ")";
          this.referenView.model.set("libelle", libelle);
          this.parent.model.set("collab", model);
          this.parent.model.set("ident", rawData.get("ident"));
          this.parent.close();
        });
      }
    }
  }

  render(): CWPopupSelectCollabView {
    const json = { "i18n": i18n };

    this.$el.append(this.template(json));
    this.$el.find(".cw-required").css("display", "none");
    this.referenView.setIdCollab();
    this.$el.find(".cmbCollab").html(this.referenView.render().el);
    this.parent._setTitle(i18n.t('populationsmenu.select_collab'));
    this.$el.find(".cmbCollab input.nom").css("width", "400px");
    this.$el.find(".cmbCollab input.nom").focus();
    if (CWSTR.isBlank(this.referenView.model.get("matric"))) {
      this.$el.find(".buttonAppliquer").addClass("btn-primary-bis");
    }
    return this;
  }
}
