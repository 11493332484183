import _ from 'underscore';
import { Collection, Model, ModelSetOptions } from 'Backbone';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';


/**
 * Model to manage the context of an habilitation
 */
export class CWBaseGridModel<TColl extends CWPaginatedCollection = CWPaginatedCollection, TModel extends CWBaseModel = CWBaseModel> extends CWBaseModel {
  /**
   * Event that Show/Hide a column
   */
  /**
   * Event that Locks a column.
   */
  /**
   * Event that Unlocks a column.
   */
  /**
   * Event that Blocks a column.
   */
  /**
   * Event that Unblocks a column.
   */
  /**
   * Event that scrolls the grid to the selected Row.
   */
  /**
   * Event that scrolls the grid to the selected Row if is not visible.
   */
  /**
   * Event triggered when user select a row.
   */
  /**
   * Event triggered when user unselects a row.
   */
  /**
   * Event that Reset the Height of the rows.
   */
  /**
   * Event fired when click on the header checkbox on multiselection.
   */

  /**
   * Event fired to inform a usecase that view a different view for this table has been selected
   */
  /**
   * Event fired when click on a view of the table menu
   */

  /**
   * Model underlying a basic DataGrid.
   */
  public coll: TColl;
  public enableSelectionOnDblclick: boolean;
  public multiselectColl: Collection<TModel>;
  public notSelectable: boolean;
  public vuesBtnBarModel: CWReadOnlyModel;
  public isChecked: boolean;
  public lastCode: number | string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.unset("coll");
    if (!attributes.coll && !options.collection) {
      throw new Error("You must initialize a DataGridModel with a Collection");
    }
    this.coll = attributes.coll || options.collection;
    /**
     * Bacause in TS is obligatory inherit from this class for the models, we need to prevent asing "row click" and other for each model, with one is sufficient
     */
    this.listenTo(this.coll, "row:click", this._manageRowSelection);
    if (attributes.enableSelectionOnDblclick === true) {
      this.unset("enableSelectionOnDblclick");
      this.enableSelectionOnDblclick = attributes.enableSelectionOnDblclick;
      this.listenTo(this.coll, "row:dblclick", this._manageRowSilentSelection);
    }
    // Delegate the events row:* to the coll
    this.on("all", (eventName, value, fromClick: boolean = false) => {
      if (eventName.indexOf("row:") !== -1) {
        const model = this.coll.get(value);

        if (!model) {
          //If it's called a row operation over a model not present in the grid collection, it will be ignored
          return;
        }
        this.coll.trigger(eventName, model, { fromClick: fromClick });
      }
    }, this);
    this.set("value", null); // value model
    this.notSelectable = false;
    this.multiselectColl = new Collection();
    this.notSelectable = false;
  }

  /**
   * Select (silently) the row when do double click on it.
   *
   */
  _manageRowSilentSelection(model: Model): void {
    if (model) {
      this.set("value", model);
    }
  }

  /**
   * Called when do a click on a row, performs the selection of that row.
   */
  _manageRowSelection(model: Model, options?: ModelSetOptions): void {
    if (model) {
      if (!this.get("value")) {
        this.set("value", model, options);
        model.trigger("row:select", model);
      } else if (this.get("value") !== model) {
        this.get("value").trigger("row:unselect", this.get("value"));
        model.trigger("row:select", model);
        this.set("value", model, options);
      } else if (this.get("value") === model) {
        this.trigger("row:selectedAgain", this, options);
      }
    } else {
      this.set("value", null);
    }
  }

  /**
   * Scroll the table to show the model passed.
   */
  scrollToRow(model: CWBaseModel, callback?: () => void, modeforced?: boolean): JQueryXHR {
    let loadedModel = this.coll.get(model) ? this.coll.get(model).clone() : null;
    let oldSelectedModel: any = null;
    const fetchDataEmpty: JQueryXHR = null;

    if (modeforced === true) {
      loadedModel = null; //il faut nettoyer le modèle afin de faire une nouvelle recherche
    }
    if (loadedModel) {
      const startIndex = this.coll.pagination.startIndex;
      const modelIndex = this.coll.indexOf(loadedModel);

      this.trigger("scroll:to", startIndex + modelIndex);
      if (callback) {
        callback();
      }
      return fetchDataEmpty;
    }
    oldSelectedModel = this.get("value");
    // If the action is new we get the previous value
    if (!this.get("value")) {
      oldSelectedModel = this.previous("value");
    }
    return this.coll.findAndGo(model, (selectOld: CWBaseModel) => {
      const startIndex = this.coll.pagination.startIndex;
      let modelIndex: number = null;
      const lIndexKeyColl = this.coll.map(function (model) {
        return model.id || model.cid;
      });

      if (selectOld && oldSelectedModel && !CWSTR.isBlank(oldSelectedModel.id)) {
        loadedModel = this.coll.get(oldSelectedModel.id);
      } else {
        loadedModel = this.coll.get(model.id);
      }
      if (CWSTR.isBlank(loadedModel) && this.coll.length > 0) {
        if (!CWSTR.isBlank(selectOld) && !CWSTR.isBlank(this.coll.get(model.id))) {
          loadedModel = this.coll.get(model.id);
        } else if (!CWSTR.isBlank(this.previous("value"))) {
          loadedModel = this.previous("value");
          //il faut vérifier si le modèle existe ou pas (par exemple, il y a un filtre appliqué)
          if (!CWSTR.isBlank(loadedModel) && !CWSTR.isBlank(loadedModel.id)) {
            loadedModel = this.coll.get(loadedModel.id);
            if (_.isEmpty(loadedModel)) {
              //on sélectionne le premier élément de la collection
              loadedModel = this.coll.at(0);
            }
          } else {
            //on sélectionne le premier élément de la collection
            loadedModel = this.coll.at(0);
          }
        } else {
          //on sélectionne le premier élément de la collection
          loadedModel = this.coll.at(0);
        }
      }
      if (!CWSTR.isBlank(loadedModel)) {
        modelIndex = lIndexKeyColl.indexOf(loadedModel.id);
      }
      if (CWSTR.isBlank(modelIndex) || modelIndex < 0) {
        modelIndex = 0;
      }
      this.trigger("scroll:to", startIndex + modelIndex);
      if (callback) {
        callback();
      }
    }, modeforced);
  }

  /**
   * Used to select the passed model on the Grid, scrolls to that element.
   */
  selectRow(model: CWBaseModel, callback?: (model?: CWBaseModel) => void, options?: { [key: string]: any }, modeforced?: boolean): void {
    let loadedModel = this.coll.get(model) as CWBaseGridModel;
    let oldSelectedModel: any = null;

    if (loadedModel && !loadedModel.notSelectable) {
      const startIndex = this.coll.pagination.startIndex;
      const modelIndex = this.coll.indexOf(loadedModel);

      this.trigger("scroll:to", startIndex + modelIndex);
      this._manageRowSelection(loadedModel, options);
      if (callback) {
        callback(loadedModel);
      }
      //WCAG
      loadedModel.trigger("cell:select", this.coll.editModeCellSelected);
      this.coll.editModeCellSelected = null;
      return;
    }
    oldSelectedModel = this.get("value");
    // If the action is new we get the previous value
    if (!this.get("value")) {
      oldSelectedModel = this.previous("value");
    }
    this.coll.findAndGo(model, (selectOld: CWBaseGridModel) => {
      let startIndex: number = null;
      let modelIndex: number = null;

      if (selectOld && oldSelectedModel && !CWSTR.isBlank(this.coll.get(oldSelectedModel.id))) {
        loadedModel = this.coll.get(oldSelectedModel.id) as CWBaseGridModel;
      } else {
        loadedModel = this.coll.get(model.id) as CWBaseGridModel;
      }
      startIndex = this.coll.pagination.startIndex;
      modelIndex = this.coll.indexOf(loadedModel);
      if (this.coll.paginated !== true) {
        this.trigger("scroll:to", startIndex + modelIndex);
      } else {
        this.trigger("scroll:to", modelIndex);
      }
      this._manageRowSelection(loadedModel);
      if (callback) {
        callback(loadedModel);
      }
    }, modeforced);
  }

  notSelectableRow(model: CWBaseModel): void {
    const loadedModel = this.coll.get(model) as CWBaseGridModel;

    if (loadedModel) {
      loadedModel.notSelectable = true;
      loadedModel.trigger("row:notSelectable", loadedModel);
    }
  }

  selectableRow(model: CWBaseModel): void {
    const loadedModel = this.coll.get(model) as CWBaseGridModel;

    if (loadedModel) {
      loadedModel.notSelectable = false;
      loadedModel.trigger("row:Selectable", loadedModel);
    }
  }

  supprimedRow(model: CWBaseModel): void {
    const loadedModel = this.coll.get(model) as CWBaseGridModel;

    if (loadedModel) {
      loadedModel.notSelectable = true;
      loadedModel.trigger("row:supprimed", loadedModel);
    }
  }

  /**
   * Select the first row of the table, scroll to the beginning.
   */
  selectFirstRow(silentArg?: boolean): void {
    let model = this.coll.at(0) as CWBaseGridModel;
    const silent = CWSTR.isBlank(silentArg) ? false : silentArg;

    if (this.coll.pagination.startIndex === 0) {
      if (silent === false && !CWSTR.isBlank(model) && model.notSelectable !== true) {

        this._manageRowSelection(_.first(this.coll.models));
      } else {
        for (let i = 1; i < this.coll.length && (model.notSelectable && model.notSelectable === true); i++) {
          model = this.coll.at(i) as CWBaseGridModel;
        }
        if (CWSTR.isBlank(model) || model.notSelectable === true) {
          model = null;
        }
        this._manageRowSelection(model);
      }
      this.trigger("scroll:to", 0);
    } else {
      //It is needed to paginate to first page
      this.coll.goTo(0, () => {
        this.selectFirstRow();
        this.trigger("scroll:to", 0);
      });
    }
  }

  resetScroll(): void {
    this.trigger("scroll:to", 0);
  }

  /**
   * Select the last row of the table, scroll to the end.
   */
  selectLastRow(silentArg?: boolean): void {
    const lastPageIndex = this.coll.totalRecords - this.coll.pagination.size;
    let newIncrement = 1;
    let model = this.coll.at(this.coll.length - 1) as CWBaseGridModel;
    const silent = CWSTR.isBlank(silentArg) ? false : silentArg;

    if (this.coll.pagination.startIndex >= lastPageIndex) {

      if (silent === false && model.notSelectable !== true) {

        this._manageRowSelection(_.last(this.coll.models));
      } else {
        for (let i = lastPageIndex - newIncrement; i >= 0 && (model.notSelectable && model.notSelectable === true); i--) {
          newIncrement++;
          if (lastPageIndex - newIncrement > 0) {
            model = this.coll.at(this.coll.length - newIncrement) as CWBaseGridModel;
          }
          if (CWSTR.isBlank(model) || model.notSelectable === true) {
            model = null;
          }
        }
        this._manageRowSelection(model);
      }
      this.trigger("scroll:to", this.coll.totalRecords);
    } else {
      //It is needed to paginate to first page
      this.coll.goTo(lastPageIndex, () => {
        this.selectLastRow();
        this.trigger("scroll:to", this.coll.totalRecords);
      });
    }
  }

  /**
   * Select the previous row of the current selected row on the table.
   */
  selectPreviousRow(): void {
    const index = this.coll.indexOf(this.get("value"));
    let newIncrement = 1;
    let model = this.coll.at(index - newIncrement) as CWBaseGridModel;

    if (model && model.notSelectable === true) {
      for (let i = index - newIncrement; i >= 0 && (model.notSelectable === true); i--) {
        newIncrement++;
        if (index - newIncrement >= 0) {
          model = this.coll.at(index - newIncrement) as CWBaseGridModel;
        }
      }
    }
    if (index > 0 && (model.notSelectable !== true)) {
      this._manageRowSelection(model);
      this.trigger("scroll:toIfHidden", index, -newIncrement);
    } else {
      this._manageRowSelection(null);
      this.trigger("scroll:toIfHidden", index, -newIncrement);
    }
  }

  /**
   * Select the next row of the current selected row on the table.
   */
  selectNextRow(): void {
    const index = this.coll.indexOf(this.get("value"));

    if (index === -1) {
      // Avoid start selection in the first row if no row is
      // previously selected
      return;
    }
    if (index < this.coll.length - 1) {
      const newIncrement = 1;
      let model = this.coll.at(index + newIncrement) as CWBaseGridModel;

      if (CWSTR.isBlank(model) || model.notSelectable === true) {
        for (let i = index + newIncrement; i < this.coll.length && (model.notSelectable === true); i++) {
          model = this.coll.at(i) as CWBaseGridModel;
        }
      }
      if (CWSTR.isBlank(model) || model.notSelectable !== true) {
        this._manageRowSelection(model);
        this.trigger("scroll:toIfHidden", index, newIncrement);
      } else {
        this._manageRowSelection(null);
        this.trigger("scroll:toIfHidden", index, newIncrement);
      }
    }
  }

  /**
   * Methods to lock a column, lock a column mean that this column disappear
   * and can't be show with the menu
   */
  lock(key: string): void {
    this.trigger("lock:column", key);
  }

  /**
   * Methods to unlock a column, the column is showed again on the menu to display it.
   */
  unlock(key: string): void {
    this.trigger("unlock:column", key);
  }

  /**
   * Method to block a column,
   * blocking a column mean that this column can't be hidden/shown with the menu.
   */
  block(key: string): void {
    this.trigger("block:column", key);
  }

  /**
   * Method to unblock a column, this column can be hidden/shown with the menu.
   */
  unblock(key: string): void {
    this.trigger("unblock:column", key);
  }

  /**
   * Function that triggers the reset of the height of the rows.
   */
  resetRowHeight(): void {
    this.trigger("reset:rowHeight");
  }

  /**
  * Method to maximize a column, this column will have the maximum value 12.
  */
  maximizeCol(key: string, widthSplitA?: number): void {
    this.trigger("maximize:column", key, widthSplitA);
  }

  /**
     * Method to reset a column, the column will be reset to the initial value
     */
  resetCol(key: string): void {
    this.trigger("reset:column", key);
  }
}
