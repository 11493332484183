import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { GLOBAL_DATA } from 'src/globalData';

/**
 * Briques configuration.
 */

/**
 * Model for Brique 'Situations à Régulariser'
 */

export class CWSituationsARegulariserModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "nbrang": 10,
      "total": null,
      "index": 1,
      "situation": {
        "prenom": "",
        "typeano": "B",
        "libelle": "Badg. en attente de validation",
        "minuteano": 1,
        "dateano": "20130910",
        "comment": "",
        "matricule": "110359",
        "heureano": 0,
        "nom": "",
        "matriculeaux": ""
      }
    }
  }

  paramMaxElements: string

  constructor(model?: CWBaseModel | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(model)) {
      options = model;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(model, options);
    this.version = "6.0.0";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/brique/respo/situation";
    }
    // param divers to control max lines in the "brique."
    // Nombre maximum d'éléments à charger dans une brique d'accueil
    this.paramMaxElements = GLOBAL_DATA.paramDivers.get("BRCnbElt").get("valeur");
  }
}