import { FORMS } from 'utils/forms.js';
import { HABILITATION } from 'utils/habilitation.js';
import { STR } from 'utils/str.js';
import { TYPE } from 'tda/tda.js';

export var FormView = Backbone.View.extend({

  _getModel: function() {
    try {
      return this.model.get("value");
    } catch (error) {
      return null;
    }
  },

  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  },

  _change: function(event, data, a_silentValue, ifValidate) {
    var silentValue = a_silentValue;
    var model = this._getModel();
    var target = event.target;
    var className = target.className.split(" ")[0];
    //Check APP TYPEs
    var typeSelector = TYPE.supportedClass;

    if (!_.isBoolean(silentValue)) {
      silentValue = true;
    }
    if ($(target).is(typeSelector)) {
      var typeClassesArray = typeSelector.replace(/[\.,]/g, "").split(" "); //eslint-disable-line
      var inputMask = "";
      var parse = {};
      var typeClass = _.find(typeClassesArray, function(type) {
        if ($(target).hasClass(type)) {
          return true;
        }
        return false;
      }, this);

      if (!STR.isBlank(this.typeFormatByClass) && !STR.isBlank(this.typeFormatByClass[className])) { //TypeCustom
        inputMask = TYPE._getMaskByFormat(this.typeFormatByClass[className]);
        parse[className] = TYPE._getTypeByTypeClass(typeClass).parse(target.value, inputMask);
      } else { //Other types
        parse[className] = TYPE._getTypeByTypeClass(typeClass).parse(target.value);
      }
      STR.setElValue(model, className, parse[className]["val"], { silent: silentValue });
      if (STR.isBlank(ifValidate) || (!STR.isBlank(ifValidate) && ifValidate)) {
        // We validate the errors if it's a typeClass
        this._controlError(true, model, className, target, parse);
        if (this.isEditedRowView) { //In case we are inside an editedRowView, we don't want to show errors in the whole fieldset
          //we only want to modify the errors for this row (to avoid wrong errors for inputs with the same class name)
          if (!_.isEmpty(model.validationError.errors)) {
            FORMS.showErrors($(this.el), model.validationError.errors);
          } else {
            FORMS.cleanErrors($(this.el));
          }
        } else { //a form that is NOT an editedRowView
          if (!_.isEmpty(model.validationError.errors)) {
            FORMS.showErrors($(this.el), model.validationError.errors);
          } else {
            FORMS.cleanErrors($(target).parents("fieldset"));
          }
        }
      }
    } else {
      switch (target.type) {
        case "checkbox":
          STR.setElValue(model, className, target.checked, {
            silent: silentValue
          });
          break;
        default:
          // Gets the reference of the view if it exists.
          var viewRef = $(target).prop("viewRef");

          if ($(target).hasClass("phx-autocomplete-input")) {
            STR.setElValue(model, className, $(target).prop("data-code"), {
              silent: silentValue
            });
          } else if (viewRef && viewRef.isComboBoxView2) {
            if (viewRef.multiselection === false) {
              STR.setElValue(model, className, viewRef.getItemId(), {
                silent: silentValue
              });
            } else {
              STR.setElValue(model, className, viewRef.getItemId(), {
                silent: silentValue
              });
            }

          } else if (!($(target).hasClass("phx-referentiel-input") || $(target).hasClass("phx-chemin-input") || $(target).hasClass("phx-list-builder-select") ||
              $(target).hasClass("phx-selecteur-activite-input"))) {
            STR.setElValue(model, className, target.value, {
              silent: silentValue
            });
          }
      }
      if (STR.isBlank(ifValidate) || (!STR.isBlank(ifValidate) && ifValidate)) {
        // We validate the errors if isn't a typeClass
        this._controlError(false, model, className, target);
      }

    }
    this._notifyEdition(event);
  },

  /** We will utilize the _controlError function to determine Validation errors.

  In var "tempError" we'll save the errors that we generate (new errors) and we're going to compare that with old mistakes
  for we can add or delete it to our array of errors and can show it correctly.

  - pointToSBracket (Chronotime function). If this function receive a variable, it always will return this one..
    but if this function receives  a compound variable (ejº libelle.code), the function will return that there is after point
    (Ejº code).
  - In this function (_controlError) the variable "isTyped" is to determine if is a typeClass (true) or not (false).
  */
  _controlError: function(isTyped, model, className, target, parse) {
    var tempError = {};
    var arrayErrors = [className];
    var infoCompName = null;

    if (model.validate) {
      tempError = model.validate(model.attributes, { individual: true, editedAttr: className });
    }
    if (!model.validationError || _.isEmpty(model.validationError.errors)) {
      model.validationError = { errors: {}, errorValidation: {} };
    }
    if (model.validationError && model.groupedErrors && model.groupedErrors[className]) {
      var grouped = model.groupedErrors;
      arrayErrors = grouped[className];
    }
    infoCompName = model.infoCompAttributeName;
    for (var i = 0; i < arrayErrors.length; i++) {
      var l_className = arrayErrors[i];
      var simpleClassName = arrayErrors[i].split(".")[0];

      if (tempError && !STR.isBlank(FORMS.pointToSBracket(tempError.errors, l_className))) {
        FORMS.assignValue(model.validationError.errors, l_className, FORMS.pointToSBracket(tempError.errors, l_className));
      } else if (!STR.isBlank(FORMS.pointToSBracket(model.validationError.errors, l_className))) {
        FORMS.removeValue(model.validationError.errors, l_className);
      } else if (!STR.isBlank(FORMS.pointToSBracket(model.validationError.errors, simpleClassName)) && (STR.isBlank(infoCompName) || infoCompName !== simpleClassName)) {
        FORMS.removeValue(model.validationError.errors, simpleClassName);
      }
      if (parse && parse[arrayErrors[i]] && parse[arrayErrors[i]]["errors"] && STR.isBlank(FORMS.pointToSBracket(model.validationError.errors, arrayErrors[i]))) {
        FORMS.assignValue(model.validationError.errors, arrayErrors[i], parse[arrayErrors[i]]["errors"]);
      }
    }
    if (this.isEditedRowView) { //In case we are inside an editedRowView, we don't want to show errors in the whole fieldset
      //we only want to modify the errors for this row (to avoid wrong errors for inputs with the same class name)
      if (!_.isEmpty(model.validationError.errors)) {
        FORMS.showErrors($(this.el), model.validationError.errors);
      } else {
        FORMS.cleanErrors($(this.el));
      }
    } else { //a form that is NOT an editedRowView
      if (!_.isEmpty(model.validationError.errors)) {
        if (isTyped === true) {
          FORMS.showErrors($(this.el), model.validationError.errors);
        } else {
          FORMS.showErrors($(target).parents("fieldset"), model.validationError.errors);
        }
      } else {
        FORMS.cleanErrors($(target).parents("fieldset"));
      }
    }
  },

  _notifyEdition: function(event) {
    var target = null;
    var className = null;

    if (event.type === "keyup") {
      var key = event.which || event.keyCode;

      // if the key is not printable it's ommited.
      if ((key >= 9 && key <= 45) || (key >= 91 && key <= 93) || (key >= 112 && key <= 222)) {
        return;
      }
    }
    target = event.target;
    className = target.className.split(" ")[0];
    // trigger event to notify form has been edited
    this.model.trigger("form:edited", className + " > " + target.value);
  },

  _manageMode: function() {
    var newMode = this.model.get("mode");

    if (!_.contains(["E", "C"], newMode)) {
      throw new Error("Mode not supported in Form : " + newMode);
    }
    this._enablePk(newMode === "C");
  },

  _enablePk: function(enabled) {
    var size = 0;

    if ((STR.isBlank(this.pkSelectors) || this.pkSelectors.length <= 0) && Configuration.development === true) {
      throw new Error("The form view have to be pkSelector setted correctly");
    }

    size = this.pkSelectors.length;
    for (var i = 0; i < size; i++) {
      var fieldset = this._getFieldset();
      var fieldName = this.pkSelectors[i].replace(".", "\\.");
      var input = fieldset.find(":input." + fieldName);
      var label = fieldset.find("label[for=" + fieldName + "],label[for^=" + fieldName + "_uid_]");
      var inputsNumber = input.length;

      for (var j = 0; j < inputsNumber; j++) {
        FORMS.setFieldReadonly(input.eq(j), !enabled, false);
      }
      if (enabled) {
        label.addClass('required');
      } else {
        label.removeClass('required');
      }
    }
  },

  _getFieldset: function() {
    return $(this.el).find("fieldset");
  },

  _mapModelToForm: function(fieldset, model, renderers, avoidLinkingLabels, callback) {
    var typeRenderers = {};
    var self = this;
    var typeClasses = TYPE.supportedClass;
    var i = 0;
    var isReadonly = false;
    var l_type = this.$el.find(typeClasses);
    var typeClass = [];
    var formatMask = [];
    var l_findInput = fieldset.find(":input");

    for (i = 0; i < l_type.length; i++) {
      var it = l_type[i];
      var attrName = it.className.split(" ")[0];
      var typeClassesArray = typeClasses.replace(/[\.,]/g, "").split(" "); // eslint-disable-line

      typeClass[attrName] = _.find(typeClassesArray, function(type) { // eslint-disable-line
        return $(it).hasClass(type);
      }, this);

      if (!STR.isBlank(self.typeFormatByClass) && !STR.isBlank(self.typeFormatByClass[attrName])) { //TypeCustom
        formatMask[attrName] = self.typeFormatByClass[attrName];
        typeRenderers[attrName] = function(a_typeClass, v, a_formatMask) {
          return TYPE._getTypeByTypeClass(a_typeClass).format(v, a_formatMask);
        };
      } else { //Other types
        //typeRenderers[attrName] = function(v){return TYPE._getTypeByTypeClass(typeClass).format(v);};
        if (self.typeMaskByClass && self.typeMaskByClass[attrName]) {
          formatMask[attrName] = TYPE._getFormatByCode(self.typeMaskByClass[attrName]);
          typeRenderers[attrName] = function(a_typeClass, v, a_formatMask) {
            return TYPE._getTypeByTypeClass(a_typeClass).format(v, a_formatMask);
          };
        } else {
          typeRenderers[attrName] = function(a_typeClass, v) {
            return TYPE._getTypeByTypeClass(a_typeClass).format(v);
          };
        }
      }
    }
    for (i = 0; i < l_findInput.length; i++) {
      var element = l_findInput[i];
      var domEl = $(element);

      if (!STR.isBlank(domEl.attr("class"))) {
        var name = domEl.attr("class").split(" ")[0];
        var value = STR.getElValue(model, name);
        var label = fieldset.find("label[for='" + name + "']");

        // Apply original renderer
        if (!_.isUndefined(renderers) && !_.isNull(renderers) && renderers[name]) {
          value = renderers[name](value, model);
        }
        // Apply type renderer
        if (!_.isUndefined(typeRenderers) && !_.isNull(typeRenderers) && typeRenderers[name]) {
          var l_formatMask = "";

          if (!STR.isBlank(self.typeFormatByClass) && !STR.isBlank(self.typeFormatByClass[name])) {
            l_formatMask = self.typeFormatByClass[name];
          } else if (self.typeMaskByClass && self.typeMaskByClass[name]) {
            l_formatMask = TYPE._getFormatByCode(self.typeMaskByClass[name]);
          }
          if (domEl.hasClass("infinityDate")) {
            value = TYPE.DATE.manageInfinity(value, l_formatMask);
          } else {
            if (!STR.isBlank(formatMask[name])) {
              value = typeRenderers[name](typeClass[name], value, formatMask[name], model);
            } else {
              value = typeRenderers[name](typeClass[name], value, model);
            }
          }
        }

        // Get the viewRef of an input
        var viewRef = domEl.prop("viewRef");
        if (element.type === "select-one" || element.type === "textarea") {
          //Select case
          domEl.val(value);

          fieldset.find("span." + name.replace(/\./g, "\\.")).remove();

          isReadonly = domEl.attr("readonly") === "readonly";
          if (isReadonly) {
            // replace readonly field by span field and hide the readonly field
            if (element.type === "select-one") {
              FORMS.setSelectFieldReadonly(fieldset, name, true);
            }
            if (element.type === "textarea") {
              //FORMS.setTextareaFieldReadonly(fieldset, name, true);
            }
            if (!STR.isBlank(label) && label.length > 0 && isReadonly === true && label.hasClass("required")) {
              label.removeClass("required");
            }
          }
        } else if (viewRef && viewRef.isComboBoxView2) {
          if (viewRef.multiselection === false) {
            var tempValue = value;
            if (!_.isObject(value)) {
              tempValue = { code: value };
              var nameFragments = name.split(".");
              nameFragments.pop(); // I remove the last part of the name
              var simpleClassName = nameFragments.join(".");
              var obj = STR.getElValue(model, simpleClassName);
              if (obj) {
                tempValue = _.extend(tempValue, obj);
              }
            }
            viewRef.setItem(tempValue, callback);
          } else {
            viewRef.setItems(value);
          }
        } else if (viewRef && viewRef.isRadioBoxView) {
          if (domEl.val() === value) {
            viewRef.manageAdditionalViewState(value);
          }
          domEl.val([value]);
        } else if (viewRef && viewRef.isSelecteurActivitesView) {
          //For selecteur d'activites do nothing, it should be managed by usecase
        } else {
          //other fields case
          switch (domEl.attr("type")) {
            case "text":
              domEl.val(value);
              isReadonly = domEl.attr("readonly") === "readonly";
              FORMS.setInputFieldReadonly(fieldset, name, isReadonly);
              if (!STR.isBlank(label) && label.length > 0 && isReadonly === true && label.hasClass("required")) {
                label.removeClass("required");
              }
              break;
            case "checkbox":
              domEl.val([value]);
              domEl.prop("checked", value);
              break;
            case "radio":
              domEl.val([value]);
              break;
            case "number":
              domEl.val([value]);
              break;
            default:
              //Nothing
          }
        }
      }
    }
    //Accessibility
    if (!avoidLinkingLabels) {
      if (this instanceof FormView && !(this.isEditedRowView)) {
        FORMS.linkLabelsToInputs(fieldset);
      }
    }
    this.disableClickOnReadonlyCheckbox();
    this._panelStateRender(fieldset);
  },

  disableClickOnReadonlyCheckbox: function() {
    this.$el.find("[type=checkbox]").off(".readonlyCheck");
    this.$el.find("[type=checkbox]").on("click.readonlyCheck", function(e) {
      if ($(e.target).is("[readonly]")) {
        e.preventDefault();
      }
      e.stopPropagation();
    });
  },

  _showValidationErrors: function(model, errors, focusField) {
    if (errors && _.has(errors, "errorValidation")) {
      if (_.has(errors, "errors")) {
        var fieldErrors = errors.errors;
        FORMS.showErrors($(this.el), fieldErrors);
        if (focusField && focusField instanceof String) {
          this.$el.find(":input.ui-state-error." + focusField).eq(0).focus();
        } else {
          this.$el.find(":input.ui-state-error").eq(0).focus();
        }
      }
    }
  },

  _cleanValidationErrors: function() {
    FORMS.cleanErrors($(this.el));
  },

  _manageFormVisibility: function() {
    var model = this._getModel();
    if (STR.isBlank(model)) {
      this.$el.hide();
    } else {
      this.$el.show();
    }
  },

  _panelStateIsReadonly: function() {
    var model = this._getModel();
    var isReadOnly = false;

    if (model && model.getHabContext && model.getHabContext()) {
      var canUpdate = HABILITATION.canUpdate(model.getHabContext().get("foncCour"));
      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  },

  _panelStateRender: function(fieldset) {
    var isReadonly = this._panelStateIsReadonly();

    if (fieldset.has("input")) {
      FORMS.setFormReadonly(fieldset, isReadonly, true);
    } else {
      FORMS.setFormReadonly(this.$el, isReadonly, true);
    }

    // add special fields manually (tables, combos, ...)
    this._panelStateRenderCustomFields(isReadonly);
  },

  _panelStateRenderCustomFields: function() {
    // override with custom readonly sentences.
  }
});
