import _ from 'underscore';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWWkfEvtModel } from './cwWkfEvt.model';

/**
 * Workflow collection of types of events
 */
export class CWWkfEvtColl extends CWPaginatedCollection<CWWkfEvtModel> {

  canViewTreatment: boolean;
  sortings: { [key: string]: any };

  constructor(models?: CWWkfEvtModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWWkfEvtModel;
    super(models, options);
    this.canViewTreatment = true;
    this.sortings = { libelle: true };
    this.paginated = false;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/assocircuitevt/type";
    }
  }
}
