import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWEditableGridView } from 'src/core/grids/editablegrid/cwEditableGrid.view';
import { CWMSGS } from 'utils/cwMsgs';
import { CWParametrageModel } from './cwParametrage.model';
import { CWParametrageView } from '../views/cwParametrage.view';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

export class CWParametragebresultatsWorkflow extends CWReadOnlyModel {

  btnBarModel: Backbone.Model;
  setupState: number;
  context: { [key: string]: any };
  parametrageResultats: CWParametrageView;
  formModel: CWParametrageModel;
  table: CWEditableGridView;
  public module: string;
  public $appendTo: JQuery; //pour les messages

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.module = options.module;
    this.btnBarModel = null;
    this.setupState = undefined; // 0 --> setUp called, 1 --> setUp finished
    this.context = undefined;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
  }

  _buttonAction(buttonId: string): void {
    const value = this.parametrageResultats.model.get("value");

    switch (buttonId) {
      case "save": {
        const oldAttributes = !CWSTR.isBlank(value.oldAttributes) ? JSON.parse(value.oldAttributes) : "";

        if (!value.isNew() && value.get("visibilite") === false && !CWSTR.isBlank(oldAttributes) && oldAttributes.visibilite === true) {
          CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1825'), (result: string) => {
            if (result === "Y") {
              this.parametrageResultats.saveParametrage();
            }
          }, null, null, null, this.$appendTo);
        } else {
          this.parametrageResultats.saveParametrage();
        }
        break;
      }
      case "revert":
        this.parametrageResultats.revertView();
        break;
      case "new":
        this.parametrageResultats.newParametrage();
        break;
      case "delete": {
        const messageConfirm = i18n.t('common:delconfirm');

        CWMSGS.showConfirmAdapter(messageConfirm, (result: string) => {
          if (result === "Y") {
            this.parametrageResultats.deleteParametrage();
          }
        }, null, null, null, this.$appendTo);
        break;
      }
      case "copy":
        value.formerID = value.get("id") ? value.get("id") : null;
        value.set("id", null);
        value.set("codebrique", null);
        value.set("visibilite", false);
        this.parametrageResultats.model.trigger("change:value"); //Force map to form
        this.parametrageResultats.table.model.coll.reset();
        this.parametrageResultats._manageActiverButtons();
        this.btnBarModel.trigger("hide:activer");
        this.btnBarModel.trigger("hide:desactiver");
        this.btnBarModel.set("mode", "E");
        break;
      case "activer":
        this.parametrageResultats.activerParametrage("activer");
        break;
      case "desactiver":
        this.parametrageResultats.activerParametrage("desactiver");
        break;
      default:
      //Nothing
    }
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.parametrageResultats._manageActiverButtons();
    this.parametrageResultats._manageHabilitation();
  }
}