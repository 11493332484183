module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class="ui-menu-item cwProfilesMenuItem" id="'+
((__t=(codeId))==null?'':__t)+
'" style="display:flex;">\n  <span style="display:flex;">\n    <span class=\'c-panneauMenu__tickIcon cw-icon cw-icon-16 cw-valider-icon\' style="display:none"></span>\n    ';
 for(var i = 0; i < level; i++){ 
__p+='\n      <span class="phx-profilutilisateur-treeLeft">&nbsp;</span>\n    ';
 } 
__p+='\n    ';
 if(enfants === true){ 
__p+='\n      <span style="position:relative;width:25px">\n        <span class=\'cw-icon cw-icon-16 cw-treenode-icon cw-treenode-expandable \'></span>\n      </span>\n    ';
 } 
__p+='\n    ';
 if(enfants !== true && level > 0){ 
__p+='\n      <span style="position:relative;width:25px">\n        <span class="cw-treenode-leaf-icon"></span>\n      </span>\n    ';
 } 
__p+='  \n    <a href="javascript:void(0)" tabindex="-1" class="menuProfileItem" data-value="'+
((__t=(code))==null?'':__t)+
'" data-type="'+
((__t=(type))==null?'':__t)+
'" data-parentId="'+
((__t=(parentId))==null?'':__t)+
'" data-userId="'+
((__t=(userId))==null?'':__t)+
'" data-codedeleg="'+
((__t=(codedeleg))==null?'':__t)+
'">'+
((__t=(libelle))==null?'':__t)+
'</a>\n  </span>\n  ';
 if(principal === true){ 
__p+='\n    <span class="cwProfilesMenuItem__selectionIcon">\n      ';
 if(selection === true){ 
__p+='\n        <span class="ui-phx-icon-metier ui-phx-profil-principal-selectable"></span>\n      ';
 }else{ 
__p+='\n        <span class="ui-phx-icon-metier ui-phx-profil-principal-non-selectable"></span>\n      ';
 } 
__p+='\n    </span>\n  ';
 } 
__p+='\n  ';
 if(enfants === true){ 
__p+='\n    <span class="phx-profle-enfants" style="display:none"></span>\n  ';
 } 
__p+='\n</li>\n';
}
return __p;
};
