module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="gererabsencesouhait-detail gererabsence-content c-gererAbsenenceContent">\n    <div class="phx-formTable">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div class="gererabsence-content">\n            <div class="gererabsence-form-inputs">\n                <div class="form-group">\n                    <div class="collab">\n                        <label for="matricule" class="cw-required">\n                            '+
((__t=(i18n.t("common:gererabsencesouhait.collab")))==null?'':__t)+
'</label>\n                        <span class="cmbCollaborateurs"></span>\n                        <span class="matricule-error-container"></span>\n                    </div>\n                </div>\n                    <div class="firstRow d-flex form-group">\n                        <div class="motif">\n                            <label for="motif.code" class="cw-required">\n                                '+
((__t=(i18n.t("common:gererabsencesouhait.motif_saisiemass")))==null?'':__t)+
'</label>\n                            <span class="cmbMotif"></span>\n                        </div>\n\n                        <div class="gererabsencesouhait-dates dates d-flex">\n                            <div class="date-container d-flex">\n                                <div class="date-group clearfix">\n                                    <label for="datedeb" class="cw-required">'+
((__t=(i18n.t("common:gererabsencesouhait.datedebut")))==null?'':__t)+
'</label>\n                                    <div class="c-date-selector input-group">\n                                        <input type="text" class="datedeb typeDate form-control periodStart" periodId="1" value="" size="17" title="'+
((__t=(i18n.t('common:gererabsencesouhait.datedebut_title')))==null?'':__t)+
'" />\n                                    </div>\n                                </div>\n                                <div class="date-group clearfix unite">\n                                    <label for="unitedeb.code" class="unitedeb">&nbsp;</label>\n                                    <span class="cmbUniteDebut" title="'+
((__t=(i18n.t('common:gererabsencesouhait.unitedebut_title')))==null?'':__t)+
'" class="cw-required"></span>\n                                </div>\n                            </div>\n                            <div class="date-container d-flex">\n                                <div class="date-group">\n                                    <label for="datefin" class="cw-required">'+
((__t=(i18n.t("common:gererabsencesouhait.datefin")))==null?'':__t)+
'</label>\n                                    <div class="c-date-selector input-group">\n                                        <input type="text" class="datefin typeDate form-control periodEnd" periodId="1" value="" size="17" title="'+
((__t=(i18n.t('common:gererabsencesouhait.datefin_title')))==null?'':__t)+
'" />\n                                    </div>\n                                </div>\n                                <div class="date-group unite">\n                                    <label for="unitefin.code" class="unitefin">&nbsp;</label>\n                                    <span class="cmbUniteFin" title="'+
((__t=(i18n.t('common:gererabsencesouhait.unitefin_title')))==null?'':__t)+
'" class="cw-required"></span>\n                                </div>\n                            </div>\n                        </div>\n                        <div class="heure">\n                            <div class="heure-group">\n                                <label for="heuredeb" class="ui-state-disabled">'+
((__t=( i18n.t('common:saisiemass.title_absence_horaire_deb') ))==null?'':__t)+
'</label>\n                                <input type="text" class="heuredeb typeHourMinuteNightly form-control ui-state-disabled" disabled="disabled" tabindex="-1" value="" size="12" title="'+
((__t=(i18n.t('common:gererabsencesouhait.heuredebut_title')))==null?'':__t)+
'" />\n                            </div>\n                            <div class="heure-group">\n                                <label for="heurefin" class="ui-state-disabled">'+
((__t=( i18n.t('common:saisiemass.title_absence_horaire_fin') ))==null?'':__t)+
'</label>\n                                <input type="text" class="heurefin typeHourMinuteNightly form-control ui-state-disabled" disabled="disabled" tabindex="-1" value="" size="12" title="'+
((__t=(i18n.t('common:gererabsencesouhait.fin_title')))==null?'':__t)+
'" />\n                            </div>\n                            <div class="heure-group">\n                                <label for="duree" style="float: none;" class="ui-state-disabled">'+
((__t=(i18n.t("common:saisiemass.title_absence_duree")))==null?'':__t)+
'</label>\n                                <input type="text" class="duree typeDurationHM form-control ui-state-disabled" disabled="disabled" value="" tabindex="-1" size="12" title="'+
((__t=(i18n.t('common:gererabsencesouhait.duree_title')))==null?'':__t)+
'" />\n                            </div>\n                        </div>\n                    </div>\n                    <div class="secondRow d-flex">\n\n                        <div class="originActif">\n                            <label for="origine.code" class="">'+
((__t=(i18n.t("common:gererabsencesouhait.origine")))==null?'':__t)+
'</label>\n                            <span class="comboOrigine"></span>\n                        </div>\n                        <div class="blockCommentaire clearfix">\n                            <label for="commentaire">'+
((__t=(i18n.t("common:gererabsencesouhait.comment")))==null?'':__t)+
'</label>\n                            <input type="text" maxlength="60" class="commentaire form-control" title="'+
((__t=(i18n.t('common:gererabsencesouhait.comment_title')))==null?'':__t)+
'" />\n                        </div>\n                        <div class="gererabsencesouhait-infocomp clearfix">\n                            <div class="gererabsence-content-btn-bar"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <ul class="validerListMenu menuContent"></ul>\n    <ul class="nouveauListMenu menuContent" style="display:none">\n        <li tabindex="0">\n            <a class=\'menuItem\' data-value=\'absenceButton\'>\n                '+
((__t=(i18n.t("common:gererabsencesouhait.nouveauAbsenceButton")))==null?'':__t)+
'\n            </a>\n        </li>\n        <li tabindex="0">\n            <a class=\'menuItem\' data-value=\'souhaitButton\'>\n                '+
((__t=(i18n.t("common:gererabsencesouhait.nouveauSouhaitButton")))==null?'':__t)+
'\n            </a>\n        </li>\n    </ul>\n</div>';
}
return __p;
};
