import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCommonNavigationLayout from './cwNavigation_layout.tpl.html';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWReadOnlyModel } from '../../models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

/**
 * Render a Page menu in a form
 */
export class CWNavigationView extends Backbone.View<Backbone.Model> {

  showMenu: boolean;
  menuTemplate: any;
  iniPanel: number;
  habOpt: string;
  menuHide: string;
  panelNames: Array<any>;
  suiviCollabContext: boolean;
  hideLinksText: boolean; //ne pas montrer les textes "situation" ou "vue journée"
  module: string;

  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.tagName = options.tagName || "div";
    options.className = options.className || "cw-navigation__content";
    options.events = _.extend({
      "click .cwMenuButton": "_toggleMenu",
      "click .menuItem:not([screen-navigation])": "_selectMenuItem",
      "click .linkSituation": () => (this._goToPage('0')),
      "click .linkVueJournee": () => (this._goToPage('30')),
      "click .menuItem[screen-navigation]": "_selectScreenNavigationItem",
      "click .linkItem": "_selectLinkItem"
    }, options.events);
    super(options);

    this.showMenu = false;
    this.suiviCollabContext = false;
    this.template = TPLCommonNavigationLayout;
    // template of the menu
    if (options.menutpl) {
      this.showMenu = true;
      this.menuTemplate = options.menutpl;
    }
    this.hideLinksText = _.isBoolean(options.hideLinksText) ? options.hideLinksText : false;
    // number of panel to initialize menuText div
    this.iniPanel = options.menuIni;
    this.habOpt = options.habilitationOption ? options.habilitationOption : null;
    this.suiviCollabContext = (options.module === "suivicollab");
    this.menuHide = null;
    if (options.menuHide) {
      this.menuHide = options.menuHide;
    }
    this.module = options.module;
    this.model = new CWReadOnlyModel({
      page: "0",
      title: "",
      defaults: {
        page: "0",
        title: ""
      }
    });
    this.model.on("revert", this.revert, this);
    this.model.on("reset", this.reset, this);
    this.model.on("setPage", this.setPage, this);
    this.model.on("closeMenu", this._closeMenu, this);
    this.panelNames = [];
  }

  render(): CWNavigationView | any {
    let json: any = { "UTILS": UTILS, "i18n": i18n };
    let menuBtn = null;
    const menuText = $(".menuText", this.el);

    this.$el.append(this.template(json));
    if (this.menuTemplate) {
      json = { "i18n": i18n, "habilitationOption": this.habOpt, "menuHide": this.menuHide };

      this.$el.append(this.menuTemplate(json));
    }
    menuBtn = $(".cwMenuButton", this.el);
    if (this.showMenu) {
      const menuContent = $(".menuContent", this.el);

      menuBtn.css('display', 'inline-block');
      menuContent.menu();
      menuContent.hide();
    } else {
      menuBtn.hide();
    }
    //Set the initial TEXT
    menuText.show();
    this._manageLinkDisplays(String(this.iniPanel));
    menuText.attr("id", this.getTextId());
    return this;
  }



  // Method to update model with newly selected menu
  // Iterate thru menus to extract correct libelles: menu1 > menu2 > etc...
  _selectMenuItem(event: JQueryEventObject): void {
    let option = null;
    const keepMenu = !CWSTR.isBlank((event.currentTarget as any).attributes["keep-menu"]);// Check keep-menu attribute if exist the menu does not change
    if (!CWSTR.isBlank((event.currentTarget as any).attributes["data-value"])) {
      option = (event.currentTarget as any).attributes["data-value"].nodeValue;
    }
    if (!keepMenu) {
      this._checkVueJourneeDisplay();
      this._showLinkSituation(true);
    }
    this._toggleMenu();
    if (option) {
      this._goToPage(option);
    }
  }

  _goToPage(page: string): void {
    this._manageLinkDisplays(page);
    this.model.set("page", page);
    (this.model as any).panelPage = page;
  }

  _manageLinkDisplays(page: string): void {
    this._checkVueJourneeDisplay();
    if (!this.hideLinksText) {
      this._showLinkSituation(true);
    }
    if (page === "0") {
      this._showLinkSituation(false);
    }
    if (page === "30") {
      this._showLinkVuejournee(false);
    }
  }

  _checkVueJourneeDisplay(): void {
    if (this.suiviCollabContext) {
      this._showLinkVuejournee(true);
    } else {
      this._showLinkVuejournee(false);
    }
  }

  _selectScreenNavigationItem(event: any): void {
    const option = event.currentTarget.attributes["data-value"].nodeValue;

    this._toggleMenu();
    if (option && (parseInt(option, 10) === 40 || parseInt(option, 10) === 41 || parseInt(option, 10) === 43)) {
      event.stopPropagation();
      this.model.trigger("call:agenda", option);
    }
  }

  _selectLinkItem(event: JQueryEventObject): void {
    const option = (event.currentTarget as any).attributes["data-value"].nodeValue;

    this._goToPage(option);
  }

  //Method to close and open the menu.
  _toggleMenu(): void {
    const menuContent = $(".menuContent", this.el);

    if (menuContent.is(":visible")) {
      menuContent.hide();
    } else {
      const menuBtn = $(".cwMenuButton", this.el);

      menuContent.show().position({
        my: "left top",
        at: "left bottom",
        of: menuBtn
      });
      $(document).one("mouseup", (event: any) => {
        const element = $(this.el).find(".menuContent").find(event.target);
        const elementInnNavigation = $(this.el).find(event.target);

        if (element.length === 0 && !(elementInnNavigation.length === 1 && $(event.target).hasClass("a.linkIteml")) && !(elementInnNavigation.length === 1)) {
          this._toggleMenu();
        } else {
          $(document).one("mouseup", (event: any) => {
            if (!$(event.target).hasClass("menuItem") && !$(event.target).parent().hasClass("menuItem")) {
              this._closeMenu();
            }
          });
        }
      });
    }
  }

  _closeMenu(): void {
    const menuContent = $(".menuContent", this.el);

    if (menuContent.is(":visible")) {
      menuContent.hide();
    }
  }

  _currentMenuText(item: any): string {
    let text = "";
    const parent = $(item).parents(".subMenuItem");
    let separator = "";

    _.each(parent.siblings("a"), (ref: any) => {
      separator = ref.getAttribute("separator");
      if (!CWSTR.isBlank(ref)) {
        if ($(ref).hasClass("linkItem")) {
          const link = $("<span>").addClass("linkItem").attr("data-value", ref.getAttribute("data-value"));

          link.html($(ref).text());
          text += "<span role='presentation' class='presentation' aria-hidden='true'> > </span>" + link.prop("outerHTML");
        } else {
          text += "<span role='presentation' class='presentation' aria-hidden='true'> - </span>" + $(ref).text();
        }
      }
    });
    return (text + "<span role='presentation' class='presentation' aria-hidden='true'>" + (separator && separator.length > 0 ? separator : " > ") + "</span>" + $(item).text());
  }

  revert(): void {
    //Revert the model
    this.model.attributes = this.model.previousAttributes();
    this._manageLinkDisplays(this.model.get("page"));
  }

  reset(): void {
    const lOptions: any = { pageReset: true };

    //Repaint the menuText with the actual page
    this.model.set("page", "0", lOptions);
  }

  setPage(page: any): void {
    const lOptions: any = { pageReset: true };

    //Repaint the menuText with the specified page
    if (page === "30") {
      this._showLinkVuejournee(false);
    }
    this.model.set("page", page, lOptions);
  }

  setPanelName(item: any): void { //eslint-disable-line
    //     //this.panelNames[$(item).attr("data-value")] = this._currentMenuText(item);
  }

  getTextId(): string {
    // Set the id to be used by WAI-ARIA
    return this.cid + "-menuText";
  }

  _showLinkSituation(value: boolean): void {
    if (!this.hideLinksText && value === true && ((this.module === "suivicollab" && CWHABILITATION.canView("RES_FICHE.V")) || (this.module === "fichecollab" && CWHABILITATION.canView("COL_FICHE.V")))) {
      $(".linkSituation", this.el).show();
    } else {
      $(".linkSituation", this.el).hide();
    }
  }

  _showLinkVuejournee(value: boolean): void {
    if (!this.hideLinksText && value === true) {
      $(".linkVueJournee", this.el).show();
    } else {
      $(".linkVueJournee", this.el).hide();
    }
  }
}
