import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model for Initialisation (EvtRegulSoumisWkf)
 */
export class CWMotifRegulInitModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "wkf_activ": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/motifregul/init";
    };
  }
}