import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { SelecteurActiviteModeSimpleActiviteModel } from './selecteurActiviteModeSimpleActivite.model.js';

export var SelecteurActiviteModeSimpleActivitesColl = PaginatedCollection.extend({

  model: SelecteurActiviteModeSimpleActiviteModel,

  /**
   * Constructor
   * Collection of activites for a certain domaine
   */
  initialize: function( /*params*/ ) {
    PaginatedCollection.prototype.initialize.call(this);
  }
});
