import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererrecapitulatifsDetailCreerRecapForm from '../cwDetailCreerRecapForm.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWButtonBarView } from 'src/core/controls/cwButtonBar.view';
import { CWComboBoxView2 } from 'src/core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWFORMS } from 'src/utils/cwForms';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWInfoCompRecap } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwInfoCompRecap';
import { CWPeriodesRecap } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwPeriodesRecap';
import { CWRecapActModel } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwRecapAct.model';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';

export class CWCreerRecapView extends CWBaseFormView {

  btnBar: CWButtonBarView;
  comboPeriode: any;
  comboType: any;
  parent: any;
  periodeComboColl: CWCombosColl;
  typeComboColl: CWCombosColl;
  workflow: any;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "change :input:not([readonly])": "_updateModel",
      "click button": "_clickListener"
    });
    options.className = "cw-fieldset";
    options.tagName = "fieldset";
    super(options);
    this.template = TPLGererrecapitulatifsDetailCreerRecapForm;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.btnBar = new CWButtonBarView({ id: "gererrecapitulatifs_creer_btnbar", isAlignToRight: true });
    this.model = new CWBaseModel({
      value: new CWRecapActModel()
    });
    this.listenTo(this.workflow, "contextchanged", this._checkComboType);
    this.listenTo(this.model, "change:value", this._changeModel);
    this.listenTo(this.workflow, "initializeCombos", this._initializeCombos);
    this.typeComboColl = new CWCombosColl({
      name: "composant",
      comboId: "recapactivite/types/"
    });
    this.periodeComboColl = new CWCombosColl({
      name: "composant",
      comboId: "recapactivite/liste/periode/"
    });
    this.periodeComboColl.model = CWPeriodesRecap;
  }

  _changeModel(): void {
    this.btnBar.model.trigger("enable:save");
    this.btnBar.model.trigger("enable:revert");
    this._mapToForm();
  }

  _clickListener(jqEvent: JQueryEventObject): void {
    const btnClicked = $(jqEvent.currentTarget).val();
    let model: any = null;

    switch (btnClicked) {
      case "save":
        model = this.model.get("value");
        if (this.workflow.context.ctxEcran === "suivicollab" && this.workflow.context && this.workflow.context.ctxHabilitation && this.workflow.context.ctxHabilitation.HabilitationGestion) {
          model.updateHabContext({ foncCour: this.workflow.context.ctxHabilitation.HabilitationGestion });
        } else {
          const fonc = this.workflow.context.ctxUtilisateur === "Responsable" ? "RES_RECAP.G" : "COL_RECAP.G";
          const hab = new CWHabilitationContext({
            onglet: this.workflow.context.ctxEcran,
            foncCour: fonc,
            natGest: "A"
          });

          model.setHabContext(hab);
        }
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);
        if (model.isValid()) {
          model.set("matricule", this.workflow.context.ctxCollab.matricule);
          model.save(null, {
            success: (fresh: { [key: string]: any }) => {
              if (this.workflow.context.ctxEcran === "suivicollab") {
                this.workflow.refreshList();
                this.parent.volet.close();
                this.workflow.trigger('recapCreated', fresh);
              } else {
                let infocomp: CWInfoCompRecap = null;

                this.workflow.headerModel.set("value", fresh.clone());
                infocomp = new CWInfoCompRecap();
                infocomp.set("commentaire_infocomp", fresh.get("commentaire_infocomp"));
                this.workflow.headerModel.set("infocomp", infocomp);
                this.parent._updateList();
                this.workflow.headerModel.trigger("recapSelected");
                this._clearForm();
                this.workflow.trigger("recapUpdated");
              }
            },
            error: () => {
              if (!_.isEmpty(model.validationError) && !_.isEmpty(model.validationError.errors)) {
                const errors = model.validationError.errors;

                if (!_.isEmpty(errors.datedeb)) {
                  errors.periode = errors.datedeb;
                  delete errors.datedeb;
                }
                if (!_.isEmpty(errors.datefin)) {
                  errors.periode = errors.datefin;
                  delete errors.datefin;
                }
                CWFORMS.showErrors($(this.el), errors);
              }
            }
          });
        }
        break;
      case "revert":
        this._clearForm();
        if (this.workflow.context.ctxEcran !== "suivicollab") {
          this.parent._showDialog();
        } else {
          this.parent.volet.close();
        }
        break;
      default:
        break;
    }
  }

  _clearForm(): void {
    const model = this.model.get("value");

    model.set(model.defaults());
    if (this.typeComboColl.length === 1) {
      this.model.get("value").set("recapitulatif", this.typeComboColl.at(0).get("code"));
      this.model.get("value").set("libelle", this.typeComboColl.at(0).get("libelle"));
      CWFORMS.setInputFieldReadonly($(this.el), "periode", false);
    } else {
      CWFORMS.setInputFieldReadonly($(this.el), "periode", true);
    }
    this._mapToForm();
    this.comboPeriode.clearCache();
    this.btnBar.model.trigger("disable:save");
    this.btnBar.model.trigger("disable:revert");
  }

  _checkComboType(): void {
    let hab = null;
    const fieldset = $(this.el);

    if (this.workflow.context.ctxEcran === "suivicollab") {
      const lFonc = (!CWSTR.isBlank(this.workflow.context.ctxHabilitation.HabilitationAcces)) ? (typeof this.workflow.context.ctxHabilitation.HabilitationAcces === "string" ? this.workflow.context.ctxHabilitation.HabilitationAcces : this.workflow.context.ctxHabilitation.HabilitationAcces.recapitulatif) : null;

      if (!_.isEmpty(lFonc)) {
        hab = new CWHabilitationContext({
          "onglet": this.workflow.context.ctxEcran,
          "natGest": "",
          "foncCour": lFonc
        });
      } else {
        hab = this.workflow.habContext.clone();
      }
    } else {
      hab = this.workflow.habV.clone();
    }
    hab.set("natGest", "A");
    this.model.get("value").setHabContext(hab);
    this.typeComboColl.setHabContext(hab);
    this.periodeComboColl.setHabContext(hab);
    this.typeComboColl.comboId += this.workflow.context.ctxCollab.matricule + "?ctxfeuilletemps=false";
    CWFORMS.setInputFieldReadonly(fieldset, "periode", true);
    this.typeComboColl.fetch({
      success: (fresh: { [key: string]: any }) => {
        this._mapToForm();
        if (fresh.length === 1) {
          this.comboType.setItem({ code: fresh.at(0).get("code"), libelle: fresh.at(0).get("libelle") });
          CWFORMS.setInputFieldReadonly(fieldset, "recapitulatif", true);
          this.periodeComboColl.comboId += fresh.at(0).get("code") + "%2C" + this.workflow.context.ctxCollab.matricule;
          CWFORMS.setInputFieldReadonly(fieldset, "periode", false);
          this.model.get("value").set("recapitulatif", fresh.at(0).get("code"));
        }
      }
    });
  }

  _updateModel(e: JQueryEventObject, data: object): void {
    const className = e.target.className.split(" ")[0];

    if (className === "recapitulatif") {
      this.comboPeriode.setItem(null);
      this.comboPeriode.clearCache();
      this.periodeComboColl.comboId = "recapactivite/liste/periode/" + this.comboType.getItem().attrs.code + "%2C" + this.workflow.context.ctxCollab.matricule;
      CWFORMS.setInputFieldReadonly($(this.el), "periode", false);
      this.btnBar.model.trigger("disable:save");
      //this.btnBar.model.trigger("enable:revert");
      this.btnBar.model.trigger("disable:revert");
      this.model.get("value").set("recapitulatif", this.comboType.getItem().attrs.code);
      this.model.get("value").set("libelle", this.comboType.getItem().attrs.libelle);
    } else if (className === "periode") {
      const dates = this.comboPeriode.getItem().attrs.code.split("_");

      this.btnBar.model.trigger("enable:save");
      this.btnBar.model.trigger("enable:revert");
      this.model.get("value").set("datedeb", dates[0]);
      this.model.get("value").set("datefin", dates[1]);
    } else {
      this._change(e, data);
    }
  }

  _mapToForm(): void {
    const model = this.model.get("value");
    const fieldset = this.$el.find(".gererrecapitulatifs-form");

    this._mapModelToForm(fieldset, model, null);
  }

  render(): CWCreerRecapView {
    const json = { "i18n": i18n };

    this.$el.append(this.template(json));
    this.$el.find(".gererrecapitulatifs-form").addClass('c-form--fixedButtons');
    this.$el.find('.gererrecapitulatifs-form-inputs').addClass('c-form__scrollZone m-0 ');
    this.$el.find("div.createBtns").html(this.btnBar.render().el);
    this.btnBar.model.trigger('hide:new');
    this.btnBar.model.trigger("hide:copy");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:valider");
    this.btnBar.model.trigger("disable:save");
    this.btnBar.model.trigger("disable:revert");
    this.comboType = new CWComboBoxView2({
      ws: this.typeComboColl,
      name: "recapitulatif",
      urlException: "activite/recap/combos/recapitulatif?ctxfeuilletemps=false",
      //width: 200,
      required: true,
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.libelle)) {
          return '';
        }
        return value.libelle;
      }
    });
    $(this.el).find(".cmbType").html(this.comboType.render().el);
    this.comboPeriode = new CWComboBoxView2({
      ws: this.periodeComboColl,
      name: "periode",
      //width: 200,
      required: true,
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.libelle)) {
          return "";
        }
        return value.libelle;
      }
    });
    $(this.el).find(".cmbPeriode").html(this.comboPeriode.render().el);
    return this;
  }

  _initializeCombos(): void {
    const hab = this.workflow.habV.clone();
    const fieldset = $(this.el);

    hab.set("natGest", "A");
    this.model.get("value").setHabContext(hab);
    this.typeComboColl.setHabContext(hab);
    this.periodeComboColl.setHabContext(hab);
    this.typeComboColl.comboId += this.workflow.context.ctxCollab.matricule + "?ctxfeuilletemps=false";
    CWFORMS.setInputFieldReadonly(fieldset, "periode", true);
    this.comboType.fetchCombo((): void => {
      const fresh = this.comboType.coll;

      this._mapToForm();
      if (fresh.length === 1) {
        this.comboType.setItem({ code: fresh.at(0).get("code"), libelle: fresh.at(0).get("libelle") });
        CWFORMS.setInputFieldReadonly(fieldset, "recapitulatif", true);
        this.periodeComboColl.comboId += fresh.at(0).get("code") + "%2C" + this.workflow.context.ctxCollab.matricule;
        CWFORMS.setInputFieldReadonly(fieldset, "periode", false);
        this.model.get("value").set("recapitulatif", fresh.at(0).get("code"));
      }
    });
  }
}
