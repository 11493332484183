import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * dabsences Model
 */
export class CWRecapModel extends CWBaseModel {
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "recapitulatif": "",
      "libelle": "",
      "datedeb": "",
      "datefin": "",
      "droit": "",
      "collaborateur": {
        "nom": "",
        "prenom": "",
        "matric": 0,
        "matric_aux": "",
        "dmaj": ""
      },
      "evenement": null,
      "indicateurpj": false,
      "piecesjointes": null,
      "etat": null,
      "statut": null,
      "nbevenement": null
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.code !== undefined) {
      response.id = response.code;
    }

    return response;
  }
}
