import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLPanneauOptions from '../panneauOptions.tpl.html';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWSTR } from 'src/utils/cwStr';
import { CWToucheFonctionColl } from '../models/cwToucheFonction.collection';
import { i18n } from 'src/i18n.js';

export class CWPanneauOptionsView extends Backbone.View<Backbone.Model>{

    template: (params?: any) => string;
    toucheFonctionColl: CWToucheFonctionColl | any;
    parent: any;
    paramDiverTDU: boolean;
    paramDiverTVAL: boolean;
    paramDiverDC: boolean;
    paramDiverHSORT: boolean;
    paramDiverTDUC: boolean;
    paramDiverTVALC: boolean;
    paramDiverDCC: boolean;
    existsCompteurInst: boolean;
    canBadge: boolean;

    constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
        params = params || {};
        params.events = _.extend({
            "click .cw-aujourdhui__btn": "_clickButton",
            "click .cw-aujourdhui__Compteurs_link": "_clickLink"
        }, params.events);
        super(params);
        this.template = TPLPanneauOptions;
        this.toucheFonctionColl = params.coll || null;
        this.parent = params.parent || null;
        this.existsCompteurInst = params.existsCompteurInst || false;
        this.canBadge = params.canBadge || false;
    }

    render(): CWPanneauOptionsView {
        const json = { 'i18n': i18n };

        this.$el.html(this.template(json));
        this.$el.find(".cw-aujourdhui__btnsfonctionne").html(this.renderPanneau());
        this.$el.css({ "max-height": "450px", "max-width": "260px" });
        this._checkOptions();
        return this;
    }

    renderPanneau(): string {
        let lRtn = "";
        const lTouche = (this.toucheFonctionColl && this.toucheFonctionColl.length > 0 && CWHABILITATION.canView("COL_BADGFONC.G") && this.canBadge === true);
        const lCompteurs = CWHABILITATION.canView("COL_TPSREEL.V");
        const lPosTouche = this.$el.find(".cw-aujourdhui__btnsfonctionne");
        const lPosCompteurs = this.$el.find(".cw-aujourdhui__Compteurs");

        if (!lCompteurs && !lPosTouche.hasClass("btnsOnly")) {
            lPosTouche.addClass("btnsOnly");
        }
        for (let i = 0; i < this.toucheFonctionColl.length; i++) {
            const lModel = this.toucheFonctionColl.models[i];

            if (lModel && !CWSTR.isBlank(lModel.get("libelle"))) {
                let lMBottom = " cw-aujourdhui__btn--separation";

                if ((i + 1) === this.toucheFonctionColl.length && !lCompteurs) {
                    lMBottom = "";
                }
                lRtn += "<div class='cw-aujourdhui__btn btn btn-primary pl-4 pr-4" + lMBottom + "' data-code='" + encodeURIComponent(lModel.get("code")) + "'>" + lModel.get("libelle") + "</div>";
            }
        }

        if (!lTouche && lPosCompteurs.hasClass("cw-aujourdhui__Compteurs--separation")) {
            lPosCompteurs.removeClass("cw-aujourdhui__Compteurs--separation");
        }
        return lRtn;
    }

    _checkOptions(): void {
        const lTouche = (this.toucheFonctionColl && this.toucheFonctionColl.length > 0 && CWHABILITATION.canView("COL_BADGFONC.G") && this.canBadge === true);
        const lCompteurs = (CWHABILITATION.canView("COL_TPSREEL.V") && this.canBadge === true);
        const lPosTouche = this.$el.find(".cw-aujourdhui__btnsfonctionne");
        const lPosCompteurs = this.$el.find(".cw-aujourdhui__Compteurs");

        if (lTouche) {
            lPosTouche.show();
        } else {
            lPosTouche.hide();
        }
        if (lCompteurs && this.existsCompteurInst) {
            lPosCompteurs.show();
        } else {
            lPosCompteurs.hide();
        }
    }

    _clickButton(event: JQueryEventObject): void {
        this.parent._clickButtonsPanneau(event);
    }

    _clickLink(): void {
        this.parent._clickLinkPanneau();
    }
}