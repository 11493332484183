import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { RecapHistoModel } from './recapHisto.model.js';

export var RecapHistoColl = PaginatedCollection.extend({

  model: RecapHistoModel,

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  }
});