module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="btn-delete-container"></div>\n<div class="btn-copy-container"></div>\n<div class="btn-save-container">\n    <button class="phx-cell-save-action btn btn-primary btn-withIcon" data-cy="cell-save">'+
((__t=(i18n.t("common:save")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('valider')))==null?'':__t)+
'</button>\n</div>\n<div class="btn-revert-container">\n    <button class="phx-cell-revert-action btn btn-secondary btn-withIcon" data-cy="cell-revert">'+
((__t=(i18n.t("common:revert")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('croix')))==null?'':__t)+
'</button>\n</div>';
}
return __p;
};
