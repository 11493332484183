import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';


export class CWScheduleModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      tolouv: 0,
      tolclo: 0,
      houv: 0,
      hclo: 0,
      dpf1: 0,
      fpf1: 0,
      dpf2: 0,
      fpf2: 0
    }
  }

  public tolouv: number;
  public tolclo: number;
  public houv: number;
  public hclo: number;
  public dpf1: number;
  public fpf1: number;
  public dpf2: number;
  public fpf2: number;
  public plages: any[];
  public baseRange: any;


  /**
 * When there is a change, recalculate plages
 */

  public showPlageBlank: boolean;

  /**
   * Constructor
   * Model to calculate the time intervals present in the schedule
   * Blank : just used to have blank space at the beginning and at the end of the row
   * Tolerance: time buffer before the entry and exit time
   * Variable: variable time length between the entry(exit) and the beginning(end) of a fixe time interval
   * Fixe: fixed interval of time
   * Pause: rest time
   * Intervals are organized as follow:
   * Blank / Entry(Tolerance) / Variable / Fixe / Pause / Fixe / Variable / (Exit)Tolerance
   */

  constructor(args?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(args)) {
      options = args;
    }
    options = options || {};
    super(args, options);
    this.tolouv = 0;
    this.tolclo = 0;
    this.houv = 0;
    this.hclo = 0;
    this.dpf1 = 0;
    this.fpf1 = 0;
    this.dpf2 = 0;
    this.fpf2 = 0;
    this.plages = [];
    if (options && _.isBoolean(options.showPlageBlank)) {
      /**
       * Show Plage Blank
       */
      this.showPlageBlank = options.showPlageBlank;
    } else {
      this.showPlageBlank = true;
    }
    this.on("change", this._calculePlages, this);
  }

  /**
   * Calculates each of the plages
   */
  _calculePlages(): void {
    let i = 0;

    this.plages = [];
    /**
     * Represents the amount of minutes of the whole plage
     */
    this.baseRange = this._calculateBaseDuration(this.get("houv"), this.get("hclo")) + this.get("tolouv") + this.get("tolclo");
    if (this.showPlageBlank) {
      // Add 5% to the original base range. Schedule duration 420 = 100%,
      // changed to 420 = 95 % plus 5%.
      this.baseRange = this.baseRange / 0.95;
    }
    // blank plage (type is blank)
    this.plages[i] = this._calculatePlageBlank();
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is tolerance)
    this.plages[i] = this._calculatePlageDebutTolerance(this.get("tolouv"), this.get("houv"));
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is variable)
    this.plages[i] = this._calculatePlageVariable(this.get("houv"), this.get("dpf1"));
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is fixe)
    this.plages[i] = this._calculatePlageFixe(this.get("dpf1"), this.get("fpf1"));
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is pause)
    this.plages[i] = this._calculatePlagePause(this.get("fpf1"), this.get("dpf2")/*, this.get("pause")*/);
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is fixe)
    this.plages[i] = this._calculatePlageFixe(this.get("dpf2"), this.get("fpf2"));
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is variable)
    this.plages[i] = this._calculatePlageVariable(this.get("fpf2"), this.get("hclo"));
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is tolerance)
    this.plages[i] = this._calculatePlageFinTolerance(this.get("tolclo"), this.get("hclo"));
    if (!CWSTR.isBlank(this.plages[i])) {
      i++;
    }
    // plage horaire (type is blank)
    this.plages[i] = this._calculatePlageBlank();
  }

  /**
   * Calculate plages with a blank type
   */
  _calculatePlageBlank(): any {
    const plage: { [key: string]: any } = {};

    plage.hdebut = "&nbsp";
    plage.hfin = "&nbsp";
    plage.duration = this.baseRange * (this.showPlageBlank ? 0.025 : 0);
    plage.range = (plage.duration * 100) / this.baseRange;
    plage.type = "blank";
    return plage;
  }

  /**
   * Calculate the pause plage
   */
  _calculatePlagePause(h1: number, h2: number): any {
    let plage: { [key: string]: any } = null;

    if (h1 !== h2) {
      plage = {};
      plage.hdebut = h1;
      plage.hfin = h2;
      plage.duration = this._calculateDuration(plage.hdebut, plage.hfin);
      plage.range = (plage.duration * 100) / this.baseRange;
      plage.type = "pause";
    }
    return plage;
  }

  /**
   * Calculate the plage fixe
   */
  _calculatePlageFixe(h1: number, h2: number): any {
    let plage: { [key: string]: any } = null;

    if (h1 !== h2) {
      plage = {};
      plage.hdebut = h1;
      plage.hfin = h2;
      plage.duration = this._calculateDuration(plage.hdebut, plage.hfin);
      plage.range = (plage.duration * 100) / this.baseRange;
      plage.type = "fixe";
    }
    return plage;
  }

  /**
   * Calculate the plage variable
   */
  _calculatePlageVariable(h1: number, h2: number): any {
    let plage: { [key: string]: any } = null;

    if (h1 !== h2) {
      plage = {};
      plage.hdebut = h1;
      plage.hfin = h2;
      plage.duration = this._calculateDuration(plage.hdebut, plage.hfin);
      plage.range = (plage.duration * 100) / this.baseRange;
      plage.type = "variable";
    }
    return plage;
  }

  /**
   * Calculate the plage debut tolerance
   */
  _calculatePlageDebutTolerance(tol: number, h: number): any {
    let plage: { [key: string]: any } = null;

    if (tol !== 0) {
      plage = {};
      plage.hdebut = CWTYPE.HOUR_MINUTE.diff(h, CWTYPE.HOUR_MINUTE.minutesToHours(tol));
      if (plage.hdebut === 0 && CWTYPE.HOUR_MINUTE.minutesToHours(tol) !== h) {
        plage.hdebut = CWTYPE.HOUR_MINUTE.diff(2400, CWTYPE.HOUR_MINUTE.minutesToHours(tol));
        plage.hdebut = CWTYPE.HOUR_MINUTE.plus(plage.hdebut, h);
      }
      plage.hfin = h;
      plage.duration = this._calculateDuration(plage.hdebut, plage.hfin);
      plage.range = (plage.duration * 100) / this.baseRange;
      plage.type = "toldeb";
    }
    return plage;
  }

  /**
   * Calculate the plage fin tolerance
   */
  _calculatePlageFinTolerance(tol: number, h: number): any {
    let plage: { [key: string]: any } = null;

    if (tol !== 0) {
      plage = {};
      plage.hdebut = h;
      plage.hfin = this._toDuration(this._toMinutes(h) + tol);
      if (plage.hfin > 2400) {
        plage.hfin = CWTYPE.HOUR_MINUTE.diff(plage.hfin, 2400);
      }
      plage.duration = this._calculateDuration(plage.hdebut, plage.hfin);
      plage.range = (plage.duration * 100) / this.baseRange;
      plage.type = "tolfin";
    }
    return plage;
  }

  /**
   * Calculate the duration in minutes between two heures
   * Calculates max range of time
   */
  _calculateDuration(h1: number, h2: number): number {
    let duration = 0;

    if (h1 !== h2) {
      duration = this._toMinutes(h2) - this._toMinutes(h1);
      if (h1 > h2) {
        duration = 1440 + duration;
      }
    }
    return duration;
  }

  /**
   * Calculate the duration in minutes between two heures
   * Calculates max range of time
   */
  _calculateBaseDuration(h1: number, h2: number): number {
    let duration = this._calculateDuration(h1, h2);

    if (duration === 0) {
      duration = 1440;
    }
    return duration;
  }

  /**
   * Transforms a value in hours (900) to a value in minutes (540)
   */
  _toMinutes(duration: number): number {
    const h = Math.floor(duration / 100);
    const m = duration % 100;

    return h * 60 + m;
  }
  // transform value in minutes (540) to a value in heures (900)
  /**
   * Transforms a value in heures (900) to a value in minutes (540)
   */
  _toDuration(minutes: number): number {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;

    return h * 100 + m;
  }
}
