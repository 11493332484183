module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="cw-fieldset c-form__scrollZone">\n  <div class="form-group">\n    <label for="libelle" >'+
((__t=(i18n.t('suvietat.nom')))==null?'':__t)+
'</label>\n    <input type="text" class="libelle form-control"></span>\n  </div>\n    <div class="form-group">\n      <label for="theme" >'+
((__t=(i18n.t('suvietat.theme')))==null?'':__t)+
'</label>\n      <input type="text" class="theme form-control"></span>\n  </div>\n  <div class="form-group">\n    <label for="datedebut" >'+
((__t=(i18n.t('suvietat.date_creation')))==null?'':__t)+
'</label>\n    <input type="text" class="datedebut form-control"></span>\n</div>\n<div class="form-group">\n  <label for="etat.libelle" >'+
((__t=(i18n.t('suvietat.traitement')))==null?'':__t)+
'</label>\n  <input type="text" class="etat.libelle form-control"></span>\n</div>\n</fieldset>\n';
}
return __p;
};
