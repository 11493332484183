import _ from 'underscore';
import { CWBaseFormView } from './cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWEditableGridModel } from 'core/grids/editablegrid/cwEditableGrid.model';
import { CWEditableGridView } from 'core/grids/editablegrid/cwEditableGrid.view';
import { CWEditableGridViewBase } from 'core/grids/editablegrid/cwEditableGridBase.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { ViewOptions } from 'Backbone';

export class CWEditedRowView<TModel extends CWBaseModel = CWBaseModel>
  extends CWBaseFormView<TModel>
  implements CWEditableGridViewBase<TModel>{

  /** Used to check like instanceof */
  public isEditedRowView: boolean;
  public pks: Array<string>;
  public parentId: string;
  public dataGrid: CWEditableGridView;

  constructor(options: ViewOptions<CWEditableGridModel> | any) {
    options = options || {};
    options.events = _.extend({
      'click .cwDataGrid__actionCell .btn': '_clickListener'
    }, options.events);
    super(options);
    this.isEditedRowView = true;
    this.pks = options.pks;
  }

  setDataGrid(dataGrid: CWEditableGridView): void {
    this.dataGrid = dataGrid;
  }

  _clickListener(event: JQueryMouseEventObject): void {
    const className = event.currentTarget.className.split(" ")[0];
    if (className === "phx-cell-save-action") {
      this.dataGrid.model.trigger("action:save");
    }
    else if (className === "phx-cell-copy-action") {
      this.dataGrid.model.trigger("action:copy");
    }
    else if (className === "phx-cell-delete-action") {
      this.dataGrid.model.trigger("action:delete");
    }
    else if (className === "phx-cell-revert-action") {
      if (CWSTR.isBlank(this.model.id)) {
        this.dataGrid.model.trigger("action:revert", false);
        this.dataGrid._finishVoletEdition(true);
      } else {
        this.dataGrid.model.trigger("action:revert", true);
        this.dataGrid._finishVoletEdition(true);
      }
    }
  }

  _enablePk(enabled: boolean): void {
    this.pkSelectors = this.pks;
    super._enablePk(enabled);
  }

  _getFieldset(): JQuery {
    return this.$el;
  }

  public canRemove(_model: TModel): boolean { /* eslint-disable-line @typescript-eslint/no-unused-vars */
    return null;
  }
  public canEdit(_model: TModel): boolean { /* eslint-disable-line @typescript-eslint/no-unused-vars */
    return null;
  }
  public canEditCallback(_model: TModel, callback: (canEdit: boolean) => void, event?: JQueryEventObject): void { //eslint-disable-line
    if (callback) {
      callback(true);
    }
  }

  _unlinkColumnNamesToInputs(jQueryRootSelector: JQuery): void {

    //Select each column title with label and transform it into a span
    jQueryRootSelector.find(".phx-grid-header-scroll tr th").each(function (_index, element) {
      const titleLabel = $(element).find("label.WCAG_table_title");

      if (titleLabel && titleLabel.length > 0) {
        const currentTitle = $(element).text();
        $(element).find("div label.WCAG_table_title").remove();
        $($(element).find("div")[0]).prepend($("<span class='title' style=\"display:inline-block;\">" + currentTitle + "</label>"));
      }

    });
  }

  /*
   * When an input in a editedRow has no tooltip,
   * it is associated to a corresponding column text in order to meet accessibility criterias.
   */
  _linkColumnNamesToInputs(jQueryRootSelector: JQuery, tableid: string | number): void {

    jQueryRootSelector.find(":input").each(function (_index: number, element: { [key: string]: any }) {
      const domEl = $(element);
      const name = domEl.attr("class").split(" ")[0];

      if (CWSTR.isBlank(element.title) && element.type !== "button") { //Associate to column text if there is no title for this input
        const classnameSelector = name.replace(/\./g, "\\.");
        let newid = _.uniqueId(name + "_uid_");
        let columnNumber = null;
        const row = $(jQueryRootSelector.closest("tr"));
        //Find out number of column
        $(row).children("td").each(function (aindex, el) {
          let index = aindex;

          if ($(el).find($(element)).length > 0) {
            index = $(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-tristate-box").length > 0 ? index + 1 : index;
            columnNumber = CWTYPE.LONG.format(index, "&&");
          }
        });

        //Add new id for the input
        if (element.type === "select-one") {
          jQueryRootSelector.find("select." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-error");
          const currentTitleEl = $(jQueryRootSelector.closest("#" + tableid)).find(" .phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first();
          const titleText = !CWSTR.isBlank($(element).parent().attr("title")) ? $(element).parent().attr("title") : $(currentTitleEl).text();
          $(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first().remove();
          $($(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div")[0]).prepend($("<label class='WCAG_table_title' for='" + newid + "' style=\"display:inline-block;\">" + titleText + "</label>"));

        } else {
          // other fields case
          let oldId: string = null;
          switch (domEl.attr("type")) {
            case "text":
              oldId = domEl.attr("id");
              if (!CWSTR.isBlank(oldId)) {
                newid = oldId;
              }
              jQueryRootSelector.find("input." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-error");
              break;
            case "checkbox":
              jQueryRootSelector.find("input." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-error");
              break;
            default:
              break;
          }

          const currentTitleEl = $(jQueryRootSelector.closest("#" + tableid)).find(" .phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first();
          const titleText = $(currentTitleEl).text();
          $(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first().remove();
          $($(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div")[0]).prepend($("<label class='WCAG_table_title' for='" + newid + "' style=\"display:inline-block;\">" + titleText + "</label>"));
        }
        //Substitute column span title by a label with for=inputId

        //required
        if ($(element).parents("td:first").find("[for]").hasClass("required") === true) {
          $(element).attr("aria-required", "true");
        }
      }

    });
  }
}