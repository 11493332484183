module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li aria-controls="'+
((__t=(id))==null?'':__t)+
'" ';
 if(typ){
__p+='uc-type="'+
((__t=(typ))==null?'':__t)+
'" ';
}
__p+='>\n  <div aria-label="'+
((__t=(nom))==null?'':__t)+
'" tabindex="0">\n    <a href="#'+
((__t=(id))==null?'':__t)+
'">\n        '+
((__t=(onglet))==null?'':__t)+
'\n    </a>\n    <span class="'+
((__t=(id))==null?'':__t)+
' ctime-tab-close">\n        <!--  <i class="material-icons cw-icon">close</i> -->\n        '+
((__t=(icone))==null?'':__t)+
'\n    </span>\n    </div>\n</li>';
}
return __p;
};
