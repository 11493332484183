module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="gererabsencesouhait-deleguer-popup">\n  <div class="gererabsencesouhait-deleguer-labels">\n    <label class="gererabsencesouhait-deleguer-label ui-phx-ihm-texte-application gererabsencesouhait-deleguer-title cw-texteNormal">'+
((__t=(i18n.t("message:GT_2002")))==null?'':__t)+
'</label>\n    <br/>\n    <label class="gererabsencesouhait-deleguer-label ui-phx-ihm-texte-application-important cw-titreBloc">'+
((__t=(i18n.t("messages:GT_2003")))==null?'':__t)+
'</label>\n    <br/>\n    <label class="gererabsencesouhait-deleguer-label ui-phx-ihm-texte-application cw-texteNormal">'+
((__t=(i18n.t("messages:GT_2016")))==null?'':__t)+
'</label>\n  </div>\n\n  <div class="d-flex" style="margin-top: 24px">\n    <button type="button" class="btnDeleguer btn btn-primary btn-withIcon bt-col-blue" style="margin-right: 24px" value="btnDeleguer">'+
((__t=(i18n.t("common:gererabsencesouhait.btnDeleguer")))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('valider','',16)))==null?'':__t)+
'</button>\n    <button type="button" class="btnAnnuler btn btn-secondary btn-withIcon" value="btnAnnuler">'+
((__t=(i18n.t("common:gererabsencesouhait.btnAnnuler")))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('croix','',16)))==null?'':__t)+
'</button>\n  </div>\n</div>\n';
}
return __p;
};
