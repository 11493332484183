module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="table_BriqueAujourdhui">\r\n    <div class="row no-gutters">\r\n        <div class="col-5 align-self-center">\r\n            <div class="cw-presence cw-presence--noMessage cw-texteSuperAllege">'+
((__t=(i18n.t('common:aujourdhui.noMessagePresence')))==null?'':__t)+
'</div>\r\n        </div>\r\n        <div class="col-7 cw-buttons-presence">\r\n            <button type=\'button\' class=\'cw-btn-presence btn btn-primary\'>\r\n                '+
((__t=((i18n.t('common:aujourdhui.btn_declarerPresence')).toUpperCase()))==null?'':__t)+
'\r\n            </button>\r\n        </div>\r\n    </div>\r\n    <div class="row no-gutters">\r\n        <div class="col-12 cw-buttons-agenda">\r\n            <button type=\'button\' class=\'cw-btn-agenda btn btn-secondary btn-withIcon\'>\r\n                <span>'+
((__t=((i18n.t('common:aujourdhui.btn_agenda')).toUpperCase()))==null?'':__t)+
'</span>'+
((__t=(UTILS.getSVGIcon('calendrier','cw-icon-text',16)))==null?'':__t)+
'\r\n            </button>\r\n        </div>\r\n    </div>\r\n</div>';
}
return __p;
};
