import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';


export class CWCollaborateursModel extends CWBaseModel {


  defaults(): { [key: string]: any } {
    return {
      "matric": "",
      "nom": "",
      "prenom": "",
      "matricaux": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/cycle/collaborateurs/";
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.matric)) {
      response.id = response.matric;
    }
    return response;
  }
}