import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
/**
 * Deleguer Model
 */
export class CWDeleguerModel extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    CWDeleguerModel.prototype.version = "2.4.0";
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.version = "2.4.0";
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/absence/composant/deleguer";
    };
  }
}
