import { BaseCollection } from 'core/models/base.collection.js';
import { SelecteurCheminModel } from './selecteurChemin.model.js';
import { STR } from 'utils/str.js';

export var SelecteurCheminColl = BaseCollection.extend({

  model: SelecteurCheminModel,
  habilitationV: "N",
  habilitationG: "N",
  usecase: "app",
  /**
   * Constructor
   * Selecteur Chemin Collection
   */
  initialize: function(params) {
    this.params = "";
    this.search = "";
    this.nbrang = "";
    if (params) {

      if (!STR.isBlank(params.structid)) {
        this.structid = params.structid;
      }
      if (!STR.isBlank(params.parentcode)) {
        this.parentcode = params.parentcode;
      }
      if (!STR.isBlank(params.parentdebsitu)) {
        this.parentdebsitu = params.parentdebsitu;
      }
      if (!STR.isBlank(params.parentfinsitu)) {
        this.parentfinsitu = params.parentfinsitu;
      }
      if (!STR.isBlank(params.datedebut)) {
        this.datedebut = params.datedebut;
      }
      if (!STR.isBlank(params.datefin)) {
        this.datefin = params.datefin;
      }
      if (!STR.isBlank(params.complet)) {
        this.complet = params.complet;
      } else {
        this.complet = false;
      }
      if (!STR.isBlank(params.niveau)) {
        this.niveau = params.niveau;
      }
      if (!STR.isBlank(params.multiple)) {
        this.multiple = params.multiple;
      }
      if (!STR.isBlank(params.selectionnes)) {
        this.selectionnes = params.selectionnes;
      }
      if (!STR.isBlank(params.intermediaire)) {
        this.intermediaire = params.intermediaire;
      }
      if (!STR.isBlank(params.partial)) {
        this.partial = params.partial;
      }
      if (!STR.isBlank(params.racine)) {
        this.racine = params.racine;
      }
      if (!STR.isBlank(params.nonrattachable)) {
        this.nonrattachable = params.nonrattachable;
      }
      if (!STR.isBlank(params.nonrattactiv)) {
        this.nonrattactiv = params.nonrattactiv;
      }
      if (!STR.isBlank(params.elementcode)) {
        this.elementcode = params.elementcode;
      }
      if (!STR.isBlank(params.ecran)) {
        this.usecase = params.ecran;
      }
      if (!STR.isBlank(params.selectpopu)) {
        this.selectpopu = params.selectpopu;
      }
    }
  },
  /**
   * Build the URL by the params
   */
  _buildURL: function() {
    var existParam = false;
    this.params = "";

    if (!STR.isBlank(this.structid)) {
      existParam = true;
      this.params += "structid=" + this.structid;
    }
    if (!STR.isBlank(this.parentcode)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "parentcode=" + this.parentcode;
    }
    if (!STR.isBlank(this.parentdebsitu)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "parentdebsitu=" + this.parentdebsitu;
    }
    if (!STR.isBlank(this.parentfinsitu)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "parentfinsitu=" + this.parentfinsitu;
    }
    if (!STR.isBlank(this.datedebut)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "datedebut=" + this.datedebut;
    }
    if (!STR.isBlank(this.datefin)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "datefin=" + this.datefin;
    }
    if (!STR.isBlank(this.complet)) {
      if (this.complet === true) {
        this.mode = "complet";
      } else {
        this.mode = "simple";
      }
    }
    if (!STR.isBlank(this.niveau)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "niveau=" + this.niveau;
    }
    if (!STR.isBlank(this.multiple)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "multiple=" + this.multiple;
    } else {
      if (existParam === true) {
        this.params += "&";
      }
      this.params += "multiple=false";
    }
    if (!STR.isBlank(this.selectionnes)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "selectionnes=" + this.selectionnes;
    }
    if (!STR.isBlank(this.intermediaire)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "intermediaire=" + this.intermediaire;
    } else {
      if (existParam === true) {
        this.params += "&";
      }
      this.params += "intermediaire=true";
    }
    if (!STR.isBlank(this.partial)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "partial=" + this.partial;
    } else {
      if (existParam === true) {
        this.params += "&";
      }
      this.params += "partial=true";
    }
    if (!STR.isBlank(this.racine)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "racine=" + this.racine;
    }
    if (!STR.isBlank(this.nonrattachable)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "nonrattachable=" + this.nonrattachable;
    }
    if (!STR.isBlank(this.nonrattactiv)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "nonrattactiv=" + this.nonrattactiv;
    }
    if (!STR.isBlank(this.elementcode)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "elementcode=" + this.elementcode;
    }
    if (!STR.isBlank(this.selectpopu)) {
      if (existParam === true) {
        this.params += "&";
      }
      existParam = true;
      this.params += "selectpopu=" + this.selectpopu;
    }
    if (existParam === true) {
      this.params = "?" + this.params;
    }

  },

  /**
   * Set niveau and build params
   */
  setNiveau: function(niveau) {
    this.niveau = niveau;
    this._buildURL();
  },

  /**
   * Set parent code and build params
   */
  setParentCode: function(parentcode) {
    this.parentcode = parentcode;
    this._buildURL();
  },

  /**
   * Adds mode params and search to build URL
   */
  url: function() {
    this._buildURL();
    var urlFin = Configuration.restRoot + "/rest/cheminstructure/" + this.mode + this.params + this.search;
    return urlFin;
  },

  /**
   * Adds mode params and search to build URL
   */
  clone: function() {
    var temp = Backbone.Collection.prototype.clone.call(this);
    temp.complet = this.complet;
    temp.racine = this.racine;
    temp.nonrattachable = this.nonrattachable;
    temp.nonrattactiv = this.nonrattactiv;
    return temp;
  }

});
