import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCommonFileTransfer from './cwFile_transfer.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWButtonBarView } from 'core/controls/cwButtonBar.view';
import { CWFileTransferItemView } from './cwFileTransferItem.view';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWHEADERS } from 'utils/cwHeaders';
import { CWLOG } from 'utils/cwLog';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPieceJointeType } from 'src/uc/common/piecesjointes/views/cwPieceJointes.view';
import { FileExtensionModel } from 'core/models/fileExtension.model.js';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

/**
 * File transfer component
 *
 * This component has the hability to be reloaded on different windows and maintain its state. This hability
 * is obtained thru the use of the function renderRefresh
 */
export class CWFileTransferView extends CWBaseFormView {

  /**
   * uploadReady event.
   *
   */
  /**
   * successUpload event.
   *
   */
  /**
   * errorUpload event.
   *
   */
  /**
   * progressUpload event.
   *
   */

  public tagName: string;
  public className: string;
  private extensionModel: CWBaseModel;
  private multiples: boolean;
  private multipleTransfer: boolean;
  private delayedUpload: boolean;
  private title: string;
  private type: string;
  private entite: string[];
  private inputField: CWFileTransferItemView;
  private btnBar: CWButtonBarView;
  private inputFields: Array<CWFileTransferItemView>;
  private extensionsAllowed: string;

  /**
   * Constructor
   * Component for transfering file from the client to the serveur
   * Used to upload Photos and Pièces jointes
   * Attributes:
   * Events: uploadReady, successUpload, errorUpload, progressUpload
   */
  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.tagName = options.tagName || "div";
    options.className = options.className || "phx-file-transfer";
    super(options);

    this.template = TPLCommonFileTransfer;

    this.model = new CWBaseModel();
    this.extensionModel = new FileExtensionModel();

    this.multiples = false;
    this.delayedUpload = false;
    if (options) {
      this.entite = options.entite;
      this.type = options.type; // for now PHCOLLAB and PJEVTABS
      if (options.habContext) {
        this.habilitationContext(options.habContext);
      }
      if (_.isBoolean(options.delayedUpload)) {
        this.delayedUpload = options.delayedUpload;
      }
    }
    this.btnBar = new CWButtonBarView({ id: "filetransfer_btnbar", isHideOnDisabled: false });
    this.btnBar.model.on("btn:click", this._buttonAction, this);

    this.multipleTransfer = false;

    this.inputField = null;
  }

  public render(): CWFileTransferView {
    const json = { "i18n": i18n, UTILS: UTILS };
    this.$el.html(this.template(json));
    this.$el.find(".fil_transfer_btnBar").html(this.btnBar.render().el);

    this.btnBar.hideButton("new");
    this.btnBar.hideButton("cancel");
    this.btnBar.hideButton("delete");
    this.btnBar.hideButton("copy");
    this.btnBar.hideButton("revert");
    this.btnBar.hideButton("save");

    this.btnBar.btnClasses["confirmer"] = "btn-primary btn-withIcon";
    this.btnBar.addButton("confirmer", i18n.t('common:confirmer'), "false");
    this.btnBar.enableButton("confirmer");

    this.btnBar.btnClasses["annuler"] = "btn-secondary btn-withIcon";
    this.btnBar.addButton("annuler", i18n.t('common:revert'), "false");
    this.btnBar.enableButton("annuler");

    const btnAnn = "<span>" + i18n.t('common:revert') + "</span>" + UTILS.getSVGIcon("croix", "", 16, null);
    const btnApl = "<span>" + i18n.t('common:confirmer') + "</span>" + UTILS.getSVGIcon("valider", "", 16, null);
    this.btnBar.$el.find(".confirmer").html(btnApl);
    this.btnBar.$el.find(".annuler").html(btnAnn);

    this._mapModelToForm(this.$el, this.model); //RGAA - needed to show "(*) : champ obligatoire"
    this._retrieveSupportedFileExtensions(this.type, (ext: string): void => {
      this._configureForm(ext);
    });

    return this;
  }

  private _buttonAction(buttonId: string): void {
    CWLOG.debug("Button clicked in motifsregul : " + buttonId);
    switch (buttonId) {
      case "confirmer":
        if (this.delayedUpload === true) {
          // avisamos que hemos terminado
          this.model.trigger("uploadReady", this);
        } else {
          if (this.shouldSubmit()) {
            // realizamos el submit
            this.submit(this.entite);
          }
        }
        break;
      case "annuler":
        this._clearInputFileField();
        this.$el.find(".file-transfer-description").prop("disabled", false);
        this.$el.find(".file-transfer-description").val("");
        this.$el.find(".file-transfer-label").html("");
        (this.$el.find("form") as any).clearForm();
        this.model.trigger("cancelUpload");
        break;
      default:
        break;
    }
  }

  /**
   *  Set the habilitation used by the component to upload the file
   */
  public habilitationContext(context: CWHabilitationContext): void {
    if (this.model) {
      this.model.setHabContext(context);
    }
    if (this.extensionModel) {
      this.extensionModel.setHabContext(context);
    }
  }

  public setHabContext(context: CWHabilitationContext): void {
    if (this.model) {
      this.model.setHabContext(context);
    }
    if (this.extensionModel) {
      this.extensionModel.setHabContext(context);
    }
  }

  /**
   * This function triggers a form submit and listens for the resulting events
   *
   */
  public submit(evenement: string[], isModification?: number): void {
    if (this.shouldSubmit() && evenement) {
      this.$el.find("input[name='id_entite']").val(evenement);
      // override form submit
      let format = "json";
      if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
        format = "xml";
      }

      //Customer 145490
      if (isModification > -1 && this.type === "PHCOLLAB") {
        this.$el.find("form").attr("action", this.$el.find("form").attr("action") + "/" + isModification);
      }

      (this.$el.find("form") as any).ajaxForm({
        //data:{"X_REQUESTED_WITH":"XMLHttpRequest"}, //TODO put here the habilitations header
        dataType: format,
        beforeSubmit: (arr: any, form: any, options: { [key: string]: any }): void => {
          options.dataType = 'json';
          options.headers = CWHEADERS.habilitationContext(this.model.getHabContext().get("onglet"), this.model.getHabContext().get("foncCour"), "A");
          options.headers.charset = document.charset;
          if (this.multipleTransfer !== true) {
            this.$el.find("form :file").prop("disabled", true);
            this.$el.find(".file-transfer-description").prop("disabled", true);
            this.$el.find(".file-transfer-cancel").prop("disabled", true);
          }

          if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
            this.$el.find(".file-transfer-label").html('<img src="assets/images/CTIME_UX_Loader.gif" />');
          }
        },
        success: (): void => {
          this._cleanForm();

          this.model.trigger("successUpload");
        },
        uploadProgress: (event: any, pos: any, total: any, per: string): void => {
          //self.$el.find(".file-transfer-label").html(per+"%");
          this.model.trigger("progressUpload", per + "%");
        },
        error: (): void => {
          if (navigator.appVersion && navigator.appVersion.indexOf("MSIE 9.0") > 0) {
            CWMSGS.showError(i18n.t('common:uploadfile.errorAucuneIe9'));
          }
          this._cleanForm();

          this.model.trigger("errorUpload");
        }
      });

      this.$el.find("form").submit();
    }
  }

  /**
   * Check if there are files to upload
   *
   */
  public shouldSubmit(): boolean {
    return this.inputField && this.inputField.hasValue() && this.inputField.isExtensionAccepted();
  }

  /**
   * Retrieves the list of files to upload
   *
   */
  public fileToUpload(): CWPieceJointeType {
    if (this.shouldSubmit()) {
      const obj = {
        code: "",
        libelle: this.inputField.getFileName(),
        description: this.getDescription(),
        cid: this.cid
      };
      return obj;
    } else {
      return null;
    }
  }

  public getDescription(): string {
    return this.$el.find(".file-transfer-description").val() as string;
  }
  /**
   * If the upload will be delayed upon validation of the host component, then the component only trigger an event.
   * Otherwise the submit of the form is called to start the upload
   *
   */
  private _submit(): void {
    if (this.delayedUpload === true) {
      // avisamos que hemos terminado
      this.model.trigger("uploadReady", this);
    } else {
      if (this.shouldSubmit()) {
        // realizamos el submit
        this.submit(this.entite);
      }
    }
  }

  /**
   * Removes all form information and reset to its default
   *
   */
  private _cleanForm(): void {
    if (this.multipleTransfer !== true) {
      this._clearInputFileField();

      this.$el.find(".file-transfer-description").prop("disabled", false);
      this.$el.find(".file-transfer-description").val("");
      this.$el.find(".file-transfer-label").html("");

      (this.$el.find("form") as any).clearForm();

      this.model.trigger("cancelUpload");
      // not used in pieces jointes.
    }
  }

  /**
   * Resets and removes all the input fields
   *
   */
  private _clearInputFileField(): void {
    if (this.inputField) {
      this.inputField.remove();
      this.inputField = null;
    }

    this._addInputFileField();
  }

  /**
   * Add an input field to allow the selection of a file
   *
   */
  private _addInputFileField(): void {
    const input = new CWFileTransferItemView({ allowRemove: false, allowAdd: false, extensionsAllowed: this.extensionsAllowed, btnBar: this.btnBar });
    const div = this.$el.find(".more-input-files");
    if (div && input) {
      input.setName("fichier");
      input.setFileExtensions(this.extensionModel.get("data"));
      input.listenTo(input.model, "add", (): void => {
        this._addInputFileField();
      });
      input.listenTo(input.model, "remove", (): void => {
        input.remove();
        //this._refreshInputs();
        if (this.inputFields.length === 0) {
          this._addInputFileField();
        }
      });
      this.inputField = input;

      div.append(input.render().el);
    }
  }

  /**
   * Configure the form and add the function to treat the upload process
   *
   *
   */
  private _configureForm(extensions: string): void {
    this._addInputFileField();

    // set form url
    if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
      this.$el.find("form").attr("action", Configuration.restRoot + "/rest/fichier/upload/textarea");
    } else {
      this.$el.find("form").attr("action", Configuration.restRoot + "/rest/fichier/upload");
    }
    // hide input file
    this.$el.find(".file-transfer-label").html("");

    // set hidden values
    this.$el.find("input[name='type_utilisation']").val(this.type);
    this.$el.find("input[name='id_entite']").val(this.entite);

    // set supported file extensions
    if (extensions) {
      this.$el.find("form :file").attr("accept", extensions);
    }
  }

  /**
   * Retrieve the file extensions allowed for the upload
   *
   *
   */
  private _retrieveSupportedFileExtensions(type: string, callback: (arg1?: string) => any): void {
    this.extensionModel.id = type;
    this.extensionModel.fetch({
      success: (fresh: Backbone.Model): void => {
        const data = fresh.get("data");
        this.extensionsAllowed = data;
        if (callback) {
          callback(data);
        }
      },
      error: (): void => {
        if (callback) {
          callback();
        }
      }
    });
  }

  public setMultipleTransfer(): void {
    this.multipleTransfer = true;
  }
}
