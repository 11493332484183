import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDelegationsDroitsCollabModel } from './cwDelegationsDroitsCollab.model';
import { CWDelegationsModel } from './cwDelegations.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWMSGS } from 'utils/cwMsgs';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'utils/sync.js';


export class CWDroitsdelWorkflow extends CWReadOnlyModel {

  /**
   * Event launch for Backbone when a model change the property value
   *
   */

  /**
   * Constructor
   * Workflow model for component droits delegations
   */

  EMPTY_MOTIF: CWDelegationsModel;
  filterModel: any;
  tableModel: any;
  btnBarModel: any;
  formModel: any;
  navigationModel: any;
  droitsBase: CWDelegationsDroitsCollabModel;
  collectionBase: any;
  isCreation: boolean;
  pDiversIdCollabModel: any
  pDiversModifDeleg: any;
  paramIdCollab: string;
  deleAfRole: number;
  deleNiveau: number;
  utilCode: number;
  fullDroids: boolean;
  fullModels: any;
  ctxPeriode: any;
  form: any;
  isCopying: boolean;
  btnBar: any;
  public module: string;
  public $appendTo: JQuery; //pour les messages

  constructor(options: { [key: string]: any }) {
    options = options || {};
    super(options);
    this.module = options.module;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    this.EMPTY_MOTIF = new CWDelegationsModel({}, { "usecase": this.module });
    //this.combosModel = null;
    this.filterModel = null;
    this.tableModel = null;
    this.btnBarModel = null;
    this.formModel = null;
    //this.headerModel = null;
    this.navigationModel = null;
    this.droitsBase = new CWDelegationsDroitsCollabModel({}, { "usecase": this.module });
    this.collectionBase = null;
    this.isCreation = false;
    //params
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.pDiversModifDeleg = GLOBAL_DATA.paramDivers.get("modifDeleg");
    this.paramIdCollab = this.pDiversIdCollabModel.get("valeur");
    this.deleAfRole = 0; //valeur par defaut
    this.deleNiveau = 0; //valeur par defaut
    if (GLOBAL_DATA.paramDivers.get("deleAfRole") && GLOBAL_DATA.paramDivers.get("deleAfRole").get("valeur")) {
      this.deleAfRole = parseInt(GLOBAL_DATA.paramDivers.get("deleAfRole").get("valeur"), 10);
    }
    if (GLOBAL_DATA.paramDivers.get("deleNiveau") && GLOBAL_DATA.paramDivers.get("deleNiveau").get("valeur")) {
      this.deleNiveau = parseInt(GLOBAL_DATA.paramDivers.get("deleNiveau").get("valeur"), 10);
    }
    //set the utilCode of actual collaborateur
    if (options.utilCode) {
      this.utilCode = options.utilCode;
    }
    this.fullDroids = true;
    this.fullModels = null;
    if (options.ctxPeriode) {
      this.ctxPeriode = options.ctxPeriode;
      this.isCreation = true;
    }
    // ready = true when the setUp is finished. Just one setUp per life
    // cycle of the module
    this.set("ready", false);
  }

  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback: any): void {
    // Load data
    this.filterModel.off("search");
    this.formModel.off("form:edited");
    // Declare events consumers
    this.filterModel.on("search", this._filterTable, this);
    this.tableModel.off("change:value");
    this.tableModel.on("change:value", this._tableValueChange, this);
    this.btnBarModel.off("btn:click");
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.formModel.on("form:edited", this._formEdited, this);
    this.listenTo(this.tableModel.coll, "collectionParams:cleaned", () => {
      this.filterModel.trigger("clean:filter");
    });
    this.droitsBase.setHabContext(this.getHabContext());
    if (this.utilCode && CWHABILITATION.canView(this.getHabContext().get("foncCour"))) {
      this.tableModel.coll.setHabContext(this.getHabContext());
      this.tableModel.coll.utilCode = this.utilCode;
      if (this.filterModel) {
        const filter: any = {};

        if (this.ctxPeriode && this.ctxPeriode.datedeb) {
          filter.datedeb = this.ctxPeriode.datedeb;
        }
        if (this.ctxPeriode && this.ctxPeriode.datefin) {
          filter.datefin = this.ctxPeriode.datefin;
        }
        filter.utilCode = this.utilCode;
        this.tableModel.coll.applyFilter(filter)
      }
      this.tableModel.coll.fetch({
        success: () => {
          if (this.tableModel.coll.length === 0) {
            if (!this.isCreation) {
              this.btnBarModel.set("mode", "C");
              this.formModel.trigger("hide:form");
            } else {
              //Customer#188579: initiate in creation mode if the list of the left screen is empty
              this.btnBarModel.set("mode", "E");
              if (this.get("ctxPeriode") && !CWSTR.isBlank(this.get("ctxPeriode").datedeb) && !CWSTR.isBlank(this.get("ctxPeriode").datefin)) {
                this.formModel.get("value").set("datedeb", this.get("ctxPeriode").datedeb);
                this.formModel.get("value").set("datefin", this.get("ctxPeriode").datefin);
              }
              if (this.form.ctxEcran === "suivicollab" || this.form.ctxEcran === "utilisateur" || this.form.ctxEcran === "droitsdel") {
                this.droitsBase.datefrom = this.formModel.get("value").get("datedeb");
                this.droitsBase.queretro = true;
                this.droitsBase.delegation = undefined;
              }
              this.droitsBase.utilCode = this.utilCode;
              this.droitsBase.fetch({
                success: (fresh: { [key: string]: any }) => {
                  this.collectionBase = this._arrayToCollection(fresh.get("profils"));
                  this.form.table.model.coll.reset(this.collectionBase.models);
                  this.form._manageInitChecks();
                  //change the view droits to masquer
                  $(this.form.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
                  this.fullDroids = true;
                }
              });
              this.formModel.trigger("show:form");
            }
          } else if (this.tableModel.coll.length > 0) {
            this.btnBarModel.set("mode", "R");
            this.formModel.trigger("show:form");
          }
          if (callback) {
            callback();
          }
        },
        error: () => {
          if (callback) {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
    this.set("ready", true);
  }

  /**
   * Masquer or afficher the droits delegues
   */
  _masquerDroits(type: string): void {
    if (type === "masquer") {
      this.fullDroids = false;
      this.fullModels = this.form.table.model.coll.models;
    } else if (type === "afficher") {
      this.fullDroids = true;
    }
    this._viewDroits();
  }

  _isPostDate(date: string): boolean {
    let post = false;

    if (!CWSTR.isBlank(date)) {
      const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;

      post = today >= date ? false : true;
    }
    return post;
  }

  _isPostPeriode(datedeb: string, datefin: string): boolean {
    let post = false;

    if (this._isPostDate(datedeb) && this._isPostDate(datefin)) {
      post = true;
    }
    return post;
  }

  /**
   * View the droits of the collection in the table
   */
  _viewDroits(): void {
    if (this.fullDroids) {
      this.form.table.model.coll.reset(this.fullModels);
    } else {
      const collection = new Backbone.Collection();

      _.each(this.fullModels, (model: any) => {
        //If the filter "masquer" is active, only show droits type "D"
        if (model.get("droit") === "D") {
          if (model.get("nivel") === 1 && this._findChildsN2N3(model.get("nivel1"))) {
            collection.add(model);
          } else if (model.get("nivel") === 2) {
            //adds level 2 if it is a group
            if (model.levelType === "group") {
              collection.add(model);
              //adds level 2 if it is a role with children
            } else if (model.levelType !== "group" && this._findChildsN3(model.get("nivel1"), model.get("nivel2"))) {
              collection.add(model);
            }
            //adds level 3
          } else if (model.get("nivel") === 3) {
            collection.add(model);
          }
        }
      });
      this.form.table.model.coll.reset(collection.models);
    }
    this.form._manageInitChecks();
  }

  /**
   * Find if there are a n2 or n3 model for n1 model
   */
  _findChildsN2N3(n1: string): boolean {
    let objResult = false;

    _.each(this.form.table.model.coll.models, (model: any) => {
      if (model.get("nivel") === 2 && model.get("nivel1") === n1 && this._findChildsN3(model.get("nivel1"), model.get("nivel2"))) {
        objResult = true;
      }
    });
    return objResult;
  }

  /**
   * Find if there are a n3 model for n2 model
   */
  _findChildsN3(n1: string, n2: string): boolean {
    let objResult = false;

    _.each(this.form.table.model.coll.models, (model: any) => {
      if ((model.get("nivel") === 3 || (model.get("nivel") === 2 && model.levelType === "group")) &&
        model.get("nivel1") === n1 && model.get("nivel2") === n2 && model.get("droit") === "D") {
        objResult = true;
      }
    });
    return objResult;
  }

  /**
   * Load the collection in the table
   */
  _loadData(utilCode: any, callback: () => void): void {
    this.tableModel.set("value", null);
    this.EMPTY_MOTIF = new CWDelegationsModel({}, { "usecase": this.module });
    if (utilCode) {
      this.tableModel.coll.utilCode = utilCode;
      this.tableModel.coll.fetch({
        success: () => {
          if (this.tableModel.coll.models.length === 0) {
            this.tableModel.set("value", null);
            this.formModel.set("value", this.EMPTY_MOTIF, { silent: true });
            this.trigger("resetForm");
            this.btnBarModel.set("mode", "C");
            this.formModel.trigger("hide:form");
          }
          if (callback) {
            callback();
          }
        },
        error: () => {
          if (callback) {
            callback();
          }
        }
      });
    } else {
      this.tableModel.coll.reset();
      this.tableModel.set("value", null);
      this.formModel.set("value", this.EMPTY_MOTIF, { silent: true });
      this.trigger("resetForm");
      if (callback) {
        callback();
      }
    }
  }

  /**
   * Apply the filter to the collection
   */
  _filterTable(filter: any): void {
    const newFilter: any = {};
    const tableColl = this.tableModel.coll;

    if (filter?.datedeb) {
      newFilter.datedeb = filter.datedeb;
    }
    if (filter?.datefin) {
      newFilter.datefin = filter.datefin;
    }
    if (!CWSTR.isBlank(filter.delegue) && !CWSTR.isBlank(filter.delegue.matricule)) {
      newFilter.delegue = filter.delegue.utilcode;
    }
    if (filter?.retro !== "all") {
      newFilter.retro = filter.retro;
    }
    if (!CWSTR.isBlank(tableColl.utilCode)) {
      tableColl.applyFilter(newFilter);
      tableColl.fetch({
        success: () => {
          if (tableColl.length > 0) {
            const row = this.tableModel.coll.at(0);

            this.tableModel.resetScroll();
            row.trigger("row:click", row);
          } else {
            this.tableModel.set("value", null);
          }
        }
      });
    }
  }

  /**
   * Change the value to the left table and change the form model
   */
  _tableValueChange(model: any): void {
    const value = model.get("value");
    const valueForm = new CWDelegationsModel({}, { "usecase": this.module });
    const modelId = (value === null) ? null : encodeURIComponent(value.get("id"));
    const modelUtilCode = this.utilCode;

    // Update Btn Bar and Form
    if (value === null) {
      this.btnBarModel.set("mode", "R");
      this.btnBarModel.set("mode", "C");
      this.formModel.trigger("hide:form");
      this.EMPTY_MOTIF = new CWDelegationsModel({}, { "usecase": this.module });
      this.formModel.set("value", this.EMPTY_MOTIF, { silent: true });
    } else {
      // Fetch the complete data of the model before notifying the form
      this.btnBarModel.set("mode", "C");
      this.btnBarModel.set("mode", "R");
      valueForm.utilCode = modelUtilCode;
      valueForm.id = modelId;
      valueForm.usecase = this.form.ctxEcran;
      valueForm.setHabContext(this.getHabContext());
      if (modelUtilCode && modelUtilCode !== null) {
        valueForm.fetch({
          success: (freshModel: { [key: string]: any }) => {
            // Store new values to the model
            freshModel.store();
            this._setModelOnFormModeRead(freshModel);
            this.isCreation = false;
          },
          data: { datefrom: value.get("datedeb") }
        });
      } else {
        valueForm.revert();
        this._setModelOnFormModeRead(valueForm);
        this.isCreation = false;
      }
    }
  }

  _setModelOnFormModeRead(value: { [key: string]: any }): void {
    let allChecked = false;

    this.formModel.set("value", value);
    allChecked = this.form.isAllChecked;
    if (this.fullModels && this.fullModels.length === 0) {
      allChecked = false;
    }
    if (this.form.table.$el) {
      (this.form.table.$el).find(".checkAll").prop("checked", allChecked);
    }
    this.fullModels = this.form.table.model.coll.models;
    this._viewDroits();
    this.formModel.trigger("show:form");
    this.btnBar._manageMode();
  }

  /**
   * Load the action to the Button Bar
   */

  _buttonAction(buttonId: string): void {
    let target: any;

    switch (buttonId) {
      case "save": {
        const objArray = this._collectionToArray(this.form.table.model.coll);

        target = this.formModel.get("value");
        target.utilCode = this.utilCode;
        target.set("profils", objArray);
        target.usecase = this.form.ctxEcran;
        target.setHabContext(this.getHabContext());
        if (this.isCreation) {
          target.id = null;
        }
        target.save(null, {
          success: (fresh: { [key: string]: any }) => {
            this.tableModel.coll.fetch({
              success: () => {
                this.isCreation = false;
                this.isCopying = false;
                this.tableModel.selectRow(fresh);
              }
            });
          }
        });
        break;
      }
      case "revert": {
        this.isCreation = false;
        this.isCopying = false;
        if (this.tableModel.get("value") === null) {
          let row = null;

          // Revert a create operation.
          if (this.tableModel.formerLineID) {
            row = this.tableModel.coll.get(this.tableModel.formerLineID);
            this.tableModel.formerLineID = null;
          }
          if (CWSTR.isBlank(row)) {
            this.formModel.trigger("hide:form");
            this.btnBarModel.set("mode", "R");
            this.EMPTY_MOTIF = new CWDelegationsModel({}, { "usecase": this.module });
            this.formModel.trigger("change:value");
            this.btnBarModel.set("mode", "C");
            this.tableModel.set("value", null);
          } else {
            this.EMPTY_MOTIF = new CWDelegationsModel({}, { "usecase": this.module });
            row.trigger("row:click", row);
          }
        } else if (this.tableModel.get("value").get("id") !== this.formModel.get("value").get("id")) {
          const models = this.form.table.model.coll.models;
          const row = this.tableModel.get("value");
          const copy = this.formModel.get("value");

          // Revert a duplicate operation
          this.formModel.set("value", row);
          this.form.table.model.coll.reset(models);
          this.btnBarModel.set("mode", "R");
          this._setModelOnFormModeRead(row);
          copy.set("id", this.formModel.get("value").get("code"), { silent: true });
          copy.set("code", this.formModel.get("value").get("code"), { silent: true });
          this.formModel.set("value", copy, { silent: true });
        } else {
          // Revert an update operation.
          this.formModel.get("value").usecase = this.form.ctxEcran;
          this.formModel.get("value").setHabContext(this.getHabContext());
          this.formModel.get("value").revert();
          this.formModel.trigger("change:value");
          this.fullModels = this.form.table.model.coll.models;
          this._viewDroits();
          this.btnBarModel.set("mode", "R");
        }
        this.form._manageInitChecks();
        break;
      }
      case "new": {
        this.form.isAllChecked = false;
        this.isCreation = true;
        this.tableModel.formerLineID =
          this.tableModel.get("value") && this.tableModel.get("value").get("id") ?
            this.tableModel.get("value").get("id") : null;
        if (this.tableModel.get("value") !== null) {
          this.tableModel.get("value").trigger("row:unselect", this.tableModel.get("value"));
          this.tableModel.set("value", null);
        }
        this.EMPTY_MOTIF = new CWDelegationsModel({}, { "usecase": this.module });
        this.formModel.set("value", this.EMPTY_MOTIF, { silent: true });
        this.formModel.trigger("change:value");
        this.form.table.model.coll.reset();
        if (this.form.ctxEcran === "suivicollab" || this.form.ctxEcran === "utilisateur" || this.form.ctxEcran === "droitsdel" || this.form.ctxEcran === "cw-appContainer") {
          this.droitsBase.datefrom = this.formModel.get("value").get("datedeb");
          this.droitsBase.queretro = true;
          this.droitsBase.delegation = undefined;
        }
        this.droitsBase.utilCode = this.utilCode;
        this.droitsBase.fetch({
          success: (fresh: { [key: string]: any }) => {
            this.collectionBase = this._arrayToCollection(fresh.get("profils"));
            this.form.table.model.coll.reset(this.collectionBase.models);
            this.form._manageInitChecks();
            //change the view droits to masquer
            $(this.form.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
            $(this.form.table.$el).find(".checkAll").prop("checked", this.form.isAllChecked);
            this.fullDroids = true;
          }
        });
        this.btnBarModel.set("mode", "E");
        this.formModel.trigger("show:form");
        break;
      }
      case "copy": {
        if (this.tableModel.get("value") !== null) {
          this.isCreation = true;
          const copy = new CWDelegationsModel(this.formModel.get("value").toJSON(), { "usecase": this.module });
          const models = this.form.table.model.coll.models;

          copy.utilCode = this.utilCode;
          //Delete attributes that are not needed for DelegationsModel
          copy.unset("auteur", { silent: true });
          copy.unset("datetrace", { silent: true });
          copy.unset("delegant", { silent: true });
          copy.unset("heuretrace", { silent: true });
          copy.unset("profil", { silent: true });
          copy.set({ id: null, code: null }, { silent: true });
          this.formModel.set("value", copy);
          this.form.table.model.coll.reset(models);
          this.btnBarModel.set("mode", "E");
          this.form._manageInitChecks();
        }
        break;
      }
      case "delete": {
        target = this.formModel.get("value");
        if (this.pDiversModifDeleg.get("valeur") === "1" && !this._isPostPeriode(target.get("datedeb"), target.get("datefin"))) {
          this.form._showSupprimerError();
        } else {
          CWMSGS.showConfirmAdapter(i18n.t('droitsdel.delconfirm'), (result) => {
            if (result === "Y") {
              const position = this._findObject(this.tableModel.coll.models, target, "code");

              target.usecase = this.form.ctxEcran;
              target.setHabContext(this.getHabContext());
              target.destroy({
                success: () => {
                  this.tableModel.coll.fetch({
                    success: () => {
                      let next = this.tableModel.coll.at(position);

                      if (next === undefined && position > 0) {
                        next = this.tableModel.coll.at(position - 1);
                      }
                      if (next !== undefined) {
                        next.trigger("row:click", next);
                      } else {
                        this.tableModel.set("value", null);
                      }
                    }
                  });
                },
                wait: true
              });
            }
          }, null, null, null, this.$appendTo);
          break;
        }
      }
    }
    this.btnBar._manageMode();
  }

  /**
   * Find the object into the collection with the value of attribute
   */
  _findObject(collection: any, object: { [key: string]: any }, attrib: any): number {
    let resp = -1;

    _.each(collection, (obj: { [key: string]: any }, index: number) => {
      if (obj.get(attrib) === object.get(attrib)) {
        resp = index;
      }
    });
    return resp;
  }

  /**
   * Function launched when a field is edited
   */
  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
  }

  /**
   * Transforms a Backbone collection to an array
   */
  _collectionToArray(objCollection: any): any {
    const elements: any = [];

    if (objCollection && objCollection.models) {
      _.each(objCollection.models, (obj: { [key: string]: any }) => {
        const objElement: any = {};

        if (obj.get("nivel") === 1) { //profils
          objElement.code = obj.get("code");
          objElement.libelle = obj.get("libelle");
          objElement.complet = obj.get("complet");
          elements.push(objElement);
        } else if (obj.get("nivel") === 2 && obj.levelType === "role") { //roles
          const objNivel1: any = _.last(elements);
          //roles container
          if (!objNivel1.roles) {
            objNivel1.roles = [];
          }
          objElement.code = obj.get("nivel2");
          objElement.libelle = obj.get("libelle");
          objElement.perimetres = obj.get("perimetres");
          objNivel1.roles.push(objElement);
        } else if (obj.get("nivel") === 3 || (obj.get("nivel") === 2 && obj.levelType === "group")) { //groupes
          let objNivel1: any = _.last(elements);
          const profilComplet = objNivel1.complet;
          let delegant: any = {};

          //find the last profil of nivel 1
          if (obj.get("nivel") === 2) {
            // group in level 2 needs a role father
            if (!objNivel1.roles && !CWSTR.isBlank(obj.role)) {
              objNivel1.roles = [];
              const objRole = obj.role;
              objNivel1.roles.push(objRole);
            }
            objNivel1 = objNivel1.roles[0];
          } else {
            objNivel1 = _.last(objNivel1.roles);
          }
          //group container
          if (!objNivel1.groupes) {
            objNivel1.groupes = [];
          }
          objElement.code = obj.get("code");
          objElement.libelle = obj.get("libelle");
          objElement.datedeb = obj.get("datedeb");
          objElement.datefin = obj.get("datefin");
          objElement.perimetre = obj.get("perimetre");
          if (this.deleNiveau === 1) {
            // group without chekbox value from profil checkbox
            objElement.delegue = profilComplet;
          } else {
            objElement.delegue = obj.get("delegue");
          }
          objElement.parent = obj.get("parent");
          objElement.droit = obj.get("droit");
          //delegant          
          if (!_.isNull(obj.get("delegant"))) {
            delegant.matricule = obj.get("delegant").get("matricule");
            delegant.matriculeaux = obj.get("delegant").get("matriculeaux");
            delegant.nom = obj.get("delegant").get("nom");
            delegant.prenom = obj.get("delegant").get("prenom");
            delegant.utilcode = obj.get("delegant").get("utilcode");
            delegant.utilnom = obj.get("delegant").get("utilnom");
          } else {
            delegant = null;
          }
          objElement.delegant = delegant;
          //insert in the array if delegue = true
          if (objElement.delegue) {
            objNivel1.groupes.push(objElement);
          }
        }
      });
    }
    return elements;
  }

  /**
   * Transforms a Array to Backbone collection
   */

  _arrayToCollection(objArray: any): any {
    const collection = new Backbone.Collection();

    if (objArray) {
      let maxRoles = 0;

      _.each(objArray, (obj: { [key: string]: any }) => {
        const roles = (obj && obj.roles) ? obj.roles.length : 0;
        maxRoles = (roles > maxRoles) ? roles : maxRoles;
      });
      //nivel 1 (profils)
      _.each(objArray, (obj1: { [key: string]: any }, index1: number) => {
        const model: any = new CWBaseModel();

        model.set("nivel", 1);
        model.set("nivel1", obj1.code);
        model.set("code", obj1.code);
        model.set("libelle", obj1.libelle);
        model.set("complet", obj1.complet);
        model.set("droit", obj1.droit);
        model.set("id", index1 + "," + obj1.code);
        //atributes for tree table view
        model.isExpanded = true;
        model.level = 1;
        model.collapsible = true;
        model.branch = index1;
        //have a children
        if (obj1.roles && obj1.roles.length > 0) {
          model.hasChild = true;
        } else {
          model.hasChild = false;
        }
        collection.add(model);
        // No Role-Depends ParamDivers deleAfRole
        if (maxRoles <= 1 && this.deleAfRole === 0) {
          //hidden roles -> nivel 2 (groups)
          const obj2 = obj1.roles[0];
          //nivel 3  (habs)
          _.each(obj2.groupes, (obj3: { [key: string]: any }, index3: number) => {
            let delegant: any = new CWBaseModel;
            const model3: any = new CWBaseModel();

            model3.set("nivel", 2);
            model3.set("nivel1", obj1.code);
            model3.set("nivel2", obj2.code);
            model3.set("code", obj3.code);
            model3.set("libelle", obj3.libelle);
            model3.set("datedeb", obj3.datedeb);
            model3.set("datefin", obj3.datefin);
            model3.set("perimetre", obj3.perimetre);
            model3.set("delegue", obj3.delegue);
            model3.set("parent", obj3.parent);
            model3.set("droit", obj3.droit);
            model3.set("id", index1 + "," + index3 + "," + obj3.code);
            model3.set("id1", index1 + "," + obj1.code);
            model3.set("id2", index1 + "," + null + "," + obj2.code);
            //atributes for tree table view
            model3.isExpanded = true;
            model3.level = 2;
            model3.levelType = "group";
            model3.collapsible = true;
            model3.branch = index1;
            //have a children
            model3.hasChild = undefined;
            //role information
            model3.role = {};
            model3.role.code = obj2.code;
            model3.role.libelle = obj2.libelle
            model3.role.perimetres = obj2.perimetres
            //delegant
            if (!_.isNull(obj3.delegant)) {
              delegant.set("matricule", obj3.delegant.matricule);
              delegant.set("matriculeaux", obj3.delegant.matriculeaux);
              delegant.set("nom", obj3.delegant.nom);
              delegant.set("prenom", obj3.delegant.prenom);
              delegant.set("utilcode", obj3.delegant.utilcode);
              delegant.set("utilnom", obj3.delegant.utilnom);
            } else {
              delegant = null;
            }
            model3.set("delegant", delegant);
            collection.add(model3);
          });
        } else { // Role Painted
          //nivel 2 (Roles)
          _.each(obj1.roles, (obj2: { [Key: string]: any }, index2: number) => {
            const model2: any = new CWBaseModel();

            model2.set("nivel", 2);
            model2.set("nivel1", obj1.code);
            model2.set("nivel2", obj2.code);
            model2.set("libelle", obj2.libelle);
            model2.set("perimetres", obj2.perimetres);
            model2.set("droit", obj1.droit);
            if (typeof (index2) === "undefined") {
              model2.set("id2", index1 + "," + null + "," + obj2.code);
            } else {
              model2.set("id2", index1 + "," + index2 + "," + obj2.code);
            }
            model2.set("id1", index1 + "," + obj1.code);
            //atributes for tree table view
            model2.isExpanded = true;
            model2.level = 2;
            model2.levelType = "role";
            model2.collapsible = true;
            model2.branch = index1;
            //have a children
            if (obj2.groupes && obj2.groupes.length > 0) {
              model2.hasChild = true;
            } else {
              model2.hasChild = false;
            }
            collection.add(model2);
            //nivel 3 (groups)
            _.each(obj2.groupes, (obj3: { [key: string]: any }, index3: number) => {
              const model3: any = new CWBaseModel();
              let delegant: any = new CWBaseModel;

              model3.set("nivel", 3);
              model3.set("nivel1", obj1.code);
              model3.set("nivel2", obj2.code);
              model3.set("code", obj3.code);
              model3.set("libelle", obj3.libelle);
              model3.set("datedeb", obj3.datedeb);
              model3.set("datefin", obj3.datefin);
              model3.set("perimetre", obj3.perimetre);
              model3.set("delegue", obj3.delegue);
              model3.set("parent", obj3.parent);
              model3.set("droit", obj3.droit);
              model3.set("id", index1 + "," + index2 + "," + index3 + "," + obj3.code);
              model3.set("id1", index1 + "," + obj1.code);
              if (typeof (index2) === "undefined") {
                model3.set("id2", index1 + "," + null + "," + obj2.code);
              } else {
                model3.set("id2", index1 + "," + index2 + "," + obj2.code);
              }
              //atributes for tree table view
              model3.isExpanded = true;
              model3.level = 3;
              model3.collapsible = true;
              model3.branch = index1;
              //have a children
              model3.hasChild = undefined;
              //delegant
              if (!_.isNull(obj3.delegant)) {
                delegant.set("matricule", obj3.delegant.matricule);
                delegant.set("matriculeaux", obj3.delegant.matriculeaux);
                delegant.set("nom", obj3.delegant.nom);
                delegant.set("prenom", obj3.delegant.prenom);
                delegant.set("utilcode", obj3.delegant.utilcode);
                delegant.set("utilnom", obj3.delegant.utilnom);
              } else {
                delegant = null;
              }
              model3.set("delegant", delegant);
              collection.add(model3);
            });
          });
        }
      });
    }
    return collection;
  }
}
