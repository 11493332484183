import * as Backbone from 'Backbone';
import tableauServiceCollDialogPersoSavecriteriaTPL from '../dialog_perso_savePlanningCriteria.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSauveGarderPreferenceModel as SauveGarderTabServicePreference } from '../models/cwSauveGarderTabServiceCollPreference.model';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { UTILS } from 'utils/utils.js';



//Dialog for personalisation criteria (EVO 518)

export class CWPersoSaveCriteriaDialog extends CWBaseFormView {

  perscriu: number;
  perscrig: number;
  workflow: any;
  parent: any;
  uc: string;
  options: string;
  level: string;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = {
      "click .options .bloq_user": "_checkButtonDelete",
      "click .options .bloq_allusers": "_checkButtonDelete",
      "change .options .bloq_user": "_changeCritere",
      "change .options .bloq_allusers": "_changeCritere",
    };

    super(options);
    this.template = tableauServiceCollDialogPersoSavecriteriaTPL;

    this.perscriu = 1;
    this.perscrig = 0;

    if (options.workflow) {
      this.workflow = options.workflow;
    }

    if (options.uc) {
      this.uc = options.uc;
    }

    if (options.parent) {
      this.parent = options.parent;
      //set the title to the dialog
      this.parent._setTitle(i18n.t('plans.tableauService.dialog_persosavecriteria_title'));
    }

    this.options = "noperso";
    this.listenTo(this, "_saveCriteria", this._saveCriteria);
    this.listenTo(this, "_cancel", this._cancel);

  }

  _changeCritere(event: JQueryEventObject): void {

    const target = event.currentTarget;
    const buttonId = target.className.split(" ")[0];
    let isDisabled = true;

    // Le traitement attendu est similaire à celui effectué pour l’option « Enregistrer les éléments courants »
    // des « critères avancés », sans tenir compte du critère courant, enregistrement de tous les éléments
    // courants du planning (population, période, présentation des collaborateurs, etc…)

    this.options = "genericData"; // save only generic data.

    if (buttonId === "bloq_user") {
      this.$el.find('.aff_allusers').removeAttr('checked');
      this.$el.find('.aff_user').attr('checked', 'true');
      this.level = "privateUser";
    } else {
      this.$el.find('.aff_user').removeAttr('checked');
      this.$el.find('.aff_allusers').attr('checked', 'true');
      this.level = "allUsers";
    }

    if (this.$el.find(".aff_user").is(":checked") ||
      this.$el.find(".aff_allusers").is(":checked")) {
      isDisabled = false;
    }
    this.$el.parent().find(".cwDialog-buttons .bt-col-blue").prop({
      "disabled": isDisabled
    });
  }

  _saveCriteria(): void {
    let persoPlanning: any = null;
    let context: any = {};

    // Le traitement attendu est similaire à celui effectué pour l’option « Enregistrer
    // les éléments courants » des « critères avancés », sans tenir compte du critère courant,
    // enregistrement de tous les éléments courants du planning (population, période, présentation des collaborateurs, etc…)

    //  TraitPlc_perscri et TraitPlc_nivcritperso

    if (this.$el.find(".bloq_allusers input").is(":not(:checked)") && this.$el.find(".bloq_user input").is(":checked")) {
      this.level = "privateUser";
    } else if (this.$el.find(".bloq_allusers input").is(":checked") && this.$el.find(".bloq_user input").is(":not(:checked)")) {
      this.level = "allUsers";
    }

    switch (this.level) {
      case "privateUser": // « U » : personnalisation au niveau « Utilisateur »
        //for user in private mode
        this.perscriu = 1;
        break;
      case "connectedUser": // « U » : personnalisation au niveau « Utilisateur »
        // to the connected user
        this.perscriu = 1;
        break;
      case "allUsers": // « G » : personnalisation au niveau « Général »
        // for all users (public)
        this.perscriu = 0;
        this.perscrig = 1;
        break;
    }

    // new data checkboxes 518

    // perscri - c'est le checkbox "Avec les options de personnalisation"

    // si TraitPlc_nivcritperso=U && TraitPlc_perscri=1 : alors perscriu=1 et perscrig=0
    // si TraitPlc_nivcritperso=G && TraitPlc_perscri=1 : alors perscriu=0 et perscrig=1
    // si TraitPlc_perscri=0 : perscriu=0 et perscrig=0

    // auto save data with the new WS.
    persoPlanning = new SauveGarderTabServicePreference();
    context = {
      onglet: "tabservcoll",
      foncCour: "COL_TSCOL",
      natGest: ""
    }
    // cloned preferences
    persoPlanning.set("preferences", this.workflow.preferences.get("preferences").clone());

    persoPlanning.set("perscriu", this.perscriu);
    persoPlanning.set("perscrig", this.perscrig);

    persoPlanning.setHabContext(new CWHabilitationContext(context));

    if (!persoPlanning.isValid()) {
      this._showValidationErrors(persoPlanning, persoPlanning.validationError);
      // add validation errors.
      LOG.debug("preferences are not valid..")

    } else {

      this._cleanValidationErrors();

      persoPlanning.url = Configuration.restRoot + "/rest/planning/tabservicecoll/preference";

      persoPlanning.unset("defaut");
      persoPlanning.unset("poptype");
      persoPlanning.unset("poplib");
      persoPlanning.unset("popid");
      persoPlanning.unset("population");
      persoPlanning.unset("profils");
      persoPlanning.unset("ident");

      UTILS.showUsecaseHidder("tabservcoll");

      persoPlanning.save(null, {
        success: (fresh: any) => {
          LOG.debug("save preferences");

          this.workflow.preferences.set("perscriu", fresh.get("perscriu"));
          this.workflow.preferences.set("perscrig", fresh.get("perscrig"));
          if (this.workflow.prefUtilisateurExiste) {
            this.workflow.prefUtilisateurExiste.notFecth = false;
          }
          UTILS.hideUsecaseHidder("tabservcoll");
        },
        error: () => {
          UTILS.hideUsecaseHidder("tabservcoll");
        }
      });
    }
    this.parent.close();
  }

  _checkButtonDelete(): void {
    let isDisabled = true;

    if (this.$el.find(".bloq_user input").is(":checked") ||
      this.$el.find(".bloq_allusers input").is(":checked")) {
      isDisabled = false;
    }
    this.$el.find(".dialogPersoSaveCriteria .btnSave").button({
      disabled: isDisabled
    });
  }

  render(): CWPersoSaveCriteriaDialog {
    const json = { i18n: i18n };

    this.$el.html(this.template(json));
    this.$el.find("button").button();
    // ::::::::::: load RADIO BUTTON VALUES for EVO 518 :::::::    
    if (this.workflow.preferences.get("perscrig").toString() === "0" && this.workflow.preferences.get("perscriu") === 1) {
      this.$el.find('.aff_user').attr('checked', 'true');
      this.$el.find('.aff_allusers').removeAttr('checked');
    } else if (this.workflow.preferences.get("perscrig") === 1 && this.workflow.preferences.get("perscriu").toString() === "0") {
      this.$el.find('.aff_user').removeAttr('checked');
      this.$el.find('.aff_allusers').attr('checked', 'true');
    } else if (this.workflow.preferences.get("perscrig") === 1 && this.workflow.preferences.get("perscriu") === 1) {
      this.$el.find('.aff_user').removeAttr('checked');
      this.$el.find('.aff_allusers').attr('checked', 'true');
    }
    // by default first checkbox checked.
    else if (this.workflow.preferences.get("perscrig").toString() === "0" && this.workflow.preferences.get("perscriu").toString() === "0") {
      this.$el.find('.aff_user').attr('checked', 'true');
      this.$el.find('.aff_allusers').removeAttr('checked');
    }
    return this;
  }

  _cancel(): void {
    this.parent.close();
  }
}
