module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="phx-tabs-'+
((__t=(id))==null?'':__t)+
'" class="phx-tabs-container notranslate">\n    <ul>\n        <li aria-controls="home'+
((__t=(id))==null?'':__t)+
'">\n            <div tabindex="0">\n                <a href="#home'+
((__t=(id))==null?'':__t)+
'" class="cw-ui-tabs-home">\n                    '+
((__t=(nom))==null?'':__t)+
'\n                </a>\n                <span class="cw-tab-plein-ecran">\n                </span>\n            </div>\n        </li>\n    </ul>\n    <div id="home'+
((__t=(id))==null?'':__t)+
'" class="phx-proto-resizable phx-simple-panel">\n        <div class="phx-details-header-text">\n            '+
((__t=(nom))==null?'':__t)+
' Accueil</div>\n    </div>\n</div>';
}
return __p;
};
