import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { GLOBAL_DATA } from 'src/globalData';
import { Model } from 'Backbone';

export class CWGererpiecesjointesWorkflow extends CWReadOnlyModel {

  gererPiecesJointes: { [key: string]: any };
  pDiversIdCollabModel: Model;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.gererPiecesJointes = null;
  }

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp(callback?: () => void): void {
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.gererPiecesJointes.diver = this.pDiversIdCollabModel.get("valeur");

    if (callback) {
      callback();
    }
  }
}
