import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model of the data for presences
 */
export class CWPresencesModel extends CWBaseModel {
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  /**
 * Default attribute values of the model
 */
  defaults(): { [key: string]: any } {
    return {
      "date": null,
      "repos": null
    }
  }

  /**
   * This method is executed when we receive a response after a successfull call to a webservice
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.date !== undefined) {
      response.id = response.date;
    }
    return response;
  }
}
