import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model of SearchListeGroupes
 */
export class CWSearchListeGroupesModel extends CWBaseModel {

  parse(response: { [key: string]: any }): { [key: string]: any } {
    const groupe: { [key: string]: any } = {};
    groupe.code = response.groupe;
    groupe.libelle = response.libelle;
    return groupe;
  }
}