import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWDialogView } from '../cwDialog.view';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils/utils.js';

export class CWVoletView<TViewRendered extends Backbone.View = Backbone.View> extends CWDialogView<TViewRendered> {
  refreshParent: boolean;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.dialogClass = options.dialogClass || "cw-volet cw-style";
    /* The options below are not modifiable */
    options.position = { my: "right bottom", at: "right bottom", of: window };
    options.resizable = false;
    options.draggable = false;
    options.modal = true;
    super(options);
    this.annuled = false;
    this.internalView = null;
    this.refreshParent = true;
  }

  render(): any {
    this.dialog = $(this.el).dialog({
      modal: this.modal,
      height: this.height,
      width: "25%",
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      position: this.position,
      appendTo: this.appendTo as any,
      closeText: i18n.t('common:close'),
      dialogClass: this.dialogClass,
      create: () => {
        const $parent = this.$el.parent();
        // Fix jQuery UI issue. When width is auto, minWidth is not applied.
        if (!CWSTR.isBlank(this.minWidth) && this.width === "auto") {
          $parent.css("minWidth", this.minWidth);
        }
        $parent.css("display", "flex");
        $parent.removeClass("cw-volet").attr("class", "cw-volet " + $parent.attr("class"));
        if ($parent.find(".ui-dialog-titlebar")) {
          $parent.find(".ui-dialog-titlebar").attr("class", "cw-volet " + $parent.find(".ui-dialog-titlebar").attr("class"));
        }
        if ($parent.find(".ui-dialog-title").length > 0) {
          $parent.find(".ui-dialog-title").attr("class", "cw-volet cw-titre " + $parent.find(".ui-dialog-title").attr("class"));
        }
        if ($parent.find(".ui-dialog-titlebar .ui-button").length > 0) {
          $parent.find(".ui-dialog-titlebar .ui-button").remove();
        }
        if ($parent.find(".ui-dialog-titlebar").length > 0) {
          $parent.find(".ui-dialog-titlebar").append(UTILS.getSVGIcon("croix-epaisse", "cw-volet__close ui-dialog-titlebar-close", 20, i18n.t('common:close')));
          $parent.find(".ui-dialog-titlebar .cw-volet__close.ui-dialog-titlebar-close").on("click", this._closeVolet.bind(this));
          $parent.find(".ui-dialog-titlebar .cw-volet__close.ui-dialog-titlebar-close").on("keyup", this._notifyEdition.bind(this));
          $parent.find(".ui-dialog-titlebar .cw-volet__close.ui-dialog-titlebar-close svg").attr("tabindex", "0")
        }
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");

        // Update title manually on creation: 
        // this way if the title has HTML it's properly parsed.
        $parent.find(".ui-dialog-title").html(this.title);
      },
      resizable: this.resizable,
      draggable: this.draggable
    });
    if (!CWSTR.isBlank(this.appendTo) && this.draggable) {
      this.dialog.dialog("widget").draggable("option", "containment", this.appendTo);
    }
    return this;
  }

  public updateTitle(titleContent: string): void {
    CWDialogView.prototype._setTitle.call(this, titleContent);
    const title = this.$el.parent().find(".ui-dialog-title").get(0);
    this._checkTitleOverflow(title);
  }

  _updateTitleTooltip(title: HTMLElement): void {
    $(title).attr("title", $(title).text());
  }

  _checkTitleOverflow(title: HTMLElement): void {
    if (title) {
      if (title.offsetWidth < title.scrollWidth) {
        this._updateTitleTooltip(title);
      } else {
        $(title).removeAttr("title");
      }
    }
  }

  _closeVolet(event: JQuery.TriggeredEvent, ui: any): void {
    this.refreshParent = false;
    this.model.trigger("dlg:close", event, ui);
  }

  _handleEvents(event: JQuery.TriggeredEvent, ui: any): void {
    CWDialogView.prototype._handleEvents.call(this, event, ui);
    if (event.type === "dialogclose") {
      const lOptions = {
        "refresh": _.isBoolean(this.refreshParent) ? this.refreshParent : false,
        "typeEvenement": ""
      };

      if (this.viewData && this.viewData.context && !CWSTR.isBlank(this.viewData.context.ctxTypeEvenement)) {
        lOptions.typeEvenement = this.viewData.context.ctxTypeEvenement;
      }
      this.model.trigger("volet:close", lOptions);
    }
  }

  open(callback?: (argument?: any) => any): void {
    const $parent = this.$el.parent(".cw-volet.ui-dialog");
    const title = $parent.find(".ui-dialog-title").get(0);

    this.refreshParent = true;
    CWDialogView.prototype.open.call(this, callback);

    if (!$parent.hasClass("cw-volet__reposition")) {
      this.$el.parent(".cw-volet.ui-dialog").addClass("cw-volet__reposition");
    }
    this._checkTitleOverflow(title);
  }

  public _notifyEdition(event: JQuery.TriggeredEvent, ui: JQuery.UIEventBase): void {
    if (event.type === "keyup") {
      const key = event.which || event.keyCode;

      if (key === 10 || key === 13 || key === 32) {
        this._closeVolet(event, ui);
      }
    }
  }
}
