import { CWBaseModel } from 'core/models/cwBase.model';


/**
 * Model pour obtenir la valeur de Type de Gestion du collaborateur
 */
export class CWTypeGestionModel extends CWBaseModel {

  collab: any;
  date: any;

  defaults(): { [key: string]: any } {
    return {
      "typegest": ""
    }
  }

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    super(options);
    if (options && options.collab) {
      this.collab = options.collab;
    }
    if (options && options.date) {
      this.date = options.date;
    }
    this.url = (): string => {
      return Configuration.restRoot + "/rest/ficheperso/" + encodeURIComponent(this.collab) + "/typegest/" + encodeURIComponent(this.date);
    }
  }
}
