import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

export class CWFractionJourModel extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "app";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/fractionj";
    }
  }

  parse(response: any): any {
    const formatReponse: { [key: string]: any } = {};

    if (response) {
      response.forEach((element: any) => {
        if (!formatReponse[element.code]) {
          formatReponse[element.code] = {
            "typegest": element.typegest,
            "code": element.code,
            "libelle": element.libelle,
            "fractions": [{
              "abrege": element.abrege,
              "code": element.code_frac,
              "ordre": element.ordre,
              "carhorj": element.carhorj,
              "libelle": element.libelle_frac,
              "heuredeb": element.hdeb,
              "heurefin": element.hfin,
            }]
          }
        } else {
          formatReponse[element.code].fractions.push({
            "abrege": element.abrege,
            "code": element.code_frac,
            "ordre": element.ordre,
            "carhorj": element.carhorj,
            "libelle": element.libelle_frac,
            "heuredeb": element.hdeb,
            "heurefin": element.hfin,
          })
        }
      });
    }
    return formatReponse;
  }
}
