import { BaseModel } from 'core/models/base.model.js';
import { i18n } from 'src/i18n.js';
import { STR } from 'utils/str.js';
import { TYPE } from 'tda/tda.js';

/**
 * Model for loading the table hierarchies at carac princ table
 */
export var CaracPrincStructModel = BaseModel.extend({
  /**
   * Add this to have Outline
   *
   */
  dymmy: "",

  initialize: function() {
    this.groupedErrors = {
      debstra: ["debstra", "finstra"],
      finstra: ["debstra", "finstra"]
    };
  },

  defaults: {
    "debstra": "",
    "finstra": "",
    "structida": "",
    "itemida": ""
  },

  validate: function(attrs, options) {
    var errors = {};
    var datedeb = { //Period validation
      "name": "debstra",
      "value": attrs.debstra,
      "label": i18n.t('activite.error_debutil')
    };
    var datefin = {
      "name": "finstra",
      "value": attrs.finstra,
      "label": i18n.t('activite.error_finutil'),
      "isInfinity": true
    };
    var errorMsg = TYPE.DATE.validatePeriod(datedeb, datefin, options);

    if (!STR.isBlank(errorMsg)) {
      errors.debstra = errorMsg.datedeb;
      errors.finstra = errorMsg.datefin;
    }

    if (STR.isBlank(attrs.itemida)) {
      errors.itemida = i18n.t('common:required', { "0": i18n.t('activite.itemida') });
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  },

  parse: function(response) {
    if (response) {
      if (!STR.isBlank(response.itemida)) {
        response.id = response.itemida;
        if (!STR.isBlank(response.debstra)) {
          response.id = response.id + "," + response.debstra;
          if (!STR.isBlank(response.finstra)) {
            response.id = response.id + "," + response.finstra;
          }
        }
      }
      if (response.result) {
        delete response.result;
      }
    }
    return response;
  }

});
