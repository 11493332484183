import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCalendaccFooter from '../cwFooter.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { i18n } from 'src/i18n.js';

/**
 * Footer View
 */
export class CWFooterView extends Backbone.View {

  workflow: any;

  constructor(options: { [key: string]: any }) {
    super(options);
    this.model = new CWBaseModel();

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.template = TPLCalendaccFooter;
    this.workflow.formModel.on("change", this.manageZones, this);
  }


  render(): CWFooterView {
    const json = { 'i18n': i18n };

    this.$el.html(this.template(json));
    this.manageZones();
    return this;
  }

  manageZones(): void {
    const zones = this.workflow.formModel.get("zones").models;

    //paint the zones in legend
    //Dynamic creation of zones
    this.$el.find(".calendacc-footer .zoneSc").empty();
    _.each(zones, (zone: any) => {
      const footerDiv = $('<div class="color-container" style="display: inline-block;"></div>');
      const divColor = $('<div class="color"></div>');
      const labelCode = $('<div class="colorText cw-texteAllege"></div>');
      const rgb = zone.get('colorRGB');

      divColor.css({ 'background-color': rgb, 'width': 'auto', 'height': 'auto', 'display': 'inline-block', 'margin-right': '12px' });
      labelCode.html(zone.get('code'));
      divColor.append(labelCode)
      footerDiv.append(divColor);
      this.$el.find(".calendacc-footer .zoneSc").append(footerDiv);
    });
  }
}
