import { BaseCollection } from 'core/models/base.collection.js';
import { NivModel } from './niv.model.js';

export var NivColl = BaseCollection.extend({
  /**
   * Add this to have Outline
   *
   */

  model: NivModel,

  /**
   * Constructor
   * Selecteur Activites Niveau Collection
   */
  initialize: function(params) {
    this.code = "";
    if (params && params.code) {
      this.code = params.code;
    }
  },

  /**
   * Url for this collection
   */
  url: function() {
    var urlFin = Configuration.restRoot + "/rest/structorga/" + this.code + "/niveau";
    return urlFin;
  }
});
