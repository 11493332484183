import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWFORMS } from 'utils/cwForms';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from './cwHabilitationContext';
import { CWHEADERS } from 'utils/cwHeaders';
import { CWSTR } from 'utils/cwStr';
import { ModelFetchOptions, ModelSaveOptions } from 'Backbone';
import { objs } from 'src/objectsRepository';

interface chronotimeModelFetchOptions extends ModelFetchOptions {
  type?: "GET" | "POST" | "PUT" | "DELETE";
}

interface chronotimeModelSaveOptions extends ModelSaveOptions {
  type?: "GET" | "POST" | "PUT" | "DELETE";
  //If the save need some other property, add here
}

/**
 * BaseModel : base model to use instead of the backbone model
 *
 * - management of habilitations
 */
export class CWBaseModel extends Backbone.Model {

  public version: string | { [key: string]: any };
  public activeSimulation: boolean;
  public habContext: CWHabilitationContext;
  public usePopulation: boolean;
  public habilitationV: string;
  public habilitationG: string;
  public usecase: string;
  public popId: string | number;
  public popType: string;
  public popCode: string | number;
  public popNat: string;
  public popDesc: string;
  public simulationData: { [key: string]: any };
  public ecran: string;
  public canViewTreatment: boolean;
  public oldAttributes: string;
  private _params: { [key: string]: any };
  public context: { [key: string]: any };
  public groupedErrors: { [key: string]: any };
  public infoCompAttributeName: string;
  public ctxVueJourneeDate: string;
  public blacklist: string[];
  public action: string;


  /**
   * Checks habilitations and if they are correct, fetches the model
   *  responds fetch without errors
   */

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.blacklist = [];
  }

  fetch(options?: chronotimeModelFetchOptions): JQueryXHR {
    const localOptions = options ? _.clone(options) : {};
    let urlParams: { [key: string]: any } = null;

    this.validationError = null;//initialisation 
    if (!CWSTR.isBlank(this.version)) {
      const currentVersion = typeof this.version === "object" ? this.version.GET : this.version;

      this.setHeaders(localOptions, CWHEADERS.versionContext(currentVersion));
    }
    urlParams = this._prepareUrlParams();
    if (!localOptions.type || localOptions.type === "GET") {
      localOptions.data = urlParams;
    }
    if (this.activeSimulation === true) {
      this.prepareSimulation(localOptions);
    }
    this._prepareEcranHeader(localOptions);
    if (this.habContext) {
      const oldFoncCour = (typeof this.habContext.get("foncCour") === "string" ? this.habContext.get("foncCour") : _.clone(this.habContext.get("foncCour")));

      this.habContext.verifierFoncCour();
      this.setHeaders(localOptions, this.habContext.header());
      if (this.usePopulation === true) {
        this.preparePopulation(localOptions);
      }
      //retablir la valeur de foncCour après la verification et la préparation de "header"
      this.updateHabContext({ "foncCour": oldFoncCour });
      return Backbone.Model.prototype.fetch.call(this, localOptions);
    } else {
      this._checkHabilitations();
      if (this.habilitationV === "N" || CWHABILITATION.canView(this.habilitationV)) {
        if (this.usePopulation === true) {
          this.preparePopulationAndHabiliation(localOptions);
        } else {
          this.setHeaders(localOptions, CWHEADERS.habilitationContext(this.usecase, this.habilitationV));
        }
        return Backbone.Model.prototype.fetch.call(this, localOptions);
      } else {
        if (Configuration.development === true && CWSTR.isBlank(this.habilitationG)) {
          throw new Error("The habilitation is not properly configured");
        }
        if (localOptions.success) {
          localOptions.success(this, null, localOptions);
        }
      }
    }
    return ($.Deferred() as any).resolve();
  }

  /**
   * Includes population Ident and Type in header
   */
  preparePopulationAndHabiliation(options: { [key: string]: any }): void {
    let popId = null,
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;

    if (!CWSTR.isBlank(this.popId) && !CWSTR.isBlank(this.popType)) {
      popId = this.popId;
      popType = this.popType;
      popCode = this.popCode;
      popNat = this.popNat;
      popDesc = this.popDesc;
    } else if (!CWSTR.isBlank(objs.populationMenu) && !CWSTR.isBlank(objs.populationMenu.model)) {
      popId = CWSTR.isBlank(objs.populationMenu.model.get("ident")) ? "0" : objs.populationMenu.model.get("ident");
      popType = objs.populationMenu.model.get("type");
      popCode = objs.populationMenu.model.get("code");
      popNat = objs.populationMenu.model.get("nature");
      popDesc = objs.populationMenu.model.get("desc");
    }
    this.setHeaders(options, CWHEADERS.habilitationContext(this.usecase, this.habilitationV));
    if (CWSTR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (CWSTR.isBlank(popNat)) {
      popNat = "";
    }
    if (CWSTR.isBlank(popCode)) {
      popCode = "";
    }
    if (CWSTR.isBlank(popDesc)) {
      popDesc = "";
    }
    if (CWSTR.isBlank(popType)) {
      popType = "D";
    } else if (popType === "M") {
      popType = "D";
    }
    this.setHeaders(options, CWHEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  }

  /**
   * Includes population Ident and Type in header
   */
  preparePopulation(options: { [key: string]: any }): void {
    let popId = null,
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;

    if (!CWSTR.isBlank(this.popId) && !CWSTR.isBlank(this.popType)) {
      popId = this.popId;
      popType = this.popType;
      popCode = this.popCode;
      popNat = this.popNat;
      popDesc = this.popDesc;
    } else if (!CWSTR.isBlank(objs.populationMenu) && !CWSTR.isBlank(objs.populationMenu.model)) {
      popId = objs.populationMenu.model.get("ident");
      popType = objs.populationMenu.model.get("type");
      popCode = objs.populationMenu.model.get("code");
      popNat = objs.populationMenu.model.get("nature");
      popDesc = objs.populationMenu.model.get("desc");
    }
    if (CWSTR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (CWSTR.isBlank(popNat)) {
      popNat = "";
    } else if (popNat === "M") {
      popNat = "C";
    }
    if (CWSTR.isBlank(popCode)) {
      popCode = "";
    }
    if (CWSTR.isBlank(popDesc)) {
      popDesc = "";
    }
    // Type must be S or D
    if (CWSTR.isBlank(popType)) {
      popType = "D";
    } else if (popType === "M") {
      popType = "D";
    }
    this.setHeaders(options, CWHEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  }

  prepareSimulation(options: { [key: string]: any }): void {
    const sim = !CWSTR.isBlank(this.simulationData) ? this.simulationData : objs.simulationModel;

    if (!CWSTR.isBlank(sim)) {
      const datedeb = sim.get("datedeb");
      let passreel = false;
      let vueJourneeDate = "";

      if (objs.ctxAffReelPasse === true) {
        passreel = true
      }
      if (!CWSTR.isBlank(this.ctxVueJourneeDate)) {
        vueJourneeDate = this.ctxVueJourneeDate;
      }
      this.setHeaders(options, CWHEADERS.simulationContext(sim.get("code"), datedeb, sim.get("datefin"), passreel, vueJourneeDate));
    }
  }

  /**
   * Checks habilitations and if they are correct, executes standard Backbone.model Save
   *  responds save without errors
   *  responds save with an error
   */
  save(key?: any, val?: chronotimeModelSaveOptions, options?: any): any {
    let isValOptions = false;

    if (CWSTR.isBlank(key) || typeof key === 'object') {
      options = val;
      isValOptions = true;
    }
    options = options ? _.clone(options) : {};

    if (!_.isEmpty(this.blacklist)) {
      this._filterAttributesInBlackList(isValOptions ? val : options);
    }

    if (!CWSTR.isBlank(this.version)) {
      let currentVersion = this.version;

      if (typeof this.version === "object") {
        if (this.isNew()) {
          currentVersion = this.version.POST;
        } else {
          currentVersion = this.version.PUT;
        }
      }
      const header = CWHEADERS.versionContext(currentVersion);

      if (isValOptions === true) {
        this.setHeaders(val, header);
      } else {
        this.setHeaders(options, header);
      }
    }
    if (this.habContext) {
      let header: { [key: string]: any } = null;
      const oldFoncCour = (typeof this.habContext.get("foncCour") === "string" ? this.habContext.get("foncCour") : _.clone(this.habContext.get("foncCour")));

      this.habContext.verifierFoncCour((this.isNew() ? "add" : "update"));
      if (isValOptions === true) {
        this._prepareEcranHeader(val);
        this._prepareOptionsErrors(val);
      } else {
        this._prepareEcranHeader(options);
        this._prepareOptionsErrors(options);
      }
      header = this.habContext.header();
      if (isValOptions === true) {
        this.setHeaders(val, header);
      } else {
        this.setHeaders(options, header);
      }
      if (isValOptions === true && this.usePopulation === true) {
        this.preparePopulation(val);
      } else if (this.usePopulation === true) {
        this.preparePopulation(options);
      }
      if (isValOptions === true && this.activeSimulation === true) {
        this.prepareSimulation(val);
      } else if (this.activeSimulation === true) {
        this.prepareSimulation(options);
      }
      //retablir la valeur de foncCour après la verification et la préparation de "header"
      this.updateHabContext({ "foncCour": oldFoncCour });
      return Backbone.Model.prototype.save.call(this, key, val, options);
    } else {
      this._checkHabilitations();
      if (isValOptions === true) {
        this._prepareEcranHeader(val);
        this._prepareOptionsErrors(val);
      } else {
        this._prepareEcranHeader(options);
        this._prepareOptionsErrors(options);
      }
      if (isValOptions === true && this.usePopulation === true) {
        this.preparePopulation(val);
      } else if (this.usePopulation === true) {
        this.preparePopulation(options);
      }
      if (isValOptions === true && this.activeSimulation === true) {
        this.prepareSimulation(val);
      } else if (this.activeSimulation === true) {
        this.prepareSimulation(options);
      }
      if (this.habilitationG === "N" || (this.canViewTreatment === true && CWHABILITATION.canView(this.habilitationG))) {
        const header = CWHEADERS.habilitationContext(this.usecase, this.habilitationG, "");

        if (isValOptions === true) {
          this.setHeaders(val, header);
        } else {
          this.setHeaders(options, header);
        }
        Backbone.Model.prototype.save.call(this, key, val, options);
      } else if (this.isNew() && (CWHABILITATION.canCreate(this.habilitationG))) {
        const header = CWHEADERS.habilitationContext(this.usecase, this.habilitationG, "A");

        if (isValOptions === true) {
          this.setHeaders(val, header);
        } else {
          this.setHeaders(options, header);
        }
        Backbone.Model.prototype.save.call(this, key, val, options);
      } else if (!this.isNew() && (CWHABILITATION.canUpdate(this.habilitationG))) {
        const header = CWHEADERS.habilitationContext(this.usecase, this.habilitationG, "M");

        if (isValOptions === true) {
          this.setHeaders(val, header);
        } else {
          this.setHeaders(options, header);
        }
        return Backbone.Model.prototype.save.call(this, key, val, options);
      } else {
        if (Configuration.development === true && CWSTR.isBlank(this.habilitationG)) {
          throw new Error("The habilitation is not properly configured");
        }
        if (CWSTR.isBlank(key) || typeof key === 'object') {
          if (val.error) {
            val.error(this, null, val);
          }
        } else {
          if (options.error) {
            options.error(this, null, options);
          }
        }
      }
    }
    return null;
  }

  /**
   * Checks habilitations and if they are correct, executes standard Backbone.model Destroy
   *  responds destroy without errors
   *  responds destroy with an error
   */
  destroy(options?: { [key: string]: any }): void {
    options = options ? _.clone(options) : {};
    if (!CWSTR.isBlank(this.version)) {
      const currentVersion = typeof this.version === "object" ? this.version.DELETE : this.version;

      this.setHeaders(options, CWHEADERS.versionContext(currentVersion));
    }
    if (this.activeSimulation === true) {
      this.prepareSimulation(options);
    }
    if (this.habContext) {
      const oldFoncCour = (typeof this.habContext.get("foncCour") === "string" ? this.habContext.get("foncCour") : _.clone(this.habContext.get("foncCour")));

      this.habContext.verifierFoncCour("delete");
      this.setHeaders(options, this.habContext.header());
      //retablir la valeur de foncCour après la verification et la préparation de "header"
      this.updateHabContext({ "foncCour": oldFoncCour });
      Backbone.Model.prototype.destroy.call(this, options);
    } else {
      this._checkHabilitations();
      this._prepareEcranHeader(options);
      if (this.habilitationG === "N" || (this.canViewTreatment === true && CWHABILITATION.canView(this.habilitationG))) {
        this.setHeaders(options, CWHEADERS.habilitationContext(this.usecase, this.habilitationG, ""));
        Backbone.Model.prototype.destroy.call(this, options);
      } else if (CWHABILITATION.canDelete(this.habilitationG) || this.isNew()) {
        this.setHeaders(options, CWHEADERS.habilitationContext(this.usecase, this.habilitationG, "S"));
        Backbone.Model.prototype.destroy.call(this, options);
      } else {
        if (Configuration.development === true && CWSTR.isBlank(this.habilitationG)) {
          throw new Error("The habilitation is not properly configured");
        }
        if (options.error) {
          options.error(this, null, options);
        }
      }
    }
  }

  /**
   * Checks that habilitationV, habilitationG and usecase are informed.If they are not an error is shown
   */
  _checkHabilitations(): void {
    if (CWSTR.isBlank(this.habilitationV)) {
      throw new Error("View Habilitation must be defined or set to empty string if not used.");
    }
    if (CWSTR.isBlank(this.habilitationG)) {
      throw new Error("Management Habilitation must be defined or set to empty string if not used.");
    }
    if (CWSTR.isBlank(this.usecase)) {
      throw new Error("Usecase name must be defined.");
    }
  }

  /**
   * Prepares model header for server calls
   */
  _prepareEcranHeader(options: { [key: string]: any }): void {
    if (!CWSTR.isBlank(this.ecran)) {
      this.setHeaders(options, CWHEADERS.ecranContext(this.ecran));
    }
  }

  /**
   * Adds options.header to current header
   *  responds without errors
   *  responds with an error
   */
  setHeaders(options: { [key: string]: any }, header: { [key: string]: any }): void {
    if (options.headers) {
      options.headers = _.extend(options.headers, header);
    } else {
      options.headers = header;
    }
  }

  /**
   * Copies current attributes to the property oldAttributes. This property can be accessed in the future to revert changes
   */
  store(): void {
    this.oldAttributes = _.clone(JSON.stringify(this.attributes));
  }

  /**
   * Returns a value from the last values got from the server.
   */
  getOldAttribute(attrName: string): string | { [key: string]: any } | number {
    let value = null;

    if (this.oldAttributes) {
      const oldAttr = this.parse(JSON.parse(this.oldAttributes));

      value = CWSTR.getElValueFromObj(oldAttr, attrName);
    }
    return value;
  }

  /**
   * Sets current model attributes to values that were stored (oldAttributes).
   */
  revert(): void {
    if (this.oldAttributes) {
      this.set(this.parse(JSON.parse(this.oldAttributes)));
      this.validationError = null;
    }
  }

  /**
   * Sets model's habilitations and usecase to values passed
   */
  setHabilitation(V: string, G: string, usecase: string): void {
    if (!CWSTR.isBlank(V)) {
      this.habilitationV = V;
    }
    if (!CWSTR.isBlank(G)) {
      this.habilitationG = G;
    }
    if (!CWSTR.isBlank(usecase)) {
      this.usecase = usecase;
    }
  }

  setHabContext(habContext: CWHabilitationContext): void {
    this.habContext = habContext;
  }

  setVersion(version: string | { [key: string]: any }): void {
    this.version = version;
  }

  updateHabContext(attributes: CWBaseModel | { [key: string]: any }): void {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  }

  getHabContext(): CWHabilitationContext {
    return this.habContext;
  }

  /**
   * Prepare the 3 kinds of parameter this collection understand in an unique
   * object that the REST service can understand
   */
  _prepareUrlParams(): { [key: string]: any } {
    const pWhere: { [key: string]: any } = {};// Filter parameters not empty

    _.each(this.params, function (value, key) {
      if (!CWSTR.isBlank(value)) {
        pWhere[key] = value;
      }
    });
    if (this.usePopulation === true) {
      // Join population parameter
      return _.extend(pWhere, { "filtre": true });
    }
    return pWhere;
  }

  /**
   * Function that adds a custom error management for error 406 when save.
   * Preserves the original error function defined on the model callback and call it
   * after the the errorBaseModel function.
   */
  _prepareOptionsErrors(options: { [key: string]: any }): void {
    let errorCallback: (model: CWBaseModel, response: XMLHttpRequest["response"], options: { [key: string]: any }) => void;
    const errorBaseModel = (model: CWBaseModel, response: XMLHttpRequest["response"], options: { [key: string]: any }): void => {
      const excludeByAction = (!CWSTR.isBlank(model.action) && (model.action === "accepter" || model.action === "refuser") ? true : false);

      if (!excludeByAction && response && response.status === 406 && !_.isEmpty(response.responseText)) {
        //If there was an error of saisi incorrect we want to show it on the edited row
        model.validationError = model.validationError || { errors: {}, errorValidation: {} };
        if (response.responseJSON && response.responseJSON.attribut) {
          const errorField = response.responseJSON.attribut;

          CWFORMS.assignValue(model.validationError.errors, errorField, response.responseJSON.message);
          model.trigger("invalid", self, model.validationError, errorField);
          response.oldStatus = response.status;
          response.oldResponseText = response.responseText;
          //Avoid the creation of the pop up
          response.status = 200;
          response.responseText = null;
        }
      }
      if (errorCallback) {
        errorCallback(model, response, options);
      }
    };

    if (options.error) {
      errorCallback = options.error;
    }
    options.error = errorBaseModel;
  }

  _filterAttributesInBlackList(options: { [key: string]: any }): void {
    options.attrs = _.omit(options.attrs ? options.attrs : this.attributes, this.blacklist);
  }

  clone(): CWBaseModel | any {
    const cloned = new (this.constructor as typeof CWBaseModel)(JSON.parse(JSON.stringify(this.toJSON())));
    const keysModel = _.keys(this.toJSON());

    cloned.setHabContext(this.getHabContext());
    for (let i = 0; i < keysModel.length; i++) {
      const keyFor = this.get(keysModel[i]);

      if (keyFor instanceof Backbone.Model) {
        if (typeof keyFor.clone === "function") {
          cloned.set(keysModel[i], keyFor.clone());
        } else {
          cloned.set(keysModel[i], new (keyFor as any).constructor(keyFor.toJSON()));
        }
      } else if (keyFor instanceof Object) {
        cloned.set(keysModel[i], _.clone(keyFor));
      }
      //autres cas sont déjà copiés et corrects ->cloned2.set(keysModel[i], keyFor);
    }
    return cloned;
  }

  public set params(nValue: { [key: string]: any }) {
    this._params = nValue;
  }

  public get params(): { [key: string]: any } {
    return this._params;
  }
}
