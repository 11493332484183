import _ from 'underscore';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWListeMotifRegulModel } from './cwListeMotif.model';


/**
 * Liste for combo Motif
 */
export class CWListeMotifRegulColl<TModel extends CWListeMotifRegulModel = CWListeMotifRegulModel> extends CWCombosColl<TModel> {

  // model: CWListeMotifRegulModel;
  moreData: boolean;

  constructor(models?: CWListeMotifRegulModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWListeMotifRegulModel;
    super(models, options);
    this.sortings = { "code": true };
    this.paginated = false;
    this.url = (): string => {

      const wsExtended = "utilregul/motif";
      const ws = "ls/motifregularisation";
      let result = Configuration.restRoot + "/rest/";
      if (this.moreData === true) {
        result += wsExtended;
      } else {
        result += ws;
      }
      return result;
    };
  }


  clone(): any {
    const temp = super.clone.call(this);
    return temp;
  }
}