import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';


/**
 * Suivi des Collaborateurs Model
 */
export class CWCollabModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "matric": null,
      "matricaux": "",
      "nom": "",
      "prenom": "",
      "statut": {
        "code": "",
        "libelle": ""
      },
      "categ": {
        "code": null,
        "libelle": ""
      },
      "itemlib": "",
      "dtrp": "",
      "nbanobloq": null,
      "dmaj": "",
      "danscontrat": null,
      "user": null,
      "type_collab": {
        "code": null,
        "libelle": "",
        "code_aff": null,
        "libellecourt": ""
      }
    }
  }

  /**
   * Checks habilitations and if they are correct, fetches the model
   *  responds fetch without errors
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.habilitationV = options.habilitationV ?? "RES_GCOLLAB.G";
    options.habilitationG = options.habilitationG ?? "RES_GCOLLAB.G";
    options.usecase = options.usecase ?? "suivicollab";
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/gestioncompte";
    }
  }
}