import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererabsencesouhaitGererabsencesouhait from '../cwGererabsencesouhait.tpl.html';
import TPLGererabsencesouhaitGererabsencesouhaitSaisiemass from '../cwGererabsencesouhait-saisiemass-mode.tpl.html';
import { CWBaseCollection } from 'core/models/cwBase.collection';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWButtonBarValidationView } from 'core/controls/cwButtonBarValidation.view';
import { CWCaracteristiquesCodifColl } from 'core/components/caracteristiques/cwCaracteristiques.codif.collection';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWComplementComboColl } from '../models/cwComplementCombo.collection';
import { CWComplementComboModel } from '../models/cwComplementCombo.model';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWEtiquette, validEtiquetteTypes } from 'core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { CWGererAbsenceSouhaitGestion } from '../models/cwGererAbsenceSouhaitGestion';
import { CWGererAbsenceSouhaitModel } from '../models/cwGererAbsenceSouhait.model';
import { CWGererabsencesouhaitWorkflow } from '../models/cwGererabsencesouhait.workflow';
import { CWGererRecapGeneralView } from 'common/evenements/gerer/gererrecapitulatifs/views/cwGererRecapGeneral.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWMAbsencesRouter } from 'mon_compte/gerer/mabsences/cwMAbsences.router';
import { CWMSGS } from 'utils/cwMsgs';
import { CWOutilAbsenceComplementModel } from '../models/cwOutilAbsenceComplement.model';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPieceJointes } from 'common/piecesjointes/views/cwPieceJointes.view';
import { CWSaisieUniteDef } from '../models/cwSaisieUniteDef';
import { CWSelectCollabPopulationView } from 'common/referentiel/referentielcollab/views/cwSelectCollabPopulation.view';
import { CWSoldesAbsencesColl } from 'common/soldesabsences/models/cwSoldesAbsences.collection';
import { CWSoldesAbsencesConfigModel } from 'common/soldesabsences/models/cwSoldesAbsencesConfig.model';
import { CWSoldesAbsencesModel } from 'common/soldesabsences/models/cwSoldesAbsences.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { CWTypeGestionModel } from '../models/cwTypeGestion.model';
import { CWWkflhistoView as WkfhistoBlockView } from 'common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { onError } from 'src/Interceptors/onError.js';
import { SIMULATION } from 'utils/simulation.js';
import { UTILS } from 'utils/utils.js';

interface CWGererAbsenceSouhaitContext {
  ctxActionsPossibles?: Array<string>; // ["Creer", "Supprimer", "Modifier", "Valider", "Transformer"]
  ctxEcran?: string;
  ctxGestionCollab?: { [key: string]: any };
  ctxGestionCollective?: boolean;
  ctxHabilitation?: {
    HabilitationAcces?: string;
    HabilitationGestion?: string;
    HabilitationValidation?: string;
  };
  ctxHistoriqueWKF?: boolean;
  ctxIdentifiantEvt?: string;
  ctxModeInitialisation?: string;
  ctxModeRepresentation?: string; // "Imbrique" || "pop-up"
  ctxPieceJointe?: boolean;
  ctxSaisieMasse?: boolean;
  ctxUtilisateur?: string; // "Collaborateur" || "Responsable"
  ctxValeursCreation?: { [key: string]: any };
  ctxValeursEvenement?: { [key: string]: any };
  ctxPourPlanningMedical?: boolean;
  ctxSimulation?: boolean;
  ctxEvtEstSimule?: {
    valeur: number;
    libelle: string;
  };
  ctxDonneeSimulee?: boolean;
  ctxTypeEvenement?: string;
  ctxAppendTo?: string; //information pour le panneau de pièces jointes
  ctxRecapActivite?: boolean;
}

/**
 * Render gerer absencese and souhait view
 */
export class CWGererAbsenceSouhaitView extends CWBaseFormView {

  private isReady: boolean;
  private collabBackup: any;
  private isContextInitialized: boolean;
  private referentielReadOnly: boolean; //for referentiel readonly
  private paramIdCollab: string;
  public model: CWBaseModel;
  public workflow: CWGererabsencesouhaitWorkflow;
  public btnBar: CWButtonBarValidationView;
  context: CWGererAbsenceSouhaitContext;
  private loadingUnites: boolean;
  public typeMaskByClass: { [key: string]: any };
  private hasBeenFocused: boolean;
  private contextInitialized: boolean;
  private listeMotifColl: CWBaseCollection;
  private disabled: boolean;
  private detailsAbsence: boolean;
  private showHelp: boolean;
  private parametreDiversTypeGest: string | number;
  public typeFormatByClass: { [key: string]: any };
  private dateFormat: string;
  private parent: CWDialogPopupView | CWMAbsencesRouter | CWGererRecapGeneralView;
  private typeGestionOfCollab: CWTypeGestionModel;
  private comboMotif: CWComboBoxView2;
  public comboUniteDebut: CWComboBoxView2;
  public comboUniteFin: CWComboBoxView2;
  private comboOrigine: CWComboBoxView2;
  private cache: { [key: string]: any };
  private origineContext: { [key: string]: any } | string;
  public referentielCollabPopu: CWSelectCollabPopulationView;
  comboMotifCalled: boolean;
  GESTION_SOUHAIT: string;
  SAISIE_HEURE: any;
  SAISIE_DUREE: any;
  original: any;
  contextComponentWkf: { ctxEcran: any; ctxHabilitation: any; ctxTypeEvenement: string; ctxRefEvenement: any; ctxSouhait: boolean; ctxRefSouhait: any; ctxTransformed: boolean; ctxDemId: any; ctxModeRepresentation: string; ctxModeDefaut: string; ctxModeRech: boolean };
  composantWorkflow: any;
  pieceJointeModified: boolean;
  pieceJointe: any;
  listeTmpMotif: any;
  absenceModified: boolean;
  mouseUpDone: boolean;
  storeAbsence: { absence: any; ctxModeInitialisation: any; ctxTypeEvenement: any; ctxHistoriqueWKF: any };
  ctxModeInitialisation: string;
  isEditable: boolean;
  etiquetteHisto1: CWEtiquette;
  private histoEtiquettePopup: CWPanneauDeroulant;
  outsideClickListener: (arg1: JQueryEventObject) => any;
  panneauIsOpen: boolean;
  COLLAB_ID: string;
  absenceDeleted: boolean;
  errorPetAntSaveDelete: boolean; //flag pour savoir si la petition dernier était en erreur ou pas
  desactiveHabOuEtat: boolean;
  private infoErrorModify: { [key: string]: any };
  private notCheckCombos: boolean;
  private isRecapActivite: boolean;
  private timer: any;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input": "_manageView",
      "keyup :input:not([readonly])": "_notifyEdition",
      "click .menuItem": "menuItemListener",
      "click div.gererabsencesouhait-containerMasquer-button": "_hideAndShowTextComp"
    }, CWTYPE.SHORT.events(),
      CWTYPE.DATE.events(),
      CWTYPE.DURATION.HOUR_MINUTE.events(),
      CWTYPE.HOUR_MINUTE_NIGHTLY.events(),
      CWTYPE.CUSTOM.events(),
      CWTYPE.LONG.events(), options.events);
    options.className = "cw-fieldset cwGererAbsenceSouhait";
    options.tagName = "fieldset";
    super(options);
    this.id = null;
    this.isReady = false;
    this.desactiveHabOuEtat = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    this.collabBackup = null;
    this.isContextInitialized = false;
    this.referentielReadOnly = false; //for referentiel readonly
    this.paramIdCollab = CWDIVERS.get("idCollab");
    this.template = TPLGererabsencesouhaitGererabsencesouhait;
    this.model = new CWBaseModel({
      value: null,
      mode: "C"
    });
    this.workflow = new CWGererabsencesouhaitWorkflow({}, { "context": this.context });
    this.workflow.formModel = this.model;
    this.btnBar = new CWButtonBarValidationView({ id: "gererabsence_btnbar", isHideOnDisabled: true });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.gererAbsenceSouhait = this;
    this.context = {};
    this.loadingUnites = false;
    if (options.context) {
      this.context = options.context;
      this.model.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces
      }));

      if (this.context.ctxSaisieMasse === true) {
        this.template = TPLGererabsencesouhaitGererabsencesouhaitSaisiemass;
      } else {
        this.context.ctxSaisieMasse = false;
      }
    }
    if (options.parent) {
      this.parent = options.parent;
      this.workflow.parent = this.parent;
    }
    if (CWSTR.isBlank(this.model.getHabContext())) {
      this.model.setHabContext(new CWHabilitationContext({
        onglet: "",
        foncCour: ""
      }));
    }
    // When the value is changed, we have to map the new value to the form
    this.model.on("form:save", this._setContextGMSituation, this);
    this.model.on("change:value", this._changeDataValue, this);
    this.model.on("change:mode", this._manageMode, this);
    this.model.on("form:edited", this._displaySubtitle, this);
    this.listenTo(this.workflow, "click:nouveau", this.showNouveauMenu);
    this.listenTo(this, "manageUnselected", this.manageModeUnselected);
    this.listenTo(this.workflow, "manageUnselected", this.manageModeUnselected);
    this.listenTo(this.workflow, "wkfObtained", this._manageCommentaire);
    if (this.context.ctxSaisieMasse) {
      this.dateFormat = "DATE";
    } else {
      this.dateFormat = "DATE_L";
    }
    this.typeMaskByClass = {};
    this.typeMaskByClass["datedeb"] = this.dateFormat;
    this.typeMaskByClass["datefin"] = this.dateFormat;
    this.hasBeenFocused = false;
    this.contextInitialized = false;
    this.listeMotifColl = null;
    this.disabled = false;
    this.detailsAbsence = false;
    this.showHelp = false;
    this.panneauIsOpen = false;
    this.parametreDiversTypeGest = CWDIVERS.get("TypeGest");
    this._initCombos();
    this.typeFormatByClass = {};
    this.errorPetAntSaveDelete = false;
    this.isRecapActivite = this.context.ctxRecapActivite ? this.context.ctxRecapActivite : false;
  }


  _getTypegestForUnites(absObject: CWGererAbsenceSouhaitModel, setUnitesCallback: (...args: any[]) => void): void {
    if (absObject && !CWSTR.isBlank(absObject.get("typegest"))) {
      setUnitesCallback(absObject.get("typegest"));
    } else {
      if (this.context.ctxGestionCollective === true) {
        setUnitesCallback(null);
      } else {
        if (!CWSTR.isBlank(this.context.ctxGestionCollab.matricule) && !CWSTR.isBlank(this._getModel().get("datedeb"))) {
          if (CWSTR.isBlank(this.typeGestionOfCollab) || this.typeGestionOfCollab.collab !== this.context.ctxGestionCollab.matricule || this.typeGestionOfCollab.date !== this._getModel().get("datedeb")) {
            this.typeGestionOfCollab = new CWTypeGestionModel({
              collab: this.context.ctxGestionCollab.matricule,
              date: this._getModel().get("datedeb")
            });
            this.typeGestionOfCollab.setHabContext(new CWHabilitationContext({
              onglet: this.context.ctxEcran,
              foncCour: this.context.ctxHabilitation.HabilitationAcces
            }));
            this.typeGestionOfCollab.fetch({
              success: (fresh: CWTypeGestionModel): void => {
                setUnitesCallback(fresh.get("typegest"));
              }
            });
          } else {
            setUnitesCallback(this.typeGestionOfCollab.get("typegest"));
          }
        } else {
          setUnitesCallback(null);
        }
      }
    }
  }

  _updateUnites(absObject: CWGererAbsenceSouhaitModel, callback?: (...args: any[]) => void): void {
    const dateError = CWTYPE.DATE.validate(this.model.get("value").get("datedeb"));
    let typeevtid = "";
    let codeabs = "";
    const setUnitesCallback = (typeGest: string): void => {
      if (this.loadingUnites === false) {
        this.loadingUnites = true;
        this.comboUniteDebut.coll.codeabs = codeabs;
        this.comboUniteDebut.coll.typegest = typeGest;
        this.comboUniteDebut.coll.typeevtid = typeevtid;
        this.comboUniteDebut.clearCache();
        this.comboUniteDebut.clearColl();
        this.comboUniteFin.coll.codeabs = codeabs;
        this.comboUniteFin.coll.typegest = typeGest;
        this.comboUniteFin.coll.typeevtid = typeevtid;
        this.comboUniteFin.clearCache();
        this.comboUniteFin.clearColl();
        this.comboUniteDebut.coll.fetch({
          success: (): void => {
            this.comboUniteFin.coll.fetch({
              success: (): void => {
                this._checkCoherenceOfDefaultUnites();
                if (callback) {
                  callback();
                }
                this.loadingUnites = false;
              },
              error: (): void => {
                CWLOG.error("Fail retrieving the unites of fin");
                this.loadingUnites = false;
              }
            })
          },
          error: (): void => {
            CWLOG.error("Fail retrieving the unites of Debut");
            this.loadingUnites = false;
          }
        });
        this.trigger("unites:updated");
      }
    };

    if (this.context) {
      if (this.context.ctxGestionCollective === true) {
        typeevtid = "REFABSENCE";
      } else {
        typeevtid = "ABSSHT";
      }
    }
    if (absObject) {
      if (absObject.get("motif") === undefined) {
        codeabs = absObject.get("code");
      } else {
        codeabs = absObject.get("motif").code;
      }
    }
    if (CWSTR.isBlank(dateError)) {
      this._getTypegestForUnites(absObject, setUnitesCallback);
    }
  }

  _initCombos(): void {
    let typeevtid: string = null;
    const habCtx = this.model.getHabContext();
    let lsUser = "";

    if (!CWSTR.isBlank(this.comboMotif)) {
      this.stopListening(this.comboMotif, "comboEdited", this._formEdited);
    }
    if (!CWSTR.isBlank(this.comboUniteDebut)) {
      this.stopListening(this.comboUniteDebut, "comboEdited", this._formEdited);
    }
    if (!CWSTR.isBlank(this.comboUniteFin)) {
      this.stopListening(this.comboUniteFin, "comboEdited", this._formEdited);
    }
    if (!CWSTR.isBlank(this.comboOrigine)) {
      this.stopListening(this.comboOrigine, "comboEdited", this._formEdited);
    }
    if (this.context) {
      if (this.context.ctxGestionCollective === true) {
        typeevtid = "REFABSENCE";
      } else {
        typeevtid = "ABSSHT";
      }
    }
    habCtx.update({ foncCour: this.context.ctxHabilitation.HabilitationGestion });
    this.comboMotif = new CWComboBoxView2({
      ws: new CWCombosColl({}, {
        name: "absence/composant",
        comboId: "motifs"
      }),
      autocomplete: true,
      name: "motif.code",
      required: true,
      habContext: habCtx,
      optionsRender: (item: any): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      }
    });
    if (this.context.ctxPourPlanningMedical === true) {
      this.comboMotif.coll.params = _.extend({}, this.comboMotif.coll.params, { "medical": true });
      this.comboMotif.clearCache();
      this.comboMotif.clearColl();
    }
    this.comboMotifCalled = false;
    this.comboMotif._oldButtonSearch = this.comboMotif._buttonSearch;
    this.comboMotif._buttonSearch = (event: JQueryEventObject): any => {
      const habSaved = this.comboMotif.coll.getHabContext().get("foncCour");

      try {
        this.comboMotif.coll.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion });
        this.comboMotif._oldButtonSearch(event);
      } finally {
        this.comboMotif.coll.updateHabContext({ foncCour: habSaved });
      }
    };
    this.cache = [];
    this.comboUniteDebut = new CWComboBoxView2({
      ws: new CWSaisieUniteDef({}, {
        typeevtid: typeevtid,
        workflow: this.workflow,
      }),
      externalCache: this.cache,
      syncExternalCache: false,
      name: "unitedeb.code",
      required: true,
      habContext: this.model.getHabContext(),
    });
    this.comboUniteFin = new CWComboBoxView2({
      ws: new CWSaisieUniteDef({}, {
        typeevtid: typeevtid,
        workflow: this.workflow,
        //dateper: "FPERC"
      }),
      externalCache: this.cache,
      syncExternalCache: false,
      name: "unitefin.code",
      required: true,
      habContext: this.model.getHabContext()
    });
    //Combo Origine
    if (this.context && this.context.ctxUtilisateur) {
      lsUser = (this.context.ctxUtilisateur === "Responsable") ? "respo" : "collab";
    } else {
      lsUser = (objs.appRt.workflow.get("zone") === "resp") ? "respo" : "collab";
    }
    this.origineContext = lsUser;
    this.comboOrigine = new CWComboBoxView2({
      ws: new CWCombosColl({}, {
        urlMine: Configuration.restRoot + "/rest/ls/origine/" + this.origineContext
      }),
      name: "origine.code",
      habContext: this.model.getHabContext(),
      optionsRender: (item: { [key: string]: any }): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle;
      }
    });
    if (this.context && this.context.ctxUtilisateur === "Responsable" && (this.context.ctxSaisieMasse === true || this.context.ctxGestionCollab)) {
      this.referentielCollabPopu = new CWSelectCollabPopulationView({
        context: this.context,
        diversIdCollab: this.paramIdCollab,
        readonly: this.referentielReadOnly,
        foncCour: this.context.ctxHabilitation.HabilitationGestion,
        appendTo: this.workflow.$appendTo,
        height: 650,
        width: 850
      });
      this.$el.find(".cmbCollaborateurs").html(this.referentielCollabPopu.render().el);
      this.listenTo(this.referentielCollabPopu.model, "referentielChanged", this._referentialPopulationChanged);
    }
    this.listenTo(this.comboMotif, "comboEdited", this._formEdited);
    this.listenTo(this.comboUniteDebut, "comboEdited", this._formEdited);
    this.listenTo(this.comboUniteFin, "comboEdited", this._formEdited);
    this.listenTo(this.comboOrigine, "comboEdited", this._formEdited);
  }

  _referentialPopulationChanged(): void {
    const matricule = this.referentielCollabPopu.model.get('matric') === undefined ? null : this.referentielCollabPopu.model.get('matric');

    this.model.get("value").set("matricule", matricule, { silent: true });
    this.context.ctxGestionCollab.matricule = matricule;
    this._formEdited();
  }

  _paintCollaborateur(): void {
    this.$el.find('.cmbCollaborateurs input').val(this.referentielCollabPopu.referentiel.itemRenderer(this.collabBackup));
  }

  _formEdited(): void {
    this.model.trigger("form:edited");
  }

  _setInitialContext(context: { [key: string]: any }, avoidMaxPiecesFetch: boolean): void {
    if (this.workflow.setupState === undefined) {
      this.workflow.setupState = 0;
      this.workflow.setContext(context);
      this.workflow.setUp(() => {
        this.contextInitialized = true;
        this.setContext(context);

        if (this.context.ctxModeRepresentation === "pop-up") {
          $((this.parent as CWDialogPopupView).el).css("height", "auto");
          //resize panel to show dates fields
          $((this.parent as CWDialogPopupView).el).parent().css("min-width", "680px");
        }
      }, avoidMaxPiecesFetch);
    }
  }

  setContext(context: { [key: string]: any }): void {
    this.isContextInitialized = true;
    this.model.updateHabContext({
      onglet: context.ctxEcran,
      foncCour: context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    });
    if (this.contextInitialized === true) {
      this.context = context;

      if (CWSTR.isBlank(this.context)) {
        throw new Error("A context must be defined...");
      }
      // EVO 495.3
      if (this.context.ctxEcran === "planresp" && this.context.ctxSimulation) {
        const divcod = new CWCombosColl({
          name: "utilitaire/divcod",
          comboId: "STEVS",
        });

        if (CWSTR.isBlank(this.context.ctxEvtEstSimule)) {
          let idText = 3;
          if (this.context.ctxValeursCreation && this.context.ctxValeursCreation.AbsDateDebut &&
            this.context.ctxValeursCreation.AbsDateDebut < CWTYPE.DATE.dateToStr(new Date(), CWTYPE.DATE.DEFAULT_MASK_BBDD)) {
            idText = 4;
          }

          this.context.ctxEvtEstSimule = {
            valeur: 3,
            libelle: SIMULATION.getJourEstSimuleLabel(idText)
          };
        }
        divcod.setHabContext(this.model.getHabContext());
        divcod.fetch({
          success: (data: { [key: string]: any }): void => {
            if (data.models.length > 0) {
              for (let i = 0; i < data.models.length; i++) {
                if (data.models[i] && data.models[i].get("code") === this.context.ctxEvtEstSimule.valeur) {
                  this.context.ctxEvtEstSimule.libelle = data.models[i].get("libelle");
                  break;
                }
              }
            }
          }
        });
      }
      this._renderMotifAbsenceListSelector(() => {
        this._prepareUI();
        this._manageModeInitialisation(() => {
          this._manageActionsPossibles();
          this._manageHabilitation();
        });
      });
    } else {
      if (this.workflow.setupState !== undefined) {
        this.context = context;
      } else {
        this._setInitialContext(context, null);
      }
    }
    if (this.context.ctxEcran === "saisiemass") {
      this.btnBar.deleteButton("new");
      this.btnBar.deleteButton("copy");
      this.btnBar.deleteButton("delete");
    }
    this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  _renderMessageInformatif(motif: CWGererAbsenceSouhaitModel): void {
    const comm = this.$el.find(".gererabsencesouhait-iconHelp");//display text comm
    const maGestJust = CWDIVERS.get("maGestJust");
    let showComm = false;
    let justiOblig: JQuery = null;

    /*
     * Proposé uniquement dans les conditions suivantes :
      1) MaTexteComp est non vide et L'aide n'est pas en lien avec la gestion des justificatifs (le paramètre 'maGestJust' est égal à 0)
      2) ou bien l'aide est en lien 'maGestJust' est égal à 1 - et la gestion des pièces jointes est activ<ée pour ce motif (MaJustifAjout vaut OUI).
      */
    if (!CWSTR.isBlank(motif) && ((!CWSTR.isBlank(motif.get("comm") && maGestJust === "0") || (!CWSTR.isBlank(motif.get("comm")) && maGestJust === "1" && motif.get("justif") === true)))) {
      showComm = true;
      comm.attr('title', motif.get("comm"));
    } else {
      comm.attr('title', "");
    }
    if (showComm) {
      comm.removeClass('d-none');
    } else {
      comm.addClass('d-none');
    }
    //display the text justify bottom
    justiOblig = this.$el.find(".gererabsencesouhait-justifiObli");
    if (!CWSTR.isBlank(motif) && !CWSTR.isBlank(motif.get("justif")) && motif.get("justif") === true) {
      let titleAbs = i18n.t('messages:GT_2001');

      if ((!CWSTR.isBlank(motif.get("justifverif")) && motif.get("justifverif") === true)) {
        titleAbs = i18n.t('messages:GT_2009');
      }
      justiOblig.text(titleAbs);
      justiOblig.removeClass('d-none');
    } else {
      justiOblig.addClass('d-none');
    }
  }

  _hideAndShowTextComp(): void {
    this.showHelp = !this.showHelp;
    this.$el.find(".gererabsencesouhait-masquer").toggle();
    this.$el.find(".gererabsencesouhait-containerMasquer-texteComp, .gererabsencesouhait-savoir").toggle();
  }

  _panelStateIsReadonly(): boolean {
    const model = this._getModel();
    let isReadOnly = false;

    if (model && model.context && model.context.ctxHabilitation && model.context.ctxHabilitation.HabilitationGestion) {
      const canUpdate = CWHABILITATION.canUpdate(model.context.ctxHabilitation.HabilitationGestion);

      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  }

  _prepareUI(): void {
    // set button bar
    if (this.context.ctxModeRepresentation === "pop-up") { // Pop up
      this.$el.find(".c-gererAbsenenceContent__btnBar").append(this.btnBar.render().el);
      this.$el.find("#gererabsence_btnbar").css("float", "left");
      this.$el.find(".c-gererAbsenenceContent").addClass('c-form--fixedButtons');
      this.$el.find('.gererabsence-form-inputs').addClass('c-form__scrollZone m-0 ');
      this._paintSimulateModeHeader();
      this._setTitlePopUp();
    } else if (this.context.ctxSaisieMasse === true) {
      this.$el.find(".gererabsence-content-btn-bar").append(this.btnBar.render().el);
    } else {
      // Imbrique
      if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass" && !this.isRecapActivite) {
        if (this.parent && (this.parent as CWMAbsencesRouter).layout) {
          $((this.parent as CWMAbsencesRouter).layout.el).find(".l-panelB-butomButtons").append(this.btnBar.render().el);
        }
      }
    }
    if (this.GESTION_SOUHAIT === "1" && this.context.ctxModeRepresentation === "Imbrique") {
      this.btnBar.addButtonAt("new", "nouveau", i18n.t('common:gererabsencesouhait.nouveauButton'), "plus");
    }
    if (this.GESTION_SOUHAIT === "1") {
      this.btnBar.addButton("transformer", i18n.t('common:gererabsencesouhait.transformerButton'), 'croisement_fleches');
    }
    this.btnBar.habilitations(this.context?.ctxHabilitation?.HabilitationGestion);//pour new/copy/save/revert
    this.hideBtnBarOnInit();
  }

  private hideBtnBarOnInit(): void {
    this.btnBar.model.trigger("hide:save");
    this.btnBar.model.trigger("hide:revert");
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("hide:nouveau");
    this.btnBar.model.trigger("hide:copy");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:accepter");
    this.btnBar.model.trigger("hide:refuser");
    this.btnBar.model.trigger("hide:transformer");
  }

  _manageModeInitialisation(callback: () => void): void {
    const initMode = this.context.ctxModeInitialisation;
    const saisieHeure = this.SAISIE_HEURE;
    const saisieDuree = this.SAISIE_DUREE;
    let absence = null;

    //réinitialisation des valeurs
    this.disabled = false;
    this.desactiveHabOuEtat = false;
    this.isEditable = true;
    this.errorPetAntSaveDelete = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    //-------------------
    if (this.context.ctxSaisieMasse === true) {
      absence = new CWGererAbsenceSouhaitModel({ matricule: null }, { context: this.context, SAISIE_HEURE: saisieHeure, SAISIE_DUREE: saisieDuree });
    } else {
      absence = new CWGererAbsenceSouhaitModel(null, { context: this.context, SAISIE_HEURE: saisieHeure, SAISIE_DUREE: saisieDuree });
    }
    switch (initMode) {
      case "Consultation":
        this._initModeConsultation(absence, callback);
        break;
      case "Ajout":
        this._initModeAjout(absence, callback);
        break;
      case "InitForAction":
        this._initForAction(callback);
        break;
      default:
        this._initDefaultMode(absence, callback);
        break;
    }
  }

  _initModeConsultation(absence: CWGererAbsenceSouhaitModel, callback: () => void): void {
    if (this.context && this.context.ctxEcran === "mabsences") {
      this.btnBar.setDroit("copy", false);
    }
    //If we are in consultation mode we shouldnt need buttons bottom side
    this.btnBar.model.set("mode", "R");
    this.btnBar.model.trigger("hide:copy");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:new");
    absence.id = this.context.ctxGestionCollab.matricule + "," + this.context.ctxIdentifiantEvt;
    absence.action = "element";
    if (!CWSTR.isBlank(this.context.ctxSimulation) && this.context.ctxDonneeSimulee === true) {
      absence.activeSimulation = true;
    }
    this.model.updateHabContext({ natGest: "M" });
    absence.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    absence.fetch({
      success: (fresh: CWGererAbsenceSouhaitModel): void => {
        fresh.store();
        this.model.set("value", absence); //This will trigger a _setContextGMSituation, and this a _mapToForm
        this.model.get("value").store();
        this.model.store();
        this.original = fresh.clone();
        this._enableForm();
        if (this.original.get("souhait") === true) {
          this.context.ctxTypeEvenement = "Souhait"
        } else {
          this.context.ctxTypeEvenement = "Absence"
        }
        this._showTypeStatusTitle();
        this._configBtnTransformer();
        this._manageWKFHisto(this.context.ctxHistoriqueWKF);
        this._renderMessageInformatif(fresh);
        this._storeAbsence();
        this._manageUtilisateur();
        if (this.context.ctxEcran === "valevt" && !CWSTR.isBlank(fresh.get("motif").code) && this.context?.ctxValeursEvenement?.AbsCptSolde !== false) {
          this._showSoldesInfo(fresh.get("motif").code);
        }
        if (this.$el.find("button.accepter").is(":visible") === true) {
          const profilId = absence.get("matricule") + "," + absence.get("code") + "," + absence.get("table");
          const profilModel = new CWBaseModel();

          this.btnBar.model.trigger("hide:accepter");
          this.btnBar.model.trigger("hide:refuser");
          profilModel.url = (): string => {
            return Configuration.restRoot + "/rest/absence/composant/profils/" + encodeURIComponent(profilId);
          };
          profilModel.setHabContext(this.model.getHabContext().copy());
          if (absence.get("souhait") === true) {
            profilModel.updateHabContext({ foncCour: "RES_VAL_SHT.G", natGest: "" });
          } else {
            profilModel.updateHabContext({ foncCour: "RES_VAL_ABS.G", natGest: "" });
          }
          profilModel.fetch({
            success: (): void => {
              this.model.get("value").set("profils", profilModel.get("profils"));//on doit nettoyer l'anterieur information
              if (profilModel.get("profils") && profilModel.get("profils").length > 0) {
                //this.model.get("value").set("profils", profilModel.get("profils"));//on doit nettoyer l'anterieur information
                this.btnBar.configureValidationsButtonFromModel(this.model.get("value"));
                this.btnBar.model.trigger("show:accepter");
                this.btnBar.model.trigger("show:refuser");
              } else {
                this.btnBar.model.trigger("hide:accepter");
                this.btnBar.model.trigger("hide:refuser");
              }
            }
          });
        }
        this._showIndicateurEvenamentMessage();
        if (fresh.get("infocomp")) { //EVO 556
          this._chargeFieldData(this);
        }
        this._updateUnites(absence);
        this._mapToForm();
        this._managePieceJointe();
        if (callback) {
          callback();
        }
        //On Valevt screen hide btnBar
        if (this.context && this.context.ctxEcran === "valevt") {
          this.btnBar.model.trigger('hide');
        }
        //Mettre toujours à la fin
        this._manageRestrictions(fresh, this.isEditable);
        this.$el.find(".gererabsence-form-inputs").off("focusin");
        if (!this.desactiveHabOuEtat) {
          this.$el.find(".gererabsence-form-inputs").one("focusin", (event: JQuery.TriggeredEvent): void => {
            this._checkModeSilentModifyAbsGest((peutModifier: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }): void => {
              this.infoErrorModify = {
                "isModifiable": peutModifier,
                "error": infoError,
                "response": infoResponse,
                "options": {}
              };
              //Pièces jointes
              if (this.pieceJointe) {
                this.pieceJointe.setOmitAction(false);//Dans ce point, l'action doit être activée
              }
              this._actionPrevClickAbsenceSou();
              if (!this.desactiveHabOuEtat) {
                if (this._showMessageModifyAbsGest()) {
                  const lModel = this.model?.get("value");

                  this._manageRestrictions(lModel, this.infoErrorModify?.isModifiable);
                  this._MessageModifyAbsGest(event);
                } else {
                  this.timer = window.setInterval(() => {
                    if (!UTILS.checkHidder(this.context.ctxEcran)) {
                      if (!(event.target.viewRef instanceof CWComboBoxView2)) {
                        event.target.click();
                      }
                      if (this.timer) {
                        window.clearInterval(this.timer);
                        this.timer = null;
                      }
                    }
                  }, 300);
                }
              }
            });
          });
        }
      }
    });
  }

  hideMasquerInfo(): void {
    if (this.$el.find(".gererabsencesouhait-containerMasquer") && this.$el.find(".gererabsencesouhait-containerMasquer").length > 0) {
      this.$el.find(".gererabsencesouhait-containerMasquer").hide();
    }
  }

  //EVO 556

  /*
   * Nettoyer l'information de la zone Infocomp (information complémentaire)
   */
  cleanInfoComp(): void {
    if (this.$el.find(".gererabsencesouhait-infocomp") && this.$el.find(".gererabsencesouhait-infocomp").length > 0) {
      this.$el.find(".gererabsencesouhait-infocomp").empty();
    }
  }

  /**
   * Creates all the combos or input fields or date fields when are necessary.
   */
  _chargeFieldData(view: CWGererAbsenceSouhaitView): void {
    let tempElem = null;

    //Reset variable fields
    if (this.context.ctxSaisieMasse === true) {
      tempElem = $('.gererabsence-content-btn-bar').detach();
    }
    this.cleanInfoComp();
    if (!CWSTR.isBlank(view.model.get("value")) && !CWSTR.isBlank(view.model.get("value").get("infocomp"))) {
      const infocomp = view.model.get("value").get("infocomp");
      const infocoms = Object.keys(infocomp).map((e) => {//Transform to an and number indexed array
        return infocomp[e]
      });

      //Order the array
      this._shortInfocomp(infocoms, 0, Object.keys(infocoms).length - 1);
      _.each(infocoms, (infocom) => {
        view._manageFields(view, infocom);
      });
    }
    if (this.context.ctxSaisieMasse === true) {
      this.$el.find(".gererabsencesouhait-infocomp").append(tempElem);
    }
  }

  /**
   * Adaptation of Quicksort method to sort the infocomp array by "ordre" property
   **/
  _shortInfocomp(infocoms: any[], start: number, end: number, isRecursive: boolean = false): void {
    let temp = null;

    if (start < end) {
      const element = infocoms[start];
      let left = start;
      let right = end;

      while (left < right) {
        while (infocoms[right].ordre > element.ordre) {
          right--;
        }
        while ((left < right) && infocoms[left].ordre <= element.ordre) {
          left++;
        }
        if (left < right) {
          temp = infocoms[left]
          infocoms[left] = infocoms[right];
          infocoms[right] = temp;
        }
      }
      temp = infocoms[right];
      infocoms[right] = infocoms[start];
      infocoms[start] = temp;
      this._shortInfocomp(infocoms, start, right - 1, true);
      this._shortInfocomp(infocoms, right + 1, end, true);
    }
    if (isRecursive !== true) { //Requiered first (SFD CTIME-Composant-Gérer une absence-SFD_V8.2 pag 40)
      for (let i = 0; i < infocoms.length - 1; i++) {
        for (let j = 0; j < infocoms.length - i - 1; j++) {
          if (!infocoms[j].oblig && infocoms[j + 1].oblig) {
            temp = infocoms[j];
            infocoms[j] = infocoms[j + 1];
            infocoms[j + 1] = temp;
          }
        }
      }
    }
  }

  _manageFields(view: CWGererAbsenceSouhaitView, infocom: { [key: string]: any }): void {
    const idCombo = "infocomp." + infocom.code;

    if (!CWSTR.isBlank(infocom.codif)) {
      const comboCollRes = new CWComplementComboColl([], { "typeInfocomp": infocom.type });
      let combo: CWComboBoxView2 = null;

      comboCollRes.setHabContext(this.workflow.formModel.getHabContext());
      comboCollRes.codif = infocom.codif;
      combo = view._createCombo(idCombo, comboCollRes, infocom.valeur, infocom.oblig === true);
      //paint a combo
      view._paintFields(view, infocom, combo, idCombo);
      comboCollRes.fetch({
        success: (): void => {
          const coll = comboCollRes.toJSON();
          const findModel = _.find(comboCollRes.models, (item: CWComplementComboModel): boolean => {
            return String(item.get("code")) === String(infocom.valeur);
          });

          CWFORMS.linkLabelsToInputs(view.$el.find("." + UTILS.addScapeSlashesForJQuerySelector(idCombo) + "-container"));
          combo.enum = coll;
          if (!CWSTR.isBlank(findModel)) {
            infocom.libelle = findModel.get("libelle");//change la libelle de l'élément d'information complementarie afin de avoir la structure correcte pour les combos avec la bonne information
          }
          combo.setItem({ valeur: infocom.valeur, libelle: infocom.libelle });
        }
      });
    } else {
      let numMaxCharacter = infocom.nummax;//paint an input text field
      let inputSize = 10; //Customer 198546
      let input: JQuery<HTMLElement> = null;

      if (infocom.code.indexOf("CHARL") !== -1) {
        numMaxCharacter = 200;
        inputSize = 35;
      } else {
        if (infocom.code.indexOf("CHAR") !== -1) {
          numMaxCharacter = 8;
        }
      }
      numMaxCharacter = (inputSize > numMaxCharacter) ? inputSize : numMaxCharacter;
      input = view._createInputText(idCombo, infocom.valeur, inputSize, numMaxCharacter);
      view._paintFields(view, infocom, input, idCombo);
    }
  }

  /**
   * Creates and returns a combo field.
   */
  _createCombo(idCombo: string, optionsColl: CWComplementComboColl, value: string, required: boolean): CWComboBoxView2 {
    let combo: CWComboBoxView2 = null;

    if (optionsColl.codif !== null) {
      combo = new CWComboBoxView2({
        ws: new CWCaracteristiquesCodifColl([], { codif: optionsColl.codif, "typeInfocomp": optionsColl.typeInfocomp }),
        name: idCombo + ".valeur",
        habContext: this.workflow.formModel.getHabContext(),
        required: required,
        preprocessBeforeSetItem: (item: { [key: string]: any }): { [key: string]: any } => {
          return { "code": item.valeur, "libelle": item.libelle };
        }
      });
    } else {
      const coll = optionsColl.toJSON();

      coll.unshift({ code: null, libelle: null });
      combo = new CWComboBoxView2({
        enum: coll,
        name: idCombo + ".valeur",
        habContext: this.workflow.formModel.getHabContext(),
        required: required,
        preprocessBeforeSetItem: (item: { [key: string]: any }): { [key: string]: any } => {
          return {
            "code": item.valeur,
            "libelle": item.libelle
          };
        }
      });
      // deletes the wrong values.
      combo.enum = _.without(combo.enum, _.findWhere(combo.enum, { code: null }));
    }
    return combo;
  }

  /**
   * Creates and returns an input text field.
   */
  _createInputText(idInput: string, value: string, size: number, maxLength: number): JQuery {
    const input = $("<input>").addClass(idInput + ".valeur").addClass('form-control').attr({ "type": "text", "size": size, "maxlength": maxLength });

    return input;
  }

  /**
   * Paints the input fields or combos and their labels.
   */
  _paintFields(view: CWGererAbsenceSouhaitView, infocoms: { [key: string]: any }, fieldToPaint: CWComboBoxView2 | string | JQuery, fieldName: string): void {
    const container = $("<div>").addClass(fieldName + "-container").addClass("form-group");

    //create the label
    container.append($("<label>").addClass("lb" + fieldName).attr({ "for": fieldName + ".valeur" }).text(infocoms.libelle));
    if (typeof fieldToPaint === "object" && fieldToPaint instanceof CWComboBoxView2 && !CWSTR.isBlank(infocoms.codif)) {
      //show the combo
      container.append(fieldToPaint.render().el);
    } else {
      //Paint the input fields
      container.append($("<span>").addClass("cmb" + fieldName).html(fieldToPaint as string));
      if (infocoms.type === "N") {
        if (infocoms.masque) {
          container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("typeCustom");
          this.typeFormatByClass[fieldName + ".valeur"] = infocoms.masque;
        } else {
          container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("typeLong");
        }
        container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).removeAttr("maxlength");
      }

    }
    //evaluate if this must be hidden or not
    if (CWSTR.isBlank(infocoms.libelle) || infocoms.libelle.charAt(0) === ".") {
      container.hide();
    } else {
      container.show();
    }
    //Evaluate if this is required or not
    if (!CWSTR.isBlank(infocoms.oblig) && infocoms.oblig === true) {
      container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("cw-required");
      container.find(".lb" + UTILS.addScapeSlashesForJQuerySelector(fieldName)).addClass("cw-required");
    }
    //Add the new container
    $(view.el).find(".gererabsencesouhait-infocomp").append(container);
    //fills the dates if needed
    if (CWSTR.isBlank(infocoms.codif) && infocoms.type === "D") {
      container.find('span').addClass('c-date-selector input-group');
      CWFORMS.setDatepicker(view, "." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur"));
      container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("typeDate").removeAttr("maxlength");
    }
  }

  //End EVO 556

  _initModeAjout(absence: CWGererAbsenceSouhaitModel, callback: () => void): void {
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });
    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");
    CWSTR.setElValue(absence, "motif.code", this.context.ctxValeursCreation.AbsCodeMotif || "");
    CWSTR.setElValue(absence, "unitedeb.code", this.context.ctxValeursCreation.AbsUniteFin || "");
    CWSTR.setElValue(absence, "unitefin.code", this.context.ctxValeursCreation.AbsUniteDebut || "");
    absence.set("datedeb", this.context.ctxValeursCreation.AbsDateDebut || "");
    absence.set("datefin", this.context.ctxValeursCreation.AbsDateFin || "");
    absence.set("duree", this.context.ctxValeursCreation.AbsHeureDuree || "0000");
    absence.set("heuredeb", this.context.ctxValeursCreation.AbsHeureDebut || "0000");
    absence.set("heurefin", this.context.ctxValeursCreation.AbsHeureFin || "0000");
    absence.set("matricule", this.context.ctxGestionCollab.matricule || "");
    absence.set("souhait", false);
    if (this.context.ctxTypeEvenement !== "Absence") {
      absence.set("souhait", true);
    }
    absence.store();
    this.model.set("value", absence); //This will trigger a _setContextGMSituation
    //Drag and Drop sync UC.Agenda
    if (!_.isEmpty(absence.get("motif").code)) {
      this.comboMotif.coll.once("sync", (): void => {
        this._managePieceJointe(absence.get("motif"));
        this._updateUnites(absence);
      });
    }
    this.model.store();
    this.original = absence.clone();
    this._showTypeStatusTitle();
    this._showIndicateurEvenamentMessage();
    if (!CWSTR.isBlank(absence.get("motif")) && !CWSTR.isBlank(absence.get("motif").code)) {
      this._manageDetailsAbsence(absence.get("motif").code);
    }
    if (typeof callback === "function") {
      callback();
    }
    if (this.context.ctxSaisieMasse === true) {
      this._manageRestrictions(absence);
    } else {
      this._manageRestrictions(absence, this.isEditable);
    }
    this._manageWKFHisto(this.context.ctxHistoriqueWKF);
    this._renderMessageInformatif(this.model.get("value"));
    this.btnBar.model.set("mode", "E");
    this._storeAbsence();
    if (!CWSTR.isBlank(this.context.ctxValeursCreation.AbsCodeMotif) && this.comboMotifCalled === false) {
      this.comboMotif.coll.setHabContext(new CWHabilitationContext({
        foncCour: this.context?.ctxHabilitation?.HabilitationGestion || "COL_ABS.G",
        natGest: "",
        onglet: this.context?.ctxEcran || "mabsences"
      }));
      if (this.context.ctxPourPlanningMedical === true) {
        this.comboMotif.coll.params = _.extend({}, this.comboMotif.coll.params, { "medical": true });
        this.comboMotif.clearCache();
        this.comboMotif.clearColl();
      } else {
        this.comboMotif.clearCache();
      }
      this.comboMotif.fetchCombo(() => {
        this.comboMotifCalled = true;
        this._manageMotifs(this);
      });
    }
    this._manageUtilisateur();
    if (this.context && this.context.ctxEcran === "saisiescoll") {
      this.manageModeUnselected(true);
      this.$el.css({ "overflow": "hidden" });
    }
    if (this.context.ctxSaisieMasse === true) {
      this.btnBar.$el.find(".revert").parent().attr("title", "");
      this.btnBar.$el.find(".revert").attr("title", "");
      this.btnBar.$el.find(".revert").removeClass("ui-button-icon-only").addClass("ui-button-special");
      this.btnBar.$el.find(".revert").addClass("ui-button-text-only");
      this.btnBar.$el.find(".revert span.ui-icon-arrowrefresh-1-w").remove();
    }
  }

  _initForAction(callback?: () => void): void {
    this.workflow.setButtonBarListener();
    this.model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    if (callback) {
      callback();
    }
  }

  _initDefaultMode(absence: CWGererAbsenceSouhaitModel, callback?: () => void): void {
    this.btnBar.model.trigger("show:new");
    this.workflow.setButtonBarListener();
    absence.store();
    this.model.set("value", absence); //This will trigger a _setContextGMSituation
    this.model.store();
    if (callback) {
      callback();
    }
    this._manageRestrictions(absence);
    this._manageWKFHisto(this.context.ctxHistoriqueWKF);
    this._renderMessageInformatif(this.model.get("value"));
    this._managePieceJointe();
    this._manageUtilisateur();
    this._disableForm();
    this._storeAbsence();
  }

  /*
   * Hide the status and type evenement if alsoTypeEv is true
   */
  _hideTypeStatusTitle(alsoTypeEv?: boolean): void {
    if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass" && !this.isRecapActivite) {
      const $layout = this.context.ctxModeRepresentation === "Imbrique" ? $((this.parent as CWMAbsencesRouter).layout.el) : (this.parent as CWDialogPopupView).$el.parent();

      $layout.find(".c-etiquette").hide();
      if (alsoTypeEv === true) {
        $layout.find(".typeAbsence").hide();
      } else {
        this._manageTypeEvenement(false);
      }
    }
  }

  _showTypeStatusTitle(alsoStatus?: boolean): void {
    this._paintWorkflowStatus();
    this._manageTypeEvenement(alsoStatus);
  }

  _manageTypeEvenement(alsoStatus: boolean): void {
    let $layout: JQuery = null;

    if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass" && !this.isRecapActivite) {
      $layout = this.context.ctxModeRepresentation === "Imbrique" ? $((this.parent as CWMAbsencesRouter).layout.el).find(".typeAbsence") : (this.parent as CWDialogPopupView).$el.parent().find(".typeAbsence");
      $layout.show();
    } else if (this.isRecapActivite) {
      $layout = this.context.ctxModeRepresentation === "Imbrique" ? (this.parent as CWGererRecapGeneralView).layout.$el.find(".absences-form .c-gererAbsenenceContent .c-gestionCollab") : (this.parent as CWDialogPopupView).$el.parent().find(".typeAbsence");
      $layout.show();
    }
    if (!this.context.ctxSaisieMasse) {
      let title = "";
      const $span = $('<span>');

      if (!CWSTR.isBlank(this.context.ctxTypeEvenement)) {
        switch (this.context.ctxTypeEvenement) {
          case "Absence":
            if (!CWSTR.isBlank(this.context.ctxActionsPossibles) && (this.context.ctxActionsPossibles.length === 1 && this.context.ctxActionsPossibles.indexOf("Creer") !== -1) || this.context.ctxModeInitialisation === "Ajout") {
              title = i18n.t('common:gererabsencesouhait.newAbsence');
            } else {
              title = this.context.ctxUtilisateur === "Collaborateur" ? i18n.t('common:gererabsencesouhait.mineAbsence') : i18n.t('common:gererabsencesouhait.absence_type');
            }
            break;
          case "Souhait":
            if ((this.context.ctxActionsPossibles.length === 1 && this.context.ctxActionsPossibles.indexOf("Creer") !== -1) || this.context.ctxModeInitialisation === "Ajout") {
              title = i18n.t('common:gererabsencesouhait.newSouhait');
            } else {
              title = this.context.ctxUtilisateur === "Collaborateur" ? i18n.t('common:gererabsencesouhait.mineSouhait') : i18n.t('common:gererabsencesouhait.souhait_type');
            }
            break;
        }
      } else {
        title = i18n.t('common:gererabsencesouhait.absence_title')
      }
      $span.html(title.toUpperCase());
      if (this.context.ctxModeInitialisation === "Ajout") {
        $span.append($('<span>').addClass('gererabsencesouhait-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gererabsencesouhait.creation_en_cours'))));
      } else if (this.context.ctxModeInitialisation === "Consultation") {
        $span.append($('<span>').addClass('gererabsencesouhait-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(String(" - ").concat(i18n.t('common:gererabsencesouhait.modification_en_cours'))));
      }
      if (!CWSTR.isBlank($layout)) {
        $layout.html($span.html());
      }
      if (alsoStatus === true) {
        this._paintWorkflowStatus();
      }
    }
  }

  _showCreationButtons(): void {
    if (this.GESTION_SOUHAIT === "1" && this.context.ctxModeRepresentation === "Imbrique") {
      this.btnBar.model.trigger("show:nouveau");
      this.btnBar.model.trigger("enable:nouveau");
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:delete");
    } else {
      this.btnBar.model.trigger("show:new");
    }
  }

  _manageActionsPossibles(): void {
    //["Creer","Supprimer","Modifier","Valider"]
    if (this.context.ctxActionsPossibles) {
      if (this.context.ctxModeInitialisation === "Consultation") {
        if (this.context.ctxActionsPossibles.indexOf("Creer") !== -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxModeRepresentation !== "pop-up") {
          this._showCreationButtons();
        }
        if (this.context.ctxActionsPossibles.indexOf("Supprimer") !== -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
          if ((CWSTR.isBlank(this.context.ctxSimulation) || (!CWSTR.isBlank(this.context.ctxSimulation) && this.context.ctxDonneeSimulee === true)) && CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion)) {
            this.btnBar.model.trigger("show:delete");
          }
        }
        if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
          this._disableForm();
        } else if (!CWSTR.isBlank(this.context.ctxSimulation) && this.context.ctxDonneeSimulee === false) {
          this._disableForm();
        }
        if (this.context.ctxActionsPossibles.indexOf("Valider") !== -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation) && this.model.get("value").get("profils") &&
          this.model.get("value").get("profils").length > 0 && this.context.ctxUtilisateur === "Responsable") {
          this.btnBar.model.trigger("show:accepter");
          this.btnBar.model.trigger("show:refuser");
        }
        if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Transformer") !== -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) &&
          CWSTR.getElValue(this.model.get("value"), "souhait") === true && (CWSTR.getElValue(this.model.get("value"), "statut.code") === "A" || CWSTR.getElValue(this.model.get("value"), "statut.code") === "H")) {
          if (this.context.ctxUtilisateur === "Responsable") {
            //Dans le cas de responsable, il y a une habilitation de gestion pour les Souhaits. Alors, on doit vérifier l'habilitation de gestion d'absences
            if (CWHABILITATION.canView("RES_ABS.G") && CWHABILITATION.canCreate("RES_ABS.G")) {
              this.btnBar.model.trigger("show:transformer");
            } else {
              this.btnBar.model.trigger("hide:transformer");
            }
          } else {
            if (CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
              this.btnBar.model.trigger("show:transformer");
            } else {
              this.btnBar.model.trigger("hide:transformer");
            }
          }
        }
      } else {
        if (this.context.ctxActionsPossibles && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxActionsPossibles.indexOf("Creer") !== -1 && this.context.ctxSaisieMasse !== true) {
          this._showCreationButtons();
        }
      }
    }
    this.btnBar.model.trigger("hide:copy");
  }

  _disableForm(): void {
    this.disabled = true;
    CWFORMS.setFormReadonly(this.$el, true, false);
    this._checkHoursDure();
    this._desactiverPieceJointe(true);
  }

  _enableForm(forced?: boolean): void {
    if (forced !== true) {
      forced = false;
    }
    this.disabled = false;
    CWFORMS.setFormReadonly(this.$el, false, forced);
    this._checkHoursDure();
    this._desactiverPieceJointe(false);
  }

  _checkHoursDure(): void {
    const mabsences = this.model.get('value');

    if (mabsences.get("unitedeb").code === 'H') {
      mabsences.get("unitefin").code = "H";
      this._manageDureeView();
    }
  }

  _manageUtilisateur(): void {
    if (this.context.ctxUtilisateur === "Responsable" && this.context.ctxGestionCollective === false && !CWSTR.isBlank(this.context.ctxGestionCollab) && !this.isRecapActivite) {
      let collabText = this.context.ctxGestionCollab.nom + " " + this.context.ctxGestionCollab.prenom; // information du collaborateur

      this.$el.find(".c-gestionCollab").removeClass("d-none");
      if (CWSTR.isBlank(this.COLLAB_ID) || (this.COLLAB_ID === "matric")) {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      } else if (this.context.ctxGestionCollab.matric_aux) {
        collabText += " (" + this.context.ctxGestionCollab.matric_aux + ")";
      } else if (this.context.ctxGestionCollab.matricule) {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      }
      this.$el.find(".c-gestionCollab").html(collabText);
    } else {
      if (this.isRecapActivite && this.context.ctxModeRepresentation === "Imbrique") {
        this.$el.find(".c-gestionCollab").removeClass("d-none");
      } else {
        this.$el.find(".c-gestionCollab").addClass('d-none');
      }
    }
  }

  private _manageHabilitation(): void {
    const peutCreer = (this.isRecapActivite) ? false : (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationGestion)) ? CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion) : false;
    const peutModifier = (this.isRecapActivite) ? false : (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationGestion)) ? CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) : false;
    const peutSupprimer = (this.isRecapActivite) ? false : (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationGestion)) ? CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion) : false;
    const peutValider = (this.isRecapActivite) ? false : (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationValidation)) ? CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation) : false;
    let isFormDesactive = false;

    if (this.model.get("value").isNew()) {
      this.isEditable = peutCreer;
    } else {
      this.isEditable = peutModifier;
    }
    if (!peutCreer && this.context.ctxModeInitialisation !== "Consultation") {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:nouveau");
      CWFORMS.setFormReadonly(this.$el.find(".gererabsence-content"), true, false);
      this.desactiveHabOuEtat = true;
      isFormDesactive = true;
    } else if (!peutCreer) {
      this.btnBar.model.trigger("hide:nouveau");
    }
    if (!peutModifier && this.context.ctxModeInitialisation !== "Ajout") {
      // we cannot disable the form if we are in "collectifs"
      if (objs.appRt.workflow.get("usecase") !== "saisiescoll") {
        this._disableForm();
        this.desactiveHabOuEtat = true;
      }
      CWFORMS.setFormReadonly(this.$el.find(".gererabsence-content"), true, false);
      isFormDesactive = true;
    }
    if (!peutModifier && !peutCreer) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
      if (!isFormDesactive) {
        this._disableForm();
      }
    }
    if (!peutSupprimer) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!peutValider) {
      this.btnBar.model.trigger("hide:accepter");
      this.btnBar.model.trigger("hide:refuser");
    }
  }

  _manageRestrictions(model: CWGererAbsenceSouhaitModel, editable?: boolean): void {
    if (model.get("statut") && !CWSTR.isBlank(model.get("statut").code)) {
      if (editable === false) {
        this._disableForm();
      }
      if (editable === true && this.disabled === true) {
        this._enableForm();
      }
      if (this.context.ctxUtilisateur === "Collaborateur") {
        this.btnBar.model.trigger("hide:accepter");
        this.btnBar.model.trigger("hide:refuser");
        switch (model.get("statut").code) {
          case "A": //Accepte
            this._disableForm();
            this.desactiveHabOuEtat = true;
            break;
          case "H": //Hors validation
            this._disableForm();
            this.desactiveHabOuEtat = true;
            break;
          case "T":
          case "D": // Demande
            if (model.get("etat") === "X") {
              this._disableForm();
              this.desactiveHabOuEtat = true;
            }
            break;
          default:
            if (editable !== false) {
              this._enableForm();
            }
        }
      } else if (this.context.ctxUtilisateur === "Responsable") {
        switch (model.get("statut").code) {
          case "D": //Demande
          case "T": //Demande
            this._disableForm();
            this.btnBar.model.trigger("hide:delete");
            this.desactiveHabOuEtat = true;
            break;
          case "I": //En cours
            this._disableForm();
            this.btnBar.model.trigger("hide:delete");
            this.desactiveHabOuEtat = true;
            break;
          case "A": //Accepte
            this.btnBar.model.trigger("hide:accepter");
            this.btnBar.model.trigger("hide:refuser");
            break;
          case "H": //Hors validation
            this.btnBar.model.trigger("hide:accepter");
            this.btnBar.model.trigger("hide:refuser");
            break;
          default:
            if (editable !== false) {
              this._enableForm();
            }
        }
      }
      if (model.get("statut").code === "R") { //Refuse
        this._disableForm();
        this.btnBar.model.trigger("hide:accepter");
        this.btnBar.model.trigger("hide:refuser");
        this.btnBar.model.trigger("hide:delete");
        this.desactiveHabOuEtat = true;
      }
    }
    if (!editable) {
      if (this.context?.ctxEcran === "mabsences") {
        if (this.model.get("mode") === "C" && this.model.get("value") && this.model.get("value").isNew() && this.btnBar.isVisible("delete")) {
          this.btnBar.model.trigger("hide:delete");
        }
      } else {
        if (this.context.ctxUtilisateur !== "Responsable") {
          this.btnBar.model.trigger("hide:delete");
        }
      }
    }
  }

  _manageWKFHisto(historique: boolean): void {
    if (historique === true && CWSTR.getElValue(this.model.get("value"), "historique") === true) {
      const typeLabel = (this.context.ctxTypeEvenement === "Souhait") ? "SOUHAITABS" : "ABSENCE";
      const demandeId = CWSTR.getElValue(this.model.get("value"), "iddemande");
      const refEvenement = CWSTR.getElValue(this.model.get("value"), "evenement");
      const refSouhait = CWSTR.getElValue(this.model.get("value"), "refsouhait");
      let ctxTransformed = false;
      let ctxSouhait = false;

      if (!CWSTR.isBlank(refSouhait)) {
        ctxTransformed = true; // is Transfomed
      }
      if (typeLabel === "SOUHAITABS") {
        ctxSouhait = true; // Is Souhait?
      }
      this.contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: typeLabel,
        ctxRefEvenement: refEvenement,
        ctxSouhait: ctxSouhait,
        ctxRefSouhait: refSouhait,
        ctxTransformed: ctxTransformed,
        ctxDemId: demandeId,
        ctxModeRepresentation: "Imbrique",
        ctxModeDefaut: "Deplie",//open list
        ctxModeRech: false,
      };
      //Prepared for the new etiquette v5
      this.composantWorkflow = new WkfhistoBlockView();
      this.composantWorkflow.render();
      this.composantWorkflow.setContext(this.contextComponentWkf);
      this.composantWorkflow.hideIcon();
    } else {
      this.$el.find(".gererabsencesouhait-wkfhisto").hide()
    }
  }

  _managePieceJointe(motifabs?: any, isRevert?: boolean): void {
    const refEvenement = CWSTR.getElValue(this.model.get("value"), "evenement");
    const mode = !CWSTR.isBlank(this.model.get("value").context) ? this.model.get("value").context.ctxModeInitialisation : this.context.ctxModeInitialisation;
    const paramPJointe = CWDIVERS.get("pJointe") === "1" ? true : false;
    let motif: CWGererAbsenceSouhaitModel, justif: boolean = false;

    if (motifabs) {
      // find motif from combo selection or drag&drop
      motif = CWSTR.isBlank(_.findWhere(this.comboMotif.coll.models, motifabs)) ?
        this.comboMotif.coll.findWhere(motifabs) :
        _.findWhere(this.comboMotif.coll.models, motifabs);
    } else {
      if (!CWSTR.isBlank(this.model.get("value"))) {
        motif = this.model.get("value")
      }
    }
    justif = !CWSTR.isBlank(motif) ? motif.get("justif") : false;
    if (justif === true && paramPJointe === true && this.context.ctxPieceJointe === true) {
      const context = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation,
        ctxRefEvenement: refEvenement,
        ctxModeRepresentation: "Imbrique",
        ctxModeDefaut: "Replie",
        ctxActionsPossibles: ["Creer", "Supprimer"],
        ctxGestionCollab: this.context.ctxGestionCollab
      };
      const maxPieces = !CWSTR.isBlank(this.workflow.maxPieces) && this.workflow.context.ctxPieceJointe === true ? this.workflow.maxPieces.get("maxi") : 0;//obtain the max number of pieces availables

      this.pieceJointeModified = false;
      if (!CWSTR.isBlank(this.pieceJointe) && !_.isEmpty(this.pieceJointe.model)) {
        this.pieceJointe.model.off("piecejointeChanged");
      }
      this.pieceJointe = new CWPieceJointes({
        context: context,
        type: "PJEVTABS",
        maxPieces: maxPieces,
        mode: mode,
        appendTo: (this.context.ctxModeRepresentation !== "pop-up") ? this.context.ctxAppendTo : null,
        modal: true
      });
      //overwrite managebuttons function to check if the absence can be modified or not.
      this.pieceJointe._oldManageButtons = this.pieceJointe._manageButtons;
      this.pieceJointe._manageButtons = (event: JQueryEventObject): void => {
        this.pieceJointe._oldManageButtons(event);
      };
      this.$el.find(".gererabsencesouhait-piecesjointes").html(this.pieceJointe.render().el);
      this.pieceJointe.setContext(context);
      this.pieceJointe.setDescription(this.getAbsenceDescription(this.model.get("value")));
      if (this.workflow.transformationEnCours) {
        this.pieceJointe.setPiecesJointes([]);
      } else {
        //Pièces jointes: si il n'est pas une nouvelle absence, le pièce jointe doit avoir l'action active
        if (!this.model?.get("value").isNew()) {
          this.pieceJointe.setOmitAction((isRevert === true ? false : true));
        }
        this.pieceJointe.setPiecesJointes(this.model.get("value").get("piecesjointes"));
      }
      this.pieceJointe.model.on("piecejointeChanged", (): void => {
        this.model.trigger("form:edited");
        this.pieceJointeModified = true;
      }, this);
      this.$el.find(".gererabsencesouhait-piecesjointes").show();
    } else if (paramPJointe === false) {
      this.$el.find(".gererabsencesouhait-piecesjointes").hide()
    } else {
      this.$el.find(".gererabsencesouhait-piecesjointes").hide();
    }
    if (!justif) {
      this.$el.find(".gererabsencesouhait-justifiObli").hide();
    } else {
      this.$el.find(".gererabsencesouhait-justifiObli").show();
    }
  }

  getAbsenceDescription(absence: CWGererAbsenceSouhaitModel): string {
    let dated = "";
    let datef = "";
    let unite = ",";
    let motif = "";
    let type = i18n.t('common:gererabsencesouhait.absence');
    let description = "";

    if (absence) {
      if (absence.get("souhait") === true) {
        type = i18n.t('common:gererabsencesouhait.souhait');
      }
      if (!CWSTR.isBlank(absence.get("motif").code)) {
        motif = absence.get("motif").libelle + " (" + absence.get("motif").code + ")";
      }
      if (!CWSTR.isBlank(absence.get("datedeb"))) {
        dated = CWTYPE.DATE.format(absence.get("datedeb"), CWTYPE._getFormatByCode(this.dateFormat));
      }
      if (!CWSTR.isBlank(absence.get("datefin"))) {
        datef = CWTYPE.DATE.format(absence.get("datefin"), CWTYPE._getFormatByCode(this.dateFormat));
      }
      if (absence.get("unitedeb").code === absence.get("unitefin").code) {
        if (absence.get("unitedeb").code === "S" || absence.get("unitedeb").code === "M") {
          unite = i18n.t('common:gererabsencesouhait.absenceUniteDescription1', { "0": absence.get("unitedeb").libelle });
        }
        if (absence.get("unitedeb").code === "H") {
          const heured = CWTYPE.HOUR_MINUTE_NIGHTLY.format(absence.get("heuredeb"));
          const heuref = CWTYPE.HOUR_MINUTE_NIGHTLY.format(absence.get("heurefin"));
          const duree = CWTYPE.DURATION.HOUR_MINUTE.format(absence.get("duree"));

          unite = i18n.t('common:gererabsencesouhait.absenceUniteDescription2', { "0": heured, "1": heuref, "2": duree });
        }
      }
      if (dated && datef) {
        const fdated = "<span class='phx-bold-input-style'>" + dated + "</span>";
        const fdatef = "<span class='phx-bold-input-style'>" + datef + "</span>";

        if (dated === datef) {
          description = i18n.t('common:gererabsencesouhait.absenceDescription1', { "0": fdated, "1": unite, "2": type, "3": motif, interpolation: { escapeValue: false } });
        } else {
          description = i18n.t('common:gererabsencesouhait.absenceDescription2', { "0": fdated, "1": fdatef, "2": unite, "3": type, "4": motif, interpolation: { escapeValue: false } });
        }
      } else {
        description = i18n.t('common:gererabsencesouhait.absenceDescription3', { "0": motif });
      }
    }
    return description;
  }


  _setTitlePopUp(): void {
    const title = $('<div>').html('<div class="gereabsencesouhait-titlePopup"><span class="typeAbsence"></span> <span class="c-titleHistoWkf"></span></span>');

    (this.parent as CWDialogPopupView).isTitleHTML = true;
    (this.parent as CWDialogPopupView)._setTitle(title.html());
  }

  _setContextGMSituation(): void {
    if (objs.gmsituation !== null && objs.gmsituation !== undefined) {
      const context: { [key: string]: any } = {};

      context.resultatsCollab = this.context.ctxGestionCollab.matricule;
      context.resultatsGroupe = 30;
      context.resultatsDate = null;
      if (this.model.get("value").get("souhait") === true) {
        context.resultatsPRSht = true;
        context.resultatsPRDde = false;
      } else {
        context.resultatsPRSht = false;
        context.resultatsPRDde = true;
      }
      objs.gmsituation.setContext(context);
    }
  }

  _changeDataValue(): void {
    this.hasBeenFocused = false;
    if (this.ctxModeInitialisation === "Ajout" || this.context.ctxModeInitialisation === "Ajout") {
      this._changeComboCollMotHab("A");
    } else {
      this._changeComboCollMotHab("M");
    }
    this._mapToForm();
  }

  render(avoidMaxPiecesFetch?: boolean): CWGererAbsenceSouhaitView {
    const json = { "i18n": i18n, 'UTILS': UTILS };

    this.$el.empty();
    this.$el.html(this.template(json));
    this.renderForm();
    if (!_.isEmpty(this.context)) {
      this._setInitialContext(this.context, avoidMaxPiecesFetch);
    }
    if (this.context.ctxModeRepresentation === "pop-up" || this.isRecapActivite) {
      this.trigger("manageUnselected", false);
    }
    return this;
  }

  renderForm(): void {
    this._initCombos();
    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);
    this.$el.find(".cmbUniteDebut").html(this.comboUniteDebut.render().el);
    this.$el.find(".cmbUniteFin").html(this.comboUniteFin.render().el);
    this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
    this._updateVisibiliteOrigine();
    // Dates
    CWFORMS.setDatepicker(this, ".datedeb");
    CWFORMS.setDatepicker(this, ".datefin");
  }

  _renderMotifAbsenceListSelector(callback?: () => void): void {
    // If only souhait motifs are request
    if (this.context.ctxTypeEvenement === "Souhait") {
      this.comboMotif.coll.applyFilter({ souhait: true });
      this.comboMotif.attributes = _.extend({}, this.comboMotif, { "souhait": true });
    } else if (this.context.ctxTypeEvenement === "Absence") {
      this.comboMotif.coll.applyFilter({ souhait: false });
      this.comboMotif.attributes = _.extend({}, this.comboMotif, { "souhait": false });
    }
    this.comboMotifCalled = false;
    if (callback) {
      callback();
    }
    this.trigger("end:renderComponent");
  }

  showNouveauMenu(): void {
    const btnNew = this.btnBar.getButtonHTMLElement("nouveau");
    const menu = $("ul.nouveauListMenu", this.el).addClass('c-panneauMenu').show().menu({
      select: (event, ui) => {
        this.executeActionMenuItem(ui.item.children()[0]);
        menu.hide();
        btnNew.focus();
      }
    }).position({
      my: "left top",
      at: "left bottom",
      of: btnNew
    });
    const funcLocal = (): void => {
      menu.hide();
      btnNew.focus();
    };

    menu.focus();
    $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), funcLocal);
    $(document).one("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), funcLocal);

  }

  menuItemListener(event: JQueryEventObject): void {
    this.executeActionMenuItem(event.currentTarget);
  }

  private executeActionMenuItem(target: Element): void {
    let action: string = null;
    let code = "";

    if (!target) {
      return;
    }
    action = (target.attributes as any)["data-value"].nodeValue;
    switch (action) {
      case "2":
        code = (target.attributes as any)["data-code"].nodeValue;
        this.btnBar.model.trigger("btn:click", "accepter", code);
        break;
      case "3":
        code = (target.attributes as any)["data-code"].nodeValue;
        this.btnBar.model.trigger("btn:click", "refuser", code);
        break;
      case "absenceButton":
        this.btnBar.model.trigger("btn:click", "nouvelleAbsence");
        break;
      case "souhaitButton":
        this.btnBar.model.trigger("btn:click", "nouveauSouhait");
        break;
      default: {
        //Do nothing
        break;
      }
    }
  }

  _configBtnTransformer(): void {
    const isSouhait = (CWSTR.getElValue(this.model.get("value"), "souhait") === true);
    const actionTransformer = (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Transformer") !== -1);
    const isTransformed = !CWSTR.isBlank((CWSTR.getElValue(this.model.get("value"), "refsouhait")));

    this.workflow.transformationEnCours = false; // souhait being tranformed to absence
    if (this.context.ctxUtilisateur === "Responsable" && isSouhait && actionTransformer && isTransformed) {//c'est une absence qui vient d'une souhait
      this.btnBar.model.trigger("hide:transformer");
      this.context.ctxHabilitation.HabilitationGestion = "RES_ABS.G";//on doit changer l'habilitation de gestion de souhait par absence
    } else {
      if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
        if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Supprimer") !== -1 && actionTransformer &&
          isSouhait && (CWSTR.getElValue(this.model.get("value"), "statut.code") === "A" || CWSTR.getElValue(this.model.get("value"), "statut.code") === "H")) {
          if (this.context.ctxUtilisateur === "Responsable") {
            //Dans le cas de responsable, il y a une habilitation de gestion pour les Souhaits. Alors, on doit vérifier l'habilitation de gestion d'absences
            if (CWHABILITATION.canView("RES_ABS.G") && CWHABILITATION.canCreate("RES_ABS.G")) {
              this.btnBar.model.trigger("show:transformer");
            } else {
              this.btnBar.model.trigger("hide:transformer");
            }
          } else {
            if (CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
              this.btnBar.model.trigger("show:transformer");
            } else {
              this.btnBar.model.trigger("hide:transformer");
            }
          }
        } else {
          this.btnBar.model.trigger("hide:transformer");
        }
      } else {
        this.btnBar.model.trigger("hide:transformer");
      }
    }
  }

  // Method for searching a unite libelle in a list of unites with the unite code
  _searchUnitelib(liste: { [key: string]: any }, unite: string | number): void {
    const element: { [key: string]: any } = _.find(liste.attributes, function (element) {
      if (element.code === unite) {
        return true;
      }
      return false;
    });

    return (element.libelle) ? element.libelle : "";
  }

  _getWorkflowStyle(statut: string): string {
    switch (statut) {
      case "A":
      case "H":
        return "ui-phx-statut-accepte";
      case "D":
      case "T":
      case "I":
        return "ui-phx-statut-demande";
      case "R":
        return "ui-phx-statut-refuse";
      default:
        CWLOG.error("Unexpected workflow style");
        return "";
    }
  }

  _mapToForm(): void {
    const mabsences = this.model?.get("value");

    if (_.isEmpty(this.$el)) {
      // this happens if component has been removed before initialization process has finished
      return;
    }
    if (!_.isEmpty(mabsences)) {
      const fieldset = this.$el.find(".gererabsence-form-inputs");
      const renderers = {};

      mabsences.setHabContext(this.model.getHabContext());
      this._manageUniteHeure(mabsences.get("unitedeb").code);
      // Error management
      mabsences.off("invalid");
      mabsences.on("invalid", this._showValidationErrors, this);
      // Clear current validation erros
      this._cleanValidationErrors();
      //FormView mapToForm
      this._manageMotifSelected(false, (argfunc: any): void => {
        this._mapModelToForm(fieldset, mabsences, renderers, undefined, argfunc);
      });
      if (CWSTR.isBlank(this.ctxModeInitialisation) && !CWSTR.isBlank(this.context.ctxModeInitialisation)) {
        this.ctxModeInitialisation = this.context.ctxModeInitialisation;
      }
      if (!CWSTR.isBlank(this.comboMotif.coll)) { //_mapModelToForm is loading data yet (so we could set parameters to avoid another call to WS)
        this._setListeMotifTmp(this.ctxModeInitialisation, this.context.ctxTypeEvenement, this.comboMotif.coll);
      }
      if (mabsences.isNew() && this.context && this.context.ctxSaisieMasse !== true) {
        fieldset.find(".motif\\.code").focus();
      }
      if (!(this.absenceDeleted === true && this.context && this.context.ctxSaisieMasse === true)) {
        this._manageRestrictions(mabsences, this.isEditable);
      }
    }
    this._manageActionsPossibles();
  }

  _manageMotifSelected(mapToFormCall?: boolean, callback?: (arg1?: any) => any): void {
    const listeMotif = this._getListeMotifTmp(this.ctxModeInitialisation, this.context.ctxTypeEvenement);

    if ((listeMotif === null || listeMotif.length === 0 || !this._manageActualMotif(this.model.get("value"))) && !this.comboMotifCalled) {
      if (this.ctxModeInitialisation === "Ajout" || this.context.ctxModeInitialisation === "Ajout") {
        this._changeComboCollMotHab("A");
      } else {
        this._changeComboCollMotHab("M");
      }
      this.comboMotif.coll.params = {};
      if (this.context.ctxPourPlanningMedical === true) {
        this.comboMotif.coll.params = { "medical": true };
        this.comboMotif.clearCache();
        this.comboMotif.clearColl();
      } else {
        this.comboMotif.clearCache();
      }
      if (this.context.ctxTypeEvenement === "Souhait") {
        this.comboMotif.coll.applyFilter({ souhait: true });
      }
      this.comboMotifCalled = true;
      if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxHabilitation.HabilitationGestion === this.model.getHabContext().get('foncCour')) {
        if (typeof callback !== "function") {
          if (!CWSTR.isBlank(this.comboMotif.term)) {
            this.comboMotif.term = "";
          }
          this.comboMotif.fetchCombo(() => {
            this._setListeMotifTmp(this.ctxModeInitialisation, this.context.ctxTypeEvenement, this.comboMotif.coll);
            this.comboMotifCalled = false;
            this._manageMotifs(this, mapToFormCall);
          });
        } else {
          callback((): void => {
            this._setListeMotifTmp(this.ctxModeInitialisation, this.context.ctxTypeEvenement, this.comboMotif.coll);
            this.comboMotifCalled = false;
            this._manageMotifs(this, mapToFormCall);
          });
        }
      } else if (typeof callback === "function") {
        callback();
      }
    } else {
      if (typeof callback === "function") {
        callback((): void => {
          this._manageMotifs(this, mapToFormCall);
        });
      } else {
        this._manageMotifs(this, mapToFormCall);
      }
    }
  }

  _getListeMotifTmp(modeInitialisation: string, typeEvenement: string): string {
    if (modeInitialisation === undefined || modeInitialisation === null) {
      modeInitialisation = "empty";
    }
    if (typeEvenement === undefined || typeEvenement === null) {
      typeEvenement = "empty";
    }
    if (this.listeTmpMotif === undefined || this.listeTmpMotif === null) {
      this.listeTmpMotif = [];
    }
    if (this.listeTmpMotif[modeInitialisation] === undefined || this.listeTmpMotif[modeInitialisation] === null) {
      this.listeTmpMotif[modeInitialisation] = [];
    }
    if (this.listeTmpMotif[modeInitialisation][typeEvenement] === undefined || this.listeTmpMotif[modeInitialisation][typeEvenement] === null) {
      this.listeTmpMotif[modeInitialisation][typeEvenement] = [];
    }
    return this.listeTmpMotif[modeInitialisation][typeEvenement];
  }

  _setListeMotifTmp(modeInitialisation: string, typeEvenement: string, listeMotif: { [key: string]: any }): void {
    if (CWSTR.isBlank(modeInitialisation)) {
      modeInitialisation = "empty";
    }
    if (CWSTR.isBlank(typeEvenement)) {
      typeEvenement = "empty";
    }
    if (_.isEmpty(this.listeTmpMotif)) {
      this.listeTmpMotif = [];
    }
    if (_.isEmpty(this.listeTmpMotif[modeInitialisation])) {
      this.listeTmpMotif[modeInitialisation] = [];
    }
    if (_.isEmpty(this.listeTmpMotif[modeInitialisation][typeEvenement])) {
      this.listeTmpMotif[modeInitialisation][typeEvenement] = [];
    }
    this.listeTmpMotif[modeInitialisation][typeEvenement] = listeMotif;
  }

  _manageActualMotif(mabsences: CWGererAbsenceSouhaitModel): { [key: string]: any } {
    const result = _.find(this.comboMotif.coll.models, (element: { [key: string]: any }): boolean => {
      return element.get("code") === mabsences.get("motif").code;
    });

    if (!result) {
      this.comboMotif.coll.params = {};
      this.comboMotif.clearCache();
      if (this.context.ctxPourPlanningMedical === true) {
        this.comboMotif.coll.params = { "medical": true };
      }
    }
    return result;
  }

  _manageMotifs(currentView: CWGererAbsenceSouhaitView, manageViewCall?: boolean): void {
    if (this.context && this.context.ctxSaisieMasse === true) {
      if (manageViewCall === true) {
        this._manageMotifsLogic(currentView);
      }
    } else {
      this._manageMotifsLogic(currentView);
    }
  }

  _manageMotifsLogic(currentView: CWGererAbsenceSouhaitView): void {
    const mabsences = currentView.model.get("value");

    _.each(currentView.comboMotif.coll.models, (element: { [key: string]: any }) => {
      if (String(element.get("code")) === String(mabsences.get("motif").code)) {
        const allowedUnites: Array<string> = [];
        const comboFilter = (response: { [key: string]: any }): { [key: string]: any } => {
          return _.filter(response, (it: { [key: string]: any }): boolean => {
            return _.contains(allowedUnites, it.id);
          });
        };
        let uniteDeb: { [key: string]: any } = null;

        mabsences.get("motif").libelle = element.get("libelle");
        if (!CWSTR.isBlank(currentView.comboUniteDebut._getItemById("H"))) {
          currentView.comboUniteDebut._getItemById("H").inUse = false;
        }
        if (!CWSTR.isBlank(currentView.comboUniteDebut._getItemById("S"))) {
          currentView.comboUniteDebut._getItemById("S").inUse = false;
        }
        if (!CWSTR.isBlank(currentView.comboUniteDebut._getItemById("J"))) {
          currentView.comboUniteDebut._getItemById("J").inUse = false;
        }
        if (!CWSTR.isBlank(currentView.comboUniteDebut._getItemById("M"))) {
          currentView.comboUniteDebut._getItemById("M").inUse = false;
        }
        if (!CWSTR.isBlank(currentView.comboUniteDebut._getItemById("K"))) {
          currentView.comboUniteDebut._getItemById("K").inUse = false;
        }
        if (!CWSTR.isBlank(currentView.comboUniteDebut._getItemById("N"))) {
          currentView.comboUniteDebut._getItemById("N").inUse = false;
        }
        if (element.get("uniteh") === true) {
          allowedUnites.push("H");
        }
        if (element.get("unites") === true) {
          allowedUnites.push("S");
        }
        if (element.get("unitej") === true) {
          allowedUnites.push("J");
        }
        if (element.get("unitem") === true) {
          allowedUnites.push("M");
        }
        if (element.get("unitek") === true) {
          allowedUnites.push("K");
        }
        if (element.get("uniten") === true) {
          allowedUnites.push("N");
        }
        if (currentView.context.ctxModeInitialisation === "Ajout" || currentView.context.ctxModeInitialisation !== "Ajout" && !_.contains(allowedUnites, mabsences.get("unitedeb").code)) {
          // In Ajout mode we initialize
          // or In case of the unit aren't allowed for the current motif
          // Force the default value of the motif
          if (!CWSTR.isBlank(element.get("unite"))) {
            mabsences.get("unitedeb").code = element.get("unite").code;
            mabsences.get("unitefin").code = element.get("unite").code;
            //
            mabsences.get("unitedeb").libelle = element.get("unite").libelle;
            mabsences.get("unitefin").libelle = element.get("unite").libelle;
            //
            currentView.comboUniteDebut.setItem(element.get("unite"));
            currentView.comboUniteFin.setItem(element.get("unite"));
          } else {
            currentView.comboUniteDebut.setItem({ code: '', libelle: '' });
            currentView.comboUniteFin.setItem({ code: '', libelle: '' });
          }
        }
        //Filter the unsupported options from the combo
        currentView.comboUniteDebut.setFilter(comboFilter);
        currentView.comboUniteFin.setFilter(comboFilter);
        uniteDeb = CWSTR.getElValue(mabsences, "unitedeb");
        currentView._manageUniteHeure(uniteDeb?.code);
      }
    });
  }

  /**
   *
   */
  _paintSimulateModeHeader(): void {
    if (this.context.ctxEcran === "planresp" && !CWSTR.isBlank(this.context.ctxSimulation) && !CWSTR.isBlank(this.context.ctxEvtEstSimule)) {
      const msg = this.context.ctxEvtEstSimule.libelle;

      this.$el.find(".journeeSimulated").html(msg).show();
    } else {
      this.$el.find(".journeeSimulated").hide();
    }
  }

  _paintWorkflowStatus(): void {
    const absence = this.model.get("value");
    let $layout = null;

    if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass" && !this.isRecapActivite) {
      if (this.context.ctxModeRepresentation === "pop-up") {
        $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');
      } else {
        $layout = (this.parent as CWMAbsencesRouter).layout ? $((this.parent as CWMAbsencesRouter).layout.el).find(".l-panelB-buttonContainer") : this.$el.find(".l-panelB-buttonContainer");
      }
    }
    if (this.context.ctxModeInitialisation === "Consultation") {//if is consultation mode
      if ($layout) {
        const AbsGenereEdT = absence.get("generationedt");
        const AbsPermut = absence.get("permutation");

        $layout.show();
        if (!CWSTR.isBlank(absence.get("statut").code)) {
          if (absence.get("statut").code !== "H") {
            //etiquette workflowhisto
            this._initEtiquette(absence);
          } else {
            if (!AbsGenereEdT) {
              if (!AbsPermut) {
                this._initEtiquette(absence);
              } else {
                if (!CWSTR.isBlank(this.context.ctxIdentifiantEvt) && AbsPermut === true) {
                  this.etiquetteHisto1 = new CWEtiquette({
                    id: "etiquetteWorkHisto",
                    text: {
                      enabled: absence.get("souhait") === true ? i18n.t('common:gererabsencesouhait.permute_sht') : i18n.t('common:gererabsencesouhait.permute_abs')
                    },
                    type: "accepte",
                    state: "actif"
                  });
                } else {
                  this.etiquetteHisto1 = null;
                  $layout.hide();
                }
              }
            } else {
              if (!CWSTR.isBlank(this.context.ctxIdentifiantEvt) && AbsGenereEdT === true) {
                this.etiquetteHisto1 = new CWEtiquette({
                  id: "etiquetteWorkHisto",
                  text: {
                    enabled: absence.get("souhait") === true ? i18n.t('common:gererabsencesouhait.generated_by_edt_sht') : i18n.t('common:gererabsencesouhait.generated_by_edt_abs')
                  },
                  type: "accepte",
                  state: "actif"
                });
              } else {
                this.etiquetteHisto1 = null;
                $layout.hide();
              }
            }
          }
          if (this.etiquetteHisto1) {
            $layout.html(this.etiquetteHisto1.render().el);
          }
        }
      }
    } else {//when we are in ajout mode
      if (!CWSTR.isBlank($layout)) {
        $layout.hide();
      }
    }
  }

  _initEtiquette(absence: any): void {
    const subtextEtiquette = absence.get("etat") === "X" ? i18n.t('common:etiquette_subText.suppression') : "";
    const codeStatut = this.checkSCodetatut(absence.get("statut").code);
    const text = absence.get("statut").code === "H" ? "" : absence.get("statut").libelle;
    const isOnlyIcon = ((this.workflow.wkfAbsActiv && absence.context.ctxTypeEvenement === "Absence") ||
      (this.workflow.wkfSouActiv && absence.context.ctxTypeEvenement === "Souhait")) &&
      absence.get("iddemande") >= 0 && absence.get("statut").code === "H";

    //il faut supprimer l'information précédente ('listenTo' aussi)
    if (!_.isEmpty(this.etiquetteHisto1)) {
      this.etiquetteHisto1.model.stopListening();
      this.etiquetteHisto1.model.off();
      this.etiquetteHisto1.$el.off();
      this.etiquetteHisto1.off();
      this.etiquetteHisto1.remove();
      this.etiquetteHisto1 = null;
    }
    this.etiquetteHisto1 = new CWEtiquette({
      id: "etiquetteWorkHisto",
      text: { enabled: text },
      subText: { enabled: subtextEtiquette },
      type: codeStatut,
      state: "actif",
      onlyIcon: isOnlyIcon
    });
    //if the workflow is on then we create the etiqette with icon and the liston to the click
    if (((this.workflow.wkfAbsActiv && absence.context.ctxTypeEvenement === "Absence") || (this.workflow.wkfSouActiv && absence.context.ctxTypeEvenement === "Souhait")) && absence.get("iddemande") >= 0) {
      this.etiquetteHisto1.icon = 'horloge_fleche';
      this.listenTo(this.etiquetteHisto1.model, "click", this.histoEtiquetteClick);
    } else if (isOnlyIcon === true || text === "") {
      this.etiquetteHisto1.setNoBackground();
    }
  }

  _showIndicateurEvenamentMessage(): void {
    const AbsGenereEdT = this.model.get("value").get("generationedt");
    const ctxIdentifiantEvt = this.context.ctxIdentifiantEvt;
    const element = this.$el.find(".gererabsencesouhait-evenamentmessage");

    if (this.context.ctxModeInitialisation === "Consultation" && !CWSTR.isBlank(ctxIdentifiantEvt) && AbsGenereEdT === true) {
      let content = i18n.t('messages:GT_1943') || "";

      content = "<div class='phx-block'><span class='phx-block-title-text ui-phx-icon-metier from-emprei-du-temps'></span></div>";
      element.html(content);
      element.show();
    } else {
      element.html("");
      element.hide();
    }
  }

  _manageDureeView(): void {
    const heuredeb = this.model.get("value").get("heuredeb");
    const heurefin = this.model.get("value").get("heurefin");

    // Duree ReadOnly when sai_absh=2
    if (String(this.SAISIE_HEURE) === "2") {
      //Desactive read only  and reactive readonly to force recalculate size of input field (customer #188754)
      CWFORMS.setFieldReadonly(this.$el.find(".duree"), false, false);
      CWFORMS.setFieldReadonly(this.$el.find(".duree"), true, false);
      this.$el.find("label[for=duree],label[for^=duree_uid_]").removeClass("cw-required");
    }
    // Duree Obligatoriness
    if (String(this.SAISIE_HEURE) !== "0" || String(this.SAISIE_HEURE) === "3") {
      this.$el.find("label[for=duree],label[for^=duree_uid_]").addClass("cw-required");
    } else {
      this.$el.find("label[for=duree],label[for^=duree_uid_]").removeClass("cw-required");
    }
    //heuredeb
    if (!CWSTR.isBlank(heuredeb) && heurefin >= heuredeb) {
      this.$el.find("label[for=heuredeb],label[for^=heuredeb_uid_]").addClass("cw-required");
      this.$el.find("label[for=heurefin],label[for^=heurefin_uid_]").addClass("cw-required");
    } else {
      this.$el.find("label[for=heuredeb],label[for^=heuredeb_uid_]").removeClass("cw-required");
      this.$el.find("label[for=heurefin],label[for^=heurefin_uid_]").removeClass("cw-required");
    }
    //heurefin
    if (!CWSTR.isBlank(heurefin) && heurefin >= heuredeb) {
      this.$el.find("label[for=heuredeb],label[for^=heuredeb_uid_]").addClass("cw-required");
      this.$el.find("label[for=heurefin],label[for^=heurefin_uid_]").addClass("cw-required");
    } else {
      this.$el.find("label[for=heuredeb],label[for^=heuredeb_uid_]").removeClass("cw-required");
      this.$el.find("label[for=heurefin],label[for^=heurefin_uid_]").removeClass("cw-required");
    }
  }

  _manageDureeValue(mheuredeb: string, mheurefin: string): void {
    const mabsences = this.model.get("value");
    let duree = 0;
    const heuredeb = parseInt(mheuredeb, 10);
    const heurefin = parseInt(mheurefin, 10);
    const heuredebBool = !CWSTR.isBlank(mheuredeb) && mheurefin >= mheuredeb;
    const heurefinBool = !CWSTR.isBlank(mheurefin) && mheurefin >= mheuredeb;

    if ((String(this.SAISIE_HEURE) === "2" || String(this.SAISIE_HEURE) === "1")) {
      duree = 0;
      if (heuredebBool === true && heurefinBool === true && (heuredeb !== 0 || heurefin !== 0)) {
        const md = heuredeb % 100;
        const mf = heurefin % 100;
        const hd = Math.floor(heuredeb / 100);
        const hf = Math.floor(heurefin / 100);

        // formula to calcul the duration
        if (mf - md < 0) {
          duree = (hf - hd - 1) * 100 + (mf - md) + 60;
        } else {
          duree = (hf - hd) * 100 + (mf - md);
        }
        if (duree < 0) {
          duree = 0;
        }
      }
      mabsences.set("duree", duree);
      this.$el.find("input.duree").val(CWTYPE.DURATION.HOUR_MINUTE_24.format(mabsences.get("duree")));
      this.$el.find("span.duree").text(CWTYPE.DURATION.HOUR_MINUTE_24.format(mabsences.get("duree")));
    }
  }

  _manageChangeDateDeb(datedeb: string): void {
    const datefin = this.model.get("value").get("datefin");

    if (!CWSTR.isBlank(datedeb) && CWSTR.isBlank(CWTYPE.DATE.validate(datedeb))) {
      const date = CWTYPE.DATE.format(datedeb, CWTYPE._getFormatByCode(this.dateFormat));

      this.$el.find(".datedeb").val(date);
      if (this.$el.find(".datefin").val() === "") {
        CWSTR.setElValue(this.model.get("value"), "datefin", datedeb);
        this.$el.find(".datefin").val(date);
      } else if (datedeb > datefin) {
        CWSTR.setElValue(this.model.get("value"), "datefin", datedeb);
        this.$el.find(".datefin").val(date);
      }
    }
  }

  _manageChangeDateFin(datefin: string): void {
    if (!CWSTR.isBlank(datefin) && CWSTR.isBlank(CWTYPE.DATE.validate(datefin))) {
      const date = CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode(this.dateFormat));

      this.$el.find(".datefin").val(date);
    }
  }

  private _displaySubtitle(): void {
    if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass" && !this.isRecapActivite) {
      const $layout = this.context.ctxModeRepresentation === "Imbrique" ? $((this.parent as CWMAbsencesRouter).layout.el).find(".typeAbsence") : (this.parent as CWDialogPopupView).$el.parent().find(".typeAbsence");

      if (this.context.ctxModeInitialisation === "Consultation") {
        const subtitle = $layout.find('.gererabsencesouhait-subtitle');

        if (subtitle.hasClass('d-none')) {
          subtitle.removeClass('d-none');
        }
      }
    }
  }

  _manageView(event: JQueryEventObject, data: { [key: string]: any }): void {
    const mabsences = this.model.get("value");
    const className = event.target.className.split(" ")[0];

    this._displaySubtitle();
    if (this._getModel() && this._getModel().context && this._getModel().context.ctxSaisieMasse === true && className === "matricule") {
      this._referentialPopulationChanged();
    }
    if (CWSTR.isBlank(data) && mabsences && !CWSTR.isBlank(mabsences.get("motif"))) {
      data = mabsences.get("motif").code;
    }
    this.absenceModified = true;
    if (className === "unitedeb.code" || className === "unitefin.code") {
      if (className === "unitedeb.code") {
        this._treatUniteDebFin();
      } else {
        this._treatUniteFinDeb();
      }
      delete this.model.get("value").validationError;
      this._change(event, data);
      if (className === "unitedeb.code") {
        const value = !CWSTR.isBlank(this.comboUniteDebut.getItem()) ? this.comboUniteDebut.getItem().attrs.code : "";

        this._manageUniteHeure(value);
        mabsences.get("unitedeb").libelle = !CWSTR.isBlank(this.comboUniteDebut.getItem()) ? this.comboUniteDebut.getItem().label : "";
        if (mabsences.get("unitedeb").code === "H" || mabsences.get("unitefin").code === 'H') {
          this.$el.find(".heure").show();
        } else {
          this.$el.find(".heure").hide();
        }
      }
      if (className === "unitefin.code") {
        const val = !CWSTR.isBlank(this.comboUniteFin.getItem()) ? this.comboUniteFin.getItem().attrs.code : "";

        this._manageUniteHeure(val);
        mabsences.get("unitefin").libelle = !CWSTR.isBlank(this.comboUniteFin.getItem()) ? this.comboUniteFin.getItem().label : "";
        if (mabsences.get("unitedeb").code === "H" || mabsences.get("unitefin").code === 'H') {
          this.$el.find(".heure").show();
        } else {
          this.$el.find(".heure").hide();
        }
      }
    } else if (className === "heuredeb" || className === "heurefin") {
      if (mabsences.get("unitedeb").code === 'H') {
        this._manageDureeValue(mabsences.get("heuredeb"), mabsences.get("heurefin"));
        this._manageDureeView();
        if (((mabsences.get("heuredeb") !== null && mabsences.get("heurefin") === null) || mabsences.get("heurefin") < mabsences.get("heuredeb")) || mabsences.get("heuredeb") !== null && mabsences.get("heurefin") !== null) {
          delete this.model.get("value").validationError;
          this._change(event, data);
          this._manageDureeValue(mabsences.get("heuredeb"), mabsences.get("heurefin"));
        } else {
          delete this.model.get("value").validationError;
          this._change(event, data, null, false);
        }
      }
    } else {
      let motif: CWGererAbsenceSouhaitModel[] = null;

      delete this.model.get("value").validationError;
      this._change(event, data);
      motif = _.filter(this.comboMotif.coll.models, function (select: CWGererAbsenceSouhaitModel) {
        return select.get("code") === data;
      });
      if (CWSTR.isBlank(motif[0]) && this.comboMotif.cache.current && this.comboMotif.cache.current?.attrs?.code === data) {
        motif[0] = new CWGererAbsenceSouhaitModel(this.comboMotif.cache.current.attrs, { SAISIE_DUREE: this.SAISIE_DUREE, SAISIE_HEURE: this.SAISIE_HEURE, context: this.context });
      }
      switch (className) {
        case "motif.code":
          if (!_.isNull(data) || _.isUndefined(data)) {
            this.comboMotif.setItem({ code: mabsences.get("motif").code })
          }
          if (this.context && this.context.ctxSaisieMasse === true && motif[0] && motif[0].get('unite') && motif[0].get('unite').code === 'H') {
            this._enableHoures(true);
          } else {
            this._enableHoures(false);
          }
          this._manageMotifSelected(true);
          this._renderMessageInformatif(motif[0]);
          this._managePieceJointe(motif[0]);
          if (!CWSTR.isBlank(motif[0])) {
            this._manageDetailsAbsence(motif[0].get("id"));
          } else {
            let tempElemActions: JQuery<HTMLElement> = null;

            //Reset variable fields
            if (this.context.ctxSaisieMasse === true) {
              tempElemActions = $('.gererabsence-content-btn-bar').detach();
            }
            this.$el.find(".gererabsencesouhait-infocomp").html(""); //Clean previusly painted fields
            if (this.context.ctxSaisieMasse === true) {
              this.$el.find(".gererabsencesouhait-infocomp").append(tempElemActions);
            }
          }
          if (!CWSTR.isBlank(motif[0])) {
            this._updateUnites(motif[0]);
          } else {
            this._updateUnites(mabsences);
          }
          // Customer #186161. To fix issue when search button is clicked
          $(event.currentTarget).trigger("blur");
          break;
        case "datedeb": {
          const lmotif = motif[0] ?? (mabsences && !_.isEmpty(mabsences.get("motif")) && !CWSTR.isBlank(mabsences.get("motif").code) ? mabsences : null);

          this._manageChangeDateDeb(mabsences.get("datedeb"));
          this._updateUnites(lmotif);
          if (UTILS.isIE()) {
            $(event.target.parentElement).find(".ui-datepicker-trigger").focus();
          }
          delete this.model.get("value").validationError;
          this._change(event, data);
        }
          break;
        case "datefin":
          this._manageChangeDateFin(mabsences.get("datefin"));
          if (UTILS.isIE()) {
            $(event.target.parentElement).find(".ui-datepicker-trigger").focus();
          }
          break;
        case "matricule":
          this._updateUnites(motif[0]);
          this.$el.find("input.motif\\.code").focus();
          break;
        default:
          break;
      }
    }
    if (this.pieceJointe) {
      this.pieceJointe.setDescription(this.getAbsenceDescription(mabsences));
    }
  }

  _manageDetailsAbsence(idMotifAbs: string): void {
    if (!CWSTR.isBlank(idMotifAbs)) {
      this.detailsAbsence = true;
      const motif = new CWOutilAbsenceComplementModel();

      motif.setHabContext(this.model.getHabContext());
      motif.set("id", idMotifAbs);
      motif.fetch({
        success: (fresh: CWOutilAbsenceComplementModel): void => {
          delete fresh.attributes.id;
          this.model.get("value").set("infocomp", fresh.get("infocomp"))
          this._chargeFieldData(this);
          if (UTILS.isIE()) {
            this.$el.find(".datedeb").focus();
          }
          if (this.context.ctxSaisieMasse === true) {
            // we call to set the height to the table if the activite has many characteristics
            this.trigger("setTableScroll");
          }
        }
      });
    }
  }

  _manageUniteHeure(unit: string): void {
    if (unit === 'H') {
      this.$el.find(".heure").show();
      this.comboUniteFin.$el.hide();
      this._manageDureeView();
    } else {
      //Fix evo 500
      if (!this.context || (this.context && this.context.ctxSaisieMasse === false)) {
        const mabsences = this.model.get("value");

        mabsences.set("heuredeb", null);
        mabsences.set("heurefin", null);
        mabsences.set("duree", null);
        this.$el.find(".heure").hide();
        this.$el.find(".heure .heuredeb").val(CWTYPE.HOUR_MINUTE.format("0000"));
        this.$el.find(".heure .heurefin").val(CWTYPE.HOUR_MINUTE.format("0000"));
        this.$el.find(".heure .duree").val(CWTYPE.DURATION.HOUR_MINUTE.format("0000"));
      }
      this.comboUniteFin.$el.show();
    }
  }

  _treatUniteDebFin(): void {
    const mabsences = this.model.get("value");
    const item = this.comboUniteDebut.getItem();
    if (this.context.ctxSaisieMasse === true) {
      if (item && item.id === 'H') {
        this._enableHoures(true);
      } else {
        this._enableHoures(false);
      }
    }
    if (mabsences.get("datefin") === mabsences.get("datedeb")) {
      mabsences.get("unitefin").code = item.id;
      this.comboUniteFin.setItem(item);
    }
    if (item.id === 'H') {
      mabsences.get("unitefin").code = item.id;
      this.comboUniteFin.setItem(item);
      this.comboUniteFin.$el.hide();
    } else {
      if (mabsences.get("unitefin").code === "H") {
        mabsences.get("unitefin").code = item.id;
        this.comboUniteFin.setItem(item);
      }
      this.comboUniteFin.$el.show();
    }
  }
  _treatUniteFinDeb(): void {
    const mabsences = this.model.get("value");
    const item = this.comboUniteFin.getItem();

    if (this.context.ctxSaisieMasse === true) {
      if (item && item.id === 'H') {
        this._enableHoures(true);
      } else {
        this._enableHoures(false);
      }
    }
    if (mabsences.get("datefin") === mabsences.get("datedeb")) {
      mabsences.get("unitedeb").code = item.id;
      this.comboUniteDebut.setItem(item);
    }
    if (item.id === 'H') {
      this.comboUniteFin.$el.hide();
      mabsences.get("unitedeb").code = item.id;
      this.comboUniteDebut.setItem(item);
    }
  }

  _enableHoures(value: boolean): void {
    if (this.context && this.context.ctxSaisieMasse === true) {
      if (value === true) {
        this.$el.find(".heure div.heure-group label").removeClass("ui-state-disabled");
        this.$el.find(".heure .heuredeb").prop('disabled', false).removeClass('ui-state-disabled').removeAttr('tabindex');
        this.$el.find(".heure .heurefin").prop('disabled', false).removeClass('ui-state-disabled').removeAttr('tabindex');
        this.$el.find(".heure .duree").prop('disabled', false).removeClass('ui-state-disabled').removeAttr('tabindex');
      } else {
        this.$el.find(".heure div.heure-group label").addClass("ui-state-disabled");
        this.$el.find(".heure .heuredeb").prop('disabled', true).addClass('ui-state-disabled').attr('tabindex', -1);
        this.$el.find(".heure .heurefin").prop('disabled', true).addClass('ui-state-disabled').attr('tabindex', -1);
        this.$el.find(".heure .duree").prop('disabled', true).addClass('ui-state-disabled').attr('tabindex', -1);
        this.clearHourFields();
      }
    }
  }

  clearHourFields(): void {
    this.model.get("value").set("heuredeb", "");
    this.model.get("value").set("heurefin", "");
    this.model.get("value").set("duree", "");
    this.$el.find(".heure .heuredeb").val(CWTYPE.HOUR_MINUTE.format("0000"));
    this.$el.find(".heure .heurefin").val(CWTYPE.HOUR_MINUTE.format("0000"));
    this.$el.find(".heure .duree").val(CWTYPE.DURATION.HOUR_MINUTE.format("0000"));
  }

  _compareModels(): string {
    const base = [];
    let needConfirmation = false;

    if (this.original.get("datedeb") !== this.model.get("value").get("datedeb")) {
      base.push(i18n.t('messages:GT_1333'));
      needConfirmation = true;
    }
    if (this.original.get("datefin") !== this.model.get("value").get("datefin")) {
      base.push(i18n.t('messages:GT_1334'));
      needConfirmation = true;
    }
    if (this.original.get("motif").code !== this.model.get("value").get("motif").code) {
      base.push(i18n.t('messages:GT_1335'));
      needConfirmation = true;
    }
    if (this.original.get("unitedeb").code !== this.model.get("value").get("unitedeb").code ||
      this.original.get("unitefin").code !== this.model.get("value").get("unitefin").code) {
      base.push(i18n.t('messages:GT_1336'));
      needConfirmation = true;
    }
    if (this.original.get("heuredeb") !== this.model.get("value").get("heuredeb") ||
      this.original.get("heurefin") !== this.model.get("value").get("heurefin")) {
      base.push(i18n.t('messages:GT_1337'));
      needConfirmation = true;
    }
    if (this.original.get("duree") !== this.model.get("value").get("duree")) {
      base.push(i18n.t('messages:GT_1338'));
      needConfirmation = true;
    }
    if (needConfirmation === true) {
      return i18n.t('messages:GT_1049') + " " + base.join(",");
    }
    return null;
  }

  // store absence information for a possible revert
  _storeAbsence(): void {
    this.storeAbsence = {
      absence: this.model.get("value"),
      ctxModeInitialisation: this.context.ctxModeInitialisation,
      ctxTypeEvenement: this.context.ctxTypeEvenement,
      ctxHistoriqueWKF: this.context.ctxHistoriqueWKF
    };
    this.storeAbsence.absence.store();
  }

  _restoreAbsence(): void {
    this.absenceModified = false;
    if (this.storeAbsence && !(this.context && this.context.ctxSaisieMasse === true)) {
      this.model.set("value", this.storeAbsence.absence);
      this.context.ctxModeInitialisation = this.storeAbsence.ctxModeInitialisation;
      this.context.ctxTypeEvenement = this.storeAbsence.ctxTypeEvenement;
      this.context.ctxHistoriqueWKF = this.storeAbsence.ctxHistoriqueWKF;
    }
    this.model.get("value").revert();
    this.model.trigger("change:value");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  revertView(avoidResetSelection?: boolean): void {//il faut déclarer un argument(avoidResetSelection) pour l'utiliser/redéclarer dans autres, par exemple cwnavTabsAbs.view
    if (this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Ajout") {
      (this.parent as CWDialogPopupView).close();
    } else { //mode Imbrique
      this.hasBeenFocused = false;
      this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "" });
      this._restoreAbsence();
      if (this.context && this.context.ctxSaisieMasse === true && this.model.get("value").get('unitedeb') && this.model.get("value").get('unitedeb').code === 'H') {
        this._enableHoures(true);
      } else {
        this._enableHoures(false);
      }
      this.workflow.btnBarModel.set("mode", "R");

      if (this.context && this.context.ctxModeRepresentation === "pop-up") {
        this.btnBar.model.trigger("hide:new");
      }
      this._renderMessageInformatif(this.model.get("value"));
      this._managePieceJointe(null, true);
      this._manageCommentaire();
      this._manageActionsPossibles();
      this._showTypeStatusTitle();
      this._manageWKFHisto(this.context.ctxHistoriqueWKF);
      if (this.context.ctxModeInitialisation === "Consultation") {
        this._configBtnTransformer();
      } else if (CWSTR.isBlank(this.context.ctxModeInitialisation)) {
        // this._hideTypeStatusTitle(true);
        this._showCreationButtons();
        this._disableForm();
      }
      //Mettre toujours à la fin
      if (!(this.absenceDeleted === true && this.context && this.context.ctxSaisieMasse === true)) {
        this._manageRestrictions(this.model.get("value"), this.isEditable);
      }
    }
    this._manageRevertMode();
  }


  _manageRevertMode(): void {
    this.model.trigger("mode:revert");
    if (this.workflow.isRevert) {
      $(".gererabsence-form-inputs", this.$el).hide();
    }
  }

  savePiecesJointes(absence: CWGererAbsenceSouhaitModel, callback: () => void): void {
    if (absence && this.context.ctxPieceJointe === true && this.pieceJointeModified === true) {
      const evenement = absence.get("evenement");

      this.pieceJointe.applyPendingActions(evenement, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  }

  getState(): string {
    return this.btnBar.model.get("mode");
  }

  saveAbsence(): void {
    const target = this.model.get("value");
    let saveAction = "";
    const savedHab = target.habContext.attributes.foncCour;

    // target.habContext.attributes.foncCour = this.context.ctxHabilitation.HabilitationGestion;
    target.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion });
    /** Concrete case for Saisie de masse, where we allow to suppress the
     *  'Collaborateur' in a modification of a new absence but we do not
     *  allow to save it since it is not a valid change to register */
    if (!CWSTR.isBlank(this.context) && this.context.ctxEcran === "saisiemass" && CWSTR.isBlank(this.$el.find('input.matricule').val())) {
      target.set("matricule", "");
    }
    if (this.absenceModified === true || target.isNew() || this.pieceJointeModified === true || target.get("evenement") === "") {
      const saveCallback = (fresh: CWGererAbsenceSouhaitModel): void => {
        this.absenceModified = false;
        this.savePiecesJointes(fresh, (): void => {
          if (this.context.ctxModeRepresentation === "pop-up") {
            (this.parent as CWDialogPopupView).close();
          }
          this.model.trigger("absenceChanged", fresh, saveAction);
          this.workflow.btnBarModel.set("mode", "R");
          if (this.context && this.context.ctxSaisieMasse === true) {
            this.collabBackup = this.referentielCollabPopu.model.toJSON();
            this.selectCollab();
            this.trigger("absence:added", fresh);
          }

          if (objs.appRt.workflow.get("zone") === "coll" || objs.appRt.workflow.get("zone") === "resp") { //Mon Compte
            this.workflow.checkDelegation(fresh);
          }
        });
        this.model.trigger("editionEnd");
        this.model.trigger("form:save");
      };
      const errorCallback = (model: CWGererAbsenceSouhaitModel, response: { [key: string]: any }): void => {
        if (this.context && this.context.ctxSaisieMasse === true && response.responseJSON.titre !== "i18n_confirmation") {
          this.trigger("absence:added", false);
          this.workflow.btnBarModel.set("mode", "E");
        }
        this.errorPetAntSaveDelete = true;
        if (this.context.ctxModeRepresentation === "pop-up") {
          if (_.isEmpty(model.validationError) || _.isEmpty(model.validationError.errors)) {
            if (response.responseJSON?.icone === "?" && response.responseJSON?.boutons.length > 1) {
              this.stopListening(target, "sync");
              this.listenToOnce(target, "sync", (): void => {
                (this.parent as CWDialogPopupView).close();
              });
            }
          }
        }
      };

      target.MODEGROUPE = false;
      target.action = "demande";
      saveAction = "update";
      if (target.isNew()) {
        saveAction = "create";
      }
      target.activeSimulation = !CWSTR.isBlank(this.context.ctxSimulation);
      target.save(null, {
        success: saveCallback,
        error: errorCallback
      });
    } else {
      this.savePiecesJointes(target, (): void => {
        if (this.context.ctxModeRepresentation === "pop-up") {
          (this.parent as CWDialogPopupView).close();
        }
        this.model.trigger("absenceChanged", target, saveAction);

        if (objs.appRt.workflow.get("zone") === "coll" || objs.appRt.workflow.get("zone") === "resp") { //Mon Compte
          if (this.model && this.model.get("value")) {
            this.workflow.checkDelegation(this.model.get("value"));
          }
        }
      });
    }
    target.updateHabContext({ foncCour: savedHab });
  }

  selectCollab(): void {
    const collab$ = this.$el.find('input.matricule');
    const inputValue = collab$.val() as string;
    const length = inputValue.length;

    if ((collab$.get(0) as any).setSelectionRange) {
      collab$.get(0).focus();
      (collab$.get(0) as any).setSelectionRange(0, length);
    }
  }

  deleteAbsence(commentaire?: string, mode?: string): void {
    const target = this.model.get("value");
    let droit = target.get("droit");
    const successDestroyCallback = (fresh: { [key: string]: any }, refresh: { [key: string]: any }): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
      this.stopListening(target, "sync");
      target.set('refresh', refresh);
      this.model.trigger("absenceChanged", target, "delete");
      this.trigger("delete-row:remove-model", { response: "Y" });
      this.model.trigger("form:save");
    };
    const errorDestroyCallbackError = (): void => {
      if (this.context?.ctxSaisieMasse === true) {
        this.errorPetAntSaveDelete = true;
      }

    };
    const errorDestroySaisieMasseCallbackError = (): void => {
      if (this.context?.ctxSaisieMasse === true) {
        this.errorPetAntSaveDelete = true;
        this.trigger("delete-row:remove-model", { response: "N" }, true);//on doit envoyer l'information de l'erreur et demander rétablir le listenToOnce
        this.stopListening(target, "sync");
        this.listenToOnce(target, "sync", (model?: { [key: string]: any }, response?: { [key: string]: any }, options?: { [key: string]: any }): void => {
          target.set('refresh', options);
          this.model.trigger("absenceChanged", model, "delete");
          this.trigger("delete-row:remove-model", { response: "Y" });
        });
      }
    };

    this.errorPetAntSaveDelete = false;
    target.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "S" });
    if (!droit) {
      droit = "N";
    }
    target.action = "demande";
    if (!CWSTR.isBlank(commentaire)) {
      target.set("commentaire", commentaire);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }
    target.activeSimulation = !CWSTR.isBlank(this.context.ctxSimulation);
    if (this.context?.ctxSaisieMasse === true && !mode) {
      CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1677'), (result: string): void => {
        if (result === "Y") {
          target.destroy({
            dataType: "json",
            url: Configuration.restRoot + "/rest/absence/composant/" + target.action + "/" + encodeURIComponent(target.id) + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
            contentType: 'application/json',
            success: successDestroyCallback,
            error: errorDestroySaisieMasseCallbackError,
            wait: true
          });
        } else {
          this.trigger("delete-row:remove-model", { response: "N" });
        }
      }, null, null, null, this.workflow.$appendTo);
    } else {
      target.destroy({
        dataType: "json",
        url: Configuration.restRoot + "/rest/absence/composant/" + target.action + "/" + encodeURIComponent(target.id) + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
        contentType: 'application/json',
        success: successDestroyCallback,
        error: errorDestroyCallbackError,
        wait: true
      });
    }
  }

  // actions for buttons in menuLine
  deleteAbsenceFromMenuButton(absence: CWGererAbsenceSouhaitModel): void {
    absence.setHabContext(this.model.getHabContext());
    this.model.set("value", absence, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  }

  accepterAbsenceFromMenuButton(absence: CWGererAbsenceSouhaitModel): void {
    absence.setHabContext(this.model.getHabContext());
    this.model.set("value", absence, { silent: true });
    this.btnBar.model.trigger("btn:click", "accepter", absence.profil.code);
  }

  refuserAbsenceFromMenuButton(absence: CWGererAbsenceSouhaitModel): void {
    absence.setHabContext(this.model.getHabContext());
    this.model.set("value", absence, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser", absence.profil.code);
  }

  _manageCreateMode(): void {
    $(".gererabsence-form-inputs", this.$el).show();
  }

  newAbsence(): void {
    this.stopMessagePrevClickAbsenceSou();
    this.notCheckCombos = true;
    this.context.ctxTypeEvenement = "Absence";
    this.context.ctxModeInitialisation = "Ajout";
    this.context.ctxHistoriqueWKF = false;
    this.context.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
    this.hideMasquerInfo();
    this.cleanInfoComp();
    this._manageCreateMode();
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });
    this._renderMotifAbsenceListSelector(() => {
      let absence = null;

      this._enableForm();
      if (this.context.ctxSaisieMasse === true) {
        absence = new CWGererAbsenceSouhaitModel({ matricule: null }, { context: this.context, SAISIE_HEURE: this.SAISIE_HEURE, SAISIE_DUREE: this.SAISIE_DUREE });
      } else {
        absence = new CWGererAbsenceSouhaitModel(null, { context: this.context, SAISIE_HEURE: this.SAISIE_HEURE, SAISIE_DUREE: this.SAISIE_DUREE });
      }
      absence.setHabContext(this.model.getHabContext());
      absence.set("souhait", false);
      absence.set("matricule", this.context.ctxGestionCollab.matricule);
      this.model.set("value", absence);
      this._showTypeStatusTitle();
      this._manageActionsPossibles();
      this._manageWKFHisto(false);
      this._renderMessageInformatif(this.model.get("value"));
      this._managePieceJointe();
      this._manageCommentaire();
      this._showIndicateurEvenamentMessage();
      this.btnBar.model.trigger("disable:nouveau");
      this.btnBar.model.trigger("show:save");
      this.btnBar.model.trigger("show:revert");
      this.btnBar.model.set("mode", "E");
      this._chargeFieldData(this);
    });
  }

  newSouhait(): void {
    this.stopMessagePrevClickAbsenceSou();
    this.notCheckCombos = true;
    this.context.ctxTypeEvenement = "Souhait";
    this.context.ctxModeInitialisation = "Ajout";
    this.context.ctxHistoriqueWKF = false;
    this.context.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
    this.hideMasquerInfo();
    this.cleanInfoComp();
    this.model.trigger("mode:create");
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });
    this._renderMotifAbsenceListSelector(() => {
      let absence = null;

      this._enableForm();
      if (this.context.ctxSaisieMasse === true) {
        absence = new CWGererAbsenceSouhaitModel({ matricule: null }, { context: this.context, SAISIE_HEURE: this.SAISIE_HEURE, SAISIE_DUREE: this.SAISIE_DUREE });
      } else {
        absence = new CWGererAbsenceSouhaitModel(null, { context: this.context, SAISIE_HEURE: this.SAISIE_HEURE, SAISIE_DUREE: this.SAISIE_DUREE });
      }
      absence.setHabContext(this.model.getHabContext());
      absence.set("souhait", true);
      absence.set("matricule", this.context.ctxGestionCollab.matricule);
      this.model.set("value", absence);
      this._showTypeStatusTitle();
      this._manageActionsPossibles();
      this._manageWKFHisto(false);
      this._managePieceJointe();
      this._manageCommentaire();
      this._showIndicateurEvenamentMessage();
      this.btnBar.model.trigger("disable:nouveau");
      this.btnBar.model.set("mode", "E");
    });
  }

  transformAbsence(): void {
    this.model.get("value").store();
    this.stopMessagePrevClickAbsenceSou();
    this.notCheckCombos = true;
    this.workflow.transformationEnCours = true; // souhait being tranformed to absence
    this.model.get("value").transformationEnCours = true;
    this.isEditable = true;
    this._enableForm();
    this.btnBar.model.trigger("hide:transformer");
    this.swapComboCollMotif();
    this._managePieceJointe();
    this.btnBar.model.trigger("disable:nouveau");
    this.btnBar.model.set("mode", "E");
    //Customer 143413
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });
    this.context.ctxTypeEvenement = "Absence";
    this.context.ctxModeInitialisation = "Ajout";
    this._manageTypeEvenement(false);
    this._manageCommentaire();//après le changement de type ctxTypeEvenement -> "Absence"
  }

  //transform souhait to absence
  swapComboCollMotif(): void {
    this.comboMotif.coll.params = {};
    this.comboMotif.coll.setHabContext(this.model.getHabContext());
    this.comboMotif.coll.applyFilter({ souhait: false });
    this.comboMotif.attributes = _.extend({}, this.comboMotif, { "souhait": false });
  }

  _changeComboCollMotHab(NatGest: string): void {
    let ecran = "";

    if (this.model && this.model.getHabContext()) {
      this.comboMotif.coll.setHabContext(this.model.getHabContext());
    }
    if (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion) {
      ecran = !CWSTR.isBlank(this.context.ctxEcran) ? this.context.ctxEcran : "mabsences";
      this.comboMotif.coll.setHabContext(new CWHabilitationContext({
        foncCour: this.context.ctxHabilitation.HabilitationGestion,
        natGest: NatGest,
        onglet: ecran
      }));
    } else if (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationAcces) {
      ecran = !CWSTR.isBlank(this.context.ctxEcran) ? this.context.ctxEcran : "mabsences";
      this.comboMotif.coll.setHabContext(new CWHabilitationContext({
        foncCour: this.context.ctxHabilitation.HabilitationAcces,
        natGest: NatGest,
        onglet: ecran
      }));
    }
  }

  saveTransformAbsence(callback?: (argument?: string) => void): void {
    const transconfirm = this._compareModels();
    const target = this.model.get("value");
    const transformerModel = new CWGererAbsenceSouhaitGestion(target.attributes);
    const saveCallback = (fresh: CWGererAbsenceSouhaitModel): void => {
      if (callback) {
        callback(target.id);
      }
      if (this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
      this.model.trigger("absenceChanged", fresh, "update");

      if (objs.appRt.workflow.get("zone") === "coll") { //Mon Compte
        this.workflow.checkDelegation(fresh);
      }

      this.model.trigger("editionEnd");
    };
    const errorCallback = (model?: { [key: string]: any }): void => {
      if (callback) {
        callback(target.id);
      }
      if ((_.isEmpty(model.validationError) || _.isEmpty(model.validationError.errors)) && this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
    };

    (transformerModel as any)._events = target._events;//si l'il y a des erreurs, il faut les afficher (les actions sont dans target._events)
    transformerModel.oldAttributes = target.oldAttributes;//pour l'action de rafraîchissement
    transformerModel.id = target.id;
    transformerModel.setHabContext(this.model.getHabContext());
    transformerModel.action = "transformer";
    if (transconfirm === null) {
      transformerModel.save(null, {
        success: saveCallback,
        error: errorCallback
      });
    } else {
      CWMSGS.showConfirmAdapter(transconfirm, (result: string): void => {
        if (result === "Y") {
          transformerModel.save(null, {
            success: saveCallback,
            error: errorCallback
          });
        }
      }, null, null, null, this.workflow.$appendTo);
    }
  }

  accepterAbsence(commentaire: string, profil: string | number, justifok: boolean | string): void {
    const target = this.model.get("value");
    const droit = target.get("droit") === null ? "N" : target.get("droit");
    const accepterModel = new CWGererAbsenceSouhaitGestion();
    const accepterCallback = (fresh: { [key: string]: any }, refresh: { [key: string]: any }): void => {
      if (refresh && refresh[0] && refresh[0].datedeb && refresh[0].datefin) {
        this.model.get("value").set("refresh", refresh);
      }
      if (this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
      this.model.trigger("absenceChanged", this.model.get("value"), "update");
    };
    const errorCallback = (model?: { [key: string]: any }, response?: { [key: string]: any }): void => {
      if (response.status === 200) {//La valeur a été changée dans CWBaseModel
        response.status = response.oldStatus;//On doit le rétablir
      }
      if (response.status === 406 && response.responseJSON?.attribut) {
        response.responseJSON.attribut = null; //On doit nettoyer l'information du attribut avant de continuer car on est dans un volet pour valider (ce n'est pas modifiable-> on ne doit pas afficher l'erreur du attribut)
        //Avec cette modification et status égal à 406, on affichera une popup
      }
    };

    accepterModel.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    accepterModel.action = "accepter";
    accepterModel.id = target.id;
    accepterModel.set("commentaire", commentaire);
    accepterModel.set("justifok", justifok);
    accepterModel.set("droit", droit);
    accepterModel.set("profil", {});
    accepterModel.get("profil").code = profil;
    accepterModel.save(null, {
      success: accepterCallback,
      error: errorCallback
    });
  }

  refuserAbsence(commentaire: string, profil: string | number): void {
    const target = this.model.get("value");
    const droit = target.get("droit") === null ? "N" : target.get("droit");
    const refuserModel = new CWGererAbsenceSouhaitGestion();
    const refuserCallback = (fresh: { [key: string]: any }, refresh: { [key: string]: any }): void => {
      if (refresh && refresh[0] && refresh[0].datedeb && refresh[0].datefin) {
        this.model.get("value").set("refresh", refresh);
      }
      if (this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
      this.model.trigger("absenceChanged", this.model.get("value"), "update");
    };
    const errorCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
    };

    refuserModel.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    refuserModel.action = "refuser";
    refuserModel.id = target.id;
    refuserModel.set("commentaire", commentaire);
    refuserModel.set("droit", droit);
    refuserModel.set("profil", {});
    refuserModel.get("profil").code = profil;
    refuserModel.save(null, {
      success: refuserCallback,
      error: errorCallback
    });
  }

  valider(callback: () => void): void {
    const validerCallback = (): void => { //fresh: CWGererAbsenceSouhaitModel, response: { [key: string]: any }): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
      this.model.trigger("absenceChanged", this.model.get("value"), "update");
      if (callback) {
        callback();
      }
    };
    const errorCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        (this.parent as CWDialogPopupView).close();
      }
    };
    let target: CWGererAbsenceSouhaitModel = null;

    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "" });
    target = this.model.get("value");
    target.setHabContext(this.model.getHabContext());
    target.action = "validesaisie";
    target.save(null, {
      success: validerCallback,
      error: errorCallback
    });
  }

  _updateVisibiliteOrigine(): void {
    const posOrigin = this.$el.find(".originActif"); //label and combo
    const contextActif = GLOBAL_DATA.contextActif.contextActifAbs;

    //La presence de cette information dans le formulaire ne sera effective que si,
    // pour le type d'événement d'absence, la saisie de l'origine de la demande est autorisée.
    if (CWSTR.isBlank(contextActif) || contextActif.get("active") !== true) {
      posOrigin.hide();
    } //Il n'est pas necessaire l'action "show" parce qu'il vient de le faire(la création)
  }

  _checkCoherenceOfDefaultUnites(): void {
    const modelInUse = this._getModel();
    const isUniteDebAutorizd = _.find(this.comboUniteDebut.coll.models, (autorizedUniteDebut: { [key: string]: any }) => {
      return autorizedUniteDebut && autorizedUniteDebut.get("code") === modelInUse.get("unitedeb").code
    });
    const isUniteFinAutorizd = _.find(this.comboUniteFin.coll.models, (autorizedUniteFin: { [key: string]: any }) => {
      return autorizedUniteFin && autorizedUniteFin.get("code") === modelInUse.get("unitefin").code
    });

    if (CWSTR.isBlank(isUniteDebAutorizd)) {
      modelInUse.set("unitedeb", {
        code: "",
        libelle: ""
      });
      this.comboUniteDebut.setItem({ code: "", libelle: "" });
    }
    if (CWSTR.isBlank(isUniteFinAutorizd)) {
      modelInUse.set("unitefin", {
        code: "",
        libelle: ""
      });
      this.comboUniteFin.setItem({ code: "", libelle: "" });
    }
  }

  public getButtonBarRef(): CWButtonBarValidationView {
    return this.btnBar;
  }

  private histoEtiquetteClick(): void {
    if (!CWSTR.isBlank(this.histoEtiquettePopup) && this.panneauIsOpen === true) {
      this.histoEtiquettePopup.close();
      this.panneauIsOpen = false;
    } else {
      this.panneauIsOpen = true;
      if (_.isEmpty(this.histoEtiquettePopup)) {
        this.histoEtiquettePopup = new CWPanneauDeroulant({
          fixedButtons: true,
          maxHeight: 360,
          view: this.composantWorkflow,
          viewData: {
            appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
            modal: false
          },
          notIconClose: true,
          closeOnClickOutside: true
        });
        if (_.isEmpty(this.outsideClickListener)) {
          this.outsideClickListener = (event: any): any => {
            if ($(event.target).parents(".c-panneauDeroulant").length === 0 && $(event.target).closest(".ui-datepicker-header").length === 0 && $(event.target).closest(".ui-datepicker-calendar").length === 0 && this.histoEtiquettePopup) {
              this.histoEtiquettePopup._closePanneau(event, null);
            }
          };
        }
        if (this.context.ctxModeRepresentation === "Imbrique") {
          this.histoEtiquettePopup.$el.on("dialogopen", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as () => any);
            $(document).on("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener);
          });
          this.histoEtiquettePopup.$el.on("dialogclose", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as any);
            this.panneauIsOpen = false;
          });
        } else {
          this.histoEtiquettePopup.model.on("dlg:close", (): void => {
            this.panneauIsOpen = false;
          });
        }
      } else {
        this.histoEtiquettePopup.view = this.composantWorkflow;
        this.histoEtiquettePopup.viewData = {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          modal: false
        };
      }
      this.histoEtiquettePopup.setPosition({
        my: "right top",
        at: "right bottom",
        of: $(event.currentTarget)
      });
      this.histoEtiquettePopup.setHeight("auto");
      this.histoEtiquettePopup.setWidth(500);
      this.histoEtiquettePopup.open();
      this.histoEtiquettePopup._setTitle(i18n.t('common:listerevtgen.historique').toUpperCase());
    }
    event.stopPropagation();
  }

  // This method return the style typoe of the etiquette when we send the letter of the status
  private checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes;

    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
        status = 'demande';
        break;
    }
    return status;
  }

  private manageModeUnselected(unSelected: boolean): void {
    const formMode: string = this.btnBar.model.get("mode");

    if (unSelected === true && formMode !== "E") {
      this.$el.find(".c-gererAbsenenceContent__form").hide();
      this.$el.find(".c-gererAbsenenceContent__btnBar").hide();
      //this.$el.find(".c-gererAbsenenceContent__title").hide();
      this.$el.find(".l-panelB-buttonContainer").hide();
      this.$el.find(".unselectedElementText").show();
      this.$el.parents().find(".typeAbsence.cw-titre").html(i18n.t('common:gererabsencesouhait.absence_title'))
    } else {
      this.$el.find(".c-gererAbsenenceContent__form").show();
      this.$el.find(".c-gererAbsenenceContent__btnBar").show();
      this.$el.find(".c-gererAbsenenceContent__title").show();
      this.$el.find(".l-panelB-buttonContainer").show();
      this.$el.find(".unselectedElementText").hide();
    }
  }

  _showSoldesInfo(motif: string): void {
    let configModel: CWSoldesAbsencesConfigModel = null;

    configModel = new CWSoldesAbsencesConfigModel();
    configModel.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: "RES_SOLDABS.V" //this.context.ctxHabilitation.HabilitationAcces
    }));
    configModel.params = { "matricule": this.workflow.get("collabId") };
    configModel.fetch({
      success: (fresh: CWSoldesAbsencesConfigModel): void => {
        let tableModel: CWDataGridModel = null;

        if (this.context && this.context.ctxGestionCollab.matricule) {
          this.id = this.context.ctxGestionCollab.matricule + "," + fresh.get("date") + "," + fresh.get("demande") + "," + fresh.get("souhait");
        } else {
          this.id = objs.appRt.workflow.authModel.get("matricule") + "," + fresh.get("date") + "," + fresh.get("demande") + "," + fresh.get("souhait");
        }
        tableModel = new CWDataGridModel<CWSoldesAbsencesColl, CWSoldesAbsencesModel>({
          coll: new CWSoldesAbsencesColl(null, { id: this.id })
        });

        tableModel.coll.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: "RES_SOLDABS.V" // this.context.ctxHabilitation
        }));
        tableModel.coll.params = { "motif": encodeURIComponent(motif) };
        tableModel.coll.fetch({
          success: (fresh: CWSoldesAbsencesColl): void => {
            const modelTable = fresh.models ? fresh.models[0] : null;

            if (!CWSTR.isBlank(modelTable)) {
              $(".info_soldesabsence", this.el).show();
              $(".droit", this.el).html(modelTable.get("courant").droit);
              $(".pris", this.el).html(modelTable.get("courant").pris);
              $(".solde", this.el).html(modelTable.get("courant").solde);
              if (modelTable.get("courant").droit.indexOf("-") !== -1) {
                $(".droit", this.el).addClass("ui-phx-valorisation-negative");
              }
              if (modelTable.get("courant").pris.indexOf("-") !== -1) {
                $(".pris", this.el).addClass("ui-phx-valorisation-negative");
              }
              if (modelTable.get("courant").solde.indexOf("-") !== -1) {
                $(".solde", this.el).addClass("ui-phx-valorisation-negative");
              }
              $(".c-gererAbsenenceContent__form .form-group", this.el).addClass("marginBottom");
            }
          }
        });
      }
    });
  }

  _desactiverPieceJointe(etat: boolean): void {
    if (_.isBoolean(etat) && !_.isEmpty(this.pieceJointe)) {
      let motif: CWGererAbsenceSouhaitModel = null;
      let justif: boolean = false;
      const paramPJointe = (CWDIVERS.get("pJointe") === "1");

      if (!CWSTR.isBlank(this.model?.get("value"))) {
        motif = this.model.get("value")
      }
      justif = !CWSTR.isBlank(motif) ? motif.get("justif") : false;
      if (justif === true && paramPJointe === true && this.context.ctxPieceJointe === true && etat === true) {
        this.pieceJointe.desactiverPieceJointe(true);
      } else {
        this.pieceJointe.desactiverPieceJointe(false);
      }
    }
  }

  _checkModeSilentModifyAbsGest(callback: (result: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }) => any): void {
    const lModel = this.model?.get("value");
    const absenceId = lModel?.id;

    if (this.isRecapActivite) { // pas de modification sur l'écran des récapitulatifs d'activités) {
      this.isEditable = false;
      if (typeof callback === "function") {
        callback(false);
      }
    } else if (absenceId && this.workflow?.transformationEnCours !== true) {
      if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
        const isModifiableModel = new CWGererAbsenceSouhaitGestion();

        isModifiableModel.id = absenceId;
        isModifiableModel.action = "modifiable";
        isModifiableModel.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: this.context.ctxHabilitation.HabilitationGestion,
          natGest: ""
        }));
        if (!CWSTR.isBlank(this.context.ctxSimulation)) {
          isModifiableModel.activeSimulation = true;
        }
        isModifiableModel.fetch({
          success: (): void => {
            this.isEditable = true;
            if (typeof callback === "function") {
              callback(true);
            }
          },
          error: (model?: { [key: string]: any }, response?: { [key: string]: any }): void => {
            this.isEditable = false;
            if (response.status === 406 && typeof callback === "function") {
              const lError: { [key: string]: any } = {};
              const lResponse = _.clone(response);

              lError.response = _.clone(response);
              lError.responseData = _.clone(response.responseJSON);
              //après les actions précédentes de "clone", on doit changer la valeur de status et omit, pour traiter ici le message
              response.responseText = null;
              response.omit = true;
              if (lResponse.responseJSON?.icone === "?" && lResponse.responseJSON?.boutons.length > 1) {
                lResponse.responseJSON.boutons[0].action = "CONTINUE";//l'information possible du premier bouton ne sera pas utilisé. On doit la changer pour suivre avec l'action
                lResponse.responseText = JSON.stringify(lResponse.responseJSON);
              }
              callback(false, lError, lResponse);
            }
          }
        });
      } else {
        this.isEditable = false;
        if (typeof callback === "function") {
          callback(false);
        }
      }
    } else {
      this.isEditable = true; //création
      if (typeof callback === "function") {
        callback(true);
      }
    }
    this.errorPetAntSaveDelete = false;
  }

  _showMessageModifyAbsGest(): boolean {
    let rtn = false;
    const response = this.infoErrorModify?.response;

    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(response) && ((response.responseJSON?.icone === "X" && response.responseJSON?.boutons[0].action === "ABORT") || (response.responseJSON?.icone === "?" && response.responseJSON?.boutons[1].action === "ABORT"))) {
      rtn = true;
    }
    return rtn;
  }

  _MessageModifyAbsGest(event: JQuery.TriggeredEvent): void {
    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(this.infoErrorModify?.error) && !_.isEmpty(this.infoErrorModify?.response)) {
      this.infoErrorModify.options.success = (): void => {
        this.isEditable = true;
        this.infoErrorModify.isModifiable = this.isEditable;
        this._enableForm();
        this.timer = window.setInterval(() => {
          if (!UTILS.checkHidder(this.context.ctxEcran)) {
            event.target.click();
            if (this.timer) {
              window.clearInterval(this.timer);
              this.timer = null;
            }
          }
        }, 300);
      };
      onError(this.infoErrorModify?.error, this.infoErrorModify?.response, this.infoErrorModify?.options);
    }
  }

  _actionPrevClickAbsenceSou(): void {
    if (this._showMessageModifyAbsGest()) {
      const $posCalend = this.$el.find("input.typeDate:not([readonly])");
      const listeCombos = _.filter(this, (item: any): boolean => {
        return (item instanceof CWComboBoxView2);
      });

      //Combo's
      this.notCheckCombos = false;
      if (listeCombos && listeCombos.length > 0) {
        for (let i = 0; i < listeCombos.length; i++) {
          if (!CWSTR.isBlank(listeCombos[i].coll)) {
            listeCombos[i].stopListening(listeCombos[i].coll, "sync");
            listeCombos[i].listenToOnce(listeCombos[i].coll, "sync", (): void => {
              if (!this.notCheckCombos) {
                const $tempComboFetch = listeCombos[i].$el.find("input.c-cwComboBoxView2__input");

                this.notCheckCombos = true;
                if ($tempComboFetch.autocomplete("instance")) {
                  $tempComboFetch.autocomplete("close");
                }
              }
            });
          }
        }
      }
      //Si l'il y a d'information complémentaire, on doit rechercher tous les les listes de sélection
      if (!_.isEmpty(this.model.get("value")) && !_.isEmpty(this.model.get("value").get("infocomp"))) {
        const $posCombos = this.$el.find("input.c-cwComboBoxView2__input:not([readonly])");

        if ($posCombos && $posCombos.length > 0) {
          for (let i = 0; i < $posCombos.length; i++) {
            const $tempCombo = $($posCombos[i]);

            if ($tempCombo.autocomplete("instance") && $tempCombo.autocomplete("widget").is(":visible")) {
              $tempCombo.autocomplete("close");
            }
          }
        }
      }
      //Datepicker's
      if ($posCalend && $posCalend.length > 0) {
        for (let i = 0; i < $posCalend.length; i++) {
          $($posCalend[i]).datepicker("hide");
        }
      }
      //Boutons
      if (this.btnBar.isVisible("revert") || this.btnBar.isVisible("save")) {
        this.btnBar.model.trigger("btn:click", "revert");
      }
    }
  }

  stopMessagePrevClickAbsenceSou(): void {
    if (this.$el) {
      this.$el.find(".gererabsence-form-inputs").off("focusin");
    }
  }

  _manageCommentaire(): void {
    if ((this.context.ctxTypeEvenement === "Absence" && this.workflow.wkfAbsActiv === false) || (this.context.ctxTypeEvenement !== "Absence" && this.workflow.wkfSouActiv === false)) {
      this.$el.find(".blockCommentaire").addClass("d-none");
    } else {
      this.$el.find(".blockCommentaire").removeClass("d-none");
    }
  }
}
