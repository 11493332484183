import { BaseGridView } from '../basegrid/baseGrid.view.js';
import { GroupeGridRowView } from './groupeGridRow.view.js';
import { GroupeItemGridRowView } from './groupeItemGridRow.view.js';
import { STR } from 'utils/str.js';
import { UTILS } from 'utils/utils.js';

export var GroupeGridView = BaseGridView.extend({

  /**
   * Default name of the view
   */
  DEFAULT_NANE: "Data",

  /**
   * Constructor
   * View underlying a Tree type DataGrid
   */
  initialize: function(options) {
    BaseGridView.prototype.initialize.call(this, options);
  },

  /**
   * Paints the view of the tree grid
   */
  render: function() {
    var self = this;

    if ($(this.el).children().length === 0) { // Render the header only one time
      $(this.el).html(this.template());

      // Header
      this._renderHead();
    }

    //Configure height and width of the component
    var tableScroll = $(this.el).find(".phx-grid-scroll");
    tableScroll.height(this.height);
    tableScroll.css("overflow-y", "auto");
    tableScroll.css("overflow-x", "auto");

    //Width:
    $(this.el).width(this.width);

    var tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);
      $(".phx-grid-scroll", this.el).scroll(function(event) {
        self._scrollControl(event);
        $(".phx-grid-header-scroll", self.el).scrollLeft($(this).scrollLeft()); //eslint-disable-line
      });
    }
    this.positionHeader();

    return this;
  },

  /**
   * Paints the view of the tree grid
   */
  _renderHead: function() {
    var _this = this;

    this.model.groupeByColumn = null;
    BaseGridView.prototype._renderHead.call(this);
    _.each(this.currentVue.columns, function(column) {
      if (column.groupe === true && _this.currentVue.columns.length === 0) {
        _this.currentVue._columns[column.property].hideResizeable();
      }
    });
    return this;
  },

  /**
   * Expands and collapses every node of a row in the tree grid
   */
  _expandCollapseRow: function(model) {
    var groupeByColumnValue = this._groupeByColumnValue(model);

    if (this.model.groupeByColumn) {
      var header = this.$el.find("tr[groupe-header='" + groupeByColumnValue + "']");
      var rows = this.$el.find("tr[groupe-item='" + groupeByColumnValue + "']");

      if (model.isExpanded === false) { //expand row
        model.isExpanded = true;
        header.find("span.goupetbl_expbtn").removeClass("ui-icon-triangle-1-e");
        header.find("span.goupetbl_expbtn").addClass("ui-icon-triangle-1-s");

        rows.show();
      } else { //collapse rows
        model.isExpanded = false;
        header.find("span.goupetbl_expbtn").removeClass("ui-icon-triangle-1-s");
        header.find("span.goupetbl_expbtn").addClass("ui-icon-triangle-1-e");

        rows.hide();
      }
    }
    return false;
  },

  _groupeByColumnValue: function(model) {
    for (var i = 0; i < this.currentVue.columns.length; i++) {
      if (this.currentVue.columns[i].groupe === true) {
        this.model.groupeByColumn = this.currentVue.columns[i].property;
        break;
      }
    }
    if (this.model.groupeByColumn) {
      var attr = STR.getElValue(model, this.model.groupeByColumn);
      if (!STR.isBlank(attr)) {
        if (_.isBoolean(attr)) {
          return attr;
        }
        return UTILS.escapeJQueryString(attr);
      }
    }
    return null;
  },

  _paintRows: function() {
    this._paintRowsGroupeView();
  },

  /**
   * Prints the row index with the total on the footer of the table
   */
  _showSelectedRowIndex: function() {
    var totalRecords = this.model.coll.totalRecords;
    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }

    var rowNumber = totalRecords;
    // if selection
    var row = $(".phx-grid-scroll tr > .ui-state-active", this.el);
    if (row) {
      var rowIndex = parseInt(row.parent().attr("rowindex"), 10);

      if (row.length > 0 && this.rowHeight > 0) {
        var extraHeight = this.$el.find(".phx-grid-scroll-extra1").height();

        rowNumber = Math.floor((extraHeight) / this.rowHeight) + rowIndex;
      }
      $(".phx-grid-footer-right", this.el).html(rowNumber + "/" + totalRecords);
    }
  },

  /**
   * Overwrites the method _paintRows from the parent class
   */
  _paintRowsGroupeView: function() {
    var tbody = $(this.el).find("tbody");
    var groupeArray = [];
    var contador = 0;
    var _this = this;
    var totalRecords = null;
    var startIndex = null;
    var size = null;
    var endIndex = null;
    var extraHeight = null;
    var preHeight = null;
    var scrollTop = null;

    if (tbody.length === 0) {
      // Avoid an initial call during Workflow setUp before the page is rendered
      return;
    }
    scrollTop = $(".phx-grid-scroll", this.el).scrollTop();
    tbody.empty();
    _.each(this.currentVue._columns, function(column) {
      column.cells = [];
    });
    for (var i = 0; i < this.model.coll.length; i++) {
      var rModel = this.model.coll.at(i);
      var row = null;
      var groupeByColumnValue = this._groupeByColumnValue(rModel);

      if (this.model.groupeByColumn) {
        if (STR.isBlank(groupeArray[groupeByColumnValue])) {
          row = new GroupeGridRowView({ model: rModel, dataGrid: this }).render().el;
          rModel.off("treetbl_exp");
          rModel.on("treetbl_exp", this._expandCollapseRow, this);
          i--;
          groupeArray[groupeByColumnValue] = 1;
        } else {
          groupeArray[groupeByColumnValue] += 1;
          row = new GroupeItemGridRowView({ model: rModel, dataGrid: this }).render().el;
        }
      } else {
        row = new GroupeItemGridRowView({ model: rModel, dataGrid: this }).render().el;
      }
      $(row).addClass("ui-grid-row");
      $(row).attr("rowindex", i + 1);
      if (contador % 2 === 1) {
        $(row).addClass("ui-grid-row-alt");
      }
      tbody.append(row);
      contador++;
    }
    // Toggle control
    // Hide the columns that must be hidden by default.
    if (this.currentVue._visibleColumns.length > 0) {
      _.each(_.difference(_.keys(this.currentVue._columns), this.currentVue._visibleColumns), function(key) {
        if (key.indexOf("phx-") !== 0) {
          _this.model.trigger("toggle:column", key);
        }
      });
      /**
       * Visible columns of the tree grid
       */
      this.currentVue._visibleColumns = [];
    }
    // Hide the columns that must be hidden by default.
    if (this.currentVue._lockedColumns.length > 0) {
      _.each(this.currentVue._lockedColumns, function(key) {
        _this.model.trigger("lock:column", key);
      });
      /**
       * Locked columns of the tree grid
       */
      this.currentVue._lockedColumns = [];
    }
    // Adjust scroll
    if (!this.rowHeight || this.rowHeight === 0) {
      this._calculateRowsPerPage();
    }
    totalRecords = this.model.coll.totalRecords;
    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }
    startIndex = this.model.coll.pagination.startIndex;
    size = this.model.coll.pagination.size;
    endIndex = Math.min(startIndex + size, totalRecords);
    extraHeight = (totalRecords - endIndex) * this.rowHeight;
    $(".phx-grid-scroll-extra2", this.el).height(extraHeight);
    preHeight = (startIndex) * this.rowHeight;
    $(".phx-grid-scroll-extra1", this.el).height(preHeight);
    $(".phx-grid-scroll", this.el).scrollTop(scrollTop);
    this._renderFilteredRowsInTitle();
    if (this.multiselection) {
      this.currentVue._columns["phx-multiselect"].header._manageChecking();
    }
    $(".phx-grid-footer-right", this.el).html(endIndex + "/" + totalRecords);
    // dynamic resize height of the grid
    this._resizeGrid();
  }
});
