import _ from 'underscore';
import { CWDelegationsUtilisateurModel } from './cwDelegationsUtilisateur.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
/**
 *referentiel coll for Utilisateurs Loading
 */
export class CWDelegationsUtilisateurColl extends CWPaginatedCollection<CWDelegationsUtilisateurModel> {

  workflow: any;
  datedeb: string;
  datefin: string;

  constructor(models?: CWDelegationsUtilisateurModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWDelegationsUtilisateurModel;
    super(models, options);
    this.workflow = options.workflow;
    this.url = (): string => {
      let txtUrl = Configuration.restRoot + "/rest/utildelegation/utilisateurs";
      let questionmark = false;

      this.datedeb = (this.workflow.formModel.get("value")) ? this.workflow.formModel.get("value").get("datedeb") : null;
      this.datefin = (this.workflow.formModel.get("value")) ? this.workflow.formModel.get("value").get("datefin") : null;
      if (!CWSTR.isBlank(this.datedeb) && CWSTR.isBlank(CWTYPE.DATE.validate(this.datedeb))) {
        txtUrl += "?datedeb=" + this.datedeb;
        questionmark = true;
      }
      if (!CWSTR.isBlank(this.datefin) && CWSTR.isBlank(CWTYPE.DATE.validate(this.datefin))) {
        txtUrl += questionmark ? "&datefin=" + this.datefin : "?datefin=" + this.datefin;
        questionmark = true;
      }
      if (this.workflow.utilCode) {
        txtUrl += questionmark ? "&userID=" + this.workflow.utilCode : "?userID=" + this.workflow.utilCode
      }
      return txtUrl;
    }
  }
}
