import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLSuvietatSuvietat from '../suvietat.tpl.html';
import { CWEditableGridModel } from 'core/grids/editablegrid/cwEditableGrid.model';
import { CWEditableGridView } from 'core/grids/editablegrid/cwEditableGrid.view';
import { CWLOG } from 'src/utils/cwLog';
import { CWSTR, UTILS } from 'src/utils';
import { CWSuvietatColl } from '../models/cwSuvietat.collection';
import { CWSuvietatEditedRowView } from './cwSuvietatEditedRow.view';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * General view
 */
export class CWSuvietatView extends Backbone.View {
  workflow: any;
  table: any;
  parent: any;
  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options ? options : {};
    options.events = _.extend({
      "click .suviDownloadFile": "downloadFile"
    }, options.events);
    super(options);
    this.template = TPLSuvietatSuvietat;

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.parent = options.parent;
    this.model = null; //tableModel
    this.table = this._initTable();
  }

  render(): CWSuvietatView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));

    $(this.el).find(".suvietatTable").html(this.table.el);
    this.table.render();

    return this;
  }

  _initTable(): CWEditableGridView {
    const tableModel = new CWEditableGridModel({
      coll: new CWSuvietatColl()
    });
    this.model = tableModel;
    const tblColumns = [
      { title: i18n.t('suvietat.date_creation'), property: "date", width: 3 },
      { title: i18n.t('suvietat.theme'), property: "theme", width: 2 },
      { title: i18n.t('suvietat.nom'), property: "libelle", width: 5 },
      { title: i18n.t('suvietat.traitement'), property: "etat", width: 1 },
      { title: i18n.t('suvietat.id'), property: "code", width: 0 },
      { title: i18n.t('suvietat.resultat'), property: "type", width: 1 }
    ];

    const viewRow = new CWSuvietatEditedRowView({
      workflow: this.workflow
    });
    const table = new CWEditableGridView({
      id: "suvietat_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('suvietat.title_table'),
      showFilteredRowsInTitle: true,
      viewRow: viewRow,
      gridHeightType: "auto",
      parentContainer: this.parent,
      viewRowTitle: i18n.t('suvietat.title_volet').toUpperCase(),
    });

    table.cellRenderers["date"] = (model: { [key: string]: any }): string => {
      const date = CWTYPE.DATE.format(model.get("datedebut"), "Dy DD/MM/YYYY", "/");
      const hour = CWTYPE.HOUR_MINUTE_SECONDS.format(model.get("heuredebut"), CWTYPE._getFormatByCode("INSTHMS"));
      return date + " " + hour;
    };
    table.cellRenderers["libelle"] = (model: { [key: string]: any }): string => {
      return "<b>" + model.get("libelle") + "</b>";
    };
    table.cellRenderers["etat"] = (model: { [key: string]: any }): string => {
      let result = "";
      switch (model.get("etat").code) {
        case "0":
          result = model.get("etat").libelle;
          break;
        case "10":
          result = model.get("etat").libelle;
          break;
        case "20":
        case "40":
          result = "<label style='width:auto !important;'>" + model.get("etat").libelle + "</label>";
          break;
        case "30":
          result = "<span style='color:red;'>" + model.get("etat").libelle + "</span>";
          break;
        default:
          break;
      }
      return result;
    };
    table.cellRenderers["type"] = (model: { [key: string]: any }): JQuery => {
      let type: JQuery;
      if (model.get("etat").code !== "20" && model.get("etat").code !== "30" && model.get("etat").code !== "40") {
        type = $('<div class="suviDownloadFile">').addClass("suvietat-icon").css("text-decoration", "underline");
        type.data("uri", model.get("url"));
        const icon = $("<div>").addClass("phx-grid-not-selectable-cell");
        if (model.get("type") === "P") {
          //type.html(i18n.t('suvietat.imp'));
          icon.attr("title", i18n.t('suvietat.imp'));
          icon.append(UTILS.getSVGIcon('imprimante', 'button cw-icon-16'));
        } else if (model.get("type") === "E") {
          //type.html("title", i18n.t('suvietat.exp'));
          icon.attr("title", i18n.t('suvietat.exp'));
          icon.append(UTILS.getSVGIcon('exporter', 'button cw-icon-16'));
        }
        type.append(icon);
      }
      return type;
    };

    table.setVisibleColumns(["date", "theme", "libelle", "etat", "type"]);
    table.setSortableColumns(["date", "theme", "libelle"]);
    table.setSortableCustomNames({ "date": ["datedebut", "heuredebut"] });
    table.setColumnsAlignment({ "type": "center" });


    table.buttonBar.model.trigger("hide:new");
    table.buttonBar.model.trigger("hide:copy");

    tableModel.coll.setHabContext(this.workflow.getHabContext());
    table.model.setHabContext(this.workflow.getHabContext());
    table.height = 520;

    return table;
  }

  private downloadFile(event: JQueryMouseEventObject): void {
    const target = $(event.target);

    if (target) {
      let url: string = target.data("uri") ? target.data("uri") : $(event.target).closest(".suviDownloadFile").data("uri");
      //Clean procol for avoid problems with debug mode (removing the protocol)
      if (url.indexOf("://") > 1) {
        url = url.substring(url.indexOf("://") + 1, url.length);
      }
      if (!CWSTR.isBlank(url)) {
        UTILS.downloadFileWithAjaxAsync(url, null, "suvietatTable");
      }
      else {
        CWLOG.error("I can't find the uri path on the target");
      }
    }
  }
}