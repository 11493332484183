import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWProfilesColl } from './cwProfiles.collection';
import { CWSTR } from 'src/utils';


/**
 * Profiles model
 */
export class CWProfilesModel extends Backbone.Model {

  _profilesCounter: number;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this._profilesCounter = null;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/utilisateur/connecte/profil/selecteur";
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    response.mesprofils = new CWProfilesColl(response.mesprofils, { parse: true });
    response.profilsrecus = new CWProfilesColl(response.profilsrecus, { parse: true });
    return response;
  }

  profilesNumber(): any {
    if (CWSTR.isBlank(this._profilesCounter) && !CWSTR.isBlank(this.get("mesprofils")) && !CWSTR.isBlank(this.get("profilsrecus"))) {
      let contMprof = this.get("mesprofils").profilesNumber();
      let contRprof = this.get("profilsrecus").profilesNumber();

      contMprof = contMprof || 0;
      contRprof = contRprof || 0;
      this._profilesCounter = contMprof + contRprof;
    }
    return this._profilesCounter;
  }
}