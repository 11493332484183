import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model used to retrieve the licences
 *
 */
export class CWLoginLicenseModel extends CWBaseModel {
  /**
   * Add this to have Outline
   *
   */

  param: { [key: string]: any }
  habilitationV: string;
  habilitationG: string;
  usecase: string;

  defaults(): { [key: string]: any } {
    return {
      libelle: ""
    }
  }


  constructor(attributes?: { [key: string]: any } | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    if (options && options.param) {
      this.param = options.param;
    }
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "app";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/authentification/licence";
    }
  }
}