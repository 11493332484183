import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 *  sauvegarderPreference
 */

export class CWSauveGarderPreferenceModel extends CWBaseModel {

  workflow: any;

  defaults(): { [key: string]: any } {
    return {
      "perscriu": null,
      "perscrig": null,
      "preferences": [{
        "acces": "C",
        "code": "",
        "nivenr": "U",
        "pfr_userid": "",
        "plc_id": "",
        "varint1": null,
        "varint2": null,
        "varint3": null,
        "varint4": null,
        "varint5": null,
        "varchar1": "",
        "varchar2": "",
        "varchar3": "",
        "varchar4": "",
        "varchar5": "",
        "vardat1": "",
        "vardat2": "",
        "vardat3": "",
        "vardat4": "",
        "vardat5": ""
      }],
      "user": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.canViewTreatment = options.canViewTreatment ? options.canViewTreatment : true;
    super(attributes, options);
    if (options) {
      this.workflow = options.workflow;
    }
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.canViewTreatment = true;
    this.usecase = "tabgardes";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/preferences/generique?themes=TG_COLL&ctxAcces=C";
    }
  }

  // OVERRIDE default clone method to copy workflow
  clone(): CWBaseModel | any {
    return new (this.constructor(this.attributes, { workflow: this.workflow }));
  }
}
