module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="phx-lanceetat-detail cw-fieldset">\n    <div class="phx-formTable">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div class="phx-lanceetat-detail-content"></div>\n        <div class="unselectedElementText">\n            <label>'+
((__t=(i18n.t("lanceetat.unselectedElementText")))==null?'':__t)+
'</label>\n            <ul>\n                <li>\n                    <div class="textAccent1">\n                        '+
((__t=(i18n.t("lanceetat.imprimer")))==null?'':__t)+
' /\n                            '+
((__t=(i18n.t("lanceetat.exporter")))==null?'':__t)+
'\n                    </div>\n                    <span>'+
((__t=(i18n.t("lanceetat.text_selectionner")))==null?'':__t)+
'</span>\n                </li>\n            </ul>\n        </div>\n    </div>\n</fieldset>';
}
return __p;
};
