import * as Backbone from 'Backbone';
import _ from 'underscore';
import profilUtilisateurProfileMenuItemTPL from '../cwProfileMenuItem.tpl.html';
import profilUtilisateurProfileRecuTPL from '../cwProfileRecu.tpl.html';
import profilUtilisateurProfilesMenuTPL from '../cwProfilesMenu.tpl.html';
import { CWProfileModel } from '../models/cwProfile.model';
import { CWProfilesColl } from '../models/cwProfiles.collection';
import { CWProfilutilisateurWorkflow } from '../models/cwProfilutilisateur.workflow';
import { CWSTR } from 'src/utils/cwStr';
import { DIVERS } from 'src/utils/divers.js';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'src/utils/utils.js';

export class CWProfilUtilisateurView extends Backbone.View {

  workflow: any;
  templateMenuItem: any;
  templateMenuRecu: any;
  isVisible: boolean;
  currentSelected: { [key: string]: any };
  oldSelected: any;

  constructor(params?: Backbone.ViewOptions<Backbone.Model> | any) {
    params = params || {};
    params.events = _.extend({
      "click #tousli": "_clickTous",
      "click .prfall-check": "_clickTous",
      "click .menuProfileItem": "_changeProfilEvent",
      "click .cw-treenode-collapsable": "_collapse",
      "click .cw-treenode-expandable": "_expand",
      "click .defaut:not(.ui-state-disabled)": "_setAsDefault"
    }, params.events);
    super(params);
    this.workflow = new CWProfilutilisateurWorkflow();
    this.template = profilUtilisateurProfilesMenuTPL;
    this.templateMenuItem = profilUtilisateurProfileMenuItemTPL;
    this.templateMenuRecu = profilUtilisateurProfileRecuTPL;
    this.isVisible = false;
    this.currentSelected = { code: null };
    this.listenTo(this.workflow, "clickMenu", this._showList);
  }

  render(): CWProfilUtilisateurView {
    const lMesprofils = GLOBAL_DATA.profiles.get("mesprofils") ? GLOBAL_DATA.profiles.get("mesprofils").length : 0;
    const lProfilsrecus = GLOBAL_DATA.profiles.get("profilsrecus") ? GLOBAL_DATA.profiles.get("profilsrecus").length : 0;
    const lIsProfilUnique = lMesprofils === 1 && lProfilsrecus === 0;
    const json = { "i18n": i18n, tousCheck: (!lIsProfilUnique ? DIVERS.get("prfTous") : "0") };
    let defaultProfil = null;
    let menuLinks = null;

    this.$el.append(this.template(json));
    this.$el.addClass("ui-phx-color-primary");
    if (!CWSTR.isBlank(objs.appRt.workflow.configuration) &&
      !CWSTR.isBlank(objs.appRt.workflow.configuration.get("profildefaut"))) {
      defaultProfil = objs.appRt.workflow.configuration.get("profildefaut");
      GLOBAL_DATA.rights.profile = defaultProfil;
    } else {
      defaultProfil = DIVERS.get("prfUti");
    }
    if (lIsProfilUnique) {
      const lProfilSelected = (lMesprofils > 0) ? GLOBAL_DATA.profiles.get("mesprofils").models[0] : GLOBAL_DATA.profiles.get("profilsrecus").models[0];
      const $icon = this.$el.find(".phx-profiles-first-icon span");

      if (!CWSTR.isBlank(lProfilSelected)) {
        this.currentSelected.code = lProfilSelected.get("code");
        if (lMesprofils > 0 && lProfilSelected.get("principal") === true) {
          $icon.addClass("ui-phx-icon-metier ui-phx-profil-principal");
        } else if (lProfilsrecus > 0) {
          $icon.addClass("ui-phx-icon-metier ui-phx-profil-recu-en-delegation");
        }
        //vérification si le profil est unique et la valeur de "defaultProfil" est distincte
        if (!CWSTR.isBlank(defaultProfil) && defaultProfil !== lProfilSelected.get("code")) {
          //on corrige cette situation: les profils ont changé (réduit à un seul) mais pas dans l'option par defaut.
          defaultProfil = lProfilSelected.get("code");
        }
      }
    }
    if (defaultProfil !== "TOUS_LES_PROFILS") {
      let element = GLOBAL_DATA.profiles.get("mesprofils").getProfile(defaultProfil);

      if (CWSTR.isBlank(element)) {
        const lFindPpal = _.find(GLOBAL_DATA.profiles.get("mesprofils").models, (item: { [key: string]: any }): any => {
          return item.get("principal");
        });

        //si le profil n'est pas trouvé, le profil principal est pris ou dans un autre cas, le premier est choisi
        if (!CWSTR.isBlank(lFindPpal)) {
          const $icon = this.$el.find(".phx-profiles-first-icon span");

          element = lFindPpal[0];
          $icon.addClass("ui-phx-icon-metier ui-phx-profil-principal");
        } else {
          element = GLOBAL_DATA.profiles.get("mesprofils").at(0);
          if (CWSTR.isBlank(element) && lProfilsrecus > 0) {
            //aucun profil dispo on prend le 1er profil reçu si il existe
            element = GLOBAL_DATA.profiles.get("profilsrecus").at(0);
          }
        }
        if (!CWSTR.isBlank(element)) {
          //mise à jour de l'information des valeurs 'currentSelected' et 'defaultProfil'->très important par être la valeur de 'GLOBAL_DATA.rights.profile'
          this.currentSelected.code = element.get("code");
          defaultProfil = element.get("code");
        }
      }
      this.$el.find(".phx-profiles-menu-header").text(element.get("libelle"));
      //initialize profile
      GLOBAL_DATA.rights.profile = defaultProfil;
    } else {
      const $liTous = this.$el.find(".phx-profiles-tous-check li.ui-menu-item");

      /*  If the attribut termeProfilGenre is 1 -> 	this.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1898', { "1" :  i18n.t('profilutilisateur.restotous')}));
      else -> this.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1897', { "1" :  i18n.t('profilutilisateur.restotous')}));
      ATTENTION! the argument will be  termeProfilPluriel  (We haven't it)
       */
      if (this.$el.find(".phx-profiles-menu-header")) {
        this.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1897', { "1": i18n.t('profilutilisateur.restotous') }));
      }
      $liTous.find(".cw-valider-icon").show();
      //initialize profile
      GLOBAL_DATA.rights.profile = null;
    }
    this._addElements();
    $(this.el).find(".phx-profiles-menu-header").keydown((e: any): void => {
      this._headerKeyboradNavigation(e);
    });
    $(this.el).find(".phx-profiles-menu-list").keydown((e: any): void => {
      this._menuProfilesHeaderNavigation(e);
    });
    $(this.el).find(".phx-profiles-menu-list").hide();
    //prepare keyboard navigation for each link at menu
    menuLinks = $(this.el).find(".phx-profiles-menu-list li.phx-menu-link a");
    _.each(menuLinks, (link: any): void => {
      $(link).keydown();
    });
    $(window).resize((): void => {
      this._checkHeight();
    });
    return this;
  }

  private _headerKeyboradNavigation(e: any): void {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 40) {
      //Enter spacebar down
      if (this.isVisible === false) {
        this._showList(e);
      }
    }
  }

  private _menuProfilesHeaderNavigation(e: any): void {
    const elementFocused = $(".phx-profiles-menu-list").find(':focus');
    let elementToFocus = null;

    if (elementFocused) {
      let index = $(".phx-profiles-menu-list").find("a").index(elementFocused);

      if (index !== -1) {
        switch (e.keyCode) {
          case 38: { //Up
            if (index > 0) {
              do {
                index--;
                elementToFocus = $(".phx-profiles-menu-list").find("a")[(index)];
                if (elementToFocus && $(elementToFocus).is(":visible")) {
                  elementToFocus.focus();
                }
              } while (elementToFocus && !$(elementToFocus).is(":visible"));
            }
            break;
          }
          case 40: { //Down
            if (index < $(".phx-profiles-menu-list").find("a").length) {
              do {
                index++;
                elementToFocus = $(".phx-profiles-menu-list").find("a")[(index)];
                if (elementToFocus && $(elementToFocus).is(":visible")) {
                  elementToFocus.focus();
                }
              } while (elementToFocus && !$(elementToFocus).is(":visible"));
            }
          }
          //Not default
        }
      }
    } else {
      if (e.keyCode === 38 || e.keyCode === 40) {
        $(".phx-profiles-menu-list").find("a").first().focus();
      }
    }
  }

  private _clickTous(event: JQueryEventObject): void {
    event.stopPropagation();
    this._closeUserMenu();
    this.currentSelected = {};
    this._changeProfil(null);
  }

  private _eventMouseUp(): void {
    //		If you click out of the menu, close the menu.
    $(document).one("click", null, (event: JQueryEventObject): void => {
      const menuElements = $(".phx-profil-menu", this.el).find(event.target).length;
      const isProfilsMenuLink = $(event.target).hasClass("phx-profil-menu");

      if (menuElements > 0 || isProfilsMenuLink) {
        //Don't close menu if mouseup is on scroll bar customer 160164
        if (((event.target.tagName === "a" || event.target.tagName === "A") && $(event.target).hasClass("ui-phx-ihm-texte-donnees-attenue") === false) ||
          (event.target.id === "tous") || // Just in case.
          ($(event.target).is(".defaut:not(.ui-state-disabled)") || $(event.target).parents(".defaut:not(.ui-state-disabled)").length > 0)
        ) {
          $(this.el).find(".phx-profiles-menu-list").hide();
          this._closeUserMenu();
          this.isVisible = false;
        } else {
          this._eventMouseUp();
        }
      } else {
        $(this.el).find(".phx-profiles-menu-list").hide();
        this.isVisible = false;
      }
    }, null);
  }

  private _closeUserMenu(): void {
    $(this.el).parents("div.userMenu").hide();
  }

  private _showList(event: JQueryEventObject): void {
    const lUcaseLoading = _.find(_.keys(UTILS.usecase_loading_counter), (item: any): any => { return UTILS.usecase_loading_counter[item] > 0; })

    if (CWSTR.isBlank(lUcaseLoading)) { //s'il y a de demandes pendants, on ne doit pas permiter de changer de profil
      if (this.isVisible === false) {
        $(this.el).find(".phx-profiles-menu-list").show();
        this.isVisible = true;
        this._eventMouseUp();
        this._checkHeight();
        $(".phx-profiles-menu-list").find("a").first().focus();
        event.preventDefault();
      } else {
        if (!$(event.target).hasClass("cw-treenode-icon")) {
          $(this.el).find(".phx-profiles-menu-list").hide();
          this.isVisible = false;
        }
      }
      event.stopPropagation();
    }
  }

  _addElements(): void {
    (GLOBAL_DATA.profiles as any).profilesNumber();
    if (GLOBAL_DATA.profiles.get("mesprofils").length > 0) {
      this.$el.find(".phx-profiles-menu-mesProfils").show();
      this._generateOptions(this.$el.find(".phx-profiles-menu-mesProfils"), GLOBAL_DATA.profiles.get("mesprofils"), 0, "M");
    }
    if (GLOBAL_DATA.profiles.get("profilsrecus").length > 0) {
      this.$el.find(".phx-profiles-menu-profilsRecus").show();
      this._generateDelegations(this.$el.find(".phx-profiles-recus"), GLOBAL_DATA.profiles.get("profilsrecus"), 0, "R");
    }
  }

  _generateOptions(parent: { [key: string]: any }, list: { [key: string]: any }, level: number, type: any, parentId?: string, userId?: string): void {
    const size = list.length;

    for (let i = 0; i < size; i++) {
      const profil = list.at(i);
      const enfants = profil.get("enfants");
      const json = {
        code: profil.get("code"),
        codeId: UTILS.escapeJQueryString(profil.get("code")),
        libelle: profil.get("libelle"),
        level: level,
        enfants: enfants.length > 0,
        principal: profil.get("principal"),
        selection: profil.get("selectionnable"),
        type: type,
        parentId: parentId,
        userId: userId,
        codedeleg: profil.get("codedeleg")
      };
      const $element = $(this.templateMenuItem(json));

      if (GLOBAL_DATA.rights.profile === profil.get("code")) {
        $element.find(".cw-valider-icon").show();
      }
      if (level === 0) {
        $element.find("a").addClass("ui-phx-ihm-texte-donnees");
        parent.append($element);
      } else {
        if (profil.get("selectionnable")) {
          $element.find("a").addClass("ui-phx-ihm-texte-donnees-alternatif");
        } else {
          $element.find("a").addClass("ui-phx-ihm-texte-donnees-attenue");
        }
        $element.find(".cw-valider-icon").css("left", "-28px");
        parent.find(".phx-profle-enfants").first().append($element);
      }
      if (enfants.length > 0) {
        let lParentCode = null;

        $element.addClass("padding-with-enfant");
        if (level === 0) {
          lParentCode = profil.get("code");
        } else {
          lParentCode = parentId;
        }
        this._generateOptions($element, enfants, level + 1, type, lParentCode, userId);
      }
    }
  }

  private _generateDelegations(parent: { [key: string]: any }, list: { [key: string]: any }, level: number, type: any): void {
    const size = list.length;
    let lastUserId = "";

    for (let i = 0; i < size; i++) {
      const recu = list.at(i);

      if (lastUserId !== recu.get("codeutil")) {
        const listFilteredByUserId = list.filter((a: any): boolean => { return a.get("codeutil") === recu.get("codeutil") });
        const collFilteredByUserId = new CWProfilesColl(listFilteredByUserId, { parse: true });
        let username = recu.get("nomutil");
        const $spanTitle = $("<span>").addClass("ui-phx-ihm-texte-donnees");
        const $spanUser = $("<span>").addClass("ui-phx-ihm-texte-donnees-important").html(" " + username);
        const $element = $(this.templateMenuRecu({}));
        let message = null;
        let profilMsg = null;

        //Treat all reçus by the same delegueur
        if (recu.get("enfants").length >= 1 || collFilteredByUserId.length > 1) { // Plural          
          profilMsg = i18n.t('profilutilisateur.profils') + " ";
          message = i18n.t('messages:GT_1903', { "1": "<span class='ui-phx-ihm-texte-donnees-important'>" + profilMsg + "</span>", interpolation: { escapeValue: false } });
        } else { // Singular
          profilMsg = i18n.t('profilutilisateur.profil') + " ";
          message = i18n.t('messages:GT_1901', { "1": "<span class='ui-phx-ihm-texte-donnees-important'>" + profilMsg + "</span>", interpolation: { escapeValue: false } });
        }
        $spanTitle.html(message);
        if (!CWSTR.isBlank(recu.get("collaborateur")) && !CWSTR.isBlank(recu.get("collaborateur").nom) && !CWSTR.isBlank(recu.get("collaborateur").prenom)) {
          username = recu.get("collaborateur").prenom + " " + recu.get("collaborateur").nom;
        }
        $element.append($spanTitle);
        $element.append($spanUser);
        parent.append($element);
        this._generateOptions($element.filter(".phx-profiles-recu-first-level"), collFilteredByUserId, 0, type, null, recu.get("codeutil"));
        lastUserId = recu.get("codeutil");
      }
    }
  }

  private _changeProfil(code: string, type?: any, parentId?: string, userId?: string, revert?: boolean, codedeleg?: string): void {
    let profileList = null;
    let parentProfil: CWProfileModel = null;
    let showTooltip = false;

    if (type === "M") {
      this._showDefaultButton();
      profileList = GLOBAL_DATA.profiles.get("mesprofils");
      if (CWSTR.isBlank(parentId) === false) {
        parentProfil = profileList.get(parentId);
        showTooltip = true;
      }
    } else if (type === "R") {
      this._hideDefaultButton();
      profileList = GLOBAL_DATA.profiles.get("profilsrecus");
      if (CWSTR.isBlank(userId) === false) {
        const listFilteredByUserId = profileList.filter((a: any): boolean => { return a.get("codeutil").toString() === userId });
        const collFilteredByUserId = new CWProfilesColl(listFilteredByUserId, { parse: true });

        showTooltip = true;
        profileList = collFilteredByUserId;
        if (CWSTR.isBlank(parentId) === false) {
          parentProfil = collFilteredByUserId.get(parentId);
        }
      }
    }
    if (profileList !== null || code === null) {
      let element: any = null;

      if (code !== null) {
        if (parentProfil === null) {
          element = profileList.get(code);
        } else {
          element = parentProfil.getProfile(code);
        }
      }
      GLOBAL_DATA.rights.profile = code;
      if (parentProfil) {
        GLOBAL_DATA.rights.parentProfil = parentProfil.get("code");
      } else {
        GLOBAL_DATA.rights.parentProfil = null;
      }
      GLOBAL_DATA.rights.reset({ silent: true }); //to clean first
      if (GLOBAL_DATA.rights.length > 0 && GLOBAL_DATA.rights.models && !CWSTR.isBlank(GLOBAL_DATA.rights.models[0].cid)) {
        GLOBAL_DATA.rights.fetch({
          data: { codedeleg: codedeleg },
          success: (): void => {
            const $icon = this.$el.find(".phx-profiles-first-icon span");
            const $iconProfil = this.$el.find(".cw-valider-icon");
            let $liProfil = null;

            // Clear classes and set again the identifier class
            $icon.removeClass();
            $iconProfil.hide();
            if (_.isEmpty(GLOBAL_DATA.rights.profile)) {
              $liProfil = this.$el.find("#tousli");
            } else {
              let idSelector: string = GLOBAL_DATA.rights.profile;

              idSelector = UTILS.escapeJQueryString(idSelector);
              $liProfil = this.$el.find("#" + idSelector);
            }
            $liProfil.find(".cw-valider-icon").first().show();
            if (CWSTR.isBlank(element) === false) {
              if (element.get("principal") === true) {
                $icon.addClass("ui-phx-icon-metier ui-phx-profil-principal");
              } else if (CWSTR.isBlank(userId) === false) {
                $icon.addClass("ui-phx-icon-metier ui-phx-profil-recu-en-delegation");
              } else if (CWSTR.isBlank(parentId) === false) {
                $icon.addClass("ui-phx-icon-metier ui-phx-appartient-a-une-hierarchie");
              }
              this.$el.find(".phx-profiles-menu-header").text(element.get("libelle"));
              //this.$el.find(".phx-profiles-tous-check .tous").val([false]);
              if (showTooltip === true) {
                const $title = $("<div>");
                const $text = $("<span>");

                $title.append(i18n.t('profilutilisateur.profilEnCours') + " : ");
                // Set text
                if (CWSTR.isBlank(parentId) === false) {
                  $text.text(parentProfil.get("libelle"));
                } else {
                  $text.text(element.get("libelle"));
                }
                $title.append($text);
                this.$el.find(".phx-profiles-menu-header").attr("title", $title.html());
              } else {
                this.$el.find(".phx-profiles-menu-header").attr("title", "");
              }
            } else {
              this.$el.find(".phx-profiles-menu-header").attr("title", "");
              this.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1897', { "1": i18n.t('profilutilisateur.restotous') }));
            }
            GLOBAL_DATA.rights.trigger("updateTabs", (revert === true), (): void => {
              if (!CWSTR.isBlank(objs.populationMenu)) {
                objs.populationMenu.repaintPopulation();
              }
            });
          },
          avoidNavigation: revert === true
        });
      }
    }
  }


  private _hideDefaultButton(): void {
    this.$el.find(".defaut").addClass("ui-state-disabled");
  }

  private _showDefaultButton(): void {
    this.$el.find(".defaut").removeClass("ui-state-disabled");
  }

  private _changeProfilEvent(event: JQueryEventObject): void {
    event.stopPropagation();
    this._closeUserMenu();

    const code = $(event.target).attr("data-value");
    const type = $(event.target).attr("data-type");
    const parentId = $(event.target).attr("data-parentId");
    const userId = $(event.target).attr("data-userId");
    const codedeleg = $(event.target).attr("data-codedeleg");
    const selected = {
      code: code,
      type: type,
      parentId: parentId,
      userId: userId,
      codedeleg: codedeleg
    };

    if ($(event.target).hasClass("ui-phx-ihm-texte-donnees-attenue")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (_.isEqual(this.currentSelected, selected) === false) {
      this.oldSelected = UTILS.cloneObj(this.currentSelected);
      this.currentSelected.code = code;
      this.currentSelected.type = type;
      this.currentSelected.parentId = parentId;
      this.currentSelected.userId = userId;
      this._changeProfil(code, type, parentId, userId, undefined, codedeleg);
    }

  }

  private _revertSelection(): void {
    if (CWSTR.isBlank(this.oldSelected.code)) {
      this.currentSelected = {};
      //this.$el.find(".tous.phx-bistate-box").prop("checked", true);
      this._changeProfil(null, null, null, null, true);
    } else {
      const code = this.oldSelected.code;
      const type = this.oldSelected.type;
      const parentId = this.oldSelected.parentId;
      const userId = this.oldSelected.userId;

      this.currentSelected = UTILS.cloneObj(this.oldSelected);
      this._changeProfil(code, type, parentId, userId, true);
    }
  }

  private _collapse(event: JQueryEventObject): void {
    const parent = $(event.target).parents(".ui-menu-item").first();
    parent.find(".phx-profle-enfants").hide();
    const icons = parent.find(".cw-treenode-collapsable");
    icons.removeClass("cw_treenode-collapsable");
    icons.addClass("cw-treenode-expandable");
    this._checkHeight();
  }

  private _expand(event: JQueryEventObject): void {
    const parent = $(event.target).parents(".ui-menu-item").first();
    parent.find(".phx-profle-enfants").first().show();
    $(event.target).removeClass("cw-treenode-expandable");
    $(event.target).addClass("cw-treenode-collapsable");
    this._checkHeight();
  }

  private _setAsDefault(): void {
    this._closeUserMenu();

    // TOUS_LES_PROFILS
    const currentDefault = DIVERS.get("prfUti");
    let newDefault = this.currentSelected.code;

    // if it has parent the default it its parent
    if (CWSTR.isBlank(this.currentSelected.parentId) === false) {
      newDefault = this.currentSelected.parentId;
    }

    if (CWSTR.isBlank(newDefault)) {
      newDefault = "TOUS_LES_PROFILS";
    }
    if (currentDefault !== newDefault) {
      (GLOBAL_DATA.paramDivers as any).modifyParamForUser("prfUti", newDefault, "profilutilisateur");
      objs.appRt.workflow.configuration.set("profildefaut", newDefault);
    }
  }

  private _checkHeight(): void {
    if (!$(this.el).find('.phx-profiles-menu-list').is(':visible')) {
      return;
    }

    const windowHeight = $(window).height();
    const listHeight = $(this.el).find('.phx-profiles-menu-list').innerHeight();
    const listOffset = $(this.el).find('.phx-profiles-menu-list').position();
    const listExtra = 25;

    if (windowHeight < listHeight + listOffset.top + listExtra) {
      const newHeight = windowHeight - listOffset.top - listExtra;
      $(this.el).find('.phx-profiles-menu-list').css({
        maxHeight: newHeight
      });
    } else {
      $(this.el).find('.phx-profiles-menu-list').css({
        maxHeight: 'none'
      });
    }

  }
}
