import _ from 'underscore';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSaisieOptionsModel } from './cwSaisieOptions.model';

/**
 * Collection for ComplementComboColl
 */

export class CWTypeSaisieOptionsColl<TModel extends CWSaisieOptionsModel = CWSaisieOptionsModel> extends CWPaginatedCollection<TModel>{
  activite: string | number | boolean;

  constructor(models?: CWSaisieOptionsModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWSaisieOptionsModel;
    super(models, options);
    this.paginated = false;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/utilevenement/modesaisie/typesaisie?activite=" + encodeURIComponent(this.activite);
    }
  }

}