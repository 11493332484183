module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="gererabsencesouhait-detail gererabsence-content c-gererAbsenenceContent">\n    <div class="form-group phx-formErrors ui-state-error"></div>\n    <div class="gererabsence-form-inputs">\n        <span class="journeeSimulated ui-phx-plan-simule-en-cours" style="display:none"></span>\n        <span class="c-gestionCollab cw-titreLight"></span>\n        <div class="c-gererAbsenenceContent__form">\n            <div class="form-group">\n                <label for="motif.code" class="c-labelComposed cw-libelleEnrichi">\n                    '+
((__t=( UTILS.getSVGIcon("etiquette", "", 16) ))==null?'':__t)+
'\n                    <span class="c-textLabel cw-required">\n                        '+
((__t=(i18n.t("common:gererabsencesouhait.motif")))==null?'':__t)+
'\n                    </span>\n                    <span class="gererabsencesouhait-justifiObli cw-texteAllege--selectionne"></span>\n                    <span class="gererabsencesouhait-iconHelp">'+
((__t=( UTILS.getSVGIcon("aide_bulle", "", 16) ))==null?'':__t)+
'</span>\n                </label>\n                <span class="cmbMotif"></span>\n            </div>\n            <div class="info_soldesabsence" style="display: none;">\n                <div class="form-row col row m-0">\n                    <div class="form-group col-4">\n                        <label class="cw-texteAllege cw-libelleEnrichi justify-content-center labelTitle">'+
((__t=(i18n.t("common:soldesabsences.droit")))==null?'':__t)+
'</label>\n                        <label class="droit ui-phx-ihm-texte-donnees-important d-flex justify-content-center"></label>\n                    </div>\n                    <div class="form-group col-4">\n                        <label class="cw-texteAllege cw-libelleEnrichi justify-content-center labelTitle">'+
((__t=(i18n.t("common:soldesabsences.pris")))==null?'':__t)+
'</label>\n                        <label class="pris ui-phx-ihm-texte-donnees-important d-flex justify-content-center"></label>\n                    </div>\n                    <div class="form-group col-4">\n                        <label class="cw-texteAllege cw-libelleEnrichi justify-content-center labelTitle">'+
((__t=(i18n.t("common:soldesabsences.solde")))==null?'':__t)+
'</label>\n                        <label class="solde ui-phx-ihm-texte-donnees-important d-flex justify-content-center"></label>\n                    </div>\n                </div>\n            </div>\n            <div class="gererabsencesouhait-dates form-group">\n                <div class="form-row paddingRight">\n                    <div class="col m-0">\n                        <label class="cw-required cw-libelleEnrichi">\n                            '+
((__t=( UTILS.getSVGIcon("periode", "", 16) ))==null?'':__t)+
'\n                            '+
((__t=(i18n.t("common:gererabsencesouhait.periode")))==null?'':__t)+
'\n                        </label>\n                        <div class="row no-gutter marginRight">\n                            <div class="form-group col">\n                                <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gererabsencesouhait.du")))==null?'':__t)+
'</label>\n                                <div class="c-date-selector input-group">\n                                    <div class="input-group-prepend">\n                                        <div for="datedeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gererabsencesouhait.du")))==null?'':__t)+
'</div>\n                                    </div>\n                                    <input type="text" class="datedeb typeDate periodStart form-control required" periodId="1" value="" size="17" title="'+
((__t=(i18n.t('common:gererabsencesouhait.datedebut_title')))==null?'':__t)+
'" />\n                                </div>\n                            </div>\n                            <div class="form-group col paddingRight">\n                                <label for="unitedeb.code" class="unitedeb sr-only"></label>\n                                <span class="cmbUniteDebut" title="'+
((__t=(i18n.t('common:gererabsencesouhait.unitedebut_title')))==null?'':__t)+
'" class="required"></span>\n                            </div>\n                        </div>\n                        <div class="row no-gutter marginRight">\n                            <div class="form-group col">\n                                <label for="datefin" class="sr-only">'+
((__t=(i18n.t("common:gererabsencesouhait.au")))==null?'':__t)+
'</label>\n                                <div class="c-date-selector input-group">\n                                    <div class="input-group-prepend">\n                                        <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gererabsencesouhait.au")))==null?'':__t)+
'</div>\n                                    </div>\n                                    <input type="text" class="datefin typeDate periodEnd form-control required" periodId="1" value="" size="17" title="'+
((__t=(i18n.t('common:gererabsencesouhait.datefin_title')))==null?'':__t)+
'" />\n                                </div>\n                            </div>\n                            <div class="form-group col paddingRight">\n                                <label for="unitefin.code" class="unitefin sr-only"></label>\n                                <span class="cmbUniteFin" title="'+
((__t=(i18n.t('common:gererabsencesouhait.unitefin_title')))==null?'':__t)+
'" class="required"></span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class="heure form-row">\n                    <div class="form-group col">\n                        <label for="heuredeb" class="sr-only">'+
((__t=(i18n.t("common:gererabsencesouhait.de")))==null?'':__t)+
'</label>\n                        <div class="input-group">\n                            <div class="input-group-prepend">\n                                <div for="heuredeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gererabsencesouhait.de")))==null?'':__t)+
'</div>\n                            </div>\n                            <input class="heuredeb form-control typeHourMinuteNightly" value="" type="text" size="12" title="'+
((__t=(i18n.t('common:gererabsencesouhait.heuredebut_title')))==null?'':__t)+
'">\n                        </div>\n                    </div>\n\n                    <div class="form-group col">\n                        <label for="heurefin" class="sr-only">'+
((__t=(i18n.t("common:gererabsencesouhait.a")))==null?'':__t)+
'</label>\n                        <div class="input-group">\n                            <div class="input-group-prepend">\n                                <div for="heurefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gererabsencesouhait.a")))==null?'':__t)+
'</div>\n                            </div>\n                            <input class="heurefin form-control typeHourMinuteNightly" value="" type="text" size="12" title="'+
((__t=(i18n.t('common:gererabsencesouhait.fin_title')))==null?'':__t)+
'">\n                        </div>\n                    </div>\n\n                    <div class="form-group col">\n                        <label for="duree" class="sr-only">'+
((__t=(i18n.t("common:gererabsencesouhait.duree")))==null?'':__t)+
'</label>\n                        <div class="input-group">\n                            <div class="input-group-prepend">\n                                <div for="duree" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gererabsencesouhait.duree")))==null?'':__t)+
'</div>\n                            </div>\n                            <input class="duree form-control typeDurationHM" value="" type="text" size="12" title="'+
((__t=(i18n.t('common:gererabsencesouhait.duree_title')))==null?'':__t)+
'">\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class="originActif form-group">\n                <label for="origine.code" class="cw-libelleEnrichi">\n                    '+
((__t=( UTILS.getSVGIcon("personnage", "", 16) ))==null?'':__t)+
''+
((__t=(i18n.t("common:gererabsencesouhait.origine")))==null?'':__t)+
'\n                </label>\n                <span class="comboOrigine"></span>\n            </div>\n            <div class="gererabsencesouhait-infocomp"></div>\n            <div class="form-row marginRight">\n                <div class="blockCommentaire col form-group">\n                    <label for="commentaire" class="cw-libelleEnrichi">\n                        '+
((__t=( UTILS.getSVGIcon("bulle", "", 16) ))==null?'':__t)+
'\n                        '+
((__t=(i18n.t("common:gererabsencesouhait.comment")))==null?'':__t)+
'\n                    </label>\n                    <textarea class="commentaire form-control" maxlength="200" cols="36" rows="7" style="white-space: pre-wrap;" title="'+
((__t=(i18n.t('common:gererabsencesouhait.comment_title')))==null?'':__t)+
'"></textarea>\n                </div>\n                <div class="gererabsencesouhait-piecesjointes form-group col"></div>\n            </div>\n\n\n\n            <span class="gererabsencesouhait-wkfhisto"></span>\n            <!--span class="gererabsencesouhait-evenamentmessage"></span-->\n            <span class="gererabsencesouhait-IndicateurPermut"></span>\n\n\n        </div>\n        <div class="unselectedElementText">\n            <label>'+
((__t=(i18n.t("common:gererabsencesouhait.unselectedElementText")))==null?'':__t)+
'</label>\n            <ul>\n                <li>\n                    <div class="textAccent1">'+
((__t=(i18n.t("common:gererabsencesouhait.ajouter")))==null?'':__t)+
'</div>\n                    <span>'+
((__t=(i18n.t("common:gererabsencesouhait.text_ajouter")))==null?'':__t)+
'</span>\n                </li>\n                <li class="consulterText">\n                    <div class="textAccent1">'+
((__t=(i18n.t("common:gererabsencesouhait.consulter")))==null?'':__t)+
'</div>\n                    <span>'+
((__t=(i18n.t("common:gererabsencesouhait.text_consulter")))==null?'':__t)+
'</span>\n                </li>\n            </ul>\n        </div>\n\n    </div>\n    <div class="c-gererAbsenenceContent__btnBar">\n        <ul class="validerListMenu menuContent"></ul>\n    </div>\n    <ul class="nouveauListMenu menuContent" style="display:none">\n        <li tabindex="0">\n            <a class=\'menuItem text-uppercase\' data-value=\'absenceButton\'>\n                '+
((__t=(i18n.t("common:gererabsencesouhait.nouveauAbsenceButton")))==null?'':__t)+
'\n            </a>\n        </li>\n        <li tabindex="0">\n            <a class=\'menuItem text-uppercase\' data-value=\'souhaitButton\'>\n                '+
((__t=(i18n.t("common:gererabsencesouhait.nouveauSouhaitButton")))==null?'':__t)+
'\n            </a>\n        </li>\n    </ul>\n</div>';
}
return __p;
};
