import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import TPLAppMenu from '../ctimeMenu.tpl.html';
import TPLAppMenuItemNiv1 from '../ctimeMenuItemNiv1.tpl.html';
import TPLAppMenuItemNiv2 from '../ctimeMenuItemNiv2.tpl.html';
import TPLAppMenuItemSection from '../ctimeMenuItemSection.tpl.html';
import TPLAppMenuNiv1 from '../ctimeMenuItem.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { GmSituationView } from 'common/gmsituation/views/gmSituation.view.js';
import { LOG } from 'utils/log.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

/**
 * ZoneMenu View
 *
 * Contain the menus specific to a zone
 *
 */
export class CWZoneMenuView extends Backbone.View<CWBaseModel> {

  templateNiv1: any;
  templateItemNiv1: any;
  templateItemNiv2: any;
  templateItemSection: any;
  cMenu: { [key: string]: any };
  zJSON: any;

  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.events = {
      "click a": "_clickOption",
      "click #cw-menu *": "_clickMenu",
      "keyup .ctime-menu-submenu-title": "_keyboardToggle"
    };
    super(options);
    this.template = TPLAppMenu;
    this.templateNiv1 = TPLAppMenuNiv1;
    this.templateItemNiv1 = TPLAppMenuItemNiv1;
    this.templateItemNiv2 = TPLAppMenuItemNiv2;
    this.templateItemSection = TPLAppMenuItemSection;
    this.zJSON = options.zJSON;
    this.className = "ctime-menu-zone";
    this.listenTo(GLOBAL_DATA.rights, "sync", this._reloadNavigation);
    this.cMenu = {};
    this.model.on("change:zone", this._updateSelectedItem, this);
    this.model.on("change:usecase", this._updateSelectedItem, this);
  }

  render(): CWZoneMenuView {
    let firstdrawnelement = true;
    let json = {};

    //Create the DOM for the menu
    _.each(this.zJSON.menus, (menu: { [key: string]: any }, index): void => {
      let zid, type;

      if (menu.aspect !== "hidden") {
        let tabindex = "-1";
        let lIcone = UTILS.getSVGIcon(menu.icone.slice(0, -4));

        if (menu.id) {
          if (firstdrawnelement) {
            tabindex = "0";
          }
          tabindex = "0";
          firstdrawnelement = false;
          if (menu.href) {
            // The link specifies the url itself, so it's not a usecase
            // of the app. It will be opened in a new navigator tab
            menu.target = menu.id;
            // menu.id = "";
          } else {
            // It's a usecase inside the application
            zid = this.zJSON.id;
            type = menu.typ || "uc"; // If it doesn't have type is an uc.
            menu.href = "#z/" + zid + "/" + type + "/" + menu.id;
            menu.target = "";
          }
          //json = { menu: menu, index: index, tabindex: tabindex , classIcone : menu.icone.slice(0,-4)};
          json = { "menu": menu, "index": index, "tabindex": tabindex, "icone": (lIcone) ? lIcone : "&nbsp;" };
          this.$el.append(this.templateNiv1(json));
        } else {
          if (menu.nom) {
            let $menuDom: JQuery = null;

            if (firstdrawnelement) {
              tabindex = "0";
            } else {
              tabindex = "-1";
            }
            tabindex = "0";
            firstdrawnelement = false;
            json = { UTILS: UTILS, nom: menu.nom, alt: menu.alt, index: index, emid: menu.emid, tabindex: tabindex, "icone": (lIcone) ? lIcone : "", "zone": this.zJSON.id };
            this.$el.append(this.template(json));
            this.$el.find("#cw-menu-item-title-" + menu.emid).on("click", this._clickMenuItem.bind(this));
            $menuDom = $("#cw-menu-item-body-" + menu.emid, this.el);
            zid = "";
            type = "uc";
            _.each(menu.ecrans, (uc: { [key: string]: any }): void => {
              if (uc.id) {
                if (uc.href) {
                  // The link specifies the url itself, so it's not a usecase
                  // of the app. It will be opened in a new navigator tab
                  uc.target = uc.id;
                  // uc.id = "";
                } else {
                  // It's a usecase inside the application
                  zid = this.zJSON.id;
                  type = uc.typ || "uc"; // If it doesn't have type is an uc.
                  uc.href = "#z/" + zid + "/" + type + "/" + uc.id;
                  uc.target = "";
                }
                if (uc.id === "comptecollabresp") {
                  zid = this.zJSON.id;
                  type = uc.typ || "uc"; // If it doesn't have type is an uc.
                  uc.href = "#z/" + zid + "/" + type + "/suivicollab/" + uc.id;
                  uc.target = "";
                } else if (uc.id === "gmsituation") {
                  // special case where no tab will be opened and UC will be shown inside popup
                  zid = this.zJSON.id;
                  type = uc.typ || "uc"; // If it doesn't have type is an uc.
                  uc.href = "javascript:void(0)";
                  uc.target = "";
                }
                uc.classIcone = uc.icone.slice(0, -4);
                lIcone = UTILS.getSVGIcon(uc.icone.slice(0, -4));
                json = { "id": uc.id, "nom": uc.nom, "emid": uc.emid, "href": uc.href, "icone": (lIcone) ? lIcone : "" };
                $($menuDom).append(this.templateItemNiv1(json));
              } else {
                let $subMenuDom: JQuery = null;

                // add section name
                if (uc.icone.slice(0, -4) === "vide") {
                  lIcone = uc.icone.slice(0, -4);
                } else {
                  lIcone = UTILS.getSVGIcon(uc.icone.slice(0, -4));
                }
                json = { UTILS: UTILS, nom: uc.nom, alt: uc.alt, index: '-1', emid: uc.emid, tabindex: '0', parent: $menuDom.attr('id'), "icone": (lIcone && menu.emid !== "EM_00054") ? lIcone : "" };
                $($menuDom).append(this.templateItemSection(json));
                $subMenuDom = $menuDom.find("#cw-menu-item-body-" + uc.emid);
                $menuDom.find("#cw-menu-item-title-" + uc.emid).on("click", this._clickMenuItem.bind(this));
                // add section submenus
                _.each(uc.ecrans, (uc2: { [key: string]: any }): void => {
                  if (uc2.href) {
                    // The link specifies the url itself, so it's not a usecase
                    // of the app. It will be opened in a new navigator tab
                    uc2.target = uc2.id;
                    // uc2.id = "";
                  } else {
                    // It's a usecase inside the application
                    zid = this.zJSON.id;
                    uc2.href = "#z/" + zid + "/uc/" + uc2.id;
                    uc2.target = "";
                  }
                  uc2.classIcone = uc2.icone.slice(0, -4);
                  json = { "id": uc2.id, "nom": uc2.nom, "emid": uc2.emid, "href": uc2.href };
                  $($subMenuDom).append(this.templateItemNiv2(json));
                });
              }
            });
          }
        }
      }
    });
    this.$el.attr("role", "menubar");
    this.$el.attr("aria-hidden", "true");
    this._reloadNavigation();
    this._updateSelectedItem();
    return this;
  }

  /**
   * function to change navigation menu to have disabled states, visually only
   */
  _reloadNavigation(): void {
    const hiddenMenus: Array<any> = [];
    const hiddenEcrans: { [key: string]: any } = {};
    const menusEmid: string[] = [];
    const allTrue = (b: any): any => { return b };
    const handleSubMenu = (menu: { [key: string]: any }): void => {
      if (menu.id) {
        const _tmpBool = this._hideLink(menu, false);

        if (menusEmid.length > 0) { // Is into submenu
          hiddenEcrans[menusEmid[menusEmid.length - 1]].push(_tmpBool);
        } else {
          hiddenEcrans[menu.emid] = [_tmpBool];
        }
        hiddenMenus.push(_tmpBool);
      } else {
        let checkTrue = null;

        menusEmid.push(menu.emid);
        hiddenEcrans[menu.emid] = [];
        menu.ecrans.forEach(handleSubMenu, this);
        checkTrue = hiddenEcrans[menu.emid].every(allTrue);
        if (menusEmid.length > 1) { //is a submenu menu son. Needs set parent value
          if (checkTrue) {
            hiddenEcrans[menusEmid[0]].push(true);
          } else {
            hiddenEcrans[menusEmid[0]].push(false);
          }
        }
        this._hideLink(menu, checkTrue);
        menusEmid.pop();
      }
    };
    this.zJSON.menus.forEach(handleSubMenu, this);
    if (hiddenMenus.every(allTrue)) {
      this.model.trigger("hideZone", this.zJSON);
    } else {
      this.model.trigger("showZone", this.zJSON);
    }
  }

  /**
   * This functions receives the array with the required permissions to view a link at the menu.
   */
  _hideLink(menu: { [key: string]: any }, forceCheck: boolean): boolean {
    const hide = forceCheck || !GLOBAL_DATA.rights.menuVisibility.get(menu.emid);
    const identifier = menu.id || menu.emid;

    if (hide) {
      this.$el.find("." + identifier).addClass("ui-state-disabled");
    } else {
      this.$el.find("." + identifier).removeClass("ui-state-disabled");
    }
    return hide;
  }

  /*
   * This function defines navigation with keyboard by items in zoneMenu
   */
  _keyboardNav(e: JQueryEventObject, zoneId: string, isAMenu: boolean): void {
    const $currentPhxMenu = $(e.target);

    if (e.target.className.split(" ")[0] === "phx-menu") {
      if (e.keyCode === 37) { // left
        e.preventDefault();
        //Current zoneItem menu
        if ($("div", $currentPhxMenu).is(":visible")) {
          $("div", $currentPhxMenu).hide();
          $("div", $currentPhxMenu).attr("aria-hidden", "true");
        }
        if ($currentPhxMenu.prevAll('.phx-menu').first().length === 0) {
          const lastMenu = $currentPhxMenu.parent('#ctime-menu-zone-' + zoneId).find('> .phx-menu').last();

          this._selectFocusableElement(lastMenu, true, false);
        } else {
          const prevMenu = $currentPhxMenu.prevAll('.phx-menu').first();

          this._selectFocusableElement(prevMenu, true, false);
        }
      } else if (e.keyCode === 39) { // right
        e.preventDefault();
        if ($("div", $currentPhxMenu).is(":visible")) {
          $("div", $currentPhxMenu).hide();
          $("div", $currentPhxMenu).attr("aria-hidden", "true");
        }
        if ($currentPhxMenu.nextAll('.phx-menu').first().length === 0) {
          const firstMenu = $currentPhxMenu.parent('#ctime-menu-zone-' + zoneId).find('> .phx-menu').first();

          this._selectFocusableElement(firstMenu, true, false);
        } else {
          const nextMenu = $currentPhxMenu.nextAll('.phx-menu').first();

          this._selectFocusableElement(nextMenu, true, false);
        }
      } else if (e.keyCode === 9) { //tab
        const menuZone = $(e.target).closest("div#ctime-menu-zone-" + zoneId);

        // If there is a submenu being show for any item in this zoneMenu, we hide it
        _.each(menuZone.find("div.phx-menu"), (menu): void => {
          if ($("div", menu).is(":visible")) {
            $("div", menu).hide();
            $("div", menu).attr("aria-hidden", "true");
          }
        });
      } else if (isAMenu === true) {
        let $menu;

        if (e.keyCode === 13 || e.keyCode === 32) {
          let $currentTarget: JQuery<Element> = null;

          e.preventDefault();
          //enter spacebar
          $currentTarget = $(e.target);
          $($currentTarget).find("span.phx-menu-text").click();
          //focus on first submenu element
          $menu = $($currentTarget);
          $("div", $menu).find("ul").first().find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
          e.preventDefault();
        } else if (e.keyCode === 40) { //down
          e.preventDefault();
          //If drop down menu is visible we navigate to last item
          if ($currentPhxMenu.find("div.ui-menu").is(":visible")) {
            $currentPhxMenu.find("div.ui-menu").find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
          } else {
            $(e.target).find("span.phx-menu-text").click();
            //focus on first submenu element
            $menu = $(e.target);
            $("div", $menu).find("ul").first().find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
          }
        }
      } else if (isAMenu === false) {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          //enter or spacebar simulates click when this menuBar item opens directly a usecase.
          $(e.target).find("a")[0].click();
        }
      }
    }
  }

  /*This function selects a focusable element inside a  zonemenuview phx-menu element
   * The focusable element can be a link a or a span
   */
  _selectFocusableElement(rootSelector: JQuery<Element>, clickElement: boolean, selectFirstChild: boolean): void {
    if (rootSelector.find("> .phx-hover > a").length === 0) { //There is no direct link to a usecase
      rootSelector.focus();
      if (clickElement === true) {
        rootSelector.find("> span").first().click();
      }
      if (selectFirstChild === true) { //Select first child at drop/down menu
        rootSelector.find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
      }
    } else { //There is a link to a usecase
      rootSelector.focus();
    }
  }

  _menuLinksKeyboardNav(e: JQueryEventObject, zoneId: string): void {
    const currentLinkMenu = $(e.target);
    let lastMenu, prevMenu, firstMenu, nextMenu;
    let $currentPhxMenu = null

    if (e.keyCode === 38) { // up
      e.preventDefault();
      if (currentLinkMenu.prevAll('li.phx-menu-link:not(.phx-menu-separator)').first().length === 0) {
        lastMenu = currentLinkMenu.nextAll('li.phx-menu-link:not(.phx-menu-separator)').last();
        lastMenu.focus();
      } else {
        prevMenu = currentLinkMenu.prevAll('li.phx-menu-link:not(.phx-menu-separator)').first();
        prevMenu.focus();
      }
    } else if (e.keyCode === 40) { // down
      e.preventDefault();
      if (currentLinkMenu.nextAll('li.phx-menu-link:not(.phx-menu-separator)').first().length === 0) {
        firstMenu = currentLinkMenu.prevAll('li.phx-menu-link:not(.phx-menu-separator)').last();
        firstMenu.focus();
      } else {
        nextMenu = currentLinkMenu.nextAll('li.phx-menu-link:not(.phx-menu-separator)').first();
        nextMenu.focus();
      }
    }
    if (e.keyCode === 37) { // left
      e.preventDefault();
      //force click to hide current menu
      $currentPhxMenu = $(e.target).closest("div.phx-menu");
      $currentPhxMenu.find("span.phx-menu-text").click();
      //move focus to previous zoneitem
      if ($currentPhxMenu.prevAll('.phx-menu').first().length === 0) {
        lastMenu = $currentPhxMenu.parent('#ctime-menu-zone-' + zoneId).find('> .phx-menu').last();
        this._selectFocusableElement(lastMenu, true, true);
      } else {
        prevMenu = $currentPhxMenu.prevAll('.phx-menu').first();
        this._selectFocusableElement(prevMenu, true, true);
      }
    } else if (e.keyCode === 39) { // right
      e.preventDefault();
      //force click to hide current menu
      $currentPhxMenu = $(e.target).closest("div.phx-menu");
      $currentPhxMenu.find("span.phx-menu-text").click();
      //move focus to next zoneitem
      if ($currentPhxMenu.nextAll('.phx-menu').first().length === 0) {
        firstMenu = $currentPhxMenu.parent('#ctime-menu-zone-' + zoneId).find('> .phx-menu').first();
        this._selectFocusableElement(firstMenu, true, true);
      } else {
        nextMenu = $currentPhxMenu.nextAll('.phx-menu').first();
        this._selectFocusableElement(nextMenu, true, true);
      }
    } else if (e.keyCode === 27) { // escape
      const parentMenu = currentLinkMenu.closest('div.phx-menu');

      this._selectFocusableElement(parentMenu, false, false);
      //Simulate click in the menuBar in order to close drop/down menu
      parentMenu.find("> span").first().click();
    } else if (e.keyCode === 9) { //tab
      const menuZone = $(e.target).closest("div#ctime-menu-zone-" + zoneId);

      // If there is a submenu being show for any item in this zoneMenu, we hide it
      _.each(menuZone.find("div.phx-menu"), (menu): void => {
        if ($("div", menu).is(":visible")) {
          $("div", menu).hide();
          $("div", menu).attr("aria-hidden", "true");
          $("div", menu).find("ul").first().attr("aria-hidden", "true");
        }
      });
    } else if (e.keyCode === 13 || e.keyCode === 32) {
      let currentTarget: JQuery<Element> = null;

      e.preventDefault();
      //enter spacebar
      currentTarget = $(e.target);
      $(currentTarget).find("a").first()[0].click();
    }
  }

  _clickMenu(e: JQueryEventObject): void {
    const menu = $(e.target).parents("div.phx-menu");

    LOG.debug('----Click Menu-----');
    if (menu.hasClass("ui-state-disabled")) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    const index = menu.attr("data-value");
    LOG.debug("menu clicked: " + index);
    if (index !== undefined) {
      if ($("div", menu).is(":visible")) {
        $("div", menu).hide();
        $("div", menu).attr("aria-hidden", "true");
        $("div", menu).find("ul").first().attr("aria-hidden", "true");
      } else {
        this._closeAllMenus();
        $("div", menu).show();
        $("div", menu).attr("aria-hidden", "false");
        $("div", menu).find("ul").first().attr("aria-hidden", "false");
        //If you click out of the menu, close the menu.
        $(document).one("mousedown", (event: JQuery.TriggeredEvent): void => {
          const element = $(this.el).find(event.target);

          if (element.length === 0 || $(event.target).parents('.bvMenu').length) { //2nd cond: if some of bvMenu buttons is pressed, close this and only show bvMenu
            $("div", menu).hide();
            $("div", menu).attr("aria-hidden", "true");
            $("div", menu).find("ul").first().attr("aria-hidden", "true");
          }
        });
      }
    }
  }

  _isModeReduced(): boolean {
    return $("#cw-menu").hasClass("modeReduced");
  }

  _clickOption(event: JQueryEventObject): void {
    const $menu = $(event.currentTarget);

    if (this._isModeReduced()) {
      if ($menu.hasClass("ui-state-disabled")) {
        event.preventDefault();
        event.stopPropagation();
      }
      return;
    }
    if ($menu.hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    // special case where no tab will be opened and UC will be shown inside popup
    if ($menu.hasClass("gmsituation")) {
      this._gmsituation();
    } else {
      this.$el.find('.cw-menu-item-title').removeClass('cw-menu-item-selected');
      $menu.parents(".cw-menu-item-title").addClass("cw-menu-item-selected");
    }
    // closes all menus
    this._closeAllMenus();
  }

  _closeAllMenus(): void {
    _.each(this.zJSON.menus, (menu, index): void => {
      $("div[data-value=" + index + "] div", this.el).hide();
      $("div[data-value=" + index + "] div", this.el).attr("aria-hidden", "true");
    });
  }

  // special case where no tab will be opened and UC will be shown inside popup
  _gmsituation(): void {
    // load dependencies to component
    objs.gmsituation = new GmSituationView();
    objs.gmsituation.open();
  }

  _keyboardToggle(event: any): void {
    const selector = event.currentTarget.getAttribute('data-target');

    if (event.keyCode === 13 || event.keyCode === 32) {
      const DATA_KEY = 'bs.collapse';
      const config = "toggle";
      const $this = $(selector);
      let data = $this.data(DATA_KEY);

      if (!data) {
        ($this as any).collapse();
        data = $this.data(DATA_KEY);
      }
      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError("No method named \"" + config + "\"");
        }
        data[config]();
      }
    }
  }

  _clickMenuItem(event: JQueryEventObject): void {
    const $lPos = $(event.currentTarget);

    if (this._isModeReduced()) {
      if ($lPos.hasClass("ui-state-disabled")) {
        event.preventDefault();
        event.stopPropagation();
      }
      return;
    }
    this._toggleCollapseItem($lPos, true);
  }

  _toggleCollapseItem($target: JQuery<Element>, toggle: boolean): void {
    let $lExpanded = $target.attr("aria-expanded") === "true";
    const $lFindIcone = $target.find(".cw-menu-arrow");
    const $lAllSubMenu = $target.parent().find("[id^='cw-menu-item-title-']");

    if (toggle) {
      $lExpanded = !$lExpanded;
    }
    if ($lAllSubMenu.length > 0) {
      _.each($lAllSubMenu, (menu): void => {
        const $lAllSubMenuIcone = $(menu).find(".cw-menu-arrow");

        if (menu && $(menu).attr("aria-expanded") === "true") {
          if ($lAllSubMenuIcone.length > 0) {
            $lAllSubMenuIcone.filter(".cw-menu-arrow-up").show();
            $lAllSubMenuIcone.filter(".cw-menu-arrow-down").hide();
          }
        } else {
          if ($lAllSubMenuIcone.length > 0) {
            $lAllSubMenuIcone.filter(".cw-menu-arrow-up").hide();
            $lAllSubMenuIcone.filter(".cw-menu-arrow-down").show();
          }
        }
      });
    }
    if ($lFindIcone.length > 0) {
      if (!$lExpanded) {
        $lFindIcone.filter(".cw-menu-arrow-up").hide();
        $lFindIcone.filter(".cw-menu-arrow-down").show();
      } else {
        $lFindIcone.filter(".cw-menu-arrow-down").hide();
        $lFindIcone.filter(".cw-menu-arrow-up").show();
      }
    }
  }

  _updateSelectedItem(): void {
    const zone = this.model.get("zone");
    const usecase = this.model.get("usecase");

    if (usecase === "gmsituation") {
      return;
    }
    this.$el.find(".cw-menu-item-title")
      .removeClass("cw-menu-item-selected")
      .removeClass("cw-menu-item-group-selected");
    if (!CWSTR.isBlank(usecase)) {
      let $selectedItem = null;

      if (usecase.indexOf("_R") >= 0) {
        $selectedItem = this.$el.find("[data-ecran=" + usecase.slice(0, -2) + "]"); //p.exemple, "agenda_R"
      } else {
        $selectedItem = this.$el.find("[data-ecran=" + usecase + "]");
      }
      if ($selectedItem.length > 0) {
        $selectedItem.addClass("cw-menu-item-selected");

        const $parentGroup = $selectedItem.parents(".cw-menu-group");
        ($parentGroup as any).collapse('show');

        const $parentItem = $parentGroup.prev(".cw-menu-item-title");
        $parentItem.addClass("cw-menu-item-group-selected");
        this._toggleCollapseItem($parentItem, false);
      }
    } else if (!CWSTR.isBlank(zone)) {
      this.$el.find("[data-ecran=" + zone + "]").addClass("cw-menu-item-selected");
    }
  }
}
