import { i18n } from 'src/i18n.js';
import { STR } from './str.js';
import { TYPE } from 'tda/tda.js';

/**
 * Utilities to check TREE dates
 */
export var EVENEMENT = {

  getLibelle: function(fmt, code, libelle) {
    var response = "";

    // Libellé d’événement > Format DomActFormatDef
    switch (fmt.toString()) {
      case "1": //libelle (code)
        response = libelle + " (" + code + ")";
        break;
      case "2": //code (libelle)
        response = code + " (" + libelle + ")";
        break;
      case "3": //libelle
        response = libelle;
        break;
      case "4": //code
        response = code;
        break;
      default:
        response = code + " (" + libelle + ")";
    }
    return response;
  },
  buildLineDisponibilites: function(dts) {
    var messageLine = "";
    var params = dts.param;
    var formatDatedeb = TYPE.DATE.format(params["@datedeb"], TYPE._getFormatByCode("DATE_B")) || "null";
    var formatDatefin = TYPE.DATE.format(params["@datefin"], TYPE._getFormatByCode("DATE_B")) || "null";

    if (params["@datefin"] === params["@datedeb"]) {
      messageLine = i18n.t('common:fusion_message.le') + " " + formatDatedeb;
    } else {
      messageLine = i18n.t('common:fusion_message.du') + " " + formatDatedeb + " " + i18n.t('common:fusion_message.au') + " " + formatDatefin;
    }
    if (!STR.isBlank(params["@varcharl1"])) {
      messageLine += ", " + params["@libmotif"] + " (" + params["@motif"] + ")";
    }
    if (!STR.isBlank(params["@disponible"])) {
      messageLine += ", " + i18n.t('common:fusion_message.disponible');
    }
    return messageLine;
  },

  buildLinePrets: function(dts) {
    var messageLine = "";
    var periode = "";
    var params = dts.param;
    var partieJournee = "";
    var rattachement = "";

    switch (params["@typeper"]) {
      case "D":
        periode = i18n.t('common:fusion_message.le') + " <span class='bold'>" + TYPE.DATE.format(params["@datedeb"], TYPE._getFormatByCode("DATE_A")) + "</span>";
        break;
      case "R":
        periode = i18n.t('common:fusion_message.du') + " <span class='bold'>" + TYPE.DATE.format(params["@datedeb"], TYPE._getFormatByCode("DATE_A")) + "</span> " + i18n.t('common:fusion_message.au') + " <span class='bold'>" + TYPE.DATE.format(params["@datefin"], TYPE._getFormatByCode("DATE_A")) + "</span>";
        break;
      case "P":
        var deb = null,
          fin = null;

        if (params["@per_united"] === "H") {
          deb = TYPE.HOUR_MINUTE.format(params["@heuredeb"]);
        } else {
          deb = params["@libunited"];
        }
        if (params["@per_unitef"] === "H") {
          fin = TYPE.HOUR_MINUTE.format(params["@heurefin"]);
        } else {
          fin = params["@libunitef"];
        }
        periode = i18n.t('common:fusion_message.du') + " <span class='bold'>" + TYPE.DATE.format(params["@datedeb"], TYPE._getFormatByCode("DATE_A")) + "</span> " + deb +
          " " + i18n.t('common:fusion_message.au') + " <span class='bold'>" + TYPE.DATE.format(params["@datefin"], TYPE._getFormatByCode("DATE_A")) + "</span> " + fin;
        //Not default
    }
    if (params["@typeper"] === "D" || params["@typeper"] === "R") {
      switch (params["@methsaiid"]) {
        case "PLH":
          partieJournee = TYPE.HOUR_MINUTE.format(params["@per_hd"]) + " - " + TYPE.HOUR_MINUTE.format(params["@per_hf"]);
          break;
        case "UNITE":
          partieJournee = params["@unitesaiid"];
          break;
        case "DUREE":
          partieJournee = TYPE.DURATION.HOUR_MINUTE.format(params["@duree"]);
          break;
        case "POURCENT":
          partieJournee = params["@pourcent"] + "%";
          //Not default
      }
    }
    messageLine = periode + ", " + params["@motif"] + ", " + partieJournee + ", " + rattachement;
    return messageLine;
  },

  buildLineMessages: function(dts) {
    var messageLine = "";
    var params = dts.param;
    var formatDatedeb = TYPE.DATE.format(params["@datedeb"], TYPE._getFormatByCode("DATE_B"));
    var formatDatefin = TYPE.DATE.format(params["@datefin"], TYPE._getFormatByCode("DATE_B"));

    if (params["@datefin"] === params["@datedeb"]) {
      messageLine = i18n.t('common:fusion_message.le') + " " + formatDatedeb;
    } else {
      messageLine = i18n.t('common:fusion_message.du') + " " + formatDatedeb + " " + i18n.t('common:fusion_message.au') + " " + formatDatefin;
    }
    if (!STR.isBlank(params["@varcharl1"])) {
      messageLine += ", " + params["@varcharl1"];
    }
    return messageLine;
  },

  buildLineHoraires: function(dts) {
    var text = "";
    var params = dts.param;

    if (!STR.isBlank(params["@datefin"]) && params["@datedeb"] !== params["@datefin"]) {
      //periode
      text = i18n.t('common:fusion_message.du') + " " + TYPE.DATE.format(params["@datedeb"]) + " " + i18n.t('common:fusion_message.au') + " " + TYPE.DATE.format(params["@datefin"]);
    } else {
      //non periode
      text = i18n.t('common:fusion_message.le') + " " + TYPE.DATE.format(params["@datedeb"]);
    }
    if (!STR.isBlank(params["@libmotif"])) {
      text += "," + params["@libmotif"];
    }
    return text;
  },

  buildLineBadgeages: function(dts) {
    var text = "";
    var params = dts.param;

    //non periode
    text = i18n.t('common:fusion_message.le') + " " + TYPE.DATE.format(params["@datedeb"]) + " " + i18n.t('common:fusion_message.a') + " " + TYPE.DURATION.HOUR_MINUTE.format(params["@heuredeb"]);
    if (!STR.isBlank(params["@motif"])) {
      text += "," + params["@libsens"] + " (" + params["@varcharc3"] + ")";
    }
    return text;
  },

  buildLineAbsences: function(dts) {
    var text = "";
    var params = dts.param;

    if (!STR.isBlank(params["@absDateFin"]) && params["@absDateDebut"] !== params["@absDateFin"]) { //periode
      text = i18n.t('messages:GT_1084', {
        "1": TYPE.DATE.format(params["@absDateDebut"]),
        "2": TYPE.DATE.format(params["@absDateFin"]),
        "3": params["@absLibelleMotif"]
      });
    } else {
      //non periode
      text = i18n.t('messages:GT_1083', {
        "1": TYPE.DATE.format(params["@absDateDebut"]),
        "2": params["@absLibelleMotif"]
      });
    }
    return text;
  },

  buildLineReguls: function(dts) {
    var text = "";
    var params = dts.param;
    var ob = {};

    if (!STR.isBlank(params["@datefin"]) && params["@datedeb"] !== params["@datefin"]) { //periode
      ob["1"] = TYPE.DATE.format(params["@datedeb"]);
      ob["2"] = TYPE.DATE.format(params["@datefin"]);
      ob["3"] = params["@motif"];
      if (!STR.isBlank(params["@motif"])) {
        ob["2"] = params["@libmotif"] + " (" + params["@motif"] + ")";
      }
      text = i18n.t('messages:GT_1084', ob);
    } else { //non periode
      ob["1"] = TYPE.DATE.format(params["@datedeb"]);
      ob["2"] = params["@motif"];
      if (!STR.isBlank(params["@motif"])) {
        ob["1"] = params["@libmotif"] + " (" + params["@motif"] + ")";
      }
      text = i18n.t('messages:GT_1083', ob);
    }
    return text;
  },

  buildLineAct: function(dts) {
    var desc = "";
    var params = dts.param;
    var motifLibelle = " ????? ";
    var formatHeuredeb = TYPE.HOUR_MINUTE_NIGHTLY.format(params["@heuredeb"]) || "null";
    var formatHeurefin = TYPE.HOUR_MINUTE_NIGHTLY.format(params["@heurefin"]) || "null";
    var formatDatedeb = TYPE.DATE.format(params["@datedeb"], TYPE._getFormatByCode("DATE_B")) || "null";
    var formatDatefin = TYPE.DATE.format(params["@datefin"], TYPE._getFormatByCode("DATE_B")) || "null";
    var formatDuree = TYPE.DURATION.HOUR_MINUTE.format(params["@duree"]) || "null";
    var formatHoureDuree = "";
    var mode = null;
    var modesaisie = null;

    if (!STR.isBlank(params["@motif"]) && !STR.isBlank(params["@libmotif"])) {
      motifLibelle = params["@libmotif"];
    }
    //Informations temporelles
    if (params["@typeper"] === "C") {
      var appendDesc = "";

      if (!STR.isBlank(params["@libunited"])) {
        appendDesc = params["@libunited"];
      }
      if (!STR.isBlank(params["@per_hpf"])) {
        appendDesc += params["@per_hpf"];
      }
      if (params["@methsaiid"] === "HPRES" && params["@hpres_av"] === "N" && params["@hpres_ap"] === "O") {
        appendDesc += i18n.t('common:fusion_message.fussion_apres_la');
      }
      appendDesc += " " + i18n.t('common:fusion_message.au');
      appendDesc += " " + formatDatefin;
      if (!STR.isBlank(params["@per_unitef"]) && !STR.isBlank(params["@libunitef"])) {
        appendDesc += " " + params["@libunitef"];
      }
      if (!STR.isBlank(params["@heurefin"])) {
        appendDesc += " " + params["@heurefin"];
      }
      if (params["@methsaiid"] === "HPRES" && params["@hpres_av"] === "O" && params["@hpres_ap"] === "N") {
        appendDesc += " " + i18n.t('common:fusion_message.fussion_avant_la');
      }
      desc = i18n.t('common:fusion_message.du') + " " + formatDatedeb + " " + appendDesc;
    }
    if ((params["@typeper"] === "D") && (params["@datedeb"] === params["@datefin"])) {
      modesaisie = null;
      mode = params["@methsaiid"];
      switch (true) {
        case mode === "UNITE" && !STR.isBlank(params["@unitesaiid"]) && params["@unitesaiid"] !== "D":
          modesaisie = params["@libunitesai"];
          break;
        case mode === "PLH":
          modesaisie = formatHeuredeb + " à " + formatHeurefin + " (" + formatHoureDuree + ")";
          break;
        case mode === "DUREE":
          modesaisie = "(" + formatDuree + ")";
          break;
        case mode === "RELIQUAT":
          modesaisie = i18n.t('common:fusion_message.reliquat');
          break;
        case mode === "POURCENT":
          modesaisie = params["@pourcent"] + " % ";
          break;
        case mode === "HPRES" && params["@hpres_av"] === "O" && params["@hpres_ap"] === "N":
          modesaisie = i18n.t('common:fusion_message.fussion_avant_la');
          break;
        case mode === "HPRES" && params["@hpres_av"] === "N" && params["@hpres_ap"] === "O":
          modesaisie = i18n.t('common:fusion_message.fussion_apres_la');
          break;
        case mode === "HPRES" && params["@hpres_av"] === "O" && params["@hpres_ap"] === "O":
          modesaisie = i18n.t('common:fusion_message.hors_pres');
          break;
        default:
          modesaisie = "";
      }
      desc = i18n.t('common:fusion_message.le') + " " + formatDatedeb + ", " + modesaisie;
    }
    if ((params["@typeper"] === "D") && (params["@datedeb"] !== params["@datefin"])) {
      modesaisie = null;
      mode = params["@methsaiid"];
      switch (true) {
        case mode === "UNITE" && !STR.isBlank(params["@per_united"]) && params["@per_united"] !== "D":
          modesaisie = " répétition " + params["@libunited"];
          break;
        case mode === "PLH":
          modesaisie = formatHeuredeb + " à " + formatHeurefin + " (" + formatHoureDuree + ")";
          break;
        case mode === "DUREE":
          modesaisie = "(" + formatDuree + ")";
          break;
        case mode === "RELIQUAT":
          modesaisie = i18n.t('common:fusion_message.reliquat');
          break;
        case mode === "POURCENT":
          modesaisie = params["@pourcent"] + " % ";
          break;
        case mode === "HPRES" && params["@hpres_av"] === "O" && params["@hpres_ap"] === "N":
          modesaisie = i18n.t('common:fusion_message.fussion_avant_la');
          break;
        case mode === "HPRES" && params["@hpres_av"] === "N" && params["@hpres_ap"] === "O":
          modesaisie = i18n.t('common:fusion_message.fussion_apres_la');
          break;
        case mode === "HPRES" && params["@hpres_av"] === "O" && params["@hpres_ap"] === "O":
          modesaisie = i18n.t('common:fusion_message.hors_pres');
          break;
        default:
          modesaisie = "";
      }
      desc = i18n.t('common:fusion_message.du') + " " + formatDatedeb + " " + i18n.t('common:fusion_message.au') + " " + formatDatefin + ", " + modesaisie;
    }
    desc += ", " + motifLibelle + " (" + params["@motif"] + ")";
    return desc;
  },

  buildLineRecap: function(dts) {
    var text = "";
    var params = dts.param;
    var libelle = null;
    var formatDatedeb = TYPE.DATE.format(params["@datedeb"], TYPE._getFormatByCode("DATE_B"));
    var formatDatefin = TYPE.DATE.format(params["@datefin"], TYPE._getFormatByCode("DATE_B"));

    if (!STR.isBlank(params["@libmotif"])) {
      libelle = params["@libmotif"];
    } else {
      libelle = " ????? ";
    }
    text = i18n.t('common:fusion_message.du') + " " + formatDatedeb + " " + i18n.t('common:fusion_message.au') + " " + formatDatefin + ", " + libelle + " (" + params["@motif"] + ") ";
    return text;
  }
};
