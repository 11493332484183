import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWGererRegularisationPopUpView } from '../views/cwGererRegularisationPopUp.view';
import { CWGererRegularisationView } from '../views/cwGererRegularisation.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWMotifRegulInitModel } from './cwMotifRegulInit.model';
import { CWMSGS } from 'utils/cwMsgs';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';


export class CWGererRegularisationsWorkflow extends CWReadOnlyModel {

  btnBarModel: CWBaseModel;
  pDiversIdCollabModel: Backbone.Model;
  gererRegularisations: CWGererRegularisationView;
  initWorkflow: CWMotifRegulInitModel;
  wkfActiv: boolean;
  formModel: CWBaseModel;
  parent: any;
  wkfRecupere: boolean;
  public module: string;
  public $appendTo: JQuery; //pour les messages
  context: { [key: string]: any };


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.context = options.context;
    this.module = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : null;
    this.btnBarModel = null;
    this.gererRegularisations = null;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
    this.wkfRecupere = options.wkfRecupere ?? false;
    this.wkfActiv = options.wkfActiv ?? false;
  }

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp(callback?: () => void): void {
    let lEcran = "";

    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.gererRegularisations.diver = this.pDiversIdCollabModel.get("valeur");
    this.initWorkflow = new CWMotifRegulInitModel();
    if (this.gererRegularisations && this.gererRegularisations.context && !CWSTR.isBlank(this.gererRegularisations.context.ctxEcran)) {
      lEcran = this.gererRegularisations.context.ctxEcran;
    } else if (this.formModel && this.formModel.getHabContext()) {
      lEcran = this.formModel.getHabContext().get("onglet");
    }
    this.initWorkflow.setHabContext(new CWHabilitationContext({
      onglet: lEcran,
      foncCour: "",
    }));
    if (this.gererRegularisations && !CWSTR.isBlank(this.gererRegularisations.context)) {
      const context = this.gererRegularisations.context;
      let lHabContext: CWHabilitationContext = null;

      if (!CWSTR.isBlank(context.ctxEcran)) {
        let foncC = null;

        if (!CWSTR.isBlank(context.ctxHabilitation.HabilitationAcces)) {
          foncC = context.ctxHabilitation.HabilitationAcces;
        } else if (!CWSTR.isBlank(context.ctxHabilitation.HabilitationGestion)) {
          foncC = context.ctxHabilitation.HabilitationGestion;
        }
        lHabContext = new CWHabilitationContext({
          onglet: context.ctxEcran,
          foncCour: foncC
        });
        this.initWorkflow.setHabContext(lHabContext);
      }
    }
    if (this.wkfRecupere) {
      this.trigger("initWorkflowActif", this.wkfActiv);//initialisé à false
      if (typeof callback === "function") {
        callback();
      }
    } else {
      this._fetchWorkflowOpt(callback);
    }
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.gererRegularisations.model.on("form:edited", this._formEdited, this);
  }

  _fetchWorkflowOpt(callback?: () => void): void {
    const lFuncLocal = (): void => {
      this.wkfRecupere = true; //flag pour savoir que le fetch déjà est fait ou pas (ne pas le faire deux fois)      
      if (typeof callback === "function") {
        callback();
      }
      this.trigger("initWorkflowActif", this.wkfActiv);//initialisé à false
    };

    if (CWHABILITATION.canView(this.initWorkflow.habContext.get("foncCour"))) {
      this.initWorkflow.fetch({
        success: (fresh: CWMotifRegulInitModel) => {
          // manages the options of the workflow.
          this.wkfActiv = fresh.get("wkf_activ");
          lFuncLocal();
        }
      });
    } else {
      lFuncLocal();
    }
  }

  _buttonAction(buttonId: string, profil?: { [key: string]: any }): void {
    switch (buttonId) {
      case "save":
        this.gererRegularisations.saveRegulCollab();
        if (this.parent && this.parent.workflow && this.parent.workflow.listerRegularisations && this.parent.workflow.listerRegularisations.filter) {
          this.parent.workflow.listerRegularisations.filter.model.trigger('enableFilter');
        }
        break;
      case "revert":
        this.gererRegularisations.revertView();
        if (CWSTR.isNull(this.gererRegularisations.model.get("value")) || CWSTR.isBlank(this.gererRegularisations.model.get("value").id) && !CWSTR.isBlank(this.gererRegularisations.$el)) {
          this.trigger("manageUnselected", true);
        }
        this.gererRegularisations.model.trigger("editionEnd");
        if (this.parent && this.parent.workflow && this.parent.workflow.listerRegularisations && this.parent.workflow.listerRegularisations.filter) {
          this.parent.workflow.listerRegularisations.filter.model.trigger('enableFilter');
        }
        break;
      case "new": {
        const usecase = objs.appRt.workflow.get("usecase");

        this.gererRegularisations.newRegulCollab(usecase);
        this.trigger("manageUnselected", false);
        break;
      }
      case "delete": {
        const statut = CWSTR.getElValue(this.gererRegularisations.model.get("value"), "statut.code");
        if (statut === "A" || statut === "H" || (statut === "I" && this.gererRegularisations.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
          const dialog = this._initPopUp("suppression");
          const dialogModel = dialog.model;

          dialog.open(() => {
            if (dialogModel.get("action") === "OK") {
              this.gererRegularisations.deleteRegulCollab(dialogModel.get("commentaire"));
            }
          });

        } else {
          CWMSGS.showConfirmAdapter(i18n.t('common:delconfirm'), (result: string) => {
            if (result === "Y") {
              this.gererRegularisations.deleteRegulCollab(null);
            }
          }, null, null, null, this.$appendTo);
        }
        break;
      }
      case "accepter": {
        const dialog = this._initPopUp("accepter");
        const dialogModel = dialog.model;
        const validationProfil = profil;

        dialog.open(() => {
          if (dialogModel.get("action") === "OK") {
            this.gererRegularisations.accepterRegulCollab(dialogModel.get("commentaire"), validationProfil);
          }
        });
        break;
      }
      case "refuser": {
        const dialog = this._initPopUp("refuser");
        const dialogModel = dialog.model;
        const validationProfil = profil;

        dialog.open(() => {
          if (dialogModel.get("action") === "OK") {
            this.gererRegularisations.refuserRegulCollab(dialogModel.get("commentaire"), validationProfil);
          }
        });
        break;
      }
    }
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("disable:accepter");
    this.btnBarModel.trigger("disable:refuser");
    if (this.formModel.getHabContext().get("natGest") !== "A") {
      this.formModel.updateHabContext({ foncCour: this.gererRegularisations.context.ctxHabilitation.HabilitationGestion, natGest: "M" });
    }
  }

  _initPopUp(act: string): CWDialogPopupView {
    const stat = this.gererRegularisations.model.get("value").get("statut").code;
    const etat = this.gererRegularisations.model.get("value").get("etat");
    const ctxUtilisateur = this.gererRegularisations.context.ctxUtilisateur;
    const dialog = new CWDialogPopupView({
      view: CWGererRegularisationPopUpView,
      viewData: {
        action: act,
        statut: stat,
        etat: etat,
        utilisateur: ctxUtilisateur,
        appendTo: this.$appendTo
      }
    });
    dialog.setHeight('auto');
    dialog.setWidth(750);
    return dialog;
  }
}
