import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { objs } from 'src/objectsRepository';


export class CWPreferenceUtilisateurExiste extends CWBaseModel {

  notFecth: boolean; //Pour éviter de faire des appels supplémentaires

  defaults(): { [key: string]: any } {
    return {
      "preference": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.habilitationV = options.habilitationV ? options.habilitationV : "RES_GCOLLAB.G";
    options.habilitationG = options.habilitationG ? options.habilitationG : "RES_GCOLLAB.G";
    options.canViewTreatment = options.canViewTreatment ? options.canViewTreatment : true;
    options.usecase = options.usecase ? options.usecase : objs.appRt.workflow.get("usecase");
    super(attributes, options);
    this.notFecth = false;
    this.url = (): string => {
      let lRtn = null;

      switch (options.usecase) {
        case "planresp":
          lRtn = Configuration.restRoot + "/rest/planning/preference/utilisateur";
          break;
        case "planmed":
          lRtn = Configuration.restRoot + "/rest/planning/preference/utilisateur?medical=true";
          break;
        case "tabservind":
          lRtn = Configuration.restRoot + "/rest/planning/tabservice/preference/utilisateur";
          break;
        case "tabservcoll":
          lRtn = Configuration.restRoot + "/rest/planning/tabservicecoll/preference/utilisateur";
          break;
        case "tabgardes":
          lRtn = Configuration.restRoot + "/rest/preferences/generique/utilisateur?themes=TG_COLL&ctxAcces=C";
          break;
        case "plequipe":
          lRtn = Configuration.restRoot + "/rest/planning/monequipe/preference/utilisateur";
          break;
        default:
          break;
      }
      return lRtn;
    }
  }
}
