import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Model for accept, reject or delete
 */
export class CWGererPretsGestion extends CWBaseModel {

  canViewTreatment: boolean;
  action: string;

  defaults(): { [key: string]: any } {
    return {
      "commentaire": "",
      "droit": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.canViewTreatment = true;
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);

      return Configuration.restRoot + "/rest/composant/pret/" + this.action + "/" + id;
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    if (!CWSTR.isBlank(this.action) && this.action === "refuser") {
      if (CWSTR.isBlank(attrs.commentaire)) {
        errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gererprets.comment') });
      }
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}
