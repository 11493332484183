module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="gererabsencesouhait-popup cw-fieldset">\n    <div class="phx-formTable">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div class="form-group">\n            <label for="commentaire">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t("common:gererabsencesouhait.comment")))==null?'':__t)+
'</label>\n            <textarea class="commentaire form-control" maxlength="200" cols="30" rows="4"></textarea>\n        </div>\n        <div class="gererabsencesouhait-popup-justifier custom-control custom-checkbox">\n            <input id="justiok" type="checkbox" class="justifok custom-control-input" name="justiok" value="true" />\n            <label for="justiok" class="custom-control-label">'+
((__t=(i18n.t("common:gererabsencesouhait.commentaire_justificatif_verifie")))==null?'':__t)+
'</label>\n        </div>\n        <div class="gererabsencesouhait-buttons form-group">\n            <button type="button" class="btnOK btn btn-primary btn-withIcon" value="btnOK">'+
((__t=(i18n.t("common:gererabsencesouhait.btnOK")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('valider','',16)))==null?'':__t)+
'</button>\n            <button type="button" class="btnAnnuler btn btn-secondary btn-withIcon" value="btnAnnuler">'+
((__t=(i18n.t("common:gererabsencesouhait.btnAnnuler")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('croix','',16)))==null?'':__t)+
'</button>\n        </div>\n    </div>\n</fieldset>\n';
}
return __p;
};
