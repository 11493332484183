import { i18n } from 'src/i18n.js';
import { ReadOnlyModel } from '../../models/readOnly.model.js';
import { STR } from 'utils/str.js';
import { LOG } from 'utils/log.js';
import TPL_common_table_main_menu from './table_main_menu.tpl.html';

export var MainChooserView = Backbone.View.extend({

  className: "phx-grid-nemu ui-grid-th",

  /**
   * View of the menu used to select a view for the table and to toggle the columns.
   */
  initialize: function(options) {
    this.template = TPL_common_table_main_menu;
    this.model = new ReadOnlyModel({
      defaults: {
        mode: ""
      }
    });
    this.dataGrid = options.dataGrid;
    /**
     * Visible columns for this table view
     */
    this.visible = [];
    /**
     * Invisible columns for this table view
     */
    this.invisible = [];

    this.listenTo(this.dataGrid.model, "toggle:column", this._manageIcons);
    this.listenTo(this.dataGrid.model, "lock:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unlock:column", this._showMenuOption);
    this.listenTo(this.dataGrid.model, "block:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unblock:column", this._showMenuOption);
    this.listenTo(this.dataGrid.model, "buttonVueChanged:click", this._changeVueSelected);
    // common class prefix
    this.classPrefix = "phx-grid-menu-";

  },

  /**
   * Event when click on triangle to toggle menu.
   */
  /**
   * Event when click on a link to select the column.
   */
  /**
   * Event when click on a link to select a view.
   */
  /**
   * Event when click on a link to select a option in the main menu (selectionner vue o seleccionner column)
   */
  events: {
    "click .phx-hd-main-btn > button": "_toggleMenu",
    "click .phx-hd-main-btn  ul.phx-grid-column-chooser li  a": "_selectColumn",
    "click .phx-hd-main-btn  ul.phx-grid-vues-chooser li  a": "_selectVue",
    "click .phx-hd-main-btn > ul > li > a": "_selectOption"
  },
  /**
   *
   * Manage the icon status of the menu to see what columns are displayed or not.
   */
  _manageIcons: function(columnName) {
    this.subMenuCols.find("." + this.classPrefix + columnName.replace(".", "\\.") + " span").toggleClass("phx-icon-planresp-valider");
    this.subMenuCols.find("." + this.classPrefix + columnName.replace(".", "\\.") + " span").toggleClass("none");
    this.dataGrid.currentVue._columns[columnName].radioOff = !this.dataGrid.currentVue._columns[columnName].radioOff;
  },

  /**
   * Show the column in the menu.
   */
  _showMenuOption: function(columnName) {
    this.subMenuCols.find("li ." + this.classPrefix + columnName.replace(".", "\\.")).parent().show();
    this.dataGrid.currentVue._columns[columnName].omitMenu = false;

  },

  /**
   * Hide a column in the menú.
   */
  _hideMenuOption: function(columnName) {
    this.subMenuCols.find("li ." + this.classPrefix + columnName.replace(".", "\\.")).parent().hide();
    if (this.dataGrid.currentVue._columns[columnName]) {
      this.dataGrid.currentVue._columns[columnName].omitMenu = true;
    } else {
      LOG.warn("error, you try to hide the column without existing");
    }

  },
  /**
   * Initiallize visibility information
   */
  resetVisibility: function() {
    this.visible = [];
    this.invisible = [];
  },

  render: function() {

    var dynamic = { text: "", "i18nCom": i18n };
    $(this.el).html(this.template(dynamic));
    $("button", this.el).button({
      icons: {
        primary: "ui-icon-triangle-1-s"
      },
      text: false
    });

    this.menu = this._configureMenu();
    this.menu.hide();

    return this;
  },

  /**
   * Add the columns to the menu list.
   */
  _configureMenu: function() {
    var menu = $(".phx-hd-main-btn > ul", this.el);
    var self = this;

    var subMenuCols = $("ul.phx-grid-column-chooser", this.el);
    _.each(this.dataGrid.currentVue.columns, function(column, index, columns) {
      if (index < columns.length && !self.dataGrid.currentVue._columns[column.property].omitMenu &&
        ((column.title.length > 0 && index === 0) || index > 0)) {
        //var li = self.make("li", {});
        var li = $("<li></li>");
        var a = $("<a class=\"" + self.classPrefix + column.property + "\"></a>");

        var span;
        if (self.dataGrid.currentVue._columns[column.property].radioOff === true) {
          span = $("<span></span>").addClass("phx-icon none");
        } else {
          span = $("<span></span>").addClass("phx-icon phx-icon-planresp-valider");
        }

        a.append(span);
        var title = (!STR.isBlank(column.menuTitle) && !STR.isBlank(column.menuTitle)) ? column.menuTitle : column.title;
        a.append(title);
        li.append(a);
        if (STR.isBlank(title)) { //Only add option to menu if it has some title text
          li.hide();
        }
        subMenuCols.append(li);
        self.visible.push(column.property);
      }
    });
    this.subMenuCols = subMenuCols;

    var subMenuVues = $("ul.phx-grid-vues-chooser", this.el);
    var vuesLength = Object.keys(this.dataGrid.vues).length;
    _.each(_.keys(this.dataGrid.vues), function(key, index) {
      if (index < vuesLength) {
        //var li = this.make("li", {});
        var li = $("<li></li>");
        var a = $("<a class=\"" + self.classPrefix + key + "\"></a>");
        var span;
        if (key !== self.dataGrid.defaultVue) {
          span = $("<span></span>").addClass("phx-icon none");
        } else {
          span = $("<span></span>").addClass("phx-icon phx-icon-planresp-valider");
        }
        a.append(span);
        var menuName = key;
        if (!STR.isBlank(self.dataGrid.vues[key].menuName)) {
          menuName = self.dataGrid.vues[key].menuName;
        }

        a.append(menuName);

        li.append(a);
        subMenuVues.append(li);
      }
    });
    this.subMenuVues = subMenuVues;

    menu.menu({ icons: { submenu: "none" } });
    menu.css("cursor", "pointer");

    //Delete blank space icons in menu
    var spanNone = menu.find("a.phx-grid-menu-selectionner-colonnes .ui-menu-icon.ui-icon.none");
    spanNone.removeClass("ui-menu-icon").removeClass("ui-icon").removeClass("none");
    var spanNoneVues = menu.find("a.phx-grid-menu-selectionner-vue .ui-menu-icon.ui-icon.none");
    spanNoneVues.removeClass("ui-menu-icon").removeClass("ui-icon").removeClass("none");

    return menu;
  },
  /**
   * Select an option
   */
  _selectOption: function(event) {
    event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");
  },
  /**
   * Select a column
   */
  _selectColumn: function(event) {
    var columnId = event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");
    // If there are only one visible column and try to click it the event is not triggered.
    if (this.visible.length > 1 || _.indexOf(this.visible, columnId) === -1) {
      var indexOf = _.indexOf(this.invisible, columnId);
      if (indexOf === -1) {
        this.invisible.push(columnId);
        this.visible.splice(_.indexOf(this.visible, columnId), 1);
      } else {
        this.visible.push(columnId);
        this.invisible.splice(_.indexOf(this.invisible, columnId), 1);
      }
      this.dataGrid.model.trigger("toggle:column", columnId);
      this._toggleMenu();
    }

  },
  /**
   * Select a view
   */
  _selectVue: function(event) {
    var vueName = event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");
    this._toggleMenu();
    this._changeVueSelected(vueName);
  },

  _changeVueSelected: function(vueName) {
    // If there are only one visible column and try to click it the event is not triggered.
    this.dataGrid.model.trigger("change:currentVue", vueName);
    this._manageVueIcons(vueName);
  },
  /**
   *
   * Manage the icon status of the menu of Views.
   */
  _manageVueIcons: function(vueName) {

    var self = this;

    if (!STR.isBlank(this.dataGrid.vues)) {
      _.each(_.keys(this.dataGrid.vues), function(key) {

        if (!STR.isBlank(self.subMenuVues)) {
          self.subMenuVues.find("." + self.classPrefix + key.replace(".", "\\.") + " span.phx-icon").removeClass("phx-icon-planresp-valider");
          self.subMenuVues.find("." + self.classPrefix + key.replace(".", "\\.") + " span.phx-icon").addClass("none");
        } else {
          $(self.el).find(".phx-grid-menu-" + key.replace(".", "\\.") + " span.phx-icon").removeClass("phx-icon-planresp-valider");
          $(self.el).find(".phx-grid-menu-" + key.replace(".", "\\.") + " span.phx-icon").addClass("none");
        }

      });

      if (STR.isBlank(self.subMenuVues)) {
        $(this.el).find(".phx-grid-menu-" + vueName.replace(".", "\\.") + " span.phx-icon").removeClass("none");
        $(this.el).find(".phx-grid-menu-" + vueName.replace(".", "\\.") + " span.phx-icon").addClass("phx-icon-planresp-valider");
      } else {

        this.subMenuVues.find("." + this.classPrefix + vueName.replace(".", "\\.") + " span.phx-icon").removeClass("none");
        this.subMenuVues.find("." + this.classPrefix + vueName.replace(".", "\\.") + " span.phx-icon").addClass("phx-icon-planresp-valider");
      }

    }
  },

  /**
   * Opens or Closes the menú.
   */
  _toggleMenu: function() {
    if (this.menu.is(":visible")) {
      this.menu.hide();
    } else {
      var targetEl = $(this.el);
      this.menu.show().position({
        my: "right top",
        at: "bottom",
        of: targetEl
      });
      var self = this;
      $(document).one("mousedown", function(event) {
        var element = $(targetEl).find(event.target);
        if (element.length === 0) {
          // mousedown out of the button
          self._toggleMenu();
        }
      });
    }
  },

  remove: function() {
    this.dataGrid = null;

    var visibleLength = this.visible.length;
    this.visible.splice(0, visibleLength);
    delete this.visible;

    var invisibleLength = this.invisible.length;
    this.invisible.splice(0, invisibleLength);
    delete this.invisible;

    Backbone.View.prototype.remove.call(this);
    this.$el.empty();
  }
});
