import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';

export class CWBaseGridCollection<TModel extends CWBaseModel = CWBaseModel> extends CWPaginatedCollection<TModel>{

  editModeCellSelected: number;

  constructor(models?: TModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(models, options);
    this.editModeCellSelected = null;
  }
}
