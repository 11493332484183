import * as Backbone from 'Backbone';
import { CWSTR } from 'src/utils/cwStr';

export class CWOverlay extends Backbone.View<Backbone.Model> {

  constructor(options?: any) {
    options = options || {};
    options.className = "c-overlay d-none";
    super(options); // Initialize de la clase superior -> Super des >->
    this.on('showOverlay', this.showOverlay);
    this.on('hideOverlay', this.hideOverlay);
    $(window).resize((): void => {
      this.resizeOverlay();
    });
  }

  public showOverlay(): void {
    this._manageOverlay(true)
  }

  public hideOverlay(): void {
    this._manageOverlay(false)
  }

  /**
   * Show/ hide Overlay
   * true to show
   * false to hidde
   */
  private _manageOverlay(state: boolean): void {
    if (state) {
      this.resizeOverlay();
      $(this.el).removeClass('d-none')
    } else {
      $(this.el).addClass('d-none');
    }
  }

  private resizeOverlay(): void {
    const position: JQueryCoordinates = $(this.el).parent().position();
    const width: number = $(this.el).parent().width();
    const height: number = $(this.el).parent().height();

    if (!CWSTR.isBlank(position)) {
      $(this.el).css({
        'width': (width + 20) + "px",
        'height': (height + 20) + "px",
        'top': (position.top - 17) + 'px',
        'left': (position.left - 10) + 'px'
      });
    }
  }
}
