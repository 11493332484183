import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';

/**
 * Model to filtered the data
 */
export class CWFilterModel extends CWBaseModel {

  /**
   * Default data model
   *		id : null,
   *		datedeb : "",
   * 		datefin : ""
   * }
   */
  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "datedeb": "",
      "datefin": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.usecase = options.usecase || "droitsdel";
    super(attributes, options);
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    const datedeb: {} = {//Period validation
      "name": "datedeb",
      "value": attrs.datedeb,
      "label": undefined
    };
    const datefin: {} = {
      "name": "datefin",
      "value": attrs.datefin,
      "label": undefined,
      "isInfinity": false
    };
    const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);

    if (!CWSTR.isBlank(errorMsg)) {
      errors.datedeb = errorMsg.datedeb;
      errors.datefin = errorMsg.datefin;
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}