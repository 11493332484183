import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';

function replaceTokens(label) {
  var result = label.replace(/\{(\d)\}/g, '{{$1}}').replace(/@(\d)/g, '{{$1}}');
  return result;
}

var conf = {
  fallbackLng: ['fr'],
  ns: ['screens', 'common', 'messages', 'login'],
  defaultNS: 'screens',
  interpolation: {
    escapeValue: false
  },
  backend: {
    loadPath: function(lngs, namespaces) {
      var url = "i18n/locales/" + lngs + "/" + namespaces + ".json";
      if (namespaces[0] === "messages") {
        url = Configuration.restRoot + "/rest/utilitaire/messages?langue=" + lngs;
      }
      return url;
    },
    parse: function(data, url) {
      if (url.indexOf("messages?langue") > 0) {
        var tempData = {};
        JSON.parse(data).forEach(function(message) {
          tempData[message.famille + "_" + message.code] = replaceTokens(message.libelle);
        });
        data = tempData;
      } else {
        data = JSON.parse(data);
      }
      return data;
    }
  },
  // init option for fetch, for example
  init: {
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default',
  }
};

if (process.env.NODE_ENV === "development") {
  conf.debug = true;
}

if (window.location.href.indexOf("login") > 0) {
  conf.ns = ['login', 'common', 'messages'];
  conf.defaultNS = 'login';
  conf.backend.loadPath = function(lngs, namespaces) {
    var url = "../i18n/locales/" + lngs + "/" + namespaces + ".json";
    if (namespaces[0] === "messages") {
      url = Configuration.restRoot + "/rest/utilitaire/messages?langue=" + lngs;
    }
    return url;
  };
}

if (window.location.href.indexOf("aide") > 0) {
  conf.ns = ['help'];
  conf.defaultNS = 'help';
  conf.backend.loadPath = function(lngs, namespaces) {
    var url = "../i18n/locales/" + lngs + "/" + namespaces + ".json";
    return url;
  };
}

if (window.location.href.indexOf("declaration") > 0) {
  conf.ns = ['declaConform'];
  conf.defaultNS = 'declaConform';
  conf.backend.loadPath = function(lngs, namespaces) {
    var url = "../i18n/locales/" + lngs + "/" + namespaces + ".json";
    return url;
  };
}

export var i18n = i18next.use(XHR).init(conf); //eslint-disable-line
