import _ from 'underscore';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSuvietatModel } from './cwSuvietat.model';


/**
 * Collection for the main table
 */
export class CWSuvietatColl extends CWPaginatedCollection {
  constructor(models?: CWSuvietatModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {

    if (!options && !_.isEmpty(models)) {

      options = models;
    }
    options = options || {};
    options.model = options.model || CWSuvietatModel;
    super(models, options);
    CWPaginatedCollection.prototype.initialize.call(this);
    this.sortings = { datedebut: false, heuredebut: false };
    this.url = (): string => {
      return Configuration.restRoot + "/rest/edition";
    };
  }
}