import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model LireMarqueurCollabPeriode: 
 * lire les marqueurs du calendrier pour un collaborateur dans une période
 */
export class CWMarqueursModel extends CWBaseModel {

  public daterech: string;
  public matric: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = options.module;
    this.params = {};
    this.daterech = null;
    this.matric = null;


    this.url = (): string => {
      return Configuration.restRoot + "/rest/calmarqueurexp/collaborateur/" + encodeURIComponent(this.matric) + "?daterech=" + encodeURIComponent(this.daterech);
    };
  }
}