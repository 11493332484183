import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Read récapitulatif
 */
export class CWRecapActModel extends CWBaseModel {
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/element/";
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "code": "",
      "recapitulatif": "",
      "libelle": "",
      "datedeb": "",
      "datefin": "",
      "commentaire_infocomp": "",
      "statut": null,
      "evenement": "",
      "etat": "",
      "indicateurpj": false,
      "piecesjointes": null,
      "droit": ""
    }
  }
}
