import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

export class CWDynamicComboModel extends CWBaseModel {
  urlService: any;
  defaut: any;
  nbrang: any;
  optionsFiltre: any;
  code: string;

  constructor(options?: { [key: string]: any }) {
    super(options);
    if (!CWSTR.isBlank(options)) {
      this.urlService = options.url;
      this.defaut = options.defaut;
      this.nbrang = options.nbrang;
      this.optionsFiltre = options.filtre;
    }
    this.url = (): string => {
      return Configuration.restRoot + "/" + this.urlService + ((!CWSTR.isBlank(this.nbrang) || !CWSTR.isBlank(this.defaut)) ? '?' : '') + (!CWSTR.isBlank(this.defaut) ? 'valeurinit=' + this.defaut : '') + (!CWSTR.isBlank(this.nbrang) ? (!CWSTR.isBlank(this.defaut) ? '&' : '') + 'nbrang=' + this.nbrang : '') + (!CWSTR.isBlank(this.optionsFiltre) ? '&filtre=' + this.optionsFiltre : '');
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
}