import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLVueannuelleDetail from '../detail.tpl.html';
import { CWBaseFormView as CommonFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWCriteresCalModel } from '../models/cwCriteresCal.model';
import { CWMSGS } from 'utils/cwMsgs';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


/**
 * Render a Calendrier vue anuelle in a form
 */
export class CWFormView extends CommonFormView {

  selectionModel: Backbone.Model;
  initialMonth: number;
  months: number;
  droppable: boolean;
  workflow: any;
  headerRender: any;
  cellRender: any;
  rowHeight: string;
  dblclick: any;
  datesSelectable: boolean;
  context: { [key: string]: any };
  mainModel: any;
  scalendar: any;
  astreinteCellRender: any;
  oldDatefinClicked: any;
  $months: any;
  $numberDays: any;
  divRow: JQuery;
  selectPresence: Backbone.Model;
  vacancesCellRender: any;
  $appendTo: JQuery; //pour les messages

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    if (options.context && options.context.ctxEcran === "calmarqueurdat") {
      //this is only for when we're in calendrier de marqueur screen
      options.events = _.extend({
        "click .phx-cell ": "clickCellCalendrier",
        "click .phx-vueannuelle-header": "_headerListener",
        "dblclick .phx-vueannuelle-header": "_headerListener",
        "click .year": "_yearClickListener",
        "mouseover .phx-cell": "_highlightMonthAndDay",
        "mouseout .phx-cell": "_outMonthAndDay"
      });
    } else {
      options.events = _.extend({
        "click .phx-cell ": "_cellClickListener",
        "dblclick .phx-cell ": "_cellClickListener",
        "click .phx-vueannuelle-header": "_headerListener",
        "dblclick .phx-vueannuelle-header": "_headerListener",
        "click .year": "_yearClickListener",
        "mousedown .phx-cell": "_mousedownHandler",
        "mousemove .phx-cell": "_mousemoveHandler",
        "mouseup .phx-cell": "_mouseupHandler",
        "mouseover .phx-cell": "_highlightMonthAndDay",
        "mouseout .phx-cell": "_outMonthAndDay"
      });
    }
    super(options);
    this.model = new CWBaseModel({
      value: new CWCriteresCalModel()
    });
    this.template = TPLVueannuelleDetail;
    this.selectionModel = new Backbone.Model({
      dateStart: "",
      dateEnd: "",
      isRange: false
    });
    this.selectPresence = new Backbone.Model({
      selPresence: [],
      hasPresence: false
    });
    //Update range model data when selectionModel changes
    this.listenTo(this.selectionModel, "change", (model) => {
      if ((model.hasChanged("dateStart") || model.hasChanged("dateEnd"))) {
        // When only change dateStart, we remove dateEnd for avoid "false ranges"
        if (!model.hasChanged("dateEnd")) {
          model.set("dateEnd", null, { silent: true }); // Don't trigger more events
        }
        if (!CWSTR.isBlank(model.get("dateStart")) && !CWSTR.isBlank(model.get("dateEnd")) && model.get("dateStart") !== model.get("dateEnd")) {
          model.set("isRange", true);
        } else {
          model.set("isRange", false);
        }
      }
      if (CWSTR.isBlank(model.get("dateStart")) && CWSTR.isBlank(model.get("dateEnd")) && this.context) {
        model.set("dateStart", this.context.ctxDateEnEvidence);
      }
    });
    this.initialMonth = 0;
    this.months = 11;
    this.droppable = false;
    if (!CWSTR.isBlank(options.droppable)) {
      this.droppable = options.droppable;
    }
    if (!CWSTR.isBlank(options.workflow)) {
      this.workflow = options.workflow;
      this.listenTo(this.workflow, "change:dateToday", this._markToday);
      this.listenTo(this.workflow, "clearSelectedChangeTab", this._clearSelectedChangeTab);
      this.listenTo(this.workflow, "initTabPresence", this._markCellInit);
      this.listenTo(this.workflow, "annuelle:scrollToDay", this._scrollToDay);
    }
    this.$appendTo = (this.workflow && !CWSTR.isBlank(this.workflow.module)) ? $("#" + this.workflow.module) : null;
    this.headerRender = null;
    this.cellRender = null; // Cell render function to paint specific content
    this.divRow = null;
    this.rowHeight = undefined;
    if (!CWSTR.isBlank(options.rowHeight)) {
      this.rowHeight = options.rowHeight;
    } else {
      this.rowHeight = "20px";
    }
  }

  _cellClickListener(event: any): void {
    // Trigger the custom even select to be interpreted by the parent view
    if (event.type === "click") {
      const editActionClicked = false;

      if (!editActionClicked) {
        setTimeout(() => {
          const double = this.dblclick;

          if (double > 0) {
            this.dblclick = double - 1;
            return false;
          }
          return true;
        }, 300);
      }
    } else if (event.type === "dblclick") {
      const dateSelected = $(event.currentTarget).attr("data-date");

      this.dblclick = 2;
      if ((this.selectionModel.get("dateStart") < this.selectionModel.get("dateEnd") && !(this.selectionModel.get("dateStart") <= dateSelected && dateSelected <= this.selectionModel.get("dateEnd"))) ||
        (this.selectionModel.get("dateStart") > this.selectionModel.get("dateEnd") && !(this.selectionModel.get("dateEnd") <= dateSelected && dateSelected <= this.selectionModel.get("dateStart")))) {
        this._dayClickListener(event);
      }
      this._dayDblclickListener(event);
      event.stopPropagation();
    }
  }

  _clearSelectedChangeTab(): void {
    this._unselectAllDates(true);
  }

  _markToday(): void {
    if (this.workflow.get("viewType") === this.workflow.YEARLY_VIEW) {
      const selectedDay = this.workflow.modifiedDay !== undefined ? this.workflow.modifiedDay : this.context.ctxDateEnEvidence;

      if (this.workflow.activeTab !== 1) {
        if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxDateEnEvidence) && this.workflow.activeTab !== 1) {
          const currentDay = this.$el.find("td.phx-cell[data-date=" + selectedDay + "]");
          const mainCont = currentDay.find(".mainCont");
          const today = CWTYPE.DATE.strToDate(selectedDay);

          $(mainCont).addClass("ui-phx-date-heure-actuelle-border");
          this.$el.find("th[data-month='" + (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1) + "'] > div").addClass("selectedMonth");
          this.$el.find(".numDaysAgenda[data-day='" + today.getDate() + "']").addClass("selectedDay");
        }
      }
    }
  }

  _goToMonthlyView(event: any): void {
    const target = $(event.currentTarget);
    const selectedDate = target.attr("data-year") + target.attr("data-month") + "01";

    if (this.workflow.get("viewType", this.workflow.MONTHLY_VIEW)) {
      this.workflow.selectionMonthModel.clear();
    }
    this.workflow.set("selectedDate", selectedDate);
    this.workflow.set("viewType", this.workflow.MONTHLY_VIEW);
    //CWMSGS.showWarning(i18n.t('messages:GL_1009'));
  }

  _dayClickListener(event: any): void {
    const isShiftPressed = event.shiftKey;
    const isCtrlPressed = event.ctrlKey;
    const dateSelected = $(event.currentTarget).attr("data-date");

    /*this._unselectAllDates();
    if (isShiftPressed === true && CWSTR.isBlank(this.selectionModel.get("dateStart")) == true) {
      this.selectionModel.set({
        "dateStart": this.selectionModel.get("dateEnd"),
        "dateEnd": ""
      });
      isShiftPressed = false;
    }*/

    if (isShiftPressed === true && !CWSTR.isBlank(this.selectionModel.get("dateStart"))) {
      this.selectionModel.set("dateEnd", dateSelected);

      //Put range of dates in correct order
      const selectionStartDate = CWTYPE.DATE.strToDate(this.selectionModel.get("dateStart"), CWTYPE.DATE.DEFAULT_MASK_BBDD);
      const selectionEndDate = CWTYPE.DATE.strToDate(this.selectionModel.get("dateEnd"), CWTYPE.DATE.DEFAULT_MASK_BBDD);

      if (selectionEndDate < selectionStartDate) {
        this.selectionModel.set({
          "dateStart": this.selectionModel.get("dateEnd"),
          "dateEnd": this.selectionModel.get("dateStart")
        });
      }

      this._treatSelectedDates();
      if (this.workflow.activeTab === 1) {
        if (this.selectPresence.get('selPresence').length > 1) {
          this.selectPresence.set('selPresence', []);
          this.selectPresence.get('selPresence').push(dateSelected);
          this._checkDatePresence(dateSelected);
        }
        this._addSelectedDatesPresence(this.selectionModel.get("dateStart"), this.selectionModel.get("dateEnd"));
      }
    } else if (isCtrlPressed === true && !CWSTR.isBlank(this.selectionModel.get("dateStart")) && this.workflow.activeTab === 1) {
      if (CWSTR.isBlank(this.selectPresence.get('selPresence'))) {
        this.selectPresence.set('selPresence', []);
      }
      const idx = _.indexOf(this.selectPresence.get('selPresence'), dateSelected);
      if (idx > -1) {
        this._unmarkCell("td.phx-cell[data-date='" + dateSelected + "']", dateSelected);
      } else {
        this.selectPresence.get('selPresence').push(dateSelected);
        this._checkDatePresence(dateSelected);
        this._markCell("td.phx-cell[data-date='" + dateSelected + "']");
      }
    } else {
      if (!CWSTR.isBlank(this.selectionModel.get("dateStart"))) {
        this._unmarkCell("td.phx-cell[data-date='" + this.selectionModel.get("dateStart") + "']", dateSelected);
      }

      if (this.selectionModel.get("dateStart") !== dateSelected) {
        this.selectionModel.set("dateStart", dateSelected);
        this._markCell("td.phx-cell[data-date='" + this.selectionModel.get("dateStart") + "']");
        if (this.workflow.activeTab === 1) {
          this.selectPresence.set('selPresence', []);
          this.selectPresence.get('selPresence').push(this.selectionModel.get("dateStart"));
          this._checkDatePresence(dateSelected);
        }
      } else {
        if (!CWSTR.isBlank(this.selectionModel.get("dateStart")) && this.selectionModel.get("dateStart") === dateSelected && this.workflow.activeTab === 1) {
          this.selectPresence.set('selPresence', []);
          this.selectPresence.get('selPresence').push(this.selectionModel.get("dateStart"));
          this._checkDatePresence(this.selectionModel.get("dateStart"));

          this._markCell("td.phx-cell[data-date='" + this.selectionModel.get("dateStart") + "']");
        } else {
          this.selectionModel.set("dateStart", null);
        }
      }
    }
  }

  _dayDblclickListener(event: any): void {
    const dateSelected = $(event.currentTarget).attr("data-date");
    if (!CWSTR.isBlank(this.selectionModel.get("dateEnd"))) {
      if (this.selectionModel.get("dateStart") < this.selectionModel.get("dateEnd")) {
        this.model.trigger("day:dblClicked", this.selectionModel.get("dateStart"), this.selectionModel.get("dateEnd"));
      } else {
        this.model.trigger("day:dblClicked", this.selectionModel.get("dateEnd"), this.selectionModel.get("dateStart"));
      }
    } else {
      this.model.trigger("day:dblClicked", dateSelected);
    }
  }

  _headerListener(event: any): void {
    if (event.type === "click") {
      const editActionClicked = false;
      if (!editActionClicked) {
        setTimeout(() => {
          const double = this.dblclick;
          if (double > 0) {
            this.dblclick = double - 1;
            return false;
          } else {
            this._monthClickListener(event);
          }
          return true;
        }, 300);

      }
    } else if (event.type === "dblclick") {
      this.dblclick = 2;
      this._monthDblclickListener(event);
      event.stopPropagation();
    }
  }

  _monthClickListener(event: any): void {
    const monthSelected = $(event.currentTarget).attr("data-month");
    const yearSelected = $(event.currentTarget).attr("data-year");

    this.model.trigger("month:clicked", monthSelected, yearSelected);
  }

  _monthDblclickListener(event: any): void {
    const monthSelected = $(event.currentTarget).attr("data-month");
    const yearSelected = $(event.currentTarget).attr("data-year");
    const datedeb = yearSelected + monthSelected + "01";
    const datefin = yearSelected + monthSelected + this._daysInMonth(monthSelected, yearSelected);
    this.model.trigger("month:dblClicked", datedeb, datefin);
  }

  _yearClickListener(event: any): void {
    const yearSelected = $(event.currentTarget).attr("data-year");
    this.model.trigger("year:clicked", yearSelected);
  }

  _treatSelectedDates(): void {
    let nbDiffMonth = this._diffMonths(this.selectionModel.get("dateStart"), this.selectionModel.get("dateEnd"));
    let datedeb: any = "";
    let datefin: any = "";
    let auxDatefin: any = "";
    let auxDatedeb: any = "";
    // Treat the special case of selecting from a later date until a previous date.
    if (nbDiffMonth > 0) {
      datedeb = this.selectionModel.get("dateStart");
      datefin = this.selectionModel.get("dateEnd");
    } else {
      nbDiffMonth = Math.abs(nbDiffMonth); // We need the absolute amount of months from this point on.
      datedeb = this.selectionModel.get("dateEnd");
      datefin = this.selectionModel.get("dateStart");
    }

    switch (nbDiffMonth) {
      case 0:
        this._markSelectedDates(datedeb, datefin);
        break;
      case 1:
        // Mark starting month.
        auxDatefin = this._computeExtremumDate(datedeb, true);
        this._markSelectedDates(datedeb, auxDatefin);

        // Mark ending month.
        auxDatedeb = this._computeExtremumDate(datefin, false);
        this._markSelectedDates(auxDatedeb, datefin);
        break;
      default: { // case > 1
        // Mark starting month.
        auxDatefin = this._computeExtremumDate(datedeb, true);
        this._markSelectedDates(datedeb, auxDatefin);

        // Mark full months between beginning and ending month.
        let auxDate = this._nextMonthDate(datedeb, true); // auxDate "YYYYMMDD" formatted
        for (let i = 0; i < nbDiffMonth - 1; i++) {
          auxDatedeb = this._computeExtremumDate(auxDate, false);
          auxDatefin = this._computeExtremumDate(auxDate, true);
          this._markSelectedDates(auxDatedeb, auxDatefin);

          auxDate = this._nextMonthDate(auxDate, true); // auxDate "YYYYMMDD" formatted
        }

        // Mark ending month.
        auxDatedeb = this._computeExtremumDate(datefin, false);
        this._markSelectedDates(auxDatedeb, datefin);
      }
    }
  }

  _computeExtremumDate(date: any, lastDayInMonth: boolean): any {
    const month = CWTYPE.DATE.strToDate(date, "YYYYMMDD").getMonth();
    const year = CWTYPE.DATE.strToDate(date, "YYYYMMDD").getFullYear();

    // If is the ending month we have to compute the last day in month, for the starting month, 1.
    const extremumDay = lastDayInMonth === true ? this._daysInMonth(month, year) : 1;

    const objDate = new Date(year, month, extremumDay);
    const auxDate = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(objDate)).val;

    return auxDate;
  }

  _markSelectedDates(datedeb: any, datefin: any): void {
    if (!CWSTR.isBlank(datedeb) && !CWSTR.isBlank(datefin)) {
      const size = datefin - datedeb;
      let date: any = "";
      if (size > 0) {
        for (let i: any = 0; i <= size; i++) {
          date = parseInt(datedeb) + parseInt(i);
          this._markCell("td.phx-cell[data-date='" + date + "']", true);
        }
      } else {
        for (let i: any = size; i <= 0; i++) {
          date = parseInt(datedeb) + parseInt(i);
          this._markCell("td.phx-cell[data-date='" + date + "']", true);
        }
      }
    }
  }

  _addSelectedDatesPresence(datedeb: any, datefin: any): void {
    if (!CWSTR.isBlank(datedeb) && !CWSTR.isBlank(datefin)) {
      let dMonth = CWTYPE.DATE.strToDate(datedeb).getMonth() + 1;
      let fMonth = CWTYPE.DATE.strToDate(datefin).getMonth() + 1;
      if (dMonth === fMonth) {
        this._sameMonthRange(datedeb, datefin);
      } else {
        let iDate = datedeb;
        let fDate = datefin;
        if (fMonth < dMonth) {
          const aux = dMonth;
          dMonth = fMonth;
          fMonth = aux;

          iDate = datefin;
          fDate = datedeb;
        }

        let strDate = "";
        let aDate = null;
        for (let i = dMonth; i <= fMonth; i++) {
          if (i === dMonth) {
            aDate = new Date(CWTYPE.DATE.strToDate(iDate).getFullYear(), CWTYPE.DATE.strToDate(iDate).getMonth() + 1, 0);
            strDate = CWTYPE.DATE.dateToStr(aDate, 'YYYYMMDD');
            this._sameMonthRange(iDate, parseInt(strDate, 10));
          } else if (i === fMonth) {
            aDate = new Date(CWTYPE.DATE.strToDate(fDate).getFullYear(), CWTYPE.DATE.strToDate(fDate).getMonth(), 1);
            strDate = CWTYPE.DATE.dateToStr(aDate, 'YYYYMMDD');
            this._sameMonthRange(parseInt(strDate, 10), fDate);
          } else {
            aDate = new Date(CWTYPE.DATE.strToDate(iDate).getFullYear(), i - 1, 1);
            strDate = CWTYPE.DATE.dateToStr(aDate, 'YYYYMMDD');
            const aDate2 = new Date(CWTYPE.DATE.strToDate(iDate).getFullYear(), i, 0);
            const strDate2 = CWTYPE.DATE.dateToStr(aDate2, 'YYYYMMDD');
            this._sameMonthRange(parseInt(strDate, 10), parseInt(strDate2, 10));
          }
        }
      }
    }
  }

  _checkDatePresence(date: string): void {
    const ele = this.workflow.mainModel.models[0].get("cel").get(date);
    if (!CWSTR.isBlank(ele) && !this.selectPresence.get('hasPresence')) {
      if (ele.get("presence")) {
        this.selectPresence.set('hasPresence', true);
      }
    }
  }

  _sameMonthRange(datedeb: any, datefin: any): void {
    const size = datefin - datedeb;
    let date = "";
    if (CWSTR.isBlank(this.selectPresence.get('selPresence'))) {
      this.selectPresence.set('selPresence', []);
    }
    if (size === 0) {
      date = String(parseInt(datedeb, 10));
      const idx = _.indexOf(this.selectPresence.get('selPresence'), date);
      if (idx < 0) {
        this.selectPresence.get('selPresence').push(date);
        this._checkDatePresence(date);
      }
    } else if (size > 0) {
      for (let i = 0; i <= size; i++) {
        date = String(parseInt(datedeb, 10) + i);
        const idx = _.indexOf(this.selectPresence.get('selPresence'), date);
        if (idx < 0) {
          this.selectPresence.get('selPresence').push(date);
          this._checkDatePresence(date);
        }
      }
    } else {
      for (let i = size; i <= 0; i++) {
        date = String(parseInt(datedeb, 10) + i);
        const idx = _.indexOf(this.selectPresence.get('selPresence'), date);
        if (idx < 0) {
          this.selectPresence.get('selPresence').push(date);
          this._checkDatePresence(date);
        }
      }
    }
  }

  _markCellInit(): void {
    if (this.workflow.get("viewType") === this.workflow.YEARLY_VIEW) {
      const element = this.$el.find("td.phx-cell[data-date='" + this.workflow.get("selectedDate") + "']");
      const mainCont = element.find(".mainCont");
      if (this.workflow.activeTab === 1) {
        mainCont.removeClass("ui-phx-date-heure-actuelle-border");
      } else {
        mainCont.addClass("ui-phx-date-heure-selectionnee-border");
      }
    }
  }

  _markCell(target: any, multiple?: boolean): void {
    const element = this.$el.find(target);
    const mainCont = element.find(".mainCont");
    if (multiple && (this.selectionModel.get("dateStart") !== this.selectionModel.get("dateEnd"))) {
      this._selectPeriode(element)
    } else {
      if (mainCont.hasClass("borderBottomAgendaDay")) {
        mainCont.removeClass("borderBottomAgendaDay");
      } else if (mainCont.removeClass("borderBottomAgendaWeek")) {
        mainCont.removeClass("borderBottomAgendaWeek");
      }

      if (this.workflow.activeTab === 1) {
        mainCont.addClass("ui-phx-date-heure-actuelle-border");
      } else {
        mainCont.removeClass("ui-phx-date-heure-actuelle-border").addClass("ui-phx-date-heure-selectionnee-border");
      }
    }
    if (this.workflow.module !== "agenda") {
      if (multiple !== true) {
        this.$el.find(".selectedDay").removeClass("selectedDay");
        this.$el.find(".selectedMonth").removeClass("selectedMonth");
        this.$el.find(".selectionStart").removeClass("selectionStart");
        this.$el.find(".selectionMid").removeClass("selectionMid");
        this.$el.find(".selectionEnd").removeClass("selectionEnd");
      } else {
        this.$el.find(".ui-phx-date-heure-selectionnee-border").removeClass("ui-phx-date-heure-selectionnee-border");
      }
    }
    this._selectMonthDay(element);
    //this._setBorderedHeightProperties(element.children().eq(0));
  }

  /**
   * Changes classes for month and day number mouseover cell
   * @param event trigger mouseover event
   */
  _highlightMonthAndDay(event: any): void {
    const date = $(event.currentTarget).attr("data-date");
    const element = this.$el.find("td.phx-cell[data-date='" + date + "']");
    const dateSel = CWTYPE.DATE.strToDate($(element).attr("data-date") as any);
    const daySel = dateSel.getDate();
    let monthSel: any = (dateSel.getMonth() + 1);//months start at 0 so add 1

    monthSel = (monthSel < 10 ? '0' : '') + monthSel
    this.$numberDays.filter("td.numDaysAgenda[data-day='" + daySel + "']:first").addClass("highlightDay");
    this.$months.filter("th[data-month='" + monthSel + "']:first").children(".background").addClass("highlightMonth");
  }

  _annualCellTooltip(element: JQuery, date: string): void {
    const headerTitle = "<b>" + CWTYPE.DATE.format(date, CWTYPE._getFormatByCode('DATE_L')) + "</b>";
    const mainCont = element.find("div.mainCont");

    mainCont.tooltip({
      items: "div.mainCont",
      content: headerTitle,
      track: true,
      show: { effect: "none", delay: 500 },
    });

    mainCont.find(".vacancesDiv, .marqueurDiv, .jourex[title], .croixColor, .interditColor")
      .on("mouseenter", function () {
        mainCont.tooltip().tooltip("disable");
      })
      .on("mouseleave", function () {
        mainCont.tooltip().tooltip("enable")
      });
  }

  _outMonthAndDay(): void {
    this.$numberDays.removeClass("highlightDay");
    this.$months.children().removeClass("highlightMonth");
  }

  searchMonthsAndNumberDays(): void {
    this.$months = this.$el.find("th");
    this.$numberDays = this.$el.find("td.numDaysAgenda");
  }

  _selectPeriode(element: JQuery): void {
    let reverseSelection = false;
    const today = CWTYPE.DATE.getCurrentDate();
    const mainCont = element.find(".mainCont");

    if (this.selectionModel.get("dateStart") > this.selectionModel.get("dateEnd")) {
      reverseSelection = true;
    }
    if (reverseSelection) {
      if ($(element).attr("data-date") === this.selectionModel.get("dateStart")) {
        if (this.workflow.activeTab === 1) {
          if ($(element).data("date").toString() === today) {
            this.$el.find(".ui-phx-date-heure-actuelle-border").removeClass("ui-phx-date-heure-actuelle-border")
          }
          mainCont.addClass("selectionEnd-presence");
        } else {
          mainCont.addClass("selectionEnd");
        }
      } else if ($(element).attr("data-date") === this.selectionModel.get("dateEnd")) {
        if (this.workflow.activeTab === 1) {
          if ($(element).data("date").toString() === today) {
            this.$el.find(".ui-phx-date-heure-actuelle-border").removeClass("ui-phx-date-heure-actuelle-border")
          }
          mainCont.addClass("selectionStart-presence");
        } else {
          mainCont.addClass("selectionStart");
        }
      } else {
        if (this.workflow.activeTab === 1) {
          if ($(element).data("date").toString() === today) {
            this.$el.find(".ui-phx-date-heure-actuelle-border").removeClass("ui-phx-date-heure-actuelle-border")
          }
          mainCont.addClass("selectionMid-presence");
        } else {
          mainCont.addClass("selectionMid");
        }
      }
    } else {
      if ($(element).attr("data-date") === this.selectionModel.get("dateStart")) {
        if (this.workflow.activeTab === 1) {
          if ($(element).data("date").toString() === today) {
            this.$el.find(".ui-phx-date-heure-actuelle-border").removeClass("ui-phx-date-heure-actuelle-border")
          }
          mainCont.addClass("selectionStart-presence");
        } else {
          mainCont.addClass("selectionStart");
        }
      } else if ($(element).attr("data-date") === this.selectionModel.get("dateEnd")) {
        if (this.workflow.activeTab === 1) {
          if ($(element).data("date").toString() === today) {
            this.$el.find(".ui-phx-date-heure-actuelle-border").removeClass("ui-phx-date-heure-actuelle-border")
          }
          mainCont.addClass("selectionEnd-presence");
        } else {
          mainCont.addClass("selectionEnd");
        }
      } else {
        if (this.workflow.activeTab === 1) {
          if ($(element).data("date").toString() === today) {
            this.$el.find(".ui-phx-date-heure-actuelle-border").removeClass("ui-phx-date-heure-actuelle-border")
          }
          mainCont.addClass("selectionMid-presence");
        } else {
          mainCont.addClass("selectionMid");
        }
      }
    }
  }

  _selectMonthDay(element: JQuery): void {
    const dateSel = CWTYPE.DATE.strToDate($(element).attr("data-date") as any);
    const daySel = dateSel.getDate();
    let monthSel: any = (dateSel.getMonth() + 1);//months start at 0 so add 1

    monthSel = (monthSel < 10 ? '0' : '') + monthSel
    this.$el.find(".numDaysAgenda[data-day='" + daySel + "']").addClass("selectedDay");
    this.$el.find("th[data-month='" + monthSel + "']").children(".background").addClass("selectedMonth");
  }

  _unmarkCell(target: any, dateSelected?: string): void {
    const element = this.$el.find(target);
    const mainCont = element.find(".mainCont");
    mainCont.removeClass("ui-phx-date-heure-selectionnee-border");

    if (this.workflow.activeTab === 1) {
      const idx = _.indexOf(this.selectPresence.get('selPresence'), dateSelected);
      if (idx > -1) {
        const aArr = _.without(this.selectPresence.get('selPresence'), dateSelected);
        this.selectPresence.set('selPresence', aArr);
        this.selectPresence.set('hasPresence', false);
        if (aArr.length > 0) {
          for (const i in aArr) {
            if (i) {
              this._checkDatePresence(aArr[i]);
            }
          }
        }
      }
      mainCont.removeClass("ui-phx-date-heure-actuelle-border");

      if (element.length > 0 && this.workflow.activeTab === 1) {
        this._unmarkRangeCells(mainCont, element);
      }
    } else {
      if (element.attr("data-date") === this.context.ctxDateEnEvidence && element.attr('data-date') === dateSelected) {
        mainCont.addClass("ui-phx-date-heure-actuelle-border");
      }
    }

    /*if (element.attr("data-date") == this.context.ctxDateEnEvidence) {
      mainCont.addClass("ui-phx-date-heure-actuelle-border");
    } else {
      //this._unsetBorderedHeightProperties(element);
    }*/
  }

  _unmarkRangeCells(content: JQuery, el: JQuery): void {
    const jDate = CWTYPE.DATE.strToDate(el.data('date'), 'YYYYMMDD');
    const aDate = CWTYPE.DATE.strToDate(el.data('date'), 'YYYYMMDD');
    aDate.setDate(jDate.getDate() - 1);
    const startDate = CWTYPE.DATE.dateToStr(aDate, 'YYYYMMDD');
    aDate.setDate(jDate.getDate() + 1);
    const endDate = CWTYPE.DATE.dateToStr(aDate, 'YYYYMMDD');
    let mainContent = null;

    if (content.hasClass('selectionStart-presence')) {
      content.removeClass("selectionStart-presence");
      mainContent = el.parent().parent().find(`td[data-date='${endDate}']`);
      if (mainContent.find('.mainCont').hasClass('selectionMid-presence')) {
        mainContent.find('.mainCont').removeClass('selectionMid-presence').addClass('selectionStart-presence');
      } else if (mainContent.find('.mainCont').hasClass('selectionEnd-presence')) {
        mainContent.find('.mainCont').removeClass('selectionEnd-presence').addClass('ui-phx-date-heure-actuelle-border');
      }
    } else if (content.hasClass('selectionMid-presence')) {
      content.removeClass("selectionMid-presence");
      mainContent = el.parent().parent().find(`td[data-date='${startDate}']`);
      if (mainContent.find('.mainCont').hasClass('selectionMid-presence')) {
        mainContent.find('.mainCont').removeClass('selectionMid-presence').addClass('selectionEnd-presence');
      } else if (mainContent.find('.mainCont').hasClass('selectionStart-presence')) {
        mainContent.find('.mainCont').removeClass('selectionStart-presence').addClass('ui-phx-date-heure-actuelle-border');
      }

      mainContent = el.parent().parent().find(`td[data-date='${endDate}']`);
      if (mainContent.find('.mainCont').hasClass('selectionMid-presence')) {
        mainContent.find('.mainCont').removeClass('selectionMid-presence').addClass('selectionStart-presence');
      } else if (mainContent.find('.mainCont').hasClass('selectionEnd-presence')) {
        mainContent.find('.mainCont').removeClass('selectionEnd-presence').addClass('ui-phx-date-heure-actuelle-border');
      }
    } else if (content.hasClass('selectionEnd-presence')) {
      content.removeClass("selectionEnd-presence");
      mainContent = el.parent().parent().find(`td[data-date='${startDate}']`);
      if (mainContent.find('.mainCont').hasClass('selectionMid-presence')) {
        mainContent.find('.mainCont').removeClass('selectionMid-presence').addClass('selectionEnd-presence');
      } else if (mainContent.find('.mainCont').hasClass('selectionStart-presence')) {
        mainContent.find('.mainCont').removeClass('selectionStart-presence').addClass('ui-phx-date-heure-actuelle-border');
      }
    }
  }

  _unselectAllDates(markDayInEvidence: boolean): void {
    if (this.workflow.get("viewType") === this.workflow.YEARLY_VIEW) {
      let element = null;
      let start = null;
      let end = null;
      let mid = null;

      this.selectPresence.set('selPresence', []);
      this.selectPresence.set('hasPresence', false);
      element = this.$el.find("div.ui-phx-date-heure-selectionnee-border");
      element.removeClass("ui-phx-date-heure-selectionnee-border");
      element = this.$el.find("div.ui-phx-date-heure-actuelle-border");
      element.removeClass("ui-phx-date-heure-actuelle-border");
      this.$el.find(".selectedDay").removeClass("selectedDay");
      this.$el.find(".selectedMonth").removeClass("selectedMonth");
      if (markDayInEvidence === true && !CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxDateEnEvidence) && this.workflow.activeTab !== 1) {
        this._markToday();
      }
      const currentDay = this.$el.find("td.phx-cell[data-date='" + this.context.ctxDateEnEvidence + "']");
      const mainCont = currentDay.find(".mainCont");

      $(mainCont).addClass("ui-phx-date-heure-actuelle-border");
      if (this.workflow.activeTab === 0) {
        const today = CWTYPE.DATE.getCurrentDate();
        this.$el.find(".phx-vueannuelle-day[data-date='" + today + "']").addClass("ui-phx-date-heure-actuelle-border");
      }
      start = this.$el.find(".selectionStart");
      start.removeClass("selectionStart");
      end = this.$el.find(".selectionEnd");
      end.removeClass("selectionEnd");
      mid = this.$el.find(".selectionMid");
      mid.removeClass("selectionMid");
      start = this.$el.find(".selectionStart-presence");
      start.removeClass("selectionStart-presence");
      end = this.$el.find(".selectionEnd-presence");
      end.removeClass("selectionEnd-presence");
      mid = this.$el.find(".selectionMid-presence");
      mid.removeClass("selectionMid-presence");
      this.$el.find(".endWeek").addClass("borderBottomAgendaWeek");
      this.$el.find(".normDay").addClass("borderBottomAgendaDay");
    }
  }

  // Events handlers
  _mousedownHandler(event: any): void {
    event.preventDefault();
    const isCtrlPressed = event.ctrlKey;

    // If the element clicked is not a selectable element.
    if ($(event.target).parents("td.phx-cell").length > 0) {
      // Delete old selection
      this.datesSelectable = true;
      if (!isCtrlPressed && this.workflow.activeTab === 1 || this.workflow.activeTab === 0 || !this.workflow.activeTab) {
        this._unselectAllDates(false);
      }
      this._dayClickListener(event);
    }
  }

  _mousemoveHandler(event: any): void {
    event.preventDefault();
    const isShiftPressed = event.shiftKey;
    const isCtrlPressed = event.ctrlKey;

    if (!CWSTR.isBlank(this.selectionModel.get("dateStart")) && this.datesSelectable === true) {
      if (!isCtrlPressed && this.workflow.activeTab === 1 || this.workflow.activeTab === 0) {
        this._unselectAllDates(false);
      }
      this.oldDatefinClicked = this.selectionModel.get("dateEnd");
      this.selectionModel.set("dateEnd", $(event.currentTarget).attr("data-date"));
      if (isShiftPressed) {
        this._treatSelectedDates();
      }
    }
  }

  _mouseupHandler(): void {
    this.datesSelectable = false;
  }

  setContext(options: { [key: string]: any }): void {
    this.context = options.context;
    CWSTR.setElValue(this.model.get("value"), "datedeb", this.context.ctxDateSynoptique.ctxDateDeb);
    CWSTR.setElValue(this.model.get("value"), "datefin", this.context.ctxDateSynoptique.ctxDateFin);

    //Update selection date
    this.selectionModel.set("dateStart", this.context.ctxDateEnEvidence);

    this.mainModel = options.mainModel;
    if (_.isBoolean(options.renderer) && options.renderer) {
      this.render();
    }
  }

  _calculateDates(): void {
    // 0 January - 11 December
    let dateDeb: any = CWTYPE.DATE.strToDate(this.context.ctxDateSynoptique, "YYYYMMDD");
    let dateFin: any = new Date(); //function used to obtain the Date object. Date is fixed behind this.
    dateDeb.setDate(1);
    dateFin.setDate(1);

    if (this.initialMonth < 0 && this.initialMonth > 11) {
      dateDeb = dateDeb.setMonth(0);
      dateFin.setMonth(11);
      dateFin.setYear(dateDeb.getFullYear());

      CWSTR.setElValue(this.model.get("value"), "datedeb", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateDeb)).val);
      CWSTR.setElValue(this.model.get("value"), "datefin", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateFin)).val);

    } else { // this.initialMonth between 1-11 ----- 2 posibilities

      if (this.initialMonth <= dateDeb.getMonth()) {
        dateDeb.setMonth(this.initialMonth);
        CWSTR.setElValue(this.model.get("value"), "datedeb", CWTYPE.DATE
          .parse(CWTYPE.DATE.dateToStr(dateDeb)).val);

        if (dateDeb.getMonth() - 1 >= 0) { // we only show 12 months, then the last month is the first - 1
          dateFin.setMonth(dateDeb.getMonth() - 1);
          dateFin.setYear(dateDeb.getFullYear() + 1);
        } else { // if the first month is 0 (January) we have to set month 11(December)
          dateFin.setMonth(11);
          dateFin.setYear(dateDeb.getFullYear());
        }
        CWSTR.setElValue(this.model.get("value"), "datefin", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateFin)).val);
      } else { // !!!!
        dateFin = dateDeb;
        dateFin.setMonth(this.initialMonth);
        CWSTR.setElValue(this.model.get("value"), "datefin", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateFin)).val); // !!!!

        if (dateDeb.getMonth() + 1 <= 11) {
          dateDeb.setMonth(dateDeb.getMonth() + 1);
          dateDeb.setYear(dateDeb.getFullYear() - 1);
        } else {
          dateDeb.setMonth(0);
          dateDeb.setYear(dateDeb.getFullYear());
        }
        CWSTR.setElValue(this.model.get("value"), "datedeb", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateDeb)).val); // !!!!
      }
    }
  }

  render(): CWFormView {
    const json = { 'i18n': i18n };
    this.$el.html(this.template(json));
    this.$el.css({ "height": "100%", "width": "100%" });

    this.drawCalendar();

    if (this.context.ctxAfficheAnnee === false) {
      this.$el.find(".phx-vueannuelle-header-years").hide();
    }
    if (this.context.ctxEcran === "calendacc") {
      this.$el.find(".tableCalendrier").css({ "overflow": "initial" });
    }

    // speed up searching dates for highlight
    this.searchMonthsAndNumberDays();
    if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxDateEnEvidence) && this.workflow.activeTab !== 1) {
      this._markToday();
    }
    return this;
  }

  /**
   * draw empty calendar
   */
  drawCalendar(): void {
    const id = "tableCalendrierJourEx";
    const table = $("<table class='" + this.cid + " " + id + " disable-select'>");

    this.scalendar = this.scalendar || {};
    this.scalendar.datedeb = this.model.get("value").get("datedeb");
    this.scalendar.datefin = this.model.get("value").get("datefin");
    this.scalendar.nbMonth = this._diffMonths(this.scalendar.datedeb, this.scalendar.datefin);
    this.scalendar.headers = this._calculateHeader(this.scalendar.nbMonth);
    this.scalendar.data = this._calculateDays();
    if (this.context.ctxEcran === "calmarqueurdat") {
      table.addClass("d-flex flex-column");
    }
    table.append(this._drawHeader(this.scalendar.headers));
    table.append(this._drawDays(this.scalendar.headers, this.scalendar.data));
    this.workflow.presenceAfterEvent = false;
    this.$el.find(".tableCalendrier").empty();
    this.$el.find(".tableCalendrier").append(table);
  }

  _diffMonths(date1: any, date2: any): any {
    date1 = CWTYPE.DATE.strToDate(date1, "YYYYMMDD");
    date2 = CWTYPE.DATE.strToDate(date2, "YYYYMMDD");
    // Remember, months are 0 based in JS
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    let month1 = date1.getMonth();
    let month2 = date2.getMonth();
    if (month1 === 0) { // Have to take into account
      month1++;
      month2++;
    }
    // If is the same month the diff is 0, for that we need to subtract 1 to the result.
    return ((year2 - year1) * 12 + (month2 - (month1 - 1))) - 1;
  }

  /**
   * Group of functions to draw the calendar
   */
  _calculateHeader(nbMonth: number): { [key: string]: any } {
    const tblColumns = [];
    const months: any = i18n.t('common:monthNames', { returnObjects: true });
    let date: any = CWTYPE.DATE.strToDate(this.scalendar.datedeb, "YYYYMMDD");
    let month: any, year: any = "";

    for (let i = 0; i <= nbMonth; i++) {
      month = date.getMonth();
      year = date.getFullYear();

      const property = months[month] + year;
      // tblColumns.push({title : months[month] + " " + year, property : property, width : 100});
      tblColumns.push({
        title: months[month],
        property: property,
        width: 100,
        month: month,
        year: year
      });

      date = this._nextMonthDate(date, false);
    }
    return tblColumns;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _drawHeader(headers?: any, _id?: any): any {
    const thead = $("<thead>");
    // const tr1 = $("<tr class='phx-vueannuelle-header-years'>");

    // if (headers.length > 0) {
    //   let year = headers[0].year;
    //   let span = 0;
    //   _.each(headers, (column: any, index: number) => {
    //     if (column.year == year && index != headers.length - 1) {
    //       span++;
    //     } else {
    //       if (index == headers.length - 1) {
    //         span++;
    //       }
    //       let th = $("<th class='phx-vueannuelle-header' colspan = '" + span + "'>");
    //       let div = $("<div class='cw-texteAllege'>").text(year);
    //       if (this.headerRender) {
    //         this.headerRender(div, null, year, null, this);
    //       }
    //       th.append(div);
    //       tr1.append(th);
    //       span = 1;
    //       year = column.year;
    //     }
    //   }, year, span, headers.length);
    // }

    const tr2 = (UTILS.isFirefox() && this.context.ctxEcran.indexOf("agenda") >= 0) ? $("<tr class='d-flex'>") : $("<tr>");
    let divContainerRow = null;
    this.divRow = $("<div class='row'>");
    tr2.append("<th class='thContainer'></th>");
    if (this.context.ctxEcran === "calmarqueurdat") {
      divContainerRow = $("<div>");
      divContainerRow.css({ "display": "inline-block", "width": "calc(100% - 40px)" });
    }

    _.each(headers, (column: any) => {
      // column.month++ cause in JS is 0-index, and we want it 1-index
      let month = column.month++;
      month = column.month < 10 ? "0" + column.month : column.month;
      const th = $("<th data-month ='" + month + "' data-year ='" + column.year + "' class='phx-vueannuelle-header' colspan = '1'>");
      const div = $("<div class='divHeader background'><span class='cw-texteAnuelleHeader spanText'>" + column.title + "</span></div>");
      let div2 = null;
      if (this.context.ctxEcran === "calmarqueurdat") {
        th.addClass("col-1 p-0");
        div2 = $("<div class='divHeader2'>");
      }

      if (this.headerRender) {
        const daysInMonth = this._daysInMonth(month - 1, column.year);
        this.headerRender(div, month, column.year, daysInMonth, this);
      }
      th.append(div);

      if (this.context.ctxEcran === "calmarqueurdat") {
        th.prepend(div2);
        this.divRow.append(th);
      } else {
        tr2.append(th);
      }
    });
    if (this.context.ctxEcran === "calmarqueurdat") {
      divContainerRow.append(this.divRow);
      tr2.append(divContainerRow);
    }
    //thead.append(tr1);
    thead.append(tr2);
    const tr3 = $("<tr style='height:3px'>");
    thead.append(tr3)

    return thead;
  }

  _drawDays(tblColumns: { [key: string]: any }, data: { [key: string]: any }): any {
    const tbody = $("<tbody>");
    let strDate = null;

    for (let i = 0; i < 31; i++) {
      const tr = (UTILS.isFirefox() && this.context.ctxEcran.indexOf("agenda") >= 0) ? $("<tr class='d-flex'>") : $("<tr>");
      const numDayTd = $("<td>").addClass("phx-vueannuelle-cell numDaysAgenda");
      if (this.context.ctxEcran === "calendacc") {
        numDayTd.css({ "left": "-24px" });
      }

      numDayTd.attr("data-day", i + 1)
      numDayTd.text(i + 1);
      numDayTd.appendTo(tr);
      let divRow = null;
      let divContainerRow = null;
      if (this.context.ctxEcran === "calmarqueurdat") {
        divContainerRow = $("<div class='divContainerRow'>");
        divRow = $("<div class='row'>");
      }
      for (let j = 0; j < tblColumns.length; j++) {

        const date = data[j][i];
        strDate = CWTYPE.DATE.dateToStr(date, "YYYYMMDD");
        let td = null;
        td = $("<td>");
        // columns of the number the day
        if (date) {

          const formattedDate = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(date)).val;
          const dayNameInitialCap = i18n.t('common:dayNamesInitialCap', { returnObjects: true })[date.getDay()];
          const dayWeek = date.getDay();
          const lastDay = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(CWTYPE.DATE.getWeeklySunday(date))).val;
          let ele = null;

          // create divs into the td
          td.addClass("phx-cell tdContainer");

          const div = $("<div>");
          div.addClass("mainCont")

          if (this.context.ctxEcran === "calmarqueurdat") {
            td.addClass("col-1 p-0");
          }

          td.attr("data-date", formattedDate);

          if (formattedDate !== lastDay) {
            div.addClass("borderBottomAgendaDay");
            div.addClass("normDay")
          } else {
            div.addClass("borderBottomAgendaWeek");
            div.addClass("endWeek")
          }

          if (i === 0) {
            div.addClass("cellHeightFirstLine");
          } else {
            div.addClass("cellHeight");
          }

          //let divDay = $("<div>").addClass("day phx-vueannuelle-day").css({ "position": "absolute", "top": 0, "left": "43px", "height": "inherit", "width": "42px" });

          // if (this.isJourHorRepos(formattedDate) === true) {
          //   numDayDiv.addClass("ui-phx-horaire-repos");
          // } else if (dayWeek == 6 || dayWeek == 0) {
          //   numDayDiv.addClass("ui-phx-entete-jour-week-end");
          // }

          //divDay.append(numDayDiv);

          let clases = "phx-vueannuelle-cell";
          if (dayWeek === 6 || dayWeek === 0) {
            if (this.isJourHorRepos(formattedDate) === true && this.isJourFerie(formattedDate) === false) {
              clases += " ui-phx-horaire-repos";
            } else {
              if (this.isJourFerie(formattedDate) === false) {
                clases += " ui-phx-entete-jour-week-end";
              }
            }
          }
          let vacancesDiv = null;
          const nameDayDiv = $("<div class='" + clases + " nameDayDiv'>").text(dayNameInitialCap);
          if (this.isJourExceptionnel(formattedDate) || this.isJourFerie(formattedDate)) {
            nameDayDiv.css({ "border-right": "1px solid white" });
          }

          vacancesDiv = $("<div class='vacancesDiv'>");

          if (!CWSTR.isBlank(this.workflow.get("plusopt"))) {
            if (this.workflow.get("plusopt").get("plusHorJ") === true) {
              nameDayDiv.css({ "border-right": "1px solid white" });
            }
          }

          if (!CWSTR.isBlank(this.workflow.mainModel)) {
            ele = this.workflow.mainModel.models[0].get("cel").get(formattedDate);
          }

          if ((!CWSTR.isBlank(ele))) {
            if ((!CWSTR.isBlank(ele.get("forfaitj")) && ele.get("forfaitj").code === "0") || !ele.get("presence")) {

              if (this.isJourExceptionnel(formattedDate)) {
                div.addClass("ui-phx-jours-exceptionnel").attr("title", CWTYPE.DATE.format(strDate, CWTYPE._getFormatByCode("DATE_L")));
              } else if (this.isJourFerie(formattedDate)) {
                div.addClass("ui-phx-jours-ferie").attr("title", CWTYPE.DATE.format(strDate, CWTYPE._getFormatByCode("DATE_L")));
              }
            }
          }
          else {
            if (this.isJourExceptionnel(formattedDate)) {
              nameDayDiv.addClass("ui-phx-jours-exceptionnel");
            } else if (this.isJourFerie(formattedDate)) {
              nameDayDiv.addClass("ui-phx-jours-ferie");
            }
          }

          //Vacances
          if (this.vacancesCellRender) {
            this.vacancesCellRender(strDate, vacancesDiv);
          }

          if (!CWSTR.isBlank(this.context) && this.context.ctxEcran !== "calmarqueurdat") {
            const marqueurDiv = $("<div class='marqueurDiv'>");
            let marq: any, typeMarq: any;
            marq = null;
            _.each(this.workflow.marqueursModel.get("marqueurs"), (marqueur: any) => {
              if (formattedDate >= marqueur.dated && formattedDate <= marqueur.datef) {
                marq = marqueur;
              }
            })
            if (!CWSTR.isBlank(marq)) {
              typeMarq = _.findWhere(this.workflow.marqueursModel.get("typesmarqueur"), { code: marq.marqueur });
              if (!CWSTR.isBlank(typeMarq)) {
                marqueurDiv.addClass(typeMarq.style.affichage);
                marqueurDiv.attr('title', this.workflow.buildMarqTooltip(typeMarq));
                if (this.context.ctxEcran === "calendacc") {
                  div.append(marqueurDiv);
                } else {
                  td.append(marqueurDiv);
                }
              }
            }
          }


          const firstDiv = $("<div class='firstDiv'>");
          td.append(firstDiv);
          if (this.context.ctxEcran === "calendacc" || this.context.ctxEcran === "calmarqueurdat") {
            div.append(vacancesDiv)
          } else {
            td.append(vacancesDiv)
          }

          div.append(nameDayDiv);

          const tdClass = "jourex";
          // if (dayWeek == 6 || dayWeek == 0) {
          //   if (this.isJourHorRepos(formattedDate) === true) {
          //     tdClass += " ui-phx-horaire-repos";
          //   } else {
          //     tdClass += " ui-phx-entete-jour-week-end";
          //   }
          // }
          const divDate = $("<div class='" + tdClass + "' data-date='" + formattedDate + "'>");
          if (dayWeek === 6 || dayWeek === 0) {
            if (this.context.ctxEcran !== "calmarqueurdat") {
              divDate.html("&nbsp");
            }
          }
          if (this.cellRender && this.context.ctxEcran !== "calendacc") {
            this.cellRender(divDate, formattedDate, div, this, i, j);
          } else {
            this.cellRender(vacancesDiv, formattedDate, div, this, i, j);
          }

          const divCell = divDate.find(".phx-cell-render");
          if (this.astreinteCellRender && divCell.length > 0) {
            this.astreinteCellRender(strDate, divCell);
          }

          const weekNumber: any = $.datepicker.iso8601Week(CWTYPE.DATE.strToDate(strDate));
          const formatedweekNumber = weekNumber < 10 ? "0" + String(weekNumber) : weekNumber;
          const divWeek = $("<div>");

          if (this.context && this.context.ctxDateEnEvidence === formattedDate && this.workflow.activeTab !== 1) {
            //div.addClass("ui-phx-date-heure-actuelle-border");
            //this._setBorderedHeightProperties(div);
            this._markToday();
          }

          if (!CWSTR.isBlank(this.workflow.get("plusopt"))) {
            if (this.workflow.get("plusopt").get("plusHorJ") === false) {
              td.addClass("position-relative");
            }
          }

          if ((dayWeek === 1 || strDate === "20200101")) {
            if (CWSTR.isBlank(this.workflow.get("plusopt"))) {
              divWeek.html(formatedweekNumber);
              //calendrier case
              if (this.context.ctxEcran === "calmarqueurdat") {
                divWeek.addClass("cw-texteWeekNumber numWeek");
              } else {
                divWeek.addClass("cw-texteWeekNumber");
              }

              divDate.prepend(divWeek);
            } else {
              //agenda case
              if (this.workflow.get("plusopt").get("plusHorJ") === true) {
                //avec horaire
                divWeek.html(formatedweekNumber);
                divWeek.addClass("cw-texteTresAllege position-relative ml-1");
                vacancesDiv.append(divWeek);
              } else if (!CWSTR.isBlank(this.mainModel.models[0].get("cel").get(strDate)) && this.mainModel.models[0].get("cel").get(strDate).get("hcon") === false) {
                //sans horaire
                divWeek.html(formatedweekNumber);
                divWeek.addClass("weekNumber mr-2");
                if (i === 30) {
                  divWeek.addClass("overflowWeekNumber");
                }
                vacancesDiv.append(divWeek);
              }
            }
          }

          div.append(divDate.addClass("divDate"));
          td.append(div);

          // Adds tooltip date cell information
          const dateformat = String(date.getFullYear()) + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
          this._annualCellTooltip(td, dateformat);
        } else {
          const div = $("<div class='day phx-vueannuelle-day'>");
          if (UTILS.isFirefox() && this.context.ctxEcran.indexOf("agenda") >= 0) {
            td.addClass("tdContainer");
          }
          if (this.context.ctxEcran === "calmarqueurdat") {
            td.addClass("col-1 p-0");
          }
          td.append(div);
        }

        if (this.context.ctxEcran === "calmarqueurdat") {
          divRow.append(td);
          divContainerRow.append(divRow);
          tr.append(divContainerRow);
        } else {
          tr.append(td);
        }

      }
      tbody.append(tr);
    }

    return tbody;
  }

  _calculateDays(): any {
    const data = [];
    const nbMonths = this.scalendar.nbMonth;

    let date = CWTYPE.DATE.strToDate(this.scalendar.datedeb, "YYYYMMDD");
    let month: any, year: any = "";

    for (let i = 0; i <= nbMonths; i++) { // For each month
      const dataColumn = [];

      month = date.getMonth();
      year = date.getFullYear();
      const daysInMonth = this._daysInMonth(month, year);

      for (let day = 1; day <= daysInMonth; day++) { // For each day in month
        const auxDate = new Date(year, month, day);
        dataColumn.push(auxDate);
      }

      data.push(dataColumn);
      date = this._nextMonthDate(date, false);
    }
    return data;
  }

  _daysInMonth(month: any, year: any): number { // Here Month is 1 based
    return new Date(year, month + 1, 0).getDate();
  }

  // Return a date of next month ("YYYYMM01") of a date gived.
  _nextMonthDate(date: any, formatted: any): any {
    let month = null;
    let year = null;
    let nextMonth = null;

    if (formatted === true) {
      date = CWTYPE.DATE.strToDate(date, "YYYYMMDD");
    }

    month = date.getMonth();
    year = date.getFullYear();
    // December = 11; Months are 0-indexed in the i18nCom.monthNames and in DATE.getMonth()
    month++;
    if (month === 12) {
      month = 0;
      year++;
    }

    nextMonth = new Date(year, month, 1);

    if (formatted === true) {
      nextMonth = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(nextMonth)).val;
    }

    return nextMonth;
  }

  clickCellCalendrier(event: JQueryEventObject): void {
    const dateSelected = $(event.currentTarget).attr("data-date");
    let typeClick: string = "";
    if (event.type === "click" && event.ctrlKey) {
      typeClick = "ctrlClick"
    } else if (event.type === "click" && event.shiftKey) {
      typeClick = "shiftClick";
    } else {
      typeClick = "click"
    }
    const modeGestion = this.workflow.buttonHeader.comboGererEn.currentCode;
    switch (modeGestion) {

      case "J":
        switch (typeClick) {
          case "click":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              if (this.workflow.activeTab !== 1) {
                this._unselectAllDates(false);
                this.selectCell($(event.currentTarget));
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;
          case "shiftClick":
            //logic for shift click
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              const lastSelectedDate = CWTYPE.DATE.strToDate(this.$el.find(".ui-phx-date-heure-selectionnee-border").parent().attr("data-date"))
              const newSelectedDate = CWTYPE.DATE.strToDate(dateSelected);
              let difDays;
              if (!CWSTR.isBlank(lastSelectedDate)) {
                if (newSelectedDate < lastSelectedDate) {
                  difDays = CWTYPE.DATE.getDiffInDays(newSelectedDate, lastSelectedDate);
                  for (let i = 0; i < difDays; i++) {
                    UTILS.addDays(lastSelectedDate, -1);
                    this.selectCell(this.$el.find(this.$el.find("td.phx-cell[data-date='" + CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(lastSelectedDate)).val + "']")));
                  }
                } else {
                  difDays = CWTYPE.DATE.getDiffInDays(lastSelectedDate, newSelectedDate);
                  for (let i = 0; i < difDays; i++) {
                    UTILS.addDays(lastSelectedDate, 1);
                    this.selectCell(this.$el.find(this.$el.find("td.phx-cell[data-date='" + CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(lastSelectedDate)).val + "']")));
                  }
                }
              } else {
                this.selectCell($(event.currentTarget))
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;

          case "ctrlClick":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              this.selectCell($(event.currentTarget));
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;
        }
        break;
      case "L": //l-d
        switch (typeClick) {
          case "click":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              if (this.workflow.form.isFullWeek()) {
                if (this.workflow.activeTab !== 1) {
                  this._unselectAllDates(false);
                  this.selectFullWeek();
                }
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;

          case "shiftClick":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              if (this.workflow.form.isFullWeek()) {
                const lastSelectedDate = CWTYPE.DATE.strToDate(this.$el.find(".selectionEnd").parent().attr("data-date"));
                this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(lastSelectedDate)).val);
                const newSelectedDate = CWTYPE.DATE.strToDate(dateSelected);
                const lastSunday = CWTYPE.DATE.getWeeklySunday(CWTYPE.DATE.strToDate(dateSelected));
                let difDays;
                if (!CWSTR.isBlank(lastSelectedDate)) {
                  if (newSelectedDate < lastSelectedDate) {
                    difDays = CWTYPE.DATE.getDiffInDays(lastSunday, lastSelectedDate) / 7;
                    for (let i = 0; i < difDays; i++) {
                      const newWeek = CWTYPE.DATE.strToDate(this.selectionModel.get("dateEnd"));
                      UTILS.addDays(newWeek, -7);
                      const nextSunday = CWTYPE.DATE.getWeeklySunday(newWeek);
                      const nextMonday = CWTYPE.DATE.getWeeklyMonday(newWeek);
                      this.selectionModel.set("dateStart", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(nextMonday)).val);
                      this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(nextSunday)).val);
                      this.selectFullWeek();
                    }
                  } else {
                    difDays = CWTYPE.DATE.getDiffInDays(lastSelectedDate, lastSunday) / 7;
                    for (let i = 0; i < difDays; i++) {
                      const newWeek = CWTYPE.DATE.strToDate(this.selectionModel.get("dateEnd"));
                      UTILS.addDays(newWeek, 1);
                      const nextSunday = CWTYPE.DATE.getWeeklySunday(newWeek);
                      const nextMonday = CWTYPE.DATE.getWeeklyMonday(newWeek);
                      this.selectionModel.set("dateStart", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(nextMonday)).val);
                      this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(nextSunday)).val);
                      this.selectFullWeek();
                    }
                  }
                } else {
                  const dateEnd = UTILS.addDays(CWTYPE.DATE.strToDate(this.selectionModel.get("dateStart")), 6);
                  this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateEnd)).val);
                  this.selectFullWeek();
                }
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;

          case "ctrlClick":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              if (this.workflow.form.isFullWeek()) {
                this.selectFullWeek();
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;
        }
        break;
      case "D"://do selection for d-l
        switch (typeClick) {
          case "click":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              if (this.workflow.form.isFullWeek()) {
                if (this.workflow.activeTab !== 1) {
                  this._unselectAllDates(false);
                  this.selectFullWeek()
                }
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;
          case "shiftClick":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              if (this.workflow.form.isFullWeek()) {
                const lastSelectedDate = CWTYPE.DATE.strToDate(this.$el.find(".selectionEnd").parent().attr("data-date"));
                this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(lastSelectedDate)).val);
                const newSelectedDate = CWTYPE.DATE.strToDate(dateSelected);

                if (newSelectedDate.getDay() === 0) {
                  UTILS.addDays(newSelectedDate, 1);
                }

                if (!CWSTR.isBlank(lastSelectedDate)) {
                  const lastSaturdayToSelect = CWTYPE.DATE.getWeeklySaturday(newSelectedDate);
                  let difDays;
                  if (lastSaturdayToSelect < lastSelectedDate) {
                    difDays = CWTYPE.DATE.getDiffInDays(lastSaturdayToSelect, lastSelectedDate) / 7;
                    for (let i = 0; i < difDays; i++) {
                      const newWeek: Date = UTILS.addDays(CWTYPE.DATE.strToDate(this.selectionModel.get("dateEnd")), -7);
                      this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(newWeek)).val, { silent: true })
                      const previousSunday = UTILS.addDays(newWeek, -6);
                      this.selectionModel.set("dateStart", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(previousSunday)).val, { silent: true });
                      this.selectFullWeek();
                    }
                  } else {
                    difDays = CWTYPE.DATE.getDiffInDays(lastSelectedDate, lastSaturdayToSelect) / 7;
                    for (let i = 0; i < difDays; i++) {
                      const newWeek: Date = UTILS.addDays(CWTYPE.DATE.strToDate(this.selectionModel.get("dateEnd")), 1);
                      this.selectionModel.set("dateStart", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(newWeek)).val)
                      const nextSaturday = CWTYPE.DATE.getWeeklySaturday(UTILS.addDays(newWeek, 1));
                      this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(nextSaturday)).val);
                      this.selectFullWeek();
                    }
                  }
                } else {
                  const dateEnd = UTILS.addDays(CWTYPE.DATE.strToDate(this.selectionModel.get("dateStart")), 6);
                  this.selectionModel.set("dateEnd", CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateEnd)).val);
                  this.selectFullWeek()
                }
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
            break;
          case "ctrlClick":
            if (this.workflow.form.dateIsInPeriode(dateSelected, modeGestion)) {
              if (this.workflow.form.isFullWeek()) {
                this.selectFullWeek()
              }
            } else {
              CWMSGS.showError(i18n.t("messages:GT_2416"), null, this.$appendTo)
            }
        }
        break;
    }
  }

  selectCell(target: any): void {
    const element = this.$el.find(target);
    const mainCont = element.find(".mainCont");

    if (mainCont.hasClass("borderBottomAgendaDay")) {
      mainCont.removeClass("borderBottomAgendaDay");
    } else if (mainCont.removeClass("borderBottomAgendaWeek")) {
      mainCont.removeClass("borderBottomAgendaWeek");
    }

    if (this.workflow.activeTab === 1) {
      mainCont.addClass("ui-phx-date-heure-actuelle-border");
    } else {
      mainCont.addClass("ui-phx-date-heure-selectionnee-border");
    }
    this._selectMonthDay(element)
  }

  selectFullWeek(): void {
    const startDate = CWTYPE.DATE.strToDate(this.selectionModel.get("dateStart"));
    let auxDate: any = "";
    for (let i = 0; i < 7; i++) {
      if (i === 0) {
        auxDate = startDate
      }
      const dateElement = this.$el.find("td.phx-cell[data-date='" + CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(auxDate)).val + "']");
      this._selectPeriode(dateElement);
      this._selectMonthDay(dateElement)
      auxDate = UTILS.addDays(auxDate, 1);
    }
  }

  isJourExceptionnel(content?: string): boolean {
    //override
    return !CWSTR.isBlank(content);
  }

  isHoraireExceptionnel(content?: string): boolean {
    //override
    return !CWSTR.isBlank(content);
  }

  isJourFerie(content?: string): boolean {
    //override
    return !CWSTR.isBlank(content);
  }

  isJourHorRepos(content?: string): boolean {
    //override
    return !CWSTR.isBlank(content);
  }

  _scrollToDay(): void {
    if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxDateEnEvidence) && this.workflow.activeTab !== 1) {
      const $lPosDay = this.$el.find("td.phx-cell[data-date=" + this.context.ctxDateEnEvidence + "]");
      const $lposTable = this.$el.find(".tableCalendrier");

      if ($lPosDay && $lPosDay.length > 0 && $lposTable && $lposTable.length > 0) {
        const lPositionDayLeftComplet = $lPosDay.position().left + $lPosDay.width() - 10 /*barre de l'ascenseur*/;
        const lWidthTable = $lposTable.width();
        const lJour = (this.context.ctxDateEnEvidence.length > 6) ? parseInt(this.context.ctxDateEnEvidence.substr(6), 10) : 0;
        const lPositionDayTopComplet = $lPosDay.height() * lJour + 33; //23 de l'entête plus 10 de la barre horizontal
        const lHeightTable = $lposTable.height();

        if (lPositionDayLeftComplet > 0 && lWidthTable > 0 && lPositionDayLeftComplet > lWidthTable) {
          this.$el.find(".tableCalendrier").scrollLeft(lPositionDayLeftComplet - lWidthTable);
        }
        if (lJour > 0 && lPositionDayTopComplet > 0 && lHeightTable > 0 && lPositionDayTopComplet > lHeightTable) {
          this.$el.find(".tableCalendrier").scrollTop(lPositionDayTopComplet - lHeightTable);
        }
      }
    }
  }
}
