import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';

/**
 * Model for Brique 'Demandes Collaborateurs'
 */
export class CWDemandesCollaborateursModel extends CWReadOnlyModel {
  urlRoot: string;
  typeevenement: string;
  defaults: any;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    options = options || {};
    super(attributes, options);
    this.urlRoot = Configuration.restRoot + "/rest/brique/respo/demande";
    this.typeevenement = "";
    this.defaults = {
      "valeur": 0
    }
    this.url = (): string => {
      return this.urlRoot + "/" + this.typeevenement;
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    return response;
  }
}