import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWWkfHistoModel } from './cwWkfHisto.model';

export class CWHistoDemModelColl<TModel extends CWWkfHistoModel = CWWkfHistoModel> extends CWPaginatedCollection {

  public ctxModeRech: string | boolean;
  public ctxDemId: string;


  constructor(models?: TModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    super(models, options);
    this.paginated = false;
    this.url = (): string => {
      const newUrl = Configuration.restRoot + "/rest/wkf/histo/demande/" + encodeURIComponent(this.ctxModeRech + "," + this.ctxDemId);
      return newUrl;
    }
  }
}