import * as Backbone from 'Backbone';
import _ from 'underscore';
import commonViewsMenuTPL from './cwViewsMenu.tpl.html';
import { CWReadOnlyModel } from '../models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


interface CWViewsMenuViewOptions extends Backbone.ViewOptions {
  defaultView: string;
  omitedViews: any;
  viewsForDisable: any;
  menuViews: any;
}

export class CWViewsMenuView extends Backbone.View {
  viewsForDisable: any;
  menuViews: any;
  classPrefix: string;
  context: { [key: string]: any };
  menu: JQuery;

  /**
   * View of the menu used to select the view we want to show
   */
  constructor(options: CWViewsMenuViewOptions) {
    options.className = "cw-grid-menu";
    /**
     * Event when click on triangle to toggle menu.
     * Event when click on a link to select a view.
     */
    options.events = {
      "click .cw-hd-main-btn > .cw-grid-vues-btn": "_toggleMenu",
      "click .cw-hd-main-btn  ul.cw-grid-vues-chooser li  a": "_selectView"
    }
    super(options);
    this.template = commonViewsMenuTPL;
    this.model = new CWReadOnlyModel({
      menuView: "",
      title: "",
      defaultView: "",
      omitedViews: "",
      defaults: {
        menuView: "",
        title: "",
        defaultView: "",
        omitedViews: ""
      }
    });
    if (!CWSTR.isBlank(options.defaultView)) {
      this.model.set("menuView", options.defaultView);
      this.model.set("defaultView", options.defaultView);
    } else {
      throw new Error("You must define a default vue to use a ViewsMenuView");
    }
    if (!CWSTR.isBlank(options.omitedViews)) {
      this.model.set("omitedViews", options.omitedViews);
    }
    if (!CWSTR.isBlank(options.viewsForDisable)) {
      this.viewsForDisable = options.viewsForDisable;
    }
    if (!CWSTR.isBlank(options.menuViews)) {
      this.menuViews = options.menuViews;
      //The name must be informed for each view
      _.each(_.keys(options.menuViews), (key) => {
        if (CWSTR.isBlank(options.menuViews[key].name)) {
          throw new Error("You must define the name attribute for each view to use a ViewsMenuView");
        }
        if (CWSTR.isBlank(options.menuViews[key].table)) {
          throw new Error("You must define the table attribute for each view to use a ViewsMenuView");
        }
        this.menuViews[key].view.model.rendered = false;
      }, this);
    } else if (!options.menuViews) {
      throw new Error("You must define the menuViews attribute to use a ViewsMenuView");
    }
    // common class prefix
    this.classPrefix = "cw-grid-menu-";
    this.model.on("renderView", this._renderView, this);
    this.model.on("periodeChange", this._changePeriode, this);
    this.model.on("change:omitedViews", this._omitViews, this);
    this.model.on("resetDefault", this._resetDefault, this);
    this.model.on("form:edit", this._manageDisableSelectorButton, this);
  }

  setPeriodeContext(periode: { [key: string]: any }): void {
    this.context = {};
    this.context.periode = periode;
  }

  /**
   * Associate the views $el, and render the default view
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  render(callbackAfterRenderLeftView: () => void): CWViewsMenuView {
    const defaultView = this.model.get("defaultView");
    const textVue = this.menuViews[defaultView].name;
    const dynamic = { text: textVue, UTILS: UTILS };
    let $posMenuItem: JQuery = null;

    this.model.set("menuView", defaultView, { silent: true });
    this.$el.html(this.template(dynamic));
    //remove all the events
    this.undelegateEvents();
    //Restart events
    this.delegateEvents();
    this.menu = this._configureMenu();
    this.menu.hide();
    this._checkSelectedMenuOption(defaultView);
    this.$el.find(".cw-hd-leftViews").css("margin-left", "-25px");
    this.$el.find(".cw-hd-leftViews").html("");
    _.each(_.keys(this.menuViews), (key) => {
      this.$el.find(".cw-hd-leftViews").append(this.menuViews[key].view.el);
      $(this.menuViews[key].view.el).hide();
    });
    //Set periode
    if (this.context && this.context.periode) {
      this._changePeriode(this.context.periode);
    }
    if (callbackAfterRenderLeftView) { //When user has selected a url with an id
      this.menuViews[defaultView].view.render(callbackAfterRenderLeftView);
    } else {
      this.menuViews[defaultView].view.render();
    }
    $(this.menuViews[defaultView].view.el).show();
    this.menuViews[defaultView].view.model.rendered = true;
    //si l'il y a une seule vue pour afficher l'arbre, on devra cacher l'icône "..."    
    $posMenuItem = this.$el.find(".cw-hd-main-btn ul li[style*='display: none']");
    if ($posMenuItem && $posMenuItem.length > 0 && (_.keys(this.menuViews).length - $posMenuItem.length) <= 1) {
      this.$el.find(".cw-hd-main-btn .cw-grid-vues-btn").hide();
    }
    return this;
  }

  /**
   * Add the different views to the menu list.
   */
  _configureMenu(): JQuery {
    const $menu = $(".cw-hd-main-btn > ul", this.el);
    const omitedViews = this.model.get("omitedViews");
    const vuesLength = Object.keys(this.menuViews).length;

    _.each(_.keys(this.menuViews), (key, index) => {
      if (index < vuesLength) {
        const li = $("<li></li>");
        const a = $("<a class=\"" + this.classPrefix + key + "\"></a>");
        const span = $("<span></span>").addClass("cw-icon cw-icon-10 cw-cercle-plein-icon c-panneauMenu__tickIcon");

        _.each(omitedViews, (viewName) => {
          if (viewName === key) {
            li.hide();
          }
        });
        a.append(span);
        a.append(this.menuViews[key].name);
        li.append(a);
        $menu.append(li);
      }
    }, this);
    this.menu = $menu;
    $menu.menu({ icons: { submenu: "none" } });
    $menu.addClass("c-panneauMenu");
    $menu.css("cursor", "pointer");
    return $menu;
  }

  /**
   * Shows all view menu options except the omitted ones.
   * Ommit views from the menu when they are not accessible
   */
  _omitViews(): void {
    const omitedViews = this.model.get("omitedViews");
    const vuesLength = Object.keys(this.menuViews).length;

    _.each(_.keys(this.menuViews), (key, index) => {
      if (index < vuesLength) {
        const li = this.$el.find(".cw-grid-menu-" + key).closest("li");

        li.show();
        _.each(omitedViews, (viewName) => {
          if (viewName === key) {
            li.hide();
          }
        });
      }
    }, this);
  }

  /**
   * Shows the selected view and hides the others.
   * Executes Renderviews as a callback from the usecase, to render the view if it hasn't been rendered yet
   */
  _selectView(event: JQueryMouseEventObject): void {
    const viewName = event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");

    this._toggleMenu();
    if (this.model.get("menuView") !== viewName) { //Selected view has changed
      const textVue = this.menuViews[viewName].name;
      let ancientView: any = null;

      //Change title
      this.$el.find(".cw-viewsmenu-title").text(textVue);
      this.model.trigger("changedShownView");
      //Launches changed:menuView event
      ancientView = this.model.get("menuView");
      this.model.set("menuView", viewName, { silent: true });
      _.each(_.keys(this.menuViews), (key) => {
        $(this.menuViews[key].view.el).hide();
      }, this);
      $(this.menuViews[viewName].view.el).show();
      this._checkSelectedMenuOption(viewName);
      if (!CWSTR.isBlank((this.model as any)._events["change:menuView"])) { //If a usecase is listening to change menuView
        this.model.trigger("change:menuView", this.model, this.renderViews, ancientView, this);
      } else { // If nobody listening to change menuView, then launch renderviews.
        this.renderViews(ancientView, this);
      }
    }
  }

  _checkSelectedMenuOption(viewName: string): void {
    this.$el.find(".cw-grid-vues-chooser .cw-icon").hide();
    this.$el.find(".cw-grid-vues-chooser .cw-grid-menu-" + viewName + " .cw-icon").show();
  }

  /**
   * Opens or Closes the menú.
   */
  _toggleMenu(): void {
    if (this.menu.is(":visible")) {
      this.menu.hide();
    } else {
      const targetEl = this.$el.find(".cw-hd-main-btn");

      this.menu.show().position({
        my: "right top",
        at: "bottom",
        of: targetEl
      });
      $(document).one("mousedown", (event) => {
        const element = $(targetEl).find(event.target as any);

        if (element.length === 0) {
          // mousedown out of the button
          this._toggleMenu();
        }
      });
    }
  }

  /**
   * Renders the current view if it hasn't been rendered yet.
   */
  renderViews(viewName: string, view: any, callback?: any): void {
    view.model.set("menuView", viewName, { silent: true });
    if (view.context && view.context.periode) {
      view._changePeriode(view.context.periode);
    }
    view.menuViews[viewName].view.render(callback);
  }

  /**
   * Resets the menu by showing current default view
   * reseting rendered parameter in order to indicate that none the views has been rendered yet
   * (except for tables that are only rendered once in their whole lifecycle).
   *
   */
  _resetDefault(): void {
    const defaultView = this.model.get("defaultView");

    _.each(_.keys(this.menuViews), (key) => {
      //Initialize rendered attribute to all views except of tables, because
      //tables have got to be rendered only once
      if (this.menuViews[key].table !== true) {
        this.menuViews[key].view.model.rendered = false;
      }
    }, this);
    //Show current default view
    _.each(_.keys(this.menuViews), (key) => {
      $(this.menuViews[key].view.el).hide();
    }, this);
    $(this.menuViews[defaultView].view.el).show();
    this.model.set("menuView", defaultView, { silent: true });
    this.model.trigger("changedShownView");
  }

  /**
   * Renders the view
   */
  _renderView(viewName: string, callback: any): void {
    this.model.set("menuView", viewName, { silent: true });
    _.each(_.keys(this.menuViews), (key) => {
      $(this.menuViews[key].view.el).hide();
    }, this);
    $(this.menuViews[viewName].view.el).show();
    if (callback) {
      this.menuViews[viewName].view.render(callback);
    } else {
      this.menuViews[viewName].view.render();
    }
    this.menuViews[viewName].view.model.rendered = true;
  }

  /**
   * Changes the periode text
   */
  _changePeriode(periode: { [key: string]: any }): void {
    let val = "";
    const viewName = this.model.get("menuView");
    const textVue = this.menuViews[viewName].name;

    if (!periode.datedeb || periode.datedeb === CWTYPE.DATE.INITIAL) {
      if (periode.datefin && periode.datefin !== CWTYPE.DATE.INFINITY) {
        val = i18n.t('common:viewsmenuview.temporalContextJusquau', { "0": CWTYPE.DATE.format(periode.datefin), interpolation: { escapeValue: false } });
      }
    } else {
      if (!periode.datefin || periode.datefin === CWTYPE.DATE.INFINITY) {
        val = i18n.t('common:viewsmenuview.temporalContextAPartirDu', { "0": CWTYPE.DATE.format(periode.datedeb), interpolation: { escapeValue: false } });
      } else {
        if (periode.datedeb === periode.datefin) {
          val = i18n.t('common:viewsmenuview.temporalContextResultatAu', { "0": CWTYPE.DATE.format(periode.datedeb), interpolation: { escapeValue: false } });
        } else {
          val = i18n.t('common:viewsmenuview.temporalContextDuAu', { "0": CWTYPE.DATE.format(periode.datedeb), "1": CWTYPE.DATE.format(periode.datefin), interpolation: { escapeValue: false } });
        }
      }
    }
    if (!CWSTR.isBlank(val)) {
      this.$el.find(".cw-viewsmenu-title").text(textVue + " - " + val);
    } else {
      this.$el.find(".cw-viewsmenu-title").text(textVue);
    }
  }

  _manageDisableSelectorButton(isDisabled: boolean): void {
    let isStruct = false;

    if (this.viewsForDisable) {
      for (let i = 0; i < this.viewsForDisable.length; i++) {
        if (this.viewsForDisable[i] === this.model.get("menuView")) {
          isStruct = true;
        }
      }
    }
    if (isStruct === true) {
      const $button = this.$el.find(".cw-grid-vues-btn");

      if (isDisabled === true) {
        $button.hide();
      } else {
        $button.show();
      }
    }
  }
}
