import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import TPLMemu from '../ctime-menu.tpl.html';
import { CWMenuZone } from './cwMenuZone.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWWorkFlowModel } from '../models/cwWorkFlow.model';
import { CWZoneSelectorView } from './cwZoneSelector.view';
import { i18n } from 'src/i18n.js';

/**
 * View of the header of the home
 */
export class CWMenuView<TModel extends CWWorkFlowModel = CWWorkFlowModel> extends Backbone.View<TModel> {

  zonesView: CWZoneSelectorView;
  menuZoneView: any;
  menuCollapse: boolean; //voir dans le futur si cette valeur sera mis à jour dans la préférence
  menuFloat: boolean;
  outsideClickListener: (arg1: JQueryEventObject) => void;
  clickIframe: number;

  constructor(options: Backbone.ViewOptions<TModel> | any) {
    options = options || {};
    options.tagName = options.tagName || "span";
    options.events = _.extend({
      "mouseover #cw-logo": "_logoClick",
      "keyup #cw-logo": "_keyboardLogoClick",
      "click .cw-menu-bottom": "_bottomClick",
      "click #cw-menu a": "_optionClick",
      "click #cw-menu.modeReduced div": "_checkSlideMode",
      "keyup #cw-menu.modeReduced div": "_keyboardCheckSlideMode"
    }, options.events);

    super(options);
    this.template = TPLMemu;
    this.zonesView = null;
    this.menuZoneView = null;
    this.template = TPLMemu;
    this.menuCollapse = false; //voir dans le futur si cette valeur sera mis à jour dans la préférence
    this.menuFloat = false;
    this.outsideClickListener = (ev: JQueryEventObject): void => {
      if (!$(ev.target).hasClass("cw-menu") && $(ev.target).parents(".cw-menu").length === 0) {
        this._optionClick(ev);
      }
    };
  }

  render(): CWMenuView<TModel> {
    const json = { "i18n": i18n, "auth": this.model.authModel.toJSON() };

    this.$el.html(this.template(json));
    this.$el.addClass('d-flex flex-column');
    this.zonesView = new CWZoneSelectorView({
      el: $(".phx-zone-selector", this.el),
      model: this.model
    });
    this.zonesView.render();
    this.menuZoneView = new CWMenuZone({
      el: $("#cw-menu-bar", this.el),
      model: this.model
    })
    this.menuZoneView.render();
    this.$el.find(".phx-zone-selector").css("position", "relative").css("display", "inline-block");
    return this;
  }

  // If menu is in Reduced mode,
  // expand the menu so the options
  // can be clicked
  _checkSlideMode(event: JQueryEventObject): void {

    const hasSubmenu: boolean = $(event.currentTarget).hasClass("ctime-menu-submenu-title");
    const isMenuItem: boolean = $(event.currentTarget).hasClass("cw-menu-item-title");
    if (isMenuItem) {
      if (hasSubmenu) {
        this._slideMenu(event);
      } else {
        this._optionClick(event);
      }
    }
  }

  _keyboardCheckSlideMode(event: JQueryEventObject): void {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this._checkSlideMode(event);
    }
  }

  _slideMenu(event: JQueryEventObject): void {
    const isExpanded: boolean = $(event.currentTarget).hasClass("cw-menu-item-group-selected");
    if (isExpanded) {
      event.stopPropagation();
      event.preventDefault();
    }

    this._toggleMenuSlide(true, event);
    this._updateMenuOptions(false, event);
    this._setUpClickListeners(true);
  }

  // If menu is in Reduced mode,
  // reduce it back when an option
  // is clicked
  _optionClick(event: JQueryEventObject): void {
    if (this.menuFloat === true) {
      this._toggleMenuSlide(false, event);
      this._updateMenuOptions(true, event);
      this._setUpClickListeners(false);
      this.menuFloat = false;
    }
  }

  // logo is clicked
  _logoClick(event: JQueryEventObject): void {
    const isReduced: boolean = this.$el.find("#cw-menu").hasClass("modeReduced");

    if (isReduced) {
      this._slideMenu(event);
    }
  }

  _keyboardLogoClick(event: JQueryEventObject): void {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this._logoClick(event);
    }
  }

  _bottomClick(event: JQueryEventObject): void {

    const isReduced: boolean = this.$el.find("#cw-menu").hasClass("modeReduced");
    if (isReduced) {
      this._slideMenu(event);
    } else {
      this._optionClick(event);
    }
  }

  // Change menu mode
  _toogleMenu(isHome: JQueryEventObject | boolean): void {
    if (_.isBoolean(isHome)) {
      this.menuCollapse = isHome;
    }
    if (!this.isZoneDashboard() || _.isBoolean(isHome)) {
      this.menuCollapse = !this.menuCollapse;
      this._toggleMenuSlide(!this.menuCollapse, isHome);
      this._updateMenuOptions(this.menuCollapse, isHome);
      setTimeout((): void => {
        this.model.trigger("resize:menu");
      }, 550);
    }
  }

  _setUpClickListeners(enable: boolean): void {
    if (enable) {
      document.addEventListener("click", this.outsideClickListener);
      // Analytics iframe click detection
      if (document.getElementById("ifrdbmm")) {
        this._detectsIframeClick();
      }
    } else {
      document.removeEventListener("click", this.outsideClickListener);
      clearInterval(this.clickIframe);
    }
  }

  /**
   * cross-domain analytics iframe click detection
   */
  _detectsIframeClick(): void {
    this.clickIframe = window.setInterval((): void => {
      if (document.activeElement === document.getElementById("ifrdbmm")) {
        window.focus();
        clearInterval(this.clickIframe);
        this._toogleMenu(false);
        this._setUpClickListeners(false);
      }
    }, 100);

  }

  // Expand or reduce menu
  // with slide animation
  _toggleMenuSlide(expand: boolean, isHome: JQueryEventObject | boolean): void {
    if (expand) {
      if (isHome === true) {
        this.$el.parents().find("#cw-appContainer .l-general-container").removeClass("cw-menu-float");
        this.$el.addClass("slideIn modeExpanded-dashboard");
        this.$el.find("#cw-menu").addClass("modeExpanded-dashboard");
        this.$el.removeClass("modeExpanded");
        this.$el.find("#cw-menu").removeClass("modeExpanded");
        this.menuFloat = false;
      } else {
        this.$el.parents().find("#cw-appContainer .l-general-container").addClass("cw-menu-float");
        this.$el.removeClass("modeExpanded-dashboard");
        this.$el.find("#cw-menu").removeClass("modeExpanded-dashboard");
        this.$el.addClass("slideIn modeExpanded");
        this.$el.find("#cw-menu").addClass("modeExpanded");
        this.menuFloat = true;
      }
      this.$el.removeClass("slideOut modeReduced");
      this.$el.find("#cw-menu").removeClass("modeReduced");
    } else if (isHome !== true) {
      this.$el.parents().find("#cw-appContainer .l-general-container").addClass("cw-menu-float");
      this.$el.addClass("slideOut modeReduced");
      this.$el.removeClass("slideIn modeExpanded");
      this.$el.find("#cw-menu").addClass("modeReduced");
      this.$el.find("#cw-menu").removeClass("modeExpanded");
      this.$el.removeClass("modeExpanded-dashboard");
      this.$el.find("#cw-menu").removeClass("modeExpanded-dashboard");
      this.menuFloat = false;
    }
  }

  // Show or hide options
  // based in "reduce" parameter
  _updateMenuOptions(reduce: boolean, isHome: JQueryEventObject | boolean): void {
    const lPosMenuHeader = this.$el.find(".cw-menu-header");
    const lPosZonesMenu = this.$el.find(".cw-menu-header .cw-zone-selector");
    const lPosBarMenus = this.$el.find("#cw-menu [class~='cw-menu-item-title'] .ctime-menu-item-libelle");
    const lPosSubMenus = this.$el.find("[id^='cw-menu-item-title-']");
    let lPosIconesArrow = null;
    const lPosMenuSpan = this.$el.find("span.cw-menu-element");

    if (lPosSubMenus.length > 0) {
      lPosIconesArrow = lPosSubMenus.find(".cw-menu-arrow");
    }
    if (reduce && isHome !== true) {
      lPosMenuHeader.css({ "padding-left": 0 });
      lPosMenuHeader.css({ "padding-right": 0 });
      if (lPosZonesMenu.length > 0) {
        lPosZonesMenu.css({ "display": "none" });
      }
      if (lPosBarMenus.length > 0) {
        lPosBarMenus.css({ "display": "none" });
      }
      if (lPosIconesArrow && lPosIconesArrow.length > 0) {
        lPosIconesArrow.addClass("modeReduced");
      }
      if (lPosMenuSpan.length > 0) {
        lPosMenuSpan.addClass("justify-content-center");
      }
    } else if (!reduce) {
      lPosMenuHeader.css({ "padding-left": "26px" });
      lPosMenuHeader.css({ "padding-right": "26px" });

      if (lPosZonesMenu.length > 0) {
        lPosZonesMenu.css({ "display": "flex" });
      }
      if (lPosBarMenus.length > 0) {
        lPosBarMenus.css({ "display": "inline" });
      }
      if (lPosIconesArrow && lPosIconesArrow.length > 0) {
        lPosIconesArrow.removeClass("modeReduced");
      }
      if (lPosMenuSpan.length > 0) {
        lPosMenuSpan.removeClass("justify-content-center");
      }
    }
  }

  isZoneDashboard(): boolean {
    let lRtn = false;
    const lUri = this.zonesView.model.get("uri");

    if (!CWSTR.isBlank(lUri)) {
      const lMatch = lUri.match(/\//g);

      if (lMatch !== null) {
        lRtn = (lMatch.length === 1);
      }
    }
    return lRtn;
  }
}
