module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 groups.forEach ( function(grp) { 
__p+='\n  ';
 if(scope){
__p+=' '+
((__t=(scope))==null?'':__t)+
' ';
 } 
__p+='.phx-dynamicStyle-cell-'+
((__t=(type))==null?'':__t)+
'_'+
((__t=(UTILS.escapeJQueryString(grp.get("cod"))))==null?'':__t)+
' {\n    ';
 if(invert) { 
__p+='\n      color :'+
((__t=(grp.get("bgcolor")))==null?'':__t)+
' !important;\n      background : '+
((__t=(grp.get("color")))==null?'':__t)+
' !important;\n      box-sizing: border-box;\n      border: solid 2px '+
((__t=(grp.get("bgcolor")))==null?'':__t)+
';\n    ';
 } else { 
__p+='\n        color : '+
((__t=(grp.get("color")))==null?'':__t)+
' !important;\n        background : '+
((__t=(grp.get("bgcolor")))==null?'':__t)+
' !important;\n    ';
 } 
__p+='\n    font-weight: bold; text-align: center;\n  }\n';
 }); 
__p+='\n';
}
return __p;
};
