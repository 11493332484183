import * as Backbone from 'Backbone';
import _ from 'underscore';
import CWEttiquetteTPL from './cwEtiquette.tpl.html';
import { CWEtiquetteModel } from './models/cwEtiquette.model';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils';


export type validEtiquetteTypes = "demande" | "accepte" | "refuse" | "revision" | "custom" | "genere" | "auto" | "recap";

export type etiquetteState = "inactif" | "actif" | "custom";

interface CWEtiquetteOptions extends Backbone.ViewOptions<CWEtiquetteModel> {
  /**
   * @param {string} id: Identify for the etiquette
   */
  id: string;
  /**
   * @param {Object} text Text for the diferents states of the etiquette, if only put "enabled" it is copy to the other states
   */
  text: {
    /**
     * @param {string} enabled Text in state enabled (1)
     */
    enabled: string;
    /**
     * @param {string} [disabled] Text in state disabled (0)
     */
    disabled?: string;
    /**
     * @param {string} [tristate] Text when are in the thirth state (2)
     */
    tristate?: string;
  };
  /**
   * @param {"demande" | "accepte" | "refuse" | "revision" | "custom"} type Type of ettiquette
   */
  type: validEtiquetteTypes;
  /**
   * @param {function} [clickAction] Function executed when click in the Etiquette
   */
  clickAction?: (id: string, state: etiquetteState) => void;
  /**
   * @param {"inactif" | "actif" | "custom"} state set the state of the etiquette "inactif", "actif", "custom"
   */
  state: etiquetteState;
  /**
   * @param {Object} subText Set subtext on the etiquette
   */
  subText?: {
    /**
     * @param {string} enabled Subtext in state enabled (1)
     */
    enabled: string;
    /**
     * @param {string} [disabled] Subtext in state disabled (0)
     */
    disabled?: string;
    /**
     * @param {string} [tristate] Subtext when are in the thirth state (2)
     */
    tristate?: string;
  };
  /**
   * @param {string} [tooltip] set the tooltip text for the etiquette
   */
  tooltip?: string;
  /**
   * @param {string} set value to use on filter
   */
  values?: string;
  /**
   * @param {string} icon add an icon to right of the etiquette
   */
  icon?: string;
  /**
   * @param {boolean} onlyIcon show only the icon of the etiquette
   */
  onlyIcon?: boolean;
}


/**
 * CWEtiquette is an a component to draw an redounded etiquette in line
 *   ------------------------------------------------
 *
 * You can use the "model" property to send events to the component:
 * @event changeState(etiquetteState) set a new state to the etiquette
 * @event updateText(Object[[enabled]:string,[disabled]:string,[tristate]:string]) Set a new text to the etiquette
 * You can listen the "model" object to get the next events:
 * @listens click(id:string,state:etiquetteState): trigger when the user click in the etiquette
 */
export class CWEtiquette extends Backbone.View<CWEtiquetteModel> {
  template: (params: any) => string;
  public model: CWEtiquetteModel;
  private _state: etiquetteState; //Disabled, enabled, tristate
  private _text: {
    enabled: string;
    disabled: string;
    tristate: string;
  }
  private _subText: {
    enabled: string;
    disabled: string;
    tristate: string;
  }
  private _icon: string;
  private _type: validEtiquetteTypes;
  private clickAction?: (id: string, state: etiquetteState) => void;
  private isRendered: boolean;
  private tooltip: string;
  private _values: string;
  private _onlyIcon: boolean;

  constructor(etiquetteProperties: CWEtiquetteOptions) {
    etiquetteProperties.tagName = etiquetteProperties.tagName || "div";
    etiquetteProperties.className = etiquetteProperties.className || "c-etiquette cw-texteAllege d-flex flex-row align-items-center";
    etiquetteProperties.events = _.extend({
      "click": "etiquetteClicked",
      "mouseover .c-etiquette__texts__container": "checkTooltip",
      "keydown": "keyDown"
    }, etiquetteProperties.events);
    super(etiquetteProperties);
    this.model = new CWEtiquetteModel();
    this.template = CWEttiquetteTPL;
    this.id = etiquetteProperties.id;
    this._text = {
      enabled: etiquetteProperties.text.enabled,
      disabled: etiquetteProperties.text.disabled ? etiquetteProperties.text.disabled : etiquetteProperties.text.enabled,
      tristate: etiquetteProperties.text.tristate ? etiquetteProperties.text.tristate : etiquetteProperties.text.enabled
    };
    this.clickAction = etiquetteProperties.clickAction ? etiquetteProperties.clickAction : null;

    this._type = etiquetteProperties.type;
    this._state = etiquetteProperties.state;
    this.tooltip = etiquetteProperties.tooltip ? etiquetteProperties.tooltip : "";
    if (!etiquetteProperties.subText) {
      etiquetteProperties.subText = {
        enabled: ""
      }
    }
    this._subText = {
      enabled: etiquetteProperties.subText.enabled,
      disabled: etiquetteProperties.subText.disabled ? etiquetteProperties.subText.disabled : etiquetteProperties.subText.enabled,
      tristate: etiquetteProperties.subText.tristate ? etiquetteProperties.subText.tristate : etiquetteProperties.subText.enabled
    };
    if (etiquetteProperties.values) {
      this._values = etiquetteProperties.values
    }

    this.icon = etiquetteProperties.icon;
    this.onlyIcon = etiquetteProperties.onlyIcon;

    this.listenTo(this.model, "updateText", this.updateText);
    this.listenTo(this.model, "changeState", this.setNewState);
    this.listenTo(this.model, "changeTootltip", this.changeTooltip);
    this.isRendered = false;
  }

  get type(): validEtiquetteTypes {
    return this._type;
  }

  set type(icon: validEtiquetteTypes) {
    this._type = icon;
    this.refreshEtiquette();
  }

  get icon(): string {
    return this._icon;
  }

  set icon(icon: string) {
    this._icon = icon;
    this.refreshEtiquette();
  }

  get onlyIcon(): boolean {
    return this._onlyIcon;
  }

  set onlyIcon(onlyIcon: boolean) {
    this._onlyIcon = onlyIcon;
    this.refreshEtiquette();
  }

  get state(): etiquetteState {
    return this._state;
  }

  set state(value: etiquetteState) {
    this._state = value;
    this.refreshEtiquette();
  }

  get text(): string {
    switch (this._state) {
      case "inactif": {
        return this._text.disabled;
      }
      case "actif": {
        return this._text.enabled;
      }
      case "custom": {
        return this._text.tristate;
      }
      default: {
        CWLOG.warn("Unexpeted text for state " + this._state);
      }
    }
    return "";
  }

  get subText(): string {
    switch (this._state) {
      case "inactif": {
        return this._subText.disabled;
      }
      case "actif": {
        return this._subText.enabled;
      }
      case "custom": {
        return this._subText.tristate;
      }
      default: {
        CWLOG.warn("Unexpeted text for state " + this._state);
      }
    }
    return "";
  }

  get values(): string {
    return this._values;
  }

  render(): CWEtiquette {
    this.$el.empty();
    const json = { "i18n": i18n, text: this.text, subText: this.subText, values: this.values };
    $(this.el).append(this.template(json));
    this.isRendered = true;
    this.refreshEtiquette();
    return this;
  }

  private updateText(text: { enabled?: string; disabled?: string; tristate?: string }): void {
    this._text.enabled = text.enabled ? text.enabled : this._text.enabled;
    this._text.disabled = text.disabled ? text.disabled : this._text.disabled;
    this._text.tristate = text.tristate ? text.tristate : this._text.tristate;
    this.refreshEtiquette();
  }


  private refreshEtiquette(): void {
    if (this.isRendered) {
      $(this.el).removeClass("c-etiquette__disabled");
      $(this.el).removeClass("c-etiquette__enabled");
      $(this.el).removeClass("c-etiquette__tristate");
      switch (this._state) {
        case "inactif": {
          $(this.el).addClass("c-etiquette__disabled").remove("tabindex");
          break;
        }
        case "actif": {
          $(this.el).addClass("c-etiquette__enabled").attr({ "tabindex": "0" });
          break;
        }
        case "custom": {
          $(this.el).addClass("c-etiquette__tristate").attr({ "tabindex": "0" });
          break;
        }
        default: {
          CWLOG.warn("Unexpeted state " + this._state);
        }
      }
      //Render text
      $(this.el).find(".c-etiquette__text").text(this.text);
      $(this.el).find(".c-etiquette__subText").text(this.subText);
      if (this.subText !== "") {
        $(this.el).addClass("c-etiquette__widthsubtext");
        $(this.el).find(".c-etiquette__subText").css("display", "");
      } else {
        $(this.el).removeClass("c-etiquette__widthsubtext");
        $(this.el).find(".c-etiquette__subText").css("display", "none");
      }

      //Render tooltip
      if (this.tooltip !== "") {
        $(this.el).prop("title", this.tooltip);
      } else {
        $(this.el).removeProp("title");
      }

      $(this.el).removeClass("c-etiquette__demande");
      $(this.el).removeClass("c-etiquette__accepte");
      $(this.el).removeClass("c-etiquette__refuse");
      $(this.el).removeClass("c-etiquette__revision");
      $(this.el).removeClass("c-etiquette__custom");
      $(this.el).removeClass("c-etiquette__genere");
      $(this.el).removeClass("c-etiquette__recap");

      switch (this.type) {
        case "demande": {
          $(this.el).addClass("c-etiquette__demande");
          break;
        } case "accepte": {
          $(this.el).addClass("c-etiquette__accepte");
          break;
        } case "refuse": {
          $(this.el).addClass("c-etiquette__refuse");
          break;
        } case "revision": {
          $(this.el).addClass("c-etiquette__revision");
          break;
        }
        case "custom": {
          $(this.el).addClass("c-etiquette__custom");
          break;
        }
        case "genere": {
          $(this.el).addClass("c-etiquette__genere");
          break;
        }
        case "auto": {
          $(this.el).addClass("c-etiquette__auto");
          break;
        }
        case "recap": {
          $(this.el).addClass("c-etiquette__recap");
          break;
        }
        default: {
          CWLOG.warn("Unexpeted type of etiquette " + this.type);
          break;
        }
      }

      if (this.icon) {
        $(this.el).addClass("c-etiquette__withIcon");
        $(this.el).find(".c-etiquette__withIcon_icon").css("display", "").html(UTILS.getSVGIcon(this.icon, '', 20));
      } else {
        $(this.el).removeClass("c-etiquette__withIcon");
        $(this.el).find(".c-etiquette__withIcon_icon").css("display", "none");
      }

      if (!this.icon && CWSTR.isBlank(this.text) && CWSTR.isBlank(this.subText)) {
        $(this.el).attr("tabindex", "-1");
      } else {
        $(this.el).attr("tabindex", "0");
      }

      if (this.onlyIcon) {
        this.$el.addClass("c-etiquette__no_background");
        this.$el.find(".c-etiquette__withIcon_icon").addClass("c-etiquette__withIcon_icon_no_border");
        this.$el.find(".c-etiquette__texts__container").addClass("d-none");
      }
    }
  }

  setNoBackground(): void {
    this.$el.addClass("c-etiquette__no_background");
    this.$el.find(".c-etiquette__withIcon_icon").addClass("c-etiquette__withIcon_icon_no_border");
  }

  private etiquetteClicked(): void {
    if (this.clickAction) {
      this.clickAction(this.id, this._state);
    }
    else {
      this.model.trigger("click", this.id, this._state);
    }
  }

  private setNewState(newState: etiquetteState): void {
    this._state = newState;
    this.refreshEtiquette();
  }

  private changeTooltip(newTooltip: string): void {
    this.tooltip = newTooltip;
    this.refreshEtiquette();
  }

  private checkTooltip(event: JQuery.TriggeredEvent): void {
    const target = event.currentTarget;

    if (CWSTR.isBlank($(target)[0].title)) {
      if (target.scrollWidth > $(target).innerWidth()) {
        $(target).attr("title", this.text);
      }
    } else {
      if (target.scrollWidth <= $(target).innerWidth()) {
        $(target).attr("title", '');
      }
    }
  }

  //Ignore unnused function, is used on backbone events
  private keyDown(key: JQueryKeyEventObject): boolean {
    if (key && (key.keyCode === 13 || key.keyCode === 32 || key.keyCode === 113)) { // enter or space or F2

      this.model.trigger("click", this.id, this._state);
      return false;
    }
    return true;
  }
}
