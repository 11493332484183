import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

export class CWScheduleColorItemView extends Backbone.View {
    /**
     * Add this to have Outline
     *
     */

    /**
     * HORIZONTAL
     */
    public HORIZONTAL: string; //"H"
    /**
     * VERTICAL
     */
    public VERTICAL: string; //"V"
    public plage: any;
    public type: any;
    public direction: string;
    public showTitle: any;
    public exceptional: any;

    /**
     * Constructor
     * Representation of each colored interval
     */
    constructor(options?: { [key: string]: any }) {
        options = options || {};
        options.className = options.className || "phx-schedule-color-item";
        options.tagName = options.tagName || "span";
        super(options);
        this.HORIZONTAL = "H";
        this.VERTICAL = "V";
        this.plage = options.plage;
        this.direction = options.direction || this.HORIZONTAL;
        if (_.isBoolean(options.showTitle)) {
            this.showTitle = options.showTitle;
        } else {
            this.showTitle = true;
        }
        if (_.isBoolean(options.exceptional)) {
            this.exceptional = options.exceptional;
        } else {
            this.exceptional = false;
        }
    }

    /**
     * Renders this view
     */
    render(): CWScheduleColorItemView {
        let property = "width";
        let plageType = this.plage.type;

        if (this.direction === this.VERTICAL) {
            property = "height";
            this.$el.css("width", "100%");
            this.$el.css("display", "block");
        }
        this.$el.css(property, this.plage.range + "%");
        plageType = plageType === "toldeb" || plageType === "tolfin" ? "tolerance" : plageType;
        this.$el.addClass("ui-phx-plage-" + plageType);
        if ("blank" !== this.plage.type && this.showTitle) {
            this.$el.prop("title", i18n.t('common:schedule_title', {
                "0": i18n.t('common:schedule_' + this.plage.type),
                "1": CWTYPE.HOUR_MINUTE.format(this.plage.hdebut),
                "2": CWTYPE.HOUR_MINUTE.format(this.plage.hfin)
            }));
        }
        if (this.direction === this.HORIZONTAL) {
            this.$el.html("&nbsp");
            if ("blank" !== this.plage.type && this.exceptional) {
                this.$el.html("*").css({
                    "text-align": "center",
                    "vertical-align": "center"
                });
            }
        }
        return this;
    }
}