module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="dialogPretsRefresh">\n    <fieldset class="cw-fieldset">\n        <div class="form-group">\n            <label class="message_question"></label>\n            <div class="custom-control custom-checkbox">\n                <input id="neplus" type="checkbox" style="position:relative;bottom:-1px;margin-left:5px;" class="neplus custom-control-input" value="true" />\n                <label for="neplus" class="custom-control-label">'+
((__t=(i18n.t('plans.planmed.affichage_prets_ne_plus')))==null?'':__t)+
'</label>\n            </div>\n    </fieldset>\n    <div class="cwDialog-buttons" style="width:100%;text-align:center;justify-content:center;">\n        <button type="button" style="margin-right:10px" class="btnConfirm btn btn-primary">'+
((__t=(i18n.t('common:yes')))==null?'':__t)+
'</button>\n        <button type="button" style="margin-left:10px" class="btnCancel btn btn-secondary">'+
((__t=(i18n.t('common:no')))==null?'':__t)+
'</button>\n    </div>\n</div>';
}
return __p;
};
