import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

export class CWPopulationComboModel extends CWBaseModel {
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (!CWSTR.isBlank(response.code)) {
      if (!CWSTR.isBlank(response.type)) {
        response.code = response.code + "," + response.type;
      }
      response.id = response.code;
    }
    return response;
  }
}