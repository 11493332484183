import _ from 'underscore';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';

export class CWFilterModel extends CWFilterBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "statuts": "D,T,I,H,A,R",
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);

  }

  protected _initDefaultValues(): void {
    this.set('statuts', "D,T,I,H,A,R");
  }
}
