import _ from 'underscore';
import { CWBaseCollection } from 'core/models/cwBase.collection';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';

export class CWActivitesModel extends CWBaseCollection<CWReadOnlyModel> {

  constructor(models?: CWReadOnlyModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWReadOnlyModel;
    super(models, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/brique/respo/demande/activites/detail";
    }
  }
}