import { STR } from './str.js';
import { TYPE } from 'tda/tda.js';

/**
 * Utilities to check TREE dates
 */
export var TREE = {

  /**
   * Check if the period is contained totally or partially
   */
  periodeContainesPart: function(periodeParent, periodeSon) {
    if ((periodeParent.datedeb <= periodeSon.datedeb && periodeParent.datefin >= periodeSon.datefin) || (periodeParent.datedeb >= periodeSon.datedeb && periodeParent.datedeb <= periodeSon.datefin) ||
      (periodeParent.datefin >= periodeSon.datedeb && periodeParent.datefin <= periodeSon.datefin)) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * Check if the intersection of the periode son limited by the periode parent
   */
  getPeriodeIntersection: function(periodeParent, periodeSon) {
    var obj = {};
    if (this.periodeContainesPart(periodeParent, periodeSon) === true) {
      obj.datedeb = periodeParent.datedeb > periodeSon.datedeb ? periodeParent.datedeb : periodeSon.datedeb;
      obj.datefin = periodeParent.datefin < periodeSon.datefin ? periodeParent.datefin : periodeSon.datefin;
    } else {
      return null;
    }
    return obj;
  },
  /**
   * Check if the period is contained totally or partially. If datedeb or datefin, are empty, they are refilled with TYPE.DATE.INITIAL and TYPE.DATE.INFINITY respectively
   */
  periodeContainesPartWithRefillDates: function(periodeParent, periodeSon) {
    var l_periodeParent = _.clone(periodeParent);
    var l_periodeSon = _.clone(periodeSon);

    if (_.isObject(l_periodeParent)) {
      if (STR.isBlank(l_periodeParent.datedeb)) {
        l_periodeParent.datedeb = TYPE.DATE.INITIAL;
      }
      if (STR.isBlank(l_periodeParent.datefin)) {
        l_periodeParent.datedeb = TYPE.DATE.INFINITY;
      }
    }
    if (_.isObject(l_periodeSon)) {
      if (STR.isBlank(l_periodeSon.datedeb)) {
        l_periodeSon.datedeb = TYPE.DATE.INITIAL;
      }
      if (STR.isBlank(l_periodeSon.datefin)) {
        l_periodeSon.datedeb = TYPE.DATE.INFINITY;
      }
    }
    if ((l_periodeParent.datedeb <= l_periodeSon.datedeb && l_periodeParent.datefin >= l_periodeSon.datefin) ||
      (l_periodeParent.datedeb >= l_periodeSon.datedeb && l_periodeParent.datedeb <= l_periodeSon.datefin) ||
      (l_periodeParent.datefin >= l_periodeSon.datedeb && l_periodeParent.datefin <= l_periodeSon.datefin)) {
      return true;
    }
    return false;
  }
};
