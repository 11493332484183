import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { View, ViewOptions } from 'Backbone';

export class CWDataGridCellThFakeView extends View<CWReadOnlyModel> {


  private hIndex: number;
  private cellIndex: number;
  private last: boolean;

  /**
   * View of the Headers Fake Cells, only for resize an fill no data headers.
   */
  constructor(options: ViewOptions<CWReadOnlyModel> | any) {
    options.tagName = options.tagName ? options.tagName : "th";
    super(options);
    this.hIndex = options.hIndex;
    this.cellIndex = options.cellIndex;
    this.last = options.last;
  }

  /**
   * Makes the columns resizeables.
   */
  resizable(brother: View): void {
    const column = $(this.el).find("div");
    column.resizable({
      handles: "e",
      minWidth: 25,
      resize: (event, ui) => {
        const newWidth = ui.size.width + "px";
        ui.helper.parent().css("width", newWidth);
        $(brother.el).width(newWidth);
      }
    });
  }

  /**
   * Renders the View.
   */
  render(): CWDataGridCellThFakeView {
    $(this.el).addClass("phx-th" + this.hIndex + this.cellIndex + " ui-grid-th");
    if (this.last !== true) {
      $(this.el).addClass("ui-grid-th-RightBorder");
    }

    return this;
  }

}