import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model pour obtenir la valeur de Type de Gestion du collaborateur
 */

export class CWTypeGestionModel extends CWBaseModel {

  collab: string;
  date: string;

  defaults(): { [key: string]: any } {
    return {
      "typegest": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.collab = options.collab;
    this.date = options.date;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/ficheperso/" + encodeURIComponent(this.collab) + "/typegest/" + encodeURIComponent(this.date);
    };
  }
}