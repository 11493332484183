import _ from 'underscore';
import { CWBaseGridCollection } from 'src/core/grids/basegrid/cwBaseGrid.collection';
import { CWListerRegularisationModel } from './cwListerRegularisation.model';

/**
 * Collection of listerRegularisation Model
 */

export class CWListerRegularisationColl<TModel extends CWListerRegularisationModel = CWListerRegularisationModel> extends CWBaseGridCollection<TModel> {

  public backupStatuts: string;

  constructor(models?: CWListerRegularisationModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWListerRegularisationModel;
    super(models, options);
    this.backupStatuts = "";
    this.url = (): string => {
      if (options.isForResponsable === true || options.isForResponsable === undefined) {
        return Configuration.restRoot + "/rest/regul/composant/liste";
      } else {
        return Configuration.restRoot + "/rest/regul/composant/listecoll";
      }
    };
  }

  _resetOriginalParams(): void {
    if (this.backupStatuts && this.backupStatuts.length > 0) {
      this.params.statuts = this.backupStatuts;
    }
  }
}
