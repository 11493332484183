module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="file_transfer">\n    <div class="phx-formTable">\n        <div>\n            <form method="post" enctype="multipart/form-data" accept-charset="utf-8">\n                <label>'+
((__t=(i18n.t("common:uploadfile.fileSelectionLabel")))==null?'':__t)+
'</label>\n                <div class="more-input-files">\n                </div>\n                <br />\n                <label>'+
((__t=(i18n.t("common:uploadfile.descriptionLabel")))==null?'':__t)+
'</label>\n                <input type="text" class="file-transfer-description" name="description" value="" />\n                <br />\n                <br />\n                <div class="fil_transfer_btnBar"></div>\n                <span class="file-transfer-label"></span>\n\n                <input type="hidden" name="type_utilisation" value="" />\n                <input type="hidden" name="id_entite" value="" />\n            </form>\n        </div>\n    </div>\n</fieldset>';
}
return __p;
};
