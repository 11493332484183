module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="form-group col cmbLibelle">\n    </div>\n    <div class="form-group col filterMore">\n        <label for="filtre_datedeb" class="sr-only">'+
((__t=(i18n.t("common:listerecap.filtre_datedeb")))==null?'':__t)+
'</label>\n        <div class="c-date-selector input-group">\n            <div class="input-group-prepend">\n                <div for="datedeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("dabsences.datedeb")))==null?'':__t)+
'</div>\n            </div>\n            <input type="text" class="filtre_datedeb typeDate periodStart form-control" periodId="1" value="" size="10" />\n        </div>\n    </div>\n    <div class="form-group col filterMore">\n        <label for="filtre_datefin" class="au sr-only">'+
((__t=(i18n.t("common:listerecap.filtre_datefin")))==null?'':__t)+
'</label>\n        <div class="c-date-selector input-group">\n            <div class="input-group-prepend">\n                <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("dabsences.datefin")))==null?'':__t)+
'</div>\n            </div>\n            <input type="text" class="filtre_datefin typeDate periodEnd form-control" periodId="1" value="" size="10" />\n        </div>\n    </div>\n</div>\n';
}
return __p;
};
