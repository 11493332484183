import _ from 'underscore';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';

export class CWTypoStandardModel extends CWReadOnlyModel {


  fetched: boolean;


  /**
   * Default data model
   *	code: "",
   *	libelle: null,
   *}
   */
  defaults(): { [key: string]: any } {
    return {
      code: "",
      libelle: "",
      regroupement: "",
      evenement: "",
      genre: "",
      chevauchement: "",
      activite: false,
      astreinte: false
    }
  }

  /**
   * Constructor
   * Typologies Global
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "app";
    this.fetched = true;
  }

  /**
   * Process to manage the response
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      this.id = response.code; //In this way the id is not created as attribute
    }
    return response;
  }

  /**
   * Fetches data if it hasn't been fetched yet
   */
  fetch(options: { [key: string]: any }): JQueryXHR {
    if (!this.fetched) {
      options = options || {}; // eslint-disable-line
      options.success = ((callback: (...args: any) => any, self: CWTypoStandardModel): any => {
        return (model: CWTypoStandardModel, response: { [key: string]: any }, options: { [key: string]: any }): void => {
          self.fetched = true;
          if (callback) {
            callback(model, response, options);
          }
        };
      })(options.success, this);
      return super.fetch.call(this, options);
    } else {
      if (options && options.success) {
        options.success(this, this.toJSON(), options);
      }
    }
    return ($.Deferred() as any).resolve();
  }

  /**
   * This object can't be cleared. So this method doesn't do anything
   *
   */
  clear(): void {
    // This object can't be cleared.
  }
}