import { BaseModel } from 'core/models/base.model.js';
import { i18n } from 'src/i18n.js';
import { STR } from 'utils/str.js';

export var LoginPasswordModel = BaseModel.extend({
  /**
   * Add this to have Outline
   *
   */

  /**
   * Default data model
   *	password : "",
   *	pwd_new : "",
   *	pwd_confirm : ""
   *}
   */
  defaults: {
    password: "",
    pwd_new: "",
    pwd_confirm: ""
  },
  /**
   * urlRoot
   */
  url: Configuration.restRoot + "/rest/authentification/password",

  /**
   * Constructor
   * Login Password Model
   */
  initialize: function(options) {
    if (options && options.param) {
      this.param = options.param;
    }
  },

  /**
   * Validation
   */
  validate: function(attrs) {
    var errors = {};

    if (STR.isBlank(attrs.password)) {
      errors.password = i18n.t('common:required', { "0": i18n.t('login:pwdmotpassact') });
    }

    if (STR.isBlank(attrs.pwd_new)) {
      errors.pwd_new = i18n.t('common:required', { "0": i18n.t('login:pwdmotpassnou') });
    }

    if (STR.isBlank(attrs.pwd_confirm)) {
      errors.pwd_confirm = i18n.t('common:required', { "0": i18n.t('login:pwdmotpassconf') });
    }

    if (!STR.isBlank(attrs.pwd_new) && !STR.isBlank(attrs.password) && attrs.pwd_new === attrs.password && (STR.isBlank(this.premiere) || this.premiere === false)) {
      errors.pwd_new = i18n.t('messages:GT_1248');
    } else if (!STR.isBlank(attrs.pwd_confirm) && !STR.isBlank(attrs.pwd_new) && attrs.pwd_confirm !== attrs.pwd_new) {
      errors.pwd_confirm = i18n.t('messages:GT_1089');
    }
    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }
});
