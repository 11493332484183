import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

/**
 * Model of ConfigResultatsListeCpt
 */
export var ConfigResultatsListeCpt = BaseModel.extend({

  urlRoot: Configuration.restRoot + "/rest/resultatsgroupecpt/config",

  url: function() {
    var newUrl = this.urlRoot;
    if (!STR.isBlank(this.matric)) {
      newUrl += "?matric=" + encodeURIComponent(this.matric);
    }
    return newUrl;
  }
});
