import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWSTR } from 'utils/cwStr';
import { UTILS } from 'utils/utils.js';

interface CWMenuButtonViewOptions extends Backbone.ViewOptions {
  customBtnClass?: string;
  buttonText: string;
  buttonAction: string;
  hasMenu: boolean;
  menuOptions: any;
  lastAction: boolean;
  isButton: boolean;
  buttonWidth: string;
  updateButtonText?: boolean;
  icon?: string;
  forceIcon?: boolean;
  rightAlign?: boolean;
}

export class CWMenuButtonView extends Backbone.View {
  isEnabled: boolean;
  updateButtonText: boolean;

  /**
   * Event launched when a button is clicked
   */
  /**
   * Event launched when ui-icon is clicked
   */
  /**
   * Event launched when ui-button is clicked
   */
  /**
   * Constructor
   * A button viewg with many options
   */
  constructor(options: CWMenuButtonViewOptions) {
    /**
 * JQuery events for this view
 */
    options.events = {
      "click .btn": "_eventMenu",
      "click .cwMenuButton-icon": "_toggleMenu",
      "click .cwMenuButton-option": "_clickListener"
    }
    super(options);
    let buttonText = "";
    let buttonAction = "";
    let hasMenu = false;
    let menuOptions: any = [];
    let lastAction = false;
    let isButton = false;
    let buttonWidth = "auto";
    let icon = "fleche_bas";
    let forceIcon = false;
    let btnClass = "btn ";
    let rightAlign = false;

    /**
     * Wether all buttons in this view are enabled or not
     */
    this.isEnabled = true;
    this.updateButtonText = false;
    if (options) {
      if (!CWSTR.isBlank(options.buttonText)) {
        buttonText = options.buttonText;
      }
      if (!CWSTR.isBlank(options.buttonAction)) {
        buttonAction = options.buttonAction;
      }
      if (!CWSTR.isBlank(options.hasMenu)) {
        hasMenu = options.hasMenu;
      }
      if (!CWSTR.isBlank(options.menuOptions)) {
        menuOptions = options.menuOptions;
      }
      if (!CWSTR.isBlank(options.lastAction)) {
        lastAction = options.lastAction;
      }
      if (!CWSTR.isBlank(options.isButton)) {
        isButton = options.isButton;
      }
      if (!CWSTR.isBlank(options.buttonWidth)) {
        buttonWidth = options.buttonWidth;
      }
      if (!CWSTR.isBlank(options.icon)) {
        icon = options.icon;
      }
      if (options.forceIcon) {
        forceIcon = true;
      }
      if (options.rightAlign) {
        rightAlign = true;
      }
      if (!CWSTR.isBlank(options.updateButtonText)) {
        this.updateButtonText = options.updateButtonText;
      }
      if (!CWSTR.isBlank(options.customBtnClass)) {
        btnClass += options.customBtnClass;
      } else {
        btnClass += "btn-primary";
      }
    }
    btnClass += " btn-withIcon btn-noHeight";
    this.model = new Backbone.Model({
      buttonText: buttonText,
      buttonAction: buttonAction,
      hasMenu: hasMenu,
      menuOptions: menuOptions,
      lastAction: lastAction,
      isButton: isButton,
      buttonWidth: buttonWidth,
      icon: icon,
      forceIcon: forceIcon,
      btnClass: btnClass,
      rightAlign: rightAlign
    });
  }

  _eventMenu(event: JQueryEventObject): boolean {
    if (this.model.get("hasMenu") === true) {
      return this._toggleMenu(event);
    } else {
      return this._clickListener(event);
    }
  }

  /**
   * Listens to button clicks. Trigger an event btn:click that the uc should listen to.
   */
  _clickListener(event: JQueryInputEventObject): boolean {

    this.$el.find("." + this.model.get("buttonAction") + "-menu-content").hide();

    if (this.isEnabled) {
      const btnClicked = event.currentTarget.className.split(" ")[0];
      const btnClickedText = event.target.textContent;

      let btnAction = $(event.target).data("action");
      if (CWSTR.isBlank(btnAction)) {
        btnAction = _.findWhere(this.model.get("menuOptions"), { action: btnClicked });
        if (btnAction && !CWSTR.isBlank(btnAction.data)) {
          btnAction = btnAction.data;
        }
      }
      this.model.trigger("btn:click", btnClicked, btnAction);

      this._refreshButton(btnClicked, btnClickedText);
    }

    return false;
  }

  /**
   * Refreshes the button
   */
  _refreshButton(btnClicked: string, text: string): void {

    if (this.model.get("lastAction") === true && this.model.get("hasMenu") === true) {
      const oldDivClass = this.$el.find("." + this.model.get("buttonAction") + "-menu").attr("class").split(" ")[0];
      const newDivClass = btnClicked + "-menu";
      const divClasses = this.$el.find("." + this.model.get("buttonAction") + "-menu").attr("class").replace(oldDivClass, newDivClass);
      this.$el.find("." + this.model.get("buttonAction") + "-menu").attr("class", divClasses);

      const oldDivContClass = this.$el.find("." + this.model.get("buttonAction") + "-menu-content").attr("class").split(" ")[0];
      const newDivContClass = btnClicked + "-menu-content";
      const divContClasses = this.$el.find("." + this.model.get("buttonAction") + "-menu-content").attr("class").replace(oldDivContClass, newDivContClass);
      this.$el.find("." + this.model.get("buttonAction") + "-menu-content").attr("class", divContClasses);

      if (this.model.get("isButton") === true) {
        const oldButtonClass = this.$el.find("button." + this.model.get("buttonAction")).attr("class").split(" ")[0];
        const newButtonClass = btnClicked;
        const buttonClasses = this.$el.find("button." + this.model.get("buttonAction")).attr("class").replace(oldButtonClass, newButtonClass);
        if (this.updateButtonText) {
          this.$el.find("button." + this.model.get("buttonAction") + " .c-menuButton__text").text(text);
        }
        this.$el.find("button." + this.model.get("buttonAction")).attr("class", buttonClasses);
      } else {
        const oldSpanClass = this.$el.find("." + this.model.get("buttonAction")).attr("class").split(" ")[0];
        const newSpanClass = btnClicked;
        const spanClasses = this.$el.find("." + this.model.get("buttonAction")).attr("class").replace(oldSpanClass, newSpanClass);
        this.$el.find("span." + this.model.get("buttonAction")).text(text);
        this.$el.find("span." + this.model.get("buttonAction")).attr("class", spanClasses);
      }

      this.model.set("buttonText", text);
      this.model.set("buttonAction", btnClicked);
    }
  }
  /**
   * Toggles menu
   */
  _toggleMenu(event: JQueryInputEventObject): boolean {
    if (this.model.get("menuOptions").length === 1) {
      return this._clickListener(event);
    }
    const menuId = this.model.get("buttonAction");
    const checkHeight = (position: { [key: string]: any }, params: { [key: string]: any }): void => {
      const $menu = params.element.element;
      const menuPositionTop = params.element.top;
      const totalHeight = $(window).height();
      const availableHeight = totalHeight - menuPositionTop - 15 /* adds margin to window border */;
      $menu.css("maxHeight", availableHeight);
      $menu.css("overflowY", "auto");
      if ($menu.hasClass("replace-margin-left") === true) {
        let menuPositionLeft = (params.element.width - params.target.width + 1) * -1;
        if (params.target.element.context.tagName === "svg" && !CWSTR.isBlank(params.target.element[0])) {
          menuPositionLeft = (params.element.width - params.target.element[0].parentElement.offsetWidth + 1) * -1;
        }
        $menu.css("margin-left", menuPositionLeft);
        $menu.removeClass("replace-margin-left");
      }
    };

    if ($("." + menuId + "-menu-content", this.el).is(":visible")) {
      $("." + menuId + "-menu-content", this.el).hide();
    } else {
      if (this.model.get("rightAlign") === true) {
        $("." + menuId + "-menu-content", this.el).addClass("replace-margin-left");
      }
      $("." + menuId + "-menu-content", this.el).show();
      $("." + menuId + "-menu-content", this.el).position({
        my: "left top",
        at: "left bottom",
        of: $(event.target).parent(),
        using: checkHeight
      });
      //If you click out of the menu, close the menu.
      $(document).one("mousedown", (event: JQuery.TriggeredEvent) => {
        const element = $(this.el).find(event.target);

        if (element.length === 0) {
          $("." + menuId + "-menu-content", this.el).hide();
          this.$el.find(".ui-icon").removeClass("ui-icon-triangle-1-n");
          this.$el.find(".ui-icon").addClass("ui-icon-triangle-1-s");
        }
      });
      //trigger pour avertir que c'est possible faire une action de changer le positionnement(il faut ajouter un "model.on" où le menu soit créé).
      //Il faut le faire pour le donner de temps de peint le menu et après, le déplacer où il doit aller)
      this.model.trigger("rePosition");
    }
    return false;
  }

  /**
   * Disables button
   */
  disableButton(disabled: boolean): void {
    this.isEnabled = !disabled;
    if (disabled === true) {
      $(this.el).find("." + this.model.get("buttonAction") + "-menu").hide();
    } else {
      $(this.el).find("." + this.model.get("buttonAction") + "-menu").show();
    }
  }

  //	removeMenuOption(idOption){
  //		this.$el.find("li." + idOption).detach();
  //		if (this.$el.find("li").length == 0) {
  //			this.$el.hide();
  //		}
  //	},
  /**
   * Renders this view
   */
  render(): CWMenuButtonView {
    let $buttonMenu;
    const $buttonDiv = $("<div></div>");
    const itemClass = "c-panneauMenu__item";
    $buttonDiv.addClass(this.model.get("buttonAction") + "-menu");
    // $buttonDiv.addClass("phx-grid-title-bar-menu");

    const btnText = $("<span>").addClass("c-menuButton__text").text(this.model.get("buttonText"));
    if (this.model.get("isButton") === true) {
      $buttonMenu = $("<button type='button' class='" + this.model.get("buttonAction") + "' value=''>");
      $buttonMenu.addClass(this.model.get("btnClass"));
      $buttonMenu.append(btnText);
      $buttonDiv.append($buttonMenu);
      // $buttonMenu.button();
      $buttonMenu.css("width", this.model.get("buttonWidth"));
    } else {
      $buttonDiv.append("<span class='" + this.model.get("buttonAction") + " " + itemClass + " cwMenuButton-option' style='cursor:pointer'>");
      $buttonDiv.append(btnText);
    }

    this.$el.empty().append($buttonDiv);
    if (this.model.get("hasMenu") === true) {
      $buttonMenu.append(UTILS.getSVGIcon(this.model.get("icon"), 'cwMenuButton-icon', 16, undefined));

      const $menu = $("<div style='display:none'></div>");
      $menu.addClass(this.model.get("buttonAction") + "-menu-content");
      $menu.addClass("c-panneauMenu c-panneauMenu--noIcon");
      // $menu.addClass("ui-menu");
      $menu.css("position", "absolute");
      $menu.css("width", "max-content");
      $menu.css("z-index", "30");

      const $ul = $("<ul></ul>");
      const size = this.model.get("menuOptions").length;
      for (let i = 0; i < size; i++) {
        const $li = $("<li style='cursor:pointer' class='" + this.model.get("menuOptions")[i].action + " " + itemClass + " cwMenuButton-option'>" + this.model.get("menuOptions")[i].text + "</li>");
        $li.data("action", this.model.get("menuOptions")[i].data);
        $ul.append($li);
      }

      $menu.append($ul);
      this.$el.append($menu);
    } else if (this.model.get("forceIcon") === true) {
      $buttonMenu.append(UTILS.getSVGIcon(this.model.get("icon"), this.model.get("buttonAction") + ' cwMenuButton-icon', 16, undefined));
    }

    return this;
  }

}
