import _ from 'underscore';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWCombosModel } from 'core/components/combo/cwCombos.model';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';

/**
 * Collection for UniteComboColl
 */
export class CWUniteComboColl extends CWCombosColl<CWCombosModel> {

  typeevtid: any;
  code: any;
  besactid: any;
  dateper: any;
  typegest: any;
  codeaff: any;

  constructor(models?: CWCombosModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options.model = options.model || CWCombosModel;
    super(models, options);

    this.typeevtid = !CWSTR.isBlank(options.typeevtid) ? options.typeevtid : null;
    this.code = !CWSTR.isBlank(options.code) ? options.code : null;
    this.dateper = !CWSTR.isBlank(options.dateper) ? options.dateper : null;
    this.typegest = !CWSTR.isBlank(options.typegest) ? options.typegest : null;
    this.besactid = !CWSTR.isBlank(options.besactid) ? options.besactid : null;

    this.url = (): string => {
      if (CWSTR.isBlank(this.typeevtid) || CWSTR.isBlank(this.code)) {
        CWLOG.error("I expected the typeevtid and code to work property");
      }

      let baseURL = Configuration.restRoot + "/rest/uniteevenement";
      if (!CWSTR.isBlank(this.besactid)) {
        baseURL += "/besoin"
      }
      baseURL += "?typeevtid=" + this.typeevtid + "&codeact=" + encodeURIComponent(this.code);

      if (!CWSTR.isBlank(this.dateper)) {
        baseURL += "&dateper=" + this.dateper;
      }
      if (!CWSTR.isBlank(this.typegest)) {
        baseURL += "&typegest=" + encodeURIComponent(this.typegest);
      }
      if (!CWSTR.isBlank(this.besactid)) {
        baseURL += "&besactid=" + encodeURIComponent(this.besactid);
      }
      return baseURL;
    }
  }

}
