import { BaseModel } from 'core/models/base.model.js';
import { BlockView as CommonBlockView } from 'core/components/collapsible_block/block.view.js';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { HistoDemModelColl } from '../models/histoDemModel.collection.js';
import { HistoEnsembleModelColl } from '../models/histoEnsembleModel.collection.js';
import { HistoEventModelColl } from '../models/histoEventModel.collection.js';
import { HistoSouhaitOriginModel } from '../models/histoSouhaitOrigin.model.js';
import { i18n } from 'src/i18n.js';
import { STR } from 'utils/str.js';
import { TYPE } from 'tda/tda.js';
import { WKF } from 'utils/wkf.js';
import TPL_wkfhisto_detail from '../detail.tpl.html';
import { HistoRecapitulatifsAndActivitesModel } from '../models/histoRecapitulatifsAndActivites.model.js';

/**
 * View for the workflow component
 */
export var BlockView = CommonBlockView.extend({

  initialize: function(options) {
    // list of components to load
    var opt = {};
    opt.component = "wkfhisto";
    opt.detailTemplate = TPL_wkfhisto_detail;
    opt.buttons = [];
    this.loadedData = false;

    if (options.ctxEcran) {
      opt.ctxEcran = options.ctxEcran;
    }

    this.model = new BaseModel({
      histoDem: new HistoDemModelColl(),
      histoEvent: new HistoEventModelColl(),
      histoEnsemble: new HistoEnsembleModelColl()
    });
    this.model.on("expand", this._loadDataInFirstTime, this);

    CommonBlockView.prototype.initialize.call(this, opt);
  },

  _loadDataInFirstTime: function(data) {
    if (!this.loadedData && data.expanded) {
      // if we load data the first time - we open the detail view.
      this.context.ctxActivModeSynth = false;
      this._loadData(data);
    }
  },

  events: _.extend({
    "click span.Detaille": "_detailView",
    "click span.Simple": "_synthetiqueView"
  }, CommonBlockView.prototype.events),

  setContext: function(context) {
    this.context = context;
    this.title = "";
    this.titleAction = "";

    if (STR.isBlank(this.context.ctxDemId) || String(this.context.ctxDemId) === "0" || this.context.ctxDemId === "null") {
      this.context.ctxDemId = null;
    }
    if (STR.isBlank(this.context.ctxRefEvenement) || this.context.ctxRefEvenement === "null") {
      this.context.ctxRefEvenement = null;
    }

    //Default values
    if (this.context.ctxModeRestitution === "Imbrique") {
      this.title = i18n.t('common:wkfhisto.title_imbrique');
      if (this.context.ctxActivModeSynth === true) {
        this.titleAction = "&nbsp<span class='Detaille' style='cursor:pointer;'>+</span> / <span class ='Simple' style='cursor:pointer;'>[-]</span>";
      }
    }

    if (this.context.ctxModeRestitution === "pop-up") {
      this.title = i18n.t('common:wkfhisto.title_popup');
    }

    if (this.context.ctxModeRestitution === "infobulle") {
      this.title = "";
      this.context.ctxActivModeSynth = true;
      this.context.ctxModeDefaut = "Deplier";
      this.hideIcon();
    }

    this.model.get("histoDem").setHabContext(
      new HabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation
      })
    );
    this.model.get("histoEvent").setHabContext(
      new HabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation
      })
    );
    this.model.get("histoEnsemble").setHabContext(
      new HabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation
      })
    );

    this._manageBlocEstDeplie();
    this.setTitle(this.title + this.titleAction);
    this.showBlock();
  },

  // override default function
  canAccess: function() {
    return true;
  },

  _loadData: function(data) {
    var self = this;

    if (data && data.context) {
      this.context = data.context;
    }
    this.loadedData = true;
    //1er les activités(et aussi les récapìtulatifs) et après les autres événements
    if ((this.context.ctxActivModeSynth === false || this.context.ctxEnsemble === true) && this.context.ctxTypeEvenement === "ACTRECAP") {
      var l_typoAct = this.context.ctxTypeEvenement;
      var l_histoAct = new HistoRecapitulatifsAndActivitesModel();

      if (!STR.isBlank(this.context.ctxHabilitation) && typeof this.context.ctxHabilitation === "string") {
        if (this.context.ctxHabilitation.indexOf("PRE") >= 0 || this.context.ctxHabilitation.indexOf("ACTPR") >= 0) {
          //activité prevue
          l_typoAct = "ACTPREV";
        }
        if (this.context.ctxHabilitation.indexOf("REC") >= 0) {
          //récapitulatif d'activité
          l_typoAct = "ACTRECAP";
        }
        if (this.context.ctxHabilitation.indexOf("REA") >= 0 || this.context.ctxHabilitation.indexOf("ACTRE") >= 0) {
          //activité realisée
          l_typoAct = "ACTREAL";
        }
      }
      l_histoAct.set("type", l_typoAct);
      l_histoAct.set("evenement", this.context.ctxRefEvenement);
      l_histoAct.set("iddemande", this.context.ctxDemId);
      l_histoAct.setHabContext(new HabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation
      }));
      l_histoAct.save(null, {
        success: function(fresh) {
          self.setContent(self._generateContent(fresh));
        }
      });
    } else {
      if (this.context.ctxEnsemble === true) {
        this.collection = this.model.get("histoEnsemble");
        this.collection.ctxTypeEvenement = this.context.ctxTypeEvenement;
        this.collection.evenements = this.context.ctxRefEvenement;
        this.collection.demandes = this.context.ctxDemId;

        this.collection.fetch({
          success: function(fresh) {
            self.setContent(self._generateContent(fresh));
            if (fresh.length === 0) {
              self.hideIcon();
            }
          }
        });

      } else if (this.context.ctxRefEvenement !== null) {
        this.collection = this.model.get("histoEvent");
        this.collection.ctxRefEvenement = this.context.ctxRefEvenement;
        this.collection.ctxTypeEvenement = this.context.ctxTypeEvenement === "Activité" ? "ACTIVITE" : this.context.ctxTypeEvenement;
        this.souhaitOrigine = null;
        if ((this.collection.ctxTypeEvenement === "REGULARISATION" || this.collection.ctxTypeEvenement === "EVTGEN" || this.collection.ctxTypeEvenement === "ABSENCE" ||
            this.collection.ctxTypeEvenement === "SOUHAITABS" || this.collection.ctxTypeEvenement === "PRET" || this.collection.ctxTypeEvenement === "BADGEAGE" ||
            this.collection.ctxTypeEvenement === "ACTIVITE") && this.context.ctxActivModeSynth === false) {
          if (this.collection.ctxTypeEvenement === "ACTIVITE") {
            if (this.collection.habContext.get('foncCour') === 'RES_VAL_ACTPR.G' || this.collection.habContext.get('foncCour') === 'RES_VAL_ACTPR.V' ||
              this.collection.habContext.get('foncCour') === 'COL_ACT_PREV.G' || this.collection.habContext.get('foncCour') === 'COL_ACT_PREV.V') {
              this.collection.ctxTypeEvenement = 'ACTPREV';
            } else {
              this.collection.ctxTypeEvenement = 'ACTREAL';
            }
          }
          if (!STR.isBlank(this.context.ctxRefSouhait)) {
            // read souhait origine
            this.souhaitOrigine = new HistoSouhaitOriginModel();
            this.souhaitOrigine.setHabContext(new HabilitationContext({
              onglet: this.context.ctxEcran,
              foncCour: "RES_SHTABS.V"
            }));
            this.souhaitOrigine.ctxRefSouhait = this.context.ctxRefSouhait;
            this.souhaitOrigine.fetch({
              success: function() {
                self.collection.fetch({
                  success: function(fresh) {
                    self.setContent(self._generateContent(fresh));
                  }
                });
              }
            });
          } else {
            this.collection.fetch({
              success: function(fresh) {
                self.setContent(self._generateContent(fresh));
              }
            });
          }
        }
      } else if (this.context.ctxDemId !== null) {
        this.collection = this.model.get("histoDem");
        this.collection.ctxDemId = this.context.ctxDemId;
        this.collection.ctxModeRech = this.context.ctxModeRech;
        this.collection.fetch({
          success: function(fresh) {
            self.setContent(self._generateContent(fresh));
            if (fresh.length === 0) {
              self.hideIcon();
            }
          }
        });
      }
    }
  },

  _detailView: function() {
    this.context.ctxActivModeSynth = false;
    this.setContent(this._generateContent(this.collection));
    this.titleAction = "&nbsp<span class='Detaille' style='cursor:pointer;'>[+]</span> / <span class ='Simple' style='cursor:pointer;'>-</span>";
    this.setTitle(this.title + this.titleAction);
  },

  _synthetiqueView: function() {
    this.context.ctxActivModeSynth = true;
    this.setContent(this._generateContent(this.collection));
    this.titleAction = "&nbsp<span class='Detaille' style='cursor:pointer;'>+</span> / <span class ='Simple' style='cursor:pointer;'>[-]</span>";
    this.setTitle(this.title + this.titleAction);
  },

  _bold: function(normalText) {
    var boldText = "<b>" + normalText + "</b>";
    return boldText;
  },

  _manageVisualization: function() {
    if (this.context.ctxBlocEstDeplie) {
      this.expand();
    } else {
      this.collapse();
    }
  },

  _manageBlocEstDeplie: function() {
    if (this.context.ctxModeDefaut === "Replie") {
      this.expanded = false;
      this.collapse();
      this.loadedData = false;
    } else {
      this.expanded = true;
      this.expand();
      this.loadedData = true;
    }
  },

  _generateContent: function(collection) {
    var htmlContent = "<table class='phx-wkfhisto-table'>";
    var i = 0;

    if (this.context.ctxActivModeSynth === true) {
      if (collection && collection.length > 0) {
        htmlContent += "<tr class='phx-wkfhisto 0'>";
        htmlContent += this._buildLine(collection.at(0));
        htmlContent += "</tr>";
      }
      if (collection && collection.length > 1) {
        htmlContent += "<tr class='phx-wkfhisto 1'>";
        htmlContent += this._buildLine(collection.at(collection.length - 1));
        htmlContent += "</tr>";
      }
    } else {
      if (collection) {
        for (i = 0; i < collection.length; i++) {
          htmlContent += "<tr class='phx-wkfhisto " + i + "'>";
          htmlContent += this._buildLine(collection.at(i));
          htmlContent += "</tr>";
        }
      }
      if (this.souhaitOrigine) {
        htmlContent += "<tr class='phx-wkfhisto " + i + "'>";
        htmlContent += this._buildLineSouhaitOrigine(this.souhaitOrigine);
        htmlContent += "</tr>";
      }
    }

    htmlContent += "</table>";
    htmlContent += "<br/>";
    return htmlContent;
  },

  _buildLineSouhaitOrigine: function(model) {
    var result = "";
    var date = "";

    if (model.get("souhait")) {
      date = TYPE.DATE.format(model.get("souhait")[0]["@date"], TYPE._getFormatByCode("DATE_A"));
    } else if (model.ctxRefSouhait) {
      date = TYPE.DATE.format(model.get("@date"), TYPE._getFormatByCode("DATE_A"));
    }
    var message = "";
    message = i18n.t('messages:GL_1032') + " " + i18n.t('messages:GL_1033', { "1": this._bold(date), interpolation: { escapeValue: false } });

    result += "<tr><td>" + message + "</td></tr>";

    return result;
  },

  _buildLine: function(model) {
    return WKF.buildLine(model);
  },

  _manageButtons: function(event) {
    var buttonId = event.currentTarget.className.split(" ")[2];

    switch (buttonId) {
      case "create":
        this.popupView = this._initPopupView(this.model.get("histoEvent"));
        this.popupView.open();
        break;
      default:
        //Nothing
    }
  },

  _initPopupView: function(model) {
    var dialog = new CWDialogView({
      view: BlockView,
      viewData: {
        model: model
      }
    });
    dialog.setHeight(300);
    dialog.setWidth(300);
    return dialog;
  }
});
