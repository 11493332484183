module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc" data-bloc-name="D" data-bloc-label="'+
((__t=(i18n.t("common:gererevtgen.date")))==null?'':__t)+
'">\n  <div class="form-group">\n    <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.du")))==null?'':__t)+
'</label>\n    <div class="c-date-selector input-group">\n        <input type="text" class="datedeb dateDebD typeDate periodStart form-control" periodId="1" value="" size="17" />\n    </div>\n  </div>\n</div>\n\n<div class="cw-multiLabel-bloc" data-bloc-name="R" data-bloc-label="'+
((__t=(i18n.t("common:gererevtgen.repetition")))==null?'':__t)+
'">\n  <div class="row">\n    <div class="form-group col">\n      <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gererevtgen.periodeDeb")))==null?'':__t)+
'</label>\n      <div class="c-date-selector input-group">\n        <div class="input-group-prepend">\n          <div for="datedeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gererevtgen.du")))==null?'':__t)+
'</div>\n        </div>\n        <input type="text" class="datedeb dateDebP typeDate periodStart form-control" periodId="1" value="" size="10" />\n      </div>\n    </div>\n\n    <div class="form-group col">\n      <label for="datefin" class="sr-only">&nbsp;</label>\n      <div class="c-date-selector input-group">\n        <div class="input-group-prepend">\n          <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gererevtgen.au")))==null?'':__t)+
'</div>\n        </div>\n        <input type="text" class="datefin dateFinP typeDate periodEnd form-control" periodId="1" value="" size="10" />\n      </div>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
