import _ from 'underscore';
import TPLDroitsdelFilter from '../cwFilter.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWDroitsdelWorkflow } from '../models/cwDroitsdel.workflow';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWFORMS } from 'utils/cwForms';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


export class CWFilterContentView extends CWBaseFormView<CWFilterBaseModel> {

  /**
   * Constructor
   * View for the content of the filter part
   */

  workflow: CWDroitsdelWorkflow;
  ctxEcran: string;

  constructor(options?: { [key: string]: any }) {
    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, CWTYPE.DATE.events())
    super(options);
    this.template = TPLDroitsdelFilter;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    this.ctxEcran = options.ctxEcran;
    this.model = options.model;

    this.model.off("invalid");
    this.model.on("invalid", this._showValidationErrors, this);

    this.model.setHabContext(this.workflow.getHabContext());

    if (this.workflow.ctxPeriode && this.workflow.ctxPeriode.datedeb) {
      this.model.set("datedeb", this.workflow.ctxPeriode.datedeb);
    }
    if (this.workflow.ctxPeriode && this.workflow.ctxPeriode.datefin) {
      this.model.set("datefin", this.workflow.ctxPeriode.datefin);
    }
  }

  /**
   * Return the models of the use case
   */
  _getModel(): any {
    return this.model;
  }

  /**
   * Renders the view to the form
   */
  render(): CWFilterContentView {
    const json = { "i18n": i18n };

    this.$el.html(this.template(json));
    return this;
  }

  /**
   * Renders the view to the form (referentiels, radiobuttons)
   */
  renderData(): void {
    CWFORMS.setDatepicker(this, ".datedeb");
    CWFORMS.setDatepicker(this, ".datefin");

    this._mapModelToForm(this.$el, this.model);
  }
}