import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

/**
 * Tree item model
 */
export var ActiviteTreeModel = BaseModel.extend({

  defaults: {
    "typelt": "",
    "code": null,
    "libelle": "",
    "datedeb": "",
    "datefin": "",
    "hierid": null,
    "feuille": null,
    "indic_filtre": null,
    "indic_periode": "",
    "indic_inapt": "",
    "indic_comp_req": "",
    "mes1595": null,
    "mes1599": null,
    "codef": null,
    "libelleformat": null,
    "niveau": null,
    "codeparent": null,
    "datedebherit": null,
    "datefinherit": null,
    "rattachement": null
  },

  parse: function(response) {
    if (!STR.isBlank(response.code)) {
      response.id = response.code;
    }
    //Heritage of parentstraid and parentdomlib in order to paint tooltip of selecteur d'activite mode complet
    if (!STR.isBlank(this.collection) && !STR.isBlank(this.collection.parentstraid)) {
      response.parentstraid = this.collection.parentstraid;
    } else if (!STR.isBlank(response.structida)) {
      response.parentstraid = response.structida;
    }
    if (!STR.isBlank(this.collection) && !STR.isBlank(this.collection.parentdomlib)) {
      response.parentdomlib = this.collection.parentdomlib;
    }
    if (!STR.isBlank(this.collection) && !STR.isBlank(this.collection.itemida)) {
      response.itemida = this.collection.itemida;
    }

    return response;
  }

});
