import * as Backbone from 'Backbone';
import TPLBadgeageBadgeageCompteDialog from '../badgeage_compte_dialog.tpl.html';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDataGridModel } from 'src/core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'src/core/grids/data_grid/cwDataGrid.view';
import { CWSearchResultatsListeCptColl } from '../../resultatgrpcompteurs/models/cwSearchResultatsListeCpt.collection';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'utils/sync.js';

/**
 * Badgeage Virtuel Dialog
 */
export class CWBadgeageVirtuelDialogView extends Backbone.View {
  private parent: any;
  private heure: number;
  private badgVirtModel: { [key: string]: any };
  private badgVirtComptColl: CWSearchResultatsListeCptColl;
  private title: string;
  private subtitle: string;
  private selectable: boolean;
  private sansSelection: boolean;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    super(options);
    if (options && options.parent) {
      this.parent = options.parent;
    }
    if (options && options.heure) {
      this.heure = options.heure;
    }
    this.template = TPLBadgeageBadgeageCompteDialog;

    if (options) {
      this.badgVirtModel = options.model;
      this.badgVirtComptColl = options.collection;
      this.title = options.title;
      this.subtitle = options.subtitle;
      this.selectable = options.selectable || true;
      this.sansSelection = options.sansSelection || false;
    }
  }

  _initCompteursTable(): CWDataGridView<CWBaseModel, CWSearchResultatsListeCptColl> {
    const tableModel = new CWDataGridModel<CWSearchResultatsListeCptColl>({
      coll: this.badgVirtComptColl
    });
    const tblColumns: Array<CWBaseGridColums> = [{
      title: i18n.t('common:bvMenu_comptLib'),
      property: "libelle",
      width: 7
    }, {
      title: i18n.t('common:bvMenu_comptVal'),
      property: "valeur",
      width: 5
    }];
    const table = new CWDataGridView<CWBaseModel, CWSearchResultatsListeCptColl>({
      columns: tblColumns,
      model: tableModel,
      gridHeightType: "auto",
      parentContainer: this.parent,
      selectable: this.selectable,
      sansSelection: this.sansSelection,
      title: null,
      showFilteredRowsInTitle: false
    });

    table.cellRenderers["libelle"] = (model: any): JQuery => {
      const span = $("<span>").attr("title", i18n.t('common:bvMenu_infoLibelle')).text(model.get("libelle"));
      return span;
    };

    table.cellRenderers["valeur"] = (model: any): JQuery => {
      const span = $("<span>").attr("title", i18n.t('common:bvMenu_infoValeur')).text(model.get("valeur"));
      if (model.get("valeur").indexOf("-") !== -1) {
        span.addClass("ui-phx-valorisation-negative");
      }
      return span;
    };

    table.setSortableColumns(["libelle"]);

    $(this.parent.el).on("dialogresize", () => {
      table._resizeGrid();
    });

    return table;
  }

  render(): Backbone.View<Backbone.Model> {
    const json = { 'i18n': i18n };
    this.$el.append(this.template(json));

    const bvcompte = this.$el.find(".bvcompte");
    const bvcompteurs = this.$el.find(".bvcompteurs");
    if (this.badgVirtModel) {
      bvcompte.show();
      bvcompteurs.hide();
      bvcompte.html("");
      this.parent._setTitle(i18n.t('login:popup_badge_header'));
      if (!CWSTR.isBlank(this.badgVirtModel.get("cptrendu"))) {
        bvcompte.append("<span class=\"bvline1 cw-texteAllege\">" + this._buildMessage(this.badgVirtModel.get("cptrendu"), this.badgVirtModel.get("parametres")) + "</span>");
        bvcompte.append("<br/><br/>");
      }
      if (!CWSTR.isBlank(this.badgVirtModel.get("message1"))) {
        bvcompte.append("<span class=\"bvline2 cw-texteAllege\">" + this.badgVirtModel.get("message1") + "</span>");
        bvcompte.append("<br/><br/>");
      }
      if (!CWSTR.isBlank(this.badgVirtModel.get("message2"))) {
        bvcompte.append("<span class=\"bvline3 cw-texteAllege\">" + this.badgVirtModel.get("message2") + "</span>");
        bvcompte.append("<br/><br/>");
      }
    } else if (this.badgVirtComptColl && this.badgVirtComptColl.length > 0) {
      if (this.subtitle) {
        this.$el.find(".bvcompteurs-valorisation").hide();
        this.$el.find(".bvcompteurs-compteurs-instantanes").html(this._subtitleCI(this.subtitle)).show();
      } else {
        //const valorisation = i18n.t('common:bvMenu_valorisation') + " " + CWTYPE.DATE.dateToStr(SYNC.getServerDate(), CWTYPE._getFormatByCode("DATE_A"));
        this.$el.find(".bvcompteurs-compteurs-instantanes").hide();
        this.$el.find(".bvcompteurs-valorisation").html(this._subtitleCI(i18n.t('common:bvMenu_valorisation'))).show();
      }




      bvcompte.hide();
      bvcompteurs.show();
      if (this.parent._setTitle && this.title) {
        this.parent._setTitle(this.title);
      } else if (this.parent._setTitle) {
        this.parent._setTitle(i18n.t('common:bvMenu_comptTitle', { "0": this.badgVirtComptColl.length }));
      }
      const tableCompteurs = this._initCompteursTable();
      bvcompteurs.html(tableCompteurs.el);
      tableCompteurs.render();
    } else {
      this.parent._setTitle(i18n.t('common:bvMenu_comptTitle', { "0": 0 }));
      bvcompteurs.html(i18n.t('common:bvMenu_infoNoDisponible'));
    }

    $(this.parent.el).css("height", "auto");

    return this;
  }

  _subtitleCI(stitle: string): string {
    const ret: string = "<span class='cw-texteAllege'>" + stitle + "</span>"
      + "&nbsp;"
      + "<span class='cw-texteNormal'>" + CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "DD/MM/YYYY") + "</span>";
    return ret;
  }

  _buildMessage(message: string, parametres: { [key: string]: any }): string {
    let result: string = null;
    if (!CWSTR.isBlank(message)) {
      result = message;
      if (!CWSTR.isBlank(parametres) && parametres.length > 0) {
        for (let i: number = 0; i < parametres.length; i++) {
          const next: number = i + 1;
          result = result.replace("@" + next, parametres[i]["@" + next]);
        }
      }
    }
    return result;
  }
}