import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


export class CWCompteDeplacementModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "matricule": "",
      "datedeb": "",
      "dtdebinter": "",
      "hdebinter": "",
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.url = (): string => {
      const url = `${Configuration.restRoot}/rest/composant/activite/deplacement/compteana/unique?matricule=${this.get('matricule')}&datedeb=${this.get('datedeb')}&dtdebinter=${this.get('dtdebinter')}&hdebinter=${this.get('hdebinter')}`;

      return url;
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } | null {
    const errors: { [key: string]: any } = {};
    let errorMsg;

    if (CWSTR.isBlank(attrs.datedeb)) {
      errors.datedeb = i18n.t('common:required', { "0": i18n.t('common:gereractivites.comptabilise') });
    }
    if (CWSTR.isBlank(attrs.dtdebinter)) {
      errors.dtdebinter = i18n.t('common:required', { "0": i18n.t('common:gereractivites.date_intervention') });
    }

    if (CWSTR.isBlank(attrs.matricule)) {
      errors.matricule = i18n.t('common:required', { "0": i18n.t('common:information') });//uniquement c'est à title informative pour le modèle, afin de ne pas faire le "fetch" si l'il n'y a pas de la valeur
    }

    if (CWSTR.isBlank(attrs.hdebinter)) {
      errors.hdebinter = i18n.t('common:required', { "0": i18n.t('common:gereractivites.arrive_hospital') });
    }
    else {
      errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.hdebinter);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.hdebinter = errorMsg;
      }
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }



}
