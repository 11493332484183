import _ from 'underscore';
import { CWFORMS } from 'utils/cwForms';
import { CWGererEvtGenView } from 'src/uc/common/evenements/gerer/gererevtgen/views/cwGererEvtGen.view';
import { CWListerEvenementsGeneralView } from 'src/uc/common/evenements/lister/listerevenements/views/cwListerEvenementsGeneral.view';
import { CWListerEvtGenModel } from 'src/uc/common/evenements/lister/listerevenements/models/cwListerEvtGen.model';
import { CWListerEvtTableView } from 'src/uc/common/evenements/lister/listerevenements/views_table/cwListerEvtTable.view';
import { CWLOG } from 'utils/cwLog';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { Model } from 'Backbone';

/**
 * Workflow for UC Gestion des événements
 */
export class CWMevenementsWorkflow extends CWReadOnlyModel {

  public gererEvtGen: CWGererEvtGenView;
  public listerEvtGen: CWListerEvenementsGeneralView;
  public matricule: string | number;
  public original: void;
  public contextLister: { [key: string]: any };
  public contextGerer: { [key: string]: any };
  public lastSelectedId: string;
  public pJointe: Model;
  public isRevert: boolean;
  public evtGenTypo: CWListerEvtGenModel;
  module: string;
  private $appendTo: JQuery; //pour les messages
  flagCreate: boolean;


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.module = options.module;
    if (!CWSTR.isBlank(this.module)) {
      this.usecase = this.module;
    }
    this.gererEvtGen = null;
    this.listerEvtGen = null;
    this.matricule = null;
    this.matricule = options.matricule;
    this.original = null;
    this.contextLister = {
      ctxTypologieEvenement: "",
    };
    this.contextGerer = {
      ctxUtilisateur: "Collaborateur",
      ctxTypologieEvenement: "",
      ctxHabilitation: {
        HabilitationAcces: "COL_EVTGEN.V",
        HabilitationGestion: "COL_EVTGEN.G",
        HabilitationValidation: ""
      },
      ctxEcran: this.module,
      ctxModeRepresentation: "Imbrique",
      ctxGestionCollab: {
        matricule: this.matricule
      },
      ctxDateConsultee: "",
      ctxActionsPossibles: ["Creer", "Supprimer", "Modifier"],
      // ctxModeInitialisation: "Consultation",
      ctxIdentifiantEvt: "",
      ctxValeursEvenement: [],
      ctxValeursCreation: [],
      ctxHistoriqueWKF: false,
      ctxGestionCollective: false,
      ctxPieceJointe: false
    };
    //ready = true when the setUp is finished. Just one setUp per life cycle of the module
    this.set("ready", false);
    this.lastSelectedId = null;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    this.flagCreate = false;
  }

  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback: (pJointe: string) => void): void {
    let pJointe: string = null;

    this.listerEvtGen.model.on("evtGenTypoChange", this._evtGenTypoChanged, this);
    this.listerEvtGen.model.on("rowChange", this._tableValueChange, this);
    this.listerEvtGen.model.on("emptyList", this._manageEmptyMode, this);
    this.gererEvtGen.model.on("evtGenChanged", this._evtGenChanged, this);
    this.gererEvtGen.model.on("mode:create", this._manageCreateMode, this);
    this.gererEvtGen.model.on("mode:revert", this._manageRevertMode, this);    
    // Retrieve parametre divers for piece jointe
    this.pJointe = GLOBAL_DATA.paramDivers.get("pJointe");
    // Load data
    pJointe = this.pJointe.get("valeur");
    if (callback) {
      callback(pJointe);
    }
    //Events 
    this.listenTo(this, "manageUnselected", (status: boolean) => {
      this.gererEvtGen.trigger("manageUnselected", status);
    });
    this.listenTo(this, "collectionParams:cleaned", (notIsButton?: boolean, origine?: string) => {
      this.gererEvtGen?.trigger("collectionParams:cleaned", notIsButton, origine);
    });
    this.set("ready", true);
  }

  _evtGenChanged(model: CWListerEvtGenModel, action: string): void {
    const modelId = (!model || model === null) ? null : model.get("evenement");

    if (this.listerEvtGen.form instanceof CWListerEvtTableView) {
      this.listerEvtGen.form.refreshList(modelId, action);
    }
    this.gererEvtGen.workflow.btnBarModel.set("mode", "C");
    this._disableFilter(false);
  }

  _evtGenTypoChanged(model?: CWListerEvtGenModel): void {
    this.evtGenTypo = model ? model : new CWListerEvtGenModel();
    this.contextLister.ctxTypologieEvenement = this.evtGenTypo;
    this.contextGerer.ctxTypologieEvenement = this.evtGenTypo;
    this.contextGerer.ctxEvtGenModeSaiDef = this.evtGenTypo.saidefo;
    this.contextGerer.ctxEvtGenSaiRepJ = this.evtGenTypo.sairepet;
    this.contextGerer.ctxHistoriqueWKF = this.evtGenTypo.soumiswkf;
  }

  _manageCreateMode(): void {
    $(".gererevtgen-form-inputs").show();
    $(".gererevtgen-form-inputs", this.$appendTo).show();
    this._disableFilter(true);
    this.flagCreate = true;
  }

  _manageRevertMode(): void {
    if (this.isRevert) {
      $(".gererevtgen-form-inputs").hide();
      this.gererEvtGen.workflow.btnBarModel.set("mode", "C");
    }
    if (this.flagCreate) {      
      if (!(this.listerEvtGen?.selectedTypo?.nbre > 0)) {
        this.trigger("manageUnselected", true);
        $(".l-panelB-buttonContainer").hide();
      }
      this._disableFilter(false);
      this.flagCreate = false;
    }
  }

  _disableFilter(inactif: boolean): void {
    $(".evtGenRadio input").prop("disabled", inactif);
    CWFORMS.setFormReadonly($(".listerevenements_generalView"), inactif, false);
    $(".listerevenements_generalView button").prop("disabled", inactif);
  }

  _manageEmptyMode(): void {
    this.gererEvtGen.workflow.btnBarModel.set("mode", "C");
    this.isRevert = true;
    this.listerEvtGen.model.trigger("rowChange", null);
    $(".gererevtgen-form-inputs").hide();
    this.trigger("hideGererEvtGen", this);
  }

  _tableValueChange(model: CWListerEvtGenModel): void {
    const formMode = this.gererEvtGen.workflow.btnBarModel.get("mode");
    const modelId = (!model || model === null) ? null : model.id;

    CWLOG.debug("Table Value Changed to : " + modelId);
    this.gererEvtGen.$el.show();
    this.contextGerer.ctxTypologieEvenement = this.evtGenTypo;
    this.contextGerer.ctxEvtGenModeSaiDef = this.evtGenTypo.saidefo;
    this.contextGerer.ctxHistoriqueWKF = this.evtGenTypo.soumiswkf;
    this.contextGerer.ctxEvtGenIntitule = this.evtGenTypo.intitule;
    if (formMode !== "E") {
      $(".l-panelB-buttonContainer").show();
    }
    if (model === null) {
      this.contextGerer.ctxModeInitialisation = "";
      this.contextGerer.ctxIdentifiantEvt = null;
      this.contextGerer.ctxValeursEvenement = {};
      this.contextGerer.ctxValeursCreation = {
        EvtGenCode: this.evtGenTypo.code,
        EvtGenDateDebut: "",
        EvtGenDateFin: "",
        EvtGenModeSaiDef: this.evtGenTypo.saidefo
      };
      this.contextGerer.ctxHistoriqueWKF = false;
      if (model && model.get("indicateurpj") || GLOBAL_DATA.paramDivers.get("pJointe").get("valeur") === "1") {
        this.contextGerer.ctxPieceJointe = true;
      } else {
        this.contextGerer.ctxPieceJointe = false;
      }
      this.gererEvtGen.setContext(this.contextGerer);
      this.trigger("hideGererEvtGen", this);
      this.trigger("manageUnselected", true);
      this.gererEvtGen.btnBar.model.trigger("hide:save");
      this.gererEvtGen.btnBar.model.trigger("hide:revert");
      this.gererEvtGen.btnBar.model.trigger("hide:copy");
      this.gererEvtGen.btnBar.model.trigger("hide:delete");
      this.gererEvtGen.btnBar.model.trigger("hide:valider");
    } else {
      this.gererEvtGen.workflow.btnBarModel.set("mode", "E");
      this.isRevert = false;
      this.gererEvtGen.$el.find(".unselectedElementText").hide();
      this.contextGerer.ctxModeInitialisation = "Consultation";
      this.contextGerer.ctxIdentifiantEvt = modelId;
      this.contextGerer.ctxValeursEvenement = {
        EvtGenCodeStatutWKF: !CWSTR.isBlank(model.get("statut")) ? model.get("statut").code : "",
        EvtGenEnSuppression: model.get("etat") === "X",
        EvtGenCode: model.get("code"),
        EvtGenTypeSaisie: model.get("typesaisie"),
        EvtGenModeSaisie: model.get("modesaisie"),
        EvtGenDateDebut: model.get("datedeb"),
        EvtGenDateFin: model.get("datefin"),
        EvtGenHeureDebut: model.get("heuredeb"),
        EvtGenHeureFin: model.get("heurefin"),
        EvtGenInfoComplAffect: model.get("complement"),
        EvtGenListeInfoComplValeur: { caracol: model.get("caracol"), caraevt: model.get("caraevt") },
        EvtGenCommentaireWKF: model.get("commentaire"),
        EvtGenOrigine: !CWSTR.isBlank(model.get("origine")) ? model.get("origine").code : ""
      };
      this.contextGerer.ctxValeursCreation = {
        EvtGenCode: this.evtGenTypo.code,
        EvtGenDateDebut: "",
        EvtGenDateFin: "",
        EvtGenModeSaiDef: this.evtGenTypo.saidefo
      };
      if (model.get("indicateurpj") || GLOBAL_DATA.paramDivers.get("pJointe").get("valeur") === "1") {
        this.contextGerer.ctxPieceJointe = true;
      } else {
        this.contextGerer.ctxPieceJointe = false;
      }
      this.gererEvtGen.setContext(this.contextGerer);
      if (!this.gererEvtGen.$el.find(".gererevtgen-form-inputs").is(":visible")) {
        this.gererEvtGen.$el.find(".gererevtgen-form-inputs").show();
      }
    }
  }  
}