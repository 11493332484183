import * as Backbone from 'Backbone';
import _ from 'underscore';
import detailSelecteurRefDialogTPL from '../cwDetailSelecteurRefDialog.tpl.html';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';


/**
 * Selecteur Referentiel Dialog View
 */
export class CWSelecteurRefDialogView extends Backbone.View {

  coll: any;
  parent: any;
  table: any;
  searchTerm: string;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options.tagName = "div";
    options.className = "0";
    super(options);
    this.template = detailSelecteurRefDialogTPL;
    this.searchTerm = "";
    if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.coll)) {
      this.coll = options.coll;
    }

    this.parent = options.parent;

    // Create table
    this.table = this._initTable(options.currentSelection);
    const tableModel = this.table.model;
    this.listenTo(tableModel, "change:value", this.selection);
    this.listenTo(tableModel.multiselectColl, "add", this.selection);
    this.listenTo(tableModel.multiselectColl, "remove", this.selection);
    this.listenTo(tableModel.multiselectColl, "reset", this.selection);
  }

  remove(): CWSelecteurRefDialogView {
    this.table.remove();
    Backbone.View.prototype.remove.call(this);
    return this;
  }

  _manageTable(): void {
    this.table.model.resetRowHeight();
    this.table.model.coll.applyFilter({ "search": this.searchTerm });
    this.table.model.coll.habContext.set("onglet", "lanceetat");
    this.table.model.coll.fetch({
      success: (): void => {
        if (!CWSTR.isBlank(this.$el)) {
          this.$el.find(".referentielhabr-selecteurref-table legend span").text(this.table.model.coll.totalRecords);
        }
      }
    });
  }

  render(): CWSelecteurRefDialogView {
    const json = { 'i18n': i18n };
    this.$el.html(this.template(json));

    // Render table
    $(this.el).find(".table_selecteurref_referentielhabr").html(this.table.render().el);
    $(this.el).find(".ui-grid.c-grind__scroll").css("height", "400px");
    $(this.el).css("height", "85%");



    this._manageTable();

    $(this.el).find(".criteres").autocomplete({
      minLength: 0,
      source: (request: { [key: string]: any }): boolean => {
        this.searchTerm = request.term;
        this._manageTable();
        return false;
      }
    });

    if (this.parent.viewData.displayTitle !== true) {
      //this.$el.find(".habr-selecteur-view-size legend").hide();
      this.$el.find(".criteres").removeAttr("placeholder");
      this.$el.find(".phx-grid-header-scroll").hide();
      this.$el.find(".phx-grid-main-chooser").hide();
      this.$el.find(".phx-grid-scroll").css("margin-top", "38px");
    }

    return this;
  }

  selection(): any {
    const selection = this.table.getSelectedRows();
    this.trigger("response", selection);
    return selection;
  }

  _selectionAndClose(): void {
    this.trigger("close", "Y");
  }

  _initTable(currentSelection: { [key: string]: any }): CWDataGridView {
    const itemPerPage = 50;
    const tableModel = new CWDataGridModel({
      coll: this.coll,
      enableSelectionOnDblclick: true
    });

    const tblColumns: Array<CWBaseGridColums> = [{
      title: i18n.t('habr.table_selectref_code'),
      property: "code",
      width: 6
    }, {
      title: i18n.t('habr.table_selectref_libelle'),
      property: "libelle",
      width: 6
    }];

    const table = new CWDataGridView({
      id: this.parent.viewData.name + "_selecteurref_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('habr.legend_selectref_table'),
      showFilteredRowsInTitle: true,
      multiselection: this.parent.viewData.multiselection,
      gridHeightType: "auto",
      parentContainer: this.parent,
      paginatorPosition: "butom",
      itemsPerPage: itemPerPage
    });

    table.width = 750;
    table.height = 250;

    if (this.parent.viewData.displayAllColumns === true) {
      table.setVisibleColumns(["code", "libelle"]);
    } else {
      const visibleColumns: any = [];

      if (!CWSTR.isBlank(this.parent.viewData.displayColumns)) {
        _.each(this.parent.viewData.displayColumns, (item: any): void => {
          visibleColumns.push(item);
        });
      }

      table.setVisibleColumns(visibleColumns);
    }

    if (currentSelection && currentSelection instanceof Backbone.Collection) {
      currentSelection.forEach((element: { [key: string]: any }): void => {
        table.model.multiselectColl.add(element.get("attrs"));
      });
    }
    return table;
  }
}
