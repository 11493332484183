module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="form-group devenements-dialogPopupHeader">\n    <fieldset class="devenements-dialogPopupHeader cw-fieldset">\n        <div class="input-group">\n            <div class="phx-devenements-data">\n                <label for="commentaire" class="cw-label-entrezcomment text-left" title="'+
((__t=(i18n.t("dregularisations.entrez_Commentaires")))==null?'':__t)+
'">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t("dabsences.entrez_Commentaires")))==null?'':__t)+
'</label>\n                <textarea class="commentaire form-control" rows="6" cols="40" maxlength="60" style="resize:none" aria-required="true"></textarea>\n                <div class="cw-texteSuperAllege">'+
((__t=(i18n.t("dabsences.comment_popup3")))==null?'':__t)+
'</div>\n                <span class="checkdEvt c-form__groupCheckbox c-form__groupCheckbox--vertical">\n                    <div class="c-form__labelCheckbox">\n                        <input type="checkbox" name="demande" class="isForced demande c-form__checkbox" value="checkForcer" title="'+
((__t=(i18n.t('dregularisations.forcer')))==null?'':__t)+
'" />\n                        <span for="isForced" class="c-form__label">'+
((__t=(i18n.t("dregularisations.forcer")))==null?'':__t)+
'</span>\n                    </div>\n                </span>\n            </div>\n        </div>\n    </fieldset>\n</div>';
}
return __p;
};
