import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDataGridModel } from '../data_grid/cwDataGrid.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { Model } from 'Backbone';

export class CWMenuGridModel<TColl extends CWPaginatedCollection = CWPaginatedCollection, TModel extends CWBaseModel = CWBaseModel> extends CWDataGridModel<TColl, TModel> {


  public propertyPK: Array<string>;
  public btnBarModel: Model;

  /**
   * Constructor
   * Model underlying a base DataGrid with menu.
   * Events out: change:value
   * Events in: row:click, row:select, row:unselect
   * this is a consultation grid with a menu action bar.
   * the menu content and actions are defined by the calling UC
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.btnBarModel = undefined;
    this.propertyPK = [];
  }

  /**
   * Set up the work flow of the model. Link the models between them
   */
  setUp(): void {
    // Declare events consumers
    this.btnBarModel.on("btn:click", this.buttonAction, this);
  }

  /**
   * Public default button action of the grid. It is public to allow
   * the extension of its behavior by doing somethign like this:
   * someCustomBtnAction : function(buttonId) { // Additional code...
   * this.tableModel.buttonAction(buttonId); }
   */
  buttonAction(buttonId: string, callback: () => void): void {
    this._buttonAction(buttonId, callback);
  }

  /**
   * Empty button action
   */
  _buttonAction(buttonId: string, callback: () => void): void { //eslint-disable-line
    //nothing
  }
}
