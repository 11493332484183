import _ from 'underscore';
import { CWActionBarView } from 'core/controls/cwActionBar.view';
import { CWBaseGridOptions, CWBaseGridView } from '../basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWMenuGridModel } from './cwMenuGrid.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { View } from 'Backbone';


export interface CWMenuGridViewInterface<TModel extends CWBaseModel = CWBaseModel,
  TColl extends CWPaginatedCollection<TModel> = CWPaginatedCollection<TModel>> extends CWBaseGridOptions<TModel> {
  /**
   * @param {CWMenuGridModel} model: Model to use the grid
   */
  model?: CWMenuGridModel<TColl, TModel>;
}

export class CWMenuGridView<TModel extends CWBaseModel = CWBaseModel,
  TColl extends CWPaginatedCollection<TModel> = CWPaginatedCollection<TModel>> extends CWBaseGridView<TModel, TColl> {
  /**
   * DEFAULT_HEIGHT
   */
  DEFAULT_HEIGHT: number;
  /**
   * DEFAULT_NANE
   */
  DEFAULT_NANE: string;
  private _showButtonBar: boolean;
  public model: CWMenuGridModel<TColl, TModel>;

  /**
   * Constructor
   * MenuGridView - Consultation BaseGrid with a button menu bar
   */
  constructor(options: CWMenuGridViewInterface<TModel, TColl>) {
    options = _.extend({ className: 'cwMenuGrid' }, options);
    super(options);
    this.DEFAULT_HEIGHT = 305;
    this.DEFAULT_NANE = "MenuGrid";
    //columns definition
    if (!options.model) {
      throw Error("You must define an MenuGridModel to use a MenuGridView");
    }
    this.model = options.model;
    //Search Pk definition if exist and initializes toggle
    _.each(this.currentVue.columns, (column) => {
      if (_.has(column, "pk") && column.pk === true) {
        this.model.propertyPK.push(column.property);
      }
    });
    this._showButtonBar = true;
    //ButtonBar
    this.buttonBar = new CWActionBarView({ iconsOnly: true });
    this.model.btnBarModel = this.buttonBar.model;
    this.model.btnBarModel.set("mode", "R");
    //Btn bar reset
    this.model.coll.on("reset", this._resetBtnBar, this);
    // Set Up the model
    this.model.setUp();
  }

  /**
   * Renders a button bar for this grid
   */
  _renderButtonBar(): void {
    if (this._showButtonBar) {
      $(".c-grind__titleBar__barAction", this.el).append(this.buttonBar.render().el);
      // This is needed because when you use nested views, if you initializes the view in the constructor,
      // the delegate events is called before the dom is ready.
      this.buttonBar.delegateEvents();
    }
  }

  /**
   * Render this grid
   */
  render(): CWMenuGridView<TModel, TColl> {
    let tableScroll: JQuery = null;
    let tbody: JQuery = null;

    if ($(this.el).children().length === 0) { // Render the Button bar and the header only one time
      $(this.el).html(this.template());
      // Header and button bar
      this._renderHead();
      this._renderButtonBar();
      if (Object.keys(this.vues).length > 1 && this.showButtonsMultipleVues === true) {
        this._renderVuesButtonBar();
      }
    }
    //Configure scroll
    tableScroll = $(this.el).find(".c-grind__scroll");
    tableScroll.height(this.height);
    tableScroll.css("overflow-y", "auto");
    tableScroll.css("overflow-x", "auto");
    //Width:
    $(this.el).width(this.width);
    tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      const $tableScroll = $(".c-grind__scroll", this.el);
      const $headerScroll = $(".c-grind__headerScroll", this.el);

      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);
      $tableScroll.scroll((): void => {
        $headerScroll.scrollLeft($tableScroll.scrollLeft());
      });
    }
    this.applyHabilitations();
    this._configureButtonBar();
    this.positionHeader();
    return this;
  }

  /**
   * Sets button bar auto render
   */
  setButtonBarAutoRender(renderBtnBar: boolean): void {
    this._showButtonBar = renderBtnBar;
  }
  /**
   * Resets the button bar
   */
  _resetBtnBar(): void {
    this.model.set("mode", "C");
  }

  /**
   * Configures the buttonbar
   */
  _configureButtonBar(): void {
    this.buttonBar.buttons.habilitation["delete"] = false;
    this.buttonBar.buttons.habilitation["copy"] = false;
    this.buttonBar.buttons.habilitation["new"] = false;
    this.buttonBar.buttons.habilitation["moveUp"] = false;
    this.buttonBar.buttons.habilitation["moveDown"] = false;
    this.buttonBar._manageMode();
  }

  /**
   * OverrideMenu
   */
  overrideMenu(customButtonActionCallback: any, callingView: View): void {
    if (customButtonActionCallback) {
      this.buttonBar.model.off("btn:click");
      this.buttonBar.model.on("btn:click", customButtonActionCallback, callingView);
    }
  }
}