import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

export class CWHistoSouhaitOriginModel extends CWBaseModel {

  public ctxRefSouhait: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options)
    this.url = (): string => {
      const newUrl = Configuration.restRoot + "/rest/wkf/histo/souhait/" + encodeURIComponent(this.ctxRefSouhait);
      return newUrl;
    };
  }
}