import * as Backbone from 'Backbone';
import TPLPersobriquesBriqueLine from '../briqueLine.tpl.html';
import { CWButtonBarView } from 'core/controls/cwButtonBar.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWListeBriquesModel } from '../models/cwListeBriques.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'src/utils/sync.js';
import { UTILS } from 'src/utils';


/**
 * View for each line of brique
 */
export class CWBriquesLineView<TModel extends CWListeBriquesModel = CWListeBriquesModel> extends Backbone.View<TModel> {

  context: { [key: string]: any };
  totalActivesLength: number;
  btnBar: CWButtonBarView;
  position: any;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    super(options);
    this.template = TPLPersobriquesBriqueLine;
    if (options.model) {
      this.model = options.model;
    }
    if (options.context) {
      this.context = options.context;
    }
    if (options && !CWSTR.isBlank(options.position)) {
      this.position = options.position;
    }
    if (options && options.totalActivesLength) {
      this.totalActivesLength = options.totalActivesLength;
    }
    this.btnBar = new CWButtonBarView({ id: "persobriques_btnbar_" + this.model.getUniqueId(), isHideOnDisabled: false });
    this.btnBar.model.on("btn:click", this._buttonAction, this);
  }

  render(): CWBriquesLineView<TModel> {
    const idLine = this.model.getUniqueId();
    const json = { "i18n": i18n, "idLine": UTILS.escapeJQueryString(idLine) };
    const briqueToday = CWTYPE.DATE.dateToStr(SYNC.getServerDate());

    let nomBrique = "";

    switch (this.model.get("id")) {
      case "bemprunt":
        nomBrique = i18n.t('common:bemprunt.mesEmprunts_title', { "0": briqueToday });
        break;
      case "binfmem":
        nomBrique = i18n.t('common:binfmem.infosMemos');
        break;
      case "bdem":
        nomBrique = i18n.t('common:bdem.mesDemandes');
        break;
      case "bsitpart":
        nomBrique = i18n.t('common:bsitpart.situation');
        break;
      case "bdemcoll":
        nomBrique = i18n.t('common:bdemcoll.DemandesCollaborateurs');
        break;
      case "baffec":
        nomBrique = i18n.t('common:baffec.affectationEnCours');
        break;
      case "bsitreg":
        nomBrique = i18n.t('common:bsitreg.SituationsARegulariser');
        break;
      case "beff":
        nomBrique = i18n.t('common:beff.brickTitle', { "0": CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":") });
        break;
      /*case "bresultats":
        break;*/
      case "aujourdhui":
        nomBrique = i18n.t('common:aujourdhui.aujourdhui_title');
        break;
      default:
        nomBrique = this.model.get("nom");
        break;
    }

    const spanText = $("<span>").html(nomBrique);

    this.$el.append(this.template(json));
    this.$el.find(".persobriques-brique-line").attr("briqueUId", idLine);
    this.$el.find("input.persobriques-brique-line-check").attr("position", this.position);
    //Checkbox
    if (this.model.get("active") === true) {
      this.$el.find("input.persobriques-brique-line-check").prop("checked", true);
    }
    if (this._isLineFictive(this.model) === true) {
      this.$el.find("input.persobriques-brique-line-check").hide();
    }
    this._manageCheckBoxHabilitations();
    //Titre
    this.$el.find(".persobriques-brique-line-text").html();
    spanText.css("margin-right", "4px");
    this.$el.find(".persobriques-brique-line-text").append(spanText);
    if (!CWSTR.isBlank(this.model.get("personnalisation")) && !this._isLineFictive(this.model)) {
      if (this._isPersonnalisable(this.model)) { //ICON personnalisable
        //if (this._isPersonnalisable(this.model) || this._isLineFictive(this.model)) { //ICON personnalisable
        /*const spanPerso = $("<span>").addClass("phx-icon").addClass("phx-icon-personnalisable");

        spanPerso.css("display", "inline-block").css("margin-left", "3px").css("margin-right", "3px");
        this.$el.find(".persobriques-brique-line-text").append(spanPerso);*/

        if (this.model.get("personnalisation").visibilite === true) { //ICON public
          this.$el.find(".persobriques-brique-line-visibility").html(i18n.t('common:persobriques.publique'));
        } else {
          this.$el.find(".persobriques-brique-line-visibility").html(i18n.t('common:persobriques.privee'));
        }
      }
    }

    // if (model.get("id") === "bresultats") {
    //   "btn_ajouter"
    // }
    //Buttons
    if (this._isLineFictive(this.model)) {
      this.$el.find(".persobriques-brique-line").empty();
      this.$el.find(".persobriques-brique-line").append(this.btnBar.render().el);
    } else {
      this.$el.find(".persobriques-brique-line-btns").append(this.btnBar.render().el);
    }
    //this.$el.find(".persobriques-brique-line-btns").hide();
    this._generateButtonBar();
    this._manageButtonBarHabilitations();
    this._manageActions();
    return this;
  }

  _generateButtonBar(): void {
    //let span: any = null;

    this.btnBar.model.trigger("hide:save");
    this.btnBar.model.trigger("hide:revert");
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:copy");
    if (this._isLineFictive(this.model)) {
      this.btnBar.changeTypeButton("creer", "btn-secondary btn-carre");//avant de la création
      this.btnBar.addButton("creer", i18n.t("common:persobriques.btn_ajouter").toUpperCase(), "", null);
    } else {
      this.btnBar.changeTypeButton("detail", "btn-secondary btn-carre");//avant de la création
      this.btnBar.addButton("detail", "", "engrenage", null, null, null, null, null, null, i18n.t("common:ARIA_label.perso_option"));
    }
  }

  _manageCheckBoxHabilitations(): void {
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.$el.find("input.persobriques-brique-line-check").hide();
    }
  }

  _manageButtonBarHabilitations(): void {
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.$el.find("input.persobriques-brique-line-check").hide();
    }
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.btnBar.model.trigger("hide:monter");
      this.btnBar.model.trigger("hide:descendre");
      this.btnBar.model.trigger("hide:detail");
    }
    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:creer");
      this.btnBar.model.trigger("hide:supprimer");
    }
    if (!CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:creer");
    }
    if (!CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:supprimer");
    }
    if (this._visibilitePublic(this.model) && !CWHABILITATION.canView("PER_BRACCTOUS.G")) {
      this.btnBar.model.trigger("hide:supprimer");
    }
  }

  _manageActions(): void {
    //Monter
    if (!this._isActive(this.model) || this.position === 0) {
      this.btnBar.model.trigger("disable:monter");
    } else {
      this.btnBar.model.trigger("enable:monter");
    }
    //Descendre
    if (!this._isActive(this.model) || this.position >= (this.totalActivesLength - 1)) {
      this.btnBar.model.trigger("disable:descendre");
    } else {
      this.btnBar.model.trigger("enable:descendre");
    }
    //detail
    if (this._isPersonnalisable(this.model) && !this._isLineFictive(this.model)) {
      this.btnBar.model.trigger("enable:detail");
      this.$el.find("button.detail").css("visibility", "visible");
    } else {
      this.btnBar.model.trigger("disable:detail");
      this.$el.find("button.detail").css("visibility", "hidden");
    }
    //creer
    if (!this._isPersonnalisable(this.model) && !this._isLineFictive(this.model)) {
      this.btnBar.model.trigger("disable:creer");
      this.$el.find("button.creer").css("visibility", "hidden");
    } else {
      this.btnBar.model.trigger("enable:creer");
      this.$el.find("button.creer").css("visibility", "visible");
    }
    //supprimer
    if (this._isPersonnalisable(this.model) && !this._isLineFictive(this.model)) {
      this.btnBar.model.trigger("enable:supprimer");
      this.$el.find("button.supprimer").css("visibility", "visible");
    } else {
      this.btnBar.model.trigger("disable:supprimer");
      this.$el.find("button.supprimer").css("visibility", "hidden");
    }
  }

  _isActive(model: CWListeBriquesModel): boolean {
    let active = false;

    if (model.get("active") === true) {
      active = true;
    }
    return active;
  }

  _isStandard(model: CWListeBriquesModel): boolean {
    let standard = true;

    if (!CWSTR.isBlank(model.get("personnalisation"))) {
      standard = false;
    }
    return standard;
  }

  _isPersonnalisable(model: CWListeBriquesModel): boolean {
    let perso = false;

    if (!CWSTR.isBlank(model.get("personnalisation")) && !CWSTR.isBlank(model.get("personnalisation").userid)) {
      perso = true;
    }
    return perso;
  }

  _isLineFictive(model: CWListeBriquesModel): boolean {
    let fictive = false;

    if (!CWSTR.isBlank(model.get("personnalisation")) && CWSTR.isBlank(model.get("personnalisation").userid) && CWSTR.isBlank(model.get("personnalisation").codebrique)) {
      fictive = true;
    }
    return fictive;
  }

  _visibilitePublic(model: CWListeBriquesModel): boolean {
    let publ = false;

    if (!CWSTR.isBlank(model.get("personnalisation")) && model.get("personnalisation").visibilite === true) {
      publ = true;
    }
    return publ;
  }

  /**
   * Function that manages button clicked in right form
   */
  _buttonAction(buttonId: any): void {
    this.model.trigger("buttonAction", buttonId, this.position);
    //this.btnBar.$el.find("." + buttonId).blur();
  }
}
