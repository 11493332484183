import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWDroitColl } from './cwDroit.collection';
import { TDAColl } from './tda.collection.js';
import { TDAModel } from './tda.model.js';

/**
 * Configuration Model. To be used with these ids: "session" --> Authentication
 * and user in session data "date" --> Server date "langues" --> langues
 * available
 */
export class CWConfigModel extends Backbone.Model {

  usecase: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/configuration";
    }
  }

  /**
   * Process to manage the response
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.confignav) {
      //on crée une copie de la zone sans les autres submenus comme menu de la part gauche
      _.each(response.confignav.zones, (item: { [key: string]: any }): void => {
        const lCopMenuzone = _.clone(item);

        lCopMenuzone.menus = [];
        item.menus.unshift(lCopMenuzone);
      });

      for (let k = 0; k < response.confignav.zones.length; k++) {
        const item = response.confignav.zones[k];

        switch (item.id) {
          case "coll":
            item.icone = "personnage.svg";
            break;
          case "resp":
            item.icone = "equipe.svg";
            break;
          case "ref":
            item.icone = "referentiel.svg";
            break;
          case "adm":
            item.icone = "engrenage.svg";
            break;
          default:
          //Nothing
        }
        for (let j = 0; j < item.menus.length; j++) {
          const submenu = item.menus[j];

          switch (submenu.id) {
            case "coll":
            case "resp":
            case "ref":
            case "adm":
              break;
            case "agenda":
              break;

            default:
              switch (submenu.icone) {
                case "vide.png":
                  submenu.icone = "vide.svg";
                  break;
                default:
                  break;
              }
              for (let w = 0; w < submenu.ecrans.length; w++) {
                const lEcran = submenu.ecrans[w];

                switch (lEcran.icone) {
                  case "vide.png":
                    lEcran.icone = "";
                    break;
                  default:
                    break;
                }
              }
          }
        }
      }
      this.set("confignav", new Backbone.Model(response.confignav));
      delete response.confignav;
    }
    if (response.droit) {
      this.set("droit", new CWDroitColl(response.droit, { parse: true }));
      delete response.droit;
    }
    if (response.typeapplicatif) {
      const length = response.typeapplicatif.length;

      this.set("typeapplicatif", new TDAColl());

      for (let i = 0; i < length; i++) {
        this.get("typeapplicatif").add(new TDAModel(response.typeapplicatif[i], {
          parse: true
        }));
      }
      delete response.typeapplicatif;
    }
    return response;
  }
}
