import * as _ from 'underscore';
import { CWSTR } from './cwStr';
import { GLOBAL_DATA } from 'src/globalData';

/**
 * Utilities to check habilitations
 */
export class CWHABILITATION {

  /**
 * Check if we can view with this habilitation
 */
  static canView(fonction: string): boolean {
    try {
      if (fonction === "N") {
        return true;
      } else {
        let right = false;
        const fonctions = CWHABILITATION.toArray(fonction);

        for (let i = 0, l = fonctions.length; i < l && !right; i++) {
          right = right || !CWSTR.isBlank(GLOBAL_DATA.rights.get(fonctions[i]));
        }
        return right;
      }
    } catch (err) {
      return false;
    }
  }

  /**
   * Check if we have all view habilitations
   */
  static canViewAll(fonction: string): boolean {
    try {
      let right = true;
      const fonctions = !_.isArray(fonction) ? CWHABILITATION.toArray(fonction) : fonction;
      const len = fonctions.length;

      for (let i = 0; right && i < len; i++) {
        right = right && !CWSTR.isBlank(GLOBAL_DATA.rights.get(fonctions[i]));
      }
      return right;
    } catch (err) {
      return false;
    }
  }

  /**
   * Check if we can create with this habilitation
   */
  static canCreate(fonction: string): boolean {
    try {
      let right = false;
      const fonctions = CWHABILITATION.toArray(fonction);

      for (let i = 0, l = fonctions.length; i < l && !right; i++) {
        right = right || (!CWSTR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && (GLOBAL_DATA.rights.get(fonctions[i]).get("gesta") === "O" || GLOBAL_DATA.rights.get(fonctions[i]).get("fonctgesta") === "N"));
      }
      return right;
    } catch (err) {
      return false;
    }
  }

  /**
   * Check if we can update with this habilitation
   */
  static canUpdate(fonction: string): boolean {
    try {
      let right = false;
      const fonctions = CWHABILITATION.toArray(fonction);

      for (let i = 0, l = fonctions.length; i < l && !right; i++) {
        right = right || (!CWSTR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && (GLOBAL_DATA.rights.get(fonctions[i]).get("gestm") === "O" || GLOBAL_DATA.rights.get(fonctions[i]).get("fonctgestm") === "N"));
      }
      return right;
    } catch (err) {
      return false;
    }
  }

  /**
   * Check if we can delete with this habilitation
   */
  static canDelete(fonction: string): boolean {
    try {
      let right = false;
      const fonctions = CWHABILITATION.toArray(fonction);

      for (let i = 0, l = fonctions.length; i < l && !right; i++) {
        right = right || (!CWSTR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && (GLOBAL_DATA.rights.get(fonctions[i]).get("gests") === "O" || GLOBAL_DATA.rights.get(fonctions[i]).get("fonctgests") === "N"));
      }
      return right;
    } catch (err) {
      return false;
    }
  }

  /**
   * Transforms a string into a array
   */
  static toArray(fonction: string): Array<string> {
    const liste: string[] = fonction.split(",");

    for (let i = 0; i < liste.length; i++) {
      //remove spaces en blanc(gauche et droite)
      liste[i] = liste[i].trim();
    }
    return liste;
  }

}