import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLActivitetreesStructureSimpleTree from '../cwStructureSimpleTree.tpl.html';
import { CWActiviteColl } from 'common/evenements/planifier/activite/models/cwActivite.collection';
import { CWActiviteTreesViewType } from './../models/cwActivitetrees.workflow';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWBaseTreeView } from './cwBaseTree.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from 'utils/cwStr';
import { CWTree2View } from 'core/tree/cwTree2.view';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { SelecteurCheminItemModel } from '../models/selecteurCheminItem.model.js';

/**
 * Hierarchies Activites Tree
 */
export class CWStructureSimpleTreeView extends CWBaseTreeView {
  _obtainLastParentView(): { [key: string]: any } {
    return {}
  }
  racineColl: CWActiviteColl;
  deployColl: any;
  showSelected: boolean;
  multiselect: boolean;
  hideMenuOptions: boolean;
  showExpandAllIcon: boolean;
  readOnly: boolean;
  isCheckedCallback: any;
  listRenderStyle: number;
  hideCheck: boolean;
  opennode: boolean;
  firstLevelModels: any;
  initSelectedModels: any;
  label: any;
  context: { [key: string]: any };
  fetchNodeOrFirstNumModels: number;

  /**
   * Constructor
   * Hierarchies Activites Tree
   */
  constructor(params?: { [key: string]: any }) {
    params = params || {};
    params.tagName = "span";
    params.className = "phx-activite-struc-simpl-tree-menuicon";
    params.events = _.extend({
      "click .phx-activite-struc-simpl-tree-menuicon": "_toggleMenu",
      "click .phx-activite-struc-simpl-tree-menuicon span": "_changeRenderer",
      "click .phx-activite-struc-simpl-tree-menuicon li": "_changeRenderer"
    }, params.events);
    super(params);
    this.template = TPLActivitetreesStructureSimpleTree;
    this.fetchNodeOrFirstNumModels = 0;
    if (!params) {
      throw new Error("Parameters are missing in the tree definition");
    }
    this.params = params;
    this.workflow = params.workflow;
    this.context = params.context;
    this.racineModel = params.racineModel;
    this.racineColl = params.racineColl;
    this.deployColl = params.deployColl;
    this.showSelected = true;
    if (!CWSTR.isBlank(params.showSelected)) {
      this.showSelected = params.showSelected;
    }
    this.model = new CWBaseModel({
      domaine: 0,
      value: null
    });
    //EVO 186: Multiselection mode, to show the checkboxes
    this.multiselect = false;
    if (params && !CWSTR.isBlank(params.multiselect)) {
      this.multiselect = params.multiselect;
    }
    //Show / hide labeling menu option
    this.hideMenuOptions = true;
    //Show / hide Expand / collapse all option
    this.showExpandAllIcon = true;
    if (params && params.showExpandAllIcon) {
      this.showExpandAllIcon = params.showExpandAllIcon;
    }
    //Checks are editable or not
    this.readOnly = false;
    if (params && params.readOnly) {
      this.readOnly = params.readOnly;
    }
    if (params && params.isCheckedCallback) {
      this.isCheckedCallback = params.isCheckedCallback;
    }
    this.model.on("change:domaine", this._setDomaineID, this);
    this.model.on("selectNode", this._selectNode, this);
    this.model.on("expandTreePath", this.expandTreePath, this);
    this.model.on("updateTreeNode", this.updateTreeNode, this);
    this.model.on("updateTreeNodeRecursive", this.updateTreeNodeRecursive, this);
    this.model.on("updateActiviteParents", this._updateActiviteParents, this);
    this.model.on("updateParentAfterDelete", this.updateParentAfterDelete, this);
    this.model.on("updateParentAfterDeleteNoSelect", this.updateParentAfterDeleteNoSelect, this);
    this.model.on("findExpandedParents", this.findExpandedParents, this);
    this.model.on("selectCorrectNodeOrFirst", this._selectCorrectNodeOrFirst, this);
    this.model.on("selectCorrectNodeAfterDup", this._selectCorrectNodeAfterDup, this);
    this.listRenderStyle = 0;
    this.hideCheck = false;
    if (!CWSTR.isBlank(params.hideCheck)) {
      this.hideCheck = params.hideCheck
    }
  }

  _obtainView(): string {
    return "STRUCTURESIMPLE";
  }

  /**
   * Gets the function to return the renderer depdending on option selected (0 is default option)
   */
  _getRenderer(listRendererStyle?: number | string, classToApply?: string): (item: CWBaseModel) => string {
    let result = null;
    const domaineDetail = this.workflow.context.ctxDomaineDetail;
    const niveauxTypeHierarchy = this.workflow.context.ctxNiveauxTypeHierarchy;
    let format = null;
    const listRenderer = !CWSTR.isBlank(listRendererStyle) ? listRendererStyle : this.listRenderStyle;

    switch (String(listRenderer)) {
      case "0":
        result = (item: CWBaseModel): string => {
          let renderfunction;

          if (item.get("code") === " ") {
            const horsStruct = domaineDetail.get("hiera") === true ? i18n.t('activite.hors_hier_ou_struct') : i18n.t('activite.hors_structure');

            return horsStruct;
          } else if (item.get("typelt") === "S") {
            return item.get("libelleformat");
          } else { //Activites
            //Added code comprobation because we need code and niveu values both of them.
            //hiertypeniv:{code: null, niveau: 0}
            if (item.get("hiertypeniv") && !CWSTR.isBlank(item.get("hiertypeniv").niveau) && !CWSTR.isBlank(item.get("hiertypeniv").code)) {
              let itemNiveau = null;

              if (this.workflow.hierarchie) {
                itemNiveau = this.workflow.hierarchie.findWhere({ niveau: item.get("hiertypeniv").niveau });
              } else {
                if (niveauxTypeHierarchy) {
                  itemNiveau = niveauxTypeHierarchy[item.get("hiertypeniv").niveau];
                }
              }
              if (itemNiveau) {
                let affichageClass = "";

                if (itemNiveau.get) { //is a model
                  affichageClass = itemNiveau.get("style").affichage;
                  renderfunction = this._getRenderer(parseInt(itemNiveau.get("format")), affichageClass);
                } else { //Is a object
                  affichageClass = itemNiveau.style.affichage;
                  renderfunction = this._getRenderer(parseInt(itemNiveau.format), affichageClass);
                }
                return renderfunction(item);
              } else {
                if (!CWSTR.isBlank(domaineDetail.get("fmtact"))) { //Default format for domaine
                  format = (!CWSTR.isBlank(domaineDetail.get("fmtact"))) ? domaineDetail.get("fmtact") : 1;
                  renderfunction = this._getRenderer(parseInt(format));
                  return renderfunction(item);
                } else {
                  return item.get("libelle") + " (" + item.get("code") + ")";
                }
              }
            } else if (!CWSTR.isBlank(domaineDetail.get("fmtact"))) { //Default format for domaine
              format = (!CWSTR.isBlank(domaineDetail.get("fmtact"))) ? domaineDetail.get("fmtact") : 1;
              renderfunction = this._getRenderer(parseInt(format));
              return renderfunction(item);
            } else {
              return item.get("libelle") + " (" + item.get("code") + ")";
            }
          }
        };
        break;
      case "1":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            const horsStruct = domaineDetail.get("hiera") === true ? i18n.t('activite.hors_hier_ou_struct') : i18n.t('activite.hors_structure');

            return horsStruct;
          } else if (!CWSTR.isBlank(item.get("typelt")) && item.get("typelt") === "S") {
            const chemin = this.generateCodesChemin(item);

            return item.get("libelle") + " (" + chemin + ")";
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("libelle") + " (" + item.get("code") + ")");
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "2":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            const horsStruct = domaineDetail.get("hiera") === true ? i18n.t('activite.hors_hier_ou_struct') : i18n.t('activite.hors_structure');

            return horsStruct;
          } else if (!CWSTR.isBlank(item.get("typelt")) && item.get("typelt") === "S") {
            const chemin = this.generateCodesChemin(item);

            return item.get("codef") + " (" + chemin + ")";
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("code") + " (" + item.get("libelle") + ")");
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "3":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            const horsStruct = domaineDetail.get("hiera") === true ? i18n.t('activite.hors_hier_ou_struct') : i18n.t('activite.hors_structure');

            return horsStruct;
          } else if (!CWSTR.isBlank(item.get("typelt")) && item.get("typelt") === "S") {
            const chemin = this.generateCodesChemin(item);

            return item.get("libelle") + " (" + chemin + ")";
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("libelle"));
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "4":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            const horsStruct = domaineDetail.get("hiera") === true ? i18n.t('activite.hors_hier_ou_struct') : i18n.t('activite.hors_structure');

            return horsStruct;
          } else if (!CWSTR.isBlank(item.get("typelt")) && item.get("typelt") === "S") {
            const chemin = this.generateCodesChemin(item);

            return item.get("codef") + chemin;
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.append(item.get("code"));
            return spanItem[0].outerHTML;
          }
        };
        break;
      default:
        break;
    }
    return result;
  }

  generateCodesChemin(item: { [key: string]: any }): string {
    let chemin = "";

    _.each(item.get("rattachement"), (ratt: { [key: string]: any }): void => {
      if (ratt.datedeb === item.get("datedeb")) {
        chemin = ratt.libelle + item.get("codef");
      }
    });
    return chemin;
  }

  /** Callback is a function to select the right node(from the previous view)
   *
   **/
  _paintTree(calback?: () => void): void {//, numModels?: number): void {
    const activiteColl = this.deployColl.clone();

    //Gets the collection to build the tree
    activiteColl.setHabContext(this.workflow.getHabContext());
    this.opennode = !CWSTR.isBlank(this.tree) && !CWSTR.isBlank(this.tree.root.opennode) && this.tree.root.opennode === true ? true : false;
    //clean tree
    this.close();
    if (!this.tree) {
      //Create the tree
      this.tree = new CWTree2View({
        "showSelected": this.showSelected,
        "coll": null,
        "name": null,
        "draggable": true,
        "rootModel": this.racineModel,
        "manyRoots": true,
        "firstLevelModels": this.firstLevelModels,
        "buildUrlColl": (parentNiveau: number, node: CWBaseModel, collection: { [key: string]: any }): void => {
          const domaineDetail = this.workflow.context.ctxDomaineDetail;

          collection.setNiveau(parentNiveau + 1);
          collection.setParentTypelt(node.get("typelt"));
          collection.setParentCode(node.get("code"));
          collection.setParentDateDebut(node.get("datedeb"));
          collection.setParentDateFin(node.get("datefin"));
          collection.setDomaine(!CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domcode") : "");
          //Selecteur activite tooltip necessary data
          collection.setDomaineLibelle(!CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domlib") : "");
          if (!CWSTR.isBlank(node.get("structida")) && !CWSTR.isBlank(collection.setStructId)) { //Load structure for first activity that falls from an structure
            collection.setStructId(node.get("structida"));
          } else if (!CWSTR.isBlank(node.get("parentstraid")) && !CWSTR.isBlank(collection.setStructId)) { //Load structure for activities that don't fall from an structure
            collection.setStructId(node.get("parentstraid"));
          }
          if (!CWSTR.isBlank(node.get("itemida")) && !CWSTR.isBlank(collection.setItemId)) { //Load itemid for activities that don't falal directly from an structure and have itemid=null
            collection.setItemId(node.get("itemida"));
          }
          collection.setContext(this.workflow.context.ctxFilterData);
          collection.setVue(3);
        },
        "dragAndDropCallback": (): void => {
          //Do nothing
        },
        "renderer": this._getRenderer(),
        "checkClass": (model: CWBaseModel): string => {
          if (model.get("typelt") === "S" || model.get("code") === " ") {
            return "phx-triangle-icon";
          } else {
            return "";
          }
        },
        "tooltipRenderer": (model: CWBaseModel, view: { [key: string]: any }): void => {
          let text = "";
          let label = $(view.$el.find(".cw-treenode-label")[0]).html();
          const treeLabel = view.$el.find(".cw-treenode-label")[0];
          const tooltipIcon = " <span class=\"cw-tree-tooltip-icon\" title=\"\"></span>";
          let tooltip: JQuery = null, tooltipCheck: JQuery = null, datedeb = "", datefin = "";
          const competencePartielle = this._getCompetencePartielle(model);
          const couvertureCertifications = this._getCouvertureCertifications(model);
          const inaptitudeComplete = this._getInaptitudeComplete(model);
          const inaptitudePartielle = this._getInaptitudePartielle(model);
          let padding = 0;
          const $lPosTooltiCpheck = view.$el.find("div.cw-treenode-check-container");

          //add tooltip icon
          //Avoid aplying not selectionnable IHM style to familles
          if ($(treeLabel).hasClass("ui-phx-ihm-non-selectionnable")) {
            $(treeLabel).removeClass("ui-phx-ihm-non-selectionnable");
            if (model.get("typelt") !== "S" && model.get("code") !== " ") {
              label = "<span class='ui-phx-ihm-non-selectionnable'>" + label + "</span>";
            }
          }
          //Structures style
          if (model.get("typelt") === "S" || model.get("code") === " ") {
            $(treeLabel).addClass("ui-cw-element-hierarchie-structure");
          }
          if (competencePartielle !== "" || couvertureCertifications !== "") {
            padding = 25;
          }
          if (inaptitudeComplete !== "" || inaptitudePartielle !== "") {
            padding += 25;
          }
          if (padding > 0) {
            label = "<span style=\"padding-left: " + padding + "px\">" + label + "</span>";
          }
          if (!CWSTR.isBlank(model.get("rattachement")) && model.get("rattachement").length > 1) {
            let first = true;

            for (let i = 0; i < model.get("rattachement").length; i++) {
              if (first === false) {
                text += "</br></br>";
              }
              if (model.get("rattachement")[i].datedeb !== model.get("datedeb")) {
                //normal
                text += this._paintTooltipPeriod(model.get("rattachement")[i]);
                //Include chemin at tooltip
                text += "  " + this._createCheminLibelle(model.get("rattachement")[i].libelle);
              } else {
                //Painting current structure
                //bold current node
                text += "<b>" + this._paintTooltipPeriod(model) + "</b>";
                //Include chemin at tooltip
                text += "  " + this._createCheminLibelle(model.get("rattachement")[i].libelle);
              }
              first = false;
            }
            if (this.context?.ctxModeRepresentation === "pop-up") {
              view.$el.find(".cw-treenode-label").first().html(competencePartielle + couvertureCertifications + inaptitudeComplete + inaptitudePartielle + label + tooltipIcon);
              tooltip = view.$el.find(".cw-tree-tooltip-icon").first().tooltip({ content: "" });
              tooltip.tooltip("option", "content", text);
              if (model.get("feuille") === true) {
                $lPosTooltiCpheck.append(competencePartielle + couvertureCertifications + inaptitudeComplete + inaptitudePartielle + tooltipIcon);
                tooltipCheck = $lPosTooltiCpheck.find(".cw-tree-tooltip-icon").first().tooltip({ content: "" });
                tooltipCheck.tooltip("option", "content", text);
              }
            } else {
              view.$el.find(".cw-treenode-label").first().html(competencePartielle + couvertureCertifications + inaptitudeComplete + inaptitudePartielle + label);
            }
          } else {
            const dated = CWSTR.isBlank(model.get("datedeb")) ? CWTYPE.DATE.INITIAL : model.get("datedeb");
            const datef = CWSTR.isBlank(model.get("datefin")) ? CWTYPE.DATE.INFINITY : model.get("datefin");

            if ((dated > this.params.workflow.context.ctxPeriodeGestion.datedeb && datef < this.params.workflow.context.ctxPeriodeGestion.datefin) ||
              datef < this.params.workflow.context.ctxPeriodeGestion.datefin ||
              dated > this.params.workflow.context.ctxPeriodeGestion.datedeb) {
              datedeb = CWTYPE.DATE.format(dated);
              datefin = CWTYPE.DATE.format(datef);
              if ((dated > CWTYPE.DATE.INITIAL && datef < CWTYPE.DATE.INFINITY)) {
                datedeb = CWTYPE.DATE.format(model.get("datedeb"));
                datefin = CWTYPE.DATE.format(model.get("datefin"));
              } else if (datef < CWTYPE.DATE.INFINITY) {
                datedeb = "... ";
                datefin = CWTYPE.DATE.format(datef);
              } else if (dated > CWTYPE.DATE.INITIAL) {
                datedeb = CWTYPE.DATE.format(dated);
                datefin = "  ...";
              }
              text += "[" + datedeb + " - " + datefin + "]";
              if (this.context?.ctxModeRepresentation === "pop-up") {
                view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inaptitudeComplete + inaptitudePartielle + label + tooltipIcon);
                tooltip = view.$el.find(".cw-tree-tooltip-icon").tooltip({ content: "" });
                tooltip.tooltip("option", "content", text);
                if (model.get("feuille") === true) {
                  $lPosTooltiCpheck.append(competencePartielle + couvertureCertifications + inaptitudeComplete + inaptitudePartielle + tooltipIcon);
                  tooltipCheck = $lPosTooltiCpheck.find(".cw-tree-tooltip-icon").tooltip({ content: "" });
                  tooltipCheck.tooltip("option", "content", text);
                }
              } else {
                view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inaptitudeComplete + inaptitudePartielle + label);
              }
            } else {
              view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inaptitudeComplete + inaptitudePartielle + label);
            }
          }
        },
        "overLabelTooltipRenderer": (model: { [key: string]: any }, view: { [key: string]: any }, callback2: (context: this) => void): void => {
          //add tooltip icon
          if (model.get("typelt") === "S") {
            const domaineDetail = this.workflow.context.ctxDomaineDetail;
            let item = new SelecteurCheminItemModel({
              "structid": domaineDetail.get("straid"),
              "date": model.get("datedeb") ? model.get("datedeb") : CWTYPE.DATE.INITIAL,
              "elementCode": model.get("code"),
              "nonrattachable": true,
              "nonrattactiv": false
            });

            if (!CWSTR.isBlank(model.get("code")) && !CWSTR.isBlank(model.get("datedeb"))) {
              item = new SelecteurCheminItemModel({
                "detailunique": true,
                //								"source": "uc/common/activitetrees (code,datedeb)",
                "datedebut": model.get("datedeb") ? model.get("datedeb") : CWTYPE.DATE.INITIAL,
                "datefin": model.get("datefin") ? model.get("datefin") : CWTYPE.DATE.INFINITY,
                "structid": domaineDetail.get("straid"),
                "elementCode": model.get("code"),
                "nonrattachable": true,
                "nonrattactiv": false
              });
            }
            // Le WS ne doit avoir qu'une seule habilitation
            if (this.workflow.selecteurContext && (this.workflow.selecteurContext.ctxEcran === "planresp" || this.workflow.selecteurContext.ctxEcran === "planmed")) {
              let foncCour = null;

              if (this.workflow.selecteurContext.ctxTypeEvtGere === "R") {
                if (typeof this.workflow.selecteurContext.ctxHabilitation.HabilitationGestion === "string") {
                  foncCour = this.workflow.selecteurContext.ctxHabilitation.HabilitationGestion;
                } else {
                  foncCour = this.workflow.selecteurContext.ctxHabilitation.HabilitationGestion.realisee;
                }
              } else {
                if (typeof this.workflow.selecteurContext.ctxHabilitation.HabilitationGestion === "string") {
                  foncCour = this.workflow.selecteurContext.ctxHabilitation.HabilitationGestion;
                } else {
                  foncCour = this.workflow.selecteurContext.ctxHabilitation.HabilitationGestion.prevue;
                }
              }
              item.setHabContext(new CWHabilitationContext({
                onglet: this.workflow.selecteurContext.ctxEcran,
                foncCour: foncCour,
              }));
            } else if (this.context && (this.context.ctxEcran === "planresp" || this.context.ctxEcran === "planmed")) {
              let foncCour = null;

              if (this.context.ctxTypeEvtGere === "R") {
                if (typeof this.context.ctxHabilitation.HabilitationGestion === "string") {
                  foncCour = this.context.ctxHabilitation.HabilitationGestion;
                } else {
                  foncCour = this.context.ctxHabilitation.HabilitationGestion.realisee;
                }
              } else {
                if (typeof this.context.ctxHabilitation.HabilitationGestion === "string") {
                  foncCour = this.context.ctxHabilitation.HabilitationGestion;
                } else {
                  foncCour = this.context.ctxHabilitation.HabilitationGestion.prevue;
                }
              }
              item.setHabContext(new CWHabilitationContext({
                onglet: this.context.ctxEcran,
                foncCour: foncCour
              }));
            } else {
              if (!this.workflow.getHabContext().attributes.foncCour.actprev || !this.workflow.getHabContext().attributes.foncCour.actreal) {
                const habTmp = this.workflow.getHabContext();

                if (this.workflow.getHabContext().attributes.foncCour.actprev) {
                  habTmp.attributes.foncCour = this.workflow.getHabContext().attributes.foncCour.actprev;
                } else if (this.workflow.getHabContext().attributes.foncCour.actreal) {
                  habTmp.attributes.foncCour = this.workflow.getHabContext().attributes.foncCour.actreal;
                }
                item.setHabContext(habTmp);
              } else {
                item.setHabContext(this.workflow.getHabContext());
              }
            }
            item.fetch({
              success: (resp: { [key: string]: any }) => {
                let tooltip = undefined;
                const attrs = resp.get("0");
                let txt = "";
                let header = "";
                const dated = !CWSTR.isBlank(attrs) && !CWSTR.isBlank(attrs.datedeb) ? attrs.datedeb : CWTYPE.DATE.INITIAL;
                const datef = !CWSTR.isBlank(attrs) && !CWSTR.isBlank(attrs.datefin) ? attrs.datefin : CWTYPE.DATE.INFINITY;
                const levels = this.workflow.elementsRattachesTitleColl;
                let marginLeft = 0;
                const tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
                const parents = !CWSTR.isBlank(attrs) ? attrs.parents : "";

                if (!CWSTR.isBlank(view.$el)) {
                  $(view.$el.find(".cw-treenode-label")[0]).attr("title", "");
                  tooltip = view.$el.find(".cw-treenode-label").first().tooltip({ content: "" });
                }
                //(&#9679)= (●)                
                if (dated > this.workflow.context.ctxPeriodeGestion.datedeb && datef >= this.workflow.context.ctxPeriodeGestion.datefin) {
                  header = i18n.t('activite.sitpartdu', { "0": CWTYPE.DATE.format(dated) });
                }
                if (dated <= this.workflow.context.ctxPeriodeGestion.datedeb && datef < this.workflow.context.ctxPeriodeGestion.datefin) {
                  header = i18n.t('activite.sitjusq', { "0": CWTYPE.DATE.format(datef) });
                }
                if (dated > this.workflow.context.ctxPeriodeGestion.datedeb && datef < this.workflow.context.ctxPeriodeGestion.datefin && dated !== datef) {
                  header = i18n.t('activite.sitduau', { "0": CWTYPE.DATE.format(dated), "1": CWTYPE.DATE.format(datef) });
                }
                if (dated > this.workflow.context.ctxPeriodeGestion.datedeb && datef < this.workflow.context.ctxPeriodeGestion.datefin && dated === datef) {
                  header = i18n.t('activite.sitdatedu', { "0": CWTYPE.DATE.format(datef) });
                }
                if (header) {
                  txt = "<div style='text-align:center;'><span >" + header + "</span><br/></div>";
                }
                if (parents && parents.length > 0) {
                  model.tooltipParents = parents;
                  _.each(model.tooltipParents, (tooltipParent: { [key: string]: any }) => {
                    tooltipParent.typelt = "S";
                  });
                  // paint chemin when node is not a root node
                  if (parents.length > 1) { //Only paint chemin when current Niveau is higher than 2
                    txt += "<table style=\"width:100px\">";
                    for (let j = 0; j < parents.length; j++) {
                      const tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";

                      if (j === 0) {
                        //Don't paint root at the chemin
                      } else {
                        let niveau = "";

                        if (!CWSTR.isBlank(levels) && j <= levels.length) {
                          niveau = levels.models[j - 1].get("libelle");
                        }
                        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + parents[j].libelle + "(" + parents[j].codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
                        marginLeft += 10;
                      }
                    }
                    txt += "</table>";
                  }
                }
                if (!CWSTR.isBlank(tooltip)) {
                  tooltip.tooltip("option", "content", txt);
                }
                if (callback2) {
                  callback2(this);
                }
              }
            }, this);
          } else {
            if (callback2) {
              callback2(this);
            }
          }
        },
        "selectableNode": (model: CWBaseModel): boolean => {
          if (model.get("typelt") === "S" || CWSTR.isBlank(model.get("code")) || (!CWSTR.isBlank(model.get("indic_inapt")) && (model.get("indic_inapt") === "C" || model.get("indic_inapt") === "P")) ||
            model.get("code") === " ") {
            return false
          }
          return true;
        },
        "checkPeriode": (model: CWBaseModel, context: { [key: string]: any }): boolean => {
          let lRtn = true;

          //S'il n'y a pas d'information des périodes, il ne devrait rien changer-> Il retournera true 
          if (model && model instanceof Backbone.Model && context && !CWSTR.isBlank(context.ctxPeriodeDebut) && !CWSTR.isBlank(context.ctxPeriodeFin)) {
            if (model.get("datedeb") > context.ctxPeriodeDebut || context.ctxPeriodeFin > model.get("datefin")) {
              lRtn = false;
            }
          }
          return lRtn;
        },
        "multiselect": this.multiselect,
        "checkedColl": this.initSelectedModels,
        "readOnly": this.readOnly,
        "isCheckedCallback": this.isCheckedCallback,
        "hideCheck": this.hideCheck,
        "hieractivitidad": this.workflow.hierarchie,
        "context": this.context, //this.workflow.selecteurContext,
        "view": CWActiviteTreesViewType.STRUCTURESIMPLE,
        "expandedNodes": this.workflow.context.ctxFromSelecteurDactivites ? this.workflow.context.ctxFromSelecteurDactivites : false,
      });
    }
    this.tree.structSimpleFetchNodeOrFirst = false;
    this.tree.model.off("syncCorrectNodeOrFirst");
    this.tree.model.on("syncCorrectNodeOrFirst", (): void => {
      if (CWSTR.isBlank(this.fetchNodeOrFirstNumModels)) {
        this.fetchNodeOrFirstNumModels = 1;
      }
      this.fetchNodeOrFirstNumModels--;
      if (this.fetchNodeOrFirstNumModels <= 0) {
        this.tree.structSimpleFetchNodeOrFirst = true;
        this._selectCorrectNodeOrFirst();
      }
    });
    this.tree.model.off("syncAddCorrectNodeOrFirst");
    this.tree.model.on("syncAddCorrectNodeOrFirst", (): void => {//afin de ne pas faire des pétition en double et pouvoir savoir lorsque l'on peut appeler à "_selectCorrectNodeOrFirst" dans "syncCorrectNodeOrFirst"
      if (CWSTR.isBlank(this.fetchNodeOrFirstNumModels) || this.fetchNodeOrFirstNumModels < 0) {
        this.fetchNodeOrFirstNumModels = 1;
      } else {
        this.fetchNodeOrFirstNumModels++;
      }
    });
    this.tree.setSortFunction(this._sortTree);
    this.tree.setColl(activiteColl);
    this.tree.model.on("nodeSelected", this._setSelection, this);
    this.tree.model.on("updatedTreeNodeCollection", this.checkSelectionableLeafts, this);
    this.model.off("expandPath");
    this.model.on("expandPath", this.tree.expandPath, this.tree);
    this.$el.find(".phx-activite-struc-simpl-tree-tree").html(this.tree.el);
    this.tree.render();
    this.setAffichage(this.listRenderStyle, false);
    if (!CWSTR.isBlank(this.opennode) && this.opennode === true) {
      this.tree.root.opennode = true;
    }
    //expand ficticious node
    if (!CWSTR.isBlank(this.tree.context) && this.tree.context.forcedSelectNodeOrFirst === true) {
      this.tree.structSimpleFetchNodeOrFirst = true;//l'option "trigger" desactivée
      this.tree.expandRoot(() => {
        this._selectCorrectNodeOrFirst();
      });
    } else {
      this.tree.structSimpleFetchNodeOrFirst = false;//l'option "trigger" activée
      this.tree.expandRoot();//la sélection du premier node ou le demandé sera fait dans le success et envoyé un trigger ici (this.tree.model.on("selectCorrectNodeOrFirst"...) 
    }
    if (calback) {
      calback();
    }
  }

  _selectCorrectNodeOrFirst(shouldReload?: boolean): void {
    this.workflow.trigger("selectLastSelectedOrFirst", shouldReload, this._obtainView());
  }

  _obtainCode(model?: CWBaseModel): string {
    let structid = null;

    _.each(model.get("parents"), (parent: { [key: string]: any }) => {
      if (!CWSTR.isBlank(parent.code) || parent.code === " ") {
        structid = parent.code;
      }
    });
    return structid;
  }

  render(callback?: () => void): this {
    const json = { i18n: i18n, label: this.label };
    const helpRacineColl = this.racineColl.clone();
    const domaineDetail = this.workflow.context.ctxDomaineDetail;

    $(this.el).html(this.template(json));
    //remove all the events
    this.undelegateEvents();
    //Restart events
    this.delegateEvents();
    helpRacineColl.domaine = this.params.domaine;
    helpRacineColl.vue = 3;
    if (!CWSTR.isBlank(domaineDetail)) {
      helpRacineColl.domaine = domaineDetail.get("domcode");
      helpRacineColl.setContext(this.workflow.context.ctxFilterData);
      helpRacineColl.setHabContext(this.workflow.getHabContext());
      helpRacineColl.fetch({
        success: (fresh: { [key: string]: any }) => {
          if (fresh.models && fresh.models.length > 0) {
            this.checkSelectionableLeafts(fresh);
            this.racineModel = this.initializeRacineModel(helpRacineColl.models[0]);
            this.racineModel.set("code", "");
            this.racineModel.firstLevelColl = helpRacineColl;
            this.firstLevelModels = [];
            _.each(helpRacineColl.models, (model: { [key: string]: any }) => {
              if (CWSTR.isBlank(model.get("code"))) { //Hors regroupement
                const horsStruct = domaineDetail.get("hiera") === true ? i18n.t('activite.hors_hier_ou_struct') : i18n.t('activite.hors_structure');

                model.set("code", " ");
                model.set("libelle", horsStruct);
              }
              this.firstLevelModels.push(model);
            });
            _.each(this.firstLevelModels, (firstLevel: { [key: string]: any }) => {
              firstLevel.vue = 3;
              firstLevel.niveau = 1;
            });
            this.fetchNodeOrFirstNumModels = fresh.models.length;
            this.workflow.trigger("tree:activitiesNumber", fresh.models.length);
            this._paintTree(callback);
          } else { //No results found initialize empty tree
            this.firstLevelModels = [];
            this.racineModel = this.initializeRacineModel(helpRacineColl.models[0]);
            this.racineModel.set("code", "");
            this.racineModel.firstLevelColl = helpRacineColl;
            if (CWSTR.isBlank(this.model.get("value"))) { //If we have already set value to null, call the events related to the change value
              this.workflow.trigger("change:value");
            } else {
              this.model.set("value", null);
            }
            this.fetchNodeOrFirstNumModels = 0;
            this.workflow.trigger("tree:activitiesNumber", 0);
            this._paintTree(callback);
          }
        }
      });
    }
    //Build the menu
    if (!this.hideMenuOptions) {
      const menuContent = $(".phx-activite-struc-simpl-tree-menucontent", this.el);
      menuContent.menu();
      menuContent.hide();
    } else {
      $(this.el).find(".phx-activite-struc-simpl-tree-dlgresultheader").hide();
      $(this.el).find(".phx-activite-struc-simpl-tree-menucontent").hide();
    }
    return this;
  }

  /**
   * When an affichage option has been selected
   */
  setAffichage(style: number, repaintTree?: boolean): void {
    $(".phx-activite-struc-simpl-tree-opt1", this.el).removeClass("ui-state-active");
    $(".phx-activite-struc-simpl-tree-opt2", this.el).removeClass("ui-state-active");
    $(".phx-activite-struc-simpl-tree-opt3", this.el).removeClass("ui-state-active");
    $(".phx-activite-struc-simpl-tree-opt4", this.el).removeClass("ui-state-active");
    switch (style) {
      case 1:
        this.listRenderStyle = 1;
        $(".phx-activite-struc-simpl-tree-opt1", this.el).addClass("ui-state-active");
        break;
      case 2:
        this.listRenderStyle = 2;
        $(".phx-activite-struc-simpl-tree-opt2", this.el).addClass("ui-state-active");
        break;
      case 3:
        this.listRenderStyle = 3;
        $(".phx-activite-struc-simpl-tree-opt3", this.el).addClass("ui-state-active");
        break;
      case 4:
        this.listRenderStyle = 4;
        $(".phx-activite-struc-simpl-tree-opt4", this.el).addClass("ui-state-active");
        break;
      default:
      //Nothing
    }
    if (this.tree) {
      this.tree.setRenderer(this._getRenderer());
      if (repaintTree !== false) {
        this.tree.root.repaintLabel(this._getRenderer());
      }
      this.trigger("rendererChanged");
    }
  }

  /**
   * In order to implement the button to select the way to render the tree
   */
  _changeRenderer(event: { [key: string]: any }): boolean {
    switch (event.target.className.split(" ")[0]) {
      case "phx-activite-struc-simpl-tree-opt1":
        this.setAffichage(1);
        break;
      case "phx-activite-struc-simpl-tree-opt2":
        this.setAffichage(2);
        break;
      case "phx-activite-struc-simpl-tree-opt3":
        this.setAffichage(3);
        break;
      case "phx-activite-struc-simpl-tree-opt4":
        this.setAffichage(4);
        break;
      default:
      //Nothing
    }
    return false;
  }

  //Method to close and open the menu.
  _toggleMenu(): void {
    const menuContent = $(".phx-activite-struc-simpl-tree-menucontent", this.el);

    if (menuContent.is(":visible")) {
      menuContent.hide();
    } else {
      const menuBtn = $(".phx-activite-struc-simpl-tree-menuicon", this.el);

      menuContent.show().position({
        my: "right top",
        at: "right bottom",
        of: menuBtn
      });
      //If you click out of the menu, close the menu.
      $(document).one("mousedown", (event: { [key: string]: any }) => {
        const element = $(this.el).find(event.target);

        if (element.length === 0) {
          this._toggleMenu();
        }
      });
      //If you click in one option, the menu is closed
      $(document).one("mouseup", (event: { [key: string]: any }) => {
        const element = $(this.el).find(event.target);

        if (element.length > 0 && event.target.className.split(" ")[0] !== "phx-activite-struc-simpl-tree-menuicon") {
          this._toggleMenu();
        }
      });
    }
  }

  getCheckedRows(): { [key: string]: any } {
    let lRtn = null;

    if (this.tree && this.tree.getCheckedRows) {
      lRtn = this.tree.getCheckedRows();
    }
    return lRtn;
  }

  setCheckedRows(selectedModels: { [key: string]: any }): void {
    this.initSelectedModels = selectedModels;
    if (!CWSTR.isBlank(this.tree)) {
      this.tree.setCheckedRows(selectedModels);
    }
  }

  clearCheckedRows(): void {
    if (!CWSTR.isBlank(this.tree)) {
      this.tree.clearCheckedRows();
    }
  }
}
