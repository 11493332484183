import { CaracPrincStructModel } from './caracPrincStruct.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

/**
 * Collection for loading the table hierarchies
 */
export var CaracPrincStructColl = PaginatedCollection.extend({

  model: CaracPrincStructModel,

  url: function() {
    var url = Configuration.restRoot + "/rest/activite/definition/" + encodeURIComponent(this.code) + "/structa";
    return url;
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  clone: function() {
    var temp = PaginatedCollection.prototype.clone.call(this);
    temp.code = this.code;
    return temp;
  }
});
