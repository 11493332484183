module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 groups.forEach ( function(grp) { 
__p+='\n';
 if(scope){
__p+=' '+
((__t=(scope))==null?'':__t)+
' ';
 } 
__p+='.phx-dynamicStyle-cell-'+
((__t=(type))==null?'':__t)+
'_'+
((__t=(UTILS.escapeJQueryString(grp.get("cod"))))==null?'':__t)+
' {\ncolor : '+
((__t=(grp.get("color")))==null?'':__t)+
' !important;\nbackground : '+
((__t=(grp.get("bgcolor")))==null?'':__t)+
' !important;\nfont-weight: bold;\nfill: '+
((__t=(grp.get("color")))==null?'':__t)+
' !important;\n}\n';
 }); 
__p+='';
}
return __p;
};
