import _ from 'underscore';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWComplementComboModel } from './cwComplementCombo.model';
import { CWSTR } from 'utils/cwStr';


/**
 * Collection for ComplementComboColl
 */
export class CWComplementComboColl<TModel extends CWComplementComboModel = CWComplementComboModel> extends CWCombosColl<TModel> {
  /**
   * Add this to have Outline
   *
   */

  public codif: string | number;
  public typeInfocomp: string;//type de donnée : N->numérique, C->char

  constructor(models?: CWComplementComboModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWComplementComboModel;
    super(models, options);
    this.codif = options.codif;
    this.paginated = false;
    this.typeInfocomp = options.typeInfocomp;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/tablebase/" + encodeURIComponent(this.codif) + "/liste";
    }
  }

  public clone(): CWComplementComboColl | any {
    const temp = super.clone.call(this);

    temp.codif = this.codif;
    temp.typeInfocomp = this.typeInfocomp;
    return temp;
  }

  parse(response: { [key: string]: any }[]): { [key: string]: any; }[] {
    ///vérification si la valeur de type est rempli et il y a de différences entre le code (texte ou char) et la valeur de l'élément complementaire(numérique)
    if (!CWSTR.isBlank(this.typeInfocomp) && response) {
      for (let i = 0; i < response.length; i++) {
        if (this.typeInfocomp === "N" && !CWSTR.isBlank(response[i].code) && typeof response[i].code === "string") {
          const valNum = parseInt(response[i].code, 10);

          if (!_.isNaN(valNum)) {
            response[i].code = String(valNum);//avec cette action, on a supprimé d'information qui peut produire des problèmes, par exemple "01"-> "1"
          }
        }
      }
    }
    return response;
  }
}