import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model to obtain EvtRecapActSoumisWkf
 */
export class CWEvtRecapActSoumisWkf extends CWBaseModel {

  /**
   * Add this to have Outline
   *
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/assocircuitevt/type?code=ACTRECAP";
    };
  }
}
