import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';


// WS added for EVO 518
// DELETE - SFD advanced criteres 2.3

export class CWSauveGarderPreferenceModelDelete extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.habilitationV = options.habilitationV ? options.habilitationV : "RES_PLMED";
    options.habilitationG = options.habilitationG ? options.habilitationG : "RES_PLMED";
    options.usecase = options.usecase ? options.usecase : "planmed";
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/planning/preference/";
    }
  }
}
