import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import TPLListBox from '../list_box.tpl.html';
import { CWLOG } from 'utils/cwLog';
import { i18n } from 'src/i18n';
import { STR } from 'utils/str';


export interface ListBoxViewOptions extends Backbone.ViewOptions<Backbone.Model> {
  enum?: Array<{ code: string | number; libelle: string; codeFormat: string }>;
  nbLine?: number;
  multiValue?: boolean;
  maxSize?: number;
  minSize?: number;
  size?: number;
  search?: boolean;
  name?: string;
  width?: string;
  default?: Array<string>;
}

/**
 * ListBoxView is an a component to draw an HTML select multioption with some changes to use in our system.
 *   ------------------------------------------------
 *
 * You can listen this object to get the next events:
 * @listens selectionChanged(Array<(newValues: (string | number)>): triger when selection changes. 
 * IMPORTANT! Is neccesary listen selectionChanged to detect when the user unselect all options!!
 */
export class ListBoxView extends Backbone.View {

  /**
   * Number of Display ligne
   */
  size: number;
  /**
   * Number max of display line
   */
  maxSize: number;
  /**
   * Number min of display line
   */
  minSize: number;
  width: string;
  searchTerm: string;
  /**
   * Display search input on the top of <div>
   */
  search: boolean;
  enum: Array<{ code: string | number; libelle: string; codeFormat: string }>;
  isSelectAll: boolean;
  multiValue: boolean;
  nbLine: number;
  name: string;
  default?: Array<string>;
  height: number;
  isActivSearch: boolean;
  first: boolean;
  private _selectedValues: Array<string | number>;


  constructor(options: ListBoxViewOptions) {
    options = options || {};
    options.events = _.extend({
      'click .ui-icon-search': '_displaySearch',
      'click .ui-icon-plus': '_addLine',
      'click .ui-icon-minus': '_removeLine',
      'keyup .list-box-search': '_startSearch',
      'change input': '_changeInput',
      'click option': '_selectOption',
      'click .phx-list-box.is-select-all': '_disabledListener'
    }, options.events);
    super(options);
    this.template = TPLListBox;
    this.size = 5;
    this.maxSize = 6;
    this.minSize = 3;
    this.width = "250px";
    this.searchTerm = "";
    this.search = false;
    this.model = null;
    this.enum = null;
    // Must be a ListBoxModel
    if (options.model) {
      this.model = options.model;
    } else if (options.enum) {
      this.enum = options.enum;
      this.nbLine = this.enum.length;
    } else {
      throw new Error(('ListBox must be insitialise with model or enum'));
    }
    this.isSelectAll = false;
    this._selectedValues = [];
    this.id = options.id;
    this.multiValue = options.multiValue;
    this.maxSize = options.maxSize;
    this.minSize = options.minSize;
    this.size = options.size;
    this.search = options.search;
    this.name = options.name;
    this.width = options.width;
    if (options.default) {
      this.default = options.default;
      if (this.default.length > 0 && this.default[0].toUpperCase() === "tout".toUpperCase()) {
        this.isSelectAll = true;
      }
    }
    if (this.nbLine < this.maxSize) {
      this.maxSize = this.nbLine;
    }
    this.isActivSearch = false;
    this.first = true;
    this.searchTerm = "";
  }

  set selectedValues(values) {
    this._selectedValues = values;
    this.trigger("selectionChanged", this._selectedValues);
  }

  get selectedValues(): Array<string | number> {
    return this._selectedValues;
  }

  private paintSelectionList(selectDefaults?: boolean): void {
    const fields = this.getFields(selectDefaults === true || selectDefaults === undefined ? true : false);
    const newSelectList = $("<select>").addClass("cwListBoxSelect " + (this.name ? this.name : "")).attr({ multiple: "multiple" });

    this.$el.find(".cwListBoxSelectContainer").empty().append(newSelectList);
    if (fields) {
      fields.forEach(element => {
        newSelectList.append(element);
      });
    } else {
      CWLOG.warn("The list box was empty");
    }
  }

  public render(): ListBoxView {
    const json = { 'i18n': i18n, name: this.name ? this.name : "" };

    $(this.el).html(this.template(json));
    this.paintSelectionList();
    if (!this.search) {
      $(this.el).find('#list-box-search').hide();
      $(this.el).find('.input-search').hide();
    }
    if (this.size <= this.minSize) {
      $(this.el).find('.ui-icon-minus').hide();
    }
    if (this.size >= this.maxSize) {
      $(this.el).find('.ui-icon-plus').hide();
    }
    if (this.isSelectAll) {
      $(this.el).find('.select-all').attr('checked', 'checked');
      $(this.el).find('.phx-list-box').addClass('is-select-all');
    }
    return this;
  }

  getFields(selectDefaults?: boolean): Array<JQuery> {
    const options: Array<JQuery> = [];

    if (!STR.isNull(this.enum)) {
      for (let index = 0; index < this.enum.length; index++) {
        const checked = (!STR.isUndefined(this.default) && !STR.isNull(this.default) && this.default.indexOf(String(this.enum[index].code)) !== -1);
        const optionElement = $("<option>", {
          value: this.enum[index].code,
          text: this.enum[index].libelle
        });

        if (checked && selectDefaults) {
          optionElement.attr("selected", "true");
        }
        options.push(optionElement);
      }
      return options;
    }
    return null;
  }

  _changeInput(event: JQuery.TriggeredEvent): void {
    let index = 0;
    const target = $(event.target);
    const parent = target.parent();

    if (target.hasClass('select-all')) {      //Remove all previus selection to avoid problem when user used the search
      let listOptions: JQuery = this.$el.find('.cwListBoxSelect').find("option");

      // I didn't find a way to remove the selection and if the user used crtl or sift 
      // reselect all so i need to recreate the combo
      // remove and paint the combo again
      this.paintSelectionList(false);
      // check search
      this._doSerach();
      if (target.prop('checked')) {
        let values: Array<string | number> = null;

        listOptions = this.$el.find('.cwListBoxSelect option:visible');
        for (index = 0; index < listOptions.length; index++) {
          listOptions[index].setAttribute("selected", "selected");
        }
        this.manageUISelectAll(true);
        values = this.getValueToArray(true);
        this.selectedValues = values;
      } else {
        this.manageUISelectAll(false);
        this.selectedValues = [];
      }
    } else {
      if (target.prop('checked')) {
        parent.addClass('select');
        target.attr('data-select', 'select');
      } else {
        parent.removeClass('select');
        target.removeAttr('data-select');
      }
      if (target.hasClass("list-box-search")) {
        this._startSearch(event);
      }
    }
  }

  private manageUISelectAll(isSelectedAll: boolean): void {
    if (isSelectedAll) {
      this.$el.find('.phx-list-box').addClass('is-select-all');
      this.isSelectAll = true;
    }
    else {
      this.$el.find('.phx-list-box').removeClass('is-select-all');
      this.isSelectAll = false;
    }
  }

  private _displaySearch(event: JQuery.TriggeredEvent): void {
    const target = $(event.target);
    const fieldSearch = this.$el.find('input.list-box-search');
    const state = target.attr('data-state') === "true";

    if (state) {
      fieldSearch.hide();
      target.attr('data-state', 'false');
      this.isActivSearch = false;
      this.first = true;
      if (this.size > this.minSize) {
        this.size--;
      }
    } else {
      fieldSearch.show();
      target.attr('data-state', 'true');
      this.isActivSearch = true;
      this.first = true;
      this.size++;
    }
  }

  private _startSearch(event: JQuery.TriggeredEvent): void {
    const target = $(event.target);

    if (this.isSelectAll) {
      this.$el.find(".select-all").click();
    }
    this._doSerach(target.val() as string);
  }

  private _doSerach(searchTerm?: string): void {
    let search: RegExp = null;
    const listLi = this.$el.find(".cwListBoxSelect").find("option");

    this.searchTerm = searchTerm !== undefined && searchTerm !== null ? searchTerm : this.searchTerm;
    search = new RegExp(this.searchTerm, 'gi');
    if (this.searchTerm === "") {
      listLi.show();
    } else {
      for (let index = 0; index < listLi.length; index++) {
        const option = $(listLi[index]);

        if (option.text().trim().match(search)) {
          option.show();
        } else {
          option.hide();
        }
      }
    }
  }

  public getValueToArray(all?: boolean): Array<string | number> {
    let listValues = null;
    const val: Array<string | number> = [];

    if (!STR.isUndefined(all) && all) {
      listValues = this.$el.find('.cwListBoxSelect').find("option");
    } else {
      listValues = this.$el.find('.cwListBoxSelect').find("option:selected");
    }
    if (listValues.length > 0) {
      for (let index = 0; index < listValues.length; index++) {
        const el = listValues[index];

        val.push(($(el).val() as any));
      }
    }
    return val;
  }

  private _selectOption(event: JQuery.TriggeredEvent): void {
    if ($(event.target).is(":selected") && //Is selected
      _.contains(this.selectedValues, $(event.target).val()) && //was previusly selected
      event.ctrlKey === false &&  //Don't use ctrl key
      event.shiftKey === false && // Don't use shift key
      this.selectedValues.length === 1) {  //And is de only one
      $(event.target).prop('selected', false); //So the user was clicked on selected option may be want deselect it
    }
    this.selectedValues = this.getValueToArray();
    if (this.selectedValues.length === this.enum.length) {
      this.$el.find(".select-all").prop("checked", true);
      this.manageUISelectAll(true);
    } else {
      this.$el.find(".select-all").prop("checked", false);
      this.manageUISelectAll(false);
    }
  }

  private _addLine(event: JQuery.TriggeredEvent): void {
    const target = $(event.target);

    if (this.size < this.maxSize) {
      if (this.isActivSearch && this.first) {
        this.first = false;
      } else {
        this.size++;
      }
      // Show icone minus    
      this.$el.find('.ui-icon-minus').show();
    }
    if (this.size >= this.maxSize) {
      target.hide()
    }
  }

  private _removeLine(event: JQuery.TriggeredEvent): void {
    const target = $(event.target);

    if (this.size > this.minSize) {
      if (!this.isActivSearch && this.first) {
        this.first = false;
      } else {
        this.size--;
      }
      // Show icone minus    
      this.$el.find('.ui-icon-plus').show();
    }
    if (this.size <= this.minSize) {
      target.hide();
    }
  }

  private _disabledListener(event: JQuery.TriggeredEvent): boolean {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  addTitleLabel(): void {
    const listLi = this.$el.find('ul.list-item-box li');

    for (let index = 0; index < listLi.length; index++) {
      const li = listLi[index];

      if (li.children[1].scrollWidth > 250) {
        $(li).find('label').attr('title', $(li).find('label').text().trim());
      }
    }
  }
}
