import _ from 'underscore';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWCombosModel } from 'src/core/components/combo/cwCombos.model';
import { CWDynamicComboModel } from './cwDynamicCombo.model';
import { CWHEADERS } from 'src/utils/cwHeaders';
import { CWSTR } from 'src/utils/cwStr';

export class CWDynamicComboModelColl extends CWCombosColl {

  urlService: any;
  defaut: any;
  filtre: any;
  refForm: any;
  datedeb: string;
  datefin: string;

  constructor(models?: CWDynamicComboModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWDynamicComboModel;
    super(models, options);
    if (options.modifyHeader === true) {
      options = _.extend(options, { headers: { "gfi-ecran": '"RES_PLANNING"' } });
    }
    this.urlService = options.url;
    this.defaut = options.defaut;
    this.filtre = options.filtre;
    this.refForm = options.refForm;
    this.url = (): string => {
      let dates = "";
      let filtre = "";

      // une des dates au moins est renseignée
      if (!CWSTR.isBlank(this.datedeb) || !CWSTR.isBlank(this.datefin)) {
        // test pour savoir s'il y a déjà des paramètres
        if (CWSTR.isBlank(this.defaut)) {
          dates = "?";
        } else {
          dates = "&";
        }
        if (!CWSTR.isBlank(this.datedeb)) {
          // la date de début est renseignée
          dates += "datedeb=" + this.datedeb;
          if (!CWSTR.isBlank(this.datefin)) {
            // la date de fin est renseignée aussi
            dates += "&datefin=" + this.datefin;
          } else {
            //S'il n'y a pas de datefin, on ajoutera la valeur de la datedeb
            dates += "&datefin=" + this.datedeb;
          }
        } else {
          // seule la date de fin est renseignée
          //S'il n'y a pas de datedeb, on ajoutera la valeur de la datefin
          dates += "datedeb=" + this.datefin + "&datefin=" + this.datefin;
        }
      }

      if (this.filtre && !CWSTR.isBlank(dates)) {
        filtre = '&filtre=true'
      }
      return Configuration.restRoot + "/" + this.urlService + (!CWSTR.isBlank(this.defaut) ? '?valeurinit=' + this.defaut : '') +
        (!CWSTR.isBlank(dates) ? dates : '') + filtre;
    };
    this.paginated = true;
  }

  sync(model: CWCombosModel, xhr: any, opt?: { [key: string]: any }): { [key: string]: any } {
    const options = opt || {};

    if (this.filtre === true) {
      options.headers = _.extend(options.headers, CWHEADERS.populationContext(this.refForm.popId, this.refForm.popType));
    }
    return super.sync(model, xhr, opt);
  }
}