import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

export class CWListeBriquesModel extends CWBaseModel {

  typeBrique: string;

  defaults(): { [key: string]: any } {
    return {
      "id": "",
      "emid": "",
      "nom": "",
      "ordre": null,
      "active": false,
      "personnalisation": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }

  getUniqueId(): string {
    let uniqueId = "";

    if (!CWSTR.isBlank(this.get("personnalisation"))) {
      uniqueId = this.get("id") + "," + this.get("emid") + "," + this.get("personnalisation").userid + "," + this.get("personnalisation").codebrique;
    } else {
      uniqueId = this.get("id") + "," + this.get("emid");
    }
    return uniqueId;
  }
}
