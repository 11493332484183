import { GLOBAL_DATA } from 'src/globalData';
import { STR } from 'utils/str.js';

/**
 * Utilities to check habilitations
 */
export var HABILITATION = {

  /**
   * Check if we can view with this habilitation
   */
  canView: function(fonction) {
    try {
      if (fonction === "N") {
        return true;
      } else {
        var right = false;
        var fonctions = HABILITATION.toArray(fonction);

        for (var i = 0, l = fonctions.length; i < l && !right; i++) {
          right = right || !STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i]));
        }
        return right;
      }
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we have all view habilitations
   */
  canViewAll: function(fonction) {
    try {
      var right = true;
      var fonctions = !_.isArray(fonction) ? HABILITATION.toArray(fonction) : fonction;

      for (var i = 0, l = fonctions.length && right; i < l; i++) {
        right = right && !STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i]));
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we can create with this habilitation
   */
  canCreate: function(fonction) {
    try {
      var right = false;
      var fonctions = HABILITATION.toArray(fonction);

      for (var i = 0, l = fonctions.length; i < l && !right; i++) {
        right = right || (!STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && (GLOBAL_DATA.rights.get(fonctions[i]).get("gesta") === "O" || GLOBAL_DATA.rights.get(fonctions[i]).get("fonctgesta") === "N"));
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we can update with this habilitation
   */
  canUpdate: function(fonction) {
    try {
      var right = false;
      var fonctions = HABILITATION.toArray(fonction);

      for (var i = 0, l = fonctions.length; i < l && !right; i++) {
        right = right || (!STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && (GLOBAL_DATA.rights.get(fonctions[i]).get("gestm") === "O" || GLOBAL_DATA.rights.get(fonctions[i]).get("fonctgestm") === "N"));
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we can delete with this habilitation
   */
  canDelete: function(fonction) {
    try {
      var right = false;
      var fonctions = HABILITATION.toArray(fonction);

      for (var i = 0, l = fonctions.length; i < l; i++) {
        right = right || (!STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && (GLOBAL_DATA.rights.get(fonctions[i]).get("gests") === "O" || GLOBAL_DATA.rights.get(fonctions[i]).get("fonctgests") === "N"));
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Transforms a string into a array
   */
  toArray: function(fonction) {
    var liste = fonction.split(",");

    for (let i = 0; i < liste.length; i++) {
      //remove spaces en blanc(gauche et droite)
      liste[i] = liste[i].trim();
    }
    return liste;
  }

};
