import { STR } from 'utils/str.js';

/**
 * Selecteur Activite, model for each activite
 */
export var SelecteurActiviteModeSimpleActiviteModel = Backbone.Model.extend({

  /**
   * Default data model
   *	code : null,
   *	libelle : "",
   *	dated : "",
   *	datef : null,
   *	racine : "",
   *	hierar: "",
   *	stracode:"",
   *	stralib:"",
   *	strapar:""
   * }
   */
  defaults: {
    code: null,
    libelle: "",
    dated: "",
    datef: null,
    racine: "",
    hierid: null,
    hierar: "",
    stracode: "",
    stralib: "",
    strapar: "",
    indic_comp_req: "",
    indic_periode: ""
    //Data Added by parse
    //parentstraid
    //parentdomlib
    //parentfmtact
  },

  /**
   * Process to manage the response, the id will be code,dated,codeStruct,codeStruct2,codeAct1,codeAct2
   */
  parse: function(response) {
    if (response && STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      if (!STR.isBlank(response.parentdomcode)) {
        response.id = response.parentdomcode;
      }
      response.id = response.id + "," + response.code + "," + response.dated + "," + response.hierid + "," + response.straid;
    }
    return response;
  }
});
