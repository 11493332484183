import _ from 'underscore';
import { CWSTR } from 'utils/cwStr';
import { CWBaseModel } from 'src/core/models/cwBase.model';


export class CWAjouterRegulPresenceModel extends CWBaseModel {

  default(): { [key: string]: any }{
    return {
      "matricule": "",
      "datedeb": ""
    }
  }
    constructor(options?: { [key: string]: any }) {
        options = options || {};
        super(options);

        this.url = (): string => {
            return Configuration.restRoot + "/rest/forfaitj/regul/"
        }
    }

    validate(attrs?: { [key: string]: any }): { [key: string]: any } | null {
        const errors: { [key: string]: any } = {};
        
        if(CWSTR.isBlank(attrs.matricule)){
            errors.matricule = "Error on matric";
        }

        if(CWSTR.isBlank(attrs.datedeb)){
            errors.datedeb = "Error on datedeb";
        }

        if (!_.isEmpty(errors)) {
          const errorMap = {
            errorValidation: CWSTR.formatMessage(errors),
            errors: errors
          };
          return errorMap;
        }
        return null;
      }
}