import * as Backbone from 'Backbone';
import TPLGererrecapitulatifsDetail from '../detail.tpl.html';
import { CWACTIVITE } from 'utils/cwActivite';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWGererAbsenceSouhaitView } from 'common/evenements/gerer/gererabsencesouhait/views/cwGererAbsenceSouhait.view';
import { CWGererActiviteClassiqueView } from 'common/evenements/gerer/gereractivites/views/cwGererActiviteClassique.view';
import { CWGererActiviteDeplacementView } from 'common/evenements/gerer/gereractivites/views/cwGererActiviteDeplacement.view';
import { CWGererRecapGeneralView } from './cwGererRecapGeneral.view';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';


export class CWGererRecapitulatifsFormView extends Backbone.View {
  gererAbsences: CWGererAbsenceSouhaitView;
  gererAbsencesContext: any;
  gererActivites: CWGererActiviteClassiqueView | CWGererActiviteDeplacementView;
  gererActiviteContext: any;
  workflow: any;
  parent: CWGererRecapGeneralView;

  constructor(options?: { [key: string]: any }) {
    super(options);

    this.template = TPLGererrecapitulatifsDetail;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.model = new CWBaseModel({
      value: null
    });
    this.model.on("change:value", this._manageEvenement, this);
    this.workflow.on("initierSaisieActMonoType", this._manageInitierSaisieActivites, this);
    this.gererActivites = null;
    this.gererActiviteContext = null;
    this.gererAbsences = null; //il sera initialisé après
    this.gererAbsencesContext = null;
  }

  render(): CWGererRecapitulatifsFormView {
    const json = { "i18n": i18n };
    this.$el.append(this.template(json));
    return this;
  }

  _manageEvenement(): void {
    const value = this.model.get("value");

    if (CWSTR.isBlank(value)) {
      //Hide view
      this.$el.hide();
      this.parent.$el.find("#activitecollab_btnbar").hide();

    } else if (value.type === this.workflow.ABSENCE) {
      this._manageGererAbsences();
    } else if (value.type === this.workflow.ACTIVITE) {
      this._manageGererActivites();
    }
  }

  _manageGererAbsences(): void {
    const value = this.model.get("value");

    if (CWSTR.isBlank(value)) {
      //Hide view
      this.$el.hide();
    } else { //show context
      this.$el.show();
      const absence = value.get("prevueColumn");

      this.gererAbsencesContext = this.workflow._consulterAbsencesContext(absence);
      if (CWSTR.isBlank(this.gererAbsences)) {
        const lParent = (this.gererAbsencesContext && this.gererAbsencesContext.ctxModeRepresentation === "Imbrique") ? this.parent : null;

        this.gererAbsences = new CWGererAbsenceSouhaitView({ "context": this.gererAbsencesContext, "parent": lParent });
        this.$el.find(".absences-form").html(this.gererAbsences.render().el);
      } else {
        this.gererAbsences.setContext(this.gererAbsencesContext);
      }
      this.$el.find(".activites-form").hide();
      this.parent.$el.find("#activitecollab_btnbar").hide();
      this.$el.find(".absences-form").show();
    }
  }

  _manageGererActivites(): void {
    const value = this.model.get("value");

    if (CWSTR.isBlank(value)) {
      //Hide view
      this.$el.hide();
    } else { //show context
      this.$el.show();
      const activite = value.get("prevueColumn");

      if (this.workflow._actCanBeConsulted(activite.get("typeevenement")) === true) {
        this.gererActiviteContext = this.workflow._consultGererActivitesContext(activite);
        this.gererActivites = CWACTIVITE.initialiseActiviteViewByContext(this.gererActiviteContext, { context: this.gererActiviteContext });
        this.stopListening(this.gererActivites.model, "activiteChanged");
        this.listenTo(this.gererActivites.model, "activiteChanged", (model, action) => {
          this._activiteChanged(model, action);
        });
        this.stopListening(this.gererActivites.model, "activiteReverted");
        this.listenTo(this.gererActivites.model, "activiteReverted", (model, initializationMode) => {
          this._activiteReverted(model, initializationMode);
        });
        this.$el.find(".gererActivites .activites-form").html(this.gererActivites.render().el);
        this.$el.find(".activites-form").show();
        this.parent.$el.find("#activitecollab_btnbar").show();
        this.$el.find(".absences-form").hide();
      } else {
        //Hide view
        this.$el.hide();
      }
    }
  }

  _manageInitierSaisieActivites(typologie: any, datedeb: any, datefin: any): void {
    const typeevenement = this.workflow.modeRepresentationModel.get("typeevenement");

    this.$el.show();
    this.gererActiviteContext = this.workflow._instantierSaisieActiviteContext(typeevenement, typologie, datedeb, datefin);
    this.gererActivites = CWACTIVITE.initialiseActiviteViewByContext(this.gererActiviteContext, { "context": this.gererActiviteContext, "parent": this.parent });
    this.stopListening(this.gererActivites.model, "activiteChanged");
    this.listenTo(this.gererActivites.model, "activiteChanged", (model, action) => {
      this._activiteChanged(model, action);
      this.parent.$el.find("#activitecollab_btnbar").hide();
    });
    this.stopListening(this.gererActivites.model, "activiteReverted");
    this.listenTo(this.gererActivites.model, "activiteReverted", (model, initializationMode) => {
      this._activiteReverted(model, initializationMode);
      this.parent.$el.find("#activitecollab_btnbar").hide();
    });
    this.$el.find(".gererActivites .activites-form").html(this.gererActivites.render().el);
    this.$el.find(".activites-form").show();
    this.$el.find(".absences-form").hide();
  }

  _activiteChanged(model: any, action: any): void {
    this.workflow._activiteChanged(model, action);
  }

  _activiteReverted(model: any, initializationMode: any): void {
    const isRecap = (model && model.get("recapitulatif") && !CWSTR.isBlank(model.get("recapitulatif").code)) ? true : false;
    const originalInitialMode = (isRecap && CWSTR.isBlank(model.get("code"))) ? "Ajout" : "Consultation";

    this.workflow._activiteReverted(model, initializationMode);
    if (initializationMode !== "Consultation" || (isRecap && this.gererActiviteContext.ctxModeRepresentation === "Imbrique" && originalInitialMode === "Ajout")) {
      this.$el.find(".activites-form").hide();
    }
    this.$el.find(".absences-form").hide();
  }
}
