module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'c-etiquette__texts__container flex-column  flex-fill\'>\n    <span class="c-etiquette__text" data-value="'+
((__t=(values))==null?'':__t)+
'">'+
((__t=(text))==null?'':__t)+
'</span>\n    <span class=\'c-etiquette__subText cw-texteAllege\'>'+
((__t=(subText))==null?'':__t)+
'</span>\n</div>\n<div class=\'c-etiquette__withIcon_icon cw-triggerClick\' role="button" aria-label="'+
((__t=(i18n.t('common:ARIA_label.historique')))==null?'':__t)+
'" tabindex=" 0">\n    <!-- icon was here -->\n</div>';
}
return __p;
};
