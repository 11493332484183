import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Calculer état final d'un récapitulatif
 */
export class CWEtatFinalRecapActModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "valideur": false,
      "valideurfinal": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/etatfinal/" + encodeURIComponent(this.id);
    }
  }
}