import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

export class CWPersoBriquesModel extends CWBaseModel {

  espaceid: string;
  zone: string;

  defaults(): { [key: string]: any } {
    return {
      "briques": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      let url = Configuration.restRoot + "/rest/personnalisation/confignav/accueil/"; //Creation

      if (!CWSTR.isBlank(this.espaceid)) {
        url += "?espaceid=" + encodeURIComponent(this.espaceid);
      }
      return url;
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (!CWSTR.isBlank(response)) {
      let lEmId = "";
      const lFind = _.find(response.briques, (model: { [key: string]: any }) => {
        if (CWSTR.isBlank(lEmId) && model.id === "bresultats") {
          lEmId = model.emid;
        }
        return (model.id === "bresultats" && model.personnalisation && CWSTR.isBlank(model.personnalisation.codebrique));
      });

      if (CWSTR.isBlank(lFind)) {
        const lBresult = {
          emid: lEmId,
          id: "bresultats",
          nom: "Résultats",
          ordre: null as any,
          active: false,
          personnalisation: {
            codebrique: null as any,
            typebrique: (this.zone === "resp") ? "CPTR" : "CPTC",
            userid: null as any,
            visibilite: null as any
          }
        };

        response.briques.push(lBresult);
      }
    }
    return response;
  }
}
