module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="gereractivites-popup cw-fieldset">\n  <div class="phx-formTable">\n    <div class="form-group phx-formErrors ui-state-error"></div>\n    <div>\n      <div class="form-group">\n          <span class="spanComment"></span>\n      </div>\n      <div class="form-group">\n         <label for="commentaire" title="'+
((__t=(i18n.t("common:gereractivites.comment")))==null?'':__t)+
'">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t("common:gereractivites.comment")))==null?'':__t)+
'</label>\n          <textarea class="commentaire form-control" maxlength="60" cols="30" rows="3"></textarea>\n      </div>\n      <div class="cwDialog-buttons cw-buttonBar">\n        <div class="btnBar-btn-container">\n          <button type="button" class="btnOK btn btn-primary btn-withIcon" value="btnOK">'+
((__t=(i18n.t('common:gereractivites.btnOK')))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('valider','',16)))==null?'':__t)+
'</button>\n        </div>\n        <div class="btnBar-btn-container">\n          <button type="button" class="btnAnnuler btn btn-secondary btn-withIcon" value="btnAnnuler">'+
((__t=(i18n.t('common:gereractivites.btnAnnuler')))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('croix','',16)))==null?'':__t)+
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</fieldset>\n';
}
return __p;
};
