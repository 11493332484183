import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

export class CWTableModel extends CWBaseModel {
  branch: any;
  collapsible: boolean;
  currentDate: any;
  dayInfoArray: any;
  evDSuppr: any;
  evPSuppr: any;
  hasChild: boolean;
  isExpanded: boolean;
  level: number;
  monthInfoArray: any;
  summeDuresAct: any;
  type: string;
  weekInfoArray: any;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  defaults(): { [key: string]: any } {
    return {
      "prevueColumn": null,
      "realiseeColumn": null
    }
  }
}
