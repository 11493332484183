import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWGererPretPopUpView } from '../views/cwGererPretPopUp.view';
import { CWGererPretView } from '../views/cwGererPret.view';
import { CWGererPretWorflowActifModel } from './cwGererPretWorflowActif.model';
import { CWGererPretWorkflowActifColl } from './cwGererPretWorkflowActif.collection';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPiecesAutorisees } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesAutorisees';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

export class CWGererpretsWorkflow extends CWReadOnlyModel {

  gererPrets: CWGererPretView;
  btnBarModel: CWBaseModel;
  pDiversIdCollabModel: any;
  maxPieces: CWPiecesAutorisees;
  formModel: any;
  public module: string;
  public $appendTo: JQuery; //pour les messages
  public wkfPretActiv: boolean;
  private activesWorkflows: CWGererPretWorkflowActifColl;
  public paramPJointe: boolean;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.context = options.context;
    this.module = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : null;
    this.gererPrets = null;
    this.btnBarModel = null;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
    this.paramPJointe = (CWDIVERS.get("pJointe") === "1");
    this.context.ctxPieceJointe = this.paramPJointe;
  }

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp(callback?: () => void): void {
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    if (this.paramPJointe) {
      this.maxPieces = new CWPiecesAutorisees({ id: "PJPRT" });
      if (!CWSTR.isBlank(this.formModel) && !CWSTR.isBlank(this.formModel.getHabContext())) {
        this.maxPieces.setHabContext(this.formModel.getHabContext());
      }
    }
    this._fetchWorkflowOpt(callback);
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.gererPrets.model.on("form:edited", this._formEdited, this);
  }

  _buttonAction(buttonId: string, profil: any): void {
    const habilitationGestion = this.gererPrets._getHabilitationGestion(
      this.formModel.get("value")
    );
    let statut = "";
    let dialog: CWDialogPopupView = null;
    let dialogModel: { [key: string]: any } = null;
    let validationProfil: any = null;

    this.formModel.updateHabContext({ foncCour: habilitationGestion });
    switch (buttonId) {
      case "save":
        this.gererPrets.savePretCollab();
        break;
      case "revert":
        this.gererPrets.revertView();
        break;
      case "new":
        this.gererPrets.newPretCollab();
        break;
      case "delete":
        statut = CWSTR.getElValue(
          this.gererPrets.model.get("value"),
          "statut.code"
        );
        if (statut === "A" || statut === "H" || (statut === "I" && this.gererPrets.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
          const dialog = this._initPopUp("suppression");
          const dialogModel = dialog.model;

          dialog.open((): void => {
            if (dialogModel.get("action") === "OK") {
              this.gererPrets.deletePretCollab(dialogModel.get("commentaire"));
            }
          });
          dialog._setTitle(i18n.t("common:gererprets.title_popUp").toUpperCase());
        } else {
          CWMSGS.showConfirmAdapter(i18n.t("common:gererprets.delconfirm"), (result: string): void => {
            if (result === "Y") {
              this.gererPrets.deletePretCollab("");
            }
          }, null, null, null, this.$appendTo);
        }
        break;
      case "accepter":
        dialog = this._initPopUp("accepter");
        dialogModel = dialog.model;
        validationProfil = profil;
        dialog.open((): void => {
          if (dialogModel.get("action") === "OK") {
            this.gererPrets.accepterPretCollab(
              dialogModel.get("commentaire"),
              validationProfil
            );
          }
        });
        dialog._setTitle(i18n.t("common:gererprets.title_popUp").toUpperCase());
        break;
      case "refuser":
        dialog = this._initPopUp("refuser");
        dialogModel = dialog.model;
        validationProfil = profil;
        dialog.open((): void => {
          if (dialogModel.get("action") === "OK") {
            this.gererPrets.refuserPretCollab(dialogModel.get("commentaire"));
          }
        });
        dialog._setTitle(i18n.t("common:gererprets.title_popUp").toUpperCase());
    }
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("hide:valider");
    if (this.formModel.getHabContext().get("natGest") !== "A") {
      const habilitationGestion = this.gererPrets._getHabilitationGestion(
        this.formModel.get("value")
      );

      this.formModel.updateHabContext({
        foncCour: habilitationGestion,
        natGest: "M",
      });
    }
  }

  _initPopUp(act: string): CWDialogPopupView {
    const stat = this.gererPrets.model.get("value").get("statut").code;
    const ctxUtilisateur = this.gererPrets.context.ctxUtilisateur;
    const dialog = new CWDialogPopupView({
      view: CWGererPretPopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur,
        appendTo: this.$appendTo
      }
    });

    dialog.setHeight("auto");
    dialog.setWidth(470);
    return dialog;
  }

  _fetchWorkflowOpt(callback: () => void): void {
    this.activesWorkflows = new CWGererPretWorkflowActifColl();
    this.activesWorkflows.setHabContext(this.formModel.getHabContext().copy());
    this.activesWorkflows.fetch({
      success: (fresh: CWGererPretWorkflowActifColl): void => {
        const pretWkf = fresh.find((model: CWGererPretWorflowActifModel): boolean => {
          return model.get("code") === "PRET";
        });

        // get options of active workflow circuits.
        this.wkfPretActiv = pretWkf ? pretWkf.get("active") : false;
        if (callback) {
          callback();
        }
      }
    });
  }
}