import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWActivitesModel } from '../models/cwActivites.collection';
import { CWDemandesCollaborateursModel } from '../models/cwDemandesCollaborateurs.model';
import { CWEvenementsGeneriqueModel } from '../models/cwEvenementsGenerique.collection';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from 'utils/cwStr';
import { forkJoin } from 'rxjs';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { NAV } from 'utils/nav.js';
import { UTILS } from 'utils/utils.js';
import '../briqueDemandesCollaborateurs.tpl.html';



const TPLBdemcollBriqueDemandesCollaborateurs = require('../briqueDemandesCollaborateurs.tpl.html');

/**
 * View of Brique 'Demandes Collaborateurs'
 */
export class CWDemandesCollaborateursView extends Backbone.View<Backbone.Model> {

  usePopulation: boolean;
  parent: any;
  habContextAbsence: CWHabilitationContext;
  modelAbsence: CWDemandesCollaborateursModel;
  habContextSouhaitAbs: CWHabilitationContext;
  modelSouhaitAbs: CWDemandesCollaborateursModel;
  habContextRegularisation: CWHabilitationContext;
  modelRegularisation: CWDemandesCollaborateursModel;
  habContextBadgeage: CWHabilitationContext;
  modelBadgeage: CWDemandesCollaborateursModel;
  habContextRecapitulatif: CWHabilitationContext;
  modelRecapitulatif: CWDemandesCollaborateursModel;
  habContextPretsValider: CWHabilitationContext;
  modelPretsValider: CWDemandesCollaborateursModel;
  habContextEvtgen: CWHabilitationContext;
  modelEvtgen: any;
  typologiesActivites: CWHabilitationContext;
  modelTypologiesActivites: any;
  parametreDiversSouhabsModel: any;
  paves: any;
  totalAValider: number;
  absenceNbr: number;
  badgeageNbr: number;
  regularisationNbr: number;
  evenGenerNbr: number;
  activitesNbr: number;
  recapitulatifNbr: number;
  pretEtEmpruntNbr: number;


  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .cw-pave__lien": "_linkToValidation"
    }, options.events);
    super(options);
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPLBdemcollBriqueDemandesCollaborateurs;
    this.parent = null;

    this.habContextAbsence = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: "RES_VAL_ABS.G",
      natGest: ""
    });
    this.modelAbsence = new CWDemandesCollaborateursModel();
    this.modelAbsence.usePopulation = this.usePopulation;
    this.modelAbsence.typeevenement = "ABSENCE";
    this.modelAbsence.setHabContext(this.habContextAbsence);

    this.habContextSouhaitAbs = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: "RES_VAL_SHT.G",
      natGest: ""
    });
    this.modelSouhaitAbs = new CWDemandesCollaborateursModel();
    this.modelSouhaitAbs.usePopulation = this.usePopulation;
    this.modelSouhaitAbs.typeevenement = "SOUHAITABS";
    this.modelSouhaitAbs.setHabContext(this.habContextSouhaitAbs);

    this.habContextRegularisation = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: "RES_VAL_REG.G",
      natGest: ""
    });
    this.modelRegularisation = new CWDemandesCollaborateursModel();
    this.modelRegularisation.usePopulation = this.usePopulation;
    this.modelRegularisation.typeevenement = "REGULARISATION";
    this.modelRegularisation.setHabContext(this.habContextRegularisation);

    this.habContextBadgeage = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: "RES_VAL_BDG.G",
      natGest: ""
    });
    this.modelBadgeage = new CWDemandesCollaborateursModel();
    this.modelBadgeage.usePopulation = this.usePopulation;
    this.modelBadgeage.typeevenement = "BADGEAGE";
    this.modelBadgeage.setHabContext(this.habContextBadgeage);

    this.habContextRecapitulatif = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: "RES_VAL_RECAP.G",
      natGest: ""
    });
    this.modelRecapitulatif = new CWDemandesCollaborateursModel();
    this.modelRecapitulatif.usePopulation = this.usePopulation;
    this.modelRecapitulatif.typeevenement = "ACTRECAP";
    this.modelRecapitulatif.setHabContext(this.habContextRecapitulatif);

    this.habContextPretsValider = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: "RES_VAL_PRET.G",
      natGest: ""
    });
    this.modelPretsValider = new CWDemandesCollaborateursModel();
    this.modelPretsValider.usePopulation = this.usePopulation;
    this.modelPretsValider.typeevenement = "PRET";
    this.modelPretsValider.setHabContext(this.habContextPretsValider);

    this.habContextEvtgen = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: "RES_VAL_EGEN.G",
      natGest: ""
    });
    this.modelEvtgen = new CWEvenementsGeneriqueModel();
    this.modelEvtgen.usePopulation = this.usePopulation;
    this.modelEvtgen.typeevenement = "EVTGEN";
    this.modelEvtgen.setHabContext(this.habContextEvtgen);

    const activiteHabilitationObj: { [key: string]: any } = {};
    if (CWHABILITATION.canView("RES_VAL_ACTPR.G")) {
      activiteHabilitationObj.actprev = "RES_VAL_ACTPR.G";
    }
    if (CWHABILITATION.canView("RES_VAL_ACTRE.G")) {
      activiteHabilitationObj.actreal = "RES_VAL_ACTRE.G";
    }
    this.typologiesActivites = new CWHabilitationContext({
      onglet: "bdemcoll",
      foncCour: activiteHabilitationObj,
      natGest: ""
    });
    this.modelTypologiesActivites = new CWActivitesModel();
    this.modelTypologiesActivites.usePopulation = this.usePopulation;
    this.modelTypologiesActivites.setHabContext(this.typologiesActivites);

    if (options && options.parent) {
      this.parent = options.parent;
    }

    // retrieve parametre divers for souhait  (SouhAbs)
    this.parametreDiversSouhabsModel = GLOBAL_DATA.paramDivers.get("SouhAbs");
  }

  render(): CWDemandesCollaborateursView {
    const json = { i18n: i18n };
    $(this.el).html(this.template(json));
    return this;
  }

  checkHabilation(): boolean {
    return CWHABILITATION.canView("RES_VAL_ABS.G")
      || CWHABILITATION.canView("RES_VAL_SHT.G")
      || CWHABILITATION.canView("RES_VAL_BDG.G")
      || CWHABILITATION.canView("RES_VAL_REG.G")
      || CWHABILITATION.canView("RES_VAL_EGEN.G")
      || CWHABILITATION.canView("RES_VAL_ACTPR.G")
      || CWHABILITATION.canView("RES_VAL_ACTRE.G")
      || CWHABILITATION.canView("RES_VAL_RECAP.G")
      || CWHABILITATION.canView("RES_VAL_PRET.G");
  }

  refreshBrique(): any {
    const lPromises = [];
    let lExistAbs = false;
    let lExistSou = false;
    let lExistBad = false;
    let lExistReg = false;
    let lExistEvt = false;
    let lExistAct = false;
    let lExistRec = false;
    let lExistPre = false;

    this.paves = [];
    $(this.el).find(".table_BriqueDemandesCollaborateurs").html(i18n.t('messages:GT_1098'));
    if (CWHABILITATION.canView("RES_VAL_ABS.G")) {
      lPromises.push(this.modelAbsence.fetch());
      lExistAbs = true;
    }
    if (this.parametreDiversSouhabsModel.get("valeur") === "1" && CWHABILITATION.canView("RES_VAL_SHT.G")) {
      lPromises.push(this.modelSouhaitAbs.fetch());
      lExistSou = true;
    }
    if (CWHABILITATION.canView("RES_VAL_BDG.G")) {
      lPromises.push(this.modelBadgeage.fetch());
      lExistBad = true;
    }
    if (CWHABILITATION.canView("RES_VAL_REG.G")) {
      lPromises.push(this.modelRegularisation.fetch());
      lExistReg = true;
    }
    if (CWHABILITATION.canView("RES_VAL_EGEN.G")) {
      lExistEvt = true;
      if (!CWSTR.isBlank(this.usePopulation) && this.usePopulation) {
        // Join population parameter
        lPromises.push(this.modelEvtgen.fetch({ data: { "filtre": true } }));
      } else {
        lPromises.push(this.modelEvtgen.fetch());
      }
    }
    if (CWHABILITATION.canView("RES_VAL_ACTPR.G") || CWHABILITATION.canView("RES_VAL_ACTRE.G")) {
      lPromises.push(this.modelTypologiesActivites.fetch());
      lExistAct = true;
    }
    if (CWHABILITATION.canView("RES_VAL_RECAP.G")) {
      lPromises.push(this.modelRecapitulatif.fetch());
      lExistRec = true;
    }
    if (CWHABILITATION.canView("RES_VAL_PRET.G")) {
      lPromises.push(this.modelPretsValider.fetch());
      lExistPre = true;
    }

    if (!_.isEmpty(lPromises)) {

      forkJoin(lPromises).subscribe(() => {
        let lTexte = "";

        this.parent.setTitle(i18n.t('common:bdemcoll.DemandesCollaborateurs'));
        if (lExistAbs || lExistSou) {
          const lNumAbs = (this.modelAbsence && this.modelAbsence.get("valeur")) ? this.modelAbsence.get("valeur") : 0;
          const lNumSou = (this.modelSouhaitAbs && this.modelSouhaitAbs.get("valeur")) ? this.modelSouhaitAbs.get("valeur") : 0;

          if ((lNumAbs + lNumSou) <= 1) {
            lTexte = "paveAbsence";
          } else {
            lTexte = "paveAbsences";
          }
          this.paves.push({ type: "absences", libelle: i18n.t('common:bdemcoll.' + lTexte), nombre: 0, lien: "valevt/ABSENCE", texte: "voirLespaveAbsences" });
        }
        if (lExistBad) {
          const lNumBad = (this.modelBadgeage && this.modelBadgeage.get("valeur")) ? this.modelBadgeage.get("valeur") : 0;

          if (lNumBad <= 1) {
            lTexte = "paveBadgeage";
          } else {
            lTexte = "paveBadgeages";
          }
          this.paves.push({ type: "badgeages", libelle: i18n.t('common:bdemcoll.' + lTexte), nombre: 0, lien: "valevt/BADGEAGE", texte: "voirLespaveBadgeages" });
        }
        if (lExistReg) {
          const lNumReg = (this.modelRegularisation && this.modelRegularisation.get("valeur")) ? this.modelRegularisation.get("valeur") : 0;

          if (lNumReg <= 1) {
            lTexte = "paveRegularisation";
          } else {
            lTexte = "paveRegularisations";
          }
          this.paves.push({ type: "regularisations", libelle: i18n.t('common:bdemcoll.' + lTexte), nombre: 0, lien: "valevt/REGULARISATION", texte: "voirLespaveRegularisations" });
        }
        if (lExistEvt) {
          const lNumEvt = (this.modelEvtgen && this.modelEvtgen.length) ? this.modelEvtgen.length : 0;

          if (lNumEvt <= 1) {
            lTexte = "paveAutresEvenement";
          } else {
            lTexte = "paveAutresEvenements";
          }
          this.paves.push({ type: "autresEvenements", libelle: i18n.t('common:bdemcoll.' + lTexte), nombre: 0, lien: "valevt/EVTGEN", texte: "voirLespaveAutresEvenements" });
        }
        if (lExistAct) {
          const lNumAct = (this.modelTypologiesActivites && this.modelTypologiesActivites.length) ? this.modelTypologiesActivites.length : 0;

          if (lNumAct <= 1) {
            lTexte = "paveActivite";
          } else {
            lTexte = "paveActivites";
          }
          this.paves.push({ type: "activites", libelle: i18n.t('common:bdemcoll.' + lTexte), nombre: 0, lien: "valevt/ACTIVITES", texte: "voirLespaveActivites" });
        }
        if (lExistRec) {
          const lNumRec = (this.modelRecapitulatif && this.modelRecapitulatif.get("valeur")) ? this.modelRecapitulatif.get("valeur") : 0;

          if (lNumRec <= 1) {
            lTexte = "paveRecapitulatif";
          } else {
            lTexte = "paveRecapitulatifs";
          }
          this.paves.push({ type: "recapitulatifs", libelle: i18n.t('common:bdemcoll.' + lTexte), nombre: 0, lien: "valevt/ACTRECAP", texte: "voirLespaveRecapitulatifs" });
        }
        if (lExistPre) {
          const lNumPre = (this.modelPretsValider && this.modelPretsValider.get("valeur")) ? this.modelPretsValider.get("valeur") : 0;

          if (lNumPre <= 1) {
            lTexte = "pavePret";
          } else {
            lTexte = "pavePrets";
          }
          this.paves.push({ type: "pretsEtEmprunts", libelle: i18n.t('common:bdemcoll.' + lTexte), nombre: 0, lien: "valevt/PRET", texte: "voirLespavePrets" });
        }
        this._loadNbrs();
        this.renderTable();
        this.delegateEvents();
      }
      );
    }
    return this;
  }

  renderTable(): void {
    if (this.totalAValider > 0) {
      $(this.el).find(".table_BriqueDemandesCollaborateurs").html(this._renderGrid());
    } else {
      $(this.el).find(".table_BriqueDemandesCollaborateurs").html(i18n.t('messages:GT_1098'));
    }
  }

  _renderGrid(): any {
    const $demandesAValider = $("<div>");
    const $grid = $("<div>");
    const paves = this.paves;
    let pavesAdded = 0;

    for (let i = 0; i < paves.length; i) {
      const $row = $("<div class='row no-gutters'>");
      while ($row[0].childElementCount < 2 && i < paves.length) {
        if (paves[i].nombre > 0) {
          $row.append($("<div class='col-6 cw_pave-" + (++pavesAdded) + "'>").append(this._appendPave(paves[i])));
        }
        i++;
      }
      if ($row[0].childElementCount !== 0) {
        $grid.append($row);
      }
    }

    $demandesAValider.append($grid);
    return $demandesAValider;
  }

  _appendPave(pave: any): any {
    const aideIcon = UTILS.getSVGIcon("valider_rond", "", 24);
    const $pave = $("<div>").addClass("cw-pave mb-2 text-center");
    const $paveIcon = $("<div>").addClass('cw-pave__icon').addClass("cw-pave__icon__" + pave.type);
    const $paveContenu = $("<div>").addClass('cw-pave-contenu');
    const $paveSize = $("<div>").addClass('cw-texteTresImportant cw-pave-contenu__white cw-pave-valeur');
    const $paveTitle = $("<div>").addClass('cw-texteImportant cw-pave-contenu__white cw-pave-title title');
    const $paveLienDiv = $("<div>").addClass('cw-lienImportant cw-pave-contenu__white cw-pave-link');
    const $paveLien = $("<span>").addClass(pave.lien + ' cw-pave__lien');
    $pave.addClass("cw-pave__" + pave.type);
    $paveIcon.append(aideIcon);
    $pave.append($paveIcon);
    $paveSize.append(pave.nombre);
    $paveTitle.append(pave.libelle);
    if (CWSTR.isBlank(pave.lien)) {
      $paveLienDiv.css('visibility', 'hidden');
    }
    $paveLien.append((i18n.t('common:bdemcoll.' + pave.texte)).toUpperCase());
    $paveLienDiv.append($paveLien);
    $pave.append($paveContenu);
    $paveContenu.append($paveSize);
    $paveContenu.append($paveTitle);
    $paveContenu.append($paveLienDiv);
    return $pave;
  }

  _linkToValidation(event: JQueryEventObject): void {
    const target = event.currentTarget;
    const ecran = target.className.split(" ")[0];

    NAV.navigate("resp", ecran, "uc", true, true);
  }

  _loadNbrs(): void {
    this.totalAValider = 0;
    if (this.paves.find((x: { type: string }) => x.type === "absences")) {
      this.absenceNbr = (this.modelAbsence.get("valeur") ? this.modelAbsence.get("valeur") : 0) + (this.modelSouhaitAbs.get("valeur") ? this.modelSouhaitAbs.get("valeur") : 0);
      this.paves.find((x: { type: string }) => x.type === "absences").nombre = this.absenceNbr;
      this.totalAValider += this.absenceNbr;
    }
    if (this.paves.find((x: { type: string }) => x.type === "badgeages")) {
      this.badgeageNbr = this.modelBadgeage.get("valeur");
      this.paves.find((x: { type: string }) => x.type === "badgeages").nombre = this.badgeageNbr;
      this.totalAValider += this.badgeageNbr;
    }
    if (this.paves.find((x: { type: string }) => x.type === "regularisations")) {
      this.regularisationNbr = this.modelRegularisation.get("valeur");
      this.paves.find((x: { type: string }) => x.type === "regularisations").nombre = this.regularisationNbr;
      this.totalAValider += this.regularisationNbr;
    }
    if (this.paves.find((x: { type: string }) => x.type === "autresEvenements")) {
      this.evenGenerNbr = this._sizeModels(this.modelEvtgen.models);
      this.paves.find((x: { type: string }) => x.type === "autresEvenements").nombre = this.evenGenerNbr;
      this.totalAValider += this.evenGenerNbr;
    }
    if (this.paves.find((x: { type: string }) => x.type === "activites")) {
      this.activitesNbr = this._sizeModels(this.modelTypologiesActivites.models);
      this.paves.find((x: { type: string }) => x.type === "activites").nombre = this.activitesNbr;
      this.totalAValider += this.activitesNbr;
    }
    if (this.paves.find((x: { type: string }) => x.type === "recapitulatifs")) {
      this.recapitulatifNbr = this.modelRecapitulatif.get("valeur");
      this.paves.find((x: { type: string }) => x.type === "recapitulatifs").nombre = this.recapitulatifNbr;
      this.totalAValider += this.recapitulatifNbr;
    }
    if (this.paves.find((x: { type: string }) => x.type === "pretsEtEmprunts")) {
      this.pretEtEmpruntNbr = this.modelPretsValider.get("valeur");
      this.paves.find((x: { type: string }) => x.type === "pretsEtEmprunts").nombre = this.pretEtEmpruntNbr;
      this.totalAValider += this.pretEtEmpruntNbr;
    }
  }

  _sizeModels(models: any): number {
    let valeur = 0;
    for (let i = 0; i < models.length; i++) {
      if (!CWSTR.isBlank(models[i].get("valeur"))) {
        valeur += models[i].get("valeur");
      } else if (!CWSTR.isBlank(models[i].get("nb_actprev")) && !CWSTR.isBlank(models[i].get("nb_actreal"))) {
        valeur += models[i].get("nb_actprev") + models[i].get("nb_actreal");
      }
    }
    return valeur;
  }

}
