import { CWBaseGridCollection } from 'src/core/grids/basegrid/cwBaseGrid.collection';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Collection of SearchResultatsListeCpt
 */
export class CWSearchResultatsListeCptColl extends CWBaseGridCollection<CWBaseModel> {

  private matricule: number;
  private groupe: string;
  private date: string;
  private demande: string;
  private souhait: string;

  constructor() {
    super();
    this.url = (): string => {
      return Configuration.restRoot + this._createUrl();
    }
    this.paginated = false;
  }
  _formatDate(date: string): string {
    const datAttr = date.split('/');
    const day = datAttr[0];
    const month = datAttr[1];
    const year = datAttr[2];
    const dateFormat = year + month + day;
    return dateFormat;
  }

  _createUrl(): string {
    let urlS = "/rest/resultatsgroupecpt?matricule=" + encodeURIComponent(this.matricule) +
      "&groupe=" + encodeURIComponent(this.groupe);

    if (this.date !== undefined && this.date !== null) {
      urlS = urlS + "&date=" + this.date;
    }
    if (this.demande !== undefined && this.demande !== null) {
      urlS = urlS + "&demande=" + encodeURIComponent(this.demande);
    }
    if (this.souhait !== undefined && this.souhait !== null) {
      urlS = urlS + "&souhait=" + encodeURIComponent(this.souhait);
    }

    return urlS;
  }

  getMatricule(): number {
    return this.matricule;
  }

  setMatricule(m: number): void {
    this.matricule = m;
  }

  getGroupe(): string {
    return this.groupe;
  }

  setGroupe(g: string): void {
    this.groupe = g;
  }

  getDate(): string {
    return this.date;
  }

  setDate(d: string): void {
    this.date = d;
  }

  getDemande(): string {
    return this.demande;
  }

  setDemande(d: string): void {
    this.demande = d;
  }

  getSouhait(): string {
    return this.souhait;
  }

  setSouhait(s: string): void {
    this.souhait = s;
  }
}