import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

export class CWMesDemandeQte extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "autresEvenements": "",
      "absences": "",
      "badgeages": "",
      "activites": "",
      "regularisations": "",
      "recapitulatifs": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = Configuration.restRoot + "/rest/brique/demande/resume";
  }

  fetch(arg: { [key: string]: any }): JQueryXHR {
    const options = arg || {};
    const lEvenements = {
      "ABSENCE": "COL_ABS.V",
      "REGULARISATION": "COL_REG.V",
      "BADGEAGE": "COL_BADG.V",
      "ACTRECAP": "COL_RECAP.V",
      "EVTGEN": "COL_EVTGEN.V",
      "ACTPREV": "COL_ACT_PREV.V",
      "ACTREAL": "COL_ACT_REAL.V"
    };

    if (CWSTR.isBlank(options.data)) {
      options.data = JSON.stringify(lEvenements);
    }
    options.type = "POST";
    options.dataType = "json";
    options.contentType = 'application/json';
    options.processData = false;

    return CWBaseModel.prototype.fetch.call(this, options);
  }
}