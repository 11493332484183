import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model for Combos Loading
 */
export class CWGererOneBadgeageProfilsModel extends CWBaseModel {
  profilId: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);

    this.profilId = "";
    if (options && options.profilId) {
      this.profilId = options.profilId;
    }

    this.url = (): string => {
      return Configuration.restRoot + "/rest/badgeage/composant/profils/" + encodeURIComponent(this.profilId);
    }
  }
}
