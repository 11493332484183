import _ from 'underscore';
import { CWGererActivitesModel } from './cwGererActivites.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

/**
 * Lister Gerer Deplacement Activites Model
 */
export class CWGererActivitesDeplacementModel extends CWGererActivitesModel {


  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.attributes = _.extend(this.attributes, {
      "typeevenement": "R",
      "datedeb": "",
      "happel": "",
      "dtappel": "",
      "dtdebinter": "",
      "dtfininter": "",
      "hdebinter": "",
      "hfininter": "",
      "dureeinter": "",
      "typedeplac": {
        "code": "",
        "libelle": "",
      },
      "cptdeplac": {
        "code": "",
        "libelle": "",
      }
    });
  }

  validate(attrs: { [key: string]: any }, options?: { [key: string]: any }): { [key: string]: any } {
    const errors: { [Key: string]: any } = {};
    let errorMsg;
    const typologie = GLOBAL_DATA.typologies.get(attrs.typologie.code);

    // Activite
    if (CWSTR.isBlank(attrs.activite)) {
      let act = "";

      if (!CWSTR.isBlank(typologie)) {
        const evenement = typologie.get("evenement");

        //activite prevue
        if (attrs.typeevenement === "P") {
          if (typologie.get("genre") === "M") {
            act = i18n.t('common:gereractivites.actprevM', { "0": evenement });
          } else {
            act = i18n.t('common:gereractivites.actprevF', { "0": evenement });
          }
          errors.actprev = i18n.t('common:required', { "0": act });
          //activite realise
        } else if (attrs.typeevenement === "R") {
          if (typologie.get("genre") === "M") {
            act = i18n.t('common:gereractivites.actrealM', { "0": evenement });
          } else {
            act = i18n.t('common:gereractivites.actrealF', { "0": evenement });
          }
          errors.actreal = i18n.t('common:required', { "0": act });
        }
      }
    }
    if (this.matricule && CWSTR.isBlank(attrs.matricule)) {
      errors.matricule = i18n.t('common:required', { "0": i18n.t('common:gereractivites.collab') });
    }
    if (CWSTR.isBlank(attrs.dateDebD)) {
      errors.dateDebD = i18n.t('common:required', { "0": i18n.t('common:gereractivites.comptabilise') });
    }
    if (!CWSTR.isBlank(attrs.dateDebD) && !CWSTR.isBlank(attrs.dateDebD)) {
      errors.dateDebD = CWTYPE.DATE.validate(String(attrs.dateDebD));
      if (!CWSTR.isBlank(errorMsg)) {
        errors.dateDebD = errorMsg;
      }
    }
    if ((CWSTR.isBlank(attrs.dtdebinter) && CWSTR.isBlank(options.editedAttr)) || (CWSTR.isBlank(attrs.dtdebinter) && !CWSTR.isBlank(options.editedAttr) && options.editedAttr === 'dtdebinter')) {
      errors.dtdebinter = i18n.t('common:required', { "0": i18n.t('common:gereractivites.date_intervention') });
    }
    if (!CWSTR.isBlank(attrs.dtdebinter) && !CWSTR.isBlank(attrs.dtdebinter)) {
      errors.dtdebinter = CWTYPE.DATE.validate(String(attrs.dtdebinter));
    }
    if (!CWSTR.isBlank(attrs.dateDebD) && !CWSTR.isBlank(attrs.dateDebD) && !CWSTR.isBlank(attrs.dtdebinter) && !CWSTR.isBlank(attrs.dtdebinter)) {
      const dif = CWTYPE.DATE.getDiffInDays(String(attrs.dateDebD), String(attrs.dtdebinter));

      if (dif < 0 || dif > 1) {
        //if ((parseInt(attrs.dtdebinter) < parseInt(attrs.datedeb)) || (parseInt(attrs.dtdebinter) > (parseInt(attrs.datedeb) + 1))) {
        if (CWSTR.isBlank(errors.dtdebinter)) {
          errors.dtdebinter = i18n.t('messages:GME_14');
        }
      }
    }
    if (!CWSTR.isBlank(attrs.dtdebinter) && !CWSTR.isBlank(attrs.dtappel) && !CWSTR.isBlank(attrs.happel) && !CWSTR.isBlank(attrs.hdebinter)) {
      if (((parseInt(attrs.dtappel) === parseInt(attrs.dtdebinter)) && parseInt(attrs.happel) > parseInt(attrs.hdebinter))) {
        errors.happel = i18n.t('messages:GME_15');
      }
    }
    if (!CWSTR.isBlank(attrs.typedeplac) && CWSTR.isBlank(attrs.typedeplac.code)) {
      errors.typedeplac = i18n.t('common:required', { "0": i18n.t('common:gereractivites.type_deplacement') });
    }
    if (!CWSTR.isBlank(attrs.dtdebinter) && !CWSTR.isBlank(attrs.dtdebinter) && !CWSTR.isBlank(attrs.dateDebD) && !CWSTR.isBlank(attrs.dateDebD)) {
      const datedeb = {
        "name": "dateDebD",
        "value": String(attrs.dateDebD),
        "label": i18n.t('common:gereractivites.comptabilise')
      };
      const datefin = {
        "name": "dtdebinter",
        "value": String(attrs.dtdebinter),
        "label": i18n.t('common:gereractivites.date_intervention'),
        "isInfinity": false
      };

      errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
      if (errorMsg && !CWSTR.isBlank(errorMsg.dateDebD)) {
        errors.dateDebD = errorMsg.dateDebD;
      }
      if (errorMsg && !CWSTR.isBlank(errorMsg.dtdebinter)) {
        errors.dtdebinter = errorMsg.dtdebinter;
      }
    }
    if (CWSTR.isBlank(attrs.hdebinter)) {
      errors.hdebinter = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heuredebut') });
    } else {
      errorMsg = CWTYPE.HOUR_MINUTE.validate(attrs.hdebinter);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.hdebinter = errorMsg;
      }
    }
    if (CWSTR.isBlank(attrs.hfininter)) {
      errors.hfininter = i18n.t('common:required', { "0": i18n.t('common:gereractivites.heurefin') });
    } else {
      errorMsg = CWTYPE.HOUR_MINUTE.validate(attrs.hfininter);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.hfininter = errorMsg;
      }
    }
    if (!CWSTR.isBlank(attrs.happel)) {
      errorMsg = CWTYPE.HOUR_MINUTE.validate(attrs.happel);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.happel = errorMsg;
      }
    }
    Object.keys(errors).forEach(function (key) {
      if (_.isEmpty(errors[key])) {
        delete errors[key];
      }
    });
    _.extend(errors, this._validateInfoComp(attrs));
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }
}
