import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Model for gerer badgeages
 */
export class CWListeBadgeageModel extends CWBaseModel {

  action: string;


  /**
   * Default data model
   *		id : null,
   *		code : null,
   *		matricule: null,
   *		statut: {
   *			code : "",
   *			libelle : ""
   *		},
   *		motif: {
   *			code : "",
   *			libelle : ""
   *		},
   *		saisie: {
   *			date : "",
   *			heure : "",
   *			sens : {
   *			   code : "",
   *			   libelle : ""
   *		    }
   *		},
   *		valorise: {
   *			date : "",
   *			heure : "",
   *			sens : {
   *			   code : "",
   *			   libelle : ""
   *		    }
   *		},
   *		initial: {
   *			date : "",
   *			heure : "",
   *			sens : {
   *			   code : "",
   *			   libelle : ""
   *		    }
   *		},
   *		fonction: "",
   *		commentaire: "",
   *		droit: null,
   *		iddemande: null,
   *		provenance: "",
   *		origine: null,
   *		terminal: {
   *			   code : "",
   *			   libelle : ""
   *		},
   *		genere: null
   * }
   */
  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": null,
      "matricule": null,
      "statut": {
        "code": "",
        "libelle": ""
      },
      "motif": {
        "code": "",
        "libelle": ""
      },
      "saisie": {
        "date": "",
        "heure": "",
        "sens": {
          code: "",
          libelle: ""
        }
      },
      "valorise": {
        "date": "",
        "heure": "",
        "sens": {
          code: "",
          libelle: ""
        }
      },
      "initial": {
        "date": "",
        "heure": "",
        "sens": {
          code: "",
          libelle: ""
        }
      },
      "fonction": "",
      "commentaire": "",
      "droit": null,
      "iddemande": null,
      "provenance": "",
      "origine": null,
      "terminal": {
        code: "",
        libelle: ""
      },
      "genere": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/badgeage/composant/" + this.action;
    }
  }

  /**
   * Validate the model before launch the REST
   */
  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    let errorMsg;

    errors.saisie = {};
    if (!CWSTR.isBlank(attrs.saisie.date)) {
      errorMsg = CWTYPE.DATE.validate(attrs.saisie.date);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.saisie.date = errorMsg;
      }
    } else {
      errors.saisie.date = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.date') });
    }
    if (!CWSTR.isBlank(attrs.saisie.heure)) {
      errorMsg = CWTYPE.HOUR_MINUTE.validate(attrs.saisie.heure);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.saisie.heure = errorMsg;
      }
    } else {
      errors.saisie.heure = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.heure') });
    }
    if (CWSTR.isBlank(attrs.saisie.sens)) {
      errors.saisie.sens = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.sens') });
    }
    if (!_.isEmpty(errors.saisie)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  /**
   * Parsed the results returned by the REST,
   * Copy the return matricule + code in the object id
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (!CWSTR.isBlank(response.matricule) && !CWSTR.isBlank(response.code)) {
      response.id = response.matricule + "," + response.code;
    }

    if (response.result) {
      return null;
    }
    return response;
  }
}