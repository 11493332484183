module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="gererevtgenerique-popup cw-fieldset">\n    <div class="phx-formTable">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div>\n            <div class="form-group">\n                <span class="spanComment"></span>\n            </div>\n            <div class="form-group">\n                <label for="commentaire">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t("common:gererevtgen.comment")))==null?'':__t)+
'</label>\n                <textarea class="commentaire form-control" maxlength="500" cols="30" style="white-space: pre-wrap" rows="3"></textarea>\n            </div>\n            <div class="gererevtgenerique-buttons cw-buttonBar">\n                <div class="btnBar-btn-container">\n                    <button type="button" class="btnOK btn btn-primary btn-withIcon" value="btnOK">'+
((__t=(i18n.t("common:gererevtgen.btnOK")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('valider','',16)))==null?'':__t)+
'</button>\n                </div>\n                <div class="btnBar-btn-container">\n                    <button type="button" class="btnAnnuler btn btn-secondary btn-withIcon" value="btnAnnuler">'+
((__t=(i18n.t("common:gererevtgen.btnAnnuler")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('croix','',16)))==null?'':__t)+
'</button>\n                </div>\n            </div>\n        </div>\n    </div>\n</fieldset>';
}
return __p;
};
