import _ from 'underscore';
import CWJaugeTPL from './cwJauge.tpl.html';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { Model, View, ViewOptions } from 'Backbone';

export interface JaugeBarValue {
  maxValue: number;
  maxLabel?: string;
  value: number;
  minLabel?: string;
}

export interface CWJaugeOptions extends ViewOptions<Model> {
  title?: string | JQuery;
  value: string | JQuery;
  id: string;
  tooltip?: JQuery;
  clickAction?: (id: string) => void;
  showBar?: boolean;
  barValue?: JaugeBarValue;
  tooltipStyle?: string;
}

export class CWJauge extends View<Model> {
  private _title: string | JQuery;
  private _value: string | JQuery;
  private _tooltip: JQuery;
  protected isRendered: boolean;
  protected clickAction?: (id: string) => void;
  protected _showBar: boolean;
  protected _barValues: JaugeBarValue;
  private mutationObserver: MutationObserver;
  private _tooltipStyle: string;

  constructor(jaugeProperties: CWJaugeOptions) {
    jaugeProperties.tagName = jaugeProperties.tagName || "div";
    jaugeProperties.className = jaugeProperties.className || "c-jauge align-items-end";
    jaugeProperties.events = _.extend({
      "click": "jaugeClicked"
    }, jaugeProperties.events);
    super(jaugeProperties);

    this.template = CWJaugeTPL;

    this.clickAction = jaugeProperties.clickAction ? jaugeProperties.clickAction : null;
    this._title = jaugeProperties.title;
    this.value = jaugeProperties.value;
    this._showBar = jaugeProperties.showBar;
    this._barValues = jaugeProperties.barValue;
    this.tooltip = jaugeProperties.tooltip;
    this.tooltipStyle = jaugeProperties.tooltipStyle;

    this.isRendered = false;
  }

  get showBar(): boolean {
    return this._showBar;
  }

  set showBar(value: boolean) {
    this._showBar = value;
    this.refreshComponent();
  }

  get barValues(): JaugeBarValue {
    return this._barValues;
  }

  set barValues(value: JaugeBarValue) {
    this._barValues = value;
    this.refreshComponent();
  }


  get title(): string | JQuery {
    return this._title;
  }

  set title(value: string | JQuery) {
    this._title = value;
    this.refreshComponent();
  }

  get tooltip(): JQuery {
    return this._tooltip;
  }

  set tooltip(value: JQuery) {
    this._tooltip = value;
    this.refreshComponent();
  }

  get value(): string | JQuery {
    return this._value;
  }

  set value(value: string | JQuery) {
    if (typeof value === "string") {
      this._value = value.trim();
    }
    else {
      this._value = value;
    }
    this.refreshComponent();
  }

  get tooltipStyle(): string {
    return this._tooltipStyle;
  }

  set tooltipStyle(value: string) {
    this._tooltipStyle = value;
    this.refreshComponent();
  }

  protected refreshComponent(): void {
    if (this.isRendered) {
      $(this.el).find(".c-jauge-inner__content").empty();
      $(this.el).find(".c-jauge-inner__content").append(this.renderValue());
      $(this.el).find(".c-jauge-inner__content").append(this.renderTitle());
      $(this.el).append(this.renderBar());
      this.renderTootlTip();
    }
  }

  protected renderBar(): JQuery {
    if (this._showBar) {
      if (this._barValues) {
        const returned = $("<div>").addClass("c-jauge__bar__container");
        const labelsContainer = $("<div>").addClass("c-jauge__bar__labels__container");
        if (this._barValues.maxLabel) {
          if (this._barValues.maxLabel) {
            labelsContainer.append($("<span>").addClass("c-jauge__bar__labels__max").html(this._barValues.maxLabel));
          }
        }
        returned.append(labelsContainer);
        let percent = (this._barValues.value * 100) / this._barValues.maxValue;
        if (percent < 0 || isNaN(percent)) {
          percent = 0;
        }
        if (percent > 100) {
          percent = 100;
          $(this.el).addClass("c-jauge-percent-max");
        }
        else {
          $(this.el).removeClass("c-jauge-percent-max");
        }
        const barFond = $("<div>").addClass("c-jauge__bar__bar");
        const barRell = $("<div>").addClass("c-jauge__bar__bar__rell").css("width", percent + "%");
        returned.append(barFond.append(barRell));
        const indicatorsContainer = $("<div>").addClass("c-jauge__indicators__container");
        if (this._barValues.minLabel) {
          indicatorsContainer.append($("<span>").addClass("c-jauge__bar__labels__min_left").html(this._barValues.minLabel));
        }
        const triangle = $("<div>").addClass("c-jauge__bar__triangle");//.css("margin-left", percent);
        const borderWidth = 12; //IMPONRTANT Sync with the border width in the STYLE!!! 
        if (percent > 0) {
          triangle.css("margin-left", "calc( " + percent + "% - " + borderWidth + "px )");
        }
        else {
          triangle.css("margin-left", "-" + borderWidth + "px");
        }
        indicatorsContainer.append(triangle);

        if (this._barValues.minLabel) {
          indicatorsContainer.append($("<span>").addClass("c-jauge__bar__labels__min_right").html(this._barValues.minLabel));
        }
        returned.append(indicatorsContainer);

        //Calc witch etiquete must to show
        if (this._barValues.minLabel) {
          this.calculateLabelMaxMinBeVisibleOrNot(percent, borderWidth);
        }
        return returned;
      }
      else {
        CWLOG.warn("[CWJauge Core Component] I can't render the bar because i don't have values (ser values BEFORE enable showbar)");
      }
    }
    return null;
  }

  /**
   * Calculate witch etiquete of the bar we need to show.
   * 
   * @param percent number The percent of deplacament of the arrow
   * @param borderArrowWidth number the width of the arrow
   */
  private calculateLabelMaxMinBeVisibleOrNot(percent: number, borderArrowWidth: number): void {
    this.mutationObserver = new MutationObserver((): void => {
      if (this.$el.is(":visible")) {
        //Get the real width of the etiquette left
        const leftLabelWidth = this.$el.find(".c-jauge__bar__labels__min_left").width();
        //Calculate the triangle position, i use the bar width and the percent minus border of arrow
        const leftSpaceRemains = (((this.$el.find(".c-jauge__bar__bar").width() * percent) / 100) - borderArrowWidth);
        //Set the margin to the left label
        this.$el.find(".c-jauge__bar__labels__min_left").css("margin-left", (leftSpaceRemains - leftLabelWidth) + "px");
        //Set the margin to the right label
        this.$el.find(".c-jauge__bar__labels__min_right").css("margin-left", "calc(" + percent + "% + " + borderArrowWidth + "px)");
        //Show/hide elements in funtion to this space
        if (leftLabelWidth > leftSpaceRemains) {
          this.$el.find(".c-jauge__bar__labels__min_left").hide();
          this.$el.find(".c-jauge__bar__labels__min_right").show();
        }
        else {
          this.$el.find(".c-jauge__bar__labels__min_left").show();
          this.$el.find(".c-jauge__bar__labels__min_right").hide();
        }
        //All done :) so i don't need the mutation observer so, let's go out! 
        this.mutationObserver.disconnect();
      }
    });
    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };
    // Observe myself
    this.mutationObserver.observe(this.el, config);
  }

  protected renderValue(): JQuery {
    const returned = $("<div>").addClass("c-jauge__val");

    const valNumber = $("<span>").addClass("c-jauge__val__value").append(this._value);
    returned.append(valNumber);

    return returned;
  }

  protected renderTitle(): JQuery {
    if (this._title) {
      return $("<div>").addClass("c-jauge__lib").append(this._title);
    }
    return null;
  }
  protected renderTootlTip(): void {
    if (this._tooltip) {
      if (!CWSTR.isBlank(this.tooltipStyle)) {
        this.tooltip.find(".c-grind__titleBar .c-grind__titleBar__barContent").addClass(this.tooltipStyle);
        this.tooltip.find(".c-grind__headerScroll").addClass(this.tooltipStyle);
        this.tooltip.find(".c-grind__scroll").addClass(this.tooltipStyle);
      }
      if (typeof this.tooltip === "string") {
        $(this.el).find(".c-jauge-inner").prop("title", this.tooltip);
      }
      else {
        $(this.el).find(".c-jauge-inner").prop("title", this.tooltip.html());
        $(this.el).find(".c-jauge-inner").addClass(this.tooltipStyle);
      }
    } else {
      $(this.el).find(".c-jauge-inner").removeProp("title");
    }
  }

  public render(): CWJauge {
    this.$el.empty();
    $(this.el).append(this.template());
    this.isRendered = true;
    this.refreshComponent();
    return this;
  }

  private jaugeClicked(): void {
    if (this.clickAction) {
      this.clickAction(this.id);
    }
    else {
      this.trigger("click", this.id);
    }
  }
}