import { BaseModel } from 'core/models/base.model.js';

/**
 * Lister l'historique de l'absence
 */
export var ListerHistoriqueModel = BaseModel.extend({
  /** * ListerHistoriqueModel */

  url: Configuration.restRoot + "/rest/wkf/derniereaction/PRET",

  defaults: {
    "evenements": null
  }
});
