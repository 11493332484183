import { BaseModel } from 'core/models/base.model.js';

export var RecapHistoModel = BaseModel.extend({

  defaults: {
    "action": "",
    "date": "",
    "heure": "",
    "auteur": "",
    "commentaire": ""
  }
});