import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDataGridModel } from '../data_grid/cwDataGrid.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';

export class CWGroupeGridModel<TColl extends CWPaginatedCollection<CWBaseModel> = CWPaginatedCollection<CWBaseModel>> extends CWDataGridModel<TColl> {

  public groupeByColumn: any;

  /**
   * Constructor
   * Model underlying a Groupe type DataGrid
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
  }

}
