import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { SaisieOptionsModel } from './saisieOptions.model.js';
/**
 * Collection for authorized mode saisies
 */
export var ModeSaisieOptionsColl = PaginatedCollection.extend({

  model: SaisieOptionsModel,

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  url: function() {
    return Configuration.restRoot + "/rest/utilevenement/modesaisie/ls?affectation=" + this.affectation;
  }

});
