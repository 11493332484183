/**
 * Utilities to manage session objects
 */
export var SESSION = {

  /**
   * Assigned the session
   *
   */
  set: function(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  /**
   * Returns the session
   *
   */
  get: function(key) {
    return JSON.parse(sessionStorage.getItem(key));
  },

  /**
   * Clear the session
   */
  removeItem: function(key) {
    sessionStorage.removeItem(key);
  },

  /**
   * Clear the session
   */
  clear: function() {
    sessionStorage.clear();
  }
};
