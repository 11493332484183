import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { EditableGridView } from '../editablegrid/editableGrid.view.js';
import { STR } from 'utils/str.js';

/**
 * View underlying a row form the Tree type DataGrid
 */
export var GroupeGridRowView = DataGridRowView.extend({

  /**
   * Name of the html tag which involves the view
   */
  tagName: "tr",

  /**
   * Name of the container class of the view
   */
  className: "ui-grid-row",

  /**
   * Indicator to know if the row is edited or not
   */
  edited: false,

  initialize: function(options) {
    DataGridRowView.prototype.initialize.call(this, options);
    this.listenTo(this.dataGrid.model, "toggle:column", this._toggleColumn);
  },

  /**
   * Event launched when the expand icon is clicked
   *
   */
  /**
   * Event launched when the row is clicked
   *
   */
  /**
   * Event launched when the row is clicked twice
   *
   */
  /**
   * Event launched when the expand icon is clicked
   *
   */
  events: {
    "click .goupetbl_expbtn": "_clickTreeTableBtn",
    "click": "_rowListener"
  },

  /**
   * Method which expands or not the row of the table grid when we click the related button.
   */
  _clickTreeTableBtn: function(event) {
    this.model.trigger("treetbl_exp", this.model);
    event.stopPropagation();
  },

  /**
   * Paints the row view of the tree grid
   */
  render: function() {
    var rowThis = $(this.el);
    var isSelected = rowThis.hasClass("phx-selected");
    var groupeByColumnValue = this.dataGrid._groupeByColumnValue(this.model);
    var _this = this;

    if (this.dataGrid.selectable) {
      rowThis.css("cursor", "pointer");
    }
    rowThis.empty();
    rowThis.attr("groupe-header", groupeByColumnValue);
    if (this.edited === true &&
      (this.dataGrid instanceof EditableGridView)) {
      this._renderForm();
    } else {
      _.each(this.dataGrid.currentVue.columns, function(column, index) {
        var cell = null;

        if (column.property === _this.dataGrid.model.groupeByColumn) {
          var span = null;
          var marginLeft = null;
          var keys = null;
          var visibles = null;

          cell = _this._addCell(column, index);
          marginLeft = _this.model.level * 10;
          if (STR.isBlank(_this.model.isExpanded)) {
            _this.model.isExpanded = true;
          }
          if (_this.model.isExpanded === true) {
            span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='goupetbl_expbtn ui-corner-all ui-icon ui-icon-triangle-1-s' style='position:absolute;top:-13px;'></span></div>");
          } else {
            span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='goupetbl_expbtn ui-corner-all  ui-icon ui-icon-triangle-1-e' style='position:absolute;top:-13px;'></span></div>");
          }
          $(cell.el).prepend(span);
          $(cell.el).css("height", "17px");
          $(cell.el).css("padding-left", marginLeft + "px");
          $(cell.el).css("font-weight", "bold");
          keys = _.keys(_this.dataGrid.currentVue._columns);
          // Get the number of visible columns
          visibles = _.filter(keys, function(columKey) {
            var visible = _this.dataGrid.currentVue._columns[columKey].visible;

            if (columKey.indexOf("phx-") >= 0) {
              visible = false;
            }
            return visible;
          });
          $(cell.el).attr("colspan", visibles.length);
          rowThis.prepend(cell.el);
          _this.dataGrid.currentVue._columns[column.property].cells.push(cell);
        }
      });

      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }

      if (isSelected) {
        this._selectRow();
      }
    }
    return this;
  },

  _toggleColumn: function() {
    var _this = this;

    if (!STR.isBlank(this.dataGrid.model.groupeByColumn)) {
      var keys = _.keys(this.dataGrid.currentVue._columns);
      var visibles = _.filter(keys, function(columKey) { // Get the number of visible columns
        var visible = _this.dataGrid.currentVue._columns[columKey].visible;

        if (columKey.indexOf("phx-") >= 0) {
          visible = false;
        }
        return visible;
      });
      var cells = this.dataGrid.currentVue._columns[this.dataGrid.model.groupeByColumn].cells;

      _.forEach(cells, function(cell) {
        if (cell.$el.is('[colspan]')) {
          cell.$el.attr("colspan", visibles.length);
        }
      });
    }
  }
});
