import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Population model
 */
export class CWPopulationGenerationModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "nature": "",
      "desc": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/population/generee/automatiquement";
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.ident)) {
      this.id = response.ident;
    }
    return response;
  }
}