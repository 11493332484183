import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Model to read infos complementaires du recap
 */
export class CWInfoCompRecap extends CWBaseModel {
  id: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.id = "";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/infocomp/" + encodeURIComponent(this.id) + "?piecejointe=true";
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "code": "",
      "recapitulatif": "",
      "libelle": "",
      "datedeb": "",
      "datefin": "",
      "commentaire_infocomp": "",
      "indicateurpj": false,
      "matricule": null,
      "evenement": "",
      "etat": "",
      "piecesjointes": null,
      "infocomp": null
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { infocomp?: any } = {};
    //Infocomp
    _.each(attrs.infocomp, (item: any) => {
      if (CWSTR.isBlank(item.valeur)) {
        if (item.oblig) {
          if (CWSTR.isBlank(errors.infocomp)) {
            errors.infocomp = {};
          }
          errors.infocomp[item.code] = {};
          errors.infocomp[item.code].valeur = i18n.t('common:required', { "0": item.libelle });
        }
      } else {
        let errorMsg = null;
        let errorNum = null;
        switch (item.type) {
          case "D":
            errorMsg = CWTYPE.DATE.validate(item.valeur);
            if (!CWSTR.isBlank(errorMsg)) {
              if (CWSTR.isBlank(errors.infocomp)) {
                errors.infocomp = {};
              }
              errors.infocomp[item.code] = {};
              errors.infocomp[item.code].valeur = errorMsg;
            } else if ((!CWSTR.isBlank(item.datemin) && item.valeur < item.datemin) || (!CWSTR.isBlank(item.datemax) && item.valeur > item.datemax)) {
              if (CWSTR.isBlank(errors.infocomp)) {
                errors.infocomp = {};
              }
              errors.infocomp[item.code] = {};
              errors.infocomp[item.code].valeur = i18n.t('messages:GT_1040', { "1": item.libelle, "2": CWTYPE.DATE.format(item.datemin), "3": CWTYPE.DATE.format(item.datemax) });
            }
            break;
          case "N":
            if (!CWSTR.isBlank(item.masque)) {
              if (!CWSTR.isBlank(CWTYPE.CUSTOM.validate(item.valeur, item.nummin, item.nummax))) {
                if (CWSTR.isBlank(errors.infocomp)) {
                  errors.infocomp = {};
                }
                errors.infocomp[item.code] = {};
                errors.infocomp[item.code].valeur = i18n.t('messages:GT_1041', { "1": item.libelle, "2": item.nummin, "3": item.nummax });
              }
            } else {
              errorNum = CWTYPE.LONG.validate(item.valeur, item.nummin, item.nummax);
              if (!CWSTR.isBlank(errorNum)) {
                if (CWSTR.isBlank(errors.infocomp)) {
                  errors.infocomp = {};
                }
                errors.infocomp[item.code] = {};
                errors.infocomp[item.code].valeur = i18n.t('messages:GT_1041', { "1": item.libelle, "2": item.nummin, "3": item.nummax });
              }
            }
            break;
        }
      }
    });

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}
