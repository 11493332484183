import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWDialogView } from '../cwDialog.view';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils/utils.js';

export enum CWDialogPopupType {
  AVEC_SAISIE, INFORMATION, CONFIRMATION
}

export class CWDialogPopupView<TView extends Backbone.View = Backbone.View> extends CWDialogView<TView> {

  popupType: CWDialogPopupType;
  notIconClose: boolean;
  refreshParent: boolean;
  textContent: string;
  functionOptionsCreate: () => any;
  functionOptionsOpen: () => any;
  functionOptionsClose: (event?: JQuery.TriggeredEvent, options?: { [key: string]: any }) => any;
  showIconsMinMax: boolean;
  retablirDraggable: boolean;//pour minimiser/maximiser
  isPopupMinimise: boolean;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.className = options.className || "phx-Dialog";
    options.dialogClass = options.dialogClass || "c-cwDialogPopup cw-style";
    options.position = options.position || { my: "center", at: "center", of: window };
    /* The options below are not modifiable */
    options.resizable = options.resizable || false;
    options.draggable = options.draggable || true;
    super(options);
    this.refreshParent = true;
    this.notIconClose = false;
    this.showIconsMinMax = false;
    this.retablirDraggable = false;
    this.isPopupMinimise = false;
    this.popupType = CWDialogPopupType.AVEC_SAISIE;
    if (options) {
      if (options.popupType) {
        this.popupType = options.popupType;
      }
      if (!CWSTR.isBlank(options.textContent)) {
        this.textContent = options.textContent;
      }
      if (!CWSTR.isBlank(options.create)) {
        this.functionOptionsCreate = options.create;
      }
      if (!CWSTR.isBlank(options.open)) {
        this.functionOptionsOpen = options.open;
      }
      if (!CWSTR.isBlank(options.close)) {
        this.functionOptionsClose = options.close;
      }
      if (_.isBoolean(options.notIconClose)) {
        this.notIconClose = options.notIconClose;
      }
      if (!CWSTR.isBlank(options.resizable)) {
        this.resizable = options.resizable;
      }
      if (!CWSTR.isBlank(options.draggable)) {
        this.draggable = options.draggable;
      }
      if (_.isBoolean(options.showIconsMinMax)) {
        this.showIconsMinMax = options.showIconsMinMax;
      }
    }
    this.listenTo(this.model, "dlg:maximise", this._minimiserMaximiserDialog);//pour retablir la pop-up depuis autre localisation
  }

  render(): CWDialogPopupView<TView> {
    if (this.popupType === CWDialogPopupType.AVEC_SAISIE) {
      this.dialogClass += " c-cwDialogPopup--avecSaisie";
    }

    if (this.popupType === CWDialogPopupType.INFORMATION || this.popupType === CWDialogPopupType.CONFIRMATION) {
      const $p = $("<div>")
        .addClass("c-cwDialogPopup__body")
        .addClass("cw-texteNormal")
        .html(this.textContent);
      this.$el.append($p);
    }

    if (!CWSTR.isBlank(this.appendTo)) {
      if (this.appendTo instanceof HTMLDivElement) {
        $(this.appendTo).prepend(this.$el);
      } else {
        this.appendTo.prepend(this.$el);
      }
    }

    // Fixes "vh" unit issue with JQuery
    let dialogHeight;
    if (typeof this.height === "string" && this.height.indexOf("vh") > -1) {
      dialogHeight = "auto";
    } else {
      dialogHeight = this.height;
    }

    this.dialog = $(this.el).dialog({
      modal: this.modal,
      height: dialogHeight,
      width: this.width,
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      position: this.position,
      appendTo: this.appendTo as any,
      closeText: i18n.t('common:close'),
      dialogClass: this.dialogClass,
      maxHeight: (this.maxHeight as any),
      maxWidth: this.maxWidth,
      create: () => {
        const $parent = this.$el.parent();
        // Fix jQuery UI issue. When width is auto, minWidth is not applied.
        if (!CWSTR.isBlank(this.minWidth) && this.width === "auto") {
          $parent.css("minWidth", this.minWidth);
        }
        $parent.removeClass("c-cwDialogPopup").attr("class", "c-cwDialogPopup " + $parent.attr("class"));
        if ($parent.find(".ui-dialog-titlebar")) {
          $parent.find(".ui-dialog-titlebar").attr("class", "c-cwDialogPopup " + $parent.find(".ui-dialog-titlebar").attr("class"));
        }

        if ($parent.find(".ui-dialog-title").length > 0) {
          $parent.find(".ui-dialog-title").attr("class", "c-cwDialogPopup " + $parent.find(".ui-dialog-title").attr("class")).addClass("cw-texteTitrePopup cw-style");
        }
        if ($parent.find(".ui-dialog-titlebar .ui-button").length > 0) {
          $parent.find(".ui-dialog-titlebar .ui-button").remove();
        }
        if ($parent.find(".ui-dialog-titlebar").length > 0 && !this.notIconClose) {
          if (this.showIconsMinMax) {
            const $lDivIcons = $("<div class='iconsDialogPopup'>");
            const $lDivMinMax = $("<div class='iconsMinMax'>");

            $lDivMinMax.append(UTILS.getSVGIcon("fenetre_mini", "c-cwDialogPopup__minimise ui-dialog-titlebar-minimise", 16, i18n.t('common:minimiser')))
            $lDivMinMax.append(UTILS.getSVGIcon("fenetre_maxi", "c-cwDialogPopup__maximise ui-dialog-titlebar-maximise d-none", 16, i18n.t('common:maximiser')));
            $lDivIcons.append($lDivMinMax);
            $lDivIcons.append($(UTILS.getSVGIcon("croix-epaisse", "c-cwDialogPopup__close ui-dialog-titlebar-close", 16, i18n.t('common:close'))));
            $parent.find(".ui-dialog-titlebar").append($lDivIcons);
            $parent.find(".ui-dialog-titlebar").removeClass("ui-helper-clearfix");
            $lDivIcons.find(".ui-dialog-titlebar-close").on("click", this._closeVolet.bind(this));
            $lDivIcons.find(".ui-dialog-titlebar-close").on("keyup", this._notifyEdition.bind(this));
            $lDivMinMax.find(".ui-dialog-titlebar-minimise").on("click", this._minimiserMaximiserDialog.bind(this));
            $lDivMinMax.find(".ui-dialog-titlebar-maximise").on("click", this._minimiserMaximiserDialog.bind(this));
            $lDivIcons.find(".ui-dialog-titlebar-close svg").attr("tabindex", "0")
          } else {
            $parent.find(".ui-dialog-titlebar").append($(UTILS.getSVGIcon("croix-epaisse", "c-cwDialogPopup__close ui-dialog-titlebar-close", 16, i18n.t('common:close'))));
            $parent.find(".ui-dialog-titlebar").removeClass("ui-helper-clearfix");
            $parent.find(".ui-dialog-titlebar-close").on("click", this._closeVolet.bind(this));
            $parent.find(".ui-dialog-titlebar-close").on("keyup", this._notifyEdition.bind(this));
            $parent.find(".ui-dialog-titlebar-close svg").attr("tabindex", "0")
          }
        }
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");

        // Update title manually on creation: 
        // this way if the title has HTML it's properly parsed.
        $parent.find(".ui-dialog-title").html(this.title);

        if (this.functionOptionsCreate) {
          this.functionOptionsCreate();
        }
      },
      resizable: this.resizable,
      draggable: this.draggable
    });
    if (!CWSTR.isBlank(this.appendTo) && this.draggable) {
      this.dialog.dialog("widget").draggable("option", "containment", this.appendTo);
    }
    return this;
  }

  _closeVolet(event: JQuery.TriggeredEvent, ui: any): void {
    this.refreshParent = false;
    this.model.trigger("dlg:close", event, ui);
  }

  open(callback?: (argument?: any) => any): void {
    this.refreshParent = true;
    CWDialogView.prototype.open.call(this, callback);
    if (this.functionOptionsOpen) {
      this.functionOptionsOpen();
    }

    this.setPosition(this.position, "popup:ready");
  }

  close(event?: JQuery.TriggeredEvent, options?: { [key: string]: any }): void {
    if (_.isEmpty(options) || options?.refreshPlanAll !== false) {
      CWDialogView.prototype.close.call(this);
      if (this.functionOptionsClose) {
        this.functionOptionsClose(event, options);
      }
    } else {//options.refreshPlanAll === false
      if (this.functionOptionsClose) {
        this.functionOptionsClose(event, options);
      }
      this.functionOptionsClose = null;//ne pas faire pétitions en double
      CWDialogView.prototype.close.call(this);
    }
  }

  hideCloseButton(): void {
    this.notIconClose = true;
    CWDialogView.prototype.hideCloseButton.call(this);
  }

  _minimiserMaximiserDialog(): void {
    const lPos = this.$el.parent().find(".ui-dialog-titlebar-minimise");

    if (CWSTR.isBlank(lPos.data("minimize"))) {
      lPos.data("minimize", this.$el.parent().height());
      this.$el.parent().height(37);//le title uniquement
      this.$el.parent().addClass("minimizePopup");
      this.$el.addClass("d-none");
      this.$el.parent().find(".ui-dialog-titlebar-maximise").removeClass("d-none");
      lPos.addClass("d-none");
      if (this.getValueDraggable()) {
        this.setValueDraggable(false);
        this.retablirDraggable = true;
      }
      this.isPopupMinimise = true;
      //la dernier action
      this.model.trigger("popup:minimise");//pour être utilisé dans autre localisation
    } else {
      const lPosMax = this.$el.parent().find(".ui-dialog-titlebar-maximise");

      lPosMax.addClass("d-none");
      lPos.removeClass("d-none");
      this.$el.removeClass("d-none");
      this.$el.parent().removeClass("minimizePopup");
      this.$el.parent().height(lPos.data("minimize"));
      lPos.data("minimize", null);
      if (this.retablirDraggable) {
        this.retablirDraggable = false;
        this.setValueDraggable(true);
        if (!CWSTR.isBlank(this.appendTo)) {
          this.dialog.dialog("widget").draggable("option", "containment", this.appendTo);
        }
      }
      this.isPopupMinimise = false;
      //la dernier action
      this.model.trigger("popup:maximise");//pour être utilisé dans autre localisation et different de dlg:maximise(d'autre lieu-> dialogPopup)
    }
  }

  isMinimise(): boolean {
    return this.isPopupMinimise;
  }

  public _notifyEdition(event: JQuery.TriggeredEvent, ui: JQuery.UIEventBase): void {
    if (event.type === "keyup") {
      const key = event.which || event.keyCode;

      if (key === 10 || key === 13 || key === 32) {
        this._closeVolet(event, ui);
      }
    }
  }
}
