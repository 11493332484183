import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWEvenementModelColl } from './cwListerEvtGen.collection';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Lister Evenement Generique Model
 */
export class CWListerEvtGenModel extends CWBaseModel {//CWBaseGridModel {

  public selectedProfil: { [key: string]: any };
  public profilErrors: string;
  public evtEtatValid: string;
  public nbEvtEtatValid: number;
  public saidefo: string;
  public sairepet: string;
  public soumiswkf: string;
  public intitule: string;
  public code: string;
  public collection: CWEvenementModelColl<CWListerEvtGenModel>;
  public individualComment: boolean;

  defaults(): { [key: string]: any } {
    return {
      "code": "",
      "evenement": "",
      "iddemande": "",
      "matricule": null,
      "matricaux": "",
      "nom": "",
      "prenom": "",
      "dmaj": "",
      "datedeb": null,
      "datefin": "",
      "heuredeb": "",
      "heurefin": "",
      "caraevt": [{
        "libelle": "",
        "valeur": "",
        "restevt": ""
      }],
      "caracol": [{
        "idx": null,
        "valeur": ""
      }],
      "statut": {
        "code": "",
        "libelle": ""
      },
      "commentaire": "",
      "indicateurpj": false,
      "piecesjointes": [],
      "profils": {
        "code": "RESP",
        "libelle": "Responsable"
      }
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/evtgen";
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.evenement)) {
      response.id = response.evenement;
    }
    return response;
  }

  validate(): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    if (CWSTR.isBlank(this.selectedProfil) || String(this.selectedProfil.code) === "0") {
      const profilError = i18n.t('common:required', { "0": i18n.t('common:listerevtgen.role') });
      const profilId = 'comboProfil_' + this.cid;

      errors[profilId] = profilError;
      this.profilErrors = profilError;
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }
}