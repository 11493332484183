import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Liste de sélection des périodes de récapitulatif
 */
export class CWPeriodesRecap extends CWBaseModel {
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    response.code = response.datedeb + "_" + response.datefin;
    response.id = response.code;
    response.libelle =
      CWTYPE.DATE.format(response.datedeb, CWTYPE._getFormatByCode("DATE_A")) +
      " " +
      i18n.t('common:gererrecapitulatifs.dateTo') +
      " " +
      CWTYPE.DATE.format(response.datefin, CWTYPE._getFormatByCode("DATE_A"));
    return response;
  }
}
