import _ from 'underscore';
import TPLListerevenementsGeneralView from '../generalView.tpl.html';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWButtonBarView } from 'src/core/controls/cwButtonBar.view';
import { CWComboBoxView2 } from 'src/core/components/combo/cwComboBoxView2';
import { CWEvenementModelColl } from '../models/cwListerEvtGen.collection';
import { CWFilterBaseViewWithObligatoryFields } from 'src/core/components/filter/cwFilterBaseWithObligatoryFields.view';
import { CWFilterListerEvtGen } from '../models/cwFilterListerEvtGen.model';
import { CWFilterView } from 'src/core/components/filter/cwFilter.view';
import { CWGererEvtGenView } from '../../../gerer/gererevtgen/views/cwGererEvtGen.view';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWListerEvetGenHistoriqueColl } from '../models/cwListerEvetGenHistorique.collection';
import { CWListerEvetGenOverloadedBaseModel } from '../models/cwListerEvtGenOverloadedBaseModel.model';
import { CWListerEvtCustomBar } from './cwListerEvtCustomBar.view';
import { CWListerEvtFilterContentView } from './cwListerEvtFilterContent.view';
import { CWListerEvtFormView } from '../views_form/cwListerEvtForm.view';
import { CWListerEvtGenModel } from '../models/cwListerEvtGen.model';
import { CWListerEvtGenWorkflow } from '../models/cwListerEvtGen.workflow';
import { CWListerEvtTableView } from '../views_table/cwListerEvtTable.view';
import { CWMenuGridModel } from 'src/core/grids/menugrid/cwMenuGrid.model';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { CWVoletView } from 'src/core/components/dialog/volet/cwVolet.view';
import { CWWKF } from 'src/utils/cwWkf';
import { i18n } from 'src/i18n';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'src/utils/utils';
import { View, ViewOptions } from 'Backbone';

export interface CWListerEvenementsGeneralViewOptions extends ViewOptions<CWReadOnlyModel> {
  parentView?: View;
  context?: { [key: string]: any };
  workflow?: CWListerEvtGenWorkflow;
  parentWorkflow?: any;
}

/**
 * GeneralView of lister évènements génériques
 */
export class CWListerEvenementsGeneralView extends View<CWReadOnlyModel> {

  public COLLABORATEUR: string;
  public RESPONSABLE: string;
  public ASCENDANT: string;
  public DESCENDANT: string;
  // Styles for the table
  public styleLabel: string;
  public styleDate: string;
  public styleHour: string;
  public context: { [key: string]: any };
  public workflow: CWListerEvtGenWorkflow;
  public parentWorkflow: any;
  public tableMode: string;
  public tableModel: CWMenuGridModel<CWEvenementModelColl, CWListerEvtGenModel>;
  public historiqueColl: CWListerEvetGenHistoriqueColl;
  public tableCellRenderers: { [key: string]: (model: CWListerEvtGenModel) => string | JQuery | HTMLElement };
  public selectedTypo: { [key: string]: any };
  public form: CWListerEvtFormView | CWListerEvtTableView;
  public filter: CWFilterBaseViewWithObligatoryFields<CWFilterListerEvtGen, CWListerEvtFilterContentView>;
  public btnBar: CWButtonBarView;
  public customBar: CWListerEvtCustomBar;
  public wkfEvenements: string;
  public wkfDemandes: any;
  public model: CWListerEvetGenOverloadedBaseModel;
  public volet: CWVoletView;


  constructor(options?: CWListerEvenementsGeneralViewOptions) {
    options = options || {};
    super(options);
    /**
     * Context
     * ctxUtilisateur [Collaborateur | Responsable]
     *
     * ctxEcran
     *
     * ctxHabilitation
     * ctxHabilitation.HabilitationAcces
     * ctxHabilitation.HabilitationValidation
     * ctxHabilitation.HabilitationGestion
     *
     * ctxTypologieEvenement
     *
     * ctxCollab
     * ctxCollab.Matricule
     * ctxCollab.MatriculeAux
     * ctxCollab.Nom
     * ctxCollab.Prenom
     *
     * ctxGestionCollective [true|false]
     * ctxVisibilite [true|false]
     * ctxGestion [true|false]
     * ctxValidation [true|false]
     * ctxValideur [true|false]
     * ctxFiltreApplique  // Acceptées, Refusées, En cours, Hors contexte de workflow
     * «Evénements génériques acceptés» : [true|false]
     * «Evénements génériques refusés» : [true|false]
     * «Evénements génériques demandés/en cours de validation» : [true|false]
     * «Evénements génériques hors contexte de workflow» : [true|false]
     * ctxPeriode
     * ctxPeriode.datedeb
     * ctxPeriode.datefin
     *
     * ctxTriDate [Ascendant | Descendant]
     *
     * ctxPieceJointe [true|false]
     *
     */
    this.COLLABORATEUR = "Collaborateur";
    this.RESPONSABLE = "Responsable";
    this.ASCENDANT = "Ascendant";
    this.DESCENDANT = "Descendant";
    // Styles for the table
    this.styleLabel = "phx-label-style";
    this.styleDate = "phx-bold-input-style";
    this.styleHour = "phx-input-style";
    this.context = {};
    if (options && options.context) {
      this.context = options.context;
    }
    if (options && options.parentWorkflow) {
      this.parentWorkflow = options.parentWorkflow;
    }
    if (options && options.workflow) {
      this.workflow = options.workflow;
      this.workflow.updateOptions(options);
      this.listenTo(this.workflow, "manageTable", () => { this.manageGridDisplay() });
    }
    else {
      this.workflow = new CWListerEvtGenWorkflow(options);
      this.listenTo(this.workflow, "manageTable", () => { this.manageGridDisplay() });
    }
    this.template = TPLListerevenementsGeneralView;
    this.model = new CWListerEvetGenOverloadedBaseModel();
    this.tableMode = "SIMPLE";
    this.tableModel = new CWMenuGridModel<CWEvenementModelColl, CWListerEvtGenModel>({ coll: new CWEvenementModelColl() });
    this.tableModel.coll.on("reset", this.filterByTypo, this);
    this.tableModel.coll.nbEvtEtatValid = 0;
    this.tableModel.coll.nbEvtEtatA = 0;
    this.tableModel.coll.nbEvtEtatR = 0;
    this.historiqueColl = new CWListerEvetGenHistoriqueColl();
    this.historiqueColl.setHabContext(this.workflow.getHabContext());
    this.workflow.on("manageTitle", this.manageTitle, this);
    this._initializeViews();
    this.tableCellRenderers = this._getTableCellRenderers();
    this.volet = this._creationVolet();
    this.workflow.volet = this.volet;
  }

  filterByTypo(): void {
    const evtColl = this.form.workflow.evenementsColl;

    if (evtColl) {
      this.tableModel.coll.reset(evtColl.where({ code: this.selectedTypo.code }), { silent: true });
    }
  }

  /**
   * Initializes views and their models
   */
  _initializeViews(): void {
    this.filter = this._initFilter();
    this.workflow.filterModel = this.filter.model;
    this.btnBar = new CWButtonBarView({
      isDisplayOnRight: true,
      isHideOnDisabled: true,
      btnOrder: {
        "save": 2,
        "revert": 1
      }
    });
    this.workflow.btnBarModel = this.btnBar.model;
    this.customBar = new CWListerEvtCustomBar({ parentView: this });
    this.listenTo(this.workflow, "collectionParams:cleaned", (notIsButton?: boolean, origine?: string) => {
      this.filter.model.trigger("clean:filter", notIsButton, origine);
    });
  }

  _initFilter(): CWFilterBaseViewWithObligatoryFields<CWFilterListerEvtGen, CWListerEvtFilterContentView> {
    const part1View = new CWListerEvtFilterContentView({ workflow: this.workflow });
    const filter = new CWFilterBaseViewWithObligatoryFields<CWFilterListerEvtGen, CWListerEvtFilterContentView>({ id: "listerevtgen_filter", viewPart1: part1View });

    return filter;
  }

  setContext(context: { [key: string]: any }): void {
    this.context = context;
    this.workflow.context = context;
    this.filter.clearFilterFields();
    if (this.form && this.form.setContext) {
      this.form.setContext(context);
    } else if (this.context.ctxUtilisateur === this.RESPONSABLE && this.context.ctxValidation) {
      if (!_.isEmpty(this.form)) {
        this.form.off();
        this.form.remove();
      }
      this.form = new CWListerEvtFormView({ context: this.context, parent: this, workflow: this.workflow });
    } else {
      if (!_.isEmpty(this.form)) {
        this.form.off();
        this.form.remove();
      }
      this.form = new CWListerEvtTableView({ context: this.context, parent: this, workflow: this.workflow });
    }
    this.workflow.progressBarModel = this.form.model.get("progressBarModel");
    if (this.workflow.progressBarModel) {
      this.workflow.progressBarModel.off("btn:click");
      this.workflow.progressBarModel.on("btn:click", this._progressBarBtnAction, this);
    }
    this.form.comboSearchProfils = this.filter.viewPart1.comboSearchProfils;
    //mis à jour l'information du objet habContext du workflow
    if (CWSTR.isBlank(this.workflow.getHabContext().get("onglet")) && !CWSTR.isBlank(this.context.ctxEcran)) {
      this.workflow.updateHabContext({ "onglet": this.context.ctxEcran });
    }
    if (CWSTR.isBlank(this.workflow.getHabContext().get("foncCour")) && this.context.ctxHabilitation && !CWSTR.isBlank(this.context.ctxHabilitation.HabilitationAcces)) {
      this.workflow.updateHabContext({ "foncCour": this.context.ctxHabilitation.HabilitationAcces });
    }
    //mis à jour l'information du objet habContext du filtre
    this.filter.viewPart1._updateHabilitationCombo();
    this.workflow.formModel = this.form.model;
    this.workflow.tableModel = this.tableModel;
    this.form.workflow.btnBarModel = this.workflow.btnBarModel;
    this.form.workflow.headerModel = this.workflow.headerModel;
    this.form.workflow.filterModel = this.workflow.filterModel;
    //errors
    this.model.objErrors = this.workflow.objErrors;
    this.model.nObjErrors = this.workflow.nObjErrors;
    //Events
    this.stopListening(this.tableModel.coll, "sync");
    this.listenTo(this.tableModel.coll, "sync", (): void => {
      this.manageTitle();
    })
  }

  /**
   * Function prepared to manage also pause and play action.
   */
  _progressBarBtnAction(action: string): void {
    if (action === "interrompre" && this.workflow.formModel.get("progressBarModel")) {
      CWSTR.setElValue(this.workflow.formModel.get("progressBarModel"), "stop", true);
    }
  }

  render(): CWListerEvenementsGeneralView {
    const json = {};

    $(this.el).append(this.template(json));
    $(this.el).find(".tableFilter_DEvenements").html(this.filter.render().el);
    if (this.context.ctxUtilisateur === this.RESPONSABLE && this.context.ctxGestionCollective) {
      this.$el.find(".listerevenements-Btn").html(this.btnBar.render().el);
      //Change the size of column
      this.filter.$el.find(".filter-simple-fields").removeClass("col-8");
      this.filter.$el.find(".filter-simple-fields").addClass("col-9");
      this.filter.$el.find(".c-button-filter").removeClass("col-4");
      this.filter.$el.find(".c-button-filter").addClass("col-3");
    } else {
      if (this.context.ctxUtilisateur === this.COLLABORATEUR) {
        this.filter.$el.find(".filter-simple-fields").removeClass("col-8");
        this.filter.$el.find(".filter-simple-fields").addClass("col-12");

        this.filter.$el.find(".c-button-filter").removeClass("col-4");
        this.filter.$el.find(".c-button-filter").addClass("col-12");
      }
    }
    if (this.context.ctxUtilisateur === this.RESPONSABLE) {
      this.filter.$el.find(".c-button-filter").addClass("justify-content-end");
    }
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:copy");
    this.$el.find(".listerevenements_generalView").append(this.form.el);
    this.form.render();
    this.$el.css("width", "100%");
    this.$el.css("height", "100%");
    this.$el.addClass("d-flex flex-column");
    this.$el.find(".table_devenements").parent().addClass("overflowHidden");
    if (!this.context.ctxGestionCollective) {
      $(this.filter.el).find(".collabContainer").hide();
    }
    if (this.context.ctxUtilisateur === this.COLLABORATEUR) {
      $(this.el).find(".tableMode").hide();
    }
    if (!this.context.ctxVisuStatut) {
      $(this.filter.el).find(".statutContainer").hide();
    }
    if (this.context.ctxEcran === "suivicollab") {
      this.manageTitle();
      //Render the custom bar under the title
      this.$el.find(".radios_container").append(this.customBar.$el);
      //Render the btn bar in the new site
      this.$el.find(".btnBarContainer").append(this.btnBar.$el);
    } else {
      this.$el.find(".cw-fieldset__situationPanel__title").remove();
    }
    if (this.filter.model.get("moreCriterias") === 1) {
      this.filter.$el.find(".filter-more").addClass("opened");
    } else {
      this.filter.$el.find(".filter-more").removeClass("opened");
    }
    return this;
  }

  private manageGridDisplay(): void {
    if (this.workflow.gridCanBeDisplayed()) {
      this._showGrid();
    } else {
      this._hideGrid();
    }
  }

  private _showGrid(): void {
    $(this.el).find(".tableDemandes").show();
  }

  private _hideGrid(): void {
    $(this.el).find(".tableDemandes").hide();
  }

  _findError(objCode: string): { code: string; libelle: string } {
    let res: { code: string; libelle: string } = null;

    _.each(this.workflow.formModel.objErrors, (obj: { code: string; libelle: string }) => {
      if (obj.code === objCode) {
        res = obj;
      }
    });
    return res;
  }

  _buildMessageError(objError: { [key: string]: any }): string {
    const styleError = "ui-phx-msg-erreur-validation";
    let htmlError = "";

    if (objError.type === "A") {
      htmlError += i18n.t('messages:GT_1222') + ":";
    } else if (objError.type === "R") {
      htmlError += i18n.t('messages:GT_1223') + ":";
    }
    if (CWSTR.isBlank(objError.message)) {
      htmlError += i18n.t('messages:GT_1224');
    } else {
      htmlError += objError.message;
    }
    htmlError = this._textToStyle(htmlError, styleError);
    return htmlError;
  }

  _getTableCellRenderers(): { [key: string]: (model: CWListerEvtGenModel) => string | JQuery } {
    const cellRenderers: { [key: string]: (model: CWListerEvtGenModel) => string | JQuery } = {};

    cellRenderers['role'] = (model: CWListerEvtGenModel): string | JQuery => {
      let profils = model.get("profils");

      if (profils && profils.length === 1) {
        profils = profils[0];
      }
      if (profils && !CWSTR.isBlank(profils.libelle)) {
        model.selectedProfil = profils;
        return profils.libelle;
      } else if (profils && profils.length > 1) {
        const comboName = "comboProfil_" + model.cid;
        const combo = new CWComboBoxView2({
          enum: profils,
          name: comboName,
          width: "150"
        });
        const label = $("<label>").attr("for", comboName);
        const container = $("<div>");
        let item = null;

        //render combo and error or only combo.
        if (!CWSTR.isBlank(model.profilErrors)) {
          label.attr("title", model.profilErrors).addClass("griderror");
          combo.name = combo.name + " ui-state-error";
        }
        container.append(label).append(combo.render().el);
        if (model.selectedProfil && String(model.selectedProfil.code) !== "0") {
          item = { code: model.selectedProfil.code, libelle: model.selectedProfil.libelle };
        }
        combo.setItem(item);
        return container;
      }
      return "";
    };
    cellRenderers['collaborateur'] = (model: CWListerEvtGenModel): string | JQuery => {
      const paramIdCollab = this.workflow.pDiversIdCollabModel.get("valeur");
      let matricule = "";
      let completeCollab = "";
      let ligne1: string = null;

      if (paramIdCollab === "matric_aux") {
        matricule = model.get("matricaux");
      } else {
        matricule = model.get("matricule");
      }
      ligne1 = model.get("nom").toUpperCase() + " " + model.get("prenom") + " (" + matricule + ")";
      completeCollab = ligne1;
      if (CWHABILITATION.canView("RES_GCOLLAB.G") && CWHABILITATION.canView("RES_FICHE.V")) {
        const url = CWSTR.isBlank(model.get("datedeb")) ? "suivicollab/" + model.get("matricule") : "suivicollab/" + model.get("matricule") + "/" + model.get("datedeb");

        return "<a class='suivicollab-link " + url + "'>" + completeCollab + "</a>";
      } else {
        return "<span>" + completeCollab + "</span>";
      }
    };
    cellRenderers['statut'] = (model: CWListerEvtGenModel): string | JQuery => {
      let demande = "";
      let evenement = "";
      const stat = model.get("statut").code;
      const style = this._getWorkflowStyle(stat) + " txtStatut";
      const spanStatus = $("<span>");

      if (String(model.get("iddemande")) === "0") {
        evenement = model.get("evenement");
      } else {
        demande = model.get("iddemande");
      }
      spanStatus.addClass("txtStatut");
      spanStatus.attr("demande", demande);
      spanStatus.attr("evenement", evenement);
      spanStatus.addClass(style);
      spanStatus.html(model.get("statut").libelle);
      return spanStatus;
    };
    cellRenderers["periode"] = (model: CWListerEvtGenModel): string | JQuery => {
      let ligne1 = "<a class='evtgen-link' data-id='" + UTILS.escapeJQueryString(model.get("evenement")) + "'>";
      let ligneError = "";
      let result = "";

      if (!CWSTR.isBlank(model.get("datedeb")) && !CWSTR.isBlank(model.get("datefin"))) {
        const datedeb = model.get("datedeb");
        let datedebFormatted = CWTYPE.DATE.format(datedeb, CWTYPE._getFormatByCode("DATE_A"));
        const datefin = model.get("datefin");
        let datefinFormatted = CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode("DATE_A"));

        datedebFormatted = this._textToStyle(datedebFormatted, this.styleDate);
        datefinFormatted = this._textToStyle(datefinFormatted, this.styleDate);
        if (model.get("typesaisie") === "D" || (CWSTR.isBlank(model.get("typesaisie")) && datedeb === datefin)) {
          ligne1 += i18n.t('common:listerevtgen.periode_date', { "0": datedebFormatted, interpolation: { escapeValue: false } });
        } else if (model.get("typesaisie") === "R" || (CWSTR.isBlank(model.get("typesaisie")) && datedeb !== datefin)) {
          ligne1 += i18n.t('common:listerevtgen.periode_rep', { "0": datedebFormatted, "1": datefinFormatted, interpolation: { escapeValue: false } });
        } else if (model.get("typesaisie") === "P") {
          if (model.get("datedeb") === model.get("datefin")) {
            ligne1 += i18n.t('common:listerevtgen.periode_date', { "0": datedebFormatted, interpolation: { escapeValue: false } });
          } else {
            ligne1 += i18n.t('common:listerevtgen.periode_rep', { "0": datedebFormatted, "1": datefinFormatted, interpolation: { escapeValue: false } });
          }
        }
      }
      if (!CWSTR.isBlank(model.get("heuredeb")) && !CWSTR.isBlank(model.get("heurefin"))) {
        let heuredeb = null;
        let heurefin = null;
        let heureDiff = null;

        if (model.get("modesaisie") === "PLH") {
          heuredeb = CWTYPE.HOUR_MINUTE_NIGHTLY.format(model.get("heuredeb"));
          heuredeb = this._textToStyle(heuredeb, this.styleHour);
          heurefin = CWTYPE.HOUR_MINUTE_NIGHTLY.format(model.get("heurefin"));
          heurefin = this._textToStyle(heurefin, this.styleHour);
          ligne1 += " ," + i18n.t('common:listerevtgen.heures_a', { "0": heuredeb, "1": heurefin, interpolation: { escapeValue: false } });
        } else {
          if (model.get("heuredeb") !== 0 || model.get("heurefin") !== 0) {
            heuredeb = CWTYPE.HOUR_MINUTE_NIGHTLY.format(model.get("heuredeb"));
            heuredeb = this._textToStyle(heuredeb, this.styleHour);
            heurefin = CWTYPE.HOUR_MINUTE_NIGHTLY.format(model.get("heurefin"));
            heurefin = this._textToStyle(heurefin, this.styleHour);
            ligne1 += " ," + i18n.t('common:listerevtgen.heures_a', { "0": heuredeb, "1": heurefin, interpolation: { escapeValue: false } });
          }
        }
        heureDiff = null;
        if (CWSTR.isBlank(model.get("duree")) && heuredeb !== heurefin) {
          heureDiff = CWTYPE.HOUR_MINUTE.diff(model.get("heurefin"), model.get("heuredeb"));
        } else if (!CWSTR.isBlank(model.get("duree"))) {
          heureDiff = model.get("duree");
        }
        if (!CWSTR.isBlank(heureDiff) && heureDiff > 0) {
          let duree = CWTYPE.DURATION.HOUR_MINUTE.format(heureDiff);

          duree = this._textToStyle(duree.trim(), this.styleHour);
          ligne1 += " (" + duree + ")";
        }
      }
      _.each(model.get("caraevt"), (value: { code: string; libelle: string; restevt: string; valeur: string }): void => {
        const caracData = this.tableModel.coll._getTypologieCaracteristique(model.get("code"), value.code);

        if (value.restevt === "V" && !CWSTR.isBlank(value.valeur)) {
          //Customer 192798 - is date?
          if (caracData.type === "D" && value.valeur !== null) {
            ligne1 += ", " + CWTYPE.DATE.format(value.valeur, CWTYPE._getFormatByCode("DATE"));
          } else {
            if (!CWSTR.isBlank(caracData.masque)) {
              ligne1 += ", " + CWTYPE.MASK.applyFormatMask(value.valeur, caracData.masque);
            } else {
              ligne1 += ", " + value.valeur;
            }
          }
        } else if (value.restevt === "IV" && !CWSTR.isBlank(value.valeur)) {
          if (caracData.type === "D" && value.valeur !== null) {
            ligne1 += ", " + CWTYPE.DATE.format(value.valeur, CWTYPE._getFormatByCode("DATE"));
          } else {
            if (!CWSTR.isBlank(caracData.masque)) {
              ligne1 += ", " + value.libelle + " " + CWTYPE.MASK.applyFormatMask(value.valeur, caracData.masque);
            } else {
              ligne1 += ", " + value.libelle + " " + value.valeur;
            }
          }
        }
      });
      ligne1 += "</a>";
      if (model.get("etat") === "X") {
        ligne1 += "<span class='ui-phx-demande-suppression'> " + i18n.t('common:listerevtgen.demandeSuppressionRegularisation') + "</span>";
      }
      // Errors:
      if (this.workflow.formModel && this.workflow.formModel.objErrors && this.workflow.formModel.objErrors.length > 0) {
        const error = this._findError(model.get("id"));

        if (!CWSTR.isBlank(error)) {
          ligneError = this._buildMessageError(error);
        }
      }
      if (!CWSTR.isBlank(ligneError)) {
        result = ligne1 + "<br/>" + ligneError;
      } else {
        result = ligne1;
      }
      return result;
    };
    cellRenderers["commentaire"] = (model: CWListerEvtGenModel): string | JQuery => {
      let linkCom = "";
      const modelCanBeManaged = this._modelCanBeManaged(model);

      if (modelCanBeManaged === true) {
        if (CWSTR.isBlank(model.get("commentaire"))) {
          linkCom = "<span data-eventid='" + model.get("id") + "' title='" + i18n.t('common:listerevtgen.grid_comentaire_title') + "' class='linkCom A " + model.get("id") + " phx-icon phx-icon-comment-white' style='cursor:pointer; text-decoration:underline;margin:auto'></span>";
        } else {
          linkCom = "<span data-eventid='" + model.get("id") + "' title='" + i18n.t('common:listerevtgen.grid_comentaire_title') + "' class='linkCom A " + model.get("id") + " phx-icon phx-icon-comment-black' style='cursor:pointer; text-decoration:underline;margin:auto'></span>";
        }
      }
      return linkCom;
    };
    cellRenderers["a"] = (model: CWListerEvtGenModel): string | JQuery => {
      const checkedAttribute = model.evtEtatValid === "A" ? "checked" : "";
      const title = " title='" + i18n.t('common:listerevtgen.accepter') + "'";
      let disabled = "";
      let returned = "";
      const modelCanBeManaged = this._modelCanBeManaged(model);

      if (CWHABILITATION.canView("RES_VAL_EGEN.G") === false) {
        disabled = " disabled='disabled'";
      }
      if (modelCanBeManaged === true) {
        // Oracle : When printed, each field code is displayed
        // in radix 64 (A-Za-z0-9+/): so a "/" can appear - should be removed.
        returned = "<input data-eventid='" + model.get("id") + "' '" + disabled + "' class='checkBoxLine A' type='checkbox' " + title + "  value='a' " + checkedAttribute + "/>";
        if (model.get("code").search("/") !== -1) {
          returned = returned.replace("/", "_");
        }
      }
      return returned;
    };
    cellRenderers["r"] = (model: CWListerEvtGenModel): string | JQuery => {
      const checkedAttribute = model.evtEtatValid === "R" ? "checked" : "";
      const title = " title='" + i18n.t('common:listerevtgen.refuser') + "'";
      let disabled = "";
      let returned = "";
      const modelCanBeManaged = this._modelCanBeManaged(model);

      if (CWHABILITATION.canView("RES_VAL_EGEN.G") === false) {
        disabled = " disabled='disabled'";
      }
      if (modelCanBeManaged === true) {
        returned = "<input data-eventid='" + model.get("id") + "' '" + disabled + "' class='checkBoxLine R' type='checkbox' " + title + "  value='r' " + checkedAttribute + "/>";
        if (model.get("code").search("/") !== -1) {
          returned = returned.replace("/", "_");
        }
      }
      return returned;
    };
    cellRenderers["pj"] = (model: CWListerEvtGenModel): string | JQuery => {
      const indicateurpj = model.get("indicateurpj");

      if (indicateurpj === true) {
        const icon = $("<span>");

        icon.addClass("piece-jointe ui-phx-piece-jointe-icon");
        icon.addClass("phx-grid-not-selectable-cell");
        icon.attr("title", i18n.t('common:listerevtgen.grid_pj_title'));
        icon.attr("data-evenement", model.get("evenement"));
        //return icon[0];
        return icon;
      }
      return "";
    };
    return cellRenderers;
  }

  _historique(callback: () => void): void {
    if (CWSTR.isBlank(this.wkfEvenements)) {
      const params: Array<string> = [];

      _.each(this.tableModel.coll.models, (item: CWListerEvtGenModel) => {
        params.push(item.get("evenement"));
      }, this);
      this.wkfEvenements = params.join(",");
      this.historiqueColl.setHabContext(this.workflow.getHabContext());
      this.historiqueColl.applyFilter({ evenements: this.wkfEvenements });
      this.historiqueColl.fetch({
        success: function () {
          if (callback) {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  _textToStyle(txt: string, style: string): string {
    let newTxt = "";

    newTxt = "<span class='" + style + "'>" + txt + "</span>";
    return newTxt;
  }

  _buildHistoDemande(demande: string): string {
    let index = 0;
    const wkfModels = [];
    let message = "";
    let hasLastAction = true;

    for (let i = 0; i < this.historiqueColl.length; i++) {
      const model = this.historiqueColl.at(i);

      if (model.get("demande") === demande) {
        wkfModels[index] = model;
        if (index === 0) {
          index++;
        }
      }
    }
    if (CWSTR.isBlank(wkfModels[1])) {
      hasLastAction = false;
    }
    if (wkfModels[0]) {
      message = this._buildHistoLine(wkfModels, 0, hasLastAction);
      if (wkfModels[1] && wkfModels[0].get("acte") !== wkfModels[1].get("acte")) {
        message += i18n.t('common:listerevtgen.derniere');
        message += this._buildHistoLine(wkfModels, 1, hasLastAction);
      }
    }
    return message;
  }

  _buildHistoLine(wmodel: Array<CWBaseModel>, detailPart: number, hasLastAction: boolean): string {
    const model = wmodel[detailPart];
    let result = "";
    const date = CWTYPE.DATE.format(model.get("moments")[0]["@date"], CWTYPE._getFormatByCode("DATE_A"));
    const codefonctionnel = model.get("codefonctionnel");
    let messageCode: Array<string> = [];
    let messageDate = "";
    let messageAuthor = "";
    const currentUser = objs.appRt.workflow.authModel.get("name");
    let modelAuthor = model.get("auteur")["@auteur"];

    messageCode = CWWKF._retrieveMessageCode(codefonctionnel);
    messageDate = " " + i18n.t('messages:GL_1026', { "1": this._bold(date) });
    if (CWSTR.isBlank(modelAuthor)) {
      modelAuthor = model.get("auteur").message;
    }
    // creator == loged user
    if ((currentUser === modelAuthor || CWSTR.isBlank(model.get("auteur")["@auteur"]))) {
      messageAuthor = " " + this._bold(i18n.t('messages:GL_1029'));
    } else if (modelAuthor) {
      messageAuthor = " " + i18n.t('messages:GL_1028', { "1": this._bold(modelAuthor) });
    }
    // we dont need author - if we have already the autor in the last action.
    if (hasLastAction === true && detailPart === 0) {
      messageAuthor = "";
    }
    result += "<tr><td> " + messageCode[0] + messageDate + messageAuthor + "</td></tr>";
    return result;
  }

  _bold(normalText: string): string {
    const boldText = "<b>" + normalText + "</b>";

    return boldText;
  }

  _getWorkflowStyle(statut: string): string {
    switch (statut) {
      case "E":
        return "ui-phx-statut-en-cours-saisie";
      case "F":
      case "G":
        return "ui-phx-statut-en-cours-revision";
      case "C":
      case "M":
        return "ui-phx-statut-en-cours-modification";
      case "Y":
      case "Z":
        return "ui-phx-statut-a-revoir";
      case "I":
        return "ui-phx-statut-en-cours";
      case "A":
        return "ui-phx-statut-accepte";
      case "R":
        return "ui-phx-statut-refuse";
      case "H":
        return "ui-phx-statut-hors-validation";
      case "D":
      case "T":
        return "ui-phx-statut-demande";
      default:
        return "";
    }
  }

  private _creationVolet(): CWVoletView {
    const volet = new CWVoletView({
      id: "cw-voletEvtDetail",
      view: CWGererEvtGenView,
      viewData: {
        context: this.context
      }
    });

    volet.setHeight("auto");
    volet.setWidth("auto"); //470
    return volet;
  }

  _modelCanBeManaged(model: CWListerEvtGenModel): boolean {
    let modelCanBeManaged = false;
    const etat = model.get("etat");

    if (this.workflow.context && this.workflow.context.ctxUtilisateur === "Responsable" && (etat === "D" || etat === "Z" || etat === "C" ||
      etat === "G" || etat === "X")) {
      modelCanBeManaged = true;
    }
    return modelCanBeManaged;
  }

  public getFilterObject(): CWFilterView<CWFilterListerEvtGen, CWListerEvtFilterContentView> {
    return this.filter;
  }
  public getButtonBarObject(): CWButtonBarView {
    return this.btnBar;
  }

  public getRadiosSelector(): CWListerEvtCustomBar {
    return this.customBar;
  }

  private manageTitle(): void {
    if (this.context.ctxEcran === "suivicollab") {
      if (this.workflow && this.workflow.evenementsColl) {
        this.$el.find(".cw-fieldset__situationPanel__title .cw-titre").html(i18n.t('suivicollab.menuAutresEvenements') + ' <span class="cw-texteSuperAllege"> (' + this.workflow.tableModel.coll.length + ') </span>');
      }
      else {
        this.$el.find(".cw-fieldset__situationPanel__title .cw-titre").text(i18n.t('suivicollab.menuAutresEvenements'));
      }
    }
  }
  public getForm(): CWListerEvtFormView {
    return this.form;
  }

  public getfilter(): CWFilterView<CWFilterListerEvtGen, CWListerEvtFilterContentView> {
    return this.filter;
  }

  public getCustomBar(): CWListerEvtCustomBar {
    return this.customBar;
  }
}
