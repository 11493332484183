import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLActivitetreesGeneralView from '../cwGeneralView.tpl.html';
import { CaracPrincStructColl } from '../models/caracPrincStruct.collection.js';
import { CWActiviteColl } from 'common/evenements/planifier/activite/models/cwActivite.collection';
import { CWActiviteModel } from 'src/uc/common/evenements/planifier/activite/models/cwActivite.model';
import { CWActiviteTreesContext, CWActiviteTreesViewType, CWActivitetreesWorkflow } from '../models/cwActivitetrees.workflow';
import { CWFamilleTreeView } from './cwFamilleTree.view';
import { CWHierarchieTreeView } from './cwHierarchieTree.view';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { CWStructureDetailTreeView } from './cwStructureDetailTree.view';
import { CWStructureSimpleTreeView } from './cwStructureSimpleTree.view';
import { CWViewsMenuView } from 'core/views/cwViewsMenu.view';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

/**
 * GeneralView of activiteTrees
 */
export class CWActiviteTreesGeneralView extends Backbone.View {

  FAMILLE: string;
  HIERARCHIE: string;
  STRUCTUREDETAIL: string;
  STRUCTURESIMPLE: string;
  context: CWActiviteTreesContext;
  racineColl: CWActiviteColl;
  racineModel: CWActiviteModel;
  deployColl: any;
  showSelected: boolean;
  multiselect: boolean;
  hideMenuOptions: boolean;
  showExpandAllIcon: boolean;
  readOnly: boolean;
  hideCheck: boolean;
  workflow: CWActivitetreesWorkflow;
  selecteurContext: any;
  familleTreeView: CWFamilleTreeView;
  hierarchieTreeView: CWHierarchieTreeView;
  structureDetailTreeView: CWStructureDetailTreeView;
  structureSimpleTreeView: CWStructureSimpleTreeView;
  leftMenuViews: CWViewsMenuView;
  parent: any;
  view: any;
  omitedViews: string[];
  viewsForDisable: string[];
  defaultView: string;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "click .expandAllIcon": "_expandAllNodes",
    }, options.events);
    super(options);
    this.FAMILLE = "FAMILLE";
    this.HIERARCHIE = "HIERARCHIE";
    this.STRUCTUREDETAIL = "STRUCTUREDETAIL";
    this.STRUCTURESIMPLE = "STRUCTURESIMPLE";
    this.context = options.context;
    this.racineColl = options.racineColl;
    this.racineModel = options.racineModel;
    this.deployColl = options.deployColl;
    this.showSelected = _.isBoolean(options.showSelected) ? options.showSelected : true;
    this.multiselect = _.isBoolean(options.multiselect) ? options.multiselect : false;
    //Show / hide labeling menu option
    this.hideMenuOptions = _.isBoolean(options.hideMenuOptions) ? options.hideMenuOptions : false;
    //Show / hide Expand / collapse all option
    this.showExpandAllIcon = _.isBoolean(options.showExpandAllIcon) ? options.showExpandAllIcon : false;
    this.readOnly = _.isBoolean(options.readOnly) ? options.readOnly : false;
    this.hideCheck = _.isBoolean(options.hideCheck) ? options.hideCheck : false;
    this.workflow = new CWActivitetreesWorkflow(options);
    this.selecteurContext = options.selecteurContext;
    this.workflow.selecteurContext = this.selecteurContext;
    this.template = TPLActivitetreesGeneralView;
    this.omitedViews = !_.isEmpty(options.omitedViews) ? options.omitedViews : null;
    this.viewsForDisable = !_.isEmpty(options.viewsForDisable) ? options.viewsForDisable : null;
    this.defaultView = !CWSTR.isBlank(options.defaultView) ? options.defaultView : null;
    this._initializeAllViews();
    this.listenTo(this.workflow, "initializeView:FAMILLE", this._initializeFamilleTreeView);
    this.listenTo(this.workflow, "initializeView:HIERARCHIE", this._initializeHierarchieTreeView);
    this.listenTo(this.workflow, "initializeView:STRUCTURESIMPLE", this._initializeStructureSimpleTreeView);
    this.listenTo(this.workflow, "initializeView:STRUCTUREDETAIL", this._initializeStructureDetailTreeView);
  }

  _resetExpandAllIcon(): void { //Set icon expand all to the default state
    $(this.el).find(".expandAllIcon.cw-icon--collapsable").addClass("d-none");
    $(this.el).find(".expandAllIcon.cw-icon--expandable").removeClass("d-none");
  }

  _showExpandAllIconExpandable(): void {
    $(this.el).find(".expandAllIcon.cw-icon--collapsable").addClass("d-none");
    $(this.el).find(".expandAllIcon.cw-icon--expandable").removeClass("d-none");
  }

  _showExpandAllIconCollapsable(): void {
    $(this.el).find(".expandAllIcon.cw-icon--collapsable").removeClass("d-none");
    $(this.el).find(".expandAllIcon.cw-icon--expandable").addClass("d-none");
  }

  _isExpandAllIconExpandable(): boolean {
    return !$(this.el).find(".expandAllIcon.cw-icon--expandable").hasClass("d-none");
  }

  _expandAllNodes(): void {
    if (this._isExpandAllIconExpandable()) {
      this._showExpandAllIconCollapsable();
      switch (this.workflow._obtainCurrentVueInTree()) {
        case CWActiviteTreesViewType.FAMILLE: {
          if (!CWSTR.isBlank(this.familleTreeView.tree)) {
            this.familleTreeView.tree.expandAllNodes();
          }
          break;
        }
        case CWActiviteTreesViewType.HIERARCHIE: {
          if (!CWSTR.isBlank(this.hierarchieTreeView.tree)) {
            this.hierarchieTreeView.tree.expandAllNodes();
          }
          break;
        }
        case CWActiviteTreesViewType.STRUCTURESIMPLE: {
          if (!CWSTR.isBlank(this.structureSimpleTreeView.tree)) {
            this.structureSimpleTreeView.tree.expandAllNodes();
          }
          break;
        }
        case CWActiviteTreesViewType.STRUCTUREDETAIL: {
          if (!CWSTR.isBlank(this.structureDetailTreeView.tree)) {
            this.structureDetailTreeView.tree.expandAllNodes();
          }
          break;
        }
        default: {
          CWLOG.error("Tree view not supported");
        }
      }
    } else {
      this._showExpandAllIconExpandable();
      switch (this.workflow._obtainCurrentVueInTree()) {
        case CWActiviteTreesViewType.FAMILLE: {
          if (!CWSTR.isBlank(this.familleTreeView.tree)) {
            this.familleTreeView.tree.collapseAllNodes();
          }
          break;
        }
        case CWActiviteTreesViewType.HIERARCHIE: {
          if (!CWSTR.isBlank(this.hierarchieTreeView.tree)) {
            this.hierarchieTreeView.tree.collapseAllNodes();
          }
          break;
        }
        case CWActiviteTreesViewType.STRUCTURESIMPLE: {
          if (!CWSTR.isBlank(this.structureSimpleTreeView.tree)) {
            this.structureSimpleTreeView.tree.collapseAllNodes();
          }
          break;
        }
        case CWActiviteTreesViewType.STRUCTUREDETAIL: {
          if (!CWSTR.isBlank(this.structureDetailTreeView.tree)) {
            this.structureDetailTreeView.tree.collapseAllNodes();
          }
          break;
        }
        default: {
          CWLOG.error("Tree view not supported");
        }
      }
    }
  }

  /**
   * Expand all node and exectue calback when all node data are load.
   */
  expandOnInit(calback?: () => void): void {
    this._showExpandAllIconCollapsable();
    switch (this.workflow._obtainCurrentVueInTree()) {
      case CWActiviteTreesViewType.FAMILLE: {
        if (!CWSTR.isBlank(this.familleTreeView.tree)) {
          this.familleTreeView.tree.expandAllNodes(calback);
        }
        break;
      }
      case CWActiviteTreesViewType.HIERARCHIE: {
        if (!CWSTR.isBlank(this.hierarchieTreeView.tree)) {
          this.hierarchieTreeView.tree.expandAllNodes(calback);
        }
        break;
      }
      case CWActiviteTreesViewType.STRUCTURESIMPLE: {
        if (!CWSTR.isBlank(this.structureSimpleTreeView.tree)) {
          this.structureSimpleTreeView.tree.expandAllNodes(calback);
        }
        break;
      }
      case CWActiviteTreesViewType.STRUCTUREDETAIL: {
        if (!CWSTR.isBlank(this.structureDetailTreeView.tree)) {
          this.structureDetailTreeView.tree.expandAllNodes(calback);
        }
        break;
      }
      default: {
        CWLOG.error("Tree view not supported");
      }
    }
  }

  /**
   * Initializes all tree views and their models
   */
  _initializeAllViews(): void {
    //Tree views
    this.familleTreeView = this._initFamilleTree();
    this.hierarchieTreeView = this._initHierarchieTree();
    this.structureDetailTreeView = this._initStructureDetailTree();
    this.structureSimpleTreeView = this._initStructureSimpleTree();
    //Set models
    this.workflow.familleTreeModel = this.familleTreeView.model;
    this.workflow.hierarchieTreeModel = this.hierarchieTreeView.model;
    this.workflow.structureSimpleTreeModel = this.structureSimpleTreeView.model;
    this.workflow.structureDetailTreeModel = this.structureDetailTreeView.model;
    //Default model
    this.workflow.currentTreeViewModel = this.familleTreeView.model;
    this.workflow.listenTo(this.workflow.familleTreeModel, "all", this.workflow._manageTreeModelsEvents);
    this.workflow.listenTo(this.workflow.hierarchieTreeModel, "all", this.workflow._manageTreeModelsEvents);
    this.workflow.listenTo(this.workflow.structureDetailTreeModel, "all", this.workflow._manageTreeModelsEvents);
    this.workflow.listenTo(this.workflow.structureSimpleTreeModel, "all", this.workflow._manageTreeModelsEvents);
    //Init Left view
    this.leftMenuViews = this._initLeftViews();
    this.workflow.menuViewsModel = this.leftMenuViews.model;
    this.workflow.listenTo(this.workflow.menuViewsModel, "change:menuView", this.workflow._manageChangeMenuView);
  }

  /**
   * Initialize Famille tree view and his model
   */
  _initializeFamilleTreeView(): void {
    if (this.familleTreeView) {
      delete this.familleTreeView;
    }
    this.familleTreeView = this._initFamilleTree();
    if (this.workflow.familleTreeModel) {
      delete this.workflow.familleTreeModel;
    }
    this.workflow.familleTreeModel = this.familleTreeView.model;
    this.workflow.listenTo(this.workflow.familleTreeModel, "all", this.workflow._manageTreeModelsEvents);
  }

  /**
   * Initialize Hierarchie tree view and his model
   */
  _initializeHierarchieTreeView(): void {
    if (this.hierarchieTreeView) {
      delete this.hierarchieTreeView;
    }
    this.hierarchieTreeView = this._initHierarchieTree();
    if (this.workflow.hierarchieTreeModel) {
      delete this.workflow.hierarchieTreeModel;
    }
    this.workflow.hierarchieTreeModel = this.hierarchieTreeView.model;
    this.workflow.listenTo(this.workflow.hierarchieTreeModel, "all", this.workflow._manageTreeModelsEvents);
  }

  /**
   * Initialize structure Detail tree view and his model
   */
  _initializeStructureDetailTreeView(): void {
    if (this.structureDetailTreeView) {
      delete this.structureDetailTreeView;
    }
    this.structureDetailTreeView = this._initStructureDetailTree();
    if (this.workflow.structureDetailTreeModel) {
      delete this.workflow.structureDetailTreeModel;
    }
    this.workflow.structureDetailTreeModel = this.structureDetailTreeView.model;
    this.workflow.listenTo(this.workflow.structureDetailTreeModel, "all", this.workflow._manageTreeModelsEvents);
  }

  /**
   * Initialize structure Simple tree view and his model
   */
  _initializeStructureSimpleTreeView(): void {
    if (this.structureSimpleTreeView) {
      delete this.structureSimpleTreeView;
    }
    this.structureSimpleTreeView = this._initStructureSimpleTree();
    if (this.workflow.structureSimpleTreeModel) {
      delete this.workflow.structureSimpleTreeModel;
    }
    this.workflow.structureSimpleTreeModel = this.structureSimpleTreeView.model;
    this.workflow.listenTo(this.workflow.structureSimpleTreeModel, "all", this.workflow._manageTreeModelsEvents);
  }

  /**
   * Initialize table of the left menu
   */
  _initLeftViews(): CWViewsMenuView {
    const menuViews: { [key: string]: any } = {};
    const omitedViews = [this.STRUCTURESIMPLE, this.STRUCTUREDETAIL];
    const viewsForDisable = [this.STRUCTUREDETAIL, this.STRUCTURESIMPLE];
    let leftViews: CWViewsMenuView = null;

    menuViews[this.FAMILLE] = { view: this.familleTreeView, name: i18n.t('common:activitetrees.vue_famille'), table: false };
    menuViews[this.HIERARCHIE] = { view: this.hierarchieTreeView, name: i18n.t('common:activitetrees.vue_hierarchie'), table: false };
    menuViews[this.STRUCTUREDETAIL] = { view: this.structureDetailTreeView, name: i18n.t('common:activitetrees.vue_structure_detaille'), table: false };
    menuViews[this.STRUCTURESIMPLE] = { view: this.structureSimpleTreeView, name: i18n.t('common:activitetrees.vue_structure_simplifie'), table: false };
    leftViews = new CWViewsMenuView({ menuViews: menuViews, defaultView: (this.defaultView || this.FAMILLE), omitedViews: (this.omitedViews || omitedViews), viewsForDisable: (this.viewsForDisable || viewsForDisable) });
    return leftViews;
  }  /**
   * Initializes Tree View
   */
  _initFamilleTree(): CWFamilleTreeView {
    const enhancedTree = new CWFamilleTreeView({
      multiple: false,
      workflow: this.workflow,
      racineColl: this.racineColl,
      racineModel: this.racineModel,
      deployColl: this.deployColl,
      multiselect: this.multiselect,
      hideMenuOptions: this.hideMenuOptions,
      showExpandAllIcon: this.showExpandAllIcon,
      readOnly: this.readOnly,
      isCheckedCallback: this._isChecked,
      hideCheck: this.hideCheck,
      showSelected: this.showSelected,
      context: this.context
    });

    //The tree need to listen to selections in order to manage the details
    return enhancedTree;
  }

  /**
   * Initializes Tree View
   */
  _initHierarchieTree(): CWHierarchieTreeView {
    const enhancedTree = new CWHierarchieTreeView({//We don't have yet the combo loaded. We can't choose a structure to show in the tree
      multiple: false,
      workflow: this.workflow,
      racineColl: this.racineColl,
      racineModel: this.racineModel,
      deployColl: this.deployColl,
      multiselect: this.multiselect,
      hideMenuOptions: this.hideMenuOptions,
      showExpandAllIcon: this.showExpandAllIcon,
      readOnly: this.readOnly,
      isCheckedCallback: this._isChecked,
      hideCheck: this.hideCheck,
      showSelected: this.showSelected,
      context: this.context
    });

    //The tree need to listen to selections in order to manage the details
    return enhancedTree;
  }

  /**
   * Initializes Tree View
   */
  _initStructureDetailTree(): CWStructureDetailTreeView {
    const enhancedTree = new CWStructureDetailTreeView({
      multiple: false,
      workflow: this.workflow,
      racineColl: this.racineColl,
      racineModel: this.racineModel,
      deployColl: this.deployColl,
      multiselect: this.multiselect,
      hideMenuOptions: this.hideMenuOptions,
      showExpandAllIcon: this.showExpandAllIcon,
      readOnly: this.readOnly,
      isCheckedCallback: this._isChecked,
      hideCheck: this.hideCheck,
      showSelected: this.showSelected,
      context: this.context
    });

    //We don't have yet the combo loaded. We can't choose a structure to show in the tree
    return enhancedTree;
  }

  /**
   * Initializes Tree View
   */
  _initStructureSimpleTree(): CWStructureSimpleTreeView {
    const enhancedTree = new CWStructureSimpleTreeView({//We don't have yet the combo loaded. We can't choose a structure to show in the tree
      multiple: false,
      workflow: this.workflow,
      racineColl: this.racineColl,
      racineModel: this.racineModel,
      deployColl: this.deployColl,
      multiselect: this.multiselect,
      hideMenuOptions: this.hideMenuOptions,
      showExpandAllIcon: this.showExpandAllIcon,
      readOnly: this.readOnly,
      isCheckedCallback: this._isChecked,
      hideCheck: this.hideCheck,
      showSelected: this.showSelected,
      context: this.context
    });

    //The tree need to listen to selections in order to manage the details
    return enhancedTree;
  }

  /**
   * Loads data and paints the view
   */
  render(): this {
    const json = { UTILS: UTILS };

    $(this.el).append(this.template(json));
    return this;
  }

  /**
   * Sets the context and rerenders complete view
   */
  setContextAndRender(context: CWActiviteTreesContext, callbackAfterRender?: () => void, flagNotPetition?: boolean): void {
    const params: { [key: string]: any } = {};
    const callback = (): void => {
      this.leftMenuViews.setPeriodeContext(this.workflow.context.ctxPeriodeGestion);
      $(this.el).find(".activitetrees_generalView").append(this.leftMenuViews.el);
      $(this.el).css("height", "100%");
      this.leftMenuViews.render(callbackAfterRender);
      if (this.showExpandAllIcon) {
        this._showExpandAllIconCollapsable();
        $(this.el).find(".phx-viewsmenu-title").css("width", "auto");
        $(this.el).find(".phx-viewsmenu-title").css("float", "right");
      }
    };

    this.familleTreeView.listRenderStyle = 0;
    this.hierarchieTreeView.listRenderStyle = 0;
    this.structureSimpleTreeView.listRenderStyle = 0;
    params.context = context;
    this.workflow.setContext(context, callback, flagNotPetition);
  }

  obtainCheckedModels(returnOnlyActivitees?: boolean): { [key: string]: any } {
    let selectedModels = null;

    switch (this.workflow._obtainCurrentVueInTree()) {
      case CWActiviteTreesViewType.FAMILLE: {
        selectedModels = this.familleTreeView.getCheckedRows();
        break;
      }
      case CWActiviteTreesViewType.HIERARCHIE: {
        selectedModels = this.hierarchieTreeView.getCheckedRows();
        break;
      }
      case CWActiviteTreesViewType.STRUCTURESIMPLE: {
        selectedModels = this.structureSimpleTreeView.getCheckedRows();
        break;
      }
      case CWActiviteTreesViewType.STRUCTUREDETAIL: {
        selectedModels = this.structureDetailTreeView.getCheckedRows();
        break;
      }
      default: {
        CWLOG.error("Tree view not supported");
        return selectedModels;
      }
    }
    if (!CWSTR.isBlank(returnOnlyActivitees) && returnOnlyActivitees && !CWSTR.isBlank(selectedModels)) {
      const newSelectedModels = new Backbone.Collection();

      _.each(selectedModels.models, (model: { [key: string]: any }): void => {
        if (!CWSTR.isBlank(model.node)) {
          if (String(model.node.get("typelt")) === "A") {
            newSelectedModels.add(model);
          }
        }
      });
      selectedModels = newSelectedModels;
    }
    return selectedModels;
  }

  setCheckedModels(selectedModels: Backbone.Collection): void {
    if (!CWSTR.isBlank(this.familleTreeView)) {
      this.familleTreeView.setCheckedRows(selectedModels);
    }
    if (!CWSTR.isBlank(this.hierarchieTreeView)) {
      this.hierarchieTreeView.setCheckedRows(selectedModels);
    }
    if (!CWSTR.isBlank(this.structureSimpleTreeView)) {
      this.structureSimpleTreeView.setCheckedRows(selectedModels);
    }
    if (!CWSTR.isBlank(this.structureDetailTreeView)) {
      this.structureDetailTreeView.setCheckedRows(selectedModels);
    }
  }

  clearCheckedModels(): void {
    //FAMILLE
    if (this.familleTreeView) {
      this.familleTreeView.clearCheckedRows();
    }
    //HIERARCHIE
    if (this.hierarchieTreeView) {
      this.hierarchieTreeView.clearCheckedRows();
    }
    //STRUCTURESIMPLE
    if (this.structureSimpleTreeView) {
      this.structureSimpleTreeView.clearCheckedRows();
    }
    //STRUCTUREDETAIL
    if (this.structureDetailTreeView) {
      this.structureDetailTreeView.clearCheckedRows();
    }
  }

  setReadOnly(readOnly: boolean): void {
    this.readOnly = readOnly;
    switch (this.workflow._obtainCurrentVueInTree()) {
      case CWActiviteTreesViewType.FAMILLE: {
        if (!CWSTR.isBlank(this.familleTreeView)) {
          this.familleTreeView.readOnly = readOnly;
        }
        break;
      }
      case CWActiviteTreesViewType.HIERARCHIE: {
        if (!CWSTR.isBlank(this.hierarchieTreeView)) {
          this.hierarchieTreeView.readOnly = readOnly;
        }
        break;
      }
      case CWActiviteTreesViewType.STRUCTURESIMPLE: {
        if (!CWSTR.isBlank(this.structureSimpleTreeView)) {
          this.structureSimpleTreeView.readOnly = readOnly;
        }
        break;
      }
      case CWActiviteTreesViewType.STRUCTUREDETAIL: {
        if (!CWSTR.isBlank(this.structureDetailTreeView)) {
          this.structureDetailTreeView.readOnly = readOnly;
        }
        break;
      }
      default: {
        CWLOG.error("Tree view not supported");
      }
    }
  }

  /**
   * Checks if the activity model is added to the collection.
   */
  _isChecked(model: { [key: string]: any }, callback: (bool: boolean, flag?: boolean) => boolean): boolean {
    if (!CWSTR.isBlank(model) && !CWSTR.isBlank(model.node)) {
      const searchCode = model.node.get('code');
      const searchHierid = model.node.get('hierid');
      let searchStructida = model.node.get('structida');
      let searchItemida = model.node.get('itemida');
      const modelParent = model.node.parent;
      const isFeuille = (model.node.get("feuille") === true);
      const localCallback = (): boolean => {
        const item = _.find(this.parent?.model?.checkedColl?.models, (modelCheckColl: { [key: string]: any }): boolean => {
          if (!CWSTR.isBlank(modelCheckColl) && !CWSTR.isBlank(modelCheckColl.node) && searchCode === modelCheckColl.node.get("code")) {
            if (!CWSTR.isBlank(this.view)) {
              switch (this.view) {
                case CWActiviteTreesViewType.FAMILLE:
                  if (CWSTR.isBlank(modelCheckColl.node.get("structida")) && CWSTR.isBlank(modelCheckColl.node.get("itemida"))) {
                    return true;
                  }
                  break;
                case CWActiviteTreesViewType.HIERARCHIE:
                  if (!CWSTR.isBlank(searchHierid) && searchHierid === modelCheckColl.node.get("hierid")) {
                    if (CWSTR.isBlank(searchItemida)) {
                      if (!CWSTR.isBlank(modelCheckColl.node.get("structida")) && !CWSTR.isBlank(modelCheckColl.node.get("itemida"))) {
                        if (modelParent.get("structida") === modelCheckColl.node.get("structida") && modelParent.get("itemida") === modelCheckColl.node.get("itemida")) {
                          return true;
                        }
                      } else if (modelCheckColl.node.parent) {
                        if (modelParent.get("structida") === modelCheckColl.node.parent.get("structida") && modelParent.get("itemida") === modelCheckColl.node.parent.get("itemida")) {
                          return true;
                        }
                      } else {
                        return true;
                      }
                    } else if (searchItemida === modelCheckColl.node.get("itemida")) {
                      return true;
                    }
                  }
                  break;
                case CWActiviteTreesViewType.STRUCTURESIMPLE:
                  if (isFeuille) {
                    if (!CWSTR.isBlank(searchStructida) && !CWSTR.isBlank(modelCheckColl.node.get("structida"))) {
                      if (searchStructida === modelCheckColl.node.get("structida")) {
                        if (!CWSTR.isBlank(searchItemida) && !CWSTR.isBlank(modelCheckColl.node.get("itemida"))) {
                          if (searchItemida === modelCheckColl.node.get("itemida")) {
                            if (!CWSTR.isBlank(searchHierid) && !CWSTR.isBlank(modelCheckColl.node.get("hierid"))) {
                              if (searchHierid === modelCheckColl.node.get("hierid")) {
                                return true;
                              }
                              return false;
                            }
                            return true;
                          }
                          return false;
                        }
                        if (!CWSTR.isBlank(searchHierid) && !CWSTR.isBlank(modelCheckColl.node.get("hierid")) && searchHierid === modelCheckColl.node.get("hierid")) {
                          return true;
                        }
                        if (CWSTR.isBlank(searchItemida) && CWSTR.isBlank(modelCheckColl.node.get("itemida")) && CWSTR.isBlank(searchHierid) && CWSTR.isBlank(modelCheckColl.node.get("hierid"))) {//hors structure (même code et structida mais sans hierid ou itemida)
                          return true;
                        }
                        return false;
                      }
                      return false;
                    } else {
                      if (!CWSTR.isBlank(searchHierid) && !CWSTR.isBlank(modelCheckColl.node.get("hierid")) && searchHierid === modelCheckColl.node.get("hierid")) {
                        return true;
                      }
                      return false;
                    }
                  } else {
                    return true;
                  }
                  break;
                case CWActiviteTreesViewType.STRUCTUREDETAIL:
                  if (searchStructida === modelCheckColl.node.get("structida") && searchItemida === modelCheckColl.node.get("itemida")) {
                    return true;
                  }
                  break;
                default:
                  return false;
              }
              return false;
            } else {
              if (CWSTR.isBlank(searchHierid) && searchStructida === modelCheckColl.node.get("structida") && searchItemida === modelCheckColl.node.get("itemida")) {
                return true;
              } else { //Mmmm me be is an activity in activity
                if (CWSTR.isBlank(searchStructida) && CWSTR.isBlank(searchItemida) && searchHierid === modelCheckColl.node.get("hierid")) {
                  if (!CWSTR.isBlank(searchHierid) && CWSTR.isBlank(searchItemida)) {
                    const modelParent = model.node.parent;

                    if (modelParent.get("structida") === modelCheckColl.node.get("structida") && modelParent.get("itemida") === modelCheckColl.node.get("itemida")) {
                      return true;
                    }
                  }
                }
              }
              return false;
            }
          } else {
            return modelCheckColl.cid === model.cid;
          }
        });

        if (!_.isEmpty(item)) {
          //Check if the model is inserted, and remplace it for the real model
          if (item.flagSetted === true) {//_.isBoolean(item.flagSetted) && item.flagSetted -> tout se peut changer par item.flagSetted===true
            this.parent.model.checkedColl.remove(item);
            this.parent.model.checkedColl.add(model);
          }
          if (callback) {
            return callback(true);
          }
        }
        if (callback) {
          return callback(false);
        }
        return false;
      };

      if (CWSTR.isBlank(model.node.get("itemida")) && model.node.get("typelt") === "A" && model.node.get("petStruct") !== true) { //Is an activité?
        if (!CWSTR.isBlank(model.node.get("code"))) {
          const collection = new CaracPrincStructColl();

          collection.code = model.node.get("code");
          collection.setHabContext(model.coll.getHabContext());
          collection.fetch({
            success: (freshCollection: { [key: string]: any }) => {
              if (!CWSTR.isBlank(freshCollection.models[0])) {
                model.node.set("itemida", freshCollection.models[0].get("itemida"));
                model.node.set("structida", freshCollection.models[0].get("structida"));
                searchStructida = model.node.get('structida');
                searchItemida = model.node.get('itemida');
              }
              model.node.set("petStruct", true);//pour ne pas faire pétitions dupliques
              localCallback();
            }
          });
        } else {
          return localCallback();
        }
      } else { //Is not an activité, may be a structure
        return localCallback();
      }
    }
    return false;
  }
}
