import * as Backbone from 'Backbone';
import _ from 'underscore';
import commonDropdownViewContainerComponentTPL from './cwBlock.tpl.html';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWSTR } from 'utils/cwStr';
import { UTILS } from 'utils/utils.js';
import { validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';


export interface CWCommonBlockOptions<TModel extends CWBaseModel = CWBaseModel> extends Backbone.ViewOptions<TModel> {
  hSeparator?: any;
  iconHidden?: any;
  detailTemplate?: any;
  expanded?: any;
  component?: any;
  buttons?: any;
  buttonsLine?: any;
  ctxEcran?: any;
  popupGerer?: any;
  typeAff?: any;
  workflow?: any;
  idElement?: string;
}

interface CWCommonBlockButton {
  id: string;
  text?: string;
  icons?: any;
  validateLine?: any;
  disableLine?: any;
}

/**
 * Auxiliar view for Dialog Vue journée
 */
export class CWCommonBlockView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<TModel> {

  menuBar: boolean;
  templateHTML: JQuery;
  $block: JQuery;
  $icon: JQuery;
  $title: JQuery;
  $content: JQuery;
  contentVoid: boolean;
  titleHTML: string;
  iconHidden: boolean;
  expanded: boolean;
  component: string;
  detailTemplate: any;
  buttons: CWCommonBlockButton[];
  JQueryBtn: any;
  buttonsLine: any;
  hSeparator: any;
  ctxEcran: any;
  popupGerer: any;
  secondaryAff: any;
  withFocus: any;
  context: any;

  constructor(params: CWCommonBlockOptions<TModel>) {
    params = params || {};
    params.events = _.extend({
      "click span.c-block__titleIcon": "_clickHandler",
      "keydown": "_keydownHandler"
    }, params.events);
    super(params);
    this.template = commonDropdownViewContainerComponentTPL;
    this.templateHTML = $(this.template());
    this.$block = this.templateHTML;
    this.$icon = this.templateHTML.find(".c-block__titleIcon");
    this.$title = this.templateHTML.find(".c-block__titleText");
    this.$content = this.templateHTML.find(".c-block__content");
    this.menuBar = false;
    //flag for build menu
    this.contentVoid = true;
    this.titleHTML = null;
    this.iconHidden = false;
    if (params.hSeparator) {
      this.iconHidden = params.iconHidden;
    }
    this.detailTemplate = params.detailTemplate;
    if (!CWSTR.isBlank(params.expanded)) {
      this.expanded = params.expanded;
    } else {
      this.expanded = false;
    }
    this.component = params.component;
    this.buttons = params.buttons;
    this.buttonsLine = params.buttonsLine;
    this.hSeparator = false;
    if (params.hSeparator) {
      this.hSeparator = params.hSeparator;
    }
    this.ctxEcran = params.ctxEcran;
    this.popupGerer = false;
    if (params.popupGerer) {
      this.popupGerer = params.popupGerer;
    }
    this.secondaryAff = false;
    if (params.component === "journee_affectations" && params.typeAff === "AS" || params.typeAff === "AE") {
      this.secondaryAff = true;
    }
    if (params.idElement) {
      this.$block.attr("id", params.idElement);
    }
    // Set WAI-ARIA attributes
    this.$block.attr("role", "region");
    this.$block.attr("tabindex", "0");
    this.withFocus = false;
  }

  setButtons(arrayButtons: []): void {
    this.buttons = arrayButtons;
  }

  setButtonsLine(arrayButtons: []): void {
    this.buttonsLine = arrayButtons;
  }

  canAccess(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationAcces &&
      CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces);
  }

  canManage(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion);
  }

  canCreate(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion);
  }

  canCreateExceptionnel(): boolean {
    return this.context && this.context.ctxHabilitation &&
      (this.context.ctxHabilitation.HabilitationGestionNormal || this.context.ctxHabilitation.HabilitationGestionEtendu) &&
      (CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestionNormal) ||
        CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestionEtendu));
  }

  canUpdate(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);
  }

  canDelete(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion);
  }

  canValidate(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationValidation &&
      CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation);
  }

  render(keepVisualizationState?: boolean): CWCommonBlockView<TModel> {
    this.setElement(this.templateHTML);
    this.$title.html(this.titleHTML);
    if (this.iconHidden) {
      this.$icon.hide();
    }
    if (this.expanded === true) {
      this.expand();
    }
    // show the bar
    $(this.el).find(".c-block__titleText").hover(
      (e: JQuery.MouseEventBase): void => {
        this._onHoverBtnTitle(e);
      },
      (e): void => {
        this._onHoverLeftBtnTitle(e);
      }
    );
    // slice the position of the bar
    $(this.el).find(".c-block__titleText").mousemove((event) => {
      if (typeof event.target.className === "string" && event.target.className.indexOf("c-block__titleText") !== -1) {
        $(".phx-block-menu-multi-icons").css("left", event.offsetX + "px");
      }
    });
    if (this.hSeparator === true) {
      this.$el.append("<div class=\"\" style=\"background: none;color: none;border-right: none;border-top: none;border-left: none;\"></div>");
    }
    if (keepVisualizationState !== true) {
      this.hideBlock();
      if (CWSTR.isBlank(this.context) === false) {
        this.showBlock();
      }
    }
    return this;
  }

  _clickHandler(): void {
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0 && this.$icon.css("display") !== "none") {
      if (this.$icon.hasClass("phx-icon-triangle-n")) {
        this.context.ctxBlocEstDeplie = true;
        this.expanded = true;
        this.expand();
      } else {
        this.context.ctxBlocEstDeplie = false;
        this.expanded = false;
        this.collapse();
      }
    }
  }

  _keydownHandler(e: KeyboardEvent): void {
    if ((e.target as HTMLElement).className.split(" ")[0] === "phx-block") {
      const key = e.which || e.keyCode;

      if (key === 13 /* enter */ || key === 32 /* space */) {
        e.preventDefault();
        this._clickHandler();
      }
    }
  }

  setTitle(argHtml?: string): void {
    this.titleHTML = argHtml;
    if (this.canAccess() && !CWSTR.isBlank(this.$title) && this.$title.length > 0) {
      this.$title.html(this.titleHTML);
      // Set WAI-ARIA attributes
      this.$block.attr("labelledby", this.cid + "-vuejournee-component");
      this.$title.attr("id", this.cid + "-vuejournee-component");
    } else {
      // Set WAI-ARIA attributes
      this.$block.attr("labelledby", "");
    }
  }

  /**
   *  When we hover the title, it will show a button bar
   */
  _hoverBtnTitle(event: JQuery.MouseEventBase): void {
    if (event.type === "mouseenter") { // When the mouse enter on the title block
      if (this.menuBar !== true) {
        // Define the JQuery buttons
        this.JQueryBtn = [];
        _.each(this.buttons, (button: any) => {
          const htmlButton = '<span class="btn_vuejour_component btn_vue_' + this.component + " " + button.id + '">' + button.text + '</span>';

          this.JQueryBtn.push($(htmlButton).button());
        });
        // We show a btnBar and handle the click event
        this.$el.find(".c-block__title").prepend(this.JQueryBtn);
        //this.$el.find(".btn_vue_" + this.component).css("float : right");
        $(".btn_vue_" + this.component).click((event) => {
          this._manageButtons(event);
        });
      } else {
        if (!CWSTR.isBlank(this.buttons)) {
          const div = $("<div>").addClass("menu_btn_vue_" + this.component);

          div.addClass("phx-block-menu-multi-icons");
          div.css("display", "inline-block");
          _.each(this.buttons, (button: CWCommonBlockButton) => {
            let span = "";

            if (!CWSTR.isBlank(button.text)) {
              span = '<span class="phx-block-text-tous menu_btn_vuejour_component menu_btn_vue_' + this.component + " " +
                button.id + '" style="display:inline-block; vertical-align:top">' + button.text + '</span>';
            } else {
              span = '<span class="menu_btn_vuejour_component menu_btn_vue_' + this.component + " " + button.id + " " +
                button.icons + '" style="display:inline-block; vertical-align:top"></span>';
            }

            div.append(span);
          });
          this.$el.find(".c-block__title").prepend(div);
          $(div).css("z-index", "100");
          $(div).css("position", "absolute"); // <<< also make it absolute!
          $(div).position({
            my: "left top",
            at: "left bottom",
            of: event.currentTarget
          });
          $(div).css("top", parseInt($(div).css("top")) - 4);
          $("span.menu_btn_vue_" + this.component).click((event) => {
            this._manageButtons(event);
          });
          $("div.phx-block-menu-multi-icons").mouseleave((event) => {
            this._hoverBtnTitle(event);
          });
        }
      }
    } else if (!(event.type === "mouseleave" && $(event.relatedTarget).hasClass("menu_btn_vue_" + this.component))) { // When the mouse get out the title block
      this.$el.find(".c-block__title .btn_vue_" + this.component).remove();
      this.$el.find(".c-block__title .phx-block-menu-multi-icons").remove();
    }
  }

  _onHoverBtnTitle(event: JQuery.MouseEventBase): void {
    const parent = $(event.currentTarget);

    parent.css("position", "relative");
    if (this.menuBar !== true) {
      const element = parent.find(".btn_vue_" + this.component);

      if (element.length === 0) {
        this.JQueryBtn = [];
        _.each(this.buttons, (button) => {
          const htmlButton = '<span class="btn_vuejour_component btn_vue_' + this.component + " " + button.id + '">' + button.text + '</span>';

          this.JQueryBtn.push($(htmlButton).button());
        });
        parent.prepend(this.JQueryBtn);
        $(".btn_vue_" + this.component).click((event) => {
          this._manageButtons(event);
        });
      }
    } else {
      const element = parent.find(".menu_btn_vue_" + this.component);

      if (element.length === 0 && !CWSTR.isBlank(this.buttons)) {
        const div = $("<div>").addClass("menu_btn_vue_" + this.component);

        div.addClass("phx-block-menu-multi-icons");
        div.css({
          "display": "inline-block",
          "position": "absolute",
          "float": "left",
          "z-index": "100",
          "left": event.offsetX,
          "top": "75%",
          "white-space": "nowrap"
        });
        _.each(this.buttons, (button) => {
          let span = "";

          if (!CWSTR.isBlank(button.text)) {
            span = '<span class="phx-block-text-tous menu_btn_vuejour_component menu_btn_vue_' + this.component + " " +
              button.id + '" style="display:inline-block; vertical-align:top">' + button.text + '</span>';
          } else {
            span = '<span class="menu_btn_vuejour_component menu_btn_vue_' + this.component + " " + button.id + " " +
              button.icons + '" style="display:inline-block; vertical-align:top"></span>';
          }
          div.append(span);
        });
        parent.prepend(div);
        $(".menu_btn_vue_" + this.component).click((e) => {
          if (this.component !== "vuejourneehoraire") {
            this._manageButtons(e);
          }
        });
        $(event.currentTarget).children(":not(.menu_btn_vue_" + this.component + ")").bind('mousemove', (e: JQuery.TriggeredEvent) => {
          div.css({
            "left": e.offsetX.toString(),
          });
        });
        div.bind('mouseleave', (e) => {
          let parent = $(e.relatedTarget);

          if (!parent.hasClass("c-block__title")) {
            parent = parent.parents(".c-block__title").first();
          }
          if (parent.find(".menu_btn_vue_" + this.component).length === 0) {
            this.$el.find(".c-block__title .btn_vue_" + this.component).remove();
            this.$el.find(".c-block__title .phx-block-menu-multi-icons").remove();
          }
        });
      }
    }
  }

  _onHoverLeftBtnTitle(event: JQuery.MouseEventBase): void {
    if (!$(event.relatedTarget).hasClass("menu_btn_vue_" + this.component)) {
      this.$el.find(".c-block__title .btn_vue_" + this.component).remove();
      this.$el.find(".c-block__title .phx-block-menu-multi-icons").remove();
    }
  }

  _onHoverBtnLine(event: JQuery.TriggeredEvent): void {
    const parent = $(event.currentTarget);

    parent.css("position", "relative");
    const element = parent.find(".line_btn_vue_" + this.component);
    if (element.length === 0) {
      const div = $("<div>").addClass("line_btn_vue_" + this.component);

      div.addClass("phx-block-menu-multi-icons");
      div.css({
        "display": "inline-block",
        "position": "absolute",
        "float": "left",
        "z-index": "100",
        "left": event.offsetX,
        "top": "75%"
      });

      const key = (event.currentTarget as HTMLElement).className.split(" ")[1];

      let showButton = false;

      _.each(this.buttonsLine, (buttonLine: CWCommonBlockButton) => {
        let valid = true;

        if (buttonLine.validateLine) {
          valid = buttonLine.validateLine(buttonLine.id, key);
        }
        if (valid) {
          showButton = true;
        }

        if (valid) {
          let span = "";
          if (!CWSTR.isBlank(buttonLine.text)) {
            span = '<span style="display:inline-block" class="phx-block-text-line line_btn_vuejour_component line_btn_vue_' + this.component + " " + buttonLine.id + '">' + buttonLine.text + '</span>';
          } else {
            span = '<span style="display:inline-block" class="line_btn_vuejour_component line_btn_vue_' + this.component + " " + buttonLine.id + " " + buttonLine.icons + '"></span>';
          }

          div.append(span);
        }
      });

      $(event.currentTarget).children().bind('mousemove', (e: JQuery.MouseEventBase) => {
        div.css({
          "left": e.offsetX.toString(),
        });
      });

      if (showButton) {
        parent.prepend(div);
      }

      $(".line_btn_vue_" + this.component).click((e) => {
        this._manageButtonsLine(e, key);
        e.stopPropagation();
      });

      div.bind('mouseleave', (e) => {
        this._onHoverLeftBtnLine(e);
      });
    }
  }

  _onHoverLeftBtnLine(event: JQuery.TriggeredEvent): void {
    if (!$((event as any).relatedTarget).hasClass("line_btn_vue_" + this.component)) {
      this.$el.find(".c-block__content .phx-block-menu-multi-icons").remove();
    }
  }

  /**
   *  When we hover a line, it will show a button bar
   */
  _hoverBtnLine(event: JQuery.MouseEventBase, key?: string): void {
    const parent = $(event.currentTarget);

    parent.parent().css("position", "relative");
    if (event.type === "mouseenter") { // When the mouse enter on the title block
      const spans: any = [];

      _.each(this.buttonsLine, (buttonLine: CWCommonBlockButton) => {
        let valid = true;
        let disabled = false;

        if (buttonLine.validateLine) {
          valid = buttonLine.validateLine(buttonLine.id, key);
        }
        if (buttonLine.disableLine) {
          disabled = buttonLine.disableLine(buttonLine.id, key);
        }
        if (valid) {
          let span = "";

          if (disabled) {
            if (!CWSTR.isBlank(buttonLine.text)) {
              span = '<span style="display:inline-block" class="phx-block-text-line line_btn_vuejour_component line_btn_vue_disabled_' + this.component + " " + buttonLine.id + '">' + buttonLine.text + '</span>';
            } else {
              span = '<span style="display:inline-block" class="nohover line_btn_vuejour_component line_btn_vue_disabled_' + this.component + " " + buttonLine.id + " " + buttonLine.icons + '"></span>';
            }
          } else {
            if (!CWSTR.isBlank(buttonLine.text)) {
              span = '<span style="display:inline-block" class="phx-block-text-line line_btn_vuejour_component line_btn_vue_' + this.component + " " + buttonLine.id + '">' + buttonLine.text + '</span>';
            } else {
              span = '<span style="display:inline-block" class="line_btn_vuejour_component line_btn_vue_' + this.component + " " + buttonLine.id + " " + buttonLine.icons + '"></span>';
            }
          }
          spans.push(span);
        }
      });

      if (spans.length > 0) {
        const div = $("<div>").addClass("line_btn_vue_" + this.component);

        div.addClass("phx-block-menu-multi-icons");
        div.css("display", "inline-block");
        _.each(spans, (span: string) => {
          div.append(span);
        });
        this.$el.find(".c-block__content").prepend(div);
        $(".line_btn_vue_" + this.component).click((event) => {
          this._manageButtonsLine(event, key);
          event.stopPropagation();
        });
        $(div).css("z-index", "100");
        $(div).css("position", "absolute"); // <<< also make it absolute!
        $(div).position({
          my: "left top",
          at: "left bottom",
          of: event.currentTarget
        });
        $(div).css("top", parseInt($(div).css("top")) - 3);
        $("div.phx-block-menu-multi-icons").mouseleave((event) => {
          this._hoverBtnLine(event);
        });
        $(".c-block__content-line." + key).mousemove((event) => {
          this._hoverBtnLine(event);
        });
      }

    } else if (event.type === "mousemove") {
      if (UTILS.isFirefox() && !CWSTR.isBlank((event.originalEvent as any).layerX)) {
        this.$el.find(".c-block__content .phx-block-menu-multi-icons").css("left", (event.originalEvent as any).layerX + "px");
      } else {
        this.$el.find(".c-block__content .phx-block-menu-multi-icons").css("left", event.offsetX + "px");
      }
    } else if (!(event.type === "mouseleave" && $(event.relatedTarget).hasClass("line_btn_vue_" + this.component))) {
      this.$el.find(".c-block__content .phx-block-menu-multi-icons").remove();
    }
  }

  /**
   * JS Interface.
   */
  _manageButtons(...args: any[]): void { //eslint-disable-line
    //Empty method
  }

  _manageButtonsLine(...args: any[]): void { //eslint-disable-line
    //Empty method
  }

  setContent(html: any): void {
    if (this.canAccess() && !CWSTR.isBlank(this.$content) && this.$content.length > 0) {
      this.$content.html(html);
    }
  }

  hideIcon(): void {
    this.iconHidden = true;
    if (this.canAccess() && !CWSTR.isBlank(this.$icon) && this.$icon.length > 0) {
      this.collapse();
      this.$icon.hide();
    }
  }

  showIcon(): void {
    this.iconHidden = false;
    if (this.canAccess() && !CWSTR.isBlank(this.$icon) && this.$icon.length > 0) {
      this.$icon.show();
      if (this.context && this.context.ctxBlocEstDeplie) {
        if (this.context.ctxBlocEstDeplie === true) {
          this.expand();
        }
      }
    }
  }

  collapse(): void {
    if (this.canAccess() && !CWSTR.isBlank(this.$icon) && this.$icon.length > 0 &&
      !CWSTR.isBlank(this.$content) && this.$content.length > 0) {
      this.$icon.removeClass("phx-icon-triangle-s");
      this.$icon.addClass("phx-icon-triangle-n");
      this.$content.hide();
      if (this.context.ctxBlocEstDeplie === false) {
        this.expanded = false;
      }
      this.model.trigger("collapse", this);
      // Set WAI-ARIA attributes
      this.$block.attr("aria-expanded", "false");
      this.$content.attr("aria-hidden", "true");
    }
  }

  expand(): void {
    if (this.canAccess() && !CWSTR.isBlank(this.$icon) && this.$icon.length > 0 &&
      !CWSTR.isBlank(this.$content) && this.$content.length > 0) {
      this.$icon.removeClass("phx-icon-triangle-n");
      this.$icon.addClass("phx-icon-triangle-s");
      this.$content.show();
      if (this.context.ctxBlocEstDeplie === true) {
        this.expanded = true;
      }
      this.model.trigger("expand", this);
      // Set WAI-ARIA attributes*/
      this.$block.attr("aria-expanded", "true");
      this.$content.attr("aria-hidden", "false");
    }
  }

  hideBlock(): void {
    this.$el.hide();
  }

  showBlock(): void {
    if (this.canAccess()) {
      this.$el.show();
    }
  }

  showBlockNoCollapsable(): void {
    this.hideBlock();
    this.iconHidden = true;
    if (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0) {
      this.$icon.hide();
    }

    if (!CWSTR.isBlank(this.$content) && this.$content.length > 0) {
      this.$content.show();
      if (this.context.ctxBlocEstDeplie === true) {
        this.expanded = true;
      }
      this.model.trigger("expand", this);
      // Set WAI-ARIA attributes*/
      this.$block.attr("aria-expanded", "true");
      this.$content.attr("aria-hidden", "false");
    }

    this.showBlock();
  }

  /*
   * Returns True when icon is not hidden.
   */
  isEmpty(): boolean {
    return (this.$el.css("display") === "none") ||
      (!CWSTR.isBlank(this.$icon) && this.$icon.length > 0 && this.$icon.css("display") === "none");
  }

  isExpanded(): boolean {
    return this.expanded;
  }

  _manageBlocEstDeplie(): void {
    if (this.context && this.context.ctxBlocEstDeplie === true) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  _generateContent(arg?: any, callbackContent?: () => any): any {//eslint-disable-line
    return $("");
  }

  setContext(context: { [key: string]: any }, anyElement?: any): void { //eslint-disable-line
    //anyElement pourra être un boolean, function, collection, etc
    //Empty method
  }

  _loadData(): void {
    //Empty method
  }

  isAgendaContext(): boolean {
    return this.context && !CWSTR.isBlank(this.context.ctxEcran) && this.context.ctxEcran.indexOf("agenda") >= 0;
  }

  // This method return the style typoe of the etiquette when we send the letter of the status
  public checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes;
    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
        status = 'demande';
        break;
    }
    return status;
  }
}
