import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import TPLAppMenu from '../menu.tpl.html';
import TPLAppMenubar from '../menuZone.tpl.html';
import TPLAppMenuItemNiv1 from '../menu_item_niv1.tpl.html';
import TPLAppMenuItemNiv2 from '../menu_item_niv2.tpl.html';
import TPLAppMenuItemSection from '../menu_item_section.tpl.html';
import { CWSTR } from 'utils/cwStr';
import { CWWorkFlowModel } from '../models/cwWorkFlow.model';
import { CWZoneMenuView } from './cwZoneMenu.view';
import { i18n } from 'src/i18n.js';

/**
 * Main MenuBar View
 *
 *  contains all the different menus
 *
 */
export class CWMenuZone extends Backbone.View<CWWorkFlowModel> {
  zJSON: { [key: string]: any };
  templateMenu: any;
  templateItemNiv1: any;
  templateItemNiv2: any;
  templateItemSection: any;
  zViews: { [key: string]: any };

  components: any;

  // zJSON: undefined, //JSON needed to paint a zone.

  constructor(options: { [key: string]: any }) {
    options.events = {
      // events
    };
    super(options);
    this.template = TPLAppMenubar;
    this.templateMenu = TPLAppMenu;
    this.templateItemNiv1 = TPLAppMenuItemNiv1;
    this.templateItemNiv2 = TPLAppMenuItemNiv2;
    this.templateItemSection = TPLAppMenuItemSection;
    // this.listenTo(GLOBAL_DATA.rights, "sync", this._reloadIcons);
    this.zViews = {};
    this.zJSON = options.zJSON;
    this.model.on("change:zone", this._zonesSwapping, this);
    this.components = null;
  }

  private _zonesSwapping(): void {
    const oldZone = this.model.previous("zone");
    const newZone = this.model.get("zone");

    if (oldZone !== "" && !CWSTR.isBlank(oldZone)) {
      this.zViews[oldZone].$el.hide();
      this.zViews[oldZone].$el.attr("aria-hidden", "true");
    }
    if (newZone !== "" && !CWSTR.isBlank(newZone)) {
      this.zViews[newZone].$el.show();
      this.zViews[newZone].$el.attr("aria-hidden", "false");
    }
    // resize header
    (Backbone as any).trigger("resize:header");
  }

  render(): CWMenuZone {

    const json = { i18n: i18n };

    this.$el.html(this.template(json));
    //Let navigation by keyboard (enter key acts as click pressed).
    // create zone menus
    _.each(this.model.configuration.get("confignav").get("zones"), (zone: { [key: string]: any }): void => {
      const zView = new CWZoneMenuView({
        id: "ctime-menu-zone-" + zone.id,
        model: this.model,
        zJSON: zone
      });

      this.zViews[zone.id] = zView;
      this.$el.find('#cw-menu ').append(zView.render().el);
      // By default it's hidden
      zView.$el.hide();
    });
    return this;
  }
}
