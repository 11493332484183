import { CWBaseModel } from 'core/models/cwBase.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSTR } from 'src/utils/cwStr';

/**
 * Model d'etats des attributs dépendants
 */
export class CWCriteresReinitModel extends CWBaseModel {
  code: any;
  newValeur: any;
  paginated: boolean;

  defaults(): { [key: string]: any } {
    return {
      "vue": "",
      "totfin": "",
      "periode": "",
      "totcoll": "",
      "totindiv": "",
      "population": null,
      "infcompl": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.url = (): string => {
      const optionalParams = (!CWSTR.isBlank(this.newValeur) ? "?valeur=" + this.newValeur : "");
      return Configuration.restRoot + "/rest/edition/definition/" + encodeURIComponent(this.id) + "/critere/" + encodeURIComponent(this.code) + "/reinit" + optionalParams;
    };

    CWPaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  }
}