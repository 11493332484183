import _ from 'underscore';
import TPLListerevenementsFilter from '../cwFilter.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWComboStatutListerEvtGenCollection } from '../models/cwComboStatutListerEvtGen.collection';
import { CWFilterListerEvtGen } from '../models/cwFilterListerEvtGen.model';
import { CWFORMS } from 'utils/cwForms';
import { CWListerEvtGenWorkflow } from '../models/cwListerEvtGen.workflow';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';
import { ViewOptions } from 'Backbone';


export interface CWListerEvtFilterContentViewOptions extends ViewOptions {
  workflow?: CWListerEvtGenWorkflow;
}

/**
 * EditableGridView to be used when validation action is required.
 */
export class CWListerEvtFilterContentView extends CWBaseFormView<CWFilterListerEvtGen> {

  public workflow: CWListerEvtGenWorkflow;
  public comboStatut: CWComboBoxView2;
  public model: CWFilterListerEvtGen;
  public typeFormatByClass: { [key: string]: string };
  private iconLoupe: string;
  public showProfils: boolean;
  public comboSearchProfils: CWComboBoxView2;

  constructor(options: CWListerEvtFilterContentViewOptions) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_changeModel",
      'change .profil': '_changeProfil'
    }, CWTYPE.LONG.events(), CWTYPE.DATE.events(), CWTYPE.CUSTOM.events(), options.events);
    super(options);
    this.template = TPLListerevenementsFilter;
    if (options.workflow) {
      this.workflow = options.workflow;
      this.listenTo(this.workflow, "manageComboProfiles", this.manageComboSearchProfile);
    }
    this.model = new CWFilterListerEvtGen();
    this._initCombos();
    this.typeFormatByClass = {};
    this.typeFormatByClass["matricule"] = "";
    this.typeMaskByClass["datedeb"] = "DATE_L";
    this.typeMaskByClass["datefin"] = "DATE_L";
    this.iconLoupe = UTILS.getSVGIcon('loupe');
  }

  _initCombos(): void {
    const comboSearchProfilsHab = this.workflow.getHabContext().copy();

    this.comboStatut = new CWComboBoxView2({
      ws: new CWComboStatutListerEvtGenCollection([], {
        name: "utilabsence",
        comboId: "statut",
        workflow: this.workflow
      }),
      name: "statut",
      width: "100",
      habContext: this.workflow.getHabContext(),
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      }
    });
    this.comboSearchProfils = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "profilwf",
        comboId: "utilisateur"
      }),
      habContext: comboSearchProfilsHab,
      name: "profil",
      required: true,
      placeholder: i18n.t('common:placeholder_combo.combo_roles')
    });
    this.comboSearchProfils.fetchCombo((): void => {
      this.workflow.checkShowProfils(this.comboSearchProfils.coll);
      this.workflow.trigger("comboProfilsFetched");
      this.workflow.trigger("manageComboProfiles");
    });
  }

  _changeModel(event: JQueryEventObject, data: { [key: string]: any }, silentValue: boolean): void {
    this._change(event, data, silentValue);
    for (const i in this.model.attributes) {
      if (CWSTR.isBlank(this.model.get(i))) {
        this.model.unset(i, { silent: true });
      }
    }
  }

  _getModel(): CWFilterListerEvtGen {
    return this.model;
  }

  render(): CWListerEvtFilterContentView {
    const json = { "i18n": i18n, 'icon': this.iconLoupe };
    const paramIdCollab = this.workflow.pDiversIdCollabModel.get("valeur");

    this.$el.append(this.template(json));
    if (paramIdCollab === "matric_aux") {
      $(this.el).find(".phx-column1 div.matriculeFilter").hide();
    } else {
      $(this.el).find(".phx-column1 div.matriculeAuxFilter").hide();
    }
    if (this.workflow.context.ctxGestionCollective === false) {
      this.$el.find(".searchcollabField").hide();
    }
    //Render combos
    $(this.el).find(".cmbStatut").html(this.comboStatut.render().el);
    $(this.el).find(".cmbProfil").html(this.comboSearchProfils.render().el);
    //Render date pickers
    CWFORMS.setDatepicker(this, ".datedeb");
    CWFORMS.setDatepicker(this, ".datefin");
    return this;
  }

  _updateHabilitationCombo(): void {
    this.comboStatut.coll.setHabContext(this.workflow.getHabContext().clone());
  }

  private _changeProfil(): void {
    this.workflow.isSelectedProfils = true;
  }

  private manageComboSearchProfile(): void {
    if (this.workflow.showProfils && this.workflow.context.ctxEcran !== "suivicollab") {
      this.$el.find(".cmbProfil").show();
    } else {
      this.$el.find(".cmbProfil").hide();
    }
  }
}
