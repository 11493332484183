import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLParametragebresultatsEditedRowCompteurs from '../editedRowCompteurs.tpl.html';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWCompteursTableModel } from '../models/cwCompteursTable.model';
import { CWEditableGridModel } from 'src/core/grids/editablegrid/cwEditableGrid.model';
import { CWEditedRowView } from 'core/views/cwEditedRow.view';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWParametragebresultatsWorkflow } from '../models/cwParametragebresultats.workflow';
import { CWSTR } from 'utils/cwStr';
import { FORMS } from 'utils/forms.js';
import { i18n } from 'src/i18n.js';


export class CWEditedRowCompteursView extends CWEditedRowView {

  workflow: CWParametragebresultatsWorkflow;
  habContext: CWHabilitationContext;
  context: { [key: string]: any };
  comboCompteur: CWComboBoxView2;
  comboType: CWComboBoxView2;
  comboPeriodicites: CWComboBoxView2;
  comboPeriodeValo: CWComboBoxView2;

  constructor(options: Backbone.ViewOptions<CWCompteursTableModel> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_changeView"
    }, options.events);
    options.className = "c-form c-form--fixedButtons";
    super(options);
    this.habContext = options.habContext;
    this.workflow = options.workflow
    /**
     * HTML template of the view
     */
    this.template = TPLParametragebresultatsEditedRowCompteurs;
    if (options && options.context) {
      this.context = options.context;
      if (this.context.ctxHabilitation && !CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion)) {
        this.habContext = new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: this.context.ctxHabilitation.HabilitationGestion,
          natGest: ""
        });
      }
    }
    this.initCombos();
  }

  /**
   * Paints the row view in edition mode of the UC
   */
  render(): CWEditedRowCompteursView {
    const json = { "i18n": i18n };
    const model = this._getModel();
    const isLigneEmpty = (this.model && this.model.get("value") && this.model.get("value").get("compteur") && this.model.get("value").get("compteur").code < 0) ? true : false;


    if (!isLigneEmpty) {
      this.$el.html(this.template(json));
      $(this.el).find(".cmbCompteur").html(this.comboCompteur.render().el);
      $(this.el).find(".cmbTypeRes").html(this.comboType.render().el);
      $(this.el).find(".cmbPeriodicite").html(this.comboPeriodicites.render().el);
      this.comboPeriodicites.setFilter(this._filterComboPeriodicite(model as CWCompteursTableModel));
      $(this.el).find(".cmbPeriodeValo").html(this.comboPeriodeValo.render().el);
      if (model.get("compteurs") && !CWSTR.isBlank(model.get("compteurs").format)) {
        this.$el.find(".format").html(model.get("compteurs").format);
      }
    }
    if (!model.isNew() && isLigneEmpty) { //(CWSTR.isBlank(model.get("compteur").code) || model.get("compteur").code < 0)) {      
      this.$el.empty();
      this.workflow.table.viewRowTitle = i18n.t('common:parametragebresultats.table_ajout_ligne');
    } else {
      this._manageTypeResultat();
      this.workflow.table.viewRowTitle = i18n.t('common:parametragebresultats.title_volet');
    }
    //Error management
    this.stopListening(model, "invalid");
    this.listenTo(model, "invalid", this._showValidationErrors);
    // Clear current validation erros
    this._cleanValidationErrors();
    this._mapModelToForm(this.$el, model);
    return this;
  }

  _changeView(event: JQueryEventObject, data: any): void {
    const className = event.target.className.split(" ")[0];
    const model = this._getModel();

    this._change(event, data);
    //Infobulle if email is too long
    if (className === "typeresultat.code") {
      this._manageTypeResultat();
      model.get("typeresultat").libelle = ""; //le combobox change le code mais pas la libellé
      if (CWSTR.getElValue(model, "typeresultat.code") === "A" && CWSTR.getElValue(model, "periodicite.code") === "J") {
        //empty periodicite
        CWSTR.setElValue(model, "periodicite.code", null);
        this.comboPeriodicites.setItem({ code: "", label: "" });
      }
    }
  }

  _manageTypeResultat(): void {
    const model = this._getModel();

    if (CWSTR.getElValue(model, "typeresultat.code") !== "A" && CWSTR.getElValue(model, "typeresultat.code") !== "R") {
      this._disableCombos();
    } else {
      this._enableCombos();
    }
  }

  _enableCombos(): void {
    const lPosPeriodicite = this.$el.find("label[for^='periodicite.code']");
    const lPosPeriodevalo = this.$el.find("label[for^='periodevalo.code']");

    FORMS.setFieldReadonly(this.$el.find(".periodicite\\.code"), false, false);
    FORMS.setFieldReadonly(this.$el.find(".periodevalo\\.code"), false, false);
    if (!lPosPeriodicite.hasClass("cw-required")) {
      lPosPeriodicite.addClass("cw-required");
    }
    if (!lPosPeriodevalo.hasClass("cw-required")) {
      lPosPeriodevalo.addClass("cw-required");
    }
  }

  _disableCombos(): void {
    const model = this._getModel();
    const lPosPeriodicite = this.$el.find("label[for^='periodicite.code']");
    const lPosPeriodevalo = this.$el.find("label[for^='periodevalo.code']");

    //empty periodicite
    CWSTR.setElValue(model, "periodicite.code", null);
    this.comboPeriodicites.setItem({ code: "", label: "" });
    //empty periodevalo
    CWSTR.setElValue(model, "periodevalo.code", null);
    this.comboPeriodeValo.setItem({ code: "", label: "" });
    //Set to readonly
    FORMS.setFieldReadonly(this.$el.find(".periodicite\\.code"), true, false);
    FORMS.setFieldReadonly(this.$el.find(".periodevalo\\.code"), true, false);
    if (lPosPeriodicite.hasClass("cw-required")) {
      lPosPeriodicite.removeClass("cw-required");
    }
    if (lPosPeriodevalo.hasClass("cw-required")) {
      lPosPeriodevalo.removeClass("cw-required");
    }
  }

  _filterComboPeriodicite(model: CWCompteursTableModel): any {
    const filter = (collection: Array<any>): Array<any> => {
      const typeRes = CWSTR.getElValue(model, "typeresultat.code");
      const filterCollection: Array<any> = [];

      _.each(collection, (model: any) => {
        if (typeRes === "A") {
          if (model.attrs.code !== "J") {
            filterCollection.push(model);
          }
        } else {
          filterCollection.push(model);
        }
      });
      return filterCollection;
    };
    return filter;
  }


  canEditCallback(model: CWEditableGridModel, callback: (canEdit: boolean) => void): void {
    // if (!(model && model.get("compteur") && _.isNumber(model.get("compteur").code) && model.get("compteur").code < 0)) {
    //code <0 -> ligne de séparation
    if (!model.isNew() && (CWSTR.isBlank(model.get("compteur").code) || model.get("compteur").code < 0)) {
      this.workflow.table.viewRowTitle = i18n.t('common:parametragebresultats.table_ajout_ligne');
    } else {
      this.workflow.table.viewRowTitle = i18n.t('common:parametragebresultats.title_volet');
    }
    callback(true);
    //}
  }

  initCombos(): void {
    //Combo Contexte Utilisation
    this.comboCompteur = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "ls",
        comboId: "compteur?tri=code"
      }),
      name: "compteur.code",
      required: true,
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      habContext: this.getHabContext()
    });
    this.comboType = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "utilgroupecpt",
        comboId: "typeresultats"
      }),
      name: "typeresultat.code",
      required: true,
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      habContext: this.getHabContext()
    });
    this.comboPeriodicites = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "utilresultatsgroupecpt",
        comboId: "periodicites"
      }),
      name: "periodicite.code",
      required: true,
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      habContext: this.getHabContext()
    });
    this.comboPeriodeValo = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "ls/divcod",
        comboId: "periodevalorisation"
      }),
      name: "periodevalo.code",
      required: true,
      optionsRender: (value: { [key: string]: any }): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      habContext: this.getHabContext()
    });
  }
}