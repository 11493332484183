import * as Backbone from 'Backbone';
import planningDialogPersoSavecriteriaTPL from '../dialog_perso_savePlanningCriteria.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWSauveGarderPreferenceModel as SauveGarderPlanresPreference } from '../models/cwSauveGarderPlanrespPreference.model';
import { CWSauveGarderPreferenceModel as SauveGarderPlanmedPreference } from '../models/cwSauveGarderPlanmedPreference.model';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { CWSTR } from 'utils/cwStr';
import { UTILS } from 'utils/utils.js';

//Dialog for personalisation criteria (EVO 518)

export class CWPersoSaveCriteriaDialog extends CWBaseFormView {

  perscriu: number;
  perscrig: number;
  workflow: any;
  parent: any;
  uc: string;
  options: string;
  level: string;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {

    options.events = {
      "click .options .bloq_user": "_checkButtonDelete",
      "click .options .bloq_allusers": "_checkButtonDelete",
      "change .options .bloq_user": "_changeCritere",
      "change .options .bloq_allusers": "_changeCritere",
    };

    super(options);
    this.template = planningDialogPersoSavecriteriaTPL;

    this.perscriu = 1;
    this.perscrig = 0;

    if (options.workflow) {
      this.workflow = options.workflow;
    }

    if (options.uc) {
      this.uc = options.uc;
    }

    if (options.parent) {
      this.parent = options.parent;
      //set the title to the dialog
      this.parent._setTitle(i18n.t('plans.planresp.dialog_persosavecriteria_title'));
    }

    this.options = "noperso";
    this.listenTo(this, "_saveCriteria", this._saveCriteria);
    this.listenTo(this, "_cancel", this._cancel);

  }

  _changeCritere(event: JQueryEventObject): void {

    const target = event.currentTarget;
    const buttonId = target.className.split(" ")[0];
    let isDisabled = true;

    // Le traitement attendu est similaire à celui effectué pour l’option « Enregistrer les éléments courants »
    // des « critères avancés », sans tenir compte du critère courant, enregistrement de tous les éléments
    // courants du planning (population, période, présentation des collaborateurs, etc…)

    this.options = "genericData"; // save only generic data.

    if (buttonId === "bloq_user") {
      this.$el.find('.aff_allusers').removeAttr('checked');
      this.$el.find('.aff_user').attr('checked', 'true');
      this.level = "privateUser";
    } else {
      this.$el.find('.aff_user').removeAttr('checked');
      this.$el.find('.aff_allusers').attr('checked', 'true');
      this.level = "allUsers";
    }

    if (this.$el.find(".aff_user").is(":checked") ||
      this.$el.find(".aff_allusers").is(":checked")) {
      isDisabled = false;
    }
    this.$el.parent().find(".cwDialog-buttons .bt-col-blue").prop({
      "disabled": isDisabled
    });


  }

  _saveCriteria(): void {

    let persoPlanning: any;
    let context: any;

    // Le traitement attendu est similaire à celui effectué pour l’option « Enregistrer
    // les éléments courants » des « critères avancés », sans tenir compte du critère courant,
    // enregistrement de tous les éléments courants du planning (population, période, présentation des collaborateurs, etc…)

    //  TraitPlc_perscri et TraitPlc_nivcritperso

    if (this.$el.find(".bloq_allusers input").is(":not(:checked)") && this.$el.find(".bloq_user input").is(":checked")) {
      this.level = "privateUser";
    } else if (this.$el.find(".bloq_allusers input").is(":checked") && this.$el.find(".bloq_user input").is(":not(:checked)")) {
      this.level = "allUsers";
    }

    switch (this.level) {
      case "privateUser": // « U » : personnalisation au niveau « Utilisateur »
        //for user in private mode
        this.perscriu = 1;
        break;
      case "connectedUser": // « U » : personnalisation au niveau « Utilisateur »
        // to the connected user
        this.perscriu = 1;
        break;
      case "allUsers": // « G » : personnalisation au niveau « Général »
        // for all users (public)
        this.perscriu = 0;
        this.perscrig = 1;
        break;
    }

    // new data checkboxes 518

    // perscri - c'est le checkbox "Avec les options de personnalisation"

    // si TraitPlc_nivcritperso=U && TraitPlc_perscri=1 : alors perscriu=1 et perscrig=0
    // si TraitPlc_nivcritperso=G && TraitPlc_perscri=1 : alors perscriu=0 et perscrig=1
    // si TraitPlc_perscri=0 : perscriu=0 et perscrig=0

    // auto save data with the new WS.
    if (this.uc === 'planmed') {
      persoPlanning = new SauveGarderPlanmedPreference();
      context = {
        onglet: "planmed",
        foncCour: this.level === "allUsers" ? "PER_PERSOGEN.M" : "RES_PLMED",
        natGest: ""
      }
    } else {
      persoPlanning = new SauveGarderPlanresPreference();
      context = {
        onglet: "planresp",
        foncCour: this.level === "allUsers" ? "PER_PERSOGEN.M" : "RES_GCOLLAB.G",
        natGest: ""
      }
    }

    // cloned preferences
    persoPlanning.set("preferences", this.workflow.preferences.get("preferences").clone());

    persoPlanning.set("perscriu", this.perscriu);
    persoPlanning.set("perscrig", this.perscrig);

    persoPlanning.setHabContext(new CWHabilitationContext(context));

    if (!persoPlanning.isValid()) {
      this._showValidationErrors(persoPlanning, persoPlanning.validationError);
      // add validation errors.
      CWLOG.debug("preferences are not valid..")

    } else {

      this._cleanValidationErrors();

      persoPlanning.url = this.uc === 'planmed' ? Configuration.restRoot + "/rest/planning/preference?medical" : Configuration.restRoot + "/rest/planning/preference";

      persoPlanning.unset("defaut");
      persoPlanning.unset("poptype");
      persoPlanning.unset("poplib");
      persoPlanning.unset("popid");
      persoPlanning.unset("population");
      persoPlanning.unset("profils");
      persoPlanning.unset("ident");

      if (this.uc === 'planmed') {
        UTILS.showUsecaseHidder("planmed");
      } else {
        UTILS.showUsecaseHidder("planresp");
      }


      persoPlanning.save(null, {
        success: (fresh: any) => {
          CWLOG.debug("save preferences");
          if (!CWSTR.isBlank(this.workflow.groupsColl)) {
            this.workflow.groupsColl.keepState = false;
          }
          this.workflow.preferences.set("perscriu", fresh.get("perscriu"));
          this.workflow.preferences.set("perscrig", fresh.get("perscrig"));
          if (this.uc === 'planmed') {
            UTILS.hideUsecaseHidder("planmed");
            if (this.workflow.prefUtilisateurExiste) {
              this.workflow.prefUtilisateurExiste.notFecth = false;
            }
          } else {
            UTILS.hideUsecaseHidder("planresp");
            if (this.workflow.prefUtilisateurExiste) {
              this.workflow.prefUtilisateurExiste.notFecth = false;
            }
          }

        },
        error: () => {
          if (this.uc === 'planmed') {
            UTILS.hideUsecaseHidder("planmed");
          } else {
            UTILS.hideUsecaseHidder("planresp");
          }
        }
      });
    }

    this.parent.close();
  }

  _checkButtonDelete(): void {

    let isDisabled = true;

    if (this.$el.find(".bloq_user input").is(":checked") ||
      this.$el.find(".bloq_allusers input").is(":checked")) {

      isDisabled = false;
    }

    this.$el.find(".dialogPersoSaveCriteria .btnSave").button({
      disabled: isDisabled
    });

  }

  render(): CWPersoSaveCriteriaDialog {
    const json = { i18n: i18n };

    this.$el.html(this.template(json));
    this.$el.find("button").button();
    // ::::::::::: load RADIO BUTTON VALUES for EVO 518 :::::::
    if (GLOBAL_DATA.rights.get("RES_GCOLLAB.G") || GLOBAL_DATA.rights.get("RES_PLMED")) {
      if (this.workflow.preferences.get("perscrig").toString() === "0" && this.workflow.preferences.get("perscriu") === 1) {
        this.$el.find('.aff_user').attr('checked', 'true');
        this.$el.find('.aff_allusers').removeAttr('checked');
      } else if (this.workflow.preferences.get("perscrig") === 1 && this.workflow.preferences.get("perscriu").toString() === "0") {
        this.$el.find('.aff_user').removeAttr('checked');
        this.$el.find('.aff_allusers').attr('checked', 'true');
      } else if (this.workflow.preferences.get("perscrig") === 1 && this.workflow.preferences.get("perscriu") === 1) {
        this.$el.find('.aff_user').removeAttr('checked');
        this.$el.find('.aff_allusers').attr('checked', 'true');
      } else if (this.workflow.preferences.get("perscrig").toString() === "0" && this.workflow.preferences.get("perscriu").toString() === "0") { // by default first checkbox checked.
        this.$el.find('.aff_user').attr('checked', 'true');
        this.$el.find('.aff_allusers').removeAttr('checked');
      }
    }
    if (this.workflow.module === "planresp" && this.workflow._isSimulationActive()) {
      this.$el.find('.aff_user').attr('checked', 'true');
      this.$el.find('.aff_allusers').removeAttr('checked');
      this.$el.find('.bloq_allusers').hide();
    }
    return this;
  }

  _cancel(): void {
    this.parent.close();
  }
}
