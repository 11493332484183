import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Model of Data of SearchListeGroupes
 */
export class CWSearchListeGroupesData extends CWBaseModel {
  withDate: boolean;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.withDate = true;
  }

  defaults(): { [key: string]: any } {
    return {
      "groupe": "",
      "date": "",
      "demande": false,
      "souhait": false
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } | null {
    const errors: { [key: string]: any } = {};

    if (this.withDate) {
      if (CWSTR.isBlank(attrs.date)) {
        errors.date = i18n.t('common:required', { "0": i18n.t('common:resultatgrpcompteurs.date') });
      } else {
        const errorMsg = CWTYPE.DATE.validate(attrs.date);
        if (!CWSTR.isBlank(errorMsg)) {
          errors.date = errorMsg;
        }
      }
    } else {
      if (CWSTR.isBlank(attrs.groupe)) {
        errors.groupe = i18n.t('common:required', { "0": i18n.t('common:resultatgrpcompteurs.groupe') });
      }
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}