import { BaseModel } from 'core/models/base.model';
import { STR } from 'utils/str.js';

/**
 * Selecteur Chemin Niveau Model
 */
export var SelecteurCheminNivModel = BaseModel.extend({ //Backbone.Model.extend({

  /**
   * Default data model
   *	code : null,
   *	libelle : "",
   *	niveau : null,
   *	abrege : "",
   *	codestruct : null,
   *	rattcollab : false,
   *	nbcollab : null
   *}
   */
  defaults: {
    code: null,
    libelle: "",
    niveau: null,
    abrege: "",
    codestruct: null,
    rattcollab: false,
    nbcollab: null
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }

});
