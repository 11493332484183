module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 groups.forEach ( function(grp) { 
__p+='\n';
 if(scope){
__p+=' '+
((__t=(scope))==null?'':__t)+
' ';
 } 
__p+='.phx-dynamicStyle-cell-'+
((__t=(type))==null?'':__t)+
'_'+
((__t=(UTILS.escapeJQueryString(grp.cod)))==null?'':__t)+
'{\ncolor : rgb('+
((__t=(grp.coul.avp.coder))==null?'':__t)+
','+
((__t=(grp.coul.avp.codeg))==null?'':__t)+
','+
((__t=(grp.coul.avp.codeb))==null?'':__t)+
') !important;\nbackground : rgb('+
((__t=(grp.coul.arp.coder))==null?'':__t)+
','+
((__t=(grp.coul.arp.codeg))==null?'':__t)+
','+
((__t=(grp.coul.arp.codeb))==null?'':__t)+
') !important; font-weight: bold;\nfill : rgb('+
((__t=(grp.coul.avp.coder))==null?'':__t)+
','+
((__t=(grp.coul.avp.codeg))==null?'':__t)+
','+
((__t=(grp.coul.avp.codeb))==null?'':__t)+
') !important;\n}\n';
 }); 
__p+='';
}
return __p;
};
