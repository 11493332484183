import _ from 'underscore';
import { CWBaseGridView, gridColumnAlign, validsWidthTypesForGridsColums } from '../basegrid/cwBaseGrid.view';
import { CWDataGridView } from './cwDataGrid.view';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { UTILS } from 'utils/utils.js';
import { View, ViewOptions } from 'Backbone';

export class CWDataGridCellThView extends View<CWReadOnlyModel> {

  /**
   * Event when click on a column header.
   */
  /**
   * Event when click on a checkbox on the header of the multisel column.
   */
  events(): { [key: string]: any } {
    return {
      "click": "_clickListener",
      "click .ui-resizable-handle": function (): boolean {
        // If I'm trying to resize and I do a silly click then it nothing happens.
        return false;
      },
      "click .phx-tristate-box": "_selectUnselectAll",
      "click .header": "headerClick"
    }
  }

  private text: string;
  private hIndex: number;
  private cellIndex: number;
  private last: boolean;
  private dataGrid: CWDataGridView;
  private columnCode: string;
  private sortButton: JQuery;
  private sortButtonIcon: JQuery;
  private multiselection: boolean;
  private minWidth: number;
  private minWidthMode: string;
  private colWidth: validsWidthTypesForGridsColums;
  private customHeaderClick: (event: JQueryEventObject, table: CWBaseGridView) => void;
  private align: gridColumnAlign;
  /**
   * View of the Headers Cells.
   */
  constructor(options: ViewOptions<CWReadOnlyModel> | any) {
    options.tagName = options.tagName ? options.tagName : "th";
    super(options);
    this.text = options.text;
    this.hIndex = options.hIndex;
    this.cellIndex = options.cellIndex;
    this.last = options.last;
    this.dataGrid = options.dataGrid;
    this.columnCode = options.columnCode;
    this.sortButton = null;
    this.sortButtonIcon = null;
    this.multiselection = options.multiselection ? true : false;
    this.listenTo(this.dataGrid.model, "rows:checked", this._manageChecking);
    this.minWidth = options.minWidth || 45;
    this.minWidthMode = options.minWidthMode;
    this.colWidth = options.colWidth;
    this.customHeaderClick = null;
    if (options && options.customHeaderClick) {
      this.customHeaderClick = options.customHeaderClick;

    }
    this.align = options.align;
  }

  /**
   * Manages the Header click and sorting if is enabled for that column.
   */
  _clickListener(): void {
    if (this.columnCode !== "phx-last") {
      const currentColumn = this.dataGrid.currentVue._columns[this.columnCode];

      if (currentColumn.sortable) {
        if (!_.isNull(currentColumn.sort)) {
          currentColumn.sort = !currentColumn.sort;
        } else {
          let otherColumns = null;

          this.dataGrid.currentVue._columns[this.columnCode].sort = true;
          this.sortButton.removeClass("d-none");
          otherColumns = _.difference(_.keys(this.dataGrid.currentVue._columns), [this.columnCode]);
          _.each(otherColumns, (column) => {
            this.dataGrid.currentVue._columns[column].sort = null;
          }, this);
        }
      }
      this.trigger("click", this.columnCode);
    }
  }

  /**
   * Hide the Sorting button.
   */
  hideSortButton(): void {
    if (this.sortButton && _.isNull(this.dataGrid.currentVue._columns[this.columnCode].sort)) {
      this.sortButton.addClass("d-none");
      this.sortButton.addClass("phx-sort-state-asc");
      this.sortButton.removeClass("phx-sort-state-desc");
    }
  }

  /**
   * Makes the columns resizeables.
   */
  resizable(brother: View): void {
    const column = $(this.el).find("div");
    const datagrid = this.dataGrid;
    column.resizable({
      handles: "e",
      minWidth: this.minWidth,
      resize: (event, ui) => {
        const newWidth = ui.size.width + "px";
        ui.helper.parent().css("width", newWidth);
        $(brother.el).width(newWidth);
      },
      stop: (event, ui) => {
        const difference = ui.size.width - ui.originalSize.width;
        const minWidhtText = $("table.c-grind__headerScroll__table", datagrid.el).css("min-width");
        const minWidth = parseInt(minWidhtText.substring(0, minWidhtText.length - 2)) + difference;
        datagrid._setTableMinWidth(minWidth);
        if (this.minWidthMode) {
          const parentModified = $(ui.element).closest('th').toArray();
          const headerNodesSelection = $(".c-grind__headerScroll table", datagrid.el).find("tr th").toArray();

          const index = _.findIndex(headerNodesSelection, (item) => {
            return (parentModified[0].isEqualNode(item));
          })
          if (index !== -1) {
            $(".c-grind__scroll table.c-grind__headerScroll__table", datagrid.el).find("thead tr th").get(index).style.minWidth = ui.size.width + 'px';
          }

        }
        LOG.info(difference + " " + minWidhtText + " " + minWidth + "px");
      }
    });
  }

  /**
   * Renders the header view.
   */
  render(): CWDataGridCellThView {
    const content = $("<div class='thContainer'></div>");

    $(this.el).addClass("phx-th" + this.hIndex + this.cellIndex);
    if (this.colWidth !== "auto") {
      $(this.el).addClass("col-" + this.colWidth);
    } else {
      $(this.el).addClass("col");
    }

    // ***** Align ****
    switch (this.align) {
      case "center": {
        $(this.el).addClass("d-flex justify-content-center");
        break;
      }
      case "left": {
        $(this.el).addClass("d-flex justify-content-start");
        break;
      }
      case "right": {
        $(this.el).addClass("d-flex justify-content-end");
        break;
      }
      default: {
        //Do nothing
      }
    }
    if (this.multiselection) {
      content.append("<div class='phx-tristate-box phx-tristate-unchecked'></div>");

      if (this.dataGrid.hideMultiSelectionCheckBox) {
        this.$el.hide();
      }
    } else {
      const textContent = _.result(this, "text");
      const contentEntete = $("<div class='cw-enteteColonne'>");

      if (!CWSTR.isBlank(textContent)) {
        const alignment = (this.dataGrid.currentVue._columnsAlign && this.dataGrid.currentVue._columnsAlign[this.columnCode]) ?
          this.dataGrid.currentVue._columnsAlign[this.columnCode] : "left";

        switch (alignment) {
          case "right":
            content.css("text-align", "right");
            break;
          case "center":
            content.css("text-align", "center");
            break;
          default:
            content.css("text-align", "left");
            break;
        }

        //content.append($("<div class='title cw-texteEnteteColone'>" + textContent + "</div>"));
        contentEntete.append($("<div class='title cw-texteEnteteColone'>" + textContent + "</div>"));
      }

      content.css("width", "100%");
      this.sortButton = $("<div class='cw-icone-sort-grid cw-triggerClick'></div>"); //$("<span></span>");//
      this.sortButton.css("border", "none");
      this.sortButton.append(UTILS.getSVGIcon('fleche_haut_bas', 'phx-sort-button haut_bas'));
      //content.append(this.sortButton);

      this.sortButton.addClass("d-none");
      this.sortButton.addClass("phx-sort-state-asc");
      this.sortButton.find(".phx-sort-button.bas").addClass("d-none");
      this.sortButton.attr("role", "button");
      this.sortButton.attr("aria-label", i18n.t('common:ARIA_label.tri'));
      this.sortButton.attr("tabindex", "0");
      contentEntete.append(this.sortButton);
      content.append(contentEntete);
    }
    $(this.el).append(content);

    // Manage the selection checkbox
    this._manageChecking();
    //accessibility
    this.$el.attr({ "role": "columnheader" });
    //Set all inputs of the header to -1 to avoid tab selection
    if (this.$el.find("a").is("a")) { this.$el.find("a").attr("tabindex", "-1"); }
    if (this.$el.find("select").is("select")) { this.$el.find("select").attr("tabindex", "-1"); }
    if (this.$el.find(":input").is(":input")) { this.$el.find("input").attr("tabindex", "-1"); }
    if (this.$el.find("button").is("button")) { this.$el.find("button").attr("tabindex", "-1"); }
    if (this.last === true) {
      content.parent().css("display", "none");
    }

    return this;
  }

  /**
   * Manages when click on the header checkbox on multiselection grid.
   */
  _manageChecking(): void {
    if (this.multiselection) {
      const coll = this.dataGrid.model.coll;
      const totalRecords = coll.paginated ? coll.totalRecords : coll.length;
      if (this.dataGrid.model.multiselectColl.length === 0) {
        this._setTristateUnchecked();
      } else if (this.dataGrid.model.multiselectColl.length !== totalRecords) {
        this._setTristateUndefined();
      } else {
        this._setTristateChecked();
      }
    }
  }

  /**
   * Sets the Tristate checkbox to Checked state.
   */
  _setTristateChecked(): void {
    const jQtarget = this.$el.find(".phx-tristate-box");

    jQtarget.html('<span class="ui-icon ui-icon-closethick"></span>');
    jQtarget.addClass("phx-tristate-checked");
    jQtarget.removeClass("phx-tristate-unchecked");
    jQtarget.removeClass("phx-tristate-undefined");
    if (this.dataGrid.hideMultiSelectionCheckBox === true) {
      jQtarget.css("display", "none");
    }
  }

  /**
   * Sets the Tristate checkbox to Unchecked state.
   */
  _setTristateUnchecked(): void {
    const jQtarget = this.$el.find(".phx-tristate-box");

    jQtarget.html('');
    jQtarget.removeClass("phx-tristate-checked");
    jQtarget.addClass("phx-tristate-unchecked");
    jQtarget.removeClass("phx-tristate-undefined");
    if (this.dataGrid.hideMultiSelectionCheckBox === true) {
      jQtarget.css("display", "none");
    }
  }

  /**
   * Sets the Tristate checkbox to Undefined state.
   */
  _setTristateUndefined(): void {
    const jQtarget = this.$el.find(".phx-tristate-box");

    jQtarget.html('<span class="ui-icon ui-icon-minusthick"></span>');
    jQtarget.removeClass("phx-tristate-checked");
    jQtarget.removeClass("phx-tristate-unchecked");
    jQtarget.addClass("phx-tristate-undefined");
    if (this.dataGrid.hideMultiSelectionCheckBox === true) {
      jQtarget.css("display", "none");
    }
  }

  /**
   * Perform a Selection/Unselection on all rows in a multiselectable grid.
   */

  _selectUnselectAll(): void {
    const state = $(event.target).hasClass("phx-tristate-unchecked");

    if (state) {
      this.dataGrid.selectAllRows();
    } else {
      this.dataGrid.clearSelectedRows();
    }
    this.dataGrid.model.trigger("tristate:checked", state);
  }

  /**
   * Set the text of the header
   *
   */
  setHeaderTitle(title: string): void {
    const $span = $("span.title", this.$el);

    if ($span && $span.length > 0) {
      $span.html(title);
    }
  }

  /**
   * 
   */
  private headerClick(event: JQueryEventObject): void {
    if (this.customHeaderClick) {
      this.customHeaderClick(event, this.dataGrid);
    }
  }

  /**
   * Show the Sorting button.
   */
  showSortButton(): void {
    if (this.sortButton && this.dataGrid.currentVue._columns[this.columnCode].sort === true) {
      this.sortButton.removeClass("d-none");
    }
  }
}