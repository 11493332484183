import * as Backbone from 'Backbone';
import _ from 'underscore';
import collabDetailSelecteurRefDialogTPL from '../cwDetailSelecteurRefDialog.tpl.html';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWFORMS } from 'src/utils/cwForms';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

/**
 * Selecteur Referentiel Dialog View
 */
export class CWSelecteurRefDialogView extends Backbone.View {

  searchTerm: string;
  parent: any;
  coll: any;
  diversIdCollab: string;
  table: CWDataGridView;
  sansUseCase: boolean;

  constructor(options: { [key: string]: any }) {
    options.events = _.extend({
      "change input.collab-recherche-simulation-chk": "_checkboxSimulation"

    })
    options.className = "cwReferentielCollabDialog";
    super(options);
    this.sansUseCase = false;
    this.searchTerm = "";
    this.template = collabDetailSelecteurRefDialogTPL;

    this.model = new CWBaseModel();
    this.parent = options.parent;

    if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.coll)) {
      this.coll = options.coll;
      this.coll.applyFilter({ "search": null });
    }

    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur");

    this.table = this._initTable();
    const tableModel = this.table.model;
    this.listenTo(tableModel, "change:value", this.selection);
    this.listenTo(tableModel.coll, "row:dblclick", this._selectionAndClose);
    this.listenTo(this.parent.model, "dlg:beforeclose", this._closeEvent);
  }

  _closeEvent(): void {
    // If mode simulated, check by default when complet view is closed
    if (!CWSTR.isBlank(this.coll.id_simulation)) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.coll.aff_collabsimu = true;
    }
  }

  selection(): any {
    const selection = this.table.model.get("value");
    this.trigger("response", selection);
    return selection;
  }

  remove(): CWSelecteurRefDialogView {
    this.table.remove();
    Backbone.View.prototype.remove.call(this);
    return this;
  }

  _selectionAndClose(): void {
    this.trigger("close", "Y");
  }

  _manageTable(): void {
    const currentValue = this.selection();

    this.table.model.resetRowHeight();

    this.table.model.coll.applyFilter({ "search": this.searchTerm });

    this.parent.dialog.dialog("option", "width", screen.width * .4);
    this.parent.dialog.dialog("option", "minWidth", 825);

    if (!this.sansUseCase) {
      if ($("#" + this.table.model.coll.getHabContext().get("onglet")).length === 0) {
        //par exemple, le menu de populations tranvesel n'a pas de usecase mais il doit avoir hidder
        this.table.model.coll.getHabContext().set("onglet", "cw-appContainer");
        this.sansUseCase = true;
      }
    }
    this.table.model.coll.fetch({
      success: () => {
        if (!CWSTR.isBlank(currentValue)) {
          this.table.model.selectRow(currentValue);
        }
        this._repositionDialog();
      }
    });
  }

  render(): CWSelecteurRefDialogView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));

    if (!CWSTR.isBlank(this.coll.id_simulation)) {
      this.coll.activeSimulation = true;

      const typeCollab = this.coll.type_collab;
      const labelContainer = $(this.el).find("label[for=collab-recherche-simulation-chk]");
      switch (typeCollab) {
        case "R":
          labelContainer.html(i18n.t('referentielcollaborateur.simulation_reels'));
          break;
        case "V":
          labelContainer.html(i18n.t('referentielcollaborateur.simulation_virtuels'));
          break;
        default:
          labelContainer.html(i18n.t('referentielcollaborateur.simulation_uniquement'));
      }
      $(this.el).find(".collab-recherche-simulation").show();
      $(this.el).find(".collab-recherche-simulation-chk").prop("checked", true);
    } else {
      $(this.el).find(".collab-recherche-simulation").hide();
    }

    $(this.el).find(".referentielcollaborateur-table").html(this.table.render().el);

    this._manageTable();

    $(this.el).find(".criteres").autocomplete({
      minLength: 0,
      source: (request: any) => {
        this.searchTerm = request.term;

        this._manageTable();

        return false;
      }
    });

    //this.$el.find("c-cwDialogPopup--avecSaisie").css("width", 825);
    CWFORMS.linkLabelsToInputs(this.$el);

    return this;
  }

  _repositionDialog(): void {
    this.parent.model.trigger("dlg:reposition");
  }

  _checkboxSimulation(event: any): void {
    const simulated = $(event.currentTarget).prop("checked");
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.coll.aff_collabsimu = simulated;
    this._manageTable();
  }

  _initTable(): CWDataGridView {
    const itemPerPage = 50;
    const collaborateurColl = this.coll;
    const tableModel = new CWDataGridModel({
      coll: collaborateurColl,
      enableSelectionOnDblclick: true
    });
    const tblColumns: Array<CWBaseGridColums> = [
      { title: i18n.t('referentielcollaborateur.nom'), property: "nom", width: 4 },
      { title: i18n.t('referentielcollaborateur.prenom'), property: "prenom", width: 4 },
      { title: i18n.t('referentielcollaborateur.matric'), property: "matric", width: 4 }
    ];
    const table = new CWDataGridView({
      id: "selectionnercollab_table",
      title: i18n.t('referentielcollaborateur.title_table'),
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: true,
      gridHeightType: "nolimite",
      maxVisibleRows: 5,
      columnsChooser: false,
      forceHideChooserMenu: true,
      parentContainer: this.parent,
      paginatorPosition: "butom",
      itemsPerPage: itemPerPage
    });

    table.cellRenderers["matric"] = (model: any): string => {
      if (this.diversIdCollab === "matric_aux") {
        return model.get("matricaux");
      } else {
        return model.get("matric");
      }
    };

    return table;
  }
}
