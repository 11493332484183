import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWDialogView, CWDialogViewOptions } from '../cwDialog.view';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'src/utils/utils.js';

export interface CWPanneauDeroulantOptions<TView extends Backbone.View = Backbone.View> extends CWDialogViewOptions<TView> {
  notIconClose?: boolean;
  notTitle?: boolean;
  closeOnClickOutside?: boolean;
  parent?: Backbone.View
}

export class CWPanneauDeroulant<TView extends Backbone.View = Backbone.View> extends CWDialogView<TView> {

  refreshParent: boolean;
  notIconClose: boolean;
  notTitle: boolean;
  closeOnClickOutside: boolean;


  constructor(options: CWPanneauDeroulantOptions<TView>) {
    options.className = options.className || "phx-Dialog";
    options.dialogClass = options.dialogClass || "c-panneauDeroulant cw-style";
    options.position = options.position || { my: "center center", at: "center center", of: window, collision: "flipfit" };
    /* The options below are not modifiable */
    options.resizable = false;
    options.draggable = false;
    options.modal = options.modal || false;
    super(options);
    this.refreshParent = true;
    this.notIconClose = false;
    this.notTitle = false;
    this.closeOnClickOutside = false;
    if (!CWSTR.isBlank(options)) {
      if (!CWSTR.isBlank(options.notIconClose)) {
        this.notIconClose = options.notIconClose;
      }
      if (!CWSTR.isBlank(options.notTitle)) {
        this.notTitle = options.notTitle;
      }
      if (!CWSTR.isBlank(options.closeOnClickOutside)) {
        this.closeOnClickOutside = options.closeOnClickOutside;
      }
    }
    this.listenTo(objs.appRt.workflow, "resize", () => {
      const isVisible = (this.dialog) ? this.dialog.is(":visible") : false;

      if (isVisible && this.dialog && this.$el.dialog("instance")) {
        const position = this.dialog.dialog("option", "position");

        this.dialog.dialog("option", "position", position);
      }
    });
  }

  render(): CWPanneauDeroulant<TView> {
    this.dialog = $(this.el).dialog({
      modal: this.modal,
      height: this.height,
      width: this.width,
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      maxHeight: (this.maxHeight as any),
      maxWidth: this.maxWidth,
      position: this.position,
      appendTo: this.appendTo as any,
      closeText: i18n.t('common:close'),
      dialogClass: this.dialogClass,
      create: () => {
        const $parent = this.$el.parent();
        // Fix jQuery UI issue. When width is auto, minWidth is not applied.
        if (!CWSTR.isBlank(this.minWidth) && this.width === "auto") {
          $parent.css("minWidth", this.minWidth);
        }
        $parent.removeClass("c-panneauDeroulant").attr("class", "c-panneauDeroulant " + $parent.attr("class"));
        if ($parent.find(".ui-dialog-titlebar")) {
          $parent.find(".ui-dialog-titlebar").attr("class", "c-panneauDeroulant " + $parent.find(".ui-dialog-titlebar").attr("class"));
        }
        if ($parent.find(".ui-dialog-title").length > 0) {
          $parent.find(".ui-dialog-title").attr("class", "c-panneauDeroulant cw-style cw-titre " + $parent.find(".ui-dialog-title").attr("class"));
        }
        if (this.notTitle) {
          $parent.find(".ui-dialog-titlebar").remove();
        } else {
          if ($parent.find(".ui-dialog-titlebar .ui-button").length > 0) {
            $parent.find(".ui-dialog-titlebar .ui-button").remove();
          }
          if ($parent.find(".ui-dialog-titlebar").length > 0 && !this.notIconClose) {
            $parent.find(".ui-dialog-titlebar").append(UTILS.getSVGIcon("croix-epaisse", "c-panneauDeroulant__close ui-dialog-titlebar-close", 20, i18n.t('common:close')));
            $parent.find(".ui-dialog-titlebar .c-panneauDeroulant__close.ui-dialog-titlebar-close").on("click", this._closePanneau.bind(this));
            $parent.find(".ui-dialog-titlebar .c-panneauDeroulant__close.ui-dialog-titlebar-close").on("keyup", this._notifyEdition.bind(this));
            $parent.find(".ui-dialog-titlebar .c-panneauDeroulant__close.ui-dialog-titlebar-close svg").attr("tabIndex", "0");
          }
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");

          // Update title manually on creation: 
          // this way if the title has HTML it's properly parsed.
          $parent.find(".ui-dialog-title").html(this.title);
        }
        $parent.removeClass("ui-corner-all");
      },
      resizable: this.resizable,
      draggable: this.draggable
    });
    $.ui.dialog.prototype._focusTabbable = (): void => {
      //empty
    };
    if (!CWSTR.isBlank(this.appendTo) && this.draggable) {
      this.dialog.dialog("widget").draggable("option", "containment", this.appendTo);
    }
    return this;
  }

  _closePanneau(event?: JQuery.TriggeredEvent, ui?: any): void {
    this.refreshParent = false;
    this.model.trigger("dlg:close", event, ui);
  }

  _checkHidderActive(): boolean {
    const uc = objs.appRt.workflow.get("usecase");
    const hidderCounter = UTILS.usecase_loading_counter[uc as any];

    if (hidderCounter && hidderCounter > 0) {
      return true;
    }

    return false;
  }

  _closePanneauClickOutside(event?: JQuery.TriggeredEvent, ui?: any): void {
    // if ($(event.target).parents(".filter-simple-fields").length !== 0 ||
    //   $(event.target).parents(".c-panneauDeroulant").length === 0 &&
    //   $(event.target).closest(".ui-datepicker-header").length === 0 &&
    //   $(event.target).closest(".ui-datepicker-calendar").length === 0 && !this._checkHidderActive()) {
    if ($(event.target).parents(".c-panneauDeroulant").length === 0 && $(event.target).closest(".ui-datepicker-header").length === 0 && $(event.target).closest(".ui-datepicker-calendar").length === 0 && $("body").find(".phx-uc-hidder-left.phx-icon-hidder").length === 1) {
      this.refreshParent = false;
      if (!CWSTR.isBlank(this.model)) {
        this.model.trigger("dlg:close", event, ui);
      }
    } else {
      $("body").off("click", this._closePanneauClickOutside.bind(this));
      $("body").one("click", this._closePanneauClickOutside.bind(this));
    }
  }

  _handleEvents(event: JQuery.TriggeredEvent, ui: any): void {
    CWDialogView.prototype._handleEvents.call(this, event, ui);
    if (event.type === "dialogclose") {
      const lOptions = {
        "refresh": _.isBoolean(this.refreshParent) ? this.refreshParent : false,
        "typeEvenement": ""
      };

      if (this.viewData && this.viewData.context && !CWSTR.isBlank(this.viewData.context.ctxTypeEvenement)) {
        lOptions.typeEvenement = this.viewData.context.ctxTypeEvenement;
      }
      this.model.trigger("panneau:close", lOptions);
    }
  }

  open(callback?: (argument?: any) => any): void {
    if (this.closeOnClickOutside === true) {
      $("body").off("click", this._closePanneauClickOutside.bind(this));
      $("body").one("click", this._closePanneauClickOutside.bind(this));
    }
    this.refreshParent = true;
    CWDialogView.prototype.open.call(this, callback);
    this.setPosition(this.position, "panneau:ready");
  }

  toggle(): void {
    if (this.$el && this.$el.dialog("instance")) {
      if (this.$el && this.$el.dialog("instance") && this.isOpen()) {
        this.close();
      } else {
        this.open();
      }
    }
  }

  hideCloseButton(): void {
    this.notIconClose = true;
    CWDialogView.prototype.hideCloseButton.call(this);
  }

  _setTitle(titleContent: string): void {
    CWDialogView.prototype._setTitle.call(this, titleContent);
    // Force resize dialog content because available space for content
    // may have changed after updating title
    if (this.dialog && this.dialog.dialog("instance")) {
      (this.dialog.dialog("instance") as any)._size();
    }
  }

  public _notifyEdition(event: JQuery.TriggeredEvent, ui: JQuery.UIEventBase): void {
    if (event.type === "keyup") {
      const key = event.which || event.keyCode;

      if (key === 10 || key === 13 || key === 32) {
        this._closePanneau(event, ui);
      }
    }
  }
}
