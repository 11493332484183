import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWGererevtgenPopUpView } from '../views/cwGererEvtGenPopUp.view';
import { CWGererEvtGenView } from '../views/cwGererEvtGen.view';
import { CWGererEvtGenWorflowActifModel } from './cwGererEvtGenWorflowActif.model';
import { CWGererEvtGenWorkflowActifColl } from './cwGererEvtGenWorkflowActif.collection';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWLOG, CWMSGS, CWSTR } from 'src/utils';
import { CWPiecesAutorisees } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesAutorisees';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n';
import { Model } from 'Backbone';


export class CWGererevtgenWorkflow extends CWReadOnlyModel {

  public gererevtgen: CWGererEvtGenView;
  public setupState: 0 | 1;// 0 --> setUp called, 1 --> setUp finished
  public btnBarModel: CWBaseModel;
  public parametreDiversIdCollabModel: Model;
  public maxPieces: CWPiecesAutorisees;
  public formModel: CWBaseModel;
  public wkfEvtGenActiv: boolean;
  private activesWorkflows: CWGererEvtGenWorkflowActifColl;
  public module: string;
  public $appendTo: JQuery; //pour les messages
  context: { [key: string]: any };

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.context = options.context;
    this.module = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : null;
    this.btnBarModel = null;
    this.setupState = undefined; // 0 --> setUp called, 1 --> setUp finished
    this.wkfEvtGenActiv = false;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp(callback?: () => void, avoidMaxPiecesFetch?: boolean): void {
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.gererevtgen.model.on("form:edited", this._formEdited, this);

    // Retrieve parametre divers for matricule (idCollab)
    this.parametreDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");

    this.maxPieces = new CWPiecesAutorisees({ id: "PJEVTGEN" });
    if (this.context !== undefined && this.context.ctxHabilitation !== undefined) {
      this.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces,
        natGest: ""
      }));
      this.maxPieces.setHabContext(this.getHabContext().copy());
    }

    this.gererevtgen.COLLAB_ID = this.parametreDiversIdCollabModel.get("valeur");
    if (CWSTR.isBlank(avoidMaxPiecesFetch) || avoidMaxPiecesFetch === false) {
      this.maxPieces.fetch({
        success: (): void => {
          this._fetchWorkflowOpt(callback);
        }
      });
    } else {
      if (callback) {
        this._fetchWorkflowOpt(callback);
      }
    }
  }

  _fetchWorkflowOpt(callback: () => void): void {
    this.activesWorkflows = new CWGererEvtGenWorkflowActifColl();
    this.activesWorkflows.setHabContext(this.getHabContext().copy());
    this.activesWorkflows.fetch({
      success: (fresh: CWGererEvtGenWorkflowActifColl): void => {
        // manages the options of the workflow.
        const antino = fresh.find((model: CWGererEvtGenWorflowActifModel): boolean => {
          return model.get("code") === "EVTGEN";
        });
        this.wkfEvtGenActiv = antino ? antino.get("active") : false;
        if (callback) {
          callback();
        }
      }
    });
  }

  _buttonAction(buttonId: string, profil: string): void {
    CWLOG.debug("Button clicked in gerer evtgen : " + buttonId);
    switch (buttonId) {
      case "save":
        this.gererevtgen.saveEvtGen();
        break;
      case "revert":
        this.gererevtgen.revertView();
        if (CWSTR.isBlank(this.gererevtgen.model.get("value").id) && !CWSTR.isBlank(this.gererevtgen.$el)) {
          this.trigger("manageUnselected", true);
        }
        break;
      case "new":
        this.gererevtgen.newEvtGen();
        this.trigger("manageUnselected", false);
        break;
      case "delete": {
        const statut = CWSTR.getElValue(this.gererevtgen.model.get("value"), "statut.code");
        if (statut === "A" || statut === "H" ||
          (statut === "I" && this.gererevtgen.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
          const dialog = this._initPopUp("suppression");
          const dialogModel = dialog.model;
          dialog.open((): void => {
            if (dialogModel.get("action") === "OK") {
              this.gererevtgen.deleteEvtGen(dialogModel.get("commentaire"));
            }
          });
          dialog._setTitle(i18n.t('common:gererevtgen.title_popUp'));
        } else {
          CWMSGS.showConfirmAdapter(i18n.t('common:delconfirm'), (result: string): void => {
            if (result === "Y") {
              this.gererevtgen.deleteEvtGen(null);
            }
          }, null, null, null, this.$appendTo);
        }
      }
        break;
      case "accepter": {
        CWLOG.debug("accepter");
        const dialog = this._initPopUp("accepter");
        const dialogModel = dialog.model;
        const validationProfil = profil;
        dialog.open((): void => {
          if (dialogModel.get("action") === "OK") {
            this.gererevtgen.accepterEvtGen(dialogModel.get("commentaire"), validationProfil, dialogModel.get("justifok"));
          }
        });

        dialog._setTitle(i18n.t('common:gererevtgen.commentaire_accept_demande'));
      }
        break;
      case "refuser": {
        CWLOG.debug("refuser");
        const dialog = this._initPopUp("refuser");
        const dialogModel = dialog.model;
        const validationProfil = profil;
        dialog.open((): void => {
          if (dialogModel.get("action") === "OK") {
            this.gererevtgen.refuserEvtGen(dialogModel.get("commentaire"), validationProfil);
          }
        });

        dialog._setTitle(i18n.t('common:gererevtgen.commentaire_refus_demande'));
      }
        break;
    }
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("disable:valider");
    this.btnBarModel.trigger("disable:nouveau");

    if (this.formModel.getHabContext().get("natGest") !== "A") {
      this.formModel.updateHabContext({ foncCour: this.gererevtgen.context.ctxHabilitation.HabilitationGestion, natGest: "M" });
    }
  }

  _initPopUp(act: string): CWDialogPopupView {
    const stat = CWSTR.getElValue(this.gererevtgen.model.get("value"), "statut.code");
    const etat = CWSTR.getElValue(this.gererevtgen.model.get("value"), "etat");
    const dialog = new CWDialogPopupView({
      view: CWGererevtgenPopUpView,
      viewData: {
        action: act,
        statut: stat,
        etat: etat,
        appendTo: this.$appendTo
      }
    });

    dialog.setHeight("auto");
    dialog.setWidth(470);

    return dialog;
  }
}
