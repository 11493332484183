import _ from 'underscore';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWPopulationComboModel } from './cwPopulationCombo.model';

/**
 * Collection for Combo 'Critere' in 'Planning par criteres'
 */
export class CWCritidComboColl extends CWCombosColl {

  constructor(models?: Array<CWPopulationComboModel> | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWPopulationComboModel;
    super(models, options);
  }

  fetch(options: { [key: string]: any }): JQueryXHR {
    options.headers = { "gfi-ecran": '"RES_PLANNING"' };
    return CWPaginatedCollection.prototype.fetch.call(this, options);
  }
}