import * as Backbone from 'Backbone';
import _ from 'underscore';
import { completeDomainData } from './completeDomainData.model.js';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { LOG } from 'utils/log.js';
import { NivColl } from './niv.collection.js';
import { NiveauTypeHierarchieColl } from './niveauTypeHierarchie.collection.js';

export enum CWActiviteTreesViewType {
  "FAMILLE" = 1,
  "HIERARCHIE" = 2,
  "STRUCTURESIMPLE" = 3,
  "STRUCTUREDETAIL" = 4,
}
/*
   treesContext.ctxUtilisateur -> Utilisateur
   treesContext.ctxDomaineDetail -> Model du domaine selected with format DomActiviteModel
   treesContext.ctxHabilitation -> Habilitations
                            HabilitationConsultation
                                       prevue
                                       realisee
   treesContext.ctxEcran ->
 
   treesContext.ctxFromSelecteurDactivites -> If trees are loaded from selecteur d'activités
 
   treesContext.ctxAffIndicTemporel
   treesContext.ctxDefaultView -> Vue par défaut, si nous voulons une vue spécifique, sinon vide.
 
   treesContext.ctxPeriodeGestion
                       datedeb
                       datefin
 
   treesContext.ctxFilterData
                       the rest of parametres of filtering
 
   treesContext.ctxTypeContenu
   treesContext.ctxRefActivUniq
    */
export interface CWActiviteTreesContext {
  ctxSansVueDetaillee?: boolean;
  ctxNiveauxTypeHierarchy?: any;
  ctxDomainesActivites?: any;
  ctxHabilitation?: any;
  ctxEcran?: any;
  ctxUtilisation?: any;
  ctxActivitesInitiales?: any;
  ctxPeriodeGestion?: any;
  ctxIndExisteDateDeb?: any;
  ctxIndExistePeriode?: any;
  ctxPeriodeHistoCour?: any;
  ctxPeriodeHistoPrec?: any;
  ctxTypeEvtGere?: any;
  ctxTypologieActivite?: any;
  ctxDomaine?: any;
  ctxCollab?: any;
  ctxFiltreStructCollab?: any;
  ctxActivHeritage?: any;
  ctxUtilisateur?: any;
  ctxDomaineDetail?: any;
  ctxFromSelecteurDactivites?: any;
  ctxAffIndicTemporel?: any;
  ctxDefaultView?: any;
  ctxFilterData?: any;
  ctxTypeContenu?: any;
  ctxRefActivUniq?: any;
  ctxBesoinPrioritaire?: boolean;
  ctxInaptitude?: boolean;
}

export class CWActivitetreesWorkflow extends CWReadOnlyModel {
  FAMILLE: string;
  HIERARCHIE: string;
  STRUCTURESIMPLE: string;
  STRUCTUREDETAIL: string;
  parameters: any;
  elementsRattachesTitleColl: any;
  menuViewsModel: any;
  domainData: any;
  hierarchie: any;
  currentViewModel: any;
  familleTreeModel: any;
  hierarchieTreeModel: any;
  structureDetailTreeModel: any;
  structureSimpleTreeModel: any;
  selecteurContext: any;
  currentTreeViewModel: any;
  context: CWActiviteTreesContext;

  /**
   * Initializes the workflow model of selecteur activites complete view
   */
  constructor(options?: { [key: string]: any }) {
    options = options || {};
    super(options);
    this.context = null;

    this.FAMILLE = "FAMILLE";
    this.HIERARCHIE = "HIERARCHIE";
    this.STRUCTURESIMPLE = "STRUCTURESIMPLE";
    this.STRUCTUREDETAIL = "STRUCTUREDETAIL";

    if (options && options.context) {
      this.context = options.context;
    }
    if (options && options.parameters) {
      this.parameters = options.parameters;
    }
    this.setHabContext(new CWHabilitationContext({
      onglet: "",
      foncCour: "",
      natGest: ""
    }));
    this.elementsRattachesTitleColl = new NivColl();
  }

  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback?: () => void): void {
    // Load data
    const foncCour = this._obtainFoncCour();
    this.updateHabContext({ onglet: this.context.ctxEcran, foncCour: foncCour, natGest: "" });

    if (this.context) { //There is a context
      const domaineDetail = this.context.ctxDomaineDetail;
      let defaultView = null,
        omitedViews = null,
        defaultObj = null;
      let hierarchie = false;
      let actstruct = false;

      if (!CWSTR.isBlank(domaineDetail)) {
        actstruct = !CWSTR.isBlank(domaineDetail.get("straid")) ? true : false;
        hierarchie = domaineDetail.get("hiera") === true ? true : false;
      }
      if (!actstruct && hierarchie) { //vue par hierarchie accesible (no matters structure)
        if (this.context.ctxFromSelecteurDactivites !== true) {
          defaultView = !CWSTR.isBlank(this.context.ctxDefaultView) ? this.context.ctxDefaultView : this.HIERARCHIE;
          this.menuViewsModel.set("defaultView", defaultView);
          omitedViews = [this.STRUCTUREDETAIL, this.STRUCTURESIMPLE, this.FAMILLE];
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        } else {
          defaultObj = this._manageDefaultView(hierarchie, actstruct);
          defaultView = defaultObj.defaultView;
          omitedViews = defaultObj.omitedViews;
          this.menuViewsModel.set("defaultView", defaultView);
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        }
        if (callback) {
          callback();
        }
      } else if (!actstruct && !hierarchie) { //hierarchie and vue par structure not accesible
        //Default vue par famille
        if (this.context.ctxFromSelecteurDactivites !== true) {
          defaultView = !CWSTR.isBlank(this.context.ctxDefaultView) ? this.context.ctxDefaultView : this.FAMILLE;
          this.menuViewsModel.set("defaultView", defaultView);
          omitedViews = [this.HIERARCHIE, this.STRUCTURESIMPLE, this.STRUCTUREDETAIL];
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        } else {
          defaultObj = this._manageDefaultView(hierarchie, actstruct);
          defaultView = defaultObj.defaultView;
          omitedViews = defaultObj.omitedViews;
          this.menuViewsModel.set("defaultView", defaultView);
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        }
        this.set("panel", 0);
        if (callback) {
          callback();
        }
      } else if (actstruct && !hierarchie) { //vue par structure accesible hierarchie not accesible
        //vue par structure by default
        if (this.context.ctxFromSelecteurDactivites !== true) {
          defaultView = !CWSTR.isBlank(this.context.ctxDefaultView) ? this.context.ctxDefaultView : this.STRUCTURESIMPLE;
          this.menuViewsModel.set("defaultView", defaultView);
          if (this.context.ctxSansVueDetaillee === true) {
            omitedViews = [this.HIERARCHIE, this.FAMILLE, this.STRUCTUREDETAIL];
          } else {
            omitedViews = [this.HIERARCHIE, this.FAMILLE];
          }
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        } else {
          defaultObj = this._manageDefaultView(hierarchie, actstruct);
          defaultView = defaultObj.defaultView;
          omitedViews = defaultObj.omitedViews;
          this.menuViewsModel.set("defaultView", defaultView);
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        }
        this.set("panel", 0);
        this.elementsRattachesTitleColl.code = domaineDetail.get("straid");
        const hc = this.getHabContext().copy();
        this.elementsRattachesTitleColl.setHabContext(hc);
        // customer #164612 : calling more than once this model without parameters reset crash backbone
        this.elementsRattachesTitleColl.fetch({
          reset: true,
          success: () => {
            if (callback) {
              callback();
            }
          }
        });
      } else if (actstruct && hierarchie) {
        if (this.context.ctxFromSelecteurDactivites !== true) {
          defaultView = !CWSTR.isBlank(this.context.ctxDefaultView) ? this.context.ctxDefaultView : this.STRUCTURESIMPLE;
          this.menuViewsModel.set("defaultView", defaultView);
          if (this.context.ctxSansVueDetaillee === true) {
            omitedViews = [this.HIERARCHIE, this.FAMILLE, this.STRUCTUREDETAIL];
          } else {
            omitedViews = [this.HIERARCHIE, this.FAMILLE];
          }
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        } else {
          defaultObj = this._manageDefaultView(hierarchie, actstruct);
          defaultView = defaultObj.defaultView;
          omitedViews = defaultObj.omitedViews;

          this.menuViewsModel.set("defaultView", defaultView);
          this.menuViewsModel.set("omitedViews", omitedViews);
          this._setCurrentTreeViewModel(defaultView);
        }
        if (callback) {
          callback();
        }
      }
    } else { //There is no context
      if (callback) {
        callback();
      }

    }

  }

  /**
   * Prepares a path for being used, depending on the current vue
   */
  prepareResultPath(pathModel: CWBaseModel, callback?: (model: CWBaseModel) => void): { [key: string]: any } {
    const currentVue = this._obtainCurrentVueInTree();
    switch (currentVue) {
      case CWActiviteTreesViewType.FAMILLE:
        LOG.debug("asd");
        break;
      case CWActiviteTreesViewType.HIERARCHIE:
        if (!CWSTR.isBlank(pathModel.get("structa"))) {
          pathModel.set("structa", []);
        }
        break;
      case CWActiviteTreesViewType.STRUCTURESIMPLE:
        //In the simple view we only need the last structure level.
        if (!CWSTR.isBlank(pathModel.get("structa"))) {
          const newArrayStruct = [];
          newArrayStruct.push(_.last(pathModel.get("structa")));
          pathModel.set("structa", newArrayStruct);
        }
        break;
      case CWActiviteTreesViewType.STRUCTUREDETAIL:
        LOG.debug("asd");
        break;
      default:
        break;
    }

    if (callback) {
      callback(pathModel);
    }
    return pathModel;
  }

  /**
   * Prepares parentModel to fetch in order to obtain parents to execute "ouvrirparents" action
   * from hierarchie and structure models
   */
  _obtainCurrentVueInTree(): number {
    const viewName = this.menuViewsModel.get("menuView");
    const vue = parseInt(CWActiviteTreesViewType[viewName]) || 1;
    return vue;
  }

  _manageDefaultView(hierarchie: boolean, actstruct: boolean): { defaultView: string; omitedViews: string[] } {
    let defaultView = "";
    let omitedViews = new Array<string>();
    const allViews = [this.FAMILLE, this.HIERARCHIE, this.STRUCTUREDETAIL, this.STRUCTURESIMPLE];

    if (actstruct === false) {
      if (hierarchie === true) {
        if (!CWSTR.isBlank(this.context.ctxDefaultView)) {
          defaultView = !CWSTR.isBlank(this.context.ctxDefaultView) ? this.context.ctxDefaultView : this.HIERARCHIE;
        } else if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === true) {
          if (this.context.ctxBesoinPrioritaire || this.context.ctxInaptitude) {//Uniquement pour Besoins Prioritaires et inaptitudes
            defaultView = this.HIERARCHIE;
          } else {
            defaultView = this.FAMILLE;
          }
        } else if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === false) {
          defaultView = this.HIERARCHIE;
        } else {
          defaultView = this.HIERARCHIE;
        }
        omitedViews = _.filter(allViews, (view) => {
          return view !== defaultView;
        });
      } else {
        if (!CWSTR.isBlank(this.context.ctxDefaultView)) {
          defaultView = !CWSTR.isBlank(this.context.ctxDefaultView) ? this.context.ctxDefaultView : this.FAMILLE;
        } else if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === true) {
          defaultView = this.FAMILLE;
        } else if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === false) {
          defaultView = this.FAMILLE;
        } else {
          defaultView = this.FAMILLE;
        }
        omitedViews = _.filter(allViews, (view) => {
          return view !== defaultView;
        });
      }
    } else {
      if (!CWSTR.isBlank(this.context.ctxDefaultView)) {
        defaultView = !CWSTR.isBlank(this.context.ctxDefaultView) ? this.context.ctxDefaultView : this.STRUCTUREDETAIL;
        if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === true) {
          omitedViews = _.filter(allViews, (view) => {
            return view !== defaultView;
          });
        } else if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === false) {
          omitedViews = _.filter(allViews, (view) => {
            return view !== defaultView;
          });
        } else {
          omitedViews = [this.FAMILLE, this.HIERARCHIE];
        }
      } else if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === true) {
        if ((this.context.ctxBesoinPrioritaire || this.context.ctxInaptitude) && actstruct) {//Uniquement pour Besoins Prioritaires et inaptitudes
          defaultView = this.STRUCTURESIMPLE;
          omitedViews = [this.FAMILLE, this.HIERARCHIE];
        } else {
          defaultView = this.FAMILLE;
          omitedViews = _.filter(allViews, (view) => {
            return view !== defaultView;
          });
        }
      } else if (this.context.ctxTypeContenu === "R" && this.context.ctxRefActivUniq === false) {
        if ((this.context.ctxBesoinPrioritaire || this.context.ctxInaptitude) && actstruct) {//Uniquement pour Besoins Prioritaires et inaptitudes
          defaultView = this.STRUCTURESIMPLE;
          omitedViews = [this.FAMILLE, this.HIERARCHIE];
        } else {
          defaultView = hierarchie === true ? this.HIERARCHIE : this.FAMILLE;
          omitedViews = _.filter(allViews, (view) => {
            return view !== defaultView;
          });
        }
      } else {
        defaultView = this.STRUCTURESIMPLE;
        omitedViews = [this.FAMILLE, this.HIERARCHIE];
      }
    }

    return { defaultView: defaultView, omitedViews: omitedViews };
  }

  _manageTreeModelsEvents(eventName: string, node: { [key: string]: any }): void {
    this.trigger(eventName, node);
  }

  /**
   * obtain fonccour from context for habilitations
   */
  _obtainFoncCour(): { [key: string]: any } {
    let foncCour: { [key: string]: any } = {};

    if (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion) {
      if (this.context.ctxHabilitation.HabilitationGestion.prevue) {
        foncCour.actprev = this.context.ctxHabilitation.HabilitationGestion.prevue;
      }
      if (this.context.ctxHabilitation.HabilitationGestion.realisee) {
        foncCour.actreal = this.context.ctxHabilitation.HabilitationGestion.realisee;
      }
      if (CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion.prevue) && CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion.realisee)) {
        foncCour = this.context.ctxHabilitation.HabilitationGestion;
      }
    } else if (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationConsultation) {
      if (this.context.ctxHabilitation.HabilitationConsultation.prevue) {
        foncCour.actprev = this.context.ctxHabilitation.HabilitationConsultation.prevue;
      }
      if (this.context.ctxHabilitation.HabilitationConsultation.realisee) {
        foncCour.actreal = this.context.ctxHabilitation.HabilitationConsultation.realisee;
      }
      if (!this.context.ctxHabilitation.HabilitationConsultation.prevue && !this.context.ctxHabilitation.HabilitationConsultation.realisee) {
        foncCour = this.context.ctxHabilitation.HabilitationConsultation;
      }
    } else {
      foncCour = this.context.ctxHabilitation.HabilitationConsultation;
    }
    return foncCour;
  }

  /**
   * Sets the context for selecteurActivite complete model
   */
  setContext(context: CWActiviteTreesContext, callback: () => void, flagNotPetition: boolean): void {
    let domainCodeChange = false
    if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxDomaineDetail) && !CWSTR.isBlank(this.context.ctxDomaineDetail.get("domcode")) &&
      !CWSTR.isBlank(context) && !CWSTR.isBlank(context.ctxDomaineDetail) && !CWSTR.isBlank(context.ctxDomaineDetail.get("domcode"))) {
      domainCodeChange = this.context.ctxDomaineDetail.get("domcode") !== context.ctxDomaineDetail.get("domcode");
    } else {
      if (CWSTR.isBlank(this.context) &&
        !CWSTR.isBlank(context) && !CWSTR.isBlank(context.ctxDomaineDetail) && !CWSTR.isBlank(context.ctxDomaineDetail.get("domcode"))) {
        domainCodeChange = true;
      }
    }
    if (context && !CWSTR.isBlank(context.ctxFilterData) && context.ctxFilterData.loadedDomain === true) {
      //c'est un paramètre  qu'on va utiliser pour savoir si le domaine a déjà été lu et éviter des appels en double
      domainCodeChange = false;
    }
    //this.hierarchie = this.context.ctxNiveauxTypeHierarchy;
    this.context = context;
    if (domainCodeChange && flagNotPetition !== true && context?.ctxFilterData?.ctxTypegest !== "GTMED") {//GTMED sera toujours Structure simple

      const postHierarchieCallback = (): void => {
        this.setUp(callback);
      }
      this._obtainHierarchieDomain(postHierarchieCallback)
    } else {
      this.setUp(callback);
    }
  }

  _obtainHierarchieDomain(callback?: () => void): void {

    //1- obtain de hierarchie id
    if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxDomaineDetail) && !CWSTR.isBlank(this.context.ctxDomaineDetail.get("domcode"))) {
      this.domainData = new completeDomainData(this.context.ctxDomaineDetail.get("domcode"));
      const hc = new CWHabilitationContext({
        onglet: "",
        foncCour: "",
        natGest: ""
      })

      if (CWSTR.isBlank(hc.get("foncCour")) && this.context && !CWSTR.isBlank(this.context.ctxHabilitation && _.isObject(this.context.ctxHabilitation))) {
        let lFoncCour = null;

        if (_.keys(this.context.ctxHabilitation).length > 0) {
          if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion) && GLOBAL_DATA.rights.get(this.context.ctxHabilitation.HabilitationGestion)) {
            lFoncCour = this.context.ctxHabilitation.HabilitationGestion;
          } else {
            let foncCourTemp = this.context.ctxHabilitation[_.keys(this.context.ctxHabilitation)[0]];

            if (!_.isEmpty(foncCourTemp) && typeof foncCourTemp !== "string" && _.keys(foncCourTemp).length === 1) {//seulement un élement(ex.{realise: "RES_ACT_REAL.G"}). Dans autre cas, on ne fera rien(ex.{prevue: "RES_ACT_PREV.G",realise: "RES_ACT_REAL.G"})
              foncCourTemp = foncCourTemp[_.keys(foncCourTemp)[0]];
            }
            lFoncCour = foncCourTemp;
          }
        }
        if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxFilterData) && !CWSTR.isBlank(this.context.ctxFilterData.typevt)) {
          if (CWSTR.isBlank(lFoncCour)) {
            lFoncCour = this.context.ctxHabilitation[_.keys(this.context.ctxHabilitation)[1]];
          }
          if (!CWSTR.isBlank(lFoncCour) && this.context.ctxFilterData.typevt === "P") {
            if (_.isObject(lFoncCour)) {
              hc.set("foncCour", lFoncCour["prevue"]);
            } else {
              hc.set("foncCour", lFoncCour);
            }
          }
          if (!CWSTR.isBlank(lFoncCour) && this.context.ctxFilterData.typevt === "R") {
            if (_.isObject(lFoncCour)) {
              hc.set("foncCour", lFoncCour["realisee"]);
            } else {
              hc.set("foncCour", lFoncCour);
            }
          }
        } else {
          hc.set("foncCour", lFoncCour);
        }
      }

      if (CWSTR.isBlank(hc.get("onglet")) && this.context && !CWSTR.isBlank(this.context.ctxEcran)) {
        hc.set("onglet", this.context.ctxEcran);
      }

      if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxFilterData) && !CWSTR.isBlank(this.context.ctxFilterData.typevt)) {
        if (this.context.ctxFilterData.typevt === "P") {
          this.domainData.autresParams = {
            prevu: true
          }
        }

        if (this.context.ctxFilterData.typevt === "R") {
          this.domainData.autresParams = {
            realise: true
          }
        }
      }

      this.domainData.setHabContext(hc);
      this.domainData.fetch({
        success: () => {
          //2- obtain hierarchie info
          if (!CWSTR.isBlank(this.domainData.get("hiertype")) && !CWSTR.isBlank(this.domainData.get("hiertype").code)) {
            this.hierarchie = new NiveauTypeHierarchieColl(this.domainData.get("hiertype").code);
            this.hierarchie.setHabContext(hc);
            this.hierarchie.horsregr = true;
            this.hierarchie.fetch({
              success: () => {
                //3- set info to context ctxNiveauxTypeHierarchy
                this.trigger("updateFilterFields", this);
                if (callback) {
                  callback();
                }
              }
            });
          } else {
            if (callback) {
              callback();
            }
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  /**
   *
   */
  initializeParams(): void {

    //Load parameters
    this.parameters = {
      typevt: this.context.ctxTypeEvtGere,
      typo: this.context.ctxTypologieActivite
    };

    /**
     * Context Utilisation
     */
    if (this.context && !CWSTR.isBlank(this.context.ctxUtilisation)) {
      this.parameters.ctxutil = this.context.ctxUtilisation;
    }

    /**
     * Date deb
     */
    if (this.context.ctxPeriodeGestion && !CWSTR.isBlank(this.context.ctxPeriodeGestion.datedeb)) {
      this.parameters.datedeb = this.context.ctxPeriodeGestion.datedeb;
    }
    /**
     * Date fin
     */
    if (this.context.ctxPeriodeGestion && !CWSTR.isBlank(this.context.ctxPeriodeGestion.datefin)) {
      this.parameters.datefin = this.context.ctxPeriodeGestion.datefin;
    }

    /**
     * Domaines
     */
    if (CWSTR.isBlank(this.context.ctxDomainesActivites)) {
      this.parameters.dom = this.context.ctxDomainesActivites;
    }

    /**
     * Domaines
     */
    if (CWSTR.isBlank(this.context.ctxCollab)) {
      this.parameters.matric = this.context.ctxCollab;
    }

  }
  /**
   * Sets current left view model, depending on the resultats view selected and reinitializes
   * treevaluechange event.
   */
  _setCurrentTreeViewModel(viewName: string): void {
    switch (viewName) {
      case "FAMILLE":
        this.currentViewModel = this.familleTreeModel;
        break;
      case "HIERARCHIE":
        this.currentViewModel = this.hierarchieTreeModel;
        break;
      case "STRUCTUREDETAIL":
        this.currentViewModel = this.structureDetailTreeModel;
        break;
      case "STRUCTURESIMPLE":
        this.currentViewModel = this.structureSimpleTreeModel;
        break;
      default:
        break;
    }
  }

  _clearValuesPrevious(viewName: string, view: { [key: string]: any }): void {
    const lClean = (aView: Backbone.View): void => {
      if (aView) {
        if (aView.$el) {
          if (aView.$el.off) {
            aView.undelegateEvents();
          }
          aView.$el.removeData().unbind();
        }
        Backbone.View.prototype.remove.call(aView);
      }
    };
    const clearNodeColl = (aView: Backbone.View): void => {
      aView.remove();
    };

    switch (viewName) {
      case "FAMILLE":
        this.clearStructureSimple(view, clearNodeColl, lClean);
        this.clearHierarchie(view, clearNodeColl, lClean);
        this.clearStructureDetail(view, clearNodeColl, lClean);
        break;
      case "HIERARCHIE":
        this.clearStructureSimple(view, clearNodeColl, lClean);
        this.clearFamille(view, clearNodeColl, lClean);
        this.clearStructureDetail(view, clearNodeColl, lClean);
        break;
      case "STRUCTUREDETAIL":
        this.clearStructureSimple(view, clearNodeColl, lClean);
        this.clearFamille(view, clearNodeColl, lClean);
        this.clearHierarchie(view, clearNodeColl, lClean);
        break;
      case "STRUCTURESIMPLE":
        this.clearFamille(view, clearNodeColl, lClean);
        this.clearHierarchie(view, clearNodeColl, lClean);
        this.clearStructureDetail(view, clearNodeColl, lClean);
        break;
      default:
      //NOTHING
    }
  }

  _manageChangeMenuView(menuViewModel: { [key: string]: any }, callback: (viewName: string, view: { [key: string]: any }) => void, oldViewName: string, menuView: { [key: string]: any }): void {

    const ownCallback = (viewName: string, view: { [key: string]: any }): void => {
      this._setCurrentTreeViewModel(viewName);
      // this._clearValuesPrevious(viewName, view);
      callback(viewName, view);
    };
    this.trigger("change:menuView", menuViewModel, ownCallback, oldViewName, menuView);
  }

  clearStructureDetail(view: { [key: string]: any }, clearNodeColl: (aView: Backbone.View) => void, lClean: (aView: Backbone.View) => void): void {
    if (view.menuViews.STRUCTUREDETAIL.view.tree &&
      view.menuViews.STRUCTUREDETAIL.view.tree.root &&
      view.menuViews.STRUCTUREDETAIL.view.tree.root.sonsColl) {
      view.menuViews.STRUCTUREDETAIL.view.tree.root.sonsColl.forEach(clearNodeColl);
    }
    if (view.menuViews.STRUCTUREDETAIL.view.tree) {
      lClean(view.menuViews.STRUCTUREDETAIL.view.tree);
      delete view.menuViews.STRUCTUREDETAIL.view.tree;
    }
  }


  clearHierarchie(view: { [key: string]: any }, clearNodeColl: (aView: Backbone.View) => void, lClean: (aView: Backbone.View) => void): void {
    if (view.menuViews.HIERARCHIE.view.tree &&
      view.menuViews.HIERARCHIE.view.tree.root &&
      view.menuViews.HIERARCHIE.view.tree.root.sonsColl) {
      view.menuViews.HIERARCHIE.view.tree.root.sonsColl.forEach(clearNodeColl);
    }
    if (view.menuViews.HIERARCHIE.view.tree) {
      lClean(view.menuViews.HIERARCHIE.view.tree);
      delete view.menuViews.HIERARCHIE.view.tree;
    }
  }


  clearStructureSimple(view: { [key: string]: any }, clearNodeColl: (aView: Backbone.View) => void, lClean: (aView: Backbone.View) => void): void {
    if (view.menuViews.STRUCTURESIMPLE.view.tree &&
      view.menuViews.STRUCTURESIMPLE.view.tree.root &&
      view.menuViews.STRUCTURESIMPLE.view.tree.root.sonsColl) {
      view.menuViews.STRUCTURESIMPLE.view.tree.root.sonsColl.forEach(clearNodeColl);
    }
    if (view.menuViews.STRUCTURESIMPLE.view.tree) {
      lClean(view.menuViews.STRUCTURESIMPLE.view.tree);
      delete view.menuViews.STRUCTURESIMPLE.view.tree;
    }
  }


  clearFamille(view: { [key: string]: any }, clearNodeColl: (aView: Backbone.View) => void, lClean: (aView: Backbone.View) => void): void {
    if (view.menuViews.FAMILLE.view.tree &&
      view.menuViews.FAMILLE.view.tree.root &&
      view.menuViews.FAMILLE.view.tree.root.sonsColl) {
      view.menuViews.FAMILLE.view.tree.root.sonsColl.forEach(clearNodeColl);
    }
    if (view.menuViews.FAMILLE.view.tree) {
      lClean(view.menuViews.FAMILLE.view.tree);
      delete view.menuViews.FAMILLE.view.tree;
    }
  }

}


