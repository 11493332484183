import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Pieces autorisees
 */
export class CWPiecesAutorisees extends CWBaseModel {

  //	habilitationV : "",
  //	habilitationG : "",
  //	usecase : "gererpiecesjointes",

  defaults(): { [key: string]: any } {
    return {
      "maxi": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/fichier/maxi";
    };
  }
}