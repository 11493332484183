module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n\n<fieldset class="phx-activite-famille-tree-dlgresultheader cw-fieldset">\n  <span class="phx-activite-famille-tree-menuicon phx-menu-icon ui-corner-all ui-icon ui-icon-circle-plus"></span>\n</fieldset>\n\n<fieldset class="phx-activite-famille-tree-dlgresult cw-fieldset">\n  <div class="activite-overflow">\n    <ul class="phx-activite-famille-tree-menucontent">\n      <li class="phx-activite-famille-tree-opt1 phx-menu-link phx-hover ui-corner-all"><span class="phx-activite-famille-tree-opt1">'+
((__t=(i18n.t('common:activitetrees.optlibcod')))==null?'':__t)+
'</span></li>\n      <li class="phx-activite-famille-tree-opt2 phx-menu-link phx-hover ui-corner-all"><span class="phx-activite-famille-tree-opt2">'+
((__t=(i18n.t('common:activitetrees.optcodlib')))==null?'':__t)+
'</span></li>\n      <li class="phx-activite-famille-tree-opt3 phx-menu-link phx-hover ui-corner-all"><span class="phx-activite-famille-tree-opt3">'+
((__t=(i18n.t('common:activitetrees.optlib')))==null?'':__t)+
'</span></li>\n      <li class="phx-activite-famille-tree-opt4 phx-menu-link phx-hover ui-corner-all"><span class="phx-activite-famille-tree-opt4">'+
((__t=(i18n.t('common:activitetrees.optcod')))==null?'':__t)+
'</span></li>\n    </ul>\n    <div class="phx-activite-famille-tree-treediv">\n      <span class="phx-activite-famille-tree-tree"></span>\n    </div>\n  </div>\n</fieldset>\n';
}
return __p;
};
