import { CWHabilitationContext } from './cwHabilitationContext';
import { CWMenuGridModel } from 'core/grids/menugrid/cwMenuGrid.model';
import { CWPiecesJointesColl } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesJointes.collection';
import { CWSTR } from 'utils/cwStr';


export class CWRemoteFileModel extends CWMenuGridModel {

  /**
   * Default data model
   *	"code": null,
   *	"libelle": "",
   */
  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "libelle": ""
    }
  }

  /**
   * Constructor
   * Remote File Model
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (attributes && !attributes.coll) {
      attributes.coll = new CWPiecesJointesColl();
    }
    super(attributes, options);
    //empty
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/fichier";
    };
  }

  /**
   * Process to manage the response
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && !CWSTR.isBlank(response.code)) {
      this.id = response.code;
    }
    return response;
  }

  setHabContext(habContext: CWHabilitationContext): void {
    this.habContext = habContext;
  }

  updateHabContext(attributes: { [key: string]: any }): void {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  }

  getHabContext(): CWHabilitationContext {
    return this.habContext;
  }
}