import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model representing a Type d'evénement
 */
export class CWGererPretWorflowActifModel extends CWBaseModel {

  canViewTreatment: boolean;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.canViewTreatment = true;
  }

  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "libelle": "",
      "active": false
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    response.id = response.code;
    if (response.id === undefined) {
      const resp = response[0];
      resp.id = resp.code;
      return resp;
    }
    return response;
  }
}