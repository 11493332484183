module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class=\'ui-menu-item phx-menu-link\' title="'+
((__t=(nom))==null?'':__t)+
'">\n    <div tabindex="-1" role="menuitem" class="cw-menu-item-title d-flex align-items-center " data-ecran="'+
((__t=(id))==null?'':__t)+
'">\n        <span class="cw-menu-item-body d-flex flex-grow-1">\n            <a tabindex="0" href="'+
((__t=(href))==null?'':__t)+
'" class="'+
((__t=(id))==null?'':__t)+
' '+
((__t=(emid))==null?'':__t)+
' d-flex flex-grow-1">\n                <span class="cw-menu-element d-flex align-items-center flex-grow-1">\n                    <span class="'+
((__t=(id))==null?'':__t)+
' ctime-menu-item-libelle">'+
((__t=(nom))==null?'':__t)+
'</span>\n                </span>\n            </a>\n        </span>\n    </div>\n</li>\n';
}
return __p;
};
