import { CWBaseGridModel } from '../basegrid/cwBaseGrid.model';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';


export class CWDataGridModel<TColl extends CWPaginatedCollection = CWPaginatedCollection,
  TModel extends CWBaseModel = CWBaseModel> extends CWBaseGridModel<TColl, TModel> {

  habContext: CWHabilitationContext;
  groupeByColumn?: any;
  /**
   * Model underlying a basic DataGrid.
   * Events out: change:value
   * Events in: row:click, row:select, row:unselect
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options)
  }

  /**
   * The current habilitation context that the grid should use perform the actions (add/modify/remove)
   *
   */
  setHabContext(habContext: CWHabilitationContext): void {
    this.habContext = habContext;
  }

  updateHabContext(attributes: { [key: string]: any }): void {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  }

  getHabContext(): CWHabilitationContext {
    return this.habContext;
  }
}