import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWSelecteurCheminItemModel } from 'core/components/selecteur_chemin/cwSelecteurCheminItem.model';
import { CWSelecteurCheminView } from 'core/components/selecteur_chemin/cwSelecteurChemin.view';
import { CWTYPE } from 'tda/cwTda';

/**
 * Plain Text Structures Chemin View
 */
export class CWPlainStructuresView extends Backbone.View<CWSelecteurCheminItemModel> {

  table: boolean;
  context: any;
  plainText: any;
  populationColl: any;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.tagName = "span";
    super(options);
    // if we are going to show this in a table or not.
    this.table = true;
    this.table = _.isBoolean(options.table) ? options.table : false;
    this.context = options.context;
    this.plainText = _.isBoolean(options.plainText) ? options.plainText : options.plainText;
    this.populationColl = options.populationColl;
  }

  render(): CWPlainStructuresView {
    let chemin: CWSelecteurCheminView = null;

    // The selector is shown if the collaborateur is rattached to it
    if (this.model.model.get("itemid")) {
      let item: CWSelecteurCheminItemModel = null;

      chemin = new CWSelecteurCheminView({
        name: "struct",
        listRenderStyle: this.model.structure ? this.model.structure.format : null, //format
        fieldWidth: 220,
        structid: this.model.structCode, //code
        datedebut: this.model.debsitu ? this.model.debsitu : CWTYPE.DATE.INITIAL,
        datefin: this.model.finsitu ? this.model.finsitu : CWTYPE.DATE.INFINITY,
        intermediaire: true,
        partial: true,
        modeComplet: false,
        multiselection: false,
        readonly: true,
        racine: true,
        nonrattachable: true,
        context: this.context,
        plainText: this.plainText
      });
      item = new CWSelecteurCheminItemModel({
        "structid": this.model.structCode,
        "date": this.model.debsitu ? this.model.debsitu : CWTYPE.DATE.INITIAL,
        "elementCode": this.model.model.get("itemid"),
        "nonrattachable": true,
        "racine": true
      });
      chemin.clearSelection();
      chemin.addValue(item);
      chemin.setElement(this.$el);
      chemin.on("generatedTooltip:selectourChemin", (): void => {
        const structureModel = chemin.selection.at(0);
        const populationModel = this.populationColl.findWhere({ code: String(structureModel.get("id")) });

        if (populationModel) {
          populationModel.set("libelle", structureModel.get("label"));
        }
      }, this);
    }
    return this;
  }
}