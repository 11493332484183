import { CWBaseModel } from 'src/core/models/cwBase.model';

export class CWCalendaccZonesModel extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    CWCalendaccZonesModel.prototype.idAttribute = "code";
    super(attributes, options);
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      response.colorRGB = "RGB(" + response.couleur.coder + "," + response.couleur.codeg + "," + response.couleur.codeb + ")";
    }
    return response;
  }
}