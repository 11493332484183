import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Le modèle du contexte actif (true) ou inactif (false).
 */
export class CWContexteActifModel extends CWBaseModel {

  public habilitationV: string;
  public habilitationG: string;
  public usecase: string;
  public contexteActif: string;
  public typeEvt: string;
  public typologie: string;

  defaults(): { [key: string]: any } {
    return {
      "active": false
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "app";
    this.contexteActif = null;
    this.typeEvt = null;
    this.typologie = null;
    if (options) {
      if (!CWSTR.isBlank(options.contexteActif)) {
        this.contexteActif = options.contexteActif;
      }
      if (!CWSTR.isBlank(options.typeEvt)) {
        this.typeEvt = options.typeEvt;
      }
      if (!CWSTR.isBlank(options.typologie)) {
        this.typologie = options.typologie;
      }
    }
    this.url = (): string => {
      let lsUrl = "/rest/typeevt/contexte/actif/";

      if (CWSTR.isBlank(this.contexteActif)) {
        throw new Error("Context actif not supported");
      }
      if (CWSTR.isBlank(this.typeEvt)) {
        throw new Error("Type Event not supported");
      }
      lsUrl += encodeURIComponent(this.contexteActif + "," + this.typeEvt);
      if (!CWSTR.isBlank(this.typologie)) {
        lsUrl += encodeURIComponent("," + this.typologie);
      }
      return Configuration.restRoot + lsUrl;
    };
  }
}
