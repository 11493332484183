import _ from 'underscore';
import TPLGereronebadgeagePopUp from '../cwPopUp.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWGererBadgeagesGestion } from 'src/uc/common/evenements/gerer/gererbadgeages/models/cwGererBadgeagesGestion';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';
import { UTILS } from 'src/utils/utils.js';


export class CWGererOneBadgeagePopUpView extends CWBaseFormView {

  action: string;
  nombre: string | number;
  parent: any;
  statut: string;
  utilisateur: string;

  /**
   * Event launched when a non-readonly input changes its value
   */
  /**
   * Event launched when a non-readonly input event keyup happens
   */
  /**
   * Event launched when button is clicked
   */

  /**
   * Constructor
   * View used for gerer one badgeage
   */
  constructor(options?: ViewOptions<Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_notifyEdition",
      "click button": "_clickListener"
    }, options.events);
    super(options);
    this.template = TPLGereronebadgeagePopUp;
    this.model = new CWBaseModel({
      value: new CWGererBadgeagesGestion()
    });
    this.parent = options.parent;
    this.nombre = options.nombre;
    this.action = options.action;
    this.statut = options.statut;
    this.utilisateur = options.utilisateur;
  }

  /**
   * Renders this view
   */
  render(): CWGererOneBadgeagePopUpView {
    const json = { "i18n": i18n, UTILS: UTILS };

    this.$el.append(this.template(json));
    switch (this.action) {
      case "accepter":
        break;
      case "refuser":
        break;
      case "suppression":
        if (this.statut === "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionCours'));
        } else if (this.statut === "A") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionAccept'));
        } else {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionSupp'));
        }
        break;

      case "suppressionTous":
        if (this.statut === "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionCours'));
        } else if (this.statut === "A") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionAccept'));
        } else {
          if ((this.nombre as number) > 1) {
            $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionSuppPlus'));
          } else {
            $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionSupp'));
          }
        }

        break;
      case "accepterTous":
        if (this.statut === "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_AcceptDemande'));
        } else if (this.statut === "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_AcceptCours'));
        }
        break;
      case "refuserTous":
        if (this.statut === "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_RefusDemande'));
        } else if (this.statut === "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_RefusCours'));
        }
        break;
    }

    if (this.utilisateur === "Responsable" && (this.action === "refuser" || this.action === "refuserTous")) {

      this.$el.find("[for=commentaire]").addClass("cw-required");
      //this.model.get("value").off("invalid");
      //this.model.get("value").on("invalid", this._showValidationErrors, this);
    }

    return this;
  }

  /**
   * Manages buttons actions
   */
  _clickListener(event: JQueryEventObject): void {
    const btnClicked = $(event.currentTarget).val();
    const model = this.model.get("value");
    const errors: { commentaire?: string } = {};
    let errorMap: { errorValidation?: string; errors?: { [key: string]: any } } = null;

    switch (btnClicked) {
      case "btnOK":
        //validation
        if (this.action === "refuser" || this.action === "refuserTous") {
          if (CWSTR.isBlank(model.get("commentaire"))) {
            errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gereronebadgeage.comment') });
          }
        }
        if (!_.isEmpty(errors)) {
          errorMap = {
            errorValidation: CWSTR.formatMessage(errors),
            errors: errors
          };
          this._showValidationErrors(this.model.get("value"), errorMap);
        } else {
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.close();
        }
        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
        break;
    }
  }
}
