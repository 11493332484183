import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLRecaphistoDetail from '../detail.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWCommonBlockView } from 'core/components/collapsible_block/cwBlock.view';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { RecapHistoArray } from '../models/recapHistoArray.js';
import { RecapHistoColl } from '../models/recapHisto.collection.js';
import { RecapHistoModel } from '../models/recapHisto.model.js';

/**
 * View for the workflow component
 */
export class CWBlockView extends CWCommonBlockView {


  loadedData: boolean;
  model: CWBaseModel;
  context: { [key: string]: any };
  title: string;
  titleAction: string;
  popupView: CWDialogView;
  public $appendTo: JQuery; //pour les messages

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    let opt: any = null;

    options = options || {};
    // list of components to load
    opt = {
      component: "recaphisto",
      detailTemplate: TPLRecaphistoDetail,
      buttons: []
    }
    options.events = _.extend({
      "click span.Detaille": "_detailView",
      "click span.Simple": "_synthetiqueView"
    }, CWCommonBlockView.prototype.events, options.events);
    options = _.extend({}, opt, options);
    super(options);
    this.loadedData = null;
    this.model = new CWBaseModel({
      value: new RecapHistoColl(),
      array: new RecapHistoArray()
    });
    this.model.on("expand", this._loadDataInFirstTime, this);
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
  }

  _loadDataInFirstTime(data: any): void {
    if (!this.loadedData && data.expanded) {
      this._loadData();
    }
  }

  setContext(context: any): void {
    this.context = context;
    this.title = "";
    this.titleAction = "";

    if (CWSTR.isBlank(this.context.ctxDemId) || String(this.context.ctxDemId) === "0" || this.context.ctxDemId === "null") {
      this.context.ctxDemId = null;
    }
    if (CWSTR.isBlank(this.context.ctxRefEvenement) || this.context.ctxRefEvenement === "null") {
      this.context.ctxRefEvenement = null;
    }

    //Default values
    if (this.context.ctxModeRestitution === "Imbrique") {
      this.title = i18n.t('common:recaphisto.title_imbrique');
      if (this.context.ctxActivModeSynth === true) {
        this.titleAction = "&nbsp<span class='Detaille' style='cursor:pointer;'>+</span> / <span class ='Simple' style='cursor:pointer;'>[-]</span>";
      }
    }

    if (this.context.ctxModeRestitution === "pop-up") {
      this.title = i18n.t('common:recaphisto.title_popup');
    }

    if (this.context.ctxModeRestitution === "Infobulle") {
      this.title = "";
      this.context.ctxActivModeSynth = true;
      this.context.ctxModeDefaut = "Deplier";
      this.hideIcon();
    }

    this.model.get("value").setHabContext(
      new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation
      })
    );

    this.model.get("array").setHabContext(
      new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation
      })
    );

    this._manageBlocEstDeplie();
    this.setTitle(this.title + this.titleAction);
    this.showBlock();
  }

  // override default function
  canAccess(): boolean {
    return true;
  }

  _loadData(): void {

    this.loadedData = true;

    this.model.get("array").set("type", this.context.ctxTypeEvenement);
    this.model.get("array").set("evenement", this.context.ctxRefEvenement);
    this.model.get("array").set("iddemande", this.context.ctxDemId);
    this.model.get("array").save(null, {
      success: (fresh: any): void => {
        this.setContent(this._generateContent(this._arrayToCollection(fresh)));
        if (fresh.length === 0) {
          this.hideIcon();
        }
      }
    });
  }

  _arrayToCollection(array: any): any {
    const coll = this.model.get("value");
    array.unset("evenement");
    array.unset("iddemande");
    array.unset("type");
    _.each(array.attributes, (item: any): void => {
      const evt = new RecapHistoModel(item);
      coll.add(evt);
    });
    return coll;
  }

  _detailView(): void {
    this.context.ctxActivModeSynth = false;
    this.setContent(this._generateContent(this.model.get("value")));
    this.titleAction = "&nbsp<span class='Detaille' style='cursor:pointer;'>[+]</span> / <span class ='Simple' style='cursor:pointer;'>-</span>";
    this.setTitle(this.title + this.titleAction);
    this.expanded = true;
    this.expand();
  }

  _synthetiqueView(): void {
    this.context.ctxActivModeSynth = true;
    this.setContent(this._generateContent(this.model.get("value")));
    this.titleAction = "&nbsp<span class='Detaille' style='cursor:pointer;'>+</span> / <span class ='Simple' style='cursor:pointer;'>[-]</span>";
    this.setTitle(this.title + this.titleAction);
    this.expanded = false;
    this.collapse();
  }

  _bold(normalText: string): string {
    const boldText = "<b>" + normalText + "</b>";
    return boldText;
  }

  _manageVisualization(): void {
    if (this.context.ctxBlocEstDeplie) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  _manageBlocEstDeplie(): void {
    if (this.context.ctxModeDefaut === "Replie") {
      this.collapse();
      this.loadedData = false;
    } else {
      this.expand();
      this.loadedData = true;
    }
  }

  _generateContent(collection: any): string {
    let htmlContent: string = "<table class='phx-recaphisto-table'>";
    if (this.context.ctxActivModeSynth === true) {
      if (collection && collection.length > 0) {
        htmlContent += "<tr class='phx-recaphisto 0'>";
        htmlContent += this._buildLine(collection.at(0));
        htmlContent += "</tr>";
      }
      if (collection && collection.length > 1) {
        htmlContent += "<tr class='phx-recaphisto 1'>";
        htmlContent += this._buildLine(collection.at(collection.length - 1));
        htmlContent += "</tr>";
      }
    } else {
      if (collection) {
        for (let i = 0; i < collection.length; i++) {
          htmlContent += "<tr class='phx-recaphisto " + i + "'>";
          htmlContent += this._buildLine(collection.at(i));
          htmlContent += "</tr>";
        }
      }
    }

    htmlContent += "</table>";
    htmlContent += "<br/>";
    return htmlContent;
  }

  _buildLine(model: any): string {
    let result = "<td>";
    const date = " " + i18n.t('messages:GL_1026', {
      "1": this._bold(CWTYPE.DATE.format(model.get("date"), CWTYPE._getFormatByCode("DATE_A")))
    });
    let author = "";
    if (!CWSTR.isBlank(model.get("auteur"))) {
      author = " " + i18n.t('messages:GL_1028', { "1": model.get("auteur") });
    }
    switch (model.get("action")) {
      case "CREAT":
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1058') + "</span><span>" + date + author;
        break;
      case "SOUM_D":
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1059') + "</span><span>" + date + author;
        break;
      case "SOUM_A":
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1060') + "</span><span>" + date + author;
        break;
      case "CORR":
      case "MODIF":
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1014') + "</span><span>" + date + author;
        break;
      case "ACC_F":
        if (model.get("origine") === "AUTO") {
          author = " " + i18n.t('messages:GL_1030');
        }
        result += "<span class='ui-phx-actes-acceptation'>" + i18n.t('messages:GL_1020') + "</span><span>" + date + author;
        break;
      case "ACC_I":
        if (model.get("origine") === "AUTO") {
          author = " " + i18n.t('messages:GL_1030');
        }
        result += "<span class='ui-phx-actes-acceptation'>" + i18n.t('messages:GL_1018') + "</span><span>" + date + author;
        break;
      case "DEM_REVV":
        result += "<span class='ui-phx-actes-refus'>" + i18n.t('messages:GL_1061') + "</span><span>" + date + author;
        break;
      case "DEM_REVC":
        result += "<span class='ui-phx-actes-refus'>" + i18n.t('messages:GL_1062') + "</span><span>" + date + author;
        break;
      case "DEM_MOD":
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1063') + "</span><span>" + date + author;
        break;
      case "REV_V":
      case "REV_C":
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1064') + "</span><span>" + date + author;
        break;
      case "REL_V":
        if (model.get("origine") === "AUTO") {
          author = " " + i18n.t('messages:GL_1031');
        }
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1065') + "</span><span>" + date + author;
        break;
      case "REL_S":
        if (model.get("origine") === "AUTO") {
          author = " " + i18n.t('messages:GL_1031');
        }
        result += "<span class='ui-phx-autres-actes'>" + i18n.t('messages:GL_1066') + "</span><span>" + date + author;
        break;
    }
    if (!CWSTR.isBlank(model.get("commentaire"))) {
      result += "</span><br><span style='padding-left:30px;'>" + model.get("commentaire");
    }
    result += "</span></td>";

    return result;
  }

  _manageButtons(event: any): void {
    const buttonId = event.currentTarget.className.split(" ")[2];

    switch (buttonId) {
      case "create":
        this.popupView = this._initPopupView(this.model.get("histoEvent"));
        this.popupView.open();
        break;
    }
  }

  _initPopupView(model: any): CWDialogView {
    const dialog = new CWDialogView({
      view: CWBlockView,
      viewData: {
        model: model,
        appendTo: this.$appendTo
      }
    });
    dialog.setHeight(300);
    dialog.setWidth(300);
    return dialog;
  }
}
