import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererrecapitulatifsDetailMixteType from '../cwDetailMixteType.tpl.html';
import { CWACTIVITE } from 'utils/cwActivite';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWBaseView } from 'common/evenements/gerer/gererrecapitulatifs/views/cwBase.view';
import { CWButtonBarView } from 'core/controls/cwButtonBar.view';
import { CWGererAbsenceSouhaitView } from 'common/evenements/gerer/gererabsencesouhait/views/cwGererAbsenceSouhait.view';
import { CWGererrecapitulatifsWorkflow } from './../models/cwGererrecapitulatifs.workflow';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWMixteViewModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwMixteView.model';
import { CWSTR } from 'utils/cwStr';
import { CWTableColl } from 'common/evenements/gerer/gererrecapitulatifs/models/cwTable.collection';
import { CWTreeTypeGridModel } from 'core/grids/treetypegrid/cwTreeTypeGrid.model';
import { CWTreeTypeGridView } from 'core/grids/treetypegrid/cwTreeTypeGrid.view';
import { CWTYPE } from 'tda/cwTda';
import { CWVoletView } from 'core/components/dialog/volet/cwVolet.view';
import { i18n } from 'src/i18n.js';


export class CWMixteTypeView extends CWBaseView {
  btnBarD: CWButtonBarView;
  btnBarP: CWButtonBarView;
  model: CWMixteViewModel;
  popupAbsenceDialog: CWVoletView;
  popupActiviteDialog: CWVoletView;
  popupView: any;
  tableColl: CWTableColl;
  template: any;
  workflow: CWGererrecapitulatifsWorkflow;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click input.supprimerPHeaderMixte": "_manageHeaderCheckbox",
      "click input.supprimerDHeaderMixte": "_manageHeaderCheckbox",
      "click .supprimerPMonth": "_manageMonthCheckbox",
      "click .supprimerDMonth": "_manageMonthCheckbox",
      "click .supprimerPWeek": "_manageWeekCheckbox",
      "click .supprimerDWeek": "_manageWeekCheckbox",
      "click .supprimerPDay": "_manageDayCheckbox",
      "click .supprimerDDay": "_manageDayCheckbox",
      "click .supprimerPLine": "_manageLineCheckbox",
      "click .supprimerDLine": "_manageLineCheckbox",
      "click .piece-jointe": "_managePieceJointe",
      "click .gerer-recapitulatif-absence-prevue-ev": "_consulterAbsence",
      "click .gerer-recapitulatif-absence-realisee-ev": "_consulterAbsence",
      "click .gerer-recapitulatif-activite-prevue-ev": "_manageActivity",
      "click .gerer-recapitulatif-activite-realisee-ev": "_manageActivity",
      "click .phx-icon.phx-icon-transformer": "_manageTransform",
      "click .phx-icon.phx-icon-transformer-and-modify": "_manageTransformAndModif",
      "mouseenter .monthDataP": "_hoverBtnTitleP",
      "mouseleave .monthDataP": "_hoverBtnTitleP",
      "mouseenter .weekDataP": "_hoverBtnTitleP",
      "mouseleave .weekDataP": "_hoverBtnTitleP",
      "mouseenter .dayDataP": "_hoverBtnTitleP",
      "mouseleave .dayDataP": "_hoverBtnTitleP",
      "mouseenter .monthDataR": "_hoverBtnTitleR",
      "mouseleave .monthDataR": "_hoverBtnTitleR",
      "mouseenter .weekDataR": "_hoverBtnTitleR",
      "mouseleave .weekDataR": "_hoverBtnTitleR",
      "mouseenter .dayDataR": "_hoverBtnTitleR",
      "mouseleave .dayDataR": "_hoverBtnTitleR",
      "change .mixteTypeView :input:not([readonly])": "_customChange"
    });
    super(options);
    this.template = TPLGererrecapitulatifsDetailMixteType;
    this.workflow = options.workflow;
    this.model = new CWMixteViewModel();
    this.btnBarP = new CWButtonBarView({ id: "supprimerP_btnbar", isHideOnDisabled: false });
    this.btnBarD = new CWButtonBarView({ id: "supprimerD_btnbar", isHideOnDisabled: false });
    this.tableColl = new CWTableColl();
    this.table = this._initTable();
    this.table.model.coll.nbevPSuppr = 0;
    this.listenTo(this.btnBarP.model, "btn:click", (buttonId: any) => {
      this.workflow._supprimerPrevuesAction(buttonId);
    });
    this.listenTo(this.btnBarD.model, "btn:click", (buttonId: any) => {
      this.workflow._supprimerDeclaresAction(buttonId);
    });
    this.popupView = null;
    this.workflow.on("initierSaisieActMixteType", this._manageInitierSaisieActivites, this);
  }

  _getModel(): any {
    return this.model;
  }

  render(): CWMixteTypeView {
    const json = { "i18n": i18n };

    this.$el.append(this.template(json));
    this.$el.find(".tableMixteType").html(this.table.el);
    this.table.render();
    this._renderHeaderButton();
    return this;
  }

  _ajoutActivityMixteType(events: JQueryEventObject): void {
    this.workflow._ajoutActivityMixteType(events.currentTarget);
  }

  _renderHeaderButton(): void {
    this.btnBarP.render().$el.appendTo(this.$el.find(".firstMixteLine .supprimerMixteButtons").first());
    this.btnBarP.addButton("deleteP", i18n.t('common:gererrecapitulatifs.btnSupprimerPrevue'));
    this.btnBarP.model.trigger("hide:save");
    this.btnBarP.model.trigger("hide:revert");
    this.btnBarP.model.trigger("hide:new");
    this.btnBarP.model.trigger("hide:copy");
    this.btnBarP.model.trigger("hide:delete");
    this.btnBarD.render().$el.appendTo(this.$el.find(".firstMixteLine .supprimerMixteButtons").first());
    this.btnBarD.addButton("deleteR", i18n.t('common:gererrecapitulatifs.btnSupprimerRealisee'));
    this.btnBarD.model.trigger("hide:save");
    this.btnBarD.model.trigger("hide:revert");
    this.btnBarD.model.trigger("hide:new");
    this.btnBarD.model.trigger("hide:copy");
    this.btnBarD.model.trigger("hide:delete");
  }

  findAndSelectCurrentEvenement(): void {
    // If an evenement is selected, reselect it if it exists
    if (!CWSTR.isBlank(this.table.model.get("value"))) {
      const value = this.table.model.get("value");
      const modelInColl = this.table.model.coll.get(value);

      if (!CWSTR.isBlank(modelInColl)) {
        modelInColl.trigger("row:click", modelInColl);
      }
    }
  }

  /**
   * Manages the event generated when a value in the form is changed
   */
  _customChange(event: JQueryEventObject, data: any): void {
    let className: string = null;

    // Manage Custom actions
    this._change(event, data);
    className = event.target.className.split(" ")[0];
    if (className === "toujours") {
      const currentSelected = this.table.model.get("value");
      const callback = (): void => {
        const value = this.workflow.valueToSelect.get("value");
        // Select current month, week or day if it was selected
        if (!CWSTR.isBlank(value)) {
          if (value.level < 4) {
            const modelInColl = this.table.model.coll.get(value);
            if (!CWSTR.isBlank(modelInColl)) {
              modelInColl.trigger("row:click", modelInColl);
            }
            this.workflow._resetSelectData();
          } else {
            if (!CWSTR.isBlank(value.get("realiseeColumn"))) {
              // Select realisee column file
              this.workflow._selectNewRow(value.get("realiseeColumn"));
              this.workflow._resetSelectData();
            } else if (!CWSTR.isBlank(value.get("prevueColumn"))) {
              // Select prevue column file
              this.workflow._selectNewRow(value.get("prevueColumn"));
              this.workflow._resetSelectData();
            } else {
              // Select day
              const idToSelect = this.workflow._getDayIdByEvenementID(value);
              const dayToSelect = this.table.model.coll.get(idToSelect);
              if (!CWSTR.isBlank(dayToSelect)) {
                dayToSelect.trigger("row:click", dayToSelect);
              }
              this.workflow._resetSelectData();
            }
          }
        }
      };

      //When toujours has changed and there has been a transformation made before,
      // repaintTable in order to show activites prevues only if they are requested
      //or if they have an activite realisée associated
      this.workflow.valueToSelect.set("value", currentSelected);
      this.workflow._repaintTable(callback, true);
    }
  }

  _hoverBtnTitleP(event: JQuery.TriggeredEvent): void {
    this._hoverBtnTitle(event, "P", this.workflow.MIXTE);
  }

  _hoverBtnTitleR(event: JQuery.TriggeredEvent): void {
    this._hoverBtnTitle(event, "R", this.workflow.MIXTE);
  }

  _checkButtonsSupprimerEv(): void {
    const tableColl = this.table.model.coll;
    const habAct = this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR ? "COL_ACT_PREV.G" : "RES_ACT_PREV.G";
    const habRecap = this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";

    this.$el.find("#supprimerP_btnbar").css("padding", "0px").css("margin-left", "auto").css("margin-right", "auto");
    this.$el.find("#supprimerD_btnbar").css("padding", "0px").css("margin-left", "auto").css("margin-right", "auto");
    if (!CWHABILITATION.canUpdate(habRecap)) {
      this.btnBarP.model.trigger("hide:deleteP");
      this.btnBarD.model.trigger("hide:deleteR");
    } else {
      if (CWHABILITATION.canDelete(habAct)) {
        if (tableColl.nbevPSuppr > 0) {
          this.btnBarP.model.trigger("enable:deleteP");
        } else {
          this.btnBarP.model.trigger("disable:deleteP");
        }
        if (tableColl.nbevDSuppr > 0) {
          this.btnBarD.model.trigger("enable:deleteR");
        } else {
          this.btnBarD.model.trigger("disable:deleteR");
        }
      } else {
        this.btnBarP.model.trigger("disable:deleteP");
        this.btnBarD.model.trigger("disable:deleteR");
      }
    }
  }

  _checkHeadersCheckboxes(): void {
    const prevueColl = this.workflow._getAnyPrevueEvenement(true);
    const evCanBeSupressed = _.find(prevueColl.models, (model: { [key: string]: any }) => { return !CWSTR.isBlank(model.evPSuppr); });
    const realiseeColl = this.workflow._getAnyRealiseeEvenement(true);
    const evRealCanBeSupressed = _.find(realiseeColl.models, (model: { [key: string]: any }) => { return !CWSTR.isBlank(model.evDSuppr); });

    if (CWSTR.isBlank(evCanBeSupressed)) {
      this.$el.find(".supprimerPHeaderMixte").hide();
    }
    else {
      this.$el.find(".supprimerPHeaderMixte").show();
      this.$el.find(".supprimerPHeaderMixte").prop("checked", false);
    }
    if (CWSTR.isBlank(evRealCanBeSupressed)) {
      this.$el.find(".supprimerDHeaderMixte").hide();
    } else {
      this.$el.find(".supprimerDHeaderMixte").show();
      this.$el.find(".supprimerDHeaderMixte").prop("checked", false);
    }
  }

  /**
   * This function manages the change of the vue for left table, in order to add a synthetique param
   * and make sorting work
   */
  _vueChanged(): void {
    this._checkButtonsSupprimerEv();
  }

  _inputBuilder(id: string): string {
    let ckb = "";

    ckb = '<div class="' + id + ' form-group">';
    ckb += '<div class="custom-control custom-checkbox">';
    ckb += '  <input id="' + id + '" type="checkbox" class="' + id + ' custom-control-input" value="a" />';
    ckb += '  <label for="' + id + '" class="custom-control-label"></label>';
    ckb += '</div>';
    ckb += '</div>';
    return ckb;
  }

  _inputBuilder2(id: string, type: string, ...val: any[]): string {
    let ckb = "";

    ckb = '<div class="form-group">';
    ckb += '<div class="custom-control custom-checkbox">';
    switch (type) {
      case "MONTH":
        ckb += '<input id="' + id + '" data-type="MONTH" data-year="' + val[0] + '" data-month="' + val[1] + '" class="' + val[2] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[3] + ' />';
        break;
      case "WEEK":
        ckb += '<input id="' + id + '" data-type="WEEK" data-year="' + val[0] + '" data-month="' + val[1] + '" data-week="' + val[2] + '" class="' + val[3] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[4] + ' />';
        break;
      case "DAY":
        ckb += '<input id="' + id + '" data-type="DAY" data-year="' + val[0] + '" data-month="' + val[1] + '" data-week="' + val[2] + '" data-date="' + val[3] + '" class="' + val[4] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[5] + ' />';
        break;
      case "EVENEMENT":
        ckb += '<input id="' + id + '" data-type="EVENEMENT" data-year="' + val[0] + '" data-month="' + val[1] + '" data-week="' + val[2] + '" data-date="' + val[3] + '" data-id="' + id + '" class="' + val[4] + ' ' + val[0] + ',' + val[1] + ',' + val[2] + ',' + val[3] + ' ' + id + ' custom-control-input" type="checkbox" ' + val[5] + ' />';
        break;
    }
    ckb += '  <label for="' + id + '" class="custom-control-label"></label>';
    ckb += '</div>';
    ckb += '</div>';
    return ckb;
  }


  /**
   * Defines the treeTable and the function used to load the subtree
   */
  _initTable(): CWTreeTypeGridView {
    const tableModel = new CWTreeTypeGridModel({ coll: this.tableColl });
    const columnSupprimerPTitle = this._inputBuilder("supprimerPHeaderMixte"); //'<br><input class="supprimerPHeaderMixte" type="checkbox" value="a" />';
    const columnSupprimerDTitle = this._inputBuilder("supprimerDHeaderMixte"); //'<br><input class="supprimerDHeaderMixte" type="checkbox" value="a" />';
    const tblColumnsHM = [
      { title: i18n.t('common:gererrecapitulatifs.grid_evenementP'), property: "evenementP", width: 3, treecol: true },
      { title: i18n.t('common:gererrecapitulatifs.grid_statut'), property: "statutP", width: 1 },
      { title: columnSupprimerPTitle, property: "supprimerP", width: 1 },
      { title: "", property: "transformer", width: 1 },
      //{ title: "", property: "transformerM", width: 1 },
      { title: i18n.t('common:gererrecapitulatifs.grid_evenementD'), property: "evenementD", width: 3 },
      { title: i18n.t('common:gererrecapitulatifs.grid_statut'), property: "statutR", width: 1 },
      { title: i18n.t('common:gererrecapitulatifs.grid_duree_hm'), property: "duree", width: 1 },
      { title: columnSupprimerDTitle, property: "supprimerD", width: 1 }
    ];
    const tblColumnsHC = [
      { title: i18n.t('common:gererrecapitulatifs.grid_evenementP'), property: "evenementP", width: 3, treecol: true },
      { title: i18n.t('common:gererrecapitulatifs.grid_statut'), property: "statutP", width: 1 },
      { title: columnSupprimerPTitle, property: "supprimerP", width: 1 },
      { title: "", property: "transformer", width: 1 },
      //{ title: "", property: "transformerM", width: 1 },
      { title: i18n.t('common:gererrecapitulatifs.grid_evenementD'), property: "evenementD", width: 3 },
      { title: i18n.t('common:gererrecapitulatifs.grid_statut'), property: "statutR", width: 1 },
      { title: i18n.t('common:gererrecapitulatifs.grid_duree_hc'), property: "duree", width: 1 },
      { title: columnSupprimerDTitle, property: "supprimerD", width: 1 }
    ];
    const vues: { [key: string]: any } = {};

    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')] = { columns: tblColumnsHM };
    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')] = { columns: tblColumnsHC };
    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')].menuName = i18n.t('common:gererrecapitulatifs.vue_pd_HM_menu_name');
    vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')].menuName = i18n.t('common:gererrecapitulatifs.vue_pd_HC_menu_name');

    const table = new CWTreeTypeGridView({
      vues: vues,
      model: tableModel,
      defaultVue: i18n.t('common:gererrecapitulatifs.vue_pd_HM'),
      title: "", //i18n.t('common:gererrecapitulatifs.grid_title_mono'),
      showFilteredRowsInTitle: false,
      gridHeightType: "auto"
    });

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')].cellRenderers["evenementP"] = (model: any): any => {
      const span = this._generateEvenementP(model, i18n.t('common:gererrecapitulatifs.vue_pd_HM'));

      return span;
    };
    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')].cellRenderers["evenementP"] = (model: any): any => {
      const span = this._generateEvenementP(model, i18n.t('common:gererrecapitulatifs.vue_pd_HC'));

      return span;
    };
    table.cellRenderers["statutP"] = (model: { [key: string]: any }): any => {
      let resp = "";

      if (model.level === 4 && !CWSTR.isBlank(model.get("prevueColumn"))) { //Evenement
        if (model.get("prevueColumn").type === this.workflow.ACTIVITE) { //ACTIVITES
          const manageActivite = this.workflow._checkActiviteGereParRecap(model.get("prevueColumn"));

          if (manageActivite === false) {
            resp = this.workflow._buildActiviteSpanStatus(model.get("prevueColumn"));
          }
        } else if (model.get("prevueColumn").type === this.workflow.ABSENCE) { //ABSENCES
          const manageAbsence = this.workflow._checkActiviteGereParRecap(model.get("prevueColumn"), true);

          if (manageAbsence === false) {
            resp = this.workflow._buildAbsenceSpanStatus(model.get("prevueColumn"));
          }
        }
      }
      return resp;
    };
    table.cellRenderers["statutR"] = (model: { [key: string]: any }): any => {
      let resp = "";

      if (model.level === 4 && !CWSTR.isBlank(model.get("realiseeColumn"))) { //Evenement
        if (model.get("realiseeColumn").type === this.workflow.ACTIVITE) { //ACTIVITES
          const manageActivite = this.workflow._checkActiviteGereParRecap(model.get("realiseeColumn"));

          if (manageActivite === false) {
            resp = this.workflow._buildAbsenceSpanStatus(model.get("realiseeColumn"));
          }
        } else if (model.get("realiseeColumn").type === this.workflow.ABSENCE) { //ABSENCES
          const manageAbsence = this.workflow._checkActiviteGereParRecap(model.get("realiseeColumn"), true);

          if (manageAbsence === false) {
            resp = this.workflow._buildAbsenceSpanStatus(model.get("realiseeColumn"));
          }
        }
      }
      return resp;
    };
    table.cellRenderers["supprimerP"] = (model: { [key: string]: any }): any => {
      let year = "",
        month = "",
        dateBBDD = "",
        week = "";
      let evCanBeSupressed: CWBaseModel = null;

      if (this.workflow._recapEtatCanBeSupressed("P") === true) {
        const checkedAttribute = model.evPSuppr === true ? "checked" : "";

        if (model.level === 1) { //MONTH
          year = model.id.split(",")[0];
          month = model.id.split(",")[1];
          const prevueMonthColl = this.workflow._getPrevueMonthEvenements(true, Number(year), Number(month));

          evCanBeSupressed = _.find(prevueMonthColl.models, (model: any) => { return !CWSTR.isBlank(model.evPSuppr); });
          if (prevueMonthColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id, "MONTH", year, month, "supprimerPMonth", checkedAttribute);
            //return '<input data-type="MONTH" data-year="' + year + '" data-month="' + month + '" class="supprimerPMonth ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
        } else if (model.level === 2) { //WEEK
          year = model.id.split(",")[0];
          month = model.id.split(",")[1];
          const weekArray = model.id.split(",");
          week = weekArray[2];
          const prevueWeekColl = this.workflow._getPrevueWeekEvenements(true, Number(year), Number(week));
          evCanBeSupressed = _.find(prevueWeekColl.models, (model: any) => { return !CWSTR.isBlank(model.evPSuppr); });
          if (prevueWeekColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id, "WEEK", year, month, week, "supprimerPWeek", checkedAttribute);
            //return '<input data-type="WEEK" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" class="supprimerPWeek ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
          //if is delegue view in style delegation
        } else if (model.level === 3) { //DAY
          year = model.id.split(",")[0];
          month = model.id.split(",")[1];
          week = model.id.split(",")[2];
          dateBBDD = model.id.split(",")[3];
          const prevueDayColl = this.workflow._getDayPrevueEvenements(true, dateBBDD);
          evCanBeSupressed = _.find(prevueDayColl.models, (model: any) => { return !CWSTR.isBlank(model.evPSuppr); });
          if (prevueDayColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id + ",P", "DAY", year, month, week, dateBBDD, "supprimerPDay", checkedAttribute);
            //return '<input data-type="DAY" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" data-date="' + dateBBDD + '" class="supprimerPDay ' + model.id + '" type="checkbox" ' + checkedAttribute + '/>';
          }
        } else if (model.level === 4) {
          const prevueModel = model.get("prevueColumn");
          if (!CWSTR.isBlank(prevueModel) && !CWSTR.isBlank(model.evPSuppr) && prevueModel.type === this.workflow.ACTIVITE) {
            year = prevueModel.year;
            month = prevueModel.month;
            week = prevueModel.week;
            dateBBDD = prevueModel.currentDate;
            let code = prevueModel.get("code");
            if (code.indexOf("/") !== -1) {
              code = prevueModel.get("code").replace('/', '_');
            }
            return this._inputBuilder2(code, "EVENEMENT", year, month, week, dateBBDD, "supprimerPLine", checkedAttribute);
            //return '<input data-type="EVENEMENT" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" data-date="' + dateBBDD + '" class="supprimerPLine ' + year + ',' + month + ',' + week + ',' + dateBBDD + ' ' + code + '" type="checkbox" ' + checkedAttribute + ' />';
          }
        }
      }
      return '';
    };

    table.cellRenderers["transformer"] = (model: { [key: string]: any }): any => {
      let resp = "";
      if (model.level === 1) { //MONTH
        const firstMonthDay = new Date(model.monthInfoArray[1], model.monthInfoArray[0], 1); //function used to obtain the first month day
        const lastMonthDay = new Date(model.monthInfoArray[1], model.monthInfoArray[0] + 1, 0); //function used to obtain the first month day
        const firstMonthDayBBDD = CWTYPE.DATE.dateToStr(firstMonthDay, "YYYYMMDD");
        const lastMonthDayBBDD = CWTYPE.DATE.dateToStr(lastMonthDay, "YYYYMMDD");
        if (this.workflow._canTransformInPeriod(firstMonthDayBBDD, lastMonthDayBBDD, false)) {
          return '<span class="phx-icon phx-icon-transformer" data-startDate="' + firstMonthDayBBDD + '" data-endDate="' + lastMonthDayBBDD + '" style="display:inline-block;vertical-align:middle;"></span>';
        }
      } else if (model.level === 2) { //WEEK
        const firstWeekDay = model.weekInfoArray[1];
        const lastWeekDay = model.weekInfoArray[2];
        if (this.workflow._canTransformInPeriod(firstWeekDay, lastWeekDay, false)) {
          return '<span class="phx-icon phx-icon-transformer" data-startDate="' + firstWeekDay + '" data-endDate="' + lastWeekDay + '" style="display:inline-block;vertical-align:middle;"></span>';
        }
      } else if (model.level === 3) { //DAY
        const dateBBDD = model.dayInfoArray[2]; //YYYYMMDD
        if (this.workflow._canTransformInPeriod(dateBBDD, dateBBDD, false)) {
          return '<span class="phx-icon phx-icon-transformer" data-startDate="' + dateBBDD + '" data-endDate="' + dateBBDD + '" style="display:inline-block;vertical-align:middle;"></span>';
        }
      } else if (model.level === 4) { //Evenement
        const prevueModel = model.get("prevueColumn");
        if (!CWSTR.isBlank(prevueModel) && prevueModel.type === this.workflow.ACTIVITE && this.workflow._recapCanTransformAct(prevueModel, false)) {
          // Prevue Activity that can be transformed
          const dateBBDD = prevueModel.currentDate;
          resp = '<span class="phx-icon phx-icon-transformer" data-code="' + prevueModel.get("code") + '" data-startDate="' + dateBBDD + '" data-endDate="' + dateBBDD + '" style="display:inline-block;vertical-align:middle;margin-right:12px"></span>';
        }
        // use cellRenderers[TransformerM] to gain one column
        if (!CWSTR.isBlank(prevueModel) && prevueModel.type === this.workflow.ACTIVITE && this.workflow._recapCanTransformAct(prevueModel, true)) {
          // Prevue Activity that can be transformed
          resp += '<span class="phx-icon phx-icon-transformer-and-modify" data-id="' + model.id + '" style="display:inline-block;vertical-align:middle;"></span>';
        }
      }
      return resp;
    };
    /*
        table.cellRenderers["transformerM"] = (model: { [key: string]: any }): any => {
          const resp = "";
          if (model.level === 4) { //Evenement
            const prevueModel = model.get("prevueColumn");
            if (!CWSTR.isBlank(prevueModel) && prevueModel.type === this.workflow.ACTIVITE && this.workflow._recapCanTransformAct(prevueModel, true)) {
              // Prevue Activity that can be transformed
              return '<span class="phx-icon phx-icon-transformer-and-modify" data-id="' + model.id + '" style="display:inline-block;vertical-align:middle;"></span>';
            }
          }
          return resp;
        };
    */
    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')].cellRenderers["evenementD"] = (model: { [key: string]: any }): any => {
      const span = this._generateEvenementD(model, i18n.t('common:gererrecapitulatifs.vue_pd_HM'));
      return span;
    };

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')].cellRenderers["evenementD"] = (model: { [key: string]: any }): any => {
      const span = this._generateEvenementD(model, i18n.t('common:gererrecapitulatifs.vue_pd_HC'));
      return span;
    };

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HM')].cellRenderers["duree"] = (model: { [key: string]: any }): any => {
      let resp = "";
      if (model.level === 3 && !CWSTR.isBlank(model.summeDuresAct)) { //DAY
        if (model.summeDuresAct !== 0) {
          resp = '<div style="width:100%;display:inline-block;margin:auto;text-align:center"><b>( ' + CWTYPE.DURATION.HOUR_MINUTE.format(model.summeDuresAct) + ' )</b><div>';
        }
      }
      return resp;
    };

    table.vues[i18n.t('common:gererrecapitulatifs.vue_pd_HC')].cellRenderers["duree"] = (model: { [key: string]: any }): any => {
      let resp = "";
      if (model.level === 3 && !CWSTR.isBlank(model.summeDuresAct)) { //DAY
        if (model.summeDuresAct !== 0) {
          resp = '<div style="width:100%;display:inline-block;margin:auto;text-align:center"><b>( ' + CWTYPE.DURATION.HOUR_HUNDREDTH.format(this.workflow._toHourHundreth(model.summeDuresAct)) + ' )</b></div>';
        }
      }
      return resp;
    };

    table.cellRenderers["supprimerD"] = (model: { [key: string]: any }): any => {
      if (this.workflow._recapEtatCanBeSupressed("R") === true) {
        const checkedAttribute = model.evDSuppr === true ? "checked" : "";
        if (model.level === 1) { //MONTH
          const year = model.id.split(",")[0];
          const month = model.id.split(",")[1];
          const realiseeMonthColl = this.workflow._getRealiseeMonthEvenements(true, year, month);
          const evCanBeSupressed = _.find(realiseeMonthColl.models, (model: any) => { return !CWSTR.isBlank(model.evDSuppr); });
          if (realiseeMonthColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id, "MONTH", year, month, "supprimerDMonth", checkedAttribute);
            //return '<input data-type="MONTH" data-year="' + year + '" data-month="' + month + '" class="supprimerDMonth ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
        } else if (model.level === 2) { //WEEK
          const year = model.id.split(",")[0];
          const month = model.id.split(",")[1];
          const weekArray = model.id.split(",");
          const week = weekArray[2];
          const realiseeWeekColl = this.workflow._getRealiseeWeekEvenements(true, year, week);
          const evCanBeSupressed = _.find(realiseeWeekColl.models, (model: any) => { return !CWSTR.isBlank(model.evDSuppr); });
          if (realiseeWeekColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id, "WEEK", year, month, week, "supprimerDWeek", checkedAttribute);
            //return '<input data-type="WEEK" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" class="supprimerDWeek ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
          //if is delegue view in style delegation
        } else if (model.level === 3) { //DAY
          const year = model.id.split(",")[0];
          const month = model.id.split(",")[1];
          const week = model.id.split(",")[2];
          const dateBBDD = model.id.split(",")[3];
          const realiseeDayColl = this.workflow._getDayRealiseeEvenements(true, dateBBDD);
          const evCanBeSupressed = _.find(realiseeDayColl.models, (model: any) => { return !CWSTR.isBlank(model.evDSuppr); });
          if (realiseeDayColl.models.length > 0 && !CWSTR.isBlank(evCanBeSupressed)) {
            return this._inputBuilder2(model.id + ",D", "DAY", year, month, week, dateBBDD, "supprimerDDay", checkedAttribute);
            //return '<input data-type="DAY" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" data-date="' + dateBBDD + '" class="supprimerDDay ' + model.id + '" type="checkbox" ' + checkedAttribute + ' />';
          }
        } else if (model.level === 4) {
          const realiseeModel = model.get("realiseeColumn");
          if (!CWSTR.isBlank(realiseeModel) && !CWSTR.isBlank(model.evDSuppr) && realiseeModel.type === this.workflow.ACTIVITE) {
            const year = realiseeModel.year;
            const month = realiseeModel.month;
            const week = realiseeModel.week;
            const dateBBDD = realiseeModel.currentDate;
            let code = realiseeModel.get("code");
            if (code.indexOf("/") !== -1) {
              code = realiseeModel.get("code").replace('/', '_');
            }

            return this._inputBuilder2(code, "EVENEMENT", year, month, week, dateBBDD, "supprimerDLine", checkedAttribute);
            //return '<input data-type="EVENEMENT" data-year="' + year + '" data-month="' + month + '" data-week="' + week + '" data-date="' + dateBBDD + '" class="supprimerDLine ' + year + ',' + month + ',' + week + ',' + dateBBDD + ' ' + realiseeModel.get("code") + '" type="checkbox" ' + checkedAttribute + ' />';
          }
        }
      }
      return '';
    };

    table.setSortableColumns([], i18n.t('common:gererrecapitulatifs.vue_pd_HC'));
    table.setSortableColumns([], i18n.t('common:gererrecapitulatifs.vue_pd_HM'));

    tableModel.off("vue:change");
    tableModel.on("vue:change", this._vueChanged, this);
    table.setOmittedColumns(["supprimerP", "transformer", "transformerM", "supprimerD"], i18n.t('common:gererrecapitulatifs.vue_pd_HC'));
    table.setOmittedColumns(["supprimerP", "transformer", "transformerM", "supprimerD"], i18n.t('common:gererrecapitulatifs.vue_pd_HM'));
    return table;
  }

  _generateEvenementP(model: { [key: string]: any }, vueName: string): any {
    let htmltoReturn = $('<span></span>');

    if (model.level === 1) { //MONTH
      htmltoReturn = this._generateMonthHtml(model, vueName, "P");
    } else if (model.level === 2) { //WEEK
      htmltoReturn = this._generateWeekHtml(model, vueName, "P");
      //if is delegue view in style delegation
    } else if (model.level === 3) { //DAY
      const dateBBDD = model.id.split(",")[3];
      const journee = this.workflow._getJournee(dateBBDD);

      if (!CWSTR.isBlank(journee) && journee.get("repos") === true) {
        htmltoReturn.addClass("ui-phx-presence-repos").html(this._generateDayHtml(model, vueName, "P"));
      } else {
        htmltoReturn.html(this._generateDayHtml(model, vueName, "P"));
      }

    } else if (model.level === 4) {
      let text = "";
      const habRecap = this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";
      if (!CWHABILITATION.canUpdate(habRecap)) {
        htmltoReturn.css("text-decoration", "none");
      }

      if (!CWSTR.isBlank(model.get("prevueColumn")) && model.get("prevueColumn").type === this.workflow.ACTIVITE) { //ACTIVITE
        htmltoReturn.addClass("gerer-recapitulatif-activite-prevue-ev");
        htmltoReturn.attr("data-code", model.get("prevueColumn").get("code"));
        text = this.workflow._buildActiviteLine(model.get("prevueColumn"), vueName);
      } else if (!CWSTR.isBlank(model.get("prevueColumn")) && model.get("prevueColumn").type === this.workflow.ABSENCE) { //ABSENCE
        htmltoReturn.addClass("gerer-recapitulatif-absence-prevue-ev");
        htmltoReturn.attr("data-code", model.get("prevueColumn").get("code"));
        text = this.workflow._buildAbsenceLine(model.get("prevueColumn"), vueName);
      } else {
        const dateBBDD = model.currentDate;
        const journee = this.workflow._getJournee(dateBBDD);
        if (!CWSTR.isBlank(journee) && journee.get("repos") !== true) {
          text = i18n.t('common:gererrecapitulatifs.aucun_evenement');
        }
      }
      htmltoReturn.html('<span class="ui-phx-ihm-texte-application">' + text + '</span>');
    }
    return htmltoReturn;
  }

  _generateEvenementD(model: { [key: string]: any }, vueName: string): any {
    let htmltoReturn = $('<span class="test"></span>');

    if (model.level === 1) { //MONTH
      htmltoReturn = this._generateMonthHtml(model, vueName, "R");
    } else if (model.level === 2) { //WEEK
      htmltoReturn = this._generateWeekHtml(model, vueName, "R");
      //if is delegue view in style delegation
    } else if (model.level === 3) { //DAY
      htmltoReturn.html(this._generateDayHtml(model, vueName, "R"));
    } else if (model.level === 4) {
      let text = "";
      const habRecap = this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";
      if (!CWHABILITATION.canUpdate(habRecap)) {
        htmltoReturn.css("text-decoration", "none");
      }

      if (!CWSTR.isBlank(model.get("realiseeColumn")) && model.get("realiseeColumn").type === this.workflow.ACTIVITE) { //ACTIVITE
        htmltoReturn.addClass("gerer-recapitulatif-activite-realisee-ev");
        htmltoReturn.attr("data-code", model.get("realiseeColumn").get("code"));
        text = this.workflow._buildActiviteLine(model.get("realiseeColumn"), vueName);
      } else if (!CWSTR.isBlank(model.get("realiseeColumn")) && model.get("realiseeColumn").type === this.workflow.ABSENCE) { //ABSENCE
        htmltoReturn.addClass("gerer-recapitulatif-absence-realisee-ev");
        htmltoReturn.attr("data-code", model.get("realiseeColumn").get("code"));
        text = this.workflow._buildAbsenceLine(model.get("realiseeColumn"), vueName);
      } else {
        const dateBBDD = model.currentDate;
        const journee = this.workflow._getJournee(dateBBDD);
        if (!CWSTR.isBlank(journee) && journee.get("repos") !== true) {
          text = i18n.t('common:gererrecapitulatifs.aucun_evenement');
        }
      }
      htmltoReturn.html('<span class="ui-phx-ihm-texte-application">' + text + '</span>');
    }
    return htmltoReturn;
  }

  _generateMonthHtml(model: { [key: string]: any }, vueName: string, typeevenement: string): any {
    const firstMonthDay = new Date(model.monthInfoArray[1], model.monthInfoArray[0], 1); //function used to obtain the first month day
    const lastMonthDay = new Date(model.monthInfoArray[1], model.monthInfoArray[0] + 1, 0); //function used to obtain the first month day
    const firstMonthDayBBDD = CWTYPE.DATE.dateToStr(firstMonthDay, "YYYYMMDD");
    const lastMonthDayBBDD = CWTYPE.DATE.dateToStr(lastMonthDay, "YYYYMMDD");
    const htmltoReturn = $("<div data-startDate='" + firstMonthDayBBDD + "' data-endDate='" + lastMonthDayBBDD + "'></div>");

    if (this.workflow._recapEtatActCanBeInstantiated(typeevenement) === true) {
      htmltoReturn.attr({ "title": i18n.t('common:gererrecapitulatifs.date_title') });
    }
    if (typeevenement === "P") { //Prevue
      htmltoReturn.addClass("monthDataP");
      htmltoReturn.css("width", "auto");
      htmltoReturn.css("display", "inline-block");
      htmltoReturn.html(i18n.t('common:gererrecapitulatifs.month_' + model.monthInfoArray[0]) + "&nbsp;" + model.monthInfoArray[1]);
    } else { //Realisee
      htmltoReturn.addClass("monthDataR");
      htmltoReturn.css("width", "100%");
      htmltoReturn.css("height", "100%");
      htmltoReturn.css("display", "inline-block");
      htmltoReturn.html("");
    }
    return htmltoReturn;
  }

  _generateWeekHtml(model: { [key: string]: any }, vueName: string, typeevenement: string): any {
    const firstWeekDay = model.weekInfoArray[1];
    const lastWeekDay = model.weekInfoArray[2];
    const htmltoReturn = $('<div data-startDate="' + firstWeekDay + '" data-endDate="' + lastWeekDay + '"></div>');

    if (this.workflow._recapEtatActCanBeInstantiated(typeevenement) === true) {
      htmltoReturn.attr({ "title": i18n.t('common:gererrecapitulatifs.date_title') });
    }
    if (typeevenement === "P") { //Prevue
      htmltoReturn.addClass("weekDataP");
      htmltoReturn.css("width", "auto");
      htmltoReturn.css("display", "inline-block");
      htmltoReturn.html(i18n.t('common:gererrecapitulatifs.week') + "&nbsp;" + model.weekInfoArray[0]);
    } else { //Realisee
      htmltoReturn.addClass("weekDataR");
      htmltoReturn.css("width", "100%");
      htmltoReturn.css("height", "100%");
      htmltoReturn.css("display", "inline-block");
      htmltoReturn.html("");
    }
    return htmltoReturn;
  }

  _generateDayHtml(model: { [key: string]: any }, vueName: string, typeevenement: string): any {
    const dateBBDD = model.dayInfoArray[2]; //YYYYMMDD
    const htmltoReturn = $("<div data-startDate='" + dateBBDD + "' data-endDate='" + dateBBDD + "'></div>");

    if (this.workflow._recapEtatActCanBeInstantiated(typeevenement) === true) {
      htmltoReturn.attr({ "title": i18n.t('common:gererrecapitulatifs.date_title') });
    }
    if (typeevenement === "P") { //Prevue
      htmltoReturn.addClass("dayDataP");
      htmltoReturn.css("width", "auto");
      htmltoReturn.css("display", "inline-block");
      htmltoReturn.html("<b>" + i18n.t('common:gererrecapitulatifs.day_' + model.dayInfoArray[0]) + "&nbsp;" + model.dayInfoArray[1] + "</b>");
    } else { //Realisee
      htmltoReturn.addClass("dayDataR");
      htmltoReturn.css("width", "100%");
      htmltoReturn.css("height", "100%");
      htmltoReturn.css("display", "inline-block");
      htmltoReturn.html("");
    }
    return htmltoReturn;
  }

  /**
   * Manages transformation click for a period
   * Code is only informed for evenements
   */
  _manageTransform(event: JQueryEventObject): void {
    const currentTarget = event.currentTarget;
    let startDate = $(currentTarget).attr("data-startdate");
    let endDate = $(currentTarget).attr("data-enddate");
    const code = $(currentTarget).attr("data-code");
    const recap = this.workflow.headerModel.get("value");
    const datedebRecap = recap.get("datedeb");
    const datefinRecap = recap.get("datefin");

    //adjust startdate and enddate to recapitulatif's period
    startDate = startDate < datedebRecap ? datedebRecap : startDate;
    endDate = endDate > datefinRecap ? datefinRecap : endDate;
    this.workflow._transformAction(startDate, endDate, code);
  }

  _manageTransformAndModif(event: JQueryEventObject): void {
    const currentTarget = event.currentTarget;
    const modelID = $(currentTarget).attr("data-id");
    const model = this.tableColl.get(modelID);

    if (!CWSTR.isBlank(model) && !CWSTR.isBlank(model.get("prevueColumn"))) {
      const prevueActivite = model.get("prevueColumn");
      let context: any = null;

      this.workflow.valueToSelect.set("datedeb", prevueActivite.get("datedeb"));
      this.workflow.valueToSelect.set("datefin", prevueActivite.get("datefin"));
      context = this.workflow._transformGererActiviteContext(model.get("prevueColumn"));
      this.popupActiviteDialog = this._initPopupActiviteView(context);
      this.popupActiviteDialog.open();
      this.popupActiviteDialog.internalView.model.on("activiteChanged", this._activiteChanged, this);
    }
  }

  _manageInitierSaisieActivites(typologie: string, startDate: any, endDate: any, typeevenement: string): void {
    const context = this.workflow._instantierSaisieActiviteContext(typeevenement, typologie, startDate, endDate);

    this.popupActiviteDialog = this._initPopupActiviteView(context);
    this.popupActiviteDialog.open();
    this.popupActiviteDialog.internalView.model.on("activiteChanged", this._activiteChanged, this);
  }

  _consulterAbsence(event: JQueryEventObject): void {
    const className = event.currentTarget.className.split(" ")[0];
    const code = $(event.currentTarget).attr("data-code");
    const type = className === "gerer-recapitulatif-absence-prevue-ev" ? "P" : "R";
    const rowModel = _.find(this.table.model.coll.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        const evenement = type === "P" ? model.get("prevueColumn") : model.get("realiseeColumn");
        if (!CWSTR.isBlank(evenement)) {
          return code === evenement.get("code");
        }
      }
      return false;
    });
    const absence = type === "P" ? rowModel.get("prevueColumn") : rowModel.get("realiseeColumn");
    const context = this.workflow._consulterAbsencesContext(absence);

    this.popupAbsenceDialog = this._initPopupAbsenceView(context);
    this.popupAbsenceDialog.open();
  }

  _initPopupAbsenceView(context: { [key: string]: any }): CWVoletView {
    if (this.popupAbsenceDialog === undefined) {
      this.popupAbsenceDialog = new CWVoletView({
        view: CWGererAbsenceSouhaitView,
        viewData: {
          context: context
        }
      });
    } else {
      this.popupAbsenceDialog.viewData = { context: context };
    }
    this.popupAbsenceDialog.setHeight(500);
    this.popupAbsenceDialog.setWidth(600);
    return this.popupAbsenceDialog;
  }

  _manageActivity(event: JQueryEventObject): void {
    const className = event.currentTarget.className.split(" ")[0];
    const code = $(event.currentTarget).attr("data-code");
    const type = className === "gerer-recapitulatif-activite-prevue-ev" ? "P" : "R";
    const rowModel = _.find(this.table.model.coll.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        const evenement = type === "P" ? model.get("prevueColumn") : model.get("realiseeColumn");

        if (!CWSTR.isBlank(evenement)) {
          return code === evenement.get("code");
        }
      }
      return false;
    });
    let position = _.indexOf(this.table.model.coll.models, rowModel);
    const activite = type === "P" ? rowModel.get("prevueColumn") : rowModel.get("realiseeColumn");

    if (position > 0) {
      position--;
    }
    this.workflow.valueToSelect.set("value", this.table.model.coll.at(position));
    this.workflow.valueToSelect.set("datedeb", activite.get("datedeb"));
    this.workflow.valueToSelect.set("datefin", activite.get("datefin"));
    if (this.workflow._actCanBeConsulted(activite.get("typeevenement")) === true) {
      const context = this.workflow._consultGererActivitesContext(activite);

      this.popupActiviteDialog = this._initPopupActiviteView(context);
      this.popupActiviteDialog.open();
      this.popupActiviteDialog.internalView.model.on("activiteChanged", this._activiteChanged, this);
    }
  }

  _initPopupActiviteView(context: { [key: string]: any }): CWVoletView {
    if (_.isEmpty(this.popupActiviteDialog)) {
      this.popupActiviteDialog = new CWVoletView({
        view: CWACTIVITE.getActiviteViewByContext(context),
        viewData: {
          context: context
        }
      });
    } else {
      this.popupActiviteDialog.viewData = { context: context };
    }
    // set the size to auto. to avoid problems.
    this.popupActiviteDialog.setHeight("auto");
    this.popupActiviteDialog.setWidth(600);
    return this.popupActiviteDialog;
  }

  _activiteChanged(model: any, action: any): void {
    this.workflow._activiteChanged(model, action);
  }
}
