import * as _ from 'underscore';
import { CWSTR } from './cwStr';

/**
 * ajax headers util
 */
export class CWHEADERS {


  /**
   * Create 'habilitation' in the context header to the REST
   */
  static habilitationContext(onglet?: string, foncCour?: any, natGest?: string): { [key: string]: any } {
    onglet = onglet || "";
    foncCour = foncCour || "";
    natGest = natGest || "";
    // Support Objects as foncCour
    if (_.isObject(foncCour)) {
      foncCour = JSON.stringify(foncCour).replace(/"/g, '\\\"');//eslint-disable-line
    }
    return { "gfi-context": '{"onglet": "' + onglet + '", "fonc_cour": "' + foncCour + '", "nat_gest": "' + natGest + '"}' };
  }

  /**
   * Create 'population' in the context header to the REST
   */
  static populationContext(popId: string | number, popType: string, popCode?: number | string, popNat?: string, popDesc?: string): { [key: string]: any } {
    const populationContext = { "gfi-population": '{"id": "' + popId + '", "type": "' + popType + '"' };

    if (!CWSTR.isBlank(popCode)) {
      populationContext["gfi-population"] += ', "code": "' + popCode + '"';
    }
    if (!CWSTR.isBlank(popNat)) {
      populationContext["gfi-population"] += ', "nature": "' + popNat + '"';
    } else {
      populationContext["gfi-population"] += ', "nature": ""';
    }
    if (!CWSTR.isBlank(popDesc)) {
      populationContext["gfi-population"] += ', "desc": "' + popDesc + '"';
    }
    populationContext["gfi-population"] += '}';
    return populationContext;
  }

  /**
   * Create 'ecran' in the context header to the REST
   */
  static ecranContext(ecran: string): { [key: string]: any } {
    return { "gfi-ecran": '"' + ecran + '"' };
  }

  /**
   * Create 'version' in the context header to versioning the REST services.
   */
  static versionContext(version: string | { [key: string]: any }): { [key: string]: any } {
    return { "Accept": 'application/vnd.gfi-chronotime.v' + version + '+json' };
  }

  static simulationContext(code: string | number, datedeb: string | number, datefin: string | number, preel?: boolean, vuejourneedate?: string | number): { [key: string]: any } {
    const dateconsult = !CWSTR.isBlank(vuejourneedate) ? (', "dateconsult": "' + vuejourneedate + '"') : "";
    const lRtn = { "gfi-simulation": '{"id": "' + code + '", "dated": "' + datedeb + '", "datef": "' + datefin + '", "passreel": "' + (preel ? preel : false) + '"' + dateconsult + '}' };

    return lRtn;
  }
}
