import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for Brique 'Resultats'
 */
export class CWResultatsModel extends CWBaseModel {

  userId: string;
  codeBrique: number;

  defaults(): { [key: string]: any } {
    return {
      "libelle": "",
      "dateref": "",
      "cpt": [{
        "code": null,
        "libelle": "",
        "valeur": "",
        "typevalo": "",
        "datevalo": "",
      }]
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    if (options) {
      if (options.userId) {
        this.userId = options.userId;
      }
      if (options.codeBrique) {
        this.codeBrique = options.codeBrique;
      }
    }
    this.url = (): string => {
      return Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.userId + "," + this.codeBrique) + "/resultat";
    };
  }
}