import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Complement Absence Model
 */
export class CWOutilAbsenceComplementModel extends CWBaseModel {
  /**
   * Add this to have Outline
   *
   */

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/absence/composant/motifs";
    };
    this.url = (): string => {
      const base = _.result(this, 'urlRoot') || _.result(this.collection, 'url');

      return base + (base.charAt(base.length - 1) === '/' ? '' : '/') + encodeURIComponent(this.get("id")) + "/infocomp";
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      if (!CWSTR.isBlank(response.code)) {
        response.id = response.code;
      }
      if (String(response.code) === "0") {
        response.id = "0";
      }
    }
    return response;
  }
}
