import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLSuvietatCustomBar from '../customBar.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { i18n } from 'src/i18n.js';

/**
 * Custom Bar View
 */
export class CWCustomBarView extends Backbone.View {
  model: CWBaseModel;
  workflow: any;
  template: any;
  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "click a": "_manageLink"
    }, options.events);
    super(options);
    this.model = new CWBaseModel({
      stopDefil: false
    });
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.template = TPLSuvietatCustomBar;
  }

  render(): CWCustomBarView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));
    this.$el.css("height", "20px");
    return this;
  }

  _manageLink(): void {
    this.workflow.stream({ interval: this.workflow.paramRafEtat });
  }
}