import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { STR } from 'utils/str.js';

/**
 * Recherche Horaire Sur Pose activite
 */
export class CWCalculHorairesActiviteCollab extends CWBaseModel {

  activiteCode: any;

  defaults(): { [key: string]: any } {
    return {}
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.activiteCode = null;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/activite/element/" + encodeURIComponent(this.activiteCode) + "/horaires";
    }
  }

  validate(): { [key: string]: any } | null {
    const errors = {};

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    return response;
  }

  fetch(options?: { [key: string]: any }): JQueryXHR {
    options = options || {};
    options = _.extend(options, { merge: false });
    options.data = JSON.stringify(this.attributes);
    options.type = this.isNew() ? "POST" : "PUT";
    options.dataType = "json";
    options.contentType = 'application/json';
    options.processData = false;
    return super.fetch(options);
  }
}
