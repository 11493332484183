module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="input-group">\n    <input class="phx-chemin-input" type="text" value="" title="" />\n    <div class="input-group-append phx-cw-chemin-button cw-triggerClick" role="button" aria-label="'+
((__t=(i18n.t('common:ARIA_label.hierarchie')))==null?'':__t)+
'" tabindex=" 0">\n        <div class="input-group-text">\n            '+
((__t=(UTILS.getSVGIcon('hierarchie')))==null?'':__t)+
'\n        </div>\n    </div>\n</div>\n<div class="phx-chemin-wrap">\n    <div class="phx-chemin-selection"></div>\n</div>';
}
return __p;
};
