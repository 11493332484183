import _ from 'underscore';
import TPLGereractivites from '../cwGererActivites.tpl.html';
import TPLGereractivitesMultiLabelModeSaisie from '../cwMultiLabel-modeSaisie.tpl.html';
import TPLGereractivitesMultiLabelModeSaisieSaisieMasse from '../cwMultiLabel-modeSaisie-saisiemass-mode.tpl.html';
import TPLGereractivitesMultiLabelTypeEvenement from '../cwMultiLabel-typeEvenement.tpl.html';
import TPLGereractivitesMultiLabelTypeEvenementSaisieMasse from '../cwMultiLabel-typeEvenement-saisiemass-mode.tpl.html';
import TPLGereractivitesMultiLabelTypeSaisie from '../cwMultiLabel-typeSaisie.tpl.html';
import TPLGereractivitesMultiLabelTypeSaisieSaisieMasse from '../cwMultiLabel-typeSaisie-saisiemass-mode.tpl.html';
import TPLGererActivitesSaisieMasse from '../cwGereractivites-saisiemass-mode.tpl.html';
import { CWACTIVITE } from 'utils/cwActivite';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWFORMS } from 'utils/cwForms';
import { CWGererActivitesModel } from '../models/cwGererActivites.model';
import { CWGererActiviteView } from './cwGererActivite.view';
import { CWMSGS } from 'utils/cwMsgs';
import { CWMultiLabel } from 'core/views/cwMultiLabel';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';
import { SelecteurActiviteItemModel } from 'common/selecteuractivites/models/selecteurActiviteItem.model.js';
import { UTILS } from 'utils/utils.js';

/**
 * GERER ACTIVITE VIEW
 */
export class CWGererActiviteClassiqueView extends CWGererActiviteView {

  constructor(options?: ViewOptions<Model> | any) {
    options = options || {};
    super(options);
    this.workflow.cwGererActivitesView = this;
    if (this.context && this.context.ctxSaisieMasse) {
      // Saisie masse screen
      this.template = TPLGererActivitesSaisieMasse;
    } else {
      // default Orientation
      this.template = TPLGereractivites;
    }
  }

  /**
   * @implements CWGererActiviteView.typeofModel
   */
  typeofModel(): typeof CWGererActivitesModel {
    return CWGererActivitesModel
  }

  /**
   * @implements CWGererActiviteView._initModeConsultation
   */
  _initModeConsultation(activite: CWGererActivitesModel, callback: () => void): void {
    const gererActivite = this.model.get("value");

    gererActivite.off("invalid");
    if (this.context.ctxActionsPossibles.indexOf("Dupliquer") === -1 && this.context.ctxEcran === "mdepl") {
      this.$el.find(".c-form--fixedButtons").removeClass("c-form--fixedButtons");
      this.btnBar.setDroit("copy", false);
    }
    if (_.isEqual(this.btnBar.model.get("mode"), "R")) {
      this.btnBar.model.trigger("change:mode");
    }
    this.btnBar.model.set("mode", "R");
    this.btnBar.hideAllBtnBar();
    // Hide creer for imbrique visualization
    if (this.context.ctxModeRepresentation === "Imbrique" && (this.context.ctxEcran === "mactivites" || this.context.ctxEcran === "suivicollab")) {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.setDroit("new", false);
    }
    activite.setHabContext(this.model.getHabContext());
    activite.id = this.context.ctxIdentifiantEvt;
    activite.avecbesoin = this.context.ctxActiviteBesoin;
    activite.action = "element";
    activite.fetch({
      success: (fresh: any): void => {
        const afterCombosCallback = (): void => {
          this.actTypeSaisieOptionsColl.activite = fresh.get("activite");
          //Refresh activite default values for the id
          this.actDefaults.id = fresh.get("activite");
          this.actDefaults.hierid = fresh.get("hierid");
          this.actDefaults.itemid = fresh.get("straid");
          this.actDefaults.fetch({
            success: (freshDef: any): void => {
              // activité non gérée dans un récapitulatif
              if (fresh.get("recapitulatif") && CWSTR.isBlank(fresh.get("recapitulatif").code) && !_.isEmpty(this.$el)) {
                if ((fresh.get("typeevenement") === "P" && freshDef.get("soumiswkf_p")) || (fresh.get("typeevenement") === "R" && freshDef.get("soumiswkf_r"))) {
                  // activite gérée en workflow : commentaire
                  this.$el.find(".blockCommentaire").show();
                } else {
                  // activité non gérée en workflow : pas de commentaire
                  this.$el.find(".blockCommentaire").hide();
                }
              }
              gererActivite.set("carpers", freshDef.get("carpers"));
              fresh.set("indication", freshDef.get("indication"));
              this.setDefaultActiviteValues(gererActivite, freshDef, freshDef.get("valdef_impose"), true);
              this.actTypeSaisieOptionsColl.fetch({
                success: (): void => {
                  this.actModeSaisieOptionsColl.activite = fresh.get("activite");
                  this.actModeSaisieOptionsColl.fetch({
                    success: (): void => {
                      const afterEtatFinalCallback = (): void => {

                        fresh.set("dateDebD", fresh.get("datedeb"));
                        fresh.set("dateDebP", fresh.get("datedeb"));
                        fresh.set("dateDebR", fresh.get("datedeb"));
                        fresh.set("dateFinP", fresh.get("datefin"));
                        fresh.set("dateFinR", fresh.get("datefin"));
                        fresh.store();
                        this.model.set("value", fresh); // This will trigger a _mapToForm
                        this.model.store();
                        this.original = fresh.clone();
                        this._initWKFHisto();
                        this._showDateConsultee();
                        this._showIntitule();
                        this._showTypeStatusTitle();
                        this._showRecapitulatif();
                        this._manageCheckAtribAuto();
                        this._manageActionsPossibles();
                        this._manageHabilitation();
                        this._managePieceJointe();
                        // Evo 378 valider
                        this._manageButonValider(fresh, (): void => {
                          this._initSelecteurDates();
                          this._showIndicateurEvenamentMessage();
                          this._updateVisibiliteOrigine();
                          if (callback) {
                            callback();
                          }
                          //Mettre toujours à la fin
                          this._manageRestrictions(fresh, this.isEditable);
                          this.$el.find(".gereractivite-form-inputs").off("focusin");
                          if (!this.desactiveHabOuEtat) {
                            this.$el.find(".gereractivite-form-inputs").one("focusin", (event: JQuery.TriggeredEvent): void => {
                              this._checkModeSilentModifyActGest(fresh, (peutModifier: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }): void => {
                                this.infoErrorModify = {
                                  "isModifiable": peutModifier,
                                  "error": infoError,
                                  "response": infoResponse,
                                  "options": {}
                                };
                                //Pièces jointes
                                if (this.pieceJointe) {
                                  this.pieceJointe.setOmitAction(false);//Dans ce point, l'action doit être activée
                                }
                                this._actionPrevClickActivite();
                                if (!this.desactiveHabOuEtat) {
                                  if (this._showMessageModifyActGest()) {
                                    const lModel = this.model?.get("value");

                                    this._manageRestrictions(lModel, this.infoErrorModify?.isModifiable);
                                    this._MessageModifyActGest(event);
                                  } else {
                                    this.timer = window.setInterval(() => {
                                      if (!UTILS.checkHidder(this.context.ctxEcran)) {
                                        if (!(event.target.viewRef instanceof CWComboBoxView2)) {
                                          event.target.click();
                                        }
                                        if (this.timer) {
                                          window.clearInterval(this.timer);
                                          this.timer = null;
                                        }
                                      }
                                    }, 300);
                                  }
                                }
                              });
                            });
                          }
                        });
                      };

                      if (!CWSTR.isBlank(activite.get("recapitulatif")) && !CWSTR.isBlank(activite.get("recapitulatif").code)) {
                        //get valideur and valideur final
                        this._getEtatFinal(activite, afterEtatFinalCallback);
                      } else {
                        if (afterEtatFinalCallback) { afterEtatFinalCallback(); }
                      }
                    }
                  });
                }
              });
            }
          });
        };

        this._enableForm();
        // activite dans un récapitulatif : pas de commentaire
        if (fresh.get("recapitulatif") && !CWSTR.isBlank(fresh.get("recapitulatif").code) && !_.isEmpty(this.$el)) {
          this.$el.find(".blockCommentaire").hide();
        }
        this._updateCombosForActivite(fresh, afterCombosCallback);
      }
    });

    if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxEcran !== "mactivites") {
      this._setTitleImbrique();
    }
  }

  /**
   * @implements CWGererActiviteView._setTitlePopUp
   */
  _setTitlePopUp(headerNew?: boolean, evenement?: string): void {
    let title = "";
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);// Typologie
    const genre = !CWSTR.isBlank(typologie) ? typologie.get("genre") : null;
    let indic: { [key: string]: any } = null;
    const $div = $('<div>').addClass("titleAndEtiquette");
    const $span = $('<span class="typeActivite">');

    // Nouvelle
    if ((headerNew === true) || (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length >= 1 &&
      _.contains(this.context.ctxActionsPossibles, "Creer") && this.context.ctxModeInitialisation === "Ajout")) {
      if (genre === "F") {
        title += i18n.t('common:gereractivites.nouvelle_title_F') + " ";
      } else if (genre === "M") {
        title += i18n.t('common:gereractivites.nouvelle_title_M') + " ";
      }
    }
    indic = { "actprev": false, "actreal": false };
    if (!CWSTR.isBlank(evenement)) {
      if (evenement === "P") {
        indic.actprev = true;
      } else if (evenement === "R") {
        indic.actreal = true;
      } else {
        if (this.workflow.formModel && this.workflow.formModel.get("value") &&
          !CWSTR.isBlank(this.workflow.formModel.get("value").get("typeevenement"))) {
          if (this.workflow.formModel.get("value").get("typeevenement") === "P") {
            indic.actprev = true;
            indic.actreal = false;
          } else {
            indic.actprev = false;
            indic.actreal = true;
          }
        } else {
          const titleSelector = this.$el.find(".typeEvenement .phx-multiLabel label:eq(0)").text();
          const titleSelectorComponents = titleSelector.split(" ");
          const evenementString = titleSelectorComponents[titleSelectorComponents.length - 1];

          if (i18n.t('common:gereractivites.realisee').indexOf(evenementString) !== -1) {
            indic.actprev = false;
            indic.actreal = true;
          }
          if (i18n.t('common:gereractivites.prevue').indexOf(evenementString) !== -1) {
            indic.actprev = true;
            indic.actreal = false;
          }
        }
      }
    }
    title += CWACTIVITE.getTypologyLabel(this.context.ctxTypologieActivite, 1, indic, false, false);
    $span.html(CWSTR.toFirstUpperCase(title.toUpperCase()));
    if (this.context.ctxModeInitialisation === "Ajout") {
      $span.append($('<span>').addClass('gereractivites-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereractivites.creation_en_cours'))));
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      $span.append($('<span>').addClass('gereractivites-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(String(" - ").concat(i18n.t('common:gereractivites.modification_en_cours'))));
    }
    $div.append($span);
    $div.append($("<span>").addClass("c-titleHistoWkf"));
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.parent.isTitleHTML = true;
      this.parent._setTitle($div);
    } else {
      $(this.$el.parent().parent().parent()).find(".l-panelB-entete .typeActivite").remove();
      $(this.$el.parent().parent().parent()).find(".l-panelB-entete").append($span);
    }
  }


  /**
   * @implements CWGererActiviteView._showTypeStatusTitle
   */
  _showTypeStatusTitle(): void {
    let $layout: JQuery = null;

    if (this.context.ctxModeRepresentation === "pop-up") {
      $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');
    }
    if (!CWSTR.isBlank($layout)) {
      $layout.hide();
      this._paintWorkflowStatus($layout);
      this._paintGenereEtiquette($layout);
    }
  }

  /**
   * @implements CWGererActiviteView.render
   */
  render(): this {
    const json = {
      "i18n": i18n,
      "UTILS": UTILS,
      "deplacement": false
    };

    this.$el.empty();
    //Regular template
    this.$el.html(this.template(json));
    // MultiLabels
    this._renderDeroulantLabels();
    this._initCombos();
    // Ref. Activites
    if (!CWSTR.isBlank(this.context.ctxTypologieActivite)) {
      if (this.selectActPrevue) {
        this.$el.find(".refActprev").html(this.selectActPrevue.render().el);
      }
      if (this.selectActRealisee) {
        this.$el.find(".refActreal").html(this.selectActRealisee.render().el);
      }
    }
    // Combos
    this.$el.find(".comboUniteDeb").html(this.comboUniteDeb.render().el);
    this.$el.find(".comboUniteFin").html(this.comboUniteFin.render().el);
    this.$el.find(".comboUnites").html(this.comboUnites.render().el);
    this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
    this.$el.find(".gereractivites-detail").css("min-width", this.minWidth);
    // contruct valid dates
    if (this.model.get("value").get("modesaisie") === "PERIOC") {
      this._InitDatesPeriode();
    }
    // Dates
    CWFORMS.setDatepicker(this, ".dateDebD");
    CWFORMS.setDatepicker(this, ".dateDebP");
    CWFORMS.setDatepicker(this, ".dateFinP");
    CWFORMS.setDatepicker(this, ".dateDebR");
    CWFORMS.setDatepicker(this, ".dateFinR");
    //Flag all elements are renderd, so setContext can show/hide correctly
    this.isRendered = true;
    //Context
    if (this.context) {
      this.setContext(this.context);
    }
    //Dialog Position
    if (this.parent && this.parent.dialog) {
      this.parent.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
      this.parent.dialog.dialog("option", "width", "auto"); //670px
    }
    this.manageIndicationVisibility(this.model.get("value"));
    if (this.context && this.context.ctxSaisieMasse) {
      const buttons = this.$el.find("#activitecollab_btnbar").detach();

      this.$el.find("div.gereractivite-form-inputs div.gereractivites-infocomp").append(buttons);
    } else {
      $(document).find(".gerer_recap_activite .l-panelB-butomButtons").html(this.btnBar.render().el);
      this.btnBar.hideAllBtnBar();
    }
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.trigger("manageUnselected", false);
    }
    return this;
  }

  _renderDeroulantLabels(): void {
    let actprev, actreal;
    let json = null;
    let requiredValue = true;
    let tplTypeEvenemet = null;
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);
    let evenement = "";
    let evtLength: number = null;
    let avoidUnique = false;
    let tplTypeSaisie = null;
    const callBackFilterTypeSaisie = (resp: { [key: string]: any }): boolean => {
      let found = false;

      if (!CWSTR.isBlank(this.actTypeSaisieOptionsColl) && this.actTypeSaisieOptionsColl.models.length > 0) {
        _.each(this.actTypeSaisieOptionsColl.models, (typeSaisie: { [key: string]: any }): void => {
          if (this.context && this.context.ctxSaisieMasse === true) {
            if (typeSaisie.get("code") === resp.libelle) { found = true; }
          } else {
            if (typeSaisie.get("code") === resp.name) { found = true; }
          }
        });
      } else {
        found = true;
      }
      return found;
    };
    const callBackFilterTypeEvent = (resp: { [key: string]: any }): boolean => {
      let found = false;

      if (!CWSTR.isBlank(this.context.ctxTypeEvtGere) && this.context.ctxTypeEvtGere.length > 0) {
        _.each(this.context.ctxTypeEvtGere, (typeEvenement: string): void => {
          let lib = "";

          if (this.context && this.context.ctxSaisieMasse === true) {
            lib = resp.libelle[0];
          } else {
            lib = resp.name[0]
          }
          if (typeEvenement === lib) {
            found = true;
          }
        });
      } else {
        found = true;
      }
      return found;
    };
    const callBackFilterModeSaisie = (resp: { [key: string]: any }): boolean => {
      let found = false;

      if (!CWSTR.isBlank(this.actModeSaisieOptionsColl) && this.actModeSaisieOptionsColl.models.length > 0) {
        _.each(this.actModeSaisieOptionsColl.models, (modeSaisie: { [key: string]: any }): void => {
          if (this.context && this.context.ctxSaisieMasse === true) {
            if (modeSaisie.get("code") === resp.libelle) { found = true; }
          } else {
            if (modeSaisie.get("code") === resp.name) { found = true; }
          }
        });
      } else {
        found = true;
      }
      return found;
    };
    let tplModeSaisie = null;

    if (this.context && this.context.ctxSaisieMasse === true) {
      tplTypeEvenemet = TPLGereractivitesMultiLabelTypeEvenementSaisieMasse;
    } else {
      tplTypeEvenemet = TPLGereractivitesMultiLabelTypeEvenement;
    }
    if (typologie) {
      evenement = typologie.get("evenement");
      // we want evenement in uppercase (first letter).
      if (!CWSTR.isBlank(evenement)) {
        evenement = evenement.substr(0, 1).toUpperCase() + evenement.substr(1);
      }
    }
    if (typologie && typologie.get("genre") === "M") {
      actprev = i18n.t('common:gereractivites.actprevM', { "0": evenement });
      actreal = i18n.t('common:gereractivites.actrealM', { "0": evenement });
    } else {
      actprev = i18n.t('common:gereractivites.actprevF', { "0": evenement });
      actreal = i18n.t('common:gereractivites.actrealF', { "0": evenement });
    }
    json = { "i18n": i18n, "actprev": actprev, "actreal": actreal };
    if (this.context && this.context.ctxSaisieMasse) {
      json = { "i18n": i18n, "actprev": i18n.t('common:gereractivites.prevu'), "actreal": i18n.t('common:gereractivites.realise') };
      requiredValue = false;
    }
    evtLength = this.context.ctxTypeEvtGere.length;
    avoidUnique = evtLength === 1 ? true : false; // we dont show selector when there is only 1 value.
    this.typeEvenement = new CWMultiLabel({
      template: tplTypeEvenemet(json),
      required: requiredValue,
      avoidUniqueSelection: avoidUnique,
      titleSaisieMasse: "&nbsp;",
      name: "typeevenement",
      changeLabelCallback: this._changeMultiLabel,
      parentView: this,
      withinParent: true,
      saisieMasse: this.context.ctxSaisieMasse
    });
    this.$el.find(".typeEvenement").html(this.typeEvenement.render().el);
    json = { "i18n": i18n, "actprev": actprev, "actreal": actreal };
    if (this.context && this.context.ctxSaisieMasse) {
      tplTypeSaisie = TPLGereractivitesMultiLabelTypeSaisieSaisieMasse;
    } else {
      tplTypeSaisie = TPLGereractivitesMultiLabelTypeSaisie;
    }
    this.typeSaisie = new CWMultiLabel({
      template: tplTypeSaisie(json),
      required: requiredValue,
      name: "typesaisie",
      titleSaisieMasse: i18n.t('common:gereractivites.periodicite'),
      changeLabelCallback: this._changeMultiLabel,
      parentView: this,
      // width: 95,
      saisieMasse: this.context.ctxSaisieMasse
    });
    this.$el.find(".typeSaisie").html(this.typeSaisie.render().el);
    //Filter typeSaisie
    this.typeSaisie.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, callBackFilterTypeSaisie);
    });
    //Filter typeEvenement
    this.typeEvenement.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, callBackFilterTypeEvent);
    });
    if (this.context && this.context.ctxSaisieMasse === true) {
      tplModeSaisie = TPLGereractivitesMultiLabelModeSaisieSaisieMasse;
    } else {
      tplModeSaisie = TPLGereractivitesMultiLabelModeSaisie;
    }
    this.modeSaisie = new CWMultiLabel({
      template: tplModeSaisie(json),
      required: requiredValue,
      name: "modesaisie",
      titleSaisieMasse: i18n.t('common:gereractivites.modesaisie'),
      changeLabelCallback: this._changeMultiLabel,
      parentView: this,
      saisieMasse: this.context.ctxSaisieMasse
    });
    this.$el.find(".modeSaisie").html(this.modeSaisie.render().el);
    //Filter modeSaisie
    this.modeSaisie.setFilter((response: { [key: string]: any }): { [key: string]: any } => {
      //return only unités checked
      return _.filter(response, callBackFilterModeSaisie);
    });
  }

  /**
   * Map the Model to the form, perform some data managements befor call
   * the FormView default MapModelToForm function.
   * @implements CWGererActiviteView._mapToForm
   */
  _mapToForm(): void {
    let buttons = null;

    if (this.$el) {
      const gererActivite = this.model.get("value");

      if (CWSTR.isBlank(gererActivite)) {
        this.$el.find(".gereractivite-form-inputs").hide();
        this._hideGrilleHoraireMessage();
      } else {
        const $fieldset = this.$el.find(".gereractivite-content");
        const renderers = {};//Renderers
        let collabInput;

        this.$el.find(".gereractivite-form-inputs").show();
        gererActivite.setHabContext(this.model.getHabContext());
        if (gererActivite.get("grillehoraire") === true) {
          this._showGrilleHoraireMessage();
        } else {
          this._hideGrilleHoraireMessage();
        }
        // Error management
        gererActivite.off("invalid");
        gererActivite.on("invalid", this._showValidationErrors, this);
        // Clear current validation erros
        this._cleanValidationErrors();
        // Visibility
        this._panelStateRender(/*gererActivite*/);
        // Manage legend
        this._manageLegend();
        if (this.context && this.context.ctxSaisieMasse) {
          buttons = this.$el.find("#activitecollab_btnbar").detach();
        }
        //Dynamic Data
        this._loadInformationsComplementairesDynamic(this._getModel().get("carpers"));
        if (this.context && this.context.ctxSaisieMasse) {
          this.$el.find("div.gereractivite-form-inputs div.gereractivites-infocomp").append(buttons);
          this._setHeureDeb();
          this._setHeureFin();
        }
        //Initialize proghoraire checks
        this._initializeCheckAtrinbAuto();
        // Fix to display libelle and matricule in referentiel collaborateurs
        if (this.context.ctxSaisieMasse === true && !CWSTR.isBlank(gererActivite.get("matricule"))) {
          collabInput = this.$el.find('.cmbCollaborateurs input').val();
        }
        // Map model to the form
        // 4º parameteravoids linking labels, manage manually the datepickers problem
        // Fix to display libelle and matricule in referentiel collaborateurs
        if (this.context.ctxSaisieMasse === true) {
          this.$el.find('.cmbCollaborateurs input').val(collabInput);
          // we call to set the height to the table if the activite has many characteristics
          this.trigger("setTableScroll");
        }
        // Manage Delorants Labels
        this._manageMultiLabels();
        //Manual Label Linking
        CWFORMS.linkLabelsToInputs(this.$el.find(".cmbCollaborateurs"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeEvenement"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".modeSaisie"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".gereractivites-infocomp"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='D']"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='P']"));
        CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='R']"));
        //Set reliquat	and presence
        this.modeSaisie.$el.find(".horspresence").prop("checked", true);
        this.modeSaisie.$el.find(".presence").prop("checked", true);
        // Calculate Soit
        this._manageSoitValue(gererActivite.get("heuredeb"), gererActivite.get("heurefin"));
        // Map the Activite Referentiel
        if (gererActivite.get("activite")) {
          const item = new SelecteurActiviteItemModel({
            "code": gererActivite.get("activite"),
            "libelle": gererActivite.get("libelle"),
            "hierid": gererActivite.get("hierid"),
            "structid": gererActivite.get("structid"),
            "itemid": gererActivite.get("straid"),
            "datedeb": gererActivite.get("datedeb"),
            "datefin": gererActivite.get("datefin"),
            "parentdomcode": CWSTR.getElValue(gererActivite, "domaine.code")
          });

          if (gererActivite.get("typeevenement") === "P") {
            // Prevue
            if (this.selectActPrevue) {
              this.selectActPrevue.addValue(item);
            }
          } else {
            // Realisee
            if (this.selectActRealisee) {
              this.selectActRealisee.addValue(item);
            }
          }
        } else {
          if (gererActivite.get("typeevenement") === "P") {
            // Prevue
            if (this.selectActPrevue) {
              this.selectActPrevue.clearSelection();
              this.selectActPrevue.clean();
            }
          } else {
            // Realisee
            if (this.selectActRealisee) {
              this.selectActRealisee.clearSelection();
              this.selectActRealisee.clean();
            }
          }
        }
        this._manageUniteDeb(CWSTR.getElValue(gererActivite, "modesai_periodeb.code"));
        this._manageUniteFin(CWSTR.getElValue(gererActivite, "modesai_periofin.code"));
        this._manageHorsPresence(CWSTR.getElValue(gererActivite, "modesai_periodeb.code"));
        this._manageHorsPresence(CWSTR.getElValue(gererActivite, "modesai_periofin.code"));
        //Focus management on deplacement is different than activities
        if (gererActivite.isNew() && this.context.ctxSaisieMasse === false) {
          if (gererActivite.get("typeevenement") === "P") {
            $fieldset.find(".actprev").focus();
          } else {
            $fieldset.find(".actreal").focus();
          }
        }
        this._manageModeSaisie();
        this._enableModeSaisie();
        //Display information label about horspresence
        this._manageLabelPresenceHorsPresence(gererActivite.get("presence"), gererActivite.get("horspresence"), gererActivite.get("typehorspresence"));
        if (this.context.ctxModeRepresentation === "pop-up") {
          this._setTitlePopUp(null, this.context.ctxTypeEvtGere);
        }
        this._enableValuesActivite();
        if (this.actDefaults && typeof this.actDefaults.get("valdef_impose") === "boolean" && this.actDefaults.get("valdef_impose") === true) {
          this._blockDefaultValues(this.defaultMaps);
        }
        this._mapModelToForm($fieldset, gererActivite, renderers, true);
      }
      if (this.actModeSaisieOptionsColl.length === 1) {
        this.modeSaisie.setMenuAccess(false);
      }
      if (this.actTypeSaisieOptionsColl.length === 1) {
        this.typeSaisie.setMenuAccess(false);
      }
      if (!CWSTR.isBlank(gererActivite.get("activite"))) {
        this.manageIndicationVisibility(gererActivite);
      }
    }
  }

  /**
   * The View Custom change event, peform logics when the inputs change his value and
   * performs put the new values on the current model with the FormView _change function.
   * @implements CWGererActiviteView._changeView
   */
  _changeView(event: JQueryEventObject, data: any): void {
    const gererActivite = this.model.get("value");
    const className = event.target.className.split(" ")[0];
    let errors = 0;
    let lTypeEvt;
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);

    this._displaySubtitle();
    // Avoid default Change Logic, apply custom change logic
    switch (className) {
      case "unitedeb.code":
        this._change(event, data);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        this._manageCheckAtribAuto();
        if (this.context.ctxActiviteBesoin === true) {
          this.$el.find(".attribAutoContainer").removeAttr("d-none")
          if (this.preferenceHoraireAuto === true) {
            this.$el.find(".attribAutoContainer").find(".addhoraireauto").prop("checked", true);
          } else {
            this.$el.find(".attribAutoContainer").find(".addhoraireauto").prop("checked", false);
          }
        }
        break;
      case "commentaire":
        this._change(event, data);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        break;
      case "actprev":
      case "actreal":
        //Do nothing selecteuractivite manages this changes alone
        break;
      case "dtdebinter":
        // FormView Default Change call
        this._change(event, data);
        break;
      case "hdebinter":
      case "hfininter":
        // FormView Default Change call
        this._change(event, data);
        break;
      case "dateDebD":
      case "dateDebP":
      case "dateDebR":
      case "datedeb":
        if (className !== "datedeb") {
          this._getModel().set({ "datedeb": CWTYPE.DATE.parse((event.target as any).value).val }, { silent: true });
        }
        this._change(event, data);
        // FormView Default Change call
        if (typologie.get('typeGestionActivite') === "REPOBLIG" && gererActivite.get("typesaisie") === "P" && className === "dateDebP") {
          const dateAux1 = CWTYPE.DATE.strToDate(CWTYPE.DATE.parse((event.target as any).value).val);

          if (!CWSTR.isBlank(dateAux1)) {
            const dateFin = CWTYPE.DATE.dateToStr(UTILS.addDays(dateAux1, 1), CWTYPE.DATE.DEFAULT_MASK_BBDD);

            this._getModel().set({ "datefin": dateFin }, { silent: true });
            this._getModel().set({ "dateFinP": dateFin }, { silent: true });
            this._mapToForm();
            this._controlError(true, gererActivite, className, (event.target as any), [{ "dateDebP": CWTYPE.DATE.parse((event.target as any).value) }]);
          }
        }
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        break;
      case "dateFinP":
      case "dateFinR":
      case "datefin":
        if (className !== "datefin") {
          this._getModel().set({ "datefin": CWTYPE.DATE.parse((event.target as any).value).val }, { silent: true });
        }
        this._change(event, data);
        break;
      case "typedeplac.code":
      case "happel":
      case "cptdeplac.code":
        // FormView Default Change call
        this._change(event, data);
        break;
      case "typeevenement":
        lTypeEvt = data === i18n.t('common:gereractivites.realise') ? "R" : "P";
        this._updateHabilitationForChangeTypeEvt(this._getHabilitationDuContext(lTypeEvt, "Gestion"));
        break;
      case "matricule":
        this._change(event, data);
        if (!CWSTR.isBlank(this.context) && this.context.ctxEcran === "saisiemass" && !CWSTR.isBlank(gererActivite.validationError) && CWSTR.isBlank(gererActivite.validationError.errors.matricule) && Object.keys(gererActivite.validationError.errors).length <= 1) {
          CWFORMS.cleanErrors($(this.el));
        }
        this.context.ctxCollab = this._getModel().get("matricule");
        this.selectActPrevue.modifyContext(this.context);
        this.selectActRealisee.modifyContext(this.context);
        break;
      case "heuredeb":
        this._change(event, data);
        if (gererActivite.get("modesaisie") === "PERIOC") {
          if (!_.isEmpty(gererActivite.validationError) && !_.isEmpty(gererActivite.validationError.errors.heuredeb) && !CWSTR.isBlank(gererActivite.validationError.errors.heuredeb) && Object.keys(gererActivite.validationError.errors).length <= 1) {
            CWFORMS.cleanErrors($(this.el));
          }
        } else {
          errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        }
        break;
      default:
        // FormView Default Change call
        this._change(event, data);
        errors = CWFORMS.countErrors(gererActivite.validationError.errors);
        break;
    }
    if (typologie.get('typeGestionActivite') === "REPOBLIG") {
      this._getModel().set('gentrt', false);
    }
    if (errors > 0) {
      this._cleanValidationErrors();
      const errorList = gererActivite.validationError.errors;

      if (this.context.ctxTypeActivite === "REPOBLIG" || typologie.get('typeGestionActivite') === "REPOBLIG" && this.context.ctxModeInitialisation === "Ajout") {
        for (const key of Object.keys(errorList)) {
          if (key !== className) {
            delete errorList[key]
          }
        }
      }
      if (!_.isEmpty(errorList)) {
        CWFORMS.showErrors(this.$el, errorList);
      }
      switch (className) {
        case "modesai_periodeb.code":
          this._manageUniteDeb(data);
          this._manageHorsPresence(data);
          break;
        case "modesai_periofin.code":
          this._manageUniteFin(data);
          this._manageHorsPresence(data);
          break;
      }
    } else {
      let datedeb, datefin;

      // Addition default change Logic
      switch (className) {
        case "modesai_periodeb.code":
          this._manageUniteDeb(data);
          this._manageHorsPresence(data);
          break;
        case "modesai_periofin.code":
          this._manageUniteFin(data);
          this._manageHorsPresence(data);
          break;
        case "dateDebD":
        case "dateDebP":
        case "dateDebR":
        case "datedeb":
          if (gererActivite.get("datedeb") === "D") {
            datedeb = gererActivite.get("datedeb");
          }
          datedeb = gererActivite.get("datedeb");
          datefin = gererActivite.get("typesaisie") === "D" ? gererActivite.get("datedeb") : gererActivite.get("datefin");
          if (((gererActivite.get("typesaisie") === "P") || (this.context && this.context.ctxSaisieMasse === true && gererActivite.get("typesaisie") === "R")) && datefin <= datedeb) { // if the start date is changed, update end date
            const dateAux1 = CWTYPE.DATE.strToDate(datedeb);
            const fieldset = this.$el.find(".multiLabelTypeSaisie_form");

            if (gererActivite.get("typesaisie") === "P") {
              if (!CWSTR.isBlank(dateAux1)) {
                UTILS.addDays(dateAux1, 1);
              }
            }
            datefin = CWTYPE.DATE.dateToStr(dateAux1, CWTYPE.DATE.DEFAULT_MASK_BBDD);
            gererActivite.set("datefin", datefin);
            this.$el.find(".datefin").val(CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode("DATE")));
            this._cleanValidationErrors();
            delete gererActivite.validationError.errors.datefin;
            this._setHeureDeb();
            this._setHeureFin();
            this._mapModelToForm(fieldset, this._getModel());
          }
          gererActivite.set("datefin", datefin);
          if (this.selectActPrevue) {
            this.selectActPrevue.setCollDates(datedeb, datefin);
          }
          if (this.selectActRealisee) {
            this.selectActRealisee.setCollDates(datedeb, datefin);
          }

          if (CWSTR.isBlank(this._getModel().validationError) || CWSTR.isBlank(this._getModel().validationError.errors[className])) {
            this._updateCombosForActivite(gererActivite);
          }
          if (UTILS.isIE()) {
            $(event.target.parentElement).find(".ui-datepicker-trigger").focus();
          }
          break;
        case "dateFinP":
        case "dateFinR":
        case "datefin":
          datedeb = gererActivite.get("datedeb");
          datefin = gererActivite.get("typesaisie") === "D" ? gererActivite.get("datedeb") : gererActivite.get("datefin");
          if (this.selectActPrevue) {
            this.selectActPrevue.setCollDates(datedeb, datefin);
          }
          if (this.selectActRealisee) {
            this.selectActRealisee.setCollDates(datedeb, datefin);
          }
          break;
        case "heuredeb":
        case "heurefin":
          this._manageSoitValue(gererActivite.get("heuredeb"), gererActivite.get("heurefin"));
        // falls through
        //Not default
      }
    }
  }

  /**
   * TODO revisar si esta lógica pertenece a la vista o al workflow
   * Performs a Revert on the From.
   * Function Called when Press the revert Btn.
   * @implements CWGererActiviteView.revertView
   */
  revertView(): void {
    if (this.context && this.context.ctxModeRepresentation === "pop-up" && (this.context.ctxModeInitialisation === "Ajout" || this.context.ctxModeInitialisation === "Transformation")) {
      this.parent.close();
    } else {
      this.hasBeenFocused = false;
      if (this.context.ctxEcran !== "saisiemass") {
        this.context.ctxModeInitialisation = "Consultation";
      }
      this._restoreActivite();
      this._restoreInitialState();
      if (this.context && this.context.ctxModeRepresentation !== "pop-up") {
        this._setTitleImbrique();
      } else {
        this._setTitlePopUp();
      }
      this._initSubtitle(false);
      this._showTypeStatusTitle();
      this.model.trigger("activiteReverted", this.model.get("value"), this.context.ctxModeInitialisation);
      if (this.context.ctxActiviteBesoin === true) {
        this.$el.find(".attribAutoContainer").addClass("d-none");
      }
    }
  }

  /**
   * 
   * @param modeDeclarer 
   * @implements CWGererActiviteView.saveActivite
   */
  saveActivite(modeDeclarer: boolean): void {
    //debut declaration-----------------------------------------------------------------------
    const target = this.model.get("value");
    const errorCallback = (model: CWBaseModel): void => {
      if (!_.isEmpty(model.validationError) && !_.isEmpty(model.validationError.errors) && model.validationError.errors.datedeb) {
        if (target.get("typesaisie") === "D") {
          model.validationError.errors.dateDebD = model.validationError.errors.datedeb;
        } else if (target.get("typesaisie") === "P") {
          model.validationError.errors.dateDebP = model.validationError.errors.datedeb;
        } else {
          model.validationError.errors.dateDebR = model.validationError.errors.datedeb;
        }
        delete model.validationError.errors.datedeb;
        this._showValidationErrors(model, model.validationError);
      } else if ((this.typeSaisie.$el.find("label").first().text() === i18n.t('common:gereractivites.date') || (this.context &&
        this.context.ctxSaisieMasse === true && this.typeSaisie.$el.find("label").first().text() === i18n.t('common:gereractivites.periodicite'))) && model.validationError && model.validationError.errors &&
        !model.validationError.errors.datedeb) {
        if (model.validationError.errors.datefin) {
          if (target.get("typesaisie") === "D") {
            model.validationError.errors.dateDebD = model.validationError.errors.datefin;
          } else if (target.get("typesaisie") === "P") {
            model.validationError.errors.dateDebP = model.validationError.errors.datefin;
          } else {
            model.validationError.errors.dateDebR = model.validationError.errors.datefin;
          }
          delete model.validationError.errors.datefin;
          this._showValidationErrors(model, model.validationError);
        }
      }
      //Pour activités qui ne sont pas déplacs
      this.errorPetAntSaveDelete = true;
    };
    const saveCallback = (fresh: any): void => {
      let saveAction = "update";

      //nettoyer les erreurs de l'écran
      this._cleanValidationErrors();
      if (modeDeclarer === true) {
        saveAction = "transformation";
      }
      if (target.isNew() || target.creation === true) {
        saveAction = "create";
        target.creation = undefined;
      }
      this.savePiecesJointes(fresh, (): void => {
        if (this.context.ctxModeRepresentation === "pop-up") {
          this.parent.close();
        }
        if (this.context && this.context.ctxSaisieMasse === true) {
          this.collabBackup = this.referentielCollabPopu.model.toJSON();
          this.selectCollab();
        }
        if (this._isOutOfDateRange(target) === true) {
          if (this._setReloadPlanningFlag(target)) {
            if (this.plworkflow && _.isBoolean(this.plworkflow.affRefPrets)) {
              //A preference stored so we don't  have to show dialog, only act as stored
              if (this.plworkflow && this.plworkflow.affRefPrets === true) {
                this.plworkflow.trigger("refresh:all", { resetPlanning: true });
              } else {
                this.model.trigger("activiteChanged", fresh, saveAction);
                if (this.context.ctxActiviteBesoin === true) {
                  this.plworkflow.ctxChangerPlanningBesoin = true;
                  this.plworkflow.ctxInitPlanActivite = false;
                  this.refreshSelectedPeriodeOnPlanning(fresh);
                  this.plworkflow.totalsModel.trigger("change");
                  this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
                }
              }
              //If affRefPrets=false, don't refresh all planning
            } else {
              //No preference stored so we have to shoe dialog
              const callback = (): void => {
                this.model.trigger("activiteChanged", fresh, saveAction);
                if (this.context.ctxActiviteBesoin === true) {
                  this.plworkflow.ctxChangerPlanningBesoin = true;
                  this.plworkflow.ctxInitPlanActivite = false;
                  this.refreshSelectedPeriodeOnPlanning(fresh);
                  this.plworkflow.totalsModel.trigger("change");
                  this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
                }
              };

              this._openRefreshPretsDialog(null, callback);
            }
          } else {
            this.model.trigger("activiteChanged", fresh, saveAction);
            if (this.context.ctxActiviteBesoin === true) {
              this.plworkflow.ctxChangerPlanningBesoin = true;
              this.plworkflow.ctxInitPlanActivite = false;
              this.refreshSelectedPeriodeOnPlanning(fresh);
              this.plworkflow.totalsModel.trigger("change");
              this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
            }
          }
        } else {
          this.model.trigger("activiteChanged", fresh, saveAction);
          if (this.context.ctxActiviteBesoin === true) {
            this.plworkflow.ctxChangerPlanningBesoin = true;
            this.plworkflow.ctxInitPlanActivite = false;
            this.refreshSelectedPeriodeOnPlanning(fresh);
            this.plworkflow.totalsModel.trigger("change");
            this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
          }
        }
        fresh.store();
      });
    };
    const lHabGestion = this._getHabilitationDuContext(target.get("typeevenement"), "Gestion");
    //fin declaration-----------------------------------------------------------------------

    target.set("matricule", this.context.ctxGestionCollab.matricule || this.context.ctxGestionCollab.matric_aux);
    target.action = "element";
    target.updateHabContext({ foncCour: lHabGestion });
    target.modeDeclarer = modeDeclarer;
    if (modeDeclarer === true) {
      // Dans le cas d'une transformation sur un recap, on ne prends pas l'habilitation du recap, mais celle de l'activite
      if (!CWSTR.isBlank(target.get("recapitulatif")) && !CWSTR.isBlank(target.get("recapitulatif").code) && !(this.context.ctxModeInitialisation === "Transformation") && !(this.context.ctxModeInitialisation === "Declaration")) {
        target.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestionRecap, natGest: "A" });
      } else {
        target.updateHabContext({ foncCour: lHabGestion, natGest: "A" });
      }
    }
    this._checkHoraireActivite(target, (): void => {
      if (_.isBoolean(target.get("addhoraireauto")) && !target.get("addhoraireauto") && !_.isEmpty(target.get("horaires"))) {
        target.unset("horaires");//on ne doit pas envoyer l'information des horaires si l'attribution d'horaire est désactivée
      }
      if (modeDeclarer === false && this.isPrevue === true && (target.get("typetransfo") === "C" || target.get("typetransfo") === "P")) {
        CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1343'), (result: string): void => {
          if (result === "Y") {
            target.save(null, {
              success: saveCallback
            });
          } else {
            this.revertView();
          }
        }, null, null, null, this.workflow.$appendTo);
      } else {
        if (target.isNew()) {
          target.creation = true;
        } else {
          target.creation = undefined;
        }
        this._manageTypeSaisieDate(target);
        target.save(null, {
          success: saveCallback,
          error: errorCallback
        });
      }
    });
  }

  private refreshSelectedPeriodeOnPlanning(fresh: any): void {
    const arrayCellIds = [];
    const periodes = fresh.get('periodes');
    const nbPeriodes = periodes ? periodes.length : 0;

    arrayCellIds.push(fresh.get("matricule") + "," + fresh.get("datedeb"));
    if (nbPeriodes > 1) {
      for (let index = 0; index < nbPeriodes; index++) {
        const periode = periodes[index];

        if (periode.datedeb === periode.datefin) {
          arrayCellIds.push(fresh.get("matricule") + "," + periode.datedeb);
        } else {
          const listDate = CWTYPE.DATE.getFullPeriode(periode.datedeb, periode.datefin);

          listDate.forEach((value) => {
            arrayCellIds.push(fresh.get("matricule") + "," + value);
          });
        }
        this.plworkflow.trigger("refresh:selection", periode.datedeb, periode.datefin, null, null, null, true, arrayCellIds, true);
      }
      this.plworkflow.trigger("refresh:selection", fresh.get("datedeb"), fresh.get("datefin"), null, null, null, true, arrayCellIds, true);
    } else if (nbPeriodes === 1) {
      let dateDebPeriode = fresh.get("datedeb");
      let dateFinPeriode = fresh.get("datefin");

      dateDebPeriode = dateDebPeriode > periodes[0].datedeb ? periodes[0].datedeb : dateDebPeriode;
      dateFinPeriode = dateFinPeriode > periodes[0].datefin ? dateFinPeriode : periodes[0].datefin;
      //on doit rafraichir le tableau de service et le planning d'activités
      if (periodes[0].datedeb === periodes[0].datefin) {
        arrayCellIds.push(fresh.get("matricule") + "," + periodes[0].datedeb);
      } else {
        const listDate = CWTYPE.DATE.getFullPeriode(periodes[0].datedeb, periodes[0].datefin);

        listDate.forEach((value) => {
          arrayCellIds.push(fresh.get("matricule") + "," + value);
        });
      }
      this.plworkflow.trigger("refresh:selection", dateDebPeriode, dateFinPeriode, null, null, null, true, arrayCellIds, true);
    } else {
      const dateDebPeriode = fresh.get("dateDebD") > fresh.get("datedeb") ? fresh.get("datedeb") : fresh.get("dateDebD");
      const dateFinPeriode = fresh.get("dateDebD") > fresh.get("datefin") ? fresh.get("dateDebD") : fresh.get("datefin");

      this.plworkflow.trigger("refresh:selection", dateDebPeriode, dateFinPeriode, null, null, null, true, arrayCellIds, true);
    }
  }

  /**
   * @implements CWGererActiviteView.newActivite
   */
  newActivite(): void {
    let target = null;
    const lHabGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");

    this.stopMessagePrevClickActivite();
    this.notCheckCombos = true;
    if (this.context && this.context.ctxSaisieMasse === true) {
      target = new CWGererActivitesModel(null, { "matricule": null, infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(this.context)) ? this.context : {} });
    } else {
      target = new CWGererActivitesModel(null, { infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(this.context)) ? this.context : {} });
    }
    this.model.updateHabContext({ foncCour: lHabGestion, natGest: "A" });
    if (!CWSTR.isBlank(this.actDefaults)) {
      target.set("carpers", this.actDefaults.get("carpers"));
    }
    target.set("matricule", this.context.ctxGestionCollab.matricule);
    this._enableForm();
    if (this.context.ctxEcran === "mdepl") {
      this.$el.find(".c-form--fixedButtons").removeClass("c-form--fixedButtons");
      if (CWSTR.isBlank(this.context.ctxTypologieActivite)) {
        this.context.ctxTypologieActivite = this.collTypoDeplac.models[0].attributes.get("code");
      }
      this._renderDeroulantLabels();
      this._initCombos();
      if (this.selectActPrevue) {
        this.$el.find(".refActprev").html(this.selectActPrevue.render().el);
      }
      if (this.selectActRealisee) {
        this.$el.find(".refActreal").html(this.selectActRealisee.render().el);
      }
      this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
    } else {
      this.model.trigger("mode:create");
    }
    this.model.set("value", target);
    this.model.trigger("form:edited", true);

    if (this.context.ctxEcran !== "saisiemass") {
      this.context.ctxModeInitialisation = "Ajout";
    }
    this._setTitlePopUp();
    this._initSubtitle(true);
    this._showTypeStatusTitle();
    this._hideTypeStatusTitle();
    this._managePieceJointe();
    this._showDateConsultee();
    this._showIndicateurEvenamentMessage();
    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "A" });
  }
}