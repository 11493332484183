module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc" data-bloc-name="PRE" data-bloc-label="'+
((__t=(actprev))==null?'':__t)+
'">\n  <label for="actprev" class="phx-inline-label" style="display: none;">&nbsp;</label>\n  <div class="refActprev form-group"></div>\n</div>\n<div class="cw-multiLabel-bloc" data-bloc-name="REA" data-bloc-label="'+
((__t=(actreal))==null?'':__t)+
'">\n  <label for="actreal" class="phx-inline-label" style="display: none;">&nbsp;</label>\n  <div class="refActreal form-group"></div>\n</div>\n\n';
}
return __p;
};
