/**
 * Application Data Type Model.
 */
export var TDAModel = Backbone.Model.extend({

  defaults: {},

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && response.code) {
      this.id = response.code;
    }

    return response;
  }

});
