import { CWGererActiviteClassiqueView } from 'common/evenements/gerer/gereractivites/views/cwGererActiviteClassique.view';
import { CWGererActiviteDeplacementView } from 'common/evenements/gerer/gereractivites/views/cwGererActiviteDeplacement.view';
import { CWGererActivitesDeplacementModel } from 'common/evenements/gerer/gereractivites/models/cwGererActivitesDeplacement.model';
import { CWGererActivitesModel } from 'common/evenements/gerer/gereractivites/models/cwGererActivites.model';
import { CWSTR } from './cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

export class CWACTIVITE {
  /**
   * Compose the label for a typology of activities
   */
  static getTypologyLabel(typoname: string, nombre: number, indicgestion: { [key: string]: any }, libellesiaucune: boolean, nompropre: boolean): string {
    let label = "";
    let evtLabel = "";
    const typologie = GLOBAL_DATA.typologies.get(typoname);// Typologie

    if (!CWSTR.isBlank(typologie)) {
      const typologieLabel = nombre > 1 ? typologie.get("regroupement") : typologie.get("evenement");
      let masculin = true;// Event

      if (typologie.get("genre") === "F") {
        masculin = false;
      }
      if (indicgestion) {
        if (indicgestion.actprev) {
          // Prevue Only
          if (nombre > 1) { evtLabel = masculin ? i18n.t('messages:GT_1572') : i18n.t('messages:GT_1574'); } else { evtLabel = masculin ? i18n.t('messages:GT_1571') : i18n.t('messages:GT_1573'); }
        }
        if (indicgestion.actreal) {
          if (!CWSTR.isBlank(evtLabel)) {
            evtLabel += i18n.t('common:gereractivites.et');
          }
          // Realisee Only
          if (nombre > 1) { evtLabel += masculin ? i18n.t('messages:GT_1576') : i18n.t('messages:GT_1578'); } else { evtLabel += masculin ? i18n.t('messages:GT_1575') : i18n.t('messages:GT_1577'); }
        }
      }
      if (libellesiaucune && nombre === 0) {
        label = (masculin ? i18n.t('messages:GT_1569') : i18n.t('messages:GT_1570')) + " ";
      }
      label += typologieLabel;
      if (!CWSTR.isBlank(evtLabel)) {
        label += " " + evtLabel;
      }
      if (nompropre) {
        label = CWSTR.toFirstUpperCase(label);
      }
    }
    return label;
  }

  static getActiviteViewByContext(context: { [key: string]: any }): typeof CWGererActiviteDeplacementView | typeof CWGererActiviteClassiqueView {
    return (context && context.ctxDeplacement) ? CWGererActiviteDeplacementView : CWGererActiviteClassiqueView;
  }

  static getActiviteModelByContext(context: { [key: string]: any }): typeof CWGererActivitesDeplacementModel | typeof CWGererActivitesModel {
    return (context && context.ctxDeplacement) ? CWGererActivitesDeplacementModel : CWGererActivitesModel;
  }

  static initialiseActiviteViewByContext(context: { [key: string]: any }, options?: { [key: string]: any }): CWGererActiviteDeplacementView | CWGererActiviteClassiqueView {
    const internalView = CWACTIVITE.getActiviteViewByContext(context);
    let lRtn = null;

    if (internalView) {
      lRtn = new internalView(options);
    }
    return lRtn;
  }

  static initialiseActiviteModelByContext(context: { [key: string]: any }, attributes?: { [key: string]: any }, options?: { [key: string]: any }): CWGererActivitesDeplacementModel | CWGererActivitesModel {
    const internalModel = CWACTIVITE.getActiviteModelByContext(context);
    let lRtn = null;

    if (internalModel) {
      lRtn = new internalModel(attributes, options);
    }
    return lRtn;
  }
} 