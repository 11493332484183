import _ from 'underscore';
import { CWBaseModel } from './cwBase.model';
import { ModelSaveOptions } from 'Backbone';

/**
 * Model for Read only purposes, for example a ComboBox
 */
export class CWReadOnlyModel extends CWBaseModel {

  habilitationV: string;
  habilitationG: string;
  usecase: string;

  constructor(args?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(args)) {
      options = args;
    }
    options = options || {};
    if (options) {
      options.habilitationV = options.habilitationV ? options.habilitationV : "";
      options.habilitationG = options.habilitationG ? options.habilitationG : "";
      options.usecase = options.usecase ? options.usecase : ".";
    }
    super(args, options);
    this.usecase = ".";
  }

  /**
   * Show error if an object that extends this models tries to be saved
   */
  save(attributes?: any, options?: ModelSaveOptions): any { //eslint-disable-line
    throw new Error("Save operation not implemented in read only Model");
  }

  /**
   * Show error if an object that extends this models tries to be destroyed
   */
  destroy(options: { [key: string]: any }): void {//eslint-disable-line
    throw new Error("Destroy operation not implemented in read only Model");
  }
}
