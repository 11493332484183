import _ from 'underscore';
import { CWHABILITATION } from 'src/utils';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWSuvietatModel } from './cwSuvietat.model';
import { GLOBAL_DATA } from 'src/globalData';

/**
 * Workflow for UC Etat du Reseau
 */
export class CWSuvietatWorkflow extends CWReadOnlyModel {
  EMPTYSUVIETAT: CWSuvietatModel;
  tableModel: any;
  customBarModel: any;
  parametreDiversIdCollabModel: any;
  parametreDiversRafEtatModel: any;
  paramIdCollab: any;
  paramRafEtat: number;

  _intervalFetch: number;
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.EMPTYSUVIETAT = new CWSuvietatModel();

    this.tableModel = null;
    this.customBarModel = null;

    //parametres
    this.parametreDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.parametreDiversRafEtatModel = GLOBAL_DATA.paramDivers.get("Raf_etats");
    this.paramIdCollab = this.parametreDiversIdCollabModel.get("valeur");

    /**
     * Model of the habilitation context for the component
     */
    let habCourante = "ETATS.V";
    if (!CWHABILITATION.canView(habCourante)) {
      // si on n'a pas l'habiltation ETATS.V, c'est qu'on n'est pas responsable mais seulement collaborateur
      habCourante = "ETATSCOL.V";
    }
    this.habContext = new CWHabilitationContext({
      onglet: "suvietat",
      foncCour: habCourante
    });
    this.EMPTYSUVIETAT.setHabContext(this.getHabContext());

    const rafEtat = this.parametreDiversRafEtatModel;
    if (!CWSTR.isBlank(rafEtat.get("valeur"))) {
      this.paramRafEtat = parseInt(rafEtat.get("valeur"), 10);
    } else {
      this.paramRafEtat = 0;
    }

    this.set("ready", false);
  }

  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback?: (arg?: any) => void): void {
    //collection fetch
    this.tableModel.coll.fetch({
      success: (): void => {
        //Repace the tableModel.coll for rafModel to get the refresh fq.
        //Start the stream Cycle
        this.tableModel.selectFirstRow();
        this.stream({ interval: this.paramRafEtat });
        if (callback) {
          callback();
        }
      }
    });

    this.set("ready", true);
  }

  _manageErreurSeul(model: { [key: string]: any }): void {
    const erreurSeul = model.get("erreurSeul");
    const coll = this.tableModel.coll;
    const streaming = this._isStreaming();

    //1 >> stop the stream
    if (streaming) {
      this._unstream();
    }
    //2 >> add/remove the filter
    if (erreurSeul) {
      //only show Errors (add indicErreur to filter)
      CWLOG.debug("Only Errors: ON");
      coll.params = _.extend(coll.params, { "erreur": "true" });
    } else {
      //display All (remove indicErreur from filter)
      CWLOG.debug("Only Errors: OFF");
      coll.params = _.omit(coll.params, "erreur");
    }
    //3 >> restart the stream
    if (streaming) {
      this.stream({ interval: this.paramRafEtat });
    }
  }

  stream(options: { [key: string]: any }): void {
    // Cancel any potential previous stream
    if (this._isStreaming()) {
      this._unstream();
    }
    CWLOG.debug("> Start Live Update <");
    let currentSelectedModel = this.tableModel.get("value");
    const _update = _.bind((): void => {
      if ($(".suvietat").length <= 2) {
        this._unstream();
      } else {
        CWLOG.debug("Updating...");
        currentSelectedModel = this.tableModel.get("value");
        if (this.tableModel.get("mode") === "R") {
          this.tableModel.coll.fetch({
            success: (): void => {
              CWLOG.debug("fetching...");
              if (currentSelectedModel) {
                this.tableModel.selectRow(currentSelectedModel);
              } else {
                this.tableModel.selectFirstRow();
              }
            }
          });
        }
        this._intervalFetch = window.setTimeout(_update, options.interval * 1000 || 10000);
      }
    }, this);
    if (options && options.interval > 0) {
      _update();
    } else {
      this.tableModel.coll.fetch({
        success: (): void => {
          this.tableModel.selectRow(currentSelectedModel);
          CWLOG.debug("fetching...");
        }
      });
    }
  }

  _unstream(): void {
    window.clearTimeout(this._intervalFetch);
    delete this._intervalFetch;
    CWLOG.debug("> Stop Live Update <");
  }

  _isStreaming(): any {
    return !CWSTR.isBlank(this._intervalFetch);
  }
}
