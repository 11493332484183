import * as Backbone from 'Backbone';
import _ from 'underscore';
import ResizeObserver from 'resize-observer-polyfill';
import TPLCommonTable from './cwTable.tpl.html';
import { CWActionBarView } from 'core/controls/cwActionBar.view';
import { CWBaseGridModel } from './cwBaseGrid.model';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWColumnsChooserView } from './cwColumnsChooser.view';
import { CWDataGridCellThFakeView } from '../data_grid/cwDataGridCellThFake.view';
import { CWDataGridCellThView } from '../data_grid/cwDataGridCellTh.view';
import { CWDataGridCellView } from '../data_grid/cwDataGridCell.view';
import { CWDataGridRowView } from '../data_grid/cwDataGridRow.view';
import { CWEditableGridViewBase } from '../editablegrid/cwEditableGridBase.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWInternColumnModel } from './cwInternColumn.model';
import { CWInternVueModel } from './cwInternVue.model';
import { CWMainChooserView } from './cwMainChooser.view';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWPaginationComponent } from './cwPaginationComponent.view';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';


export type gridHeightValidsTypes = "statique" | "auto" | "nolimite";

export type gridColumnAlign = "left" | "right" | "center";
export type validPaginationPositions = "butom" | "top" | "none" | "auto";

export type validsWidthTypesForGridsColums = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto";

export interface CWBaseGridColums {
  title: string;
  property: string;
  width?: validsWidthTypesForGridsColums;
  pk?: boolean;
  wordSpacing?: boolean;
  align?: gridColumnAlign;
  /* DEPRECATED */
  minWidth?: string;
  groupe?: boolean;
  sortable?: boolean;
  menuTitle?: string;
  className?: string;//Pour ajouter une nouvelle "class", comme auxiliaire, et pouvoir faire "find" ou créer des styles, pour cette valeur
}

export interface CWBaseGridOptions<TModel extends CWBaseModel = CWBaseModel,
  TColl extends CWPaginatedCollection = CWPaginatedCollection> extends Backbone.ViewOptions<CWBaseGridModel> {
  sortingUseAllColumn?: boolean;
  defaultSortingOrder?: Array<string>;
  /**
   * Parameter to indicate if this table is editable.
   */
  editable?: boolean;
  model?: CWBaseGridModel<TColl, TModel>;
  minWidthMode?: boolean;
  habilitations?: string;
  habModeM?: boolean;
  defaultHeight?: "auto" | number;
  title?: string;
  showFilteredRowsInTitle?: boolean;
  selectInSorting?: boolean;
  showButtonsMultipleVues?: boolean;
  showLevierMultipleViews?: boolean;
  titleFontStyle?: string;
  subTitle?: string;
  /**
   * Evo #991 : Add behavior to menue view 
   *  - position on header
   *  - no fetch on change view
   *  - no display menu ==> switch between view 
   */
  levierMultipleVueOnHeader?: boolean;
  noFetchOnVueChange?: boolean;
  switchBetweenViewOnClik?: boolean;
  vues?: { [key: string]: CWInternVueModel };
  /**
   * Message showed when the table is empty
   */
  emptyMessage?: string;
  emptyMessageClass?: string;
  columnsChooser?: boolean;
  forceHideChooserMenu?: boolean;
  /**
  * Parameter that tells if the table allows multi selection.
  */
  multiselection?: boolean;
  /**
   * Allow to move the selected row number on other element of dom
   */
  hideMultiselectionCpt?: boolean;
  /**
 * Indicates if multiselection checkbox must be hided
 */
  hideMultiSelectionCheckBox?: boolean;

  /**
 * Set an custom class to rows
 */
  appendClassToRow?: string;

  /**
  * Indicates if menu icon must be shown/ hided
  */
  showMenuIcon?: boolean;

  /**
 * Indicates an attribute to filter rows that can be selected
 */
  filterSelection?: string;
  /**
  * Indicates an attribute to filter rows that can be selected
  */
  filterSelectionFunction?: (arg1: TModel) => boolean;
  columnsChooserOverridedView?: new (options: { dataGrid: CWBaseGridView; el: JQuery }) => CWColumnsChooserView;
  /**
  * Defines how the height of the grid should be calculated.
  * posible values : statique, auto, nolimite
  */
  gridHeightType?: gridHeightValidsTypes;
  /**
  * Minimum rows visible (just to have a consistent grid representation when there is less than x rows --> nolimite.
  */
  minVisibleRows?: number;
  /**
  * Maximum rows visible (just to have a consistent grid representation when there is more than x rows --> auto
  */
  maxVisibleRows?: number;
  /**
 * In this case the buttons omit the qualification attributes
 */
  omitHabilitationAttributes?: boolean;
  /**
 * push the new button in left o right the actionBar default buttons
 */
  positionNewButtonsOnLeft?: boolean;
  /**
   * Remove all class from the row elements (dafault false)
   */
  removeGridStyle?: boolean;
  /**
   * Set the position of the pagination rows (default auto)
   */
  paginatorPosition?: validPaginationPositions;
  columns?: Array<CWBaseGridColums>;
  defaultVue?: string;
  parentContainer?: Backbone.View;
  /**
   * layout view for split panels width changes detection
   */
  layout?: Backbone.View;
  /**
  * Set the number of rows per page (default pageSize value in config.js)
  * */
  itemsPerPage?: number;
  /**
   * Disabled the alternating backgorund per rows
   * */
  disableAlternateRowBackground?: boolean;
  /**
   * Event for intercept when the user click on the header
   */
  customHeaderClick?: (event: JQueryEventObject, table: CWBaseGridView) => void;
  /**
   * parameter that tells if the rows are selectable
   */
  selectable?: boolean;
  /**
  * parameter that tells if the rows are highlighted
  */
  sansSelection?: boolean;
  /**
  * Puts up and down on two new buttons
  */
  moveInButton?: boolean;
  /**
   * Set the max width in pixels for the container (useful for tables in tooltips)
   */
  maxWidth?: number;
  minWidthTable?: number;
  resizable?: boolean;
  /**
   * Check the table selection row is enabled
   */
  isNotSelectableRow?: boolean;
  /**
   * Disable the column heads
   */
  disableColumnTitles?: boolean;
  /**
 * Used to position buttons in different class
 * for fixed buttons 
 * default class for volets 'c-form--fixedButtons'
 */
  classForButtons?: string;
  /**
  * Additional classes for multiselection
  */
  classSelectedRow?: string;
  classUnselectedRow?: string;
  /**
   * Utilisé pour afficher ou pas les boutons dans editableGridView
   */
  isEditableGridView?: boolean;

}

export class CWBaseGridView<TModel extends CWBaseModel = CWBaseModel, TColl extends CWPaginatedCollection = CWPaginatedCollection> extends Backbone.View<CWBaseGridModel<TColl, TModel>> {

  /**
   * Event launched by the parent class to reload any table
   *
   */

  /**
   * Default grid columns width
   */
  DEFAULT_COL_WIDTH: number;
  /**
   * Default grid columns width
   */
  DEFAULT_MIN_COL_WIDTH: number;
  /**
   * Default grid height
   */
  DEFAULT_HEIGHT: number | string;
  /**
   * Default grid width, format: Number%
   */
  DEFAULT_WIDTH: string;
  /**
   * Rows per page quantity if height can't be calculated
   */
  DEFAULT_ROWS_PER_PAGE: number;
  /**
   * Name of the Grid by default
   */
  DEFAULT_NANE: string;
  /**
   * Parameter to indicate if update is allowed by habilitations.
   */
  habilitationUpdate: boolean;
  /**
   * Parameter that indicate that the table has been globally locked by an external habilitation (see function lockTable).
   */
  lockedByHabilitations: boolean;
  /**
   * Parameter to indicate if this table is editable.
   */
  editable: boolean;
  /**
   * Parameter that tells if the table allows multi selection.
   */
  multiselection: boolean;
  /**
   * Indicates an attribute to filter rows that can be selected
   */
  filterSelection: string;
  /**
   * Indicates an attribute to filter rows that can be selected
   */
  filterSelectionFunction: (arg1: TModel) => boolean;
  /**
   * parameter that tells if the rows are selectable
   */
  selectable: boolean;
  /**
   * Parameter that paint the column chooser btn
   */
  columnsChooser: boolean;
  /**
   * Defines how the height of the grid should be calculated.
   * posible values : statique, auto, nolimite
   */
  gridHeightType: gridHeightValidsTypes;
  /**
   * Minimum rows visible (just to have a consistent grid representation when there is less than x rows --> nolimite.
   */
  minVisibleRows: number;
  /**
   * Maximum rows visible (just to have a consistent grid representation when there is more than x rows --> auto
   */
  maxVisibleRows: any;
  /**
   * In case the grid is inside a dialog we can pass the dialog to calculate table size.
   */
  parentContainer: Backbone.View;
  /**
   * In this case the buttons omit the qualification attributes
   */
  omitHabilitationAttributes: boolean;
  /**
   * push the new button in left o right the actionBar default buttons
   */
  positionNewButtonsOnLeft: boolean;
  /**
   * Remove grid style
   */
  removeGridStyle: boolean;
  /**
   * Disabled the alternating backgorund per rows
   * */
  disableAlternateRowBackground: boolean;
  /**
   * Constructor
   * View underlying a generic grid
   *  	ex: {property: "nature", title: "Nature", width: 50}
   *  	can be one of these: "statique", "auto", "nolimite".
   */

  height: number | string;
  width: number | string;
  cellRenderers: { [key: string]: (model: TModel, className?: string, cell?: CWDataGridCellView) => JQuery | string | HTMLElement | Backbone.View };
  vues: { [key: string]: CWInternVueModel };
  minWidthMode: number | string | boolean;
  habilitations: string;
  habModeM: boolean;
  title: string;
  defaultVue: string;
  emptyMessage: string;
  emptyMessageClass: string;
  currentVue: CWInternVueModel;
  titleFontStyle: string;
  /**
   * This property required an not empty title, if the title is empty
   * the number of rows in title is not showed
   */
  showFilteredRowsInTitle: boolean;
  _isRowUpdatable: boolean;
  forceHideChooserMenu: boolean;
  useMaxVisibleRows: boolean;
  rowHeight: number | string;
  rowsPerPage: number;
  gridHeight: string | number | null;
  template: (params?: any) => string;
  selectInSorting: boolean;
  enableSelectionOnDblclick: boolean;
  isPaginating: boolean;
  showButtonsMultipleVues: boolean;
  showLevierMultipleViews: boolean;
  levierMultipleVueOnHeader: boolean;
  noFetchOnVueChange: boolean;
  switchBetweenViewOnClik: boolean;
  columnsChooserView: CWColumnsChooserView | CWMainChooserView;
  columnsChooserOverridedView: new (options: { dataGrid: CWBaseGridView; el: JQuery }) => CWColumnsChooserView;
  vuesButtonBar: CWActionBarView;
  lastPage: boolean;
  firstPage: boolean;
  buttonBar: CWActionBarView;
  _lastScrollPosition: number;
  cellSelected: number;
  orderedData: any[];
  clonedCollection: TColl;
  overrideFilteredRowsInTitle: (arg?: number) => any;
  rowNumber: number;
  filterMultiSelectColl: { [key: string]: any };
  rows: Array<CWDataGridRowView>;
  paginator: CWPaginationComponent;
  paginatorPosition: validPaginationPositions;
  hideMultiSelectionCheckBox: boolean;
  hideMultiselectionCpt: boolean;
  appendClassToRow: string;
  showMenuIcon: boolean;
  viewRow: CWEditableGridViewBase<TModel>;
  viewRowTitle: string;
  resizable: boolean;
  isNotSelectableRow: boolean;
  usecase: string;
  /**
  * Disable the column heads
  */
  disableColumnTitles?: boolean;
  layout: Backbone.View;

  public sortingUseAllColumn: boolean;
  public defaultSortingOrder: Array<string>;
  /**
   * When don't have elments we need to hide the paginator, for save
   * the position i use this property
   */
  private savedPaginationPosition: validPaginationPositions;
  private customHeaderClick?: (event: JQueryEventObject, table: CWBaseGridView) => void;

  _manageSortingOld: (columnCode: string, callback: (coll?: TColl) => void, silent: boolean, collection?: any) => void;

  isEditableGridView: boolean;
  /**
  * parameter that tells if the rows are highlighted
  */
  sansSelection: boolean;

  /**
   * Storage element to save the parent of the paginator when we hide it
   */
  private deAtachParentPaginator: Array<JQuery>;

  /**
  * Puts up and down on two new buttons
  */
  moveInButton?: boolean;
  /**
   * Set the max width in pixels for the container (useful for tables in tooltips)
   */
  maxWidth?: number;
  minWidthTable: number;
  classForButtons: string;
  /**
   * Additional classes for multiselection
   */
  classSelectedRow: string;
  classUnselectedRow: string;

  private resizeObserverForScroll: ResizeObserver;

  subTitle?: string;

  constructor(options: CWBaseGridOptions<TModel, TColl>) {
    options.events = _.extend({
      "keydown .c-grind__scroll": "keyEvent",
      "keydown .c-grind__table__header": "keyEventHeader",
      "click th": "_clickListenerHeader"
    }, options.events);
    super(options);
    this.isEditableGridView = _.isBoolean(options.isEditableGridView) ? options.isEditableGridView : false;
    this.DEFAULT_COL_WIDTH = 100;
    this.DEFAULT_MIN_COL_WIDTH = 100;
    this.DEFAULT_HEIGHT = "auto";
    this.DEFAULT_WIDTH = "100%";
    this.DEFAULT_ROWS_PER_PAGE = 12;
    this.DEFAULT_NANE = "Base";
    this.habilitationUpdate = true;
    this.lockedByHabilitations = false;
    this.editable = options.editable ? true : false;
    this.multiselection = false;
    this.hideMultiSelectionCheckBox = false;
    this.hideMultiselectionCpt = false;
    this.appendClassToRow = "";
    this.showMenuIcon = false;
    this.filterSelectionFunction = undefined;
    this.selectable = true;
    this.sansSelection = false;
    this.columnsChooser = true;
    this.gridHeightType = "statique";
    this.minVisibleRows = 3;
    this.maxVisibleRows = null;
    this.parentContainer = null;
    this.layout = null;
    this.omitHabilitationAttributes = false;
    this.positionNewButtonsOnLeft = false;
    this.removeGridStyle = false;
    this.disableAlternateRowBackground = false;
    this.isNotSelectableRow = false;
    this.model = options.model || null;
    this.disableColumnTitles = options.disableColumnTitles || false;
    this.cellRenderers = {};
    this.vues = {};
    this.height = this.DEFAULT_HEIGHT;
    this.width = this.DEFAULT_WIDTH;
    this.cellRenderers = {}; //Allow the definition of custom cell renderer per column
    this.minWidthMode = (options.minWidthMode) ? options.minWidthMode : false;
    // habilitation
    this.habilitations = options.habilitations;
    //habilitation in mode M
    this.habModeM = false;
    if (options && options.habModeM) {
      this.habModeM = options.habModeM;
    }
    if (options && options.defaultHeight && _.isNumber(options.defaultHeight)) {
      this.height = options.defaultHeight;
    }
    // set the title of the table
    this.title = options.title;
    if (options?.subTitle) {
      this.subTitle = options.subTitle;
    }
    this.showFilteredRowsInTitle = options.showFilteredRowsInTitle;
    this._isRowUpdatable = true;
    this.rowHeight = null; // It used to be on the outside as class variable
    this.gridHeight = null;
    this.titleFontStyle = options.titleFontStyle;
    this.template = TPLCommonTable;
    if (options && _.isBoolean(options.selectable)) {
      this.selectable = options.selectable;
    }
    if (options && _.isBoolean(options.sansSelection)) {
      this.sansSelection = options.sansSelection;
    }
    this.selectInSorting = true;
    if (options && !CWSTR.isBlank(options.selectInSorting)) {
      this.selectInSorting = options.selectInSorting;
    }
    //showButtonsMultipleVues decides if  buttons that allow to change vue selected are going to be shown
    if (options && !CWSTR.isBlank(options.showButtonsMultipleVues)) {
      this.showButtonsMultipleVues = options.showButtonsMultipleVues;
    } else {
      this.showButtonsMultipleVues = false;
    }
    // #Evo 991 
    this.noFetchOnVueChange = options.noFetchOnVueChange || false;
    this.switchBetweenViewOnClik = options.switchBetweenViewOnClik || false;

    if (options && options.layout) {
      this.layout = options.layout;
    }
    //showLevierMultipleViews decides if  buttons that allow to change vue selected are going to be shown
    if (options && !CWSTR.isBlank(options.showLevierMultipleViews)) {
      this.showLevierMultipleViews = options.showLevierMultipleViews;
      if (!CWSTR.isBlank(options.levierMultipleVueOnHeader)) {
        this.levierMultipleVueOnHeader = options.levierMultipleVueOnHeader;
      } else {
        this.showLevierMultipleViews = false;
      }
    } else {
      this.showLevierMultipleViews = false;
    }

    this.resizable = false;
    if (options && options.resizable) {
      this.resizable = options.resizable;
    }
    if (options && options.isNotSelectableRow) {
      this.isNotSelectableRow = options.isNotSelectableRow;
    }
    //Create a standard vue if vue does not exist
    if (!options.vues) { //Initialize this.vues an this.currentVue when only one view is going to be used
      const vue = new CWInternVueModel(null, options);

      this.vues = {};
      //columns definition
      if (!options.columns) {
        throw new Error("You must define the columns attribute to use a " + this.DEFAULT_NANE + "GridView");
      }
      if (options.minWidthMode) {
        options.columns.forEach((column: any) => {
          if (!Object.prototype.hasOwnProperty.call(column, "minWidth")) {
            throw new Error("You must define the columns minWidth attribute for each column in , min-width-mode");
          }
        })
      }
      this.defaultVue = "CURRENT";
      vue.columns = options.columns;
      this.vues["CURRENT"] = vue;
      this.currentVue = this.vues["CURRENT"];
    } else { //Initialize this.vues an this.currentVue when there are many views defined for this table
      if (options.defaultVue) {
        this.defaultVue = options.defaultVue;
      } else {
        throw new Error("You must define a default vue to use a " + this.DEFAULT_NANE + "GridView");
      }
      this.vues = {};
      _.each(_.keys(options.vues), (key: number | string) => {
        if (!options.vues[key].columns) {
          throw new Error("You must define the columns attribute for each vue to use a " + this.DEFAULT_NANE + "GridView");
        } else {
          this.vues[key] = new CWInternVueModel(key.toString(), options.vues[key]);
        }
      }, this);
      if (Object.keys(options.vues).length > 0) {
        this.currentVue = this.vues[this.defaultVue];
        if (this.showButtonsMultipleVues === true) {
          this._initializeVuesButtonBar();
        }
      } else {
        throw new Error("You must define at least one vue to use a " + this.DEFAULT_NANE + "GridView");
      }
      this.model.on("change:currentVue", this._manageVueChange, this);
    }
    this.emptyMessage = "";
    if (options && options.emptyMessage) {
      this.emptyMessage = options.emptyMessage;
      if (options.emptyMessageClass) {
        this.emptyMessageClass = options.emptyMessageClass;
      }
    }
    // check if there is only one column to hide the column chooser button
    if (this.currentVue.columns.length === 1 && Object.keys(this.vues).length === 1) {
      this.columnsChooser = false;
    } else {
      if (!CWSTR.isBlank(options.columnsChooser)) {
        this.columnsChooser = options.columnsChooser;
      }
    }
    this.forceHideChooserMenu = (options.forceHideChooserMenu === true);
    this.showMenuIcon = (options.showMenuIcon === true);
    this.multiselection = (options.multiselection === true);
    // add multi-selection column
    if (this.multiselection) {
      _.each(this.vues, (vue: any) => {
        vue.columns.splice(0, 0, { title: "", property: "phx-multiselect", width: 1 });
      });
      this.model.multiselectColl.on("add", this._manageMultiSelection, this);
      this.model.multiselectColl.on("remove", this._manageMultiSelection, this);
      this.model.multiselectColl.on("reset", this._manageMultiSelection, this);
      //filter the selection
      if (options.filterSelection) {
        const obj: { [key: string]: any } = {};

        this.filterSelection = options.filterSelection;
        obj[this.filterSelection] = true;
        this.filterMultiSelectColl = obj;
      }
      //filter the selection
      if (options.filterSelectionFunction) {
        this.filterSelectionFunction = options.filterSelectionFunction;
      }
      this.hideMultiSelectionCheckBox = (options.hideMultiSelectionCheckBox === true);
      this.hideMultiselectionCpt = (options.hideMultiselectionCpt === true);
      this.appendClassToRow = options.appendClassToRow ? options.appendClassToRow : "";
    }
    // add an empty last column
    _.each(this.vues, (vue: any) => {
      if (!this.minWidthMode) {
        vue.columns.push({ title: "", property: "phx-last", width: 1 });
      } else {
        vue.columns.push({ title: "", property: "phx-last", width: 1, "minWidth": 18 }); //avant width 18
      }
    });
    // Initializes private columns
    _.each(this.vues, (vue: any) => {
      _.each(vue.columns, (column: any) => {
        vue._columns[column.property] = new CWInternColumnModel();
        if (this.minWidthMode) {
          column.width = '100%';
          vue._columns[column.property].width = column.width;
          vue._columns[column.property].minWidth = column.minWidth;
        } else {
          column.width = UTILS.getTextWidth(column.width);
          vue._columns[column.property].width = column.width;
        }
      });
    });
    // configure checkbox, action and last column for every vue
    _.each(_.keys(this.vues), (key: string | number) => {
      //this.setOmittedColumns(["phx-multiselect", "phx-action", "phx-last"], key);
      this.setOmittedColumns(["phx-multiselect", "phx-last"], key);
    });
    _.each(this.vues, (vue: any) => {
      if (vue._columns["phx-multiselect"]) {
        vue._columns["phx-multiselect"].sortable = false;
        vue._columns["phx-multiselect"].visible = true;
      }
    });
    // repaint rows when a reset event is fired on the collection
    this.listenTo(this.model.coll, "reset", this._paintRows);
    // Show/hide columns
    this.listenTo(this.model, "toggle:column", this.toggleColumn);
    // Lock/unlock columns
    this.listenTo(this.model, "lock:column", this._lockColumn);
    this.listenTo(this.model, "unlock:column", this._unlockColumn);
    // Reset rowHeight
    this.listenTo(this.model, "reset:rowHeight", this._resetRowHeight);
    //scroll utility
    this.listenTo(this.model, "scroll:to", this._scrollToIndex);
    this.listenTo(this.model, "scroll:toIfHidden", this._scrollToIndexIfHidden);
    this.listenTo(this.model, "focus:header", this._focusHeader);
    //When painting rows may be necessary calculate the width of the scrolls
    this.listenTo(this.model, "finishedPaintRows", this.calculatePaddingForScrolls);
    //change le "width" de la colonne
    this.listenTo(this.model, "width:column", this._changeWidthColumn);
    //Maximiser/retablir la largeur de la colonne
    this.listenTo(this.model, "maximize:column", this._maximizeColumn);
    this.listenTo(this.model, "reset:column", this._resetColumn);
    // Control pagination limits
    this.lastPage = false;
    this.firstPage = true;
    this.columnsChooserView = null;
    if (options && options.columnsChooserOverridedView) {
      this.columnsChooserOverridedView = options.columnsChooserOverridedView;
    }
    if (this.model.enableSelectionOnDblclick === true) {
      this.enableSelectionOnDblclick = true;
    }
    // refresh row index on select / unselect
    this.listenTo(this.model.coll, "row:select", this._showSelectedRowIndex);
    this.listenTo(this.model.coll, "row:unselect", this._showSelectedRowIndex);
    // table height options
    if (options.gridHeightType) {
      this.gridHeightType = options.gridHeightType;
      this.parentContainer = options.parentContainer;
      if (this.gridHeightType !== "statique" && this.gridHeightType !== "auto" && this.gridHeightType !== "nolimite") {
        this.gridHeightType = "statique";
      }
    }
    if (!CWSTR.isBlank(options.minVisibleRows) && options.minVisibleRows >= 0) {
      this.minVisibleRows = options.minVisibleRows;
    }
    if (options.maxVisibleRows && options.maxVisibleRows > 0) {
      this.maxVisibleRows = options.maxVisibleRows;
    }
    this.useMaxVisibleRows = false;
    if (this.gridHeightType === "nolimite" && options.maxVisibleRows) {
      this.useMaxVisibleRows = true;
    }
    if (this.gridHeightType === "auto") {
      this.listenTo(objs.appRt.workflow, "resize", () => {
        if (this.$el.is(":visible")) {
          this._resizeGrid();
          this.model.trigger('finishedPaintRows');
        }
      });
    }
    // event used to fix a bug in IE with the scrolls
    this.listenTo(objs.appRt.workflow, "fixScrollPosition", () => {
      if (this.$el.is(":visible")) {
        this._fixScrollPosition();
      }
    });
    // listen resize changes in split bar
    if (this.layout && this.layout.cid) {
      this.listenTo(objs.appRt.workflow, "cw-resize-" + this.layout.cid, this._infobulleFirstcol);
    }

    if (options && options.omitHabilitationAttributes) {
      this.omitHabilitationAttributes = options.omitHabilitationAttributes;
    }
    if (options && options.positionNewButtonsOnLeft) {
      this.positionNewButtonsOnLeft = options.positionNewButtonsOnLeft;
    }
    if (options && _.isBoolean(options.removeGridStyle)) {
      this.removeGridStyle = options.removeGridStyle;
    }
    if (options && _.isBoolean(options.disableAlternateRowBackground)) {
      this.disableAlternateRowBackground = options.disableAlternateRowBackground;
    }
    this.paginator = new CWPaginationComponent({
      actualPage: 0,
      coll: this.model.coll,
      usecase: options.model.get("usecase")
    });
    if (options && (this.paginator.usecase || this.model.usecase)) {
      this.usecase = options.model.get("usecase") ? options.model.get("usecase") : this.model.usecase;
      this.paginator.model.usecase = this.usecase;
    }
    this.listenTo(this.paginator.model, "all", this.paginateTo)
    // hide / show pagination
    this.listenTo(this.model, "paginator", this._manageVisibilityPaginator);
    this.deAtachParentPaginator = Array<JQuery>();
    this.paginatorPosition = "auto";
    if (options && options.paginatorPosition) {
      this.paginatorPosition = options.paginatorPosition;
    }
    this.savedPaginationPosition = this.paginatorPosition;
    if (options.itemsPerPage) {
      this.paginator.coll.pagination.size = options.itemsPerPage;
      this.paginator.coll.pagination.itemsPerPage = options.itemsPerPage;
    }
    if (options.customHeaderClick) {
      this.customHeaderClick = options.customHeaderClick;
    }
    if (options && options.sortingUseAllColumn) {
      this.sortingUseAllColumn = options.sortingUseAllColumn;
    } else {
      this.sortingUseAllColumn = false;
    }
    if (options && options.defaultSortingOrder) {
      this.defaultSortingOrder = options.defaultSortingOrder;
    } else {
      this.defaultSortingOrder = [];
    }
    this.moveInButton = options.moveInButton || false;
    this.maxWidth = null;
    if (options && options.maxWidth) {
      this.maxWidth = options.maxWidth;
    }
    this.minWidthTable = null;
    if (options && options.minWidthTable) {
      this.minWidthTable = options.minWidthTable;
    }
    this.resizeObserverForScroll = null;
    this.classForButtons = options.classForButtons;
    this.classSelectedRow = options.classSelectedRow;
    this.classUnselectedRow = options.classUnselectedRow;
  }

  /**
   * Initialize the button bar that will be used to change the selected vue
   */
  _initializeVuesButtonBar(): void {
    /**
     * ButtonBar
     */
    this.vuesButtonBar = new CWActionBarView({});
    this.model.vuesBtnBarModel = this.vuesButtonBar.model;
    this.model.vuesBtnBarModel.set("mode", "C");
  }

  /**
   *
   * Manages change of view launched from menu
   */
  _manageVueChange(vueName: string): void {
    //When vue has changed
    if (this.currentVue !== this.vues[vueName]) {
      this.currentVue = this.vues[vueName];
      this.reRenderHead();
      this._toggleVuesButtons();
      this._toggleVuesLevier();
      //Hide header for columns that are not visible
      _.each(this.currentVue._columns, (column: any) => {
        if (column.visible === false) {
          column.hideHeader();
        }
      });
      this.model.trigger("vue:change", vueName);
      if (this.currentVue.beforeFetchCallback) {
        this.currentVue.beforeFetchCallback(() => { this._manageVueChangeCallback() });
      } else {
        this._manageVueChangeCallback();
      }
    }
  }

  /**
   * Clean the header of the grid and renders it.
   */
  reRenderHead(): void {
    const thead: any = this.$el.find("thead");

    if (thead.length > 0) {
      thead.empty();
    }
    this._renderHead();
  }

  /**
   * Function that renders the header of the grid.
   */
  _renderHead(): void {
    let totalWidth: number = 0;
    let cont: number = 0;
    let colWidth: number | string = this.DEFAULT_COL_WIDTH;
    let minWidth: number | string = this.DEFAULT_COL_WIDTH;
    let header = null;
    let headerFake = null;
    let totalRecords = null;

    // Iterates over the 2 headers the visible and the fake one
    this.$el.find("thead").each((index: number, thead: any) => {
      const row = this.appendClassToRow !== "" ? $("<tr class='c-grind__table__row row " + this.appendClassToRow + "'>") : $("<tr class='c-grind__table__row row'>");

      if (this.sansSelection) {
        row.addClass("cw-sans-selection");
      }
      $(thead).append(row);
      if (index === 0) {
        // Create a th for every header and store it
        _.each(this.currentVue.columns, (column: any, cellIndex: number | string, columns: any) => {
          const lastHeader: boolean = (cellIndex === (columns.length - 1)),
            options = {
              hIndex: index,
              cellIndex: cellIndex,
              text: column.title,
              last: lastHeader,
              dataGrid: this,
              columnCode: column.property,
              multiselection: (column.property === "phx-multiselect"),
              colWidth: column.width,
              customHeaderClick: this.customHeaderClick
            };

          if (this.minWidthMode) {
            _.extend(options, { "minWidth": column.minWidth, "minWidthMode": true });
          }

          if (!this.disableColumnTitles) {
            const thCell = new CWDataGridCellThView(options);

            row.append(thCell.render().el);
            //verifier après ajouter si la colonne est visible ou pas et par conséquent, ajouter ou pas le style correspondant
            if (!this.currentVue._columns[column.property].visible) {
              thCell.$el.css("display", "none");
            }
            /*  @property {Boolean} wordSpacing - Adjustment of header's text of table.The text will wrap when necessary*/
            // Configure widths and remove border right of the last header column
            if (!lastHeader) {
              if (!CWSTR.isBlank(column.wordSpacing)) {
                const colwidthspacing = 5;

                thCell.$el.find(".title").css({ "white-space": "normal", "word-spacing": colwidthspacing + "px", "text-align": "center" });
              }
            }
            // Stores the headers
            this.currentVue._columns[column.property].header = thCell;
            this.listenTo(thCell, "click", this._manageSorting);
          }
          else {
            this.$el.find(".c-grind__headerScroll table").css("border-top", "0px");
            this.currentVue._columns[column.property].header = null;
          }

          if (!this.minWidthMode) {
            if (column.width) {
              totalWidth += column.width;
            } else {
              totalWidth += this.DEFAULT_MIN_COL_WIDTH;
            }
          } else {
            if (column.minWidth) {
              totalWidth += column.minWidth;
            } else {
              totalWidth += this.DEFAULT_MIN_COL_WIDTH;
            }
          }
        });
      } else {
        // Create a th for every header and store it
        _.each(this.currentVue.columns, (column: any, cellIndex: number | string, columns: any) => {
          const lastHeader: boolean = (cellIndex === (columns.length - 1));
          const thCell: any = new CWDataGridCellThFakeView({ hIndex: index, cellIndex: cellIndex, last: lastHeader });
          row.append(thCell.render().el);
          // Configure widths
          if (!lastHeader) {
            colWidth = (column.width) ? column.width : this.DEFAULT_COL_WIDTH;
            thCell.$el.css("width", colWidth);
          }

          if (this.minWidthMode) {
            minWidth = (column.minWidth) ? column.minWidth : this.DEFAULT_COL_WIDTH;
            thCell.$el.css("min-width", minWidth);
          }

          // Stores the fake headers
          this.currentVue._columns[column.property].headerFake = thCell;
        });
      }
    });
    if (!CWSTR.isBlank(this.minWidthTable) && (CWSTR.isBlank(this.minWidthMode) || this.minWidthMode === false)) {
      totalWidth = this.minWidthTable;
    }
    this._setTableMinWidth(totalWidth);
    // Configure resizable columns
    _.each(this.currentVue.columns, (column: any, cellIndex: number, columns: any) => {
      if (cellIndex < columns.length - 1) {
        header = this.currentVue._columns[column.property].header;
        headerFake = this.currentVue._columns[column.property].headerFake;
        if (!CWSTR.isBlank(header) && this.resizable === true) {
          header.resizable(headerFake);
          headerFake.resizable(header);
        }
      }
    });
    if (!CWSTR.isBlank(header)) {
      //Le nombre de colonnes peut inclure deux de contrôle "phx-action" et "phx-last" que nous ne devons pas raconter
      //pour "show/hide" le menu de colonnes
      cont = 0;
      if (this.currentVue.columns.length > 0) {
        _.each(this.currentVue.columns, (item: any) => {
          //if (item && item.property !== "phx-action" && item.property !== "phx-last") {
          if (item && item.property !== "phx-last") {
            cont++;
          }
        });
      }
      //Update columnsChooser for this view
      if (this.currentVue.columns.length === 1 || cont === 1) {
        this.columnsChooser = false;
      } else if (CWSTR.isBlank(this.forceHideChooserMenu) || this.forceHideChooserMenu === false) {
        this.columnsChooser = true;
      }
      // render column chooser
      if (Object.keys(this.vues).length > 1) {
        if (!(this.columnsChooser === false)) {
          //Only initialize columnschooserview, the first time the vue renders
          if (CWSTR.isBlank(this.columnsChooserView)) {
            this.columnsChooserView = new CWMainChooserView({ dataGrid: this, el: $(".c-grind__titleBar__mainChoose", this.el), switchBetweenViewOnClik: this.switchBetweenViewOnClik });
          }
          if (this.columnsChooserView instanceof CWMainChooserView) {
            this.columnsChooserView.resetVisibility();
          }
          this.columnsChooserView.render();
          if (this.levierMultipleVueOnHeader) {
            this.$el.find(".c-grind__headerScroll ").addClass('d-flex').append(this.$el.find(".c-grind__titleBar__mainChoose").detach().css('margin-top', "12px"));
          }
        }
      } else {
        if (!(this.columnsChooser === false)) {
          if (this.columnsChooserOverridedView) {
            this.columnsChooserView = new this.columnsChooserOverridedView({ dataGrid: this, el: $(".c-grind__titleBar__mainChoose", this.el) });
            this.columnsChooserView.render();
          } else {
            this.columnsChooserView = new CWColumnsChooserView({ dataGrid: this, el: $(".c-grind__titleBar__mainChoose", this.el) });
            this.columnsChooserView.render();
          }
        }
      }
      // make special two columns not resizeable
      this.currentVue._columns["phx-last"].hideResizeable();
    }
    // set title
    this._renderTitle();
    totalRecords = this.model.coll.totalRecords;
    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }
    if (totalRecords <= 0) {
      this.$el.find("thead").hide();
    } else {
      this.$el.find("thead").show();
    }
  }

  _toggleVuesButtons(): void {
    if (this.showButtonsMultipleVues) {
      _.each(_.keys(this.vues), (key: number | string) => {
        if (this.currentVue === this.vues[key]) {
          this.model.vuesBtnBarModel.trigger("hab.hide:" + key);
        } else {
          this.model.vuesBtnBarModel.trigger("hab.show:" + key);
          this.model.vuesBtnBarModel.trigger("show:" + key);
        }
      });
    }
  }

  _toggleVuesLevier(): void {
    if (this.showLevierMultipleViews) {
      $(".vuesLevierSelected", this.$el).text(i18n.t('common:grid.vue').toLowerCase() + " " + this.currentVue.vueName.toLowerCase());
    }
  }

  _manageVueChangeCallback(): void {
    if (!this.noFetchOnVueChange) {
      this.model.coll.fetch({
        success: () => {
          const model = this.model.get("value");
          if (!CWSTR.isBlank(model) && !CWSTR.isBlank(model.get("code"))) {
            this.model.selectRow(this.model.get("value"));
          }
        }
      });
    } else {
      this.model.coll.trigger('reset', this.model.coll);
      if (this.model.get('value')) {
        this.model.get('value').trigger('row:select');
      }
    }
  }

  /**
   * Sets the minimum width of the grid
   */
  _setTableMinWidth(totalWidth: number): void {
    const extraspace = 18;

    if (this.minWidthMode) {
      this.$el.find(".c-grind__headerScroll table").css({ "min-width": (totalWidth) + "px", "table-layout": "auto" });
      this.$el.find(".c-grind__scroll table").css({ "min-width": totalWidth + "px", "table-layout": "auto" });
    } else {
      this.$el.find(".c-grind__headerScroll table").css("min-width", (totalWidth + extraspace) + "px");
      this.$el.find(".c-grind__scroll table").css("min-width", totalWidth + "px");
    }
  }

  /**
   * Renders the title of the grid.
   */
  _renderTitle(): void {
    let totalRecords = this.model.coll.totalRecords;
    const $lPosIni = this.$el.find(".c-grind-upperElements");
    let showMessageEmptyRows = false;

    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }
    showMessageEmptyRows = (totalRecords === 0 && (!this.overrideFilteredRowsInTitle || (this.overrideFilteredRowsInTitle && this.overrideFilteredRowsInTitle(totalRecords) === "")));
    if (showMessageEmptyRows) {
      if ($lPosIni && $lPosIni.length > 0 && !$lPosIni.hasClass("pb-5")) {
        $lPosIni.addClass("pb-5");//on doit ajouter une séparation extra entre le filtre( et les icônes de rafraichissement) et le texte de "Aucun élement ..."
      }
      this.setCustomMessageTable();
      $(".c-grind__titleBar_rowsNumber", this.el).html("");
      $(".c-grind__titleBar_text", this.el).show();
    } else {
      if ($lPosIni && $lPosIni.length > 0 && $lPosIni.hasClass("pb-5")) {
        $lPosIni.removeClass("pb-5"); //on doit enlever la séparation extra, si elle existe
      }
      if (!CWSTR.isBlank(this.title)) {
        $(".c-grind__titleBar_text", this.el).html(this.title);
        if (this.showFilteredRowsInTitle === true && CWSTR.isBlank(this.subTitle)) {
          $(".c-grind__titleBar_rowsNumber", this.el).html("(" + totalRecords + ")");
        } else if (!CWSTR.isBlank(this.subTitle)) {
          $(".c-grind__titleBar_rowsNumber", this.el).html(" (" + this.subTitle + ")");
          $(".c-grind__titleBar_rowsNumber", this.el).addClass(this.titleFontStyle).removeClass("cw-texteEnteteColone");
          $(".c-grind__titleBar_text", this.el).addClass("cw-texteRubrique").removeClass(this.titleFontStyle);
        }
        if (!CWSTR.isBlank(this.titleFontStyle) && CWSTR.isBlank(this.subTitle)) {
          $(".c-grind__titleBar_text", this.el).addClass(this.titleFontStyle).removeClass("cw-texteRubrique");
        }
      } else {
        $(".c-grind__titleBar_text", this.el).html("");
        $(".c-grind__titleBar_text", this.el).hide();
        this._calculateMarginTitleBar();
      }
    }
    this.manageCustomMessageVisibility(showMessageEmptyRows);
  }

  /**
   * Manages the multiSelection for the grid.
   */
  _manageMultiSelection(): void {
    if (this.model.multiselectColl.length > 0) {
      $(".c-grind__footerLeft", this.el).html(i18n.t('common:selectedRows') + this.model.multiselectColl.length)
        .addClass("cw-texteSecondaryNormal cw-texteSelectionne").show();
      $(".cw-buttons .btnConfirm").prop("disabled", false);
    } else {
      $(".c-grind__footerLeft", this.el).empty().hide();
      $(".cw-buttons .btnConfirm").prop("disabled", true);
      if (!CWSTR.isBlank(this.classSelectedRow) || !CWSTR.isBlank(this.classUnselectedRow)) {
        _.each(this.rows, (row: CWDataGridRowView) => {
          row.$el.addClass(this.classUnselectedRow);
          row.$el.removeClass(this.classSelectedRow);
        })
      }
    }
    if (this.hideMultiselectionCpt) {
      $(".c-grind__footerLeft", this.el).empty().hide();
    }
    // trigger event with the number of row checked for multiselection
    this.model.trigger("rows:checked", this.model.multiselectColl.length);
  }

  /**
   * Sets the Omitted columns.
   */
  setOmittedColumns(columnsName: Array<string>, vueName?: string | number): void {
    let key: any;
    let keys: any;

    if (CWSTR.isBlank(vueName)) {
      _.each(columnsName, (columnName: string) => {
        if (this.currentVue._columns[columnName]) {
          this.currentVue._columns[columnName].omitMenu = true;
        }
      }, this);
      keys = _.keys(this.currentVue._columns);
      for (key in keys) {
        if (key.indexOf("phx-") === 0) {
          this.currentVue._columns[key].omitMenu = true;
        }
      }
    } else {
      _.each(columnsName, (columnName) => {
        if (this.vues[vueName]._columns[columnName]) {
          this.vues[vueName]._columns[columnName].omitMenu = true;
        }
      }, this);
      keys = _.keys(this.vues[vueName]._columns);
      for (key in keys) {
        if (key.indexOf("phx-") === 0) {
          this.vues[vueName]._columns[key].omitMenu = true;
        }
      }
    }
  }

  /**
   * Prints the row index with the total on the footer of the table
   */
  _showSelectedRowIndex(): void {
    //Manage the visibility of the rows
    if (this.model.coll.length === 0) {
      this.showTable(false);
    } else {
      this.showTable(true);
    }
  }

  /**
   * Dynamically resize the height of the grid as needed
   */
  _resizeGrid(): void {
    let availableHeight = null;
    let minHeight = 57;
    let tmp = 0;

    if (this.maxVisibleRows && this.rowHeight && (this.rowHeight as number) > 0) {
      availableHeight = parseInt(this.rowHeight.toString()) * this.maxVisibleRows;
    }
    if (this.minVisibleRows && this.rowHeight && (this.rowHeight as number) > 0) {
      minHeight = (parseInt(this.rowHeight.toString()) * this.minVisibleRows) + 3;
    }
    // auto ajustable grid
    if (this.gridHeightType === "auto") {
      let topOffset = this.$el.offset().top;
      const height = this.$el.height();
      const scrollHeight = $(".c-grind__scroll", this.el).height();
      let windowsHeight = 0;
      let filterHeigth = 0;

      if (this.parentContainer) {
        windowsHeight = $(this.parentContainer.el).height();
        topOffset = topOffset - $(this.parentContainer.el).offset().top;
      } else {
        const $lpos = this.$el.parents(".phx-grid-container");

        if ($lpos.length > 0) {
          windowsHeight = $lpos.height();
          topOffset = topOffset - $lpos.offset().top + 10;
        } else {
          windowsHeight = $("#phx-wrap").height();
        }
      }
      //Adjustement for tables under filter
      if (this.$el.parents("div[class*='phx-panelA']").length > 0) {
        filterHeigth = 1;
      }
      tmp = height - scrollHeight;
      if (tmp < 0) {
        tmp = 0;
      }
      availableHeight = Math.max(windowsHeight - topOffset - tmp, minHeight) - filterHeigth - 5;
      if (this.maxVisibleRows && this.rowHeight && (this.rowHeight as number) > 0) {
        if (this.model.coll.length > 0) {
          availableHeight = Math.min(parseInt(this.rowHeight.toString()) * this.model.coll.length, parseInt(this.rowHeight.toString()) * this.maxVisibleRows);
        } else {
          availableHeight = Math.min(availableHeight, parseInt(this.rowHeight.toString()) * this.maxVisibleRows);
        }
      } else if (this.model.coll.length === 0 && !this.rowHeight) {
        //If rowHeight has not value because the collection is empty set the minHeight
        availableHeight = minHeight;
      }
      availableHeight = Math.max(availableHeight + 2, minHeight);
    }
    // grid with infinite height and minimum visible rows
    if (this.gridHeightType === "nolimite" && this.model.coll.paginated === false) {
      if (this.model.coll.length >= this.minVisibleRows) {
        availableHeight = "auto";
      } else {
        availableHeight = minHeight;
      }
    }
    if (this.gridHeightType === "nolimite" && this.useMaxVisibleRows === true && (this.gridHeight as number) > minHeight) {
      availableHeight = this.gridHeight;
    }
    if (availableHeight && this.useMaxVisibleRows === true) {
      if ($(".c-grind__scroll", this.el).height() !== availableHeight) {
        $(".c-grind__scroll", this.el).height(availableHeight);
      }
    }
  }

  _fixScrollPosition(): void {
    $(".c-grind__scroll", this.el).scrollTop(this._lastScrollPosition);
    LOG.debug("called _fixScrollPosition after view becomes visible to fix the positionof the scroll - IE bug");
  }

  /* EVO 537 */
  _addButtonSwitchVues(): void {
    let selectedOption: any = {},
      selectedText = "";
    const selectedTextContainer = $("<span class='vuesLevierSelected'>");
    const container = $(".c-grind__titleBar__barContent", this.el);
    const menuContainer = $("<span>");
    let btn: JQuery = null;
    const $element = $("<span>").addClass("ui-icon").addClass("ui-icon-triangle-1-s").css("display", "inline-block").css("vertical-align", "middle");
    let menuBtn: JQuery = null;

    if (this.currentVue && this.currentVue.vueName) {
      selectedText = this.currentVue.vueName;
    } else {
      selectedOption = this.vues[Object.keys(this.vues)[0]];
      selectedText = selectedOption.vueName;
    }
    selectedText = i18n.t('common:grid.vue').toLowerCase() + " " + selectedText.toLowerCase();
    selectedTextContainer.append(selectedText);
    $(".c-grind__titleBar_rowsNumber", this.el).after(", ");
    btn = $("<span class='btnSwitchVues'>").append(selectedTextContainer).append($element);
    menuBtn = this._addMenuSwitchVues(selectedOption);
    menuContainer.append(btn);
    menuContainer.append(menuBtn);
    container.append(menuContainer);
    btn.click(() => {
      const menu = menuBtn.show().position({ my: "left top", at: "left bottom", of: this });
      const localfunc = (): void => {
        menu.hide();
      };

      $(document).off("click." + this.cid, localfunc);
      $(document).one("click." + this.cid, localfunc);
      return false;
    });
  }

  _addMenuSwitchVues(selectedOption: any): JQuery {
    const options = this.vues;
    const $menu = $("<ul>");

    _.each(options, (value: any) => {
      const $li = $("<li>");
      const $a = $("<a>");

      if (value.code === selectedOption.code) {
        const selectedFlag = $("<span>").addClass("phx-icon phx-icon-planresp-valider");

        $a.append(selectedFlag);
      }
      $a.html(i18n.t('common:grid.vue') + " " + value.vueName.toLowerCase());
      $a.attr("data-code", value.vueName);
      $li.append($a);
      $menu.append($li);
      $li.click((event: JQuery.ClickEvent) => {
        const target = event.target;
        const vueName = $(target).data("code");

        this.model.trigger("change:currentVue", vueName)
      });
    }, this);
    $menu.menu();
    $menu.css("cursor", "pointer");
    $menu.css("width", "150px");
    $menu.css("position", "absolute");
    $menu.css("z-index", "100");
    $menu.hide();
    return $menu;
  }

  /**
   * Enables/Disables the Grid
   */
  enabled(enable: boolean): void {
    const $lphx = this.$el.find(".phx-grid");
    const $lgrind = this.$el.find(".c-grind__disable");

    this.editable = enable && this.habilitationUpdate;
    if (enable) {
      $lphx.removeClass("ui-state-disabled");
      $lgrind.width(0);
      $lgrind.height(0);
    } else {
      $lphx.addClass("ui-state-disabled");
      $lgrind.width($lphx.width());
      $lgrind.height($lphx.height());
    }
  }
  setHeight(height: number): void {
    this.height = height;
  }

  /**
   * Locks the grid forbidding the access to buttons or editable rows.
   */
  lockTable(): void {
    this.lockedByHabilitations = true;
    this.applyHabilitations();
    if (this.lockedByHabilitations === true) {
      this.buttonBar.model.trigger("hab.hide:all");
      this.habilitationUpdate = false;
      this.editable = false;
    }
  }

  /**
   * Manage the habilitations of the grid hidding the not allowed actions
   */
  applyHabilitations(): void {
    if (!CWSTR.isBlank(this.habilitations)) {
      if ((this.omitHabilitationAttributes && !CWHABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !CWHABILITATION.canCreate(this.habilitations))) {
        this.buttonBar.model.trigger("hab.hide:new");
        this.buttonBar.model.trigger("hab.hide:copy");
      }
      if ((this.omitHabilitationAttributes && !CWHABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !CWHABILITATION.canDelete(this.habilitations))) {
        this.buttonBar.model.trigger("hab.hide:delete");
      }
      if ((this.omitHabilitationAttributes && !CWHABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !CWHABILITATION.canUpdate(this.habilitations))) {
        this.habilitationUpdate = false;
        this.editable = false;
      }
      if ((this.omitHabilitationAttributes && !CWHABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !CWHABILITATION.canCreate(this.habilitations) && !CWHABILITATION.canUpdate(this.habilitations))) {
        this.buttonBar.model.trigger("hab.hide:save");
      }
      if (this.habModeM === true && !this.omitHabilitationAttributes && !CWHABILITATION.canUpdate(this.habilitations)) {
        this.buttonBar.model.trigger("hab.hide:new");
        this.buttonBar.model.trigger("hab.hide:copy");
        this.buttonBar.model.trigger("hab.hide:delete");
      }
      if (this.omitHabilitationAttributes && !CWHABILITATION.canView(this.habilitations) && this.buttonBar.newButtons) {
        _.each(this.buttonBar.newButtons, (button: any) => {
          this.buttonBar.model.trigger("hab.hide:" + button.buttonAction);
        });
      }
    }
  }

  /**
   * Unlocks the grid allowing the access to buttons or editable rows.
   */
  unLockTable(): void {
    this.lockedByHabilitations = false;
    this.buttonBar.model.trigger("hab.show:all");
    this.habilitationUpdate = true;
    this.editable = true;
    this.applyHabilitations();
  }

  keyEventHeader(e: JQueryKeyEventObject): boolean {
    let ret = true;

    if (e.keyCode === 40) { // down 1 row
      this._selectAdjacentRow(e, "th", "next");
      ret = false;
    }
    if (e.keyCode === 39) { // right
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, null, "th");
      ret = false;
    }
    if (e.keyCode === 37) { // left
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, null, "th");
      ret = false;
    }
    if (e.keyCode === 35) { // end, moves to the last cell in the current row
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, this.currentVue.columns.length - 1, "th");
      ret = false;
    }
    if (e.keyCode === 36) { // home, moves to the first cell in the current row
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, -1, "th");
      ret = false;
    }
    if (e.keyCode === 38) { // up. This is just to avoid the movement of the screen
      ret = false;
    }
    if (e.keyCode === 33 || e.keyCode === 34) { // up 1 page
      ret = false;
    }
    if (e.keyCode === 13 || e.keyCode === 32) { // enter or space
      $(e.target).click();
      ret = false;
    }
    //return false to avoid the movement of the window when we press an arrow key
    return ret;
  }

  /**
   * Manage the keypress events on the grid.
   */
  keyEvent(e: JQueryKeyEventObject): boolean {
    let ret = true;
    let silent = false;

    if (this.model.get('mode') !== "E") {
      e.stopImmediatePropagation();
      if (CWSTR.isBlank(this.rowsPerPage)) {
        this._calculateRowsPerPage();
      }
      if (e.keyCode === 38) { // up 1 row
        //Accessibility
        this._selectAdjacentRow(e, "td", "prev");
        this.model.selectPreviousRow();
        this._scrollIntoView();
        ret = false;
      }
      if (e.keyCode === 33) { // up 1 page
        if (!$(e.target).parents("tr:first").siblings().addBack().first().hasClass("phx-selected") ||
          !(this.$el.find(".c-grind__scrollExtra1").height() === 0)) {
          silent = $(e.target).parents("tr:first").is(".phx-selected") === true ? false : true;
          this.model.selectFirstRow(silent);
          this._scrollIntoView();
        }
        ret = false;
        //Accessibility
        this._selectAdjacentRow(e, "td", "first");
      }
      if (e.keyCode === 40) { // down 1 row
        this._selectAdjacentRow(e, "td", "next");
        this.model.selectNextRow();
        this._scrollIntoView();
        ret = false;
      }
      if (e.keyCode === 34) { // down 1 page
        if (!$(e.target).parents("tr:first").siblings().addBack().last().hasClass("phx-selected")) {
          silent = $(e.target).parents("tr:first").is(".phx-selected") === true ? false : true;
          this.model.selectLastRow(silent);
          this._scrollIntoView();
        }
        ret = false;
        //Accessibility
        this._selectAdjacentRow(e, "td", "last");
      }
      //Accessibility
      if (e.keyCode === 39) { // right
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, null, "td");
        ret = false;
      }
      if (e.keyCode === 37) { // left
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, null, "td");
        ret = false;
      }
      if (e.keyCode === 35) { // end, moves to the last cell in the current row
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, this.currentVue.columns.length - 1, "td");
        ret = false;
      }
      if (e.keyCode === 36) { // home, moves to the first cell in the current row
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, -1, "td");
        ret = false;
      }
      if (e.keyCode === 13 || e.keyCode === 113) { // enter or F2
        const cell = $(e.currentTarget).find(".grid-cell-focus");
        const tr = $(e.currentTarget).find(".phx-selected");

        if (tr.length > 0) {
          this.model.coll.editModeCellSelected = tr.children().index(cell);
        }
        else {
          this.model.coll.editModeCellSelected = $(e.currentTarget).find(".c-grind__table__row").children().index(cell);
          //Trigger the click event
          cell.click();
        }
      }
      if (e.keyCode === 32) {
        ret = false;
      }
    }
    //return false if event was managed overwise return true
    return ret;
  }


  //horizontal selection inside the table
  _selectAdjacentRow(e: JQueryKeyEventObject, tag: string, adjacent: string): void {
    const currentCell = $(e.currentTarget).find(".grid-cell-focus");
    let nextCellPosition = null;
    let nextCell = null;

    //if it is an input we need to focus the input not the cell.
    if (currentCell.is(":input")) {
      nextCellPosition = currentCell.parents(tag + ":first").parent().children().index(currentCell.parents(tag + ":first"));
    } else {
      nextCellPosition = currentCell.parent().children().index(currentCell);
    }
    //tag == "th" ----> header -> table movement
    if (tag === "th") {
      const firstRowTable = this.$el.find(".c-grind__headerScroll__table .c-grind__table__body").children()[0];

      nextCell = $(firstRowTable).children()[nextCellPosition];
    } else {
      switch (adjacent) {
        case "next":
          //key down
          nextCell = $(e.target).parents("tr:first").next().children()[nextCellPosition];
          break;
        case "prev":
          // key up
          nextCell = $(e.target).parents("tr:first").prev().children()[nextCellPosition];
          if (CWSTR.isBlank(nextCell) && this.$el.find(".c-grind__scrollExtra1").height() === 0) {
            this._focusHeader();
          }
          break;
        case "first":
          //re pag
          nextCell = $(e.currentTarget).find("table tbody tr:first").children()[nextCellPosition];
          break;
        case "last":
          //av pag
          nextCell = $(e.currentTarget).find("table tbody tr:last").children()[nextCellPosition];
          break;
        default:
        //Nothing
      }
    }
    if (!CWSTR.isBlank(nextCell)) {
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr("tabindex", "0").addClass("grid-cell-focus").focus();
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      }
    }
  }

  //  ACCESIBILITY FUNCTIONS
  //Select the adjacent cell (header and table)
  _selectAdjacentCell(container: any, operation: number, position: any, tag: string): void {
    const numberCells = this.currentVue.columns.length;
    const currentCell = this.$el.find(".grid-cell-focus");
    let nextCellPosition = null;
    let nextCell = null;

    //obtain the position of the new cell. if it is an input we need to focus the input not the cell.
    if (currentCell.is("td") || currentCell.is("th")) {
      nextCellPosition = !CWSTR.isBlank(position) ? position : currentCell.parent().children().index(currentCell);
    } else {
      nextCellPosition = !CWSTR.isBlank(position) ? position : currentCell.parents(tag + ":first").parent().children().index(currentCell.parents(tag + ":first"));
    }
    //obtain the new cell
    do {
      nextCellPosition = nextCellPosition + operation;
      nextCell = !CWSTR.isBlank(container[nextCellPosition]) ? container[nextCellPosition] : null;
    } while ((CWSTR.isBlank(nextCell) && nextCellPosition >= 0 && nextCellPosition < numberCells) || (!CWSTR.isBlank(nextCell) && !$(nextCell).is(":visible")));
    //select the new cell
    if (!CWSTR.isBlank(nextCell) && this._selectedCellIsVisible($(nextCell))) {
      this.cellSelected = nextCellPosition;
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus();
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      }
    }
  }

  _selectedCellIsVisible(nextCell: JQuery): boolean {
    const $lpos = this.$el.find(".c-grind__scroll");
    const offsetCell = nextCell.offset();
    const offsetTable = $lpos.offset();
    const heightTable = $lpos.height();
    let lbRtn = false;

    if (offsetCell && offsetTable && heightTable) {
      if (offsetCell.top >= offsetTable.top && offsetCell.top <= (offsetTable.top + heightTable)) {
        lbRtn = true;
      } else {
        lbRtn = false;
      }
    }
    return lbRtn;
  }


  //focus header when we navigate table -> header
  _focusHeader(): void {
    const headerRow = this.$el.find(".c-grind__headerScroll table thead tr");
    const currentCell = this.$el.find(".grid-cell-focus");
    let index = null;
    let nextCell = null;

    if (currentCell.is(":input")) {
      index = currentCell.parents("tr:first").children().index(currentCell.parents("td:first"));
    } else {
      index = currentCell.parents("tr:first").children().index(currentCell);
    }
    nextCell = headerRow.children()[index];
    if (!CWSTR.isBlank(nextCell)) {
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr("tabindex", "0").addClass("grid-cell-focus").focus();
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      }
    }
    if (this.model.get("value")) {
      this.model._manageRowSelection(this.model.get("value"));
    }
  }

  _clickListenerHeader(e?: JQueryEventObject): void {
    const headerRow = this.$el.find(".c-grind__headerScroll table thead tr");
    const currentCell = this.$el.find(".grid-cell-focus");
    const index = headerRow.find("th").index(e.currentTarget);
    let nextCell: HTMLElement = null;

    this.model.coll.editModeCellSelected = index;
    this.cellSelected = index;
    nextCell = headerRow.children()[index];
    if (!CWSTR.isBlank(nextCell)) {
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr("tabindex", "0").addClass("grid-cell-focus").focus();
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      }
    }
  }

  positionHeader(): void {
    const interval = setInterval(() => {
      if (this.$el.parents("body").length > 0) {
        const calculateMargin = (): void => {
          const margin = this._calculateMarginTitleBar();

          this._resizeGrid(); // prevents extra right scrollbar
          clearInterval(interval);
          this._resizeGrid();
          if ((CWSTR.isBlank(margin) || margin === 0) && this.model) {
            const idElement = this.$el.attr("id");

            this._calculateMarginTitleBar();
            if (!CWSTR.isBlank(idElement)) { //il faut l'envoyer avec l'identificateur pour l'element qui a le problème et en utilisant la méthode "escapeJQueryString"
              this.model.trigger("repositionEntete:" + UTILS.escapeJQueryString(idElement)); //comme une dernier action à vérifier l'entète dans l'écran.
            }
          }
        };

        if (this.$el.is(":hidden")) {
          if (this.$el.css("display") === "none") {
            this.$el.show();
            calculateMargin();
            this.$el.hide();
          } else if (this.$el.parents().filter((index: any, element: any) => { return $(element).css("display") === "none"; }).length > 0) {
            const parent = this.$el.parents().filter((index: any, element: any) => { return $(element).css("display") === "none"; });

            for (let i = 0; i < parent.length; i++) {
              const $itemParent = $(parent[i]);
              const hiddenByClass = ($itemParent && (!$itemParent.attr("style") || ($itemParent.attr("style") && $itemParent.attr("style").indexOf("display: none") < 0)) && $itemParent.hasClass("d-none")) ? true : false;

              if (!hiddenByClass) {
                $itemParent.show();
              } else {
                $itemParent.removeClass("d-none");
              }
              calculateMargin();
              if (!hiddenByClass) {
                $itemParent.hide();
              } else {
                $itemParent.addClass("d-none");
              }
            }
          }
        } else {
          calculateMargin();
        }
      }
    }, 100);
  }

  resetSorting(): void {
    const keys = _.keys(this.currentVue._columns);
    let i = 0,
      key: any = null;

    //Hide the sort buttons of all columns
    for (; i < keys.length; i++) {
      key = keys[i];
      if (this.currentVue._columns[key].sortable) {
        this.currentVue._columns[key].sort = null;
        this.currentVue._columns[key].header.hideSortButton();
        this.currentVue._columns[key].header.$el.find(".title").removeClass("phx-state-sort");

        this.orderedData[key] = null;
      }
    }
  }

  /**
   * Select all rows on the grid
   */
  selectAllRows(): any {
    let clonedCollection: any = null;

    this.model.multiselectColl.reset(null, { silent: true });
    clonedCollection = this.model.coll.clone();
    clonedCollection.setHabContext(this.model.coll.getHabContext());
    clonedCollection.url = this.model.coll.url;
    clonedCollection.params = this.model.coll.params;
    // if (this.model.coll.paginated === false) {
    if (!CWSTR.isBlank(this.filterSelection)) {
      clonedCollection.models = clonedCollection.where(this.filterMultiSelectColl);
    } else if (this.filterSelectionFunction) {
      clonedCollection.models = _.filter(clonedCollection.models, (model: any) => {
        return this.filterSelectionFunction(model);
      });
    }
    this.model.multiselectColl.add(clonedCollection.models);
    this.model.coll.forEach((model: any) => {
      model.trigger("row:checked");
    });
    if (!CWSTR.isBlank(this.classSelectedRow) || !CWSTR.isBlank(this.classUnselectedRow)) {
      _.each(this.rows, (row: CWDataGridRowView) => {
        row.$el.addClass(this.classSelectedRow);
        row.$el.removeClass(this.classUnselectedRow);
      })
      this._manageMultiSelection();
    }
  }

  /**
   * Clear all selected rows.
   */
  clearSelectedRows(): void {
    this.model.multiselectColl.reset(null);
    this.model.coll.forEach((model: any) => {
      model.trigger("row:unchecked");
    });
    this._manageMultiSelection();
  }

  /**
   * Get active row (row selected but not marked for selection.
   */
  getCurrentRow(): any {
    return this.model.get("value");
  }

  /**
   * Gets all rows marked for selection if in multiselection, otherwise return the active row.
   */
  getSelectedRows(): any {
    if (this.multiselection === true) {
      if (!CWSTR.isBlank(this.filterSelection)) {
        this.model.multiselectColl.models = this.model.multiselectColl.where(this.filterMultiSelectColl);
      } else {
        if (this.filterSelectionFunction) {
          this.model.multiselectColl.models = _.filter(this.model.multiselectColl.models, (model: TModel) => {
            return this.filterSelectionFunction(model);
          })
        }
      }
      return this.model.multiselectColl;
    } else {
      return this.getCurrentRow();
    }
  }

  /**
   * Indicates if row can be saved
   */
  _allowRowUpdate(allowed: any): void {
    this._isRowUpdatable = allowed;
  }

  /**
   * Refresh the number of filtered rows in the title of the grid.
   */
  recalculateFilteredRowsInTitle(): void {
    this._renderFilteredRowsInTitle();
  }

  /**
   * Renders the filtered rows number on the title with the total.
   */
  _renderFilteredRowsInTitle(): void {
    if (this.showFilteredRowsInTitle === true && !CWSTR.isBlank(this.title)) {
      let totalRecords = this.model.coll.totalRecords;

      this._renderTitle();
      if (this.model.coll.paginated === false) {
        totalRecords = this.model.coll.length;
      }
      // this method can be defined from our usecase if we need special treatment
      if ((this.overrideFilteredRowsInTitle) && (this.overrideFilteredRowsInTitle(totalRecords) !== "")) {
        totalRecords = parseInt(this.overrideFilteredRowsInTitle(totalRecords));
      }
      $(".c-grind__titleBar_rowsNumber", this.el).html(" (" + totalRecords + ")");
    } else {
      $(".c-grind__titleBar_rowsNumber", this.el).hide();
    }
  }

  /**
   * Set and renders the grid title.
   */
  setTitle(title: string): void {
    this.title = title;
    this._renderTitle();
    this._renderFilteredRowsInTitle();
  }

  /**
   * Resets the row  and the grid Height
   */
  _resetRowHeight(): void {
    this.rowHeight = 0;
    this.gridHeight = 0;
  }

  /**
   * Customize the names of the columns when call rest for sorting
   * ex: niveau.code >> niveau => { "niveau.code" : "niveau_ordre",... }
   */
  setSortableCustomNames(sortableNames: { [key: string]: any }, vueName?: any): void {
    if (CWSTR.isBlank(vueName)) {
      this.currentVue._sortableCustomNames = sortableNames;
    } else {
      this.vues[vueName]._sortableCustomNames = sortableNames;
    }
  }

  /**
   * Sets the Sortable columns.
   */
  setSortableColumns(columnsName?: Array<string>, vueName?: string): void {
    let keys = null;

    //Standard use when there is only one vue for the table
    if (CWSTR.isBlank(vueName)) {
      // Reset values
      _.each(_.keys(this.currentVue._columns), (columnName) => {
        this.currentVue._columns[columnName].sortable = true;
      }, this);

      _.each(_.difference(_.keys(this.currentVue._columns), columnsName), (columnName) => {
        this.currentVue._columns[columnName].sortable = false;
      }, this);
      keys = _.keys(this.currentVue._columns);
      for (const key in keys) {
        if (key.indexOf("phx-") === 0) {
          this.currentVue._columns[key].sortable = false;
        }
      }
    } else { //Modified use for a table with many vues
      // Reset values
      _.each(_.keys(this.vues[vueName]._columns), (columnName) => {
        this.vues[vueName]._columns[columnName].sortable = true;
      }, this);
      _.each(_.difference(_.keys(this.vues[vueName]._columns), columnsName), (columnName) => {
        this.vues[vueName]._columns[columnName].sortable = false;
      }, this);
      keys = _.keys(this.currentVue._columns);
      for (const key2 in keys) {
        if (key2.indexOf("phx-") === 0) {
          this.vues[vueName]._columns[key2].sortable = false;
        }
      }
    }
  }

  /**
   * Set the alignment of the columns. The default aligment is left, to override this
   * behavior you must specify the correct aligment like { "columnName" : "left|right|center",...}.
   */
  setColumnsAlignment(columnsAlignment: { [key: string]: gridColumnAlign }, vueName?: string): void {
    if (CWSTR.isBlank(vueName)) {
      this.currentVue._columnsAlign = columnsAlignment;
    } else {
      this.vues[vueName]._columnsAlign = columnsAlignment;
    }
  }

  /**
   * Sets the Lockeds columns.
   */
  setLockedColumns(columnsName: Array<string>, vueName?: string): void {
    if (CWSTR.isBlank(vueName)) {
      this.currentVue._lockedColumns = columnsName;
    } else {
      this.vues[vueName]._lockedColumns = columnsName;
    }
  }

  /**
   * Sets the Visible columns.
   */
  setVisibleColumns(columnsName: Array<string>, vueName?: string): void {
    if (CWSTR.isBlank(vueName)) {
      this.currentVue._visibleColumns = columnsName;
    } else {
      this.vues[vueName]._visibleColumns = columnsName;
    }
  }

  /**
   * Custom Backbone remove function that removes the View.
   */
  remove(): any {
    delete this.height;
    delete this.width;
    if (this.rows) {
      for (let r = 0; r < this.rows.length; r++) {
        this.rows[r].remove();
        this.rows[r] = null;
      }
      this.rows.length = 0;
    }
    this.stopListening();
    _.each(this.vues, (vue: any) => {
      _.each(vue.cellRenderers, (cellRenderer: any) => {
        if (cellRenderer.remove) {
          cellRenderer.remove();
        }
      });
    });
    _.each(_.keys(this.vues), (keyVue: any) => {
      let keys = _.keys(this.vues[keyVue].cellRenderers);
      let visibleColumnsLength = null;
      let lockedColumnsLength = null;
      let columnsLength = null;

      for (const key in keys) {
        if (this.vues[keyVue]) {
          delete this.vues[keyVue].cellRenderers[keys[key]];
        }
      }
      delete this.vues[keyVue].cellRenderers;
      keys = _.keys(this.vues[keyVue]._columnsAlign);
      for (const key in keys) {
        if (this.vues[keyVue]) {
          delete this.vues[keyVue]._columnsAlign[keys[key]];
        }
      }
      delete this.vues[keyVue]._columnsAlign;
      _.each(this.vues[keyVue]._columns, (column: any) => {
        column.remove();
      });
      keys = _.keys(this.vues[keyVue]._columns);
      for (const key in keys) {
        if (this.vues[keyVue]) {
          delete this.vues[keyVue]._columns[keys[key]];
        }
      }
      delete this.vues[keyVue]._columns;
      keys = _.keys(this.vues[keyVue]._sortableCustomNames);
      for (const key in keys) {
        if (this.vues[keyVue]) {
          delete this.vues[keyVue]._sortableCustomNames[keys[key]];
        }
      }
      delete this.vues[keyVue]._sortableCustomNames;
      visibleColumnsLength = this.vues[keyVue]._visibleColumns.length;
      this.vues[keyVue]._visibleColumns.splice(0, visibleColumnsLength);
      delete this.vues[keyVue]._visibleColumns;
      lockedColumnsLength = this.vues[keyVue]._lockedColumns.length;
      this.vues[keyVue]._lockedColumns.splice(0, lockedColumnsLength);
      delete this.vues[keyVue]._lockedColumns;
      columnsLength = this.vues[keyVue].columns.length;
      this.vues[keyVue].columns.splice(0, columnsLength);
      delete this.vues[keyVue].columns;
      delete this.vues[keyVue];
    }, this);
    delete this.currentVue;
    delete this.vues;
    delete this.template;
    delete this.multiselection;
    delete this.selectable;
    delete this.lastPage;
    delete this.firstPage;
    if (!CWSTR.isBlank(this.columnsChooserView)) {
      this.columnsChooserView.remove();
      delete this.columnsChooserView;
    }
    if (this.model && this.model.coll) {
      this.model.coll.reset(null, { silent: true });
    }
    this.model.stopListening();
    Backbone.View.prototype.remove.call(this);
    this.$el.empty();
  }

  /**
   * Scroll the table to make the selected item in the table visible
   *
   * The selected item must be marked with class phx-selected
   * The div containing the table must be marked with class c-grind__scroll
   */
  _scrollIntoView(): void {
    const element = this.$el.find("tbody .phx-selected");

    if (!CWSTR.isBlank(element[0])) {
      const parent = $(element).parents(".c-grind__scroll");// gets the scrollable container containing the selected row
      const scrollTop = $(parent).scrollTop();// current position of the scroll
      const tableHeight = $(parent).height();// table height
      const elementOffset = scrollTop + $(element).offset().top - $(parent).offset().top;// offset of the selected row with respect to the top of the table

      // check if selected row is out of view
      if (elementOffset < scrollTop) {
        // to maintain selected row on top of the table
        $(parent).scrollTop(elementOffset);
      } else if (elementOffset > (scrollTop + tableHeight - element.height())) {
        // to maintain selected row on bottom of the table
        $(parent).scrollTop(elementOffset + element.height() - tableHeight);
      }
    }
  }

  /**
   * Show/Hide Custom Message
   */
  manageCustomMessageVisibility(isVisible: boolean): void {
    //If the message of emty rows is visible I need to hide the pagination
    if (!isVisible) {
      this.paginatorPosition = this.savedPaginationPosition;
    } else {
      if (this.paginatorPosition !== "none") {
        this.savedPaginationPosition = this.paginatorPosition;
      }
      this.paginatorPosition = "none";
    }
    this.renderPaginator();
  }

  /**
   * Set an aux Message to the table.
   */
  setCustomMessageTable(title?: string): void {
    const messageDiv = this.$el.find(".c-grind__titleBar_text");

    if (!CWSTR.isBlank(title)) {
      messageDiv.html(title);
    } else {
      //Unavailable message for tab focus
      const emptyMessage = !CWSTR.isBlank(this.emptyMessage) ? this.emptyMessage : i18n.t('common:grid_no_item_title');
      const span: any = $("<span>").html(emptyMessage).attr({ "tabindex": "0" });

      if (!CWSTR.isBlank(this.emptyMessageClass)) {
        span.addClass(this.emptyMessageClass);
      }
      messageDiv.html(span);
    }

  }

  /**
   * In the old version by default all tables are paginated, so for this version
   * the default value "auto" change to "butom" if the collection of the table
   * is paginated.
   */
  protected checPaginationPositionLogic(): void {
    if (this.paginatorPosition === "auto") {
      if (this.model.coll.paginated === true) {
        this.paginatorPosition = "butom";
      } else {
        this.paginatorPosition = "none";
      }
    }
  }

  protected renderPaginator(): void {
    $(this.paginator.el).detach();
    this.checPaginationPositionLogic();
    if (this.paginatorPosition !== "none") {
      if (!this.paginator.isRendered) {
        this.paginator.usecase = this.usecase ? this.usecase : this.model.usecase;
        this.paginator.render();
      }
      if (this.paginatorPosition === "butom") {
        $(".c-grind_paginationContainer-bottom", this.el).append(this.paginator.el).show();
        $(".c-grind_paginationContainer-top", this.el).hide();
      }
      else {
        $(".c-grind_paginationContainer-bottom", this.el).hide();
        $(".c-grind_paginationContainer-top", this.el).append(this.paginator.el).show();
        this.$el.find(".c-grind-upperElements .c-grind_paginationContainer").addClass("pr-2 pb-2");
      }
    } else {
      this.$el.find(".c-grind-upperElements .c-grind_paginationContainer").css("display", "none");
      this.$el.find(".c-grind .c-grind_paginationContainer").css("display", "none");
    }
  }

  /**
   * Renders the rows of the current grid.
   */
  _paintRows(): void {
    const tbody = this.$el.find("tbody.c-grind__table__body");
    let scrollTop: number = null;

    if (tbody.length === 0) {
      // Avoid an initial call during Workflow setUp before the page is rendered
      return;
    }
    scrollTop = $(".c-grind__scroll", this.el).scrollTop();
    if (this.rows) {
      for (let r = 0; r < this.rows.length; r++) {
        this.rows[r].remove();
        this.rows[r] = null;
      }
      this.rows.length = 0;
    } else {
      this.rows = [];
    }
    tbody.empty();
    _.each(this.currentVue._columns, (column: any) => {
      if (column && column.cells) {
        for (let c = 0; c < column.cells.length; c++) {
          column.cells[c].remove();
          column.cells[c] = null;
        }
        column.cells = [];
      }
    });
    for (let i = 0; i < this.model.coll.length; i++) {
      const rModel = this.model.coll.at(i);
      const rowView = new CWDataGridRowView({ model: rModel, dataGrid: this, rowNumber: i, classForButtons: this.classForButtons });
      const row = rowView.render().el;
      const $lPos = $(row.firstChild).find("div");

      if (!this.removeGridStyle) {
        if (this.disableAlternateRowBackground === true) {
          $(row).addClass("row");
        }
        else {
          $(row).addClass("c-grind__table__row row");
          if (this.sansSelection) {
            $(row).addClass("cw-sans-selection");
          }
          if (i % 2 === 1 && !this.sansSelection) {
            $(row).addClass("c-grind__table__row-alt row");
          }
        }
      }
      // select the first cell of the first row when the table is at detail section and has not select row by default.
      if (i === 0 && !CWSTR.isBlank($(row).children()[0]) && CWSTR.isBlank(this.model.coll.editModeCellSelected)) {
        if ($(row).children().eq(0).find(":input").is(":input") && !this.hideMultiSelectionCheckBox) {
          $(row).children().eq(0).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus");
        } else if (!this.hideMultiSelectionCheckBox) {
          $(row).children().eq(0).attr({ "tabindex": "0" }).addClass("grid-cell-focus");
        } else {
          $(row).children().eq(1).attr({ "tabindex": "0" }).addClass("grid-cell-focus");
        }
      }
      tbody.append(row);
      this.rows.push(rowView);
      //Sur la première colonne, uniquement on fera la vérification pour ajouter ou pas, l'infobulle 
      if ($lPos && $lPos.length > 0 && $lPos.hasClass("cw-texte-reduit")) {
        if ($lPos[0].scrollWidth > $lPos[0].clientWidth) {
          $lPos.attr("title", $lPos.text());
          $lPos.data("titleMaxCol", true);
        } else if ($lPos.data("titleMaxCol") === true) {//Uniquement on removera le title de maxCol. Si l'il y a autre, il sera affiché
          $lPos.attr("title", "");
        }
        //Adding a title (infobulle) when we want to show ... if the libellé is too long
      } else if ($(row).find(".add-texte-reduit").length > 0) {
        const $posCol = $(row).find(".add-texte-reduit");

        if ($posCol[0].scrollWidth > $posCol[0].clientWidth) {
          _.each($posCol, (element: any) => {
            if (element.scrollWidth > element.clientWidth) {
              $(element).attr("title", $(element).text());
              $(element).data("titleMaxCol", true);
            }
          });
        } else if ($posCol.data("titleMaxCol") === true) {//Uniquement on removera le title de maxCol. Si l'il y a autre, il sera affiché
          $posCol.attr("title", "");
        }
      }
    }
    // Toggle control
    // Hide the columns that must be hidden by default.
    if (this.currentVue._visibleColumns.length > 0) {
      _.each(_.difference(_.keys(this.currentVue._columns), this.currentVue._visibleColumns), (key: any) => {
        if (key.indexOf("phx-") !== 0) {
          this.model.trigger("toggle:column", key);
        }
      }, this);
      this.currentVue._visibleColumns = [];
    }
    // Hide the columns that must be hidden by default.
    if (this.currentVue._lockedColumns.length > 0) {
      _.each(this.currentVue._lockedColumns, (key: any) => {
        this.model.trigger("lock:column", key);
      }, this);
      this.currentVue._lockedColumns = [];
    }
    // Adjust scroll
    this.showTable(true); //(1) C'est important pour le calcul de "rowHeight". Ne pas le toucher ou le mettre dans un "if"
    if (!this.rowHeight || this.rowHeight === 0) {
      this._calculateRowsPerPage();
    }
    if (this.model.coll.length === 0) {//(2) Ne pas toucher.
      this.showTable(false);
    }
    if (CWSTR.isBlank(this.rowHeight)) {
      this.rowHeight = 0;
    }
    $(".c-grind__scroll", this.el).scrollTop(scrollTop);
    this._renderFilteredRowsInTitle();
    if (this.multiselection) {
      this.currentVue._columns["phx-multiselect"].header._manageChecking();
      this._manageMultiSelection();
    }
    // dynamic resize heigth of grid
    this._resizeGrid();
    if (this.model.coll.length === 0) {
      this.reRenderHead();
    } else {
      this._renderTitle();
      this.$el.find("thead").show();
    }
    this.model.trigger('finishedPaintRows');
  }

  /**
   * Because in the new UI the scroll in some browser are inside container
   * we we need to add an a padding in headers (and may be other) to take
   * care about this. So i think this is the best option to calculate
   * who scroll we need, because  depending of browser and user config
   * the scroll may be more or less width
   */
  private calculatePaddingForScrolls(): void {
    setTimeout((): void => {
      if (CWSTR.isBlank(this.resizeObserverForScroll)) {
        const lPos = $(".c-grind__scroll", this.el);

        if (lPos.length > 0 && this.$el.parents('html').length > 0) { //Check if exist and are atached to dom
          this.resizeObserverForScroll = new ResizeObserver((): void => {
            if (this.$el.is(":visible")) {
              this.appendCalculateScroll();
            }
          });
          this.resizeObserverForScroll.observe(lPos[0]);
        }
      }
    }, 500);
  }

  private appendCalculateScroll(): void {
    $(".c-grind__headerScroll", this.el).css("padding-right", UTILS.getScrollbarWidth($(".c-grind__scroll", this.el)) + "px");
    //If you need to add padding right to other element add  here!
  }

  /**
   * Calculate the rows per page for the current grid,
   * using the row Height and the grid Height set
   */
  _calculateRowsPerPage(forced?: boolean): void {
    if (CWSTR.isBlank(this.rowHeight) || (this.rowHeight as number) <= 0 || (forced === true && !CWSTR.isBlank(this.rowHeight) && (this.rowHeight as number) > 0)) {
      const $rows: any = $('tbody tr', this.el);

      this.rowHeight = $rows.is(':visible') ? $rows.outerHeight() : $rows.getHiddenDimensions().outerHeight;
      this.gridHeight = 0;
      if ($rows && $rows.length) {
        for (let i = 0; i < $rows.length && i < this.maxVisibleRows; i++) {
          this.gridHeight += $($rows[i]).outerHeight();
        }
      }
    }
    /**
     * The rows per page quantity
     */
    this.rowsPerPage = this.DEFAULT_ROWS_PER_PAGE;
    if ((this.rowHeight as number) > 0) {
      this.rowsPerPage = (parseInt(this.height.toString()) / parseInt(this.rowHeight.toString()));
    }
  }

  /**
   * return true if column name starts with "phx-", in that case is a special column.
   */
  _specialColumns(columnId: any): boolean {
    return columnId.indexOf("phx-") === 0;
  }

  /**
   * Show/Hide the column if isn't a special column.
   */
  toggleColumn(columnId: any): void {
    if (this._specialColumns(columnId)) {
      return;
    }
    if (!this.currentVue._columns[columnId]) {
      return;
    }
    this._toggleColumn(columnId);
    this.currentVue._columns[columnId].visible = !this.currentVue._columns[columnId].visible;
  }

  /**
   * Performs the Show/Hide Column action.
   */
  _toggleColumn(columnId: any): void {
    let totalWidth = null;

    if (this._specialColumns(columnId)) {
      return;
    }
    if (!this.currentVue._columns[columnId]) {
      return;
    }
    if (this.minWidthMode) {
      this._resetWidthsHeadersColumns();
    }
    // Hide
    if (this.currentVue._columns[columnId].visible) {
      // Two types of 'hide' method depending browser (reason: rendering problems in Chrome browser)
      this.currentVue._columns[columnId].hide();
      //apply accessibility visibility
      _.each(this.currentVue._columns[columnId].cells, (cell: any) => {
        cell.$el.attr("aria-hidden", true);
      }, this);
      if (!this.minWidthMode) {
        // Reduce the table min-width
        totalWidth = this.$el.find(".c-grind__scroll table").css("min-width");
        if (!totalWidth) {
          totalWidth = "0px";
        }
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) - this.currentVue._columns[columnId].width;
        this._setTableMinWidth(totalWidth);
      } else {
        totalWidth = this.$el.find(".c-grind__scroll table").css("min-width");
        if (!totalWidth) {
          totalWidth = "0px";
        }
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) - this.currentVue._columns[columnId].minWidth;
        this._setTableMinWidth(totalWidth);
      }
    } else { // Show
      // Two types of 'show' method depending browser (reason: rendering problems in Chrome browser)
      this.currentVue._columns[columnId].show();
      //apply accessibility visibility
      _.each(this.currentVue._columns[columnId].cells, (cell: any) => {
        cell.$el.attr("aria-hidden", false);
      }, this);
      if (!this.minWidthMode) {
        // Reduce the table min-width
        totalWidth = this.$el.find(".c-grind__scroll table").css("min-width");
        if (!totalWidth) {
          totalWidth = "0px";
        }
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) + this.currentVue._columns[columnId].width;
        this._setTableMinWidth(totalWidth);
        //			this.$el.find("table").css("min-width",totalWidth+"px");
      } else {
        totalWidth = this.$el.find(".c-grind__scroll table").css("min-width");
        if (!totalWidth) {
          totalWidth = "0px";
        }
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) + this.currentVue._columns[columnId].minWidth;
        this._setTableMinWidth(totalWidth);
      }
    }
  }

  _resetWidthsHeadersColumns(): void {
    $.each(this.currentVue._columns, (key: any) => {
      this.currentVue._columns[key].header.$el.width("100%");
      this.currentVue._columns[key].header.$el.find("> .ui-resizable").width("100%").css("min-width", this.currentVue._columns[key].minWidth);
      this.currentVue._columns[key].headerFake.$el.width("100%").css("min-width", this.currentVue._columns[key].minWidth);
    });
  }

  _unlockColumn(columnId: any): void {
    if (this.currentVue._columns[columnId] && !this.currentVue._columns[columnId].visible) {
      this.model.trigger("toggle:column", columnId);
    }
  }

  /**
   * If the column is visible when lock it, perform a toggle to hide it.
   */
  _lockColumn(columnId: any): void {
    if (this.currentVue._columns[columnId] && this.currentVue._columns[columnId].visible) {
      this.model.trigger("toggle:column", columnId);
    }
  }
  /**
   * Scroll the grid to the row of the selected index.
   */
  _scrollToIndex(index: number): void {
    const $scrollDiv = $(".c-grind__scroll", this.el);
    let counter = 0;
    const interval = setInterval(() => {
      counter++;
      if (($scrollDiv.is(":visible") || counter > 5) && !CWSTR.isBlank(this.rowHeight)) {
        let indexPag = index;
        const $listeRows = this.$el ? this.$el.find("table .c-grind__table__body tr") : null;

        clearInterval(interval);
        if (!CWSTR.isBlank(this.paginatorPosition) && this.paginatorPosition !== "none" && this.paginator?.coll?.paginated === true) {
          if ($listeRows.length > 0 && index > $listeRows.length && !CWSTR.isBlank(this.paginator?.model?.actualPage) && !CWSTR.isBlank(this.paginator.model.itemsPerPage)) {
            indexPag -= (this.paginator.model.actualPage * this.paginator.model.itemsPerPage);
          }
          if (indexPag > 6 && $listeRows && $listeRows.length > 0 && indexPag < $listeRows.length) {
            let valHeight = 0;

            for (let i = 0; i < indexPag; i++) {
              valHeight += $($listeRows[i]).height();
            }
            if (valHeight < 0) {
              valHeight = 0;
            }
            $scrollDiv.scrollTop(valHeight);
          } else {
            $scrollDiv.scrollTop(indexPag * parseInt(this.rowHeight.toString()));
          }
        } else {
          $scrollDiv.scrollTop(indexPag * parseInt(this.rowHeight.toString()));
        }
      }
    }, 300);
  }

  /**
   * Scroll the grid to the row of the selected index if is not visible.
   */
  _scrollToIndexIfHidden(indexArg: number, increment: number): void {
    const index = indexArg + increment;// recalculate index
    const rowTopOffset = this.$el.find("tr.c-grind__table__row").eq(index).offset().top;
    const tableTopOffset = this.$el.find(".c-grind__scroll").offset().top;
    const tableHeight = this.$el.find(".c-grind__scroll").height();
    const rowIndex = (this.$el.find("tr.c-grind__table__row").eq(index)[0] as any).rowIndex;
    const extraHeight = this.$el.find(".c-grind__scrollExtra1").height();
    const rowNumber = Math.floor((extraHeight) / parseInt(this.rowHeight.toString())) + rowIndex;

    if (increment < 0 && rowTopOffset < tableTopOffset) {
      $(".c-grind__scroll", this.el).scrollTop((rowNumber - 1) * parseInt(this.rowHeight.toString()));
    } else if (increment > 0 && (rowTopOffset + parseInt(this.rowHeight.toString())) > (tableHeight + tableTopOffset)) {
      const currentScroll = $(".c-grind__scroll", this.el).scrollTop();
      $(".c-grind__scroll", this.el).scrollTop(currentScroll + parseInt(this.rowHeight.toString()));
    }
  }

  /**
   * Show/Hide table footer.
   */
  setTotalRowsVisible(visible: boolean): void {
    if (visible) {
      $(".c-grind_paginationContainer", this.el).show();
    } else {
      $(".c-grind_paginationContainer", this.el).hide();
    }
  }

  /**
   * Render the button bar to change vue selected
   */
  _renderVuesButtonBar(): void {
    if (this.showButtonsMultipleVues) {
      _.each(_.keys(this.vues), (key: any) => {
        if (!CWSTR.isBlank(this.vues[key].buttonText)) {
          this.vuesButtonBar.addButton(this.vues[key].buttonText, key, false, null, null);
          this.vuesButtonBar.setButtonVisibleInModeC(key, true);
        } else {
          throw new Error("buttonText must be defined in vues when showButtonsMultipleVues is true");
        }
      });
      $(".c-grind__titleBar__barAction", this.el).prepend(this.vuesButtonBar.render().el);
      this.vuesButtonBar.$el.css("display", "inline-block");
      if (this.buttonBar) {
        this.buttonBar.$el.css("display", "inline-block");
      }
      //Hide buttons
      this.model.vuesBtnBarModel.trigger("hab.hide:new");
      this.model.vuesBtnBarModel.trigger("hab.hide:copy");
      this.model.vuesBtnBarModel.trigger("hab.hide:delete");
      this.model.vuesBtnBarModel.trigger("hab.hide:save");
      this.model.vuesBtnBarModel.trigger("hab.hide:revert");
      _.each(_.keys(this.vues), (key: any) => {
        if (this.currentVue === this.vues[key]) {
          this.model.vuesBtnBarModel.trigger("hab.hide:" + key);
        }
      });
      //Liste to
      this.model.vuesBtnBarModel.off("btn:click");
      this.model.vuesBtnBarModel.on("btn:click", this._vueButtonBarAction, this);
    }
  }

  _vueButtonBarAction(buttonId: any): void {
    this.model.trigger("buttonVueChanged:click", buttonId);
  }

  refreshVueColumns(vueName: any, tblColumns: any): void {
    // Remove tables with empty titles
    this.vues[vueName].columns = _.filter(tblColumns, (item: any) => {
      return item.title !== "" || this._specialColumns(item.property);
    });
    if (vueName === this.currentVue.vueName) {
      this.reRenderHead();
      this._toggleVuesButtons();
      this._toggleVuesLevier();
    }
    //Hide header for columns that are not visible
    _.each(this.vues[vueName]._columns, (column: any, key: any) => {
      const columnDefinition = _.find(this.vues[vueName].columns, (item: any) => {
        return item.property === key;
      });

      if (!columnDefinition && !this._specialColumns(key)) {
        this.vues[vueName]._columns[key].remove();
        delete this.vues[vueName]._columns[key];
      } else if (column.visible === false) {
        column.hideHeader();
      }
    });
    this.positionHeader();
  }

  /**
   * Remove sorting arrow from all columns and
   * clear sorting params from collection
   */
  clearSorting(): void {
    const coll = this.model.coll;
    const keys = _.keys(this.currentVue._columns);

    if (!CWSTR.isBlank(coll.sortings)) {
      coll.sortings = {};
    }
    _.each(keys, (key: any) => {
      this.currentVue._columns[key].header.hideSortButton();
      this.currentVue._columns[key].header.$el.find(".title").removeClass("phx-state-sort");
      this.currentVue._columns[key].sort = null;
      this.currentVue._columns[key].sortable = false;
    });
  }

  /**
   * Manage the Sortig when click on a Column header
   * ordering by the columns and refreshing the table
   */
  _manageSorting(columnCode: string, callback: any, silent: boolean, collection: any): void {
    if (this.currentVue._columns[columnCode].sortable) {
      let coll = this.model.coll;
      let keys: { [key: string]: any } = null;

      if (!CWSTR.isBlank(collection)) {
        coll = collection;
      }
      this.currentVue._columns[columnCode].header.$el.find(".title").addClass("phx-state-sort");
      keys = _.difference(_.keys(this.currentVue._columns), [columnCode]);
      _.each(keys, (key: any) => {
        this.currentVue._columns[key].header.hideSortButton();
        this.currentVue._columns[key].header.$el.find(".title").removeClass("phx-state-sort");
      });
      if (!_.isNull(this.currentVue._columns[columnCode].sort)) {
        let valueSort: any = null;

        coll.sortings = {};
        //Look if exist customized column codes for sort / support arrays of sortable columns
        valueSort = this.currentVue._columns[columnCode].sort;
        if (!CWSTR.isBlank(this.currentVue._sortableCustomNames[columnCode])) {
          const singular = !_.isArray(this.currentVue._sortableCustomNames[columnCode]);
          const sortableColumnCode = singular ? [this.currentVue._sortableCustomNames[columnCode]] : _.clone(this.currentVue._sortableCustomNames[columnCode]);

          for (let i = 0, l = sortableColumnCode.length; i < l; i++) {
            coll.sortings[sortableColumnCode[i]] = valueSort;
          }
        } else {
          coll.sortings[columnCode] = valueSort;
        }
        if (this.sortingUseAllColumn) {
          for (const columnName in this.defaultSortingOrder) {
            if (this.defaultSortingOrder[columnName] !== columnCode && this.defaultSortingOrder[columnName] !== this.currentVue._sortableCustomNames[columnCode] && !this.currentVue._sortableCustomNames[columnCode].includes(this.defaultSortingOrder[columnName])) {
              coll.sortings[this.defaultSortingOrder[columnName]] = true;
            }
          }
        }
        coll.fetch({
          success: (result: any) => {
            if (result.models.length > 0 && silent !== true) {
              if (this.selectInSorting === true) {
                this.model.selectRow(result.models[0]);
              } else {
                if (this.model.get("value") && this.model.get("value").get("id")) {
                  const oldRow = this.rows.find((item: { [key: string]: any }) => item.model.get("id") === this.model.get("value").get("id"));

                  if (oldRow) {
                    oldRow.$el.addClass("phx-selected");
                  }
                }
              }
            }
            if (callback) {
              callback(result);
            }
          },
          silent: silent
        });
      }
    }
  }

  /**
   * Set the text of a column
   *
   */
  setColumnTitle(property: any, title: string): void {
    const thCell = this.currentVue._columns[property].header;

    if (thCell) {
      thCell.setHeaderTitle(title);
    }
    if (this.columnsChooserView && this.columnsChooserView instanceof CWColumnsChooserView) {
      this.columnsChooserView.setMenuItemTitle(property, title);
    }
  }

  private paginateTo(eventName: string): void {
    if (eventName.search("paginateTo:") === 0) {
      const eventParts = eventName.split("paginateTo:");
      const page = parseInt(eventParts[1]);

      if (!CWSTR.isBlank(page) && page >= 0) {
        const startIndex = page * this.model.coll.pagination.size;

        this.model.coll.goTo(startIndex, () => {
          const value = this.model.get("value");
          const newValue = this.model.coll.get(value);

          if (!CWSTR.isBlank(newValue)) {
            let lPos = null;

            $(".c-grind__scroll", this.el).focus();
            this.model._manageRowSelection(newValue);
            lPos = $(".c-grind__scroll", this.el).find(".phx-selected");
            if (lPos.length > 0) {
              this._scrollIntoView();
            } else {
              $(".c-grind__scroll", this.el).scrollTop(0);
            }
          } else {
            $(".c-grind__scroll", this.el).scrollTop(0);
          }
        });
      }
    }
  }

  private _manageVisibilityPaginator(event: string): void {
    if (event === "hide" && this.deAtachParentPaginator.length === 0 && document.contains($(this.paginator.el)[0])) {
      this.deAtachParentPaginator[0] = $(this.paginator.el).parent();
      $(this.paginator.el).detach();
    } else if (event === "show" && this.deAtachParentPaginator.length > 0) {
      this.deAtachParentPaginator[0].append($(this.paginator.el));
      this.deAtachParentPaginator = Array<JQuery>();
    }
  }

  private _calculateMarginTitleBar(): number {
    const margin = $(".c-grind__titleBar", this.el).eq(0).outerHeight(true);

    $(".c-grind__headerScroll", this.el).css("margin-top", margin);
    return margin;
  }

  private _changeWidthColumn(nameColumn: string, widthColum: validsWidthTypesForGridsColums): void {
    if (!CWSTR.isBlank(nameColumn) && _.isNumber(widthColum)) {
      const colSelect = this.currentVue._columns[nameColumn];

      if (!_.isEmpty(colSelect)) {
        const widthAnt = colSelect.width;
        const widthNew: validsWidthTypesForGridsColums = (widthColum > 0 && widthColum < 13) ? widthColum : 1;
        const numColumn = _.findIndex(this.currentVue.columns, { "property": "affprincipale.itemlib" });
        const $findCol = this.$el.find("td.phx-td" + numColumn);

        if (widthAnt !== widthNew) {//s'ils sont differents, du coup on fait les modifications
          //on change l'information de la longueur
          colSelect.width = widthNew;
          this.currentVue.columns[numColumn].width = widthNew;
          //on change l'entête
          colSelect.header.$el.removeClass("col-" + widthAnt).addClass("col-" + colSelect.width);
          if (!CWSTR.isBlank(numColumn) && $findCol && $findCol.length > 0) {
            //On change les lignes de la grille
            $findCol.removeClass("col-" + widthAnt).addClass("col-" + colSelect.width);
          }
        }
      }
    }
  }

  _maximizeColumn(nameCol: string, widthSplitA?: number): void {
    if (!CWSTR.isBlank(nameCol) && !_.isEmpty(this.vues?.CURRENT?._columns[nameCol])) {
      const colTemp = this.vues?.CURRENT?._columns[nameCol];
      let classAnt = ((colTemp.width || 0) > 0) ? "col-" + colTemp.width : null;
      const classMax = "col-12";
      const existWidth = _.isNumber(widthSplitA);
      const newWidth = existWidth ? (widthSplitA - 63) : 0; //(-63) pour enlever la zone de paddings
      let NotchangePage = true;

      if (colTemp.cells && colTemp.cells.length > 0) {
        for (let i = 0; i < colTemp.cells.length; i++) {
          const lPos = colTemp.cells[i].$el.find("div");

          //par l'action de changement de page, la vlaeur de la "class" peut être la première
          if (NotchangePage && !CWSTR.isBlank(colTemp.widthOrigine) && colTemp.cells[i]?.$el && colTemp.cells[i].$el.hasClass("col-" + colTemp.widthOrigine)) {
            NotchangePage = false;//uniquement on fera cette vérification une fois
            classAnt = "col-" + colTemp.widthOrigine;
          }
          if (!CWSTR.isBlank(classAnt) && classMax !== classAnt) {
            if (colTemp.cells[i]?.$el && !CWSTR.isBlank(classAnt)) {
              colTemp.cells[i].$el.removeClass(classAnt);
            }
            colTemp.cells[i].$el.addClass(classMax);
          }
          if (existWidth) {
            colTemp.cells[i].$el.find("div").css("width", newWidth + "px");
          } else if (!CWSTR.isBlank(colTemp.cells[i].$el.attr("width"))) {
            colTemp.cells[i].$el.find("div").css("width", "");
          }
          //pour l'infobulle
          if (lPos && lPos.length > 0) {
            if (lPos[0].scrollWidth > lPos[0].clientWidth) {
              lPos.attr("title", lPos.text());
              lPos.data("titleMaxCol", true);
            } else if (lPos.data("titleMaxCol") === true) {//uniquement on removera le title de maxCol. si l'il y a autre, il sera affiché
              lPos.attr("title", "");
            }
          }
        }
        if (colTemp.header?.colWidth && !CWSTR.isBlank(classAnt) && classMax !== classAnt) {
          colTemp.header.colWidth = 12;
          if (colTemp.header.$el && !CWSTR.isBlank(classAnt)) {
            colTemp.header.$el.removeClass(classAnt);
          }
          colTemp.header.$el.addClass(classMax);
        }
        //Uniquement la première fois
        if (CWSTR.isBlank(colTemp.widthOrigine)) {
          colTemp.widthOrigine = colTemp.width;
        }
        colTemp.width = 12;
      }
      this._calculateRowsPerPage(true);
    }
  }

  _resetColumn(nameCol: string): void {
    if (!CWSTR.isBlank(nameCol) && !_.isEmpty(this.vues?.CURRENT?._columns[nameCol])) {
      const colTemp = this.vues?.CURRENT?._columns[nameCol];
      const classAnt = ((colTemp.widthOrigine || 0) > 0) ? "col-" + colTemp.widthOrigine : null;
      const classMax = "col-12";

      if (colTemp.cells && colTemp.cells.length > 0) {
        for (let i = 0; i < colTemp.cells.length; i++) {
          const lPos = colTemp.cells[i].$el.find("div");

          if (!CWSTR.isBlank(classAnt) && classMax !== classAnt) {
            if (colTemp.cells[i]?.$el && !CWSTR.isBlank(classMax)) {
              colTemp.cells[i].$el.removeClass(classMax);
            }
            colTemp.cells[i].$el.addClass(classAnt);
          }
          colTemp.cells[i].$el.find("div").css("width", "");
          //pour l'infobulle
          if (lPos && lPos.length > 0) {
            if (lPos[0].scrollWidth > lPos[0].clientWidth) {
              lPos.attr("title", lPos.text());
              lPos.data("titleMaxCol", true);
            } else if (lPos.data("titleMaxCol") === true) {//uniquement on removera le title de maxCol. si l'il y a autre, il sera affiché
              lPos.attr("title", "");
            }
          }
        }
        if (colTemp.header.$el && !CWSTR.isBlank(classMax)) {
          colTemp.header.$el.removeClass(classMax);
        }
        colTemp.header.$el.addClass(classAnt);
        if (!CWSTR.isBlank(colTemp.widthOrigine)) {
          colTemp.header.colWidth = colTemp.widthOrigine;
          colTemp.width = colTemp.widthOrigine;
        }
      }
      this._calculateRowsPerPage(true);
    }
  }

  _infobulleFirstcol(): void {
    if (!_.isEmpty(this.vues?.CURRENT?.columns)) {
      const colNameTemp = this.vues?.CURRENT?.columns[0];
      const colTemp = this.vues?.CURRENT?._columns[colNameTemp?.property];

      if (colTemp.cells && colTemp.cells.length > 0) {
        for (let i = 0; i < colTemp.cells.length; i++) {
          const lPos = colTemp.cells[i].$el.find("div");

          //Vérifier si l'il faut afficher une infobulle ou pas avec le texte complete du champ, uniqument pour la première colonne
          if (lPos && lPos.length > 0) {
            if (lPos[0].scrollWidth > lPos[0].clientWidth) {
              lPos.attr("title", lPos.text());
              lPos.data("titleMaxCol", true);
            } else if (lPos.data("titleMaxCol") === true) {//uniquement on removera le title de maxCol. si l'il y a autre, il sera affiché
              lPos.attr("title", "");
            }
          }
        }
      }
    }
  }

  showTable(etat: boolean): void {
    if (_.isBoolean(etat)) {
      const $lPos = this.$el.find(".c-grind__headerScroll__table");

      if ($lPos && $lPos.length > 0) {
        if (etat) {
          $lPos.show();
        } else {
          $lPos.hide();
        }
      }
    }
  }

  disableSortableColumn(columnName: string, val: boolean, vueName?: string): void {
    if (!CWSTR.isBlank(columnName) && _.isBoolean(val)) {
      const coll = this.model.coll;
      const lvue = CWSTR.isBlank(vueName) ? this.currentVue : ((!_.isEmpty(this.vues) && this.vues[vueName]) ? this.vues[vueName] : null);

      if (!CWSTR.isBlank(coll.sortings) && !CWSTR.isBlank(lvue._sortableCustomNames[columnName])) {
        delete coll.sortings[lvue._sortableCustomNames[columnName]];
      }
      if (!_.isEmpty(lvue?._columns) && lvue._columns[columnName]) {
        lvue._columns[columnName].sortable = !val;//l'information doit être le contraire
        if (val === true) {
          lvue._columns[columnName].sort = null;//cas spécial: s'il s'agit de la colonne active pour trier, elle sera désactivée
          lvue._columns[columnName].header.hideSortButton();
          lvue._columns[columnName].header.$el.find(".title").removeClass("phx-state-sort");
        } else {
          lvue._columns[columnName].header.showSortButton();
          //ce n'est pas nécessaire faire addClass("phx-state-sort")
        }
      }
    }
  }
}
