import { ReadOnlyModel } from 'core/models/readOnly.model.js';

export var ParametresDiversModel = ReadOnlyModel.extend({

  /**
   * habilitationV
   */
  habilitationV: "N",
  /**
   * habilitationG
   */
  habilitationG: "N",
  /**
   * usecase
   */
  usecase: "app",

  /**
   * Default data model
   *	code: "",
   *	libelle: null,
   *	valeur: null
   *}
   */
  defaults: {
    code: "",
    libelle: null,
    valeur: null
  },

  /**
   * Constructor
   * Parametres Divers Model
   */
  initialize: function() {
    this.fetched = true;
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && response.code) {
      this.id = response.code; //In this way the id is not created as attribute
    }
    return response;
  },

  /**
   * Fetches data if it hasn't been fetched yet
   */
  fetch: function(options) {
    if (!this.fetched) {
      options = options || {}; // eslint-disable-line
      options.success = (function(callback, self) {
        return function(model, response, options) {
          self.fetched = true;
          if (callback) {
            callback(model, response, options);
          }
        };
      })(options.success, this);
      return ReadOnlyModel.prototype.fetch.call(this, options);
    } else {
      if (options && options.success) {
        options.success(this, this.toJSON(), options);
      }
    }
    return $.Deferred().resolve();
  },

  /**
   * This object can't be cleared. So this method doesn't do anything
   *
   */
  clear: function() {
    // This object can't be cleared.
  }
});
