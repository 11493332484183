module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=' <div id="cw-menu-item-title-'+
((__t=(emid))==null?'':__t)+
'" class="'+
((__t=(emid))==null?'':__t)+
' cw-menu-item-title ctime-menu-submenu-title d-flex justify-content-between align-items-center" aria-haspopup="true" tabindex="'+
((__t=(tabindex))==null?'':__t)+
'" data-value="'+
((__t=(index))==null?'':__t)+
'" data-toggle="collapse" data-target="#cw-menu-item-body-'+
((__t=(emid))==null?'':__t)+
'" aria-expanded="false" aria-controls="cw-menu-item-body-'+
((__t=(emid))==null?'':__t)+
'" role="button">\n     <span class="cw-menu-element d-flex align-items-center flex-grow-1">\n         <span class="d-flex cw-menu-item-icon flex-shrink-0">'+
((__t=(icone))==null?'':__t)+
'</span>\n         <span class="ctime-menu-item-libelle ml-3">'+
((__t=(nom))==null?'':__t)+
'</span>\n     </span>\n     <span class="mr-4 cw-menu-arrow cw-chevron-icon">\n        \n    </span>\n </div>\n <div id="cw-menu-item-body-'+
((__t=(emid))==null?'':__t)+
'" class="collapse cw-menu-group" aria-labelledby="cw-menu-item-title-'+
((__t=(emid))==null?'':__t)+
'" data-parent="#ctime-menu-zone-'+
((__t=(zone))==null?'':__t)+
'">\n </div>\n';
}
return __p;
};
