module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span id="phx-menus-transversal-right" class="phx-menu-header-buttons mr-5">\n    <span tabindex="0" class="phx-menus-transversal-refresh" title="'+
((__t=(i18n.t('common:refresh')))==null?'':__t)+
'">\n        '+
((__t=(UTILS.getSVGIcon('actualiser','',20)))==null?'':__t)+
'\n    </span>\n    <span tabindex="0" class="phx-menus-transversal-print" title="'+
((__t=(i18n.t('common:print')))==null?'':__t)+
'">\n        '+
((__t=(UTILS.getSVGIcon('imprimante','',20)))==null?'':__t)+
'\n    </span>\n    <span tabindex="0" class="phx-menus-transversal-personnaliser" title="'+
((__t=(i18n.t('common:personnaliser')))==null?'':__t)+
'">\n        '+
((__t=(UTILS.getSVGIcon('engrenage','',20)))==null?'':__t)+
'\n    </span>\n    <span tabindex="0" class="phx-menus-transversal-calend" title="'+
((__t=(i18n.t('common:calendar')))==null?'':__t)+
'">\n        '+
((__t=(UTILS.getSVGIcon('calendrier','',20)))==null?'':__t)+
'\n    </span>\n    <span tabindex="0" class="phx-menus-transversal-help" title="'+
((__t=(i18n.t('common:help')))==null?'':__t)+
'">\n        '+
((__t=(UTILS.getSVGIcon('aide','',20)))==null?'':__t)+
'\n    </span>\n    <span tabindex="0" class="phx-menus-transversal-swagger" title="'+
((__t=(i18n.t('common:swagger')))==null?'':__t)+
'">\n      '+
((__t=(UTILS.getSVGIcon('swagger','',20)))==null?'':__t)+
'\n    </span>\n</span>\n';
}
return __p;
};
