import { BaseGridModel } from '../basegrid/baseGrid.model.js';

export var GroupeGridModel = BaseGridModel.extend({

  /**
   * Constructor
   * Model underlying a Groupe type DataGrid
   */
  initialize: function(options) {
    BaseGridModel.prototype.initialize.call(this, options);
  }

});
