module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc" data-bloc-name="PLH" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.heures')))==null?'':__t)+
'">\n\n    <div class="heure form-row">\n        <div class="form-group col">\n            <label for="heuredeb" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.heures_de")))==null?'':__t)+
'</label>\n            <div class="input-group">\n                <div class="input-group-prepend">\n                    <div for="heuredeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.heures_de")))==null?'':__t)+
'</div>\n                </div>\n                <input class="heuredeb form-control typeHourMinuteNightly" value="" type="text" size="12" />\n            </div>\n        </div>\n\n        <div class="form-group col">\n            <label for="heurefin" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.heures_a")))==null?'':__t)+
'</label>\n            <div class="input-group">\n                <div class="input-group-prepend">\n                    <div for="heurefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.heures_a")))==null?'':__t)+
'</div>\n                </div>\n                <input class="heurefin form-control typeHourMinuteNightly" value="" type="text" size="12" />\n            </div>\n        </div>\n\n        <div class="form-group col">\n            <label for="duree" class="sr-only">'+
((__t=(i18n.t("common:gereractivites.heures_soit")))==null?'':__t)+
'</label>\n            <div class="input-group">\n                <div class="input-group-prepend">\n                    <div for="dureecalculee" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereractivites.heures_soit")))==null?'':__t)+
'</div>\n                </div>\n                <input class="dureecalculee form-control typeDurationHM" readonly="readonly" value="" type="text" size="12" />\n            </div>\n        </div>\n    </div>\n</div>\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="DUREE" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.duree')))==null?'':__t)+
'">\n    <input type="text" class="duree form-control typeDuration24HM" size="6">\n</div>\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="UNITE" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.unite')))==null?'':__t)+
'">\n    <label for="unitedeb.code" class="sr-only"></label>\n    <span class="comboUnites cw-required"></span>\n</div>\n<div class="cw-multiLabel-bloc form-group cw-customControlGroup" data-bloc-name="PRES" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.presence')))==null?'':__t)+
'">\n    <div class="custom-control custom-checkbox">\n        <input type="checkbox" class="presence custom-control-input" value="true" readonly="readonly">\n        <label for="presence" class="label-presencehorspresence custom-control-label"></label>\n    </div>\n</div>\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="POURCENT" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.tempeff')))==null?'':__t)+
'">\n    <input type="text" class="pourcentage typePercentage_0 form-control" size="10">\n    <label for="pourcentage" class="sr-only"></label>\n</div>\n<div class="cw-multiLabel-bloc form-group cw-customControlGroup" data-bloc-name="RELIQUAT" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.reliquat')))==null?'':__t)+
'">\n    <div class="custom-control custom-checkbox">\n        <input type="checkbox" class="horspresence custom-control-input" value="true" readonly="readonly">\n        <label for="horspresence" class="custom-control-label">'+
((__t=(i18n.t('common:gereractivites.duJour')))==null?'':__t)+
'</label>\n    </div>\n</div>\n<div class="cw-multiLabel-bloc form-group cw-customControlGroup" data-bloc-name="HORSPRES" data-bloc-label="'+
((__t=(i18n.t('common:gereractivites.horspres')))==null?'':__t)+
'">\n    <div class="custom-control custom-checkbox">\n        <input type="checkbox" class="horspresav custom-control-input" value="true">\n        <label for="horspresav" class="custom-control-label">'+
((__t=(i18n.t('common:gereractivites.avant')))==null?'':__t)+
'</label>\n    </div>\n    <div class="custom-control custom-checkbox">\n        <input type="checkbox" class="horspresap custom-control-input" value="true">\n        <label for="horspresap" class="custom-control-label">'+
((__t=(i18n.t('common:gereractivites.apres')))==null?'':__t)+
'</label>\n    </div>\n</div>';
}
return __p;
};
