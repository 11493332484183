import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererrecapitulatifsGeneralView from '../generalView.tpl.html';
import { CWGererRecapitulatifsFormView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwGererRecapitulatifForm.view';
import { CWGererRecapitulatifsHeaderView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwGererRecapitulatifsHeader.view';
import { CWGererrecapitulatifsWorkflow } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/models/cwGererrecapitulatifs.workflow';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWListeDetailPanelView } from 'src/core/layouts/cwListeDetailPanel.view';
import { CWMixteTypeView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwMixteType.view';
import { CWMonoTypeView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwMonoType.view';
import { CWSTR } from 'src/utils/cwStr';
import { objs } from 'src/objectsRepository';

/**
 * GeneralView of liste recapitulatifs
 */
export class CWGererRecapGeneralView extends Backbone.View {

  ASCENSDANT: string;
  COLLABORATEUR: string;
  context: { [key: string]: any };
  DESCENDANT: string;
  form: CWGererRecapitulatifsFormView;
  header: CWGererRecapitulatifsHeaderView;
  layout: CWListeDetailPanelView;
  mixteView: CWMixteTypeView;
  monoTypeView: CWMonoTypeView;
  RESPONSABLE: string;
  workflow: CWGererrecapitulatifsWorkflow;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.className = options.className || "gerer_recap_activite";
    super(options);
    this.template = TPLGererrecapitulatifsGeneralView;
    this.COLLABORATEUR = "Collaborateur";
    this.RESPONSABLE = "Responsable";
    this.ASCENSDANT = "Ascendant";
    this.DESCENDANT = "Descendant";
    this.context = options.context || {};
    // we need action possibles for this screen. (to activate buttons)
    if (CWSTR.isBlank(this.context.ctxActionsPossibles) && objs.appRt.workflow.get("usecase") === "valevt") {
      this.context.ctxActionsPossibles = this._buildContextActionsDrecap();
    }
    this.workflow = new CWGererrecapitulatifsWorkflow(options);
    this._initializeAllViews();
    this.listenTo(this.workflow, "tableDataLoaded", this._tableDataLoaded);
    this.listenTo(this, "showhandle", this._showResizableHandle);
    this.listenTo(this, "hidehandle", this._hideResizableHandle);
  }

  _buildContextActionsDrecap(): Array<string> {
    const ctxActionsPossibles = ["Valider", "Controler", "Soumettre", "Modifier"];

    // Action possibles.
    return ctxActionsPossibles;
  }

  /**
   * Initializes all tree views and their models
   */
  _initializeAllViews(): void {
    this.layout = new CWListeDetailPanelView({ position: 34, defaultOpeningSize: "50/50" });
    this.header = this._initHeaderView();
    this.workflow.headerModel = this.header.model;
    this.form = this._initForm();
    this.workflow.formModel = this.form.model;
    this.monoTypeView = this._initMonoTypeView();
    this.workflow.monoTypeModel = this.monoTypeView.model;
    this.workflow.monoTableModel = this.monoTypeView.table.model;
    this.workflow.tableMonoColl = this.monoTypeView.table.model.coll;
    this.mixteView = this._initMixteView();
    this.workflow.mixteModel = this.mixteView.model;
    this.workflow.mixteTableModel = this.mixteView.table.model;
    this.workflow.tableMixteColl = this.mixteView.table.model.coll;
  }

  _initForm(): CWGererRecapitulatifsFormView {
    const form = new CWGererRecapitulatifsFormView({ id: "gererrecap_form", workflow: this.workflow, context: this.context, parent: this });

    return form;
  }

  _initHeaderView(): CWGererRecapitulatifsHeaderView {
    const header = new CWGererRecapitulatifsHeaderView({ id: "header_form", workflow: this.workflow, context: this.context, parent: this });

    return header;
  }

  _initMonoTypeView(): CWMonoTypeView {
    const monoTypeView = new CWMonoTypeView({ id: "mono_type", workflow: this.workflow });

    return monoTypeView;
  }

  _initMixteView(): CWMixteTypeView {
    const mixte = new CWMixteTypeView({ id: "mixte", workflow: this.workflow });

    return mixte;
  }

  /**
   * Loads data and paints the view
   */
  render(): CWGererRecapGeneralView {
    let $posDiv: JQuery = null;

    $(this.el).append(this.template());
    this.$el.find(".gererrecapitulatifs-Header-Zone").html(this.header.render().el);
    $(this.el).find(".gererrecapitulatifs-Layout-Zone").html(this.layout.el);
    this.layout.render();
    $(this.layout.el).find(".l-panelA-liste").html(this.monoTypeView.render().el);
    $(this.layout.el).find(".l-panelA-liste").append(this.mixteView.render().el);
    $(this.layout.el).find(".l-panelB-content").html(this.form.render().el);
    this.form.$el.find(".activites-form").hide();
    this.form.$el.find(".absences-form").hide();
    $(this.layout.el).find(".l-panelA-filter").hide();
    this.layout.model.set("position", 50);
    this.mixteView.$el.hide();
    this.monoTypeView.$el.hide();
    this.$el.find(".phx-splitAcell").css("background", "none").css("border-top", "none");
    if (!CWSTR.isBlank(this.context)) {
      if (this.context.ctxEcran === 'mactivites') {
        this.$el.find(".gererrecapitulatifs-dialog").removeClass('suivicollabScreen').addClass('mactiviteScreen').prop('tabindex', 1).focus();
      } else {
        this.$el.find(".gererrecapitulatifs-dialog").removeClass('mactiviteScreen').addClass('suivicollabScreen').prop('tabindex', 1).focus();
      }
    }
    //cacher les titles avec le texte "Aucune..."
    this.$el.find(".tableMonoType .c-grind__titleBar_text").hide();
    this.$el.find(".tableMixteType .c-grind__titleBar_text").hide();
    //cacher la zone de pagination
    this.$el.find(".tableMonoType .c-grind-upperElements").removeClass("pb-5");
    this.$el.find(".tableMixteType .c-grind-upperElements").removeClass("pb-5");
    $posDiv = this.$el.find(".tableMonoType div:first");
    //Dans le mode "MONO", on doit ajouter un nouveau style
    if ($posDiv && $posDiv.length > 0) {
      $posDiv.css({ "height": "auto" });
    }
    if (this.context) {
      this.setContext(this.context);
    }
    return this;
  }

  /**
   * Sets the context and rerenders complete view
   */
  setContext(context: { [key: string]: any }): void {
    let habilitation = context.ctxUtilisateur === this.workflow.COLLABORATEUR ? "COL_RECAP.V" : "RES_RECAP.V";

    this.context = context;
    if (_.isObject(this.context.ctxHabilitation.HabilitationAcces)) {
      if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationAcces.recapitulatif)) {
        habilitation = this.context.ctxHabilitation.HabilitationAcces.recapitulatif;
      }
    }
    if (CWHABILITATION.canView(habilitation)) {
      const callback = (): void => {
        //Set fomr habilitation
        if (this.context.ctxEcran !== "mactivites") {
          this.workflow.trigger("contextchanged", context);
        } else {
          this.workflow.trigger("initializeCombos", context);
        }
      };

      this.workflow.setUp(context, callback);
    } else { //User doen't have  habilitations to see component
      this.$el.html(" ");
    }
    this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application      
    }
    this.$el.find(".ui-resizable-handle").hide();
  }

  _showResizableHandle(): void {
    this.$el.find(".ui-resizable-handle").show();
  }

  _hideResizableHandle(): void {
    this.$el.find(".ui-resizable-handle").attr("style", "background-color: transparent; display: none");
    this.$el.find(".ui-resizable-handle").hide();

  }

  _tableDataLoaded(callback: () => void): void {
    if (!CWSTR.isBlank(this.workflow.headerModel.get("value"))) {
      const representation = this.workflow.modeRepresentationModel.get("representation");

      if (representation === this.workflow.MONO) {
        this.layout.model.set("position", 50);
        this.mixteView.$el.hide();
        this.monoTypeView.$el.show();
        if (this.workflow.formModel.get("value") !== null) {
          this.form.$el.show();
        }
        this.monoTypeView.table.model.coll.trigger("reset");
        //Check if buttons can be managed
        this.monoTypeView._checkButtonsSupprimerEv();
        this.monoTypeView._checkHeadersCheckboxes();
        this.monoTypeView.findAndSelectFirstEvenement();
      } else if (representation === this.workflow.MIXTE) {
        this.layout.model.set("position", 100);
        this.layout.disableSplitter();
        this.monoTypeView.$el.hide();
        this.mixteView.$el.show();
        this.form.$el.hide();
        this.mixteView.table.model.coll.trigger("reset");
        //Check if buttons can be managed
        this.mixteView._checkButtonsSupprimerEv();
        this.mixteView._checkHeadersCheckboxes();
        this.mixteView.findAndSelectCurrentEvenement();
      }
    } else { //Current recap model has been deleted from header
      //Hide all views
      this.layout.model.set("position", 60);
      this.mixteView.$el.hide();
      this.monoTypeView.$el.hide();
      this.form.$el.hide();
    }
    if (callback) {
      callback();
    }
  }

  remove(): any {
    if (this.form) {
      this.form.remove();
      this.form = null;
    }
    if (this.header) {
      this.header.remove();
      this.header = null;
    }
    if (this.mixteView) {
      this.mixteView.remove();
      this.mixteView = null;
    }
    return super.remove();
  }
}
