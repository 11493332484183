import _ from 'underscore';
import { CWFamilleActiviteModel } from './cwFamilleActivite.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';

/**
 * Collection for referentiel of activity families
 */
export class CWFamilleActiviteColl extends CWPaginatedCollection<CWFamilleActiviteModel>{


  constructor(models?: CWFamilleActiviteModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWFamilleActiviteModel;
    super(models, options);    
    this.sortings = { libelle: true };
    this.url = (): string => {
      return Configuration.restRoot + "/rest/ls/familleactivite";
    }
  }
}
