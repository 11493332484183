import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

/**
 *  Model for loading the type hierarchy
 */
export var NiveauTypeHierarchieModel = BaseModel.extend({

  habilitationV: "PAR_ACTHIE.V",
  usecase: "activite",

  /**
   * Model for loading each activite
   */

  defaults: {
    "niveau": null,
    "libelle": "",
    "genre": "",
    "style": {
      "code": "",
      "libelle": "",
      "affichage": ""
    },
    "format": "",
    "transverse": "",
    "horsregr": "",
    "rechref": "",
    "saisieexp": "",
    "rechrexp": "",
    "grpcarpersodef": "",
    "grpcarpersodefhisto": "",
    "grpcarpersosai": ""
  },

  validate: function() {
    var errors = {};

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  parse: function(response) {
    if (response) {
      if (!STR.isBlank(response.code)) {
        response.id = response.code;
      }
      if (String(response.code) === "0") {
        response.id = "0";
      }
    }
    return response;
  }
});
