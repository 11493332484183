import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model of lancer etats in order to build the url to print excel or pdf
 */
export class CWLancerEtatModel extends CWBaseModel {
  code: any;
  thcollab: boolean;
  type: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options)
    this.url = (): string => {
      const thcollab = this.thcollab || false;
      return Configuration.restRoot + "/rest/edition/" + this.type + "/" + encodeURIComponent(this.code) + "?thcollab=" + thcollab;
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }

  removeTitleBirt(): void {
    const listAttr = Object.keys(this.attributes);
    const removeList = listAttr.filter((el: any): any => { return el.match(/_title_birt/gi); });
    for (let index = 0; index < removeList.length; index++) {
      delete this.attributes[removeList[index]];
    }
  }
}