import _ from 'underscore';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWPopulationModel } from './cwPopulation.model';

/**
 * Collection of populations
 */
export class CWPopulationColl extends CWPaginatedCollection<CWPopulationModel>{

  constructor(models?: Array<CWPopulationModel> | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWPopulationModel;
    super(models, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/population/menu";
    }
    this.sortings = { libelle: true };
    this.paginated = false;
  }
}