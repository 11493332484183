import * as Backbone from 'Backbone';
import TPLAgendaDialogPersoDeleteCriteria from '../dialog_perso_deleteCriteria.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from 'utils/cwStr';
import { CWSupprimerPreferenceModel } from '../models/cwSupprimerPreference.model';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';

export class CWPersoDeleteCriteriaDialog extends CWBaseFormView {

  workflow: any;
  parent: any;
  modePlanning: any;
  mode: any;
  RESPONSABLE_TYPE: any;
  habColl: boolean;
  habResp: boolean;
  usecase: string;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = {
      "click .options .bloq_user": "_checkButtonDelete",
      "click .options .bloq_allusers": "_checkButtonDelete"
    };
    super(options);
    this.template = TPLAgendaDialogPersoDeleteCriteria;
    this.modePlanning = false;
    if (options && !CWSTR.isBlank(options.modePlanning)) {
      this.modePlanning = options.modePlanning;
    }
    this.workflow = options.workflow;
    this.usecase = this.workflow.module || objs.appRt.workflow.get("usecase");
    this.habContext = new CWHabilitationContext({
      onglet: this.usecase,
      foncCour: "PER_PLANTOUS.G"
    });
    this.mode = 1;
    this.model = new CWBaseModel({});
    this.workflow = options.workflow;
    this.habColl = CWHABILITATION.canView("COL_AGHEBDO") || CWHABILITATION.canView("COL_AGMENS") || CWHABILITATION.canView("COL_AGANNU");
    this.habResp = CWHABILITATION.canView("RES_AGHEBDO") || CWHABILITATION.canView("RES_AGMENS") || CWHABILITATION.canView("RES_AGANNU");
    if (options.parent) {
      this.parent = options.parent;
      //set the title to the dialog
      this.parent._setTitle(i18n.t('agenda.dialog_persodeletecriteria_title'));
    }
    this.model.setHabContext(this.habContext);
    this.listenTo(this, "btnDelete", this._confirm);
    this.listenTo(this, "btnCancel", this._cancel);
  }

  render(): CWPersoDeleteCriteriaDialog {
    const json = { i18n: i18n };

    this.$el.append(this.template(json));
    this.$el.find("button").button();
    if (this.habColl && this.habResp) {
      this.$el.find(".dialogPersoDeleteCriteria-options").show();
      this.$el.find(".dialogPersoDeleteCriteria-options").show();
      this.$el.find(".dialogPersoDeleteCriteria-title-equipe").hide();
      this.$el.find(".dialogPersoDeleteCriteria-title-propre").hide();
      this.$el.parent().css("height", 255);
      if (this.workflow.get("utilisateur") !== this.workflow.RESPONSABLE_TYPE) { // Context Access Responsable
        this.$el.find('.del_user').attr('checked', 'true');
        this.$el.find('.del_allusers').attr('checked', 'false');
      } else {
        this.$el.find('.del_user').attr('checked', 'false');
        this.$el.find('.del_allusers').attr('checked', 'true');
      }
      this.mode = 3;
    } else {
      if (this.workflow.get("utilisateur") === this.workflow.RESPONSABLE_TYPE) {
        this.$el.find(".dialogPersoDeleteCriteria-title-propre").hide();
        this.mode = 2;
      } else {
        this.$el.find(".dialogPersoDeleteCriteria-title-equipe").hide();
        this.mode = 1;
      }
      this.$el.find(".dialogPersoDeleteCriteria-title-select").hide();
      this.$el.find(".dialogPersoDeleteCriteria .section-own").hide();
      this.$el.parent().css("height", 215);
    }
    return this;
  }

  _confirm(): void {
    let lOptions: { [key: string]: any } = {};
    let deleteModel: CWSupprimerPreferenceModel = null;

    if (this.workflow.get("utilisateur") === this.RESPONSABLE_TYPE || this.usecase === "agenda_R") {
      if (CWHABILITATION.canView("PER_PERSOGEN.M")) {
        lOptions = {
          contextCollab: this.$el.find('.del_user').is(":checked") ? 1 : 0,
          contextResp: this.$el.find('.del_allusers').is(":checked") ? 2 : 0
        };
      } else {
        lOptions = {
          contextCollab: 1,
          contextResp: 0
        };
      }
    } else {
      if (!this.habResp) {
        lOptions = {
          contextCollab: 1,
          contextResp: 0
        };
      } else {
        if (CWHABILITATION.canView("PER_PERSOGEN.M")) {
          lOptions = {
            contextCollab: this.$el.find('.del_user').is(":checked") ? 1 : 0,
            contextResp: this.$el.find('.del_allusers').is(":checked") ? 2 : 0
          };
        } else {
          lOptions = {
            contextCollab: 1,
            contextResp: 0
          };
        }
      }
    }
    lOptions.usecase = this.workflow.module;
    deleteModel = new CWSupprimerPreferenceModel(null, lOptions);
    deleteModel.set("id", ""); // ( dans la DCT il n'y a pas de ID, mais backbone needs an id.  )
    this.habContext = new CWHabilitationContext({
      onglet: this.workflow.module,
      foncCour: "PER_PERSOGEN.M",
      natGest: "S"
    });
    deleteModel.setHabContext(this.habContext);
    deleteModel.url = deleteModel.urlRoot();
    deleteModel.destroy({
      success: () => {
        const agendaWorkflow = this.workflow.preferences.workflow;

        this._cancel();
        this.workflow.preferences.fetch({ //vérifier
          success: (freshItem: any) => {
            agendaWorkflow.originalPreferences = freshItem.clone();
            agendaWorkflow.loadPreferences(agendaWorkflow.get("viewType"), true);
            agendaWorkflow.trigger("refresh:all", null, true);
          }
        });
      },
      error: () => {
        this._cancel();
      }
    });
  }

  _checkButtonDelete(): void {
    let isDisabled = true;

    if (this.$el.find(".bloq_user input").is(":checked") ||
      this.$el.find(".bloq_allusers input").is(":checked")) {
      isDisabled = false;
    }
    this.$el.parent().find(".cwDialog-buttons .bt-col-blue").prop({
      "disabled": isDisabled
    });
  }

  _cancel(): void {
    this.parent.close();
  }
}
