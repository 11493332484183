import * as Backbone from 'Backbone';
import TPLDroitsdelTooltipDeleg from '../tooltipDeleg.tpl.html';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';


export class CWTooltipDelegView extends Backbone.View {

  /**
   * Constructor
   * Toooltip for Fonc View
   */

  constructor(options: { [key: string]: any }) {
    super(options);
    this.template = TPLDroitsdelTooltipDeleg;
    if (options && options.model) {
      this.model = options.model;
    }
  }

  /**
   * Renders the view to the form
   */
  render(): CWTooltipDelegView {
    const model = this.model;
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));
    let resp = "";

    if (model.get("vue") === true && model.get("filtres")) {
      for (let i = 0; i < model.get("filtres").length; i++) {
        resp += model.get("filtres")[i].libelle;
        if (i < model.get("filtres").length - 1) {
          resp += "<br/>";
        }
      }
      $(this.el).find("span.filtres").html(resp);
      $(this.el).find("span.filtres-content").show();
    } else {
      $(this.el).find("span.filtres-content").hide();
    }

    if (CWSTR.isBlank(model.get("profilwkf"))) {
      $(this.el).find("span.profil-content").hide();
    } else {
      $(this.el).find("span.profil").html(model.get("profilwkf"));
      $(this.el).find("span.profil-content").show();
    }

    if (model.get("ajout") || model.get("modification") || model.get("suppression")) {
      resp = "";
      if (model.get("ajout")) {
        resp += i18n.t('droitsdel.creer') + ",";
      }
      if (model.get("modification")) {
        resp += i18n.t('droitsdel.modifier') + ",";
      }
      if (model.get("suppression")) {
        resp += i18n.t('droitsdel.supprimer') + ",";
      }

      resp = resp.substring(0, resp.length - 1);

      $(this.el).find("span.actions").html(resp);
      $(this.el).find("span.actions-content").show();
    } else {
      $(this.el).find("span.actions-content").hide();
    }

    return this;
  }
}