import TPL_selecteuractivites_selecteur_activite_item from '../selecteur_activite_item.tpl.html';

export var ResultItemView = Backbone.View.extend({

  /**
   * Name of the container class of the view
   */
  className: "phx-selecteur-activite-item ui-corner-all",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "span",

  /**
   * Event launched when the item is clicked
   *
   */

  events: {
    "click": "_removeItem"
  },

  /**
   * Constructor
   * View underlying a selecteur de chemin result item
   */
  initialize: function(params) {
    /**
     * Show periode mark or not
     */
    this.showPeriodeMark = false;

    /**
     * HTML template of the view
     */
    this.template = TPL_selecteuractivites_selecteur_activite_item;
    if (params && params.showPeriodeMark) {
      this.showPeriodeMark = params.showPeriodeMark;
    }
  },

  /**
   * Paints the row view of the selecteur de chemin result item
   */
  render: function() {
    var label = this.model.attributes.label;
    var text = "";
    if (this.showPeriodeMark === true) {
      text = "<span><span class=\"phx-selecteur-activite-tooltip\" title=\"\">(&#9679)</span>" + label + "</span>";
    } else {
      text = label;
    }

    $(this.el).html(this.template({ label: text }));
    return this;
  },

  /**
   * Method which removes an existing item
   */
  _removeItem: function() {
    this.model.trigger("click:item", this.model);
  }
});
