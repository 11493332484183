import _ from 'underscore';
import { CWDataGridRowView } from './cwDataGridRow.view';
import { CWSTR } from 'utils/cwStr';
import { Model, View, ViewOptions } from 'Backbone';


export class CWDataGridCellView extends View {

  dataGridRow: CWDataGridRowView;
  text: string | View | JQuery;

  /**
   * View of the Cells
   */
  constructor(options: ViewOptions<Model> | any) {
    options = options || {};
    options.tagName = "td";
    options.className = options.className || "";
    options.events = _.extend({
      "keydown .cw-triggerClick": "doClickInRenderedElements"
    }, options.events);
    super(options);
    if (!options.dataGridRow) {
      throw Error("You must use a DataGridCellView just inside a DataGridRowView");
    }
    this.dataGridRow = options.dataGridRow;
    this.text = options.text;
  }

  /**
   * Simulate a click when pressing "Enter", "Space" or "F2" key
   * in elements with class .cw-triggerClick
   * */
  private doClickInRenderedElements(key: JQueryKeyEventObject): void {
    if (key && (key.keyCode === 13 || key.keyCode === 32 || key.keyCode === 113)) { // enter or space or F2
      $(key.target).click();
      key.stopPropagation();
    }
  }

  /**
   * Sets the text attribute and renders the cell.
   */
  setText(text: string | JQuery | View): void {
    if (!CWSTR.isBlank(text)) {
      this.text = text;
      this.render();
    }
  }

  /**
   * The render function thats generate the TD element
   */
  render(): CWDataGridCellView {
    this.$el.empty();
    //Render a view inside the td
    if (this.text instanceof View) {
      // this.$el.css("padding", "0");
      this.$el.append(this.text.render().el);
    } else {
      const $container = $("<span>");

      if (this.dataGridRow.dataGrid.isEditableGridView === true) {
        $container.addClass('cwDataGrid__cellLine');
      }
      if (typeof this.text === "string" || typeof this.text === "number") {
        $container.append(this.text);
      } else if (this.text instanceof jQuery) {
        $container.append(this.text);
      } else if (this.text instanceof HTMLElement) {
        $container.append(this.text);
      }
      this.$el.append($container);
    }
    return this;
  }
}
