import _ from 'underscore';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';


export class CWTypeStructModel extends CWReadOnlyModel {

  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "libelle": "null"
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.version = "2.2.0";
    super(attributes, options);
    this.params = {};
    this.setHabContext(new CWHabilitationContext({
      onglet: options.module,
      foncCour: "N",
      natGest: ""
    }));
    this.url = (): string => {
      return Configuration.restRoot + "/rest/calmarqueurexp/typestruct"
    };
  }
}
