import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Prets Model
 */

export class CWAccepterPret extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "commentaire": "",
      "droit": "",
      "profil": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/pret/accepter/";
    }
  }
}