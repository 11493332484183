import { CWBaseModel } from './cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

/**
 * CWFilterBaseModel : base model to the filter
 *
 * need to implement the methode _initDefaultValues()
 */
export abstract class CWFilterBaseModel extends CWBaseModel {

  public savedValues: { [key: string]: any } | CWBaseModel | any;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
  }

  public restaureDefaultValues(): void {
    this.set(this.savedValues);
  }

  /** Initialise and store the default value if they are not initialized */
  protected _storeDefaultValues(): void {
    if (CWSTR.isUndefined(this.savedValues)) {
      this._initDefaultValues();
      this.savedValues = Object.assign({}, this.attributes);
    }
  }

  /**
   * Function call automaticaly by the function store.
   */
  protected _initDefaultValues(): void {
    // Init the model on this function.
  }
}
