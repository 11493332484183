import * as Backbone from 'Backbone';
import _ from 'underscore';
import CWSplitPanelTPL from './cwSplitPanel.tpl.html';
import { CWButtonBarView } from '../controls/cwButtonBar.view';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';


type CWSplitPannelDefaultModes = "0/100" | "100/0" | "50/50" | "33/66" | "25/75" | "custom";


export interface CWSplitPannelInterface extends Backbone.ViewOptions<Backbone.Model> {
  /**
   * @param {() => string"} template: If you need a custom template with this you can asing it
   */
  firstTemplate?: () => string;
  /**
   * @param {number} position: Set de dafault position in pocentage
   */
  position?: number;
  /**
   * @param {number} prevPosition: Last used when click on arrows (only works if position is 100 or 0)
   */
  prevPosition?: number;
  /**
   * @param {"0/100" | "100/0" | "50/50" | "33/66" | "25/75" | "custom"} defaultOpeningSize: Set the default opening size.
   */
  defaultOpeningSize: CWSplitPannelDefaultModes;

  module?: string;
}

type CWSplitPannelButtonsPosition = "left_top" | "right_top" | "right_bottom";

export interface CWSplitPannelDefaultPlacesInterface {
  /**
   * @param {CWSplitPannelButtonsPosition} position: Specify the position where the button is rendered
   */
  position: CWSplitPannelButtonsPosition;
  /**
   * @param {string} buttonName: Specify the button to rendered
   */
  buttonName: string;
}

export class CWSplitPanelView extends Backbone.View<Backbone.Model> {

  // Border width of the split bar control in pixels. It is used in the
  // functions that recalculte the max width of menu and split panels to
  // avoid the split controls become hidden. See
  // phx.app.AppRouter._updateMaxWidthSplitPanels and _updateMaxWidthLeft

  static readonly SPLIT_BORDER_WIDTH = 12;
  static readonly TABS_HEADER_HEIGHT = 75;
  static readonly MINIMUM_SIZE = 0.3;

  splitterButtonsTimer: NodeJS.Timeout;
  resizing: boolean;
  elSplitA: any;
  elSplitB: any;
  labelledby: string;
  template: () => string;
  firstTemplate: () => string;
  private barhandleColor: string;
  private defaultOpeningSize: CWSplitPannelDefaultModes;
  private module: string;

  /**
   * Constructor
   * Split Panel View
   */
  constructor(options: CWSplitPannelInterface) {
    options.className = "l-splitPanel container-fluid";
    options.events = {
      "keydown .ui-resizable-handle": "_keypressHandler"
    }
    super(options);
    this.firstTemplate = CWSplitPanelTPL;
    //this.SPLIT_BORDER_WIDTH = 12;
    // this.TABS_HEADER_HEIGHT = 75;
    // this.MINIMUM_SIZE = 0.3;
    this.model = new Backbone.Model({
      position: 50,
      prevPosition: 30
    });
    if (options && options.firstTemplate) {
      this.template = options.firstTemplate;
    } else {
      this.template = this.firstTemplate;
    }
    if (options && _.isNumber(options.position)) {
      this.model.set("position", options.position);
    }
    if (options && _.isNumber(options.prevPosition)) {
      this.model.set("prevPosition", options.prevPosition);
    }
    this.module = (!CWSTR.isBlank(options?.module)) ? options.module : objs.appRt.workflow.get("usecase");
    this.defaultOpeningSize = options.defaultOpeningSize;
    this.listenTo(this.model, "change:position", this._manageSplitPosition);
    this.listenTo(Backbone, "resize:" + this.cid, this._updateMaxWidthSplitPanels);
    this.splitterButtonsTimer = null;
    this.resizing = false;
  }

  /**
   * Renders this view
   */
  render(): CWSplitPanelView {
    $(this.el).html(this.firstTemplate());
    $(this.el).attr("cid", this.cid);
    // Set IDs
    this.$el.find(".l-splitA").attr("id", this.cid + "-splitA");
    this.$el.find(".l-splitB").attr("id", this.cid + "-splitB");
    // Set WAI-ARIA role
    this.$el.find(".l-splitB").attr("role", "region");
    if (!CWSTR.isBlank(this.labelledby)) {
      this.$el.find(".l-splitB").attr("aria-labelledby", this.labelledby);
    }
    //Adjust split position
    /**
     * Left panel
     */
    this.elSplitA = $(".l-splitA", this.el);
    this.elSplitB = $(".l-splitB", this.el);
    //Make resizable
    this.elSplitA.resizable({
      handles: "e",
      minWidth: objs.appRt.borderWidth,
      create: (event: Event) => {
        const rigthArrow = $("<div>").addClass("ui-resizable-goto-right  " + this.cid).css({ "z-index": "91" }).append($("<span>").addClass("ui-resizable-icon-right"));// Create elements
        const leftArrow = $("<div>").addClass("ui-resizable-goto-left " + this.cid).css({ "z-index": "91" }).append($("<span>").addClass("ui-resizable-icon-left"));

        // add events
        rigthArrow.mouseenter(() => {
          clearTimeout(this.splitterButtonsTimer);
        });
        rigthArrow.mouseleave(() => {
          this._hideActionsDelayed();
        });
        rigthArrow.click(() => {
          const currentPosition = this.model.get("position");
          const previousPosition = this.model.get("prevPosition");

          if (currentPosition <= CWSplitPanelView.MINIMUM_SIZE) {
            this.model.set("position", previousPosition);
          } else {
            this.model.set("prevPosition", currentPosition);
            this.model.set("position", 100);
          }
          this.model.trigger("click:arrow", "right");
          this._hideActions();
        });
        leftArrow.mouseenter(() => {
          clearTimeout(this.splitterButtonsTimer);
        });
        leftArrow.mouseleave(() => {
          this._hideActionsDelayed();
        });
        leftArrow.click(() => {
          const currentPosition = this.model.get("position");
          const previousPosition = this.model.get("prevPosition");

          if (currentPosition === 100) {
            this.model.set("position", previousPosition);
          } else {
            this.model.set("prevPosition", currentPosition);
            this.model.set("position", CWSplitPanelView.MINIMUM_SIZE);
          }

          this._hideActions();
          this.model.trigger("click:arrow", "left");
        });
        $(event.target).append(rigthArrow);
        $(event.target).append(leftArrow);
        rigthArrow.hide();
        leftArrow.hide();
        rigthArrow.position({
          my: "left+6 center",
          of: event.target,
          collision: "fit"
        });
        leftArrow.position({
          my: "right-6 center",
          of: event.target,
          collision: "fit"
        });
        // WAI-ARIA properties to omit buttons
        rigthArrow.attr("aria-hidden", "true");
        leftArrow.attr("aria-hidden", "true");
        // Set splitter as focusable
        $(event.target).find(".ui-resizable-handle").attr("tabindex", "0");
        // Set WAI-ARIA properties
        $(event.target).find(".ui-resizable-handle").attr("role", "separator");
        $(event.target).find(".ui-resizable-handle").attr("aria-label", i18n.t('wai_aria.panelSplitter'));
        $(event.target).find(".ui-resizable-handle").attr("aria-controls", this.cid + "-splitA " + this.cid + "-splitB");
        this._manageAriaExpanded();
      }
      ,
      start: () => {
        this.resizing = true;
        this._hideActions();
      },
      stop: (event: JQueryEventObject) => {
        let pos = (event.pageX - $(event.target).offset().left) * 100 / this.$el.width();

        this.resizing = false;
        if (pos > 100) {
          pos = 100;
        }
        if (pos < 0) {
          pos = 0;
        }
        this.model.set("position", pos, { silent: true }); //Silent because "pos" is not exactly in flex mode, check the comment in resize event
        this._manageAriaExpanded();
        this.eafterResizeActions();
      },
      resize: (event: Event, ui: JQueryUI.ResizableUIParams) => {
        this.resizePannels(ui.size.width);
        const maxPannelSize = (this.$el.width() - ($(event.target).outerWidth() - $(event.target).width())); //The parent width minus the padding of my element

        // set to maximun
        if ($(event.target).outerWidth() > maxPannelSize) {
          this.resizePannels("100%");
          this.model.set("prevPosition", 30);
        }
        // set to minimum
        if ($(event.target).outerWidth() < 46) {
          this.resizePannels(String(CWSplitPanelView.MINIMUM_SIZE) + "%");
          this.model.set("prevPosition", 30);
        }

        //This are the attemps to get the correct position, but i can't :'(
        /*const pos = ui.size.width * 100 / this.$el.width();
        LOG.debug("1- " + event.pageX * 100 / this.$el.width());
        LOG.debug("2- " + event.pageX * 100 / $("#phx-wrap").width());
        LOG.debug("3- " + ui.size.width * 100 / this.$el.width());
        LOG.debug("4- " + ui.size.width * 100 / $("#phx-wrap").width());
        LOG.debug("5- " + (event.pageX - $(event.target).offset().left)* 100 / this.$el.width());
        LOG.debug("6- " + (event.pageX - $(event.target).offset().left)* 100 / $("#phx-wrap").width());
        LOG.debug("7- " + (ui.size.width - $(event.target).offset().left)* 100 / this.$el.width());
        LOG.debug("8- " + (ui.size.width- $(event.target).offset().left)* 100 / $("#phx-wrap").width());
        if (pos > 0 && pos < 100){
          this.model.set("position", pos, { silent: false });
        }*/
      }
    });
    const handle = this.elSplitA.find(".ui-resizable-handle");
    //Set mouseover event
    let theTimeOut: NodeJS.Timeout;

    handle.mouseenter((event: JQueryEventObject) => {
      theTimeOut = setTimeout(() => {
        this._showActions(event);
      }, 50);
    });
    handle.mouseleave(() => {
      clearTimeout(theTimeOut);
      this._hideActionsDelayed();
    });
    handle.addClass(this.cid);
    //I save the bakcgroun color of the handle bar, for do animation with packground color
    this.barhandleColor = handle.css('backgroundColor');
    handle.css('backgroundColor', "transparent");
    this.setDefaultPannelSize();
    return this;
  }

  private setDefaultPannelSize(): void {
    switch (this.defaultOpeningSize) {
      case "0/100": {
        this.resizePannels(String(CWSplitPanelView.MINIMUM_SIZE) + "%");
        break;
      }
      case "100/0": {
        this.resizePannels("100%");
        break;
      }
      case "50/50": {
        this.resizePannels("50%");
        break;
      }
      case "25/75": {
        this.resizePannels("25%");
        break;
      }
      case "33/66": {
        this.resizePannels("33%");
        break;
      }
      case "custom": {
        //Do nothing... i think... 
        break;
      }
      default: {
        CWLOG.warn("I can't manage the default opening size: " + this.defaultOpeningSize);
      }
    }
  }

  private resizePannels(splitPosition: string | number): void {
    let unit = "";

    if (typeof splitPosition === "number") {
      unit = "px";
    }
    this.elSplitA.attr("style", [
      "max-width: " + splitPosition + unit,
      "width:" + splitPosition + unit + " !important",
      "flex: 0 0 " + splitPosition + unit
    ].join(';'));
    this.elSplitB.attr("style", [
      "max-width: calc( 100% - " + splitPosition + unit + ")",
      "flex: 1 1"
    ].join(';'));

    this._manageTabFocus(String(splitPosition));

    // Custom : remove border, and adapting padding
    //LOG.debug("splitPosition = " + splitPosition + unit);
    if (typeof splitPosition === "number" && splitPosition <= 10
      || typeof splitPosition === "string" && splitPosition === CWSplitPanelView.MINIMUM_SIZE + "%") {
      this.elSplitA.css("padding", "0");
      this.elSplitB.css("padding-left", "0");
      this.elSplitA.find(".leftContentPanel").css("border", "none");
    } else {
      this.elSplitA.find(".leftContentPanel").removeAttr("style");
    }
    this.model.set("position", parseFloat(splitPosition.toString()), { silent: true });
    if (!this.$el.is(":visible") && CWSTR.isBlank(unit)) {//ce n'est pas visible et l'unité est en pourcentage
      const lWidthTmp = screen.width - 90;
      const splitPourc = (typeof splitPosition === "string" && splitPosition.indexOf("%") >= 0) ? Number(splitPosition.split("%")[0]) : null;

      if (lWidthTmp > 0 && !CWSTR.isBlank(splitPourc)) {
        const wA = (lWidthTmp * (splitPourc / 100)) - 30;
        const wB = (lWidthTmp * (100 - splitPourc) / 100) - 42;

        objs.appRt.workflow.trigger("cw-resize-" + this.cid, wA, wB);
      } else {
        objs.appRt.workflow.trigger("cw-resize-" + this.cid, this.elSplitA.width(), this.elSplitB.width());
      }
    } else {
      objs.appRt.workflow.trigger("cw-resize-" + this.cid, this.elSplitA.width(), this.elSplitB.width());
    }
  }

  _keypressHandler(event: KeyboardEvent): void {
    const key = event.which || event.keyCode;
    const displacement = 1;
    const currentPosition = this.model.get("position");
    const prevPosition = this.model.get("prevPosition");

    // The key hava a custom action
    if (key === 13 || key === 35 || key === 36 || key === 37 || key === 39) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (key === 13) {
      this.model.set("position", prevPosition);
    } else if (key === 35) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", 100);
    } else if (key === 36) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", CWSplitPanelView.MINIMUM_SIZE);
    } else if (key === 37 && currentPosition > 0) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", currentPosition - displacement);
    } else if (key === 39 && currentPosition < 100) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", currentPosition + displacement);
    }
  }

  /**
   * Resize the maxwidth of split panels and the width of partB to adjust
   * scroll
   */
  _updateMaxWidthSplitPanels(): void {
    const borderWidth = CWSplitPanelView.SPLIT_BORDER_WIDTH;//This border width is needed to adjust the min and maximum width of the menu
    const navMenuWidth = $(".cw-menu").width() + 15;
    let maxWidthAllowed = $("#phx-wrap").width() - navMenuWidth;
    const elSplitA = this.$el.find(".l-splitA").eq(0);

    // Configure split panels
    // set size of left panel
    // If is a liste detail
    //LOG.debug("resizing:" + this.cid);
    if (elSplitA.length > 0) {
      maxWidthAllowed -= borderWidth;
      elSplitA.resizable("option", "maxWidth", maxWidthAllowed);
      if (maxWidthAllowed < elSplitA.width()) {
        elSplitA.width(maxWidthAllowed);
      }
    }
  }
  /**
   * Show action left and right arrows
   */
  _showActions(event: JQueryMouseEventObject): void {
    this.showActions($(event.currentTarget) as JQuery);
  }

  private showActions(barHand: JQuery): void {
    if (!this.resizing) {
      clearTimeout(this.splitterButtonsTimer);
      barHand.animate({ backgroundColor: this.barhandleColor }, 150, (): void => {
        if (this.model.get("position") < 98.4) {
          const rigthArrow = this.$el.find(".l-splitA .ui-resizable-goto-right." + this.cid);

          rigthArrow.fadeIn().position({
            my: "left+7 center",
            of: barHand,
            collision: "fit"
          });
        }
        if (this.model.get("position") > 2) {
          const leftArrow = this.$el.find(".l-splitA .ui-resizable-goto-left." + this.cid);

          leftArrow.fadeIn().position({
            my: "right-7 center",
            of: barHand,
            collision: "fit"
          });
        }
      });
    }
  }
  /**
   * Hide Actions delayed
   */
  _hideActionsDelayed(): void {
    if (!this.resizing) {
      this.splitterButtonsTimer = setTimeout(() => {
        this.$el.find(".l-splitA .ui-resizable-goto-right." + this.cid).fadeOut(200, (): void => {
          this.$el.find(".l-splitA .ui-resizable-handle." + this.cid).animate({ backgroundColor: "transparent" }, 150);
        });
        this.$el.find(".l-splitA .ui-resizable-goto-left." + this.cid).fadeOut(200);
      }, 300);
    }
  }
  /**
   * Hide left and right arrow actionsactions
   */
  _hideActions(): void {
    this.$el.find(".l-splitA .ui-resizable-goto-right." + this.cid).hide();
    this.$el.find(".l-splitA .ui-resizable-goto-left." + this.cid).hide();
  }

  private eafterResizeActions(): void {
    this.showActions(this.$el.find(".l-splitA .ui-resizable-handle." + this.cid));
    this.splitterButtonsTimer = setTimeout(() => {
      this.$el.find(".l-splitA .ui-resizable-goto-right." + this.cid).fadeOut(200, (): void => {
        this.$el.find(".l-splitA .ui-resizable-handle." + this.cid).animate({ backgroundColor: "transparent" }, 150);
      });
      this.$el.find(".l-splitA .ui-resizable-goto-left." + this.cid).fadeOut(200);
    }, 300);
  }

  /**
   * Manages split positioning
   */
  _manageSplitPosition(): void {
    this._manageAriaExpanded();
    if (CWSTR.isBlank(this.model.get("prevPosition"))) {
      this.model.set("prevPosition", this.model.get("position"));
    }
    this.resizePannels(this.model.get("position") + "%");
    this._hideActions();
    // Force to update width.
    objs.appRt._updateMaxWidthSplitPanels();
    objs.appRt._resizeProtoResizables();
  }

  /**
   * Avoids element focused by Tab in hide splitB panel 
   * @param position 
   */
  _manageTabFocus(position: string): void {
    if (position === "100%") {
      this.$el.find(".l-splitB").attr("tabindex", "-1").css("visibility", "hidden");
      this.$el.find(".l-splitB").hide();
    } else if (position === String(CWSplitPanelView.MINIMUM_SIZE) + "%") {
      this.$el.find(".l-splitA .leftContentPanel").hide();
    } else {
      this.$el.find(".l-splitB").removeAttr('tabindex').css("visibility", "visible");
      this.$el.find(".l-splitB").show();
      this.$el.find(".l-splitA .leftContentPanel").show();
    }
  }

  /**
   * Calculates center height
   */
  _calculateCenterHeight(): number {
    return $(this.el).parent().height() - $(".cw-header").height();
  }

  _manageAriaExpanded(): void {
    if (this.model.get("position") <= 0.3) {
      this.$el.find(".ui-resizable-handle").attr("aria-expanded", "false");
    } else {
      this.$el.find(".ui-resizable-handle").attr("aria-expanded", "true");
    }
  }

  setLabelIds(labelIds: string): void {
    this.labelledby = labelIds;
  }

  enableResizable(): void {
    this.elSplitA.resizable("enable");
  }

  disableResizable(): void {
    this.elSplitA.resizable("disable");
  }

  disableSplitter(): void {
    this.$el.find('.l-splitA > .ui-resizable-handle').hide();
    this.$el.find('.l-splitA').addClass('phx-hideSplitter');
  }

  enableSplitter(): void {
    this.$el.find('.l-splitA > .ui-resizable-handle').show();
    this.$el.find('.l-splitA').removeClass('phx-hideSplitter');
  }

  hideLeft(): void {
    this.$el.find('.l-splitA').hide();
  }

  showLeft(): void {
    this.$el.find('.l-splitA').show();
  }

  hideRight(): void {
    this.$el.find('.l-splitB').hide();
  }

  showRight(): void {
    this.$el.find('.l-splitB').show();
  }

  /**
   * Redistribute the buttons over the layout
   *
   * @param {CWButtonBarView} buttonBar The button bar to redistribute over layout
   * @param {Array<CWSplitPannelDefaultPlacesInterface>} buttonPosition Objects indicate the position and the name of button to resituate
   */
  autoRedistributeButtons(buttonBar: CWButtonBarView, buttonPosition?: Array<CWSplitPannelDefaultPlacesInterface>): void {
    let buttonPositions = buttonPosition;

    if (!buttonPositions || buttonPositions.length === 0) {
      buttonPositions = [
        {
          position: "left_top",
          buttonName: "new"
        }
      ];
    }
    if (buttonBar) {
      $(".l-panelB-butomButtons", this.el).append(buttonBar.el);
      buttonPositions.forEach(buttonPosition => {
        let element: JQuery = null;
        switch (buttonPosition.position) {
          case "left_top": {
            element = $(".l-panelA-buttonContainer", this.el);
            break;
          }
          case "right_top": {
            element = $(".l-panelB-buttonContainer", this.el);
            break;
          }
          case "right_bottom": {
            element = $(".l-panelB-butomButtons", this.el);
            break;
          }

          default:
            CWLOG.warn("Unexpected position: " + buttonPosition.position);
            break;
        }
        if (element && element.length > 0) {
          buttonBar.detachButton(buttonPosition.buttonName, element);
        }
      });
    }
  }

  getBarhandleColor(): string {
    return this.barhandleColor;
  }

  setBarhandleColor(val: string): void {
    this.barhandleColor = val;
  }
}
