module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- <div class="phx_chemin_dlgentete">\n    <label class="phx_chemin_strlbl" for="phx-chemin-strinp"></label>\n    <input class="phx-chemin-strinp form-control" type="text"></input>\n    <button type="button" class="phx_chemin_rechbtn btn btn-secondary" value="save">'+
((__t=(i18n.t("common:chemin.dlgrechercher")))==null?'':__t)+
'</button>\n</div> -->\n<div class="dialog_container" style="height: inherit;">\n<fieldset class="phx_chemin_dlgentete cw-fieldset">\n    <div class="form-row">\n        <div class="form-group col-6">\n            <label class="phx_chemin_strlbl sr-only" for="phx-chemin-strinp"></label>\n            <input name="searchcollab" class="phx-chemin-strinp form-control" type="text">\n        </div>\n        <div class="form-group col-2">\n            <div class="input-group">\n                <button type="button" class="phx_chemin_rechbtn btn btn-secondary btn-withIcon" value="save">'+
((__t=(i18n.t("common:chemin.dlgrechercher")))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon("loupe")))==null?'':__t)+
'</button>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<fieldset class="phx_chemin_dlgresultheader cw-fieldset">\n    <label class="phx_chemin_restitle"></label>\n    <span class="phx_chemin_menuicon phx-menu-icon ui-corner-all ui-icon ui-icon-circle-plus"></span>\n</fieldset>\n\n<fieldset class="phx_chemin_dlgresult cw-fieldset">\n    <div class="phx_chemin_dlgresult_div">\n        <ul class="phx_chemin_menucontent">\n            <li class="phx_chemin_opt1 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt1">'+
((__t=(i18n.t("common:chemin.optlibcod")))==null?'':__t)+
'</span></li>\n            <li class="phx_chemin_opt2 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt2">'+
((__t=(i18n.t("common:chemin.optcodlib")))==null?'':__t)+
'</span></li>\n            <li class="phx_chemin_opt3 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt3">'+
((__t=(i18n.t("common:chemin.optlib")))==null?'':__t)+
'</span></li>\n            <li class="phx_chemin_opt4 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt4">'+
((__t=(i18n.t("common:chemin.optcod")))==null?'':__t)+
'</span></li>\n        </ul>\n        <div class="phx_chemin_treediv">\n            <span class="phx_chemin_tree"></span>\n        </div>\n    </div>\n</fieldset>\n\n<fieldset class="phx_chemin_dlgentete cw-fieldset">\n    <label class="phx_chemin_sellbl">'+
((__t=(i18n.t("common:chemin.dlgselection")))==null?'':__t)+
'</label>\n    <span class="phx-chemin-selval cw-texteImportant"></span>\n    <div class="phx-chemindlg-selection"></div>\n</fieldset>\n';
 if(context && context.ctxStructureSelpop){
__p+='\n    <fieldset class="cw-fieldset">\n        <div class="form-group">\n            <div class="custom-control custom-checkbox phx-chemin-population-descendence '+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 1 )?'d-none':''))==null?'':__t)+
'" >                    \n                <input id="cw-populationDescendenceChemin" class="populationDescendence custom-control-input" type="checkbox" '+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 2 )?'checked':''))==null?'':__t)+
' '+
((__t=((context.ctxSelRemDesc == 2 || context.ctxSelRemDesc == 3 )?'':'disabled'))==null?'':__t)+
' value="true">\n                <label for="cw-populationDescendenceChemin" class="custom-control-label"> '+
((__t=( i18n.t("populationsmenu.returnerEgalement")))==null?'':__t)+
'</label>\n            </div>\n        </div>\n    </fieldset>\n';
}
__p+='\n</div>\n';
}
return __p;
};
