import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLResultatgrpcompteursGroupecompteurs from '../groupecompteurs.tpl.html';
import { CompteursColl } from '../../badgeage/models/compteurs.collection.js';
import { ConfigResultatsListeCpt } from '../models/configResultatsListeCpt.js';
import { CWBadgeageVirtuelDialogView } from '../../badgeage/views/cwBadgeageVirtuelDialog.view';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWConsultationDialogView } from './cwConsultationDialog.view';
import { CWDataGridModel } from 'src/core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWModeBadgModel } from 'common/badgeage/models/cwModeBadg.model';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPreferences } from '../models/cwPreferences';
import { CWSearchListeGroupesColl } from '../models/cwSearchListeGroupes.collection';
import { CWSearchListeGroupesData } from './../models/cwSearchListeGroupesData';
import { CWSearchResultatsListeCptColl } from '../models/cwSearchResultatsListeCpt.collection';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { objs } from 'src/objectsRepository';
import { SearchResultatsListeCptModel } from '../models/searchResultatsListeCpt.model.js';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';

/**
 * CWDialogView of the Groupe Compteurs
 */
export class CWGroupeCompteursDialogView extends CWBaseFormView {
  private parent: CWDialogPopupView;
  private origin: string;
  private context: { [key: string]: any };
  private table: CWDataGridView<CWBaseModel, CWSearchResultatsListeCptColl>;
  private newMatricule: number;
  private newTypeGroupe: string;
  private newGroupe: string;
  private newDate: string;
  private newDemande: string | boolean;
  private newSouhait: string | boolean;
  private configDate: string;
  private configSouhait: boolean;
  private isVisible: boolean;
  private numSeparators: number;
  private popupResponse: { [key: string]: any };
  private preferences: CWPreferences;
  private compteurInst: CWBadgeageVirtuelDialogView;
  private maxHeight: number;
  private heightOneTable: number;
  private heightOneTableScroll: number;
  private heightTwoTable: number;
  private heightTwoTableScroll: number;
  private disableAlternateRowBackground: boolean;
  public $appendTo: JQuery; //pour les messages

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .iconConsul": "_showConsultation",
      "click .showCompteurs": "_showBlockCompteurs",
      "click .hideCompteurs": "_hideBlockCompteurs",
      "keydown #consultation": "_keydownHand"
    });
    super(options);
    this.template = TPLResultatgrpcompteursGroupecompteurs;
    if (options.parent) {
      this._closeOthersDialogs(options.parent.$el.attr("id"));
      this.parent = options.parent;
      if (this.parent._setTitle && options.context.ctxModule === "agenda") {
        this.parent._setTitle(i18n.t('common:resultatgrpcompteurs.dialogGroupeCompteurs'));
      } else if (this.parent._setTitle && options.context.ctxModule === "agenda_R") {
        this.parent._setTitle(i18n.t('common:resultatgrpcompteurs.situationcollab'));
      }
    }
    this.disableAlternateRowBackground = false;
    if (_.isBoolean(options.disableAlternateRowBackground)) {
      this.disableAlternateRowBackground = options.disableAlternateRowBackground;
    }
    if (this.parent) {
      // Flag icon to open Valorisation in agenda
      this.origin = "header_valorisation";
    } else {
      // Click in "Situation" section in "Complements" right view of agenda
      this.origin = "complements_situation";
    }
    this.context = options.context;
    if (CWSTR.isBlank(this.context)) {
      throw new Error("A context must be specified for this component.");
    }
    this.model = new CWBaseModel({
      value: new SearchResultatsListeCptModel()
    });
    this.model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation
    }));
    this.table = null;
    this.newMatricule = options.context.resultatsCollab;
    this.newTypeGroupe = options.context.resultatsGroupe;
    this.newGroupe = null;
    this.newDate = null;
    this.newDemande = null;
    this.newSouhait = null;
    this.configSouhait = null;
    this.isVisible = false;
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? ((this.context.ctxEcran !== "gmsituation") ? $("#" + this.context.ctxEcran) : null) : null;
    this._getGroupe(this.newTypeGroupe, () => {
      const configModel = new ConfigResultatsListeCpt();

      configModel.setHabContext(this.model.getHabContext());
      if (this.context.ctxModule === "agenda_R") {
        configModel.matric = this.context.resultatsCollab;
      }
      configModel.fetch({
        success: (fresh: { [key: string]: any }) => {
          this.newDate = fresh.get("date");
          this.newDemande = fresh.get("demande");
          this.newSouhait = fresh.get("souhait");

          if (this.context) {
            if (!CWSTR.isBlank(this.context.resultatsDate)) {
              this.newDate = this.context.resultatsDate;
            }

            this.newDemande = this.context.resultatsPRDde;
            this.newSouhait = this.context.resultatsPRSht;
          }

          this.table = this._initTable();
          this._changeTitle();

          // Show/hide columns
          this.table.model.on("toggle:column", this._showIcon, this);

          this.table.model.coll.setMatricule(this.newMatricule);
          this.table.model.coll.setGroupe(this.newGroupe);
          this.table.model.coll.setDate(this.newDate);
          this.table.model.coll.setDemande(this.newDemande as string);
          this.table.model.coll.setSouhait(this.newSouhait as string);

          // remove row selection
          this.table.model.coll.off("row:click");

          //updateTable
          if (this.context.resultatsAuto === true) {
            this.table.model.coll.setHabContext(this.model.getHabContext());
            this.table.model.coll.fetch({
              success: () => {
                this._renderTable();
                //								this.table.model.trigger("dataLoaded",this.table.model.coll.length);
                //								this.trigger("dataLoaded",this.table.model.coll.length);
                //this._adjustPoputpToContent(this.table.model.coll.length);
                this.trigger("resizeDialog");
                if (this.parent) {
                  this.parent.model.trigger("dlg:reposition");
                }
                this._setFilterTabindex();
              }
            });
          } else {
            this._renderTable();
            this._showConsultation();
          }
        }
      });
    });
    this.numSeparators = 0;
  }

  _keydownHand(e: any): void {
    const key = e.which || e.keyCode;
    if (key === 13 /* enter */ || key === 32 /* space */) {
      e.preventDefault();
      e.target.click();
    }
  }

  /**
   * Prevents loss of tabindex in flter icon after resize.
   */
  _setFilterTabindex(): void {
    $(this.el).find("#consultation").attr("tabindex", "0");
  }

  _adjustPoputpToContent(rows: number): void {
    const newHeight = (rows < 20) ? (((rows) * 26) + 141) : 615;
    if (this.parent) {
      this.parent.$el.height(newHeight);
    }
  }

  _closeOthersDialogs(thisdialogid: string): void {
    $(".ui-dialog .gmsituation").each((index: number, element: Element) => {
      const idhtml = $(element).parent(".ui-dialog-content").attr("id");
      if (idhtml !== thisdialogid) {
        $("#" + idhtml).dialog("close");
      }
    });
  }

  _showIcon(columnName: string): void {
    if (columnName === "type") {
      this.isVisible = !this.isVisible;
      this.table.model.trigger("toggle:column", "type_icon");
    }
  }

  /**
   * 	resultatsMode = this.workflow.get("utilisateur"); //no
    resultatsCollab = this.workflow.get("collabId");
    resultatsGroupe = 33;//TODO fix with right values
    resultatsDate = null;
    resultatsPRDde = null;
    resultatsPRSht = null;
    resultatsAuto = true; //no
    resultatsTitre = false; //no
   */
  setContext(context: { [key: string]: any }): void {
    // remove row selection
    if (this.table) {
      this.table.model.coll.off("row:click");
    }
    this.$appendTo = (context && !CWSTR.isBlank(context.ctxEcran)) ? ((context.ctxEcran !== "gmsituation") ? $("#" + context.ctxEcran) : null) : null;
    if (context.ctxEcran !== "agenda" && context.module !== "agenda") {//module arrive de l'écran consultation/situation ->gmsituation
      CWMSGS.showConfirmAdapter(i18n.t('common:resultatgrpcompteurs.confirm'), (result: string) => {
        if (result === "Y") {
          this._setContext(context);
        }
      }, null, null, null, this.$appendTo);
    } else {
      this._setContext(context);
    }
    // Hide table footer
    if (this.table) {
      this.table.setTotalRowsVisible(false);
    }
  }

  _setContext(context: { [key: string]: any }): void {
    this.newMatricule = context.resultatsCollab;
    this.newTypeGroupe = context.resultatsGroupe;

    this._getGroupe(this.newTypeGroupe, () => {
      if (context.resultatsDate === null && this.configDate) {
        this.newDate = this.configDate;
      }

      this.newDate = (this.newDate === null) ? context.resultatsDate : this.newDate;
      this.newDemande = (this.newDemande === null) ? context.resultatsPRDde : this.newDemande;
      this.newSouhait = (this.newSouhait === null) ? context.resultatsPRSht : this.newSouhait;

      this._updateTable();
    });
  }

  _updateTable(): void {
    this.numSeparators = 0;
    if (this.table && this.table.model && this.table.model.coll) {
      this.table.model.coll.setMatricule(this.newMatricule);
      this.table.model.coll.setGroupe(this.newGroupe);
      this.table.model.coll.setDate(this.newDate);
      this.table.model.coll.setDemande(this.newDemande as string);
      this.table.model.coll.setSouhait(this.newSouhait as string);
      this.table.model.coll.setHabContext(this.model.getHabContext());
      this._changeTitle();
      this.table.model.coll.fetch({});
    }
  }

  _getGroupe(typegroupe: any, callback: () => void): void {
    const listeGroupes = new CWSearchListeGroupesColl(null, { typegroupe: typegroupe });
    listeGroupes.setHabContext(this.model.getHabContext());

    if (this.context.ctxModule === "agenda_R") {
      listeGroupes.params.matric = this.context.resultatsCollab;
    }

    if (!CWSTR.isBlank(listeGroupes.typegroupe)) {
      listeGroupes.fetch({
        success: (fresh: { [key: string]: any }) => {
          if (fresh.length > 0) {
            this.newGroupe = fresh.models[0].get("code");
          }
          if (callback) {
            callback();
          }
        },
        error: () => {
          if (callback) {
            callback();
          }
        }
      });
    }
  }

  _initTable(): CWDataGridView<CWBaseModel, CWSearchResultatsListeCptColl> {
    const tableModel = new CWDataGridModel<CWSearchResultatsListeCptColl, CWBaseModel>({ coll: new CWSearchResultatsListeCptColl() });
    const tblColumns: Array<CWBaseGridColums> = [
      { title: i18n.t('common:resultatgrpcompteurs.libelle'), property: "libelle", width: 7 },
      { title: i18n.t('common:resultatgrpcompteurs.valeur'), property: "valeur", width: 3 },
      { title: "", property: "type_icon", width: 2 }
    ];

    let table = null;
    if (this.context.ctxEcran === "agenda") {
      table = new CWDataGridView<CWBaseModel, CWSearchResultatsListeCptColl>({
        id: "compteurs_table",
        columns: tblColumns,
        model: tableModel,
        gridHeightType: "nolimite",
        title: i18n.t('common:resultatgrpcompteurs.title_compteurs') + " " + CWTYPE.DATE.format(this.newDate),
        selectable: false,
        showFilteredRowsInTitle: false,
        disableAlternateRowBackground: this.disableAlternateRowBackground
      });
    } else {
      table = new CWDataGridView<CWBaseModel, CWSearchResultatsListeCptColl>({
        id: "compteurs_table",
        columns: tblColumns,
        model: tableModel,
        gridHeightType: "auto",
        showFilteredRowsInTitle: false,
        parentContainer: this.parent,
        title: i18n.t('common:resultatgrpcompteurs.title_compteurs') + " " + CWTYPE.DATE.format(this.newDate),
        selectable: false,
        disableAlternateRowBackground: this.disableAlternateRowBackground
      });
    }

    table.setSortableColumns([]);

    // Render the cells when a blank line is coming up
    table.cellRenderers["libelle"] = (model: { [key: string]: any }): string | JQuery => {
      if (model.get("libelle") === "Saut de ligne") {
        this.numSeparators++;
        return "";
      } else {
        const span = $("<span>").text(model.get("libelle"));
        if (this.context.ctxEcran === "agenda") {
          span.attr("title", model.get("libelle"));
        }
        return span;
      }
    };
    table.cellRenderers["valeur"] = (model: { [key: string]: any }): string | JQuery => {
      if (model.get("libelle") === "Saut de ligne") {
        return "";
      } else {
        const span = $("<span>").text(model.get("valeur"));
        if (model.get("valeur").indexOf("-") !== -1) {
          span.addClass("ui-phx-valorisation-negative");
        }
        if (this.context.ctxEcran === "agenda") {
          span.attr("title", model.get("valeur"));
        }
        return span;
      }
    };
    table.cellRenderers["type"] = (model: { [key: string]: any }): string | JQuery => {
      if (model.get("libelle") === "Saut de ligne") {
        return "";
      } else {
        switch (model.get("type")) {
          case 4:
            return i18n.t('common:resultatgrpcompteurs.type4');
          case 5:
            return i18n.t('common:resultatgrpcompteurs.type5');
          default:
            return "";
        }
      }
    };
    table.cellRenderers["type_icon"] = (model: { [key: string]: any }): string | JQuery => {
      if (model.get("libelle") === "Saut de ligne") {
        return "";
      } else {
        switch (model.get("type")) {
          case 4: {
            //const span = $("<span>").addClass("phx-icon phx-icon-resultatgrpcompteurs-window mx-auto").attr("title", i18n.t('common:resultatgrpcompteurs.type4')).html("&nbsp;");
            const span = $("<span>").addClass("mx-auto").attr("title", i18n.t('common:resultatgrpcompteurs.type4')).html("&nbsp;");
            span.append(UTILS.getSVGIcon('fenetre_couleur', 'cw-icon-24', 24));
            return span.get(0).outerHTML;
          }
          case 5: {
            //const span = $("<span>").addClass("phx-icon phx-icon-resultatgrpcompteurs-windows mx-auto").attr("title", i18n.t('common:resultatgrpcompteurs.type5')).html("&nbsp;");
            const span = $("<span>").addClass("mx-auto").attr("title", i18n.t('common:resultatgrpcompteurs.type5')).html("&nbsp;");
            span.append(UTILS.getSVGIcon('fenetres_plusieurs_couleur', 'cw-icon-24', 24));
            return span.get(0).outerHTML;
          }
          default:
            return "";
        }
      }
    };

    table.setVisibleColumns(["libelle", "valeur", "type_icon"]);

    // override default method to show correct total number of rows
    table.overrideFilteredRowsInTitle = (): number => {
      return this.table.model.coll.length - this.numSeparators;
    };

    return table;
  }

  _renderTable(): void {
    const json = { 'i18n': i18n, model: this.model.get("value"), "UTILS": UTILS };
    $(this.el).html(this.template(json));
    $(this.el).find("#consultation").attr("tabindex", "0");
    if (this.context.rightView) {
      this.$el.find(".iconConsul").hide()
    }

    // retrieve the matricule from the loged user
    const matricule = objs.appRt.workflow.authModel.get("matricule");

    let title = i18n.t('common:resultatgrpcompteurs.dialogGroupeCompteurs');
    let subtitle = null;

    const pdiver = GLOBAL_DATA.paramDivers.get("idCollab");

    if (this.context.ctxModule === "agenda_R") {
      title = i18n.t('common:resultatgrpcompteurs.situationcollab');

      if (this.context.ctxUser) {
        const user = this.context.ctxUser;
        let mat = user.get("aux");
        if (pdiver.get("valeur") === "matric") {
          mat = user.get("mat");
        }
        subtitle = user.get("nom") + " " + user.get("pre") + " (" + mat + ")";
      }
    }

    this.maxHeight = $(window).height() - 150;
    this.heightOneTable = this.maxHeight * 0.8; // height for table resultats
    this.heightTwoTable = this.maxHeight * 0.4; // height when both tables are visible
    this.heightOneTableScroll = this.heightOneTable - 74; // height for scroll of table resultats
    this.heightTwoTableScroll = this.heightTwoTable - 74; // height for scroll when both tables are visible

    if (this.context.ctxEcran === "agenda") {
      $(this.el).find(".tableresultats").html(this.table.el);
      $(this.el).find(".tableresultats").css({ "max-height": "350px" });
      this.table.render();
      this.$el.find("#compteurs_table").css({ "max-height": "350px" });
      this.$el.find(".c-grind__scroll").css({ "overflow-y": "overlay", "max-height": "285px" });
    } else {

      $(this.el).find(".tableresultats").html(this.table.el);
      //$(this.el).find(".tableresultats").css({ "max-height": this.maxHeight });
      this.table.render();
      //this.$el.find("#compteurs_table").css({ "max-height": this.maxHeight });
      this.$el.find("#compteurs_table").css({ "height": "auto", "max-height": this.heightOneTable });
      this.$el.find(".c-grind__scroll").css({ "overflow-y": "overlay", "max-height": this.heightOneTableScroll });
    }

    if (this.parent && this.parent.resize) {
      this.parent.resize();
    }

    // Hide table footer
    this.table.setTotalRowsVisible(false);

    const heure = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");

    if (subtitle !== null) {
      $(this.el).find(".ctxGestionCollab").html(subtitle);
      $(this.el).find(".groupecompteurs-collab").show();
    } else {
      $(this.el).find(".groupecompteurs-collab").hide();
    }

    //Dialog compteurs
    const callbackViewGruopeCompteurs = (): void => {

      const compteursColl = new CompteursColl({ matricule: matricule });
      compteursColl.setHabContext(new CWHabilitationContext({
        onglet: "badgeage",
        foncCour: "COL_TPSREEL.V",
        natGest: ""
      }));
      compteursColl.fetch({
        success: (fresh: { [key: string]: any }) => {



          this._getOpenPreference((open: any) => {
            let _tableHeight = 0;
            let _tableHeightScroll = 0;
            if (open) {
              $(this.el).find(".tableCompteursInstant").show();
              $(this.el).find(".showCompteurs").hide();
              $(this.el).find(".hideCompteurs").show();
              _tableHeight = this.heightTwoTable;
              _tableHeightScroll = this.heightTwoTableScroll;
            } else {
              $(this.el).find(".tableCompteursInstant").hide();
              $(this.el).find(".hideCompteurs").hide();
              $(this.el).find(".showCompteurs").show();
              _tableHeight = this.heightOneTable;
              _tableHeightScroll = this.heightOneTableScroll;
            }

            this.compteurInst = new CWBadgeageVirtuelDialogView({
              "collection": fresh,
              "heure": heure,
              "parent": this.parent,
              "title": title,
              "subtitle": i18n.t('common:resultatgrpcompteurs.compteurs')
            });


            //Adjust size of dialog
            $(this.el).find(".tableCompteursInstant").html(this.compteurInst.render().el);
            $(this.el).find(".tableCompteursInstant .bvcompteurs").css({ "max-height": _tableHeight });
            $(this.compteurInst.el).find(".c-grind__scroll").css({ "overflow-y": "overlay", "height": "auto", "max-height": _tableHeightScroll });

            $(this.table.el).find(".c-grind__scroll").css({ "overflow-y": "overlay", "max-height": _tableHeightScroll });
            this.$el.find("#compteurs_table").css({ "height": "auto", "max-height": _tableHeight });

            if (this.parent) {
              this.parent.model.trigger("dlg:reposition");
            }
          });
        }
      });

    };

    const callbackNoViewGroupeCompteurs = (): void => {
      $(this.el).find(".showCompteurs").hide();
      $(this.el).find(".hideCompteurs").hide();
      $(this.el).find(".tableCompteursInstant").hide();
      if (!CWSTR.isBlank(this.parent)) {
        this.parent.$el.height(this.$el.height());
      }
    };

    this._doItIfCanViewCompteurs(matricule, this.context.ctxModule, callbackViewGruopeCompteurs, callbackNoViewGroupeCompteurs);

  }

  _doItIfCanViewCompteurs(matricule: number, module: string, callbackYes: () => void, callbackNo: () => void): void {
    const pdiver = GLOBAL_DATA.paramDivers.get("cptInsAffR");

    if ((matricule && module !== "agenda_R" && pdiver !== null && parseInt(pdiver.get("valeur"), 10) === 1 && CWHABILITATION.canView("COL_TPSREEL.V") && !CWHABILITATION.canView("COL_BADGVIRT.G") && this.origin === "header_valorisation")) {
      const badgeagemode = new CWModeBadgModel({}, { matricule: matricule });

      badgeagemode.setHabContext(new CWHabilitationContext({
        onglet: "",
        foncCour: "",
        natGest: ""
      }));
      badgeagemode.fetch({
        success: () => {
          if (callbackYes) {
            callbackYes();
          }
        },
        error: () => {
          if (callbackNo) {
            callbackNo();
          }
        }
      });
    } else {
      if (callbackNo) {
        callbackNo();
      }
    }
  }

  _changeTitle(): void {
    const title: string = "<span class='cw-texteAllege'>" + i18n.t('common:resultatgrpcompteurs.title_compteurs') + "</span>"
      + "&nbsp;"
      + "<span class='cw-texteNormal'>" + CWTYPE.DATE.format(this.newDate) + "</span>";
    this.table.setTitle(title);
  }

  _showConsultation(ofContainer?: any): void {
    const dialogModel = new CWSearchListeGroupesColl(null, { typegroupe: this.newTypeGroupe });
    let lPosition: any;

    if (CWSTR.isBlank(this.context.rightView)) {
      lPosition = (ofContainer) ? { my: "center center", at: "center center", of: ofContainer } : { my: "center top", at: "center bottom", of: $(ofContainer.currentTarget) };
      lPosition.of = this.$el;
    } else {
      lPosition = (ofContainer) ? { my: "left top", at: "center bottom", of: ofContainer } : { my: "center top", at: "center bottom", of: this.el };
    }
    dialogModel.setHabContext(this.model.getHabContext());
    this._refreshDivers();
    dialogModel.fetch({
      success: (fresh: { [key: string]: any }) => {
        //create the dialogView for change data
        const dialogModel = new CWSearchListeGroupesData();
        dialogModel.set("groupe", this.newGroupe);
        dialogModel.set("date", this.newDate);
        dialogModel.set("demande", this.newDemande);
        dialogModel.set("souhait", this.newSouhait);
        const dialogConsu = new CWPanneauDeroulant({
          view: CWConsultationDialogView,
          position: lPosition,
          closeOnClickOutside: true,
          viewData: {
            model: dialogModel,
            comboOptions: fresh,
            configSouhait: this.configSouhait,
            parent: this,
            appendTo: this.$appendTo
          }
        });
        dialogConsu.title = i18n.t("common:soldesabsences.title_table");
        dialogConsu.setHeight("auto");
        dialogConsu.setWidth(550);

        // set buttons
        const btn = [{
          text: i18n.t('common:resultatgrpcompteurs.appliquer'),
          btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
          icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
          click: (): void => {
            if (dialogConsu.viewData.model.isValid()) {
              this.popupResponse = dialogConsu.viewData.model.attributes;
              this._popupResponseCallback("y");
              dialogConsu.dialog.dialog("close");
            }
          }
        }, {
          text: i18n.t('common:resultatgrpcompteurs.annuler'),
          btnClass: 'btn btn-secondary btn-withIcon',
          icon: UTILS.getSVGIcon("croix", "", 16, null),
          click: (): void => {
            this._popupResponseCallback("n");
            dialogConsu.dialog.dialog("close");
          }
        }];
        dialogConsu.setButtons(btn);
        dialogConsu.open();
      }
    });
  }

  _showBlockCompteurs(): void {
    this.$el.find(".showCompteurs").hide();
    this.$el.find(".hideCompteurs").show();
    this.$el.find(".tableCompteursInstant").show();
    //Resize set fixed height, return it to "auto" for correct visualization
    //this.parent.$el.parent(".ui-dialog").css("height", "auto");
    $(this.el).find(".tableCompteursInstant .bvcompteurs").css({ "max-height": this.heightTwoTable });
    $(this.compteurInst.el).find(".c-grind__scroll").css({ "height": "auto", "max-height": this.heightTwoTableScroll });

    this.$el.find("#compteurs_table").css({ "height": "auto", "max-height": this.heightTwoTable });
    $(this.table.el).find(".c-grind__scroll").css({ "overflow-y": "overlay", "max-height": this.heightTwoTableScroll });
    this.parent.$el.dialog("option", "position", { my: "center", at: "center", of: window });

    this._saveOpenPreference(true);
  }

  _hideBlockCompteurs(): void {
    this.$el.find(".hideCompteurs").hide();
    this.$el.find(".showCompteurs").show();
    this.$el.find(".tableCompteursInstant").hide();
    //Resize set fixed height, return it to "auto" for correct visualization
    //this.parent.$el.parent(".ui-dialog").css("height", "auto");
    this.$el.find("#compteurs_table").css({ "height": "auto", "max-height": this.heightOneTable });
    $(this.table.el).find(".c-grind__scroll").css({ "overflow-y": "overlay", "max-height": this.heightOneTableScroll });
    this.parent.$el.dialog("option", "position", { my: "center", at: "center", of: window });

    this._saveOpenPreference(false);
  }

  _getOpenPreference(callback: (p: boolean) => void): void {
    let open = false;

    this.preferences = new CWPreferences();
    this.preferences.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: "USERS",
    }));
    this.preferences.fetch({
      success: (data: { [key: string]: any }) => {
        const prefsArray = data.get("preferences");
        _.each(prefsArray, (pref: { [key: string]: any }) => {
          if (pref.code === "VALO_AFF_CPTINST") {
            open = pref.varint1 === 1;
          }
        });

        if (callback) {
          callback(open);
        }
      }
    });
  }

  _saveOpenPreference(openByDefault: boolean): void {
    const preferencesArr = this.preferences.get("preferences");
    let found = false;
    _.each(preferencesArr, (pref: { [key: string]: any }) => {
      if (pref.code === "VALO_AFF_CPTINST") {
        found = true;
        pref.varint1 = openByDefault ? 1 : 0;
      }
    });

    if (!found) {
      const newpref: { [key: string]: any } = {
        code: "VALO_AFF_CPTINST",
        varchar1: "",
        varchar2: "",
        varchar3: "",
        varchar4: "",
        varchar5: "",
        vardat1: "",
        vardat2: "",
        vardat3: "",
        vardat4: "",
        vardat5: "",
        varint1: openByDefault ? 1 : 0,
        varint2: null,
        varint3: null,
        varint4: null,
        varint5: null
      }

      preferencesArr.push(newpref);
    }

    this.preferences.set("preferences", preferencesArr);
    this.preferences.save(null, {
      success: (data: { [key: string]: any }) => {
        LOG.debug(data);
      },
      error: (data: { [key: string]: any }) => {
        LOG.debug(data);
      }
    });
  }

  _popupResponseCallback(action: string): void {
    if (action === "y") {
      if (this.popupResponse.date !== "") {
        this.newDate = this.popupResponse.date;
      }
      if (this.popupResponse.groupe !== "") {
        this.newGroupe = this.popupResponse.groupe;
      }

      this.newDemande = this.popupResponse.demande;
      this.newSouhait = this.popupResponse.souhait;

      this._updateTable();
    }
  }

  _refreshDivers(): void {
    if (CWSTR.isBlank(this.configSouhait)) {
      if (GLOBAL_DATA.paramDivers.get("SouhAbs") && GLOBAL_DATA.paramDivers.get("SouhAbs").get("valeur") === "1") {
        this.configSouhait = true;
      } else {
        this.configSouhait = false;
      }
    }
    if (CWSTR.isBlank(this.popupResponse)) {
      if (GLOBAL_DATA.paramDivers.get("demabs_sit") && GLOBAL_DATA.paramDivers.get("demabs_sit").get("valeur") === "1") {
        this.newDemande = true;
        this.newSouhait = true;
      } else {
        this.newDemande = false;
        this.newSouhait = false;
      }
    }
  }

}
