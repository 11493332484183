module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="dialogPersoDeleteCriteria row">\n  <div class="col-lg-12" style="margin-bottom: 24px">\n    <div class="title form-group">\n      <span class="cw-titreBloc">'+
((__t=(i18n.t('plans.planmed.dialog_persodeletecriteria_subtitle')))==null?'':__t)+
'</span>\n    </div>\n    <div class="decription form-group">\n      <span class="cw-texteNormal">'+
((__t=(i18n.t('plans.planmed.dialog_persodeletecriteria_des')))==null?'':__t)+
'</span>\n    </div>\n  </div>\n</div>\n\n\n<!--\n  <div class="buttons">\n    <button type="button" class="btnDelete" value="appliquer">'+
((__t=(i18n.t('plans.planmed.dialog_persodeletecriteria_confirm')))==null?'':__t)+
'</button>\n    <button type="button" class="btnCancel" value="annuler">'+
((__t=(i18n.t('plans.planmed.dialog_persodeletecriteria_cancel')))==null?'':__t)+
'</button>\n  </div>-->\n';
}
return __p;
};
