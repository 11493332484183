import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Model for accept, reject or delete
 */
export class CWGererAbsenceSouhaitGestion extends CWBaseModel {

  action: string;

  defaults(): { [key: string]: any } {
    return {
      "commentaire": ""
    };
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);

      return Configuration.restRoot + "/rest/absence/composant/" + this.action + "/" + id;
    };
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    if (this.action === "refuser") {
      const errors: { [key: string]: any } = {};

      if (CWSTR.isBlank(attrs.commentaire)) {
        errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gererabsencesouhait.comment') });
      }
      if (!_.isEmpty(errors)) {
        const errorMap = {
          errorValidation: CWSTR.formatMessage(errors),
          errors: errors
        };

        return errorMap;
      }
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}