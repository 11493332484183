import _ from 'underscore';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWSTR } from 'utils/cwStr';

/**
 * Model for «Saisie Unites Defs»
 */

export class CWSaisieUniteDef extends CWCombosColl {

  typeevtid: string;
  typegest: string;
  codeabs: string;
  dateper: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.typeevtid = options.typeevtid;
    this.typegest = options.typegest;
    this.codeabs = options.codeabs;
    this.dateper = options.dateper;
    this.url = (): string => {
      let typegest = "";
      let codeabs = "";
      let dateper = "";

      if (!CWSTR.isBlank(this.typegest)) {
        if (this.typegest !== "GTMED") {
          typegest = "&typegest=GTNMED";
        } else {
          typegest = "&typegest=GTMED";
        }
      }
      if (!CWSTR.isBlank(this.codeabs)) {
        codeabs = "&codeabs=" + encodeURIComponent(this.codeabs);
      }
      if (!CWSTR.isBlank(this.dateper)) {
        dateper = "&dateper=" + this.dateper;
      }
      return Configuration.restRoot + "/rest/uniteevenement?typeevtid=" + this.typeevtid + codeabs + typegest + dateper;
    };
  }
}
