import _ from 'underscore';
import TPLCommonTableColMenu from './cwTable_main_menu.tpl.html';
import { CWDataGridView } from '../data_grid/cwDataGrid.view';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { UTILS } from 'utils/utils.js';
import { View, ViewOptions } from 'Backbone';


export class CWColumnsChooserView extends View<CWReadOnlyModel> {

  /**
   * View of the menu that toggles the columns.
   */
  private dataGrid: CWDataGridView;
  private visible: Array<string>;
  private invisible: Array<string>;
  private classPrefix: string;
  private menu: JQuery;

  constructor(options: ViewOptions<CWReadOnlyModel> | any) {
    options.className = options.className ? options.className : "phx-grid-nemu ui-grid-th";
    super(options);
    this.template = TPLCommonTableColMenu;
    this.model = new ReadOnlyModel({
      defaults: {
        mode: ""
      }
    });
    this.dataGrid = options.dataGrid;
    this.visible = [];
    this.invisible = [];
    this.listenTo(this.dataGrid.model, "toggle:column", this._manageIcons);
    this.listenTo(this.dataGrid.model, "lock:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unlock:column", this._showMenuOption);
    this.listenTo(this.dataGrid.model, "block:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unblock:column", this._showMenuOption);
    // common class prefix
    this.classPrefix = "phx-grid-menu-";
  }

  /**
   * Event when click on a column header.
   */
  /**
   * Event when click on a checkbox on the header of the multisel column.
   */
  events(): { [key: string]: any } {
    return {
      "click .button": "_toggleMenu",
      "click li a": "_selectOption"
    };
  }

  /**
   * Render the menu view
   */
  render(): CWColumnsChooserView {
    const dynamic = { text: "", i18nCom: i18n, UTILS: UTILS };

    $(this.el).html(this.template(dynamic));
    this.menu = this._configureMenu();
    this.menu.hide();
    if (this.dataGrid.showMenuIcon === false) {
      this.$el.find(".phx-hd-main-btn").hide();
    }
    return this;
  }

  /**
   * Manage the icon status of the menu to see what columns are displayed or not.
   */
  _manageIcons(columnName: string): void {
    this.menu.find("." + this.classPrefix + columnName.replace(".", "\\.") + " span").toggleClass("phx-icon-planresp-valider");
    this.menu.find("." + this.classPrefix + columnName.replace(".", "\\.") + " span").toggleClass("none");
    this._manageVisibleState(columnName);
  }

  /**
   * Show the column in the menu.
   */
  _showMenuOption(columnName: string): void {
    this.menu.find("li ." + this.classPrefix + columnName.replace(".", "\\.")).parent().show();
  }

  /**
   * Hide a column in the menú.
   */
  _hideMenuOption(columnName: string): void {
    this.menu.find("li ." + this.classPrefix + columnName.replace(".", "\\.")).parent().hide();
  }

  /**
   * Add the columns to the menu list.
   */
  _configureMenu(): JQuery {
    const $menu = $("ul", this.el);

    _.each(this.dataGrid.currentVue.columns, (column, index, columns) => {
      if (index < columns.length && !this.dataGrid.currentVue._columns[column.property].omitMenu && ((column.title && column.title.length > 0 && index === 0) || index > 0)) {
        const $li = $("<li></li>");
        const $a = $("<a class=\"" + this.classPrefix + column.property + "\"></a>");
        const $span = $("<span></span>").addClass("phx-icon phx-icon-planresp-valider");
        const title = (!CWSTR.isBlank(column.menuTitle) && !CWSTR.isBlank(column.menuTitle)) ? column.menuTitle : column.title;

        $a.append($span);
        $a.append(title);
        $li.append($a);
        if (CWSTR.isBlank(title)) { //Only add option to menu if it has some title text
          $li.hide();
        }
        $menu.append($li);
        this.visible.push(column.property);
      }
    }, this);
    $menu.menu();
    $menu.css("cursor", "pointer");
    return $menu;
  }

  /**
   * Manages the column menú click hidding or showing the cilcked column.
   */
  _selectOption(event: JQueryEventObject): void {
    const columnId = event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");

    // If there are only one visible column and try to click it the event is not triggered.
    if (this.visible.length > 1 || _.indexOf(this.visible, columnId) === -1) {
      this.dataGrid.model.trigger("toggle:column", columnId);
    }
    this._toggleMenu();
  }

  _manageVisibleState(columnId: string): void {
    const indexOf = _.indexOf(this.invisible, columnId);

    if (indexOf === -1) {
      this.invisible.push(columnId);
      this.visible.splice(_.indexOf(this.visible, columnId), 1);
    } else {
      this.visible.push(columnId);
      this.invisible.splice(_.indexOf(this.invisible, columnId), 1);
    }
  }

  /**
   * Opens or Closes the menú.
   */
  _toggleMenu(): void {
    if (this.menu.is(":visible")) {
      this.menu.hide();
    } else {
      const $targetEl = $(this.el);

      this.menu.show().position({
        my: "right top",
        at: "bottom",
        of: $targetEl
      });
      $(document).one("mousedown", (event) => {
        const element = $targetEl.find(event.target as any);

        if (element.length === 0) {
          // mousedown out of the button
          this._toggleMenu();
        }
      });
    }
    //		}
  }

  /**
   * Called when backbone removes the view.
   */
  remove(): CWColumnsChooserView {
    const visibleLength = this.visible.length;
    const invisibleLength = this.invisible.length;

    this.dataGrid = null;
    if (this.menu && this.menu.menu("instance")) {
      this.menu.menu("destroy");
      this.menu = null;
    }
    this.visible.splice(0, visibleLength);
    delete this.visible;
    this.invisible.splice(0, invisibleLength);
    delete this.invisible;
    super.remove();
    this.$el.empty();
    return this;
  }

  /**
   * Set the text of an item of the menu
   *
   */
  setMenuItemTitle(property: string, title: string): void {
    const $entry = $("." + this.classPrefix + property, this.$el);

    if ($entry && $entry.length > 0) {
      const $span = $("span", $entry);

      $entry.append($span);
      $entry.append(title);
      if ($entry.parent("li").length > 0 && !$entry.parent("li").is(":visible")) {
        $entry.parent("li").removeClass("ui-menu-divider");
        $entry.parent("li").addClass("ui-menu-item");
        $entry.parent("li").show();
      }
    }
  }
}