import { STR } from 'utils/str.js';

export var DataGridCellView = Backbone.View.extend({

  tagName: "td",

  /**
   * View of the Cells
   */
  initialize: function(options) {
    if (!options.dataGridRow) {
      throw Error("You must use a DataGridCellView just inside a DataGridRowView");
    }
    this.dataGridRow = options.dataGridRow;
    this.text = options.text;
  },

  /**
   * Sets the text attribute and renders the cell.
   */
  setText: function(text) {
    if (!STR.isBlank(text)) {
      this.text = text;
      this.render();
    }
  },

  /**
   * The render function thats generate the TD element
   */
  render: function() {
    //Render a view inside the td
    if (this.text instanceof Backbone.View) {
      this.$el.css("padding", "0");
      this.text = this.text.render().el;
    }

    if (!STR.isBlank(this.text)) {
      this.$el.append(this.text);
    }
    return this;
  }
});
