module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="cw-fieldset gereronebadgeage-detail">\n    <div class="gereronebadgeage-content c-form--fixedButtons">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div class="gererbadgeage-form-inputs c-form__scrollZone">\n            <div class="optionsHeader form-group">\n                <div class="gereronebadgeage-utilisateur cw-titreLight"></div>\n                <span class="gereronebadgeage-date"></span>\n                <!-- <span class="gereronebadgeage-action"></span> -->\n            </div>\n\n            <div class="optionsDate form-group">\n                <div class="form-row">\n                    <div class="form-group col-8">\n                        <label class="cw-required cw-libelleEnrichi">\n                            '+
((__t=( UTILS.getSVGIcon("periode", "", 16) ))==null?'':__t)+
'\n                            '+
((__t=(i18n.t("common:gererbadgeages.date")))==null?'':__t)+
'\n                        </label>\n                        <label for="saisie.date" class="sr-only">'+
((__t=(i18n.t("common:gereronebadgeage.le")))==null?'':__t)+
'</label>\n                        <div class="c-date-selector input-group">\n                            <div class="input-group-prepend">\n                                <div for="saisie.date" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereronebadgeage.le")))==null?'':__t)+
'</div>\n                            </div>\n                            <input type="text" class="saisie.date typeDate form-control required" value="" size="17" />\n                        </div>\n                    </div>\n                    <div class="form-group col-4">\n                        <label class="cw-required cw-libelleEnrichi">'+
((__t=(i18n.t("common:gererbadgeages.heure")))==null?'':__t)+
'</label>\n                        <label for="saisie.heure" class="sr-only">'+
((__t=(i18n.t("common:gereronebadgeage.a")))==null?'':__t)+
'</label>\n                        <div class="input-group">\n                            <div class="input-group-prepend">\n                                <div for="saisie.heure" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:gereronebadgeage.a")))==null?'':__t)+
'</div>\n                            </div>\n                            <input class="saisie.heure form-control typeHourMinuteNightly required" value="" type="text" size="12" />\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class="optionsSense form-group">\n                <label for="saisie.sens" class="cw-libelleEnrichi cw-required">'+
((__t=(i18n.t('common:gereronebadgeage.sens')))==null?'':__t)+
'</label>\n                <span class="cmbSens required"></span>\n                <span class="motif.code-error-container"></span>\n            </div>\n\n            <div class="optionsMotif form-group">\n                <label for="motif.code" class="cw-libelleEnrichi">'+
((__t=(i18n.t('common:gereronebadgeage.motif')))==null?'':__t)+
'</label>\n                <span class="cmbMotif"></span>\n                <span class="motif.code-error-container"></span>\n            </div>\n\n            <div class="optionsFonction form-group">\n                <label for="fonction" class="cw-libelleEnrichi">'+
((__t=(i18n.t('common:gereronebadgeage.fonction')))==null?'':__t)+
'</label>\n                <input type="text" class="fonction form-control" value="" size="10" maxlength="10" />\n            </div>\n\n            <div class="blockBadgeCommentaire form-group">\n                <label for="commentaire" class="cw-libelleEnrichi">'+
((__t=(i18n.t('common:gereronebadgeage.comment')))==null?'':__t)+
'</label>\n                <textarea class="commentaire form-control" maxlength="60" cols="36" rows="5" style="resize:none"></textarea>\n            </div>\n\n            <!-- <div class="wkfhisto form-group"></div>\n            <span class="gereronebadgeage-wkfhisto"></span> -->\n        </div>\n    </div>\n    <!-- <ul class="validerListMenu menuContent"></ul> -->\n  </fieldset>\n';
}
return __p;
};
