import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';

/**
 * Model for Brique 'Situations particulières'
 */
/**
 * Model used to show the data of situations particulieres
 */
export class CWSituationModel extends CWReadOnlyModel {

  defaults(): { [key: string]: any } {
    return {
      "nbrang": 10,
      "total": null,
      "index": 1,
      "situation": {
        "date": "",
        "heure": "",
        "libelle": "",
        "commentaire": "",
        "type": "",
        "calcul": ""
      }
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/brique/situation?tri=date;asc,libelle;asc,commentaire;asc";
    };
  }
}