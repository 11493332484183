import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWDroitModel } from './cwDroit.model';
import { CWSTR } from 'utils/cwStr';
import { objs } from 'src/objectsRepository';


/**
 * Droits Collection. Get all right of a user.
 */
export class CWDroitColl extends Backbone.Collection<CWDroitModel>{

  profile: string;
  parentProfil: string;
  menuVisibility: Backbone.Model;

  constructor(models?: CWDroitModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWDroitModel;
    options.profile = options.profile || null;
    options.parentProfil = options.parentProfil || null;
    options.menuVisibility = options.menuVisibility || new Backbone.Model;
    super(models, options);
    this.profile = options.profile;
    this.parentProfil = options.parentProfil;
    this.menuVisibility = options.menuVisibility;
    this.url = (): string => {
      let Prof = "";

      if (!CWSTR.isBlank(this.profile) && !CWSTR.isBlank(this.parentProfil)) {
        Prof = "/" + encodeURIComponent(this.parentProfil) + "?hierarchique=" + this.profile;
      } else if (!CWSTR.isBlank(this.profile)) {
        Prof = "/" + encodeURIComponent(this.profile);
      }
      return Configuration.restRoot + "/rest/configuration/droit" + Prof;
    };
  }

  initialize(models?: CWDroitModel[] | { [key: string]: any }[], options?: { [key: string]: any }): void {
    if (options) {
      this.profile = options.profile;
      this.parentProfil = options.parentProfil;
      this.menuVisibility = options.menuVisibility;
    }
  }

  /**
   * This function retrieve a boolean which means if the current profile has at least one
   * "responsable" habilitation (which start by RES)
   */
  isRespo(): boolean {
    return this.some((m: CWDroitModel): boolean => {
      return (m.get("fonction").search("RES") === 0);
    });
  }


  parse(droits: { [key: string]: any }): { [key: string]: any } {
    if (droits && droits.length > 0 && CWSTR.isBlank(droits[0].id)) {
      //Après cette action, la collection aura l'information bien sur "_byId" pour faire les ".get(id)"
      for (let i = 0; i < droits.length; i++) {
        droits[i].id = droits[i].fonction;
      }
    }
    this.calculateMenuAccess(droits);
    return droits;
  }

  calculateMenuAccess(droitsOrigin: { [key: string]: any }): void {
    const ecranHidden: { [key: string]: any } = {};
    const menusEmid: string[] = [];
    let zoneHidden: boolean[] = [];
    const predicate = (b: any): any => {
      return b;
    };
    let droitsResponse = _.clone(droitsOrigin);
    const predicateSearchDroit = (m: string): boolean => {
      return droitsResponse.indexOf(m) > -1;
    };
    const handleMenu = (menu: { [key: string]: any }): void => {
      if (menu.id) {
        // saisiescoll must have at least one function allowed, not all
        const _tmp = menu.fonctions.some(predicateSearchDroit);
        let screenId = menu.id;
        if (menu.aspect === "hidden") {
          screenId = screenId + "_R";
        }
        zoneHidden.push(_tmp);
        if (menusEmid.length > 0) { //into submenu					
          ecranHidden[menusEmid[menusEmid.length - 1]].push(_tmp);
        }
        else {
          ecranHidden[menu.emid] = [_tmp];
        }
        this.menuVisibility.set(menu.emid, _tmp);
        this.menuVisibility.set(screenId, _tmp);
      } else {
        menusEmid.push(menu.emid);
        ecranHidden[menu.emid] = [];
        menu.ecrans.forEach(handleMenu, this);
        if (ecranHidden[menu.emid].some(predicate)) {
          zoneHidden.push(true);
          if (menusEmid.length > 1) {//submenu son
            ecranHidden[menusEmid[0]].push(true);
          }
          this.menuVisibility.set(menu.emid, true);
        } else {
          zoneHidden.push(false);
          if (menusEmid.length > 1) {//submenu son
            ecranHidden[menusEmid[0]].push(false);
          }
          this.menuVisibility.set(menu.emid, false);
        }
        menusEmid.pop();
      }
    };

    droitsResponse = droitsResponse.map((m: { [key: string]: any }): string => {
      return m.fonction;
    });
    objs.appRt.workflow.configuration.get("confignav").get("zones").forEach((zone: { [key: string]: any }): void => {
      zoneHidden = [];
      zone.menus.forEach(handleMenu, this);
      zone.briques.forEach((b: { [key: string]: any }): void => {
        const _tmp = b.fonctions.every(predicateSearchDroit);
        this.menuVisibility.set(b.emid, _tmp);
      }, this);
      if (!zoneHidden.some(predicate)) {
        this.menuVisibility.set(zone.emid, false);
        this.menuVisibility.set(zone.id, false);
      } else {
        this.menuVisibility.set(zone.emid, true);
        this.menuVisibility.set(zone.id, true);
      }
    }, this);
    objs.appRt.workflow.configuration.get("confignav").get("transverses").forEach(handleMenu, this);
  }
}
