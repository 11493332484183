import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseCollection } from 'core/models/cwBase.collection';

/**
 * Coll representing the valid typeevenements for a Recapitulatif
 */
export class CWReadTypeEvenementCollection extends CWBaseCollection {

  idRecapitulatif: string;

  constructor(models?: Backbone.Model[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || Backbone.Model;
    super(models, options);
    this.idRecapitulatif = options.idRecapitulatif || "";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/activite/typeevt?filtre_recapitulatif=" + encodeURIComponent(this.idRecapitulatif);
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      const lColl = [];
      const lkeys = _.keys(response);

      for (let i = 0; i < lkeys.length; i++) {
        const element: { [key: string]: any } = { "id": lkeys[i], "code": lkeys[i], "actprev": response[lkeys[i]].actprev, "actreal": response[lkeys[i]].actreal };

        element.typeevt = [];
        if (response[lkeys[i]].actprev === true) {
          element.typeevt.push("P");
        }
        if (response[lkeys[i]].actreal === true) {
          element.typeevt.push("R");
        }
        lColl.push(element);
      }
      if (lColl.length > 0) {
        response = lColl;
      }
    }
    return response;
  }
}
