import * as Backbone from 'Backbone';
import _ from 'underscore';
import referentielFamilleActiviteDetailTPL from '../cwDetail.tpl.html';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWFamilleActiviteColl } from '../models/cwFamilleActivite.collection';
import { CWFamilleActiviteModel } from '../models/cwFamilleActivite.model';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSelecteurRefDialogView } from './cwSelecteurRefDialog.view';
import { CWSelecteurReferentielView } from 'core/components/selecteur_referentiel/cwSelecteurReferentiel.view.js';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Select collab view
 */
export class CWSelectFamilleActiviteView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<CWBaseModel> {

  host: Backbone.View;
  idComponent: string;
  required: boolean;
  context: { [key: string]: any };
  fieldWidth: string;
  habContext: CWHabilitationContext;
  referentiel: typeof CWSelecteurReferentielView;
  $appendTo: JQuery;
  idName: string;
  infoPlaceholder: string;

  constructor(options?: Backbone.ViewOptions<TModel> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, options.events);
    super(options);
    this.template = referentielFamilleActiviteDetailTPL;
    this.host = options.host;
    this.idComponent = options.idComponent;
    this.required = _.isBoolean(options.required) ? options.required : false;
    this.context = options.context;
    this.fieldWidth = options.fieldWidth; // Width of the ref input default is "100%"
    this.model = new CWFamilleActiviteModel();
    this.habContext = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.Habilitation,
      natGest: this.context.ctxNature
    });
    this.model.setHabContext(this.habContext);
    this.referentiel = null;
    this.$appendTo = !CWSTR.isBlank(options.appendTo) ? options.appendTo : null;
    this.idName = options.idName;
    this.infoPlaceholder = options.infoPlaceholder;
  }

  render(): CWSelectFamilleActiviteView<CWBaseModel> {
    this.referentiel = new CWSelecteurReferentielView({
      coll: new CWFamilleActiviteColl(),
      name: (!CWSTR.isBlank(this.idName) ? this.idName : "famille.code"),
      title: i18n.t('common:referentiel_famille_activite.title_selectref'),
      itemRenderer: (model: { [key: string]: any }): string => {
        let rtn = "";

        if (!_.isEmpty(model)) {
          if (!CWSTR.isBlank(model.code) && !CWSTR.isBlank(model.libelle)) {
            rtn = !CWSTR.isBlank(model.libelle) ? model.libelle + " (" + model.code + ")" : model.code;
          } else if (!CWSTR.isBlank(model.code)) {
            rtn = model.code;
          } else if (!CWSTR.isBlank(model.libelle)) {
            rtn = model.libelle;
          }
        }
        return rtn;
      },
      required: this.required,
      view: CWSelecteurRefDialogView,
      width: 600,
      height: 523,
      addItemCallback: (data: CWFamilleActiviteModel): void => {
        this._responseCallback(data);
      },
      fieldWidth: this.fieldWidth,
      listWidth: "auto",
      appendTo: this.$appendTo,
      placeholder: this.infoPlaceholder
    });
    this.$el.html(this.template());
    // Selecteur Referentiel    
    this.referentiel.coll.setHabContext(this.habContext);
    this.$el.find(".cmbSelectionner").html(this.referentiel.render().el);
    return this;
  }

  _responseCallback(data: CWFamilleActiviteModel): void {
    this.model.set(data);
    if (this.host && (this.host as any)._responseCallbackFamilleActivite) {
      (this.host as any)._responseCallbackFamilleActivite(data, this.idComponent);
    }
  }

  initWithCode(code: string): void {
    const originalModel = new CWFamilleActiviteModel();
    const originalCollection = new Backbone.Collection();

    originalModel.setHabContext(this.habContext);
    originalModel.id = code;
    originalModel.fetch({
      success: (fresh: CWFamilleActiviteModel): void => {
        originalCollection.push(fresh);
        this.referentiel.setValues(originalCollection);
      }
    });
  }

  remove(): Backbone.View<CWBaseModel> {
    this.host = null;
    return super.remove(); // Remove view from DOM
  }

  clean(): void {
    if (this.referentiel) {
      this.referentiel.clean();
    }
  }
}
