import _ from 'underscore';
import TPLListerpretsDetail from '../detail.tpl.html';
import { BlockView } from 'common/wkfhisto/views/block.view.js';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWEditableGridModel } from 'core/grids/editablegrid/cwEditableGrid.model';
import { CWEditableGroupeGridView } from 'core/grids/editablegroupegrid/cwEditableGroupeGrid.view';
import { CWEtiquette, validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { CWGererPiecesJointesView } from 'common/evenements/gerer/gererpiecesjointes/views/cwGererPiecesJointes.view';
import { CWGererPretView } from 'common/evenements/gerer/gererprets/views/cwGererPret.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWHeaderPopupView } from './cwHeaderPopup.view';
import { CWListerpretsWorkflow } from 'src/uc/common/evenements/lister/listerprets/models/cwListerprets.workflow';
import { CWMenuGridModel } from 'src/core/grids/menugrid/cwMenuGrid.model';
import { CWMenuGridView } from 'src/core/grids/menugrid/cwMenuGrid.view';
import { CWPanneauDeroulant } from 'src/core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPretColl } from '../models/cwPret.collection';
import { CWPretModel } from 'src/uc/common/evenements/lister/listerprets/models/cwPret.model';
import { CWRowPopupView } from './cwRowPopup.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWVoletView } from 'src/core/components/dialog/volet/cwVolet.view';
import { EVENEMENT } from 'utils/evenement.js';
import { i18n } from 'src/i18n.js';
import { ListerHistoriqueModel } from '../models/listerHistorique.model.js';
import { LOG } from 'utils/log.js';
import { objs } from 'src/objectsRepository';
import { ProgressBarModel } from '../models/progressBar.model.js';
import { ResumePopUpView } from './resumePopUp.view.js';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';


export class CWFormView extends CWBaseFormView {
  /**
   * Add this to have Outline
   *
   */
  histoInfoBulles: any;
  titleCont: any;
  workflow: CWListerpretsWorkflow;
  context: any;
  emptyMessage: any;
  model: CWBaseModel | any;
  historiqueModel: any;
  wkfEvenements: any;
  table: CWEditableGroupeGridView | any;
  copyModelsState: any;
  isSortedAsc: boolean;
  headerDialog: CWPanneauDeroulant;
  rowDialog: any;
  popupGerePretDialog: CWVoletView;
  usePopulation: boolean;
  managingVueChange: boolean;
  pjPopupView: CWDialogPopupView;
  resumeDialog: CWDialogPopupView;
  private $appendTo: JQuery; //pour les messages

  constructor(options: any) {
    options = options || {};
    options.events = _.extend({
      "click .linkCom": "_openRowPopup",
      "click .checkBoxHeader": "_manageHeaderCheckbox",
      "click .checkBoxLine": "_manageLineCheckbox",
      "click button": "_clickListener",
      "change input[class*='comboProfil']": "_manageRowProfil",
      "click .piece-jointe": "_managePieceJointe",
      "mouseover .infoBulleStatut": "_paintStatusInfoBull"
    });
    super(options);
    this.template = TPLListerpretsDetail;
    // Auxi lets to print the historification title after the data is loaded.
    this.histoInfoBulles = [];
    this.titleCont = 0;
    this.workflow = options.workflow;
    this.$appendTo = (this.workflow && this.workflow.context && !CWSTR.isBlank(this.workflow.context.ctxEcran)) ? $("#" + this.workflow.context.ctxEcran) : null;
    this.context = options.context;
    this.emptyMessage = options.emptyMessage;
    this.model = new CWBaseModel({
      // This values are set in the workflow.
      progressBarModel: new ProgressBarModel(),
      globalComment: "",
      isForced: false
    });
    this.model.setHabContext(new CWHabilitationContext());
    this.historiqueModel = new ListerHistoriqueModel();
    if (this.workflow && this.workflow.getHabContext()) {
      this.historiqueModel.setHabContext(this.workflow.getHabContext());
    }
    this.wkfEvenements = null;
    // Output mode of characteristics and grille:
    if (this.context && this.context.ctxPresentationGrille && this.context.ctxPresentationGrille === "Neutralisee") {
      // Regroupé & grille Neutralisée
      this.table = this._initTableEmprunts(this.emptyMessage);
    } else {
      // Éclaté & grille Habillée
      this.table = this._initTable(this.emptyMessage);
    }
    this.model.on("cleanGridErrors", this._cleanErrorsTable, this);
    this.table.model.coll.on("revert:tableNotProfils", () => { this._revertTable(false); }, this);
    //this.table.model.coll.on("row:click", this._detailPret, this);
    this.table.model.coll.on("revert:table", () => { this._revertTable(true); }, this);
    this.table.model.coll.on("row:click", this._detailPret, this);
    this.copyModelsState = [];
    // To manage the btnBar, we initialize the counter to have the number of prets to treat.
    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.isSortedAsc = true;
  }

  _hideZonesGrid(): void {
    //header
    $(this.table.el).find(".phx-hd-btn").hide();
    $(this.table.el).find(".phx-grid-title-bar").hide();
    $(this.table.el).find(".phx-grid-header-scroll").hide();
    //footer
    $(this.table.el).find(".phx-grid-footer-left").hide();
    $(this.table.el).find(".phx-grid-footer-right").hide();
  }

  resetAfterContextChange(): void {
    // To manage the btnBar, we initialize the counter to have the number of prets to treat.
    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.copyModelsState = this._copyModels(this.table.model.coll);
    this.workflow.btnBarModel.set("mode", "R");
    $(this.el).find(".checkBoxHeader.R").prop("checked", false);
    $(this.el).find(".checkBoxHeader.A").prop("checked", false);
    CWSTR.setElValue(this.model, "globalComment", "");
  }

  _revertTable(all: any): void {
    let revertAll = false;
    const tableColl = this.table.model.coll;

    if (all === true) {
      revertAll = true;
    }
    _.each(tableColl.models, (model) => {
      this._revertRow(model, revertAll);
    });
    CWSTR.setElValue(this.model, "globalComment", "");
    $(this.el).find(".checkBoxHeader.A").prop("checked", false);
    $(this.el).find(".checkBoxHeader.R").prop("checked", false);
    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.copyModelsState = this._copyModels(this.table.model.coll);
    this._displayHeaderLeftText();
    this._displayHeaderRightText();
    this.table.model.coll.trigger("updateHeader:coll");
    this.table.model.coll.trigger("display:alert", "hide");
    this._cleanErrorsTable();
    this.workflow.btnBarModel.set("mode", "R");
  }

  _revertRow(model: any, all: any): void {
    CWSTR.setElValue(model, "commentaire", "");
    if (model.recEtatValid === "A") {
      $(this.el).find(".checkBoxLine.A").prop("checked", false);
    } else if (model.recEtatValid === "R") {
      $(this.el).find(".checkBoxLine.R").prop("checked", false);
    }
    model.recEtatValid = "";
    if (all === true) {
      const comboProfil = $(this.el).find(".comboProfil_" + model.cid);

      if (comboProfil.length > 0 && model.selectedProfil && model.selectedProfil.code !== "0") {
        const item = $(this.el).find(".comboProfil_" + model.cid).prop("viewRef");

        item.setItem("");
        model.selectedProfil = {
          code: "0",
          libelle: ""
        };
      }
    }
  }

  _openHeaderPopup(event: any): any {
    const comment = this.model.get("globalComment");
    const isforced = this.model.get("isForced");
    const action = event.currentTarget.className.split(" ")[1];

    if (this.headerDialog === undefined) {
      this.headerDialog = new CWPanneauDeroulant({
        view: CWHeaderPopupView,
        viewData: { commentaire: comment, isForced: isforced, action: action }
      });
    } else {
      this.headerDialog.viewData = { commentaire: comment, isForced: isforced, action: action };
    }
    const btn = [{
      text: i18n.t('common:listerprets.btnValider'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        this.headerDialog.getInternalViewObject().trigger("btnResult", "btnValider");
      }
    }, {
      text: i18n.t('dabsences.annuler'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        this.headerDialog.getInternalViewObject().trigger("btnResult", "btnRevert");
      }
    }];
    this.headerDialog.setButtons(btn);
    this.headerDialog.setHeight(430);
    this.headerDialog.setWidth(500);

    const dialogModel = this.headerDialog.model;
    dialogModel.trigger("dlg:open", () => {
      if (dialogModel.get("isCommentChanged") === true) {
        const newCommentaire = dialogModel.get("globalComment");
        const isForced = dialogModel.get("isForced");
        this._manageComments(newCommentaire, isForced);
        this.model.set("globalComment", newCommentaire);
        this.model.set("isForced", isForced);
        this.copyModelsState = this._copyModels(this.table.model.coll);
      }
    });
    this.headerDialog._setTitle(i18n.t('common:listerprets.commentaireGlobalPopup'));
  }

  _openRowPopup(event: any): void {
    const id = event.currentTarget.className.split(" ")[2];
    let model = this.table.model.coll.get(id);
    if (model === undefined) {
      $.each(this.table.model.coll.models, (i: any, line: any) => {
        if (UTILS.escapeJQueryString(line.get("code")) === id) {
          model = line;
        }
      });
    }
    const comment = !CWSTR.isBlank(model.get("commentaire")) ? model.get("commentaire") : "";
    const refEvenement = model.get("evenement");

    if (this.rowDialog === undefined) {
      this.rowDialog = new CWDialogPopupView({
        view: CWRowPopupView,
        viewData: {
          commentaire: comment,
          action: model.recEtatValid,
          refEvenement: refEvenement,
          workflow: this.workflow,
          appendTo: this.$appendTo
        }
      });
    } else {
      this.rowDialog.viewData = {
        commentaire: comment,
        action: model.recEtatValid,
        refEvenement: refEvenement,
        workflow: this.workflow
      };
    }

    this.rowDialog.setHeight(430);
    this.rowDialog.setWidth(500);

    const dialogModel = this.rowDialog.model;
    dialogModel.trigger("dlg:open", () => {
      if (dialogModel.get("isCommentChanged") === true) {
        const commentaire = dialogModel.get("commentaire");
        model.set("commentaire", commentaire);
        this.copyModelsState = this._copyModels(this.table.model.coll);
      }
    });
    this.rowDialog._setTitle(i18n.t('common:listerprets.commentairePopup'));
  }

  /*
   *  Change the comment in each absence if it is selected and it has the old global comment.
   *
   */
  _manageComments(newCommentaire: any, isForced: any): void {
    const ancientCommentaire = this.model.get("globalComment");
    const tableColl = this.table.model.coll;
    _.each(tableColl.models, (tableRow: any) => {
      if (!CWSTR.isBlank(tableRow.recEtatValid)) {
        if (isForced === true) {
          tableRow.set("commentaire", newCommentaire);
        } else if (tableRow.get("commentaire") === ancientCommentaire || CWSTR.isBlank(tableRow.get("commentaire"))) {
          tableRow.set("commentaire", newCommentaire);
        }
      }
    });
  }

  _manageHeaderCheckbox(event: any): void {
    const checked = event.currentTarget.checked;
    const action = event.currentTarget.className.split(" ")[1];

    if (checked === true) {
      this.table.model.coll.nbrecEtatValid = 0;
      this.table.model.coll.nbrecEtatA = 0;
      this.table.model.coll.nbrecEtatR = 0;
    }

    this._manageAllRows(action, checked);
    this._manageBtnBarActivation();
    this._manageFiltreEnabled();
    this._displayHeaderLeftText();
    this._displayHeaderRightText();
    this._checkNbAlert();
    this.copyModelsState = this._copyModels(this.table.model.coll);

    switch (action) {
      case "A":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.A").prop("checked", true);
          $(this.el).find(".checkBoxLine.R").prop("checked", false);
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
          this._openHeaderPopup(event);
        } else {
          $(this.el).find(".checkBoxLine.A").prop("checked", false);
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
        }
        break;
      case "R":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.R").prop("checked", true);
          $(this.el).find(".checkBoxLine.A").prop("checked", false);
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
          this._openHeaderPopup(event);
        } else {
          $(this.el).find(".checkBoxLine.R").prop("checked", false);
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
        }
        break;
      default:
        break;
    }
    this.table.model.coll.trigger("updateHeader:coll");
  }

  _checkNbAlert(): void {
    let nbAlert = this.workflow.pDiversValRAalertModel.get("valeur");
    const nbChecked = this.table.model.coll.nbrecEtatValid;

    if (CWSTR.isBlank(nbAlert)) { nbAlert = 5; } // Default Value

    if (nbChecked >= nbAlert) {
      this.table.model.coll.trigger("display:alert", "show");
    } else {
      this.table.model.coll.trigger("display:alert", "hide");
    }
  }

  _manageAllRows(action: any, checked: any): void { // action A, R, ""
    const tableColl = this.table.model.coll;

    _.each(tableColl.models, (tableRow: any) => {
      tableRow.recEtatValid = "";
    });

    _.each(tableColl.models, (tableRow: any) => {
      this._manageRow(tableRow, action, checked);
    });
    this.copyModelsState = this._copyModels(this.table.model.coll);
  }

  _manageRow(model: any, action: any, checked: any): void { // action A, R, ""
    const tableColl = this.table.model.coll;
    if (checked === true) {
      if (model.recEtatValid === "R") {
        tableColl.nbrecEtatR--;
        tableColl.nbrecEtatValid--;
      } else if (model.recEtatValid === "A") {
        tableColl.nbrecEtatA--;
        tableColl.nbrecEtatValid--;
      }

      model.recEtatValid = action === "A" ? "A" : "R";
      if (action === "A") {
        tableColl.nbrecEtatA++;
      } else {
        tableColl.nbrecEtatR++;
      }
      model.nbrecEtatValid = action === "A" ? tableColl.nbrecEtatA : tableColl.nbrecEtatR;
      tableColl.nbrecEtatValid++;
    } else {
      if (action === "A") {
        tableColl.nbrecEtatA--;
      } else {
        tableColl.nbrecEtatR--;
      }
      model.nbrecEtatValid = action === "A" ? tableColl.nbrecEtatA : tableColl.nbrecEtatR;
      model.recEtatValid = "";
      tableColl.nbrecEtatValid--;
    }
  }

  _manageBtnBarActivation(): void {
    const tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this._cleanErrorsTable();
      this.workflow.btnBarModel.set("mode", "R");
    } else {
      this.workflow.btnBarModel.set("mode", "E");
    }
  }

  _manageFiltreEnabled(): void {
    const tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this.workflow.enableFiltering();
    } else {
      this.workflow.disableFiltering();
    }
  }

  _displayHeaderLeftText(): void {
    const context = this.workflow.context;
    if (context && context.ctxGestionCollective === true && context.ctxValidation === true) {
      this.table.model.coll.trigger("display:leftText", "show");
    } else {
      this.table.model.coll.trigger("display:leftText", "hide");
    }
  }

  _displayHeaderRightText(): void {
    const tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this.table.model.coll.trigger("display:rightText", "hide");
    } else {
      this.table.model.coll.trigger("display:rightText", "show");
    }
  }

  _manageLineCheckbox(event: any): void {
    const checked = event.currentTarget.checked;
    const action = event.currentTarget.className.split(" ")[1];
    const id = event.currentTarget.className.split(" ")[2];

    let model = this.table.model.coll.get(id);
    if (model === undefined) {
      $.each(this.table.model.coll.models, (i: any, line: any) => {
        if (UTILS.escapeJQueryString(line.get("code")) === id) {
          model = line;
        }
      });
    }
    this._manageRow(model, action, checked);
    this._manageBtnBarActivation();
    this._manageFiltreEnabled();
    this._displayHeaderLeftText();
    this._displayHeaderRightText();
    this._checkNbAlert();

    this.copyModelsState = this._copyModels(this.table.model.coll);
    switch (action) {
      case "A":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.R." + id).prop("checked", false);
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
        } else {
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
        }
        break;
      case "R":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.A." + id).prop("checked", false);
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
          this._openRowPopup(event);
        } else {
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
        }
        break;
      default:
        break;
    }

    if (this._checkAllRowsActived(action) === true) {
      $(this.el).find(".checkBoxHeader." + action).prop("checked", true);
    }
    if (!checked || model.isValid()) {
      model.trigger("cleanError", model);
    }
    this.table.model.coll.trigger("updateHeader:coll");
  }

  _checkAllRowsActived(action: any): boolean {
    const arrayCheck: any = $(this.el).find(".checkBoxLine." + action);
    for (let i = 0; i < arrayCheck.length; i++) {
      if (arrayCheck[i].checked === false) {
        return false;
      }
    }
    return true;
  }

  render(): CWFormView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));

    if (this.context && this.context.ctxPresentationGrille && this.context.ctxPresentationGrille === "Neutralisee") {
      return this.renderNeutralisee();
    }
    $(this.el).find(".tablePrets").html(this.table.el);
    this.table.render();

    //this.table.setTitle(this._getTitle());
    this.table.model.coll.trigger("display:alert", "hide");
    this.table.model.coll.trigger("display:rightText", "hide");
    this.table.model.coll.trigger("display:leftText", "hide");

    return this;
  }

  renderNeutralisee(): any {
    this.table.model.coll.fetch({
      success: (fresh: any): any => {
        // allows bemprunt to clone collection
        fresh.trigger("ready");
      },
    });
    $(this.el).find(".tablePrets").html(this.table.el);
    this.table.render();
    // mode grille Neutralisee
    this._hideZonesGrid();

    return this
  }

  _clickListener(event: any): void {
    const btnClicked = event.currentTarget.value;
    this.model.get("progressBarModel").trigger("btn:click", btnClicked);
  }

  _showGridValidationErrors(model: any, errors: any): void {
    if (errors && _.has(errors, "errorValidation") && _.has(errors, "errors")) {
      const fieldErrors = errors.errors;
      const comboName = "comboProfil_" + model.cid;
      const combo = $(this.el).find("." + comboName);
      const td = combo.closest('td');
      CWFORMS.showErrors(td, fieldErrors);
    }
  }

  _cleanGridValidationErrors(model: any): void {
    const comboName = ".comboProfil_" + model.cid;
    const combo = $(this.el).find(comboName);
    const td = combo.closest('td');
    CWFORMS.cleanErrors(td);
  }

  /**
   * Open detail du pret
   */

  _detailPret(model: any): void {
    const pretModel = model;

    if (model && (!this.popupGerePretDialog || !this.popupGerePretDialog.isOpen())) {
      const context: any = {};

      context.ctxUtilisateur = this.workflow.context.ctxUtilisateur;
      if (pretModel.get("emprunt") === true) {
        context.ctxPerimetre = pretModel.get("perimetre").code;
        context.ctxTypeEvtGere = "E";
      } else {
        context.ctxPerimetre = "";
        context.ctxTypeEvtGere = "P";
      }
      context.ctxHabilitation = {};
      if (this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR) {
        context.ctxHabilitation.HabilitationAcces = "COL_PRET.V";
        context.ctxHabilitation.HabilitationGestion = "";
        context.ctxHabilitation.HabilitationValidation = "";
        context.ctxActionsPossibles = "";
      } else {
        context.ctxHabilitation.HabilitationAcces = "RES_PRET.V";
        context.ctxHabilitation.HabilitationValidation = "RES_VAL_PRET.G";
        context.ctxActionsPossibles = ["Modifier", "Supprimer", "Valider"];
        if (context.ctxTypeEvtGere === "P") {
          context.ctxHabilitation.HabilitationGestionNormal = "RES_PRET_N.G";
          context.ctxHabilitation.HabilitationGestionEtendu = "RES_PRET_E.G";
        } else {
          context.ctxHabilitation.HabilitationGestionNormal = "RES_EMPR_N.G";
          context.ctxHabilitation.HabilitationGestionEtendu = "RES_EMPR_E.G";
        }
      }
      context.ctxEcran = this.workflow.context.ctxEcran;
      context.ctxModeRepresentation = "pop-up";
      context.ctxCollab = {};
      context.ctxCollab.matricule = pretModel.get("collaborateur").matric;
      context.ctxCollab["matric_aux"] = pretModel.get("collaborateur").matricaux;
      context.ctxCollab.nom = pretModel.get("collaborateur").nom;
      context.ctxCollab.prenom = pretModel.get("collaborateur").prenom;
      context.ctxListeCollab = "";
      context.ctxModeInitialisation = "Consultation";
      context.ctxIdentifiantEvt = pretModel.get("code");
      context.ctxHistoriqueWKF = true;
      context.ctxPieceJointe = (this.workflow.paramDivPieceJointe.get("valeur") === "1");
      if (!CWSTR.isBlank(pretModel.get("collabremplace")) && !CWSTR.isBlank(pretModel.get("collabremplace").matric)) {
        context.ctxCollabReference = {};
        context.ctxCollabReference.matricule = pretModel.get("collabremplace").matric;
        context.ctxCollabReference["matric_aux"] = pretModel.get("collabremplace").matricaux;
        context.ctxCollabReference.nom = pretModel.get("collabremplace").nom;
        context.ctxCollabReference.prenom = pretModel.get("collabremplace").prenom;
        context.ctxCollabReference.datedeb = pretModel.get("collabremplace").datedeb;
        context.ctxCollabReference.datefin = pretModel.get("collabremplace").datefin;
      }
      context.ctxPerimetreFige = false;
      if (!CWSTR.isBlank(this.workflow.context.ctxSimulation)) {
        context.ctxHabilitation.HabilitationValidation = "";
        context.ctxSimulation = this.workflow.context.ctxSimulation;
        context.ctxEvtEstSimule = this.workflow.context.ctxEvtEstSimule;
        context.ctxDonneeSimulee = this.workflow.context.ctxDonneeSimulee;
        context.ctxPerimetreFige = true;
        context.ctxHistoriqueWKF = false;
        context.ctxPieceJointe = false;
        if ( /*this.context.ctxJourEstSimule == 3 && */ CWHABILITATION.canView("RES_PLANSIM.G")) {
          context.ctxActionsPossibles = ["Modifier", "Supprimer"];
        } else {
          context.ctxActionsPossibles = [];
        }
      }
      this._initPopupGererPret(context);
      this.popupGerePretDialog.open();
      this.listenTo(this.popupGerePretDialog.internalView.model, "pretChanged", (model: any, action: any) => {
        this.workflow.setUp();
        this.workflow.trigger("listerpretsUpdated");
        this.workflow.trigger("pretChanged", model, action);
      });
    }
  }

  _initPopupGererPret(context: any): any {
    if (this.popupGerePretDialog === undefined) {
      this.popupGerePretDialog = new CWVoletView({
        view: CWGererPretView,
        viewData: {
          context: context
        }
      });
    } else {
      this.popupGerePretDialog.viewData = { context: context };
    }
    this.popupGerePretDialog.setHeight("auto");
    this.popupGerePretDialog.setWidth(600);
    return this.popupGerePretDialog;
  }

  _initTableEmprunts(emptyMessage: any): CWDataGridView {

    const tableModel = new CWEditableGridModel<CWPretColl<CWPretModel>, CWPretModel>({
      coll: new CWPretColl(),
      emptyMessage: emptyMessage
    });

    const tblColumnsSynt: Array<CWBaseGridColums> = [
      { title: "Collab demande Emprunt", property: "matricule", width: 3 },
      { title: "periode,statud,rattachemment", property: "composed", width: "auto" }
    ];

    const table = new CWDataGridView({
      id: "listeEmprunts",
      columns: tblColumnsSynt,
      model: tableModel,
      columnsChooser: false,
      removeGridStyle: true,
      title: null,
      showFilteredRowsInTitle: false,
      maxVisibleRows: 10,
      selectable: false,
      gridHeightType: "auto",
      habilitations: "RES_PRET.V",
      emptyMessage: i18n.t('common:listerprets.grid_emprunt_empty'),
      selectInSorting: false
    });

    tableModel.coll.usePopulation = this.usePopulation;
    tableModel.coll.setHabContext(this.context.habContext);
    tableModel.coll.setParametres(this.context);
    table.setSortableColumns([]);

    table.cellRenderers["matricule"] = (model: any): any => {
      const paramIdCollab = this.workflow ? this.workflow.pDiversIdCollabModel.get("valeur") : CWDIVERS.get("idCollab");
      const matricule = paramIdCollab === "matric_aux" ? model.get("collaborateur").matricaux : model.get("collaborateur").matric;
      const ligne1 = model.get("collaborateur").nom.toUpperCase() + " " + model.get("collaborateur").prenom + " (" + matricule + ")";

      return ligne1;
    };
    table.cellRenderers["composed"] = (model: any): any => {

      //periode
      const ligneError = "";
      let periode = "<span class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>";
      switch (model.get("typesaisie")) {
        case "D":
          periode += i18n.t('common:listerprets.le') + " <span class='bold'>" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "R":
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")) + "</span> " + i18n.t('common:listerprets.au') + " <span class='bold'>" + CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "P": {
          let deb = "",
            fin = "";
          if (model.get("unitedeb").code === "H") {
            deb = CWTYPE.HOUR_MINUTE.format(model.get("heuredeb"));
          } else {
            if (model.get("unitefin").code !== "J") {
              deb = model.get("unitedeb").libelle;
            }
          }
          if (model.get("unitefin").code === "H") {
            fin = " " + CWTYPE.HOUR_MINUTE.format(model.get("heurefin"));
          } else {
            if (model.get("unitefin").code !== "J") {
              fin = " " + model.get("unitefin").libelle;
            }
          }
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")) + "</span> " + deb + " " + i18n.t('common:listerprets.au') + "  <span class='bold'>" + CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A")) + "</span>" + fin;
          break;
        }
        default:
          break;
      }
      periode += "</span>";
      //Error
      //			if(this.model.objErrors && this.model.objErrors.length > 0){
      //				let error = this._findError(model.get("code"));
      //				if(!STR.isBlank(error)){
      //					ligneError = this._buildMessageErrorPer(error);
      //				}
      //			}
      if (!CWSTR.isBlank(ligneError)) {
        periode += " <br>" + ligneError;
      }

      //statud
      const stat = model.get("statut").code;
      let style = "ui-phx-statut-demande";
      switch (stat) {
        case "E":
          style = "ui-phx-statut-en-cours-saisie";
          break;
        case "F":
        case "G":
          style = "ui-phx-statut-en-cours-revision";
          break;
        case "C":
        case "M":
          style = "ui-phx-statut-en-cours-modification";
          break;
        case "Y":
        case "Z":
          style = "ui-phx-statut-a-revoir";
          break;
        case "A":
          style = "ui-phx-statut-accepte";
          break;
        case "D":
          style = "ui-phx-statut-demande";
          break;
        case "H":
          style = "ui-phx-statut-hors-validation";
          break;
        case "R":
          style = "ui-phx-statut-refuse";
          break;
        case "I":
        case "T":
          style = "ui-phx-statut-en-cours";
          break;
        default:
          break;
      }

      const statud = "<span class='" + style + "'>" + model.get("statut").libelle + "</span>";

      //Rattachement
      let rattachement = "";
      if (!CWSTR.isBlank(model.get("structures")) && model.get("structures") !== null &&
        model.get("structures").length > 0) {
        const libelleFormat = EVENEMENT.getLibelle(model.get('structures')[0].format, model.get('structures')[0].itemcode, model.get('structures')[0].itemlib);
        rattachement += "<span class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>" + libelleFormat + "</span>";
      }

      //forms the line
      let composed = "";
      if (stat === "H") {
        composed = periode + ", " + rattachement;
      } else {
        composed = periode + ", " + statud + ", " + rattachement;
      }
      return composed;
    };

    tableModel.off("vue:change");
    tableModel.on("vue:change", this._vueChanged, this);

    return table;
  }

  _initTable(emptyMessage: any): CWMenuGridView {

    ///////////////////////////////////////

    const tableModel = new CWMenuGridModel<CWPretColl<CWPretModel>, CWPretModel>({
      coll: new CWPretColl(),
      isEmptyRowSelection: emptyMessage
    });

    this.listenTo(tableModel.coll, "sync", () => {
      this.stopListening(null, "cleanError");
      tableModel.coll.forEach((model: any) => {
        model.off("invalid");
        model.on("invalid", this._showGridValidationErrors, this);
        if (this.managingVueChange === true) {
          this.managingVueChange = false;
        }
        this.listenTo(model, "cleanError", this._cleanGridValidationErrors);
      });
    });

    let disabled = "";
    if (CWHABILITATION.canView("RES_VAL_PRET.G") === false) {
      disabled = " disabled='disabled'";
    }
    let tblColumnsDetaille: any = null;
    if (!CWSTR.isBlank(this.context) && this.context.ctxEcran === "suivicollab") {
      tblColumnsDetaille = [
        { title: i18n.t('common:listerprets.grid_periode') + "<br>" + i18n.t('common:listerprets.grid_partiejournee'), property: "periode", width: 4 },
        { title: i18n.t('common:listerprets.grid_motif'), property: "motif", width: 3 },
        { title: i18n.t('common:listerprets.grid_rattach'), property: "structures", width: 3 },
        { title: i18n.t('common:listerprets.grid_statut'), property: "statut", width: 2 },
      ];
    } else {
      tblColumnsDetaille = [
        { title: "", property: "typepret", width: "auto", groupe: true },
        { title: i18n.t('common:listerprets.grid_role'), property: "profils", width: "auto" },
        { title: i18n.t('common:listerprets.grid_collaborateur'), property: "matricule", width: "auto" },
        { title: i18n.t('common:listerprets.grid_periode'), property: "periode", width: "auto" },
        { title: i18n.t('common:listerprets.grid_emprunt'), property: "emprunt", width: "auto" },
        { title: i18n.t('common:listerprets.grid_motif'), property: "motif", width: "auto" },
        { title: i18n.t('common:listerprets.grid_partiejournee'), property: "partiejournee", width: "auto" },
        { title: i18n.t('common:listerprets.grid_rattach'), property: "structures", width: "auto" },
        { title: i18n.t('common:listerprets.grid_multiple'), property: "multiple", width: "auto" },
        { title: i18n.t('common:listerprets.grid_statut'), property: "statut", width: "auto" },
        { title: "<span class='linkComHeader'>" + i18n.t('common:listerprets.grid_comentaire') + "</span>", property: "commentaire", width: "auto" },
        { title: i18n.t('common:listerprets.grid_accepter') + "<br><input " + disabled + " class='checkBoxHeader A' type='checkbox'  value='a'/>", property: "a", width: "auto" },
        { title: i18n.t('common:listerprets.grid_refuser') + "<br><input  " + disabled + " class='checkBoxHeader R' type='checkbox'  value='r'/>", property: "r", width: "auto" },
        { title: i18n.t('common:listerprets.grid_pj'), property: "pj", width: "auto" }
      ];
    }
    // const tblColumnsSynt: any = [
    //     { title: "", property: "typepret", width: 30, groupe: true },
    //     { title: i18n.t('common:listerprets.grid_role'), property: "profils", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_collaborateur'), property: "matricule", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_periode'), property: "periode", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_emprunt'), property: "emprunt", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_motif'), property: "motif", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_partiejournee'), property: "partiejournee", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_rattach'), property: "structures", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_multiple'), property: "multiple", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_statut'), property: "statut", width: "auto" },
    //     { title: "<span class='linkComHeader'>" + i18n.t('common:listerprets.grid_comentaire') + "</span>", property: "commentaire", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_accepter') + "<br><input " + disabled + " title='" + i18n.t('common:listerprets.grid_toutaccepter_title') + "' class='checkBoxHeader A' type='checkbox'  value='a'/>", property: "a", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_refuser') + "<br><input " + disabled + " title='" + i18n.t('common:listerprets.grid_toutrefuser_title') + "' class='checkBoxHeader R' type='checkbox'  value='r'/>", property: "r", width: "auto" },
    //     { title: i18n.t('common:listerprets.grid_pj'), property: "pj", width: "auto" }
    // ];

    // const vues: any = {};
    // vues[i18n.t('common:listerprets.vue_synt')] = { columns: tblColumnsSynt };
    // vues[i18n.t('common:listerprets.vue_detaille')] = { columns: tblColumnsDetaille };

    //const title = this._getTitle();

    //Added "nolimite" to suivicollab and planning
    //const viewRow = new CWEditedRowView({ parent: this });

    const table = new CWMenuGridView<CWPretModel, CWPretColl<CWPretModel>>({
      id: "listerprets_table",
      columns: tblColumnsDetaille,
      model: tableModel,
      showFilteredRowsInTitle: false,
      gridHeightType: "auto",
      paginatorPosition: "butom",
      disableAlternateRowBackground: true,
      resizable: false,
      isNotSelectableRow: true,
      selectInSorting: false
    });
    if (this.context && this.context.ctxEcran && this.context.ctxEcran === "suivicollab" &&
      (CWHABILITATION.canCreate("RES_PRET_N.G") || CWHABILITATION.canCreate("RES_PRET_E.G"))) {
      table.buttonBar.addButton(i18n.t('suivicollab:creer'), "newPret", false);
      table.buttonBar.buttons.visibleInModeC["newPret"] = true;
      table.overrideMenu(this.newPret, this);
    }
    table.buttonBar.model.trigger("hide:new");
    table.buttonBar.model.trigger("hab.hide:delete");
    table.buttonBar.model.trigger("hide:copy");

    table.cellRenderers["typepret"] = (model: any): any => {
      if (model.get("typepret") === "R") {
        return i18n.t('common:listerprets.grid_emprunt_a_valider');
      } else {
        return this._getTitle();
      }
    };

    table.cellRenderers["profils"] = (model: any): any => {
      const profils = model.get("profils");

      if (profils && profils.length === 1) {
        model.selectedProfil = profils[0];
        return profils[0].libelle;

      } else if (profils && profils.length > 1) {
        const comboName = "comboProfil_" + model.cid;
        const combo = new CWComboBoxView2({
          enum: profils,
          name: comboName,
          width: "100",
          habContext: this.model.getHabContext()
        });

        //render combo and error or only combo.
        const label = $("<label>").attr("for", comboName);
        const container = $("<div>");
        /*if(!STR.isBlank(model.profilErrors)){
            label.attr("title",model.profilErrors).addClass("griderror");
            combo.name = combo.name + " ui-state-error";
        }*/
        container.append(label).append(combo.render().el);
        let item = null;
        if (model.selectedProfil && model.selectedProfil.code !== "0") {
          item = { code: model.selectedProfil.code, libelle: model.selectedProfil.libelle };
        }
        combo.setItem(item);
        return container;
      }
      return "";
    };
    table.cellRenderers["matricule"] = (model: any): any => {
      const paramIdCollab = this.workflow.pDiversIdCollabModel.get("valeur");
      const matricule = paramIdCollab === "matric_aux" ? model.get("collaborateur").matricaux : model.get("collaborateur").matric;
      let ligne1 = model.get("collaborateur").nom.toUpperCase() + " " + model.get("collaborateur").prenom + " (" + matricule + ")";
      ligne1 += "<span style='display:inline-block;margin-left:3px;'>";
      switch (model.get("collaborateur").indicpret_resp) {
        case "P":
          ligne1 += " <span class='phx-icon ui-phx-icon-metier ui-phx-marqueur-collab-prete'>&nbsp;</span>";
          break;
        case "R":
          ligne1 += " <span class='phx-icon ui-phx-icon-metier ui-phx-marqueur-collab-recu'>&nbsp;</span>";
          break;
        case "I":
          ligne1 += " <span class='phx-icon ui-phx-icon-metier ui-phx-marqueur-collab-prete-interne'>&nbsp;</span>";
          break;
      }
      ligne1 += "</span>";
      return ligne1;
    };
    table.cellRenderers["periode"] = (model: any): any => {
      let ligneError = "";
      let periode = "<span class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>";
      switch (model.get("typesaisie")) {
        case "D":
          periode += i18n.t('common:listerprets.le') + " <span>" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "R":
          periode += i18n.t('common:listerprets.du') + " <span>" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")) + "</span> " + i18n.t('common:listerprets.au') + " <span class='bold'>" + CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "P": {
          let deb, fin;
          if (model.get("unitedeb").code === "H") {
            deb = CWTYPE.HOUR_MINUTE.format(model.get("heuredeb"));
          } else {
            deb = model.get("unitedeb").libelle;
          }
          if (model.get("unitefin").code === "H") {
            fin = CWTYPE.HOUR_MINUTE.format(model.get("heurefin"));
          } else {
            fin = model.get("unitefin").libelle;
          }
          periode += i18n.t('common:listerprets.du') + " <span>" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")) + "</span> " + deb + " " + i18n.t('common:listerprets.au') + "  <span class='bold'>" + CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A")) + "</span> " + fin;
          break;
        }
        default:
          break;
      }
      periode += "</span>";
      if (model.get("typesaisie") === "D" || model.get("typesaisie") === "R") {
        switch (model.get("modesaisie")) {
          case "PLH":
            periode += "<br>" + CWTYPE.HOUR_MINUTE.format(model.get("heuredeb")) + " - " + CWTYPE.HOUR_MINUTE.format(model.get("heurefin"));
            break;
          case "UNITE":
            periode += "<br>" + model.get("unitedeb").libelle;
            break;
          case "DUREE":
            periode += "<br>" + CWTYPE.DURATION.HOUR_MINUTE.format(model.get("duree"));
            break;
          case "POURCENT":
            periode += "<br>" + model.get("pourcentage") + "%";
            break;
          default:
            break;
        }
      }
      //Error
      if (this.model.objErrors.length > 0) {
        const error = this._findError(model.get("code"));
        if (!CWSTR.isBlank(error)) {
          ligneError = this._buildMessageErrorPer(error);
        }
      }
      if (!CWSTR.isBlank(ligneError)) {
        periode += " <br>" + ligneError;
      }
      if (model.get("indicateurpj")) {
        periode += "<span class='piece-jointe' style='padding:1em' data-code='" + UTILS.escapeJQueryString(model.get("code")) + "'>" + UTILS.getSVGIcon("trombone") + "</span>"

      }
      return periode;
    };

    table.cellRenderers["emprunt"] = (model: any): any => {
      if (model.get("emprunt")) {
        return '<span title="' + i18n.t('common:listerprets.grid_emprunt_title') + '" style="margin-left:calc(50% - 8px);" class="ui-icon ui-icon-check"></span>';
      } else {
        return "";
      }
    };

    table.cellRenderers["motif"] = (model: any): any => {
      let motif = "";
      const idCollab = this.workflow.pDiversIdCollabModel.get("valeur").replace('_', '');

      // EVO 450 - Docu 955
      if (!CWSTR.isBlank(model.get("collabremplace")) && !CWSTR.isBlank(model.get("collabremplace").matric)) {
        if (_.isEmpty("idCollab")) {
          motif += "<span>" + i18n.t('messages:GT_1848') + " " + model.get("collabremplace").nom + " " + model.get("collabremplace").prenom + " (" + model.get("collabremplace").matric + ")</span>";
        } else {
          motif += "<span>" + i18n.t('messages:GT_1848') + " " + model.get("collabremplace").nom + " " + model.get("collabremplace").prenom + " (" + model.get("collabremplace")[idCollab] + ")</span>";
        }
      } else {
        // Old code
        motif += "<span class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>" + model.get("motif").libelle + "</span>";
      }

      return motif;
    };

    table.cellRenderers["partiejournee"] = (model: any): any => {
      if (model.get("typesaisie") === "D" || model.get("typesaisie") === "R") {
        switch (model.get("modesaisie")) {
          case "PLH":
            return CWTYPE.HOUR_MINUTE.format(model.get("heuredeb")) + " - " + CWTYPE.HOUR_MINUTE.format(model.get("heurefin"));
          case "UNITE":
            if (model.get("unitedeb").code !== "J") {
              return model.get("unitedeb").libelle;
            }
            return "";
          case "DUREE":
            return CWTYPE.DURATION.HOUR_MINUTE.format(model.get("duree"));
          case "POURCENT":
            return model.get("pourcentage") + "%";
          default:
            break;
        }
      }
      return "";
    };

    table.cellRenderers["structures"] = (model: any): any => {
      let rattachement = "";

      if (!CWSTR.isBlank(model.get("structures")) && model.get("structures") !== null &&
        model.get("structures").length > 0) {
        const libelleFormat = EVENEMENT.getLibelle(model.get('structures')[0].format, model.get('structures')[0].itemcode, model.get('structures')[0].itemlib);
        rattachement += "<span class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>" + libelleFormat + "</span>";
      }
      return rattachement;
    };

    table.cellRenderers["multiple"] = (model: any): any => {
      if (!CWSTR.isBlank(model.get("structures")) && model.get("structures") !== null &&
        model.get("structures").length > 1) {
        return "<span title='" + i18n.t('common:listerprets.grid_rattachmult') + "'>*</span>";
      }
      return "";
    };

    table.cellRenderers["statut"] = (model): JQuery => {
      let type: validEtiquetteTypes;
      let subText: string = "";

      switch (model.get("statut").code) {
        case "D": {
          type = "demande";
          break;
        } case "A": {
          type = "accepte";
          break;
        } case "H":
          return null;
        case "R": {
          type = "refuse";
          break;
        }
        case "E":
        case "F":
        case "G":
        case "C":
        case "M":
        case "Y":
        case "Z": {
          type = "custom";
          break;
        }
        default: {
          break;
        }
      }

      subText = model.get("etat") === "X" ? i18n.t('common:listeractivites.suppression') : "";
      const div = $("<div class='d-flex flex-row justify-content-center'></div>");
      const etiquetteStatut = new CWEtiquette({
        text: {
          enabled: model.get("statut").libelle
        },
        subText: {
          enabled: subText
        },
        state: "actif",
        type: type,
        id: model.get("code") + "_etiquette"
      });

      div.append(etiquetteStatut.render().el);
      return div;
    };

    // table.cellRenderers["statut"] = (model: any): any => {
    //     const stat = model.get("statut").code;
    //     if (stat !== null) {
    //         let style = "ui-phx-statut-demande";
    //         switch (stat) {
    //             case "E":
    //                 style = "ui-phx-statut-en-cours-saisie";
    //                 break;
    //             case "F":
    //             case "G":
    //                 style = "ui-phx-statut-en-cours-revision";
    //                 break;
    //             case "C":
    //             case "M":
    //                 style = "ui-phx-statut-en-cours-modification";
    //                 break;
    //             case "Y":
    //             case "Z":
    //                 style = "ui-phx-statut-a-revoir";
    //                 break;
    //             case "A":
    //                 style = "ui-phx-statut-accepte";
    //                 break;
    //             case "D":
    //                 style = "ui-phx-statut-demande";
    //                 break;
    //             case "H":
    //                 style = "ui-phx-statut-hors-validation";
    //                 break;
    //         }

    //         const spanElem = $("<span>").addClass(style).html(model.get("statut").libelle);
    //         const tooltipText = this._manageWKFHisto(model);
    //         $(spanElem).attr("title", tooltipText);
    //         $(spanElem).addClass("infoBulleStatut");
    //         return String(spanElem[0].outerHTML);
    //     }
    // };

    table.cellRenderers["commentaire"] = (model: any): any => {
      if (this.managingVueChange === true) {
        this._updateCheckAndCommentsModels();
      }
      let linkCom = "";
      if (CWSTR.isBlank(model.get("commentaire"))) {
        linkCom = "<span title='" + i18n.t('common:listerprets.grid_commentaire_title') + "' class='linkCom A " + UTILS.escapeJQueryString(model.get("code")) + " phx-icon phx-icon-comment-white ' style='cursor:pointer; text-decoration:underline;margin:auto'></span>";
      } else {
        linkCom = "<span title='" + i18n.t('common:listerprets.grid_commentaire_title') + "' class='linkCom A " + UTILS.escapeJQueryString(model.get("code")) + " phx-icon phx-icon-comment-black ' style='cursor:pointer; text-decoration:underline;margin:auto'></span>";
      }

      return linkCom;
    };

    table.cellRenderers["a"] = (model: any): any => {
      // We don't show checkbox when it's not possible to valid
      if (model.get("profils") === null || model.get("profils").length === 0) {
        return "";
      }
      let disabled = "";
      if (CWHABILITATION.canView("RES_VAL_PRET.G") === false) {
        disabled = " disabled='disabled'";
      }
      if (this.managingVueChange === true) {
        this._updateCheckAndCommentsModels();
      }
      const checkedAttribute = model.recEtatValid === "A" ? "checked" : "";

      return "<input title='" + i18n.t('common:listerprets.grid_accepter_title') + "' " + disabled + " class='checkBoxLine A " + UTILS.escapeJQueryString(model.get("code")) + "' type='checkbox'  value='a' " + checkedAttribute + "/>";

    };

    table.cellRenderers["r"] = (model: any): any => {
      // We don't show checkbox when it's not possible to valid
      if (model.get("profils") === null || model.get("profils").length === 0) {
        return "";
      }
      let disabled = "";
      if (CWHABILITATION.canView("RES_VAL_PRET.G") === false) {
        disabled = " disabled='disabled'";
      }
      if (this.managingVueChange === true) {
        this._updateCheckAndCommentsModels();
      }
      const checkedAttribute = model.recEtatValid === "R" ? "checked" : "";
      //Prets out of workflow cannot be accepter or refused
      return "<input title='" + i18n.t('common:listerprets.grid_refuser_title') + "' " + disabled + "  class='checkBoxLine R " + UTILS.escapeJQueryString(model.get("code")) + "' type='checkbox'  value='r' " + checkedAttribute + "/>";
    };

    table.cellRenderers["pj"] = (model: any): any => {
      if (model.get("piecesjointes")) {
        const indicateurpj = model.get("indicateurpj");

        if (indicateurpj) {
          const icon = $("<span>");
          icon.addClass("piece-jointe ui-phx-piece-jointe-icon phx-grid-not-selectable-cell");
          icon.attr({ "title": i18n.t('common:listerprets.grid_pj_title'), "data-code": UTILS.escapeJQueryString(model.get("code")) });
          return icon[0];
        }
        return "";
      } else {
        return "";
      }

    };

    // table.setSortableColumns(["profils", "matricule", "periode", "motif"], i18n.t('common:listerprets.vue_detaille'));
    // table.setSortableColumns(["profils", "matricule", "periode", "motif"], i18n.t('common:listerprets.vue_synt'));
    // table.setOmittedColumns(["typepret", "periode", "commentaire", "a", "r"]);

    //the column periode is the datedeb for the webServices
    // const customNames = {
    //     "periode": ["datedeb"]
    // };
    // table.setSortableCustomNames(customNames, i18n.t('common:listerprets.vue_detaille'));
    // table.setSortableCustomNames(customNames, i18n.t('common:listerprets.vue_synt'));

    // tableModel.off("vue:change");
    // tableModel.on("vue:change", this._vueChanged, this);

    return table;

  }

  newPret(buttonId: any): void {

    if (buttonId === "newPret") {
      const context: any = {};

      context.ctxUtilisateur = "Responsable";
      context.ctxHabilitation = {};
      context.ctxUtilisation = "Gérer un prêt par un responsable"; //Not used at the moment
      context.ctxTypeEvtGere = "P";
      context.ctxHabilitation.HabilitationAcces = "RES_PRET.V";
      context.ctxHabilitation.HabilitationGestionNormal = "RES_PRET_N.G";
      context.ctxHabilitation.HabilitationGestionEtendu = "RES_PRET_E.G";
      context.ctxHabilitation.HabilitationValidation = "RES_VAL_PRET.G";
      context.ctxEcran = "suivicollab";
      context.ctxCollab = {};
      context.ctxCollab.matricule = this.workflow.context.ctxCollab.matricule;
      context.ctxCollab["matric_aux"] = this.workflow.context.ctxCollab.matriculeAux;
      context.ctxCollab.nom = this.workflow.context.ctxCollab.nom;
      context.ctxCollab.prenom = this.workflow.context.ctxCollab.prenom;
      context.ctxModeRepresentation = "pop-up";
      context.ctxPieceJointe = (this.workflow.paramDivPieceJointe.get("valeur") === "1");
      context.ctxListeCollab = null;
      context.ctxPerimetreFige = false;
      context.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer", "Valider"];
      context.ctxModeInitialisation = "Ajout";
      context.ctxIdentifiantEvt = null;
      context.ctxValeursEvenement = null;
      context.ctxValeursCreation = {};
      context.ctxValeursCreation.PretType = "";
      context.ctxValeursCreation.PretPerimetre = CWHABILITATION.canCreate("RES_PRET_N.G") ? "N" : "E";
      context.ctxValeursCreation.PretEstUnEmprunt = false;
      context.ctxValeursCreation.PretResponsable = objs.appRt.workflow.authModel.get("user"); //userid of current user
      context.ctxValeursCreation.PretDateDebut = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
      context.ctxValeursCreation.PretUniteDebut = "";
      context.ctxValeursCreation.PretDateFin = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
      context.ctxValeursCreation.PretUniteFin = "";
      context.ctxValeursCreation.PretHeureDebut = "";
      context.ctxValeursCreation.PretHeureFin = "";
      context.ctxValeursCreation.PretCommentaire = "";
      context.ctxHistoriqueWKF = false;
      this._initPopupGererPret(context);
      this.popupGerePretDialog.open();
      this.listenTo(this.popupGerePretDialog.internalView.model, "pretChanged", (model: any, action: any) => {
        this.workflow.setUp();
        this.workflow.trigger("listerpretsUpdated");
        this.workflow.trigger("pretChanged", model, action);
      });
    }
  }
  /**
   * Returns the pret historique detail of this evenement.
   */

  _getHistoObj(even: any): any {
    let obj = null;
    _.each(this.historiqueModel.attributes, (attr: any) => {
      if (attr !== null && even === attr.evenement) {
        obj = attr;
      }
    });
    return obj;
  }

  _manageWKFHisto(model: any): any {
    const typeLabel = "PRET";
    const contextComponentWkf: any = {
      ctxEcran: this.workflow.context.ctxEcran,
      ctxHabilitation: this.workflow.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: typeLabel,
      ctxRefEvenement: model.get("evenement"),
      ctxDemId: null,
      ctxModeRestitution: "infobulle",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Replie",
      ctxModeRech: true
    };

    const composantWorkflow = new BlockView();
    $(this.el).find(".listerprets-wkfhisto").html(composantWorkflow.render().el);
    composantWorkflow.setContext(contextComponentWkf);

    // store the component to reload it when the data is available.
    this.histoInfoBulles[this.titleCont] = composantWorkflow;
    this.titleCont++;

    return composantWorkflow.$el.html();
  }

  /**
   * This function prints the status infobull (historification component) after all data is loaded.
   * we assure that the data is shown, in case the fetch has not finisted to load the data.
   */

  _paintStatusInfoBull(): void {

    const numElements = $("[class*='infoBulleStatut']").length;

    for (let cont = 0; cont < numElements; cont++) {
      if (this.histoInfoBulles[cont]) {
        this.$el.find(".infoBulleStatut:eq(" + cont + ")").attr("title", this.histoInfoBulles[cont].$el.html());
      }
    }
  }

  /**
   * This function manages the change of the vue for  table, in order to get historiquedata
   */
  _vueChanged(): void {
    this._historique(() => {
      this.table.model.coll.trigger("reset");
      this.workflow.trigger("lock:tableColumns");
      this.managingVueChange = true;
    });
  }

  _copyModels(coll: any): void {
    const modelArray: any = [];
    _.each(coll.models, (model: any) => {
      modelArray.push(model);
    });
    return modelArray;
  }

  _updateCheckAndCommentsModels(): void {
    _.each(this.table.model.coll.models, (model: any) => {
      _.each(this.copyModelsState, (copyModel: any) => {
        if (copyModel.get("id") === model.get("id")) {
          model.recEtatValid = copyModel.recEtatValid;
          model.nbrecEtatValid = copyModel.nbrecEtatValid;
          model.set("commentaire", copyModel.get("commentaire"), { silent: true });
        }
      });
    });
    this.managingVueChange = false;
  }

  _historique(callback?: any): void {
    const params: any = [];
    _.each(this.table.model.coll.models, (item: any) => {
      params.push(item.get("evenement"));
    }, this);

    this.wkfEvenements = params;

    this.historiqueModel = new ListerHistoriqueModel();
    this.historiqueModel.setHabContext(this.workflow.getHabContext());
    this.historiqueModel.set("evenements", this.wkfEvenements);
    //			this.historiqueModel.applyFilter({evenements : this.wkfEvenements});
    this.historiqueModel.save(null, {
      success: (): void => {

        if (callback) {
          callback();
        }
      },
      error: (): void => {

        if (callback) {
          callback();
        }
      }
    });
  }

  /**
   * Gets the table title depending on ctxValideur
   */
  _getTitle(): any {
    if (this.context && this.context.ctxValideur === true) {
      return i18n.t('common:listerprets.grid_title_a_valider');
    } else if (this.context && this.context.ctxEncoursOuAVenir === true) {
      return i18n.t('common:listerprets.grid_title') + i18n.t('common:listerprets.grid_title_encours');
    } else {
      return i18n.t('common:listerprets.grid_title');
    }
  }

  /**
   * Manage pieces jointes
   */
  _managePieceJointe(event: any): any {
    const maxPieces = !CWSTR.isBlank(this.workflow.maxPieces) ? this.workflow.maxPieces.get("maxi") : 0;
    const modelCode = $(event.currentTarget).attr("data-code");
    let model = this.table.model.coll.get(modelCode);

    if (CWSTR.isBlank(model)) {
      model = _.find(this.table.model.coll.models, (item: any) => {
        if (UTILS.escapeJQueryString(item.get("code")) === modelCode) {
          return item;
        }
        return null;
      });
    }
    if (model.get("piecesjointes").length === 1) {
      const code = model.get("piecesjointes")[0].code;
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    } else if (model.get("piecesjointes").length > 1) {

      if (this.pjPopupView) {
        (this.pjPopupView.internalView as CWGererPiecesJointesView).setPJ(model.get("piecesjointes"), "");
      } else {

        const context: any = {};
        context.ctxRefEvenement = model.get("evenement");
        context.ctxHabilitation = {};
        context.ctxHabilitation.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
        context.ctxHabilitation.HabilitationGestion = this.context.ctxHabilitation.HabilitationValidation;

        context.ctxEcran = this.context.ctxEcran;
        context.ctxGestionCollab = {};
        context.ctxGestionCollab.nom = model.get("collaborateur").nom;
        context.ctxGestionCollab.prenom = model.get("collaborateur").prenom;
        context.ctxGestionCollab.matricule = model.get("collaborateur").matric;
        context.ctxGestionCollab["matric_aux"] = model.get("collaborateur").matricaux;

        context.ctxActionsPossibles = [];
        context.ctxModeInitialisation = "Consultation";

        this.pjPopupView = this._initPJPopupView(context, model.get("piecesjointes"), "", maxPieces);
        this.pjPopupView.open(() => {
          this.pjPopupView = null;
        });
      }
    }
    return false;
  }

  _formatedParametres(model: any): any {
    const objResponse: any = {};

    objResponse.datedeb = this._addClassToText(CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")), "phx-bold-label-style");
    objResponse.datefin = this._addClassToText(CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A")), "phx-bold-label-style");
    const competence = model.get("libelle") + " (" + model.get("code") + ")";
    objResponse.competence = this._addClassToText(competence, "phx-bold-label-style");

    return objResponse;
  }

  _addClassToText(text: any, objClass: any): any {
    return $("<span>").text(text).addClass(objClass);
  }

  _initPJPopupView(context: any, list: any, description: any, maxpieces: any): CWDialogPopupView {
    const dialog = new CWDialogPopupView({
      view: CWGererPiecesJointesView,
      viewData: {
        context: context,
        coll: list,
        description: description,
        modal: false,
        maxPieces: maxpieces,
        appendTo: this.$appendTo
      }
    });
    dialog.setHeight("auto");
    dialog.setWidth(600);
    return dialog;
  }

  _findError(objCode: any): any {
    let res = "";

    _.each(this.model.objErrors, (obj: any) => {
      if (obj.code === objCode) {
        res = obj;
      }
    });

    return res;
  }

  _cleanErrorsTable(): void {
    this.table.model.coll.forEach((model: any) => {
      model.trigger("cleanError", model);
    });
  }

  _buildMessageErrorRec(objError: any): any {
    const styleError = "ui-phx-msg-erreur-validation";
    let htmlError = "";
    if (objError.type === "A") {
      htmlError += i18n.t('messages:GT_1370') + ":";
    } else if (objError.type === "R") {
      htmlError += i18n.t('messages:GT_1371') + ":";
    }

    htmlError = this._textToStyle(htmlError, styleError);

    return htmlError;
  }

  _buildMessageErrorPer(objError: any): any {
    const styleError = "ui-phx-msg-erreur-validation";
    let htmlError = "";
    if (CWSTR.isBlank(objError.message)) {
      htmlError += i18n.t('messages:GT_1224');
    } else {
      htmlError += objError.message;
    }
    htmlError = this._textToStyle(htmlError, styleError);
    return htmlError;
  }

  _textToStyle(txt: any, style: any): any {
    let newTxt = "";
    newTxt = "<span class='" + style + "'>" + txt + "</span>";

    return newTxt;
  }

  _initPopupResumeView(data: any): any {

    if (this.resumeDialog === undefined) {
      this.resumeDialog = new CWDialogPopupView({
        view: ResumePopUpView,
        viewData: {
          data: data,
          appendTo: this.$appendTo || this.el
        }
      });
    } else {
      this.resumeDialog.viewData = { data: data, appendTo: this.el };
    }
    this.resumeDialog.setHeight(150);
    this.resumeDialog.setWidth(620);

    this.resumeDialog.model.trigger("dlg:open", () => {
      // Close Callback
      LOG.debug("callback from dialog view");
    });
    this.resumeDialog._setTitle(i18n.t('common:listerprets.resumePopUp_title'));
  }

  _manageRowProfil(event: any): void {
    const className = event.currentTarget.className;
    const id = !CWSTR.isBlank(className.split(" ")[0].split("_")[1]) ? className.split(" ")[0].split("_")[1] : "";
    const item = $(event.currentTarget).prop("viewRef").getItem();
    let model = this.table.model.coll.get(id);

    if (CWSTR.isBlank(model)) {
      model = _.find(this.table.model.coll.models, (lItem: any) => {
        if (UTILS.escapeJQueryString(lItem.get("code")) === id) {
          return lItem;
        }
        return null;
      });
    }
    if (model !== undefined) {
      if (!CWSTR.isBlank(item.id)) {
        model.selectedProfil = {
          code: item.id,
          libelle: item.label
        };
        model.trigger("cleanError", model);
      } else {
        model.selectedProfil = {
          code: "0",
          libelle: ""
        };
      }
    }
  }

  getGrid(): any {
    return this.table;
  }
}
