import _ from 'underscore';
import { CWAffectationEnCoursModel } from './cwAffectationEnCours.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';


//Table remplacements EVO 450
export class CWAffectationRemplaColl extends CWPaginatedCollection<CWAffectationEnCoursModel> {

  //url et paginated sont herités: on n'en doit pas définir ici

  constructor(models?: CWAffectationEnCoursModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = CWAffectationEnCoursModel;
    options.comparator = (element: CWAffectationEnCoursModel): string => {
      return element.get("datedeb");
    };
    super(models, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/brique/affectations/remplacements";
    }
    this.paginated = false;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    response.list = response.affectation;
    delete response.affectation;
    return CWPaginatedCollection.prototype.parse.call(this, response);
  }
}