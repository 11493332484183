module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="c-form--fixedButtons">\n    <div class="form-group phx-formErrors ui-state-error"></div>\n    <div class="gererevtgen-content c-form__scrollZone m-0">\n        <div class="gererevtgen-form-inputs">\n            <div class="form-group">\n                <span class="gererevtgen-utilisateur">\n                    <span class="c-gestionCollab cw-titreLight"></span>\n                </span>\n            </div>\n\n            <fieldset class="gererevtgen-detail-form cw-fieldset ">\n\n                <span class="typeSaisie"></span>\n\n                <span class="modeSaisie"></span>\n\n                <div class="blockCommentaire form-group">\n                    <label for="complement">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t("common:gererevtgen.infocompl")))==null?'':__t)+
'</label>\n                    <textarea class="complement form-control" maxlength="200" cols="36" rows="7" style="white-space: pre-wrap;"></textarea>\n                </div>\n\n                <div class="originActif form-group">\n                    <label for="origine.code" class="">'+
((__t=(i18n.t("common:gererevtgen.origine")))==null?'':__t)+
'</label>\n                    <span class="comboOrigine"></span>\n                </div>\n\n                <div class="commentaireWkf form-group">\n                    <label for="commentaire" class="">'+
((__t=(i18n.t("common:gererevtgen.comment")))==null?'':__t)+
'</label>\n                    <textarea class="commentaire form-control" maxlength="200" cols="36" rows="4" style="white-space: pre-wrap;"></textarea>\n                </div>\n            </fieldset>\n\n            <fieldset class="cw-fieldset">\n                <div class="gererevtgen-piecesjointes form-group"></div>\n                <span class="gererevtgen-infocompsaisie"></span>\n                <span class="gererevtgen-wkfhisto"></span>\n            </fieldset>\n        </div>\n    </div>\n\n    <div class="unselectedElementText">\n        <label>'+
((__t=(i18n.t("common:gererevtgen.unselectedElementText")))==null?'':__t)+
'</label>\n        <ul>\n            <li>\n                <div class="textAccent1">\n                    '+
((__t=(i18n.t("common:gererevtgen.ajouter")))==null?'':__t)+
'\n                </div>\n                <span>'+
((__t=(i18n.t("common:gererevtgen.text_ajouter")))==null?'':__t)+
'</span>\n            </li>\n            <li class="consulterText">\n                <div class="textAccent1">\n                    '+
((__t=(i18n.t("common:gererevtgen.consulter")))==null?'':__t)+
'\n                </div>\n                <span>'+
((__t=(i18n.t("common:gererevtgen.text_consulter")))==null?'':__t)+
'</span>\n            </li>\n        </ul>\n    </div>\n\n    <div class="c-gererEvtGenContent__btnBar">\n        <ul class="validerListMenu menuContent"></ul>\n    </div>\n</div>';
}
return __p;
};
