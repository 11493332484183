import { CWBaseFormView as CommonFormView } from 'core/views/cwForm.view';
import { CWCalendaccWorkflow } from '../models/cwCalendacc.workflow';
import { CWCustomBarView } from './cwCustomBar.view';
import { CWFooterView } from './cwFooter.view';
import { CWFormView } from './cwForm.view';
import { CWSimplePanelView } from 'core/layouts/cwSimplePanel.view';
import { i18n } from 'src/i18n.js';

export class CWGeneralView extends CommonFormView {

  parent: any;
  workflow: CWCalendaccWorkflow;
  layout: CWSimplePanelView;
  form: any;
  customBar: CWCustomBarView;
  footer: CWFooterView;
  usecase: string;


  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.className = options.className || 'calendacc';
    super(options);
    this.parent = options.parent;
    this.usecase = options.usecase || "calendacc";
    // Initializes the main parts of the UI and its workflow
    this.workflow = new CWCalendaccWorkflow({ "usecase": this.usecase });
    this.layout = new CWSimplePanelView({ className: "l-simplePanel" });
    this.form = this._initForm(); //table view
    this.workflow.formModel = this.form.model;
    this.workflow.form = this.form;
    this.customBar = new CWCustomBarView({ workflow: this.workflow });
    this.workflow.customBarModel = this.customBar.model;
    this.footer = new CWFooterView({ workflow: this.workflow });
  }

  /**
   * Init screen
   */
  list(): void {
    this._initLayoutUC();
  }

  _initLayoutUC(callback?: (arg?: any) => void): void {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      }
    } else {
      this.workflow.setUp(() => {
        let viewForm: HTMLElement = null;

        //When the workflow is ready, we can render the view without concerns
        this.$el.html(this.customBar.render().el);
        viewForm = this.form.render().el;
        $(viewForm).addClass("calendacc-form");
        this.$el.append(viewForm);
        this.$el.append(this.footer.render().el);
        if (callback) {
          callback();
        }
        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        this.trigger("uc:ready");
      });
    }
  }

  _initForm(): CWFormView {
    return new CWFormView({ "workflow": this.workflow, "usecase": this.usecase });
  }

  render(): CWGeneralView {
    this.$el.css({ "height": "100%", "width": "100%" });
    this.$el.parent().parent().css({ "max-width": "1140px", "width": "100%" });
    this.$el.parent().parent().css({ "max-height": "886px", "height": "100%", "padding-bottom": "4px" });
    this.$el.parent().css({ "max-width": "1138px", "width": "100%" });
    this.$el.parent().css({ "max-height": "876px", "height": "calc(100% - 34px)" });
    this.parent._setTitle(i18n.t('calendacc.usecase'));
    $(this.parent.el).on("dialogresize", (event) => {
      $(event.target).height($(event.target).parent().height() - 60);
      $(event.target).width($(event.target).parent().width() - 50);
    });
    this._initLayoutUC();
    return this;
  }
}
