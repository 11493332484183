import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDialogView } from 'src/core/components/dialog/cwDialog.view';
import { CWGererPiecesJointesView } from 'src/uc/common/evenements/gerer/gererpiecesjointes/views/cwGererPiecesJointes.view';
import { CWMenuButtonView } from 'src/core/controls/cwMenuButton.view';
import { CWSTR } from 'src/utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

export class CWBaseView<TModel extends CWBaseModel = CWBaseModel> extends CWBaseFormView<TModel> {
  workflow: any;
  table: any;
  pjPopupView: any;

  constructor(options?: Backbone.ViewOptions<TModel> | any) {
    super(options);
  }

  /**
   * Manage pieces jointes
   */
  _managePieceJointe(jqEvent: JQuery.TriggeredEvent): boolean {
    const modelCode = $(jqEvent.currentTarget).attr("data-code");
    const modelType = $(jqEvent.currentTarget).attr("data-type");
    let event = null;

    let model = _.find(this.table.model.coll.models, (mod: TModel) => {
      return !CWSTR.isBlank(mod.get("prevueColumn")) ? mod.get("prevueColumn").get("code") === modelCode : null;
    });

    if (!CWSTR.isBlank(model)) {
      event = model.get("prevueColumn");
    } else {
      model = _.find(this.table.model.coll.models, (mod: TModel) => {
        event = mod.get("realiseeColumn");
        return !CWSTR.isBlank(mod.get("realiseeColumn")) ? mod.get("realiseeColumn").get("code") === modelCode : null;
      });
    }

    let maxPieces = 0;
    if (modelType === this.workflow.ACTIVITE) {
      maxPieces = !CWSTR.isBlank(this.workflow.maxPiecesAct) ? this.workflow.maxPiecesAct.get("maxi") : 0;
    } else if (modelType === this.workflow.ABSENCE) {
      maxPieces = !CWSTR.isBlank(this.workflow.maxPiecesAbs) ? this.workflow.maxPiecesAbs.get("maxi") : 0;
    }

    if (event.get("piecesjointes").length === 1) {
      const code = event.get("piecesjointes")[0].code;
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    } else if (event.get("piecesjointes").length > 1) {
      if (this.pjPopupView) {
        this.pjPopupView.internalView.setPJ(event.get("piecesjointes"), "");
      } else {
        const arr: any = [];
        const context = {
          ctxRefEvenement: modelCode,
          ctxHabilitation: this.workflow.context.ctxHabilitation,
          ctxEcran: this.workflow.context.ctxEcran,
          ctxGestionCollab: this.workflow.context.ctxCollab,
          ctxActionsPossibles: arr,
          ctxModeInitialisation: "Consultation"
        };

        this.pjPopupView = this._initPJPopupView(context, event.get("piecesjointes"), "", maxPieces);
        this.pjPopupView.open(() => {
          this.pjPopupView = null;
        });
      }
    }
    return false;
  }

  _addClassToText(text: string, objClass: string): any {
    return $("<span>").text(text).addClass(objClass);
  }

  _initPJPopupView(context: object, list: any, description: string, maxpieces: number): CWDialogView {
    const dialog = new CWDialogView({
      view: CWGererPiecesJointesView,
      viewData: {
        context: context,
        coll: list,
        description: description,
        modal: false,
        maxPieces: maxpieces,
        appendTo: ((this.workflow && this.workflow.context && !CWSTR.isBlank(this.workflow.context.ctxEcran)) ? $("#" + this.workflow.context.ctxEcran) : null)
      }
    });
    dialog.setHeight("auto");
    dialog.setWidth(600);
    return dialog;
  }

  _checkButtonsSupprimerEv(): void {
    //override par monoType ou MixteType
  }

  _manageHeaderCheckbox(jqEvent: JQuery.TriggeredEvent): void {
    const checked = $(jqEvent.currentTarget).prop('checked');
    const className = jqEvent.currentTarget.className.split(" ")[0];
    const representation = this.workflow.modeRepresentationModel.get("representation");
    const infoClass = (representation === this.workflow.MONO) ? "Mono" : "Mixte";
    const action = className === ("supprimerPHeader" + infoClass) ? "P" : "D";

    this._manageAllRows(checked, action);
    this._checkButtonsSupprimerEv();
    if (action === "P") { //PREVUE
      if (checked === true) {
        $(this.el).find(".supprimerPHeader" + infoClass).prop("checked", true);
        $(this.el).find(".supprimerPMonth").prop("checked", true);
        $(this.el).find(".supprimerPWeek").prop("checked", true);
        $(this.el).find(".supprimerPDay").prop("checked", true);
        $(this.el).find(".supprimerPLine").prop("checked", true);
      } else {
        $(this.el).find(".supprimerPHeader" + infoClass).prop("checked", false);
        $(this.el).find(".supprimerPMonth").prop("checked", false);
        $(this.el).find(".supprimerPWeek").prop("checked", false);
        $(this.el).find(".supprimerPDay").prop("checked", false);
        $(this.el).find(".supprimerPLine").prop("checked", false);
      }
    } else { //Declare
      if (checked === true) {
        $(this.el).find(".supprimerDHeader" + infoClass).prop("checked", true);
        $(this.el).find(".supprimerDMonth").prop("checked", true);
        $(this.el).find(".supprimerDWeek").prop("checked", true);
        $(this.el).find(".supprimerDDay").prop("checked", true);
        $(this.el).find(".supprimerDLine").prop("checked", true);
      } else {
        $(this.el).find(".supprimerDHeader" + infoClass).prop("checked", false);
        $(this.el).find(".supprimerDMonth").prop("checked", false);
        $(this.el).find(".supprimerDWeek").prop("checked", false);
        $(this.el).find(".supprimerDDay").prop("checked", false);
        $(this.el).find(".supprimerDLine").prop("checked", false);
      }
    }
  }

  _manageMonthCheckbox(jqEvent: JQuery.TriggeredEvent): void {
    const className = jqEvent.currentTarget.className.split(" ")[0];
    const action = className === "supprimerPMonth" ? "P" : "D";
    const checked = $(jqEvent.currentTarget).prop('checked');
    const modelId = jqEvent.currentTarget.className.split(" ")[1];
    const year = modelId.split(",")[0];
    const month = modelId.split(",")[1];
    let code1 = jqEvent.currentTarget.className.split(" ")[2];
    //const code = code1.split(",")[1];
    if (code1.indexOf("_") !== -1) {
      code1 = code1.replace('_', '/');
    }

    this._manageMonthRows(checked, year, month, action);
    this._checkButtonsSupprimerEv();
    if (action === "P") { //PREVUE
      if (checked === true) {
        $(this.el).find(".supprimerPMonth[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
        $(this.el).find(".supprimerPWeek[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
        $(this.el).find(".supprimerPDay[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
        $(this.el).find(".supprimerPLine[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerPMonth[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
        $(this.el).find(".supprimerPWeek[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
        $(this.el).find(".supprimerPDay[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
        $(this.el).find(".supprimerPLine[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
      }
    } else { //Declaree
      if (checked === true) {
        $(this.el).find(".supprimerDMonth[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
        $(this.el).find(".supprimerDWeek[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
        $(this.el).find(".supprimerDDay[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
        $(this.el).find(".supprimerDLine[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerDMonth[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
        $(this.el).find(".supprimerDWeek[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
        $(this.el).find(".supprimerDDay[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
        $(this.el).find(".supprimerDLine[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
      }
    }
    this._checkMonthEvtPeriodSelected(checked, action, year, month);
    this._checkAllPeriodSelected(action);
  }

  _manageWeekCheckbox(jqEvent: JQuery.TriggeredEvent): void {
    const checked = $(jqEvent.currentTarget).prop('checked');
    const className = jqEvent.currentTarget.className.split(" ")[0];
    const action = className === "supprimerPWeek" ? "P" : "D";
    const modelId = jqEvent.currentTarget.className.split(" ")[1];
    const year = modelId.split(",")[0];
    const month = modelId.split(",")[1];
    const week = modelId.split(",")[2];


    this._manageWeekRows(checked, year, week, action);
    this._checkButtonsSupprimerEv();
    if (action === "P") { //PREVUE
      if (checked === true) {
        $(this.el).find(".supprimerPWeek[data-year='" + year + "'][data-week='" + week + "']").prop("checked", true);
        $(this.el).find(".supprimerPDay[data-year='" + year + "'][data-week='" + week + "']").prop("checked", true);
        $(this.el).find(".supprimerPLine[data-year='" + year + "'][data-week='" + week + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerPWeek[data-year='" + year + "'][data-week='" + week + "']").prop("checked", false);
        $(this.el).find(".supprimerPDay[data-year='" + year + "'][data-week='" + week + "']").prop("checked", false);
        $(this.el).find(".supprimerPLine[data-year='" + year + "'][data-week='" + week + "']").prop("checked", false);
      }
    } else { //DECLAREE
      if (checked === true) {
        $(this.el).find(".supprimerDWeek[data-year='" + year + "'][data-week='" + week + "']").prop("checked", true);
        $(this.el).find(".supprimerDDay[data-year='" + year + "'][data-week='" + week + "']").prop("checked", true);
        $(this.el).find(".supprimerDLine[data-year='" + year + "'][data-week='" + week + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerDWeek[data-year='" + year + "'][data-week='" + week + "']").prop("checked", false);
        $(this.el).find(".supprimerDDay[data-year='" + year + "'][data-week='" + week + "']").prop("checked", false);
        $(this.el).find(".supprimerDLine[data-year='" + year + "'][data-week='" + week + "']").prop("checked", false);
      }
    }
    this._checkWeekEvtPeriodSelected(checked, action, year, month, week);
    this._checkAllPeriodSelected(action);
  }

  _manageDayCheckbox(jqEvent: JQuery.TriggeredEvent): void {
    const checked = $(jqEvent.currentTarget).prop('checked');
    const className = jqEvent.currentTarget.className.split(" ")[0];
    const action = className === "supprimerPDay" ? "P" : "D";
    const modelId = jqEvent.currentTarget.className.split(" ")[1];
    const year = modelId.split(",")[0];
    const month = modelId.split(",")[1];
    const week = modelId.split(",")[2];
    const dateBBDD = modelId.split(",")[3];


    this._manageDayRows(checked, year, month, dateBBDD, action);
    this._checkButtonsSupprimerEv();
    if (action === "P") { //PREVUE
      if (checked === true) {
        $(this.el).find(".supprimerPDay[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", true);
        $(this.el).find(".supprimerPLine[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerPDay[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
        $(this.el).find(".supprimerPLine[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
      }
    } else { //Declaree
      if (checked === true) {
        $(this.el).find(".supprimerDDay[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", true);
        $(this.el).find(".supprimerDLine[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerDDay[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
        $(this.el).find(".supprimerDLine[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
      }
    }
    this._checkDayEvtPeriodSelected(checked, action, year, month, week, dateBBDD);
    this._checkAllPeriodSelected(action);
  }

  _manageLineCheckbox(jqEvent: JQuery.TriggeredEvent): void {
    const checked = $(jqEvent.currentTarget).prop('checked');
    const className = jqEvent.currentTarget.className.split(" ")[0];
    const action = className === "supprimerPLine" ? "P" : "D";
    const modelId = jqEvent.currentTarget.className.split(" ")[1];
    const dta = modelId.split(",");
    const year = dta[0];
    const month = dta[1];
    const dateBBDD = modelId.split(",")[3];
    let code1 = jqEvent.currentTarget.className.split(" ")[2];

    const code = code1;
    if (code1.indexOf("_") !== -1) {
      code1 = code1.replace('_', '/');
    }

    this._manageEvRows(checked, code1, year, month, dateBBDD, action);
    this._checkButtonsSupprimerEv();
    if (action === "P") { //PREVUE
      if (checked === true) {
        $(this.el).find(".supprimerPLine." + code + "[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerPLine." + code + "[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
      }
    } else { //Declaree
      if (checked === true) {
        $(this.el).find(".supprimerDLine." + code + "[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", true);
      } else {
        $(this.el).find(".supprimerDLine." + code + "[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
      }
    }
    this._checkEvtPeriodSelected(checked, action, code1);
    this._checkAllPeriodSelected(action);
  }

  _checkAllDaySelected(action: string, year: string, month: string, week: string, dateBBDD: string): void {
    let className = null;
    let classNameDay = null;
    if (action === "P") {
      // Prevue
      className = "supprimerPLine";
      classNameDay = "supprimerPDay";
    } else {
      // Declare
      className = "supprimerDLine";
      classNameDay = "supprimerDDay";
    }

    const dayElements = $(this.el).find("." + className + "[data-year='" + year + "'][data-month='" + month + "'][data-week='" + week + "'][data-date='" + dateBBDD + "']");
    let allChecked = true;
    let allUnChecked = true;
    _.each(dayElements, (element: { [key: string]: any }) => {
      allChecked = allChecked && (element.checked === true);
      allUnChecked = allUnChecked && (element.checked === false);
    });
    if (allChecked === true) {
      $(this.el).find("." + classNameDay + "[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", true);
    } else {
      $(this.el).find("." + classNameDay + "[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
    }
    if (allUnChecked === true) {
      $(this.el).find("." + classNameDay + "[data-year='" + year + "'][data-month='" + month + "'][data-date='" + dateBBDD + "']").prop("checked", false);
    }
  }

  _checkAllWeekSelected(action: string, year: string, month: string, week: string): void {
    let className = null;
    let classNameWeek;
    if (action === "P") {
      // Prevue
      className = "supprimerPLine";
      classNameWeek = "supprimerPWeek";
    } else {
      // Declare
      className = "supprimerDLine";
      classNameWeek = "supprimerDWeek";
    }

    const dayElements = $(this.el).find("." + className + "[data-year='" + year + "'][data-month='" + month + "'][data-week='" + week + "']");
    let allChecked = true;
    let allUnChecked = true;
    _.each(dayElements, (element: { [key: string]: any }) => {
      allChecked = allChecked && (element.checked === true);
      allUnChecked = allUnChecked && (element.checked === false);
    });
    if (allChecked === true) {
      $(this.el).find("." + classNameWeek + "[data-year='" + year + "'][data-month='" + month + "'][data-week='" + week + "']").prop("checked", true);
    } else {
      $(this.el).find("." + classNameWeek + "[data-year='" + year + "'][data-month='" + month + "'][data-week='" + week + "']").prop("checked", false);
    }
    if (allUnChecked === true) {
      $(this.el).find("." + classNameWeek + "[data-year='" + year + "'][data-month='" + month + "'][data-week='" + week + "']").prop("checked", false);
    }
  }

  _checkAllMonthSelected(action: string, year: string, month: string): void {
    let className = null;
    let classNameMonth;
    if (action === "P") {
      // Prevue
      className = "supprimerPLine";
      classNameMonth = "supprimerPMonth";
    } else {
      // Declare
      className = "supprimerDLine";
      classNameMonth = "supprimerDMonth";
    }

    const dayElements = $(this.el).find("." + className + "[data-year='" + year + "'][data-month='" + month + "']");
    let allChecked = true;
    let allUnChecked = true;
    _.each(dayElements, (element: { [key: string]: any }) => {
      allChecked = allChecked && (element.checked === true);
      allUnChecked = allUnChecked && (element.checked === false);
    });
    if (allChecked === true) {
      $(this.el).find("." + classNameMonth + "[data-year='" + year + "'][data-month='" + month + "']").prop("checked", true);
    } else {
      $(this.el).find("." + classNameMonth + "[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
    }
    if (allUnChecked === true) {
      $(this.el).find("." + classNameMonth + "[data-year='" + year + "'][data-month='" + month + "']").prop("checked", false);
    }
  }

  _checkAllPeriodSelected(action: string): void {
    let className = null;
    let classNameHeader;
    const representation = this.workflow.modeRepresentationModel.get("representation");
    const infoClass = (representation === this.workflow.MONO) ? "Mono" : "Mixte";

    if (action === "P") {
      // Prevue
      className = "supprimerPLine";
      classNameHeader = "supprimerPHeader" + infoClass;
    } else {
      // Declare
      className = "supprimerDLine";
      classNameHeader = "supprimerDHeader" + infoClass;
    }

    const dayElements = $(this.el).find("." + className);
    let allChecked = true;
    let allUnChecked = true;
    _.each(dayElements, (element: { [key: string]: any }) => {
      allChecked = allChecked && (element.checked === true);
      allUnChecked = allUnChecked && (element.checked === false)
    });
    if (allChecked === true) {
      $(this.el).find("." + classNameHeader).prop("checked", true);
    } else {
      $(this.el).find("." + classNameHeader).prop("checked", false);
    }
    if (allUnChecked === true) {
      $(this.el).find("." + classNameHeader).prop("checked", false);
    }
  }

  _checkEvtPeriodSelected(checked: boolean, action: string, id: string): void {
    let clName = null;

    if (action === "P") {
      // Prevue
      clName = "supprimerPLine";
    } else {
      // Declare
      clName = "supprimerDLine";
    }

    const criteria = "." + clName + "[data-id='" + id + "']";
    const dayElements = $(this.el).find(criteria);
    const lstMo: Array<string> = [];
    const lstWe: Array<string> = [];
    const lstDa: Array<string> = [];

    _.each(dayElements, (element: { [key: string]: any }) => {
      // element.className => supprimerPLine 2023,5,23,20230605 1629965 custom-control-input
      const dta = element.className.split(" ")[1].split(",");
      const mo = dta[0] + "," + dta[1];
      const we = dta[0] + "," + dta[1] + "," + dta[2];
      const da = dta[0] + "," + dta[1] + "," + dta[2] + "," + dta[3];

      if (!_.contains(lstMo, mo)) {
        lstMo.push(mo);
      }

      if (!_.contains(lstWe, we)) {
        lstWe.push(we);
      }

      if (!_.contains(lstDa, da)) {
        lstDa.push(da);
      }
      element.checked = checked;
    });

    // pour mois / week / jour, on regarde s'il n'y a pas de case à cocher / decocher
    for (let i = 0; i < lstDa.length; i++) {
      const yr = lstDa[i].split(",")[0];
      const mo = lstDa[i].split(",")[1];
      const we = lstDa[i].split(",")[2];
      const da = lstDa[i].split(",")[3];
      this._checkAllDaySelected(action, yr, mo, we, da);
    }
    for (let i = 0; i < lstWe.length; i++) {
      const yr = lstWe[i].split(",")[0];
      const mo = lstWe[i].split(",")[1];
      const we = lstWe[i].split(",")[2];
      this._checkAllWeekSelected(action, yr, mo, we);
    }
    for (let i = 0; i < lstMo.length; i++) {
      const yr = lstMo[i].split(",")[0];
      const mo = lstMo[i].split(",")[1];
      this._checkAllMonthSelected(action, yr, mo);
    }
  }

  _checkDayEvtPeriodSelected(checked: boolean, action: string, year: string, month: string, week: string, dateBBDD: string): void {
    let className = null;
    if (action === "P") {
      // Prevue
      className = "supprimerPLine";
    } else {
      // Declare
      className = "supprimerDLine";
    }
    const lstId: Array<string> = [];
    const dayElements = $(this.el).find("." + className + "[data-year='" + year + "'][data-month='" + month + "'][data-week='" + week + "'][data-date='" + dateBBDD + "']");
    _.each(dayElements, (element: { [key: string]: any }) => {
      // element.className => supprimerPLine 2023,5,23,20230605 1629965 custom-control-input
      const id = element.className.split(" ")[2];
      if (!_.contains(lstId, id)) {
        lstId.push(id);
      }
    });
    //  on regarde s'il n'y a pas de case à cocher / decocher
    for (let i = 0; i < lstId.length; i++) {
      this._checkEvtPeriodSelected(checked, action, lstId[i])
    }
  }

  _checkWeekEvtPeriodSelected(checked: boolean, action: string, year: string, month: string, week: string): void {
    let className = null;
    if (action === "P") {
      // Prevue
      className = "supprimerPLine";
    } else {
      // Declare
      className = "supprimerDLine";
    }
    const lstId: Array<string> = [];
    const dayElements = $(this.el).find("." + className + "[data-year='" + year + "'][data-month='" + month + "'][data-week='" + week + "']");
    _.each(dayElements, (element: { [key: string]: any }) => {
      // element.className => supprimerPLine 2023,5,23,20230605 1629965 custom-control-input
      const id = element.className.split(" ")[2];
      if (!_.contains(lstId, id)) {
        lstId.push(id);
      }
    });
    // on regarde s'il n'y a pas de case à cocher / decocher
    for (let i = 0; i < lstId.length; i++) {
      this._checkEvtPeriodSelected(checked, action, lstId[i])
    }
  }

  _checkMonthEvtPeriodSelected(checked: boolean, action: string, year: string, month: string): void {
    let className = null;
    if (action === "P") {
      // Prevue
      className = "supprimerPLine";
    } else {
      // Declare
      className = "supprimerDLine";
    }
    const lstId: Array<string> = [];
    const dayElements = $(this.el).find("." + className + "[data-year='" + year + "'][data-month='" + month + "']");
    _.each(dayElements, (element: { [key: string]: any }) => {
      // element.className => supprimerPLine 2023,5,23,20230605 1629965 custom-control-input
      const id = element.className.split(" ")[2];
      if (!_.contains(lstId, id)) {
        lstId.push(id);
      }
    });
    // on regarde s'il n'y a pas de case à cocher / decocher
    for (let i = 0; i < lstId.length; i++) {
      this._checkEvtPeriodSelected(checked, action, lstId[i])
    }
  }

  _manageAllRows(checked: boolean, action: string): void {
    const tableColl = this.table.model.coll;

    _.each(tableColl.models, (tableRow: { [key: string]: any }) => {
      this._manageRow(tableRow, checked, action);
    });
  }

  _manageMonthRows(checked: boolean, year: string, month: string, action: string): void {
    const tableColl = this.table.model.coll;

    _.each(tableColl.models, (tableRow: { [key: string]: any }) => {
      if (String(tableRow.year) === year && String(tableRow.month) === month) {
        this._manageRow(tableRow, checked, action);
      }
    });
  }

  _manageWeekRows(checked: boolean, year: string, week: string, action: string): void {
    const tableColl = this.table.model.coll;

    _.each(tableColl.models, (tableRow: { [key: string]: any }) => {
      if (String(tableRow.year) === year && String(tableRow.week) === week) {
        this._manageRow(tableRow, checked, action);
      }
    });
  }

  _manageDayRows(checked: boolean, year: string, month: string, dateBBDD: string, action: string): void {
    const tableColl = this.table.model.coll;

    _.each(tableColl.models, (tableRow: { [key: string]: any }) => {
      if (String(tableRow.year) === year && String(tableRow.month) === month && String(tableRow.currentDate) === dateBBDD) {
        this._manageRow(tableRow, checked, action);
      }
    });
  }

  _manageEvRows(checked: boolean, code: string, year: string, month: string, dateBBDD: string, action: string): void {
    const tableColl = this.table.model.coll;
    if (action === "P") {
      _.each(tableColl.models, (tableRow: { [key: string]: any }) => {
        const model = tableRow.get("prevueColumn");
        if (String(tableRow.year) === year && String(tableRow.month) === month && String(tableRow.currentDate) === dateBBDD && !CWSTR.isBlank(model) && String(model.get("code")) === String(code)) {
          this._manageRow(tableRow, checked, action);
        }
      });
    } else {
      _.each(tableColl.models, (tableRow: { [key: string]: any }) => {
        const model = tableRow.get("realiseeColumn");
        if (String(tableRow.year) === year && String(tableRow.month) === month && String(tableRow.currentDate) === dateBBDD && !CWSTR.isBlank(model) && String(model.get("code")) === String(code)) {
          this._manageRow(tableRow, checked, action);
        }
      });
    }
  }

  _manageRow(model: { [key: string]: any }, checked: boolean, action: string): void {
    const tableColl = this.table.model.coll;
    if (action === "P") {
      // Prevue
      if (model.level === 4 && !CWSTR.isBlank(model.evPSuppr)) {
        // Only evenements are summarized at nbevPSuppr
        if (checked === true) {
          if (model.evPSuppr === true) {
            tableColl.nbevPSuppr--;
          }
          model.evPSuppr = true;
          tableColl.nbevPSuppr++;
        } else {
          if (model.evPSuppr === true) {
            tableColl.nbevPSuppr--;
          }
          model.evPSuppr = false;
        }
      }
    } else {
      // Declaree
      if (model.level === 4 && !CWSTR.isBlank(model.evDSuppr)) {
        // Only evenements are summarized at nbevPSuppr
        if (checked === true) {
          if (model.evDSuppr === true) {
            tableColl.nbevDSuppr--;
          }
          model.evDSuppr = true;
          tableColl.nbevDSuppr++;
        } else {
          if (model.evDSuppr === true) {
            tableColl.nbevDSuppr--;
          }
          model.evDSuppr = false;
        }
      }
    }
  }

  _hoverBtnTitle(event: JQuery.TriggeredEvent, typeevenement: string, typeView: string): void {
    if (event.type === "mouseenter" && this.workflow._recapEtatActCanBeInstantiated(typeevenement) === true) {
      // When the mouse enter on the title block
      const target = event.currentTarget;
      const menuOptions: Array<{ [key: string]: any }> = [];
      const buttonText = i18n.t('common:gererrecapitulatifs.btnAjouter');
      // const buttonText = UTILS.getSVGIcon('plus');
      let buttonAction = "ajouter";

      const recap = this.workflow.headerModel.get("value");
      const datedebRecap = recap.get("datedeb");
      const datefinRecap = recap.get("datefin");
      let startDate = $(target).attr("data-startdate");
      let endDate = $(target).attr("data-enddate");
      startDate = startDate < datedebRecap ? datedebRecap : startDate;
      endDate = endDate > datefinRecap ? datefinRecap : endDate;

      // add dynamic typologies to plusopt
      _.each(GLOBAL_DATA.typologies.models, (typ: { [key: string]: any }) => {
        const typologie = _.find(this.workflow.Typologies, (item: { [key: string]: any }) => {
          return item.code === typ.get("code");
        });

        if (!CWSTR.isBlank(typologie)) {
          if (_.contains(typologie.get("typeevt"), typeevenement)) {
            const btnObj = { action: typ.get("code") + "," + startDate + "," + endDate + "," + typeevenement, text: i18n.t('common:gererrecapitulatifs.btnAjouter') + " " + typ.get("evenement") };
            menuOptions.push(btnObj);
          }
        }
      });

      if (menuOptions.length > 0) {
        let width = "10em";
        const span = $("<span>");
        let createMenu = null;

        if (menuOptions.length === 1) {
          buttonAction = menuOptions[0].action;
          width = "8em";
        }
        createMenu = new CWMenuButtonView({
          buttonText: buttonText,
          buttonAction: buttonAction,
          hasMenu: menuOptions.length > 1,
          menuOptions: menuOptions,
          lastAction: false,
          isButton: true,
          buttonWidth: width
        });
        span.addClass("button-gerer-recap-span");
        span.html(createMenu.render().el);
        //span.find(".ajouter").css("height", "19px");
        span.appendTo($(target) as JQuery);

        if (typeView === this.workflow.MONO) {
          // Monotypeview
          if (menuOptions.length === 1) {
            createMenu.$el.find("button.btn").on("click", (event: JQuery.TriggeredEvent) => {
              // split with second class name to get id with white space
              const buttonId = event.currentTarget.className.split(" btn")[0];
              this.workflow._ajoutActivityMonoType(buttonId);
            });
          } else {
            createMenu.model.on("btn:click", this.workflow._ajoutActivityMonoType, this.workflow);
          }
        } else {
          // Mixte type view
          if (menuOptions.length === 1) {
            createMenu.$el.find("button.btn").on("click", (event: JQuery.TriggeredEvent) => {
              // split with second class name to get id with white space
              const buttonId = event.currentTarget.className.split(" btn")[0];
              this.workflow._ajoutActivityMixteType(buttonId);
            });
          } else {
            createMenu.model.on("btn:click", this.workflow._ajoutActivityMixteType, this.workflow);
          }
        }
      }
    } else { // When the mouse get out the title block
      this.$el.find(".button-gerer-recap-span").remove();
      const target = event.currentTarget;
      if (typeevenement === "P") {
        $(target).css("width", "auto");
      } else {
        $(target).css("width", "100%");
        $(target).css("height", "100%");
      }
    }
  }
}
