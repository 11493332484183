import * as Backbone from 'Backbone';
import _ from 'underscore';
import detailSelecteurRefDialogTPL from '../cwDetailSelecteurRefDialog.tpl.html';
import { CWBaseGridColums, validPaginationPositions } from 'core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Selecteur Referentiel Dialog View
 */
export class CWSelecteurRefDialogView extends Backbone.View {

  coll: any;
  table: CWDataGridView;
  excludedItems: any;
  searchTerm: string;
  protected paginatorPosition: validPaginationPositions;

  constructor(options: { [key: string]: any }) {
    options = options || {};
    options.tagName = options.tagName || "div";
    options.className = options.className || "0 refDialogUtilisateur"
    super(options);
    this.template = detailSelecteurRefDialogTPL;
    this.coll = options.coll;
    this.excludedItems = options.excludedItems;
    this.paginatorPosition = options.paginatorPosition || "auto";
    // Create table
    this.table = this._initTable();
    this.table.model.on("change:value", this.selection, this);
    this.searchTerm = "";
    this.table.model.coll.on("row:dblclick", this._selectionAndClose, this);
  }

  _manageTable(): void {
    this.table.model.resetRowHeight();
    this.table.model.coll.applyFilter(_.extend({}, this.searchTerm, { "search": this.searchTerm }));
    this.table.model.coll.fetch({
      success: () => {
        if (this.excludedItems) {
          const models = _.reject(this.table.model.coll.models, (value) => {
            return !_.contains(this.excludedItems, value.id);
          });
          this.table.model.coll.remove(models);
          this.table.model.coll.trigger("reset");
        }
      }
    });
  }

  render(): CWSelecteurRefDialogView {
    const json = { 'i18n': i18n };

    this.$el.html(this.template(json));
    this.$el.parent().css({ "height": "600px", "max-height": "600px", "overflow": "hidden" });
    this.$el.css({ "height": "90%" });
    // Render table
    $(this.el).find(".table_selecteurref_utilisateur").css({ "height": "calc(100% - 58px)" });
    $(this.el).find(".table_selecteurref_utilisateur").html(this.table.render().el);
    this._manageTable();
    $(this.el).find(".criteres").autocomplete({
      minLength: 0,
      source: (request: any) => {
        this.searchTerm = request.term;
        this._manageTable();
        return false;
      }
    });
    return this;
  }

  selection(): CWBaseModel {
    const selection = this.table.model.get("value");

    this.trigger("response", selection);
    return selection;
  }

  _selectionAndClose(): void {
    this.trigger("close", "Y");
  }

  _initTable(): CWDataGridView {
    const tableModel = new CWDataGridModel({ coll: this.coll, enableSelectionOnDblclick: true });
    const messCollabMat = i18n.t('droitsdel.collabMatricule1') + "<br/>" + i18n.t('droitsdel.collabMatricule2');
    const messUtilCode = i18n.t('droitsdel.utilCode1') + "<br/>" + i18n.t('droitsdel.utilCode2');
    const tableColumns: Array<CWBaseGridColums> = [
      { title: messCollabMat, property: "matricule", width: 2 },
      { title: i18n.t('droitsdel.collabMatriculeaux'), property: "matriculeaux", width: 2 },
      { title: i18n.t('droitsdel.collabNom'), property: "nom", width: 2 },
      { title: i18n.t('droitsdel.collabPrenom'), property: "prenom", width: 2 },
      { title: messUtilCode, property: "utilcode", width: 2 },
      { title: i18n.t('droitsdel.utilCode1') + "<br/>" + i18n.t('droitsdel.utilNom'), property: "utilnom", width: 2 }
    ];
    const table = new CWDataGridView({
      id: "droitsdel",
      columns: tableColumns,
      model: tableModel,
      title: i18n.t('droitsdel.legend_selectref_table'),
      showFilteredRowsInTitle: true,
      multiselection: false,
      paginatorPosition: this.paginatorPosition,
    });

    table.cellRenderers["prenom"] = (model: { [key: string]: any }): JQuery => {
      let resp = "";
      const rtn = $("<div class='add-texte-reduit cw-reduitLibelle'>");

      if (!CWSTR.isBlank(model.get("prenom"))) {
        resp = model.get("prenom");
      }
      return rtn.html(resp);
    };
    //Il sera possible le trier par tous le colonnes
    return table;
  }
}