import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

/**
 * Selecteur Activites Niveau Model
 */
export var NivModel = BaseModel.extend({
  /**
   * Add this to have Outline
   *
   */

  /**
   * Default data model
   *	rattactivite: false,
   *	nbactratcol: "",
   *	niveau: "",
   *	libelle: "",
   *	rattcollab: false,
   *	abrege: "",
   *	equipe: false,
   *	nbactivite: "",
   *	nbcollab: ""
   *}
   */
  defaults: {
    rattactivite: false,
    nbactratcol: "",
    niveau: "",
    libelle: "",
    rattcollab: false,
    abrege: "",
    equipe: false,
    nbactivite: "",
    nbcollab: ""
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && STR.isBlank(response.id) && !STR.isBlank(response.niveau)) {
      response.id = response.niveau;
    }
    return response;
  }
});
