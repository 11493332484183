import * as Backbone from 'Backbone';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWCustomBarView } from './cwCustomBar.view';
import { CWSimplePanelView } from 'core/layouts/cwSimplePanel.view';
import { CWSuvietatView } from './cwSuvietat.view';
import { CWSuvietatWorkflow } from '../models/cwSuvietat.workflow';
import { i18n } from 'src/i18n.js';


/**
 * It will paint all the tab for the UC.
 */
export class CWGeneralView extends CWBaseFormView {

  parent: any;
  workflow: CWSuvietatWorkflow;
  layout: any;
  table: any;
  customBar: any;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options.className = 'suvietat';
    super(options);
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.workflow = new CWSuvietatWorkflow();
    this.layout = new CWSimplePanelView({ className: "l-simplePanel" });
    this.table = this._initTable(); //table view
    this.workflow.tableModel = this.table.model;
    this.customBar = new CWCustomBarView({ workflow: this.workflow });
    this.workflow.customBarModel = this.customBar.model;
  }

  /**
   * Init screen
   */
  list(): void {
    this._initLayoutUC();
  }

  _initLayoutUC(callback?: (arg?: any) => void): void {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      }
    } else {
      this.workflow.setUp((): void => {
        //When the workflow is ready, we can render the view without concerns
        this.layout.$el.find(".l-simplePanel__panelA").empty();
        this.layout.$el.find(".l-simplePanel__panelA").append(this.customBar.render().el);
        this.layout.$el.find(".l-simplePanel__panelA").append(this.table.render().el);

        if (callback) {
          callback();
        }
        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        this.trigger("uc:ready");
      });
    }
  }

  _initTable(): CWSuvietatView {
    const tableView = new CWSuvietatView({
      workflow: this.workflow,
      showFilteredRowsInTitle: true,
      parent: this.parent
    });
    return tableView;
  }

  render(): CWGeneralView {
    this.$el.html(this.layout.render().el);
    // allow auto height of dialog
    this.parent._setTitle(i18n.t('suvietat.title_popup'));
    this._initLayoutUC((): void => {
      if (this.parent) {
        this.parent.resize();
      }
    });
    return this;
  }
}