import { BaseModel } from 'core/models/base.model.js';

/**
 * Model for the messages
 */
export var MessageModel = BaseModel.extend({

  defaults: {
    "code": "",
    "message": "",
    "datedeb": "",
    "datefin": "",
    "bloquant": false
  }
});