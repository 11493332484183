import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWCalendaccModel } from '../models/cwCalendacc.model';
import { CWFormView as VueannuelleFormView } from 'common/vueannuelle/views/cwForm.view';
import { CWFormView as VuemensuelFormView } from 'common/vuemensuel/views/cwForm.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


export class CWFormView extends Backbone.View {

  workflow: any;
  vueannuelle: VueannuelleFormView;
  vuemensuel: VuemensuelFormView;
  usecase: string;

  constructor(options: { [key: string]: any }) {
    options = options || {};
    super(options);
    this.workflow = options.workflow;
    this.usecase = options.usecase;
    this.vueannuelle = new VueannuelleFormView({ droppable: true, workflow: this.workflow });
    this.vuemensuel = new VuemensuelFormView({ droppable: true, workflow: this.workflow });
    //change the render functions
    this.vueannuelle.cellRender = this._renderCellsVueannuelle;
    this.vueannuelle.isJourExceptionnel = (stringDate: string): boolean => {
      const model = this.workflow.formModel;
      const elements = model.get("elements");
      const day = elements.get(stringDate);
      let jex = false;

      if (day) {
        jex = day.get("nature") === "E";
      }
      return jex;
    };
    this.vueannuelle.isJourFerie = (stringDate: string): boolean => {
      const model = this.workflow.formModel;
      const elements = model.get("elements");
      const day = elements.get(stringDate);
      let jfe = false;

      if (day) {
        jfe = day.get("nature") === "F";
      }
      return jfe;
    };
    this.vueannuelle.isJourHorRepos = (): boolean => {
      return false;
    };
    this.model = new CWCalendaccModel({}, { "usecase": this.usecase });
  }


  render(): CWFormView {
    $(this.el).html(this.vueannuelle.render().el);
    return this;
  }

  _renderCellsVueannuelle(parent: any, stringDate: string): void {
    const model = this.workflow.formModel;
    const elements = model.get("elements");
    const zones = model.get("zones");
    const vasco = model.get("vasco");
    const day = elements.get(stringDate);

    if (day) {
      const vacances: any = [];
      const backgroundZones: any = [];
      const titleZones: any = [];

      //view if is day vacences
      _.each(day.get("vacances"), (vac: any) => {
        const objVac = vasco.get(vac.id);
        const cols = model.attributes.zones.models.length;
        const style = "display:inline-block;width:6px;height:20px;;z-index:2;position:relative;";
        const cellDom: any = $("<div style='position:relative;top:0px;left:0px'></div>");

        vacances[vac.zone] = vac;
        backgroundZones[vac.zone] = "background-color:" + zones.get(vac.zone).get("colorRGB");
        titleZones[vac.zone] = " title='" + i18n.t('calendacc.titleVacances', {
          "0": zones.get(vac.zone).get("libelle"),
          "1": objVac.get("type").libelle,
          "2": CWTYPE.DATE.format(objVac.get("datedeb")),
          "3": CWTYPE.DATE.format(objVac.get("datefin"))
        }) + "' ";
        // Dynamic creation of zone lines
        parent.css({ "width": cols * 6 });
        parent.css("height", "100%");
        _.each(model.attributes.zones.models, (model: any) => {
          if (!CWSTR.isBlank(backgroundZones[model.attributes.code])) {
            cellDom.append($("<div style='" + style + "border-right:1px solid white;" + backgroundZones[model.attributes.code] + "'" + titleZones[model.attributes.code] + "></div>"));
          } else {
            cellDom.append($("<div style= '" + style + "'" + titleZones[model.attributes.code] + "></div>"));
          }
          parent.html(cellDom);
        });
      });
    }
  }
}
