import { BaseModel } from 'core/models/base.model.js';
import { ButtonBarView } from 'core/controls/buttonBar.view.js';
import { FileExtensionModel } from 'core/models/fileExtension.model.js';
import { FileTransferItemView } from './fileTransferItem.view.js';
import { HEADERS } from 'utils/headers.js';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { CWMSGS } from 'utils/cwMsgs';
import TPL_common_file_transfer from './file_transfer.tpl.html';

/**
 * File transfer component
 *
 * This component has the hability to be reloaded on different windows and maintain its state. This hability
 * is obtained thru the use of the function renderRefresh
 */
export var FileTransferView = Backbone.View.extend({

  /**
   * uploadReady event.
   *
   */
  /**
   * successUpload event.
   *
   */
  /**
   * errorUpload event.
   *
   */
  /**
   * progressUpload event.
   *
   */

  tagName: "div",
  className: "phx-file-transfer",

  /**
   * Constructor
   * Component for transfering file from the client to the serveur
   * Used to upload Photos and Pièces jointes
   * Attributes:
   * Events: uploadReady, successUpload, errorUpload, progressUpload
   */
  initialize: function(options) {
    this.template = TPL_common_file_transfer;

    this.model = new BaseModel();
    this.extensionModel = new FileExtensionModel();

    this.multiples = false;
    this.delayedUpload = false;
    if (options) {
      this.entite = options.entite;
      this.type = options.type; // for now PHCOLLAB and PJEVTABS
      if (options.habContext) {
        this.habilitationContext(options.habContext);
      }
      if (_.isBoolean(options.delayedUpload)) {
        this.delayedUpload = options.delayedUpload;
      }
    }
    this.btnBar = new ButtonBarView({ id: "filetransfer_btnbar" });
    this.btnBar.model.on("btn:click", this._buttonAction, this);

    this.multipleTransfer = false;

    this.inputField = null;
  },

  render: function() {
    var self = this;
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));
    this.$el.find(".fil_transfer_btnBar").html(this.btnBar.render().el);
    this.btnBar.hideButton("new");
    this.btnBar.hideButton("revert");
    this.btnBar.hideButton("delete");
    this.btnBar.hideButton("copy");
    this.btnBar.addButton("cancel", i18n.t('common:uploadfile.cancelButton'), false);
    this.btnBar.enableButton("cancel");
    this.btnBar.enableButton("save");

    this._retrieveSupportedFileExtensions(this.type, function(ext) {
      self._configureForm(ext);
    });

    return this;
  },

  _buttonAction: function(buttonId) {
    LOG.debug("Button clicked in motifsregul : " + buttonId);
    switch (buttonId) {
      case "save":
        if (this.delayedUpload === true) {
          // avisamos que hemos terminado
          this.model.trigger("uploadReady", this);
        } else {
          if (this.shouldSubmit()) {
            // realizamos el submit
            this.submit(this.entite);
          }
        }
        break;
      case "cancel":
        this._clearInputFileField();
        this.$el.find(".file-transfer-description").attr("disabled", false);
        this.$el.find(".file-transfer-description").val("");
        this.$el.find(".file-transfer-label").html("");
        this.$el.find("form").clearForm();
        this.model.trigger("cancelUpload");
        break;
      default:
        break;
    }
  },

  /**
   *  Set the habilitation used by the component to upload the file
   */
  habilitationContext: function(context) {
    if (this.model) {
      this.model.setHabContext(context);
    }
    if (this.extensionModel) {
      this.extensionModel.setHabContext(context);
    }
  },

  setHabContext: function(context) {
    if (this.model) {
      this.model.setHabContext(context);
    }
    if (this.extensionModel) {
      this.extensionModel.setHabContext(context);
    }
  },

  /**
   * This function triggers a form submit and listens for the resulting events
   *
   */
  submit: function(evenement, isModification) {
    if (this.shouldSubmit() && evenement) {
      var self = this;
      this.$el.find("input[name='id_entite']").val(evenement);
      // override form submit
      var format = "json";
      if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
        format = "xml";
      }

      //Customer 145490
      if (isModification > -1 && this.type === "PHCOLLAB") {
        this.$el.find("form").attr("action", this.$el.find("form").attr("action") + "/" + isModification);
      }

      this.$el.find("form").ajaxForm({
        //data:{"X_REQUESTED_WITH":"XMLHttpRequest"}, //TODO put here the habilitations header
        dataType: format,
        beforeSubmit: function(arr, form, options) {
          options.dataType = 'json';
          options.headers = HEADERS.habilitationContext(self.model.getHabContext().get("onglet"), self.model.getHabContext().get("foncCour"), "A");
          options.headers.charset = document.charset;
          if (self.multipleTransfer !== true) {
            self.$el.find("form :file").attr("disabled", true);
            self.$el.find(".file-transfer-description").attr("disabled", true);
            self.$el.find(".file-transfer-cancel").attr("disabled", true);
          }

          if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
            self.$el.find(".file-transfer-label").html('<img src="img/planresp_loading.gif" />');
          }
        },
        success: function() {
          self._cleanForm();

          self.model.trigger("successUpload");
        },
        uploadProgress: function(event, pos, total, per) {
          //self.$el.find(".file-transfer-label").html(per+"%");
          self.model.trigger("progressUpload", per + "%");
        },
        error: function() {
          if (navigator.appVersion && navigator.appVersion.indexOf("MSIE 9.0") > 0) {
            CWMSGS.showError(i18n.t('common:uploadfile.errorAucuneIe9'));
          }
          self._cleanForm();

          self.model.trigger("errorUpload");
        }
      });

      this.$el.find("form").submit();
    }
  },

  /**
   * Check if there are files to upload
   *
   */
  shouldSubmit: function() {
    return (this.inputField && this.inputField.hasValue());
  },

  /**
   * Retrieves the list of files to upload
   *
   */
  fileToUpload: function() {

    if (this.shouldSubmit()) {
      var obj = {};
      obj.code = null;
      obj.libelle = this.inputField.getFileName();
      obj.description = this.getDescription();
      obj.cid = this.cid;
      return obj;
    } else {
      return null;
    }
  },

  getDescription: function() {
    return this.$el.find(".file-transfer-description").val();
  },
  /**
   * If the upload will be delayed upon validation of the host component, then the component only trigger an event.
   * Otherwise the submit of the form is called to start the upload
   *
   */
  _submit: function() {

    if (this.delayedUpload === true) {
      // avisamos que hemos terminado
      this.model.trigger("uploadReady", this);
    } else {
      if (this.shouldSubmit()) {
        // realizamos el submit
        this.submit(this.entite);
      }
    }
  },

  /**
   * Removes all form information and reset to its default
   *
   */
  _cleanForm: function() {
    if (this.multipleTransfer !== true) {
      this._clearInputFileField();

      this.$el.find(".file-transfer-description").attr("disabled", false);
      this.$el.find(".file-transfer-description").val("");

      this.$el.find(".file-transfer-label").html("");

      this.$el.find("form").clearForm();

      this.model.trigger("cancelUpload");
      // not used in pieces jointes.
    }
  },

  /**
   * Resets and removes all the input fields
   *
   */
  _clearInputFileField: function() {
    if (this.inputField) {
      this.inputField.remove();
      this.inputField = null;
    }

    this._addInputFileField();
  },

  /**
   * Add an input field to allow the selection of a file
   *
   */
  _addInputFileField: function() {
    var self = this;
    var input = new FileTransferItemView({ allowRemove: false, allowAdd: false });
    var div = this.$el.find(".more-input-files");
    if (div && input) {
      input.setName("fichier");
      input.setFileExtensions(this.extensionModel.get("data"));
      input.listenTo(input.model, "add", function() {
        self._addInputFileField();
      });
      input.listenTo(input.model, "remove", function() {
        input.remove();
        self._refreshInputs();
        if (self.inputFields.length === 0) {
          self._addInputFileField();
        }
      });
      this.inputField = input;

      div.append(input.render().el);
    }
  },

  /**
   * Configure the form and add the function to treat the upload process
   *
   *
   */
  _configureForm: function(extensions) {
    this._addInputFileField();

    // set form url
    if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
      this.$el.find("form").attr("action", Configuration.restRoot + "/rest/fichier/upload/textarea");
    } else {
      this.$el.find("form").attr("action", Configuration.restRoot + "/rest/fichier/upload");
    }
    // hide input file
    this.$el.find(".file-transfer-label").html("");

    // set hidden values
    this.$el.find("input[name='type_utilisation']").val(this.type);
    this.$el.find("input[name='id_entite']").val(this.entite);

    // set supported file extensions
    if (extensions) {
      this.$el.find("form :file").attr("accept", extensions);
    }
  },

  /**
   * Retrieve the file extensions allowed for the upload
   *
   *
   */
  _retrieveSupportedFileExtensions: function(type, callback) {

    this.extensionModel.id = type;
    this.extensionModel.fetch({
      success: function(fresh) {
        var data = fresh.get("data");
        if (callback) {
          callback(data);
        }
      },
      error: function() {
        if (callback) {
          callback();
        }
      }
    });
  },
  setMultipleTransfer: function() {
    this.multipleTransfer = true;
  }
});
