import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLReferentielutilisateurDetail from '../detail.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDelegationsUtilisateurColl } from '../models/cwDelegationsUtilisateur.collection';
import { CWDelegationsUtilisateurModel } from '../models/cwDelegationsUtilisateur.model';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSelecteurRefDialogView } from './cwSelecteurRefDialog.view';
import { CWSelecteurReferentielView } from 'core/components/selecteur_referentiel/cwSelecteurReferentiel.view.js';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { validPaginationPositions } from 'src/core/grids/basegrid/cwBaseGrid.view';


export class CWSelectUtilisateurView extends Backbone.View<CWBaseModel> {

  host: CWBaseFormView;
  idComponent: any;
  context: { [key: string]: any };
  fieldWidth: number;
  habContext: CWHabilitationContext;
  referentiel: typeof CWSelecteurReferentielView;
  arrExcludeIds: any;
  $appendTo: JQuery;
  model: CWBaseModel;
  protected paginatorPosition: validPaginationPositions;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_keyup"
    });
    super(options);
    this.template = TPLReferentielutilisateurDetail;
    this.$appendTo = (!CWSTR.isBlank(options.ctxEcran)) ? $("#" + options.ctxEcran) : null;
    this.host = options.host;
    this.idComponent = options.idComponent;
    this.context = options.context;
    this.fieldWidth = options.fieldWidth; // Width of the ref input default is "100%"
    this.paginatorPosition = options.paginatorPosition || "auto";
    this.model = new CWDelegationsUtilisateurModel();
    this.habContext = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.Habilitation
    });
    this.model.setHabContext(this.habContext);
  }

  render(): CWSelectUtilisateurView {
    const json = { 'i18n': i18n };

    this.$el.html(this.template(json));
    this.referentiel = new CWSelecteurReferentielView({
      coll: new CWDelegationsUtilisateurColl([], { "workflow": (this.host as any).workflow }),
      name: this.context.name,
      itemRenderer: (model: any): string => {
        let resp = "";

        if (_.isNull(model.code) && !_.isNull(model.libelle)) {
          resp = model.libelle;
        } else {
          if (!CWSTR.isBlank(model.nom)) { // if have a collaborateur
            if ((this.host as any).workflow.paramIdCollab === "matric" || CWSTR.isBlank((this.host as any).workflow.paramIdCollab)) {
              resp = model.nom + " " + model.prenom + " (" + model.matricule + ")";
            } else if ((this.host as any).workflow.paramIdCollab === "matric_aux") {
              resp = model.nom + " " + model.prenom + " (" + model.matriculeaux + ")";
            }
          } else { // if have a utilisateur
            resp = i18n.t('droitsdel.utilisateur') + " " + model.utilnom;
          }
        }
        return resp;
      },
      view: CWSelecteurRefDialogView,
      title: i18n.t('droitsdel.legend_selectref_criteres'),
      addItemCallback: (data: { [key: string]: any }): any => {
        this._responseCallback(data);
      },
      fieldWidth: this.fieldWidth,
      appendTo: this.$appendTo,
      paginatorPosition: this.paginatorPosition,
      width: "1000px"
    });
    if (this.context) {
      this.referentiel.coll.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.Habilitation
      }));
    }
    this.referentiel._valuesFilter = (values: any): any => {
      if (this.arrExcludeIds) {
        return _.filter(values, (value: any) => {
          return !_.contains(this.arrExcludeIds, value.attrs.id);
        });
      }
      return values;
    };
    this.$el.find(".cmbSelectionner").html(this.referentiel.render().el);
    return this;
  }

  _responseCallback(data: { [key: string]: any }): void {
    this.model.set(data);
    if (this.host && (this.host as any)._responseCallback) {
      (this.host as any)._responseCallback(data, this.idComponent);
    }
  }

  _change(event: any): void {
    $(event.target).attr("title", String($(event.target).val()));
  }

  _keyup(event: any): void {
    if (String($(event.target).val()).length === 0) {
      $(event.target).attr("title", "");
    }
  }

  initWithCode(code: any): void {
    const originalModel = new CWDelegationsUtilisateurModel();
    const originalCollection = new Backbone.Collection();

    originalModel.code = code;
    originalModel.fetch({
      success: (fresh: any) => {
        originalCollection.push(fresh);
        this.referentiel.setValues(originalCollection);
      }
    });
  }

  // array of ids to remove from result list
  filterOut(arrExcludeIds: any): void {
    this.referentiel.arrExcludeIds = arrExcludeIds;
  }
}