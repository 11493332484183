import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';

/**
 * Model for the mixteview form
 */
export class CWMixteViewModel extends CWBaseModel {
  /**
   * Add this to have Outline
   *
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  defaults(): { [key: string]: any } {
    return {
      "toujours": false,
      "transformationMade": false
    }
  }

  validate(): { [key: string]: any } {
    const errors = {};

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}
