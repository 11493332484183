import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWDIVERS } from './cwDivers';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { EVENEMENT } from './evenement.js';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { LICENCE } from 'utils/licence.js';
import { STYLE } from 'utils/style.js';
import { UTILS } from 'utils/utils.js';

/**
 * Generic Render for planing and diary cells.
 */
export class CWRENDERAGENTS {

  static posClonedElements: JQuery = $("body .cloned_elements .cw-render_clone_elements");


  /**
   * Function that generate the primaire indicateur in planning (configurable). In agenda and edt, this method is not used (current indicateurs keeps being used)
   * */
  static renderIndicateurPrimaire(view: string, coleurBorderClass: string, coleurBackgroundClass: string, animate: boolean): JQuery {
    const $divIndicateur = $("<div>");
    const $animationContainer = $("<span>").addClass("animationContainer").css({
      height: "20px",
      display: "inline"
    });

    if (view === "M") {
      if (LICENCE.hasModule("GTM")) {
        $divIndicateur.addClass("ui-phx-icon-primary-indicateur-m-CTIME-GTMED ui-phx-icon-indicateurs-mensuel " + coleurBackgroundClass);
      } else {
        $divIndicateur.addClass("ui-phx-icon-primary-indicateur-m ui-phx-icon-indicateurs-mensuel " + coleurBackgroundClass);
      }
      $divIndicateur.addClass(coleurBorderClass + "_border");
      $divIndicateur.css("border", "1px solid rgb(0,0,0)");
      $divIndicateur.css("display", "inline-block");
    } else if (view === "S") {
      if (LICENCE.hasModule("GTM")) {
        $divIndicateur.addClass("ui-phx-icon-primary-indicateur-w-CTIME-GTMED ui-phx-icon-indicateurs-hebdo " + coleurBackgroundClass);
      } else {
        $divIndicateur.addClass("ui-phx-icon-primary-indicateur-w ui-phx-icon-indicateurs-hebdo " + coleurBackgroundClass);
      }
      $divIndicateur.addClass(coleurBorderClass + "_border");
      $divIndicateur.css("border", "2px solid rgb(0,0,0)");
      $divIndicateur.css("display", "inline-block");
    }
    $animationContainer.append($divIndicateur);
    if (animate) {
      if ($('html').is('.ie9')) { //ie9 does not support animation by css so we have to use jquery animation
        CWRENDERAGENTS._animate($divIndicateur, view, $animationContainer);
      } else {
        $divIndicateur.addClass("animate");
      }
    }
    return $animationContainer;
  }

  /**
   * Function that generate the primaire indicateur in planning (configurable). In agenda and edt, this method is not used (current indicateurs keeps being used)
   * */
  static renderIndicateurSecondaire(view: string, coleurBorderClass: string, coleurBackgroundClass: string, animate: boolean, aModeNormal?: boolean): JQuery {
    //a_modeNormal utilisé en core/views/cwSecondaireButton.view
    const $divIndicateur = $("<div>");

    if (view === "M") {
      if (aModeNormal === true || !LICENCE.hasModule("GTM")) {
        $divIndicateur.addClass("ui-phx-icon-secondaire-indicateur-m " + coleurBackgroundClass);
      } else {
        $divIndicateur.addClass("ui-phx-icon-secondaire-indicateur-m-CTIME-GTMED " + coleurBackgroundClass);
      }
      $divIndicateur.addClass(coleurBorderClass + "_border");
      $divIndicateur.css("border", "1px solid rgb(0,0,0)");
    } else if (view === "S") {
      if (aModeNormal === true || !LICENCE.hasModule("GTM")) {
        $divIndicateur.addClass("ui-phx-icon-secondaire-indicateur-w " + coleurBackgroundClass);
      } else {
        $divIndicateur.addClass("ui-phx-icon-secondaire-indicateur-w-CTIME-GTMED " + coleurBackgroundClass);
      }
      $divIndicateur.addClass(coleurBorderClass + "_border");
      $divIndicateur.css("border", "2px solid rgb(0,0,0)");
    }
    if (animate) {
      if ($('html').is('.ie9')) { //ie9 does not support animation by css so we have to use jquery animation
        CWRENDERAGENTS._animateRotation($divIndicateur, view);
      } else {
        $divIndicateur.addClass("animationSecondaire");
      }
    }
    return $divIndicateur;
  }

  static renderIndicateurPretsAffSec(view: string, coleurBorderClass: string, coleurBackgroundClass: string, animate: boolean): JQuery {
    const $divIndicateur = $("<div>");
    const $divEmpty = $("<div class='empty'>")
    let colBack = "", colBord = "";
    if (!CWSTR.isBlank(coleurBackgroundClass)) {
      colBack = coleurBackgroundClass.startsWith("rgb") ? coleurBackgroundClass : CWRENDERAGENTS._getColorFromClass(coleurBackgroundClass);
    }
    if (!CWSTR.isBlank(coleurBorderClass)) {
      colBord = coleurBorderClass.startsWith("rgb") ? coleurBorderClass : CWRENDERAGENTS._getColorFromClass(coleurBorderClass);
    }
    //aModeNormal utilisé en core/views/cwSecondaireButton.view
    $divIndicateur.append($divEmpty);
    if (view === "M") {
      $divIndicateur.addClass("ui-phx-icon-pretaffsec-indicateur-m ");
      $divEmpty.css({ "border-bottom-color": colBack });
      $divIndicateur.css({ "border-bottom-color": colBord });
    } else if (view === "S") {
      $divIndicateur.addClass("ui-phx-icon-pretaffsec-indicateur-w ");
      $divEmpty.css({ "border-bottom-color": colBack });
      $divIndicateur.css({ "border-bottom-color": colBord });
    } else if (view === "SC") {
      $divIndicateur.addClass("ui-phx-icon-pretaffsec-indicateur-w cell");
      $divEmpty.css({ "border-bottom-color": colBack });
      $divIndicateur.css({ "border-bottom-color": colBord });
    } else if (view === "SCMED") {
      $divIndicateur.addClass("ui-phx-icon-pretaffsec-indicateur-w med");
      $divEmpty.css({ "border-bottom-color": colBack });
      $divIndicateur.css({ "border-bottom-color": colBord });
    }
    if (animate) {
      if ($('html').is('.ie9')) { //ie9 does not support animation by css so we have to use jquery animation
        CWRENDERAGENTS._animateRotation($divIndicateur, view);
      } else {
        if (view === "SCMED" || view === "M") {
          $divIndicateur.addClass("animationPretsAffSecScaled");
        } else {
          $divIndicateur.addClass("animationPretsAffSec");
        }
      }
    }
    return $divIndicateur;
  }

  /**
   * Function that generate the indicateur in planning (simulation) to see changements from real planning.
   * */
  static renderIndicateurSimulation(view: string, coleurBorderClass: string, coleurBackgroundClass: string, animate: boolean): JQuery {
    const $divIndicateur = $("<div>");

    if (view === "M") {
      $divIndicateur.addClass("ui-phx-icon-secondaire-indicateur-m spec1 " + coleurBackgroundClass);
      $divIndicateur.css("border", "1px solid rgb(0,0,0)");
    } else if (view === "S") {
      $divIndicateur.addClass("ui-phx-icon-secondaire-indicateur-w spec1 " + coleurBackgroundClass);
      $divIndicateur.css("border", "2px solid rgb(0,0,0)");
    }
    if (!CWSTR.isBlank(coleurBackgroundClass)) {
      $divIndicateur.addClass(coleurBackgroundClass);
    }
    if (!CWSTR.isBlank(coleurBorderClass)) {
      $divIndicateur.addClass(coleurBorderClass + "_border");
      $divIndicateur.css("border", "2px solid rgb(0,0,0)");
    }
    if (animate) {
      if ($('html').is('.ie9')) { //ie9 does not support animation by css so we have to use jquery animation
        CWRENDERAGENTS._animateRotation($divIndicateur, view);
      } else {
        $divIndicateur.addClass("animate");
      }
    }
    return $divIndicateur;
  }

  /**
   * Function to get color from a class that defines background color
   */
  static _getColorFromClass(backgroundColorClass: string): string {
    let colorRGB = "";

    if (!CWSTR.isBlank(backgroundColorClass)) {
      CWRENDERAGENTS.posClonedElements.addClass(backgroundColorClass);
      colorRGB = CWRENDERAGENTS.posClonedElements.css("background-color");
      CWRENDERAGENTS.posClonedElements.removeClass(backgroundColorClass);
    }
    return colorRGB;
  }

  /**
   * Function to create animation in ie9
   */
  static _animate($div: JQuery, view: string, $parentDiv: JQuery): void {
    let totalWidth = "11px";
    let halfWidth = "7px";
    let parentWidth = "17px";

    if (view === "M") {
      totalWidth = "6px";
      halfWidth = "3px";
      parentWidth = "12px";
    }
    $div.css("margin", "auto");
    $parentDiv.css("width", parentWidth);
    $parentDiv.css("margin-bottom", "2px");
    $parentDiv.css("display", "inline-block");
    $div.animate({ 'width': totalWidth }, 1000, () => {
      $div.animate({ 'width': halfWidth }, 500, () => {
        $div.animate({ 'width': '0px' }, 500, () => {
          CWRENDERAGENTS._animate($div, view, $parentDiv);
        });
      });
    });
  }

  /**
   * Function to create rotate animation in ie9
   */
  static _animateRotation($div: JQuery, view: string): void {
    const angle = 135;

    $({ deg: 45 }).animate({ deg: angle }, {
      duration: 1500,
      step: (now: number) => {
        $div.css({
          transform: 'rotate(' + now + 'deg)'
        });
      },
      complete: () => {
        setTimeout(() => {
          CWRENDERAGENTS._animateRotation($div, view);
        }, 4000);
      }
    });
  }

  /**
   * Function to check if indicateur primaire is going to be shown or not
   */
  static _checkIndicateurPrimaire(indToRender: { [key: string]: any }, typeIndSecon: number, indicSecondaire: string, indicateurs: { [key: string]: any }, activitiesFilters: Backbone.Collection, viewMode: string): boolean {
    let primaire = true;

    if (CWRENDERAGENTS._checkIndicateurSecondaire(indToRender, typeIndSecon, indicSecondaire, indicateurs, activitiesFilters, viewMode)) {
      let numberOfIndicateurs = 0;

      //There is going to be shown an indicateur secondaire
      if (indToRender.val) {
        numberOfIndicateurs++;
      }
      if (indToRender.valb) {
        numberOfIndicateurs++;
      }
      if (indToRender.abs) {
        numberOfIndicateurs++;
      }
      if (indToRender.reg) {
        numberOfIndicateurs++;
      }
      if (indToRender.bad) {
        numberOfIndicateurs++;
      }
      if (indToRender.astreg) {
        numberOfIndicateurs++;
      }
      if (indToRender.mem) {
        numberOfIndicateurs++;
      }
      if (indToRender.mes) {
        numberOfIndicateurs++;
      }
      if (indToRender.rptn) {
        numberOfIndicateurs++;
      }
      if (indToRender.rpen) {
        numberOfIndicateurs++;
      }
      if (indToRender.pret) {
        numberOfIndicateurs++;
      }
      if (indToRender.prpn) {
        numberOfIndicateurs++;
      }
      if (indToRender.pren) {
        numberOfIndicateurs++;
      }
      if (indToRender.affsec) {
        numberOfIndicateurs++;
      }
      if (numberOfIndicateurs < 2) {
        const result = CWRENDERAGENTS._checkInfoTypologies(indicSecondaire, indicateurs, activitiesFilters, viewMode);

        //When numberOfIndicateurs>= 2 it is not necessary to check typologies, so we don't do it, in order to optimize behaviour
        //Typologies d'activite
        numberOfIndicateurs = numberOfIndicateurs + result.numberOfIndicateurs;
      }
      if (numberOfIndicateurs <= 1) {
        //If there is only 1 indicateur to be shown and it is an indicateur secondaire,
        //don't show indicateur primaire
        primaire = false;
      }
    }
    return primaire;
  }

  /* Function to check if indicateur secondaire is going to be shown or not
   * @param indToRender - Object with the flags of which indicateur to render
   * @param typeIndSecon - 0 aucun 1 indicateur, 2 typologie
   * @param indicSecondaire - Code of the indicateur secondaire
   * @indicateurs - All info of Indicateurs recived in WS
   * @activitiesFilters - Info about how are configured the activities in filtres
   */
  static _checkIndicateurSecondaire(indToRender: { [key: string]: any }, typeIndSecon: number, indicSecondaire: string, indicateurs: { [key: string]: any }, activitiesFilters: Backbone.Collection, viewMode: string): boolean {
    let secondaire = false;

    if (typeIndSecon === 1) { //Secondaire type=indicator (not typologie)
      if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "deval", indicateurs) ||
        CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "messages", indicateurs) ||
        CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "regul", indicateurs) ||
        CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "badgeage", indicateurs) ||
        CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "abscomp", indicateurs) ||
        CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "pret", indicateurs) ||
        CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "remplacements", indicateurs)) {
        secondaire = true;
      }
    } else if (typeIndSecon === 2) { //Secondaire type=typologie (not typologie)
      if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "act", indicateurs, activitiesFilters, viewMode)) {
        secondaire = true;
      }
    }
    return secondaire;
  }

  /* Function to check if the indicateur secondaire is set for a type
   * @param indToRender - Object with the flags of which indicateur to render
   * @param indicSecondaire - Code of the indicateur secondaire
   * @param type - type of indicator (act if activites, otherwise empty)
   * @indicateurs - All info of Indicateurs recived in WS
   * @activitiesFilters - Info about how are configured the activities in filtres
   */
  static _checkIndicateurSecondaryOfType(indToRender: { [key: string]: any }, indicSecondaire: string, type: string, indicateurs: { [key: string]: any }, activitiesFilters?: Backbone.Collection, viewMode?: string): boolean {
    let secondaire = false;

    switch (type) {
      case "deval":
        if ((indToRender.val || indToRender.valb) && indicSecondaire === "deval" && indicateurs.anod && indicateurs.anod.length > 0) {
          secondaire = true;
        }
        break;
      case "messages":
        if (indToRender.mes && indicSecondaire === "messages" && indicateurs.mesd && indicateurs.mesd.length > 0) {
          secondaire = true;
        }
        break;
      case "regul":
        if (((indToRender.reg || indToRender.regav) && indicSecondaire === "regul") && (indicateurs.regd && indicateurs.regd.length > 0)) {
          secondaire = true;
        }
        break;
      case "badgeage":
        if (indToRender.bad && indicSecondaire === "badgeage" && indicateurs.badd && indicateurs.badd.length > 0) {
          secondaire = true;
        }
        break;
      case "abscomp":
        if (((indToRender.abs || indToRender.absv || indToRender.absav || indToRender.souv || indToRender.souav) && indicSecondaire === "abscomp") &&
          (indicateurs.absd && indicateurs.absd.length > 0)) {
          secondaire = true;
        }
        break;
      case "pret":
        if (indToRender.pret && indicSecondaire === "pret" && indicateurs.pretd) {
          secondaire = true;
        }
        break;
      case "remplacements":
        if ((indToRender.rptn || indToRender.rpen || indToRender.pren || indToRender.prpn || indToRender.repla) &&
          indicSecondaire === "remplacements" && indicateurs.prtd && indicateurs.prtd.length > 0) {
          secondaire = true;
        }
        break;
      case "affSecond":
        if (indToRender.affsec && indicSecondaire === "affSecond" && indicateurs.affsec && indicateurs.affsec.length > 0) {
          secondaire = true;
        }
        break;
      case "act": //Check if typologie is present or not
        if (indicateurs.typo && indicateurs.typo.length > 0) {
          const result = CWRENDERAGENTS._checkInfoTypologies(indicSecondaire, indicateurs, activitiesFilters, viewMode);

          if (result.secondaire) {
            secondaire = true;
          }
        }
        break;
      default:
    }
    return secondaire;
  }

  /**
   * Gets the indicateur key associated to indicateur  secondaire
   */
  static _getSecondaireIndicateur(typeInd: number, indSecondaire: string): { [key: string]: any } {
    const indToRender: { [key: string]: any } = {};

    //		deval : valorisations
    //		abscomp : absences complémentaires (absences, absences validées, souhaits validés, absences à valider, souhaits à valider)
    //	    regul : régularisations (régularisations, régularisations à valider)
    //		messages : messages
    //		badge : badgeages
    //		act : Activités & Astreintes
    //      pret: Dispo
    //		remplacements: Prets
    //		8 : régularisations Astreintes?

    if (typeInd === 1) {
      switch (indSecondaire) {
        case "deval":
          indToRender.val = true;
          break;
        case "messages":
          indToRender.mes = true;
          break;
        case "regul":
          indToRender.reg = true;
          break;
        case "badgeage":
          indToRender.bad = true;
          break;
        case "abscomp":
          indToRender.abs = true;
          break;
        case "pret":
          indToRender.pret = true;
          break;
        case "remplacements":
          indToRender.rptn = true;
          break;
        case "affsecond":
          indToRender.affsec = true;
          break;
        default:
      }
    } else if (typeInd === 2) {
      indToRender.act = true;
    }
    return indToRender;
  }

  /**
   * Checks if typologie selected for secondaire indicateur is one of the typologies shownj in the current cell
   */
  static _checkInfoTypologies(indicSecondaire: string, indicateurs: { [key: string]: any }, activitiesFilters: Backbone.Collection, viewMode: string): { [key: string]: any } {
    const result: any = { "secondaire": false, "numberOfIndicateurs": 0 }

    if (indicateurs.ap) {
      const keys = _.keys(indicateurs.ap);
      const size = keys.length;

      for (let i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]) && activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          result.numberOfIndicateurs++;
          if (indicSecondaire === keys[i]) {
            result.secondaire = true;
          }
        }
      }
    }
    if (indicateurs.ar) {
      const keys = _.keys(indicateurs.ar);
      const size = keys.length;

      for (let i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]) && activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          result.numberOfIndicateurs++;
          if (indicSecondaire === keys[i]) {
            result.secondaire = true;
          }
        }
      }
    }
    if (indicateurs.apz) {
      const keys = _.keys(indicateurs.apz);
      const size = keys.length;

      for (let i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]) && activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("prevu") === true) {
          result.numberOfIndicateurs++;
          if (indicSecondaire === keys[i]) {
            result.secondaire = true;
          }
        }
      }
    }
    if (indicateurs.arz) {
      const keys = _.keys(indicateurs.arz);
      const size = keys.length;

      for (let i = 0; i < size; i++) {
        if (activitiesFilters.get(keys[i]) && activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
          activitiesFilters.get(keys[i]).get("selected") === true &&
          activitiesFilters.get(keys[i]).get("realise") === true) {
          result.numberOfIndicateurs++;
          if (indicSecondaire === keys[i]) {
            result.secondaire = true;
          }
        }
      }
    }
    return result;
  }

  static _renderIndicateursTooltip(indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, viewEl: JQuery, options: { [key: string]: any }): void {
    let context = null,
      icons = null,
      activitiesFilters = null,
      viewMode = "s",
      edt = false;
    let typologiesConfig = null;
    let keys = null;
    let size = null;
    let i = null;
    let genre = "";
    let typology = null;
    let typoCode = null;
    let titleContent = "";
    const user = model.user;
    const nom = (user?.nom) ? user.nom.toUpperCase() : "";

    if (options) {
      if (options.context) {
        context = options.context;
      }
      if (options.icons) {
        icons = options.icons;
      }
      if (options.activitiesFilters) {
        activitiesFilters = options.activitiesFilters;
      }
      if (options.viewMode) {
        viewMode = options.viewMode;
      }
      if (options.typologiesConfig) {
        typologiesConfig = options.typologiesConfig;
      }
      if (_.isBoolean(options.edt)) {
        edt = options.edt;
      }
    }
    //Tooltip - only when indicateur
    /*
        <b>NOM Prénom</b>, le vendredi <b>31 mai 2013</b>
        2 anomalies(s)
        3 régularisation(s)
        1 absence(s) complémentaire(s)
      */
    if (edt !== true) {
      // review behaviour of date for edt (currently is not being sent)
      const date = CWTYPE.DATE.format(model.dat, "DD Month YYYY");
      const day = CWTYPE.DATE.strToDate(model.dat).getDay();
      const lang = UTILS.getLanguage();
      let leLang = ", ";

      if (lang === "fr") {
        leLang = ", le ";
      }
      titleContent = "<b>" + nom + " " + user?.pre + "</b>" + leLang + i18n.t('common:dayNames.' + day) + " <b>" + date + "</b><br>";
    } else {
      titleContent = "<b>" + nom + " " + user?.pre + "</b>" + indicateurs.dateLabel;
    }
    if (CWSTR.isBlank(user?.pre)) {
      titleContent = "";
    }
    titleContent += "<ul style='padding-left : 1em;' class='" + context + "'>";
    if (indToRender.val) {
      titleContent += "<li>" + (icons ? icons.val : "") + indicateurs.val + " " + i18n.t('plans.planresp.tooltip_val') + "</li>";
    }
    if (indToRender.valb) {
      titleContent += "<li>" + (icons ? icons.valb : "") + indicateurs.valb + " " + i18n.t('plans.planresp.tooltip_val') + "</li>";
    }
    if (indToRender.abs) {
      titleContent += "<li>" + (icons ? icons.abs : "") + indicateurs.abs + " " + i18n.t('plans.planresp.tooltip_abs') + "</li>";
    }
    if (indToRender.reg) {
      titleContent += "<li>" + (icons ? icons.reg : "") + indicateurs.reg + " " + i18n.t('plans.planresp.tooltip_reg') + "</li>";
    }
    if (indToRender.astreg) {
      if (CWDIVERS.get("AstRegul") === "1") {
        const textAstRegulCh = CWDIVERS.get("AstRegulCh");

        titleContent += "<li>" + (icons ? icons.astreg : "") + indicateurs.astreg + " " + i18n.t('plans.planresp.tooltip_regast_AstRegulCh') + textAstRegulCh + "</li>";
      } else {
        titleContent += "<li>" + (icons ? icons.astreg : "") + indicateurs.astreg + " " + i18n.t('plans.planresp.tooltip_regast') + "</li>";
      }
    }
    if (indToRender.mem) {
      titleContent += "<li>" + (icons ? icons.mem : "") + indicateurs.mem + " " + i18n.t('plans.planresp.tooltip_mem') + "</li>";
    }
    if (indToRender.mes) {
      titleContent += "<li>" + (icons ? icons.mes : "") + indicateurs.mes + " " + i18n.t('plans.planresp.tooltip_mes') + "</li>";
    }
    if (indToRender.bad) {
      titleContent += "<li>" + (icons ? icons.bad : "") + indicateurs.bad + " " + i18n.t('plans.planresp.tooltip_bad') + "</li>";
    }
    //icons
    if (indToRender.rptn) {
      titleContent += "<li>" + (icons ? icons.val : "") + indicateurs.rptn + " " + i18n.t('plans.planresp.tooltip_reppla') + ": " + i18n.t('messages:GT_1850') + "</li>";
    }
    if (indToRender.rpen) {
      titleContent += "<li>" + (icons ? icons.val : "") + indicateurs.rpen + " " + i18n.t('plans.planresp.tooltip_reppla') + ": " + i18n.t('messages:GT_1851') + "</li>";
    }
    if (indToRender.pren) {
      titleContent += "<li>" + (icons ? icons.val : "") + indicateurs.pren + " " + i18n.t('plans.planresp.tooltip_empr') + "</li>";
    }
    if (indToRender.prpn) {
      titleContent += "<li>" + (icons ? icons.val : "") + indicateurs.prpn + " " + i18n.t('plans.planresp.tooltip_pret') + "</li>";
    }
    if (indToRender.act) {
      if (indicateurs.ap) {
        keys = _.keys(indicateurs.ap);
        size = keys.length;
        for (i = 0; i < size; i++) {
          if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
            activitiesFilters.get(keys[i]).get("selected") === true &&
            activitiesFilters.get(keys[i]).get("prevu") === true) {
            typology = GLOBAL_DATA.typologies.get(keys[i]);
            typoCode = typology.get("code");
            if (typologiesConfig[typoCode]) {
              if (typologiesConfig[typoCode].get("actprev") === true) {
                if (typologiesConfig[typoCode].get("actreal") === false) {
                  //CalqActUniq = vrai
                  if (indicateurs.ap[keys[i]] === 1) {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": typology.get("evenement").toLowerCase() }) + "</li>";
                  } else {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                      "1": indicateurs.ap[keys[i]],
                      "2": typology.get("regroupement").toLowerCase()
                    }) + "</li>";
                  }
                } else {
                  //CalqActUniq = faux
                  genre = "";
                  if (indicateurs.ap[keys[i]] === 1) {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1571');
                    } else {
                      genre = i18n.t('messages:GT_1573');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_106', {
                      "1": typology.get("evenement").toLowerCase(),
                      "2": genre
                    }) + "</li>";
                  } else {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1572');
                    } else {
                      genre = i18n.t('messages:GT_1574');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_90', {
                      "1": indicateurs.ap[keys[i]],
                      "2": typology.get("regroupement").toLowerCase(),
                      "3": genre
                    }) + "</li>";
                  }
                }
              } else {
                //Nothing
              }
            }
          }
        }
        if (size === 0 && _.isNumber(indicateurs.ap) && indicateurs.ap > 0) {
          const lAp = indicateurs.ap;

          if (lAp > 0) {
            const lCadAp = ((lAp === 1) ? i18n.t("common:activite") + i18n.t('messages:GT_1573') : i18n.t("common:activites") + i18n.t('messages:GT_1574'));

            if (lAp === 1) {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": lCadAp }) + "</li>";
            } else {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                "1": lAp,
                "2": lCadAp
              }) + "</li>";
            }
          }
        }
      }
      if (indicateurs.ar) {
        keys = _.keys(indicateurs.ar);
        size = keys.length;
        for (i = 0; i < size; i++) {
          if (activitiesFilters.get(keys[i]).get("indcomplementaire") === true &&
            activitiesFilters.get(keys[i]).get("selected") === true &&
            activitiesFilters.get(keys[i]).get("realise") === true) {
            typology = GLOBAL_DATA.typologies.get(keys[i]);
            typoCode = typology.get("code");
            if (typologiesConfig[typoCode]) {
              if (typologiesConfig[typoCode].get("actreal") === true) {
                if (typologiesConfig[typoCode].get("actprev") === false) {
                  //CalqActUniq = vrai
                  if (indicateurs.ar[keys[i]] === 1) {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": typology.get("evenement").toLowerCase() }) + "</li>";
                  } else {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                      "1": indicateurs.ar[keys[i]],
                      "2": typology.get("regroupement").toLowerCase()
                    }) + "</li>";
                  }
                } else {
                  //CalqActUniq = faux
                  genre = "";
                  if (indicateurs.ar[keys[i]] === 1) {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1575');
                    } else {
                      genre = i18n.t('messages:GT_1577');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_106', {
                      "1": typology.get("evenement").toLowerCase(),
                      "2": genre
                    }) + "</li>";
                  } else {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1576');
                    } else {
                      genre = i18n.t('messages:GT_1578');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_90', {
                      "1": indicateurs.ar[keys[i]],
                      "2": typology.get("regroupement").toLowerCase(),
                      "3": genre
                    }) + "</li>";
                  }
                }
              } else {
                //Nothing
              }
            }
          }
        }
        if (size === 0 && _.isNumber(indicateurs.ar) && indicateurs.ar > 0) {
          const lAr = indicateurs.ar;

          if (lAr > 0) {
            const lCadAr = ((lAr === 1) ? i18n.t("common:activite") + i18n.t('messages:GT_1577') : i18n.t("common:activites") + i18n.t('messages:GT_1578'));

            if (lAr === 1) {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": lCadAr }) + "</li>";
            } else {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                "1": lAr,
                "2": lCadAr
              }) + "</li>";
            }
          }
        }
      }
      if (indicateurs.apz) {
        keys = _.keys(indicateurs.apz);
        size = keys.length;
        for (i = 0; i < size; i++) {
          if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
            activitiesFilters.get(keys[i]).get("selected") === true &&
            activitiesFilters.get(keys[i]).get("prevu") === true) {
            typology = GLOBAL_DATA.typologies.get(keys[i]);
            typoCode = typology.get("code");
            if (typologiesConfig[typoCode]) {
              if (typologiesConfig[typoCode].get("actprev") === true) {
                if (typologiesConfig[typoCode].get("actreal") === false) {
                  //CalqActUniq = vrai
                  if (indicateurs.apz[keys[i]] === 1) {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": typology.get("evenement").toLowerCase() }) + "</li>";
                  } else {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                      "1": indicateurs.apz[keys[i]],
                      "2": typology.get("regroupement").toLowerCase()
                    }) + "</li>";
                  }
                } else {
                  //CalqActUniq = faux
                  genre = "";
                  if (indicateurs.apz[keys[i]] === 1) {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1571');
                    } else {
                      genre = i18n.t('messages:GT_1573');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_106', {
                      "1": typology.get("evenement").toLowerCase(),
                      "2": genre
                    }) + "</li>";
                  } else {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1572');
                    } else {
                      genre = i18n.t('messages:GT_1574');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_90', {
                      "1": indicateurs.apz[keys[i]],
                      "2": typology.get("regroupement").toLowerCase(),
                      "3": genre
                    }) + "</li>";
                  }
                }
              } else {
                //Nothing
              }
            }
          }
        }
        if (size === 0 && _.isNumber(indicateurs.apz) && indicateurs.apz > 0) {
          const lApz = indicateurs.apz;

          if (lApz > 0) {
            const lCadApz = ((lApz === 1) ? i18n.t("common:activite") + i18n.t('messages:GT_1573') : i18n.t("common:activites") + i18n.t('messages:GT_1574'));

            if (lApz === 1) {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": lCadApz }) + "</li>";
            } else {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                "1": lApz,
                "2": lCadApz
              }) + "</li>";
            }
          }
        }
      }
      if (indicateurs.arz) {
        keys = _.keys(indicateurs.arz);
        size = keys.length;
        for (i = 0; i < size; i++) {
          if (activitiesFilters.get(keys[i]).get("indzoom" + viewMode) === true &&
            activitiesFilters.get(keys[i]).get("selected") === true &&
            activitiesFilters.get(keys[i]).get("realise") === true) {
            typology = GLOBAL_DATA.typologies.get(keys[i]);
            typoCode = typology.get("code");
            if (typologiesConfig[typoCode]) {
              if (typologiesConfig[typoCode].get("actreal") === true) {
                if (typologiesConfig[typoCode].get("actprev") === false) {
                  //CalqActUniq = vrai
                  if (indicateurs.arz[keys[i]] === 1) {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": typology.get("evenement").toLowerCase() }) + "</li>";
                  } else {
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                      "1": indicateurs.arz[keys[i]],
                      "2": typology.get("regroupement").toLowerCase()
                    }) + "</li>";
                  }
                } else {
                  //CalqActUniq = faux
                  genre = "";
                  if (indicateurs.arz[keys[i]] === 1) {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1575');
                    } else {
                      genre = i18n.t('messages:GT_1577');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_106', {
                      "1": typology.get("evenement").toLowerCase(),
                      "2": genre
                    }) + "</li>";
                  } else {
                    if (typology.get("genre") === "M") {
                      genre = i18n.t('messages:GT_1576');
                    } else {
                      genre = i18n.t('messages:GT_1578');
                    }
                    titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_90', {
                      "1": indicateurs.arz[keys[i]],
                      "2": typology.get("regroupement").toLowerCase(),
                      "3": genre
                    }) + "</li>";
                  }
                }
              } else {
                //Nothing
              }
            }
          }
        }
        if (size === 0 && _.isNumber(indicateurs.arz) && indicateurs.arz > 0) {
          const lArz = indicateurs.arz;

          if (lArz > 0) {
            const lCadArz = ((lArz === 1) ? i18n.t("common:activite") + i18n.t('messages:GT_1577') : i18n.t("common:activites") + i18n.t('messages:GT_1578'));

            if (lArz === 1) {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_105', { "1": lCadArz }) + "</li>";
            } else {
              titleContent += "<li>" + (icons ? icons.act : "") + i18n.t('messages:PLH_89', {
                "1": lArz,
                "2": lCadArz
              }) + "</li>";
            }
          }
        }
      }
    }
    if (indToRender.pret) {
      titleContent += "<li>" + (icons && icons.pret ? icons.pret : "") + indicateurs.pret + " " + i18n.t('plans.planresp.tooltip_pret') + "</li>";
    }
    titleContent += '</ul>';
    viewEl.prop("title", titleContent);
  }

  /**
   * New method to render indicateur tooltip. Now we have more info and can paint a more detailed infobulle
   */
  static _renderIndicateursNewTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, viewEl: JQuery, options: { [key: string]: any }): void {
    let icons = null,
      viewMode = "S",
      indPrimaire = false,
      indSecondaireFlag = false,
      indPretsAffSec = false,
      typeIndSecon = 0,
      indicSecondaire = "",
      princBackClass = "",
      princBordClass = "",
      secBackClass = "",
      pretsAffSecBackClass = "",
      pretsAffSecBordClass = "",
      secBordClass = "";
    let leLang = ", ";
    const $titleContent = $("<div>");
    const user = model.user;
    const nom = (user?.nom) ? user.nom.toUpperCase() : "";
    const lang = UTILS.getLanguage();
    const date = CWTYPE.DATE.format(model.dat, "DD Month YYYY");
    const day = CWTYPE.DATE.strToDate(model.dat).getDay();
    const $secondLineIndicContainer = $("<span>").addClass("phx-tooltip-second-line-container");
    let $firstLine = null;
    let $secondLine = null;
    const $evContent = $("<table>");

    if (options) {
      if (options.icons) {
        icons = options.icons;
      }
      if (options.viewMode) {
        viewMode = options.viewMode;
      }
      if (_.isBoolean(options.indSecondaireFlag)) {
        indSecondaireFlag = options.indSecondaireFlag;
      }
      if (options.typeIndSecon) {
        typeIndSecon = options.typeIndSecon;
      }
      if (_.isBoolean(options.indPrimaire)) {
        indPrimaire = options.indPrimaire;
      }
      if (options.indicSecondaire) {
        indicSecondaire = options.indicSecondaire;
      }
      if (options.indPretsAffSec) {
        indPretsAffSec = options.indPretsAffSec;
      }
      if (options.princBackClass) {
        princBackClass = options.princBackClass;
      }
      if (options.princBordClass) {
        princBordClass = options.princBordClass;
      }
      if (options.secBackClass) {
        secBackClass = options.secBackClass;
      }
      if (options.secBordClass) {
        secBordClass = options.secBordClass;
      }
      if (options.pretsAffSecBackClass) {
        pretsAffSecBackClass = options.pretsAffSecBackClass;
      }
      if (options.pretsAffSecBordClass) {
        pretsAffSecBordClass = options.pretsAffSecBordClass;
      }
    }
    //Tooltip - only when indicateur
    /*
        <b>NOM Prénom</b>, le vendredi <b>31 mai 2013</b>
        2 anomalies(s)
        3 régularisation(s)
        1 absence(s) complémentaire(s)
      */
    if (lang === "fr") {
      leLang = ", le ";
    }
    $firstLine = $("<span class='ui-phx-ihm-texte-donnees-important'>" + nom + " " + user?.pre + "</span>" + leLang + i18n.t('common:dayNames.' + day) + " <span class='ui-phx-ihm-texte-donnees-important'>" + date + "</span><br>");
    $secondLine = $("<span class='ui-phx-ihm-texte-application'><span class='phx-tooltip-second-line-text-container'>" + i18n.t('common:infoIndicateurs') + "</span></span>").css("display", "inline-block");
    $secondLine.find(".phx-tooltip-second-line-text-container").css({ "vertical-align": "middle", "margin-top": "2px" });
    if (icons && icons.mainIcon) {
      $secondLine.append(icons.mainIcon);
    } else if (indPrimaire === true) {
      const $lIndPrim = CWRENDERAGENTS.renderIndicateurPrimaire(viewMode, princBordClass, princBackClass, false).addClass("ui-phx-icon-in-tooltip");

      $secondLineIndicContainer.append($lIndPrim);
      $secondLine.append($secondLineIndicContainer);
    } else if (indSecondaireFlag === true) {
      const $lIndsecond = CWRENDERAGENTS.renderIndicateurSecondaire(viewMode, secBordClass, secBackClass, false).addClass("ui-phx-icon-in-tooltip");

      $secondLineIndicContainer.append($lIndsecond);
      $secondLine.append($secondLineIndicContainer);
    } else if (indPretsAffSec === true) {
      const $lIndPretAffSec = CWRENDERAGENTS.renderIndicateurPretsAffSec(viewMode, pretsAffSecBordClass, pretsAffSecBackClass, false).addClass("ui-phx-pretaffsec-icon-in-tooltip");

      if (options.lbIsTabServInd === false) {
        $firstLine = $("<span class='ui-phx-ihm-texte-donnees-important'>" + user?.nom + " " + user?.pre + "</span>" + leLang + i18n.t('common:dayNames.' + day) + " <span class='ui-phx-ihm-texte-donnees-important'>" + date + "</span><br>");
      } else {
        $firstLine = "";
      }

      $secondLineIndicContainer.append($lIndPretAffSec);
      $secondLine.append($secondLineIndicContainer);
    }
    //Badgeages
    if (indToRender.bad) {
      const $badgContent = CWRENDERAGENTS._renderBadgeagesTooltip(indToRender, pk, model, indicateurs, options);

      $evContent.append($badgContent);
    }
    //Regularisations
    if (indToRender.reg) {
      const $regContent = CWRENDERAGENTS._renderRegulsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      $evContent.append($regContent);
    }
    //Absences complémentaires,
    if (indToRender.abs) {
      //Shouhaits
      if (indicateurs.soud && indicateurs.soud.length > 0) {
        const $souhContent = CWRENDERAGENTS._renderSouhTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        $evContent.append($souhContent);
      }
      //Absences
      if (indicateurs.absd && indicateurs.absd.length > 0) {
        const $absContent = CWRENDERAGENTS._renderAbsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        $evContent.append($absContent);
      }
    }
    //Activités regroupées dans un bloc par typologie et types d’activité «prévues»/«réalisées» (voir ci-après)
    if (indToRender.act) {
      let addTypoRealVal = false;
      let addTypoPrevVal = false;

      for (let i = 0; Array.isArray(indicateurs.typo) && i < indicateurs.typo.length; i++) {
        const typologie: { [key: string]: any } = indicateurs.typo[i];
        const index: number = i;

        if (!_.isNull(typologie.real) && _.isArray(typologie.real) && typologie.real.length > 0) {
          for (let j = 0; j < typologie.real.length; j++) {
            const real: { [key: string]: any } = typologie.real[j];

            if (real.eta === "D" || real.eta === "I" || real.eta === "T") {
              let currentData = viewEl.data("act-typos");

              addTypoRealVal = true;
              if (CWSTR.isBlank(currentData)) {
                currentData = [];
              }
              currentData.push(typologie.cod);
              viewEl.data("act-typos", currentData);
            }
            if (real.ext === false) {
              viewEl.addClass("phx-cell-actExterieuresInd");
            }
          }
        }
        if (!_.isNull(typologie.prev) && _.isArray(typologie.prev) && typologie.prev.length > 0) {
          for (let k = 0; k < typologie.prev.length; k++) {
            const prev: { [key: string]: any } = typologie.prev[k];

            if (prev.eta === "D" || prev.eta === "I" || prev.eta === "T") {
              let currentData = viewEl.data("act-typos");

              addTypoPrevVal = true;
              if (CWSTR.isBlank(currentData)) {
                currentData = [];
              }
              currentData.push(typologie.cod);
              viewEl.data("act-typos", currentData);
            }
            if (prev.ext === false) {
              viewEl.addClass("phx-cell-actExterieuresInd");
            }
          }
        }
        if (indSecondaireFlag !== true) { //We are painting indicateur primaire
          if (!CWRENDERAGENTS._isTypologieSecondaire(typeIndSecon, indicSecondaire, typologie)) { //Don't paint for indic primaire the typologie selected for indic secondaire
            const $actContent = CWRENDERAGENTS._renderActTooltip(dtsModel, typologie, index, indToRender, pk, model, indicateurs, options);

            if (addTypoRealVal === true) {
              viewEl.addClass("phx-cell-act-rel-ind-val");
            }
            if (addTypoPrevVal === true) {
              viewEl.addClass("phx-cell-act-prev-ind-val");
            }
            $evContent.append($actContent);
          }
        } else { //We are painting tooltip indicateur secondaire
          if (CWRENDERAGENTS._isTypologieSecondaire(typeIndSecon, indicSecondaire, typologie)) { //Only paint the typologie selected for indicateur secondaire
            const $actContent = CWRENDERAGENTS._renderActTooltip(dtsModel, typologie, index, indToRender, pk, model, indicateurs, options);

            if (addTypoRealVal === true) {
              viewEl.addClass("phx-cell-act-rel-ind-val");
            }
            if (addTypoPrevVal === true) {
              viewEl.addClass("phx-cell-act-prev-ind-val");
            }
            $evContent.append($actContent);
          }
        }
      }
    }
    //Prêts ou emprunts avec ou sans remplacement
    if (indToRender.rptn || indToRender.rpen || indToRender.pren || indToRender.prpn || indToRender.repla) {
      //repla is not sent by metier, is a local indicator for vue zoom journee that there is some indicateur of pret or remplacement for this day
      const $pretContent = CWRENDERAGENTS._renderPretTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      $evContent.append($pretContent);
    }

    //Prêts ou emprunts avec ou sans remplacement in zoom journe
    if (indToRender.pretint || indToRender.pretprete || indToRender.pretrecu) {
      //pretint, pretprete, pretrecu are not sent by metier, are a local indicators for vue zoom journee that there is some indicateur of pret or remplacement for this day
      const $pretContent = CWRENDERAGENTS._renderPretTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      $evContent.append($pretContent);
    }

    //Disponibilité ou indisponibilité pour prêts
    if (indToRender.pret) {
      const $dispContent = CWRENDERAGENTS._renderDispTooltip(indToRender, pk, model, indicateurs, options);

      $evContent.append($dispContent);
    }
    //Affectations secondaires MT819
    if (indToRender.affsec) {
      const $affsecondContent = CWRENDERAGENTS._renderAffsecondTooltip(dtsModel, indToRender, pk, model, indicateurs);

      $evContent.append($affsecondContent);
    }
    //Anomalies,
    if (indToRender.val || indToRender.valb) {
      const $anoContent = CWRENDERAGENTS._renderAnoTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      $evContent.append($anoContent);
    }
    //Messages
    if (indToRender.mes) {
      const $mesContent = CWRENDERAGENTS._renderMesTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      $evContent.append($mesContent);
    }
    //Astreinte par regul
    if (indToRender.astreg) {
      const $astregContent = CWRENDERAGENTS._renderAstrRegulsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      $evContent.append($astregContent);
    }
    $titleContent.append($firstLine);
    $titleContent.append($secondLine);
    $titleContent.append($evContent);
    viewEl.prop("title", $titleContent[0].innerHTML);
  }

  static _isTypologieSecondaire(typeIndSecon: number, indicSecondaire: string, typologie: { [key: string]: any }): boolean {
    let typoSecond = false;

    if (typeIndSecon === 2 && indicSecondaire === typologie.cod) {
      typoSecond = true;
    }
    return typoSecond;
  }

  static _renderBadgeagesTooltip(indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const $badContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $badTitle = null;
    let text = "";
    let $spanContext = null;

    if (indicateurs.badd && indicateurs.badd.length > 1) {
      $badTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_bad_plu'));
    } else {
      $badTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_bad_sing'));
    }
    $badContainer.find(".phx-render-tooltip-column1").append($badTitle);
    if (_.isObject(options.icons) && options.icons.bad) {
      $badContainer.find(".phx-render-tooltip-column1").prepend($(options.icons.bad).css("display", "inline-block"));
      $badContainer.find(".phx-render-tooltip-column1").find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }
    for (let k = 0; Array.isArray(indicateurs.badd) && k < indicateurs.badd.length; k++) {
      const badgObj: { [key: string]: any } = indicateurs.badd[k];

      if (k !== 0) {
        text += ", ";
      }
      if (CWRENDERAGENTS._checkSoumisAWorkflowAndNonValide(badgObj.stat)) {
        text += " ?";
      }
      text += "<span class='ui-phx-ihm-texte-donnees-important'>";
      if (badgObj.valo && !CWSTR.isBlank(badgObj.valo.heure)) {
        text += " " + CWTYPE.HOUR_MINUTE.format(badgObj.valo.heure) + " </span>";
        text += " (" + badgObj.valo.sens + ")";
      } else if (badgObj.modif && !CWSTR.isBlank(badgObj.modif.heure)) {
        text += " " + CWTYPE.HOUR_MINUTE.format(badgObj.modif.heure) + " </span>";
        text += " (" + badgObj.modif.sens + ")";
      } else if (badgObj.init && !CWSTR.isBlank(badgObj.init.heure)) {
        text += " " + CWTYPE.HOUR_MINUTE.format(badgObj.init.heure) + " </span>";
        text += " (" + badgObj.init.sens + ")";
      }
      if (CWRENDERAGENTS._checkDemandeSupression(badgObj)) {
        text += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
      }
    }
    $spanContext = $("<span>").html(text);
    $badContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $badContainer;
  }

  static _renderRegulsTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const $regContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $regTitle = null;
    let text = "";
    let $spanContext = null;

    if (indicateurs.regd && indicateurs.regd.length > 1) {
      $regTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_reg_plu'));
    } else {
      $regTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_reg_sing'));
    }
    $regContainer.find(".phx-render-tooltip-column1").append($regTitle);
    if (_.isObject(options.icons) && options.icons.reg) {
      $regContainer.find(".phx-render-tooltip-column1").prepend($(options.icons.reg).css("display", "inline-block"));
      $regContainer.find(".phx-render-tooltip-column1").find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });

    }
    for (let k = 0; Array.isArray(indicateurs.regd) && k < indicateurs.regd.length; k++) {
      const regObj: { [key: string]: any } = indicateurs.regd[k];

      if (k !== 0) {
        text += "</br>";
      }
      if (CWRENDERAGENTS._checkSoumisAWorkflowAndNonValide(regObj.etat)) {
        text += " ?";
      }
      if (!CWSTR.isBlank(regObj.val)) {
        const libelle = dtsModel.REGULS.get(regObj.cod);
        const formato = libelle.get("fmt");

        if (!CWSTR.isBlank(formato)) {
          text += libelle.get("lib") + " (" + CWTYPE.CUSTOM.format(regObj.val, formato) + ")";
        } else {
          text += libelle.get("lib") + "(" + CWTYPE.DURATION.HOUR_MINUTE_24.format(regObj.val) + ")";
        }
      }
      if (CWRENDERAGENTS._checkDemandeSupression(regObj)) {
        text += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
      }
    }
    $spanContext = $("<span>").html(text);
    $regContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $regContainer;
  }

  static _renderAstrRegulsTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const $regContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let text = "";
    let textAstRegulCh = null;
    let $regTitle = null;
    let $spanContext = null;

    if (CWDIVERS.get("AstRegul") === "1") {
      textAstRegulCh = CWDIVERS.get("AstRegulCh");
      $regTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(textAstRegulCh);
    } else {
      $regTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.tooltip_regast'));
    }
    $regContainer.find(".phx-render-tooltip-column1").append($regTitle);
    if (_.isObject(options.icons) && options.icons.astreg) {
      $regContainer.find(".phx-render-tooltip-column1").prepend($(options.icons.astreg).css("display", "inline-block"));
      $regContainer.find(".phx-render-tooltip-column1").find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }
    for (let k = 0; Array.isArray(indicateurs.astregd) && k < indicateurs.astregd.length; k++) {
      const regObj: { [key: string]: any } = indicateurs.astregd[k];

      if (k !== 0) {
        text += "</br>";
      }
      if (CWRENDERAGENTS._checkSoumisAWorkflowAndNonValide(regObj.sta)) {
        text += " ?";
      }
      if (!CWSTR.isBlank(regObj.val)) {
        const libelle = dtsModel.REGULS.get(regObj.cod);
        text += libelle.get("lib") + "(" + regObj.val + ")";
      }
      if (CWRENDERAGENTS._checkDemandeSupression(regObj)) {
        text += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
      }
    }
    $spanContext = $("<span>").html(text);
    $regContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $regContainer;
  }

  static _renderSouhTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    let absTextByGroupe = false;
    const $souContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let text = "";
    let $souTitle = null;
    let $spanContext = null;
    let lbIsPlequipe = false;
    let lbParamDiverAnonymAbs = false;

    if (options) {
      if (_.isBoolean(options.absTextByGroupe)) {
        absTextByGroupe = options.absTextByGroupe;
      }
    }
    if (_.isBoolean(options.isPlequipe)) {
      lbIsPlequipe = options.isPlequipe;
    }
    if (_.isBoolean(options.paramDiverAnonymAbs)) {
      lbParamDiverAnonymAbs = options.paramDiverAnonymAbs;
    }
    if (indicateurs.soud && indicateurs.soud.length > 1) {
      $souTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_sou_plu'));
    } else {
      $souTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_sou_sing'));
    }
    $souContainer.find(".phx-render-tooltip-column1").append($souTitle);
    if (_.isObject(options.icons) && options.icons.abs) {
      $souContainer.find(".phx-render-tooltip-column1").prepend($(options.icons.abs).css("display", "inline-block"));
      $souContainer.find(".phx-render-tooltip-column1").find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }
    for (let k = 0; Array.isArray(indicateurs.soud) && k < indicateurs.soud.length; k++) {
      const souObj: { [key: string]: any } = indicateurs.soud[k];
      let absDTSmodel = null;

      if (k !== 0 && souObj.uni !== "J") {
        text += ", ";
      }
      if (k !== 0) {
        text += "<br>";
      }
      if (!CWSTR.isBlank(souObj.uni)) {
        if (souObj.uni === "M" || souObj.uni === "S" || souObj.uni === "K" || souObj.uni === "J" || souObj.uni === "N") {
          let lib = "";
          const lFind = dtsModel.AUX_UNI.find((m: Backbone.Model) => {
            return m.get("cod") === souObj.uni;
          });

          if (!CWSTR.isBlank(lFind)) {
            lib = lFind.get("lib");
          }
          text += lib;
        } else if (souObj.uni === "H" && (souObj.hrd !== 0 || souObj.hrf !== 0)) {
          if (lbIsPlequipe && lbParamDiverAnonymAbs) {
            text = "<label>" + i18n.t('common:utils.souhaitConf', {
              "0": "</label>" + CWTYPE.HOUR_MINUTE.format(souObj.hrd, CWTYPE._getFormatByCode("DUREEHM")).trim() + "<label>",
              "1": "</label>" + CWTYPE.HOUR_MINUTE.format(souObj.hrf, CWTYPE._getFormatByCode("DUREEHM")).trim(),
              interpolation: { escapeValue: false }
            });
          } else {
            text += i18n.t('messages:GT_1721', {
              "1": CWTYPE.HOUR_MINUTE_NIGHTLY.format(souObj.hrd),
              "2": CWTYPE.HOUR_MINUTE_NIGHTLY.format(souObj.hrf),
              "3": CWTYPE.DURATION.HOUR_MINUTE.format(souObj.dur).trim()
            });
          }
        } else if (souObj.uni === "H") {
          if (lbIsPlequipe && lbParamDiverAnonymAbs) {
            text = "<label>" + i18n.t('common:utils.souhait') + " " + CWTYPE.DURATION.HOUR_MINUTE.format(souObj.dur);
          } else {
            text += CWTYPE.DURATION.HOUR_MINUTE.format(souObj.dur) + ", ";
          }
        }
      }
      if (CWRENDERAGENTS._checkSoumisAWorkflowAndNonValide(souObj.sta)) {
        text += " ?";
      }
      if (!lbIsPlequipe || !lbParamDiverAnonymAbs) {
        absDTSmodel = dtsModel.ABSENCES.get(souObj.cod);
        if (absTextByGroupe && !CWSTR.isBlank(absDTSmodel.get("grp"))) { //Use groupe of souhait
          text += " <span class='ui-phx-ihm-texte-donnees-important'>" + dtsModel.GROUPS_ABSENCE.get(absDTSmodel.get("grp")).get("lib") + "</span>";
        } else {
          text += " <span class='ui-phx-ihm-texte-donnees-important'>" + absDTSmodel.get("lib") + "</span>";
        }
      }
      if (CWRENDERAGENTS._checkDemandeSupression(souObj)) {
        text += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
      }
    }
    $spanContext = $("<span>").html(text);
    $souContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $souContainer;
  }

  static _renderAbsTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    let absTextByGroupe = false;
    const $absContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $absTitle = null;
    let text = "";
    let $spanContext = null;
    let lbIsPlequipe = false;
    let lbParamDiverAnonymAbs = false;

    if (options) {
      if (_.isBoolean(options.absTextByGroupe)) {
        absTextByGroupe = options.absTextByGroupe;
      }
    }
    if (_.isBoolean(options.isPlequipe)) {
      lbIsPlequipe = options.isPlequipe;
    }
    if (_.isBoolean(options.paramDiverAnonymAbs)) {
      lbParamDiverAnonymAbs = options.paramDiverAnonymAbs;
    }
    if (indicateurs.absd && indicateurs.absd.length > 1) {
      if (_.isEmpty(options.fractionsMedical)) {
        $absTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_abs_plu'));
      } else { //Médical
        $absTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planmed.new_tooltip_abs_plu'));
      }
    } else {
      if (_.isEmpty(options.fractionsMedical)) {
        $absTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_abs_sing'));
      } else { //Médical
        $absTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planmed.new_tooltip_abs_sing'));
      }
    }
    $absContainer.find(".phx-render-tooltip-column1").append($absTitle);
    if (_.isObject(options.icons) && options.icons.abs) {
      $absContainer.find(".phx-render-tooltip-column1").prepend($(options.icons.abs).css("display", "inline-block"));
      $absContainer.find(".phx-render-tooltip-column1").find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }
    for (let k = 0; Array.isArray(indicateurs.absd) && k < indicateurs.absd.length; k++) {
      const absObj: { [key: string]: any } = indicateurs.absd[k];
      let absDTSmodel = null;

      if (k !== 0 && absObj.uni !== "J") {
        text += ", ";
      }
      if (k !== 0) {
        text += "<br>";
      }

      if (!CWSTR.isBlank(absObj.uni)) {
        if (absObj.uni === "M" || absObj.uni === "S" || absObj.uni === "K" || absObj.uni === "J" || absObj.uni === "N") {
          let lib = "";
          const lFind = dtsModel.AUX_UNI.find((m: { [key: string]: any }) => {
            return m.get("cod") === absObj.uni;
          });

          if (!CWSTR.isBlank(lFind)) {
            lib = lFind.get("lib");
          }
          text += lib;
        } else if (absObj.uni === "H" && (absObj.hrd !== 0 || absObj.hrf !== 0)) {
          if (lbIsPlequipe && lbParamDiverAnonymAbs) {
            text = "<label>" + i18n.t('common:utils.absconf', {
              "0": "</label>" + CWTYPE.HOUR_MINUTE.format(absObj.hrd, CWTYPE._getFormatByCode("DUREEHM")).trim() + "<label>",
              "1": "</label>" + CWTYPE.HOUR_MINUTE.format(absObj.hrf, CWTYPE._getFormatByCode("DUREEHM")).trim(),
              interpolation: { escapeValue: false }
            });
          } else {
            text += i18n.t('messages:GT_1721', {
              "1": CWTYPE.HOUR_MINUTE_NIGHTLY.format(absObj.hrd),
              "2": CWTYPE.HOUR_MINUTE_NIGHTLY.format(absObj.hrf),
              "3": CWTYPE.DURATION.HOUR_MINUTE.format(absObj.dur).trim()
            });
          }
        } else if (absObj.uni === "H") {
          if (lbIsPlequipe && lbParamDiverAnonymAbs) {
            text = "<label>" + i18n.t('common:utils.absence') + " " + CWTYPE.DURATION.HOUR_MINUTE.format(absObj.dur);
          } else {
            text += CWTYPE.DURATION.HOUR_MINUTE.format(absObj.dur) + ", ";
          }
        }
      }
      if (CWRENDERAGENTS._checkSoumisAWorkflowAndNonValide(absObj.sta)) {
        text += " ?";
      }
      if (!lbIsPlequipe || !lbParamDiverAnonymAbs) {
        absDTSmodel = dtsModel.ABSENCES.get(absObj.cod);
        if (absTextByGroupe && !CWSTR.isBlank(absDTSmodel.get("grp"))) { //Use groupe of souhait
          text += " <span class='ui-phx-ihm-texte-donnees-important'>" + dtsModel.GROUPS_ABSENCE.get(absDTSmodel.get("grp")).get("lib") + "</span>";
        } else {
          text += " <span class='ui-phx-ihm-texte-donnees-important'>" + absDTSmodel.get("lib") + " </span>";
        }
      }
      if (CWRENDERAGENTS._checkDemandeSupression(absObj)) {
        text += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
      }
    }
    $spanContext = $("<span>").html(text);
    $absContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $absContainer;
  }

  static _renderActTooltip(dtsModel: any, typologie: { [key: string]: any }, index: string | number, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    let twoActContainers = false;
    let typologiesConfig = null;
    let viewMode = "";
    let typeAcsAst = ""; //Type of activite to render (ACT - activite, ASTR - astreinte, "" - both);
    const date = pk.split(",").pop();
    let activitiesFilters = null;
    const $actContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $actContainerRea = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    const lPosactContainerCol1 = $actContainer.find(".phx-render-tooltip-column1");
    const lPosactContainerCol2 = $actContainer.find(".phx-render-tooltip-column2");
    let sizePrev = 0;
    let sizeReal = 0;
    const optionsPlequipe: { [key: string]: any } = {};

    if (options) {
      if (options.activitiesFilters) {
        activitiesFilters = options.activitiesFilters;
      }
      if (options.typologiesConfig) {
        typologiesConfig = options.typologiesConfig;
      }
      if (options.viewMode) {
        viewMode = options.viewMode;
      }
      if (options.typeAcsAst) { //typologie de domaine to paint
        typeAcsAst = options.typeAcsAst;
      }
      if (_.isBoolean(options.isPlequipe)) {
        optionsPlequipe.isPlequipe = options.isPlequipe;
      }
      if (_.isBoolean(options.paramDiverAnonymAct)) {
        optionsPlequipe.paramDiverAnonymAct = options.paramDiverAnonymAct;
      }
    }
    if (!CWSTR.isBlank(activitiesFilters) && activitiesFilters.get(typologie.cod)) {
      if ((activitiesFilters.get(typologie.cod).get("indcomplementaire") === true || activitiesFilters.get(typologie.cod).get("indzoom" + viewMode)) &&
        activitiesFilters.get(typologie.cod).get("selected") === true &&
        ((activitiesFilters.get(typologie.cod).get("prevu") === true && indicateurs.typo[index].prev && indicateurs.typo[index].prev.length > 0 && typologiesConfig[typologie.cod].get("actprev") === true) ||
          (activitiesFilters.get(typologie.cod).get("realise") === true && indicateurs.typo[index].real && indicateurs.typo[index].real.length > 0 && typologiesConfig[typologie.cod].get("actreal") === true))) {
        const typoGlobalData = GLOBAL_DATA.typologies.get(typologie.cod);
        const typoCode = typologie.cod;

        if (typologiesConfig[typologie.cod]) {
          sizePrev = 0;
          sizeReal = 0;
          if (activitiesFilters.get(typologie.cod).get("prevu") === true && indicateurs.typo[index].prev && indicateurs.typo[index].prev.length > 0) {
            if (CWSTR.isBlank(typeAcsAst)) { //We want to paint both typologies de domaine
              sizePrev = indicateurs.typo[index].prev.length;
            } else { //We want to paint only activities or astreintes(for mutiline)
              for (let k = 0; Array.isArray(indicateurs.typo[index].prev) && k < indicateurs.typo[index].prev.length; k++) {
                const actObj: { [key: string]: any } = indicateurs.typo[index].prev[k];

                if (actObj.tdo === typeAcsAst) {
                  sizePrev++;
                }
              }
            }
          }
          if (activitiesFilters.get(typologie.cod).get("realise") === true && indicateurs.typo[index].real && indicateurs.typo[index].real.length > 0) {
            if (CWSTR.isBlank(typeAcsAst)) { //We want to paint both typologies de domaine
              sizeReal = indicateurs.typo[index].real.length;
            } else { //We want to paint only activities or astreintes(for mutiline)
              for (let k = 0; Array.isArray(indicateurs.typo[index].real) && k < indicateurs.typo[index].real.length; k++) {
                const actObj: { [key: string]: any } = indicateurs.typo[index].real[k];

                if (actObj.tdo === typeAcsAst) {
                  sizeReal++;
                }
              }
            }
          }
          if (typologiesConfig[typoCode].get("actreal") === true && typologiesConfig[typoCode].get("actprev") === true) {
            let genre = "";

            //Both typologies (prevues and realisees) CalqActUniq=false            
            if (sizePrev >= 1) {
              let $actTitle = null;
              const $content = $("<span>");

              if (sizePrev === 1) { //One prevue
                if (typoGlobalData.get("genre") === "M") {
                  genre = i18n.t('messages:GT_1571');
                } else {
                  genre = i18n.t('messages:GT_1573');
                }
              } else { //Many prevues
                if (typoGlobalData.get("genre") === "M") {
                  genre = i18n.t('messages:GT_1572');
                } else {
                  genre = i18n.t('messages:GT_1574');
                }
              }
              //Title
              if (sizePrev > 1) {
                $actTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(CWSTR.toFirstUpperCase(typoGlobalData.get("regroupement") + " " + genre));
              } else {
                $actTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(CWSTR.toFirstUpperCase(typoGlobalData.get("evenement") + " " + genre));
              }
              lPosactContainerCol1.empty();
              lPosactContainerCol1.append($actTitle);
              if (_.isObject(options.icons) && options.icons.act) {
                lPosactContainerCol1.prepend($(options.icons.act).css("display", "inline-block"));
                lPosactContainerCol1.find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
              }
              //Prevues
              for (let k = 0; Array.isArray(indicateurs.typo[index].prev) && k < indicateurs.typo[index].prev.length; k++) {
                const preAct: { [key: string]: any } = indicateurs.typo[index].prev[k];

                if (CWSTR.isBlank(typeAcsAst) || typeAcsAst === preAct.tdo) {
                  let $tempoInfoPre = CWRENDERAGENTS._renderTemporelleActInfo(preAct, date, dtsModel, "P", false, optionsPlequipe);
                  let $divLine = null;

                  if (CWRENDERAGENTS._checkDemandeSupression(preAct)) {
                    $tempoInfoPre += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
                  }
                  $divLine = $("<div>").html($tempoInfoPre);
                  $content.append($divLine);
                }
              }
              lPosactContainerCol2.empty();
              lPosactContainerCol2.append($content);
            }
            if (sizeReal >= 1) {
              let $actTitle = null;
              const $content = $("<span>");
              let lPosActContainerReaCol1 = null;
              let lPosActContainerReaCol2 = null;

              if (sizePrev >= 1) {
                twoActContainers = true;
              } else {
                $actContainerRea = $actContainer;
              }
              lPosActContainerReaCol1 = $actContainerRea.find(".phx-render-tooltip-column1");
              lPosActContainerReaCol2 = $actContainerRea.find(".phx-render-tooltip-column2");
              if (sizeReal === 1) { //One realisee
                if (typoGlobalData.get("genre") === "M") {
                  genre = i18n.t('messages:GT_1575');
                } else {
                  genre = i18n.t('messages:GT_1577');
                }
              } else { //Many realisees
                if (typoGlobalData.get("genre") === "M") {
                  genre = i18n.t('messages:GT_1576');
                } else {
                  genre = i18n.t('messages:GT_1578');
                }
              }
              if (sizeReal > 1) {
                $actTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(CWSTR.toFirstUpperCase(typoGlobalData.get("regroupement") + " " + genre));
              } else {
                $actTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(CWSTR.toFirstUpperCase(typoGlobalData.get("evenement") + " " + genre));
              }
              if (!CWSTR.isBlank(lPosActContainerReaCol1) && lPosActContainerReaCol1.length > 0) {
                lPosActContainerReaCol1.empty();
                lPosActContainerReaCol1.append($actTitle);
                if (_.isObject(options.icons) && options.icons.act) {
                  lPosActContainerReaCol1.prepend($(options.icons.act).css("display", "inline-block"));
                  lPosActContainerReaCol1.find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
                }
              }
              //Realisees
              for (let k = 0; Array.isArray(indicateurs.typo[index].real) && k < indicateurs.typo[index].real.length; k++) {
                const reaAct: { [key: string]: any } = indicateurs.typo[index].real[k];

                if (CWSTR.isBlank(typeAcsAst) || typeAcsAst === reaAct.tdo) {
                  let $tempoInfoRea = CWRENDERAGENTS._renderTemporelleActInfo(reaAct, date, dtsModel, "R", false, optionsPlequipe);
                  let $divLine = null;

                  if (CWRENDERAGENTS._checkDemandeSupression(reaAct)) {
                    $tempoInfoRea += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
                  }
                  $divLine = $("<div>").html($tempoInfoRea);
                  $content.append($divLine);
                }
              }
              if (!CWSTR.isBlank(lPosActContainerReaCol2) && lPosActContainerReaCol2.length > 0) {
                lPosActContainerReaCol2.empty();
                lPosActContainerReaCol2.append($content);
              }
            }
          } else { //Only one typologie CalqActUniq=true
            if ((sizePrev + sizeReal) >= 1) { //If there are no activities for this, don't paint them
              let $actTitle = null;
              const $content = $("<span>");

              if ((sizePrev + sizeReal) > 1) {
                $actTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(CWSTR.toFirstUpperCase(typoGlobalData.get("regroupement")));
              } else {
                $actTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(CWSTR.toFirstUpperCase(typoGlobalData.get("evenement")));
              }
              lPosactContainerCol1.empty();
              lPosactContainerCol1.append($actTitle);
              if (_.isObject(options.icons) && options.icons.act) {
                lPosactContainerCol1.prepend($(options.icons.act).css("display", "inline-block"));
                lPosactContainerCol1.find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
              }

              if (typologiesConfig[typoCode].get("actprev") === true && activitiesFilters.get(typologie.cod).get("prevu") === true) {
                //Prevues
                for (let k = 0; Array.isArray(indicateurs.typo[index].prev) && k < indicateurs.typo[index].prev.length; k++) {
                  const preAct: { [key: string]: any } = indicateurs.typo[index].prev[k];

                  if (CWSTR.isBlank(typeAcsAst) || typeAcsAst === preAct.tdo) {
                    let $tempoInfo = CWRENDERAGENTS._renderTemporelleActInfo(preAct, date, dtsModel, "P", false, optionsPlequipe);
                    let $divLine = null;

                    if (CWRENDERAGENTS._checkDemandeSupression(preAct)) {
                      $tempoInfo += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
                    }
                    $divLine = $("<div>").html($tempoInfo);
                    $content.append($divLine);
                  }
                }
              }
              if (typologiesConfig[typoCode].get("actreal") === true && activitiesFilters.get(typologie.cod).get("realise") === true) {
                //Realisees
                for (let k = 0; Array.isArray(indicateurs.typo[index].real) && k < indicateurs.typo[index].real.length; k++) {
                  const reaAct: { [key: string]: any } = indicateurs.typo[index].real[k];

                  if (CWSTR.isBlank(typeAcsAst) || typeAcsAst === reaAct.tdo) {
                    let $tempoInfo = CWRENDERAGENTS._renderTemporelleActInfo(reaAct, date, dtsModel, "R", false, optionsPlequipe);
                    let $divLine = null;

                    if (CWRENDERAGENTS._checkDemandeSupression(reaAct)) {
                      $tempoInfo += ' <span class="ui-phx-demande-suppression">(' + i18n.t('common:demandeSuppression') + ')</span>';
                    }
                    $divLine = $("<div>").html($tempoInfo);
                    $content.append($divLine);

                  }
                }

              }
              lPosactContainerCol2.empty();
              lPosactContainerCol2.append($content);
            }
          }
        }
      }
      if ((sizePrev + sizeReal) < 1) { //If no activities of this type, no tooltip is shown
        return null;
      } else if (twoActContainers === true) {
        return $("<span>").append($actContainer).append($actContainerRea);
      } else {
        return $actContainer;
      }
    }
    return null;
  }

  /**
   * Renders information temporelle about activites (from SFD composant gerer un recapitulatif section 2.7.7.2 restitution)
   * Used to render activities infobulle in planning
   */
  static _renderTemporelleActInfo(activite: { [key: string]: any }, currentDate: string, dtsModel: any, typeEvenement: string, copierColler?: boolean, optionsPlequipe?: { [key: string]: boolean }): string {
    const response: any = [];

    if (_.isEmpty(optionsPlequipe) || !(optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct)) {
      if (CWRENDERAGENTS._checkSoumisAWorkflowAndNonValide(activite.eta)) {
        response.push(" ?");
      }
    }
    // Indication temporelle
    if (activite.tpe === "C") { //typesaisie
      // -Activités saisies sur une période continue : ActTypeSaisie = «C»
      //Pour les journées intermédiaires, si nous avons une saisie en hors presence avec une astreinte en dehors de la presence effective.
      if (activite.deb < currentDate && currentDate < activite.fin && !activite.pre && activite.hpre && (activite.ude === "S" || activite.ufi === "E")) {
        const cssClass = "ui-phx-ihm-texte-application";

        response.push("<span class='" + cssClass + "'>" + i18n.t('messages:GT_1404') + "</span>");
      }
      //Pour la date de début en journée complète
      //Pour la date de début en fraction de journée
      else if (activite.deb === currentDate && !CWSTR.isBlank(activite.unic) && activite.unic !== "D" && activite.unic !== "H" && activite.unic !== "SORTIE") {
        const cssClass = "ui-phx-ihm-texte-donnees";
        let lib = "";
        const lFindAux = dtsModel.AUX_UNI.find((m: Backbone.Model) => {
          return m.get("cod") === activite.unic;
        });

        if (!CWSTR.isBlank(lFindAux)) {
          lib = lFindAux.get("lib");
        }
        response.push("<span class='" + cssClass + "'>" + lib + "</span>");
      } // Pour les activités débutant à une heure précise
      else if (activite.deb === currentDate && activite.unic === "H" && !CWSTR.isBlank(activite.hed)) {
        const messageClass = "ui-phx-ihm-texte-application";
        const donneesClass = (typeEvenement === "P") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        let message = i18n.t('messages:GT_1747', { "1": "<span class='" + donneesClass + "'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.hed) + "</span>", interpolation: { escapeValue: false } });

        if (!_.isEmpty(optionsPlequipe) && optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct) {
          message = message.slice(0, -1); //on enleve le dernier caractère ","
        }
        response.push("<span class='" + messageClass + "'>" + message + "</span>");
      }
      //Pour les activités débutant après la présence du collaborateur
      else if (activite.deb === currentDate && activite.unic === "SORTIE") {
        const cssClass = "ui-phx-ihm-texte-application";

        response.push("<span class='" + cssClass + "'>" + i18n.t('messages:GT_1403') + "</span>");
      }
      //Pour la date de fin en journée complète
      //Pour la date de fin en fraction de journée
      else if (activite.fin === currentDate && !CWSTR.isBlank(activite.unic) && activite.unic !== "D" && activite.unic !== "H" && activite.unic !== "ENTREE") {
        const cssClass = (typeEvenement === "P" && activite.unic === "J") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        let lib = "";
        const lFindAux = dtsModel.AUX_UNI.find((m: Backbone.Model) => {
          return m.get("cod") === activite.unic;
        });

        if (!CWSTR.isBlank(lFindAux)) {
          lib = lFindAux.get("lib");
        }
        response.push("<span class='" + cssClass + "'>" + lib + "</span>");
      }
      // Pour les activités se terminant à une heure précise
      else if (activite.fin === currentDate && activite.unic === "H" && !CWSTR.isBlank(activite.hef)) {
        const messageClass = "ui-phx-ihm-texte-application";
        const donneesClass = (typeEvenement === "P") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        let message = i18n.t('messages:GT_1748', { "1": "<span class='" + donneesClass + "'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.hef) + "</span>", interpolation: { escapeValue: false } });

        if (!_.isEmpty(optionsPlequipe) && optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct) {
          message = message.slice(0, -1); //on enleve le dernier caractère ","
        }
        response.push("<span class='" + messageClass + "'>" + message + "</span>");
      }
      //Pour les activités débutant avant la présence du collaborateur
      else if (activite.fin === currentDate && activite.unic === "ENTREE") {
        const cssClass = "ui-phx-ihm-texte-application";

        response.push("<span class='" + cssClass + "'>" + i18n.t('messages:GT_1402') + "</span>");
      }
    } else if (activite.tpe === "D") {
      // -Activités saisies sur une date ou pour une période répétitive : ActTypeSaisie = «D»
      switch (activite.mod) {
        case "UNITE":
          //Pour les activités se déroulant sur une fraction de journée :
          if (!CWSTR.isBlank(activite.unic) && activite.unic !== "D" && activite.deb <= currentDate && currentDate <= activite.fin && !CWSTR.isBlank(activite.unic)) {
            const cssClass = "ui-phx-ihm-texte-donnees";
            let lib = "";
            const lFindAux = dtsModel.AUX_UNI.find((m: Backbone.Model) => {
              return m.get("cod") === activite.unic;
            });

            if (!CWSTR.isBlank(lFindAux)) {
              lib = lFindAux.get("lib");
            }
            response.push("<span class='" + cssClass + "'>" + lib + "</span>");
          }
          break;
        case "PLH":
          // Pour les activités se déroulement sur un créneau horaire
          if (activite.deb <= currentDate && currentDate <= activite.fin) {
            const messageClass = "ui-phx-ihm-texte-application";
            const donneesClass = "ui-phx-ihm-texte-donnees";
            const donneesAlter = "ui-phx-ihm-texte-donnees-alternatif";
            const duration = CWTYPE.DURATION.HOUR_MINUTE._calculateDuration(activite.hed, activite.hef);
            let durationFormatted = CWTYPE.DURATION.HOUR_MINUTE.format(duration);
            let message = null;

            // Delete spaces
            durationFormatted = durationFormatted.replace(/ /g, "");
            message = i18n.t('messages:GT_1721', {
              "1": "<span class='" + donneesClass + "'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.hed) + "</span>",
              "2": "<span class='" + donneesClass + "'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.hef) + "</span>",
              "3": "<span class='" + donneesAlter + "'>" + durationFormatted + "</span>",
              interpolation: { escapeValue: false }
            });
            if (!_.isEmpty(optionsPlequipe) && optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct) {
              message = message.slice(0, -1); //on enleve le dernier caractère ","
            }
            response.push("<span class='" + messageClass + "'>" + message + "</span>");
          }
          break;
        case "DUREE":
          // Pour les activités saisies en durée
          if (activite.hed <= currentDate && currentDate <= activite.fin) {
            const cssClass = "ui-phx-ihm-texte-donnees";
            const message = CWTYPE.DURATION.HOUR_MINUTE.format(activite.het, "&&h&&");

            response.push("<span class='" + cssClass + "'>" + message + "</span>");
          }
          break;
        case "RELIQUAT":
          // Pour les activités saisies en reliquat
          if (activite.deb <= currentDate && currentDate <= activite.fin) {
            const cssClass = "ui-phx-ihm-texte-application";
            const message = i18n.t('messages:GT_1722');

            response.push("<span class='" + cssClass + "'>" + message + "</span>");
          }
          break;
        case "POURCENT":
          // Pour les activités saisies en reliquat
          if (activite.deb <= currentDate && currentDate <= activite.fin) {
            const cssClass = "ui-phx-ihm-texte-donnees";
            const message = CWTYPE.PERCENTAGE_0.format(activite.pct);

            response.push("<span class='" + cssClass + "'>" + message + "</span>");
          }
          break;
        case "HORSPRES":
          //Pour les activités débutant avant la présence du collaborateur
          if (activite.deb <= currentDate && currentDate <= activite.fin) {
            const cssClass = "ui-phx-ihm-texte-application";

            if (activite.hav && !activite.hap) {
              response.push("<span class='" + cssClass + "'>" + i18n.t('messages:GT_1402') + "</span>");
            } else if (!activite.hav && activite.hap) {
              response.push("<span class='" + cssClass + "'>" + i18n.t('messages:GT_1403') + "</span>");
            } else if (activite.hav && activite.hap) {
              response.push("<span class='" + cssClass + "'>" + i18n.t('messages:GT_1404') + "</span>");
            }
          }
          break;
      }
    }

    if (copierColler === true) {
      if (response.length >= 1) {
        response.unshift(", ");
      }
      response.unshift("<span class='ui-phx-ihm-texte-donnees-important'>" + dtsModel.ACTIVITES.get(activite.act).get("abr4") + "</span>");
    } else {
      if (_.isEmpty(optionsPlequipe) || !(optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct)) {
        // In activite.mod === "PLH" the ", " is already set
        if (response.length >= 1 && activite.mod !== "PLH") {
          response.push(",");
        }
        response.push(" ");
        response.push("<span class='ui-phx-ihm-texte-donnees-important'>" + dtsModel.ACTIVITES.get(activite.act).get("lib") + "</span>");
      }
    }
    return response.join("");
  }

  /**
   * Function to render pret tooltip
   */
  static _renderPretTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const $pretContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $pretTitle = null;
    const $content = $("<div>");
    const date = pk.split(",").pop();


    if (indicateurs.prtd && indicateurs.prtd.length > 1) {
      $pretTitle = $("<div class='ui-phx-ihm-texte-application'>").text(i18n.t('plans.planresp.new_tooltip_pret_plu'));
    } else {
      $pretTitle = $("<div class='ui-phx-ihm-texte-application'>").text(i18n.t('plans.planresp.new_tooltip_pret_sing'));
    }
    $pretContainer.find(".phx-render-tooltip-column1").append($pretTitle);
    if (_.isObject(options.icons) && options.icons.pret) {
      $pretContainer.find(".phx-render-tooltip-column1").prepend($(options.icons.pret).css("display", "inline-block"));
      $pretContainer.find(".phx-render-tooltip-column1").find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }

    for (let k = 0; Array.isArray(indicateurs.prtd) && k < indicateurs.prtd.length; k++) {
      const pret: { [key: string]: any } = indicateurs.prtd[k];

      let $divLine = null;
      const response = CWRENDERAGENTS._renderTemporellePretInfo(pret, date, dtsModel, "P");

      if (response.length > 0) {
        $divLine = $('<div>');
        $divLine.append(response.join(" "));
      }
      if (pret.lib) {
        if (!CWSTR.isBlank($divLine)) {
          $divLine.append(", ");
        } else {
          $divLine = $('<div>');
        }
        //Libelle
        const $spanLibelle = $("<span>").addClass("ui-phx-ihm-texte-donnees-important").html(pret.lib);
        $divLine.append($spanLibelle);
      }

      //Collaborateur
      if (!CWSTR.isBlank(pret.rpe) && !CWSTR.isBlank(pret.rpe.mat)) {
        let $spanCollab = null;
        let $span = null;

        if (!CWSTR.isBlank($divLine)) {
          $divLine.append(", ");
        } else {
          $divLine = $('<div>');
        }
        if (String(pret.rpe.mat) !== pk.split(",")[0]) {//Collaborateur remplace = current collaborateur
          const collab = dtsModel.AUX_COL.find((m: Backbone.Model) => {
            return m.get("cod") === pret.rpe.mat;
          });
          let collabText = null;

          $span = $("<span>").addClass("ui-phx-ihm-texte-application").html(i18n.t('messages:GT_1848'));
          if (!CWSTR.isBlank(collab)) {
            if (CWDIVERS.get("idCollab") === "matric_aux") {
              collabText = " " + collab.get("nom") + " " + collab.get("prem") + " " + collab.get("aux");
            } else {
              collabText = " " + collab.get("nom") + " " + collab.get("prem") + " " + collab.get("cod");
            }
          } else {
            collabText = " ";
          }
          $spanCollab = $("<span>").addClass("ui-phx-ihm-texte-donnees-important").html(collabText);
        } else {//Collaborateur remplaçant = current collaborateur
          const collab = dtsModel.AUX_COL.find((m: Backbone.Model) => {
            return m.get("cod") === pret.mat;
          });
          let collabText = null;

          $span = $("<span>").addClass("ui-phx-ihm-texte-application").html(i18n.t('messages:GT_1849'));
          if (!CWSTR.isBlank(collab)) {
            if (CWDIVERS.get("idCollab") === "matric_aux") {
              collabText = " " + collab.get("nom") + " " + collab.get("prem") + " " + collab.get("aux");
            } else {
              collabText = " " + collab.get("nom") + " " + collab.get("prem") + " " + collab.get("cod");
            }
          } else {
            collabText = " ";
          }
          $spanCollab = $("<span>").addClass("ui-phx-ihm-texte-donnees-important").html(collabText);
        }
        $divLine.append($span);
        $divLine.append($spanCollab);
      }
      // Structure
      if (!CWSTR.isBlank(pret.str)) {
        const libelleFormat = EVENEMENT.getLibelle(pret.str.fmt, pret.str.cod, pret.str.lib);
        const $span = $("<span>").addClass("ui-phx-ihm-texte-application").html(libelleFormat);

        if (!CWSTR.isBlank($divLine)) {
          $divLine.append(", ");
        } else {
          $divLine = $('<div>');
        }
        $divLine.append($span);
      }
      if (CWSTR.isBlank($divLine)) {
        $divLine = $('<div>');
      }
      $content.append($divLine);
    }

    $pretContainer.find(".phx-render-tooltip-column2").append($content);
    return $pretContainer;
  }

  static _renderTemporellePretInfo(pret: { [key: string]: any }, currentDate: string, dtsModel: any, typeEvenement: string): Array<string> {
    const response = [];
    // Indication temporelle
    if (pret.tpe === "C") { //typesaisie
      // -Activités saisies sur une période continue : ActTypeSaisie = «C»

      //Pour la date de début en journée complète
      //Pour la date de début en fraction de journée
      if (pret.deb === currentDate && !CWSTR.isBlank(pret.unic) && pret.unic !== 'H') {
        const cssClass = (typeEvenement === "P") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";

        response.push("<span class='" + cssClass + "'>" + dtsModel.AUX_UNI.find((m: Backbone.Model) => {
          return m.get("cod") === pret.unic;
        }).get("lib") + "</span>");
      }
      //Pour la date de fin en journée complète
      //Pour la date de fin en fraction de journée
      if (pret.fin === currentDate && !CWSTR.isBlank(pret.unic) && pret.unic !== 'H') {
        const cssClass = (typeEvenement === "P") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";

        response.push("<span class='" + cssClass + "'>" + dtsModel.AUX_UNI.find((m: Backbone.Model) => {
          return m.get("cod") === pret.unic;
        }).get("lib") + "</span>");
      }
      // Pour les pret débutant à une heure précise (Hors V2.1)
      if (pret.deb === currentDate && !CWSTR.isBlank(pret.hed) && !CWSTR.isBlank(pret.unic) && pret.unic === 'H') {
        const messageClass = "ui-phx-ihm-texte-application";
        const donneesClass = (typeEvenement === "P") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        const message = i18n.t('messages:GT_1747', {
          "1": "<span class='" + donneesClass + "'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(pret.hed) + "</span>",
          interpolation: { escapeValue: false }
        });

        response.push("<span class='" + messageClass + "'>" + message + "</span>");
      }
      // Pour les pret se terminant à une heure précise (Hors V2.1)
      if (pret.fin === currentDate && !CWSTR.isBlank(pret.hef) && !CWSTR.isBlank(pret.unic) && pret.unic === 'H') {
        const messageClass = "ui-phx-ihm-texte-application";
        const donneesClass = (typeEvenement === "P") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        const message = i18n.t('messages:GT_1748', { "1": donneesClass, "2": CWTYPE.HOUR_MINUTE_NIGHTLY.format(pret.hef) });

        response.push("<span class='" + messageClass + "'>" + message + "</span>");
      }
    } else if (pret.tpe === "D") {
      // -Activités saisies sur une date ou pour une période répétitive : ActTypeSaisie = «D»
      switch (pret.mod) {
        case "UNITE":
          //Pour les pret se déroulant sur une fraction de journée :
          if (!CWSTR.isBlank(pret.unic) && pret.unic !== "D" && pret.deb <= currentDate && currentDate <= pret.fin) {
            const cssClass = "ui-phx-ihm-texte-donnees";
            response.push("<span class='" + cssClass + "'>" + dtsModel.AUX_UNI.find((m: Backbone.Model) => {
              return m.get("cod") === pret.unic;
            }).get("lib") + "</span>");
          }
          break;
        case "PLH":
          // Pour les pret se déroulement sur un créneau horaire (Hors V2.1)
          if (pret.deb <= currentDate && currentDate <= pret.fin) {
            const messageClass = "ui-phx-ihm-texte-application";
            const donneesClass = (typeEvenement === "P") ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
            const donneesAlter = "ui-phx-ihm-texte-donnees-alternatif";
            const duration = CWTYPE.DURATION.HOUR_MINUTE._calculateDuration(pret.hed, pret.hef);
            const durationFormatted = CWTYPE.DURATION.HOUR_MINUTE.format(duration);
            const message = i18n.t('messages:GT_1721', {
              "1": "<span class='" + donneesClass + "'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(pret.hed) + "</span>",
              "2": "<span class='" + donneesClass + "'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(pret.hef) + "</span>",
              "3": "<span class='" + donneesAlter + "'>" + durationFormatted + "</span>",
              interpolation: { escapeValue: false }
            });

            response.push("<span class='" + messageClass + "'>" + message + "</span>");
          }
          break;
      }
    }
    return response;
  }

  /**
   * Used to render Affectations Secondaires infobulle in planning
   */
  static _renderAffsecondTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }): JQuery {
    const affs = indicateurs.affsec; // MT819
    const $affsContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $affsTitle = null;
    const $content = $("<div>");

    // indicateurs.affsec
    if (affs.length > 1) {
      $affsTitle = $("<div class='ui-phx-ihm-texte-application'>").text(i18n.t('plans.planresp.new_tooltip_affs_plu'));
    } else {
      $affsTitle = $("<div class='ui-phx-ihm-texte-application'>").text(i18n.t('plans.planresp.new_tooltip_affs_sing'));
    }
    $affsContainer.find(".phx-render-tooltip-column1").append($affsTitle);

    // indicateurs.affsec
    for (let k = 0; k < affs.length; k++) {
      const aff: { [key: string]: any } = affs[k];
      const $divLine = $('<div>');
      const $spanStr = $("<span>").addClass("ui-phx-ihm-texte-donnees-important").html(aff.itemlib);
      const $spanUnite = $("<span>").addClass("ui-phx-ihm-texte-application").html(aff.unitesaisie);

      // structure
      $divLine.append($spanStr);
      $divLine.append(", ");
      // unite
      $divLine.append($spanUnite);
      $content.append($divLine);
    }
    $affsContainer.find(".phx-render-tooltip-column2").append($content);
    return $affsContainer;
  }

  static _renderDispTooltip(indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const $dispContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    const dispoObj = indicateurs.pretd;
    let $dispTitle = null;
    let text = "<span style='font-weight:bold;'>";
    let $spanContext = null;
    const lPosCol1 = $dispContainer.find(".phx-render-tooltip-column1");

    if (dispoObj && !dispoObj.dispo) {
      $dispTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_indispo'));
    } else {
      $dispTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_dispo'));
    }
    lPosCol1.append($dispTitle);
    if (_.isObject(options.icons) && options.icons.pret) {
      lPosCol1.prepend($(options.icons.pret).css("display", "inline-block"));
      lPosCol1.find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }
    if (dispoObj && !CWSTR.isBlank(dispoObj.val)) {
      text += dispoObj.val;
    }
    text += "</span>";
    $spanContext = $("<span>").html(text);
    $dispContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $dispContainer;
  }

  static _renderAnoTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const $anoContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $anoTitle = null;
    const lPosCol1 = $anoContainer.find(".phx-render-tooltip-column1");
    const anoText: any = [];
    let $spanContext = null;

    if (indicateurs.anod && indicateurs.anod.length > 1) {
      $anoTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_ano_plu'));
    } else {
      $anoTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_ano_sing'));
    }
    lPosCol1.append($anoTitle);
    if (_.isObject(options.icons) && options.icons.val) {
      lPosCol1.prepend($(options.icons.val).css("display", "inline-block"));
      lPosCol1.find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }
    for (let k = 0; Array.isArray(indicateurs.anod) && k < indicateurs.anod.length; k++) {
      const anoObj: { [key: string]: any } = indicateurs.anod[k];

      if (!CWSTR.isBlank(anoObj.cod)) {
        const anoData = dtsModel.ANOMALIES.get(anoObj.cod);

        if (anoData && !CWSTR.isBlank(anoData.get("lib")) && !CWSTR.isBlank(anoData.get("typ"))) {
          const className = STYLE.getClassColorFromAnomalie(anoData.get("typ"));

          switch (anoData.get("typ")) {
            case "B":
              anoText[0] = CWSTR.isBlank(anoText[0]) ? "" : anoText[0] + "<br>";
              anoText[0] += "<span class ='" + className + "' style='font-weight:bold;' " +
                "title='" + i18n.t('plans.planresp.typeUrgent_title') + "'>! " +
                anoData.get("lib") + "</span>";
              break;
            case "P":
              anoText[1] = CWSTR.isBlank(anoText[1]) ? "" : anoText[1] + "<br>";
              anoText[1] += "<span class ='" + className + "' style='font-weight:bold;' " +
                "title='" + i18n.t('plans.planresp.typeImportant_title') + "'>! " +
                anoData.get("lib") + "</span>";
              break;
            case "N":
              anoText[2] = CWSTR.isBlank(anoText[2]) ? "" : anoText[2] + "<br>";
              anoText[2] += "<span class ='" + className + "' style='font-weight:bold;' " +
                "title='" + i18n.t('plans.planresp.typeInformation_title') + "'>! " +
                anoData.get("lib") + "</span>";
              break;
          }
        }
      }
    }


    $spanContext = $("<span>").html(_.compact(anoText).join("<br>"));
    $anoContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $anoContainer;

  }

  static _renderMesTooltip(dtsModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const $mesContainer = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
    let $mesTitle = null;
    const lPosCol1 = $mesContainer.find(".phx-render-tooltip-column1");
    let mesText = "<span style='font-weight:bold;'>";
    let $spanContext = null;

    if (indicateurs.mes && indicateurs.mes > 1) {
      $mesTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_mes_plu'));
    } else {
      $mesTitle = $("<div class='ui-phx-ihm-texte-application'></div>").text(i18n.t('plans.planresp.new_tooltip_mes_sing'));
    }
    lPosCol1.append($mesTitle);
    if (_.isObject(options.icons) && options.icons.mes) {
      lPosCol1.prepend($(options.icons.mes).css("display", "inline-block"));
      lPosCol1.find(".ui-phx-ihm-texte-application").css({ "display": "inline-block", "vertical-align": "top" });
    }
    for (let k = 0; Array.isArray(indicateurs.mesd) && k < indicateurs.mesd.length; k++) {
      const mesObj: { [key: string]: any } = indicateurs.mesd[k];

      if (k !== 0) {
        mesText += "</br>";
      }
      if (!CWSTR.isBlank(mesObj.val)) {
        mesText += mesObj.val + " ";
      }
    }
    mesText += "</span>";
    $spanContext = $("<span>").html(mesText);
    $mesContainer.find(".phx-render-tooltip-column2").append($spanContext);
    return $mesContainer;
  }

  static _checkSoumisAWorkflowAndNonValide(statut: string): boolean {
    let soumis = false;

    switch (statut) {
      case "D":
      case "T": //Demande
      case "I": //En cours
        soumis = true;
        break;
    }
    return soumis;
  }

  static _checkDemandeSupression(evObj: { [key: string]: any }): boolean {
    let demandSupr = false;

    if (evObj.eta === "X" || evObj.etat === "X") {
      demandSupr = true;
    }
    return demandSupr;
  }

  static _renderIndicateursMultiLineTooltip(dtsModel: any, evtModel: any, indToRender: { [key: string]: any }, pk: string, model: any, indicateurs: { [key: string]: any }, options: { [key: string]: any }, indPretAffSecToRender: { [key: string]: any }): { [key: string]: any } {
    let activitiesFilters: Backbone.Collection = null;
    let icons = null,
      viewMode = "s",
      typeIndSecon = 0,
      indicSecondaire = "",
      princBackClass = "",
      princBordClass = "",
      secBackClass = "",
      secBordClass = "",
      pretsAffSecBackClass = "",
      pretsAffSecBordClass = "";
    const $titleContentAbs = $("<table>"),
      $titleContentBadg = $("<table>"),
      $titleContentAct = $("<table>"),
      $titleContentAst = $("<table>");
    const $titleContentAstReg = $("<table>"),
      $titleContentInd = $("<table>");
    let $titleContent = $("<table>");
    const $titleContentAbsSecondaire = $("<table>"),
      $titleContentBadgSecondaire = $("<table>"),
      $titleContentActSecondaire = $("<table>"),
      $titleContentAstSecondaire = $("<table>");
    const $titleContentAstRegSecondaire = $("<table>"),
      $titleContentIndSecondaire = $("<table>");
    const $titleIndPretAffSec = $("<table>");
    const user = model.user;
    const nom = (user?.nom) ? user.nom.toUpperCase() : "";
    let nbMultiLines = 0;
    let lineAbs = 0,
      lineBadg = 0,
      lineAct = 0,
      lineAst = 0,
      lineInd = 0,
      lineAstReg = 0;
    const objIndicateurs: any = [];
    const objIndicateursSecondaire: any = [];
    const objIndicateursAffSec: any = [];
    const date = CWTYPE.DATE.format(model.dat, "DD Month YYYY");
    const day = CWTYPE.DATE.strToDate(model.dat).getDay();
    const lang = UTILS.getLanguage();
    let leLang = ", ";
    let $firstLine = null;
    const $secondLine = $("<span class='ui-phx-ihm-texte-application'><span class='phx-tooltip-second-line-text-container'>" + i18n.t('common:infoIndicateurs') + "</span></span>");
    const $secondLineIndicContainer = $("<span>").addClass("phx-tooltip-second-line-container");

    if (options) {
      if (options.icons) {
        icons = options.icons;
      }
      if (options.activitiesFilters) {
        activitiesFilters = options.activitiesFilters;
      }
      if (options.viewMode) {
        viewMode = options.viewMode;
      }
      if (_.isNumber(options.nbMultiLines)) {
        nbMultiLines = options.nbMultiLines;
      }
      if (_.isNumber(options.lineAbs)) {
        lineAbs = options.lineAbs;
      }
      if (_.isNumber(options.lineBadg)) {
        lineBadg = options.lineBadg;
      }
      if (_.isNumber(options.lineAct)) {
        lineAct = options.lineAct;
      }
      if (_.isNumber(options.lineAst)) {
        lineAst = options.lineAst;
      }
      if (_.isNumber(options.lineInd)) {
        lineInd = options.lineInd;
      }
      if (_.isNumber(options.lineAstReg)) {
        lineAstReg = options.lineAstReg;
      }
      if (options.typeIndSecon) {
        typeIndSecon = options.typeIndSecon;
      }
      if (options.indicSecondaire) {
        indicSecondaire = options.indicSecondaire;
      }
      if (options.princBackClass) {
        princBackClass = options.princBackClass;
      }
      if (options.princBordClass) {
        princBordClass = options.princBordClass;
      }
      if (options.secBackClass) {
        secBackClass = options.secBackClass;
      }
      if (options.secBordClass) {
        secBordClass = options.secBordClass;
      }

      if (options.pretsAffSecBackClass) {
        pretsAffSecBackClass = options.pretsAffSecBackClass
      }

      if (options.pretsAffSecBordClass) {
        pretsAffSecBordClass = options.pretsAffSecBordClass
      }
    }
    //Tooltip - only when indicateur
    /*
        <b>NOM Prénom</b>, le vendredi <b>31 mai 2013</b>
        2 anomalies(s)
        3 régularisation(s)
        1 absence(s) complémentaire(s)
      */
    $titleContent = $("<div>");
    if (lang === "fr") {
      leLang = ", le ";
    }
    $firstLine = $("<span class='ui-phx-ihm-texte-donnees-important'>" + nom + " " + user?.pre + "</span>" + leLang + i18n.t('common:dayNames.' + day) + " <span class='ui-phx-ihm-texte-donnees-important'>" + date + "</span><br>");
    $secondLine.find(".phx-tooltip-second-line-text-container").css({ "vertical-align": "middle", "margin-top": "2px" });
    $secondLineIndicContainer.append(CWRENDERAGENTS.renderIndicateurPrimaire("S", princBordClass, princBackClass, false).addClass("ui-phx-icon-in-tooltip"));
    $secondLine.append($secondLineIndicContainer);
    $titleContent.append($firstLine);
    $titleContent.append($secondLine);
    //Anomalies,
    if (indToRender.val || indToRender.valb) {
      const $anoContent = CWRENDERAGENTS._renderAnoTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "deval", indicateurs)) { //IndicateurSecondaire =anomalies
        $titleContentIndSecondaire.append($anoContent);
      } else { //Indicateur Primaire
        $titleContentInd.append($anoContent);
      }
    }
    //Absences complémentaires,
    if (indToRender.abs) {
      //Shouhaits
      if (indicateurs.soud && indicateurs.soud.length > 0) {
        const $souhContent = CWRENDERAGENTS._renderSouhTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

        if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "abscomp", indicateurs)) { //IndicateurSecondaire = absences complementaire
          $titleContentAbsSecondaire.append($souhContent);
        } else { //Indicateur Primaire
          $titleContentAbs.append($souhContent);
        }
      }
      //Absences
      if (indicateurs.absd && indicateurs.absd.length > 0) {
        const $absContent = CWRENDERAGENTS._renderAbsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);
        if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "abscomp", indicateurs)) { //IndicateurSecondaire = absences complementaire
          $titleContentAbsSecondaire.append($absContent);
        } else { //Indicateur Primaire
          $titleContentAbs.append($absContent);
        }
      }

    }
    //Regularisations
    if (indToRender.reg) {
      const $regContent = CWRENDERAGENTS._renderRegulsTooltip(dtsModel, indToRender, pk, model, indicateurs, options);

      if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "regul", indicateurs)) { //IndicateurSecondaire = regularisations
        $titleContentIndSecondaire.append($regContent);
      } else { //Indicateur Primaire
        $titleContentInd.append($regContent);
      }
    }
    if (indToRender.astreg) {
      const $astregRow = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");

      if (CWDIVERS.get("AstRegul") === "1") {
        const textAstRegulCh = CWDIVERS.get("AstRegulCh");
        const astreg = "<span>" + (icons ? icons.astreg : "") + indicateurs.astreg + " " + i18n.t('plans.planresp.tooltip_regast_AstRegulCh') + textAstRegulCh + "</span>";

        $astregRow.find(".phx-render-tooltip-column1").html(astreg);
        $titleContentAstReg.append($astregRow);
      } else {
        const astreg = "<span>" + (icons ? icons.astreg : "") + indicateurs.astreg + " " + i18n.t('plans.planresp.tooltip_regast') + "</span>";

        $astregRow.find(".phx-render-tooltip-column1").html(astreg)
        $titleContentAstReg.append($astregRow);
      }
    }
    if (indToRender.mem) {
      const $memRow = $("<tr>").append("<td class='phx-render-tooltip-column1'></td><td class='phx-render-tooltip-column2'></td>");
      const mem = "<span>" + (icons ? icons.mem : "") + indicateurs.mem + " " + i18n.t('plans.planresp.tooltip_mem') + "</span>";

      $memRow.find(".phx-render-tooltip-column1").html(mem);
      $titleContentInd.append($memRow);
    }
    //Messages
    if (indToRender.mes && indicateurs.mes > 0) {
      const $mesContent = CWRENDERAGENTS._renderMesTooltip(dtsModel, indToRender, pk, model, indicateurs, options);
      if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "messages", indicateurs)) { //IndicateurSecondaire = absences complementaire
        $titleContentIndSecondaire.append($mesContent);
      } else { //Indicateur Primaire
        $titleContentInd.append($mesContent);
      }
    }
    //Badgeages
    if (indToRender.bad) {
      const $badgContent = CWRENDERAGENTS._renderBadgeagesTooltip(indToRender, pk, model, indicateurs, options);

      if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "badgeage", indicateurs)) { //IndicateurSecondaire = badgeages
        $titleContentBadgSecondaire.append($badgContent);
      } else { //Indicateur Primaire
        $titleContentBadg.append($badgContent);
      }
    }

    //Disponibilité ou indisponibilité pour prêts
    if (indToRender.pret) {
      const $dispContent = CWRENDERAGENTS._renderDispTooltip(indToRender, pk, model, indicateurs, options);

      if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "pret", indicateurs)) { //IndicateurSecondaire = disponibilites
        $titleContentIndSecondaire.append($dispContent);
      } else { //Indicateur Primaire
        $titleContentInd.append($dispContent);
      }

    }



    // //Prêts ou emprunts avec ou sans remplacement
    if (indPretAffSecToRender.rptn || indPretAffSecToRender.rpen || indPretAffSecToRender.pren || indPretAffSecToRender.prpn) {
      const $pretContent = CWRENDERAGENTS._renderPretTooltip(dtsModel, indToRender, pk, model, indicateurs, options);
      $titleIndPretAffSec.append($pretContent);
      /*if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "remplacements", indicateurs)) { //IndicateurSecondaire = prets
        $titleIndPretAffSec.append($pretContent);
      } else { //Indicateur Primaire
        $titleIndPretAffSec.append($pretContent);
      }*/
    }


    // // //Affectations secondaires
    // if (indToRender.affsecond) {
    //   const $dispContent = CWRENDERAGENTS._renderDispTooltip(indToRender, pk, model, indicateurs, options);

    //   if (CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "affsec", indicateurs)) { //IndicateurSecondaire = disponibilites
    //     $titleContentIndSecondaire.append($dispContent);
    //   } else { //Indicateur Primaire
    //     $titleContentInd.append($dispContent);
    //   }
    // }

    if (indToRender.affsec || indPretAffSecToRender.affsec) {
      const $affSecondContent = CWRENDERAGENTS._renderAffsecondTooltip(dtsModel, indToRender, pk, model, indicateurs);
      $titleIndPretAffSec.append($affSecondContent);
    }




    //Activités regroupées dans un bloc par typologie et types d’activité «prévues»/«réalisées» (voir ci-après)
    if (indToRender.act) {
      options = _.extend(options, {
        typeAcsAst: "ACT"
      });
      for (let k = 0; Array.isArray(indicateurs.typo) && k < indicateurs.typo.length; k++) {
        const typologie: { [key: string]: any } = indicateurs.typo[k];
        let $actContent = null;

        //activities
        options.typeAcsAst = "ACT";
        $actContent = CWRENDERAGENTS._renderActTooltip(dtsModel, typologie, k, indToRender, pk, model, indicateurs, options);
        if (String(typeIndSecon) === "2" && CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "act", indicateurs, activitiesFilters, viewMode) && typologie.cod === indicSecondaire) { //IndicateurSecondaire = this typologie
          $titleContentActSecondaire.append($actContent);
        } else { //Indicateur primaire
          $titleContentAct.append($actContent);
        }
        //astreintes
        options.typeAcsAst = "ASTR";
        $actContent = CWRENDERAGENTS._renderActTooltip(dtsModel, typologie, k, indToRender, pk, model, indicateurs, options);
        if (String(typeIndSecon) === "2" && CWRENDERAGENTS._checkIndicateurSecondaryOfType(indToRender, indicSecondaire, "act", indicateurs, activitiesFilters, viewMode) && typologie.cod === indicSecondaire) { //IndicateurSecondaire = this typologie
          $titleContentAstSecondaire.append($actContent);
        } else { //Indicateur primaire
          $titleContentAst.append($actContent);
        }
      }
    }
    for (let i = 0; i < nbMultiLines; i++) {
      objIndicateurs[i] = "";
      objIndicateursSecondaire[i] = "";
      objIndicateursAffSec[i] = "";
      //Primaire
      if (!CWSTR.isBlank($titleContentAbs) && !CWSTR.isBlank($titleContentAbs[0].innerHTML) && lineAbs === i) {
        objIndicateurs[i] += $titleContentAbs[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentBadg) && !CWSTR.isBlank($titleContentBadg[0].innerHTML) && lineBadg === i) {
        objIndicateurs[i] += $titleContentBadg[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentAct) && !CWSTR.isBlank($titleContentAct[0].innerHTML) && lineAct === i) {
        objIndicateurs[i] += $titleContentAct[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentAst) && !CWSTR.isBlank($titleContentAst[0].innerHTML) && lineAst === i) {
        objIndicateurs[i] += $titleContentAst[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentInd) && !CWSTR.isBlank($titleContentInd[0].innerHTML) && lineInd === i) {
        objIndicateurs[i] += $titleContentInd[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentAstReg) && !CWSTR.isBlank($titleContentAstReg[0].innerHTML) && lineAstReg === i) {
        objIndicateurs[i] += $titleContentAstReg[0].outerHTML;
      }
      if (!CWSTR.isBlank(objIndicateurs[i])) {
        objIndicateurs[i] = $titleContent[0].outerHTML + objIndicateurs[i];
      }
      //Secondaire
      if (!CWSTR.isBlank($titleContentAbsSecondaire) && !CWSTR.isBlank($titleContentAbsSecondaire[0].innerHTML) && lineAbs === i) {
        objIndicateursSecondaire[i] += $titleContentAbsSecondaire[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentBadgSecondaire) && !CWSTR.isBlank($titleContentBadgSecondaire[0].innerHTML) && lineBadg === i) {
        objIndicateursSecondaire[i] += $titleContentBadgSecondaire[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentActSecondaire) && !CWSTR.isBlank($titleContentActSecondaire[0].innerHTML) && lineAct === i) {
        objIndicateursSecondaire[i] += $titleContentActSecondaire[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentAstSecondaire) && !CWSTR.isBlank($titleContentAstSecondaire[0].innerHTML) && lineAst === i) {
        objIndicateursSecondaire[i] += $titleContentAstSecondaire[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentIndSecondaire) && !CWSTR.isBlank($titleContentIndSecondaire[0].innerHTML) && lineInd === i) {
        objIndicateursSecondaire[i] += $titleContentIndSecondaire[0].outerHTML;
      }
      if (!CWSTR.isBlank($titleContentAstRegSecondaire) && !CWSTR.isBlank($titleContentAstRegSecondaire[0].innerHTML) && lineAstReg === i) {
        objIndicateursSecondaire[i] += $titleContentAstRegSecondaire[0].outerHTML;
      }
      if (!CWSTR.isBlank(objIndicateursSecondaire[i])) {
        const $titleCloned = $titleContent.clone(); //Paint indicateur secondaire in tooltip
        const lPosTitle = $titleCloned.find(".phx-tooltip-second-line-container");

        lPosTitle.empty();
        lPosTitle.append(CWRENDERAGENTS.renderIndicateurSecondaire("S", secBordClass, secBackClass, false).addClass("ui-phx-icon-in-tooltip"));
        objIndicateursSecondaire[i] = $titleCloned[0].outerHTML + objIndicateursSecondaire[i];
      }
      //prets
      if (!CWSTR.isBlank($titleIndPretAffSec) && !CWSTR.isBlank($titleIndPretAffSec[0].innerHTML) && lineInd === i) {
        const $titleCloned = $titleContent.clone();
        const lPosTitle = $titleCloned.find(".phx-tooltip-second-line-container");

        lPosTitle.empty();
        lPosTitle.append(CWRENDERAGENTS.renderIndicateurPretsAffSec("S", pretsAffSecBordClass, pretsAffSecBackClass, false).addClass("ui-phx-pretaffsec-icon-in-tooltip"));
        objIndicateursAffSec[i] = $titleCloned[0].outerHTML + $titleIndPretAffSec[0].outerHTML;
      }
    }
    return { "objIndicateurs": objIndicateurs, "objIndicateursSecondaire": objIndicateursSecondaire, "objIndicateursAffSec": objIndicateursAffSec };
  }

  /**
   * Function that generate the HTML structure of the Horaires information for the cell.
   */
  static _renderHoraires(layers: Backbone.Model, viewMode: string, evtModel: any, dtsModel: any, pk: string, confidentielle: { [key: string]: any }, selonNature: boolean, edt: boolean, isMultiLine: boolean, lineHor: number, lineAbs: number, lineBadg: number, lineAct: number, options: { [key: string]: any }): JQuery {
    const targetDom = $("<div/>");//DOM structure
    const targetTextDom = $("<div class='phx-cell-render-text'/>");
    let conf: { [key: string]: any } = {};
    let repos = false;
    let selonNatureHor = false;
    let horaireId = null;
    const lCheckMulti = _.isBoolean(isMultiLine) ? isMultiLine : false;
    const lMemeLineAbs = (_.isNumber(lineHor) && lineHor === lineAbs) ? true : false;
    const lMemeLineBadg = (_.isNumber(lineHor) && lineHor === lineBadg) ? true : false;
    const idlg = options ? options.planningIdlg : null;
    const joursExMode = (options && options.joursExMode) ? options.joursExMode : null;
    let horaire = null;
    let horaireExc = false;
    const plagesView = layers.get("hor_pla");
    let absences, absunit1, absunit2;
    let targetRenderClass = "phx-cell-render-center"; // By default center
    const paramDiverPLaff2plfx = !CWSTR.isBlank(CWDIVERS.get("PLaff2plfx")) ? CWDIVERS.get("PLaff2plfx") : "0";
    //pour le planning de mon équipe
    let lbIsPlequipe = false;
    let lbParamDiverAnonymHor = false;

    if (!CWSTR.isBlank(confidentielle)) {
      conf = confidentielle;
    } else {
      conf.absences = false;
      conf.horaires = false;
      conf.souhaits = false;
    }
    if (_.isBoolean(selonNature)) {
      selonNatureHor = selonNature;
    }
    if (_.isBoolean(options.isPlequipe)) {
      lbIsPlequipe = options.isPlequipe;
    }
    if (_.isBoolean(options.paramDiverAnonymHor)) {
      lbParamDiverAnonymHor = options.paramDiverAnonymHor;
    }
    if (edt !== true) { //For planning use horaire realisee
      horaireId = evtModel.getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("val"); //0
    } else { //For emploi du temps use horaire theorique
      // EVO529 -> get "horaire particulier" if exist
      if (evtModel.getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("horjp")) {
        const horairePerId = evtModel.getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("horjp");

        horaireId = evtModel.getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("cod");
        if (horairePerId !== horaireId) {
          horaireId = horairePerId;
        }
      } else {
        horaireId = evtModel.getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("cod");
      }
    }
    //Only add horaire if layer activated
    horaire = _.clone(dtsModel.HORAIRES.get(horaireId));
    //An horarie is Exceptionel when val != cod
    horaireExc = (horaireId !== evtModel.getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("cod"));
    //Customer#191389: we don't need to check 'layers.get("bdg")' as it was before, checking only 'layers.get("hor_pla")' is the correct way
    //Absences info
    if ((layers.get("sou") || layers.get("abs")) && evtModel.hasEvent("ABSENCES", pk, idlg)) {
      absences = evtModel.getEvent("ABSENCES", pk, idlg).get("evt");
      if (layers.get("sou") !== undefined) {
        absunit1 = (absences.at(0) &&
          ((layers.get("sou") && absences.at(0).get("dts").sou === true) || (layers.get("abs") && absences.at(0).get("dts").sou === false))) ? absences.at(0).get("val") : undefined;
        absunit2 = (absences.at(1) &&
          ((layers.get("sou") && absences.at(1).get("dts").sou === true) || (layers.get("abs") && absences.at(1).get("dts").sou === false))) ? absences.at(1).get("val") : undefined;
      } else {
        absunit1 = (absences.at(0)) ? absences.at(0).get("val") : undefined;
        absunit2 = (absences.at(1)) ? absences.at(1).get("val") : undefined;
      }
    }
    if (horaire) {
      let semi = false;
      let absenceMatin = false;
      let absenceSoir = false;
      let targetClass = "";
      let variableHoraire = false;
      let debutNormalStyle = false;
      let finNormalStyle = false;
      const parametreDiversIndJourneeValeur = CWDIVERS.get("indJournee"); //EVO 545 paramDiver indJournee pour presence journee
      let natureTargetClass = "";
      let paintTextHor = true;
      let paintUnitTextHorPart = "";
      let lbHoraireEx = false;

      //Check Absences for Horaire Renderization
      if ((absunit1 === "J" || absunit1 === "K" || absunit1 === "N" || absunit2 === "J" || absunit2 === "K" || absunit2 === "N" ||
        (absunit1 === "M" && absunit2 === "S") || (absunit1 === "S" && absunit2 === "M")) &&
        (!lCheckMulti || (lCheckMulti && lMemeLineAbs))) {
        //Absence du Jour or 2 absences Matin & Soir
        return undefined;
      } else if ((absunit1 === "M" || absunit2 === "M") &&
        (!lCheckMulti || (lCheckMulti && lMemeLineAbs))) {
        //1 Absence Matin
        targetRenderClass = "phx-cell-render-right";
        semi = true;
        absenceMatin = true;
      } else if ((absunit1 === "S" || absunit2 === "S") &&
        (!lCheckMulti || (lCheckMulti && lMemeLineAbs))) {
        //1 Absence Soir
        targetRenderClass = "phx-cell-render-left";
        semi = true;
        absenceSoir = true;
      }
      if (selonNatureHor === true) { //Paint horaire style with nature d'horaire style
        const nat = horaire.get("nat");

        if (!CWSTR.isBlank(nat) && !CWSTR.isBlank(dtsModel.NATHOR.get(nat))) {
          natureTargetClass = dtsModel.NATHOR.get(nat).get("aff");
        }
      }
      //HORAIRE TYPE CLASS STYLE
      if (horaire.get("re") === true) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-repos" : natureTargetClass;
        repos = true;
      } else if (horaire.get("hn") === true && horaire.get("hm") === true && horaire.get("hs") === true) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hn") === true && horaire.get("hm") === true) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hn") === true && horaire.get("hs") === true) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hn") === true && horaire.get("hj") === false) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hm") === true && horaire.get("hs") === true) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hm") === true) {
        //Check Absences on Left Part (Matin)
        if (!absenceMatin || (lCheckMulti && !lMemeLineAbs)) {
          if (conf.horaires === false) {
            targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin" : natureTargetClass;
          } else {
            targetClass = "ui-phx-presence-dans-lequipe";
          }
          if (viewMode === "S") {
            targetRenderClass = "phx-cell-render-left";
            semi = true;
          }
        } else {
          return undefined;
        }
      } else if (horaire.get("hs") === true) {
        //Check Absences on Right Part (Soir)
        if (!absenceSoir || (lCheckMulti && !lMemeLineAbs)) {
          if (conf.horaires === false) {
            targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir" : natureTargetClass;
          } else {
            targetClass = "ui-phx-presence-dans-lequipe";
          }
          if (viewMode === "S") {
            targetRenderClass = "phx-cell-render-right";
            semi = true;
          }
        } else {
          return undefined;
        }
      } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === false) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === true) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (selonNatureHor === true) {
        if (conf.horaires === false) {
          targetClass = natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else {
        targetClass = "ui-phx-horaire-sans-presence";
      }
      //Add Horaire class
      targetDom.addClass(targetClass);
      if (layers.get("act") && evtModel.hasEvent("ACTIVITES", pk, idlg) && (!lCheckMulti || (lCheckMulti && lineAct === lineHor))) {
        for (let contAct = 0; contAct < evtModel.getEvent("ACTIVITES", pk, idlg).get("evt").models.length; contAct++) {
          const actCel = evtModel.getEvent("ACTIVITES", pk, idlg).get("evt").models[contAct];
          const actUni = actCel.get("dts").uni;
          const lActInfoCalque = (options && options.activitiesFilters) ? options.activitiesFilters : null;
          let actActif = true;
          const isAstreinte = (actCel.get("dts").tdo === "ASTR");

          if (actCel.get("dts") && actCel.get("dts").tpo && lActInfoCalque) {
            const lActCalque = lActInfoCalque.get(actCel.get("dts").tpo);

            if (!CWSTR.isBlank(lActCalque)) {
              if (actCel.get("dts").typ === "P") {//prevue
                actActif = lActCalque.get("prevu");
              } else {//realisée
                actActif = lActCalque.get("realise");
              }
            }
          }
          if (actActif) {
            if (paintTextHor && !isAstreinte && (actUni === "D" || actUni === "J" || actUni === "N")) {

              paintTextHor = false; //s'il y a des activités de unites "D" ou "J" ou "N et il n'y a pas d'absences, le text de l'horaire ne sera pas visible et il produira un problème avec l'option de neutraliser
            }
            if (paintTextHor && !isAstreinte && ((actUni === "M" || actUni === "N1") && horaire.get("hm") && !horaire.get("hs")) || ((actUni === "S" || actUni === "N2") && !horaire.get("hm") && horaire.get("hs"))) {
              paintTextHor = false;
            }
            if (paintTextHor && !isAstreinte && !CWSTR.isBlank(paintUnitTextHorPart) && (actUni === "M" || actUni === "S" || actUni === "N1" || actUni === "N2")) {
              let lUnitAux = "";

              if (actUni === "M" || actUni === "N1") {
                lUnitAux = "M";
              } else {
                lUnitAux = "S";
              }
              if (lUnitAux !== paintUnitTextHorPart) { //il y a des activités de matin et soir-> le texte du horaire ne se doit pas peint
                paintTextHor = false;
              }
            } else if (paintTextHor && !isAstreinte && CWSTR.isBlank(paintUnitTextHorPart) && (actUni === "M" || actUni === "S" || actUni === "N1" || actUni === "N2")) {
              if (actUni === "M" || actUni === "N1") {
                paintUnitTextHorPart = "M";
              } else {
                paintUnitTextHorPart = "S";
              }
            }
          }
        }
      }
      //Add Horaire exceptionel class (not in Edt joursExcept. mode)
      if (horaireExc && layers.get("hor_exc") && !joursExMode && paintTextHor) {
        if (!(lbIsPlequipe && lbParamDiverAnonymHor)) {
          targetTextDom.addClass("phx-dynamicStyle-cell-hor_exc");
        } else {
          lbHoraireEx = true;
        }
      }
      //Don't add content or :before classes if exist badgeages
      if ((!(layers.get("bdg") && evtModel.hasEvent("BADGEAGES", pk, idlg) && viewMode === "S") && (!lCheckMulti ||
        (lCheckMulti && lMemeLineBadg))) || (lCheckMulti && !lMemeLineBadg)) {
        const plagevbl1 = (horaire.get("ho") !== horaire.get("f1d"));
        const plagevbl2 = (horaire.get("f2f") !== horaire.get("hf"));
        const plagefixe1 = (horaire.get("f1d") !== horaire.get("f1f"));
        const plagefixe2 = (horaire.get("f2d") !== horaire.get("f2f"));
        const plagepause = (horaire.get("f1f") !== horaire.get("f2d"));

        //CONTENT TEXT
        if (viewMode === "M") {
          if (semi === false && paintTextHor && CWSTR.isBlank(paintUnitTextHorPart) && !(lbIsPlequipe && lbParamDiverAnonymHor)) {
            targetTextDom.html(horaire.get("ab2"));
          }
        } else if (viewMode === "S") {
          if (plagesView && (paramDiverPLaff2plfx === "0" || (paramDiverPLaff2plfx === "1" && semi)) && horaire.get("re") === false && (horaire.get("hm") === true || horaire.get("hs") === true || horaire.get("hn") === true || horaire.get("hj") === true)) {
            let pause = (plagepause && (plagefixe1 && plagefixe2)) ? " ... " : " - ";
            let debut, fin;

            if (absenceMatin === true) {
              //ABSENCE MATIN
              if (horaire.get("hn") === true || horaire.get("hs") === true) {
                if (plagefixe1 && plagefixe2) {
                  // 2 Plages Fixes
                  debut = horaire.get("f2d");
                  fin = horaire.get("f2f");
                } else if (plagefixe1 || plagefixe2) {
                  // 1 Plage Fixe
                  if (plagefixe1) {
                    debut = horaire.get("f1d");
                    debutNormalStyle = true;
                    fin = horaire.get("f1f");
                  } else {
                    debut = horaire.get("f2d");
                    debutNormalStyle = true;
                    fin = horaire.get("f2f");
                  }
                } else if (plagevbl1 || plagevbl2 || plagepause) {
                  // 0 Plages Fixes, 0 < Plagevble
                  debut = horaire.get("ho");
                  debutNormalStyle = true;
                  fin = horaire.get("hf");
                  variableHoraire = true;
                } else {
                  // 0 Plages Fixes, 0 Plagevble
                  debut = "";
                  fin = "";
                }
              } else {
                //White, remove the horaire class "targetClass"
                targetDom.removeClass(targetClass);
              }
              pause = undefined;
            } else if (absenceSoir === true) {
              //ABSENCE SOIR
              if (horaire.get("hn") === true || horaire.get("hm") === true) {
                if (plagefixe1 && plagefixe2) {
                  // 2 Plages Fixes
                  debut = horaire.get("f1d");
                  fin = horaire.get("f1f");
                } else if (plagefixe1 || plagefixe2) {
                  // 1 Plage Fixe
                  if (plagefixe1) {
                    debut = horaire.get("f1d");
                    fin = horaire.get("f1f");
                    finNormalStyle = true;
                  } else {
                    debut = horaire.get("f2d");
                    fin = horaire.get("f2f");
                    finNormalStyle = true;
                  }
                } else if (plagevbl1 || plagevbl2 || plagepause) {
                  // 0 Plages Fixes, 0 < Plagevble
                  debut = horaire.get("ho");
                  fin = horaire.get("hf");
                  finNormalStyle = true;
                  variableHoraire = true;
                } else {
                  // 0 Plages Fixes, 0 Plagevble
                  debut = "";
                  fin = "";
                }
              } else {
                //White, remove the horaire class "targetClass"
                targetDom.removeClass(targetClass);
              }
              pause = undefined;
            } else {
              // NO ABSENCES
              if (plagefixe1 === true && plagefixe2 === true) {
                debut = horaire.get("f1d");
                fin = horaire.get("f2f");
              } else if (plagefixe1 === true) {
                debut = horaire.get("f1d");
                fin = horaire.get("f1f");
              } else if (plagefixe2 === true) {
                debut = horaire.get("f2d");
                fin = horaire.get("f2f");
              } else {
                //No plages fixes significatives
                debut = horaire.get("ho");
                fin = horaire.get("hf");
                variableHoraire = true;
              }
            }
            if (paintTextHor && !(lbIsPlequipe && lbParamDiverAnonymHor)) {
              let lTexte = "";
              const text1 = CWTYPE.HOUR_MINUTE.format(debut);
              const text2 = CWTYPE.HOUR_MINUTE.format(fin);

              if (semi === true) { //text in 2 rows
                if (pause !== undefined && plagepause && (plagefixe1 && plagefixe2)) {
                  pause = "...";
                } else {
                  pause = "";
                }
                if (debutNormalStyle === true && paintUnitTextHorPart !== "M") {
                  lTexte = '<span style="font-weight: normal;">' + text1 + '</span>' + pause + "<br>" + text2;
                } else if (finNormalStyle === true && paintUnitTextHorPart !== "S") {
                  lTexte = CWTYPE.HOUR_MINUTE.format(debut) + pause + '<br><span style="font-weight: normal;">' + CWTYPE.HOUR_MINUTE.format(fin) + '</span>';
                } else { // all bold
                  if (!absenceMatin && !absenceSoir && (paintUnitTextHorPart === "M" || paintUnitTextHorPart === "S")) {
                    let lPos = 0;
                    let textAux = "";

                    if (targetRenderClass === "phx-cell-render-left") {
                      if (paintUnitTextHorPart === "M") {
                        lTexte = "";
                      } else {
                        lTexte = CWTYPE.HOUR_MINUTE.format(debut) + pause + "<br>" + CWTYPE.HOUR_MINUTE.format(fin);
                      }
                    } else if (targetRenderClass === "phx-cell-render-right") {
                      if (paintUnitTextHorPart === "S") {
                        lTexte = "";
                      } else {
                        lTexte = CWTYPE.HOUR_MINUTE.format(debut) + pause + "<br>" + CWTYPE.HOUR_MINUTE.format(fin);
                      }
                    } else {
                      if (paintUnitTextHorPart === "M") {
                        textAux = CWTYPE.HOUR_MINUTE.format(fin);
                        lPos = textAux.length * 2 + 4;//4 spaces en blanc de séparation, p.ex.: "_________18:00"
                        lTexte = textAux.padStart(lPos, " ");
                      } else if (paintUnitTextHorPart === "S") {
                        textAux = CWTYPE.HOUR_MINUTE.format(debut);
                        lPos = textAux.length * 2 + 4;
                        lTexte = textAux.padEnd(lPos, " ");
                      } else {
                        lTexte = CWTYPE.HOUR_MINUTE.format(debut) + pause + CWTYPE.HOUR_MINUTE.format(fin);
                      }
                    }
                    lTexte = "<span style='white-space: pre;'>" + lTexte + "</span>";
                  } else if ((absenceSoir && paintUnitTextHorPart === "M") || (absenceMatin && paintUnitTextHorPart === "S")) {
                    lTexte = "";
                  } else {
                    lTexte = CWTYPE.HOUR_MINUTE.format(debut) + pause + "<br>" + CWTYPE.HOUR_MINUTE.format(fin);
                  }
                }
              } else { //1 row text
                lTexte = text1 + pause + text2;
                if (!CWSTR.isBlank(paintUnitTextHorPart) && lTexte.length > 0) {
                  let lPos = 0;
                  let textAux = "";

                  if (paintUnitTextHorPart === "M") {
                    textAux = text2;
                    lPos = textAux.length * 2 + 4;//4 spacea en blanc de séparation, p.ex.: "_________18:00"
                    lTexte = textAux.padStart(lPos, " ");
                  } else {
                    textAux = text1;
                    lPos = textAux.length * 2 + 4;
                    lTexte = textAux.padEnd(lPos, " ");
                  }
                  lTexte = "<span style='white-space: pre;'>" + lTexte + "</span>";
                }
              }
              targetTextDom.html(lTexte);
            }
          } else if ((plagesView && paramDiverPLaff2plfx === "1" && horaire.get("re") === false && (horaire.get("hm") === true || horaire.get("hs") === true ||
            horaire.get("hn") === true || horaire.get("hj") === true)) && !(lbIsPlequipe && lbParamDiverAnonymHor)) {
            let debut1 = null, debut2 = null, fin1 = null, fin2 = null;

            if (plagefixe1 === true && plagefixe2 === true) {
              debut1 = horaire.get("f1d");
              debut2 = horaire.get("f1f");
              fin1 = horaire.get("f2d");
              fin2 = horaire.get("f2f");
            } else if (plagefixe1 === true) {
              debut1 = horaire.get("f1d");
              fin1 = horaire.get("f1f");
            } else if (plagefixe2 === true) {
              debut1 = horaire.get("f2d");
              fin1 = horaire.get("f2f");
            } else {
              //No plages fixes significatives
              debut1 = horaire.get("ho");
              fin1 = horaire.get("hf");
              variableHoraire = true;
            }
            if (paintTextHor) {
              let lTexte = "";
              const text1 = CWTYPE.HOUR_MINUTE.format(debut1);
              const text2 = CWTYPE.HOUR_MINUTE.format(fin1);

              if (!CWSTR.isBlank(paintUnitTextHorPart) && lTexte.length > 0) {
                if (paintUnitTextHorPart === "M") {
                  if (lbHoraireEx) {
                    lTexte = "<div class='phx-cell-render-right phx-dynamicStyle-cell-hor_exc cw-cell-render__deuxPlages'>";
                  } else {
                    lTexte = "<div class='phx-cell-render-right cw-cell-render__deuxPlages'>";
                  }
                  lTexte += "<span style='white-space: pre;'>" + text2 + "</span></div>";
                } else {
                  if (lbHoraireEx) {
                    lTexte = "<div class='phx-cell-render-left phx-dynamicStyle-cell-hor_exc cw-cell-render__deuxPlages'>";
                  } else {
                    lTexte = "<div class='phx-cell-render-left cw-cell-render__deuxPlages'>";
                  }
                  lTexte = "<span style='white-space: pre;'>" + text1 + "</span></div>";
                }
              } else {
                const text1f = CWTYPE.HOUR_MINUTE.format(debut2);
                const text2f = CWTYPE.HOUR_MINUTE.format(fin2);

                if (lbHoraireEx) {
                  lTexte = "<div class='phx-cell-render-left phx-dynamicStyle-cell-hor_exc cw-cell-render__deuxPlages'>" + text1;
                } else {
                  lTexte = "<div class='phx-cell-render-left cw-cell-render__deuxPlages'>" + text1;
                }
                lTexte += "<br>" + text1f + "</div>  <div class='phx-cell-render-right cw-cell-render__deuxPlages'>" + text2 + "<br>" + text2f + "</div>";
              }
              targetTextDom.html(lTexte);
            }
          } else {
            if (paintTextHor && (repos || !(lbIsPlequipe && lbParamDiverAnonymHor))) {
              let lTexte = (semi === true) ? horaire.get("ab4") : horaire.get("ab8");

              if (!CWSTR.isBlank(paintUnitTextHorPart)) {
                const lPos = lTexte.length * 2 + 6;//4 spacea en blanc de séparation, p.ex.: "________CAJ"

                if (paintUnitTextHorPart === "M") {
                  lTexte = lTexte.padStart(lPos, " ");
                } else {
                  lTexte = lTexte.padEnd(lPos, " ");
                }
                lTexte = "<span style='white-space: pre;'>" + lTexte + "</span>";
              }
              targetTextDom.html(lTexte);
            }
          }
        }
        //Variable Class
        if (variableHoraire === true) {
          if (targetTextDom.hasClass("phx-dynamicStyle-cell-hor_exc")) {
            targetTextDom.removeClass("phx-dynamicStyle-cell-hor_exc");
            targetTextDom.addClass("phx-dynamicStyle-cell-hor_excvar");
          } else if (!plagefixe1 && !plagefixe2 && plagepause || plagevbl1 || plagevbl2) {
            targetTextDom.addClass("phx-dynamicStyle-cell-hor_var");
          }
        }
      }
    } else {
      targetTextDom.html(""); //This case is when in EdT apply an a program horarire but one o more days don't have horaire.
    }
    targetDom.addClass(targetRenderClass);
    if (lbIsPlequipe && lbParamDiverAnonymHor && !repos) {
      targetDom.addClass("cwPlequipeAnonym__Hour");//"ui-phx-presence-dans-lequipe");//
    }
    if (conf.horaires === false || repos === true) {
      targetDom.append(targetTextDom);
    }
    return targetDom;
  }

  static _getHoraireClass(horaire: any, dtsModel: any, viewMode: string, selonNature: boolean, confidentielle?: { [key: string]: any }): string {
    let natureTargetClass = "";
    let targetRenderClass = "";
    let targetClass = "";
    const absenceMatin = false;
    const absenceSoir = false;
    const parametreDiversIndJourneeValeur = CWDIVERS.get("indJournee"); // EVO 545 paramDiver indJournee pour presence journee
    let conf: { [key: string]: any } = {};
    let selonNatureHor = false;

    if (!CWSTR.isBlank(confidentielle)) {
      conf = confidentielle;
    } else {
      conf.absences = false;
      conf.horaires = false;
      conf.souhaits = false;
    }
    if (_.isBoolean(selonNature)) {
      selonNatureHor = selonNature;
    }
    if (selonNatureHor === true) { //Paint horaire style with nature d'horaire style
      const nat = horaire.get("nat");

      if (!CWSTR.isBlank(nat) && !CWSTR.isBlank(dtsModel.NATHOR.get(nat))) {
        natureTargetClass = dtsModel.NATHOR.get(nat).get("aff");
      }
    }
    //HORAIRE TYPE CLASS STYLE
    if (horaire.get("re") === true) {
      targetClass = (selonNatureHor === false) ? "ui-phx-horaire-repos" : natureTargetClass;
    } else if (horaire.get("hn") === true && horaire.get("hm") === true && horaire.get("hs") === true) {
      if (conf.horaires === false) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour-nuit" : natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else if (horaire.get("hn") === true && horaire.get("hm") === true) {
      if (conf.horaires === false) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin-nuit" : natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else if (horaire.get("hn") === true && horaire.get("hs") === true) {
      if (conf.horaires === false) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir-nuit" : natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else if (horaire.get("hn") === true && horaire.get("hj") === false) {
      if (conf.horaires === false) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-nuit" : natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else if (horaire.get("hm") === true && horaire.get("hs") === true) {
      if (conf.horaires === false) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour" : natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else if (horaire.get("hm") === true) {
      //Check Absences on Left Part (Matin)
      if (!absenceMatin) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
        if (viewMode === "S") {
          targetRenderClass = "phx-cell-render-left";
        }
      }
    } else if (horaire.get("hs") === true) {
      //Check Absences on Right Part (Soir)
      if (!absenceSoir) {
        if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
        if (viewMode === "S") {
          targetRenderClass = "phx-cell-render-right";
        }
      }
    } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === false) {
      if (conf.horaires === false) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1" : natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === true) {
      if (conf.horaires === false) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1-nuit" : natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else if (selonNatureHor === true) {
      if (conf.horaires === false) {
        targetClass = natureTargetClass;
      } else {
        targetClass = "ui-phx-presence-dans-lequipe";
      }
    } else {
      targetClass = "ui-phx-horaire-sans-presence";
    }
    return targetClass.concat(" " + targetRenderClass);
  }

  /**
   * Function that generate the HTML structure of the Absences information for the cell.
   */
  static _renderAbsences(layers: Backbone.Model, viewMode: string, evtModel: any, dtsModel: any, pk: string, range: string, confidentielle: { [key: string]: any }, absTextByGroupe: boolean, filterByIds: any, edt?: boolean, simulation?: boolean, isVueComparee?: boolean, displayMultiLines?: boolean, lineAbs?: number, lineAct?: number, options?: { [key: string]: any }): Array<JQuery> {
    let conf: { [key: string]: any } = {};
    const idlg = options ? options.planningIdlg : null;
    let textByAbsGroupe = false;
    const absencesDoms: any = [];
    const absences = evtModel.getEvent("ABSENCES", pk, idlg).get("evt"); //1
    let filteredAbsences: any = [];
    let firstUniteJ = true, firstUniteK = true, firstUniteN = true, firstUniteM = true, firstUniteS = true;
    const activiteUnites: any = [];
    let newFilteredAbsencesDay: any = [];
    //pour le planning de mon équipe
    const objAnonymiser = { "isPlequipe": false, "paramDiverAnonymAbs": false };

    if (!CWSTR.isBlank(confidentielle)) {
      conf = confidentielle;
    } else {
      conf.absences = false;
      conf.horaires = false;
      conf.souhaits = false;
    }
    if (options) {
      if (_.isBoolean(options.isPlequipe)) {
        objAnonymiser.isPlequipe = options.isPlequipe;
      }
      if (_.isBoolean(options.paramDiverAnonymAbs)) {
        objAnonymiser.paramDiverAnonymAbs = options.paramDiverAnonymAbs;
      }
    }

    if (!CWSTR.isBlank(absTextByGroupe) && _.isBoolean(absTextByGroupe) && absTextByGroupe === true) {
      textByAbsGroupe = true;
    }
    //Remove the Heures absences
    for (let i = 0; i < absences.length; i++) {
      // I don't show the absences which are only to daily zoom.
      if ((absences.at(i).get("dts").zom !== false && viewMode === "M") || (absences.at(i).get("dts").zos !== false && viewMode === "S")) {
        if (absences.at(i).get("val") !== "H") {
          if (layers.get("sou") !== undefined) {
            // accessed from the planning of the agenda
            if (layers.get("abs") && absences.at(i).get("dts").sou === false) {
              filteredAbsences.push(absences.at(i));
            }
            if (layers.get("sou") && absences.at(i).get("dts").sou === true) {
              filteredAbsences.push(absences.at(i));
            }
          } else {
            // accessed from the planning
            if (layers.get("abs")) {
              if (CWSTR.isBlank(filterByIds)) {
                filteredAbsences.push(absences.at(i));
              } else {
                if (_.contains(filterByIds, absences.at(i).get("uid"))) {
                  filteredAbsences.push(absences.at(i));
                }
              }
            }
          }
        }
        if (conf && conf.absences === true && _.isEmpty(absences.at(i).get("dts")) ||
          conf && conf.souhaits === true && _.isEmpty(absences.at(i).get("dts"))) {
          filteredAbsences.push(absences.at(i));
        }
      }
    }
    //Remove evenements no validated,
    //show only absences that have been validated
    if (!CWSTR.isBlank(layers.get("evt_val")) && layers.get("evt_val") === false) {
      const newFilteredAbsences: any = [];

      for (let i = 0; i < filteredAbsences.length; i++) {
        const status = filteredAbsences[i].get("dts").sta;

        if (status === "A" || status === "H" || status === "R") {
          newFilteredAbsences.push(filteredAbsences[i]);
        }
      }
      filteredAbsences = newFilteredAbsences;
    }
    //Remove 2 absences to day,
    //show only 1 absences to the day
    if (isVueComparee !== true) {
      let firstDay = true;

      for (let i = 0; i < filteredAbsences.length; i++) {
        const day = filteredAbsences[i].get("val");

        if (day === "J" && firstDay === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstDay = false;
        } else if (day !== "J") {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
        }
      }
      filteredAbsences = newFilteredAbsencesDay;
    }
    if (filteredAbsences.length > 0) {
      if (layers.get("act") && evtModel.hasEvent("ACTIVITES", pk, idlg)) {
        if ((displayMultiLines !== true || (displayMultiLines === true && lineAct === lineAbs)) && options.isCopyPaste !== true) {
          for (let contAct = 0; contAct < evtModel.getEvent("ACTIVITES", pk, idlg).get("evt").models.length; contAct++) {
            const actCel = evtModel.getEvent("ACTIVITES", pk, idlg).get("evt").models[contAct];
            const lActInfoCalque = (options && options.activitiesFilters) ? options.activitiesFilters : null;
            let actActif = true;

            if (actCel.get("dts") && actCel.get("dts").tpo && lActInfoCalque) {
              const lActCalque = lActInfoCalque.get(actCel.get("dts").tpo);

              if (!CWSTR.isBlank(lActCalque)) {
                if (actCel.get("dts").typ === "P") {//prevue
                  actActif = lActCalque.get("prevu");
                } else {//realisée
                  actActif = lActCalque.get("realise");
                }
              }
            }
            if (actActif) {
              activiteUnites.push(actCel.get("dts").uni);
            }
          }
        }
      }
      //1º tri les absences et n'importe pas le numero d'absences parce qu'elles seront peintes par la priorité et unité s'il y a fraction disponible (de cette maniere, le numero d'absences n'est pas déjà un problème)
      filteredAbsences = CWRENDERAGENTS.sortAbsences(filteredAbsences);
      newFilteredAbsencesDay = []; //initialise
      //Remove 2 absences to day, etc    
      for (let i = 0; i < filteredAbsences.length; i++) {
        const day = filteredAbsences[i].get("val");

        if (day === "J" && firstUniteJ === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteJ = false;
        }
        if (day === "N" && firstUniteN === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteN = false;
        }
        if (day === "K" && firstUniteK === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteK = false;
        }
        if (day === "S" && firstUniteS === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteS = false;
        }
        if (day === "M" && firstUniteM === true) {
          newFilteredAbsencesDay.push(filteredAbsences[i]);
          firstUniteM = false;
        }
      }
      filteredAbsences = newFilteredAbsencesDay;
      for (let i = 0; i < filteredAbsences.length; i++) {
        absencesDoms.push(CWRENDERAGENTS._setAbsence(filteredAbsences[i], layers, viewMode, dtsModel, pk, range, conf, textByAbsGroupe, edt, activiteUnites, objAnonymiser, options));
      }
    }
    return absencesDoms;
  }

  /**
   * Function that generate the HTML structure of one Absence information for the Absences Render.
   */
  static _setAbsence(event: Backbone.Model, layers: Backbone.Model, viewMode: string, dtsModel: any, pk: string, range: string, confidentielle: { [key: string]: any }, textByAbsGroupe: boolean, edt: boolean, activiteUnites: Array<string>, objAnonymiser: { [key: string]: any }, options: { [key: string]: any }): JQuery {
    const unite = event.get("val");
    const souhait = event.get("dts").sou; // Boolean
    const sta = event.get("dts").sta; // Char
    const periode = event.get("dts").per; //Periode
    const absJSON = confidentielle.absences === false ? dtsModel.ABSENCES.get(event.get("cod")) : "";
    let signClass;
    const abUid = event.get("uid"); //Absence uid
    const boolAbsSou = (confidentielle.absences === true && souhait === false) || (confidentielle.souhaits === true && souhait === true);
    const targetTextDom = $("<div class='phx-cell-render-text'/>");
    const targetDom = $("<div/>");//DOM structure
    let paintTextAbs = true;
    let forfaitjOption = null;
    let ecran = "";

    if (!CWSTR.isBlank(options)) {
      if (!CWSTR.isBlank(options.forfaitj)) {
        forfaitjOption = options.forfaitj;
      }
      if (!CWSTR.isBlank(options.usecase)) {
        ecran = options.usecase;
      }
    }

    if (!options.isCopyPaste) {
      if (!_.isEmpty(activiteUnites) && (activiteUnites.indexOf("D") >= 0 || activiteUnites.indexOf("J") >= 0 || activiteUnites.indexOf("N") >= 0) ||
        (activiteUnites.indexOf("M") >= 0 && activiteUnites.indexOf("S") >= 0) || (activiteUnites.indexOf("N1") >= 0 && activiteUnites.indexOf("N2") >= 0)) {
        paintTextAbs = false; //s'il y a des activités de unites "D" ou "J" ou "N, le text de l'absence ne sera pas visible et il produira un problème avec l'option de neutraliser 
      }
    }
    if (unite !== "H") {
      targetDom.attr("abs-data-uid", abUid);
      switch (unite) {
        case "J":
        case "K":
        case "N":
          targetDom.addClass("phx-cell-render-center");
          if (ecran === "agenda" || ecran === "agenda_R") {
            if (!CWSTR.isBlank(forfaitjOption) && forfaitjOption.presence) {
              targetDom.css("height", "67%");
              if (forfaitjOption.typeblock === "JOUR") {
                targetDom.addClass("cw-forfaitj-cell-render-center-agenda-jour");
                targetDom.css("width", "92%");
                targetDom.css("height", "72%");
              } else if (forfaitjOption.typeblock === "MAT") {
                targetDom.addClass("cw-forfaitj-cell-render-center-agenda-mat");
              } else {
                targetDom.addClass("cw-forfaitj-cell-render-center-agenda-soir");

              }
            }
          } else {
            if (!CWSTR.isBlank(forfaitjOption) && forfaitjOption.presence) {
              if (viewMode === "S") {
                if (forfaitjOption.typeblock === "JOUR") {
                  targetDom.addClass("cw-forfaitj-cell-render-center-planning-semaine-jour");
                  if (layers.get("hor")) {
                    targetDom.css("width", "94%");
                    targetDom.css("height", "80%");
                  } else {
                    targetDom.css("width", "97%");
                    targetDom.css("height", "81%");
                    targetDom.css("margin-right", "2px");
                  }
                } else if (forfaitjOption.typeblock === "MAT") {
                  targetDom.addClass("cw-forfaitj-cell-render-center-planning-semaine-mat");
                  if (layers.get("hor")) {
                    targetDom.css("height", "82%");
                  } else {
                    targetDom.css("width", "94%");
                    targetDom.css("height", "78.4%");
                    targetDom.css("margin-top", "2px");
                    targetDom.css("margin-right", "4px");
                  }
                } else {
                  targetDom.addClass("cw-forfaitj-cell-render-center-planning-semaine-soir");
                  if (layers.get("hor")) {
                    targetDom.css("height", "82%");
                  } else {
                    targetDom.css("width", "96%");
                    targetDom.css("height", "78.4%");
                    targetDom.css("margin-top", "2px");
                    targetDom.css("margin-right", "3px");
                  }
                }
              } else {
                if (forfaitjOption.typeblock === "JOUR") {
                  targetDom.addClass("cw-forfaitj-cell-render-center-planning-mois-jour");
                  targetDom.css("width", "88%");
                  targetDom.css("height", "81%");
                } else if (forfaitjOption.typeblock === "MAT") {
                  targetDom.addClass("cw-forfaitj-cell-render-center-planning-mois-mat");
                  targetDom.css("height", "75.4%");
                  if (!layers.get("hor")) {
                    targetDom.css("width", "93%");
                    targetDom.css("margin-right", "1px");
                    targetDom.css("border-radius", "7px");
                    targetDom.css("margin-top", "3px");
                  }
                } else {
                  targetDom.css("height", "75.4%");
                  if (layers.get("hor")) {
                    targetDom.css("margin-top", "4px");
                  } else {
                    targetDom.css("width", "93%");
                    targetDom.css("margin-right", "1px");
                    targetDom.css("border-radius", "7px");
                    targetDom.css("margin-top", "3px");
                  }
                }
              }
            }
          }
          break;
        case "M":
          targetDom.addClass("phx-cell-render-left");
          if (ecran === "agenda" || ecran === "agenda_R") {
            if (!CWSTR.isBlank(forfaitjOption) && forfaitjOption.presence) {
              if (forfaitjOption.typeblock === "MAT") {
                targetDom.addClass("cw-forfaitj-cell-render-left-agenda-jour");
                targetDom.css("width", "43%");
                targetDom.css("height", "72%");
              } else if (forfaitjOption.typeblock === "SOIR") {
                targetDom.css("width", "49%");
              } else {
                targetDom.addClass("cw-forfaitj-cell-render-left-agenda");
                targetDom.css("height", "72%");
              }
            }
          } else {
            if (!CWSTR.isBlank(forfaitjOption) && forfaitjOption.presence) {
              if (viewMode === "S") {
                if (forfaitjOption.typeblock === "MAT") {
                  targetDom.addClass("cw-forfaitj-cell-render-left-planning-semaine-mat");
                  if (layers.get("hor")) {
                    targetDom.css("width", "42.5%");
                    targetDom.css("height", "78%");
                  } else {
                    targetDom.css("width", "94%");
                    targetDom.css("height", "81%");
                    targetDom.css("margin-left", "2px");
                    targetDom.css("margin-top", "2px");
                  }
                } else if (forfaitjOption.typeblock === "SOIR") {
                  targetDom.css("margin-top", "0px");
                  if (!layers.get("hor")) {
                    targetDom.css("height", "78.4%");
                    targetDom.css("margin-top", "2px");
                    targetDom.css("margin-left", "3px");
                    targetDom.css("border-radius", "7px");
                  }
                } else {
                  targetDom.addClass("cw-forfaitj-cell-render-left-planning-semaine");
                  if (layers.get("hor")) {
                    targetDom.css("height", "80.94%");
                    targetDom.css("margin-top", "4px");
                    targetDom.css("margin-left", "4px");
                  } else {
                    targetDom.css("height", "81%");
                  }
                }
              } else {
                if (forfaitjOption.typeblock === "MAT") {
                  targetDom.addClass("cw-forfaitj-cell-render-left-planning-mat");
                  targetDom.css("height", "75%");
                  if (layers.get("hor")) {
                    targetDom.css("width", "40%");
                  } else {
                    targetDom.css("width", "45%");
                  }
                } else if (forfaitjOption.typeblock === "SOIR") {
                  targetDom.addClass("cw-forfaitj-cell-render-left-planning-soir");
                  if (layers.get("hor")) {
                    targetDom.css("width", "45%");
                  } else {
                    targetDom.css("height", "86%");
                    targetDom.css("margin-top", "2px");
                    targetDom.css("border-radius", "7px");
                    targetDom.css("margin-left", "2px");
                  }
                } else {
                  targetDom.addClass("cw-forfaitj-cell-render-left-planning");
                  targetDom.css("height", "77%");
                }
              }
            }
          }
          break;
        case "S":
          targetDom.addClass("phx-cell-render-right");
          if (ecran === "agenda" || ecran === "agenda_R") {
            if (!CWSTR.isBlank(forfaitjOption) && forfaitjOption.presence) {
              if (forfaitjOption.typeblock === "MAT") {
                targetDom.addClass("cw-forfaitj-cell-render-right-agenda-mat");
                targetDom.css("width", "46%");
                targetDom.css("height", "95%");
              } else if (forfaitjOption.typeblock === "SOIR") {
                targetDom.addClass("cw-forfaitj-cell-render-right-agenda-soir");
                targetDom.css("height", "66%");
                targetDom.css("width", "43%");
              } else {
                targetDom.addClass("cw-forfaitj-cell-render-right-agenda");
                targetDom.css("height", "72%");
              }
            }
          } else {
            if (!CWSTR.isBlank(forfaitjOption) && forfaitjOption.presence) {
              if (viewMode === "S") {
                if (forfaitjOption.typeblock === "MAT") {
                  targetDom.addClass("cw-forfaitj-cell-render-right-planning-semaine-mat");
                  if (!layers.get("hor")) {
                    targetDom.css("height", "78.4%");
                    targetDom.css("margin-top", "2px");
                    targetDom.css("margin-left", "-3px");
                    targetDom.css("border-radius", "7px");
                  }
                } else if (forfaitjOption.typeblock === "SOIR") {
                  targetDom.addClass("cw-forfaitj-cell-render-right-planning-semaine-soir");
                  if (layers.get("hor")) {
                    targetDom.css("height", "77%");
                    targetDom.css("width", "43.5%");
                  } else {
                    targetDom.css("height", "81%");
                    targetDom.css("margin-top", "2px");
                    targetDom.css("margin-left", "-3px");
                  }
                } else {
                  targetDom.addClass("cw-forfaitj-cell-render-right-planning-semaine");
                  if (layers.get("hor")) {
                    targetDom.css("height", "80.94%");
                    targetDom.css("margin-top", "4px");
                    targetDom.css("margin-left", "-4px");
                  } else {
                    targetDom.css("height", "81%");
                    targetDom.css("margin-top", "2px");
                  }
                }
              } else {
                if (forfaitjOption.typeblock === "MAT") {
                  targetDom.addClass("cw-forfaitj-cell-render-right-planning-mat");
                  if (layers.get("hor")) {
                    targetDom.css("width", "47%");
                  } else {
                    targetDom.css("height", "86%");
                    targetDom.css("margin-top", "2px");
                    targetDom.css("margin-left", "-2px");
                    targetDom.css("border-radius", "7px");
                  }
                } else if (forfaitjOption.typeblock === "SOIR") {
                  targetDom.addClass("cw-forfaitj-cell-render-right-planning-soir");
                  if (layers.get("hor")) {
                    targetDom.css("height", "79%");
                    targetDom.css("width", "36%");
                  } else {
                    targetDom.css("height", "86%");
                    targetDom.css("margin-left", "-2px");
                  }
                } else {
                  targetDom.addClass("cw-forfaitj-cell-render-right-planning");
                  targetDom.css("height", "77%");
                }
              }
            }
          }
          break;
        default:
      }
      //CLASS STYLE
      if (confidentielle && confidentielle.absences && CWSTR.isBlank(absJSON) || confidentielle && confidentielle.souhaits && CWSTR.isBlank(absJSON)) {
        targetDom.addClass("ui-phx-absence-dans-lequipe");
      } else if (objAnonymiser.isPlequipe && objAnonymiser.paramDiverAnonymAbs) {
        targetDom.addClass("cwPlequipeAnonym__Abs");
      } else if (boolAbsSou === false) {
        let cod = "";

        if (!CWSTR.isBlank(absJSON) && !CWSTR.isBlank(absJSON.get("group"))) {
          cod = absJSON.get("group").get("cod");
        }
        targetDom.addClass("phx-dynamicStyle-cell-abs_" + UTILS.escapeJQueryString(cod));
      }
      //sta
      if (sta === "D" || sta === "I" || sta === "T") {
        if (souhait === true) {
          if (confidentielle.souhaits === false && !(objAnonymiser.isPlequipe && objAnonymiser.paramDiverAnonymAbs) && options.agendaAnnuelInd !== true) {//Si ce n'est pas l'agenda annuel
            // cannot comment/delete the class in CSS, because is used in other parts of app.
            //Deleted: Customer#188188 request
            /*if(showIndicateurSou){ // but in agenda Annuel. we must deactivate the "s" indicateur as SFD.*/
            if (paintTextAbs) {
              signClass = "phx-dynamicStyle-cell-absavalsou";
              /*}*/
            }
            targetDom.addClass("phx-cell-sou-aval"); //Class for options D'affichage
          }
        } else {
          if (confidentielle.absences === false && !(objAnonymiser.isPlequipe && objAnonymiser.paramDiverAnonymAbs)) {
            //Deleted: Customer#188188 request
            /*if(showIndicateurSou){*/
            if (paintTextAbs) {
              signClass = "phx-dynamicStyle-cell-absaval";
              /*}*/
            }
            targetDom.addClass("phx-cell-abs-aval"); //Class for options D'affichage
          }
        }
      } else if (sta === "A" || sta === "H") {
        if (souhait === true && options.agendaAnnuelInd !== true) {//Si ce n'est pas l'agenda annuel
          signClass = "phx-dynamicStyle-cell-abssou";
        }
      }
      // only if has value
      if (!CWSTR.isBlank(signClass)) {
        targetTextDom.addClass(signClass);
      }
      //TEXT)
      if (boolAbsSou === false && paintTextAbs && !(objAnonymiser.isPlequipe && objAnonymiser.paramDiverAnonymAbs)) {
        if (viewMode === "M") {
          if (!_.isEmpty(activiteUnites)) {
            paintTextAbs = false; //s'il y a des activités, le text de l'absence ne sera pas visible et il produira un problème avec l'option de neutraliser 
          }
          if (paintTextAbs && (unite === "J" || unite === "K" || unite === "N")) {
            let text = "";

            if (!CWSTR.isBlank(absJSON)) {
              if (textByAbsGroupe !== true) {
                text = absJSON.get("cod").substring(0, 2);
              } else {
                const grp = absJSON.get("grp");
                const abrc = dtsModel.GROUPS_ABSENCE.get(grp).get("abregecourt");

                text = !CWSTR.isBlank(abrc) ? abrc : "**";
              }
            }
            if (souhait === true && options.agendaAnnuelInd === true) {//Si c'est l'agenda annuel, et souhait
              const lEtatSou = (sta === "D" || sta === "I" || sta === "T") ? "? " : " ";

              text = lEtatSou + text;
              if (options.indicateursAgenda === true) {
                text = i18n.t("agenda.absenceso") + text;
              }
            }
            targetTextDom.html(text);
          } else if (paintTextAbs && unite !== "J" && unite !== "K" && unite !== "N" && souhait === true && options.agendaAnnuelInd === true) {
            let text = "";
            const lEtatSou = (sta === "D" || sta === "I" || sta === "T") ? "?" : "";

            //cas spécial agenda annuel, souhait et unité Matin ou Après-midi
            text = lEtatSou;
            if (options.indicateursAgenda === true) {
              text = i18n.t("agenda.absenceso") + text;
            }
            targetTextDom.html(text);
          }
        } else if (viewMode === "S") {
          let text = "";
          let paintTextAbsPart = false;
          let lPos = null;
          if (!options.isCopyPaste) {
            if (!_.isEmpty(activiteUnites) && (activiteUnites.indexOf("M") >= 0 || activiteUnites.indexOf("S") >= 0 || activiteUnites.indexOf("N1") || activiteUnites.indexOf("N2") >= 0)) {
              paintTextAbsPart = true; //s'il y a des activités de unites "M" ou "S" ou "N1" ou "N2", le text de l'absence ne sera pas visible complètement et il produira un problème avec l'option de neutraliser 
            }
          }
          if (!CWSTR.isBlank(absJSON)) {
            if (textByAbsGroupe !== true) {
              text = absJSON.get("cod").substring(0, 4);
            } else {
              const grp = absJSON.get("grp");
              const abrl = dtsModel.GROUPS_ABSENCE.get(grp).get("abregelong");

              text = !CWSTR.isBlank(abrl) ? abrl : "*******";
            }
          }
          if (paintTextAbsPart && (unite === "J" || unite === "K" || unite === "N")) {
            lPos = text.length * 2 + 4;//4 spaces en blanc de séparation, p.ex.: "______CAJ"
            if (activiteUnites.indexOf("M") >= 0 || activiteUnites.indexOf("N1")) {
              text = text.padStart(lPos, " ");
            } else {
              text = text.padEnd(lPos, " ");
            }
            //text = text.substr(0, 2);
            text = "<span style='white-space: pre;'>" + text + "</span>";
          } else if (!_.isEmpty(activiteUnites) && (((activiteUnites.indexOf("M") >= 0 || activiteUnites.indexOf("N1") >= 0) && unite === "M") ||
            ((activiteUnites.indexOf("S") >= 0 || activiteUnites.indexOf("N2") >= 0) && unite === "S"))) {
            text = ""; //s'il y a des activités de unites "M" ou "S" ou "N1" ou "N2", le text de l'absence ne sera pas visible  et il produira un problème avec l'option de neutraliser 
          }
          targetTextDom.html(text);
        }
      }
      if (periode === true && layers.get("abs_per")) {
        //ICON Période
        if (edt !== true) { //From planning or agenda
          CWRENDERAGENTS._managePeriodeAbsPlanning(viewMode, pk, event, range, targetDom, unite, dtsModel);
        } else { //From edt
          CWRENDERAGENTS._managePeriodeAbsEdt(viewMode, pk, event, range, targetDom, unite, dtsModel);
        }
      }
    }
    return targetDom.append(targetTextDom);
  }

  /**
   * Function that generate the HTMLicon for periode absences in planning
   * **/
  static _managePeriodeAbsPlanning(viewMode: string, pk: string, event: Backbone.Model, range: string, targetDom: JQuery, unite: string, dtsModel: { [key: string]: any }): void {
    const currentDate = parseInt(pk.split(",")[1], 10);
    const perDebDate = parseInt(event.get("dts").deb, 10);
    const perFinDate = parseInt(event.get("dts").fin, 10);
    const perDebUnit = event.get("dts").ude;
    const perFinUnit = event.get("dts").ufi;
    const firstRangeDate = range.split(",")[0];
    const lastRangeDate = range.split(",")[1];
    const absJSON = dtsModel.ABSENCES.get(event.get("cod"));
    let lClassSvg = "";
    let cod = "";
    const lSize = (viewMode === "S") ? 8 : 7;

    if (!CWSTR.isBlank(absJSON) && !CWSTR.isBlank(absJSON.get("group"))) {
      cod = absJSON.get("group").get("cod");
    }
    lClassSvg = "cw-ui-icon-options phx-dynamicStyle-cell-abs_" + UTILS.escapeJQueryString(cod);
    //Normal Case
    if (currentDate < perFinDate) {
      if ((perDebUnit === "J" && perFinUnit === "J") || (perDebUnit === "J" && perFinUnit === "M") || (perDebUnit === "S" && perFinUnit === "J") ||
        (perDebUnit === "S" && perFinUnit === "M") || (perDebUnit === "K" && perFinUnit === "K") || (perDebUnit === "N" && perFinUnit === "N") ||
        (perDebUnit === "N" && perFinUnit === "K") || (perDebUnit === "J" && perFinUnit === "K") || (perDebUnit === "J" && perFinUnit === "N") ||
        (perDebUnit === "S" && perFinUnit === "N") || (perDebUnit === "S" && perFinUnit === "K")) {

        if (currentDate !== perDebDate) {
          const lClass = "cw-ui-icon-absperiode-avant view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");// + periodeIconClass;

          targetDom.prepend(UTILS.getSVGIcon('marqueur_periode_s', lClass, lSize, null, false, false, false, lClassSvg));
        }
        if (String(currentDate) === lastRangeDate) {
          const lClass = "cw-ui-icon-absperiode-apres-end view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");// + periodeIconClass;

          targetDom.prepend(UTILS.getSVGIcon("marqueur_periode_s", lClass, lSize, null, false, false, false, lClassSvg));
        } else {
          const unite = (perFinDate - currentDate === 1) ? perFinUnit.toLowerCase() : event.get("val").toLowerCase();
          const lClass = "cw-ui-icon-absperiode-apres " + unite + " view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");// + " " + periodeIconClass;

          targetDom.prepend(UTILS.getSVGIcon("marqueur_periode_s", lClass, lSize, null, false, false, false, lClassSvg));
        }
      }
    } else if (currentDate === perFinDate) {
      if ((perDebUnit === "J" && perFinUnit === "J") || (perDebUnit === "J" && perFinUnit === "M") || (perDebUnit === "S" && perFinUnit === "J") ||
        (perDebUnit === "S" && perFinUnit === "M") || (perDebUnit === "K" && perFinUnit === "K") || (perDebUnit === "N" && perFinUnit === "N") ||
        (perDebUnit === "J" && perFinUnit === "K") || (perDebUnit === "N" && perFinUnit === "K") || (perDebUnit === "J" && perFinUnit === "N") ||
        (perDebUnit === "S" && perFinUnit === "N") || (perDebUnit === "S" && perFinUnit === "K")) {
        const lClass = "cw-ui-icon-absperiode-avant view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");

        targetDom.prepend(UTILS.getSVGIcon('marqueur_periode_s', lClass, lSize, null, false, false, false, lClassSvg));
      }
    }
    //First Case (on the border of the planning)
    if ((String(currentDate) === firstRangeDate) && unite !== "S") {
      if (currentDate > perDebDate && currentDate <= perFinDate) {
        const lClass = "cw-ui-icon-absperiode-avant view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");

        targetDom.prepend(UTILS.getSVGIcon('marqueur_periode_s', lClass, lSize, null, false, false, false, lClassSvg));
      }
    }
  }

  /**
   * Function that generate the HTML icon for periode absences in edt
   * **/
  static _managePeriodeAbsEdt(viewMode: string, pk: string, event: any, range: string, targetDom: JQuery, unite: string, dtsModel: { [key: string]: any }): void {
    const currentDate = parseInt(pk, 10); //numj in mode normal, date in mode simulation
    const perDebDate = parseInt(event.get("dts").deb, 10); //numj in mode normal, date in mode simulation
    const perFinDate = parseInt(event.get("dts").fin, 10); //numj in mode normal, date in mode simulation
    const perDebUnit = (currentDate && currentDate !== perFinDate) ? event.get("val") : "";
    const perFinUnit = (currentDate && currentDate === perFinDate) ? event.get("val") : "";
    const firstRangeDate = range.split(",")[0]; //numj in mode normal, date in mode simulation
    const lastRangeDate = range.split(",")[1]; //numj in mode normal, date in mode simulation
    const absJSON = dtsModel.ABSENCES.get(event.get("cod"));
    let lClassSvg = "";
    let cod = "";
    const lSize = (viewMode === "S") ? 8 : 7;

    if (!CWSTR.isBlank(absJSON) && !CWSTR.isBlank(absJSON.get("group"))) {
      cod = absJSON.get("group").get("cod");
    }
    lClassSvg = "cw-ui-icon-options phx-dynamicStyle-cell-abs_" + UTILS.escapeJQueryString(cod);
    //Normal Case
    if (currentDate < perFinDate) {
      if (perDebUnit === "J" || perDebUnit === "S" || perDebUnit === "K") {
        if (currentDate !== perDebDate) {
          const lClass = "cw-ui-icon-absperiode-avant view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");

          targetDom.prepend(UTILS.getSVGIcon('marqueur_periode_s', lClass, lSize, null, false, false, false, lClassSvg));
        }
        if (String(currentDate) === lastRangeDate) {
          const lClass = "cw-ui-icon-absperiode-apres-end view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");

          targetDom.prepend(UTILS.getSVGIcon("marqueur_periode_s", lClass, lSize, null, false, false, false, lClassSvg));
        } else {
          const lClass = "cw-ui-icon-absperiode-apres view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");

          targetDom.prepend(UTILS.getSVGIcon("marqueur_periode_s", lClass, lSize, null, false, false, false, lClassSvg));
        }
      }
    } else if (currentDate === perFinDate) {
      if (perFinUnit === "J" || perFinUnit === "M" || perFinUnit === "K") {
        const lClass = "cw-ui-icon-absperiode-avant view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");

        targetDom.prepend(UTILS.getSVGIcon('marqueur_periode_s', lClass, lSize, null, false, false, false, lClassSvg));
      }
    }
    //First Case (on the border of the planning)
    if ((String(currentDate) === firstRangeDate) && unite !== "S") {
      if (currentDate > perDebDate && currentDate <= perFinDate) {
        const lClass = "cw-ui-icon-absperiode-avant view-" + (viewMode ? viewMode.toLocaleLowerCase() : "");

        targetDom.prepend(UTILS.getSVGIcon('marqueur_periode_s', lClass, lSize, null, false, false, false, lClassSvg));
      }
    }
  }

  /**
   * Function that generate the HTML structure of the Activities information for the cell.
   */
  static _renderActivities(layers: Backbone.Model, viewMode: string, evtModel: any, dtsModel: any, pk: string, activitiesFilters: Backbone.Collection, plagesMode: boolean, edt: boolean, options: { [key: string]: any }): Array<JQuery> {
    const idlg = options ? options.planningIdlg : null;
    const activitiesDoms: any = [];
    const activities = evtModel.getEvent("ACTIVITES", pk, idlg).get("evt");
    const byTdo = activities.groupBy((model: Backbone.Model) => {
      return model.get("dts").tdo;
    });
    let sizeACT = 0,
      sizeASTR = 0;
    const evts: any = [];
    let found;
    let size = 0;
    const optionsPlequipe: { [key: string]: any } = {};

    // If there are Activities, count them.
    if (byTdo["ACT"]) {
      const typeActs = _.countBy(byTdo["ACT"], (item: Backbone.Model) => {
        return item.get("dts").typ;
      });

      if (typeActs.P > 0) {
        sizeACT++;
      }
      if (typeActs.R > 0) {
        sizeACT++;
      }
    }
    // If there are Astreintes, count them.
    if (byTdo["ASTR"]) {
      const typeAsts = _.countBy(byTdo["ASTR"], (item: Backbone.Model) => {
        return item.get("dts").typ;
      });

      if (typeAsts.P > 0) {
        sizeASTR++;
      }
      if (typeAsts.R > 0) {
        sizeASTR++;
      }
    }
    if (_.isBoolean(options.isPlequipe)) {
      optionsPlequipe.isPlequipe = options.isPlequipe;
    }
    if (_.isBoolean(options.paramDiverAnonymAct)) {
      optionsPlequipe.paramDiverAnonymAct = options.paramDiverAnonymAct;
    }
    if (!CWSTR.isBlank(options.usecase)) {
      optionsPlequipe.usecase = options.usecase;
    }
    if (activities) {
      for (let i = 0; i < activities.length; i++) {
        found = false;
        for (let j = 0; !found && j < evts.length; j++) {
          if (activities.at(i).get("dts").chevnb === evts[j].acts[0].get("dts").chevnb &&
            activities.at(i).get("dts").chevnb > 1 &&
            activities.at(i).get("dts").chevhed === evts[j].acts[0].get("dts").chevhed &&
            activities.at(i).get("dts").chevhef === evts[j].acts[0].get("dts").chevhef &&
            activities.at(i).get("dts").typ === evts[j].acts[0].get("dts").typ &&
            activities.at(i).get("dts").tdo === evts[j].acts[0].get("dts").tdo) {
            evts[j].acts.push(activities.at(i));
            found = true;
          }
        }
        if (!found) {
          const acts: any = [];

          acts.push(activities.at(i));
          evts.push({ "acts": acts });
        }
      }
    }
    size = evts.length;
    for (let i = 0; i < size; i++) {
      const dts = evts[i].acts[0].get("dts");
      let div = null;
      let canRender = true;
      let lbSelect = false;
      let lType = "";

      if (dts.zos === false && viewMode === "S") {
        canRender = false;
      } else if (dts.zom === false && viewMode === "M") {
        canRender = false;
      }
      if (layers.get("act") !== true) {
        canRender = false;
      }
      //Preferences
      lbSelect = (edt === true ? true : activitiesFilters.get(activities.at(i).get("dts").tpo).get("selected"));
      lType = (activities.at(i).get("dts").typ === "P") ? "prevu" : "realise";
      if (!(lbSelect && (edt === true || activitiesFilters.get(activities.at(i).get("dts").tpo).get(lType)))) {
        canRender = false;
      }
      if (canRender) {
        const graphisme = activities.at(i).get("dts").gph;

        if (dts.tdo === "ACT") {
          const activite = dtsModel.ACTIVITES.get(activities.at(i).get("cod"));

          if (activite) {
            div = CWRENDERAGENTS._renderActivite(dts, viewMode, activite, sizeACT, evts[i], activitiesFilters, plagesMode, graphisme, edt, optionsPlequipe);
          }
        } else {
          div = CWRENDERAGENTS._renderAstreinte(dts, sizeASTR, activitiesFilters, graphisme, edt, optionsPlequipe);
        }
      }
      activitiesDoms.push(div);
    }
    return activitiesDoms;
  }

  /**
   * Function that generate the HTML structure of the Activities information for the cell.
   */
  static _renderActivitiesMultilines(layers: Backbone.Model, viewMode: string, evtModel: any, dtsModel: any, pk: string, activitiesFilters: Backbone.Collection, plagesMode: boolean, edt: boolean, options: { [key: string]: any }): { [key: string]: any } {
    const idlg = options ? options.planningIdlg : null;
    const activities = evtModel.getEvent("ACTIVITES", pk, idlg).get("evt");
    const byTdo = activities.groupBy((model: Backbone.Model) => {
      return model.get("dts").tdo;
    });
    let sizeACT = 0,
      sizeASTR = 0;
    const lRtn: { [key: string]: any } = { "activitiesDoms": [], "astreintesDoms": [] };
    const evts = [];
    let found = false;
    let size = 0;

    // If there are Activities, count them.
    if (byTdo["ACT"]) {
      const typeActs = _.countBy(byTdo["ACT"], (item: Backbone.Model) => {
        return item.get("dts").typ;
      });

      if (typeActs.P > 0) {
        sizeACT++;
      }
      if (typeActs.R > 0) {
        sizeACT++;
      }
    }
    // If there are Astreintes, count them.
    if (byTdo["ASTR"]) {
      const typeAsts = _.countBy(byTdo["ASTR"], (item: Backbone.Model) => {
        return item.get("dts").typ;
      });

      if (typeAsts.P > 0) {
        sizeASTR++;
      }
      if (typeAsts.R > 0) {
        sizeASTR++;
      }
    }
    if (activities) {
      for (let i = 0; i < activities.length; i++) {
        found = false;
        for (let j = 0; !found && j < evts.length; j++) {
          if (activities.at(i).get("dts").chevnb === evts[j].acts[0].get("dts").chevnb &&
            activities.at(i).get("dts").chevnb > 1 &&
            activities.at(i).get("dts").chevhed === evts[j].acts[0].get("dts").chevhed &&
            activities.at(i).get("dts").chevhef === evts[j].acts[0].get("dts").chevhef &&
            activities.at(i).get("dts").typ === evts[j].acts[0].get("dts").typ &&
            activities.at(i).get("dts").tdo === evts[j].acts[0].get("dts").tdo) {
            evts[j].acts.push(activities.at(i));
            found = true;
          }
        }
        if (!found) {
          const acts = [];
          acts.push(activities.at(i));
          evts.push({ acts: acts });
        }
      }
    }
    size = evts.length;
    for (let i = 0; i < size; i++) {
      const dts = evts[i].acts[0].get("dts");
      let div = null;
      let canRender = true;
      const lbSelect = (edt === true) ? true : activitiesFilters.get(activities.at(i).get("dts").tpo).get("selected");
      const lType = (activities.at(i).get("dts").typ === "P") ? "prevu" : "realise";

      if (dts.zos === false && viewMode === "S") {
        canRender = false;
      } else if (dts.zom === false && viewMode === "M") {
        canRender = false;
      }
      if (layers.get("act") !== true) {
        canRender = false;
      }
      if (!(lbSelect && (edt === true || activitiesFilters.get(activities.at(i).get("dts").tpo).get(lType)))) {
        canRender = false;
      }
      if (canRender) {
        const graphisme = activities.at(i).get("dts").gph;

        if (dts.tdo === "ACT") {
          const activite = dtsModel.ACTIVITES.get(activities.at(i).get("cod"));

          div = CWRENDERAGENTS._renderActivite(dts, viewMode, activite, sizeACT, evts[i], activitiesFilters, plagesMode, graphisme, edt, options);
          lRtn.activitiesDoms.push(div);
        } else {
          div = CWRENDERAGENTS._renderAstreinte(dts, sizeASTR, activitiesFilters, graphisme, edt, options);
          lRtn.astreintesDoms.push(div);
        }
      }
    }
    return lRtn;
  }

  /**
   * Function that generate the HTML structure of the Activites information for the cell.
   */
  static _renderActivite(dts: any, viewMode: string, activite: Backbone.Model, size: number, overlapings: { [key: string]: any }, activitiesFilters: Backbone.Collection, plagesMode: boolean, graphisme: string, edt: boolean, optionsPlequipe?: { [key: string]: any }): JQuery {
    let jour = false,
      matin = false,
      soir = false;
    const div = $("<div>");
    let cssClass = (optionsPlequipe && optionsPlequipe.usecase === "plequipe") ? "phx-plequipe-act" : "phx-planresp-act";
    const sta = dts.sta;
    const paintPreviousSymbols = (div: JQuery, dts: any): void => {
      if (edt !== true && (_.isEmpty(optionsPlequipe) || !(optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct))) {
        // Previous symbols
        if (!CWSTR.isBlank(dts.rec)) {
          if (dts.sta !== "A" && dts.sta !== "H") {
            div.addClass("phx-dynamicStyle-cell-actrecapaval");
          } else {
            div.addClass("phx-dynamicStyle-cell-actrecap");
          }
        } else {
          if (dts.sta !== "A" && dts.sta !== "H") {
            div.addClass("phx-dynamicStyle-cell-actaval");
          }
        }
      }
    };

    if (graphisme === "M") {
      matin = true;
    } else if (graphisme === "J") {
      jour = true;
    } else if (graphisme === "S") {
      soir = true;
    }
    if (jour) {
      cssClass += "-jour";
    } else if (matin) {
      cssClass += "-matin";
    } else if (soir) {
      cssClass += "-soir";
    }
    // if there are more than one activity in the same cell
    if (size > 1 && overlapings.acts.length === 1) {
      if (dts.typ === "R") {
        cssClass += "-real";

      } else {
        cssClass += "-prev";
      }
    }
    div.addClass(cssClass);
    //sta
    if (_.isEmpty(optionsPlequipe) || !(optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct)) {
      if (sta === "D" || sta === "I" || sta === "T" || sta === "E") {
        if (dts.typ === "R") {
          div.addClass("phx-cell-act-rel-val");
        } else {
          div.addClass("phx-cell-act-prev-val");
        }
      }
    }
    div.data("act-typos", [dts.tpo]);
    if (dts.ext === false) {
      div.addClass("phx-cell-actExterieures");
    } else {
      div.addClass("phx-cell-actInterieures");
    }
    //colors activites
    if (CWSTR.isBlank(dts.fam) || dts.fam === "EMPTY" || (edt === true || activitiesFilters.get(dts.tpo).get("color") === "default")) {
      //no group activites
      if (dts.typ === "R") {
        div.addClass("ui-phx-activite-planning-inverse");
      } else {
        div.addClass("ui-phx-activite-planning");
      }
    } else {
      div.addClass("phx-dynamicStyle-cell-act_" + dts.typ + "_" + UTILS.escapeJQueryString(dts.fam));
    }
    div.addClass(((optionsPlequipe && optionsPlequipe.usecase === "plequipe") ? "phx-ui-plequipe-act-cornerall" : "phx-ui-planresp-act-cornerall"));
    if (_.isEmpty(optionsPlequipe) || !(optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct)) {
      if (dts.chevnb > 1) {
        paintPreviousSymbols(div, dts);
        div.text("(" + dts.chevnb + ")");
      } else {
        //Set text
        if (viewMode === "M" && size === 1) {
          paintPreviousSymbols(div, dts);
          if (jour) {
            div.text(activite.get("abr2"));
          }
        } else if (viewMode === "S" && (size === 1 || plagesMode === true)) {
          paintPreviousSymbols(div, dts);
          if (jour) {
            div.text(activite.get("abr8"));
          } else {
            div.text(activite.get("abr4"));
          }
        }
      }
    }
    return div;
  }

  static _renderAstreinte(dts: { [key: string]: any }, size: number, activitiesFilters: Backbone.Collection, graphisme: any, edt: any, optionsPlequipe?: { [key: string]: any }): JQuery {
    let jour, matin = false,
      soir = false,
      avantP = false,
      apresP = false;
    let noLeftMargin = false,
      noRightMargin = false;
    let div = $("<div>");
    let cssClass = (optionsPlequipe && optionsPlequipe.usecase === "plequipe") ? "phx-plequipe-ast-" : "phx-planresp-ast-";
    const sta = dts.sta;

    if (!CWSTR.isBlank(graphisme) && graphisme.length === 1) {
      switch (graphisme) {
        case "G":
          avantP = true;
          break;
        case "M":
          matin = true;
          break;
        case "J":
          jour = true;
          break;
        case "S":
          soir = true;
          break;
        case "D":
          apresP = true;
          break;
        default:
          break;
      }
    } else if (!CWSTR.isBlank(graphisme) && graphisme.length === 2) {
      if (graphisme === "GD" || graphisme === "DG") {
        avantP = apresP = true;
      } else {
        if (_.contains(graphisme, "G") === true) {
          noLeftMargin = true;
        }
        if (_.contains(graphisme, "M") === true) {
          matin = true;
        }
        if (_.contains(graphisme, "J") === true) {
          jour = true;
        }
        if (_.contains(graphisme, "D") === true) {
          noRightMargin = true;
        }
        if (_.contains(graphisme, "S") === true) {
          soir = true;
        }
      }
    } else if (!CWSTR.isBlank(graphisme) && graphisme.length > 2) { //graphisme size 3
      avantP = true;
      apresP = true;

      if (_.contains(graphisme, "J") === true) {
        jour = true;
      } else if (_.contains(graphisme, "M") === true) {
        matin = true;
      } else { //graphisme contains S
        soir = true;
      }
    } else if (CWSTR.isBlank(graphisme) && !CWSTR.isBlank(dts)) {
      switch (dts.uni) {
        case "G":
          avantP = true;
          break;
        case "M":
          matin = true;
          break;
        case "J":
          jour = true;
          break;
        case "S":
          soir = true;
          break;
        case "D":
          apresP = true;
          break;
        default:
          break;
      }
    }

    if (jour) {
      cssClass += "jour";
    } else if (matin) {
      cssClass += "matin";
    } else if (soir) {
      cssClass += "soir";
    } else if (avantP) {
      cssClass += "avantPresence";
    } else if (apresP) {
      cssClass += "apresPresence";
    }
    if (noLeftMargin === true) {
      div.addClass(((optionsPlequipe && optionsPlequipe.usecase === "plequipe") ? "phx-plequipe-ast-hav" : "phx-planresp-ast-hav"));
    }
    if (noRightMargin === true) {
      div.addClass(((optionsPlequipe && optionsPlequipe.usecase === "plequipe") ? "phx-plequipe-ast-hap" : "phx-planresp-ast-hap"));
    }
    if (size > 1) {
      if (dts.typ === "R") {
        cssClass += "-real";
      } else {
        cssClass += "-prev";
      }
    }
    div.addClass(cssClass);
    //sta
    if (_.isEmpty(optionsPlequipe) || !(optionsPlequipe.isPlequipe && optionsPlequipe.paramDiverAnonymAct)) {
      if (sta === "D" || sta === "I" || sta === "T" || sta === "E") {
        if (dts.typ === "R") {
          div.addClass("phx-cell-ast-rel-val");
        } else {
          div.addClass("phx-cell-ast-prev-val");
        }
      }
    }
    //colors activites
    if (CWSTR.isBlank(dts.fam) || dts.fam === "EMPTY" || (edt === true || activitiesFilters.get(dts.tpo).get("color") === "default")) {
      //no group activites
      if (dts.typ === "R") {
        div.addClass("ui-phx-astreinte-planning-inverse");
      } else {
        div.addClass("ui-phx-astreinte-planning");
      }
    } else {
      div.addClass("phx-dynamicStyle-cell-act_" + dts.typ + "_" + UTILS.escapeJQueryString(dts.fam));
    }
    if (avantP && apresP) {
      const div1 = div.clone();
      const div2 = div.clone();

      if (soir === false && matin === false) {
        div2[0].className = div2[0].className.replace("-avantPresence", "-apresPresence");
      } else if (matin === true) {
        div2[0].className = div2[0].className.replace("-matin", "-apresPresence");
      } else if (soir === true) {
        div1[0].className = div1[0].className.replace("-soir", "-avantPresence");
      }
      div = $("<div>").css({
        width: "100%",
        height: "100%"
      });
      div.append(div1);
      div.append(div2);
    }
    return div;
  }

  /**
   * Function that generate the HTML structure of the Badgeages information for the cell.
   */
  static _renderBadgeages(layers: { [key: string]: any }, viewMode: string, evtModel: any, dtsModel: any, pk: string, isMultiLine: boolean, confidentielle: boolean, selonNature: boolean, lineBadg: number, lineHor: number, lineAbs: number, lineAct: number, options: { [key: string]: any }): JQuery {
    const idlg = options ? options.planningIdlg : null;
    const evtBdg = evtModel.getEvent("BADGEAGES", pk, idlg).get("evt").at(0);
    const evtDts = evtBdg.get("dts");//dts : {s1, s2, e1, e2, h1, h2, nb}
    const targetDom = $("<div/>");
    let separator, badge1Text, badge2Text, semi, absences, absunit1, absunit2, bdg1hasContent, bdg2hasContent;
    //Style Constats
    const STYLE_BDG = "ui-phx-heures-badgeages-planning";
    const STYLE_BDG_MISSION = "phx-dynamicStyle-cell-bdgmiss";
    const STYLE_BDG_A_VALIDER = "phx-dynamicStyle-cell-bdgaval";
    const STYLE_BDG_MISSION_A_VALIDER = "phx-dynamicStyle-cell-bdgmissaval";
    const targetTextDom = $("<div class='phx-cell-render-text'/>");
    let targetRenderClass = "";
    let selonNatureHor = false;
    const lCheckMulti = _.isBoolean(isMultiLine) ? isMultiLine : false;
    const lMemeLineHor = (_.isNumber(lineBadg) && lineBadg === lineHor) ? true : false;
    const lMemeLineAbs = (_.isNumber(lineBadg) && lineBadg === lineAbs) ? true : false;
    const lMemeLineAct = (_.isNumber(lineBadg) && lineBadg === lineAct) ? true : false;
    const absenceMatin = false;
    const absenceSoir = false;
    let targetClass = "";
    let conf: any = {};
    let horaireId = null;
    let horaire = null;
    const badge1Html = $("<span>");
    const badge2Html = $("<span>");
    let paintTextBdg = true;
    let paintUnitTextBdgPart = "";
    //pour le planning de mon équipe
    let lbIsPlequipe = false;
    let lbParamDiverAnonymHor = false;

    //Horaires Info
    if (layers.get("hor") && evtModel.hasEvent("HORAIRES", pk, idlg)) {
      horaireId = evtModel.getEvent("HORAIRES", pk, idlg).get("evt").at(0).get("val"); //0
      horaire = _.clone(dtsModel.HORAIRES.get(horaireId));
    }
    //Absences Info
    if (layers.get("abs") && evtModel.hasEvent("ABSENCES", pk, idlg)) {
      absences = evtModel.getEvent("ABSENCES", pk, idlg).get("evt");
      absunit1 = (absences.at(0)) ? absences.at(0).get("val") : undefined;
      absunit2 = (absences.at(1)) ? absences.at(1).get("val") : undefined;
    }
    if (_.isBoolean(options.isPlequipe)) {
      lbIsPlequipe = options.isPlequipe;
    }
    if (_.isBoolean(options.paramDiverAnonymHor)) {
      lbParamDiverAnonymHor = options.paramDiverAnonymHor;
    }
    //Default
    targetRenderClass = "phx-cell-render-center";
    if (absences && lMemeLineAbs) {
      if (absences.length === 1 && (absunit1 === "M" || absunit2 === "M")) {
        // Une absence prioritaire en unité "M"
        if (horaire && !horaire.get("hs") && horaire.get("hm") && !horaire.get("hn") && !horaire.get("re")) {
          // Absence en unité Met Horaire Matin
          semi = true;
          targetRenderClass = "phx-cell-render-left";
        } else {
          //Indicateur horaire Soir, Nuit, Repos, ou aucun indicateur, ou aucun horaire
          if (horaire) {
            semi = true;
          }
          targetRenderClass = "phx-cell-render-right";
        }
      } else if (absences.length === 1 && (absunit1 === "S" || absunit2 === "S")) {
        // Une absence prioritaire en unité "S"
        if (horaire && horaire.get("hs") && !horaire.get("hm") && !horaire.get("hn") && !horaire.get("re")) {
          // Absence en unité Met Horaire Soir
          semi = true;
          targetRenderClass = "phx-cell-render-right";
        } else {
          //Indicateur horaire Soir, Nuit, Repos, ou aucun indicateur, ou aucun horaire
          if (horaire) {
            semi = true;
          }
          targetRenderClass = "phx-cell-render-left";
        }
      }
    } else if (horaire && lMemeLineHor) {
      if (!horaire.get("hs") && horaire.get("hm") && !horaire.get("hn") && !horaire.get("re")) {
        //Matin
        semi = true;
        targetRenderClass = "phx-cell-render-left";
      } else if (horaire.get("hs") && !horaire.get("hm") && !horaire.get("hn") && !horaire.get("re")) {
        //Soir
        semi = true;
        targetRenderClass = "phx-cell-render-right";
      }
    }
    if (horaire && lCheckMulti && !lMemeLineHor) {
      let natureTargetClass = "";
      const parametreDiversIndJourneeValeur = CWDIVERS.get("indJournee");// EVO 545 paramDiver indJournee pour presence journee

      if (!CWSTR.isBlank(confidentielle)) {
        conf = confidentielle;
      } else {
        conf.absences = false;
        conf.horaires = false;
        conf.souhaits = false;
      }
      if (_.isBoolean(selonNature)) {
        selonNatureHor = selonNature;
      }
      if (selonNatureHor === true) { //Paint horaire style with nature d'horaire style
        const nat = horaire.get("nat");

        if (!CWSTR.isBlank(nat) && !CWSTR.isBlank(dtsModel.NATHOR.get(nat))) {
          natureTargetClass = dtsModel.NATHOR.get(nat).get("aff");
        }
      }
      //HORAIRE TYPE CLASS STYLE
      if (horaire.get("re") === true) {
        targetClass = (selonNatureHor === false) ? "ui-phx-horaire-repos" : natureTargetClass;
      } else if (horaire.get("hn") === true && horaire.get("hm") === true && horaire.get("hs") === true) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hn") === true && horaire.get("hm") === true) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hn") === true && horaire.get("hs") === true) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hn") === true && horaire.get("hj") === false) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hm") === true && horaire.get("hs") === true) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (horaire.get("hm") === true) {
        //Check Absences on Left Part (Matin)
        if (!absenceMatin) {
          if (lbIsPlequipe && lbParamDiverAnonymHor) {
            targetClass = "cwPlequipeAnonym__Hour";
          } else if (conf.horaires === false) {
            targetClass = (selonNatureHor === false) ? "ui-phx-horaire-matin" : natureTargetClass;
          } else {
            targetClass = "ui-phx-presence-dans-lequipe";
          }
          if (viewMode === "S") {
            targetRenderClass = "phx-cell-render-left";
            semi = true;
          }
        }
      } else if (horaire.get("hs") === true) {
        //Check Absences on Right Part (Soir)
        if (!absenceSoir) {
          if (lbIsPlequipe && lbParamDiverAnonymHor) {
            targetClass = "cwPlequipeAnonym__Hour";
          } else if (conf.horaires === false) {
            targetClass = (selonNatureHor === false) ? "ui-phx-horaire-soir" : natureTargetClass;
          } else {
            targetClass = "ui-phx-presence-dans-lequipe";
          }
          if (viewMode === "S") {
            targetRenderClass = "phx-cell-render-right";
            semi = true;
          }
        }
      } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === false) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (parametreDiversIndJourneeValeur === "1" && horaire.get("hj") === true && horaire.get("hn") === true) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = (selonNatureHor === false) ? "ui-phx-horaire-jour1-nuit" : natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else if (selonNatureHor === true) {
        if (lbIsPlequipe && lbParamDiverAnonymHor) {
          targetClass = "cwPlequipeAnonym__Hour";
        } else if (conf.horaires === false) {
          targetClass = natureTargetClass;
        } else {
          targetClass = "ui-phx-presence-dans-lequipe";
        }
      } else {
        targetClass = "ui-phx-horaire-sans-presence";
      }
      //Add Horaire class
      targetDom.addClass(targetClass);
    }
    if (layers.get("act") && evtModel.hasEvent("ACTIVITES", pk, idlg) && (!lCheckMulti || (lCheckMulti && lMemeLineAct))) {
      for (let contAct = 0; contAct < evtModel.getEvent("ACTIVITES", pk, idlg).get("evt").models.length; contAct++) {
        const actCel = evtModel.getEvent("ACTIVITES", pk, idlg).get("evt").models[contAct];
        const actUni = actCel.get("dts").uni;
        const lActInfoCalque = (options && options.activitiesFilters) ? options.activitiesFilters : null;
        let actActif = true;

        if (actCel.get("dts") && actCel.get("dts").tpo && lActInfoCalque) {
          const lActCalque = lActInfoCalque.get(actCel.get("dts").tpo);

          if (!CWSTR.isBlank(lActCalque)) {
            if (actCel.get("dts").typ === "P") {//prevue
              actActif = lActCalque.get("prevu");
            } else {//realisée
              actActif = lActCalque.get("realise");
            }
          }
        }
        if (actActif) {
          if (paintTextBdg && actUni === "D" || actUni === "J" || actUni === "N") {
            paintTextBdg = false; //s'il y a des activités de unites "D" ou "J" ou "N et il n'y a pas d'absences, le text de l'horaire ne sera pas visible et il produira un problème avec l'option de neutraliser
          }
          if (paintTextBdg && ((actUni === "M" || actUni === "N1") && horaire && horaire.get("hm") && !horaire.get("hs")) || ((actUni === "S" || actUni === "N2") && horaire && !horaire.get("hm") && horaire.get("hs"))) {
            paintTextBdg = false;
          }
          if (paintTextBdg && !CWSTR.isBlank(paintUnitTextBdgPart) && (actUni === "M" || actUni === "S" || actUni === "N1" || actUni === "N2")) {
            let lUnitAux = "";

            if (actUni === "M" || actUni === "N1") {
              lUnitAux = "M";
            } else {
              lUnitAux = "S";
            }
            if (lUnitAux !== paintUnitTextBdgPart) { //il y a des activités de matin et soir-> le texte du horaire ne se doit pas peint
              paintTextBdg = false;
            }
          } else if (paintTextBdg && CWSTR.isBlank(paintUnitTextBdgPart) && (actUni === "M" || actUni === "S" || actUni === "N1" || actUni === "N2")) {
            if (actUni === "M" || actUni === "N1") {
              paintUnitTextBdgPart = "M";
            } else {
              paintUnitTextBdgPart = "S";
            }
          }
        }
      }
    }
    if (paintTextBdg && !(lbIsPlequipe && lbParamDiverAnonymHor)) {
      //Text
      badge1Text = CWTYPE.HOUR_MINUTE.format(evtDts.h1);
      badge2Text = CWTYPE.HOUR_MINUTE.format(evtDts.h2);
      bdg1hasContent = true;
      bdg2hasContent = true;
      separator = " - ";
      if (String(evtDts.nb) === "1") {
        if (_.contains(["E", "e", "R", "r"], evtDts.s1)) {
          if (semi) {
            separator = "<br>" + separator;
            badge2Text = "";
          } else {
            badge2Text = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
          }
          bdg2hasContent = false;
        } else {
          if (semi) {
            separator = separator + "<br>";
            badge1Text = "";
          } else {
            badge1Text = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
          }
          bdg1hasContent = false;
        }
      } else if (String(evtDts.nb) === "2") {
        if (semi) {
          separator = "<br>";
        }
      } else if (evtDts.nb > 2) {
        separator = (semi) ? "...<br>" : " ... ";
      }
      //mission sens with "D","R"   
      if (_.contains(["D", "d", "R", "r"], evtDts.s1)) {
        badge1Html.addClass(STYLE_BDG_MISSION);
      }
      if (_.contains(["D", "d", "R", "r"], evtDts.s2)) {
        badge2Html.addClass(STYLE_BDG_MISSION);
      }
      //validation "D","I","T" || "A","H"
      if (_.contains(["D", "I", "T"], evtDts.e1)) {
        if (badge1Html.hasClass(STYLE_BDG_MISSION)) {
          badge1Html.removeClass(STYLE_BDG_MISSION);
          badge1Html.addClass(STYLE_BDG_MISSION_A_VALIDER);
        } else {
          badge1Html.addClass(STYLE_BDG_A_VALIDER);
        }
      }
      if (_.contains(["D", "I", "T"], evtDts.e2)) {
        if (badge2Html.hasClass(STYLE_BDG_MISSION)) {
          badge2Html.removeClass(STYLE_BDG_MISSION);
          badge2Html.addClass(STYLE_BDG_MISSION_A_VALIDER);
        } else {
          badge2Html.addClass(STYLE_BDG_A_VALIDER);
        }
      }
      //Remove classes if no content
      if (!bdg1hasContent) {
        badge1Html.removeClass();
      }
      if (!bdg2hasContent) {
        badge2Html.removeClass();
      }

      if (targetRenderClass === "phx-cell-render-left") {
        if (paintUnitTextBdgPart === "M") {
          targetTextDom.html("");
        } else {
          //Fill Html with text content
          badge1Html.append(badge1Text);
          badge2Html.append(badge2Text);
          targetDom.addClass(targetRenderClass);
          targetDom.addClass(STYLE_BDG);
          targetTextDom.html($("<div>").append(badge1Html).html() + separator + $("<div>").append(badge2Html).html());
        }
      } else if (targetRenderClass === "phx-cell-render-right") {
        if (paintUnitTextBdgPart === "S") {
          targetTextDom.html("");
        } else {
          //Fill Html with text content
          badge1Html.append(badge1Text);
          badge2Html.append(badge2Text);
          targetDom.addClass(targetRenderClass);
          targetDom.addClass(STYLE_BDG);
          targetTextDom.html($("<div>").append(badge1Html).html() + separator + $("<div>").append(badge2Html).html());
        }
      } else {
        if (paintUnitTextBdgPart === "M") {
          const lPos = badge2Text.length * 2 + 4;//4 spaces en blanc de séparation, p.ex.: "_________18:00"

          badge2Text = badge2Text.padStart(lPos, " ");
          badge2Text = "<span style='white-space: pre;'>" + badge2Text + "</span>";
          badge2Html.append(badge2Text);
          targetDom.addClass(targetRenderClass);
          targetDom.addClass(STYLE_BDG);
          targetTextDom.html($("<div>").append(badge2Html).html());
        } else if (paintUnitTextBdgPart === "S") {
          const lPos = badge1Text.length * 2 + 4;//4 spaces en blanc de séparation, p.ex.: "_________18:00"

          badge1Text = badge1Text.padEnd(lPos, " ");
          badge1Text = "<span style='white-space: pre;'>" + badge1Text + "</span>";
          badge1Html.append(badge1Text);
          targetDom.addClass(targetRenderClass);
          targetDom.addClass(STYLE_BDG);
          targetTextDom.html($("<div>").append(badge1Html).html());
        } else {
          //Fill Html with text content
          badge1Html.append(badge1Text);
          badge2Html.append(badge2Text);
          targetDom.addClass(targetRenderClass);
          targetDom.addClass(STYLE_BDG);
          targetTextDom.html($("<div>").append(badge1Html).html() + separator + $("<div>").append(badge2Html).html());
        }
      }
    } else {
      targetTextDom.html("");
    }
    if (!(horaire && lCheckMulti && !lMemeLineHor) && lbIsPlequipe && lbParamDiverAnonymHor && (!horaire || (horaire && horaire.get("re") !== true))) {
      targetDom.addClass("cwPlequipeAnonym__Hour");
    }
    return targetDom.append(targetTextDom);
  }

  /**
   * Function that generate the HTML structure of the Activite information for the cell.
   */
  static renderActivityDayCell(originalHauteur: number, viewMode: string, evtModel: any, dtsModel: any, pk: string, fullPk: string, model: Backbone.Model, typAct: string, viewEl: JQuery): void {
    let hauteur = originalHauteur;
    const typologyId = fullPk.split(",")[0];
    const cellDom = $("<div class='phx-cell-render'>"); //cell DOM elements

    // Clean all styles
    viewEl.empty();
    viewEl.removeClass();
    viewEl.prop("title", "");
    viewEl.addClass("ui-phx-info-cell-style");
    if (model.get("dts").hpu === true) {
      cellDom.addClass("ui-phx-activite-non-exploitable");
    } else if (evtModel.get("COLLABS").get(pk) && evtModel.get("COLLABS").get(pk).get("evt").length > 0) {
      //If activity has both P and R, filter the ones for the current row
      if (CWSTR.isBlank(typAct) || typAct === evtModel.get("COLLABS").get(pk).get("tevt")) {
        const length = evtModel.get("COLLABS").get(pk).get("evt").length;

        for (let i = 0; i < length && hauteur > 0; i++) {
          const mat = evtModel.get("COLLABS").get(pk).get("evt").at(i).get("mat");
          const act = evtModel.get("COLLABS").get(pk).get("evt").at(i).get("cod");
          const divDom = $("<div/>");
          const partLeftDom = $("<span class='phx-planact-cell-horaire ui-phx-ihm-texte-donnees-alternatif' />");
          const partRightDom = $("<span class='phx-planact-cell-collab' />");

          if (viewMode === "M") {
            //MONTH VIEW: only initials
            partRightDom.html(dtsModel.COLLABS.get(mat).get("ini"));
          } else {
            const actData = dtsModel.ACTSAISIES.get(act);

            //WEEK VIEW
            partRightDom.html(dtsModel.COLLABS.get(mat).get("nom") + " " + dtsModel.COLLABS.get(mat).get("pre").substring(0, 1) + ".");
            //Horaire           
            switch (actData.get("mod")) {
              case "HORSPRES":
                if (actData.get("hav")) {
                  if (actData.get("hap")) {
                    partLeftDom.html(i18n.t('messages:GT_1436'));
                  } else {
                    partLeftDom.html(i18n.t('messages:GT_1434'));
                  }
                } else if (actData.get("hap")) {
                  partLeftDom.html(i18n.t('messages:GT_1435'));
                }
                break;
              case "PLH": {
                let libelleDeb = "";
                const heuredeb = actData.get("hed");

                if (heuredeb > 2400) {
                  libelleDeb = CWTYPE.HOUR_MINUTE_NIGHTLY.format(heuredeb);
                } else {
                  libelleDeb = CWTYPE.HOUR_MINUTE.format(heuredeb);
                }

                partLeftDom.html(libelleDeb + "...");
              }
                break;
              case "DUREE":
                partLeftDom.html(CWTYPE.DURATION.HOUR_MINUTE.format(actData.get("het")) + "...");
                break;
              case "UNITE":
                if (actData.get("ude") !== "D") {
                  partLeftDom.html("(" + actData.get("udeabr") + ")");
                }
                break;
              case "PERIOC": {
                let unide = actData.get("ude");
                let unifi = actData.get("ufi");

                if (unide.indexOf("U_") === 0 || unifi.indexOf("U_") === 0) {
                  unide = unide.substring(2);
                  unifi = unifi.substring(2);
                }
                if (unide !== "D") {
                  if (actData.get("deb") === model.get("dat")) {
                    partLeftDom.html("(" + actData.get("udeabr") + ")");
                  } else if (actData.get("fin") === model.get("dat")) {
                    partLeftDom.html("(" + actData.get("ufiabr") + ")");
                  } else if ((actData.get("fin") !== model.get("dat")) && (actData.get("deb") !== model.get("dat"))) {
                    partLeftDom.html("(" + dtsModel.dca + ")");
                  }
                }
              }
                break;
              case "RELIQUAT":
                partLeftDom.html(i18n.t('messages:GT_1722'));
                break;
              case "POURCENT":
                partLeftDom.html(CWTYPE.PERCENTAGE_0.format(actData.get("pct")));
                break;
            }
            if (partLeftDom.html().length > 0) {
              partLeftDom.append(" ");
            }
          }

          divDom.append(partLeftDom);
          divDom.append(partRightDom);
          cellDom.append(divDom);
          hauteur--;

          //More activities than lines
          if (length > originalHauteur) {
            const indicateurDom = $("<div class='phx-cell-render-ind'/>");
            const span = $("<span class='phx-icon' />");

            if (viewMode === "M") {
              span.addClass("cw-cercle-plein-icon cw-icon cw-icon-10 cw-cursor-pointer");
            } else if (viewMode === "S") {
              span.addClass("cw-cercle-plein-icon cw-icon cw-icon-16 cw-cursor-pointer");
            }

            const title = CWRENDERAGENTS._renderActivityIndicateurTooltip(evtModel, dtsModel, pk, typologyId, model);

            span.attr("title", title);
            indicateurDom.append(span);
            cellDom.append(indicateurDom);
          }
        }
      }
    }
    viewEl.append(cellDom);
  }

  static renderActivityTitleDayCell(viewEl: JQuery, model: Backbone.Model): void {
    const cellDom = $("<div>");

    // Clean all styles
    viewEl.empty();
    viewEl.removeClass();
    viewEl.prop("title", "");
    viewEl.addClass("ui-phx-info-cell-style");
    if (String(model.get("typ")) === "3") {
      cellDom.addClass("ui-phx-structure");
    } else {
      const myTypologie = _.find(GLOBAL_DATA.typologies.models, (typologie: { [key: string]: any }) => {
        return (String(typologie.id) === String(model.get("cod")));
      });

      if (myTypologie.get("astreinte") === true && myTypologie.get("activite") === false) {
        cellDom.addClass("ui-phx-regroupement-astreinte-planning"); //astreinte
      } else {
        cellDom.addClass("ui-phx-regroupement-activite-planning"); // activite
      }
    }
    viewEl.append(cellDom);
  }

  static _renderActivityIndicateurTooltip(evtModel: any, dtsModel: any, pk: string, typologyId: string, model: Backbone.Model): string {
    let titleContent = "";
    const tipology = GLOBAL_DATA.typologies.get(typologyId);
    const libelle = dtsModel.ACTIVITES.get(pk.split(",")[0]).get("lib");
    const code = dtsModel.ACTIVITES.get(pk.split(",")[0]).get("cod");
    const date = CWTYPE.DATE.format(model.get("dat"), CWTYPE._getFormatByCode("DATE_L"));
    let lineText = "";
    const evt = evtModel.getEvent("COLLABS", pk);
    let numColl = null;

    titleContent += "<ul style='padding-left : 1em;'>";
    titleContent += "<span style='text-transform:capitalize;'>" + tipology.get("evenement") + "</span> <b>" + libelle + " (" + code + ")</b>" + i18n.t('messages:GT_1442') + " <b>" + date + "</b><br>";
    if (evt.get("tevt") === "P") {
      if (evt.get("elt").length > 1) {
        if (tipology.get("genre") === "M") {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1572');
        } else {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1574');
        }
      } else {
        if (tipology.get("genre") === "F") {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1571');
        } else {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1573');
        }
      }
    } else if (evt.get("tevt") === "R") {
      if (evt.get("elt").length > 1) {
        if (tipology.get("genre") === "M") {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1576');
        } else {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1578');
        }
      } else {
        if (tipology.get("genre") === "F") {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1575');
        } else {
          lineText = evt.get("nbact") + " " + i18n.t('messages:GT_1577');
        }
      }
    }
    lineText += " " + i18n.t('messages:GT_1443') + " ";
    numColl = evt.get("nbcol");
    if (evt.get("nbcol") > 1) {
      lineText += numColl + " " + i18n.t('messages:GT_1441');
    } else {
      lineText += numColl + " " + i18n.t('messages:GT_1440');
    }
    titleContent += "<li>" + lineText + "</li>";
    titleContent += '</ul>';
    return titleContent;
  }

  static paintPreviousSymbols(div: JQuery, dts: { [key: string]: any }): void {
    // Previous symbols
    if (!CWSTR.isBlank(dts.rec)) {
      if (dts.sta !== "A" && dts.sta !== "H") {
        div.addClass("phx-dynamicStyle-cell-actrecapaval");
      } else {
        div.addClass("phx-dynamicStyle-cell-actrecap");
      }
    } else {
      if (dts.sta !== "A" && dts.sta !== "H") {
        div.addClass("phx-dynamicStyle-cell-actaval");
      }
    }
  }

  /**
   * Function that generate the HTML structure of the 'Memos->Notes' information for the cell.
   */
  static _renderMemos(layers: Backbone.Model, viewMode: string, evtModel: any, pk: string, options: { [key: string]: any }): JQuery {
    const idlg = options ? options.planningIdlg : null;
    const targetClass = (viewMode === "S") ? "cw-cell-render-memo-hebdo" : "cw-cell-render-memo-mois";
    const $targetDom = $("<div>").addClass(targetClass); //DOM structure
    let memoTxt = null;
    const lWidth = screen.width * .3; //30%  
    const $lDivInfobulle = $("<div>").css("max-width", lWidth);
    const lHeightIcons = (viewMode === "S") ? 10 : 8;

    if (!CWSTR.isBlank(options.forfaitj) && options.forfaitj.presence && !CWSTR.isBlank(options.forfaitj.code) && options.forfaitj !== "0") {
      $targetDom.addClass("cw-cell-render-memo-hebdo-forfaitj");
    }

    if (layers.get("mem") && evtModel.hasEvent("MEMOS", pk, idlg)) {
      const evtMemCol = evtModel.getEvent("MEMOS", pk, idlg);

      if (!CWSTR.isBlank(evtMemCol.get("evt")) && !CWSTR.isBlank(evtMemCol.get("evt").at(0))) {
        const evtMem = evtMemCol.get("evt").at(0);

        memoTxt = evtMem.get("val");
      }
    }
    if (!CWSTR.isBlank(memoTxt)) {
      $lDivInfobulle.html(memoTxt);
      $targetDom.prop("title", $lDivInfobulle[0].outerHTML);
    }
    $targetDom.append(UTILS.getSVGIcon("memo_cellule", "", lHeightIcons, null));
    return $targetDom;
  }

  static sortAbsences(listeAbsences: Array<Backbone.Model>): Array<Backbone.Model> {
    //tri 1º par la priorité et si elles sont les mêmes, par unité
    if (!_.isEmpty(listeAbsences) && listeAbsences.length > 1) {
      listeAbsences.sort((infoAbsenceA: Backbone.Model, infoAbsenceB: Backbone.Model) => {
        if (infoAbsenceA.get("dts").pri > infoAbsenceB.get("dts").pri) {
          return 1;
        } else if (infoAbsenceA.get("dts").pri < infoAbsenceB.get("dts").pri) {
          return -1;
        } else {
          //J->1, N->2, K->3, S->4, M->5
          let valUniteA = 999, valUniteB = 999;

          switch (infoAbsenceA.get("val")) {
            case "J":
              valUniteA = 1;
              break;
            case "N":
              valUniteA = 2;
              break;
            case "K":
              valUniteA = 3;
              break;
            case "S":
              valUniteA = 4;
              break;
            case "M":
              valUniteA = 5;
              break;
          }
          switch (infoAbsenceB.get("val")) {
            case "J":
              valUniteB = 1;
              break;
            case "N":
              valUniteB = 2;
              break;
            case "K":
              valUniteB = 3;
              break;
            case "S":
              valUniteB = 4;
              break;
            case "M":
              valUniteB = 5;
              break;
          }
          if (valUniteA > valUniteB) {
            return 1;
          }
          if (valUniteA < valUniteB) {
            return -1;
          }
          return 0;
        }
      });
    }
    return listeAbsences;
  }
}
