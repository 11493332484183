import { ActionBarView } from 'core/controls/actionBar.view.js';
import { BaseGridView } from '../basegrid/baseGrid.view.js';
import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { LOG } from 'utils/log.js';
import { STR } from 'utils/str.js';

export var EditableGridView = BaseGridView.extend({
  /**
   * Event launched for mode management
   */
  /**
   * Event launched for action save
   */
  /**
   * Event launched for action revert
   */
  /**
   * Add this to have Outline
   *
   */
  /**
   * DEFAULT_HEIGHT
   */
  DEFAULT_HEIGHT: 305,
  /**
   * DEFAULT_NAME
   */
  DEFAULT_NANE: "Editable",

  /**
   * Constructor
   * EditableGridView
   */
  initialize: function(options) {
    /**
     * indicate table is editable
     */
    this.editable = true;
    this.isEditableGridView = true;

    BaseGridView.prototype.initialize.call(this, options);

    //columns definition
    if (!options.model) {
      throw Error("You must define an EditableGridModel to use a EditableGridView");
    }
    /**
     * EditableGridModel
     */
    this.model = options.model;

    //Search Pk definition if exist and initializes toggle
    _.each(this.currentVue.columns, function(column) {
      if (_.has(column, "pk") && column.pk === true) {
        this.model.propertyPK.push(column.property); //eslint-disable-line
      }
    }, this);

    //edit row view definition
    if (!options.viewRow) {
      throw Error("You must define a ViewRow to use a EditableGridView");
    }
    this.viewRow = options.viewRow;
    this.viewRow.parentId = this.id;

    this.headerFixed = false;
    if (options.headerFixed) {
      this.headerFixed = true;
    }

    this.heightMax = false;
    if (options.heightMax) {
      this.heightMax = true;
    }

    // action bar
    //		this.actionBarTemplate = TPL_common_table_actionbar;

    this._showButtonBar = true;
    /**
     * ButtonBar
     */
    this.buttonBar = new ActionBarView({ iconsOnly: true, positionNewButtonsOnLeft: this.positionNewButtonsOnLeft });
    this.model.btnBarModel = this.buttonBar.model;
    this.model.btnBarModel.set("mode", this.model.get("mode"));

    var self = this;
    this.model.coll.off("add");
    this.model.coll.on("add", function(model) {

      if (model.isNew()) {
        var tbody = $("tbody", self.el);
        var rowView = new DataGridRowView({ model: model, dataGrid: self });
        if (STR.isBlank(self.rows)) {
          self.rows = [];
        }
        if (self.model.coll.pagination.startIndex === 0) {
          self.rows.push(rowView);
          tbody.prepend(rowView.render().el);
          self._scrollToIndex(0);
          model.trigger("add:model", model);

        } else {
          // If the table is not at the beginning we need to paginate to the first row.
          self.model.coll.goTo(0, function(fresh) {
            self.rows.push(rowView);
            tbody.prepend(rowView.render().el);
            self._scrollToIndex(0);
            // adds the context for the new element
            model.collection = fresh;
            // simulates adding new model to coll
            // After paginate return to the edition state
            self.model._manageRowEdition(model);
            model.trigger("add:model", model);
          });
        }

        self.manageCustomMessageVisibility(false);

        if (!STR.isBlank(self.multiselection)) {
          self._focusFirstSelectibleCell();
        } else {
          self._focusFirstCell();
        }

      }
    });

    this.listenTo(this.model, "change:mode", this._manageMode);
    this.listenTo(this.model, "action:save", this._saveRowEdition);
    this.listenTo(this.model, "action:revert", this._revertRowEdition);

    //Btn bar reset
    this.model.coll.on("reset", this._resetBtnBar, this);

    // Set Up the model
    this.model.setUp();
  },

  /**
   * Manage key press. Depending on the key pressed:
   * Down one row /down 1 page/ save changes /escape key
   */
  keyEvent: function(e) {
    var ret = BaseGridView.prototype.keyEvent.call(this, e);
    var rowInEdition = (this.model.get('mode') === "E");
    if (e.keyCode === 40 && !rowInEdition) { // down 1 row
      this._revertRowEdition();
    }
    if (e.keyCode === 34 && !rowInEdition) { // down 1 page
      this._revertRowEdition();
    }

    //if the enter button is pressed on the revert button its not a save action
    if (e.keyCode === 13 && $(e.target).hasClass("phx-cell-revert-action") === false) { // Enter key pressed
      if (rowInEdition) {
        // we are in edition mode and press enter to save changes
        this._saveRowEdition();

      } else {
        // press enter to enter edition mode
        this.model.coll.trigger("row:dblclick", this.model.get('value'));
      }
      ret = false;
    }
    if (e.keyCode === 27 || ((e.keyCode === 13 || e.keyCode === 32) && $(e.target).hasClass("phx-cell-revert-action") === true)) { // Esc key pressed or enter on the revert
      this._revertRowEdition(true);
      ret = false;

      //WCAG
      this._focusLastCell();
    }
    //return false if event was managed overwise return true
    //LOG.debug("keypressed: "+e.keyCode);
    return ret;
  },
  /**
   * Reverts row edition
   */
  _revertRowEdition: function(clickRow) {
    if (this.model.get('mode') === "E") {
      $(".phx-cell-revert-action", this.el).focus();

      this.model.btnBarModel.trigger("btn:click", "revert");
      if (this.model.coll.length === 0) {
        this.showTable(false); //on doit cacher l'entête avec les colonnes(le tableau vide)
        this.manageCustomMessageVisibility(true);
      } else {
        var model = this.model.get("value");
        if (clickRow) {
          model.trigger("row:select", model);
        }
      }
    }
  },
  /**
   * Save row edition
   */
  _saveRowEdition: function() {
    $(".phx-cell-save-action", this.el).focus();
    this.model.btnBarModel.trigger("btn:click", "save");
    this._focusLastCell();
  },
  /**
   * Render the button bar
   */
  _renderButtonBar: function() {
    if (this._showButtonBar) {
      $(".phx-grid-title-bar-action", this.el).append(this.buttonBar.render().el);
      // This is needed because when you use nested views, if you initializes the view in the constructor,
      // the delegate events is called before the dom is ready.
      this.buttonBar.delegateEvents();
    }
  },
  /**
   * Renders this view
   */
  render: function() {
    var self = this;

    if ($(this.el).children().length === 0) { // Render the Button bar and the header only one time
      $(this.el).html(this.template());
      // Header and button bar
      this._renderHead();
      this._renderButtonBar();
      if (Object.keys(this.vues).length > 1 && this.showLevierMultipleViews === true) {
        this._addButtonSwitchVues();
      }
      if (Object.keys(this.vues).length > 1 && this.showButtonsMultipleVues === true) {
        this._renderVuesButtonBar();
      }
    }

    if (this.headerFixed === true) {
      var l_height = this.height;

      if (this.heightMax === true) {
        l_height = window.innerHeight - 100 - $(this.el).find("tbody").offset().top;
      }

      $(this.el).find("tbody").parents("table").css({ "display": "table-caption" });
      $(this.el).find("tbody").parents("table").height(l_height);
    }

    $(this.el).find("tbody").attr("tableView-id", this.cid);
    //Configure scroll
    var tableScroll = $(this.el).find(".phx-grid-scroll");
    tableScroll.height(this.height);
    tableScroll.css("overflow-y", "auto");
    tableScroll.css("overflow-x", "auto");

    //Width:
    $(this.el).width(this.width);

    var tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);

      $(".phx-grid-scroll", this.el).scroll(function(event) {
        self._scrollControl(event);
        $(".phx-grid-header-scroll", self.el).scrollLeft($(this).scrollLeft()); //eslint-disable-line
      });
    }

    this.applyHabilitations();

    this.positionHeader();
    return this;
  },

  /**
   * It manages the buttons that allow to edit the values of the table
   * Copied from enabled
   */
  enableEdition: function(enable) {
    this.editable = enable && this.habilitationUpdate;

    this.model.readOnly = !this.editable;

    if (this.editable) {
      $(".phx-grid-title-bar-action", this.el).show();
      this.currentVue._columns["phx-action"].visible = true;
      this._unlockColumn("phx-action");
    } else {
      $(".phx-grid-title-bar-action", this.el).hide();
      this.currentVue._columns["phx-action"].visible = false;
      this._lockColumn("phx-action");
    }
  },

  /**
   * It manages the buttons that allow to edit the values of the table
   * when we want to show the action bar
   */
  enableTableEdition: function(enable) {
    this.editable = enable && this.habilitationUpdate;

    this.model.readOnly = !this.editable;

    if (this.editable) {
      this.currentVue._columns["phx-action"].visible = true;
      this._unlockColumn("phx-action");
    } else {
      this.currentVue._columns["phx-action"].visible = false;
      this._lockColumn("phx-action");
    }
  },

  /**
   * Mode management
   */
  _manageMode: function(model, mode) {
    var isVisible = false;

    if (mode === "E" && this.model.coll.paginated) {
      $(".phx-grid-scroll", this.el).css("overflow-y", "hidden");
    } else {
      $(".phx-grid-scroll", this.el).css("overflow-y", "auto");
    }
    if (mode === "E" || mode === "C") {
      if (mode === "C" && this.model && this.model.coll && this.model.coll.length === 0) {
        isVisible = true;
      }
      this.manageCustomMessageVisibility(isVisible);
    }
  },
  /**
   * Set button bar auto render
   */
  setButtonBarAutoRender: function(renderBtnBar) {
    this._showButtonBar = renderBtnBar;
  },
  /**
   * Reset button abr
   */
  _resetBtnBar: function() {
    this.model.set("mode", "C");
  },
  /**
   * Allow edition
   */
  allowEdition: function(allowed) {
    if (allowed) {
      this.model.coll.on("row:dblclick");
      this.currentVue._columns["phx-action"].visible = true;
    } else {
      this.model.coll.off("row:dblclick");
      this.currentVue._columns["phx-action"].visible = false;
    }
  },
  /**
   * Allow row update
   */
  allowRowUpdate: function(allowed) {
    BaseGridView.prototype._allowRowUpdate.call(this, allowed);
    this._showButtonBar = allowed;
  },
  /**
   * Disable delete button
   */
  disableDeleteButton: function(disable) {
    if (disable === true) {
      this.buttonBar.model.trigger("hide:delete");
    } else {
      this.buttonBar.model.trigger("show:delete");
    }
  },
  /**
   * Overrides a menu
   */
  overrideMenu: function(customButtonActionCallback, callingView) {
    if (customButtonActionCallback) {
      this.buttonBar.model.off("btn:click");
      this.buttonBar.model.on("btn:click", customButtonActionCallback, callingView);
    }
  },

  //Accessibility
  _focusLastCell: function() {
    this._focusCell(this.model.coll.editModeCellSelected);
  },

  //select the first selectible cell with input
  _focusFirstSelectibleCell: function() {
    var index = 0;
    var findCell = 0;

    while (findCell === 0) {
      var currentRow = this.$el.find(".phx-selected");
      var cell = currentRow.children()[index];

      if ($(cell).length > 0) {
        if ($(cell).find(":input").is(":input")) {
          // we have found the first cell with an input
          this._focusCell(index);
          findCell = 1;
        } else {
          // we haven't found a cell with an input, we loop again
          index++;
        }
      } else {
        // we haven't found any cell with an input
        findCell = 2;
        LOG.debug("no Input find");
      }
    }
  },

  _focusFirstCell: function() {
    this._focusCell(0);
  },

  _focusCell: function(index) {
    var currentRow = this.$el.find(".phx-selected");
    var cell = currentRow.children()[index];

    if ($(cell).find(":input").is(":input")) {
      $(cell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus();
    } else {
      $(cell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      LOG.debug("focus the first cell");
    }
  },
});
