module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="activitetrees_generalView" style="height:100%">\n  '+
((__t=(UTILS.getSVGIcon("chevron_droite","cw-icon--primary expandAllIcon cw-icon--expandable cw-icon--selectable cw-icon--clickable d-none")))==null?'':__t)+
'\n  '+
((__t=(UTILS.getSVGIcon("chevron_bas","cw-icon--primary expandAllIcon cw-icon--collapsable cw-icon--selectable cw-icon--clickable d-none")))==null?'':__t)+
'\n</div>\n';
}
return __p;
};
