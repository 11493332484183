import _ from 'underscore';
import TPLListerevenementsCustomBar from '../customBar.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWListerEvenementsGeneralView } from './cwListerEvenementsGeneral.view';
import { CWRadioBoxView } from 'core/views/cwRadioBox.view';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { View, ViewOptions } from 'Backbone';

export interface CWListerEvtCustomBarOptions extends ViewOptions<CWReadOnlyModel> {
  parentView?: CWListerEvenementsGeneralView;
}

export class CWListerEvtCustomBar extends View<CWReadOnlyModel> {

  private parentView: CWListerEvenementsGeneralView;
  private evtGenRadio: CWRadioBoxView;

  constructor(options?: CWListerEvtCustomBarOptions) {
    options = options ? options : {};
    options.tagName = options.tagName || "div";
    options.className = options.className || "devenements-customBar";
    options.events = _.extend({
      "click input": "_manageTypoChange"
    }, options.events);
    super(options);
    this.model = new CWBaseModel();
    this.template = TPLListerevenementsCustomBar;
    this.parentView = options.parentView;
  }

  render(): CWListerEvtCustomBar {
    this.$el.html(this.template());
    return this;
  }

  initTypoCombo(options: Array<{ [key: string]: any }>, selectedOption: string): void {
    //RadioBox
    if (options.length > 0) {
      this.evtGenRadio = new CWRadioBoxView({
        label: "",
        options: options,
        name: "evtGenRadio" + this.cid,
        required: true,
        orientation: "horizontal",
        selectedOption: selectedOption,
        addTionalClassForRadios: "cw-texteNormal",
        selectedClass: "cw-texteNormal--selectionne",
        classLabel: ""
      });
      $(this.el).find(".evtGenRadio").html(this.evtGenRadio.render().el);
      this.parentView.model.trigger("evtGenTypoChange", this.parentView.selectedTypo);
      //Hide radio buttons
      if (options.length === 1) {
        $(this.el).find(".evtGenRadio" + this.cid).parent().hide();
        $(this.el).find(".cw-customControlGroup").append($("<div><label class='cw-texteNormal--selectionne uniqueRadioButton'>" + options[0].libelle + "</label></div>"));
      }
    } else {
      this.parentView.model.trigger("evtGenTypoChange", this.parentView.selectedTypo);
    }
  }

  _manageTypoChange(event: JQueryInputEventObject): void {
    const code = (event.target as HTMLInputElement).value;

    this.parentView.form.workflow._clearArrays();
    this.parentView.form._initTable(code, true);
  }

  disableBar(state: boolean): void {
    if (state === true) {
      this.$el.find(".evtGenRadio input").prop("disabled", true);
    } else {
      this.$el.find(".evtGenRadio input").prop("disabled", false);//removeAttr("disabled");
    }
  }
}
