import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLDabsencesProgressBar from '../cwProgressBar.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWProgressBarModel } from '../models/cwProgressBar.model';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils/utils.js';

export class CWProgressBarView extends CWBaseFormView<CWProgressBarModel> {

  public workflow: CWReadOnlyModel | any;
  public hideBtnStop: boolean;
  public isBtnStop: boolean;
  initialised: boolean;
  sansInfo: boolean;

  constructor(options?: Backbone.ViewOptions<CWProgressBarModel> | any) {
    options = options || {};
    options.events = _.extend({
      "click .btnPause": "_clickListener",
      "click .btnStop": "_clickListener"
    }, options.events);
    options.model = options.model || new CWProgressBarModel();
    super(options);
    this.template = TPLDabsencesProgressBar;
    this.workflow = options.workflow || null;
    this.hideBtnStop = _.isBoolean(options.hideBtnStop) ? options.hideBtnStop : false;
    this.isBtnStop = _.isBoolean(options.isBtnStop) ? options.isBtnStop : false;
    this.sansInfo = _.isBoolean(options.sansInfo) ? options.sansInfo : false;
    this.initialised = false;
    this.on("changeProgressBar", this._changeProgressBar, this);
  }

  render(): CWProgressBarView | any {
    const json = { "i18n": i18n, "UTILS": UTILS, "isBtnStop": this.isBtnStop };

    this.$el.append(this.template(json));
    if (this.hideBtnStop) {
      this.$el.find('.btnPause').hide();
      this.$el.find('.messages').css("width", "100%");
      this.$el.find('.progress-bar').css("width", "100%");
    }
    this.$el.parent().siblings().hide();
    return this;
  }

  _changeProgressBar(treated: number, maxValue: number): void {
    const lPosPg = this.$el.find("div.progress-bar");

    if (lPosPg && lPosPg.length > 0) {
      if (!this.initialised || !lPosPg.progressbar("instance")) {
        this.model.set("max", maxValue);
        lPosPg.progressbar({ max: maxValue });//creation  
        if (!this.sansInfo) {
          this.$el.find("span.waitmessage").html(i18n.t('messages:GT_1109'));
        }
        this.initialised = true;
      }
      if (treated > maxValue) {
        treated = maxValue;
      }
      this.model.set("value", treated);
      if (lPosPg.progressbar("option", "max") !== maxValue) {
        lPosPg.progressbar("option", "max", maxValue);
      }
      if (treated <= maxValue) {
        lPosPg.progressbar("value", treated);
        if (!this.sansInfo) {
          this.$el.find("span.valueText").html(treated + "/" + maxValue);
        }
      }
    }
  }

  _clickListener(): void {
    if (this.workflow.progressBarModel) {
      this.workflow.progressBarModel.trigger("btn:click", "interrompre");
    } else {
      this.model.trigger("btn:click", "interrompre", this.$el);
    }
  }
}