import _ from 'underscore';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Model for Brique 'Infos / Memos'
 */
export class CWInfosMemosModel extends CWReadOnlyModel {

  defaults(): { [key: string]: any } {
    return {
      "index": "",
      "nbrang": "",
      "total": "",
      "message": {
        "message": "",
        "datedeb": null,
        "datefin": null,
        "accueil": null,
        "acquitte": null,
        "genere": null
      }
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/brique/message?tri=datedeb;desc,datefin;desc,code;asc";
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    let tooltip = "";
    _.each(response.message, (data: { [key: string]: any }): void => {
      if (data.datedeb !== data.datefin) {
        tooltip = i18n.t('common:binfmem.messageAfficheDu') + CWTYPE.DATE.format(data.datedeb, CWTYPE._getFormatByCode("DATE_A")) +
          i18n.t('common:binfmem.messageAfficheAu') + CWTYPE.DATE.format(data.datefin, CWTYPE._getFormatByCode("DATE_A"));
        data.tooltip = tooltip;
      }
    });
    return response;
  }
}