import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

export var SelecteurActiviteItemModel = BaseModel.extend({

  /**
   * urlRoot
   */
  urlRoot: Configuration.restRoot + "/rest/configuration",

  /**
   * Default data model
   *	code: "",
   *	libelle: ""
   *}
   */
  defaults: {
    code: "",
    libelle: "",
    datedeb: "",
    datefin: "",
    hierid: "",
    structid: "",
    itemid: ""
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
});
