import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';


export class CWDelegationsDroitsCollabModel extends CWBaseModel {

  utilCode: any;
  datefrom: any;
  queretro: boolean;
  delegation: any;
  /**
   * Start date of the droids
   */
  /**
   * Code of the delegation
   */
  /**
   * Code of the Utilisateur
   */

  /**
   * Default data model
   *		profils : []
   * }
   */
  defaults(): { [key: string]: any } {
    return {
      "profils": []
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.usecase = options.usecase || "droitsdel";
    super(attributes, options);
    if (options && options.utilCode) {
      this.utilCode = options.utilCode;
    }
    /**
    * Builds the url for the REST call
    */
    this.url = (): string => {
      let extraParams = "?delegable=true";

      if (this.datefrom) {
        extraParams += "&datefrom=" + this.datefrom;
        if (this.queretro === true) {
          extraParams += "&queretro=true";
        }
      }
      if (this.delegation) {
        extraParams += "&delegation=" + this.delegation;
      }
      return Configuration.restRoot + "/rest/delegation/" + encodeURIComponent(this.utilCode) + "/droits" + extraParams;
    };
  }
}