import * as Backbone from 'Backbone';
import TPLEditedRowSuvietat from '../editedRowSuvietat.tpl.html';
import { CWEditedRowView } from 'core/views/cwEditedRow.view';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n';

export class CWSuvietatEditedRowView extends CWEditedRowView {
  workflow: any;
  parent: any;
  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    //options.className = "c-form c-form--fixedButtons";
    super(options);
    this.workflow = options.workflow;
    this.template = TPLEditedRowSuvietat;
  }

  render(): CWSuvietatEditedRowView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));
    const model = this._getModel();

    const renderers: any = {};
    renderers["datedebut"] = (datedebut: any, model: { [key: string]: any }): string => {
      const date = CWTYPE.DATE.format(model.get("datedebut"), "Dy DD/MM/YYYY", "/");
      const hour = CWTYPE.HOUR_MINUTE_SECONDS.format(model.get("heuredebut"), CWTYPE._getFormatByCode("INSTHMS"));
      return date + " " + hour;
    };
    this._mapModelToForm(this.$el, model, renderers);
    return this;
  }

  canEdit(): boolean {
    return false;
  }

}