import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model for carperson Motif
 */
export class CWCarPersonMotifRegulModel extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/regul/composant/demande/init/";
    };
  }
}
