import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import TPLListerecapGeneralView from '../cwGeneralView.tpl.html';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWButtonBarView } from 'src/core/controls/cwButtonBar.view';
import { CWCreerRecapView } from 'common/evenements/gerer/gererrecapitulatifs/views/cwCreerRecap.view';
import { CWFilterBaseViewWithObligatoryFields } from 'src/core/components/filter/cwFilterBaseWithObligatoryFields.view';
import { CWFilterViewOptions } from 'src/core/components/filter/cwFilter.view';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListerecapFilterContentView } from './cwFilterContent.view';
import { CWListerecapFilterMoreContentView } from './cwFilterMoreCriterias.view';
import { CWListerecapWorkflow } from '../models/cwListerecap.workflow';
import { CWListerRecapFormView } from './cwListerecapForm.view';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWPanneauDeroulant } from 'src/core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWProgressBarView } from 'src/uc/common/progressbar/views/cwProgressBar.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWVoletView } from 'src/core/components/dialog/volet/cwVolet.view';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils';


interface CWListerecapGeneralOptionViewInterface extends Backbone.ViewOptions {
  context?: CWListerecapGeneralContextInterface;
  hideLinkGererRecap?: { [key: string]: any } | boolean;
  selectInSorting?: boolean;
  firstLoad?: boolean;
}

type ctxUtilisateur = "Collaborateur" | "Responsable";
export type ctxFiltreApplique = "E" | "F" | "M" | "Y" | "C" | "G" | "Z" | "A" | "D" | "I" | "H";
export type ctxTriDate = "Ascendant" | "Descendant";


/**
   * Context
   * ctxUtilisateur [Collaborateur | Responsable]
   *
   * ctxEcran
   *
   * ctxHabilitation
   * ctxHabilitation.HabilitationAcces
   * ctxHabilitation.HabilitationValidation
   *
   * ctxCollab
   * ctxCollab.Matricule
   * ctxCollab.MatriculeAux
   * ctxCollab.Nom
   * ctxCollab.Prenom
   *
   * ctxGestionCollective  [true|false]
   * ctxValidation [true|false]
   * ctxValideur [true|false]
   * ctxFiltreApplique  // Validées, Refusées, En cours, Hors contexted de worklfow
   * «Récapitulatif en cours pour le collaborateur» : Etats fonctionnels = E, F, M ou Y
   * «Récapitulatif en cours pour un responsable» : Etats fonctionnels = C, G ou Z
   * «Récapitulatifs acceptés» : Etat fonctionnel = A
   * «Récapitulatifs demandés/en cours de validation» : Etat fonctionnel = D
   * ctxPeriode
   * ctxPeriode.datedeb
   * ctxPeriode.datefin
   *
   * ctxTriDate [Ascendant | Descendant]
   *
   */
export interface CWListerecapGeneralContextInterface {
  ctxUtilisateur: ctxUtilisateur;
  ctxEcran: string;
  ctxHabilitation: {
    HabilitationAcces?: {
      absences?: string;
      recapitulatif?: string;
      prevues?: string;
      realisees?: string;
    } | string;
    HabilitationGestion?: {
      absences?: string;
      recapitulatif?: string;
      prevues?: string;
      realisees?: string;
    } | string;
    HabilitationValidation?: {
      absences?: string;
      recapitulatif?: string;
      prevues?: string;
      realisees?: string;
    } | string;
  };
  ctxCollab?: {
    matricule?: string;
    matriculeAux?: string;
    nom?: string;
    prenom?: string;
  };
  ctxGestionCollective: boolean;
  ctxValidation: boolean;
  ctxValideur: boolean;
  ctxFiltreApplique: ctxFiltreApplique[];
  ctxPeriode?: {
    datedeb: string;
    datefin: string;
  };
  ctxTriDate?: ctxTriDate;

  ctxModeRepresentation?: string;
  ctxTypeRecap?: string;
  ctxPeriodeRecap?: {
    datedeb: string;
    datefin: string;
  };
  ctxFixeRecap?: boolean;
  ctxPieceJointe?: boolean;
  usecase?: string;
}

/**
 * GeneralView of liste recapitulatifs
 */
export class CWListerecapGeneralView extends Backbone.View {
  COLLABORATEUR: string;
  RESPONSABLE: string;
  ASCENSDANT: string;
  DESCENDANT: string;
  hideLinkGererRecap: any;
  selectInSorting: boolean;
  workflow: CWListerecapWorkflow;
  context: CWListerecapGeneralContextInterface;
  form: CWListerRecapFormView;
  filter: CWFilterBaseViewWithObligatoryFields;
  btnBar: CWButtonBarView;
  dialogProgressBar: any;
  progressBarOpened: boolean;
  volet: CWVoletView<CWCreerRecapView>;

  constructor(options?: CWListerecapGeneralOptionViewInterface) {
    options = options || {} as CWListerecapGeneralOptionViewInterface;
    super(options);
    this.COLLABORATEUR = "Collaborateur";
    this.RESPONSABLE = "Responsable";

    this.ASCENSDANT = "Ascendant";
    this.DESCENDANT = "Descendant";

    if (options && options.context) {
      this.context = options.context;
    } else {
      this.context = null;
    }

    if (options && options.hideLinkGererRecap) {
      this.hideLinkGererRecap = options.hideLinkGererRecap;
    }

    this.selectInSorting = true;
    if (options && !CWSTR.isBlank(options.selectInSorting)) {
      this.selectInSorting = options.selectInSorting;
    }

    this.workflow = new CWListerecapWorkflow({ context: this.context, "usecase": ((this.context && this.context.usecase) ? this.context.usecase : ((this.context && this.context.ctxEcran) ? this.context.ctxEcran : null)), "firstLoad": options.firstLoad });

    this.template = TPLListerecapGeneralView;
    this._initializeAllViews();

  }

  /**
   * Initializes all tree views and their models
   */
  _initializeAllViews(): void {
    this.form = this._initForm();
    this.workflow.formModel = this.form.model;
    this.workflow.tableModel = this.form.table.model;
    this.form.workflow = this.workflow;

    this.filter = this._initFilter();
    this.workflow.filterModel = this.filter.model;

    this.volet = this._creationVolet();
    this.workflow.volet = this.volet;

    this.btnBar = new CWButtonBarView({
      isDisplayOnRight: true,
      isHideOnDisabled: true,
      btnOrder: {
        "save": 2,
        "revert": 1
      },
      btnClasses: {
        "new": "btn-secondary"
      }
    });
    this.workflow.btnBarModel = this.btnBar.model;

    if (this.context.ctxGestionCollective) {
      this.btnBar.deleteButton('new');
      this.btnBar.model.set('mode', 'E');
    } else {
      this.btnBar.model.set('mode', 'R');
    }
    //if (!CWHABILITATION.canView('RES_ACT_PREV.G')
    //  || !CWHABILITATION.canView('RES_ACT_REAL.G')
    //  || !CWHABILITATION.canView('RES_RECAP.G')
    //  || !CWHABILITATION.canView('RES_RECAP_CTRL')

    if (!CWHABILITATION.canView('RES_RECAP.G')
    ) {
      this.btnBar.deleteButton('new');
    }
    this.btnBar.deleteButton('delete');
    this.btnBar.deleteButton('copy');
    this.workflow.progressBarModel = this.form.model.get("progressBarModel");

    //events
    this.workflow.progressBarModel.on("change:value", this._changeProgressBar, this);
    this.workflow.on('recapCreated', this._openDetails);
    this.form.table.model.on("finish:proccess", this._validationFinnished, this);

    this.form.table.model.coll.on("updateHeader:coll", this._updateHeaderVisibility, this);

    this.listenTo(this.form.table.model, 'collReset', this.resteTitle);


    this.listenTo(this.workflow, "comboProfilsFetched", this._updateHeaderVisibility);
    //errors
    this.form.model.objErrors = this.workflow.objErrors;
    this.form.model.nObjErrors = this.workflow.nObjErrors;
  }

  /**
   * Initializes filter view
   */
  _initFilter(): CWFilterBaseViewWithObligatoryFields {
    const part1View = new CWListerecapFilterContentView({ workflow: this.workflow, context: this.context });
    const part2View = new CWListerecapFilterMoreContentView({ workflow: this.workflow, context: this.context });
    const options: CWFilterViewOptions = { id: "listerecap_filter", viewPart1: part1View, viewPart2: part2View };
    if (this.context.ctxEcran === "mactivites") {
      options.columns = 6;
    }
    const filter = new CWFilterBaseViewWithObligatoryFields(options);
    return filter;
  }

  _initForm(): CWListerRecapFormView {
    const form = new CWListerRecapFormView({ id: "listerecap_form", workflow: this.workflow, context: this.context, hideLinkGererRecap: this.hideLinkGererRecap, selectInSorting: this.selectInSorting });
    return form;
  }

  private _creationVolet(): CWVoletView<CWCreerRecapView> {
    const volet = new CWVoletView<CWCreerRecapView>({
      id: "cw-voletActDetail",
      view: CWCreerRecapView,
      viewData: {
        workflow: this.workflow,
        parent: this
      }
    });

    volet.setHeight("auto");
    volet.setWidth("auto"); //470
    return volet;
  }

  /**
   * Loads data and paints the view
   */
  render(): CWListerecapGeneralView {
    const json = {};

    $(this.el).append(this.template(json));
    $(this.el).addClass('listerecap_generalView');
    this.$el.find(".listerecap-Filtre").html(this.filter.el);
    this.$el.find(".listerecap-Btn").html(this.btnBar.el);
    this.$el.append(this.form.el);

    if (this.context) {
      this.setContext(this.context, () => {
        this.form.render();

        this.filter.render();
        this.btnBar.render();
        if (!this.context.ctxGestionCollective && this.context.ctxValidation) {
          this.btnBar.model.set("mode", "C");
        } else {
          this.btnBar.model.set("mode", "E");
        }
        this.btnBar.changeButtonLabel("save", i18n.t('dabsences.confirmer'));
        this.btnBar.changeButtonLabel("revert", i18n.t('dabsences.abandonner'));
        this.btnBar.$el.find(".save").addClass("btn-withIcon").append(UTILS.getSVGIcon("valider"));
        this.btnBar.$el.find(".revert").removeClass("btn-primary").addClass("btn-withIcon btn-secondary").append(UTILS.getSVGIcon("croix"));
        if (this.context.ctxEcran === "suivicollab") {
          this.btnBar.detachButton('new', $('.cw-buttonBarRecap', $(this.el)));
        } else {
          this.$el.find(".cw-fieldset__situationPanel__title").remove();
        }
        this.resteTitle();
      });
    }

    return this;
  }

  /**
   * Sets the context and rerenders complete view
   */
  setContext(context: CWListerecapGeneralContextInterface, callbackInit?: () => void, refreshTable?: boolean): void {
    this.context = context;
    this.form.table.model.coll.setSortings(context.ctxTriDate);

    const callback = (): void => {
      const lFoncCour: { [key: string]: any } = {};

      if (this.workflow) {
        if (!_.isEmpty(this.workflow.context.ctxHabilitation.HabilitationAcces)) {
          lFoncCour.acces = this.workflow.context.ctxHabilitation.HabilitationAcces;
        }
        if (!_.isEmpty(this.workflow.context.ctxHabilitation.HabilitationGestion)) {
          lFoncCour.gestion = this.workflow.context.ctxHabilitation.HabilitationGestion;
        }
        if (!_.isEmpty(this.workflow.context.ctxHabilitation.HabilitationValidation)) {
          lFoncCour.validation = this.workflow.context.ctxHabilitation.HabilitationValidation;
        }
      } else if (this.context) {
        if (!_.isEmpty(this.context.ctxHabilitation.HabilitationAcces)) {
          lFoncCour.acces = this.context.ctxHabilitation.HabilitationAcces;
        }
        if (!_.isEmpty(this.context.ctxHabilitation.HabilitationGestion)) {
          lFoncCour.gestion = this.context.ctxHabilitation.HabilitationGestion;
        }
        if (!_.isEmpty(this.context.ctxHabilitation.HabilitationValidation)) {
          lFoncCour.validation = this.context.ctxHabilitation.HabilitationValidation;
        }
      }
      //Set fomr habilitation
      if (this.form) {
        this.form.model.setHabContext(new CWHabilitationContext({
          onglet: context.ctxEcran,
          foncCour: lFoncCour
        }));
      }
      this._hideCollabFilter();
      this._hideHeaderText();
      this._hideBtnBar();
      //Hide button bar if validation is not allowed
      if (!CWSTR.isUndefined(this.context.ctxValidation) && !this.context.ctxValidation) {
        this.$el.find(".listerecap-Btn .phx-button-bar").hide();
      } else {
        this.$el.find(".listerecap-Btn .phx-button-bar").show();
      }
      if (this.form) {
        this.form.resetAfterContextChange();
        this.form.table.model.trigger("change:currentVue", i18n.t('common:listerecap.vue_synt'));
      }
      if (callbackInit) {
        callbackInit();
      }
      if (this.workflow) {
        this.workflow.trigger("contextchanged", context);
      }
    };
    this.workflow.setContext(context, callback, refreshTable);
  }

  resteTitle(): void {
    if (this.context && this.context.ctxEcran === "suivicollab") {
      const title = i18n.t('suivicollab.menuRecapActivite') + '<span class="cw-texteSuperAllege"> (' + this.form.table.model.coll.totalRecords + ') </span>';

      this.$el.find(".cw-fieldset__situationPanel__title .cw-titre").html(title);
    }
  }

  _updateHeaderVisibility(): void {
    const numberOfRoles = this.workflow.roleCollection ? this.workflow.roleCollection.length : 0;

    if (!this.workflow.showProfils || numberOfRoles <= 1 || this.context.ctxUtilisateur === this.workflow.COLLABORATEUR || this.context.ctxValidation !== true) {
      this.form.table.model.trigger("lock:column", "profils");
      $(this.el).find(".roleFilter").hide();
    } else {
      this.form.table.model.trigger("unlock:column", "profils");
      $(this.el).find(".roleFilter").show();
    }
  }

  _hideCollabFilter(): void {
    if (this.filter) {
      if (this.context && this.context.ctxGestionCollective === false) {
        this.filter.$el.find(".collabData").hide();
      } else {
        this.filter.$el.find(".collabData").show();
      }
    }
  }

  _hideHeaderText(): void {
    if (this.context && this.context.ctxGestionCollective === true &&
      this.context.ctxValidation === true) {
      this.$el.find(".listerecap-Third-Zone").show();
      this.$el.find(".listerecap-Third-Zone").css("height", "auto");
    } else {
      this.$el.find(".listerecap-Third-Zone").hide();
      this.$el.find(".listerecap-Third-Zone").css("height", "0px");
    }
  }

  _hideBtnBar(): void {
    if (this.context && ((this.workflow && this.context.ctxUtilisateur === this.workflow.COLLABORATEUR) || this.context.ctxValidation === false)) {
      this.$el.find(".listerecap-Btn").hide();
    } else {
      this.$el.find(".listerecap-Btn").show();
    }
  }

  _changeProgressBar(model: CWBaseModel, treated: number): void {
    const maxValue = model.get("max");


    if (this.dialogProgressBar === undefined) {
      this.dialogProgressBar = new CWPanneauDeroulant({
        view: CWProgressBarView,
        viewData: {
          appendTo: this.el,
          workflow: this.workflow,
        },
        notIconClose: true,
        notTitle: true,
        closeOnClickOutside: false
      });
    }

    this.dialogProgressBar.setHeight("auto");
    this.dialogProgressBar.setWidth(515);
    if (this.progressBarOpened === false) {
      this.progressBarOpened = true;
      this.dialogProgressBar.model.trigger("dlg:open");
    }
    if (!CWSTR.isBlank(this.dialogProgressBar.getInternalViewObject())) {
      this.dialogProgressBar.getInternalViewObject().trigger('changeProgressBar', treated, maxValue);
    }
  }

  _validationFinnished(): void {
    this.form.table.model.coll.nbrecEtatValid = 0;
    this.form.table.model.coll.nbrecEtatA = 0;
    this.form.table.model.coll.nbrecEtatR = 0;
    //launch the popup with the resume
    this.form._initPopupResumeView(this.form.model.nObjErrors);

    this.form.table.model.coll.fetch({
      success: (fresh: CWPaginatedCollection): void => {
        if (!CWSTR.isBlank(this.workflow.tableModel.coll.params) && !CWSTR.isBlank(this.workflow.tableModel.coll.params.profil)) {
          this.workflow._setNewProfil(fresh, this.workflow.tableModel.coll.params.profil);
        }

        fresh.trigger("reset");
        this.$el.find("div.tableRecap").show();
        this.form.table.model.coll.trigger("manage:header", "show");
        this.form.table.model.coll.trigger("updateHeader:coll");
        $(this.el).find(".checkBoxHeader.R").prop("checked", false);
        $(this.el).find(".checkBoxHeader.A").prop("checked", false);
        $(this.el).find("div.treatedRecap").hide();
        $(this.el).find("div.progress").hide();
      },
      silent: true
    });
  }

  _openDetails(): void {
    //Ouvrir ici le détail de la première lige de la grille
  }

  remove(): any {
    if (this.workflow) {
      this.workflow.destroy();
      this.workflow = null;
    }
    if (this.btnBar) {
      this.btnBar.remove();
      this.btnBar = null;
    }
    if (this.filter) {
      if (this.filter.model) {
        this.filter.model.off();
        this.filter.model = null;
      }
      this.filter.remove();
      this.filter = null;
    }
    if (this.form) {
      if (this.form.model) {
        this.form.model.off();
        this.form.model = null;
      }
      this.form.remove();
      this.form = null;
    }
    if (this.dialogProgressBar) {
      this.dialogProgressBar.remove();
      this.dialogProgressBar = null;
    }
    if (this.volet) {
      this.volet.remove();
      this.volet = null;
    }
    this.$el.empty();
    return super.remove();
  }
}
