import { BaseModel } from 'core/models/base.model.js';
import { STR } from 'utils/str.js';

/**
 * Model for loading the Racine  activite in  table
 */
export var ActiviteModel = BaseModel.extend({
  /**
   * Add this to have Outline
   *
   */
  dymmy: "",

  //	urlRoot : Configuration.restRoot+"/rest/activite/definition",

  /**
   * Model for loading each activite
   */
  initialize: function( /*options*/ ) {
    if (STR.isBlank(this.get("rattachement"))) {
      var rattachement = [];
      this.set("rattachement", rattachement);
    }
    if (STR.isBlank(this.get("mes1595"))) {
      var mes1595 = [];
      this.set("mes1595", mes1595);
    }
    if (STR.isBlank(this.get("mes1599"))) {
      var mes1599 = [];
      this.set("mes1599", mes1599);
    }
  },

  defaults: {
    "typelt": "",
    "code": "",
    "libelle": "",
    "datedeb": "",
    "datefin": "",
    "hierid": "",
    "feuille": false,
    "indic_filtre": null,
    "indic_periode": "",
    "indic_inapt": "",
    "indic_comp_req": "",
    "mes1595": null,
    "mes1599": null,
    "codef": null,
    "libelleformat": null,
    "niveau": null,
    "codeparent": null,
    "datedebherit": "",
    "datefinherit": "",
    "rattachement": null
  },

  validate: function( /*attrs*/ ) {
    var errors = {};

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  parse: function(response) {
    if (response) {
      if (!STR.isBlank(response.code)) {
        response.id = response.code;
      }
      if (String(response.code) === "0") {
        response.id = "0";
      }
    }
    return response;
  }

});
