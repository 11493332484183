import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { ObjectHash } from 'Backbone';

export class CWPreferences extends CWBaseModel {

  constructor() {
    super();
    this.urlRoot = Configuration.restRoot + "/rest/situationvalo/preferences";
    this.defaults = (): ObjectHash => {
      return {
        "preferences": [{
          "varchar5": "",
          "varchar3": "",
          "varchar4": "",
          "varchar1": "",
          "code": "",
          "varchar2": "",
          "varint3": null,
          "varint2": null,
          "varint1": null,
          "varint5": null,
          "varint4": null,
          "vardat5": "",
          "vardat4": "",
          "vardat3": "",
          "vardat2": "",
          "vardat1": ""
        }]
      }
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}