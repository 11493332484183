import * as Backbone from 'Backbone';
import { i18n } from 'src/i18n.js';
import { CWSTR } from 'utils/cwStr';

export class CWDelegueRenderView extends Backbone.View {

  workflow: any;

  constructor(options: { [key: string]: any }) {
    super(options);
    this.model = null;
    if (options && options.model) {
      this.model = options.model;
    }
    this.workflow = null;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

  }

  render(): CWDelegueRenderView {
    let text = "";
    if (this.model.get("delegue")) {
      if (!CWSTR.isBlank(this.model.get("delegue").matricule)) { // if have a collaborateur
        if (this.workflow.paramIdCollab === "matric" || CWSTR.isBlank(this.workflow.paramIdCollab)) {
          text = this.model.get("delegue").nom + " " + this.model.get("delegue").prenom + " (" + this.model.get("delegue").matricule + ")";
          this.$el.html(text).attr("title", text);
        } else if (this.workflow.paramIdCollab === "matric_aux") {
          text = this.model.get("delegue").nom + " " + this.model.get("delegue").prenom + " (" + this.model.get("delegue").matriculeaux + ")";
          this.$el.html(text).attr("title", text);
        }
      } else { // if have a utilisateur
        text = i18n.t('droitsdel.utilisateur') + " <span style='font-weight: bold'>" + this.model.get("delegue").utilnom + "</span>";
        this.$el.html(text).attr("title", text);
      }
    }
    return this;
  }
}