module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="gererprets-detail gererprets-content c-gererPretsContent c-form--fixedButtons">\n    <div class="form-group phx-formErrors ui-state-error"></div>\n    <div class="gererpret-form-inputs c-form__scrollZone">\n        <span class="journeeSimulated ui-phx-plan-simule-en-cours" style="display:none"></span>\n        <div class="beginning-form">\n            <div class="imbrique-header">\n                <span class="pret-header"></span>\n                <span class="periode-header"></span>\n                <span class="statutPretLibelle"></span>\n            </div>\n            <div class="popup-header mb-4">\n                <span class="gererprets-utilisateur cw-titreLight"></span>\n                <span class="statutPretLibelle"></span>\n            </div>\n            <div class="form-group">\n                <label for="type.code" class="cw-required">'+
((__t=(i18n.t('common:gererprets.type')))==null?'':__t)+
'</label>\n                <div class="input-group">\n                    <div class="cmbType" style="width: 100%;"></div>\n                </div>\n            </div>\n            <div class="form-group">\n                <label for="perimetre.code" class="cw-required">'+
((__t=(i18n.t('common:gererprets.perimetre')))==null?'':__t)+
'</label>\n                <div class="input-group">\n                    <div class="cmbPerimetre" style="width: 100%;"></div>\n\n                </div>\n            </div>\n            <div class="form-group">\n                <div class="liste-structures"></div>\n            </div>\n        </div>\n        <span class="typeSaisie"></span>\n        <span class="modeSaisie"></span>\n\n        <div class="end-form">\n            <div class="form-group">\n                <label for="motif.code" class="cw-required">'+
((__t=(i18n.t('common:gererprets.motif')))==null?'':__t)+
'</label>\n                <div class="input-group">\n                    <span class="cmbMotif" style="width: 100%;" title="'+
((__t=(i18n.t('common:gererprets.motif_title')))==null?'':__t)+
'"></span>\n                </div>\n            </div>\n            <div class="originActif form-group">\n                <label for="origine.code">'+
((__t=(i18n.t('common:gererprets.origine')))==null?'':__t)+
'</label>\n                <div class="input-group">\n                    <span class="cmbOrigine" style="width: 100%;" title=""></span>\n                </div>\n            </div>\n            <div class="gererprets-data">\n                <div class="data">\n                    <div class="form-group">\n                        <label for="libelle">'+
((__t=(i18n.t('common:gererprets.libelle')))==null?'':__t)+
'</label>\n                        <input type="text" class="libelle form-control " value="" maxlength="30" size="30" />\n                        <span class="valeur-error-container"></span>\n                    </div>\n                    <div class="form-group">\n                        <label for="collabremplace">'+
((__t=(i18n.t('common:gererprets.collabRef')))==null?'':__t)+
'</label>\n                        <input type="text" class="collabremplace form-control ui-phx-ihm-texte-donnees-contrast-alternatif" value="" maxlength="30" size="30" />\n                    </div>\n                    <div class="comment_pret_div form-group">\n                        <label for="commentaire_pret">'+
((__t=(i18n.t('common:gererprets.info_comp')))==null?'':__t)+
'</label>\n                        <textarea class="commentaire_pret form-control" maxlength="200" cols="30" rows="4" style="resize:none"></textarea>\n                    </div>\n                    <div class="commentaire_div form-group">\n                        <label for="commentaire">'+
((__t=(i18n.t('common:gererprets.comment')))==null?'':__t)+
'</label>\n                        <textarea class="commentaire form-control" title="'+
((__t=(i18n.t('common:gererprets.comment_title')))==null?'':__t)+
'" maxlength="200" cols="30" rows="4" style="resize:none"></textarea>\n                    </div>\n                </div>\n            </div>\n\n            <span class="gererprets-piecesjointes form-group"></span>\n            <span class="gererprets-infocomp"></span>\n        </div>\n        <!-- <span class="gererprets-wkfhisto"></span> -->\n    </div>\n</div>\n';
}
return __p;
};
