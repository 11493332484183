import { MessageModel } from './message.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

/**
 * Collection for the messages
 */
export var MessageColl = PaginatedCollection.extend({

  model: MessageModel,

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
    //this.sortings = {bloquant : true};
  }

});