import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for Brique 'info / memos' for viser messages
 */

export class CWViserInfoMemosModel extends CWBaseModel {

  id: string;
  code: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    if (attributes.id) {
      options.code = attributes.id;
    }
    super(attributes, options);
    this.id = options.code;
    this.code = options.code;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/message/" + encodeURIComponent(this.code) + "/viser";
    };
  }
}