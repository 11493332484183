import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWSTR } from 'src/utils/cwStr';
import { objs } from 'src/objectsRepository';

export class CWSelecteurCheminItemModel extends CWBaseModel {

  data: string;
  habContext: CWHabilitationContext;
  model: any;
  structure: any;
  structCode: any;
  debsitu: any;
  finsitu: any;

  /**
   * Constructor
   * Paginated collection for the application. Manage also filtering and sorting
   */
  constructor(attributes?: { [key: string]: any } | any, options?: { [key: string]: any } | any) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.data = "structid=" + options.structid;
    if (!CWSTR.isBlank(options.detailunique)) {
      this.data += "&detailunique=true";
      this.data += "&datedebut=" + options.datedebut;
      this.data += "&datefin=" + options.datefin;
      //			this.data += "&originesource=" + options.source;
    } else {
      this.data += "&datedebut=" + options.date;
      this.data += "&datefin=" + options.date;
    }
    this.data += "&multiple=false&intermediaire=true&partial=true";
    if (!CWSTR.isBlank(options.nonrattachable)) {
      this.data += "&nonrattachable=" + options.nonrattachable;
    }
    if (!CWSTR.isBlank(options.nonrattactiv)) {
      this.data += "&nonrattactiv=" + options.nonrattactiv;
    }
    if (!CWSTR.isBlank(options.chemin)) {
      this.data += "&chemin=" + options.chemin;
    } else {
      this.data += "&elementcode=" + options.elementCode;
    }
    this.url = (): string => {
      return Configuration.restRoot + "/rest/cheminstructure/simple?" + this.data;
    };
    this.ecran = options.ecran;
  }

  /**
   * Process to manage the response
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }

  fetch(options?: { [key: string]: any }): JQueryXHR {
    options = options || {};
    this.usecase = objs.appRt.workflow.get("usecase");
    if (!this.habContext && !this.habilitationV && !this.habilitationG) {
      this.habilitationV = "N";
      this.habilitationG = "N";
    }
    if (!CWSTR.isBlank(this.ecran)) {
      this.usecase = this.ecran;
    }
    return super.fetch(options);
  }
}
