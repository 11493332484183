import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLBaffecBriqueAffectationEnCours from '../briqueAffectationEnCours.tpl.html';
import { CWAffectationComplemColl } from '../models/cwAffectationComplem.collection';
import { CWAffectationEnCoursModel } from '../models/cwAffectationEnCours.model';
import { CWAffectationPrincipColl } from '../models/cwAffectationPrincip.collection';
import { CWAffectationRemplaColl } from '../models/cwAffectationRempla.collection';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWPanneauAffectationPpalView } from './cwPanneauAffectationPpal.view';
import { CWPanneauAffectationRemplaView } from './cwPanneauAffectationRempla.view';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { forkJoin } from 'rxjs';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'src/utils/sync.js';
import { TypeStructureModel } from '../models/typeStructure.model.js';
import { UTILS } from 'utils/utils.js';


export class CWAffectationEnCoursView extends Backbone.View<Backbone.Model>{

  usePopulation: boolean;
  template: (params: any) => string;
  model: CWAffectationEnCoursModel;
  habContext: CWHabilitationContext;
  modelAffPrincipal: CWAffectationPrincipColl;
  modelRemplacements: CWAffectationRemplaColl;
  modelComplementaires: CWAffectationComplemColl;
  infoCompActivated: boolean;
  tableC: any;
  StrutureP: any;
  strutureHabContext: CWHabilitationContext;
  hasPrets: boolean;
  hasAffect: boolean;
  isStructurePrincipal: boolean;
  ctxTypesAffectations: { [key: string]: any };
  structureSelected: any;
  empty: boolean;
  affPrincNbr: number;
  affRemplacantNbr: number;
  panneau: CWPanneauDeroulant;
  panneauRempla: CWPanneauDeroulant;
  outsideClickListener: (arg1: JQueryEventObject) => any;
  outsideClickListenerRempl: (arg1: JQueryEventObject) => any;
  maxLinesComplementaires: number;
  codeSexe: string;


  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .table_BriqueAffectationEnCours_link": "_linkToValidation",
      'render': 'refreshBrique',
      "click .cw-affPpal__aideBulle": "_ouvrirPanneauAffectationsPpal",
      "click .cw-affRempla__aideBulle": "_ouvrirPanneauAffectationsRempla"
    }, options.events);
    super(options);

    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPLBaffecBriqueAffectationEnCours;
    this.model = new CWAffectationEnCoursModel();
    this.codeSexe = "M";

    this.modelAffPrincipal = new CWAffectationPrincipColl();
    this.modelAffPrincipal.usePopulation = this.usePopulation;
    this.modelAffPrincipal.setHabContext(new CWHabilitationContext({
      onglet: "baffec",
      foncCour: "COL_AFFPRINC.V",
      natGest: ""
    }));

    this.modelRemplacements = new CWAffectationRemplaColl();
    this.modelRemplacements.usePopulation = this.usePopulation;
    this.modelRemplacements.setHabContext(new CWHabilitationContext({
      onglet: "baffec",
      foncCour: "COL_PRET.V",  // eslint-disable-line
      natGest: ""  // eslint-disable-line
    }));

    // Flag to know if the affectation complementaire is activated
    this.infoCompActivated = CWHABILITATION.canView("COL_AFFSECOND.V") || CWHABILITATION.canView("COL_PRET.V");
    if (this.infoCompActivated) {
      this.modelComplementaires = new CWAffectationComplemColl();
      this.modelComplementaires.usePopulation = this.usePopulation;
      this.modelComplementaires.setHabContext(new CWHabilitationContext({
        onglet: "baffec",
        foncCour: { // eslint-disable-line
          "affsec": "COL_AFFSECOND.V",
          "affexc": "COL_PRET.V"
        },
        natGest: ""// eslint-disable-line
      }));
    }

    this.StrutureP = new TypeStructureModel();
    this.strutureHabContext = new CWHabilitationContext({
      onglet: "baffec",
      foncCour: "PAR_TYPESTR.V",// eslint-disable-line
      natGest: ""// eslint-disable-line
    });
    this.StrutureP.setHabContext(this.strutureHabContext);

    this.hasPrets = false;
    this.hasAffect = false;
    this.isStructurePrincipal = true;

    // by default all are activated.
    this.ctxTypesAffectations = ["Affectation principale", "Affectations secondaires", "Prêts"];

    this.affPrincNbr = 0;
    this.affRemplacantNbr = 0;
    this.maxLinesComplementaires = 5;
  }

  /**
     * Renders this brique
     *
     */
  render(): CWAffectationEnCoursView {
    const json = { 'i18n': i18n };
    const lAucuneAffPpal = "<div class='pb-2'>" + i18n.t('common:binfmem.noMessageAffiche') + "</div>";

    $(this.el).html(this.template(json));
    if (CWHABILITATION.canView("COL_AFFPRINC.V")) {
      this.$el.find(".table_AffectationPrincipale ").html(lAucuneAffPpal);
    }

    this.$el.find(".table_AffectationRemplacement ").html();

    return this;
  }

  checkHabilation(): boolean {
    return CWHABILITATION.canView("COL_AFFPRINC.V") || CWHABILITATION.canView("COL_AFFSECOND.V") || CWHABILITATION.canView("COL_PRET.V");
  }

  refreshBrique(): CWAffectationEnCoursView {
    const calls = [];
    const lAucuneAffPpal = "<div class='pb-2'>" + i18n.t('common:binfmem.noMessageAffiche') + "</div>";

    this.$el.attr("aria-busy", "true");
    if (CWHABILITATION.canView("COL_AFFPRINC.V")) {
      this.$el.find(".table_AffectationPrincipale").html(lAucuneAffPpal);
      calls.push(this.modelAffPrincipal.fetch());
    }
    if (CWHABILITATION.canView("COL_AFFSECOND.V") || CWHABILITATION.canView("COL_PRET.V")) {
      if (this.modelComplementaires) {
        calls.push(this.modelComplementaires.fetch({
          error: (model: any, response: { [key: string]: any }) => {
            // If error 550 raised so we catch the error to avoid dialogbox with security error
            // Probably cause : licence is not set for COL_AFFSECOND.V
            if (response.status === 550) {
              response.status = null;
            }
          }
        }));
      }
    }
    if (calls.length > 0) {
      const sub = forkJoin(calls);

      sub.subscribe((): void => {
        this.codeSexe = objs.appRt.workflow.authModel.get("sexe");
        if (CWHABILITATION.canView("COL_AFFPRINC.V")) {
          this._renderGridAffPrincipale(this.modelAffPrincipal);
          this.$el.attr("aria-busy", "false");
          if (this.panneau) {
            this.panneau._closePanneau();
            this.panneau.dialog.dialog("destroy");
            this.panneau.dialog = null;
            this.panneau = null;
          }
        }
        if (CWHABILITATION.canView("COL_PRET.V")) {
          if (this.panneauRempla) {
            this.panneauRempla._closePanneau();
            this.panneauRempla.dialog.dialog("destroy");
            this.panneauRempla.dialog = null;
            this.panneauRempla = null;
          }
        }
        if (CWHABILITATION.canView("COL_AFFSECOND.V") || CWHABILITATION.canView("COL_PRET.V")) {
          this._renderGridComplementaires(this.modelComplementaires);
          this.$el.attr("aria-busy", "false");
          const rowHeight = this.$el.find(".table_AffectationComplementaire .cw-affC__row").outerHeight();
          this.$el.find(".cw-affectationsComplementaires").css("max-height", ((rowHeight * this.maxLinesComplementaires) + 1) + "px");
        }
      },
        (): void => {
          //Nothing
        });
    }
    this.delegateEvents();
    return this;
  }

  _renderGridAffPrincipale(collAffP: any): void {
    this.affPrincNbr = collAffP.length;
    if (this.affPrincNbr > 0) {
      if (CWHABILITATION.canView("COL_PRET.V")) {
        this.modelRemplacements.fetch({
          success: (fresh: any) => {
            this._renderGridRemplacement(fresh.models);
            this.$el.attr("aria-busy", "false");
          }
        });
      }
      const grid = this._createGridOfAffPrincipale(collAffP.models);
      this.$el.find(".table_AffectationPrincipale").html(grid);
    }
  }

  _createGridOfAffPrincipale(modelsAffP: any): any {
    const $retour = $("<div>").addClass("row col cw-affP cw-texteNormal");

    for (let i = 0; i < modelsAffP.length; i++) {
      // Check which model is in actual date 
      if (CWTYPE.DATE._checkDateInPeriod(modelsAffP[i].get("datedeb"), modelsAffP[i].get("datefin"))) {

        const aideBulleIcon = UTILS.getSVGIcon("aide_bulle", "", 16);
        const $iconaide = $("<span>").append(aideBulleIcon).addClass("cw-affPpal__aideBulle cw-icon ml-2 cw-triggerClick");
        $iconaide.attr("role", "button");
        $iconaide.attr("aria-label", i18n.t('common:ARIA_label.add'));
        $iconaide.attr("tabindex", "0");
        //AffPrincRattachementLibelle et/ou AffPrincRattachementCode
        // (en fonction de la restitution définie dans le type de structure)        
        $retour.append(modelsAffP[i].get("itemlib"));
        if (this.affPrincNbr > 1) {
          $retour.append(" " + i18n.t('common:baffec.jusquau') + " " + CWTYPE.DATE.manageInfinity(modelsAffP[i].get("datefin"), CWTYPE._getFormatByCode("DATE_A")) + " ");
          $retour.append($iconaide);
        }
        break;
      }
    }
    return $retour;
  }

  _renderGridRemplacement(remplacement: any): void {
    this.affRemplacantNbr = remplacement.length;
    if (this.affRemplacantNbr > 0) {
      const grid = this._createGridOfRemplacement(remplacement);
      this.$el.find(".table_AffectationRemplacement").html(grid);
    }
  }

  _createGridOfRemplacement(modelRempl: any): any {
    const $retour = $("<div>");
    const $title = $("<div>").addClass("row col cw-affRempl-title cw-texteSuperAllege").append(i18n.t('common:baffec.affectationRemplacement_' + this.codeSexe.toLowerCase()));
    const $remplacement = $("<div>").addClass("row col cw-affRempl");
    const $remplacementLib = $("<span>");
    const remplCollabNom = modelRempl[0].get("pret").collaborateur.nom;// Selon idcollab
    const remplCollabPrenom = modelRempl[0].get("pret").collaborateur.prenom;
    const isMatricAux = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur") === "matric_aux" ? true : false;
    const remplCollabMatric = isMatricAux ? modelRempl[0].get("pret").collaborateur.matricaux : modelRempl[0].get("pret").collaborateur.matric;
    $retour.append($title);
    $remplacementLib.append(remplCollabNom + " " + remplCollabPrenom + " (" + remplCollabMatric + ") " + this._formattedRemplacementLines(modelRempl[0]));
    // En construction... _checkDateInPeriod(dateDeb, dateFin, dateDuJour)
    if (CWTYPE.DATE._checkDateInPeriod(modelRempl[0].get("datedeb"), modelRempl[0].get("datefin"))) {
      $remplacementLib.addClass("cw-texteNormal--selectionne");
    } else {
      $remplacementLib.addClass("cw-texteNormal");
    }
    if (this.affRemplacantNbr > 1) {
      const aideBulleIcon = UTILS.getSVGIcon("aide_bulle", "", 16);
      const $iconaide = $("<span>").append(aideBulleIcon).addClass("cw-affRempla__aideBulle cw-icon ml-2");
      $remplacementLib.append($iconaide);
    }
    $remplacement.append($remplacementLib);
    $retour.append($remplacement);
    return $retour;
  }

  _renderGridComplementaires(complementaire: any): void {
    if (complementaire.length > 0) {
      const tblColumns = [
        { property: "date" },
        { property: "itemlib" },
        { property: "nature" }
      ];
      const grid = this._createGridOfAffComplementaires(complementaire.models, tblColumns);
      this.$el.find(".table_AffectationComplementaire").html(grid);
    }
  }

  _createGridOfAffComplementaires(modelComp: any, tablColumns: any): any {
    //shall compare if new date is the same lastdate
    let lastdate = "";
    let newdate = "";
    //To show a different value in the cell
    const format: { [key: string]: any } = {};

    format["date"] = (model: CWAffectationEnCoursModel | any): any => {
      const $formattedText = $("<div>");
      /*
          CTIME V5.0 – Evolution UI (SFD V3.0)
        •	Restitution en fonction du TDA
        •	Format DATE_A
        •	La date est masquée si c’est la même que la ligne précédente
        •	Si l’unité valable sur la date est différente de D (Date complète), affichage de cette unité après la date
        •	Si AffComplDate = aujourd’hui, alors style « texte allégé sélectionné » sinon style « texte allégé »
        FIN CTIME V5.0 – Evolution UI
      */
      newdate = this._formatteddates(model);
      //date shall not be displayed if new line as the same date than last line
      if (newdate === lastdate) {
        return $formattedText.append("");
      }
      lastdate = newdate;
      $formattedText.append(lastdate);
      if (CWTYPE.DATE._checkDateInPeriod(model.get("date"), model.get("date"))) {
        $formattedText.addClass('cw-texteAllege--selectionne');
      }
      else if (model.get("date") === undefined
        && model.get("datedeb") === model.get("datefin")
        && CWTYPE.DATE._checkDateInPeriod(model.get("datedeb"), model.get("datefin"))) {
        $formattedText.addClass('cw-texteAllege--selectionne');
      }
      else {
        $formattedText.addClass('cw-texteAllege');
      }
      return $formattedText;
    };

    format["itemlib"] = (model: CWAffectationEnCoursModel | any): any => {
      return this._formattedlines(model);
    };

    format["nature"] = (model: CWAffectationEnCoursModel | any): any => {
      return this._formattedlinesNature(model);
    };

    const $retour = $("<div>");
    const $conteneurAffectations = $("<div>").addClass("cw-affectationsComplementaires");
    const $title = $("<div>").addClass("row col cw-texteTresAllege").append(i18n.t('common:baffec.affectationComplementaire'));
    $retour.append($title);
    for (let i = 0; i < modelComp.length; i++) {
      const $row = $("<div>").addClass("row cw-affC__row");

      for (let j = 0; j < tablColumns.length; j++) {
        let $col = $("<div>").addClass("col-5");
        if (j === 2) {
          $col = $("<div>").addClass("col-2").addClass("cw-text-direction");
        }
        $col.append(format[tablColumns[j].property](modelComp[i]));
        $row.append($col);
      }
      $conteneurAffectations.append($row);
    }

    $retour.append($conteneurAffectations);
    return $retour;
  }


  _formattedlinesNature(model: any): string {
    let text = "";

    text += "<span ";
    // Infobulle pour un prêt avec remplacement : message GT-1848 suivi d’un espace et de RemplacéCollabNom RemplacéCollabPrenom (RemplacéCollabMatricule) ou RemplacéCollabNom RemplacéCollabPrenom (RemplacéCollabMatricule_aux) selon IdCollab
    if (!CWSTR.isBlank(model.attributes.collabremplace)) {
      if (model.attributes.collabremplace.matric !== null || model.attributes.collabremplace.matricaux !== null) {
        const isMatricAux = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur") === "matric_aux" ? true : false;
        const remplCollabMatric = isMatricAux ? model.attributes.collabremplace.matricaux : model.attributes.collabremplace.matric;

        text += "title='" + i18n.t('messages:GT_1848') + " " + model.attributes.collabremplace.nom + " " + model.attributes.collabremplace.prenom + " (" + remplCollabMatric + ")'";
        text = text.concat(
          "title='", i18n.t('messages:GT_1848'),
          " ", model.attributes.collabremplace.nom,
          " ", model.attributes.collabremplace.prenom,
          " (", remplCollabMatric, ")'"
        );
      }
      /*if (model.attributes.collabremplace.matric !== null) {
        text += "title='" + i18n.t('messages:GT_1848') + " " + model.attributes.collabremplace.nom + " " + model.attributes.collabremplace.prenom + " (" + model.attributes.collabremplace.matric + ")'";
      } else if (model.attributes.collabremplace.matricaux !== null) {
        text += "title='" + i18n.t('messages:GT_1848') + " " + model.attributes.collabremplace.nom + " " + model.attributes.collabremplace.prenom + " (" + model.attributes.collabremplace.matricaux + ")'";
      }*/
    }
    if (!CWSTR.isBlank(model.get("motif"))) {
      text += " class='ui-phx-ihm-texte-donnees cw-affC__nature cw-affc__nature_nowrap p-2'>" + model.get("motif").libelle + "</span>";
      return text;
    }
    text += " class='ui-phx-ihm-texte-donnees p-2'></span>";
    return "";
  }

  _formatteddates(model: any): string {
    let formattedText = "";
    if (model.get("nature").code === "S") {
      formattedText = CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A"));
    } else if (model.get("nature").code === "P") {
      formattedText = CWTYPE.DATE.format(model.get("date"), CWTYPE._getFormatByCode("DATE_A"));
    }
    if (model.get("unitedeb") !== undefined && model.get("unitedeb").code !== "D") {
      formattedText = formattedText.concat(" ", model.get("unitedeb").libelle);
    }
    return formattedText;
  }

  _formattedlines(model: any): any {
    const $formattedText = $("<div>");
    if (CWTYPE.DATE._checkDateInPeriod(model.get("date"), model.get("date"))
      || (model.get("date") === undefined && model.get("datedeb") === model.get("datefin") && CWTYPE.DATE._checkDateInPeriod(model.get("datedeb"), model.get("datefin")))
    ) {
      $formattedText.addClass('cw-texteNormal--selectionne');
    } else {
      $formattedText.addClass('cw-texteNormal');
    }
    if (model.get("nature").code === "S") {
      $formattedText.append(model.get("itemlib"));
    } else {
      $formattedText.append(model.get("itemlib"));
    }
    return $formattedText;
  }

  _formattedRemplacementLines(model: CWAffectationEnCoursModel): string {
    const text = [];
    let temporelle = "";
    const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    const pret = model.get("pret");
    // Indication temporelle
    if (pret && pret.typesaisie) {
      const uniteDeb = pret.unitedeb;
      const uniteFin = pret.unitefin;
      if (pret.typesaisie === "C" && (CWSTR.isBlank(pret.heuredeb) && CWSTR.isBlank(pret.heurefin))) {
        if (uniteDeb && uniteDeb.code === "D" && (uniteFin && uniteFin.code === uniteDeb.code)) {
          temporelle += i18n.t('common:baffec.du_au_2p', { //GT1184
            "1": CWTYPE.DATE.format(pret.datedeb, CWTYPE._getFormatByCode("DATE_A")),
            "3": CWTYPE.DATE.format(pret.datefin, CWTYPE._getFormatByCode("DATE_A"))
          });
        } else if (uniteDeb && uniteDeb.code !== "D" && (uniteFin && uniteFin.code !== "D")) {
          temporelle += i18n.t('common:baffec.du_au_4p', { //GT1187
            "1": CWTYPE.DATE.format(pret.datedeb, CWTYPE._getFormatByCode("DATE_A")),
            "2": uniteDeb.libelle,
            "3": CWTYPE.DATE.format(pret.datefin, CWTYPE._getFormatByCode("DATE_A")),
            "4": uniteFin.libelle
          });
        }
      } else if (pret.typesaisie === "D" && (model.get("datedeb") <= today <= model.get("datefin"))) {
        if (pret.modesaisie === "UNITE" && (uniteDeb && uniteFin && uniteDeb.code === "D" && uniteFin.code === uniteDeb.code)) {
          temporelle += i18n.t('common:baffec.le_1p', { // GT_1181
            "1": CWTYPE.DATE.format(pret.datedeb, CWTYPE._getFormatByCode("DATE_A"))
          });
        } else if (pret.modesaisie === "UNITE" && (uniteDeb && uniteFin && uniteDeb.code !== "D" && uniteFin.code === uniteDeb.code)) {
          temporelle += i18n.t('common:baffec.le_2p', { // GT_1180
            "1": CWTYPE.DATE.format(pret.datedeb, CWTYPE._getFormatByCode("DATE_A")),
            "2": uniteDeb.libelle
          });
        } else if ((pret.modesaisie === "PLH")) {
          temporelle += i18n.t('messages:GT_1721', {
            "1": CWTYPE.HOUR_MINUTE.format(pret.heuredeb),
            "2": CWTYPE.HOUR_MINUTE.format(pret.heurefin),
            "3": CWTYPE.DURATION.HOUR_MINUTE.format(pret.duree)
          });
        } else if (pret.modesaisie === "DUREE") {
          temporelle += "(" + CWTYPE.DURATION.HOUR_MINUTE.format(model.get("duree")) + ")";
        } else if (pret.modesaisie === "POURCENT") {
          temporelle += CWTYPE.PERCENTAGE.format(pret.pourcentage, null);
        }
      }
    }
    if (!_.isEmpty(temporelle)) {
      text.push(temporelle.replace(',', ''));
    }
    return text.join(", ");
  }


  isEmpty(): boolean {
    return this.empty;
  }

  _creationPanneauAffPpal(ofContainer: HTMLElement): CWPanneauDeroulant {
    const lTitle = (this.modelAffPrincipal.length > 1) ? "affectationsPrincipales" : "affectationPrincipale";
    const lPosition = (ofContainer) ? { my: "left top", at: "center bottom", of: ofContainer } : { my: "center top", at: "center bottom", of: this.el }
    const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    const lHabContext = new CWHabilitationContext({
      onglet: "baffec",
      foncCour: "COL_AFFPRINC.V", // eslint-disable-line
      natGest: "" // eslint-disable-line
    });
    const lAutresAffPpal = new CWAffectationPrincipColl(this.modelAffPrincipal.filter((model) => {
      return !(model.get("datedeb") <= today && model.get("datefin") >= today);
    }));
    lAutresAffPpal.comparator = (item: Backbone.Model): string => {
      return item.get('datedeb');
    };
    lAutresAffPpal.sort();
    const panneau = new CWPanneauDeroulant({
      view: CWPanneauAffectationPpalView,
      notIconClose: true,
      position: lPosition,
      viewData: {
        appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
        model: this.model,
        parent: this,
        coll: lAutresAffPpal,
        usePopulation: this.usePopulation,
        habContext: lHabContext
      }
    });

    panneau.setHeight("auto");
    panneau.setWidth("auto");
    panneau.dialogClass += " cw-pave__panneau";
    panneau._setTitle(i18n.t('common:baffec.' + lTitle).toUpperCase());
    return panneau;
  }

  _ouvrirPanneauAffectationsPpal(): void {
    const lPos = this.$el.find(".cw-affPpal__aideBulle");
    let lPosButton = null;

    if (lPos.length > 0) {
      lPosButton = lPos[0];
    } else {
      lPosButton = null;
    }
    if (CWSTR.isBlank(this.panneau)) {
      this.panneau = this._creationPanneauAffPpal(lPosButton);
      this.outsideClickListener = (event: any): any => {
        if (lPos.filter(event.target).length < 1 &&
          (lPos.find("." + event.target.classList[0]).length < 1 || lPos.offsetParent()[0] !== event.target.offsetParent) &&
          (this.panneau.$el.find($(event.target)).length < 1 && lPos.find(event.target).length < 1)) {
          this.panneau._closePanneau(event, null);
        }
      };
    }
    $(this.panneau.$el).off("dialogopen");
    $(this.panneau.$el).off("dialogclose");
    $(this.panneau.$el).on("dialogopen", (): void => {
      $(document).on("click." + this.cid, this.outsideClickListener);
    }
    );
    $(this.panneau.$el).on("dialogclose", (): void => {
      $(document).off("click." + this.cid, this.outsideClickListener as any);
    }
    );
    this.panneau.open();
  }

  _creationPanneauAffRempla(ofContainer: HTMLElement): CWPanneauDeroulant {
    const lPosition = (ofContainer) ? { my: "left top", at: "center bottom", of: ofContainer } : { my: "center top", at: "center bottom", of: this.el }
    const lHabContext = new CWHabilitationContext({
      onglet: "baffec",
      foncCour: "COL_PRET.V", // eslint-disable-line
      natGest: "" // eslint-disable-line
    });
    const lAutresAffRempla = new CWAffectationRemplaColl(this.modelRemplacements.models);

    lAutresAffRempla.comparator = (item: Backbone.Model): string => {
      return item.get('datedeb');
    };
    lAutresAffRempla.sort();
    lAutresAffRempla.shift();//on supprime le premier élément
    const panneauRempla = new CWPanneauDeroulant({
      view: CWPanneauAffectationRemplaView,
      notIconClose: true,
      position: lPosition,
      viewData: {
        appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
        model: this.model,
        parent: this,
        coll: lAutresAffRempla,
        usePopulation: this.usePopulation,
        habContext: lHabContext
      }
    });

    panneauRempla.setHeight("auto");
    panneauRempla.setWidth("auto"); //470
    panneauRempla.dialogClass += " cw-pave__panneau";
    panneauRempla._setTitle(i18n.t('common:baffec.affectationRemplacementPuis_' + this.codeSexe.toLowerCase()).toUpperCase());
    return panneauRempla;
  }

  _ouvrirPanneauAffectationsRempla(): void {
    const lPos = this.$el.find(".cw-affRempla__aideBulle");
    let lPosButton = null;

    if (lPos.length > 0) {
      lPosButton = lPos[0];
    } else {
      lPosButton = null;
    }
    if (CWSTR.isBlank(this.panneauRempla)) {
      this.panneauRempla = this._creationPanneauAffRempla(lPosButton);
      this.outsideClickListenerRempl = (event: any): any => {
        if (lPos.filter(event.target).length < 1 &&
          (lPos.find("." + event.target.classList[0]).length < 1 || lPos.offsetParent()[0] !== event.target.offsetParent) &&
          (this.panneauRempla.$el.find($(event.target)).length < 1 && lPos.find(event.target).length < 1)) {
          this.panneauRempla._closePanneau(event, null);
        }
      };
    }
    $(this.panneauRempla.$el).off("dialogopen");
    $(this.panneauRempla.$el).off("dialogclose");
    $(this.panneauRempla.$el).on("dialogopen", (): void => {
      $(document).on("click", this.outsideClickListenerRempl);
    }
    );
    $(this.panneauRempla.$el).on("dialogclose", (): void => {
      $(document).off("click", this.outsideClickListenerRempl as any);
    }
    );
    this.panneauRempla.open();
  }
}