import _ from 'underscore';
import { CWLibellesModel } from './cwLibelles.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';


export class CWLibellesModelColl<TModel extends CWLibellesModel = CWLibellesModel> extends CWPaginatedCollection<TModel>{

  habilitationV: string;
  habilitationG: string;
  usecase: string;

  constructor(models?: CWLibellesModel[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWLibellesModel;
    super(models, options);
    //Default values from class
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "beff";
    this.url = (): string => {
      return Configuration.restRoot + "/rest/utilbrique/situations";
    }
    CWPaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
    this.sortings = { code: true };
  }
}