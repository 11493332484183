import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n';
import { ModelSaveOptions } from 'Backbone';

/**
 * gererEvtGenModel
 */
export class CWGererEvtGenModel extends CWBaseModel {

  public MODEGROUPE: boolean;
  public action: string;
  public profil: { [key: string]: any };

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    if (options && !CWSTR.isBlank(options.context)) {
      this.context = options.context;

      if (this.context.ctxValeursCreation && !CWSTR.isBlank(this.context.ctxValeursCreation.EvtGenModeSaiDef)) {
        this.set("saidefo", this.context.ctxEvtGenModeSaiDef);
        this.set("sairepet", this.context.ctxEvtGenSaiRepJ);
      }
    }
    this.MODEGROUPE = false;
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);
      if (id === "") {
        return Configuration.restRoot + "/rest/composant/evtgen";
      } else {
        return Configuration.restRoot + "/rest/composant/evtgen/" + id;
      }
    }
  }

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": "",
      "evenement": null,
      "matricule": null,
      // "matricaux": "",
      // "nom": "",
      // "prenom": "",
      "datedeb": "",
      "datefin": "",
      "typesaisie": "",
      "modesaisie": "",
      "heuredeb": "0000",
      "heurefin": "0000",
      "complement": "",
      "saiauto": {
        "code": "",
        "libelle": ""
      },
      "statut": {
        "code": "",
        "libelle": ""
      },
      "origine": {
        "code": "",
        "libelle": ""
      },
      "etat": null
    }
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    let errorMsg;
    const infocomp: Array<any> = [];
    let hasModeSaisie = false; //par defaut

    if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxTypologieEvenement)) {
      const evtGenTypo = this.context.ctxTypologieEvenement;

      // Liste des modes de saisie autorisés. EvtGenSaiHDF vaut true si la liste contient le mode "PLH"
      if (evtGenTypo.saiauto) {
        if (evtGenTypo.saiauto.length > 0) {
          // It is an array?
          const indexSaiauto = _.findIndex(evtGenTypo.saiauto, (item: { [key: string]: any }): boolean => {
            return item.code === "PLH";
          });
          hasModeSaisie = indexSaiauto > -1;
        } else if (!CWSTR.isBlank(evtGenTypo.saiauto)) {
          // It is an object?
          hasModeSaisie = evtGenTypo.saiauto.code === "PLH";
        }
      }
    }

    //TypeSaisie
    if (attrs.typesaisie === "D") {
      if (CWSTR.isBlank(attrs.datedeb)) {
        errors.datedeb = i18n.t('common:required', { "0": i18n.t('common:gererevtgen.date') });
      } else {
        errorMsg = CWTYPE.DATE.validate(attrs.date);
        if (!CWSTR.isBlank(errorMsg)) {
          errors.datedeb = errorMsg;
        }
      }
    } else {
      //Period validation
      const datedeb = {
        "name": "datedeb",
        "value": attrs.datedeb,
        "label": i18n.t('common:gererevtgen.datedebut')
      };
      const datefin = {
        "name": "datefin",
        "value": attrs.datefin,
        "label": i18n.t('common:gererevtgen.datefin'),
        "isInfinity": false
      };

      errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
      if (!CWSTR.isBlank(errorMsg)) {
        if (errorMsg.datedeb) {
          errors.datedeb = errorMsg.datedeb;
        }
        if (errorMsg.datefin) {
          errors.datefin = errorMsg.datefin;
        }
      }
    }
    if (attrs.typesaisie !== "P") { //if not "PERIOC"
      // ModeSaisie
      if (attrs.modesaisie === "PLH" || (CWSTR.isBlank(attrs.modesaisie) && hasModeSaisie)) {
        if (CWSTR.isBlank(attrs.heuredeb)) {
          errors.heuredeb = i18n.t('common:required', { "0": i18n.t('common:gererevtgen.heuredebut') });
        } else {
          errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heuredeb);
          if (!CWSTR.isBlank(errorMsg)) {
            errors.heuredeb = errorMsg;
          }
        }
        if (CWSTR.isBlank(attrs.heurefin)) {
          errors.heurefin = i18n.t('common:required', { "0": i18n.t('common:gererevtgen.heurefin') });
        } else {
          errorMsg = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(attrs.heurefin);
          if (!CWSTR.isBlank(errorMsg)) {
            errors.heurefin = errorMsg;
          }
        }
        if (CWSTR.isBlank(errors.heurefin) && CWSTR.isBlank(errors.heuredeb) && (attrs.heuredeb >= attrs.heurefin)) {
          errors.heurefin = i18n.t('messages:GL_1070');
        }
      } else if (attrs.modesaisie === "DUREE") {
        if (CWSTR.isBlank(attrs.duree)) {
          errors.duree = i18n.t('common:required', { "0": i18n.t('common:gererevtgen.duree') });
        } else {
          errorMsg = CWTYPE.DURATION.HOUR_MINUTE.validate(attrs.duree);
          if (!CWSTR.isBlank(errorMsg)) {
            errors.duree = errorMsg;
          }
        }
      } else if (attrs.modesaisie === "POURCENT") {
        if (CWSTR.isBlank(attrs.pourcentage)) {
          errors.pourcentage = i18n.t('common:required', { "0": i18n.t('common:gererevtgen.tempeff') });
        } else {
          errorMsg = CWTYPE.CUSTOM.validate(attrs.pourcentage, 0, 100);
          if (!CWSTR.isBlank(errorMsg)) {
            errors.pourcentage = errorMsg;
          }
        }
      }
    }
    _.each(this.attributes, (value: any, key: string) => {
      if (key.indexOf("evtg_") > -1) {
        if (!CWSTR.isBlank(value) && _.isObject(value)) {
          infocomp.push(value);
        }
      }
    });
    _.each(infocomp, (infocom): void => {
      const key = infocom.code.toLowerCase();
      let messageError = "";

      //Check required
      if (infocom.oblig === true && CWSTR.isBlank(attrs[key].valeur)) {
        messageError = i18n.t('common:required', { "0": attrs[key].libelle });
      }
      //Check max - min
      if (!CWSTR.isBlank(key) && !CWSTR.isBlank(attrs[key]) && !CWSTR.isBlank(attrs[key].valeur)) {
        if (infocom.type === "D" && (!CWSTR.isBlank(infocom.datemin) || !CWSTR.isBlank(infocom.datemax))) { //Type DATE
          messageError = CWTYPE.DATE.validate(attrs[key].valeur);
          if (CWSTR.isBlank(messageError)) {
            if ((!CWSTR.isBlank(infocom.datemin) && parseInt(attrs[key].valeur) < parseInt(infocom.datemin)) || (!CWSTR.isBlank(infocom.datemax) && parseInt(attrs[key].valeur) > parseInt(infocom.datemax))) {
              messageError = i18n.t('messages:GT_1040', { "1": infocom.libelle, "2": CWTYPE.DATE.format(infocom.datemin), "3": CWTYPE.DATE.format(infocom.datemax) });
            }
          }
        } else {
          if (infocom.type === "N" && (!CWSTR.isBlank(infocom.nummin) || !CWSTR.isBlank(infocom.nummax))) { //Tipe NUM
            if ((!CWSTR.isBlank(infocom.nummin) && parseInt(attrs[key].valeur) < parseInt(infocom.nummin)) ||
              (!CWSTR.isBlank(infocom.nummax) && parseInt(attrs[key].valeur) > parseInt(infocom.nummax))) {
              messageError = i18n.t('messages:GT_1041', { "1": infocom.libelle, "2": infocom.nummin, "3": infocom.nummax });
            }
          }
        }
      }
      if (!CWSTR.isBlank(messageError)) {
        if (CWSTR.isBlank(errors[key])) {
          errors[key] = {};
        }
        errors[key].valeur = messageError;
      }
    });
    if (CWSTR.isBlank(attrs.datefin) && !CWSTR.isBlank(attrs.datedeb)) {
      attrs.datefin = attrs.datedeb;
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  save(attributes?: any, options?: ModelSaveOptions): void {
    if (this.action !== "refuser" && this.action !== "accepter") {
      this.unset("profils");
      this.unset("table");
      this.unset("justifverif");
      this.unset("justif");
    }
    super.save.call(this, attributes, options);
  }

  _calculateDay(datedeb: string, datefin: string): number {
    const start = this._parseDate(datedeb);
    const end = this._parseDate(datefin);
    const startMs = start.getTime();
    const endMs = end.getTime();
    const diffMs = endMs - startMs;
    const diffMsAux = diffMs / 86400000;
    let res = Math.trunc ? Math.trunc(diffMsAux) : diffMsAux - diffMsAux % 1;//Math.trunc doesn't work on IE11 and lower

    res = res + 1;
    return res;
  }

  _parseDate(string: string): Date {
    const year = string.substring(0, 4);
    const month = string.substring(4, 6);
    const day = string.substring(6, 8);

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (this.MODEGROUPE === false) {
      if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
        response.id = response.evenement;
      }
    }
    if (response.result) {
      return null;
    }
    return response;
  }
}
