import { CWActiviteModel } from 'common/evenements/planifier/activite/models/cwActivite.model';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

export class CWTreeModel extends CWBaseModel {
  root: any
  firstLevelNode: any;
  coll: any;
  draggable: any;
  rootModel: CWActiviteModel;
  firstLevelModels: any;
  buildUrlColl: any;
  dragAndDropCallback: any;
  renderer: any;
  tooltipRenderer: any;
  overLabelTooltipRenderer: any;
  checkClass: any;
  selectableNode: any;
  manyRoots: boolean;
  checkedColl: any;
  node: any;
  checkPeriode: (...args: any[]) => any;

  /**
   * Event when choose a Node with double click.
   */
  /**
   * Event when select a Node with the Ctrl Key pressed.
   */
  /**
   * Event when select a Node.
   */

  defaults(): { [key: string]: any } {
    return {
      label: "",
      maxLevels: 0
    }
  }

  /**
   * Model of the Tree Component
   *
   */
  constructor(params?: { [key: string]: any }) {
    params = params || {};
    super(params);
    if (params && params.coll) {
      this.coll = params.coll;
    }
    this.set("label", params.name);
    if (params && params.draggable) {
      this.draggable = params.dragable;
    } else {
      this.draggable = false;
    }
    if (params && params.rootModel) {
      this.rootModel = params.rootModel;
    }
    if (params && params.manyRoots === true) {
      this.manyRoots = true;
      if (params && params.firstLevelModels) {
        this.firstLevelModels = params.firstLevelModels;
      }
      if (!CWSTR.isBlank(this.rootModel) && CWSTR.isBlank(this.rootModel.firstLevelColl)) {
        throw new Error("You must inform a firstLevelColl in order to use a fictitious Root : ");
      }
    }
    if (params && params.buildUrlColl) {
      this.buildUrlColl = params.buildUrlColl;
    }
    if (params && params.dragAndDropCallback) {
      this.dragAndDropCallback = params.dragAndDropCallback;
    }
    if (params && params.renderer) {
      this.renderer = params.renderer;
    }
    if (params && params.tooltipRenderer) {
      this.tooltipRenderer = params.tooltipRenderer;
    }
    if (params && params.overLabelTooltipRenderer) {
      this.overLabelTooltipRenderer = params.overLabelTooltipRenderer;
    }
    if (params && params.checkClass) {
      this.checkClass = params.checkClass;
    }
    if (params && params.selectableNode) {
      this.selectableNode = params.selectableNode;
    }
    if (params && params.checkPeriode) {
      this.checkPeriode = params.checkPeriode;
    }
  }
}