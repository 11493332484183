/**
 * View thought to be the last tabbable element in the page. If there is a visible form in edited mode,
 * this view does the last input element take the focus to the save button.
 */
export var FocusToFormSaveView = Backbone.View.extend({

  conf: Configuration,

  events: {
    "focus": "_goToLogout"
  },

  /**
   * If a save button is enabled and visible, this method set the focus on it.
   */
  _goToLogout: function(event) {
    var saveButton = $(".save[aria-disabled=false]").filter(":visible");

    if (saveButton.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      saveButton.focus();
    }
  }
});
