import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseGridView } from 'core/grids/basegrid/cwBaseGrid.view';
import { CWSTR } from 'utils/cwStr';
import { CWTreeTypeGridRowView } from './cwTreeTypeGridRow.view';


export class CWTreeTypeGridView extends CWBaseGridView {

  /**
   * Default name of the view
   */
  DEFAULT_NANE: any;

  /**
   * Icons to be used as  expandable and collapsable icons
   * by default they are the triangle icons
   * First element is expandable, and second element of the array is the collapsible
   */
  expCollIcons: any;
  /**
   * If set to true, table stores expanded elements and, when table is repainted it tries to expand again the elements
   * taht were expanded
   */
  keepExpanded: boolean;
  /**
   * Array of ids of the elements expanded.
   * Only used when keepExpanded=true
   */
  arrayOfExpanded: any;
  /**
   * Constructor
   * View underlying a Tree type DataGrid
   * in usecase in the cellrenderer method, to decide which class will encapsulate the draggable element. Also the column which will contain this class, should be tagged with
   * draggable=true;
   */

  /** rowAltPersonalised
   * S'il existe et vaut true, la table ne peint pas les lignes grises chaque deux files mais il le fait en fonction du model is collapsible et d'autres options.
   */
  rowAltPersonalised: boolean;

  /** expandAll
   * S'il existe et il vaut true, il répand tous les noeuds
   * Seulement utilisé avec keepExpanded=true
   */
  expandAll: boolean;

  draggableClass: any;

  fetchSubTree: (arg1?: any, arg2?: any) => any;

  constructor(options: Backbone.ViewOptions | any) {
    options = options || {};
    super(options);
    this.DEFAULT_NANE = "Data";
    this.expCollIcons = { expandable: "ui-icon ui-icon-triangle-1-e", collapsible: "ui-icon ui-icon-triangle-1-s" };
    this.keepExpanded = false;
    this.arrayOfExpanded = [];
    this.rowAltPersonalised = false;
    this.expandAll = false;


    CWBaseGridView.prototype.initialize.call(this, options);

    if (options && options.expCollIcons) {
      this.expCollIcons = options.expCollIcons;
    }
    if (options && options.draggableClass) {
      this.draggableClass = options.draggableClass;
    }
    if (options && options.keepExpanded) {
      this.keepExpanded = options.keepExpanded;
    }
    if (options && options.rowAltPersonalised) {
      this.rowAltPersonalised = options.rowAltPersonalised;
    }
    if (options && options.expandAll) {
      this.expandAll = options.expandAll;
    }
    // Show/hide columns
    this.model.on("cleanExpanded", this.cleanExpanded, this);
  }

  /**
   * Paints the view of the tree grid
   */
  render(): CWTreeTypeGridView {

    if ($(this.el).children().length === 0) { // Render the header only one time
      $(this.el).html(this.template());
      // Header
      this._renderHead();
    }

    //Configure height and width of the component
    //const tableScroll = $(this.el).find(".c-grind__scroll");
    /*tableScroll.height(this.height);
    tableScroll.css("overflow-y", "auto");
    tableScroll.css("overflow-x", "auto");*/

    //Width:
    $(this.el).width(this.width);

    const tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);
      /*
      $(".phx-grid-scroll", this.el).scroll((event: any): void => {
         this._scrollControl(event);
         $(".phx-grid-header-scroll", this.el).scrollLeft($(this).scrollLeft());
      });
      */
    }
    this.positionHeader();

    if (!CWSTR.isBlank(this.model) && !CWSTR.isBlank(this.model.coll) && this.model.coll.paginated) {
      $(".list_arrows", this.el).show();
      if ((this as any)._showArrows) {
        (this as any)._showArrows();
      }
    }

    return this;
  }

  /**
   * Function called when we want to clena the array of expanded
   */
  cleanExpanded(): void {
    this.arrayOfExpanded = [];
  }
  /**
   * Expands and collapses every node of a row in the tree grid
   */
  _expandCollapseRow(model: any): boolean {
    const rows = this.$el.find("span[treetype_path^='" + model.path + ".']");
    const treeGroup = this.$el.find("tr[data-path='" + model.path + "']");

    if (model.isExpanded === false || CWSTR.isBlank(model.hasChild)) { //expand row 
      if (!CWSTR.isBlank(this.fetchSubTree) && CWSTR.isBlank(model.hasChild)) {
        this.fetchSubTree(model, this._addRows);
      }

      model.isExpanded = true;

      //Add element to arrayOfExpanded
      if (this.keepExpanded === true && !_.contains(this.arrayOfExpanded, model.get("id"))) {
        this.arrayOfExpanded.push(model.get("id"));
      }
      const span = this.$el.find(".treetbl_expbtn[item = " + model.item + "][level = " + model.level + "][branch='" + model.branch + "']");
      span.removeClass(this.expCollIcons.expandable);
      span.addClass(this.expCollIcons.collapsible);

      rows.parents("tr").show();
      // Accessibility parameters
      treeGroup.attr("aria-expanded","true");

      this._setModelVisibility(rows.parents("tr"), true);

      let finded = false;
      let end = false;
      let lastLevelCollapsed = null;
      for (let i = 0; i < this.model.coll.models.length && !end; i++) {
        if (finded === true) {
          if ((this.model.coll.models[i] as any).level <= model.level || (this.model.coll.models[i] as any).branch !== model.branch) {
            end = true;
          } else {
            if ((this.model.coll.models[i] as any).level <= lastLevelCollapsed) {
              lastLevelCollapsed = null;
            }

            if ((this.model.coll.models[i] as any).isExpanded === false) {
              if (CWSTR.isBlank(lastLevelCollapsed) || (this.model.coll.models[i] as any).level < lastLevelCollapsed) {
                lastLevelCollapsed = (this.model.coll.models[i] as any).level;
                const hiddenRows = this.$el.find("span[treetype_path^='" + (this.model.coll.models[i] as any).path + ".']");
                hiddenRows.parents("tr").hide();
                this._setModelVisibility(hiddenRows.parents("tr"), false);
              }
            }
          }
        }
        if ((this.model.coll.models[i] as any).path === model.path) {
          finded = true;
        }
      }
    } else { //collapse rows

      //Remove element from arrayOfExpanded
      if (this.keepExpanded === true && _.contains(this.arrayOfExpanded, model.get("id"))) {
        const position = _.indexOf(this.arrayOfExpanded, model.get("id"));
        this.arrayOfExpanded.splice(position, 1);
      }

      const span = this.$el.find(".treetbl_expbtn[item = " + model.item + "][level = " + model.level + "][branch='" + model.branch + "']");
      span.removeClass(this.expCollIcons.collapsible);
      span.addClass(this.expCollIcons.expandable);

      rows.parents("tr").hide();
      // Accessibility parameters
      treeGroup.attr("aria-expanded","false");
      this._setModelVisibility(rows.parents("tr"), false);

      const re = new RegExp('^' + model.path + '\\.');
      const modelsToDelete = [];
      for (let x = 0; x < this.model.coll.models.length; x++) {
        if (re.test((this.model.coll.models[x] as any).branch)) {
          modelsToDelete[x] = this.model.coll.models[x];
        }
      }
      this.model.coll.remove(modelsToDelete);
      this.$el.find("tr[data-branch^='" + model.path + ".']").remove();

      if (modelsToDelete.length !== 0) {
        model.hasChild = undefined;
      }
      model.isExpanded = false;
    }
    return false;
  }

  /**
   * Update visibillity in the model that represents each row
   */
  _setModelVisibility(rows: any, visibility: any): void {

    _.each(rows, (row: any): void => {
      const dataId = $(row).attr("data-id");
      const model = this.model.coll.get(dataId);
      if (!CWSTR.isBlank(model)) {
        (model as any).visible = visibility;
      }
    });

  }
  /**
   * Adds some rows models in a collection to the tree grid row model(add nodes to an existing node)
   */
  _addRows(model: any, coll: any, tableColl: any): void {
    if (!CWSTR.isBlank(model) && !CWSTR.isBlank(coll) && coll.models.length > 0 && !CWSTR.isBlank(tableColl)) {
      model.hasChild = true;

      //order the collection by branch/level
      coll.comparator = (model: any): string => {
        return String(model.branch) + String(model.level) + String(model.item);
      };
      coll.sort();

      const index = tableColl.models.indexOf(model) + 1;
      tableColl.add(coll.models, { at: index });

      tableColl.trigger("reset");
    } else if (!CWSTR.isBlank(model) && !CWSTR.isBlank(tableColl)) {
      model.hasChild = false;
      tableColl.trigger("reset");
    }

  }
  /**
   * Adds the number of the items (branch number,level number and number of item in the branch) to every node of a collection
   */
  _addItemNumbers(branch: any, level: any, coll: any): void {
    if (!CWSTR.isBlank(branch) && !CWSTR.isBlank(level)) {
      const spans = this.$el.find("span[branch='" + branch + "'][level=" + level + "]");
      if (CWSTR.isBlank(spans.attr("item"))) {
        if (!CWSTR.isBlank(coll)) {
          let counter = 1;
          for (let i = 0; i < coll.length; i++) {
            if (coll.at(i).branch === branch && coll.at(i).level === level) {
              coll.at(i).item = _.clone(counter);
              counter++;
            }
          }
        }
      }
    }
  }

  /**
   * Overwrites the method _paintRows from the parent class
   */
  _paintRows(): any {
    const tbody = $(this.el).find("tbody.c-grind__table__body");
    if (tbody.length === 0) {
      // Avoid an initial call during Workflow setUp before the page is rendered
      return;
    }

    tbody.empty();
    _.each(this.currentVue._columns, (column: any): void => {
      column.cells = [];
    });

    let actualBranch = -1;
    let actualLevel = -1;
    let lastBranch = -1;
    let lastLevel = -1;
    let visible = true;
    let firstCollapsedLevel = null;
    let parentPath = null;
    for (let i = 0; i < this.model.coll.length; i++) {
      const rModel: any = this.model.coll.at(i);

      if (this.keepExpanded === true && rModel.hasChild === true && (_.contains(this.arrayOfExpanded, rModel.get("id")) || this.expandAll === true)) {
        rModel.isExpanded = true;
      }

      lastBranch = actualBranch;
      lastLevel = actualLevel;

      let changed = false;

      if (actualBranch !== rModel.branch) {
        actualBranch = rModel.branch;
        visible = true;
        firstCollapsedLevel = null;
        changed = true;
      }

      if (actualLevel !== rModel.level) {
        actualLevel = rModel.level;
        changed = true;
      }

      if (actualLevel <= firstCollapsedLevel) {
        visible = true;
        firstCollapsedLevel = null;
      }

      if (changed === true) {
        this._addItemNumbers(actualBranch, actualLevel, this.model.coll);
      }

      //add path
      if (lastBranch !== rModel.branch) {
        parentPath = rModel.branch;
      } else {
        if (lastLevel > rModel.level) {
          const array: any = parentPath.split(".");
          let u = 0;

          for (u = 0; u <= (lastLevel - rModel.level); u++) {
            array.pop();
          }

          parentPath = array.join(".");
        } else if (lastLevel === rModel.level) {
          const array: any = parentPath.split(".");
          array.pop();
          parentPath = array.join(".");
        }
        parentPath += "." + rModel.item;
      }
      rModel.path = parentPath;

      rModel.off("treetbl_exp");
      rModel.on("treetbl_exp", this._expandCollapseRow, this);
      rModel.off("treetbl_exp_select_row");
      rModel.on("treetbl_exp_select_row", this.model._manageRowSelection, this.model);
      const row = new CWTreeTypeGridRowView({ model: rModel, dataGrid: this }).render().el;
      /*$(row).addClass("ui-grid-row");
      if (this.rowAltPersonalised && rModel.collapsible === true) {
        $(row).addClass("ui-grid-row-alt");
      } else if (!this.rowAltPersonalised) {
        if (i % 2 === 1) {
          $(row).addClass("ui-grid-row-alt");
        }
      }*/
      tbody.append(row);

      if (visible === true) {
        $(row).show();
      } else {
        $(row).hide();
      }
      rModel.visible = visible;

      if (rModel.isExpanded === false) {
        visible = false;
        if (CWSTR.isBlank(firstCollapsedLevel) || firstCollapsedLevel > actualLevel) {
          firstCollapsedLevel = actualLevel;
        }
      }

      //Accessibility
      if (i === 0 && !CWSTR.isBlank($(row).children()[0])) {
        if ($(row).children().eq(0).find(":input").is(":input")) {
          $(row).children().eq(0).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus");
        } else {
          $(row).children().eq(0).attr({ "tabindex": "0" }).addClass("grid-cell-focus");
        }
      }
    }

    // Toggle control
    // Hide the columns that must be hidden by default.
    if (this.currentVue._visibleColumns.length > 0) {
      _.each(_.difference(_.keys(this.currentVue._columns), this.currentVue._visibleColumns), (key: any): void => {
        if (key.indexOf("phx-") !== 0) {
          this.model.trigger("toggle:column", key);
        }
      }, this);

      /**
       * Visible columns of the tree grid
       */
      this.currentVue._visibleColumns = [];
    }

    // Hide the columns that must be hidden by default.
    if (this.currentVue._lockedColumns.length > 0) {
      _.each(this.currentVue._lockedColumns, (key: any): void => {
        this.model.trigger("lock:column", key);
      }, this);

      /**
       * Locked columns of the tree grid
       */
      this.currentVue._lockedColumns = [];
    }

    // Adjust scroll
    if (!this.rowHeight || this.rowHeight === 0) {
      this._calculateRowsPerPage();
    }
    let totalRecords = this.model.coll.totalRecords;
    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }
    const startIndex = this.model.coll.pagination.startIndex;
    const size = this.model.coll.pagination.size;
    const endIndex = Math.min(startIndex + size, totalRecords);
    const lRowHeight = (CWSTR.isBlank(this.rowHeight) ? 0 : parseInt(String(this.rowHeight), 10));
    const extraHeight = (totalRecords - endIndex) * lRowHeight;
    const preHeight = (startIndex) * lRowHeight;

    $(".c-grind__scroll-extra2", this.el).height(extraHeight);
    $(".c-grind__scroll-extra1", this.el).height(preHeight);
    this._renderFilteredRowsInTitle();
    if (this.multiselection) {
      this.currentVue._columns["phx-multiselect"].header._manageChecking();
    }
    $(".c-grind__footer-right", this.el).html(endIndex + "/" + totalRecords);
    // dynamic resize height of the grid
    this._resizeGrid();
    if (this.model.coll.length !== 0) {
      this._renderFilteredRowsInTitle();
      $(this.el).find("thead").show();
    }
  }

  _manageVueChangeCallback(): void {
    this.model.coll.trigger("reset");
  }
}
