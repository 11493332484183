import { CWAuthModel } from './../app/models/cwAuth.model';
import { objs } from 'src/objectsRepository';

export class CWAUTHMANAGER {

  static getUserConectedModel(): CWAuthModel {
    return objs.appRt.workflow.authModel;
  }

  static getMatricule(): string {
    return CWAUTHMANAGER.getUserConectedModel().get('matricule');
  }

  /**
   * retroune le genre de la persone connecté : Masculin (M) ou Féminin (F)
   */
  static getGenre(): string {
    return CWAUTHMANAGER.getUserConectedModel().get('sexe');
  }

  static getUserId(): string {
    return objs.appRt.workflow.authModel.get("user")
  }
}