import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';

/**
 * Type de Structure Model
 */
export class CWTypeStructureModel extends CWReadOnlyModel {

  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "codef": "null",
      "libelle": "null",
      "abrege": "null",
      "dated": "null",
      "format": null,
      "indiczonesco": "null",
      "prioritejourex": null,
      "rattcollab": "null",
      "nbelements": null,
      "nbcollab": null,
      "nbcollabnonrattache": null,
      "principal": null
    }
  }
}
