import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWTYPE } from 'tda/cwTda';


/**
 * String format utility class
 */
export class CWSTR {

  /**
   * Checks if a text is empty
   */
  static isEmpty(str: any): boolean {
    let lStr = str;

    if (lStr === 0) {
      lStr = String(lStr); // To avoid problems when id is 0.
    }
    return (!lStr || 0 === lStr.length);
  }

  /**
   * Checks if a text is blank or null
   */
  static isBlank(str: any): boolean {
    let lStr = str;

    if (lStr === 0 || typeof lStr === "boolean") {
      lStr = String(lStr); // To avoid problems when id is 0.
    }
    return (!lStr || /^\s*$/.test(lStr));
  }

  static isNull(str: any): boolean {
    return (str === null || str === "null" || (Array.isArray(str) && str.length === 0));
  }

  static isUndefined(str: any): boolean {
    return (str === undefined || str === "undefined");
  }

  /**
   * Replaces the parametres on the message with the parametres value.
   */
  static buildMessageParametres(message: string, parametres: { [key: string]: any }): string {
    let result = "";

    if (!CWSTR.isBlank(message)) {
      result = message;
      if (!CWSTR.isBlank(parametres)) {
        const array = [];

        for (const item in parametres) {
          if (Object.prototype.hasOwnProperty.call(parametres, item)) {
            array.push(item);
          }
        }
        array.sort(function (a, b) {
          return b.length - a.length;
        });
        for (let j = 0; j < array.length; j++) {
          if (!CWSTR.isBlank(parametres[array[j]])) {
            result = result.replace(array[j], parametres[array[j]]);
          } else {
            result = result.replace(array[j], "");
          }
        }
      }
    }
    return result;
  }

  /**
   * Format an array of Messages by joining all of them and putting a back
   */
  static formatMessage(msgs: { [key: string]: any } | string[]): string {
    let strMsg = "";

    // Valid for arrays ["msg1","msg2"] or objects {1:"msg1",2:"msg2"}, or
    // complex objects like {1:{2:"msg1"},2:"msg2"}
    _.each(msgs, (value): void => {
      if (_.isObject(value)) {
        value = CWSTR.formatMessage(value);
        value = value.substring(2, value.length - 1);
      }
      strMsg = strMsg + "* " + value + "\n";
    });
    return strMsg;
  }

  /*
   Return formated value from a Caractéristique Paramétrable
    caracteristique: { code: "personum", tda: {...}, masque: "&&:&&", type: "N", valeur: 10, ... }
   */
  static formatCarpersValue(caracteristique: { [key: string]: any }): string {
    let result: string = "";
    let tdaType: any;
    const value = caracteristique.valeur;
    let masque = caracteristique.masque;// Get masque

    switch (caracteristique.type) {
      case "C":
        break;
      case "N":
        tdaType = CWTYPE.LONG;
        break;
      case "D":
        tdaType = CWTYPE.DATE;
        break;
      /* no default */
    }
    // Overwrite tda type if selected
    if (!CWSTR.isBlank(caracteristique.tda)) {
      tdaType = CWTYPE._getTypeByCode(caracteristique.tda)
    }
    // If no masque, take it from tdatype
    if (CWSTR.isBlank(masque) && tdaType && tdaType.format) {
      masque = CWTYPE._getFormatByCode(caracteristique.tda);
    }
    // Format value if masque exists
    if (tdaType && tdaType.format) {
      result = tdaType.format(value, masque);
    } else {
      result = value;
    }
    if (!CWSTR.isBlank(caracteristique.codif) && !CWSTR.isBlank(caracteristique.valeur) && !CWSTR.isBlank(caracteristique.codif.code)) {
      if (!CWSTR.isBlank(caracteristique.valeurcodif.libelle)) {
        result = caracteristique.valeurcodif.libelle + " (" + caracteristique.valeurcodif.code + ")";
      } else {
        result = caracteristique.valeurcodif.code;
      }
    }
    return result;
  }

  /**
   * Get an el value from a model : getElValue(model, "regleCalcul.libelle")
   *
   */
  static getElValue(model: CWBaseModel | Backbone.Model, property: string): any {
    const tokens = property.split(".");
    const rootValue = CWSTR.isBlank(model) ? null : model.get(tokens[0]);
    let value = rootValue;

    for (let i = 1; i < tokens.length; i++) {
      if (CWSTR.isBlank(value)) {
        return null;
      }
      value = value[tokens[i]];
    }
    return value;
  }

  /**
   * Similar to getElValue but from a JSON object instead of a model.
   *
   */
  static getElValueFromObj(object: { [key: string]: any }, property: string): any {
    const tokens = property.split(".");
    let rootValue = null;
    let value = null;

    if (!_.has(object, tokens[0])) {
      return null;
    }
    rootValue = object[tokens[0]];
    value = rootValue;
    for (let i = 1; i < tokens.length; i++) {
      if (value === null || value === undefined) {
        return null;
      }
      value = value[tokens[i]];
    }
    return value;
  }

  /**
   * Set an el value to a model : setElValue(model, "regleCalcul.code",
   * "REGUL")
   *
   */
  static setElValue(model: Backbone.Model | CWBaseModel, property: string, value: any, options?: { [key: string]: any }): Backbone.Model {
    const tokens = property.split(".");
    let objectValue: { [key: string]: any } = {};

    if (tokens.length > 1) {
      // Complex element
      objectValue = _.clone(model.get(tokens[0]));
      if (objectValue === null || objectValue === undefined) {
        objectValue = {};
      }
      objectValue = CWSTR._extendObject(objectValue, tokens, 1, value);
    } else {
      // Simple element
      objectValue = value;
    }
    if (CWSTR.isBlank(model)) {
      model = new Backbone.Model();
    }
    return model.set(tokens[0], objectValue, options);
  }

  /**
   * Replaces the char at the desired position for the string
   */
  static replaceCharAt(str: string, index: number, chr: string): string {
    if (index > str.length - 1) { return str; }
    return str.substr(0, index) + chr + str.substr(index + 1);
  }

  /**
   * Recursive method that insert a value in a complex object.
   */
  private static _extendObject(object: { [key: string]: any }, tokens: Array<any>, index: number, value: any): { [key: string]: any } {
    object = !CWSTR.isNull(object) ? object : {};
    if (tokens.length - 1 === index) {
      object[tokens[index]] = value;
      return object;
    }
    if (!object[tokens[index]]) { object[tokens[index]] = {}; }
    _.extend(object[tokens[index]], CWSTR._extendObject(object[tokens[index]], tokens, index + 1, value));
    return object;
  }

  static extractObjectValues(object: { [key: string]: any }): { [key: string]: any } {
    const auxArray = _.values(object);

    _.each(auxArray, (it: { [key: string]: any }, index: number): void => {
      if (_.isObject(it)) {
        auxArray[index] = CWSTR.extractObjectValues(it);
      }
    });
    _.flatten(auxArray);
    return auxArray;
  }

  static toFirstUpperCase(string: string): string {
    return string.substring(0, 1).toUpperCase() + string.substring(1);
  }

  static convertToAscii(string: string): string {
    let resultString = "";

    if (!CWSTR.isBlank(string)) {
      for (let i = 0; i < string.length; i++) {
        resultString += "&#" + string.charCodeAt(i) + ";";
      }
    }
    return resultString;
  }

  static escapeCSSSelector(str: string): string {
    // eslint-disable-next-line no-control-regex
    const regCssescape = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g;
    const funcCssescape = (ch: string, asCodePoint: boolean): string => {
      if (asCodePoint) {
        // U+0000 NULL becomes U+FFFD REPLACEMENT CHARACTER
        if (ch === "\0") {
          return "\uFFFD";
        }
        // Control characters and (dependent upon position) numbers get escaped as code points
        return ch.slice(0, -1) + "\\" + ch.charCodeAt(ch.length - 1).toString(16) + " ";
      }
      // Other potentially-special ASCII characters get backslash-escaped
      return "\\" + ch;
    };

    return (str).replace(regCssescape, funcCssescape);
  }
}