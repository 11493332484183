import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

export class CWMarqueursModel extends CWBaseModel {

  public dated: string;
  public datef: string;
  public collab: string;
  public matric: any

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = options.usecase || "calendacc";
    this.params = {};
    this.dated = null;
    this.datef = null;
    this.collab = null;
    this.matric = null;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/calmarqueurexp/collaborateur/" + encodeURIComponent(this.matric) + "/" + this.dated + "/" + this.datef;
    };
  }
}