import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLSelecteurActivites from '../cwSelecteurActivite.tpl.html';
import { CompleteWFL } from '../models/completeWFL.js';
import { CWAutocompleteColl } from 'core/components/combo/cwAutocomplete.collection';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWCompleteGeneralView } from './cwCompleteGeneral.view';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { FORMS } from 'utils/forms.js';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { NivColl } from '../models/niv.collection.js';
import { objs } from 'src/objectsRepository';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { ResultItemView } from './resultItem.view.js';
import { SearchCheminActivite } from '../models/searchCheminActivite.js';
import { SelecteurActiviteModeSimpleActiviteModel } from '../models/selecteurActiviteModeSimpleActivite.model.js';
import { SelecteurActiviteModeSimpleColl } from '../models/selecteurActiviteModeSimple.collection.js';
import { SYNC } from 'src/utils/sync.js';
import { UTILS } from 'src/utils/utils.js';

export class CWSelecteurActivitesView extends Backbone.View {


  isSelecteurActivitesView: boolean;

  /**
   * Minimum string length to do the autocomplete search
   */
  minSearchLength: any;

  /**
   * Maximum number of items in the list of results to show
   */
  maxResultItems: any;

  /**
   * Generate Label instead let it being generated by usecase
   */
  generateLabel: boolean;

  params: any;
  context: { [key: string]: any };
  coll: any;
  name: any;
  title: any;
  height: any;
  width: any;
  fieldWidth: any;
  niveauColls: { [key: string]: any }[];
  addItemCallback: any;
  removeItemCallback: any;
  selection: CWAutocompleteColl;
  workflow: typeof CompleteWFL;
  view: any;
  label: any;
  appended: boolean;
  input: JQuery;
  listRenderer: (args: { [key: string]: any }) => string;
  popup: any;
  viewData: { context: any; parameters: any; currentSelection: any; workflow: any };
  imbriqueView: any;
  recentOnMouseEnter: boolean;
  parameters: any;
  term: any;
  nodeType: any;
  public $appendTo: JQuery; //pour les messages
  placeholder: string;
  widthDifferentAsParent: boolean;

  /**
   * Constructor
   * Selecteur d'activité mode simple view
   */
  constructor(options: Backbone.ViewOptions | any) {
    options = options || {};
    options.events = _.extend({
      "click .cw-selecteur-activite-button": "_toggleCompleteView",
      "keyup .cw-selecteur-activite-button": "_keyupIcon",
      "autocompleteopen": "_openlist",
      "autocompleteselect": "_selectItem",
      "change .cw-selecteur-activite-input": "_changeItem",
      "keyup .cw-selecteur-activite-input": "_enterPress",
      "mouseenter .cw-selecteur-activite-input": "_manageMouseenter",
      "autocompletefocus": "_keyboardMenuItemFocus"
    }, options.events);
    options.tagName = "div";
    options.className = "cw-selecteur-activite";
    /**
  * Width of the combo list
  */
    options.listWidth = "400px";
    super(options);
    this.isSelecteurActivitesView = true;
    this.minSearchLength = Configuration.selecteurActivites.minCharactersToSearch;
    this.maxResultItems = Configuration.selecteurActivites.maxResultItems;
    this.generateLabel = false;
    /**
     * HTML template of the view
     */
    this.template = TPLSelecteurActivites;
    /**
     * Parameters of the initialization
     */
    this.params = options;
    if (options && !CWSTR.isBlank(options.context)) {
      /**
       * Multiselection indicator
       * params.context.ctxUtilisateur - Obligatoire {Collaborateur, Responsable}
       * params.context.ctxHabilitation - Obligatoire
       * params.context.ctxEcran - Obligatoire
       * params.context.ctxUtilisation - Obligatoire
       * params.context.ctxActivitesInitiales - Array of activities ( format SelecteurActiviteItemModel) to load in the selecteur.
       * params.context.ctxPeriodeGestion
       * params.context.ctxIndExisteDateDeb
       * params.context.ctxIndExistePeriode
       * params.context.ctxPeriodeHistoCour
       * params.context.ctxPeriodeHistoPrec
       * params.context.ctxTypeEvtGere - Optional {P,R}
       * params.context.ctxTypologieActivite - Obligatoire
       * params.context.ctxDomainesActivités
       * params.context.ctxCollab
       * params.context.ctxActivHeritage
       * params.context.ctxModeRestitution - Obligatoire -{Simplifie,Complet}
       * params.context.ctxAccesModeComplet
       * params.context.ctxSelectMultiple
       * params.context.ctxConsultationSeule
       * params.context.ctxAffIndicTemporel
       * params context.ctxFiltreStructCollab
       * params.context.ctxHierAutorise
       * params.context.ctxRatthorsreg
       * params.context.ctxBesoinPrioritaire
       * params.context.ctxFeuilleTemps 
       * params.context.ctxCompteAnalytiqueRef
       * params.context.ctxInaptitude
       * params.context.ctxPalette - filtre des domaines, pour les palettes, retire les domaines feuilles de temps et grille de besoins
       *
       * ----------Context added for IHM prupose-------
       * params.context.ctxmodeRepresentation {pop-up,Imbrique}
       * params.context.ctxImbriqueEl - Only necessary for modeRepresentation imbrique. Informs about
       *                                the html element where mode complete will be imbrique
       * params.context.ctxPourPlanningMedical - pour filtrer les activites de type médical ou sans l'option. Il est appelé depuis la vuejournee
       *                                      du planning médical
       * params.context.ctxOnlySelectLeaf - in popoup view only are selectable leaft elements
       * params.context.horsStructExplotation - pour les activités avec grille non médicals sans structure, en explotation.
       */
      this.context = options.context;
      this.initializeContext();
      this._updateCollParametres();//Ici, l'initialisation de "this.parameters"
    }
    /**
     * Collection of elements of the component
     */
    this.coll = new SelecteurActiviteModeSimpleColl();
    this.coll.setCollParameters(this.parameters);
    const foncCour = this._obtainFoncCour();
    const habContext = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCour
    });
    this.coll.setHabContext(habContext);

    if (options && !CWSTR.isBlank(options.name)) {
      /**
       * Name of the component
       */
      this.name = options.name;
    }

    /**
     * Title of the component
     */
    this.title = i18n.t('common:selecteuractivites.dlgtitle');

    /**
     * Height of the component
     */
    this.height = undefined;
    if (options && options.height) {
      this.height = options.height;
    } else {
      this.height = 500;
    }

    /**
     * Width of the component
     */
    this.width = undefined;
    if (options && options.width) {
      this.width = options.width;
    } else {
      this.width = 800;
    }

    /**
     * Width of the input field of the component
     */
    this.fieldWidth = undefined;
    if (options && options.fieldWidth) {
      const lRegxp = /^([0-9\.]+)[%]?$/g; //eslint-disable-line
      if (lRegxp.test(options.fieldWidth) || options.fieldWidth === "auto") {
        this.fieldWidth = options.fieldWidth;
      } else {
        throw new Error("the fieldWidth must be numeric (" + this.name + ")");
      }
    } else {
      this.fieldWidth = "100%";
    }

    /**
     * Array of collections with niveau data. One collection per each structure
     */
    if (options && options.niveauColls) {
      this.niveauColls = options.niveauColls;
    } else {
      this.niveauColls = [];
    }

    /**
     * Callback function to execute when an element is added
     */
    this.addItemCallback = options.addItemCallback;

    /**
     * Callback function to execute when an element is deleted
     */
    this.removeItemCallback = options.removeItemCallback;

    if (options && options.generateLabel) {
      this.generateLabel = options.generateLabel;
    }

    // response list filled with user selection
    /**
     * Selection list of elements of the autocompletion results
     */
    this.selection = new CWAutocompleteColl();
    this.selection.itemRenderer = this._getAttrsRenderer();
    this.selection.on("click:item", this._removeItem, this);
    this.model = new Backbone.Model();

    //Initialize workflow and pass to workflow context and parameters
    this.workflow = new CompleteWFL(options);
    this.$appendTo = options.appendTo;
    this.placeholder = !CWSTR.isBlank(options?.placeholder) ? options.placeholder : "";
    this.widthDifferentAsParent = options.widthDifferentAsParent ? true : false;
  }

  /**
   * obtain fonccour from context for habilitations
   */
  protected _obtainFoncCour(): { [key: string]: any } {
    let foncCour: { [key: string]: any } = {};

    if (this.context && this.context.ctxHabilitation && !_.isEmpty(this.context.ctxHabilitation.HabilitationGestion) && (this.context.ctxEcran !== "suivicollab" || this.context.ctxFeuilleTemps === true)) {
      switch (this.context.ctxTypeEvtGere) {
        case "P":
          if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion.prevue)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion.prevue;
          } else if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion;
          }
          break;
        case "R":
          if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion.realisee;
          } else if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion;
          }
          break;
        default:
          if (this.context.ctxHabilitation.HabilitationGestion.prevue) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion.prevue;
          }
          if (this.context.ctxHabilitation.HabilitationGestion.realisee) {
            if (_.isEmpty(foncCour)) {
              foncCour = this.context.ctxHabilitation.HabilitationGestion.realisee;
            } else {
              foncCour.actprev = this.context.ctxHabilitation.HabilitationGestion.prevue;
              foncCour.actreal = this.context.ctxHabilitation.HabilitationGestion.realisee;
            }
          }
          if (!_.isEmpty(this.context.ctxHabilitation.HabilitationGestion) && CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion.prevue) && CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationGestion;
          }
      }
    } else if (this.context && this.context.ctxHabilitation && !_.isEmpty(this.context.ctxHabilitation.HabilitationConsultation)) {
      switch (this.context.ctxTypeEvtGere) {
        case "P":
          if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.prevue)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation.prevue;
          } else if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationConsultation)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation;
          }
          break;
        case "R":
          if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation.realisee;
          } else if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationConsultation)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation;
          }
          break;
        default:
          if (this.context.ctxHabilitation.HabilitationConsultation.prevue) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation.prevue;
          }
          if (this.context.ctxHabilitation.HabilitationConsultation.realisee) {
            if (_.isEmpty(foncCour)) {
              foncCour = this.context.ctxHabilitation.HabilitationConsultation.realisee;
            } else {
              foncCour.actprev = this.context.ctxHabilitation.HabilitationConsultation.prevue;
              foncCour.actreal = this.context.ctxHabilitation.HabilitationConsultation.realisee;
            }
          }
          if (!_.isEmpty(this.context.ctxHabilitation.HabilitationConsultation) && CWSTR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.prevue) && CWSTR.isBlank(this.context.ctxHabilitation.HabilitationConsultation.realisee)) {
            foncCour = this.context.ctxHabilitation.HabilitationConsultation;
          }
      }
    }
    return foncCour;
  }
  /**
   * Function to check if context values are correct and initialize them
   */
  initializeContext(): void {
    const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;

    if (CWSTR.isBlank(this.context.ctxHabilitation) || CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion) &&
      CWSTR.isBlank(this.context.ctxHabilitation.HabilitationConsultation) || CWSTR.isBlank(this.context.ctxEcran) ||
      (CWSTR.isBlank(this.context.ctxTypologieActivite) && this.context.ctxConsultationSeule !== true) || CWSTR.isBlank(this.context.ctxModeRestitution)) {
      throw Error("The context for selecteur d'activité is not properly configured");
    }
    //SET CONTEXT DATES
    if (CWSTR.isBlank(this.context.ctxPeriodeGestion.datedeb) && CWSTR.isBlank(this.context.ctxPeriodeGestion.datefin)) {
      //If no date is informed search for today
      this.context.ctxPeriodeGestion.datedeb = today;
      this.context.ctxPeriodeGestion.datefin = today;
    } else if (!CWSTR.isBlank(this.context.ctxPeriodeGestion.datedeb) && CWSTR.isBlank(this.context.ctxPeriodeGestion.datefin)) {
      this.context.ctxPeriodeGestion.datefin = CWTYPE.DATE.INFINITY;
    } else if (CWSTR.isBlank(this.context.ctxPeriodeGestion.datedeb) && !CWSTR.isBlank(this.context.ctxPeriodeGestion.datefin)) {
      this.context.ctxPeriodeGestion.datedeb = CWTYPE.DATE.INITIAL;
    }
    this.context.ctxIndExisteDateDeb = !CWSTR.isBlank(this.context.ctxIndExisteDateDeb) ? this.context.ctxIndExisteDateDeb : true;
    this.context.ctxIndExistePeriode = !CWSTR.isBlank(this.context.ctxIndExistePeriode) ? this.context.ctxIndExistePeriode : true;
    if (!CWSTR.isBlank(this.context.ctxCollab)) {
      this.context.ctxFiltreStructCollab = !CWSTR.isBlank(this.context.ctxFiltreStructCollab) ? this.context.ctxFiltreStructCollab : true;
      if (this.context.ctxFiltreStructCollab === true) {
        this.context.ctxActivHeritage = !CWSTR.isBlank(this.context.ctxActivHeritage) ? this.context.ctxActivHeritage : true;
      } else {
        this.context.ctxActivHeritage = null;
      }
      this.context.ctxActivCompetenceExacte = !CWSTR.isBlank(this.context.ctxActivCompetenceExacte) ? this.context.ctxActivCompetenceExacte : true;
      this.context.ctxActivAvecInaptitude = !CWSTR.isBlank(this.context.ctxActivAvecInaptitude) ? this.context.ctxActivAvecInaptitude : false;
    } else {
      this.context.ctxFiltreStructCollab = null;
      this.context.ctxActivHeritage = null;
      this.context.ctxActivCompetenceExacte = null;
      this.context.ctxActivAvecInaptitude = null;
    }
    this.context.ctxRefActivUniq = !CWSTR.isBlank(this.context.ctxRefActivUniq) ? this.context.ctxRefActivUniq : false;
    this.context.ctxAccesModeComplet = !CWSTR.isBlank(this.context.ctxAccesModeComplet) ? this.context.ctxAccesModeComplet : true;
    this.context.ctxSelectMultiple = !CWSTR.isBlank(this.context.ctxSelectMultiple) ? this.context.ctxSelectMultiple : false;
    this.context.ctxConsultationSeule = !CWSTR.isBlank(this.context.ctxConsultationSeule) ? this.context.ctxConsultationSeule : false;
    this.context.ctxAffIndicTemporel = !CWSTR.isBlank(this.context.ctxAffIndicTemporel) ? this.context.ctxAffIndicTemporel : false;
    this.context.ctxFiltreHiertypeNiv = !CWSTR.isBlank(this.context.ctxFiltreHiertypeNiv) ? this.context.ctxFiltreHiertypeNiv : null;
    this.context.ctxHierAutorise = !CWSTR.isBlank(this.context.ctxHierAutorise) ? this.context.ctxHierAutorise : null;
    this.context.ctxRatthorsreg = !CWSTR.isBlank(this.context.ctxRatthorsreg) ? this.context.ctxRatthorsreg : null;
    //Feuille de temps
    this.context.ctxFeuilleTemps = this.context.ctxFeuilleTemps || false;//false par défaut 
    //Feuille de temps
    this.context.ctxCompteAnalytiqueRef = !CWSTR.isBlank(this.context.ctxCompteAnalytiqueRef) ? this.context.ctxCompteAnalytiqueRef : null;
    if ((!this.context.ctxConsultationSeule && !CWSTR.isBlank(this.context.ctxAccesModeComplet) && this.context.ctxAccesModeComplet === true) ||
      this.context.ctxModeRestitution === "Complet") {
      /**
       * Mode complet indicator
       */
      this.context.ctxAccesModeComplet = true;
      if (CWSTR.isBlank(this.context.ctxModeRepresentation)) {
        this.context.ctxModeRepresentation = "pop-up";
      }
      if (this.context.ctxModeRepresentation !== "Imbrique" && this.context.ctxModeRepresentation !== "pop-up") {
        throw Error("Invalid representation mode for ctxModeRepresentation");
      }
      /**
       * View for the mode complet dialog
       */
      this.view = CWCompleteGeneralView;
    }
  }

  modifyContext(context: { [key: string]: any }): void {
    this.context.ctxCollab = context.ctxCollab;
    if (!CWSTR.isBlank(context.ctxCollab)) {
      this.context.ctxActivCompetenceExacte = !CWSTR.isBlank(context.ctxActivCompetenceExacte) ? context.ctxActivCompetenceExacte : true;
      this.context.ctxActivAvecInaptitude = !CWSTR.isBlank(context.ctxActivAvecInaptitude) ? context.ctxActivAvecInaptitude : false;
    } else {
      this.context.ctxActivCompetenceExacte = null;
      this.context.ctxActivAvecInaptitude = null;
    }
  }

  /**
   * Cleans the selection of the component
   */
  cleanSelection(): void {
    this.$el.find(".cw-selecteur-activite-multiSelection").empty();
    this.selection.reset();
    if (this.$el.find(".cw-selecteur-activite-input").data('ui-tooltip')) {
      this.$el.find(".cw-selecteur-activite-input").tooltip('destroy');
    }
  }

  protected _loadActivitesInitiales(): void {

    if (!CWSTR.isBlank(this.context.ctxActivitesInitiales)) {
      this.clearSelection();
      _.each(this.context.ctxActivitesInitiales, (activiteModel) => {
        this.addValue(activiteModel);
      });
    }
  }

  /**
   * Sets the filter parametres for the collection and context.
   */
  setCollDates(datedeb: any, datefin: any): void {
    //		datedeb=!STR.isBlank(datedeb)?datedeb:TYPE.DATE.INITIAL;
    //		datefin=!STR.isBlank(datefin)?datefin:TYPE.DATE.INFINITY;
    const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    if (!CWSTR.isBlank(datedeb) && !CWSTR.isBlank(datefin)) {
      this.context.ctxPeriodeGestion.datedeb = datedeb;
      this.context.ctxPeriodeGestion.datefin = datefin;
    } else if (CWSTR.isBlank(datedeb) && CWSTR.isBlank(datefin)) {
      //If no date is informed search for today
      this.context.ctxPeriodeGestion.datedeb = today;
      this.context.ctxPeriodeGestion.datefin = today;
    } else if (!CWSTR.isBlank(datedeb) && CWSTR.isBlank(datefin)) {
      this.context.ctxPeriodeGestion.datedeb = datedeb;
      this.context.ctxPeriodeGestion.datefin = CWTYPE.DATE.INFINITY;
    } else if (CWSTR.isBlank(datedeb) && !CWSTR.isBlank(datefin)) {
      this.context.ctxPeriodeGestion.datedeb = CWTYPE.DATE.INITIAL;
      this.context.ctxPeriodeGestion.datefin = datefin;
    }

    this.parameters.datedeb = this.context.ctxPeriodeGestion.datedeb;
    this.parameters.datefin = this.context.ctxPeriodeGestion.datefin;
    this.coll.setCollParameters(this.parameters);
  }

  // setImbriqueEl(domEl:): void {
  //   if (!CWSTR.isBlank(domEl)) {
  //     this.context.ctxImbriqueEl = domEl;
  //   }
  // }

  /**
   * Sets the context for the component and empties and repaints it
   */
  setContext(context: { [key: string]: any }): void {
    this.context = context || {};
    this.initializeContext();

    this._updateCollParametres();
    this._loadActivitesInitiales();
    this.coll.setCollParameters(this.parameters);
  }

  protected _updateCollParametres(): void {
    //Update coll parameters
    this.parameters = {
      search: null,
      typevt: this.context.ctxTypeEvtGere,
      typo: this.context.ctxTypologieActivite
    };
    /**
     * Context Utilisation
     */
    if (this.context && !CWSTR.isBlank(this.context.ctxTypeContenu)) {
      this.parameters.contenu = this.context.ctxTypeContenu;
    }

    //idplanact doit s'ajouter uniquement si l'option "restreindre" est cochée.

    if (this.context && !CWSTR.isBlank(this.context.ctxUtilisation)) {
      this.parameters.ctxutil = this.context.ctxUtilisation;
    }
    if (this.context.ctxPeriodeGestion && !CWSTR.isBlank(this.context.ctxPeriodeGestion.datedeb)) {
      this.parameters.datedeb = this.context.ctxPeriodeGestion.datedeb;
    }
    if (this.context.ctxPeriodeGestion && !CWSTR.isBlank(this.context.ctxPeriodeGestion.datefin)) {
      this.parameters.datefin = this.context.ctxPeriodeGestion.datefin;
    }
    if (!CWSTR.isBlank(this.context.ctxDomainesActivites)) {
      this.parameters.dom = this.context.ctxDomainesActivites;
    }
    if (!CWSTR.isBlank(this.context.ctxCollab)) {
      this.parameters.matric = this.context.ctxCollab;
    }
    if (this.context && !CWSTR.isBlank(this.context.ctxEmploiDuTemps) && this.context.ctxEmploiDuTemps === true) {
      this.parameters.emploidutemps = true;
    }
    /**
     * Pertot
     */
    if (!CWSTR.isBlank(this.context.ctxIndExistePeriode)) {
      this.parameters.pertot = this.context.ctxIndExistePeriode;
    }
    /**
     * Perdeb
     */
    if (!CWSTR.isBlank(this.context.ctxIndExisteDateDeb)) {
      this.parameters.perdeb = this.context.ctxIndExisteDateDeb;
    }
    /**
     * fltstruct
     */
    if (!CWSTR.isBlank(this.context.ctxFiltreStructCollab)) {
      this.parameters.fltstruct = this.context.ctxFiltreStructCollab;
    }
    /**
     * heritage
     */
    if (!CWSTR.isBlank(this.context.ctxActivHeritage)) {
      this.parameters.heritage = this.context.ctxActivHeritage;
    }

    /**
     * comp_exacte
     */
    if (!CWSTR.isBlank(this.context.ctxActivCompetenceExacte)) {
      this.parameters["comp_exacte"] = this.context.ctxActivCompetenceExacte;
    }
    /**
     * avec_inapt
     */
    if (!CWSTR.isBlank(this.context.ctxActivAvecInaptitude)) {
      this.parameters["avec_inapt"] = this.context.ctxActivAvecInaptitude;
    }
    /**
     * act_unique
     */
    if (!CWSTR.isBlank(this.context.ctxRefActivUniq)) {
      this.parameters["act_unique"] = this.context.ctxRefActivUniq;
    }
    /**
     * flt_hiertypeniv
     */
    if (!CWSTR.isBlank(this.context.ctxFiltreHiertypeNiv)) {
      this.parameters["flt_hiertypeniv"] = this.context.ctxFiltreHiertypeNiv;
    }
    /**
     * flt_ratthorsregr
     */
    if (!CWSTR.isBlank(this.context.ctxRatthorsreg)) {
      this.parameters["flt_ratthorsregr"] = this.context.ctxRatthorsreg;
    }
    /**
     * ctxdeplacement
     */
    if (!CWSTR.isBlank(this.context.ctxDeplacement)) {
      this.parameters.ctxdeplacement = this.context.ctxDeplacement;
    }
    /**
     * ctxUnique
     */
    if (!CWSTR.isBlank(this.context.ctxUnique) && this.context.ctxUnique === true) {
      this.parameters.ctxunique = this.context.ctxUnique;
    }
    /**
     * ctxPourPlanningMedical -> paramètre "medical"
     */
    if (this.context.ctxPourPlanningMedical === true) {
      this.parameters.medical = true;
    }
    /**
     * ctxGrille -> paramètre "Grille"
     */
    if (!CWSTR.isBlank(this.context.ctxGrille) && this.context.ctxGrille === true) {
      this.parameters.ctxgrille = this.context.ctxGrille;
    }
    /**
     * ctxPalette -> paramètre "ctxpalette" -> selecteur domaine en mode palette (=pas de fdt, ni de besoin)
     */
    if (!CWSTR.isBlank(this.context.ctxPalette)) {
      this.parameters.ctxpalette = this.context.ctxPalette;
    }

    /**
     * ctxTypeGest and ctxHorsTypeGest
     */
    if (this.context.ctxTypeContenu === "R" && !_.isNull(this.context.ctxTypeGest) && !CWSTR.isBlank(this.context.ctxTypeGest)) { //il faut ne pas utiliser CWSTR.isBlank parce qu'on doit envoyer le cas que la valeur soit "".
      this.parameters.ctxTypeGest = this.context.ctxTypeGest;
      this.parameters.ctxHorsTypeGest = _.isBoolean(this.context.ctxHorsTypeGest) ? this.context.ctxHorsTypeGest : false;
    }
    /**
     * horsStructExplotation-> pour les activités avec grille non médicals sans structure, en explotation.
     */
    this.parameters.horsStructExplotation = this.context.horsStructExplotation;
    /**
     * seulementPourFiltre-> pour pouvoir rechercher activités avec grille en explotation(de moment, uniquement il est utilisé en Suivi, dans activités hors récapitulatif).
     */
    this.parameters.seulementPourFiltre = this.context.ctxFiltre;

    if (this.context.ctxTypeContenu !== "R") {//exploitation
      this.parameters.ctxfeuilletemps = this.context.ctxFeuilleTemps;
      this.parameters.ctxcptana = this.context.ctxCompteAnalytiqueRef;
    }
  }

  /**
   * Gets the path of an element of the component's list
   */
  protected _getChemin(model: any | Backbone.Model, initParentDom: { [key: string]: any }[]): string {
    let result = "";
    let i = 0;
    let item: { [key: string]: any } = {};
    let first = false;

    //When there are too many results show according message.
    if (_.isNull(model.code)) {
      return model.libelle;
    }
    if ((model instanceof Backbone.Model) && (!CWSTR.isBlank(model.get("attrs")) && _.isNull(model.get("attrs").code))) {
      return (model as any).libelle;
    }

    //this is an activite model
    let initParent: { [key: string]: any };
    _.each(initParentDom, (inParent: { [key: string]: any }) => {
      if (model.parentdomcode === inParent.code) {
        initParent = inParent;
      }
    });
    if (initParent && (initParent.result > 0 || initParent.commonStructs === true)) {
      result += ".../";
    }

    if (!CWSTR.isBlank(model)) {
      let strapar = [];
      item = {};
      if (_.isNull(model.strapar) || model.strapar) {
        //Autocomplete list item
        strapar = model.strapar;
        item = new Backbone.Model(model);
      } else if (model.get("attrs") && (_.isNull(model.get("attrs").strapar) || model.get("attrs").strapar)) {
        //Using chemin for comparation
        strapar = model.get("attrs").strapar;
        item = new Backbone.Model(model.get("attrs"));
      }

      //Render structure chemin
      if (!CWSTR.isBlank(strapar) && strapar.length > 0 && initParent.commonStructs === false) {
        first = true;
        if (item.get("parentfmtstra") === 1 || item.get("parentfmtstra") === 2 || item.get("parentfmtstra") === 3) {
          //When struct format d'affichage include a libelle
          //var lastParent=strapar[(strapar.length)-1];
          result += "<span class='ui-cw-element-hierarchie-structure'>" + item.get("stralib") + " (";
        }
        if (item.get("parentfmtstra") === 1 || item.get("parentfmtstra") === 2 || item.get("parentfmtstra") === 4) {
          //When struct format d'affichage include a code
          for (i = 0; i < strapar.length; i++) { //We do not want root
            result += "/" + strapar[i].codef;
          }
        }
        result += "/" + item.get("stracode") + ")</span>";
      } else if (!_.isNull(item.get("stracode"))) {
        result += "<span class='ui-cw-element-hierarchie-structure'>" + item.get("stracode") + "</span>";
      }
      //Render hierarchies chemin
      let hierar = [];
      item = {};
      if (_.isNull(model.hierar) || model.hierar) {
        hierar = model.hierar;
        item = new Backbone.Model(model);
      } else if (model.get("attrs") && (_.isNull(model.get("attrs").hierar) || model.get("attrs").hierar)) {
        hierar = model.get("attrs").hierar;
        item = new Backbone.Model(model.get("attrs"));
      }
      if (!CWSTR.isBlank(hierar) && hierar.length > 0) {
        if (hierar.length > 0) {
          result += "<span class='ui-phx-element-hierarchie-activite'>";
          for (i = initParent.result; i < hierar.length; i++) {
            if (first === true) {
              first = false;
            } else {
              result += "/";
            }
            const hierarModel = new Backbone.Model(hierar[i]);
            result += this._getRenderer(item.get("parentfmtact")).call(this, hierarModel);
          }
          result += "</span>";
        }
      }
      if (!CWSTR.isBlank(result) && result !== ".../") {
        result += "/";
      } else if (_.isNull(model.hierar) || model.hierar) {
        result += "/";
      }

      result += this._getRenderer(item.get("parentfmtact")).call(this, item);
    }
    return result;
  }

  /**
   * Shows the (o) mark of an element when its periode is not complete
   */
  protected _showTooltip( /*model*/): boolean {
    /*var result = false;
    var datedeb = this.context.ctxPeriodeGestion.datedeb;
    var datefin = this.context.ctxPeriodeGestion.datefin;
    if (this.context.ctxAffIndicTemporel === true && datedeb && datefin && !STR.isBlank(model)) {
      if (model.dated > datedeb || model.datef < datefin) {
        //(&#9679)= (●)
        result = true;
      }
    }
    return result;*/
    return false; //FIX for delivery 25/03/2015
  }

  /**
   * Method which is executed during the navigation in the menu using the keyboard
   */
  protected _keyboardMenuItemFocus(): boolean {
    return false;
  }

  /**
   * Method which is executed during the navigation in the menu using the keyboard to get structure's level labels
   */
  protected _getLevels(callback: (level: any) => void, data?: any, structidDom?: any): void {

    if (CWSTR.isBlank(structidDom) && CWSTR.isBlank(data)) {
      callback([]);
    }
    if (!CWSTR.isBlank(structidDom)) {
      //Obtain data for this structure if it exsts
      let strucNiveauColl: { [key: string]: any } = null;

      //Search a determined structure
      _.each(this.niveauColls, (niveauColl, index) => {
        if (niveauColl.structid === structidDom) {
          strucNiveauColl = this.niveauColls[index];
        }
      });
      if (strucNiveauColl && strucNiveauColl.models) {
        callback(strucNiveauColl.models);
      } else {
        const coll = new NivColl({ code: structidDom });
        const foncCour = this._obtainFoncCour();
        coll.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: foncCour
        }));

        coll.fetch({
          success: (freshColl: any) => {
            freshColl.structid = structidDom;
            this.niveauColls.push(coll);
            callback(coll.models);
          },
          error: () => {
            callback([]);
          }
        });
      }
    } else {
      //Search niveaus for every structure
      const arrayOfStructuresSearched: { [key: string]: any }[] = [];


      _.each(data, (autocElement: { [key: string]: any }) => {
        if (CWSTR.isBlank(autocElement.attrs.domcode)) {
          //Element is not a domaine
          const structid = autocElement.attrs.parentstraid;

          //Obtain data for this structure if it exsts
          let strucNiveauColl = null;
          let strucFound = false;

          //Find if structure levels were already searched or are going to be searched
          _.each(arrayOfStructuresSearched, (structSearched) => {
            if (structSearched.structid === structid) {
              strucFound = true;
            }
          });
          _.each(this.niveauColls, (niveauColl) => {
            if (niveauColl.structid === structid) {
              strucFound = true;
              strucNiveauColl = niveauColl;
            }
          });
          if (strucFound === false && !CWSTR.isBlank(structid)) {
            //If structure levels haven't been searched yet, search them
            const coll = new NivColl({ code: structid });
            const foncCour = this._obtainFoncCour();
            coll.setHabContext(new CWHabilitationContext({
              onglet: this.context.ctxEcran,
              foncCour: foncCour
            }));
            arrayOfStructuresSearched.push({ structid: structid });

            coll.fetch({
              success: (freshColl: { [key: string]: any }) => {
                freshColl.structid = structid;
                this.niveauColls.push(coll);
                callback(coll.models);
              },
              error: () => {
                callback([]);
              }
            });
          } else {
            if (!CWSTR.isBlank(strucNiveauColl)) {
              callback(strucNiveauColl);
            } else {
              callback([]);
            }
          }
        }
      });
    }
  }
  /**
   * Obtain the path for an element in order to paint tooltip.
   */
  protected _obtainPath(code: string, objParams: any, callback: (arg: any) => void): void {

    const chemin = new SearchCheminActivite(objParams);
    const foncCour = this._obtainFoncCour();
    chemin.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCour
    }));
    chemin.code = code;
    objs.appRt.workflow.trigger("hidder:disable");
    chemin.fetch({
      success: (freshChemin: any): void => {
        objs.appRt.workflow.trigger("hidder:enable");//rétablir le hidder 
        if (callback) {
          callback(freshChemin);
        }
      },
      error: (): void => {
        objs.appRt.workflow.trigger("hidder:enable");//rétablir le hidder 
      }
    });
  }

  /**
   * Prepares parentModel to fetch in order to obtain parentsfor tooltip
   * node - the value selected that we want to obtain its path
   */
  protected _getParamsForPath(nodeToOpen: { [key: string]: any }): { [key: string]: any } {
    let objParams: { [key: string]: any } = {};
    const code = nodeToOpen.get("code");
    const hierid = nodeToOpen.get("hierid");
    const datedeb = nodeToOpen.get("dated");
    const datefin = nodeToOpen.get("datef");
    const itemid = nodeToOpen.get("straid");

    if (!CWSTR.isBlank(code) && !CWSTR.isBlank(hierid) && CWSTR.isBlank(itemid)) {
      objParams["hierid"] = hierid;
      objParams["structure"] = false;
    } else if (!CWSTR.isBlank(code) && CWSTR.isBlank(hierid) && !CWSTR.isBlank(itemid)) {
      objParams["itemida"] = itemid;
      objParams["complet"] = "N";
      objParams["hierarchie"] = false;
    } else if (!CWSTR.isBlank(code) && !CWSTR.isBlank(hierid) && !CWSTR.isBlank(itemid)) {
      objParams["hierid"] = hierid;
      objParams["itemida"] = itemid;
      objParams["complet"] = "N";
    } else {
      //No Chemin can be found
      objParams = null;
    }

    if (!CWSTR.isBlank(datedeb) && !CWSTR.isBlank(datefin)) {

      if (_.isEmpty(objParams)) {
        objParams = {};
      }
      objParams["debctx"] = datedeb;
      objParams["finctx"] = datefin;
      objParams["complet"] = true;
    }

    return objParams;
  }
  /**
   * Sets the readonly flag of the component
   */
  setReadOnly(readOnly: boolean): void {
    this.context.ctxConsultationSeule = readOnly;
    if (this.context.ctxAccesModeComplet === true) {
      if (readOnly === true) {
        this._hideButton(".cw-selecteur-activite-button");
      } else {
        this._showButton(".cw-selecteur-activite-button");
      }
    }
  }

  protected _hideButton(selector: string): void {
    this.$el.find(selector).addClass("d-none");
  }

  protected _showButton(selector: string): void {
    this.$el.find(selector).removeClass("d-none");
  }

  /**
   * Returns the tooltip text of an element of the component
   */
  protected _tooltipText(model: { [key: string]: any }, levels: any, dateonly?: boolean): string {
    let txt = "";

    if (!_.isEmpty(model.attrs)) {
      let tab = "";
      let header = "";
      let j = 0;
      let parentModel = null;
      const datedebContext = this.context.ctxPeriodeGestion.datedeb;
      const datefinContext = this.context.ctxPeriodeGestion.datefin;

      //Domaine info
      if (model.attrs.parentdomlib) {
        txt += "<span>" + i18n.t('common:selecteuractivites.domaine') + " <span style='font-style:italic;'>" + model.attrs.parentdomlib + "</span></span><br/>";
      }
      //HEader
      if (!CWSTR.isBlank(model.attrs.dated) && !CWSTR.isBlank(model.attrs.datef)) {
        if (model.attrs.dated > datedebContext && model.attrs.datef >= datefinContext) {
          header = i18n.t('common:selecteuractivites.sitpartdu', { "0": CWTYPE.DATE.format(model.attrs.dated) });
        }
        if (model.attrs.dated <= datedebContext && model.attrs.datef < datefinContext) {
          header = i18n.t('common:selecteuractivites.sitjusq', { "0": CWTYPE.DATE.format(model.attrs.datef) });
        }
        if (model.attrs.dated > datedebContext && model.attrs.datef < datefinContext && model.attrs.dated !== model.attrs.datef) {
          header = i18n.t('common:selecteuractivites.sitduau', { "0": CWTYPE.DATE.format(model.attrs.dated), "1": CWTYPE.DATE.format(model.attrs.datef) });
        }
        if (model.attrs.dated > datedebContext && model.attrs.datef < datefinContext && model.attrs.dated === model.attrs.datef) {
          header = i18n.t('common:selecteuractivites.sitdatedu', { "0": CWTYPE.DATE.format(model.attrs.datef) });
        }

        if (header) {
          txt += "<span>" + header + "</span><br/><br/>";
        }
      }

      if (!dateonly) {
        let parentDrawn = false;
        let marginLeft = 0;
        const tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
        const numberOfStructures = (!CWSTR.isBlank(model.attrs.strapar) && !CWSTR.isBlank(model.attrs.strapar.length)) ? model.attrs.strapar.length : 0;
        const actModel = new Backbone.Model(model.attrs);
        let couvertPartielle = "";

        //Paint structures
        txt += "<table style=\"width:500px\">";
        if (model.attrs.strapar && model.attrs.strapar.length > 0) {
          // paint chemin when node is not a root node
          for (j = 0; j < model.attrs.strapar.length; j++) {
            parentDrawn = true;
            parentModel = new Backbone.Model(model.attrs.strapar[j]);
            // tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
            tab = "<span style='display: inline-block; padding: 0; margin-right: 2px; margin-left: " + marginLeft + "px '>" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";
            if (j === 0) {
              txt += "<tr><td  style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\"><span class=\"ui-cw-element-hierarchie-structure\">" + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtstra).call(this, parentModel) + "</span></td><td class=\"phx-selecteur-activite-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:selecteuractivites.structure') + "</td></tr>";
            } else {
              let niveau = "";

              if (j - 1 < levels.length) {
                niveau = levels[j - 1].get("libelle");
              }
              txt += "<tr><td  style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\">" + tab + "<span class=\"ui-cw-element-hierarchie-structure\">" + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtstra).call(this, parentModel) + "</span></td><td class=\"phx-selecteur-activite-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
              marginLeft += 10;
            }
          }
        }
        //Paint hierarchies
        if (model.attrs.hierar && model.attrs.hierar.length > 0) {
          // paint chemin when node is not a root node
          for (j = 0; j < model.attrs.hierar.length; j++) {
            const lib = CWSTR.isBlank(model.attrs.hierar[j].hiertypeniv) ? model.attrs.hierar[j].libelle : model.attrs.hierar[j].hiertypeniv.libelle;

            parentDrawn = true;
            parentModel = new Backbone.Model(model.attrs.hierar[j]);
            // tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
            tab = "<span style='display: inline-block; padding: 0; margin-right: 2px; margin-left: " + marginLeft + "px '>" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";

            if (j === 0 && numberOfStructures > 0) {
              if (CWSTR.isBlank(model.attrs.hierar[j].hiertypeniv)) {
                txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\">" + tab + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtact).call(this, parentModel) + "</span></td><td class=\"phx-selecteur-activite-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + "<span>" + lib + "</span></td></tr>";
              } else {
                txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\"><span class=\"ui-phx-" + model.attrs.hierar[j].hiertypeniv.style + "\">" + tab + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtact).call(this, parentModel) + "</span></td><td class=\"phx-selecteur-activite-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + "<span class=\"ui-phx-" + model.attrs.hierar[j].hiertypeniv.style + "\">" + lib + "</span></td></tr>";
              }
              marginLeft += 10;
            } else if (j === 0) {
              if (CWSTR.isBlank(model.attrs.hierar[j].hiertypeniv)) {
                txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\">" + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtact).call(this, parentModel) + "</span></td></tr>";
              } else {
                txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\"><span class=\"ui-phx-" + model.attrs.hierar[j].hiertypeniv.style + "\">" + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtact).call(this, parentModel) + "</span></td><td class=\"phx-selecteur-activite-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + "<span class=\"ui-phx-" + model.attrs.hierar[j].hiertypeniv.style + "\">" + model.attrs.hierar[j].hiertypeniv.libelle + "</span></td></tr>";
              }
            } else {
              if (CWSTR.isBlank(model.attrs.hierar[j].hiertypeniv)) {
                txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\">" + tab + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtact).call(this, parentModel) + "</span></td></tr>";
              } else {
                txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\"><span class=\"ui-phx-" + model.attrs.hierar[j].hiertypeniv.style + "\">" + tab + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtact).call(this, parentModel) + "</span></td><td class=\"phx-selecteur-activite-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + "<span class=\"ui-phx-" + model.attrs.hierar[j].hiertypeniv.style + "\">" + model.attrs.hierar[j].hiertypeniv.libelle + "</span></td></tr>";
              }
              marginLeft += 10;
            }
          }
        }
        //Add current activité
        if (model.id !== model.attrs.code) {  //recherche en mode autocomplétion : il faut ajouter l'activité
          tab = "<span style='display: inline-block; padding: 0; margin-right: 2px; margin-left: " + marginLeft + "px '>" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";
          txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\">" + tab + model.label + "</span></td></tr>";
        }

        tab = (parentDrawn === true) ? "<span style='display: inline-block; padding: 0; margin-right: 2px; margin-left: " + marginLeft + "px '>" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>" : "";
        if (!CWSTR.isBlank(this.context.ctxCollab) && !CWSTR.isBlank(model.attrs.indic_comp_req) && model.attrs.indic_comp_req === "O") {
          couvertPartielle = "<span>&#8776;</span>"; //Addd couverture partielle indicator
        }
        if (_.isEmpty(model.attrs.hierar)) {
          txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width: calc(100vw - 300px);\">" + tab + couvertPartielle + this._getRenderer(model.attrs.hiertypeniv && model.attrs.hiertypeniv.niveau_data ? parseInt(model.attrs.hiertypeniv.niveau_data.format) : model.attrs.parentfmtact).call(this, actModel) + "</td><td class=\"phx-selecteur-activite-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + (model.attrs.hiertypeniv ? this._getTypoIntituleHierarchie(model) : this._getTypoIntitule()) + "</td></tr>";
        }
        txt += "</table>";
      }
      if (!CWSTR.isBlank(this.context.ctxCollab) && !CWSTR.isBlank(model.attrs.indic_comp_req) && model.attrs.indic_comp_req !== "C") {
        txt += "<br>" + i18n.t('messages:GT_1428');
      }
    }
    return txt;
  }

  /**
   * Gets the render function for every element of the list.
   */
  protected _getListRenderer(initParentDom: { [key: string]: any }[], countDomaineItems: number): (model: { [key: string]: any }) => string {

    const result = (model: { [key: string]: any }): string => {
      if (CWSTR.isBlank(model.domcode)) {
        //This is an activiteElement{
        const incompletePeriode = this._showTooltip(/*model*/);
        let totalText = "";
        if (incompletePeriode === true) {
          totalText = "<span class=\"phx-selecteur-activite-option \"><span class=\"phx-selecteur-activite-tooltip\" title=\"\">(&#9679)</span>" + this._getChemin(model, initParentDom) + "</span>";
        } else {
          totalText = "<span class=\"phx-selecteur-activite-option\">" + this._getChemin(model, initParentDom) + "</span>";
        }
        return totalText;
      } else {
        //This is a domaineElement
        if (countDomaineItems > 1) {
          //Onlye paint domaines if there are more than 1
          return "<span class=\"phx-selecteur-activite-domaine-option ui-phx-regroupement-sein-formulaire\">" + model.domlib + "</span>";
        } else {
          return "";
        }
      }
    };
    return result;
  }

  /**
   * Gets the render function depending on the format value
   */
  protected _getRenderer(format: number): (item: { [key: string]: any }) => string {
    let result = null;
    let message;
    switch (format) {
      case 1:
        result = (item: { [key: string]: any }): string => {
          //Use codef for structures and code for activites
          const code = !CWSTR.isBlank(item.get("codef")) ? item.get("codef") : item.get("code");
          if (!CWSTR.isBlank(item.get("hiertypeniv")) && !CWSTR.isBlank(item.get("hiertypeniv").niveau_data)) {
            message = "<span class='" + item.get("hiertypeniv").niveau_data.style.affichage + "'>" + item.get("libelle") + " (" + code + ") </span>";
          } else {
            message = item.get("libelle") + " (" + code + ")";
          }
          return message;
        };
        break;
      case 2:
        result = (item: { [key: string]: any }): string => {
          //Use codef for structures and code for activites
          const code = !CWSTR.isBlank(item.get("codef")) ? item.get("codef") : item.get("code");
          if (!CWSTR.isBlank(item.get("hiertypeniv")) && !CWSTR.isBlank(item.get("hiertypeniv").niveau_data)) {
            message = "<span class='" + item.get("hiertypeniv").niveau_data.style.affichage + "'>" + code + " (" + item.get("libelle") + ")</span>";
          } else {
            message = "<span>" + code + " (" + item.get("libelle") + ")</span>";
          }
          return message;
        };
        break;
      case 3:
        result = (item: { [key: string]: any }): string => {
          if (!CWSTR.isBlank(item.get("hiertypeniv")) && !CWSTR.isBlank(item.get("hiertypeniv").niveau_data)) {
            message = "<span class='" + item.get("hiertypeniv").niveau_data.style.affichage + "'>" + item.get("libelle") + "</span>";
          } else {
            message = "<span>" + item.get("libelle") + "</span>";
          }
          return message;
        };
        break;
      case 4:
        result = (item: { [key: string]: any }): string => {
          //Use codef for structures and code for activites
          const code = !CWSTR.isBlank(item.get("codef")) ? item.get("codef") : item.get("code");
          if (!CWSTR.isBlank(item.get("hiertypeniv")) && !CWSTR.isBlank(item.get("hiertypeniv").niveau_data)) {
            message = "<span class='" + item.get("hiertypeniv").niveau_data.style.affichage + "'>" + code + "</span>";
          } else {
            message = "<span>" + code + "</span>";
          }
          return message;
        };
        break;
      default:
        result = (item: { [key: string]: any }): string => {
          //Use codef for structures and code for activites
          let code = !CWSTR.isBlank(item.get("codef")) ? item.get("codef") : item.get("code");
          if (!CWSTR.isBlank(item.get("hiertypeniv")) && !CWSTR.isBlank(item.get("hiertypeniv").niveau_data)) {
            code = "<span class='" + item.get("hiertypeniv").niveau_data.style.affichage + "'>" + code + "</span>";
          }
          return item.get("libelle") + " (" + code + ")";
        };
        break;
    }
    return result;
  }

  /**
   * Gets the render function depending on the factivite format (only for actvités), for selected elements that
   * will be shown as selected elements
   */
  protected _getAttrsRenderer(): (item: { [key: string]: any }) => string {
    let result = null;

    result = (item: { [key: string]: any }): string => {
      const renderer = item.hiertypeniv && item.hiertypeniv.niveau_data ? parseInt(item.hiertypeniv.niveau_data.format) : item.parentfmtact;

      switch (renderer) {
        case 1:
          return item.libelle + " (" + item.code + ")";
        case 2:
          return item.code + " (" + item.libelle + ")";
        case 3:
          return item.libelle;
        case 4:
          return item.code;
        default:
          return item.libelle + " (" + item.code + ")";
      }
    };
    return result;
  }

  /**
   * Gets the number of paretns that every element has in common
   */
  protected _getInitialNiveau(domModel: { [key: string]: any }): { [key: string]: any } {
    let result = 0,
      i = 0,
      j = 0,
      k = 0;
    let resultHier = 0;
    const collection = domModel.ActsColl;
    let minParents = 0;
    let maxParents = 0;
    let first = true;
    let first2 = true;
    let noStructures = true;
    let allStructsInCommon = false;
    const resultObj = { result: 0, code: domModel.get("domcode"), commonStructs: false };

    if (!CWSTR.isBlank(collection) && collection.models && collection.models.length > 1) {
      //Min common StructParents
      for (i = 0; i < collection.models.length; i++) {
        if (!CWSTR.isBlank(collection.models[i].get("strapar")) && collection.models[i].get("strapar").length > 0) {
          noStructures = false;
          if (collection.models[i].get("strapar").length < minParents && !first) {
            minParents = collection.models[i].get("strapar").length;
          } else if (first === true) {
            first = false;
            minParents = collection.models[i].get("strapar").length;
          }
          if (collection.models[i].get("strapar").length >= maxParents && !first2) {
            maxParents = collection.models[i].get("strapar").length;
          } else if (first2 === true) {
            first2 = false;
            maxParents = collection.models[i].get("strapar").length;
          }
        }
      }

      let end = false;
      for (j = 1; j < minParents && minParents > 1 && !end; j++) { //eslint-disable-line
        let lastParent = null;
        for (k = 0; k < collection.models.length && !end; k++) {
          if (!CWSTR.isBlank(collection.models[k].get("strapar"))) {
            if (CWSTR.isBlank(lastParent)) {
              lastParent = collection.models[k].get("strapar")[j];
            } else if (collection.models[k].get("strapar")[j].code !== lastParent.code) {
              result = j;
              end = true;
            }
          }
        }

        if (!end) {
          result = j + 1;
        }
      }

      if (result === maxParents && maxParents > 0) {
        allStructsInCommon = true;
      }

      //If structParents in common are all the structparents, search for hierarchies in commmon
      //Or if there aren't structure parents, and we have to treat only hierarchies, search for hierarchies in common
      if (result === maxParents || noStructures === true) {
        let minHierParents = 0;
        let maxHierParents = 0;
        let firstHier = true;
        let firstHier2 = true;

        for (i = 0; i < collection.models.length; i++) {
          if (!CWSTR.isBlank(collection.models[i].get("hierar")) && collection.models[i].get("hierar").length > 0) {
            if (collection.models[i].get("hierar").length < minHierParents && !first) {
              minHierParents = collection.models[i].get("hierar").length;
            } else if (firstHier === true) {
              firstHier = false;
              minHierParents = collection.models[i].get("hierar").length;
            }
            if (collection.models[i].get("hierar").length > maxHierParents && !first2) {
              maxHierParents = collection.models[i].get("hierar").length;
            } else if (firstHier2 === true) {
              firstHier2 = false;
              maxHierParents = collection.models[i].get("hierar").length;
            }
          } else {
            minHierParents = 0;
          }
        }

        end = false;
        for (j = 0; j < minHierParents && minHierParents > 1 && !end; j++) { //eslint-disable-line
          let lastHierParent = null;
          for (k = 0; k < collection.models.length && !end; k++) {
            if (CWSTR.isBlank(lastHierParent)) {
              lastHierParent = collection.models[k].get("hierar")[j];
            } else if (collection.models[k].get("hierar")[j].code !== lastHierParent.code) {
              resultHier = j;
              end = true;
            }
          }
          if (!end) {
            resultHier = j + 1;
          }
        }
      }
      result = resultHier;
    }
    resultObj.result = result;
    resultObj.commonStructs = allStructsInCommon;
    return resultObj;
  }

  /**
   * Paints the view of the selecteur d'activités component
   */
  render(callback?: () => any): this {
    const json = { 'i18n': i18n, label: this.label, UTILS: UTILS, placeholder: this.placeholder };

    this.$el.html(this.template(json));

    this.$el.css("margin-bottom", "0px");

    this.initializeAutocompleteInput();

    //Paint activities initiales
    if (this.context && !CWSTR.isBlank(this.context.ctxActivitesInitiales)) {
      this._loadActivitesInitiales();
    }
    this.setElementUnique(callback);

    return this;
  }

  setElementUnique(callback?: () => any): void {

    if (this.context && this.context.ctxUnique === true) {
      this.coll.fetch({
        success: (data: any) => {
          if (data && data.models instanceof Array && data.models.length > 0) {
            this.clearSelection();
            this.addValue(new CWBaseModel((data.models[0]).get("act")[0]));
            this.addItemCallback((data.models[0]).get("act")[0], callback);
          } else {
            if (typeof callback === "function") {
              callback();
            }
          }
        }
      })
    } else {
      if (typeof callback === "function") {
        callback();
      }
    }
  }

  /**
   * Initializes autocomplete
   */
  initializeAutocompleteInput(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    const lPosition = {
      my: "left top",
      at: "left bottom",
      of: this.$el.find(".cw-selecteur-activite-wrap"),
      collision: "flip"
    };


    // instantiate/configure component
    if (CWSTR.isBlank(this.view)) {
      this._hideButton(".cw-selecteur-activite-button");
    } else {
      this._showButton(".cw-selecteur-activite-button");
    }
    const $input = this.$el.find(".cw-selecteur-activite-input");
    $input.attr("class", this.name + " " + $input.attr("class"));

    if (this.generateLabel === true) {
      (this.$el).find("label[for=\"" + this.name + "\"]").remove();
      const label = $("<label>").attr("for", this.name);
      const typologyeIntitule = this._getTypoIntitule();
      label.html(typologyeIntitule);
      const parentDiv = this.$el.find(".cw-selecteur-activite-wrap");
      label.insertBefore(parentDiv);
    }
    this.$el.find(".selecteur-activite-error-container").css("display", "table");
    this.$el.find(".selecteur-activite-error-container").removeClass("selecteur-activite-error-container").addClass(this.name + "-error-container");

    if (!this.context.ctxConsultationSeule) {
      this.appended = false;
      this.input = this.$el.find(".cw-selecteur-activite-input").autocomplete({
        minLength: this.minSearchLength,
        source: (request: any, response: any) => {
          //Delete current tooltip
          _this.$el.find(".cw-selecteur-activite-input").tooltip({ content: "" });
          _this.$el.find(".cw-selecteur-activite-input").trigger("mouseout");
          const term = request.term;
          if (_this.coll) {
            _this.coll.params = "";
            _this.coll.pagination.size = _this.maxResultItems;
            _this.coll.search = term;
            _this.coll.fetch({
              success: (freshColl: { [key: string]: any }) => {
                const collection = new CWAutocompleteColl();
                const initParentPerDom: { [key: string]: any }[] = []; //Obtain initparent for each domaine
                let countActiviteItems = 0;
                let countDomaineItems = 0;

                _.each(freshColl.models, (domaine: { [key: string]: any }) => {
                  const initParent = _this._getInitialNiveau(domaine);

                  countDomaineItems++;
                  initParentPerDom.push(initParent);
                  _.each(domaine.get("act"), () => {
                    countActiviteItems++;
                  });
                });
                _this.listRenderer = _this._getListRenderer(initParentPerDom, countDomaineItems);
                collection.itemRenderer = _this.listRenderer;
                // add message to list if more results exist
                if (countActiviteItems > _this.maxResultItems) {
                  let message = "";
                  let info = null;

                  if (_this.context.ctxAccesModeComplet === true) {
                    message = i18n.t('messages:GL_1007');
                  } else {
                    message = i18n.t('messages:GL_1006');
                  }
                  info = new ReadOnlyModel({ code: null, libelle: message });
                  collection.add([info.toJSON()], { parse: true } as Backbone.AddOptions);
                } else {
                  //Load autocompletedata
                  _.each(freshColl.models, (domaine: any) => {
                    const dom = [domaine.toJSON()];

                    if (freshColl.models.length > 1) {
                      collection.add(dom, { parse: true } as Backbone.AddOptions);
                    }
                    collection.add(domaine.ActsColl.toJSON(), { parse: true } as Backbone.AddOptions);
                  });
                }
                response(collection.toJSON());
              }
            });
          }
        },
        position: lPosition,
        open: function () {
          const menu = $(this).data("ui-autocomplete").menu;

          menu.element.position(lPosition); //positionner autre fois après le changement de la largeur
        },
        create: function () {
          const menu = $(this).data("ui-autocomplete").menu;

          menu.activeMenu.addClass("c-panneauMenu c-panneauMenu--noIcon");//nouveu style
          $(menu.activeMenu).css("position", "fixed");
          $(this).data('ui-autocomplete')._resizeMenu = (): void => {
            if (_this.appended === false) {
              //AppendTo option in order to let jquery recalculate z-index for each element
              $(_this.input).autocomplete("option", "appendTo", null);
              _this.appended = true;
            }
          };
        }
      });
    } else {
      let fieldset = null;

      this.$el.find(".cw-selecteur-activite-input").prop("readonly", true);
      fieldset = this.$el.find(".cw-selecteur-activite-wrap");
      FORMS.setInputFieldReadonly(fieldset, this.name, true);
    }
    if (this.input) {
      $(this.input).data("ui-autocomplete")._renderItem = (ul: any, item: { [key: string]: any }): JQuery => {

        const keywords = $.trim(this.term);
        let output = item.label;
        let output2 = "";
        try {
          if (item.label[1] && item.label[1].textContent) {
            output = item.label[1].textContent.replace(new RegExp("(" + keywords + ")", "gi"),
              '<span class="phx-searched-char-sequence">$1</span>');
            output2 = item.label[0];
          } else {
            if ($(output)) {
              //Replace only label text (not HTML elements)
              const innerText = $(output).contents().filter((): boolean => {
                return this.nodeType === 3;
              })[0].nodeValue;
              const innerTextReplaced = innerText.replace(new RegExp("(" + keywords + ")", "gi"),
                "<span class='phx-searched-char-sequence'>$1</span>");
              output = item.label.replace(innerText, innerTextReplaced);
            } else {
              output = item.label.replace(new RegExp("(" + keywords + ")", "gi"),
                '<span class="phx-searched-char-sequence">$1</span>');
            }
          }
        } catch (err) {
          //Nothing
        }

        if (CWSTR.isBlank(item.attrs.id)) {
          return $("<li>").addClass("ui-state-disabled").html('<span class="phx-label-style phx-selecteur-activite-moreitems-info">' + item.label + '</span>').appendTo(ul);
        }
        if (!CWSTR.isBlank(item.attrs.domcode)) {
          //Domaine element
          return $("<li>").addClass("ui-state-disabled").css("opacity", "1").append($("<a>").html(output2).append(output)).appendTo(ul);
        }
        return $("<li>").append($("<a>").html(output2).append(output)).appendTo(ul);
      };
    }
    this.$el.find(".cw-selecteur-activite-input").prop("viewRef", this as any);
    //apply width to the component wrapper
    this.$el.find(".cw-selecteur-activite-wrap").css("width", this.fieldWidth);

    //apply width class to the input and remove button if not complete mode
    if (this.context.ctxAccesModeComplet === true) {
      /*if ((String(this.fieldWidth)).indexOf("%") === -1) { => Must not be set in the component, but in css
        this.$el.find(".cw-selecteur-activite-input").css("width", this.fieldWidth - 22 + "px");
      } else {
        this.$el.find(".cw-selecteur-activite-input").css("width", "calc(" + this.fieldWidth + " - 22px)");
      }*/
      if (!CWSTR.isBlank(this.context.ctxImbriqueEl)) { //Hide complete view at load
        this.context.ctxImbriqueEl.hide();
      }

      if (this.context.ctxModeRestitution === "Complet") {
        this.$el.find(".cw-selecteur-activite-button").trigger("click");
      }

      this._showButton(".cw-selecteur-activite-button");
    } else {

      this._hideButton(".cw-selecteur-activite-button");
      // this.$el.find(".cw-selecteur-activite-input").css("width", "calc(100% - 4px)");
    }

    this.$el.find(".cw-selecteur-activite-input").tooltip({ content: "" });

    if (!this.context.ctxSelectMultiple) {
      this.$el.find(".phx-selecteur-activite-selection");
    } else {
      this.$el.find(".phx-selecteur-activite-selection").css("display", "inherit");
    }
  }

  /**
   * Gets the intitulé depending on the typologie
   */
  protected _getTypoIntitule(): string {
    let intitule: string = "";
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);
    if (typologie) {
      const evenement = typologie.get("evenement");
      intitule = evenement.charAt(0).toUpperCase() + evenement.slice(1);
    }
    return intitule;
  }

  protected _getTypoIntituleHierarchie(model: { [key: string]: any }): string {
    let intitule = "";
    if (!CWSTR.isBlank(model.attrs.hiertypeniv.niveau_data)) {
      intitule = model.attrs.hiertypeniv.niveau_data.libelle;
    } else {
      const niveauData = _.find(model.attrs.hierar, (value: { [key: string]: any }) => { return value.code === model.attrs.hiertypeniv.code })
      if (niveauData) {
        intitule = niveauData.libelle;
      }
    }
    return intitule;
  }

  /**
   * Clears the current selection of the component
   */
  clearSelection(): void {
    this.selection.reset();
    this._resetTooltip();
    this._paintItems();
  }

  // item is a backbone model representing an activite structure
  /**
   * Adds a value to the component
   */
  addValue(item: { [key: string]: any }, callback?: (arg: any) => void): void {

    const foncCour = this._obtainFoncCour();
    item.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCour
    }));
    const resp: typeof SelecteurActiviteModeSimpleActiviteModel = new SelecteurActiviteModeSimpleActiviteModel({
      code: item.get("code"),
      libelle: item.get("libelle"),
      hierar: "",
      strapar: "",
      dated: item.get("datedeb"),
      datef: item.get("datefin"),
      hierid: item.get("hierid"),
      stracode: "",
      stralib: "",
      straid: item.get("itemid"),
      "indic_comp_req": "",
      "indic_periode": "",
      //Parsed information
      parentstraid: item.get("structid"),
      parentdomlib: item.get("parentdomlib"),
      parentfmtact: item.get("parentfmtact"),
      parentfmtstra: "",
      parentdomcode: item.get("parentdomcode"),
      //Hierachie
      hiertypeniv: CWSTR.isBlank(item.get("hiertypeniv")) ? null : item.get("hiertypeniv")
    });

    const callbackAfterPath = (freshChemin?: any): void => {
      //Include chemin from WS searchChemin
      const hierar = !CWSTR.isBlank(freshChemin) ? freshChemin.get("hierar") : [];
      const strapar = !CWSTR.isBlank(freshChemin) ? freshChemin.get("structa") : [];
      resp.set("hierar", hierar);
      resp.set("strapar", strapar);

      if (this.context.ctxSelectMultiple !== true) {
        // clear input field and set focus to it.
        this.selection.reset();
      }

      this.selection.add([resp.attributes], { parse: true });
      this.selection.at(this.selection.models.length - 1).freshChemin = freshChemin;
      this._paintItems();
      if (callback) {
        callback(resp);
      }
    };

    if (this._isNotCurrentSelection(resp)) { //The added value is not the current selection
      //var params=this._getParamsForPath(resp);
      //if (params)
      //	this._obtainPath(item.get("code"),params,callbackAfterPath);
      //else if (callbackAfterPath)
      callbackAfterPath();
    } else { //The added value is the current selection, so we don't have to add it again
      this._paintItems();
      if (callback) {
        callback(resp);
      }
    }

  }

  /**
   * Adds a value to the component
   */
  GetItemTooltip(item: { [key: string]: any }, callback: (arg: any) => void): void {

    const foncCour = this._obtainFoncCour();
    item.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCour
    }));
    const resp = new SelecteurActiviteModeSimpleActiviteModel({
      code: item.get("code"),
      libelle: item.get("libelle"),
      hierar: "",
      strapar: "",
      dated: item.get("datedeb"),
      datef: item.get("datefin"),
      hierid: item.get("hierid"),
      stracode: "",
      stralib: "",
      straid: item.get("itemid"),
      "indic_comp_req": "",
      "indic_periode": "",
      //Parsed information
      parentstraid: item.get("structid"),
      parentdomlib: item.get("parentdomlib"),
      parentfmtact: item.get("parentfmtact"),
      parentfmtstra: "",
      parentdomcode: "",
      //Hierachie
      hiertypeniv: CWSTR.isBlank(item.get("hiertypeniv")) ? null : item.get("hiertypeniv")
    });

    const callbackLevels = (levels: any): void => {
      if (callback) {
        resp.attrs = resp.attributes;
        callback(this._tooltipText(resp, levels));
      }
    }

    const callbackAfterPath = (freshChemin?: any): void => {
      //Include chemin from WS searchChemin
      const hierar = !CWSTR.isBlank(freshChemin) ? freshChemin.get("hierar") : [];
      const strapar = !CWSTR.isBlank(freshChemin) ? freshChemin.get("structa") : [];
      resp.set("hierar", hierar);
      resp.set("strapar", strapar);

      // add tooltip
      const structid = item.get("structid");
      this._getLevels(callbackLevels, undefined, structid);
    };

    const params = this._getParamsForPath(resp);
    if (params) {
      this._obtainPath(item.get("code"), params, callbackAfterPath);
    } else if (callbackAfterPath) {
      callbackAfterPath();
    }
  }

  protected _isNotCurrentSelection(resp: typeof SelecteurActiviteModeSimpleActiviteModel): boolean {
    if (this.context.ctxSelectMultiple === false && this.selection.models.length === 1) {
      const currentSelection = this.selection.models[0];
      const currentSelAttrs = currentSelection.get("attrs");
      if (currentSelAttrs.code === resp.get("code") && currentSelAttrs.hierid === resp.get("hierid") &&
        currentSelAttrs.dated === resp.get("dated") && currentSelAttrs.parentstraid === resp.get("parentstraid") &&
        currentSelAttrs.straid === resp.get("straid")) {
        return false;
      }
    }
    return true;
  }
  /**
   * Gets the selected values
   */
  getValues(): CWAutocompleteColl {
    return this.selection;
  }

  /**
   * Paints every element of the selection
   */
  protected _paintItems(reprintlabel?: boolean): void {
    if (!_.isBoolean(reprintlabel)) {
      reprintlabel = true;
    }
    if (this.context.ctxSelectMultiple) {
      this.$el.find(".cw-selecteur-activite-multiSelection").empty();
      _.each(this.selection.models, (value) => {
        const showPeriodeMark = this._showTooltip(/*value.get("attrs")*/);
        const itemView = new ResultItemView({ model: value, showPeriodeMark: showPeriodeMark });

        this.$el.find(".cw-selecteur-activite-multiSelection").append(itemView.render().el);
      });

      if (this.selection.models.length === 0) {
        this.$el.find(".cw-selecteur-activite-multiSelection").css("display", "inherit");
      } else {
        this.$el.find(".cw-selecteur-activite-multiSelection").css("display", "table");
      }

      this.$el.find(".cw-selecteur-activite-multiSelection").position({
        my: "left top",
        at: "left bottom",
        of: this.$el.find(".cw-selecteur-activite-wrap")
      });
    } else {
      if (this.selection.length > 0) {
        const showPeriodeMark = this._showTooltip(/*this.selection.at(0).get("attrs")*/);
        const text = showPeriodeMark === true ? "(o) " : "";
        if (reprintlabel) {
          this.$el.find(".cw-selecteur-activite-input").val(text + this.selection.at(0).get("label"));
        }
      }
    }
  }

  /**
   * Selects a new element in the component
   */
  protected _selectItem(event: JQueryEventObject, ui: any): boolean {
    let habilitationGestion = this.context.ctxHabilitation.HabilitationGestion;

    if (!CWSTR.isBlank(habilitationGestion) && typeof habilitationGestion === "object") {
      const lValeur: Array<any> = _.map(habilitationGestion, null);

      for (let i = 0; i < lValeur.length; i++) {
        if (CWHABILITATION.canView(lValeur[i])) {
          //on prend le première valeur qui qui ait d'habilitation
          habilitationGestion = lValeur[i];
          break;
        }
      }
    }
    if (CWSTR.isBlank(ui.item.attrs.domcode) && (CWSTR.isBlank(habilitationGestion) || CWHABILITATION.canView(habilitationGestion))) {
      //When element is an activite and not a domaine
      const freshChemin = this._generateFreshChemin(ui.item.attrs);
      this._addItem(ui.item.attrs, null, freshChemin);
    }
    return false;
  }

  /**
   *generates a model 	of type SearchCheminActivite
   * in order to be able to deploy tree in complet mode and select the element in the tree
   */
  protected _generateFreshChemin(objAttrs: { [key: string]: any }): void {

    const resp = new SelecteurActiviteModeSimpleActiviteModel({
      code: objAttrs.code,
      libelle: objAttrs.libelle,
      hierar: objAttrs.hierar,
      strapar: objAttrs.strapar,
      dated: objAttrs.dated,
      datef: objAttrs.datef,
      hierid: objAttrs.hierid,
      stracode: objAttrs.stracode,
      stralib: objAttrs.stralib,
      straid: objAttrs.straid,
      "indic_comp_req": "",
      "indic_periode": "",
      //Parsed information
      parentstraid: objAttrs.parentstraid,
      parentdomlib: objAttrs.parentdomlib,
      parentfmtact: objAttrs.parentfmtact,
      parentfmtstra: objAttrs.parentfmtstra,
      parentdomcode: objAttrs.parentdomcode,
      //Hierachie
      hiertypeniv: objAttrs.hiertypeniv
    });
    const params = this._getParamsForPath(resp);
    const chemin = new SearchCheminActivite(params);
    chemin.set("hierar", objAttrs.hierar);
    //The structure chemin is sent in mode simple in an array strapar, and the last structure parent is sent in straid,stracode,stralib fields, so we have to add them to the array
    const strapar = !CWSTR.isBlank(objAttrs.strapar) ? _.clone(objAttrs.strapar) : [];
    strapar.push({ code: objAttrs.straid, libelle: objAttrs.stralib, codef: objAttrs.stracode });
    chemin.set("structa", strapar);
    return chemin;
  }
  /**
   * Changes a item in the current selection
   */
  protected _changeItem(event: JQueryEventObject): void {
    const value = $(event.target).val();
    if (this.context.ctxSelectMultiple === false && CWSTR.isBlank(value) && this.addItemCallback) {
      this.selection.reset();
      const model = new this.coll.model;
      this.addItemCallback(model.attributes);
    }
    this._resetTooltip();
  }

  public _enterPress(event: KeyboardEvent): void {
    if (CWSTR.isBlank((event.currentTarget as any).value) && event.keyCode !== 9) {
      if (this.addItemCallback) {
        this.addItemCallback();
      }
      this.clean();
    }
  }

  /**
   * Adds a new element to the selection
   */
  protected _addItem(attrs: { [key: string]: any }, dontnotifyEdition: boolean, freshChemin: any): boolean {
    if (this.context.ctxSelectMultiple === true) {
      // clear input field and set focus to it.
      this.$el.find(".cw-selecteur-activite-input").val("");
      this.$el.find(".cw-selecteur-activite-input").focus();
    } else {
      this.selection.reset();
      this._resetTooltip();
    }

    // add item to selection list
    if (CWSTR.isBlank(this.selection.get(attrs.id))) {
      this.selection.add([attrs], { parse: true });
      this.selection.at(this.selection.models.length - 1).freshChemin = freshChemin;
      if (this.addItemCallback) {
        this.addItemCallback(attrs);
      }

      // Notify edition
      if (CWSTR.isBlank(dontnotifyEdition) && dontnotifyEdition !== true) {
        this.$el.find(".cw-selecteur-activite-input").trigger("change");
      }
      // paint items
      this._paintItems();
    }

    // retur false to prevent bubbling of event
    return false;
  }

  /**
   * Deletes an element from the list of selections of the component
   */
  protected _removeItem(model: { [key: string]: any }): void {
    // remove item from collection
    this.selection.remove(model);
    this._resetTooltip();

    if (this.removeItemCallback) {
      this.removeItemCallback(model.attributes.attrs);
    }

    // repaint
    this._paintItems();
    // Notify edition
    this.$el.find(".cw-selecteur-activite-input").trigger("change");
  }

  /**
   * Opens the detail popup view
   */
  protected _toggleCompleteView(): void {
    if (this.context.ctxModeRepresentation === "pop-up" || ((this.context.ctxEcran === "mdepl" || this.context.ctxEcran === "suivicollab" || this.context.ctxEcran === "mactivites") && this.context.ctxModeRepresentation === "Imbrique")) {
      //pop-up
      if (!CWSTR.isBlank(this.popup)) {
        this.popup.remove();
        this.popup = null;
      }
      this.popup = new CWDialogPopupView({
        view: this.view,
        viewData: {
          context: this.context,
          parameters: this.parameters,
          currentSelection: this.selection,
          workflow: this.workflow,
          appendTo: this.$appendTo
        }
      });
      this.popup.setHeight(this.height);
      this.popup.setWidth(this.width);
      this.popup.open();
      this.popup._setTitle(this.title);
      this.listenTo(this.popup.internalView.completeView.model, "activiteSelected", this._completeViewActiviteSelected);
    } else if (this.context.ctxModeRepresentation === "Imbrique") {
      //Show/Hide imbique view
      if ($(this.context.ctxImbriqueEl).is(":visible")) {
        $(this.context.ctxImbriqueEl).hide();
        this.model.trigger("imbrique:hide");
      } else {
        $(this.context.ctxImbriqueEl).show();
        $(this.context.ctxImbriqueEl).css("width", this.width);
        $(this.context.ctxImbriqueEl).css("height", this.height);
        $(this.context.ctxImbriqueEl).addClass("cw-selecteur-activite-imbrique-border-left");
        this.model.trigger("imbrique:show");
        // render new view
        this.viewData = { context: this.context, parameters: this.parameters, currentSelection: this.selection, workflow: this.workflow };
        this.imbriqueView = new this.view(this.viewData);
        $(this.context.ctxImbriqueEl).html(this.imbriqueView.el);
        this.imbriqueView.render();
        this.listenTo(this.imbriqueView.completeView.model, "activiteSelected", this._completeViewActiviteSelected);
      }
    }
  }

  /**
   * Callback function to be executed after selection of an activity in the mode complet view
   */
  protected _completeViewActiviteSelected(value: { [key: string]: any }): void {
    const item: { [key: string]: any } = this._adaptActivitySelectedToReturned(value);
    const callback = (freshChemin?: any): void => {
      if (!CWSTR.isBlank(freshChemin)) {
        //A chemin to this element has been obtained
        item.set("hierar", freshChemin.get("hierar"));
        item.set("strapar", freshChemin.get("structa"));
        if (_.isUndefined(item.get("structid")) && !CWSTR.isBlank(freshChemin.get("structida"))) {//Uniquement si le champ "structid" n'existe pas déjà (c'est different de s'il a la valeur null-> il existe ou il a été déjà créé-> ce ne serais pas le cas
          item.set("structid", freshChemin.get("structida"));
        }
      }
      this._addItem(item.attributes, true, freshChemin);
      if (this.context.ctxModeRepresentation === "pop-up" && !CWSTR.isBlank(this.popup) || ((this.context.ctxEcran === "mdepl" || this.context.ctxEcran === "mactivites" || this.context.ctxEcran === "suivicollab") && this.context.ctxModeRepresentation === "Imbrique")) {
        this.popup.close();
      }
    };
    //Obtain view
    //let currentView = 1;
    // if (this.context.ctxModeRepresentation === "pop-up") {
    //   currentView = this.popup.internalView._obtainCurrentVue();
    // } else if (this.context.ctxModeRepresentation === "Imbrique") {
    //   currentView = this.imbriqueView._obtainCurrentVue();
    // }

    //Obtain chemin
    const params = this._getParamsForPath(item);
    if (params) {
      this._obtainPath(value.get("code"), params, callback);
    } else {
      callback();
    }

  }

  protected _adaptActivitySelectedToReturned(item: { [key: string]: any }): typeof SelecteurActiviteModeSimpleActiviteModel {
    const resp = new SelecteurActiviteModeSimpleActiviteModel({//format activity returned
      code: item.get("code"),
      typegest: item.get("typegest"),
      libelle: item.get("libelle"),
      dated: item.get("datedeb"),
      datef: item.get("datefin"),
      hierar: "",
      strapar: "",
      hierid: (!CWSTR.isBlank(item.get("hierid")) ? parseInt(item.get("hierid"), 10) : item.get("hierid")),
      stracode: "",
      straid: item.get("itemida"),
      stralib: "",
      "indic_comp_req": item.get("indic_comp_req"),
      "indic_periode": item.get("indic_periode"),
      //Parsed information
      parentstraid: CWSTR.isBlank(item.get("parentstraid")) ? "" : item.get("parentstraid"),
      parentdomlib: CWSTR.isBlank(item.get("parentdomlib")) ? "" : item.get("parentdomlib"),
      parentfmtact: CWSTR.isBlank(item.get("parentfmtact")) ? "" : item.get("parentfmtact"),
      parentfmtstra: "",
      parentdomcode: CWSTR.isBlank(item.get("parentdomcode")) ? "" : item.get("parentdomcode"),
      //Hierachie
      hiertypeniv: CWSTR.isBlank(item.get("hiertypeniv")) ? null : item.get("hiertypeniv")
    });

    if (!CWSTR.isBlank(CWSTR.isBlank(item.get("hiertypeniv"))) && !CWSTR.isBlank(this.popup) && !CWSTR.isBlank(this.popup.internalView) && !CWSTR.isBlank(this.popup.internalView.workflow.domColl) && this.popup.internalView.workflow.domColl.models.length > 0) {
      const domainData = this.popup.internalView.workflow.domColl.get(resp.get("parentdomcode"));

      if (!CWSTR.isBlank(domainData.get("hiertype")) && domainData.get("hiertype").niveaux.length > 0) {
        resp.get("hiertypeniv")["niveau_data"] = domainData.get("hiertype").niveaux[resp.get("hiertypeniv").niveau];
      }
    }
    return resp;
  }
  // resize result list on open
  /**
   * Opens a new list of autocompletion results
   */
  protected _openlist(): void {
    const parentWidth = this.$el.css("width");

    if (!this.widthDifferentAsParent) {
      //changement de la largeur du menu. Il faut après positionner autre fois (dans ce cas là, il est fait dans le méthode "open" d'autocomplete qui est defini ici)
      $(".cw-selecteur-activite-input", this.el).autocomplete("widget").css("width", parentWidth);
    }
  }

  /**
   * Cleans the current selection
   */
  clean(): void {
    this.$el.find(".cw-selecteur-activite-input").val("");
    this.selection.reset();
    this._resetTooltip();
  }

  protected _resetTooltip(): void {
    const input = this.$el.find("input." + UTILS.escapeJQueryString(this.name));

    if (input.data("ui-tooltip")) {
      input.tooltip("option", "content", "");
    }
  }

  protected _manageMouseenter(): void {
    let resp: { [key: string]: any } = null;
    const input = this.$el.find(".cw-selecteur-activite-input");
    const openTooltip = (): void => {
      let levels = [];

      if (!input.data("ui-tooltip")) {
        input.tooltip();
      }
      if (this.niveauColls && this.niveauColls[0] && this.niveauColls[0].models) {
        levels = this.niveauColls[0].models;
      }
      if (input.tooltip("option", "content").length === 0) {
        const txt = this._tooltipText(this.selection.at(0).attributes, levels);

        input.tooltip({ content: txt });
      }
      input.attr("title", "");
      input.tooltip("open");
    };
    const callback = (): void => {
      openTooltip();
      this.recentOnMouseEnter = false;
    };
    const showTooltip = (): void => {
      // add tooltip
      const structid = this.selection.at(0).attributes.attrs.parentstraid;
      this._getLevels(callback, undefined, structid);
    };
    const callbackAfterPath = (freshChemin: { [key: string]: any }): void => {
      //Include chemin from WS searchChemin
      const hierar = !CWSTR.isBlank(freshChemin) ? freshChemin.get("hierar") : [];
      const strapar = !CWSTR.isBlank(freshChemin) ? freshChemin.get("structa") : [];

      resp.set("hierar", hierar);
      resp.set("strapar", strapar);
      if (this.context.ctxSelectMultiple !== true) {
        // clear input field and set focus to it.
        this.selection.reset();
      }
      this.selection.add([resp.attributes], { parse: true });
      this.selection.at(this.selection.models.length - 1).freshChemin = freshChemin;
      showTooltip();
    };

    if (this.selection.length > 0 && !this.recentOnMouseEnter) { //The added value is not the current selection
      if (_.isEmpty(this.selection.at(0).freshChemin)) {
        const item = this.selection.at(0);

        this.recentOnMouseEnter = true;
        resp = new SelecteurActiviteModeSimpleActiviteModel({
          code: item.get("id"),
          libelle: item.get("attrs").libelle,
          hierar: "",
          strapar: "",
          dated: item.get("attrs").dated,
          datef: item.get("attrs").datef,
          hierid: item.get("attrs").hierid,
          stracode: "",
          stralib: "",
          straid: item.get("attrs").straid,
          "indic_comp_req": "",
          "indic_periode": "",
          //Parsed information
          parentstraid: item.get("attrs").structid || item.get("attrs").parentstraid,
          parentdomlib: item.get("attrs").parentdomlib,
          parentfmtact: item.get("attrs").parentfmtact,
          parentfmtstra: "",
          parentdomcode: item.get("attrs").parentdomcode,
          //Hierachie
          hiertypeniv: CWSTR.isBlank(item.get("attrs").hiertypeniv) ? null : item.get("attrs").hiertypeniv
        });
        const params = this._getParamsForPath(resp);
        if (params) {
          this._obtainPath(item.get("id"), params, callbackAfterPath);
        } else {
          openTooltip();
          this.recentOnMouseEnter = false;
        }
      } else {
        openTooltip();
      }
    }
  }

  public _keyupIcon(event: JQueryEventObject): void {
    if (event.type === "keyup") {
      const key = event.which || event.keyCode;

      if (key === 13 || key === 10) {
        this._toggleCompleteView();
      }
    }
  }
}
