import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWProfileModel } from './cwProfile.model';
import { CWSTR } from 'src/utils/cwStr';

/**
 * Collection of profiles
 */
export class CWProfilesColl<TModel extends CWProfileModel = CWProfileModel> extends Backbone.Collection<TModel> {

  constructor(models?: CWProfileModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWProfileModel;
    super(models, options);
  }

  profilesNumber(): number {
    let result = 0;
    if (this.length > 0) {
      for (let i = 0; i < this.length; i++) {
        result += this.at(i).profilesNumber();
      }
    }
    return result;
  }

  getProfile(code: any): any {
    let element: any = this.get(code);

    if (CWSTR.isBlank(element)) {
      for (let i = 0; i < this.length && CWSTR.isBlank(element); i++) {
        const profil = this.at(i);
        element = profil.getProfile(code);
      }
    }
    return element;
  }
}