import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLLanceetatFilter from '../cwFilter.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWLanceetatWorkflow } from '../models/cwLanceetat.workflow';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { CWHABILITATION } from 'src/utils/cwHabilitation';

/**
 * View for the content of the filter part
 */
export class CWFilterContentView extends CWBaseFormView<CWFilterBaseModel> {

  workflow: CWLanceetatWorkflow;
  comboThemes: any;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, options.events);
    super(options);
    this.template = TPLLanceetatFilter;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
  }

  _getModel(): CWBaseModel | any {
    return this.model;
  }

  render(): CWFilterContentView {
    const json = { 'i18n': i18n };

    const habFoncCour = this.workflow.getHabContext().copy();
    const newFoncCour: { [key: string]: any } = {};
    if (CWHABILITATION.canView("ETATSCOL.V")) {
      newFoncCour["collab"] = "ETATSCOL.V";
    }
    if (CWHABILITATION.canView("ETATS.V")) {
      newFoncCour["resp"] = "ETATS.V";
    }
    habFoncCour.update({ foncCour: newFoncCour, natGest: "" });

    this.$el.html(this.template(json));
    this.comboThemes = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "utiledition",
        comboId: "theme"
      }),
      name: "theme",
      placeholder: i18n.t('lanceetat.theme'),
      optionsRender: (value: any): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      habContext: habFoncCour
    });
    $(this.el).find(".cboTheme").html(this.comboThemes.render().el);
    return this;
  }
}
