module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="row">\n    <div class="col-4 cmbProfil"></div>\n    <div class="col">\n        <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:listerevtgen.debutPeriode")))==null?'':__t)+
'</label>\n        <div class="c-date-selector input-group">\n            <div class="input-group-prepend">\n                <div for="datedeb" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:listerevtgen.debutPeriode")))==null?'':__t)+
'</div>\n            </div>\n            <input type="text" class="datedeb typeDate form-control" value="" size="10" />\n        </div>\n    </div>\n    <div class="col">\n        <label for="datefin" class="sr-only">'+
((__t=(i18n.t("common:listerevtgen.finPeriode")))==null?'':__t)+
'</label>\n        <div class="c-date-selector input-group">\n            <div class="input-group-prepend">\n                <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:listerevtgen.finPeriode")))==null?'':__t)+
'</div>\n            </div>\n            <input type="text" class="datefin typeDate form-control" value="" size="10" />\n        </div>\n    </div>\n    <div class="col searchcollabField">\n        <label style="display:none" for="searchcollab"></label>\n        <div class="input-group">\n            <input name="searchcollab" class="searchcollab form-control" type="text" data-attributes="matricule,matriculeaux,nom,prenom" placeholder="'+
((__t=(i18n.t("common:listerevtgen.nom")))==null?'':__t)+
', '+
((__t=(i18n.t("common:listerevtgen.prenom")))==null?'':__t)+
', '+
((__t=(i18n.t("common:listerevtgen.matricule")))==null?'':__t)+
'">\n            <div class="loupe input-group-append cw-texteTresAllege">\n                <label for="loupe" class="input-group-text">'+
((__t=(icon))==null?'':__t)+
'</label>\n            </div>\n        </div>\n    </div>\n</div>';
}
return __p;
};
