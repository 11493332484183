import _ from 'underscore';
import { CWBaseGridOptions, CWBaseGridView } from '../basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDataGridModel } from './cwDataGrid.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';


export interface CWDataGridViewInterface<TModel extends CWBaseModel = CWBaseModel,
  TColl extends CWPaginatedCollection<TModel> = CWPaginatedCollection<TModel>> extends CWBaseGridOptions<TModel> {
  /**
   * @param {CWMenuGridModel} model: Model to use the grid
   */
  model?: CWDataGridModel<TColl, TModel>;
}
/**
 * Tmodel-> Type of models contians the rows in the table
 * TColl-> collections of the models
 */
export class CWDataGridView<TModel extends CWBaseModel = CWBaseModel,
  TColl extends CWPaginatedCollection<TModel> = CWPaginatedCollection<TModel>> extends CWBaseGridView<TModel, TColl> {
  /**
   * DEFAULT_NAME
   */
  DEFAULT_NANE: string;
  overflowx: number;
  overflowy: number;
  model: CWDataGridModel<TColl, TModel>;

  usecase: string;

  /**
   * Data Grid View
   */
  constructor(options: CWDataGridViewInterface<TModel, TColl>) {
    options = _.extend({ className: 'cwDataGrid flex-column' }, options);
    super(options);
    this.DEFAULT_NANE = "Data";//columns definition
    if (!options.model) {
      throw Error("You must define an CWDataGridModel to use a CWDataGridModel");
    }
    this.model = options.model;
    if (options && options.model.get("usecase")) {
      this.usecase = options.model.get("usecase")
    }
  }

  setOverflow(x: number, y: number): void {
    this.overflowx = x;
    this.overflowy = y;
  }

  /**
   * Renders this view
   */
  render(): CWDataGridView<TModel, TColl> {

    if ($(this.el).children().length === 0) { // Render the header only one time
      $(this.el).html(this.template());
      // Header
      this._renderHead();
    }
    //Width:
    $(this.el).width(this.width);

    const tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);
    }
    this.renderPaginator();

    this.positionHeader();

    if (this.maxWidth) {
      this.$el.children().first().css("max-width", this.maxWidth + "px");
    }
    return this;
  }

  remove(): CWDataGridView<TModel, TColl> {
    super.remove();
    this.stopListening();
    return this;
  }
}
