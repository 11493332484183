module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-multiLabel-bloc form-group" data-bloc-name="PRE" data-bloc-label="'+
((__t=(actprev))==null?'':__t)+
'">\n    <label for="actprev" class="title-label cw-required">'+
((__t=(i18n.t("common:gereractivites.activite")))==null?'':__t)+
'&nbsp;</label>\n    <span class="refActprev d-flex"></span>\n</div>\n\n<div class="cw-multiLabel-bloc form-group" data-bloc-name="REA" data-bloc-label="'+
((__t=(actreal))==null?'':__t)+
'">\n    <div class="phx-line">\n      <label for="actreal" class="title-label cw-required">'+
((__t=(i18n.t("common:gereractivites.activite")))==null?'':__t)+
'&nbsp;</label>\n      <span class="refActreal d-flex"></span>\n    </div>\n</div>\n';
}
return __p;
};
