import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';
import { LOG } from 'utils/log.js';

export var onComplete = function(headers) {
  if (UTILS) {
    setTimeout(function() {
      if (headers["gfi-context"]) {
        if (objs && objs.appRt &&
          (objs.appRt.blocked === true || objs.appRt.hidderDisabled === true)) {
          //don't show/hide usecase hidder when app is blocked
          if (objs.appRt.blocked && headers["gfi-context"]) {
            LOG.debug("app blocked " + JSON.parse(headers["gfi-context"]).onglet);
          }
        } else {
          UTILS.hideUsecaseHidder(JSON.parse(headers["gfi-context"]).onglet);
        }
      }
    }, 400);
  }
}
