import * as Backbone from 'Backbone';
import referentielFamilleActiviteDetailSelecteurRefDialogTPL from '../cwDetailSelecteurRefDialog.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWFamilleActiviteColl } from '../models/cwFamilleActivite.collection';
import { CWFamilleActiviteModel } from '../models/cwFamilleActivite.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Selecteur Referentiel Dialog View (Family Activies - UX)
 */
export class CWSelecteurRefDialogView extends CWBaseFormView {

  searchTerm: string;
  coll: CWFamilleActiviteColl;
  parent: any;
  table: CWDataGridView;
  $appendTo: JQuery;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.className = "0 cw-refDialogFamilleActivite";
    options.tagName = "div";
    options.searchTerm = "";
    super(options);
    this.searchTerm = "";
    this.template = referentielFamilleActiviteDetailSelecteurRefDialogTPL;
    if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.coll)) {
      this.coll = options.coll;
    }
    this.parent = options.parent;
    // Create table
    this.table = this._initTable();
    this.table.model.on("change:value", this.selection, this);
    this.table.model.coll.on("row:dblclick", this._selectionAndClose, this);
    this.$appendTo = !CWSTR.isBlank(options.appendTo) ? options.appendTo : null;
  }

  _manageTable(): void {
    const currentValue = this.selection();

    this.table.model.resetRowHeight();
    this.table.model.coll.applyFilter({ "search": this.searchTerm });
    this.table.model.coll.fetch({
      success: (): void => {
        if (!CWSTR.isBlank(currentValue)) {
          this.table.model.selectRow(currentValue);
        }        
      }
    });
  }

  _selectionAndClose(): void {
    this.trigger("close", "Y");
  }

  render(): CWSelecteurRefDialogView {
    const json = { "i18n": i18n };

    this.$el.html(this.template(json));
    // Render table
    this.$el.find(".cw-table_selecteurref_familleActivite").html(this.table.render().el);
    this.setHeightTable("230px");
    if (this.parent) {
      this.parent.setPosition({ my: "center", at: "center", of: window });
    }
    this._manageTable();
    this.$el.find(".criteres").autocomplete({
      minLength: 0,
      source: (request: { [key: string]: any }): boolean => {
        this.searchTerm = request.term;
        this._manageTable();
        return false;
      }
    });
    return this;
  }

  selection(): CWFamilleActiviteModel {
    const selection = this.table.model.get("value");

    this.trigger("response", selection);
    return selection;
  }

  _initTable(): CWDataGridView {
    const tableModel = new CWDataGridModel({ coll: this.coll, enableSelectionOnDblclick: true });
    const tableColumns: CWBaseGridColums[] = [
      { title: i18n.t('common:referentiel_famille_activite.table_selectref_code'), property: "code", width: 4 },
      { title: i18n.t('common:referentiel_famille_activite.table_selectref_libelle'), property: "libelle", width: 8 }
    ];
    const table = new CWDataGridView({
      id: "cw-familleActivite_selectref_table",
      columns: tableColumns,
      model: tableModel,
      title: i18n.t('common:referentiel_famille_activite.title_table'),
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      parentContainer: this.parent,
      paginatorPosition: "butom",
    });

    table.width = 550;
    table.height = 230;
    table.setVisibleColumns(["code", "libelle"]);
    return table;
  }

  setHeightTable(valeur: string): void {
    if (!CWSTR.isBlank(valeur) && (valeur.indexOf("px") > 0 || valeur.indexOf("%") > 0 || valeur.indexOf("%") > 0 || valeur.indexOf("em") > 0 || valeur.indexOf("rem") > 0 || valeur === "auto")) {
      this.$el.find(".ui-grid.c-grind__scroll").css("height", "230px");
    }
  }
}
