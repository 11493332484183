module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="d-flex flex-column">\n    <div class="d-flex align-items-center justify-content-end">\n        <div class="cw-viewsmenu-title cw-texteTresAllege">\n            '+
((__t=(text))==null?'':__t)+
'\n        </div>\n        <div class="cw-hd-main-btn" style="float:right;width: 18px;margin:1;padding:0;margin-right:1px">\n            <span style="margin:1;padding:0" class="cw-grid-vues-btn ml-2">'+
((__t=(UTILS.getSVGIcon("cercle_plein_trois_points","cw-icon--primary cw-icon--selectable cw-icon--clickable",13)))==null?'':__t)+
'</span>\n            <ul class="cw-grid-vues-chooser" style="position: fixed;z-index: 11;"></ul>\n        </div>\n    </div>\n    <div class="cw-hd-leftViews"></div>\n</div>';
}
return __p;
};
