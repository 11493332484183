import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCommonTreeNode from './cwTreeNode.tpl.html';
import { CWActiviteColl } from 'common/evenements/planifier/activite/models/cwActivite.collection';
import { CWBaseCollection } from 'core/models/cwBase.collection';
import { CWFORMS } from 'utils/cwForms';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { CWTree2View } from './cwTree2.view';
import { CWTreeModel } from './cwTree.model';
import { CWTreeNodeColl } from './cwTreeNode.collection';
import { CWTreeNodeModel } from './cwTreeNode.model';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

interface CWTree2NodeViewOption extends Backbone.ViewOptions {
  level?: any;
  parent?: CWTree2View | CWTree2NodeView;
  root?: CWTreeModel;
  firstLevelNode?: any;
  node?: any;
  showSelected?: any;
  selectableNode?: any;
  multiselect?: any;
  isCheckedCallback?: any;
  hideCheck?: any;
  readOnly?: any;
  hieractivitidad?: any;
  context?: { [key: string]: any };
  view?: number;
  expandedNodes?: boolean;
}

export enum CWActiviteTreesViewType {
  "FAMILLE" = 1,
  "HIERARCHIE" = 2,
  "STRUCTURESIMPLE" = 3,
  "STRUCTUREDETAIL" = 4,
}

export class CWTree2NodeView extends Backbone.View {
  sonsColl: CWTree2NodeView[];
  parent: CWTree2NodeView | CWTree2View | any;
  tooltipRenderer: (node: { [key: string]: any }, context: { [key: string]: any }) => void;
  overLabelTooltipRenderer: (node: { [key: string]: any }, context: { [key: string]: any }, calback?: () => void) => void;
  showSelected: boolean;
  sortTree: (a: any, b: any) => number;
  readOnly: boolean;
  model: CWTreeNodeModel;
  checkClass: any;
  selectableNode: any;
  multiselect: any;
  isCheckedCallback: any;
  hideCheck: any;
  hieractivitidad: any
  checkSons: boolean;
  opennode: any;
  checkPeriode: (...args: any[]) => any;
  context: { [key: string]: any };
  flagChangeSon: boolean;
  view: number;
  expandedNodes: boolean;

  /**
   * View of nodes of the Tree Component
   */
  constructor(params?: CWTree2NodeViewOption) {
    params = params || {};
    params.className = "cw-treenode cw-treenode-expandable";
    params.events = _.extend({
      "click .cw-treenode-icon": "_changeTreeState",
      "click .cw-treenode-label": "itemSelected",
      "dblclick .cw-treenode-label": "_itemChoosen",
      "click .cw-treenode-refresh": "_refreshClickedSubtree",
      "click .cw-treenode-prev": "_refreshClickedSubtree",
      "click .cw-treenode-next": "_refreshClickedSubtree",
      "change .cw-treenode-check-container input[type='checkbox']": "_changeCheckState",
    }, params.events);
    super(params);
    this.template = TPLCommonTreeNode;
    this.model = new CWTreeNodeModel();
    this.sonsColl = [];
    this.parent = undefined;
    this.tooltipRenderer = undefined;
    this.overLabelTooltipRenderer = undefined;
    this.showSelected = undefined;
    this.sortTree = undefined;
    this.readOnly = false;
    this.checkPeriode = null;
    this.flagChangeSon = false;
    this.context = (params && !_.isEmpty(params.context)) ? params.context : null;
    if (params.parent) {
      this.parent = params.parent;
    }
    if (params.root) {
      this.model.root = params.root;
      if (this.model.root.rootModel) {
        this.model.node = this.model.root.rootModel;
      }
      if (params.firstLevelNode && params.firstLevelNode === true &&
        this.model.root.firstLevelModels) {
        this.model.firstLevelModels = this.model.root.firstLevelModels;
        this.model.firstLevelNode = true;
      }
      if (this.model.root.checkClass) {
        this.checkClass = this.model.root.checkClass;
      }
      if (this.model.root.selectableNode) {
        this.model.selectableNode = this.model.root.selectableNode;
      }
      if (!CWSTR.isBlank(this.model.root.get("label"))) {
        this.model.set("label", this.model.root.get("label"));
      } else if (this.model.node) {
        if (this.model.root.renderer) {
          this.model.set("label", this.model.root.renderer.call(this, this.model.node));
        } else {
          this.model.set("label", this.model.node.get("libelle"));
        }
        if (this.model.root.tooltipRenderer) {
          this.tooltipRenderer = this.model.root.tooltipRenderer;
        }
        if (this.model.root.overLabelTooltipRenderer) {
          this.overLabelTooltipRenderer = this.model.root.overLabelTooltipRenderer;
        }
      }
    }
    // information associated with current node
    if (params.node) {
      this.model.node = params.node;
      if (this.model.root.renderer) {
        this.model.set("label", this.model.root.renderer.call(this, this.model.node));
      } else {
        this.model.set("label", this.model.node.get("libelle"));
      }
      if (this.model.root.checkPeriode) {
        this.checkPeriode = this.model.root.checkPeriode;
      }
    }
    if (params.showSelected) {
      this.showSelected = params.showSelected;
    }
    this.selectableNode = true;
    if (!CWSTR.isBlank(params.selectableNode)) {
      this.selectableNode = params.selectableNode;
    }
    this.multiselect = false;
    if (!CWSTR.isBlank(params.multiselect)) {
      this.multiselect = params.multiselect;
    }
    if (params && params.isCheckedCallback) {
      this.isCheckedCallback = params.isCheckedCallback;
      this._isChecked = params.isCheckedCallback;
    }
    this.hideCheck = false;
    if (!CWSTR.isBlank(params.hideCheck)) {
      this.hideCheck = params.hideCheck;
    }
    if (!CWSTR.isBlank(params.readOnly)) {
      this.readOnly = params.readOnly;
    }
    if (params && params.hieractivitidad) {
      this.hieractivitidad = params.hieractivitidad;
    }
    if (params && params.expandedNodes) {
      this.expandedNodes = params.expandedNodes;
    }
    if (this.multiselect && (CWSTR.isBlank(this.parent) || CWSTR.isBlank(this.parent.model) || CWSTR.isBlank(this.parent.model.checkedColl))) {
      if (!CWSTR.isBlank(this.parent) && !CWSTR.isBlank(this.parent.model) &&
        !CWSTR.isBlank(this.parent.model.root) && !CWSTR.isBlank(this.parent.model.root.checkedColl)) {
        this.parent.model.checkedColl = this.parent.model.root.checkedColl;
      } else {
        throw Error("You must use a model.checkedColl in multiselection mode");
      }
    }
    if (params && params.view) {
      this.view = params.view;
    }
    this.model.set("level", params.level);
    this.listenTo(this.model, "updateTreeNode", this._updateTreeNode);
    this.listenTo(this.model, "updateTreeNodeRecursive", this._updateTreeNodeRecursive);
    this.listenTo(this.model, "updateParentTreeNode", this._updateParentTreeNode);
    this.listenTo(this.model, "refresh", this._refreshSubtreeByTrigger);
    this._addListenersToNode();
    this.model.coll = this.model.root.coll.clone();
    if (!CWSTR.isBlank(this.model.root.coll.habContext)) {
      this.model.coll.setHabContext(this.model.root.coll.getHabContext());
    } else {
      this.model.coll.usecase = this.model.root.coll.usecase;
      this.model.coll.habilitationV = this.model.root.coll.habilitationV;
      this.model.coll.habilitationG = this.model.root.coll.habilitationG;
    }
    this.model.coll.structid = this.model.root.coll.structid;
    this.model.coll.domaine = this.model.root.coll.domaine;
    this.model.root.buildUrlColl.call(this, this.model.get("level"), this.model.node, this.model.coll);
    this.id = this.cid;
  }

  /**
   * Repaint the displayed label and the tooltip if is defined.
   */
  repaintLabel(renderer: (node: { [key: string]: any }, context: { [key: string]: any }) => void): void {
    if (this.$el.find(".cw-treenode-label").length > 0) {
      const label = renderer.call(this, this.model.node);

      this.model.set("label", label);
      $(this.$el.find(".cw-treenode-label")[0]).html(label);
      //show tooltips
      if (this.tooltipRenderer) {
        this.tooltipRenderer(this.model.node, this);
      }
      if (this.overLabelTooltipRenderer) {
        this.overLabelTooltipRenderer(this.model.node, this);
      }
      if (this.checkClass) {
        const iconClass = this.checkClass(this.model.node, this);

        if (!CWSTR.isBlank(iconClass)) {
          this.$el.addClass(iconClass);
        }
      }
      for (let i = 0; i < this.sonsColl.length; i++) {
        this.sonsColl[i].repaintLabel(renderer);
      }
      this._sortSubTree();
    }
  }

  /**
   * Render Main View Function
   * 	Sets the drag and drop option if enabled.
   *  Renders the tooltips.
   */
  render(callback?: () => void): this {
    const json = { "i18n": i18n, cid: this.cid, libelle: this.model.root.renderer.call(this, this.model.node) };
    let posNode: { [key: string]: any } = null;
    let posNodeContainer = null;
    let posNodeLabel = null;
    let posWrap = null;

    this.$el.attr("id", this.id);
    this.$el.addClass("cw-level-" + this.model.get("level"));
    this.$el.html(this.template(json));
    //Add data for future acces
    posWrap = this.$el.find(".cw-treenode-wrap");
    posWrap.data("view", this);
    posNode = this.$el.find(".cw-treenode-check");
    posNodeContainer = this.$el.find(".cw-treenode-check-container");
    posNodeLabel = this.$el.find(".cw-treenode-label");
    if (this.model.root.get("draggable") === true) {
      posWrap.draggable({
        revert: true,
        delay: 150,
        //make a copy of the content and adds to a floating on the cursor
        helper: "clone",
        cursorAt: { top: -15, left: -15 },
        start: this._calculateDrops,
        stop: this._resetDrops
      });
      posWrap.droppable({
        //Don't propagate the drop event
        greedy: true,
        //Set the drag interact on cursor
        tolerance: "pointer",
        //Class when hover accepted element
        hoverClass: "ui-drop-hover",
        //Function when drop an acceptable element
        drop: (event, ui) => {
          const targetView = $(event.target).data("view");
          const origView = ui.draggable.data("view");

          CWLOG.debug("DROPPED SUCCES !!! From: " + origView.model.get("label") + "(" + origView.model.cid + ") To:" + targetView.model.get("label") + "(" + targetView.model.cid + ")");
          origView.callback = this.model.root.dragAndDropCallback;
          origView.targetView = targetView;
        }
      });
    }
    //Decide if this node is selectable or it isn't
    //If data returned for selectionnable is false or null, don't let select the node
    //If the element has been marked  as selectableNode=false (for familles structures etc) don't select the element
    //If value of Rechrexp hierarchie niveau is false 
    if (!this.checksRechrexp(this.model.node) || (!CWSTR.isBlank(this.model.node.get("selectionnable")) && this.model.node.get("selectionnable") === false) || _.isNull(this.model.node.get("selectionnable")) ||
      this.selectableNode === false) { //add class to know that this node is not selectionnable
      this.$el.find(".cw-treenode-label").addClass("cw-node-not-selectionnable").addClass("ui-cw-ihm-non-selectionnable");
    }
    //add id information to nodeview in order to find it easily  by html
    if (!CWSTR.isBlank(this.model.node.get("id"))) {
      this.$el.attr("tree-node-Id", this.model.node.get("id"));
    }
    this._showHideIcon();
    //show tooltips
    if (this.tooltipRenderer) {
      this.tooltipRenderer(this.model.node, this);
    }
    //show tooltips
    if (this.overLabelTooltipRenderer) {
      this.overLabelTooltipRenderer(this.model.node, this, callback);
    }
    if (this.checkClass) {
      const iconClass = this.checkClass(this.model.node, this);

      if (!CWSTR.isBlank(iconClass)) {
        this.$el.addClass(iconClass);
      }
    }
    if (this.model.node.get("feuille") && this.checkPeriode && typeof this.checkPeriode === "function") {
      if (!this.checkPeriode(this.model.node, this.context)) {
        const $lPosCheck = this.$el.find("label[for^='cw-treenode-check" + this.cid + "']");//avec check
        const $lposSpan = this.$el.find("span.cw-treenode-label");//sans check

        if ($lPosCheck && $lPosCheck.length > 0 && !$lPosCheck.hasClass("cw-texteSuperAllege")) {
          $lPosCheck.addClass("cw-texteSuperAllege").removeClass("cw-texteNormal");
        }
        if ($lposSpan && $lposSpan.length > 0 && !$lposSpan.hasClass("cw-texteSuperAllege")) {
          $lposSpan.addClass("cw-texteSuperAllege").removeClass("cw-texteNormal");
        }
      }
    }
    posWrap.hover((e) => {
      this._showRefreshButton(e);
    });
    //EVO 186: Multiselection mode, show the checkboxes
    if (this.multiselect) {
      const lfCallback = (result: boolean): boolean => {
        if (result) {
          posNode.prop('checked', true);
          //Check all parents
          if (this.model.node && _.isBoolean(this.model.node.get("feuille"))) {
            const isExistHier = (this.view === CWActiviteTreesViewType.HIERARCHIE);//si l'il y a de l'hiérarchie, il faut ne pas rechercher les parents

            if (!isExistHier) {
              this._checkAllParents(this, null, this.model.node.get("feuille"));
            }
          } else {
            this._checkAllParents(this);
          }
          return true
        } else {
          posNode.prop('checked', false);

          return false;
        }
      };

      posNodeContainer.show();
      posNodeLabel.hide();
      this._isChecked(this.model, lfCallback);
      //if (!STR.isBlank(this.parent.readOnly)) {
      if (!CWSTR.isBlank(this.readOnly)) {
        //FORMS.setFieldReadonly(l_posNode, this.parent.readOnly, true);
        CWFORMS.setFieldReadonly(posNode, this.readOnly, true);
      }
    }
    //Add class to identify checkbox for this node
    posNode.addClass("treenode-check-" + UTILS.escapeJQueryString(String(this.model.node.get("code"))));
    posNode.addClass("custom-control-input");
    if (this.model.node.get("feuille") === true && this.hideCheck === true) {
      posNodeContainer.hide(); //check manqué
      posNodeLabel.show(); //check manqué
      //FORMS.setFieldReadonly(this.$el.find(".cw-treenode-check.treenode-check-" + UTILS.escapeJQueryString(String(this.model.node.get("code")))), true, false);
    }
    if (!this.overLabelTooltipRenderer) {
      if (callback) {
        callback();
      }
    }
    return this;
  }

  /**
   * Set selectionable with hierarchie niveau of Rechrexp 
   */
  checksRechrexp(node: { [key: string]: any }): boolean {
    const niveau = CWSTR.isBlank(node.get("hiertypeniv")) ? null : node.get("hiertypeniv").niveau;
    let selectionable = true;
    let found = null;

    // we make not selectionable only in explotation
    if (niveau && this.hieractivitidad && this.hieractivitidad.habContext.get("onglet") !== "activite") {
      found = this.hieractivitidad.find((item: { [key: string]: any }) => {
        return item.get('niveau') === niveau;
      });
    }
    if (found && found.get("saisieexp") === false) {
      selectionable = false;
    }
    return selectionable;
  }

  /**
   * Show/Hide the expand node button
   */
  _showHideIcon(): void {
    if (!CWSTR.isBlank(this.model) && !CWSTR.isBlank(this.model.node)) {
      const feuille = this.model.node.get("feuille");
      const $containMarq = this.$el.find(".containMarq");
      let avecmarq = false;

      //Show or not the expand icon
      if (feuille === false || this.model.get("hasChild") > 0) {
        this.$el.find(".cw-treenode-icon").first().show();
        this.$el.find(".cw-treenode-leaf-icon").first().hide();
      } else {
        this.$el.find(".cw-treenode-icon").first().hide();
        this.$el.find(".cw-treenode-leaf-icon").first().show();
      }
      //Show Marqueur icon
      avecmarq = this.model.node.get("avecmarq") || false;
      if (avecmarq === false) {
        $containMarq.first().hide();
      } else if ($containMarq.length > 0) {
        $containMarq.first().show();
      } else {
        const containMarqIcon = $("<span class='containMarq'></span>");

        this.$el.append(containMarqIcon);
      }
    }
  }

  _showArrows(): void {
    if (!CWSTR.isBlank(this.model) && !CWSTR.isBlank(this.model.node) && !CWSTR.isBlank(this.model.root) &&
      this.model.node.get("id") === this.model.root.rootModel.get("id")) {
      this._manageArrowsVisibility();
    }
  }

  _manageArrowsVisibility(): void {
    if (this.model.coll.paginated && (this.model.coll.totalRecords > this.model.coll.pagination.size)) {
      $(".cw-treenode-prev", this.el).first().css("display", "inline-block");
      $(".cw-treenode-next", this.el).first().css("display", "inline-block");
      if (this.model.coll.pagination.startIndex < this.model.coll.pagination.size) {
        $("button.cw-treenode-prev", this.el).first().addClass("ui-button-disabled ui-state-disabled").prop("disabled", true);
      } else {
        $("button.cw-treenode-prev", this.el).first().removeClass("ui-button-disabled ui-state-disabled").prop("disabled", false);
      }
      if ((this.model.coll.totalRecords - this.model.coll.pagination.startIndex) < this.model.coll.pagination.size) {
        $(".cw-treenode-next", this.el).first().addClass("ui-button-disabled ui-state-disabled").prop("disabled", true);
      } else {
        $(".cw-treenode-next", this.el).first().removeClass("ui-button-disabled ui-state-disabled").prop("disabled", false);
      }
    }
  }

  /**
   * Manage the Refresh Button Display.
   */
  _showRefreshButton(event: { [key: string]: any }): void {
    if (event.type === "mouseenter") { // When the mouse enter on the title block
      $(".cw-treenode-refresh", this.el).first().css("visibility", "visible");
    } else {
      $(".cw-treenode-refresh", this.el).first().css("visibility", "hidden");
    }
  }

  /**
   * Function called when start the drag.
   */
  _calculateDrops(event: { [key: string]: any }): void {
    const view = $(event.target).data("view");
    const model = view.model;

    CWLOG.debug("dragged");
    //Disable own
    $(event.target).droppable("disable");
    //Disable the childs
    if (model.get("hasChild") > 0 && model.get("expanded") === true) {
      //disable droppable from childrens
      view.$el.children(".cw-treenode").find(".cw-treenode-wrap").droppable("disable");
    }
    //disable the direct father
    view.$el.parent().children(".cw-treenode-wrap").droppable("disable");
  }

  /**
   * Function called when end the drag.
   */
  _resetDrops(event: { [key: string]: any }): void {
    const targetView = $(event.target).data("view");

    CWLOG.debug("End Drag");
    //Enable all droppables
    if (targetView) {
      if (targetView.$el && targetView.$el.parent()) {
        targetView.$el.parent().find(".cw-treenode-wrap.ui-droppable-disabled").droppable("enable");
      }
      if (targetView.callback) {
        targetView.callback(targetView, targetView.targetView);
        targetView.callback = undefined;
      }
    }
  }

  /**
   * Expand/Collapses the Tree Node.
   */
  _changeTreeState(event: { [key: string]: any }, callback?: () => void): false {
    if (this.$el.hasClass("cw-treenode-expandable")) {
      this._expand(event.target, callback);
    } else {
      this._collapse();
    }
    return false;
  }

  /**
   * Manages the node selection.
   */
  itemSelected(event: { [key: string]: any }): false {
    CWLOG.debug("node selected");
    if (!this.$el.find(".cw-treenode-label").first().hasClass("cw-node-not-selectionnable")) { //When this node can be selected
      if (this.showSelected) {
        this.$el.find("span.cw-treenode-label").first().addClass("ui-state-active");
        this.$el.find("span.cw-treenode-label").first().addClass("cw-treeNode-lastNodeSelected");
      }
      if (event && event.ctrlKey) {
        this.model.root.trigger("nodeSelectedWithCtrlKey", this.model);
      } else {
        if (CWSTR.isBlank(this.parent.filter) || this.parent.filter !== true) {
          this.model.root.trigger("nodeSelected", this.model);
        }
        this.parent.filter = false;
      }
    }
    return false;
  }

  /**
   * Manages the node selection.
   */
  _itemChoosen(): false {
    CWLOG.debug("node choosen");
    if (!this.$el.find(".cw-treenode-label").first().hasClass("cw-node-not-selectionnable")) {
      this.model.root.trigger("nodeChoosen", this.model);
    }
    return false;
  }

  /**
   * Clean the View
   */
  empty(): void {
    // Clean memory
    for (let i = 0; i < this.sonsColl.length; i++) {
      this.sonsColl[i].remove();
    }
    this.sonsColl = [];
    // Clean DOM
    this.$el.empty();
  }

  /**
   * Remove the View.
   */
  remove(): Backbone.View {
    let res = null;

    // COMPLETELY UNBIND THE VIEW
    if (this.$el) {
      if (this.$el.off) {
        this.undelegateEvents();
      }
      this.$el.removeData().unbind();
    }
    delete this.model;
    this.clearSonsArray();
    res = super.remove(); // Remove view from DOM
    delete this.$el; // Delete the jQuery wrapped object variable
    delete this.el; // Delete the variable reference to this node
    return res;
  }

  clearSonsArray(): void {
    // Clean memory
    for (let i = 0; i < this.sonsColl.length; i++) {
      if (this.sonsColl[i].remove) {
        this.sonsColl[i].remove();
      }
    }
    this.sonsColl = [];
  }

  //EVO 186: Multiselection mode, add/remove checked collections ----->
  /**
   * Manages the checkboxes selection.
   */
  _changeCheckState(event: { [key: string]: any }): void {
    const callback = (result: boolean): boolean => {
      let res = false;
      const isExistHierStruct = ((this.view === CWActiviteTreesViewType.HIERARCHIE) || (this.view === CWActiviteTreesViewType.STRUCTURESIMPLE && !CWSTR.isBlank(this.model.node.get("hierid"))));

      this.model.isChecked = !result;
      if (this.model.isChecked && this.model.node && (!CWSTR.isBlank(this.model.node.get("code")) || /* ou hors structure, structure simple*/
        (this.view === CWActiviteTreesViewType.STRUCTURESIMPLE && CWSTR.isBlank(this.model.node.get("hierid")) && CWSTR.isBlank(this.model.node.get("structida")) && CWSTR.isBlank(this.model.node.get("itemida"))))) {
        this.parent.model.checkedColl.add(this.model);
        if (CWSTR.isBlank(this.parent.model.get("value"))) {
          // if no rows are selected and a row is checked for multiselection, we select the current row
          this.model.trigger("row:click", this.model);
          res = true;
        }
        if (!isExistHierStruct) {
          //Check all parents (mais avant si ce n'est pas une feuille, doit verifier tous les fills)        
          this._checkAllParents(this, null, this.model.node.get("feuille"));
        } else if (isExistHierStruct && this.view === CWActiviteTreesViewType.STRUCTURESIMPLE && res) {
          const parentStruct = this.getFirstLevelModel(this);//._getParent(this)

          //il faut cocher le nœud racine
          if (!_.isEmpty(parentStruct) && !_.isEmpty(parentStruct.model)) {
            parentStruct.$el.find(".treenode-check-" + UTILS.escapeJQueryString(String(parentStruct.model.node.get("code")))).prop('checked', true);
            parentStruct.model.isChecked = true;
          }
        }
      } else {
        if (!isExistHierStruct && ((this.model?.node?.parent && !CWSTR.isBlank(this.model.node.parent.get("code"))) || /* ou hors structure, structure simple*/
          (this.view === CWActiviteTreesViewType.STRUCTURESIMPLE && this.model.node.parent && CWSTR.isBlank(this.model.node.parent.get("hierid")) && CWSTR.isBlank(this.model.node.parent.get("structida")) && CWSTR.isBlank(this.model.node.parent.get("itemida"))))) {
          this._checkAllParents(this, this.model.node.parent.get("code"), this.model.node.get("feuille"));
        }
        if (!_.isBoolean(this.model.node.get("feuille")) || !this.model.node.get("feuille")) {
          this.checkSons = true;
        }
        this.parent.model.checkedColl.remove(this.model);
        res = false;
        if (isExistHierStruct) {//struct+hier           
          const parentStruct = this.getFirstLevelModel(this);

          if (!_.isEmpty(parentStruct) && !_.isEmpty(parentStruct.model)) {
            const firstLevel = this.getFirstLevelModel(this);

            if (firstLevel) {
              const $posChecked = firstLevel.$el.find(".cw-treenode-check");
              let exist = false;

              if ($posChecked && $posChecked.length > 1) {
                for (let i = 1; i < $posChecked.length && !exist; i++) {
                  const $nodeView = $($posChecked[i]);

                  if ($nodeView && $nodeView.prop("checked") === true) {
                    exist = true;
                  }
                }
              }
              if (!exist) {//il faut décocher le noeud racine si l'il n'y a pas d'autres noeuds cochés
                parentStruct.$el.find(".treenode-check-" + UTILS.escapeJQueryString(String(parentStruct.model.node.get("code")))).prop('checked', false);
                parentStruct.model.isChecked = false;
              }
            }
          }
        }
      }
      if (!isExistHierStruct && (CWSTR.isBlank(this.checkSons) || this.checkSons)) {
        this._checkAllSons(this, this.model.isChecked);
        res = true;
      }
      return res;
    };

    this._isChecked(this.model, callback);
    event.stopPropagation();
  }

  _checkAllParents(view: CWTree2NodeView, codeParent?: number | string, isFeuille?: boolean): void {
    if (!CWSTR.isBlank(view.parent)) {
      const lParent: CWTree2NodeView = view.parent;
      const isExistHierStruct = ((this.view === CWActiviteTreesViewType.HIERARCHIE) || (this.view === CWActiviteTreesViewType.STRUCTURESIMPLE && !CWSTR.isBlank(lParent.model.node.get("hierid"))));

      if (!CWSTR.isBlank(lParent) && !CWSTR.isBlank(lParent.model) && !CWSTR.isBlank(lParent.model.node)) {
        const callback = (result: boolean, searchSons?: boolean): boolean => {
          if (!result) { //True is an a node with sons and false is a node with sons            
            lParent.checkSons = (_.isBoolean(searchSons)) ? searchSons : false; //Prevent to check sons            
            if (!CWSTR.isBlank(lParent.model.node.get("code")) || /*ou pour l'hors structure sur la vue "Structure simple"*/
              (this.view === CWActiviteTreesViewType.STRUCTURESIMPLE && lParent.model.node.get("code") === " " && lParent.$el.find(".treenode-check-" + UTILS.escapeJQueryString(String(lParent.model.node.get("code")))).length > 0)) {
              if (!isExistHierStruct) {
                lParent.$el.find(".treenode-check-" + UTILS.escapeJQueryString(String(lParent.model.node.get("code")))).click(); //Click in the checkbox to trigger the events
              } else {
                const parentStruct = this.getFirstLevelModel(this);

                //il faut cocher le nœud racine
                if (!_.isEmpty(parentStruct) && !_.isEmpty(parentStruct.model)) {
                  parentStruct.$el.find(".treenode-check-" + UTILS.escapeJQueryString(String(parentStruct.model.node.get("code")))).prop('checked', true);
                  parentStruct.model.isChecked = true;
                }
              }
            }
            if (isFeuille === true) {
              lParent.checkSons = false;
              lParent.flagChangeSon = true;
            } else {
              lParent.checkSons = true;
            }
            return true;
          }
          return false;
        };

        if (!CWSTR.isBlank(codeParent)) {
          //On doit chercher s'il y a d'autre activité cochée ou pas
          if (!this._checkAnotherSon(lParent.model.checkedColl, codeParent)) {
            callback(false, true);
            if (lParent.flagChangeSon) {
              //retablir la valeur du père pour pouvoir check les filles dans la prochaine actión de manière correcte
              lParent.flagChangeSon = false;
              lParent.checkSons = true;
            }
          } else {
            if (isFeuille === true) {
              lParent.checkSons = false;
            } else {
              lParent.checkSons = true;
            }
          }
        } else if (this.view === CWActiviteTreesViewType.STRUCTURESIMPLE && codeParent === " ") {//hors structure sur la vue "Structure simple"
          if (!this._checkAnotherSonHorsStructure(lParent.model.checkedColl, codeParent)) {
            callback(false, true);
            if (lParent.flagChangeSon) {
              //retablir la valeur du père pour pouvoir check les filles dans la prochaine actión de manière correcte
              lParent.flagChangeSon = false;
              lParent.checkSons = true;
            }
          }
          else {
            if (isFeuille === true) {
              lParent.checkSons = false;
            } else {
              lParent.checkSons = true;
            }
          }
        }
        else {
          this._isChecked(lParent.model, callback);
        }
      }
    }
  }

  /**
   * Checks if the activity model is added to the collection.
   */
  _isChecked(model: { [key: string]: any }, callback?: (check: boolean) => boolean): boolean {
    const item = this.parent.model.checkedColl.find((aItem: { [key: string]: any }) => {
      if (!CWSTR.isBlank(aItem) && !CWSTR.isBlank(aItem.node)) {
        if (model.node.get("code") === aItem.node.get("code")) {
          return true;
        }
        return false;
      } else {
        return aItem.cid === model.cid
      }
    });

    if (!CWSTR.isBlank(item)) {
      //Check if the model is inserted, and remplace it for the real model
      if (!CWSTR.isBlank(item.flagSetted) && item.flagSetted === true) {
        this.parent.model.checkedColl.remove(item);
        this.parent.model.checkedColl.add(model);
      }
      if (callback) {
        return callback(true);
      }
      return true;
    }
    if (callback) {
      return callback(false);
    }
    return false;
  }

  _getParentNodesAsString(model: { [key: string]: any }): string {
    let nodesString = "";

    if (!CWSTR.isBlank(model) && !CWSTR.isBlank(model.node)) {
      const parents = model.node.get("parents");

      for (let i = 1; i < parents.length; i++) {
        nodesString += parents[i].code + "/";
      }
      nodesString = nodesString.slice(0, -1); //Remove last "/"
    }
    return nodesString;
  }

  /**
   * Checks all sons of one activity famlies.
   */
  _checkAllSons(context: CWTree2NodeView, check: boolean): void {
    if (context.model.node.get("feuille") === false && context.sonsColl.length === 0) { //Node have childrens and don't have expanded
      context._expand(null, () => {
        if (context.sonsColl.length !== 0) { //Have childs!
          context._checkAllSons(context, check);
        }
      });
    }
    _.each(context.sonsColl, (view) => { //Model.node.childrens
      const callback = (result: boolean): boolean => {
        if (!result && check) {
          context.parent.model.checkedColl.add(view.model);
          return true;
        } else if (result && !check) {
          context.parent.model.checkedColl.remove(view.model);
          return false;
        }
        return false;
      };
      let feuille = null;

      if (view.hideCheck !== true || (view.hideCheck === true && view.model.node.get("feuille") === false)) {
        context._isChecked(view.model, callback);
        $(view.el).find(".cw-treenode-check").prop('checked', check);
      }
      //Check if the node have childrens
      feuille = view.model.node.get("feuille");
      if (feuille === false) { //Hab hildrens
        view._expand(null, () => {
          view._checkAllSons(view, check);
        });
      }
    });
  }

  /**
   * collapse all nodes
   */
  _collapseAllNodes(context?: CWTree2NodeView): void {
    context = context || this;
    _.each(context.sonsColl, (view) => { //Model.node.childrens
      const feuille = view.model.node.get("feuille");

      if (feuille === false) { //Hab hildrens
        if (view.sonsColl.length > 0) { //Children has expanded
          context._collapseAllNodes(view);
        }
      }
    });
    if (context.model.node.get("feuille") === false && !CWSTR.isBlank(context.model.node.niveau)) {
      context._collapse();
    }
  }

  /**
   * Expand all nodes and execute the calback when all the node are load and expand.
   */
  _expandAllNodes(context?: CWTree2NodeView, calback?: () => void): void {
    context = context || this;
    const promises: JQueryXHR[] = new Array<JQueryXHR>();

    if (context.model.node.get("feuille") === false && context.sonsColl.length === 0) { //Node have childrens and don't have expanded
      promises.push(context._expand(null, () => {
        if (context.sonsColl.length !== 0) { //Have childs!
          context._expandAllNodes(context);
        }
      }));
    }
    _.each(context.sonsColl, (view) => { //Model.node.childrens
      const feuille = view.model.node.get("feuille");

      if (feuille === false) { //Hab hildrens
        promises.push(view._expand(null, () => {
          view._expandAllNodes(view);
        }))
      }
    });
    if (calback) {
      Promise.all(promises).then((): void => {
        calback();
      })
    }
  }

  /**
   * Expand the Node, fetching the children collection of items and retrun the result of fetch.
   */
  _expand(target: { [key: string]: any }, callback?: (coll: CWBaseCollection, sonsColl: CWTree2NodeView[]) => void, shouldReload?: boolean): JQueryXHR {
    let fictitiousRoot = false;
    let fetchData: JQueryXHR = null;

    if (!CWSTR.isBlank(this.model.firstLevelNode) && this.model.firstLevelNode === true && this.model.firstLevelModels) {
      fictitiousRoot = true;
    }

    // Accessibility parameters
    this.$el.find(".cw-treenode-row:first").attr("aria-expanded","true");

    this.$el.removeClass("cw-treenode-expandable").addClass("cw-treenode-collapsable");
    if (fictitiousRoot) { //For fictitiousroot that is not going to be shown, don't show icon
      this.$el.removeClass("cw-treenode-collapsable");
    }
    if (shouldReload === true) {
      if (fictitiousRoot === true) { //When many roots are going to be used
        const lFuncSub1 = (): void => {
          this._sortSubTree();
          this._showHideIcon();
          this._showArrows();
          this.model.node.childrens = this.model.coll;
          if (callback) {
            callback(this.model.coll, this.sonsColl);
          }
        };

        this.model.coll.reset();
        this.clearSonsArray();
        _.each(this.model.firstLevelModels, (firstLevelModel) => {
          this.model.coll.add(firstLevelModel);
        });
        if (CWSTR.isBlank(this.model.coll.vue) || this.model.coll.vue === 3 || this.model.coll.vue === 4) {
          this.fillSubTree(null, lFuncSub1);
        } else {
          this.fillSubTreeRest(null, lFuncSub1);
        }
      } else { //When there is only one root
        this.model.coll.reset();
        this.clearSonsArray();
        if (this.model.node.get("typelt") === "A" && !CWSTR.isBlank(this.model.node.get("hierid"))) {
          this.model.coll.hierid = this.model.node.get("hierid");
        }
        fetchData = this.model.coll.fetch({
          success: (fresh: CWTreeNodeColl) => {
            let lFuncSub2 = null;

            if (this.model && this.model.root) {
              this.model.root.trigger("updatedTreeNodeCollection", fresh, this.model.node);
            }
            lFuncSub2 = (): void => {
              this._sortSubTree();
              this._showArrows();
              this._showHideIcon();
              this.model.node.childrens = this.model.coll;
              if (callback) {
                callback(this.model.coll, this.sonsColl);
              }
            };
            if (CWSTR.isBlank(this.model.coll.vue) || this.model.coll.vue === 3 || this.model.coll.vue === 4) {
              this.fillSubTree(null, lFuncSub2);
            } else {
              this.fillSubTreeRest(null, lFuncSub2);
            }
          }
        });
      }
    } else {
      if (CWSTR.isBlank(this.model.get("hasChild"))) {
        if (fictitiousRoot === true) { //When many roots are going to be used
          const lFuncSub3 = (): void => {
            this._sortSubTree();
            this._showArrows();
            this._showHideIcon();
            if (!CWSTR.isBlank(this.model) && !CWSTR.isBlank(this.model.node)) {
              this.model.node.childrens = this.model.coll;
              if (callback) {
                callback(this.model.coll, this.sonsColl);
              }
            }
          };

          this.model.coll.reset();
          this.clearSonsArray();
          _.each(this.model.firstLevelModels, (firstLevelModel: Backbone.Model) => {
            this.model.coll.add(firstLevelModel, { silent: true });
          });
          if (CWSTR.isBlank(this.model.coll.vue) || this.model.coll.vue === 3 || this.model.coll.vue === 4) {
            this.fillSubTree(null, lFuncSub3);
          } else {
            this.fillSubTreeRest(null, lFuncSub3);
          }
          fetchData = Promise.resolve() as unknown as JQueryXHR;
        } else { //When there is only one root
          if (this.model.node.get("typelt") === "A" && !CWSTR.isBlank(this.model.node.get("hierid"))) {
            this.model.coll.hierid = this.model.node.get("hierid");
          }
          this.model.coll.reset();
          this.clearSonsArray();
          fetchData = this.model.coll.fetch({
            success: (fresh: CWTreeNodeColl) => {
              let slFuncub4 = null;
              const existMoreNiv = _.find(fresh.models, (item: CWTreeNodeModel): boolean => {
                return item.get("feuille") === false;
              });

              if (this.model?.root && !_.isEmpty(existMoreNiv)) {
                this.model.root.trigger("syncAddCorrectNodeOrFirst");//afin de ne pas faire des pétition en double et pouvoir savoir lorsque l'on peut appeler à "_selectCorrectNodeOrFirst" dans "syncCorrectNodeOrFirst"
              }
              if (this.model && this.model.root) {
                this.model.root.trigger("updatedTreeNodeCollection", fresh, this.model.node);
              }
              slFuncub4 = (): void => {
                const lParentForNodeOrFirst = (this.view === 3) ? this._getParent(this) : null;//uniquement pour Structure simple

                this._sortSubTree();
                this._showArrows();
                this._showHideIcon();
                this.model.node.childrens = fresh;
                if (callback) {
                  callback(fresh, this.sonsColl);
                }
                if (lParentForNodeOrFirst && lParentForNodeOrFirst.parent && lParentForNodeOrFirst.parent.structSimpleFetchNodeOrFirst === false) {
                  if (this.model && this.model.root) {
                    this.model.root.trigger("syncCorrectNodeOrFirst");
                  }
                }
              };
              if (CWSTR.isBlank(this.model) || CWSTR.isBlank(this.model.coll.vue) || this.model.coll.vue === 3 || this.model.coll.vue === 4) {
                this.fillSubTree(null, slFuncub4);
              } else {
                this.fillSubTreeRest(null, slFuncub4);
              }
            }
          });
        }

      } else {
        const sublevel = this.model.get("level") + 1;

        this.$el.find("div.cw-level-" + sublevel).removeClass("cw-treenode-closed");
        this.model.node.childrens = this.model.coll;
        if (callback) {
          callback(this.model.coll, this.sonsColl);
        }
      }
    }
    this.model.set("expanded", true);
    if (!fictitiousRoot) {
      this._showHideIcon();
    }
    return fetchData;
  }

  /**
   * Collapse the Node.
   */
  _collapse(): void {
    const sublevel = this.model.get("level") + 1;

    // Accessibility parameters
    this.$el.find(".cw-treenode-row:first").attr("aria-expanded","false");

    this.$el.removeClass("cw-treenode-collapsable").addClass("cw-treenode-expandable");
    this.$el.find("div.cw-level-" + sublevel).addClass("cw-treenode-closed");
    this.model.set("expanded", false);
  }

  /**
   * Update the data recursive
   */
  _updateTreeNodeRecursive(callback: () => void): void {
    this._updateTreeNode(callback);
  }

  /**
   * Update the data of current node and his childs.
   */
  _updateTreeNode(callback?: () => void, aExpand?: boolean): void {
    let expand = aExpand;
    let fictitiousRoot = false;

    if (!_.isBoolean(expand)) {
      expand = true;
    }
    if (!CWSTR.isBlank(this.model.firstLevelNode) && this.model.firstLevelNode === true && this.model.firstLevelModels) {
      fictitiousRoot = true;
    }
    if (fictitiousRoot === true) {
      this.model.root.rootModel.firstLevelColl.fetch({
        success: (freshColl: CWActiviteColl) => {
          this.model.coll.reset();
          _.each(freshColl.models, (firstLevelModel) => {
            if (CWSTR.isBlank(firstLevelModel.get("code"))) { //Hors regroupement
              firstLevelModel.set("code", " ");
            }
            this.model.coll.add(firstLevelModel);
          });
          this.updateSubTree();
          this._sortSubTree();
          this._showArrows();
          if (this.model.get("hasChild") > 0 && expand) {
            this._expand(null);
          }
          if (callback) {
            callback();
          }
        }
      });
    } else {
      this.model.coll.fetch({
        success: () => {
          this.updateSubTree();
          this._sortSubTree();
          this._showArrows();
          if (this.model.get("hasChild") > 0 && expand) {
            this._expand(null);
          }
          if (callback) {
            callback();
          }
        }
      });
    }
  }

  /**
   * Update the data of the parent Node and his childs.
   */
  _updateParentTreeNode(): void {
    if (this.parent) {
      this.parent.model.coll.fetch({
        success: () => {
          this.updateSubTree();
          this._sortSubTree();
          this._showArrows();
          //If parent is blank, it is because it is the root of the tree so we don't have to
          //expand its parent
          if (!CWSTR.isBlank(this.parent) && this.parent.model.get("hasChild") > 0 && this.parent instanceof CWTree2NodeView) {
            this.parent._expand(null);
          }
        }
      });
    }
  }

  /* Listen the Click on Refresh Button.
   * Refresh the data of the clicked node and the subtrees.
   * @instance
   * @private
   * @listens TreeNodeView#"click .cw-treenode-wrap"
   * @param {external:JQuery.Event} event - click event.
   * @returns {Boolean}
   */
  _refreshClickedSubtree(event?: { [key: string]: any }): boolean {
    if (!CWSTR.isBlank(event)) { //It is the first time that we refresh the parent to update the current clicked node
      const target = event.currentTarget.className.split(" ")[0];
      const callback = (): void => {
        if (target === "cw-treenode-refresh") {
          this._refreshTreeNode();
        } else if (target === "cw-treenode-prev") {
          this._prevPagination();
        } else if (target === "cw-treenode-next") {
          this._nextPagination();
        }
        _.each(this.sonsColl, (node) => {
          node._refreshClickedSubtree();
        }, this);
      };

      //Refreshing parent
      this._refreshClickedNode(this.model.node.id, callback);
      //this._updateParentTreeNode();
    } else { //Refreshing current clicked node or its sons sons
      this._refreshTreeNode();
      _.each(this.sonsColl, (node) => {
        node._refreshClickedSubtree();
      }, this);
    }
    return false;
  }

  /* Listen Refresh trigger in node.
   * Refresh the data of the selected node and the subtrees.
   */
  _refreshSubtreeByTrigger(): void {
    const callback = (): void => {
      this._refreshTreeNode();
      _.each(this.sonsColl, (node) => {
        node._refreshClickedSubtree();
      }, this);
    };

    this._refreshClickedNode(this.model.node.id, callback);
  }

  /**
   * Refresh this Node and its sub tree.
   */
  _refreshTreeNode(): void {
    if (CWSTR.isBlank(this.model.node.get("feuille")) || (!CWSTR.isBlank(this.model.node.get("feuille")) && this.model.node.get("feuille") !== true)) {
      this.model.coll.fetch({
        success: () => {
          this.refreshSubTree();
          this._sortSubTree();
          this._showArrows();
        }
      });
    }
  }

  _prevPagination(): void {
    if (CWSTR.isBlank(this.model.node.get("feuille")) || (!CWSTR.isBlank(this.model.node.get("feuille")) && this.model.node.get("feuille") !== true)) {
      if (this.model.coll.paginated) {
        if (this.model.coll.pagination.startIndex >= (this.model.coll.pagination.size + 1)) {
          this.model.coll.pagination.startIndex -= this.model.coll.pagination.size;
        } else {
          this.model.coll.pagination.startIndex = 0;
        }
        this.model.coll.fetch({
          success: () => {
            this.refreshSubTree();
            this._sortSubTree();
            this._showArrows();
          }
        });
      }
    }
  }

  _nextPagination(): void {
    if (CWSTR.isBlank(this.model.node.get("feuille")) || (!CWSTR.isBlank(this.model.node.get("feuille")) && this.model.node.get("feuille") !== true)) {
      if (this.model.coll.paginated) {
        if ((this.model.coll.totalRecords - this.model.coll.pagination.startIndex) >= this.model.coll.pagination.size) {
          this.model.coll.pagination.startIndex += this.model.coll.pagination.size;
          this.model.coll.fetch({
            success: () => {
              this.refreshSubTree();
              this._sortSubTree();
              this._showArrows();
            }
          });
        }
      }
    }
  }

  /**
   * Refresh data for the Clicked Node.
   */
  _refreshClickedNode(idClickedNode: string, callback?: () => void): void {
    let fictitiousRoot = false;

    if (!CWSTR.isBlank(this.parent.model.firstLevelNode) && this.parent.model.firstLevelNode === true &&
      this.parent.model.firstLevelModels) {
      fictitiousRoot = true;
    }
    //We fetch parent in order to get updated info about clicked node only if it is not a fictitous node
    //Only if parent is not a fictitious root
    if (this.parent && CWSTR.isBlank(this.parent.model.firstLevelNode) && CWSTR.isBlank(this.parent.model.firstLevelModels)) {
      //this.parent.model.root.buildUrlColl.call(this, this.parent.model.get("level"), this.parent.model.node, this.parent.model.root.coll);
      this.parent.model.coll.fetch({
        success: () => {
          _.each(this.model.coll.models, (item) => {
            let found = false;
            let existingNodeIndex = null;

            if (!CWSTR.isBlank(this.sonsColl)) {
              for (let idx = 0; idx < this.sonsColl.length; idx++) {
                const son = this.sonsColl[idx];

                if (item.id === idClickedNode && item.id === son.model.node.id) {
                  found = true;
                  existingNodeIndex = idx;
                  break;
                }
              }
            }
            if (found === true) {
              const existingNode = this.sonsColl[existingNodeIndex];

              // update existing tree node
              if (existingNode) {
                let label = null;

                existingNode.model.node = item;
                //Added to maintain parents after refreshing subtree
                existingNode.model.node.parent = this.model.node;
                existingNode.model.node.set("parents", _.clone(this.model.node.get("parents")));
                if (CWSTR.isBlank(existingNode.model.node.get("parents"))) {
                  existingNode.model.node.set("parents", []);
                }
                existingNode.model.node.get("parents").push(this.model.node.attributes);
                //existingNode.render();
                existingNode._showHideIcon();
                //Update label of current Node
                label = existingNode.model.root.renderer.call(existingNode, existingNode.model.node);
                existingNode.model.set("label", label);
                //Paint Label
                $(existingNode.$el.find(".cw-treenode-label:first")).html(label);

                //show tooltips
                if (existingNode.tooltipRenderer) {
                  existingNode.tooltipRenderer(existingNode.model.node, existingNode);
                }
                //show tooltips
                if (existingNode.overLabelTooltipRenderer) {
                  existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
                }
              }
            }
          }, this);
          if (callback) {
            callback();
          }
        }
      });
    } else if (this.parent && this.parent instanceof CWTree2NodeView && this.parent._refreshClickedNodeSons && !fictitiousRoot) { //We have clicked in a first level that has a fictitious root
      //Refresh data for firstLevelColl
      this.model.root.rootModel.firstLevelColl.fetch({
        success: (freshColl: CWActiviteColl) => {
          this.model.coll.reset();
          _.each(freshColl.models, (firstLevelModel) => {
            if (CWSTR.isBlank(firstLevelModel.get("code"))) { //Hors regroupement
              firstLevelModel.set("code", " ");
            }
            this.model.coll.add(firstLevelModel);
          });
          this._refreshClickedNodeSons(idClickedNode, callback);
        }
      });
    } else { //We have clicked on a root and it is not fictitious
      if (callback) {
        callback();
      }
    }
  }

  /**
   * Refresh data of parent's sons of node that we want to be refreshed
   */
  _refreshClickedNodeSons(idClickedNode: string, callback?: () => void): void {
    _.each(this.model.coll.models, (item) => {
      let found = false;
      let existingNodeIndex = null;

      for (let idx = 0; idx < this.sonsColl.length; idx++) {
        const son = this.sonsColl[idx];

        if (item.id === idClickedNode && item.id === son.model.node.id) {
          found = true;
          existingNodeIndex = idx;
          break;
        }
      }
      if (found === true) {
        const existingNode = this.sonsColl[existingNodeIndex];

        // update existing tree node
        if (existingNode) {
          let label = null;

          existingNode.model.node = item;
          //Added to maintain parents after refreshing subtree
          existingNode.model.node.parent = this.model.node;
          existingNode.model.node.set("parents", _.clone(this.model.node.get("parents")));
          if (CWSTR.isBlank(existingNode.model.node.get("parents"))) {
            existingNode.model.node.set("parents", []);
          }
          existingNode.model.node.get("parents").push(this.model.node.attributes);
          //existingNode.render();
          existingNode._showHideIcon();
          //Update label of current Node
          label = existingNode.model.root.renderer.call(existingNode, existingNode.model.node);
          existingNode.model.set("label", label);
          //Paint Label
          $(existingNode.$el.find(".cw-treenode-label:first")).text(label);

          //show tooltips
          if (existingNode.tooltipRenderer) {
            existingNode.tooltipRenderer(existingNode.model.node, existingNode);
          }
          //show tooltips
          if (existingNode.overLabelTooltipRenderer) {
            existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
          }
        }
      }
    }, this);
    if (callback) {
      callback();
    }
  }

  /**
   * Create the subtree for the current node(mode recursive).
   */
  fillSubTree(collection: { [key: string]: any }, callback?: () => void): void {
    let lLon = 0;
    let lQuantProcess = 10;
    let lPart = 0;
    const lList: number[] = [];
    const lEstat: boolean[] = [];
    let lLast = 0;
    let lRest = 0;

    if (!CWSTR.isBlank(this.model)) {
      lLon = (!CWSTR.isBlank(collection)) ? collection.length : this.model.coll.models.length;
      this.model.set("hasChild", lLon);
    }
    lLast = (lLon === 0) ? 0 : (lLon - 1); //derniers process
    if (lLon > lQuantProcess) {
      lPart = lLast / lQuantProcess;
      lRest = lLon % lQuantProcess;
    } else {
      lQuantProcess = lLon;
      lPart = 1;
    }
    //Math.trunc doesn't work on IE11 and lower
    lPart = parseInt(String(lPart), 10);
    for (let j = 0; j < lQuantProcess; j++) {
      lList[j] = lPart * (j + 1);
      lEstat[j] = false;
    }
    if (lRest > 0 && lPart > 0) {
      const incr = parseInt(String(lRest / lPart)) + ((lRest % lPart > 0) ? 1 : 0);

      for (let j = lQuantProcess; j < lQuantProcess + incr; j++) {
        lList[j] = lPart * (j + 1);
        lEstat[j] = false;
      }
      lQuantProcess += incr;
    }
    for (let i = 0; i < lQuantProcess; i++) {
      this._runList(collection, lEstat, (i === 0 ? 0 : lList[i - 1]), lList[i], false, i, (aInd) => {
        lEstat[aInd] = true;
        this._runList(collection, lEstat, lList[lQuantProcess - 1], lLon, true, aInd, callback);
      });
    }
  }

  _runList(collection: { [key: string]: any }, aEstat: boolean[], aInd: any, aLon: any, aLast: any, aPosEstat: any, callback?: (ind?: number) => void): void {
    if (_.isBoolean(aLast) && aLast) {
      if (aEstat) {
        let lbFin = true;
        const lLon2 = aEstat.length;

        for (let i = 0; i < lLon2; i++) {
          lbFin = lbFin && aEstat[i];
        }
        if (lbFin) {
          if (aInd < aLon) {
            this._fillPartial(collection, aEstat, aInd, aLon, aLast, aPosEstat, callback);
          } else {
            if (callback) {
              callback();
            }
          }
        }
        //rien dans un autre cas
      }
    } else {
      if (aInd < aLon) {
        this._fillPartial(collection, aEstat, aInd, aLon, aLast, aPosEstat, callback);
      } else {
        if (callback) {
          callback(aPosEstat);
        }
      }
    }
  }

  _fillPartial(collection: { [key: string]: any }, aEstat: boolean[], aPos: any, alon: any, alast: any, aposEstat: any, callback?: (ind?: number) => void): void {
    let item = null;
    const lInd = aPos + 1;
    let selectionableNode = true;

    if (CWSTR.isBlank(collection) && aPos >= 0 && aPos < alon && !CWSTR.isBlank(this.model) && !CWSTR.isBlank(this.model.coll) && this.model.coll.length > 0 && aPos < this.model.coll.length) {
      let treeNode: CWTree2NodeView = null;

      item = this.model.coll.at(aPos);
      item.parent = this.model.node;
      if (!_.isEmpty(item.parent) && (this.view === CWActiviteTreesViewType.STRUCTURESIMPLE || this.view === CWActiviteTreesViewType.STRUCTUREDETAIL) && CWSTR.isBlank(item.parent.get("itemida")) && CWSTR.isBlank(item.parent.get("structida"))) {
        const dernierParent = _.last(this.model.node.get("parents"));//le dernier valeur de parents aura les valeurs correctes, s'il y a !, de itemida et structida 

        //si le parent d'une activité n'a pas les données correctes de "itemida" et/ou "structida", dans les vérifications ou l'action d'enregistrer ne sera pas correcte
        if (!_.isEmpty(dernierParent) && (!CWSTR.isBlank(dernierParent.itemida) || !CWSTR.isBlank(dernierParent.structida))) {
          item.parent.set("itemida", dernierParent.itemida);
          item.parent.set("structida", dernierParent.structida);
        }
      }
      item.set("parents", _.clone(this.model.node.get("parents")));
      if (CWSTR.isBlank(item.get("parents"))) {
        item.set("parents", []);
      }
      item.get("parents").push(this.model.node.attributes);
      if (this.model.selectableNode) {
        selectionableNode = this.model.selectableNode.call(item, item, item.parent);
      }
      treeNode = new CWTree2NodeView({
        root: this.model.root,
        parent: this,
        node: item,
        level: this.model.get("level") + 1,
        showSelected: this.showSelected,
        selectableNode: selectionableNode,
        multiselect: this.multiselect,
        isCheckedCallback: this.isCheckedCallback,
        hideCheck: this.hideCheck,
        readOnly: this.readOnly,
        hieractivitidad: this.hieractivitidad,
        context: this.context,
        view: this.view,
      });
      treeNode.setSortFunction(this.sortTree);
      this.sonsColl.push(treeNode);
      treeNode.render(() => {
        if (!CWSTR.isBlank(this.$el)) {
          this.$el.append(treeNode.el);
        }
        this._runList(collection, aEstat, lInd, alon, alast, aposEstat, callback);
      });
      // expands treenode not in filter case
      if (this.expandedNodes && this.opennode !== true) {
        treeNode._expandAllNodes(treeNode);
      }
    } else if (!CWSTR.isBlank(collection) && aPos >= 0 && aPos < alon && collection.models && collection.models.length > 0) {
      let node: CWTree2NodeView = null;

      for (let i = aPos; i < alon && i < collection.length; i++) {
        item = collection.at(i);
        item.set("parents", _.clone(this.model.node.get("parents")));
        if (CWSTR.isBlank(item.get("parents"))) {
          item.set("parents", []);
        }
        item.get("parents").push(this.model.node.attributes);
        item.parent = this.model.node;
        if (this.model.selectableNode) {
          selectionableNode = this.model.selectableNode.call(item, item.parent);
        }
        node = new CWTree2NodeView({
          root: this.model.root,
          parent: this,
          node: item,
          level: this.model.get("level") + 1,
          showSelected: this.showSelected,
          selectableNode: selectionableNode,
          multiselect: this.multiselect,
          isCheckedCallback: this.isCheckedCallback,
          hideCheck: this.hideCheck,
          readOnly: this.readOnly,
          hieractivitidad: this.hieractivitidad,
          context: this.context,
          view: this.view,
        });
        node.setSortFunction(this.sortTree);
        this.sonsColl.push(node);
        node.render();
        node.fillSubTree(item.get("enfants"), callback);
        this.model.set("expanded", true);
        this.$el.removeClass("cw-treenode-expandable").addClass("cw-treenode-collapsable");
        this.$el.append(node.el);
        // expands treenode not in filter case
        if (this.expandedNodes && this.opennode !== true) {
          node._expandAllNodes(node);
        }
      }
    }
  }

  /**
   * Create the subtree for the current node(mode itérative).
   */
  fillSubTreeRest(collection: CWTreeNodeColl, callback?: () => void): void {
    this.model.set("hasChild", this.model.coll.models.length);
    if (CWSTR.isBlank(collection)) {
      if (this.model && this.model.coll && this.model.coll.models && this.model.coll.models.length > 0) {
        _.each(this.model.coll.models, (item) => {
          let selectionableNode = true;
          let treeNode: CWTree2NodeView = null;

          item.parent = this.model.node;
          item.set("parents", _.clone(this.model.node.get("parents")));
          if (CWSTR.isBlank(item.get("parents"))) {
            item.set("parents", []);
          }
          item.get("parents").push(this.model.node.attributes);
          if (this.model.selectableNode) {
            selectionableNode = this.model.selectableNode.call(item, item, item.parent);
          }
          treeNode = new CWTree2NodeView({
            root: this.model.root,
            node: item,
            level: this.model.get("level") + 1,
            showSelected: this.showSelected,
            selectableNode: selectionableNode,
            multiselect: this.multiselect,
            parent: this,
            isCheckedCallback: this.isCheckedCallback,
            hideCheck: this.hideCheck,
            readOnly: this.readOnly,
            hieractivitidad: this.hieractivitidad,
            context: this.context,
            view: this.view,
            expandedNodes: this.expandedNodes
          });
          treeNode.setSortFunction(this.sortTree);
          this.sonsColl.push(treeNode);
          this.$el.append(treeNode.render().el);
          // expands treenode not in filter case
          if (this.expandedNodes && this.opennode !== true) {
            treeNode._expandAllNodes(treeNode);
          }
        }, this);
      }
    } else {
      if (collection && collection.models && collection.models.length > 0) {
        _.each(collection.models, (item) => {
          let selectionableNode = true;
          let node: CWTree2NodeView = null;

          item.set("parents", _.clone(this.model.node.get("parents")));
          if (CWSTR.isBlank(item.get("parents"))) {
            item.set("parents", []);
          }
          item.get("parents").push(this.model.node.attributes);
          item.parent = this.model.node;
          if (this.model.selectableNode) {
            selectionableNode = this.model.selectableNode.call(item, item.parent);
          }
          node = new CWTree2NodeView({
            root: this.model.root,
            parent: this,
            node: item,
            level: this.model.get("level") + 1,
            showSelected: this.showSelected,
            selectableNode: selectionableNode,
            multiselect: this.multiselect,
            isCheckedCallback: this.isCheckedCallback,
            hideCheck: this.hideCheck,
            readOnly: this.readOnly,
            hieractivitidad: this.hieractivitidad,
            context: this.context,
            view: this.view,
          });
          node.setSortFunction(this.sortTree);
          this.sonsColl.push(node);
          node.render();
          node.fillSubTreeRest(item.get("enfants"), callback);
          this.model.set("expanded", true);
          this.$el.removeClass("cw-treenode-expandable").addClass("cw-treenode-collapsable");
          this.$el.append(node.el);
          // expands treenode not in filter case
          if (this.expandedNodes && this.opennode !== true) {
            node._expandAllNodes(node);
          }
        }, this);
      }
    }
    if (typeof callback === "function") {
      let continueAction = (this.view !== CWActiviteTreesViewType.HIERARCHIE);

      if (!continueAction) {//L'arbre a le type hierarchie
        const lParentForNodeOrFirst = this._getParent(this);

        continueAction = (lParentForNodeOrFirst.parent.hierarchieFetchNodeOrFirst === true);
      }
      if (continueAction) {
        callback();
      }
    }
  }

  /**
   * Updates the subtree information.
   */
  updateSubTree(): void {
    let feuille = false;

    this.model.set("hasChild", this.model.coll.models.length);
    _.each(this.model.coll.models, (item) => {
      let found = false;
      let existingNodeIndex = null;

      for (let idx = 0; idx < this.sonsColl.length; idx++) {
        const son = this.sonsColl[idx];

        if (item.id === son.model.node.id) {
          found = true;
          existingNodeIndex = idx;
          break;
        }
      }
      if (found === true) {
        const existingNode = this.sonsColl[existingNodeIndex];

        // update existing tree node
        if (existingNode) {
          existingNode.model.node = item;
          this._addListenersToNode(existingNode);
          //Added to maintain parents after updating subtree
          existingNode.model.node.parent = this.model.node;
          existingNode.model.node.set("parents", _.clone(this.model.node.get("parents")));
          if (CWSTR.isBlank(existingNode.model.node.get("parents"))) {
            existingNode.model.node.set("parents", []);
          }
          existingNode.model.node.get("parents").push(this.model.node.attributes);
          //existingNode.render();
          existingNode._showHideIcon();
          //show tooltips
          if (existingNode.tooltipRenderer) {
            existingNode.tooltipRenderer(existingNode.model.node, existingNode);
          }
          //show tooltips
          if (existingNode.overLabelTooltipRenderer) {
            existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
          }
        }
      } else {
        let selectionableNode = true;
        let treeNode: CWTree2NodeView = null;

        // create new node to add it to the tree
        item.parent = this.model.node;
        item.set("parents", _.clone(this.model.node.get("parents")));
        if (CWSTR.isBlank(item.get("parents"))) {
          item.set("parents", []);
        }
        item.get("parents").push(this.model.node.attributes);
        if (this.model.selectableNode) {
          selectionableNode = this.model.selectableNode.call(item, item, item.parent);
        }
        treeNode = new CWTree2NodeView({
          root: this.model.root,
          parent: this,
          node: item,
          level: this.model.get("level") + 1,
          showSelected: this.showSelected,
          selectableNode: selectionableNode,
          multiselect: this.multiselect,
          hideCheck: this.hideCheck,
          readOnly: this.readOnly,
          hieractivitidad: this.hieractivitidad,
          context: this.context,
          view: this.view,
        });
        treeNode.setSortFunction(this.sortTree);
        this.sonsColl.push(treeNode);
        this.$el.append(treeNode.render().el);
      }
    });

    // remove nodes
    _.each(_.compact(this.sonsColl), (son, index) => {
      let found = false;

      for (let idx = 0; idx < this.model.coll.models.length; idx++) {
        const item = this.model.coll.models[idx];

        // when the model values are empty we delete the unused row.
        if (!CWSTR.isBlank(son.model) && item.id === son.model.node.id) {
          found = true;
          break;
        }
      }
      if (found === false) {
        const deleteNode = this.sonsColl[index];

        if (deleteNode) {
          deleteNode.remove();
          this.sonsColl.splice(index, 1);
        }
      }
    });
    //Show or not the expand icon
    feuille = (this.sonsColl.length === 0);
    if (feuille === true && this.model.get("level") !== 0) {
      this.$el.find(".cw-treenode-icon").first().hide();
      this.$el.find(".cw-treenode-leaf-icon").first().show();
    } else {
      this.$el.find(".cw-treenode-icon").first().show();
      this.$el.find(".cw-treenode-leaf-icon").first().hide();
    }
    this.repaintLabel(this.model.root.renderer);
  }

  /**
   * Refresh the subtree Information.
   */
  refreshSubTree(): void {
    if (!CWSTR.isBlank(this.model)) { //When the node has been deleted and no longer exists, we don't do anything
      let feuille = false;

      //If node has not been deleted we make the treatment
      _.each(this.model.coll.models, (item) => {
        let found = false;
        let existingNodeIndex = null;

        for (let idx = 0; idx < this.sonsColl.length; idx++) {
          const son = this.sonsColl[idx];

          if (item.id === son.model.node.id) {
            found = true;
            existingNodeIndex = idx;
            break;
          }
        }
        if (found === true) {
          const existingNode = this.sonsColl[existingNodeIndex];

          // update existing tree node
          if (existingNode) {
            existingNode.model.node = item;
            //Added to maintain parents after refreshing subtree
            existingNode.model.node.parent = this.model.node;
            existingNode.model.node.set("parents", _.clone(this.model.node.get("parents")));
            if (CWSTR.isBlank(existingNode.model.node.get("parents"))) {
              existingNode.model.node.set("parents", []);
            }
            existingNode.model.node.get("parents").push(this.model.node.attributes);
            //existingNode.render();
            existingNode._showHideIcon();
            //show tooltips
            if (existingNode.tooltipRenderer) {
              existingNode.tooltipRenderer(existingNode.model.node, existingNode);
            }
            //show tooltips
            if (existingNode.overLabelTooltipRenderer) {
              existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
            }
          }
        } else if (!CWSTR.isBlank(this.model.get("expanded")) && this.model.get("expanded") === true) {
          let selectionableNode = true;
          let treeNode: CWTree2NodeView = null;

          // create new node to add it to the tree
          item.parent = this.model.node;
          item.set("parents", _.clone(this.model.node.get("parents")));
          if (!item.get("parents")) {
            item.set("parents", []);
          }
          item.get("parents").push(this.model.node.attributes);
          if (this.model.selectableNode) {
            selectionableNode = this.model.selectableNode.call(item, item, item.parent);
          }
          treeNode = new CWTree2NodeView({
            root: this.model.root,
            parent: this,
            node: item,
            level: this.model.get("level") + 1,
            showSelected: this.showSelected,
            selectableNode: selectionableNode,
            multiselect: this.multiselect,
            hideCheck: this.hideCheck,
            readOnly: this.readOnly,
            hieractivitidad: this.hieractivitidad,
            context: this.context,
            view: this.view,
          });
          treeNode.setSortFunction(this.sortTree);
          this.sonsColl.push(treeNode);
          this.$el.append(treeNode.render().el);
        }
      });
      // remove nodes
      if (!CWSTR.isBlank(this.model.get("expanded")) && this.model.get("expanded") === true) {
        for (let index = this.sonsColl.length - 1; index >= 0; index--) {
          let found = false;
          const son = this.sonsColl[index];

          for (let idx = 0; idx < this.model.coll.models.length; idx++) {
            if (!CWSTR.isBlank(son)) {
              const item = this.model.coll.models[idx];

              // when the model values are empty we delete the unused row.
              if (!CWSTR.isBlank(son.model) && item.id === son.model.node.id) {
                found = true;
                break;
              }
              if (item.id === son.model.node.id) {
                found = true;
                break;
              }
            }
          }
          if (found === false) {
            const deleteNode = this.sonsColl[index];

            if (deleteNode) {
              deleteNode.remove();
              this.sonsColl.splice(index, 1);
            }
          }
        }
      }
      //Show or not the expand icon
      feuille = (this.model.coll.models.length === 0);
      if (feuille === true) {
        this.$el.find(".cw-treenode-icon").first().hide();
        this.$el.find(".cw-treenode-leaf-icon").first().show();
      } else {
        this.$el.find(".cw-treenode-icon").first().show();
        this.$el.find(".cw-treenode-leaf-icon").first().hide();
      }
      this.repaintLabel(this.model.root.renderer);
    }
  }

  /**
   * Setter for the sortTree function
   */
  setSortFunction(func: (a: any, b: any) => number): void {
    this.sortTree = func;
  }

  /**
   * Sort the SubTree with the sorting function "sortTree"
   */
  _sortSubTree(): void {
    // sort subtree
    this.sonsColl.sort(this.sortTree);

    // Accessibility parameters
    const submenu = $("<div>").addClass("cw-submenu-group").attr("role","group");

    // repaint subtree
    _.each(this.sonsColl, (item: Backbone.View) => {
      submenu.append(item.$el.detach());
    });

    this.$el.append(submenu);
  }

  _selectNode(): void {
    let container: JQuery = null;

    this.$el.find(".cw-treenode-label:first").trigger("click");
    container = $("#" + this.cid).parents(".activite-overflow");
    if (container.length > 0) {
      let diff = 0;

      container.scrollTop(0);
      diff = $("#" + this.cid).offset().top - container.offset().top;
      //-8px for separate the element to the top margin.
      container.scrollTop(diff - 8);
    }
  }

  /**
   * Marks element as selected without changing data model about selection element (because it is already selected in mode simple input
   * and we are only remarking the selection class)
   */
  _markCLassSelectedNode(): void {
    let container: JQuery = null;

    if (!this.$el.find(".cw-treenode-label").first().hasClass("cw-node-not-selectionnable")) { //When this node can be selected
      if (this.showSelected) {
        this.$el.find("span.cw-treenode-label").first().addClass("ui-state-active");
        this.$el.find("span.cw-treenode-label").first().addClass("cw-treeNode-lastNodeSelected");
      }
    }
    container = $("#" + this.cid).parents(".activite-overflow");
    if (container.length > 0) {
      let diff = 0;

      container.scrollTop(0);
      diff = $("#" + this.cid).offset().top - container.offset().top;
      //-8px for separate the element to the top margin.
      container.scrollTop(diff - 8);
    }
  }

  _addListenersToNode(existingNode?: { [key: string]: any }): void {
    if (!CWSTR.isBlank(existingNode)) {
      const node = existingNode.model.node;

      node.off("expandNode");
      node.off("selectNode");
      node.off("markCLassSelectedNode");
      node.off("refresh");
      existingNode.listenTo(node, "expandNode", this._expand);
      existingNode.listenTo(node, "selectNode", this._selectNode);
      existingNode.listenTo(node, "markCLassSelectedNode", this._markCLassSelectedNode);
      existingNode.listenTo(node, "refresh", this._refreshSubtreeByTrigger);
    } else {
      this.model.node.off("expandNode");
      this.model.node.off("selectNode");
      this.model.node.off("markCLassSelectedNode");
      this.model.node.off("refresh");
      this.listenTo(this.model.node, "expandNode", this._expand);
      this.listenTo(this.model.node, "selectNode", this._selectNode);
      this.listenTo(this.model.node, "markCLassSelectedNode", this._markCLassSelectedNode);
      this.listenTo(this.model.node, "refresh", this._refreshSubtreeByTrigger);
    }
  }

  _checkAnotherSon(checkedColl: Backbone.Collection, codeParent: number | string): boolean {
    let lExists = false;

    if (!_.isEmpty(checkedColl) && !CWSTR.isEmpty(codeParent)) {
      let lCont = 0;

      for (let i = 0; i < checkedColl.length && !lExists; i++) {
        const lNode = (checkedColl.models[i] as CWTreeNodeModel).node;

        if (lNode && lNode.parent && !CWSTR.isBlank(lNode.parent.get("code")) && String(lNode.parent.get("code")) === String(codeParent)) {
          lCont++;
        }
        if (lCont > 1) {//parce que cette verification se fait avant de le supprimer de checkedcoll, c'est-à-dire, comme minimum lCont sera 1
          lExists = true;
        }
      }
    }
    return lExists;
  }

  _getParent(node: CWTree2NodeView): CWTree2NodeView {
    let rtn = node;

    if (node && node.parent) {
      if (node.parent instanceof CWTree2View) {
        rtn = node;
      } else {
        rtn = this._getParent(node.parent);
      }
    }
    return rtn;
  }

  _checkAnotherSonHorsStructure(checkedColl: Backbone.Collection, codeParent: number | string): boolean {
    let lExists = false;

    if (!_.isEmpty(checkedColl)) {
      let lCont = 0;

      for (let i = 0; i < checkedColl.length && !lExists; i++) {
        const lNode = (checkedColl.models[i] as CWTreeNodeModel).node;

        if (lNode && lNode.parent && lNode.parent.get("code") === " " && lNode.parent.get("code") === codeParent) {
          lCont++;
        }
        if (lCont > 1) {//parce que cette verification se fait avant de le supprimer de checkedcoll, c'est-à-dire, comme minimum lCont sera 1
          lExists = true;
        }
      }
    }
    return lExists;
  }

  private getFirstLevelModel(view: CWTree2NodeView): CWTree2NodeView {
    let rtn: CWTree2NodeView = null;

    if (view && view.model) {
      if (view.model.get("level") === 1) {
        rtn = view;
      } else if (!CWSTR.isBlank(view.model.get("level"))) {
        rtn = this.getFirstLevelModel(view.parent);
      }
    }
    return rtn;
  }
}
