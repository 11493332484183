import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererabsencesouhaitPopUpDeleguer from '../cwPopUpDeleguer.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWGererAbsenceSouhaitView } from './cwGererAbsenceSouhait.view';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


export class CWGererAbsenceSouhaitDeleguerPopUpView extends CWBaseFormView {

  multipleAbsence: boolean;
  public parent: CWGererAbsenceSouhaitView | any;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click button": "_clickListener"
    });
    super(options);
    this.multipleAbsence = false;
    this.template = TPLGererabsencesouhaitPopUpDeleguer;
    this.parent = options.parent;
    this.multipleAbsence = options.multipleAbsence;
  }

  render(): CWGererAbsenceSouhaitDeleguerPopUpView {
    const json: { [key: string]: any } = { "i18n": i18n, "UTILS": UTILS };

    this.$el.append(this.template(json));
    if (this.multipleAbsence === true) {
      this.$el.find(".gererabsencesouhait-deleguer-title").html(i18n.t('messages:GT_1976'));
    } else {
      this.$el.find(".gererabsencesouhait-deleguer-title").html(i18n.t('messages:GT_2002'));
    }
    // Render buttons
    /*    this.$el.find(".btnDeleguer").button();
        this.$el.find(".btnAnnuler").button();*/

    return this;
  }

  _clickListener(event: JQueryEventObject | any): void {
    const btnClicked = event.currentTarget.value;

    switch (btnClicked) {
      case "btnDeleguer":
        this.parent.model.set("action", "deleguer");
        this.parent.close();
        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
        break;
    }
  }
}
