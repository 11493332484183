module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'c-wkfhisto__line '+
((__t=( index ))==null?'':__t)+
' '+
((__t=( customClass ))==null?'':__t)+
' cw-texteAllege\'>\n  '+
((__t=( line ))==null?'':__t)+
'\n</div>\n';
}
return __p;
};
