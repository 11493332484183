import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * dabsences Model
 */
export class CWModeRepresentationModel extends CWBaseModel {
  code: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/element/construction/" + encodeURIComponent(this.code);
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "representation": null
    }
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.code !== undefined) {
      response.id = response.code;
    }

    return response;
  }
}
