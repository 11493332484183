import * as _ from 'underscore';
import * as Backbone from 'Backbone';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWEvtBadgeageSoumisWkf } from '../models/cwEvtBadgeageSoumisWkf';
import { CWGererBadgeagesPopUpView } from '../views/cwGererBadgeagesPopUp.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMSGS } from 'utils/cwMsgs';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';

export class CWGererbadgeagesWorkflow extends CWReadOnlyModel {

  gererBadgeages: any;
  paramIdCollab: any;
  paramProvenanceBadgeage: any;
  parametreDiversProvenanceBadgeage: Backbone.Model;
  parametreDiversIdCollabModel: Backbone.Model;
  module: string;
  $appendTo: JQuery<HTMLElement>;
  table: any;
  workflow: any;
  tableModel: any;
  wkf_activ: boolean;

  /**
   * Constructor
   * Workflow model for component gerer badgeages (various together)
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.paramIdCollab = null;
    this.paramProvenanceBadgeage = null;
    this.parametreDiversProvenanceBadgeage = GLOBAL_DATA.paramDivers.get("badg_prov");
    this.parametreDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.module = "gererbadgeages";
    if (options.module) {
      this.module = options.module;
    }
    /**
     * Model of the habilitation context
     */
    this.habContext = new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_BADG.V"
    });
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
  }

  /**
   * Set up the workflow. Link the models between them
   */
  setUp(callback: () => void): void {
    const evtBadgeageSoumisWkf = new CWEvtBadgeageSoumisWkf();
    this["wkf_activ"] = false;

    this.gererBadgeages.paramIdCollab = this.parametreDiversIdCollabModel.get("valeur");
    this.gererBadgeages.paramProvenanceBadgeage = this.parametreDiversProvenanceBadgeage.get("valeur");

    evtBadgeageSoumisWkf.setHabContext(new CWHabilitationContext({
      onglet: "gererbadgeages",
      foncCour: "PAR_WKFASSC.V",
      natGest: ""
    }));
    if (this.gererBadgeages && !CWSTR.isBlank(this.gererBadgeages.context)) {
      const lContext = this.gererBadgeages.context;

      if (!CWSTR.isBlank(lContext.ctxEcran) && !CWSTR.isBlank(lContext.ctxHabilitation.HabilitationAcces)) {
        const lHabContext = new CWHabilitationContext({
          onglet: lContext.ctxEcran,
          foncCour: lContext.ctxHabilitation.HabilitationAcces
        });
        evtBadgeageSoumisWkf.setHabContext(lHabContext);
      }
    }
    // obtain badgeages workflow circuits value
    if (CWHABILITATION.canView(evtBadgeageSoumisWkf.habContext.get("foncCour"))) {
      evtBadgeageSoumisWkf.fetch({
        success: (fresh: { [key: string]: any }) => {
          this["wkf_activ"] = fresh.get("0").active;
          if (callback) {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  _confirmDelete(): void {
    const objModel = this.table.model.get("value");
    const statut = objModel.get("statut").code;

    if (statut === "A" || statut === "H" || (statut === "I" && this.gererBadgeages.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
      const dialog = this._initPopUp("suppression", this.gererBadgeages);
      const dialogModel = dialog.model;

      dialog.open(() => {
        if (dialogModel.get("action") === "OK") {
          this.gererBadgeages.deleteBadgeage(dialogModel.get("commentaire"));
        }
      });
      dialog._setTitle(i18n.t('common:gererbadgeages.title_popUp_confirm'));
    } else {
      CWMSGS.showConfirmAdapter(i18n.t('common:gererbadgeages.delconfirm'), (result: string) => {
        if (result === "Y") {
          this.gererBadgeages.deleteBadgeage("");
        }
      }, null, null, null, null /*l'action de delete était dans un volet et appendTo doit être vîde*/);
    }
  }

  /**
   * Custom action that pressing buttons on the buttonbar
   */
  _customBtnAction(buttonId: string, callback: () => void): void {
    const buttonName = buttonId.split("_")[0];

    //Intercept the save action and ignore the others
    //this table has a button menu when each item has the struct accepter_ or refuser_
    switch (buttonName) {
      case "new":
        this.workflow.gererBadgeages.newBadgeage();
        break;
      case "revert":
        this.workflow.gererBadgeages.revertBadgeage(callback);
        break;
      case "save": {
        const objModel = this.table.model.get("value");
        const context = this.context;
        let error = false;

        if (!CWSTR.isBlank(context.ctxGestionPeriode)) {
          if (context.ctxGestionPeriode.datedebut < objModel.get("saisie").date && context.ctxGestionPeriode.datefin > objModel.get("saisie").date) {
            if (context.ctxGestionPeriode.heuredebut < objModel.get("saisie").heure &&
              context.ctxGestionPeriode.heurefin > objModel.get("saisie").heure) {
              error = false;
            } else {
              error = true;
            }
          } else {
            error = true;
          }
        }
        if (error) {
          const txtError = i18n.t('messages:GT_1142', {
            "1": context.ctxGestionPeriode.datedebut,
            "2": context.ctxGestionPeriode.heuredebut,
            "3": context.ctxGestionPeriode.datefin,
            "4": context.ctxGestionPeriode.heurefin
          });

          CWMSGS.showError(txtError, null, this.$appendTo);
        } else {
          if (objModel.isNew()) {
            this.workflow.gererBadgeages.saveBadgeage();
          } else {
            if (objModel.get("saisie") && objModel.get("saisie").sens && (objModel.get("saisie").sens === "X" || (objModel.get("saisie").sens.code && objModel.get("saisie").sens.code === "X"))) {
              this.workflow._confirmDelete();
            } else {
              this.workflow.gererBadgeages.modifiableBadgeage((fresh: { [key: string]: any }) => {
                if (fresh.get("result") === "ok") {
                  this.workflow.gererBadgeages.saveBadgeage();
                }
              });
            }
          }
        }
        break;
      }
      case "delete":
        this.workflow._confirmDelete();
        break;
      case "accepter":
      case "refuser": {
        const role = buttonId.slice(buttonId.indexOf("_") + 1, buttonId.length);
        const dialog = this.workflow._initPopUp(buttonName, this);
        const dialogModel = dialog.model;

        // hide validate button options
        if (this.workflow.table.buttonBar.$el.find(".valider-menu-content")) {
          const validerMenu = this.workflow.table.buttonBar.$el.find(".valider-menu-content");

          if (validerMenu) {
            validerMenu.toggle();
          }
        }
        dialog.open(() => {
          if (dialogModel.get("action") === "OK") {
            if (buttonName === "accepter") {
              this.workflow.gererBadgeages.accepterBadgeage(dialogModel.get("commentaire"), role);
            } else if (buttonName === "refuser") {
              this.workflow.gererBadgeages.refuserBadgeage(dialogModel.get("commentaire"), role);
            }
          }
        });
        dialog._setTitle(i18n.t('common:gererbadgeages.title_popUp_confirm'));
        break;
      }
      case "copy":
        this.workflow.gererBadgeages.copyBadgeage();
        break;
      default:
        this.workflow.tableModel.buttonAction(buttonId);
    }
  }

  /**
   * Initialize the popup to add commentary in 'delete', 'accepter' or 'refuser'
   */
  _initPopUp(act: string, obj: { [key: string]: any }): CWDialogPopupView {
    const stat = this.tableModel.get("value").get("statut").code;
    const ctxUtilisateur = obj.context.ctxUtilisateur;
    const dialog = new CWDialogPopupView({
      view: CWGererBadgeagesPopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur,
        appendTo: null ///on ne doit pas utiliser 'this.$appendTo' parce qu'il sera appelé depuis le volet (sans zone)
      }
    });

    dialog.setHeight("auto");
    dialog.setWidth(470);
    return dialog;
  }
}
