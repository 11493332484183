import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model to submit a recapdelete an activity
 */
export class CWDeleteActivityModel extends CWBaseModel {
  code: string;
  /**
   * Add this to have Outline
   *
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.code = "";
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/activite/element";
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "commentaire": "",
      "droit": ""
    }
  }
}
