import * as Backbone from 'Backbone';
import _ from 'underscore';
import tableauGardesDialogPersoSavecriteriaTPL from '../dialog_perso_saveTableauGardesCriteria.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWSauveGarderPreferenceModel as CWSauveGarderTabGardesPreference } from '../models/cwSauveGarderTabGardesPreference.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';


interface CWPersoSaveCriteriaDialogOptions extends Backbone.ViewOptions<CWBaseModel> {
  workflow?: any;
  parent: any;
}


export class CWPersoSaveCriteriaDialog extends CWBaseFormView {

  perscriu: number;
  perscrig: number;
  workflow: any;
  parent: any;
  options: string;
  level: string;


  constructor(options?: CWPersoSaveCriteriaDialogOptions) {
    options.events = {
      "click .options .bloq_user": "_checkButtonDelete",
      "click .options .bloq_allusers": "_checkButtonDelete",
      "change .options .bloq_user": "_changeCritere",
      "change .options .bloq_allusers": "_changeCritere"
    };
    super(options);
    this.template = tableauGardesDialogPersoSavecriteriaTPL;
    this.perscriu = 1;
    this.perscrig = 0;
    if (options.workflow) {
      this.workflow = options.workflow;
    }
    if (options.parent) {
      this.parent = options.parent;
      //set the title to the dialog
      this.parent._setTitle(i18n.t('tabgardes.dialog_persosavecriteria_title'));
    }
    this.options = "noperso";
    this.listenTo(this, "_saveCriteria", this._saveCriteria);
    this.listenTo(this, "_cancel", this._cancel);
    //initialisation de "prescrig" et "prescriu" s'ils n'ont pas de valeur
    if (CWSTR.isBlank(this.workflow.preferences.get("perscrig"))) {
      this.workflow.preferences.set("perscrig", 0, { silent: true });
    }
    if (CWSTR.isBlank(this.workflow.preferences.get("perscriu"))) {
      this.workflow.preferences.set("perscriu", 1, { silent: true });
    }
  }

  _changeCritere(event: JQueryEventObject): void {
    const target = event.currentTarget;
    const buttonId = target.className.split(" ")[0];
    let isDisabled = true;

    // Le traitement attendu est similaire à celui effectué pour l’option « Enregistrer les éléments courants »
    // des « critères avancés », sans tenir compte du critère courant, enregistrement de tous les éléments
    // courants du planning (population, période, présentation des collaborateurs, etc…)

    this.options = "genericData"; // save only generic data.
    if (buttonId === "bloq_user") {
      this.$el.find('.aff_allusers').removeAttr('checked');
      this.$el.find('.aff_user').attr('checked', 'true');
      this.level = "privateUser";
    } else {
      this.$el.find('.aff_user').removeAttr('checked');
      this.$el.find('.aff_allusers').attr('checked', 'true');
      this.level = "allUsers";
    }
    if (this.$el.find(".aff_user").is(":checked") ||
      this.$el.find(".aff_allusers").is(":checked")) {
      isDisabled = false;
    }
    this.$el.parent().find(".cwDialog-buttons .bt-col-blue").prop({
      "disabled": isDisabled
    });
  }

  _saveCriteria(): void {
    let persoPlanning: any = null;
    let context: any = {};

    if (this.$el.find(".bloq_allusers input").is(":not(:checked)") && this.$el.find(".bloq_user input").is(":checked")) {
      this.level = "privateUser";
    } else if (this.$el.find(".bloq_allusers input").is(":checked") && this.$el.find(".bloq_user input").is(":not(:checked)")) {
      this.level = "allUsers";
    }

    switch (this.level) {
      case "privateUser": // « U » : personnalisation au niveau « Utilisateur »
        //for user in private mode
        this.perscriu = 1;
        break;
      case "connectedUser": // « U » : personnalisation au niveau « Utilisateur »
        // to the connected user
        this.perscriu = 1;
        break;
      case "allUsers": // « G » : personnalisation au niveau « Général »
        // for all users (public)
        this.perscriu = 0;
        this.perscrig = 1;
        break;
    }

    persoPlanning = new CWSauveGarderTabGardesPreference();
    context = {
      onglet: "tabgardes",
      foncCour: "COL_TGARDE",
      natGest: ""
    }
    // cloned preferences
    persoPlanning.set("preferences", this.workflow.preferences.get("preferences").clone());
    persoPlanning.set("perscriu", this.perscriu);
    persoPlanning.set("perscrig", this.perscrig);
    persoPlanning.setHabContext(new CWHabilitationContext(context));
    if (!persoPlanning.isValid()) {
      this._showValidationErrors(persoPlanning, persoPlanning.validationError);
      // add validation errors.
      CWLOG.debug("preferences are not valid.")
    } else {
      const lUser = (this.perscrig === 1) ? null : parseInt(objs.appRt.workflow.authModel.get("user"), 10);

      this._cleanValidationErrors();
      UTILS.showUsecaseHidder("tabgardes");
      persoPlanning.set("user", lUser, { silent: true });
      persoPlanning.save(null, {
        success: (fresh: any) => {
          if (!_.isEmpty(fresh)) {
            CWLOG.debug("Saved preferences");
            this.workflow.preferences.trigger("prepareInfoPersonnalisation", fresh);
          }
          UTILS.hideUsecaseHidder("tabgardes");
        },
        error: () => {
          UTILS.hideUsecaseHidder("tabgardes");
        }
      });
    }
    this.parent.close();
  }

  _checkButtonDelete(): void {
    let isDisabled = true;

    if (this.$el.find(".bloq_user input").is(":checked") ||
      this.$el.find(".bloq_allusers input").is(":checked")) {
      isDisabled = false;
    }
    this.$el.find(".dialogPersoSaveCriteria .btnSave").button({
      disabled: isDisabled
    });
  }

  render(): CWPersoSaveCriteriaDialog {
    const json = { i18n: i18n };

    this.$el.html(this.template(json));
    this.$el.find("button").button();

    if (this.workflow.preferences.get("perscrig").toString() === "0" && this.workflow.preferences.get("perscriu") === 1) {
      this.$el.find('.aff_user').attr('checked', 'true');
      this.$el.find('.aff_allusers').removeAttr('checked');

    } else if (this.workflow.preferences.get("perscrig") === 1 && this.workflow.preferences.get("perscriu").toString() === "0") {
      this.$el.find('.aff_user').removeAttr('checked');
      this.$el.find('.aff_allusers').attr('checked', 'true');
    } else if (this.workflow.preferences.get("perscrig") === 1 && this.workflow.preferences.get("perscriu") === 1) {
      this.$el.find('.aff_user').removeAttr('checked');
      this.$el.find('.aff_allusers').attr('checked', 'true');
    }
    // by default first checkbox checked.
    else if (this.workflow.preferences.get("perscrig").toString() === "0" && this.workflow.preferences.get("perscriu").toString() === "0") {
      this.$el.find('.aff_user').attr('checked', 'true');
      this.$el.find('.aff_allusers').removeAttr('checked');
    }
    return this;
  }

  _cancel(): void {
    this.parent.close();
  }
}
