module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n  <fieldset class="droitsdel-detail-panel cw-fieldset">\n    <div class="phx-formTable">\n      <div class="form-group phx-formErrors ui-state-error"></div>\n\n      <div class="form-row">\n        <div class="form-group col-6">\n            <div class="c-date-selector input-group">\n                <div class="input-group-prepend">\n                    <div for="datedeb" class="input-group-text c-labelAppend"><label for="datedeb" class="cw-required cw-labelImbrique">'+
((__t=(i18n.t("common:droits.coldu")))==null?'':__t)+
'</label></div>\n                </div>\n                <input type="text" class="datedeb typeDate periodStart form-control" periodId="1" value="" size="19" />\n            </div>\n            <span class="datedeb-error-container"></span>\n        </div>\n        <div class="form-group col-6">\n          <label for="datefin" class="sr-only">'+
((__t=(i18n.t("common:droits.colau")))==null?'':__t)+
'</label>\n          <div class="c-date-selector input-group">\n              <div class="input-group-prepend">\n                  <div for="datefin" class="input-group-text c-labelAppend">'+
((__t=(i18n.t("common:droits.colau")))==null?'':__t)+
'</div>\n              </div>\n              <input type="text" class="datefin typeDate periodEnd form-control" periodId="1" value="" size="19" />\n            </div>\n            <span class="datefin-error-container"></span>\n        </div>\n        </div>\n        \n        <div class="form-row">\n            <div class="form-group col-6">\n                <div class="form-group">\n                    <label for="delegue.matricule" class="droitsdel-label-lines cw-required">'+
((__t=(i18n.t("droitsdel.droitsDelegues")))==null?'':__t)+
'</label>\n                    <span class="droisDeleguesA"></span>\n                    <span class="delegue.matricule-error-container"></span>\n                </div>\n                <div class="form-group cw-customControlGroup">\n                    <div class="custom-control custom-checkbox">\n                        <input type="checkbox" class="retro custom-control-input" value="false" title="'+
((__t=(i18n.t("droitsdel.retrocession_title")))==null?'':__t)+
'" />\n                        <label for="retro" class="droitsdel-label-lines custom-control-label">'+
((__t=(i18n.t("droitsdel.retrocession")))==null?'':__t)+
'</label>\n                    </div>\n                </div>\n            </div>\n            <div class="form-group col-6">\n                <label for="commentaire">'+
((__t=(i18n.t("droitsdel.commentaire")))==null?'':__t)+
'</label>\n                <textarea class="commentaire form-control" maxlength="200" cols="" rows="4" style="height: 100px;resize: none" ></textarea>\n            </div>   \n            \n        </div>\n    </div>\n  </fieldset>\n\n  <a style="padding: 0" class="masquerAfficherDroits cw-texteNormal" href="javascript:void(0)">'+
((__t=(i18n.t("droitsdel.masquer")))==null?'':__t)+
'</a><br><br>\n</fieldset>\n\n<div class="table_droits"></div>\n';
}
return __p;
};
