import * as Backbone from 'Backbone';
import { CWProfilUtilisateurView } from 'common/profilutilisateur/views/cwProfilUtilisateur.view';
import { objs } from 'src/objectsRepository';
import { PROFILE } from 'utils/profile.js';

/**
 * View to show the profile component.
 */
export class CWProfileSelector extends Backbone.View {

  render(): CWProfileSelector {
    //Create the DOM for the menu
    let visibleMenu = false;
    const transverses = (this.model as any).configuration.get("confignav").get("transverses");

    for (let i = 0; i < transverses.length; i++) {
      const uc = transverses[i];

      if (uc.id === "profilutilisateur" && PROFILE.checkHabilitations(transverses[i].fonctions) && PROFILE.checkProfile()) {
        visibleMenu = true;
        objs.profilutilisateur = new CWProfilUtilisateurView();
        this.$el.append(objs.profilutilisateur.render().el);
      }
    }
    if (visibleMenu === false) {
      this.$el.hide();
    } else {
      this.$el.show();
    }
    return this;
  }
}