module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="cw-fieldset gererrecapitulatifs-commentPopup">\n    <!-- Empty div //RGAA - needed to show "(*) : champ obligatoire"  -->\n    <div></div>\n    <div class="form-row">\n        <div class="col m-0">\n            <div class="phx-gererrecapitulatifs-data">\n                <div class="form-group">\n                    <span class="title cw-texteNormal"></span>\n                </div>\n\n                <div class="form-group">\n                    <label for="commentaire" class="commentLabelStyle" title="'+
((__t=(i18n.t('common:gererrecapitulatifs.commentaire')))==null?'':__t)+
'">'+
((__t=(i18n.t('common:gererrecapitulatifs.commentaire')))==null?'':__t)+
'</label>\n                    <textarea class="commentaire form-control" rows="6" cols="40" maxlength="200" style="resize:none;"></textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class="form-row">\n        <span class="cwDialog-buttons">\n            <button type="button" class="btnOk btn btn-primary btn-withIcon" value="btnOk">'+
((__t=(i18n.t('common:confirmer')))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('valider','',16)))==null?'':__t)+
'</button>\n            <button type="button" class="btnAnnuler btn btn-secondary btn-withIcon" value="btnAnnuler">'+
((__t=(i18n.t('common:abort')))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon("croix",'',16)))==null?'':__t)+
'</button>\n        </span>\n    </div>\n\n</fieldset>';
}
return __p;
};
