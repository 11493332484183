import _ from 'underscore';
import TPLCalendaccCustomBar from '../cwCustomBar.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils';

/**
 * Custom Bar View
 */
export class CWCustomBarView extends CWBaseFormView {

  workflow: any;

  constructor(options: { [key: string]: any }) {
    options.events = _.extend({
      "keyup :input:not([readonly])": "_changeYearKeyUp",
      "click .arrow": "_changeYearBtn"
    }, CWTYPE.SHORT.events());
    super(options);
    this.model = new CWBaseModel();

    if (options && options.workflow) {
      this.workflow = options.workflow;
      this.model.set("year", this.workflow.yearDate);
    }

    this.template = TPLCalendaccCustomBar;
  }

  _getModel(): any {
    return this.model;
  }


  _changeYearKeyUp(event: any): void {
    let value = event.currentTarget.value;
    const key = event.keyCode;

    if (key !== 9) { //TAB
      value = CWTYPE.SHORT.parse(value).val;
      if (value.length === 4 && value > 1899 && value <= 2100) {
        this.model.set("year", value);
        this.workflow.formModel.trigger("changeYear", value);
      }
    }
  }

  _changeYearBtn(event: any): void {
    const element = event.currentTarget.className.split(" ")[1];
    let anno = null;

    switch (element) {
      case "leftArrow":
        anno = this.model.get("year");
        anno--;
        this.model.set("year", anno);
        this.$el.find(".year").val(anno);
        this.workflow.formModel.trigger("changeYear", anno);
        break;
      case "rightArrow":
        anno = this.model.get("year");
        anno++;
        this.model.set("year", anno);
        this.$el.find(".year").val(anno);
        this.workflow.formModel.trigger("changeYear", anno);
        break;
    }
  }

  render(): CWCustomBarView {
    const json = { "i18n": i18n, "UTILS": UTILS };

    this.$el.html(this.template(json));
    this.$el.find(".year").val(this.workflow.yearDate);
    return this;
  }
}