import _ from 'underscore';
import { CWCaracteristiquesCodifModel } from './cwCaracteristiques.codif.model';
import { CWCombosColl } from '../combo/cwCombos.collection';
import { CWSTR } from 'utils/cwStr';

/**
 * Collection of models of the combos to obtain the structure of the combos from personalissables fields
 */
export class CWCaracteristiquesCodifColl<TModel extends CWCaracteristiquesCodifModel = CWCaracteristiquesCodifModel> extends CWCombosColl<TModel>{

  /**
   * Type of combo to call to
   */
  public codif: string;
  public typeInfocomp: string;//type de donnée : N->numérique, C->char

  constructor(models?: CWCaracteristiquesCodifModel[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWCaracteristiquesCodifModel;
    super(models, options);
    this.paginated = false;
    this.codif = options.codif;
    this.typeInfocomp = options.typeInfocomp;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/tablebase/" + encodeURIComponent(this.codif) + "/liste";
    }
  }

  /**
   * This method is overwritting the parent's one.
   */
  clone(): CWCaracteristiquesCodifColl | any {
    const temp = super.clone.call(this);

    temp.codif = this.codif;
    temp.typeInfocomp = this.typeInfocomp;
    return temp;
  }

  parse(response: { [key: string]: any }[]): { [key: string]: any; }[] {
    ///vérification si la valeur de type est rempli et il y a de différences entre le code (texte ou char) et la valeur de l'élément complementaire(numérique)
    if (!CWSTR.isBlank(this.typeInfocomp) && response) {
      for (let i = 0; i < response.length; i++) {
        if (this.typeInfocomp === "N" && !CWSTR.isBlank(response[i].code) && typeof response[i].code === "string") {
          const valNum = parseInt(response[i].code, 10);

          if (!_.isNaN(valNum)) {
            response[i].code = String(valNum);//avec cette action, on a supprimé d'information qui peut produire des problèmes, par exemple "01"-> "1"
          }
        }
      }
    }
    return response;
  }
}