module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-menu ui-corner-all phx-menu-hover '+
((__t=(emid))==null?'':__t)+
'" role="menuitem"\n  aria-haspopup="true" tabindex="'+
((__t=(tabindex))==null?'':__t)+
'" data-value="'+
((__t=(index))==null?'':__t)+
'">\n  <span class="phx-menu-text">'+
((__t=(nom))==null?'':__t)+
'</span>\n  <span class="phx-menu-icon ui-corner-all ui-icon ui-icon-triangle-1-s"></span>\n  <div class="ui-menu ui-corner-all" aria-hidden="true">\n    <ul role="menu" aria-hidden="true"></ul>\n  </div>\n</div>\n';
}
return __p;
};
