import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWCollabModel } from './cwCollab.model';
import { CWDeleguerModel } from './cwDeleguer.model';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWGererAbsenceSouhaitDeleguerPopUpView } from '../views/cwGererAbsenceSouhaitDeleguerPopUp.view';
import { CWGererAbsenceSouhaitModel } from './cwGererAbsenceSouhait.model';
import { CWGererAbsenceSouhaitPopUpView } from '../views/cwGererAbsenceSouhaitPopUp.view';
import { CWGererAbsenceSouhaitView } from '../views/cwGererAbsenceSouhait.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMotifAbsInitModel } from './cwMotifAbsInit.model';
import { CWMSGS } from 'src/utils/cwMsgs';
import { CWPiecesAutorisees } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesAutorisees';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { LOG } from 'src/utils/log.js';
import { objs } from 'src/objectsRepository';

export class CWGererabsencesouhaitWorkflow extends CWReadOnlyModel {

  gererAbsenceSouhait: CWGererAbsenceSouhaitView;
  btnBarModel: Backbone.Model | CWBaseModel;
  setupState: number;
  parametreDiversIdCWCollabModel: Backbone.Model | CWBaseModel;
  pDiversSouhabsModel: Backbone.Model;
  pDiverspDiversSaiabsModelSouhabsModel: Backbone.Model;
  pDiversSaiabsDureeModel: Backbone.Model;
  maxPieces: CWPiecesAutorisees;
  wkfSouActiv: boolean;
  wkfAbsActiv: boolean;
  wkfRecupere: boolean;
  initWorkflow: CWMotifAbsInitModel;
  pDiversSaiabsModel: Backbone.Model;
  transformationEnCours: boolean;
  formModel: CWBaseModel;
  isRevert: boolean;
  parent: any;
  public module: string;
  public $appendTo: JQuery; //pour les messages

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.context = options.context;
    this.module = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : null;
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
    this.gererAbsenceSouhait = null;
    this.btnBarModel = null;
    this.setupState = undefined; // 0 --> setUp called, 1 --> setUp finished
    this.context = undefined;
    this.habContext = new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_SOLDEABS.V",
    });
    // Retrieve parametre divers for souhaite visibility (SouhAbs)
    this.pDiversSouhabsModel = GLOBAL_DATA.paramDivers.get("SouhAbs");
  }

  public setContext(ncontext: { [key: string]: any }): void {
    this.context = ncontext;
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    this.module = (this.context ? this.context.ctxEcran : null);
    if (!_.isEmpty(ncontext) && !CWSTR.isBlank(ncontext.ctxEcran) && ncontext.ctxHabilitation && !CWSTR.isBlank(ncontext.ctxHabilitation.HabilitationAcces)) {
      this.habContext = new CWHabilitationContext({
        onglet: ncontext.ctxEcran,
        foncCour: ncontext.ctxHabilitation.HabilitationAcces,
      });
    }
  }

  setButtonBarListener(): void {
    // this.listenTo(this.btnBarModel,"btn:click",this._buttonAction);
  }
  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp(callback: () => void, avoidMaxPiecesFetch: boolean): void {

    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.gererAbsenceSouhait.model.on("form:edited", this._formEdited, this);

    // retrieve parametre divers for matricule (idCollab)
    this.parametreDiversIdCWCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    // Retrieve parametre divers for souhaite visibility (SouhAbs)
    this.pDiversSouhabsModel = GLOBAL_DATA.paramDivers.get("SouhAbs");
    // Retrieve parametre divers for mode saisie heure (modeSaisieHeure)
    this.pDiversSaiabsModel = GLOBAL_DATA.paramDivers.get("sai_absh");
    // Retrieve parametre divers for mode saisie heure duree (modesaisieHeureDuree)
    this.pDiversSaiabsDureeModel = GLOBAL_DATA.paramDivers.get("abshduree0");

    this.maxPieces = new CWPiecesAutorisees({ id: "PJEVTABS" });
    if (this.context !== undefined && this.context.ctxHabilitation !== undefined) {
      this.maxPieces.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces ? this.context.ctxHabilitation.HabilitationAcces : this.context.ctxHabilitation.HabilitationGestion,  // eslint-disable-line
        natGest: ""                                                // eslint-disable-line
      }));
    }

    this.wkfSouActiv = false;
    this.wkfAbsActiv = false;

    this.initWorkflow = new CWMotifAbsInitModel();
    this.initWorkflow.setHabContext(new CWHabilitationContext({ onglet: this.module, foncCour: "PAR_MA.V" }));

    this.gererAbsenceSouhait.COLLAB_ID = this.parametreDiversIdCWCollabModel.get("valeur");
    this.gererAbsenceSouhait.GESTION_SOUHAIT = this.pDiversSouhabsModel.get("valeur");
    this.gererAbsenceSouhait.SAISIE_HEURE = this.pDiversSaiabsModel.get("valeur");
    this.gererAbsenceSouhait.SAISIE_DUREE = this.pDiversSaiabsDureeModel.get("valeur");

    if ((CWSTR.isBlank(avoidMaxPiecesFetch) || avoidMaxPiecesFetch === false) && CWHABILITATION.canView(this.maxPieces.habContext.get("foncCour"))) {
      this.maxPieces.fetch({
        success: () => {
          this._fetchWorkflowOpt(callback);
        }
      });
    } else {
      this._fetchWorkflowOpt(callback);
    }
  }

  _fetchWorkflowOpt(callback: () => void): void {
    this.initWorkflow.fetch({
      success: (fresh: CWMotifAbsInitModel): void => {
        // manages the options of the workflow.
        this.wkfRecupere = true; //flag pour savoir que le fetch déjà est fait ou pas (ne pas le faire deux fois)
        this.wkfAbsActiv = fresh.get("wkfdemactiv");
        this.wkfSouActiv = fresh.get("wkfsouactiv");
        this.trigger("wkfObtained");

        if (callback) {
          callback();
        }
      }
    });
  }

  _buttonAction(buttonId: string, profil?: any): void {
    LOG.debug("Button clicked in gerer absences : " + buttonId);
    let dialog = null;
    let dialogModel: Backbone.Model | CWBaseModel = null;
    let validationProfil: any = null;
    let statut: string;
    switch (buttonId) {
      case "save":
        if (this.transformationEnCours === true) {
          this.gererAbsenceSouhait.saveTransformAbsence();
        } else {
          this.gererAbsenceSouhait.saveAbsence();
        }
        if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
          this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('enableFilter');
        }
        break;
      case "revert":
        this.gererAbsenceSouhait.revertView();
        if (CWSTR.isBlank(this.gererAbsenceSouhait.model.get("value").id) && !CWSTR.isBlank(this.gererAbsenceSouhait.$el)) {
          this.trigger("manageUnselected", true);
        }
        if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
          this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('enableFilter');
        }
        this.gererAbsenceSouhait.model.trigger("editionEnd");
        break;
      case "new":
        if (this.gererAbsenceSouhait.context.ctxModeRepresentation === "Imbrique") {
          this.gererAbsenceSouhait.context.ctxTypeEvenement = "Absence";
        }
        if (this.gererAbsenceSouhait.context.ctxTypeEvenement === "Souhait") {
          this.gererAbsenceSouhait.newSouhait();
        } else {
          this.gererAbsenceSouhait.newAbsence();
        }
        /* if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
           this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('disableFilter');
         }*/
        this.btnBarModel.trigger("disable:accepter");
        this.btnBarModel.trigger("disable:refuser");
        this.trigger("manageUnselected", false);
        break;
      case "nouvelleAbsence":
        this.btnBarModel.trigger("hide:transformer");
        /*if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
          this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('disableFilter');
        }*/
        this.gererAbsenceSouhait.newAbsence();
        this.btnBarModel.trigger("disable:accepter");
        this.btnBarModel.trigger("disable:refuser");
        this.trigger("manageUnselected", false);
        break;
      case "nouveauSouhait":
        this.btnBarModel.trigger("disable:accepter");
        this.btnBarModel.trigger("disable:refuser");
        this.btnBarModel.trigger("hide:transformer");
        /*if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
          this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('disableFilter');
        }*/
        this.gererAbsenceSouhait.newSouhait();
        this.trigger("manageUnselected", false);
        break;
      case "transformer":
        this.gererAbsenceSouhait.transformAbsence();
        break;
      case "delete":
        statut = CWSTR.getElValue(this.gererAbsenceSouhait.model.get("value"), "statut.code");
        if (statut === "A" || statut === "H" ||
          (statut === "I" && this.gererAbsenceSouhait.context.ctxUtilisateur.toUpperCase() === "COLLABORATEUR")) {
          dialog = this._initPopUp("suppression");
          dialogModel = dialog.model;
          dialog.open(() => {
            if (dialogModel.get("action") === "OK") {
              this.gererAbsenceSouhait.deleteAbsence(dialogModel.get("commentaire"));
            }
          });
        } else {
          CWMSGS.showConfirmAdapter(i18n.t('common:delconfirm'), (result: string) => {
            if (result === "Y") {
              this.gererAbsenceSouhait.deleteAbsence(null);
            }
          }, null, null, null, this.$appendTo);
        }
        if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
          this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('enableFilter');
        }
        break;
      case "accepter":
        LOG.debug("accepter");
        dialog = this._initPopUp("accepter");
        dialogModel = dialog.model;
        validationProfil = profil;
        dialog.open(() => {
          if (dialogModel.get("action") === "OK") {
            this.gererAbsenceSouhait.accepterAbsence(dialogModel.get("commentaire"), validationProfil, dialogModel.get("justifok"));
          }
        });
        if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
          this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('enableFilter');
        }
        break;
      case "refuser":
        LOG.debug("refuser");
        dialog = this._initPopUp("refuser");
        dialogModel = dialog.model;
        validationProfil = profil;
        dialog.open(() => {
          if (dialogModel.get("action") === "OK") {
            this.gererAbsenceSouhait.refuserAbsence(dialogModel.get("commentaire"), validationProfil);
          }
        });
        if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
          this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('enableFilter');
        }
        break;
      case "nouveau": {
        LOG.debug("nouveau");
        this.trigger("click:nouveau");
      }
    }
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("disable:accepter");
    this.btnBarModel.trigger("disable:refuser");
    this.btnBarModel.trigger("disable:nouveau");

    if (this.formModel.getHabContext().get("natGest") !== "A") {
      this.formModel.updateHabContext({ foncCour: this.gererAbsenceSouhait.context.ctxHabilitation.HabilitationGestion, natGest: "M" }); // eslint-disable-line
    }
    /*if (this.parent && this.parent.workflow && this.parent.workflow.listerAbsenceSouhait && this.parent.workflow.listerAbsenceSouhait.filter) {
      this.parent.workflow.listerAbsenceSouhait.filter.model.trigger('disableFilter');
    }*/
  }

  _initPopUp(act: string): CWDialogPopupView {
    const stat = CWSTR.getElValue(this.gererAbsenceSouhait.model.get("value"), "statut.code");
    const type = CWSTR.getElValue(this.gererAbsenceSouhait.model.get("value"), "souhait");
    const justifiVerif = CWSTR.getElValue(this.gererAbsenceSouhait.model.get("value"), "justifverif");
    const justifi = CWSTR.getElValue(this.gererAbsenceSouhait.model.get("value"), "justif");
    const justifCheck = (justifi === true && justifiVerif === true) ? true : false;
    const absJustifVerif = CWSTR.getElValue(this.gererAbsenceSouhait.model.get("value"), "justifok");
    const etat = CWSTR.getElValue(this.gererAbsenceSouhait.model.get("value"), "etat");
    const dialog = new CWDialogPopupView({
      view: CWGererAbsenceSouhaitPopUpView,
      viewData: {
        action: act,
        statut: stat,
        type: type,
        justifCheck: justifCheck,
        absJustifVerif: absJustifVerif,
        etat: etat,
        appendTo: this.$appendTo
      }
    });

    dialog.setHeight("auto");
    dialog.setWidth(470);

    return dialog;
  }

  //EVO 582.2 - Check delegations
  checkDelegation(absence: CWGererAbsenceSouhaitModel): void {
    const modelDeleguer = new CWDeleguerModel();
    modelDeleguer.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationGestion  // eslint-disable-line
    }));

    const absences = [];
    absences.push({
      "souhait": absence.get("souhait"),
      "motif": absence.get("motif"),
      "datedeb": absence.get("datedeb"),
      "datefin": absence.get("datefin"),
      "unitedeb": absence.get("unitedeb"),
      "unitefin": absence.get("unitefin"),
      "matricule": absence.get("matricule")
    });
    modelDeleguer.attributes = JSON.parse(JSON.stringify(absences));

    if (CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && !CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      modelDeleguer.habContext.exceptionCreation = true;
    }

    modelDeleguer.save(null, {
      success: (fresh: CWDeleguerModel): void => {
        if (fresh.get("deleguer") === true) {
          if (objs.appRt.workflow.get("zone") === "resp") {
            const collabData = new CWCollabModel();
            const localFoncCour = CWHABILITATION.canView("RES_GCOLLAB.G") ? "RES_GCOLLAB.G" : "";

            //Get collab data
            collabData.setHabContext(new CWHabilitationContext({
              onglet: this.context.ctxEcran,
              foncCour: localFoncCour
            }));
            collabData.id = absence.get("matricule") + "," + absence.get("datedeb");
            collabData.fetch({
              success: (colabFresh: CWCollabModel) => {
                if (!CWSTR.isBlank(colabFresh.get("user"))) {
                  let datedeb = absence.get("datedeb");
                  let datefin = absence.get("datefin");
                  const now = new Date();
                  if (CWTYPE.DATE.getDiffInDays(datedeb, now) > 0) {
                    datedeb = CWTYPE.DATE.dateToStr(now, CWTYPE.DATE.DEFAULT_MASK_BBDD);
                  }
                  if (CWTYPE.DATE.getDiffInDays(datedeb, datefin) < 0) {
                    datefin = datedeb;
                  }
                  const context = {
                    viewData: {
                      utilCode: colabFresh.get("user"),
                      utilMatricule: absence.get("matricule"),
                      ctxPeriode: {
                        datedeb: datedeb,
                        datefin: datefin,
                      }
                    },
                  }
                  const dialog = this._initPopUpDeleguer();
                  const dialogModel = dialog.model;
                  dialog.open(function () {
                    if (dialogModel.get("action") === "deleguer") {
                      objs.appRt.headerView.userMenuView.clickOption(null, "droitsdel", context);
                    }
                  });
                  dialog._setTitle(i18n.t('common:gererabsencesouhait.title_popUp_deleguer'));
                }
              },
              error: () => {
                //Do nothing
              }
            });
          } else {
            let context = {};
            let datedeb = absence.get("datedeb");
            let datefin = absence.get("datefin");
            const now = new Date();
            if (CWTYPE.DATE.getDiffInDays(datedeb, now) > 0) {
              datedeb = CWTYPE.DATE.dateToStr(now, CWTYPE.DATE.DEFAULT_MASK_BBDD);
            }
            if (CWTYPE.DATE.getDiffInDays(datedeb, datefin) < 0) {
              datefin = datedeb;
            }
            if (!CWSTR.isBlank(objs.appRt.workflow.authModel.get("user"))) {
              context = {
                viewData: {
                  utilCode: objs.appRt.workflow.authModel.get("user"),
                  utilMatricule: objs.appRt.workflow.authModel.get("matricule"),
                  ctxPeriode: {
                    datedeb: datedeb,
                    datefin: datefin,
                  }
                }
              };
            } else {
              context = {
                viewData: {
                  ctxPeriode: {
                    datedeb: datedeb,
                    datefin: datefin,
                  }
                },
              };
            }
            const dialog = this._initPopUpDeleguer(false);
            const dialogModel = dialog.model;
            dialog.open(function () {
              if (dialogModel.get("action") === "deleguer") {
                objs.appRt.headerView.userMenuView.clickOption(null, "droitsdel", context);
              }
            });
            dialog._setTitle(i18n.t('common:gererabsencesouhait.title_popUp_deleguer'));
          }
        }
      }
    });
  }

  _initPopUpDeleguer(multipleAbs?: any): CWDialogPopupView {
    const dialog = new CWDialogPopupView({
      view: CWGererAbsenceSouhaitDeleguerPopUpView,
      viewData: { multipleAbsence: multipleAbs, appendTo: this.$appendTo }
    });

    dialog.setHeight("auto");
    dialog.setWidth(645);

    return dialog;
  }
}
