import _ from 'underscore';
import TPLListerpretsRowPopup from '../cwRowPopup.tpl.html';
import { CWAccepterPret } from '../models/cwAccepterPret';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWRefuserPret } from '../models/cwRefuserPret';
import { CWSTR } from 'utils/cwStr';
import { CWWkflhistoView } from 'src/uc/common/wkfhisto/views/cwWkfhisto.view';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils/utils.js';


export class CWRowPopupView extends CWBaseFormView {
  refEvenement: any;
  demandeId: any;
  action: any;
  workflow: any;
  commentaire: any;
  parent: any;
  pretEtat: string;

  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_enableButtons",
      "click button": "_clickListener"
    }, options.events);

    super(options);
    this.template = TPLListerpretsRowPopup;

    this.model = new CWBaseModel({
      value: null
    });

    if (options.refEvenement) {
      this.refEvenement = options.refEvenement;
    }

    if (options.pretEtat) {
      this.pretEtat = options.pretEtat;
    }

    if (options.demandeId) {
      this.demandeId = options.demandeId;
    }

    if (options.action) {
      this.action = options.action;
      if (this.action === "A") {
        this.model.set("value", new CWAccepterPret());
      } else if (this.action === "R") {
        this.model.set("value", new CWRefuserPret());
      } else if (this.action === "C") {
        if (this.pretEtat === "R") {
          this.model.set("value", new CWRefuserPret());
        } else {
          this.model.set("value", new CWAccepterPret());
        }
      }
    } else {
      this.model.set("value", new CWAccepterPret());//should be dummy
    }

    this.workflow = options.workflow;

    this.model.get("value").on("change:commentaire", this._mapToForm, this);

    this.commentaire = CWSTR.isBlank(options.commentaire) ? "" : options.commentaire;

    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }
    this.listenTo(this, "btnResult", this._clickListener);
  }

  render(): this {
    const json = { "i18n": i18n, UTILS: UTILS };
    const fieldset = this.$el;
    this.$el.append(this.template(json));

    //Initialize the comment textarea with the previous comment.
    CWSTR.setElValue(this.model.get("value"), "commentaire", this.commentaire);

    //Make the comment mandatory if is for refuse a pret
    if (this.action === "R" || this.pretEtat === "R") {
      $(this.el).find("fieldset.listerprets-dialogPopupRow label[for=commentaire], fieldset.listerprets-dialogPopupRow label[for^=commentaire_uid_]").addClass("cw-required");
    }

    //Disable the buttons
    $(this.el).find("fieldset.listerprets-dialogPopupRow button").button({ disabled: true });
    $(this.el).find(".btnAnnuler").button({ disabled: false });
    this._manageWKFHisto();

    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }
    this._mapModelToForm(fieldset, this.model); //RGAA - needed to show "(*) : champ obligatoire"

    return this;
  }

  _manageWKFHisto(): void {
    const typeLabel = "PRET";
    const contextComponentWkf = {
      ctxEcran: this.workflow.context.ctxEcran,
      ctxHabilitation: this.workflow.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: typeLabel,
      ctxRefEvenement: this.refEvenement,
      ctxDemId: this.demandeId,
      ctxModeRestitution: "Imbrique",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Deplier",
      ctxModeRech: "false"
    };

    const composantWorkflow = new CWWkflhistoView();
    $(this.el).find(".listerprets-wkfhisto").html(composantWorkflow.render().el);
    composantWorkflow.setContext(contextComponentWkf);
  }

  _change(event: JQueryEventObject): void {
    super._change(event);

    // Clear current validation erros
    this._cleanValidationErrors();
  }

  _enableButtons(): void {
    $(this.el).find("fieldset.listerprets-dialogPopupRow button").button({ disabled: false });
  }

  _mapToForm(): void {
    const model = this.model.get("value");
    const fieldset = this.$el.find("fieldset");
    this._mapModelToForm(fieldset, model, null);
  }

  _clickListener(btnClicked: string): void {
    const model = this.model.get("value");
    const commentaire = CWSTR.getElValue(model, "commentaire");

    switch (btnClicked) {
      case "btnValider":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);

        if (model.isValid()) {
          this.parent.model.set("commentaire", commentaire);
          this.parent.model.set("isCommentChanged", true);
          this.parent.close();
        }
        break;
      case "btnRevert":
        this.parent.close();
        break;
      default:
    }
  }
}
