import _ from 'underscore';
import TPLPiecesjointesComposant from '../cwPieceJointesComposant.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWCommonBlockOptions, CWCommonBlockView } from 'core/components/collapsible_block/cwBlock.view';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWFileTransferView } from 'core/components/file_transfer/cwFileTransfer.view';
import { CWGererPiecesJointesView } from 'common/evenements/gerer/gererpiecesjointes/views/cwGererPiecesJointes.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPiecesJointesColl } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesJointes.collection';
import { CWRemoteFileModel } from 'core/models/cwRemoteFile.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


export interface CWPieceJointesOptions extends CWCommonBlockOptions<CWBaseModel> {
  hideLibelle?: any;
  context?: { [key: string]: any };
  type?: string;
  maxPieces?: number;
  gerer?: boolean;
  mode?: string;
  appendTo?: string;
  modal?: boolean;
}

export interface CWPieceJointeType {
  code: string;
  libelle: string;
  description: string;
  mime?: string;
  id?: string;
  reelNom?: string;
  deleted?: boolean;
  cid?: string;
}

/**
 * View for the pieces jointes component
 */
export class CWPieceJointes extends CWCommonBlockView<CWBaseModel> {

  private type: string;
  private maxPieces: number;
  private piecesJointesList: Array<CWPieceJointeType>;
  private fileTransferList: Array<CWFileTransferView>;
  public description: string;
  private popupView: CWDialogView;
  private mode: string;
  private hideLibelle: boolean;
  private appendTo: string;
  private modal: boolean;
  private containerClass: string;
  public _oldManageButtons: (...args: any[]) => void;
  private desactive: boolean;//pour le méthode "desactiverPieceJointe"
  private omitAction: boolean;//Pour ne pas faire l'action d'ajouter une fois

  constructor(options?: CWPieceJointesOptions) {
    options = options ? options : {};
    options.className = options.className ? options.className + " pieceJointeComponentContainer" : "pieceJointeComponentContainer";
    options.events = _.extend({
      "click .c-panneauMenu": "_manageButtons",
      "keydown .c-panneauMenu": "keyDownEvent"
    }, options.events);
    super(options);
    this.context = options.context;
    this.type = options.type;
    if (typeof options.hideLibelle === "boolean") {
      this.hideLibelle = options.hideLibelle;
    }
    this.maxPieces = options.maxPieces;
    this.mode = options.mode;
    this.containerClass = options.className;
    this.appendTo = options.appendTo;
    this.modal = _.isBoolean(options.modal) ? options.modal : false;
    if (CWSTR.isBlank(this.type)) {
      throw new Error("A 'type' should be defined for this component");
    }
    this.model = new CWBaseModel({
      value: new CWPiecesJointesColl()
    });
    this.template = TPLPiecesjointesComposant;
    this.piecesJointesList = [];
    this.fileTransferList = [];
    this.desactive = false;
    this.omitAction = false;
  }

  render(): CWPieceJointes {
    const json = { "i18n": i18n, "UTILS": UTILS, "labelClass": this.hideLibelle ? 'sr-only' : '' };

    this.$el.append(this.template(json));
    return this;
  }

  // override default function
  canAccess(): boolean {
    const manage = (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationAcces && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces));

    return manage;
  }

  // override default implementation to use this.context.ctxActionsPossibles contains "Modifier"
  canManage(): boolean {
    const manage = (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion));

    if (manage && this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Creer") !== -1) {
      return true;
    }
    return false;
  }

  setContext(context: { [key: string]: any }): void {
    this.omitAction = false;
    this.context = context;
  }

  setDescription(description: string): void {
    this.description = description;
  }

  setPiecesJointes(list: CWPieceJointeType[]): void {
    this.piecesJointesList.length = 0;
    _.each(list, (item) => {
      const obj: CWPieceJointeType = {
        code: item.code,
        cid: item.code,
        libelle: item.libelle,
        description: item.description,
        mime: item.mime,
        id: item.code,
        reelNom: item.reelNom
      };

      this.piecesJointesList.push(obj);
    });
    if (this.piecesJointesList) {
      this._generateContent(this.piecesJointesList);
    }
  }

  applyPendingActions(evenement: string[], callback: (fresh?: { [key: string]: any }) => void, keepFileTransferState?: boolean, fresh?: { [key: string]: any }): void {
    try {
      const isModification = this.piecesJointesList.length >= 2 ? parseInt(this.piecesJointesList[0].code) : -1;
      const pieceDelete = _.filter(this.piecesJointesList, function (ele) {
        return ele.deleted === true;
      });

      if (pieceDelete) {
        // first step is to remove deleted pieces jointes
        _.each(pieceDelete, (item) => {
          if (item.deleted === true) {
            const model = new CWRemoteFileModel({ coll: null });

            model.id = item.code + "," + this.context.ctxRefEvenement + "," + this.type;
            model.setHabContext(new CWHabilitationContext({
              onglet: this.context.ctxEcran,
              foncCour: this.context.ctxHabilitation.HabilitationGestion,
              natGest: "S"
            }));
            model.destroy();
          }
        }, this);
      }
      // second step is to add new pieces jointes
      if (this.fileTransferList.length > 0) {
        const copyFileTransfer = _.clone(this.fileTransferList);

        if (keepFileTransferState) {
          _.each(this.fileTransferList, function (fileTransfer) {
            fileTransfer.setMultipleTransfer();
          });
        }
        this._recursiveSumbits(evenement, this.fileTransferList.length - 1, callback, isModification, keepFileTransferState, copyFileTransfer, fresh);
      } else {
        if (callback) {
          callback(fresh);
        }
      }
    } catch (e) {
      CWLOG.debug("exception Catch Piece Jointe: " + e);
      for (let i = this.fileTransferList.length - 1; i >= 0; i--) {
        this.fileTransferList[i].remove();
        this.fileTransferList.splice(i, 1);
      }
      if (callback) {
        callback(fresh);
      }
    }
  }

  _recursiveSumbits(evenement: string[], cont: number, callback: (fresh?: { [key: string]: any }) => void, isModification: number, keepFileTransferState: boolean, copyFileTransfer: Array<CWFileTransferView>, fresh?: { [key: string]: any }): void {
    const i = cont;

    if (i >= 0) {
      this._submitPieceJointe(evenement, this.fileTransferList[i], (cid: string, hasError: boolean, keepFileTransferState: boolean) => {
        // destroy view when transfer is finished
        for (let i = cont; i >= 0; i--) {
          if (this.fileTransferList[i].cid === cid) {
            this.fileTransferList[i].remove();
            this.fileTransferList.splice(i, 1);
            break;
          }
        }
        //if does not have error the recursivity should continue
        if (!hasError) {
          this._recursiveSumbits(evenement, i - 1, callback, isModification, keepFileTransferState, copyFileTransfer, fresh);
        }
        if (this.fileTransferList.length === 0 || hasError) {
          if (keepFileTransferState === true) { //Restore filetransfer if keepfiletransfer is true
            this.fileTransferList = copyFileTransfer;
          }
          if (callback) {
            callback(fresh);
          }
        }
      }, isModification, keepFileTransferState);
    }
  }

  _submitPieceJointe(evenement: string[], fileTransfer: CWFileTransferView, callback: (cid: string, success: boolean, keepFileTransferState?: boolean) => void, isModification: number, keepFileTransferState: boolean): void {
    if (fileTransfer && fileTransfer.shouldSubmit()) {
      fileTransfer.model.off("successUpload");
      fileTransfer.model.off("errorUpload");
      fileTransfer.model.off("progressUpload");
      fileTransfer.model.on("successUpload", () => {
        CWLOG.debug("success");
        if (callback) {
          callback(fileTransfer.cid, false, keepFileTransferState);
        }
      });
      fileTransfer.model.on("errorUpload", () => {
        CWLOG.debug("error");
        if (callback) {
          callback(fileTransfer.cid, true, keepFileTransferState);
        }
      });
      fileTransfer.submit(evenement, isModification);
    } else {
      if (callback) {
        callback(fileTransfer.cid, keepFileTransferState);
      }
    }
  }

  _contextToFilter(): { [key: string]: any } {
    const params: { [key: string]: any } = {};

    return params;
  }

  _getVisibleItems(list: Array<CWPieceJointeType>): number {
    let nbItems = 0;

    _.each(list, function (item: CWPieceJointeType) {
      if (item.deleted !== true) {
        nbItems++;
      }
    }, this);
    return nbItems;
  }

  _generateContent(collection: Array<CWPieceJointeType>): void {
    const ul = this.$el.find(".listPieces").empty();
    const $lPosAucune = this.$el.find(".cw-libelleEnrichi.aucune");
    let nbPj = null;

    if (collection.length > 0) {
      this._addPieceJointeLine(collection, 0, ul, (html: JQuery) => {
        ul.append(html);
      });
    } else {
      if (this.mode === "Consultation") {
        if (!$lPosAucune || $lPosAucune.length === 0) {
          const label = "<label class='cw-libelleEnrichi aucune'>" + i18n.t('common:piecesjointes.aucune') + "</label>";

          this.$el.append(label);
        }
      }
    }
    if (this.mode !== "Consultation" || this.canManage()) {
      const li = $("<li class='c-panneauMenu elementList add'>");
      const label = "<label class='cw-libelleEnrichi' tabindex='0'>" + i18n.t('common:piecesjointes.add_button') + "</label>";

      ul.append(li.append(label));
      this.$el.find(".aucune").hide();
    }
    this.$el.append(ul);
    if (this.canAccess() === false) {
      this.$el.hide();
    }
    // calcul du nombre effectif : ne pas tenir compte des pj supprimés
    nbPj = collection.length;
    for (let i = collection.length - 1; i >= 0; i--) {
      if (collection[i].deleted) {
        nbPj--;
      }
    }
    if (nbPj >= this.maxPieces || this.canManage() === false || this.desactive === true) {
      this.$el.find(".add").hide();
    }
  }

  _addPieceJointeLine(collection: Array<CWPieceJointeType>, i: number, htmlContent: JQuery, contentCallback: (arg1: JQuery) => void): void {
    if (collection.length > i) {
      const model = collection[i];

      if (model.deleted !== true) {
        this._formatPieceJointe(model, htmlContent);
      }
      this._addPieceJointeLine(collection, ++i, htmlContent, contentCallback);
    } else {
      contentCallback(htmlContent);
    }
  }

  _formatPieceJointe(model: CWPieceJointeType, ul: JQuery): void {
    const $li = $("<li class='c-panneauMenu elementList'>");
    const $file = CWSTR.isBlank(model.code) ? $("<span class='fileName selectionne'>") : $("<span class='fileName'>");
    const $icon = $("<div>");
    const $divFileName = $("<div class='contentFilename_pieceJointe'>");
    const $divContentLi = $("<div class='content_pieceJointe'>");
    const libelle = model.reelNom ? model.reelNom : model.libelle;
    const extension = !CWSTR.isBlank(libelle) ? libelle.split(".") : null;

    $li.attr({ "tabindex": "0" });
    try {
      const elementCode = !CWSTR.isBlank(model.code) ? model.code : model.cid;

      $file.data("filename", libelle);
      $file.data("code", elementCode);
      $file.addClass(UTILS.escapeJQueryString(String(elementCode)));
      $file.html(libelle);
    } catch (e) {
      $file.html(model.reelNom ? model.reelNom : model.libelle);
    }
    if (extension && extension.length > 0) {
      $icon.addClass(this.mimeClass(extension[extension.length - 1].toLowerCase()));
    } else {
      $icon.addClass(this.mimeClass("default"));
    }
    $divFileName.append($icon);
    $divFileName.append($file)
    $divContentLi.append($divFileName);
    if (this.desactive !== true && (this.mode !== "Consultation" || this.context.ctxActionsPossibles.indexOf("Supprimer") !== -1 && CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion))) {
      $divContentLi.append(UTILS.getSVGIcon('poubelle'));
    }
    $li.append($divContentLi);
    $li.attr("title", model.description);
    ul.prepend($li);
  }

  mimeClass(mime: string): string {
    switch (mime) {
      case "doc":
      case "docx":
        return "phx-icon-doc";
      case "pdf":
        return "phx-icon-pdf";
      case "png":
      case "bmp":
      case "jpg":
      case "jpeg":
        return "phx-icon-img";
      case "xls":
        return "phx-icon-xls";
      default:
        return "ui-phx-piece-jointe-icon";
    }
  }

  // open file in another navigator tab
  _manageFileClick(event: JQueryEventObject | JQueryKeyEventObject): void {
    if (!UTILS.isDownloadingFile()) {
      let code = null;

      if (event.target.tagName === "svg") {
        code = $(event.target).parent().parent().find(".fileName").data("code");
        this._manageDelete(code, event);
      } else {
        let filename: string = null;
        const $pos = $(event.target).find(".fileName");

        code = $pos.data("code");
        filename = $pos.data("filename") ? $pos.data("filename") : "file";
        if (!CWSTR.isBlank(code)) {
          const pathToTheFile = Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key);

          UTILS.downloadFileWithAjaxAsync(pathToTheFile, filename, this.containerClass);
        }
      }
    }
  }

  _manageButtons(event: JQueryEventObject | JQueryKeyEventObject): void {
    if (this.omitAction !== true) {
      const buttonId = event.currentTarget.className.split(" ")[2];
      const context: { [key: string]: any } = {};

      CWLOG.debug("Button clicked in component pieces jointes : " + buttonId);
      if (!CWSTR.isBlank(buttonId) && buttonId === "add") {
        if (CWSTR.isBlank(this.popupView)) {
          context.ctxRefEvenement = this.context.ctxRefEvenement;
          context.ctxHabilitation = this.context.ctxHabilitation;
          context.ctxEcran = this.context.ctxEcran;
          context.ctxGestionCollab = this.context.ctxGestionCollab;
          context.ctxActionsPossibles = this.context.ctxActionsPossibles;
          context.ctxModeInitialisation = "Ajout";
          this.popupView = this._initPopupView(context);
          this.popupView.open();
          this.popupView.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
          this.popupView.internalView.model.on("piecejointeAdded", this._piecejointeAdded, this);
          this.popupView.internalView.model.on("piecejointeRemoved", this._piecejointeRemoved, this);
        } else {
          if (!this.popupView.isOpen()) {
            context.ctxRefEvenement = this.context.ctxRefEvenement;
            context.ctxHabilitation = this.context.ctxHabilitation;
            context.ctxEcran = this.context.ctxEcran;
            context.ctxGestionCollab = this.context.ctxGestionCollab;
            context.ctxActionsPossibles = this.context.ctxActionsPossibles;
            context.ctxModeInitialisation = "Ajout";
            this.popupView = this._initPopupView(context);
            this.popupView.open();
            this.popupView.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
            this.popupView.internalView.model.on("piecejointeAdded", this._piecejointeAdded, this);
            this.popupView.internalView.model.on("piecejointeRemoved", this._piecejointeRemoved, this);
          }
        }
      } else {
        this._manageFileClick(event);
      }
    }
  }

  private keyDownEvent(key: JQueryKeyEventObject): boolean {
    if (key && (key.keyCode === 13 || key.keyCode === 32 || key.keyCode === 113)) { // enter or space or F2
      this._manageButtons(key);
    }
    return true;
  }

  _piecejointeAdded(fresh: CWFileTransferView): void {
    // new piece jointe added
    if (fresh instanceof CWFileTransferView) {
      if (fresh.shouldSubmit()) {
        this.fileTransferList.push(fresh);
        this.piecesJointesList.push(fresh.fileToUpload());
      }
    }
    // repaint component
    this._generateContent(this.piecesJointesList);
    // inform parent that the list has changed
    this.model.trigger("piecejointeChanged");
  }

  _piecejointeRemoved(id: string): void {
    let listSansSup: CWPieceJointeType[] = null;

    // piece jointe removed
    if (id) {
      for (let i = this.piecesJointesList.length - 1; i >= 0; i--) {
        if (this.piecesJointesList[i].cid === id) {
          if (this.fileTransferList.length > 0) {
            // clean file transfer list
            for (let j = this.fileTransferList.length - 1; j >= 0; j--) {
              if (this.fileTransferList[j].cid === id) {
                this.fileTransferList[j].remove();
                this.fileTransferList.splice(j, 1);
                this.piecesJointesList.splice(i, 1);
                break;
              }
            }
          }
        }
        if (!CWSTR.isBlank(this.piecesJointesList[i]) && this.piecesJointesList[i].cid === id) {
          this.piecesJointesList[i].deleted = true;
          break;
        }
      }
    }
    // repaint component
    listSansSup = (_.filter(this.piecesJointesList, (ele: { [key: string]: any }): boolean => {
      return ele.deleted !== true;
    }) as CWPieceJointeType[]);
    this._generateContent(listSansSup);
    // inform parent that the list has changed
    this.model.trigger("piecejointeChanged");
  }

  _initPopupView(context: { [key: string]: any }): CWDialogView {
    const fileTransfer = this._initFileTransfer();
    const dialog = new CWPanneauDeroulant({
      view: CWGererPiecesJointesView,
      viewData: {
        context: context,
        coll: this.piecesJointesList,
        fileTransferView: fileTransfer,
        description: this.description,
        maxPieces: this.maxPieces,
        appendTo: this.appendTo,
        modal: this.modal
      }
    });

    dialog.setHeight("auto");
    dialog.setWidth("auto");
    return dialog;
  }

  _initFileTransfer(): CWFileTransferView {
    const habContextFileTransfer = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    });
    const fileTransfer = new CWFileTransferView({
      entite: this.context.ctxRefEvenement,
      type: this.type,
      multiples: false,
      delayedUpload: true,
      habContext: habContextFileTransfer
    });

    fileTransfer.setHabContext(habContextFileTransfer);
    return fileTransfer;
  }

  _manageDelete(code: string, event: JQueryEventObject | JQueryKeyEventObject): void {
    let cid = "";
    let listSansSup: CWPieceJointeType[] = null;

    for (let i = this.piecesJointesList.length - 1; i >= 0; i--) {
      if (this.piecesJointesList[i].code.toString() === code || this.piecesJointesList[i].cid === code) {
        cid = this.piecesJointesList[i].cid;
      }
    }
    this._piecejointeRemoved(cid);
    $(event.target).parent().parent().remove();
    listSansSup = (_.filter(this.piecesJointesList, (ele: { [key: string]: any }): boolean => {
      return ele.deleted !== true;
    }) as CWPieceJointeType[]);
    if (listSansSup.length >= this.maxPieces) {
      this.$el.find(".add").hide();
    }
  }

  desactiverPieceJointe(etat: boolean): void {
    if (_.isBoolean(etat)) {
      if (etat) {
        this.mode = "Consultation";
        this.desactive = true;
        this._generateContent(this.piecesJointesList);
        if (this.piecesJointesList && this.piecesJointesList.length > 0) {
          this.$el.find(".listPieces li label").attr("tabindex", "-1");
        } else {
          //enlever le texte
          this.$el.find(".listPieces").hide();
          this.$el.find(".aucune").addClass("elementList").show();
        }
      } else {
        this.mode = null;
        this.desactive = false;
        this._generateContent(this.piecesJointesList);
        if (this.piecesJointesList && this.piecesJointesList.length > 0) {
          this.$el.find(".listPieces li label").attr("tabindex", "0");
        } else {
          this.$el.find(".listPieces").show();
          this.$el.find(".aucune").removeClass("elementList").hide();//Tous
        }
      }
    }
  }

  setOmitAction(etat: boolean): void {
    this.omitAction = _.isBoolean(etat) ? etat : false;
  }
}
