import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Prets Model
 */

export class CWPretModel extends CWBaseModel {

  profilErrors: string;
  selectedProfil: any;
  pretEtatValid: any;
  individualComment: boolean;
  nbpretEtatValid: number;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": "",
      "type": {
        "code": "",
        "libelle": ""
      },
      "motif": {
        "code": "",
        "libelle": ""
      },
      "collaborateur": {
        "nom": "",
        "prenom": "",
        "matric": null,
        "matricaux": "",
        "dmaj": "",
        "indicpret_resp": ""
      },
      "typepret": "",
      "indicateurp": false,
      "indicateurr": false,
      "emprunt": false,
      "datedeb": null,
      "datefin": null,
      "unitedeb": {
        "code": "",
        "libelle": ""
      },
      "unitefin": {
        "code": "",
        "libelle": ""
      },
      "heuredeb": null,
      "heurefin": null,
      "duree": null,
      "pourcentage": null,
      "dureecalculee": null,
      "perimetre": {
        "code": null,
        "libelle": ""
      },
      "droit": "",
      "profils": [],
      "structures": [],
      "indicateurpj": false,
      "piecesjointes": [],
      "etat": "",
      "statut": {
        "code": "",
        "libelle": ""
      },
      "derniereaction": {
        "action": "",
        "date": "",
        "auteur": ""
      }
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  validate(): any {
    const errors: any = {};

    if (CWSTR.isBlank(this.selectedProfil) || String(this.selectedProfil.code) === "0") {
      const profilError = i18n.t('common:required', { "0": i18n.t('common:listerprets.role') });
      const profilId = 'comboProfil_' + this.cid;

      errors[profilId as any] = profilError;
      this.profilErrors = profilError;
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.code !== undefined) {
      response.id = response.code;
    }
    if (response.collaborateur.indicpret_resp === "R") {
      response.typepret = "R";
    } else {
      response.typepret = "P";
    }

    return response;
  }
}
