import { BodyViewFrame } from './views/bodyViewFrame.js';
import { FORMS } from 'utils/forms.js';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { CWMSGS } from 'utils/cwMsgs';
import { objs } from 'src/objectsRepository';
import { CWRouterFactory } from 'src/uc/cwRouterFactory';
import { SplitPanelView } from 'core/layouts/splitPanel.view.js';
import { STR } from 'utils/str.js';
import { UTILS } from 'utils/utils.js';
import { WorkFlowModelFrame } from './models/workFlowModelFrame.js';

/**
 * Application main configuration
 */

/**
 * Application Main Router
 */
export var PhoenixAppRouterFrame = Backbone.Router.extend({

  initialize: function() {
    //Hidding the header
    $(".phx-header").hide();

    // Initializes the main parts of the UI and its workflow
    this.workflow = new WorkFlowModelFrame();

    // change the uri in the home implies a real navigation
    this.workflow.on("change:uri", function(wfl, value, options) {
      LOG.debug("Navigate to: " + wfl.get("uri") + " value: " + value + " trigger: " + options.trigger);
      var trigger = true;
      if (options && _.isBoolean(options.trigger)) {
        trigger = options.trigger;
      }
      this.navigate(wfl.get("uri"), { "trigger": trigger }); // eslint-disable-line no-invalid-this
    }, this);

    // Reset the subrouter of the usecase
    this.workflow.on("predestroy:usecase", this._predestroyUsecase, this);
    this.workflow.on("destroy:usecase", this._resetUsecase, this);

    // Manage full screen changes
    this.workflow.on("change:fullscreen", function(wfl) {
      var fullsc = wfl.get("fullscreen");
      this._manageFullscreen(fullsc); // eslint-disable-line no-invalid-this
    }, this);

    // Initializes the views
    this.bodyView = new BodyViewFrame({ el: $("#phx-container"), model: this.workflow });

    var self = this;
    Backbone.history.on("route", function(router, name, args) {
      LOG.debug("route: " + router.prefix + router.module + " usecase: " + router.module + " operation: " + args.join("/"));
      // If the router listener is a subroute,
      if (router !== self) {
        // If the subroute is already created
        if (objs[router.module + "Rt"]) {
          var zone = router.prefix.split("/")[1];
          var type = router.prefix.split("/")[2];
          self.workflow.set("zone", zone);
          self.workflow.set("type", type);
          self.workflow.set("usecase", router.module);
          self.workflow.set("operation", args.join("/"));
          self.workflow.set("uri", router.prefix + router.module + "/" + args.join("/"));
          LOG.debug("zone: " + zone + " usecase: " + router.module + " operation: " + args.join("/"));
        }
      }
    });

    this.workflow.on("changed:zone", function() {
      self._resizeProtoResizables();
    });
    this.workflow.on("changed:usecase", function() {
      self._resizeProtoResizables();
    });
    this.workflow.on("resize", function() {
      var usecase = self.workflow.get("usecase");
      self._resizeUsecaseResizables("#" + usecase);
    });

  },

  routes: {
    "": "home",
    "z/:zoneid": "dispatchToUseCase",
    "z/:zoneid/:type/*subroute": "dispatchToUseCase"
  },

  /**
   * Home of the application
   */
  home: function() {
    var self = this;
    this._initHomeLayout(function() {
      if (!STR.isBlank(self.workflow.paramEcranDef) && !STR.isBlank(self.workflow.paramEcranDef.get("valeur")) &&
        self.workflow.paramEcranDef.get("valeur") !== "aucun") {
        self.workflow._loadPreferedUc();
      } else {
        var briques = self.workflow.BRIQUES;
        var zone = "";

        if (briques["coll"] && briques["coll"].length > 0) {
          zone = "coll";
        } else if (briques["resp"] && briques["resp"].length > 0) {
          zone = "resp";
        } else if (briques["adm"] && briques["adm"].length > 0) {
          zone = "adm";
        } else if (briques["ref"] && briques["ref"].length > 0) {
          zone = "ref";
        }
        self.workflow.set("zone", zone);
        if (!STR.isBlank(zone)) {
          self.workflow.set("uri", "z/" + zone);
          self.workflow.set("selectedTab-" + zone, { module: "", type: "" });
        }
      }
    });
  },

  /**
   * Manage the requirement of load an Usecase
   */
  dispatchToUseCase: function(zone, type, subroute) {
    // Check if Home is not yet initialized
    var uri = "";
    var self = this;

    if (!(STR.isBlank(subroute) || _.isNull(subroute))) {
      // Limit for the tabs size
      var tabsTotal = objs.appRt.workflow.attributes.tabstotal;
      if (tabsTotal >= 10) {
        CWMSGS.showWarning(i18n.t('common:tabs_maximum_size', { "0": 10 }));
        return;
      }
    }

    this._dispatchToZone(zone, function() {
      var ucid = "";

      self.workflow.set("zone", zone);
      // create zone uri
      uri += "z/" + zone;
      try {
        if (!STR.isBlank(subroute)) {
          var parts = subroute.split("/");
          var operation = null;

          ucid = parts[0];
          if (parts.length > 1) {
            operation = subroute.replace(ucid + "/", "");
          }
          try {
            self.workflow.set("type", type);
            self.workflow.set("usecase", ucid);
            self._resizeProtoResizables();
          } catch (e) {
            LOG.error("Error resizing a module : " + e);
          }
          //add usecase uri
          uri += "/" + type + "/" + _.map(parts, function(part) { return encodeURIComponent(part); }).join("/");
          self._loadModule(ucid, type + "/");
          if (!STR.isBlank(operation)) {
            self.workflow.set("operation", operation);
          }
        } else {
          self.workflow.set("type", "");
          self.workflow.set("usecase", "");
        }
        // eslint-disable-next-line no-useless-catch
      } catch (e) {
        LOG.error(e);
        throw e;
      } finally {
        // Set uri
        self.workflow.set("uri", uri);
        self.workflow.set("selectedTab-" + zone, { module: ucid, type: type });
      }
    });
  },

  /**
   * Manage the zone dispatching
   */
  _dispatchToZone: function(zone, callback) {
    var self = this;

    this._initHomeLayout(function() {
      var zones = _.keys(self.workflow.ZONES);

      if (!_.contains(zones, zone)) {
        LOG.debug("The required zone does not exist : [" + zone + "]");
        self.workflow.set("uri", "");
        self.navigate(self.workflow.get("uri"));
      } else if (callback) {
        callback();
      }
    });
  },

  /**
   * Manage the change to full screen mode
   */
  _manageFullscreen: function() {
    $(".phx-center").height($(window).height());
    $(".phx-center").width($(window).width());
    this._updateMaxWidthSplitPanels();
    this._resizeProtoResizables();
  },

  /**
   * Initializes all the objects needed to paint the home. Layouts, workflow ...
   */
  _initHomeLayout: function(callback) {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      }
    } else {
      var self = this;

      this.workflow.setUp(function() {
        $("#phx-loading_initial").hide();
        $("#phx-wrap").show();
        self.bodyView.render();
        self._resizeProtoResizables();
        $(".phx-center").width($(window).width());
        if (callback) {
          callback();
        }
        self._resizeProtoResizables();
        self._updateMaxWidthSplitPanels();
      });
    }
  },

  /**
   * Load an application module on the fly
   */
  _loadModule: function(module, modulePrefix) {
    this._createNewRouter(module, modulePrefix);
  },

  _createNewRouter: function(module, modulePrefix) {
    try {
      var self = this;
      var prefix = "";

      // We assume all files are already loaded.
      if (!STR.isBlank(module)) {
        var l_zone = objs.appRt.workflow._searchZoneFromUc(module);

        //la zone du module est obtenu de l'information qui déjà on a. On évite des problèmes comme "coll/pl/planresp" ou "ref/suivi..."
        prefix = "z/" + l_zone + "/" + modulePrefix;
        if (this.workflow.get("zone") !== l_zone) {
          LOG.debug("(_createNewRouter) zone distinct: " + this.workflow.get("zone") + " !=" + l_zone);
          this.workflow.set("zone", l_zone, { silent: true });
        }
      } else {
        prefix = "z/" + this.workflow.get("zone") + "/" + modulePrefix;
      }
      // We assume all files are already loaded.
      CWRouterFactory.get(module).then(function(router) {
        objs[module + "Rt"] = new router(prefix, { "prefix": prefix, createTrailingSlashRoutes: true, "module": module });
        objs[module + "Rt"].on("uc:ready", function() {
          self._resizeProtoResizables();
          self._updateMaxWidthSplitPanels();
        });
        self.workflow.set("usecase", objs[module + "Rt"].module);
      })
    } catch (ex) {
      throw new Error("Error creating a router. Error code:(LoadingUCException)");
    }
  },

  /**
   * Reset recursively the backbone objects by calling off() or remove() for
   * the views
   */
  _resetBackboneRouter: function(obj) {
    if (obj instanceof Backbone.Router) {
      obj.off();
      obj.stopListening();
      if (obj.remove) {
        obj.remove();
      } else {
        UTILS.backboneObjectRecollector(obj, true);
      }
    }
  },

  /**
   * Reset the router of the Usecase if it exists
   */
  _resetUsecase: function(ucid, reloadUC) {
    var router = objs[ucid + "Rt"];

    // Delete routes from history
    Backbone.history.handlers = _.filter(Backbone.history.handlers, function(historyRouter) {
      var exists = true;

      if ((ucid === "agenda" || ucid === "agenda_R") && historyRouter.route.toString().indexOf("agenda_R") >= 0) {
        exists = true;
      } else {
        exists = (historyRouter.route.toString().indexOf(ucid) === -1);
      }
      return exists;
    });
    if (router) {
      this._resetBackboneRouter(router);
      delete objs[ucid + "Rt"];
    }
    if (reloadUC) {
      var ulDom = $("#phx-tabs-" + this.workflow.get("zone") + " ul").eq(0);

      if ($("#" + this.workflow.get("usecase"), ulDom.parent()).length === 0) {
        ulDom.after($("<div id=\"" + this.workflow.get("usecase") + "\" class='phx-proto-resizable '></div>"));
      }
      this.dispatchToUseCase(this.workflow.get("zone"), this.workflow.get("type"), this.workflow.get("usecase"));
    }

  },

  /**
   * Reset the router of the Usecase if it exists
   */
  _predestroyUsecase: function(ucid, reloadUC) {
    var router = objs[ucid + "Rt"];

    //When router has a _treatClose function
    if (router && !STR.isBlank(router._treatClose)) {
      var self = this;

      router._treatClose(reloadUC, function() {
        self.workflow.trigger("destroy:usecase", ucid, reloadUC);
      });
    } else {
      this.workflow.trigger("destroy:usecase", ucid, reloadUC);
    }
  },

  /**
   * Configure the screen and layout management
   */
  configureHomeLayout: function() {

    var self = this;
    var prevHeight = $(window).height();
    //On Resize the center part
    $(window).resize(function(event, ui) {
      // If the resize don't implies an ui or is on height it applies resizeProtos.
      var height = $(window).height();
      if ((!ui && prevHeight !== height) || (ui && ui.originalSize.height !== ui.size.height)) {
        // reset previous height
        prevHeight = height;
        self._resizeProtoResizables();
      }
      self._updateMaxWidthSplitPanels(event, ui);

      // Expand resize event to the whole application
      Backbone.trigger("resize:views", event, ui);
    });

    // Configure tooltips
    $(document).tooltip(FORMS.tooltip).off("focusin");
  },

  /**
   * Resize the height of layout divs
   */
  _resizeProtoResizables: function() {
    // Sets the height of the phx-container.
    var newSize = $(window).height() - 10;
    $("#phx-container").height(newSize);

    // Iterate all proto-resizables and change its height.
    var components = $("#phx-container .phx-proto-resizable");
    _.each(components, function(component) {
      var offsetTop = $(component).offset().top;
      // IF is a panel B4 adjust the size of the scroll
      if ($(component).hasClass("phx-panelB4")) {
        offsetTop += SplitPanelView.prototype.SPLIT_BORDER_WIDTH;
      }
      if (offsetTop > 0) {
        $(component).height($(window).height() - offsetTop);
      }
    }, this);

    this.workflow.trigger("resize");
  },

  /**
   * Resize the height of layout divs
   */
  _resizeUsecaseResizables: function(usecase) {
    // Sets the height of the phx-container.
    var newSize = $(window).height() - 10;
    $("#phx-container").height(newSize);

    // Iterate all proto-resizables and change its height.
    var components = $(".phx-proto-resizable", usecase);
    _.each(components, function(component) {
      var offsetTop = $(component).offset().top;
      // IF is a panel B4 adjust the size of the scroll
      if ($(component).hasClass("phx-panelB4")) {
        offsetTop += SplitPanelView.prototype.SPLIT_BORDER_WIDTH;
      }
      if (offsetTop > 0) {
        $(component).height($(window).height() - offsetTop);
      }
    }, this);
  },

  /**
   * Resize the maxwidth of split panels and the width of partB to adjust
   * scroll
   */
  _updateMaxWidthSplitPanels: function() {

    // set width of main container
    $(".phx-center").width($(window).width());

    //This border width is needed to adjust the min and maximun width of the menu
    var borderWidth = SplitPanelView.prototype.SPLIT_BORDER_WIDTH;
    var zone = this.workflow.get("zone");
    var lastUsecase = "lastuc-" + zone;
    var activeModule = this.workflow.get(lastUsecase);
    if (!activeModule || activeModule === "") {
      return;
    }

    var maxWidthAllowed = $(window).width();
    //set size of active panel
    var module = $("#" + activeModule);
    //    	LOG.debug("maxWidthAllowed: "+maxWidthAllowed+" "+module.css("padding-left")+" "+module.css("padding-right")+" "+(module.innerWidth() - module.width()))
    module.width(maxWidthAllowed);

    // Configure split panels
    // set size of left panel
    var elSplitA = $("#" + activeModule).find(".phx-splitPanel .phx-splitA");
    // If is a liste detail
    if (elSplitA.length > 0) {
      var size = elSplitA.length;
      for (var i = 0; i < size; i++) {
        var cid = elSplitA.eq(i).parents(".phx-splitPanel").attr("cid");
        LOG.debug("Backbone=>rezize:" + cid);
        Backbone.trigger("resize:" + cid);
      }
    } else {
      elSplitA = $("#" + activeModule).find(".phx-splitA .phx-panelA3");
      //			LOG.debug("max:"+maxWidthAllowed+"  width:"+elSplitA.width());
      elSplitA.width(maxWidthAllowed - borderWidth);
    }
  },

  /**
   * Calculate the required height of #phx-container
   */
  calculateCenterHeight: function() {
    return $(window).height();
  }
});
