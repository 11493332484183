import * as Backbone from 'Backbone';
import TPLLanceetatDetailHeader from '../cwDetailHeader.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWEtatsModel } from '../models/cwEtats.model';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';

export class CWHeaderView extends CWBaseFormView {
  constructor(params?: Backbone.ViewOptions<Backbone.Model> | any) {
    super(params);
    this.template = TPLLanceetatDetailHeader;

    this.model = new CWBaseModel({
      value: new CWEtatsModel()
    });
    this.model.on("change:value", this._mapToForm, this);
  }

  _mapToForm(): void {
    const value = this.model.get("value");
    if (!CWSTR.isBlank(value.get("libelle"))) {
      this.$el.find(".phx-lanceetat-header-selected").html(value.get("libelle"));
    } else {
      this.$el.find(".phx-lanceetat-header-selected").html("");
    }
    this.model.trigger("resize");
  }

  render(): CWHeaderView {
    const json = { 'i18n': i18n };
    this.$el.append(this.template(json));

    return this;
  }
}