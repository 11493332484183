import _ from 'underscore';
import { CWDummyEvtGen } from './cwDummyEvtGen.model';
import { CWSTR } from 'src/utils';
import { i18n } from 'src/i18n';


export class CWRefuserEvtGen extends CWDummyEvtGen {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/evtgen/refuser/";
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    if (CWSTR.isBlank(attrs.commentaire)) {
      errors.commentaire = i18n.t('messages:GT_1103');
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}