import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';

/**
 * Model to submit a recap
 */
export class CWSoumettreRecap extends CWBaseModel {
  id: string;

  /**
   * Add this to have Outline
   *
   */
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/element/demande/" + encodeURIComponent(this.id);
    };
  }
}
