module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="phx-formTable">\n    <div class="gererrecapitulatifs-header ctxGestionCollab" style="padding: 0; font-size: 9pt; font-weight: normal;">\n        <span role="button" aria-label="'+
((__t=(i18n.t('common:ARIA_label.open_recap')))==null?'':__t)+
'" tabindex="0" class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s gererrecapitulatifs-dialog-btn  cw-triggerClick"></span>\n        <span id="recapTitle" class="menuText recapTitle"></span>\n        <span class="sep"></span>\n        <span class="date"></span>\n        <span class="status"></span>\n        <span class="phx-icon phx-icon-comment-black comment"></span>\n        <span class="piece-jointe"></span>\n        <span class="plus"><a>'+
((__t=(i18n.t('common:gererrecapitulatifs.btnPlus')))==null?'':__t)+
'</a></span>\n        <span class="buttons"></span>\n        <ul class="validerListMenu menuContent"></ul>\n        <div class="clear"></div>\n    </div>\n    <div class="gererrecapitulatifs-dialog">\n        <fieldset class="cw-fieldset  d-flex flex-column" style="display: none;">\n            <div class="form-row">\n                <div class="form-group col-7">\n                    <div class="listerRecapsTitle cw-texteRubrique">\n                        '+
((__t=(i18n.t('common:gererrecapitulatifs.titleSelect')))==null?'':__t)+
'\n                    </div>\n                </div>\n                <div class="form-group col-5">\n                    <div class="creerRecapsTitle cw-texteRubrique">\n                        '+
((__t=(i18n.t('common:gererrecapitulatifs.titleAdd')))==null?'':__t)+
'\n                    </div>\n                </div>\n            </div>\n            <div class="form-row">\n                <div class="listerRecaps form-group col-7"></div>\n                <div class="creerRecaps form-group col-5"></div>\n            </div>\n        </fieldset>\n    </div>\n</div>';
}
return __p;
};
