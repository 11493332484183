module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="custom-control custom-checkbox">\n    <input id="'+
((__t=( id ))==null?'':__t)+
'" type="checkbox" class="phx-bistate-box custom-control-input" name="'+
((__t=( id ))==null?'':__t)+
'" value="true" />\n    <label for="'+
((__t=( id ))==null?'':__t)+
'" class="custom-control-label" ></label>\n</div>';
}
return __p;
};
