import { BaseCollection } from './base.collection.js';
import { i18n } from 'src/i18n.js';
import { MSGS } from 'utils/msgs.js';
import { STR } from 'utils/str.js';

export var PaginatedCollection = BaseCollection.extend({
  /**
   * Initializes the special attributes of this collection
   */

  /**
   * Constructor
   * Paginated collection for the application. Manage also filtering and sorting
   */
  initialize: function() {
    /**
     * Name of the field(s) that are ids of the models included in the
     * collection. Override it in your collection if the id is not the code
     */
    this.idnames = ["code"];
    /**
     * Params used at URL to filter and sort the collection
     */
    this.params = {}; // {code:2000,label:"test",...}
    /**
     * pagination - Index and size of pagination
     **/
    this.pagination = {
      startIndex: 0,
      size: Configuration.pageSize
      // Default size per page
    };
    /**
     * Fields set to true will be sorted
     */
    this.sortings = {}; // pairs {code:true, libelle:false...}
    /**
     * Number of total records in the collection
     */
    this.totalRecords = 0;
    // Pagination order
    this.ajaxRequests = null;
    // Set if the collection is paginated or not. Default true
    /**
     * Set to true when this collection is paginated (true by default)
     */
    this.paginated = true;
    //Omitted filter params
    this.omittedParams = [];
    this.omittedParams.push("target");
  },

  setOmittedParams: function(params) {
    if (params) {
      // clean Omitted filter params
      this.omittedParams = [];
      this.omittedParams.push("target");
      for (var i in params) {
        if (this.omittedParams.indexOf(i) === -1) {
          this.omittedParams.push(i);
        }
      }
    }
  },

  /**
   * Calls standar Backbone collection reset to replace a collection with a new list of models
   */
  reset: function(models, options) {
    this.totalRecords = 0;
    Backbone.Collection.prototype.reset.call(this, models, options);
  },

  /**
   * Find a model inside the whole collection, and paginate to the required
   * record if needed
   */
  findAndGo: function(model, callback, modeforced) {
    var self = this;
    var ids = null;
    var queryIds = "";

    if (model instanceof Backbone.Collection) {
      return -1;
    }
    /*modeforced values
    null ou undefined -> cas normal (il vient d'un "fetch")
    true-> il vient de scrollToRow et il faut faire une nouvelle pétition (par le tri appliqué ou autres cirscontances)
    false-> il vient de scrollToRow et il ne faut pas faire une nouvelle pétition-> le modèle n'existe pas et il faut éviter une deuxième pétition
    */
    if ((STR.isBlank(modeforced) && this.indexOf(this.get(model)) !== -1) || modeforced === false) {
      if (callback) {
        callback();
      }
      return null;
    }
    if (!model) {
      return -1;
    }
    ids = model.id;
    if (_.isString(ids)) {
      ids = model.id.split(",");
    } else {
      ids = [];
      ids.push(model.id);
    }
    _.each(this.idnames, function(value, index) {
      if (!_.isEmpty(queryIds)) {
        queryIds += ",";
      }
      queryIds += value + ";" + ids[index];
    });
    this.pagination.startIndex = 0;
    if (_.isUndefined(this.params) || _.isNull(this.params)) {
      this.params = {};
    }
    this.params.target = queryIds;
    return this.fetchPagination({
      success: function() {
        var asking = false; // Manages if the user should be asked to clean the filter
        var methodEnding = function(selectOld) {
          self.params.target = "";
          if (callback) {
            callback(selectOld);
          }
        };
        var index = self.models.find(function(collModel) { return String(collModel.id) === String(model.id); });

        if (STR.isBlank(index) && (!(model.get("ext") === "ext"))) {
          var search = _.keys(_.omit(self.params, self.omittedParams)).length;

          // Check if there is a search
          if (search > 0) {
            var optionsText = { yes: i18n.t('common:yes'), no: i18n.t('common:no') };

            asking = true;
            MSGS.showConfirm(i18n.t('messages:GL_1041'), function(result) {
              if (result === "C") {
                self.params = _.pick(self.params, self.omittedParams);
                self._resetOriginalParams();
                self.fetchPagination({
                  success: function() {
                    methodEnding();
                    self.trigger("collectionParams:cleaned");
                  },
                  error: function() {
                    methodEnding();
                  }
                });
              } else {
                methodEnding(true);
              }
            }, false, optionsText);
          }
        }
        if (!asking) {
          methodEnding();
        }
      }
    });
  },

  /**
   * This method reset the seach params when the collection has fixed parameters
   * and they are modified by a filter
   */
  _resetOriginalParams: function() {
    //Override it when the params will need to be reseted.
  },

  /**
   * Go to record at index passed as argument: "index"
   */
  goTo: function(index, callback) {
    this.pagination.startIndex = index;
    this.fetchPagination({
      success: function(fresh) {
        if (callback) {
          callback(fresh);
        }
      }
    });
  },

  /**
   * Go forward the number of records passed as argument: "number"
   */
  next: function(number, callback) {
    this.pagination.startIndex += number;
    this.fetchPagination({
      success: function() {
        if (callback) {
          callback();
        }
      }
    });
  },

  /**
   * Go back the number of records passed as argument: "number"
   */
  prev: function(number, callback) {
    this.pagination.startIndex -= number;
    this.fetchPagination({
      success: function() {
        if (callback) {
          callback();
        }
      }
    });
  },

  /**
   * Overrides the fetch to prepare the parameters before calling the server
   *  responds fetch without errors
   */
  fetch: function(options) {
    var oldSilent = false;
    var urlParams = this._prepareUrlParams();
    options = options ? _.clone(options) : {};
    if (!options.type || options.type === "GET") {
      options.data = urlParams;
    }
    if (STR.isBlank(options.reset) || !_.isBoolean(options.reset)) {
      options.reset = true;
    }
    if (options.silent === true) {
      oldSilent = true;
    }
    options.silent = true;
    options.success = (function(callback, self) {
      return function(model, response, options2) {
        options.silent = false;

        if (oldSilent !== true) {
          self.trigger('reset', self, options2);
        }
        if (callback) {
          callback(model, response, options2);
        }
      };
    })(options.success, this, urlParams.index);
    this.ajaxRequests = urlParams;
    return BaseCollection.prototype.fetch.call(this, options);
  },

  /**
   * Fetch used when we want to paginate. It will do a lazy data rendering
   */
  fetchPagination: function(options) {
    var oldSilent = false;
    var urlParams = this._prepareUrlParams();
    options = options ? _.clone(options) : {};
    if (!options.type || options.type === "GET") {
      options.data = urlParams;
    }
    // Store old index to control the pagination.
    options.oldIndex = urlParams.index;

    if (STR.isBlank(options.reset) || !_.isBoolean(options.reset)) {
      options.reset = true;
    }
    if (options.silent === true) {
      oldSilent = true;
    }
    options.silent = true;
    options.success = (function(callback, self) {
      return function(model, response, options) {
        if (options.oldIndex === 0 || self.ajaxRequests.index === options.oldIndex) {
          options.silent = false;
          if (oldSilent !== true) {
            self.trigger('reset', self, options);
          }
          if (callback) {
            callback(model, response, options);
          }
        }
      };
    })(options.success, this);
    this.ajaxRequests = urlParams;
    // Option to do the fetch synchronous
    options.sync = true;
    return BaseCollection.prototype.fetch.call(this, options);
  },

  /**
   * Prepare the 3 kinds of parameter this collection understand in an unique
   * object that the REST service can understand
   */
  _prepareUrlParams: function() {

    // Sorting configuration
    var pSorting = {};
    var querySort = "";

    _.each(this.sortings, function(value, key) {
      if (!_.isEmpty(querySort)) {
        querySort += ",";
      }
      querySort += key;
      var direction = (value) ? "asc" : "desc";
      querySort += ";" + direction;
    });
    if (!_.isEmpty(querySort)) {
      pSorting.tri = querySort;
    }

    // Filter parameters not empty
    var pWhere = {};
    _.each(this.params, function(value, key) {
      if (!STR.isBlank(value)) {
        pWhere[key] = value;
      }
    });

    // Join all the parameters
    var qParams = _.extend(pWhere, pSorting);
    if (this.paginated) {
      var respPagination = {};

      // Map our fields to the expected REST pagination specs
      // check for gruped vignettes
      if (!STR.isBlank(this.pagination.gruped)) {
        // set the values for grouped pagination
        respPagination = {
          index: this.pagination.startIndex * this.pagination.ROWS_FOR_FILE + 1,
          nbrang: this.pagination.size * this.pagination.ROWS_FOR_FILE
        };
      } else {
        respPagination = {
          index: this.pagination.startIndex + 1,
          nbrang: this.pagination.size
        };
      }

      // Join pagination parameters
      qParams = _.extend(qParams, respPagination);
    }

    if (this.usePopulation === true) {
      // Join population parameter
      qParams = _.extend(qParams, { "filtre": true });
    }
    return qParams;
  },

  /**
   * Prepare the 3 kinds of parameter this collection understand in an unique
   * object that the REST service can understand
   */
  resetPagination: function() {
    this.pagination.startIndex = 0;
    this.pagination.size = Configuration.pageSize;
  },

  /**
   * Process the response to feed the special field totalRecords and manage
   * the case of the response is an array instead of the expected one
   * {total=187, Array}
   */
  parse: function(response) {
    if (_.isArray(response)) {
      // It is waiting for a response [objects]. If this is so, we have a
      // REST service not paginated
      this.paginated = false;
      this.totalRecords = parseInt(response.length, 10);
      this.pagination.size = this.totalRecords;
      return response;
    } else {
      // It is waiting for a response {total:100, list=[objects]}
      this.totalRecords = parseInt(response.total);
      this.pagination.startIndex = Math.max(0, response.index - 1);
      return response.list;
    }
  },

  /**
   * This method do the request synchronous
   */
  sync: function(model, xhr, options) {
    if (options.sync) {
      var self = this;
      _.extend(options, {
        error: function() {
          self._lastSync = null;
        }
      });
      if (!this._lastSync) {
        this._lastSync = Backbone.sync.call(this, model, xhr, options);
      } else {
        var _this = this;
        this._lastSync = this._lastSync.then(function() {
          if (self.ajaxRequests.index === options.oldIndex) {
            return Backbone.sync.call(_this, model, xhr, options);
          }
          return null;
        });
      }
      return this._lastSync;
    } else {
      this._lastSync = null;
      return Backbone.sync.call(this, model, xhr, options);
    }
  },

  /**
   * Apply new filter to the collection and put the pagination in the 0 index
   *
   */
  applyFilter: function(params) {
    this.params = params;
    this.pagination.startIndex = 0;
  },

  /**
   * Clear filters and put the pagination in the 0 index
   */
  clearFilter: function() {
    this.params = {};
    this.pagination.startIndex = 0;
  }

});
