import { BaseModel } from 'core/models/base.model.js';
import { i18n } from 'src/i18n.js';
import { STR } from 'utils/str.js';

/**
 * Model of data for the right tables
 */
export var FormatColonneSortieModel = BaseModel.extend({

  habilitationV: "PAR_INTERF.V",
  habilitationG: "PAR_INTERF.G",
  usecase: "interfaces",

  defaults: {
    "id": null,
    "code": "", // code de l'interface
    "format": "", // code du format de sortie de l'interface
    "colonne": "0", // numero de la colonne
    "entetecol": "", // entete de colonne
    "type": "", // type de la colonne
    "typelibelle": "", // libelle du type de la colonne
    "valeur": "", // valeur de la colonne
    "repetition": "0", // repetition de la colonne
    "longueur": "0", // longueur de la colonne 
    "fmt": "", // format de la colonne 
    "utilise": "true", // Est-ce quela colonne est utilisée
    "comment": "", // commentaire de la colonne 
    "libelle": "" // libelle de la valeur
  },

  url: function() {
    var rtnUrl = null;

    switch (this.action) {
      case "ordre":
        rtnUrl = Configuration.restRoot + "/rest/paraminterface/formatsortie/" + encodeURIComponent(this.code) + "/" + encodeURIComponent(this.format) + "/" + encodeURIComponent(this.colonne);
        break;
      case "get":
        rtnUrl = Configuration.restRoot + "/rest/paraminterface/formatsortie/" + encodeURIComponent(this.code) + "/" + encodeURIComponent(this.format);
        break;
      case "save":
        rtnUrl = Configuration.restRoot + "/rest/paraminterface/formatsortie/" + encodeURIComponent(this.code) + "/" + encodeURIComponent(this.format);
        break;
      case "delete":
        rtnUrl = Configuration.restRoot + "/rest/paraminterface/formatsortie/" + encodeURIComponent(this.code) + "/" + encodeURIComponent(this.format) + "/" + encodeURIComponent(this.colonne);
    }
    return rtnUrl;
  },

  parse: function(response) {
    var responseAr = {};
    // If answer is inside an Array

    if (response.constructor === Array) {
      responseAr = response[0];
    } else {
      responseAr = response;
    }
    if (!_.isEmpty(responseAr) && responseAr.id === undefined && responseAr.colonne !== undefined) {
      responseAr.id = responseAr.colonne;
    }

    return responseAr;
  },

  validate: function(attrs) {
    var errors = {};

    if (STR.isBlank(attrs.valeur) && attrs.type === "CARSPE") {
      errors["valeurtxt"] = i18n.t('common:required', { "0": i18n.t('interfaces.valeurcol') });
    }
    if (STR.isBlank(attrs.type)) {
      errors["typelibelle"] = i18n.t('common:required', { "0": i18n.t('interfaces.typecol') });
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
});
