import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for load the elements with the general table (table left)
 */
export class CWElementModel extends CWBaseModel {

  /**
   * Default data model
   *		id : null,
   *		code : "",
   *		libelle : ""
   * }
   */
  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": "",
      "libelle": ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    options.usecase = options.usecase || "droitsdel";
    super(attributes, options)
  }
}