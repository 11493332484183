
const Zone0 = require('mon_compte/navigation.json');
const Zone1 = require('mon_equipe/navigation.json');
const Zone2 = require('referentiel/navigation.json');
const Zone3 = require('administration/navigation.json');


class CWRouterFactoryClass {
  routersZone0: any;
  routersZone1: any;
  routersZone2: any;
  routersZone3: any;

  constructor(routers0: any, routers1: any, routers2: any, routers3: any) {
    this.routersZone0 = new Map();
    this.routersZone1 = new Map();
    this.routersZone2 = new Map();
    this.routersZone3 = new Map();
    routers0.forEach((element: { [key: string]: any }) => {
      this.routersZone0.set(element.name, element);
    });
    routers1.forEach((element: { [key: string]: any }) => {
      this.routersZone1.set(element.name, element);
    });
    routers2.forEach((element: { [key: string]: any }) => {
      this.routersZone2.set(element.name, element);
    });
    routers3.forEach((element: { [key: string]: any }) => {
      this.routersZone3.set(element.name, element);
    });
  }

  get(name: string): any {
    let routerName = "";

    if (this.routersZone0.has(name)) {
      routerName = this.routersZone0.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "MonCompte" */
        /* webpackMode: "lazy" */
        "./mon_compte/monCompteRouters").then((routers: any) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    } else if (this.routersZone1.has(name)) {
      routerName = this.routersZone1.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "MonEquipe" */
        /* webpackMode: "lazy" */
        "./mon_equipe/monEquipeRouters").then((routers: any) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    } else if (this.routersZone2.has(name)) {
      routerName = this.routersZone2.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "Referentiel" */
        /* webpackMode: "lazy" */
        "./referentiel/referentielRouters").then((routers: any) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    } else if (this.routersZone3.has(name)) {
      routerName = this.routersZone3.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "Admistration" */
        /* webpackMode: "lazy" */
        "./administration/administrationRouters").then((routers: any) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    }
    return Promise.reject("Router not found.");
  }
}

const CWRouterFactory = new CWRouterFactoryClass(Zone0, Zone1, Zone2, Zone3);

export { CWRouterFactory };
