import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLSelecteurActivitesDialog from '../cwSelecteurActivitesDialog.tpl.html';
import { ActiviteColl } from '../models/activite.collection.js';
import { ActiviteModel } from '../models/activite.model.js';
import { ActiviteTreeColl } from '../models/activiteTree.collection.js';
import { CompleteWFL } from '../models/completeWFL.js';
import { CWActiviteTreesGeneralView } from 'common/activitetrees/views/cwActiviteTreesGeneral.view';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWFilterContentView } from './cwFilterContent.view';
import { CWFilterMoreContentView } from './cwFilterMoreContent.view';
import { CWFilterView } from 'core/components/filter/cwFilter.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWRadioBoxView } from 'core/views/cwRadioBox.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


export class CWDialogView extends CWBaseFormView {

  context: { [key: string]: any };
  parameters: any;
  initialSelection: any;
  workflow: typeof CompleteWFL;
  comboDomaines: CWComboBoxView2 | CWRadioBoxView;
  selActOuv: string;
  activiteTreesView: CWActiviteTreesGeneralView;
  filterView: CWFilterView;
  firstLoad: boolean;

  constructor(options: Backbone.ViewOptions | any) {
    options = options || {};
    options.events = _.extend({
      "click .phx-select-act-block-btn-recemment": "_toggleBlock",
      "click .phx-select-act-block-btn-cette-periode": "_toggleBlock",
      "click .phx-select-act-block-btn-periode-precedente": "_toggleBlock",
      "click .phx-select-act-block-btn-disponibles": "_toggleBlock",
      "change .cwSelecteurActiviteDlg .cwSelecteurActiviteDlg__collaborateurFieldsetRes :input:not([readonly])": "_filterChanged",
      "change .cwSelecteurActiviteDlg .cwSelecteurActiviteDlg__collaborateurFieldset :input:not([readonly])": "_filterChanged",
      "change .cwSelecteurActiviteDlg .domaineFieldset :input:not([readonly])": "_filterChanged"
    }, options.events);
    options.tagName = "span";
    options.className = "phx-selecteur-activites-dialog";
    super(options);
    /**
     * HTML template of the view
     */
    this.template = TPLSelecteurActivitesDialog;
    this.initialSelection = null;
    //Load parameters
    this.context = options.context;
    this.parameters = options.parameters;
    if (options.currentSelection && options.currentSelection.models.length === 1) {
      this.initialSelection = options.currentSelection.at(0);
    }
    this.workflow = options.workflow;
    this.habContext = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this._obtainFoncCour()
    });
    //InitializeFilterView
    this.filterView = this._initFilter();
    this.filterView.model.on("search", this._filter, this);
    /**
     * model with domainecompentece and inaptitude selected
     */
    this.model = new CWBaseModel({
      domaine: "",
      competence: false,
      inaptitude: false,
      restreindre: false
    });
    this.initializeCompAndInapFromContext();
    this.comboDomaines = null;
    this.selActOuv = GLOBAL_DATA.paramDivers.get("SelActOuv").get("valeur");
    this.firstLoad = true;
    //idplanact doit s'ajouter uniquement si l'option "restreindre" est cochée. Par défaut, cette n'est pas.
    this.workflow.domColl.idplanact = null;
    this.listenTo(this.workflow.domColl, "sync", () => {
      this._loadDomaines();
    });
  }
  /**
   * obtain fonccour from context for habilitations
   */
  _obtainFoncCour(): { [key: string]: any } {
    let foncCour: { [key: string]: any } = {};

    if (this.context.ctxEcran === "planresp" || this.context.ctxEcran === "planmed") {
      if (this.context.ctxTypeEvtGere === "R") {
        foncCour = this.context.ctxHabilitation.HabilitationGestion.realisee;
      } else {
        foncCour = this.context.ctxHabilitation.HabilitationGestion.prevue;
      }
    } else {
      if (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationConsultation) {
        if (this.context.ctxHabilitation.HabilitationConsultation.prevue) {
          foncCour.actprev = this.context.ctxHabilitation.HabilitationConsultation.prevue;
        }
        if (this.context.ctxHabilitation.HabilitationConsultation.realisee) {
          foncCour.actreal = this.context.ctxHabilitation.HabilitationConsultation.realisee;
        }
        if (!this.context.ctxHabilitation.HabilitationConsultation.prevue && !this.context.ctxHabilitation.HabilitationConsultation.realisee) {
          foncCour = this.context.ctxHabilitation.HabilitationConsultation;
        }
      } else {
        foncCour = this.context.ctxHabilitation.HabilitationConsultation;
      }
    }
    return foncCour;
  }

  initializeCompAndInapFromContext(): void {
    if (this.context.ctxActivCompetenceExacte === true) {
      this.model.set("competence", true);
    }
    if (this.context.ctxActivAvecInaptitude === true) {
      this.model.set("inaptitude", true);
    }
    if (!CWSTR.isBlank(this.context.ctxPlanActivites)) {
      this.model.set("restreindre", true);
    }
  }

  /**
   * Prepare the context needed for activitetrees component
   */
  _prepareTreesContext(context: { [key: string]: any }, defaultView?: string, code?: string, libelle?: string, famille?: string, filter?: { [key: string]: any }): { [key: string]: any } {
    const treesContext: { [key: string]: any } = {};
    const callback = (model: CWBaseModel): boolean => {
      return model.get("domcode") === this.model.get("domaine");
    };
    const domModel = _.find(this.workflow.domColl.models, callback);
    const datedeb = (!CWSTR.isBlank(context.ctxPeriodeGestion) && !CWSTR.isBlank(context.ctxPeriodeGestion.datedeb)) ? context.ctxPeriodeGestion.datedeb : CWTYPE.DATE.INITIAL;
    const datefin = (!CWSTR.isBlank(context.ctxPeriodeGestion) && !CWSTR.isBlank(context.ctxPeriodeGestion.datefin)) ? context.ctxPeriodeGestion.datefin : CWTYPE.DATE.INFINITY;
    const contenu = context.ctxTypeContenu;
    const actUnique = context.ctxRefActivUniq;
    const dom = !CWSTR.isBlank(domModel) ? domModel.get("domcode") : "";
    const avecInapt = this.model.get("inaptitude");
    const compExacte = this.model.get("competence");
    const ctxutil = context.ctxUtilisation;
    const typevt = context.ctxTypeEvtGere;
    const perdeb = context.ctxIndExisteDateDeb;
    const pertot = context.ctxIndExistePeriode;
    const matric = context.ctxCollab;
    const fltstruct = context.ctxFiltreStructCollab;
    const heritage = context.ctxActivHeritage;
    let emploidutemps = false;
    const fltHiertypeniv = context.ctxFiltreHiertypeNiv;
    const fltRatthorsregr = context.ctxRatthorsreg;
    const idplanact = this.workflow.parameters.idplanact;

    treesContext.ctxUtilisateur = "Collaborateur";
    treesContext.ctxHabilitation = {};
    treesContext.ctxHabilitation.HabilitationConsultation = this.context.ctxHabilitation.HabilitationConsultation;
    if (this.context.ctxHabilitation.HabilitationGestion) {
      treesContext.ctxHabilitation.HabilitationGestion = this.context.ctxHabilitation.HabilitationGestion;
    }
    treesContext.ctxEcran = context.ctxEcran;
    treesContext.ctxFromSelecteurDactivites = (this.context.ctxModePlieOuverture === true ? false : true);
    treesContext.ctxAffIndicTemporel = context.ctxAffIndicTemporel;
    treesContext.ctxFiltreHiertypeNiv = context.ctxFiltreHiertypeNiv;
    treesContext.ctxHierAutorise = context.ctxHierAutorise;
    treesContext.ctxRatthorsreg = context.ctxRatthorsreg;
    treesContext.ctxDomaineDetail = domModel;
    treesContext.ctxDefaultView = defaultView;
    treesContext.ctxPeriodeGestion = {};
    treesContext.ctxPeriodeGestion.datedeb = context.ctxPeriodeGestion.datedeb;
    treesContext.ctxPeriodeGestion.datefin = context.ctxPeriodeGestion.datefin;
    treesContext.ctxTypeContenu = this.context.ctxTypeContenu;
    treesContext.ctxRefActivUniq = this.context.ctxRefActivUniq;
    if (!CWSTR.isBlank(context.ctxEmploiDuTemps) && context.ctxEmploiDuTemps === true) {
      emploidutemps = context.ctxEmploiDuTemps;
    }
    treesContext.ctxNiveauxTypeHierarchy = this.workflow.ctxNiveauxTypeHierarchy;
    treesContext.ctxOnlySelectLeaf = context.ctxOnlySelectLeaf;
    treesContext.ctxFilterData = {
      contenu: contenu,
      "act_unique": actUnique,
      dom: dom,
      "avec_inapt": avecInapt,
      "comp_exacte": compExacte,
      ctxutil: ctxutil,
      typevt: typevt,
      datedeb: datedeb,
      datefin: datefin,
      perdeb: perdeb,
      pertot: pertot,
      matric: matric,
      fltstruct: fltstruct,
      heritage: heritage,
      code: code,
      libelle: libelle,
      famille: famille,
      emploidutemps: emploidutemps,
      "flt_hiertypeniv": fltHiertypeniv,
      "flt_ratthorsregr": fltRatthorsregr,
      idplanact: idplanact,
      filter: filter,
      ctxTypeGest: context.ctxTypeGest,
      ctxHorsTypeGest: context.ctxHorsTypeGest,
      horsStructExplotation: context.horsStructExplotation,
      ctxfeuilletemps: (context.ctxFeuilleTemps !== true) ? (domModel.get("saisieft") === true && this.context.ctxFiltre === true) ? true : false : context.ctxFeuilleTemps,
      ctxcptana: context.ctxCompteAnalytiqueRef
    };
    if (this.context.ctxPourPlanningMedical === true) {
      treesContext.ctxFilterData = _.extend({}, treesContext.ctxFilterData, { "medical": true });
    }
    treesContext.ctxBesoinPrioritaire = _.isBoolean(context.ctxBesoinPrioritaire) ? context.ctxBesoinPrioritaire : false;//Pour Besoins Prioritaires
    treesContext.ctxInaptitude = _.isBoolean(context.ctxInaptitude) ? context.ctxInaptitude : false;//Pour Inaptitudes
    treesContext.ctxModePlieOuverture = context.ctxModePlieOuverture;
    return treesContext;
  }

  /**
   * Initializes filter view
   */
  _initFilter(): CWFilterView {
    const foncCour = this.workflow._obtainFoncCour();
    const habContext = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: foncCour
    });
    const part1View = new CWFilterContentView({ habContext: habContext, workflow: this.workflow });
    const part2View = new CWFilterMoreContentView({ habContext: habContext });
    const filter = new CWFilterView({ id: "selecteur_activite_filter", viewPart1: part1View, viewPart2: part2View, columns: 7 });

    return filter;
  }

  _getModel(): CWBaseModel {
    return this.model;
  }

  /**
   * Filter shown data.
   */
  _filter(filter: { [key: string]: any }): void {
    const menuModel = this.activiteTreesView.workflow.menuViewsModel;
    let filterEmpty = false;

    this.workflow.filterContext = filter;
    this.$el.find(".activitetrees_generalView").remove();
    this.activiteTreesView.remove();
    if (this.activiteTreesView) {
      const selectedDomain = this.workflow.domColl.get(this.model.get("domaine"));

      this.$el.find(".activite-trees-Views").html(this.activiteTreesView.render().el);
      this._hideMessage();
      if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("grillebesoin") === true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
        this._showGrilleMessage(selectedDomain);
      } else if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("saisieft") === true && this.context.ctxFeuilleTemps !== true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
        this._showFeuilleTempsMessage();
      } else {
        let defaultView = null;
        let treesContext = null;

        defaultView = menuModel.get("menuView");
        // don't deploy node with empty filter
        _.each(filter, (view) => {
          if (!CWSTR.isBlank(view.code) || !CWSTR.isBlank(view.libelle)) {
            filterEmpty = true;
          }
        });
        filterEmpty = defaultView === this.activiteTreesView.FAMILLE ? true : filterEmpty;
        this._checkOpenNodes(filterEmpty, defaultView);
        treesContext = this._prepareTreesContext(this.context, defaultView, filter.code, filter.libelle, filter.famille, filter);
        this.activiteTreesView.setContextAndRender(treesContext);
      }
    }
  }

  /**
   * When a different node in the tree is selected,
   */
  _treeValueChange(model: CWBaseModel): void {
    const value = !CWSTR.isBlank(model) ? model.get("value") : null;

    if (!CWSTR.isBlank(value)) {
      value.set("parentdomcode", this.model.get("domaine"));
      this.model.trigger("activiteSelected", value);
    }
  }

  /**
   * Input value has changed and it is necessary to filter again
   */
  _filterChanged(event: JQuery.TriggeredEvent, data: object): void {
    const className = event.target.className.split(" ")[0];
    const isChecked = (event.target as any).checked;
    let defaultView = "";
    let selectedDomain: { [key: string]: any } = null;

    if (this.workflow.filterContext) {
      delete this.workflow.filterContext;
      this.filterView.clearFilterFields();
    }
    this._change(event, data);
    selectedDomain = this.workflow.domColl.get(this.model.get("domaine"));
    if (className !== "domaine" && className !== "restreindre") {
      const menuModel = this.activiteTreesView.workflow.menuViewsModel;

      //Don't keep the current view when domaine has changed
      defaultView = menuModel.get("menuView");
      this.filterView.trigger("startEditing");
    } else if (className === "restreindre") {
      //InitializeFilterView
      this.filterView = this._initFilter();
      this.filterView.model.on("search", this._filter, this);
      if (isChecked === true) {
        this.workflow.domColl.idplanact = this.context.ctxPlanActivites;
        this.workflow.parameters.idplanact = this.context.ctxPlanActivites;
      } else {
        this.workflow.domColl.idplanact = null;
        this.workflow.parameters.idplanact = null;
      }
      this.workflow.setUp((): void => {
        this.paintInfoDomaines()
      });
    } else {
      // Prendre le typehier.code
      // Obtenir les niveaux
      if (!CWSTR.isBlank(data)) {
        selectedDomain = this.workflow.domColl.get(data);
      }
      if (!CWSTR.isBlank(selectedDomain) && !CWSTR.isBlank(selectedDomain.get("hiertype")) && !CWSTR.isBlank(selectedDomain.get("hiertype").code)) {
        this.workflow.ctxNiveauxTypeHierarchy = selectedDomain.get("hiertype").niveaux;
      } else {
        this.workflow.ctxNiveauxTypeHierarchy = null;
      }
      this._verifMessage(selectedDomain, defaultView);
    }
    if (!CWSTR.isBlank(this.activiteTreesView)) {
      this._checkOpenNodes(false, this.activiteTreesView.workflow.menuViewsModel.get("menuView"));
    }
  }

  _verifMessage(selectedDomain: { [key: string]: any }, defaultView: string): void {
    // Verification GTMED & of the grillebesoin param
    this._hideMessage();
    if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("grillebesoin") === true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
      this._showGrilleMessage(selectedDomain);
    } else if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("saisieft") === true && this.context.ctxFeuilleTemps !== true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
      this._showFeuilleTempsMessage();
    } else {
      if (this.activiteTreesView) {
        const filter = this.workflow.filterContext;
        const code = !CWSTR.isBlank(filter) ? filter.code : "";
        const libelle = !CWSTR.isBlank(filter) ? filter.libelle : "";
        const famille = !CWSTR.isBlank(filter) ? filter.famille : "";
        const treesContext = this._prepareTreesContext(this.context, defaultView, code, libelle, famille);

        this.activiteTreesView.setContextAndRender(treesContext);
      }
    }
    this.workflow.trigger("updateFilterFields", this);
  }

  _showGrilleMessage(selectedDomain: any): void {
    this.$el.find("#selecteur_activite_filter").hide();
    this.$el.find(".activite-trees-Views").hide();
    this.$el.find(".message-activite").show();
    if (selectedDomain.get("typegest") === "GTMED") {
      this.$el.find(".message-activite > span").text(i18n.t('messages:GME_51')).show();
    } else {
      this.$el.find(".message-activite > span").text(i18n.t('messages:GT_5256')).show();
    }
  }

  /**
   * Shows/Hides data for this menu Block
   */
  _toggleBlock(event: JQueryEventObject): void {
    const target = event.currentTarget;
    const block = $(target).closest(".cwSelecteurActiviteDlg__block");
    const dataSection = $(block).find(".cwSelecteurActiviteDlg__block-data");

    if ($(target).hasClass("cw-icon-rot-270")) {
      $(target).removeClass("cw-icon-rot-270");
      $(dataSection).show();
    } else {
      $(target).addClass("cw-icon-rot-270");
      $(dataSection).hide();
    }
  }

  /**
   * Paints the view of the selecteur de chemin dialog component
   */
  render(): CWDialogView {
    const json = { i18n: i18n, restreindre: this.model.get("restreindre"), UTILS: UTILS };

    $(this.el).append(this.template(json));
    return this;
  }
  /**
   * If there is an element selected currently in selecteur activities
   * seploy tree to select it
   */
  _selectCurrentElement(): void {
    if (!CWSTR.isBlank(this.initialSelection) && !CWSTR.isBlank(this.initialSelection.get("attrs").parentdomcode) && !CWSTR.isBlank(this.initialSelection.freshChemin)) {
      const nodeToOpen = this.initialSelection.clone();
      const cheminModel = this.initialSelection.freshChemin;

      //Only expand if we have info about the activite selected, the domaine and the chemin to reach it
      nodeToOpen.nodeType = "H";
      if (!CWSTR.isBlank(CWSTR.getElValue(cheminModel, "datedeb")) && !CWSTR.isBlank(CWSTR.getElValue(cheminModel, "datefin"))) {
        nodeToOpen.periodeToSelect = { datedeb: CWSTR.getElValue(cheminModel, "datedeb"), datefin: CWSTR.getElValue(cheminModel, "datefin") };
      }
      if (this.activiteTreesView.workflow._obtainCurrentVueInTree() === 1) {
        const arrayPath = [];
        const familleCode = CWSTR.isBlank(this.initialSelection.get("attrs").famille) ? null : this.initialSelection.get("attrs").famille.code;
        let modelPath: CWBaseModel = null;

        //VUE par FAMILLE
        arrayPath.push({ code: familleCode });
        modelPath = new CWBaseModel({ "hierar": arrayPath });
        this.activiteTreesView.workflow.currentViewModel.trigger("expandPath", modelPath, nodeToOpen.get("attrs"), false, false, false, [], null, true);
      } else {
        const cheminAdaptedToView = this.activiteTreesView.workflow.prepareResultPath(cheminModel.clone());

        //VUE PAR HIERARCHIE, OR STRUCTURE SIMPLE OR STRUCTURE DETAILLE
        //only add the empty structure for struct detaille and simple
        this.activiteTreesView.workflow.currentViewModel.trigger("expandPath", cheminAdaptedToView, nodeToOpen.get("attrs"), false, false, false, [], null, true);
      }
    }
  }
  /**
   * Gets the domaineCode of the activite selected in selectuer if it exists
   */
  _getInitialDomaine(): string {
    let domCode = null;

    if (!CWSTR.isBlank(this.initialSelection)) {
      const initDomaineCode = this.initialSelection.get("attrs").parentdomcode;

      domCode = _.find(this.workflow.domColl.models, (mod: CWBaseModel): boolean => {
        return initDomaineCode === mod.get("domcode");
      });
      return CWSTR.isBlank(domCode) ? null : domCode.get("domcode");
    }
    return domCode;
  }
  /**
   * When view of tree is changed (from structure simple to structure detaille)
   */
  _changeLeftView(model: CWBaseModel, callback: (arg1: string, arg2: string) => void, ancientView: any, view: string): void {
    const viewName = model.get("menuView");

    if (callback) {
      callback(viewName, view);
    }
  }

  /**
   * We call to select first node for each type of tree
   */
  _selectCorrectNodeOrFirst(shouldReload: any, viewName: string): void {
    let callbackAnySelected = null;

    switch (viewName) {
      case this.activiteTreesView.FAMILLE:
        if (!CWSTR.isBlank(this.activiteTreesView.familleTreeView.tree) && !CWSTR.isBlank(this.activiteTreesView.familleTreeView.tree.root.opennode)) {
          callbackAnySelected = (found: boolean): void => {
            this.activiteTreesView.familleTreeView._emptyForm(!found);
          };
          if (this.activiteTreesView.workflow.context.ctxFilterData.code || this.activiteTreesView.workflow.context.ctxFilterData.libelle) {
            this.activiteTreesView.familleTreeView.tree.filter = true;
          }
          if (!CWSTR.isBlank(this.filterView.viewPart1.model.get("code")) || !CWSTR.isBlank(this.filterView.viewPart1.model.get("libelle"))) {
            this.activiteTreesView.familleTreeView.tree._selectFirstNode(callbackAnySelected);
          }
        }
        break;
      case this.activiteTreesView.HIERARCHIE:
        if (!CWSTR.isBlank(this.activiteTreesView.hierarchieTreeView.tree) && !CWSTR.isBlank(this.activiteTreesView.hierarchieTreeView.tree.root.opennode)) {
          callbackAnySelected = (found: boolean): void => {
            this.activiteTreesView.hierarchieTreeView._emptyForm(!found);
          };
          this.activiteTreesView.hierarchieTreeView.tree._selectFirstNode(callbackAnySelected);
        }
        break;
      case this.activiteTreesView.STRUCTUREDETAIL:
        if (!CWSTR.isBlank(this.activiteTreesView.structureDetailTreeView.tree) && !CWSTR.isBlank(this.activiteTreesView.structureDetailTreeView.tree.root.opennode)) {
          callbackAnySelected = (found: boolean): void => {
            this.activiteTreesView.structureDetailTreeView._emptyForm(!found);
          };
          this.activiteTreesView.structureDetailTreeView.tree._selectFirstNode(callbackAnySelected);
        }
        break;
      case this.activiteTreesView.STRUCTURESIMPLE:
        if (!CWSTR.isBlank(this.activiteTreesView.structureSimpleTreeView.tree) && !CWSTR.isBlank(this.activiteTreesView.structureSimpleTreeView.tree.root.opennode)) {
          callbackAnySelected = (found: boolean): void => {
            this.activiteTreesView.structureSimpleTreeView._emptyForm(!found);
          };
          this.activiteTreesView.structureSimpleTreeView.tree._selectFirstNode(callbackAnySelected);
        }
      //Not default
    }
  }

  /**
   * When we select a filter, we set a parameter to deploy nodes,
   */
  _checkOpenNodes(value: boolean, defaultView: string): void {
    switch (defaultView) {
      case this.activiteTreesView.FAMILLE:
        if (!CWSTR.isBlank(this.activiteTreesView.familleTreeView.tree)) {
          this.activiteTreesView.familleTreeView.tree.root.opennode = value;
        }
        break;
      case this.activiteTreesView.HIERARCHIE:
        if (!CWSTR.isBlank(this.activiteTreesView.hierarchieTreeView.tree)) {
          this.activiteTreesView.hierarchieTreeView.tree.root.opennode = value;
        }
        break;
      case this.activiteTreesView.STRUCTUREDETAIL:
        if (!CWSTR.isBlank(this.activiteTreesView.structureDetailTreeView.tree)) {
          this.activiteTreesView.structureDetailTreeView.tree.root.opennode = value;
        }
        break;
      case this.activiteTreesView.STRUCTURESIMPLE:
        if (!CWSTR.isBlank(this.activiteTreesView.structureSimpleTreeView.tree)) {
          this.activiteTreesView.structureSimpleTreeView.tree.root.opennode = value;
        }
      //Not default
    }
  }

  paintInfoDomaines(): void {
    let selectedDomain = (this.workflow.domColl.models.length > 0) ? this.workflow.domColl.get(this.model.get("domaine")) : null;
    const defaultView = "";
    let domColl = null;
    let fieldset: JQuery = null;

    $(this.el).find(".filter").html(this.filterView.render().el);
    domColl = this.workflow.domColl;
    if (domColl.models.length > 2) {
      const comboOptions: { [key: string]: any }[] = [];

      //ComboBox
      _.each(domColl.models, (domaine: CWBaseModel) => {
        const obj = { code: domaine.get("domcode"), libelle: domaine.get("domlib") };

        comboOptions.push(obj);
      });
      this.comboDomaines = new CWComboBoxView2({
        enum: comboOptions,
        name: "domaine",
        optionsRender: (value): string => {
          if (!value || CWSTR.isBlank(value.code)) {
            return "";
          }
          return value.libelle;
        },
        required: true
      });
      this.$el.find(".domaineSection").html(this.comboDomaines.render().el);
      this.comboDomaines.setItem(comboOptions[0]);
      this.model.set("domaine", comboOptions[0].code);
    } else if (domColl.models.length === 2) {
      const radioOptions: { [key: string]: any }[] = [];

      //RadioBox
      _.each(domColl.models, (domaine: CWBaseModel): void => {
        const obj = { code: domaine.get("domcode"), libelle: domaine.get("domlib") };

        radioOptions.push(obj);
      });
      this.comboDomaines = new CWRadioBoxView({
        options: radioOptions,
        name: "domaine",
        selectedOption: radioOptions[0].code,
        orientation: "horizontal"
      });
      this.$el.find(".domaineSection").html(this.comboDomaines.render().el);
      this.model.set("domaine", radioOptions[0].code);
    } else if (domColl.models.length === 1) {
      const $input = $("<input type='text' />");

      //One domaine
      $input.val(domColl.models[0].get("domlib"));
      $input.prop("readonly", true);
      $input.addClass("form-control");
      this.$el.find(".domaineSection").html($input[0].outerHTML);
      if (domColl.models.length === 1) {
        this.model.set("domaine", domColl.models[0].get("domcode"));
      }
    } else if (domColl.models.length < 1) {
      const aucuneSpan = "<div class='cw-texteNormal my-2'>" + i18n.t('common:selecteuractivites.aucunDomaine') + "</div>";

      //Empty
      this.$el.find(".domaineSection").html(aucuneSpan);
      if (domColl.models.length === 1) {
        this.model.set("domaine", domColl.models[0].get("domcode"));
      }
    }
    if (domColl.models.length >= 1) {
      const domaineCode = this._getInitialDomaine();
      const callbackTmp = (): void => {
        this._selectCurrentElement();
      };
      let filter = null;
      let code = null;
      let libelle = null;
      let famille = null;
      let treesContext = null;

      //Sets the domaine value
      if (!CWSTR.isBlank(domaineCode)) {
        this.model.set("domaine", domaineCode);
        if (!CWSTR.isBlank(this.comboDomaines) && this.comboDomaines instanceof CWComboBoxView2) {
          this.comboDomaines.setItem({ code: domaineCode, libelle: "" });
        } else if (!CWSTR.isBlank(this.comboDomaines) && this.comboDomaines instanceof CWRadioBoxView) {
          this.comboDomaines.setItem(domaineCode);
        }
      }
      filter = this.workflow.filterContext;
      code = !CWSTR.isBlank(filter) ? filter.code : "";
      libelle = !CWSTR.isBlank(filter) ? filter.libelle : "";
      famille = !CWSTR.isBlank(filter) ? filter.famille : "";
      treesContext = this._prepareTreesContext(this.context, defaultView, code, libelle, famille, filter);
      this.activiteTreesView = new CWActiviteTreesGeneralView({
        context: "",
        racineModel: new ActiviteModel(),
        racineColl: new ActiviteColl(),
        deployColl: new ActiviteTreeColl()
      });
      this.listenToOnce(this.activiteTreesView.workflow, "selectLastSelectedOrFirst", callbackTmp);
      this.listenTo(this.activiteTreesView.workflow, "change:value", this._treeValueChange);
      this.listenTo(this.activiteTreesView.workflow, "change:menuView", this._changeLeftView);
      if (this.selActOuv === "0" || this.selActOuv === "2") {
        this.$el.find(".activite-trees-Views").html(this.activiteTreesView.render().el);
        selectedDomain = this.workflow.domColl.get(this.model.get("domaine"));
        this._hideMessage();
        if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("grillebesoin") === true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
          this._showGrilleMessage(selectedDomain);
        } else if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("saisieft") === true && this.context.ctxFeuilleTemps !== true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
          this._showFeuilleTempsMessage();
        } else {
          this.activiteTreesView.setContextAndRender(treesContext);
        }
      }
    } else {
      this.$el.find(".activite-trees-Views").html("");
    }
    //Show domaines
    if (domColl.models.length <= 0) { //If no domaine is present
      selectedDomain = null;
      this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset").hide();
      this.$el.find(".filter").hide();
    } else {
      this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset").show();
      this.$el.find(".filter").show();
    }
    if (CWSTR.isBlank(this.context.ctxCollab)) {
      this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset").hide();
    }
    //Map to fields
    fieldset = this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset");
    this._mapModelToForm(fieldset, this.model);
    this._verifMessage(selectedDomain, defaultView);
  }

  _loadDomaines(): void {
    if (this.firstLoad) {
      const domColl = this.workflow.domColl;
      let fieldset: JQuery = null;

      this.firstLoad = false;
      //Render DOMAINES
      $(this.el).find(".filter").html(this.filterView.render().el);
      if (domColl.models.length > 2) {
        const comboOptions: { [key: string]: any }[] = [];

        //ComboBox
        _.each(domColl.models, (domaine: CWBaseModel) => {
          const obj = { code: domaine.get("domcode"), libelle: domaine.get("domlib") };

          comboOptions.push(obj);
        });
        this.comboDomaines = new CWComboBoxView2({
          enum: comboOptions,
          name: "domaine",
          optionsRender: (value): string => {
            if (!value || CWSTR.isBlank(value.code)) {
              return "";
            }
            return value.libelle;
          },
          required: true
        });
        this.$el.find(".domaineSection").html(this.comboDomaines.render().el);
        this.comboDomaines.setItem(comboOptions[0]);
        this.model.set("domaine", comboOptions[0].code);
      } else if (domColl.models.length === 2) {
        const radioOptions: { [key: string]: any }[] = [];

        //RadioBox
        _.each(domColl.models, (domaine: CWBaseModel) => {
          const obj = { code: domaine.get("domcode"), libelle: domaine.get("domlib") };

          radioOptions.push(obj);
        });
        this.comboDomaines = new CWRadioBoxView({
          options: radioOptions,
          name: "domaine",
          selectedOption: radioOptions[0].code,
          orientation: "horizontal"
        });
        this.$el.find(".domaineSection").html(this.comboDomaines.render().el);
        this.model.set("domaine", radioOptions[0].code);
      } else if (domColl.models.length === 1) {
        const $input = $("<input type='text' />");

        //One domaine
        $input.val(domColl.models[0].get("domlib"));
        $input.prop("readonly", true);
        $input.addClass("form-control");
        this.$el.find(".domaineSection").empty();
        this.$el.find(".domaineSection").append($input);
        if (domColl.models.length === 1) {
          this.model.set("domaine", domColl.models[0].get("domcode"));
        }
      } else if (domColl.models.length < 1) {
        const aucuneSpan = "<div class='cw-texteNormal my-2'>" + i18n.t('common:selecteuractivites.aucunDomaine') + "</div>";

        //Empty
        this.$el.find(".domaineSection").html(aucuneSpan);
        if (domColl.models.length === 1) {
          this.model.set("domaine", domColl.models[0].get("domcode"));
        }
      }
      if (domColl.models.length >= 1) {
        const domaineCode = this._getInitialDomaine();
        const callback = (): void => {
          this._selectCurrentElement();
        };
        const callback2 = (): void => {
          if (!CWSTR.isBlank(this.activiteTreesView)) {
            this.workflow.trigger("updateFilterFields", this);
          }
        };
        let treesContext = null;

        //Sets the domaine value
        if (!CWSTR.isBlank(domaineCode)) {
          this.model.set("domaine", domaineCode);
          if (!CWSTR.isBlank(this.comboDomaines) && this.comboDomaines instanceof CWComboBoxView2) {
            this.comboDomaines.setItem({ code: domaineCode, libelle: "" });
          } else if (!CWSTR.isBlank(this.comboDomaines) && this.comboDomaines instanceof CWRadioBoxView) {
            this.comboDomaines.setItem(domaineCode);
          }
        }
        treesContext = this._prepareTreesContext(this.context);
        this.activiteTreesView = new CWActiviteTreesGeneralView({
          context: "",
          racineModel: new ActiviteModel(),
          racineColl: new ActiviteColl(),
          deployColl: new ActiviteTreeColl(),
          selecteurContext: this.context,
        });
        this.listenToOnce(this.activiteTreesView.workflow, "selectLastSelectedOrFirst", callback);
        this.listenTo(this.activiteTreesView.workflow, "updateFilterFields", callback2);
        this.listenTo(this.activiteTreesView.workflow, "change:value", this._treeValueChange);
        this.listenTo(this.activiteTreesView.workflow, "change:menuView", this._changeLeftView);
        this.activiteTreesView.workflow.on("selectLastSelectedOrFirst", this._selectCorrectNodeOrFirst, this);
        if (this.selActOuv === "0" || this.selActOuv === "2") {
          const selectedDomain = this.workflow.domColl.get(this.model.get("domaine"));

          this.$el.find(".activite-trees-Views").html(this.activiteTreesView.render().el);
          this._hideMessage();
          if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("grillebesoin") === true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
            this._showGrilleMessage(selectedDomain);
          } else if (this.context.ctxFiltre !== true && !CWSTR.isBlank(selectedDomain) && selectedDomain.get("saisieft") === true && this.context.ctxFeuilleTemps !== true && this.context.ctxTypeContenu !== "R" && this.context.ctxEcran !== "actvalor") {
            this._showFeuilleTempsMessage();
          } else {
            this.activiteTreesView.setContextAndRender(treesContext);
          }
        }
      } else {
        this.$el.find(".activite-trees-Views").html("");
      }
      if (domColl.models.length <= 0) { //If no domaine is present
        this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset").hide();
        this.$el.find(".filter").hide();
      } else {
        this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset").show();
        this.$el.find(".filter").show();
      }
      if (CWSTR.isBlank(this.context.ctxCollab)) {
        this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset").hide();
      }
      //Map to fields
      fieldset = this.$el.find(".cwSelecteurActiviteDlg__collaborateurFieldset");
      this._mapModelToForm(fieldset, this.model);
    }
  }

  _hideMessage(): void {
    this.$el.find("#selecteur_activite_filter").show();
    this.$el.find(".activite-trees-Views").show();
    this.$el.find(".message-activite > span").hide();
  }

  _showFeuilleTempsMessage(): void {
    this.$el.find("#selecteur_activite_filter").hide();
    this.$el.find(".activite-trees-Views").hide();
    this.$el.find(".message-activite").show();
    this.$el.find(".message-activite > span").text(i18n.t('messages:GT_2504')).show();
  }
}
