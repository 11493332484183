import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Model for gerer badgeages
 **/
export class CWListeBadgeageModel extends CWBaseModel {
  action: string;
  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "code": null,
      "matricule": null,
      "statut": {
        "code": "",
        "libelle": ""
      },
      "motif": {
        "code": "",
        "libelle": ""
      },
      "saisie": {
        "date": "",
        "heure": "",
        "sens": ""
      },
      "valorise": {
        "date": "",
        "heure": "",
        "sens": ""
      },
      "initial": {
        "date": "",
        "heure": "",
        "sens": ""
      },
      "fonction": "",
      "commentaire": "",
      "droit": null,
      "iddemande": null,
      "provenance": "",
      "origine": null,
      "terminal": null,
      "genere": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);

    this.action = "";
    if (options && options.action) {
      this.action = options.action;
    }

    this.url = (): string => {
      return Configuration.restRoot + "/rest/badgeage/composant/" + this.action;
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    let errorMsg: string;
    let errorMap: { errorValidation?: string; errors?: { [key: string]: any } } = null;

    errors.saisie = {};
    if (!CWSTR.isBlank(attrs.saisie.date)) {
      errorMsg = CWTYPE.DATE.validate(attrs.saisie.date);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.saisie.date = errorMsg;
      }
    } else {
      errors.saisie.date = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.date') });
    }
    if (!CWSTR.isBlank(attrs.saisie.heure)) {
      errorMsg = CWTYPE.HOUR_MINUTE.validate(attrs.saisie.heure);
      if (!CWSTR.isBlank(errorMsg)) {
        errors.saisie.heure = errorMsg;
      }
    } else {
      errors.saisie.heure = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.heure') });
    }
    if (CWSTR.isBlank(attrs.saisie.sens)) {
      errors.saisie.sens = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.sens') });
    }

    if (!_.isEmpty(errors.saisie)) {
      errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
    }

    return errorMap;
  }

  /**
   * Parsed the results returned by the REST,
   * Copy the matricule and code in the object id.
   * Initializes saisies values to valorises values in order to show them initially in the form.
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.matricule !== undefined && response.code !== undefined) {
      response.id = response.matricule + "," + response.code;
    }
    if (response.valorise !== undefined) {
      if (response.valorise.heure !== undefined) {
        response.saisie.heure = response.valorise.heure;
      }
      if (response.valorise.date !== undefined) {
        response.saisie.date = response.valorise.date;
      }
      if (response.valorise.sens !== undefined) {
        response.saisie.sens = response.valorise.sens;
      }
    }

    if (response.result) {
      return null;
    }
    return response;
  }
}
