import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLPlanningDialogPersoDeleteCriteria from '../dialog_perso_deletePlanningCriteria.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWSauveGarderPreferenceModelDelete as SauveGarderPlanrespPreference } from '../models/cwSauveGarderPlanrespPreferenceModelDelete';
import { CWSauveGarderPreferenceModelDelete as SauveGarderPlanmedPreference } from '../models/cwSauveGarderPlanmedPreferenceModelDelete';
import { CWSauveGarderPreferenceModelDelete as SauveGarderTabServicePreference } from '../models/cwSauveGarderTabServicePreferenceModelDelete';
import { CWSauveGarderPreferenceModelDelete as SauveGarderTabServiceCollPreference } from '../models/cwSauveGarderTabServiceCollPreferenceModelDelete';
import { CWSauveGarderPreferenceModelDelete as SauveGarderTabGardesPreference } from '../models/cwSauveGarderTabGardesPreferenceModelDelete';
import { CWSauveGarderPreferenceModelDelete as SauveGarderPlequipePreference } from '../models/cwSauveGarderPlequipePreferenceModelDelete';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';

export class CWPersoDeleteCriteriaDialog extends CWBaseFormView {

  workflow: any;
  uc: any;
  parent: any;
  template: (params?: any) => string;
  modePlanning: boolean;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    super(options);
    let context: any;

    this.template = TPLPlanningDialogPersoDeleteCriteria;
    this.modePlanning = false;
    if (options && !CWSTR.isBlank(options.modePlanning)) {
      this.modePlanning = options.modePlanning;
    }
    if (options.workflow) {
      this.workflow = options.workflow;
    }
    if (options.uc) {
      this.uc = options.uc;
    }
    if (options.parent) {
      this.parent = options.parent;
      //set the title to the dialog
      this.parent._setTitle(i18n.t('plans.planresp.dialog_persodeletecriteria_title'));
    }
    if (this.uc === 'planmed') {
      context = {
        onglet: "planmed",
        foncCour: "PER_PLANTOUS.G"
      }
    } else if (this.uc === 'tabservind') {
      context = {
        onglet: "tabservind",
        foncCour: "N"
      }
    } else {
      context = {
        onglet: "planresp",
        foncCour: "PER_PLANTOUS.G"
      }
    }
    this.habContext = new CWHabilitationContext(context);
    this.model = new CWBaseModel({});
    this.model.setHabContext(this.habContext);
    this.listenTo(this, "btnDelete", this._confirm);
    this.listenTo(this, "btnCancel", this._cancel);
  }

  render(): CWPersoDeleteCriteriaDialog {
    const json = { i18n: i18n, modePlanning: this.modePlanning, "habilitation_gerer": CWHABILITATION.canView("PER_PLANTOUS.G") };

    this.$el.append(this.template(json));
    this.$el.find("button").button();
    return this;
  }

  _confirm(): void {
    let deleteModel: any;
    let context: any;
    let optionsDel: any = {};

    if (this.uc === 'planmed') {
      deleteModel = new SauveGarderPlanmedPreference({});
    } else if (this.uc === 'tabservind') {
      deleteModel = new SauveGarderTabServicePreference({});
    } else if (_.isEqual(this.uc, "tabservcoll")) {
      deleteModel = new SauveGarderTabServiceCollPreference({});
    } else if (_.isEqual(this.uc, "tabgardes")) {
      deleteModel = new SauveGarderTabGardesPreference({});
    } else if (_.isEqual(this.uc, "plequipe")) {
      deleteModel = new SauveGarderPlequipePreference({});
    } else {
      deleteModel = new SauveGarderPlanrespPreference({});
    }
    deleteModel.set("id", ""); // ( dans la DCT il n'y a pas de ID, mais backbone needs an id.  )
    if (this.uc === 'planmed') {
      context = {
        onglet: "planmed",
        foncCour: "RES_PLMED",
        natGest: "S"
      }
    } else if (this.uc === 'tabservind') {
      context = {
        onglet: "tabservind",
        foncCour: "COL_TSIND",
        natGest: "S"
      }
    } else if (_.isEqual(this.uc, "tabservcoll")) {
      context = {
        onglet: "tabservcoll",
        foncCour: "COL_TSCOL",
        natGest: "S"
      }
    } else if (_.isEqual(this.uc, "tabgardes")) {
      context = {
        onglet: "tabgardes",
        foncCour: "COL_TGARDE",
        natGest: "S"
      }
    } else if (_.isEqual(this.uc, "plequipe")) {
      context = {
        onglet: "plequipe",
        foncCour: "COL_PLEQUIPE",
        natGest: "S"
      }
    } else {
      context = {
        onglet: "planresp",
        foncCour: "RES_GCOLLAB.G",
        natGest: "S"
      }
    }
    this.habContext = new CWHabilitationContext(context);
    deleteModel.setHabContext(this.habContext);
    optionsDel = {
      success: (): void => {
        CWLOG.debug("delete user preferences: OK");
        if (this.uc === "tabgardes") {
          this.workflow.preferences.fetch({ //vérifier
            success: () => { //(freshItem: any) => {              
              this.workflow.preferences.trigger("initializationAfterDeletePreference");
            }
          });
        }
        this._cancel();
      },
      error: (): void => {
        CWLOG.debug("delete Error");
        this._cancel();
      }
    };
    if (this.uc === 'planmed') {
      deleteModel.url = deleteModel.urlRoot() + "?medical";
    } else if (this.uc !== "tabgardes") {
      deleteModel.url = deleteModel.urlRoot();
    }
    if ((this.uc === 'planresp' || this.uc === 'planmed' || this.uc === 'plequipe' || this.uc === 'tabservind' || this.uc === 'tabservcoll') && this.workflow.prefUtilisateurExiste) {
      this.workflow.prefUtilisateurExiste.notFecth = false;
    }
    CWLOG.debug("delete : " + deleteModel.url);
    deleteModel.destroy(optionsDel); // end delete.
    this._cancel();
  }

  _cancel(): void {
    this.parent.close();
  }
}
