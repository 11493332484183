import * as Backbone from 'Backbone';
import _ from 'underscore';
import binfmemBriqueInfosMemosTPL from '../briqueInfosMemos.tpl.html';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWInfosMemosModel } from '../models/cwInfosMemos.model';
import { CWSTR } from 'utils/cwStr';
import { CWViserInfoMemosModel } from '../models/cwViserInfoMemos.model';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

/**
 * View of Brique 'Infos / Memos'
 */
export class CWInfosMemosView extends Backbone.View<Backbone.Model> {

  //private _maxLines: number = 3;

  usePopulation: boolean;
  template: (params: any) => string;
  habContext: CWHabilitationContext;
  model: any;
  parent: any;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "click .cw-infoMemo__viserInfo": "_setMessageView",
      "mouseenter .cw-infoMemo__viser": "_hoverVuIcon",
      "mouseleave .cw-infoMemo__viser": "_hoverVuIcon"
    }, options.events);
    super(options);
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = binfmemBriqueInfosMemosTPL;
    this.habContext = new CWHabilitationContext({
      onglet: "binfmem",
      foncCour: "COL_MESS.V", // eslint-disable-line
      natGest: ""  // eslint-disable-line
    })

    this.model = new CWInfosMemosModel();
    this.model.usePopulation = this.usePopulation;
    this.model.setHabContext(this.habContext);

    this.parent = null;

    if (options && options.parent) {
      this.parent = options.parent;

    }

  }

  render(): CWInfosMemosView {
    const json = { 'i18n': i18n };
    const lTextAucunMess = "<div style='padding:21px 0px 1px 0px'>" + i18n.t('common:binfmem.noMessageAffiche') + "</div>"

    this.$el.html(this.template(json));

    this.$el.find(".table_BriqueInfosMemos").html(lTextAucunMess);

    return this;
  }


  checkHabilation(): boolean {
    return CWHABILITATION.canView("COL_MESS.V")
  }

  refreshBrique(): CWInfosMemosView {
    const lTextAucunMess = "<div style='padding:21px 0px 1px 0px'>" + i18n.t('common:binfmem.noMessageAffiche') + "</div>"

    this.$el.attr("aria-busy", "true");

    this.$el.find(".table_BriqueInfosMemos").html(lTextAucunMess);

    if (CWHABILITATION.canView("COL_MESS.V")) {
      this.model.fetch({
        success: () => {
          this.renderGrid();
          this.$el.attr("aria-busy", "false");
        }
      });
    }
    this.delegateEvents();

    return this;

  }

  renderGrid(): void {
    const allColl = this.model.get("message");
    const coll = new Array<any>();

    _.each(allColl, (model: { [key: string]: any }): void => {
      const mesgen = model.genere; //MesNotifChgt
      const acquitte = model.acquitte; //MesNotifChgtVu

      if (mesgen === false || (mesgen === true && acquitte === false)) {
        coll.push(model);
      }

    });

    if (coll.length > 0) {
      //create the columns for the table
      const tblColumns = [
        { title: "Message", property: "message" }
      ];
      //create the grid witdh the collection data and collection titles
      const grid = this._createGridOfColl(coll, tblColumns, "tableBriqueInfosMemos");

      this.$el.find(".table_BriqueInfosMemos").html(grid);
      //create the tooltips
      const rows = this.$el.find(".table_BriqueInfosMemos #tableBriqueInfosMemos .row");
      for (let i = 0; i < coll.length; i++) {
        const rowModel = coll[i];
        if (rowModel.datedeb !== rowModel.datefin) {
          $(rows[i]).attr("title", rowModel.tooltip);
        }
      }
    }
  }

  _createGridOfColl(coll: Array<any>, tblColumns: Array<any>, id: string): string {
    const avertissementIcon = UTILS.getSVGIcon("valider", "", 16);
    let grid = "";
    grid += "<div id='" + id + "'>"
    for (let i = 0; i < coll.length; i++) {
      const mesgen = coll[i]["genere"]; //MesNotifChgt
      const acquitte = coll[i]["acquitte"]; //MesNotifChgtVu
      const messageVu = mesgen === true && acquitte === false && CWHABILITATION.canView("COL_MESS.V");

      if (mesgen === false || (mesgen === true && acquitte === false)) {
        grid += "<div class='row cw-info__row py-2";
        grid += messageVu ? " cw-infoMemo__viser" : "";
        grid += "'>";
        for (let j = 0; j < tblColumns.length; j++) {
          grid += "<div class='col-11 align-self-center cw-infoMemo__lib'>";
          grid += this._prepareMessageText(coll[i][tblColumns[j].property]);
          grid += "</div>";
          //Add icons
          //if (mesgen === true && acquitte === false && CWHABILITATION.canView("COL_MESS.V")) {
          grid += "<div class='col-1 align-self-center pl-0'>";
          grid += "<span ";
          grid += messageVu ? "" : "style='visibility:hidden' ";
          grid += "class='cw-infoMemo__viserInfo' title='" + i18n.t('common:binfmem.infobulle_message_vu') + "' data_code='" + coll[i].code + "'>" + avertissementIcon + "</span>";
          grid += "</div>";
          //}
        }
        grid += "</div>";
      }
    }
    grid += "</div>";
    return grid;
  }

  /*
   * concatenate an array of messages
   */
  _prepareMessageText(message: Array<string>): string {
    let messageText: string = "";

    for (let i = 0; i < message.length; i++) {
      if (!CWSTR.isBlank(message[i])) {
        messageText += message[i];
        messageText += !CWSTR.isBlank(message[i + 1]) ? " " : "";
      }
    }

    return messageText;
  }

  _setMessageView(event: any): void {
    const mesgen = this.model.attributes.message.find((x: { code: any }) => x.code === event.currentTarget.attributes.data_code.value).genere;
    const acquitte = this.model.attributes.message.find((x: { code: any }) => x.code === event.currentTarget.attributes.data_code.value).acquitte;

    if (mesgen === true && acquitte === false && CWHABILITATION.canView("COL_MESS.V")) {
      const target = event.currentTarget;
      const code = target.attributes.data_code.value;
      const messageSaveModel = new CWViserInfoMemosModel({ id: code }, {});

      messageSaveModel.setHabContext(this.model.getHabContext());
      messageSaveModel.save(null, {
        silent: true,
        success: () => {
          this.refreshBrique();
        }
      }, null);

    }
  }

  _hoverVuIcon(event: any): void {
    const target = event.currentTarget;
    if (event.type === "mouseenter") {
      const currTarget = $(target);
      currTarget.find(".cw-infoMemo__lib").addClass("cw-infoMemo__messVuLib");
      currTarget.find(".cw-infoMemo__viserInfo").addClass("cw-infoMemo__messVuViserInfo");
    } else if (event.type === "mouseleave") {
      this.$el.find(".cw-infoMemo__lib").removeClass("cw-infoMemo__messVuLib");
      this.$el.find(".cw-infoMemo__viserInfo").removeClass("cw-infoMemo__messVuViserInfo");
    }
  }
}