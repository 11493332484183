import _ from 'underscore';
import TPLCommonTableMainMenu from './cwTable_main_menu.tpl.html';
import { CWDataGridView } from '../data_grid/cwDataGrid.view';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { UTILS } from 'src/utils/utils';
import { View, ViewOptions } from 'Backbone';

export class CWMainChooserView extends View<CWReadOnlyModel> {

  public visible: Array<string>;
  public invisible: Array<string>;
  public dataGrid: CWDataGridView;
  public classPrefix: string;
  public menu: JQuery;
  public subMenuVues: JQuery;
  public switchBetweenViewOnClik: boolean;

  /**
   * View of the menu used to select a view for the table and to toggle the columns.
   */
  constructor(params: ViewOptions<CWReadOnlyModel> | any) {
    params.className = params.className ? params.className : "phx-grid-nemu ui-grid-th";
    params.classPrefix = params.classPrefix ? params.classPrefix : "phx-grid-menu-";
    super(params);
    this.template = TPLCommonTableMainMenu;
    this.model = new CWReadOnlyModel({
      defaults: {
        mode: ""
      }
    });

    this.dataGrid = params.dataGrid;
    this.switchBetweenViewOnClik = Object.keys(this.dataGrid.vues).length === 2 ? params.switchBetweenViewOnClik : false;

    /**
     * 
     */

    /**
     * Visible columns for this table view
     */
    this.visible = [];
    /**
     * Invisible columns for this table view
     */
    this.invisible = [];

    this.listenTo(this.dataGrid.model, "toggle:column", this._manageIcons);
    this.listenTo(this.dataGrid.model, "lock:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unlock:column", this._showMenuOption);
    this.listenTo(this.dataGrid.model, "block:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unblock:column", this._showMenuOption);
    this.listenTo(this.dataGrid.model, "buttonVueChanged:click", this._changeVueSelected);
    // common class prefix
    this.classPrefix = "phx-grid-menu-";

  }

  /**
   * Event when click on triangle to toggle menu.
   */
  /**
   * Event when click on a link to select the column.
   */
  /**
   * Event when click on a link to select a view.
   */
  /**
   * Event when click on a link to select a option in the main menu (selectionner vue o seleccionner column)
   */
  events(): { [key: string]: any } {
    return {
      "click .phx-hd-main-btn > .button": "_toggleMenu",
      "click .phx-hd-main-btn ul.phx-grid-vues-chooser li.ui-menu-item": "_selectVue"
    }
  }
  /**
   *
   * Manage the icon status of the menu to see what columns are displayed or not.
   */
  _manageIcons(columnName: string): void {
    this.dataGrid.currentVue._columns[columnName].radioOff = !this.dataGrid.currentVue._columns[columnName].radioOff;
  }

  /**
   * Show the column in the menu.
   */
  _showMenuOption(columnName: string): void {
    this.dataGrid.currentVue._columns[columnName].omitMenu = false;

  }

  /**
   * Hide a column in the menú.
   */
  _hideMenuOption(columnName: string): void {
    if (this.dataGrid.currentVue._columns[columnName]) {
      this.dataGrid.currentVue._columns[columnName].omitMenu = true;
    } else {
      LOG.warn("error, you try to hide the column without existing");
    }

  }
  /**
   * Initiallize visibility information
   */
  resetVisibility(): void {
    this.visible = [];
    this.invisible = [];
  }

  render(): CWMainChooserView {

    const dynamic = { text: "", "i18nCom": i18n, UTILS: UTILS };
    $(this.el).html(this.template(dynamic));
    $("button", this.el).button({
      icons: {
        primary: "ui-icon-triangle-1-s"
      },
      text: false
    });

    if (!this.switchBetweenViewOnClik) {
      this.menu = this._configureMenu();
      this.menu.hide();
    } else {
      this.$el.find('.phx-hd-main-btn').addClass("toggle-switch-view")
        .attr('title', i18n.t("common:grid.vue") + " " + Object.keys(this.dataGrid.vues)[1])
        .find('ul').remove();

    }

    return this;
  }

  /**
   * Add the columns to the menu list.
   */
  _configureMenu(): JQuery {
    const menu = $(".phx-hd-main-btn > ul", this.el);

    const subMenuVues = $("ul.phx-grid-vues-chooser", this.el);
    const vuesLength = Object.keys(this.dataGrid.vues).length;
    _.each(_.keys(this.dataGrid.vues), (key, index) => {
      if (index < vuesLength) {
        const li = $("<li class=\"" + this.classPrefix + key + "\"></li>");
        const a = $("<a></a>");
        let span;
        if (key !== this.dataGrid.currentVue.vueName) {
          span = $("<span></span>").addClass("c-panneauMenu__tickIcon");
        } else {
          span = $(UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon cw-icon--primary"));
        }
        a.append(span);
        let menuName = key;
        if (!CWSTR.isBlank(this.dataGrid.vues[key].menuName)) {
          menuName = this.dataGrid.vues[key].menuName;
        }

        a.append(menuName);

        li.append(a);
        subMenuVues.append(li);
      }
    }, this);
    this.subMenuVues = subMenuVues;

    menu.menu({ icons: { submenu: "none" } });
    menu.css("cursor", "pointer");
    //Delete blank space icons in menu
    const spanNone = menu.find("a.phx-grid-menu-selectionner-colonnes .ui-menu-icon.ui-icon.none");
    spanNone.removeClass("ui-menu-icon").removeClass("ui-icon").removeClass("none");
    const spanNoneVues = menu.find("a.phx-grid-menu-selectionner-vue .ui-menu-icon.ui-icon.none");
    spanNoneVues.removeClass("ui-menu-icon").removeClass("ui-icon").removeClass("none");

    return menu;
  }

  _switchView(): void {
    const vues = this.dataGrid.vues;
    const currentIndex = Object.keys(vues).indexOf(this.dataGrid.currentVue.vueName);

    switch (currentIndex) {
      case 0:
        this._changeVueSelected(Object.keys(vues)[1])
        break;
      case 1:
        this._changeVueSelected(Object.keys(vues)[0])
        break;
      default:
        throw new Error("Must defined 2 views, no more or less to use this behavior");
    }
  }
  /**
   * Select an option
   */
  _selectOption(event: JQueryEventObject): void {
    event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");
  }
  /**
   * Select a column
   */
  _selectColumn(event: JQueryEventObject): void {
    const columnId = event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");
    // If there are only one visible column and try to click it the event is not triggered.
    if (this.visible.length > 1 || _.indexOf(this.visible, columnId) === -1) {
      const indexOf = _.indexOf(this.invisible, columnId);
      if (indexOf === -1) {
        this.invisible.push(columnId);
        this.visible.splice(_.indexOf(this.visible, columnId), 1);
      } else {
        this.visible.push(columnId);
        this.invisible.splice(_.indexOf(this.invisible, columnId), 1);
      }
      this.dataGrid.model.trigger("toggle:column", columnId);
      this._toggleMenu();
    }

  }
  /**
   * Select a view
   */
  _selectVue(event: JQueryEventObject): void {
    const vueName = event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");
    this._toggleMenu();
    this._changeVueSelected(vueName);
  }

  _changeVueSelected(vueName: string): void {
    // If there are only one visible column and try to click it the event is not triggered.
    const keysList = Object.keys(this.dataGrid.vues);
    const vueTitle = keysList[(keysList.indexOf(vueName) + 1) % 2]
    this.dataGrid.model.trigger("change:currentVue", vueName);
    this._manageVueIcons(vueName);
    if (this.switchBetweenViewOnClik) {
      this.$el.find('.phx-hd-main-btn').attr('title', i18n.t("common:grid.vue") + " " + vueTitle);
    }
  }
  /**
   *
   * Manage the icon status of the menu of Views.
   */
  _manageVueIcons(vueName: string): void {
    const menuIconClassname = "c-panneauMenu__tickIcon";
    const $emptyIcon = $("<span>").addClass(menuIconClassname);
    const $tickIcon = $(UTILS.getSVGIcon("valider", menuIconClassname + " cw-icon--primary"));

    if (!CWSTR.isBlank(this.dataGrid.vues)) {
      const keys = _.keys(this.dataGrid.vues);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!CWSTR.isBlank(this.subMenuVues)) {
          this.subMenuVues.find("." + this.classPrefix + key.replace(".", "\\.") + " ." + menuIconClassname).replaceWith($emptyIcon.clone());
        } else {
          $(this.el).find(".phx-grid-menu-" + key.replace(".", "\\.") + " ." + menuIconClassname).replaceWith($emptyIcon.clone());
        }
      }

      if (CWSTR.isBlank(this.subMenuVues)) {
        $(this.el).find(".phx-grid-menu-" + vueName.replace(".", "\\.") + " ." + menuIconClassname).replaceWith($tickIcon.clone());
      } else {
        this.subMenuVues.find("." + this.classPrefix + vueName.replace(".", "\\.") + " ." + menuIconClassname).replaceWith($tickIcon.clone());
      }

    }
  }

  /**
   * Opens or Closes the menú.
   */
  _toggleMenu(): void {
    if (!this.switchBetweenViewOnClik) {
      if (this.menu.is(":visible")) {
        this.menu.hide();
      } else {
        const targetEl = $(this.el);
        this.menu.show().position({
          my: "right top",
          at: "bottom",
          of: targetEl
        });
        $(document).one("mousedown", (event) => {
          const element = $(targetEl).find(event.target as any);
          if (element.length === 0) {
            // mousedown out of the button
            this._toggleMenu();
          }
        });
      }
    } else {
      this._switchView();
    }
  }

  remove(): CWMainChooserView {
    this.dataGrid = null;

    const visibleLength = this.visible.length;
    this.visible.splice(0, visibleLength);
    delete this.visible;

    const invisibleLength = this.invisible.length;
    this.invisible.splice(0, invisibleLength);
    delete this.invisible;

    View.prototype.remove.call(this);
    this.$el.empty();
    return this;
  }
}
