module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="cw-fieldset cw-fileTranfer">\n    <form method="post" enctype="multipart/form-data" accept-charset="utf-8">\n        <div class="form-group mb-4">\n            <label class="cw-libelleEnrichi cw-required">'+
((__t=(UTILS.getSVGIcon("trombone", "", 16, null)))==null?'':__t)+
' '+
((__t=(i18n.t("common:uploadfile.fileSelectionLabel")))==null?'':__t)+
'</label>\n            <div class="more-input-files"></div>\n        </div>\n        <div class="form-group mb-4">\n            <label class="cw-libelleEnrichi">'+
((__t=(UTILS.getSVGIcon("bulle", "", 16, null)))==null?'':__t)+
' '+
((__t=(i18n.t("common:uploadfile.descriptionLabel")))==null?'':__t)+
'</label>\n            <textarea type="textarea" class="file-transfer-description form-control" name="description" value="" rows="4" tabindex="0"></textarea>\n        </div>\n        <div class="fil_transfer_btnBar"></div>\n        <span class="file-transfer-label"></span>\n        <input type="hidden" name="type_utilisation" value="" />\n        <input type="hidden" name="id_entite" value="" />\n    </form>\n</fieldset>';
}
return __p;
};
