import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * The model of Deplacer Compteur.
 */
export class CWDeplacerCompteursModel extends CWBaseModel {

  userid: string;
  codebrique: string;
  ordre: number;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      let url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/";

      url += encodeURIComponent(this.userid + "," + this.codebrique);
      url += "/detail/" + encodeURIComponent(this.id);
      url += "/ordre/" + encodeURIComponent(this.ordre);
      return url;
    }
  }
}