module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="table_BriqueAujourdhui">\r\n    <div class="row no-gutters">\r\n        <div class="col-6 align-self-center">\r\n            <div class="cw-badgeages cw-badgeages--noMessage">'+
((__t=(i18n.t('common:aujourdhui.noMessageBadger')))==null?'':__t)+
'</div>\r\n        </div>\r\n        <div class="col-6 cw-buttons-badger">  \r\n            <button type="button" aria-label="'+
((__t=(i18n.t('common:ARIA_label.other_options')))==null?'':__t)+
'" class="cw-btn-points btn btn-secondary">'+
((__t=(UTILS.getSVGIcon('trois_points','',16)))==null?'':__t)+
'</button> \r\n        </div>\r\n    </div>\r\n</div>';
}
return __p;
};
