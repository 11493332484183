import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSearchListeGroupesModel } from './cwSearchListeGroupes.model';


/**
 * Collection of SearchListeGroupes
 */
export class CWSearchListeGroupesColl<CWSearchListeGroupesModel> extends CWPaginatedCollection<CWBaseModel>{

  typegroupe: any;

  constructor(models?: CWSearchListeGroupesModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWSearchListeGroupesModel;
    super(models, options);
    this.typegroupe = options.typegroupe;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/resultatsgroupecpt/groupes/" + encodeURIComponent(this.typegroupe);
    }
  }
}