import { DIVERS } from './divers.js';
import { GLOBAL_DATA } from 'src/globalData';
import { HABILITATION } from './habilitation.js';
import { STR } from 'utils/str';
/**
 * Profile to switch between habs groups
 */

export var PROFILE = {

  profileManagementEnabled: null,

  checkHabilitations: function(habArr) {
    return _.every(habArr, HABILITATION.canView, HABILITATION);
  },

  checkProfile: function() {
    if (STR.isBlank(this.profileManagementEnabled)) {
      var diver = (DIVERS.get("prfActiv") === "1");
      var habilitation = HABILITATION.canView("UTI_SELPROFCOUR");
      var profileNumber = GLOBAL_DATA.profiles.profilesNumber() > 0;

      this.profileManagementEnabled = diver && habilitation && profileNumber;
    }
    return this.profileManagementEnabled;
  }
};
