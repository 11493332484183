import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

export class CWRefuserRecap extends CWBaseModel {


  constructor(options?: { [key: string]: any }) {
    super(options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/refuser/"
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "commentaire": "",
      "droit": "",
      "profil": ""
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } | null {
    const errors: { [key: string]: any } = {};

    if (CWSTR.isBlank(attrs.commentaire)) {
      errors.commentaire = i18n.t('messages:GT_1103');
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}
