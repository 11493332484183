import _ from 'underscore';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Population model
 */
export class CWPopulationSelectColaborateursModel extends CWReadOnlyModel {

  defaults(): { [key: string]: any } {
    return {
      "matric": null
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: any = {};

    if (CWSTR.isBlank(attrs.matric)) {
      errors.nom = i18n.t('common:required', { "0": i18n.t('populations.collaborateurs_collaborateur') });
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.ident)) {
      this.id = response.ident;
    }
    return response;
  }
}