module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="row">\n    <div class="roleSelectField col d-none">\n    </div>\n    <div class="cmbLibelle col d-none">\n    </div>\n    <div class="col input-group collabData">\n        <input name="searchcollab" class="searchcollab form-control" type="text" data-attributes="matricule,matriculeaux,nom,prenom" placeholder="'+
((__t=(i18n.t("common:listerecap.filtre_nom")))==null?'':__t)+
', '+
((__t=(i18n.t("common:listerecap.filtre_prenom")))==null?'':__t)+
', '+
((__t=(i18n.t("common:listerecap.filtre_matricule")))==null?'':__t)+
'">\n        <div class="loupe input-group-append cw-texteTresAllege">\n            <label class="input-group-text">'+
((__t=(icon))==null?'':__t)+
'</label>\n        </div>\n    </div>\n</div>\n';
}
return __p;
};
