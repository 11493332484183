import _ from 'underscore';
import TPLListerpretsHeaderPopup from '../headerPopup.tpl.html';
import { CWAccepterPret } from '../models/cwAccepterPret';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWRefuserPret } from '../models/cwRefuserPret';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


export class CWHeaderPopupView extends CWBaseFormView {

  model: CWBaseModel;
  action: string;
  commentaire: any;
  isForced: boolean;
  parent: any;
  commentRequired: boolean;

  constructor(options: any) {
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_enableButtons",
      "click :input.isForced": "_change",
    });
    super(options);
    this.template = TPLListerpretsHeaderPopup;

    this.model = new CWBaseModel({
      value: null //new BaseModel({"commentaire" : "", "isForced" : false})
    });

    this.commentRequired = CWSTR.isBlank(options.commentRequired) ? false : options.commentRequired;


    if (options.action) {
      this.action = options.action;
      if (this.action === "A") {
        this.model.set("value", new CWAccepterPret());
      } else if (this.action === "R") {
        this.model.set("value", new CWRefuserPret());
      } else if (this.action === "C") {
        if (this.commentRequired) {
          this.model.set("value", new CWRefuserPret());
        } else {
          this.model.set("value", new CWAccepterPret());
        }
      }
    } else {
      this.model.set("value", new CWAccepterPret());//should be dummy
    }

    this.model.get("value").on("change", this._mapToForm, this);

    this.commentaire = CWSTR.isBlank(options.commentaire) ? "" : options.commentaire;
    this.isForced = CWSTR.isBlank(options.isForced) ? "" : options.isForced;

    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }
    this.listenTo(this, "btnResult", this._clickListener);

  }

  render(): CWHeaderPopupView {
    const json = { "i18n": i18n, UTILS: UTILS };
    const fieldset = this.$el;
    this.$el.append(this.template(json));

    //Initialize the comment textarea with the previous comment and the forced checkbox.
    this.model.get("value").isForced = this.isForced;
    CWSTR.setElValue(this.model.get("value"), "commentaire", this.commentaire);

    if (this.action === "R" || this.commentRequired) {
      $(this.el).find("fieldset.listerprets-dialogPopupHeader label[for=commentaire], fieldset.listerprets-dialogPopupHeader label[for^=commentaire_uid_]").addClass("cw-required");
    }

    //Disable the buttons
    $(this.el).find("fieldset.listerprets-dialogPopupHeader button").button({ disabled: true });
    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }
    this._mapModelToForm(fieldset, this.model); //RGAA - needed to show "(*) : champ obligatoire"

    return this;
  }

  _change(event: any): void {
    const element = event.currentTarget;

    //MapCheckBoxToModel
    if (element.className.split(' ')[0] === "isForced") {
      this.model.get("value").isForced = element.checked;
    } else {
      super._change(event);
    }

    // Clear current validation erros
    this._cleanValidationErrors();
  }

  _enableButtons(): void {
    $(this.el).find("fieldset.listerprets-dialogPopupHeader button").button({ disabled: false });
  }

  _mapToForm(): void {
    const model = this.model.get("value");
    const fieldset = this.$el.find("fieldset");

    this._mapModelToForm(fieldset, model, null);

    $(this.el).find("fieldset.listerprets-dialogPopupHeader input.isForced").prop("checked", model.isForced);
  }

  _clickListener(btnClick: any): void {
    const model = this.model.get("value");
    const commentaire = CWSTR.getElValue(model, "commentaire");
    const isForced = model.isForced;

    switch (btnClick) {
      case "btnValider":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);

        if (model.isValid()) {
          this.parent.model.set("globalComment", commentaire);
          this.parent.model.set("isForced", isForced);
          if (commentaire !== this.commentaire) {//if the comment change...
            this.parent.model.set("isCommentChanged", true);
          }
          this.parent.close();
        }
        break;
      case "btnRevert":
        model.isForced = this.isForced;
        this.parent.close();
        break;
      default:
        break;
    }
  }
}
