import { STR } from './str.js';

/**
 * Utilities to manage the combos.
 */
export var COMBOS = {

  /**
   * Transforms an object returned by WS into an array that can be used by combo component
   */
  manageComboData: function(attrs) {
    var result = [];
    if (!STR.isBlank(attrs)) {
      for (var i = 0; i < Object.keys(attrs).length; i++) {
        if (STR.isBlank(attrs[i])) {
          break;
        }
        result.push(attrs[i]);
      }
    }
    return result;
  }

};
