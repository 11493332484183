import { CWBaseModel } from 'src/core/models/cwBase.model';

export class CWDummyEvtGen extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "commentaire": "",
      "droit": "",
      "profil": ""
    }
  }
}