import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLSelecteurActiviteFilter from '../cwSelecteurActiviteFilter.tpl.html';
import { CompleteWFL } from '../models/completeWFL.js';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDialogView } from './cwDialog.view';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';

export class CWFilterContentView extends CWBaseFormView<CWFilterBaseModel> {

  workflow: typeof CompleteWFL;

  /**
   * Constructor
   * FilterView for this usecase
   */
  constructor(options: Backbone.ViewOptions | any) {

    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, CWTYPE.SHORT.events(), options.events);

    options.className = "form-row";
    super(options);

    this.template = TPLSelecteurActiviteFilter;
    this.model = new CWBaseModel() as CWFilterBaseModel;
    if (options && options.habContext) {
      this.habContext = options.habContext;
    }
    if (options && options.workflow) {
      this.workflow = options.workflow;
     // this.workflow.on("updateFilterFields", this._renderHierTypeFilterFields, this);
    }
  }

  _getModel(): CWFilterBaseModel {
    return this.model;
  }

  render(): CWFilterContentView {
    const json = { 'i18n': i18n };
    this.$el.append(this.template(json));

    this._renderHierTypeFilterFields();

    return this;
  }

  _renderHierTypeFilterFields(receiver?: CWDialogView): void {
    let context: { [key: string]: any } = null;
    const container = $(".hierTypeFilterFields", this.$el);
    let domaineDetail = null;
    let hierTypeNiveaux = null;
    if (receiver && !CWSTR.isBlank(receiver.activiteTreesView)) {
      context = receiver.activiteTreesView.workflow.context;
      domaineDetail = receiver.activiteTreesView.workflow.domainData;
      hierTypeNiveaux = receiver.activiteTreesView.workflow.hierarchie;
    } else {
      context = this.workflow.context;
      domaineDetail = this.workflow.domColl.models[0];
      hierTypeNiveaux = this.workflow.niveauxTypeHierarchy;
    }
    container.removeClass("d-none");
    if (!CWSTR.isBlank(hierTypeNiveaux)) {
      hierTypeNiveaux.filter((item: { [key: string]: any }) => {
        if (context && context.ctxTypeContenu === "R") {
          return item.get("rechref") === true;
        } else if (context && context.ctxTypeContenu === "E") {
          return item.get("rechrexp") === true;
        }
        return item;
      });
    }
    container.empty();

    if (domaineDetail && domaineDetail.get("hiertype") && domaineDetail.get("hiertype").code) {
      const hierTypeLibelle = domaineDetail.get("hiertype").libelle;
      const hierTypeCode = domaineDetail.get("hiertype").code;
      const hierTypeTitle = hierTypeLibelle + " (" + hierTypeCode + ")";
      const hierTypeFieldset = $("<legend>").append(hierTypeTitle);

      if (hierTypeNiveaux.length > 0) {
        $(container)
          .append(hierTypeFieldset);
        hierTypeNiveaux
          .forEach((item: { [key: string]: any }) => {
            const labelDiv = $("<div>").append(item.get("libelle")).addClass("form-group cw-texteSuperAllege");
            const labelCode = $("<label>").append(i18n.t('activite.code')).attr("for", item.get("niveau") + "\.code"); //eslint-disable-line
            const inputCode = $("<input type='text'>").addClass(item.get("niveau") + "\.code");  //eslint-disable-line
            const inputLibelle = $("<input type='text'>").addClass(item.get("niveau") + "\.libelle"); //eslint-disable-line
            const labelLibelle = $("<label>").append(i18n.t('activite.libelle')).attr("for", item.get("niveau") + "\.libelle"); //eslint-disable-line
            const niveauContainer = $("<div>").addClass("filter-niveaux-item form-row");
            $(container).append(labelDiv);

            const codeGroup = $("<div class='form-group col-3'>");
            $(inputCode).addClass("form-control");
            $(codeGroup).append(labelCode);
            $(codeGroup).append(inputCode);
            $(niveauContainer).append(codeGroup);

            const libelleGroup = $("<div class='form-group col-9'>");
            $(inputLibelle).addClass("form-control");
            $(libelleGroup).append(labelLibelle);
            $(libelleGroup).append(inputLibelle);
            $(niveauContainer).append(libelleGroup);

            $(container).append(niveauContainer);
          });
      }
    }
  }
}
