import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLSelecteurActiviteFilterMore from '../cwSelecteurActiviteFilterMore.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWSelectFamilleActiviteView } from 'common/referentiel/referentielFamilleActivite/views/cwSelectFamilleActivite.view';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';


export class CWFilterMoreContentView extends CWBaseFormView<CWFilterBaseModel> {

  constructor(options: Backbone.ViewOptions | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, options.events);
    super(options);
    this.template = TPLSelecteurActiviteFilterMore;
    this.model = new CWBaseModel() as CWFilterBaseModel;
    this.habContext = options.habContext;
  }

  /**
   * Gets the model
   **/
  _getModel(): CWFilterBaseModel {
    return this.model;
  }

  /**
   * Renders this view
   */
  render(): CWFilterMoreContentView {
    const json = { 'i18n': i18n };
    const context = {
      Habilitation: this.habContext ? this.habContext.get("foncCour") : "",
      ctxEcran: this.habContext ? this.habContext.get("onglet") : "",
      ctxNature: ""
    };
    let referentielFamilleAct: CWSelectFamilleActiviteView = null;

    this.$el.html(this.template(json));
    // Selecteur référentiel Famille d'activité
    referentielFamilleAct = new CWSelectFamilleActiviteView({ "host": this, "context": context, "idComponent": "cw-activite_refFamilleAct_selAct", "required": true, "fieldWidth": 265 });
    this.$el.find(".selecteurFamille").html(referentielFamilleAct.render().el);
    return this;
  }

  _responseCallbackFamilleActivite(data: { [key: string]: any }): void {
    const model = this._getModel();
    const lCode = (!_.isEmpty(data) && !CWSTR.isBlank(data.code)) ? data.code : "";

    model.set("famille", lCode);
  }
}
