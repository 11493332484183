import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Model to decline a recap
 */
export class CWRefuserRecap extends CWBaseModel {
  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/composant/recapactivite/refuser/";
    };
  }

  defaults(): { [key: string]: any } {
    return {
      "commentaire": "",
      "droit": "",
      "profil": ""
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { commentaire?: string } = {};

    if (CWSTR.isBlank(attrs.commentaire)) {
      errors.commentaire = i18n.t('messages:GT_1103');
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
}
