import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLBempruntBriqueMesEmprunts from '../briqueMesEmprunts.tpl.html';
import { CWFormView } from 'common/evenements/lister/listerprets/views/cwForm.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWPortletView } from 'src/core/components/brique/cwPortlet.view';
import { CWPretColl } from 'common/evenements/lister/listerprets/models/cwPret.collection';
import { CWPretModel } from 'common/evenements/lister/listerprets/models/cwPret.model';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'utils/sync.js';

/**
 * View of Brique 'Liste des Emprunts'
 */
export class CWMesEmpruntsView extends Backbone.View<Backbone.Model>{

  briqueToday: string;
  mesEmpruntsContext: { [key: string]: any };
  usePopulation: boolean;
  parent: CWPortletView;
  context: { [key: string]: any };
  tableMesEmprunts: CWFormView;
  mesEmpruntsColl: CWPretColl;
  collAllMesEmprunts: Array<CWPretModel>;
  filterEnCours: boolean;
  filterAcceptes: boolean;
  filterRefuse: boolean;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .cw-emprunt-etiq": "_manageCheckboxChanges"
    }, options.events);
    super(options);
    this.briqueToday = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), CWTYPE.DATE.DEFAULT_MASK_BBDD);
    this.template = TPLBempruntBriqueMesEmprunts;
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.parent = null;
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.context = null;
    if (options && options.context) {
      this.context = options.context;
    }
    // Sets the context for listerPrets
    this.initContext();
    this.tableMesEmprunts = new CWFormView({ "context": this.mesEmpruntsContext });
    this.tableMesEmprunts.usePopulation = this.usePopulation;
    this.mesEmpruntsColl = this.tableMesEmprunts.table.model.coll;
  }

  render(): CWMesEmpruntsView {
    const json = { 'i18n': i18n };

    $(this.el).html(this.template(json));
    return this;
  }

  /*
   * Reads checkboxs states from filter statut
   */
  _manageCheckboxChanges(event: JQueryEventObject): void {
    const filtreClass = $(event.currentTarget).attr("class");
    let actif = false;

    if (filtreClass.indexOf("cw-emprunt-etiqEnCours") > 0) {
      this.filterEnCours = !this.filterEnCours;
      actif = this.filterEnCours;
    } else if (filtreClass.indexOf("cw-emprunt-etiqAccepte") > 0) {
      this.filterAcceptes = !this.filterAcceptes;
      actif = this.filterAcceptes;
    } else if (filtreClass.indexOf("cw-emprunt-etiqRefuse") > 0) {
      this.filterRefuse = !this.filterRefuse;
      actif = this.filterRefuse;
    }
    if (actif) {
      $(event.currentTarget).removeClass("cw-emprunt-inactif");
    } else {
      $(event.currentTarget).addClass("cw-emprunt-inactif");
    }
    this._filterEmpruntsCollection();
  }
  /*
   * Set checkboxes initial states
   */
  _resetCheckboxes(): void {
    this.filterEnCours = true;
    this.filterAcceptes = true;
    this.filterRefuse = true;
    this.$el.find(".cw-emprunt-etiq").removeClass("cw-emprunt-inactif");
  }
  /*
   *  Filters the collection used for listePrets
   */
  _filterEmpruntsCollection(): void {
    const filterStatut: any = [];
    let filteredMesEmpruntModels: any = null;

    if (this.filterEnCours === true) {
      filterStatut.push("D", "I", "T")
    }
    if (this.filterAcceptes === true) {
      filterStatut.push("A", "H")
    }
    if (this.filterRefuse === true) {
      filterStatut.push("R")
    }
    filteredMesEmpruntModels = this.collAllMesEmprunts.filter((model: CWPretModel) => {
      return filterStatut.indexOf(model.get('statut').code) > -1;
    });
    this.$el.find(".table_BriqueMesEmprunts").html(this._createGrid(filteredMesEmpruntModels));
  }


  checkHabilation(): boolean {
    return CWHABILITATION.canView("RES_PRET.V")
  }
  /*
   * Preserves the complete collection of prets
   * Adjust the size of the brique
   */
  refreshBrique(): void {

    this._resetCheckboxes();
    // Gets empunts table empunts

    if (CWHABILITATION.canView("RES_PRET.V")) {
      this.mesEmpruntsColl.fetch({
        success: (fresh: any) => {
          if (fresh.length > 0) {
            this.$el.find("#bemprunt").removeClass('cw-aucunSit');
            this.collAllMesEmprunts = fresh.models;
            this.$el.find(".table_BriqueMesEmprunts").html(this._createGrid(fresh.models));
          } else {
            this.$el.find("#bemprunt").html(i18n.t('common:bemprunt.noMessageAffiche'));
            this.$el.find("#bemprunt").addClass('cw-aucunSit');
          }
        },
      });
    }

    this.$el.parents(".phx-portlet-div").css({
      "padding": "0px 0px 0px 2px",
      "width": "calc(100% - 4px)"
    });
  }

  _createGrid(emprunts: Array<CWPretModel>): any {
    const $grid = $("<div>");

    for (let i = 0; i < emprunts.length; i++) {
      const $row = $("<div class='row no-gutters'>");
      const $collab = $("<div class='col-4 align-self-center'>");
      const $date = $("<div class='col-7 align-self-center'>");
      const dateDeb = emprunts[i].get("datedeb");
      const uniteDeb = emprunts[i].get("unitedeb").code !== "D" ? emprunts[i].get("unitedeb").libelle : "";
      const dateFin = emprunts[i].get("datefin");
      const uniteFin = emprunts[i].get("unitefin").code !== "D" ? emprunts[i].get("unitefin").libelle : "";
      let dateFormat = CWTYPE.DATE.format(dateDeb, CWTYPE._getFormatByCode("DATE_A")) + " " + uniteDeb;
      const $status = $("<div class='col-1 align-self-center'>");

      $collab.append($("<span class='cw-texteNormal'>").append(emprunts[i].get("collaborateur").nom + " " + emprunts[i].get("collaborateur").prenom));
      $collab.prop('title', this._getTitle(emprunts[i].get("structures")[0]));
      if (dateDeb !== dateFin) {
        dateFormat += " - " + CWTYPE.DATE.format(dateFin, CWTYPE._getFormatByCode("DATE_A")) + " " + uniteFin;
      }
      $date.append($("<span class='cw-texteAllege'>").append(dateFormat));
      $status.append($("<div class='cw-emprunt__" + this._getStatus(emprunts[i]) + "'>"));
      $row.append($collab);
      $row.append($date);
      $row.append($status);
      $grid.append($row);
    }
    return $grid;
  }

  private _getTitle(obj: { [key: string]: any }): string {

    let title: string = "";

    switch (obj.format) {
      case 1: // libellé (code)
        title = obj.itemlib + " (" + obj.itemcode + ")";
        break;
      case 2: // code (libellé)
        title = obj.itemcode + " (" + obj.itemlib + ")";
        break;
      case 3: // libellé
        title = obj.itemlib;
        break;
      case 4: // code
        title = obj.itemcode;
        break;
    }

    return title;
  }

  _getStatus(emprunt: CWPretModel): string {
    switch (emprunt.get("etat")) {
      case "D":
        return "enCours";
      case "I":
        return "enCours";
      case "T":
        return "enCours";
      case "A":
        return "accepte";
      case "H":
        return "accepte";
      case "R":
        return "refuse";
      default:
      //Nothing
    }
    return "";
  }

  initContext(): void {
    this.mesEmpruntsContext = {};
    this.mesEmpruntsContext.ctxUtilisateur = "Responsable";
    this.mesEmpruntsContext.ctxHabilitation = {};
    this.mesEmpruntsContext.ctxHabilitation.HabilitationAcces = "RES_PRET.V";
    this.mesEmpruntsContext.ctxHabilitation.HabilitationValidation = "RES_VAL_PRET.G";
    this.mesEmpruntsContext.ctxCollab = null;
    this.mesEmpruntsContext.ctxGestionCollective = true;
    this.mesEmpruntsContext.ctxValidation = false;
    this.mesEmpruntsContext.ctxValideur = false;
    this.mesEmpruntsContext.ctxFiltreApplique = ["A", "D", "I", "T", "R", "H"]; //"A"=accepte "D"=demande "H"=hors validation "I"=en cours "R"=refusé "T"=demande
    this.mesEmpruntsContext.ctxVisuStatut = true;
    this.mesEmpruntsContext.ctxEncoursOuAVenir = true;
    this.mesEmpruntsContext.ctxPeriode = {};
    this.mesEmpruntsContext.ctxPeriode.datedeb = this.briqueToday;
    this.mesEmpruntsContext.ctxPeriode.datefin = this.briqueToday;
    this.mesEmpruntsContext.ctxTriDate = "Ascendant";
    this.mesEmpruntsContext.ctxPretsID = [];
    this.mesEmpruntsContext.ctxPretRecus = true;
    this.mesEmpruntsContext.ctxTypePret = "EMPRUNTS";
    this.mesEmpruntsContext.ctxRestitutionPrets = "Regroupee";
    this.mesEmpruntsContext.ctxPresentationGrille = "Neutralisee";
    this.mesEmpruntsContext.habContext = new CWHabilitationContext({
      "onglet": "bemprunt",
      "foncCour": { "acces": "RES_PRET.V", "validation": "RES_VAL_PRET.G" },
      "natGest": ""
    });
  }
}
