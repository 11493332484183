import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLAppTabsheet from '../tabsheet.tpl.html';
import TPLAppTabsheetItem from '../tabsheet_item.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWHomeView } from './cwHome.view';
import { CWMSGS } from 'utils/cwMsgs';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { NAV } from 'utils/nav.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

/**
 * Tabsheet View, usecase tabs loading and closing
 */
export class CWTabSheetView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<CWBaseModel> {
  templateItem: any;
  zJSON: any;
  _ucStorage: { [key: string]: any };
  _openTabs: string[];
  home: any;
  ready: boolean;
  tabSelector: JQuery;

  // Stores the level of every uc in the tree. See _optionChange
  /*_ucStorage: undefined,
  _openTabs: undefined,*/

  constructor(options?: Backbone.ViewOptions<TModel> | any) {
    options.events = {
      "click .ui-tabs-nav span.ctime-tab-close": "_clickCross",
      "click .ui-tabs-nav span.cw-tab-plein-ecran": "_clickReturn",
      "mousedown .ui-tabs-nav a": "_clickOnTab"
    };
    super(options);
    this.template = TPLAppTabsheet;
    this.templateItem = TPLAppTabsheetItem;
    this.zJSON = options.zJSON;

    //Calculate and store every uc in the zone
    this._ucStorage = {};
    this._openTabs = [""];
    _.each(this.zJSON.menus, (menu: { [key: string]: any }): void => {
      if (this.zJSON.id === "resp" && (menu.id === "agenda" || menu.id === "planact")) {
        menu.target = menu.id;
      }
      if (menu.target) {
        this._ucStorage[menu.target] = menu;
      }
      _.each(menu.ecrans, (uc: { [key: string]: any }): void => {
        if (uc.target) {
          this._ucStorage[uc.target] = uc;
        } else if (!CWSTR.isBlank(uc.id)) {
          this._ucStorage[uc.id] = uc;
        }
        _.each(uc.ecrans, (uc2: { [key: string]: any }): void => {
          if (uc2.target) {
            this._ucStorage[uc2.target] = uc2;
          }
        }, this);

      }, this);
    }, this);
    this.model.on("change:usecase", this._usecaseChanged, this);
    this.model.on("destroy:usecase", this._usecaseDestroyed, this);
    // Home view
    this.home = null;
    this.ready = false;
  }

  _clickCross(event: JQueryEventObject): boolean {
    const itemId: string = event.currentTarget.className.split(" ")[0];

    if ((UTILS.usecase_loading_counter as any)[itemId] > 0 || (UTILS.usecase_loading_counter as any)[itemId + "ElementHidder"] > 0) {
      //il y a des demandes pendantes
      return false;
    }
    this.model.trigger("predestroy:usecase", itemId);
    return true;
  }

  _clickReturn(): void {
    const currentMode = this.model.get("fullscreen");
    let lsIcone = "";

    this.model.set("fullscreen", !currentMode);
    if (!currentMode) {
      lsIcone = "plein_ecran_sortie";
    } else {
      lsIcone = "plein_ecran";
    }
    this.$el.find(".cw-tab-plein-ecran svg").remove();
    this.$el.find(".cw-tab-plein-ecran").append(UTILS.getSVGIcon(lsIcone, 'cw-icon-24', 24));
  }

  _clickOnTab(event: JQueryEventObject): boolean {
    event.preventDefault();
    event.stopPropagation();
    event.returnValue = false;
    event.cancelBubble = true;

    const hasCloseIcon = $(event.target).parent().find(".ctime-tab-close");
    if (event.button === 1 && hasCloseIcon.length > 0) {
      const itemId = hasCloseIcon[0].className.split(" ")[0];
      this.model.trigger("predestroy:usecase", itemId);
    }
    return false;
  }

  _usecaseChanged(): void {
    const self = this;//eslint-disable-line
    let ucid = "";

    // Be careful, all the TabSheets are hearing the same model
    if (this.zJSON["id"] !== this.model.get("zone")) {
      return;
    }
    ucid = this.model.get("usecase");
    const baseModule = ucid.split("_")[0];
    if (this._openTabs.indexOf(ucid) === -1) {
      if (CWSTR.isBlank(this._ucStorage[baseModule])) {
        return;
      }
      this._createTab(this._ucStorage[baseModule], ucid);
    }
    const tabNumber = _.indexOf(this._openTabs, ucid);
    const lbHome = (!ucid) ? true : false;
    this.home.model.menuView._toogleMenu(lbHome);
    this.tabSelector.tabs("option", "active", tabNumber);
    const zone = this.model.get("zone");
    if (!ucid) {
      ucid = "home" + zone;
      this.model.trigger("change:usecaseTitle", this._ucStorage[zone].nom);
    } else {
      this.model.trigger("change:usecaseTitle", this._ucStorage[baseModule].onglet)
    }
    if (this.zJSON.id === zone) {
      const selectedTab = this.$el.find("ul.ui-tabs-nav li[aria-controls=" + ucid + "]");

      if (selectedTab.prop("reload")) {
        if (ucid.indexOf("home") === 0) {
          this.model.trigger("reload:brique");
        } else {
          this.model.trigger("predestroy:usecase", ucid, true, null, (): void => {
            self.model.trigger("changed:usecase", ucid);
          });
        }
        CWMSGS.showInfo(i18n.t('messages:GL_1072'));
        selectedTab.prop("reload", false);
      } else {
        this.model.trigger("changed:usecase", ucid);
      }
    }
  }

  _usecaseDestroyed(ucid: string, omitCloseTab: boolean, omittedCheck: boolean): void {
    if (omitCloseTab !== true) {
      // Be careful, all the TabSheets are hearing the same model
      if (this.zJSON["id"] !== this.model.get("zone") && !omittedCheck) {
        return;
      }
      this._destroyTab(ucid, omittedCheck);
    }
  }

  _selectTab(event: JQueryEventObject, ui: JQueryUI.TabsActivationUIParams): void {
    let itemId = ui.newTab.attr("aria-controls");
    const type = ui.newTab.attr("uc-type"); // If the tab doesn't have type se "uc" as default
    const previousUc = this.model.get("usecase");

    if (itemId.indexOf("home") === 0) {
      itemId = "";
    }
    this.model.set("usecase", itemId);
    this.model.set("type", type);
    // Set focus to the first element in the UC.
    $(ui.newTab).focus();
    //s'il y a une erreur du ws dans les zones home(coll ou resp), on doit afficher la popup si on a là.
    if (CWSTR.isBlank(itemId) && (this.model.get("zone") === "resp" || this.model.get("zone") === "coll")) {
      const $existDialog = this.$el.find("#home" + this.model.get("zone") + " .ui-widget-overlay.ui-front");//hidder actif

      if ($existDialog && $existDialog.length > 0) {
        const $lPosDialog = $existDialog.parent().find(".c-cwDialogPopup.ui-dialog-showError");

        if ($lPosDialog && $lPosDialog.length > 0) {
          const $dialog = $lPosDialog.find(".phx-Dialog ui-dialog-content");

          //$lPosDialog.show();
          $lPosDialog.css({ top: "100px", left: "100px", display: "block" });
          if ($dialog && $dialog.length > 0 && $dialog.dialog("instance")) {
            $dialog.dialog("option", "position", { my: "center", at: "center", of: this.$el.find("#home" + this.model.get("zone")) });
          }
        }
      }
    }

    // Change the url if it's a click event. Only navigate to url if usecase has changed. This is done in order to avoid that
    // navigation to the same usecase is made more than once when accesing to a uc from a link in another uc.
    if (this.ready === true && previousUc !== itemId) {
      NAV.navigate(this.zJSON["id"], itemId, type, false);
    }
  }

  _createTab(uc: { [key: string]: any }, module: string): void {
    const tempUc = _.clone(uc);
    const ulDom = $("ul", this.tabSelector).eq(0);
    const newTab = $("<div id=\"" + module + "\" class='phx-proto-resizable '></div>");

    if (CWSTR.isBlank(uc.typ)) {
      tempUc.typ = "uc";
    }
    if (tempUc.target !== module) {
      tempUc.target = module;
    }
    if (String(tempUc.id) !== module) {
      tempUc.id = module;
    }
    tempUc.icone = UTILS.getSVGIcon('croix-epaisse', 'cw-icon-12', 12);
    newTab.prop("fonctions", this.zJSON["fonctions"]);
    ulDom.append(this.templateItem(tempUc));
    ulDom.after(newTab);
    this.tabSelector.tabs("refresh");
    // Set WAI-ARIA property
    ulDom.parent().find("#" + tempUc.target).attr("aria-live", "off");
    this._openTabs.push(module);
    if (objs[module + "Rt"]) {
      $("#" + module).append(objs[module + "Rt"].layout.el);
      if (objs[module + "Rt"]._applyResizes) {
        objs[module + "Rt"]._applyResizes();
      }
    }
    //enlever le commentaires suivant pour montrer le nombre de tabs qu'il y a ouverts
    //this.model.trigger("add:tab", this.zJSON.id);
  }

  _destroyTab(ucid: string, isOmittedCheck: boolean): void {
    const tabIndex = _.indexOf(this._openTabs, ucid);

    if (tabIndex >= 0) {
      const targetLi = $("ul li", this.tabSelector).eq(tabIndex);
      const targetDiv = $("#" + ucid, this.el);

      targetLi.remove();
      targetDiv.remove();
      this._openTabs.splice(tabIndex, 1);
      const type = $("ul li", this.tabSelector).eq(tabIndex - 1).attr("uc-type"); // If the tab doesn't have type se "uc" as default
      if (!isOmittedCheck) {
        this.tabSelector.tabs("refresh");
        //Change the url to the active tab without navigate
        NAV.navigate(this.zJSON["id"], this._openTabs[tabIndex - 1], type, false);
      } else {
        const lModule = this._openTabs[tabIndex - 1];
        this.zJSON.target = this.zJSON["id"];
        if (!CWSTR.isBlank(lModule)) {
          const lZone = objs.appRt.workflow._searchZoneFromUc(lModule);

          if (lZone !== this.zJSON.target) {
            this.zJSON.target = lZone;
          }
        }
        this.model.set("selectedTab-" + this.zJSON.target, { "module": lModule, "type": type }, { silent: true });
      }
      //Notify tab remove
      this.model.trigger("remove:tab", this.zJSON.target);
    }
  }

  render(): CWTabSheetView {
    const self = this;//eslint-disable-line

    this.$el.html(this.template(this.zJSON));
    this.$el.find(".cw-tab-plein-ecran").append(UTILS.getSVGIcon('plein_ecran', 'cw-icon-24', 24));
    // Create TabSheet
    this.tabSelector = $("#phx-tabs-" + this.zJSON["id"], this.el);
    this.tabSelector.tabs({
      activate: (event: JQueryEventObject, ui): void => {
        self._selectTab(event, ui);
        $(ui.newPanel).off("keydown").on("keydown", (event): void => {
          self._keyupHandler((event as any));
        });
        self.ready = true;
      },
      beforeLoad: (event: JQueryEventObject, ui): void => {
        (ui.jqXHR as any).omit = true;
        event.preventDefault();
      },
      create: (event: JQueryEventObject, ui): void => {
        $(ui.panel).attr("aria-live", "off");
      }
    });

    this.home = new CWHomeView({
      el: $("#home" + this.zJSON["id"], this.el),
      zJSON: this.zJSON,
      model: this.model
    });
    this.home.render();
    return this;
  }

  _keyupHandler(event: JQueryEventObject): void {
    const itemId = event.delegateTarget.id;
    const isHome = itemId === ("home" + this.zJSON["id"]);
    const key = event.which || event.keyCode;

    // Alt + Delete close the actual tab
    if (!isHome && event.altKey === true && key === 46) {
      this.model.trigger("predestroy:usecase", itemId);
    }
  }
}
