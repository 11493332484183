import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

export class CWModeBadgModel extends CWBaseModel {

  matricule: string;

  defaults(): { [key: string]: any } {
    return {
      modebadg: ""
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    if (options.matricule) {
      this.matricule = options.matricule;
    }
    this.url = (): string => {
      return Configuration.restRoot + "/rest/badgvirtuel/" + this.matricule + "/modebadg";
    }
  }
}