module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="phx-cell-revert-action btn btn-primary btn-withIcon">'+
((__t=(i18n.t("common:revert")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('croix')))==null?'':__t)+
'</button>\n';
}
return __p;
};
