/**
 * JavaScript logging utility
 */
export class CWLOG {

  static level = "ERROR";

  /**
   * Type of the logs
   *		"DEBUG" : 1,
   *		"INFO" : 2,
   *		"WARN" : 3,
   *		"ERROR" : 4
   * }
   */
  static levelMap: { [key: string]: number } = {
    "DEBUG": 1,
    "INFO": 2,
    "WARN": 3,
    "ERROR": 4
  };

  static methodMap: { [key: string]: string } = {
    "DEBUG": "debug",
    "INFO": "info",
    "WARN": "warn",
    "ERROR": "error"
  };

  /**
   * Loged the message type DEBUG.
   */
  static debug(...msg: any): void {
    CWLOG.innerLog("DEBUG", msg);
  }

  /**
   * Loged the message type INFO.
   */
  static info(...msg: any): void {
    CWLOG.innerLog("INFO", msg);
  }

  /**
   * Loged the message type WARN.
   */
  static warn(...msg: any): void {
    CWLOG.innerLog("WARN", msg);
  }

  /**
   * Loged the message type ERROR.
   */
  static error(...msg: any): void {
    CWLOG.innerLog("ERROR", msg);
  }

  /**
   * Launch the message to de console, depend of type the log
   */
  static innerLog(pLevel: string, ...msg: any): void {
    if (window.console) {
      if (CWLOG.levelMap[CWLOG.level] <= CWLOG.levelMap[pLevel]) {
        const method = CWLOG.methodMap[pLevel];

        if ((console as any)[method]) {
          const lCad = "[" + pLevel + "] - ";

          for (let i = 0; i < msg.length; i++) {
            const lInfo = (Configuration.development === true) ? msg[i] : String(msg[i]);

            (console as any)[method](lCad, lInfo);
          }
        } else {
          CWLOG.debug(msg);
        }
      }
    }
  }
}
