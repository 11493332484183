import _ from 'underscore';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWFilterView, CWFilterViewOptions } from 'src/core/components/filter/cwFilter.view';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';

/**
 * This view is overloaded because in the case of valider with multiple validations rols
 * select the role is obligatory, so if with out this we don't lauch the search, so we need 
 * to overload the "enterPress" event to block the base CWFilterView force search function
 */
export class CWFilterBaseViewWithObligatoryFields<TModel extends CWReadOnlyModel = CWReadOnlyModel, TViewPart1 extends CWBaseFormView<CWFilterBaseModel> = CWBaseFormView<CWFilterBaseModel>,
    TViewPart2 extends CWBaseFormView<CWFilterBaseModel> = CWBaseFormView<CWFilterBaseModel>> extends CWFilterView<TModel, TViewPart1, TViewPart2>{


    constructor(options: CWFilterViewOptions) {
        options.className = "c-filter-view";
        options.events = _.extend({
            "keypress :input:not([readonly])": "_enterPress",
        }, options.events);
        super(options);
    }

    _enterPress(event: KeyboardEvent): void {
        if (event.keyCode === 13 && (event.target as HTMLElement).tagName !== "TEXTAREA") { //Don't listen to enter key in textareas, since they may be part of the search text
            $(event.currentTarget).blur();
            $(this.el).find(".search").click();
        }
    }

}
