import * as Backbone from 'Backbone';
import _ from 'underscore';
import FilterLayoutTPL from './cwFilterLayout.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWFilterBaseModel } from 'core/models/cwFilterBase.model';
import { CWOverlay } from '../overlay/cwOverlay.view';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

interface CWHTMLInputElement extends HTMLInputElement {
  "data-code": string;
}

export interface CWFilterViewOptions<TViewPart1 extends CWBaseFormView<CWFilterBaseModel> = CWBaseFormView<CWFilterBaseModel>, TViewPart2 extends CWBaseFormView<CWFilterBaseModel> = CWBaseFormView<CWFilterBaseModel>> extends Backbone.ViewOptions {
  viewPart1: TViewPart1;
  viewPart2?: TViewPart2;
  columns?: number;
  buttonOnLastFilterRowView?: boolean;
  buttonOnMoreFilterView?: boolean;
  buttonOnBottomFilterView?: boolean;
  hideMainButtons?: boolean;
  iconeMoreCriteres?: boolean;
  iconeMoreCriteresExpand?: string;
  iconeMoreCriteresCollapse?: string;
  customMapFieldSet?: any;
  isActionClear?: boolean;

}

export class CWFilterView<TModel extends CWReadOnlyModel = CWReadOnlyModel, TViewPart1 extends CWBaseFormView<CWFilterBaseModel> = CWBaseFormView<CWFilterBaseModel>,
  TViewPart2 extends CWBaseFormView<CWFilterBaseModel> = CWBaseFormView<CWFilterBaseModel>> extends CWBaseFormView<TModel>{

  /**
   * Name of the container class of the view
   */
  className: string;
  template: (params?: any) => string;
  viewPart1: TViewPart1;
  viewPart2: TViewPart2;
  customMapFieldSet: any;
  date: string;
  demande: string;
  souhait: string;
  customClearFieldSetValues: (fieldset: JQuery) => any;
  private iconSearch: string;
  private iconClear: string;
  private overlay: CWOverlay;
  private buttonOnMoreFilterView: boolean;
  private buttonOnBottomFilterView: boolean;
  private buttonOnLastFilterRowView: boolean;
  private hideMainButtons: boolean;
  private saveBtn: JQuery;
  private isActionClear: boolean;

  /**
   * Number of columns intended for the filter content
   */
  private columns: number;
  private iconeMoreCriteres: boolean;
  private iconeMoreCriteresExpand: string;
  private iconeMoreCriteresCollapse: string;

  /**
   * Constructor
   * View which has the general structure to show a set of inputs used to filter data. It needs at least one
   * child view which contains the inputs to paint
   */
  constructor(options: CWFilterViewOptions) {
    options.className = "c-filter-view";
    options.events = _.extend({
      /**
     * Event launched when the button clear is clicked
     * Event launched when the button search is clicked
     * Event launched when the button more options is clicked
     * Event launched when the enter key is pressed in the filter
     *
     */
      "click .clear": "_fireClick",
      "click .search": "_fireClick",
      'click .loupe': "_loupeSearch",
      "click .c-button-filter__more-filter": "_changeMoreCriteriasVisualisationMode",
      "keyup :input:not([readonly])": "_enterPress",
      "keypress .c-button-filter__more-filter": "_keyPressMoreFilter"
    }, options.events);
    super(options);
    if (options.customMapFieldSet) {
      this.customMapFieldSet = options.customMapFieldSet;
    }
    /**
     * HTML template of the view
     */
    this.template = FilterLayoutTPL;
    if (!options.viewPart1) {
      throw new Error("You must define at least a view with a set of fields to use the filter");
    }
    /**
     * View to show with the single search inputs
     * Always displayed filters
     */
    this.viewPart1 = options.viewPart1 as TViewPart1;
    /**
     * View to show with the advance search inputs
     */
    this.viewPart2 = options.viewPart2 as TViewPart2;
    this.columns = typeof options.columns === "number" ? options.columns : 8;
    this.isActionClear = false;
    if (_.isBoolean(options.isActionClear)) {
      this.isActionClear = options.isActionClear;
    }
    /**
     * Model of the view (mode 0 -> collapsed, mode 1 -> expanded)
     */
    this.model = new CWReadOnlyModel({ mode: 0, moreCriterias: 0 }) as TModel;
    this.model.set("mode", 1);
    this.model.set('isEditing', false);
    this.model.on("change:isEditing", this._manageEditingMode, this);
    this.model.on("change:moreCriterias", this.filterMoreCriteriasVisualisation, this);
    this.model.on("clearFilter", this.restaureDefaultValues, this);
    this.model.on("disableFilter", this.showOverlay, this);
    this.model.on("enableFilter", this.hideOverlay, this);
    this.model.on("btn:click", this._manageClick, this);
    this.on("startEditing", this._startEditing);
    this.on("finishEditing", this._finishEditing);
    this.listenTo(this.viewPart1.model, "form:edited", this._startEditing);
    if (this.viewPart2) {
      this.listenTo(this.viewPart2.model, "form:edited", this._startEditing);
    }
    this.listenTo(this.model, "clean:filter", (notIsButton?: boolean, origine?: string) => {
      this._manageClick("clear", notIsButton, origine);
    });
    this.iconSearch = UTILS.getSVGIcon("valider");
    this.iconClear = UTILS.getSVGIcon("croix");
    this.model.set("savedValues", {});
    this.overlay = new CWOverlay();
    this.buttonOnLastFilterRowView = options.buttonOnLastFilterRowView ? options.buttonOnLastFilterRowView : false;
    this.buttonOnMoreFilterView = options.buttonOnMoreFilterView ? options.buttonOnMoreFilterView : false;
    this.buttonOnBottomFilterView = options.buttonOnBottomFilterView ? options.buttonOnBottomFilterView : false;
    this.hideMainButtons = options.hideMainButtons ? options.hideMainButtons : false;
    this.iconeMoreCriteres = options.iconeMoreCriteres || false;
    this.iconeMoreCriteresExpand = null;
    this.iconeMoreCriteresCollapse = null;
    if (this.iconeMoreCriteres) {
      this.iconeMoreCriteresExpand = options.iconeMoreCriteresExpand || null;
      this.iconeMoreCriteresCollapse = options.iconeMoreCriteresCollapse || null;
      if (CWSTR.isBlank(this.iconeMoreCriteresExpand) && !CWSTR.isBlank(this.iconeMoreCriteresCollapse)) {
        //meme icône pour les deux actions
        this.iconeMoreCriteresExpand = this.iconeMoreCriteresCollapse;
      } else if (!CWSTR.isBlank(this.iconeMoreCriteresExpand) && CWSTR.isBlank(this.iconeMoreCriteresCollapse)) {
        //meme icône pour les deux actions
        this.iconeMoreCriteresCollapse = this.iconeMoreCriteresExpand;
      }
    }
  }

  _enterPress(event: KeyboardEvent): void {
    this._startEditing();
    if (event.keyCode === 13 && (event.target as HTMLElement).tagName !== "TEXTAREA") { //Don't listen to enter key in textareas, since they may be part of the search text
      $(event.currentTarget).trigger("change");
      $(event.currentTarget).blur();
      this.$el.find(".search").click();
    }
  }

  _getModel(): TModel {
    return this.model;
  }

  /**
   * Change filter model more criterias visualisation mode in order to trigger the change attributes event
   */
  _changeMoreCriteriasVisualisationMode(): void {
    if (this.model.get("moreCriterias") === 1) { //Filter more criterias expanded
      this.model.set("moreCriterias", 0);
    } else {
      this.model.set("moreCriterias", 1);
    }
  }

  _fireClick(event: MouseEvent): void {
    const btnClicked = (event.currentTarget as HTMLInputElement).value;

    this.model.trigger("btn:click", btnClicked, this.isActionClear);
  }

  isFilterFormsValid(): boolean {
    let isValid = true;

    if (this.viewPart1 && this.viewPart1 instanceof CWBaseFormView && !this.viewPart1._getModel().isValid()) {
      isValid = false;
    }
    if (this.viewPart2 && this.viewPart2 instanceof CWBaseFormView && !this.viewPart2._getModel().isValid()) {
      isValid = false;
    }
    return isValid;
  }

  _manageClick(buttonId: string, notIsButton?: boolean, origine?: string): void {
    if (buttonId === "clear") {
      if (this.viewPart1 && this.viewPart1 instanceof CWBaseFormView) {
        this.viewPart1._cleanValidationErrors();
      }
      if (this.viewPart2 && this.viewPart2 instanceof CWBaseFormView) {
        this.viewPart2._cleanValidationErrors();
      }
      this.clearFilterFields();
      //Nouveau en comparation avec les anterieurs versions: lorsque on clique sur "clear" les actions sont nettoyer et faire la pétition du WS
      if (notIsButton !== true) {
        this.triggerFilter(origine);
        this._finishEditing();
      }
    } else if (buttonId === "search") {
      if (this.isEditingMode()) {
        if (this.isFilterFormsValid()) {
          this.triggerFilter();
          //the filter will be apply so they are not in editing mode, they are apply
          this._finishEditing();
        }
      }
    }
  }

  /**
   * Update filter view in response to the change event in the filter model
   */
  filterVisualisation(): void {
    objs.appRt.workflow.trigger("resize");
    objs.appRt.workflow.trigger("dialogresize");
  }

  /**
   * Update filter more criterias view in response to the change event in the filter model
   */
  filterMoreCriteriasVisualisation(): void {
    if (this.model.get("moreCriterias") === 1) { //Filter more criterias expanded
      this.$el.find(".c-button-filter__more-filter").html(i18n.t('common:lessfilters'));
      this.$el.find(".c-button-filter__more-filter").attr("aria-label", i18n.t('common:lessfilters'));
      $(".filter-more", this.el).show();
    } else {
      this.$el.find(".c-button-filter__more-filter").html(i18n.t('common:morefilters'));
      this.$el.find(".c-button-filter__more-filter").attr("aria-label", i18n.t('common:morefilters'));
      $(".filter-more", this.el).hide();
    }
    if (this.iconeMoreCriteres === true) {
      const lPosMoreFilter = this.$el.find(".c-button-filter__more-filter");

      lPosMoreFilter.empty();
      if (this.model.get("moreCriterias") === 1 && !CWSTR.isBlank(this.iconeMoreCriteresCollapse)) {
        lPosMoreFilter.append(UTILS.getSVGIcon(this.iconeMoreCriteresCollapse));
      } else if (this.model.get("moreCriterias") === 0 && !CWSTR.isBlank(this.iconeMoreCriteresExpand)) {
        lPosMoreFilter.append(UTILS.getSVGIcon(this.iconeMoreCriteresExpand));
      }
    }
    objs.appRt.workflow.trigger("resize");
  }

  /**
   * Triggers the search action to any subscribe views
   */
  triggerFilter(origine?: string): void {
    let values: any = null;

    if (this.customMapFieldSet) {
      values = this.customMapFieldSet(this.$el.find(".c-filter-content"));
    } else {
      values = this._mapFieldSet(this.$el.find(".c-filter-content"));
      // Clean Empty Values #142458
      _.each(values, function (val, name) {
        if (CWSTR.isBlank(val)) {
          delete values[name];
        }
      });
    }
    this.model.trigger("search", values, origine);
  }

  render(): CWFilterView<TModel, TViewPart1, TViewPart2> {
    const columnsFilter = "col-" + this.columns;
    const columnsButtons = "col-" + (this.columns !== 12 ? (12 - this.columns) : 12);
    const json = {
      "i18n": i18n,
      "columnsFilter": columnsFilter,
      "columnsButtons": columnsButtons,
      "icon": { "search": this.iconSearch, "clear": this.iconClear },
      "hideMainButtons": this.hideMainButtons,
    };
    let lPosMoreFilter = null;

    this.$el.html(this.template(json));
    this.$el.append(this.overlay.render().el);
    if (this.viewPart1) {
      this.$el.find(".filter-simple-fields").html(this.viewPart1.render().el);
    }
    if (this.viewPart2) {
      this.$el.find(".filter-more-fields").html(this.viewPart2.render().el);
    } else {
      this.$el.find(".c-button-filter__more-filter").hide();
      this.$el.find(".filter-more").hide();
    }
    if (this.buttonOnLastFilterRowView) {
      $(this.viewPart1.el).find('.customFilterButtonsContainer').html(this.$el.find(".filterButtonsContainer").html());
      this.$el.find(".filterButtonsContainer").detach();
    }
    if (this.buttonOnMoreFilterView) {
      $(this.viewPart2.el).find('.customFilterButtonsContainer').html(this.$el.find(".filterButtonsContainer").html());
      this.$el.find(".filterButtonsContainer").detach();
    }
    if (this.buttonOnBottomFilterView) {
      this.$el.find('.filter-bottom>.customFilterButtonsContainer').html(this.$el.find(".filterButtonsContainer").html());
      this.$el.find(".filterButtonsContainer").detach();
    } else {
      this.$el.find('.filter-bottom').hide();
    }
    if (this.hideMainButtons) {
      this.$el.find(".filter-simple > .c-button-filter").hide();
    }
    this.filterVisualisation();
    this.filterMoreCriteriasVisualisation();
    this._saveDefaultValue();
    if (this.iconeMoreCriteres === true) {
      lPosMoreFilter = this.$el.find(".c-button-filter__more-filter");
      lPosMoreFilter.text("");
      if (!CWSTR.isBlank(this.iconeMoreCriteresExpand)) {
        lPosMoreFilter.append(UTILS.getSVGIcon(this.iconeMoreCriteresExpand));
      }
    }
    return this;
  }

  /**
   * Clears all the fields
   */
  clearFilterFields(): void {
    if (this.customClearFieldSetValues) {
      this.customClearFieldSetValues(this.$el.find(".c-filter-content"));
    } else {
      this._clearFieldSetValues(this.$el.find(".c-filter-content"));
    }
    if (this.viewPart1 instanceof CWBaseFormView) {
      this.viewPart1.restaureDefaultValues();
    }
    if (this.viewPart2 instanceof CWBaseFormView) {
      this.viewPart2.restaureDefaultValues();
    }
    this.model.trigger("clear");
  }

  /**
   * Clears all the fields of the specified fieldset
   *
   */
  _clearFieldSetValues(fieldset: any): void {
    if (this.viewPart1 instanceof CWBaseFormView) {
      this.viewPart1._getModel().clear();
    }
    if (this.viewPart2 instanceof CWBaseFormView) {
      this.viewPart2._getModel().clear();
    }
    // used for button, checkbox, input and textarea elements
    fieldset.find(":input:not(:button)").each((index: number, element: HTMLInputElement) => {
      const domEl = $(element);
      const value = "";
      const viewRef = domEl.prop("viewRef");

      if (element.type === "select-one" || element.type === "textarea") {
        //Select case
        domEl.val(value);
        $(domEl).css("color", "");
        $(domEl).css("background-color", "");
      } else if (viewRef && viewRef.isComboBoxView2) {
        //clear item only, we dont want to clear the cache of the combo 
        viewRef.setItem({ code: null });

      } else if (viewRef) {
        if (typeof viewRef.clean === "function") {
          viewRef.clean();
        }
      } else {
        //other fields case
        switch (domEl.attr("type")) {
          case "text":
            domEl.val(value);
            break;
          case "checkbox":
            domEl.val([value]);
            break;
          case "radio":
            domEl.val([value]);
            break;
          default:
            break;
        }
      }
    });
  }

  /**
   * Map the values of some fields included in a Fieldset in an array
   * The inputs must have class="model.property"
   *
   */
  _mapFieldSet(fieldset: any): { [key: string]: any } {
    let values: { [key: string]: any } = {};

    values = this._getViewPartFieldValues(fieldset, this.viewPart1);
    if (this.viewPart2) {
      values = _.extend(values, this._getViewPartFieldValues(fieldset, this.viewPart2));
    }
    return values;
  }

  /**
   * Gets the values of some fields included in a part of the fieldset in an array
   * The inputs must have class="model.property"
   *
   */
  _getViewPartFieldValues(fieldset: any, viewPart: any): { [key: string]: any } {
    let values: { [key: string]: any } = {};

    if (viewPart && viewPart instanceof CWBaseFormView) {
      values = viewPart._getModel().toJSON();
    } else {
      // used for button, checkbox, input and textarea elements
      fieldset.find(":input").each((index: number, element: HTMLInputElement) => {
        const domEl = $(element);
        const name = domEl.attr("class").split(" ")[0];
        let value: any = null;

        if (element.type === "select-one" || element.type === "textarea") {
          //Select case
          if (domEl.hasClass("c-cwComboBoxView2__input") && domEl.prop("data-code")) {
            value = domEl.prop("data-code");
          } else {
            value = domEl.val();
          }
          values[name] = value.trim();
        } else {
          //other fields case
          switch (domEl.attr("type")) {
            case "text":
              value = domEl.val();
              values[name] = value.trim();
              break;
            case "checkbox":
              value = domEl.is(':checked');
              values[name] = value;
              break;
            case "radio":
              if (domEl.is(':checked')) {
                value = domEl.val();
                values[name] = value;
              }
              break;
            default:
              break;
          }
        }
      });
    }
    return values;
  }

  private _saveDefaultValue(): void {
    const tmpValues: { [key: string]: any } = {};

    this.$el.find(':input:not(:button)').each((index: number, element: CWHTMLInputElement) => {
      let value: string | boolean;

      switch (element.type) {
        case "radio":
        case "checkbox":
          value = element.checked;
          break;
        case "text":
          //Maybe add condition for datepickers
          if (element.classList.contains('c-cwComboBoxView2__input')) {
            value = element["data-code"];
          } else if (element.classList.contains('typeDate')) {
            const className = element.classList[0];

            if (this.viewPart1.model.get(className)) {
              value = this.viewPart1.model.get(className);
            } else if (this.viewPart2 && this.viewPart2.model && this.viewPart2.model.get(className)) {
              value = this.viewPart2.model.get(className);
            }
          } else {
            value = element.value;
          }
          break;
        default:
          value = element.value;
      }
      if (value && !CWSTR.isBlank(value)) {
        tmpValues[element.classList[0]] = value;
      }
    })
    this.model.set('savedValues', tmpValues);
  }

  public restaureDefaultValues(): void {
    const defaultValues = this.model.get('savedValues');

    this.$el.find(':input:not(:button)').each((index: number, element: HTMLInputElement) => {
      const value = defaultValues[element.classList[0]];

      switch (element.type) {
        case "radio":
        case "checkbox":
          element.checked = value;
          break;
        default:
          element.value = value || "";
      }
    });
    //for the combo
    this.viewPart1.restaureDefaultValues();
    if (this.viewPart2) {
      this.viewPart2.restaureDefaultValues();
    }
  }

  public isEditingMode(): boolean {
    return this.model.get('isEditing');
  }

  private _startEditing(): void {
    this.model.set('isEditing', true);
  }

  private _finishEditing(): void {
    this.model.set('isEditing', false);
  }

  private _manageEditingMode(): void {
    const clear = this.$el.find('.clear');
    const search = this.$el.find('.search');

    if (this.isEditingMode()) {
      search.removeClass('btn-primary-bis');
      clear.removeClass('btn-secondary-bis');
      search.addClass('btn-primary');
      clear.addClass('btn-secondary');
    } else {
      search.removeClass('btn-primary');
      clear.removeClass('btn-secondary');
      search.addClass('btn-primary-bis');
      clear.addClass('btn-secondary-bis');
    }
  }

  public showOverlay(): void {
    this.overlay.showOverlay();
  }

  public hideOverlay(): void {
    this.overlay.hideOverlay();
  }

  public getDefaultValues(): { [key: string]: any } {
    const defaultValues: { [key: string]: any } = {};

    Object.assign(defaultValues, (this.viewPart1.model as unknown as any).savedValues);
    if (this.viewPart2) {
      Object.assign(defaultValues, (this.viewPart2.model as unknown as any).savedValues);
    }
    return defaultValues;
  }

  private _keyPressMoreFilter(keyEvent: JQueryKeyEventObject): void {
    if (keyEvent && (keyEvent.keyCode === 32 || keyEvent.keyCode === 13)) {
      this.model.set("moreCriterias", this.model.get("moreCriterias") === 0 ? 1 : 0);
    }
  }

  private _loupeSearch(): void {
    this._startEditing();
    this.model.trigger('btn:click', 'search');
  }

  public getManageClick(): (buttonId: string, notIsButton?: boolean, origine?: string) => any {
    return this._manageClick;
  }
}
