import _ from 'underscore';
import TPLPagination from './cwPaginationComponent.tpl.html';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWPaginationComponentModel } from './cwPaginationComponent.model';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { LOG } from 'src/utils/log';
import { UTILS } from 'src/utils/utils';
import { View, ViewOptions } from 'Backbone';

interface CWPaginationComponentOptions extends ViewOptions<CWPaginationComponentModel> {
  coll: CWPaginatedCollection;
  actualPage?: number;
  usecase?: string;
}

export class CWPaginationComponent extends View<CWPaginationComponentModel> {

  coll: CWPaginatedCollection;
  public isRendered: boolean;
  private _collectionPaginated: boolean;
  public documentTitle: string;
  public usecase: string;

  constructor(params: CWPaginationComponentOptions) {
    params.tagName = params.tagName || "div";
    params.className = params.className || "c-paginator ml-auto";
    params.events = _.extend({
      "click .c-paginator__action": "goTo",
      "keydown": "keyDown"
    }, params.events);
    super(params);
    this.template = TPLPagination;
    this.coll = params.coll;
    this.model = new CWPaginationComponentModel();
    this.model.actualPage = 0;
    this.init(params);
    this.isRendered = false;
    this.collectionPaginated = this.coll.paginated;
    this.documentTitle = document.title;
    this.usecase = params.usecase;
    this.listenTo(this.coll, "updatePaginationCounter", this.updatePaginationCounter);
    this.listenTo(this.coll, "sync", this.init);
    this.listenTo(this.coll, "change:pagination", this.paginationChanged)
  }

  public render(): CWPaginationComponent {
    const json = { "i18n": i18n, UTILS: UTILS };
    $(this.el).html(this.template(json));
    this.isRendered = true;
    this.updateElements();
    return this;
  }

  public updateElements(): void {
    if (this.isRendered) {
      if (this.collectionPaginated) {
        //First page
        if (this.model.actualPage <= 0) {
          $(".c-paginator__first", this.el).addClass("c-paginator__item-disabled");
          $(".c-paginator__prev", this.el).addClass("c-paginator__item-disabled");
          $(".c-paginator__first", this.el).removeClass("c-paginator__action").remove("tabindex");
          $(".c-paginator__prev", this.el).removeClass("c-paginator__action").remove("tabindex");
        } else if (this.model.actualPage === 1) {

          $(".c-paginator__first", this.el).addClass("c-paginator__item-disabled");
          $(".c-paginator__prev", this.el).removeClass("c-paginator__item-disabled");
          $(".c-paginator__first", this.el).removeClass("c-paginator__action").remove("tabindex");
          $(".c-paginator__prev", this.el).addClass("c-paginator__action").attr({ "tabindex": "0" });
        }
        else {
          $(".c-paginator__first", this.el).removeClass("c-paginator__item-disabled");
          $(".c-paginator__prev", this.el).removeClass("c-paginator__item-disabled");
          $(".c-paginator__first", this.el).addClass("c-paginator__action").attr({ "tabindex": "0" });
          $(".c-paginator__prev", this.el).addClass("c-paginator__action").attr({ "tabindex": "0" });
        }

        //Last page
        if (this.model.actualPage >= (this.model.lastPage - 1)) {
          $(".c-paginator__last", this.el).addClass("c-paginator__item-disabled");
          $(".c-paginator__next", this.el).addClass("c-paginator__item-disabled");
          $(".c-paginator__last", this.el).removeClass("c-paginator__action").remove("tabindex");
          $(".c-paginator__next", this.el).removeClass("c-paginator__action").remove("tabindex");
        } else if (this.model.actualPage === (this.model.lastPage - 2)) {
          $(".c-paginator__last", this.el).addClass("c-paginator__item-disabled");
          $(".c-paginator__next", this.el).removeClass("c-paginator__item-disabled");
          $(".c-paginator__last", this.el).removeClass("c-paginator__action").remove("tabindex");
          $(".c-paginator__next", this.el).addClass("c-paginator__action").attr({ "tabindex": "0" });
        }
        else {
          $(".c-paginator__last", this.el).removeClass("c-paginator__item-disabled");
          $(".c-paginator__next", this.el).removeClass("c-paginator__item-disabled");
          $(".c-paginator__last", this.el).addClass("c-paginator__action").attr({ "tabindex": "0" });
          $(".c-paginator__next", this.el).addClass("c-paginator__action").attr({ "tabindex": "0" });
        }
      }
      else {
        $(".c-paginator__first", this.el).addClass("c-paginator__item-disabled").remove("tabindex");
        $(".c-paginator__prev", this.el).addClass("c-paginator__item-disabled").remove("tabindex");
        $(".c-paginator__last", this.el).addClass("c-paginator__item-disabled").remove("tabindex");
        $(".c-paginator__next", this.el).addClass("c-paginator__item-disabled").remove("tabindex");
      }
      this.updatePaginationCounter();
    }
  }

  private goTo(event: JQueryEventObject): void {
    const className = event.currentTarget.className.split(" ")[1];
    switch (className) {
      case "c-paginator__first": {
        this.model.actualPage = 0;
        break;
      }
      case "c-paginator__prev": {
        if (this.model.actualPage > 0) {
          this.model.actualPage--;
        }
        break;
      }
      case "c-paginator__next": {

        if (this.model.actualPage < this.model.lastPage - 1) {
          this.model.actualPage++;
        }
        break;
      }
      case "c-paginator__last": {
        this.model.actualPage = this.model.lastPage - 1;
        break;
      }
      default: {
        LOG.warn("Element \"" + className + "\" clicked not supported, check goTo")
        break;
      }
    }
    this.updateElements();
    this.model.trigger("paginateTo:" + this.model.actualPage);
  }

  private updatePaginationCounter(): void {
    this.model.totalItems = this.coll.length;
    if (this.collectionPaginated) {
      const pageNum = (this.model.actualPage + 1).toString();
      $(".c-paginator__pageNumber", this.el).text((this.model.actualPage + 1));
      $(".c-paginator__lastPage", this.el).text(this.model.lastPage);
      if (this.usecase !== "valevt") {
        document.title = this.documentTitle + " " + i18n.t("common:grid.paginator.page") + " " + pageNum;
      }
    }
    else {
      $(".c-paginator__pageNumber", this.el).text("1");
      $(".c-paginator__lastPage", this.el).text("1");
    }
    $(".c-paginator__numberElements", this.el).text(this.model.totalItems);
    $(".c-paginator__elementsString", this.el).text(this.model.totalItems === 1 ? i18n.t("common:grid.paginator.element") : i18n.t("common:grid.paginator.elements"));
  }

  private init(params?: CWPaginationComponentOptions): void {
    let lItems = null;

    if (CWSTR.isBlank(this.model.itemsPerPage) || this.model.itemsPerPage <= 0) {
      this.model.itemsPerPage = this.coll.pagination.size;
    }
    if (this.coll.pagination.itemsPerPage && this.coll.pagination.itemsPerPage !== 0) {
      lItems = this.coll.pagination.itemsPerPage;
      if (this.model.itemsPerPage !== this.coll.pagination.itemsPerPage) {
        this.model.itemsPerPage = lItems;
      }
    } else if (this.coll.pagination.size && this.coll.pagination.size !== 0) {
      lItems = this.coll.pagination.size;
    }
    if (params && _.isNumber(params.actualPage)) {
      this.model.actualPage = params.actualPage;
    } else if (!CWSTR.isEmpty(lItems) && lItems !== 0) {
      this.model.actualPage = Math.trunc((this.coll.pagination.startIndex) / lItems); /** If you detect an error in this line
                                                                                                check the case one only have one elment
                                                                                                per page */
    }
    if (CWSTR.isBlank(lItems)) {
      lItems = this.model.itemsPerPage;
    }
    if (lItems !== 0) {
      this.model.lastPage = Math.ceil(this.coll.totalRecords / lItems);
    }
    this.model.totalItems = this.coll.length;
    this.updateElements();
  }

  public updateParams(newParams: CWPaginationComponentOptions): void {
    this.coll = newParams.coll;
    this.init(newParams);
  }

  get collectionPaginated(): boolean {
    return this._collectionPaginated;
  }

  set collectionPaginated(value: boolean) {
    this._collectionPaginated = value;
    this.updateElements();
  }

  private paginationChanged(newValue: boolean): void {
    this.collectionPaginated = newValue;
  }

  //Ignore unnused function, is used on backbone events
  private keyDown(key: JQueryKeyEventObject): boolean {
    if (key && (key.keyCode === 13 || key.keyCode === 32 || key.keyCode === 113)) { // enter or space or F2
      $(document.activeElement).click();
      return false;
    }
    return true;
  }
}