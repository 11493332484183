import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Model the table "liste de compteurs"
 */
export class CWCompteursTableModel extends CWBaseModel {

  action: string;

  defaults(): { [key: string]: any } {
    return {
      "rowid": "",
      "ordre": null,
      "compteur": null,
      "periodicite": null,
      "typeresultat": null,
      "periodevalo": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    if (CWSTR.isBlank(this.get("compteur")) === true) {
      this.set("compteur", { code: null, libelle: "", format: "" });
    }
    if (CWSTR.isBlank(this.get("periodicite")) === true) {
      this.set("periodicite", { code: null, libelle: "" });
    }
    if (CWSTR.isBlank(this.get("typeresultat")) === true) {
      this.set("typeresultat", { code: null, libelle: "" });
    }
    if (CWSTR.isBlank(this.get("periodevalo")) === true) {
      this.set("periodevalo", { code: null, libelle: "" });
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: any = {};

    if (CWSTR.isBlank(attrs.compteur) || CWSTR.isBlank(attrs.compteur.code)) {
      errors.compteur = {};
      errors.compteur.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_compteur') });
    }
    if (CWSTR.isBlank(attrs.typeresultat) || CWSTR.isBlank(attrs.typeresultat.code)) {
      errors.typeresultat = {};
      errors.typeresultat.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_type') });
    }
    if ((attrs.typeresultat.code === "A" || attrs.typeresultat.code === "R") && (CWSTR.isBlank(attrs.periodicite) || CWSTR.isBlank(attrs.periodicite.code))) {
      errors.periodicite = {};
      errors.periodicite.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_periodicite') });
    }
    if ((attrs.typeresultat.code === "A" || attrs.typeresultat.code === "R") && (CWSTR.isBlank(attrs.periodevalo) || CWSTR.isBlank(attrs.periodevalo.code))) {
      errors.periodevalo = {};
      errors.periodevalo.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_periode') });
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response.rowid !== undefined) {
      response.id = response.rowid;
    }
    return response;
  }
}