import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'src/utils/cwStr';
/**
 * Activate Model
 */
export class CWActivateModel extends CWBaseModel {

  userid: string;
  codebrique: string;
  action: string;
  espaceid: string;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      let url = Configuration.restRoot + "/rest/personnalisation/confignav/accueil/brique/" + encodeURIComponent(this.userid + "," + this.codebrique) + "/" + this.action;

      if (!CWSTR.isBlank(this.espaceid)) {
        url += "?espaceid=" + encodeURIComponent(this.espaceid);
      }
      return url;
    }
  }
}