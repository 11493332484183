import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWCollabPopulationModel } from './cwCollabPopulation.model';
import { CWHEADERS } from 'utils/cwHeaders';
import { CWPaginatedCollection } from 'core/models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';
import { objs } from 'src/objectsRepository';

/**
 * Collection of collaborateurs
 */
export class CWCollabPopulationCollection extends CWPaginatedCollection<CWCollabPopulationModel>{

    population: Backbone.Model;

    constructor(models?: CWCollabPopulationModel[] | { [key: string]: any }[] | any, options?: { [key: string]: any }) {
        if (!options && !_.isEmpty(models)) {
            options = models;
        }
        options = options || {};
        options.model = options.model || CWCollabPopulationModel;
        super(models, options);
        if (!CWSTR.isBlank(objs.populationMenu)) {
            this.population = objs.populationMenu.model;
        } else {
            this.population = null;
        }
        this.sortings = { nom: true };
        this.url = (): string => {
            return Configuration.restRoot + "/rest/population/collaborateurs?filtre=true";
        }
    }


    sync(model: CWCollabPopulationModel, xhr?: { [key: string]: any }, options?: { [key: string]: any }): any {
        const lOptions: { [key: string]: any } = options || {};

        if (!CWSTR.isBlank(this.population)) {
            options.headers = _.extend(lOptions.headers, CWHEADERS.populationContext(this.population.get("ident") || 0, this.population.get("type")));
            return super.sync(model, xhr, lOptions);
        }
        return null;
    }
}
