import { i18n } from 'src/i18n.js';
import { TYPE } from 'tda/tda.js';

export var ScheduleColorItemView = Backbone.View.extend({
  /**
   * Add this to have Outline
   *
   */

  /**
   * tagName
   */
  tagName: "span",
  /**
   * className
   */
  className: "phx-schedule-color-item",
  /**
   * HORIZONTAL
   */
  HORIZONTAL: "H",
  /**
   * VERTICAL
   */
  VERTICAL: "V",

  /**
   * Constructor
   * Representation of each colored interval
   */
  initialize: function(options) {
    this.plage = options.plage;
    this.direction = options.direction || this.HORIZONTAL;
    if (_.isBoolean(options.showTitle)) {
      this.showTitle = options.showTitle;
    } else {
      this.showTitle = true;
    }

    if (_.isBoolean(options.exceptional)) {
      /**
       * A boolean that indicates if exceptional mark must be shown
       */
      this.exceptional = options.exceptional;
    } else {
      this.exceptional = false;
    }
  },

  /**
   * Renders this view
   */
  render: function() {
    var property = "width";
    if (this.direction === this.VERTICAL) {
      property = "height";
      $(this.el).css("width", "100%");
      $(this.el).css("display", "block");
    }
    $(this.el).css(property, this.plage.range + "%");
    var plageType = this.plage.type;
    plageType = plageType === "toldeb" || plageType === "tolfin" ? "tolerance" : plageType;
    $(this.el).addClass("ui-phx-plage-" + plageType);
    if ("blank" !== this.plage.type && this.showTitle) {
      $(this.el).prop("title", i18n.t('common:schedule_title', {
        "0": i18n.t('common:schedule_' + this.plage.type),
        "1": TYPE.HOUR_MINUTE.format(this.plage.hdebut),
        "2": TYPE.HOUR_MINUTE.format(this.plage.hfin)
      }));
    }

    if (this.direction === this.HORIZONTAL) {
      $(this.el).html("&nbsp");
      if ("blank" !== this.plage.type && this.exceptional) {
        $(this.el).html("*").css({
          "text-align": "center",
          "vertical-align": "center"
        });
      }
    }

    return this;
  }
});
