import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererrecapitulatifsInfoComPopup from '../cwInfoComPopup.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWBlockView as CWRecaphistoBlockView } from 'common/recaphisto/views/cwBlock.view';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWComplementComboColl } from 'common/evenements/gerer/gererrecapitulatifs/models/cwComplementCombo.collection';
import { CWDIVERS } from 'utils/cwDivers';
import { CWFORMS } from 'utils/cwForms';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWPieceJointes } from 'common/piecesjointes/views/cwPieceJointes.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


export class CWInfoComPopupView extends CWBaseFormView {

  editable: boolean;
  etat: string;
  histo: CWRecaphistoBlockView;
  modelModified: boolean;
  piecesjointesModified: boolean;
  pj: CWPieceJointes;
  parent: any;
  workflow: any;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_changeView",
      "keyup :input:not([readonly])": "_enableButtons",
      "click button": "_clickListener"
    }, CWTYPE.LONG.events(".infocomp"), CWTYPE.DATE.events(), CWTYPE.CUSTOM.events());
    super(options);
    this.template = TPLGererrecapitulatifsInfoComPopup;
    this.workflow = options.workflow;
    this.editable = options.editable;
    this.model = new CWBaseModel({
      value: this.workflow.headerModel.get("infocomp")
    });
    this.model.get("value").id = options.id;
    this.model.get("value").setHabContext(this.workflow.habV);
    if (this.workflow.isActiveWorkflowRecap() === true) {
      this.histo = new CWRecaphistoBlockView({ "context": { ctxEcran: ((this.workflow && !CWSTR.isBlank(this.workflow.module)) ? this.workflow.module : null) } });
    }
    this.listenTo(this.model, "change:value", this._mapToForm);
    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
      this.parent.model.set("arePJChanged", false);
    }
    this.etat = options.etat;
    this.modelModified = false;
    this.piecesjointesModified = false;
    this.typeFormatByClass = {};
    this.model.get("value").fetch({
      success: (fresh: { [key: string]: any }) => {
        fresh.store();
        if (this.workflow.context.ctxPieceJointe === true) {
          this.pj.setPiecesJointes(fresh.get("piecesjointes"));
        }
        this._mapToForm();
      },
      error: (model?: { [key: string]: any }, response?: { [key: string]: any }): void => {
        response.sansAppendTo = true;//pour ne pas tenir compte l'écran où le message sera ouvert et calculer la valeur correcte du style css "z-index"
        this.editable = false;
      }
    });
  }

  _managePieceJointe(): void {
    const maxPieces = !CWSTR.isBlank(this.workflow.maxPiecesRecap) && this.workflow.context.ctxPieceJointe === true ? this.workflow.maxPiecesRecap.get("maxi") : 0;

    if (maxPieces > 0) {
      const pjContext: { [key: string]: any } = {
        ctxRefEvenement: this.model.get("value").evenement,
        ctxHabilitation: {
          HabilitationAcces: {}
        },
        ctxEcran: this.workflow.context.ctxEcran,
        ctxGestionCollab: this.workflow.context.ctxCollab,
        ctxModeInitialisation: null,
        ctxActionsPossibles: null, ctxModeRestitution: "Imbrique",
        ctxModeDefaut: "Replie"
      };

      //Collaborateur
      if (this.workflow.context.ctxUtilisateur === "Collaborateur") {
        pjContext.ctxHabilitation.HabilitationAcces = this.workflow.habV.get("foncCour");
      } else {
        if (_.isObject(pjContext.ctxHabilitation.HabilitationAcces)) {
          pjContext.ctxHabilitation.HabilitationAcces = "RES_RECAP.V";
        } else {
          pjContext.ctxHabilitation.HabilitationAcces = this.workflow.habV.get("foncCour");
        }
      }
      if (this.workflow.context.ctxUtilisateur === "Collaborateur") {
        if (_.isObject(pjContext.ctxHabilitation.HabilitationGestion)) {
          pjContext.ctxHabilitation.HabilitationAcces = "COL_RECAP_CTRL";
        } else {
          pjContext.ctxHabilitation.HabilitationGestion = this.workflow.habG.get("foncCour");
        }
      } else {
        if (_.isObject(pjContext.ctxHabilitation.HabilitationGestion)) {
          pjContext.ctxHabilitation.HabilitationAcces = "RES_RECAP_CTRL";
        } else {
          pjContext.ctxHabilitation.HabilitationGestion = this.workflow.habG.get("foncCour");
        }
      }
      // Collaborateur and Responsable
      if (_.isObject(pjContext.ctxHabilitation.HabilitationValidation)) {
        pjContext.ctxHabilitation.HabilitationAcces = "RES_VAL_RECAP.G";
      }
      pjContext.ctxEcran = this.workflow.context.ctxEcran;
      pjContext.ctxGestionCollab = this.workflow.context.ctxCollab;
      if (!this.editable) {
        pjContext.ctxModeInitialisation = "Consultation";
        pjContext.ctxActionsPossibles = [];
      } else {
        pjContext.ctxModeInitialisation = "Ajout";
        pjContext.ctxActionsPossibles = ["Creer", "Supprimer"];
      }
      this.piecesjointesModified = false;
      this.pj = new CWPieceJointes({
        context: pjContext,
        type: "PJEVTRECAP",
        maxPieces: maxPieces,
        popupGerer: true,
        appendTo: null,
        modal: true,
        mode: pjContext.ctxModeInitialisation
      });
      this.pj._oldManageButtons = this.pj._manageButtons;
      this.pj._manageButtons = (event): void => {
        this._focused(event, (): void => {
          this.pj._oldManageButtons(event);
        });
      };
      $(this.el).find(".recap-piecesjointes").html(this.pj.render().el);
      this.pj.setContext(pjContext);
      this.pj.setPiecesJointes(this.model.get("value").get("piecesjointes"));
      this.pj.model.on("piecejointeChanged", (): void => {
        this.model.trigger("form:edited");
        this._enableButtons();
        this.piecesjointesModified = true;
      }, this);
      $(this.el).find(".recap-piecesjointes").show();
    } else {
      $(this.el).find(".recap-piecesjointes").hide();
    }
  }

  _focused(event: JQueryEventObject | JQueryKeyEventObject, callback?: () => void): void {
    if (callback) {
      callback();
    }
  }

  render(): CWInfoComPopupView {
    const json = { "i18n": i18n, "UTILS": UTILS };
    let histoContext: { [key: string]: any } = null;

    this.$el.append(this.template(json));
    // Render buttons
    $(this.el).find(".btnValider").hide();
    $(this.el).find(".btnRevert").hide();
    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }
    this._managePieceJointe();
    if (this.workflow.isActiveWorkflowRecap() === true) {
      histoContext = {
        ctxEcran: this.workflow.context.ctxEcran,
        ctxHabilitation: (this.workflow.context.ctxHabilitation.HabilitationGestionRecap ? this.workflow.context.ctxHabilitation.HabilitationGestionRecap : this.workflow.context.ctxHabilitation.HabilitationGestion.recapitulatif),
        ctxTypeEvenement: "ACTRECAP",
        ctxRefEvenement: this.workflow.headerModel.get("infocomp").get("evenement"),
        ctxDemId: "",
        ctxModeRestitution: "Imbrique",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: false
      };
      this.histo.setContext(histoContext);
      $(this.el).find(".histo").html(this.histo.render().el);
    }

    return this;
  }

  _changeView(event: JQueryEventObject, data: any): void {
    this.modelModified = true;
    this._enableButtons();
    this._change(event, data);
  }

  _enableButtons(): void {
    $(this.el).find("fieldset .cwDialog-buttons button").show();
  }

  _disableButtons(): void {
    $(this.el).find("fieldset .cwDialog-buttons button").hide();
  }

  _mapToForm(): void {
    const model = this.model.get("value");
    const fieldset = this.$el.find("fieldset");
    let title = "";
    const infoCompArray = model.get("infocomp");

    if ((this.workflow.context.ctxUtilisateur === "Responsable" && CWHABILITATION.canView("RES_RECAP.G") || this.workflow.context.ctxUtilisateur === "Collaborateur" && CWHABILITATION.canView("COL_RECAP.G"))) {
      this.model.get("value").setHabContext(this.workflow.habG);
    }
    this.$el.find(".infocomp").html("");
    title = "<span class='menuText'>" + model.get("libelle") + "<span>&nbsp;-&nbsp;<span class='" + this.workflow.styleFromStatus(model.get('etat')) + "'>(" + this.etat + ")</span><br><br>" +
      i18n.t('common:gererrecapitulatifs.dateFrom') + "&nbsp;" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_L")) +
      "&nbsp;" + i18n.t('common:gererrecapitulatifs.dateTo') + "&nbsp;" + CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_L"));
    this.$el.find("span.title").html(title);
    _.each(infoCompArray, (it: { [key: string]: any }, key: string) => {
      this._manageFields(it, key);
    });
    this._mapModelToForm(fieldset, model, null);
    if (!this.editable) {
      CWFORMS.setFormReadonly(this.$el, true, true);
    }
    if (this.parent && this.parent.model) {
      this.parent.model.trigger("dlg:reposition");
    }
  }

  _clickListener(event: JQueryEventObject): void {
    const btnClicked = $(event.currentTarget).val();
    const model = this.model.get("value");

    switch (btnClicked) {
      case "btnValider":
        // Error management
        if (this.modelModified) {
          model.off("invalid");
          model.on("invalid", this._showValidationErrors, this);
          if (model.isValid()) {
            model.save(null, {
              success: (fresh: { [key: string]: any }) => {
                this.savePiecesJointes(fresh, () => {
                  this.parent.model.set("isCommentChanged", true);
                  this.parent.close();
                });
              },
              error: (model?: { [key: string]: any }, response?: { [key: string]: any }): void => {
                response.sansAppendTo = true;//pour ne pas tenir compte l'écran où le message sera ouvert et calculer la valeur correcte du style css "z-index"
              }
            });
          }
        } else {
          this.savePiecesJointes(model, () => {
            this.parent.close();
          });
        }
        break;
      case "btnRevert":
        this.modelModified = false;
        this.piecesjointesModified = false;
        model.revert();
        this._managePieceJointe();
        this._disableButtons();
        this._mapToForm();
        break;
      default:
      //Nothing
    }
  }

  savePiecesJointes(model: any, callback: () => void): void {
    if (model && (this.piecesjointesModified)) {
      const evenement = model.get("evenement");

      this.parent.model.set("arePJChanged", true);
      this.pj.applyPendingActions(evenement, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  }

  _manageFields(fieldData: { [key: string]: any }, fieldName: string): void {
    if (!CWSTR.isBlank(fieldData.codif)) {
      const combo = this._createCombo(fieldData);

      if (combo) {
        this._paintField(fieldData, combo);
      }
    } else {
      const input = this._createInputText(fieldName, 14, 10);

      // PAINT AN INPUT TEXT
      this._paintField(fieldData, input);
    }
  }

  /**
   * Creates and returns a combo field.
   */
  _createCombo(fieldData: { [key: string]: any }): CWComboBoxView2 {
    let combo: CWComboBoxView2 = null;

    if (fieldData) {
      const idCombo = fieldData.code;

      combo = new CWComboBoxView2({
        ws: new CWComplementComboColl([], { codif: fieldData.codif, "typeInfocomp": fieldData.type }),
        name: "infocomp." + idCombo + ".valeur",
        habContext: this.workflow.getHabContext(),
        required: fieldData.oblig,
        optionsRender: (value: { [key: string]: any }): string => {
          if (!value || CWSTR.isBlank(value.code)) {
            return "";
          }
          return value.libelle + " (" + value.code + ")";
        },
        preprocessBeforeSetItem: (item: { [key: string]: any }): { [key: string]: any } => {
          return { "code": item.valeur, "libelle": "" };
        }
      });
    }
    if (combo) {
      this.listenTo(combo, "comboEdited", this._formEdited);
    }
    return combo;
  }

  /**
   * Paints the input fields or combos and their labels.
   */
  _paintField(fieldData: { [key: string]: any }, fieldToPaint: any): void {
    const fieldName = fieldData.code;
    const pre = "infocomp";
    const $domEl = $("<div class='form-group'>");
    const $labelEl = $("<label for='" + fieldName + "' >").attr("for", pre + "." + fieldName + ".valeur");

    if (fieldData.oblig) {
      $labelEl.addClass("cw-required");
    }
    $domEl.append($labelEl);
    this.$el.find(".infocomp").append($domEl);
    if (!CWSTR.isBlank(fieldData.codif)) {
      const $containerEl = $("<span>");

      $domEl.append($containerEl);
      if (fieldToPaint.type !== "select-one") {
        $containerEl.html(fieldToPaint.render().el);
      } else {
        $containerEl.html(fieldToPaint);
      }
      $labelEl.text(fieldData.libelle);
    } else {
      const $containerEl = $("<span>");

      //
      // Paint the input fields
      //
      $domEl.append($containerEl);
      $containerEl.html(fieldToPaint);
      // Add typeLong mask and events changed in # customer 146014
      if (fieldData.type === "N") {
        if (fieldData.masque) {
          $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeCustom");
          this.typeFormatByClass[pre + "." + fieldName + ".valeur"] = fieldData.masque;
        } else {
          $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeLong");
        }
        $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").removeAttr("maxlength");
      }
      //fills the dates if needed
      if (fieldData.type === "D") {
        CWFORMS.setDatepicker(this, "." + pre + "\\." + fieldName + "\\.valeur");
        $domEl.find("." + pre + "\\." + fieldName + "\\.valeur").addClass("typeDate");
        $domEl.find("." + pre + "\\." + fieldName + "\\.valeur").removeAttr("maxlength");
      }
      if (fieldData.type === "J") {
        $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeJour");
      }
      $labelEl.text(fieldData.libelle);
    }
    // evaluate if this must be hidden or not
    if (fieldData.libelle && fieldData.libelle.indexOf(".") === 0) {
      $domEl.hide();
    } else {
      $domEl.show();
    }
  }

  /**
   * Creates and returns an input text field.
   */
  _createInputText(idInput: string, size: number, maxLength: number): JQuery {
    const input = $("<input>").addClass("infocomp." + idInput + ".valeur form-control").attr({ "type": "text", "size": size, "maxlength": maxLength });

    return input;
  }

  _disableForm(): void {
    this.editable = false;
    CWFORMS.setFormReadonly(this.$el, true, false);
    this._desactiverPieceJointe(true);
  }

  _desactiverPieceJointe(etat: boolean): void {
    if (_.isBoolean(etat) && !_.isEmpty(this.pj)) {
      const paramPJointe = (CWDIVERS.get("pJointe") === "1");

      if (paramPJointe === true && this.workflow?.context?.ctxPieceJointe === true && etat === true) {
        this.pj.desactiverPieceJointe(true);
      } else {
        this.pj.desactiverPieceJointe(false);
      }
    }
  }
}
