import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
/**
 * Model for an activite in treetypegreid table
 */
export class CWActiviteModel extends CWBaseModel {

  individualComment: boolean;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
  }

  defaults(): { [key: string]: any } {
    return {
      "code": "",
      "activite": "",
      "libelle": "",
      "typeevenement": "",
      "typetransfo": "",
      "typesaisie": "",
      "modesaisie": "",
      "datedeb": "",
      "unitedeb": "",
      "heuredeb": null,
      "datefin": "",
      "unitefin": null,
      "heurefin": null,
      "duree": null,
      "dureecalculee": null,
      "pourcentage": null,
      "format": null,
      "indicateurpj": false,
      "piecesjointes": null
    }
  }
}
