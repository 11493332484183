import _ from 'underscore';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';

/**
 * Population model
 */
export class CWPopulationModel extends CWReadOnlyModel {

  defaults(): { [key: string]: any } {
    return {
      "ident": null,
      "code": null,
      "libelle": "",
      "type": "D"
    }
  }
  habilitationOrigine: string; //pour les cas qui le selecteur est ouvert depuis autre écran et il faut utiliser autre habilitation. Par défaut, vide

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/population/" + this.id;
    };
    this.habilitationOrigine = null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.ident)) {
      this.id = response.ident;
    }
    return response;
  }
}