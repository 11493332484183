import _ from 'underscore';
import TPLListerregularisationsFilter from '../cwFilter.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWEtiquette, etiquetteState } from 'core/components/etiquette/cwEtiquette.view';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWFilterModel } from '../models/cwFilter.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';

/**
 * View for the content of the filter part
 */
export class CWFilterContentListeRegulView extends CWBaseFormView<CWFilterBaseModel> {

  etiquetteDemande: CWEtiquette;
  etiquetteAcepte: CWEtiquette;
  etiquetteRefuse: CWEtiquette;
  private workflow: any;
  model: CWFilterModel;
  statusEtiquette: { [key: string]: etiquetteState };

  constructor(options?: ViewOptions<Model> | any) {
    options.events = _.extend({
      "change :input:not([readonly])": "_change"
    }, CWTYPE.DATE.events());
    super(options);
    this.template = TPLListerregularisationsFilter;
    this.model = new CWFilterModel();
    this.setHabContext(options.mainView.getHabContext());
    this.statusEtiquette = {};
    if (options.statusEtiquette) {
      this.statusEtiquette = options.statusEtiquette;
    }
    this.initEtiquettes();
    this.workflow = options.workflow;
    this.listenTo(this.workflow, "enabledEtiquette", this.resetEtiquette);
  }

  _getModel(): CWFilterModel {
    return this.model;
  }

  render(): CWFilterContentListeRegulView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));

    if (this.workflow.wkfActiv) {
      this.$el.find(".etiquettesContainer").append(this.etiquetteDemande.render().el);
      this.$el.find(".etiquettesContainer").append(this.etiquetteAcepte.render().el);
      this.$el.find(".etiquettesContainer").append(this.etiquetteRefuse.render().el);
    }

    this._mapModelToForm(this.$el.find(".etiquettesContainer"), this.model);
    return this;
  }

  private initEtiquettes(): void {

    this.etiquetteAcepte = new CWEtiquette({
      id: "etiquetteFilterAcepte",
      text: {
        enabled: i18n.t('common:listerregularisations.etiquette_accepte')
      },
      subText: {
        enabled: ""
      },
      type: "accepte",
      state: (!_.isEmpty(this.statusEtiquette) && !CWSTR.isBlank(this.statusEtiquette.accepte)) ? this.statusEtiquette.accepte : "actif",
      values: "A,H"
    });
    this.etiquetteDemande = new CWEtiquette({
      id: "etiquetteFilterDemande",
      text: {
        enabled: i18n.t('common:listerregularisations.etiquette_demande')
      },
      subText: {
        enabled: ""
      },
      type: "demande",
      state: (!_.isEmpty(this.statusEtiquette) && !CWSTR.isBlank(this.statusEtiquette.demande)) ? this.statusEtiquette.demande : "actif",
      values: "D,T,I"
    });
    this.etiquetteRefuse = new CWEtiquette({
      id: "etiquetteFilterRefuse",
      text: {
        enabled: i18n.t('common:listerregularisations.etiquette_refuse')
      },
      subText: {
        enabled: ""
      },
      type: "refuse",
      state: (!_.isEmpty(this.statusEtiquette) && !CWSTR.isBlank(this.statusEtiquette.refuse)) ? this.statusEtiquette.refuse : "actif",
      values: "R"
    });

    this.listenTo(this.etiquetteAcepte.model, "click", this.changeFilterEtiquettes);
    this.listenTo(this.etiquetteDemande.model, "click", this.changeFilterEtiquettes);
    this.listenTo(this.etiquetteRefuse.model, "click", this.changeFilterEtiquettes);

  }

  private changeFilterEtiquettes(id: string, state: etiquetteState): void {

    switch (id) {
      case "etiquetteFilterDemande":
        this.etiquetteDemande.model.trigger("changeState", state === "actif" ? "inactif" : "actif");
        this.$el.find(".demande").click();
        break;
      case "etiquetteFilterAcepte":
        this.etiquetteAcepte.model.trigger("changeState", state === "actif" ? "inactif" : "actif");
        this.$el.find(".acepte").click();
        break;
      case "etiquetteFilterRefuse":
        this.etiquetteRefuse.model.trigger("changeState", state === "actif" ? "inactif" : "actif");
        this.$el.find(".refuse").click();
        break;
      default:
        break;
    }
    this._updateModel();
    this.workflow.trigger("selectedOptionfilter", this.model);
  }

  _updateModel(): void {
    let statuts = '';

    if (this.etiquetteDemande.state === "actif") {
      statuts += statuts === "" ? this.etiquetteDemande.values : "," + this.etiquetteDemande.values;
    }
    if (this.etiquetteAcepte.state === "actif") {
      statuts += statuts === "" ? this.etiquetteAcepte.values : "," + this.etiquetteAcepte.values;
    }
    if (this.etiquetteRefuse.state === 'actif') {
      statuts += statuts === "" ? this.etiquetteRefuse.values : "," + this.etiquetteRefuse.values;
    }

    this.model.set('statuts', statuts);
  }

  private resetEtiquette(): void {
    this.etiquetteDemande.model.trigger("changeState", "actif");
    this.etiquetteAcepte.model.trigger("changeState", "actif");
    this.etiquetteRefuse.model.trigger("changeState", "actif");
  }
}
