import * as Backbone from 'Backbone';
import _ from 'underscore';
import persobriquesDetailTPL from '../detail.tpl.html';
import { CWBriquesLineView } from './cwBriquesLine.view';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListeBriquesColl } from '../models/cwListeBriques.collection';
import { CWListeBriquesModel } from '../models/cwListeBriques.model';
import { CWMSGS, ShowConfirmOptions } from 'utils/cwMsgs';
import { CWParametrageView } from 'common/parametragebresultats/views/cwParametrage.view';
import { CWPersoBriquesDeleteModel } from '../models/cwPersoBriquesDelete.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';

/**
 * View for the personnalisation of the liste of brique
 */

export class CWBriquesListeView<TModel extends CWListeBriquesModel = CWListeBriquesModel> extends Backbone.View<TModel> {

  context: { [key: string]: any };
  bresRmax: number;
  bresCmax: number;
  briqueLineViews: Array<CWBriquesLineView>;
  dialogParametrage: CWDialogPopupView;
  briquesToRefresh: Array<string>;
  changeDialogDetected: boolean;
  parent: CWDialogView;
  public $appendTo: JQuery; //pour les messages

  constructor(options: Backbone.ViewOptions<CWListeBriquesModel> | any) {
    let lTmp = "";

    options = options || {};
    options.events = _.extend({
      "click .persobriques-brique-line-check": "_manageActivation"
    }, options.events);
    super(options);
    this.template = persobriquesDetailTPL;
    if (options && options.context) {
      this.context = options.context;
      this.parent = options.parent;
      this.context.ctxHabilitation = {};
      this.context.ctxHabilitation.HabilitationAcces = this.context.zone === "coll" ? "PER_BRACC_C.V" : "PER_BRACC_R.V";
      this.context.ctxHabilitation.HabilitationGestion = this.context.zone === "coll" ? "PER_BRACC_C.G" : "PER_BRACC_R.G";
      this.model.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces
      }));
      this.model.get("value").setHabContext(this.model.getHabContext());
    }
    if (CWSTR.isBlank(this.model.getHabContext())) {
      this.model.setHabContext(new CWHabilitationContext({
        onglet: "persobriques",
        foncCour: "N"
      }));
      this.model.get("value").setHabContext(this.model.getHabContext());
    }
    lTmp = CWDIVERS.get("bresR_max");
    this.bresRmax = (lTmp) ? parseInt(lTmp, 10) : null;
    lTmp = CWDIVERS.get("bresC_max");
    this.bresCmax = (lTmp) ? parseInt(lTmp, 10) : null;
  }

  canAccess(): boolean {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationAcces &&
      CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces);
  }

  setContext(context: { [key: string]: any }): void {
    this.context = context;
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    this.context.ctxHabilitation = {};
    this.context.ctxHabilitation.HabilitationAcces = this.context.zone === "coll" ? "PER_BRACC_C.V" : "PER_BRACC_R.V";
    this.context.ctxHabilitation.HabilitationGestion = this.context.zone === "coll" ? "PER_BRACC_C.G" : "PER_BRACC_R.G";
    this.model.updateHabContext({
      onglet: context.ctxEcran,
      foncCour: context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    });
    if (this.canAccess()) {
      this._loadData(null, null, true);
    }
  }

  _loadData(refreshData?: boolean, briquesToRefresh?: string[], silentLoad?: boolean): void {
    const value = this.model.get("value");

    value.setHabContext(this.model.getHabContext());
    if (this.canAccess()) {
      value.espaceid = this.context.espaceid; // ‘EM_00009’ pour ‘Mon Compte’  et	‘EM_00022’ pour ‘Mon Equipe’
      value.zone = objs.appRt.workflow.get("zone");
      value.fetch({
        success: () => {
          this._generateContent(this.model.get("value"), (html: string) => {
            this.setContent(html);
            if (silentLoad !== true) {
              this.model.trigger("reload", this._modelToColl(this.model.get("value")), refreshData, briquesToRefresh);
            }
          });
        }
      });
    }
  }

  _modelToColl(model: CWListeBriquesModel): CWListeBriquesColl {
    const coll = new CWListeBriquesColl();

    _.each(model.get("briques"), (briqueObject: CWListeBriquesModel) => {
      const briqueModel = new CWListeBriquesModel(briqueObject);

      briqueModel.id = briqueModel.getUniqueId();
      briqueModel.typeBrique = briqueObject.id;
      coll.add(briqueModel);
    });
    return coll;
  }

  getUniqueId(obj: { [key: string]: any }): string {
    let uniqueId = "";

    if (!CWSTR.isBlank(obj.personnalisation)) {
      uniqueId = obj.id + "," + obj.emid + "," + obj.personnalisation.userid + "," + obj.personnalisation.codebrique;
    } else {
      uniqueId = obj.id + "," + obj.emid;
    }
    return uniqueId;
  }

  _generateContent(value: any, callbackContent: (args1?: string) => any): void {
    const collection = this._modelToColl(value);

    this.briqueLineViews = [];
    if (collection.length > 0) {
      this._buildBriqueLine(collection, 0, $("<span>"), callbackContent);
    }
  }

  _buildBriqueLine(collection: CWListeBriquesColl, i: number, htmlContent: any, contentCallback: (arg1: string) => any): void {
    if (collection.length > i) {
      const model = collection.at(i) as CWListeBriquesModel;
      const activesLength = this._totalActivesLength();

      this.briqueLineViews[model.getUniqueId() as any] = new CWBriquesLineView({ model: model, context: this.context, position: i, totalActivesLength: activesLength });
      this.briqueLineViews[model.getUniqueId() as any].model.on("hideButtons", this._hideButtonLines, this);
      this.listenTo(model, "buttonAction", (buttonId: string, position: number) => {
        this._buttonAction(buttonId, position);
      });
      htmlContent.append(this.briqueLineViews[model.getUniqueId() as any].render().el);
      this._buildBriqueLine(collection, ++i, htmlContent, contentCallback);
    } else {
      contentCallback(htmlContent);
    }
  }

  //Hide buttons of all lines when  we are going to show one button line to avoid showing two buttons lines (because of bad browser management of hover button)
  _hideButtonLines(): void {
    this.$el.find(".persobriques-brique-line-btns").hide();
  }

  _totalActivesLength(): number {
    const value = this.model.get("value");
    let activeCount = 0;

    _.each(value.get("briques"), (briqueObj: { [key: string]: any }) => {
      if (briqueObj.active === true) {
        activeCount++;
      }
    });
    return activeCount;
  }

  setContent(html: string): void {
    if (this.canAccess()) {
      this.$el.find(".persobriques-content").html(html);
    }
  }

  render(): CWBriquesListeView<TModel> {
    const json = { "i18n": i18n };

    this.$el.append(this.template(json));
    this.$el.find(".persobriques-intitle").text(i18n.t('app.personnaliser') + ' ' + (this.context.zoneName).toUpperCase());
    this.$el.find(".persobriques-container").css("min-width", screen.width * .3); // Largeur de la popup = 30% de la largeur de la fenêtre du navigateur 
    return this;
  }

  _manageActivation(event: JQueryEventObject): void {
    const target = this.model.get("value");
    const checkBox = event.target;
    const position = $(checkBox).attr("position");
    const howManyBResultats = this._tooManybresultats();

    if (target && target.get("briques") && target.get("briques").length >= position && target.get("briques")[position]) {
      target.get("briques")[position].active = $(checkBox).prop("checked");
    }
    if (target.get("briques")[position].id === "bresultats" && target.get("briques")[position].active === true && !CWSTR.isBlank(howManyBResultats)) {
      let nBriques = 0;

      target.get("briques")[position].active = false;
      $(checkBox).prop("checked", 0);
      if (this.context.zone === "coll" && !CWSTR.isBlank(this.bresCmax)) {
        nBriques = this.bresCmax;
      } else if (this.context.zone === "resp" && !CWSTR.isBlank(this.bresRmax)) {
        nBriques = this.bresRmax;
      }
      CWMSGS.showError(i18n.t('messages:GT_1823', { "1": nBriques }), null, this.$appendTo);
    } else {
      this._updatePersonnalisation();
    }
  }

  _tooManybresultats(): number {
    let tooMany = false;
    let bResultatsActive = 0;

    _.each(this.model.get("value").get("briques"), (briqueObj: { [key: string]: any }) => {
      if (briqueObj.id === "bresultats" && briqueObj.active === true) {
        bResultatsActive++;
      }
    });
    if (this.context.zone === "coll" && !CWSTR.isBlank(this.bresCmax) && bResultatsActive > this.bresCmax) {
      tooMany = true;
    } else if (this.context.zone === "resp" && !CWSTR.isBlank(this.bresRmax) && bResultatsActive > this.bresRmax) {
      tooMany = true;
    }
    return (tooMany === true) ? bResultatsActive : null;
  }

  _updatePersonnalisation(): void {
    let target = null;

    target = this.model.get("value");
    this._deleteFictives(target);//dans ce méthode, la valeur de target est modifiée
    target.set("id", "briques"); //Force PUT
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    }));
    target.save(null, {
      success: () => {
        this._loadData();
      }
    });
  }

  // CASE 1 : Move only 1 item and save it. Delete if it isn't used.
  _loadDataHomeAndUpdate(briqueIdMoved: string, newPos: number): void {
    const value = this.model.get("value");

    value.setHabContext(this.model.getHabContext());
    if (this.canAccess()) {
      value.espaceid = this.context.espaceid; // ‘EM_00009’ pour ‘Mon Compte’
      //	‘EM_00022’ pour ‘Mon Equipe’
      value.fetch({
        success: () => {
          const briques = this.model.get("value").attributes.briques;
          const target = this.model.get("value");

          for (let i = 0; i < briques.length; i++) {
            if (briqueIdMoved.substring(0, 10) === "bresultats") {
              briqueIdMoved = "bresultats";
            }
            if (briques[i].id === briqueIdMoved) {
              const oldPos = target.attributes.briques[i].ordre;

              target.attributes.briques[i].ordre = newPos;
              for (let j = 0; j < briques.length; j++) {
                if (briques[j].ordre === newPos) {
                  const briqueLost = target.attributes.briques[j];

                  target.attributes.briques[j].ordre = oldPos;
                  target.attributes.briques[j] = target.attributes.briques[i];
                  target.attributes.briques[i] = briqueLost;
                  break;
                }
              }
              break;
            }
          }
          this._deleteFictives(target);
          target.set("id", "briques"); //Force PUT
          target.setHabContext(new CWHabilitationContext({
            onglet: this.context.ctxEcran,
            foncCour: this.context.ctxHabilitation.HabilitationAcces,
            natGest: ""
          }));
          target.save(null, {
            success: () => {
              this.model.trigger("refresh:Briques_" + objs.appRt.workflow.get("zone"), false);
            }
          });
        }
      });
    }
  }

  // CASE 2 : Save all items. Delete if it isn't used.
  _loadDataHomeAndUpdateAllItems(briqueIdOrder: string[]): void {
    const value = this.model.get("value");

    value.setHabContext(this.model.getHabContext());
    if (this.canAccess()) {
      value.espaceid = this.context.espaceid; // ‘EM_00009’ pour ‘Mon Compte’
      //	‘EM_00022’ pour ‘Mon Equipe’
      value.fetch({
        success: () => {
          const target = this.model.get("value");
          const briques = target.get("briques");
          let briqueResultadCode = "";
          let changeOrder = false;

          for (let i = 0; i < briqueIdOrder.length; i++) {
            if (briqueIdOrder[i].substring(0, 10) === "bresultats") {
              briqueResultadCode = briqueIdOrder[i].substring(briqueIdOrder[i].indexOf("_codeb_") + 7);
            }
            for (let j = 0; j < briques.length; j++) {
              if (!CWSTR.isBlank(briqueResultadCode) && !CWSTR.isBlank(briques[j].personnalisation)) {
                if (briqueResultadCode === briques[j].personnalisation.codebrique) {
                  changeOrder = true;
                }
              } else if (briqueIdOrder[i] === briques[j].id) {
                changeOrder = true;
              }
              if (changeOrder) {
                const oldBrique = target.attributes.briques[i];

                target.attributes.briques[i] = target.attributes.briques[j]
                target.attributes.briques[i].ordre = i + 1;
                target.attributes.briques[j] = oldBrique;
                briqueResultadCode = "";
                changeOrder = false;
                break;
              }
            }
          }
          this._deleteFictives(target);
          target.set("id", "briques"); //Force PUT
          target.setHabContext(new CWHabilitationContext({
            onglet: this.context.ctxEcran,
            foncCour: this.context.ctxHabilitation.HabilitationAcces,
            natGest: ""
          }));
          target.save(null, {
            success: () => {
              this.model.trigger("refresh:Briques_" + objs.appRt.workflow.get("zone"), false);
            }
          });
        }
      });
    }
  }

  _deleteFictives(target: CWPersoBriquesDeleteModel): void {
    for (let i = target.get("briques").length; i > 0; i--) {
      if (this._isObjectFictive(target.get("briques")[(i - 1)]) === true) {
        target.get("briques").splice((i - 1), 1);
      }
    }
  }

  _isObjectPublic(obj: { [key: string]: any }): boolean {
    let publ = false;

    if (!CWSTR.isBlank(obj.personnalisation) && obj.personnalisation.visibilite === true) {
      publ = true;
    }
    return publ;
  }

  _isObjectFictive(obj: { [key: string]: any }): boolean {
    let fictive = false;

    if (!CWSTR.isBlank(obj.personnalisation) && CWSTR.isBlank(obj.personnalisation.userid) && CWSTR.isBlank(obj.personnalisation.codebrique)) {
      fictive = true;
    }
    return fictive;
  }

  /**
   * Function that manages button clicked in right form
   */
  _buttonAction(buttonId: string, position: number): void {
    const target = this.model.get("value");
    const context = this._getParametrageContext(position);
    let lShowConfirmOptions: ShowConfirmOptions = { text: "" };//initialisation

    switch (buttonId) {
      case "detail":
        context.ctxModeInitialisation = "Consultation";
        this._openParametragePopUp(context);
        break;
      case "creer":
        context.ctxModeInitialisation = "Ajout";
        this._openParametragePopUp(context);
        break;
      case "supprimer":
        lShowConfirmOptions = {
          text: i18n.t('common:delconfirm'),
          buttons: { yes: true, no: true },
          callback: (result: string): void => {
            if (result === "Y") {
              const deleteTarget = new CWPersoBriquesDeleteModel();
              const deleteObj = target.get("briques")[position];
              const id = deleteObj.personnalisation.userid + "," + deleteObj.personnalisation.codebrique;

              deleteTarget.id = id;
              deleteTarget.set("id", id);
              deleteTarget.setHabContext(new CWHabilitationContext({
                onglet: this.context.ctxEcran,
                foncCour: this.context.ctxHabilitation.HabilitationGestion,
                natGest: "S"
              }));
              deleteTarget.destroy({
                success: () => {
                  this._loadData();
                },
                wait: true
              });
            }
          },
          appendTo: this.$appendTo
        }
        CWMSGS.showConfirm(lShowConfirmOptions);
        break;
      default:
      //Nothing
    }
  }

  _getParametrageContext(position: number): { [key: string]: any } {
    const currentBrique = this.model.get("value").get("briques")[position];
    const userid = (currentBrique.personnalisation && !CWSTR.isBlank(currentBrique.personnalisation.userid)) ? currentBrique.personnalisation.userid : parseInt(objs.appRt.workflow.authModel.get("user"), 10);
    const codebrique = !CWSTR.isBlank(currentBrique.personnalisation) ? currentBrique.personnalisation.codebrique : "";
    const typebrique = !CWSTR.isBlank(currentBrique.personnalisation) ? currentBrique.personnalisation.typebrique : "";
    const ctxHabilitation = this.context.zone === "coll" ? { HabilitationAcces: "PER_BRACC_C.V", HabilitationGestion: "PER_BRACC_C.G" } : { HabilitationAcces: "PER_BRACC_R.V", HabilitationGestion: "PER_BRACC_R.G" };
    const context = {
      ctxEcran: this.context.ctxEcran,
      ctxHabilitation: ctxHabilitation,
      currentBrique: currentBrique,
      espaceid: this.context.espaceid,
      zone: this.context.zone,
      zoneName: this.context.zoneName,
      ctxActionsPossibles: ["Creer", "Modifier", "Supprimer", "Dupliquer", "Activer"],
      ctxModeInitialisation: "",
      briqueName: currentBrique.nom,
      typebrique: typebrique,
      userid: userid,
      codebrique: codebrique
    };

    return context;
  }

  _openParametragePopUp(context: { [key: string]: any }): void {
    this.briquesToRefresh = [];
    if (CWSTR.isBlank(this.dialogParametrage)) {
      this.dialogParametrage = new CWDialogPopupView({
        view: CWParametrageView,
        popupType: CWDialogPopupType.AVEC_SAISIE,
        viewData: {
          context: context,
          appendTo: this.$appendTo
        }
      });
    } else {
      this.dialogParametrage.viewData = {
        context: context
      };
    }
    this.dialogParametrage.setHeight("auto");
    this.dialogParametrage.setWidth("80%");
    this.changeDialogDetected = false;
    this.dialogParametrage.open(() => {
      //What to do on close
      if (this.changeDialogDetected === true) {
        this._loadData(true, this.briquesToRefresh);
      }
    });
    this.dialogParametrage.dialog.dialog("option", "position", { my: "top", at: "top+100", of: $("#phx-container") });
    this.dialogParametrage.$el.parent().css("overflow", "visible"); //To show combo
    this.dialogParametrage.internalView.model.off("parametrageChanged");
    this.dialogParametrage.internalView.model.on("parametrageChanged", (fresh: CWListeBriquesModel, action: string) => {
      if (action === "modification") {
        this.briquesToRefresh.push(this._getPersoId(fresh, context));
      }
      this.changeDialogDetected = true;
    });
  }

  _getPersoId(briqueModel: CWListeBriquesModel, context: { [key: string]: any }): string {
    const uniqueId = context.currentBrique.id + "," + context.currentBrique.emid + "," + briqueModel.get("userid") + "," + briqueModel.get("codebrique");

    return uniqueId;
  }
}
