import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';


/**
/** * Lister l'historique de l'absence
 */
export class CWListerRegulHistoriqueColl extends CWPaginatedCollection<CWBaseModel> {

  constructor(models?: CWBaseModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(models, options);

    this.url = (): string => {
      return Configuration.restRoot + "/rest/wkf/histo/ensemble/REGULARISATION";
    };
  }
}