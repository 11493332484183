import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { STR } from 'utils/str.js';
import { WkfHistoModel } from './wkfHisto.model.js';

export var HistoEnsembleModelColl = PaginatedCollection.extend({

  model: WkfHistoModel,

  url: function() {
    var newUrl = Configuration.restRoot + "/rest/wkf/histo/ensemble/" + encodeURIComponent(this.ctxTypeEvenement);
    if (!STR.isBlank(this.evenements)) {
      newUrl += "?evenements=" + this.evenements;
    }
    return newUrl;
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  }
});
