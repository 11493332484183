import { CWGererEvtGenView } from 'src/uc/common/evenements/gerer/gererevtgen/views/cwGererEvtGen.view';
import { CWListeDetailPanelView } from 'src/core/layouts/cwListeDetailPanel.view';
import { CWListerEvenementsGeneralView } from 'common/evenements/lister/listerevenements/views/cwListerEvenementsGeneral.view';
import { CWMevenementsWorkflow } from './models/cwMevenements.workflow';
import { CWSplitPannelDefaultPlacesInterface } from 'src/core/layouts/cwSplitPanel.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWSubRoute } from 'core/routers/cwSubRoute';
import { i18n } from 'src/i18n';
import { objs } from 'src/objectsRepository';

/**
 * Configuration of the Use Case Gestion des événements
 */

/**
 * Router for the use case "mevenements"
 */
export class CWMEvenementsRouter extends CWSubRoute {


  matricule: string | number;
  workflow: CWMevenementsWorkflow;
  layout: CWListeDetailPanelView;

  listerEvtGen: CWListerEvenementsGeneralView;
  gererEvtGen: CWGererEvtGenView;

  constructor(prefix?: any, option?: any) {
    CWMEvenementsRouter.prototype.routes = (): { [key: string]: any } => {
      return {
        "mevenements": "list",
        "mevenements/new": "newMEvenements",
        "mevenements/:id": "details"
      };
    };
    super(prefix, option);
    // Initializes the main parts of the UI and its workflow
    // get matricule to use
    this.matricule = objs.appRt.workflow.authModel.get("matricule");
    this.workflow = new CWMevenementsWorkflow({}, { "matricule": this.matricule, "module": this.module });

    this.layout = new CWListeDetailPanelView({ position: 42, defaultOpeningSize: "50/50" });
  }

  list(): void {
    // If it's the first time the user enter the UC, we will present it with
    // an initial status, if not, we'll navigate to the current status of
    // the screen
    //		const firstTime = (this.workflow.get("ready") == false);
    //0 - clean screen and initializes the layout
    this._initLayoutUC();
  }

  details(code: string): void {
    const firstTime = (this.workflow.get("ready") === false);

    this.workflow.contextLister.ctxTypologieEvenement = { code: code };

    //0 - clean screen and initializes the layout
    this._initLayoutUC(function () {
      if (firstTime) {
        // ...
      }
    });
  }

  newMEvenements(): void {
    this._initLayoutUC();
  }

  _hideGererEvtGen(): void {
    if (!CWSTR.isBlank(this.workflow.gererEvtGen)) {
      this.layout.$el.find(".gererevtgen-form-inputs").hide();
      this.layout.trigger("manageUnselected", false); //<- TODO Check if "layout" is correct (this.layout.$el.find(".unselectedElementText").show();)
    }
  }

  _initLayoutUC(callback?: () => void): void {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      }
    } else {

      // listerevtgen
      const listerContext: { [key: string]: any } = {
        ctxUtilisateur: "Collaborateur",
        ctxHabilitation: {
          HabilitationAcces: "COL_EVTGEN.V",
          HabilitationGestion: "COL_EVTGEN.G"
        },
        ctxTypologieEvenement: this.workflow.contextLister.ctxTypologieEvenement,
        ctxCollab: {
          matricule: this.matricule,
          matricaux: "",
          nom: "",
          prenom: ""
        },
        ctxGestionCollective: false,
        ctxVisibilite: false,
        ctxValidation: false,
        ctxGestion: false,
        ctxValideur: false,
        ctxFiltreApplique: "A,D,H,I,R,T",
        ctxVisuStatut: true,
        ctxPeriode: null,
        ctxTriDate: "Descendant",
        ctxEcran: this.module,
        ctxPieceJointe: true
      };

      this.listerEvtGen = new CWListerEvenementsGeneralView({ context: listerContext, parentWorkflow: this.workflow });
      this.workflow.listerEvtGen = this.listerEvtGen;

      // gererevtgen
      this.workflow.matricule = this.matricule;
      this.gererEvtGen = new CWGererEvtGenView({ context: this.workflow.contextGerer, parent: this });
      this.workflow.gererEvtGen = this.gererEvtGen;
      this.workflow.on("hideGererEvtGen", this._hideGererEvtGen, this);

      this.workflow.setUp((pJointe: string) => {
        listerContext.ctxPieceJointe = (pJointe === "1");
        this.workflow.listerEvtGen.setContext(listerContext);

        //When the workflow is ready, we can render the view without concerns
        $("#" + this.module).append(this.layout.el);
        this.layout.render();
        $(".l-splitA__panelA  .l-left-pannel-entete", this.layout.el).addClass('justify-content-between');
        $(".l-panelA-entete", this.layout.el).html(i18n.t('common:listerabsencesouhait.lefttitle'));
        //listerEvtGen.render();
        $(".l-splitA__panelA .l-left-pannel-entete", this.layout.el).after(this.listerEvtGen.getCustomBar().el);
        $(".l-panelA-filter", this.layout.el).html(this.listerEvtGen.getfilter().el);
        $(".l-panelA-liste", this.layout.el).html(this.listerEvtGen.render().el);

        //Component
        $(".l-panelB-content", this.layout.el).html(this.gererEvtGen.render().el);

        const buttonPossition: Array<CWSplitPannelDefaultPlacesInterface> = [
          {
            buttonName: "nouveau",
            position: "left_top"
          },
          {
            buttonName: "new",
            position: "left_top"
          },
        ];

        this.layout.autoRedistributeButtons(this.gererEvtGen.getButtonBarRef(), buttonPossition);
        const changeLayoutTitle = (selectedTypo: { [key: string]: any } | string): void => {
          let newTitle = "";
          if (typeof selectedTypo === "string") {
            newTitle = selectedTypo;
          } else {
            newTitle = selectedTypo.intitule;
            /* //If you need to remove the title of selected type of event, uncoment this
            let title = "";
            if (selectedTypo && selectedTypo.nbre > 0) {
              title = selectedTypo.intitule;
            }
            $(".l-panelB-entete", this.layout.el).find(".typeEvtGen").html(title);*/

          }
          $(".l-panelB-entete", this.layout.el).find(".typeEvtGen").html(newTitle);

        };
        this.listenTo(this.listerEvtGen.workflow, "selectedTypoChanged", (selectedTypo: { [key: string]: any }) => {
          changeLayoutTitle(selectedTypo);
          this.gererEvtGen.trigger("selectedTypoChanged", selectedTypo);
          //Remove the histo etiquette 
          $(".l-panelB-buttonContainer", this.layout.el).html("");
        });

        this.listenTo(this.listerEvtGen.workflow, "manageUnselected", (mode: boolean) => {
          this.gererEvtGen.trigger("manageUnselected", mode);
        });

        this.listenTo(this.gererEvtGen, "setLayoutTitle", (newTitle: string) => {
          changeLayoutTitle(newTitle);
        });


        this.workflow.contextGerer.ctxModeInitialisation = "";
        this.workflow.contextGerer.ctxIdentifiantEvt = null;
        this.workflow.contextGerer.ctxTypologieEvenement = null;
        this.workflow.contextGerer.ctxValeursEvenement = {};
        this.workflow.contextGerer.ctxValeursCreation = {};
        this.workflow.contextGerer.ctxHistoriqueWKF = false;
        this.workflow.contextGerer.ctxPieceJointe = (pJointe === "1");
        this.workflow.contextGerer.ctxEcran = this.module;
        this.gererEvtGen.setContext(this.workflow.contextGerer);

        //Display title of absence on the layout
        $(".l-panelB-entete", this.layout.el).append($('<span>').addClass('typeEvtGen cw-titre'));
        $(".l-panelB-entete", this.layout.el).addClass('d-flex justify-content-between');

        this._hideGererEvtGen();

        if (callback) {
          callback();
        }

        this.trigger("uc:ready");
      });
    }
  }
}
