module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="cw-fieldset c-form__scrollZone">\n  <div class="form-group">\n    <label for="compteur.code" class="cw-required">'+
((__t=(i18n.t('common:parametragebresultats.table_col_compteur')))==null?'':__t)+
'</label>\n    <span class="cmbCompteur" data-column="compteur.code"></span>\n  </div>\n  <div class="form-group">\n    <label for="typeresultat.code" class="cw-required">'+
((__t=(i18n.t('common:parametragebresultats.table_col_type')))==null?'':__t)+
'</label>\n    <span class="cmbTypeRes" data-column="typeresultat.code"></span>\n  </div>\n  <div class="periodicite form-group">\n    <label for="periodicite.code" class="cw-required">'+
((__t=(i18n.t('common:parametragebresultats.table_col_periodicite')))==null?'':__t)+
'</label>\n    <span class="cmbPeriodicite" data-column="periodicite.code"></span>\n  </div>\n  <div class="periodevalo form-group">\n    <label for="periodevalo.code" class="cw-required">'+
((__t=(i18n.t('common:parametragebresultats.table_col_periode')))==null?'':__t)+
'</label>\n    <span class="cmbPeriodeValo" data-column="periodevalo.code"></span>\n  </div>  \n</fieldset>';
}
return __p;
};
