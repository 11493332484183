import * as Backbone from 'Backbone';
import TPLCommonSimplePanel from './cwSimple_panel.tpl.html';

export class CWSimplePanelView extends Backbone.View<Backbone.Model> {
  template: (params?: any) => string;

  /**
   * Constructor
   * Simple Panel View
   */
  constructor(viewOptions?: Backbone.ViewOptions<Backbone.Model> | any) {
    viewOptions.className = viewOptions.className || "l-simplePanel";
    super(viewOptions);
    this.template = TPLCommonSimplePanel;
  }

  /**
   * Renders this view
   */
  render(): CWSimplePanelView {
    $(this.el).html(this.template());
    $(this.el).attr("cid", this.cid);

    //Adjust height
    const requiredHeight = this._calculateCenterHeight();
    const elSplitA = $(this.el).find(".l-simplePanel__panelA");
    elSplitA.height(requiredHeight);
    return this;
  }

  /**
   * Calculates center height
   */
  _calculateCenterHeight(): number {
    return ($("#phx-wrap").height() - $(".cw-header").height());
  }
}
