import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';


export class CWPersoBriquesDeleteModel extends CWBaseModel {

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    if (options && options.id) {
      this.id = options.id;
    }
    this.url = (): string => {
      return Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.id);
    }
  }
}