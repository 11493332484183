import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for Brique 'Effectifs'
 */
export class CWEffectifsModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "present": 0,
      "absentprevu": 0,
      "absentnonprevu": 0,
      "indefini": 0,
      "heure": 0
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.url = (): string => {
      return Configuration.restRoot + "/rest/brique/respo/effectif";
    }
  }
}
