import { ComboBoxView2 } from 'core/components/combo/comboBoxView2.js';
import { GLOBAL_DATA } from '../globalData';
import { i18n } from '../i18n.js';
import { STR } from './str.js';
import { TYPE } from '../tda/tda.js';
import { UTILS } from './utils.js';

/**
 * Forms utilities
 */
export var FORMS = {

  /**
   * Show a red ball on the right of the labels associated to inputs with the
   * same name. See code for details.
   *
   */
  showErrors: function(jQueryRootSelector, fieldErrors, hold) {
    var $formErrors = jQueryRootSelector.find(".phx-formErrors");

    if (fieldErrors) {
      $formErrors.attr("role", "alert");
      var errorCount = this._countErrors(fieldErrors);

      // for if have a 1 or much errors
      if (errorCount === 1) {
        $formErrors.html("1" + i18n.t('common:detectedError'));
      } else {
        $formErrors.html(errorCount + i18n.t('common:detectedErrors'));
      }
      //Errors for the reader
      var errorsDescription = STR.extractObjectValues(fieldErrors);
      $formErrors.append($("<div>").css("position", "absolute").css("top", "-9999px").html(errorsDescription.join(",")));

      $formErrors.css("display", "block");
    } else {
      $formErrors.html("&nbsp;");
      $formErrors.css("display", "none");
    }

    var self = this;
    jQueryRootSelector.find("label[for]").each(
      function(index, label) {
        var labelId = $(label).attr("for").replace("\'", ""); //eslint-disable-line
        var input = jQueryRootSelector.find("#" + labelId.replace(/\./g, "\\.") + ",." + labelId.replace(/\./g, "\\."));
        /*
        if(input && input.length>1){
        	input = $(input[0]);
        }*/
        if (STR.isBlank(input) || input.length <= 0) {
          // There is a label related to an input which don't exist.
          return;
        }
        var inputId = input.attr("id");
        var fieldName = input.attr("class").split(" ")[0];

        // CUSTOMER 143376 1) Due to different names between WS error field and html input defined
        //                 is necessary to change name of html field in order to set validaton
        //                 message for it (for saise.date could be "datedeb" or "datefin" errors)
        //				   assigning directly field name of error to labelId.
        //
        //				   For component evaluation, if is not executed so model validation works.
        var isBadgeage = false;
        if (fieldName === "saisie.date") {
          if (!fieldErrors.saisie) {
            fieldName = Object.keys(fieldErrors)[0]; // CUSTOMER 143376 Assign error variable from WS
          }

          isBadgeage = true;
        }

        // If composite object "inputname.code" not find a, error value will search for "inputname"
        var errorValue = STR.getElValueFromObj(fieldErrors, fieldName) || STR.getElValueFromObj(fieldErrors, fieldName.split(".")[0]);

        // CUSTOMER 143376 2) Let everything to its original state avoiding change method's flow.
        if (isBadgeage) {
          fieldName = "saisie.date";
        }

        // If hold attribute is true don't clean errors from the form
        if (!_.isString(errorValue) && $(input).hasClass("ui-state-error") && hold !== true) {
          // clear error message

          self._cleanInputErrors(input, labelId, jQueryRootSelector, label);
        }

        if (_.isString(errorValue)) {
          // add error message
          input.removeClass("ui-state-error").removeAttr("aria-invalid");
          jQueryRootSelector.find("." + labelId.replace(/\./g, "\\.") + "-error").detach();
          input.addClass("ui-state-error").attr("aria-invalid", "true");

          if (!STR.isBlank(errorValue)) {
            if (jQueryRootSelector.is("tr") || input.hasClass("phx-inline-error")) {
              $(label).addClass("griderror");
              $(label).attr("title", errorValue);
              $(label).tooltip(_.extend({}, FORMS.tooltip, { tooltipClass: "phx-grid-tooltip-error" }));
            } else {
              // error container defined in the template to
              // personalize position of the error message
              var errorContainer = jQueryRootSelector.find("." + fieldName.replace(/\./g, "\\.") +
                "-error-container");
              var errorDiv = $("<div></div>").addClass("phx-field-error " + labelId + "-error ui-state-error");

              //CUSTOMER 170849
              if (labelId === "pwd_confirm") {
                errorDiv.css("float", "left");
              }
              //							errorDiv.attr("role","alert");
              //							errorDiv.attr("aria-atomic","true");
              errorDiv.attr("id", inputId + "-error");

              errorDiv.html(errorValue);
              if (errorContainer.length === 0) {
                var referentielParent = input.parents(".phx-selecteur-referentiel");
                if (referentielParent.length > 0) {
                  referentielParent.after(errorDiv);
                } else if (input.hasClass("phx-selecteur-activite-input") && input.next(".phx-selecteur-activite-button.ui-icon-search").length > 0) {
                  // place the error just after the input field
                  input.next(".phx-selecteur-activite-button.ui-icon-search").after(errorDiv);
                } else {
                  //CUSTOMER 166240
                  // Testing if field is date and has "datepicker" icon to place error after it
                  if (input.hasClass("hasDatepicker") && input.next().hasClass("ui-datepicker-trigger")) {
                    input = input.next();
                  }
                  // place the error just after the input field
                  input.after(errorDiv);
                }
              } else {
                // place the error inside the container
                errorContainer.html(errorDiv);
              }

            }
          }

          var viewRef = input.prop("viewRef");
          if (!STR.isBlank(viewRef) && viewRef instanceof ComboBoxView2) {

            viewRef.showErrors();
          }
        }
      }
    );
  },

  /**
   * Counts the items in object (only terminals, not count if parent)
   *
   */
  _countErrors: function(data) {
    var errors = 0;

    if (_.isObject(data) || _.isArray(data)) {
      for (var subField in data) {
        if (subField) {
          errors += this._countErrors(data[subField]);
        }
      }
    } else {
      errors = 1;
    }
    return errors;
  },

  /**
   * Clean the red balls and tooltips added to labels by the function
   *
   */
  cleanErrors: function(jQueryRootSelector, className) {
    var self = this;

    jQueryRootSelector.find(".phx-formErrors").html("&nbsp;");
    jQueryRootSelector.find(".phx-formErrors").css("display", "none");
    jQueryRootSelector.find("label[for]").each(function(index, label) {
      var labelId = $(label).attr("for").replace("\'", "").replace("/", "\\/"); //eslint-disable-line
      // If className only clean errors of that input
      if (!STR.isBlank(labelId) && (STR.isBlank(className) || labelId === className)) {
        var input = jQueryRootSelector.find("." + labelId.replace(/\./g, "\\."));
        if (input && input.length === 0) {
          input = jQueryRootSelector.find("#" + labelId.replace(/\./g, "\\."));
        }
        self._cleanInputErrors(input, labelId, jQueryRootSelector, label);
      }
    });
  },

  /**
   * Clean the exists errors of the inputs
   *
   */
  _cleanInputErrors: function(input, labelId, jQueryRootSelector, label) {

    input.removeClass("ui-state-error");

    input.find("." + labelId.replace(/\./g, "\\.")).removeClass("ui-state-error");

    jQueryRootSelector.find("." + labelId.replace(/\./g, "\\.") + "-error").detach();
    if ($(label).hasClass("griderror")) {
      $(label).removeClass("griderror");
      $(label).attr("title", "");
    }
    $(label).tooltip({ tooltipClass: "" });

    var viewRef = input.prop("viewRef");
    if (!STR.isBlank(viewRef) && viewRef.cleanErrors) {
      viewRef.cleanErrors();
    }
  },

  /**
   * Each input is associated to a corresponding label in order to meet accessibility criterias
   */
  linkLabelsToInputs: function(jQueryRootSelector) {
    jQueryRootSelector.find(":input").each(function(index, element) {
      var domEl = $(element);
      var classes = domEl.attr("class");

      if (!STR.isBlank(classes)) {
        var name = domEl.attr("class").split(" ")[0];
        var classnameSelector = null;
        var newid = null;
        var rootSelector = null;

        if (element.type === "select-one") {
          classnameSelector = name.replace(/\./g, "\\.");
          newid = _.uniqueId(name + "_uid_");
          jQueryRootSelector.find("select." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
          rootSelector = $(jQueryRootSelector.find("label[for=" + classnameSelector + "], label[for^=" + classnameSelector + "_uid_]"));
          $(rootSelector[0]).attr("for", newid);
          jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
        } else if (element.type === "textarea") {
          classnameSelector = name.replace(/\./g, "\\.");
          newid = _.uniqueId(name + "_uid_");
          jQueryRootSelector.find("textarea." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
          rootSelector = $(jQueryRootSelector.find("label[for=" + classnameSelector + "], label[for^=" + classnameSelector + "_uid_]"));
          $(rootSelector[0]).attr("for", newid);
          jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
        } else {
          // other fields case
          switch (domEl.attr("type")) {
            case "text":
              var oldId = null;
              var $label = null;
              var $posInput = null;

              classnameSelector = name.replace(/\./g, "\\.");
              newid = _.uniqueId(name + "_uid_");
              // If the input has an id we keep it, it is to solve the case were the datepicker
              oldId = domEl.attr("id");
              if (!STR.isBlank(oldId)) {
                newid = oldId;
              }
              $posInput = jQueryRootSelector.find("input." + classnameSelector);
              rootSelector = $(jQueryRootSelector.find("label[for=" + classnameSelector + "], label[for^=" + classnameSelector + "_uid_],label[datePicker-ref=" + classnameSelector + "]"));
              if ($posInput.length > 1 && rootSelector.length === $posInput.length) {
                var $posRootElementSpan = jQueryRootSelector.find("span." + classnameSelector);
                var $posRootElementDiv = jQueryRootSelector.find("div." + classnameSelector + "-info");
                var identElementSpan = ($posInput.length > 0 && $posRootElementSpan.length > 0 && $posRootElementSpan.length === $posInput.length);
                var identElementDiv = ($posInput.length > 0 && $posRootElementDiv.length > 0 && $posRootElementDiv.length === $posInput.length);
                var textLabelAnt = $(rootSelector[0]).text(); //avant modifier les valeurs

                for (let i = 0; i < $posInput.length; i++) {
                  var $posElement = $($posInput[i]);

                  if ($posElement.length > 0 && STR.isBlank($posElement.attr("id"))) {
                    var newidElement = _.uniqueId(name + "_uid_");

                    $posElement.attr("id", newidElement).attr("aria-describedby", newidElement + "-info " + newidElement + "-error");
                    $label = $(rootSelector[i]).attr("for", newidElement);
                    if (identElementSpan) {
                      $($posRootElementSpan[i]).attr("aria-label", $label.text());
                    }
                    if (identElementDiv) {
                      $($posRootElementDiv[i]).attr("id", newidElement + "-info");
                    }
                  }
                }
                if (!identElementSpan) { //l'action précédent à ce code (dans le "else" suivant)
                  $(jQueryRootSelector.find("span." + classnameSelector)[0]).attr("aria-label", textLabelAnt);
                }
                if (!identElementDiv) { //l'action précédent à ce code (dans le "else" suivant)
                  jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
                }
              } else {
                $posInput.attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
                $label = $(rootSelector[0]).attr("for", newid);
                $(jQueryRootSelector.find("span." + classnameSelector)[0]).attr("aria-label", $label.text());
                jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
              }
              break;
            case "checkbox":
              classnameSelector = name.replace(/\./g, "\\.");
              newid = _.uniqueId(name + "_uid_");
              jQueryRootSelector.find("input." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
              rootSelector = $(jQueryRootSelector.find("label[for=" + classnameSelector + "], label[for^=" + classnameSelector + "_uid_]"));
              $(rootSelector[0]).attr("for", newid);
              jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
              break;
            case "radio":
              classnameSelector = name.replace(/\./g, "\\.");
              newid = _.uniqueId(name + "_uid_");
              $(element).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
              rootSelector = $(jQueryRootSelector.find("label[for=" + classnameSelector + "], label[for^=" + classnameSelector + "_uid_]"));
              $(rootSelector[0]).attr("for", newid);
              jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
              break;
            default:
              break;
          }
        }
      }
    });
  },

  /**
   * Manage readonly fields of type input: 1 add span to show value of
   * readonly field
   */
  setInputFieldReadonly: function(fieldset, fieldname, readonly) {
    var el = fieldset.find("input." + fieldname.replace(/\./g, "\\."));

    if (!STR.isBlank(el)) {
      if (readonly === true) {
        el.attr("aria-readonly", true);
        //Resize the input
        this.autosizeInput(el);
        // Hide components
        if (el.hasClass("hasDatepicker")) {
          this.readonlyDatepicker(fieldset, fieldname, true);
        } else if (el.hasClass("phx-autocomplete-input")) {
          el.parent().find(".phx-autocomplete-button").hide();
        } else if (el.hasClass("phx-referentiel-input")) {
          el.parent().find(".phx-referentiel-button").hide();
        } else if (el.hasClass("phx-chemin-input")) {
          el.parent().find(".phx-chemin-button").hide();
        } else if (el.hasClass("phx-combobox-input")) {
          ComboBoxView2.prototype.enable.call(this, !readonly, el.parents(".phx-combobox"), fieldname);
        }
      } else {
        el.removeAttr("aria-readonly");

        this.resetSizeInput(el);

        // Show components
        if (el.hasClass("hasDatepicker")) {
          this.readonlyDatepicker(fieldset, fieldname, false);
        } else if (el.hasClass("phx-autocomplete-input")) {
          el.parent().find(".phx-autocomplete-button").show();
        } else if (el.hasClass("phx-referentiel-input")) {
          el.parent().find(".phx-referentiel-button").show();
        } else if (el.hasClass("phx-chemin-input")) {
          el.parent().find(".phx-chemin-button").show();
        } else if (el.hasClass("phx-combobox-input")) {
          ComboBoxView2.prototype.enable.call(this, !readonly, el.parents(".phx-combobox"), fieldname);
        }
      }
    }
  },

  /**
   * Manage readonly fields of type textarea: 1 add span to show value of
   * readonly field
   */
  setTextareaFieldReadonly: function(fieldset, fieldname, readonly) {
    var el = fieldset.find("textarea." + fieldname.replace(/\./g, "\\."));
    fieldset.find("span." + fieldname.replace(/\./g, "\\.")).remove();

    if (!STR.isBlank(el)) {
      if (readonly === true) {
        el.attr("readOnly", "");
        el.attr("aria-readonly", "true");
        el.css("resize", "none");
        el.css("border", "none");
      } else {
        el.removeAttr("readOnly");
        el.attr("aria-readonly", "false");
        el.css("resize", "");
        el.css("border", "");
      }
    }
  },

  /**
   * Manage readonly fields of type select: 1 add span to show value of
   * readonly field
   */
  setSelectFieldReadonly: function(fieldset, fieldname, readonly) {
    var el = fieldset.find("." + fieldname.replace(/\./g, "\\."));
    var value = $("option:selected", el).text();
    var input = null;
    var select = null;

    fieldset.find("input." + fieldname.replace(/\./g, "\\.")).remove();
    if (!STR.isBlank(el)) {
      if (readonly === true) {
        var title = el.attr("title");

        input = $("<input>").addClass(fieldname).val(value);
        input.attr("id", el.attr("id"));
        input.attr("title", title);
        input.attr("readOnly", "");
        input.attr("aria-readonly", "true");
        el.before(input);
        //Resize the input
        this.autosizeInput(input);
        select = el.filter("select");
        this.autosizeInput(select);
        select.hide();
      } else {
        input = el.filter("input");
        select = el.filter("select");
        input.remove();
        this.resetSizeInput(select);
        el.show();
      }
    }
  },

  /**
   * Assign a value from an object to another object.
   *
   */
  assignValue: function(toObject, property, value, options) {
    if (property.indexOf(".") === -1) {
      toObject[property] = value;
    } else {
      this._assignValue(toObject, property, value, options);
    }
  },

  /**
   * Assign a value from an object to another object.
   * @ private
   */
  _assignValue: function(toObject, property, value, options) {
    if (toObject) {
      var tokens = property.split(".");
      if (tokens.length > 1) {
        //Create property if not exist
        if (toObject[tokens[0]] === undefined) {
          toObject[tokens[0]] = {};
        }
        // Complex element
        if (STR.isBlank(toObject[tokens[0]])) { //If the path didn't exist we create it
          toObject[tokens[0]] = {};
        }
        this._assignValue(toObject[tokens[0]], tokens.slice(1).join("."), value, options);
      } else {
        // Simple element
        toObject[tokens[0]] = value;
      }
    }
  },

  /**
   * Delete an el value to a model
   *
   */
  removeValue: function(object, property, options) {
    if (property.indexOf(".") === -1) {
      delete object[property];
    } else {
      this._removeValue(object, property, options);
    }
  },

  /**
   * Delete an el value to a model
   */
  _removeValue: function(object, property, options) {
    //		var tempObj = this.pointToSBracket(object, property, options);
    //		var tokens = property.split(".");
    //		delete tempObj[_.last(tokens)];

    var objectValue = object;
    var tokens = property.split(".");
    if (tokens.length > 1) {
      // Complex element
      objectValue = object[tokens[0]];
      objectValue = this._removeValue(objectValue, tokens.slice(1).join("."), options);
      if (_.isEmpty(object[tokens[0]])) {
        delete object[tokens[0]];
      }
    } else {
      // Simple element
      delete objectValue[tokens[0]];
    }

  },

  /**
   * Selects the value after the last (.)
   *
   */
  pointToSBracket: function(object, property, options) {
    var objReturn = object; //ne pas initiliser à null parce qu'on utilise la valeur de "undefined" dans le core/..forms.view

    if (!STR.isBlank(object)) {
      if (property.indexOf(".") === -1) {
        objReturn = object[property];
      } else {
        objReturn = this._pointToSBracket(object, property, options);
      }
    }
    return objReturn;
  },

  /**
   * Selects the value after the last (.)
   */
  _pointToSBracket: function(object, property, options) {
    var objectValue = object;

    if (objectValue) {
      var tokens = property.split(".");

      if (tokens.length > 1) {
        // Complex element
        objectValue = object[tokens[0]];
        objectValue = this._pointToSBracket(objectValue, tokens.slice(1).join("."), options);
      } else {
        // Simple element
        return objectValue[tokens[0]];
      }
    }
    return objectValue;
  },

  /**
   * Adjust the width of the input element to the content.
   */
  autosizeInput: function(input) {
    var lInput = null;
    var value = null;

    //Store old width
    if (STR.isBlank(input.attr("old-width"))) {
      if (!input.parents(":last").is("html")) {
        var viewRef = input.prop("viewRef");
        var clon = null;

        // To calculate the combobox width I need to clone all combobox and not only the input.
        if (viewRef instanceof ComboBoxView2) {
          clon = viewRef.$el.clone();
          $("body .cloned_elements").append(clon);
          // I just take the with of the input.
          input.attr("old-width", clon.find("input").css("width"));
          clon.remove();
        } else {
          clon = input.clone();
          $("body .cloned_elements").append(clon);
          input.attr("old-width", clon.css("width"));
          clon.remove();
        }
      } else if (input.is(":hidden")) {
        if (input.css("display") === "none") {
          input.show();
          input.attr("old-width", input.css("width"));
          input.hide();
        } else if (input.parents().filter(function() { return $(this).css("display") == "none"; }).length > 0) { //eslint-disable-line
          var parent = input.parents().filter(function() { return $(this).css("display") == "none"; }); //eslint-disable-line

          for (let i = 0; i < parent.length; i++) {
            var $itemParent = $(parent[i]);
            var hiddenByClass = ($itemParent && (!$itemParent.attr("style") || ($itemParent.attr("style") && $itemParent.attr("style").indexOf("display: none") < 0)) && $itemParent.hasClass("d-none")) ? true : false;

            if (!hiddenByClass) {
              $itemParent.show();
            } else {
              $itemParent.removeClass("d-none");
            }
            input.attr("old-width", input.css("width"));
            if (!hiddenByClass) {
              $itemParent.hide();
            } else {
              $itemParent.addClass("d-none");
            }
          }
        }
      } else {
        input.attr("old-width", input.css("width"));
      }
    }
    //Remove unnecesary whitespace
    input.val($.trim(input.val()));
    lInput = $(input);
    value = lInput.val() || "";
    input.width(FORMS.getWidthFromMirror(value, lInput));
  },

  getWidthFromMirror: function(text, element) {
    var width = 0;
    var mirror = $("body .cloned_elements .cw-forms_clone_elements");

    if (!STR.isBlank(text) && mirror.length > 0 && element.length > 0) {
      mirror.text(text);
      $.each(['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'letterSpacing', 'textTransform', 'wordSpacing', 'textIndent'], function(i, val) {
        mirror[0].style[val] = element.css(val);
      });
      width = mirror.width() + 3; //un peut de espace pour l'afficher correctement
      mirror.empty(); //clean once it has been used to calculate width of each line
      mirror.removeAttr("style"); //retablir les styles      
    }
    return width;
  },

  /**
   * Return the size of the
   */
  resetSizeInput: function(input) {
    //Reset field width
    var oldWidth = input.attr("old-width");
    if (oldWidth && oldWidth !== "0px") {
      input.css("width", oldWidth);
    }
  },

  /**
   *  * Set or not set the form in readonly mode
   *
   */
  setFormReadonly: function(fieldset, readonlyForm, forcedByHabilitations) {

    fieldset.find(":input").each(function(index, element) {
      FORMS.setFieldReadonly(element, readonlyForm, forcedByHabilitations);
    });

    fieldset.find(".phx-multiLabel").each(function(index, element) {
      var viewRef = $(element).prop("viewRef");
      if (!STR.isBlank(viewRef)) {
        viewRef.setReadOnly(readonlyForm);
      }
    });

    //Remove * (required) for readonly data
    if (readonlyForm) {
      fieldset.find("label.required").css("background", "none");
    } else {
      fieldset.find("label.required").css("background", "");
    }
  },

  /**
   * Set or not set the fieldset in readonly mode
   *
   */
  setFieldReadonly: function(element, setToReadonly, forcedByHabilitations) {
    var domEl = $(element);
    if (domEl.length > 0 && !STR.isBlank(domEl.attr("class"))) {
      var name = domEl.attr("class").split(" ")[0];
      var fieldset = domEl.parent();
      // Check if I have already in readonly, for checkbox and radio they should be disabled.
      var fieldIsReadonly = domEl.is("[readonly]") || (domEl.is(":disabled") && (domEl.type === "radio" || domEl.type === "checkbox"));
      var forcedToReadonly = domEl.is("[forcedreadonly]");

      // field is readonly and the habilitation try to make it editable, don't do nothing.
      if (fieldIsReadonly && !setToReadonly && ((forcedByHabilitations && !forcedToReadonly) || (!forcedByHabilitations && forcedToReadonly))) {
        return;
      }

      // field state is correct
      if (fieldIsReadonly === setToReadonly) {
        if (forcedByHabilitations === true && setToReadonly === true) {
          domEl.attr("forcedreadonly", "");
        }
        if (forcedByHabilitations === true && setToReadonly === false) {
          domEl.removeAttr("forcedreadonly");
        }
        return;
      }

      if (element.type === "select-one" || element.type === "textarea" || (element[0] && (element[0].type === "select-one" || element[0].type === "textarea"))) {
        if (setToReadonly === true) {
          if (forcedByHabilitations === true) {
            domEl.attr("forcedreadonly", "");
          }
          // replace readonly field by span field and hide the readonly field
          if (element.type === "select-one" || (element[0] && element[0].type === "select-one")) {
            domEl.attr("readonly", "");
            FORMS.setSelectFieldReadonly(fieldset, name, true);
          }
          if (element.type === "textarea" || (element[0] && element[0].type === "textarea")) {
            domEl.attr("readonly", "");
            FORMS.setTextareaFieldReadonly(fieldset, name, true);
          }
        } else if (forcedByHabilitations === true || forcedToReadonly === false) {
          domEl.removeAttr("readonly");
          domEl.removeAttr("forcedreadonly");
          if (element.type === "textarea" || (element[0] && element[0].type === "textarea")) {
            FORMS.setTextareaFieldReadonly(fieldset, name, false);
          } else if (element.type === "select-one" || (element[0] && element[0].type === "select-one")) {
            FORMS.setSelectFieldReadonly(fieldset, name, false);
          }
        }
      } else {
        var viewRef = domEl.prop("viewRef");

        //other fields case
        switch (domEl.attr("type")) {
          case "text":
            if (setToReadonly === true) {
              domEl.attr("readonly", "");
              if (forcedByHabilitations === true) {
                domEl.attr("forcedreadonly", "");
              }
            } else if (forcedByHabilitations === true || forcedToReadonly === false) {
              domEl.removeAttr("readonly");
              domEl.removeAttr("forcedreadonly");
            }

            if (domEl.hasClass("hasDatepicker")) {
              this.readonlyDatepicker(fieldset, name, setToReadonly);
            } else if (viewRef && viewRef._enable) {
              viewRef._enable(!setToReadonly);
            } else if (domEl.hasClass("typeDate") && setToReadonly === false) {
              this.readonlyDatepicker(fieldset, name, setToReadonly);
            } else if (viewRef && viewRef.setReadOnly) {
              viewRef.setReadOnly(setToReadonly);
            } else {
              FORMS.setInputFieldReadonly(fieldset, name, setToReadonly);
            }
            break;
          case "checkbox":
          case "radio":
            domEl.attr("disabled", true);
            if (setToReadonly === true) {
              domEl.attr("readonly", true);
              if (forcedByHabilitations === true) {
                domEl.attr("forcedreadonly", "");
              }
            } else if (forcedByHabilitations === true || forcedToReadonly === false) {
              domEl.removeAttr("readonly");
              domEl.removeAttr("disabled");
              domEl.removeAttr("forcedreadonly");
            }
            break;
          default:
            break;
        }
      }
    }
  },

  /** WIDGETS **/

  /**
   * Tooltip global configuration
   */
  tooltip: {
    /**
     * Whether the tooltip should track (follow) the mouse.
     */
    track: true,

    position: {
      my: "left+10 top+10",
      at: "left bottom",
      collision: "flipfit"
    },
    /**
     * Method executed when a tooltip is opened.
     */
    open: function(event, ui) {
      $(this).data("tooltip", true);

      // Remove completely "title" attribute to avoid
      // issue in Edge showing an empty box
      var uiId = ui.tooltip.attr("id");
      var $target = $(document).find("[aria-describedby='" + uiId + "']");
      $target.removeAttr("title");
      if (event.originalEvent &&
        event.originalEvent.target) {
        if ($(event.originalEvent.target).hasClass("ui-state-error")) {
          ui.tooltip.addClass("ui-state-error");
        }
      }
    },
    /**
     * Method executed when a tooltip is closed.
     */
    close: function() {
      $(this).data("tooltip", false);
    },
    /**
     * Method executed to get the content of the tooltip from an element.
     */
    content: function() {
      return $(this).prop('title');
    }
  },

  /**
   * Default configuration for the datepickers used by setDatepicker(...)
   */
  datepicker: function() {
    var lan = UTILS.getLanguage();
    if (lan === "en") {
      // This patch is needed because jQuery Ui return
      // undefined for "en". The english options are returned
      // for "".
      lan = "";
    }

    var phoenixArgs = {
      yearRange: "1899:2100",
      showOn: "button",
      //buttonImageOnly : true,
      buttonText: "&nbsp;<span class='ui-button-icon-primary ui-icon ui-icon-calendar'></span>",
      //In JQuery Datepicker the 4 digits year format is 'yy'
      dateFormat: GLOBAL_DATA.types.get("DATE").get("masque").toLowerCase().replace("yyyy", "yy"),
      minDate: new Date(1899, 12 - 1, 31),
      onClose: function() {
        $(this).blur();
      },
      // Regional options got from the internationalization
      closeText: i18n.t('common:close'),
      prevText: i18n.t('common:precedent'),
      nextText: i18n.t('common:suivant'),
      currentText: i18n.t('common:aujourd_hui'),
      monthNames: i18n.t('common:monthNames', { returnObjects: true }),
      monthNamesShort: i18n.t('common:monthNamesShort', { returnObjects: true }),
      dayNames: i18n.t('common:dayNames', { returnObjects: true }),
      dayNamesShort: i18n.t('common:dayNamesShort', { returnObjects: true }),
      dayNamesMin: i18n.t('common:dayNamesInitialCap', { returnObjects: true }),
      weekHeader: i18n.t('common:semAbr'),
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
    };
    return _.extend({}, phoenixArgs);
  },

  /**
   * Set a date picker to an element in a view and adds the button icon
   */
  setDatepicker: function(view, element, options) {
    if (view && view.typeMaskByClass && !STR.isBlank(element)) {
      if (!options || !options.dateFormat) {
        var target = element.substring(1);
        var typeMask = view.typeMaskByClass[target];
        if (typeMask) {
          if (!options) {
            options = {};
          }
          options.dateFormat = TYPE._getMaskByCode(typeMask).toLowerCase().replace("yyyy", "yy");
        }
      }
    }

    var self = this;
    var before = {
      beforeShow: function(input) {
        // Force href click triggering
        $('.ui-tabs-nav a[href^="#"]').off("click.hideDatepickers");
        $('.ui-tabs-nav a[href^="#"]').on("click.hideDatepickers", function() {
          $(view.el).trigger("hrefOuterClick");
        });
        // href click hides datepicker
        $(view.el).off("hrefOuterClick");
        $(view.el).on("hrefOuterClick", function() {
          $.datepicker._hideDatepicker();
          $(view.el).off("hrefOuterClick");
          $('.ui-tabs-nav a[href^="#"]').off("click.hideDatepickers");
        });

        if ($(input).hasClass("periodEnd") && STR.isBlank(input.valueAsDate)) {
          var attr = input.className.split(" ")[0];
          var maskCode = TYPE._getMaskCode(attr, this, "typeDate"); //get Format Mask
          var parse = TYPE.DATE.parse(input.value, maskCode); //Parse the input value
          var periodId = $(input).attr("periodId");
          var startDate = view.$el.find(".periodStart[periodId=" + periodId + "]");
          var startDateName = startDate.attr("class").split(" ")[0];
          var startDateValue = STR.getElValue(view._getModel(), startDateName);

          if (!STR.isBlank(startDateValue) && (STR.isBlank(parse["val"]) || (parse["val"] === TYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1"))) {
            FORMS.modifyDatepicker(view, ".periodEnd[periodId=" + periodId + "]", { "defaultDate": TYPE.DATE.strToDate(startDateValue) });
          }
        }
        $(document).on("mousewheel", function() {
          $.datepicker._hideDatepicker();
          $(document).off("mousewheel");
        });
        if (UTILS.isFirefox()) {
          $(document).on('wheel', function() {
            $.datepicker._hideDatepicker();
            $(document).off('wheel');
          });
        }
      }
    };

    /** Datepicker FIX to multiple retrieved items with the same name  **/
    $(view.el).find(element).each(function(index, it) {
      var datePickerOptions = _.clone(self.datepicker());
      var onSelect = {
        onSelect: function() {
          $(it).blur();
          $(it).focus();
        }
      }

      if (options && options.onSelect) {
        onSelect = options.onSelect;
      }

      if (options) {
        datePickerOptions = _.extend(datePickerOptions, options, before, onSelect);
      } else {
        datePickerOptions = _.extend(datePickerOptions, before, onSelect);
      }
      $(it).datepicker(datePickerOptions);
    });

    $(view.el).find("button.ui-datepicker-trigger").addClass('ui-button ui-corner-right ui-button-icon-only');
    $(view.el).find("button.ui-datepicker-trigger").attr('tabindex', "0");
    $(view.el).find("button.ui-datepicker-trigger").attr('aria-label', i18n.t('common:ARIA_label.datepicker'));
    // auto-size the datepicker when placed inside a table
    $(view.el).find(element).parents("td").addClass("wider");
  },

  /**
   * Change a date picker options
   */
  modifyDatepicker: function(view, element, options) {
    $(view.el).find(element).datepicker("option", options);
    $(view.el).find("button.ui-datepicker-trigger").addClass('ui-button ui-corner-right ui-button-icon-only');
    $(view.el).find("button.ui-datepicker-trigger").attr('aria-label', i18n.t('common:ARIA_label.datepicker'));
  },

  /**
   * Disable/Enable a datePicker element
   */
  disableDatepicker: function(view, element, disabled) {
    $(view.el).find(element).datepicker("option", "disabled", disabled);
    $(view.el).find("button.ui-datepicker-trigger").addClass('ui-button ui-corner-right ui-button-icon-only');
    if (disabled) {
      $(view.el).find(element).addClass("ui-state-disabled");
    } else {
      $(view.el).find(element).removeClass("ui-state-disabled");
    }
  },

  /**
   * Make/remove datePicker element as readonly
   */
  readonlyDatepicker: function(view, className, readonly) {
    var $el = $(view);
    var $element = $el.find("." + className.replace(/\./g, "\\."));
    var oldValue = $element.val();
    if (readonly) {
      $element.attr("readonly", "");
      $element.datepicker("option", "showOn", '');
      $element.parents("td").removeClass("wider");
    } else {
      $element.removeAttr("readonly");
      $element.datepicker("option", "showOn", "button");
      $el.find("button.ui-datepicker-trigger").addClass('ui-button ui-corner-right ui-button-icon-only').attr('aria-label', i18n.t('common:ARIA_label.datepicker'));
      //			 auto-size the datepicker when placed inside a table
      $element.parents("td").addClass("wider");
      $element.siblings(".ui-datepicker-trigger").addClass("ui-datepicker-trigger ui-button ui-corner-right ui-button-icon-only");

    }
    // Reset value
    $element.val(oldValue);

    // Don't move this. Autosize should be done after "Reset value" 
    if (readonly) {
      this.autosizeInput($element);
    } else {
      //# customer 146685 - fixed also the readonly size
      var datePicker = view.find("input." + className.replace(/\./g, "\\."));
      this.resetSizeInput(datePicker);
    }
  },

  /**
   * Disable an element
   */
  disableElement: function(view, element) {
    $(view.el).find(element).prop("disabled", true);
    $(view.el).find(element).addClass("ui-state-disabled");
  },

  /**
   * Enable an element
   */
  enableElement: function(view, element) {
    $(view.el).find(element).prop("disabled", false);
    $(view.el).find(element).removeClass("ui-state-disabled");
  }
};
