module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li role="menuitem" tabindex="-1" class="phx-menu-link phx-hover ui-corner-all">\n  <a tabindex="-1" href="'+
((__t=(href))==null?'':__t)+
'" class="'+
((__t=(id))==null?'':__t)+
' phx-hover ui-corner-all" target="'+
((__t=(target))==null?'':__t)+
'">- '+
((__t=(nom))==null?'':__t)+
'</a>\n</li>\n';
}
return __p;
};
