import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';

/**
 * Model for referentiel of activity families (UX)
 */
export class CWFamilleActiviteModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "code": null,
      "libelle": ""
    }
  }

  validate(attrs: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    if (CWSTR.isBlank(attrs.code)) {
      errors.code = i18n.t('common:required', { "0": i18n.t('common:referentiel_famille_activite.table_selectref_code') });
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
}
