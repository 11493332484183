import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLPanneauAffectationRempla from '../panneauAffectationRempla.tpl.html';
import { CWAffectationEnCoursModel } from '../models/cwAffectationEnCours.model';
import { CWAffectationRemplaColl } from '../models/cwAffectationRempla.collection';
import { CWBaseGridColums, CWBaseGridOptions } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWDataGridModel } from 'core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'core/grids/data_grid/cwDataGrid.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { DIVERS } from 'src/utils/divers.js';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'utils/sync.js';


/**
 * View of Brique 'Resultats'
 */
export class CWPanneauAffectationRemplaView extends Backbone.View<Backbone.Model>{

    template: (params?: any) => string;
    collAffRempla: CWAffectationRemplaColl | any;
    parent: any;
    structureSelected: any;
    usePopulation: boolean;
    habContext: CWHabilitationContext;
    table: CWDataGridView;
    idCollab: string;

    constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
        params = params || {};
        // params.events = _.extend({
        //     "click button": "_clickListener"
        // }, params.events);
        super(params);
        this.template = TPLPanneauAffectationRempla;
        this.usePopulation = params.usePopulation || false;
        if (CWSTR.isBlank(params.habContext)) {
            this.habContext = new CWHabilitationContext({
                onglet: "baffec",
                foncCour: "COL_AFFPRINC.V", // eslint-disable-line
                natGest: "" // eslint-disable-line
            });
        } else {
            this.habContext = params.habContext;
        }
        this.collAffRempla = params.coll || null;
        if (CWSTR.isBlank(this.collAffRempla)) {
            this.collAffRempla = new CWAffectationRemplaColl();
            this.collAffRempla.usePopulation = this.usePopulation;
            this.collAffRempla.setHabContext(this.habContext);
        }
        this.parent = params.parent || null;
        this.table = this._initTable();
        //this.useMatricaux = (DIVERS.get("idCollab") === "matric_aux"); // eslint-disable-line
        this.idCollab = DIVERS.get("idCollab").replace('_', '') || "matric";
    }


    render(): CWPanneauAffectationRemplaView {
        const json = { 'i18n': i18n };

        this.$el.html(this.template(json));
        this.$el.find(".table_AutresAffectationsRemplacements").html(this.table.el);
        this.table.render();
        this.$el.css({ "max-height": "100px", "max-width": "600px", "width": "580px" });
        this.$el.find(".phx-th00").css({ "padding": "0px" });
        return this;
    }

    _initTable(): CWDataGridView {
        const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
        const isMatricAux = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur") === "matric_aux" ? true : false;
        const tableModel = new CWDataGridModel({
            coll: this.collAffRempla
        });
        const tblColumns: Array<CWBaseGridColums> = [
            { title: "", property: "pret", width: 12 },
        ];
        const table = new CWDataGridView({
            id: "baffec_tableRempla",
            columns: tblColumns,
            model: tableModel,
            showFilteredRowsInTitle: false,
            title: "",
            gridHeightType: "auto",
            maxVisibleRows: 4,
            habilitations: "COL_PRET.V",
            selectable: false,
            sansSelection: true
        } as CWBaseGridOptions);

        tableModel.coll.usePopulation = this.usePopulation;
        table.setSortableColumns([]);
        //To show a different value in the cell
        table.cellRenderers["pret"] = (model: CWAffectationEnCoursModel): string => {
            let lRtn = "";
            let lContent = "";
            let lInfo = "";


            if (model && !CWSTR.isBlank(model.get("pret")) && !CWSTR.isBlank(model.get("pret").collaborateur)) {
                const lCollab = model.get("pret").collaborateur;
                const matricToShow = isMatricAux ? lCollab.matricaux : lCollab.matric;

                lContent = lCollab.nom + " " + lCollab.prenom + " (" + /*lCollab[this.idCollab]*/ matricToShow + ")";
            }
            if (!CWSTR.isBlank(lContent)) {
                lContent += " ";
            }
            lInfo = this._formattedRemplacementLines(model);
            if (model.get("datedeb") <= today && today <= model.get("datefin")) {
                if (!CWSTR.isBlank(lInfo)) {
                    lContent += $(lInfo).addClass("cw-texteAllege--selectionne")[0].outerHTML;
                }
                lRtn = "<span class='cw-texteAllege--selectionne'>" + lContent + "</span>";
            } else {
                if (!CWSTR.isBlank(lInfo)) {
                    lContent += $(lInfo).addClass("cw-texteAllege")[0].outerHTML;
                }
                lRtn = "<span class='cw-texteAllege'>" + lContent + "</span>";
            }
            return lRtn;
        };
        // set habilitations context that the table collection will use
        tableModel.coll.setHabContext(this.habContext);
        return table;
    }

    _formattedRemplacementLines(model: CWAffectationEnCoursModel): string {
        const text = [];
        let temporelle = "";
        const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
        let lfuncFormat = (value: string): string => {
            return value.toLowerCase();
        };

        // Indication temporelle
        if (model.get("pret") && model.get("pret").typesaisie) {

            if (model.get("pret").typesaisie === "C" && (CWSTR.isBlank(model.get("pret").heuredeb) && CWSTR.isBlank(model.get("pret").heurefin))) {

                if (model.get("pret").unitedeb && model.get("pret").unitedeb.code === "D" && (model.get("pret").unitefin && model.get("pret").unitefin.code === model.get("pret").unitedeb.code)) {
                    temporelle += "<span class='cw-texteAllege'>"
                        + i18n.t('common:baffec.du_au_2p', { //GT_1184
                            "1": CWTYPE.DATE.format(model.get("pret").datedeb, CWTYPE._getFormatByCode("DATE_A")),
                            "3": CWTYPE.DATE.format(model.get("pret").datefin, CWTYPE._getFormatByCode("DATE_A"))
                        })
                        + "</span>";
                } else if (model.get("pret").unitedeb && model.get("pret").unitedeb.code !== "D" &&
                    (model.get("pret").unitefin && model.get("pret").unitefin.code !== "D")) {
                    temporelle += "<span class='cw-texteAllege'>"
                        + i18n.t('common:baffec.du_au_4p', { //GT_1187
                            "1": CWTYPE.DATE.format(model.get("pret").datedeb, CWTYPE._getFormatByCode("DATE_A")),
                            "2": model.get("pret").unitedeb.libelle,
                            "3": CWTYPE.DATE.format(model.get("pret").datefin, CWTYPE._getFormatByCode("DATE_A")),
                            "4": model.get("pret").unitefin.libelle, interpolation: { format: lfuncFormat }
                        }, { reset: true })
                        + "</span>";
                }
            } else if (model.get("pret").typesaisie === "D") { //} model.get("datedeb") <= today <= model.get("datefin"))) {
                let selectionneClass = "";
                if (CWTYPE.DATE._checkDateInPeriod(model.get("datedeb"), model.get("datefin"), today)) {
                    selectionneClass = "--selectionne"
                }

                if (model.get("pret").modesaisie === "UNITE" &&
                    (model.get("pret").unitedeb && model.get("pret").unitefin
                        && model.get("pret").unitedeb.code === "D"
                        && model.get("pret").unitefin.code === model.get("pret").unitedeb.code)) {

                    temporelle += "<span class='cw-texteAllege" + selectionneClass + "'>"
                        + i18n.t('common:baffec.le_1p', { //GT_1181
                            "1": CWTYPE.DATE.format(model.get("pret").datedeb)
                        })
                        + "</span>";
                } else if (model.get("pret").modesaisie === "UNITE" &&
                    (model.get("pret").unitedeb && model.get("pret").unitefin
                        && model.get("pret").unitedeb.code !== "D"
                        && model.get("pret").unitefin.code === model.get("pret").unitedeb.code)) {
                    temporelle += "<span class='cw-texteAllege" + selectionneClass + "'>"
                        + i18n.t('common:baffec.le_2p', { //GT_1180
                            "1": CWTYPE.DATE.format(model.get("pret").datedeb, CWTYPE._getFormatByCode("DATE_A")),
                            "2": model.get("pret").unitedeb.libelle
                        })
                        + "</span>";
                } else if ((model.get("pret").modesaisie === "PLH")) {
                    temporelle += "<span class='cw-texteAllege" + selectionneClass + "'>"
                        + i18n.t('messages:GT_1721', {
                            "1": "<span class='cw-texteAllege" + selectionneClass + "'>" + CWTYPE.HOUR_MINUTE.format(model.get("pret").heuredeb) + "</span>",
                            "2": "<span class='cw-texteAllege" + selectionneClass + "'>" + CWTYPE.HOUR_MINUTE.format(model.get("pret").heurefin) + "</span>",
                            "3": "(<span class='cw-texteAllege" + selectionneClass + "'>" + CWTYPE.DURATION.HOUR_MINUTE.format(model.get("pret").duree) + "</span>"
                        })
                        + "</span>";

                } else if (model.get("pret").modesaisie === "DUREE") {
                    temporelle += "<span class='cw-texteAllege" + selectionneClass + "'>(" + CWTYPE.DURATION.HOUR_MINUTE.format(model.get("duree")) + ")</span>";
                } else if (model.get("pret").modesaisie === "POURCENT") {
                    temporelle += "<span class='cw-texteAllege" + selectionneClass + "'>" + CWTYPE.PERCENTAGE.format(model.get("pret").pourcentage, null) + "</span>";
                }
            }

        }
        if (!_.isEmpty(temporelle)) {
            text.push(temporelle.replace(',', ''));
        }
        lfuncFormat = null;
        return text.join(", ");
    }
}