module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="calendacc-footer">\n\n  <!-- <span class="calendacc-footer-title-jours cw-texteImportant">'+
((__t=(i18n.t('calendacc.jours')))==null?'':__t)+
'</span> -->\n  \n  <div class="ui-jours-ferie typeFe container">\n    <div class="cw-texteAllege">'+
((__t=(i18n.t('calendacc.jourFe')))==null?'':__t)+
'</div>\n  </div>\n  \n\n  <div class="ui-phx-jours-exceptionnel typeEx container">\n    <div class="cw-texteAllege">'+
((__t=(i18n.t('calendacc.jourEx')))==null?'':__t)+
'</div>\n  </div>\n  \n\n  <span class="calendacc-footer-title-zones cw-texteImportant">'+
((__t=(i18n.t('calendacc.zones')))==null?'':__t)+
'</span>\n\n  <div class="zoneSc">\n    \n  </div>\n</div>\n';
}
return __p;
};
