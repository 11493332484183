import { BaseModel } from './base.model.js';

/**
 * Model for Read only purposes, for example a ComboBox
 */
export var ReadOnlyModel = BaseModel.extend({

  habilitationV: "",
  habilitationG: "",
  usecase: ".",

  /**
   * Show error if an object that extends this models tries to be saved
   */
  save: function() {
    throw new Error("Save operation not implemented in read only Model");
  },

  /**
   * Show error if an object that extends this models tries to be destroyed
   */
  destroy: function() {
    throw new Error("Destroy operation not implemented in read only Model");
  }

});
