import _ from 'underscore';
import GererpiecesjointesGererpiecesjointesTPL from '../gererpiecesjointes.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWEditableGridModel } from 'core/grids/editablegrid/cwEditableGrid.model';
import { CWGererpiecesjointesWorkflow } from '../models/cwGererpiecesjointes.workflow';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMenuGridView } from 'core/grids/menugrid/cwMenuGrid.view';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPieceJointeType } from 'src/uc/common/piecesjointes/views/cwPieceJointes.view';
import { CWPiecesJointesColl } from '../models/cwPiecesJointes.collection';
import { CWRemoteFileModel } from 'core/models/cwRemoteFile.model';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { View, ViewOptions } from 'Backbone';


/**
 *
 * Render gerer pieces jointes view
 */
export interface CWGererPiecesJointesViewOptions extends ViewOptions<CWBaseModel> {
  context?: { [key: string]: any };
  coll?: any; //TOOD
  parent?: CWDialogPopupView;
  fileTransferView?: View;
  description?: string;
  maxPieces?: number;
}

export class CWGererPiecesJointesView extends CWBaseFormView<CWBaseModel> {
  private workflow: CWGererpiecesjointesWorkflow;
  private context: { [key: string]: any };
  private coll: any; //TOOD
  private parent: CWDialogPopupView;
  private fileTransferView: View;
  private description: string;
  private maxPieces: number;
  private table: CWMenuGridView<CWRemoteFileModel>;
  public diver: string;
  public $appendTo: JQuery; //pour les messages

  constructor(options?: CWGererPiecesJointesViewOptions) {
    options = options || {};
    options.events = _.extend({
      "click div.phx-piece-jointe span.piece-jointe-file": "_manageFileClick",
    }, options.events);
    super(options);
    this.template = GererpiecesjointesGererpiecesjointesTPL;
    this.model = new CWBaseModel({
      value: new CWRemoteFileModel({
        coll: new CWPiecesJointesColl()
      }),
      mode: "C"
    });
    this.workflow = new CWGererpiecesjointesWorkflow();
    this.workflow.gererPiecesJointes = this;
    this.workflow.setUp();
    this.context = options.context;
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (options.coll) {
      this.coll = this._listToCollection(options.coll);
    }
    this.parent = options.parent;
    this.fileTransferView = options.fileTransferView;
    this.description = options.description;
    this.maxPieces = options.maxPieces;
    this.table = this._initTable();
    this._initFileTransfer();
    this.$el.on("click", function (event) { //Prevent close popup if click inself
      event.stopPropagation();
    });
    $(document).on("click" + (this.context?.ctxEcran ? ("." + this.context.ctxEcran) : ""), (event: JQuery.TriggeredEvent) => {
      if (this.context.ctxFermetureAuto) {
        this.parent.close();
        $(this).off(event);
      }
    });
  }

  _initFileTransfer(): void {
    if (this.fileTransferView) {
      this.fileTransferView.model.off("uploadReady");
      this.fileTransferView.model.on("uploadReady", (): void => {
        if (this.parent) {
          this.model.trigger("piecejointeAdded", this.fileTransferView);
          this.fileTransferView = null;
          this.parent.close(null);
        }
      }, this);
      this.fileTransferView.model.on("cancelUpload", () => {
        if (this.parent) {
          //this.model.trigger("piecejointeAdded", this.fileTransferView);
          this.fileTransferView = null;
          this.parent.close();
        }
      }, this);
    }
  }

  _listToCollection(list: Array<CWPieceJointeType>): CWPiecesJointesColl {
    const collection = new CWPiecesJointesColl();

    if (list) {
      _.each(list, function (item) {
        if (item.deleted !== true) {
          const model = new CWRemoteFileModel(item);
          collection.add(model);
        }
      }, this);
    }
    collection.paginated = false;
    return collection;
  }

  setContext(context: { [key: string]: any }): void {
    this.context = context;
    this.parent._setTitle(i18n.t('common:gererpiecesjointes.piecejointe_title'));
  }

  setPJ(coll: Array<CWPieceJointeType>, description: string): void {
    this.coll = this._listToCollection(coll);
    this.description = description;

    this.setContext(this.context);

    this.table.model.coll.reset(this.coll.models);
  }

  render(): CWGererPiecesJointesView {
    const json = { "i18n": i18n, model: this.model.get("value") };
    this.$el.empty();

    this.$el.html(this.template(json));

    if (this.context) {
      this.setContext(this.context);
    }
    if (this.fileTransferView) {
      $(this.el).find(".file_transfer").hide();
      $(this.el).find(".file_transfer").html(this.fileTransferView.render().el);
    }
    $(this.el).find(".table_pieces_jointes").html(this.table.el);
    this.table.render();
    this.table.setTotalRowsVisible(false);

    // Customize the new action of the buttonBar
    this.table.buttonBar.buttons.habilitation["new"] = this.canAddPieceJointe();
    this.table.buttonBar.buttons.habilitation["delete"] = this.canDeletePieceJointe();
    this.table.buttonBar.model.set("mode", "C");
    //Check Items Limit
    this._checkItemLimit();

    this.table.overrideMenu(this._customBtnAction, this);

    if (this.fileTransferView) {
      $(this.el).find(".table_pieces_jointes").hide();
      $(this.el).find(".file_transfer").show();
    }

    if (!this.canAddPieceJointe()) {
      $(this.el).find(".table_pieces_jointes").show();
      $(this.el).find(".file_transfer").hide();
    }

    if (this.parent) {
      this.parent.resize();
    }

    return this;
  }

  canAddPieceJointe(): boolean {
    const manage = this.context &&
      this.context.ctxHabilitation &&
      this.context.ctxHabilitation.HabilitationGestion &&
      CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion);

    return (manage && this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Creer") !== -1);
  }

  canDeletePieceJointe(): boolean {
    const manage = this.context &&
      this.context.ctxHabilitation &&
      this.context.ctxHabilitation.HabilitationGestion &&
      CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion);

    return (manage && this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Supprimer") !== -1);
  }

  /**
   * Checks if can add more files, if not hide the Add button.
   */
  _checkItemLimit(): void {
    if (!CWSTR.isBlank(this.maxPieces) && this.coll.length >= this.maxPieces) {
      this.table.buttonBar.model.trigger("hide:new");
    }
  }

  _initTable(): CWMenuGridView<CWRemoteFileModel> {
    const tblColumns: Array<CWBaseGridColums> = [{ title: "", property: "icon", width: 2 },
    { title: i18n.t('common:gererpiecesjointes.libelle'), property: "libelle", width: 10 }
    ];

    let tableModel;
    if (this.coll) {
      tableModel = new CWEditableGridModel<CWPiecesJointesColl, CWRemoteFileModel>({ coll: this.coll });
    } else {
      tableModel = new CWEditableGridModel<CWPiecesJointesColl, CWRemoteFileModel>({ coll: new CWPiecesJointesColl() });
    }

    const table = new CWMenuGridView<CWRemoteFileModel>({
      id: "piece_jointe_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('common:gererpiecesjointes.table_title'),
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      maxVisibleRows: 5,
      parentContainer: this.parent
    });

    table.cellRenderers["icon"] = (model: CWEditableGridModel): JQuery => {
      const icon = $("<span>");

      icon.addClass("phx-piece-jointe");

      icon.addClass("piece-jointe-icon");
      icon.addClass(this.mimeClass(model.get("mime")));

      return icon;
    };

    table.cellRenderers["libelle"] = (model: CWEditableGridModel): JQuery => {
      const div = $("<div>");
      const file = $("<span>");

      div.addClass("phx-piece-jointe");

      try {
        // let libelle = !CWSTR.isBlank(model.get("reelNom")) ? model.get("reelNom") : model.get("libelle").substring(model.get("libelle").lastIndexOf("_") + 1);
        const libelle = model.get("reelNom") ? model.get("reelNom") : model.get("code") === null ? model.get("libelle") : model.get("libelle").substring(model.get("libelle").lastIndexOf("_") + 1);
        file.attr("data-code", model.get("code"));
        file.html(libelle);
      } catch (e) {
        file.html(!CWSTR.isBlank(model.get("reelNom")) ? model.get("reelNom") : model.get("libelle"));
      }

      // show text as a link to open pieces jointes
      file.addClass("piece-jointe-file");

      div.append(file);
      if (!CWSTR.isBlank(model.get("description"))) {
        div.append("<br>");
        div.append(model.get("description"));
      }

      return div;
    };
    table.height = 200;

    table.setSortableColumns();

    return table;
  }

  mimeClass(mime: string): string {
    switch (mime) {
      case "doc":
      case "docx":
        return "phx-icon-doc";
      case "pdf":
        return "phx-icon-pdf";
      case "png":
      case "bmp":
      case "jpg":
      case "jpeg":
        return "phx-icon-img";
      case "xls":
        return "phx-icon-xls";
      default:
        return "ui-phx-piece-jointe-icon";

    }
  }

  // open file in another navigator tab
  _manageFileClick(event: JQueryMouseEventObject): void {
    const code = $(event.target).attr("data-code");

    if (code) {
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    }

  }

  _customBtnAction(buttonId: string): void {
    const current = this.table.model.get("value");

    switch (buttonId) {
      case "new":
        if (this.fileTransferView) {
          $(this.el).find(".table_pieces_jointes").hide();
          $(this.el).find(".file_transfer").show();
        }
        break;
      case "delete":
        CWMSGS.showConfirmAdapter(i18n.t('common:delconfirm'), (result) => {
          if (result === "Y") {
            if (CWSTR.isBlank(current.getHabContext())) {
              if (!CWSTR.isBlank(self.parent) && !CWSTR.isBlank(this.parent.viewData) && !CWSTR.isBlank(this.parent.viewData.context)) {
                current.setHabContext(new CWHabilitationContext({
                  onglet: this.parent.viewData.context.ctxEcran,
                  foncCour: this.parent.viewData.context.ctxHabilitation.HabilitationGestion,
                  natGest: "S"
                }));
              }
            }
            if (current.get("code") === current.id) //The pj is in the server, postpone the destroy to piecesjointes/views.js:applyPendingActions
            {
              this.model.trigger("piecejointeRemoved", current.get("code") ? current.get("code") : current.get("cid"));
              this.parent.close();
            } else {
              current.destroy({
                success: () => { //Will never run?
                  if (this.parent) {
                    this.model.trigger("piecejointeRemoved", current.get("code") ? current.get("code") : current.get("cid"));
                    this.parent.close();
                  }
                },
                error: () => { //Deleted an a non uploaded pj
                  if (this.parent) {
                    this.parent.close();
                  }
                }
              });
            }
          }
        }, null, null, null, this.$appendTo);
        break;
      default: {
        //Nothing
      }
    }
  }
}
