import { CWBaseModel } from 'core/models/cwBase.model';
import { CWSTR } from 'utils/cwStr';

export class CWCollabPopulationModel extends CWBaseModel {

    defaults(): { [key: string]: any } {
        return {
            "matric": "",
            "matricaux": "",
            "nom": "",
            "prenom": ""
        }
    }

    parse(response: { [key: string]: any }): { [key: string]: any } {
        if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.matric)) {
            response.id = response.matric;
        }
        return response;
    }
}
