import * as Backbone from 'Backbone';


/**
 * Right Model.
 */
export class CWDroitModel extends Backbone.Model {

  /**
   * Process to manage the response
   */
  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response && response.fonction) {
      this.id = response.fonction;
      response.id = response.fonction;
    }
    return response;
  }
}