import _ from 'underscore';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';

/**
 * Model to filter Evenements Generiques
 */
export class CWFilterListerEvtGen extends CWFilterBaseModel {
  private _filterDefaultValues: { [key: string]: any };

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    super(attributes, options);
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
    this._filterDefaultValues = {};
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    //Period validation
    const datedeb: { [key: string]: any } = {
      "name": "datedeb",
      "value": attrs.datedeb,
      "label": undefined
    };
    const datefin: { [key: string]: any } = {
      "name": "datefin",
      "value": attrs.datefin,
      "label": undefined,
      "isInfinity": false
    };
    const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
    if (!CWSTR.isBlank(errorMsg)) {
      errors.datedeb = errorMsg.datedeb;
      errors.datefin = errorMsg.datefin;
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }

  public get filterDefaultValues(): { [key: string]: any } {
    return this._filterDefaultValues;
  }
  public set filterDefaultValues(nValues: { [key: string]: any }) {
    this._filterDefaultValues = nValues;
  }
}