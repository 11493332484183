import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWCalendaccElementsModel } from './cwCalendaccElements.model';
import { CWCalendaccVascoModel } from './cwCalendaccVasco.model';
import { CWCalendaccZonesModel } from './cwCalendaccZones.model';

/**
 * Model for the main table
 */
export class CWCalendaccModel extends CWBaseModel {

  habilitationV: string;
  habilitationG: string;
  usecase: string;
  datedeb: string;
  datefin: string

  defaults(): { [key: string]: any } {
    return {
      "elements": [],
      "vasco": [],
      "zones": [],
      "natures": []
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.habilitationV = "CALENDRIER.V";
    this.habilitationG = "N";
    this.usecase = options.usecase || "calendacc";
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/calendriercollab/" + encodeURIComponent(this.datedeb + "," + this.datefin);
    };
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (response) {
      if (response.elements) {
        response.elements = new Backbone.Collection(response.elements, { model: CWCalendaccElementsModel });
      }
      if (response.vasco) {
        response.vasco = new Backbone.Collection(response.vasco, { model: CWCalendaccVascoModel });
      }
      if (response.zones) {
        response.zones = new Backbone.Collection(response.zones, {
          model: CWCalendaccZonesModel,
          parse: true
        });
      }
    }
    return response;
  }
}