import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';

/**
 * Model for Initialisation (EvtAbsSoumisWkf , EvtSouSoumisWkf)
 */
export class CWMotifAbsInitModel extends CWBaseModel {

  defaults(): { [key: string]: any } {
    return {
      "wkfdemactiv": null,
      "wkfsouactiv": null
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    options = options || {};
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.urlRoot = (): string => {
      return Configuration.restRoot + "/rest/motifabsence/initwkf";
    };
  }
}